import store from '../store';
import { UPDATE_PRO_REQUIRED_DIALOG } from '../store/userSubscription/duck';
import { isSubscriptionAllow } from './user-subscription';

export const isSubscriptionAllowFromStore = ({ type, nicknames }) => {
  const subscriptions = store.getState().userSubscription.subscriptions;
  const allowed = isSubscriptionAllow(subscriptions, { type, nicknames });
  return allowed;
};

export const isSubscriptionAllowAction = ({ type, nicknames }, customPayload) => {
  let result = store.dispatch((dispatch, getState) => {
    const subscriptions = getState().userSubscription.subscriptions;
    const allowed = isSubscriptionAllow(subscriptions, { type, nicknames });
    dispatch({ type: UPDATE_PRO_REQUIRED_DIALOG, payload: !allowed ? (customPayload ? customPayload : true) : false });
    return allowed;
  });
  return result;
};
