import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';

/***********************************
 * Action Types
 ***********/

export const ADD_MESSAGE = 'portfoplus/message/ADD_MESSAGE';
export const ADD_MESSAGE_SUCCESS = 'portfoplus/message/ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_ERROR = 'portfoplus/message/ADD_MESSAGE_ERROR';

export const FETCH_MESSAGES = 'portfoplus/message/FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'portfoplus/message/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'portfoplus/message/FETCH_MESSAGES_ERROR';

export const FEEDBACK = 'portfoplus/message/FEEDBACK';
export const FEEDBACK_SUCCESS = 'portfoplus/message/FEEDBACK_SUCCESS';
export const FEEDBACK_ERROR = 'portfoplus/message/FEEDBACK_ERROR';

export const SELECT_CLIENT = 'portfoplus/message/SELECT_CLIENT';
export const INIT_PHASE = 'portfoplus/message/INIT_PHASE';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated

  feedbackPhase: INIT,
  addMessagePhase: INIT,
  fetchMessagesPhase: INIT,
  resMessage: null,
  fetchMessageData: [],
  selectedClient: {},
  error: null,
  isSubmitting: false
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case ADD_MESSAGE: {
      return state
        .set('addMessagePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_MESSAGE_SUCCESS: {
      const { payload } = action;
      return state.set('addMessagePhase', payload.success).set('error', null);
    }

    case ADD_MESSAGE_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('addMessagePhase', ERROR);
    }

    case FETCH_MESSAGES: {
      return state
        .set('fetchMessagesPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case FETCH_MESSAGES_SUCCESS: {
      const { payload } = action;
      return state
        .set('fetchMessagesPhase', payload.success)
        .set('fetchMessageData', payload.data)
        .set('error', null);
    }

    case FETCH_MESSAGES_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchMessagesPhase', ERROR);
    }

    case FEEDBACK: {
      return state
        .set('feedbackPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case FEEDBACK_SUCCESS: {
      const { payload } = action;
      return state
        .set('feedbackPhase', payload.success)
        .set('resMessage', payload.message)
        .set('error', null);
    }

    case FEEDBACK_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('feedbackPhase', ERROR);
    }

    case SELECT_CLIENT: {
      const { payload } = action;
      return state
        .set('selectedClient', payload.data)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case INIT_PHASE: {
      return state.set('feedbackPhase', INIT);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const addMessage = data => {
  return {
    type: ADD_MESSAGE,
    payload: { data }
  };
};

export const fetchMessages = data => {
  return {
    type: FETCH_MESSAGES,
    payload: data
  };
};

export const feedback = data => {
  return {
    type: FEEDBACK,
    payload: { data }
  };
};

export const selectClient = data => {
  return {
    type: SELECT_CLIENT,
    payload: { data }
  };
};

export const initPhaseFeedBackPhase = () => ({
  type: INIT_PHASE
});

/***********************************
 * Epics
 ***********/

const addMessageEpic = action$ =>
  action$.ofType(ADD_MESSAGE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.addMessage(action.payload.data))
      .flatMap(payload => [
        {
          type: ADD_MESSAGE_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: ADD_MESSAGE_ERROR,
          payload: { error }
        })
      );
  });

const feedbackEpic = action$ =>
  action$.ofType(FEEDBACK).mergeMap(action => {
    return Rx.Observable.fromPromise(api.feedback(action.payload.data))
      .flatMap(payload => [
        {
          type: FEEDBACK_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: FEEDBACK_ERROR,
          payload: { error }
        })
      );
  });

const fetchMessageEpic = action$ =>
  action$.ofType(FETCH_MESSAGES).mergeMap(action => {
    return Rx.Observable.fromPromise(api.fetchMessage(action.payload))
      .flatMap(payload => [
        {
          type: FETCH_MESSAGES_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: FETCH_MESSAGES_ERROR,
          payload: { error }
        })
      );
  });

export const messageEpic = combineEpics(addMessageEpic, fetchMessageEpic, feedbackEpic);
