import React, { useState, useEffect } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import PVPScatterChart from './PVPScatterChart';
import { formatNumber, decimalToString } from '../../../../utils/formatNumber';
import { injectIntl } from 'react-intl';
import PVPHorizontalBar from './PVPHorizontalBar';
import { POLICY_TYPE } from '../../../../constants/policy';
import { getSubCategoryNameById } from '../../../../utils/policy';
import _ from 'lodash';
import { getInsurerColor } from '../../../../utils/insurer';

const POLICY_INFO_TYPE = {
  PREMIUM: 0,
  PROTECTION: 1
};

const PVP_OPTION_TYPE = {
  BAR: 0,
  SCATTER: 1
};

export const BAR_COLOR = {
  EXAMPLE: '#eeeeee',
  PREMIUM: '#ff6383',
  PROTECTION: '#35d0ba'
};

const PVPChartsView = props => {
  const { intl, currency, policyType, data = [] } = props;

  const [pvpOption, setPvpOption] = useState(0);
  const [pvpGraph, setPvpGraph] = useState([]);
  const [pvpColor, setPvpColor] = useState([]);

  const currencyIntl = intl.formatMessage({ id: currency });

  const barChartOption = (policyType, barType) => {
    const text =
      barType === POLICY_INFO_TYPE.PREMIUM
        ? 'Yearly Premium'
        : policyType === POLICY_TYPE.LIFE
        ? 'Life Protection'
        : 'C.I. Protection';

    const labelString =
      barType === POLICY_INFO_TYPE.PREMIUM
        ? `${intl.formatMessage({ id: 'Premium' })} (${currencyIntl}/${intl.formatMessage({ id: 'year' })})`
        : `${intl.formatMessage({ id: 'Protection' })} (${currencyIntl})`;

    return {
      title: {
        display: true,
        text: intl.formatMessage({ id: text })
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontSize: 12,
          boxWidth: 11
        }
      },
      scales: {
        xAxes: [
          {
            ticks: {
              min: 0,
              autoSkip: false,
              callback: function(label, index, labels) {
                return formatNumber(label, intl);
              }
            },
            scaleLabel: {
              display: true,
              labelString: labelString
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontSize: '10'
            },
            offset: true
          }
        ]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += intl.formatMessage({ id: currency }) + ' ';
            label += decimalToString(tooltipItem.xLabel);

            return label;
          }
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    };
  };

  const onChangePVPOption = event => {
    setPvpOption(parseInt(event.target.value));
  };

  useEffect(() => {
    const _pvpGraph = [];
    const _pvpColor = [];

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const policy = data[i];

        _pvpGraph.push({
          policy: `${intl.formatMessage({ id: getSubCategoryNameById(policy.subCategoryId) })} ${intl.formatMessage({
            id: policy.insurerTitle[intl.locale]
          })}`,
          totalPremiumAmount: policy.totalPremiumAmount.toFixed(2),
          totalProtectionAmount:
            policyType === POLICY_TYPE.LIFE
              ? _.get(policy, 'totalProtectionAmount', 0).toFixed(0)
              : _.get(policy, 'sumInsuredInCurrency', 0).toFixed(0)
        });

        _pvpColor.push(getInsurerColor({ color: policy.color }));
      }
    }

    setPvpGraph(_pvpGraph);
    setPvpColor(_pvpColor);
  }, [data, intl, policyType]);

  const premiumBarColor = BAR_COLOR.PREMIUM;
  const protectionBarColor = BAR_COLOR.PROTECTION;

  // Premium VS Protection Bar Chart
  const pvpBarChartHeight = 30 * Math.max(pvpGraph ? pvpGraph.length : 0, 1) + 80;
  const pvpBarChartData = [
    {
      labels: [],
      datasets: []
    },
    {
      labels: [],
      datasets: []
    }
  ];

  const premiumDataSet = [];
  const protectionDataSet = [];

  for (let i = 0; i < pvpGraph.length; i++) {
    pvpBarChartData[POLICY_INFO_TYPE.PREMIUM].labels.push(pvpGraph[i].policy);
    premiumDataSet.push(pvpGraph[i].totalPremiumAmount);

    pvpBarChartData[POLICY_INFO_TYPE.PROTECTION].labels.push(pvpGraph[i].policy);
    protectionDataSet.push(pvpGraph[i].totalProtectionAmount);
  }

  pvpBarChartData[POLICY_INFO_TYPE.PREMIUM].datasets.push({
    backgroundColor: premiumBarColor,
    borderColor: premiumBarColor,
    borderWidth: 1,
    data: premiumDataSet,
    categoryPercentage: 0.75,
    barPercentage: 1,
    label: intl.formatMessage({ id: 'Premium' }),
    maxBarThickness: 15
  });

  pvpBarChartData[POLICY_INFO_TYPE.PROTECTION].datasets.push({
    backgroundColor: protectionBarColor,
    borderColor: protectionBarColor,
    borderWidth: 1,
    data: protectionDataSet,
    categoryPercentage: 0.75,
    barPercentage: 1,
    label: intl.formatMessage({ id: 'Protection' }),
    maxBarThickness: 15
  });

  const pvpScatterChartData = [];

  pvpGraph.forEach(function(value, index) {
    pvpScatterChartData.push({
      name: value.policy,
      type: 'scatter',
      data: [{ value: [value.totalProtectionAmount, value.totalPremiumAmount] }]
    });
  });

  return (
    <div>
      <div className="text-center analysisFont">
        {intl.formatMessage({
          id: 'Premium Vs Protection'
        })}
      </div>

      <div className="text-center analysis-overview-subtitle">
        (
        {policyType === POLICY_TYPE.LIFE
          ? intl.formatMessage({ id: 'for life coverage' })
          : intl.formatMessage({ id: 'for C.I. coverage' })}
        )
      </div>

      <FormControl className="pvp-select-div">
        <RadioGroup aria-label="pvpOption" name="pvpOption" value={pvpOption.toString()} onChange={onChangePVPOption}>
          <FormControlLabel
            value={PVP_OPTION_TYPE.BAR.toString()}
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'Bar Chart' })}
          />
          <FormControlLabel
            value={PVP_OPTION_TYPE.SCATTER.toString()}
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'Scatter Chart' })}
          />
        </RadioGroup>
      </FormControl>

      {pvpOption === PVP_OPTION_TYPE.BAR && (
        <div>
          <div
            id="canvas-holder"
            className="Outer"
            style={{
              height: pvpBarChartHeight,
              padding: 0
            }}
          >
            <PVPHorizontalBar
              data={pvpBarChartData[POLICY_INFO_TYPE.PREMIUM]}
              options={barChartOption(policyType, POLICY_INFO_TYPE.PREMIUM)}
              exampleValue={500}
            />
          </div>

          <div
            id="canvas-holder"
            className="Outer"
            style={{
              height: pvpBarChartHeight,
              padding: 0
            }}
          >
            <PVPHorizontalBar
              data={pvpBarChartData[POLICY_INFO_TYPE.PROTECTION]}
              options={barChartOption(policyType, POLICY_INFO_TYPE.PROTECTION)}
              exampleValue={2000000}
            />
          </div>
        </div>
      )}

      {pvpOption === PVP_OPTION_TYPE.SCATTER && (
        <div>
          <div id="canvas-holder" className="Outer">
            <PVPScatterChart
              pvpScatterChartData={pvpScatterChartData}
              pvpColor={pvpColor}
              intl={intl}
              currency={currency}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(PVPChartsView);
