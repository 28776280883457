import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';

import store from './store';
import AppContainer from './views/App/container';
// import './index.scss';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';

import mixpanel from 'mixpanel-browser';
// import amplitude from 'amplitude-js/amplitude';
import * as amplitude from '@amplitude/analytics-browser';
// import ReactPixel from 'react-facebook-pixel';

import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';

import ClearCache from 'react-clear-cache';

let productionEnv = process.env.NODE_ENV.trim() === 'production';
let developmentEnv = process.env.NODE_ENV.trim() === 'development';

// initialize GTM
if (process.env.GTM_ID && process.env.GTM_ID.trim() !== '' && process.env.GTM_ID.trim() !== 'XXX') {
  TagManager.initialize({ gtmId: process.env.GTM_ID });
}

const defaultTracking = {
  attribution: true,
  pageViews: true,
  sessions: true,
  formInteractions: true,
  fileDownloads: true
}


if (process.env.STAGE === 'beta') {
  // Amplitude Project: Pfp-beta
  // Mixpanel Project: pfp-beta

  mixpanel.init('f747f9f898ba054a4f7ab4ecbdcf45e4');
  amplitude.init('3225b40434eae0b7ff78a94d68776205', { defaultTracking: defaultTracking });
} else if (process.env.STAGE === 'development') {
  // Amplitude Project: Pfp-dev
  // Mixpanel Project: pfp-dev

  mixpanel.init('3d7bdc38401cd1cd9da2f4894729cef7');
  amplitude.init('e44036daa4e6cfd8b72f6bf1d680e2b7', { defaultTracking: defaultTracking });
} else if (productionEnv) {
  // Amplitude Project: Pfp
  // Mixpanel Project: pfp

  mixpanel.init('0643f0ca9b729177ea599bde560a17f5');
  amplitude.init('7ce77aa539b82503097ccd8b3160fcbe', { defaultTracking: defaultTracking });
  // ReactPixel.init('620592661797981');
  // ReactPixel.pageView();
} else {

  // Amplitude Project: Pfp-dev
  // Mixpanel Project: pfp-dev

  mixpanel.init('3d7bdc38401cd1cd9da2f4894729cef7');
  amplitude.init('e44036daa4e6cfd8b72f6bf1d680e2b7', { defaultTracking: defaultTracking });
}

// register Sentry
if (process.env.SENTRY_DSN !== undefined && process.env.SENTRY_DSN.trim() !== '') {
  let sentryEnv = process.env.SENTRY_ENV;
  Sentry.init({ dsn: process.env.SENTRY_DSN, environment: sentryEnv });
}

Modal.setAppElement('body');

addLocaleData([...en, ...zh]);

ReactDOM.render(
  <ClearCache auto>
    {({ isLatestVersion, emptyCacheStorage }) => (
      <Provider store={store}>
        <AppContainer isLatestVersion={isLatestVersion} emptyCacheStorage={emptyCacheStorage} />
      </Provider>
    )}
  </ClearCache>,
  document.getElementById('root')
);
