import React, { Fragment, useRef, useImperativeHandle, forwardRef } from 'react';
import { BoldButton, ItalicButton, OrderedListButton, UnderlineButton, UnorderedListButton } from './draft-js-buttons';
import FontSizeButton from './ToolbarButtons/FontSizeButton/FontSizeButton';
import ColorButton from './ToolbarButtons/ColorButton/ColorButton';
import { Separator, createEditorStateWithText } from 'draft-js-static-toolbar-plugin';
import AlignButton from './ToolbarButtons/AlignButton/AlignButton';
import { ALIGN_BLOCK_TYPES, customStyles } from './constants';
import EmojiButton from './ToolbarButtons/EmojiButton/EmojiButton';
import AddImageButton from './ToolbarButtons/AddImageButton/AddImageButton';
import UndoButton from './ToolbarButtons/UndoButton/UndoButton';
import RedoButton from './ToolbarButtons/RedoButton/RedoButton';
import './MyToolbar.scss';
import { ChatGPTRewriteMessage } from '../ClientEntry/ChatGPTPresetDrawer/ChatGPTRewriteMessage';
import { formatEditorStateValue, getPlainTextFromEditorState } from '../../utils/editor';
import IconToast from '../NewToast';
import { toast } from 'react-toastify';


const MyToolbar = forwardRef((props, ref) => {
  const {
    imageUsedIn,
    editorState,
    onChange,
    textAlign,
    image,
    list,
    undo,
    wrapperClassName,
    editorRef,
    ...rest
  } = props;

  const toolbarRef = useRef(null);

  const getToolbar = () => {
    return toolbarRef.current;
  };

  useImperativeHandle(ref, () => ({
    getToolbar: getToolbar
  }));

  const editor = editorRef && editorRef.current ? editorRef.current.getEditor() : undefined;
  const plugins = editorRef && editorRef.current ? editorRef.current.getPlugins() : {};
  const { toolbar: toolbarPlugin, image: imagePlugin } = plugins;
  const Toolbar = (toolbarPlugin && toolbarPlugin.Toolbar) || (() => null);

  return (
    <div className={`toolbar-wrapper ${wrapperClassName ? wrapperClassName : ''}`} ref={toolbarRef}>
      <Toolbar {...rest}>
        {externalProps => {
          externalProps.setEditorState = onChange;
          return (
            <Fragment>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <FontSizeButton
                editorState={editorState}
                onChange={onChange}
                fontSizeStyles={customStyles.fontSize}
                {...externalProps}
              />
              <ColorButton
                editorState={editorState}
                onChange={onChange}
                colorStyles={customStyles.color}
                editor={editor}
                {...externalProps}
              />

              <Separator {...externalProps} />

              {(list || textAlign) && (
                <Fragment>
                  {list && (
                    <Fragment>
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                    </Fragment>
                  )}

                  {textAlign && (
                    <Fragment>
                      {(textAlign.left || textAlign === true) && (
                        <AlignButton
                          type={ALIGN_BLOCK_TYPES.ALIGN_LEFT}
                          editorState={editorState}
                          onChange={onChange}
                          {...externalProps}
                        />
                      )}

                      {(textAlign.center || textAlign === true) && (
                        <AlignButton
                          type={ALIGN_BLOCK_TYPES.ALIGN_CENTER}
                          editorState={editorState}
                          onChange={onChange}
                          {...externalProps}
                        />
                      )}

                      {(textAlign.right || textAlign === true) && (
                        <AlignButton
                          type={ALIGN_BLOCK_TYPES.ALIGN_RIGHT}
                          editorState={editorState}
                          onChange={onChange}
                          {...externalProps}
                        />
                      )}
                    </Fragment>
                  )}

                  <Separator {...externalProps} />
                </Fragment>
              )}

              <EmojiButton editorState={editorState} onChange={onChange} editor={editor} {...externalProps} />

              {image && (
                <AddImageButton
                  imageUsedIn={imageUsedIn}
                  editorState={editorState}
                  onChange={onChange}
                  modifier={imagePlugin.addImage}
                  {...externalProps}
                />
              )}

              {undo && (
                <Fragment>
                  <Separator {...externalProps} />
                  <UndoButton editorState={editorState} onChange={onChange} {...externalProps} />
                  <RedoButton editorState={editorState} onChange={onChange} {...externalProps} />
                </Fragment>
              )}
              {
                // <ChatGPTRewriteMessage
                //   buttonType='toolbar' {...externalProps}
                //   onValue={(value) => { console.log('value') }} />
                <ChatGPTRewriteMessage
                  buttonType='toolbar'
                  containerStyle={{ display: "inline-block" }}
                  message={getPlainTextFromEditorState(editorState)}
                  onValue={(message) => {
                    if (message) {
                      const newState = formatEditorStateValue(message);
                      onChange(newState);
                    }
                  }} {...externalProps} />
              }
            </Fragment>
          );
        }}
      </Toolbar>
    </div>
  );
});

export default MyToolbar;
