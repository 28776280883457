import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { UserSubscription } from '../../../utils/user-subscription';
import CurrentCoreSubscriptionPricingCard from '../../Subscription/SubscriptionContent/CurrentCoreSubscriptionPricingCard/CurrentCoreSubscriptionPricingCard';
import { INIT, SUCCESS } from '../../../constants/phase';
import { getTeams } from '../../../store/team/duck';
import { shouldShowTeamPlan } from '../../../utils/team';
import NativeOrWeb from '../../../utils/native';
import './SubscriptionCard.scss';

const CardStyle = {
  padding: 16,
  boxShadow: 'var(--my-shadow)'
};

const TitleItemStyle = {
  width: '100%'
};

const TitleStyle = {
  fontSize: '125%',
  fontWeight: 'bold',
  color: '#858585'
};

const ManageButtonStyle = {
  padding: 0
};

const TeamPlanTypoStyle = {
  fontSize: '150%',
  margin: '12px 0 6px'
};

const SubscriptionCard = props => {
  const {
    intl,
    history,
    userDetails,
    stripePromise,
    userSubscription,
    teams,
    getTeamsPhase,
    getTeams
  } = props;

  const userSubscriptionO = new UserSubscription(userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const isTeam = shouldShowTeamPlan(teams);

  if (currentCoreSubscription?.subscriptionPlan.isTrial) {
    let trialInterval = currentCoreSubscription.subscriptionPlan.trialInterval;
    let trialIntervalCount = currentCoreSubscription.subscriptionPlan.trialIntervalCount;
    if (moment(currentCoreSubscription.expiredAt).diff(moment(currentCoreSubscription.startedAt), trialInterval) === trialIntervalCount) {
      var trialing = true;
    }
  }

  // const isUpgradable = isUserSubscriptionUpgradable(userSubscription, subscriptionPlans, )
  const manage = () => history.push('/subscription');
  const startEarly = () => history.push({
    pathname: '/subscription',
    state: { openChangeSubscriptionPlan: true, couponId: trialing ? "EB95" : "" }
  });

  useEffect(() => {
    if (getTeamsPhase === INIT) {
      getTeams();
    }
  }, [getTeamsPhase, getTeams]);

  return getTeamsPhase === SUCCESS ? (
    <Card className="subscription-card" style={CardStyle}>
      <Grid container direction="column" alignItems="center">
        <Grid item style={TitleItemStyle}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography align="left" style={TitleStyle}>
                {intl.formatMessage({ id: 'sub-current' })}
              </Typography>
            </Grid>
            {!isTeam && (
              <Grid item>
                <Button color="primary" variant="outlined" style={ManageButtonStyle} onClick={manage}>
                  {intl.formatMessage({ id: 'manage-profile' })}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <CurrentCoreSubscriptionPricingCard
            userDetails={userDetails}
            stripePromise={stripePromise}
            header={
              isTeam && <Typography style={TeamPlanTypoStyle}>{intl.formatMessage({ id: 'team-plan' })}</Typography>
            }
            hideAmountDue={true}
            hideAmount={true}
            subscription={currentCoreSubscription}
            style={{ width: '100%' }}
            fullWidth={true}
            defaultHideCardDetails={true}
          />

          {!NativeOrWeb.isIOSPlatform() && trialing &&
            <Button
              onClick={startEarly}
              variant="contained"
              color="primary"
              fullWidth={true}
              style={{ marginTop: 10 }}
            >
              {currentCoreSubscription.subscriptionPlan.interval === "year" ?
                intl.formatMessage({ id: 'start-annual-plan-early' }) :
                intl.formatMessage({ id: 'start-monthly-plan-early' })
              }
            </Button>
          }
        </Grid>
      </Grid>
    </Card>
  ) : null;
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    userSubscription: state.userSubscription.userSubscription,
    subscriptionPlans: state.subscriptionPlan.subscriptionPlans,
    upgradeSubscriptionPlansFetched: state.subscriptionPlan.upgradeSubscriptionPlansFetched,
    teams: state.team.teams,
    getTeamsPhase: state.team.getTeamsPhase
  }),
  {
    getTeams
  }
)(SubscriptionCard);

export default injectIntl(withRouter(container));
