import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  useMediaQuery,
  Typography
} from '@material-ui/core';
import { toggleDialog, mergeDialog } from '../../store/control/duck';
import { SlideTransition } from '../Transition/Transition';
import { decimalToString } from '../../utils/formatNumber';
import { getClientDetails, submitClient } from '../../store/client/duck';
import { usePrevious } from '../../utils/hooks';
import _ from 'lodash';
import ExpenseCalculator from './ExpenseCalculator/ExpenseCalculator';
import { getExpensesTotal } from '../../utils/client';

const TotalValueTypoStyle = {
  fontSize: '175%',
  fontWeight: 500
};

const ExpenseCalculatorDialog = props => {
  const {
    intl,
    createClientPhase,
    fetchDetailsPhase,
    clientDetails,
    expenseCalculatorDialog,
    toggleDialog,
    mergeDialog,
    submitClient,
    getClientDetails
  } = props;

  const { onSubmit, clientId, factsheetId, closed, expenses, disableSubmit } = expenseCalculatorDialog || {};

  const shouldUseWideLayout = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const prevCreateClientPhase = usePrevious(createClientPhase);
  const prevFetchDetailsPhase = usePrevious(fetchDetailsPhase);
  const prevFactsheetId = usePrevious(factsheetId);

  const {
    diet,
    transportation,
    rentMortgage,
    waterElectricityGas,
    supportParents,
    children,
    shopping,
    pets,
    domesticHelpers,
    otherHouseholdExpenses,
    entertainment,
    digital,
    membership,
    mpf,
    insurance,
    tax,
    privateCar,
    repayment,
    tuition,
    otherMiscellaneousExpenses
  } = expenses || {};

  const isOpen = !!expenseCalculatorDialog && !closed;

  const { _id, email, advisorId } = clientDetails || {};

  const totalValue = getExpensesTotal(expenses);

  const submit = () => {
    const expenses = {
      diet: diet || 0,
      transportation: transportation || 0,
      rentMortgage: rentMortgage || 0,
      waterElectricityGas: waterElectricityGas || 0,
      supportParents: supportParents || 0,
      children: children || 0,
      shopping: shopping || 0,
      pets: pets || 0,
      domesticHelpers: domesticHelpers || 0,
      otherHouseholdExpenses: otherHouseholdExpenses || 0,
      entertainment: entertainment || 0,
      digital: digital || 0,
      membership: membership || 0,
      mpf: mpf || 0,
      insurance: insurance || 0,
      tax: tax || 0,
      privateCar: privateCar || 0,
      repayment: repayment || 0,
      tuition: tuition || 0,
      otherMiscellaneousExpenses: otherMiscellaneousExpenses || 0
    };

    if (advisorId && clientId && _id && email) {
      submitClient({
        advisorId: advisorId,
        clientId: clientId,
        _id: _id,
        email: email,
        expenses: expenses
      });
    }

    if (onSubmit) {
      onSubmit(totalValue, expenses);
    }

    close();
  };

  const close = () => {
    toggleDialog('expenseCalculator', { ...expenseCalculatorDialog, closed: true });
  };

  const onClosed = () => {
    toggleDialog('expenseCalculator', false);
  };

  const onChange = event => {
    const newExpenses = { ...expenses };
    if (event.target.value === undefined) {
      newExpenses[event.target.name] = '';
    } else {
      newExpenses[event.target.name] = event.target.value;
    }
    mergeDialog('expenseCalculator', { expenses: newExpenses });
  };

  useEffect(() => {
    if (isOpen) {
      if (factsheetId && !prevFactsheetId) {
        const newExpenses = _.get(clientDetails, 'expenses');
        mergeDialog('expenseCalculator', { expenses: newExpenses || null });
      }
    }
  }, [clientDetails, isOpen, factsheetId, prevFactsheetId, mergeDialog]);

  useEffect(() => {
    if (fetchDetailsPhase === true && prevFetchDetailsPhase !== true) {
      if (isOpen) {
        mergeDialog('expenseCalculator', { expenses: { ..._.get(clientDetails, 'expenses') } });
      }
    }
  }, [fetchDetailsPhase, prevFetchDetailsPhase, clientDetails, expenseCalculatorDialog, isOpen, mergeDialog]);

  useEffect(() => {
    if (clientId && factsheetId) {
      getClientDetails({ userId: clientId, factsheetId: factsheetId });
    }
  }, [getClientDetails, clientId, factsheetId]);

  useEffect(() => {
    if (createClientPhase === true && prevCreateClientPhase !== true) {
      if (clientId && factsheetId) {
        getClientDetails({ userId: clientId, factsheetId: factsheetId });
      }
    }
  }, [createClientPhase, prevCreateClientPhase, getClientDetails, clientId, factsheetId]);

  return (
    <Dialog
      className="expense-calculator-dialog"
      open={isOpen}
      onClose={close}
      onExited={onClosed}
      fullWidth={true}
      fullScreen={!shouldUseWideLayout}
      TransitionComponent={SlideTransition}
    >
      <DialogTitle disableTypography={true}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="primary" align="left" style={{ fontSize: '1.8rem' }}>
              {intl.formatMessage({ id: 'expense-dialog-title' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="baseline" spacing={1}>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'ec-total-expense' })}</Typography>
              </Grid>
              <Grid item>
                <Typography color="primary" style={TotalValueTypoStyle}>
                  {decimalToString(totalValue)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ExpenseCalculator expenses={expenses} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" disabled={disableSubmit} onClick={submit}>
          {intl.formatMessage({ id: 'apply' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    createClientPhase: state.client.createClientPhase,
    fetchDetailsPhase: state.client.fetchDetailsPhase,
    clientDetails: state.client.clientDetails,
    expenseCalculatorDialog: state.control.expenseCalculatorDialog
  }),
  {
    toggleDialog,
    mergeDialog,
    submitClient,
    getClientDetails
  }
)(ExpenseCalculatorDialog);

export default injectIntl(container);
