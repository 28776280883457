import React, { Fragment, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import GuidanceButton from '../GuidanceGroup/GuidanceButton/GuidanceButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import Pikto from '../Pikto/Pikto';
import MyResizeObserver from '../MyResizeObserver/MyResizeObserver';
import { SlideTransition } from '../Transition/Transition';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { trackDirect } from '../../store/analytics/api';
import { tracking } from '../../utils/tracking';
import './ContentsDialog.scss';

export const getLocalePikto = (set, locale) => (set || {})[locale || 'zh-Hant-HK'];

const DialogStyle = {
  zIndex: 1301
};

const DialogContentStyle = {
  padding: 8
};

const CloseButtonStyle = {
  width: 48,
  position: 'absolute',
  top: 4,
  right: 4
};

const MainDialogContentStyle = {
  paddingBottom: 24
};

const Chart = props => {
  const { targetItem, intl, ...rest } = props;

  if (targetItem) {
    if (targetItem.render) {
      if (typeof targetItem.render === 'function') {
        return targetItem.render({ intl, ...rest });
      } else {
        return targetItem.render;
      }
    } else if (targetItem.piktoChartIds) {
      const localeId = _.get(targetItem.piktoChartIds, `[${intl.locale}]`);
      return <Pikto uid={localeId} {...rest} />;
    }
  }

  return null;
};

const useSpecialSectionSummaryStyles = makeStyles({
  content: {
    margin: '6px 0'
  }
});

const SpecialSection = props => {
  const { intl, img, title, items, onClickItem } = props;
  const classes = useSpecialSectionSummaryStyles();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        style={{ padding: '0 6px' }}
        classes={classes}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>{img}</Grid>
          <Grid item>
            <Typography>{title}</Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: '0 5px 0 3px' }}>
        <Section intl={intl} onClickItem={onClickItem} items={items} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const Section = props => {
  const { title, ImgProps, items, onClickItem, targetItem, ...rest } = props;

  return (
    <Grid container spacing={1}>
      {title && (
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography align="left">{typeof title === 'function' ? title(rest) : title}</Typography>
            </Grid>
            <Grid item>
              <img width={24} {...ImgProps} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {(items || []).map((item, index) => {
        if (!item) {
          return null;
        }

        const isSelected = targetItem?.id === item.id;

        return (
          <Grid key={index} item xs={12}>
            <GuidanceButton
              label={typeof item.title === 'function' ? item.title(rest) : item.title}
              labelProps={{
                align: 'left',
                style: { color: isSelected ? '#000' : undefined, fontWeight: isSelected ? 'bold' : 'normal' }
              }}
              color={item.color}
              redDot={item.redDot}
              onClick={() => onClickItem(item)}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const ContentsDialog = props => {
  const {
    intl,
    history,
    title,
    sections,
    defaultItem,
    open,
    onClose,
    onClosed,
    extras,
    className,
    type,
    style,
    ...rest
  } = props;

  const isNarrowScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [targetItem, setTargetItem] = useState(!isNarrowScreen ? defaultItem : undefined);
  const [height, setHeight] = useState(undefined);

  useEffect(() => {
    setTargetItem(defaultItem);
  }, [defaultItem]);

  useEffect(() => {
    if (open && type === "financial-charts") {
      tracking("Click on Financial Concept Item", { id: defaultItem.id });
      trackDirect("viewFinancialChart", defaultItem.id);
    } else if (open && type === "recruitment") {
      tracking("Click on Recruitment Item", { id: defaultItem.id });
      trackDirect("viewRecruitment", defaultItem.id);
    }
  }, [open]);

  const formattedOnClosed = () => {
    if (onClosed) {
      onClosed();
    }
    setTargetItem(undefined);
  };

  const closeChartDialog = () => setTargetItem(null);

  const onClosedChartDialog = () => setTargetItem(undefined);

  const onClickItem = item => {
    if (type === "financial-charts") {
      tracking("Click on Financial Concept Item", { id: item.id });
      trackDirect("viewFinancialChart", item.id);
    } else if (type === "recruitment") {
      tracking("Click on Recruitment Item", { id: item.id });
      trackDirect("viewRecruitment", item.id);
    }

    if (item && item.type === 'pikto') {
      // explicitly remove Pikto component to force-reload the content
      setTargetItem('loading');
      setTimeout(() => {
        setTargetItem(item);
      }, 500);
    } else {
      setTargetItem(item);
    }
  };

  const onResize = entries => {
    if (!isNarrowScreen) {
      const target = _.get(entries, '[0].target');
      if (target) {
        if (target.offsetHeight) {
          setHeight(target.offsetHeight);
        }
      }
    }
  };

  return (
    <Fragment>
      {!isNarrowScreen && open && <MyResizeObserver targetsSelector=".contents-dialog .content" onResize={onResize} />}

      <Dialog
        className={`contents-dialog${className ? ` ${className}` : ''}`}
        open={open}
        onClose={onClose}
        onExited={formattedOnClosed}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={SlideTransition}
        style={{ ...DialogStyle, ...style }}
        {...rest}
      >
        <IconButton onClick={onClose} style={CloseButtonStyle}>
          <CloseRoundedIcon />
        </IconButton>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          className="content"
          style={{
            ...MainDialogContentStyle,
            ...(!isNarrowScreen ? { height: height, overflow: 'hidden' } : undefined)
          }}
        >
          <Grid container alignItems="flex-start" spacing={2}>
            <Grid
              item
              xs={12}
              sm={3}
              style={
                !isNarrowScreen
                  ? {
                    height: height ? height - 24 : height,
                    overflow: 'auto'
                  }
                  : undefined
              }
            >
              <Grid container direction="column" justify="flex-start" spacing={2}>
                {sections.map((section, index) => {
                  if (!section) {
                    return null;
                  }

                  const key = `${section.title}#${index}`;

                  if (section.special) {
                    return (
                      <Grid item key={key}>
                        <SpecialSection
                          intl={intl}
                          img={section.img}
                          title={section.title}
                          items={section.items}
                          onClickItem={onClickItem}
                          targetItem={targetItem}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item key={key}>
                        <Section
                          intl={intl}
                          onClickItem={onClickItem}
                          title={section.title}
                          items={section.items}
                          targetItem={targetItem}
                        />
                      </Grid>
                    );
                  }
                })}

                {extras && extras}
              </Grid>
            </Grid>
            {!isNarrowScreen && (
              <Grid item sm={9} style={{ height: height ? height - 24 : height, overflow: 'auto' }}>
                <Chart targetItem={!!open ? targetItem : undefined} intl={intl} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      {isNarrowScreen && (
        <Dialog
          fullScreen={targetItem && targetItem.fullScreenDialog}
          fullWidth={true}
          open={open && !!targetItem}
          onClose={closeChartDialog}
          onExited={onClosedChartDialog}
          TransitionComponent={SlideTransition}
          style={DialogStyle}
        >
          <DialogContent style={DialogContentStyle}>
            <Chart targetItem={targetItem} intl={intl} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={closeChartDialog}>
              {intl.formatMessage({ id: 'Close' })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

export default injectIntl(ContentsDialog);
