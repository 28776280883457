import React from 'react';
import { Redirect } from 'react-router';
import { getReferralLink } from '../../../../utils/referral';

const ReferralResponse = (props) => {
  const { location } = props;

  const id = location.pathname.replace('/referral_response/', '');
  let referById, adviserId;
  if (id.substring(0, 3) === 'usr') {
    const tempId = id.substring(4);
    referById = tempId.substr(tempId.indexOf('$') + 1);
    adviserId = tempId.substr(0, tempId.indexOf('$'));
  } else {
    adviserId = id.substring(4);
  }

  const redirectUrl = getReferralLink({
    targetAdviser: { _id: adviserId },
    client: { _id: referById },
    isInternalRoute: true,
  });

  return (
    <Redirect to={redirectUrl} />
    // <AdviserInfoContainer
    // 	themeColor={themeColor}
    // 	wideContent={content}
    // 	narrowContent={content}
    // 	style={{ padding: 0 }}
    // />
  );
};

export default ReferralResponse;
