import React, { useState, Fragment, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import _ from 'lodash';
import './PolicyCategoryTableInfo.scss';
import {
  getSubCategoryColor,
  getSubCategoryIcon,
  isCategory,
  isPolicyInsurer,
  isRiderPolicy
} from '../../../utils/policy';
import { formatMessage } from '../../../utils/locale';
import { PolicyReviewShavedText } from '../PolicyReview';
import { CSSTransition } from 'react-transition-group';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { getWebImageUrl } from '../../../utils/image';

export const POLICY_REVIEW_INFO_TABLE_DEFAULT_WIDTH = 510;
export const POLICY_REVIEW_INFO_TABLE_SHORTENED_DEFAULT_WIDTH = 40;

const PolicyCategoryTableInfo = props => {
  const { intl, category, lastRowNoBorder, collapsed, hideCategory, shortened, width } = props;

  const isCompanyCategory = isCategory(_.get(category, '_id'), 'company');

  // we need an extra internal flag instead of collapsed to avoid the exit transition is disappeared immediately due to width change
  const [preCollapsed, setPreCollapsed] = useState(collapsed);
  const [showCollapsed, setShowCollapsed] = useState(!collapsed);

  const categoryTitle = _.get(category, 'title');
  const policies = _.flatMap(_.get(category, 'subCategories', []), subCategory =>
    subCategory.policies.map((policy, policyIndex) => ({
      ...policy,
      subCategory: subCategory,
      isFirst: policyIndex === 0,
      rowSpan: subCategory.policies.length
    }))
  );

  useEffect(() => {
    if (collapsed) {
      setPreCollapsed(true);
    }
  }, [collapsed]);

  return policies ? (
    //  marginRight: 2 is a hack for showing the right border correctly
    //  cannot find a proper fix yet
    <TableContainer className="policy-category-table info" style={shortened ? { marginRight: 2 } : undefined}>
      <CSSTransition
        in={collapsed}
        timeout={500}
        onEntered={() => setShowCollapsed(false)}
        onExited={() => {
          setShowCollapsed(true);
          setPreCollapsed(false);
        }}
        classNames="collapse"
      >
        <Table
          size="small"
          style={
            !shortened
              ? { width: preCollapsed ? undefined : width || POLICY_REVIEW_INFO_TABLE_DEFAULT_WIDTH }
              : { width: POLICY_REVIEW_INFO_TABLE_SHORTENED_DEFAULT_WIDTH }
          }
        >
          <TableHead>
            <TableRow className="sub-header">
              <TableCell align="center" rowSpan={3} style={{ width: !shortened ? 125 : 40 }}>
                {!hideCategory ? <div className="category-title">{formatMessage(intl, categoryTitle)}</div> : <div />}
              </TableCell>

              {!shortened && (
                <TableCell colSpan={3} style={{ borderBottom: '3px solid transparent' }}>
                  <div />
                </TableCell>
              )}
            </TableRow>
            <TableRow className="main-row">
              {!shortened && (
                <Fragment>
                  {!shortened && !collapsed && showCollapsed && (
                    <TableCell align="center" rowSpan={2} className="collapse" style={{ width: 90 }}>
                      <div>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'Insurer' })}</Typography>
                      </div>
                    </TableCell>
                  )}

                  {!shortened && (
                    <TableCell align="center" rowSpan={2} style={{ width: 160 }}>
                      <div>
                        <Typography color="textSecondary">
                          {intl.formatMessage({ id: isCompanyCategory ? 'Workplace Name' : 'pf-plan-name' })}
                        </Typography>
                      </div>
                    </TableCell>
                  )}

                  {!shortened && !collapsed && showCollapsed && (
                    <TableCell align="center" rowSpan={2} className="collapse" style={{ width: 110 }}>
                      <div>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'Policy No.' })}</Typography>
                      </div>
                    </TableCell>
                  )}
                </Fragment>
              )}
            </TableRow>
            <TableRow className="sub-row" />
          </TableHead>
          <TableBody>
            {policies.map((policy, policyIndex) => {
              const subCategory = policy.subCategory;
              const subCategoryTitle = _.get(subCategory, 'title');
              const color = getSubCategoryColor(subCategory);
              const isDummy = !!policy.dummy;
              const isOtherInsurer = isPolicyInsurer(policy, 'other');
              const policyInsurer = _.get(policy, 'insurer');
              const isRider = isRiderPolicy(policy);

              return (
                <TableRow
                  key={policy._id}
                  className={lastRowNoBorder && policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
                >
                  {policy.isFirst && (
                    <TableCell
                      align="center"
                      rowSpan={policy.rowSpan}
                      style={{
                        borderRight: `3px solid ${color}`,
                        verticalAlign: 'top',
                        paddingTop: 6
                      }}
                    >
                      <Grid container alignItems="center" style={{ color: color }}>
                        <Grid item style={!shortened ? { marginRight: 6 } : undefined}>
                          {getSubCategoryIcon(subCategory, true)}
                        </Grid>
                        {!shortened && <Grid item>{formatMessage(intl, subCategoryTitle)}</Grid>}
                      </Grid>
                    </TableCell>
                  )}
                  {!shortened && !collapsed && showCollapsed && (
                    <TableCell align="center" className="collapse">
                      <div>
                        <PolicyReviewShavedText showCollapsed={showCollapsed}>
                          {isOtherInsurer ? policyInsurer : formatMessage(intl, policyInsurer)}
                        </PolicyReviewShavedText>
                      </div>
                    </TableCell>
                  )}
                  {!shortened && (
                    <TableCell align="center">
                      <Grid container alignItems="center" wrap="nowrap">
                        <Grid item style={isDummy && policy.policyName ? { color: '#FF0000' } : undefined}>
                          <PolicyReviewShavedText showCollapsed={showCollapsed}>
                            {isCompanyCategory ? policy.companyName : policy.policyName}
                          </PolicyReviewShavedText>
                        </Grid>
                        {isRider && (
                          <Grid item style={{ marginLeft: 4 }}>
                            <img src={getWebImageUrl('img/rider_small.png')} alt="rider" height={16} />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                  {!shortened && !collapsed && showCollapsed && (
                    <TableCell align="center" className="collapse">
                      <div>
                        <PolicyReviewShavedText showCollapsed={showCollapsed}>
                          {policy.policyNumber}
                        </PolicyReviewShavedText>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CSSTransition>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyCategoryTableInfo);
