import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Redirect, withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './ShareWithAdviser.scss';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { getSearchObject } from '../../../utils/router';
import _ from 'lodash';
import { selectedLocale } from '../../../store/locale/action';
import { sanitize } from 'dompurify';

const ShareWithAdviser = props => {
  const { intl, history } = props;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const searchObject = getSearchObject(history);
  const name = _.get(searchObject, 'name');
  const language = _.get(searchObject, 'lang');

  useEffect(() => {
    const finalLanguage = language || navigator.language;
    if (finalLanguage) {
      dispatch(selectedLocale(finalLanguage));
    }
  }, [dispatch, language]);

  const nextUrl = history.location.pathname.replace('share-a', 'share-a-in');

  // console.log(nextUrl);
  const signIn = () => history.push({ pathname: '/login', nextUrl: nextUrl });
  const signUp = () => history.push({ pathname: '/signup', nextUrl: nextUrl });
  const knowMore = () => window.open('https://www.portfoplus.com/adviser', '_blank');

  if (isAuthenticated) {
    return <Redirect to={nextUrl} />;
  } else {
    return (
      <div className="share-wif-adv">
        <Container disableGutters={true} maxWidth="sm">
          <div className="img-wrapper">
            <img alt="portfoplus" src="img/share/User-refer-adviser-banner.png" />
          </div>

          <div className="messages">
            <Typography
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  intl.formatMessage(
                    { id: 'connect-wif-adv-welcome' },
                    { name: intl.locale === 'en' ? `${name} ` : name }
                  )
                )
              }}
            />

            <div style={{ textAlign: 'center' }}>
              <Button color="primary" onClick={knowMore}>
                {intl.formatMessage({ id: 'connect-wif-adv-know-more' })}
              </Button>
            </div>
          </div>

          <Grid className="actions" container justify="center" alignItems="center">
            <Grid item xs={6} style={{ paddingRight: '8px' }}>
              <Button variant="outlined" color="primary" onClick={signIn}>
                {intl.formatMessage({ id: 'Sign In' })}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '8px' }}>
              <Button variant="contained" color="primary" onClick={signUp}>
                {intl.formatMessage({ id: 'Sign Up' })}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
};

export default injectIntl(withRouter(ShareWithAdviser));
