import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { SlideTransition } from './Transition/Transition';

class connectWithAdviser extends React.Component {
  render() {
    const { open, onClose, intl } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {intl.formatMessage({
              id: 'Connect'
            })}
          </DialogTitle>
          <DialogContent>
            {intl.formatMessage({
              id: 'No connected adviser, please connect here.'
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Link to={'/connect_with_adviser'}>
              <Button color="primary" autoFocus onClick={onClose}>
                {intl.formatMessage({ id: 'Connect' })}
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connectWithAdviser;
