import React from 'react';
import { injectIntl } from 'react-intl';
import { HorizontalBar } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core/styles';

const getChartConfig = template => ({
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: template ? 0 : 1000
  },
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false,
          min: 0
        },
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          display: true
        },
        gridLines: {
          display: false
        },
        scaleLabel: {
          display: false
        }
      }
    ]
  },
  plugins: {
    datalabels: false
  },
  cornerRadius: 0
});

const ExpenseIncomeChart = props => {
  const { intl, monthlyExpense, monthlyIncome, template } = props;
  const theme = useTheme();

  let data, colors;
  if (monthlyIncome || monthlyExpense) {
    const firstColor = monthlyExpense > monthlyIncome ? theme.palette.error.main : '#3Eeef8';
    const secondColor = '#3Eeef888';

    data = [monthlyExpense, monthlyIncome];
    colors = [firstColor, secondColor];
  } else {
    data = [1, 1];
    colors = ['#e6e6e6', '#e6e6e6'];
  }

  const chartData = {
    labels: [intl.formatMessage({ id: 'expense' }), intl.formatMessage({ id: 'Income' })],
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: colors,
        barPercentage: 0.9,
        categoryPercentage: 1
      }
    ]
  };

  return (
    <div
      className="expense-income-chart"
      style={{ position: 'relative', width: template ? 215 : '100%', height: '100%', margin: 'auto' }}
    >
      <div
        style={{
          position: 'absolute',
          height: 45,
          width: '100%',
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}
      >
        <HorizontalBar data={chartData} options={getChartConfig(template)} />
      </div>
    </div>
  );
};

export default injectIntl(ExpenseIncomeChart);
