import { Grid, Typography } from '@material-ui/core';
import React from 'react';

// const TitleIconImageStyle = {
//   height: 20
// };

const AdviserHomePageSubtitle = props => {
  const { title } = props;
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        {/* <img src="img/logo_s_Round.png" alt="portfoplus" style={TitleIconImageStyle} /> */}
        🛠️
      </Grid>
      <Grid item>
        <Typography className="subtitle" color="textSecondary">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AdviserHomePageSubtitle;
