import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../../store/control/duck';
import { Portal } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { tracking } from '../../../../utils/tracking';

const Style = {
  position: 'fixed',
  bottom: 'calc(65px + var(--bottom-padding))',
  right: 20,
  background: '#8A2BE2',
  zIndex: 10
};

const WideStyle = {
  width: 60,
  height: 60,
  ...Style
};

const FinancialChartsButton = props => {
  const { defaultItem, toggleDialog, isWide } = props;

  const onClick = () => {
    tracking('Click on Financial Concept - Analysis');
    toggleDialog('financialCharts', { defaultItem });
  };

  return (
    <Portal container={document.body}>
      <Fab
        className="fab"
        size="medium"
        variant="round"
        color="primary"
        onClick={onClick}
        style={isWide ? WideStyle : Style}
      >
        <img src="img/adviser-home-page/fin_charts_white.png" alt="useful-charts" width="60%" />
      </Fab>
    </Portal>
  );
};

const container = connect(state => ({}), {
  toggleDialog
})(FinancialChartsButton);

export default injectIntl(container);
