import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import { sanitize } from 'dompurify';

class QuarterProgressModal extends PureComponent {
  render() {
    const { intl, open, onClose, okBtnOnClick } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        fullWidth={true}
        keepMounted
      >
        <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Quarterly Progress' })}</DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(
                intl.formatMessage({
                  id: 'Progress-Desc-1'
                })
              )
            }}
          />
          <div>
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#3966f8' }} />
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  intl.formatMessage({
                    id: 'Expected-Biz-Desc'
                  })
                )
              }}
            />
          </div>
          <div>
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#8c1aff' }} />
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  intl.formatMessage({
                    id: 'Closed-Biz-Desc'
                  })
                )
              }}
            />
          </div>
          <div>
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#d2a6ff' }} />
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  intl.formatMessage({
                    id: 'FollowUp-Biz-Desc'
                  })
                )
              }}
            />
          </div>
          <div>
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#8c1aff' }} />+
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#d2a6ff' }} />
            <span
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  intl.formatMessage({
                    id: 'Progress-Biz-Desc'
                  })
                )
              }}
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(
                intl.formatMessage({
                  id: 'Progress-Desc-2'
                })
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={okBtnOnClick} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(QuarterProgressModal);
