

import { Typography, Button } from '@material-ui/core'
import React from 'react'
import { CheckCircle } from '@material-ui/icons'


const RequestSuccessView = (props) => {
    const { intl, backToHome } = props;

    return (
        <div className="fixed-wrapper">
            <div >
                <CheckCircle className='icon' />
            </div>
            <br />
            <br />
            <div className="title">
                <Typography className='title' id="account-deletion-success-title">
                    {intl.formatMessage({ id: 'account-deletion-success-title' })} </Typography>
                <Typography className='description' id="account-deletion-success-description" >
                    {intl.formatMessage({ id: 'account-deletion-success-description' })} </Typography>
            </div>

            <br />
            <br />
            <div className="button-group">
                {/* <Button
                    onClick={backToLogin}
                    color="primary" >
                    <span>{intl.formatMessage({ id: 'Back to Login' })} </span>
                </Button> */}
                <Button onClick={backToHome}
                    color="primary"
                    variant='contained' >
                    <span>{intl.formatMessage({ id: 'account-deletion-back-to-home' })} </span>
                </Button>
            </div>
        </div >
    );

}

export default RequestSuccessView