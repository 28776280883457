import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Drawer, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { isClientDisconnected } from '../ClientEntry/utils';
import { isNavAside } from '../../utils/device';
import './ClientNavBarDrawer.scss';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getMedicalCards } from '../../store/medicalCard/duck';
import { INIT } from '../../constants/phase';
import { tracking } from '../../utils/tracking';
import { shouldShowFinHome } from '../../utils/user';

const ContainerStyle = {
  padding: 16,
  display: 'flex'
};

const GridSideNavStyle = {
  marginLeft: 70,
  marginTop: 80,
  width: 302
};

const GridNotSideNavStyle = {
  margin: 'auto'
};

const ActionButtonStyle = {
  textAlign: 'center'
};

const ClientNavBarDrawer = props => {
  const {
    intl,
    history,
    userDetails,
    isConnected,
    hasMedicalCards,
    getMedicalCardsPhase,
    open,
    closeClientNavDrawer,
    getMedicalCards
  } = props;

  const showFinHome = shouldShowFinHome({ user: userDetails });

  const addPolicy = () => {
    // Tracking
    tracking('User Function Accessed: Open Add Policy from Nav Bar');
    history.push('/existing_policy');
    closeClientNavDrawer();
  };
  const adviser = () => {
    // Tracking
    tracking('User Nav Bar Accessed: Adviser');
    history.push('/message');
    closeClientNavDrawer();
  };
  const connectWithAdviser = () => {
    // Tracking
    tracking('User Nav Bar Accessed: Connect With Adviser');
    history.push('/share_options?connectWithAdviserDialog=true');
    closeClientNavDrawer();
  };
  const myMedicalCard = () => {
    // Tracking
    tracking('User Nav Bar Accessed: My Medical Card');
    history.push({ pathname: '/my-profile', state: { medicalCard: true } });
    closeClientNavDrawer();
  };
  const reinder = () => {
    tracking('User Nav Bar Accessed: Reminder');
    history.push('/reminder');
  };
  const consultation = () => {
    tracking('User Nav Bar Accessed: Consultation');
  };
  const finHome = showFinHome
    ? () => {
        tracking('User Nav Bar Accessed: Fin Home');
        window.open('https://www.megarichbs.com/family?source=PortfoPlus', '_blank');
      }
    : undefined;

  const isSideNav = isNavAside();

  // hack to blur background easily
  useEffect(() => {
    const body = document.body;
    if (body) {
      if (open) {
        body.classList.add('blur');
      } else {
        body.classList.remove('blur');
      }
    }
  }, [open]);

  useEffect(() => {
    if (getMedicalCardsPhase === INIT) {
      getMedicalCards();
    }
  }, [getMedicalCardsPhase, getMedicalCards]);

  const ActionButton = props => {
    const { ImgProps, text, ...rest } = props;

    return (
      <Grid item xs={isSideNav ? undefined : 6} style={ActionButtonStyle}>
        <Button className="client-nav-bar-btn" {...rest}>
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item>
              <img {...ImgProps} />
            </Grid>
            <Grid item>
              <Typography>{text}</Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    );
  };

  return (
    <Drawer
      className="client-nav-bar-drawer"
      anchor={isSideNav ? 'left' : 'bottom'}
      open={open}
      onClose={closeClientNavDrawer}
      onClick={closeClientNavDrawer}
    >
      <div style={{ ...ContainerStyle, ...(!isSideNav && { height: '100%' }) }}>
        <Grid container spacing={2} style={isSideNav ? GridSideNavStyle : GridNotSideNavStyle}>
          <ActionButton
            ImgProps={{
              src: 'img/button_add.png',
              alt: 'add-policy'
            }}
            text={intl.formatMessage({ id: 'Add Policy' })}
            onClick={addPolicy}
          />

          {hasMedicalCards ? (
            <ActionButton
              ImgProps={{
                src: 'img/button_medi.png',
                alt: 'my-profile'
              }}
              text={intl.formatMessage({ id: 'my-med-card' })}
              onClick={myMedicalCard}
            />
          ) : (
            <ActionButton
              ImgProps={{
                src: 'img/button_alarm.png',
                alt: 'reinder'
              }}
              text={intl.formatMessage({ id: 'Reminder' })}
              onClick={reinder}
            />
          )}

          {isConnected ? (
            <ActionButton
              ImgProps={{
                src: 'img/button_agent.png',
                alt: 'adviser'
              }}
              text={intl.formatMessage({ id: 'My Adviser' })}
              onClick={adviser}
            />
          ) : (
            <ActionButton
              ImgProps={{
                src: 'img/button_connect.png',
                alt: 'connect-with-adviser'
              }}
              text={intl.formatMessage({ id: 'connect-adv' })}
              onClick={connectWithAdviser}
            />
          )}

          {showFinHome ? (
            <ActionButton
              ImgProps={{
                src: 'img/finhome.png',
                alt: 'fin-home'
              }}
              text="理財一家"
              onClick={finHome}
            />
          ) : (
            <ActionButton
              ImgProps={{
                src: 'img/button_finance2.png',
                alt: 'consultation'
              }}
              text={intl.formatMessage({ id: 'consultation' })}
              onClick={consultation}
            />
          )}
        </Grid>
      </div>
    </Drawer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    isConnected: !isClientDisconnected(state.user.userDetails),
    hasMedicalCards: (state.medicalCard.medicalCards || []).length > 0,
    getMedicalCardsPhase: state.medicalCard.getMedicalCardsPhase
  }),
  {
    getMedicalCards
  }
)(ClientNavBarDrawer);

export default injectIntl(withRouter(container));
