import React, { PureComponent } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import './MyTabs.css';

class MyTabs extends PureComponent {
  render() {
    const { tabsClassName, value, onChange, tabLabels, disabled } = this.props;

    let widthCss = tabLabels !== undefined && tabLabels.length > 0 ? 100 / tabLabels.length + '%' : '0%';
    return (
      <Tabs
        className={'nav nav-tabs tabs-line text-center ' + (tabsClassName || '')}
        value={value}
        onChange={onChange}
        TabIndicatorProps={{
          style: {
            width: widthCss,
            backgroundColor: disabled ? 'white' : '#3966f8'
          }
        }}
        // classes={{ wrapper: disabled && 'set-color' , disabled: disabled &&'set-opacity' }}
      >
        {tabLabels !== undefined &&
          tabLabels.map((tabLabel, index) => (
            <Tab
              key={index}
              className="tab-item"
              label={tabLabel}
              style={{ width: widthCss }}
              classes={{ wrapper: disabled && 'set-color', disabled: disabled && 'set-opacity' }}
              disabled={disabled}
            />
          ))}
      </Tabs>
    );
  }
}

export default MyTabs;
