import React, { useState, Fragment, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import MyToolbar from '../../../../components/MyEditor/MyToolbar';
import MyEditor from '../../../../components/MyEditor/MyEditor';
import AlertPopUp from '../../../../components/AlertPopup';

const FullWidthStyle = {
  width: '100%'
};

const DefaultButtonStyle = {
  minHeight: 'unset',
  minWidth: 'unset',
  padding: '4px 8px',
  lineHeight: 1
};

const TextEditorInput = props => {
  const { intl, title, hint, value, onChange, onSetDefault } = props;

  const [warningDialog, setWarningDialog] = useState(false);
  const myEditorRef = useRef(null);
  const myToolbarRef = useRef(null);

  const openWarningDialog = () => setWarningDialog(true);
  const closeWarningDialog = () => setWarningDialog(false);
  const setDefault = () => {
    onSetDefault();
    closeWarningDialog();
  };

  return (
    <Fragment>
      <AlertPopUp
        intl={intl}
        open={warningDialog}
        handleClose={closeWarningDialog}
        handleUpdateUserProfile={setDefault}
        title={intl.formatMessage({ id: 'Confirm' })}
        description={intl.formatMessage({ id: 'adviser_message_delete' })}
        showUpdateButton={true}
      />

      <Grid container direction="column" alignItems="flex-start" spacing={1}>
        <Grid item style={FullWidthStyle}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className="field-label">{title}</Typography>
            </Grid>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={openWarningDialog} style={DefaultButtonStyle}>
                {intl.formatMessage({ id: 'Default' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {hint && (
          <Grid item>
            <Typography display="inline">{hint}</Typography>
          </Grid>
        )}
        <Grid item style={FullWidthStyle}>
          <div className="my-editor-container">
            <MyToolbar
              textAlign={{ left: true, center: true }}
              editorState={value}
              onChange={onChange}
              ref={myToolbarRef}
              editorRef={myEditorRef}
            />
            <MyEditor
              editorState={value}
              onChange={onChange}
              textAlign={true}
              ref={myEditorRef}
              toolbarRef={myToolbarRef}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default injectIntl(TextEditorInput);
