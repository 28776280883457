/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { formatPhoneNumber } from '../../../../../utils/user';

class ReferralSuccessModal extends React.Component {
  clientMessages(whatsApp) {
    const { intl } = this.props;
    let phoneNoText = whatsApp ? 'phone=' + whatsApp.replace('+', '') : '';
    let url = 'https://api.whatsapp.com/send?' + phoneNoText + `&text=${intl.formatMessage({ id: 'wts-ref-message' })}`;
    var win = window.open(encodeURI(url), '_blank');
    if (win) win.focus();
  }
  render() {
    const { intl, open, onCloseOnly, closeSuccessModal, val } = this.props;
    let addFbHTTPS = false;
    if (val && val.facebookLink) {
      if (val.facebookLink.indexOf('https') === -1) {
        addFbHTTPS = true;
      }
    }
    let addLiHTTPS = false;
    if (val && val.linkedinLink) {
      if (val.linkedinLink.indexOf('https') === -1) {
        addLiHTTPS = true;
      }
    }
    return (
      <Dialog
        open={open}
        onClose={closeSuccessModal}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        className="modalwidth-language"
      >
        <DialogContent>
          <div className="thanku-icon">
            <img alt="" src="img/referral_success.svg" width="125" height="125" />
            <p className="figureColor">
              {' '}
              {intl.formatMessage({ id: 'message-sent' })}
              {'!'}
            </p>
          </div>
          <p>{intl.formatMessage({ id: 'handle-asap' })}</p>
          <p>
            {intl.formatMessage({
              id: 'add-more-referral'
            })}
          </p>
          <div className="referral-adv-details-wrapper detailswrapper-mag">
            <div className="sociallists">
              <ul>
                <li className="right-width">
                  {val && val.whatsapp && val.whatsapp !== '' && (
                    <div className="referral-adv-details">
                      <div className="referral-adv-details-img">
                        <img alt="" src="img/whatsapp.svg" />
                      </div>
                      <div className="referral-adv-details-value">
                        <a className="login-link" onClick={this.clientMessages.bind(this, val.whatsapp)}>
                          {formatPhoneNumber(val.whatsapp)}
                        </a>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="clearfix"></div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button onClick={onCloseOnly} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
          <Button onClick={closeSuccessModal} color="primary">
            {intl.formatMessage({ id: 'enter-more' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReferralSuccessModal;
