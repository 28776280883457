import React, { useRef } from 'react';
import { connect } from 'react-redux';
import ProfilePicture from '@dsalvagni/react-profile-picture';
import '@dsalvagni/react-profile-picture/dist/ProfilePicture.css';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import Compressor from 'compressorjs';
import Backend from '../../../../../backend';
import './UploadAvatarDialog.scss';
import { dataUrlToBlob } from '../../../../../utils/download';
import { uploadAttachments } from '../../../../../store/media/api';
import { MEDIA_UPLOAD_TYPE } from '../../../../../constants/media';

const DEBUG = process.env.NODE_ENV === 'development';

const DialogContentStyle = { padding: '8px 4px' };

const UploadAvatarDialog = props => {
  const { intl, userDetails, open, onClose, onSuccess, ...rest } = props;

  const profilePictureRef = useRef(null);
  const userId = userDetails?._id;

  const submit = async () => {
    const profilePicture = profilePictureRef.current;
    if (profilePicture && userId) {
      const blob = dataUrlToBlob(profilePicture.getImageAsDataUrl(1));
      const filename = profilePicture?.state?.file?.name || 'avatar.jpeg';
      // console.log({ filename, profilePicture });

      await new Promise(resolve => {
        new Compressor(blob, {
          // convertSize: 0.2 * 1024 * 1024, // max size = 200 KB,
          quality: 1,
          maxWidth: 380,
          maxHeight: 2048,
          mimeType: 'image/jpeg',
          success: async compressedFile => {
            try {
              const attachmentsData = await uploadAttachments({
                resourceId: userId,
                attachments: [
                  {
                    filename: filename,
                    url: URL.createObjectURL(compressedFile)
                  }
                ],
                type: MEDIA_UPLOAD_TYPE.AVATAR
              });
              // console.log(attachmentsData);

              onSuccess(attachmentsData[0].url);
              resolve(attachmentsData[0].url);
            } catch (error) {
              console.error(error);
              resolve(false);
            }
          },
          error(compressError) {
            alert('An error occurred in compressing the image');
            console.error(compressError.message);
            resolve(false);
          }
        });
      });
    }
  };

  return (
    <Dialog
      className="upload-avatar-dialog"
      open={open}
      fullWidth={true}
      TransitionComponent={SlideTransition}
      {...rest}
    >
      <DialogTitle>{intl.formatMessage({ id: 'upload-avatar-dialog-title' })}</DialogTitle>
      <DialogContent style={DialogContentStyle}>
        <ProfilePicture
          ref={profilePictureRef}
          frameFormat="circle"
          useHelper={true}
          minImageSize={300}
          cropSize={300}
          debug={DEBUG}
          messages={{
            DEFAULT: intl.formatMessage({ id: 'upload-avatar-dialog-msg-1' }),
            DRAGOVER: intl.formatMessage({ id: 'upload-avatar-dialog-msg-2' }),
            INVALID_FILE_TYPE: intl.formatMessage({ id: 'upload-avatar-dialog-msg-3' }),
            INVALID_IMAGE_SIZE: intl.formatMessage({ id: 'upload-avatar-dialog-msg-4' })
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={submit}>
          {intl.formatMessage({ id: 'Submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(UploadAvatarDialog);

export default injectIntl(container);
