import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import FlipMove from 'react-flip-move';
import { Button, IconButton } from '@material-ui/core';
import TodoRow from './TodoRow/TodoRow';
import TodoEditor from './TodoEditor/TodoEditor';
import TodoMenu from './TodoMenu/TodoMenu';
import TodoDialog from './TodoDialog/TodoDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
// import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import StarIcon from '@material-ui/icons/Star';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import {
  updateAdviserTodo,
  addAdviserTodo,
  deleteAdviserTodo,
  deleteAllCompletedTodo,
  updateSort
} from '../../store/todoList/duck';
import NoteEditorModal from '../../views/Advisor/Dashboard/partials/business/NoteEditorModal/NoteEditorModal';
import './TodoList.scss';
import IconToast from '../NewToast';
import { toggleDialog } from '../../store/control/duck';
import { tracking } from '../../utils/tracking';

const WhiteboardPenButtonStyle = {
  position: 'absolute',
  height: 48,
  width: 48,
  right: 112
};

const styles = {
  root: {
    width: '100%',
    maxWidth: 300,
    wordWrap: 'break-word'
  },
  disabled: {
    fill: '#808080!important'
  },
  addBtn: {
    width: '100%',
    height: '50px',
    borderBottom: '1px solid #D3D3D3'
  }
};

class TodoList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.todos = React.createRef();
    //sort arrays
    const activeTodo = props.activeAdviserTodo ? props.activeAdviserTodo : [];
    this.sortArray(activeTodo, props.sort);
    const completedTodo = props.completedAdviserTodo ? props.completedAdviserTodo : [];
    this.sortArray(completedTodo, props.sort);
    const importantTodo = props.importantAdviserTodo ? props.importantAdviserTodo : [];
    this.sortArray(importantTodo, props.sort);

    this.state = {
      edit: false,
      activeTodo: activeTodo,
      completedTodo: completedTodo,
      importantTodo: importantTodo,
      dropDownAnchor: null,
      localSubmit: false,
      initialExpand: true,
      expandCompleted: true,
      showDeleteModal: false,
      focus: false,
      noteEditorDialog: false
    };
  }

  componentDidMount() {
    this.todos.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sort !== this.props.sort) {
      const activeTodo = this.props.activeAdviserTodo ? this.props.activeAdviserTodo : [];
      this.sortArray(activeTodo, this.props.sort);
      const completedTodo = this.props.completedAdviserTodo ? this.props.completedAdviserTodo : [];
      this.sortArray(completedTodo, this.props.sort);
      const importantTodo = this.props.importantAdviserTodo ? this.props.importantAdviserTodo : [];
      this.sortArray(importantTodo, this.props.sort);
      this.setState({
        dropDownAnchor: null,
        activeTodo,
        completedTodo,
        importantTodo
      });
    }
    if (prevProps.submit && !this.props.submit) {
      if (this.props.error) {
        toast.info(IconToast('error', this.props.intl.formatMessage({ id: 'todo-err-msg' })), {
          className: 'new-toast'
        });
        this.setState({ localSubmit: false });
      }
      const activeTodo = this.props.activeAdviserTodo ? this.props.activeAdviserTodo : [];
      this.sortArray(activeTodo, this.props.sort);
      const completedTodo = this.props.completedAdviserTodo ? this.props.completedAdviserTodo : [];
      this.sortArray(completedTodo, this.props.sort);
      const importantTodo = this.props.importantAdviserTodo ? this.props.importantAdviserTodo : [];
      this.sortArray(importantTodo, this.props.sort);
      this.setState({
        localSubmit: false,
        activeTodo,
        completedTodo,
        importantTodo,
        showDeleteModal: false,
        edit: this.state.localSubmit ? false : this.state.edit,
        focus: this.state.localSubmit ? false : this.state.focus
      });
    }
  }

  toggleTodoForm = () => {
    this.setState({ edit: !this.state.edit, focus: !this.state.focus });
  };

  sortByDueDateLatestToEarliest = array => {
    array.sort((a, b) => {
      let aDate = new Date(
        a.dueDate ? a.dueDate : !b.dueDate ? parseInt(a._id.toString().substring(0, 8), 16) * 1000 : 8640000000000000
      );
      let bDate = new Date(
        b.dueDate ? b.dueDate : !a.dueDate ? parseInt(b._id.toString().substring(0, 8), 16) * 1000 : 8640000000000000
      );
      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      }
      return 0;
    });
  };
  sortByCreationDateEarliestToLatest = array => {
    array.sort((a, b) => {
      let aDate = new Date(parseInt(a._id.toString().substring(0, 8), 16) * 1000);
      let bDate = new Date(parseInt(b._id.toString().substring(0, 8), 16) * 1000);
      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      }
      return 0;
    });
  };
  sortByCreationDateLatestToEarliest = array => {
    array.sort((a, b) => {
      let aDate = new Date(parseInt(a._id.toString().substring(0, 8), 16) * 1000);
      let bDate = new Date(parseInt(b._id.toString().substring(0, 8), 16) * 1000);
      if (aDate > bDate) {
        return -1;
      } else if (aDate < bDate) {
        return 1;
      }
      return 0;
    });
  };
  sortArray = (array, sort) => {
    if (sort === 'dueDate') {
      this.sortByDueDateLatestToEarliest(array);
    } else if (sort === 'creationDateNewToOld') {
      this.sortByCreationDateLatestToEarliest(array);
    } else {
      this.sortByCreationDateEarliestToLatest(array);
    }
  };

  //delete modal
  onModalClose = () => this.setState({ showDeleteModal: false });

  //editor callback
  onEditorSave = () => this.setState({ localSubmit: true });

  //Drop down menu methods
  openDropDown = e => this.setState({ dropDownAnchor: e.currentTarget });
  onDropDownClose = () => this.setState({ dropDownAnchor: null });

  openDeleteModal = () => this.setState({ showDeleteModal: true, dropDownAnchor: null });

  getRow = value => {
    return (
      <TodoRow
        key={value._id}
        todo={value}
        submit={this.props.submit}
        updateAdviserTodo={this.props.updateAdviserTodo}
        deleteAdviserTodo={this.props.deleteAdviserTodo}
      />
    );
  };

  toggleExpand = () => {
    if (this.state.initialExpand) {
      this.setState({ expandCompleted: !this.state.expandCompleted, initialExpand: false });
    } else {
      this.setState({ expandCompleted: !this.state.expandCompleted });
    }
  };

  openNotePad = () => {
    // Tracking
    tracking('Access Tools: My Notes');
    this.setState({ noteEditorDialog: true });
  };
  closeNotePad = () => this.setState({ noteEditorDialog: false });

  whiteboard = () => {
    const { toggleDialog } = this.props;
    toggleDialog('myWhiteBoard', {});
  };

  render() {
    return (
      <div className="todo">
        <TodoDialog
          showDeleteModal={this.state.showDeleteModal}
          onModalClose={this.onModalClose}
          deleteAllCompletedTodo={this.props.deleteAllCompletedTodo}
        />
        <NoteEditorModal open={this.state.noteEditorDialog} onClose={this.closeNotePad} />
        <div className="top-bar">
          <IconButton edge="start" onClick={this.props.close}>
            <CloseIcon />
          </IconButton>
          <IconButton onClick={this.whiteboard} style={WhiteboardPenButtonStyle}>
            <img alt="" src="img/financial-pyramid/whiteboardPen.png" width="100%" />
          </IconButton>
          <IconButton className="notepad" onClick={this.openNotePad}>
            <img alt="" src="img/mynotes.png" />
          </IconButton>
          <IconButton className="template-todo" onClick={this.props.openTemplate}>
            <img alt="" src="img/copybook.png" />
          </IconButton>
          <IconButton onClick={this.openDropDown}>
            <MoreVertIcon />
          </IconButton>
          <TodoMenu
            onDropDownClose={this.onDropDownClose}
            dropDownAnchor={this.state.dropDownAnchor}
            sort={this.props.sort}
            handleChangeSort={this.props.updateSort}
            openDeleteModal={this.openDeleteModal}
          />
        </div>
        <Collapse in={!this.state.edit}>
          <Button classes={{ root: this.props.classes.addBtn }} onClick={this.toggleTodoForm}>
            <div className="full-width">
              <AddCircleRoundedIcon className="add-btn" />
              {this.props.intl.formatMessage({ id: 'add-todo' })}
            </div>
          </Button>
        </Collapse>
        <Collapse in={this.state.edit}>
          <TodoEditor
            onSave={this.onEditorSave}
            todo={{ todo: '', dueDate: null, completed: false, important: false }}
            submit={this.state.localSubmit}
            closeEditor={this.toggleTodoForm}
            focus={this.state.focus}
            addAdviserTodo={this.props.addAdviserTodo}
          />
        </Collapse>
        <div className="list-container" ref={this.todos}>
          <FlipMove className={this.props.classes.root}>
            <div className="todo-title important-todo">
              <StarIcon className="checked" />
              <div className="important">{this.props.intl.formatMessage({ id: 'important-todo' })}</div>
            </div>
            {this.state.importantTodo.map(this.getRow)}
            <div className="todo-title">{this.props.intl.formatMessage({ id: 'active-todo' })}</div>
            {this.state.activeTodo.map(this.getRow)}

            <div className="todo-title">
              {this.props.intl.formatMessage({ id: 'completed-todo' })}
              <IconButton onClick={this.toggleExpand}>
                {
                  <ExpandLessIcon
                    //avoid initial transform
                    className={this.state.initialExpand ? '' : this.state.expandCompleted ? 'expandMore' : 'expandLess'}
                  />
                }
              </IconButton>
            </div>
            <Collapse in={this.state.expandCompleted}>
              <FlipMove className={this.props.classes.root}>{this.state.completedTodo.map(this.getRow)}</FlipMove>
            </Collapse>
          </FlipMove>
        </div>
      </div>
    );
  }
}

const container = withStyles(styles)(
  connect(
    // Map state to props
    state => ({
      activeAdviserTodo: state.user.adviserTodo.activeAdviserTodo,
      completedAdviserTodo: state.user.adviserTodo.completedAdviserTodo,
      importantAdviserTodo: state.user.adviserTodo.importantAdviserTodo,
      submit: state.todoList.isSubmitting,
      sort: state.todoList.sort,
      error: state.todoList.error
    }),
    // Map actions to props
    {
      updateAdviserTodo,
      addAdviserTodo,
      deleteAdviserTodo,
      deleteAllCompletedTodo,
      updateSort,
      toggleDialog
    },
    // mergeProps
    null,
    // options
    {}
  )(TodoList)
);

export default injectIntl(container);
