import { connect } from 'react-redux';
import {
  addPolicy,
  uploadDocument,
  getPortfolioJson,
  handleCategoryData,
  handleSubCategoryData,
  insurerInfo
} from '../../store/policy/duck';
import { handleSignOut } from '../../store/user/duck';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import AddPolicyView from './component';
import { injectIntl } from 'react-intl';

const AddPolicy = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    teamCampaignCodes: state.functions.campaignCodes,
    exchangeRate: state.user.exchangeRate,
    AddPortfolioData: state.policy.JsonData,
    policyPhase: state.policy.policyPhase,
    uploadPhase: state.policy.uploadPhase,
    categoryData: state.policy.categoryData,
    subCategoryData: state.policy.subCategoryData,
    insurers: state.insurer.insurers,
    newReferralCount: state.referral.newReferralCount,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    viewingClient: state.client.viewingClient,
    easyAddPolicy: state.control.easyAddPolicy,
    router: state.router
  }),
  // Map actions to props
  {
    addPolicy,
    uploadDocument,
    getPortfolioJson,
    handleCategoryData,
    handleSubCategoryData,
    handleSignOut,
    insurerInfo,
    renderHeaderButton
  }
)(AddPolicyView);

export default injectIntl(AddPolicy);
