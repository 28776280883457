import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, { useEffect, useRef, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

const CheckboxGridStyle = {
  textAlign: 'center'
};

const CheckboxStyle = {
  margin: 0
};

const ConfirmAmountDialog = props => {
  const { intl, teamInvitationExtension, open, subscriptionPlan, expiryDate, onConfirm } = props;

  const [internalExpiryDate, setInternalExpiryDate] = useState(expiryDate); // moment
  const [checked, setChecked] = useState(false);
  const isSet = useRef(false);
  const isTrial = !!subscriptionPlan?.isTrial;
  const isFreeTrial = !!subscriptionPlan?.isFreeTrial;
  const isAuthorization = !!subscriptionPlan?.isAuthorization;

  const handleCheckboxChange = event => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (expiryDate) {
      if (!isSet.current) {
        isSet.current = true;
        // need to add teamInvitationExtension month to expiryDate for team invitation
        if (!subscriptionPlan.isTrial) {
          setInternalExpiryDate(moment(expiryDate).add(teamInvitationExtension || 0, 'month'));
        } else {
          setInternalExpiryDate(moment(expiryDate));
        }
      }
    }
  }, [expiryDate, subscriptionPlan.isTrial, teamInvitationExtension]);

  if (!internalExpiryDate) {
    return null;
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{intl.formatMessage({ id: 'payment-confirm-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormattedMessage
              id={
                isFreeTrial
                  ? 'payment-confirm-dialog-content-free-trial'
                  : subscriptionPlan.interval === 'month'
                    ? 'payment-confirm-dialog-content-monthly-payment'
                    : isTrial
                      ? 'payment-confirm-dialog-content-trial'
                      : isAuthorization
                        ? 'payment-confirm-dialog-content-authorization'
                        : 'payment-confirm-dialog-content'
              }
              values={{
                expiryDate: <b>{
                  intl.locale === "zh-Hant-HK" || intl.locale === "zh" ?
                    internalExpiryDate.format('YYYY年M月D日') :
                    internalExpiryDate.format('DD MMM YYYY')
                }</b>
              }}
            />
          </Grid>
          <Grid item xs={12} style={CheckboxGridStyle}>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={checked} onChange={handleCheckboxChange} style={CheckboxStyle} />
              }
              label={intl.formatMessage({ id: 'understand' })}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" disabled={!checked} onClick={onConfirm}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    teamInvitationExtension: state.userSubscription.teamInvitationExtension
  }),
  {}
)(ConfirmAmountDialog);

export default injectIntl(container);
