import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import { ADD_ONS_OPTIONS } from '../../../../constants/policy';
import { getAddOnsIntlId } from '../../../../utils/policy';

const AddOnsInput = props => {
  const { intl, value, onChange } = props;

  return (
    <Grid container justify="space-between" alignItems="flex-start" spacing={0}>
      <Grid item xs={12}>
        <Typography color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
          {intl.formatMessage({ id: 'add-ons' })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SelectButtonGroup
          value={value}
          choices={Object.values(ADD_ONS_OPTIONS).map(choice => ({
            value: choice,
            label: intl.formatMessage({ id: getAddOnsIntlId(choice) }),
            xs: 6,
            fullWidth: true
          }))}
          onChange={onChange}
          justify="flex-end"
          buttonClassName="plan-type-select-button"
          multi={true}
        />
      </Grid>
    </Grid>
  );
};

export default injectIntl(AddOnsInput);
