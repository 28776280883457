import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PolicyView from './partials/policy';
import UserView from './partials/user';
import MySwipeableViews from '../../../components/MySwipeableViews/MySwipeableViews';
import MyTabs from '../../../components/MyTabs/MyTabs';
import './dashboard.scss';
import TopNavHelpButton from '../../../components/TopNavHelpButton/TopNavHelpButton';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import StickyHeader from '../../../components/StickyComponents/StickyHeader';
import { withRouter } from 'react-router';
import { TopNavigationPortal } from '../../../components/topNavigation';

const slideNameMapping = {
  Client: 0,
  Policy: 1
};
const inverseSlideNameMapping = ['Client', 'Policy'];

class AdvisorDashboardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'Client',
      data: [],
      lab: false
    };
    this.runGuideline = this.runGuideline.bind(this);
  }

  componentDidMount() {
    this.props.renderHeaderButton(true);
  }

  UNSAFE_componentWillMount() {
    const { getAdviserDashboard, getDuePolicy, getPaidUpPolicies, connectedUserPolicies } = this.props;
    getAdviserDashboard();
    getDuePolicy();
    getPaidUpPolicies();
    connectedUserPolicies();

    if (this.props.location.state === 'from_lab') {
      this.setState({ tab: 'Client', lab: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location } = this.props;
    if (location.pathname !== '/adviser_business') {
      if (this && this.mySwipeableViews) this.mySwipeableViews.container.current.updateHeight();
    }

    if (prevState.tab !== this.state.tab) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  runGuideline() {
    this.setState(
      {
        tab: 'Client'
      },
      () => {
        setTimeout(() => {
          this.props.runGuideline('advisorDashboard');
        }, 300);
      }
    );
  }

  render() {
    const { intl, bigDataSwipeable } = this.props;

    return (
      <AppContentContainer>
        <div className="adviser-dashboard">
          <TopNavigationPortal>
            <div className="plus-btn-client dashboard-guideline-icon">
              <TopNavHelpButton onSelectCurrentTutorial={this.runGuideline} buttonProps={{ notClientStyle: true }} />
            </div>
          </TopNavigationPortal>

          <div className="dashboard-wrapper">
            <StickyHeader>
              {this.state.lab ? (
                <ul className="nav nav-tabs tabs-line text-center" role="tablist">
                  <li role="presentation" className="active">
                    <Link to={{ pathname: 'advisorDashboard', state: 'from_lab' }}>
                      {intl.formatMessage({ id: 'Client' })}
                    </Link>
                  </li>
                </ul>
              ) : (
                <div>
                  <MyTabs
                    value={slideNameMapping[this.state.tab]}
                    onChange={(event, value) => this.setState({ tab: inverseSlideNameMapping[value] })}
                    tabLabels={[
                      //intl.formatMessage({ id: 'Business' }),
                      intl.formatMessage({ id: 'Client' }),
                      intl.formatMessage({ id: 'Policy' })
                    ]}
                  />
                </div>
              )}
            </StickyHeader>

            <MySwipeableViews
              ref={node => {
                this.mySwipeableViews = node;
              }}
              index={slideNameMapping[this.state.tab]}
              onChangeIndex={index => {
                this.setState({
                  tab: inverseSlideNameMapping[index]
                });
              }}
              disabled={!bigDataSwipeable}
            >
              <div className="adviser-dashboard-slide user-view-only">
                <UserView {...this.props} />
              </div>
              <div className="adviser-dashboard-slide">
                <PolicyView {...this.props} />
              </div>
            </MySwipeableViews>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

export default withRouter(AdvisorDashboardComponent);
