import React, { Fragment, useState, useEffect, useRef, memo } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import './AdviserInfoBottomButtons.scss';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import { connect } from 'react-redux';
import { increaseAdviserProfilePlusLike } from '../../../store/adviserProfilePlus/duck';
import { getSearchObject } from '../../../utils/router';
import { withRouter } from 'react-router';
import _ from 'lodash';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import { tracking } from '../../../utils/tracking';
import { formatCapacitorUrl } from '../../../utils/url';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LikeFab from './LikeFab/LikeFab';
import { isElementInViewport } from '../../../utils/dom';
import { usePrevious } from '../../../utils/hooks';
import ContactMeDialog from './ContactMeDialog/ContactMeDialog';
import { getReferralLink } from '../../../utils/referral';

const ContainerStyle = {
  padding: '0 12px',
  marginTop: '-12px',
};

const ButtonGridStyle = {
  flexGrow: 1,
};

const ButtonStyle = {
  background: 'var(--my-gradient)',
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  minWidth: 'unset',
  minHeight: 39,
};

const ContactButtonStyle = {
  ...ButtonStyle,
  width: '100%',
  borderRadius: 24,
};

const ShareButtonStyle = {
  ...ButtonStyle,
  width: 39,
  height: 39,
  borderRadius: '50%',
};

const ThumbUpIconStyle = {
  fontSize: '23px',
  verticalAlign: 'middle',
};

const LikeTypoStyle = {
  fontSize: '120%',
};

const CloseButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
};

const AdviserInfoBottomButtons = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    intl,
    style,
    adviserProfilePlus,
    triggerOpen,
    defaultMessage,
    fab,
    increaseAdviserProfilePlusLike,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [animToggle, setAnimToggle] = useState(false);
  const [shouldShowFab, setShouldShowFab] = useState(false);
  const [defaultRemark, setDefaultRemark] = useState(undefined);
  const mainGroupRef = useRef(null);
  const prevDefaultMessage = usePrevious(defaultMessage);

  const { adviserInfo, likes } = adviserProfilePlus || {};
  const adviserId = _.get(adviserInfo, '_id');

  const openDialog = () => {
    tracking('User Click on Like - My Adviser Profile+');
    increaseAdviserProfilePlusLike(adviserId);
    setOpen(true);
  };
  const closeDialog = () => setOpen(false);
  const openShareDialog = () => {
    tracking('User Click on Share Adviser Contact - My Adviser Profile+');
    setShareDialogOpen(true);
  };
  const closeShareDialog = () => setShareDialogOpen(false);
  const triggerAnim = () => setAnimToggle(!animToggle);
  const closeContactMeCard = () => {
    closeDialog();

    const target = getReferralLink({ targetAdviser: adviserInfo, isInternalRoute: true });
    if (history.location.pathname !== target) {
      history.push(target);
    }
  };

  useEffect(() => {
    setOpen(triggerOpen);
  }, [triggerOpen]);

  useEffect(() => {
    if (fab) {
      if (mainGroupRef.current) {
        setShouldShowFab(!isElementInViewport(mainGroupRef.current));
      }
    }
  }, [fab]);

  useEffect(() => {
    if (defaultMessage !== prevDefaultMessage) {
      setDefaultRemark(defaultMessage);
    }
  }, [defaultMessage, prevDefaultMessage]);

  return (
    <Fragment>
      {shouldShowFab && <LikeFab onClick={openDialog} />}
      <Grid container alignItems="center" spacing={2} style={ContainerStyle} ref={mainGroupRef}>
        <Grid item style={ButtonGridStyle}>
          <Button
            className="find-me"
            variant="contained"
            color="primary"
            style={{ ...ContactButtonStyle, ...style }}
            onClick={openDialog}
            {...rest}
          >
            <Grid container alignItems="flex-end" justify="center" spacing={1}>
              <Grid item>
                <Typography style={LikeTypoStyle}>{intl.formatMessage({ id: 'like' })}</Typography>
              </Grid>
              <Grid item>
                <ThumbUpRoundedIcon style={ThumbUpIconStyle} />
              </Grid>
              <Grid item>
                <Typography style={LikeTypoStyle}>{likes || 0}</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" style={ShareButtonStyle} onClick={openShareDialog}>
            <ShareRoundedIcon />
          </Button>
        </Grid>
      </Grid>

      <ContactMeDialog
        className="adviser-info-find-me-dialog"
        open={open}
        onClose={closeDialog}
        onSuccess={closeContactMeCard}
        TransitionProps={{
          onEntered: triggerAnim,
        }}
        animToggle={animToggle}
      />

      <Dialog
        open={shareDialogOpen}
        onClose={closeShareDialog}
        fullWidth={true}
        maxWidth="xs"
        TransitionComponent={SlideTransition}
      >
        <DialogContent>
          <ShareButtonGroup
            title={intl.formatMessage({ id: 'adv-info-share-msg' })}
            url={getReferralLink({ targetAdviser: adviserInfo })}
            isCTA
            CopyTextFieldProps={{
              multiline: true,
            }}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

AdviserInfoBottomButtons.whyDidYouRender = true;

const container = connect(
  (state) => ({
    adviserProfilePlus: state.adviserProfilePlus.adviserProfilePlus,
  }),
  {
    increaseAdviserProfilePlusLike,
  },
)(AdviserInfoBottomButtons);

export default memo(withRouter(injectIntl(container)));
