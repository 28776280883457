import { fetch } from '../../utils';
import Backend from '../../backend';
import qs from 'qs';

//TO GET THE DATA ANALYSIS CHART
export const getAnalysisData = ({ userId, ...rest }) => {
  return fetch(`${Backend.methodUrl('getAnalysisData')}/${userId}?${qs.stringify(rest)}`, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
