import { connect } from 'react-redux';
import { updateUserDetails, getUser, handleSignOut, initPhase } from '../../store/user/duck';
import { getAdvisorMessage } from '../../store/referral/duck';
import UserDetailsView from './component';
import { selectedLocale } from '../../store/locale/action';
import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';

const UserDetails = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    updatePhase: state.user.updatePhase,
    advisorMessage: state.referral.advisorMessage,
    advisorMessagePhase: state.referral.advisorMessagePhase,
    newReferralCount: state.referral.newReferralCount,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    getUserPhase: state.user.getUserPhase,
    updateMessageExtras: state.user.updateMessageExtras,
    message: state.user.message
  }),
  // Map actions to props
  {
    updateUserDetails,
    getUser,
    getAdvisorMessage,
    handleSignOut,
    initPhase,
    selectedLocale,
    renderHeaderButton
  }
)(UserDetailsView);

export default injectIntl(UserDetails);
