import React from 'react';
import _ from 'lodash';

const AdviserInfoPageCardBackground2 = props => {
  const { style, ...rest } = props;

  return (
    <div
      style={_.merge(
        {
          position: 'absolute',
          width: 'calc(100% + 48px)',
          height: '100%',
          zIndex: 0,
          opacity: 1,
          top: -10,
          left: -26,
          backgroundSize: '280px auto',
          backgroundRepeat: 'no-repeat'
        },
        style
      )}
      {...rest}
    />
  );
};

export default AdviserInfoPageCardBackground2;
