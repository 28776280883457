import React from 'react';
import TodoEditor from '../TodoEditor/TodoEditor';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Checkbox, IconButton } from '@material-ui/core';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import './TodoRow.scss';

const styles = {
  ListItemDisabled: {
    backgroundColor: '#80808022',
    '&:hover': {
      '& $child3': {
        visibility: 'visible!important'
      }
    }
  },
  ListItem: {
    '&:hover': {
      backgroundColor: '#80808022',
      '& $child3': {
        visibility: 'visible!important'
      }
    }
  },
  child3: {
    visibility: 'hidden'
  }
};

class TodoRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      localSubmit: false,
      focus: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submit && !this.props.submit && this.state.localSubmit) {
      this.setState({ localSubmit: false, edit: false, focus: false });
    }
  }

  handleCheckboxClick = () => {
    this.setState({ localSubmit: true });
    this.props.updateAdviserTodo({ todo: { ...this.props.todo, completed: !this.props.todo.completed } });
  };

  toggleEditor = () => {
    this.setState({ edit: !this.state.edit, focus: !this.state.focus });
  };
  onDelete = () => {
    this.setState({ localSubmit: true });
    this.props.deleteAdviserTodo({ todoId: this.props.todo._id.toString() });
  };
  onSave = () => this.setState({ localSubmit: true });
  isOverDue = date => {
    if (!date) {
      return false;
    }
    //normalize dates and disregard hours
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return date <= now;
  };

  render() {
    const value = this.props.todo;
    const showExclamation = !value.completed && this.isOverDue(value.dueDate);
    return (
      <div>
        <Collapse in={!this.state.edit}>
          <ListItem
            key={value._id.toString()}
            dense
            divider
            classes={{ container: value.completed ? this.props.classes.ListItemDisabled : this.props.classes.ListItem }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                onChange={this.handleCheckboxClick}
                disabled={this.props.submit}
                checked={value.completed}
                checkedIcon={<CheckBoxIcon className={value.completed ? 'checkbox-color' : ''} />}
                inputProps={{ 'aria-labelledby': value._id.toString() }}
              />
            </ListItemIcon>
            <ListItemText
              className="todo-and-date"
              primary={<span className={'todo-text' + (value.completed ? ' strike-through' : '')}>{value.todo}</span>}
              secondary={
                <span className={'date-row' + (value.completed ? ' strike-through' : '')}>
                  {showExclamation && <PriorityHighIcon className="exclamation" />}
                  <span className={showExclamation ? 'with-exclamation' : ''}>
                    {value.dueDate ? moment(new Date(value.dueDate)).format('DD-MMM-YYYY') : ''}
                  </span>
                </span>
              }
            />
            <ListItemSecondaryAction classes={{ root: this.props.classes.child3 }}>
              {!value.completed && (
                <IconButton edge="end" aria-label="edit" disabled={this.state.localSubmit} onClick={this.toggleEditor}>
                  <EditIcon />
                </IconButton>
              )}
              {value.completed && (
                <IconButton edge="end" aria-label="delete" disabled={this.state.localSubmit} onClick={this.onDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </Collapse>
        <Collapse in={this.state.edit}>
          <TodoEditor
            todo={value}
            submit={this.state.localSubmit}
            onSave={this.onSave}
            onDelete={this.onDelete}
            closeEditor={this.toggleEditor}
            updateAdviserTodo={this.props.updateAdviserTodo}
            deleteAdviserTodo={this.props.deleteAdviserTodo}
            focus={this.state.focus}
          />
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(TodoRow);
