import React, { PureComponent } from 'react';
import BusinessView from './partials/Progress';
import MyTabs from '../../../components/MyTabs/MyTabs';
import * as Exchange from '../../../utils/currencyExchange';
import SwipeableViews from 'react-swipeable-views';
import './component.scss';
import ClientEntryDialogs from '../../../components/ClientEntry/ClientEntryDialogs';
import Assistant from './partials/Assistant';
import _ from 'lodash';
import { addSearch } from '../../../utils/router';
import SettingsIcon from '@material-ui/icons/Settings';
import { isSubscriptionAllowAction } from '../../../utils/user-subscription-store';
import TopNavHelpButton from '../../../components/TopNavHelpButton/TopNavHelpButton';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import StickyHeader from '../../../components/StickyComponents/StickyHeader';
import { TopNavigationPortal } from '../../../components/topNavigation';

// const WIDTHTIGGERPOINT = 1340;
const ASSISTANT_WIDTH_TRIGGER_POINT = 1024;

class BusinessDashboardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      enableMouseEvents: true,
      businessTabIndex: 0,
      assistantCategoryIndex: 0,
      width: window.innerWidth
    };
    this.runGuideline = this.runGuideline.bind(this);
    this.changeChildSwipeIndex = this.changeChildSwipeIndex.bind(this);
    this.unmount = false;
  }

  updateUrl = () => {
    const { history } = this.props;
    addSearch(history, _.pick(this.state, ['businessTabIndex', 'assistantCategoryIndex']));
  };

  async UNSAFE_componentWillMount() {
    const { location } = this.props;

    const stateObj = {};

    //this.props.defaultMessages();

    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      const businessTabIndexParam = searchParams.get('businessTabIndex');
      const assistantCategoryIndexParam = searchParams.get('assistantCategoryIndex');
      if (businessTabIndexParam) {
        stateObj.businessTabIndex = parseInt(businessTabIndexParam);
        // if it is at assistant tab, check subscription
        if (stateObj.businessTabIndex === 1) {
          isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] });
        }
      }
      if (assistantCategoryIndexParam) {
        stateObj.assistantCategoryIndex = parseInt(assistantCategoryIndexParam);
      }
    }

    if (!this.unmount) {
      this.setState(stateObj);

      //Remove the localstorage keys when edit cleint by assistant.
      let keysToRemove = [
        'clientId',
        'arrowRotateClient',
        'currentPage',
        'searchTerm',
        'clientState',
        'selectedLuckyClientObj',
        'preSelectedClientIds',
        'clientPersonalData',
        'clientFactsheetDataOne',
        'clientFactsheetDataTwo',
        'clientFactsheetDataThree'
      ];
      keysToRemove.forEach(k => localStorage.removeItem(k));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    Exchange.getCurrencyExchangeRate(nextProps.exchangeRate);
  }

  runGuideline() {
    this.props.runGuideline('adviserBusiness');
  }

  componentWillUnmount() {
    this.unmount = true;
    this.props.endGuideline();
    //if(this.state.businessTabIndex === 'Assistant'){
    var body = document.body;
    body.classList.remove('assistant-body');
    window.removeEventListener('resize', this.handleResize, false);
    //}
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize, false);
    this.props.renderHeaderButton(true);
    this.handleResize();
  }

  handleResize = () => {
    const width = window.innerWidth;
    if (width !== this.state.width) {
      // if (width >= WIDTHTIGGERPOINT) {
      //   if (this.state.businessTabIndex === 1) {
      //     this.setState({
      //       businessTabIndex: 0
      //     });
      //   }
      // }
      if (width >= ASSISTANT_WIDTH_TRIGGER_POINT) {
        if (this.state.assistantCategoryIndex <= 2) this.changeChildSwipeIndex(0);
        if (this.state.assistantCategoryIndex >= 3) this.changeChildSwipeIndex(1);
      }
      this.setState({ width: width });
    }

    const height = window.innerHeight - 45;
    const mainSwipeViewContainerElem = document.querySelector('.main__swipe__view .react-swipeable-view-container');
    const swipeViewInnerContainerElem = document.querySelector('.swipe__view__inner .react-swipeable-view-container');

    if (mainSwipeViewContainerElem) {
      mainSwipeViewContainerElem.style.height = `${height}px`;
    }

    if (swipeViewInnerContainerElem) {
      swipeViewInnerContainerElem.style.height = `${height}px`;
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //window.scrollTo(0, 0);
    const { businessTabIndex } = this.state;
    const body = document.body;
    if (businessTabIndex === 1 && window.width < ASSISTANT_WIDTH_TRIGGER_POINT) {
      body.classList.add('assistant-body');
    } else {
      body.classList.remove('assistant-body');
    }
    // let outerHeight = businessTabIndex === 0 ? 1000 : $(window).height() - 135;
    // if (businessTabIndex === 0) {
    //   $('.main__swipe__view .react-swipeable-view-container').css({ overflowY: 'hidden' });
    // }
  }

  handleFunnelOnClick = e => {
    // if (this.state.width < ASSISTANT_WIDTH_TRIGGER_POINT) {
    this.changeBusinessTabIndex(1);
    this.changeChildSwipeIndex(e.dataIndex + (this.state.width < ASSISTANT_WIDTH_TRIGGER_POINT ? 1 : 0));
    // } else {
    // if (this.state.width < WIDTHTIGGERPOINT) {
    //   this.changeBusinessTabIndex(1);
    // }
    // this.changeChildSwipeIndex(e.dataIndex);
    // }
  };

  //Enable to mouse event when any popup is open
  handleEnableMouseEvents(status) {
    this.setState({ enableMouseEvents: status });
  }

  changeBusinessTabIndex = index => {
    // if it is at assistant tab, check subscription
    if (index === 1) {
      isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] });
    }
    this.setState({ businessTabIndex: index }, () => this.updateUrl());
  };

  changeChildSwipeIndex = index => {
    this.setState({ assistantCategoryIndex: index }, () => this.updateUrl());
  };

  customizeAssistant = event => {
    if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) {
      return;
    }
    event.stopPropagation();
    this.props.toggleDialog('assistantCustomize', {});
  };

  render() {
    const { intl } = this.props;

    const { businessTabIndex, assistantCategoryIndex } = this.state;

    return (
      <div>
        <TopNavigationPortal>
          <div className="plus-btn-client dashboard-guideline-icon">
            <TopNavHelpButton onSelectCurrentTutorial={this.runGuideline} buttonProps={{ notClientStyle: true }} />
          </div>
        </TopNavigationPortal>
        <ClientEntryDialogs />

        <AppContentContainer>
          <div style={{ width: '100%' }}>
            <StickyHeader>
              <MyTabs
                value={businessTabIndex}
                onChange={(event, value) => this.changeBusinessTabIndex(value)}
                tabLabels={[
                  <div>{intl.formatMessage({ id: 'Progress' })}</div>,
                  <div>
                    {intl.formatMessage({ id: 'Assistant' })}
                    <SettingsIcon
                      style={{ position: 'absolute', top: '6px', right: '6px' }}
                      onClick={this.customizeAssistant}
                    />
                  </div>
                ]}
              />
            </StickyHeader>

            <div
              id="page-content-wrapper"
              className={
                'dashboard-wrapper dashboard-no-padding' + (businessTabIndex === 1 ? ' pagecontentwrapper' : '')
              }
            >
              <div
                className={
                  businessTabIndex === 1
                    ? 'business__tab business__tab--new opportunityheight assistant__swipe__view'
                    : 'business__tab business__tab--new progress__view'
                }
              >
                <div className="row custom__tabs__swipe">
                  <div>
                    <SwipeableViews
                      enableMouseEvents={false}
                      index={businessTabIndex}
                      onChangeIndex={index => {
                        this.changeBusinessTabIndex(index);
                      }}
                      className="main__swipe__view business"
                      // OpportunityViewHeight={businessTabIndex === 'Assistant' ? 55 : 0}
                      disableLazyLoading={true}
                      ignoreNativeScroll={true}
                    >
                      <div className="adviser-dashboard-slide adviserdashboardslideEdit">
                        <BusinessView
                          {...this.props}
                          width={this.state.width}
                          // trigger={WIDTHTIGGERPOINT}
                          handleEnableMouseEvents={this.handleEnableMouseEvents.bind(this)}
                          handleFunnelOnClick={this.handleFunnelOnClick}
                        />
                      </div>
                      <div className="adviser-dashboard-slide adviseropportunityEdit htautoEdit">
                        <div className="reactswipeableviewcontainer">
                          <Assistant
                            assistantCategoryIndex={assistantCategoryIndex}
                            onChangeTab={this.changeChildSwipeIndex}
                          />
                        </div>
                      </div>
                    </SwipeableViews>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppContentContainer>
      </div>
    );
  }
}

export default BusinessDashboardComponent;
