import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DatePicker } from '@material-ui/pickers';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import _ from 'lodash';
import moment from 'moment';
import { updateAdviserBusiness } from '../../../../../../store/adviserDashboard/duck';

const QuarterEndDateDialog = props => {
  const { intl, adviserBusiness, onClose, updateAdviserBusiness, ...rest } = props;

  const abQuarterEndDate = _.get(adviserBusiness, 'quarterEndDate');
  const abBigDate = _.get(adviserBusiness, 'bigDate');
  const [quarterEndDate, setQuarterEndDate] = useState(abQuarterEndDate ? moment.parseZone(abQuarterEndDate) : null);
  const [bigDate, setBigDate] = useState(abBigDate ? moment.parseZone(abBigDate) : null);

  const currentMonth = parseInt(moment().format('MM'));
  const month = currentMonth <= 3 ? 3 : currentMonth <= 6 ? 6 : currentMonth <= 9 ? 9 : currentMonth <= 12 ? 12 : 12;
  const today = new Date();
  const year = today.getFullYear();
  const day = 0;
  const minDate = new Date(
    moment(today)
      .add(1, 'days')
      .format('YYYY-MM-DD')
  );
  const maxDate = new Date(moment(new Date(year, month, day)).format('YYYY-MM-DD'));

  const onChangeQuarterEndDate = date => {
    if (date) {
      const qdate = date ? moment(date).format('YYYY-MM-DD') : null;
      const qdate1 = date ? moment.utc(qdate).format('YYYY-MM-DDT00:00:00.00Z') : null;
      setQuarterEndDate(qdate1);
    } else {
      setQuarterEndDate(null);
    }
  };

  const onChangeBigDate = date => {
    if (date) {
      const d = date ? moment(date).format('YYYY-MM-DD') : null;
      const date1 = d ? moment.utc(d).format('YYYY-MM-DDT00:00:00.00Z') : null;
      setBigDate(date1);
    } else {
      setBigDate(null);
    }
  };

  const updateQuarterDate = () => {
    updateAdviserBusiness({ quarterEndDate, bigDate });
    onClose();
  };

  useEffect(() => {
    setQuarterEndDate(abQuarterEndDate ? moment.parseZone(abQuarterEndDate) : null);
    setBigDate(abBigDate ? moment.parseZone(abBigDate) : null);
  }, [adviserBusiness]);

  return (
    <Dialog TransitionComponent={SlideTransition} fullWidth={true} keepMounted onClose={onClose} {...rest}>
      <DialogTitle> {intl.formatMessage({ id: 'Date Setting' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'dateSetting_desc' })}</DialogContentText>
        <div className="caseclose-form">
          <div className="form-group" style={{ marginBottom: '-15px' }}>
            <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key padding__left-0">
              <label className="control-label adv_biz_modal_key">{intl.formatMessage({ id: 'Quarter End' })} :</label>
            </div>
            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
              <div className="modal-text">
                <DatePicker
                  name="quarterEndDate"
                  clearable={true}
                  format="DD-MMM-YYYY"
                  className="case-datepicker close-case-date case-open-datepicker input__spacing qEnd--Date"
                  minDate={minDate}
                  maxDate={maxDate}
                  value={quarterEndDate}
                  onChange={onChangeQuarterEndDate}
                  autoOk={true}
                  invalidDateMessage={null}
                  minDateMessage={null}
                  maxDateMessage={null}
                />
              </div>
            </div>
          </div>
          <div className="form-group" style={{ marginBottom: '0px' }}>
            <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
              <label className="control-label adv_biz_modal_key">{intl.formatMessage({ id: 'Big Date' })} :</label>
            </div>
            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
              <div className="modal-text">
                <DatePicker
                  name="bigDate"
                  clearable={true}
                  format="DD-MMM-YYYY"
                  className="case-datepicker close-case-date case-open-datepicker input__spacing qEnd--Date"
                  value={bigDate}
                  onChange={onChangeBigDate}
                  autoOk={true}
                  invalidDateMessage={null}
                  minDateMessage={null}
                  maxDateMessage={null}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage({ id: 'Close' })}
        </Button>
        <Button onClick={updateQuarterDate} color="primary">
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    adviserBusiness: state.adviserDashboard.adviserBusiness
  }),
  {
    updateAdviserBusiness
  }
)(QuarterEndDateDialog);

export default injectIntl(container);
