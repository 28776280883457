import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Prompt } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DialogContent } from '@material-ui/core';
import withRouter from 'react-router/withRouter';

class BackConfirmDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      backConfirmModal: false,
      goBackConfirmed: false
    };
  }

  render() {
    const { intl, history, content } = this.props;
    const { backConfirmModal, goBackConfirmed } = this.state;

    return (
      <Fragment>
        <Prompt
          when={true}
          message={(location, action) => {
            if (location.pathname !== history.location.pathname) {
              if (!goBackConfirmed) {
                this.setState({ backConfirmModal: true });
                return false;
              }
            }
            return true;
          }}
        />
        <Dialog open={backConfirmModal}>
          <DialogTitle>{intl.formatMessage({ id: 'go-back-dialog-title' })}</DialogTitle>
          {content && <DialogContent>{content}</DialogContent>}
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ backConfirmModal: false, goBackConfirmed: false })}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button
              color="primary"
              onClick={() =>
                this.setState(
                  {
                    backConfirmModal: false,
                    goBackConfirmed: true
                  },
                  () => history.goBack()
                )
              }
            >
              {intl.formatMessage({ id: 'Confirm' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(withRouter(BackConfirmDialog));

export default injectIntl(container);
