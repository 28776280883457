import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { TextZoom } from '@capacitor/text-zoom';
import { AppLauncher } from '@capacitor/app-launcher';
import * as Badge from './badge';
import * as Biometric from './biometric';
import * as CodePush from './code-push';
import * as Copy from './copy';
import * as Download from './download';
import * as Geolocation from './geolocation';
import * as Http from './http';
import * as Icon from './icon';
import * as Network from './network';
import * as Notification from './notification';
import * as PushNotification from './push-notification';
import * as Share from './share';
import * as Preferences from './preferences';
import * as VoiceRecorder from './voice-recorder';
import * as WalletPass from './wallet-pass';
import { processInternalRoute } from './utils';
import { formatEmail } from '../email';

// NFC Launch, Android only
import { NFCLaunch } from 'capacitor-nfc-launch';

const USER_EMAIL_STORAGE_KEY = 'userEmail';
const USER_NAME_STORAGE_KEY = 'userName';

if (Capacitor.isNativePlatform()) {
  // Do not respect to accessibility's font size
  TextZoom.set({ value: 1 })
    .then()
    .catch();
}

// App.addListener('appStateChange', state => {
//   // state.isActive contains the active state
//   if (state.isActive) {
//     if (process.env.DISABLE_NATIVE_UPDATE !== 'true') {
//       if (Capacitor.isNativePlatform()) {
//         console.log('App in foreground: OTA Update');
//         codePush
//           .notifyApplicationReady()
//           .then(async () => {
//             const result = await CodePush.checkForOTAUpdate();
//             if (result) {
//               store.dispatch(toggleDialog('otaUpdate', {}));
//             }
//           })
//           .catch(console.log);
//       }
//     }
//   }
// });

const isNativePlatform = () => Capacitor.isNativePlatform();
const getPlatform = () => Capacitor.getPlatform();
const getAppInfo = () => App.getInfo();
const isIOSPlatform = () => Capacitor.getPlatform() === 'ios';
const shouldShowEnterpriseDialog = userDetails => {
  if (isIOSPlatform()) {
    if (userDetails && userDetails.email) {
      const formattedEmail = formatEmail(userDetails.email);
      if (formattedEmail === 'pfp.kyc@googlemail.com' || formattedEmail === 'pfpky.c@googlemail.com') {
        return true;
      }
    }
  }

  return false;
};
const shouldEnterpriseDialogBlock = userDetails => {
  if (isIOSPlatform()) {
    if (userDetails && userDetails.email) {
      const formattedEmail = formatEmail(userDetails.email);
      if (formattedEmail === 'pfpky.c@googlemail.com') {
        return true;
      }
    }
  }

  return false;
};

const addDeepLinkListener = history => {
  App.addListener('appUrlOpen', event => {
    processInternalRoute(event.url);
  });
};

const addNFCListener = histroy => {
  // Due to appUrlOpen is for link click and not for NFC
  // We need to add NFC listener here

  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
    NFCLaunch.addListener('message', (data) => {
      const eotRegExp = new RegExp('\u0004', 'g');
      const cleanedData = data.message.replace(eotRegExp, '');
      console.log('NFC message processed:', cleanedData);
      try {
        if (cleanedData &&
          cleanedData.startsWith("app.portfoplus.com") ||
          cleanedData.startsWith("beta-app.portfoplus.com")) {
          processInternalRoute(cleanedData);
        }
      } catch (error) {
        console.error('Error processing NFC message: ', error);
      }
    });
  }
}


const addBackListener = history => {
  App.addListener('backButton', data => {
    history.goBack();
  });
};

const saveUserEmail = async email => {
  if (email) {
    if (isNativePlatform()) {
      return Preferences.saveStorage(USER_EMAIL_STORAGE_KEY, email);
    } else {
      return undefined;
    }
  }
};

const getUserEmail = async () => {
  if (isNativePlatform()) {
    return Preferences.getStorage(USER_EMAIL_STORAGE_KEY);
  } else {
    return undefined;
  }
};

const removeUserEmail = async () => {
  if (isNativePlatform()) {
    return Preferences.removeStorage(USER_EMAIL_STORAGE_KEY);
  } else {
    return undefined;
  }
};

const saveUserName = async name => {
  if (name) {
    if (isNativePlatform()) {
      return Preferences.saveStorage(USER_NAME_STORAGE_KEY, name);
    } else {
      return undefined;
    }
  }
};

const getUserName = async () => {
  if (isNativePlatform()) {
    return Preferences.getStorage(USER_NAME_STORAGE_KEY);
  } else {
    return undefined;
  }
};

const removeUserName = async () => {
  if (isNativePlatform()) {
    return Preferences.removeStorage(USER_NAME_STORAGE_KEY);
  } else {
    return undefined;
  }
};

const NativeOrWeb = {
  addNFCListener,
  addDeepLinkListener,
  addBackListener,
  isNativePlatform,
  getPlatform,
  getAppInfo,
  isIOSPlatform,
  shouldShowEnterpriseDialog,
  shouldEnterpriseDialogBlock,
  saveUserName,
  getUserName,
  removeUserName,
  saveUserEmail,
  getUserEmail,
  removeUserEmail,
  canOpenUrl: AppLauncher.canOpenUrl,
  openUrl: AppLauncher.openUrl,
  ...Badge,
  ...Biometric,
  ...CodePush,
  ...Copy,
  ...Download,
  ...Geolocation,
  ...Http,
  ...Icon,
  ...Network,
  ...Notification,
  ...PushNotification,
  ...Share,
  ...Preferences,
  ...VoiceRecorder,
  ...WalletPass
};

export default NativeOrWeb;
