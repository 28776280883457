import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
import _ from 'lodash';

/***********************************
 * Action Types
 ***********/

export const SHARE_DOCUMENTS = 'portfoplus/share/SHARE_DOCUMENTS';
export const SHARE_DOCUMENTS_SUCCESS = 'portfoplus/share/SHARE_DOCUMENTS_SUCCESS';
export const SHARE_DOCUMENTS_ERROR = 'portfoplus/share/SHARE_DOCUMENTS_ERROR';

export const GET_SHARE_DOCUMENTS = 'portfoplus/share/GET_SHARE_DOCUMENTS';
export const GET_SHARE_DOCUMENTS_SUCCESS = 'portfoplus/share/GET_SHARE_DOCUMENTS_SUCCESS';
export const GET_SHARE_DOCUMENTS_ERROR = 'portfoplus/share/GET_SHARE_DOCUMENTS_ERROR';

export const GET_SHARE_ME_DOCUMENTS = 'portfoplus/share/GET_SHARE_ME_DOCUMENTS';
export const GET_SHARE_ME_DOCUMENTS_SUCCESS = 'portfoplus/share/GET_SHARE_ME_DOCUMENTS_SUCCESS';
export const GET_SHARE_ME_DOCUMENTS_ERROR = 'portfoplus/share/GET_SHARE_ME_DOCUMENTS_ERROR';

// export const GET_DOCUMENTS = 'portfoplus/share/GET_DOCUMENTS';
// export const GET_DOCUMENTS_SUCCESS =
//   'portfoplus/share/GET_DOCUMENTS_SUCCESS';
// export const GET_DOCUMENTS_ERROR =
//   'portfoplus/share/GET_DOCUMENTS_ERROR';

export const GET_SHARE_TO_DATA = 'portfoplus/share/GET_SHARE_TO_DATA';
export const GET_SHARE_TO_DATA_SUCCESS = 'portfoplus/share/GET_SHARE_TO_DATA_SUCCESS';
export const GET_SHARE_TO_DATA_ERROR = 'portfoplus/share/GET_SHARE_TO_DATA_ERROR';

export const GET_SHARE_FROM_DATA = 'portfoplus/share/GET_SHARE_FROM_DATA';
export const GET_SHARE_FROM_DATA_SUCCESS = 'portfoplus/share/GET_SHARE_FROM_DATA_SUCCESS';
export const GET_SHARE_FROM_DATA_ERROR = 'portfoplus/share/GET_SHARE_FROM_DATA_ERROR';

export const UPDATE_SHARE_TO_LIST = 'portfoplus/share/UPDATE_SHARE_TO_LIST';
export const UPDATE_SHARE_TO_LIST_SUCCESS = 'portfoplus/share/UPDATE_SHARE_TO_LIST_SUCCESS';
export const UPDATE_SHARE_TO_LIST_ERROR = 'portfoplus/share/UPDATE_SHARE_TO_LIST_ERROR';

export const UPDATE_SHARE_DETAILS = 'portfoplus/share/UPDATE_SHARE_DETAILS';
export const UPDATE_SHARE_DETAILS_SUCCESS = 'portfoplus/share/UPDATE_SHARE_DETAILS_SUCCESS';
export const UPDATE_SHARE_DETAILS_ERROR = 'portfoplus/share/UPDATE_SHARE_DETAILS_ERROR';

export const DELETE_SHARE_LIST = 'portfoplus/share/DELETE_SHARE_LIST';
export const DELETE_SHARE_LIST_SUCCESS = 'portfoplus/share/DELETE_SHARE_LIST_SUCCESS';
export const DELETE_SHARE_LIST_ERROR = 'portfoplus/share/DELETE_SHARE_LIST_ERROR';

export const DELETE_SHARE_FROM_LIST = 'portfoplus/share/DELETE_SHARE_FROM_LIST';
export const DELETE_SHARE_FROM_LIST_SUCCESS = 'portfoplus/share/DELETE_SHARE_FROM_LIST_SUCCESS';
export const DELETE_SHARE_FROM_LIST_ERROR = 'portfoplus/share/DELETE_SHARE_FROM_LIST_ERROR';

export const NOTICE_ADVISER_TO_REGISTER = 'portfoplus/share/NOTICE_ADVISER_TO_REGISTER';
export const NOTICE_ADVISER_TO_REGISTER_SUCCESS = 'portfoplus/share/NOTICE_ADVISER_TO_REGISTER_SUCCESS';
export const NOTICE_ADVISER_TO_REGISTER_ERROR = 'portfoplus/share/NOTICE_ADVISER_TO_REGISTER_ERROR';

export const INIT_PHASE = 'portfoplus/user/INIT_PHASE';

export const SHARE_DASHBOARD_CHART_DATA = 'portfoplus/share/SHARE_DASHBOARD_CHART_DATA';

//To update the personal share deshbaord data
export const PERSONAL_SHARE_DASHBOARD_CHART_DATA = 'portfoplus/share/PERSONAL_SHARE_DASHBOARD_CHART_DATA';
//To update the general share deshbaord data
export const GENERAL_SHARE_DASHBOARD_CHART_DATA = 'portfoplus/share/GENERAL_SHARE_DASHBOARD_CHART_DATA';
//To update the company share deshbaord data
export const COMPANY_SHARE_DASHBOARD_CHART_DATA = 'portfoplus/share/COMPANY_SHARE_DASHBOARD_CHART_DATA';

export const INIT_SHARE_PHASE = 'portfoplus/share/INIT_SHARE_PHASE';

export const INIT_ADVISER_STATUS_PHASE = 'portfoplus/share/INIT_ADVISER_STATUS_PHASE';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  sharePhase: INIT,
  getDocPhase: INIT,
  getShareToPhase: INIT,
  getShareFromPhase: INIT,
  togglePhase: INIT,
  deletePhase: INIT,
  deleteFromPhase: INIT,
  resMessage: null,
  documentsPolicy: [],
  shareToData: [],
  shareFromData: [],
  error: null,
  isSubmitting: false,
  overwrite: false,
  forexCurrency1: [],
  portfolio1: [],
  categories1: [],
  shareChartData: [],
  chartPhase: false,
  shareType: [],
  sharedUser: [],
  adviserInfo: {},
  registerKey: INIT,
  adviserNoticeStatus: INIT,
  personalShareChartData: [],
  companyShareChartData: [],
  generalShareChartData: [],
  generalFetchOption: 'Premium'
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case SHARE_DOCUMENTS: {
      return state
        .set('sharePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SHARE_DOCUMENTS_SUCCESS: {
      const { payload } = action;
      return state
        .set('sharePhase', payload.success)
        .set('resMessage', payload.message)
        .set('overwrite', payload.overwrite)
        .set('sharedUser', payload.data)
        .set('registerKey', _.get(payload, 'registerKey', false))
        .set('adviserNoticeStatus', false)
        .set('error', null);
    }

    case SHARE_DOCUMENTS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('sharePhase', ERROR);
    }

    case NOTICE_ADVISER_TO_REGISTER: {
      return state
        .set('sharePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case NOTICE_ADVISER_TO_REGISTER_SUCCESS: {
      const { payload } = action;
      return state
        .set('sharePhase', payload.success)
        .set('adviserNoticeStatus', payload.adviserNoticeStatus)
        .set('error', null)
        .set('registerKey', false);
    }

    case NOTICE_ADVISER_TO_REGISTER_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('sharePhase', ERROR);
    }

    case GET_SHARE_DOCUMENTS: {
      return state
        .set('getDocPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_SHARE_DOCUMENTS_SUCCESS: {
      const { payload } = action;
      return state
        .set('forexCurrency1', payload.forexCurrency)
        .set('categories1', payload.categories)
        .set('portfolio1', payload.portfolio)
        .set('resMessage', payload.message)
        .set('shareType', payload.shares)
        .set('adviserInfo', payload.adviserInfo)
        .set('getDocPhase', payload.success)
        .set('error', null);
    }

    case GET_SHARE_DOCUMENTS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getDocPhase', ERROR);
    }

    case GET_SHARE_ME_DOCUMENTS: {
      return state
        .set('getDocPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_SHARE_ME_DOCUMENTS_SUCCESS: {
      const { payload } = action;
      return state
        .set('forexCurrency1', payload.forexCurrency)
        .set('categories1', payload.categories)
        .set('portfolio1', payload.portfolio)
        .set('resMessage', payload.message)
        .set('shareType', payload.shares)
        .set('adviserInfo', payload.adviserInfo)
        .set('getDocPhase', payload.success)
        .set('error', null);
    }

    case GET_SHARE_ME_DOCUMENTS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getDocPhase', ERROR);
    }

    case GET_SHARE_TO_DATA: {
      return state
        .set('sharePhase', INIT)
        .set('getShareToPhase', LOADING)
        .set('getDocPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
        .set('togglePhase', INIT)
        .set('deletePhase', INIT);
    }

    case GET_SHARE_TO_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('shareToData', payload.data)
        .set('getShareToPhase', payload.success)
        .set('error', null);
    }

    case GET_SHARE_TO_DATA_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getShareToPhase', ERROR);
    }

    case GET_SHARE_FROM_DATA: {
      return state
        .set('deleteFromPhase', INIT)
        .set('getShareFromPhase', LOADING)
        .set('getDocPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_SHARE_FROM_DATA_SUCCESS: {
      const { payload } = action;
      return state
        .set('deleteFromPhase', INIT)
        .set('shareFromData', payload.data)
        .set('getShareFromPhase', payload.success)
        .set('error', null);
    }

    case GET_SHARE_FROM_DATA_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getShareFromPhase', ERROR);
    }

    case UPDATE_SHARE_TO_LIST: {
      return state
        .set('togglePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_SHARE_TO_LIST_SUCCESS: {
      const { payload } = action;
      return state.set('togglePhase', payload.success).set('error', null);
    }

    case UPDATE_SHARE_TO_LIST_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('togglePhase', ERROR);
    }

    case UPDATE_SHARE_DETAILS: {
      return state
        .set('sharePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_SHARE_DETAILS_SUCCESS: {
      const { payload } = action;
      return state
        .set('sharePhase', payload.success)
        .set('resMessage', payload.message)
        .set('error', null);
    }

    case UPDATE_SHARE_DETAILS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('sharePhase', ERROR);
    }
    case DELETE_SHARE_LIST: {
      return state
        .set('deletePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case DELETE_SHARE_LIST_SUCCESS: {
      const { payload } = action;
      return state.set('deletePhase', payload.success).set('error', null);
    }

    case DELETE_SHARE_LIST_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('deletePhase', ERROR);
    }

    case DELETE_SHARE_FROM_LIST: {
      return state
        .set('deleteFromPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case DELETE_SHARE_FROM_LIST_SUCCESS: {
      const { payload } = action;
      return state.set('deleteFromPhase', payload.success).set('error', null);
    }

    case DELETE_SHARE_FROM_LIST_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('deleteFromPhase', ERROR);
    }
    case INIT_PHASE: {
      return state.set('getDocPhase', INIT);
    }
    case SHARE_DASHBOARD_CHART_DATA: {
      const { payload } = action;
      return state.set('shareChartData', payload).set('chartPhase', true);
    }
    case PERSONAL_SHARE_DASHBOARD_CHART_DATA: {
      const { payload } = action;
      return state.set('personalShareChartData', payload).set('chartPhase', true);
    }
    case GENERAL_SHARE_DASHBOARD_CHART_DATA: {
      const { payload } = action;
      return state.set('generalShareChartData', payload).set('chartPhase', true);
    }
    case COMPANY_SHARE_DASHBOARD_CHART_DATA: {
      const { payload } = action;
      return state.set('companyShareChartData', payload).set('chartPhase', true);
    }

    case INIT_SHARE_PHASE: {
      return state
        .set('overwrite', INIT)
        .set('sharePhase', INIT)
        .set('getDocPhase', INIT);
    }

    case INIT_ADVISER_STATUS_PHASE: {
      return state.set('adviserNoticeStatus', INIT);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const shareDocuments = data => {
  return {
    type: SHARE_DOCUMENTS,
    payload: { data }
  };
};

export const noticeAdviserToRegister = data => {
  return {
    type: NOTICE_ADVISER_TO_REGISTER,
    payload: { data }
  };
};

export const getShareDocuments = data => ({
  type: GET_SHARE_DOCUMENTS,
  payload: data
});

export const getShareMeDocuments = data => ({
  type: GET_SHARE_ME_DOCUMENTS,
  payload: data
});

export const getShareToData = () => ({
  type: GET_SHARE_TO_DATA
});

export const getShareFromData = () => ({
  type: GET_SHARE_FROM_DATA
});

export const updateShareToList = data => {
  return {
    type: UPDATE_SHARE_TO_LIST,
    payload: { data }
  };
};

export const updateShareDetails = data => {
  return {
    type: UPDATE_SHARE_DETAILS,
    payload: { data }
  };
};

export const deleteShareList = id => ({
  type: DELETE_SHARE_LIST,
  payload: id
});

export const deleteShareFromList = id => ({
  type: DELETE_SHARE_FROM_LIST,
  payload: id
});

export const initSharePhase = () => ({
  type: INIT_PHASE
});

export const handleShareDashboardChartData = data => ({
  type: SHARE_DASHBOARD_CHART_DATA,
  payload: data
});

//Handle the data of personal share deshboard
export const handlePersonalShareDashboardChartData = data => ({
  type: PERSONAL_SHARE_DASHBOARD_CHART_DATA,
  payload: data
});

//Handle the data of general share deshboard
export const handleGeneralShareDashboardChartData = data => ({
  type: GENERAL_SHARE_DASHBOARD_CHART_DATA,
  payload: data
});

//Handle the data of company share deshboard
export const handleCompanyShareDashboardChartData = data => ({
  type: COMPANY_SHARE_DASHBOARD_CHART_DATA,
  payload: data
});

export const initPhase = () => ({
  type: INIT_SHARE_PHASE
});

export const initAdviserStatusPhase = () => ({
  type: INIT_ADVISER_STATUS_PHASE
});

/***********************************
 * Epics
 ***********/

const getShareDocumentsEpic = action$ =>
  action$.ofType(GET_SHARE_DOCUMENTS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getShareDocuments(action.payload))
      .map(payload => ({
        type: GET_SHARE_DOCUMENTS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_SHARE_DOCUMENTS_ERROR,
          payload: { error }
        })
      );
  });

const getShareMeDocumentsEpic = action$ =>
  action$.ofType(GET_SHARE_ME_DOCUMENTS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getShareMeDocuments(action.payload))
      .map(payload => ({
        type: GET_SHARE_ME_DOCUMENTS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_SHARE_ME_DOCUMENTS_ERROR,
          payload: { error }
        })
      );
  });

const shareDocumentsEpic = action$ =>
  action$.ofType(SHARE_DOCUMENTS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.shareDocuments(action.payload.data))
      .flatMap(payload => [
        {
          type: SHARE_DOCUMENTS_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: SHARE_DOCUMENTS_ERROR,
          payload: { error }
        })
      );
  });

const getShareToDataEpic = action$ =>
  action$.ofType(GET_SHARE_TO_DATA).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getShareToData())
      .map(payload => ({
        type: GET_SHARE_TO_DATA_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_SHARE_TO_DATA_ERROR,
          payload: { error }
        })
      );
  });

const getShareFromDataEpic = action$ =>
  action$.ofType(GET_SHARE_FROM_DATA).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getShareFromData())
      .map(payload => ({
        type: GET_SHARE_FROM_DATA_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_SHARE_FROM_DATA_ERROR,
          payload: { error }
        })
      );
  });

const updateShareToListEpic = action$ =>
  action$.ofType(UPDATE_SHARE_TO_LIST).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateShareToList(action.payload.data))
      .flatMap(payload => [
        {
          type: UPDATE_SHARE_TO_LIST_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_SHARE_TO_LIST_ERROR,
          payload: { error }
        })
      );
  });

const updateShareDetailsEpic = action$ =>
  action$.ofType(UPDATE_SHARE_DETAILS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateShareDetails(action.payload.data))
      .flatMap(payload => [
        {
          type: UPDATE_SHARE_DETAILS_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_SHARE_DETAILS_ERROR,
          payload: { error }
        })
      );
  });

const deleteShareListEpic = action$ =>
  action$.ofType(DELETE_SHARE_LIST).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteShareList(action.payload))
      .map(payload => ({
        type: DELETE_SHARE_LIST_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_SHARE_LIST_ERROR,
          payload: { error }
        })
      );
  });

const deleteShareFromListEpic = action$ =>
  action$.ofType(DELETE_SHARE_FROM_LIST).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteShareFromList(action.payload))
      .map(payload => ({
        type: DELETE_SHARE_FROM_LIST_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_SHARE_FROM_LIST_ERROR,
          payload: { error }
        })
      );
  });

const noticeAdviserToRegisterEpic = action$ =>
  action$.ofType(NOTICE_ADVISER_TO_REGISTER).mergeMap(action => {
    return Rx.Observable.fromPromise(api.noticeAdviserToRegister(action.payload.data))
      .flatMap(payload => [
        {
          type: NOTICE_ADVISER_TO_REGISTER_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: NOTICE_ADVISER_TO_REGISTER_ERROR,
          payload: { error }
        })
      );
  });

export const shareEpic = combineEpics(
  shareDocumentsEpic,
  getShareDocumentsEpic,
  getShareToDataEpic,
  getShareFromDataEpic,
  updateShareToListEpic,
  deleteShareListEpic,
  deleteShareFromListEpic,
  updateShareDetailsEpic,
  getShareMeDocumentsEpic,
  noticeAdviserToRegisterEpic
);
