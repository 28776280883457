import React from 'react';
import Swipeable from 'react-swipeable';

class MySwipeable extends React.Component {
  constructor(props) {
    super(props);
    this.handleSwiped = this.handleSwiped.bind(this);
  }

  handleSwiped(e, deltaX, deltaY, isFlick, velocity) {
    const { handleSwipe } = this.props;
    const angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;

    if (handleSwipe === undefined || handleSwipe === null || typeof handleSwipe !== 'function') {
      return;
    }

    if (Math.abs(angle) >= 160 || Math.abs(angle) <= 20) {
      if (velocity > 0.6) {
        if (deltaX < -10) {
          handleSwipe('left', e);
        } else if (deltaX > 10) {
          handleSwipe('right', e);
        }
      }
    }
  }

  render() {
    const { disabled, children } = this.props;

    return (
      <Swipeable onSwiped={this.handleSwiped} disabled={disabled ? disabled : false}>
        {children}
      </Swipeable>
    );
  }
}

export default MySwipeable;
