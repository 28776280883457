import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatLastMeetingDuration, LAST_MEETING_COLOR_BAR_PAIRS } from '../utils';
import ColoredProgress from '../ClientShortFall/ColoredProgress/ColoredProgress';
import './LastMeetingHint.scss';
import Typography from '@material-ui/core/Typography';

export default class LastMeetingHint extends React.PureComponent {
  render() {
    const { intl, lastMeetingDuration } = this.props;
    const MthIntl = intl.formatMessage({ id: 'Mth' });
    return (
      <div>
        <Typography paragraph={true}>{intl.formatMessage({ id: 'show-passed-time' })}</Typography>
        <Grid container direction="column" alignItems="center">
          {LAST_MEETING_COLOR_BAR_PAIRS.map((pair, index) => (
            <Grid item className="last-meeting-hint-row" key={index}>
              <ColoredProgress color={pair.color} percentage={pair.width} maxWidth="80px" />
              <span>
                {`${
                  pair.lastMeetingDurationMonths.largerThan
                    ? `${pair.lastMeetingDurationMonths.largerThan}${MthIntl} < `
                    : ''
                }${intl.formatMessage({ id: 'Day' })}${
                  pair.lastMeetingDurationMonths.lessThan
                    ? ` < ${pair.lastMeetingDurationMonths.lessThan}${MthIntl}`
                    : ''
                }`}
              </span>
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="center" style={{ marginTop: 10 }}>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'client-long-time-no-see' })}</Typography>
          </Grid>
          <Grid item>
            <Typography color="primary">{formatLastMeetingDuration(intl, lastMeetingDuration)}</Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}
