import { connect } from 'react-redux';
import { editPolicy, policyInfo, deletePolicy, insurerInfo } from '../../store/policy/duck';
import InsuredDetailsView from './component';
import { currencyExchangeRate, handleSignOut } from '../../store/user/duck';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';

import { injectIntl } from 'react-intl';

const InsuredDetails = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    teamCampaignCodes: state.functions.campaignCodes,
    exchangeRate: state.user.exchangeRate,
    deletePolicyPhase: state.policy.deletePolicyPhase,
    dashboardPolicyData: state.policy.dashboardPolicyData,
    insurers: state.insurer.insurers,
    policyInfoData: state.policy.policyInfoData,
    policyInfoPhase: state.policy.policyInfoPhase,
    newReferralCount: state.referral.newReferralCount,
    reminderBadgeCount: state.reminder.reminderBadgeCount
  }),
  // Map actions to props
  {
    editPolicy,
    policyInfo,
    deletePolicy,
    insurerInfo,
    currencyExchangeRate,
    handleSignOut,
    renderHeaderButton
  }
)(InsuredDetailsView);

export default injectIntl(InsuredDetails);
