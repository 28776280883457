import React from 'react';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';

const ReferSignUpBannerStyle = {
  background: '#ffd485',
  padding: 8,
  margin: '0 8px',
  borderRadius: 8
};

const TypoStyle = {
  fontWeight: 'bold',
  fontSize: '135%'
};

const ReferSignUpBanner = props => {
  const { intl, style, ...rest } = props;

  return (
    <div style={{ ...ReferSignUpBannerStyle, ...style }} {...rest}>
      <Typography color="textSecondary" align="center" style={TypoStyle}>
        {intl.formatMessage({ id: 'refer-adviser-sign-up' })}
      </Typography>
    </div>
  );
};

export default injectIntl(ReferSignUpBanner);
