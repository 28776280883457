import moment from 'moment';
import { convertToDateOnly } from '../../utils/date';

export const formatPlanData = data => {
  const {
    insurer,
    insurerId,
    type,
    feature,
    planType,
    policyName,
    companyName,
    policyNumber,
    guaranteeValue,
    isAnnuity,
    vhisPlan,
    coverageType,
    roomClass,
    accountTypes,
    addOns
  } = data || {};

  const formData = {};
  formData.insurer = insurer;
  formData.insurerId = insurerId;
  formData.type = type;
  formData.feature = feature;
  formData.planType = planType;
  formData.policyName = policyName;
  formData.companyName = companyName;
  formData.policyNumber = policyNumber;
  formData.guaranteeValue = guaranteeValue;
  if (guaranteeValue === 'Yes' || guaranteeValue === 'No Idea') {
    formData.isAnnuity = isAnnuity;
  } else {
    formData.isAnnuity = '';
  }
  formData.vhisPlan = vhisPlan;
  formData.coverageType = coverageType;
  formData.roomClass = roomClass;
  formData.accountTypes = accountTypes;
  formData.addOns = addOns;

  localStorage.setItem('plan', JSON.stringify(formData));
  localStorage.setItem('editPlan', JSON.stringify('true'));
};

export const formatPaymentData = (data, userDetails) => {
  const {
    policyStartDate,
    currency,
    lifeCoverage,
    sumInsured,
    premium,
    naPremium,
    frequency,
    levelPremium,
    taxDeductible,
    paymentMethod,
    lumpSumAmount,
    policyDueDate,
    reminderDate,
    adviserReminderActive,
    remindMeDay,
    reminderActive
  } = data || {};
  const { userType } = userDetails || {};

  let tempPolicyStartDate;
  if (policyStartDate === null) {
    tempPolicyStartDate = null;
  } else {
    let date = policyStartDate && policyStartDate !== '' ? moment(policyStartDate).format('YYYY-MM-DD') : null;
    tempPolicyStartDate =
      date !== null
        ? moment.utc(date).format(
            'YYYY-MM-DDT00:00:00.00Z' //Change in the date send format
          )
        : null;
  }

  let formData = {};
  formData.currency = currency;
  if (Number.isInteger(lifeCoverage) === true || lifeCoverage.toString().indexOf('.') === -1) {
    formData.lifeCoverage = parseInt(lifeCoverage);
  } else {
    formData.lifeCoverage = parseFloat(lifeCoverage).toFixed(2);
  }

  formData.sumInsured = sumInsured ? parseInt(sumInsured) : 0;

  if (Number.isInteger(premium) === true || premium.toString().indexOf('.') === -1) {
    formData.premium = parseInt(premium);
  } else {
    formData.premium = premium ? parseFloat(premium).toFixed(2) : 0;
  }
  formData.naPremium = naPremium;

  formData.levelPremium = levelPremium;
  formData.taxDeductible = taxDeductible;
  formData.paymentMethod = paymentMethod;
  formData.policyStartDate = tempPolicyStartDate;
  formData.frequency = frequency;
  formData.lumpSumAmount = lumpSumAmount;
  formData.policyDueDate = policyDueDate;
  formData.reminderDate = reminderDate;
  if (userType === 'Adviser' && formData.frequency !== '1') {
    formData.adviserReminderActive = adviserReminderActive;
  } else if (userType === 'Adviser' && formData.frequency === '1') {
    formData.adviserReminderActive = adviserReminderActive === true ? !adviserReminderActive : adviserReminderActive;
  }
  formData.remindMeDay = remindMeDay;
  formData.reminderActive = reminderActive;

  localStorage.setItem('payment', JSON.stringify(formData));
  localStorage.setItem('editPayment', JSON.stringify('true'));
};

export const formatDetailData = data => {
  const {
    paymentTerms,
    paymentTermsOther,
    protectionTerms,
    protectionTermsOther,
    policyOwner,
    policyOwnerSelected,
    lifeInsured,
    lifeInsuredSelected,
    lifeInsuredAge,
    beneficial,
    beneficialSelected,
    vehicleBrand,
    vehicleModel,
    vehicleYear,
    ncb,
    homeSize,
    homeContentsValue,
    permanentDisablement,
    medicalExpenses,
    physiotherapy,
    chineseDoctor,
    coverageDetail,
    chineseBonesetter,
    hospitalCashDetail,
    coverageDay,
    tripDelayCover,
    lossItemCover,
    mobilePhoneCover,
    rentalCarCover,
    emergencyTel,
    employersLiability,
    outPatientDetail,
    dentalDetail,
    repatriation,
    accident,
    maidReplacement,
    propertyValue,
    downPayment,
    loanRatio,
    outStandingLoan,
    interestRate,
    interestTerms,
    penaltyPeriod,
    totalWithdrawn,
    marketValue,
    surrenderValue,
    policyStatus,
    smm,
    hospitalCash,
    outPatient,
    dental,
    deductible,
    claimLimit,
    paidUpDate,
    selectedPolicyStatus,
    multiClaim,
    earlyStage,
    premiumHolidayDate,
    marketValueUpdateDate
  } = data || {};

  let tempPaymentTerms = '';
  let tempPaymentTermsOther = '';
  let tempProtectionTerms = '';
  let tempProtectionTermsOther = '';
  if (paymentTerms === 'Other') {
    tempPaymentTerms = paymentTerms;
    tempPaymentTermsOther = paymentTermsOther;
  } else {
    tempPaymentTerms = paymentTerms;
  }

  if (protectionTerms === 'Other') {
    tempProtectionTerms = protectionTerms;
    tempProtectionTermsOther = protectionTermsOther;
  } else {
    tempProtectionTerms = protectionTerms;
  }

  const formData = {};
  formData.policyOwner = policyOwner;
  formData.policyOwnerSelected = policyOwnerSelected;
  formData.lifeInsured = lifeInsured;
  formData.lifeInsuredSelected = lifeInsuredSelected;
  formData.lifeInsuredAge = lifeInsuredAge;
  formData.paymentTerms = tempPaymentTerms;
  formData.paymentTermsOther = tempPaymentTermsOther;
  formData.protectionTerms = tempProtectionTerms;
  formData.protectionTermsOther = tempProtectionTermsOther;
  formData.beneficial = beneficial;
  formData.beneficialSelected = beneficialSelected;
  formData.vehicleBrand = vehicleBrand;
  formData.vehicleModel = vehicleModel;
  formData.vehicleYear = vehicleYear;
  formData.ncb = ncb;
  formData.homeSize = homeSize;
  formData.homeContentsValue = homeContentsValue;
  formData.permanentDisablement = permanentDisablement;
  formData.medicalExpenses = medicalExpenses;
  formData.physiotherapy = physiotherapy;
  formData.chineseDoctor = chineseDoctor;
  formData.coverageDetail = coverageDetail;
  formData.chineseBonesetter = chineseBonesetter;
  formData.hospitalCashDetail = hospitalCashDetail;
  formData.coverageDay = coverageDay;
  formData.tripDelayCover = tripDelayCover;
  formData.lossItemCover = lossItemCover;
  formData.mobilePhoneCover = mobilePhoneCover;
  formData.rentalCarCover = rentalCarCover;
  formData.emergencyTel = emergencyTel;
  formData.employersLiability = employersLiability;
  formData.outPatientDetail = outPatientDetail;
  formData.dentalDetail = dentalDetail;
  formData.repatriation = repatriation;
  formData.accident = accident;
  formData.maidReplacement = maidReplacement;
  //field of mortgage
  formData.propertyValue = propertyValue;
  formData.downPayment = downPayment;
  formData.loanRatio = loanRatio;
  formData.outStandingLoan = outStandingLoan;
  formData.interestRate = interestRate;
  formData.interestTerms = interestTerms;
  formData.penaltyPeriod = penaltyPeriod;

  formData.totalWithdrawn = totalWithdrawn;
  formData.marketValue = marketValue;
  formData.surrenderValue = surrenderValue;
  formData.policyStatus = policyStatus;
  formData.smm = smm;
  formData.hospitalCash = hospitalCash;
  formData.outPatient = outPatient;
  formData.dental = dental;
  formData.deductible = deductible;
  formData.claimLimit = claimLimit;
  formData.paidUpDate = convertToDateOnly(paidUpDate);
  formData.selectedPolicyStatus = selectedPolicyStatus;
  formData.multiClaim = multiClaim;
  formData.earlyStage = earlyStage;
  formData.premiumHolidayDate = premiumHolidayDate
    ? moment.utc(moment(premiumHolidayDate).format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z')
    : null;
  formData.marketValueUpdateDate = marketValueUpdateDate
    ? moment.utc(moment(marketValueUpdateDate).format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z')
    : null;

  localStorage.setItem('detail', JSON.stringify(formData));
  localStorage.setItem('editDetail', JSON.stringify('true'));
};

export const formatAppendixData = data => {
  const {
    advisorName,
    advisorPhoneNo,
    advisorPhoneNo2,
    advisorEmail,
    remark,
    productLink,
    uploadDocuments,
    imagePreviewUrl,
    orphanCase,
    isDraft,
    files
  } = data || {};

  const formData = {};
  formData.advisorName = advisorName;
  formData.advisorPhoneNo = advisorPhoneNo;
  formData.advisorPhoneNo2 = advisorPhoneNo2;
  formData.advisorEmail = advisorEmail;
  formData.remark = remark;
  formData.productLink = productLink;
  formData.uploadDocuments = imagePreviewUrl || uploadDocuments;
  formData.orphanCase = orphanCase;
  formData.isDraft = isDraft;
  formData.attachments = (files || []).map(file => {
    return {
      filename: file.name,
      // FIXME: this is a temp workaround as current add policy logic requires storing temp data in localStorage as JSON string
      url: file instanceof File ? URL.createObjectURL(file) : file.url
    };
  });
  localStorage.setItem('apendix', JSON.stringify(formData));
  localStorage.setItem('editApendix', JSON.stringify('true'));
};

export const findPaidUpAutoDate = (policyStartDate, paymentTerms, owner, factsheet) => {
  let paidUpAutoDate = null;
  if (paymentTerms && paymentTerms !== '' && paymentTerms !== 'Other') {
    if (paymentTerms.indexOf(' Years') > -1) {
      const year = parseInt(paymentTerms.replace(' Years', ''));
      if (policyStartDate) {
        paidUpAutoDate = moment(policyStartDate).add(Math.max(year, 0), 'y');
      }
    } else {
      if (paymentTerms.indexOf('Till age ') > -1) {
        const tillAge = parseInt(paymentTerms.replace('Till age ', ''));
        if (owner || factsheet) {
          // if the policy owner is a client of the current adviser
          if (factsheet && typeof factsheet !== 'string' && factsheet.dob) {
            paidUpAutoDate = moment(factsheet.dob).add(tillAge, 'y');
          } else if (owner && owner.dob) {
            paidUpAutoDate = moment(owner.dob).add(tillAge, 'y');
          }
        }
      }
    }
  }
  return paidUpAutoDate;
};

export const shouldUsePolicyNameInput = insurer => {
  return ['SunLife', 'AIA', 'HSBC', 'BOC Life', 'Prudential', 'Manulife'].includes(insurer);
};
