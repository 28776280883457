import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../components/Transition/Transition';
import CropperWrapper from '../../../../components/CropperWrapper/CropperWrapper';
import { toast } from 'react-toastify';
import IconToast from '../../../../components/NewToast';
import Compressor from 'compressorjs';
import { uploadAttachments } from '../../../../store/media/api';
import { MEDIA_UPLOAD_TYPE } from '../../../../constants/media';
import { updateUserDetails } from '../../../../store/user/duck';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import { usePrevious } from '../../../../utils/hooks';
import { isUserTeamMember } from '../../../../utils/team';
import TeamSubscriptionOnlyDialog from './TeamSubscriptionOnlyDialog/TeamSubscriptionOnlyDialog';

const FormControlLabelStyle = {
  height: 29,
  marginRight: 0
};

const UploadMyLogoButton = props => {
  const { intl, userDetails, teams, updateUserDetails } = props;
  const { customLogoSettings } = userDetails || {};

  const [customLogoBlob, setCustomLogoBlob] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [teamSubscriptionOnlyDialogOpen, setTeamSubscriptionOnlyDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [useInPDF, setUseInPDF] = useState(customLogoSettings?.useInPDF || true);
  const [useInLazyPack, setUseInLazyPack] = useState(customLogoSettings?.useInLazyPack || true);
  const [useInProfilePlus, setUseInProfilePlus] = useState(customLogoSettings?.useInProfilePlus || false);
  const [useInMenu, setUseInMenu] = useState(customLogoSettings?.useInMenu || false);

  const hasInitialCustomLogoCropAreaSet = useRef(false);
  const cropperWrapperRef = useRef(null);
  const previousDialogOpen = usePrevious(dialogOpen);

  const isTeamMember = isUserTeamMember(teams);

  const onChangeUseInPDF = event => setUseInPDF(event.target.checked);
  const onChangeUseInLazyPack = event => setUseInLazyPack(event.target.checked);
  const onChangeUseInProfilePlus = event => {
    if (!isTeamMember && event.target.checked === true) {
      setTeamSubscriptionOnlyDialogOpen(true);
      return;
    }

    setUseInProfilePlus(event.target.checked);
  };

  const onChangeUseInMenu = event => {
    if (!isTeamMember && event.target.checked === true) {
      setTeamSubscriptionOnlyDialogOpen(true);
      return;
    }

    setUseInMenu(event.target.checked);
  };

  const closeTeamSubscriptionOnlyDialog = () => {
    setTeamSubscriptionOnlyDialogOpen(false);
  };

  const onClickUploadButton = () => {
    setDialogOpen(true);
  };

  const onReadyCrop = () => {
    // set the crop area to the full image size if the image is the user's custom logo
    const cropper = cropperWrapperRef.current?.getCropper();
    if (customLogoBlob) {
      if (cropper) {
        if (!hasInitialCustomLogoCropAreaSet.current) {
          hasInitialCustomLogoCropAreaSet.current = true;
          const { cropBoxData } = cropper;
          cropper.setCropBoxData({
            left: 0,
            top: 0,
            width: cropBoxData.maxWidth,
            height: cropBoxData.maxHeight
          });
        }
      }
    }
  };

  const onDialogCancel = () => {
    setDialogOpen(false);
  };

  const onDialogSubmit = async () => {
    setLoading(true);
    try {
      const userId = userDetails?._id;
      const cropperWrapper = cropperWrapperRef.current;
      const cropper = cropperWrapper?.getCropper();
      const hasImage = !!cropperWrapper?.getImageBlob();

      let url = null;

      if (hasImage) {
        const croppedBlob = await new Promise((resolve, reject) => {
          try {
            const canvas = cropper.getCroppedCanvas();
            canvas.toBlob(resolve);
          } catch (error) {
            reject(error);
          }
        });

        const compressedFile = await new Promise((resolve, reject) => {
          new Compressor(croppedBlob, {
            // convertSize: 50 * 1024, // max size = 50 KB,
            quality: 0.8,
            maxWidth: 500,
            maxHeight: 250,
            mimeType: 'image/jpeg',
            success: compressedFile => resolve(compressedFile),
            error(compressError) {
              reject(compressError);
              console.error(compressError.message);
            }
          });
        });

        if (compressedFile.size > 50 * 1024) {
          throw new Error('The file size is too large');
        }

        const filename = cropperWrapperRef.current.getFilename() || 'logo.jpeg';

        const attachmentsData = await uploadAttachments({
          resourceId: userId,
          attachments: [
            {
              filename: filename,
              url: URL.createObjectURL(compressedFile)
            }
          ],
          type: MEDIA_UPLOAD_TYPE.ADVISER_LOGO
        });

        url = attachmentsData[0].url;
      }

      updateUserDetails({
        customLogo: url,
        customLogoSettings: {
          useInPDF,
          useInLazyPack,
          useInProfilePlus: isTeamMember ? useInProfilePlus : false,
          useInMenu: isTeamMember ? useInMenu : false
        }
      });

      toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), { className: 'new-toast' });
      setDialogOpen(false);
    } catch (error) {
      if (error.message === 'The file size is too large') {
        toast.info(IconToast('error', intl.formatMessage({ id: 'upload-my-logo-file-size-exceed-msg' })), {
          className: 'new-toast'
        });
        return;
      }

      toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
    } finally {
      setLoading(false);
    }
  };

  const getCustomLogoBlob = useCallback(() => {
    const { customLogo } = userDetails || {};
    if (customLogo) {
      setLoading(true);
      fetch(`${customLogo}?t=${new Date().getTime()}`)
        .then(res => res.blob())
        .then(setCustomLogoBlob)
        .catch(error => {
          console.log(error);
          setCustomLogoBlob(null);
        })
        .finally(() => setLoading(false));
    } else {
      setCustomLogoBlob(null);
    }
  }, [userDetails]);

  useEffect(() => {
    if (dialogOpen !== previousDialogOpen) {
      if (!dialogOpen) {
        hasInitialCustomLogoCropAreaSet.current = false;
        const cropperWrapper = cropperWrapperRef.current;
        if (cropperWrapper) {
          cropperWrapper.destroy();
        }
      } else {
        getCustomLogoBlob();
      }
    }
  }, [dialogOpen, previousDialogOpen, getCustomLogoBlob]);

  useEffect(() => {
    getCustomLogoBlob();
  }, [getCustomLogoBlob]);

  useEffect(() => {
    const { customLogoSettings } = userDetails || {};
    if (customLogoSettings) {
      const { useInPDF, useInLazyPack, useInProfilePlus, useInMenu } = customLogoSettings;
      setUseInPDF(useInPDF);
      setUseInLazyPack(useInLazyPack);
      setUseInProfilePlus(useInProfilePlus);
      setUseInMenu(useInMenu);
    }
  }, [userDetails]);

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <TeamSubscriptionOnlyDialog open={teamSubscriptionOnlyDialogOpen} onClose={closeTeamSubscriptionOnlyDialog} />
      <Dialog open={dialogOpen} fullWidth={true} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'upload-my-logo-dialog-title' })}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography>{intl.formatMessage({ id: 'upload-my-logo-dialog-content' })}</Typography>
            </Grid>
            <Grid item>
              <CropperWrapper
                imgBlob={customLogoBlob}
                dropzone={true}
                cropperOptions={{
                  aspectRatio: 2,
                  ready: onReadyCrop
                }}
                ref={cropperWrapperRef}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography>{intl.formatMessage({ id: 'upload-my-logo-dialog-options' })}</Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={useInPDF} onChange={onChangeUseInPDF} />}
                        label={intl.formatMessage({ id: 'use-in-pdf' })}
                        style={FormControlLabelStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={useInLazyPack} onChange={onChangeUseInLazyPack} />}
                        label={intl.formatMessage({ id: 'use-in-lazy-pack' })}
                        style={FormControlLabelStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" checked={useInProfilePlus} onChange={onChangeUseInProfilePlus} />
                        }
                        label={intl.formatMessage({ id: 'use-in-profile-plus' })}
                        style={FormControlLabelStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<Checkbox color="primary" checked={useInMenu} onChange={onChangeUseInMenu} />}
                        label={intl.formatMessage({ id: 'use-in-menu' })}
                        style={FormControlLabelStyle}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onDialogCancel}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" onClick={onDialogSubmit}>
            {intl.formatMessage({ id: 'Submit' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        color="primary"
        variant="contained"
        disableElevation={true}
        startIcon={<PhotoCameraRoundedIcon />}
        onClick={onClickUploadButton}
      >
        {intl.formatMessage({ id: 'my-logo' })}
      </Button>
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    teams: state.team.teams
  }),
  {
    updateUserDetails
  }
)(UploadMyLogoButton);

export default injectIntl(container);
