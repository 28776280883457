import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import './DISCDialogComponent.scss';
import { SlideTransition } from '../../../Transition/Transition';
import SmallRoundedButton from '../../../SmallRoundedButton/SmallRoundedButton';

const DISCDialogComponent = props => {
  const { intl, open, personality, contentTextType, onClose, onExited, onSubmit } = props;

  const [_personality, setPersonality] = useState(personality);
  const [outgoing, setOutgoing] = useState(false);
  const [introverted, setIntroverted] = useState(false);
  const [logical, setLogical] = useState(false);
  const [emotional, setEmotional] = useState(false);
  const shouldUseTwoRows = useMediaQuery('(max-width: 408px)');

  const _onSubmit = () => {
    if (onSubmit) {
      onSubmit(_personality);
    }
    onClose();
  };

  const toggleOutgoing = () => {
    setOutgoing(!outgoing);
    setIntroverted(false);
  };

  const toggleIntroverted = () => {
    setIntroverted(!introverted);
    setOutgoing(false);
  };

  const toggleLogical = () => {
    setLogical(!logical);
    setEmotional(false);
  };

  const toggleEmotional = () => {
    setEmotional(!emotional);
    setLogical(false);
  };

  const selectPersonality = newPersonality => {
    setPersonality(newPersonality);
    // mergeDialog('DISC', { personality: personality === newPersonality ? '' : newPersonality });
  };

  const selectD = () => selectPersonality('D');

  const selectI = () => selectPersonality('I');

  const selectS = () => selectPersonality('S');
  const selectC = () => selectPersonality('C');

  const selectPersonalityAccordingly = useCallback(() => {
    let newPersonality;
    if (outgoing && logical) {
      newPersonality = 'D';
    } else if (outgoing && emotional) {
      newPersonality = 'I';
    } else if (introverted && emotional) {
      newPersonality = 'S';
    } else if (introverted && logical) {
      newPersonality = 'C';
    }

    if (newPersonality) {
      setPersonality(newPersonality);
    }
  }, [outgoing, introverted, logical, emotional]);

  const selectCharacteristicsAccordingly = useCallback(() => {
    if (_personality) {
      switch (_personality) {
        case 'D':
          setOutgoing(true);
          setIntroverted(false);
          setLogical(true);
          setEmotional(false);
          break;
        case 'I':
          setOutgoing(true);
          setIntroverted(false);
          setLogical(false);
          setEmotional(true);
          break;
        case 'S':
          setOutgoing(false);
          setIntroverted(true);
          setLogical(false);
          setEmotional(true);
          break;
        case 'C':
          setOutgoing(false);
          setIntroverted(true);
          setLogical(true);
          setEmotional(false);
          break;
        default:
          break;
      }
    }
  }, [_personality]);

  useEffect(() => {
    selectPersonalityAccordingly();
  }, [selectPersonalityAccordingly]);

  useEffect(() => {
    setPersonality(personality);
  }, [personality]);

  useEffect(() => {
    selectCharacteristicsAccordingly();
  }, [selectCharacteristicsAccordingly]);

  return (
    <Dialog
      className="disc-dialog"
      open={open}
      onClose={onClose}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'Personality' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography color="textSecondary">
              {intl.formatMessage({
                id: contentTextType === 'adviser' ? 'adviser_personality_type' : 'client_personality_type'
              })}
            </Typography>
          </Grid>
          <Grid item style={{ height: shouldUseTwoRows ? 70 : 39 }}>
            <Grid container spacing={1}>
              <Grid item xs={shouldUseTwoRows ? 12 : 6}>
                <Grid container justify="center" spacing={1}>
                  <Grid item>
                    <SmallRoundedButton selected={outgoing} onClick={toggleOutgoing}>
                      {intl.formatMessage({ id: 'outgoing' })}
                    </SmallRoundedButton>
                  </Grid>
                  <Grid item>
                    <SmallRoundedButton selected={introverted} onClick={toggleIntroverted}>
                      {intl.formatMessage({ id: 'introverted' })}
                    </SmallRoundedButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={shouldUseTwoRows ? 12 : 6}>
                <Grid container justify="center" spacing={1}>
                  <Grid item>
                    <SmallRoundedButton selected={logical} onClick={toggleLogical}>
                      {intl.formatMessage({ id: 'logical' })}
                    </SmallRoundedButton>
                  </Grid>
                  <Grid item>
                    <SmallRoundedButton selected={emotional} onClick={toggleEmotional}>
                      {intl.formatMessage({ id: 'emotional' })}
                    </SmallRoundedButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              {[
                {
                  value: 'D',
                  color: '#EA8462',
                  label: intl.formatMessage({ id: 'personality_D' }),
                  description: intl.formatMessage({ id: 'Desc_D' }),
                  onClick: selectD
                },
                {
                  value: 'I',
                  color: '#EAC862',
                  label: intl.formatMessage({ id: 'personality_I' }),
                  description: intl.formatMessage({ id: 'Desc_I' }),
                  onClick: selectI
                },
                {
                  value: 'S',
                  color: '#62EA84',
                  label: intl.formatMessage({ id: 'personality_S' }),
                  description: intl.formatMessage({ id: 'Desc_S' }),
                  onClick: selectS
                },
                {
                  value: 'C',
                  color: '#62A6EA',
                  label: intl.formatMessage({ id: 'personality_C' }),
                  description: intl.formatMessage({ id: 'Desc_C' }),
                  onClick: selectC
                }
              ].map(({ value, color, label, description, onClick }) => (
                <Grid item key={label}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <span>
                        <Button
                          className="portfolio-default-button button-line-height dialogue__personality"
                          style={{
                            background: _personality === value ? color : '#cccccc',
                            color: _personality === value ? '#ffffff' : '#000'
                          }}
                          variant="contained"
                          onClick={onClick}
                        >
                          {label}
                        </Button>
                      </span>
                    </Grid>
                    <Grid item>
                      <Typography>{description}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage({ id: 'Close' })}
        </Button>
        <Button onClick={_onSubmit} color="primary">
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(DISCDialogComponent);
