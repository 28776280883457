import React, { useEffect, memo } from 'react';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup
} from '@material-ui/core';
import { formatNumber } from '../../../utils/formatNumber';
import EditIconButton from '../../../components/EditIconButton';
import PVPChartsView from './PVPChartsView/PVPChartsView';
import { decimalToString } from '../../../utils/formatNumber';
import { POLICY_TYPE } from '../../../constants/policy';
import CoverageChartView from './CoverageChartView/CoverageChartView';
import CustomInputSelect from './CustomInputSelect/CustomInputSelect';
import { INFLATION_RATE, INPUT_GROUP_TYPE, SUPPORT_YEAR_OPTIONS } from '../../../constants/analysis';
// import AnalysisExport from './AnalysisExport/AnalysisExport';
// import { formatAnalysisDoc } from './AnalysisExport/formatter';
import LifeTopUpEffectChart from './LifeTopUpEffectChart/LifeTopUpEffectChart';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { updateAnalysisInputs, updateAnalysisLifeExport } from '../../../store/analysis/duck';
// import MostLongLivingPeopleChartView from './MostLongLivingPeopleChartView/MostLongLivingPeopleChartView';
import { getExtraMoneyOptions } from '../../../utils/analysis';
import './styles.css';
import MoreInfoTrigger from '../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import { toggleDialog } from '../../../store/control/duck';
import { getUserCurrency, isAdviserType } from '../../../utils/user';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import LifeInputPanel from './LifeInputPanel/LifeInputPanel';
import LifeSummary from './LifeSummary/LifeSummary';
import LifePolicyStatistics from './LifePolicyStatistics/LifePolicyStatistics';

const MyPolicyView = memo(
  props => {
    const {
      intl,
      userDetails,
      history,
      isWide,
      isSafariGenerate,
      monthlyExpense,
      lifeExtraMoney,
      lifeFamilySupportNeeded,
      lifeToReachData,
      lifeBetterThanKey,
      lifeTotalCoverage,
      debtPlusMortgageLoan,
      exportInputs,
      toggleDialog,
      inputPanelRef,
      mySwipeableViews,
      updateAnalysisInputs,
      updateAnalysisLifeExport
    } = props;

    const currency = getUserCurrency(userDetails);

    const extraMoneyOptions = getExtraMoneyOptions(monthlyExpense);

    // Calculate total life net debt
    let totalLifeNetDebt = lifeTotalCoverage - debtPlusMortgageLoan;

    // Calculate monthly income can provide and extra money based on family support years needed
    let monthlyIncomeCanProvide = 0;
    let extraMoneyDisplay = 0;

    if (totalLifeNetDebt > 0) {
      let extraMoneyPP = lifeExtraMoney * Math.pow(INFLATION_RATE, lifeFamilySupportNeeded * 12);

      let netTotalLife = 0;

      if (totalLifeNetDebt > extraMoneyPP) {
        extraMoneyDisplay = lifeExtraMoney;
        netTotalLife = totalLifeNetDebt - extraMoneyPP;
      } else {
        netTotalLife = totalLifeNetDebt;
      }

      if (netTotalLife > 0) {
        monthlyIncomeCanProvide = (
          (netTotalLife * (1 - INFLATION_RATE)) /
          (1 - Math.pow(INFLATION_RATE, lifeFamilySupportNeeded * 12))
        ).toFixed(1);
      }
    }

    let lifeScore = lifeToReachData.score,
      protectionRisk = '';

    if (lifeScore >= 0 && lifeScore < 33) {
      protectionRisk = 'High';
    }
    if (lifeScore >= 33 && lifeScore < 67) {
      protectionRisk = 'Mid';
    }
    if (lifeScore >= 67) {
      protectionRisk = 'Low';
    }

    let supportImg = '';
    if (protectionRisk === 'High') {
      supportImg = 'img/family-red.png';
    } else if (protectionRisk === 'Mid') {
      supportImg = 'img/family-yellow.png';
    } else {
      supportImg = 'img/family-green.png';
    }

    let yearSupport = Math.max(lifeToReachData.yearSupport, 0);
    let supportYears = isNaN(yearSupport) || yearSupport === Number.POSITIVE_INFINITY ? null : Math.floor(yearSupport);
    let supportMonths = supportYears === null ? ' - ' : Math.floor((yearSupport - supportYears) * 12);

    const handleClickEdit = option => {
      if (inputPanelRef && inputPanelRef.current) {
        let instance;
        if (inputPanelRef.current.getWrappedInstance) {
          instance = inputPanelRef.current.getWrappedInstance();
        } else {
          instance = inputPanelRef.current;
        }

        if (instance) {
          instance.setInputGroupOption(option);
          instance.open();
        }
      }
    };

    const handleChangeFamilySupportNeeded = e => {
      updateAnalysisInputs({
        lifeFamilySupportNeeded: e.target.value
      });
    };

    const handleChangeExtraMoney = e => {
      updateAnalysisInputs({ lifeExtraMoney: e.target.value });
    };

    const createSwipeableViewsHandler = timeout => event => {
      if (event && event.persist) {
        event.persist();
      }
      setTimeout(() => {
        if (mySwipeableViews && mySwipeableViews.current) {
          mySwipeableViews.current.adjustSlideHeights();
        }
      }, timeout);
    };
    const onChangePVPExpansionPanel = createSwipeableViewsHandler(475);
    const onChangeExportExpansionPanel = createSwipeableViewsHandler(350);
    const onDidUpdateExport = createSwipeableViewsHandler(300);

    return (
      <div
        style={{ paddingTop: 0, marginTop: 0 }}
        className={'analysis-slide analysis-life' + (isSafariGenerate ? ' safari-gen' : '')}
      >
        {isSafariGenerate && exportInputs.exportInputPanel === true && <LifeInputPanel />}
        <div className="analysis-expansion">
          {!isWide && isAdviserType(userDetails) && !isSafariGenerate && (
            <AladdinHint
              title={intl.formatMessage({ id: 'aladdin-life-analysis-title' })}
              helpCenter="c33"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          )}

          {!isWide && !isSafariGenerate && <LifePolicyStatistics onChange={onChangePVPExpansionPanel} />}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportScore === true)) && (
            <div className="cardAnalysis analysis-expansion-element analysis-score">
              <MoreInfoTrigger
                titleIntlId="Life Protection Score"
                contentIntlId="moreInfo_lifeScore"
                className="analysis-moreinfo"
              />
              <CoverageChartView policyType={POLICY_TYPE.LIFE} score={lifeScore} />
            </div>
          )}

          {!isWide && (!isSafariGenerate || (isSafariGenerate && exportInputs.exportTotal === true)) && <LifeSummary />}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportDuration === true)) && (
            <div
              style={{ borderLeft: '6px solid #3EDA7D' }}
              className="cardAnalysis analysis-expansion-element analysis-duration text-center greyL6 font16"
            >
              <MoreInfoTrigger
                titleIntlId="Duration Analysis"
                contentIntlId="moreInfo_lifeCalculation"
                className="analysis-moreinfo"
              />
              <EditIconButton
                handleClick={event => {
                  handleClickEdit(INPUT_GROUP_TYPE.TARGET_DURATION);
                }}
              />
              <h4>{intl.formatMessage({ id: 'Duration Analysis' })}</h4>
              <div>
                <div className="violet-text">{intl.formatMessage({ id: 'Family Support Duration' })}</div>
                <div className="support-duration-group">
                  <span className="figureColor support-duration-number">
                    {supportYears === null ? ' - ' : supportYears}
                  </span>
                  <span className="support-duration-text">{intl.formatMessage({ id: 'Yrs' })}</span>
                </div>
                <div className="support-duration-group">
                  <span className="figureColor support-duration-number">
                    {supportMonths === null ? ' - ' : supportMonths}
                  </span>
                  <span className="support-duration-text">{intl.formatMessage({ id: 'Mth' })}</span>
                </div>
                <div className="support-duration-group-last">
                  <img alt="" className="support-duration-img" src={supportImg} />
                </div>
              </div>
              <br />
              {process.env.NODE_ENV === 'development' && (
                <LifeTopUpEffectChart intl={intl} totalLifeNetDebt={totalLifeNetDebt} monthlyExpense={monthlyExpense} />
              )}
            </div>
          )}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportIncome === true)) && (
            <div
              style={{ borderLeft: '6px solid #FFA600' }}
              className="cardAnalysis analysis-expansion-element analysis-income text-center greyL6 font16"
            >
              <h4>{intl.formatMessage({ id: 'Income Analysis' })}</h4>

              <EditIconButton
                handleClick={event => {
                  handleClickEdit(INPUT_GROUP_TYPE.TARGET_INCOME);
                }}
              />

              <div className="analysis-expansion-element">
                <div>
                  <span>
                    {intl.formatMessage({
                      id: 'The insurance can provide monthly income'
                    })}
                  </span>

                  <div className="font24 figureColor">
                    {intl.formatMessage({
                      id: currency
                    })}{' '}
                    {decimalToString(monthlyIncomeCanProvide)}
                  </div>

                  <div>
                    {intl.formatMessage({
                      id: 'to support your family for'
                    })}
                  </div>

                  <div className="font24 figureColor">
                    <CustomInputSelect
                      className="analysis-input"
                      inline={true}
                      name="lifeFamilySupportNeeded"
                      onChange={handleChangeFamilySupportNeeded}
                      value={lifeFamilySupportNeeded}
                      selectOptions={SUPPORT_YEAR_OPTIONS}
                      allowCustomValue={true}
                      min={1}
                      nonZeroInteger
                      max={SUPPORT_YEAR_OPTIONS[SUPPORT_YEAR_OPTIONS.length - 1]}
                    />
                    {' ' + intl.formatMessage({ id: 'years' })}
                  </div>

                  <div>
                    {intl.formatMessage({
                      id: 'with remaining balance'
                    })}
                  </div>
                  <div className="font24 figureColor">
                    {intl.formatMessage({
                      id: currency
                    })}{' '}
                    <CustomInputSelect
                      className="analysis-input"
                      inline={true}
                      name="lifeExtraMoney"
                      onChange={handleChangeExtraMoney}
                      value={extraMoneyDisplay > 0 ? lifeExtraMoney : 0}
                      selectOptions={extraMoneyOptions}
                      isDollarValue={true}
                      allowCustomValue={true}
                      min={0}
                      max={extraMoneyOptions[extraMoneyOptions.length - 1]}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportRanking === true)) && (
            <div className="cardAnalysis analysis-expansion-element analysis-ranking text-center greyL6 font16">
              <div style={{ marginBottom: '15px' }}>
                <div>
                  {lifeBetterThanKey !== 'lessThan25'
                    ? intl.formatMessage({
                        id: 'Your total life protection is better than'
                      })
                    : intl.formatMessage({
                        id: 'Your total life protection is less than'
                      })}{' '}
                </div>
                <div className="font24 figureColor">
                  {lifeBetterThanKey === '25' && '25'}
                  {lifeBetterThanKey === '50' && '50'}
                  {lifeBetterThanKey === '75' && '75'}
                  {lifeBetterThanKey === '' && '0'}
                  {lifeBetterThanKey === 'lessThan25' && '25'}
                  {'%'}
                </div>
                <div>{intl.formatMessage({ id: 'of peers' })}</div>
              </div>
            </div>
          )}

          {/*{!isSafariGenerate && _.get(userDetails, 'userType') === 'Adviser' && (*/}
          {/*  <ExpansionPanel*/}
          {/*    defaultExpanded={false}*/}
          {/*    className="analysis-expansion exclude-in-image analysis-bottom export-expansion"*/}
          {/*    TransitionProps={{*/}
          {/*      className: 'export-expansion-collapse',*/}
          {/*      timeout: 300*/}
          {/*    }}*/}
          {/*    onChange={onChangeExportExpansionPanel}*/}
          {/*  >*/}
          {/*    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">*/}
          {/*      {intl.formatMessage({ id: 'analysis-export-heading' })}&nbsp;<span className="pro-text">PRO</span>*/}
          {/*    </ExpansionPanelSummary>*/}

          {/*    <ExpansionPanelDetails className="analysis-expansion-detail">*/}
          {/*      <AnalysisExport*/}
          {/*        onDidUpdate={onDidUpdateExport}*/}
          {/*        shouldRender={() => {*/}
          {/*          if (!Object.entries(exportInputs).find(entry => entry[1] === true)) {*/}
          {/*            toggleDialog('analysisExportOptionsHint', {});*/}
          {/*            return false;*/}
          {/*          }*/}
          {/*          return true;*/}
          {/*        }}*/}
          {/*        intl={intl}*/}
          {/*        history={history}*/}
          {/*        safariGenPath="/analysis/life/safari-gen"*/}
          {/*        targetSelector=".analysis-life"*/}
          {/*        inputs={*/}
          {/*          <FormControl component="fieldset" className="export-select-form">*/}
          {/*            <FormLabel component="legend">*/}
          {/*              {intl.formatMessage({*/}
          {/*                id: 'Select fields to export'*/}
          {/*              })}*/}
          {/*            </FormLabel>*/}
          {/*            <FormGroup className="export-select-group">*/}
          {/*              {[*/}
          {/*                {*/}
          {/*                  state: 'exportInputPanel',*/}
          {/*                  labelId: 'input-panel'*/}
          {/*                },*/}
          {/*                { state: 'exportScore', labelId: 'score' },*/}
          {/*                {*/}
          {/*                  state: 'exportTotal',*/}
          {/*                  labelId: 'Total life protection'*/}
          {/*                },*/}
          {/*                {*/}
          {/*                  state: 'exportDuration',*/}
          {/*                  labelId: 'Duration Analysis'*/}
          {/*                },*/}
          {/*                {*/}
          {/*                  state: 'exportIncome',*/}
          {/*                  labelId: 'Income Analysis'*/}
          {/*                },*/}
          {/*                { state: 'exportRanking', labelId: 'Ranking' }*/}
          {/*              ].map((obj, index) => {*/}
          {/*                return (*/}
          {/*                  <FormControl key={index}>*/}
          {/*                    <FormControlLabel*/}
          {/*                      control={*/}
          {/*                        <Checkbox*/}
          {/*                          color="primary"*/}
          {/*                          checked={exportInputs[obj.state] || false}*/}
          {/*                          onChange={(event, checked) =>*/}
          {/*                            updateAnalysisLifeExport({*/}
          {/*                              [obj.state]: checked*/}
          {/*                            })*/}
          {/*                          }*/}
          {/*                          value={obj.state}*/}
          {/*                        />*/}
          {/*                      }*/}
          {/*                      label={intl.formatMessage({*/}
          {/*                        id: obj.labelId*/}
          {/*                      })}*/}
          {/*                    />*/}
          {/*                  </FormControl>*/}
          {/*                );*/}
          {/*              })}*/}
          {/*            </FormGroup>*/}
          {/*          </FormControl>*/}
          {/*        }*/}
          {/*        options={() => {*/}
          {/*          const selectionPairs = [*/}
          {/*            {*/}
          {/*              state: 'exportInputPanel',*/}
          {/*              selector: '.analysis-input-panel'*/}
          {/*            },*/}
          {/*            { state: 'exportScore', selector: '.analysis-score' },*/}
          {/*            { state: 'exportTotal', selector: '.analysis-total' },*/}
          {/*            {*/}
          {/*              state: 'exportDuration',*/}
          {/*              selector: '.analysis-duration'*/}
          {/*            },*/}
          {/*            { state: 'exportIncome', selector: '.analysis-income' },*/}
          {/*            {*/}
          {/*              state: 'exportRanking',*/}
          {/*              selector: '.analysis-ranking'*/}
          {/*            }*/}
          {/*          ];*/}

          {/*          return {*/}
          {/*            height:*/}
          {/*              (exportInputs.exportInputPanel === true ? 370 : 0) +*/}
          {/*              (exportInputs.exportScore === true*/}
          {/*                ? document.querySelector('.analysis-life .analysis-score').offsetHeight + 7*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportTotal === true*/}
          {/*                ? document.querySelector('.analysis-life .analysis-total').offsetHeight + 7*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportDuration === true*/}
          {/*                ? document.querySelector('.analysis-life .analysis-duration').offsetHeight + 7*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportIncome === true*/}
          {/*                ? document.querySelector('.analysis-life .analysis-income').offsetHeight + 14*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportRanking === true*/}
          {/*                ? document.querySelector('.analysis-life .analysis-ranking').offsetHeight + 14*/}
          {/*                : 0),*/}
          {/*            backgroundColor: '#ffffff',*/}
          {/*            onclone: async doc => {*/}
          {/*              try {*/}
          {/*                formatAnalysisDoc(doc, '.analysis-life', '.life-input-panel');*/}

          {/*                selectionPairs.forEach(selectionPair => {*/}
          {/*                  if (exportInputs[selectionPair.state] === false) {*/}
          {/*                    doc.querySelector(`.analysis-life ${selectionPair.selector}`).remove();*/}
          {/*                  }*/}
          {/*                });*/}
          {/*              } catch (e) {*/}
          {/*                return e;*/}
          {/*              }*/}
          {/*            }*/}
          {/*          };*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </ExpansionPanelDetails>*/}
          {/*  </ExpansionPanel>*/}
          {/*)}*/}
        </div>

        <div className="height-block-for-fab exclude-in-image" />
      </div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);
// MyPolicyView.whyDidYouRender = true;

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,

    monthlyExpense: state.analysis.monthlyExpense,

    lifeTopUp: state.analysis.lifeTopUp,
    lifeExtraMoney: state.analysis.lifeExtraMoney,
    lifeFamilySupportNeeded: state.analysis.lifeFamilySupportNeeded,

    lifeToReachData: state.analysis.lifeToReachData,
    lifeBetterThanKey: state.analysis.lifeBetterThanKey,
    lifeTotalCoverage: state.analysis.lifeTotalCoverage,

    debtPlusMortgageLoan: state.analysis.debtPlusMortgageLoan,

    exportInputs: { ...state.analysis.lifeExport }
  }),
  // Map actions to props
  {
    updateAnalysisInputs,
    updateAnalysisLifeExport,
    toggleDialog
  }
)(MyPolicyView);

export default injectIntl(container);
