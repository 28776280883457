import React, { forwardRef, useEffect, useState, useImperativeHandle, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import ColoredLeftBorderCard from '../ColoredLeftBorderCard/ColoredLeftBorderCard';
import { PYRAMID_COLORS } from '../constants';
import { decimalToString } from '../../../../utils/formatNumber';
import { useTheme } from '@material-ui/core/styles';
import { isFalseExceptZero } from '../../../../utils/helpers';
import ToggleEditButton from '../ToggleEditButton/ToggleEditButton';
import { NumberFormatWrapper } from '../../../../components/NumberFormatTextField/NumberFormatTextField';
import { usePrevious } from '../../../../utils/hooks';
import AssetDistributionChart from './AssetDistributionChart/AssetDistributionChart';
import ExpenseIncomeChart from './ExpenseIncomeChart/ExpenseIncomeChart';
import MonthlyExpensePurchaseMonthsChart from './MonthlyExpensePurchaseMonthsChart/MonthlyExpensePurchaseMonthsChart';
import { tracking } from '../../../../utils/tracking';
import ExpenseCalculatorTriggerButton from '../../../../components/ExpenseCalculatorDialog/ExpenseCalculatorTriggerButton/ExpenseCalculatorTriggerButton';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

const EditButtonStyle = {
  position: 'absolute',
  top: 7,
  right: 6,
  zIndex: 2
};

const ArrowIconStyle = {
  background: '#3966f8',
  color: '#fff',
  borderRadius: '50%',
  fontSize: 24
};

const InfoTypo = props => {
  const { label, value, valueEdit, valueStyle, ...rest } = props;

  return (
    <Grid container justify="center" alignItems="center" wrap={valueEdit ? 'nowrap' : undefined} spacing={1} {...rest}>
      <Grid item>
        <Typography noWrap={!!valueEdit}>{label}</Typography>
      </Grid>
      <Grid item>
        {!!valueEdit ? (
          valueEdit
        ) : (
          <Typography color="primary" style={{ fontWeight: 'bold', fontSize: '2.07rem', ...valueStyle }}>
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const InfoSection = forwardRef((props, ref) => {
  const {
    intl,
    clientId,
    factsheetId,
    currency,
    monthlyIncome,
    monthlyExpense,
    emergencyFund,
    pyramidData,
    onChange,
    template,
    expenseUpdateDetailsRef,
    showExpenseCalculator,
    onToggleShowExpenseCalculator,
    expenses
  } = props;
  const [internalMonthlyIncome, setInternalMonthlyIncome] = useState(monthlyIncome);
  const [internalMonthlyExpense, setInternalMonthlyExpense] = useState(monthlyExpense);
  const [internalEmergencyFund, setInternalEmergencyFund] = useState(emergencyFund);
  const [internalExpenses, setInternalExpenses] = useState(expenses);
  const prevMonthlyIncome = usePrevious(monthlyIncome);
  const prevMonthlyExpense = usePrevious(monthlyExpense);
  const prevEmergencyFund = usePrevious(emergencyFund);

  const theme = useTheme();
  const [isEdit, setIsEdit] = useState(false);
  const [isEditEmergencyFund, setIsEditEmergencyFund] = useState(false);

  const toggleIsEdit = () => {
    if (isEdit) {
      tracking('- Click on Monthly expense setting - Financial pyramid');
      onChange({
        monthlyIncome: internalMonthlyIncome,
        monthlyExpense: internalMonthlyExpense,
        expenses: internalExpenses
      });
    }
    setIsEdit(!isEdit);
  };

  const toggleIsEditAuto = () => {
    if (isEdit) {
      onChange({
        monthlyIncome: internalMonthlyIncome,
        monthlyExpense: internalMonthlyExpense,
        expenses: internalExpenses
      });
    }
  };

  const toggleIsEditEmergencyFund = () => {
    if (isEditEmergencyFund) {
      tracking('Click on Emergency asset - Financial pyramid');
      onChange({ emergencyFund: internalEmergencyFund });
    }
    setIsEditEmergencyFund(!isEditEmergencyFund);
  };

  const monthlyUsable =
    !isFalseExceptZero(internalMonthlyIncome) && !isFalseExceptZero(internalMonthlyExpense)
      ? internalMonthlyIncome - internalMonthlyExpense
      : undefined;
  const formatValueDisplay = value => (!isFalseExceptZero(value) ? decimalToString(value) : '-');

  const onChangeMonthlyIncome = event => setInternalMonthlyIncome(event.target.value);
  const onChangeMonthlyExpense = event => setInternalMonthlyExpense(event.target.value);
  const onChangeEmergencyFund = event => setInternalEmergencyFund(event.target.value);
  const onChangeMonthlyExpenseFromCalculator = (total, expenses) => {
    if (expenseUpdateDetailsRef) {
      expenseUpdateDetailsRef.current = { total: total };
    }
    onChange({ monthlyExpense: total, expenses: expenses });
    setInternalMonthlyExpense(total);
    setInternalExpenses(expenses);
  };

  useEffect(() => {
    if (monthlyIncome !== prevMonthlyIncome && monthlyIncome !== internalMonthlyIncome) {
      if (monthlyIncome === null){
        setInternalMonthlyIncome(0);
      } else{
        setInternalMonthlyIncome(monthlyIncome);
      }
    }
  }, [monthlyIncome, internalMonthlyIncome, prevMonthlyIncome, clientId]);

  useEffect(() => {
    console.log(monthlyExpense, prevMonthlyExpense, internalMonthlyExpense)
    if (monthlyExpense !== prevMonthlyExpense && monthlyExpense !== internalMonthlyExpense) {
      if (monthlyExpense === null){
        setInternalMonthlyExpense(0);
      } else{
        setInternalMonthlyExpense(monthlyExpense);
      }
    }
  }, [monthlyExpense, internalMonthlyExpense, prevMonthlyExpense, clientId]);

  useEffect(() => {
    if (emergencyFund !== prevEmergencyFund && emergencyFund !== internalEmergencyFund) {
      if (emergencyFund === null){
        setInternalEmergencyFund(0);
      } else{
        setInternalEmergencyFund(emergencyFund);
      }
    }
  }, [emergencyFund, internalEmergencyFund, prevEmergencyFund, clientId]);

  useImperativeHandle(ref, () => ({
    toggleInfoEdit: flag => setIsEdit(flag !== undefined ? flag : !isEdit),
    toggleEmergencyFundEdit: flag => setIsEditEmergencyFund(flag !== undefined ? flag : !isEditEmergencyFund)
  }));

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item style={{ width: '100%' }}>
          <ColoredLeftBorderCard color="#3966f8" contentStyle={{ width: '100%', padding: 16 }}>
            {onChange && (
              <ToggleEditButton
                isEdit={isEdit}
                onChange={toggleIsEdit}
                style={EditButtonStyle}
                disabled={!clientId || !factsheetId}
              />
            )}

            <Grid container justify="center" spacing={2}>
              <Grid item xs={12}>
                <Typography align="left">{intl.formatMessage({ id: 'monthly' })}</Typography>
              </Grid>
              <Grid item xs={12}>
                <InfoTypo
                  label={`${intl.formatMessage({ id: 'Income' })}:`}
                  value={formatValueDisplay(internalMonthlyIncome)}
                  valueEdit={
                    isEdit && (
                      <TextField
                        value={internalMonthlyIncome}
                        onChange={onChangeMonthlyIncome}
                        onBlur={toggleIsEditAuto}
                        InputProps={{
                          inputComponent: NumberFormatWrapper
                        }}
                      />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ position: 'relative' }}>
                {!template && !isEdit && (
                  <IconButton
                    color="primary"
                    onClick={onToggleShowExpenseCalculator}
                    style={{
                      position: 'absolute',
                      left: -4,
                      top: '50%',
                      transition: 'transform 0.8s',
                      transform: `translateY(-50%) rotate(${showExpenseCalculator ? 180 : 0}deg)`,
                      padding: 0,
                      boxShadow:
                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                    }}
                  >
                    <ArrowDropDownRoundedIcon style={ArrowIconStyle} />
                  </IconButton>
                )}

                <InfoTypo
                  label={`${intl.formatMessage({ id: 'expense' })}:`}
                  value={formatValueDisplay(internalMonthlyExpense)}
                  valueEdit={
                    isEdit && (
                      <div style={{ position: 'relative' }}>
                        <TextField
                          value={internalMonthlyExpense}
                          onChange={onChangeMonthlyExpense}
                          onBlur={toggleIsEditAuto}
                          InputProps={{
                            inputComponent: NumberFormatWrapper
                          }}
                        />
                        {clientId && factsheetId && (
                          <ExpenseCalculatorTriggerButton
                            expenses={expenses}
                            onSubmit={onChangeMonthlyExpenseFromCalculator}
                            style={{ position: 'absolute', right: 0, bottom: 6 }}
                          />
                        )}
                      </div>
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <InfoTypo
                  label={`${intl.formatMessage({ id: 'surplus' })}:`}
                  value={formatValueDisplay(monthlyUsable)}
                  valueStyle={monthlyUsable && monthlyUsable < 0 ? { color: theme.palette.error.main } : undefined}
                />
              </Grid>
              <Grid item xs={12} style={{ height: 60 }}>
                <ExpenseIncomeChart monthlyExpense={monthlyExpense} monthlyIncome={monthlyIncome} template={template} />
              </Grid>
              <Grid item xs={12} style={{ height: 166 }}>
                <AssetDistributionChart currency={currency} pyramidData={pyramidData} template={template} />
              </Grid>
            </Grid>
          </ColoredLeftBorderCard>
        </Grid>

        <Grid item style={{ width: '100%' }}>
          <ColoredLeftBorderCard
            color="#3966f8"
            contentStyle={{ width: '100%', padding: '24px 16px 16px', whiteSpace: 'pre-wrap' }}
          >
            {onChange && (
              <ToggleEditButton
                isEdit={isEditEmergencyFund}
                onChange={toggleIsEditEmergencyFund}
                style={EditButtonStyle}
                disabled={!clientId || !factsheetId}
              />
            )}
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} style={{ zIndex: 1 }}>
                <InfoTypo
                  justify="flex-start"
                  label={`${intl.formatMessage({ id: 'emg-fund' })}:`}
                  value={formatValueDisplay(internalEmergencyFund)}
                  valueEdit={
                    isEditEmergencyFund && (
                      <TextField
                        value={internalEmergencyFund}
                        onChange={onChangeEmergencyFund}
                        onBlur={toggleIsEditEmergencyFund}
                        InputProps={{
                          inputComponent: NumberFormatWrapper
                        }}
                      />
                    )
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary" align="center">
                  {intl.formatMessage({ id: 'fin-py-expense-months' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MonthlyExpensePurchaseMonthsChart
                  value={monthlyExpense ? emergencyFund / monthlyExpense : 0}
                  template={template}
                />
              </Grid>
            </Grid>
          </ColoredLeftBorderCard>
        </Grid>
      </Grid>
    </Fragment>
  );
});

export default injectIntl(InfoSection, { withRef: true });
