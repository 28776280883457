import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import NumberFormatTextField from '../../../../../components/NumberFormatTextField/NumberFormatTextField';
import { addThousandSeparators } from '../../../../../utils/formatNumber';
import { DEFAULT_PREMIUM_FINANCING_PROFILE } from '../../../../../constants/premium-financing';

const LoanCalculatorDialog = props => {
  const { intl, open, day0SurrenderValue, bankLoanPercentage, onChange, onClose, onSubmit, ...rest } = props;

  const formattedDay0SurrenderValue =
    day0SurrenderValue !== undefined ? day0SurrenderValue : DEFAULT_PREMIUM_FINANCING_PROFILE.day0SurrenderValue;
  const formattedBankLoanPercentage =
    bankLoanPercentage !== undefined ? bankLoanPercentage : DEFAULT_PREMIUM_FINANCING_PROFILE.bankLoanPercentage;

  const loan = (formattedDay0SurrenderValue ?? 0) * ((formattedBankLoanPercentage ?? 0) / 100);

  const onChangeDay0SurrenderValue = event => {
    // use null as empty value
    onChange({ target: { name: 'day0SurrenderValue', value: event.target.value ?? null } });
  };

  const onChangeBankLoansPercentage = event => {
    // use null as empty value
    onChange({ target: { name: 'bankLoanPercentage', value: event.target.value ?? null } });
  };

  const formattedOnSubmit = () => {
    onSubmit(loan);
    onClose();
  };

  return (
    <Dialog maxWidth="xs" open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'pfa-loan-cal-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} className="pfa-input-panel">
          <Grid item xs={12} className="input-control">
            <Grid container>
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'pfa-day-0-surrender-value' })}
              </Grid>
              <Grid item xs={6}>
                <NumberFormatTextField
                  name="day0SurrenderValue"
                  value={formattedDay0SurrenderValue}
                  onChange={onChangeDay0SurrenderValue}
                  inputProps={{
                    allowNegative: false,
                    prefix: '$',
                    decimalScale: 0
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="input-control">
            <Grid container>
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'pfa-bank-loan-percentage' })}
              </Grid>
              <Grid item xs={6}>
                <NumberFormatTextField
                  name="bankLoanPercentage"
                  value={formattedBankLoanPercentage?.toFixed(1)}
                  inputProps={{
                    allowNegative: false,
                    suffix: `%`,
                    decimalScale: 1,
                    isAllowed: values => {
                      const { formattedValue, value } = values;
                      return formattedValue === '' || (value >= 0 && value <= 100);
                    }
                  }}
                  onChange={onChangeBankLoansPercentage}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'pfa-loan-amount' })}
              </Grid>
              <Grid item xs={6} style={{ color: 'grey', fontSize: '110%' }}>
                ${addThousandSeparators(loan.toFixed(0))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={formattedOnSubmit}>
          {intl.formatMessage({ id: 'paste-use' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(LoanCalculatorDialog);
