import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { sanitize } from 'dompurify';
import { getUser, handleSignOut } from '../../../store/user/duck';

export default class verifyAdviser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      emailVerfiyModal: false
    };
    this.closeEmailVerfiyModal = this.closeEmailVerfiyModal.bind(this);
  }

  componentDidMount() {
    var user = this.props.userDetails;
    if (_.isEmpty(user)) {
      /*to display the page language as per the browser language*/
      var userLang = navigator.language || navigator.userLanguage;
      const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      this.props.selectedLocale(language);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.verifyEmailPhase !== true && this.props.verifyEmailPhase === true) {
      if (this.props.userDetails?.userType) {
        this.props.getUser();
      }
      this.setState({ emailVerfiyModal: true });
    } else if (prevProps.verifyEmailPhase !== false && this.props.verifyEmailPhase === false) {
      this.props.history.replace('/');
    }
  }

  UNSAFE_componentWillMount() {
    //functionality to verify users email
    const { verifyEmail } = this.props;
    if (this.props.match.params.adviserId) {
      let obj = {};
      obj.adviserId = this.props.match.params.adviserId;
      obj.status = 'verifyEmail';
      verifyEmail(obj);
    }
  }

  closeEmailVerfiyModal() {
    const { history } = this.props;
    this.setState({
      emailVerfiyModal: false
    });
    history.push({
      pathname: '/login'
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <div>
        <div id="loginWrapper">
          <div className="page-containt">
            <img alt="" style={{ paddingTop: '125px' }} className="logo" src="img/logo_kyc.png" />
            <div className="consent-style">
              <Dialog
                open={this.state.emailVerfiyModal}
                onClose={this.closeEmailVerfiyModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={SlideTransition}
                fullWidth={true}
              >
                <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Verify Email' })}</DialogTitle>
                <DialogContent>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        intl.formatMessage({
                          id: 'Your email is verified successfully'
                        })
                      )
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeEmailVerfiyModal} color="primary" autoFocus>
                    {intl.formatMessage({ id: 'OK' })}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
