import React from 'react';
import { Select, Zoom } from '@material-ui/core';

// temp component to override transition component to avoid issue due to safari CSS bug
// https://github.com/mui/material-ui/pull/32202
const TempSelect = props => {
  const { MenuProps, ...rest } = props;

  return (
    <Select
      MenuProps={{
        TransitionComponent: Zoom,
        ...MenuProps
      }}
      {...rest}
    />
  );
};

export default TempSelect;
