import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import LikeCount from '../../../../components/LikeCount/LikeCount';

const ContainerStyle = {
  padding: '0 12px',
  marginTop: '-12px'
};

const ButtonGridStyle = {
  flexGrow: 1
};

const ButtonStyle = {
  background: 'var(--my-gradient)',
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  minWidth: 'unset',
  minHeight: 39
};

const ShareButtonStyle = {
  ...ButtonStyle,
  width: '100%',
  borderRadius: 24
};

const LikeButtonStyle = {
  ...ButtonStyle,
  width: 39,
  height: 39,
  borderRadius: '50%'
};

const ThumbUpIconStyle = {
  fontSize: '23px',
  verticalAlign: 'middle'
};

const BottomButtons = props => {
  const { intl, adviserProfilePlus, onShare } = props;
  const { likes } = adviserProfilePlus || {};

  const animRef = useRef(null);

  useEffect(() => {
    const run = async () => {
      if (animRef && animRef.current) {
        const anim = await animRef.current.animationDidLoad();
        setTimeout(() => {
          if (anim) {
            anim.play();
          }
        }, 350);
      } else {
        setTimeout(() => {
          run();
        }, 500);
      }
    };
    run()
      .then()
      .catch();
  }, []);

  return (
    <Grid container alignItems="center" spacing={2} style={ContainerStyle}>
      <Grid item>
        <LikeCount
          likes={likes || 0}
          LottieProps={{
            ref: animRef,
            style: {
              width: 48,
              height: 48,
              top: -18,
              left: -12
            }
          }}
        />
      </Grid>
      <Grid item style={ButtonGridStyle}>
        <Button
          className="find-me"
          variant="contained"
          color="primary"
          style={ShareButtonStyle}
          onClick={onShare}
          startIcon={<ShareRoundedIcon />}
        >
          {intl.formatMessage({ id: 'share-to-client' })}
        </Button>
      </Grid>
    </Grid>
  );
};

const container = connect(
  state => ({
    adviserProfilePlus: state.adviserProfilePlus.adviserProfilePlus
  }),
  {}
)(BottomButtons);

export default injectIntl(container);
