import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import IconToast from '../../../components/NewToast';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

class ShareFromView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      deleteId: '',
      askToDisableTrackingModal: false
    };
    this.closeModal = this.closeModal.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.deleteFromPhase === true) {
      this.props.getShareFromData();
      this.props.getUser();
      toast.info(IconToast('info', intl.formatMessage({ id: 'Deleted_sharing_record' })), { className: 'new-toast' });
    }
    if (nextProps.getDocPhase === false) {
      toast.info(IconToast('error', nextProps.resMessage), { className: 'new-toast' });
    }
    if (nextProps.getDocPhase === true) {
      //Push the object of policy of share in state so that while reload we can get the data by state if props are null
      let sharePolicyData = {};
      sharePolicyData.portfolio1 = _.get(nextProps, 'portfolio1');
      sharePolicyData.categories1 = _.get(nextProps, 'categories1');
      sharePolicyData.forexCurrency1 = _.get(nextProps, 'forexCurrency1');
      sharePolicyData.shareType = _.get(nextProps, 'shareType');
      sharePolicyData.adviserInfo = _.get(nextProps, 'adviserInfo');
      this.props.history.push({
        pathname: '/share_link_view',
        state: { sharePolicyData }
      });
    }
  }

  openModal(data) {
    let { userDetails } = this.props;
    let flag = false;
    let advisorDetails = data.senderId.advisorDetails;
    if (advisorDetails && advisorDetails.length > 0) {
      advisorDetails.map(value => {
        if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
          flag = true;
        }
        return true;
      });
    }
    if (flag) {
      this.setState({ askToDisableTrackingModal: true });
    } else {
      this.setState({
        deleteId: data._id,
        modalIsOpen: true
      });
    }
  }

  closeTrackingFirst() {
    this.setState({ askToDisableTrackingModal: false });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, askToDisableTrackingModal: false });
  }

  //Delete the share view records
  delete() {
    const data = this.state.deleteId;
    this.props.deleteShareFromList(data);
    this.setState({ modalIsOpen: false });
  }
  // To show the policy of share from
  viewSharedPolicy(val) {
    const { getShareMeDocuments } = this.props;
    var formData = {};
    formData.id = val._id;
    this.setState({ selectedID: val.senderId._id, shareID: val._id });
    getShareMeDocuments(formData);
  }

  render() {
    const { shareFromData, intl, userDetails } = this.props;
    // var user = this.props.userDetails;
    // let margin = user.userType === 'User' ? '95px' : '61px';
    return (
      <div>
        {this.props.deleteFromPhase === 'loading' && <LoadingBackdrop open={true} />}
        <div id="wrapper" className="share__page">
          <div className="shareContainer" style={{ marginTop: userDetails.userType === 'User' ? '0px' : '50px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
                width: '100%',
                borderBottom: '1px solid #dddddd'
              }}
            >
              <div style={{ marginTop: '5px', marginBottom: '8px' }}>
                <AladdinHint title={intl.formatMessage({ id: 'share-from-hints' })} />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div style={{ paddingBottom: 'calc(55px + var(--bottom-padding))' }}>
                <div className="JStableOuter share">
                  <table className="share-table">
                    <thead>
                      <tr className="shareDiv">
                        {/*<th className="share-from-table-view-col">{intl.formatMessage({ id: 'View' })}</th>*/}
                        <th className="share-from-table-email-col">{intl.formatMessage({ id: 'Email' })} </th>
                        <th className="share-from-table-type-col">{intl.formatMessage({ id: 'Type' })}</th>
                        <th className="share-from-table-delete-col">{intl.formatMessage({ id: 'Delete' })}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shareFromData &&
                        shareFromData.length > 0 &&
                        shareFromData.map((val, index) => {
                          if (!val.senderId) {
                            return '';
                          }
                          var senderInfo = val.senderId;
                          return (
                            <tr key={index} className="shareDiv">
                              {/*<td className="view-style share-from-table-view-col">*/}
                              {/*  <img*/}
                              {/*    alt=""*/}
                              {/*    className="forwardArrow"*/}
                              {/*    src="img/view.png"*/}
                              {/*    onClick={this.viewSharedPolicy.bind(this, val)}*/}
                              {/*  />*/}
                              {/*</td>*/}
                              <td className="share-from-table-email-col">{senderInfo.email}</td>
                              <td className="share-from-table-type-col">
                                {val.receiverType === 'Advisor'
                                  ? intl.formatMessage({ id: 'Adviser' })
                                  : val.receiverType === 'User'
                                  ? intl.formatMessage({ id: 'Reference' })
                                  : val.receiverType === 'Emergency'
                                  ? intl.formatMessage({ id: 'Emergency' })
                                  : intl.formatMessage({
                                      id: 'Family'
                                    })}
                              </td>
                              <td className="share-from-table-delete-col">
                                <img
                                  alt=""
                                  className="forwardArrow"
                                  src="img/bin_blue.png"
                                  onClick={this.openModal.bind(this, val)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Dialog
                    open={this.state.modalIsOpen}
                    onClose={this.closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete' })}</DialogTitle>
                    <DialogContent>
                      {intl.formatMessage({
                        id: 'Are you sure you want to delete this?'
                      })}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeModal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.delete.bind(this)} color="primary">
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={this.state.askToDisableTrackingModal}
                    onClose={this.closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
                    <DialogContent>
                      {intl.formatMessage({
                        id: 'delete_the_tracking'
                      })}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeTrackingFirst.bind(this)} color="primary">
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShareFromView;
