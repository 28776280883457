import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

const LOCALES = ['zh-Hant-HK', 'en', 'zh'];
const LOCALES_MAP = {
  'zh-Hant-HK': '繁',
  en: 'EN',
  zh: '简'
};

const ContainerStyle = {
  width: 'auto',
  zIndex: 1045
};

const LocaleButtonStyle = {
  border: 0,
  textDecoration: 'underline',
  minWidth: 'unset',
  minHeight: 'unset'
};

const LocaleButton = props => {
  const { style, ...rest } = props;
  return (
    <Button
      style={{
        ...LocaleButtonStyle,
        ...style
      }}
      {...rest}
    />
  );
};

const AdviserInfoLocaleSelector = props => {
  const { locale, textColor, style, onChange, ...rest } = props;

  const filteredLocales = LOCALES.filter(lang => lang !== locale);

  const createOnClickHandler = lang => () => onChange(lang);
  // console.log('window.innerWidth: ', window.innerWidth, ' window.innerWidth:', window.innerHeight);
  if (window.innerWidth > 960 && window.innerHeight < 700) {
    //   // console.log('add 50 px to the top of Language Selector');
    style.top = '50px';
  }

  return (
    <Grid
      container
      alignItems="center"
      justify="flex-end"
      style={{
        ...ContainerStyle,
        ...style
      }}
      {...rest}
    >
      <Grid item>
        <LocaleButton onClick={createOnClickHandler(filteredLocales[0])} style={{ color: textColor }}>
          {LOCALES_MAP[filteredLocales[0]]}
        </LocaleButton>
      </Grid>

      <Grid item>
        <Typography style={{ color: textColor }}>•</Typography>
      </Grid>

      <Grid item>
        <LocaleButton onClick={createOnClickHandler(filteredLocales[1])} style={{ color: textColor }}>
          {LOCALES_MAP[filteredLocales[1]]}
        </LocaleButton>
      </Grid>
    </Grid>
  );
};

export default AdviserInfoLocaleSelector;
