import React, { PureComponent } from 'react';
import { acceptTeamInvitation } from '../../../../store/team/api';

class AcceptTeamInvitation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      invitationId: ''
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.Id) {
      var id = this.props.match.params.Id;
      this.setState({ invitationId: id });
    }
    acceptTeamInvitation({
      invitationId: id
    });
  }

  render() {
    const { intl } = this.props;

    return (
      <div>
        <div id="loginWrapper">
          <div className="page-containt">
            <img alt="" style={{ marginTop: '125px' }} className="logo" src="img/logo_kyc.png" />
            <div className="consent-style">
              <p>
                {intl.formatMessage({
                  id: 'You have accepted the invitation to join the team'
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AcceptTeamInvitation;
