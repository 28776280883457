import React from 'react';
import { injectIntl } from 'react-intl';
import AdviserInfoCardAbstract from '../AdviserInfoCardAbstract/AdviserInfoCardAbstract';
import { Button, Grid, IconButton } from '@material-ui/core';
import MyEditor from '../../../components/MyEditor/MyEditor';
import { formatEditorStateValue, getEditorDefaultMessage } from '../../../utils/editor';
import { formatOpenUrl } from '../../../utils/url';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import NativeOrWeb from '../../../utils/native';
import { ADVISER_LOGO_FUNCTIONS } from '../../../constants/user';
import { getUserLogo } from '../../../utils/user';

const FullWidthStyle = {
  width: '100%'
};

const LogoImageStyle = {
  width: '100%',
  maxHeight: 100,
  objectFit: 'contain'
};

const TeamPageButtonStyle = {
  minHeight: 'unset',
  minWidth: 'unset',
  padding: '6px 18px',
  borderRadius: '16px',
  lineHeight: 1
};

const ImageButtonStyle = {
  padding: 6
};

const ImageStyle = {
  width: 20,
  height: 20
};

const AdviserInfoPageTeam = props => {
  const { intl, adviserInfo, isIntoView } = props;

  const {
    shouldShowProfilePlusTeamLogo,
    teamDescription,
    teamPage,
    teamInstagram,
    teamFacebook,
    teamLinkedin,
    teamWechat,
    teamLogoUrl
  } = adviserInfo || {};

  const formattedTeamLogoUrl = getUserLogo(
    adviserInfo,
    shouldShowProfilePlusTeamLogo && teamLogoUrl,
    ADVISER_LOGO_FUNCTIONS.PROFILE_PLUS
  );
  const formattedTeamDescription = formatEditorStateValue(
    teamDescription || getEditorDefaultMessage(intl.locale, 'profile-plus-team-description')
  );

  const goTeamPage = () => {
    window.open(formatOpenUrl(teamPage), '_blank');
  };

  const goInstagram = () => {
    window.open(formatOpenUrl(`https://www.instagram.com/${teamInstagram}`), '_blank');
  };

  const goFacebook = () => {
    window.open(formatOpenUrl(teamFacebook), '_blank');
  };

  const goLinkedin = () => {
    window.open(formatOpenUrl(teamLinkedin), '_blank');
  };

  const goWechat = () => {
    NativeOrWeb.copyToClipboard(teamWechat || '').then(() => {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
        className: 'new-toast'
      });
    });
  };

  return (
    <AdviserInfoCardAbstract
      className="adviser-info-page-team"
      title={intl.formatMessage({ id: 'adv-info-team-title' })}
      adviserInfo={adviserInfo}
      contentHeight="calc(100% - 110px)"
      isIntoView={isIntoView}
    >
      <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
        <Grid item style={{ ...FullWidthStyle, flexGrow: 1 }} className="content-container">
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            wrap="nowrap"
            spacing={3}
            style={{ height: '100%' }}
          >
            {formattedTeamLogoUrl && (
              <Grid item style={FullWidthStyle}>
                <img src={formattedTeamLogoUrl} alt="team-logo" style={LogoImageStyle} />
              </Grid>
            )}
            <Grid item style={{ ...FullWidthStyle, flexGrow: 1, overflow: 'auto' }}>
              <MyEditor editorState={formattedTeamDescription} readOnly={true} />
            </Grid>
            {(teamPage || teamInstagram || teamFacebook || teamLinkedin || teamWechat) && (
              <Grid item style={FullWidthStyle}>
                <Grid container spacing={1}>
                  {teamPage && (
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <Grid item>
                          <Button color="primary" variant="contained" onClick={goTeamPage} style={TeamPageButtonStyle}>
                            {intl.formatMessage({ id: 'mda-team-page' })}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {(teamInstagram || teamFacebook || teamLinkedin || teamWechat) && (
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justify="center" spacing={1}>
                        {teamInstagram && (
                          <Grid item>
                            <IconButton onClick={goInstagram} style={ImageButtonStyle}>
                              <img src="img/instagram.svg" alt="instagram" style={ImageStyle} />
                            </IconButton>
                          </Grid>
                        )}

                        {teamFacebook && (
                          <Grid item>
                            <IconButton onClick={goFacebook} style={ImageButtonStyle}>
                              <img src="img/facebook.svg" alt="facebook" style={ImageStyle} />
                            </IconButton>
                          </Grid>
                        )}

                        {teamLinkedin && (
                          <Grid item>
                            <IconButton onClick={goLinkedin} style={ImageButtonStyle}>
                              <img src="img/linkedin.svg" alt="linkedin" style={ImageStyle} />
                            </IconButton>
                          </Grid>
                        )}

                        {teamWechat && (
                          <Grid item>
                            <IconButton onClick={goWechat} style={ImageButtonStyle}>
                              <img src="img/wechat.svg" alt="wechat" style={ImageStyle} />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AdviserInfoCardAbstract>
  );
};

export default injectIntl(AdviserInfoPageTeam);
