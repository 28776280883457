import React, { PureComponent } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { withTheme } from '@material-ui/core/styles';
import moment from 'moment';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import MySwipeable from '../../../../components/MySwipeable';
import countryList from '../../../../store/locale/country-list';
import EditIcon from '@material-ui/icons/Edit';
import { Checkbox, Fab, Portal, Typography } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import _ from 'lodash';
import { Button, Dialog, IconButton, InputAdornment } from '@material-ui/core';
import AlertPopUp from '../../../../components/AlertPopup';
import './clientFactsheet.scss';
import PromotionHelpModal from '../../../../components/PromotionHelpModal';
import FactSheetField from './FactSheetField/FactSheetField.js';
import TodayIcon from '@material-ui/icons/Today';
import TagsControl from '../../../../components/ClientEntry/controls/TagsControl/TagsControl';
import TagsDialog from '../../../../components/ClientEntry/TagsDialog/TagsDialog';
import DeleteIconButton from '../../../../components/DeleteIconButton';
import DeleteClientDialog from '../../../../components/ClientEntry/DeleteClientDialog/DeleteClientDialog';
import { getAgeFromDob, getDerivedAge } from '../../../../utils/date';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import ExpenseCalculatorTriggerButton from '../../../../components/ExpenseCalculatorDialog/ExpenseCalculatorTriggerButton/ExpenseCalculatorTriggerButton';
import { tryTranslate } from '../../../../utils/locale';
import RecruitmentHelpModal from '../../../../components/RecruitmentHelpModal/RecruitmentHelpModal';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import DISCDialogComponent from '../../../../components/ClientEntry/DISCDialog/DISCDialogComponent/DISCDialogComponent';
import EditFamilyDialog from '../../../../components/EditFamilyDialog/EditFamilyDialog.js';

const EditFabStyle = { position: 'fixed', bottom: 'calc(55px + var(--bottom-padding))', right: 12 };

class ClientFactsheetView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      phase: 'myrecord',
      modalIsOpen: false,
      edit: '',
      editValue: '',
      age: '',
      connection: false,
      families: [],
      savedFamilies: [],
      savedTags: [],
      tags: [],
      err: {},
      fsReminderActive: false,
      fsReminderDate: null,
      fsReminderDateChange: false,
      click: false,
      editPersonalValue: '',
      clientPersonalityPopup: false,
      msgOpt: false,
      PromotionHelpModalOpen: false,
      isRecruitment: false,
      RecruitmentModelOpen: false,
      editFamilyDialogOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveFactSheet = this.saveFactSheet.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleReminderChange = this.handleReminderChange.bind(this);
    this.handlePersonality = this.handlePersonality.bind(this);
    this.handleEditMsgOptChange = this.handleEditMsgOptChange.bind(this);
  }

  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  UNSAFE_componentWillMount() {
    if (this.props.location && this.props.location.clientId && this.props.location.factsheetId) {
      const { clientDetails } = this.props;
      let obj = { connection: false };
      var dataNew = this.props.clientDetails;
      obj.factsId = dataNew._id;
      obj.families = dataNew.families ? dataNew.families : [];
      obj.savedFamilies = dataNew.families ? dataNew.families : [];
      obj.tags = dataNew.tags ? dataNew.tags : [];
      obj.savedTags = obj.tags ? dataNew.tags : [];
      let user = this.props.clientPersonalDetails ? this.props.clientPersonalDetails : {};

      let userDetails = this.props.userDetails ? this.props.userDetails : {};
      if (user.advisorDetails && userDetails.email && user.advisorDetails.length > 0) {
        user.advisorDetails.map(value => {
          if (value.email === userDetails.email) {
            obj.connection = value.connection;
          }
          return true;
        });
      }
      this.setState({
        fsReminderActive: clientDetails.fsReminderActive,
        fsReminderDate: clientDetails.fsReminderDate
      });

      this.setState(obj);
    } else {
      // go back to client page if there're no client and factsheet ids
      this.props.history.push({ pathname: '/client' });
    }
  }

  //Function to get the connection status of client
  getConnectionStatus = async () => {
    var user = this.props.userDetails;
    let user1 = this.props.clientPersonalDetails;
    let connection = false;
    if (user1 && user1.advisorDetails && user1.advisorDetails.length > 0) {
      await user1.advisorDetails.map(value => {
        if (value.email === user.email) {
          connection = _.get(value, 'connection', false);
        }
        return true;
      });
    } else if (user1.inactiveUser) {
      connection = !_.get(user1, 'inactiveUser', false);
    }
    return connection;
  };

  changeTab = async val => {
    let connection = await this.getConnectionStatus();
    if (connection) {
      this.setState({ phase: val, click: val !== 'myrecord' ? true : false });
    }
    if (!connection) {
      this.setState({ phase: 'myrecord', modalIsOpen: val === 'userprofile' ? true : false });
      // return;
    }
  };

  handleSwipe = async direction => {
    const { phase } = this.state;
    let connection = await this.getConnectionStatus();
    if (connection) {
      if (direction === 'left') {
        if (phase === 'userprofile') {
          this.setState({ phase: 'myrecord', click: false });
        }
      } else if (direction === 'right') {
        if (phase === 'myrecord') {
          this.setState({ phase: 'userprofile', click: true });
        }
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    let obj = {};
    // if (nextProps.fetchDetailsPhase === true && this.state.path) {
    //   this.props.history.push({ pathname: this.state.path });
    // }
    // if (nextProps.fetchDetailsPhase === false) {
    //   this.props.history.push({ pathname: this.state.path });
    // }
    if (this.props.deleteClientPhase !== true && nextProps.deleteClientPhase === true) {
      this.props.history.push('/client');
    }
    if (nextProps.updateFactSheetPhase === true && this.props.updateFactSheetPhase !== true) {
      obj.edit = 'tags';
      obj.clientPersonalityPopup = false;
    }
    this.setState({
      fsReminderActive: _.get(nextProps, 'clientDetails.fsReminderActive'),
      fsReminderDate: _.get(nextProps, 'clientDetails.fsReminderDate'),
      families: _.get(nextProps, 'clientDetails.families', []),
      savedFamilies: _.get(nextProps, 'clientDetails.families', []),
      tags: _.get(nextProps, 'clientDetails.tags', []),
      savedTags: _.get(nextProps, 'clientDetails.tags', []),
      editPersonalValue: _.get(nextProps, 'clientDetails.personality', ''),
      msgOpt: _.get(nextProps, 'clientDetails.msgOpt', false),
      editMsgOpt: _.get(nextProps, 'clientDetails.msgOpt', false),
      isRecruitment: _.get(nextProps, 'clientDetails.isRecruitment', false)
    });
    this.setState(obj);
  }

  //function to change the lates meeting date
  handleLatestMeetingDateChange(date) {
    const { edit } = this.state;
    if (edit === 'latestMeetingDate') {
      this.setState({ editValue: date });
    }
    if (edit === 'fsReminderDate') {
      delete this.state.err.fsReminderDate;
      // var d = new Date();
      // var tz = d.getTimezoneOffset(); /*to get the utc offset of date time of local country  and offset can be negative and positive*/
      // let minuteDiff = -tz; /*change in positive if tz is negative and change in negative if offset if positive*/
      // this.state.err.fsReminderDate = '';
      /*comment the code to add offset*/
      // var fsReminderDate =
      //   date !== undefined && date !== null && date !== ''
      //     ? moment(date)
      //         .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      //         .add(
      //           minuteDiff,
      //           'minutes'
      //         ) /*adding the offset to save the currec date in db bcoz server convert in utc*/
      //     : date;
      var fsReminderDate = date !== undefined && date !== null && date !== '' ? date : date;
      this.setState({ editValue: fsReminderDate, fsReminderDateChange: true });
    }
  }

  editFatchsheetPolicy() {
    this.props.history.push({ pathname: '/client_personal_form' });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  //funciton to change the dob
  handleDateChange(date) {
    this.setState({ editValue: date });
    let years = date ? getDerivedAge(date) : '';
    this.setState({ age: years });
  }

  saveFactSheet() {
    const { clientDetails, clientPersonalDetails } = this.props;
    const { edit, editValue, tags, fsReminderActive } = this.state;
    let formData = {};
    let err = {};
    if (edit === 'dob') {
      var abc = editValue ? moment(editValue).format('YYYY-MM-DD') : '';
      let dob = abc ? moment.utc(abc).format('YYYY-MM-DDT00:00:00.00Z') : '';
      let dob1 = editValue ? moment(editValue).format('DD-MM-YYYY') : '';
      let years = dob1 ? getDerivedAge(moment(dob1, 'DD-MM-YYYY')) : '';
      formData.value = dob;
      formData.key = edit;
      formData.age = this.state.age ? this.state.age : years;
    } else {
      formData.value = this.state.editValue;
      formData.key = this.state.edit;
    }

    if (edit === 'latestMeetingDate') {
      var latesMeetingFormat = editValue ? moment(editValue).format('YYYY-MM-DD') : null;
      var latestMeeting = latesMeetingFormat ? moment.utc(latesMeetingFormat).format('YYYY-MM-DDT00:00:00.00Z') : '';
      formData.value = latestMeeting;
    }
    if (this.state.edit === 'monthlyIncome') {
      formData.value = parseInt(this.state.editValue);
    }
    if (this.state.edit === 'monthlyExpense') {
      formData.value = parseInt(this.state.editValue);
    }
    if (this.state.edit === 'debt') {
      formData.value = this.state.editValue;
    }
    if (this.state.edit === 'name' && this.state.editValue === '') {
      err.name = 'Name is required';
    }
    if (
      edit === 'fsReminderDate' &&
      fsReminderActive === true &&
      (editValue === null || editValue === undefined || editValue === '')
    ) {
      err.fsReminderDate = 'Please set date';
    }
    if (edit === 'fsReminderDate' && fsReminderActive === true) {
      var date =
        editValue !== null && editValue !== undefined && editValue !== ''
          ? moment(editValue).format('YYYY-MM-DD')
          : null;
      var fs_Reminder_Date = date !== null ? moment.utc(date).format('YYYY-MM-DDT00:00:00.00Z') : null;
      formData.value = fs_Reminder_Date;
      formData.fsReminderActive = fsReminderActive;
    }
    if (edit === 'msgOpt') {
      formData.value = this.state.editMsgOpt;
    }
    if (edit === 'isRecruitment') {
      formData.value = this.state.editIsRecruitment;
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      if (clientPersonalDetails && clientPersonalDetails.inactiveUser) {
        formData.id = clientPersonalDetails.factsheetId;
      } else {
        formData.id = clientDetails._id;
      }
      formData.clientId = _.get(clientPersonalDetails, '_id', '');
      formData.isInactive = _.get(clientPersonalDetails, 'inactiveUser', false);
      //tag: only set the object if old_data!=new_data
      if (this.state.edit === 'tags') {
        var old_data = this.state.savedTags !== null && this.state.savedTags !== undefined ? this.state.savedTags : [];
        if (!_.isEqual(old_data, editValue)) {
          formData.usedTags = {
            old_data: old_data,
            new_data: editValue
          };
        }
      }
      this.props.updateFactSheet(formData);
    }

    // close the modal if the current edit value is tag
    if (this.state.edit === 'tags') {
      this.setState({
        savedTags: tags,
        edit: '',
        editValue: ''
      });
    }
  }

  refreshData = oldFamilyName => {
    if (oldFamilyName){
      this.props.getClientDetails({ userId: this.props.viewingClient._id, factsheetId: this.props.viewingClient.factsheetId._id });
    }
  }

  handleReminderChange() {
    const { fsReminderActive, editValue } = this.state;
    this.setState({
      fsReminderActive: !fsReminderActive,
      editValue: !fsReminderActive ? editValue : null,
      err: {}
    });
  }

  isNullOrEmpty(str) {
    return !str.trim();
  }

  handlePersonality(personality) {
    this.setState({ personality: personality, editValue: personality, edit: 'personality' }, () => {
      this.saveFactSheet();
    });
  }

  handleEditMsgOptChange() {
    this.setState({
      editMsgOpt: !this.state.editMsgOpt
    });
  }

  delete = () => {
    this.props.toggleDialog('deleteClient', { client: this.props.viewingClient });
  };

  render() {
    const { intl, history, clientFactsheetPhase, updateFactSheetPhase, userDetails } = this.props;
    const {
      phase,
      //connection,
      // fsReminderDate,
      fsReminderActive,
      editValue,
      edit,
      editPersonalValue,
      modalIsOpen,
      msgOpt,
      PromotionHelpModalOpen,
      RecruitmentModelOpen
    } = this.state;
    let clientPersonality = edit === 'personality' ? editValue : editPersonalValue;
    /*To show the correct date in date picker because date picker taking the previous day in negative utc therfore we add the  offset to selected date or database date*/
    const maxDate = new Date();
    // const fs1 =
    //   fsReminderDate !== null &&
    //   fsReminderDate !== undefined &&
    //   fsReminderDate !== ''
    //     ? moment(fsReminderDate)
    //         .utcOffset(tz)
    //         .format('DD-MMM-YYYY')
    //     : null;
    // let fs2 = '';
    // if (edit === 'fsReminderDate') {
    //   fs2 =
    //     editValue !== null && editValue !== undefined && editValue !== ''
    //       ? moment(editValue)
    //           .utcOffset(tz)
    //           .format('DD-MMM-YYYY')
    //       : null;
    // }
    let val = this.props.clientDetails;
    let user = this.props.clientPersonalDetails;
    val = val ? val : {};
    user = user ? user : {};
    // var userDetails = this.props.userDetails;
    let cancelWord = intl.formatMessage({ id: 'Cancel' }),
      saveWord = intl.formatMessage({ id: 'Save' }),
      naWord = intl.formatMessage({ id: 'N/A' });
    let emailShow = false;
    let clientId = window.localStorage.getItem('clientId');
    if (val.email) {
      let _id =
        user && user._id !== undefined && user._id !== null && user._id !== ''
          ? user._id.toString()
          : clientId
            ? clientId.toString()
            : '';
      let inactiveEmail = val.email;
      if (inactiveEmail.indexOf(_id) !== -1) {
        emailShow = true;
      } else {
        emailShow = false;
      }
    }

    let itemArray = [
      'Name',
      'Email',
      'existing-client',
      'client-type',
      'Referrer',
      'Importance',
      'client-source',
      'Latest Meeting',
      'Remind me',
      'Quick Notes',
      'WhatsApp',
      'DoB',
      'Age',
      'Smoker',
      'Nationality',
      'Place of Birth',
      'Occupation',
      'Work Location',
      'Home Location',
      'WeChat ID',
      'Line ID',
      'Marriage Status',
      'No. of Child',
      'Monthly Income',
      'Monthly Expense',
      'Liquid Asset',
      'Debt',
      'MPF',
      'Insurance',
      'Investment',
      'Budget',
      'Interest',
      'Remark',
      'Gender',
      'Tel',
      'Personality',
      'message-opt',
      'recruit-lead'
    ];

    let itemWord = {};
    itemArray.forEach(function (value) {
      itemWord[value] = intl.formatMessage({ id: value });
    });
    const date = new Date();
    const date1 = moment(date).format('YYYY-MM-DD');
    const age = getAgeFromDob(val.dob, val.age);
    let usedTags = userDetails.usedTags ? userDetails.usedTags : [];
    var importTag = this.state.tags !== undefined && this.state.tags !== null ? this.state.tags : [];
    if (importTag.length > 0) {
      importTag = importTag.map(value => {
        return {
          id: value.text,
          text: value.text
        };
      });
    }

    return (
      <div>
        <TagsDialog />
        <DeleteClientDialog />
        <EditFamilyDialog
          intl={intl}
          open={this.state.editFamilyDialogOpen}
          closeDialog={() => this.setState({ editFamilyDialogOpen: false, edit: "", editValue: "" })}
          selectedFamilies={this.state.families}
          savedFamilies={this.state.savedFamilies}
          setSelectedFamilies={families => this.setState({ families: families, edit: 'families', editValue: families })}
          saveFactSheet={this.saveFactSheet}
          refreshData={this.refreshData}
          manageOnly={false}
        />

        {phase === 'myrecord' && (
          <Portal container={document.body}>
            <Fab
              color="primary"
              size="medium"
              variant="round"
              onClick={this.editFatchsheetPolicy.bind(this)}
              style={EditFabStyle}
            >
              <EditIcon />
            </Fab>
          </Portal>
        )}

        <div>
          {clientFactsheetPhase === 'loading' && <LoadingBackdrop open={true} />}

          <TopNavigationPortal>
            <DeleteIconButton className="edit__pen" handleClick={this.delete} />
          </TopNavigationPortal>
        </div>
        <Portal container={document.body}>
          <div className="nav-item">
            <ul className="nav nav-tabs tabs-line text-center factsheetTabs" role="tablist">
              <li onClick={this.changeTab.bind(this, 'myrecord')} className={phase === 'myrecord' ? 'active' : ''}>
                <a>{intl.formatMessage({ id: 'My Record' })}</a>
              </li>
              <li
                onClick={this.changeTab.bind(this, 'userprofile')}
                className={phase === 'userprofile' ? '' : 'noncolor'}
              >
                <a className={phase === 'userprofile' ? 'bluescolor' : 'nocolor'}>
                  {intl.formatMessage({ id: 'Client Record' })}
                </a>
              </li>
            </ul>
          </div>
        </Portal>
        <div id="wrapper">
          <div id="page-content-wrapper" className="option-button-container">
            <div className="container-fluid" style={{ paddingBottom: 65 }}>
              <div className="row">
                <MySwipeable handleSwipe={this.handleSwipe}>
                  <div>
                    <div id="clientFactSheetDetailCSS" className="listPipeline_details">
                      <div>
                        {phase === 'myrecord' ? (
                          <ul>
                            <li
                              className="head"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <AladdinHint title={intl.formatMessage({ id: 'click-for-edit' })} />
                            </li>
                            <FactSheetField
                              leftTitle={itemWord['Name']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.name}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'name',
                                  editValue: val.name
                                })
                              }
                              isEditing={this.state.edit === 'name'}
                              errorField={this.state.err.name ? this.state.err.name : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={intl.formatMessage({ id: 'nickname' })}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.nickname}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'nickname',
                                  editValue: val.nickname
                                })
                              }
                              isEditing={this.state.edit === 'nickname'}
                              errorField={this.state.err.nickname ? this.state.err.nickname : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['existing-client']}
                              displayValue={
                                !!val.existingClient ? (
                                  <span>
                                    <img
                                      src={
                                        val.existingClient === 'Yes'
                                          ? 'img/existingClient.svg'
                                          : 'img/nonExistingClient.svg'
                                      }
                                      alt="existing-client"
                                      width="20"
                                      style={{
                                        margin: '0 6px',
                                        verticalAlign: 'sub'
                                      }}
                                    />
                                    {tryTranslate(intl, val.existingClient)}
                                  </span>
                                ) : (
                                  ''
                                )
                              }
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'existingClient',
                                  editValue: val.existingClient
                                })
                              }
                              isEditing={this.state.edit === 'existingClient'}
                              errorField={this.state.err.existingClient ? this.state.err.existingClient : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  <img
                                    src="img/existingClient.svg"
                                    alt="existing-client"
                                    width="20"
                                    style={{ margin: '0 6px' }}
                                  />
                                  <Button
                                    variant={this.state.editValue === 'Yes' ? 'contained' : 'outlined'}
                                    className={`option-select-button plan-type-select-button${this.state.editValue === 'Yes' ? ` selected` : ''
                                      }`}
                                    onClick={() =>
                                      this.setState({ editValue: this.state.editValue === 'Yes' ? null : 'Yes' })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Yes' })}
                                  </Button>
                                  <img
                                    src="img/nonExistingClient.svg"
                                    alt="non-existing-client"
                                    width="20"
                                    style={{ margin: '0 6px' }}
                                  />
                                  <Button
                                    variant={this.state.editValue === 'No' ? 'contained' : 'outlined'}
                                    className={`option-select-button plan-type-select-button${this.state.editValue === 'No' ? ` selected` : ''
                                      }`}
                                    onClick={() =>
                                      this.setState({ editValue: this.state.editValue === 'No' ? null : 'No' })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'No' })}
                                  </Button>
                                </div>
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['client-type']}
                              displayValue={val.source}
                              inputType={'formatMessage'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'source',
                                  editValue: val.source
                                })
                              }
                              isEditing={this.state.edit === 'source'}
                              errorField={this.state.err.source ? this.state.err.source : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  {' '}
                                  <Button
                                    variant={this.state.editValue === 'Warm' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Warm'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    key={0}
                                    onClick={() => this.setState({ editValue: 'Warm' })}
                                  >
                                    {intl.formatMessage({ id: 'Warm' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'Refer' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Refer'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => this.setState({ editValue: 'Refer' })}
                                  >
                                    {intl.formatMessage({ id: 'Refer' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'Cold' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Cold'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => this.setState({ editValue: 'Cold' })}
                                  >
                                    {intl.formatMessage({ id: 'Cold' })}
                                  </Button>
                                </div>
                              }
                            />
                            <FactSheetField
                              leftTitle={itemWord['Referrer']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.referrerName}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'referrerName',
                                  editValue: val.referrerName
                                })
                              }
                              isEditing={this.state.edit === 'referrerName'}
                              errorField={this.state.err.referrerName ? this.state.err.referrerName : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['Importance']}
                              displayValue={val.importance}
                              inputType={'formatMessage'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'importance',
                                  editValue: val.importance
                                })
                              }
                              isEditing={this.state.edit === 'importance'}
                              errorField={this.state.err.importance ? this.state.err.importance : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  {' '}
                                  <Button
                                    variant={this.state.editValue === 'Normal' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Normal'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    key={0}
                                    onClick={() => this.setState({ editValue: 'Normal' })}
                                  >
                                    {intl.formatMessage({ id: 'Normal' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'VIP' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'VIP'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => this.setState({ editValue: 'VIP' })}
                                  >
                                    {intl.formatMessage({ id: 'VIP' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'V.VIP' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'V.VIP'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => this.setState({ editValue: 'V.VIP' })}
                                  >
                                    {intl.formatMessage({ id: 'V.VIP' })}
                                  </Button>
                                </div>
                              }
                            />

                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'family' })}</div>
                              <div
                                className="left-value"
                                onClick={() => this.setState({ editFamilyDialogOpen: true })}
                              >
                                {
                                  this.state.families.map((family, index) => (
                                    <span
                                      className='familyTag'
                                      key={index}
                                    >
                                      {family}
                                    </span>
                                  ))
                                }
                                {!this.state.families || this.state.families.length === 0 && 
                                <div className="ReactTags__selected">
                                  <span
                                    className="tag-wrapper ReactTags__tag-no-remove"
                                    style={{ color: this.props.theme.palette.primary.main }}
                                  >
                                    + 🏠&nbsp;
                                  </span>
                                  </div>
                                }
                              </div>
                            </li>

                            <li>
                              <div className="left-title">{intl.formatMessage({ id: 'Tag' })}</div>
                              <div
                                className="left-value"
                                onClick={() =>
                                  this.setState({
                                    edit: 'tags',
                                    editValue: val.tags
                                  })
                                }
                              >
                                {/* If there is tag, display it */}
                                {val.tags !== undefined && val.tags !== null && (
                                  <TagsControl
                                    tags={val.tags}
                                    style={{ margin: 0 }}
                                    onChange={tags => this.setState({ editValue: tags }, () => this.saveFactSheet())}
                                    addTagButton={true}
                                  />
                                )}
                              </div>
                            </li>

                            <FactSheetField
                              leftTitle={itemWord['Quick Notes']}
                              displayValue={
                                <div style={{ whiteSpace: 'pre-wrap', display: 'inline-block' }}>{val.remark}</div>
                              }
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'remark',
                                  editValue: val.remark
                                })
                              }
                              isEditing={this.state.edit === 'remark'}
                              errorField={this.state.err.remark ? this.state.err.remark : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <textarea
                                  type="text"
                                  name="remark"
                                  className="left-value-input"
                                  value={this.state.editValue}
                                  onChange={event =>
                                    this.setState({
                                      editValue: event.target.value
                                    })
                                  }
                                  rows="3"
                                />
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['client-source']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.network}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'network',
                                  editValue: val.network
                                })
                              }
                              isEditing={this.state.edit === 'network'}
                              errorField={this.state.err.network ? this.state.err.network : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Latest Meeting']}
                              displayValue={val.latestMeetingDate}
                              inputType={'date'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'latestMeetingDate',
                                  editValue:
                                    val.latestMeetingDate !== null &&
                                      val.latestMeetingDate !== undefined &&
                                      val.latestMeetingDate !== ''
                                      ? moment.parseZone(val.latestMeetingDate)
                                      : null
                                })
                              }
                              isEditing={this.state.edit === 'latestMeetingDate'}
                              errorField={
                                this.state.err.latestMeetingDate ? this.state.err.latestMeetingDate : undefined
                              }
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="">
                                  <DatePicker
                                    name="latestMeetingDate"
                                    maxDate={moment().add(7, 'days')}
                                    format="DD-MMM-YYYY"
                                    clearable={true}
                                    value={
                                      this.state.edit !== 'latestMeetingDate' ||
                                        this.state.editValue === undefined ||
                                        this.state.editValue === null ||
                                        this.state.editValue === ''
                                        ? null
                                        : moment.parseZone(this.state.editValue)
                                    }
                                    onChange={date => this.handleLatestMeetingDateChange(date)}
                                    autoOk={true}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            style={{
                                              padding: '0 5px 0 0',
                                              marginLeft: '-12px'
                                            }}
                                          >
                                            <TodayIcon color="action" style={{ fontSize: 20 }} />
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                  />
                                </div>
                              }
                            />

                            <li style={{ alignItems: 'center', height: 45 }}>
                              <div className="left-title">{itemWord['message-opt']}</div>
                              <HelpOutlineIcon
                                color="primary"
                                className="client--personality-info"
                                style={{ paddingBottom: '5px', cursor: 'pointer' }}
                                onClick={() => this.setState({ PromotionHelpModalOpen: true })}
                              />
                              <div className="left-value">
                                <div
                                  onChange={() =>
                                    this.setState(
                                      {
                                        edit: 'msgOpt',
                                        editMsgOpt: !this.state.msgOpt
                                      },
                                      this.saveFactSheet
                                    )
                                  }
                                >
                                  <Checkbox checked={msgOpt} color="primary" />
                                </div>
                              </div>
                            </li>

                            <li style={{ alignItems: 'center', height: 45 }}>
                              <div className="left-title">{itemWord['recruit-lead']}</div>
                              <HelpOutlineIcon
                                color="primary"
                                className="client--personality-info"
                                style={{ paddingBottom: '5px', cursor: 'pointer' }}
                                onClick={() => this.setState({ RecruitmentModelOpen: true })}
                              />
                              <div className="left-value">
                                <div
                                  onChange={() => {
                                    console.log('client fact sheet state: ', this.state)
                                    const nextState = !this.state.isRecruitment
                                    this.setState(
                                      {
                                        edit: 'isRecruitment',
                                        editIsRecruitment: nextState
                                      },
                                      this.saveFactSheet
                                    )

                                    if (nextState) {
                                      const trackAddedPotentialRecruitment = require('../../../../utils/tracking/index').trackAddedPotentialRecruitment;
                                      trackAddedPotentialRecruitment({
                                        "Facesheet ID": val._id,
                                        "Source": val.source,
                                        "Create Date": val.createdate,
                                        "Latest Meeting Date": val.latestMeetingDate
                                      })
                                    }
                                  }
                                  }
                                >
                                  <Checkbox checked={this.state.isRecruitment} color="primary" />
                                </div>
                              </div>
                            </li>

                            <FactSheetField
                              leftTitle={itemWord['Remind me']}
                              leftCSS={fsReminderActive ? 'left-title' : 'left-title-disable'}
                              displayValue={val.fsReminderDate}
                              inputType={'date'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'fsReminderDate',
                                  editValue:
                                    val.fsReminderDate !== null &&
                                      val.fsReminderDate !== undefined &&
                                      val.fsReminderDate !== ''
                                      ? moment.parseZone(val.fsReminderDate)
                                      : null
                                })
                              }
                              isEditing={this.state.edit === 'fsReminderDate'}
                              errorField={this.state.err.fsReminderDate ? this.state.err.fsReminderDate : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  <DatePicker
                                    name="fsReminderDate"
                                    format="DD-MMM-YYYY"
                                    clearable={true}
                                    minDate={new Date(date1)}
                                    value={
                                      this.state.edit !== 'fsReminderDate' ||
                                        this.state.editValue === undefined ||
                                        this.state.editValue === null ||
                                        this.state.editValue === ''
                                        ? null
                                        : moment.parseZone(editValue)
                                    }
                                    openTo={true}
                                    onChange={date => this.handleLatestMeetingDateChange(date)}
                                    autoOk={true}
                                    invalidDateMessage={null}
                                    minDateMessage={null}
                                    maxDateMessage={null}
                                    disabled={!fsReminderActive}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    InputProps={
                                      this.state.fsReminderActive && {
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              style={{
                                                padding: '0 5px 0 0',
                                                marginLeft: '-12px'
                                              }}
                                            >
                                              <TodayIcon color="action" style={{ fontSize: 20 }} />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }
                                    }
                                  />
                                  <div
                                    className="form-group"
                                    style={{
                                      marginBottom: '0px',
                                      textAlign: 'center'
                                    }}
                                  >
                                    <Checkbox
                                      name="fsReminderActive"
                                      value="fsReminderActive"
                                      checked={fsReminderActive}
                                      onChange={this.handleReminderChange}
                                      color="primary"
                                    />
                                  </div>
                                </div>
                              }
                            />

                            <li>
                              <div className="left-title" style={{ paddingTop: '5px' }}>
                                {itemWord['Personality']}
                              </div>
                              <HelpOutlineIcon
                                color="primary"
                                className="client--personality-info"
                                style={{ paddingBottom: '5px' }}
                                onClick={() => {
                                  this.setState({ clientPersonalityPopup: true });
                                }}
                              />
                              <div className="left-value">
                                {/* {this.state.edit == 'personality' ? ( */}
                                <div>
                                  {' '}
                                  <Button
                                    variant="contained"
                                    className="portfolio-default-button button-line-height"
                                    style={{
                                      background: clientPersonality === 'D' ? '#EA8462' : '#cccccc',
                                      color: clientPersonality === 'D' ? '#ffffff' : '#000'
                                    }}
                                    key={0}
                                    onClick={() =>
                                      this.setState({
                                        editValue: clientPersonality !== 'D' ? 'D' : '',
                                        edit: 'personality'
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'personality_D' })}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="portfolio-default-button button-line-height"
                                    style={{
                                      background: clientPersonality === 'I' ? '#EAC862' : '#cccccc',
                                      color: clientPersonality === 'I' ? '#ffffff' : '#000'
                                    }}
                                    key={1}
                                    onClick={() =>
                                      this.setState({
                                        editValue: clientPersonality !== 'I' ? 'I' : '',
                                        edit: 'personality'
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'personality_I' })}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="portfolio-default-button button-line-height"
                                    style={{
                                      background: clientPersonality === 'S' ? '#62EA84' : '#cccccc',
                                      color: clientPersonality === 'S' ? '#ffffff' : '#000'
                                    }}
                                    key={2}
                                    onClick={() =>
                                      this.setState({
                                        editValue: clientPersonality !== 'S' ? 'S' : '',
                                        edit: 'personality'
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'personality_S' })}
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="portfolio-default-button button-line-height"
                                    style={{
                                      background: clientPersonality === 'C' ? '#62A6EA' : '#cccccc',
                                      color: clientPersonality === 'C' ? '#ffffff' : '#000'
                                    }}
                                    key={3}
                                    onClick={() =>
                                      this.setState({
                                        editValue: clientPersonality !== 'C' ? 'C' : '',
                                        edit: 'personality'
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'personality_C' })}
                                  </Button>
                                  {this.state.edit === 'personality' && (
                                    <div>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="factsheet-edit-button"
                                        onClick={() => this.setState({ edit: '' })}
                                      >
                                        {cancelWord}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="factsheet-edit-button"
                                        disabled={updateFactSheetPhase === 'loading' ? true : false}
                                        onClick={this.saveFactSheet}
                                      >
                                        {saveWord}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="left-title">{itemWord['Email']}</div>
                              <div className="left-value">
                                <div>{!emailShow ? val.email : ''}</div>
                              </div>
                            </li>

                            <FactSheetField
                              leftTitle={itemWord['Tel']}
                              displayValue={val.phoneNumber}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'phoneNumber',
                                  editValue: val.phoneNumber
                                })
                              }
                              isEditing={this.state.edit === 'phoneNumber'}
                              errorField={this.state.err.phoneNumber ? this.state.err.phoneNumber : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <Phone
                                  international={true}
                                  country="HK"
                                  placeholder={intl.formatMessage({
                                    id: 'Enter phone number'
                                  })}
                                  value={this.state.editValue}
                                  onChange={event => {
                                    this.setState({
                                      editValue: event ? event : ''
                                    });
                                  }}
                                  countries={countryList.labels}
                                  flagsPath={'img/flags/'}
                                  indicateInvalid
                                />
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['WhatsApp']}
                              displayValue={val.whatsapp}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'whatsapp',
                                  editValue: val.whatsapp
                                })
                              }
                              isEditing={this.state.edit === 'whatsapp'}
                              errorField={this.state.err.whatsapp ? this.state.err.whatsapp : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <Phone
                                  international={true}
                                  country="HK"
                                  placeholder={intl.formatMessage({
                                    id: 'Enter phone number'
                                  })}
                                  value={this.state.editValue}
                                  onChange={event => {
                                    this.setState({
                                      editValue: event ? event : ''
                                    });
                                  }}
                                  countries={countryList.labels}
                                  flagsPath={'img/flags/'}
                                  indicateInvalid
                                />
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['DoB']}
                              leftCSS={'left-title violet__label'}
                              displayValue={val.dob}
                              inputType={'date'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'dob',
                                  editValue:
                                    val.dob !== null && val.dob !== undefined && val.dob !== ''
                                      ? moment.parseZone(val.dob)
                                      : null
                                })
                              }
                              isEditing={this.state.edit === 'dob'}
                              errorField={this.state.err.dob ? this.state.err.dob : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="">
                                  <DatePicker
                                    name="dob"
                                    maxDate={maxDate}
                                    format="DD-MMM-YYYY"
                                    clearable={true}
                                    value={
                                      this.state.edit !== 'dob' ||
                                        this.state.editValue === undefined ||
                                        this.state.editValue === null ||
                                        this.state.editValue === ''
                                        ? null
                                        : moment.parseZone(
                                          this.state.editValue
                                        ) /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
                                    }
                                    openTo={true}
                                    onChange={date => this.handleDateChange(date)}
                                    autoOk={true}
                                    initialFocusedDate={moment().subtract(30, "years")}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            style={{
                                              padding: '0 5px 0 0',
                                              marginLeft: '-12px'
                                            }}
                                          >
                                            <TodayIcon color="action" style={{ fontSize: 20 }} />
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                                    inputProps={{ style: { textAlign: 'center' } }}
                                  />
                                </div>
                              }
                            />

                            <li>
                              <div className="left-title">{itemWord['Age']}</div>
                              <div className="left-value">
                                <div>
                                  {this.state.edit === 'age' ? (
                                    <div>
                                      <input
                                        type="number"
                                        name="age"
                                        className="left-value-input"
                                        value={this.state.editValue}
                                        pattern="[0-9]*"
                                        onChange={event =>
                                          this.setState({
                                            editValue: event.target.value
                                          })
                                        }
                                      />
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="factsheet-edit-button"
                                        onClick={() => this.setState({ edit: '' })}
                                      >
                                        {cancelWord}
                                      </Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="factsheet-edit-button"
                                        disabled={updateFactSheetPhase === 'loading' ? true : false}
                                        onClick={this.saveFactSheet}
                                      >
                                        {saveWord}
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>
                                      {val.dob === undefined || val.dob === null || val.dob === '' ? (
                                        <div
                                          className="fs-value"
                                          onClick={() =>
                                            this.setState({
                                              edit: 'age',
                                              editValue: age ? age : ''
                                            })
                                          }
                                        >
                                          {age ? (
                                            age
                                          ) : (
                                            <span
                                              className="left-null-value"
                                              onClick={() =>
                                                this.setState({
                                                  edit: 'age',
                                                  editValue: age ? age : ''
                                                })
                                              }
                                            >
                                              {naWord}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        age
                                      )}
                                      <EditIcon className="fs-value-edit-icon" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                            <FactSheetField
                              leftTitle={itemWord['Gender']}
                              leftCSS={'left-title violet__label'}
                              displayValue={val.gender}
                              inputType={'formatMessage'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'gender',
                                  editValue: val.gender
                                })
                              }
                              isEditing={this.state.edit === 'gender'}
                              errorField={this.state.err.gender ? this.state.err.gender : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  <Button
                                    variant={this.state.editValue === 'Male' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Male'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => {
                                      this.setState({
                                        edit: 'gender',
                                        editValue: 'Male'
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'Male' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'Female' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Female'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => {
                                      this.setState({
                                        edit: 'gender',
                                        editValue: 'Female'
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'Female' })}
                                  </Button>
                                </div>
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['Smoker']}
                              leftCSS={'left-title violet__label'}
                              displayValue={val.smoker}
                              inputType={'formatMessage'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'smoker',
                                  editValue: val.smoker
                                })
                              }
                              isEditing={this.state.edit === 'smoker'}
                              errorField={this.state.err.smoker ? this.state.err.smoker : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  <Button
                                    variant={this.state.editValue === 'No' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'No'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => {
                                      this.setState({
                                        edit: 'smoker',
                                        editValue: 'No'
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'No' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'Yes' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Yes'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => {
                                      this.setState({
                                        edit: 'smoker',
                                        editValue: 'Yes'
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'Yes' })}
                                  </Button>{' '}
                                </div>
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['Nationality']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.nationality}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'nationality',
                                  editValue: val.nationality
                                })
                              }
                              isEditing={this.state.edit === 'nationality'}
                              errorField={this.state.err.nationality ? this.state.err.nationality : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Place of Birth']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.birthPlace}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'birthPlace',
                                  editValue: val.birthPlace
                                })
                              }
                              isEditing={this.state.edit === 'birthPlace'}
                              errorField={this.state.err.birthPlace ? this.state.err.birthPlace : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Occupation']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.occupation}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'occupation',
                                  editValue: val.occupation
                                })
                              }
                              isEditing={this.state.edit === 'occupation'}
                              errorField={this.state.err.occupation ? this.state.err.occupation : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Work Location']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.workLocation}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'workLocation',
                                  editValue: val.workLocation
                                })
                              }
                              isEditing={this.state.edit === 'workLocation'}
                              errorField={this.state.err.workLocation ? this.state.err.workLocation : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Home Location']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.homeLocation}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'homeLocation',
                                  editValue: val.homeLocation
                                })
                              }
                              isEditing={this.state.edit === 'homeLocation'}
                              errorField={this.state.err.homeLocation ? this.state.err.homeLocation : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['WeChat ID']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.wechatId}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'wechatId',
                                  editValue: val.wechatId
                                })
                              }
                              isEditing={this.state.edit === 'wechatId'}
                              errorField={this.state.err.wechatId ? this.state.err.wechatId : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Line ID']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.lineId}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'lineId',
                                  editValue: val.lineId
                                })
                              }
                              isEditing={this.state.edit === 'lineId'}
                              errorField={this.state.err.lineId ? this.state.err.lineId : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Marriage Status']}
                              leftCSS={'left-title violet__label'}
                              displayValue={val.marriageStatus}
                              inputType={'formatMessage'}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'marriageStatus',
                                  editValue: val.marriageStatus
                                })
                              }
                              isEditing={this.state.edit === 'marriageStatus'}
                              errorField={this.state.err.marriageStatus ? this.state.err.marriageStatus : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="button-option-wrapper">
                                  <Button
                                    variant={this.state.editValue === 'Single' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Single'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => {
                                      this.setState({
                                        edit: 'marriageStatus',
                                        editValue: 'Single'
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'Single' })}
                                  </Button>
                                  <Button
                                    variant={this.state.editValue === 'Married' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.editValue === 'Married'
                                        ? 'option-select-button plan-type-select-button selected'
                                        : 'option-select-button plan-type-select-button'
                                    }
                                    onClick={() => {
                                      this.setState({
                                        edit: 'marriageStatus',
                                        editValue: 'Married'
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'Married' })}
                                  </Button>
                                </div>
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['No. of Child']}
                              leftCSS={'left-title violet__label'}
                              displayValue={val.child}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'child',
                                  editValue: val.child ? val.child.toString() : null
                                })
                              }
                              isEditing={this.state.edit === 'child'}
                              errorField={this.state.err.child ? this.state.err.child : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <div className="">
                                  <Button
                                    variant={this.state.editValue !== '9' ? 'contained' : 'outlined'}
                                    className="factsheet-edit-button"
                                    key={1}
                                    color={this.state.editValue !== '9' ? 'primary' : 'default'}
                                    disabled={this.state.editValue === '9' ? true : false}
                                    onClick={() =>
                                      this.setState({
                                        editValue:
                                          this.state.editValue === '9'
                                            ? '9'
                                            : (
                                              parseInt(
                                                this.state.editValue === '' || this.state.editValue === null
                                                  ? '0'
                                                  : this.state.editValue
                                              ) +
                                              (this.state.editValue === null || this.state.editValue === '' ? 0 : 1)
                                            ).toString()
                                      })
                                    }
                                  >
                                    +
                                  </Button>
                                  <span className="number-of-child">{this.state.editValue}</span>
                                  <Button
                                    variant={this.state.editValue !== null ? 'contained' : 'outlined'}
                                    className="factsheet-edit-button"
                                    color={
                                      this.state.editValue !== null && this.state.editValue !== ''
                                        ? 'primary'
                                        : 'default'
                                    }
                                    disabled={
                                      this.state.editValue === null || this.state.editValue === '' ? true : false
                                    }
                                    key={0}
                                    onClick={() =>
                                      this.setState({
                                        editValue:
                                          this.state.editValue === '0' ||
                                            this.state.editValue === null ||
                                            this.state.editValue === ''
                                            ? null
                                            : (
                                              parseInt(
                                                this.state.editValue === '' || this.state.editValue === '0'
                                                  ? '1'
                                                  : this.state.editValue
                                              ) - 1
                                            ).toString()
                                      })
                                    }
                                  >
                                    -
                                  </Button>
                                </div>
                              }
                            />

                            <FactSheetField
                              leftTitle={itemWord['Monthly Income']}
                              leftCSS={'left-title violet__label'}
                              editValue={this.state.editValue}
                              editValueOnChange={values => {
                                const { value } = values;
                                this.setState({ editValue: value });
                              }}
                              displayValue={val.monthlyIncome}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'monthlyIncome',
                                  editValue: val.monthlyIncome
                                })
                              }
                              isEditing={this.state.edit === 'monthlyIncome'}
                              errorField={this.state.err.monthlyIncome ? this.state.err.monthlyIncome : undefined}
                              inputType={'currency'}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['Monthly Expense']}
                              leftCSS={'left-title violet__label'}
                              editValue={this.state.editValue}
                              editValueOnChange={values => {
                                const { value } = values;
                                this.setState({ editValue: value });
                              }}
                              displayValue={val.monthlyExpense}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'monthlyExpense',
                                  editValue: val.monthlyExpense
                                })
                              }
                              isEditing={this.state.edit === 'monthlyExpense'}
                              errorField={this.state.err.monthlyExpense ? this.state.err.monthlyExpense : undefined}
                              inputType={'currency'}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              editInputExtras={
                                clientId &&
                                val._id && (
                                  <ExpenseCalculatorTriggerButton
                                    clientId={JSON.parse(clientId)}
                                    factsheetId={val._id}
                                    expenses={val ? val.expenses : undefined}
                                    onSubmit={total => this.setState({ editValue: total })}
                                    style={{ position: 'absolute', right: 0, top: 0 }}
                                  />
                                )
                              }
                            />
                            <FactSheetField
                              leftTitle={itemWord['Liquid Asset']}
                              editValue={this.state.editValue}
                              editValueOnChange={values => {
                                const { value } = values;
                                this.setState({ editValue: value });
                              }}
                              displayValue={val.liquidAsset}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'liquidAsset',
                                  editValue: val.liquidAsset
                                })
                              }
                              isEditing={this.state.edit === 'liquidAsset'}
                              errorField={this.state.err.liquidAsset ? this.state.err.liquidAsset : undefined}
                              inputType={'currency'}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['Debt']}
                              editValue={this.state.editValue}
                              editValueOnChange={values => {
                                const { value } = values;
                                this.setState({ editValue: value });
                              }}
                              displayValue={val.debt}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'debt',
                                  editValue: val.debt
                                })
                              }
                              isEditing={this.state.edit === 'debt'}
                              errorField={this.state.err.debt ? this.state.err.debt : undefined}
                              inputType={'currency'}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['MPF']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.MPF}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'MPF',
                                  editValue: val.MPF
                                })
                              }
                              isEditing={this.state.edit === 'MPF'}
                              errorField={this.state.err.MPF ? this.state.err.MPF : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />

                            <FactSheetField
                              leftTitle={itemWord['Insurance']}
                              displayValue={val.insurance}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'insurance',
                                  editValue: val.insurance
                                })
                              }
                              isEditing={this.state.edit === 'insurance'}
                              errorField={this.state.err.insurance ? this.state.err.insurance : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <textarea
                                  type="text"
                                  name="insurance"
                                  className="left-value-input"
                                  value={this.state.editValue}
                                  onChange={event =>
                                    this.setState({
                                      editValue: event.target.value
                                    })
                                  }
                                  rows="3"
                                />
                              }
                            />
                            <FactSheetField
                              leftTitle={itemWord['Investment']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.investment}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'investment',
                                  editValue: val.investment
                                })
                              }
                              isEditing={this.state.edit === 'investment'}
                              errorField={this.state.err.investment ? this.state.err.investment : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['Budget']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.budget}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'budget',
                                  editValue: val.budget
                                })
                              }
                              isEditing={this.state.edit === 'budget'}
                              errorField={this.state.err.budget ? this.state.err.budget : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['Interest']}
                              editValue={this.state.editValue}
                              editValueOnChange={event =>
                                this.setState({
                                  err: {},
                                  editValue: event.target.value
                                })
                              }
                              displayValue={val.interest}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'interest',
                                  editValue: val.interest
                                })
                              }
                              isEditing={this.state.edit === 'interest'}
                              errorField={this.state.err.interest ? this.state.err.interest : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                            />
                            <FactSheetField
                              leftTitle={itemWord['Remark']}
                              displayValue={val.factsheetRemark}
                              unEditOnChange={() =>
                                this.setState({
                                  edit: 'factsheetRemark',
                                  editValue: val.factsheetRemark
                                })
                              }
                              isEditing={this.state.edit === 'factsheetRemark'}
                              errorField={this.state.err.factsheetRemark ? this.state.err.factsheetRemark : undefined}
                              updateFactSheetPhase={updateFactSheetPhase}
                              saveBtnOnClick={this.saveFactSheet}
                              cancelBtnOnClick={() => this.setState({ edit: '' })}
                              inputComponent={
                                <textarea
                                  type="text"
                                  name="factsheetRemark"
                                  className="left-value-input"
                                  value={this.state.editValue}
                                  onChange={event =>
                                    this.setState({
                                      editValue: event.target.value
                                    })
                                  }
                                  rows="3"
                                />
                              }
                            />
                          </ul>
                        ) : (
                          <ul>
                            <li className="head">
                              <div className="left-title">{itemWord['Name']}</div>
                              <div className="left-value">{user.name}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Tel']}</div>
                              <div className="left-value">{user.phoneNumber}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['WhatsApp']}</div>
                              <div className="left-value">{user.whatsapp}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Email']}</div>
                              <div className="left-value">{user.email}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['DOB']}</div>
                              <div className="left-value">
                                {user.dob === undefined || user.dob === null
                                  ? ''
                                  : moment(user.dob).format('DD-MMM-YYYY')}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Age']}</div>
                              <div className="left-value">{user.age}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Gender']}</div>
                              <div className="left-value">
                                {user.gender !== '' && user.gender !== null && user.gender !== undefined
                                  ? intl.formatMessage({ id: user.gender })
                                  : user.gender}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Smoker']}</div>
                              <div className="left-value">
                                {user.smoker !== '' && user.smoker !== null && user.smaoker !== undefined
                                  ? intl.formatMessage({ id: user.smoker })
                                  : user.smoker}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Occupation']}</div>
                              <div className="left-value">{user.occupation}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['WeChat ID']}</div>
                              <div className="left-value">{user.wechatId}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Line ID']}</div>
                              <div className="left-value">{user.lineId}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Marriage Status']}</div>
                              <div className="left-value">
                                {user.marriageStatus !== '' &&
                                  user.marriageStatus !== null &&
                                  user.marriageStatus !== undefined
                                  ? intl.formatMessage({
                                    id: user.marriageStatus
                                  })
                                  : user.marriageStatus}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['No. of Child']}</div>
                              <div className="left-value">{user.child}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Monthly Income']}</div>
                              <div className="left-value">
                                {user.monthlyIncome
                                  ? parseInt(user.monthlyIncome)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Monthly Expense']}</div>
                              <div className="left-value">
                                {user.monthlyExpense
                                  ? parseInt(user.monthlyExpense)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Liquid Asset']}</div>
                              <div className="left-value">{user.liquidAsset}</div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Debt']}</div>
                              <div className="left-value">
                                {user.debt
                                  ? parseInt(user.debt)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''}
                              </div>
                            </li>
                            <li>
                              <div className="left-title">{itemWord['Remark']}</div>
                              <div className="left-value">{user.remark}</div>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <DISCDialogComponent
                      open={this.state.clientPersonalityPopup}
                      onClose={() => {
                        this.setState({ clientPersonalityPopup: false });
                      }}
                      contentText="client"
                      personality={clientPersonality}
                      onSubmit={this.handlePersonality}
                    />
                    <AlertPopUp
                      intl={intl}
                      open={modalIsOpen}
                      handleClose={() => this.setState({ modalIsOpen: false })}
                      title={intl.formatMessage({ id: 'Not connected' })}
                      description={intl.formatMessage({
                        id: 'Not_Connected_Content'
                      })}
                      showOk={true}
                    />

                    <PromotionHelpModal
                      open={PromotionHelpModalOpen}
                      onClose={() => this.setState({ PromotionHelpModalOpen: false })}
                      intl={intl}
                    />
                    <RecruitmentHelpModal
                      open={RecruitmentModelOpen}
                      onClose={() => this.setState({ RecruitmentModelOpen: false })}
                      intl={intl}
                    />
                  </div>
                </MySwipeable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTheme(ClientFactsheetView);
