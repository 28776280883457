import { fetch } from '../../utils';
import Backend from '../../backend';

export async function trackDirect(type, detail) {
  try {
    return await fetch(Backend.methodUrl('track'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ type, detail: detail })
    });
  } catch (error) {
    console.log(error);
  }
};
