import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import { getClientConnection, getClientConnectionStatus, getClientEmail, isInactiveEmail } from '../utils';
import './ConnectionDialog.scss';
import { generateRandomCode } from '../../../utils/randomCode';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AladdinHint from '../../AladdinHint/AladdinHint';

//img
const CONNECTION_STATUS_IMAGES = {
  CONSENT: 'img/consentTrue.svg',
  NO_CONSENT: 'img/consentFalse.svg'
};
const PORTFOLIO_IMAGES = {
  PENDING: 'img/doughnut_pending.svg',
  INACTIVE: 'img/doughnut_chart_half.svg',
  CONNECTED: 'img/doughnut_chart_blue.svg',
  DISCONNECTED: 'img/doughnut_chart_grey.svg'
};
const GET_CONNECTION_IMAGE = {
  INACTIVE: 'img/inactiveLink.svg',
  PENDING: 'img/LinkPending.svg',
  CONNECTED: 'img/LinkConnected.svg',
  DISCONNECTED: 'img/LinkDisconnect.svg'
};

const UndoButtonStyle = { padding: 0 };

const ConnectionDialog = props => {
  const { intl, connectionDialog, toggleDialog } = props;

  const { client, closed } = connectionDialog || {};
  const { consent, connectionRequestId } = client || {};

  const connection = getClientConnection(client);
  const connectionStatus = getClientConnectionStatus(client);
  const clientEmail = getClientEmail(client);

  const close = () => props.toggleDialog('connection', { ...connectionDialog, closed: true });
  const onExited = () => props.toggleDialog('connection', false);

  const toggleConsentDialog = () => {
    if (client.consent) {
      toggleDialog('normal', { message: intl.formatMessage({ id: 'The consent is already got.' }) });
    } else {
      const inactiveEmail = isInactiveEmail(client);
      const showConsentDialog = () => {
        toggleDialog('consent', {
          ...connectionDialog,
          editingEmail: !inactiveEmail ? clientEmail : '',
          emailEditable: inactiveEmail
        });
      };

      if (client.isDemo) {
        toggleDialog('isDemoClientWarning', { client: client, onConfirm: () => showConsentDialog() });
      } else {
        showConsentDialog();
      }
    }
  };

  const toggleConnectDialog = () => {
    if (connection) {
      toggleDialog('normal', { message: intl.formatMessage({ id: 'This client is already connected with you.' }) });
    } else {
      const showConnectDialog = () => {
        toggleDialog('connect', {
          ...connectionDialog,
          editingEmail: !isInactiveEmail(client) ? clientEmail : '',
          passCode: generateRandomCode(8)
        });
      };

      if (client.isDemo) {
        toggleDialog('isDemoClientWarning', { client: client, onConfirm: () => showConnectDialog() });
      } else {
        showConnectDialog();
      }
    }
  };

  const toggleResendDialog = () => {
    const showResendDialog = () => {
      toggleDialog('resend', { ...connectionDialog, editingEmail: getClientEmail(connectionDialog.client) });
    };

    if (client.isDemo) {
      toggleDialog('isDemoClientWarning', { client: client, onConfirm: () => showResendDialog() });
    } else {
      showResendDialog();
    }
  };

  const toggleConfirmDeleteConnectionRequestDialog = async () => {
    toggleDialog('confirmDeleteConnectionRequest', { client: client });
  };

  return !connectionDialog || !client ? null : (
    <Dialog
      className="connection-dialog"
      open={!!connectionDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'Connect with client' })}</DialogTitle>
      <DialogContent className="content">
        <Grid container direction="column" spacing={2} justify="center">
          <Grid item>
            <AladdinHint title={intl.formatMessage({ id: 'aladdin-connection-title' })} helpCenter="c04" />
          </Grid>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'connectClient_desc' })}</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
              <Grid item style={{ position: 'relative' }}>
                <img
                  alt="portfolio"
                  style={{ width: '20px', position: 'absolute', top: '50%', transform: 'translate(-28px, -55%)' }}
                  src={
                    connectionStatus === 'Inactive'
                      ? GET_CONNECTION_IMAGE.INACTIVE
                      : connectionStatus === 'Pending'
                      ? GET_CONNECTION_IMAGE.PENDING
                      : connectionStatus === 'Connected'
                      ? GET_CONNECTION_IMAGE.CONNECTED
                      : GET_CONNECTION_IMAGE.DISCONNECTED
                  }
                />
                {connectionStatus === 'Inactive' && (
                  <Button
                    className="portfolio-add-button"
                    color="primary"
                    variant="contained"
                    onClick={toggleConnectDialog}
                  >
                    {intl.formatMessage({ id: 'get-connect' })}
                  </Button>
                )}
                {connectionStatus === 'Pending' && connectionRequestId && (
                  <Button
                    className="portfolio-add-button"
                    color="primary"
                    variant="contained"
                    onClick={toggleResendDialog}
                  >
                    {intl.formatMessage({ id: 'resend-connect' })}
                  </Button>
                )}
                {connectionStatus === 'Connected' && (
                  <Typography style={{ color: '#3eda7d' }}>
                    <b>{intl.formatMessage({ id: connectionStatus })}</b>
                  </Typography>
                )}
                {connectionStatus === 'Disconnected' && (
                  <Typography color="textSecondary">
                    <b>{intl.formatMessage({ id: connectionStatus })}</b>
                  </Typography>
                )}
              </Grid>

              {connectionRequestId && (
                <Grid item>
                  <Button
                    className="portfolio-add-button"
                    variant="outlined"
                    color="primary"
                    onClick={toggleConfirmDeleteConnectionRequestDialog}
                    style={UndoButtonStyle}
                  >
                    {intl.formatMessage({ id: 'undo' })}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {!consent ? (
              <Button color="primary" onClick={toggleConsentDialog}>
                {intl.formatMessage({ id: 'get-consent' })}
              </Button>
            ) : (
              <Typography style={{ color: '#3eda7d', marginLeft: 13 }}>
                {intl.formatMessage({ id: 'Consent_YES' })}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button onClick={close} color="primary">
              {intl.formatMessage({ id: 'Close' })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  // Map state to props
  state => ({
    connectionDialog: state.control.connectionDialog
  }),
  // Map actions to props
  {
    toggleDialog
  }
)(ConnectionDialog);

export default injectIntl(container);
