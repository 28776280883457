import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { SlideTransition } from '../../../Transition/Transition';

const ProfilePlusAddToEWalletDialog = props => {
  const { intl, open, onClose, onSubmit } = props;

  const [language, setLanguage] = useState('auto');

  const onChangeLanguage = event => {
    setLanguage(event.target.value);
  };

  const _onSubmit = () => {
    onSubmit({ language });
  };

  useEffect(() => {
    setLanguage('auto');
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'profile-plus-add-to-e-wallet-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'profile-plus-add-to-e-wallet-dialog-content' })}</Typography>
          </Grid>
          <Grid item>
            <RadioGroup value={language} onChange={onChangeLanguage}>
              <Grid container spacing={1}>
                {[
                  {
                    value: 'auto',
                    label: intl.formatMessage({ id: 'Auto' })
                  },
                  {
                    value: 'zh-Hant',
                    label: '繁中'
                  },
                  {
                    value: 'en',
                    label: 'ENG'
                  },
                  {
                    value: 'zh-Hans',
                    label: '简中'
                  }
                ].map(({ value, label }) => (
                  <Grid item xs={6} key={value}>
                    <FormControlLabel
                      control={<Radio color="primary" style={{ padding: 4 }} />}
                      value={value}
                      label={label}
                      style={{ marginBottom: 0 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={_onSubmit}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ProfilePlusAddToEWalletDialog);
