import React from 'react';
import _ from 'lodash';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
import './FillInStartDateModal.scss';
import { SlideTransition } from '../../../../components/Transition/Transition';

export default class FillInStartDateModal extends React.PureComponent {
  render() {
    const { intl, onOK, onContinue } = this.props;

    return (
      <Dialog
        {..._.pick(this.props, ['open', 'onClose'])}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        fullWidth={true}
        keepMounted
      >
        <DialogTitle id="alert-dialog-slide-title">
          {intl.formatMessage({ id: 'fill-in-start-date-modal-title' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {intl.formatMessage({ id: 'fill-in-start-date-modal-content' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onContinue} color="primary">
            {intl.formatMessage({ id: 'continue' })}
          </Button>
          <Button className="button-title-font" onClick={onOK} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({ id: 'fill-in-now' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
