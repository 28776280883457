import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import PolicyReviewChooseClient from '../../PolicyReview/PolicyReviewChooseClient/PolicyReviewChooseClient';
import { updateViewingClient } from '../../../store/client/duck';
import ChatGPTIGPostDrawer from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTIGPostDrawer/ChatGPTIGPostDrawer';
import { toggleControl, toggleDialog } from '../../../store/control/duck';
import { goToExport, toggleMeetingNoteDialog } from '../../../components/ClientEntry/utils';
import { tracking } from '../../../utils/tracking';
import ChatGPTFunDrawer from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTFunDrawer/ChatGPTFunDrawer';
import { ChatGPTRewriteMessageDrawer } from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTRewriteMessage';
import NativeOrWeb from '../../../utils/native';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';

const ChatGPTOptions = props => {
  const { intl, history, viewingClient, updateViewingClient, toggleControl, toggleDialog } = props;

  const onSelectClient = client => {
    updateViewingClient(client);
  };

  const igPost = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Social Media Post - ChatGPT');

    toggleControl('chatGPTIGPostDrawer', { client: viewingClient });
  };

  const iceBreaking = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on IceBreaking Script - ChatGPT');

    history.push({
      pathname: '/client',
      selectedClient: viewingClient,
      gpt: 'ice-breaking'
    });
  };

  const matchLazyPacks = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Match Lazy Packs - ChatGPT');

    history.push({
      pathname: '/client',
      selectedClient: viewingClient,
      gpt: 'lazy-packs'
    });
  };

  const personalMsg = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Personalized Message - ChatGPT');

    toggleControl('chatGPTPersonalMessageDrawer', { client: viewingClient });
    goToExport(viewingClient, history);
  };

  const voiceNotes = () => {
    if (!viewingClient) {
      return;
    }

    tracking('Click on Voice Notes - ChatGPT');

    toggleMeetingNoteDialog(toggleDialog, viewingClient, { triggerRecordDialog: true });
    history.push({ pathname: '/client', selectedClient: viewingClient, gpt: true });
  };

  const fun = () => {
    tracking('Click on Fun - ChatGPT');

    toggleControl('chatGPTFunDrawer', {});
  };

  const rewriteMessage = () => {
    tracking('Click on RewriteMessage - ChatGPT');
    toggleControl('chatGPTRewriteMessageDrawer',
      {
        open: true,
        message: "",
        client: viewingClient,
        onValue: (value) => {
          NativeOrWeb.copyToClipboard(value).then(() => {
            toast.info(IconToast(
              'info', intl.formatMessage({ id: 'Copied!' })),
              { className: 'new-toast' });
          })
            .catch(console.log);
        }
      });
  }

  return (
    <Fragment>
      <ChatGPTIGPostDrawer />
      <ChatGPTFunDrawer />
      <ChatGPTRewriteMessageDrawer />
      <FunctionCardsView
        className="chat-gpt-options"
        getItems={({ intl, history }) => [
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-ig-post' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-ig-post-desc' }),
            imgSrc: 'img/gpt/social_media.png',
            imgAlt: 'ig-post',
            color: '#5fc3d2',
            onClick: igPost,
            disabled: !viewingClient
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-ice-breaking' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-ice-breaking-desc' }),
            imgSrc: 'img/gpt/ice_break.png',
            imgAlt: 'ice-breaking',
            color: '#3999f8',
            onClick: iceBreaking,
            disabled: !viewingClient
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-personal-msg' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-personal-msg-desc' }),
            imgSrc: 'img/gpt/personalized_msg.png',
            imgAlt: 'personal-msg',
            color: '#2260a5',
            onClick: personalMsg,
            disabled: !viewingClient
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-voice-notes' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-voice-notes-desc' }),
            imgSrc: 'img/promotion.png',
            imgAlt: 'voice-notes',
            color: '#433BC9',
            onClick: voiceNotes,
            disabled: !viewingClient
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-lazy-packs' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-lazy-packs-desc' }),
            imgSrc: 'img/promotion.png',
            imgAlt: 'lazy-packs',
            color: '#5fc3d2',
            onClick: matchLazyPacks,
            disabled: !viewingClient
          },
          {
            text: intl.formatMessage({ id: 'chat-gpt-options-fun' }),
            subtitle: intl.formatMessage({ id: 'chat-gpt-options-fun-desc' }),
            imgSrc: 'img/adviser-home-page/referadviser.png',
            imgAlt: 'emojis',
            color: '#3999f8',
            onClick: fun
          },
          {
            text: intl.formatMessage({ id: 'gpt-message-rewite-title' }),
            subtitle: intl.formatMessage({ id: 'gpt-message-rewite-description' }),
            imgSrc: '/img/create_blue.png',
            imgAlt: 'emojis',
            color: '#203398',
            onClick: rewriteMessage
          }
        ]}
        prepend={
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {intl.formatMessage({ id: 'chat-gpt-options-hint' })}
              </Typography>
            </Grid>
            <Grid item>
              <PolicyReviewChooseClient selectedClient={viewingClient || ''} setSelectedClient={onSelectClient} />
            </Grid>
          </Grid>
        }
      />
    </Fragment>
  );
};

const container = connect(
  state => ({
    viewingClient: state.client.viewingClient
  }),
  {
    updateViewingClient,
    toggleControl,
    toggleDialog
  }
)(ChatGPTOptions);

export default injectIntl(container);
