import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getErrorMessageIntl } from '../../../../utils/validators';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { POLICY_NAMES } from '../../constants';
import { isSubCategory } from '../../../../utils/policy';
import _ from 'lodash';

const useStyles = makeStyles({
  option: {
    fontSize: '14px',
    minHeight: 32
  }
});

const PolicyNameInput = props => {
  const { intl, userData, insurer, subCategoryId, label, value, onChange, error } = props;
  const { policyNames } = userData || {};
  const classes = useStyles();
  const isMPF = isSubCategory(subCategoryId, 'provident');

  const renderInput = params => (
    <TextField
      label={label}
      name="policyName"
      required
      error={!!error}
      helperText={error && intl.formatMessage({ id: getErrorMessageIntl(error) })}
      {...params}
    />
  );

  const onChangeFormatted = (event, newValue) => {
    if (event) {
      onChange({ target: { value: newValue } });
    }
  };

  const onInputChange = (event, newValue) => {
    if (event) {
      onChange({ target: { value: newValue } });
    }
  };

  const options = isMPF && insurer && POLICY_NAMES[insurer] ? POLICY_NAMES[insurer] : _.uniq(policyNames || []);

  return (
    <Autocomplete
      onChange={onChangeFormatted}
      onInputChange={onInputChange}
      inputValue={value || ''}
      options={options}
      getOptionLabel={option => option}
      renderInput={renderInput}
      freeSolo={true}
      classes={classes}
    />
  );
};

const container = connect(
  state => ({
    userData: state.userData.userData
  }),
  {}
)(PolicyNameInput);

export default injectIntl(container);
