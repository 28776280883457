import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { INFLATION_RATE } from '../../../../constants/analysis';

const getChartOptions = intl => {
  return {
    animation: {
      duration: 1500
    },
    title: {
      display: false,
      fontSize: 12,
      text: intl.formatMessage({ id: 'Years of family support' })
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontSize: 12,
        usePointStyle: true,
        boxWidth: 10
      }
    },
    showTooltips: false,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += tooltipItem.xLabel;
          label += ' ' + intl.formatMessage({ id: 'Years' });

          return label;
        },
        footer: (tooltipItems, data) => {
          return (
            intl.formatMessage({ id: 'Total' }) +
            ': ' +
            (tooltipItems[0].xLabel + tooltipItems[1].xLabel).toFixed(1) +
            ' ' +
            intl.formatMessage({ id: 'Years' })
          );
        }
      }
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({ id: 'supportYears' }),
            fontSize: 12
          },
          stacked: true,
          ticks: { fontSize: 12, beginAtZero: true }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({ id: 'Protection (HKD)' }),
            fontSize: 12
          },
          stacked: true,
          ticks: {
            min: 0,
            beginAtZero: true,
            fontSize: 12
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
};

export default class LifeTopUpEffectChart extends Component {
  render() {
    const { intl, totalLifeNetDebt, monthlyExpense } = this.props;

    let mText = intl.formatMessage({ id: 'M' }),
      currentText = intl.formatMessage({ id: 'Original' }),
      topUpText = intl.formatMessage({ id: 'Top up' });

    let redBar = Math.max(
      0,
      Math.log(1 - (Math.max(0, totalLifeNetDebt) / monthlyExpense) * (1 - INFLATION_RATE)) /
        Math.log(INFLATION_RATE) /
        12
    );

    let greenBar =
      Math.log(1 - (Math.max(0, totalLifeNetDebt + 1000000) / monthlyExpense) * (1 - INFLATION_RATE)) /
      Math.log(INFLATION_RATE) /
      12;

    let blueBar =
      Math.log(1 - (Math.max(0, totalLifeNetDebt + 2000000) / monthlyExpense) * (1 - INFLATION_RATE)) /
      Math.log(INFLATION_RATE) /
      12;

    let purpleBar =
      Math.log(1 - (Math.max(0, totalLifeNetDebt + 3000000) / monthlyExpense) * (1 - INFLATION_RATE)) /
      Math.log(INFLATION_RATE) /
      12;

    let yellowBar =
      Math.log(1 - (Math.max(0, totalLifeNetDebt + 4000000) / monthlyExpense) * (1 - INFLATION_RATE)) /
      Math.log(INFLATION_RATE) /
      12;

    let greenBarNew = Math.max(0, greenBar - redBar).toFixed(1);
    let blueBarNew = Math.max(0, blueBar - redBar).toFixed(1);
    let purpleBarNew = Math.max(0, purpleBar - redBar).toFixed(1);
    let yellowBarNew = Math.max(0, yellowBar - redBar).toFixed(1);

    redBar = redBar.toFixed(1);

    let stackedChartData = {
      labels: ['+4' + mText, '+3' + mText, '+2' + mText, '+1' + mText, currentText],
      datasets: [
        {
          label: currentText,
          backgroundColor: '#3966f8',
          borderWidth: 0,
          //  borderColor: '#FF7F50',
          categoryPercentage: 1,
          barPercentage: 0.75,
          maxBarThickness: 15,
          data: [parseFloat(redBar), parseFloat(redBar), parseFloat(redBar), parseFloat(redBar), parseFloat(redBar)]
        },
        {
          label: topUpText,
          backgroundColor: '#44d9e6',
          borderWidth: 0,
          // borderColor: '#FF7F50',
          categoryPercentage: 1,
          barPercentage: 0.75,
          maxBarThickness: 15,
          data: [parseFloat(yellowBarNew), parseFloat(purpleBarNew), parseFloat(blueBarNew), parseFloat(greenBarNew), 0]
        }
      ]
    };

    return (
      <div className="clearfix">
        <h5 className="text-center horizontal-graph violet-text">
          {intl.formatMessage({
            id: 'Top up effect for better Family Support'
          })}
        </h5>
        <div className="Outer">
          <HorizontalBar height={170} options={getChartOptions(intl)} data={stackedChartData} />
        </div>
      </div>
    );
  }
}
