import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';

export default function RecruitmentHelpModal(props) {
  const { open, onClose, intl } = props;

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'recruitment-help-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'recruitment-help-content' })}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
