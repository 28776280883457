import React, { useState } from 'react';
import { hexToRgb, Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const ProfileImageContainerStyle = {
  position: 'relative',
  borderStyle: 'solid',
  background: '#fff',
  width: 'fit-content',
  margin: 'auto',
  borderRadius: '50%'
};

const MyDetailsProfilePicture = (props) => {
  const { src, initial, width, borderColor, style, imgStyle, extra, onProfilePicClick } = props;

  const theme = useTheme();
  const [isImageError, setIsImageError] = useState(false);

  // console.log('onProfilePicClick::: ', onProfilePicClick)
  const RGB =
    hexToRgb(borderColor || theme.palette.primary.main)
      .replace('rgb', 'rgba')
      .replace(')', ', 0.8)') || borderColor;

  const onImageLoad = () => setIsImageError(false);
  const onImageError = () => setIsImageError(true);

  return (
    <div
      onClick={() => onProfilePicClick(true)}
      style={{
        ...ProfileImageContainerStyle,
        borderColor: RGB,
        padding: (6 * width) / 150,
        borderWidth: (4 * width) / 150,
        ...style
      }}
    >
      {extra && extra}

      {src !== 'male' && src !== 'female' && src ? (
        <img
          src={src}
          width={width || 'inherit'}
          alt="profile"
          style={{
            borderRadius: '50%',
            minWidth: width,
            minHeight: width,
            visibility: isImageError ? 'hidden' : undefined,
            ...imgStyle
          }}
          onError={onImageError}
          onLoad={onImageLoad}
        />
      ) : (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{
            width: width || 'inherit',
            height: width || 'inherit',
            borderRadius: '50%',
            background: src === 'female' ? '#EE82EE' : '#03CEA4',
            ...imgStyle
          }}
        >
          <Grid item>
            <Typography
              align="center"
              color="textPrimary"
              style={{ fontWeight: 800, fontSize: width ? `${width / 2}px` : 'inherit' }}
            >
              {initial}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MyDetailsProfilePicture;
