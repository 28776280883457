import React, { PureComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { get } from 'lodash';
import MoreInfoIconButton from '../../../../components/MoreInfoIconButton';
import { FormControl, Button, Grid } from '@material-ui/core';
import '../../Dashboard/partials/business/business.css';
import TargetEditorModal from '../../Dashboard/partials/business/TargetEditorModal/TargetEditorModal';
import { weeklyAptCalculator } from './../../../../utils/weeklyAptCalculator';
import BlockingOverlay from '../../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BusinessTargetEditorModal from '../../Dashboard/partials/business/setBusinessTargetModal/BusinessTargetModal.js';
import TimeLeftCard from './ProgressComponent/Cards/TimeLeftCard/TimeLeftCard.js';
import ClosedBizCard from './ProgressComponent/Cards/ClosedBizCard.js';
import QuarterProgressCard from './ProgressComponent/Cards/QuarterProgressCard.js';
import EmojiCard from './ProgressComponent/Cards/EmojiCard.js';
import FollowUpCard from './ProgressComponent/Cards/FollowUpCard.js';
import QuarterTargetCard from './ProgressComponent/Cards/QuarterTargetCard.js';
import QuarterProgressModal from './ProgressComponent/Dialog/QuarterProgressModal.js';
import CurrentProgressModal from './ProgressComponent/Dialog/CurrentProgressModal.js';
import { SlideTransition } from '../../../../components/Transition/Transition';
import Card from '@material-ui/core/Card';
import ReactEcharts from 'echarts-for-react';
import CreateIcon from '@material-ui/icons/Create';
import { getTimeLeftData } from '../../../../utils/business';
import QuarterEndDateDialog from './ProgressComponent/Dialog/QuarterEndDateDialog';

class BusinessView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      confirmedBiz: 0,
      confirmedBizModal: false,
      isGetFigureAdviser: false,
      confirmedBizEdit: false,
      quarterEndDate: null,
      quarterEndDateModal: false,
      quarterTargetModal: false,
      currentProgressModal: false,
      quarterProgressModal: false,
      assistantModal: false,
      quarterTarget: 0,
      currentProgress: 0,
      currentProgressOption: 'showExpectedFrom',
      oldValue: 'showExpectedFrom',
      quarterTargetEdit: false,
      targetEditorModalOpen: false,
      bigDate: null,
      inputConfirmedBiz: 0,
      key: '',
      typeArray: [],
      quarterTargetType: '',
      MDRT: 'No',
      COT: 'No',
      TOT: 'No',
      avgCaseSize: 0,
      closingRate: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateCurrentProgress = this.updateCurrentProgress.bind(this);
    this.clearBigDate = this.clearBigDate.bind(this);

    const {
      fetchAdviserBusiness,
      adviserBusinessAnalysis,
      fetchFigureAdviser,
      loginFetched,
      updateLoginFetched
    } = props;
    if (!loginFetched) {
      fetchAdviserBusiness();
      adviserBusinessAnalysis();
      fetchFigureAdviser();
    } else {
      updateLoginFetched(false);
    }
  }

  closeModal() {
    const { adviserBusiness } = this.props;
    const quarterEndDate =
      adviserBusiness && adviserBusiness.quarterEndDate ? moment.parseZone(adviserBusiness.quarterEndDate) : null;
    this.setState({
      modalIsOpen: false,
      quarterEndDateModal: false,
      quarterEndDate: quarterEndDate,
      quarterTargetModal: false,
      currentProgressModal: false,
      confirmedBizModal: false,
      quarterTarget: adviserBusiness && adviserBusiness.quarterTarget ? adviserBusiness.quarterTarget : 0,
      currentProgress: adviserBusiness && adviserBusiness.currentProgress ? adviserBusiness.currentProgress : 0,
      currentProgressOption: this.state.oldValue,
      bigDate: adviserBusiness && adviserBusiness.bigDate ? adviserBusiness.bigDate : null,
      MDRT: 'No',
      COT: 'No',
      TOT: 'No',
      quarterTargetType: '',
      avgCaseSize: adviserBusiness && adviserBusiness.avgCaseSize ? adviserBusiness.avgCaseSize : 0,
      closingRate: adviserBusiness && adviserBusiness.closingRate ? adviserBusiness.closingRate * 100 : 0
    });
    //Sending props from child to parent component to update the props of swipe
    this.props.handleEnableMouseEvents(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //Set the state of quarterTarget and current progress
    if (nextProps.updateQuarterTargetPhase === true) {
      this.setState({
        quarterTarget:
          nextProps.adviserBusiness && nextProps.adviserBusiness.quarterTarget
            ? nextProps.adviserBusiness.quarterTarget
            : 0,
        currentProgress:
          nextProps.adviserBusiness && nextProps.adviserBusiness.currentProgress
            ? nextProps.adviserBusiness.currentProgress
            : 0,
        currentProgressOption:
          nextProps.adviserBusiness && nextProps.adviserBusiness.currentProgressOption
            ? nextProps.adviserBusiness.currentProgressOption
            : this.state.currentProgressOption,
        oldValue:
          nextProps.adviserBusiness && nextProps.adviserBusiness.currentProgressOption
            ? nextProps.adviserBusiness.currentProgressOption
            : this.state.currentProgressOption,
        MDRT: 'No',
        COT: 'No',
        TOT: 'No',
        avgCaseSize:
          nextProps.adviserBusiness && nextProps.adviserBusiness.avgCaseSize
            ? nextProps.adviserBusiness.avgCaseSize
            : 0,
        closingRate:
          nextProps.adviserBusiness && nextProps.adviserBusiness.closingRate
            ? nextProps.adviserBusiness.closingRate * 100
            : 0,
        confirmedBiz:
          nextProps.adviserBusiness && nextProps.adviserBusiness.confirmedBiz
            ? nextProps.adviserBusiness.confirmedBiz
            : 0,
        inputConfirmedBiz:
          nextProps.adviserBusiness && nextProps.adviserBusiness.confirmedBiz
            ? nextProps.adviserBusiness.confirmedBiz
            : 0,
        isGetFigureAdviser: true,
        quarterEndDate:
          nextProps.adviserBusiness && nextProps.adviserBusiness.quarterEndDate
            ? moment.parseZone(nextProps.adviserBusiness.quarterEndDate)
            : null,
        bigDate:
          nextProps.adviserBusiness && nextProps.adviserBusiness.bigDate ? nextProps.adviserBusiness.bigDate : null
      });
    }
  }

  //Handle the cut off date
  handleQuarterDateChange(date, key) {
    if (key === 'quaterEndDate') {
      if (date) {
        var qdate = date ? moment(date).format('YYYY-MM-DD') : '';
        let qdate1 = date ? moment.utc(qdate).format('YYYY-MM-DDT00:00:00.00Z') : '';
        this.setState({ quarterEndDate: qdate1, key: key });
      } else {
        this.setState({ quarterEndDate: null, key: key });
      }
    }
    if (key === 'bigDate') {
      if (date) {
        let d = date ? moment(date).format('YYYY-MM-DD') : '';
        let date1 = d ? moment.utc(d).format('YYYY-MM-DDT00:00:00.00Z') : '';
        this.setState({ bigDate: date1, key: key });
      } else {
        this.setState({ bigDate: null, key: key });
      }
    }
  }

  // save and update confirmed Biz
  UpdateConfirmedBiz = () => {
    const { inputConfirmedBiz } = this.state;
    let obj = {};
    obj.confirmedBiz_LastUpdatedDate = moment.utc(moment());
    if (inputConfirmedBiz === '' || isNaN(inputConfirmedBiz)) {
      this.setState({ confirmedBiz: 0 });
      obj.confirmedBiz = inputConfirmedBiz;

      // obj.lastUpdateOfConfirmedBiz= moment(now())
    } else {
      this.setState({
        confirmedBiz: inputConfirmedBiz
      });
      obj.confirmedBiz = inputConfirmedBiz;
    }

    this.setState({ confirmedBizEdit: false });
    this.props.updateAdviserBusiness(obj);

    this.closeModal();
  };

  //To update the business current progress
  updateCurrentProgress = () => {
    const { currentProgress, currentProgressOption } = this.state;
    const { updateAdviserBusiness, adviserBusiness } = this.props;
    const adviserBusinessValue = _.get(adviserBusiness, 'currentProgress', '0')
      ? _.get(adviserBusiness, 'currentProgress', '0')
      : '0';
    const currentOption = _.get(adviserBusiness, 'currentProgressOption', '0')
      ? _.get(adviserBusiness, 'currentProgressOption', '0')
      : '0';
    this.setState({ oldValue: currentProgressOption });
    if (currentProgress !== adviserBusinessValue && currentProgressOption === 'showManualInput') {
      if (currentProgress) {
        updateAdviserBusiness({ currentProgress });
      } else {
        updateAdviserBusiness({ currentProgress: null, currentProgressOption });
      }
    }
    if (currentOption !== currentProgressOption) {
      if (currentProgressOption) {
        updateAdviserBusiness({ currentProgressOption });
      } else {
        updateAdviserBusiness({ currentProgress: null, currentProgressOption });
      }
    }
    this.setState({ currentProgressModal: false });
    //Sending props from child to parent component to update the props of swipe
    this.props.handleEnableMouseEvents(true);
  };

  //to open the quarter end date modal
  quarterEndDateModal() {
    this.setState({ quarterEndDateModal: true });
  }

  //To open the  quarter target modal
  quarterTargetModal() {
    this.setState({ quarterTargetModal: true });
    //Sending props from child to parent component to update the props of swipe
    this.props.handleEnableMouseEvents(false);
  }

  //To open the current progress modal
  currentProgressModal() {
    this.setState({ currentProgressModal: true });
    //Sending props from child to parent component to update the props of swipe
    this.props.handleEnableMouseEvents(false);
  }

  //To save the confirmesBiz
  saveConfirmedBiz() {
    const { confirmedBiz } = this.state;
    let obj = {};
    obj.confirmedBiz = confirmedBiz;
    if (confirmedBiz === '') {
      this.setState({ confirmedBiz: 0 });
    }
    this.setState({ confirmedBizEdit: false });
    this.props.updateAdviserBusiness(obj);
  }

  confirmedBizModal = () => {
    this.setState({ confirmedBizModal: true });
    //Sending props from child to parent component to update the props of swipe
    this.props.handleEnableMouseEvents(false);
  };

  handleChange(e) {
    let { name, value } = e.target;
    const { adviserBusiness } = this.props;
    if (name === 'currentProgressOption') {
      this.setState({
        [name]: value,
        currentProgress: adviserBusiness && adviserBusiness.currentProgress ? adviserBusiness.currentProgress : 0
      });
    } else {
      if (value === '1') {
        this.setState({ [name]: value });
      } else {
        this.setState({ modalIsOpen: true });
      }
    }
  }

  //To clear the big date of business
  clearBigDate = () => {
    const { bigDate } = this.state;
    if (bigDate !== null) {
      this.setState({ bigDate: null });
      const { updateAdviserBusiness } = this.props;
      updateAdviserBusiness({ bigDate: null });
    }
  };

  componentWillUnmount() {
    const { initTrackingPhase } = this.props;
    initTrackingPhase();
  }

  render() {
    let {
      userDetails,
      adjustedBusinessVol,
      activeTrackRecord,
      opportunityCount,
      opportunityBizVol,
      intl,
      adviserBusiness,
      assistant
    } = this.props;
    //let productionEnv = process.env.NODE_ENV === 'production' ? true : false;
    const {
      confirmedBiz,
      // confirmedBizEdit,
      quarterTarget,
      currentProgress,
      currentProgressOption,
      targetEditorModalOpen,
      quarterEndDateModal
    } = this.state;
    const highOpportunityCount = _.get(opportunityCount, 'highOpportunityCount', 0);
    const mediumOpportunityCount = _.get(opportunityCount, 'mediumOpportunityCount', 0);
    const lowOpportunityCount = _.get(opportunityCount, 'lowOpportunityCount', 0);
    const noneOpportunityCount = _.get(opportunityCount, 'noneOpportunityCount', 0);
    const highOpportunityBizVol = _.get(opportunityBizVol, 'highOpportunityBizVol', 0);
    const mediumOpportunityBizVol = _.get(opportunityBizVol, 'mediumOpportunityBizVol', 0);
    const lowOpportunityBizVol = _.get(opportunityBizVol, 'lowOpportunityBizVol', 0);
    const noneOpportunityBizVol = _.get(opportunityBizVol, 'noneOpportunityBizVol', 0);
    const opportunityCountObj = {
      highOpportunityCount: highOpportunityCount,
      mediumOpportunityCount: mediumOpportunityCount,
      lowOpportunityCount: lowOpportunityCount,
      noneOpportunityCount: noneOpportunityCount
    };
    const opportunityBizVolObj = {
      highOpportunityBizVol: highOpportunityBizVol,
      mediumOpportunityBizVol: mediumOpportunityBizVol,
      lowOpportunityBizVol: lowOpportunityBizVol,
      noneOpportunityBizVol: noneOpportunityBizVol,
      isManual: currentProgressOption === 'showManualInput' ? true : false
    };

    let confirmedBizUpdateDate =
      adviserBusiness && adviserBusiness.confirmedBiz_LastUpdatedDate
        ? moment(adviserBusiness.confirmedBiz_LastUpdatedDate).format('DD-MMM-YYYY')
        : '-';

    const { r1, QEnd, pastDays, chartData } = getTimeLeftData(adviserBusiness);

    let currentMonth = parseInt(moment().format('MM'));

    // console.log('QEndDate');
    // console.log(QEndDate);
    // console.log('currentDate');
    // console.log(currentDate);
    // console.log('Q');
    // console.log(Q);
    // console.log('r1');
    // console.log(r1);
    // console.log('QEnd');
    // console.log(QEnd);
    // console.log('pastDays');
    // console.log(pastDays);

    let adviserBusiness_bigDate = get(adviserBusiness, 'bigDate', null);
    let bigDateEnd1 = moment.utc(moment().format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z');
    let bigDateEnd = adviserBusiness_bigDate ? moment(adviserBusiness_bigDate).diff(moment(bigDateEnd1), 'days') : '-';

    let adjustedBiz = (confirmedBiz !== '' ? parseInt(confirmedBiz) : 0) + adjustedBusinessVol;
    let month = currentMonth <= 3 ? 3 : currentMonth <= 6 ? 6 : currentMonth <= 9 ? 9 : currentMonth <= 12 ? 12 : 12;
    const today = new Date();
    var year = today.getFullYear();
    var day = 0;
    const maxDate = new Date(year, month, day);
    /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
    let quarterEndDateVal = this.state.quarterEndDate;
    if (currentProgressOption === 'showManualInput') {
    } else {
    }

    const date1 = moment(today)
      .add(1, 'days')
      .format('YYYY-MM-DD');

    const m1 = moment(maxDate).format('YYYY-MM-DD');
    let quarterBusiness = r1 > 0 ? (pastDays / r1) * quarterTarget : 0;
    //To get the outer chart progress % according to the progress option which is current progress or opp adjusted
    // const outerChartValue =
    //   currentProgressOption === 'showManualInput'
    //     ? currentProgress
    //     : adjustedBiz;
    let outerChartValue;
    if (currentProgressOption === 'showManualInput') {
      outerChartValue = parseInt(currentProgress) + (confirmedBiz !== '' ? parseInt(confirmedBiz) : 0);
    } else {
      outerChartValue = adjustedBiz;
    }
    let targetBiz =
      currentProgressOption === 'showManualInput' ? parseInt(currentProgress) : parseInt(adjustedBusinessVol);
    // let totalBiz =
    //   (quarterTarget - outerChartValue > 0 ? quarterTarget - outerChartValue : 0) +
    //   parseInt(confirmedBiz) +
    //   parseInt(targetBiz);

    //Calculate the % of unser done calculates as outer chart value divided by quarter target here % can greater than 100
    const QuarterOuterProgressPercentage = quarterTarget > 0 ? (outerChartValue / quarterTarget) * 100 : 0;
    // To get the % of time available progress chart pastDaye divided range
    //Range is equal to the quarter start date to quarter end date selected by adviser
    let outerProgressValue = outerChartValue;
    let innerProgressValue = quarterBusiness;

    //Status to show the leading to lagging messages
    let headingStatus = '';
    if (outerProgressValue !== 0 && outerProgressValue >= innerProgressValue && QuarterOuterProgressPercentage < 100) {
      headingStatus = 'onTrack';
    } else if (outerProgressValue < innerProgressValue && QuarterOuterProgressPercentage < 100) {
      headingStatus = 'offTrack';
    } else if (QuarterOuterProgressPercentage >= 100) {
      headingStatus = 'great';
    } else {
      headingStatus = 'zeroProgress';
    }

    const weeklyAppt = Math.ceil(
      weeklyAptCalculator(
        parseFloat(this.state.quarterTarget),
        parseFloat(this.state.avgCaseSize),
        parseFloat(this.state.closingRate) / 100
      )
    );
    const assistantDataCount = [0, 0, 0, 0];

    if (assistant) {
      assistant.categories.forEach(category => {
        if (category.name === 'tracking-stage-1') {
          assistantDataCount[0] = {
            value: category.lists[0].data.filter(entry => !entry.isDone).length,
            name: category.lists[0].data.filter(entry => !entry.isDone).length.toString(),
            itemStyle: {
              color: '#5DABF9'
            }
          };
        }
        if (category.name === 'tracking-stage-2') {
          assistantDataCount[1] = {
            value: category.lists[0].data.filter(entry => !entry.isDone).length,
            name: category.lists[0].data.filter(entry => !entry.isDone).length.toString(),
            itemStyle: {
              color: '#7BE6A6'
            }
          };
        }
        if (category.name === 'tracking-stage-3') {
          assistantDataCount[2] = {
            value: category.lists[0].data.filter(entry => !entry.isDone).length,
            name: category.lists[0].data.filter(entry => !entry.isDone).length.toString(),
            itemStyle: {
              color: '#FFBD42'
            }
          };
        }
        if (category.name === 'tracking-stage-4') {
          assistantDataCount[3] = {
            value: category.lists[0].data.filter(entry => !entry.isDone).length,
            name: category.lists[0].data.filter(entry => !entry.isDone).length.toString(),
            itemStyle: {
              color: '#FF858D'
            }
          };

          assistantDataCount[4] = {
            value: category.lists[0].data.filter(entry => !entry.isDone).length,
            name: category.lists[0].data.filter(entry => !entry.isDone).length.toString(),
            itemStyle: {
              color: '#ff6a7400' //not showing last part in UI
            }
          };
        }
      });
    }

    var assistantOption = {
      series: [
        {
          type: 'funnel',
          min: 0,
          max:
            assistantDataCount.reduce((prev, current) => {
              return prev.value > current.value ? prev : current;
            }).value * 2,
          width: '100%',
          height: '63%',
          minSize: '10%',
          maxSize: '180%',
          sort: 'none',
          gap: 2,
          top: 8,
          left: 0,
          right: 0,
          bottom: 0,
          label: {
            show: true,
            position: 'inside'
          },
          emphasis: {
            label: {
              fontSize: 20
            }
          },
          color: ['#3966f800', '#5ee09200', '#ffaf1900', '#ff6a7400', '#cccccccc00'], //last colour show as transparent for all time
          data: assistantDataCount
        }
      ]
    };

    let onEvents = {
      click: this.props.handleFunnelOnClick
    };

    return (
      <div
        style={
          !this.props.trigger || this.props.width < this.props.trigger
            ? { paddingBottom: '100px' }
            : { paddingBottom: '20px', paddingTop: '40px' }
        }
      >
        <QuarterEndDateDialog open={quarterEndDateModal} onClose={this.closeModal} />
        <Grid container spacing={1} style={{ lineHeight: '1.35', marginTop: '8px' }}>
          <Grid container item xs={12}>
            <Grid item xs={6} sm={3} lg={3}>
              {/* 季結 */}
              {/* <BlockingOverlay> */}
              <TimeLeftCard
                r1={r1}
                QEnd={QEnd}
                chartData={chartData}
                bigDateEnd={bigDateEnd}
                openModal={this.quarterEndDateModal.bind(this)}
              />
              {/* </BlockingOverlay> */}
            </Grid>

            <Grid item xs={6} sm={3} lg={3}>
              {/* 智能助理 */}
              <BlockingOverlay>
                <Card className="progress-card" id="assistant-card">
                  <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                    <Grid item className="progress-card-title">
                      {intl.formatMessage({ id: 'Assistant' })}
                    </Grid>
                    <Grid item>
                      <MoreInfoIconButton
                        className="pt-10"
                        handleClick={() => this.setState({ assistantModal: true })}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                    <Grid item className="progress-card-title" style={{ color: '#00000061' }}>
                      {intl.formatMessage({ id: 'stage-1-4-count' })}
                    </Grid>
                  </Grid>
                  <div style={{ marginBottom: '-135px' }}>
                    <ReactEcharts option={assistantOption} onEvents={onEvents} />
                  </div>
                </Card>
              </BlockingOverlay>
            </Grid>

            <Grid item xs={12} sm={6} lg={6} id="quarter-summary">
              {/* 目標 */}
              {/* <BlockingOverlay> */}
              <QuarterTargetCard
                quarterTarget={this.state.quarterTarget}
                weeklyAppt={weeklyAppt}
                openModal={this.quarterTargetModal.bind(this)}
              />
              {/* </BlockingOverlay> */}

              <Grid container>
                <Grid item xs={6}>
                  <BlockingOverlay>
                    <EmojiCard headingStatus={headingStatus} />
                  </BlockingOverlay>
                </Grid>
                {/* 已有生意 */}
                <Grid item xs={6}>
                  <BlockingOverlay>
                    <ClosedBizCard
                      confirmedBiz={confirmedBiz}
                      expectedBiz={quarterBusiness}
                      quarterTarget={quarterTarget}
                      openModal={this.confirmedBizModal}
                    />
                  </BlockingOverlay>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12} sm={6} lg={6} id="progress-summary">
              {/* 季度進度 */}
              <BlockingOverlay>
                <QuarterProgressCard
                  headingStatus={headingStatus}
                  outerChartValue={outerChartValue}
                  targetBiz={targetBiz}
                  QEnd={QEnd}
                  quarterTarget={quarterTarget}
                  confirmedBiz={confirmedBiz}
                  expectedBiz={quarterBusiness}
                  openModal={() => this.setState({ quarterProgressModal: true })}
                />
              </BlockingOverlay>
            </Grid>

            <Grid item xs={12} sm={6} lg={6} id="Followup-card">
              <Grid container>
                {/* 跟進中 */}

                <Grid item xs={12}>
                  <BlockingOverlay>
                    <FollowUpCard
                      targetBiz={targetBiz}
                      opportunityBizVolObj={opportunityBizVolObj}
                      opportunityCount={opportunityCountObj}
                      numberOfRecord={activeTrackRecord}
                      page={'adviser_business'}
                      leftBtnComponent={
                        <Button
                          color="primary"
                          className="edit-button edit-info-button"
                          onClick={this.currentProgressModal.bind(this)}
                        >
                          <CreateIcon />
                        </Button>
                      }
                      rightBtnComponent={
                        <Link
                          to={{
                            pathname: '/client',
                            select: 'TrackOn'
                          }}
                        >
                          <Button variant="outlined" color="primary" className="show-button">
                            {intl.formatMessage({ id: 'show' })}
                          </Button>
                        </Link>
                      }
                    />
                  </BlockingOverlay>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className="modalView"
          contentLabel="Example Modal"
        >
          <div className="modal-text">
            {intl.formatMessage({
              id: 'The selected item is a premium function'
            })}
          </div>
          <div className="modalBtn">
            <input
              type="button"
              onClick={this.closeModal}
              className="action-button"
              value={intl.formatMessage({ id: 'Close' })}
            />
          </div>
        </Modal>
        <TargetEditorModal
          intl={intl}
          remindMe={userDetails.remindMe}
          remindTarget={userDetails.remindTarget}
          open={targetEditorModalOpen}
          onClose={() => this.setState({ targetEditorModalOpen: false })}
          loadingPhase={userDetails !== undefined}
        />
        <BusinessTargetEditorModal
          {...this.props}
          intl={intl}
          open={this.state.quarterTargetModal}
          onClose={() => this.setState({ quarterTargetModal: false })}
          updateSwipe={true}
        />

        <Dialog
          open={this.state.confirmedBizModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
          keepMounted
        >
          <DialogTitle id="alert-dialog-title"> {intl.formatMessage({ id: 'Closed Biz' })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: 'Closed Biz Description' })}</DialogContentText>
            <div className="caseclose-form caseclose-form-business">
              <div className="form-group" style={{ marginTop: '28px' }}>
                <div className="row">
                  {/* <div className="col-md-2 col-xs-2" /> */}
                  <div className="col-md-12 col-xs-12  adv_biz_modal_key">
                    <FormControl className="d-flex-box">
                      <NumberFormat
                        className="form-control"
                        style={{
                          color: '#3966f8',
                          textAlign: 'center',
                          height: '20px',
                          fontSize: '18px'
                        }}
                        thousandSeparator={true}
                        onValueChange={values => {
                          const { value } = values;
                          this.setState({ inputConfirmedBiz: value });
                        }}
                        value={parseInt(this.state.inputConfirmedBiz)}
                        inputMode="decimal"
                        pattern="[0-9]*"
                      />
                    </FormControl>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    {intl.formatMessage({ id: 'Last Update' })}: {confirmedBizUpdateDate}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={this.UpdateConfirmedBiz} color="primary">
              {intl.formatMessage({ id: 'Update' })}
            </Button>
          </DialogActions>
        </Dialog>

        <CurrentProgressModal
          open={this.state.currentProgressModal}
          onClose={this.closeModal}
          radioGrpOnChange={this.handleChange}
          okBtnOnClick={this.updateCurrentProgress}
          closeBtnOnClick={this.closeModal}
          currentProgressOption={currentProgressOption.toString()}
          numberFormatOnChange={values => {
            const { value } = values;
            this.setState({ currentProgress: value });
          }}
          adjustedBusinessVol={parseInt(adjustedBusinessVol, intl)}
          currentProgress={parseInt(this.state.currentProgress, intl)}
          totalAdjustedTrackingBiz={
            highOpportunityBizVol * 0.75 +
            mediumOpportunityBizVol * 0.5 +
            lowOpportunityBizVol * 0.25 +
            noneOpportunityBizVol * 0.1
          }
        />

        {/* Quarter Targer */}
        <QuarterProgressModal
          open={this.state.quarterProgressModal}
          onClose={() => this.setState({ quarterProgressModal: false })}
          okBtnOnClick={() => this.setState({ quarterProgressModal: false })}
        />

        {/* Assistant Modal */}
        <Dialog
          open={this.state.assistantModal}
          onClose={() => this.setState({ assistantModal: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
          keepMounted
        >
          <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Assistant' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'assistant_description' })}</DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ assistantModal: false })} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default BusinessView;
