import React, { Fragment } from 'react';
import { Button, TextField, Card, CardContent } from '@material-ui/core';
import './AccessCodeGate.scss';
import { toast } from 'react-toastify';
import IconToast from '../../components/NewToast';

export default class AccessCodeGate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      accessCode: ''
    };
  }

  handleSubmitAccessCode() {
    const { accessCodes, onAccess } = this.props;
    const { accessCode } = this.state;
    if (accessCodes.findIndex(code => code === accessCode) > -1) {
      this.setState({ authorized: true }, () => {
        if (onAccess) onAccess();
      });
    } else {
      toast.info(IconToast('error', 'Invalid access code!'), { className: 'new-toast' });
    }
  }

  render() {
    const { intl, allowed, children } = this.props;
    const { authorized } = this.state;

    return !allowed && !authorized ? (
      <Card className="access-code-card">
        <CardContent>
          <div>
            <img
              className="logo"
              src="https://www.portfoplus.com/adviser/img/AdvBanner-1.png"
              alt="cheque-banner"
              onClick={() => window.open('http://bit.ly/PFP_Facebook')}
            />
          </div>

          <div className="access-code-input-wrapper">
            <div className="heading">{intl.formatMessage({ id: 'cheque-out-access-heading' })}</div>
            <TextField
              id="accessCode"
              label={intl.formatMessage({ id: 'access-code' })}
              onChange={event => this.setState({ accessCode: event.target.value })}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  this.handleSubmitAccessCode();
                }
              }}
            />

            <div className="hint">{intl.formatMessage({ id: 'cheque-out-disclaimer' })}</div>

            <div className="button-wrapper">
              <Button
                color="primary"
                variant="contained"
                className="submit-button"
                onClick={() => this.handleSubmitAccessCode()}
              >
                {intl.formatMessage({ id: 'Submit' })}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    ) : (
      <Fragment>{children}</Fragment>
    );
  }
}
