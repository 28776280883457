import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const FigureUnitTypo = props => {
  const { color = 'primary', value, unit, fontSizeRem = 1.38, sameFontSize = false } = props;

  return (
    <Grid container alignItems="baseline">
      <Grid item>
        <Typography
          color={color}
          style={{
            fontSize: `${fontSizeRem}rem`
          }}
        >
          {value}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          color={color}
          style={{
            fontSize: `${fontSizeRem * (sameFontSize ? 1 : 0.55)}rem`,
            paddingLeft: 2
          }}
        >
          {unit}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FigureUnitTypo;
