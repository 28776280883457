import { ClickAwayListener, Grid, Tab, Tabs } from '@material-ui/core';
import { getUserInitial } from '../../../../utils/user';
import MyMedicalCard from './MyMedicalCard/MyMedicalCard';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _ from 'lodash';
import { disableScroll, enableScroll } from '../../../../utils/dom';
import { Transition } from 'react-transition-group';
import MyResizeObserver from '../../../../components/MyResizeObserver/MyResizeObserver';
import { usePrevious } from '../../../../utils/hooks';

const getCardImageContainerTransitionStyles = (transitionProps, state) => {
  const transitionX = _.get(transitionProps, 'x', 0),
    transitionY = _.get(transitionProps, 'y', 0),
    transitionWidth = _.get(transitionProps, 'width', 0),
    transitionHeight = _.get(transitionProps, 'height', 0),
    transitionScale = _.get(transitionProps, 'scale', 1),
    transitionTranslateX = _.get(transitionProps, 'translateX', 0),
    transitionTranslateY = _.get(transitionProps, 'translateY', 0);

  const styles = {
    position: 'fixed',
    width: transitionWidth,
    height: transitionHeight,
    top: transitionY,
    left: transitionX,
    zIndex: 2000
  };

  const stateSpecificStyles = {
    entering: {},
    entered: {
      transform: `translate3d(${transitionTranslateX}px, ${transitionTranslateY}px, 0) rotate(90deg) scale(${transitionScale})`,
      transition: 'transform 1000ms',
      backfaceVisibility: 'hidden',
      WebkitFontSmoothing: 'subpixel-antialiased'
    },
    exiting: {
      transform: `translate3d(${transitionTranslateX}px, ${transitionTranslateY}px, 0) rotate(90deg) scale(${transitionScale})`,
      backfaceVisibility: 'hidden',
      WebkitFontSmoothing: 'subpixel-antialiased'
    },
    exited: {
      transition: 'transform 1000ms'
    }
  };

  return _.merge(styles, stateSpecificStyles[state]);
};

const MyMedicalCardsContainerStyle = {
  position: 'relative'
};

const MyMedicalCardSectionStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: 4,
  background: '#bdc2cc',
  zIndex: 100
};

const CardTabsContainerStyle = {
  marginBottom: -4,
  padding: '0 2px'
};

const MyMedicalCardContainerStyle = {
  width: '100%',
  padding: '4px 2px 2px',
  background: '#fff'
};

const DummyDivStyle = {
  width: '100%'
};

const MyMedicalCardsContainer = forwardRef((props, ref) => {
  const { medicalCards, selectedCardNo, onChangeCard, enlarged, onToggleEnlarged } = props;

  const previousEnlarged = usePrevious(enlarged);

  const myMedicalCardRef = useRef(null);
  const hasMedicalCard = (medicalCards || []).length > 0;
  const selectedCard = medicalCards.find(card => card.cardNo === selectedCardNo);

  const cardRef = useRef(null);
  const myMedicalCardSectionRef = useRef(null);

  const [transitionProps, setTransitionProps] = useState(undefined);
  const [exitAnimated, setExitAnimated] = useState(true);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const myMedicalCardSection = _.get(myMedicalCardSectionRef, 'current');

  const toggleEnlarged = useCallback(() => {
    if (!hasMedicalCard && !enlarged) {
      return;
    }

    const elem = _.get(cardRef, 'current');
    if (elem) {
      const rect = elem.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const scale =
        Math.round(Math.min((windowHeight * 0.8) / rect.width, (windowWidth * 0.8) / rect.height) * 100) / 100;
      const elemCenterX = rect.x + rect.width / 2;
      const elemCenterY = rect.y + rect.height / 2;

      setTransitionProps({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
        scale: scale,
        translateX: Math.round(windowWidth / 2 - elemCenterX),
        translateY: Math.round(windowHeight / 2 - elemCenterY)
      });
      if (enlarged) {
        setExitAnimated(false);
      }
    }
  }, [enlarged, hasMedicalCard]);

  useEffect(() => {
    return function cleanup() {
      enableScroll();
    };
  }, []);

  useEffect(() => {
    if (enlarged !== previousEnlarged) {
      toggleEnlarged();
    }
  }, [enlarged, previousEnlarged, toggleEnlarged]);

  return (
    <div ref={cardRef} style={MyMedicalCardsContainerStyle}>
      <MyResizeObserver targetsSelector=".my-medical-card-section" onResize={forceUpdate} />
      <div
        style={{
          ...DummyDivStyle,
          paddingTop: `${(_.get(myMedicalCardSection, 'offsetHeight', 7) /
            _.get(myMedicalCardSection, 'offsetWidth', 10)) *
            100}%`
        }}
      />
      <Transition
        nodeRef={myMedicalCardSection}
        in={!!enlarged}
        timeout={100}
        onEnter={disableScroll}
        onExit={disableScroll}
      >
        {state => {
          const onTransitionEnd = event => {
            const myMedicalCardSectionClassName = _.get(myMedicalCardSectionRef, 'current.className');
            if (
              myMedicalCardSectionClassName &&
              state === 'exited' &&
              (event.target.className || '').indexOf(myMedicalCardSectionClassName) > -1
            ) {
              setExitAnimated(true);
              enableScroll();
            }
          };

          return (
            <ClickAwayListener
              onClickAway={onToggleEnlarged}
              mouseEvent={enlarged ? undefined : false}
              touchEvent={enlarged ? undefined : false}
            >
              <Grid
                ref={myMedicalCardSectionRef}
                className="my-medical-card-section"
                container
                direction="row"
                alignItems="flex-start"
                style={{
                  ...MyMedicalCardSectionStyle,
                  ...(!hasMedicalCard ? { padding: '21px 3px 3px' } : { padding: 3 }),
                  ...(!exitAnimated && getCardImageContainerTransitionStyles(transitionProps, state))
                }}
                onTransitionEnd={onTransitionEnd}
              >
                {hasMedicalCard && (
                  <Grid item xs={12} style={CardTabsContainerStyle}>
                    <Tabs
                      className="card-tabs"
                      value={selectedCardNo}
                      onChange={onChangeCard}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{
                        style: {
                          left: `${medicalCards.findIndex(card => card.cardNo === selectedCardNo) * 41.45}px`,
                          width: 41.45
                        }
                      }}
                      style={{
                        transform: `perspective(1px) translateZ(0)`,
                        backfaceVisibility: 'hidden',
                        WebkitFontSmoothing: 'subpixel-antialiased'
                      }}
                    >
                      {medicalCards.map(card => {
                        return (
                          <Tab
                            key={card.cardNo}
                            label={`${getUserInitial({ name: card.firstName })}${getUserInitial({
                              name: card.lastName
                            })}`}
                            value={card.cardNo}
                          />
                        );
                      })}
                    </Tabs>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  style={MyMedicalCardContainerStyle}
                  onClick={hasMedicalCard ? onToggleEnlarged : undefined}
                >
                  <MyMedicalCard ref={myMedicalCardRef} medicalCard={selectedCard} />
                </Grid>
              </Grid>
            </ClickAwayListener>
          );
        }}
      </Transition>
    </div>
  );
});

export default MyMedicalCardsContainer;
