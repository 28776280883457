import EN_TRANSLATION from './messages/en.json';
import ZH_HANT_HK_TRANSLATION from './messages/zh-Hant-HK.json';
import ZH_TRANSLATION from './messages/zh.json';
import { LOCALE_SELECTED } from './action';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
// import amplitude from 'amplitude-js/amplitude';
import * as amplitude from '@amplitude/analytics-browser';
import { getSearchParam } from '../../utils/url';

// using parameter in url search or navigator language as default language
const url = window.location.href;
const urlSplits = url.split('?');
const search = urlSplits.length > 1 ? `?${urlSplits[1]}` : undefined;
let selectedLang;
if (search) {
  selectedLang = getSearchParam(search, 'lang');
} else {
  const userLang = navigator.language || navigator.userLanguage;
  selectedLang = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
}

let defaultTranslation;
switch (selectedLang) {
  case 'zh':
    defaultTranslation = ZH_TRANSLATION;
    break;
  case 'zh-Hant-HK':
    defaultTranslation = ZH_HANT_HK_TRANSLATION;
    break;
  default:
    defaultTranslation = EN_TRANSLATION;
    break;
}

const initialState = {
  lang: defaultTranslation.lang,
  messages: defaultTranslation.messages
};

export const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCALE_SELECTED:
      let identify = new amplitude.Identify().set('Locale', action.locale);
      amplitude.identify(identify);

      moment.locale(action.locale);

      // Tracking
      mixpanel.people.set({
        Locale: action.locale
      });
      mixpanel.register({
        Locale: action.locale
      });

      if (action.locale === 'zh') {
        import('../../zh.scss').then(() => { });
      } else if (action.locale === 'zh-Hant-HK') {
        import('../../zh-Hant-HK.scss').then(() => { });
      } else {
        import('../../en.scss').then(() => { });
      }

      let data;
      switch (action.locale) {
        case 'en':
          data = EN_TRANSLATION;
          break;
        case 'zh-Hant-HK':
          data = ZH_HANT_HK_TRANSLATION;
          break;
        case 'zh':
          data = ZH_TRANSLATION;
          break;
        default:
          data = EN_TRANSLATION;
          break;
      }

      return {
        lang: data.lang,
        messages: data.messages
      };
    default:
      return state;
  }
};
