import { fetch } from '../../utils';

import mixpanel from 'mixpanel-browser';
// import amplitude from 'amplitude-js/amplitude';
import * as amplitude from '@amplitude/analytics-browser';
// import ReactPixel from 'react-facebook-pixel';

import Backend from '../../backend';
import { ga4Tracking, pixelTracking, tracking } from '../../utils/tracking';
import { isCurrentShortSubscriptionTrialing, ShortSubscriptions } from '../../utils/user-subscription';
import NativeOrWeb from '../../utils/native';
import _ from 'lodash';

//API TO RECREATE A NEWER TOKEN AND EXTEND THE EXP
export const authenticateUser = explicitAccessToken => {
  return fetch(Backend.methodUrl('auth'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(explicitAccessToken ? { accessToken: explicitAccessToken } : {})
  })
    .then(response => {
      // alert(response.status);

      return response.json();
    })
    .then(payload => {
      // alert(JSON.stringify(payload));
      if (payload && payload._id) {
        // identify user if auth success
        mixpanel.identify(payload._id);
        amplitude.setUserId(payload._id);
      }
      return payload;
    })
    .catch(responseError => {
      const error = {
        message: 'Authentication failed',
        error: responseError
      };
      throw error;
    });
};
//API FO LOGIN
export const loginUser = credentials => {
  return fetch(Backend.methodUrl('loginUser'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...credentials, source: NativeOrWeb.isNativePlatform() ? "app" : "web" })
  })
    .then(res => res.json())
    .then(payload => {
      if (payload && payload.success && payload.data && payload.data._id) {
        const campaignCodesText = (payload.data.campaignCodes || []).map(campaignCode => campaignCode.text);
        const hasValidCoreSubscription =
          payload.data.userType === 'Adviser' &&
          !!new ShortSubscriptions(payload.data.subscriptions).containValidCoreSubscription();
        const isTrialing =
          payload.data.userType === 'Adviser' && isCurrentShortSubscriptionTrialing(payload.data.subscriptions);

        // Tracking
        if (payload.data.userType === 'Adviser') {
          // Tracking
          mixpanel.identify(payload.data._id);
          // console.log('payload.data::: ', payload.data);

          const setProperty = {
            $name: payload.data.name,
            $email: payload.data.email,
            $emailVerified: payload.data.emailVerified,
            $created: payload.data.createDate,
            $lastLogin: payload.data.lastLogin,
            $campaignCodesText: campaignCodesText,
            $optOutOfEmail: payload.data.optOutOfEmail,
            $language: payload.data.language,
            $hasValidCoreSubscription: hasValidCoreSubscription,
            $isTrialing: isTrialing,
            $teamName: payload.data.teamDetails.teamNames,
            $teamShortName: payload.data.teamDetails.teamShortNames,
            $teamCampaignCodes: payload.data.teamDetails.teamCampaignCodes,
            $teams: payload.data.teamDetails.teams,
            'User Type': payload.data.userType,
            $gender: payload.data.gender,
            $hobbies: payload.data.hobbies,
            $seniority: payload.data.seniority,
            $personalityTags: payload.data.personalityTags,
            $personality: payload.data.personality,
            $isMDRT: payload.data.isMDRT,
            $age: payload.data.age,
            $dob: payload.data.dob
          };
          mixpanel.people.set(setProperty);

          mixpanel.register({
            'User Type': payload.data.userType
          });
        }

        amplitude.setUserId(payload.data._id);
        let identify = new amplitude.Identify()
          .set('Name', payload.data.name)
          .set('Email', payload.data.email)
          .set('Email Verified', payload.data.emailVerified)
          .set('User Type', payload.data.userType)
          .set('Last Login', payload.data.lastLogin)
          .set('Campaign Codes Text', campaignCodesText)
          .set('Opt Out Email', payload.data.optOutOfEmail)
          .set('Language', payload.data.language)
          .set('Team Name', payload.data.teamDetails.teamNames)
          .set('Team Short Name', payload.data.teamDetails.teamShortNames)
          .set('Team Campaign Codes', payload.data.teamDetails.teamCampaignCodes)
          .set('Teams', payload.data.teamDetails.teams)
          .set('Seniority', payload.data.seniority)
          .set('Personality Tags', payload.data.personalityTags)
          .set('Personality', payload.data.personality)
          .set('Is MDRT', payload.data.isMDRT)
          .set('Age', payload.data.age)
          .set('DOB', payload.data.dob)
          .set('Gender', payload.data.gender)
          .set('Hobbies', payload.data.hobbies)
          .setOnce('Created', payload.data.createDate);
        if (payload.data.userType === 'Adviser') {
          identify.set('Has Valid Core Subscription', hasValidCoreSubscription);
          identify.set('Is Trialing', isTrialing);
        }
        amplitude.identify(identify);

        if (payload.data.emailVerified) {
          tracking('User Logged In');
        }
      }

      return payload;
    })
    .catch(error => {
      error = {
        message: 'Invalid Email!'
      };
      throw error;
    });
};

//API TO LOGOUT
export const logoutUser = async _ => {
  try {
    const pushToken = await NativeOrWeb.getPushNotificationToken();
    const res = await fetch(Backend.methodUrl('logoutUser'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: pushToken
      })
    });
    const payload = await res.json();
    return payload;
  } catch (error) {
    throw { message: 'Logout error' };
  }
};

//API TO CREATE THE USER FOR ADVISER
export const createUser = data => {
  return fetch(Backend.methodUrl('registerUser'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if (payload && payload.success && payload.data && payload.data._id) {
        if (payload.data.userType === 'Adviser') {
          // Tracking
          mixpanel.identify(payload.data._id);
          mixpanel.people.set({
            $name: payload.data.name,
            $email: payload.data.email,
            'User Type': payload.data.userType,
            $created: payload.data.createDate
          });
          // pixelTracking('CompleteRegistration');
        }

        // Tracking
        let identify = new amplitude.Identify()
          .set('Name', payload.data.name)
          .set('Email', payload.data.email)
          .set('Email Verified', payload.data.emailVerified)
          .set('User Type', payload.data.userType)
          .setOnce('Created', payload.data.createDate);
        amplitude.identify(identify);

        tracking('User Created');

        ga4Tracking('user_signup', {
          user_type: payload.data.userType,
          status: 'accepted',
          phone: payload.data.phoneNumber,
          email: payload.data.email,
          username: payload.data.name,
          opt_in: !payload.data.optOutOfEmail,
          language: payload.data.language
        });
      }

      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO CREATE THE USER FOR CLIENT
export const createClient = data => {
  return fetch(Backend.methodUrl('registerClient'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if (payload && payload.success && payload.data && payload.data._id) {
        // Tracking
        amplitude.setUserId(payload.data._id);
        let identify = new amplitude.Identify()
          .set('Name', payload.data.name)
          .set('Email', payload.data.email)
          .set('Email Verified', payload.data.emailVerified)
          .set('User Type', payload.data.userType)
          .setOnce('Created', payload.data.createDate);
        amplitude.identify(identify);

        tracking('User Created');

        // pixelTracking('Lead');
      }

      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO APPROVE THE USER BY SUPERADMIN
export const approveUser = data => {
  return fetch(Backend.methodUrl('approveUser'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO VERFIY THE EMAIL OF ADVISER
export const verifyEmail = data => {
  return fetch(Backend.methodUrl('verifyEmail'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO UPDATE THE USER PROFILE
export const updateUserDetails = data => {
  return fetch(Backend.methodUrl('updateUserDetails'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO CHANGE THE PASSWORD
export const changePassword = data => {
  return fetch(Backend.methodUrl('changePassword'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE LINK OF RESET PASSWORD
export const forgotPassword = data => {
  return fetch(Backend.methodUrl('forgotPassword'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE RESET PASSWORD WHEN USER GO TO THORUGH THE LINK
export const resetPassword = data => {
  return fetch(Backend.methodUrl('resetPassword'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE LOGIN USER DATA
export const getUser = () => {
  return fetch(Backend.methodUrl('getUser'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE CONNECTED ADVISER FOR CHAT WITH ADVISER
export const getConnectedAdviser = () => {
  return fetch(Backend.methodUrl('getConnectedAdviser'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE FOREX CURRENCY RATE
export const currencyExchangeRate = () => {
  return fetch(Backend.methodUrl('currencyExchangeRate'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE DEFAULT MESSAGE
export const defaultMessages = () => {
  return fetch(Backend.methodUrl('defaultMessages'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE NOTIFICATIUON OF REMINDER POLICY
export const getNotifications = () => {
  return fetch(Backend.methodUrl('getNotifications'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE FIGURE USER
export const getFigureUser = () => {
  return fetch(Backend.methodUrl('getFigureUser'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// APT TO CHECK WHETHER EMAIL IS REGISTERED
export const checkEmail = email => {
  return fetch(Backend.methodUrl(`check-email/${email}`), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if (payload.error) {
        throw new Error(payload.error);
      } else {
        return payload;
      }
    })
    .catch(error => {
      throw error;
    });
};



// APT TO CHECK WHETHER EMAIL IS REGISTERED
export const requestAccountDeletion = (email, survey) => {
  return fetch(Backend.api(`account/deletion`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email, survey: survey })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if (payload.error) {
        throw new Error(payload.error);
      } else {
        if (payload.message === 'Requested') {
          // disable the deleted button
          localStorage.setItem('accountDeletionRequested', 'true');
        }
        return payload;
      }
    })
    .catch(error => {
      throw error;
    });
};