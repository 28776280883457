import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { decimalToString } from '../../../../utils/formatNumber';
import { injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

const NumberFormatWrapper = props => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      decimalSeparator="."
      thousandSeparator=","
      inputMode="decimal"
      pattern="[0-9],*"
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value !== '' ? parseFloat(values.value) : undefined
          }
        });
      }}
    />
  );
};

const CustomTopUpInput = props => {
  const { intl, value, onFocus, onChange, startAdornmentLabel, ...rest } = props;

  const [internalValue, setInternalValue] = useState(value || 0);

  const onTextFieldChange = event => {
    setInternalValue(event.target.value);
  };

  const onBlur = event => {
    if (onChange) {
      //parse internal value back to a float
      //since the parsing does not recognize commas, we remove them
      let val = parseFloat(internalValue);
      if (internalValue === undefined) {
        setInternalValue('0');
      } else if (typeof internalValue === 'string') {
        val = parseFloat(internalValue.replace(/,/g, ''));
      }
      onChange({ target: { value: internalValue ? val : 0 } });
    }
  };

  useEffect(() => {
    setInternalValue(decimalToString(value));
  }, [value]);

  return (
    <TextField
      className="analysis-input"
      name="customTopUp"
      label={intl.formatMessage({ id: 'cus-top-up' })}
      value={internalValue}
      onChange={onTextFieldChange}
      type="text"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: NumberFormatWrapper,
        inputProps: {
          allowNegative: false,
          decimalScale: 1,
          isAllowed: values => {
            const { formattedValue, value } = values;
            return formattedValue === '' || value;
          },
          onBlur: onBlur
        },
        startAdornment:
          startAdornmentLabel !== undefined ? (
            <InputAdornment position="start">{startAdornmentLabel}</InputAdornment>
          ) : (
            undefined
          ),
        className: 'analysis-input-text'
      }}
      {...rest}
    />
  );
};

export default injectIntl(CustomTopUpInput);
