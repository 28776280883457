import React, { PureComponent, Fragment } from 'react';
import ForgotPasswordView from './partials/forgotPassword';
import ResetPasswordView from './partials/resetPassword';

class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordId: ''
    };
  }

  updateResetPasswordId = () => {
    if (this.props.match.params.Id) {
      var resetPasswordId = this.props.location.pathname.replace('/reset_password/', '');
      this.setState({ resetPasswordId: resetPasswordId });
    }
  };

  UNSAFE_componentWillMount() {
    this.updateResetPasswordId();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateResetPasswordId();
  }

  componentDidMount() {
    this.props.renderHeaderButton(true);
  }

  render() {
    const currentLocation = this.props.location.pathname;
    return (
      <Fragment>
        {currentLocation === '/forgot_password' && <ForgotPasswordView {...this.props} />}
        {this.state.resetPasswordId !== '' && <ResetPasswordView {...this.props} />}
      </Fragment>
    );
  }
}

export default ForgotPassword;
