import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, useMediaQuery } from '@material-ui/core';
import BusinessToolCard from './BusinessToolCard/BusinessToolCard';
import AdviserHomePageSubtitle from '../AdviserHomePageSubtitle/AdviserHomePageSubtitle';
import { withRouter } from 'react-router';
import { tracking } from '../../../utils/tracking';
import { getNewReferralCount } from '../../../store/referral/duck';
import Badge from '@material-ui/core/Badge';
import HelpCenterButton from './HelpCenterButton/HelpCenterButton';

const FullWidthStyle = {
  width: '100%'
};

const ContainerStyle = {
  ...FullWidthStyle,
  marginTop: 8
};

const ItemStyle = {
  ...FullWidthStyle,
  marginBottom: 5
};

const OptionGridStyle = {
  ...ItemStyle,
  background: '#f0f6ff',
  borderRadius: 16,
  position: 'relative',
  borderTop: '2px solid #c9caff'
};

const OverlayStyle = {
  background: '#d4e7ff',
  position: 'absolute',
  borderRadius: 16,
  width: 'calc(53% + 250px)',
  height: '100%',
  opacity: 0.5
};

const LeftOverlayStyle = {
  ...OverlayStyle,
  left: 0,
  top: 0
};

const RightOverlayStyle = {
  ...OverlayStyle,
  right: 0,
  top: -32
};

const RightOverlayDecorationContainerStyle = {
  ...RightOverlayStyle,
  background: 'transparent',
  opacity: 1
};

const RightOverlayDecorationStyle = {
  position: 'absolute',
  bottom: 0,
  width: 'calc((100% - 563px) / 7)'
};

const CardGridStyle = {
  textAlign: 'center',
  zIndex: 1
};

const CartStyle = {
  // maxWidth: 500,
};

const BusinessToolsSection = props => {
  const { intl, history, newReferralCount, getNewReferralCount } = props;
  const isWideLayout = useMediaQuery(theme => theme.breakpoints.up('md'));

  const doorOpener = () => {
    tracking('Click on Door Opener - Homepage');
    history.push({ pathname: '/door-opener' });
  };
  const dateClientTools = () => {
    tracking('Click on Client Meet (category) - Homepage');
    history.push('/meeting-tools');
  };
  const recruitment = () => {
    tracking('Click on Recruitment (category) - Homepage');
    history.push('/recruitment');
  };
  const acquireClient = () => {
    tracking('Click on Lead Generation (category) - Homepage');
    history.push('/client-acquisition');
  };
  const helpCenter = () => {
    tracking('Click on Help Center - Homepage');
    history.push('/new_help_center');
  };

  const options = [
    {
      title: intl.formatMessage({ id: 'door-opener' }),
      description: intl.formatMessage({ id: 'adv-home-biz-tools-1-desc' }),
      backgroundColor: '#5fc3d2',
      imgSrc: 'img/adviser-home-page/big_button-_date_client.png',
      imgAlt: 'door-opener',
      action: doorOpener
    },
    {
      title: (
        <Badge variant="dot" color="error" badgeContent="">
          {intl.formatMessage({ id: 'meeting-tools' })}
        </Badge>
      ),
      description: intl.formatMessage({ id: 'adv-home-biz-tools-2-desc' }),
      backgroundColor: '#3999f8',
      imgSrc: 'img/adviser-home-page/big_button-_meet_client.png',
      imgAlt: 'meeting-tools',
      action: dateClientTools
    },
    {
      title: intl.formatMessage({ id: 'client-acquisition' }),
      titleExtras: !!newReferralCount && (
        <Badge className="referral-count" badgeContent={newReferralCount} color="error" />
      ),
      description: intl.formatMessage({ id: 'adv-home-biz-tools-4-desc' }),
      backgroundColor: '#2260a5',
      imgSrc: 'img/adviser-home-page/big_button-_CRM.png',
      imgAlt: 'acquire-clients',
      action: acquireClient
    },
    {
      title: intl.formatMessage({ id: 'team-recruitment' }),
      description: intl.formatMessage({ id: 'adv-home-biz-tools-3-desc' }),
      backgroundColor: '#453bd1',
      imgSrc: 'img/adviser-home-page/big_button-_get_client.png',
      imgAlt: 'recruitment',
      action: recruitment
    }
  ];

  useEffect(() => {
    getNewReferralCount();
  }, [getNewReferralCount]);

  return (
    <Grid className="biz-tools" container direction="column" style={ContainerStyle}>
      <Grid item style={ItemStyle}>
        <AdviserHomePageSubtitle title={intl.formatMessage({ id: 'adv-home-biz-tools-title' })} />
      </Grid>
      <Grid
        item
        style={{
          ...OptionGridStyle,
          padding: isWideLayout ? '44px 24px 24px' : '36px 12px 12px',
          marginTop: isWideLayout ? 0 : 16
        }}
      >
        <HelpCenterButton
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isWideLayout ? 428 : 'calc(85% - 13.6px)'
          }}
          isWideLayout={isWideLayout}
          onClick={helpCenter}
        />
        <Grid container direction="row" alignItems="center" spacing={isWideLayout ? 5 : 2}>
          {options.map((option, index) => (
            <Grid key={index} item xs={isWideLayout ? 6 : 12} style={CardGridStyle}>
              <BusinessToolCard {...option} small={!isWideLayout} style={CartStyle} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const container = connect(
  state => ({
    newReferralCount: state.referral.newReferralCount
  }),
  {
    getNewReferralCount
  }
)(BusinessToolsSection);

export default injectIntl(withRouter(container));
