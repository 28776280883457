import { getSelectorsByUserAgent } from 'react-device-detect';
import { INVERTED_REFERRAL_LINK_SEARCH_PARAMS_MAP, REFERRAL_LINK_SEARCH_PARAMS_MAP } from '../constants/referral';
import { tryTranslate } from './locale';
import { getLikedTagIntl } from './my-details';

const PFPLUS_BACKEND_URL = process.env.PFPLUS_BACKEND_URL;

export const getReferralLink = ({
  targetAdviser,
  client,
  source,
  medium,
  role,
  isQRCode,
  userDetails,
  isInternalRoute,
  extraSearch
}) => {
  // if (!client) {
  //   return `${HOSTNAME}/referral_response/adv/${targetAdviser._id}`;
  // } else {
  //   return `${HOSTNAME}/referral_response/usr/${targetAdviser._id}$${client._id}`;
  // }

  const searchObj = {
    [REFERRAL_LINK_SEARCH_PARAMS_MAP.source]: source,
    [REFERRAL_LINK_SEARCH_PARAMS_MAP.medium]: medium || (isQRCode ? 'app-qr' : 'link'),
    [REFERRAL_LINK_SEARCH_PARAMS_MAP.role]: role,
    ...extraSearch
  };

  if (client && client._id) {
    searchObj[REFERRAL_LINK_SEARCH_PARAMS_MAP.referById] = client._id;
  }

  if (userDetails && userDetails.userType) {
    if (userDetails.userType === 'Adviser') {
      searchObj[REFERRAL_LINK_SEARCH_PARAMS_MAP.role] = 'adviser';
    } else if (userDetails.userType === 'User') {
      searchObj[REFERRAL_LINK_SEARCH_PARAMS_MAP.role] = 'client';
    }
  }

  // delete undefined values
  Object.keys(searchObj).forEach(key => {
    if (searchObj[key] === undefined) {
      delete searchObj[key];
    }
  });

  const searchString = Object.keys(searchObj).length > 0 ? new URLSearchParams(searchObj).toString() : '';

  const path = `/adviser/${targetAdviser._id}${searchString ? `?${searchString}` : ''}`;

  if (isInternalRoute) {
    return path;
  } else {
    return `${PFPLUS_BACKEND_URL}${path}`;
  }
};

export const getReferralLinkInternalRoute = ({
  targetAdviser,
  client,
  source,
  medium,
  role,
  isQRCode,
  userDetails
}) => {
  return getReferralLink({ targetAdviser, client, source, medium, role, isQRCode, userDetails }).replace(
    PFPLUS_BACKEND_URL,
    ''
  );
};

export const getFormattedReferralLinkSearchObject = searchObj => {
  const formattedSearchObj = {};

  Object.keys(searchObj || {}).forEach(key => {
    if (INVERTED_REFERRAL_LINK_SEARCH_PARAMS_MAP[key]) {
      formattedSearchObj[INVERTED_REFERRAL_LINK_SEARCH_PARAMS_MAP[key]] = searchObj[key];
    }
  });

  return formattedSearchObj;
};

export const getReferralSourceString = (intl, referral) => {
  const { source, medium, role, userAgent, isAutoGenerated } = referral;

  let str = '';

  if (isAutoGenerated) {
    str += intl.formatMessage({ id: 'Auto' });
  }

  if (medium) {
    const MEDIUM_MAP = {
      link: intl.formatMessage({ id: 'link' }),
      'app-qr': intl.formatMessage({ id: 'QR Code' }),
      nfc: 'NFC',
      'apple-wallet': 'Apple Wallet',
      'google-wallet': 'Google Wallet'
    };

    const formattedMediumStr = MEDIUM_MAP[medium] || medium;

    str += `${str ? ', ' : ''}${formattedMediumStr}`;
  }

  if (role && role !== 'adviser') {
    const ROLE_MAP = {
      adviser: intl.formatMessage({ id: 'Adviser' }),
      client: intl.formatMessage({ id: 'Client' })
    };

    const formattedRoleStr = ROLE_MAP[role] || role;

    str += `${str ? ', ' : ''}${formattedRoleStr}`;
  }

  if (source) {
    const SOURCE_MAP = {
      'lazy-pack': intl.formatMessage({ id: 'lazy-pack' }),
      pdf: 'PDF'
    };

    const formattedSourceStr = SOURCE_MAP[source] || source;

    str += `${str ? ', ' : ''}${formattedSourceStr}`;
  }

  if (userAgent) {
    const { osName } = getSelectorsByUserAgent(userAgent);
    str += `${str ? ', ' : ''}${osName}`;
  }

  return str;
};

export const getReferralRemarkString = (intl, referral) => {
  let str = '';

  const { email, answerTags, likedTags, helpItems, remark } = referral || {};

  if ((helpItems && helpItems.length > 0) || remark) {
    str += `🔍${intl.formatMessage({ id: 'leave-comment' })}: ${helpItems
      .map(helpItem => tryTranslate(intl, getLikedTagIntl(helpItem)))
      .join(', ')}`;
    if (remark) {
      if (helpItems.length > 0) {
        str += ', ';
      }
      str += remark;
    }
  }

  if (str) {
    str += '\n';
  }

  if (answerTags) {
    let count = 1;
    for (let question in answerTags) {
      str += `❓${intl.formatMessage({ id: 'qn' })}: ${question}, 📝${intl.formatMessage({ id: 'ans' })}: ${answerTags[question]}\n`;
      count += 1;
    }
  }

  if (likedTags && likedTags.length > 0) {
    str += `👍${intl.formatMessage({ id: 'referral-request' })}: ${likedTags
      .map(likedTag => tryTranslate(intl, getLikedTagIntl(likedTag)))
      .join(', ')}`;
  }

  if (email) {
    if (str) {
      str += '\n';
    }
    str += `${intl.formatMessage({ id: 'email' })}: ${email}`;
  }

  return str;
};
