import React, { Fragment, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles, Switch } from '@material-ui/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { requestPermissions, unregisterPushNotification, getPushNotificationToken } from '../../../utils/native/push-notification';

const useStyles = makeStyles({
  root: {
    marginRight: -8
  }
});

const NotificationSwitch = props => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const classes = useStyles(props);

  async function checkPermissions() {
    let localNotificationPermissionStatus = await LocalNotifications.checkPermissions();
    let pushNotificationPermissionStatus = await PushNotifications.checkPermissions();
    if (pushNotificationPermissionStatus.receive === 'granted' && localNotificationPermissionStatus.display === 'granted') {
      let pushNotificationToken = await getPushNotificationToken();
      if (pushNotificationToken) {
        setPermissionGranted(true);
      } else {
        setPermissionGranted(false);
      }
    } else {
      setPermissionGranted(false);
    }
  }

  useEffect(() => {
    checkPermissions();
  }, []);

  const onChange = async event => {
    if (event.target.checked) {
      await requestPermissions(false, setPermissionGranted);
    } else {
      await unregisterPushNotification();
      setPermissionGranted(false);
    }
  };

  return (
    <Fragment>
      <Switch color="primary" checked={permissionGranted} onChange={onChange} classes={classes} />
    </Fragment>
  )
};

export default injectIntl(NotificationSwitch);
