import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { stripHTML } from '../../../../../utils/helpers';
import _ from 'lodash';
import ShareButtonGroup from '../../../../../components/ShareButtonGroup/ShareButtonGroup';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const CloseButtonStyle = {
  position: 'absolute',
  top: 4,
  right: 4
};

const ShareToClientMessageDialog = props => {
  const {
    intl,
    open,
    title,
    content,
    phone,
    whatsapp,
    email,
    getMessage,
    onClose,
    formatWhatsappSubject,
    extraButton
  } = props;

  const [language, setLanguage] = useState(_.get(intl, 'locale', 'en'));

  const onChangeLanguage = async event => {
    const language = event.target.value;
    if (language === 'zh') {
      await import(`../../../../../zh.scss`);
    } else if (language === 'zh-Hant-HK') {
      await import(`../../../../../zh-Hant-HK.scss`);
    } else {
      await import(`../../../../../en.scss`);
    }

    setLanguage(language);
  };

  const displayMessage = getMessage(language);
  const whatsappMessage = stripHTML(displayMessage.replace(/<b>|<\/b>/g, '*'));
  const htmlStrippedMessage = stripHTML(displayMessage);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} fullWidth={true}>
      <IconButton onClick={onClose} style={CloseButtonStyle}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div>
          {content && content}
          <div className="analysis-input-groups">
            <FormControl
              className="d-flex-box-lang input-group-option-select font-css client-reminder-color"
              margin="none"
            >
              <RadioGroup
                aria-label="inputGroupOption"
                name="messageLanguage"
                value={language.toString()}
                onChange={onChangeLanguage}
              >
                <FormControlLabel
                  className="text-left language__select"
                  value="en"
                  control={<Radio color="primary" />}
                  label="ENG"
                />
                <FormControlLabel
                  className="text-left language__select"
                  value="zh-Hant-HK"
                  control={<Radio color="primary" />}
                  label="繁中"
                />
                <FormControlLabel
                  className="text-left language__select"
                  value="zh"
                  control={<Radio color="primary" />}
                  label="简中"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <ShareButtonGroup
            title={whatsappMessage}
            url=""
            whatsappNumber={whatsapp}
            phone={phone}
            isCTA
            CopyTextFieldProps={{
              multiline: true
            }}
          />
        </div>
      </DialogContent>
      {extraButton && <DialogActions>{extraButton}</DialogActions>}
    </Dialog>
  );
};

export default injectIntl(ShareToClientMessageDialog);
