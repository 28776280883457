import React from 'react';
import { IconButton } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';

class ShareIconButtonn extends React.Component {
  render() {
    const { className, style, ariaLabel, handleClick } = this.props;
    return (
      <IconButton
        aria-label={ariaLabel ? ariaLabel : 'Share'}
        color="primary"
        className={className}
        style={style}
        onClick={handleClick}
      >
        <ShareIcon />
      </IconButton>
    );
  }
}

export default ShareIconButtonn;
