import React from 'react';
import { DialogContent, Typography } from '@material-ui/core';
import { sanitize } from 'dompurify';

const styles = {
  helpImage: { maxHeight: '45vh', maxWidth: '100%', margin: 'auto' },
  mainButton: { marginRight: 5 },
  text: { whiteSpace: 'pre-wrap' }
};

class PreferenceHelp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      helpChecked: false
    };
  }

  render() {
    const { intl, preferenceIndex } = this.props;
    return (
      <div>
        {preferenceIndex === 1 ? (
          <DialogContent>
            <div className="page-containt sliderImg preference-help-img">
              <img src="animated-svg/APP-AddPolicy-Help.svg" alt="" style={styles.helpImage} />
            </div>
            <Typography
              dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'dashboard-help-content' })) }}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <div className="page-containt sliderImg preference-help-img">
              <img src="animated-svg/APP-Connect-Help.svg" alt="" style={styles.helpImage} />
            </div>
            <Typography
              dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'adviser-help-content' })) }}
              style={styles.text}
            />
          </DialogContent>
        )}
      </div>
    );
  }
}

export default PreferenceHelp;
