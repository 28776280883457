import React, { useState, useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const ClientSearchField = props => {
  const { delay, value, onChange, onClickPowerSearch, ...rest } = props;
  const [internalValue, setInternalValue] = useState(value);
  const valueRef = useRef(value);

  const update = event => {
    const target = event.target;
    setInternalValue(target.value);
    valueRef.current = target.value;
    setTimeout(() => {
      if (valueRef.current === target.value) {
        onChange({ target: target });
      }
    }, delay || 300);
  };

  const clear = () => update({ target: { value: '' } });

  return (
    <TextField
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={onClickPowerSearch} style={{ padding: 5 }}>
              <img alt="serach" src="img/client-search.png" style={{ height: 20 }} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={internalValue ? clear : undefined}
              edge="end"
              style={{ padding: 5, visibility: internalValue ? undefined : 'hidden' }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        ),
        style: {
          borderRadius: 12,
          paddingLeft: 0,
          font: 'inherit'
        }
      }}
      onChange={update}
      value={internalValue}
      {...rest}
    />
  );
};

export default ClientSearchField;
