import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles({
  inputBase: {
    fontSize: 'inherit'
  },
  input: {
    height: '15px'
  }
});

const LoginTextField = props => {
  const { InputProps, ...rest } = props;

  const classes = useStyles(props);

  return (
    <TextField
      className="input"
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.inputBase,
          input: classes.input
        },
        ...InputProps
      }}
      {...rest}
    />
  );
};

export default LoginTextField;
