import React from 'react';
import { injectIntl } from 'react-intl';
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import NumberFormatTextField from '../../../views/AddPolicy/Inputs/NumberFormatInput/NumberFormatTextField';

const FAMILY_LIFE_CATEGORY_COLOR = '#3eda7d';
const OTHER_EXPENSES_CATEGORY_COLOR = '#f49e0b';

const useInputStyles = makeStyles(theme =>
  createStyles({
    input: {
      'box-sizing': 'content-box',
      padding: '6px 0 7px',
      color: theme.palette.primary.main
    }
  })
);

const useInputLabelStyles = makeStyles({
  root: {
    margin: 0
  },
  formControl: {
    transform: 'translate(0, 24px) scale(1)'
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(1)'
  }
});

const ThisNumberFormatTextField = props => {
  const { InputProps, InputLabelProps, onChange, disabled, ...rest } = props;
  const inputClasses = useInputStyles();
  const inputLabelClasses = useInputLabelStyles();

  const formattedInputProps = { ...InputProps };
  const formattedInputLabelProps = { ...InputLabelProps };

  if (!formattedInputProps.classes) {
    formattedInputProps.classes = inputClasses;
  }

  if (!formattedInputLabelProps.classes) {
    formattedInputLabelProps.classes = inputLabelClasses;
  }

  return (
    <NumberFormatTextField
      InputProps={formattedInputProps}
      InputLabelProps={formattedInputLabelProps}
      disabled={disabled !== undefined ? disabled : onChange === undefined}
      onChange={onChange}
      {...rest}
    />
  );
};

const CategoryHeader = props => {
  const { icon, label, color, layout } = props;
  const isRowLayout = layout === 'row';

  return (
    <Grid
      container
      justify={isRowLayout ? 'flex-start' : 'center'}
      alignItems={isRowLayout ? 'flex-start' : 'center'}
      direction={isRowLayout ? 'column' : 'row'}
      spacing={1}
      style={{
        height: '100%',
        color: color,
        [isRowLayout ? 'borderRight' : 'borderBottom']: `1px solid ${color}`
      }}
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography style={{ color: color }}>{label}</Typography>
      </Grid>
    </Grid>
  );
};

const GridItem20Style = { flexGrow: 0, maxWidth: '20%', flexBasis: '20%' };
const RowHeaderWidth = 130;

const RowExpenseCalculator = props => {
  const { intl, expenses, onChange, shrink } = props;

  const {
    diet,
    transportation,
    rentMortgage,
    waterElectricityGas,
    supportParents,
    children,
    shopping,
    pets,
    domesticHelpers,
    otherHouseholdExpenses,
    entertainment,
    digital,
    membership,
    mpf,
    insurance,
    tax,
    privateCar,
    repayment,
    tuition,
    otherMiscellaneousExpenses
  } = expenses || {};

  const InputLabelProps = shrink ? { shrink } : undefined;

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={6} style={{ marginBottom: 8 }}>
        <Grid container spacing={3}>
          <Grid item style={{ width: RowHeaderWidth, paddingBottom: 0 }}>
            <CategoryHeader
              icon={<SupervisorAccountRoundedIcon />}
              label={intl.formatMessage({ id: 'ec-cat-family-life' })}
              color={FAMILY_LIFE_CATEGORY_COLOR}
              layout="row"
            />
          </Grid>

          <Grid item style={{ width: `calc(100% - ${RowHeaderWidth}px)` }}>
            <Grid container spacing={1}>
              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-diet' })}
                  name="diet"
                  value={diet || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-transportation' })}
                  name="transportation"
                  value={transportation || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-rent-mortgage' })}
                  name="rentMortgage"
                  value={rentMortgage || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-water-electricity-gas' })}
                  name="waterElectricityGas"
                  value={waterElectricityGas || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-support-parents' })}
                  name="supportParents"
                  value={supportParents || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-children' })}
                  name="children"
                  value={children || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-shopping' })}
                  name="shopping"
                  value={shopping || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-pets' })}
                  name="pets"
                  value={pets || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-domestic-helpers' })}
                  name="domesticHelpers"
                  value={domesticHelpers || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-other-household-expenses' })}
                  name="otherHouseholdExpenses"
                  value={otherHouseholdExpenses || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} style={{ marginBottom: 8 }}>
        <Grid container spacing={3}>
          <Grid item style={{ width: RowHeaderWidth, paddingBottom: 0 }}>
            <CategoryHeader
              icon={<ShoppingCartRoundedIcon />}
              label={intl.formatMessage({ id: 'ec-cat-other-expenses' })}
              color={OTHER_EXPENSES_CATEGORY_COLOR}
              layout="row"
            />
          </Grid>

          <Grid item style={{ width: `calc(100% - ${RowHeaderWidth}px)` }}>
            <Grid container spacing={1}>
              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-entertainment' })}
                  name="entertainment"
                  value={entertainment || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-digital' })}
                  name="digital"
                  value={digital || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-membership' })}
                  name="membership"
                  value={membership || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-mpf' })}
                  name="mpf"
                  value={mpf || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-insurance' })}
                  name="insurance"
                  value={insurance || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-tax' })}
                  name="tax"
                  value={tax || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-private-car' })}
                  name="privateCar"
                  value={privateCar || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-repayment' })}
                  name="repayment"
                  value={repayment || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-tuition' })}
                  name="tuition"
                  value={tuition || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item style={GridItem20Style}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-other-miscellaneous-expenses' })}
                  name="otherMiscellaneousExpenses"
                  value={otherMiscellaneousExpenses || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ColumnExpenseCalculator = props => {
  const { intl, expenses, onChange, shrink } = props;

  const InputLabelProps = { shrink };

  const {
    diet,
    transportation,
    rentMortgage,
    waterElectricityGas,
    supportParents,
    children,
    shopping,
    pets,
    domesticHelpers,
    otherHouseholdExpenses,
    entertainment,
    digital,
    membership,
    mpf,
    insurance,
    tax,
    privateCar,
    repayment,
    tuition,
    otherMiscellaneousExpenses
  } = expenses || {};

  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ margin: '0 4px' }}>
            <CategoryHeader
              icon={<SupervisorAccountRoundedIcon />}
              label={intl.formatMessage({ id: 'ec-cat-family-life' })}
              color={FAMILY_LIFE_CATEGORY_COLOR}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-diet' })}
                  name="diet"
                  value={diet || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-transportation' })}
                  name="transportation"
                  value={transportation || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-rent-mortgage' })}
                  name="rentMortgage"
                  value={rentMortgage || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-water-electricity-gas' })}
                  name="waterElectricityGas"
                  value={waterElectricityGas || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-support-parents' })}
                  name="supportParents"
                  value={supportParents || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-children' })}
                  name="children"
                  value={children || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-shopping' })}
                  name="shopping"
                  value={shopping || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-pets' })}
                  name="pets"
                  value={pets || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-domestic-helpers' })}
                  name="domesticHelpers"
                  value={domesticHelpers || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>

              <Grid item xs={6}>
                <ThisNumberFormatTextField
                  label={intl.formatMessage({ id: 'ec-other-household-expenses' })}
                  name="otherHouseholdExpenses"
                  value={otherHouseholdExpenses || ''}
                  onChange={onChange}
                  InputLabelProps={InputLabelProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ margin: '0 4px' }}>
            <CategoryHeader
              icon={<ShoppingCartRoundedIcon />}
              label={intl.formatMessage({ id: 'ec-cat-other-expenses' })}
              color={OTHER_EXPENSES_CATEGORY_COLOR}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-entertainment' })}
              name="entertainment"
              value={entertainment || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-digital' })}
              name="digital"
              value={digital || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-membership' })}
              name="membership"
              value={membership || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-mpf' })}
              name="mpf"
              value={mpf || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-insurance' })}
              name="insurance"
              value={insurance || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-tax' })}
              name="tax"
              value={tax || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-private-car' })}
              name="privateCar"
              value={privateCar || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-repayment' })}
              name="repayment"
              value={repayment || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-tuition' })}
              name="tuition"
              value={tuition || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>

          <Grid item xs={6}>
            <ThisNumberFormatTextField
              label={intl.formatMessage({ id: 'ec-other-miscellaneous-expenses' })}
              name="otherMiscellaneousExpenses"
              value={otherMiscellaneousExpenses || ''}
              onChange={onChange}
              InputLabelProps={InputLabelProps}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ExpenseCalculator = props => {
  const { layout } = props;

  const isRowLayout = layout === 'row';

  return isRowLayout ? <RowExpenseCalculator {...props} /> : <ColumnExpenseCalculator {...props} />;
};

export default injectIntl(ExpenseCalculator);
