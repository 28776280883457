import React from 'react';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { ADVISER_FAQ_URL } from '../../../../constants/medical-card';
import { sanitize } from 'dompurify';

const ClinicInformationDialog = props => {
  const { intl, open, onClose, ...rest } = props;

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'med-card-clinic-info' })}</DialogTitle>
      <DialogContent>
        <Typography
          paragraph={true}
          dangerouslySetInnerHTML={{
            __html: sanitize(intl.formatMessage({ id: 'med-card-adv-clinic-dialog-content' }))
          }}
        />

        <Typography
          dangerouslySetInnerHTML={{
            __html: sanitize(
              intl.formatMessage(
                {
                  id: 'med-card-adv-help'
                },
                {
                  link: ADVISER_FAQ_URL,
                  link2: `https://api.whatsapp.com/send?phone=85266370320&text=${encodeURIComponent(
                    `想了解更多門診卡`
                  )}`
                }
              )
            )
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ClinicInformationDialog);
