import React from 'react';
import './NotificationBellButton.scss';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';

export default class NotificationBellButton extends React.PureComponent {
  render() {
    const { hasNotifications, newNotificationNumber } = this.props;
    return (
      <Badge
        color="error"
        // variant="dot"
        className="Badge"
        badgeContent={newNotificationNumber}
        invisible={hasNotifications ? false : true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {/* <img
          src="img/notification-center/notification.png"
          className="bellButton"
          style={{ display: hasNotifications ? 'block' : 'none' }}
          alt="hasNotification"
        /> */}
        <NotificationsIcon className="bellButton" alt="notNotification" />
      </Badge>
    );
  }
}
