export const shouldShowTeamPlan = teams => {
  if (teams && Array.isArray(teams) && teams.length > 0) {
    const isTeamLogoEnabledTeam = (teams || []).find(team => !!(team?.members || []).find(member => member?.permission?.isUseTeamLogo === true));
    if (isTeamLogoEnabledTeam) {
      return isTeamLogoEnabledTeam.isSingle !== true;
    }
  }

  return false;
};

export const isUserTeamMember = teams => (teams || []).length > 0;
