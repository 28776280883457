import { fetch } from '../../utils';
import Backend from '../../backend';

//API TO GET THE POLICY LIST OF USER
export const getPolicyList = userId => {
  return fetch(Backend.methodUrl('policyList') + '/' + userId, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO UPDATE THE REMINDER OF POLICY
export const updateReminder = data => {
  return fetch(Backend.methodUrl('updateReminder'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE NOTIFICATION OF REMINDER
export const getReminderBadgeCount = () => {
  return fetch(Backend.methodUrl('getReminderBadgeCount'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
