import React, { useState, useEffect } from 'react';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import TeamAnalysisView from './partials/teamAnalysis';
import MyTabs from '../../../components/MyTabs/MyTabs';
import TeamsView from './partials/team';
import MySwipeableViews from '../../../components/MySwipeableViews/MySwipeableViews';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import StickyHeader from '../../../components/StickyComponents/StickyHeader';
import TeamStructure from './TeamStructure';
import { getTeamStructure } from '../../../store/team/api';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import { fetchAdviserTeamDetail as fetchAdviserTeamDetailManual } from '../../../store/adviserDashboard/api';
import FollowUpDescriptionModal from '../../Advisor/Business/partials/ProgressComponent/Dialog/FollowUpDescriptionModal.js';
import { useTheme } from '@material-ui/core/styles';

const slideIndex = {
  Analysis: 0,
  Team: 1
};

const AdvisorTeamDashboardComponent = props => {
  const {
    userDetails,
    renderHeaderButton,
    fetchAdviserTeamDetail,
    fetchAdviserBusiness,
    teamDetailPhase,
    intl
  } = props;

  const [teamTrackingData, setTeamTrackingData] = useState(null);
  const [getAdviserTeamDetail, setGetAdviserTeamDetail] = useState(null);
  const [globalQuarterEndDate, setGlobalQuarterEndDate] = useState(null);
  const [ownTeamID, setOwnTeamID] = useState('');
  const [tab, setTab] = useState(slideIndex.Analysis);
  const [myselfData, setMyselfData] = useState(null);
  const [followUpDescriptionOpen, setFollowUpDescriptionOpen] = useState(false);
  const [followUpDescriptionNumber, setFollowUpDescriptionNumber] = useState(0);
  const [followUpDescriptionIsTeam, setFollowUpDescriptionIsTeam] = useState(true);
  const [activeTeamData, setActiveTeamData] = useState(null);
  const [adviserDetails, setAdviserDetails] = useState(getAdviserTeamDetail);
  const [teamStructure, setTeamStructure] = useState({});
  const [teamStructureTeamId, setTeamStructureTeamId] = useState('');
  const [teamStructureDialogOpen, setTeamStructureDialogOpen] = useState(false);
  const [teamStructureLoading, setTeamStructureLoading] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [previousSelectedTeams, setPreviousSelectedTeams] = useState([]);
  const [version, setVersion] = useState(Date());
  const theme = useTheme();

  const adjustActiveTeamData = (data, myselfData) => {
    if (!myselfData) {
      return data;
    }

    var adjustedData = data.map(value => {
      var tmp = JSON.parse(JSON.stringify(value));
      if (myselfData.currentProgressOption === 'showExpectedFrom') {
        tmp.highOpportunityCount -= myselfData.highOpportunityCount;
        tmp.lowOpportunityCount -= myselfData.lowOpportunityCount;
        tmp.mediumOpportunityCount -= myselfData.mediumOpportunityCount;
        tmp.noneOpportunityCount -= myselfData.noneOpportunityCount;
        tmp.sumOfActiveTrack -= myselfData.count;
        tmp.sumOfAdjustedBiz -= myselfData.adjustOpenBiz;
        tmp.sumOfClosedBiz -= myselfData.closedBiz;
        tmp.sumOfHighAdjustedBiz -= myselfData.highAdjustedBiz;
        tmp.sumOfLowAdjustedBiz -= myselfData.lowAdjustedBiz;
        tmp.sumOfMedAdjustedBiz -= myselfData.medAdjustedBiz;
        tmp.sumOfNoneAdjustedBiz -= myselfData.noneAdjustedBiz;
        tmp.sumOfQuarterTarget -= myselfData.quarterTarget;
      } else if (myselfData.currentProgressOption === 'showManualInput') {
        tmp.sumOfManualInput -= myselfData.currentProgress;
      }
      return tmp;
    });
    return adjustedData;
  };

  useEffect(() => {
    setTeamTrackingData(props.teamTrackingData);
    setGetAdviserTeamDetail(props.getAdviserTeamDetail);
    if (props.teamTrackingData?.length > 1) {
      openTeamStructureDialog();
    }
  }, [props.teamTrackingData, props.getAdviserTeamDetail]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [tab]);

  useEffect(() => {
    renderHeaderButton(true);
    fetchAdviserTeamDetail();
    fetchAdviserBusiness();
  }, []);

  useEffect(() => {
    if (teamStructureTeamId) {
      showTeamStructureTeam();
    } else {
      refreshData(teamTrackingData, getAdviserTeamDetail);
    }
  }, [teamTrackingData, getAdviserTeamDetail, teamStructureTeamId, ownTeamID, version]);

  function openFollowupDiscriptionDialog(isTeam, number) {
    setFollowUpDescriptionIsTeam(isTeam);
    setFollowUpDescriptionNumber(number);
    setFollowUpDescriptionOpen(true);
  }

  const autoFetchTeamStructure = async teamId => {
    if (teamId) {
      setTeamStructureLoading(true);
      let tempTeamStructure = await getTeamStructure(teamId);
      if (tempTeamStructure.success) {
        setTeamStructure(tempTeamStructure);
        setPreviousSelectedTeams([{ 2: tempTeamStructure.teamStructure }]);
      } else {
        toast.info(IconToast('error', intl.formatMessage({ id: 'Something went wrong' })), { className: 'new-toast' });
      }
      setTeamStructureLoading(false);
    }
  };

  const showTeamStructureTeam = async () => {
    setTeamStructureLoading(true);
    let data = await fetchAdviserTeamDetailManual(teamStructureTeamId);
    setTeamStructureLoading(false);
    if (data.success) {
      if (data.teamTrackingData.length === 0 && data.data.length === 0) {
        toast.info(IconToast('warn', intl.formatMessage({ id: 'no-member' })), { className: 'new-toast' });
      } else {
        refreshData(data.teamTrackingData, data.data, teamStructureTeamId);
        setTeamStructureDialogOpen(false);
        setPreviousSelectedTeams(selectedTeams);
      }
    } else {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Something went wrong' })), { className: 'new-toast' });
    }
  };

  function refreshData(teamTrackingData, getAdviserTeamDetail, teamStructureTeamId = null) {
    if (teamTrackingData && getAdviserTeamDetail && userDetails) {
      if (teamStructureTeamId) {
        var tempAdviserDetails = getAdviserTeamDetail.filter(value => {
          return value.teamId === teamStructureTeamId;
        });
        var tempMyselfData = getAdviserTeamDetail.find(value => {
          return value._id === userDetails._id;
        });
      } else if (ownTeamID) {
        var tempAdviserDetails = getAdviserTeamDetail.filter(value => {
          return value.teamId === ownTeamID;
        });
        var tempMyselfData = getAdviserTeamDetail.find(value => {
          return value._id === userDetails._id && value.teamId === ownTeamID;
        });
      } else {
        var tempAdviserDetails = [...getAdviserTeamDetail];
        var tempMyselfData = getAdviserTeamDetail.find(value => {
          return value._id === userDetails._id;
        });
        if (teamTrackingData?.length === 1) {
          setOwnTeamID(teamTrackingData[0].teamId);
          autoFetchTeamStructure(teamTrackingData[0].teamId);
        }
      }

      if (!globalQuarterEndDate && myselfData) {
        setGlobalQuarterEndDate(myselfData.quarterEndDate);
      }

      let tempAllTeamData = adjustActiveTeamData([...teamTrackingData], tempMyselfData);
      let tempAdjustedAllTeamData = [...teamTrackingData];

      if (tempAllTeamData.length === 1) {
        setActiveTeamData(tempAdjustedAllTeamData[0]);
      } else if (tempAllTeamData) {
        tempAdjustedAllTeamData = tempAdjustedAllTeamData.find(value => {
          return value.teamId === ownTeamID;
        });
        setActiveTeamData(tempAdjustedAllTeamData);
      } else {
        setActiveTeamData(null);
      }

      setMyselfData(tempMyselfData);
      setAdviserDetails(tempAdviserDetails);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const changeOwnTeam = event => {
    refreshData(teamTrackingData, getAdviserTeamDetail);
    setTeamStructureTeamId('');
    const teamData = teamTrackingData.find(value => value.teamId === event.target.value);
    setOwnTeamID(teamData.teamId);
    setSelectedTeams([]);
    autoFetchTeamStructure(teamData.teamId);
  };

  const openTeamStructureDialog = async () => {
    setTeamStructureDialogOpen(true);
  };

  const closeTeamStructureDialog = () => {
    setTeamStructureDialogOpen(false);
    setSelectedTeams(previousSelectedTeams);
  };

  let ready = teamTrackingData && Object.values(teamTrackingData).length >= 1 && !teamStructureLoading;
  let totalAdjustedTrackingBiz =
    activeTeamData?.sumOfHighAdjustedBiz * 0.75 +
    activeTeamData?.sumOfMedAdjustedBiz * 0.5 +
    activeTeamData?.sumOfLowAdjustedBiz * 0.25 +
    activeTeamData?.sumOfNoneAdjustedBiz * 0.1 +
    activeTeamData?.sumOfManualInput;

  return (
    <AppContentContainer>
      <TeamStructure
        intl={intl}
        open={teamStructureDialogOpen}
        onClose={closeTeamStructureDialog}
        refreshData={refreshData}
        teamStructure={teamStructure.teamStructure}
        setTeamStructureTeamId={setTeamStructureTeamId}
        loading={teamStructureLoading}
        setVersion={setVersion}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        changeOwnTeam={changeOwnTeam}
        teamTrackingData={teamTrackingData}
        ownTeamID={ownTeamID}
      />

      <FollowUpDescriptionModal
        open={followUpDescriptionOpen}
        onClose={() => setFollowUpDescriptionOpen(false)}
        okBtnOnClick={() => setFollowUpDescriptionOpen(false)}
        totalAdjustedTrackingBiz={followUpDescriptionNumber}
        isTeam={followUpDescriptionIsTeam}
      />

      <div>
        {teamDetailPhase && (
          <StickyHeader>
            <div className="select-team-panel">
              <div className="nav text-center" style={{ marginTop: 8 }}>
                {ready && ownTeamID && (
                  <span style={{ fontSize: '1.2em' }}>
                    {intl.formatMessage({ id: 'Team' })}:&nbsp;
                    <span style={{ color: theme.palette.primary.main }}>{activeTeamData?.teamName}</span>
                  </span>
                )}

                {/* 
                  // Show the select button if either condition met:
                  // 1. if the user has more than one teams and user hasn't selected their name
                  // 2. if the user has selected team and has more than one teams or subteams 
                */}
                {ready ? (
                  (!teamStructureDialogOpen || (teamStructureDialogOpen && ownTeamID)) &&
                  (teamStructure?.teamStructure?.subteams?.length > 0 || teamTrackingData?.length > 1) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openTeamStructureDialog}
                      style={{ marginLeft: 8, padding: '0px 6px', minWidth: 'unset' }}
                    >
                      {ownTeamID ? intl.formatMessage({ id: 'select' }) : intl.formatMessage({ id: 'select-team' })}
                    </Button>
                  )
                ) : (
                  <CircularProgress size={30} />
                )}
              </div>
            </div>

            <div style={{ visibility: ready && ownTeamID ? 'visible' : 'hidden' }}>
              <MyTabs
                value={tab}
                disabled={window.innerWidth > 600}
                onChange={handleTabChange}
                tabLabels={[intl.formatMessage({ id: 'business-progress' }), intl.formatMessage({ id: 'Team Member' })]}
              />
            </div>
          </StickyHeader>
        )}
        <div className="advisor_team-page">
          <div className={'advisor_team-wrapper' + (tab === 0 ? ' team-wrapper' : '')}>
            {activeTeamData && (
              <div>
                {window.innerWidth > 600 ? (
                  <Grid container>
                    <Grid item xs={6}>
                      <TeamAnalysisView
                        {...props}
                        activeTeamData={activeTeamData}
                        adviserDetails={adviserDetails}
                        myselfData={myselfData}
                        setGlobalQuarterEndDate={setGlobalQuarterEndDate}
                        openFollowupDiscriptionDialog={() =>
                          openFollowupDiscriptionDialog(true, totalAdjustedTrackingBiz)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TeamsView
                        {...props}
                        activeTeamData={activeTeamData}
                        adviserDetails={adviserDetails}
                        myselfData={myselfData}
                        globalQuarterEndDate={globalQuarterEndDate}
                        openFollowupDiscriptionDialog={openFollowupDiscriptionDialog}
                        selectedTeams={selectedTeams}
                        setSelectedTeams={setSelectedTeams}
                        setVersion={setVersion}
                        setTeamStructureTeamId={setTeamStructureTeamId}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <MySwipeableViews
                    index={tab}
                    onChangeIndex={index => {
                      setTab(index);
                    }}
                  >
                    <TeamAnalysisView
                      {...props}
                      activeTeamData={activeTeamData}
                      adviserDetails={adviserDetails}
                      myselfData={myselfData}
                      setGlobalQuarterEndDate={setGlobalQuarterEndDate}
                      openFollowupDiscriptionDialog={() =>
                        openFollowupDiscriptionDialog(true, totalAdjustedTrackingBiz)
                      }
                    />
                    <TeamsView
                      {...props}
                      activeTeamData={activeTeamData}
                      adviserDetails={adviserDetails}
                      myselfData={myselfData}
                      globalQuarterEndDate={globalQuarterEndDate}
                      openFollowupDiscriptionDialog={openFollowupDiscriptionDialog}
                      selectedTeams={selectedTeams}
                      setSelectedTeams={setSelectedTeams}
                      setVersion={setVersion}
                      setTeamStructureTeamId={setTeamStructureTeamId}
                    />
                  </MySwipeableViews>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContentContainer>
  );
};

export default AdvisorTeamDashboardComponent;
