import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Grid, Typography } from '@material-ui/core';
import { decimalToString, getIntegerOrFixed } from '../../../../../utils/formatNumber';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const getOptions = (percentage, colors, axisLabelFormatter) => ({
  title: {
    show: false
  },
  animationDuration: 1200,
  animationDurationUpdate: 1200,
  grid: {
    show: false,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  // tooltip: {
  //   formatter: '{b}: {c}'
  // },
  legend: {
    show: false
  },
  toolbox: {},
  series: [
    {
      type: 'gauge',
      startAngle: 180,
      endAngle: 0,
      radius: '100%',
      innerSize: '8%',
      innerRadius: 0.9,
      data: [
        {
          value: percentage
        }
      ],
      axisLine: {
        // 坐标轴线
        show: true, // 默认显示，属性show控制显示与否
        lineStyle: {
          // 属性lineStyle控制线条样式
          color: colors,
          // [
          // 	[
          // 		1,
          // 		new echarts.graphic.LinearGradient(
          // 			0,
          // 			0,
          // 			1,
          // 			0,
          // 			colors.map(colorArray => ({ offset: colorArray[0], color: colorArray[1] }))
          // 		)
          // 	]
          // ],
          width: 10
        }
      },
      axisTick: {
        // 坐标轴小标记
        show: false // 属性show控制显示与否，默认不显示
      },
      axisLabel: {
        // 坐标轴文本标签，详见axis.axisLabel
        show: true,
        distance: -15,
        formatter: axisLabelFormatter,
        color: '#9b9c99',
        fontSize: 9,
        shadowBlur: 0
      },
      splitLine: {
        // 分隔线
        show: false // 默认显示，属性show控制显示与否
      },
      pointer: {
        length: '45%',
        width: 3
      },
      itemStyle: {
        color: '#3966f8',
        shadowBlur: 0
      },
      title: {
        show: false
      },
      detail: {
        show: false,
        backgroundColor: 'rgba(0,0,0,0)',
        borderWidth: 0,
        borderColor: '#ccc',
        width: 0,
        height: 0,
        offsetCenter: [0, 0], // x, y，单位px
        // formatter: '{value}/100',
        textStyle: {
          // 其余属性默认使用全局文本样式，详见TEXTSTYLE
          color: 'auto',
          fontSize: 18
        }
      },
      tooltips: {
        formatter: '{a} <br/>{b} : {c}%'
      }
    }
  ]
});

const GaugeChart = props => {
  const {
    value,
    max,
    percentage,
    maxPercentage,
    insufficientPercentage,
    label,
    colors,
    axisLabelFormatter,
    className,
    width,
    ...rest
  } = props;

  const chartPercentage = max ? (value / max) * 100 : 0;

  return (
    <Grid container justify="center" spacing={0} style={{ width: width, margin: '0 auto' }} className={className}>
      <Grid item xs={12}>
        <div style={{ position: 'relative', height: 100 }}>
          <div
            style={{
              position: 'absolute',
              height: 150,
              width: '100%',
              maxWidth: 175,
              left: '50%',
              transform: 'translate(-50%, -35%)'
            }}
          >
            <ReactEcharts option={getOptions(chartPercentage, colors, axisLabelFormatter)} {...rest} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" align="center" style={{ fontWeight: 500, fontSize: '115%' }}>
          {percentage <= maxPercentage ? `${getIntegerOrFixed(percentage, 2)}%` : `>${maxPercentage}%`}
        </Typography>
        <Grid container justify="center" alignItems="center">
          {insufficientPercentage && percentage < insufficientPercentage && (
            <Grid item>
              <WarningRoundedIcon color="error" style={{ verticalAlign: 'middle' }} />
            </Grid>
          )}
          <Grid item>
            <Typography color="textSecondary" align="center">
              {label}
            </Typography>
          </Grid>
        </Grid>
        <Typography align="center">{decimalToString(value)}</Typography>
      </Grid>
    </Grid>
  );
};

export default GaugeChart;
