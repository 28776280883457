import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import './LanguageRadioGroup.scss';
import { LANGUAGE } from '../../constants/language';

const OPTIONS = [
  {
    value: LANGUAGE.EN,
    label: 'ENG'
  },
  {
    value: LANGUAGE.ZH_HANT_HK,
    label: '繁中'
  },
  {
    value: LANGUAGE.ZH,
    label: '简中'
  }
];

const LanguageRadioGroup = props => {
  const { className, options, ...rest } = props;

  const _options = options ? OPTIONS.filter(option => options.includes(option.value)) : OPTIONS;

  return (
    <RadioGroup className={`lang-radio-group ${className ? className : ''}`} {...rest}>
      {_options.map(option => (
        <FormControlLabel
          key={option.value}
          control={<Radio color="primary" />}
          value={option.value}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

export default LanguageRadioGroup;
