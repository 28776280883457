import React from 'react';
import '../ClientDashboardDrawer.scss';
import { Bar } from 'react-chartjs-2';
import { formatNumber } from '../../../utils/formatNumber';
import { getUserCurrency } from '../../../utils/user';
import { getPremiumPeriodValue, isPaidUpPolicy } from '../../../utils/policy';

const MonthlyPremiumChart = props => {
  const { intl, finalPortfolio, userDetails } = props;

  const selectedCurrency = getUserCurrency(userDetails);
  var selectedCategories = [
    {
      title: 'Life',
      subCategoryId: '59b0ed2cdae1831be084f804',
      monthlyPremium: 0
    },
    {
      title: 'C.I.',
      subCategoryId: '59b0ed6fdae1831be084f805',
      monthlyPremium: 0
    },
    {
      title: 'Med',
      subCategoryId: '59b0ed8fdae1831be084f806',
      monthlyPremium: 0
    },
    {
      title: 'Saving',
      subCategoryId: '5baf2af899d85c2d38c8f00f',
      monthlyPremium: 0
    },
    {
      title: 'ILAS',
      subCategoryId: '5baf2af899d85c2d38c8f00f',
      monthlyPremium: 0
    }
  ];
  var totalMonthlyPremium = 0;
  selectedCategories.map(category => {
    const formattedPortfolio = finalPortfolio.filter(policy => {
      if (category.subCategoryId === '5baf2af899d85c2d38c8f00f') {
        if (category.title === 'Saving') {
          return category.subCategoryId === policy.subCategoryId && policy.guaranteeValue !== 'No';
        } else if (category.title === 'ILAS') {
          return category.subCategoryId === policy.subCategoryId && policy.guaranteeValue === 'No';
        }
      } else {
        return category.subCategoryId === policy.subCategoryId;
      }
    });
    formattedPortfolio.forEach(policy => {
      let monthlyPremium = Math.round(getPremiumPeriodValue(policy, 'Monthly', selectedCurrency));
      if (!isPaidUpPolicy(policy)) {
        category.monthlyPremium += monthlyPremium;
        totalMonthlyPremium += monthlyPremium;
      }
    });
  });

  var averagePremiumChartData = {
    labels: [
      intl.formatMessage({ id: 'Life' }),
      intl.formatMessage({ id: 'C.I.' }),
      intl.formatMessage({ id: 'Med' }),
      intl.formatMessage({ id: 'Saving' }),
      intl.formatMessage({ id: 'ILAS' })
    ],
    datasets: [
      {
        backgroundColor: ['#3eda7d', '#FF6A74', '#3966f8', '#8c1aff', '#BF80FF'],
        borderColor: ['#3eda7d', '#FF6A74', '#3966f8', '#8c1aff', '#BF80FF'],
        borderWidth: 0,
        maxBarThickness: 15,
        data: selectedCategories.map(category => {
          return Math.floor(category.monthlyPremium / 1) * 1;
        }),
        label: intl.formatMessage({ id: 'Premium' })
      }
    ]
  };
  let averageMonthlyChartOptions = {
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    responsive: true,
    legend: {
      position: 'bottom',
      display: false,
      labels: {
        fontSize: 10,
        usePointStyle: true,
        boxWidth: 11
      }
    },
    title: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: totalMonthlyPremium === 0 ? 1000 : undefined,
            callback: function(label) {
              return formatNumber(label, intl);
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += intl.formatMessage({ id: selectedCurrency }) + ' ';
          label += tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          label += ' (' + Math.round((tooltipItem.yLabel / totalMonthlyPremium) * 100) + '%)';
          return label;
        }
      }
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };

  return (
    <div className="cardAnalysis">
      <h4 className="text-center coverage-graph-title">{intl.formatMessage({ id: 'monthly-pay' })}</h4>
      <div className="Outer" style={{ marginTop: '1px' }}>
        <Bar data={averagePremiumChartData} options={averageMonthlyChartOptions} height={200} />
      </div>
    </div>
  );
};
export default MonthlyPremiumChart;
