import React from 'react';
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import { Fab } from '@material-ui/core';

const ButtonStyle = {
  position: 'fixed',
  bottom: 64,
  right: 16,
  width: 39,
  height: 39,
  zIndex: 10
};

const LikeFab = props => {
  const { style, ...rest } = props;

  return (
    <Fab color="primary" style={{ ...ButtonStyle, ...style }} {...rest}>
      <ThumbUpAltRoundedIcon />
    </Fab>
  );
};

export default LikeFab;
