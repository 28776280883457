import { fetch } from '../../utils';
import Backend from '../../backend';

export const getFamiliesApi = async () => {
    try {
      const res = await fetch(Backend.api(`getFamilies`));
      const payload = res.json();
      return payload;
    } catch (error) {
      throw error;
    }
  };
  
  export const addNewFamilyApi = async (family) => {
    try {
      const res = await fetch(Backend.api(`addNewFamily`), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ family })
      });
      const payload = res.json();
      return payload;
    } catch (error) {
      throw error;
    }
  };

  export const editFamilyNameApi = async (oldFamilyName, newFamilyName) => {
    try {
      const res = await fetch(Backend.api(`editFamilyName`), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ oldFamilyName, newFamilyName })
      });
      const payload = res.json();
      return payload;
    } catch (error) {
      throw error;
    }
  };
  
  export const deleteFamilyApi = async (family) => {
    try {
      const res = await fetch(Backend.api(`deleteFamily`), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ family })
      });
      const payload = res.json();
      return payload;
    } catch (error) {
      throw error;
    }
  };
  