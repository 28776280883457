import _ from 'lodash';
import { getAgeFromDob } from './date';
import {
  getCIToReachData,
  getLifeToReachData,
  getMedicalToReachData,
  getPeerScore,
  getSavingToReachData
} from './analysisCalculator';
import { InitialStateInterface as AnalysisInitialStateInterface } from '../store/analysis/duck';
import { getCIBetterThanKey, getLifeBetterThanKey, getTotalSavingCoverage } from './analysis';
import moment from "moment";

export const openWhatsappUrl = url => {
  let win = window.open(url, '_blank');
  if (win) win.focus();
};

export const getClientWhatsappUrl = (intl, clientDetails, defaultMessage, clientWhatsApp) => {
  let contactNo = clientWhatsApp
    ? clientWhatsApp
    : _.get(clientDetails, 'factsheetId.whatsapp')
    ? _.get(clientDetails, 'factsheetId.whatsapp', '')
    : _.get(clientDetails, 'whatsapp', '');

  let phoneNoText = contactNo ? 'phone=' + contactNo.replace('+', '') : '';

  let whatsAppMessage = intl.formatMessage({ id: 'Hi, we connected in PortfoPlus, feel free ask me anything.' });
  return (
    'https://api.whatsapp.com/send?' +
    phoneNoText +
    `&text=${
      defaultMessage !== undefined && defaultMessage !== '' && defaultMessage !== null
        ? defaultMessage
        : whatsAppMessage
    }`
  );
};

export const getRandomClient = clients =>
  clients && clients.length > 0 ? clients[Math.floor(Math.random() * clients.length)] : undefined;
// randomly get one client from high/medium potential clients (scoreLevel: 0 = high, 1 = medium, 2 = low, undefined = no result)
export const getRandomTargetClient = clients => {
  if (clients && clients.length > 0) {
    const filteredClients = clients.filter(client => client.scoreLevel === 0 || client.scoreLevel === 1);
    return getRandomClient(filteredClients);
  } else {
    return undefined;
  }
};

export const getLocalStorageClientId = () => {
  const clientId = localStorage.getItem('clientId');
  return clientId ? JSON.parse(clientId) : undefined;
};

export const getExpensesTotal = expenses => {
  return Object.entries(expenses || {}).reduce((total, [key, value]) => {
    return total + (value || 0);
  }, 0);
};

export const getClientAnalysisScores = (client, currency, figure) => {
  const { connect, inactiveUser, factsheetId, medicalPolicyCounts } = client || {};
  const { age, dob, monthlyExpense, monthlyIncome, debt } = factsheetId || {};

  const result = {};

  if (connect === true || inactiveUser === true) {
    // Set lifeCoverage = 0 if lifeCoverage is undefined (no policy)
    const lifeCoverage = _.get(client, 'lifeCoverage', 0);
    const ciCoverage = _.get(client, 'ciCoverage', 0);
    const savingTotalCoverage = getTotalSavingCoverage(
      _.get(client, 'totalPremiumSaving', 0) + _.get(client, 'totalWithILAS', 0),
      0
    );

    // Check whether the input parameters are valid or not
    // For life calculation, dob & monthlyExpense are compulsory
    if (age !== null && age !== undefined && (monthlyExpense || monthlyExpense === 0)) {
      // Set debt = 0 if debt in factsheet is invalid
      const formattedDebt = debt === null || debt === undefined || debt === '' || !/^\d+$/.test(debt) ? 0 : debt;

      const formattedAge = getAgeFromDob(dob, age);
      let lifeToReachData = getLifeToReachData(lifeCoverage, monthlyExpense, formattedDebt, formattedAge);

      result.lifeToReachGood = lifeToReachData.reachGood;
      result.lifeToReachExcellent = lifeToReachData.reachExcellent;
      result.lifeScore = lifeToReachData.score;
    }

    // For CI calculation, monthlyIncome are compulsory
    if (monthlyIncome || monthlyIncome === 0) {
      let ciToReachData = getCIToReachData(
        ciCoverage,
        monthlyIncome,
        AnalysisInitialStateInterface.ciClaimCoverExpenses,
        currency
      );

      result.ciToReachGood = ciToReachData.reachGood;
      result.ciToReachExcellent = ciToReachData.reachExcellent;
      result.ciScore = ciToReachData.score;
    }

    // For Saving calculation, monthlyIncome is compulsory
    if (monthlyIncome || monthlyIncome === 0) {
      const savingToReachData = getSavingToReachData(savingTotalCoverage, monthlyIncome);
      result.savingToReachMid = savingToReachData.reachMid;
      result.savingToReactHigh = savingToReachData.reachHigh;
      result.savingScore = savingToReachData.score;
    }

    // Medical calculation
    if (medicalPolicyCounts) {
      const medicalToReachData = getMedicalToReachData(medicalPolicyCounts);
      result.medicalScore = medicalToReachData.score;
    }

    if (figure) {
      const lifeBetterThanKey = getLifeBetterThanKey(figure, lifeCoverage);
      const ciBetterThanKey = getCIBetterThanKey(figure, ciCoverage);
      result.peerScore = getPeerScore(lifeBetterThanKey, ciBetterThanKey);
    }
  }

  return result;
};

export const getRemarkDateLine = () => `📅 ${moment().format('DD-MMM-YYYY')}\n`;
