import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Button,
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Portal,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import './MedicalCardPurchase.scss';
import { getMedicalCardCouponCodes } from '../../../store/medicalCardCouponCode/duck';
import _ from 'lodash';
import moment from 'moment-timezone';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import PhoneIcon from '@material-ui/icons/Phone';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import MyMedicalCardsSupportDialog from '../../MyProfile/MyMedicalCardsCard/MyMedicalCardsSupportDialog/MyMedicalCardsSupportDialog';
import { ADVISER_FAQ_URL } from '../../../constants/medical-card';
import ClinicInformationDialog from './ClinicInformationDialog/ClinicInformationDialog';
import { hasCampaignCode } from '../../../utils/user';
import RedeemIcon from '@material-ui/icons/Redeem';
import { MEDICAL_CARD_FREE_TRIAL_COUPON_CODE } from '../../../constants/medical-card-coupon-code';
import MedicalCardFreeTrialHintDialog from './MedicalCardFreeTrialHintDialog/MedicalCardFreeTrialHintDialog';
import {
  getMixCareBulkPurchaseLink,
  getMixCareDoctorListUrl,
  getMixCareRegistrationLink,
  getMixCareTermsLink
} from '../../../utils/mixcare';
import { toggleDialog } from '../../../store/control/duck';
import { sanitize } from 'dompurify';

const SHARE_LINK = 'https://w3.portfoplus.com/partner/adviser-exclusive-gift';
const TERMS_LINK = getMixCareTermsLink();

const BuyExpansionStyle = {
  boxShadow: 'none',
  border: '1px solid lightgrey',
  color: '#616161'
};

const StepTitleStyle = {
  fontSize: '110%',
  fontWeight: 'bold'
};

const MedicalCardPurchase = props => {
  const {
    intl,
    history,
    userDetails,
    medicalCardCouponCodes,
    triedFreeTrial,
    teamCampaignCodes,
    getMedicalCardCouponCodes,
    toggleDialog
  } = props;

  const [supportDialog, setSupportDialog] = useState(false);
  const [clinicInfoDialog, setClinicInfoDialog] = useState(false);
  const [freeTrialHintDialog, setFreeTrialHintDialog] = useState(false);

  const formattedMedicalCardCouponCodes = medicalCardCouponCodes || [];
  const toBeUsedMedicalCarCouponCode = _.get(formattedMedicalCardCouponCodes, '[0]', undefined);
  const totalQuota = formattedMedicalCardCouponCodes.reduce(
    (accumulator, couponCode) => accumulator + ((couponCode && couponCode.quota) || 0),
    0
  );
  const canTryFreeTrial = triedFreeTrial === false && hasCampaignCode(userDetails, teamCampaignCodes, 'FREEMC6MTH');

  const name = _.get(userDetails, 'name', '');
  const email = _.get(userDetails, 'email', '');
  const code = _.get(toBeUsedMedicalCarCouponCode, 'couponCode', '');
  const quota = totalQuota;
  const expiryDate = _.get(toBeUsedMedicalCarCouponCode, 'expiredAt');
  const isExpired = expiryDate && moment(expiryDate).isBefore(moment());
  const giftUserMsg = intl.formatMessage({ id: 'med-card-referral-share-msg' });
  const applyLink = getMixCareRegistrationLink({ coupon: code });
  const freeTrialLink = getMixCareRegistrationLink({ name: name, email: email, coupon: 'VHHOGFJYOB' });

  const back = () => history.goBack();
  const buy10 = () => {
    toggleDialog('thirdPartyRedirect', {
      title: intl.formatMessage({ id: 'redirect-to-partner-title' }),
      content: intl.formatMessage({ id: 'redirect-to-partner-content' }),
      url: getMixCareBulkPurchaseLink({ name, email, quota: 10 })
    });
  };
  const buy20 = () => {
    toggleDialog('thirdPartyRedirect', {
      title: intl.formatMessage({ id: 'redirect-to-partner-title' }),
      content: intl.formatMessage({ id: 'redirect-to-partner-content' }),
      url: getMixCareBulkPurchaseLink({ name, email, quota: 20 })
    });
  };

  const validCheck = () => {
    if (code && quota && !isExpired) {
      return true;
    } else {
      toast.info(IconToast('error', intl.formatMessage({ id: 'med-card-buy-buy-first' })), { className: 'new-toast' });
      return false;
    }
  };

  const apply = () => {
    if (validCheck()) {
      toggleDialog('thirdPartyRedirect', {
        title: intl.formatMessage({ id: 'redirect-to-partner-title' }),
        content: intl.formatMessage({ id: 'redirect-to-partner-content' }),
        url: applyLink
      });
      // window.open(applyLink, '_blank');
    }
  };

  const qr = () => {
    if (validCheck()) {
      history.push('/medical-card-qr-code', { applyLink: applyLink });
    }
  };

  const clinic = () => {
    if (quota) {
      window.open(getMixCareDoctorListUrl(), '_blank');
    } else {
      setClinicInfoDialog(true);
    }
  };
  const terms = () => {
    window.open('https://w3.portfoplus.com/partner/medical-card-details/', '_blank');
  };
  const support = () => {
    setSupportDialog(true);
  };
  const closeSupportDialog = () => setSupportDialog(false);
  const closeClinicInfoDialog = () => setClinicInfoDialog(false);

  const openFreeTrialHintDialog = () => setFreeTrialHintDialog(true);
  const closeFreeTrialHintDialog = () => setFreeTrialHintDialog(false);
  const tryFreeTrial = () => {
    toggleDialog('thirdPartyRedirect', {
      title: intl.formatMessage({ id: 'redirect-to-partner-title' }),
      content: intl.formatMessage({ id: 'redirect-to-partner-content' }),
      url: freeTrialLink
    });
    // window.open(freeTrialLink, '_blank');
    closeFreeTrialHintDialog();
  };

  useEffect(() => {
    getMedicalCardCouponCodes();
  }, [getMedicalCardCouponCodes]);

  return (
    <div className="medical-card-purchase refer-adviser">
      <MyMedicalCardsSupportDialog
        open={supportDialog}
        contentPrepend={
          <Typography
            paragraph={true}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                intl.formatMessage(
                  {
                    id: 'med-card-adv-help'
                  },
                  {
                    link: ADVISER_FAQ_URL,
                    link2: `https://api.whatsapp.com/send?phone=85266370320&text=${encodeURIComponent(
                      `想了解更多門診卡`
                    )}`
                  }
                )
              )
            }}
          />
        }
        onClose={closeSupportDialog}
      />

      <ClinicInformationDialog open={clinicInfoDialog} onClose={closeClinicInfoDialog} />

      <MedicalCardFreeTrialHintDialog
        open={freeTrialHintDialog}
        onClose={closeFreeTrialHintDialog}
        onSubmit={tryFreeTrial}
      />

      <Container className="container" maxWidth="sm">
        <section>
          <VideoPlayer
            url="https://youtu.be/WI8B1h3MVUE"
            containerProps={
              supportDialog || clinicInfoDialog
                ? {
                    style: {
                      pointerEvents: 'none'
                    }
                  }
                : undefined
            }
          />
        </section>

        <section>
          <Typography style={{ whiteSpace: 'pre-wrap', fontSize: '150%', fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'med-card-buy-desc' })}
          </Typography>
        </section>

        <section>
          <ExpansionPanel style={BuyExpansionStyle}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={StepTitleStyle}>{intl.formatMessage({ id: 'med-card-buy-step-2' })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    align="left"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(intl.formatMessage({ id: 'med-card-buy-step-2-hint' }))
                    }}
                  />
                </Grid>
                <Grid item>
                  <ShareButtonGroup
                    title={giftUserMsg}
                    url={SHARE_LINK}
                    CopyTextFieldProps={{
                      multiline: true
                    }}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </section>

        <section>
          <ExpansionPanel style={BuyExpansionStyle}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={StepTitleStyle}>{intl.formatMessage({ id: 'med-card-buy-step-1' })}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: 8 }}>
              <List disablePadding={true} style={{ width: '100%' }}>
                <ListSubheader>
                  <Typography align="left">{intl.formatMessage({ id: 'med-card-buy-step-1-hint' })}</Typography>
                </ListSubheader>
                <ListItem divider={true}>
                  <ListItemText
                    primary={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitize(intl.formatMessage({ id: 'med-card-buy-10-title' }))
                        }}
                      />
                    }
                    secondary={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitize(intl.formatMessage({ id: 'med-card-buy-10-price' }))
                        }}
                      />
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" startIcon={<ShoppingCartIcon />} onClick={buy10}>
                      {intl.formatMessage({ id: 'med-card-buy-cta' })}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider={true}>
                  <ListItemText
                    primary={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitize(intl.formatMessage({ id: 'med-card-buy-20-title' }))
                        }}
                      />
                    }
                    secondary={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitize(intl.formatMessage({ id: 'med-card-buy-20-price' }))
                        }}
                      />
                    }
                  />
                  <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" startIcon={<ShoppingCartIcon />} onClick={buy20}>
                      {intl.formatMessage({ id: 'med-card-buy-cta' })}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </section>

        <section>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="left"
                gutterBottom={true}
                dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-buy-count' })) }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="flex-end" justify="flex-start">
                <Grid item>
                  <Typography
                    align="left"
                    color="primary"
                    style={{
                      fontSize: '250%',
                      fontWeight: 'bold',
                      lineHeight: 1,
                      color: isExpired ? 'lightgrey' : undefined
                    }}
                  >
                    {quota}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="flex-start" justify="space-between">
                <Grid item>
                  <Grid container direction="column" alignItems="flex-start">
                    <Grid item>
                      <Button startIcon={<PhoneIcon />} onClick={support}>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'support' })}</Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Typography color="textSecondary">
                        {intl.formatMessage(
                          { id: 'med-card-buy-expiry-date' },
                          { date: expiryDate ? moment(expiryDate).format('DD-MMM-YYYY') : ' - ' }
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Button startIcon={<ZoomInIcon />} onClick={terms}>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'med-card-tc' })}</Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button startIcon={<ZoomInIcon />} onClick={clinic}>
                        <Typography color="textSecondary">
                          {intl.formatMessage({ id: 'med-card-clinic-info' })}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {canTryFreeTrial && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  startIcon={<RedeemIcon />}
                  onClick={openFreeTrialHintDialog}
                >
                  {intl.formatMessage({ id: 'med-card-free-trial' })}
                </Button>
              </Grid>
            )}
          </Grid>
        </section>

        <hr />

        <section>
          <Typography align="left" style={StepTitleStyle}>
            {intl.formatMessage({ id: 'med-card-buy-step-3' })}
          </Typography>
          <Typography
            align="left"
            color="textSecondary"
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.formatMessage({ id: 'med-card-buy-step-3-hint' }, { link: TERMS_LINK }))
            }}
          />
        </section>

        <section>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Button variant="contained" color="primary" fullWidth={true} onClick={apply}>
                {intl.formatMessage({ id: 'med-card-buy-apply' })}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" color="primary" fullWidth={true} onClick={qr}>
                {intl.formatMessage({ id: 'med-card-buy-qr' })}
              </Button>
            </Grid>
          </Grid>
        </section>
      </Container>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    medicalCardCouponCodes: state.medicalCardCouponCode.medicalCardCouponCodes,
    triedFreeTrial: state.medicalCardCouponCode.triedFreeTrial,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {
    getMedicalCardCouponCodes: getMedicalCardCouponCodes,
    toggleDialog
  }
)(MedicalCardPurchase);

export default injectIntl(container);
