import { fetch } from '../../utils';
import Backend from '../../backend';
import { handleJobResponse } from '../../utils/job';

// export const getSpeechToken = () => {
//   return fetch(Backend.methodUrl('speech/token'))
//     .then(res => {
//       return res.json();
//     })
//     .then(payload => {
//       return payload;
//     })
//     .catch(error => {
//       throw error;
//     });
// };

export const speechToText = async ({ mimeType, msDuration, recordDataBase64 }, options) => {
  const { pollTimeout } = options || {};
  const response = await handleJobResponse(
    fetch(Backend.methodUrl('speech'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        mimeType,
        msDuration,
        recordDataBase64
      })
    }),
    {
      pollTimeout: pollTimeout || 7000
    }
  );

  if (response.error) {
    throw new Error(response.error);
  }

  const { completed, failed } = response;

  const completedTask = completed?.[0];
  const failedTask = failed?.[0];

  if (failedTask) {
    throw new Error(failed.message);
  }

  return completedTask?.extras;
};
