import createStyles from 'draft-js-custom-styles';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import { composeDecorators } from 'draft-js-plugins-editor';
import createImagePlugin from './draft-js-image-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createAlignmentPlugin from './draft-js-alignment-plugin';

const createdStyleObject = createStyles(['font-size', 'color', 'text-transform'], 'CUSTOM_');
export const customStyles = createdStyleObject.styles;
export const customStyleFn = createdStyleObject.customStyleFn;

export const getEditorPlugins = ({ toolbar, focus, alignment, image }) => {
  const plugins = {};

  if (toolbar) {
    plugins.toolbar = createToolbarPlugin();
  }

  if (focus || image) {
    plugins.focus = createFocusPlugin();
  }

  if (alignment || image) {
    plugins.alignment = createAlignmentPlugin();
  }

  if (image) {
    const decorator = composeDecorators(plugins.alignment.decorator, plugins.focus.decorator);
    plugins.image = createImagePlugin({ decorator });
  }

  return plugins;
};

export const ALIGN_BLOCK_TYPES = {
  ALIGN_LEFT: 'align-left',
  ALIGN_CENTER: 'align-center',
  ALIGN_RIGHT: 'align-right'
};

export const SELECTABLE_COLORS = [
  '#61BD6D',
  '#1ABC9C',
  '#54ACD2',
  '#2C82C9',
  '#9365B8',
  '#475577',
  '#CCCCCC',
  '#41A85F',
  '#00A885',
  '#3D8EB9',
  '#2969B0',
  '#553982',
  '#28324E',
  '#000000',
  '#F7DA64',
  '#FBA026',
  '#EB6B56',
  '#E25041',
  '#A38F84',
  '#EFEFEF',
  '#FFFFFF',
  '#FAC51C',
  '#F37934',
  '#D14841',
  '#B8312F',
  '#7C706B',
  '#D1D5D8'
];
