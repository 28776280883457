import { Chart } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation-fix';
import _ from 'lodash';
// import * as ChartAnnotation from '@giliweb/chartjs-plugin-annotation';
const defaultFontFamily = 'Roboto, Noto Sans TC, Noto Sans SC, sans-serif';

export const initChartJs = () => {
  Chart.defaults.global.defaultFontFamily = defaultFontFamily;

  let caPlugin = ChartAnnotation;
  caPlugin['id'] = 'annotation';
  Chart.pluginService.register(caPlugin);

  // register custom drawing for doughnut chart (center label)
  let originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function() {
      originalDoughnutDraw.apply(this, arguments);

      let chart = this.chart.chart;
      if (chart.config.data.texts !== undefined) {
        let texts = chart.config.data.texts;

        let ctx = chart.ctx;
        let width = chart.width;
        let height = chart.outerRadius * 2;
        let fontSize = (height * 0.1).toFixed(2);
        // let totalPadding = texts.reduce((acc, textObj) => acc + (textObj.topMargin !== undefined ? textObj.topMargin : 0), 0);

        const getSeparator = textWrap => (textWrap === 'character' ? '' : ' ');
        const splitWord = (text, textWrap) => text.split(getSeparator(textWrap));

        const calculateExtraPadding = (context, text, maxWidth, font, textWrap) => {
          const separator = getSeparator(textWrap);
          ctx.font = font;
          let y = 0;
          let words = splitWord(text);
          let line = '';

          for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + separator;
            // console.log('round', n, testLine);
            let metrics = context.measureText(testLine.trim());

            // initial height
            if (n === 0) {
              y += metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            }

            let testWidth = metrics.width;
            if (testWidth > maxWidth) {
              // console.log('testWidth > maxWidth && n > 0', testWidth, maxWidth);
              line = words[n] + separator;
              y += metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
              // console.log('y', y);
            } else {
              // console.log('else');
              line = testLine;
              y = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            }
          }
          // console.log('y', y);
          return y;
        };

        const wrapText = (context, subtexts, width, y, maxWidth, fontWeight, fontSizeFactor, textWrap) => {
          // console.log(context, text, width, y, maxWidth, lineHeight);
          const separator = getSeparator(textWrap);
          let x;

          const words = subtexts.reduce((accumulator, subtext) => {
            return accumulator.concat(
              splitWord(subtext.text).map(word => ({
                word: word,
                color: subtext.color
              }))
            );
          }, []);

          context.font = fontWeight + ' ' + Math.round(fontSize * fontSizeFactor) + 'px ' + defaultFontFamily;
          context.textBaseline = 'middle';

          let fillingWords = [];
          let metrics;

          const fillWords = () => {
            const line = fillingWords.map(fillingWord => fillingWord.word).join(separator);
            metrics = context.measureText(line.trim());
            x = Math.round((width - metrics.width) / 2);
            y += metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

            fillingWords.forEach((fillingWord, index) => {
              const fillLine = `${fillingWord.word}${index < fillingWords.length - 1 ? separator : ''}`;
              context.fillStyle = fillingWord.color;
              const fillingWordWidth = context.measureText(fillLine).width;
              context.fillText(fillLine, x, y);
              x += fillingWordWidth;
              fillingWords = [];
            });
          };

          words.forEach((wordObj, index) => {
            const line = fillingWords.map(fillingWord => fillingWord.word).join(separator);
            const testLine = line + wordObj.word + separator;
            metrics = context.measureText(testLine);

            // // initial height
            // if (index === 0) {
            // 	y += metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            // }

            let testWidth = metrics.width;
            if (testWidth > maxWidth && index > 0) {
              fillWords(fillingWords);
            } else {
              fillingWords.push(wordObj);
            }
          });

          if (fillingWords.length > 0) {
            fillWords(fillingWords);
          }

          return y;
        };

        // console.log(totalPadding);
        let maxWidth = chart.innerRadius * 2 * 0.9;
        let extraPadding = texts.reduce((acc, textObj) => {
          return (
            acc +
            calculateExtraPadding(
              ctx,
              textObj.subtexts.map(subtext => subtext.text).join(''),
              maxWidth,
              textObj.fontWeight + ' ' + Math.round(fontSize * textObj.fontSizeFactor) + 'px ' + defaultFontFamily,
              textObj.textWrap
            )
          );
        }, 0);

        let textY = (height - extraPadding) / 2 + (height / 2 - chart.outerRadius);

        const originalCtxSetting = { ..._.pick(ctx, ['font', 'textBaseline', 'fillStyle']) };

        texts.forEach(textObj => {
          let topMargin = textObj.topMargin !== undefined ? textObj.topMargin : 0;
          textY = textY + topMargin;
          textY = wrapText(
            ctx,
            textObj.subtexts,
            width,
            textY,
            maxWidth,
            textObj.fontWeight,
            textObj.fontSizeFactor,
            textObj.textWrap
          );
        });

        ctx.font = originalCtxSetting.font;
        ctx.textBaseline = originalCtxSetting.textBaseline;
        ctx.fillStyle = originalCtxSetting.fillStyle;
      }
    }
  });

  // Chart.pluginService.register({
  // 	// showAllTooltips
  // 	beforeRender: function(chart) {
  // 		if (chart.config.options.showAllTooltips) {
  // 			// create an array of tooltips
  // 			// we can't use the chart tooltip because there is only one tooltip per chart
  // 			chart.pluginTooltips = [];
  // 			chart.config.data.datasets.forEach(function(dataset, i) {
  // 				chart.getDatasetMeta(i).data.forEach(function(sector, j) {
  // 					if (dataset.data[j].showTooltip) {
  // 						chart.pluginTooltips.push(
  // 							new Chart.Tooltip(
  // 								{
  // 									_chart: chart.chart,
  // 									_chartInstance: chart,
  // 									_data: chart.data,
  // 									_options: chart.options.tooltips,
  // 									_active: [sector],
  // 								},
  // 								chart,
  // 							),
  // 						);
  // 					}
  // 				});
  // 			});
  //
  // 			// turn off normal tooltips
  // 			chart.options.tooltips.enabled = false;
  // 		}
  // 	},
  // 	afterDraw: function(chart, easing) {
  // 		if (chart.config.options.showAllTooltips) {
  // 			// we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
  // 			if (!chart.allTooltipsOnce) {
  // 				if (easing !== 1) return;
  // 				chart.allTooltipsOnce = true;
  // 			}
  //
  // 			// turn on tooltips
  // 			chart.options.tooltips.enabled = true;
  // 			Chart.helpers.each(chart.pluginTooltips, function(tooltip) {
  // 				tooltip.initialize();
  // 				tooltip.update();
  // 				// we don't actually need this since we are not animating tooltips
  // 				tooltip.pivot();
  // 				tooltip.transition(easing).draw();
  // 			});
  // 			chart.options.tooltips.enabled = false;
  // 		}
  // 	},
  // });
};
