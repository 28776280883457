import { Capacitor } from '@capacitor/core';
import { CapacitorPassToWallet } from 'capacitor-pass-to-wallet';
import { AppLauncher } from '@capacitor/app-launcher';

export const openWalletApp = async () => {
  try {
    const platform = Capacitor.getPlatform();
    if (platform === 'ios') {
      await AppLauncher.openUrl({ url: 'shoebox://' });
    } else if (platform === 'android') {
      await AppLauncher.openUrl({ url: 'com.google.android.apps.walletnfcrel' });
    } else {
    }
  } catch (error) {
    console.error(error);
  }
};

// only for iOS
export const addToWallet = async base64Data => {
  try {
    await CapacitorPassToWallet.addToWallet({ base64: base64Data });
  } catch (error) {
    if (error.message) {
      try {
        const errorObject = JSON.parse(error.message);
        if (errorObject.code === 100) {
          await openWalletApp();
        }
      } catch (error) {
        throw error;
      }
    } else {
      throw error;
    }
  }
};
