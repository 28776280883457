import React, { Fragment, useState } from 'react';
import { IconButton } from '@material-ui/core';
import TapAndPlayIcon from '@material-ui/icons/TapAndPlay';
import NFCAutoGenerateDialog from './NFCAutoGenerateDialog/NFCAutoGenerateDialog';

const NFCAutoGenerateButton = (props) => {
  const { style, ...rest } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const onClick = () => setDialogOpen(true);
  const onCloseDialog = () => setDialogOpen(false);

  return (
    <Fragment>
      <IconButton onClick={onClick} style={style} {...rest}>
        <TapAndPlayIcon />
      </IconButton>
      <NFCAutoGenerateDialog open={dialogOpen} onClose={onCloseDialog} />
    </Fragment>
  );
};

export default NFCAutoGenerateButton;
