import React from 'react';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { generateRandomCode } from '../utils/randomCode';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  tooltip: {
    fontSize: 12
  }
}

class AutoGenInput extends React.PureComponent {
  render() {
    const { classes, autoGenLabel, name, onChange, ...rest } = this.props;
    return (
      <TextField
        id="access-code"
        type="text"
        name={name}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={autoGenLabel} classes={{tooltip: classes.tooltip}} placement="left">
                <IconButton
                  aria-label={autoGenLabel}
                  onClick={
                    onChange ? () => onChange({ target: { value: generateRandomCode(8), name: name } }) : undefined
                  }
                >
                  <KeyboardIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(AutoGenInput);
