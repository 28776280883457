import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import { injectIntl } from 'react-intl';
import DefaultCloseButton from '../DefaultCloseButton/DefaultCloseButton';

const DoNotShowAgainTooltip = props => {
  const {
    intl,
    formattedStep,
    formattedSize,
    doNotShowAgain,
    onChangeDoNotShowAgain,
    hideCloseButton,

    continuous,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,
    index,
    isLastStep,
    step,
    size
  } = props;
  const { content, hideBackButton, locale, showProgress, showSkipButton, title, styles } = step;
  const { back, close, last, next, skip } = locale;
  const output = {
    primary: close
  };

  if (continuous) {
    output.primary = isLastStep ? last : next;

    if (showProgress) {
      output.primary = (
        <span>
          {output.primary} ({formattedStep !== undefined ? formattedStep : index + 1}/
          {formattedSize !== undefined ? formattedSize : size})
        </span>
      );
    }
  }

  if (showSkipButton && !isLastStep) {
    output.skip = (
      <button style={styles.buttonSkip} {...skipProps}>
        {skip}
      </button>
    );
  }

  if (!hideBackButton && index > 0) {
    output.back = (
      <button style={styles.buttonBack} {...backProps}>
        {back}
      </button>
    );
  }

  output.close = !hideCloseButton ? <DefaultCloseButton {...closeProps} styles={styles.buttonClose} /> : null;
  _.set(styles, 'tooltipFooter.justifyContent', 'space-between');

  return (
    <div className="joyride-tooltip-do-not-show-again" key="JoyrideTooltip" style={styles.tooltip} {...tooltipProps}>
      <div style={styles.tooltipContainer}>
        {output.close}
        {title && <h4 style={styles.tooltipTitle}>{title}</h4>}
        {content && <div style={styles.tooltipContent}>{content}</div>}
      </div>
      <div style={styles.tooltipFooter}>
        <div className="do-not-show-again">
          <FormControlLabel
            control={<Checkbox checked={doNotShowAgain} onChange={onChangeDoNotShowAgain} />}
            label={intl.formatMessage({ id: 'dun-show-again' })}
          />
        </div>
        <div>
          {output.skip}
          {output.back}
          <button style={styles.buttonNext} {...primaryProps}>
            {output.primary}
          </button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DoNotShowAgainTooltip);
