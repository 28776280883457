export const defaultFilters = [
  {
    name: null,
    clientState: 'TrackOn',
    searchTerm: '',
    filterObject: {}
  },
  {
    name: null,
    clientState: 'hightolow',
    searchTerm: '',
    filterObject: {}
  },
  {
    name: null,
    clientState: 'all',
    searchTerm: '',
    filterObject: {}
  }
];
