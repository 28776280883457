import { ERROR, INIT, LOADING } from '../../constants/phase';
import produce from 'immer';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const GET_INSURERS_LOADING = 'GET_INSURERS_LOADING';
export const GET_INSURERS_SUCCESS = 'GET_INSURERS_SUCCESS';
export const GET_INSURERS_ERROR = 'GET_INSURERS_ERROR';
export const RESET_INSURERS = 'RESET_INSURERS';

/***********************************
 * Initial State
 ***********/
const initialState = {
  insurers: [],
  getPhase: INIT,
  error: undefined
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_INSURERS_LOADING:
        draft.getPhase = LOADING;
        break;
      case GET_INSURERS_SUCCESS:
        const { success, insurers } = action;
        draft.insurers = insurers;
        draft.getPhase = success;
        draft.error = undefined;
        break;
      case GET_INSURERS_ERROR:
        const { error } = action;
        draft.insurers = undefined;
        draft.getPhase = ERROR;
        draft.error = error;
        break;
      case RESET_INSURERS:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getInsurers = () => async (dispatch, getState) => {
  dispatch({ type: GET_INSURERS_LOADING });

  try {
    const response = await api.getInsurers();
    dispatch({ type: GET_INSURERS_SUCCESS, success: response.success, insurers: response });
  } catch (error) {
    dispatch({ type: GET_INSURERS_ERROR, error });
  }
};
