import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Card, Grid, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { getQuarterTargetData, getTimeLeftData } from '../../../utils/business';
import TimeAvailableChart from '../../Advisor/Business/partials/ProgressComponent/TimeAvailableChart';
import { formatOnlyNumber, formatOnlyUnit } from '../../../utils/formatNumber';
import EditIcon from '@material-ui/icons/Edit';
import BusinessTargetEditorModal from '../../Advisor/Dashboard/partials/business/setBusinessTargetModal/BusinessTargetModal';
import QuarterEndDateDialog from '../../Advisor/Business/partials/ProgressComponent/Dialog/QuarterEndDateDialog';

const CardStyle = {
  position: 'relative'
};

const ChartGridStyle = {
  height: '125%',
  position: 'relative'
};

const ChartContainerStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '75%',
  left: 0,
  transform: 'translate(0, -50%)'
};

const FigureTypoStyle = {
  fontWeight: 'bold',
  fontSize: '160%'
};

const FigureUnitTypoStyle = {
  fontSize: '150%'
};

const EditButtonStyle = {
  position: 'absolute',
  top: -4,
  right: 3
};

const DashboardSection = props => {
  const { intl, adviserBusiness } = props;
  const [open, setOpen] = useState(false);
  const [dateDialog, setDateDialog] = useState(false);
  const isWide = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const { r1, QEnd, pastDays, chartData } = getTimeLeftData(adviserBusiness);
  const { quarterTarget, avgCaseSize, closingRate, weeklyAppt } = getQuarterTargetData(adviserBusiness);

  const edit = () => setOpen(true);
  const close = () => setOpen(false);
  const openDateDialog = () => setDateDialog(true);
  const closeDateDialog = () => setDateDialog(false);

  return (
    <Card style={CardStyle}>
      <BusinessTargetEditorModal open={open} onClose={close} updateSwipe={true} />
      <QuarterEndDateDialog open={dateDialog} onClose={closeDateDialog} />

      <Grid container justify="flex-start" alignItems="center" wrap="nowrap" style={{ height: isWide ? 100 : 68 }}>
        <Grid item xs={false} md={3} style={ChartGridStyle}>
          <div style={ChartContainerStyle}>
            <TimeAvailableChart r1={r1} QEnd={QEnd} chartData={chartData} style={{ height: '100%' }} />
          </div>
        </Grid>
        <Grid item xs={4} md={3} style={{ position: 'relative' }}>
          <IconButton color="primary" size="small" onClick={openDateDialog} style={EditButtonStyle}>
            <EditIcon style={{ fontSize: isWide ? 19 : 14 }} />
          </IconButton>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography
                color="textSecondary"
                display="inline"
                style={{ fontWeight: 'bold', fontSize: isWide ? '250%' : '160%' }}
              >
                {QEnd}&nbsp;
              </Typography>
              <Typography color="textSecondary" display="inline" style={FigureUnitTypoStyle}>
                {intl.formatMessage({ id: 'days' })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" display="inline">
                {intl.formatMessage({ id: 'adv-home-dash-qend-label' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} md={3}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography
                color="primary"
                display="inline"
                style={{ fontWeight: 'bold', fontSize: isWide ? '250%' : '160%' }}
              >
                {formatOnlyNumber(quarterTarget, intl)}&nbsp;
              </Typography>
              <Typography color="primary" display="inline" style={FigureUnitTypoStyle}>
                {quarterTarget ? formatOnlyUnit(quarterTarget, intl) : ''}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" display="inline">
                {intl.formatMessage({ id: 'Q-Target' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} md={3} style={{ position: 'relative' }}>
          <IconButton color="primary" size="small" onClick={edit} style={EditButtonStyle}>
            <EditIcon style={{ fontSize: isWide ? 19 : 14 }} />
          </IconButton>

          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography
                color="textSecondary"
                display="inline"
                style={{ fontWeight: 'bold', fontSize: isWide ? '250%' : '160%' }}
              >
                {weeklyAppt}&nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" display="inline">
                {intl.formatMessage({ id: 'Wkly-Appt' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const container = connect(
  state => ({
    adviserBusiness: state.adviserDashboard.adviserBusiness
  }),
  {}
)(DashboardSection);

export default injectIntl(container);
