import _ from 'lodash';
import { addThousandSeparators } from './formatNumber';
import moment from 'moment-timezone';
import { getSearchObject } from './router';
import { history } from '../views/App/history';
import { SIGN_UP_PLAN_MODE } from '../constants/subscription-plan';

class ShortSubscriptions {
  shortSubscriptions;

  constructor(shortSubscriptions) {
    this.shortSubscriptions = shortSubscriptions;
  }

  static isShortSubscriptionValid(shortSubscription) {
    const currentTime = new Date().getTime();
    return new Date(shortSubscription.expiredAt).getTime() > currentTime;
  }

  static isShortSubscriptionCoreValid(shortSubscription) {
    return shortSubscription.type === 'core' && ShortSubscriptions.isShortSubscriptionValid(shortSubscription);
  }

  static isShortSubscriptionTyped(shortSubscription, { type, nicknames }) {
    return (
      (!type || shortSubscription.type === type) &&
      (!nicknames || nicknames.find(text => text === shortSubscription.nickname)) &&
      ShortSubscriptions.isShortSubscriptionValid(shortSubscription)
    );
  }

  containValidCoreSubscription() {
    return this.shortSubscriptions
      ? this.shortSubscriptions.find(subscription => ShortSubscriptions.isShortSubscriptionCoreValid(subscription))
      : false;
  }

  containTypedSubscription({ type, nicknames }) {
    return this.shortSubscriptions
      ? this.shortSubscriptions.find(shortSubscription =>
          ShortSubscriptions.isShortSubscriptionTyped(shortSubscription, { type, nicknames })
        )
      : false;
  }

  isCoreSubscriptionFreeTrial() {
    if (this.shortSubscriptions) {
      const coreSubscription = this.shortSubscriptions.find(shortSubscription =>
        ShortSubscriptions.isShortSubscriptionCoreValid(shortSubscription)
      );
      return coreSubscription && coreSubscription.isFreeTrial;
    }
    return false;
  }

  isCoreSubscriptionTrialing() {
    if (this.shortSubscriptions) {
      const coreSubscription = this.shortSubscriptions.find(shortSubscription =>
        ShortSubscriptions.isShortSubscriptionCoreValid(shortSubscription)
      );
      return coreSubscription && coreSubscription.isTrialing;
    }
    return false;
  }
}

class UserSubscription {
  userSubscription;

  constructor(userSubscription) {
    this.userSubscription = userSubscription;
  }

  static isSubscriptionValid(subscription) {
    const currentTime = new Date().getTime();
    return (
      new Date(subscription.startedAt).getTime() <= currentTime &&
      new Date(subscription.expiredAt).getTime() > currentTime &&
      subscription.active
    );
  }

  static isSubscriptionCoreValid(subscription) {
    return subscription.subscriptionPlan.type === 'core' && UserSubscription.isSubscriptionValid(subscription);
  }

  static isSubscriptionPro(subscription) {
    return subscription.subscriptionPlan.nickname === 'Pro' && UserSubscription.isSubscriptionCoreValid(subscription);
  }

  static isSubscriptionFreeTrial(subscription) {
    return !!_.get(subscription, 'subscriptionPlan.isFreeTrial');
  }

  static isSubscriptionTrialing(subscription) {
    return _.get(subscription, 'subscriptionPlan.isTrial') === true && _.get(subscription, 'isTrialEnded') === false;
  }

  getUnpaidCoreSubscription(createType) {
    return this.userSubscription && this.userSubscription.subscriptions
      ? this.userSubscription.subscriptions.find(
          subscription =>
            subscription.subscriptionPlan.type === 'core' &&
            !subscription.paid &&
            (createType === undefined || subscription.createType === createType)
        )
      : undefined;
  }

  getSubscriptionsArray() {
    return this.userSubscription && this.userSubscription.subscriptions
      ? this.userSubscription.subscriptions
          .filter(subscription => {
            return UserSubscription.isSubscriptionCoreValid(subscription);
          })
          .map(subscription => {
            return {
              type: _.get(subscription, 'subscriptionPlan.type'),
              nickname: _.get(subscription, 'subscriptionPlan.nickname'),
              expiredAt: subscription.expiredAt,
              isFreeTrial: _.get(subscription, 'subscriptionPlan.isFreeTrial', false),
              isTrialing: UserSubscription.isSubscriptionTrialing(subscription)
            };
          })
      : [];
  }

  getUnexpiredCoreSubscription() {
    return this.userSubscription && this.userSubscription.subscriptions
      ? this.userSubscription.subscriptions.filter(
          subscription =>
            new Date(subscription.expiredAt).getTime() > new Date().getTime() &&
            subscription.paid &&
            subscription.active &&
            subscription.subscriptionPlan.type === 'core'
        )
      : [];
  }

  getCurrentCoreSubscription() {
    return this.userSubscription && this.userSubscription.subscriptions
      ? this.userSubscription.subscriptions.find(
          subscription =>
            subscription.active &&
            subscription.paid &&
            subscription.subscriptionPlan.type === 'core' &&
            new Date(subscription.startedAt).getTime() <= new Date().getTime() &&
            new Date(subscription.expiredAt).getTime() > new Date().getTime()
        )
      : undefined;
  }

  getUnpaidUpgradeCoreSubscription() {
    return this.userSubscription && this.userSubscription.subscriptions
      ? this.userSubscription.subscriptions.find(
          subscription =>
            subscription.subscriptionPlan.type === 'core' && subscription.createType === 'upgrade' && !subscription.paid
        )
      : undefined;
  }

  getNextCoreSubscription() {
    return this.userSubscription && this.userSubscription.subscriptions
      ? this.userSubscription.subscriptions.find(
          subscription => subscription.subscriptionPlan.type === 'core' && subscription.createType === 'reserve'
        )
      : undefined;
  }

  getFreeTrialCoreSubscription() {
    return this.userSubscription && this.userSubscription.subscriptions
      ? [...this.userSubscription.subscriptions]
          .reverse()
          .find(
            subscription => subscription.subscriptionPlan.type === 'core' && subscription.subscriptionPlan.isFreeTrial
          )
      : undefined;
  }

  getFreeTrialCoreSubscriptionRemainingSeconds() {
    const currentCoreSubscription = this.getCurrentCoreSubscription();

    const startDate = moment();
    let freeTrialRemainingSeconds = 0;
    if (currentCoreSubscription) {
      const isCurrentTrial = UserSubscription.isSubscriptionFreeTrial(currentCoreSubscription);
      if (isCurrentTrial && currentCoreSubscription.expiredAt) {
        freeTrialRemainingSeconds = moment(currentCoreSubscription.expiredAt).diff(startDate, 'seconds');
      }
    }
    return freeTrialRemainingSeconds;
  }
}

const isSubscriptionAllow = (subscriptions, { type, nicknames }) => {
  return !!subscriptions.find(
    subscription =>
      ShortSubscriptions.isShortSubscriptionValid(subscription) &&
      (!type || subscription.type === type) &&
      (!nicknames || nicknames.find(text => text === subscription.nickname))
  );
};

const getSortedSubscriptions = subscriptions =>
  subscriptions
    ? subscriptions
        .filter(subscription => UserSubscription.isSubscriptionCoreValid(subscription))
        .map(subscription => ({ ...subscription, startedAtDate: new Date(subscription.startedAt).getTime() }))
        .sort((a, b) => {
          return b.startedAtDate - a.startedAtDate;
        })
    : [];

// handle 'quarter' interval
const getFormattedInterval = ({ interval, intervalCount }) => {
  if (interval === 'month' && intervalCount === 3) {
    return { interval: 'quarter', intervalCount: intervalCount / 3 };
  }
  return { interval, intervalCount };
};

const getDisplayInterval = (intl, { interval, intervalCount, isFreeTrial, isTrial }) => {
  if (isFreeTrial) {
    return intl.formatMessage({ id: 'free-trial' });
  } else if (isTrial) {
    return intl.formatMessage({ id: 'trial' });
  } else {
    let formattedInterval = getFormattedInterval({ interval, intervalCount });

    let suffix;

    switch (formattedInterval.interval) {
      case 'day':
        suffix = 'daily';
        break;
      case 'week':
        suffix = 'weekly';
        break;
      case 'month':
        suffix = 'monthly';
        break;
      case 'quarter':
        suffix = 'quarterly';
        break;
      case 'year':
        suffix = 'annually';
        break;
      default:
        suffix = '';
        break;
    }

    suffix = intl.formatMessage({ id: suffix });

    if (formattedInterval.intervalCount === 1) {
      return suffix;
    } else {
      return `${formattedInterval.intervalCount}-${
        formattedInterval !== 'year' ? suffix : intl.formatMessage({ id: 'yearly' })
      }`;
    }
  }
};

const translationKeyMap = {
  en: 'en',
  zh: 'zh',
  'zh-Hant-HK': 'zhHantHK'
};

const saveProFlagToLocalStorage = subscriptions => {
  if (new ShortSubscriptions(subscriptions).containTypedSubscription({ type: 'core', nicknames: ['Pro', 'Ultra'] })) {
    localStorage.setItem('isPro', JSON.stringify(true));
  } else {
    localStorage.removeItem('isPro');
  }
};

const getProFlagFromLocalStorage = () => {
  const localStorageFlag = localStorage.getItem('isPro');
  return localStorageFlag ? JSON.parse(localStorageFlag) : false;
};

export const getPriceAmountDisplay = amount => addThousandSeparators(Math.ceil(amount / 100));

export const isUserSubscriptionUpgradable = (userSubscription, subscriptionPlans) => {
  const userSubscriptionO = new UserSubscription(userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const unpaidCoreUpgradeSubscription = userSubscriptionO.getUnpaidUpgradeCoreSubscription();
  const nextCoreSubscription = userSubscriptionO.getNextCoreSubscription();

  return (
    currentCoreSubscription &&
    (subscriptionPlans || []).length > 0 &&
    !unpaidCoreUpgradeSubscription &&
    !nextCoreSubscription
  );
};

const getFreeTrialRemainingSeconds = userSubscription => {
  const userSubscriptionO = new UserSubscription(userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();

  const startDate = moment();
  let freeTrialRemainingSeconds = 0;
  if (currentCoreSubscription) {
    const isCurrentTrial = UserSubscription.isSubscriptionFreeTrial(currentCoreSubscription);
    const isTrailing = UserSubscription.isSubscriptionTrialing(currentCoreSubscription);
    if ((isCurrentTrial || isTrailing) && currentCoreSubscription.expiredAt) {
      freeTrialRemainingSeconds = moment(currentCoreSubscription.expiredAt).diff(startDate, 'seconds');
    }
  }

  return freeTrialRemainingSeconds;
};

const getSubscriptionPlanExpiryDate = (userSubscription, subscriptionPlan) => {
  const freeTrialRemainingSeconds = getFreeTrialRemainingSeconds(userSubscription, subscriptionPlan);

  const startDate = moment();
  const expiryDate = subscriptionPlan
    ? startDate
        .clone()
        .add(
          subscriptionPlan.isTrial ? subscriptionPlan.trialIntervalCount : subscriptionPlan.intervalCount,
          subscriptionPlan.isTrial ? subscriptionPlan.trialInterval : subscriptionPlan.interval
        )
        .add(freeTrialRemainingSeconds || 0, 'seconds')
        .subtract(1, 'days')
    : undefined;
  const days = expiryDate ? expiryDate.diff(startDate, 'days') : undefined;

  return { startDate, expiryDate, freeTrialRemainingSeconds, days };
};

const isCurrentShortSubscriptionTrialing = shortSubscriptions => {
  if (shortSubscriptions) {
    const shortSubscriptionsO = new ShortSubscriptions(shortSubscriptions);
    const currentCoreSubscription = shortSubscriptionsO.containValidCoreSubscription();

    if (currentCoreSubscription) {
      if (currentCoreSubscription.isTrialing) {
        return true;
      }
    }
  }

  return false;
};

const getCurrentCoreSubscription = userSubscription => {
  const userSubscriptionO = new UserSubscription(userSubscription);
  return userSubscriptionO.getCurrentCoreSubscription();
};

export {
  ShortSubscriptions,
  UserSubscription,
  isSubscriptionAllow,
  getSortedSubscriptions,
  getFormattedInterval,
  getDisplayInterval,
  translationKeyMap,
  saveProFlagToLocalStorage,
  getProFlagFromLocalStorage,
  getSubscriptionPlanExpiryDate,
  isCurrentShortSubscriptionTrialing,
  getCurrentCoreSubscription
};
