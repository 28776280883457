import React from 'react';
import { injectIntl } from 'react-intl';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Grid } from '@material-ui/core';
import './EmergencyExport.scss';
import { getWebImageUrl } from '../../utils/image';
import AdviserInfo from './AdviserInfo/AdviserInfo';
import { shouldShowQDAPIcon, shouldShowVHISIcon } from '../../utils/policy';
import { getPdfTelUrl } from '../../utils/url';
import { MACAU_INSURERS } from '../../constants/macau-insurers';
import { sanitize } from 'dompurify';
import { getUserLogo } from '../../utils/user';
import { ADVISER_LOGO_FUNCTIONS } from '../../constants/user';
import { getInsurerColor } from '../../utils/insurer';
import PoweredBy from '../PolicyReview/PoweredBy/PoweredBy';
import CreateIcon from '@material-ui/icons/Create';

export const POLICY_INFO_TYPE = {
  POLICY_NO: 0,
  POLICY_NAME: 1,
};

class Export extends React.PureComponent {
  constructor(props) {
    super(props);
    this.remarkRef = React.createRef();
    this.state = {
      editorButton: {
        style: {
          top: 0,
          display: 'none'
        }
      }
    }
    this.setRemarkEditButtonStyle = this.setRemarkEditButtonStyle.bind(this);
  }

  componentDidUpdate() {
    // console.log('componentDidUpdate')
    // console.log('this.remarkRef.current: ', this.remarkRef?.current?.offsetTop)
    this.setRemarkEditButtonStyle()
  }

  setRemarkEditButtonStyle() {
    if (this.remarkRef.current) {
      const offsetHeight = this.remarkRef.current?.offsetHeight
      const offsetTop = this.remarkRef.current?.offsetTop
      // console.log('offsetHeight: ', offsetHeight, '  offsetTop: ', offsetTop)
      const nextOffset = (offsetHeight >= 44 ? (offsetHeight - 44) / 2 : 0)
      if (this.state?.editorButton?.style?.top != nextOffset) {
        const nextStyle = { top: nextOffset, display: 'block' }
        this.setState({
          ...this.state,
          editorButton: {
            style: nextStyle
          }
        })
      }
    }
  }

  render() {
    const { intl, options, onRemarkEdit,
      template, ...rest } = this.props;

    if (!options) {
      return <div>No Data Provided</div>;
    }

    const {
      selectName,
      selectPolicyInfo,
      selectPolicyInfoType,
      selectPersonalPolicy,
      selectWorkplacePolicy,
      selectGeneralPolicy,
      selectMacauInfo,
      useTeamLogo,
      changeHeaderImage,
      showReferIcon,
      remark,
      referById,
      data,
      isPrivateMode,
    } = options;

    // if (data !== undefined && data !== null && data.details !== undefined) {
    //   let otherInsurerIndex = data.details.findIndex(element => element.name === 'Other');
    //   if (otherInsurerIndex > -1) {
    //     let otherInsurer = data.details[otherInsurerIndex];
    //     let uniqueOtherInsurerNames = [...new Set(otherInsurer.policy.map(policy => policy.insurer))];
    //
    //     let otherInsurers = [];
    //     uniqueOtherInsurerNames.forEach(insurer => {
    //       let policies = otherInsurer.policy.filter(policy => policy.insurer === insurer);
    //       otherInsurers.push({
    //         ...otherInsurer,
    //         name: insurer,
    //         policy: policies,
    //         isOther: true
    //       });
    //     });
    //
    //     data.details.splice(otherInsurerIndex, 1);
    //     data.details = data.details.concat(otherInsurers);
    //   }
    // }

    let filteredCount = { total: 0, life: 0, medical: 0, ci: 0, other: 0, rider: 0 };
    const extraDetails = [];
    if (data && data.details) {
      data.details.forEach(function (value, index) {
        const extraDetail = {};
        extraDetail.basicPolicyCount = 0;
        extraDetail.riderPolicyCount = 0;
        extraDetail.policyFiltered = value.policy.filter((policy) => {
          if (
            (policy.type === 'Personal' && selectPersonalPolicy) ||
            (policy.type === 'Company' && selectWorkplacePolicy) ||
            (policy.type === 'General' && selectGeneralPolicy)
          ) {
            if (policy.planType === 'Rider') {
              extraDetail.riderPolicyCount++;
            } else {
              extraDetail.basicPolicyCount++;
            }
            return true;
          } else {
            if (policy.planType === 'Rider') {
              filteredCount.rider += 1;
            } else {
              filteredCount.total += 1;
              if (policy.insurance === 'Life') {
                filteredCount.life += 1;
              } else if (policy.insurance === 'Medical') {
                filteredCount.medical += 1;
              } else if (policy.insurance === 'Critical Illness') {
                filteredCount.ci += 1;
              } else {
                filteredCount.other += 1;
              }
            }
          }
        });

        extraDetails.push(extraDetail);
      });
    }

    const userLogo = getUserLogo(data?.connectedAdviser, data?.teamLogoUrl, ADVISER_LOGO_FUNCTIONS.PDF);

    return (
      <div id="emergency-export" className={`emergency-export emergency-export-wrapper ${template ? 'template' : 'preview'}`} {...rest}>
        {data && data.details && (
          <div>
            {!isPrivateMode && (
              <div className="emergency-logo">
                {changeHeaderImage === true ? (
                  <img src={getWebImageUrl('img/export_header_one.png')} alt="" />
                ) : useTeamLogo === true && userLogo ? (
                  <img alt="" src={userLogo + `?v=${new Date().getTime()}`} crossOrigin="anonymous" />
                ) : (
                  <img alt="" className="default-logo" src={getWebImageUrl('img/export_header.png')} />
                )}
              </div>
            )}
            <div className="emergency-overview">
              <div className="emergency-overview-left">
                <Grid container direction="column" justify="center" wrap="nowrap" style={{ height: '100%' }}>
                  <Grid item>
                    <div className="emergency-client">
                      <span>{selectName ? data.clientName : '-'}</span>
                    </div>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <AdviserInfo
                      show={!isPrivateMode}
                      showQRCode={showReferIcon}
                      adviser={data && data.connectedAdviser}
                      referById={referById}
                      referLinkSearch={{
                        defaultMessage: intl.formatMessage(
                          { id: 'emer-pdf-default-msg' },
                          { clientName: data.clientName },
                        ),
                      }}
                      QRActionButton="create-one-for-free"
                      qrCodeWidth={160}
                      referLinkParams={{
                        source: 'pdf',
                        extraSearch: {
                          defaultMessage: intl.formatMessage(
                            { id: 'emer-pdf-default-msg' },
                            { clientName: data.clientName },
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className="emergency-overview-right">
                <Grid container direction="column" justify="center" wrap="nowrap" style={{ height: '100%' }}>
                  <Grid item style={{ flexGrow: 1 }}>
                    <Grid container spacing={0} justify="center" className="emergency-overview-right-wrapper">
                      <Grid
                        item
                        className="emergency-policy-overview-section emergency-policy-overview-section-total flex-center"
                      >
                        <div className="emergency-policy-overview-section-wrapper">
                          <div className="emergency-policy-overview-total-number">
                            <span>{data.policies.count.total - filteredCount.total}</span>
                          </div>

                          <div className="emergency-policy-overview-total-number-label">
                            <span>
                              {data.policies.count.total - filteredCount.total < 2
                                ? intl.formatMessage({ id: 'basic-plan' })
                                : intl.formatMessage({ id: 'basic-plans' })}
                            </span>
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        className="emergency-policy-overview-section emergency-policy-overview-section-middle flex-center"
                      >
                        <div className="emergency-policy-overview-section-wrapper">
                          <span>-</span>
                        </div>
                      </Grid>

                      <Grid
                        item
                        className="emergency-policy-overview-section emergency-policy-overview-section-subcategory flex-center"
                      >
                        <div className="emergency-policy-overview-section-wrapper">
                          <div className="emergency-policy-overview-subcategory-total">
                            <div className="emergency-policy-overview-subcategory-total-number">
                              <span>{data.policies.count.life - filteredCount.life}</span>
                            </div>
                            <div className="emergency-policy-overview-subcategory-total-label">
                              <span>
                                &nbsp;
                                {intl.formatMessage({ id: 'Life' })}
                              </span>
                            </div>
                          </div>

                          <div className="emergency-policy-overview-subcategory-total">
                            <div className="emergency-policy-overview-subcategory-total-number">
                              <span>{data.policies.count.medical - filteredCount.medical}</span>
                            </div>
                            <div className="emergency-policy-overview-subcategory-total-label">
                              <span>
                                &nbsp;
                                {intl.formatMessage({ id: 'Med' })}
                              </span>
                            </div>
                          </div>

                          <div className="emergency-policy-overview-subcategory-total">
                            <div className="emergency-policy-overview-subcategory-total-number">
                              <span>{data.policies.count.ci - filteredCount.ci}</span>
                            </div>
                            <div className="emergency-policy-overview-subcategory-total-label">
                              <span>
                                &nbsp;
                                {intl.formatMessage({ id: 'C.I.' })}
                              </span>
                            </div>
                          </div>

                          <div className="emergency-policy-overview-subcategory-total">
                            <div className="emergency-policy-overview-subcategory-total-number">
                              <span>{data.policies.count.other - filteredCount.other}</span>
                            </div>
                            <div className="emergency-policy-overview-subcategory-total-label">
                              <span>
                                &nbsp;
                                {intl.formatMessage({ id: 'Other' })}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <div style={{ textAlign: 'center', backgroundColor: '#3eeef8' }}>
                      <span>
                        <img src={getWebImageUrl('img/rider_small.png')} alt="rider" height={24} />
                      </span>
                      <div className="emergency-policy-overview-subcategory-total-number">
                        <span>{data.policies.count.rider - filteredCount.rider}</span>
                      </div>
                      <div className="emergency-policy-overview-subcategory-total-label">
                        <span>
                          &nbsp;
                          {intl.formatMessage({ id: 'pdf-rider' })}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

            {remark && (
              <div className="emergency-remark">
                <div ref={this.remarkRef} className="emergency-remark-content">
                  {!template &&
                    <div id="overlay" style={this.state.editorButton.style}
                    >
                      {/* {console.log(this.state.editorButton)} */}
                      <Button
                        color="primary"
                        className="edit-info-button"
                        onClick={onRemarkEdit}
                        disabled={false}
                      >
                        <CreateIcon />
                      </Button>
                    </ div>
                  }
                  <div dangerouslySetInnerHTML={{ __html: sanitize(decodeURIComponent(remark)) }} />
                </div>
              </div>
            )}

            <div className="emergency-policy-details">
              {data.details &&
                data.details.map(function (value, index) {
                  const extraDetail = extraDetails[index];
                  const macauInsurer = selectMacauInfo ? MACAU_INSURERS[value._id] : undefined;
                  const color = getInsurerColor({ color: value.color });
                  var shouldShowMPFHotline = false;


                  if (value.policy?.map((e) => e.insurance)?.join(',')?.includes('Provident Fund')) {
                    // console.log("Provident Fund Found, Show MPF Hotline");
                    shouldShowMPFHotline = true;
                  }

                  if (extraDetail.policyFiltered.length > 0) {
                    return (
                      <div key={index} className="emergency-policy-details-insurer">
                        <div
                          className="emergency-policy-details-insurer-wrapper"
                          style={{
                            borderLeft: '9px solid ' + color,
                          }}
                        >
                          <div className="emergency-insurer-left">
                            <div className="emergency-insurer-info">
                              <div className="emergency-insurer-name">{value.name[intl.locale]}</div>
                            </div>

                            <div className="emergency-insurer-policies">
                              <Table className="emergency-insurer-table emergency_reference-table">
                                <TableHead>
                                  <TableRow>
                                    {selectPolicyInfo === true && (
                                      <TableCell>
                                        {selectPolicyInfoType === POLICY_INFO_TYPE.POLICY_NO
                                          ? intl.formatMessage({
                                            id: 'Policy Number',
                                          })
                                          : intl.formatMessage({
                                            id: 'Policy Name',
                                          })}
                                      </TableCell>
                                    )}
                                    <TableCell>
                                      {intl.formatMessage({
                                        id: 'Insurance',
                                      })}
                                    </TableCell>
                                    <TableCell>{intl.formatMessage({ id: 'Type' })}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {extraDetail.policyFiltered &&
                                    extraDetail.policyFiltered.map(function (policy, policyIndex) {
                                      return (
                                        <TableRow key={policyIndex}>
                                          {selectPolicyInfo && (
                                            <TableCell>
                                              <div className="export-policy-title-wrapper">
                                                <div className="export-policy-title">
                                                  {selectPolicyInfoType === POLICY_INFO_TYPE.POLICY_NO
                                                    ? policy.policyNumber
                                                    :
                                                    policy.type === "Company"
                                                      ? `${intl.formatMessage({ id: 'Company' })}: ${policy.policyName}`
                                                      : policy.policyName
                                                  }
                                                </div>

                                                <div className="export-policy-icon-wrapper">
                                                  {policy.planType === 'Rider' && (
                                                    <img
                                                      alt=""
                                                      className="export-policy-icon"
                                                      src={getWebImageUrl('img/rider_small.png')}
                                                    />
                                                  )}

                                                  {policy.guaranteeValue === 'No' && (
                                                    <img
                                                      alt=""
                                                      className="export-policy-icon"
                                                      src={getWebImageUrl('img/ilas_small.png')}
                                                    />
                                                  )}

                                                  {policy.lifeInsuredSelected === 'Not Me' && (
                                                    <img
                                                      alt=""
                                                      className="export-policy-icon"
                                                      src={getWebImageUrl('img/notme.png')}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </TableCell>
                                          )}
                                          <TableCell>
                                            <span className="export-insurance-text">
                                              {intl.formatMessage({
                                                id: policy.insurance,
                                              })}
                                            </span>
                                            {shouldShowVHISIcon(policy) && (
                                              <div className="vhisPlan_export">
                                                {intl.formatMessage({
                                                  id: 'vhisIcon',
                                                })}
                                              </div>
                                            )}
                                            {shouldShowQDAPIcon(policy) && (
                                              <div className="qdapPlan_export">
                                                {intl.formatMessage({
                                                  id: 'QDAP',
                                                })}
                                              </div>
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {intl.formatMessage({
                                              id: policy.type,
                                            })}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </div>

                            {(value.hotline || value.email) && (
                              <div className="emergency-insurer-contact">
                                {value.hotline && (
                                  <div className="emergency-insurer-hotline">
                                    <div className="emergency-insurer-contact-label left">
                                      {intl.formatMessage({ id: 'CS Hotline' })}
                                    </div>
                                    <a href={getPdfTelUrl(value.hotline)} target="_blank" rel="noopener noreferrer">
                                      <div className="emergency-insurer-contact-value phone left">{value.hotline}</div>
                                    </a>
                                    {macauInsurer && (
                                      <a
                                        href={getPdfTelUrl(macauInsurer.hotline)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <div className="emergency-insurer-contact-value phone left">{macauInsurer.hotline}</div>
                                      </a>
                                    )}
                                  </div>
                                )}
                                {value.emergencyHotline && (
                                  <div className="emergency-insurer-hotline">
                                    <div className="emergency-insurer-contact-label center">
                                      {intl.formatMessage({ id: 'emergency-hotline-label' })}
                                    </div>
                                    <a href={getPdfTelUrl(value.emergencyHotline)} target="_blank" rel="noopener noreferrer">
                                      <div className="emergency-insurer-contact-value phone center">{value.emergencyHotline}</div>
                                    </a>
                                    {macauInsurer && (
                                      <a
                                        href={getPdfTelUrl(macauInsurer.emergencyHotline)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <div className="emergency-insurer-contact-value phone center">{macauInsurer.emergencyHotline}</div>
                                      </a>
                                    )}
                                  </div>
                                )}

                                {shouldShowMPFHotline && value.mpfGeneralHotline && (
                                  <div className="emergency-insurer-hotline">
                                    <div className="emergency-insurer-contact-label right">
                                      {intl.formatMessage({ id: 'mpf-general-hotline-label' })}
                                    </div>
                                    <a href={getPdfTelUrl(value.hotline)} target="_blank" rel="noopener noreferrer">
                                      <div className="emergency-insurer-contact-value phone right">{value.mpfGeneralHotline}</div>
                                    </a>
                                    {macauInsurer && (
                                      <a
                                        href={getPdfTelUrl(macauInsurer.mpfGeneralHotline)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <div className="emergency-insurer-contact-value phone right">{macauInsurer.mpfGeneralHotline}</div>
                                      </a>
                                    )}
                                  </div>
                                )}

                              </div>
                            )}

                            {value.email && (
                              <div className="emergency-insurer-contact">
                                <div className="emergency-insurer-email">
                                  {!value.email || value.email === '' ? (
                                    <div className="emergency-insurer-contact-value empty">-</div>
                                  ) : (
                                    <span
                                    // href={`mailto:${value.email}`} target="_blank" rel="noopener noreferrer"
                                    >
                                      <div className="emergency-insurer-contact-value email">{value.email}</div>
                                    </span>
                                  )}
                                  {macauInsurer && macauInsurer.email && (
                                    <span>
                                      <div className="emergency-insurer-contact-value email">{macauInsurer.email}</div>
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="emergency-insurer-right">
                            <Grid container direction="column" wrap="nowrap">
                              <Grid item>
                                <div
                                  className="emergency-insurer-policy-count"
                                  style={{
                                    backgroundColor: color,
                                  }}
                                >
                                  <div className="emergency-insurer-policy-count-number">
                                    <span>{extraDetail.basicPolicyCount || 0}</span>
                                  </div>

                                  <div className="emergency-insurer-policy-count-label">
                                    <span>
                                      {extraDetail.basicPolicyCount < 2
                                        ? intl.formatMessage({ id: 'basic-plan' })
                                        : intl.formatMessage({ id: 'basic-plans' })}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item style={{ marginTop: 12 }}>
                                <div
                                  style={{
                                    backgroundColor: color,
                                    marginLeft: 30,
                                    padding: '10px 0',
                                  }}
                                >
                                  <div className="emergency-insurer-policy-count-label">
                                    <span style={{ fontSize: 24, lineHeight: 1, verticalAlign: 'middle' }}>
                                      {extraDetail.riderPolicyCount || 0}
                                    </span>
                                    <span style={{ lineHeight: 1, verticalAlign: 'middle' }}>
                                      &nbsp;
                                      {intl.formatMessage({ id: 'Rider' })}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return true;
                })}
            </div>

            <div className="emergency-contacts">
              <div className="emergency-contacts-header">
                <span>{intl.formatMessage({ id: 'Emergency Hotline' })}</span>
              </div>
              <ul>
                <li>
                  <div className="emergency-contacts-label">
                    {intl.formatMessage({
                      id: 'HKGov Assistance to HK Residents No.',
                    })}
                  </div>
                  <a className="link" href={getPdfTelUrl('+8521868')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">+852&nbsp;1868</div>
                  </a>
                </li>
                <li>
                  <div className="emergency-contacts-label">
                    {intl.formatMessage({
                      id: 'Chinese citizens Global Emergency No.',
                    })}
                  </div>
                  <a className="link" href={getPdfTelUrl('+861012308')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">+86&nbsp;&nbsp;&nbsp;1012308</div>
                  </a>
                </li>
              </ul>
              <div>
                <div className="emergency-contacts-header">
                  <span>{intl.formatMessage({ id: 'emergency-global-support-title' })}</span>
                </div>
                <div className="gobal-emergency-service-hint">
                  <span >
                    {intl.formatMessage({ id: 'emergency-global-support-hint' })}</span>
                </div>
              </div>
              <ul>
                <li>
                  <div className="emergency-contacts-label">
                    {intl.formatMessage({
                      id: 'sos-ltd',
                    })}
                  </div>
                  <a className="link" href={getPdfTelUrl('+85225289900')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">+852&nbsp;25289900</div>
                  </a>
                </li>
              </ul>
              <div className="emergency-contacts-header">
                <span>{intl.formatMessage({ id: 'Major cities emergency No.' })}</span>
              </div>
              <ul>
                <li>
                  <div className="emergency-contacts-label">{intl.formatMessage({ id: 'exportHSMMU' })}</div>
                  <a className="link" href={getPdfTelUrl('999')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">999</div>
                  </a>
                </li>
                <li>
                  <div className="emergency-contacts-label">{intl.formatMessage({ id: 'exportCTJ' })}</div>
                  <a className="link" href={getPdfTelUrl('110')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">110</div>
                  </a>
                </li>
                <li>
                  <div className="emergency-contacts-label">{intl.formatMessage({ id: 'exportKTPCU' })}</div>
                  <a className="link" href={getPdfTelUrl('911')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">911</div>
                  </a>
                </li>
                <li>
                  <div className="emergency-contacts-label">{intl.formatMessage({ id: 'exportAGFISSN' })}</div>
                  <a className="link" href={getPdfTelUrl('112')} target="_blank" rel="noopener noreferrer">
                    <div className="emergency-contacts-phone">112</div>
                  </a>
                </li>
              </ul>
            </div>
            {/* Footer */}
            <div className='footer'>
              {/* Axis */}
              <div className='flex' >
                <div className='flex'>
                  <img alt="rider" className="icon ma"
                    src={getWebImageUrl('img/rider_small.png')} />
                  <span className='text pr ma'>{`: ${intl.formatMessage({ id: 'pdf-rider' })}`} </span>
                  <img alt="insured-not-me" className="icon ma" src={getWebImageUrl('img/notme.png')} />
                  <span className='text pr ma'> {`: ${intl.formatMessage({ id: 'life-insured-hint' })}`} </span>
                  <img alt="ilas" className="icon ma" src={getWebImageUrl('img/ilas_small.png')} />
                  <span className='text pr ma'> {`: ${intl.formatMessage({ id: 'ilas-portfolio' })}`} </span>

                </div>
                <div className="right" style={{ flexGrow: 1 }}>
                  <PoweredBy />
                </div>
              </div>
              <div className='text'>
                <span>{intl.formatMessage({ id: 'emergency-disclaimer' })}  </span>
              </div>
            </div>
          </div>
        )
        }
      </div>
    );
  }
}

export default injectIntl(Export);
