import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import SwiperCore, { Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import Card from '@material-ui/core/Card';
import './AdviserHomePage.scss';
import TopNavHelpButton from '../../components/TopNavHelpButton/TopNavHelpButton';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { tracking } from '../../utils/tracking';
import { withRouter } from 'react-router';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { hasQuickClosingCampaignCode, shouldEnableChatGPT, shouldShowAdviserFirstGuideline } from '../../utils/user';
import AdviserFirstGuidelineTooltip from '../../components/guidelines/AdviserFirstGuidelineTooltip/AdviserFirstGuidelineTooltip';
import { endGuideline, runGuideline } from '../../store/guideline/duck';
import { getNewReferralCount } from '../../store/referral/duck';
import { goToAddClient } from '../../components/ClientEntry/utils';
import DateClientDialog from '../../components/DateClientDialog/DateClientDialog';
import { ADVISER_HOME_PAGE_BANNERS } from '../../constants/adviser-home-page';
import { Button, Container, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import AdviserLoginLogic from '../../components/LoginLogic/AdviserLoginLogic';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';
import BusinessToolsSection from './BusinessToolsSection/BusinessToolsSection';
import MoreToolsSection from './MoreToolsSection/MoreToolsSection';
import DashboardSection from './DashboardSection/DashboardSection';
import ShortcutsSection from './ShortcutsSection/ShortcutsSection';
import AdviserSwiperDecoration from './AdviserSwiperDecoration/AdviserSwiperDecoration';
import { toggleControl } from '../../store/control/duck';
import { TopNavigationPortal } from '../../components/topNavigation';
import TeamBlastPopUp from './TeamBlastPopUp';
import { markNotificationAsRead } from '../../store/notificationCenter/duck';
import VideoDialog from '../../components/VideoPlayer/VideoDialog';
import { updateFirstGuidelineState } from '../../store/user/duck';
import ChatGPTFab from './ChatGPTFab/ChatGPTFab';
import { hasCampaignCode } from '../../utils/user';

SwiperCore.use([Pagination, Navigation, EffectCoverflow, Autoplay]);

const FullWidthStyle = {
  width: '100%'
};

const AdviserHomePage = props => {
  const {
    intl,
    history,
    location,
    userDetails,
    teamCampaignCodes,
    renderHeaderButton,
    runGuideline,
    getNewReferralCount,
    toggleControl,
    endGuideline,
    hasTeamBlast,
    teamBlastPop,
    markNotificationAsRead,
    firstGuidelineRan,
    updateFirstGuidelineState
  } = props;

  const [dateClientDialog, setDateClientDialog] = useState(false);
  const [showTeamBlast, setShowTeamBlast] = useState(false);
  const narrowLayout = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const showGreeting = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const topNavHelpButtonRef = useRef(null);
  const videoDialogRef = useRef(null);
  const getTopNavHelpButtonInstance = () => {
    const topNavHelpButton = _.get(topNavHelpButtonRef, 'current');
    return topNavHelpButton ? topNavHelpButton.getWrappedInstance() : undefined;
  };

  const shouldShowQuickClosing = hasQuickClosingCampaignCode(userDetails);
  const showChatGPT = shouldEnableChatGPT({ user: userDetails, teamCampaignCodes: teamCampaignCodes });
  const isChubbUser = hasCampaignCode(userDetails, teamCampaignCodes, 'ENTCBB');

  const quickClosing = () => {
    tracking('Click on Quick Closing - Homepage');
    history.push('/quick_closing');
  };

  const addClient = () => goToAddClient(history, { replaceRoute: '/client' });
  const openDateClientDialog = () => {
    setDateClientDialog(true);
  };
  const closeDateClientDialog = () => {
    setDateClientDialog(false);
  };
  const onCloseAllDialogs = () => {
    const runAdviserFirstGuideline = () => {
      if (shouldShowAdviserFirstGuideline(userDetails)) {
        setTimeout(() => {
          runGuideline('adviserWelcome', {
            parameters: {
              intl: intl,
              onFinish: () => {
                const topNavHelpButtonInstance = getTopNavHelpButtonInstance();
                if (topNavHelpButtonInstance) {
                  topNavHelpButtonInstance.open();
                }
              }
            },
            tooltipComponent: AdviserFirstGuidelineTooltip
          });
        }, 400);
      }
    };

    if (!firstGuidelineRan && shouldShowAdviserFirstGuideline(userDetails)) {
      updateFirstGuidelineState(true);
      runAdviserFirstGuideline();
    } else {
      toggleControl('addToHomeSnackbar', true);
    }
  };
  const onClickBannerHoldCover = () => {
    tracking('Click on banners - HoldCover');
    history.push('/partnership');
  };
  const onPFPOlympic = () => {
    tracking('Click on banners - Events');
    history.push('/events');
  };

  const onClickBanner0 = () => {
    tracking('Click on banners - Homepage');
    openDateClientDialog();
  };
  const onClickBanner1 = () => {
    tracking('Click on banners - Homepage');
    // history.push('/new_help_center#c38');
    //window.open('http://bit.ly/UpgradeforTeam', '_blank');
    // window.open('https://www.instagram.com/portfoplus/', '_blank');
    // history.push('/nfc-card');
    // window.open('https://bit.ly/pf_seminar', '_blank');
    // history.push('/new_help_center#t04');
    // window.open('https://www.instagram.com/p/CnlW8N7u5_g', '_blank');
    history.push('/hot_topic');
  };
  const onClickBanner2 = event => {
    tracking('Click on banners - Homepage');
    // window.open('http://bit.ly/advisersgroup', '_blank');
    // history.push('/profile-plus');
    // const videoDialogInstance = _.get(videoDialogRef, 'current._wrappedInstance');
    // if (videoDialogInstance) {
    //   videoDialogInstance.open(event, 'https://youtu.be/oqrF2gx9zP8');
    // }
    history.push('/new_help_center');
  };

  const goPremiumFinancing = () => {
    history.push('/premium_financing');
  };

  const markAsRead = data => {
    if (data.receipients.ids.state === 'new') {
      markNotificationAsRead(data._id);
    }
  };

  useEffect(() => {
    renderHeaderButton(true);
  }, [renderHeaderButton]);

  useEffect(() => {
    if (_.get(userDetails, 'userType') === 'Adviser') {
      getNewReferralCount();
    }
  }, [userDetails, getNewReferralCount]);

  useEffect(() => {
    return () => {
      endGuideline();
    };
  }, [endGuideline]);

  useEffect(() => {
    let showTeamBlast = location.state && location.state.showTeamBlast ? location.state.showTeamBlast : false;
    setShowTeamBlast(showTeamBlast);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardWidth = narrowLayout ? '65%' : '40%';


  return (
    <AppContentContainer>
      <AdviserLoginLogic onCloseAllDialogs={onCloseAllDialogs} />
      <VideoDialog
        ref={videoDialogRef}
        appendActions={
          <Button color="primary" onClick={goPremiumFinancing}>
            {intl.formatMessage({ id: 'go-to' })}
          </Button>
        }
      />
      {showChatGPT && <ChatGPTFab />}

      <div className="home-page adviser">
        <TopNavigationPortal>
          <div className="plus-btn-client dashboard-guideline-icon">
            <TopNavHelpButton ref={topNavHelpButtonRef} buttonProps={{ notClientStyle: true }} />
          </div>
        </TopNavigationPortal>

        <DateClientDialog open={dateClientDialog} onClose={closeDateClientDialog} />

        <div style={{ position: 'relative' }}>
          {!narrowLayout && <AdviserSwiperDecoration />}

          <Container maxWidth="sm" style={{ padding: 0 }}>
            <Swiper
              spaceBetween={15}
              centeredSlides={true}
              centeredSlidesBounds={true}
              autoHeight={true}
              initialSlide={0}
              slidesPerView={narrowLayout ? 1.15 : 1.35}
              loop={true}
              loopedSlides={0}
              navigation={false}
              pagination={false}
              effect="coverflow"
              coverflowEffect={{
                rotate: 0,
                depth: 350,
                modifier: 2,
                stretch: 75,
                slideShadows: false
              }}
              autoplay={{
                delay: 3000
              }}
            >
              {/* {
                !isChubbUser &&
                <SwiperSlide>
                  <Card onClick={onPFPOlympic}>
                    <img alt="events" src={ADVISER_HOME_PAGE_BANNERS[4][intl.locale]} />
                  </Card>
                </SwiperSlide>
              } */}
              <SwiperSlide>
                <Card onClick={onClickBanner0}>
                  <img alt="adviser-help" src={ADVISER_HOME_PAGE_BANNERS[0][intl.locale]} />
                </Card>
              </SwiperSlide>
              {
                !isChubbUser &&
                <SwiperSlide>
                  <Card onClick={onClickBannerHoldCover}>
                    <img alt="holdcover" src={ADVISER_HOME_PAGE_BANNERS[3][intl.locale]} />
                  </Card>
                </SwiperSlide>
              }

              <SwiperSlide>
                <Card onClick={onClickBanner1}>
                  <img alt="lazy-pack" src={ADVISER_HOME_PAGE_BANNERS[1][intl.locale]} />
                </Card>
              </SwiperSlide>
              <SwiperSlide>
                <Card onClick={onClickBanner2}>
                  <img alt="classroom" src={ADVISER_HOME_PAGE_BANNERS[2][intl.locale]} />
                </Card>
              </SwiperSlide>
            </Swiper>
          </Container>
        </div>

        <Grid
          container
          direction="column"
          alignItems="flex-start"
          spacing={narrowLayout ? 2 : 3}
          style={{ marginBottom: 60 }}
        >
          <Grid item style={FullWidthStyle}>
            {showGreeting && (
              <Typography color="textSecondary" style={{ fontSize: '150%' }} component="div">
                {intl.formatMessage({ id: 'home-greeting' })}
                <Typography color="primary" display="inline" style={{ fontSize: '100%' }}>
                  {_.get(userDetails, 'name', '')}!
                </Typography>
              </Typography>
            )}

            <Grid container alignItems="center" wrap="nowrap" spacing={2}>
              <Grid item xs={11} sm={7} md={8}>
                <DashboardSection />
              </Grid>
              <Grid item xs={1} sm={5} md={4} style={{ padding: 2 }}>
                <ShortcutsSection />
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={FullWidthStyle}>
            <BusinessToolsSection />
          </Grid>

          <Grid item style={FullWidthStyle}>
            <MoreToolsSection getTopNavHelpButtonInstance={getTopNavHelpButtonInstance} />
          </Grid>

          {/*<div className="hot-features">*/}
          {/*	<Typography className="subtitle" color="primary" paragraph={true}>*/}
          {/*		{intl.formatMessage({ id: 'home-hot-features' })}*/}
          {/*	</Typography>*/}

          {/*	<GridButtonLayout*/}
          {/*		items={[*/}
          {/*			shouldShowNOVA && {*/}
          {/*				image: <img src="img/adviser-home-page/nova-slife.png" alt="nova"/>,*/}
          {/*				text: 'NOVA (af)',*/}
          {/*				onClick: NOVA*/}
          {/*			},*/}
          {/*			shouldShowXKSubscribe && {*/}
          {/*				image: <img src="img/adviser-home-page/APCS-homepage.png" alt="apcs-home-page"/>,*/}
          {/*				text: intl.formatMessage({ id: 'APCS' }),*/}
          {/*				onClick: APCS,*/}
          {/*				style: { position: 'relative' },*/}
          {/*				extra: <HotLabel text={intl.formatMessage({ id: 'special' })}/>*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/help/fastlearn.png" alt="go-to-help-center"/>,*/}
          {/*				text: intl.formatMessage({ id: 'newbie-tour' }),*/}
          {/*				onClick: adviserHelp*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/door_opener.png" alt="hot-topic"/>,*/}
          {/*				text: intl.formatMessage({ id: 'door-opener' }),*/}
          {/*				onClick: doorOpener,*/}
          {/*				style: { position: 'relative' },*/}
          {/*				extra: <HotLabel text={intl.formatMessage({ id: 'hot' })}/>*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/export-options/exportreview.png" alt="export-review"/>,*/}
          {/*				text: intl.formatMessage({ id: 'export-sum-desc' }),*/}
          {/*				onClick: policyReview,*/}
          {/*				style: { position: 'relative' },*/}
          {/*				extra: <HotLabel text={intl.formatMessage({ id: 'hot' })}/>*/}
          {/*			},*/}
          {/*			shouldShowQuickClosing && {*/}
          {/*				image: <img src="img/adviser-home-page/teampartner.png" alt="export-review"/>,*/}
          {/*				text: intl.formatMessage({ id: 'TeamUp' }),*/}
          {/*				onClick: quickClosing,*/}
          {/*				style: { position: 'relative' },*/}
          {/*				extra: <HotLabel text={intl.formatMessage({ id: 'hot' })}/>*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/homepagetools.png" alt="go-to-help-center"/>,*/}
          {/*				text: intl.formatMessage({ id: 'Tools' }),*/}
          {/*				onClick: tools*/}
          {/*			}*/}
          {/*		]}*/}
          {/*	/>*/}
          {/*</div>*/}

          {/*<div className="newbie-lessons">*/}
          {/*	<Typography className="subtitle" color="primary" paragraph={true}>*/}
          {/*		{intl.formatMessage({ id: 'home-newbie-lessons' })}*/}
          {/*	</Typography>*/}

          {/*	<GridButtonLayout*/}
          {/*		items={[*/}
          {/*			{*/}
          {/*				image: <img src="img/addClient.png" alt="add-client"/>,*/}
          {/*				text: intl.formatMessage({ id: 'add-client' }),*/}
          {/*				onClick: addClient*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/adviser-home-page/FreeTraining.png" alt="free-webinar"/>,*/}
          {/*				text: intl.formatMessage({ id: 'free-webinar' }),*/}
          {/*				onClick: webinar*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: (*/}
          {/*					<Badge*/}
          {/*						className="Badge todo-badge"*/}
          {/*						invisible={!newReferralCount}*/}
          {/*						badgeContent={newReferralCount}*/}
          {/*						color="error"*/}
          {/*					>*/}
          {/*						<img src="img/adviser-home-page/AddReferral.png" alt="new-client-referral"/>*/}
          {/*					</Badge>*/}
          {/*				),*/}
          {/*				text: intl.formatMessage({ id: 'new-client-referral' }),*/}
          {/*				onClick: referral*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/showqrcode.png" alt="get-users"/>,*/}
          {/*				text: intl.formatMessage({ id: 'Get Users' }),*/}
          {/*				onClick: getUsers*/}
          {/*			},*/}
          {/*			{*/}
          {/*				image: <img src="img/helpCenter.png" alt="help-center"/>,*/}
          {/*				text: intl.formatMessage({ id: 'Help Center' }),*/}
          {/*				onClick: helpCenter*/}
          {/*			}*/}
          {/*		]}*/}
          {/*	/>*/}
          {/*</div>*/}
        </Grid>
      </div>
      {hasTeamBlast &&
        showTeamBlast &&
        teamBlastPop?.map(e => <TeamBlastPopUp markAsRead={markAsRead} teamBlastPop={e} />)}
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    newReferralCount: state.referral.newReferralCount,
    hasTeamBlast: state.notificationCenter.hasTeamBlast,
    teamBlastPop: state.notificationCenter.teamBlastPop,
    firstGuidelineRan: state.user.firstGuidelineRan,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {
    renderHeaderButton,
    runGuideline,
    getNewReferralCount,
    toggleControl,
    endGuideline,
    markNotificationAsRead,
    updateFirstGuidelineState
  }
)(AdviserHomePage);

export default injectIntl(withRouter(container));
