import React, { PureComponent } from 'react';
import moment from 'moment';
import '../../InsuredDetails/styles.scss';

const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];
const subCategoryDetails = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' }
];

class ViewShareDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      val: '',
      planDetails: false,
      edit: '',
      insurer: '',
      currency: '',
      premium: '',
      lifeCoverage: '',
      protectionAmountInHKD: '',
      sumInsured: '',
      feature: '',
      planType: '',
      levelPremium: '',
      paymentMethod: '',
      paymentTerms1: '',
      protectionTerms1: '',
      policyOwner: '',
      modalIsOpen: false,
      lifeInsured: '',
      lifeInsuredAge: '',
      paymentTerms: '',
      protectionTerms: '',
      annualPremiumInHKD: '',
      beneficial: '',
      remark: '',
      policyStartDate: '',
      option: false,
      life: '',
      categoryType: '',
      subCategoryType: '',
      err: {},
      deductible: '',
      smm: 'No',
      hospitalCash: 'No',
      outPatient: 'No',
      dental: 'No',
      orphanCase: '',
      roomClass: ''
    };
  }

  UNSAFE_componentWillMount() {
    const { dashboardPolicyData } = this.props;
    var categoryType = '';
    var subCategoryType = '';
    categoryDetails.map(function(data) {
      if (data.id === dashboardPolicyData.categoryId) {
        categoryType = data.title;
      }
      return '';
    });
    subCategoryDetails.map(function(data) {
      if (data.id === dashboardPolicyData.subCategoryId) {
        subCategoryType = data.title;
      }
      return '';
    });
    this.setState({
      categoryType: categoryType,
      subCategoryType: subCategoryType
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.policyInfoPhase === true) {
      sessionStorage.setItem('editPolicyDetails', JSON.stringify(nextProps.policyInfoData));
      sessionStorage.setItem('editPolicy', JSON.stringify(true));

      this.props.history.push({ pathname: '/plan' });
    }
  }

  handleDetails() {
    this.setState({ planDetails: !this.state.planDetails });
  }

  editPolicy() {
    const { dashboardPolicyData } = this.props;
    let data = dashboardPolicyData._id;
    this.props.policyInfo(data);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  insurerInfo(val) {
    let ins = this.state.insurerData ? this.state.insurerData : val.insurerId;
    this.props.history.push({
      pathname: '/insurer_detail',
      state: { viewShareInfo: ins }
    });
  }

  render() {
    const { dashboardPolicyData, location, exchangeRate, intl } = this.props;
    const {
      planDetails,
      feature,
      planType,
      levelPremium,
      paymentMethod,
      policyOwner,
      lifeInsured,
      lifeInsuredAge,
      paymentTerms,
      protectionTerms,
      beneficial,
      advisorName,
      advisorPhoneNo,
      advisorEmail,
      remark,
      emergencyTel,
      rentalCarCover,
      mobilePhoneCover,
      lossItemCover,
      tripDelayCover,
      coverageDay,
      homeContentsValue,
      homeSize,
      ncb,
      vehicleYear,
      vehicleBrand,
      vehicleModel,
      coverageDetail,
      deductible,
      subCategoryType,
      categoryType,
      orphanCase,
      insurerData
    } = this.state;

    var val = dashboardPolicyData;
    let currencyValue =
      val.currency === 'USD' ? exchangeRate.usd_hkd : val.currency === 'CNY' ? exchangeRate.cny_hkd : 1;
    let premiumTermsValue = '';
    let frequencyData = '';
    frequencyData = val.frequency;
    var premiumTime = 12 / parseInt(frequencyData);
    if (!this.isEmpty(dashboardPolicyData) && !val.shareType) {
      var lifeCoverage = this.state.lifeCoverage !== '' ? parseFloat(this.state.lifeCoverage) : val.lifeCoverage;
      if (Number.isInteger(lifeCoverage) === true) {
        lifeCoverage = lifeCoverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        lifeCoverage =
          lifeCoverage &&
          parseFloat(lifeCoverage)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var protectionAmountInHKD = this.state.protectionAmountInHKD
        ? parseFloat(this.state.protectionAmountInHKD)
        : val.lifeCoverage * currencyValue;
      if (Number.isInteger(protectionAmountInHKD) === true) {
        protectionAmountInHKD = protectionAmountInHKD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        protectionAmountInHKD =
          protectionAmountInHKD &&
          parseFloat(protectionAmountInHKD)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var premium = this.state.premium !== '' ? parseFloat(this.state.premium) : val.premium;
      if (Number.isInteger(premium) === true) {
        premium = premium.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        premium =
          premium &&
          parseFloat(premium)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      var annualPremiumInHKD = this.state.annualPremiumInHKD
        ? parseFloat(this.state.annualPremiumInHKD)
        : val.premium * premiumTime * currencyValue;
      if (Number.isInteger(annualPremiumInHKD) === true) {
        annualPremiumInHKD = annualPremiumInHKD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        annualPremiumInHKD =
          annualPremiumInHKD &&
          parseFloat(annualPremiumInHKD)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      if (val.policyStartDate !== null) {
        var date = moment(val.policyStartDate).format('DD-MMM-YYYY');
      } else {
        date = null;
      }
      var sumInsured =
        this.state.sumInsured !== ''
          ? this.state.sumInsured
          : //: val.sumInsured;
          val.sumInsured && val.sumInsured.indexOf('.') === -1
          ? parseInt(val.sumInsured)
          : val.sumInsured;
      if (Number.isInteger(sumInsured) === true) {
        sumInsured = sumInsured.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        sumInsured =
          sumInsured &&
          parseFloat(sumInsured)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      if (frequencyData === '1') {
        premiumTermsValue = 'per Month';
      }
      if (frequencyData === '3') {
        premiumTermsValue = 'per Quarter';
      }
      if (frequencyData === '6') {
        premiumTermsValue = 'per Half Year';
      }
      if (frequencyData === '12') {
        premiumTermsValue = 'per Year';
      }
    }

    return (
      <div>
        {!this.isEmpty(dashboardPolicyData) && (
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="infolist insure-list">
                      {val.shareType ? (
                        <ul>
                          <li>
                            <div className="info-title">Insurer:</div>
                            <div className="info-btn-text">
                              {insurerData ? insurerData.title[intl.locale] : val.insurer}
                              <span className="info-btns" onClick={this.insurerInfo.bind(this, val)}>
                                Info
                              </span>
                            </div>
                          </li>
                          {(val.categoryId === '59b0eb2314ea7f1aafb6d0b6' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ||
                          (val.categoryId === '59b0eb3814ea7f1aafb6d0b7' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ||
                          (val.categoryId === '59b0eaee14ea7f1aafb6d0b5' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ? (
                            <li>
                              Type:{' '}
                              <span className="btnSpan">Other {this.state.type ? this.state.type : val.type}</span>
                            </li>
                          ) : (
                            <li>
                              Type:{' '}
                              <span>
                                {this.state.categoryType === 'Company' ? 'Workplace' : this.state.categoryType}{' '}
                                {this.state.subCategoryType}
                              </span>
                            </li>
                          )}
                          {val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' ? (
                            <li>
                              Policy Number:
                              <span className="btnSpan">{val.policyNumber}</span>
                            </li>
                          ) : (
                            ''
                          )}
                          <li>
                            Adviser Name:
                            <span className="btnSpan">{val.advisorName}</span>
                          </li>
                          <li>
                            Adviser Tel:
                            <span className="btnSpan">{val.advisorPhoneNo}</span>
                          </li>
                          <li>
                            Adviser Email:
                            <span className="btnSpan">{val.advisorEmail}</span>
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          <li>
                            <div className="info-title">Insurer:</div>
                            <div className="info-btn-text">
                              {insurerData ? insurerData.title[intl.locale] : val.insurer}
                              <span className="info-btns" onClick={this.insurerInfo.bind(this, val)}>
                                Info
                              </span>
                            </div>
                          </li>

                          {(val.categoryId === '59b0eb2314ea7f1aafb6d0b6' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ||
                          (val.categoryId === '59b0eb3814ea7f1aafb6d0b7' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ||
                          (val.categoryId === '59b0eaee14ea7f1aafb6d0b5' &&
                            val.subCategoryId === '59b0ed98dae1831be084f807') ? (
                            <li>
                              Type:{' '}
                              <span className="btnSpan">Other {this.state.type ? this.state.type : val.type}</span>
                            </li>
                          ) : (
                            <li>
                              Type:{' '}
                              <span>
                                {this.state.categoryType} {this.state.subCategoryType}
                              </span>
                            </li>
                          )}

                          {val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' ? (
                            <li>
                              Policy Name:
                              <span className="btnSpan">{val.policyName}</span>
                            </li>
                          ) : (
                            <li>
                              Workplace Name:
                              <span className="btnSpan">{val.companyName}</span>
                            </li>
                          )}

                          {val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' && (
                            <li>
                              Policy Number:
                              <span className="btnSpan">{val.policyNumber}</span>
                            </li>
                          )}

                          <li>
                            Currency:
                            <span className="btnSpan">{val.currency}</span>
                          </li>
                          <li className="large-text">
                            <div>
                              {val.categoryId === '59b0eb3814ea7f1aafb6d0b7'
                                ? 'Coverage Amount'
                                : 'Life Coverage Amount'}
                              :
                            </div>
                            <span className="btnSpan">
                              {val.currency} {lifeCoverage}
                            </span>
                          </li>

                          {val.subCategoryId === '59b0ed6fdae1831be084f805' && (
                            <li className="large-text">
                              <div>C.I. Coverage Amount:</div>
                              <span className="btnSpan">
                                {val.currency} {sumInsured}
                              </span>
                            </li>
                          )}

                          {val.subCategoryId === '59b0ed8fdae1831be084f806' ? (
                            <div>
                              <li>
                                Coverage Type:
                                <span className="btnSpan">
                                  {this.state.coverageType ? this.state.coverageType : val.coverageType}
                                </span>
                              </li>
                              <li>
                                Room Class:
                                <span className="btnSpan">
                                  {this.state.roomClass ? this.state.roomClass : val.roomClass}
                                </span>
                              </li>
                            </div>
                          ) : (
                            ''
                          )}

                          <li>
                            Premium:
                            <span className="btnSpan">
                              {val.currency} {this.state.premium ? this.state.premium : premium}
                            </span>
                          </li>

                          <li>
                            Frequency:
                            <span className="btnSpan">{premiumTermsValue}</span>
                          </li>

                          <li>
                            Policy Start Date:
                            <span className="btnSpan">{date}</span>
                          </li>

                          {planDetails && (
                            <div>
                              <li className="large-text">
                                <div>Protection amount in HKD:</div>
                                <span>HKD {protectionAmountInHKD}</span>
                              </li>
                              <li className="large-text">
                                <div>Annual Premium in HKD:</div>
                                <span>HKD {annualPremiumInHKD}</span>
                              </li>

                              {val.categoryId === '59b0eaee14ea7f1aafb6d0b5' ? (
                                <div>
                                  <li>
                                    Feature:
                                    <span className="btnSpan">{feature ? feature : val.feature}</span>
                                  </li>

                                  <li>
                                    Plan Type:
                                    <span className="btnSpan">{planType ? planType : val.planType}</span>
                                  </li>

                                  <li>
                                    Level Premium :
                                    <span className="btnSpan">{levelPremium ? levelPremium : val.levelPremium}</span>
                                  </li>
                                </div>
                              ) : (
                                ''
                              )}

                              {val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' && (
                                <li>
                                  <div className="payment-title">Payment method:</div>
                                  <div className="payment-btn-text">
                                    {paymentMethod ? paymentMethod : val.paymentMethod}
                                    <span className="info-btns" onClick={this.insurerInfo.bind(this, val)}>
                                      Info
                                    </span>
                                  </div>
                                </li>
                              )}

                              {val.subCategoryId !== '59b0ede7dae1831be084f80a' &&
                              val.subCategoryId !== '59b0ed98dae1831be084f807'
                                ? val.categoryId !== '59b0eb2314ea7f1aafb6d0b6' && (
                                    <li>
                                      Policy Owner :
                                      <span className="btnSpan">{policyOwner ? policyOwner : val.policyOwner}</span>
                                    </li>
                                  )
                                : ''}

                              {val.categoryId === '59b0eb3814ea7f1aafb6d0b7' &&
                                val.subCategoryId === '59b0ed98dae1831be084f807' && (
                                  <li>
                                    Coverage detail :
                                    <span className="btnSpan">
                                      {coverageDetail ? coverageDetail : val.coverageDetail}
                                    </span>
                                  </li>
                                )}

                              {val.categoryId === '59b0eaee14ea7f1aafb6d0b5' ? (
                                <div>
                                  <li>
                                    Life Insured :
                                    <span className="btnSpan">{lifeInsured ? lifeInsured : val.lifeInsured}</span>
                                  </li>

                                  <li className="large-text">
                                    <div>Life insured age at policy start:</div>
                                    <span className="btnSpan">
                                      {lifeInsuredAge ? lifeInsuredAge : val.lifeInsuredAge}
                                    </span>
                                  </li>

                                  <li>
                                    Payment Terms:
                                    <span className="btnSpan">{paymentTerms ? paymentTerms : val.paymentTerms}</span>
                                  </li>

                                  <li>
                                    Protection Terms:
                                    <span className="btnSpan">
                                      {protectionTerms ? protectionTerms : val.protectionTerms}
                                    </span>
                                  </li>
                                </div>
                              ) : (
                                <div>
                                  {val.subCategoryId === '59b0edd8dae1831be084f808' ? (
                                    <div>
                                      <li>
                                        Vehicle Brand :
                                        <span className="btnSpan">
                                          {vehicleBrand ? vehicleBrand : val.vehicleBrand}
                                        </span>
                                      </li>
                                      <li>
                                        Vehicle Model:
                                        <span className="btnSpan">
                                          {vehicleModel ? vehicleModel : val.vehicleModel}
                                        </span>
                                      </li>

                                      <li>
                                        Vehicle Year:
                                        <span className="btnSpan">{vehicleYear ? vehicleYear : val.vehicleYear}</span>
                                      </li>

                                      <li>
                                        NCB:
                                        <span className="btnSpan">{ncb ? ncb : val.ncb}</span>
                                      </li>
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  {val.subCategoryId === '59b0eddfdae1831be084f809' && (
                                    <div>
                                      <li>
                                        Home Size :<span className="btnSpan">{homeSize ? homeSize : val.homeSize}</span>
                                      </li>

                                      <li>
                                        {intl.formatMessage({
                                          id: 'Home contents value'
                                        })}
                                        :
                                        <span className="btnSpan">
                                          {homeContentsValue ? homeContentsValue : val.homeContentsValue}
                                        </span>
                                      </li>
                                    </div>
                                  )}

                                  {val.subCategoryId === '59b0ede7dae1831be084f80a' ? (
                                    <div>
                                      <li>
                                        Coverage no. of day :
                                        <span className="btnSpan">{coverageDay ? coverageDay : val.coverageDay}</span>
                                      </li>

                                      <li>
                                        Trip delay cover:
                                        <span className="btnSpan">
                                          {tripDelayCover ? tripDelayCover : val.tripDelayCover}
                                        </span>
                                      </li>

                                      <li>
                                        Loss item cover:
                                        <span className="btnSpan">
                                          {lossItemCover ? lossItemCover : val.lossItemCover}
                                        </span>
                                      </li>

                                      <li>
                                        Mobile phone cover:
                                        <span className="btnSpan">
                                          {mobilePhoneCover ? mobilePhoneCover : val.mobilePhoneCover}
                                        </span>
                                      </li>

                                      <li>
                                        Rental car cover:
                                        <span className="btnSpan">
                                          {rentalCarCover ? rentalCarCover : val.rentalCarCover}
                                        </span>
                                      </li>

                                      <li>
                                        Emergency tel:
                                        <span className="btnSpan">
                                          {emergencyTel ? emergencyTel : val.emergencyTel}
                                        </span>
                                      </li>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              )}

                              {val.categoryId !== '59b0eb3814ea7f1aafb6d0b7' ? (
                                <li>
                                  Beneficiary :
                                  <span className="btnSpan">{beneficial ? beneficial : val.beneficial}</span>
                                </li>
                              ) : (
                                ''
                              )}

                              <li>
                                Adviser Name:
                                <span className="btnSpan">{advisorName ? advisorName : val.advisorName}</span>
                              </li>
                              <li>
                                Adviser Tel:
                                <span className="btnSpan">{advisorPhoneNo ? advisorPhoneNo : val.advisorPhoneNo}</span>
                              </li>
                              <li>
                                Adviser Email:
                                <span className="btnSpan">{advisorEmail ? advisorEmail : val.advisorEmail}</span>
                              </li>
                              {subCategoryType === 'Medical' && (
                                <div>
                                  <li>
                                    SMM:
                                    <span className="btnSpan">{val.smm}</span>
                                  </li>
                                  <li>
                                    Hospital Cash:
                                    <span className="btnSpan">{val.hospitalCash}</span>
                                  </li>
                                  <li>
                                    Outatient:
                                    <span className="btnSpan">{val.outPatient}</span>
                                  </li>
                                  <li>
                                    Dental:
                                    <span className="btnSpan">{val.dental}</span>
                                  </li>
                                </div>
                              )}
                              {subCategoryType === 'Medical' && (
                                <div>
                                  <li>
                                    Deductible:
                                    <span className="btnSpan">{deductible ? deductible : val.deductible}</span>
                                  </li>
                                </div>
                              )}
                              <li>
                                Remark:
                                <span className="btnSpan">{remark ? remark : val.remark}</span>
                              </li>
                              {categoryType === 'Personal' && (
                                <div>
                                  <li>
                                    Orphan Case:
                                    <span className="btnSpan">{orphanCase ? orphanCase : val.orphanCase}</span>
                                  </li>
                                </div>
                              )}
                            </div>
                          )}
                        </ul>
                      )}
                    </div>
                    {!val.shareType && (
                      <div className="insurer-display">
                        <h4 className="display-text" onClick={this.handleDetails.bind(this)}>
                          {planDetails ? 'Hide...' : 'Details...'}
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ViewShareDetails;
