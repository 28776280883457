import React from 'react';
import './NotFound.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

class NotFound extends React.PureComponent {
  reload = () => window.location.reload();

  render() {
    const { intl, history, buttonText, onClick } = this.props;
    const reloadButtonText = intl.formatMessage({ id: 'reload' });
    const formattedOnClick = onClick || (() => history.goBack());
    return (
      <div className="not-found-container">
        <Container className="not-found-wrapper" maxWidth="sm">
          <div>
            <img alt="404" src="img/404Error.svg" />
          </div>
          <Typography color="textSecondary" gutterBottom={true}>
            {intl.formatMessage({ id: '404-reload-hint' }, { buttonText: reloadButtonText })}
          </Typography>
          <Button color="primary" onClick={this.reload}>
            {reloadButtonText}
          </Button>
          <Button color="primary" onClick={formattedOnClick}>
            {buttonText ? buttonText : intl.formatMessage({ id: 'joyride_back' })}
          </Button>
        </Container>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(NotFound);

export default injectIntl(container);
