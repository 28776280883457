import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getPolicies } from '../../../../store/policyPicker/duck';
import _ from 'lodash';
import { usePrevious } from '../../../../utils/hooks';
import { SUCCESS } from '../../../../constants/phase';
import { updateSelectedPolicies } from '../../../../store/analysis/duck';
import {
  ciPolicyPickerDefaultFilter,
  lifePolicyPickerDefaultFilter,
  savingPolicyPickerDefaultFilter
} from '../../../../utils/analysis';

const PolicyPickerDefaultPicked = props => {
  const { viewingClient, policies, getPoliciesPhase, getPolicies, updateSelectedPolicies } = props;
  const prevPolicies = usePrevious(policies);
  const prevGetPoliciesPhase = usePrevious(getPoliciesPhase);
  const setDefaultPoliciesRef = useRef(false);

  useEffect(() => {
    setDefaultPoliciesRef.current = false;
    getPolicies(_.get(viewingClient, '_id'));
  }, [viewingClient, getPolicies]);

  useEffect(() => {
    if (setDefaultPoliciesRef.current === false) {
      if (getPoliciesPhase === SUCCESS && prevGetPoliciesPhase !== SUCCESS && prevGetPoliciesPhase !== undefined) {
        setDefaultPoliciesRef.current = true;
        updateSelectedPolicies({
          lifeSelectedPolicies: policies.filter(lifePolicyPickerDefaultFilter),
          ciSelectedPolicies: policies.filter(ciPolicyPickerDefaultFilter),
          savingSelectedPolicies: policies.filter(savingPolicyPickerDefaultFilter)
        });
      }
    }
  }, [prevGetPoliciesPhase, getPoliciesPhase, policies, updateSelectedPolicies]);

  return null;
};

const container = connect(
  state => ({
    viewingClient: state.client.viewingClient,
    policies: state.policyPicker.policies,
    getPoliciesPhase: state.policyPicker.getPoliciesPhase
  }),
  {
    getPolicies,
    updateSelectedPolicies
  }
)(PolicyPickerDefaultPicked);

export default container;
