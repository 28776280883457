import React from 'react';
import { connect } from 'react-redux';
import MyEditorModal from '../../../../../components/MyEditorModal/MyEditorModal';
import { updateAdviserBlastNotification, updateTeamBlastNotification } from '../../../../../store/notificationCenter/duck';

class MessageEditorModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { intl, open, onClose, updateAdviserBlastNotification, updateTeamBlastNotification, adviserBlast, teamBlast, isTeam } = this.props;
    const blast = isTeam ? teamBlast : adviserBlast;
    return (
      <MyEditorModal
        intl={intl}
        open={open}
        onClose={onClose}
        originalState={blast ? blast.detail : ''}
        contentState={blast ? blast.detail : ''}
        headerText={intl.formatMessage({ id: 'promotion-message' })}
        onUpdate={data => {
          if (isTeam) {
            updateTeamBlastNotification({ key: 'detail', value: data });
            return;
          }
          updateAdviserBlastNotification({ key: 'detail', value: data });
        }}
        imageUsedIn={isTeam ? 'adviser/teamBlast' : 'adviser/promotion'}
        textAlign={true}
        image={true}
        list={true}
        // loadingPhase={loadingPhase}
        // updatePhase={updatePhase}
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    adviserBlast: state.notificationCenter.adviserBlast,
    teamBlast: state.notificationCenter.teamBlast
  }),
  // Map actions to props
  {
    updateAdviserBlastNotification,
    updateTeamBlastNotification
  }
)(MessageEditorModal);

export default container;
