import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';
import * as api from './api';
import { isPolicyCategory } from '../../utils/policy';
import { GET_CLIENT_BY_ID } from '../client/duck';
import { UPDATE_POLICY_REVIEW_INPUTS } from '../policyReview/duck';
import { getUserData } from '../userData/duck';

/***********************************
 * Action Types
 ***********/

export const ADD_POLICY = 'portfoplus/policy/ADD_POLICY';
export const ADD_POLICY_SUCCESS = 'portfoplus/policy/ADD_POLICY_SUCCESS';
export const ADD_POLICY_ERROR = 'portfoplus/policy/ADD_POLICY_ERROR';

export const EDIT_POLICY = 'portfoplus/policy/EDIT_POLICY';
export const EDIT_POLICY_SUCCESS = 'portfoplus/policy/EDIT_POLICY_SUCCESS';
export const EDIT_POLICY_ERROR = 'portfoplus/policy/EDIT_POLICY_ERROR';

export const POLICY_INFO = 'portfoplus/policy/POLICY_INFO';
export const POLICY_INFO_SUCCESS = 'portfoplus/policy/POLICY_INFO_SUCCESS';
export const POLICY_INFO_ERROR = 'portfoplus/policy/POLICY_INFO_ERROR';

export const DELETE_POLICY = 'portfoplus/policy/DELETE_POLICY';
export const DELETE_POLICY_SUCCESS = 'portfoplus/policy/DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_ERROR = 'portfoplus/policy/DELETE_POLICY_ERROR';

export const MOVE_POLICY = 'portfoplus/policy/MOVE_POLICY';
export const MOVE_POLICY_SUCCESS = 'portfoplus/policy/MOVE_POLICY_SUCCESS';
export const MOVE_POLICY_ERROR = 'portfoplus/policy/MOVE_POLICY_ERROR';

export const UPLOAD_DOCUMENT = 'portfoplus/policy/UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'portfoplus/policy/UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'portfoplus/policy/UPLOAD_DOCUMENT_ERROR';

export const FETCH_POLICY = 'portfoplus/policy/FETCH_POLICY';
export const FETCH_POLICY_SUCCESS = 'portfoplus/policy/FETCH_POLICY_SUCCESS';
export const FETCH_POLICY_ERROR = 'portfoplus/policy/FETCH_POLICY_ERROR';

export const FETCH_ME_POLICY = 'portfoplus/policy/FETCH_ME_POLICY';
export const FETCH_ME_POLICY_SUCCESS = 'portfoplus/policy/FETCH_ME_POLICY_SUCCESS';
export const FETCH_ME_POLICY_ERROR = 'portfoplus/policy/FETCH_ME_POLICY_ERRO';

export const GET_PORTFOLIO_JSON = 'portfoplus/policy/GET_PORTFOLIO_JSON';
export const GET_PORTFOLIO_JSON_SUCCESS = 'portfoplus/policy/GET_PORTFOLIO_JSON_SUCCESS';
export const GET_PORTFOLIO_JSON_ERROR = 'portfoplus/policy/GET_PORTFOLIO_JSON_ERROR';

export const CATEGORY_DATA = 'portfoplus/policy/CATEGORY_DATA';
export const SUB_CATEGORY_DATA = 'portfoplus/policy/SUB_CATEGORY_DATA';

export const DASHBOARD_SUB_CATEGORY_DATA = 'portfoplus/policy/DASHBOARD_SUB_CATEGORY_DATA';
export const DASHBOARD_POLICY_DATA = 'portfoplus/policy/DASHBOARD_POLICY_DATA';

export const INSURER_INFO = 'portfoplus/policy/INSURER_INFO';
export const INSURER_INFO_SUCCESS = 'portfoplus/policy/INSURER_INFO_SUCCESS';
export const INSURER_INFO_ERROR = 'portfoplus/policy/INSURER_INFO_ERROR';

export const INIT_PHASE = 'portfoplus/policy/INIT_PHASE';
export const UPDATE_SELECTED_PORTFOLIO = 'policy/UPDATE_SELECTED_PORTFOLIO';
export const RESET_CATEGORIES_PORTFOLIO = 'RESET_CATEGORIES_PORTFOLIO';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated

  phase: INIT,
  policyPhase: INIT,
  uploadPhase: INIT,
  fetchPhase: INIT,
  resetPhase: INIT,
  categoryPhase: INIT,
  policyInfoPhase: INIT,
  policyData: [],
  categoryData: [],
  subCategoryData: [],
  dashboardCategoryData: [],
  dashboardSubCategoryData: [],
  dashboardPolicyData: {},
  policyInfoData: {},
  JsonData: [],
  insurers: [],
  error: null,
  isSubmitting: false,
  edit: null,
  settings: null,
  clientPayOptions: 'Protection',
  payOptions: 'Protection',
  forexCurrency: [],
  portfolio: [],
  categories: [],
  chartData: [],
  chartPhase: false,
  premiumView: '',
  personalChartData: [],
  companyChartData: [],
  generalChartData: [],
  generalCategoryPayOptions: 'Premium',
  generalCategoryPremiumView: 'Monthly',
  generalCategoryClientPayOption: 'Premium',
  payOptionsPhase: INIT,
  currentPortfolioId: undefined,
  factsheetData: [],
  // policy action status
  movePolicyStatus: ''
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case ADD_POLICY: {
      return state
        .set('policyPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADD_POLICY_SUCCESS: {
      const { payload } = action;
      return state
        .set('phase', payload.success)
        .set('policyPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case ADD_POLICY_ERROR: {
      return state.set('policyPhase', ERROR);
    }

    case EDIT_POLICY: {
      return state
        .set('policyInfoPhase', INIT)
        .set('policyPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }
    case EDIT_POLICY_SUCCESS: {
      const { payload } = action;
      return state
        .set('phase', payload.success)
        .set('policyPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case EDIT_POLICY_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('policyPhase', ERROR);
    }

    case POLICY_INFO: {
      return state
        .set('policyInfoPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case POLICY_INFO_SUCCESS: {
      const { payload } = action;
      return state
        .set('policyInfoData', payload.data) // update data used in addPolicy view
        .set('dashboardPolicyData', payload.data) // update data used in InsuredDetails view
        .set('policyInfoPhase', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case POLICY_INFO_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('policyInfoPhase', ERROR);
    }

    case DELETE_POLICY: {
      return state.set('error', null).set('isSubmitting', true);
    }
    case DELETE_POLICY_SUCCESS: {
      return state.set('error', null).set('isSubmitting', false);
    }

    case DELETE_POLICY_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error);
    }

    case MOVE_POLICY: {
      return state.set('movePolicyStatus', 'processing');
    }

    case MOVE_POLICY_SUCCESS: {
      return state.set('movePolicyStatus', 'success');
    }

    case MOVE_POLICY_ERROR: {
      const { payload } = action;
      return state.set('movePolicyStatus', 'error');
    }

    case UPLOAD_DOCUMENT: {
      return state
        .set('uploadPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPLOAD_DOCUMENT_SUCCESS: {
      return state
        .set('uploadPhase', SUCCESS)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case UPLOAD_DOCUMENT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('uploadPhase', ERROR);
    }

    case FETCH_POLICY: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
        .set('policyPhase', INIT)
        .set('categoryPhase', INIT);
    }

    case FETCH_POLICY_SUCCESS: {
      const { payload } = action;
      return state
        .set('policyData', payload.data)
        .set('forexCurrency', payload.forexCurrency)
        .set('categories', payload.categories)
        .set('portfolio', payload.portfolio)
        .set('fetchPhase', SUCCESS)
        .set('error', null)
        .set('factsheetData', payload.factsheetData);
    }

    case FETCH_POLICY_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchPhase', ERROR);
    }

    case FETCH_ME_POLICY: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true)
        .set('policyPhase', INIT)
        .set('categoryPhase', INIT);
    }

    case FETCH_ME_POLICY_SUCCESS: {
      const { payload } = action;
      return state
        .set('policyData', payload.data)
        .set('forexCurrency', payload.forexCurrency)
        .set('categories', payload.categories)
        .set('portfolio', payload.portfolio)
        .set('fetchPhase', SUCCESS)
        .set('error', null);
    }

    case FETCH_ME_POLICY_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchPhase', ERROR);
    }

    case GET_PORTFOLIO_JSON: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_PORTFOLIO_JSON_SUCCESS: {
      const { payload } = action;
      return state
        .set('JsonData', payload.data)
        .set('fetchPhase', SUCCESS)
        .set('error', null);
    }

    case GET_PORTFOLIO_JSON_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchPhase', ERROR);
    }

    case CATEGORY_DATA: {
      const { payload } = action;
      return state.set('categoryData', payload);
    }

    case SUB_CATEGORY_DATA: {
      const { payload } = action;
      return state.set('subCategoryData', payload);
    }
    case DASHBOARD_SUB_CATEGORY_DATA: {
      const { payload } = action;
      return state.set('dashboardSubCategoryData', payload);
    }

    case DASHBOARD_POLICY_DATA: {
      const { payload } = action;
      return state.set('dashboardPolicyData', payload);
    }

    case INSURER_INFO: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case INSURER_INFO_SUCCESS: {
      const { payload } = action;
      return state.set('insurers', payload.data).set('error', null);
    }

    case INSURER_INFO_ERROR: {
      const { error } = action.payload;
      return state.set('error', error);
    }

    case INIT_PHASE: {
      return state
        .set('premiumView', '')
        .set('generalCategoryClientPayOption', 'Premium')
        .set('generalCategoryPremiumView', 'Monthly');
    }

    case UPDATE_SELECTED_PORTFOLIO: {
      return state.set('currentPortfolioId', action.payload);
    }

    case RESET_CATEGORIES_PORTFOLIO: {
      return state.set('categories', []).set('portfolio', []);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const addPolicy = (data, userType) => {
  return {
    type: ADD_POLICY,
    payload: { data, userType }
  };
};

export const editPolicy = (data, clientId) => {
  return {
    type: EDIT_POLICY,
    payload: { data, clientId }
  };
};

export const policyInfo = data => ({
  type: POLICY_INFO,
  payload: data
});

export const deletePolicy = (data, clientId) => {
  return {
    type: DELETE_POLICY,
    payload: { data, clientId }
  };
};

export const movePolicy = (data, originalClientId) => {
  return {
    type: MOVE_POLICY,
    payload: { data, originalClientId }
  };
};

export const uploadDocument = data => {
  return {
    type: UPLOAD_DOCUMENT,
    payload: { data }
  };
};

export const fetchPolicy = data => ({
  type: FETCH_POLICY,
  payload: data
});

export const fetchMePolicy = data => ({
  type: FETCH_ME_POLICY,
  payload: data
});

export const getPortfolioJson = () => ({
  type: GET_PORTFOLIO_JSON
});

export const handleCategoryData = data => ({
  type: CATEGORY_DATA,
  payload: data
});

export const handleSubCategoryData = data => ({
  type: SUB_CATEGORY_DATA,
  payload: data
});

export const handleDashboardSubCategoryData = data => ({
  type: DASHBOARD_SUB_CATEGORY_DATA,
  payload: data
});

export const handleDashboardPolicyData = data => ({
  type: DASHBOARD_POLICY_DATA,
  payload: data
});

export const insurerInfo = () => ({
  type: INSURER_INFO
});

export const initPremiumViewPhase = () => ({
  type: INIT_PHASE
});

export const updateSelectedPortfolio = targetShareId => ({
  type: UPDATE_SELECTED_PORTFOLIO,
  payload: targetShareId
});

/***********************************
 * Epics
 ***********/

const addPolicyEpic = (action$, state$) =>
  action$.ofType(ADD_POLICY).mergeMap(action => {
    return Rx.Observable.fromPromise(api.addPolicy(action.payload.data))
      .flatMap(payload => {
        state$.dispatch(getUserData());
        const result = [
          {
            type: ADD_POLICY_SUCCESS,
            payload
          }
        ];

        if (action.payload.userType === 'Adviser') {
          const clientId = action.payload.data.userId || payload.data.userId;
          result.push({ type: GET_CLIENT_BY_ID, payload: { clientId: clientId } });
        }

        return result;
      })
      .catch(error => {
        console.log(error);
        return Rx.Observable.of({
          type: ADD_POLICY_ERROR,
          payload: { error: error.toString() }
        });
      });
  });

const editPolicyEpic = (action$, state$) =>
  action$.ofType(EDIT_POLICY).mergeMap(action => {
    const policyId = action.payload.data.id;
    const clientId = action.payload.clientId;
    return Rx.Observable.fromPromise(api.editPolicy(action.payload.data))
      .flatMap(payload => {
        state$.dispatch(getUserData());
        const result = [
          {
            type: EDIT_POLICY_SUCCESS,
            payload
          },
          {
            type: POLICY_INFO,
            payload: policyId
          }
        ];

        if (clientId) {
          result.push({ type: GET_CLIENT_BY_ID, payload: { clientId: clientId } });
        }

        return result;
      })
      .catch(error =>
        Rx.Observable.of({
          type: EDIT_POLICY_ERROR,
          payload: { error }
        })
      );
  });

const policyInfoEpic = action$ =>
  action$.ofType(POLICY_INFO).mergeMap(action => {
    return Rx.Observable.fromPromise(api.policyInfo(action.payload))
      .map(payload => ({
        type: POLICY_INFO_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: POLICY_INFO_ERROR,
          payload: { error }
        })
      );
  });

const deletePolicyEpic = action$ =>
  action$.ofType(DELETE_POLICY).mergeMap(action => {
    const clientId = action.payload.clientId;
    return Rx.Observable.fromPromise(api.deletePolicy(action.payload.data))
      .flatMap(payload => {
        const result = [
          {
            type: DELETE_POLICY_SUCCESS,
            payload
          }
        ];

        if (clientId) {
          result.push({ type: GET_CLIENT_BY_ID, payload: { clientId: clientId } });
        }

        return result;
      })
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_POLICY_ERROR,
          payload: { error }
        })
      );
  });

const movePolicyEpic = action$ =>
  action$.ofType(MOVE_POLICY).mergeMap(action => {
    const originalClientId = action.payload.originalClientId;
    return Rx.Observable.fromPromise(api.movePolicy(action.payload.data))
      .flatMap(payload => {
        const result = [
          {
            type: MOVE_POLICY_SUCCESS,
            payload
          }
        ];

        if (action.payload.data.clientId) {
          result.push({ type: GET_CLIENT_BY_ID, payload: { clientId: action.payload.data.clientId } });
        }

        if (originalClientId) {
          result.push({ type: GET_CLIENT_BY_ID, payload: { clientId: originalClientId } });
        }

        return result;
      })
      .catch(error =>
        Rx.Observable.of({
          type: MOVE_POLICY_ERROR,
          payload: { error: error.message }
        })
      );
  });

const uploadDocumentEpic = action$ =>
  action$.ofType(UPLOAD_DOCUMENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.uploadDocument(action.payload.data))
      .flatMap(payload => [
        {
          type: UPLOAD_DOCUMENT_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPLOAD_DOCUMENT_ERROR,
          payload: { error }
        })
      );
  });

const fetchPolicyEpic = action$ =>
  action$.ofType(FETCH_POLICY).mergeMap(action => {
    return Rx.Observable.fromPromise(api.fetchPolicy(action.payload))
      .flatMap(payload => {
        const { portfolio } = payload;
        let foundCompany = false;
        (portfolio || []).forEach(policy => {
          if (isPolicyCategory(policy, 'company')) {
            foundCompany = true;
          }
        });

        return [
          {
            type: FETCH_POLICY_SUCCESS,
            payload
          },
          {
            type: UPDATE_POLICY_REVIEW_INPUTS,
            key: 'selectCompany',
            value: foundCompany
          }
        ];
      })
      .catch(error =>
        Rx.Observable.of({
          type: FETCH_POLICY_ERROR,
          payload: { error }
        })
      );
  });

const fetchMePolicyEpic = action$ =>
  action$.ofType(FETCH_ME_POLICY).mergeMap(action => {
    return Rx.Observable.fromPromise(api.fetchMePolicy(action.payload))
      .map(payload => ({
        type: FETCH_ME_POLICY_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: FETCH_ME_POLICY_ERROR,
          payload: { error }
        })
      );
  });

const getPortfolioJsonEpic = action$ =>
  action$.ofType(GET_PORTFOLIO_JSON).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getPortfolioJson())
      .map(payload => ({
        type: GET_PORTFOLIO_JSON_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_PORTFOLIO_JSON_ERROR,
          payload: { error }
        })
      );
  });

const insurerInfoEpic = action$ =>
  action$.ofType(INSURER_INFO).mergeMap(() => {
    return Rx.Observable.fromPromise(api.insurerInfo())
      .map(payload => ({
        type: INSURER_INFO_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: INSURER_INFO_ERROR,
          payload: { error }
        })
      );
  });

export const policyEpic = combineEpics(
  addPolicyEpic,
  editPolicyEpic,
  policyInfoEpic,
  deletePolicyEpic,
  movePolicyEpic,
  uploadDocumentEpic,
  fetchPolicyEpic,
  getPortfolioJsonEpic,
  insurerInfoEpic,
  fetchMePolicyEpic
);
