import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Grid, Slide, Snackbar, Typography } from '@material-ui/core';
import { isAdviserType } from '../../utils/user';
import { toggleControl } from '../../store/control/duck';
import { android, iOS, safari, chrome, samsungBrowser, isStandaloneMode, mobile } from '../../utils/device';
import NativeOrWeb from "../../utils/native";

const Style = {
  top: 'calc(45px + var(--top-padding))',
  width: '100%',
  left: 0,
  right: 0,
  zIndex: 1201,
  transform: 'unset'
};

const ContentStyle = {
  borderRadius: 0,
  background: '#0e047d',
  width: '100%'
};

const ImageStyle = {
  width: 35,
  borderRadius: 4
};

const ButtonStyle = {
  color: '#fff'
};

const AddToHomePageSnackbar = props => {
  const { intl, userDetails, addToHomeSnackbar, toggleControl } = props;

  if (!(!isStandaloneMode() && !sessionStorage.getItem('pwaInstalled') && !NativeOrWeb.isNativePlatform())) {
    return null;
  }

  const tutorial = () => {
    let target;
    if (iOS()) {
      if (safari()) {
        target =
          'https://w3.portfoplus.com/%e6%8c%89%e9%8d%b5%e5%8a%a0%e5%85%a5%e4%b8%bb%e9%a0%81-iphone-ipad%e7%89%88-safari%e7%80%8f%e8%a6%bd%e5%99%a8-%e6%95%99%e5%ad%b8/';
      } else {
        target =
          'https://w3.portfoplus.com/%e6%8c%89%e9%8d%b5%e5%8a%a0%e5%85%a5%e4%b8%bb%e9%a0%81-iphone-ipad%e7%89%88-chrome%e7%80%8f%e8%a6%bd%e5%99%a8-%e6%95%99%e5%ad%b8/';
      }
    } else if (android()) {
      if (chrome()) {
        target =
          'https://w3.portfoplus.com/%e6%8c%89%e9%8d%b5%e5%8a%a0%e5%85%a5%e4%b8%bb%e9%a0%81-android%e7%89%88-chrome%e7%80%8f%e8%a6%bd%e5%99%a8-%e6%95%99%e5%ad%b8/';
      } else if (samsungBrowser()) {
        target =
          'https://w3.portfoplus.com/%e6%8c%89%e9%8d%b5%e5%8a%a0%e5%85%a5%e4%b8%bb%e9%a0%81-android%e7%89%88-samsung%e7%80%8f%e8%a6%bd%e5%99%a8-%e6%95%99%e5%ad%b8/';
      }
    } else {
      target =
        'https://w3.portfoplus.com/%e6%8c%89%e9%8d%b5%e5%8a%a0%e5%85%a5%e4%b8%bb%e9%a0%81-%e6%a1%8c%e9%9d%a2%e7%89%88-chrome%e7%80%8f%e8%a6%bd%e5%99%a8-%e6%95%99%e5%ad%b8/';
    }
    window.open(target, '_blank');
  };
  const onClose = () => toggleControl('addToHomeSnackbar', false);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!addToHomeSnackbar}
      ContentProps={{
        message: (
          <Grid container alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item>
              <img src="img/addtohomepage.png" alt="portfoplus" style={ImageStyle} />
            </Grid>
            <Grid item>
              <Typography color="textPrimary">
                {intl.formatMessage({ id: isAdviserType(userDetails) ? 'add-to-home-adv' : 'add-to-home' })}
              </Typography>
            </Grid>
          </Grid>
        ),
        action: (
          <Fragment>
            <Button onClick={tutorial} style={ButtonStyle}>
              <Typography color="textPrimary">{intl.formatMessage({ id: 'add-to-home-tut' })}</Typography>
            </Button>
            <Button onClick={onClose} style={ButtonStyle}>
              <Typography color="textPrimary">{intl.formatMessage({ id: 'add-to-home-ok' })}</Typography>
            </Button>
          </Fragment>
        ),
        style: ContentStyle
      }}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' }}
      style={Style}
    />
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    addToHomeSnackbar: state.control.addToHomeSnackbar
  }),
  {
    toggleControl
  }
)(AddToHomePageSnackbar);

export default injectIntl(container);
