import React, { Fragment, useState, useEffect, useRef, useImperativeHandle, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import MoreInfoIconButton from '../MoreInfoIconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './TopNavHelpButton.scss';
import Typography from '@material-ui/core/Typography';
import { ShortSubscriptions } from '../../utils/user-subscription';
// import ExtendFreeTrialDialog from '../ExtendFreeTrialDialog/ExtendFreeTrialDialog';
import { usePrevious } from '../../utils/hooks';
import { ZoomTransition } from '../Transition/Transition';
import _ from 'lodash';
import AdviserHelpDialog from '../AdviserHelpDialog/AdviserHelpDialog';
import { withRouterInnerRef } from '../../utils/router';
import { tracking } from '../../utils/tracking';
import { getContentJson, getContentLink, getLocalContent } from '../StandardContentComponents/utils';
import IntroPage from '../AdviserHelpDialog/partials/Intro';
import Help from '../AdviserHelpDialog/partials/Help';
import ShareMessage from '../AdviserHelpDialog/partials/ShareMessage';
import { toggleUserMissionPopover } from '../../store/userMission/duck';

const TopNavHelpButton = React.forwardRef((props, ref) => {
  const { intl, history, subscriptions, onSelectCurrentTutorial, open, buttonProps, toggleUserMissionPopover } = props;

  const buttonRef = useRef(null);

  const [internalOpen, setInternalOpen] = useState(false);
  // const [freeTrialDialog, setFreeTrialDialog] = useState(false);
  const [adviserHelpDialog, setAdviserHelpDialog] = useState(false);

  const [contents, setContents] = useState([]);
  const [message, setMessage] = useState(intl.formatMessage({ id: 'share-message-option-1-content' }));

  const previousInternalOpen = usePrevious(internalOpen);
  const previousOpen = usePrevious(open);

  const isFreeTrial = new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();

  const fetchData = async () => {
    const data = await getContentJson({
      contentLink: getContentLink('help-center'),
      localContent: process.env.NODE_ENV === 'development' && getLocalContent('help-center')
    });
    setContents(data);
  };

  // const webinar = () => {
  //   tracking('Click on Join Webinar - Homepage');
  //   window.open('https://forms.gle/qLhbW3M8P8CF2pYD9', '_blank');
  // };

  const getAdviserHelpDialogItems = useCallback(
    () => [
      {
        title: 'adviser-help-modal-title-1',
        component: setIndex => <IntroPage setIndex={setIndex} intl={intl} />
      },
      {
        title: 'adviser-help-intro-question-1',
        component: () => (
          <Help intl={intl} content={contents[1]} index={1} title={intl.formatMessage({ id: 'adviser-help-title' })} />
        )
      },
      {
        title: 'adviser-help-intro-question-2',
        component: () => (
          <Help
            intl={intl}
            content={contents[2]}
            index={3}
            title={intl.formatMessage({ id: 'adviser-Step2-help-title' })}
          />
        )
        // rightButton: () => (
        //   <Button style={{ width: 105 }} color="primary" onClick={webinar}>
        //     {intl.formatMessage({ id: 'free-webinar' })}
        //   </Button>
        // )
      },
      {
        title: 'adviser-help-intro-question-3',
        component: () => <ShareMessage intl={intl} setMessage={setMessage} message={message} />
      }
    ],
    [contents, message]
  );

  const openDialog = () => {
    tracking('Click on Top Navigation Help Button');
    setInternalOpen(true);
  };
  const closeDialog = () => setInternalOpen(false);
  const selectExtendFreeTrial = isFreeTrial
    ? () => {
        tracking('Click on Free-Trial Extension on Top Navigation Help Dialog');

        toggleUserMissionPopover(true, 'free-trial');
      }
    : undefined;
  // const closeExtendFreeTrialDialog = () => setFreeTrialDialog(false);
  const selectAdviserHelp = () => {
    tracking("Click on Newbies' Tour on Top Navigation Help Dialog");

    setAdviserHelpDialog(true);
  };
  const closeAdviserHelpDialog = () => setAdviserHelpDialog(false);
  const selectCurrentTutorial = onSelectCurrentTutorial
    ? () => {
        tracking('Click on Current Page Tutorial on Top Navigation Help Dialog');
        closeDialog();
        onSelectCurrentTutorial();
      }
    : undefined;
  const selectHelpCenter = () => {
    tracking('Click on Help Center on Top Navigation Help Dialog');

    closeDialog();
    history.push('/new_help_center');
  };

  useEffect(() => {
    if (open !== previousOpen) {
      setInternalOpen(!!open);
    }
  }, [open, previousOpen]);

  useEffect(() => {
    if (open !== internalOpen) {
      setInternalOpen(!!(previousInternalOpen !== internalOpen ? internalOpen : open));
    }
  }, [open, internalOpen, previousInternalOpen]);

  useImperativeHandle(ref, () => ({
    open: () => setInternalOpen(true),
    // openExtendFreeTrial: () => setFreeTrialDialog(true),
    openAdviserHelpDialog: () => setAdviserHelpDialog(true)
  }));

  useEffect(() => {
    fetchData();
    return () => null;
  }, []);

  return (
    <Fragment>
      <MoreInfoIconButton forwardedRef={buttonRef} handleClick={openDialog} {...buttonProps} />
      {/*<ExtendFreeTrialDialog open={freeTrialDialog} onClose={closeExtendFreeTrialDialog} />*/}
      <AdviserHelpDialog
        intl={intl}
        open={adviserHelpDialog}
        onClose={closeAdviserHelpDialog}
        items={getAdviserHelpDialogItems()}
      />
      <Dialog
        className="top-nav-help-dialog"
        open={internalOpen}
        onClose={closeDialog}
        TransitionComponent={ZoomTransition}
        TransitionProps={{
          triggerTarget: _.get(buttonRef, 'current')
        }}
      >
        <DialogTitle>{intl.formatMessage({ id: 'top-nav-help-title' })}</DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container direction="column">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={true}
                startIcon={<img src="img/help/stepbystep.svg" alt="go-to-help-center" />}
                onClick={selectAdviserHelp}
              >
                <Typography color="textSecondary">{intl.formatMessage({ id: 'newbie-tour' })}</Typography>
              </Button>
            </Grid>
            {selectExtendFreeTrial && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  startIcon={<img src="img/help/extendtrial.png" alt="extend-free-trial" />}
                  onClick={selectExtendFreeTrial}
                >
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'extend-free-trial' })}</Typography>
                </Button>
              </Grid>
            )}
            {selectCurrentTutorial && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  fullWidth={true}
                  startIcon={<img src="img/help/walkthru.svg" alt="current-page-tutorial" />}
                  onClick={selectCurrentTutorial}
                >
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'current-page-tutorial' })}</Typography>
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={true}
                startIcon={<img src="img/help/popuphelp.svg" alt="go-to-help-center" />}
                onClick={selectHelpCenter}
              >
                <Typography color="textSecondary">{intl.formatMessage({ id: 'go-help-center' })}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
});

const container = connect(
  state => ({
    subscriptions: state.userSubscription.subscriptions
  }),
  {
    toggleUserMissionPopover
  },
  null,
  {
    forwardRef: true
  }
)(TopNavHelpButton);

export default withRouterInnerRef(injectIntl(container, { withRef: true }), { withRef: true });
