import React from 'react';
import { makeStyles } from '@material-ui/styles';
import MySlider from '../../../../components/MySlider/MySlider';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -9,
    marginLeft: -11
  },
  track: {
    height: 8
  },
  rail: {
    height: 8
  }
});

const InputPanelSlider = props => {
  const { ...rest } = props;
  const classes = useStyles(props);
  return <MySlider classes={classes} {...rest} />;
};

export default InputPanelSlider;
