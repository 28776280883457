import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Preferences } from '@capacitor/preferences';
import { CapacitorSilentNotifications } from 'capacitor-plugin-silent-notifications';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { App } from '@capacitor/app';
import Backend from '../../backend';
import store from '../../store';
import { toggleDialog } from '../../store/control/duck';
import { getBrowserDefaultLanguage } from '../locale';
import { processInternalRoute } from './utils';
import { setBadge } from './badge';

const platform = Capacitor.getPlatform();
const PUBLIC_URL = process.env.PUBLIC_URL;
var listener = null;

export const getPushNotificationToken = async () => {
    try {
        const storageResult = await Preferences.get({ key: 'pushToken' });
        return storageResult.value;
    } catch (error) {
        console.error('Error getting push notification token from storage:', error);
    }
};

const processPushNotificationAction = notificationAction => {
    const { notification } = notificationAction || {};
    let data = notification?.data || {};

    if (platform === 'ios') {
        data = notification.data.data;
    } else if (platform === 'android' && data.pinpoint) {
        if (typeof data.pinpoint === 'string') {
            data.pinpoint = JSON.parse(data.pinpoint);
        }
    }

    const { pinpoint } = data;
    const { deeplink } = pinpoint || {};
    if (deeplink) {
        const hostnames = [PUBLIC_URL, 'app.portfoplus.com', 'beta-app.portfoplus.com'];
        const foundHostname = hostnames.some(hostname => deeplink.includes(hostname));

        if (foundHostname) {
            processInternalRoute(deeplink);
        } else {
            window.open(deeplink, '_blank');
        }
    }
};

const addListener = () => {
    LocalNotifications.addListener('localNotificationActionPerformed', processPushNotificationAction);
    PushNotifications.addListener('pushNotificationActionPerformed', processPushNotificationAction);
    CapacitorSilentNotifications.addListener('silentNotificationReceived', notification => {
        const { data } = notification || {};
        const { jsonBody } = data || {};
        if (jsonBody?.isSilent && jsonBody.type === 'badge') {
            setBadge(jsonBody.badgeCount);
        }
    });
    PushNotifications.addListener('registration', async token => {
        if (token?.value) {
            Preferences.set({ key: 'pushToken', value: token.value });

            const appInfo = await App.getInfo();
            const locale = getBrowserDefaultLanguage();
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            await fetch(Backend.methodUrl('endpoints/register'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: token.value,
                    appVersion: appInfo.version,
                    locale: locale,
                    timezone: timezone,
                    nativePlatform: platform
                }),
                credentials: 'include'
            });
        }
    });
}

const registerPushNotification = async () => {
    try {
        await PushNotifications.register();
        addListener();
    } catch (error) {
        console.error('Error registering push notifications:', error);
    }
};

export const unregisterPushNotification = async () => {
    try {
        const token = await getPushNotificationToken();
        if (token) {
            await PushNotifications.unregister();
            let result = await fetch(Backend.methodUrl('endpoints/unregister'), {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token }),
                credentials: 'include'
            });
            result = await result.json();
            await Preferences.remove({ key: 'pushToken' });
        }
    } catch (error) {
        console.error('Error unregistering push notifications:', error);
    }
};

// Add app state listener for IOS
async function addAppStateListener(setPermissionGranted) {
    async function handleAppStateChange(state) {
        if (state.isActive) {
            if (listener) {
                listener.remove();
                listener = null
            }

            const pushNotificationPermissionStatus = await PushNotifications.checkPermissions();
            const localNotificationPermissionStatus = await LocalNotifications.checkPermissions();
            if (pushNotificationPermissionStatus.receive === 'granted' && localNotificationPermissionStatus.display === 'granted') {
                if (setPermissionGranted) {
                    setPermissionGranted(true);
                }
                await registerPushNotification();
            }
        }
    }

    if (listener) {
        listener.remove();
        listener = null
    }
    listener = await App.addListener('appStateChange', handleAppStateChange);
}

export async function requestPermissions(initialAttempt, setPermissionGranted = null) {
    let permissionGranted = false;
    let pushNotificationPermissionStatus = await PushNotifications.checkPermissions();
    let localNotificationPermissionStatus = await LocalNotifications.checkPermissions();
    let iosWillShowPrompt = pushNotificationPermissionStatus.receive === 'prompt' && localNotificationPermissionStatus.display === 'prompt';

    if (pushNotificationPermissionStatus.receive === 'granted' && localNotificationPermissionStatus.display === 'granted') {
        permissionGranted = true;
    } else {
        pushNotificationPermissionStatus = await PushNotifications.requestPermissions();
        if (pushNotificationPermissionStatus.receive === 'granted') {
            localNotificationPermissionStatus = await LocalNotifications.requestPermissions();
        }

        if (pushNotificationPermissionStatus.receive === 'granted' && localNotificationPermissionStatus.display === 'granted') {
            permissionGranted = true;
        } else if (!initialAttempt || !iosWillShowPrompt) {
            // Ask the user to go to settings
            await NativeSettings.open({
                optionAndroid: AndroidSettings.AppNotification,
                optionIOS: IOSSettings.App
            });

            if (platform === 'android') {
                pushNotificationPermissionStatus = await PushNotifications.checkPermissions();
                localNotificationPermissionStatus = await LocalNotifications.checkPermissions();
                if (pushNotificationPermissionStatus.receive === 'granted' && localNotificationPermissionStatus.display === 'granted') {
                    permissionGranted = true;
                }
            } else if (platform === 'ios') {
                addAppStateListener(setPermissionGranted);
            }
        }
    }

    if (permissionGranted) {
        if (setPermissionGranted) {
            setPermissionGranted(true);
        }
        await registerPushNotification();
        if (platform === 'android') {
            PushNotifications.addListener('pushNotificationReceived', async notification => {
                try {
                    const pinpointData = notification.data.pinpoint || {};
                    const title = notification.title || pinpointData.title;
                    const body = notification.body || pinpointData.body;
                    const data = notification.data || pinpointData.data;

                    if (title || body) {
                        await LocalNotifications.schedule({
                            notifications: [
                                {
                                    smallIcon: 'ic_stat_logo',
                                    iconColor: '#3966f8',
                                    id: new Date().getTime(),
                                    title: title,
                                    body: body,
                                    data: data,
                                    actionTypeId: 'local-push-notification'
                                }
                            ]
                        });
                    }
                } catch (error) {
                    console.error('Error handling push notification on Android:', error);
                }
            });
        }
    }
};

export const checkAndRequestPermissions = async (signIn) => {
    const permissionsKey = 'permissionsRequested';
    const { value: permissionsRequested } = await Preferences.get({ key: permissionsKey });

    if (Capacitor.isNativePlatform()) {
        if (!permissionsRequested) {
            store.dispatch(
                toggleDialog('permission', {
                    intlId: 'push-notification-required-msg',
                    onClose: async () => {
                        await requestPermissions(true);
                        await Preferences.set({
                            key: permissionsKey,
                            value: 'true'
                        });
                    }
                })
            );
        } else {
            addListener();

            if (signIn) {
                let token = await getPushNotificationToken();
                if (token) {
                    await fetch(Backend.methodUrl('endpoints/signIn'), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ token }),
                        credentials: 'include'
                    });
                }
            }
        }
    }
};

checkAndRequestPermissions(false);