import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { toggleDialog } from '../../../store/control/duck';
import { CPI_WORDINGS, BORDER_COLORS } from '../ClientEntry';

class CPIHintDialog extends React.PureComponent {
  close = () => {
    const { toggleDialog, CPIHintDialog } = this.props;
    const { onClose } = CPIHintDialog;
    toggleDialog('CPIHint', { ...CPIHintDialog, closed: true });
    if (onClose) onClose();
  };
  closed = () => this.props.toggleDialog('CPIHint', false);

  render() {
    const { intl, CPIHintDialog } = this.props;

    if (!CPIHintDialog) return null;

    const { closed } = CPIHintDialog;

    return (
      <Dialog
        open={!!CPIHintDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'Client_Potential_Index' })}</DialogTitle>
        <DialogContent>
          <div className="client-base-modal-content">
            <p>
              {intl.formatMessage({
                id: 'C.P.I_indicates'
              })}
            </p>
          </div>
          <div className="client-base-modal-content">
            <p>
              {intl.formatMessage({
                id: 'The_top_indicator'
              })}{' '}
            </p>
          </div>
          <div className="client-base-modal-content">
            <p>
              {intl.formatMessage({
                id: 'Indicator_attach'
              })}
            </p>
          </div>
          <div className="client-base-modal-colors">
            {CPI_WORDINGS.map(function(value, index) {
              return (
                <div
                  key={index}
                  style={{
                    borderLeft: '20px solid ' + BORDER_COLORS[index]
                  }}
                >
                  {intl.formatMessage({ id: value })}
                </div>
              );
            })}
          </div>
          <div>
            <p>
              {intl.formatMessage({
                id: 'At_least'
              })}
              <br />
              {intl.formatMessage({
                id: 'C.P.I_is_for_reference'
              })}
              <br />
              {intl.formatMessage({
                id: 'A.I._engine'
              })}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    CPIHintDialog: state.control.CPIHintDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(CPIHintDialog);

export default injectIntl(container);
