import { combineEpics } from 'redux-observable';
import * as api from './api';

/***********************************
 * Action Types
 ***********/

/***********************************
 * Initial State
 ***********/

const InitialState = {};

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = InitialState, action = {}) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
/***********************************
 * Epics
 ***********/
