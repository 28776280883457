import React from 'react';
import Joyride from 'react-joyride';
import { injectIntl } from 'react-intl';

class MyJoyride extends React.Component {
  render() {
    let { run, callback, steps, stepIndex, spotlightClicks, intl, ...rest } = this.props;

    return (
      <Joyride
        debug={false}
        steps={steps}
        run={run}
        callback={callback}
        stepIndex={stepIndex}
        continuous={true}
        showProgress={false}
        showSkipButton={false}
        hideBackButton={true}
        disableCloseOnEsc={true}
        spotlightClicks={spotlightClicks !== undefined ? spotlightClicks : true}
        disableOverlayClose={true}
        styles={{
          options: {
            primaryColor: '#3966f8',
            zIndex: 1401
          }
        }}
        locale={{
          back: intl.formatMessage({ id: 'joyride_back' }),
          close: intl.formatMessage({ id: 'joyride_close' }),
          last: intl.formatMessage({ id: 'joyride_last' }),
          next: intl.formatMessage({ id: 'joyride_next' }),
          skip: intl.formatMessage({ id: 'joyride_skip' })
        }}
        {...rest}
      />
    );
  }
}

export default injectIntl(MyJoyride);
