import React, { Fragment, PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export default class ConsentResponse extends PureComponent {
  UNSAFE_componentWillMount() {
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      // deferredPrompt = e;
      // Update UI notify the user they can add to home screen
      // btnAdd.style.display = 'block';
    });
    if (this.props.match.params.Id) {
      var data = this.props.location.pathname.replace('/consentResponse/', '');
      this.props.submitConsent({ data });
    }
  }

  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  render() {
    const { intl, clientMatch } = this.props;
    return (
      <div>
        <div id="loginWrapper">
          <Container maxWidth="xs" className="page-containt" style={{ padding: '50px 10px 0' }}>
            {clientMatch === true && (
              <Fragment>
                <div>
                  <img alt="" src="img/ThankYouConsent.svg" style={{ width: '100%' }} />
                </div>
                <div style={{ margin: '15px auto', width: '80%' }}>
                  <Typography color="primary" gutterBottom={true} style={{ fontSize: '250%', fontWeight: 'bold' }}>
                    {intl.formatMessage({
                      id: 'consent-title'
                    })}
                  </Typography>
                  <Typography color="textSecondary" style={{ whiteSpace: 'pre-wrap' }}>
                    {intl.formatMessage({
                      id: 'consent-content'
                    })}
                  </Typography>
                </div>
              </Fragment>
            )}
            {clientMatch === false && (
              <Fragment>
                <img alt="" className="logo" src="img/logo_kyc.png" />
                <div className="consent-style">
                  <p>
                    {intl.formatMessage({
                      id: 'message_when_email_not_match'
                    })}
                  </p>
                </div>
              </Fragment>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
