import React, { PureComponent } from 'react';
import AcceptTeamInvitation from './acceptInvitation';

class AcceptInvitationComponent extends PureComponent {
  render() {
    return (
      <div>
        <AcceptTeamInvitation {...this.props} />
      </div>
    );
  }
}

export default AcceptInvitationComponent;
