import React from 'react';
import { injectIntl } from 'react-intl';
import ExpenseCalculator from '../../../../components/ExpenseCalculatorDialog/ExpenseCalculator/ExpenseCalculator';
import ColoredLeftBorderCard from '../ColoredLeftBorderCard/ColoredLeftBorderCard';
import { Button, Grid, Typography } from '@material-ui/core';
import { decimalToString } from '../../../../utils/formatNumber';
import { getExpensesTotal } from '../../../../utils/client';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { toggleDialog } from '../../../../store/control/duck';

const ButtonStyle = {
  // position: 'absolute',
  // top: 8,
  // right: 8,
  minWidth: 'unset',
  padding: '2px 6px'
};

const EditIconStyle = {
  fontSize: '1.3rem'
};

const TotalValueTypoStyle = {
  fontSize: '2.07rem',
  fontWeight: 'bold'
};

const ExpenseCalculatorCard = props => {
  const { intl, clientId, factsheetId, expenses, onSubmitExpenses, toggleDialog, template, disabled } = props;

  const total = getExpensesTotal(expenses);

  const openDialog = () => {
    toggleDialog('expenseCalculator', {
      clientId: clientId,
      factsheetId: factsheetId,
      expenses: expenses,
      onSubmit: onSubmitExpenses
    });
  };

  const editButtonDisabled = disabled;

  return (
    <ColoredLeftBorderCard
      color="#3966f8"
      contentStyle={{ width: '100%', padding: 16 }}
      style={{ position: 'relative' }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography>{intl.formatMessage({ id: 'ec-total-expense' })}</Typography>
            </Grid>
            <Grid item>
              <Typography color="primary" style={TotalValueTypoStyle}>
                {decimalToString(total)}
              </Typography>
            </Grid>
            {!template && (
              <Grid item style={{ marginLeft: 8 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ ...ButtonStyle, background: !editButtonDisabled ? 'var(--new-theme)' : undefined }}
                  onClick={openDialog}
                  disabled={editButtonDisabled}
                >
                  <EditRoundedIcon style={EditIconStyle} />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ExpenseCalculator expenses={expenses} layout="row" shrink={true} />
        </Grid>
      </Grid>
    </ColoredLeftBorderCard>
  );
};

export default injectIntl(ExpenseCalculatorCard);
