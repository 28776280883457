import { connect } from 'react-redux';
import {
  submitAdviserMessage,
  getAdvisorMessage,
  submitReferral,
  getReferralRecords,
  getNewReferralCount,
  refreshNewReferrals,
  updateReferralById,
  deleteRecord,
  initPhase
} from '../../../store/referral/duck';
import { getClient, clearClientDetails } from '../../../store/client/duck';
import { handleSignOut, getUser, getConnectedAdviser } from '../../../store/user/duck';
import ReferralView from './component';
import { injectIntl } from 'react-intl';
import { selectedLocale } from '../../../store/locale/action';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';
import { getAdviserSubscriptionNickname } from '../../../store/userSubscription/duck';

const Referral = connect(
  // Map state to props
  state => ({
    clientData: state.client.clientData,
    submitAdviserMessagePhase: state.referral.submitAdviserMessagePhase,
    message: state.referral.message,
    advisorMessage: state.referral.advisorMessage,
    submitReferralPhase: state.referral.submitReferralPhase,
    submitReferralMessageExtras: state.referral.submitReferralMessageExtras,
    referralRecords: state.referral.referralRecords,
    newReferralCount: state.referral.newReferralCount,
    deletePhase: state.referral.deletePhase,
    advisorMessagePhase: state.referral.advisorMessagePhase,
    userDetails: state.user.userDetails,
    referralTeamLogoUrl: state.referral.teamLogoUrl,
    teamLogoUrl: state.team.teamLogoUrl,
    connectedAdvisers: state.user.connectedAdvisers,
    getAdviserPhase: state.user.getAdviserPhase
  }),
  // Map actions to props
  {
    submitAdviserMessage,
    getAdvisorMessage,
    submitReferral,
    getReferralRecords,
    getNewReferralCount,
    refreshNewReferrals,
    updateReferralById,
    deleteRecord,
    handleSignOut,
    getUser,
    getClient,
    selectedLocale,
    clearClientDetails,
    renderHeaderButton,
    getConnectedAdviser,
    getAdviserSubscriptionNickname,
    initPhase
  }
)(ReferralView);

export default injectIntl(Referral);
