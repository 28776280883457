import React, { PureComponent } from 'react';
import PersonalDetailsForm from './partials/personalDetailsForm';
import FactsheetDetailsFormOne from './partials/factsheetDetailsFormOne';
import FactsheetDetailsFormTwo from './partials/factsheetDetailsFormTwo';
import FactsheetDetailsFormThree from './partials/factsheetDetailsFormThree';
import ClientPage from './partials/clientPage';
import ClientFactsheetView from './partials/clientFactsheet';
import ConsentResponse from './partials/consentResponse';
import AdvisorEmergencyExportWrapper from './partials/AdvisorEmergencyExportWrapper';
import _ from 'lodash';
import { LOADING } from '../../../constants/phase';
import { goToAddPortfolio, setClientIdLocalStorage } from '../../../components/ClientEntry/utils';
import NewClientAddPolicyDialog from './partials/NewClientAddPolicyDialog/NewClientAddPolicyDialog';
import AddPortfolioHintDialog from '../../../components/ClientEntry/AddPortfolioHintDialog/AddPortfolioHintDialog';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import { tracking } from '../../../utils/tracking';

class ClientView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      id: '',
      consentPage: '',
      addPolicyDialog: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location, history } = this.props;
    if (prevProps.location.pathname === '/client_personal_form' && location.pathname !== '/client_personal_form') {
      this.setState({ page: 1 });
    }
    if (location.state?.page) {
      this.setState({ page: location.state.page });
      let newState = { ...location.state };
      delete newState.page;
      history.replace(newState);
    }
  }

  UNSAFE_componentWillMount() {
    var id = this.props.match.params.data;
    this.setState({ id: id });
    if (!this.props.location.pathname.includes('/consentResponse/')) {
      this.props.fetchFigureAdviser();
      this.props.fetchAdviserBusiness();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, message, createClientMessageExtras, isAddingNewClient } = nextProps;
    if (nextProps.createClientPhase === true && this.props.createClientPhase === LOADING) {
      if (isAddingNewClient) {
        // after submitting client, client list will be fetched, wait for the updated client list, find the newly added client & show add policy dialog
        // setClientIdLocalStorage(_.get(viewingClient, '_id'));
        // Tracking
        // Log add client event
        // tracking('Client Added');
        this.setState({ addPolicyDialog: true });
      } else {
        if (this.props.location.pathname === "/client_personal_form" && this.state.page !== 1 && this.state.page !== 4) {
        } else {
          this.done();
        }
      }
    } else if (nextProps.createClientPhase === false && this.props.createClientPhase !== false) {
      toast.info(IconToast('error', intl.formatMessage({ id: message }, createClientMessageExtras)), {
        className: 'new-toast'
      });
    }
    // const { initCreatePhase } = this.props;
    // if (nextProps.createClientPhase === true) {
    // 	let factsheetUpdateInTracking = this.props.location.state;
    // 	console.log({ factsheetUpdateInTracking });
    // 	// this.props.getClient();
    // 	this.props.history.push({
    // 		pathname: '/client',
    // 		state: factsheetUpdateInTracking
    // 	});
    // 	initCreatePhase();
    // }
  }

  refuseAddPolicy = () => {
    this.setState({ addPolicyDialog: false });

    // redirect
    this.props.history.replace('/client');
  };

  goAddPolicy = () => {
    this.setState({ addPolicyDialog: false });

    const { history, userDetails, viewingClient, toggleDialog } = this.props;
    if (viewingClient) {
      const extraLocationState = { replaceRoute: _.get(history, 'location.state.replaceRoute') };
      if (viewingClient.consent) {
        goToAddPortfolio(viewingClient, history, userDetails, true, extraLocationState);
      } else {
        toggleDialog('addPortfolioHint', {
          client: viewingClient,
          isReplace: true,
          extraLocationState: extraLocationState,
          onClose: () => this.done()
        });
      }
    } else {
      this.done();
    }
  };

  sendData() {
    var user = this.props.userDetails;
    var clientPersonalData = JSON.parse(localStorage.getItem('clientPersonalData'));
    var clientFactsheetDataOne = JSON.parse(localStorage.getItem('clientFactsheetDataOne'));
    var clientFactsheetDataTwo = JSON.parse(localStorage.getItem('clientFactsheetDataTwo'));
    var clientFactsheetDataThree = JSON.parse(localStorage.getItem('clientFactsheetDataThree'));

    var trackingObj = JSON.parse(localStorage.getItem('trackingObj'));

    var enableTracking = localStorage.getItem('enableTracking');

    var clientId = JSON.parse(localStorage.getItem('clientId'));
    var formData = {};

    formData.advisorId = user._id;
    formData.clientId = clientId;
    formData.connectedAdviserEmail = user.email;
    if (clientPersonalData) {
      formData.name = clientPersonalData.name;
      formData.nickname = clientPersonalData.nickname;
      formData.email = clientPersonalData.email;
      formData.existingClient = clientPersonalData.existingClient;
      formData.source = clientPersonalData.source;
      formData.importance = clientPersonalData.importance;
      formData.network = clientPersonalData.network;
      formData.remark = clientPersonalData.remark;
      formData.lastMeetingLocation = clientPersonalData.lastMeetingLocation;
      formData.latestMeetingDate = clientPersonalData.latestMeetingDate;
      formData.nextMeetingDate = clientPersonalData.nextMeetingDate;
      formData.msgOpt = clientPersonalData.msgOpt;
      formData.consent = clientPersonalData.consent;
      formData.tags = clientPersonalData.tags;
      formData.usedTags = clientPersonalData.usedTags;
      formData.personality = clientPersonalData.personality;
      //if(clientPersonalData.source && clientPersonalData.source === 'Refer'){
      formData.referrerName = _.get(clientPersonalData, 'referrerName', '');
      formData.isRecruitment = clientPersonalData.isRecruitment;
      //}
      if (clientPersonalData.fsReminderActive) {
        formData.fsReminderDate = clientPersonalData.fsReminderDate;
        formData.fsReminderActive = clientPersonalData.fsReminderActive;
      }
    }
    if (clientFactsheetDataOne) {
      formData.email = clientFactsheetDataOne.email;
      formData.phoneNumber = clientFactsheetDataOne.phoneNumber;
      formData.whatsapp = clientFactsheetDataOne.whatsapp;
      formData.wechatId = clientFactsheetDataOne.wechatId;
      formData.lineId = clientFactsheetDataOne.lineId;

      // formData.age = clientFactsheetDataOne.age;
      // formData.gender = clientFactsheetDataOne.gender;
      // formData.smoker = clientFactsheetDataOne.smoker;
      formData.nationality = clientFactsheetDataOne.nationality;
      formData.birthPlace = clientFactsheetDataOne.birthPlace;
      formData.occupation = clientFactsheetDataOne.occupation;
      formData.workLocation = clientFactsheetDataOne.workLocation;
      formData.homeLocation = clientFactsheetDataOne.homeLocation;
      // formData.dob = clientFactsheetDataOne.dob;
    }
    if (clientFactsheetDataTwo) {
      // formData.occupation = clientFactsheetDataTwo.occupation;
      // formData.workLocation = clientFactsheetDataTwo.workLocation;
      // formData.homeLocation = clientFactsheetDataTwo.homeLocation;
      formData.marriageStatus = clientFactsheetDataTwo.marriageStatus;
      formData.child = clientFactsheetDataTwo.child;
      formData.age = clientFactsheetDataTwo.age;
      formData.dob = clientFactsheetDataTwo.dob;
      formData.gender = clientFactsheetDataTwo.gender;
      formData.smoker = clientFactsheetDataTwo.smoker;
      formData.monthlyIncome = clientFactsheetDataTwo.monthlyIncome;
      formData.monthlyExpense = clientFactsheetDataTwo.monthlyExpense;
      formData.liquidAsset = clientFactsheetDataTwo.liquidAsset;
      formData.debt = clientFactsheetDataTwo.debt;
      formData.expenses = clientFactsheetDataTwo.expenses;
    }
    if (clientFactsheetDataThree) {
      formData.MPF = clientFactsheetDataThree.MPF;
      formData.insurance = clientFactsheetDataThree.insurance;
      formData.investment = clientFactsheetDataThree.investment;
      formData.budget = clientFactsheetDataThree.budget;
      formData.interest = clientFactsheetDataThree.interest;
      formData.factsheetRemark = clientFactsheetDataThree.factsheetRemark;
    }
    if (clientId === null || clientId === undefined || clientId === '') {
      formData.enableTracking = enableTracking;
      let formData1 = {};
      formData1.formData = formData;
      formData1.trackingData = trackingObj;
      localStorage.removeItem('trackingObj');
      localStorage.removeItem('enableTracking');
      this.props.submitClient(formData1);
      // redirect
      // this.done();
    } else {
      this.props.submitClient(formData);
      localStorage.removeItem('trackingObj');
      localStorage.removeItem('enableTracking');

      // Tracking
      // Log add client event
      tracking('Client Edited');

      // redirect if edit from assistant
      this.done();
    }
  }

  nextPage(page) {
    this.setState({ page: page + 1 });
  }

  previousPage(page) {
    this.setState({ page: page - 1 });
  }

  done = () => {
    const { history, location } = this.props;
    if (location.pathname !== '/client') {
      const replaceRoute = _.get(location, 'state.replaceRoute');
      if (replaceRoute) {
        history.replace(replaceRoute);
      } else {
        history.goBack();
      }
      this.setState({ page: 1 });
    }
  };

  render() {
    const { addPolicyDialog } = this.state;
    const data = this.state.id;
    return (
      <div>
        <NewClientAddPolicyDialog
          open={addPolicyDialog}
          onNo={this.refuseAddPolicy}
          onYes={this.goAddPolicy}
          history={this.props.history}
        />
        <AddPortfolioHintDialog />

        <div>
          {this.props.location.pathname === '/client' && <ClientPage {...this.props} />}
          {this.state.id
            ? this.props.location.pathname.indexOf('/client/data') !== -1 && <ClientPage {...this.props} data={data} />
            : ''}
          {this.props.location.pathname === '/client_factsheet' && <ClientFactsheetView {...this.props} />}
          {this.props.location.pathname === '/emergency_reference' && (
            <AdvisorEmergencyExportWrapper {...this.props} clientId={this.props.location.exportClientId} />
          )}
          {this.props.location.pathname === '/client_personal_form' && this.state.page === 1 && (
            <PersonalDetailsForm {...this.props} 
              handleNextPage={this.nextPage.bind(this)} 
            />
          )}
          {this.props.location.pathname === '/client_personal_form' && this.state.page === 2 && (
            <FactsheetDetailsFormOne
              {...this.props}
              handleNextPage={this.nextPage.bind(this)}
              handlePreviousPage={this.previousPage.bind(this)}
            />
          )}
          {this.props.location.pathname === '/client_personal_form' && this.state.page === 3 && (
            <FactsheetDetailsFormTwo
              {...this.props}
              handleNextPage={this.nextPage.bind(this)}
              handlePreviousPage={this.previousPage.bind(this)}
            />
          )}
          {this.props.location.pathname === '/client_personal_form' && this.state.page === 4 && (
            <FactsheetDetailsFormThree
              {...this.props}
              handleSubmit={this.sendData.bind(this)}
              handlePreviousPage={this.previousPage.bind(this)}
            />
          )}
          {this.props.location.pathname.includes('/consentResponse/') && <ConsentResponse {...this.props} />}
        </div>
      </div>
    );
  }
}

export default ClientView;
