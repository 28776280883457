import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';

export default class ShareLink extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      code: '',
      id: '',
      err: {},
      msg: '',
      warningMessage: '',
      key: 'web',
      warningModal: false,
      sendersAdviserId: ''
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeWarningModal = this.closeWarningModal.bind(this);
  }

  //To get the lnaguage of user browser
  componentDidMount() {
    var user = this.props.userDetails;
    if (_.isEmpty(user)) {
      var userLang = navigator.language || navigator.userLanguage;
      const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      this.props.selectedLocale(language);
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.Id) {
      var id = this.props.location.pathname.replace('/share/', '');
      let sendersAdviserId = this.props.location.search;
      this.setState({ id: id, sendersAdviserId: sendersAdviserId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.getDocPhase === true && this.props.getDocPhase !== true) {
      let self = this;
      var shareType = _.get(nextProps, 'shareType[0].receiverType', '');
      var senderId = _.get(nextProps, 'shareType[0].senderId', '');
      let sendersAdviserId = '';
      if (shareType === 'Family') {
        let connection = false;
        if (senderId.advisorDetails && senderId.advisorDetails.length > 0) {
          senderId.advisorDetails.map(value => {
            if (value.connection) {
              //this if condition is kept here to check the if connection is true then only include else not.
              connection = true;
            }
            return false;
          });
        }
        if (connection) {
          sendersAdviserId = self.state.sendersAdviserId.substr(1);
        }
      }
      //Push the object of policy of share in state so that while reload we can get the data by state if props are null
      let sharePolicyData = {};
      sharePolicyData.portfolio1 = _.get(nextProps, 'portfolio1');
      sharePolicyData.categories1 = _.get(nextProps, 'categories1');
      sharePolicyData.forexCurrency1 = _.get(nextProps, 'forexCurrency1');
      sharePolicyData.shareType = _.get(nextProps, 'shareType');
      sharePolicyData.adviserInfo = _.get(nextProps, 'adviserInfo');
      setTimeout(function() {
        self.props.history.push({
          pathname: '/share_link_view',
          state: {
            page: 'share_link_page',
            sendersAdviserId: sendersAdviserId,
            sharePolicyData
          }
        });
      }, 500);
      this.props.initPhase();
    }

    if (nextProps.getDocPhase === false) {
      this.setState({
        warningMessage: nextProps.resMessage,
        warningModal: true
      });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  //Submit the form of access code to view the share detail
  submitForm() {
    const err = {};
    if (this.state.email === '') {
      err.email = 'Enter Email.';
    }
    if (this.state.code === '') {
      err.code = 'Enter Access Code';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      this.props.handleSignOut(undefined, true);
      var formData = {};
      formData.id = this.state.id;
      formData.mobile = this.state.code;
      formData.email = this.state.email.toLowerCase();
      formData.key = this.state.key;
      this.props.getShareDocuments(formData);
    }
  }

  closeWarningModal() {
    this.setState({ warningModal: false });
  }

  render() {
    const { intl } = this.props;
    return (
      <div style={{ overflow: 'auto', paddingBottom: 'calc(50px + var(--bottom-padding))' }}>
        <div id="loginWrapper">
          <div className="page-containt">
            <img alt="" style={{ marginTop: '30px' }} className="logo" src="img/logo_kyc.png" />
          </div>
          <div className="referral-msg">
            <p>{intl.formatMessage({ id: 'User_consolid_all_the_policies' })}</p>
            <p>{intl.formatMessage({ id: 'It is completely free! Enjoy!' })}</p>
            <p>{intl.formatMessage({ id: 'Visit us: www.portfoplus.com' })}</p>
            <p>
              {intl.formatMessage({
                id: 'Know Your Coverage Be Well Protected'
              })}
            </p>
          </div>
          <br />
          <br />
          <div className="modal-text">
            {intl.formatMessage({
              id: 'Get ACCESS CODE from the sender to view the shared portfolio.'
            })}
          </div>
          <div className="myForm referral-form">
            <div className="form-group">
              <input
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Your Email*' })}
              </label>
              <i className="bar" />
              {this.state.err.email ? (
                <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
              ) : (
                ''
              )}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="code"
                value={this.state.code}
                onChange={this.handleChange.bind(this)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Access Code* (Ask sender)' })}
              </label>
              <i className="bar" />
              {this.state.err.code ? (
                <span className="error_field">{intl.formatMessage({ id: this.state.err.code })}</span>
              ) : (
                ''
              )}
            </div>
            <div className="upDownGap">
              <button className="btn-login" onClick={this.submitForm}>
                {intl.formatMessage({ id: 'Submit' })}
              </button>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.warningModal}
          onClose={this.closeWarningModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Share Link' })}</DialogTitle>
          <DialogContent>
            {this.state.warningMessage !== '' ? intl.formatMessage({ id: this.state.warningMessage }) : ''}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeMoreInfoModal} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
