import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import IconToast from '../../../components/NewToast';
import { getDefaultRoute } from '../../../utils/router';
import { validatePassword, validatePasswordConfirm } from '../../../utils/validators';
import PasswordRulesInfoTooltip from '../../../components/PasswordRulesInfoTooltip/PasswordRulesInfoTooltip';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

class ChangePasswordView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confNewPassword: '',
      err: {},
      message: '',
      changePassModal: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitChangePasswordForm = this.submitChangePasswordForm.bind(this);
  }

  handleChange(event) {
    delete this.state.err.confNewPassword;
    this.setState({ [event.target.name]: event.target.value });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.changePasswordPhase === true) {
      this.setState({
        changePassModal: true,
        currentPassword: '',
        newPassword: '',
        confNewPassword: ''
      });
    }
    if (nextProps.changePasswordPhase === false) {
      toast.info(IconToast('info', intl.formatMessage({ id: nextProps.message })), { className: 'new-toast' });
    }
  }

  // user can change password with his old password
  submitChangePasswordForm(event) {
    event.preventDefault();
    const err = {};

    const passwordValidationResult = validatePassword(this.state.newPassword);
    if (passwordValidationResult) {
      err.confNewPassword = passwordValidationResult;
    }
    const passwordConfirmValidationResult = validatePasswordConfirm(this.state.confNewPassword, this.state.newPassword);
    if (passwordConfirmValidationResult) {
      err.confNewPassword = passwordConfirmValidationResult;
    }

    if (this.state.currentPassword === '' || this.state.newPassword === '' || this.state.confNewPassword === '') {
      err.confNewPassword = 'Enter complete details';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      const data = {};
      data.password = this.state.currentPassword;
      data.new_password = this.state.newPassword;
      this.props.changePassword(data);
    }
  }

  changePassSuccess() {
    var user = this.props.userDetails;
    const { location } = this.props;
    if (location.state && location.state.userLoginDetail && location.state.userLoginDetail.state) {
      let data = {};
      data.hideWelcome = this.props.location.state.userLoginDetail.hideWelcome;
      this.props.updateUserDetails(data);
    }
    let routePath = getDefaultRoute(user);
    //route to welcome page if user just signed up and was forced to change password
    this.props.history.push({
      pathname: routePath,
      state:
        user.userType === 'User'
          ? {
              welcomeMessage: user.welcomeMessage
            }
          : undefined
    });
  }

  render() {
    const { intl } = this.props;
    return (
      <div>
        {this.props.changePasswordPhase === 'loading' && <LoadingBackdrop open={true} />}
        <div id="loginWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 col-sm-12 col-sm-offset-3">
                <div className="sub-function-heading">
                  <h4>{intl.formatMessage({ id: 'Change Password' })}</h4>
                </div>
                <form>
                  <div className="form-group">
                    <input
                      type="password"
                      required="required"
                      onChange={this.handleChange}
                      value={this.state.currentPassword}
                      name="currentPassword"
                    />
                    <label className="control-label" htmlFor="input">
                      {intl.formatMessage({ id: 'Enter Current Password' })}
                    </label>
                    <i className="bar" />
                  </div>
                  <div className="form-group">
                    <PasswordRulesInfoTooltip password={this.state.newPassword}>
                      <input
                        type="password"
                        autoComplete="new-password"
                        required="required"
                        onChange={this.handleChange}
                        value={this.state.newPassword}
                        name="newPassword"
                      />
                    </PasswordRulesInfoTooltip>
                    <label className="control-label" htmlFor="input">
                      {intl.formatMessage({ id: 'Enter New Password' })}
                    </label>
                    <i className="bar" />
                  </div>
                  <div className="form-group">
                    <PasswordRulesInfoTooltip password={this.state.confNewPassword}>
                      <input
                        type="password"
                        autoComplete="new-password"
                        required="required"
                        onChange={this.handleChange}
                        value={this.state.confNewPassword}
                        name="confNewPassword"
                      />
                    </PasswordRulesInfoTooltip>
                    <label className="control-label" htmlFor="input">
                      {intl.formatMessage({ id: 'Confirm New Password' })}
                    </label>
                    <i className="bar" />
                    {this.state.err.confNewPassword ? (
                      <span className="error_field">
                        {intl.formatMessage({
                          id: this.state.err.confNewPassword
                        })}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <h4 className="btn-blue" onClick={this.submitChangePasswordForm} type="submit">
                    {intl.formatMessage({ id: 'Change Password' })}
                  </h4>
                </form>
              </div>
            </div>
            <Dialog
              open={this.state.changePassModal}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              TransitionComponent={SlideTransition}
              fullWidth={true}
            >
              <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Success' })}</DialogTitle>
              <DialogContent>{intl.formatMessage({ id: 'passwordChangedSuccess' })}</DialogContent>
              <DialogActions>
                <Button onClick={this.changePassSuccess.bind(this)} color="primary">
                  {intl.formatMessage({ id: 'OK' })}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePasswordView;
