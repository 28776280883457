import React from 'react';
import Grid from '@material-ui/core/Grid';
import BlockingOverlay from '../../SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import ImageIconButton from '../../ImageIconButton/ImageIconButton';

const GridButton = props => {
  const narrowLayout = window.innerWidth <= 600;
  const buttonGridXs = narrowLayout ? 6 : undefined;
  const buttonGridWidth = narrowLayout ? undefined : '190px';

  const { overlay, ...rest } = props;
  return (
    <Grid item xs={buttonGridXs} style={{ width: buttonGridWidth }}>
      {overlay ? (
        <BlockingOverlay parentStyle={{ borderRadius: 10 }}>
          <ImageIconButton {...rest} />
        </BlockingOverlay>
      ) : (
        <ImageIconButton {...rest} />
      )}
    </Grid>
  );
};

export default GridButton;
