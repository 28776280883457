import { Capacitor, CapacitorCookies } from '@capacitor/core';
import { saveStorage, getStorage, removeStorage } from './preferences';

const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';

export const saveAccessTokenCookie = async accessToken => {
  if (Capacitor.isNativePlatform()) {
    if (accessToken) {
      return saveStorage(ACCESS_TOKEN_STORAGE_KEY, accessToken);
    }
  }
};

export const getAccessTokenCookie = async () => {
  if (Capacitor.isNativePlatform()) {
    return getStorage(ACCESS_TOKEN_STORAGE_KEY);
  } else {
    return undefined;
  }
};

export const removeAccessTokenCookie = async () => {
  if (Capacitor.isNativePlatform()) {
    return removeStorage(ACCESS_TOKEN_STORAGE_KEY);
  }
};

export const clearCookies = async () => {
  if (Capacitor.isNativePlatform()) {
    await removeAccessTokenCookie();
    return CapacitorCookies.clearAllCookies();
  }
};
