import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './AladdinHint.scss';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import { getContentLink, getLocalContent } from '../StandardContentComponents/utils';
import StandardContentTrigger from '../StandardContentComponents/StandardContentDialog/StandardContentTrigger';

const PlayButtonStyle = {
  // background: '#5fc3d2',
  background: '#ff2929',
  minWidth: 'unset',
  padding: '0 6px'
};

const PlayButtonIconStyle = {
  fontSize: 20
};

const GridItemStyle = {
  padding: '2px 4px 4px 4px'
};

const AvatarStyle = {
  border: 'solid #8069ff 1px',
  background: 'unset',
  color: '#8069ff',
  width: 18,
  height: 18
};

const AladdinHint = props => {
  const {
    staticContext,
    className,
    containerProps,
    title,
    stepIndex,
    hint,
    titleTypoProps,
    extras,
    helpCenter,
    ...rest
  } = props;

  return (
    <div className={`policy-review-step click-for-edit-div aladdin-hint ${className ? className : ''}`} {...rest}>
      <Grid container {..._.merge({ alignItems: 'flex-end' }, containerProps)}>
        <Grid item style={GridItemStyle}>
          💡
          {/* <img src="img/help/tips.png" alt="" width={22} /> */}
        </Grid>
        {stepIndex && (
          <Grid item style={GridItemStyle}>
            <Avatar style={AvatarStyle}>{stepIndex}</Avatar>
          </Grid>
        )}
        <Grid item style={GridItemStyle}>
          <Typography
            // color="primary"
            {..._.merge(
              {
                style: {
                  fontSize: '110%',
                  // fontWeight: 500,
                  color: '#8069ff'
                }
              },
              titleTypoProps
            )}
          >
            {title}
          </Typography>
        </Grid>
        {helpCenter && (
          <Grid item style={GridItemStyle}>
            <StandardContentTrigger
              color="primary"
              variant="contained"
              style={PlayButtonStyle}
              contentLink={getContentLink('help-center')}
              localContent={process.env.NODE_ENV === 'development' && getLocalContent('help-center')}
              itemId={helpCenter}
            >
              <PlayArrowRoundedIcon style={PlayButtonIconStyle} />
            </StandardContentTrigger>
          </Grid>
        )}
        {extras && (
          <Grid item style={GridItemStyle}>
            {extras}
          </Grid>
        )}
      </Grid>
      {hint && <Typography color="textSecondary">{hint}</Typography>}
    </div>
  );
};

export default AladdinHint;
