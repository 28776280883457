import React, { useState } from 'react';
import StandardContentItem from '../../StandardContentComponents/StandardContentTabPanels/StandardContentItem/StandardContentItem';
import DialogContentText from '@material-ui/core/DialogContentText';

const Help = ({ title, intl, content, index }) => {
  const [selectedPanelId, setSelectedPanelId] = useState(-1);
  const handleItemChange = (event, expanded, item) => {
    setSelectedPanelId(expanded ? item.id : undefined);
  };
  return (
    <div className="category-wrapper" key={index}>
      <DialogContentText style={{ marginBottom: 10, fontSize: '1.5rem' }}>{title}</DialogContentText>
      {content
        ? content.items.map((item, i) => (
            <StandardContentItem
              item={item}
              expanded={selectedPanelId === item.id}
              onChange={(event, expanded) => handleItemChange(event, expanded, item)}
              onExited={() => {
                if (this && this.mySwipeableViews) this.mySwipeableViews.adjustSlideHeights();
              }}
              key={i}
              alwaysBreak={true}
            />
          ))
        : null}
    </div>
  );
};

export default Help;
