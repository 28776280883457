import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Badge, Button } from '@material-ui/core';
import withRouter from 'react-router/withRouter';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { tracking } from '../../../utils/tracking';

class ClientExpansionPortfolio extends React.PureComponent {
  render() {
    const {
      intl,
      isDisconnected,
      portfolioImage,
      policyAddImage,
      exportImage,
      analysisImage,
      portfolioOnClick,
      addPolicyOnClick,
      exportOnClick,
      analysisOnClick,
      staticContext,
      showDetails,
      ...rest
    } = this.props;

    return (
      <div className="client-border-first" {...rest}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
          style={{ padding: '8px 0 4px 0px' }}
          className="ClientExpansionPortfolio"
        >
          {/* 查看保單 */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={3}
            className={!isDisconnected ? 'link' : 'clientNameHidden'}
            onClick={() => {
              tracking('Click on Portfolio - Client');
              portfolioOnClick();
            }}
          >
            <Grid item className="client-expand-img-wrapper">
              <img alt="portfolio" src={portfolioImage} className="client-expand-img" />
            </Grid>

            <Grid item xs>
              <Button className="action-button adviser-action" disabled={isDisconnected}>
                {intl.formatMessage({ id: 'Policy_Portfolio' })}
              </Button>
            </Grid>
          </Grid>

          {/* 添加保單 */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={3}
            className={!isDisconnected ? 'link' : 'clientNameHidden'}
            onClick={() => {
              tracking('Click on Add Policy - Client');
              addPolicyOnClick();
            }}
          >
            <Grid item className="client-expand-img-wrapper">
              <img alt="portfolio" src={policyAddImage} className="client-expand-img" />
            </Grid>

            <Grid item xs>
              <Button className="action-button adviser-action" disabled={isDisconnected}>
                {intl.formatMessage({ id: 'clientpage-add' })}
              </Button>
            </Grid>
          </Grid>

          {/* 保單pdf */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={3}
            className={!isDisconnected ? 'link' : 'clientNameHidden'}
            onClick={() => {
              tracking('Click on Policy PDF');
              if (exportOnClick) {
                exportOnClick();
              }
            }}
          >
            <Grid item xs className="client-expand-img-wrapper">
              <Badge
                className="button-text-badge"
                variant="dot"
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <img alt="export" src={exportImage} className="client-expand-img" />
              </Badge>
            </Grid>

            <Grid item xs>
              <Button className="action-button adviser-action" disabled={isDisconnected}>
                {intl.formatMessage({ id: 'policy-pdf' })}
              </Button>
            </Grid>
          </Grid>

          {/* 保障分析 */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
            xs={3}
            className={!isDisconnected ? 'link' : 'clientNameHidden'}
            onClick={() => {
              if (!isDisconnected) {
                tracking('Click on Analysis - Client');
                if (analysisOnClick) {
                  analysisOnClick();
                }
              }
            }}
          >
            <Grid item className="client-expand-img-wrapper">
              <img alt="analysis" src={analysisImage} className="client-expand-img" />
            </Grid>

            <Grid item xs>
              <Button className="action-button adviser-action" disabled={isDisconnected}>
                {intl.formatMessage({ id: 'client-protection-Analysis' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(withRouter(ClientExpansionPortfolio));

export default injectIntl(container);
