import React, { useState, useRef } from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MySwipeableViews from '../MySwipeableViews/MySwipeableViews';
import './SavingAnnuitySlides.scss';
import { injectIntl } from 'react-intl';

const SavingAnnuitySlides = props => {
  const { intl, toggleSwipeableView = () => {}, defaultExpanded = false } = props;

  const [annuityIndex, setAnnuityIndex] = useState(2);
  const sliderRef = useRef(null);
  const imageLoadedRef = useRef(0);

  const onChangeExpansionPanel = () => {};

  const onChangeIndex = index => {
    setAnnuityIndex(index);
  };

  const onMouseDown = event => {
    event.stopPropagation();
    toggleSwipeableView(false);
  };

  const onMouseUp = event => {
    event.stopPropagation();
    toggleSwipeableView(true);
  };

  const onSwiperRef = node => {
    if (node) {
      sliderRef.current = node;
      if (imageLoadedRef.current >= 5) {
        sliderRef.current.adjustSlideHeights();
      }
    }
  };

  const onLoadImage = () => {
    imageLoadedRef.current += 1;
    if (imageLoadedRef.current >= 5 && sliderRef.current) {
      sliderRef.current.adjustSlideHeights();
    }
  };

  const createOnClickFakePrevButton = index => () => {
    setAnnuityIndex(Math.max(index - 1 + 2, 0));
  };

  const createOnClickFakeNextButton = index => () => {
    setAnnuityIndex(Math.min(index + 1 + 2, 4));
  };

  return (
    <ExpansionPanel
      className="analysis-expansion analysis-expansion-overview analysis-bottom exclude-in-image"
      style={{ backgroundColor: '#F0FFFF' }}
      defaultExpanded={defaultExpanded}
      TransitionProps={{ timeout: 300 }}
      onChange={onChangeExpansionPanel}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">
        {intl.formatMessage({ id: 'QDAP' })}
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className="analysis-expansion-detail">
        <div
          className="cardAnalysis analysis-expansion-element saving-annuity"
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
          <MySwipeableViews
            ref={onSwiperRef}
            index={annuityIndex}
            onChangeIndex={onChangeIndex}
            savingAnnuityHeight={false}
          >
            {[-2, -1, 0, 1, 2].map(index => {
              return (
                <div className="saving-annuity-slide" key={index}>
                  <div className="slide-fake-button" onClick={createOnClickFakePrevButton(index)} />
                  <div className="slide-fake-button" onClick={createOnClickFakeNextButton(index)} />
                  <img
                    alt=""
                    src={'https://static.portfoplus.com/img/qdap/card' + index + '.jpg'}
                    onLoad={onLoadImage}
                  />
                </div>
              );
            })}
          </MySwipeableViews>
        </div>
        <div className="cardAnalysis analysis-expansion-element font16 greyL6">
          <div className="medical-analysis-box">
            <h4 className="text-center">{intl.formatMessage({ id: 'HK QDAP Feature' })}</h4>
            <br />
            <div>
              <img alt="" src="img/vhisanalysis1.png" className="analysis-medical-sign-img" /> -
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'Max. HK$60K yearly tax deduction'
                  })
                }}
              />
              <br />
              <img alt="" src="img/qdapanalysis2.png" className="analysis-medical-sign-img" /> -
              <span
                className="figureColor"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'Min. total premiums of HK$180K'
                  })
                }}
              />
              <br />
              <img alt="" src="img/qdapanalysis3.png" className="analysis-medical-sign-img" /> -
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'Min. payment period of 5 yrs'
                  })
                }}
              />
              <br />
              <img alt="" src="img/qdapanalysis4.png" className="analysis-medical-sign-img" /> -
              <span
                className="figureColor"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'Min. annuity period of 10 yrs'
                  })
                }}
              />
              <br />
              <img alt="" src="img/vhisanalysis5.png" className="analysis-medical-sign-img" /> -
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'Annuitization at age 50 or beyond'
                  })
                }}
              />
              <br />
              <div className="text-center">
                <a
                  rel="noopener noreferrer"
                  style={{ color: '#808080', fontSize: '12px' }}
                  target="_blank"
                  href="https://www.ia.org.hk/tc/qualifying_deferred_annuity_policy/index.html"
                >
                  {intl.formatMessage({ id: 'Source:' })} https://www.ia.org.hk
                </a>
              </div>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default injectIntl(SavingAnnuitySlides);
