import React, { useEffect } from 'react';
import ProRequiredCard from '../../../components/SubscriptionComponents/ProRequiredCard/ProRequiredCard';
import { tracking } from '../../../utils/tracking';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';
import { toggleDialog } from '../../../store/control/duck';
import { useDispatch } from 'react-redux';

const isProduction = process.env.NODE_ENV === 'production';

const ToolsView = props => {
  const { intl, history, renderHeaderButton } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    renderHeaderButton(true);
  }, [renderHeaderButton]);

  // const adviserHelp = () => {
  //   tracking('Access Tools: Help Center');
  //   history.push({ pathname: '/new_help_center', state: { previousPath: '/adviser_tools' } });
  // };
  //
  // const hotTopic = () => {
  //   tracking('Access Tools: Hot topic');
  //   history.push({ pathname: '/hot_topic' });
  // };

  const whatsappWizard = () => {
    tracking('Access Tools: whatsapp wizard');
    history.push('/whatsapp-wizard');
  };

  const covid19 = () => {
    tracking('Access Tools: covid-19 search');
    history.push({ pathname: '/covid19' });
  };

  // const adviserBlast = () => {
  //   tracking('Access Tools: Adviser Blast');
  //   history.push({ pathname: '/adviser_blast' });
  // };

  const chequeWizard = () => {
    tracking('Access Tools: Cheque Wizard');
    history.push({ pathname: '/cheque_out' });
  };

  const info = () => {
    tracking('Access Tools: Insurer Info');
    history.push({ pathname: '/insurer_info', state: { previousPath: true } });
  };

  // const setTarget = () => {
  //   tracking('Access Tools: Set target');
  //   history.push({ pathname: '/set_target' });
  // };

  const remoteMeeting = () => {
    tracking('Enter video call');
    history.push({ pathname: '/conf_call' });
  };

  // const needAnalysis = () => {
  //   tracking('Access Tools: Need Analysis');
  //   localStorage.removeItem('clientId');
  //   history.push({ pathname: '/need-analysis', state: { previousPath: '/adviser_tools' } });
  // };

  // const premiumFinancing = () => {
  //   tracking('Access Tools: Premium Financing');
  //   history.push({ pathname: '/premium_financing' });
  // };

  const freeTools = () => {
    tracking('Access Tools: Free Tools');
    history.push({ pathname: '/free_tools' });
  };

  const whitebaord = () => {
    tracking('Access Tools - Whiteboard');
    dispatch(toggleDialog('myWhiteBoard', {}));
  };

  const adviserBlast = () => {
    tracking('Access Tools - Advise Blast');
    history.push({ pathname: '/adviser_blast' });
  };

  const back = () => {
    history.goBack();
  };

  return (
    <div>
      <div id="wrapper">
        <div id="page-content-wrapper">
          <div className="container-fluid policyInsuranceType">
            <div className="row">
              <div className="col-lg-12">
                <ProRequiredCard sectionTitleId="Tools" />

                <GridButtonLayout
                  items={[
                    {
                      image: <img src="img/showqrcode.png" alt="whatsapp-wizard" />,
                      text: intl.formatMessage({ id: 'whatsapp-wizard' }),
                      onClick: whatsappWizard
                    },
                    // {
                    //   image: (
                    //     <Fragment>
                    //       <img src="img/helpCenter.png" alt="go-to-help-center" />
                    //       <Badge
                    //         variant="dot"
                    //         className="navigation-referral-new-badge help_badge"
                    //         color="error"
                    //         badgeContent=""
                    //       />
                    //     </Fragment>
                    //   ),
                    //   text: intl.formatMessage({ id: 'newbie-tour' }),
                    //   onClick: adviserHelp
                    // },
                    // {
                    //   image: (
                    //     <Fragment>
                    //       <img src="img/hottopic.png" alt="go-to-hot-topic" />
                    //       <Badge
                    //         variant="dot"
                    //         className="navigation-referral-new-badge help_badge"
                    //         color="error"
                    //         badgeContent=""
                    //       />
                    //     </Fragment>
                    //   ),
                    //   text: intl.formatMessage({ id: 'Hot Topic' }),
                    //   onClick: hotTopic
                    // },
                    !isProduction && {
                      image: <img src="img/StopVirus.png" alt="covid19" />,
                      text: intl.formatMessage({ id: 'COVID19' }),
                      onClick: covid19
                    },
                    // {
                    //   image: <img src="img/promotion.png" alt="adviser-blast-message" />,
                    //   text: intl.formatMessage({ id: 'adviser-blast' }),
                    //   onClick: adviserBlast,
                    //   overlay: true
                    // },
                    {
                      image: <img src="img/cheque.png" alt="cheque-wizard" />,
                      text: intl.formatMessage({ id: 'Cheque Wizard' }),
                      onClick: chequeWizard
                    },
                    {
                      image: <img src="img/informbig.png" alt="info" />,
                      text: intl.formatMessage({ id: 'Info' }),
                      onClick: info
                    },
                    // {
                    //   image: <img src="img/biztarget.png" alt="set-target" />,
                    //   text: intl.formatMessage({ id: 'Set Target' }),
                    //   onClick: setTarget
                    // },
                    !isProduction && {
                      image: <img src="img/RemoteMeeting.png" alt="remote-meeting" />,
                      text: intl.formatMessage({ id: 'Remote Meeting' }),
                      onClick: remoteMeeting
                    },
                    // {
                    //   image: <img src="img/saletools.png" alt="need-analysis" />,
                    //   text: intl.formatMessage({ id: 'Need Analysis' }),
                    //   onClick: needAnalysis
                    // },
                    // {
                    //   image: <img src="img/pfinancing.png" alt="premium-financing" />,
                    //   text: intl.formatMessage({ id: 'pfa' }),
                    //   onClick: premiumFinancing
                    // },
                    {
                      image: <img src="img/freeTools.png" alt="free-tools" />,
                      text: intl.formatMessage({ id: 'Free Tools' }),
                      onClick: freeTools
                    },
                    {
                      image: <img src="img/financial-pyramid/whiteboardMenu.png" alt="premium-financing" />,
                      text: intl.formatMessage({ id: 'fin-py-handwriting-mode' }),
                      onClick: whitebaord
                    },
                    {
                      image: <img src="img/promotion.png" alt="adviser-blast-message" />,
                      text: intl.formatMessage({ id: 'adviser-blast' }),
                      overlay: true,
                      onClick: adviserBlast
                    }
                  ]}
                  style={{ paddingBottom: 15 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsView;
