import React, { Fragment, forwardRef } from 'react';
import { injectIntl } from 'react-intl';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { SlideTransition } from '../Transition/Transition';
import 'react-spring-bottom-sheet/dist/style.css';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

const BottomSheetOrDialog = forwardRef((props, ref) => {
  const {
    intl,
    open,
    className,
    onClose,
    onExited,
    header,
    headerCTAs,
    actions,
    actionsPrepend,
    content,
    BottomSheetProps,
    DialogProps,
    DialogTitleProps,
    DialogContentProps,
    DialogActionsProps,
    DialogParams
  } = props;

  const shouldUseBottomSheet = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const _headerCTAs = (headerCTAs || []).filter(Boolean);
  const _actions = actions || [];
  const _actionsPrepend = actionsPrepend || [];

  if (shouldUseBottomSheet) {
    const { disableAutoDismiss, onSpringEnd, BoxProps, ...restBottomSheetProps } = BottomSheetProps || {};
    const onDismiss = event => {
      if (onClose) {
        onClose(event);
      }
    };
    const _onSpringEnd = event => {
      const { type } = event || {};
      if (onSpringEnd) {
        onSpringEnd(event);
      }
      if (type === 'CLOSE') {
        if (onExited) {
          setTimeout(() => {
            onExited();
          }, 25);
        }
      }
    };

    return (
      <Fragment>
        <Backdrop open={open} onClick={!disableAutoDismiss ? onClose : undefined} style={{ zIndex: 1043 }} />
        <BottomSheet
          className={className}
          open={open}
          onDismiss={!disableAutoDismiss ? onDismiss : undefined}
          onSpringEnd={_onSpringEnd}
          header={
            <Grid container alignItems="flex-start" justify="space-between">
              <Grid item>
                <Typography color="primary" align="left" component="div">
                  {header}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  {_headerCTAs.map((cta, index) =>
                    cta ? (
                      <Grid item key={index}>
                        {cta}
                      </Grid>
                    ) : null
                  )}
                  <Grid item>
                    <IconButton
                      onClick={onClose}
                      style={{ padding: 1, color: 'var(--rsbs-handle-bg,hsla(0, 0%, 0%, 0.14))' }}
                    >
                      <ClearRoundedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          footer={
            (_actionsPrepend.length > 0 || _actions.length > 0) && (
              <Grid container justify="space-between" alignItems="center" style={{ width: '100%' }}>
                <Grid item>
                  {_actionsPrepend.map((cta, index) =>
                    cta ? (
                      <Grid item key={index}>
                        {cta}
                      </Grid>
                    ) : null
                  )}
                </Grid>
                <Grid item>
                  {_actions.length > 0 && (
                    <Grid container alignItems="center">
                      {(_actions || []).map((cta, index) =>
                        cta ? (
                          <Grid item key={index}>
                            {cta}
                          </Grid>
                        ) : null
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )
          }
          blocking={false}
          expandOnContentDrag={true}
          ref={ref}
          {...restBottomSheetProps}
        >
          <Box padding={2} height="100%" {...BoxProps}>
            {content}
          </Box>
        </BottomSheet>
      </Fragment>
    );
  } else {
    const { leftCloseButton, dialogTitleCloseButton } = DialogParams || {};

    return (
      <Dialog
        className={className}
        open={open}
        onClose={onClose}
        onExited={onExited}
        TransitionComponent={SlideTransition}
        ref={ref}
        {...DialogProps}
      >
        <DialogTitle {...DialogTitleProps} disableTypography={true}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography color="primary" variant="h6">
                {header}
              </Typography>
            </Grid>
            {_headerCTAs.length > 0 && (
              <Grid item>
                <Grid container alignItems="center">
                  {_headerCTAs.map((cta, index) =>
                    cta ? (
                      <Grid item key={index}>
                        {cta}
                      </Grid>
                    ) : null
                  )}
                </Grid>
              </Grid>
            )}
            {dialogTitleCloseButton && (
              <Grid item>
                <IconButton
                  onClick={onClose}
                  style={{ padding: 1, color: 'var(--rsbs-handle-bg,hsla(0, 0%, 0%, 0.14))' }}
                >
                  <ClearRoundedIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent {...DialogContentProps}>{content}</DialogContent>
        <DialogActions {...DialogActionsProps}>
          <Grid container justify="space-between" alignItems="center" style={{ width: '100%' }}>
            <Grid item>
              <Grid container alignItems="center">
                {leftCloseButton && !dialogTitleCloseButton && (
                  <Grid item>
                    <Button color="primary" onClick={onClose}>
                      {intl.formatMessage({ id: 'Close' })}
                    </Button>
                  </Grid>
                )}
                {_actionsPrepend.map((cta, index) =>
                  cta ? (
                    <Grid item key={index}>
                      {cta}
                    </Grid>
                  ) : null
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                {!leftCloseButton && !dialogTitleCloseButton && (
                  <Grid item>
                    <Button color="primary" onClick={onClose}>
                      {intl.formatMessage({ id: 'Close' })}
                    </Button>
                  </Grid>
                )}
                {_actions.map((cta, index) =>
                  cta ? (
                    <Grid item key={index}>
                      {cta}
                    </Grid>
                  ) : null
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
});

export default injectIntl(BottomSheetOrDialog);
