import { Badge, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  badge: {
    top: -9,
    right: -17,
    height: 15,
    minWidth: 15,
    width: 15,
    fontSize: '0.75em'
  }
});

const SearchFilterBadge = props => {
  const { ...rest } = props;
  const classes = useStyles();

  return <Badge color="error" classes={classes} {...rest} />;
};

export default SearchFilterBadge;
