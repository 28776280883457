import { fetch } from '../../utils';
import Backend from '../../backend';

export const JOB_TYPE = {
  IMPORT_CLIENT: 'import-client',
  GPT: 'gpt'
};

const JOB_ENDPOINT = {
  [JOB_TYPE.IMPORT_CLIENT]: 'job/import/clients',
  [JOB_TYPE.GPT]: 'job/gpt'
};

export const getJob = async (type, jobId) => {
  const endpoint = JOB_ENDPOINT[type];

  if (!endpoint) {
    throw new Error('Invalid job type');
  }

  return fetch(Backend.methodUrl(`${endpoint}/${jobId}`))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
