import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import { Button, Grid } from '@material-ui/core';
import AnimatedLinearProgressBar from '../../../../../../components/AnimatedLinearProgressBar';
import Card from '@material-ui/core/Card';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import { formatOnlyNumber, formatOnlyUnit } from '../../../../../../utils/formatNumber';
import CreateIcon from '@material-ui/icons/Create';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

class ClosedBizCard extends PureComponent {
  render() {
    const { intl, confirmedBiz, expectedBiz, quarterTarget, openModal, page } = this.props;

    //Calculate the percentage of expected closed biz
    const expectedClosedBizPercentage = quarterTarget > 0 ? (expectedBiz / quarterTarget) * 100 : 0;
    const closedBizPercentage = quarterTarget > 0 ? (parseInt(confirmedBiz) / quarterTarget) * 100 : 0;

    return (
      <Card className={page !== 'teamMember' ? 'progress-card' : 'progress-card-upper-shadow'}>
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
          <Grid item xs={12} className="progress-card-title">
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#8c1aff' }} />
            {intl.formatMessage({ id: 'Closed Biz' })}
            {closedBizPercentage >= expectedClosedBizPercentage ? (
              <SentimentSatisfiedAltIcon className="progress-emoji" style={{ color: '#5ee092' }} />
            ) : (
              <SentimentDissatisfiedIcon className="progress-emoji" style={{ color: '#ff6a74' }} />
            )}

            {openModal && (
              <Button
                color="primary"
                variant="contained"
                className="close-biz-modal-btn edit-button edit-info-button"
                onClick={openModal}
                style={{ float: 'right' }}
              >
                <CreateIcon />
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={0}>
          <Grid item xs={12} style={{ color: '#8c1aff' }}>
            <span>
              <span className="progress-card-number">
                {parseInt(confirmedBiz) > 0 ? formatOnlyNumber(confirmedBiz, intl) : '-'}{' '}
              </span>
              <span className="progress-card-unit">{confirmedBiz ? formatOnlyUnit(confirmedBiz, intl) : ''}</span>
            </span>
          </Grid>
          <Grid item xs={8}>
            <AnimatedLinearProgressBar
              className={
                'customize-progress-bar ' +
                (closedBizPercentage < 33.3
                  ? 'bar-red-color'
                  : closedBizPercentage < 66.6
                  ? 'bar-yellow-color'
                  : 'bar-green-color')
              }
              barValue={closedBizPercentage > 0 ? (closedBizPercentage > 100 ? 100 : closedBizPercentage) : 0}
            />
          </Grid>
          <Grid item xs={3} style={{ marginLeft: '6px', color: '#8c1aff' }}>
            {parseInt(closedBizPercentage)}%
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default injectIntl(ClosedBizCard);
