import _ from 'lodash';

export const REFERRAL_HELP_ITEMS_CHOICES = [
  'policy-consolidation',
  'buy-protection',
  'product-comparison',
  'financial-advice',
  'claim',
];

export const REFERRAL_LINK_SEARCH_PARAMS_MAP = {
  medium: 'm',
  role: 'r',
  source: 's',
  referById: 'rbid',
};

export const INVERTED_REFERRAL_LINK_SEARCH_PARAMS_MAP = _.invert(REFERRAL_LINK_SEARCH_PARAMS_MAP);
