import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import { formatOnlyNumber, formatOnlyUnit } from '../../../../../../utils/formatNumber';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreInfoIconButton from '../../../../../../components/MoreInfoIconButton';
import QuarterProgressChart from '../QuarterProgressChart.js';

class QuarterProgressCard extends PureComponent {
  render() {
    const {
      intl,
      headingStatus,
      outerChartValue,
      targetBiz,
      QEnd,
      quarterTarget,
      confirmedBiz,
      expectedBiz,
      openModal
    } = this.props;

    var totalProgress =
      parseInt(confirmedBiz) > 0
        ? parseInt(targetBiz) > 0
          ? confirmedBiz + targetBiz
          : confirmedBiz
        : parseInt(targetBiz) > 0
        ? targetBiz
        : '';

    return (
      <Card className="progress-card" id="quarterly-progress-card">
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
          <Grid item className="progress-card-title">
            {intl.formatMessage({ id: 'Quarterly Progress' })}
          </Grid>
          <Grid item>
            <MoreInfoIconButton className="pt-10 progress-card-more-info-btn" handleClick={openModal} />
          </Grid>
        </Grid>

        <div className="Outer" style={{ marginTop: '18px', marginBottom: '18px' }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6} className="quarter-progress-chart">
              <QuarterProgressChart
                headingStatus={headingStatus}
                outerChartValue={outerChartValue}
                targetBiz={targetBiz}
                QEnd={QEnd}
                quarterTarget={quarterTarget}
                confirmedBiz={confirmedBiz}
                quarterBusiness={expectedBiz}
                openModal={() => this.setState({ quarterProgressModal: true })}
              />
            </Grid>
            <Grid item xs={6}>
              <Card className="quarter-progress-chart-right-card" id="current-progress-card">
                {/* 已有生意 */}
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                  <Grid item className="progress-card-title">
                    <FiberManualRecordIcon className="card-title-dot" style={{ color: '#8c1aff' }} />+
                    <FiberManualRecordIcon className="card-title-dot" style={{ color: '#d2a6ff' }} />
                    {intl.formatMessage({ id: 'Progress' })}
                    {totalProgress >= expectedBiz ? (
                      <SentimentSatisfiedAltIcon className="progress-emoji" style={{ color: '#5ee092' }} />
                    ) : (
                      <SentimentDissatisfiedIcon className="progress-emoji" style={{ color: '#ff6a74' }} />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className={totalProgress >= expectedBiz ? 'green-color' : 'red-color'}
                    style={{ color: '#3966f8', textAlign: 'left' }}
                  >
                    <span className="progress-card-number-smaller">{formatOnlyNumber(totalProgress, intl)} </span>
                    <span className="progress-card-unit" style={{ color: '#00000061' }}>
                      {}
                      {totalProgress ? formatOnlyUnit(totalProgress, intl) : ''}
                    </span>
                  </Grid>
                </Grid>
                {/* 應有進度  */}
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                  <Grid item className="progress-card-title">
                    <FiberManualRecordIcon className="card-title-dot" style={{ color: '#3966f8' }} />
                    {intl.formatMessage({ id: 'Expected Biz' })}
                  </Grid>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={0}>
                  <Grid item xs={12} style={{ color: '#3966f8', textAlign: 'left' }}>
                    <span>
                      <span className="progress-card-number-smaller">
                        {expectedBiz > 0 ? formatOnlyNumber(expectedBiz, intl) : '-'}{' '}
                      </span>
                      <span className="progress-card-unit" style={{ color: '#00000061' }}>
                        {expectedBiz ? formatOnlyUnit(expectedBiz, intl) : ''}
                      </span>
                    </span>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  }
}

export default injectIntl(QuarterProgressCard);
