import React from 'react';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import './ChosenClient.scss';
import Typography from '@material-ui/core/Typography';

export default class ChosenClient extends React.PureComponent {
  render() {
    const { img, client, onHint, onClick } = this.props;
    return (
      <Grid container className="chosen-client">
        <Grid item className="link" onClick={onHint}>
          <img src={img} alt="" />
        </Grid>
        <Grid item className={`client-name ${onClick ? 'link' : ''}`} onClick={onClick}>
          <div className="flex-box">
            <Typography color="primary">{_.get(client, 'factsheetId.name', '-')}</Typography>
          </div>
        </Grid>
      </Grid>
    );
  }
}
