import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { handleSignOut } from '../../../../store/user/duck';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';
import {
  getContentJson,
  getContentLink,
  getLocalContent
} from '../../../../components/StandardContentComponents/utils';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer';
import { tracking } from '../../../../utils/tracking';
import HotTopicContentSlides from '../hotTopic/HotTopicContentSlides';
import './NewHelpCenter.scss';
// import _ from 'lodash';

const NewHelpCenterView = props => {
  const { history, location, intl, userDetails, subscriptions } = props;

  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getContentJson({
        contentLink: getContentLink('help-center'),
        localContent: process.env.NODE_ENV === 'development' && (await getLocalContent('help-center'))
      });
      setContents({ data: data });
    };

    fetchData()
      .then()
      .catch();
  }, []);

  useEffect(() => {
    // user tracking
    tracking('Adviser enter help center');
  }, []);

  return (
    <AppContentContainer>
      <div style={{ paddingTop: 10 }}>
        <AladdinHint
          containerProps={{ justify: 'center' }}
          title={intl.formatMessage({ id: 'aladdin-help-center-title' })}
        />

        <HotTopicContentSlides
          className="new-help-center"
          history={history}
          location={location}
          intl={intl}
          userDetails={userDetails}
          subscriptions={subscriptions}
          eventType="help center"
          hotTopic={contents}
          SeeMoreProps={{
            className: 'new-help-center',
            rowItemLength: 2
          }}
          HotTopicItemProps={{
            DialogProps: {
              maxWidth: 'md'
            }
          }}
          categoryViewPath="/new_help_center_category"
          tagViewPath="/new_help_center_tag"
        />

        {/*<StandardContentTabPanels*/}
        {/*	{...this.props}*/}
        {/*	contentLink={getContentLink('help-center')}*/}
        {/*	localContent={process.env.NODE_ENV === 'development' && getLocalContent('help-center')}*/}
        {/*	eventType="help center"*/}
        {/*/>*/}
      </div>
    </AppContentContainer>
  );
};

let container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions
  }),
  // Map actions to props
  {
    handleSignOut,
    renderHeaderButton
  }
)(NewHelpCenterView);

export default injectIntl(container);
