import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateUserDetails } from '../../../../../../store/user/duck';
import _ from 'lodash';
import { tracking } from '../../../../../../utils/tracking';
import { updateAdviserBusiness } from '../../../../../../store/adviserDashboard/duck';
import { injectIntl } from 'react-intl';
import { getQuarterTargetData } from '../../../../../../utils/business';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import BusinessTargetCalculator from './BusinessTargetCalculator/BusinessTargetCalculator';
import { usePrevious } from '../../../../../../utils/hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';

const BusinessTargetEditorModal = props => {
  const {
    intl,
    onClose,
    open,
    adviserBusiness,
    updateQuarterTargetPhase,
    updateAdviserBusiness,
    updateSwipe,
    handleEnableMouseEvents
  } = props;

  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [quarterTarget, setQuarterTarget] = useState(0);
  const [avgCaseSize, setAvgCaseSize] = useState(0);
  const [closingRate, setClosingRate] = useState(0);
  const [isMDRT, setIsMDRT] = useState('No');
  const [isCOT, setIsCOT] = useState('No');
  const [isTOT, setIsTOT] = useState('No');
  const prevUpdateQuarterTargetPhase = usePrevious(updateQuarterTargetPhase);

  useEffect(() => {
    if (updateQuarterTargetPhase === true && prevUpdateQuarterTargetPhase !== true) {
      const data = getQuarterTargetData(adviserBusiness);
      setQuarterTarget(data.quarterTarget);
      setAvgCaseSize(data.avgCaseSize);
      setClosingRate(data.closingRate);
    }
  }, [prevUpdateQuarterTargetPhase, updateQuarterTargetPhase, adviserBusiness]);

  //To update the business quarter target
  const updateQuarterTarget = () => {
    const updateObj = {
      quarterTarget: typeof quarterTarget === 'string' ? parseFloat(quarterTarget) : quarterTarget,
      avgCaseSize: typeof avgCaseSize === 'string' ? parseFloat(avgCaseSize) : avgCaseSize,
      closingRate: parseFloat(closingRate) / 100
    };

    const edited = !_.isEqual(_.pick(adviserBusiness, Object.keys(updateObj)), updateObj);
    if (edited) {
      tracking('Edit Any Setting of Business Target');
    }

    updateAdviserBusiness(updateObj);
    if (updateSwipe) {
      onClose();
    }
    //Sending props from child to parent component to update the props of swipe
    if (!updateSwipe && handleEnableMouseEvents) {
      handleEnableMouseEvents(true);
    }
  };

  //Handle the closing rate change
  const handleClosingRate = value => {
    setClosingRate(value);
  };

  //handle the quarter target value
  const handleQuarterTarget = value => {
    setQuarterTarget(value);
  };

  //handle the avg case size
  const handleAvgCaseSize = value => {
    setAvgCaseSize(value);
  };

  return (
    <div>
      <Dialog
        className="business-target-dialog"
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Business Target' })}</DialogTitle>
        <DialogContent>
          <Typography color="textSecondary">{intl.formatMessage({ id: 'BizTarget_desc' })}</Typography>

          <BusinessTargetCalculator
            defaultQuarterTarget={_.get(adviserBusiness, 'quarterTarget', 0)}
            quarterTarget={quarterTarget}
            avgCaseSize={avgCaseSize}
            closingRate={closingRate}
            isMDRT={isMDRT}
            isCOT={isCOT}
            isTOT={isTOT}
            onChangeQuarterTarget={handleQuarterTarget}
            onChangeAvgCaseSize={handleAvgCaseSize}
            onChangeClosingRate={handleClosingRate}
            onChangeIsMDRT={setIsMDRT}
            onChangeIsCOT={setIsCOT}
            onChangeIsTOT={setIsTOT}
            style={{ marginTop: '45px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={updateQuarterTarget} color="primary">
            {intl.formatMessage({ id: 'Confirm' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const container = connect(
  // Map state to props
  state => ({
    updatePhase: state.user.updatePhase,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    updateQuarterTargetPhase: state.adviserDashboard.updateQuarterTargetPhase
  }),
  // Map actions to props
  {
    updateUserDetails,
    updateAdviserBusiness
  }
)(BusinessTargetEditorModal);

export default injectIntl(container);
