import React from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import IconButton from '@material-ui/core/IconButton';
import { isDraftPolicy } from '../../../utils/policy';
import './PolicyCategoryTableEdit.scss';
import { getWebImageUrl } from '../../../utils/image';
import { Button } from '@material-ui/core';
import { tracking } from '../../../utils/tracking';

const PolicyCategoryTableEdit = props => {
  const { intl, category, lastRowNoBorder, onAddPolicy, onEditPolicy, disableActions } = props;

  const subCategories = _.get(category, 'subCategories', []);
  const editPolicy = onEditPolicy
    ? policy => () => {
        // Tracking
        tracking('Policy Review table: Edit Policy');
        onEditPolicy(policy);
      }
    : undefined;

  return category ? (
    <TableContainer className="policy-category-table edit">
      <Table size="small" style={{ width: 40 }}>
        <TableHead>
          <TableRow className="sub-header">
            <TableCell style={{ borderBottom: '3px solid transparent', width: 40 }}>
              <div />
            </TableCell>
          </TableRow>
          <TableRow className="main-row">
            <TableCell align="center" rowSpan={2}>
              <div />
            </TableCell>
          </TableRow>
          <TableRow className="sub-row" />
        </TableHead>
        <TableBody>
          {subCategories.map(subCategory => {
            const policies = _.get(subCategory, 'policies', []);

            return policies.map((policy, policyIndex) => {
              const isDraft = isDraftPolicy(policy);
              const add = onAddPolicy
                ? () => {
                    // Tracking
                    tracking('Policy Review table: Add Policy');
                    onAddPolicy(category, policy);
                  }
                : undefined;
              const edit = editPolicy(policy);
              return (
                <TableRow
                  key={policy._id}
                  className={lastRowNoBorder && policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
                >
                  <TableCell align="center">
                    <div>
                      {!policy.dummy ? (
                        <IconButton color="primary" onClick={edit} disabled={disableActions}>
                          {isDraft ? <img src={getWebImageUrl('img/draft.png')} alt="draft" /> : <EditRoundedIcon />}
                        </IconButton>
                      ) : (
                        <Button
                          className="orange action-button"
                          variant="contained"
                          color="primary"
                          onClick={add}
                          disabled={disableActions}
                          style={{ padding: 3, lineHeight: 1, minWidth: 'unset', textTransform: 'unset' }}
                        >
                          {intl.formatMessage({ id: 'Add' })}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyCategoryTableEdit);
