import { fetch } from '../../utils';
import Backend from '../../backend';
import { toast } from 'react-toastify';
import IconToast from '../../components/NewToast';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { handleWalletPassFetch } from '../../utils/wallet-pass';
import qs from 'qs';

export const getWalletPassLinkDirectUrl = (directToken, { type, ...rest }) =>
  Backend.methodUrl(`wallet-pass/link/${directToken}?${qs.stringify({ type, ...rest })}`);

export const getWalletPass = async (type, params) => {
  try {
    await handleWalletPassFetch(Backend.methodUrl(`wallet-pass/${type}?${qs.stringify(params)}`), type);
  } catch (error) {
    console.error(error);
    const intl = getGlobalIntl();
    toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
  }
};

export const createWalletPassLink = async (type, data) => {
  return fetch(Backend.methodUrl('wallet-pass/link'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ type, ...data })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getWalletPassLink = async ({ token, password, extras, type, options }) => {
  return fetch(Backend.methodUrl(`wallet-pass/link/${token}`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ password, extras, type, options })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getWalletPassLinkDirect = async ({ directToken, type, ...rest }) => {
  return fetch(getWalletPassLinkDirectUrl(directToken, { type, ...rest }));
};
