import React from 'react';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import './SyncInputButton.scss';

class SyncInputButton extends React.PureComponent {
  openConfirmSaveDialog = () => this.props.toggleDialog('analysisConfirmSave', {});

  render() {
    const { intl, className, toggleDialog, ...rest } = this.props;
    return (
      <Button
        variant="contained"
        color="primary"
        className={`sync-input ${className ? className : ''}`}
        onClick={this.openConfirmSaveDialog}
        {...rest}
      >
        <AccountCircleRoundedIcon />
        {intl.formatMessage({ id: 'save-info' })}
      </Button>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {
    toggleDialog
  }
)(SyncInputButton);

export default injectIntl(container);
