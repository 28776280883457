import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MenuItem, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { INIT, LOADING } from '../../../constants/phase';
import { getClient, updateViewingClient } from '../../../store/client/duck';
import CircularProgress from '@material-ui/core/CircularProgress';
import './PolicyReviewChooseClient.scss';
import { Grid, Typography } from '@material-ui/core';
import { goToAddClient, isClientDisconnected } from '../../../components/ClientEntry/utils';
import { injectIntl } from 'react-intl';
import { tracking } from '../../../utils/tracking';
import { withRouter } from 'react-router';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import TempSelect from '../../../components/TempSelect/TempSelect';

const PolicyReviewChooseClient = props => {
  const {
    intl,
    history,
    clients,
    fetchClientPhase,
    viewingClient,
    getClient,
    selectedClient,
    setSelectedClient,
    updateViewingClient
  } = props;

  const [searchClient, setSearchCient] = useState("");

  const filterClients = clients =>
    (clients || [])
      .filter(client => !isClientDisconnected(client))
      .filter(client => _.get(client, 'factsheetId.name', '').toLowerCase().includes(searchClient.toLowerCase()))
      .sort((a, b) => _.get(a, 'factsheetId.name', '').localeCompare(_.get(b, 'factsheetId.name', '')));

  const isLoading = fetchClientPhase === LOADING;

  const selectClient = event => {
    if (event.target.value === "add-client") {
      tracking('Click on Client add - Financial pyramid');
      goToAddClient(history);
    } else if (event.target.value === "search-client") {
      event.stopPropagation();
    } else {
      const foundClient = filterClients(clients).find(client => client._id === event.target.value);
      setSelectedClient(foundClient || null);
    }
  };

  useEffect(() => {
    if (viewingClient) {
      const isDisconnected = isClientDisconnected(viewingClient);
      if (isDisconnected) {
        updateViewingClient(undefined);
      }
    }
  }, [viewingClient, updateViewingClient]);

  useEffect(() => {
    if (fetchClientPhase === INIT) {
      getClient();
    }
  }, [fetchClientPhase, getClient]);

  useEffect(() => {
    if (viewingClient) {
      if (viewingClient !== selectedClient && _.get(viewingClient, '_id') !== _.get(selectedClient, '_id')) {
        const isDisconnected = isClientDisconnected(viewingClient);
        if (isDisconnected) {
          setSelectedClient(undefined);
        } else {
          setSelectedClient(viewingClient);
        }
      }
    }
  }, [selectedClient, viewingClient, setSelectedClient]);

  return (
    <TempSelect
      className="policy-review-choose-client"
      variant="outlined"
      value={isLoading ? 'loading' : _.get(selectedClient, '_id', '')}
      onChange={selectClient}
      onClose={() => setSearchCient("")}
      renderValue={
        !selectedClient
          ? () => (
            <Typography color={!selectedClient ? 'error' : undefined}>
              {intl.formatMessage({ id: 'not-selected' })}
            </Typography>
          )
          : undefined
      }
      displayEmpty={true}
      disabled={isLoading}
      error={!selectedClient}
      MenuProps={{ autoFocus: false }}
    >
      {isLoading ? (
        <MenuItem value="loading">
          <CircularProgress />
        </MenuItem>
      ) : (
        [
          <MenuItem key="add-client" value="add-client">
            <Grid container alignItems="center">
              <Grid item>
                <AddRoundedIcon color="primary" style={{ verticalAlign: 'middle' }} />
              </Grid>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'add-client' })}</Typography>
              </Grid>
            </Grid>
          </MenuItem>,
          <MenuItem>
            <TextField
              variant='outlined'
              autoFocus
              size="small"
              placeholder={intl.formatMessage({ id: 'search-client' })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchCient(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </MenuItem>,
          ...filterClients(clients).map(client => (
            <MenuItem key={client._id} value={client._id}>
              <Typography>{_.get(client, 'factsheetId.name', '')}</Typography>
            </MenuItem>
          ))
        ]
      )}
    </TempSelect>
  );
};

const container = connect(
  state => ({
    clients: state.client.clientData,
    fetchClientPhase: state.client.fetchClientPhase,
    viewingClient: state.client.viewingClient
  }),
  {
    getClient,
    updateViewingClient
  }
)(PolicyReviewChooseClient);

export default injectIntl(withRouter(container));
