import { injectIntl } from 'react-intl';
import TimeAvailableChart from '../../TimeAvailableChart.js';
import React, { PureComponent } from 'react';
import { Button, Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import Card from '@material-ui/core/Card';

class TimeLeftCard extends PureComponent {
  render() {
    const { intl, r1, QEnd, bigDateEnd, chartData, openModal } = this.props;

    // const QendColor =
    //   QEnd > (2 * r1) / 3
    //     ? 'green-color'
    //     : QEnd > r1 / 3 && QEnd <= (2 * r1) / 3
    //     ? 'yellow-color'
    //     : QEnd >= 0 && QEnd <= r1 / 3
    //     ? 'red-color'
    //     : '';

    return (
      <Card className="progress-card" id="follow-up-case-card">
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
          <Grid item className="progress-card-title">
            {intl.formatMessage({ id: 'time left' })}
          </Grid>
          <Grid item>
            <Button color="primary" className="edit-button edit-info-button" onClick={openModal}>
              <CreateIcon />
            </Button>
          </Grid>
        </Grid>
        <div className="Outer" style={{ marginBottom: '-92px', marginTop: '5px' }}>
          <TimeAvailableChart r1={r1} QEnd={QEnd} chartData={chartData} style={{ height: 175 }} />
        </div>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={0}
          style={bigDateEnd !== '-' ? { paddingTop: '30px' } : { paddingTop: '30px', paddingBottom: '29px' }}
        >
          <Grid item xs={5}>
            <span style={{ float: 'right' }}>{intl.formatMessage({ id: 'QEnd' })}:&nbsp;</span>
          </Grid>

          {QEnd > 0 ? (
            <Grid item xs={7}>
              <span className={`progress-card-number-smaller`}>{QEnd}&nbsp;</span>
              <span className="progress-card-unit" style={{ color: '#00000061' }}>
                {intl.formatMessage({ id: 'days' })}
              </span>
            </Grid>
          ) : (
            <Grid item xs={7} className="progress-card-number">
              <span className={`progress-card-number-smaller`}>{intl.formatMessage({ id: 'Today' })}</span>
            </Grid>
          )}
        </Grid>

        {bigDateEnd !== '-' && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={0}
            // style={{ marginTop: '-5px' }}
          >
            <Grid item xs={5} className="">
              <span style={{ float: 'right' }}>{intl.formatMessage({ id: 'Big Day' })}:&nbsp;</span>
            </Grid>
            {bigDateEnd !== 0 ? (
              <Grid item xs={7}>
                <span className="progress-card-number-smaller" style={{ color: '#00000061' }}>
                  {bigDateEnd}&nbsp;
                </span>
                <span className="progress-card-unit" style={{ color: '#00000061' }}>
                  {intl.formatMessage({ id: 'days' })}
                </span>
              </Grid>
            ) : (
              <Grid item xs={7} className="progress-card-number-smaller" style={{ color: '#00000061' }}>
                <span className="progress-card-unit" style={{ color: '#00000061' }}>
                  {intl.formatMessage({ id: 'Today' })}
                </span>
                <span className="progress-card-number-smaller" style={{ color: '#00000061' }}>
                  &nbsp;
                </span>
              </Grid>
            )}
          </Grid>
        )}
      </Card>
    );
  }
}

export default injectIntl(TimeLeftCard);
