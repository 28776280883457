import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog, mergeDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import StandardContentItem from '../StandardContentTabPanels/StandardContentItem/StandardContentItem';
import { getContentJson } from '../utils';
import _ from 'lodash';
import { withRouter } from 'react-router';
import './StandardContentDialog.scss';
import { getHotTopicContentsAllItems, getHotTopicItemSuggestions } from '../../../utils/hot-topic';

class StandardContentDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      item: undefined
    };
    this.unmounted = false;
    this.listener = undefined;
  }

  close = () => this.props.mergeDialog('standardContent', { closed: true });
  closed = () => this.props.toggleDialog('standardContent', false);

  componentDidMount() {
    const { history } = this.props;
    this.listener = history.listen(() => {
      this.closed();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevContentLink = _.get(prevProps, 'standardContentDialog.contentLink');
    const contentLink = _.get(this.props, 'standardContentDialog.contentLink');

    if (contentLink && prevContentLink !== contentLink) {
      const localContent = _.get(this.props, 'standardContentDialog.localContent');
      // read contents from local if it is in development
      getContentJson({ contentLink, localContent }).then(data => {
        const itemId = _.get(this.props, 'standardContentDialog.itemId');
        let item = undefined;
        if (data) {
          data.some(category => {
            const target = category.items.find(item => item.id === itemId);
            if (target) {
              item = target;
              return true;
            }
            return false;
          });

          if (item) {
            const allContentItems = getHotTopicContentsAllItems(data);
            item = {
              ...item,
              suggestions: getHotTopicItemSuggestions(item, allContentItems)
            };
          }
        }

        if (this && !this.unmounted) {
          this.setState({ item: item });
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.listener) {
      this.listener();
    }
    this.unmounted = true;
  }

  render() {
    const { intl, history, standardContentDialog } = this.props;
    const { item } = this.state;

    if (!standardContentDialog || !item) {
      return null;
    }

    const { closed } = standardContentDialog;

    return (
      <Dialog
        className="standard-content-dialog"
        open={!!standardContentDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
        fullScreen={false}
      >
        <DialogContent>
          <StandardContentItem item={item} withoutExpansion={true} dialogLayout={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary" autoFocus>
            {intl.formatMessage({ id: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    standardContentDialog: state.control.standardContentDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog
  },
  // mergeProps
  null,
  // options
  {}
)(StandardContentDialog);

export default injectIntl(withRouter(container));
