import { connect } from 'react-redux';
import {
  fetchPolicy,
  handleDashboardPolicyData,
  fetchMePolicy,
  updateSelectedPortfolio
} from '../../store/policy/duck';
import { getShareFromData, initSharePhase, getShareMeDocuments } from '../../store/share/duck';
import {
  initPhase,
  updateUserDetails,
  handleSignOut,
  getUser,
  getNotifications,
  getConnectedAdviser,
  userDashboardFetch,
  updateLoginFetched,
  setShownDashHelp
} from '../../store/user/duck';
import DashboardComponent from './component';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { injectIntl } from 'react-intl';
import { updateVisitedDashboard } from '../../store/app';
import { runGuideline } from '../../store/guideline/duck';
import {
  getNotificationCenterList,
  markNotificationAsRead,
  createNotification,
  clientAllModalDone
} from '../../store/notificationCenter/duck';
import withStyles from '@material-ui/core/styles/withStyles';
import { toggleControl } from '../../store/control/duck';

const styles = theme => ({
  avatar: {
    boxShadow: theme.shadows[3]
  }
});

const DashboardContainer = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    fetchPhase: state.policy.fetchPhase,
    shareFromData: state.share.shareFromData,
    getDocPhase: state.share.getDocPhase,
    categories: state.policy.categories,
    portfolio: state.policy.portfolio,
    categories1: state.share.categories1,
    portfolio1: state.share.portfolio1,
    notifications: state.user.notifications,
    showToast: state.notificationCenter.showToast,
    hasAdviserBlast: state.notificationCenter.hasAdviserBlast,
    adviserBlastPop: state.notificationCenter.adviserBlastPop,
    connectedAdvisers: state.user.connectedAdvisers,
    loginFetched: state.user.loginFetched,
    guidelineIsStarted: state.guideline.start,
    userFirstGuideline: state.user.userFirstGuideline,
    firstGuidelineRan: state.user.firstGuidelineRan,
    shownDashHelp: state.user.shownDashHelp,
    currentPortfolioId: state.policy.currentPortfolioId,
    factsheetData: state.policy.factsheetData
  }),
  // Map actions to props
  {
    fetchPolicy,
    initPhase,
    handleDashboardPolicyData,
    getShareFromData,
    updateUserDetails,
    handleSignOut,
    fetchMePolicy,
    getUser,
    initSharePhase,
    getShareMeDocuments,
    getNotifications,
    updateVisitedDashboard,
    runGuideline,
    getNotificationCenterList,
    markNotificationAsRead,
    getConnectedAdviser,
    createNotification,
    renderHeaderButton,
    userDashboardFetch,
    updateLoginFetched,
    clientAllModalDone,
    setShownDashHelp,
    updateSelectedPortfolio,
    toggleControl
  }
)(DashboardComponent);

export default injectIntl(withStyles(styles)(DashboardContainer));
