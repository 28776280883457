import store from '../store/index';
import { history } from '../views/App/history';
import _ from 'lodash';
import { RESET_AUTH } from '../store/auth/duck';
import { AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY } from '../constants/session';

const checkStatus = async response => {
  if ((response.status >= 200 && response.status < 300) || response.status === 422 || response.status === 500) {
    return response;
  } else if (response.status === 403) {
    if (store.getState().auth.isAuthenticated) {
      sessionStorage.removeItem(AUTO_TRIED_BIOMETRIC_LOGIN_SESSION_KEY);
      store.dispatch({ type: RESET_AUTH });
    }
    // if (localStorage.getItem('Authorization')) {
    //   let tokenLogin = localStorage.getItem('tokenLogin');
    //   //clear local and session storage and reset user state
    //   localStorage.clear();
    //   sessionStorage.clear();
    //
    //   const currentPathname = _.get(history, 'location.pathname');
    //
    //   const redirect = () => {
    //     if (tokenLogin === 'true') {
    //       history.push({ pathname: '/login', nextUrl: currentPathname });
    //     } else {
    //       localStorage.setItem('timeout', 'true');
    //       history.push({ pathname: '/login', state: { timeout: true }, nextUrl: currentPathname });
    //     }
    //     // window.open(URL, '_self');
    //   };
    //
    //   // redirect user to login page if the response returns 403 and the current path is not public to guest
    //   if (!currentPathname) {
    //     redirect();
    //   } else {
    //     const isPathExceptional = !!UNAUTHORIZED_REDIRECT_EXCEPTIONAL_PATHS.find(
    //       path => currentPathname.indexOf(path) > -1
    //     );
    //     if (!isPathExceptional) {
    //       redirect();
    //     } else {
    //       return;
    //     }
    //   }
    // }
  }
  const errorMessage = await response.text();
  const error = new Error(errorMessage ? errorMessage : response.statusText);
  error.response = response;
  throw error;
};

export default (url, options, includeCredentials = true) => {
  options = options || {};
  if (includeCredentials) {
    options.credentials = 'include';
  }
  return fetch(url, options)
    .then(checkStatus)
    .then(response => response);
};
