import { Grid, Typography } from '@material-ui/core';
import { decimalToString } from '../../../../utils/formatNumber';
import React from 'react';
import { injectIntl } from 'react-intl';

const ColouredCurrencyStyle = {
  borderBottom: '1px solid #dcdcdc',
  width: 'auto'
};

const ColoredCurrency = props => {
  const { intl, currency, color, value, fontSize } = props;
  return (
    <Grid container spacing={1} justify="center" alignItems="center" style={ColouredCurrencyStyle}>
      {/* <Grid item>
        <Typography color="textSecondary">{intl.formatMessage({ id: currency })}</Typography>
      </Grid> */}
      <Grid item style={{ flexGrow: 1, minWidth: 68 }}>
        <Typography style={{ color: color, fontSize: fontSize }}>{decimalToString(value)}</Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(ColoredCurrency);
