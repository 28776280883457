import _ from 'lodash';
import { LANGUAGE } from '../constants/language';

export const getTranslatedInsurerInfo = (insurer, locale) => {
  if (!insurer) {
    return {};
  }

  const { domain, image, hotline, email, region } = insurer || {};

  const result = {
    domain,
    image,
    hotline,
    email,
    region
  };

  [
    'title',
    'website',
    'weblogin',
    'payment',
    'claimInfo',
    'claimReport',
    'forms',
    'address',
    'address2',
    'payeeName',
    'exchangeRatePage'
  ].forEach(key => {
    if (insurer[key]) {
      result[key] = insurer[key][locale] ?? insurer[key][LANGUAGE.EN];
    } else {
      result[key] = '';
    }
  });

  return result;
};

export const isInsurerOther = insurer => _.get(insurer, 'insurerId') === 'Other';
export const isInsurerWhatIf = insurer => _.get(insurer, 'insurerId') === 'What if';
export const getInsurerTitleEn = insurer => _.get(insurer, `title.${LANGUAGE.EN}`, '');

export const getInsurerColor = ({ color }) => color || '#3966f8';
