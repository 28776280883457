import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';

const Style = {
  borderRadius: 8,
  background: 'var(--my-gradient)',
  color: '#fff',
  padding: '4px 4px 3px 4px',
  lineHeight: 1,
  minHeight: 'unset',
  minWidth: 'unset',
  fontSize: '0.75em',
  fontWeight: 400,
  boxShadow: 'var(--my-shadow)',
};

const ProfileButton = (props) => {
  const { intl, href, style } = props;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <Button variant="contained" style={{ ...Style, ...style }}>
        {intl.formatMessage({ id: 'mini-e-card' })}
      </Button>
    </a>
  );
};

export default injectIntl(ProfileButton);
