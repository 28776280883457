import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import '../../ReferAdviser/ReferAdviser.scss';
import _ from 'lodash';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import { sanitize } from 'dompurify';

const REFER_USER_URL = 'https://w3.portfoplus.com/partner/adviser-exclusive';

const StepTitleStyle = {
  fontWeight: 'bold',
  fontSize: '110%'
};

const LeftAlignSection = {
  textAlign: 'left'
};

const SelfBuyButtonStyle = {
  borderRadius: 4,
  padding: '0 8px'
};

const MedicalCardReferral = props => {
  const { intl, userDetails } = props;

  const name = _.get(userDetails, 'name');
  const email = _.get(userDetails, 'email');
  const referUserMsg = intl.formatMessage({ id: 'med-card-referral-share-msg' });
  const referUserLinkParams = `${name ? `Aname=${encodeURIComponent(name)}&` : ''}${
    email ? `Aemail=${encodeURIComponent(email)}` : ''
  }`;
  const referUserLink = `${REFER_USER_URL}?${referUserLinkParams}`;

  const copied = () => toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });
  const selfBuy = () => window.open(referUserLink, '_blank');

  return (
    <div className="medical-card-referral refer-adviser">
      <Container className="container" maxWidth="sm">
        <section className="banner-section">
          <img src="img/medical-card-referral/MedAdvisershare-header.png" alt="medical-card" />
        </section>

        <Typography style={{ whiteSpace: 'pre-wrap', fontSize: '150%', fontWeight: 'bold' }}>
          {intl.formatMessage({ id: 'med-card-referral-desc' })}
        </Typography>

        <section style={LeftAlignSection}>
          <Typography
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-referral-step-1' })) }}
            gutterBottom={true}
            style={StepTitleStyle}
          />
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography
                color="textSecondary"
                dangerouslySetInnerHTML={{
                  __html: sanitize(intl.formatMessage({ id: 'med-card-referral-step-1-hint' }))
                }}
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={selfBuy} style={SelfBuyButtonStyle}>
                {intl.formatMessage({ id: 'med-card-referral-self-buy' })}
              </Button>
            </Grid>
          </Grid>
        </section>
        <section>
          <ShareButtonGroup
            title={referUserMsg}
            url={referUserLink}
            isCTA
            CopyTextFieldProps={{
              multiline: true
            }}
          />
        </section>

        <hr />
        <section style={LeftAlignSection}>
          <Typography
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-referral-step-2' })) }}
            gutterBottom={true}
            style={StepTitleStyle}
          />
          <Typography
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-referral-step-2-hint' })) }}
          />
        </section>
        <hr />
        <section style={LeftAlignSection}>
          <Typography
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-referral-step-3' })) }}
            gutterBottom={true}
            style={StepTitleStyle}
          />
          <Typography
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-referral-step-3-hint' })) }}
          />
        </section>
      </Container>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(MedicalCardReferral);

export default injectIntl(container);
