import React from 'react';
import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import PFinRow from '../PFinRow/PFinRow';
import _ from 'lodash';

const yearHeaderStyle = { borderBottom: 'solid 6px grey' };
const principalHeaderStyle = { borderBottom: 'solid 6px #3966f8' };
const cashValueHeaderStyle = { borderBottom: 'solid 6px #665EFF' };
const expenseHeaderStyle = { borderBottom: 'solid 6px #FFC000' };
const returnHeaderStyle = { borderBottom: 'solid 6px #0FAF3F' };
const cashBackHeaderStyle = { borderBottom: 'solid 6px #3EC7F8' };

const PFinTable = ({
  intl,
  years,
  principal,
  base,
  bankLoanInterest,
  firstYearBonus,
  yearlyData,
  overrideLoanInterest,
  rowOpen,
  onToggleOpen
}) => (
  <Card className="pfa-item-details-card pfa-card orange">
    <CardContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={yearHeaderStyle}>
                {intl.formatMessage({ id: 'Year' })}
              </TableCell>
              <TableCell align="center" style={principalHeaderStyle}>
                {intl.formatMessage({ id: 'pfa-base' })}
              </TableCell>
              <TableCell align="center" style={expenseHeaderStyle}>
                {intl.formatMessage({ id: 'pfa-total-expense' })}
              </TableCell>
              <TableCell align="center" style={cashValueHeaderStyle}>
                {intl.formatMessage({ id: 'pfa-cash-value' })}
              </TableCell>
              <TableCell align="center" style={cashBackHeaderStyle}>
                {intl.formatMessage({ id: 'pfa-net-cash' })}
              </TableCell>
              <TableCell align="center" style={returnHeaderStyle}>
                {intl.formatMessage({ id: 'pfa-return' })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {years.map((e, i) =>
              i !== 0 ? (
                <PFinRow
                  key={i}
                  principal={principal}
                  base={base}
                  bankLoanInterest={bankLoanInterest}
                  term={e}
                  firstYearBonus={firstYearBonus}
                  overrideLoanInterest={overrideLoanInterest}
                  {...yearlyData}
                  i={i}
                  intl={intl}
                  open={_.get(rowOpen, `[${i}]`, false)}
                  onToggleOpen={onToggleOpen}
                />
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
);

export default PFinTable;
