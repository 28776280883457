import React, { PureComponent } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { get } from 'lodash';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DatePicker } from '@material-ui/pickers';
import { formatNumber, formatOnlyNumber, formatOnlyUnit } from '../../../../utils/formatNumber';
import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import CreateIcon from '@material-ui/icons/Create';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Slide } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import TimeLeftCard from '../../Business/partials/ProgressComponent/Cards/TimeLeftCard/TimeLeftCard.js';
import ClosedBizCard from '../../../Advisor/Business/partials/ProgressComponent/Cards/ClosedBizCard.js';
import QuarterProgressCard from '../../../Advisor/Business/partials/ProgressComponent/Cards/QuarterProgressCard.js';
import FollowUpCard from '../../../Advisor/Business/partials/ProgressComponent/Cards/FollowUpCard.js';
import QuarterProgressModal from '../../../Advisor/Business/partials/ProgressComponent/Dialog/QuarterProgressModal.js';
import MoreInfoIconButton from '../../../../components/MoreInfoIconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class TeamsAnalysisView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      teamQuarterTarget: 0,
      teamClosedBiz: 0,
      teamQuarterTargetEdit: false,
      teamCloseBizEdit: false,
      teamSumOfClosedBiz: 0,
      teamCurrentProgressOption: 'showExpectedFrom',
      teamCurrentProgress: 0,
      oldValue: 'showExpectedFrom',
      //Modal state
      quarterEndDate: null,
      teamTargetModal: false,
      teamClosedBizInfoModal: false,
      teamCurrentProgressModal: false,
      teamPotentialInfoModal: false,
      quarterEndDateModal: false,
      quarterProgressModal: false,
      bigDate: null,
      key: ''
    };
    this.updateTeamQuarterTarget = this.updateTeamQuarterTarget.bind(this);
    this.saveConfirmedBiz = this.saveConfirmedBiz.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setTeamQuarterAsSame = this.setTeamQuarterAsSame.bind(this);
    this.setCloseBusinessAsSame = this.setCloseBusinessAsSame.bind(this);
    // this.updateTeamCurrentProgress = this.updateTeamCurrentProgress.bind(this);
  }

  handleChange(e) {
    let { name, value } = e.target;
    const { teamQuarterTarget } = this.props;
    if (name === 'teamCurrentProgressOption') {
      this.setState({
        [name]: value,
        teamcurrentProgress: _.get(teamQuarterTarget, '[0].teamCurrentProgress', 0)
      });
    } else {
      if (value === '1') {
        this.setState({ [name]: value });
      } else {
        this.setState({ modalIsOpen: true });
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { adviserBusiness } = this.props;
    this.setState({
      bigDate: adviserBusiness && adviserBusiness.bigDate ? adviserBusiness.bigDate : null,
      quarterEndDate:
        adviserBusiness && adviserBusiness.quarterEndDate ? moment.parseZone(adviserBusiness.quarterEndDate) : null
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updateTeamTargetPhase === true) {
      this.setState({ teamQuarterTargetEdit: false, teamCloseBizEdit: false });
    }
  }

  //To update the business quarter target
  updateTeamQuarterTarget = () => {
    const { updateTeamsOfAdviser, activeTeamData } = this.props;
    const { teamQuarterTarget } = this.state;
    // If the value has no changes, exit
    if (activeTeamData.sumOfTeamsTarget === teamQuarterTarget) {
      this.closeModal();
      return;
    }

    let updateObject = {
      teamID: activeTeamData.teamId,
      updates: {}
    };
    if (!teamQuarterTarget) {
      updateObject.updates.teamTarget = 0;
    } else {
      updateObject.updates.teamTarget = teamQuarterTarget;
    }

    updateTeamsOfAdviser(updateObject);
    this.setState({
      teamQuarterTargetEdit: false
    });
    this.closeModal();
  };

  //To save the confirmesBiz
  saveConfirmedBiz = () => {
    const { teamSumOfClosedBiz } = this.state;
    const { updateTeamsOfAdviser, activeTeamData } = this.props;
    let updateObject = {
      teamID: activeTeamData.teamId,
      updates: { teamClosedBiz: teamSumOfClosedBiz }
    };

    if (teamSumOfClosedBiz === '') {
      this.setState({ confirmedBiz: 0 });
    }
    updateTeamsOfAdviser(updateObject);
    this.setState({ teamCloseBizEdit: false });
    this.closeModal();
  };

  teamTargetInfoModal() {
    this.setState({ teamTargetModal: true });
  }

  teamClosedBizInfoModal() {
    this.setState({ teamClosedBizInfoModal: true });
  }

  teamPotentialInfoModal() {
    this.setState({ teamPotentialInfoModal: true });
  }

  quarterEndDateModal() {
    this.setState({ quarterEndDateModal: true });
  }

  //Handle the cut off date
  handleQuarterDateChange(date, key) {
    if (key === 'quaterEndDate') {
      if (date) {
        var qdate = date ? moment(date).format('YYYY-MM-DD') : '';
        let qdate1 = date ? moment.utc(qdate).format('YYYY-MM-DDT00:00:00.00Z') : '';
        this.setState({ quarterEndDate: qdate1, key: key });
      } else {
        this.setState({ quarterEndDate: null, key: key });
      }
    }
    if (key === 'bigDate') {
      if (date) {
        let d = date ? moment(date).format('YYYY-MM-DD') : '';
        let date1 = d ? moment.utc(d).format('YYYY-MM-DDT00:00:00.00Z') : '';
        this.setState({ bigDate: date1, key: key });
      } else {
        this.setState({ bigDate: null, key: key });
      }
    }
  }

  //To update the quarter date
  updateQuarterDate = () => {
    const { quarterEndDate, bigDate } = this.state;
    const { updateAdviserBusiness } = this.props;
    let obj = {};
    obj.quarterEndDate = quarterEndDate ? quarterEndDate : null;
    obj.bigDate = bigDate ? bigDate : null;
    this.props.setGlobalQuarterEndDate(quarterEndDate);
    updateAdviserBusiness(obj);
    this.setState({ quarterEndDateModal: false });
  };

  closeModal() {
    const { adviserBusiness } = this.props;
    this.setState({
      teamTargetModal: false,
      quarterProgressModal: false,
      teamClosedBizInfoModal: false,
      teamPotentialInfoModal: false,
      teamQuarterTargetEdit: false,
      teamCloseBizEdit: false,
      quarterEndDateModal: false,
      bigDate: adviserBusiness && adviserBusiness.bigDate ? adviserBusiness.bigDate : null,
      quarterEndDate:
        adviserBusiness && adviserBusiness.quarterEndDate ? moment.parseZone(adviserBusiness.quarterEndDate) : null
    });
  }

  setCloseBusinessAsSame(value) {
    this.setState({
      teamSumOfClosedBiz: value,
      teamCloseBizEdit: true
    });
  }

  setTeamQuarterAsSame(value) {
    this.setState({
      teamQuarterTarget: value,
      teamQuarterTargetEdit: true
    });
  }

  render() {
    const { adviserDetails, intl, activeTeamData, adviserBusiness, myselfData } = this.props;
    const {
      teamQuarterTargetEdit,
      bigDate,
      teamCloseBizEdit,
      teamCurrentProgressOption,
      teamCurrentProgress
    } = this.state;
    let isEdit = activeTeamData ? (activeTeamData.teamName === 'Total' ? false : true) : false;

    let teamSumOfClosedBiz;
    if (!teamCloseBizEdit) {
      teamSumOfClosedBiz = activeTeamData.sumOfTeamsClosedBiz;
      this.setState({ teamSumOfClosedBiz: activeTeamData.sumOfTeamsClosedBiz });
    } else {
      teamSumOfClosedBiz = this.state.teamSumOfClosedBiz;
    }

    let teamQuarterTarget;
    if (!teamQuarterTargetEdit) {
      teamQuarterTarget = activeTeamData.sumOfTeamsTarget;
      this.setState({ teamQuarterTarget: activeTeamData.sumOfTeamsTarget });
    } else {
      teamQuarterTarget = this.state.teamQuarterTarget;
    }

    if (isEdit === false) {
      teamQuarterTarget = _.get(activeTeamData, 'sumOfQuarterTarget', 0);
      teamSumOfClosedBiz = _.get(activeTeamData, 'sumOfClosedBiz', 0);
    }

    let quarterEndDateVal = this.state.quarterEndDate;
    let currentYear = moment().format('YYYY');
    let currentMonth = parseInt(moment().format('MM'));
    let currentDate = moment().startOf('day');
    let Q =
      currentMonth <= 3
        ? currentYear + '-03-31'
        : currentMonth <= 6
        ? currentYear + '-06-30'
        : currentMonth <= 9
        ? currentYear + '-09-30'
        : currentMonth <= 12
        ? currentYear + '-12-31'
        : '';
    let QMonthStartDate1 =
      currentMonth <= 3
        ? currentYear + '-01-01'
        : currentMonth <= 6
        ? currentYear + '-04-01'
        : currentMonth <= 9
        ? currentYear + '-07-01'
        : currentMonth <= 12
        ? currentYear + '-10-01'
        : '';
    let endDate =
      adviserBusiness && adviserBusiness.quarterEndDate
        ? moment.parseZone(adviserBusiness.quarterEndDate).format('YYYY-MM-DD')
        : Q;

    // let QEndDate = moment(endDate).toDate();
    let pastDays = moment().diff(QMonthStartDate1, 'days') + 1;

    let month = currentMonth <= 3 ? 3 : currentMonth <= 6 ? 6 : currentMonth <= 9 ? 9 : currentMonth <= 12 ? 12 : 12;
    const today = new Date();
    var year = today.getFullYear();
    var day = 0;
    const maxDate = new Date(year, month, day);
    const date1 = moment(today)
      .add(1, 'days')
      .format('YYYY-MM-DD');
    const m1 = moment(maxDate).format('YYYY-MM-DD');

    //calculate the range according to the quarter
    let r1 = 0;

    if (moment(endDate).isAfter(currentDate)) {
      r1 = moment(endDate).diff(QMonthStartDate1, 'days') + 1;
    } else {
      r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
    }

    let quarterBusiness = r1 > 0 ? (pastDays / r1) * teamQuarterTarget : 0;
    let highOpportunityCount = _.get(activeTeamData, 'highOpportunityCount', 0);
    let mediumOpportunityCount = _.get(activeTeamData, 'mediumOpportunityCount', 0);
    let lowOpportunityCount = _.get(activeTeamData, 'lowOpportunityCount', 0);
    let noneOpportunityCount = _.get(activeTeamData, 'noneOpportunityCount', 0);
    let opportunityCountObj = {
      highOpportunityCount: highOpportunityCount,
      mediumOpportunityCount: mediumOpportunityCount,
      lowOpportunityCount: lowOpportunityCount,
      noneOpportunityCount: noneOpportunityCount
    };
    let totalOpportunityCount =
      highOpportunityCount + mediumOpportunityCount + lowOpportunityCount + noneOpportunityCount;

    let sumOfManualInput = _.get(activeTeamData, 'sumOfManualInput', 0);

    const highOpportunityBizVol = _.get(activeTeamData, 'sumOfHighAdjustedBiz', 0);
    const mediumOpportunityBizVol = _.get(activeTeamData, 'sumOfMedAdjustedBiz', 0);
    const lowOpportunityBizVol = _.get(activeTeamData, 'sumOfLowAdjustedBiz', 0);
    const noneOpportunityBizVol = _.get(activeTeamData, 'sumOfNoneAdjustedBiz', 0);
    const opportunityBizVolObj = {
      highOpportunityBizVol: highOpportunityBizVol,
      mediumOpportunityBizVol: mediumOpportunityBizVol,
      lowOpportunityBizVol: lowOpportunityBizVol,
      noneOpportunityBizVol: noneOpportunityBizVol,
      manualBizVol: _.get(activeTeamData, 'sumOfManualInput', null)
    };

    let teamAdjustedBiz =
      parseInt(teamSumOfClosedBiz) +
      _.get(activeTeamData, 'sumOfHighAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfMedAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfLowAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfNoneAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfManualInput', 0);
    const outerChartValue = teamCurrentProgressOption === 'showManualInput' ? teamCurrentProgress : teamAdjustedBiz;
    //Calculate the percentage of expected closed biz

    let totalTrackingBiz =
      _.get(activeTeamData, 'sumOfHighAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfMedAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfLowAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfNoneAdjustedBiz', 0) +
      _.get(activeTeamData, 'sumOfManualInput', 0);
    let outerProgressValue = outerChartValue;
    let innerProgressValue = quarterBusiness;

    const QuarterOuterProgressPercentage = teamQuarterTarget > 0 ? (totalTrackingBiz / teamQuarterTarget) * 100 : 0;

    //Status to show the leading to lagging messages
    let headingStatus = '';
    if (outerProgressValue !== 0 && outerProgressValue >= innerProgressValue && QuarterOuterProgressPercentage < 100) {
      headingStatus = 'onTrack';
    } else if (outerProgressValue < innerProgressValue && QuarterOuterProgressPercentage < 100) {
      headingStatus = 'offTrack';
    } else if (QuarterOuterProgressPercentage >= 100) {
      headingStatus = 'great';
    } else {
      headingStatus = 'zeroProgress';
    }

    let QEnd = r1 - pastDays;
    let chartData = r1 > 0 ? (pastDays / r1) * 100 : 100;

    let adviserBusiness_bigDate = get(adviserBusiness, 'bigDate', null);
    let bigDateEnd1 = moment.utc(moment().format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z');
    let bigDateEnd = adviserBusiness_bigDate ? moment(adviserBusiness_bigDate).diff(moment(bigDateEnd1), 'days') : '-';

    let calculatedTeamTarget = 0;
    let calculatedClosedBiz = 0;
    for (let tempAdviser of adviserDetails) {
      calculatedTeamTarget += tempAdviser.quarterTarget || 0;
      calculatedClosedBiz += tempAdviser.closedBiz || 0;
    }

    return (
      <div>
        <Grid container spacing={0} style={{ lineHeight: '1.35' }}>
          <Grid container spacing={0} style={{ margin: '0px' }}>
            <Grid item xs={6} sm={6} lg={6}>
              <TimeLeftCard
                r1={r1}
                QEnd={QEnd}
                chartData={chartData}
                bigDateEnd={bigDateEnd}
                openModal={this.quarterEndDateModal.bind(this)}
              />
            </Grid>

            <Grid container item xs={6} sm={6} lg={6}>
              <Grid item xs={12}>
                <Card className="progress-card" id="current-progress-card" style={{ paddingBottom: '20px' }}>
                  <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                    <Grid item className="progress-card-title">
                      {intl.formatMessage({ id: 'Team Target' })}
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        className="edit-button edit-info-button"
                        onClick={this.teamTargetInfoModal.bind(this)}
                        style={{ float: 'right' }}
                      >
                        <CreateIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={0}>
                    <Grid item xs={12} style={{ color: '#3966f8' }}>
                      <span>
                        <span className="progress-card-number">
                          {teamQuarterTarget > 0 ? formatOnlyNumber(teamQuarterTarget, intl) : '-'}{' '}
                        </span>
                        <span className="progress-card-unit">
                          {teamQuarterTarget ? formatOnlyUnit(teamQuarterTarget, intl) : ''}
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <ClosedBizCard
                  confirmedBiz={teamSumOfClosedBiz}
                  expectedBiz={quarterBusiness}
                  quarterTarget={teamQuarterTarget}
                  openModal={this.teamClosedBizInfoModal.bind(this)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <QuarterProgressCard
                headingStatus={headingStatus}
                outerChartValue={outerChartValue}
                targetBiz={totalTrackingBiz}
                QEnd={QEnd}
                quarterTarget={teamQuarterTarget}
                confirmedBiz={teamSumOfClosedBiz}
                expectedBiz={quarterBusiness}
                openModal={() => this.setState({ quarterProgressModal: true })}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <FollowUpCard
                targetBiz={totalTrackingBiz}
                opportunityBizVolObj={opportunityBizVolObj}
                opportunityCount={opportunityCountObj}
                numberOfRecord={totalOpportunityCount}
                manualInput={sumOfManualInput}
                leftBtnComponent={
                  <MoreInfoIconButton
                    className="pt-10 progress-card-more-info-btn"
                    handleClick={this.props.openFollowupDiscriptionDialog}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.teamTargetModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={Transition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Team Target' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'Teammates_Target' })}</DialogContent>
          <DialogContent>
            <div className="modal-body body_scroll">
              {adviserDetails &&
                adviserDetails.length > 0 &&
                activeTeamData &&
                adviserDetails.map((val, index) => {
                  if (
                    activeTeamData.teamName === val.teamName ||
                    (myselfData && myselfData._id === val._id) ||
                    activeTeamData.teamName === 'Total'
                  ) {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key popup--data--padding">
                          {val.TeamAdviserName}
                        </div>
                        <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key popup--data--padding">
                          {val.quarterTarget ? formatNumber(val.quarterTarget, intl) : 0}
                        </div>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
            </div>
            <div className="row popup--total--padding">
              <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
                {intl.formatMessage({ id: 'teamTotal' })}
              </div>
              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                {formatNumber(calculatedTeamTarget, intl)}
              </div>
            </div>
          </DialogContent>
          {isEdit === true && (
            <DialogContent>
              <div className="">{intl.formatMessage({ id: 'Customize_Team_Target' })}</div>
              <div className="form-group text-center adv_biz_modal_key" style={{ margin: '10px 0px 0px 0px' }}>
                <NumberFormat
                  className={'form-control'}
                  thousandSeparator={true}
                  style={{
                    width: '50%',
                    borderBottom: '1px solid gray',
                    height: '25px'
                  }}
                  onValueChange={values => {
                    const { value } = values;
                    this.setState({
                      teamQuarterTarget: value,
                      teamQuarterTargetEdit: true
                    });
                  }}
                  value={teamQuarterTarget}
                />
                <Button
                  variant="contained"
                  onClick={() => this.setTeamQuarterAsSame(calculatedTeamTarget)}
                  color="primary"
                  style={{ margin: '0px 0px 0px 10px', padding: '0px 4px', minWidth: 'unset' }}
                >
                  {intl.formatMessage({ id: 'Same' })}
                </Button>
              </div>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={() => this.updateTeamQuarterTarget()} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.teamClosedBizInfoModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={Transition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Closed Biz' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'Teammates_Closed_Biz' })}</DialogContent>
          <DialogContent>
            <div className="modal-body body_scroll">
              {adviserDetails &&
                adviserDetails.length > 0 &&
                activeTeamData &&
                adviserDetails.map((val, index) => {
                  if (
                    activeTeamData.teamName === val.teamName ||
                    (myselfData && myselfData._id === val._id) ||
                    activeTeamData.teamName === 'Total'
                  ) {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key popup--data--padding grey-color">
                          {val.TeamAdviserName}
                        </div>
                        <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key popup--data--padding grey-color">
                          {val.closedBiz ? formatNumber(val.closedBiz, intl) : 0}
                        </div>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
            </div>
            <div className="row popup--total--padding">
              <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
                {intl.formatMessage({ id: 'teamTotal' })}
              </div>
              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                {formatNumber(calculatedClosedBiz, intl)}
              </div>
            </div>
          </DialogContent>
          {isEdit === true && (
            <DialogContent>
              <div className="">{intl.formatMessage({ id: 'Customize_Close_Biz' })}</div>
              <div className="form-group text-center adv_biz_modal_key" style={{ margin: '10px 0px 0px 0px' }}>
                <NumberFormat
                  className={'form-control'}
                  thousandSeparator={true}
                  style={{
                    width: '64%',
                    borderBottom: '1px solid gray',
                    height: '25px'
                  }}
                  onValueChange={values => {
                    const { value } = values;
                    this.setState({
                      teamSumOfClosedBiz: value,
                      teamCloseBizEdit: true
                    });
                  }}
                  value={teamSumOfClosedBiz}
                />
                <Button
                  variant="contained"
                  onClick={() => this.setCloseBusinessAsSame(calculatedClosedBiz)}
                  color="primary"
                  style={{ margin: '0px 0px 0px 10px', padding: '0px 4px', minWidth: 'unset' }}
                >
                  {intl.formatMessage({ id: 'Same' })}
                </Button>
              </div>
            </DialogContent>
          )}

          <DialogActions>
            <Button onClick={() => this.saveConfirmedBiz()} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.teamPotentialInfoModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={Transition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Potential Business' })}</DialogTitle>
          <DialogContent>
            <div className="modal-body body_scroll">
              {adviserDetails &&
                adviserDetails.length > 0 &&
                activeTeamData &&
                adviserDetails.map((val, index) => {
                  if (
                    activeTeamData.teamName === val.teamName ||
                    (myselfData && myselfData._id === val._id) ||
                    activeTeamData.teamName === 'Total'
                  ) {
                    let potentialBiz = 0;
                    if (val.currentProgressOption === 'showManualInput') {
                      potentialBiz = _.get(val, 'currentProgress', 0);
                    } else {
                      potentialBiz = _.get(val, 'adjustOpenBiz', 0);
                    }
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key popup--data--padding grey-color">
                          {val.TeamAdviserName}
                        </div>
                        <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key popup--data--padding grey-color">
                          {val.teamClosedBiz ? formatNumber(potentialBiz, intl) : 0}
                          {' ('}
                          {val.count} {intl.formatMessage({ id: 'cases' })}
                          {') '}
                          {val.currentProgressOption === 'showManualInput' && (
                            <span>({intl.formatMessage({ id: 'ManualInput' })})</span>
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}
            </div>
            <div className="row popup--total--padding">
              <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
                {intl.formatMessage({ id: 'Total' })}:
              </div>
              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                {formatNumber(totalTrackingBiz, intl)}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Quarter Targer */}
        <QuarterProgressModal
          open={this.state.quarterProgressModal}
          onClose={() => this.setState({ quarterProgressModal: false })}
          okBtnOnClick={() => this.setState({ quarterProgressModal: false })}
        />

        <Dialog
          open={this.state.quarterEndDateModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={Transition}
          fullWidth={true}
          keepMounted
        >
          <DialogTitle id="alert-dialog-title"> {intl.formatMessage({ id: 'Date Setting' })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: 'dateSetting_desc' })}</DialogContentText>
            <div className="caseclose-form">
              <div className="form-group" style={{ marginBottom: '-15px' }}>
                <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key padding__left-0">
                  <label className="control-label adv_biz_modal_key">
                    {intl.formatMessage({ id: 'Quarter End' })} :
                  </label>
                </div>
                <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                  <div className="modal-text">
                    <DatePicker
                      name="quarterEndDate"
                      clearable={true}
                      format="DD-MMM-YYYY"
                      className="case-datepicker close-case-date case-open-datepicker input__spacing qEnd--Date"
                      minDate={new Date(date1)}
                      maxDate={new Date(m1)}
                      value={quarterEndDateVal}
                      onChange={date => this.handleQuarterDateChange(date, 'quaterEndDate')}
                      autoOk={true}
                      invalidDateMessage={null}
                      minDateMessage={null}
                      maxDateMessage={null}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
                  <label className="control-label adv_biz_modal_key">{intl.formatMessage({ id: 'Big Date' })} :</label>
                </div>
                <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                  <div className="modal-text">
                    <DatePicker
                      name="bigDate"
                      clearable={true}
                      format="DD-MMM-YYYY"
                      className="case-datepicker close-case-date case-open-datepicker input__spacing qEnd--Date"
                      value={bigDate}
                      onChange={date => this.handleQuarterDateChange(date, 'bigDate')}
                      autoOk={true}
                      invalidDateMessage={null}
                      minDateMessage={null}
                      maxDateMessage={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              {intl.formatMessage({ id: 'Close' })}
            </Button>
            <Button onClick={this.updateQuarterDate} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default TeamsAnalysisView;
