import { fetch } from '../../utils';
import Backend from '../../backend';

const ordinaryJSONFetch = (url, method, data) => {
  return fetch(url, {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const createQRCode = data => {
  return ordinaryJSONFetch(Backend.methodUrl('createQRCode'), 'POST', data);
};

export const checkQRCode = data => {
  return ordinaryJSONFetch(Backend.methodUrl('checkQRCode'), 'POST', data);
};

export const useQRCode = data => {
  return ordinaryJSONFetch(Backend.methodUrl('useQRCode'), 'POST', data);
};
