import { desktop } from '../../utils/device';
import React from 'react';

const DesktopStyle = props => {
  return (
    desktop() && (
      <style
        dangerouslySetInnerHTML={{
          __html: `
                    ::-webkit-scrollbar {
                      width: 5px;
                    }
                    
                    ::-webkit-scrollbar-track {
                      background: #f1f1f1;
                    }
                    
                    ::-webkit-scrollbar-thumb {
                      background: #c1c1c1;
                    }
                    
                    ::-webkit-scrollbar-thumb:hover {
                      background: #9E9E9E;
                    }
                  `
        }}
      />
    )
  );
};

export default DesktopStyle;
