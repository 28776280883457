import ConfirmModal from '../ConfirmModal/ConfirmModal';
import React from 'react';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../store/control/duck';
import _ from 'lodash';

class DiscardConfirmDialog extends React.PureComponent {
  close = () => this.props.mergeDialog('discardWarning', { closed: true });
  closed = () => this.props.toggleDialog('discardWarning', false);
  confirm = () => {
    const onConfirm = _.get(this.props, 'discardWarningDialog.onConfirm');
    if (onConfirm) {
      onConfirm();
    }
    this.close();
  };

  render() {
    const { discardWarningDialog } = this.props;

    if (!discardWarningDialog) {
      return null;
    }

    const { closed, title, content } = discardWarningDialog;

    return (
      <ConfirmModal
        open={!closed}
        onExited={this.closed}
        title={title ? title : 'leave-page'}
        content={content ? content : 'changes-that-you-made-will-not-be-saved'}
        buttons={[
          {
            text: 'Cancel',
            variant: 'contained',
            onClick: this.close
          },
          {
            text: 'leave-without-saving',
            onClick: this.confirm
          }
        ]}
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    discardWarningDialog: state.control.discardWarningDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog
  },
  // mergeProps
  null,
  // options
  {}
)(DiscardConfirmDialog);

export default container;
