import React from 'react';
import { injectIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const DropzoneWrapperStyle = {
  cursor: 'pointer',
  padding: 24,
  textAlign: 'center',
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
};

const Dropzone = props => {
  const { intl, files, onDropAccepted, error, ...rest } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: onDropAccepted,
    ...rest
  });

  return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div style={DropzoneWrapperStyle}>
          <CloudUploadIcon />
          <Typography color="textSecondary">{intl.formatMessage({ id: 'dropzone-upload-hint' })}</Typography>
          <Typography color="primary" style={{ textDecoration: 'underline' }}>
            {intl.formatMessage({ id: 'clicking-here' })}
          </Typography>
        </div>
      </div>
      <aside>
        {(files || []).map(file => (
          <div key={file.name}>
            <div>{file.preview ? <img src={file.preview} alt="preview" /> : <p>{file.path}</p>}</div>
          </div>
        ))}
      </aside>
      {!!error && <Typography color="error">{error}</Typography>}
    </section>
  );
};

export default injectIntl(Dropzone);
