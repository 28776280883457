import React, { PureComponent } from 'react';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ProCornerTriangle from '../../components/CornerTriangle/ProCornerTriangle';
import './Message.scss';
import ConnectWithAdviser from '../../components/connectWithAdviser';
import { SlideTransition } from '../../components/Transition/Transition';
import _ from 'lodash';
import { isNavAside } from '../../utils/device';
import { tracking } from '../../utils/tracking';

class MessageView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      receiverType: '',
      content: '',
      email: '',
      whatsapp: '',
      chatModal: false,
      err: {},
      connectModalOpen: false,
      errMessage: ''
    };
  }

  componentDidMount() {
    const { renderHeaderButton } = this.props;
    renderHeaderButton(true);
  }

  UNSAFE_componentWillMount() {
    this.props.getConnectedAdviser();
    let analysisFlag = this.props.location.state;
    analysisFlag = analysisFlag && analysisFlag.analysisPage ? true : false;
    if (analysisFlag) {
      this.setState({ chatModal: true });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  openChatModal() {
    if (!this.props.connectedAdvisers || this.props.connectedAdvisers.length === 0) {
      this.setState({ connectModalOpen: true });
    } else {
      this.setState({ chatModal: true });
    }
  }

  closeChatModal() {
    const prevLocation = this.props.location.state ? this.props.location.state.prevLocation : '';
    if (prevLocation) {
      this.props.history.push({
        pathname: prevLocation
      });
    }
    this.setState({ chatModal: false });
  }

  // submitForm() {
  //   var user = this.props.userDetails;
  //   const err = {};
  //   if (
  //     this.state.email !== '' &&
  //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
  //   ) {
  //     err.email = 'Invalid email.';
  //   }
  //   if (this.state.content === '') {
  //     err.content = 'This field is required';
  //   }
  //   this.setState({ err });
  //   if (!Object.keys(err).length) {
  //     var formData = {};
  //     formData.senderEmail = this.state.email;
  //     formData.whatsapp = this.state.whatsapp;
  //     formData.content = this.state.content;
  //     formData.userType = 'User';
  //    this.props.feedback(formData);
  //   }
  // }

  routeReferral() {
    const { intl, userDetails, connectedAdvisers, history, toggleDialog } = this.props;
    if (connectedAdvisers && connectedAdvisers.length > 0) {
      const adviserName = _.get(connectedAdvisers, '[0].name');
      if (isNavAside()) {
        toggleDialog('referralQRCode', {
          referLinkSearch: {
            defaultMessage: intl.formatMessage(
              { id: 'client-referral-qr-default-msg' },
              { adviserName: adviserName, clientName: userDetails?.name }
            )
          },
          customCTAMessage: intl.formatMessage({ id: 'referral-card-client-message' }),
          defaultMessageIntlId: 'referral-link-message-2',
          defaultMessageExtras: { name: _.get(connectedAdvisers, '[0].name') }
        });
      } else {
        history.push({
          pathname: '/get_users'
        });
      }
    } else {
      this.setState({ connectModalOpen: true });
    }
  }

  routeMyAdviser() {
    const { connectedAdvisers, history } = this.props;
    const adviserId = _.get(connectedAdvisers, '[0]._id');
    if (adviserId) {
      history.push({ pathname: `/adviser/${adviserId}` });
      tracking('User Click on My Adviser Profile+');
    } else {
      this.setState({ connectModalOpen: true });
    }
  }

  render() {
    let analysisFlag = this.props.location.state;
    analysisFlag = analysisFlag && analysisFlag.analysisPage ? true : false;
    const { connectedAdvisers, intl } = this.props;
    let productionEnv = process.env.NODE_ENV === 'production' ? true : false;
    return (
      <div className="message-page">
        <ConnectWithAdviser
          open={this.state.connectModalOpen}
          onClose={() => {
            this.setState({ connectModalOpen: false });
          }}
          intl={intl}
          clickAdviser={connectedAdvisers && connectedAdvisers.length === 0 ? true : false}
        />
        <div id="wrapper" className={!productionEnv ? 'user-referral' : 'user-referral-pro'}>
          <div className="row">
            <div className="col-lg-12 messagePadding">
              <ul className="card-list">
                <li onClick={this.routeMyAdviser.bind(this)}>
                  {/* <Link to="/refer_client"> */}
                  <div className="portfolioCard1 my-adviser">
                    <img src="img/profile-plus_icon.png" alt="p-page" />
                    <h4>{intl.formatMessage({ id: 'my-adviser-p-page' })}</h4>
                    <ProCornerTriangle />
                  </div>
                  {/* </Link> */}
                </li>
                <li onClick={this.routeReferral.bind(this)}>
                  <div className="portfolioCard1">
                    <img src="img/person.png" alt="" />
                    <h4>{intl.formatMessage({ id: 'Refer My Adviser' })}</h4>
                  </div>
                </li>
                <li onClick={this.openChatModal.bind(this)}>
                  <div className="portfolioCard1">
                    <img src="img/add_chat.png" alt="" />
                    <h4>{intl.formatMessage({ id: 'Chat' })}</h4>
                  </div>
                </li>
                {/* {!productionEnv && (
                    <li>
                      <Link to="/newsFeed">
                        <div className="portfolioCard1">
                          <img src="img/recommendation.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'News Feed' })}</h4>
                        </div>
                      </Link>
                    </li>
                  )} */}
              </ul>
              {connectedAdvisers && connectedAdvisers.length > 0 && (
                <Dialog
                  open={this.state.chatModal}
                  onClose={this.closeChatModal.bind(this)}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                  TransitionComponent={SlideTransition}
                  fullWidth={true}
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {intl.formatMessage({
                      id: 'Select the adviser you want to chat with'
                    })}
                  </DialogTitle>
                  <DialogContent>
                    <div className="chat-modal">
                      <ul>
                        {connectedAdvisers && connectedAdvisers.length > 0
                          ? connectedAdvisers.map((val, index) => {
                              let phoneNoText = val.whatsapp ? 'phone=' + val.whatsapp.replace('+', '') : '';
                              let url =
                                'https://api.whatsapp.com/send?' +
                                phoneNoText +
                                '&text=' +
                                intl.formatMessage({
                                  id: 'Hi, we connected in PortfoPlus, I would like to message you.'
                                });
                              if (analysisFlag) {
                                url =
                                  'https://api.whatsapp.com/send?' +
                                  phoneNoText +
                                  '&text=' +
                                  intl.formatMessage({
                                    id:
                                      'HI, I checked the Analysis in PortfoPlus, I would like to have a review of my financial planning'
                                  });
                              }
                              return (
                                <a rel="noopener noreferrer" key={index} href={url} target="_blank">
                                  <li>
                                    <div className="avatar-chat">
                                      <div id="profileImageMsg">
                                        {val.name && val.name.length > 0 ? val.name[0] : ''}
                                      </div>
                                    </div>
                                    <span id="firstNameAdv">{val.name}</span>
                                  </li>
                                </a>
                              );
                            })
                          : ''}
                      </ul>
                    </div>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageView;
