import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { withRouter } from 'react-router';

const ConnectionRequestFailDialog = props => {
  const { history, location, match, staticContext, intl, open, ...rest } = props;

  const close = () => {
    history.replace('/');
  };

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'connection-request-fail-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'connection-request-fail-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(withRouter(ConnectionRequestFailDialog));
