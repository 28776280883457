import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import './AssistantCustomizeDialog.scss';
import IntegerControl from '../../../../../components/IntegerControl/IntegerControl';
import _ from 'lodash';
import { updateAssistantCategories } from '../../../../../store/assistant/duck';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TRACKING_MAX_DAYS } from '../../../../../constants/tracking';

class AssistantCustomizeDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categories: undefined,
      changed: false
    };
  }

  close = () => {
    this.props.toggleDialog('assistantCustomize', { ...this.props.assistantCustomizeDialog, closed: true });
  };
  closed = () => {
    this.setState({ categories: undefined, changed: false });
    this.props.toggleDialog('assistantCustomize', false);
  };

  submit = async () => {
    const { updateAssistantCategories } = this.props;
    const { categories, changed } = this.state;
    // console.log(categories);
    if (changed) {
      await updateAssistantCategories(
        categories.map((category, index) => {
          category.lists = category.lists.map(list => {
            list.doneEntries = [];
            return list;
          });

          if (index === categories.length - 1) {
            delete category.lists[0].extras.maxDay;
          }

          return category;
        })
      );
    }
    this.close();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { assistantCustomizeDialog } = this.props;
    const { categories } = this.state;
    if (assistantCustomizeDialog && !assistantCustomizeDialog.closed) {
      if ((!prevProps.assistant && this.props.assistant) || (!categories && this.props.assistant)) {
        this.setState({
          categories: _.cloneDeep(
            this.props.assistant.categories.filter(category => category.name.indexOf('tracking-stage') > -1)
          )
        });
      }
    }
  }

  render() {
    const { intl, assistantCustomizeDialog } = this.props;
    const { categories } = this.state;

    if (!assistantCustomizeDialog || !categories) return null;

    const { closed } = assistantCustomizeDialog;

    return (
      <Dialog
        className="assistant-customize-dialog"
        open={!!assistantCustomizeDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'ast-cus-dialog-title' })}</DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" gutterBottom={true}>
            {intl.formatMessage({ id: 'ast-cus-dialog-hint' })}
          </Typography>
          <form>
            {categories.map((category, categoryIndex) => {
              const targetList = category.lists[0];
              const isLastStage = categoryIndex === categories.length - 1;

              return (
                <Grid container key={category.name} direction="row" justify="flex-start" alignItems="center">
                  <Grid item>
                    <span>{`${intl.formatMessage({ id: `ast-cat-${category.name}` })}: ${intl.formatMessage({
                      id: 'Day'
                    })} `}</span>
                  </Grid>
                  <Grid item>
                    <span style={{ color: 'grey', margin: '0 10px' }}>{targetList.extras.minDay}</span>
                  </Grid>
                  {!isLastStage ? (
                    <Fragment>
                      <Grid item>
                        <span style={{ fontWeight: 'bold' }}>{' — '}</span>
                        <IntegerControl
                          controlOnRight={true}
                          value={targetList.extras.maxDay}
                          min={targetList.extras.minDay}
                          max={
                            Math.min(
                              _.get(categories, `[${categoryIndex + 1}].lists[0].extras.maxDay`, Infinity),
                              TRACKING_MAX_DAYS
                            ) - 1
                          }
                          onChange={value => {
                            const copyObj = _.cloneDeep(categories);
                            _.set(copyObj, `[${categoryIndex}].lists[0].extras.maxDay`, value);
                            if (categoryIndex < categories.length - 1) {
                              _.set(copyObj, `[${categoryIndex + 1}].lists[0].extras.minDay`, value + 1);
                            }
                            this.setState({ categories: copyObj, changed: true });
                          }}
                        />
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item style={{ color: 'grey' }}>
                        <span style={{ fontWeight: 'bold' }}>{' — '}</span>
                        <span style={{ marginLeft: 7 }}>{TRACKING_MAX_DAYS}</span>
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              );
            })}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary" autoFocus>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.submit} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    assistant: state.assistant.formattedAssistant,
    assistantCustomizeDialog: state.control.assistantCustomizeDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    updateAssistantCategories
  },
  // mergeProps
  null,
  // options
  {}
)(AssistantCustomizeDialog);

export default injectIntl(container);
