import React from 'react';
import { Radar } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import { getScoreColor, getScoreEmoji } from '../../../../utils/analysis';
import { red } from '@material-ui/core/colors';

class OverallRadar extends React.PureComponent {
  render() {
    const {
      intl,
      life,
      ci,
      saving,
      medical,
      peer,
      shortLabel,
      labelSize,
      padding,
      maintainAspectRatio,
      ...rest
    } = this.props;

    const fontColors = [
      getScoreColor(ci),
      getScoreColor(saving),
      getScoreColor(medical),
      getScoreColor(peer),
      getScoreColor(life)
    ];

    return (
      <Radar
        data={{
          labels: [
            intl.formatMessage({ id: shortLabel ? 'C.I.' : 'Critical Illness' }) + getScoreEmoji(ci),
            intl.formatMessage({ id: 'Saving' }) + getScoreEmoji(saving),
            intl.formatMessage({ id: shortLabel ? 'Med' : 'Medical' }) + getScoreEmoji(medical),
            intl.formatMessage({ id: shortLabel ? 'Peer' : 'Ranking' }) + getScoreEmoji(peer),
            intl.formatMessage({ id: 'Life' }) + getScoreEmoji(life)
          ],
          datasets: [
            {
              data: [ci, saving, medical, peer, life],
              label: '',
              backgroundColor: 'rgba(57, 102, 248, 0.4)',
              borderColor: 'rgba(57, 102, 248, 1)',
              borderWidth: 1.5,
              pointBackgroundColor: '#3966f8',
              pointBorderColor: '#3966f8',
              pointRadius: 1.2,
              lineTension: 0.1
            }
          ]
        }}
        options={{
          responsive: true,
          maintainAspectRatio: maintainAspectRatio,
          // height: 200,
          // width: 100,
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              labelColor: tooltipItem => {
                return {
                  borderColor: fontColors[tooltipItem.index],
                  backgroundColor: fontColors[tooltipItem.index]
                };
              },
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ':';
                }
                label += Math.round(tooltipItem.yLabel * 100) / 100;
                return ' ' + label;
              }
            }
          },
          layout: {
            padding: padding
          },
          scale: {
            ticks: {
              display: false,
              min: 0,
              max: 100,
              stepSize: 50
            },
            pointLabels: {
              fontSize: labelSize ? labelSize : 15,
              lineHeight: 1.5,
              // fontStyle: 'bold',
              fontColor: fontColors
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          }
        }}
        {...rest}
      />
    );
  }
}

export default injectIntl(OverallRadar);
