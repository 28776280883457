import { connect } from 'react-redux';
import AppComponent from './component';
import { getUser, handleSignOut } from '../../store/user/duck';
import { currencyExchangeRate, setShownDashHelp, updateFirstGuidelineState } from '../../store/user/duck';

import { selectedLocale } from '../../store/locale/action';
import { getUserTeamLogoNews } from '../../store/team/duck';
import { getNewReferralCount } from '../../store/referral/duck';
import { getAdviserSubscriptionNickname } from '../../store/userSubscription/duck';
import { createNotification } from '../../store/notificationCenter/duck';
import { getFigure } from '../../store/figure/duck';
import { getConfiguration } from '../../store/configuration/duck';
import { updateRouter } from '../../store/router/duck';
import { getInsurers } from '../../store/insurer/duck';
// import { updateSelectedPortfolio } from '../../store/policy/duck';

require('../../utils/roundedBars.js');

const AppContainer = connect(
  // Map state to props
  state => ({
    initialAuthDone: state.auth.initialAuthDone,
    isAuthenticated: state.auth.isAuthenticated,
    userDetails: state.user.userDetails,
    getUserPhase: state.user.getUserPhase,
    visitedDashboard: state.app.get('visitedDashboard'),
    userSubscription: state.userSubscription.userSubscription,
    subscriptions: state.userSubscription.subscriptions,
    requireExplicitLogin: state.user.requireExplicitLogin,
    subscriptionInitialFetched: state.userSubscription.initialFetched,
    notificationCenterData: state.notificationCenter.notificationCenterData,
    expiryDialogOpen: state.userSubscription.expiryDialogOpen,
    keepShowingNewSignUp: state.userSubscription.keepShowingNewSignUp,
    policyReviewPresentMode: state.policyReview.presentMode,
    financialPyramidPresentMode: state.financialPyramid.presentMode,
    teamCampaignCodes: state.functions.campaignCodes,
    showPadding: state.app.get('showPadding'),
    insurerGetPhase: state.insurer.getPhase
  }),
  // Map actions to props
  {
    getConfiguration,
    getUser,
    selectedLocale,
    currencyExchangeRate,
    getUserTeamLogoNews,
    getNewReferralCount,
    handleSignOut,
    getAdviserSubscriptionNickname,
    createNotification,
    getFigure,
    setShownDashHelp,
    updateFirstGuidelineState,
    updateRouter,
    getInsurers
  }
)(AppComponent);

export default AppContainer;
