import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import NumberFormatTextField from '../../../../../components/NumberFormatTextField/NumberFormatTextField';
import './PolicyInterestCalculatorModal.scss';
import { SlideTransition } from '../../../../../components/Transition/Transition';

export default class PolicyInterestCalculatorModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      targetPolicyValue: 1159000
      // principal: props.principal,
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  // 	if (nextProps.principal !== prevState.principal) {
  // 		return { principal: nextProps.principal, targetPolicyValue: nextProps.principal };
  // 	}
  //
  // 	return null;
  // }

  render() {
    const { intl, open, onClose, onSubmitTargetPolicyValue, term } = this.props;
    const { targetPolicyValue } = this.state;

    return (
      <Dialog
        className="policy-interest-modal"
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle className="alert-dialog-title">
          {intl.formatMessage({ id: 'pfa-cal-modal-title' }, { year: term })}
        </DialogTitle>
        <DialogContent className="alert-dialog-slide-description">
          <DialogContentText>{intl.formatMessage({ id: 'pfa-cal-modal-content' })}</DialogContentText>
          <NumberFormatTextField
            name="targetPolicyValue"
            value={targetPolicyValue}
            onChange={event => this.setState({ targetPolicyValue: event.target.value })}
            inputProps={{
              allowNegative: false,
              prefix: '$'
            }}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            color="primary"
            onClick={() => {
              onSubmitTargetPolicyValue(targetPolicyValue);
            }}
          >
            <div className="text">{intl.formatMessage({ id: 'OK' })}</div>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
