import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ShortSubscriptions } from '../../../../../../utils/user-subscription';
import { getHotTopicItemImageUrl, getHotTopicItemShareUrl, getHotTopicItemPreviewUrl } from '../../../../../../utils/hot-topic';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import VideoPlayer from '../../../../../../components/VideoPlayer/VideoPlayer';
import { sanitize } from 'dompurify';
import { interpolateString } from '../../../../../../utils/string';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ZoomIn from '@material-ui/icons/ZoomIn';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import ShareButtonGroup from '../../../../../../components/ShareButtonGroup/ShareButtonGroup';
import { toggleDialog } from '../../../../../../store/control/duck';
import { tracking } from '../../../../../../utils/tracking';
import _ from 'lodash';
import LanguageRadioGroup from '../../../../../../components/LanguageRadioGroup/LanguageRadioGroup';
import { LANGUAGE } from '../../../../../../constants/language';
import { trackDirect } from '../../../../../../store/analytics/api';

const PFPLUS_URL = process.env.PFPLUS_URL;

const ContentGridContainerStyle = {
  position: 'relative',
  justifyContent: 'space-between',
  margin: '10px 0',
  width: '100%'
};

const TitleStyle = {
  maxWidth: '60%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const PreviewButtonStyle = isExcluded =>
  isExcluded
    ? {
      color: '#FFFFFF',
      backgroundColor: '#4F1271',
      width: '130px'
    }
    : {
      width: '130px'
    };

const ImageStyle = {
  width: '130px',
  height: '195px',
  margin: '0 0 10px 0'
};

const ThumbNailStyle = {
  textAlign: 'center'
};

const HotTopicItemDialogContent = props => {
  const { intl, history, userDetails, subscriptions, item, enableLanguages, onClickSuggestion, toggleDialog } = props;

  const [locale, setLocale] = useState(
    history.location.state?.language
      ? history.location.state.language === 'Mandarin'
        ? LANGUAGE.ZH
        : LANGUAGE.ZH_HANT_HK
      : intl.locale === LANGUAGE.EN
        ? LANGUAGE.ZH_HANT_HK
        : intl.locale
  );
  const [isIpadWidth, setIsIpadWidth] = useState(window.innerWidth > 767);

  const {
    id,
    piktoId,
    texts,
    thumbnail,
    graphicLink,
    videoId,
    pdfLink,
    suggestions,
    excludeFreeTrial,
    excludePayTrial,
    updatedAt,
    createdAt
  } = item || {};

  const isItemFreeTrialExcluded =
    excludeFreeTrial && new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();
  const isItemPayTrialExcluded = excludePayTrial && new ShortSubscriptions(subscriptions).isCoreSubscriptionTrialing();
  const isExcluded = isItemFreeTrialExcluded || isItemPayTrialExcluded;

  // compatible with piktoId = 'xxx' format (can remove later)
  const finalPiktoId = piktoId ? piktoId[locale] || piktoId : '';

  const externalPiktoLinkWithoutSearch = getHotTopicItemShareUrl(item, locale);
  const externalPiktoLink = getHotTopicItemShareUrl(item, locale, { aId: _.get(userDetails, '_id', '') });
  const externalPreviewPiktoLink = getHotTopicItemPreviewUrl(item, locale, { aId: _.get(userDetails, '_id', '') });

  const globalVariables = { externalPiktoLinkWithoutSearch, externalPiktoLink };

  const thumbnailImageUrl = getHotTopicItemImageUrl(item);

  const download = () => {
    if (isExcluded) {
      toggleDialog('freeTrialLazyPackExcluded', {});
    } else {
      window.open(pdfLink);
      tracking('Adviser download in help center', { id: id });
    }
  };

  const pikto = () => {
    if (isExcluded) {
      toggleDialog('freeTrialLazyPackExcluded', {});
    } else {
      tracking('Click on Hot Topic - Preview');
      trackDirect('previewHotTopic', id.replace('featured-', ''));
      window.open(externalPreviewPiktoLink, '_blank');
    }
  };

  const onClickGraphicLink = async () => {
    toggleDialog('iframe', { title: intl.formatMessage({ id: 'click-graphic-link' }), url: graphicLink });
  };

  const shop = () => history.push('/purchase-lazy-pack');

  const onChangeLocale = event => setLocale(event.target.value);

  useEffect(() => {
    const handleResize = () => {
      setIsIpadWidth(window.innerWidth > 767);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Grid container spacing={1}>
      {videoId && !!videoId.trim() && (
        <Grid item xs={12}>
          <VideoPlayer url={videoId} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container direction="column">
          <Grid item>
            <div>
              {texts &&
                texts[intl.locale].map((text, index) => (
                  <div key={index} className="text-wrapper">
                    <Grid container direction="row" style={ContentGridContainerStyle}>
                      <Grid item style={isIpadWidth ? TitleStyle : {}}>
                        <div
                          className="hot-topic-text-title"
                          dangerouslySetInnerHTML={{ __html: sanitize(text.subtitle || '') }}
                        />
                        <div
                          className="text-description"
                          dangerouslySetInnerHTML={{
                            __html: sanitize(interpolateString(text.description, globalVariables) || '')
                          }}
                        />
                        {!isIpadWidth && finalPiktoId && (
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={PreviewButtonStyle(isExcluded)}
                              startIcon={isExcluded ? <LockOutlinedIcon /> : <ZoomIn />}
                              onClick={pikto}
                            >
                              {intl.formatMessage({ id: 'preview' })}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                      {isIpadWidth && thumbnail && (
                        <Grid item style={ThumbNailStyle}>
                          <img style={ImageStyle} src={thumbnailImageUrl} alt="thumbnail" />
                          {finalPiktoId && (
                            <Grid item>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={PreviewButtonStyle(isExcluded)}
                                startIcon={isExcluded ? <LockOutlinedIcon /> : <ZoomIn />}
                                onClick={pikto}
                              >
                                {intl.formatMessage({ id: 'preview' })}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {suggestions && suggestions.length > 0 && (
                      <Grid container direction="column" style={{ margin: '20px 0 10px 0' }}>
                        <Grid item>
                          <Typography>{intl.formatMessage({ id: 'hot-topic-suggestions' })}</Typography>
                        </Grid>
                        {suggestions.map(suggestion => {
                          const onClick = event => {
                            event.preventDefault();
                            onClickSuggestion(suggestion);
                          };
                          return (
                            <Grid item key={suggestion.id}>
                              <a
                                href={`${window.location.pathname}#${suggestion.id}`}
                                style={{
                                  color: '#3966F8',
                                  textDecoration: 'underline'
                                }}
                                onClick={onClick}
                              >
                                <Typography>{suggestion.title[intl.locale]}</Typography>
                              </a>
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                    {(updatedAt || createdAt) && (
                      <Typography color="textSecondary" gutterBottom={true}>
                        {`${intl.formatMessage({ id: 'Last Update' })}: ${moment(updatedAt || createdAt).format(
                          'D-MMM-YYYY'
                        )}`}
                      </Typography>
                    )}
                  </div>
                ))}

              {enableLanguages && (
                <LanguageRadioGroup
                  options={[LANGUAGE.ZH_HANT_HK, LANGUAGE.ZH]}
                  value={locale}
                  onChange={onChangeLocale}
                />
              )}

              {texts &&
                texts[locale].map((text, index) => (
                  <div key={index} className="text-wrapper">
                    {text['copy-text'] &&
                      text['copy-text'].length > 0 &&
                      text['copy-text'].map((t, copyTextIndex) => {
                        t = interpolateString(t, globalVariables);

                        var externalPiktoLinkWithoutFeature = externalPiktoLink.replace('featured-', '');
                        return (
                          <div className="text-copy" key={copyTextIndex}>
                            <ShareButtonGroup
                              key={id}
                              title={
                                history.location.state?.share_message
                                  ? history.location.state.share_message
                                  : t.replace(/<br\s*[\/]?>/gi, '\n').replace(/<\/?[^>]+(>|$)/g, '')
                              }
                              url={externalPiktoLinkWithoutFeature}
                              isCTA
                              locked={isExcluded ? () => toggleDialog('freeTrialLazyPackExcluded', {}) : null}
                              onShare={() => {
                                tracking('Click on Hot Topic - Native share');
                                trackDirect('shareHotTopic', id.replace('featured-', ''));
                              }}
                              onWhatsapp={() => {
                                tracking('Click on Hot Topic - Whatsapp Share');
                                trackDirect('shareHotTopic', id.replace('featured-', ''));
                              }}
                              onCopy={() => {
                                tracking('Click on Hot Topic - Copy');
                                trackDirect('shareHotTopic', id.replace('featured-', ''));
                              }}
                              CopyTextFieldProps={{
                                multiline: true,
                                rows: 2
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item>
            <div>
              {graphicLink && (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button variant="outlined" color="primary" onClick={onClickGraphicLink} className="basic-button">
                      {intl.formatMessage({ id: 'click-graphic-link' })}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={1}>
                {pdfLink && !!pdfLink.trim() && (
                  <Fragment>
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={download}>
                        {/* {intl.formatMessage({ id: 'download-pdf' })} */}
                        <GetAppIcon />
                      </Button>
                    </Grid>
                    {isExcluded && (
                      <Grid item>
                        <Button variant="contained" color="primary" onClick={shop}>
                          <LocalMallRoundedIcon />
                        </Button>
                      </Grid>
                    )}
                  </Fragment>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions
  }),
  {
    toggleDialog
  }
)(HotTopicItemDialogContent);

export default injectIntl(container);
