import React from 'react';
import { Button } from '@material-ui/core';

const Style = {
  padding: 4,
  borderRadius: 16,
  color: 'rgba(0, 0, 0, 0.23)',
  lineHeight: 1
};

const FollowUpButton = props => {
  const { style, children, ...rest } = props;
  return (
    <Button variant="outlined" style={{ ...Style, ...style }} {...rest}>
      {children}
    </Button>
  );
};

export default FollowUpButton;
