import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';

const ImportFailureDialog = props => {
  const { intl, open, onClose } = props;

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} fullWidth={true}>
      <DialogTitle>{intl.formatMessage({ id: 'import-failure-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'import-failure-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ImportFailureDialog);
