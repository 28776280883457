import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { PURCHASE_LOGO_URL } from '../../../../../constants/url';

const TeamSubscriptionOnlyDialog = props => {
  const { intl, open, onClose } = props;

  const onInquire = () => {
    window.open(PURCHASE_LOGO_URL, '_blank');
  };

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'team-subscription-only-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'team-subscription-only-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onInquire}>
          {intl.formatMessage({ id: 'inquire' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(TeamSubscriptionOnlyDialog);
