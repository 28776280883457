import {fetch} from "../../utils";
import Backend from "../../backend";

export const getMedicalCardsApi = () => {
    return fetch(Backend.methodUrl('medical-cards'))
        .then(response => response.json())
        .then(payload => payload)
        .catch(error => {
            throw error;
        });
};