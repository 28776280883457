import moment from 'moment';

export const convertToDateOnly = datetime => {
  if (!datetime) {
    return null;
  } else {
    const dateStringWithoutTime = moment(datetime).format('YYYY-MM-DD');
    return moment.utc(dateStringWithoutTime).format('YYYY-MM-DDT00:00:00.00Z'); //Change in the date send format)
  }
};

export const getDerivedAge = dob => Math.floor(moment().diff(moment(dob), 'years', true));

export const getAgeFromDob = (dob, age) => {
  dob = dob ? moment(moment(dob).format('DD-MM-YYYY'), 'DD-MM-YYYY') : undefined;
  age = dob ? getDerivedAge(dob) : age ? age : undefined;
  return age;
};
