import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import countryList from '../../../../store/locale/country-list';
import _ from 'lodash';
import { formatEditorStateValue, getEditorDefaultMessage } from '../../../../utils/editor';
import '../../../Advisor/Referral/styles.scss';
import ReferralSuccessModal from '../../../Advisor/Referral/partials/responseSuccessModal/responseSuccessModal';
import ReferralConfirmModal from '../../../Advisor/Referral/partials/responseSuccessModal/referralConfirmModal';
import ProCornerTriangle from '../../../../components/CornerTriangle/ProCornerTriangle';
import './AdviserProfile.scss';
import MyEditor from '../../../../components/MyEditor/MyEditor';
import { Button } from '@material-ui/core';
import IconToast from '../../../../components/NewToast';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import { tryTranslate } from '../../../../utils/locale';
import { INIT } from '../../../../constants/phase';
import { getSearchObject } from '../../../../utils/router';
import { tracking } from '../../../../utils/tracking';
import { sanitize } from 'dompurify';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';

export default class AdviserProfileView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      message: '',
      phoneNumber: '',
      wechatId: '',
      whatsapp: '',
      lineId: '',
      modalIsOpen: false,
      err: {},
      referById: '',
      checked: false,
      successModal: false,
      index: 0,
      whatsAppDirty: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getConnectedAdviser, getAdviserSubscriptionNickname } = this.props;
    getConnectedAdviser();
    getAdviserSubscriptionNickname();

    const { advisorMessagePhase, advisorId, getAdvisorMessage } = this.props;
    if (advisorMessagePhase === INIT && advisorId) {
      getAdvisorMessage(advisorId);
    }
  }

  componentDidMount() {}

  handleChange(e) {
    if (e.target.name === 'name') {
      delete this.state.err.name;
    }
    if (e.target.name === 'email') {
      delete this.state.err.email;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeSuccessModal() {
    this.setState({ successModal: false });
    this.props.initPhase();
  }

  openModal(event) {
    event.preventDefault();
    if (this.state.index === 0) {
      this.slider.slickNext();
      return;
    }
    const err = {};
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) && this.state.email !== '') {
      err.email = 'Invalid email';
    }
    if (this.state.name === '') {
      err.name = 'Name is required';
    }
    if (this.state.phoneNumber === '') {
      err.phoneNumber = 'This field is required';
    }
    if (!Object.keys(err).length) {
      this.setState({ modalIsOpen: true, err });
    } else if (this.state.index === 1) {
      if (err.name || err.phoneNumber) {
        this.slider.slickPrev();
      }
      //set a delay for smooth transition between slides
      setTimeout(() => this.setState({ err }), 0);
    }
  }

  submitForm() {
    const { history, submitReferral, connectedAdvisers, userDetails, qrCode, referById, advisorId } = this.props;
    const err = {};
    if (this.state.checked === false) {
      err.checked = '*Please check the info checkbox';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      var postData = {};
      if (qrCode) {
        postData.referById = referById;
        postData.advisorId = advisorId;
      } else {
        postData.referById = _.get(userDetails, '_id', '');
        postData.advisorId = _.get(connectedAdvisers, '[0]._id', '');
      }
      postData.name = this.state.name;
      postData.email = this.state.email;
      postData.phoneNumber = this.state.phoneNumber;
      postData.whatsapp = this.state.whatsapp;
      postData.wechatId = this.state.wechatId;
      postData.lineId = this.state.lineId;
      postData.source = _.get(getSearchObject(history), 'source');
      submitReferral(postData);

      // Track event
      let eventProperties = this.state.eventProperties;
      tracking('User submit referral', eventProperties);
    }
  }

  confirmEmail() {
    delete this.state.err.checked;
    this.setState({ checked: !this.state.checked });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.submitReferralPhase === false) {
      toast.info(
        IconToast('info', tryTranslate(nextProps.intl, nextProps.message, nextProps.submitReferralMessageExtras)),
        { className: 'new-toast' }
      );
    }
    if (nextProps.submitReferralPhase === true) {
      this.setState({
        modalIsOpen: false,
        email: '',
        name: '',
        phoneNumber: '',
        checked: false,
        wechatId: '',
        whatsapp: '',
        lineId: '',
        successModal: true
      });
    }

    // set mixpanel and amplitude event properties
    if (nextProps.advisorMessage && nextProps.advisorMessage._id) {
      let eventProperties = {
        advisorId: nextProps.advisorMessage._id,
        advisorName: nextProps.advisorMessage.name
      };

      this.setState({
        eventProperties
      });
    }
  }

  clientMessages(whatsApp) {
    const { intl } = this.props;
    let phoneNoText = whatsApp ? 'phone=' + whatsApp.replace('+', '') : '';
    let url = 'https://api.whatsapp.com/send?' + phoneNoText + `&text=${intl.formatMessage({ id: 'wts-ref-message' })}`;
    var win = window.open(encodeURI(url), '_blank');
    if (win) win.focus();
  }

  render() {
    const { userDetails, intl, connectedAdvisers, submitReferralPhase, noNav, formOnly } = this.props;

    const isGuest = userDetails._id === undefined;

    let settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: isMobile,
      afterChange: index => this.setState({ index })
    };
    // format adviser message
    let formattedAdviserMessage = '';
    let val = this.props.qrCode ? this.props.advisorMessage : _.get(connectedAdvisers, '[0]', {});
    if (val && val.adviserMessage) {
      formattedAdviserMessage = formatEditorStateValue(val.adviserMessage);
    } else {
      formattedAdviserMessage = formatEditorStateValue(getEditorDefaultMessage(intl.locale, 'adviser-message'));
    }
    let addFbHTTPS = false;
    if (val && val.facebookLink) {
      if (val.facebookLink.indexOf('https') === -1) {
        addFbHTTPS = true;
      }
    }
    let addLiHTTPS = false;
    if (val && val.linkedinLink) {
      if (val.linkedinLink.indexOf('https') === -1) {
        addLiHTTPS = true;
      }
    }

    return (
      <div className="adviser-profile">
        {submitReferralPhase === 'loading' && <LoadingBackdrop open={true} />}
        <div className="whiteWrapper">
          <div
            id="loginWrapper"
            className="referral-responsee-wrapper"
            style={{ paddingTop: this.props.qrCode ? 0 : 50 }}
          >
            {!this.props.qrCode && <ProCornerTriangle />}
            <div>
              {!formOnly && (
                <div className="referral-adv-details-wrapper">
                  <h2>{val && val.name ? val.name : '-'}</h2>
                  <div className="referral-msg">
                    <MyEditor editorState={formattedAdviserMessage} readOnly={true} />
                  </div>
                  <br />
                  <br />

                  <div className="sociallists">
                    <ul>
                      <li>
                        {val && val.phoneNumber && val.phoneNumber !== '' && (
                          <div className="referral-adv-details">
                            <div className="referral-adv-details-img">
                              <img alt="" src="img/phone.svg" />
                            </div>
                            <div className="referral-adv-details-value">
                              <a className="login-link" href={'tel:' + val.phoneNumber}>
                                {val.phoneNumber}
                              </a>
                            </div>
                          </div>
                        )}
                      </li>
                      <li>
                        {val && val.phoneNumber2 && val.phoneNumber2 !== '' && (
                          <div className="referral-adv-details">
                            <div className="referral-adv-details-img">
                              <img alt="" src="img/phone.svg" />
                            </div>
                            <div className="referral-adv-details-value">
                              <a
                                className="login-link"
                                style={{
                                  color: '#3966f8'
                                }}
                                href={'tel:' + val.phoneNumber2}
                              >
                                {val.phoneNumber2}
                              </a>
                            </div>
                          </div>
                        )}
                      </li>
                      <li>
                        {val && val.whatsapp && val.whatsapp !== '' && (
                          <div className="referral-adv-details">
                            <div className="referral-adv-details-img">
                              <img alt="" src="img/whatsapp.svg" />
                            </div>
                            <div className="referral-adv-details-value">
                              <span
                                className="login-link"
                                style={{
                                  color: '#3966f8',
                                  cursor: 'pointer'
                                }}
                                onClick={this.clientMessages.bind(this, val.whatsapp)}
                              >
                                {val.whatsapp}
                              </span>
                            </div>
                          </div>
                        )}
                      </li>
                      <li>
                        {val && val.wechatId && val.wechatId !== '' && (
                          <div className="referral-adv-details">
                            <div className="referral-adv-details-img">
                              <img alt="" src="img/wechat.svg" />
                            </div>
                            <div className="referral-adv-details-value">
                              {val && val.wechatId && val.wechatId !== '' ? val.wechatId : '-'}
                            </div>
                          </div>
                        )}
                      </li>
                      <li>
                        {val && val.lineId && val.lineId !== '' && (
                          <div className="referral-adv-details">
                            <div className="referral-adv-details-img">
                              <img alt="" src="img/line.svg" />
                            </div>
                            <div className="referral-adv-details-value">
                              {val && val.lineId && val.lineId !== '' ? val.lineId : '-'}
                            </div>
                          </div>
                        )}
                      </li>
                      <li>
                        {val && (val.facebookLink || val.linkedinLink) && (
                          <div className="referral-adv-details">
                            {val && val.facebookLink && val.facebookLink !== '' && (
                              <a
                                style={{
                                  color: '#3966f8',
                                  display: 'inline-block',
                                  marginRight: '15px'
                                }}
                                rel="noopener noreferrer"
                                href={addFbHTTPS ? 'https://' + val.facebookLink : val.facebookLink}
                                target="_blank"
                              >
                                <div className="referral-adv-details-img">
                                  <img alt="" src="img/facebook.svg" />
                                </div>
                                <span
                                  className="referral-adv-details-value"
                                  style={{ borderBottom: '1px solid #3966f8' }}
                                >
                                  {intl.formatMessage({ id: 'visit-word' })}
                                </span>
                              </a>
                            )}
                            {val && val.linkedinLink && val.linkedinLink !== '' && (
                              <a
                                style={{
                                  color: '#3966f8',
                                  display: 'inline-block'
                                }}
                                rel="noopener noreferrer"
                                href={addLiHTTPS ? 'https://' + val.linkedinLink : val.linkedinLink}
                                target="_blank"
                              >
                                <div className="referral-adv-details-img">
                                  <img alt="" src="img/web.svg" />
                                </div>
                                <span
                                  className="referral-adv-details-value"
                                  style={{ borderBottom: '1px solid #3966f8' }}
                                >
                                  {intl.formatMessage({ id: 'visit-word' })}
                                </span>
                              </a>
                            )}
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="clearfix"></div>
              <div className="modal-text" style={{ marginTop: 40, marginBottom: 10 }}>
                {intl.formatMessage({
                  id: 'Userrefermsg'
                })}
              </div>
              <form className="myForm referral-form" style={{ boxShadow: '0 0 1px #808080', padding: 5 }}>
                <Slider {...settings} ref={slider => (this.slider = slider)}>
                  <div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange.bind(this)}
                        required="required"
                      />
                      <label className="control-label top-label" htmlFor="input">
                        {intl.formatMessage({ id: 'Name' })}*
                      </label>
                      <i className="bar" />
                      {this.state.err.name ? (
                        <span className="error_field">{intl.formatMessage({ id: this.state.err.name })}</span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group phone_module clearfix">
                          <label className=" phone-module-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Tel' })}*
                          </label>
                          <Phone
                            international={true}
                            // inputComponent={SmartInput}
                            country="HK"
                            placeholder={intl.formatMessage({
                              id: 'Enter phone number'
                            })}
                            value={this.state.phoneNumber}
                            onChange={value =>
                              this.setState({
                                phoneNumber: value,
                                whatsapp: this.state.whatsAppDirty ? this.state.whatsapp : value
                              })
                            }
                            //labels={countryList.labels}
                            countries={countryList.labels}
                            flagsPath={'img/flags/'}
                            indicateInvalid
                          />
                          <i className="bar" />
                          {this.state.err.phoneNumber ? (
                            <span className="error_field">
                              {intl.formatMessage({ id: this.state.err.phoneNumber })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="upDownGap upDownGap-btn slider-btn next-btn">
                      <Button
                        className="btn-login gradient-action-button"
                        color="primary"
                        variant="contained"
                        onClick={this.openModal.bind(this)}
                      >
                        {intl.formatMessage({ id: 'Next' })}
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className="form-group upDownGap">
                      <input
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        required="required"
                      />
                      <label className="control-label top-label" htmlFor="input">
                        {intl.formatMessage({ id: 'Email' })}
                      </label>
                      <i className="bar" />
                      {this.state.err.email ? (
                        <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xs-12">
                        <div className="form-group phone_module clearfix">
                          <label className=" phone-module-label" htmlFor="input">
                            {intl.formatMessage({ id: 'WhatsApp' })}
                          </label>
                          <Phone
                            international={true}
                            //inputComponent={SmartInput}
                            country="HK"
                            placeholder={intl.formatMessage({
                              id: 'Enter whatsapp number'
                            })}
                            value={this.state.whatsapp}
                            onChange={value => {
                              delete this.state.err.whatsapp;
                              this.setState({ whatsapp: value, whatsAppDirty: true });
                            }}
                            //labels={countryList.labels}
                            countries={countryList.labels}
                            flagsPath={'img/flags/'}
                            indicateInvalid
                          />
                          <i className="bar" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="wechatId"
                            value={this.state.wechatId}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label top-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Wechat ID' })}
                          </label>
                          <i className="bar" />
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="lineId"
                            value={this.state.lineId}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Line ID' })}
                          </label>
                          <i className="bar" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="upDownGap upDownGap-btn slider-btn"
                      style={{ display: 'flex', justifyContent: 'space-around' }}
                    >
                      <Button
                        className="btn-login"
                        color="primary"
                        variant="outlined"
                        onClick={event => {
                          event.preventDefault();
                          this.slider.slickPrev();
                        }}
                      >
                        {intl.formatMessage({ id: 'Back' })}
                      </Button>
                      <Button
                        className="btn-login gradient-action-button"
                        color="primary"
                        style={{ marginLeft: 35 }}
                        variant="contained"
                        onClick={this.openModal.bind(this)}
                      >
                        {intl.formatMessage({ id: 'Submit' })}
                      </Button>
                    </div>
                  </div>
                </Slider>
              </form>
              <div style={{ marginTop: 10, textAlign: 'center' }}>
                {intl.formatMessage({ id: 'referral-privacy-policy' })}{' '}
                <a
                  href="https://www.portfoplus.com/privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'Privacy Policy' })) }}
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
          <ReferralConfirmModal
            intl={intl}
            fromReferClient
            open={this.state.modalIsOpen}
            onClose={this.closeModal}
            submitForm={this.submitForm.bind(this)}
            email={this.state.email}
            checked={this.state.checked}
            confirmEmail={this.confirmEmail.bind(this)}
            err={this.state.err.checked}
          />
          <ReferralSuccessModal
            val={val}
            intl={intl}
            open={this.state.successModal}
            closeSuccessModal={this.closeSuccessModal}
          />
        </div>
      </div>
    );
  }
}
