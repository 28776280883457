import React, { useState } from 'react';
import { Typography, TextField, Grid, Button, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import ShareButtonGroup from '../../ShareButtonGroup/ShareButtonGroup';
import DialogContentText from '@material-ui/core/DialogContentText';
import GetAppIcon from '@material-ui/icons/GetApp';

const options = [
  { label: 'share-message-option-1' },
  { label: 'share-message-option-2' },
  { label: 'share-message-option-4' }
];

const optionsSecond = [
  { label: 'share-message-option-5' },
  { label: 'share-message-option-6' },
  { label: 'share-message-option-3' }
];

const ShareMessage = ({ setMessage, message, intl }) => {
  const [option, setOption] = useState(options[0].label);
  const openLink = url => {
    const win = window.open(url, '_blank', 'noreferrer');
    if (win) {
      win.focus();
    }
  };

  const getPDF = () => {
    window.open('http://bit.ly/pfpintrofaq', '_blank');
  };

  return (
    <div id="adviser-help-share-message">
      <DialogContentText style={{ fontSize: '1.5rem' }}>
        {intl.formatMessage({ id: 'share-message-title' })}
      </DialogContentText>
      <RadioGroup
        className={`lang-radio-group`}
        value={option}
        onChange={e => {
          const val = e.target.value;
          setOption(val);
          setMessage(intl.formatMessage({ id: `${val}-content` }));
        }}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.label}
            control={<Radio color="primary" />}
            value={option.label}
            label={intl.formatMessage({ id: option.label })}
          />
        ))}
      </RadioGroup>
      <RadioGroup
        className={`lang-radio-group`}
        value={option}
        onChange={e => {
          const val = e.target.value;
          setOption(val);
          setMessage(intl.formatMessage({ id: `${val}-content` }));
        }}
      >
        {optionsSecond.map(option => (
          <FormControlLabel
            key={option.label}
            control={<Radio color="primary" />}
            value={option.label}
            label={intl.formatMessage({ id: option.label })}
          />
        ))}
      </RadioGroup>

      <ShareButtonGroup
        title={message}
        url=""
        isCTA
        CopyTextFieldProps={{
          multiline: true
        }}
      />

      <Typography style={{ marginTop: 10 }} color="textSecondary">
        {intl.formatMessage({ id: 'share-message-content' })}
      </Typography>

      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <Button variant="contained" color="primary" onClick={getPDF}>
          <GetAppIcon />
        </Button>
      </div>
    </div>
  );
};

export default ShareMessage;
