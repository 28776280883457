import React, { Fragment, PureComponent } from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Grid, Button, InputAdornment, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import _ from 'lodash';
import '../styles.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TodayIcon from '@material-ui/icons/Today';
import MyToggleButton from '../../../../components/MyToggleButton/MyToggleButton';
import { jsonParse } from '../../../../utils/json';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { findPaidUpAutoDate, formatDetailData } from '../../utils';
import { DEFAULT_DETAIL_DATA } from '../../constants';
import NumberFormatTextField from '../../Inputs/NumberFormatInput/NumberFormatTextField';
import MarketValueUpdateDateInput from '../../Inputs/MarketValueUpdateDateInput/MarketValueUpdateDateInput';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { TERMS_OPTIONS } from '../../../../constants/policy';
import { getTermsIntl } from '../../../../utils/policy';
const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];
const subCategoryDeatils = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' },
  { id: '5b9a5bfee624cdb543a476d1', title: 'Maid' },
  { id: '5b9a5bfee624cdb543a476d2', title: 'Accident' },
  { id: '5baf2af899d85c2d38c8f00f', title: 'Saving' },
  { id: '5c18ebd120ca05b7e2b32565', title: 'Provident Fund' },
  { id: '5c41b85a83230016450b2214', title: 'Mortgage' }
];

class DetailView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_DETAIL_DATA,

      err: {},
      type: 'hidden',
      keyArray: []
    };
    this.submitForm = this.submitForm.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleButtonStatus = this.handleButtonStatus.bind(this);

    this.editPolicyDetails = jsonParse(sessionStorage.getItem('editPolicyDetails'));
    this.editPolicy = jsonParse(sessionStorage.getItem('editPolicy'));
  }

  UNSAFE_componentWillMount() {
    var detailData = '';
    var premiumHolidayDate = null;
    let paidUpDate = null;
    let marketValueUpdateDate = null;
    var previousPolicy = '';
    //saving the policy data in there respective fields
    var editDetail = JSON.parse(localStorage.getItem('editDetail'));
    var addRider = JSON.parse(localStorage.getItem('addRider'));
    if (this.editPolicyDetails) {
      detailData = this.editPolicyDetails;
    } else {
      detailData = JSON.parse(localStorage.getItem('detail'));
    }
    if (editDetail === 'true') {
      detailData = JSON.parse(localStorage.getItem('detail'));
    }
    if (detailData != null) {
      if (detailData.premiumHolidayDate != null) {
        premiumHolidayDate = moment.parseZone(detailData.premiumHolidayDate);
      }
      if (detailData.marketValueUpdateDate != null) {
        marketValueUpdateDate = moment.parseZone(detailData.marketValueUpdateDate);
      }
      if (detailData.paidUpDate != null) {
        paidUpDate = moment.parseZone(detailData.paidUpDate);
      }
    }
    if (addRider === true) {
      previousPolicy = JSON.parse(localStorage.getItem('previousPolicyData'));
    }
    if (detailData != null) {
      this.setState({
        policyOwner: detailData.policyOwner,
        policyOwnerSelected: detailData.policyOwnerSelected ?? '',
        lifeInsured: detailData.lifeInsured,
        lifeInsuredSelected: detailData.lifeInsuredSelected ? detailData.lifeInsuredSelected : '',
        lifeInsuredAge: detailData.lifeInsuredAge,
        paymentTerms: detailData.paymentTerms || '',
        paymentTermsOther: detailData.paymentTermsOther || '',
        protectionTerms: detailData.protectionTerms || '',
        protectionTermsOther: detailData.protectionTermsOther || '',
        beneficial: detailData.beneficial,
        beneficialSelected: detailData.beneficialSelected ?? '',
        vehicleYear: detailData.vehicleYear,
        vehicleModel: detailData.vehicleModel,
        vehicleBrand: detailData.vehicleBrand,
        ncb: detailData.ncb,
        homeSize: detailData.homeSize,
        homeContentsValue: detailData.homeContentsValue,
        coverageDetail: detailData.coverageDetail,
        coverageDay: detailData.coverageDay,
        tripDelayCover: detailData.tripDelayCover,
        lossItemCover: detailData.lossItemCover,
        mobilePhoneCover: detailData.mobilePhoneCover,
        rentalCarCover: detailData.rentalCarCover,
        emergencyTel: detailData.emergencyTel,
        permanentDisablement: detailData.permanentDisablement,
        medicalExpenses: detailData.medicalExpenses,
        physiotherapy: detailData.physiotherapy,
        chineseDoctor: detailData.chineseDoctor,
        chineseBonesetter: detailData.chineseBonesetter,
        hospitalCashDetail: detailData.hospitalCashDetail,
        employersLiability: detailData.employersLiability,
        outPatientDetail: detailData.outPatientDetail,
        dentalDetail: detailData.dentalDetail,
        repatriation: detailData.repatriation,
        accident: detailData.accident,
        maidReplacement: detailData.maidReplacement,
        propertyValue: detailData.propertyValue,
        downPayment: detailData.downPayment,
        loanRatio: detailData.loanRatio,
        outStandingLoan: detailData.outStandingLoan,
        interestRate: detailData.interestRate,
        interestTerms: detailData.interestTerms,
        penaltyPeriod: detailData.penaltyPeriod,
        policyStatus: detailData.policyStatus || '',
        premiumHolidayDate: premiumHolidayDate,
        totalWithdrawn: detailData.totalWithdrawn,
        marketValue: detailData.marketValue,
        surrenderValue: detailData.surrenderValue,
        smm: detailData.smm,
        hospitalCash: detailData.hospitalCash,
        outPatient: detailData.outPatient,
        dental: detailData.dental,
        deductible: detailData.deductible,
        claimLimit: detailData.claimLimit,
        paidUpDate: paidUpDate,
        selectedPolicyStatus: detailData.selectedPolicyStatus ? detailData.selectedPolicyStatus : '',
        multiClaim: detailData.multiClaim,
        earlyStage: detailData.earlyStage,
        marketValueUpdateDate: marketValueUpdateDate
      });
      if (detailData.smm === 'Yes') {
        this.state.keyArray.push(0);
      }
      if (detailData.hospitalCash === 'Yes') {
        this.state.keyArray.push(1);
      }
      if (detailData.outPatient === 'Yes') {
        this.state.keyArray.push(2);
      }
      if (detailData.dental === 'Yes') {
        this.state.keyArray.push(3);
      }
    }
    if (previousPolicy != null && detailData === null && previousPolicy !== '' && previousPolicy !== undefined) {
      if (previousPolicy.premiumHolidayDate != null) {
        premiumHolidayDate = moment.parseZone(previousPolicy.premiumHolidayDate);
      }
      if (previousPolicy.paidUpDate != null) {
        paidUpDate = moment.parseZone(previousPolicy.paidUpDate);
      }
      // if (previousPolicy.marketValueUpdateDate != null) {
      //   marketValueUpdateDate = moment.parseZone(previousPolicy.marketValueUpdateDate);
      // }
      this.setState({
        policyOwner: previousPolicy.policyOwner,
        policyOwnerSelected: previousPolicy.policyOwnerSelected ?? '',
        lifeInsured: previousPolicy.lifeInsured,
        lifeInsuredSelected: previousPolicy.lifeInsuredSelected ? previousPolicy.lifeInsuredSelected : '',
        lifeInsuredAge: _.get(previousPolicy, 'lifeInsuredAge', ''),
        paymentTermsOther: _.get(previousPolicy, 'paymentTermsOther', ''),
        protectionTermsOther: _.get(previousPolicy, 'protectionTermsOther', ''),
        beneficial: _.get(previousPolicy, 'beneficial', ''),
        beneficialSelected: _.get(previousPolicy, 'beneficialSelected', ''),
        vehicleYear: _.get(previousPolicy, 'vehicleYear', ''),
        vehicleModel: _.get(previousPolicy, 'vehicleModel', ''),
        vehicleBrand: _.get(previousPolicy, 'vehicleBrand', ''),
        ncb: _.get(previousPolicy, 'ncb', ''),
        homeSize: _.get(previousPolicy, 'homeSize', ''),
        homeContentsValue: _.get(previousPolicy, 'homeContentsValue'),
        coverageDetail: _.get(previousPolicy, 'coverageDetail', ''),
        coverageDay: _.get(previousPolicy, 'coverageDay', ''),
        tripDelayCover: _.get(previousPolicy, 'tripDelayCover', ''),
        lossItemCover: _.get(previousPolicy, 'lossItemCover', ''),
        mobilePhoneCover: _.get(previousPolicy, 'mobilePhoneCover', ''),
        rentalCarCover: _.get(previousPolicy, 'rentalCarCover', ''),
        permanentDisablement: _.get(previousPolicy, 'permanentDisablement', ''),
        medicalExpenses: _.get(previousPolicy, 'medicalExpenses', ''),
        physiotherapy: _.get(previousPolicy, 'physiotherapy', ''),
        chineseDoctor: _.get(previousPolicy, 'chineseDoctor', ''),
        chineseBonesetter: _.get(previousPolicy, 'chineseBonesetter', ''),
        hospitalCashDetail: _.get(previousPolicy, 'hospitalCashDetail', ''),
        employersLiability: _.get(previousPolicy, 'employersLiability', ''),
        outPatientDetail: _.get(previousPolicy, 'outPatientDetail', ''),
        dentalDetail: _.get(previousPolicy, 'dentalDetail', ''),
        repatriation: _.get(previousPolicy, 'repatriation', ''),
        accident: _.get(previousPolicy, 'accident', ''),
        maidReplacement: _.get(previousPolicy, 'maidReplacement', ''),
        policyStatus: _.get(previousPolicy, 'policyStatus', ''),
        premiumHolidayDate: premiumHolidayDate,
        totalWithdrawn: _.get(previousPolicy, 'totalWithdrawn', ''),
        // marketValue: _.get(previousPolicy, 'marketValue', ''),
        propertyValue: _.get(previousPolicy, 'propertyValue', ''),
        downPayment: _.get(previousPolicy, 'downPayment', ''),
        loanRatio: _.get(previousPolicy, 'loanRatio', ''),
        outStandingLoan: _.get(previousPolicy, 'outStandingLoan', ''),
        interestRate: _.get(previousPolicy, 'interestRate', ''),
        interestTerms: _.get(previousPolicy, 'interestTerms', ''),
        penaltyPeriod: _.get(previousPolicy, 'penaltyPeriod', ''),
        smm: _.get(previousPolicy, 'smm', ''),
        hospitalCash: _.get(previousPolicy, 'hospitalCash', ''),
        outPatient: _.get(previousPolicy, 'outPatient', ''),
        dental: _.get(previousPolicy, 'dental', ''),
        deductible: _.get(previousPolicy, 'deductible', ''),
        paidUpDate: paidUpDate,
        selectedPolicyStatus: previousPolicy.selectedPolicyStatus ? previousPolicy.selectedPolicyStatus : '',
        multiClaim: _.get(previousPolicy, 'multiClaim', false),
        earlyStage: _.get(previousPolicy, 'earlyStage', false)
        // marketValueUpdateDate: marketValueUpdateDate
      });
      if (_.get(previousPolicy, 'smm') === 'Yes') {
        this.state.keyArray.push(0);
      }
      if (_.get(previousPolicy, 'hospitalCash') === 'Yes') {
        this.state.keyArray.push(1);
      }
      if (_.get(previousPolicy, 'outPatient') === 'Yes') {
        this.state.keyArray.push(2);
      }
      if (_.get(previousPolicy, 'dental') === 'Yes') {
        this.state.keyArray.push(3);
      }
    }
  }

  handleChange(e) {
    const err = {};
    if (e.target.name === 'lifeInsuredAge') {
      if (isNaN(e.target.value)) {
        err.lifeInsuredAge = 'Life Insured Age must be a number.';
        this.setState({ err });
        return;
      }
    }
    if (e.target.name === 'lifeInsuredAge') {
      err.lifeInsuredAge = '';
    }
    this.setState({ [e.target.name]: e.target.value, err });
  }
  submitForm() {
    const err = {};
    let categoryType = {};
    let subCategoryType = {};
    if (this.editPolicyDetails) {
      let categoryId = this.editPolicyDetails.categoryId;
      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
    }
    if (this.editPolicyDetails) {
      let subCategoryId = this.editPolicyDetails.subCategoryId;
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }
    if (
      (categoryType.title === 'Personal' ||
        subCategoryType.title === 'Saving' ||
        subCategoryType.title === 'Accident') &&
      this.state.lifeInsuredSelected === ''
    ) {
      err.lifeInsuredSelected = 'Please choose life insured';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      formatDetailData(this.state);
      this.props.handleNextPage();
    }
  }
  stateChange() {
    this.setState({ type: 'text' });
  }
  handlePrevious() {
    formatDetailData(this.state);
    this.props.handlePreviousPage();
  }

  handleDateChange(date) {
    this.setState({ premiumHolidayDate: date });
  }

  handleMarketValueUpdateDateChange = date => this.setState({ marketValueUpdateDate: date });

  //Will save the life insured age files automatically when pressed
  handleClickAuto() {
    var paymentPageData = JSON.parse(localStorage.getItem('payment'));
    var factsheetDOB = JSON.parse(localStorage.getItem('factsheetDOB'));
    if (paymentPageData != null && paymentPageData.policyStartDate != null && factsheetDOB) {
      let policyStartDate = moment(paymentPageData.policyStartDate);
      let age = moment(policyStartDate).diff(moment(factsheetDOB, 'YYYY-MM-DD'), 'years');
      this.setState({ lifeInsuredAge: age >= 0 ? age : 0 });
    }
  }

  //Handle the status in case of medical for hospital cash , smm , outpatient and denal
  handleButtonStatus(key) {
    const state = this.state.keyArray;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({ smm: 'Yes' });
      }
      if (key === 1) {
        this.setState({ hospitalCash: 'Yes' });
      }
      if (key === 2) {
        this.setState({ outPatient: 'Yes' });
      }
      if (key === 3) {
        this.setState({ dental: 'Yes' });
      }
    } else {
      this.setState({
        keyArray: this.state.keyArray.filter(key1 => {
          return key1 !== key;
        })
      });
      if (key === 0) {
        this.setState({ smm: 'No' });
      }
      if (key === 1) {
        this.setState({ hospitalCash: 'No' });
      }
      if (key === 2) {
        this.setState({ outPatient: 'No' });
      }
      if (key === 3) {
        this.setState({ dental: 'No' });
      }
    }
  }

  selectlifeInsuredPersonal(key) {
    delete this.state.err.lifeInsuredSelected;
    this.setState({ lifeInsuredSelected: key });
  }

  selectPolicyOwner = key => {
    this.setState({ policyOwnerSelected: key });
  };

  selectBeneficial = key => {
    this.setState({ beneficialSelected: key });
  };

  selectPolicyStatus(key) {
    const { selectedPolicyStatus } = this.state;
    this.setState({
      selectedPolicyStatus: selectedPolicyStatus === key ? '' : key
    });
  }

  triggerButton = stateKey => event => {
    this.setState({ [stateKey]: !this.state[stateKey] });
  };

  selectedComponent = (type, top) => {
    const { intl } = this.props;
    const onClick = type === 'policyOwner' ? this.selectPolicyOwner : this.selectBeneficial;
    const value = this.state[type + 'Selected'];
    return (
      <div className="setting-select policy_select" style={{ top: top ?? '-16px' }}>
        <Button
          variant={value === 'Self' ? 'contained' : 'outlined'}
          className={value === 'Self' ? 'plan-type-select-button selected' : 'plan-type-select-button'}
          onClick={() => onClick('Self')}
        >
          {intl.formatMessage({ id: 'Self' })}
        </Button>

        <Button
          variant={value === 'Not Me' ? 'contained' : 'outlined'}
          className={value === 'Not Me' ? 'plan-type-select-button selected' : 'plan-type-select-button'}
          onClick={() => onClick('Not Me')}
        >
          {intl.formatMessage({ id: 'Not Me' })}
        </Button>
        <Button
          variant={value === 'No Idea' ? 'contained' : 'outlined'}
          className={value === 'No Idea' ? 'plan-type-select-button  selected' : 'plan-type-select-button'}
          onClick={() => onClick('No Idea')}
        >
          {intl.formatMessage({ id: 'No Idea' })}
        </Button>
      </div>
    );
  };

  render() {
    const { intl, userDetails, viewingClient } = this.props;
    const { paymentTerms } = this.state;

    const policyOwner = viewingClient ? viewingClient : userDetails.userType === 'User' ? userDetails : null;

    var paymentPageData = JSON.parse(localStorage.getItem('payment'));
    var factsheetDOB = JSON.parse(localStorage.getItem('factsheetDOB'));
    let autoButtonDiabled = true,
      policyStartDate = null;
    if (paymentPageData) {
      if (paymentPageData.policyStartDate) {
        policyStartDate = moment(paymentPageData.policyStartDate);
      }
      if (policyStartDate && factsheetDOB) {
        let days = moment(policyStartDate).diff(moment(factsheetDOB, 'YYYY-MM-DD'), 'days');
        if (days > 0) {
          autoButtonDiabled = false;
        }
      }
    }

    // find paidUpAutoDate
    const paidUpAutoDate = findPaidUpAutoDate(
      policyStartDate,
      paymentTerms,
      policyOwner,
      policyOwner && policyOwner.factsheetId
    );

    let showAutoButton = userDetails && userDetails.userType === 'Adviser' ? true : false;
    var categoryId = '';
    var subCategoryId = '';
    var categoryType = {};
    var subCategoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;
      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }

    const lifeInsuredComponents = (
      <Fragment>
        <div className="form-group">
          <input
            type="text"
            name="lifeInsured"
            value={this.state.lifeInsured}
            onChange={this.handleChange.bind(this)}
            required="required"
            className="life__insured--input"
            placeholder={intl.formatMessage({ id: 'input-detail' })}
          />
          <label className="control-label life_insured" htmlFor="input">
            {intl.formatMessage({ id: 'Life Insured' })}*:
          </label>
          <div className="setting-select policy_select">
            {this.state.lifeInsuredSelected === 'Not Me' && <img alt="" className="not_me_icon" src="img/notme.png" />}
            <Button
              variant={this.state.lifeInsuredSelected === 'Self' ? 'contained' : 'outlined'}
              className={
                this.state.lifeInsuredSelected === 'Self'
                  ? 'plan-type-select-button selected'
                  : 'plan-type-select-button'
              }
              onClick={this.selectlifeInsuredPersonal.bind(this, 'Self')}
            >
              {intl.formatMessage({ id: 'Self' })}
            </Button>

            <Button
              variant={this.state.lifeInsuredSelected === 'Not Me' ? 'contained' : 'outlined'}
              className={
                this.state.lifeInsuredSelected === 'Not Me'
                  ? 'plan-type-select-button selected'
                  : 'plan-type-select-button'
              }
              onClick={this.selectlifeInsuredPersonal.bind(this, 'Not Me')}
            >
              {intl.formatMessage({ id: 'Not Me' })}
            </Button>
            <Button
              variant={this.state.lifeInsuredSelected === 'No Idea' ? 'contained' : 'outlined'}
              className={
                this.state.lifeInsuredSelected === 'No Idea'
                  ? 'plan-type-select-button  selected'
                  : 'plan-type-select-button'
              }
              onClick={this.selectlifeInsuredPersonal.bind(this, 'No Idea')}
            >
              {intl.formatMessage({ id: 'No Idea' })}
            </Button>
          </div>
          <i className="bar" />
          {this.state.err.lifeInsuredSelected && (
            <span className="error_field">
              {intl.formatMessage({
                id: this.state.err.lifeInsuredSelected
              })}
            </span>
          )}
        </div>
      </Fragment>
    );

    const paidUpDateComponent = (
      <div className="row">
        <div className="col-md-12 col-xs-12">
          <div className="form-group datepicker-group">
            <DatePicker
              name="paidUpDate"
              format="DD-MMM-YYYY"
              maxDate={moment().add(120, 'years')}
              clearable={true}
              value={this.state.paidUpDate ? moment.parseZone(this.state.paidUpDate) : null}
              openTo="year"
              onChange={date => this.setState({ paidUpDate: date })}
              autoOk={true}
              label={intl.formatMessage({ id: 'paid-up-date' })}
              invalidDateMessage={null}
              minDateMessage={null}
              maxDateMessage={null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton style={{ padding: '0', margin: '0 50px 0 -12px' }}>
                      <TodayIcon color="action" style={{ fontSize: 20 }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Button
              disabled={!paidUpAutoDate}
              variant="contained"
              color="primary"
              className={!paidUpAutoDate ? 'action-change-button disable-button' : 'action-change-button'}
              onClick={() => this.setState({ paidUpDate: paidUpAutoDate })}
            >
              {intl.formatMessage({ id: 'Auto' })}
            </Button>
          </div>
        </div>
      </div>
    );

    // let paymentAndProtectionComponent =
    //   <div className="row">

    //     <div className="col-md-6 col-xs-6">
    //         <div className="form-group">
    //           <div className="row">

    //           </div>
    //           <div className="row">

    //           </div>
    //         </div>
    //     </div>

    //     <div className="col-md-6 col-xs-6">
    //           <div className="row">

    //           </div>
    //           <div className="row">

    //           </div>
    //     </div>

    //   </div>;

    let paymentAndProtectionComponent = (
      <div className="row">
        <div className="col-md-6 col-xs-6">
          <div className="form-group">
            <div className="row"></div>
            <div className="row"></div>
          </div>
        </div>

        <div className="col-md-6 col-xs-6">
          <div className="row"></div>
          <div className="row"></div>
        </div>
      </div>
    );

    let paymentComponent = 0;

    const policyOwnerComponent = (
      <div className="form-group">
        {this.selectedComponent('policyOwner', -16)}
        <input
          type="text"
          name="policyOwner"
          className="life__insured--input"
          placeholder={intl.formatMessage({ id: 'input-detail' })}
          value={this.state.policyOwner || ''}
          onChange={this.handleChange.bind(this)}
          required="required"
        />
        <label className="control-label life_insured" htmlFor="input">
          {subCategoryType && subCategoryType.title === 'Provident Fund'
            ? intl.formatMessage({ id: 'pf-owner' })
            : intl.formatMessage({ id: 'Policy Owner' })}
          :
        </label>
        <i className="bar" />
      </div>
    );

    const beneficialComponent = (
      <div className="form-group">
        {this.selectedComponent('beneficial', '-16')}
        <textarea
          type="text"
          name="beneficial"
          className="life__insured--input"
          placeholder={intl.formatMessage({ id: 'input-detail' })}
          value={this.state.beneficial}
          onChange={this.handleChange.bind(this)}
          required="required"
          rows="2"
        />
        <label className="control-label life_insured" htmlFor="input">
          {intl.formatMessage({ id: 'Beneficiary' })}:
        </label>
        <i className="bar" />
      </div>
    );

    const policyStatusComponent = (
      <div className="policy__select--box">
        <div className="form-group">
          <FormControl>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <InputLabel
                  shrink={false}
                  style={{ color: '#333', transform: 'unset', position: 'relative', margin: 0 }}
                >
                  {subCategoryType && subCategoryType.title === 'Provident Fund'
                    ? intl.formatMessage({ id: 'pf-policy-status' })
                    : intl.formatMessage({ id: 'policy_status_label' })}
                  :
                </InputLabel>
              </Grid>
              <Grid item>
                <div>
                  <Button
                    variant={this.state.selectedPolicyStatus === 'In-force' ? 'contained' : 'outlined'}
                    className={
                      this.state.selectedPolicyStatus === 'In-force'
                        ? 'plan-type-select-button selected'
                        : 'plan-type-select-button'
                    }
                    onClick={this.selectPolicyStatus.bind(this, 'In-force')}
                  >
                    {intl.formatMessage({ id: 'in-force' })}
                  </Button>

                  <Button
                    variant={this.state.selectedPolicyStatus === 'Holiday' ? 'contained' : 'outlined'}
                    className={
                      this.state.selectedPolicyStatus === 'Holiday'
                        ? 'plan-type-select-button selected'
                        : 'plan-type-select-button'
                    }
                    onClick={this.selectPolicyStatus.bind(this, 'Holiday')}
                  >
                    {intl.formatMessage({ id: 'holiday' })}
                  </Button>

                  <Button
                    variant={this.state.selectedPolicyStatus === 'Paid-up' ? 'contained' : 'outlined'}
                    className={
                      this.state.selectedPolicyStatus === 'Paid-up'
                        ? 'plan-type-select-button selected'
                        : 'plan-type-select-button'
                    }
                    onClick={this.selectPolicyStatus.bind(this, 'Paid-up')}
                  >
                    {intl.formatMessage({ id: 'paid-up' })}
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Input
              type="text"
              name="policyStatus"
              value={this.state.policyStatus}
              onChange={this.handleChange.bind(this)}
              placeholder={intl.formatMessage({ id: 'input-detail' })}
            />
          </FormControl>
        </div>
      </div>
    );

    return (
      <div>
        <TopNavigationPortal>
          <span className="back_arrow" onClick={this.handlePrevious}>
            <ArrowBackIcon />
          </span>
        </TopNavigationPortal>
        <div id="wrapper">
          <div id="page-content-wrapper" className="policy__detail">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  {categoryType &&
                  subCategoryType &&
                  (categoryType.title === 'Personal' ||
                    subCategoryType.title === 'Saving' ||
                    subCategoryType.title === 'Provident Fund') ? (
                    <form id="msform" noValidate={true}>
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active"> </li>
                        <li className="active"> </li>
                        <li> </li>
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Detail' })}</h2>
                        {subCategoryType && subCategoryType.title !== 'Provident Fund' && (
                          <div className="row">
                            <div className="col-md-6 col-xs-6">
                              <div className="form-group">
                                <select
                                  name="paymentTerms"
                                  onChange={this.handleChange.bind(this)}
                                  value={this.state.paymentTerms}
                                >
                                  {this.state.paymentTerms && (
                                    <option value={this.state.paymentTerms}>
                                      {this.state.paymentTerms &&
                                        this.state.paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                          paymentTerm
                                        ) {
                                          return intl.formatMessage({
                                            id: paymentTerm
                                          });
                                        })}
                                    </option>
                                  )}
                                  <option value="" />
                                  {Object.values(TERMS_OPTIONS).map(option => (
                                    <option value={option} key={option}>
                                      {getTermsIntl(intl, option)}
                                    </option>
                                  ))}
                                </select>
                                <div>
                                  {this.state.paymentTerms === 'Other' ? (
                                    <input
                                      type="text"
                                      name="paymentTermsOther"
                                      placeholder={intl.formatMessage({
                                        id: 'Enter Payment Terms'
                                      })}
                                      value={this.state.paymentTermsOther}
                                      onChange={this.handleChange.bind(this)}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <label className="control-label" htmlFor="select">
                                  {intl.formatMessage({ id: 'Payment Terms' })}:
                                </label>
                                <i className="bar" />
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-6">
                              <div className="form-group">
                                <select
                                  name="protectionTerms"
                                  onChange={this.handleChange.bind(this)}
                                  value={this.state.protectionTerms}
                                >
                                  {this.state.protectionTerms && (
                                    <option value={this.state.protectionTerms}>
                                      {this.state.protectionTerms &&
                                        this.state.protectionTerms.replace(
                                          /Years|Till age|Whole Life|Other/gi,
                                          function(protectionTerm) {
                                            return intl.formatMessage({
                                              id: protectionTerm
                                            });
                                          }
                                        )}
                                    </option>
                                  )}
                                  <option value="" />
                                  {Object.values(TERMS_OPTIONS).map(option => (
                                    <option value={option} key={option}>
                                      {getTermsIntl(intl, option)}
                                    </option>
                                  ))}
                                </select>
                                <div>
                                  {this.state.protectionTerms === 'Other' ? (
                                    <input
                                      type="text"
                                      name="protectionTermsOther"
                                      placeholder={intl.formatMessage({
                                        id: 'Enter Protection Terms'
                                      })}
                                      value={this.state.protectionTermsOther}
                                      onChange={this.handleChange.bind(this)}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <label className="control-label" htmlFor="select">
                                  {intl.formatMessage({ id: 'Protection Terms' })}:
                                </label>
                                <i className="bar" />
                                <Button
                                  className="action-change-button"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    this.setState({
                                      protectionTerms: this.state.paymentTerms,
                                      protectionTermsOther: this.state.paymentTermsOther
                                    });
                                  }}
                                >
                                  {intl.formatMessage({ id: 'Same' })}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                        {subCategoryType && subCategoryType.title !== 'Provident Fund' && (
                          <div>
                            {paidUpDateComponent}

                            <div className="policy__select--box">
                              {categoryType &&
                                subCategoryType &&
                                (categoryType.title === 'Personal' || subCategoryType.title === 'Saving') &&
                                lifeInsuredComponents}
                              <Fragment>{policyOwnerComponent}</Fragment>
                              <Fragment>{beneficialComponent}</Fragment>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="lifeInsuredAge"
                                  value={this.state.lifeInsuredAge}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                  className={showAutoButton ? 'add-client-no-email-input' : ''}
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({
                                    id: 'Age of owner at policy start'
                                  })}
                                  :
                                </label>
                                {showAutoButton && (
                                  <Button
                                    disabled={autoButtonDiabled}
                                    variant="contained"
                                    color="primary"
                                    className={
                                      autoButtonDiabled ? 'action-change-button disable-button' : 'action-change-button'
                                    }
                                    onClick={this.handleClickAuto.bind(this)}
                                  >
                                    {intl.formatMessage({ id: 'Auto' })}
                                  </Button>
                                )}
                                <i className="bar" />
                                {this.state.err.lifeInsuredAge ? (
                                  <span className="error_field">
                                    {intl.formatMessage({
                                      id: this.state.err.lifeInsuredAge
                                    })}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              {subCategoryType && subCategoryType.title === 'Medical' && (
                                <div className="select-buttons-div">
                                  <div style={{ marginBottom: '15px' }}>
                                    <Button
                                      variant={this.state.smm === 'Yes' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.smm === 'Yes'
                                          ? 'plan-type-select-button medical-type-select-button selected'
                                          : 'plan-type-select-button medical-type-select-button'
                                      }
                                      key={0}
                                      onClick={() => this.handleButtonStatus(0)}
                                    >
                                      {intl.formatMessage({ id: 'SMM' })}
                                    </Button>

                                    <Button
                                      variant={this.state.hospitalCash === 'Yes' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.hospitalCash === 'Yes'
                                          ? 'plan-type-select-button medical-type-select-button selected'
                                          : 'plan-type-select-button medical-type-select-button'
                                      }
                                      key={1}
                                      onClick={() => this.handleButtonStatus(1)}
                                    >
                                      {intl.formatMessage({
                                        id: 'Hospital Cash'
                                      })}
                                    </Button>

                                    <Button
                                      variant={this.state.outPatient === 'Yes' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.outPatient === 'Yes'
                                          ? 'plan-type-select-button medical-type-select-button selected'
                                          : 'plan-type-select-button medical-type-select-button'
                                      }
                                      key={2}
                                      onClick={() => this.handleButtonStatus(2)}
                                    >
                                      {intl.formatMessage({ id: 'Outpatient' })}
                                    </Button>

                                    <Button
                                      variant={this.state.dental === 'Yes' ? 'contained' : 'outlined'}
                                      className={
                                        this.state.dental === 'Yes'
                                          ? 'plan-type-select-button medical-type-select-button selected'
                                          : 'plan-type-select-button medical-type-select-button'
                                      }
                                      key={3}
                                      onClick={() => this.handleButtonStatus(3)}
                                    >
                                      {intl.formatMessage({ id: 'Dental' })}
                                    </Button>
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="deductible"
                                      value={this.state.deductible || ''}
                                      onChange={this.handleChange.bind(this)}
                                      required="required"
                                    />
                                    <label className="control-label" htmlFor="input">
                                      {intl.formatMessage({ id: 'Deductible' })}:
                                    </label>
                                    <i className="bar" />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      name="claimLimit"
                                      value={this.state.claimLimit || ''}
                                      onChange={this.handleChange.bind(this)}
                                      required="required"
                                    />
                                    <label className="control-label" htmlFor="input">
                                      {intl.formatMessage({
                                        id: 'Max. Claim Limit'
                                      })}
                                      :
                                    </label>
                                    <i className="bar" />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {subCategoryType && subCategoryType.title === 'Critical Illness' && (
                          <div className="select-buttons-div">
                            <MyToggleButton
                              className="medical-type-select-button"
                              value={this.state.multiClaim}
                              targetValue={true}
                              onClick={this.triggerButton('multiClaim')}
                            >
                              {intl.formatMessage({ id: 'multi-claim' })}
                            </MyToggleButton>
                            <MyToggleButton
                              className="medical-type-select-button"
                              value={this.state.earlyStage}
                              targetValue={true}
                              onClick={this.triggerButton('earlyStage')}
                            >
                              {intl.formatMessage({ id: 'early-stage' })}
                            </MyToggleButton>
                          </div>
                        )}

                        {categoryType && (categoryType.title === 'Personal' || categoryType.title === 'General') && (
                          <div>
                            {policyStatusComponent}
                            {subCategoryType && subCategoryType.title !== 'Provident Fund' && (
                              <div className="form-group datepicker-group">
                                <DatePicker
                                  name="premiumHolidayDate"
                                  //maxDate={maxDate}
                                  format="DD-MMM-YYYY"
                                  clearable={true}
                                  value={
                                    this.state.premiumHolidayDate === null ||
                                    this.state.premiumHolidayDate === undefined ||
                                    this.state.premiumHolidayDate === ''
                                      ? null
                                      : moment.parseZone(this.state.premiumHolidayDate)
                                  }
                                  openTo="year"
                                  onChange={date => this.handleDateChange(date)}
                                  autoOk={true}
                                  label={intl.formatMessage({
                                    id: 'Premium Holiday from'
                                  })}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton>
                                          <TodayIcon color="action" style={{ fontSize: 20 }} />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </div>
                            )}

                            {subCategoryType &&
                              (subCategoryType.title === 'Saving' || subCategoryType.title === 'Provident Fund') && (
                                <div className="form-group">
                                  <NumberFormat
                                    //type="number"
                                    //name="totalWithdrawn"
                                    value={this.state.totalWithdrawn}
                                    thousandSeparator={true}
                                    onValueChange={values => {
                                      const { value } = values;
                                      this.setState({ totalWithdrawn: value });
                                    }}
                                    required="required"
                                  />
                                  <label
                                    className="control-label"
                                    //style={{ top: '-12px' }}
                                    htmlFor="input"
                                  >
                                    {intl.formatMessage({
                                      id: 'Total withdrawals'
                                    })}
                                    :
                                  </label>
                                  <i className="bar" />
                                </div>
                              )}

                            {((categoryType && categoryType.title === 'Personal') ||
                              (subCategoryType &&
                                (subCategoryType.title === 'Saving' ||
                                  subCategoryType.title === 'Provident Fund'))) && (
                              <div>
                                <Grid container spacing={1} style={{ marginBottom: 20 }}>
                                  <Grid item xs={6}>
                                    <NumberFormatTextField
                                      label={`${intl.formatMessage({
                                        id:
                                          categoryType && categoryType.title === 'Personal'
                                            ? 'cash-value'
                                            : 'Market Value'
                                      })}:`}
                                      name="marketValue"
                                      value={this.state.marketValue || ''}
                                      onChange={event => this.setState({ marketValue: event.target.value })}
                                      InputLabelProps={{
                                        style: {
                                          color: '#333'
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <NumberFormatTextField
                                      label={`${intl.formatMessage({ id: 'surrender-value' })}:`}
                                      name="surrenderValue"
                                      value={this.state.surrenderValue || ''}
                                      onChange={event => this.setState({ surrenderValue: event.target.value })}
                                      InputLabelProps={{
                                        style: {
                                          color: '#333'
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>

                                <MarketValueUpdateDateInput
                                  value={
                                    this.state.marketValueUpdateDate
                                      ? moment.parseZone(this.state.marketValueUpdateDate)
                                      : null
                                  }
                                  onChange={this.handleMarketValueUpdateDateChange}
                                  InputLabelProps={{
                                    style: {
                                      color: '#333'
                                    }
                                  }}
                                  InputAdornmentProps={{
                                    style: {
                                      margin: '0 0 12px 8px'
                                    }
                                  }}
                                  style={{ marginBottom: 20 }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}

                  {categoryType &&
                  subCategoryType &&
                  categoryType.title === 'Company' &&
                  subCategoryType.title !== 'Provident Fund' ? (
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active"> </li>
                        <li className="active"> </li>
                        <li> </li>
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Detail' })}</h2>
                        <Fragment>{beneficialComponent}</Fragment>
                        {subCategoryType && subCategoryType.title === 'Other' && policyStatusComponent}
                        {subCategoryType && subCategoryType.title === 'Medical' && (
                          <div className="select-buttons-div">
                            <div style={{ marginBottom: '15px' }}>
                              <Button
                                variant={this.state.smm === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.smm === 'Yes'
                                    ? 'plan-type-select-button medical-type-select-button selected'
                                    : 'plan-type-select-button medical-type-select-button'
                                }
                                key={0}
                                onClick={() => this.handleButtonStatus(0)}
                              >
                                {intl.formatMessage({ id: 'SMM' })}
                              </Button>

                              <Button
                                variant={this.state.hospitalCash === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.hospitalCash === 'Yes'
                                    ? 'plan-type-select-button medical-type-select-button selected'
                                    : 'plan-type-select-button medical-type-select-button'
                                }
                                key={1}
                                onClick={() => this.handleButtonStatus(1)}
                              >
                                {intl.formatMessage({ id: 'Hospital Cash' })}
                              </Button>

                              <Button
                                variant={this.state.outPatient === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.outPatient === 'Yes'
                                    ? 'plan-type-select-button medical-type-select-button selected'
                                    : 'plan-type-select-button medical-type-select-button'
                                }
                                key={2}
                                onClick={() => this.handleButtonStatus(2)}
                              >
                                {intl.formatMessage({ id: 'Outpatient' })}
                              </Button>

                              <Button
                                variant={this.state.dental === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.dental === 'Yes'
                                    ? 'plan-type-select-button medical-type-select-button selected'
                                    : 'plan-type-select-button medical-type-select-button'
                                }
                                key={3}
                                onClick={() => this.handleButtonStatus(3)}
                              >
                                {intl.formatMessage({ id: 'Dental' })}
                              </Button>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="deductible"
                                value={this.state.deductible || ''}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Deductible' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="claimLimit"
                                value={this.state.claimLimit || ''}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Max. Claim Limit' })}:
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        )}
                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}

                  {categoryType &&
                  subCategoryType &&
                  categoryType.title === 'General' &&
                  subCategoryType.title !== 'Saving' &&
                  subCategoryType.title !== 'Provident Fund' ? (
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active"> </li>
                        <li className="active"> </li>
                        <li> </li>
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Detail' })}</h2>
                        <div>
                          <Fragment>
                            {subCategoryType &&
                              subCategoryType.title !== 'Travel' &&
                              subCategoryType.title !== 'Other' &&
                              subCategoryType.title !== 'Accident' &&
                              subCategoryType.title !== 'Maid' &&
                              subCategoryType.title !== 'Mortgage' &&
                              policyOwnerComponent}
                          </Fragment>
                          {subCategoryType && subCategoryType.title === 'Other' && (
                            <Fragment>
                              <div className="form-group">
                                <textarea
                                  type="text"
                                  name="coverageDetail"
                                  value={this.state.coverageDetail}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                  rows="10"
                                  style={{ marginTop: '8px' }}
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({ id: 'Coverage Detail' })}:
                                </label>
                                <i className="bar" />
                              </div>

                              <div className="form-group">
                                <NumberFormat
                                  //type="number"
                                  //name="totalWithdrawn"
                                  value={this.state.totalWithdrawn}
                                  thousandSeparator={true}
                                  onValueChange={values => {
                                    const { value } = values;
                                    this.setState({ totalWithdrawn: value });
                                  }}
                                  required="required"
                                />
                                <label
                                  className="control-label"
                                  //style={{ top: '-12px' }}
                                  htmlFor="input"
                                >
                                  {intl.formatMessage({
                                    id: 'Total withdrawals'
                                  })}
                                  :
                                </label>
                                <i className="bar" />
                              </div>

                              <Grid container spacing={1} style={{ marginBottom: 20 }}>
                                <Grid item xs={6}>
                                  <NumberFormatTextField
                                    label={`${intl.formatMessage({
                                      id:
                                        categoryType && categoryType.title === 'Personal'
                                          ? 'cash-value'
                                          : 'Market Value'
                                    })}:`}
                                    name="marketValue"
                                    value={this.state.marketValue || ''}
                                    onChange={event => this.setState({ marketValue: event.target.value })}
                                    InputLabelProps={{
                                      style: {
                                        color: '#333'
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <MarketValueUpdateDateInput
                                    value={
                                      this.state.marketValueUpdateDate
                                        ? moment.parseZone(this.state.marketValueUpdateDate)
                                        : null
                                    }
                                    onChange={this.handleMarketValueUpdateDateChange}
                                    InputLabelProps={{
                                      style: {
                                        color: '#333'
                                      }
                                    }}
                                    InputAdornmentProps={{
                                      style: {
                                        margin: '0 0 12px 8px'
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Fragment>
                          )}
                          {subCategoryType && subCategoryType.title === 'Motor' ? (
                            <div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="vehicleBrand"
                                  value={this.state.vehicleBrand}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({ id: 'Vehicle Brand' })}:
                                </label>
                                <i className="bar" />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="vehicleModel"
                                  value={this.state.vehicleModel}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({ id: 'Vehicle Model' })}:
                                </label>
                                <i className="bar" />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="vehicleYear"
                                  value={this.state.vehicleYear}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({ id: 'Vehicle Year' })}:
                                </label>
                                <i className="bar" />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="ncb"
                                  value={this.state.ncb}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({ id: 'NCB' })}:
                                </label>
                                <i className="bar" />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}

                          {subCategoryType && subCategoryType.title === 'Home' ? (
                            <div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="homeSize"
                                  value={this.state.homeSize}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({ id: 'Home Size' })}:
                                </label>
                                <i className="bar" />
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="homeContentsValue"
                                  value={this.state.homeContentsValue || ''}
                                  onChange={this.handleChange.bind(this)}
                                  required="required"
                                />
                                <label className="control-label" htmlFor="input">
                                  {intl.formatMessage({
                                    id: 'Home contents value'
                                  })}
                                  :
                                </label>
                                <i className="bar" />
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        {subCategoryType && subCategoryType.title === 'Travel' && (
                          <div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="coverageDay"
                                value={this.state.coverageDay}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({
                                  id: 'Coverage no. of day'
                                })}
                                :
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="tripDelayCover"
                                value={this.state.tripDelayCover}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Trip delay cover' })}:{' '}
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="lossItemCover"
                                value={this.state.lossItemCover}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Loss item cover' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="mobilePhoneCover"
                                value={this.state.mobilePhoneCover}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({
                                  id: 'Mobile phone cover'
                                })}
                                :
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="rentalCarCover"
                                value={this.state.rentalCarCover}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Rental car cover' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="emergencyTel"
                                value={this.state.emergencyTel}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Emergency Tel' })}:
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        )}
                        {subCategoryType && subCategoryType.title === 'Accident' ? (
                          <div>
                            <div className="row">
                              <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                  <select
                                    name="paymentTerms"
                                    onChange={this.handleChange.bind(this)}
                                    value={this.state.paymentTerms}
                                  >
                                    {this.state.paymentTerms && (
                                      <option value={this.state.paymentTerms}>
                                        {this.state.paymentTerms &&
                                          this.state.paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, function(
                                            paymentTerm
                                          ) {
                                            return intl.formatMessage({
                                              id: paymentTerm
                                            });
                                          })}
                                      </option>
                                    )}
                                    <option value="" />
                                    {Object.values(TERMS_OPTIONS).map(option => (
                                      <option value={option} key={option}>
                                        {getTermsIntl(intl, option)}
                                      </option>
                                    ))}
                                  </select>
                                  <div>
                                    {this.state.paymentTerms === 'Other' ? (
                                      <input
                                        type="text"
                                        name="paymentTermsOther"
                                        placeholder={intl.formatMessage({
                                          id: 'Enter Payment Terms'
                                        })}
                                        value={this.state.paymentTermsOther}
                                        onChange={this.handleChange.bind(this)}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <label className="control-label" htmlFor="select">
                                    {intl.formatMessage({ id: 'Payment Terms' })}:
                                  </label>
                                  <i className="bar" />
                                </div>
                              </div>

                              <div className="col-md-6 col-xs-6">
                                <div className="form-group">
                                  <select
                                    name="protectionTerms"
                                    onChange={this.handleChange.bind(this)}
                                    value={this.state.protectionTerms}
                                  >
                                    {this.state.protectionTerms && (
                                      <option value={this.state.protectionTerms}>
                                        {this.state.protectionTerms &&
                                          this.state.protectionTerms.replace(
                                            /Years|Till age|Whole Life|Other/gi,
                                            function(protectionTerm) {
                                              return intl.formatMessage({
                                                id: protectionTerm
                                              });
                                            }
                                          )}
                                      </option>
                                    )}
                                    <option value="" />
                                    {Object.values(TERMS_OPTIONS).map(option => (
                                      <option value={option} key={option}>
                                        {getTermsIntl(intl, option)}
                                      </option>
                                    ))}
                                  </select>
                                  <div>
                                    {this.state.protectionTerms === 'Other' ? (
                                      <input
                                        type="text"
                                        name="protectionTermsOther"
                                        placeholder={intl.formatMessage({
                                          id: 'Enter Protection Terms'
                                        })}
                                        value={this.state.protectionTermsOther}
                                        onChange={this.handleChange.bind(this)}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <label className="control-label" htmlFor="select">
                                    {intl.formatMessage({ id: 'Protection Terms' })}:
                                  </label>
                                  <i className="bar" />
                                  <Button
                                    className="action-change-button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      this.setState({
                                        protectionTerms: this.state.paymentTerms,
                                        protectionTermsOther: this.state.paymentTermsOther
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'Same' })}
                                  </Button>
                                </div>
                              </div>
                            </div>

                            {paidUpDateComponent}

                            <div className="policy__select--box">{lifeInsuredComponents}</div>

                            <div className="policy__select--box">{policyOwnerComponent}</div>
                            <div className="policy__select--box">{beneficialComponent}</div>

                            <div className="form-group">
                              <input
                                type="text"
                                name="permanentDisablement"
                                value={this.state.permanentDisablement}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({
                                  id: 'Permanent Disablement'
                                })}
                                :
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="medicalExpenses"
                                value={this.state.medicalExpenses}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Medical Expenses' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="physiotherapy"
                                value={this.state.physiotherapy}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Physiotherapy' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="chineseDoctor"
                                value={this.state.chineseDoctor}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Chinese Doctor' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="chineseBonesetter"
                                value={this.state.chineseBonesetter}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({
                                  id: 'Chinese Bonesetter'
                                })}
                                :
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="hospitalCashDetail"
                                value={this.state.hospitalCashDetail}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Hospital Cash' })}:
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {subCategoryType && subCategoryType.title === 'Maid' ? (
                          <div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="employersLiability"
                                value={this.state.employersLiability}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({
                                  id: "Employer's Liability"
                                })}
                                :
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="medicalExpenses"
                                value={this.state.medicalExpenses}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Medical Expenses' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="outPatientDetail"
                                value={this.state.outPatientDetail}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Outpatient' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="dentalDetail"
                                value={this.state.dentalDetail}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Dental' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="repatriation"
                                value={this.state.repatriation}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Repatriation' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="accident"
                                value={this.state.accident}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Accident' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="maidReplacement"
                                value={this.state.maidReplacement}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Maid Replacement' })}:
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {subCategoryType && subCategoryType.title === 'Mortgage' ? (
                          <div>
                            <div className="form-group">
                              <select
                                name="paymentTerms"
                                onChange={this.handleChange.bind(this)}
                                value={this.state.paymentTerms}
                              >
                                {this.state.paymentTerms && (
                                  <option value={this.state.paymentTerms}>
                                    {this.state.paymentTerms &&
                                      this.state.paymentTerms.replace(/Years|Till age/gi, function(paymentTerm) {
                                        return intl.formatMessage({
                                          id: paymentTerm
                                        });
                                      })}
                                  </option>
                                )}
                                <option value="" />
                                {Object.values(TERMS_OPTIONS).map(option => (
                                  <option value={option} key={option}>
                                    {getTermsIntl(intl, option)}
                                  </option>
                                ))}
                              </select>
                              <div>
                                {this.state.paymentTerms === 'Other' ? (
                                  <input
                                    type="text"
                                    name="paymentTermsOther"
                                    placeholder={intl.formatMessage({
                                      id: 'Enter Payment Terms'
                                    })}
                                    value={this.state.paymentTermsOther}
                                    onChange={this.handleChange.bind(this)}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              <label className="control-label" htmlFor="select">
                                {intl.formatMessage({ id: 'Payment Terms' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="premium"
                                value={this.state.propertyValue}
                                thousandSeparator={true}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ propertyValue: value });
                                }}
                                required="required"
                              />
                              <label
                                className="control-label"
                                //style={{ top: '-12px' }}
                                htmlFor="input"
                              >
                                {intl.formatMessage({ id: 'Property Value' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="premium"
                                value={this.state.downPayment}
                                thousandSeparator={true}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ downPayment: value });
                                }}
                                required="required"
                              />
                              <label
                                className="control-label"
                                //style={{ top: '-12px' }}
                                htmlFor="input"
                              >
                                {intl.formatMessage({ id: 'Down Payment' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="loanRatio"
                                value={this.state.loanRatio || ''}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Loan Ratio' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="premium"
                                value={this.state.outStandingLoan}
                                thousandSeparator={true}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ outStandingLoan: value });
                                }}
                                required="required"
                              />
                              <label
                                className="control-label"
                                //style={{ top: '-12px' }}
                                htmlFor="input"
                              >
                                {intl.formatMessage({ id: 'Outstanding Loan' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="interestRate"
                                value={this.state.interestRate || ''}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Interest Rate' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="interestTerms"
                                value={this.state.interestTerms || ''}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Interest Terms' })}:
                              </label>
                              <i className="bar" />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                name="penaltyPeriod"
                                value={this.state.penaltyPeriod || ''}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Penalty period' })}:
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}

                        {subCategoryType && subCategoryType.title === 'Other' && policyStatusComponent}

                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailView;
