import React, { useMemo } from 'react';
import Slider from 'react-slick';
import { isMobile, isIPad13 } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import { useRef } from 'react';
import { Typography } from '@material-ui/core';
import './ContentSlides.scss';

const ContentSlides = ({ title, titleChildren, children, seeAllButton }) => {
  const isLargeScreen = window.innerWidth > 1024;

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: isLargeScreen ? 6 : 3,
      slidesToScroll: isLargeScreen ? 6 : 3,
      swipe: false
    }),
    [isLargeScreen]
  );

  const sliderRef = useRef(null);

  return (
    <div className="contentSlidesRow">
      <div className="contentSlidesHeader">
        <div className="contentSlidesHeaderTitle">
          <Typography variant="h6">{title}</Typography>
          {titleChildren}
        </div>
        {seeAllButton && (
          <Button className="contentSlidesSeeMore" onClick={seeAllButton.onClick}>
            {seeAllButton.label}
          </Button>
        )}
      </div>
      {isMobile || isIPad13 ? (
        <div className="mobileSliderContainer">{children}</div>
      ) : (
        <Slider {...settings} ref={sliderRef}>
          {children}
        </Slider>
      )}
    </div>
  );
};

export default ContentSlides;
