import React, { useEffect, useRef, useState } from 'react';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import { decimalToString } from '../../utils/formatNumber';

const useStyles = makeStyles({
  root: ({ classes }) =>
    _.merge(
      {
        height: 4,
        padding: '3px 0 0 0',
        marginLeft: '6px',
        width: '95%'
      },
      classes?.root
    ),
  thumb: ({ classes }) =>
    _.merge(
      {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -9,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
          boxShadow: '#ccc 0 2px 3px 1px'
        },
        '& .bar': {
          height: 7,
          width: 1,
          backgroundColor: 'currentColor',
          marginLeft: 1,
          marginRight: 1
        }
      },
      classes?.thumb
    ),
  active: {},
  valueLabel: ({ classes }) =>
    _.merge(
      {
        top: -20,
        fontSize: '1.25rem',
        '& > span': {
          width: 'unset',
          height: 'unset',
          borderRadius: 8,
          transform: 'unset',
          padding: '4px 8px',
          lineHeight: '1.4em',
          fontWeight: '500',
          background: 'rgba(97, 97, 97, 0.9)',
          '& > span': {
            transform: 'unset',
            whiteSpace: 'nowrap'
          }
        }
      },
      classes?.valueLabel
    ),
  track: ({ classes, trackColor }) =>
    _.merge(
      {
        color: trackColor,
        height: 4,
        borderRadius: 8
      },
      classes?.track
    ),
  rail: ({ classes }) =>
    _.merge(
      {
        height: 4,
        borderRadius: 8,
        backgroundColor: '#3966f880'
      },
      classes?.rail
    ),
  mark: ({ classes }) =>
    _.merge(
      {
        backgroundColor: '#fff',
        border: '2px solid #e9e9e9',
        borderRadius: '50%',
        height: 8,
        width: 8,
        marginTop: -2
      },
      classes?.mark
    ),
  markActive: ({ classes }) =>
    _.merge(
      {
        borderColor: '#96dbfa'
      },
      classes?.markActive
    ),
  markLabel: ({ classes }) =>
    _.merge(
      {
        top: 15,
        fontSize: 14
      },
      classes?.markLabel
    )
});

function AirbnbThumbComponent(props) {
  const { children, ...rest } = props;
  return (
    <span {...rest}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
      {children}
    </span>
  );
}

const MySlider = props => {
  const {
    classes = {},
    className,
    steps,
    min,
    max,
    value,
    onChange,
    onChangeCommitted,
    stopPropagation,
    timedUpdateDelay,
    getTrackColor,
    ...rest
  } = props;

  const [internalValue, setInternalValue] = useState(value);
  const _unmounted = useRef(false);
  const timedUpdate = useRef(undefined);
  const _classes = useStyles({
    classes: classes,
    trackColor: getTrackColor ? getTrackColor(internalValue) : undefined
  });
  const handleBeforeChange = (event, value) => {
    event.preventDefault();
    if (stopPropagation) {
      event.stopPropagation();
    }
    setInternalValue(value);
    if (onChange) {
      onChange(event, value);
    }

    if (timedUpdateDelay) {
      if (timedUpdate.current) {
        clearTimeout(timedUpdate.current);
      }
      timedUpdate.current = setTimeout(() => {
        if (!_unmounted.current) {
          if (internalValue === value) {
            onChangeCommitted(event, value);
          }
          timedUpdate.current = undefined;
        }
      }, timedUpdateDelay);
    }
  };

  const handleAfterChange = (event, value) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (onChangeCommitted) {
      onChangeCommitted(event, value);
    }
  };

  useEffect(() => {
    return function cleanup() {
      _unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <Slider
      classes={_classes}
      className={clsx('slider', className)}
      onChange={handleBeforeChange}
      onChangeCommitted={handleAfterChange}
      ThumbComponent={AirbnbThumbComponent}
      valueLabelDisplay="auto"
      // ValueLabelComponent={props => {
      // 	const { children, open, value } = props;
      //
      // 	return (
      // 		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      // 			{children}
      // 		</Tooltip>
      // 	);
      // }}
      min={min}
      max={max}
      step={max !== undefined && min !== undefined && steps !== undefined ? Math.round((max - min) / steps) : undefined}
      {...rest}
      value={internalValue}
    />
  );
};

export default MySlider;
