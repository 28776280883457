import React, { Fragment, memo, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { Doughnut } from 'react-chartjs-2';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { formatNumber } from '../../../utils/formatNumber';
import EditIconButton from '../../../components/EditIconButton';
import { decimalToString } from '../../../utils/formatNumber';
import PVPHorizontalBar from './PVPChartsView/PVPHorizontalBar';
import MySlider from '../../../components/MySlider/MySlider';
import { connect } from 'react-redux';
import { updateUserDetails } from '../../../store/user/duck';
import {
  updateAnalysisInputs,
  updateAnalysisSavingExport,
  updateSavingSelectedPolicies
} from '../../../store/analysis/duck';
import { injectIntl } from 'react-intl';
import SavingAnnuitySlides from '../../../components/SavingAnnuitySlides/SavingAnnuitySlides';
import { futureValueCalculator, getCalculatedSavingData, retirementValueCalculator } from '../../../utils/analysis';
import { RETURN_RATE_OPTIONS, SAVING_YEAR_OPTIONS } from '../../../constants/analysis';
import './styles.css';
import MoreInfoTrigger from '../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import withStyles from '@material-ui/core/styles/withStyles';
import { toggleDialog } from '../../../store/control/duck';
import { getUserCurrency, isAdviserType } from '../../../utils/user';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import SavingInputPanel from './SavingInputPanel/SavingInputPanel';
import * as echarts from 'echarts';
import SavingSummary from './SavingSummary/SavingSummary';
import SavingPolicyStatistics from './SavingPolicyStatistics/SavingPolicyStatistics';
// import SaveEarlyChart from "./SaveEarlyChart/SaveEarlyChart";

const PurpleSlider = withStyles({
  root: {
    color: 'rgb(138, 43, 226)'
  }
})(MySlider);

const SavingView = memo(
  props => {
    const {
      intl,
      userDetails,
      location,
      isWide,
      isSafariGenerate,
      savingTerms,
      showAccumulationValueAt,
      returnRate,
      inflationRate,
      currentAsset,
      retireAfter,
      retirementTerms,
      retirementReturnRate,
      retirementInflationRate,
      savingTotalCoverage,
      savingToReachData,
      monthlyIncome,
      age,
      showAge,
      exportInputs,
      inputPanelRef,
      mySwipeableViews,
      toggleSwipeableView,
      updateAnalysisInputs
    } = props;

    const [defaultSavingExpanded, setDefaultSavingExpanded] = useState(false);

    const clientId = JSON.parse(localStorage.getItem('clientId'));
    const currency = getUserCurrency(userDetails);
    const savingScore = savingToReachData.score;

    useEffect(() => {
      window.scrollTo(0, 0);
      if (clientId) {
        setDefaultSavingExpanded(_.get(location, 'state.clientFactsheetDetail.clientIncData.defaultSaving', false));
      }
    }, [clientId, location]);

    const {
      returnRateMonthly,
      inflationRateMonthly,
      principalValue,
      futureValueFirst,
      futureValueSecond,
      retirementValueAtRetireAfter,
      retirementReturnRateMonthly,
      retirementInflationRateMonthly,
      retirementIncomeLevel,
      retirementDisplayValue
    } = getCalculatedSavingData(
      userDetails,
      monthlyIncome,
      currentAsset,
      returnRate,
      inflationRate,
      savingTerms,
      savingTotalCoverage,
      showAccumulationValueAt,
      retireAfter,
      retirementReturnRate,
      retirementInflationRate,
      retirementTerms
    );

    // Saving ratio Score Chart
    let savingRatioChartOption = {
      title: {
        show: false
      },
      animationDuration: 1200,
      animationDurationUpdate: 1200,
      grid: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      tooltip: {
        formatter: '{b}: {c}'
      },
      legend: {
        show: false
      },
      toolbox: {},
      series: [
        {
          name: intl.formatMessage({ id: 'Saving Ratio' }),
          type: 'gauge',
          min: 0,
          max: 80,
          splitNumber: 4,
          startAngle: 180,
          endAngle: 0,
          radius: '100%',
          data: [
            {
              value: isNaN(parseInt(savingScore)) ? 0 : parseInt(savingScore),
              name: intl.formatMessage({ id: 'Saving Ratio' })
            }
          ],
          axisLine: {
            lineStyle: {
              color: [
                [
                  1,
                  new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                      offset: 0,
                      color: '#ff0000'
                    },
                    {
                      offset: 0.1875,
                      color: '#ffa600'
                    },
                    {
                      offset: 0.475,
                      color: '#3eda7d'
                    }
                  ])
                ]
              ],
              width: 43
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            // 坐标轴文本标签，详见axis.axisLabel
            show: true,
            distance: 20,
            // interval : 20000,
            formatter: v => `${v}%`,
            color: '#d6d6d6',
            fontWeight: 'normal',
            fontSize: 10,
            shadowBlur: 0
          },
          splitLine: {
            // 分隔线
            show: false // 默认显示，属性show控制显示与否
          },
          pointer: {
            length: '45%',
            width: 6
          },
          itemStyle: {
            color: '#3966f8',
            shadowBlur: 0
          },
          title: {
            show: false,
            offsetCenter: [0, 0], // x, y，单位px
            textStyle: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              color: '#757575',
              fontSize: 13
            }
          },
          detail: {
            show: true, //disable showing xx/100 score in UI
            borderWidth: 0,
            borderColor: '#ccc',
            offsetCenter: [0, 40], // x, y，单位px
            // formatter: '',
            valueAnimation: true,
            formatter: value => `${value > 80 ? '>80' : value}%`,
            textStyle: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              color: '#3966f8',
              fontSize: 25
            },
            height: '31%',
            width: '25%',
            rich: {}
          },
          tooltips: {
            formatter: '{a} <br/>{b} : {c}%'
          }
        }
      ]
    };

    let colors = ['#3ffff8', '#3888f8'];
    let xAxisData = [];
    let yAxisData_totalSaving = [];
    let yAxisData_totalValue = [];
    let yAxisData_savingPeriod = [];
    let yAxisData_currentAssetAfterSavingPeriod = [];
    let yAxisData_currentAssetBeforeSavingPeriod = [];
    for (
      let i = 0;
      i <= (showAccumulationValueAt > savingTerms ? showAccumulationValueAt : showAccumulationValueAt);
      i++
    ) {
      //get the Current Asset area before saving period
      if (i <= savingTerms) {
        yAxisData_currentAssetBeforeSavingPeriod.push(currentAsset);
      } else {
        yAxisData_currentAssetBeforeSavingPeriod.push('');
      }

      //get the Current Asset area after saving period
      if (i >= savingTerms) {
        yAxisData_currentAssetAfterSavingPeriod.push(currentAsset);
      } else {
        yAxisData_currentAssetAfterSavingPeriod.push('');
      }

      if (i <= savingTerms) {
        yAxisData_totalSaving.push(savingTotalCoverage * 12 * i + currentAsset);
        yAxisData_savingPeriod.push(savingTotalCoverage * 12 * i + currentAsset);
      } else {
        yAxisData_totalSaving.push(yAxisData_totalSaving[yAxisData_totalSaving.length - 1]);
      }

      let tmpFirst = futureValueCalculator(
        returnRateMonthly - inflationRateMonthly,
        savingTerms * 12,
        -savingTotalCoverage,
        -currentAsset,
        1
      );

      yAxisData_totalValue.push(
        Math.round(
          futureValueCalculator(
            returnRateMonthly - inflationRateMonthly,
            i > savingTerms ? (i - savingTerms) * 12 : i * 12,
            i > savingTerms ? 0 : -savingTotalCoverage,
            i > savingTerms ? -tmpFirst : -currentAsset,
            1
          )
        )
      );

      if (i === 0 || i === savingTerms || i === showAccumulationValueAt) {
        // xAxisData.push(String(i+'年'));
        xAxisData.push('');
      } else {
        xAxisData.push('');
      }
    }

    let savingCompareChartOptions = {
      color: colors,
      tooltip: {
        trigger: 'none'
      },
      grid: {
        top: 30,
        bottom: 30
      },
      xAxis: [
        {
          name: intl.formatMessage({ id: 'Year' }),
          nameLocation: 'middle',
          clickable: false,
          type: 'category',
          boundaryGap: false,
          axisTick: {
            show: false
          },
          data: xAxisData
        }
      ],
      yAxis: [
        {
          name: intl.formatMessage({ id: 'Saving Value' }),
          nameLocation: 'middle',
          type: 'value',
          axisLabel: { show: false },
          max:
            returnRate > inflationRate
              ? yAxisData_totalValue[yAxisData_totalValue.length - 1] * 1.2
              : yAxisData_totalSaving[yAxisData_totalSaving.length - 1] * 1.2
        }
      ],
      series: [
        {
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: returnRate >= inflationRate ? yAxisData_totalValue : '',
          showSymbol: false
        },
        {
          name: '',
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: yAxisData_totalSaving,
          showSymbol: false,
          markPoint: {
            symbol: 'circle',
            symbolSize: 5,

            data: [
              {
                value: formatNumber(principalValue, intl),
                xAxis: 0,
                yAxis:
                  yAxisData_totalSaving[yAxisData_totalSaving.length - 1] * (returnRate > inflationRate ? 0.95 : 1.05)
              },
              {
                value: showAccumulationValueAt + intl.formatMessage({ id: 'Yrs' }),
                xAxis: showAccumulationValueAt,
                yAxis: 0
              },
              {
                value: savingTerms + intl.formatMessage({ id: 'Yrs' }),
                xAxis: savingTerms,
                yAxis: 0
              },
              {
                value: formatNumber(futureValueSecond, intl),
                xAxis: 0,
                yAxis:
                  yAxisData_totalValue[yAxisData_totalValue.length - 1] * (returnRate > inflationRate ? 1.05 : 0.95)
              },
              showAccumulationValueAt > savingTerms && savingTotalCoverage > 0
                ? {
                    value: intl.formatMessage({ id: 'Contribution Period' }),
                    xAxis:
                      showAccumulationValueAt === 1
                        ? 0.75
                        : showAccumulationValueAt > savingTerms
                        ? savingTerms / 1.5
                        : showAccumulationValueAt / 1.5,
                    yAxis: yAxisData_totalSaving[yAxisData_totalSaving.length - 1] / 3 + currentAsset * 0.6
                  }
                : ''
            ]
          },
          markLine: {
            label: {
              normal: {
                show: false
              }
            },
            symbol: ['none', 'none'],
            data: [
              { type: 'max' },
              { yAxis: yAxisData_totalValue[yAxisData_totalValue.length - 1] },
              [
                {
                  // name: '停止儲蓄年期:',
                  itemStyle: { normal: { type: 'solid', color: '#8B0000' } },
                  coord: [savingTerms, 0]
                },
                {
                  coord: [savingTerms, yAxisData_totalSaving[yAxisData_totalSaving.length - 1]]
                }
              ]
            ]
          }
        },
        {
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: returnRate < inflationRate ? yAxisData_totalValue : '',
          showSymbol: false
        },
        {
          // Contribution Period area
          type: 'line',
          areaStyle: {
            color: '#2167ad'
          },
          data: showAccumulationValueAt > savingTerms ? yAxisData_savingPeriod : 0,
          showSymbol: false
        },
        {
          itemStyle: { normal: { color: '#c18ff0' } },
          type: 'line',
          areaStyle: {
            color: '#c18ff0'
          },
          data: yAxisData_currentAssetBeforeSavingPeriod,
          showSymbol: false
        },
        {
          itemStyle: { normal: { color: '#2167ad' } },
          type: 'line',
          areaStyle: {
            color: '#2167ad'
          },
          data: showAccumulationValueAt > savingTerms ? yAxisData_currentAssetAfterSavingPeriod : 0,
          showSymbol: false
        },
        {
          itemStyle: { normal: { color: '#c18ff0' } },
          type: 'line',
          areaStyle: {
            color: '#c18ff0'
          },
          data: showAccumulationValueAt > savingTerms ? yAxisData_currentAssetAfterSavingPeriod : 0,
          showSymbol: false
        }
      ]
    };

    let yAxisData_afterTotal = [];
    let yAxisData_monthlyExpense = [];
    let xAxisData_date = [];

    let tmpFirst = 0;
    let thisAfter = retireAfter;
    let yearsToCal = retirementTerms;
    let netReturnRate = retirementReturnRateMonthly - retirementInflationRateMonthly;

    let yAxisData_totalSaving_gr = [];
    let yAxisData_totalValue_gr = [];
    let yAxisData_savingPeriod_gr = [];
    let yAxisData_currentAssetAfterSavingPeriod_gr = [];
    let yAxisData_currentAssetBeforeSavingPeriod_gr = [];
    for (let i = 0; i <= (thisAfter > savingTerms ? thisAfter : thisAfter); i++) {
      //get the Current Asset area before saving period
      if (i <= savingTerms) {
        yAxisData_currentAssetBeforeSavingPeriod_gr.push(currentAsset);
      } else {
        yAxisData_currentAssetBeforeSavingPeriod_gr.push('');
      }

      //get the Current Asset area after saving period
      if (i >= savingTerms) {
        yAxisData_currentAssetAfterSavingPeriod_gr.push(currentAsset);
      } else {
        yAxisData_currentAssetAfterSavingPeriod_gr.push('');
      }

      if (i <= savingTerms) {
        yAxisData_totalSaving_gr.push(savingTotalCoverage * 12 * i + currentAsset);
        yAxisData_savingPeriod_gr.push(savingTotalCoverage * 12 * i + currentAsset);
      } else {
        yAxisData_totalSaving_gr.push(yAxisData_totalSaving_gr[yAxisData_totalSaving_gr.length - 1]);
      }

      let tmpFirst = futureValueCalculator(
        returnRateMonthly - inflationRateMonthly,
        savingTerms * 12,
        -savingTotalCoverage,
        -currentAsset,
        1
      );

      yAxisData_totalValue_gr.push(
        Math.round(
          futureValueCalculator(
            returnRateMonthly - inflationRateMonthly,
            i > savingTerms ? (i - savingTerms) * 12 : i * 12,
            i > savingTerms ? 0 : -savingTotalCoverage,
            i > savingTerms ? -tmpFirst : -currentAsset,
            1
          )
        )
      );
    }

    //valud it has after {useAfter} years
    tmpFirst = futureValueCalculator(
      returnRateMonthly - inflationRateMonthly,
      thisAfter > savingTerms ? (thisAfter - savingTerms) * 12 : thisAfter * 12,
      thisAfter > savingTerms ? 0 : -savingTotalCoverage,
      thisAfter > savingTerms ? -futureValueFirst : -currentAsset,
      1
    );

    let monthlyExpense = retirementValueCalculator(netReturnRate, yearsToCal * 12, -tmpFirst, 0, 1);

    for (let i = 0; i < thisAfter + yearsToCal + 1; i++) {
      xAxisData_date.push('');
    }

    for (let i = 0; i < thisAfter; i++) {
      yAxisData_afterTotal.push('');
      yAxisData_monthlyExpense.push('');
    }

    for (let i = 0; i <= yearsToCal; i++) {
      if (i === 0) {
        yAxisData_afterTotal.push(tmpFirst);
        yAxisData_monthlyExpense.push(monthlyExpense);
      } else {
        let curentAsset_tmp = futureValueCalculator(
          netReturnRate,
          12,
          yAxisData_monthlyExpense[yAxisData_monthlyExpense.length - 1],
          -yAxisData_afterTotal[yAxisData_afterTotal.length - 1],
          1
        ).toFixed(3);

        let monthlyExpense_tmp = retirementValueCalculator(
          netReturnRate,
          (yearsToCal - i) * 12,
          -curentAsset_tmp,
          0,
          1
        );

        yAxisData_afterTotal.push(curentAsset_tmp);
        yAxisData_monthlyExpense.push(monthlyExpense_tmp);
      }
    }

    let retireChartOptions = {
      color: colors,
      tooltip: {
        trigger: 'none'
      },
      grid: {
        top: 30,
        bottom: 30
      },
      xAxis: [
        {
          name: intl.formatMessage({ id: 'Year' }),
          nameLocation: 'middle',
          clickable: false,
          type: 'category',
          boundaryGap: false,
          axisTick: {
            show: false
          },
          data: xAxisData_date
        }
      ],
      yAxis: [
        {
          name: intl.formatMessage({ id: 'Saving Value' }),
          nameLocation: 'middle',
          type: 'value',
          axisLabel: { show: false },
          max:
            returnRate > inflationRate
              ? yAxisData_totalValue_gr[yAxisData_totalValue_gr.length - 1] * 1.2
              : yAxisData_totalSaving_gr[yAxisData_totalSaving_gr.length - 1] * 1.2
        }
      ],
      series: [
        {
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: returnRate > inflationRate ? yAxisData_totalValue_gr : '',
          showSymbol: false
        },
        {
          name: '',
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: yAxisData_totalSaving_gr,
          showSymbol: false,
          markPoint: {
            symbol: 'circle',
            symbolSize: 5,

            data: [
              //Value displaying in y-axis showing the total saving
              {
                value: formatNumber(principalValue, intl),
                xAxis: 0,
                yAxis: yAxisData_totalSaving_gr[yAxisData_totalSaving_gr.length - 1] * (netReturnRate > 0 ? 0.95 : 1.05)
              },
              //Value displaying in y-axis showing the total value
              {
                value: formatNumber(yAxisData_totalValue_gr[yAxisData_totalValue_gr.length - 1], intl),
                xAxis: 0,
                yAxis:
                  yAxisData_totalValue_gr[yAxisData_totalValue_gr.length - 1] *
                  (returnRate > inflationRate ? 1.05 : 0.95)
              },
              //wording of Contribution Period
              thisAfter > savingTerms && savingTotalCoverage > 0
                ? {
                    value: intl.formatMessage({ id: 'Contribution Period' }),
                    xAxis: thisAfter === 1 ? 0.75 : thisAfter > savingTerms ? savingTerms / 1.5 : thisAfter / 1.5,
                    yAxis: yAxisData_totalSaving_gr[yAxisData_totalSaving_gr.length - 1] / 3 + currentAsset * 0.6
                  }
                : ''
            ]
          },
          markLine: {
            label: {
              normal: {
                show: false
              }
            },
            symbol: ['none', 'none'],
            data: [
              { type: 'max' },
              {
                yAxis: yAxisData_totalValue_gr[yAxisData_totalValue_gr.length - 1]
              },
              [
                {
                  // name: '停止儲蓄年期:',
                  itemStyle: { normal: { type: 'solid', color: '#8B0000' } },
                  coord: [savingTerms, 0]
                },
                {
                  coord: [
                    savingTerms,
                    thisAfter > savingTerms ? yAxisData_totalSaving_gr[yAxisData_totalSaving_gr.length - 1] : 0
                  ]
                }
              ]
            ]
          }
        },
        {
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: returnRate < inflationRate ? yAxisData_totalValue_gr : '',
          showSymbol: false
        },
        {
          // Contribution Period area
          type: 'line',
          areaStyle: {
            color: '#2167ad'
          },
          data: thisAfter > savingTerms ? yAxisData_savingPeriod_gr : 0,
          showSymbol: false
        },
        {
          itemStyle: { normal: { color: '#c18ff0' } },
          type: 'line',
          areaStyle: {
            color: '#c18ff0'
          },
          data: yAxisData_currentAssetBeforeSavingPeriod_gr,
          showSymbol: false
        },
        {
          itemStyle: { normal: { color: '#2167ad' } },
          type: 'line',
          areaStyle: {
            color: '#2167ad'
          },
          data: thisAfter > savingTerms ? yAxisData_currentAssetAfterSavingPeriod_gr : 0,
          showSymbol: false
        },
        {
          itemStyle: { normal: { color: '#c18ff0' } },
          type: 'line',
          areaStyle: {
            color: '#c18ff0'
          },
          data: thisAfter > savingTerms ? yAxisData_currentAssetAfterSavingPeriod_gr : 0,
          showSymbol: false
        },
        //after retirement/general
        {
          type: 'line',
          itemStyle: { normal: { color: '#FFA600' } },
          areaStyle: { color: '#FFA600' },
          smooth: false,
          data: yAxisData_afterTotal,
          showSymbol: false,
          markPoint: {
            symbol: 'circle',
            symbolSize: 5,

            data: [
              //spending wording
              {
                value: intl.formatMessage({ id: 'Spending Period' }),
                itemStyle: { normal: { color: '#FFA600' } },
                xAxis: thisAfter + yearsToCal / 3,
                yAxis: yAxisData_totalValue_gr[yAxisData_totalValue_gr.length - 1] / 4
              }
            ]
          }
        },

        {
          name: '',
          type: 'line',
          areaStyle: {},
          smooth: false,
          data: xAxisData_date,
          showSymbol: false,
          markPoint: {
            symbol: 'circle',
            symbolSize: 5,
            data: [
              //End year
              {
                value: thisAfter + yearsToCal + intl.formatMessage({ id: 'Yrs' }),
                itemStyle: { normal: { color: '#3888f8' } },
                xAxis: xAxisData_date.length - 1,
                yAxis: 0
              },
              //Start Year
              thisAfter / (thisAfter + yearsToCal) < 0.9
                ? {
                    value: thisAfter + intl.formatMessage({ id: 'Yrs' }),
                    itemStyle: { normal: { color: '#3888f8' } },
                    xAxis: xAxisData_date.length - yearsToCal - 1,
                    yAxis: 0
                  }
                : ''
            ]
          }
        }
      ]
    };

    const ageTextComponent = plus =>
      showAge && (
        <span className="age-text">({intl.formatMessage({ id: 'age-value' }, { age: age + (plus || 0) })})</span>
      );

    const handleClickEdit = option => {
      if (inputPanelRef && inputPanelRef.current) {
        let instance;
        if (inputPanelRef.current.getWrappedInstance) {
          instance = inputPanelRef.current.getWrappedInstance();
        } else {
          instance = inputPanelRef.current;
        }

        if (instance) {
          instance.setInputGroupOption(option);
          instance.open();
        }
      }
    };

    const handleBeforeSlideChange = () => toggleSwipeableView(false);

    const handleSlideShowAccumulationValueAt = (event, value) =>
      updateAnalysisInputs({ savingShowAccumulationValueAt: value + savingTerms });

    const handleChangeReturnRate = (event, value) => updateAnalysisInputs({ savingReturnRate: value });

    const handleSavingTerms = (event, value) =>
      updateAnalysisInputs({
        savingTerms: value,
        savingShowAccumulationValueAt: value
      });

    const handleChangeRetireAfter = (event, value) => updateAnalysisInputs({ savingRetireAfter: value });

    const handleChangeRetirementTerms = (event, value) => updateAnalysisInputs({ savingRetirementTerms: value });

    return (
      <div
        style={{ paddingTop: 0, marginTop: 0 }}
        className={'analysis-slide analysis-saving' + (isSafariGenerate ? ' safari-gen' : '')}
      >
        <img id="linear-pic" src="img/angular_gradient.png" alt="gradient" style={{ display: 'none' }} />

        {isSafariGenerate && exportInputs.exportInputPanel === true && <SavingInputPanel />}

        <div className="analysis-expansion">
          {!isWide && isAdviserType(userDetails) && !isSafariGenerate && (
            <AladdinHint
              title={intl.formatMessage({ id: 'aladdin-saving-analysis-title' })}
              helpCenter="c35"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          )}

          {!isWide && !isSafariGenerate && (
            <SavingAnnuitySlides toggleSwipeableView={toggleSwipeableView} defaultExpanded={defaultSavingExpanded} />
          )}

          {!isWide && !isSafariGenerate && <SavingPolicyStatistics mySwipeableViews={mySwipeableViews} />}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportScore === true)) && (
            <div className="cardAnalysis analysis-expansion-element analysis-score">
              <MoreInfoTrigger
                titleIntlId="Saving Ratio"
                contentIntlId="moreInfo_savingScore"
                className="analysis-moreinfo"
              />
              <h4 className="text-center greyL6">{intl.formatMessage({ id: 'Saving Ratio' })}</h4>

              <div id="canvas-holder" className="Outer" style={{ height: '175px', width: 300 }}>
                <ReactEcharts option={savingRatioChartOption} />
              </div>
              <br />
            </div>
          )}

          {!isWide && (!isSafariGenerate || (isSafariGenerate && exportInputs.exportTotal === true)) && (
            <SavingSummary />
          )}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportAccumulation === true)) && (
            <div
              style={{ borderLeft: '6px solid #3EDA7D' }}
              className="cardAnalysis analysis-expansion-element analysis-accumulation text-center greyL6 font16"
            >
              <div style={{ marginBottom: '22px' }}>
                <h4>{intl.formatMessage({ id: 'Accumulation' })}</h4>
              </div>
              <EditIconButton
                handleClick={() => {
                  handleClickEdit('Future');
                }}
              />

              <div>
                <div style={{ textAlign: 'start', paddingLeft: '7.5%' }}>
                  {intl.formatMessage({ id: 'Saving Periods' })}:{' '}
                  <span className="figureColor" style={{ fontSize: '20px' }}>
                    {savingTerms}
                  </span>
                  <span className="figureColor" style={{ fontSize: '20px' }}>
                    {intl.formatMessage({ id: 'Yrs' })}
                  </span>
                  {ageTextComponent(savingTerms)}
                </div>
                <div className="analysis-saving-show-at-slider" style={{ marginBottom: '10px' }}>
                  <PurpleSlider
                    min={1}
                    max={SAVING_YEAR_OPTIONS[SAVING_YEAR_OPTIONS.length - 1]}
                    value={savingTerms}
                    onChange={handleBeforeSlideChange}
                    onChangeCommitted={handleSavingTerms}
                    stopPropagation={true}
                  />
                </div>

                <div style={{ textAlign: 'start', paddingLeft: '7.5%' }}>
                  {intl.formatMessage({ id: 'extra-holding-period' })}:{' '}
                  <span className="figureColor" style={{ fontSize: '20px' }}>
                    {showAccumulationValueAt - savingTerms}
                  </span>
                  <span className="figureColor" style={{ fontSize: '20px' }}>
                    {intl.formatMessage({ id: 'Yrs' })}
                  </span>
                  {ageTextComponent(showAccumulationValueAt)}
                </div>
                <div className="analysis-saving-show-at-slider" style={{ marginBottom: '10px' }}>
                  <PurpleSlider
                    min={0}
                    max={60}
                    value={showAccumulationValueAt - savingTerms}
                    onChange={handleBeforeSlideChange}
                    onChangeCommitted={handleSlideShowAccumulationValueAt}
                    stopPropagation={true}
                  />
                </div>

                <div style={{ textAlign: 'start', paddingLeft: '7.5%' }}>
                  {intl.formatMessage({ id: 'with return' })}:{' '}
                  <span className="figureColor" style={{ fontSize: '20px' }}>{`${returnRate}%`}</span>
                  <span className="age-text">
                    ({intl.formatMessage({ id: 'Inflation' })}: {inflationRate}%)
                  </span>
                </div>
                <div className="analysis-saving-show-at-slider">
                  <PurpleSlider
                    min={0}
                    max={RETURN_RATE_OPTIONS[RETURN_RATE_OPTIONS.length - 1]}
                    step={0.1}
                    value={returnRate}
                    onChange={handleBeforeSlideChange}
                    onChangeCommitted={handleChangeReturnRate}
                    stopPropagation={true}
                  />
                </div>

                <br />
                <div className="font24 figureColor">
                  <h4 className="violet-text"> {intl.formatMessage({ id: 'total-principal' })}</h4>
                  {intl.formatMessage({ id: currency }) + ' '}
                  <span className="figure-large">{formatNumber(principalValue, intl)}</span>
                </div>
                <div>
                  <h4 className="violet-text">
                    {intl.formatMessage({ id: 'Its value at' })} {savingTerms}
                    {' + '}
                    {showAccumulationValueAt - savingTerms} {intl.formatMessage({ id: 'Yrs' })}
                    {ageTextComponent(showAccumulationValueAt)}
                  </h4>
                  <div className="font24 figureColor">
                    {intl.formatMessage({ id: currency }) + ' '}
                    <span className="figure-large">{formatNumber(futureValueSecond, intl)}</span>
                  </div>
                  <div
                    className={futureValueSecond - principalValue < 0 ? 'text-red' : 'text-green'}
                    style={{ fontSize: 18, marginTop: '-6px' }}
                  >
                    {futureValueSecond - principalValue < 0
                      ? intl.formatMessage({ id: 'analysis-loss' })
                      : intl.formatMessage({ id: 'Earn' })}
                    {': '}
                    <span
                      className={(futureValueSecond - principalValue < 0 ? 'text-red' : 'text-green', 'figure-large')}
                    >
                      {futureValueSecond - principalValue >= 0
                        ? formatNumber(futureValueSecond - principalValue, intl)
                        : formatNumber(principalValue - futureValueSecond, intl)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="clearfix">
                <div className="analysis-saving-compare-graph Outer" style={{ width: '100%', height: '50%' }}>
                  <div className="analysis-saving-compare-graph">
                    <ReactEcharts option={savingCompareChartOptions} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportRetirementUsage === true)) && (
            <div className="analysis-retirement-usage">
              <div style={{ borderLeft: '6px solid #FFA600' }} className="cardAnalysis analysis-expansion-element">
                <h4 className="text-center greyL6">{intl.formatMessage({ id: 'Retirement' })}</h4>

                <EditIconButton
                  handleClick={() => {
                    handleClickEdit('Retirement');
                  }}
                />

                <div className="text-center font16 greyL6">
                  <div>
                    {intl.formatMessage({ id: 'After' })}{' '}
                    <span className="figureColor" style={{ fontSize: '24px' }}>
                      {retireAfter}
                    </span>
                    <span className="figureColor" style={{ fontSize: '24px' }}>
                      {intl.formatMessage({ id: 'Yrs' })}
                    </span>
                    {ageTextComponent(retireAfter)}
                  </div>
                  <div className="analysis-saving-show-at-slider" style={{ marginBottom: '10px' }}>
                    <PurpleSlider
                      min={0}
                      max={SAVING_YEAR_OPTIONS[SAVING_YEAR_OPTIONS.length - 1]}
                      value={retireAfter}
                      onChange={handleBeforeSlideChange}
                      onChangeCommitted={handleChangeRetireAfter}
                      stopPropagation={true}
                    />
                  </div>
                  <div>{intl.formatMessage({ id: 'you would have' })}</div>
                  <div className="font24 figureColor">
                    {intl.formatMessage({ id: currency }) + ' '}
                    <span className="figure-large">{formatNumber(retirementValueAtRetireAfter, intl)}</span>
                  </div>

                  {intl.formatMessage({ id: 'It can provide monthly income' })}

                  <div>
                    <div className="font24 figureColor">
                      {intl.formatMessage({ id: currency }) + ' '}
                      <span className="figure-large"> {retirementDisplayValue}</span>
                    </div>
                    <div className="analysis-saving-retirement-icon">{<img {...retirementIncomeLevel} />}</div>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    {intl.formatMessage({ id: 'to support your retirement life for' })}:{' '}
                    <span style={{ fontSize: 24 }} className="figureColor">
                      {retirementTerms}
                    </span>
                    <span style={{ fontSize: 24 }} className="figureColor">
                      {intl.formatMessage({ id: 'years' })}
                    </span>
                    {ageTextComponent(retireAfter + retirementTerms)}
                  </div>
                  <div className="analysis-saving-show-at-slider" style={{ marginBottom: '10px' }}>
                    <PurpleSlider
                      min={1}
                      max={SAVING_YEAR_OPTIONS[SAVING_YEAR_OPTIONS.length - 1]}
                      value={retirementTerms}
                      onChange={handleBeforeSlideChange}
                      onChangeCommitted={handleChangeRetirementTerms}
                      stopPropagation={true}
                    />
                  </div>

                  <div className="analysis-saving-compare-graph Outer" style={{ width: '100%', height: '50%' }}>
                    <div className="analysis-saving-compare-graph">
                      <ReactEcharts option={retireChartOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* //hide the function to gen png for share due to not functioning  */}
          {/* {!isSafariGenerate && _.get(userDetails, 'userType') === 'Adviser' && (
            <ExpansionPanel
              defaultExpanded={false}
              className="analysis-expansion exclude-in-image analysis-bottom export-expansion"
              TransitionProps={{
                className: 'export-expansion-collapse',
                timeout: 300
              }}
              onChange={event => {
                event.persist();
                setTimeout(() => {
                  if (mySwipeableViews.current)
                    mySwipeableViews.current.adjustSlideHeights();
                }, 350);
              }}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">
                {intl.formatMessage({ id: 'analysis-export-heading' })}&nbsp;<span className="pro-text">PRO</span>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="analysis-expansion-detail">
                <AnalysisExport
                  onDidUpdate={() =>
                    setTimeout(() => {
                      if (mySwipeableViews.current)
                        mySwipeableViews.current.adjustSlideHeights();
                    }, 300)
                  }
                  shouldRender={() => {
                    if (!Object.entries(exportInputs).find(entry => entry[1] === true)) {
                      toggleDialog('analysisExportOptionsHint', {});
                      return false;
                    }
                    return true;
                  }}
                  intl={intl}
                  history={history}
                  safariGenPath="/analysis/saving/safari-gen"
                  targetSelector=".analysis-saving"
                  afterRender={() => mySwipeableViews.current.adjustSlideHeights()}
                  inputs={
                    <FormControl component="fieldset" className="export-select-form">
                      <FormLabel component="legend">
                        {intl.formatMessage({
                          id: 'Select fields to export'
                        })}
                      </FormLabel>
                      <FormGroup className="export-select-group">
                        {[
                          {
                            state: 'exportInputPanel',
                            labelId: 'input-panel'
                          },
                          { state: 'exportScore', labelId: 'score' },
                          {
                            state: 'exportTotal',
                            labelId: 'Total Monthly Saving'
                          },
                          {
                            state: 'exportAccumulation',
                            labelId: 'Accumulation'
                          },
                          {
                            state: 'exportRetirementUsage',
                            labelId: 'retirement-or-usage'
                          }
                        ].map((obj, index) => {
                          return (
                            <FormControl key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={exportInputs[obj.state] || false}
                                    onChange={(event, checked) => {
                                      let updates = { [obj.state]: checked };
                                      if (checked && android()) {
                                        if (obj.state === 'exportAccumulation') {
                                          updates['exportRetirementUsage'] = false;
                                        } else if (obj.state === 'exportRetirementUsage') {
                                          updates['exportAccumulation'] = false;
                                        }
                                      }
                                      updateAnalysisSavingExport(updates);
                                    }}
                                    value={obj.state}
                                  />
                                }
                                label={intl.formatMessage({
                                  id: obj.labelId
                                })}
                              />
                            </FormControl>
                          );
                        })}
                      </FormGroup>
                    </FormControl>
                  }
                  options={() => {
                    return {
                      height:
                        (exportInputs.exportInputPanel === true ? 325 : 0) +
                        (exportInputs.exportScore === true
                          ? document.querySelector('.analysis-saving .analysis-score').offsetHeight + 14
                          : 0) +
                        (exportInputs.exportTotal === true
                          ? document.querySelector('.analysis-saving .analysis-total').offsetHeight + 14
                          : 0) +
                        (exportInputs.exportAccumulation === true
                          ? document.querySelector('.analysis-saving .analysis-accumulation').offsetHeight + 14
                          : 0) +
                        (exportInputs.exportRetirementUsage === true
                          ? document.querySelector('.analysis-saving .analysis-retirement-usage').offsetHeight + 14 - 52
                          : 0),
                      backgroundColor: '#f5f5f5',
                      onclone: async doc => {
                        try {
                          formatAnalysisDoc(doc, '.analysis-saving', '.saving-input-panel');

                          const selectionPairs = [
                            {
                              state: 'exportInputPanel',
                              selector: '.analysis-input-panel'
                            },
                            {
                              state: 'exportScore',
                              selector: '.analysis-score'
                            },
                            {
                              state: 'exportTotal',
                              selector: '.analysis-total'
                            },
                            {
                              state: 'exportAccumulation',
                              selector: '.analysis-accumulation'
                            },
                            {
                              state: 'exportRetirementUsage',
                              selector: '.analysis-retirement-usage'
                            }
                          ];

                          selectionPairs.forEach(selectionPair => {
                            if (exportInputs[selectionPair.state] === false) {
                              doc
                                .querySelector('.analysis-saving')
                                .querySelector(selectionPair.selector)
                                .remove();
                            }
                          });
                        } catch (e) {
                          return e;
                        }
                      }
                    };
                  }}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )} */}
        </div>

        <div className="height-block-for-fab exclude-in-image" />
      </div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

// SavingView.whyDidYouRender = true;

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,

    monthlyIncome: state.analysis.monthlyIncome,
    age: state.analysis.age,

    showAge: state.analysis.showAge,

    savingCustomTopUp: state.analysis.savingCustomTopUp,
    savingTerms: state.analysis.savingTerms,
    showAccumulationValueAt: state.analysis.savingShowAccumulationValueAt,
    returnRate: state.analysis.savingReturnRate,
    inflationRate: state.analysis.savingInflationRate,
    currentAsset: state.analysis.currentAsset,
    retireAfter: state.analysis.savingRetireAfter,
    retirementTerms: state.analysis.savingRetirementTerms,
    retirementReturnRate: state.analysis.savingRetirementReturnRate,
    retirementInflationRate: state.analysis.savingRetirementInflationRate,

    savingToReachData: state.analysis.savingToReachData,
    savingTotalCoverage: state.analysis.savingTotalCoverage

    // exportInputs: { ...state.analysis.savingExport }
  }),
  // Map actions to props
  {
    updateUserDetails,
    updateAnalysisInputs,
    updateAnalysisSavingExport,
    toggleDialog,
    updateSavingSelectedPolicies
  },
  // mergeProps
  null,
  // options
  {}
)(SavingView);

export default injectIntl(container);
