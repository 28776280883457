import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmRoomModal(props) {
  const { intl, open, onClose, joinRoom, roomID } = props;

  return (
    <div>
      <Dialog open={open} onClose={onClose} keepMounted aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Join Meeting' })}</DialogTitle>
        <DialogContent>
          <div className="caseclose-form">
            <div className="form-title">{intl.formatMessage({ id: 'Join meeting with ID' })}:</div>
            <div className="form-title">{roomID}</div>
            <div className="form-title">{intl.formatMessage({ id: '(Use Safari for IOS or Chrome for other)' })}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={joinRoom} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
