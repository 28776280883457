import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, IconButton, LinearProgress, Typography } from '@material-ui/core';
import _ from 'lodash';
import { formatNumber } from '../../../../../../../utils/formatNumber';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { makeStyles } from '@material-ui/core/styles';
import './TopClientTable.scss';

const COLUMN_STYLES = [
  {
    width: 45
  },
  {
    width: 'calc((100% - 175px) / 7 * 3)'
  },
  {
    width: 'calc((100% - 175px) / 7 * 4)'
  },
  {
    width: 130
  }
];

const RowStyle = {
  height: 40
};

const UserRowStyle = {
  cursor: 'pointer'
};

const NameTypoStyle = {
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

const ActionButtonStyle = {
  width: '100%'
};

const ActionButtonIconStyle = {
  fontSize: '16px'
};

const EntriesRegionStyle = {
  height: 200,
  overflow: 'hidden auto'
};

const useProgressStyles = makeStyles(theme => ({
  root: props => ({
    width: '100%',
    maxWidth: 150,
    margin: 'auto',
    height: 5,
    borderRadius: 15,
    backgroundColor: props.progressBackgroundColor
  }),
  bar: props => ({
    backgroundColor: props.progressColor
  })
}));

const getIndexEmoji = index => {
  switch (index) {
    case 0:
      return '1️⃣';
    case 1:
      return '2️⃣';
    case 2:
      return '3️⃣';
    case 3:
      return '4️⃣';
    case 4:
      return '5️⃣';
    case 5:
      return '6️⃣';
    case 6:
      return '7️⃣';
    case 7:
      return '8️⃣';
    case 8:
      return '9️⃣';
    case 9:
      return '🔟';
    default:
      return null;
  }
};

const getProgress = (value, maxValue) => (!maxValue ? 0 : ((value || 0) / maxValue) * 100);

const TopClientTable = props => {
  const { intl, valueLabel, progressColor, progressBackgroundColor, items, onClickItem } = props;

  const formattedItems = items || [];
  const emptyItemsLength = 10 - formattedItems.length;
  const maxValue = _.get(formattedItems, '[0].value');

  const classes = useProgressStyles({ progressColor, progressBackgroundColor });

  return (
    <Grid container className="top-client-table">
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1} style={RowStyle}>
              <Grid item style={COLUMN_STYLES[0]} />
              <Grid item style={COLUMN_STYLES[1]}>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Client' })}</Typography>
              </Grid>
              <Grid item style={COLUMN_STYLES[2]} />
              <Grid item style={COLUMN_STYLES[3]}>
                <Typography color="textSecondary">{valueLabel}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={EntriesRegionStyle}>
        <Grid container spacing={1}>
          {formattedItems.map((item, index) => {
            return (
              <Grid key={item.userId} item xs={12} onClick={() => onClickItem(item)} style={UserRowStyle}>
                <Grid container alignItems="center" spacing={1} style={RowStyle}>
                  <Grid item style={COLUMN_STYLES[0]}>
                    <Typography align="center">{getIndexEmoji(index)}</Typography>
                  </Grid>
                  <Grid item style={COLUMN_STYLES[1]}>
                    <Typography style={NameTypoStyle}>{item.userName}</Typography>
                  </Grid>
                  <Grid item style={COLUMN_STYLES[2]}>
                    <LinearProgress
                      variant="determinate"
                      value={getProgress(item.value, maxValue)}
                      classes={{
                        root: classes.root,
                        bar: classes.bar
                      }}
                    />
                  </Grid>
                  <Grid item style={COLUMN_STYLES[3]}>
                    <Grid container justify="space-between" spacing={1}>
                      <Grid item>
                        <Typography>{formatNumber(item.value, intl)}</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton color="primary" size="small" style={ActionButtonStyle}>
                          <ArrowForwardIosRoundedIcon style={ActionButtonIconStyle} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {new Array(emptyItemsLength).fill(0).map((_, index) => (
            <Grid key={index} item xs={12}>
              <Grid container alignItems="center" spacing={1} style={RowStyle}>
                <Grid item style={COLUMN_STYLES[0]}>
                  <Typography align="center">{getIndexEmoji(formattedItems.length + index)}</Typography>
                </Grid>
                <Grid item style={COLUMN_STYLES[1]}>
                  <Typography>-</Typography>
                </Grid>
                <Grid item style={COLUMN_STYLES[2]}>
                  <LinearProgress
                    variant="determinate"
                    value={0}
                    classes={{
                      root: classes.root,
                      bar: classes.bar
                    }}
                  />
                </Grid>
                <Grid item style={COLUMN_STYLES[3]}>
                  <Typography>-</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default injectIntl(TopClientTable);
