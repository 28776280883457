import React from 'react';
import { injectIntl } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, DialogContentText } from '@material-ui/core';
import ImageWithTitleCardButton from '../../../components/AdviserHelpDialog/partials/ImageWithTitleCardButton/ImageWithTitleCardButton.js';
import './AdviserShortCuts.scss';
import { ZoomTransition } from '../../../components/Transition/Transition';
import _ from 'lodash';
const AdviserShortCuts = (props) => {
    const { intl, shortCutsOpen, onSetShortCutsOpen, history, adviserShortcutRef } = props;
    const shortcuts = [
        {
            title: 'adviser-shortcut-1-title',
            description: 'adviser-shortcut-1-description',
            imgSrc: '/img/potential_client.png',
            path: '/referral_records'
        },
        {
            title: 'adviser-shortcut-2-title',
            description: 'adviser-shortcut-2-description',
            imgSrc: '/img/hottopic.png',
            path: '/hot_topic'
        },
        {
            title: 'adviser-shortcut-3-title',
            description: 'adviser-shortcut-3-description',
            imgSrc: '/img/add_person.png',
            path: '/client_personal_form'
        }
    ]

    // console.log('onProfilePicClick::: ', onProfilePicClick)
    return (
        <React.Fragment>
            <Dialog
                TransitionComponent={ZoomTransition}
                TransitionProps={{
                    triggerTarget: _.get(adviserShortcutRef, 'current')
                }}
                fullWidth={true}
                maxWidth="xs"
                PaperProps={{
                    style: { margin: 16, padding: '0px 0px 16px 0px', width: 'calc(100% - 32px)' }
                }}
                open={shortCutsOpen}
                onClose={() => onSetShortCutsOpen(false)}>

                <DialogTitle style={{ fontSize: 16, padding: '16px 24px' }} >
                    {intl.formatMessage({ id: 'adviser-shortcut-dialog-title' })}
                    <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={() => onSetShortCutsOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: '8px 24px' }}>
                    <DialogContentText style={{ fontSize: 16 }}>
                        {intl.formatMessage({ id: 'adviser-shortcut-dialog-description' })}
                    </DialogContentText>

                    {shortcuts.map((e, index) => (
                        <ImageWithTitleCardButton
                            key={index}
                            title={intl.formatMessage({ id: e.title })}
                            description={intl.formatMessage({ id: e.description })}
                            imgSrc={e.imgSrc}
                            onClick={() => {
                                history.push(e.path);
                            }}
                        />

                    ))}
                </DialogContent>
            </Dialog>
        </React.Fragment>

    );
};

export default injectIntl(AdviserShortCuts);