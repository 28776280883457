import { Portal } from '@material-ui/core';
import React from 'react';
import './CustomTopNavigation.scss';
import topNavTitlePro from '../topNavTitlePro.json';
import ProBadge from '../SubscriptionComponents/ProBadge/ProBadge';

const InnerContainerStyle = {
  position: 'relative',
  height: '100%'
};

const CustomTopNavigation = props => {
  const { className, children, title, shouldShowProBadge, ...rest } = props;
  return (
    <ul className={`navigation${className ? ` ${className}` : ''}`} {...rest}>
      <div style={InnerContainerStyle}>
        {title && (
          <li className="title">
            <div>
              <span>
                {title}
                {!!shouldShowProBadge && <ProBadge />}
              </span>
            </div>
          </li>
        )}
        {children}
      </div>
    </ul>
  );
};

export const CustomTopNavigationWithPortal = props => (
  <Portal container={document.body}>
    <CustomTopNavigation {...props} />
  </Portal>
);

export default CustomTopNavigation;
