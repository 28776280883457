import React from 'react';
import AspectRatioSkeleton from '../AspectRatioSkeleton/AspectRatioSkeleton';
import { sanitize } from 'dompurify';

export default class AnimatedSvg extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.svgLoading = undefined;
    this.state = { svgHtml: undefined };
  }

  componentDidMount() {
    const { url } = this.props;
    this.svgLoading = new Promise(async (resolve, reject) => {
      try {
        const svg = await (await fetch(url)).text();
        this.setState({ svgHtml: svg });
        resolve(svg);
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  }

  // svgDidLoad = async () => await this.svgLoading;

  render() {
    const { className, url, animated, ...rest } = this.props;
    const { svgHtml } = this.state;
    return animated !== false && svgHtml ? (
      <div
        className={`animated-svg ${className ? className : ''}`}
        ref={this.container}
        dangerouslySetInnerHTML={{
          __html: sanitize(svgHtml)
        }}
        {...rest}
      />
    ) : (
      <AspectRatioSkeleton aspectPercentage={66.7} />
    );
  }
}
