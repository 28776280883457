import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MyEditor from '../../components/MyEditor/MyEditor';
import { SlideTransition } from '../Transition/Transition';
import MyToolbar from '../MyEditor/MyToolbar';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const myToolbarRef = React.useRef(null);
  const myEditorRef = React.useRef(null);

  const {
    onClose,
    open,
    updatePhase,
    loadingPhase,
    headerText,
    intl,
    LOADING,
    extraHTML,
    save,
    className,
    ...rest
  } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth={true}
        fullScreen={fullScreen}
        className={className}
      >
        <DialogTitle id="responsive-dialog-title">{headerText}</DialogTitle>
        <div style={{ overflow: 'hidden', padding: '0 24px', flex: '0 0 auto' }}>
          <MyToolbar {...rest} ref={myToolbarRef} editorRef={myEditorRef} undo={true} />
        </div>
        <DialogContent className="responsive-dialog-box">
          {(updatePhase === LOADING || loadingPhase === LOADING) && <LoadingBackdrop open={true} />}
          <MyEditor {...rest} ref={myEditorRef} toolbarRef={myToolbarRef} />
        </DialogContent>
        <div style={{ overflow: 'hidden', padding: '0 24px', flex: '0 0 auto' }}>{extraHTML}</div>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={save} color="primary">
            {intl.formatMessage({ id: 'Save' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
