import * as api from './api';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';

/***********************************
 * Action Types
 ***********/
export const UPDATE_MEDICAL_CARDS = 'UPDATE_MEDICAL_CARDS';
export const UPDATE_GET_MEDICAL_CARDS_PHASE = 'UPDATE_GET_MEDICAL_CARDS_PHASE';

/***********************************
 * Initial State
 ***********/
const initialState = {
	medicalCards: [],
	getMedicalCardsPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
	switch (action.type) {
		case UPDATE_MEDICAL_CARDS:
			const { medicalCards } = action.payload;
			return {
				...state,
				medicalCards,
				getMedicalCardsPhase: SUCCESS
			};
		case UPDATE_GET_MEDICAL_CARDS_PHASE:
			const { phase } = action.payload;
			return {
				...state,
				getMedicalCardsPhase: phase
			}
		default:
			return state;
	}
}

/***********************************
 * Action Creators
 ***********/
export const getMedicalCards = () => async (dispatch, getState) => {
	dispatch({ type: UPDATE_GET_MEDICAL_CARDS_PHASE, payload: { phase: LOADING } });
	try {
		const json = await api.getMedicalCardsApi();
		dispatch({ type: UPDATE_MEDICAL_CARDS, payload: { medicalCards: json.data } });
	} catch (error) {
		console.error(error);
		dispatch({ type: UPDATE_GET_MEDICAL_CARDS_PHASE, payload: { phase: ERROR } });
	}
};