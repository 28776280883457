import { fetch } from '../../utils';
import Backend from '../../backend';

//TO GET THE GETCONNEDTEDUSERBIRHDAY , GETCONNECTEDUSER , AVERAGECOVERAGEDATA , AVERAGEPREMIUMDATA  (BOTH CONNECTED AND INAVTIVE)
export const getAdviserDashboard = () => {
  return fetch(Backend.methodUrl('getAdviserDashboard'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET CONNECTED USER POLICY
export const connectedUserPolicies = () => {
  return fetch(Backend.methodUrl('connectedUserPolicies'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET CONNECTED AND INACTIVE SUERS POLICY COUNT OF LIFE , MED , CI AND SAVING
export const policyCount = () => {
  return fetch(Backend.methodUrl('policyCount'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET COUNT OF DUE OF POLICY OF CONNECTED USER AND INACTIVE USER
export const getDuePolicy = () => {
  return fetch(Backend.methodUrl('getDuePolicy'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET PAID UP POLICIES OF CONNECTED USER AND INACTIVE USER
export const getPaidUpPolicy = () => {
  return fetch(Backend.methodUrl('paidUpPolicies'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET THE ADVISER BUSINESS ANALYSIS DATA
export const adviserBusinessAnalysis = () => {
  return fetch(Backend.methodUrl('adviserBusinessAnalysis'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET THE FIGURE OF ADVISER FROM FIGUREADVISER TABLE
export const fetchFigureAdviser = () => {
  return fetch(Backend.methodUrl('fetchFigureAdviser'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO UPDATE THE FIGURE OF ADVISER
export const updateFigureAdviser = data => {
  return fetch(Backend.methodUrl('updateFigureAdviser'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO UPDATE THE QUARTER TARGET OF ADVISER
export const updateAdviserBusiness = data => {
  return fetch(Backend.methodUrl('updateAdviserBusiness'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET THE ADVISER BUSINESS
export const fetchAdviserBusiness = () => {
  return fetch(Backend.methodUrl('getAdviserBusiness'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO GET THE ADVISER TEAM DETAIL
export const fetchAdviserTeamDetail = (teamId = '') => {
  let url = teamId !== '' ? `getAdviserTeamDetail?teamId=${teamId}` : 'getAdviserTeamDetail';
  return fetch(Backend.methodUrl(url), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//TO UPDATE THE TEAMS DETAIL OF ADVISER
export const updateTeamsOfAdviser = data => {
  return fetch(Backend.methodUrl('updateTeamsOfAdviser'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
