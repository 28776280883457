import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import { tracking } from '../../../../utils/tracking';
import { toggleDialog } from '../../../../store/control/duck';

const ButtonStyle = {
  padding: 3,
  lineHeight: 1,
  minWidth: 71,
  textTransform: 'unset'
};

const IconStyle = {
  width: 24,
  margin: '4px 0'
};

const FinancialConceptButton = props => {
  const { intl, style, defaultItem, toggleDialog } = props;

  const openPurchasePowerDialog = () => {
    tracking('Click on Financial Concept - Fin Pyramid');
    toggleDialog('financialCharts', { defaultItem });
  };

  return (
    <Fragment>
      <Button color="primary" onClick={openPurchasePowerDialog} style={{ ...ButtonStyle, ...style }}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <img src="img/finConcept.png" alt="financial-charts" style={IconStyle} />
          </Grid>
          <Grid item>
            <Typography align="center" color="textSecondary">
              {intl.formatMessage({ id: 'fin-py-fin-charts' })}
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </Fragment>
  );
};

const container = connect(state => ({}), {
  toggleDialog
})(FinancialConceptButton);

export default injectIntl(container);
