import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

const RecordDialog = props => {
  const { intl, recording, initializing, open, onClose, onStartRecording, onDoneRecording, style, ...rest } = props;

  return (
    <Dialog open={open} style={{ ...style }} {...rest}>
      <DialogTitle disableTypography={true}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <RecordVoiceOverIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography color="primary">
              {recording
                ? initializing
                  ? intl.formatMessage({ id: 'stop-recording-dialog-title-1' })
                  : intl.formatMessage({ id: 'stop-recording-dialog-title-2' })
                : intl.formatMessage({ id: 'stop-recording-dialog-title-3' })}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>
          {intl.formatMessage({ id: 'stop-recording-dialog-content' })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        {recording ? (
          <Button
            color="primary"
            variant="contained"
            disableElevation={true}
            disabled={initializing}
            onClick={onDoneRecording}
          >
            {intl.formatMessage({ id: 'stop-recording-dialog-done' })}
          </Button>
        ) : (
          <Button color="primary" variant="contained" disableElevation={true} onClick={onStartRecording}>
            {intl.formatMessage({ id: 'stop-recording-dialog-start' })}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(RecordDialog);
