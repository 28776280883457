//API TO GENERATE PDF
import { fetch } from '../../utils';
import Backend from '../../backend';

export const getPDF = async (type, data) =>
  fetch(Backend.pdfApi(`/generate/${type}`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      // Extract MIME type from the response, or set it based on other logic
      let mimeType = res.headers.get('Content-Type');
      if (mimeType.includes("application/pdf")){
        mimeType = "application/pdf";
      } else if (mimeType.includes("image/png")){
        mimeType = "image/png";
      } else{
        mimeType = "application/octet-stream";
      }
      return res.text().then(base64String => ({ base64String, mimeType }));
    })
    .then(({ base64String, mimeType }) => {
      let binaryStr = atob(base64String);
      let length = binaryStr.length;
      let bytes = new Uint8Array(length);

      for (let i = 0; i < length; i++) {
        bytes[i] = binaryStr.charCodeAt(i);
      }

      return new Blob([bytes], { type: mimeType });
    })
    .catch(error => {
      throw error;
    });