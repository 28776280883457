import moment from 'moment';
import { decimalToString } from './formatNumber';
import { isClientDisconnected } from '../components/ClientEntry/utils';
import { LANGUAGE } from '../constants/language';

export const getBriefPrompt = () => `提供更簡潔的答案`; //later have to have prompt according to Lauguage. condense the answer

export const getMorePrompt = () => `請再提供兩個例子`; //give me two more example

// prettier-ignore
export const getFunPrompt = ({ text }) =>
  `Add emoji to make the message to client more fun, output using the same language as the original message and only output the message without any other explanation """${text}"""`;

// Ice Breaking
// prettier-ignore
export const getIceBreakingPrompt = ({ client }) => {
  const {
    factsheetId,
    lifeCoverage,
    ciCoverage,
    medicalPolicyCounts,
    totalPremiumSaving,
    totalWithILAS,
    savingPolicyCount,
    ilasPolicyCount
  } = client || {};

  const isDisconnected = isClientDisconnected(client);

  const factsheetFields = {
    importance: {
      label: 'Normal / VIP / V.VIP'
    },
    tags: {
      label: 'Tags',
      format: value => (value || []).map(tag => tag.text).join(' ')
    },
    latestMeetingDate: {
      label: 'Latest Meeting',
      format: value => (value ? moment(value).format('DD-MMM-YYYY') : '')
    },
    personality: {
      label: 'DISC Personality'
    },
    dob: {
      label: 'Date of Birth',
      format: value => (value ? moment(value).format('DD-MMM-YYYY') : '')
    },
    age: {
      label: 'Age'
    },
    gender: {
      label: 'Gender'
    },
    smoker: {
      label: 'Is Smoker'
    },
    birthPlace: {
      label: 'Birth Place'
    },
    occupation: {
      label: 'Occupation'
    },
    marriageStatus: {
      label: 'Marriage Status'
    },
    monthlyIncome: {
      label: 'Monthly Income in HKD',
      format: value => (value || value === 0 ? decimalToString(value) : '')
    },
    monthlyExpense: {
      label: 'Monthly Expense in HKD',
      format: value => (value || value === 0 ? decimalToString(value) : '')
    },
    remark: {
      label: 'Remark'
    }
  };

  const factsheetText = Object.entries(factsheetFields)
    .map(entry => {
      const fieldKey = entry[0];
      const field = entry[1];
      const fieldValue = factsheetId?.[fieldKey] ?? '';
      const formattedValue = field.format ? field.format(fieldValue) : fieldValue;
      return formattedValue ? `${field.label}: ${formattedValue}` : '';
    })
    .filter(str => !!str)
    .join('\n');

  let policyText = '';

  if (!isDisconnected) {
    policyText += `Life coverage: HKD ${decimalToString(lifeCoverage)}\n`;
    policyText += `Critical coverage: HKD ${decimalToString(ciCoverage)}\n`;
    policyText += `${medicalPolicyCounts?.highend || medicalPolicyCounts?.others ? 'Have' : 'Do not have'
      } Medical coverage\n`;
    policyText += `${savingPolicyCount ? 'Have' : 'Do not have'
      } saving insurance (paying monthly premium HKD ${decimalToString(totalPremiumSaving)})\n`;
    policyText += `${ilasPolicyCount ? 'Have' : 'Do not have'
      } investment insurance (paying monthly premium HKD ${decimalToString(totalWithILAS)})`;
  }

  return `Act as an expert in interpersonal skills and communicate very well based on one's DISC personality type, suggest some interesting findings from the client's profile. the result should be able to help me to chat with him in a more fun way. give me 3 answers in points form, each begin with a relevant emoji, answer all in traditional Chinese
  
  Output requirement: Use the "DISC personality" data to come up with a point. if have Date of Birth, mention the constellation and include that constellation's feature as one of the points. If has child information, mention that as one of the points. For the script, be casual and don't mention directly the client's personality.
  
  format of each point:
  
  [emoji] [interesting findings]
  🗣️"[一個與客戶交談的例子]"
  💬"[the same script in Cantonese in words]"
  🔍: [explain why to use this script]
  
  At the end, based on the DISC personality, give an overall comment about points to note when talking to the client and suggest the style of material to prepare, eg more figures or more narrative material, if going to sell an insurance plan.
  
  Profile: """ ${factsheetText}${policyText ? `\n${policyText}` : ''} """`;
};

// Match Lazy Packs
// prettier-ignore
export const getMatchLazyPacksPrompt = ({ client, lifeStage, chatPurpose, adviserLanguage, clientLanguage }) => {
  return `You are an experienced sales coach in the insurance industry who is very good at using the methods in the famous book "How to Win Friends and Influence People".
    You need to teach sales how to communicate and connect with customers, including sending appropriate hot-topic to customers.
    
    There is a customer whose information is as follows: 
    Name: ${client.name} 
    Life Stage: ${lifeStage}
    ${client.factsheetId.age ? `Age: ${client.factsheetId.age},` : ''}
    ${client.factsheetId.tags?.length >= 1 ? `Tags: ${client.factsheetId.tags.map(tag => tag.text)},` : ''}
    ${client.factsheetId.personality ? `Personality (DISC): ${client.factsheetId.personality},` : ''}
    ${client.factsheetId.gender ? `Gender: ${client.factsheetId.gender},` : ''}
    ${client.factsheetId.marriageStatus ? `Marriage Status: ${client.factsheetId.marriageStatus},` : ''}
    ${client.factsheetId.child ? `Number of Child: ${client.factsheetId.child},` : ''}
    ${client.factsheetId.monthlyIncome ? `Monthly Income: ${client.factsheetId.monthlyIncome},` : ''}

    Here are the descriptions for each Life Stage：
    1."Career Start"：This stage marks the beginning of one’s professional journey, typically involving securing the first job, exploring various career options, and starting to establish a professional identity.
    2."Career & Family Growth"：This stage involves advancing in one’s career while also establishing and nurturing a family. It requires balancing professional achievements with family responsibilities.
    3."Family with Children":During this stage, individuals continue to progress in their careers while also raising and educating their children. It involves managing the dual responsibilities of family and career effectively.
    4."Pre-Retirement":The focus of this stage is on planning and preparing for retirement. This includes ensuring financial security, making lifestyle adjustments, and transitioning away from full-time employment.
    5."Retired":The retired stage is a period of life post-career where individuals fully disengage from their professional roles, enjoy leisure, pursue personal interests, and possibly engage in volunteer activities.

    The purpose of the communication with the client is： {"Chat Purpose": ${chatPurpose}}.
    Now you need to filter out the top three suitable hot-topic(懶人包) from the attached file.
    
    Title: The title of the chosen hot topic (exact same as the one in the file)
    Reason: Explain within 50 words in ${adviserLanguage} why this topic is particularly relevant
    Personalized Message: Craft a concise message in ${clientLanguage} (around 100 words) to recommend the selected hot-topic
    
    Return in JSON format: [{id: ..., title: ..., reason: ..., personalized_message: ...}, ...]`;
};

// prettier-ignore
const clientPrompBuilder = (client) => {
  // console.log('getMessageRewritePromp client: ', client)
  if (client) {
    var clientPromp = "There is a customer whose information is as follows::: "
    if (client && client.name) {
      clientPromp += `Name: ${client.name},`
    }
    if (client && client.factsheetId.age) {
      clientPromp += "Age: ${client.factsheetId.age},"
    }
    if (client && client.factsheetId.tags?.length >= 1) {
      clientPromp += `Tags: ${client.factsheetId.tags.map(tag => tag.text)},`
    }
    if (client && client.factsheetId.personality) {
      clientPromp += `Personality (DISC): ${client.factsheetId.personality},`
    }
    if (client && client.factsheetId.gender) {
      clientPromp += `Gender: ${client.factsheetId.gender},`
    }
    if (client && client.factsheetId.marriageStatus) {
      clientPromp += `Marriage Status: ${client.factsheetId.marriageStatus},`
    }
    if (client && client.factsheetId.child) {
      clientPromp += `Number of Child: ${client.factsheetId.child},`
    }
    if (client && client.factsheetId.monthlyIncome) {
      clientPromp += `Monthly Income: ${client.factsheetId.monthlyIncome},`
    }
    return clientPromp
  }
  else return ""

}

// Message Rewrite
// prettier-ignore
export const getMessageRewritePromp = (props) => {
  const clientPromp = clientPrompBuilder(props.client)

  return clientPromp + `Act as experienced insurance representative, rewrite the following message.
      message: """${props.message}"""
    Now, please craft 3 TEXT MESSAGE in dialet: ${props.dialet}, in tone ${props.tone}. Please keep the message concise and engaging, output text count should similar to the original message,
      make sure ${clientPromp ? 'suitable for the client\'s personality,' : ''} not to escape from the subject of origianl message.

    Return in MINIMUM VALID JSON ARRAY: ["message", "message", "message"]`;
};

// IG Post
// prettier-ignore
export const getIGPostPrompt = ({ text }) => {
  return `Create captions for an financial concept related Instagram post related to following text that will engage the target audience of youngsters, use funny tone with rhyme.Include relevant hashtags, quotes, and other elements to make the captions stand out.start with relevant emoji.Give 2 captions in English, 提供另外兩個繁體中文例子, 提供另外兩個Cantonese例子 text: """ ${text} """`;
};

// Meeting Note Speech Extraction
// prettier-ignore
export const getMeetingNoteSpeechExtractionPrompt = ({ speechText, language }) => {
  if ([LANGUAGE.ZH_HANT_HK, LANGUAGE.ZH].includes(language)) {
    return `
  You are an experienced insurance agent in Hong Kong. 
  Perform the following action in their respective order:
  1. Correct typos error(for English words) and homophones in the Meeting Record to make logical and without mistakes according to the context.
  2. Read the meeting record delimited by triple quotation carefully
  3. Understand the meaning of different items in the meeting notes by referring to the Notes Items Clarifier
  4. Convert the meeting record into meeting notes using the desired format using chinese.If there is insufficient information to conclude for an item, output "-".Use no bullet point unless specified
  5. If there are more than 4 valid meeting notes(output is not "-"), provide a brief(less than 20 words) constructive  suggestion for the insurance agent to better follow up and maintain this client.If there are 4 or fewer valid meeting notes, then output "-"..

  Meeting Record:
    """
  ${speechText}
    """
  
  # Notes Items Clarifier
  Meeting Date & Venue: Where and when of the meeting  
  Client Profile: Age, Occupation & Experience, Graduation School
  Family Status: Single or Married, other family members
  Financial Situation: Income, Expense of the client
    Themes & Goals for next meeting: Insurance products and domain that should focus and promote in the next meeting
  Important Information: Any information important in fostering a purchase
  
  # Use the following format:
  會議日期及地點：-
  客戶姓名：-
  客戶資料：-
  習慣/興趣：-
  家庭狀況：-
  財務狀況：-
  可動用資金: -
  購買的保險產品：-
  對保險的態度：(正面/中立/負面/有興趣/不確定)
  下次會議的主題和目標：
  • ...
  • ...
  重要資訊：
  • ...
  • ...
  🤖️ AI建議：-`;
  } else {
    return `
  Perform the following action in their respective order:
  1. Read the meeting record delimited by triple quotation carefully
  2. Understand the meaning of different items in the meeting notes by referring to the Notes Items Clarifier
  3. Convert the meeting record into meeting notes using the desired format.If there is insufficient information to conclude for an item, output "-".Use no bullet point unless specified
  4. If there are more than 4 valid meeting notes(output is not "-"), provide a brief(less than 10 words) constructive suggestion for the insurance agent to better follow up and maintain this client.If there are 4 or fewer valid meeting notes, then output "-".
  
  Meeting Record:
""" ${speechText} """
  
  # Notes Items Clarifier
  Meeting Date & Venue: Where and when of the meeting  
  Client Profile: Age, Occupation & Experience, Graduation School
  Family Status: Single or Married, other family members
  Financial Situation: Income, Expense of the client
  Themes & Goals for next meeting: Insurance products and domain that should focus and promote in the next meeting
  Important Information: Any information important in fostering a purchase
  
  # Use the following format:
  Meeting Date & Venue: -
  Client Name: -
  Client Profile: -
  Habits / Interest: -
  Family Status: -
  Financial Situation: -
  Budget for Insurance Product: -
  Purchased Insurance Product: -
  Attitude to Insurance: (Positive / Neutral / Negative / Interested / Not Sure)
  Themes & Goals for next meeting: 
  • ...
  • ... 
  Important Information: 
  • ...
  • ... 
  🤖️ AI Suggest: - `;
  }
};

// Personal Message
// prettier-ignore
export const getPersonalMessagePrompt = ({ clientName, insuranceType }) => {
  return `write very short interesting poems in rhyme of the following topic, for each example, include relevant emoji and 3 hashtags,
  one of hashtags use words from the client name """${clientName}"""
  
  give 2 examples in English, then
  另外提供兩個與前 English例子不同的繁體中文例子

topic: """ [Please consider ${insuranceType} insurance] """`;
};

// Feedback Message
// prettier-ignore
export const getAiFeedbackMessage = ({
  contentRate,
  usabilityRate,
  timeSavingRate,
  overallRate,
  suggestions,
  extraContent
}) => `我對生成的內容評分：（1代表最差，5代表最好）
  
  🤩 內容驚喜程度：${contentRate}
  👍 有用 / 幫到手：${usabilityRate}
  🕑 可節省時間：${timeSavingRate}
  🌟 總體評價：${overallRate}
  📝 改進ChatGPT功能 建議：
  ${suggestions}

以下是生成的內容可幫助我們改進，如果覺得不合適，可以刪除它
  
  ${extraContent} `;
