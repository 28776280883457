import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';
import { TRACKING_COLORS } from '../../ClientEntry';
import { injectIntl } from 'react-intl';
import './OpportunityControl.scss';

class OpportunityControl extends React.PureComponent {
  render() {
    const { intl, opportunity, onChange } = this.props;

    return (
      <Fragment>
        {['L', 'M', 'H'].map(opp => (
          <Button
            key={opp}
            className="opportunity-button"
            style={{
              background: opportunity === opp ? TRACKING_COLORS[opp] : '#cccccc',
              color: opportunity === opp ? '#ffffff' : '#000'
            }}
            variant="contained"
            onClick={() => onChange(opportunity === opp ? null : opp)}
          >
            {intl.formatMessage({ id: `opp${opp}` })}
          </Button>
        ))}
      </Fragment>
    );
  }
}

export default injectIntl(OpportunityControl);
