import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { translateMessage } from '../../../../../utils/locale';
import ShareToClientMessageDialog from '../../../Client/partials/ShareToClientMessageDialog/ShareToClientMessageDialog';
import { getReferralLink } from '../../../../../utils/referral';

class COVIDShareReferralDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showReferralLink: true,
    };
  }

  render() {
    const { intl, dialog, onClose, userDetails } = this.props;
    const { showReferralLink } = this.state;

    if (!dialog) return null;

    return (
      <ShareToClientMessageDialog
        open={!!dialog}
        onClose={onClose}
        title={intl.formatMessage({ id: 'covid-home-monitor' })}
        content={
          <Fragment>
            <Typography color="textSecondary">{intl.formatMessage({ id: 'covid-hm-dialog-content' })}</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showReferralLink}
                  onChange={() => this.setState({ showReferralLink: !showReferralLink })}
                  name="showReferralLink"
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: 'my-ref-link' })}
            />
          </Fragment>
        }
        getMessage={(language) => {
          let message = translateMessage('covid-hm-message-1', language, { name: encodeURI(userDetails.name) });

          if (showReferralLink) {
            message += '\n\n';
            message += translateMessage('covid-hm-message-2', language, {
              link: getReferralLink({ targetAdviser: userDetails, userDetails: userDetails }),
            });
          }

          return message;
        }}
      />
    );
  }
}

const container = connect(
  // Map state to props
  (state) => ({
    userDetails: state.user.userDetails,
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {},
)(COVIDShareReferralDialog);

export default injectIntl(container);
