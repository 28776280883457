import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import StyledTextField from '../../StyledTextField/StyledTextField';
import './QuickNotesRawDialog.scss';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import { scrollAndFocusTextareaEnd } from '../../../utils/dom';
import RecordMeetingRemarkButton from '../../RecordMeetingRemarkButton/RecordMeetingRemarkButton';
import { getRemarkDateLine } from '../../../utils/client';
import { shouldEnableSpeechToText } from '../../../utils/user';

const QuickNotesRawDialog = props => {
  const { intl, userDetails, teamCampaignCodes, remark, open, onClose, onExited, onSubmit, ...rest } = props;
  const [internalRemark, setInternalRemark] = useState(remark);
  const shouldFullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const inputRef = useRef(null);

  const enableSpeechToText = shouldEnableSpeechToText({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

  const onChange = event => setInternalRemark(event.target.value);

  const appendRemark = text => {
    setInternalRemark(
      `${internalRemark}${internalRemark ? `\n${!(internalRemark || '').endsWith('\n') ? '\n' : ''}` : ''}${text}`
    );
  };

  const submit = () => {
    if (onSubmit) {
      onSubmit(internalRemark);
    }
    onClose();
  };

  const addDate = () => {
    appendRemark(getRemarkDateLine());

    setTimeout(() => {
      if (inputRef.current) {
        const textareaElem = inputRef.current.querySelector('textarea');
        scrollAndFocusTextareaEnd(textareaElem);
      }
    }, 150);
  };

  useEffect(() => {
    setInternalRemark(remark);

    return () => {
      setInternalRemark('');
    };
  }, [remark]);

  return (
    <Dialog
      data-body-scroll-lock-ignore="true"
      className="quick-notes-dialog"
      open={open}
      onClose={onClose}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
      fullScreen={shouldFullScreen}
      PaperProps={
        !shouldFullScreen
          ? {
              style: {
                height: '80%'
              }
            }
          : undefined
      }
      {...rest}
    >
      <DialogTitle disableTypography={true}>
        <Grid container spacing={1} justify="space-between">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography color="primary">{intl.formatMessage({ id: 'Quick Notes' })}</Typography>
              </Grid>
              <Grid item>
                <IconButton size="small">
                  <AlarmAddIcon color="primary" style={{ fontSize: 20 }} onClick={addDate} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {enableSpeechToText && (
            <Grid item>
              <RecordMeetingRemarkButton
                ButtonProps={{
                  color: 'primary',
                  size: 'small'
                }}
                IconProps={{
                  style: { fontSize: 20 }
                }}
                onResult={result => appendRemark(`${getRemarkDateLine()}${result}`)}
              />
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <StyledTextField
          value={internalRemark}
          onChange={onChange}
          multiline={true}
          InputProps={{ style: { height: '100%' } }}
          style={{ height: '100%' }}
          ref={inputRef}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={submit} color="primary" autoFocus>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {}
)(QuickNotesRawDialog);

export default injectIntl(container);
