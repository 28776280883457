import React, { useState, useRef, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import MyWhiteboard from '../MyWhiteboard';
import _ from 'lodash';
import './MyWhiteboardContainer.scss';
import moment from 'moment-timezone';
import { usePrevious } from '../../../utils/hooks';
import palette from '../../../views/App/palette.scss';
import { Button, Portal } from '@material-ui/core';
import EjectIcon from '@material-ui/icons/Eject';

const TriggerButtonStyle = {
  minWidth: 'unset',
  color: palette['primaryMain'],
  border: `2px solid ${palette['primaryMain']}`
};

const TriggerIconStyle = {
  fontSize: '3rem'
};

const getImgSrc = locale => {
  if (locale === 'en') {
    return '/img/financial-pyramid/triangle_frame_eng.png';
  } else if (locale === 'zh') {
    return '/img/financial-pyramid/triangle_frame_simplified.png';
  } else {
    return '/img/financial-pyramid/triangle_frame.png';
  }
};

const getHeight = () => {
  return (window.innerHeight - 180) * 2;
};

const MyWhiteboardContainer = props => {
  const { intl, className, onLoaded, clientName, shouldDrawFrame } = props;

  const containerRef = useRef(null);
  const listenerRef = useRef(null);

  const [height, setHeight] = useState(getHeight());
  const [loaded, setLoaded] = useState(false);
  const previousLoaded = usePrevious(loaded);

  const drawFrame = useCallback(() => {
    // console.log('drawFrame');
    if (loaded) {
      setTimeout(() => {
        const image = new Image(60, 45); // Using optional size for image
        image.onload = drawImageActualSize;

        const src = getImgSrc(intl.locale);
        image.src = src;

        function drawImageActualSize() {
          MyWhiteboard.whiteboard.clearBackground();
          const containerHeight = _.get(containerRef, 'current.offsetHeight', height) / 2;
          const containerWidth = _.get(containerRef, 'current.offsetWidth', document.body.offsetWidth);

          const pHeight = containerHeight - 10;
          const pWidth = (this.naturalWidth * pHeight) / this.naturalHeight;
          MyWhiteboard.whiteboard.preDrawUrl(src, pWidth, pHeight, (containerWidth - pWidth) / 2, 10, 0, '2');
        }
      }, 550);
    }
  }, [height, intl.locale, loaded]);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    listenerRef.current = () => {
      setHeight(getHeight());
    };
    window.addEventListener('orientationchange', listenerRef.current);

    return () => {
      if (MyWhiteboard && MyWhiteboard.whiteboard) {
        MyWhiteboard.whiteboard.clearWhiteboard();
      }
      window.removeEventListener('orientationchange', listenerRef.current);
    };
  }, []);

  useEffect(() => {
    if (loaded && !previousLoaded) {
      if (shouldDrawFrame) {
        drawFrame();
      }

      if (onLoaded) {
        onLoaded();
      }
    }
  }, [loaded, drawFrame, onLoaded, previousLoaded, shouldDrawFrame]);

  const filename = `Whiteboard${clientName ? `_${clientName}` : ''}_${moment().format('DDMMMYYYY')}`;

  return (
    <div
      className={`whiteboard-container${className ? ` ${className}` : ''}`}
      style={{ height: height }}
      ref={containerRef}
    >
      <MyWhiteboard onLoad={onLoad} filename={filename} customJSONExtension="pfp" />

      {loaded && (
        <Portal container={() => document.querySelector('.whiteboard #toolbar .btn-group')}>
          <Button variant="outlined" color="primary" onClick={drawFrame} style={TriggerButtonStyle}>
            <EjectIcon style={TriggerIconStyle} />
          </Button>
        </Portal>
      )}
    </div>
  );
};

export default injectIntl(MyWhiteboardContainer);
