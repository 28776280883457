import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel
} from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import { changeInactiveUserEmail, getConsent } from '../../../store/client/duck';
import { getClientEmail, getClientName } from '../utils';
import _ from 'lodash';
import { sanitize } from 'dompurify';

const INITIAL_STATE = { confirmed: false, error: undefined, extras: undefined, loading: false };

class ConsentConfirmDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { consentConfirmDialog, getConsentPhase, toggleDialog, clientMessage, clientMessageExtras } = nextProps;
    if (consentConfirmDialog && getConsentPhase !== this.props.getConsentPhase) {
      this.setState({ loading: false }, () => {
        if (getConsentPhase === true) {
          this.close();
          toggleDialog('consent', false);
          toggleDialog('shareConfirm', { client: _.get(consentConfirmDialog, 'client'), mode: 'consent' });
        } else if (getConsentPhase === false) {
          this.setState({ error: clientMessage, extras: clientMessageExtras });
        }
      });
    }
  }

  submitConsent = () => {
    const { consentConfirmDialog, changeInactiveUserEmail, getConsent } = this.props;
    const { confirmed } = this.state;

    if (confirmed) {
      this.setState({ loading: true });
    }

    const editingEmail = consentConfirmDialog.editingEmail,
      client = consentConfirmDialog.client;

    const formData = {};
    formData.clientEmail = editingEmail.toLowerCase();
    formData.clientName = getClientName(client);
    formData.clientId = client._id;
    formData.language = client.language;

    const clientEmail = getClientEmail(client);
    if (editingEmail.toLowerCase() !== clientEmail) {
      let formData = {};
      formData.email = editingEmail.toLowerCase();
      formData.clientId = client._id;
      changeInactiveUserEmail(formData);
    }
    getConsent(formData);
  };

  close = () => {
    const { loading } = this.state;
    if (!loading) this.props.toggleDialog('consentConfirm', { ...this.props.consentConfirmDialog, closed: true });
  };
  closed = () => {
    this.props.toggleDialog('consentConfirm', false);
    this.setState(INITIAL_STATE);
  };

  render() {
    const { intl, consentConfirmDialog } = this.props;
    const { confirmed, error, extras, loading } = this.state;

    const { client, closed } = consentConfirmDialog;
    const clientName = getClientName(client);

    if (!client) return null;

    return (
      <Dialog
        open={!!consentConfirmDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'Confirm' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'DOUBLE CHECK the email address' })}</DialogContentText>
          <div className="double-check-div">
            <div>
              <b>{consentConfirmDialog.editingEmail}</b>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name="confirmed"
                  value="confirmed"
                  checked={confirmed}
                  onChange={() => this.setState({ confirmed: !confirmed, error: undefined, extras: undefined })}
                  color="primary"
                />
              }
              label={intl.formatMessage({ id: 'Correct' })}
              className="double-check-label"
            />
          </div>
          {error && (
            <div
              className="error_field"
              dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: error }, extras)) }}
            />
          )}
          {intl.formatMessage({
            id: 'Confirm to send consent request to'
          })}{' '}
          <b>{clientName}</b>?<br />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary" disabled={loading}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button
            onClick={confirmed ? this.submitConsent : () => this.setState({ error: 'Please confirm' })}
            color="primary"
            disabled={loading}
            autoFocus
          >
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    consentConfirmDialog: state.control.consentConfirmDialog,
    getConsentPhase: state.client.getConsentPhase,
    clientMessage: state.client.clientMessage,
    clientMessageExtras: state.client.clientMessageExtras
  }),
  // Map actions to props
  {
    toggleDialog,
    changeInactiveUserEmail,
    getConsent
  },
  // mergeProps
  null,
  // options
  {}
)(ConsentConfirmDialog);

export default injectIntl(container);
