import React from 'react';
import { Button } from '@material-ui/core';

const Style = {
  padding: 4,
  borderRadius: 16,
  lineHeight: 1
};

const ActionButton = props => {
  const { style, children, ...rest } = props;
  return (
    <Button color="primary" variant="outlined" style={{ ...Style, ...style }} {...rest}>
      {children}
    </Button>
  );
};

export default ActionButton;
