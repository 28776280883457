import * as api from '../policy/api';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import { produce } from 'immer';
import Rx from 'rxjs';
import { combineEpics } from 'redux-observable';

/***********************************
 * Action Types
 ***********/
export const UPDATE_POLICY_PICKER_POLICIES = 'UPDATE_POLICY_PICKER_POLICIES';
export const UPDATE_POLICY_PICKER_POLICIES_SUCCESS = 'UPDATE_POLICY_PICKER_POLICIES_SUCCESS';
export const UPDATE_POLICY_PICKER_POLICIES_ERROR = 'UPDATE_POLICY_PICKER_POLICIES_ERROR';
export const RESET_POLICY_PICKER = 'RESET_POLICY_PICKER';

/***********************************
 * Initial State
 ***********/
const initialState = {
  policies: [],
  policiesOwner: undefined,
  error: undefined,
  getPoliciesPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_POLICY_PICKER_POLICIES:
        draft.getPoliciesPhase = LOADING;
        draft.error = undefined;
        break;
      case UPDATE_POLICY_PICKER_POLICIES_SUCCESS:
        draft.getPoliciesPhase = SUCCESS;
        draft.policies = action.policies;
        draft.policiesOwner = action.policiesOwner;
        break;
      case UPDATE_POLICY_PICKER_POLICIES_ERROR:
        draft.getPoliciesPhase = ERROR;
        draft.error = action.error;
        break;
      case RESET_POLICY_PICKER:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getPolicies = clientId => ({
  type: UPDATE_POLICY_PICKER_POLICIES,
  clientId: clientId
});
export const resetPolicyPicker = () => ({
  type: RESET_POLICY_PICKER
});

/***********************************
 * Epics
 ***********/
const getPoliciesEpic = (action$, state$) =>
  action$.ofType(UPDATE_POLICY_PICKER_POLICIES).mergeMap(action => {
    const { clientId } = action;
    const apiPromise = clientId ? api.getClientPolicies(clientId) : api.getOwnPolicies();

    return Rx.Observable.fromPromise(apiPromise)
      .flatMap(result => {
        const { policies = [], userId } = result;

        return [
          {
            type: UPDATE_POLICY_PICKER_POLICIES_SUCCESS,
            policies: policies,
            policiesOwner: userId
          }
        ];
      })
      .catch(error => {
        console.log(error);
        Rx.Observable.of({
          type: UPDATE_POLICY_PICKER_POLICIES_ERROR,
          error: error.toString()
        });
      });
  });

export const policyPickerEpic = combineEpics(getPoliciesEpic);
