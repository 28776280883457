import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Editor from 'draft-js-plugins-editor';
import 'draft-js-image-plugin/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-focus-plugin/lib/plugin.css';
import './draft-js-alignment-plugin/plugin.css';

import { ALIGN_BLOCK_TYPES, customStyleFn, getEditorPlugins } from './constants';
import './MyEditor.scss';

const MyEditor = forwardRef((props, ref) => {
  const {
    imageUsedIn,
    readOnly,
    textAlignment,
    editorState,
    onChange,
    textAlign,
    image,
    list,
    toolbarRef,
    wrapperClassName,
    children,
    ...rest
  } = props;

  const [{ plugins }] = useState(() => {
    const plugins = getEditorPlugins({ toolbar: true, focus: true, alignment: true, image: true });
    return { plugins };
  });

  const editorRef = useRef(null);

  const AlignmentTool = (plugins && plugins.alignment && plugins.alignment.AlignmentTool) || (() => null);

  const focus = () => {
    if (editorRef && editorRef.current) {
      editorRef.current.focus();
    }
  };

  const getEditor = () => {
    return editorRef.current;
  };

  const getPlugins = () => {
    return plugins;
  };

  useImperativeHandle(ref, () => ({
    focus: focus,
    getEditor: getEditor,
    getPlugins: getPlugins
  }));

  // const toolbar = toolbarRef && toolbarRef.current ? toolbarRef.current.getToolbar() : undefined;

  return (
    <div
      className={`my-editor ${wrapperClassName ? wrapperClassName : ''} ${readOnly ? 'read-only' : ''}`}
      // onClick={this.focus.bind(this)}
    >
      <div
        style={
          {
            // paddingTo<p: toolbarRef && toolbarRef.current ? `${toolbarRef.current.offsetHeight + 8}px` : 0,
          }
        }
      >
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={editorState => {
            if (onChange) onChange(editorState);
          }}
          plugins={Object.values(plugins)}
          customStyleFn={customStyleFn}
          textAlignment={textAlignment}
          blockStyleFn={contentBlock => {
            const type = contentBlock.getType();
            switch (type) {
              case ALIGN_BLOCK_TYPES.ALIGN_LEFT:
                return type;
              case ALIGN_BLOCK_TYPES.ALIGN_RIGHT:
                return type;
              case ALIGN_BLOCK_TYPES.ALIGN_CENTER:
                return type;
              default:
                return;
            }
          }}
          {...rest}
        />
        {!readOnly && textAlign && <AlignmentTool />}
      </div>
      {children}
    </div>
  );
});

export default MyEditor;
