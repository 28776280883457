import { fetch } from '../../utils';
import Backend from '../../backend';
import { tracking } from '../../utils/tracking';

//TO UPDATE THE TODOs OF ADVISER
export const updateAdviserTodo = data => {
  return fetch(Backend.methodUrl('adviserTodo'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(result => {
      // Tracking
      tracking('Todo Updated');
      return result;
    })
    .catch(error => {
      throw error;
    });
};

//TO ADD A TODO
export const addAdviserTodo = data => {
  return fetch(Backend.methodUrl('adviserTodo'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(result => {
      // Tracking
      tracking('Todo Added');
      return result;
    })
    .catch(error => {
      throw error;
    });
};

//DELETE A TODO
export const deleteAdviserTodo = data => {
  return fetch(Backend.methodUrl('adviserTodo'), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(result => {
      // Tracking
      tracking('Todo Deleted');
      return result;
    })
    .catch(error => {
      throw error;
    });
};

//DELETE ALL COMPLETED TODO
export const deleteAllCompletedTodo = () => {
  return fetch(Backend.methodUrl('adviserTodo/all'), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(result => {
      // Tracking
      tracking('Todo Deleted');
      return result;
    })
    .catch(error => {
      throw error;
    });
};
