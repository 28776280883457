import React, { PureComponent } from 'react';
import FunctionCardsContainer from '../../components/FunctionCard/FunctionCardsContainer';
import { isAdviserType } from '../../utils/user';
import { HotLabel } from '../AdviserHomePage/HotLabel/HotLabel';
import { tracking } from '../../utils/tracking';

class AnalysisView extends PureComponent {
  componentDidMount() {
    const { location } = this.props;
    let clientId = JSON.parse(localStorage.getItem('clientId'));
    this.props.renderHeaderButton(!(clientId && location.state && !location.state.previousPath));
  }
  render() {
    const { history, intl, location, userDetails } = this.props;

    return (
      <div id="wrapper" className="safari-gen">
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row policyTitle">
              <FunctionCardsContainer
                items={[
                  {
                    text: intl.formatMessage({ id: 'need-analysis' }),
                    subtitle: intl.formatMessage({ id: 'need-analysis-desc' }),
                    imgSrc: 'img/saletools.png',
                    imgAlt: 'need-analysis',
                    color: '#5fc3d2',
                    onClick: () => {
                      tracking('Click on Needs Analysis - Analysis');
                      history.push({
                        pathname: '/need-analysis',
                        state: { ...location.state, previousPath: '/analysis' } ?? { previousPath: '/analysis' }
                      });
                    }
                  },
                  {
                    text: intl.formatMessage({ id: 'premium-protection' }),
                    subtitle: intl.formatMessage({ id: 'premium-protection-desc' }),
                    imgSrc: 'img/premium-protection.png',
                    imgAlt: 'premium-protection',
                    color: '#3999f8',
                    onClick: () => {
                      tracking('Click on Premium & Protection - Analysis');
                      history.push({
                        pathname: '/premium-protection',
                        state: { ...location.state, previousPath: '/analysis' } ?? { previousPath: '/analysis' }
                      });
                    }
                  },
                  isAdviserType(userDetails) && {
                    text: intl.formatMessage({ id: 'fin-pyramid' }),
                    subtitle: intl.formatMessage({ id: 'fin-pyramid-desc' }),
                    imgSrc: 'img/pyramid.png',
                    imgAlt: 'financial-pyramid',
                    color: '#2260a5',
                    onClick: () => {
                      tracking('Click on Financial Pyramid - Analysis');
                      history.push({ pathname: '/financial-pyramid' });
                    },
                    extras: (
                      <HotLabel
                        text={intl.formatMessage({ id: 'hot' })}
                        style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                      />
                    )
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnalysisView;
