export const staticMpfDetail = [
  {
    // "_id" : ObjectId("5e33d6cbdb7a15641636d5df"),
    title: 'MPF',
    domain: '',
    image: 'img/pro_fund.png',
    value: '0',
    __v: 0,
    region: 'any'
  }
];
