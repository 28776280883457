import React, { PureComponent } from 'react';
import PersonalData from './partials/personalData';
import MyDetail from './partials/myDetail';
import { toast } from 'react-toastify';
import _ from 'lodash';
import IconToast from '../../components/NewToast';
import { tracking } from '../../utils/tracking';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer.js';
import { isUserType } from '../../utils/user';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';

class UserDetailsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
  }

  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, history, userDetails, updatePhase, message, updateMessageExtras } = this.props;
    if (updatePhase === true && prevProps.updatePhase !== true) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Updated successfully' })), { className: 'new-toast' });
      if (isUserType(userDetails)) {
        history.push('/c-dashboard');
      }
    } else if (updatePhase === false && prevProps.updatePhase !== false) {
      toast.info(IconToast('error', intl.formatMessage({ id: message }, updateMessageExtras)), {
        className: 'new-toast'
      });
    }
  }

  //sending the users profile data to backend to update that in users table
  sendData() {
    const { userDetails } = this.props;

    let personalData = JSON.parse(localStorage.getItem('personalData'));
    let myDetails = JSON.parse(localStorage.getItem('myDetails'));

    if (personalData && myDetails) {
      const mergedProfile = _.merge(personalData, myDetails);
      const edited = !_.isEqual(_.pick(userDetails, Object.keys(mergedProfile)), mergedProfile);
      if (edited) {
        tracking('Edit Any Setting in Profile');
      }

      let userType = userDetails.userType;
      let formData = {};
      formData.name = personalData.name;
      formData.workEmail = personalData.workEmail;
      formData.phoneNumber = personalData.phoneNumber;
      formData.whatsapp = personalData.whatsapp;
      formData.wechatId = personalData.wechatId;
      formData.lineId = personalData.lineId;
      formData.gender = personalData.gender;

      if (userType === 'User') {
        formData.occupation = personalData.occupation;
        formData.smoker = myDetails.smoker;
        formData.dob = myDetails.dob;
        formData.age = myDetails.age;
        formData.marriageStatus = myDetails.marriageStatus;
        formData.child = myDetails.child;
        formData.monthlyIncome = myDetails.monthlyIncome;
        formData.monthlyExpense = myDetails.monthlyExpense;
        formData.liquidAsset = myDetails.liquidAsset;
        formData.debt = myDetails.debt;
        formData.remark = myDetails.remark;
      } else {
        formData.dob = personalData.dob;
        formData.age = personalData.age;
        formData.company = myDetails.company;
        formData.adviserSince = myDetails.adviserSince;
        formData.servicingYear = myDetails.servicingYear;
        formData.myPassion = myDetails.myPassion;
        formData.adviserMessage = myDetails.adviserMessage;
        formData.remindMe = myDetails.remindMe;
        formData.remindTarget = myDetails.remindTarget;
        formData.phoneNumber2 = personalData.phoneNumber2;
        formData.personality = _.get(personalData, 'personality', '');
        formData.linkedinLink = _.get(myDetails, 'linkedinLink', '');
        formData.facebookLink = _.get(myDetails, 'facebookLink', '');
      }
      this.props.updateUserDetails(formData);
    } else {
      toast.info(IconToast('warn', 'Please reload and try again'), { className: 'new-toast' });
    }
    localStorage.removeItem('personalData');
    localStorage.removeItem('myDetails');
  }

  nextPage(page) {
    this.props.initPhase();
    this.setState({ page: page + 1 });
  }

  previousPage(page) {
    this.setState({ page: page - 1 });
  }

  render() {
    const { updatePhase } = this.props;
    return (
      <div>
        <div id="wrapper">
          {updatePhase === 'loading' && <LoadingBackdrop open={true} />}
          {this.props.location.pathname === '/personalData' && this.state.page === 1 && (
            <AppContentContainer>
              <PersonalData {...this.props} handleNextPage={this.nextPage.bind(this)} />
            </AppContentContainer>
          )}
          {this.props.location.pathname === '/myDetail' ||
            (this.state.page === 2 && (
              <AppContentContainer>
                <MyDetail
                  {...this.props}
                  handleSubmit={this.sendData.bind(this)}
                  handlePreviousPage={this.previousPage.bind(this)}
                />
              </AppContentContainer>
            ))}
        </div>
      </div>
    );
  }
}

export default UserDetailsView;
