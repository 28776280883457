import { fetch } from '../../utils';
import Backend from '../../backend';

export const getUserMissionsApi = () => {
  return fetch(Backend.methodUrl('user-mission'))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const updateAccessHotTopicMissionVariableApi = () => {
  return fetch(Backend.methodUrl('user-mission/access-hot-topic'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
