import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Box } from '@material-ui/core';
import GSTag from '../../../../../components/GSTag/GSTag';
import { LANGUAGE } from '../../../../../constants/language';

const TagsRegion = props => {
  const { intl, taggedContents = [], style, onClickTag } = props;

  const createOnClickTagHandler = ({ tag, items }) =>
    onClickTag
      ? () => {
          onClickTag({ tag, items });
        }
      : undefined;

  return taggedContents.length > 0 ? (
    <Box padding={2}>
      <Grid container spacing={1} style={style}>
        {taggedContents.map((content, index) => {
          const { tag, items, hidden } = content;

          if (hidden) {
            return null;
          }

          const label = tag[intl.locale || LANGUAGE.ZH_HANT_HK];
          const onClick = createOnClickTagHandler({ tag: tag, items: items });

          return (
            <Grid item key={label}>
              <GSTag label={"#" + label} color={index} onClick={onClick} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  ) : null;
};

export default injectIntl(TagsRegion);
