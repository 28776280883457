// export default {
//   labels: {
//     AU: 'Australia',
//     CA: 'Canada',
//     CN: 'China (中国)',
//     FR: 'France (République française)',
//     HK: 'Hong Kong (香港)',
//     HU: 'Hungary (Magyarország)',
//     ID: 'Indonesia',
//     JP: 'Japan (日本)',
//     MO: 'Macau (澳門)',
//     MY: 'Malaysia',
//     MX: 'Mexico (México)',
//     NL: 'Netherlands (Nederland)',
//     NZ: 'New Zealand',
//     PH: 'Philippines (Republika ng Pilipinas)',
//     SG: 'Singapore (Singapura) (新加坡共和国)',
//     KR: 'South Korea (대한민국)',
//     TW: 'Taiwan (台灣)',
//     TH: 'Thailand (ไทย)',
//     GB: 'United Kingdom',
//     US: 'United States',
//     VN: 'Vietnam (Việt Nam)',
//     DE: 'Germany (Deutschland)'
//   }
// };
export default {
  labels: [
    'CN',
    'HK',
    'MO'
  ]
};
