import React from 'react';
import { Card, Grid } from '@material-ui/core';

const CardStyle = {
  position: 'relative',
  borderRadius: 12,
  boxShadow: 'var(--my-shadow)',
  whiteSpace: 'nowrap'
};

const ColoredLeftBorderCard = props => {
  const { color, background, contentStyle, children, style } = props;

  return (
    <Card style={{ ...CardStyle, background: background, ...style }}>
      <Grid container wrap="nowrap">
        <Grid item style={{ background: color, minWidth: 7 }} />

        <Grid item style={{ flexGrow: 1, padding: '8px 16px', ...contentStyle }}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ColoredLeftBorderCard;
