import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './MyProfile.scss';
import './MyProfileUser.scss';
import { Badge, Grid } from '@material-ui/core';
import NameCard from './NameCard/NameCard';
import GridButtonLayout from '../../components/GridButtonLayout/GridButtonLayout';
import { getReminderBadgeCount } from '../../store/reminder/duck';
import { INIT } from '../../constants/phase';
import FeedBackModal from '../../components/FeedbackModal/FeedBackModal';
import UserAccountDetailsCard from './UserAccountDetailsCard/UserAccountDetailsCard';
import MyMedicalCardsCard from './MyMedicalCardsCard/MyMedicalCardsCard';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import _ from 'lodash';
import { tracking } from '../../utils/tracking';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';

const MyProfileUser = props => {
  const {
    intl,
    history,
    location,
    reminderBadgeCount,
    reminderBadgeCountPhase,
    getReminderBadgeCount,
    renderHeaderButton
  } = props;

  const [feedbackDialog, setFeedbackDialog] = useState(false);

  const myMedicalCardsCardRef = useRef(null);

  const closeFeedbackDialog = () => setFeedbackDialog(false);

  const emergency = () => {};

  const share = () => {
    tracking('My Profile: Share');
    history.push('/share_options');
  };

  const reminder = () => {
    tracking('My Profile: Reminder');
    history.push('/reminder');
  };

  const helpCenter = () => {
    tracking('My Profile: Help Center');
    window.open('https://w3.portfoplus.com/help-center', '_blank');
  };

  const feedback = () => {
    tracking('My Profile: Feedback');
    setFeedbackDialog(true);
  };

  useEffect(() => {
    if (reminderBadgeCountPhase === INIT) {
      getReminderBadgeCount();
    }
  }, [reminderBadgeCountPhase, getReminderBadgeCount]);

  useEffect(() => {
    renderHeaderButton(true);
  }, [renderHeaderButton]);

  // toggle medical card if location state has identifier
  useEffect(() => {
    const toggleTimeout = setTimeout(() => {
      const myMedicalCardsCard = _.get(myMedicalCardsCardRef, 'current');
      if (myMedicalCardsCard && _.get(location, 'state.medicalCard')) {
        history.replace({ pathname: location.pathname, state: undefined });
        const wrappedInstance = myMedicalCardsCard.getWrappedInstance();
        if (wrappedInstance) {
          wrappedInstance.toggle();
        }
      }
    }, 500);

    return function cleanup() {
      if (toggleTimeout) {
        clearTimeout(toggleTimeout);
      }
    };
  }, [history, location, myMedicalCardsCardRef]);

  return (
    <AppContentContainer>
      <FeedBackModal isOpen={feedbackDialog} onRequestClose={closeFeedbackDialog} />

      <div className="my-profile my-profile-user">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NameCard />
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="stretch" spacing={2} className="same-height-container">
              <Grid item xs={12} sm={6}>
                <UserAccountDetailsCard />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyMedicalCardsCard ref={myMedicalCardsCardRef} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <GridButtonLayout
              items={[
                {
                  image: <img src="img/usershare.png" alt="share" />,
                  text: intl.formatMessage({ id: 'Share' }),
                  onClick: share
                },
                {
                  image: <img src="img/user_alarm.png" alt="reminder" />,
                  text:
                    reminderBadgeCount > 0 ? (
                      <Badge variant="dot" color="error" badgeContent={reminderBadgeCount}>
                        {intl.formatMessage({ id: 'Reminder' })}
                      </Badge>
                    ) : (
                      intl.formatMessage({ id: 'Reminder' })
                    ),
                  onClick: reminder
                },
                {
                  image: <img src="img/helpCenter.png" alt="go-to-help-center" />,
                  text: intl.formatMessage({ id: 'Help Center' }),
                  onClick: helpCenter
                },
                {
                  image: <img src="img/feedback.png" alt="feedback" />,
                  text: intl.formatMessage({ id: 'Feedback' }),
                  onClick: feedback
                }
              ]}
            />
          </Grid>
        </Grid>
      </div>
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    reminderBadgeCountPhase: state.reminder.reminderBadgeCountPhase
  }),
  {
    getReminderBadgeCount,
    renderHeaderButton
  }
)(MyProfileUser);

export default injectIntl(container);
