import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { toggleDialog } from '../../store/control/duck';
import Typography from '@material-ui/core/Typography';
import { feedback } from '../../store/message/duck';
import { usePrevious } from '../../utils/hooks';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { getAiFeedbackMessage } from '../../utils/aiPrompt';

const ChatGPTFeedbackDialog = props => {
  const { intl, chatGPTFeedbackDialog, feedbackPhase, toggleDialog, feedback } = props;

  const [contentRate, setContentRate] = useState(undefined);
  const [usabilityRate, setUsabilityRate] = useState(undefined);
  const [timeSavingRate, setTimeSavingRate] = useState(undefined);
  const [overallRate, setOverallRate] = useState(undefined);
  const [suggestions, setSuggestions] = useState('');
  const [errors, setErrors] = useState({});
  const prevFeedbackPhase = usePrevious(feedbackPhase);
  const isWide = useMediaQuery(theme => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (feedbackPhase !== prevFeedbackPhase) {
      if (feedbackPhase === true) {
        toast.info(IconToast('info', intl.formatMessage({ id: 'Feedback submitted successfully' })), {
          className: 'new-toast'
        });
        toggleDialog('chatGPTFeedback', { ...chatGPTFeedbackDialog, closed: true });
      }
    }
  }, [intl, feedbackPhase, prevFeedbackPhase, toggleDialog, chatGPTFeedbackDialog]);

  const { extraContent, onSubmit, closed } = chatGPTFeedbackDialog || {};

  const isOpen = !!chatGPTFeedbackDialog && !closed;

  const onChangeContentRate = (event, newValue) => {
    setContentRate(newValue);
  };

  const onChangeUsabilityRate = (event, newValue) => {
    setUsabilityRate(newValue);
  };

  const onChangeTimeSavingRate = (event, newValue) => {
    setTimeSavingRate(newValue);
  };

  const onChangeOverallRate = (event, newValue) => {
    setOverallRate(newValue);
  };

  const onChangeSuggestions = event => {
    setSuggestions(event.target.value);
  };

  const onClose = () => {
    toggleDialog('chatGPTFeedback', { ...chatGPTFeedbackDialog, closed: true });
  };

  const onExited = () => {
    toggleDialog('chatGPTFeedback', false);
    setContentRate(0);
    setUsabilityRate(0);
    setTimeSavingRate(0);
    setOverallRate(0);
    setSuggestions('');
  };

  const _onSubmit = () => {
    const errs = {};
    if (contentRate === undefined) {
      errs.contentRate = intl.formatMessage({ id: 'Required input field' });
    }
    if (usabilityRate === undefined) {
      errs.usabilityRate = intl.formatMessage({ id: 'Required input field' });
    }
    if (timeSavingRate === undefined) {
      errs.timeSavingRate = intl.formatMessage({ id: 'Required input field' });
    }
    if (overallRate === undefined) {
      errs.overallRate = intl.formatMessage({ id: 'Required input field' });
    }
    if (!suggestions) {
      errs.suggestions = intl.formatMessage({ id: 'Required input field' });
    }
    if (Object.keys(errs).length > 0) {
      setErrors(errs);
      return;
    }

    const content = getAiFeedbackMessage({
      contentRate,
      usabilityRate,
      timeSavingRate,
      overallRate,
      suggestions,
      extraContent
    });

    feedback({ content: content });

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog
      className="chat-gpt-feedback-dialog"
      open={isOpen}
      fullWidth={true}
      onClose={onClose}
      onExited={onExited}
      TransitionComponent={SlideTransition}
    >
      <DialogTitle>{intl.formatMessage({ id: 'chat-gpt-feedback-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'chat-gpt-feedback-dialog-content' })}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              {[
                {
                  label: intl.formatMessage({ id: 'chat-gpt-feedback-dialog-content-rate' }),
                  value: contentRate,
                  onChange: onChangeContentRate,
                  error: !!errors.contentRate
                },
                {
                  label: intl.formatMessage({ id: 'chat-gpt-feedback-dialog-usability-rate' }),
                  value: usabilityRate,
                  onChange: onChangeUsabilityRate,
                  error: !!errors.usabilityRate
                },
                {
                  label: intl.formatMessage({ id: 'chat-gpt-feedback-dialog-time-saving-rate' }),
                  value: timeSavingRate,
                  onChange: onChangeTimeSavingRate,
                  error: !!errors.timeSavingRate
                },
                {
                  label: intl.formatMessage({ id: 'chat-gpt-feedback-dialog-overall-rate' }),
                  value: overallRate,
                  onChange: onChangeOverallRate,
                  error: !!errors.overallRate
                }
              ].map(({ label, value, onChange, error, helperText }) => (
                <Grid item key={label}>
                  <Grid container direction={isWide ? 'row' : 'column'} alignItems={isWide ? 'center' : undefined}>
                    <Grid item>
                      <Typography
                        color={error ? 'error' : undefined}
                        style={{
                          fontSize: 16,
                          width: 160
                        }}
                      >
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item style={{ textAlign: 'center', flexGrow: 1 }}>
                      <Rating value={value} onChange={onChange} style={{ fontSize: '2.5rem' }} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography color={!!errors.suggestions ? 'error' : undefined} style={{ fontSize: 16 }}>
                      {intl.formatMessage({ id: 'chat-gpt-feedback-dialog-suggestions' })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      multiline={true}
                      rows={5}
                      error={!!errors.suggestions}
                      helperText={errors.suggestions}
                      value={suggestions}
                      onChange={onChangeSuggestions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={_onSubmit}>
          {intl.formatMessage({ id: 'Submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    chatGPTFeedbackDialog: state.control.chatGPTFeedbackDialog,
    feedbackPhase: state.message.feedbackPhase
  }),
  {
    toggleDialog,
    feedback
  }
)(ChatGPTFeedbackDialog);

export default injectIntl(container);
