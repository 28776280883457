import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import _ from 'lodash';
import { getAvatar } from '../../../utils/my-details';
import { getUserInitial } from '../../../utils/user';
import { THEME_COLOR_IMAGES, THEME_COLOR_PALETTES } from '../../../constants/my-details';
import MyDetailsProfilePicture from '../../Advisor/MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture';
import { formatOpenUrl } from '../../../utils/url';
import { getVCardStringWithPhoto } from '../../../utils/vcard';
import { blobToDataURL, download } from '../../../utils/download';
import AdviserInfoPageCardBackground3 from '../AdviserInfoPageCardBackground/AdviserInfoPageCardBackground3';
import AdviserInfoCardAbstract from '../AdviserInfoCardAbstract/AdviserInfoCardAbstract';
import { getReferralLink } from '../../../utils/referral';

const ImageButtonStyle = {
  padding: 6,
};

const ImageStyle = {
  width: 20,
  height: 20,
};

const GridContainerStyle = {
  height: '100%',
};

const OnTopBackGroundStyle = {
  zIndex: 1,
};

const AvatarGridStyle = {
  ...OnTopBackGroundStyle,
  padding: '25px 0 60px 0',
};

const TypoStyle = {
  whiteSpace: 'pre-wrap',
};

const NameTypoStyle = {
  ...TypoStyle,
  fontSize: '220%',
  letterSpacing: '-1px',
};

const JobTypoStyle = {
  ...TypoStyle,
  fontSize: '150%',
  paddingBottom: '6px',
  marginTop: '-3px',
};

const SaveMyContactButtonStyle = {
  borderRadius: 25,
  width: 100,
  background: '#fff',
  padding: '2px',
};

const FullWidthStyle = {
  width: '100%',
};

const AdviserInfoPageCover = (props) => {
  const { intl, userDetails, adviserInfo, isIntoView, onProfilePicClick } = props;

  const { name, jobTitle, company, phoneNumber, whatsapp, workEmail, facebookLink, instagram, realLinkedinLink } =
    adviserInfo || {};
  const themeColor = _.get(adviserInfo, 'themeColor') || 'blue';
  const themeStyle = _.get(adviserInfo, 'themeStyle') || 'classic';
  const avatar = getAvatar(adviserInfo);
  const referralLink = getReferralLink({ targetAdviser: adviserInfo, userDetails: userDetails });

  const goPhoneNumber = () => {
    window.open(`tel:${phoneNumber}`, '_blank');
  };
  const goWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${whatsapp.replace('+', '')}&text=${encodeURIComponent(
        intl.formatMessage({ id: 'adv-info-whatsapp-msg' }, { link: referralLink }),
      )}`,
      '_blank',
    );
  };
  const goWorkEmail = () => {
    window.open(`mailto:${workEmail}`, '_blank');
  };
  const goFacebook = () => {
    window.open(formatOpenUrl(facebookLink), '_blank');
  };

  const goInstagram = () => {
    window.open(formatOpenUrl(`https://www.instagram.com/${instagram}`), '_blank');
  };

  const goLinkedin = () => {
    window.open(formatOpenUrl(realLinkedinLink), '_blank');
  };

  const saveMyContact = () => {
    getVCardStringWithPhoto(adviserInfo, (string) => download(string, `${name}.vcf`, 'text/x-vcard'));
  };

  // console.log('aadsadxs::: ', onProfilePicClick)
  // console.log('userDetails::: ', userDetails)
  return (
    <AdviserInfoCardAbstract
      className="adviser-info-page-cover"
      customHeader={
        <AdviserInfoPageCardBackground3
          style={{
            backgroundImage: `url("${THEME_COLOR_IMAGES[themeStyle][themeColor || 'blue']['mainTop']}")`,
          }}
        />
      }
      customFooterStyle={{
        backgroundImage: `url("${THEME_COLOR_IMAGES[themeStyle][themeColor || 'blue']['mainFooter']}")`,
        height: 84,
      }}
      contentStyle={{
        overflow: 'hidden',
      }}
      adviserInfo={adviserInfo}
      contentHeight="calc(100% - 160px)"
      isIntoView={isIntoView}
    >
      <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
        <Grid item className="content-container" style={{ ...FullWidthStyle, flexGrow: 1, height: '100%' }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            wrap="nowrap"
            spacing={2}
            style={GridContainerStyle}
          >
            <Grid item style={AvatarGridStyle}>
              <MyDetailsProfilePicture
                onProfilePicClick={onProfilePicClick}
                initial={getUserInitial(adviserInfo)}
                src={avatar}
                width={150}
                borderColor={THEME_COLOR_PALETTES[themeColor][1]}
                extra={
                  <div
                    style={{
                      width: 160,
                      height: 160,
                      position: 'absolute',
                      top: 1,
                      left: 1,
                      borderRadius: '50%',
                      boxShadow: 'inset rgba(0, 0, 0, 0.6) 2px 3px 7px 1px',
                    }}
                  />
                }
              />
            </Grid>

            <Grid item style={{ ...OnTopBackGroundStyle, overflow: 'auto', height: 'calc(100% - 256px)' }}>
              <Grid container direction="column" alignItems="center" justify="center" spacing={2}>
                <Grid item>
                  <Typography
                    align="center"
                    style={{
                      ...NameTypoStyle,
                      color: THEME_COLOR_PALETTES[themeColor][5],
                    }}
                  >
                    {name}
                  </Typography>

                  {jobTitle && (
                    <Typography
                      align="center"
                      style={{
                        ...JobTypoStyle,
                        color: THEME_COLOR_PALETTES[themeColor][5],
                      }}
                    >
                      {jobTitle}
                    </Typography>
                  )}

                  {company && (
                    <Typography
                      align="center"
                      style={{
                        ...TypoStyle,
                        color: THEME_COLOR_PALETTES[themeColor][5],
                      }}
                    >
                      {company}
                    </Typography>
                  )}
                </Grid>
                {(phoneNumber || whatsapp || workEmail || facebookLink || instagram || realLinkedinLink) && (
                  <Grid item style={OnTopBackGroundStyle}>
                    <Grid container alignItems="center" justify="center" spacing={1}>
                      {phoneNumber && (
                        <Grid item>
                          <IconButton onClick={goPhoneNumber} style={ImageButtonStyle}>
                            <img src="img/phone.svg" alt="phone" style={ImageStyle} />
                          </IconButton>
                        </Grid>
                      )}
                      {workEmail && (
                        <Grid item>
                          <IconButton onClick={goWorkEmail} style={ImageButtonStyle}>
                            <img src="img/email.svg" alt="email" style={ImageStyle} />
                          </IconButton>
                        </Grid>
                      )}
                      {facebookLink && (
                        <Grid item>
                          <IconButton onClick={goFacebook} style={ImageButtonStyle}>
                            <img src="img/facebook.svg" alt="facebook" style={ImageStyle} />
                          </IconButton>
                        </Grid>
                      )}
                      {realLinkedinLink && (
                        <Grid item>
                          <IconButton onClick={goLinkedin} style={ImageButtonStyle}>
                            <img src="img/linkedin.svg" alt="linkedin" style={ImageStyle} />
                          </IconButton>
                        </Grid>
                      )}
                      {instagram && (
                        <Grid item>
                          <IconButton onClick={goInstagram} style={ImageButtonStyle}>
                            <img src="img/instagram.svg" alt="instagram" style={ImageStyle} />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                <Grid item style={OnTopBackGroundStyle}>
                  <Grid container justify="center" alignItems="center" spacing={1}>
                    {whatsapp && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={goWhatsapp}
                          style={SaveMyContactButtonStyle}
                        >
                          {intl.formatMessage({ id: 'WhatsApp' })}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={saveMyContact}
                        style={SaveMyContactButtonStyle}
                      >
                        {intl.formatMessage({ id: 'adv-info-save-my-contact' })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdviserInfoCardAbstract>
  );
};

export default injectIntl(AdviserInfoPageCover);
