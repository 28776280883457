import React from 'react';
import { Button, Grid } from '@material-ui/core';
import './SelectButtonGroup.scss';
import clsx from 'clsx';

const SelectButtonStyle = {
  borderRadius: 16,
  lineHeight: 1.1,
  minWidth: 60,
  padding: '4px 10px',
  color: '#9E9E9E'
};

const SelectButton = props => {
  const { className, isSelected, selectedColor, style, ...rest } = props;

  return (
    <Button
      className={clsx('select-button', className)}
      variant={isSelected ? 'contained' : 'outlined'}
      color={isSelected ? 'primary' : 'inherit'}
      style={{
        ...SelectButtonStyle,
        ...(selectedColor && isSelected ? { backgroundColor: selectedColor } : undefined),
        ...(isSelected ? { color: '#fff' } : undefined),
        ...(typeof style === 'function' ? style(isSelected) : style)
      }}
      {...rest}
    />
  );
};

const SelectButtonGroup = props => {
  const { choices, value, multi, max, onChange, onMax, buttonClassName, ...rest } = props;

  const formattedValue = multi ? (typeof value === 'string' ? [value] : value || []) : value;

  const isChoiceSelected = choice => {
    if (multi) {
      return formattedValue.find(oneValue => oneValue === choice.value);
    } else {
      return formattedValue === choice.value;
    }
  };

  const createChoiceOnClickHandler = choice => () => {
    if (onChange) {
      if (multi) {
        if (isChoiceSelected(choice)) {
          onChange({ target: { value: formattedValue.filter(oneValue => oneValue !== choice.value) } });
        } else {
          if (!max || (max && formattedValue.length <= max - 1)) {
            onChange({ target: { value: [...formattedValue, choice.value] } });
          } else {
            if (onMax) {
              onMax();
            }
          }
        }
      } else {
        onChange({ target: { value: isChoiceSelected(choice) ? undefined : choice.value } });
      }
    }
  };

  return (
    <Grid container alignItems="center" justify="flex-start" spacing={1} {...rest}>
      {choices.map(choice => {
        const { value, label, xs, sm, md, lg, xl, ...rest } = choice;
        return (
          <Grid item key={value} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <SelectButton
              isSelected={isChoiceSelected(choice)}
              onClick={createChoiceOnClickHandler(choice)}
              className={buttonClassName}
              {...rest}
            >
              {label}
            </SelectButton>
          </Grid>
        );
      })}
    </Grid>
  );
};
export default SelectButtonGroup;
