import React from 'react';
import { IconButton } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

class MoreInfoIconButton extends React.Component {
  render() {
    const { className, ariaLabel, handleClick, notClientStyle, colorStyle, forwardedRef, ...rest } = this.props;
    return (
      <IconButton
        aria-label={ariaLabel ? ariaLabel : 'More Info'}
        color={colorStyle ? colorStyle : 'primary'}
        className={
          !notClientStyle
            ? 'more-info-img ' + (className ? className : '')
            : 'more-info-img-client ' + (className ? className : '')
        }
        onClick={handleClick}
        ref={forwardedRef}
        {...rest}
      >
        <HelpOutlineIcon />
      </IconButton>
    );
  }
}

export default MoreInfoIconButton;
