export const addClientGuideline = ({ intl }) => [
  {
    target: '.fab',
    content: 'jr-ac-1',
    placement: 'auto',
    disableBeacon: true,
    isFixed: true,
    disableScrolling: true,
    title: intl.formatMessage({ id: 'jr-ac-1-title' }),
    tutorialItemId: 'c02'
  }
];
