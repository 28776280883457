import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import produce from 'immer';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const GET_USER_DATA_PENDING = 'GET_USER_DATA_PENDING';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';
export const RESET_USER_DATA = 'RESET_USER_DATA';

/***********************************
 * Initial State
 ***********/
const initialState = {
  userData: {},
  getPhase: INIT,
  error: undefined
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_USER_DATA_PENDING:
        draft.getPhase = LOADING;
        break;
      case GET_USER_DATA_SUCCESS:
        const { userData } = action;
        draft.userData = userData;
        draft.getPhase = SUCCESS;
        draft.error = undefined;
        break;
      case GET_USER_DATA_ERROR:
        const { error } = action;
        draft.userData = {};
        draft.getPhase = ERROR;
        draft.error = error;
        break;
      case RESET_USER_DATA:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getUserData = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_DATA_PENDING });

  try {
    const response = await api.getUserData();
    dispatch({ type: GET_USER_DATA_SUCCESS, userData: response });
  } catch (error) {
    dispatch({ type: GET_USER_DATA_ERROR, error });
  }
};
