import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
import * as api from './api';
import _ from 'lodash';
import { getDefaultPremiumFinancingProfile } from '../../utils/premium-financing';
import produce from 'immer';

/***********************************
 * Action Types
 ***********/
export const UPDATE_PFIN_CALCULATOR_PROFILES = 'UPDATE_PFIN_CALCULATOR_PROFILES';
export const UPDATE_PFIN_CALCULATOR_PROFILES_STATE = 'UPDATE_PFIN_CALCULATOR_PROFILES_STATE';
export const GET_PFIN_CALCULATOR_PROFILES_STATE = 'GET_UPDATE_PFIN_CALCULATOR_PROFILES_STATE';
export const UPDATE_PFIN_CALCULATOR_PROFILES_LOCAL = 'UPDATE_PFIN_CALCULATOR_PROFILES_LOCAL';
export const UPDATE_PFIN_CALCULATOR_INPUTS = 'UPDATE_PFIN_CALCULATOR_INPUTS';

/***********************************
 * Initial State
 ***********/
const initialState = {
  getProfilesLoadState: INIT,
  updateProfilesLoadState: INIT,
  profiles: [
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile(),
    getDefaultPremiumFinancingProfile()
  ],
  loading: false
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_PFIN_CALCULATOR_PROFILES_STATE:
        draft.getProfilesLoadState = action.state;
        break;
      case UPDATE_PFIN_CALCULATOR_PROFILES_STATE:
        draft.updateProfilesLoadState = action.state;
        break;
      case UPDATE_PFIN_CALCULATOR_PROFILES:
        draft.profiles = action.profiles;
        break;
      case UPDATE_PFIN_CALCULATOR_PROFILES_LOCAL:
        draft.profiles[action.index] = {
          ...draft.profiles[action.index],
          ...action.setObj
        };
        break;
      case UPDATE_PFIN_CALCULATOR_INPUTS:
        draft[action.key] = action.value;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getPFinCalculatorProfiles = () => async dispatch => {
  dispatch({ type: GET_PFIN_CALCULATOR_PROFILES_STATE, state: LOADING });
  try {
    const result = await api.getPFinCalculatorProfiles();
    if (result && result.pFinCalculatorProfiles) {
      dispatch({
        type: UPDATE_PFIN_CALCULATOR_PROFILES,
        profiles: result.pFinCalculatorProfiles.profiles
      });
    }
    dispatch({ type: GET_PFIN_CALCULATOR_PROFILES_STATE, state: SUCCESS });
  } catch (error) {
    dispatch({ type: GET_PFIN_CALCULATOR_PROFILES_STATE, state: ERROR });
  }
};

export const savePFinCalculatorProfiles = body => async dispatch => {
  dispatch({ type: UPDATE_PFIN_CALCULATOR_PROFILES_STATE, state: LOADING });
  try {
    const result = await api.updatePFinCalculatorProfiles(body);
    if (result && result.pFinCalculatorProfiles) {
      dispatch({
        type: UPDATE_PFIN_CALCULATOR_PROFILES,
        profiles: result.pFinCalculatorProfiles.profiles
      });
    }
    dispatch({ type: UPDATE_PFIN_CALCULATOR_PROFILES_STATE, state: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_PFIN_CALCULATOR_PROFILES_STATE, state: ERROR });
  }
};

export const updatePFinCalculatorProfiles = (index, setObj) => dispatch => {
  dispatch({
    type: UPDATE_PFIN_CALCULATOR_PROFILES_LOCAL,
    setObj,
    index
  });
};

export const updatePFinCalculatorInputs = (key, value) => ({
  type: UPDATE_PFIN_CALCULATOR_INPUTS,
  key,
  value
});
