import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, TextField, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import NumberFormatInputWrapper from '../NumberFormatInput/NumberFormatInputWrapper';

const ButtonStyle = {
  margin: 0,
  width: '100%'
};

const PremiumInput = props => {
  const {
    intl,
    label,
    value,
    onChange,
    frequency,
    onChangeFrequency,
    naPremium,
    onChangeNAPremium,
    InputLabelProps,
    inputProps,
    ...rest
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ paddingTop: 12 }}>
        <TextField
          label={label}
          name="premium"
          defaultValue={0}
          value={value}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: NumberFormatInputWrapper,
            inputProps: inputProps,
            endAdornment: (
              <InputAdornment position="end" style={{ position: 'absolute', maxWidth: 180, right: 0, top: 0 }}>
                <SelectButtonGroup
                  value={frequency}
                  choices={[
                    {
                      value: '1',
                      label: intl.formatMessage({ id: 'Per Month' }),
                      disabled: !!naPremium,
                      xs: 6,
                      style: ButtonStyle
                    },
                    {
                      value: '3',
                      label: intl.formatMessage({ id: 'Per Quarter' }),
                      disabled: !!naPremium,
                      xs: 6,
                      style: ButtonStyle
                    },
                    {
                      value: '6',
                      label: intl.formatMessage({ id: 'Per Half Year' }),
                      disabled: !!naPremium,
                      xs: 6,
                      style: ButtonStyle
                    },
                    {
                      value: '12',
                      label: intl.formatMessage({ id: 'Per Year' }),
                      disabled: !!naPremium,
                      xs: 6,
                      style: ButtonStyle
                    }
                  ]}
                  onChange={onChangeFrequency}
                  justify="flex-end"
                  spacing={1}
                  buttonClassName="plan-type-select-button"
                />
              </InputAdornment>
            ),
            style: {
              minHeight: 32
            }
          }}
          onChange={onChange}
          {...rest}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="primary" checked={naPremium || false} onChange={onChangeNAPremium} />}
          label={intl.formatMessage({ id: 'premium-na' })}
          style={{ marginBottom: 0 }}
        />
      </Grid>
    </Grid>
  );
};

export default injectIntl(PremiumInput);
