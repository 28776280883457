import React from 'react';
import { connect } from 'react-redux';
import { fetchAdviserBusiness, updateAdviserBusiness } from '../../../../../../store/adviserDashboard/duck';
import MyEditorModal from '../../../../../../components/MyEditorModal/MyEditorModal';
import { injectIntl } from 'react-intl';

class NoteEditorModal extends React.Component {
  constructor(props) {
    super(props);
    if (!props.adviserBusiness) {
      props.fetchAdviserBusiness();
    }
  }

  render() {
    const {
      intl,
      adviserBusiness,
      open,
      getFigureAdviserPhase,
      updatePhase,
      onClose,
      updateAdviserBusiness
    } = this.props;

    const note = adviserBusiness ? adviserBusiness.note : undefined;

    return (
      <MyEditorModal
        intl={intl}
        originalState={note}
        contentState={note}
        open={open}
        onClose={onClose}
        loadingPhase={getFigureAdviserPhase}
        updatePhase={updatePhase}
        headerText={intl.formatMessage({ id: 'Notes' })}
        onUpdate={data => {
          updateAdviserBusiness({ note: data });
        }}
        textAlign={true}
        image={false}
        list={true}
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    getFigureAdviserPhase: state.adviserDashboard.getFigureAdviserPhase,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    updatePhase: state.adviserDashboard.updateQuarterTargetPhase
  }),
  // Map actions to props
  {
    fetchAdviserBusiness,
    updateAdviserBusiness
  },
  // mergeProps
  null,
  // options
  {
    forwardRef: true
  }
)(NoteEditorModal);

export default injectIntl(container);
