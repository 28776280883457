import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const IconStyle = {
  fontSize: '100px'
};

const TitleTypoStyle = {
  fontWeight: 'bold',
  margin: '30px 0 15px 0'
};

const SubTitleTypoStyle = {
  marginBottom: 10
};

const RegisterSuccess = props => {
  const { intl } = props;

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <CheckCircleRoundedIcon color="primary" style={IconStyle} />
      </Grid>
      <Grid item>
        <Typography variant="h3" style={TitleTypoStyle}>
          {intl.formatMessage({ id: 'Thank you' })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5" style={SubTitleTypoStyle}>
          {intl.formatMessage({ id: 'pa-register-success' })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(RegisterSuccess);
