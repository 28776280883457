import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FileOpener } from '@ionic-native/file-opener';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { isChromeIOS } from '../device';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { tryTranslate } from '../locale';
import { scheduleNotification } from './notification';
import store from '../../store';
import { toggleDialog } from '../../store/control/duck';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';
import { getFormattedName } from './file-system';
import { fetch } from '../../utils';

const setupLocalNotifications = () => {
  if (Capacitor.isNativePlatform()) {
    LocalNotifications.registerActionTypes({ types: [{ id: 'download-file', actions: [] }] })
      .then()
      .catch();

    LocalNotifications.addListener('localNotificationActionPerformed', notificationAction => {
      const { extra, actionTypeId } = notificationAction.notification;
      if (actionTypeId === 'download-file') {
        const { uri, type } = extra || {};
        FileOpener.showOpenWithDialog(uri, type).catch(e => console.log('Error opening file', e));
      }
    });
  }
};
setupLocalNotifications();

export const checkFilesystemPermission = async (onNotDenied, onDenied) => {
  try {
    const permissionsStatus = await Filesystem.checkPermissions();
    const { publicStorage } = permissionsStatus;

    if (publicStorage === 'denied') {
      store.dispatch(
        toggleDialog('permissionDenied', {
          intlId: 'filesystem-denied-msg',
          onClose: () => OpenNativeSettings.open('application_details').catch()
        })
      );
      onDenied && (await onDenied({ message: tryTranslate(getGlobalIntl(), 'permission-denied') }));
    } else if (publicStorage === 'prompt-with-rationale' || publicStorage === 'prompt') {
      store.dispatch(
        toggleDialog('permission', {
          intlId: 'filesystem-required-msg',
          onClose: onNotDenied
        })
      );
    } else {
      await onNotDenied();
    }
  } catch (error) {
    onDenied && (await onDenied(error));
  }
};

const blobToDataURL = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => resolve(e.target.result);
    reader.onerror = e => reject(e);
    reader.readAsDataURL(blob);
  });

const openFileInNewTab = (url, filename, type) => {
  const anchor = document.createElement('a');
  anchor.download = filename;
  anchor.href = url;
  anchor.type = type || 'text/plain';
  anchor.target = '_blank';
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
};

export const downloadFile = async (data, filename, type, fetchParams) => {
  const { fetchOptions, fetchIncludeCredentials } = fetchParams || {};
  const currentPlatform = Capacitor.getPlatform();

  if (!(data instanceof Blob)){
    const intl = getGlobalIntl();
    toast.info(tryTranslate(intl, 'file-download-started'));
  }
  
  const blob = data instanceof Blob ? data : await fetch(data, fetchOptions, fetchIncludeCredentials).then(r => r.blob());

  if (currentPlatform === 'web') {
    if (isChromeIOS()) {
      const dataUrl = await blobToDataURL(blob);
      openFileInNewTab(dataUrl, filename, type);
    } else {
      saveAs(blob, filename);
    }
  } else {
    await checkFilesystemPermission(async () => {
      const base64data = await blobToDataURL(blob);
      const isIOS = currentPlatform === 'ios';
      let formattedName = await getFormattedName(filename, 'Download/', Directory.ExternalStorage);
      let formattedType = type || '';
      formattedType = formattedType.indexOf('text/json') > -1 ? (isIOS ? 'text/rtf' : 'text/plain') : formattedType;
      
      let result;
      let currentTry = 0;
      let lastIndexOfDot = formattedName.lastIndexOf('.');
      let extension = lastIndexOfDot > -1 ? formattedName.substr(lastIndexOfDot + 1) : '';
      let formattedNameOnly = lastIndexOfDot > -1 ? formattedName.substr(0, lastIndexOfDot) : formattedName;

      try {
        while (!result) {
          try {
            result = await Filesystem.writeFile({
              path: `Download/${formattedName}`,
              data: base64data,
              directory: currentPlatform === 'ios' ? Directory.Cache : Directory.ExternalStorage,
              recursive: true
            });
          } catch (error) {
            if (error.message !== 'FILE_NOTCREATED') {
              break;
            } else {
              currentTry++;
              formattedName = `${formattedNameOnly}(${currentTry}).${extension}`;
            }
          }
        }

        FileOpener.open(result.uri, formattedType).catch(e => console.log('Error opening file', e));

        if (!isIOS) {
          const intl = getGlobalIntl();
          await scheduleNotification({
            notifications: [
              {
                title: tryTranslate(intl, 'file-saved-title'),
                body: tryTranslate(intl, 'file-saved-content'),
                actionTypeId: 'download-file',
                extra: { uri: result.uri, type: formattedType }
              }
            ]
          });
        }
      } catch (error) {
        console.error('Error in file download:', error);
      }
    });
  }
};
