import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { LOADING } from '../../../constants/phase';
import { enableTracking } from '../../../store/client/duck';
import { getClientConnectionStatus } from '../utils';
import BusinessVolControl from '../controls/BusinessVolControl/BusinessVolControl';
import { tracking } from '../../../utils/tracking';

const OPPORTUNITY_BACKGROUND_COLORS = { L: 'rgb(255, 106, 116)', M: 'rgb(255, 175, 25)', H: 'rgb(94, 224, 146)' };

class TrackOnDialog extends React.PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const { toggleDialog } = this.props;
    if (this.props.enableTrackingPhase === true && prevProps.enableTrackingPhase === LOADING) {
      toggleDialog('trackOn', false);
    }
  }

  enableTracking = () => {
    const { trackOnDialog, userDetails, toggleDialog, enableTracking } = this.props;
    const { client, startDate, opportunity, expectedBusinessAmount } = trackOnDialog;

    let formattedStartDate = startDate
      ? moment.utc(moment(startDate).format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z')
      : '';
    let obj = {};
    obj.clientId = client._id;
    obj.factsheetId = client.factsheetId._id;
    obj.advisorId = userDetails._id;
    obj.met = 0;

    if (!expectedBusinessAmount) {
      toggleDialog('trackOn', { ...trackOnDialog, errorExpectedBusinessAmount: 'Please fill in' });
      return;
    }

    // Added new filed on modal and also insert them on create new tracking
    obj.businessVol = expectedBusinessAmount;

    if (opportunity) {
      obj.opportunity = opportunity;
      if (opportunity === 'H') {
        let dateHigh = moment().format('YYYY-MM-DD');
        obj.dateHigh = moment.utc(dateHigh).format('YYYY-MM-DDT00:00:00.00Z');
        obj.dayCount = moment().diff(moment(formattedStartDate, 'YYYY-MM-DD'), 'days');
      } else {
        obj.dayCount = 0;
      }
    } else {
      obj.dayCount = 0;
    }

    obj.startDate = formattedStartDate;
    obj.isActive = client.trackingId ? !client.trackingId.isActive : true;
    obj.connectionStatus = getClientConnectionStatus(client);

    enableTracking(obj);

    // Tracking
    tracking('Enable Business Tracking');
  };

  close = () => this.props.toggleDialog('trackOn', { ...this.props.trackOnDialog, closed: true });
  closed = () => this.props.toggleDialog('trackOn', false);

  render() {
    const { intl, trackOnDialog, enableTrackingPhase, toggleDialog } = this.props;

    if (!trackOnDialog) return null;

    const { startDate, opportunity, expectedBusinessAmount, errorExpectedBusinessAmount, closed } = trackOnDialog;

    return (
      <Dialog
        open={!!trackOnDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'BusinessTracking' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'bizTrackingDesc' })}</DialogContentText>
          <div className="modal-text">
            <div className="row">
              <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                {intl.formatMessage({ id: 'Tracking start on' })}
              </div>
              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                <DatePicker
                  className="case-datepicker close-case-date case-open-datepicker"
                  maxDate={new Date()}
                  name="startDate"
                  format="DD-MMM-YYYY"
                  value={startDate}
                  onChange={date =>
                    toggleDialog('trackOn', {
                      ...trackOnDialog,
                      startDate: date,
                      startDateChanged: true
                    })
                  }
                  autoOk={true}
                  invalidDateMessage={null}
                  minDateMessage={null}
                  maxDateMessage={null}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                {intl.formatMessage({ id: 'Opportunity' })}
              </div>
              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                <div className="clientList">
                  {Object.keys(OPPORTUNITY_BACKGROUND_COLORS).map(key => (
                    <Button
                      key={key}
                      className="portfolio-default-button button-line-height"
                      style={{
                        background: opportunity === key ? OPPORTUNITY_BACKGROUND_COLORS[key] : '#ccc',
                        color: opportunity === key ? '#fff' : '#000'
                      }}
                      variant="contained"
                      onClick={() =>
                        toggleDialog('trackOn', {
                          ...trackOnDialog,
                          opportunity: key
                        })
                      }
                    >
                      {intl.formatMessage({
                        id: `opp${key}`
                      })}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                {intl.formatMessage({ id: 'Expected Business' })}
              </div>
              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                <BusinessVolControl
                  style={{
                    width: 111,
                    borderBottom: '1px solid gray',
                    height: '25px',
                    color: '#3966f8'
                  }}
                  value={expectedBusinessAmount}
                  onChange={value =>
                    toggleDialog('trackOn', {
                      ...trackOnDialog,
                      expectedBusinessAmount: value,
                      errorExpectedBusinessAmount: undefined
                    })
                  }
                />
                <i className="bar bar-edit" />
                {errorExpectedBusinessAmount && (
                  <span className="error_field" style={{ fontSize: '13px' }}>
                    {intl.formatMessage({ id: errorExpectedBusinessAmount })}
                  </span>
                )}
              </div>
            </div>
            {/* <br />
            {intl.formatMessage({ id: 'After each meeting please update' })}
            <ol className="tracking_ol">
              <li>{intl.formatMessage({ id: 'Factsheet' })}</li>
              <li>{intl.formatMessage({ id: 'No. of Meeting' })}</li>
              <li>"{intl.formatMessage({ id: 'Opportunity' })}"</li>
            </ol> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.enableTracking} color="primary" disabled={enableTrackingPhase === LOADING} autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    trackOnDialog: state.control.trackOnDialog,
    enableTrackingPhase: state.client.enableTrackingPhase
  }),
  // Map actions to props
  {
    toggleDialog,
    enableTracking
  },
  // mergeProps
  null,
  // options
  {}
)(TrackOnDialog);

export default injectIntl(container);
