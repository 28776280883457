import React from 'react';
import MyEditor from '../MyEditor/MyEditor';
import { formatEditorStateValue } from '../../utils/editor';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';

export default function AdviserBlastPopUp(props) {
  const { open, onClose, markAndClose, intl, clientMessages } = props;
  const data = props.adviserBlastPop;

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition}>
      <DialogContent>
        <MyEditor readOnly={true} editorState={formatEditorStateValue(data && data.detail ? data.detail : '')} />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={clientMessages} color="primary" variant="contained">
          {intl.formatMessage({ id: 'more-detail' })}
        </Button>
        <Button color="primary" onClick={markAndClose}>
          {intl.formatMessage({ id: 'Close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
