/***********************************
 * Action Types
 ***********/
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const RENDER = 'RENDER';
export const NO_RENDER = 'NO_RENDER';

/***********************************
 * Initial State
 ***********/
const InitialStateInterface = {
  headerButtonControl: false,
  showMenuButton: true
};

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = InitialStateInterface, action = {}) {
  // const { payload } = action;

  switch (action.type) {
    /***********************************
     * Create QR Code
     ***********/
    case OPEN: {
      state.headerButtonControl = true;
      return state;
    }

    case CLOSE: {
      state.headerButtonControl = false;
      return state;
    }
    case RENDER: {
      state.showMenuButton = true;

      return state;
    }
    case NO_RENDER: {
      state.showMenuButton = false;
      return state;
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const openHeaderMenu = () => {
  return { type: OPEN };
}
export const closeHeaderMenu = () => {
  return { type: CLOSE };
}
export const renderHeaderButton = (val) => {
  if (val) return { type: RENDER };
  else return { type: NO_RENDER };
}
