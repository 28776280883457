import { Collapse, Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useState } from 'react';
import { formatNumber } from '../../../../../utils/formatNumber';

const formulaStyle = { color: '#3966f8' };

const PFinRow = ({
  principal,
  base,
  intl,
  bankLoanInterest,
  term,
  expenseForTable,
  totalAmount,
  returnsForTable,
  profitRateWithYearsToShow,
  firstYearBonus,
  cashValueMinusLoan,
  overrideLoanInterest,
  yearlyIRR,
  i,
  open,
  onToggleOpen
}) => {
  return (
    <>
      <TableRow className="main-rows">
        <TableCell align="center">
          <div>{`${term}`}</div>
        </TableCell>
        <TableCell align="center">
          <div>{formatNumber(base, intl)}</div>
        </TableCell>
        <TableCell align="center">
          <div>{formatNumber(expenseForTable[i], intl)}</div>
        </TableCell>
        <TableCell align="center">
          <div>{formatNumber(totalAmount[i], intl)}</div>
        </TableCell>
        <TableCell align="center">
          <div>{formatNumber(cashValueMinusLoan[i], intl)}</div>
        </TableCell>
        <TableCell align="center">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={10}>
              <Grid container justify="center" alignItems="center">
                <div>{formatNumber(returnsForTable[i], intl)}</div>
                <div className="profit-rate">{`${
                  !isNaN(profitRateWithYearsToShow[i]) ? profitRateWithYearsToShow[i]?.toFixed(2) ?? '0' : '-'
                }%`}</div>
                {open && (
                  <div>{`${intl.formatMessage({ id: 'pfa-annual-return' })}: ${
                    !isNaN(yearlyIRR[i]) ? yearlyIRR[i]?.toFixed(2) ?? '0' : '-'
                  }%`}</div>
                )}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="expand row" size="small" onClick={() => onToggleOpen(i)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow className="collapse-rows">
        <TableCell colSpan={1} align="center" />
        <TableCell colSpan={1} align="center" />
        <TableCell colSpan={1} align="center">
          <Collapse in={open}>
            {overrideLoanInterest
              ? intl.formatMessage(
                  { id: 'pfa-table-expense-formula' },
                  { loan: formatNumber((principal ?? 0) - (base ?? 0) - (firstYearBonus ?? 0), intl) }
                )
              : `${formatNumber((principal ?? 0) - (base ?? 0) - (firstYearBonus ?? 0), intl)} x ${bankLoanInterest ??
                  0}% x ${term}`}
          </Collapse>
        </TableCell>
        <TableCell colSpan={1} align="center" />
        <TableCell colSpan={1} align="center">
          <Collapse in={open}>{`${formatNumber(totalAmount[i] ?? 0, intl)} - ${formatNumber(
            (principal ?? 0) - (base ?? 0) - (firstYearBonus ?? 0),
            intl
          )}`}</Collapse>
        </TableCell>
        <TableCell colSpan={1} align="center">
          <Collapse in={open}>
            {`(${formatNumber(totalAmount[i] ?? 0, intl)} - ${formatNumber(base ?? 0, intl)} - ${formatNumber(
              (principal ?? 0) - (base ?? 0) - (firstYearBonus ?? 0),
              intl
            )} - ${intl.formatMessage({ id: 'pfa-total-expense' })})`}{' '}
            <span style={formulaStyle}>{`( / ${formatNumber(base, intl)} )`}</span>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PFinRow;
