import _ from 'lodash';

// Format number in xxxK if locale is English, in xxx萬 if locale is Chinese
// Chinese translation will be read from intl
export const formatNumber = function(value, intl, separate) {
  // if (value === undefined || isNaN(value)) return '-'; fix null tostring error
  if ((!value && value !== 0) || isNaN(value)) return '-';

  value = value.toString();
  // negative value
  let isNegative = false;
  if (value.length > 0 && value[0] === '-') {
    value = value.replace('-', '');
    isNegative = true;
  }

  value = Math.round(parseFloat(value));

  let unit, unitTranslation;
  if (intl.locale === 'en') {
    unit = 1000;
    unitTranslation = 'K';
  } else {
    unit = 10000;
    unitTranslation = intl.formatMessage({ id: 'tenK' });
  }

  let formattedValue;
  if (value < unit) {
    formattedValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    unitTranslation = '';
  } else {
    // handle cases that are near to unit
    let fixedAmount = value / unit < 100 ? 1 : 0;

    let displayNumber = value % unit === 0 ? (value / unit).toString() : (value / unit).toFixed(fixedAmount);
    formattedValue = displayNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (isNegative) {
    formattedValue = '-' + formattedValue;
  }

  if (!separate) {
    return formattedValue + unitTranslation;
  } else {
    return { value: formattedValue, unit: unitTranslation };
  }
};

export const formatOnlyNumber = function(value, intl) {
  // if (value === undefined || isNaN(value)) return '-'; fix null tostring error
  if (!value || isNaN(value)) return '-';

  value = value.toString();
  // negative value
  let isNegative = false;
  if (value.length > 0 && value[0] === '-') {
    value = value.replace('-', '');
    isNegative = true;
  }

  value = Math.round(parseFloat(value));

  let unit;
  if (intl.locale === 'en') {
    unit = 1000;
  } else {
    unit = 10000;
  }

  let formattedValue;
  if (value < unit) {
    formattedValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    // handle cases that are near to unit
    let fixedAmount = value / unit < 100 ? 1 : 0;

    let displayNumber = value % unit === 0 ? (value / unit).toString() : (value / unit).toFixed(fixedAmount);
    formattedValue = displayNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (isNegative) {
    formattedValue = '-' + formattedValue;
  }

  return formattedValue;
};

export const formatOnlyUnit = function(value, intl) {
  // if (value === undefined || isNaN(value)) return '-'; fix null tostring error
  if (!value || isNaN(value)) return '-';

  let unit, unitTranslation;
  if (intl.locale === 'en') {
    unit = 1000;
    unitTranslation = 'K';
  } else {
    unit = 10000;
    unitTranslation = intl.formatMessage({ id: 'tenK' });
  }

  return value < unit ? '' : unitTranslation;
};

export const roundNumber = (value, precision) => {
  if (!value || isNaN(value)) return value;

  const multiplier = Math.pow(10, precision || 0);
  return Math.round((value ?? 0) * multiplier) / multiplier;
};

export const decimalToString = (value, decimalPlaces) => {
  let frontPart = '',
    backPart = '',
    containDot = false;

  if (value === NaN) {
    return '-';
  }

  if (typeof value !== 'string') {
    if (value === undefined || value === null) {
      value = '';
    }
    value = value.toString();
  }

  if (value.indexOf('.') > -1) {
    containDot = true;

    const splits = value.split('.');
    frontPart = _.get(splits, '[0]', '');
    backPart = _.get(splits, '[1]', '');
  } else {
    frontPart = value;
    backPart = '';
  }

  if (backPart) {
    backPart = parseFloat(`0.${backPart}`)
      .toFixed(decimalPlaces || 0)
      .replace(/(\.0+|0+)$/, '')
      .replace('0.', '');
    if (backPart === '0') {
      containDot = false;
      backPart = '';
    }
    if (backPart === '1') {
      containDot = false;
      backPart = '';
      frontPart = parseFloat(frontPart) + 1;
    }
  }

  frontPart = Math.round(frontPart)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let formatted = `${frontPart}`;
  if (containDot && decimalPlaces > 0) {
    formatted += `.${backPart}`;
  }

  if (formatted === '-Infinity') {
    return '-';
  }

  return formatted;
};

export const addThousandSeparators = value => {
  let parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getIntegerOrFixed = (value, decimalPlaces) => {
  const roundedValue = value.toFixed(decimalPlaces === undefined ? 1 : decimalPlaces).toString();
  const roundedFloatValue = parseFloat(roundedValue);

  return roundedFloatValue.toString();
};

export const parseFormattedNumber = numberStr => {
  return parseFloat((numberStr.toString() || '0').replace(/,/g, ''));
};

export const countDecimals = value => {
  if (Math.floor(value) === value) return 0;

  const str = (value || '').toString();
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0;
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return str.split('-')[1] || 0;
};
