
import { Standard } from './Template/Template'
import { injectIntl } from 'react-intl';
import React from 'react';


const EventDetail = props => {
    // console.log('EventDetail props: ', props);
    return < Standard {...props} />;
}



export default injectIntl(EventDetail);
