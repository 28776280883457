import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { toggleDialog } from '../../../store/control/duck';
import { SlideTransition } from '../../Transition/Transition';

const ChatGPTInsufficientDataDialog = props => {
  const { intl, chatGPTInsufficientDataDialog, toggleDialog } = props;

  const { client, closed } = chatGPTInsufficientDataDialog || {};

  const isOpen = !!chatGPTInsufficientDataDialog && !closed;

  const onClose = () => {
    toggleDialog('chatGPTInsufficientData', { ...chatGPTInsufficientDataDialog, closed: true });
    toggleDialog('smartIdea', { client: client });
  };

  const onExited = () => {
    toggleDialog('chatGPTInsufficientData', false);
  };

  return (
    <Dialog
      className="chat-gpt-insufficient-data-dialog"
      open={isOpen}
      fullWidth={true}
      onExited={onExited}
      TransitionComponent={SlideTransition}
    >
      <DialogTitle>{intl.formatMessage({ id: 'chat-gpt-insufficient-data-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'chat-gpt-insufficient-data-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    chatGPTInsufficientDataDialog: state.control.chatGPTInsufficientDataDialog
  }),
  {
    toggleDialog
  }
)(ChatGPTInsufficientDataDialog);

export default injectIntl(container);
