import React from 'react';

export const POLICY_TYPE = {
  LIFE: 0,
  CI: 1
};

export const TERMS_OPTIONS = {
  YEARS_5: '5 Years',
  YEARS_8: '8 Years',
  YEARS_10: '10 Years',
  YEARS_12: '12 Years',
  YEARS_15: '15 Years',
  YEARS_18: '18 Years',
  YEARS_20: '20 Years',
  YEARS_25: '25 Years',
  TILL_AGE_55: 'Till age 55',
  TILL_AGE_60: 'Till age 60',
  TILL_AGE_65: 'Till age 65',
  TILL_AGE_70: 'Till age 70',
  TILL_AGE_75: 'Till age 75',
  TILL_AGE_80: 'Till age 80',
  TILL_AGE_85: 'Till age 85',
  TILL_AGE_100: 'Till age 100',
  TILL_AGE_120: 'Till age 120',
  WHOLE_LIFE: 'Whole Life',
  OTHER: 'Other'
};

export const ADD_ONS_OPTIONS = {
  WAVIER_OF_PREM: 'wavier-of-prem',
  PAYOR_BENEFIT: 'payor-benefit'
};
