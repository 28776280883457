import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateUserDetails } from '../../../../store/user/duck';
import { updateAnalysisInputs, updateSavingSelectedPolicies } from '../../../../store/analysis/duck';
import { injectIntl } from 'react-intl';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';
import {
  getCalculatedSavingData,
  getSavingTopUpMax,
  getSavingTopUpOptions,
  getScoreColor
} from '../../../../utils/analysis';
import PolicyPickerInfo from '../PolicyPickerInfo/PolicyPickerInfo';
import _ from 'lodash';
import PolicyPickerButton from '../PolicyPickerButton/PolicyPickerButton';
import { isInsuredNotMePolicy, isPolicyCategory, isPolicySubCategory } from '../../../../utils/policy';
import AgeInput from '../AgeInput/AgeInput';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import CustomInputSelect from '../CustomInputSelect/CustomInputSelect';
import {
  INCOME_OPTIONS,
  INPUT_GROUP_TYPE,
  RETURN_RATE_OPTIONS,
  INFLATION_RATE_OPTIONS,
  SAVING_YEAR_OPTIONS,
  SCORE_LEVEL_COLORS
} from '../../../../constants/analysis';
import CurrentAssetInput from '../CurrentAssetInput/CurrentAssetInput';
import MoreInfoTrigger from '../../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import CustomTopUpInput from '../CustomTopUpInput/CustomTopUpInput';
import AnalysisInputPanel from '../AnalysisInputPanel';
import { getUserCurrency } from '../../../../utils/user';
import Typography from '@material-ui/core/Typography';
import FigureUnitTypo from '../../../../components/FigureUnitTypo/FigureUnitTypo';
import InputPanelSlider from '../InputPanelSlider/InputPanelSlider';
import { getSavingToReachData } from '../../../../utils/analysisCalculator';

const FutureDivStyle = {
  borderLeft: '3px solid #3EDA7D',
  paddingLeft: '6px',
  marginLeft: '7px'
};

const RetirementDivStyle = {
  borderLeft: '3px solid #FFA600',
  paddingLeft: '6px',
  marginLeft: '7px'
};

const PICKER_FILTER = policy =>
  (isPolicyCategory(policy, 'personal') ||
    (isPolicyCategory(policy, 'general') && isPolicySubCategory(policy, 'saving')) ||
    (isPolicyCategory(policy, 'general') && isPolicySubCategory(policy, 'provident')) ||
    (isPolicyCategory(policy, 'company'))
  ) &&
  !isInsuredNotMePolicy(policy);

const SavingInputPanel = forwardRef((props, ref) => {
  const {
    intl,
    userDetails,
    savingData,
    age,
    monthlyIncome,
    showAge,
    savingTerms,
    showAccumulationValueAt,
    returnRate,
    inflationRate,
    currentAsset,
    retireAfter,
    retirementTerms,
    retirementReturnRate,
    retirementInflationRate,
    savingTopUp,
    savingCustomTopUp,
    savingTotalCoverage,
    savingSelectedPolicies,
    updateUserDetails,
    updateSavingSelectedPolicies,
    updateAnalysisInputs,
    policies
  } = props;

  const [inputGroupOption, setInputGroupOption] = useState('Future');

  const stickyInputRef = useRef(null);

  const clientId = JSON.parse(localStorage.getItem('clientId'));

  const currency = getUserCurrency(userDetails);
  const savingTopUpOptions = getSavingTopUpOptions(monthlyIncome);

  const currencyIntl = intl.formatMessage({ id: currency });
  const yearIntl = intl.formatMessage({ id: 'Yrs' });

  const { retirementValueAtRetireAfter } = getCalculatedSavingData(
    userDetails,
    monthlyIncome,
    currentAsset,
    returnRate,
    inflationRate,
    savingTerms,
    savingTotalCoverage,
    showAccumulationValueAt,
    retireAfter,
    retirementReturnRate,
    retirementInflationRate,
    retirementTerms
  );

  const slideTopUp = (event, value) => updateAnalysisInputs({ savingTopUp: value });
  const submitPolicies = policies => {
    updateSavingSelectedPolicies(policies);
    if (clientId) {
      let selectedPolicies = JSON.parse(sessionStorage.getItem("selectedPolicies") || "{}");
      if (selectedPolicies[clientId]) {
        selectedPolicies[clientId]["savingSelectedPolicies"] = policies.map(policy => policy._id);
      } else {
        selectedPolicies[clientId] = { "savingSelectedPolicies": policies.map(policy => policy._id) };
      }
      sessionStorage.setItem("selectedPolicies", JSON.stringify(selectedPolicies));
    }
  }
  const changeAge = event => {
    const value = isNaN(event.target.value) ? 35 : event.target.value ? parseInt(event.target.value) : 0;
    if (event.target.value !== value) {
      updateAnalysisInputs({ age: value });
    }
  };
  const changeShowAge = event => updateAnalysisInputs({ showAge: event.target.checked });
  const changeInputGroupOption = event => setInputGroupOption(event.target.value);
  const changeMonthlyIncome = event => {
    if (event.target.value !== monthlyIncome) {
      const updates = { monthlyIncome: event.target.value };

      if (!clientId) {
        updateUserDetails(updates);
      }

      updateAnalysisInputs(updates);
    }
  };

  const createSimpleOnChangeHandler = (propsKey, storeKey) => event => {
    if (event.target.value !== props[propsKey]) {
      updateAnalysisInputs({ [storeKey]: event.target.value });
    }
  };
  const changeReturnRate = createSimpleOnChangeHandler('returnRate', 'savingReturnRate');
  const changeInflationRate = createSimpleOnChangeHandler('inflationRate', 'savingInflationRate');
  const changeSavingTerms = event => {
    if (event.target.value !== savingTerms) {
      updateAnalysisInputs({
        savingTerms: event.target.value,
        savingShowAccumulationValueAt: event.target.value
      });
    }
  };
  const changeCurrentAsset = event =>
    updateAnalysisInputs({ currentAsset: event.target.value ? parseInt(event.target.value) : 0 });
  const changeRetireAfter = createSimpleOnChangeHandler('retireAfter', 'savingRetireAfter');
  const changeRetirementTerms = createSimpleOnChangeHandler('retirementTerms', 'savingRetirementTerms');
  const changeRetirementReturnRate = createSimpleOnChangeHandler('retirementReturnRate', 'savingRetirementReturnRate');
  const changeRetirementInflationRate = createSimpleOnChangeHandler(
    'retirementInflationRate',
    'savingRetirementInflationRate'
  );
  const changeTopUp = event => {
    if (event.target.value !== savingTopUp) {
      updateAnalysisInputs({ savingTopUp: event.target.value });
    }
  };
  const changeCustomTopUp = event => updateAnalysisInputs({ savingCustomTopUp: event.target.value });
  const open = () => {
    if (stickyInputRef && stickyInputRef.current) {
      stickyInputRef.current.openInput();
    }
  };
  const close = () => {
    if (stickyInputRef && stickyInputRef.current) {
      stickyInputRef.current.closeInput();
    }
  };

  useImperativeHandle(ref, () => ({
    open: open,
    close: close,
    setInputGroupOption: setInputGroupOption
  }));

  useEffect(() => {
    let selectedPolicies = JSON.parse(sessionStorage.getItem("selectedPolicies") || "{}");
    if (selectedPolicies[clientId] && selectedPolicies[clientId]["savingSelectedPolicies"]) {
      let newPolicies = policies.filter(policy => selectedPolicies[clientId]["savingSelectedPolicies"].includes(policy._id));
      updateSavingSelectedPolicies(newPolicies);
    }
  }, [policies]);

  return (
    <AnalysisInputPanel
      className="saving-input-panel analysis-input-panel client-dash-analysis client-analysis-top-bar"
      ref={stickyInputRef}
      inputTitle={
        <Grid container direction="column">
          <Grid item>
            <div style={{ fontSize: 18 }}>
              {`${intl.formatMessage({ id: 'Monthly Saving' })}: `}
              <span className="figureColor">{`${currencyIntl} ${decimalToString(savingTotalCoverage)}`}</span>
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'analysis-existing' })}</Typography>
              </Grid>
              <Grid item>
                <FigureUnitTypo {...formatNumber(savingTotalCoverage - savingTopUp, intl, true)} sameFontSize={true} />
              </Grid>
              <Grid item>
                <Typography>+</Typography>
              </Grid>
              <Grid item>
                <FigureUnitTypo {...formatNumber(savingTopUp, intl, true)} sameFontSize={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      slider={
        <InputPanelSlider
          min={0}
          max={getSavingTopUpMax(monthlyIncome)}
          steps={100}
          value={savingTopUp}
          onChangeCommitted={slideTopUp}
          stopPropagation={true}
          valueLabelFormat={decimalToString}
          getTrackColor={internalValue => {
            const { score } = getSavingToReachData(internalValue + (savingTotalCoverage - savingTopUp), monthlyIncome);

            return getScoreColor(score);
          }}
        />
      }
      detailComponents={
        <div className="analysis-input-groups">
          <PolicyPickerInfo
            figures={[
              {
                value: {
                  value: _.get(savingData, 'count', 0),
                  unit: intl.formatMessage({ id: 'analy-picker-count-unit' })
                },
                label: intl.formatMessage({ id: 'analy-picker-count-label' })
              },
              {
                value: formatNumber(_.get(savingData, 'totalMarketValue', 0), intl, true),
                label: intl.formatMessage({ id: 'Market Value' })
              },
              {
                value: formatNumber(_.get(savingData, 'totalPremium', 0), intl, true),
                label: intl.formatMessage({ id: 'Monthly Premium' })
              }
            ]}
            button={
              <PolicyPickerButton
                selectedPolicies={savingSelectedPolicies}
                filter={PICKER_FILTER}
                onSubmit={submitPolicies}
              />
            }
          />

          <div className="analysis-checkboxes">
            <div>
              <span className="life-policy-protec center-align-label">
                {`${intl.formatMessage({ id: 'show-age' })}: `}
              </span>
              <span className="figureColor center-align-value">
                <AgeInput
                  intl={intl}
                  value={age}
                  label={undefined}
                  onValueChange={changeAge}
                  style={{ margin: 0, padding: 0, width: 'auto' }}
                />
                <FormControlLabel
                  className="use-total-life-checkbox"
                  control={<Checkbox color="primary" checked={showAge} onChange={changeShowAge} value="showAge" />}
                />
              </span>
            </div>
          </div>

          <FormControl className="input-group-option-select form_mr">
            <RadioGroup value={inputGroupOption} onChange={changeInputGroupOption}>
              <FormControlLabel
                className={`target-duration saving-radio-button${inputGroupOption === 'Future' ? ' checked' : ''}`}
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'Accumulation' })}
                value="Future"
              />
              <FormControlLabel
                className={`target-income saving-radio-button${inputGroupOption === 'Retirement' ? ' checked' : ''}`}
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'Retirement' })}
                value="Retirement"
              />
            </RadioGroup>
          </FormControl>

          {inputGroupOption === 'Future' ? (
            <div style={FutureDivStyle}>
              <CustomInputSelect
                className="analysis-input"
                label={intl.formatMessage({ id: 'Monthly income' })}
                onChange={changeMonthlyIncome}
                value={monthlyIncome}
                startAdornmentLabel={currencyIntl}
                selectOptions={INCOME_OPTIONS}
                isDollarValue={true}
                allowCustomValue={true}
                min={0}
                max={INCOME_OPTIONS[INCOME_OPTIONS.length - 1]}
              />
              <div className="analysis-saving-rates-wrapper analysis-saving-rates-wrapper-half">
                <CustomInputSelect
                  className="analysis-input"
                  label={intl.formatMessage({ id: 'Return' })}
                  onChange={changeReturnRate}
                  value={returnRate}
                  endAdornmentLabel="%"
                  selectOptions={RETURN_RATE_OPTIONS}
                  allowCustomValue={true}
                  min={0}
                  max={RETURN_RATE_OPTIONS[RETURN_RATE_OPTIONS.length - 1]}
                  decimalPlaces={2}
                />

                <small className="saving-tab-dash">-</small>
                <CustomInputSelect
                  className="analysis-input"
                  label={intl.formatMessage({ id: 'Inflation' })}
                  onChange={changeInflationRate}
                  value={inflationRate}
                  endAdornmentLabel="%"
                  selectOptions={INFLATION_RATE_OPTIONS}
                  allowCustomValue={true}
                  min={0}
                  max={INFLATION_RATE_OPTIONS[INFLATION_RATE_OPTIONS.length - 1]}
                  decimalPlaces={1}
                />
              </div>
              <CustomInputSelect
                className="analysis-input"
                label={intl.formatMessage({ id: 'Saving Periods' })}
                onChange={changeSavingTerms}
                value={savingTerms}
                endAdornmentLabel={yearIntl}
                selectOptions={SAVING_YEAR_OPTIONS}
                allowCustomValue={true}
                min={1}
                nonZeroInteger
                max={SAVING_YEAR_OPTIONS[SAVING_YEAR_OPTIONS.length - 1]}
              />
              <CurrentAssetInput
                value={currentAsset}
                startAdornmentLabel={currencyIntl}
                onValueChange={changeCurrentAsset}
              />
            </div>
          ) : (
            <div className="analysis-saving-input-wrapper">
              <div className="retirement-saving-wrapper text-center">
                <span className="violet-text">
                  {`${intl.formatMessage({ id: 'retirmentReserve' })} ${retireAfter}${yearIntl}:`}
                  <span className="figureColor">
                    {`${currencyIntl} ${formatNumber(retirementValueAtRetireAfter, intl)}`}
                  </span>
                </span>
                <MoreInfoTrigger
                  titleIntlId={inputGroupOption}
                  contentIntlId="moreInfo_retirementSaving"
                  className="retirement-saving-more-info"
                />
              </div>
              <div style={RetirementDivStyle}>
                <CustomInputSelect
                  className="analysis-input"
                  label={intl.formatMessage({ id: 'Retire after' })}
                  onChange={changeRetireAfter}
                  value={retireAfter}
                  endAdornmentLabel={yearIntl}
                  selectOptions={SAVING_YEAR_OPTIONS}
                  allowCustomValue={true}
                  min={1}
                  nonZeroInteger
                  max={SAVING_YEAR_OPTIONS[SAVING_YEAR_OPTIONS.length - 1]}
                />

                <CustomInputSelect
                  className="analysis-input"
                  label={intl.formatMessage({ id: 'Retire for' })}
                  onChange={changeRetirementTerms}
                  value={retirementTerms}
                  endAdornmentLabel={yearIntl}
                  selectOptions={SAVING_YEAR_OPTIONS}
                  allowCustomValue={true}
                  min={1}
                  nonZeroInteger
                  max={SAVING_YEAR_OPTIONS[SAVING_YEAR_OPTIONS.length - 1]}
                />

                <div className="analysis-saving-rates-wrapper">
                  <CustomInputSelect
                    className="analysis-input"
                    label={intl.formatMessage({ id: 'Retirement Return' })}
                    onChange={changeRetirementReturnRate}
                    value={retirementReturnRate}
                    endAdornmentLabel="%"
                    selectOptions={RETURN_RATE_OPTIONS}
                    allowCustomValue={true}
                    min={0}
                    max={RETURN_RATE_OPTIONS[RETURN_RATE_OPTIONS.length - 1]}
                    decimalPlaces={1}
                  />

                  <CustomInputSelect
                    className="analysis-input"
                    label={intl.formatMessage({ id: 'Retirement Inflation' })}
                    onChange={changeRetirementInflationRate}
                    value={retirementInflationRate}
                    endAdornmentLabel="%"
                    selectOptions={INFLATION_RATE_OPTIONS}
                    allowCustomValue={true}
                    min={0}
                    max={INFLATION_RATE_OPTIONS[INFLATION_RATE_OPTIONS.length - 1]}
                    decimalPlaces={1}
                  />
                </div>
              </div>
            </div>
          )}
          <div>
            <CustomInputSelect
              className="analysis-input analysis-input-top-up"
              label={intl.formatMessage({ id: 'Topup-saving' })}
              onChange={changeTopUp}
              value={savingTopUp}
              startAdornmentLabel={currencyIntl}
              selectOptions={savingTopUpOptions}
              isDollarValue={true}
              allowCustomValue={true}
              min={0}
              max={savingTopUpOptions[savingTopUpOptions.length - 1]}
              style={{ width: '50%' }}
            />

            <CustomTopUpInput
              value={savingCustomTopUp}
              onChange={changeCustomTopUp}
              startAdornmentLabel={currencyIntl}
              style={{ width: '40%' }}
            />

            <div className="analysis-input analysis-input-go" style={{ width: '10%' }}>
              <Button variant="contained" onClick={close} style={{ minWidth: 32 }}>
                {intl.formatMessage({ id: 'Go' })}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
});

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    savingData: state.analysis.savingData,
    savingSelectedPolicies: state.analysis.savingSelectedPolicies,
    age: state.analysis.age,
    monthlyIncome: state.analysis.monthlyIncome,
    showAge: state.analysis.showAge,
    savingTopUp: state.analysis.savingTopUp,
    savingCustomTopUp: state.analysis.savingCustomTopUp,
    savingTerms: state.analysis.savingTerms,
    showAccumulationValueAt: state.analysis.savingShowAccumulationValueAt,
    returnRate: state.analysis.savingReturnRate,
    inflationRate: state.analysis.savingInflationRate,
    currentAsset: state.analysis.currentAsset,
    retireAfter: state.analysis.savingRetireAfter,
    retirementTerms: state.analysis.savingRetirementTerms,
    retirementReturnRate: state.analysis.savingRetirementReturnRate,
    retirementInflationRate: state.analysis.savingRetirementInflationRate,
    savingTotalCoverage: state.analysis.savingTotalCoverage,
    policies: state.policyPicker.policies
  }),
  {
    updateUserDetails,
    updateAnalysisInputs,
    updateSavingSelectedPolicies
  },
  null,
  {
    forwardRef: true
  }
)(SavingInputPanel);

export default injectIntl(container, { withRef: true });
