import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import AccessCodeGate from '../../components/AccessCodeGate/AccessCodeGate';
import './style.css';
import ShareIconButtonn from '../../components/ShareIconButton';
import ShareModal from '../../components/ShareModal';
import NumberFormat from 'react-number-format';
import { weeklyAptCalculator } from './../../utils/weeklyAptCalculator';
import { decimalToString } from './../../utils/formatNumber';
import { TARGET_TYPE } from './../../constants/businessTarget';

const pfpDomain = process.env.PFPLUS_URL;
const shareURL = new URL('/targetsCalculator', pfpDomain).href;

class TargetsCalculatorView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      shareModalOpen: false,
      quarterTarget: 250000,
      key: '',
      typeArray: [],
      quarterTargetType: '',
      MDRT: 'No',
      COT: 'No',
      TOT: 'No',
      avgCaseSize: 8000,
      closingRate: 33,
      weeklyAppt: 0
    };
  }
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  handleModal = () => {
    let { shareModalOpen } = this.state;
    this.setState({
      shareModalOpen: !shareModalOpen
    });
  };
  closingRateChange = e => {
    const { value } = e.target;
    if (parseInt(value.replace(/,/g, '')) > 100) {
      this.setState({ closingRate: 100 });
      this.forceUpdate();
    } else {
      this.setState({ closingRate: value });
    }
  };

  //handle the quarter target value
  handleQuarterTarget = value => {
    this.setState({ quarterTarget: value });
  };

  //handle the avg case size
  handleAvgCaseSize = value => {
    this.setState({ avgCaseSize: value });
  };

  //Handle the quarter target value by MDRT COT TOT
  handleButtonTypeStatus = key => {
    const state = this.state.typeArray;
    const { adviserBusiness } = this.props;
    if (state.indexOf(key) === -1) {
      this.setState({ typeArray: [...this.state.typeArray, key] });
      if (key === 0) {
        this.setState({
          MDRT: 'Yes',
          COT: 'No',
          TOT: 'No',
          quarterTargetType: 'MDRT',
          quarterTarget: TARGET_TYPE.MDRTC / 4
        });
      }
      if (key === 1) {
        this.setState({
          MDRT: 'No',
          COT: 'Yes',
          TOT: 'No',
          quarterTargetType: 'COT',
          quarterTarget: (TARGET_TYPE.MDRTC * 3) / 4
        });
      }
      if (key === 2) {
        this.setState({
          MDRT: 'No',
          COT: 'No',
          TOT: 'Yes',
          quarterTargetType: 'TOT',
          quarterTarget: (TARGET_TYPE.MDRTC * 6) / 4
        });
      }
    } else {
      if (key === 0) {
        if (this.state.MDRT === 'Yes') {
          this.setState({
            MDRT: 'YesP',
            quarterTargetType: '',
            quarterTarget: TARGET_TYPE.MDRTP / 4
          });
        }
        if (this.state.MDRT === 'YesP') {
          this.setState({
            MDRT: 'No',
            quarterTargetType: '',
            quarterTarget: adviserBusiness && adviserBusiness.quarterTarget ? adviserBusiness.quarterTarget : 0
          });
        }
        if (this.state.MDRT === 'No') {
          this.setState({
            MDRT: 'Yes',
            COT: 'No',
            TOT: 'No',
            quarterTargetType: 'MDRT',
            quarterTarget: TARGET_TYPE.MDRTC / 4
          });
        }
      }
      if (key === 1) {
        if (this.state.COT === 'Yes') {
          this.setState({
            COT: 'YesP',
            quarterTargetType: '',
            quarterTarget: (TARGET_TYPE.MDRTP * 3) / 4
          });
        }
        if (this.state.COT === 'YesP') {
          this.setState({
            COT: 'No',
            quarterTargetType: '',
            quarterTarget: adviserBusiness && adviserBusiness.quarterTarget ? adviserBusiness.quarterTarget : 0
          });
        }
        if (this.state.COT === 'No') {
          this.setState({
            MDRT: 'No',
            COT: 'Yes',
            TOT: 'No',
            quarterTargetType: 'MDRT',
            quarterTarget: (TARGET_TYPE.MDRTC * 3) / 4
          });
        }
      }
      if (key === 2) {
        if (this.state.TOT === 'Yes') {
          this.setState({
            TOT: 'YesP',
            quarterTargetType: '',
            quarterTarget: (TARGET_TYPE.MDRTP * 6) / 4
          });
        }
        if (this.state.TOT === 'YesP') {
          this.setState({
            TOT: 'No',
            quarterTargetType: '',
            quarterTarget: adviserBusiness && adviserBusiness.quarterTarget ? adviserBusiness.quarterTarget : 0
          });
        }
        if (this.state.TOT === 'No') {
          this.setState({
            MDRT: 'No',
            COT: 'No',
            TOT: 'Yes',
            quarterTargetType: 'TOT',
            quarterTarget: (TARGET_TYPE.MDRTC * 6) / 4
          });
        }
      }
    }
  };

  render() {
    let { intl, userDetails } = this.props;
    const { quarterTarget, avgCaseSize, closingRate, MDRT, COT, TOT, shareModalOpen } = this.state;

    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid" id="target-container">
              <div className="row">
                <div className="share-button">
                  <div onClick={this.handleModal}>
                    <ShareIconButtonn style={{ color: 'white' }} />
                  </div>
                </div>
                <div className="nav-top-margin-target">
                  <AccessCodeGate
                    intl={intl}
                    allowed={userDetails && userDetails._id}
                    accessCodes={['oneteam', 'pfplus', 'lucky', 'Lucky', 'AD']}
                  >
                    <div className="card">
                      <div className="card-wrapper analysis-expansion-element" id="target-card">
                        <div className="icon-mice">
                          <img alt="" src="img/logo_kyc.png" />
                        </div>
                        <div className="caseclose-form" style={{ marginTop: '45px' }}>
                          <h4>{intl.formatMessage({ id: 'Business Target' })}</h4>
                          <p>{intl.formatMessage({ id: 'BizTarget_desc' })}</p>
                          <div className="form-group form-gorup-margin">
                            <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                              <label className="control-label adv_biz_modal_key quarterlabel">
                                {intl.formatMessage({ id: 'Quarter Target' })} :
                              </label>
                            </div>
                            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                              <NumberFormat
                                className="form-control  number-input"
                                style={{ fontSize: '130%', color: '#3966f8' }}
                                thousandSeparator={true}
                                // onValueChange={handleQuarterTarget()}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.handleQuarterTarget(value);
                                }}
                                value={quarterTarget}
                                inputMode="decimal"
                                pattern="[0-9]*"
                              />
                            </div>
                          </div>
                          <div className="quarter--Target-option">
                            <div className="form-group">
                              <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key ">
                                <Button
                                  variant={MDRT === 'Yes' || MDRT === 'YesP' ? 'contained' : 'outlined'}
                                  className={
                                    MDRT === 'Yes' || MDRT === 'YesP'
                                      ? 'plan-type-select-button selected source-button-responsive'
                                      : 'plan-type-select-button source-button-responsive'
                                  }
                                  key={0}
                                  onClick={() => this.handleButtonTypeStatus(0)}
                                >
                                  {intl.formatMessage({ id: 'MDRT' })}
                                </Button>
                              </div>
                              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                                <span className="blue-color" style={{ fontWeight: 'bold' }}>
                                  {MDRT === 'Yes' || MDRT === 'No'
                                    ? intl.formatMessage({ id: 'C_Target' })
                                    : intl.formatMessage({ id: 'P_Target' })}
                                </span>{' '}
                                {MDRT === 'Yes' || MDRT === 'No'
                                  ? decimalToString(TARGET_TYPE.MDRTC)
                                  : decimalToString(TARGET_TYPE.MDRTP)}
                              </div>
                            </div>
                            <div className="form-group" style={{ marginTop: '-25px' }}>
                              <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
                                <Button
                                  variant={COT === 'Yes' || COT === 'YesP' ? 'contained' : 'outlined'}
                                  className={
                                    COT === 'Yes' || COT === 'YesP'
                                      ? 'plan-type-select-button selected source-button-responsive'
                                      : 'plan-type-select-button source-button-responsive'
                                  }
                                  key={1}
                                  onClick={() => this.handleButtonTypeStatus(1)}
                                >
                                  {intl.formatMessage({ id: 'COT' })}
                                </Button>
                              </div>
                              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                                <span className="blue-color" style={{ fontWeight: 'bold' }}>
                                  {COT === 'Yes' || COT === 'No'
                                    ? intl.formatMessage({ id: 'C_Target' })
                                    : intl.formatMessage({ id: 'P_Target' })}
                                </span>{' '}
                                {COT === 'Yes' || COT === 'No'
                                  ? decimalToString(TARGET_TYPE.MDRTC * 3)
                                  : decimalToString(TARGET_TYPE.MDRTP * 3)}
                              </div>
                            </div>
                            <div className="form-group" style={{ marginTop: '-25px' }}>
                              <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key">
                                <Button
                                  variant={TOT === 'Yes' || TOT === 'YesP' ? 'contained' : 'outlined'}
                                  className={
                                    TOT === 'Yes' || TOT === 'YesP'
                                      ? 'plan-type-select-button selected source-button-responsive'
                                      : 'plan-type-select-button source-button-responsive'
                                  }
                                  key={2}
                                  onClick={() => this.handleButtonTypeStatus(2)}
                                >
                                  {intl.formatMessage({ id: 'TOT' })}
                                </Button>
                              </div>
                              <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                                <span className="blue-color" style={{ fontWeight: 'bold' }}>
                                  {TOT === 'Yes' || TOT === 'No'
                                    ? intl.formatMessage({ id: 'C_Target' })
                                    : intl.formatMessage({ id: 'P_Target' })}
                                </span>{' '}
                                {TOT === 'Yes' || TOT === 'No'
                                  ? decimalToString(TARGET_TYPE.MDRTC * 6)
                                  : decimalToString(TARGET_TYPE.MDRTP * 6)}
                              </div>
                            </div>
                          </div>
                          <div className="form-group" style={{ marginTop: '-26px' }}>
                            <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                              <label className="control-label adv_biz_modal_key quarterlabel">
                                {intl.formatMessage({ id: 'avg_case_size' })} :
                              </label>
                            </div>
                            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                              <NumberFormat
                                className="form-control  number-input"
                                style={{ fontSize: '130%', color: '#3966f8' }}
                                thousandSeparator={true}
                                // onValueChange={handleAvgCaseSize}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.handleAvgCaseSize(value);
                                }}
                                value={avgCaseSize}
                                inputMode="decimal"
                                pattern="[0-9]*"
                              />
                            </div>
                          </div>
                          <div className="form-group" style={{ marginTop: '-26px' }}>
                            <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                              <label className="control-label adv_biz_modal_key quarterlabel">
                                {intl.formatMessage({ id: 'Closing Rate' })} :
                              </label>
                            </div>
                            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                              <NumberFormat
                                className="form-control  target--input"
                                style={{ fontSize: '130%', color: '#3966f8' }}
                                thousandSeparator={true}
                                name="closingRate"
                                onChange={this.closingRateChange}
                                value={closingRate}
                                inputMode="decimal"
                                pattern="[0-9]*"
                              />
                              <span>{'%'}</span>
                            </div>
                          </div>
                          <div style={{ marginTop: '-26px' }}>
                            <div className="col-md-12 col-xs-12 text-center case-minWidth">
                              <label className="control-label target-calc-result">
                                {intl.formatMessage({ id: 'Weekly Appt' })}
                              </label>
                            </div>
                            <div className="col-md-12 col-xs-12 text-center input__spacing">
                              <span className="figureColor weekly__apt target-calc-result ">
                                {weeklyAptCalculator(
                                  parseFloat(quarterTarget),
                                  parseFloat(avgCaseSize),
                                  parseFloat(closingRate) / 100
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <img
                          className="logo"
                          src="https://www.portfoplus.com/adviser/img/AdvBanner-1.png"
                          alt="cheque-banner"
                          onClick={() => window.open('http://bit.ly/PFP_Facebook')}
                        />
                      </div>
                    </div>
                  </AccessCodeGate>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShareModal open={shareModalOpen} onClose={this.handleModal} shareURL={shareURL} {...this.props} />
      </div>
    );
  }
}

export default TargetsCalculatorView;
