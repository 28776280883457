import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import '../Template.scss';
import './TemplateMenu.scss';

class TemplateMenu extends React.PureComponent {
  render() {
    return (
      <Menu
        open={this.props.dropDownAnchor !== null}
        anchorEl={this.props.dropDownAnchor}
        keepMounted
        onClose={this.props.onDropDownClose}
      >
        <MenuItem onClick={this.props.openDeleteModal}>
          <DeleteIcon className="delete-icon" />
          <div className="delete-all">{this.props.intl.formatMessage({ id: 'delete-all-template' })}</div>
        </MenuItem>
      </Menu>
    );
  }
}

export default injectIntl(TemplateMenu);
