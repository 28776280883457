import React from 'react';
import AdviserInfoPageCardBackground2 from '../AdviserInfoPageCardBackground/AdviserInfoPageCardBackground2';
import { Grid, Typography } from '@material-ui/core';

const ContainerStyle = {
  width: '100%',
  // height: 50,
  position: 'relative'
};

const TypoContainerStyle = {
  zIndex: 1,
  transform: 'translate3d(0, -3px, 0)'
};

const AdviserInfoCardHeader = props => {
  const { titleColor, label, labelStyle, hideBackground, ...rest } = props;
  return (
    <Grid item style={ContainerStyle}>
      {/*<AdviserInfoPageCardBackground color={backgroundColor} radius={35} direction="right" topOffset={-4} {...rest} />*/}
      {!hideBackground && <AdviserInfoPageCardBackground2 {...rest} />}
      <Grid container alignItems="baseline" style={TypoContainerStyle}>
        <Grid item>
          <div
            style={{
              backgroundColor: titleColor,
              width: 10,
              height: 10,
              borderRadius: '50%',
              marginRight: 10,
              marginBottom: 2
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            style={{
              color: titleColor,
              fontWeight: 500,
              fontSize: '150%',
              marginTop: 16,
              ...labelStyle
            }}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdviserInfoCardHeader;
