import { connect } from 'react-redux';
import component from './component';
import { updateStepIndex, endGuideline } from '../../../store/guideline/duck';

import { injectIntl } from 'react-intl';
const container = connect(
  // Map state to props
  state => ({
    run: state.guideline.run,
    steps: state.guideline.steps,
    stepIndex: state.guideline.stepIndex,
    location: state.guideline.location,
    showProgress: state.guideline.showProgress,
    tooltipComponent: state.guideline.tooltipComponent
  }),
  // Map actions to props
  {
    updateStepIndex,
    endGuideline
  }
)(component);

export default injectIntl(container);
