import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import { Button, Typography } from '@material-ui/core';
import { getSearchObject } from '../../../utils/router';
import { cancelSubscription, removeUnpaidSubscription, endSubscriptionNow } from '../../../store/userSubscription/duck';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';
import CurrentCoreSubscriptionPricingCard from './CurrentCoreSubscriptionPricingCard/CurrentCoreSubscriptionPricingCard';
import CancelCurrentSubscriptionDialog from '../../../components/SubscriptionComponents/CancelCurrentSubscriptionDialog/CancelCurrentSubscriptionDialog';
import { INIT } from '../../../constants/phase';
import { getTeams } from '../../../store/team/duck';
import { shouldShowTeamPlan } from '../../../utils/team';
import { updateUserDetails } from '../../../store/user/duck';
import { hasCampaignCode } from '../../../utils/user';
import NativeOrWeb from '../../../utils/native';
import { UserSubscription } from '../../../utils/user-subscription';
import ChangeSubscriptionPlan from '../ChangeSubscriptionPlan/ChangeSubscriptionPlan';
import { getPaymentMethodApi, getCustomerCreditsApi } from '../../../store/stripe/api';
import './SubscriptionContent.scss';

const TeamPlanTypoStyle = {
  fontSize: '150%',
  margin: '12px 0 6px'
};

const SubscriptionContent = props => {
  const {
    intl,
    userDetails,
    stripePromise,
    userSubscription,
    history,
    cancelSubscription,
    endSubscriptionNow,
    teams,
    getTeamsPhase,
    getTeams,
    updateUserDetails
  } = props;

  const [loading, setLoading] = useState(false);
  const [cancelCurrentDialog, setCancelCurrentDialog] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [credits, setCredits] = useState(0);
  const [changeSubscriptionPlanOpen, setChangeSubscriptionPlanOpen] = useState(false);
  const [defaultCoupon, setDefaultCoupon] = useState("");

  const userSubscriptionO = new UserSubscription(userSubscription);
  const currentCoreSubscription = userSubscriptionO.getCurrentCoreSubscription();
  const isTeam = shouldShowTeamPlan(teams);
  const searchObject = getSearchObject(history);
  const openCancelCurrentDialog = () => setCancelCurrentDialog(true);
  const closeCancelCurrentDialog = () => setCancelCurrentDialog(false);
  const isNative = NativeOrWeb.isNativePlatform();

  const cancel = async subscription => {
    setLoading(true);
    await cancelSubscription(subscription._id);
    setLoading(false);
  };

  const endNow = async subscription => {
    await endSubscriptionNow(subscription._id);
  };

  useEffect(() => {
    if (getTeamsPhase === INIT) {
      getTeams();
    }
  }, [getTeamsPhase, getTeams]);

  useEffect(() => {
    getCustomerCredits();
  }, []);

  useEffect(() => {
    if (userDetails) {
      const searchObject = getSearchObject(history);
      // add campaignCode if it is specified in the url
      if (searchObject.campaignCode) {
        const campaignCodes = userDetails.campaignCodes || [];
        if (!hasCampaignCode(userDetails, [], searchObject.campaignCode)) {
          updateUserDetails({ campaignCodes: [...campaignCodes, { text: searchObject.campaignCode }] });
        }
      }

      if (searchObject.coupon) {
        setDefaultCoupon(searchObject.coupon);
      }

      if (searchObject.openChangeSubscriptionPlan === "true") {
        openChangeSubscriptionPlan();
      } else {
        let state = history.location.state;
        if (state) {
          let newState = { ...history.location.state };
          if (state.openChangeSubscriptionPlan) {
            openChangeSubscriptionPlan();
            newState = { ...history.location.state, openChangeSubscriptionPlan: false };
          }
          if (!searchObject.coupon && state.coupon) {
            setDefaultCoupon(state.coupon);
          }
          history.push({ ...history.location, state: newState });
        }
      }
    }
  }, [history, updateUserDetails, userDetails]);

  async function fetchPaymentMethod(stripeSubscriptionId) {
    let result = await getPaymentMethodApi(stripeSubscriptionId);
    if (result.defaultPaymentMethod) {
      setPaymentMethod(result.defaultPaymentMethod);
    } else {
      setPaymentMethod(null);
    }
  }

  async function getCustomerCredits() {
    let result = await getCustomerCreditsApi();
    if (result.success) {
      setCredits(result.credits);
    }
  }

  async function openChangeSubscriptionPlan() {
    getCustomerCredits();
    if (currentCoreSubscription.stripeSubscriptionId) {
      await fetchPaymentMethod(currentCoreSubscription.stripeSubscriptionId);
    }
    setChangeSubscriptionPlanOpen(true);
  }

  if (currentCoreSubscription?.subscriptionPlan.isTrial) {
    let trialInterval = currentCoreSubscription.subscriptionPlan.trialInterval;
    let trialIntervalCount = currentCoreSubscription.subscriptionPlan.trialIntervalCount;
    if (moment(currentCoreSubscription.expiredAt).diff(moment(currentCoreSubscription.startedAt), trialInterval) === trialIntervalCount) {
      var trialing = true;
      if (!defaultCoupon) {
        setDefaultCoupon("EB95");
      }
    }
  } else {
    var trialing = false;
  }

  return (
    <>
      <CancelCurrentSubscriptionDialog
        subscription={currentCoreSubscription}
        open={cancelCurrentDialog}
        onClose={closeCancelCurrentDialog}
        onConfirm={() => cancel(currentCoreSubscription)}
      />

      <ChangeSubscriptionPlan
        intl={intl}
        userDetails={userDetails}
        stripePromise={stripePromise}
        open={changeSubscriptionPlanOpen}
        onClose={() => setChangeSubscriptionPlanOpen(false)}
        currentSubscription={currentCoreSubscription}
        currentSubscriptionPlan={currentCoreSubscription?.subscriptionPlan}
        trialing={trialing}
        defaultPaymentMethod={paymentMethod}
        fetchPaymentMethod={fetchPaymentMethod}
        currentCredits={credits}
        getCustomerCredits={getCustomerCredits}
        defaultCoupon={defaultCoupon}
      />

      <div className="subscription-content">
        <LoadingBackdrop open={loading} />

        <div className="current-subscriptions container">
          {credits > 0 && <Typography variant="h5">{intl.formatMessage({ id: 'credits-will-be-applied' }, { credits: credits / 100 })}</Typography>}
          {currentCoreSubscription && (
            <>
              <Typography variant="h5" color="textSecondary" align="center" className="page-title">
                {intl.formatMessage({ id: 'sub-current' })}
              </Typography>

              <CurrentCoreSubscriptionPricingCard
                userDetails={userDetails}
                stripePromise={stripePromise}
                header={
                  isTeam && <Typography style={TeamPlanTypoStyle}>{intl.formatMessage({ id: 'team-plan' })}</Typography>
                }
                hideAmount={true}
                hideAmountDue={!!isTeam}
                subscription={currentCoreSubscription}
                fullWidth={false}
                defaultHideCardDetails={false}
              />

              <Button
                onClick={openChangeSubscriptionPlan}
                variant="contained"
                color="primary"
                style={{ width: isMobile ? "80%" : "40%", marginTop: 10 }}
              >
                {trialing ?
                  currentCoreSubscription.subscriptionPlan.interval === "year" ?
                    intl.formatMessage({ id: 'start-annual-plan-early' }) :
                    intl.formatMessage({ id: 'start-monthly-plan-early' }) :
                  intl.formatMessage({ id: 'change-subscription-plan' })
                }
              </Button>

              {searchObject.endNow === 'true' && currentCoreSubscription.paymentChannel === 'others' && (
                <Button
                  onClick={() => endNow(currentCoreSubscription)}
                  variant="contained"
                  color="primary"
                  style={{ width: isMobile ? "80%" : "40%", marginTop: 10 }}
                >
                  {intl.formatMessage({ id: 'end-subscription-immediately' })}
                </Button>
              )}

              {currentCoreSubscription.paymentChannel === "stripe" && currentCoreSubscription.stripeSubscriptionId && (
                <Button
                  onClick={currentCoreSubscription.autoExtend ? openCancelCurrentDialog : undefined}
                  style={{ textDecoration: 'underline', color: currentCoreSubscription.autoExtend ? '#b7b7b7' : '#e0e0e0', marginTop: 10 }}
                  disabled={!currentCoreSubscription.autoExtend}
                >
                  {intl.formatMessage({ id: currentCoreSubscription.autoExtend ? 'cancel-sub-button' : 'cancelled-sub' })}
                </Button>
              )}
            </>
          )}

          <div className="expand"></div>

          {isNative &&
            <Fragment>
              <Button
                onClick={() => {
                  history.push('/account_deletion')
                }} style={{ textDecoration: 'underline', color: '#b7b7b7' }}>
                {intl.formatMessage({ id: 'account-deletion-menu' })}
              </Button>
            </Fragment>
          }
        </div>
      </div>
    </>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    userSubscription: state.userSubscription.userSubscription,
    subscriptionPlans: state.subscriptionPlan.subscriptionPlans,
    upgradeSubscriptionPlansFetched: state.subscriptionPlan.upgradeSubscriptionPlansFetched,
    teams: state.team.teams,
    getTeamsPhase: state.team.getTeamsPhase
  }),
  {
    cancelSubscription,
    removeUnpaidSubscription,
    endSubscriptionNow,
    getTeams,
    updateUserDetails
  }
)(SubscriptionContent);

export default injectIntl(withRouter(container));
