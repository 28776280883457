import React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

class EditIconButton extends React.Component {
  render() {
    const { className, ariaLabel, handleClick, fetchOtherClass, changeClass } = this.props;
    return (
      <IconButton
        aria-label={ariaLabel ? ariaLabel : 'Edit'}
        color="primary"
        className={
          fetchOtherClass
            ? className
            : changeClass
            ? 'smart-edit-icon-button'
            : 'edit-icon-button ' + (className ? className : '')
        }
        onClick={handleClick}
      >
        <EditIcon />
      </IconButton>
    );
  }
}

export default EditIconButton;
