import React, { PureComponent } from 'react';
import Switch from '@material-ui/core/Switch';
import '../styles.scss';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import IconToast from '../../../components/NewToast';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

class ShareToView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      deleteId: ''
    };
    this.closeModal = this.closeModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getShareToData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.togglePhase === true || nextProps.deletePhase === true) {
      this.props.getShareToData();
      this.props.getUser();
    }
    if (nextProps.togglePhase === true) {
      if (this.state.isActive) {
        toast.info(IconToast('info', intl.formatMessage({ id: 'updated_sharing_record' })), { className: 'new-toast' });
      }
      if (!this.state.isActive) {
        toast.info(IconToast('info', intl.formatMessage({ id: 'updated_sharing_record_disbaled' })), {
          className: 'new-toast'
        });
      }
    }
    if (nextProps.deletePhase === true) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Deleted_sharing_record' })), { className: 'new-toast' });
    }
  }

  //to disable the share voew detail
  onToggle(v) {
    var postData = {};
    postData.shareId = v._id;
    postData.isActive = !v.isActive;
    this.setState({ isActive: !v.isActive });
    this.props.updateShareToList(postData);
  }

  openModal(data) {
    this.setState({
      deleteId: data,
      modalIsOpen: true
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  //To delete the share view records
  delete() {
    const data = this.state.deleteId;
    this.props.deleteShareList(data);
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { shareToData, intl, userDetails } = this.props;
    return (
      <div>
        {this.props.deletePhase === 'loading' && <LoadingBackdrop open={true} />}

        <div id="wrapper" className="share__page">
          <div className="hareContainer" style={{ marginTop: userDetails.userType === 'User' ? '0px' : '50px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
                width: '100%',
                borderBottom: '1px solid #dddddd'
              }}
            >
              <div style={{ marginTop: '5px', marginBottom: '8px' }}>
                <AladdinHint title={intl.formatMessage({ id: 'share-to-hints' })} />
              </div>
            </div>
          </div>

          <div>
            <div>
              <div style={{ paddingBottom: 'calc(55px + var(--bottom-padding))' }}>
                <div className="JStableOuter share">
                  <table className="share-table share-table-to">
                    <thead>
                      <tr className="shareDiv">
                        <th className="share-to-table-email-col">{intl.formatMessage({ id: 'Email' })}</th>
                        <th className="share-to-table-type-col">{intl.formatMessage({ id: 'Type' })}</th>
                        <th className="share-to-table-enable-col">{intl.formatMessage({ id: 'Enable' })}</th>
                        <th className="share-to-table-delete-col">{intl.formatMessage({ id: 'Delete' })}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shareToData &&
                        shareToData.length > 0 &&
                        shareToData.map((val, index) => {
                          return (
                            <tr key={index} className="shareDiv">
                              <td className="share-to-table-email-col">{val.email}</td>
                              <td className="share-to-table-type-col">
                                {val.receiverType === 'Advisor'
                                  ? intl.formatMessage({ id: 'Adviser' })
                                  : val.receiverType === 'User'
                                  ? intl.formatMessage({ id: 'Reference' })
                                  : val.receiverType === 'Emergency'
                                  ? intl.formatMessage({ id: 'Emergency' })
                                  : intl.formatMessage({
                                      id: 'Family'
                                    })}
                              </td>
                              <td className="share-to-table-enable-col">
                                <Switch
                                  color="primary"
                                  key={index}
                                  checked={val.isActive}
                                  onChange={this.onToggle.bind(this, val, index)}
                                  className={val.isActive ? 'material-ui-toggle' : ''}
                                />
                              </td>
                              <td className="share-to-table-delete-col">
                                <img
                                  className="forwardArrow"
                                  src="img/bin_blue.png"
                                  alt=""
                                  onClick={this.openModal.bind(this, val._id)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <Dialog
                    open={this.state.modalIsOpen}
                    onClose={this.closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete' })}</DialogTitle>
                    <DialogContent>
                      {intl.formatMessage({
                        id: 'Are you sure you want to delete this?'
                      })}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeModal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.delete.bind(this)} color="primary" autoFocus>
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShareToView;
