import { connect } from 'react-redux';
import { getBusinessSummary } from '../../../store/summary/duck';
import {
  getAdviserDashboard,
  connectedUserPolicies,
  policyCount,
  adviserBusinessAnalysis,
  getDuePolicy,
  fetchFigureAdviser,
  updateFigureAdviser,
  updateAdviserBusiness,
  fetchAdviserBusiness,
  fetchAdviserTeamDetail
} from '../../../store/adviserDashboard/duck';
import { handleSignOut, updateUserDetails, defaultMessages, updateLoginFetched } from '../../../store/user/duck';
import { selectedLocale } from '../../../store/locale/action';
import { getClient, submitClient, updateClientData } from '../../../store/client/duck';
import BusinessDashboardComponent from './component';

import { injectIntl } from 'react-intl';
import { endGuideline, runGuideline } from '../../../store/guideline/duck';
import { initTrackingPhase } from '../../../store/client/duck';
import { getAssistant } from '../../../store/assistant/duck';
import { toggleDialog } from '../../../store/control/duck';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';
import { adviserAllModalDone } from '../../../store/notificationCenter/duck';

const BusinessDashboardContainer = connect(
  // Map state to props
  state => ({
    opportunityCount: state.adviserDashboard.opportunityCount,
    opportunityBizVol: state.adviserDashboard.opportunityBizVol,
    adjustedBusinessVol: state.adviserDashboard.adjustedBusinessVol,
    activeTrackRecord: state.adviserDashboard.activeTrackRecord,
    totalBusinessVol: state.adviserDashboard.totalBusinessVol,
    userDetails: state.user.userDetails,
    defaultMessage: state.user.defaultMessage,
    businessSummaryData: state.summary.businessSummaryData,
    businessSummaryPhase: state.summary.businessSummaryPhase,
    dashboardCategoryData: state.policy.dashboardCategoryData,
    averageCoverageData: state.adviserDashboard.averageCoverageData,
    averagePremiumData: state.adviserDashboard.averagePremiumData,
    //GetConnectedUsers: state.adviserDashboard.GetConnectedUsers,
    getUsersGender: state.adviserDashboard.getUsersGender,
    getActiveReminder: state.adviserDashboard.getActiveReminder,
    getClientStatusData: state.adviserDashboard.getClientStatusData,
    GetConnectedUsersBirthday: state.adviserDashboard.GetConnectedUsersBirthday,
    GetUsersPolicyDueData: state.adviserDashboard.GetUsersPolicyDueData,
    GetUsersWithoutPolicyData: state.adviserDashboard.GetUsersWithoutPolicyData,
    noPolicyPhase: state.adviserDashboard.noPolicyPhase,
    policiesData: state.adviserDashboard.policiesData,
    policyCountData: state.adviserDashboard.policyCountData,
    newReferralCount: state.referral.newReferralCount,
    updateFigureAdviserPhase: state.adviserDashboard.updateFigureAdviserPhase,
    getFigureAdviserPhase: state.adviserDashboard.getFigureAdviserPhase,
    figureAdviserData: state.adviserDashboard.figureAdviserData,
    getClientSavingILASPolicyData: state.adviserDashboard.getClientSavingILASPolicyData,
    PastBirthdayOfUsers: state.adviserDashboard.PastBirthdayOfUsers,
    updateQuarterTargetPhase: state.adviserDashboard.updateQuarterTargetPhase,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    getAdviserTeamDetail: state.adviserDashboard.getAdviserTeamDetail,
    exchangeRate: state.user.exchangeRate,
    GetConnectedUsersDiscCount: state.adviserDashboard.GetConnectedUsersDiscCount,
    clientData: state.client.clientData,
    enableTrackingPhase: state.client.enableTrackingPhase,
    policyDueData: state.adviserDashboard.policyDueData,
    subscriptions: state.userSubscription.subscriptions,
    clientTagUsage: state.adviserDashboard.clientTagUsage,
    clientDetails: state.client.clientDetails,
    updateFactSheetPhase: state.client.updateFactSheetPhase,
    // assistantUpdating:
    //   state.assistant.updating ||
    //   state.client.fetchClientPhase === LOADING ||
    //   state.adviserDashboard.getAdviserDashboardPhase === LOADING ||
    //   state.adviserDashboard.policyDuePhase === LOADING,
    assistant: state.assistant.formattedAssistant,
    assistantNeedUpdate: state.assistant.needUpdate,
    loginFetched: state.user.loginFetched,
    shownAdviserHelp: state.user.shownAdviserHelp
  }),
  // Map actions to props
  {
    getBusinessSummary,
    getAdviserDashboard,
    adviserBusinessAnalysis,
    connectedUserPolicies,
    policyCount,
    getDuePolicy,
    handleSignOut,
    updateUserDetails,
    updateFigureAdviser,
    fetchFigureAdviser,
    defaultMessages,
    selectedLocale,
    updateAdviserBusiness,
    fetchAdviserBusiness,
    fetchAdviserTeamDetail,
    runGuideline,
    initTrackingPhase,
    submitClient,
    endGuideline,
    updateClientData,
    getAssistant,
    getClient,
    toggleDialog,
    renderHeaderButton,
    adviserAllModalDone,
    updateLoginFetched
  }
)(BusinessDashboardComponent);

export default injectIntl(BusinessDashboardContainer);
