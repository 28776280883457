export const onKeyDownNumberOnly = allowDecimal => e => {
  if (
    !(
      (e.keyCode > 95 && e.keyCode < 106) ||
      (e.keyCode > 47 && e.keyCode < 58) ||
      e.keyCode === 8 ||
      (allowDecimal && e.keyCode === 190)
    )
  ) {
    e.preventDefault();
  }
};
