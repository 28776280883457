import { Network } from '@capacitor/network';
import store from '../../store';
import { toggleDialog } from '../../store/control/duck';
import { Capacitor } from '@capacitor/core';

if (Capacitor.isNativePlatform()) {
  const toggle = status => store.dispatch(toggleDialog('nativeNetworkOffline', status.connected ? false : {}));

  Network.getStatus().then(toggle);

  Network.addListener('networkStatusChange', toggle);
}
