import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ShortSubscriptions } from '../../../utils/user-subscription';
import _ from 'lodash';
import './BlockingOverlay.scss';
import { toggleProDialog } from '../../../store/userSubscription/duck';

class BlockingOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRenderText: {}
    };
    this.childRefs = {};
  }

  componentDidMount() {
    const { renderText } = this.props;
    const { shouldRenderText } = this.state;
    let nextShouldRenderText = {};
    let changed = false;
    for (let [childIndex, childNode] of Object.entries(this.childRefs)) {
      let shouldRender = renderText || (childNode.offsetWidth > 150 && childNode.offsetHeight > 40);
      if (!shouldRenderText[childIndex] || shouldRenderText[childIndex] !== shouldRender) {
        changed = true;
      }
      nextShouldRenderText[childIndex] = shouldRender;
    }

    if (changed) {
      this.setState({ shouldRenderText: nextShouldRenderText });
    }
  }

  render() {
    const {
      intl,
      subscriptions,
      children,
      parentStyle,
      overlayStyle,
      toggleProDialog,
      show,
      renderText,
      isStrong,
      ...rest
    } = this.props;
    const { shouldRenderText } = this.state;

    const finalShow =
      show !== undefined
        ? show
        : !new ShortSubscriptions(subscriptions).containTypedSubscription({
            type: 'core',
            nicknames: ['Pro', 'Ultra']
          });

    const childrenWithProps = React.Children.map(children, (child, childIndex) => {
      // console.log(child)

      if (typeof child.type === 'function') {
        child = <div>{child}</div>;
      }

      let backdrop = (
        <div
          key="backdrop"
          className={`blocking-overlay${isStrong ? ' strong' : ''}`}
          style={overlayStyle}
          onClick={() => toggleProDialog(true)}
          {...rest}
        >
          <div className="position-wrapper">
            <div className="content-wrapper">
              {shouldRenderText && shouldRenderText[childIndex] !== false && (
                <div className="content">{intl.formatMessage({ id: 'block-overlay-msg' })}</div>
              )}
            </div>
          </div>
        </div>
      );

      return React.cloneElement(child, {
        ref: node => {
          if (node) {
            this.childRefs[childIndex] = node;
          }
        },
        style: _.merge(child.props.style, { position: 'relative' }, parentStyle),
        children:
          finalShow && child.props.children
            ? Array.isArray(child.props.children)
              ? [backdrop].concat(child.props.children)
              : [backdrop, child.props.children]
            : child.props.children
      });
    });

    return childrenWithProps;
  }
}

const container = connect(
  // Map state to props
  state => ({
    subscriptions: state.userSubscription.subscriptions
  }),
  // Map actions to props
  {
    toggleProDialog
  },
  // mergeProps
  null,
  // options
  {}
)(BlockingOverlay);

export default injectIntl(container);
