import React from 'react';
import { injectIntl } from 'react-intl';
import {
  Container,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import './EmergencyInfo.scss';

const renderCitiesString = (intl, countryIntlIds) =>
  countryIntlIds.map(id => intl.formatMessage({ id: id })).join(', ');

const SubHeader = props => {
  const { title, hint } = props;

  return (
    <ListSubheader component="div" dense={true} disableGutters={true} disableSticky={true}>
      <ListItemText
        primary={title}
        secondary={hint}
        primaryTypographyProps={{
          color: 'primary'
        }}
      />
    </ListSubheader>
  );
};

const PhoneEntry = props => {
  const { title, phoneNumber } = props;

  const call = () => window.open(`tel://${phoneNumber}`.replace(/ /g, ''), '_blank');

  return (
    <ListItem component="div" ContainerComponent="div" dense={true} disableGutters={true} divider={true}>
      <ListItemText primary={title} secondary={phoneNumber} />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={call}>
          <PhoneRoundedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const EmergencyInfo = props => {
  const { intl } = props;

  const formatInsurerLine = (shortName, intlId) =>
    `${shortName} ${intl.locale !== 'en' ? intl.formatMessage({ id: intlId }) : ''}`;

  return (
    <Container className="emergency-info">
      <div className="text-center">
        <img className="image1" src="img/emergency_detail.png" alt="" />
      </div>

      <List component="div" dense={true} disablePadding={true}>
        <SubHeader title={intl.formatMessage({ id: 'Emergency Hotline' })} />
        <PhoneEntry
          title={intl.formatMessage({ id: 'HKGov Assistance to HK Residents No.' })}
          phoneNumber="+852 1868"
        />
        <PhoneEntry
          title={intl.formatMessage({ id: 'Chinese citizens Global Emergency No.' })}
          phoneNumber="+86 1012308"
        />
      </List>

      <List component="div" dense={true} disablePadding={true}>
        <SubHeader
          title={intl.formatMessage({ id: 'emer-global-sup' })}
          hint={intl.formatMessage({ id: 'emer-global-sup-hint' })}
        />
        <PhoneEntry title={intl.formatMessage({ id: 'ipa' })} phoneNumber="+852 2862 0123" />
        <PhoneEntry title={intl.formatMessage({ id: 'sos-ltd' })} phoneNumber="+852 2528 9900" />
        <PhoneEntry title={intl.formatMessage({ id: 'aig-travel-guard' })} phoneNumber="+852 3516 8699" />
      </List>

      <List component="div" dense={true} disablePadding={true}>
        <SubHeader title={intl.formatMessage({ id: 'Major cities emergency No.' })} />
        <PhoneEntry
          title={renderCitiesString(intl, ['Hong Kong', 'Singapore', 'Macau', 'Malaysia', 'United Kingdom'])}
          phoneNumber="999"
        />
        <PhoneEntry title={renderCitiesString(intl, ['China', 'Taiwan', 'Japan'])} phoneNumber="110" />
        <PhoneEntry title={renderCitiesString(intl, ['Thailand', 'Philippines', 'Canada', 'USA'])} phoneNumber="911" />
        <PhoneEntry
          title={renderCitiesString(intl, [
            'Germany',
            'France',
            'Italy',
            'Switzerland',
            'Spain',
            'Australia',
            'New Zealand',
            'Korea'
          ])}
          phoneNumber="112"
        />
        {/* <PhoneEntry title={renderCitiesString(intl, ['New Zealand'])} phoneNumber="111" /> */}
      </List>

      <List component="div" dense={true} disablePadding={true}>
        <SubHeader title={intl.formatMessage({ id: 'Travel Insurance emergency No.' })} />
        <PhoneEntry title={formatInsurerLine('AIA', 'AIA')} phoneNumber="+852 2200 6399" />
        <PhoneEntry title={formatInsurerLine('AIG', 'AIG')} phoneNumber="+852 3516 8699" />
        <PhoneEntry title={formatInsurerLine('Allied World', 'AlliedWorld')} phoneNumber="+852 2765 6700" />
        <PhoneEntry title={formatInsurerLine('AXA', 'AXA')} phoneNumber="+852 2861 9285" />
        <PhoneEntry title={formatInsurerLine('Blue Cross', 'Bluecross')} phoneNumber="+852 3608 6083" />
        <PhoneEntry title={formatInsurerLine('BOC', 'BOC')} phoneNumber="+852 2861 9235" />
        <PhoneEntry title={formatInsurerLine('Chubb', 'CHUBB')} phoneNumber="+852 3723 3030" />
        <PhoneEntry title={formatInsurerLine('Dah Sing', 'DahSing')} phoneNumber="+852 8206 2229" />
        <PhoneEntry title={formatInsurerLine('DBS', 'DBS')} phoneNumber="+852 3723 3030" />
        <PhoneEntry title={formatInsurerLine('FWD', 'FWD')} phoneNumber="+852 3122 2900" />
        <PhoneEntry title={formatInsurerLine('Hang Seng', 'HangSeng')} phoneNumber="+852 2862 0183" />
        <PhoneEntry title={formatInsurerLine('HSBC', 'HSBC')} phoneNumber="+852 2528 9333" />
        <PhoneEntry title={formatInsurerLine('Sumitomo Mitsui', 'MSIG')} phoneNumber="+852 3122 6899" />
        <PhoneEntry title={formatInsurerLine('Ping An', 'PingAn')} phoneNumber="+852 2851 1990" />
        <PhoneEntry title={formatInsurerLine('Prudential', 'Prudential')} phoneNumber="+852 2862 0111" />
        <PhoneEntry title={formatInsurerLine('QBE', 'QBE')} phoneNumber="+852 2862 0181" />
        <PhoneEntry title={intl.formatMessage({ id: 'Starr' })} phoneNumber="+852 2802 8638" />
        <PhoneEntry title={formatInsurerLine('Zurich', 'Zurich')} phoneNumber="+852 2886 3977" />
      </List>
    </Container>
  );
};

export default injectIntl(EmergencyInfo);
