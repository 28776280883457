import { connect } from 'react-redux';
import {
  approveUser,
  currencyExchangeRate,
  handleSignOut,
  loginFetch,
  loginUser,
  resetState,
  verifyEmail
} from '../../store/user/duck';
import LoginDemoComponent from './component';
import { selectedLocale } from '../../store/locale/action';
import { injectIntl } from 'react-intl';

const LoginDemo = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    loginMessage: state.user.loginMessage,
    loginPhase: state.user.loginPhase,
    approvePhase: state.user.approvePhase,
    exchangeRate: state.user.exchangeRate
  }),
  // Map actions to props
  {
    loginUser,
    approveUser,
    verifyEmail,
    selectedLocale,
    currencyExchangeRate,
    handleSignOut,
    resetState,
    loginFetch
  }
)(LoginDemoComponent);

export default injectIntl(LoginDemo);
