import React, { Fragment } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import './CopyTextField.scss';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { injectIntl } from 'react-intl';
import NativeOrWeb from '../../utils/native';
import { ChatGPTRewriteMessage } from '../ClientEntry/ChatGPTPresetDrawer/ChatGPTRewriteMessage';
import { shouldEnableChatGPT } from '../../utils/user';
import store from '../../store';


const CopyTextField = props => {
  const { intl, text,
    oriMessage,
    showGPT = false,
    onRewriteMessageValue,
    multiline, onCopy, ...rest } = props;


  const copy = () => {
    if (onCopy) {
      onCopy();
    }

    if (text) {
      NativeOrWeb.copyToClipboard(text).then(() => {
        toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });
      });
    }
  };

  return (
    <Fragment>
      <TextField
        className="copy-text-field"
        value={text}
        variant="outlined"
        fullWidth={true}
        multiline={multiline}
        InputProps={{
          style: multiline ? { fontSize: '100%' } : {},
          endAdornment: (
            <InputAdornment>
              <IconButton
                style={{ padding: '6px', marginRight: '-10px' }}
                disabled={!text}
                onClick={copy}
                color="primary"
              >
                <FileCopyOutlinedIcon style={{ fontSize: '20' }} />
              </IconButton>
              {showGPT
                && oriMessage
                && onRewriteMessageValue
                && <ChatGPTRewriteMessage
                  client={undefined}
                  message={oriMessage}
                  onValue={onRewriteMessageValue}
                  style={{ padding: '6px', marginRight: '-10px' }}
                />}
            </InputAdornment>
          )
        }}
        {...rest}
      />
    </Fragment>
  );
};

export default injectIntl(CopyTextField);
