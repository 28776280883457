
import mixpanel from 'mixpanel-browser';
// import amplitude from 'amplitude-js/amplitude';
import store from '../../store';

/* 
From JAN 2024, tracking sdk change from amplitude-js/amplitude to @amplitude/analytics-browser
SDK document: https://www.docs.developers.amplitude.com/data/sdks/browser-2/
*/

import * as amplitude from '@amplitude/analytics-browser';

// For next big update (Tracking, the init service should move to here)
// And should set one time only, unless it's necessary

// export const initUserPropertiesForTracking = (userId) => {
//     initAmplitude(userId);
//     initMixpanel(userId);
// }

const setAmplitude = (userId, props) => {
    // console.log('setAmplitude ::: called ')
    try {

        let identify = new amplitude.identify();

        for (let key in props) {
            // $teamCampaignCodes: "NOVAFINCON,TEST,1D1EF1F1F,QWDQWD121,13R1R31"
            // $teamNames: "Demo Team,Test tt,Test Team Report"
            // $teamShortName: "demo test,tt,ttr"
            // $teams: "5d1438854b0d4b28f4a53553,65310672e1d909a60b0b9caf,655f0ac89760d846a57940c5
            switch (key) {
                case '$teams':
                    identify.set('Teams', props[key]);
                    break;
                case '$teamNames':
                    identify.set('Team Name', props[key]);
                    break;
                case '$teamShortNames':
                    identify.set('Team Short Name', props[key]);
                    break;
                case '$teamCampaignCodes':
                    identify.set('Team Campaign Codes', props[key]);
                    break;
                case '$campaignCodesText':
                    identify.set('Campaign Codes Text', props[key]);
                    break;
                default:
                    break;
            }
        }
        // console.log('identify ::: ', props)
        amplitude.setUserId(userId);
        amplitude.identify(identify);
    }
    catch (error) { }
}

const setMixpanel = (userID, props) => {
    try {
        mixpanel.identify(userID);
        mixpanel.people.set(props);
    }
    catch (error) { }
}

export const setProps = (props) => {
    const userID = props._id || store.getState().user.userDetails._id;
    setAmplitude(userID, props);
    setMixpanel(userID, props);

    // const userDetails = store.getState().user.userDetails;
    // const teams = store.getState().team.teams;



    // console.log('userDetails ::: ', userDetails)
    // console.log('teams ::: ', teams)


    // const campaignCodesText = (userDetails.campaignCodes || []).map(campaignCode => campaignCode.text);
    // const hasValidCoreSubscription =
    //     userDetails.userType === 'Adviser' &&
    //     !!new ShortSubscriptions(userDetails.subscriptions).containValidCoreSubscription();
    // const isTrialing =
    //     userDetails.userType === 'Adviser' && isCurrentShortSubscriptionTrialing(userDetails.subscriptions);

    // const basic = {
    //     'Name': userDetails.name,
    //     'Email': userDetails.email,
    //     'Email Verified': userDetails.emailVerified,
    //     'User Type': userDetails.userType,
    //     'Last Login': userDetails.lastLogin,
    //     'Campaign Codes Text': userDetails.campaignCodesText,
    //     'Opt Out Email': userDetails.optOutOfEmail,
    //     'Language': userDetails.language,
    //     'Created': userDetails.createDate,
    //     ...props || {}

    // }

    // if (userDetails.userType === 'Adviser') {
    //     // identify.set('Has Valid Core Subscription', hasValidCoreSubscription);
    //     // identify.set('Is Trialing', isTrialing);
    // }

    // if (teams && teams.length > 0) {

    // }



    // console.log('setProps ::: ', props)
    // const mixpanel = require('mixpanel-browser');
    // const amplitude = require('amplitude-js/amplitude');

}