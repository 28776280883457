import React from 'react';
import './ErrorView.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

class ErrorView extends React.PureComponent {
  render() {
    const { intl, onClick, buttonText } = this.props;
    return (
      <div className="error-container">
        <Container className="error-view" maxWidth="sm">
          <div>
            <img src="img/403_Error_Forbidden.svg" alt="error" />
          </div>
          <div>{intl.formatMessage({ id: 'error-msg' })}</div>
          <Button color="primary" onClick={onClick}>
            {buttonText ? buttonText : intl.formatMessage({ id: 'joyride_back' })}
          </Button>
        </Container>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(ErrorView);

export default injectIntl(container);
