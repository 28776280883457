import React from 'react';
import { injectIntl } from 'react-intl';
import { Doughnut } from 'react-chartjs-2';
import { formatNumber } from '../../../../../utils/formatNumber';
import { PYRAMID_COLORS } from '../../constants';

const getChartConfig = (intl, currency, template, tooltipsEnabled) => ({
  cutoutPercentage: 68,
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false
    }
  },
  animation: {
    duration: template ? 0 : 3800
  },
  tooltips: {
    enabled: tooltipsEnabled,
    callbacks: {
      label: function(tooltipItem, data) {
        let label = data.labels[tooltipItem.index] || '';
        const currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

        if (label) {
          label += ': ';
        }
        label += intl.formatMessage({ id: currency }) + ' ';
        label += formatNumber(currentValue, intl);

        return label;
      }
    }
  }
});

const AssetDistributionChart = props => {
  const { intl, currency, pyramidData, template } = props;

  let data, colors, tooltipsEnabled;
  if (pyramidData.risk.asset || pyramidData.investment.asset || pyramidData.saving.asset) {
    data = [pyramidData.risk.asset, pyramidData.investment.asset, pyramidData.saving.asset];
    colors = PYRAMID_COLORS;
    tooltipsEnabled = true;
  } else {
    data = [1, 0, 0];
    colors = ['#e6e6e6', '#e6e6e6', '#e6e6e6'];
    tooltipsEnabled = false;
  }

  const chartData = {
    labels: [
      intl.formatMessage({ id: 'Risk' }),
      intl.formatMessage({ id: 'Investment' }),
      intl.formatMessage({ id: 'Saving' })
    ],
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors
      }
    ],
    texts: [
      {
        subtexts: [
          {
            text: intl.formatMessage({ id: 'total-asset' }),
            color: 'rgba(0, 0, 0, 0.54)'
          }
        ],
        fontSizeFactor: 1.1,
        fontWeight: 'normal',
        topMargin: -8,
        textWrap: intl.locale === 'en' ? 'word' : 'character'
      },
      {
        subtexts: [
          {
            text: `${formatNumber(pyramidData.totalAsset, intl)}`,
            color: '#3966f8'
          }
        ],
        fontSizeFactor: 1.5,
        fontWeight: 'bold',
        topMargin: 10,
        textWrap: 'character'
      }
    ]
  };

  return (
    <div
      className="asset-distribution-chart"
      style={{ position: 'relative', width: template ? 215 : '100%', margin: 'auto' }}
    >
      <div
        style={{
          position: 'absolute',
          height: 150,
          width: '100%',
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}
      >
        <Doughnut data={chartData} options={getChartConfig(intl, currency, template, tooltipsEnabled)} />
      </div>
    </div>
  );
};

export default injectIntl(AssetDistributionChart);
