import React from 'react';

const PurchaseLazyPack = props => {
  const { showPadding } = props;
  return (
    <div>
      <iframe
        title="Purchase Lazy Pack"
        src="https://docs.google.com/forms/d/e/1FAIpQLSe04XKL_yXSRpqsXHSCuu2wj_jr-R2GwX4RwYac1GJYxBMAgQ/viewform"
        scrolling="yes"
        style={{
          width: showPadding ? 'calc(100% - 60px)' : '100%',
          height: '100%',
          position: 'fixed',
          border: 'none',
          paddingTop: 45
        }}
      />
    </div>
  );
};

export default PurchaseLazyPack;
