import React from "react";
import { Dialog, DialogTitle, DialogContent, Button, DialogContentText, DialogActions } from "@material-ui/core";


const alertView = (props) => {
    // console.log('alertView props: ', props)
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary" variant="contained" autoFocus>
                    {props.closeText}
                </Button>
                <Button className="account-deletion dim"
                    onClick={props.onConfirm} >
                    {props.confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}



export default alertView;