import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormControlLabel, Switch } from '@material-ui/core';
import { toggleControl } from '../../../../store/control/duck';

const SimplifiedViewToggle = props => {
  const { intl, easyAddPolicy, toggleControl, beforeSwitch } = props;

  const onChange = event => {
    if (beforeSwitch) {
      beforeSwitch();
    }
    toggleControl('easyAddPolicy', event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Switch checked={easyAddPolicy} onChange={onChange} color="primary" />}
      label={intl.formatMessage({ id: 'easy-add-policy' })}
      labelPlacement="start"
    />
  );
};

const container = connect(
  state => ({
    easyAddPolicy: state.control.easyAddPolicy
  }),
  {
    toggleControl
  }
)(SimplifiedViewToggle);

export default injectIntl(container);
