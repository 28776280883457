import React, { Fragment, useState, useEffect } from 'react';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getTeams } from '../../../store/team/duck';
import { injectIntl } from 'react-intl';
import { MenuItem, Portal, Select } from '@material-ui/core';
import './TeamLibrary.scss';
import { getGlobalIntl } from '../../../reduxConnectedIntlProvider';
import CreateCategoryButton from './components/CreateCategoryButton/CreateCategoryButton';
import FileSlides from './components/FileSlides/FileSlides';

const TeamLibrary = props => {
  const intl = getGlobalIntl();
  const teams = useSelector(state => state.team.teams);
  const dispatch = useDispatch();

  const [currentTeam, setCurrentTeam] = useState(null);
  const categories = currentTeam?.fileAttachmentsCategories;

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  useEffect(() => {
    if (teams && teams.length > 0) {
      let localStorageTeamId = localStorage.getItem('currentTeamId')
      if (currentTeam !== null) {
        setCurrentTeam(teams.find(team => team._id === currentTeam._id));
      } else if (localStorageTeamId) {
        setCurrentTeam(teams.find(team => team._id === localStorageTeamId));
      } else {
        setCurrentTeam(teams[0]);
      }
    }
  }, [teams]);

  const handleTeamChange = event => {
    localStorage.setItem('currentTeamId', event.target.value);
    setCurrentTeam(teams.find(team => team._id === event.target.value));
  };

  const isTeamHead = () => {
    const role = currentTeam?.members[0]?.role;
    return role === 'teamLeader'
  }

  return (
    <AppContentContainer>
      {teams && currentTeam && (
        <div className="teamContainer">
          {intl.formatMessage({ id: 'team-library-current-team-title' })}
          <Select className="teamSelect" value={currentTeam._id} onChange={handleTeamChange}>
            {teams.map(team => (
              <MenuItem key={team._id} value={team._id}>
                {team.teamName}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      {categories && (
        <Fragment>
          <Portal container={document.querySelector('.top-navigation')}>
            <CreateCategoryButton
              team={currentTeam}
              style={{
                color: '#fff',
                position: 'absolute',
                bottom: 6,
                right: 0,
                padding: 4
              }}
            />
          </Portal>
          {categories.map((category, index) => (
            <FileSlides key={category._id} team={currentTeam} categories={categories} category={category} isTeamHead={isTeamHead()} />
          ))}
        </Fragment>
      )}
    </AppContentContainer>
  );
};

export default injectIntl(TeamLibrary);
