import { isStepLastClicked } from './duck';
import { disableScroll, enableScroll } from '../../utils/dom';
import { ACTIONS, LIFECYCLE } from 'react-joyride';

export const protectionLackGuideline = ({ intl }) => [
  {
    target: '.policy-category-tiles-wrapper',
    content: 'jr-pl-1',
    placement: 'auto',
    disableBeacon: true,
    isFixed: true,
    scrollToFirstStep: true,
    title: intl.formatMessage({ id: 'jr-pl-1-title' }),
    tutorialItemId: 'c41',
    callback: tour => {
      if (tour.action === ACTIONS.START && tour.lifecycle === LIFECYCLE.READY) {
        disableScroll();
      }
      if (isStepLastClicked(tour)) {
        enableScroll();
      }
    }
  }
];
