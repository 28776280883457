import React from 'react';
import { versionString, versionDescription } from './version';

export const WebAppVersionContext = React.createContext({ versionString, versionDescription });
export const WebAppVersionProvider = ({ children }) => {
  return (
    <WebAppVersionContext.Provider value={{ versionString, versionDescription }}>
      {children}
    </WebAppVersionContext.Provider>
  );
};

export default WebAppVersionProvider;
