import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';

const NormalDialog = props => {
  const { intl, normalDialog, toggleDialog } = props;
  const { closed, title, message } = normalDialog || {};

  const close = () => toggleDialog('normal', { ...normalDialog, closed: true });
  const onExited = () => toggleDialog('normal', false);

  return (
    <Dialog
      open={!!normalDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  // Map state to props
  state => ({
    normalDialog: state.control.normalDialog
  }),
  // Map actions to props
  {
    toggleDialog
  }
)(NormalDialog);

export default injectIntl(container);
