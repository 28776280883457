import { connect } from 'react-redux';
import { addMessage, fetchMessages } from '../../../../store/message/duck';
import { handleSignOut } from '../../../../store/user/duck';
import ChequeOutView from './component';

import { injectIntl } from 'react-intl';
import { insurerInfo } from '../../../../store/policy/duck';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';
import { getChequeWizard, resetChequeWizard, updateChequeWizard } from '../../../../store/chequeWizard/duck';

const ChequeOut = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    fetchMessagesPhase: state.message.fetchMessagesPhase,
    fetchMessageData: state.message.fetchMessageData,
    newReferralCount: state.referral.newReferralCount,
    insurers: state.insurer.insurers,
    chequeWizard: state.chequeWizard.chequeWizard,
    getPhase: state.chequeWizard.getPhase,
    updatePhase: state.chequeWizard.updatePhase
  }),
  // Map actions to props
  {
    addMessage,
    fetchMessages,
    handleSignOut,
    insurerInfo,
    renderHeaderButton,
    getChequeWizard,
    updateChequeWizard,
    resetChequeWizard
  }
)(ChequeOutView);

export default injectIntl(ChequeOut);
