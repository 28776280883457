import React from 'react';
import { injectIntl } from 'react-intl';
import './Partnership.scss';
import Container from '@material-ui/core/Container';
import FunctionCardsContainer from '../../../components/FunctionCard/FunctionCardsContainer';
import { tracking } from '../../../utils/tracking';
import { hasCampaignCode } from '../../../utils/user';
import store from '../../../store';

const Partnership = props => {
  tracking('Promotion Page Accessed');
  const { intl, history } = props;

  const redirectHandler = item => () =>
    history.push({
      pathname: '/partnershipDetails',
      state: { back: true, partner: item }
    });

  const data = [
    // {
    //   title: intl.formatMessage({ id: 'apcs-card-title' }),
    //   subtitle: intl.formatMessage({ id: 'apcs-card-sub-title' }),
    //   imgSrc: 'img/partnership/APCSLogo.jpg',
    //   imgAlt: 'APCS',
    //   bannerSrc: 'img/partnership/XK-partnership-banner.png',
    //   color: '#898052',
    //   link: 'http://bit.ly/APCSTraining',
    //   content: intl.formatMessage({ id: 'apcs-content' })
    // },
    // {
    //   title: intl.formatMessage({ id: 'moon-cake-card-title' }),
    //   subtitle: intl.formatMessage({ id: 'moon-cake-card-sub-title' }),
    //   imgSrc: 'img/partnership/moonCake.png',
    //   imgAlt: 'MoonCake',
    //   bannerSrc: 'img/partnership/moonCake-banner.png',
    //   color: '#F8961C',
    //   link: 'https://bit.ly/PMMooncake',
    //   terms: intl.formatMessage({ id: 'moon-cake-terms' }),
    //   content: intl.formatMessage({ id: 'moon-cake-content' })
    // },

    {
      id: 'holdcover',
      title: intl.formatMessage({ id: 'partnership-holdcover-title' }),
      subtitle: intl.formatMessage({ id: 'partnership-holdcover-subtitle' }),
      imgSrc: '/img/holdcover_Logo.png',
      imgAlt: 'HoldCover',
      bannerSrc: '/img/adviser-home-page/holdcoverBanner_tc.png',
      color: '#faea38',
      link: 'https://forms.gle/1MFCb7V5QKXNZCpv6',
      terms: intl.formatMessage({ id: 'partnership-holdcover-terms' }),
      content: intl.formatMessage({ id: 'partnership-holdcover-content' }),
      cta: intl.formatMessage({ id: 'partnership-holdcover-cta' })
    },
    {
      id: 'eCup',
      title: intl.formatMessage({ id: 'e-cup-card-title' }),
      subtitle: intl.formatMessage({ id: 'e-cup-card-sub-title' }),
      imgSrc: 'img/partnership/eCup-logo.png',
      imgAlt: 'eCup',
      bannerSrc: 'img/partnership/eCup-banner.jpg',
      color: '#47C2BF',
      link:
        'https://ecuphk.com/api/v1/accept/treat?unique_id=ZE9FQkJYZTdUaUlwb1MyTFRzcFFwb0VkTzM0Ym9yMEZRVFUxM2M1RlY1Q2pFSllvcXk=&multiple=MQ==',
      terms: intl.formatMessage({ id: 'e-cup-terms' }),
      content: intl.formatMessage({ id: 'e-cup-content' }),
      cta: intl.formatMessage({ id: 'Partnership-question' })
    },
    {
      id: 'findDoc',
      title: intl.formatMessage({ id: 'find-doc-card-title' }),
      subtitle: intl.formatMessage({ id: 'find-doc-card-sub-title' }),
      imgSrc: 'img/partnership/LogowithColor.png',
      imgAlt: 'findDoc',
      bannerSrc: 'img/partnership/FindDoc-banner.jpg',
      color: '#0F4C81',
      link: 'https://www.finddoc.com/club?utm_source=portfo&utm_medium=referral',
      terms: intl.formatMessage({ id: 'find-doc-terms' }),
      content: intl.formatMessage({ id: 'find-doc-content' }),
      cta: intl.formatMessage({ id: 'Partnership-question' })
    },
  ];

  // remove holdcover if user has HOLDCOVER campaign code
  if (hasCampaignCode(store.getState().user.userDetails, [], 'ENTCBB')) {
    const idx = data.findIndex(item => item.id === 'holdcover')
    // console.log('pop holdcover at idx : ', idx)
    if (idx !== -1) {
      data.splice(idx, 1);
    }
  }

  // console.log('data: ', data)

  return (
    <div style={{ background: '#fff' }}>
      <Container className="partner-list">
        <FunctionCardsContainer items={data.map(item => ({ ...item, onClick: redirectHandler(item) }))} />
      </Container>
    </div>
  );
};

export default injectIntl(Partnership);
