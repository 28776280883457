import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';

export default class IntegerControl extends React.PureComponent {
  render() {
    const { value, onChange, min, max, className, controlOnRight, ...rest } = this.props;

    const isValue = value !== undefined && value !== null && value !== '';
    const hasMin = min !== undefined && min !== null && min !== '';
    const hasMax = max !== undefined && max !== null && max !== '';
    const formattedValue = isValue ? value : 0;
    const formattedMin = hasMin ? min : -Infinity;
    const formattedMax = hasMax ? max : Infinity;

    const minus = (
      <Button
        variant={isValue ? 'contained' : 'outlined'}
        className={`plan-type-select-button child-sel-btn child-btn-res ${value > formattedMin ? 'selected' : ''}`}
        onClick={() => onChange(Math.max(formattedValue - 1, formattedMin))}
      >
        -
      </Button>
    );

    const valueText = <span style={{ padding: '2px 7px' }}>{formattedValue}</span>;

    const plus = (
      <Button
        variant={isValue ? 'contained' : 'outlined'}
        className={`plan-type-select-button child-sel-btn child-btn-res ${value < formattedMax ? 'selected' : ''}`}
        onClick={() => onChange(Math.min(formattedValue + 1, formattedMax))}
      >
        +
      </Button>
    );

    const elements = !controlOnRight ? (
      <Fragment>
        {minus}
        {valueText}
        {plus}
      </Fragment>
    ) : (
      <Fragment>
        {valueText}
        {minus}
        {plus}
      </Fragment>
    );

    return (
      <div className={`select-buttons-inline-div select-buttons-inline-res ${className ? className : ''}`} {...rest}>
        {elements}
      </div>
    );
  }
}
