import { fetch } from '../../utils';
import Backend from '../../backend';

export const vertexGenerateWithText = async (model, geminiFormattedMessages, temperature, topP, maxOutputTokens) => {
  return fetch(Backend.methodUrl('vertexGenerateWithText'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ model, contents: geminiFormattedMessages, temperature, topP, maxOutputTokens })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const vertexGenerateWithTextStreaming = async (
  model,
  existingMessages,
  geminiFormattedMessages,
  temperature,
  topP,
  maxOutputTokens,
  setChatMessages = null,
  setText = null
) => {
  try {
    await fetch(Backend.methodUrl('vertexGenerateWithTextStreaming'), {
      method: 'POST',
      body: JSON.stringify({ model, contents: geminiFormattedMessages, temperature, topP, maxOutputTokens }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let responseText = '';

        function read() {
          reader.read().then(({ done, value }) => {
            if (done) {
              return;
            }
            const text = decoder.decode(value, { stream: true });
            responseText += text;
            if (setChatMessages) {
              setChatMessages([...existingMessages, { content: responseText, role: 'assistant' }]);
            }
            if (setText) {
              setText(responseText);
            }
            read();
          });
        }
        read();
      })
      .catch(error => {
        console.error('Error streaming data:', error);
      });
  } catch (error) {
    throw error;
  }
};

export const vertexCountTokens = async (model, contents) => {
  return fetch(Backend.methodUrl('vertexCountTokens'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ model, contents })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
