import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import AdviserHomePageSubtitle from '../AdviserHomePageSubtitle/AdviserHomePageSubtitle';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';
import { HotLabel } from '../HotLabel/HotLabel';
import { hasCampaignCode, hasReferralCode } from '../../../utils/user';
import { tracking } from '../../../utils/tracking';
import { withRouter } from 'react-router';
import { toggleDialog } from '../../../store/control/duck';
import _ from 'lodash';
import { getAffiliate } from '../../../store/affiliate/duck';
import { shouldShowReferAdviserPage } from '../../../utils/adviser-referral';
import { SUCCESS } from '../../../constants/phase';
import CornerTriangle from '../../../components/CornerTriangle/CornerTriangle';

const FullWidthStyle = {
  width: '100%'
};

const ContainerStyle = {
  ...FullWidthStyle
};

const ItemStyle = {
  ...FullWidthStyle,
  marginBottom: 5
};

const MoreToolsSection = props => {
  const {
    intl,
    history,
    userDetails,
    userSubscription,
    subscriptions,
    wordpressPages,
    teamCampaignCodes,
    affiliate,
    getTopNavHelpButtonInstance,
    toggleDialog,
    getAffiliate
  } = props;

  // const isFreeTrial = new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();

  // const shouldShowXKSubscribe = !isFreeTrial && hasReferralCode(userDetails, 'XK2021');
  // const shouldShowNOVA = !isFreeTrial && hasReferralCode(userDetails, 'NOVAAPR');
  // const shouldShowNOVADIR = !isFreeTrial && hasReferralCode(userDetails, 'NOVADIR');
  // const shouldShowNOVA1V = !isFreeTrial && hasReferralCode(userDetails, 'NOVA1V');
  // const shouldShowDemo = hasCampaignCode(userDetails, teamCampaignCodes, 'DEMO');
  const shouldShowRMD = hasCampaignCode(userDetails, teamCampaignCodes, 'PARTNER_RUNDOG');


  // const shouldShowEVENTS = hasCampaignCode(userDetails, teamCampaignCodes, 'FEATURE_EVENTS');
  // const isChubbUser = hasCampaignCode(userDetails, teamCampaignCodes, 'ENTCBB');
  // const Events = () => history.push({ pathname: '/events' });
  const WeeklyKeywords = () => history.push({ pathname: '/weekly_keywords/lts' });

  const RUNMATHDOGHK = () => history.push({ pathname: '/runmathdoghk' });
  // const NOVA = () => history.push({ pathname: '/nova' });
  // const NOVADIR = () => history.push({ pathname: '/nova-dir' });
  // const NOVA1V = () => history.push({ pathname: '/nova-1v' });
  // const MyTeam = () => history.push({ pathname: '/my-team' });
  const pushIFrame = (url, name) => history.push({ pathname: '/frame', state: { url: url, title: name } });

  const adviserHelp = () => {
    const topNavHelpButtonInstance = getTopNavHelpButtonInstance();
    if (topNavHelpButtonInstance) {
      tracking('Click on Newbies Tour - Homepage');
      topNavHelpButtonInstance.openAdviserHelpDialog();
    }
  };

  const tools = () => {
    tracking('Click on Tools - Homepage');
    history.push('/adviser_tools');
  };

  const usefulCharts = () => {
    tracking('Click on Financial Concept - Main page');
    toggleDialog('financialCharts', {});
  };

  const setTarget = () => {
    tracking('Click on Set Target - Main page');
    history.push('/set_target');
  };

  const premiumFinancing = () => {
    tracking('Click on Premium Financing -  Main page');
    history.push('/premium_financing');
  };

  const profilePlus = () => {
    tracking('Click on Profile Plus - Main page');
    history.push('/profile-plus');
  };

  const goAffiliate = () => {
    tracking('Click on Affiliate - Main page');
    history.push('/affiliate');
  };

  const referAdviser = () => {
    tracking('Click on Refer Adviser -  Main page');
    history.push('/refer_adviser');
  };

  const exclusiveOffer = () => {
    // tracking('Click on Exclusive Offer - My Profile');
    history.push('/partnership');
  };

  const shouldShowReferAdviserButton = shouldShowReferAdviserPage(userSubscription);

  useEffect(() => {
    getAffiliate();
  }, [getAffiliate]);

  return (
    <Grid className="more-tools" container direction="column" style={ContainerStyle}>
      <Grid item style={ItemStyle}>
        <AdviserHomePageSubtitle title={intl.formatMessage({ id: 'adv-home-more-tools-title' })} />
      </Grid>
      <Grid item style={ItemStyle}>
        <GridButtonLayout
          items={[
            {
              image: <img src="img/profile-plus_icon.png" alt="profile-plus" />,
              text: (
                <Grid container justify="center" alignItems="center" style={{ height: 20 }}>
                  <Grid item style={{ height: 20 }}>
                    <img src="img/profile-plus.png" alt="profile-plus" style={{ height: 13, verticalAlign: 'unset' }} />
                  </Grid>
                </Grid>
              ),
              onClick: profilePlus
            },
            {
              image: <img src="img/adviser-home-page/Partneroffer.png" alt="exclusive-offer" />,
              text: intl.formatMessage({ id: 'exclusive-offer' }),
              onClick: exclusiveOffer,
              extra: (
                <HotLabel
                  text={intl.formatMessage({ id: 'hot' })}
                  style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                />
              )
            },
            affiliate && {
              image: <img src="img/adviser-home-page/referadviser.png" alt="affiliate" />,
              text: intl.formatMessage({ id: 'affiliate' }),
              onClick: goAffiliate,
              extra: <CornerTriangle text="COT" backgroundColor="#FF9900" />
            },
            ...(wordpressPages || []).map(page => {
              const nameLocale = _.get(page.name, intl.locale, '');
              return {
                image: <img src={page.iconImg} alt={nameLocale} />,
                text: nameLocale,
                onClick: () => pushIFrame(page.link, nameLocale)
              };
            }),
            // shouldShowNOVADIR && {
            //   image: <img src="img/adviser-home-page/nova-slife.png" alt="nova-direct" />,
            //   text: 'NOVA (Direct)',
            //   onClick: NOVADIR
            // },
            // shouldShowNOVA1V && {
            //   image: <img src="img/adviser-home-page/nova-slife.png" alt="nova-1v" />,
            //   text: 'NOVA (1v)',
            //   onClick: NOVA1V
            // },
            // shouldShowNOVA && {
            //   image: <img src="img/adviser-home-page/nova-slife.png" alt="nova" />,
            //   text: 'NOVA (af)',
            //   onClick: NOVA
            // },
            // shouldShowDemo && {
            //   image: <img src="img/adviser-home-page/demoSmall.png" alt="my-team" />,
            //   text: 'My Team',
            //   onClick: MyTeam
            // },
            {
              image: <img src="img/help/fastlearn.png" alt="go-to-help-center" />,
              text: intl.formatMessage({ id: 'newbie-tour' }),
              onClick: adviserHelp
            },
            {
              image: <img src="img/homepagetools.png" alt="go-to-help-center" />,
              text: intl.formatMessage({ id: 'Tools' }),
              onClick: tools
            },
            {
              image: <img src="img/biztarget.png" alt="help-center" />,
              text: intl.formatMessage({ id: 'Set Target' }),
              onClick: setTarget
            },
            // {
            //   image: <img src="img/adviser-home-page/useful_chart.png" alt="useful-charts" />,
            //   text: intl.formatMessage({ id: 'fin-charts' }),
            //   onClick: usefulCharts
            // },
            {
              image: <img src="img/pfinancing.png" alt="premium-financing" />,
              text: intl.formatMessage({ id: 'pfa' }),
              onClick: premiumFinancing,
              extra: <CornerTriangle text="COT" backgroundColor="#FF9900" />
            },
            shouldShowReferAdviserButton && {
              image: <img src="img/adviser-home-page/referadviser.png" alt="refer-adviser" />,
              text: intl.formatMessage({ id: 'refer-adviser' }),
              onClick: referAdviser,
              extra: (
                <HotLabel
                  text={intl.formatMessage({ id: 'hot' })}
                  style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                />
              )
            },
            shouldShowRMD &&
            {
              image: <img src="img/partnership/rmdLogo.png" alt="rmd-home-page" />,
              text: intl.formatMessage({ id: 'runmathdog' }),
              onClick: RUNMATHDOGHK,
              style: { position: 'relative' }
              // extra: <HotLabel text={intl.formatMessage({ id: 'special' })}/>
            },

            // !isChubbUser &&
            // {
            //   image: <img src="/img/event/pfp-olympic/logo.png" alt="rmd-home-page" />,
            //   text: intl.formatMessage({ id: 'events-toolbar-title' }),
            //   onClick: Events,
            //   style: { position: 'relative' }
            //   // extra: <HotLabel text={intl.formatMessage({ id: 'special' })}/>
            // },

            {
              image: <img src="/img/hottopic.png" alt="weekly-keywords" />,
              text: intl.formatMessage({ id: 'weekly-keyword-more-tools-label' }),
              onClick: WeeklyKeywords,
              style: { position: 'relative' }
              // extra: <HotLabel text={intl.formatMessage({ id: 'special' })}/>
            },
          ]}

        />
      </Grid>
    </Grid>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    userSubscription: state.userSubscription.userSubscription,
    subscriptions: state.userSubscription.subscriptions,
    wordpressPages: state.functions.wordpressPages,
    teamCampaignCodes: state.functions.campaignCodes,
    affiliate: state.affiliate.affiliate
  }),
  {
    toggleDialog,
    getAffiliate
  }
)(MoreToolsSection);

export default injectIntl(withRouter(container));
