import * as api from './api';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';

/***********************************
 * Action Types
 ***********/
export const TOGGLE_USER_MISSIONS_POPOVER = 'TOGGLE_USER_MISSIONS_POPOVER';
export const UPDATE_VIEWING_USER_MISSION_ID = 'UPDATE_VIEWING_USER_MISSION_ID';
export const UPDATE_USER_MISSIONS = 'UPDATE_USER_MISSIONS';
export const UPDATE_USER_MISSIONS_PHASE = 'UPDATE_USER_MISSIONS_PHASE';

/***********************************
 * Initial State
 ***********/
const initialState = {
  popoverOpen: false,
  popoverPage: 0,
  viewingUserMissionId: '',
  userMissions: undefined,
  getUserMissionsPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_USER_MISSIONS_POPOVER:
      return {
        ...state,
        popoverOpen: action.payload.isOpen,
        popoverPage: action.payload.popoverPage || 0,
        viewingUserMissionId: action.payload.isOpen ? action.payload.viewingUserMissionId || '' : ''
      };
    case UPDATE_VIEWING_USER_MISSION_ID:
      return {
        ...state,
        popoverPage: action.payload.popoverPage || 0,
        viewingUserMissionId: action.payload.viewingUserMissionId || null
      };
    case UPDATE_USER_MISSIONS:
      return {
        ...state,
        getUserMissionsPhase: SUCCESS,
        userMissions: action.payload.userMissions
      };
    case UPDATE_USER_MISSIONS_PHASE:
      return {
        ...state,
        getUserMissionsPhase: action.payload
      };
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const getUserMissions = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_USER_MISSIONS_PHASE, payload: LOADING });

  try {
    const { userMissions } = await api.getUserMissionsApi();
    dispatch({ type: UPDATE_USER_MISSIONS, payload: { userMissions: userMissions } });
    return userMissions;
  } catch (error) {
    dispatch({ type: UPDATE_USER_MISSIONS_PHASE, payload: error });
  }
};

export const toggleUserMissionPopover = (isOpen, viewingUserMissionId, popoverPage) => ({
  type: TOGGLE_USER_MISSIONS_POPOVER,
  payload: { isOpen, viewingUserMissionId, popoverPage }
});

export const updateViewingUserMissionId = (viewingUserMissionId, popoverPage) => ({
  type: UPDATE_VIEWING_USER_MISSION_ID,
  payload: { viewingUserMissionId, popoverPage }
});
