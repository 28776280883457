import { combineEpics } from 'redux-observable';
import { ACTIONS, STATUS } from 'react-joyride';
import { userFirstLogin1, userFirstLogin2, userFirstLogin3 } from './userFirstGuideline';
import { protectionLackGuideline } from './protectionLackGuideline';
import { clientReminderGuideline } from './clientReminderGuideline';
import { CPIGuideline } from './CPIGuideline';
import { addClientGuideline } from './addClientGuideline';
import { clientFilterGuideline } from './clientFilterGuideline';
import { connectClientGuideline } from './connectClientGuideline';
import { adviserWelcome, adviserWelcome2, adviserWelcome3 } from './adviserWelcomeGuideline';

const DELAY = 150;
// const MAX_RETRY_STEP = 3;

export const isStepLastClicked = tour => tour.action !== ACTIONS.CLOSE && tour.status === STATUS.FINISHED;

const STEPS = {
  client: [
    {
      target: '#help_center_menu_item',
      content: 'jr-client-helpcenter',
      isFixed: true,
      disableScrolling: true,
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    },
    {
      target: '.todo-btn svg',
      content: 'jr-client-todo',
      placement: 'bottom',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false,
      requireAction: true
    },
    {
      target: '.reset-wrapper button',
      content: 'jr-client-search',
      placement: 'bottom',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    },
    {
      target: '.client-border-first',
      content: 'jr-client-upcard',
      placement: 'bottom',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    },
    {
      target: '.client-border-second',
      content: 'jr-client-leftcard',
      placement: 'right',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    },
    {
      target: '.client-border-third',
      content: 'jr-client-rightcard',
      placement: 'left',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    },
    {
      target: '#custom-page-tag',
      content: 'jr-client-page-tag',
      placement: 'top',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    },
    {
      target: '#luck-dice img',
      content: 'jr-client-luck-dice',
      placement: 'top',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false
    }
  ],
  advisorDashboard: [
    {
      target: '#Tag_analysis',
      content: 'jr-bigdata-tag',
      placement: 'right-start',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '#disc',
      content: 'jr-bigdata-disc',
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '#birthday',
      content: 'jr-bigdata-bday',
      placement: 'right',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '#long-time-no-see',
      content: 'jr-bigdata-nosee',
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '#target-client',
      content: 'jr-bigdata-HiCPI',
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '#random-client',
      content: 'jr-bigdata-random',
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '.client-dashboard-number-div',
      content: 'jr-bigdata-BizOpp',
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    },
    {
      target: '.cardAnalysis.client-dashboard-coverage-div',
      content: 'jr-bigdata-nocoverage',
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/advisorDashboard'],
      displayOnPathMatch: false
    }
  ],
  userDashboard: [
    {
      target: '.nav-item',
      content: 'joyride_user_dashboard_1',
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.portfolio-selector .scrollIcons',
      content: 'joyride_user_dashboard_2',
      placement: 'right-start',
      disableBeacon: true
    },
    {
      target: '.portfolio-view-switch',
      content: 'joyride_user_dashboard_3',
      placement: 'right-start',
      disableBeacon: true
    },
    {
      target: '.info-box:first-child',
      content: 'jr-user-dash-6',
      placement: 'auto',
      disableBeacon: true
    },
    {
      target: '.info-box:nth-child(2)',
      content: 'joyride_user_dashboard_4',
      placement: 'bottom-end',
      disableBeacon: true
    },
    {
      target: '.portfolio-subcategory:nth-child(2)',
      content: 'joyride_user_dashboard_5',
      placement: 'auto',
      disableBeacon: true
    }
  ],
  adviserWelcome,
  adviserWelcome2,
  adviserWelcome3,
  sendMessage: [
    {
      target: '.clientName',
      content: 'Send-message-to-client',
      isFixed: true,
      disableScrolling: true,
      placement: 'auto',
      disableBeacon: true,
      pathname: ['/client'],
      displayOnPathMatch: false,
      spotlightClicks: true
    }
  ],
  adviserBusiness: [
    {
      target: '#follow-up-case-card',
      content: 'joyride_adviser_business_1',
      isFixed: true,
      disableScrolling: true,
      placement: 'auto',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#assistant-card',
      content: 'joyride_adviser_business_2',
      isFixed: true,
      disableScrolling: true,
      placement: 'auto',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#quarter-summary',
      content: 'joyride_adviser_business_3',
      isFixed: true,
      disableScrolling: true,
      placement: 'auto',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#progress-summary',
      content: 'joyride_adviser_business_4',
      placement: 'auto',
      disableBeacon: true,
      spotlightClicks: true
    },
    {
      target: '#Followup-card',
      content: 'joyride_adviser_business_5',
      placement: 'auto',
      disableBeacon: true,
      spotlightClicks: true
    }
  ],
  userFirstLogin1,
  userFirstLogin2,
  userFirstLogin3,
  protectionLack: protectionLackGuideline,
  clientReminder: clientReminderGuideline,
  CPI: CPIGuideline,
  addClient: addClientGuideline,
  clientFilter: clientFilterGuideline,
  connectClient: connectClientGuideline
};

/**
 * Private: Initial State
 */
const initialState = {
  start: false,
  run: false,
  stepsIdentifier: '',
  steps: [],
  stepIndex: 0,
  showProgress: false,
  tooltipComponent: undefined,
  extras: undefined,
  onStop: undefined
};

/**
 * Public: Action Types
 */
export const actionTypes = {
  UPDATE_STEP_INDEX: 'portfoplus/guideline/UPDATE_STEP_INDEX',
  RUN_GUIDELINE: 'portfoplus/guideline/RUN_GUIDELINE',
  PAUSE_GUIDELINE: 'portfoplus/guideline/PAUSE_GUIDELINE',
  RESUME_GUIDELINE: 'portfoplus/guideline/RESUME_GUIDELINE',
  RESTART_GUIDELINE: 'portfoplus/guideline/RESTART_GUIDELINE',
  END_GUIDELINE: 'portfoplus/guideline/END_GUIDELINE'
  // RETRY_STEP: 'portfoplus/guideline/adviserNewClientGuideline/RETRY_STEP',
  // RETRY_STEP_NEXT:
  //   'portfoplus/guideline/adviserNewClientGuideline/RETRY_STEP_NEXT'
};

/**
 * Public: Action Creators
 */
export const updateStepIndex = addIndex => {
  return {
    type: actionTypes.UPDATE_STEP_INDEX,
    payload: { addIndex: addIndex }
  };
};

export const runGuideline = (stepsIdentifier, props) => {
  return {
    type: actionTypes.RUN_GUIDELINE,
    payload: { stepsIdentifier: stepsIdentifier, props }
  };
};

export const pauseGuideline = () => {
  return {
    type: actionTypes.PAUSE_GUIDELINE,
    payload: {}
  };
};

export const resumeGuideline = () => {
  return {
    type: actionTypes.RESUME_GUIDELINE,
    payload: {}
  };
};

export const restartGuideline = stepsIdentifier => {
  return {
    type: actionTypes.RESTART_GUIDELINE,
    payload: { stepsIdentifier: stepsIdentifier }
  };
};

export const endGuideline = () => {
  return {
    type: actionTypes.END_GUIDELINE
  };
};

/**
 * Public: Reducer
 */
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.UPDATE_STEP_INDEX: {
      const { stepIndex } = state;
      const { addIndex } = action.payload;

      state.stepIndex = stepIndex + addIndex;

      return state;
    }

    case actionTypes.RUN_GUIDELINE: {
      const { stepsIdentifier, props } = action.payload;
      const { parameters, showProgress, tooltipComponent, extras, onStop } = props || {};

      state.start = true;
      state.run = true;
      state.steps =
        typeof STEPS[stepsIdentifier] === 'function' ? STEPS[stepsIdentifier](parameters) : STEPS[stepsIdentifier];
      state.stepsIdentifier = stepsIdentifier;
      state.stepIndex = 0;
      state.showProgress = showProgress || false;
      state.tooltipComponent = tooltipComponent || undefined;
      state.extras = extras;
      state.onStop = onStop || undefined;

      return state;
    }

    case actionTypes.PAUSE_GUIDELINE: {
      state.run = false;

      return state;
    }

    case actionTypes.RESUME_GUIDELINE: {
      state.run = true;

      return state;
    }

    case actionTypes.RESTART_GUIDELINE: {
      const { stepsIdentifier } = action.payload;

      state.run = false;
      state.stepsIdentifier = stepsIdentifier;
      state.steps = STEPS[state.stepsIdentifier];
      state.stepIndex = 0;

      return state;
    }

    case actionTypes.END_GUIDELINE: {
      state.start = false;
      state.run = false;
      state.stepIndex = 0;
      state.steps = [];
      state.stepsIdentifier = '';

      return state;
    }

    default: {
      return state;
    }
  }
}

/**
 * Public: Epic
 */
const restartGuidelineEpic = action$ =>
  action$
    .ofType(actionTypes.RESTART_GUIDELINE)
    .delay(DELAY)
    .map(() => {
      return { type: actionTypes.RUN_GUIDELINE };
    });

// const retryStepEpic = action$ =>
//   action$
//     .ofType(actionTypes.RETRY_STEP)
//     .delay(DELAY / 2)
//     .map(action => ({ type: actionTypes.RETRY_STEP_NEXT }));
//
// const retryStepNextEpic = action$ =>
//   action$
//     .ofType(actionTypes.RETRY_STEP_NEXT)
//     .delay(DELAY / 2)
//     .map(action => ({ type: actionTypes.RUN_GUIDELINE }));

export const guidelineEpic = combineEpics(restartGuidelineEpic);
