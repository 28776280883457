import { Preferences } from '@capacitor/preferences';

export const saveStorage = async (key, value) => {
  try {
    await Preferences.set({ key: key, value: value });
    return true;
  } catch (error) {
    console.log(`saveStorage ${key} ${value}`);
    console.log(error);
    return false;
  }
};

export const getStorage = async key => {
  try {
    const storageResult = await Preferences.get({ key: key });
    return storageResult.value;
  } catch (error) {
    console.log(`loadStorage ${key}`);
    console.log(error);
    return undefined;
  }
};

export const removeStorage = async key => {
  try {
    await Preferences.remove({ key: key });
    return true;
  } catch (error) {
    console.log(`removeStorage ${key}`);
    console.log(error);
    return false;
  }
};
