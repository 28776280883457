import React from 'react';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { InputAdornment, IconButton } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';

class LatestMeetingDateControl extends React.PureComponent {
  render() {
    const { latestMeetingDate, onChange, onClose, className } = this.props;

    return (
      <DatePicker
        className={`${className ? className : ''}`}
        onClose={onClose}
        name="latestMeetingDate"
        format="DD-MMM-YYYY"
        clearable={true}
        maxDate={moment().add(7, 'days')}
        value={latestMeetingDate || null}
        onChange={onChange}
        autoOk={true}
        invalidDateMessage={null}
        minDateMessage={null}
        maxDateMessage={null}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton style={{ padding: '0 5px 0 0' }}>
                <TodayIcon color="action" style={{ fontSize: 20 }} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

export default LatestMeetingDateControl;
