import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import palette from './palette.scss';

const PALETTE = {
  primary: {
    light: palette.primaryLight,
    main: palette.primaryMain,
    dark: '#1945d5',
    contrastText: '#ffffff'
  },
  secondary: {
    light: '#9f8cff',
    main: '#665eff',
    dark: '#1a33cb',
    contrastText: '#ffffff'
  },
  tertiary: {
    light: '#fff179',
    main: '#ffee58',
    dark: '#b2a63d',
    contrastText: '#ffffff'
  }
};

export const MUI_THEME_OPTIONS = {
  typography: {
    fontFamily: "'Roboto', 'Noto Sans TC', 'Noto Sans SC', sans-serif !important"
  },
  palette: PALETTE,
  fontFamily: "'Roboto', 'Noto Sans TC', 'Noto Sans SC', sans-serif !important",
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'unset',
        fontSize: 'inherit',
        padding: '5px 12px',
        lineHeight: '1.5'
      },
      textPrimary: {
        fontSize: 'inherit',
        color: '#3966f8'
      },
      outlinedPrimary: {
        fontSize: 'inherit',
        border: '1px solid #3966f8cc',
        color: '#3966f8'
      },
      outlined: {
        padding: '5px 12px'
      }
    },
    MuiDrawer: {
      paperAnchorLeft: {
        width: '250px'
      }
    },
    MuiInput: {
      root: {
        'font-size': 'inherit'
      },
      input: {
        'box-sizing': 'border-box',
        padding: '0px 6px'
      },
      underline: {
        '&:after': {
          'border-bottom': '2px solid #3966f8'
        }
      },
      formControl: {
        'min-height': '18px'
      }
    },
    MuiOutlinedInput: {
      root: {
        'font-size': 'inherit'
      }
    },
    MuiFormControl: {
      root: {
        width: '100%',
        'font-size': 'inherit'
      }
    },
    MuiFormLabel: {
      root: {},
      focused: {}
    },
    MuiInputLabel: {
      root: {
        'font-size': 'inherit',
        'font-family': 'inherit',
        'font-weight': 'inherit'
      },
      shrink: {
        transform: 'translate(0, 1.5px) scale(1)'
      },
      formControl: {
        transform: 'translate(0, 12px) scale(1)'
      }
    },
    MuiMenuItem: {
      root: {
        'font-size': 'inherit'
      }
    },
    MuiSwitch: {
      colorPrimary: {
        color: PALETTE.primary.main
      }
      // colorPrimary: {
      //   '& $thumb': {
      //     backgroundColor: PALETTE.primary.main,
      //   },
      //   thumb: {}
      // },
      //   iconChecked: {
      //     color: '#2196f3'
      //   },
      //   bar: {},
      //   checked: {
      //     '& + $bar': {
      //       backgroundColor: '#2196f3 !important'
      //     }
      //   }
    },
    MuiTouchRipple: {
      // child: {
      // 	backgroundColor: '#2196f3 !important',
      // },
    },
    // MuiSwitchBase: {
    //   root: {
    //     height: '29px'
    //   }
    // },
    MuiCheckbox: {
      root: {},
      checked: {},
      colorPrimary: {
        color: '#3966f8 !important'
      },
      colorSecondary: {
        // color: '#665eff !important'
        color: '#3966f8 !important'
      }
    },
    MuiSnackbar: {
      root: {
        'z-index': '10000'
      }
    },
    MuiSnackbarContent: {
      message: {
        'font-size': '1.25rem'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        height: 'auto',
        backgroundColor: '#3966f8'
      }
    },
    // MuiPickersToolbarButton: {
    //   toolbarBtn: {
    //     'font-size': '2.2rem'
    //   },
    //   toolbarBtnSelected: {
    //     'font-size': '3.3rem'
    //   }
    // },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: 'white',
      },
      dayLabel: {
        'font-size': '1.5rem'
      }
    },
    // MuiPickersDay: {
    //   day: {
    //     color: '#3966f8',
    //     'font-size': '1.5rem'
    //   },
    //   selected: {
    //     backgroundColor: '#3966f8'
    //   },
    //   current: {
    //     color: '#3966f8'
    //   }
    // },
    MuiPickersDay: {
      daySelected: {
        '&$daySelected': {
          color: '#fff'
        }
      },
      day: {
        color: '#3966f8'
      }
    },
    // MuiPickersYear: {
    //   root: {
    //     'font-size': '1.4rem'
    //   },
    //   selected: {
    //     color: '#3966f8',
    //     'font-size': '3rem'
    //   }
    // },
    MuiPickersYear: {
      root: {
        'font-size': '1.4rem',
        '&$yearSelected': {
          color: '#3966f8',
          'font-size': '3rem'
        }
      }
    },
    // MuiPickersModal: {
    //   dialogAction: {
    //     color: '#3966f8'
    //   }
    // },
    // MuiDialogActions: {
    //   spacing: {
    //     'font-size': '1.3rem'
    //   }
    // },
    MuiTypography: {
      colorTextPrimary: {
        color: '#ffffff'
      },
      // title: {
      //   'font-size': '1.575rem'
      // },
      // subheading: {
      //   'font-size': '1.2rem'
      // },
      body1: {
        'font-size': '1.38rem'
      },
      body2: {
        'font-size': '1.5rem',
        'font-weight': '500'
      },
      h4: {
        'font-size': '3.125rem'
      },
      h6: {
        'font-size': '1.8rem',
        color: '#3966f8'
      },
      subtitle1: {
        'font-size': '3rem'
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'unset'
      }
    },
    MuiSvgIcon: {
      root: {
        'font-size': '24px'
      }
    },
    MuiTablePagination: {
      root: {
        color: 'rgba(0, 0, 0, 0.54)'
      }
    },
    MuiBadge: {
      colorError: {
        backgroundColor: '#ff0000'
      }
    },
    MuiStepIcon: {
      text: {
        fontSize: '1rem'
      }
    },
    MuiPaper: {
      root: {
        color: 'inherit'
      }
    },
    MuiSpeedDialAction: {
      staticTooltip: {
        whiteSpace: 'nowrap'
      }
    }
    // MuiDialogContent: {
    //   root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center'
    //   }
    // }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1340,
      xl: 1920
    }
  }
};

export const muiTheme = createMuiTheme(MUI_THEME_OPTIONS);
