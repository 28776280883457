import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';

const ButtonStyle = {
  minHeight: 27,
  minWidth: 27,
  borderRadius: 3,
  padding: 4,
  boxShadow: 'none',
  margin: '0 2px',
  lineHeight: 1
};

const DISCButton = props => {
  const { selected, color, style, ...rest } = props;

  return (
    <Button
      variant="contained"
      style={{
        ...ButtonStyle,
        background: selected ? color : '#ccc',
        color: selected ? '#fff' : '#000',
        ...style
      }}
      {...rest}
    />
  );
};

const DISCSelect = props => {
  const { intl, personality, onChange } = props;

  const createOnClickHandler = value => () => onChange(personality === value ? '' : value);

  return (
    <Grid container alignItems="center">
      <Grid item>
        <DISCButton selected={personality === 'D'} color="#EA8462" onClick={createOnClickHandler('D')}>
          {intl.formatMessage({ id: 'personality_D' })}
        </DISCButton>
      </Grid>
      <Grid item>
        <DISCButton selected={personality === 'I'} color="#EAC862" onClick={createOnClickHandler('I')}>
          {intl.formatMessage({ id: 'personality_I' })}
        </DISCButton>
      </Grid>
      <Grid item>
        <DISCButton selected={personality === 'S'} color="#62EA84" onClick={createOnClickHandler('S')}>
          {intl.formatMessage({ id: 'personality_S' })}
        </DISCButton>
      </Grid>
      <Grid item>
        <DISCButton selected={personality === 'C'} color="#62A6EA" onClick={createOnClickHandler('C')}>
          {intl.formatMessage({ id: 'personality_C' })}
        </DISCButton>
      </Grid>
    </Grid>
  );
};

export default injectIntl(DISCSelect);
