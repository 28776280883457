import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { toggleExpiryDialog } from '../../../store/userSubscription/duck';
import { injectIntl } from 'react-intl';
import { translationKeyMap, UserSubscription } from '../../../utils/user-subscription';
import moment from 'moment-timezone';
import _ from 'lodash';
import './SubscriptionExpiryReminderDialog.scss';
import { tracking } from '../../../utils/tracking';

class SubscriptionExpiryReminderDialog extends React.PureComponent {
  render() {
    const { intl, history, open, userSubscription, toggleExpiryDialog } = this.props;
    const targetSubscription = new UserSubscription(userSubscription).getCurrentCoreSubscription();
    const isCurrentFreeTrial = UserSubscription.isSubscriptionFreeTrial(targetSubscription);
    // console.log('targetSubscription: ', targetSubscription)
    const ssInterval = targetSubscription?.subscriptionPlan?.interval
    return targetSubscription && ssInterval === 'year' ? (
      <Dialog className="sub-expiry-reminder-dialog" open={!!open} onBackdropClick={() => toggleExpiryDialog(false)}>
        <DialogTitle>{intl.formatMessage({ id: 'sub-expiry-dialog-title' })}</DialogTitle>

        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id:
                    !targetSubscription.autoExtend || !targetSubscription.stripeSubscriptionId
                      ? 'sub-expiry-dialog-content-1'
                      : 'sub-expiry-dialog-content-2'
                },
                {
                  nickname: _.get(
                    targetSubscription,
                    `subscriptionPlan.displayName[${translationKeyMap[intl.locale]}]`,
                    ''
                  ),
                  expiredAt: `<span class="expiry-date">${moment(targetSubscription.expiredAt)
                    .subtract(1, 'days')
                    .format('DD-MMM-YYYY')}</span>`
                }
              )
            }}
          />
          {isCurrentFreeTrial && (
            <Typography>
              {intl.formatMessage({ id: 'trial-coupon-text' })} ({intl.formatMessage({ id: 'trial-extend-text' })})
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              tracking('Free Subscription Expiry Popup - Cancel');
              toggleExpiryDialog(false);
            }}
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              tracking('Free Subscription Expiry Popup - See more');
              toggleExpiryDialog(false);
              history.push({
                pathname: '/subscription',
                search: isCurrentFreeTrial ? '?couponId=EARLYBIRD' : undefined
              });
            }}
          >
            {intl.formatMessage({ id: isCurrentFreeTrial ? 'upgrade-get-discount' : 'View' })}
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

const container = connect(
  // Map state to props
  state => ({
    open: state.userSubscription.expiryDialogOpen,
    userSubscription: state.userSubscription.userSubscription
  }),
  // Map actions to props
  {
    toggleExpiryDialog
  },
  // mergeProps
  null,
  // options
  {}
)(SubscriptionExpiryReminderDialog);

export default injectIntl(container);
