import React from 'react';

export const getNotificationImg = (type, props) => {
  if (type === 'reminder') {
    return <img src="img/notification-center/calendar.png" alt="reminder" {...props} />;
  } else if (type === 'connection') {
    return <img src="img/notification-center/like.png" alt="connection" {...props} />;
  } else if (type === 'system') {
    return <img src="img/notification-center/idea.png" alt="system" {...props} />;
  } else if (type === 'promotion') {
    return <img src="img/notification-center/speaker.png" alt="promotion" {...props} />;
  } else {
    return <img src="img/notification-center/email.png" alt="blasting" {...props} />;
  }
};
