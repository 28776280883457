Object.defineProperty(exports, '__esModule', {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _createInlineStyleButton = require('../../utils/createInlineStyleButton');

var _createInlineStyleButton2 = _interopRequireDefault(_createInlineStyleButton);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

exports.default = (0, _createInlineStyleButton2.default)({
  style: 'UNDERLINE',
  children: _react2.default.createElement(
    'svg',
    { height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
    _react2.default.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' }),
    _react2.default.createElement('path', {
      d:
        'M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z'
    })
  )
});
