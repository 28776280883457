
import * as amplitude from '@amplitude/analytics-browser';
import NativeOrWeb from '../../../utils/native';






/**
 * Tracks a page viewed event using Amplitude analytics. 
 * @param {string} props.domain - The domain of the page.
 * @param {string} props.location - The location of the page.
 * @param {string} props.path - The path of the page.
 * @param {string} props.title - The title of the page.
 * @param {string} props.url - The URL of the page.
 * @param {string} props.referrer - The referrer of the page.
 * @param {string} props.referring_domain - The referring domain of the page.
 * @param {Object} extraProps - Additional properties to include in the event.
 * @returns {Promise<void>} - A promise that resolves when the event is tracked.
 */
export const trackAmplitudePageViewed = async (props = {}, extraProps = {}) => {
    // console.log('trackAmplitudePageViewed props: ', props, ' extraProps: ', extraProps)
    if (props.domain && props.location && props.path &&
        props.title && props.url) {
        const properties = {
            ...extraProps,
            "[Amplitude] Page Domain": props.domain,
            "[Amplitude] Page Location": props.location,
            "[Amplitude] Page Path": props.path,
            "[Amplitude] Page Title": props.title,
            "[Amplitude] Page URL": props.url,
            "referrer": props.referrer,
            "referring_domain": props.referring_domain
        }
        const eventName = "[Amplitude] Page Viewed";
        const platformType = NativeOrWeb.getPlatform() || 'web';
        amplitude.logEvent(eventName, {
            platformType: platformType, ...properties
        });

        // console.log('trackAmplitudePageViewed: ', eventName, " : ", { platformType: platformType, ...properties })
    }
    // Document.referrer
}