import { fetch } from '../../utils';
import Backend from '../../backend';

//API TO GET NOTIFICATIONCENTER LIST OF USER

export const getNotificationList = () => {
  return fetch(Backend.methodUrl('getNotificationList'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO MARK ALL NOTIFICATIONCENTER AS READ

export const markAllNotificationAsRead = () => {
  return fetch(Backend.methodUrl('markAllNotificationAsRead'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO MARK A NOTIFICATIONCENTER AS READ BY Id

export const markNotificationAsRead = id => {
  return fetch(Backend.methodUrl('markNotificationAsRead') + '/' + id, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO CREATE NOTIFICATIONCENTER

export const createNotification = data => {
  return fetch(Backend.methodUrl('createNotification'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET ADVISER BLAST NOTIFICATION

export const getAdviserBlastNotification = () => {
  return fetch(Backend.methodUrl('getAdviserBlastNotification'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO UPDATE ADVISER BLAST NOTIFICATION

export const updateAdviserBlastNotification = data => {
  return fetch(Backend.methodUrl('updateAdviserBlastNotification'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getTeamBlastNotification = () => {
  return fetch(Backend.methodUrl('getTeamBlastNotification'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO UPDATE ADVISER BLAST NOTIFICATION

export const updateTeamBlastNotification = data => {
  return fetch(Backend.methodUrl('updateTeamBlastNotification'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO CLEAR ALL MSGOPT

export const clearAllMsgOpt = () => {
  return fetch(Backend.methodUrl('clearAllMsgOpt'), {
    method: 'POST'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
