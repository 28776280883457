import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Container, Grid, Typography } from '@material-ui/core';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import _ from 'lodash';
import ShareIcon from '@material-ui/icons/Share';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { ADVISER_FAQ_URL, REFER_USER_URL } from '../../../constants/medical-card';
import { toggleDialog } from '../../../store/control/duck';
import { sanitize } from 'dompurify';

const MedicalCardMain = props => {
  const { intl, history, userDetails, toggleDialog } = props;

  const name = _.get(userDetails, 'name');
  const email = _.get(userDetails, 'email');
  const referUserLinkParams = `${name ? `Aname=${encodeURIComponent(name)}&` : ''}${
    email ? `Aemail=${encodeURIComponent(email)}` : ''
  }`;
  const referUserFAQLink = `${REFER_USER_URL}?${referUserLinkParams}#FAQ`;
  const share = () => history.push('/medical-card-referral');
  const buy = () => history.push('/medical-card-purchase');
  const adviserFAQ = () => toggleDialog('iframe', { title: '顧問 FAQ', url: ADVISER_FAQ_URL });
  const referUserFAQ = () => toggleDialog('iframe', { title: '客戶 FAQ', url: referUserFAQLink });

  return (
    <div className="medical-card-referral refer-adviser">
      <Container className="container" maxWidth="sm">
        <section>
          <VideoPlayer url="https://youtu.be/WI8B1h3MVUE" />
        </section>
        <section>
          <Typography style={{ whiteSpace: 'pre-wrap', fontSize: '150%', fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'med-card-main-title' })}
          </Typography>
        </section>
        <section>
          <Typography
            align="left"
            style={{ fontSize: 15 }}
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.formatMessage({ id: 'med-card-main-desc' }))
            }}
          />
          <Typography align="left">
            * 必看:{' '}
            <Typography color="primary" variant="span" style={{ fontWeight: 500 }}>
              <a className="link-highlight" onClick={adviserFAQ}>
                顧問 FAQ
              </a>
            </Typography>{' '}
            <Typography color="primary" variant="span" style={{ fontWeight: 500 }}>
              <a className="link-highlight" onClick={referUserFAQ} target="_blank">
                客戶 FAQ
              </a>
            </Typography>{' '}
            <Typography color="primary" variant="span" style={{ fontWeight: 500 }}>
              <a
                className="link-highlight"
                href="https://static.portfoplus.com/img/pdf/Medcard-guide.pdf"
                target="_blank"
              >
                操作手冊
              </a>
            </Typography>
            <br />
          </Typography>
        </section>

        <section>
          <Typography color="primary" style={{ whiteSpace: 'pre-wrap', fontSize: '130%' }}>
            {intl.formatMessage({ id: 'med-card-main-actions-title' })}
          </Typography>
        </section>

        <section>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button color="primary" variant="outlined" fullWidth={true} startIcon={<ShareIcon />} onClick={share}>
                {intl.formatMessage({ id: 'med-card-main-share' })}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" fullWidth={true} startIcon={<LocalMallIcon />} onClick={buy}>
                {intl.formatMessage({ id: 'med-card-main-buy' })}
              </Button>
            </Grid>
          </Grid>
        </section>
      </Container>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {
    toggleDialog
  }
)(MedicalCardMain);

export default injectIntl(container);
