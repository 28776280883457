export const DEFAULT_AVERAGE_PREMIUM_SETTING = {
  auto: true,
  confidentLevel: 70
};

export const getAveragePremiumSetting = (averagePremiumSetting, averagePremiumData) => {
  averagePremiumSetting = averagePremiumSetting ? averagePremiumSetting : DEFAULT_AVERAGE_PREMIUM_SETTING;
  if (!averagePremiumSetting.auto) {
    return averagePremiumSetting;
  } else {
    const {
      avgLifePolicyPremium,
      avgMedicalPolicyPremium,
      avgCIPolicyPremium,
      avgSavingPolicyPremium,
      avgILASPolicyPremium
    } = averagePremiumData || {};
    const formatter = value => (value || value === 0 ? Math.round(value * 12) : null);
    return {
      ...averagePremiumSetting,
      life: formatter(avgLifePolicyPremium),
      medical: formatter(avgMedicalPolicyPremium),
      ci: formatter(avgCIPolicyPremium),
      saving: formatter(avgSavingPolicyPremium),
      ilas: formatter(avgILASPolicyPremium)
    };
  }
};

export const getPotentialBusiness = (policyCount, averagePremium, confidentLevel) =>
  (policyCount ? policyCount : 0) *
  (averagePremium ? averagePremium : 0) *
  ((confidentLevel ? confidentLevel : 0) / 100);
