import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

const LoadingBackdrop = props => {
  const { className, open, style } = props;
  return (
    <Backdrop className={className} open={open} style={{ zIndex: 1400, ...style }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
