import {
  FAMILIAR_PLACES_TAG_STYLE,
  HOBBIES_CHOICES,
  HOBBIES_TAG_STYLE,
  MAIN_PRODUCTS_CHOICES,
  MAIN_PRODUCTS_CHOICES_INTL_MAP,
  PERSONALITY_CHOICES,
  PERSONALITY_TAGS_TAG_STYLE
} from '../constants/my-details';

export const getAvatar = userDetails => {
  const { avatar, gender } = userDetails || {};
  return avatar || (gender === 'Female' ? 'female' : 'male');
};
export const getMainLanguageIntl = language => `ml-${language}`;
export const getPersonalityTagIntl = tag => `pt-${tag}`;
export const getSeniorityIntl = seniority => `sen-${seniority}`;
export const getMainProductIntl = mainProduct => MAIN_PRODUCTS_CHOICES_INTL_MAP[mainProduct];
export const getMainServiceIntl = mainService => mainService;
export const getIsMDRTIntl = MDRTType => MDRTType;
export const getHobbyIntl = hobby => `hb-${hobby}`;
export const getLikedTagIntl = likedTag => {
  if (HOBBIES_CHOICES.findIndex(hobby => hobby === likedTag) > -1) {
    return getHobbyIntl(likedTag);
  } else if (PERSONALITY_CHOICES.findIndex(personalityTag => personalityTag === likedTag) > -1) {
    return getPersonalityTagIntl(likedTag);
  } else {
    return likedTag;
  }
};
export const getHelpItemIntl = helpItem => {
  if (MAIN_PRODUCTS_CHOICES.findIndex(mainProduct => mainProduct === helpItem) > -1) {
    return getMainProductIntl(helpItem);
  } else {
    return getMainServiceIntl(helpItem);
  }
};

export const getLikedTagStyle = likedTag => {
  if (HOBBIES_CHOICES.findIndex(hobby => hobby === likedTag) > -1) {
    return HOBBIES_TAG_STYLE;
  } else if (PERSONALITY_CHOICES.findIndex(personalityTag => personalityTag === likedTag) > -1) {
    return PERSONALITY_TAGS_TAG_STYLE;
  } else {
    return FAMILIAR_PLACES_TAG_STYLE;
  }
};
