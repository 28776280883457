import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ZoomTransition } from '../../../../../components/Transition/Transition';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import StatisticCount from '../StatisticCount/StatisticCount';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import HotTopicItemDialogContent from './HotTopicItemDialogContent/HotTopicItemDialogContent';
import { toggleDialog } from '../../../../../store/control/duck';
import { withRouter } from 'react-router';
import BottomSheetOrDialog from '../../../../../components/BottomSheetOrDialog/BottomSheetOrDialog';

const HotTopicItemDialog = props => {
  const { intl, history, location, hotTopicItemDialog, toggleDialog } = props;

  const openLocationPathnameRef = useRef(null);

  const { closed, item, onToggleItem, shouldShowStatistic, statistic, DialogProps, enableLanguages } =
    hotTopicItemDialog || {};
  const isDialogOpen = !!hotTopicItemDialog && !closed;

  const { title } = item || {};

  const onClickSuggestion = suggestionItem => {
    toggleDialog('hotTopicItem', {
      ...hotTopicItemDialog,
      item: suggestionItem,
      DialogProps: _.omit(DialogProps, ['TransitionProps.triggerTarget'])
    });
    if (onToggleItem) {
      onToggleItem(suggestionItem, true);
    }
  };

  const closeDialog = () => {
    history.replace({
      pathname: history.location.pathname,
      hash: '',
      state: {}
    });
    openLocationPathnameRef.current = null;
    toggleDialog('hotTopicItem', { ...hotTopicItemDialog, closed: true });
    if (onToggleItem) {
      onToggleItem(item, false);
    }
  };

  const onExited = () => {
    history.replace({
      pathname: history.location.pathname,
      hash: '',
      state: {}
    });
    toggleDialog('hotTopicItem', false);
  };

  useEffect(() => {
    if (isDialogOpen) {
      if (!openLocationPathnameRef.current) {
        openLocationPathnameRef.current = location.pathname;
      }
    }
  }, [location.pathname, isDialogOpen]);

  useEffect(() => {
    if (location.pathname !== openLocationPathnameRef.current) {
      openLocationPathnameRef.current = null;
      toggleDialog('hotTopicItem', { closed: true });
    }
  }, [location.pathname, toggleDialog]);

  return (
    <BottomSheetOrDialog
      className="hot-topic-item-dialog"
      open={isDialogOpen}
      onClose={closeDialog}
      onExited={onExited}
      header={
        <Grid container direction="column" spacing={1}>
          <Grid item>{title && title[intl.locale]}</Grid>
          {shouldShowStatistic && (
            <Grid item>
              <Grid container alignItems="center" spacing={2} style={{ padding: '6px 0' }}>
                <Grid item>
                  <StatisticCount
                    IconComponent={VisibilityIcon}
                    label={intl.formatMessage({ id: 'visit-word' })}
                    count={_.get(statistic, 'counts["View Lazy Pack"]', 0)}
                  />
                </Grid>
                <Grid item>
                  <StatisticCount
                    IconComponent={FavoriteIcon}
                    label={intl.formatMessage({ id: 'like-2' })}
                    count={_.get(statistic, 'counts["Like Lazy Pack"]', 0)}
                  />
                </Grid>
                <Grid item>
                  <StatisticCount
                    IconComponent={ShareIcon}
                    label={intl.formatMessage({ id: 'share' })}
                    count={_.get(statistic, 'counts["Share Lazy Pack"]', 0)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
      content={
        <div className="item-details">
          <HotTopicItemDialogContent
            item={item}
            enableLanguages={enableLanguages}
            onClickSuggestion={onClickSuggestion}
            history={history}
          />
        </div>
      }
      DialogProps={{
        TransitionComponent: ZoomTransition,
        fullWidth: true,
        ...DialogProps
      }}
    />
  );
};

const container = connect(
  state => ({
    hotTopicItemDialog: state.control.hotTopicItemDialog
  }),
  {
    toggleDialog
  }
)(HotTopicItemDialog);

export default injectIntl(withRouter(container));
