import { Doughnut } from 'react-chartjs-2';
import React from 'react';
import { injectIntl } from 'react-intl';
import { customLegendCallback, PercentageTooltipLabeler, RoundedEdgePlugin } from '../../../../../../utils/chart-js';
import './PolicyCountChart.scss';
import _ from 'lodash';

class PolicyCountChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.chartRef = React.createRef();
    this.legendRef = React.createRef();
  }

  render() {
    const { intl, counts } = this.props;
    const { lifeCount, medicalCount, criticalCount, savingCount, savingILASCount } = counts;
    const total = lifeCount + medicalCount + criticalCount + savingCount + savingILASCount;

    const isNull =
      lifeCount === 0 && medicalCount === 0 && criticalCount === 0 && savingCount === 0 && savingILASCount === 0
        ? true
        : false;

    const nullData = {
      labels: [intl.formatMessage({ id: 'No Policy' })],
      datasets: [
        {
          data: ['0.1'],
          backgroundColor: ['#E9e9e9'],
          hoverBackgroundColor: ['#E9e9e9']
        }
      ]
    };

    const data = {
      labels: [
        intl.formatMessage({ id: 'Life' }),
        intl.formatMessage({ id: 'C.I.' }),
        intl.formatMessage({ id: 'Medical' }),
        intl.formatMessage({ id: 'Saving' }),
        intl.formatMessage({ id: 'ILAS' })
      ],
      datasets: [
        {
          data: [lifeCount, criticalCount, medicalCount, savingCount, savingILASCount],
          backgroundColor: ['#3eda7d', '#FF6A74', '#3966f8', '#8c1aff', '#BF80FF'],
          hoverBackgroundColor: ['#3eda7d', '#FF6A74', '#3966f8', '#8c1aff', '#BF80FF'],
          weight: 1,
          labels: [
            intl.formatMessage({ id: 'Life' }),
            intl.formatMessage({ id: 'C.I.' }),
            intl.formatMessage({ id: 'Medical' }),
            intl.formatMessage({ id: 'Saving' }),
            intl.formatMessage({ id: 'ILAS' })
          ]
        }
      ],
      texts: [
        {
          subtexts: [
            {
              text: intl.formatMessage({ id: 'Total Policies' }),
              color: '#757575'
            }
          ],
          fontSizeFactor: 1,
          fontWeight: '400'
        },
        {
          subtexts: [
            {
              text: (total ? total : 0).toString(),
              color: '#3966f8'
            }
          ],
          fontSizeFactor: 2,
          fontWeight: '400'
        }
      ]
    };

    const options = {
      cutoutPercentage: 78,
      legendCallback: customLegendCallback(),
      animation: {
        duration: 3000
      },
      cutoutPercentage: 80,
      aspectRatio: 1,
      plugins: {
        datalabels: {
          display: false
        },
        roundedEdge: true
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: PercentageTooltipLabeler
        }
      }
    };

    const legendDiv = _.get(this.legendRef, 'current');
    const chartInstance = _.get(this.chartRef, 'current.chartInstance');
    if (legendDiv && chartInstance) {
      legendDiv.innerHTML = chartInstance.generateLegend();
    }
    return (
      <div className="Outer" style={{ marginTop: '1px' }}>
        <Doughnut data={data} options={options} plugins={RoundedEdgePlugin} ref={this.chartRef} />

        <div ref={this.legendRef} />
      </div>
    );
  }
}

export default injectIntl(PolicyCountChart);
