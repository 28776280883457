import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './PortfolioActions.scss';
import withRouter from 'react-router/withRouter';
import { goToAddPortfolio, goToAnalysis, goToExportOption } from '../../../../../components/ClientEntry/utils';

const PortfolioActions = props => {
  const { intl, history, location, match, viewingClient, userDetails, staticContext, ...rest } = props;

  const addPolicy = () => {
    goToAddPortfolio(viewingClient, history, userDetails);
  };

  const analysis = () => {
    goToAnalysis(viewingClient, history);
  };

  const exportOption = () => {
    goToExportOption(viewingClient, history);
  };

  return viewingClient ? (
    <Grid
      className="portfolio-actions click-for-edit-div-animated"
      container
      direction="column"
      wrap="nowrap"
      {...rest}
    >
      <Grid item style={{ flexGrow: 1, height: '100%' }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="space-evenly"
          wrap="nowrap"
          style={{ height: '100%' }}
        >
          <Grid item style={{ width: '100%' }}>
            <Button variant="outlined" color="primary" fullWidth={true} onClick={addPolicy}>
              <Grid container direction="column" alignItems="center" justify="center" wrap="nowrap">
                <Grid item>
                  <img alt="add" src="img/add-icon.svg" width={20} />
                </Grid>
                <Grid item>
                  <Typography color="primary">{intl.formatMessage({ id: 'portfo-action-add-policy' })}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Button variant="outlined" color="primary" fullWidth={true} onClick={analysis}>
              <Grid container direction="column" alignItems="center" justify="center" wrap="nowrap">
                <Grid item>
                  <img alt="export" src="img/pAnalysis_blue.svg" width={20} />
                </Grid>
                <Grid item>
                  <Typography color="primary">{intl.formatMessage({ id: 'portfo-action-analy' })}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Button variant="outlined" color="primary" fullWidth={true} onClick={exportOption}>
              <Grid container direction="column" alignItems="center" justify="center" wrap="nowrap">
                <Grid item>
                  <img alt="export" src="img/export_blue.svg" width={20} />
                </Grid>
                <Grid item>
                  <Typography color="primary">{intl.formatMessage({ id: 'portfo-action-pdf' })}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    viewingClient: state.client.viewingClient
  }),
  {}
)(PortfolioActions);

export default injectIntl(withRouter(container));
