import _ from 'lodash';
import React from 'react';
import withRouter from 'react-router/withRouter';

export const withRouterInnerRef = WrappedComponent => {
  class InnerComponentWithRef extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }

  const ComponentWithRef = withRouter(InnerComponentWithRef, { withRef: true });

  return React.forwardRef((props, ref) => {
    return <ComponentWithRef {...props} forwardRef={ref} />;
  });
};

export const getDefaultRoute = userDetails => {
  if (userDetails && userDetails.userType) {
    if (userDetails.userType === 'User') {
      return '/c-dashboard';
    } else {
      return '/home';
    }
  } else {
    return '/login';
  }
};

export const goBack = (event, history) => {
  event.preventDefault();
  if (history.location.state && history.location.state.previousPath) {
    history.replace(history.location.state.previousPath, _.omit(history.location.state, ['previousPath']));
  } else {
    history.goBack();
  }
};

export const getSearchObject = history => {
  const entries = Array.from(new URLSearchParams(history.location.search).entries());

  const searchObject = entries.reduce((acc, entry) => {
    const [key, value] = entry;
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});

  return searchObject;
};

export const addSearch = (history, searchObject) => {
  const currentSearchObject = getSearchObject(history);
  let mergedObject = _.merge(currentSearchObject, searchObject);
  mergedObject = _.fromPairs(Object.entries(mergedObject).filter(entry => !!entry[1]));
  const searchParams = new URLSearchParams(mergedObject);
  history.replace(`${history.location.pathname}?${searchParams.toString()}`);
};

export const changeLocationHash = hash => {
  if (window.history.replaceState) {
    const url = new URL(window.location);
    url.hash = hash;
    window.history.replaceState(null, null, url);
  } else {
    window.location.hash = `#${hash}`;
  }
};
