import React from 'react';
import clsx from 'clsx';
import './ContentSlide.scss';

export default function ContentSlide({ className, onClick, children }) {
  return (
    <div className={clsx('itemContainer', className)} onClick={onClick}>
      <div>{children}</div>
    </div>
  );
}
