import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editFileAttachmentsCategory } from '../../../../../store/team/api';
import { getTeams } from '../../../../../store/team/duck';
import RemoveCategoryDialog from '../RemoveCategoryDialog/RemoveCategoryDialog';
import FormSubmitWrapper from '../../../../../components/FormSubmitWrapper/FormSubmitWrapper';

const EditCategoryDialog = props => {
  const { intl, open, onClose, team, category, categories, isTeamHead } = props;

  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState(category?.title || '');
  const [error, setError] = useState('');
  const [isCategoryRemoveDialogOpen, setIsCategoryRemoveDialogOpen] = useState(false);

  const editCategory = async () => {
    await editFileAttachmentsCategory(team._id, category._id, categories, categoryName);
    dispatch(getTeams());
  };

  const handleCloseCategoryRemoveDialog = () => {
    setIsCategoryRemoveDialogOpen(false);
  };

  const handleCategoryNameChange = e => {
    setError('');
    setCategoryName(e.target.value);
  };

  const handleRemoveCategory = async () => {
    if (category.attachments.length > 0) {
      setError(intl.formatMessage({ id: 'team-library-category-dialog-error-category-has-attachments' }));
      return;
    }
    setIsCategoryRemoveDialogOpen(true);
  };

  const handleConfirm = async () => {
    if (categoryName === '') {
      setError(intl.formatMessage({ id: 'team-library-category-dialog-error-input-required' }));
      return;
    }
    await editCategory();
    onClose();
  };

  return (
    <Fragment>
      <RemoveCategoryDialog
        open={isCategoryRemoveDialogOpen}
        onClose={handleCloseCategoryRemoveDialog}
        team={team}
        category={category}
        categories={categories}
      />
      <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
        <DialogTitle>{intl.formatMessage({ id: 'team-library-edit-category-dialog-title' })}</DialogTitle>
        <DialogContent>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'team-library-edit-category-dialog-description' })}</Typography>
          </Grid>
          <Grid item>
            <FormSubmitWrapper onSubmit={handleConfirm}>
              <Input
                autoFocus={true}
                value={categoryName}
                onChange={handleCategoryNameChange}
                placeholder={intl.formatMessage({ id: 'team-library-category-dialog-input-placeholder' })}
                fullWidth
                style={{ height: 30 }}
              />
            </FormSubmitWrapper>
          </Grid>
          {error && (
            <Grid item>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between" spacing={1}>
            < Grid item>
              {!!isTeamHead ? <Button variant="outlined" color="primary" onClick={handleRemoveCategory} style={{ marginLeft: 8 }}>
                {intl.formatMessage({ id: 'Delete' })}
              </Button>
                :
                <></>}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Button color="primary" onClick={onClose}>
                    {intl.formatMessage({ id: 'Cancel' })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button color="primary" onClick={handleConfirm}>
                    {intl.formatMessage({ id: 'Confirm' })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment >
  );
};

export default injectIntl(EditCategoryDialog);
