import React, { Fragment, forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import CropperJS from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import Dropzone from '../Dropzone/Dropzone';
import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const CropperWrapper = forwardRef((props, ref) => {
  const { imgBlob, dropzone, cropperOptions } = props;

  const [internalImgBlob, setInternalImgBlob] = useState(imgBlob);
  const [imgSrc, setImgSrc] = useState(null);

  const divRef = useRef(null);
  const imgRef = useRef(null);
  const cropperRef = useRef(null);
  const cropper = cropperRef.current;

  const onDropAccepted = files => {
    const file = files[0];
    setInternalImgBlob(file);
  };

  const onImgLoad = () => {
    if (imgSrc) {
      cropperRef.current = new CropperJS(imgRef.current, {
        ...cropperOptions
      });
    }
  };

  const deleteImage = () => {
    destroy();
  };

  const destroy = () => {
    if (cropper) {
      cropper.destroy();
      cropperRef.current = null;
    }
    setInternalImgBlob(null);
    setImgSrc(null);
  };

  useImperativeHandle(ref, () => ({
    getCropper: () => cropperRef.current,
    getImageBlob: () => internalImgBlob,
    getFilename: () => internalImgBlob?.name,
    destroy: destroy
  }));

  useEffect(() => {
    setInternalImgBlob(imgBlob);
  }, [imgBlob]);

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    const run = async () => {
      if (internalImgBlob) {
        const base64Data = await new Promise((resolve, reject) => {
          try {
            const reader = new FileReader();
            reader.onloadend = readerEvent => {
              resolve(readerEvent.target.result);
            };
            reader.readAsDataURL(internalImgBlob);
          } catch (error) {
            reject(error);
          }
        });

        setImgSrc(base64Data);
      }
    };

    run()
      .then()
      .catch(console.log);
  }, [internalImgBlob]);

  return (
    <Fragment>
      {dropzone && !internalImgBlob && (
        <Dropzone onDropAccepted={onDropAccepted} accept={['image/jpeg', 'image/png']} />
      )}
      {(!dropzone || (dropzone && internalImgBlob)) && (
        <Grid container direction="column" spacing={1}>
          {dropzone && (
            <Grid item>
              <Grid container justify="flex-end">
                <Grid item>
                  <IconButton color="primary" size="small" onClick={deleteImage}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <div ref={divRef} style={{ width: '100%', overflow: 'hidden' }}>
              <img
                alt="cropping"
                ref={imgRef}
                src={imgSrc}
                style={{ display: 'block', maxWidth: '100%', maxHeight: '50vh', objectFit: 'contain' }}
                onLoad={onImgLoad}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
});

export default CropperWrapper;
