import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const StatisticCount = props => {
  const { IconComponent, label, count } = props;
  return (
    <Grid container justify="center" alignItems="center" spacing={1} style={{ color: '#757575' }}>
      <Grid item style={{ height: 28.7 }}>
        <IconComponent style={{ fontSize: 20.7 }} />
      </Grid>
      {label && (
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography>{count || 0}</Typography>
      </Grid>
    </Grid>
  );
};

export default StatisticCount;
