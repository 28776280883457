import NumberFormat from 'react-number-format';
import React from 'react';
import { toast } from 'react-toastify';
import IconToast from '../../../../components/NewToast';
import { injectIntl } from 'react-intl';

const NumberFormatInputWrapper = props => {
  const { intl, inputRef, onChange, ...rest } = props;

  const handleInput = event => {
    const { value } = event.target;

    const valueWithoutCommas = value.replace(/,/g, '');
    const validNumberPattern = /^(?!0\d)\d*\.?\d*%?$/;
    if (valueWithoutCommas && !validNumberPattern.test(valueWithoutCommas)) {
      toast.dismiss();
      toast.warn(IconToast('warn', intl.formatMessage({ id: 'please-enter-number' })), { className: 'new-toast' });
    }
  };

  const handleValueChange = values => {
    const { value } = values;

    onChange({
      target: {
        name: props.name,
        value: value !== '' ? parseFloat(value) : undefined
      }
    });
  };

  return (
    <NumberFormat
      {...rest}
      decimalSeparator="."
      thousandSeparator=","
      inputMode="decimal"
      pattern="[0-9]*"
      allowNegative={false}
      onValueChange={handleValueChange}
      onInput={handleInput}
    />
  );
};

export default injectIntl(NumberFormatInputWrapper);
