import { injectIntl } from 'react-intl';
import { useEffect } from 'react';

const LocaleMessageExtractor = props => {
  const { intl, locale, id, extras, onChange } = props;
  useEffect(() => {
    onChange(intl.formatMessage({ id: id }, extras));
  }, [intl, locale, id, extras, onChange]);

  return null;
};

export default injectIntl(LocaleMessageExtractor);
