const DISABLE_DOM_STYLE_ELEM_ID = 'disable-dom';

export const disableDomInteraction = () => {
  const styleElem = document.createElement('style');
  styleElem.id = DISABLE_DOM_STYLE_ELEM_ID;
  styleElem.textContent = 'body, div { pointer-events: none; user-select: none; }';
  document.head.append(styleElem);
  return styleElem;
};

export const enableDomInteraction = () => {
  const styleElem = document.querySelector(`style#${DISABLE_DOM_STYLE_ELEM_ID}`);
  if (styleElem) {
    styleElem.remove();
  }
};

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
const preventDefault = e => e.preventDefault();
const preventDefaultForScrollKeys = e => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};

let supportsPassive = false;
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get: () => {
        return (supportsPassive = true);
      }
    })
  );
} catch (e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
export const disableScroll = () => {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
};

// call this to Enable
export const enableScroll = () => {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
};

export const isElementInViewport = element => {
  if (!element) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const scrollAndFocusTextareaEnd = textareaElem => {
  if (textareaElem) {
    textareaElem.focus();
    textareaElem.scrollTop = textareaElem.scrollHeight;
    textareaElem.setSelectionRange(textareaElem.value.length, textareaElem.value.length);
  }
};
