import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import './ReferAdviser.scss';
import { withRouter } from 'react-router';
import ShareButtonGroup from '../../components/ShareButtonGroup/ShareButtonGroup';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { getUserMissions } from '../../store/userMission/duck';
import { CircularProgress, Grid } from '@material-ui/core';
import { LOADING } from '../../constants/phase';

const ReferAdviser = props => {
  const { intl, userDetails, userMissions, getUserMissionsPhase, getUserMissions } = props;

  const referAdviserMsg = intl.formatMessage({ id: 'refer-adviser-msg' });
  const searchStr = new URLSearchParams({
    campaignCode: userDetails.email,
    planMode: 'rfpay',
    couponId: 'REF22PAY'
  }).toString();
  const referAdviserLink = `${process.env.PFPLUS_URL}/signup?${searchStr}`;

  const { gainedRewards, variables } =
    (userMissions || []).find(userMission => userMission.missionListId.id === 'adviser-referral') || {};
  const { referAdviserRegister, referAdviserSubscribe } = variables || {};
  const { bonusDay } = (gainedRewards || []).reduce(
    (accumulator, reward) => {
      if (reward.type === 'extend-current-subscription') {
        accumulator.bonusDay += reward.quantity;
      }
      return accumulator;
    },
    { bonusDay: 0 }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getUserMissions();
  }, [getUserMissions]);

  return (
    <div className="refer-adviser">
      <Container className="container" maxWidth="sm">
        <section className="banner-section">
          <img src="img/refer-adviser/referral.png" alt="refer-adviser" />
        </section>

        <section>
          <Typography color="primary" style={{ whiteSpace: 'pre-wrap', fontSize: '150%' }}>
            {intl.formatMessage({ id: 'refer-adviser-title' })}
          </Typography>
        </section>

        <section>
          <Typography align="left">{intl.formatMessage({ id: 'refer-adviser-hint-1' })}</Typography>
        </section>

        <section>
          <Typography align="left">{intl.formatMessage({ id: 'refer-adviser-hint-2' })}</Typography>
        </section>

        <section>
          <ShareButtonGroup
            title={referAdviserMsg}
            url={referAdviserLink}
            isCTA
            CopyTextFieldProps={{
              multiline: true
            }}
          />
        </section>

        <section className="rewards-section">
          <Card>
            <List component="div" disablePadding={true}>
              <ListItem component="div" divider={true}>
                <ListItemAvatar>
                  <img src="img/refer-adviser/check.svg" alt="check" width={50} />
                </ListItemAvatar>
                <ListItemText
                  disableTypography={true}
                  primary={
                    <Grid className="hint" container spacing={1} alignItems="center" justify="center">
                      <Grid item xs={12}>
                        <Typography color="primary">
                          {intl.formatMessage({ id: 'affiliate-progress-title' })}
                        </Typography>
                      </Grid>
                      {getUserMissionsPhase === LOADING ? (
                        <Grid container justify="center">
                          <Grid item>
                            <CircularProgress />
                          </Grid>
                        </Grid>
                      ) : (
                        <Fragment>
                          <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                              <Grid item>
                                <Typography>
                                  {intl.formatMessage({ id: 'refer-adviser-progress-dialog-no-registered' })}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography style={{ fontSize: '150%' }}>{referAdviserRegister}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                              <Grid item>
                                <Typography>
                                  {intl.formatMessage({ id: 'refer-adviser-progress-dialog-no-referral' })}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography color="primary" style={{ fontWeight: 'bold', fontSize: '150%' }}>
                                  {referAdviserSubscribe}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justify="space-between" alignItems="center">
                              <Grid item>
                                <Typography>
                                  {intl.formatMessage({ id: 'refer-adviser-progress-dialog-bonus-day' })}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  color="primary"
                                  style={{ fontWeight: 'bold', fontSize: '150%' }}
                                >{`+${bonusDay}`}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  }
                />
              </ListItem>
            </List>
          </Card>
        </section>

        <section className="terms-section">
          <Typography color="textSecondary" gutterBottom={true}>
            {intl.formatMessage({ id: 'refer-adviser-max-reward' })}
          </Typography>
        </section>
      </Container>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    userMissions: state.userMission.userMissions ? [...state.userMission.userMissions] : undefined,
    getUserMissionsPhase: state.userMission.getUserMissionsPhase
  }),
  {
    getUserMissions
  }
)(ReferAdviser);

export default injectIntl(withRouter(container));
