import React from 'react';
import Phone from 'react-phone-number-input';
import countryList from '../../store/locale/country-list';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { feedback, initPhaseFeedBackPhase } from '../../store/message/duck';
import { toast } from 'react-toastify';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import IconToast from '../NewToast';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';

class FeedBackModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      email: '',
      whatsapp: '',
      err: {}
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { intl } = nextProps;
    if (this.props.feedbackPhase !== true && nextProps.feedbackPhase === true) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Feedback submitted successfully' })), {
        className: 'new-toast'
      });
      this.closeModal();
    }
  }

  componentWillUnmount() {
    this.props.initPhaseFeedBackPhase();
  }

  handleChange(e) {
    if (e.target.name === 'email') {
      delete this.state.err.email;
    }
    if (e.target.name === 'content') {
      delete this.state.err.content;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  submitForm() {
    const { feedback } = this.props;
    const { content, email, whatsapp } = this.state;
    let err = {};

    if (email !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      err.email = 'Invalid email';
    }
    if (content === '') {
      err.content = 'This field is required';
    }

    this.setState({ err });

    if (!Object.keys(err).length) {
      let formData = {};
      formData.senderEmail = email;
      formData.whatsapp = whatsapp;
      formData.content = content;
      feedback(formData);
    }
  }

  closeModal() {
    const { onRequestClose } = this.props;
    this.setState({ content: '', email: '', whatsapp: '', err: {} });
    onRequestClose();
  }

  render() {
    const { intl, isOpen, feedbackPhase } = this.props;
    const { content, email, whatsapp, err } = this.state;

    return (
      <div>
        {feedbackPhase === 'loading' && <LoadingBackdrop open={true} />}
        <Dialog
          open={isOpen}
          onClose={() => this.closeModal()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {intl.formatMessage({ id: 'Feedback direct to CEO' })}
          </DialogTitle>
          <DialogContent>
            <div className="form-group">
              <textarea
                rows="4"
                type="text"
                name="content"
                value={content}
                onChange={event => this.handleChange(event)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'What we can improve?' })}
              </label>
              <i className="bar" />
              {err.content ? <span className="error_field">{intl.formatMessage({ id: err.content })}</span> : ''}
            </div>
            <div className="form-group">
              <input
                type="text"
                name="email"
                value={email}
                onChange={event => this.handleChange(event)}
                required="required"
              />
              <label className="control-label" htmlFor="input">
                {intl.formatMessage({ id: 'Email' })}
              </label>
              <i className="bar" />
              {err.email ? <span className="error_field">{intl.formatMessage({ id: err.email })}</span> : ''}
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="form-group phone_module clearfix">
                  <label className=" phone-module-label" htmlFor="input">
                    {intl.formatMessage({ id: 'WhatsApp' })}
                  </label>
                  <Phone
                    international={true}
                    // inputComponent={SmartInput}
                    country="HK"
                    placeholder={intl.formatMessage({ id: 'Enter whatsapp number' })}
                    value={whatsapp}
                    onChange={value => this.setState({ whatsapp: value })}
                    //labels={countryList.labels}
                    countries={countryList.labels}
                    flagsPath={'img/flags/'}
                    indicateInvalid
                  />
                  <i className="bar" />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeModal()} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={() => this.submitForm()} color="primary" autoFocus>
              {intl.formatMessage({ id: 'Submit' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    feedbackPhase: state.message.feedbackPhase
  }),
  // Map actions to props
  {
    feedback,
    initPhaseFeedBackPhase
  },
  // mergeProps
  null,
  // options
  {}
)(FeedBackModal);

export default injectIntl(container);
