import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const TypoStyle = {
  whiteSpace: 'nowrap',
  fontSize: '16px'
};

const ModeSwitch = props => {
  const { intl, checked, onChange } = props;

  return (
    <Typography component="div" style={TypoStyle}>
      <Grid container alignItems="center" wrap="nowrap" spacing={1}>
        <Grid item>{intl.formatMessage({ id: 'fin-py-handwriting-mode' })}</Grid>
            <Grid item style={{ fontSize: '75%' }}>
              ({intl.formatMessage({ id: 'beta' })})
            </Grid>
        <Grid item>
          <Switch color="primary" checked={checked} onChange={onChange} />
        </Grid>
      </Grid>
    </Typography>
  );
};

export default injectIntl(ModeSwitch);
