import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import DefaultCloseButton from '../DefaultCloseButton/DefaultCloseButton';
import { Button, Grid, Typography } from '@material-ui/core';
import { getContentLink, getLocalContent } from '../../StandardContentComponents/utils';
import StandardContentTrigger from '../../StandardContentComponents/StandardContentDialog/StandardContentTrigger';

const GuidanceTooltip = props => {
  const {
    intl,
    formattedStep,
    formattedSize,
    hideCloseButton,

    continuous,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
    skipProps,
    index,
    isLastStep,
    step,
    size
  } = props;
  const { content, hideBackButton, locale, showProgress, showSkipButton, title, styles, tutorialItemId } = step;
  const { back, close, last, next, skip } = locale;
  const output = {
    primary: close
  };

  if (continuous) {
    output.primary = isLastStep ? last : next;

    if (showProgress) {
      output.primary = (
        <span>
          {output.primary} ({formattedStep !== undefined ? formattedStep : index + 1}/
          {formattedSize !== undefined ? formattedSize : size})
        </span>
      );
    }
  }

  if (showSkipButton && !isLastStep) {
    output.skip = (
      <button style={styles.buttonSkip} {...skipProps}>
        {skip}
      </button>
    );
  }

  if (!hideBackButton && index > 0) {
    output.back = (
      <button style={styles.buttonBack} {...backProps}>
        {back}
      </button>
    );
  }

  output.close = !hideCloseButton ? <DefaultCloseButton {...closeProps} styles={styles.buttonClose} /> : null;

  const extraOnClick = event => {
    if (primaryProps.onClick) {
      primaryProps.onClick(event);
    }
  };

  return (
    <div className="joyride-tooltip-do-not-show-again" key="JoyrideTooltip" style={styles.tooltip} {...tooltipProps}>
      <div style={styles.tooltipContainer}>
        {output.close}
        {title && (
          <Grid container style={{ padding: '0 10px' }} spacing={1}>
            <Grid item>
              <img src="img/help/stepbystep.svg" alt="" width={25} />
            </Grid>
            <Grid item>
              <Typography color="primary" style={{ fontSize: '105%' }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
        )}
        {content && <div style={{ ...styles.tooltipContent, padding: '20px 10px 0', fontSize: '90%' }}>{content}</div>}
      </div>
      <div style={styles.tooltipFooter}>
        <div>
          {output.skip}
          {output.back}
          <StandardContentTrigger
            color="primary"
            variant="outlined"
            style={{ padding: '2px 6px' }}
            buttonIntlId="video-tutorial"
            contentLink={getContentLink('help-center')}
            localContent={process.env.NODE_ENV === 'development' && getLocalContent('help-center')}
            itemId={tutorialItemId}
            extraOnClick={extraOnClick}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(GuidanceTooltip);
