import React from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';

const Style = {
  paddingTop: 45,
  paddingRight: 0
};

export const APP_CONTENT_CONTAINER_MAX_WIDTH = 1180;

const AppContentContainer = props => {
  const { className, children, style, showPadding } = props;

  return (
    <Container
      // maxWidth="lg"
      className={`app-content-container${className ? ` ${className}` : ''}`}
      style={{
        ...Style,
        maxWidth: APP_CONTENT_CONTAINER_MAX_WIDTH,
        // paddingLeft: 60,
        paddingLeft: 0,
        paddingBottom: `calc(${showPadding ? 0 : 60}px + var(--bottom-padding))`,
        ...style
      }}
    >
      {children}
    </Container>
  );
};

const container = connect(
  state => ({
    showPadding: state.app.get('showPadding')
  }),
  {}
)(AppContentContainer);

export default container;
