import _ from 'lodash';
import { expose } from 'threads/worker';
import csv from 'csvtojson';
import proj4 from 'proj4';

const SELECT_THRESHOLD = 0.3;
let thresholds = SELECT_THRESHOLD;

// Distance calculation (WSG84)
const deg2rad = deg => deg * (Math.PI / 180);
const getWSG84DistanceInKm = ([lat1, lon1], [lat2, lon2]) => {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Distance in km
  return R * c;
};

// Coordinate conversion (HK80 -> WSG84)
proj4.defs(
  'EPSG:2326',
  '+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs'
);
proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
const fromHK80ToWGS84 = point => {
  return proj4('EPSG:2326', 'EPSG:4326', point);
};

const getHK80DistanceInKm = (a, b) => {
  let aLat, aLong, bLat, bLong;

  if (a.latitude) aLat = a.latitude;
  if (a.longitude) aLong = a.longitude;
  if (b.latitude) bLat = b.latitude;
  if (b.longitude) bLong = b.longitude;

  if (!aLat || !aLong) {
    if (a.x && a.y) {
      const pointWSG84 = fromHK80ToWGS84([a.x, a.y]);
      aLat = pointWSG84[1];
      aLong = pointWSG84[0];
    } else {
      return null;
    }
  }

  if (!bLat || !bLong) {
    if (b.x && b.y) {
      const pointWSG84 = fromHK80ToWGS84([b.x, b.y]);
      bLat = pointWSG84[1];
      bLong = pointWSG84[0];
    } else {
      return null;
    }
  }

  if (!aLat || !aLong || !bLat || !bLong) {
    return null;
  }

  return getWSG84DistanceInKm([aLat, aLong], [bLat, bLong]);
};

// const fetchData = async url => await (await fetch(url, { credentials: 'include' })).text();

export const getLocationSearchResult = async address => {
  if (!address || !address.trim()) return null;
  try {
    const response = await fetch(encodeURI(`https://geodata.gov.hk/gs/api/v1.0.0/locationSearch?q=${address}`));
    if (response.status === 400) {
      return null;
    } else {
      const json = await response.json();
      return json && json.length && json.length > 0 ? json[0] : null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getDataFromGov = async urls => {
  const caseBuildingCSV = await (await fetch(urls.caseBuildingUrl)).text();
  const homeConfineeBuildingCSV = await (await fetch(urls.homeConfineeBuildingUrl)).text();

  const parseCSV = async csvString => {
    let parsed = await csv({ quote: '"', delimiter: '^' }).fromString(csvString);
    return parsed.map(entry => {
      const { location, ...rest } = entry;
      let parsedLocation;
      try {
        parsedLocation = location ? JSON.parse(location) : null;
      } catch (e) {
        parsedLocation = null;
      }
      return { ...rest, location: parsedLocation };
    });
  };
  const caseBuildingList = await parseCSV(caseBuildingCSV);
  const homeConfineeBuildingList = await parseCSV(homeConfineeBuildingCSV);
  return {
    caseBuildingList,
    homeConfineeBuildingList
  };
};

const compareLocations = (a, b) => {
  const distance = getHK80DistanceInKm(a, b);
  return distance;
};
export const searchAddressInBuildingList = (addresses, buildingList, type) => {
  if (addresses && addresses.length && addresses.length > 0) {
    return buildingList
      .map(buildingEntry => ({
        ...buildingEntry,
        distance: _.min(
          addresses.map(address =>
            !address || !buildingEntry.location ? null : compareLocations(address, buildingEntry.location)
          )
        )
      }))
      .filter(
        buildingEntry =>
          buildingEntry.distance !== undefined &&
          buildingEntry.distance !== null &&
          buildingEntry.distance <= thresholds
      )
      .map(buildingEntry => {
        return {
          ...buildingEntry,
          mostMatchingAddress: `${buildingEntry.districtEng} ${buildingEntry.buildingNameEng}`,
          type: type
        };
      })
      .sort((a, b) => {
        return a.distance - b.distance;
      });
  } else {
    return [];
  }
};

export const run = async (data, clientList, newThresholds, urls) => {
  thresholds = newThresholds ? newThresholds : SELECT_THRESHOLD;
  data = !data ? await getDataFromGov(urls) : data;

  let formattedClientList = clientList.filter(
    client =>
      client.factsheetId &&
      ((client.factsheetId.homeLocation &&
        (typeof client.factsheetId.homeLocation !== 'string' || client.factsheetId.homeLocation.trim())) ||
        (client.factsheetId.workLocation &&
          (typeof client.factsheetId.workLocation !== 'string' || client.factsheetId.workLocation.trim())))
  );

  for (let i = 0; i < formattedClientList.length; i++) {
    if (!formattedClientList[i].homeSearchLocation) {
      formattedClientList[i].homeSearchLocation =
        typeof formattedClientList[i].factsheetId.homeLocation === 'string'
          ? await getLocationSearchResult(formattedClientList[i].factsheetId.homeLocation)
          : formattedClientList[i].factsheetId.homeLocation;
    }
    if (!formattedClientList[i].workSearchLocation) {
      formattedClientList[i].workSearchLocation =
        typeof formattedClientList[i].factsheetId.workLocation === 'string'
          ? await getLocationSearchResult(formattedClientList[i].factsheetId.workLocation)
          : formattedClientList[i].factsheetId.workLocation;
    }
  }

  return {
    data: data,
    clients: (
      await Promise.all(
        formattedClientList.map(async client => {
          const addresses = [];
          if (client.homeSearchLocation) addresses.push(client.homeSearchLocation);
          if (client.workSearchLocation) addresses.push(client.workSearchLocation);
          // console.log('addresses.length', addresses.length);
          const caseBuildingList = searchAddressInBuildingList(addresses, data.caseBuildingList, '14-days');
          const homeConfineeBuildingList = searchAddressInBuildingList(
            addresses,
            data.homeConfineeBuildingList,
            'home-quarantine'
          );
          // console.log(caseBuildingList, homeConfineeBuildingList);
          return {
            ...client,
            caseBuildingList: caseBuildingList,
            homeConfineeBuildingList: homeConfineeBuildingList
          };
        })
      )
    ).filter(client => client.caseBuildingList.length > 0 || client.homeConfineeBuildingList.length > 0)
  };
};

expose({
  async run(data, clientList, newThresholds, urls) {
    return await run(data, clientList, newThresholds, urls);
  }
});
