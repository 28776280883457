import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ShortSubscriptions, UserSubscription } from '../../../utils/user-subscription';
import moment from 'moment-timezone';

const FreeTrialExpiredDialog = props => {
  const { intl, history, location, subscriptions, userSubscription } = props;

  const [shown, setShown] = useState(false);
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
    const freeTrialSubscription = new UserSubscription(userSubscription).getFreeTrialCoreSubscription();
    history.replace({ pathname: location.pathname, search: freeTrialSubscription ? '?couponId=EARLYBIRD' : undefined });
  };

  useEffect(() => {
    if (!shown) {
      setShown(true);
      // subscription expired && do not have a valid core subscription
      if (!new ShortSubscriptions(subscriptions).containValidCoreSubscription()) {
        if (userSubscription) {
          const freeTrialSubscription = new UserSubscription(userSubscription).getFreeTrialCoreSubscription();
          if (freeTrialSubscription) {
            const expiredMoment = freeTrialSubscription.expiredAt && moment(freeTrialSubscription.expiredAt);
            if (expiredMoment) {
              const nowMoment = moment();
              const diff = nowMoment.diff(expiredMoment, 'days', true);
              if (diff >= 0 && diff <= 5) {
                setOpen(true);
              }
            }
          }
        }
      }
    }
  }, [shown, subscriptions, userSubscription]);

  return (
    <Dialog open={open}>
      <DialogTitle>{intl.formatMessage({ id: 'trial-expired-dialog-title' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'trial-expired-dialog-content' })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          {intl.formatMessage({ id: 'subscribe-now' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userSubscription: state.userSubscription.userSubscription,
    subscriptions: state.userSubscription.subscriptions
  }),
  {}
)(FreeTrialExpiredDialog);

export default injectIntl(withRouter(container));
