import React from 'react';
import { Badge, Button, Grid, Typography } from '@material-ui/core';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import palette from '../../../views/App/palette.scss';

const DEFAULT_COLOR = palette.primaryMain;
const BORDER_RADIUS = 6;

const FullHeightStyle = {
  height: '100%'
};

const ButtonStyle = {
  borderRadius: BORDER_RADIUS,
  background: '#fff',
  padding: 0
};

const BorderGridStyle = {
  width: 6,
  borderRadius: `${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px`
};

const LabelGridStyle = {
  flexGrow: 1,
  position: 'relative'
};

const LabelTypoContainerStyle = {
  ...FullHeightStyle,
  padding: 8
};

const ArrowGridStyle = {
  width: 22,
  color: '#fff',
  borderRadius: `0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0`
};

const IconStyle = {
  fontSize: 35,
  verticalAlign: 'middle'
};

const ArrowIconContainerStyle = {
  ...FullHeightStyle
};

const RedDotStyle = {
  position: 'absolute',
  top: 8,
  right: 8
};

const GuidanceButton = props => {
  const { label, labelProps, color, redDot, onClick } = props;

  return (
    <Button variant="contained" color="primary" fullWidth={true} onClick={onClick} style={ButtonStyle}>
      <Grid container wrap="nowrap">
        <Grid item style={{ ...BorderGridStyle, background: color || DEFAULT_COLOR }} />
        <Grid item style={LabelGridStyle}>
          {redDot && <Badge color="error" variant="dot" style={RedDotStyle} />}
          <Grid container alignItems="center" style={LabelTypoContainerStyle}>
            <Grid item>
              <Typography color="textSecondary" {...labelProps}>
                {label}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ ...ArrowGridStyle, background: color || DEFAULT_COLOR }}>
          <Grid container alignItems="center" justify="center" style={ArrowIconContainerStyle}>
            <Grid item>
              <ArrowRightRoundedIcon style={IconStyle} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};

export default GuidanceButton;
