import { fetch } from '../../utils';
import Backend from '../../backend';

//API TO ADD THE CLIENT ON CLIENT VIEW
export const submitClient = data => {
  return fetch(Backend.methodUrl('submitClient'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO ENABLE THE TRACKING OF CLIENT
export const enableTracking = data => {
  return fetch(Backend.methodUrl('enableTracking'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE CLIENT OF LOGIN ADVISER
export const getClient = () => {
  return fetch(Backend.methodUrl('getClient'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO CHECK THE CLIENT IS EXIST OR NOT WHILE ADDING THE CLIENT
export const checkClient = data => {
  return fetch(Backend.methodUrl('checkClient'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO DELETE THE CLIENT FROM CLIENT VIEW
export const deleteClient = data => {
  return fetch(Backend.methodUrl('deleteClient') + `/${data.clientId}?factsheetId=${data.factsheetId}`, {
    method: 'DELETE'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { error };
    });
};

//API TO GET THE CLIENT DETAIL
export const getClientDetails = data => {
  const factsheetId = data.factsheetId;
  const userId = data.userId;
  return fetch(Backend.methodUrl('getClientDetails') + `/${factsheetId}/${userId}`, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO SEND THE CONSENT TO THE CLIENT
export const getConsent = data => {
  return fetch(Backend.methodUrl('getConsent'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO SUBMIT THE CONSENT
export const submitConsent = data => {
  return fetch(Backend.methodUrl('submitConsent'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { error };
    });
};

//API TO UPDATE THE FACTSHEET DETAIL
export const updateFactSheet = data => {
  return fetch(Backend.methodUrl('updateFactSheet'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API THE CHNAGE THE EMAIL  OF INACTIVE USER
export const changeInactiveUserEmail = data => {
  return fetch(Backend.methodUrl('changeInactiveUserEmail'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO UPDATE THE CLIENT LANGUAGE
export const updateClientLanguage = data => {
  return fetch(Backend.methodUrl('updateLanguage'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getClientCoverage = data => {
  const userId = data.userId;
  return fetch(Backend.methodUrl('getClientCoveragePolicy') + `/${userId}`, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO CREATE DEMO CLIENT
export const createDemoClient = data => {
  return fetch(Backend.methodUrl('createDefaultClient'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const changeDemoClient = clientId => {
  return fetch(Backend.methodUrl(`changeDemoClient/${clientId}`))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const adviserExport = ({ password }) => {
  return fetch(Backend.methodUrl('adviser-export'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ password })
  })
    .then(res => {
      if (res.status === 200) {
        return res.blob();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getClientById = clientId => {
  return fetch(Backend.methodUrl(`clients/${clientId}`))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
