import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import '../../../Client/styles.scss';
import ClientEntry from '../../../../../components/ClientEntry/ClientEntry';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import { addSearch } from '../../../../../utils/router';
import { injectIntl } from 'react-intl';
import ClientEntryMailButton from '../../../../../components/ClientEntry/ClientEntryMailButton/ClientEntryMailButton';

class ClientList extends React.PureComponent {
  constructor(props) {
    super(props);

    const stateObj = {
      selectedClientId: null
    };

    const { category, listName, isDone, location } = props;

    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      const assistantClientSearch = searchParams.get(`assistantClient`);
      if (assistantClientSearch) {
        const split = assistantClientSearch.split('$');
        if (split[0] === category && split[1] === listName && split[2] === isDone.toString()) {
          stateObj.selectedClientId = split[3];
        }
      }
    }

    this.state = stateObj;
    this.mailAnimRef = React.createRef();
  }

  handleAction(client, event) {
    event.stopPropagation();
    const { onAction } = this.props;
    if (onAction) {
      onAction(client);
    }
  }

  render() {
    const { intl, entries, handleDoneChange, category, listName, onAction } = this.props;
    const { selectedClientId } = this.state;

    const doneText = intl.formatMessage({ id: 'finish' });
    const finalEntries = [];

    entries.forEach((entry, i) => {
      const { client } = entry;
      const props = {
        key: i,
        client: client,
        expanded: selectedClientId === client._id,
        selectedClient: !!selectedClientId,
        onChange: (event, expanded) => this.setState({ selectedClientId: expanded ? client._id : null }),
        beforeRoute: () => {
          addSearch(this.props.history, { assistantClient: `${category}$${listName}$${entry.isDone}$${client._id}` });
        },
        customActions: (
          <FormControlLabel
            label={doneText}
            className="labelwidth client__name__label"
            aria-label="Acknowledge"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Checkbox
                value="isDone"
                color="primary"
                checked={entry.isDone}
                onChange={() => handleDoneChange(client, i)}
              />
            }
          />
        ),
        summaryPrepend: onAction && <ClientEntryMailButton onClick={this.handleAction.bind(this, client)} />
      };

      //Condition for not done (Pending)
      // if (entry.isDone === true) {
      //   //Condition for  done (Done)
      //   props.hideDetails = true;
      // }

      finalEntries.push(<ClientEntry {...props} />);
    });

    return <div>{finalEntries}</div>;
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(withRouter(ClientList));

export default injectIntl(container);
