import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';

const Style = {
  width: 'calc(100% + 32px)',
  margin: '-15px -16px 10px',
  textAlign: 'center',
  padding: 8,
  background: '#ffa62b',
  color: '#fff',
  fontSize: '150%'
};

const SubscriptionExtendedBanner = props => {
  const { intl, freeTrialRemainingSeconds } = props;
  const [internalSeconds, setInternalSeconds] = useState(freeTrialRemainingSeconds);
  const isSet = useRef(false);

  useEffect(() => {
    if (freeTrialRemainingSeconds !== undefined) {
      if (!isSet.current) {
        isSet.current = true;
        setInternalSeconds(freeTrialRemainingSeconds);
      }
    }
  }, [freeTrialRemainingSeconds]);

  return internalSeconds > 0 ? (
    <div style={Style}>
      {intl.formatMessage({ id: 'sub-extended-banner' }, { days: Math.ceil(internalSeconds / 86400) })}
    </div>
  ) : null;
};

export default injectIntl(SubscriptionExtendedBanner);
