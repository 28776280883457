import moment from 'moment';
import { weeklyAptCalculator } from './weeklyAptCalculator';

export const getTimeLeftData = adviserBusiness => {
  //Quarter end and year End
  //Logic to calculate the QEND and YEAREND keys
  let currentYear = moment().format('YYYY');
  let currentMonth = parseInt(moment().format('MM'));
  let currentDate = moment().startOf('day');
  let Q =
    currentMonth <= 3
      ? currentYear + '-03-31'
      : currentMonth <= 6
      ? currentYear + '-06-30'
      : currentMonth <= 9
      ? currentYear + '-09-30'
      : currentMonth <= 12
      ? currentYear + '-12-31'
      : '';
  let QMonthStartDate1 =
    currentMonth <= 3
      ? currentYear + '-01-01'
      : currentMonth <= 6
      ? currentYear + '-04-01'
      : currentMonth <= 9
      ? currentYear + '-07-01'
      : currentMonth <= 12
      ? currentYear + '-10-01'
      : '';

  let endDate =
    adviserBusiness && adviserBusiness.quarterEndDate
      ? moment.parseZone(adviserBusiness.quarterEndDate).format('YYYY-MM-DD')
      : Q;
  let QEndDate = moment(endDate).toDate();

  let pastDays = moment().diff(QMonthStartDate1, 'days') + 1;

  let QEndMonth = parseInt(moment(endDate).format('MM'));

  /*End to calculate the big date count*/

  let QEnd = 0;

  //calculate the range according to the quarter
  let r1 = 0;
  let QEndYear = parseInt(moment(endDate).format('YYYY'));
  let currrentEndYear = parseInt(moment().format('YYYY'));

  if (moment(endDate).isAfter(currentDate) || (moment(endDate).isSame(currentDate) && QEndYear === currrentEndYear)) {
    r1 = moment(endDate).diff(QMonthStartDate1, 'days') + 1;
  } else if (QEndMonth >= currentMonth && QEndYear === currrentEndYear) {
    // r1 = 0;
    r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
  } else if (
    QEndYear === currrentEndYear &&
    QEndMonth <= currentMonth &&
    QEndMonth <= parseInt(moment(Q).format('MM')) &&
    currentMonth <= parseInt(moment(Q).format('MM')) &&
    QEndMonth >= parseInt(moment(QMonthStartDate1).format('MM'))
  ) {
    // r1 = 0;
    r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
  } else {
    r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
  }

  //define Qend(date from )
  if ((moment(QEndDate).isAfter(currentDate) || moment(QEndDate).isSame(currentDate)) && QEndYear === currrentEndYear) {
    QEnd = moment(QEndDate).diff(currentDate, 'days');
  } else if (QEndMonth >= currentMonth && QEndYear === currrentEndYear) {
    // QEnd = '-';
    QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
  } else if (
    QEndYear === currrentEndYear &&
    QEndMonth <= currentMonth &&
    QEndMonth <= parseInt(moment(Q).format('MM')) &&
    currentMonth <= parseInt(moment(Q).format('MM')) &&
    QEndMonth >= parseInt(moment(QMonthStartDate1).format('MM'))
  ) {
    // QEnd = '-';
    QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
  } else {
    QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
    QEnd = QEnd > 92 ? 92 : QEnd <= 92 && QEnd >= 0 ? QEnd : '-';
  }

  let chartData = r1 > 0 ? (pastDays / r1) * 100 : 100;

  return { r1, QEnd, pastDays, chartData };
};

export const getQuarterTargetData = adviserBusiness => {
  const result = {
    quarterTarget: adviserBusiness && adviserBusiness.quarterTarget ? adviserBusiness.quarterTarget : 0,
    avgCaseSize: adviserBusiness && adviserBusiness.avgCaseSize ? adviserBusiness.avgCaseSize : 0,
    closingRate: adviserBusiness && adviserBusiness.closingRate ? adviserBusiness.closingRate * 100 : 0
  };

  result.weeklyAppt = Math.ceil(
    weeklyAptCalculator(
      parseFloat(result.quarterTarget.toString()),
      parseFloat(result.avgCaseSize.toString()),
      parseFloat(result.closingRate.toString()) / 100
    )
  );

  return result;
};
