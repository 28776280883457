import * as api from './api';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';

/***********************************
 * Action Types
 ***********/
export const UPDATE_ADVISER_PROFILE_PLUS = 'UPDATE_ADVISER_PROFILE_PLUS';
export const UPDATE_GET_ADVISER_PROFILE_PLUS_PHASE = 'UPDATE_GET_ADVISER_PROFILE_PLUS_PHASE';

/***********************************
 * Initial State
 ***********/
const initialState = {
  adviserProfilePlus: undefined,
  getAdviserProfilePlusPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_ADVISER_PROFILE_PLUS:
      const { adviserProfilePlus } = action.payload;
      return {
        ...state,
        adviserProfilePlus: adviserProfilePlus,
        getAdviserProfilePlusPhase: SUCCESS
      };
    case UPDATE_GET_ADVISER_PROFILE_PLUS_PHASE:
      const { phase } = action.payload;
      return {
        ...state,
        getAdviserProfilePlusPhase: phase
      };
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const getAdviserProfilePlus = (userId, location) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_GET_ADVISER_PROFILE_PLUS_PHASE, payload: { phase: LOADING } });
  try {
    const adviserProfilePlus = await api.getAdviserProfilePlus(userId, location);
    dispatch({ type: UPDATE_ADVISER_PROFILE_PLUS, payload: { adviserProfilePlus } });
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_GET_ADVISER_PROFILE_PLUS_PHASE, payload: { phase: ERROR } });
  }
};

export const increaseAdviserProfilePlusLike = userId => async (dispatch, getState) => {
  try {
    const adviserProfilePlus = await api.increaseAdviserProfilePlusLike(userId);
    dispatch({ type: UPDATE_ADVISER_PROFILE_PLUS, payload: { adviserProfilePlus } });
  } catch (error) {
    console.error(error);
  }
};
