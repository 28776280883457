import React, { useState, useRef, useEffect, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import ImageWithTitleCardButton from '../AdviserHelpDialog/partials/ImageWithTitleCardButton/ImageWithTitleCardButton';
import { DialogContentText } from '@material-ui/core';
import ShareMessage from '../AdviserHelpDialog/partials/ShareMessage';
import AdviserHelpDialog from '../AdviserHelpDialog/AdviserHelpDialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { tracking } from '../../utils/tracking';
import VideoDialog from '../VideoPlayer/VideoDialog';
import _ from 'lodash';
import { getContentJson, getContentLink, getLocalContent } from '../StandardContentComponents/utils';
import Help from '../AdviserHelpDialog/partials/Help';

const DateClientDialog = props => {
  const { intl, history, open, onClose } = props;
  const videoDialogRef = useRef(null);
  const [message, setMessage] = useState(intl.formatMessage({ id: 'share-message-option-1-content' }));
  const [contents, setContents] = useState([]);

  useEffect(() => {
    getContentJson({
      contentLink: getContentLink('help-center'),
      localContent: process.env.NODE_ENV === 'development' && getLocalContent('help-center')
    }).then(data => {
      setContents(data);
    });
  }, []);

  return (
    <AdviserHelpDialog
      className="date-client-dialog"
      intl={intl}
      open={open}
      disableNext={true}
      onClose={onClose}
      doNotShowClose={true}
      items={[
        {
          component: setIndex => (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ margin: '-20px -24px -8px', width: 'calc(100% + 24px + 24px)' }}
            >
              <Grid item>
                <img
                  src="img/VideoDemo2.jpg"
                  alt="deal-with-client"
                  onClick={() => {
                    setIndex(1);
                  }}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: 500, fontSize: '120%', padding: 12 }}>
                  {intl.formatMessage({ id: 'date-client-appoint-hint' })}
                </Typography>
              </Grid>
            </Grid>
          ),
          customActions: setIndex => (
            <Fragment>
              <Button
                onClick={() => {
                  tracking('Click on Skip - Website');
                  onClose();
                }}
              >
                {intl.formatMessage({ id: 'continue' })}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  tracking('Click on Visit immediately - Website');
                  tracking('Q4 Prospecting Series Modal: 3 ways to create appointments clicked');
                  setIndex(1);
                }}
              >
                {intl.formatMessage({ id: 'date-client-appoint' })}
              </Button>
            </Fragment>
          )
        },
        {
          title: 'tot-help-center-title',
          customBack: (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                tracking('Click on More Tips - Website');
                history.push('/new_help_center#c91');
              }}
            >
              {intl.formatMessage({ id: 'extra-tips' })}
            </Button>
          ),
          component: setIndex => (
            <Help intl={intl} content={contents[2]} title={intl.formatMessage({ id: 'tot-help-center-subtitle' })} />
            // <div>
            // 	<DialogContentText style={{ fontSize: 16 }}>
            // 		{intl.formatMessage({ id: 'date-client-appoint-subtitle' })}
            // 	</DialogContentText>
            //
            // 	<VideoDialog ref={videoDialogRef}/>
            //
            // 	<ImageWithTitleCardButton
            // 		index={1}
            // 		title={intl.formatMessage({ id: 'date-client-youtube' })}
            // 		description={intl.formatMessage({ id: 'date-client-youtube-hint' })}
            // 		imgSrc="img/Date-client-play.png"
            // 		onClick={event => {
            // 			// Tracking
            // 			tracking('Make appointment Modal: Youtube clicked');
            // 			const videoDialogInstance = _.get(videoDialogRef, 'current._wrappedInstance');
            // 			if (videoDialogInstance) {
            // 				videoDialogInstance.open(event, 'https://youtu.be/1xn1EtR3aOM');
            // 			}
            // 		}}
            // 	/>
            //
            // 	<ImageWithTitleCardButton
            // 		index={2}
            // 		title={intl.formatMessage({ id: 'date-client-share' })}
            // 		description={intl.formatMessage({ id: 'date-client-share-hint' })}
            // 		imgSrc="img/Date-client-script.png"
            // 		onClick={() => {
            // 			// Tracking
            // 			tracking('Make appointment Modal: Share Scripts clicked');
            // 			setIndex(2);
            // 		}}
            // 	/>
            // 	{/*<ImageWithTitleCardButton*/}
            // 	{/*	index={2}*/}
            // 	{/*	title={intl.formatMessage({ id: 'date-client-demo' })}*/}
            // 	{/*	description={intl.formatMessage({ id: 'date-client-demo-hint' })}*/}
            // 	{/*	imgSrc="img/CreateDemo.png"*/}
            // 	{/*	onClick={() => {*/}
            // 	{/*		// Tracking*/}
            // 	{/*		tracking('Make appointment Modal: Create Template clicked');*/}
            // 	{/*		history.replace('/home');*/}
            // 	{/*		history.push('/add_demo_client');*/}
            // 	{/*	}}*/}
            // 	{/*/>*/}
            // 	<ImageWithTitleCardButton
            // 		index={3}
            // 		title={intl.formatMessage({ id: 'date-client-lazy-pack' })}
            // 		description={intl.formatMessage({ id: 'date-client-lazy-pack-hint' })}
            // 		imgSrc="img/hottopic.png"
            // 		onClick={() => {
            // 			// Tracking
            // 			tracking('Make appointment Modal: Share Hot Topic PDF clicked');
            // 			history.replace('/home');
            // 			history.push('/hot_topic#c114');
            // 		}}
            // 	/>
            // </div>
          )
        }
        // {
        // 	title: 'adviser-help-intro-question-3',
        // 	component: () => <ShareMessage intl={intl} setMessage={message => setMessage(message)}
        // 	                               message={message}/>
        // }
      ]}
    />
  );
};

export default injectIntl(withRouter(DateClientDialog));
