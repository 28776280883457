import produce from 'immer';

/***********************************
 * Action Types
 ***********/
export const UPDATE_ADVISER_EXPORT_INPUTS = 'UPDATE_ADVISER_EXPORT_INPUTS';

/***********************************
 * Initial State
 ***********/
const initialState = {
  loading: false
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_ADVISER_EXPORT_INPUTS:
        draft[action.key] = action.value;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const updateAdviserExportInputs = (key, value) => ({
  type: UPDATE_ADVISER_EXPORT_INPUTS,
  key,
  value
});
