import React from 'react';
import './Subscription.scss';
import SubscriptionContent from "./SubscriptionContent/SubscriptionContent";

const SubscriptionView = props => {
	return (
		<div className="subscription gradient-bg">
			<div id="wrapper">
				<div id="page-content-wrapper">
					<div className="container-fluid">
						<SubscriptionContent stripePromise={props.stripePromise} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SubscriptionView;
