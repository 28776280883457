import _ from 'lodash';
import { DEFAULT_PREMIUM_FINANCING_PROFILE } from '../constants/premium-financing';

export const getDefaultPremiumFinancingProfile = () => {
  return _.cloneDeep(DEFAULT_PREMIUM_FINANCING_PROFILE);
};

export const getPremiumFinancingProfileDisplay = (intl, profile, profileIndex) => {
  const defaultLabel = intl.formatMessage({ id: 'fin-py-ver' }, { index: profileIndex + 1 });
  return profile && profile.displayName ? profile.displayName : defaultLabel;
};
