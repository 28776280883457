import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';
import { getConnectionRequest } from '../../store/connectionRequest/api';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../components/Transition/Transition';
import { usePrevious } from '../../utils/hooks';
import { isAdviserType } from '../../utils/user';

const ConnectionRequest = props => {
  const { history, match, intl, initialAuthDone, authenticatedUser } = props;

  const [invalidDialog, setInvalidDialog] = useState(false);
  const [blockAdviserDialog, setBlockAdviserDialog] = useState(false);
  const prevInitialAuthDone = usePrevious(initialAuthDone);

  const confirmInvalid = () => {
    setInvalidDialog(false);
    history.replace('/');
  };

  const confirmBlockAdviser = () => {
    setBlockAdviserDialog(false);
    history.replace('/');
  };

  useEffect(() => {
    if (prevInitialAuthDone !== true && initialAuthDone === true) {
      (async () => {
        const connectionRequestId = match.params.connectionRequestId;
        try {
          if (isAdviserType(authenticatedUser)) {
            setBlockAdviserDialog(true);
            return;
          }

          const response = await getConnectionRequest(connectionRequestId);
          if (response.connectionRequest) {
            const { _id, email } = response.connectionRequest || {};

            if (response.isExistingClient) {
              history.replace('/connection-request/accept', { connectionRequest: response.connectionRequest });
            } else {
              history.replace(`/clientSignUp?loginEmail=${email}&cr=${_id}`);
            }
          } else {
            setInvalidDialog(true);
          }
        } catch (error) {
          console.log(error);
          setInvalidDialog(true);
        }
      })()
        .then()
        .catch();
    }
  }, [match, history, initialAuthDone, prevInitialAuthDone, authenticatedUser]);

  return (
    <Fragment>
      <LoadingBackdrop open={!invalidDialog && !blockAdviserDialog} />
      <Dialog open={invalidDialog} fullWidth={true} TransitionComponent={SlideTransition}>
        <DialogContent>{intl.formatMessage({ id: 'connection-request-invalid' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={confirmInvalid}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={blockAdviserDialog} fullWidth={true} TransitionComponent={SlideTransition}>
        <DialogContent>{intl.formatMessage({ id: 'connection-request-block-adviser' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={confirmBlockAdviser}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const container = connect(
  state => ({
    initialAuthDone: state.auth.initialAuthDone,
    authenticatedUser: state.auth.authenticatedUser
  }),
  {}
)(ConnectionRequest);

export default injectIntl(container);
