import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { formatNumber } from '../../../../../utils/formatNumber';
import Grid from '@material-ui/core/Grid';
import {
  getRoomClass,
  isILASPolicy,
  isDraftPolicy,
  isInsuredNotMePolicy,
  isPaidUpPolicy,
  isPolicyCategory,
  isPolicySubCategory,
  isRiderPolicy,
  shouldShowVHISIcon,
  shouldShowQDAPIcon
} from '../../../../../utils/policy';
import './PolicyEntry.scss';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { isInsurerOther } from '../../../../../utils/insurer';
import _ from 'lodash';

const PolicyEntry = props => {
  const {
    intl,
    policy,
    // premiumFrequency,
    currency,
    infoType,
    onClick,
    formatValue,
    percentage,
    showPolicyName, // = desktop view
    showPolicyNumber // = desktop view with wider screen (px)
  } = props;

  const insurer = _.get(policy, 'insurerId', {});

  const isCompanyPolicy = isPolicyCategory(policy, 'company');
  const isMedical = isPolicySubCategory(policy, 'medical');
  const isPaidUp = isPaidUpPolicy(policy);
  const isRider = isRiderPolicy(policy);
  const isInsuredNotMe = isInsuredNotMePolicy(policy);
  const roomClass = getRoomClass(policy);
  const isILAS = isILASPolicy(policy);
  const isDraft = isDraftPolicy(policy);

  let policyAmount = formatValue(policy);

  const formattedPercentage = typeof percentage === 'function' ? percentage(policy, policyAmount) : percentage;
  policyAmount = policyAmount !== 'N/A' ? formatNumber(policyAmount, intl) : 'N/A';

  const click = onClick ? () => onClick(policy) : undefined;

  const value = (
    <Grid container alignItems="center" style={{ height: '100%' }}>
      {infoType === 'Protection' ? (
        isMedical ? (
          <Grid item>{policy.coverageType ? intl.formatMessage({ id: policy.coverageType }) : ''}</Grid>
        ) : (
          <Fragment>
            <Grid item>{intl.formatMessage({ id: currency })}</Grid>
            <Grid item>{policyAmount}</Grid>
          </Fragment>
        )
      ) : policyAmount !== 'N/A' ? (
        <Fragment>
          <Grid item>{intl.formatMessage({ id: currency })}</Grid>
          <Grid item className={isPaidUp ? 'deleteNo' : ''}>
            {policyAmount}
          </Grid>
        </Fragment>
      ) : (
        <Grid item className={isPaidUp ? 'deleteNo' : ''}>
          {policyAmount}
        </Grid>
      )}
    </Grid>
  );

  const showVHIS = shouldShowVHISIcon(policy);
  const showQDAP = shouldShowQDAPIcon(policy);

  return (
    <div className="policy-entry" onClick={click} style={isInsuredNotMe ? { background: '#e2ffff' } : undefined}>
      <Grid container alignItems="center" style={isDraft ? { fontStyle: 'italic' } : undefined}>
        <Grid item xs={4} sm={3} className="text-left insurer-img insurerLeft plan_type_rider">
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justify={showPolicyName ? undefined : 'space-between'}
            style={{ paddingRight: 6 }}
          >
            <Grid item style={showPolicyName ? { paddingLeft: 20 } : undefined}>
              {isInsurerOther(insurer) ? (
                <div className="other-insurer">{policy.insurer}</div>
              ) : (
                <img alt="insurer" src={insurer.image} />
              )}
            </Grid>
            <Grid item style={showPolicyName ? { flexGrow: 1 } : undefined}>
              <Grid container direction="column" alignItems="center" spacing={0}>
                {isRider && (
                  <Grid item>
                    <div className="special-tag">
                      <img alt="rider" src="img/rider_small.png" />
                    </div>
                  </Grid>
                )}
                {isILAS && (
                  <Grid item>
                    <div className="special-tag">
                      <img alt="ilas" src="img/ilas_small.png" />
                    </div>
                  </Grid>
                )}
                {isDraft && (
                  <Grid item>
                    <div className="special-tag">
                      <img alt="draft" src="img/draft.png" />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={showPolicyName ? 3 : undefined}
          sm={showPolicyName ? (showPolicyNumber ? 2 : 5) : undefined}
          style={{ flexGrow: showPolicyName ? undefined : 1 }}
        >
          <Grid container>
            <Grid item>
              {showVHIS && <span className="vhisPlan">{intl.formatMessage({ id: 'vhisIcon' })} </span>}
              {showQDAP && <span className="qdapPlan">{intl.formatMessage({ id: 'QDAP' })} </span>}
            </Grid>
            <Grid item>
              {showPolicyName ? (
                <div className="policy-name">{isCompanyPolicy ? policy.companyName : policy.policyName} </div>
              ) : (
                value
              )}
            </Grid>
          </Grid>
        </Grid>

        {showPolicyNumber && (
          <Grid item sm={3}>
            <Grid container>
              <Grid item>
                <div className="policy-name">{policy.policyNumber ? policy.policyNumber : ''} </div>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={showPolicyName ? 5 : undefined} sm={showPolicyName ? 4 : undefined}>
          <Grid container alignItems="center" justify="flex-end">
            {showPolicyName && (
              <Grid item style={{ paddingRight: 6, flexGrow: 1 }}>
                {value}
              </Grid>
            )}
            <Grid item xs={showPolicyName ? 3 : undefined}>
              <Grid container justify="flex-end" alignItems="center">
                {isInsuredNotMe && (
                  <Grid item style={{ paddingRight: 6 }}>
                    <div className="special-tag">
                      <img alt="insured-not-me" src="img/notme.png" />
                    </div>
                  </Grid>
                )}
                <Grid item>
                  <div>
                    {isMedical && infoType === 'Protection'
                      ? roomClass
                        ? intl.formatMessage({ id: roomClass })
                        : ''
                      : formattedPercentage}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {onClick && (
              <Grid item>
                <NavigateNextIcon color="primary" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(PolicyEntry);
