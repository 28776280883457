import { fetch } from '../../utils';
import Backend from '../../backend';
import { tracking } from '../../utils/tracking';

//API TO SUBMIT THE ADVISER MESSAGE
export const submitAdviserMessage = data => {
  return fetch(Backend.methodUrl('submitAdviserMessage'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE ADVISER MESSAGE
export const getAdvisorMessage = id => {
  return fetch(Backend.methodUrl('getAdvisorMessage') + '/' + id, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO SUBMIT THE REFERRAL
export const submitReferral = data => {
  return fetch(Backend.methodUrl('submitReferral'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      // track if success
      if (payload.success) {
        tracking(`User Submit Referral Success`, { adviserId: data.advisorId });
      }
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE REFERRAL RECORDS
export const getReferralRecords = () => {
  return fetch(Backend.methodUrl('getReferralRecords'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE COUNTS OF NEW RERERRALS TO SHOW AS A NOTIFICATION
export const getNewReferralCount = () => {
  return fetch(Backend.methodUrl('getNewReferralCount'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO REFRESH THE NEW REFERRAL (UPDATE THE KEYS OF NEWCLIENT FROM TRUE TO FALSE TO SHOW ONLY ONCE)
export const refreshNewReferrals = () => {
  return fetch(Backend.methodUrl('refreshNewReferrals'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API to update referral by id
export const updateReferralById = (referralId, updates) => {
  return fetch(Backend.methodUrl('referrals') + `/${referralId}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(updates)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO DELETE THE RECORDS OF REFERRAL
export const deleteRecord = id => {
  return fetch(Backend.methodUrl('deleteRecord') + '/' + id, {
    method: 'DELETE'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { error };
    });
};
