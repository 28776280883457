import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import './user/user.scss';
import { Bar } from 'react-chartjs-2';
import { ExpandIconButton } from '../../../../components/AddIconButton/AddIconButton';
import BlockingOverlay from '../../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import { isSubscriptionAllowAction } from '../../../../utils/user-subscription-store';
import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import AlertPopUp from '../../../../components/AlertPopup';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SlideTransition } from '../../../../components/Transition/Transition';
import ProRequiredCard from '../../../../components/SubscriptionComponents/ProRequiredCard/ProRequiredCard';
import Grid from '@material-ui/core/Grid';
import { checkAssistantDataExist, updateAssistantData } from '../../../../utils/assistant';
import Typography from '@material-ui/core/Typography';
import UserDistributionChart from './user/UserDistributionChart/UserDistributionChart';
import { getRandomClient, getRandomTargetClient } from '../../../../utils/client';
import ChosenClient from './user/ChosenClient/ChosenClient';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import PolicyCategoryTiles from './user/PolicyCategoryTiles/PolicyCategoryTiles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getAveragePremiumSetting, getPotentialBusiness } from '../../../../utils/potential-business';
import AveragePremiumSettingDialog from './user/AveragePremiumSettingDialog/AveragePremiumSettingDialog';
import GuidanceTooltip from '../../../../components/guidelines/GuidanceTooltip/GuidanceTooltip';
import TopClientStatistics from './user/TopClientStatistics/TopClientStatistics';
import { DEFAULT_COLORS } from '../../../../constants/color';

const getSelectedClients = (props, state) => {
  const stateObj = {};
  const { targetClient, luckyClient } = state;
  if (props.clients && props.clients.length > 0) {
    if (!targetClient) {
      stateObj.targetClient = getRandomTargetClient(props.clients);
    }
    if (!luckyClient) {
      stateObj.luckyClient = getRandomClient(props.clients);
    }
  }
  return stateObj;
};

class UserView extends PureComponent {
  constructor(props) {
    super(props);

    const selectedClients = getSelectedClients(props, {});

    this.state = {
      modalIsOpen: false,
      paymentOptions: 'More functions',
      bussinessAnalysisModal: false,
      confirmedBiz: 0,
      clientStatusModal: false,
      reminderInactiveModalIsOpen: false,
      birthdayChartModal: false,
      discChartModal: false,
      tagAnalysisModal: false,
      tagAnalysisData: {
        selectedTag: undefined,
        data: [],
        label: [],
        backgroundColor: []
      },
      moreFunctionsExpanded: false,
      ...selectedClients
    };
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.reminderNotActiveModal = this.reminderNotActiveModal.bind(this);
    this.openTagModal = this.openTagModal.bind(this);
    this.closeTagModal = this.closeTagModal.bind(this);
    this.editTagModalData = this.editTagModalData.bind(this);

    // check & load assistant data if necessary
    checkAssistantDataExist({
      onLoaded: () => {}
    });
  }

  componentDidMount() {
    // check & update assistant data if there is any change in other data
    updateAssistantData()
      .then()
      .catch();

    const { intl, history, runGuideline } = this.props;
    const guidanceState = _.get(history, 'location.state.guidance');
    if (guidanceState) {
      let state = { ...history.location.state };
      delete state.guidance;
      history.replace({ ...history.location, state });

      const run = () => {
        runGuideline(guidanceState, {
          parameters: {
            intl: intl
          },
          tooltipComponent: GuidanceTooltip
        });
      };

      run();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillUnmount() {
    this.props.endGuideline();
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
    //this.props.connectedUserPolicies();
    //this.props.adviserBusinessAnalysis();
    //this.props.getDuePolicy();
    // this.props.fetchFigureAdviser();
    localStorage.removeItem('myDetails');
    localStorage.removeItem('personalData');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let policyData = nextProps.policiesData;
    let life = policyData && policyData.life && policyData.life;
    let illness = policyData && policyData.illness && policyData.illness;
    let medical = policyData && policyData.medical && policyData.medical;
    let saving = policyData && policyData.saving && policyData.saving;
    let ilas = policyData && policyData.ilas && policyData.ilas;
    let stateObj = {
      life: life,
      illness: illness,
      medical: medical,
      saving: saving,
      ilas: ilas
    };

    if (nextProps.getFigureAdviserPhase === true) {
      stateObj.confirmedBiz =
        nextProps.figureAdviserData && nextProps.figureAdviserData.confirmedBiz
          ? nextProps.figureAdviserData.confirmedBiz
          : 0;
    }

    if (nextProps.updateFigureAdviserPhase === true) {
      this.props.fetchFigureAdviser();
    }

    stateObj = _.merge(stateObj, getSelectedClients(nextProps, this.state));

    this.setState(stateObj);
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      bussinessAnalysisModal: false,
      confirmedBiz: 0,
      clientStatusModal: false,
      reminderInactiveModalIsOpen: false
    });
  }

  openTagModal() {
    let usedTags = _.get(this.props.userDetails, 'usedTags', []);
    this.setState({
      tagAnalysisModal: true,
      tagAnalysisData: {
        selectedTag: undefined,
        data: usedTags.map(tag => {
          return tag.numberOfUses;
        }),
        label: usedTags.map(tag => {
          return tag.text;
        }),
        backgroundColor: usedTags.map(tag => {
          return tag.color;
        })
      }
    });
  }

  closeTagModal() {
    let usedTags = _.get(this.props.userDetails, 'usedTags', []);
    this.setState({
      tagAnalysisModal: false,
      tagAnalysisData: {
        selectedTag: undefined,
        data: usedTags.map(tag => {
          return tag.numberOfUses;
        }),
        label: usedTags.map(tag => {
          return tag.text;
        }),
        backgroundColor: usedTags.map(tag => {
          return tag.color;
        })
      }
    });
  }

  editTagModalData(tag) {
    let clientTagUsage = this.props.clientTagUsage;
    let tmp = clientTagUsage.find(value => {
      return value.text === tag.text;
    });
    this.setState({
      tagAnalysisData: {
        selectedTag: tag,
        data: tmp.data.numberOfUses,
        label: tmp.data.text,
        backgroundColor: tmp.data.color
      }
    });
  }

  reminderNotActiveModal() {
    // if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) return;
    this.setState({ reminderInactiveModalIsOpen: true });
  }

  handleChange(value) {
    if (value === 'More functions') {
      this.setState({ paymentOptions: value });
      //this.setState({ modalIsOpen: true });
    } else if (value === 'disc-counting') {
      this.setState({ discChartModal: true });
    } else if (value === 'tag-analysis') {
      this.openTagModal();
    } else {
      if (value === 'Clients connection status') {
        this.setState({ clientStatusModal: true });
      }
      if (value === 'Long time no see') {
        this.props.history.push({
          pathname: '/client',
          select: 'Long time no see'
        });
      }
      if (value === 'remindMe') {
        this.props.history.push({
          pathname: '/client',
          select: 'remindMe'
        });
      }
      if (value === 'allReminder') {
        this.props.history.push({
          pathname: '/client',
          select: 'allReminder'
        });
      }
      this.setState({ modalIsOpen: true });
    }
  }

  //To save the confirmesBiz
  saveConfirmedBiz() {
    let obj = {};
    obj.confirmedBiz = this.state.confirmedBiz;
    // this.props.updateUserDetails(obj);
    this.props.updateFigureAdviser(obj);
    this.setState({ bussinessAnalysisModal: false });
  }

  targetClientHint = () =>
    this.props.toggleDialog('moreInfo', {
      titleIntlId: 'bigdata-target-hint-title',
      contentIntlId: 'bigdata-target-hint-content'
    });

  luckyClientHint = () =>
    this.props.toggleDialog('moreInfo', {
      titleIntlId: 'bigdata-random-hint-title',
      contentIntlId: 'bigdata-random-hint-content'
    });

  checkTargetClient = () => {
    const { history } = this.props;
    const { targetClient } = this.state;
    if (targetClient) {
      history.push({
        pathname: '/client',
        select: 'lucky',
        selectedClient: targetClient,
        smartIdea: 'smart-idea-lucky-hint'
      });
    }
  };

  checkLuckyClient = () => {
    const { history } = this.props;
    const { luckyClient } = this.state;
    if (luckyClient) {
      history.push({
        pathname: '/client',
        select: 'lucky',
        selectedClient: luckyClient,
        smartIdea: 'smart-idea-lucky-hint'
      });
    }
  };

  render() {
    const {
      intl,
      GetConnectedUsersBirthday,
      getUsersGender,
      getClientStatusData,
      GetConnectedUsersDiscCount,
      policyDueClientCount,
      remindMeClientCount,
      birthdayClientCount,
      paidUpPolicyClientCount,
      averagePremiumData,
      userDetails
    } = this.props;
    const { discChartModal, tagAnalysisModal, targetClient, luckyClient } = this.state;
    let user = this.props.userDetails;
    let usedTags = _.get(user, 'usedTags', []);

    let totalClientsCount = _.get(getClientStatusData, 'totalClientsCount', 0);
    let inactiveCount = _.get(getClientStatusData, 'inactiveCount', 0);
    let pendingCount = _.get(getClientStatusData, 'pendingCount', 0);
    let connectedCount = _.get(getClientStatusData, 'connectedCount', 0);
    let disconnectedCount = _.get(getClientStatusData, 'disconnectedCount', 0);
    // const activeReminder = _.get(this.props, 'getActiveReminder', []);
    // let reminderOff = false;
    // let ActiveClientArray = [];
    // if (activeReminder && activeReminder.length > 0) {
    //   activeReminder.forEach(word => {
    //     if (isClientRemindMe(word, user.email)) {
    //       ActiveClientArray.push(word);
    //     }
    //   });
    // }
    // if (ActiveClientArray.length > 0) {
    //   reminderOff = true;
    // }
    let inactiveCountPercentage = totalClientsCount > 0 ? ((inactiveCount / totalClientsCount) * 100).toFixed(0) : 0;
    let pendingCountPercentage = totalClientsCount > 0 ? ((pendingCount / totalClientsCount) * 100).toFixed(0) : 0;
    let connectedCountPercentage = totalClientsCount > 0 ? ((connectedCount / totalClientsCount) * 100).toFixed(0) : 0;
    let disconnectedCountPercentage =
      totalClientsCount > 0 ? ((disconnectedCount / totalClientsCount) * 100).toFixed(0) : 0;
    let totalCountMinusDisplay = '';
    if (disconnectedCount > 0) {
      totalCountMinusDisplay = ' - ' + disconnectedCount + ' = ' + (totalClientsCount - disconnectedCount);
    }
    //Logic to calculate the QEND and YEAREND keys
    // let currentYear = moment().format('YYYY');
    // let currentMonth = parseInt(moment().format('MM'));
    // let Q =
    //   currentMonth <= 3
    //     ? currentYear + '-03-31'
    //     : currentMonth <= 6
    //       ? currentYear + '-06-30'
    //       : currentMonth <= 9
    //         ? currentYear + '-09-30'
    //         : currentMonth <= 12
    //           ? currentYear + '-12-31'
    //           : '';
    // let currentDate = moment().startOf('day');
    // let endDate =
    //   userDetails && userDetails.quarterEndDate ? moment.parseZone(userDetails.quarterEndDate).format('YYYY-MM-DD') : Q;
    // let QEndDate = moment(endDate).toDate();
    // let QEndMonth = parseInt(moment(endDate).format('MM'));
    // let yearEnd = moment(moment(currentYear + '-12-31').toDate()).diff(currentDate, 'days');
    // let QEnd = 0;
    // if (moment(QEndDate).isAfter(currentDate)) {
    //   QEnd = moment(QEndDate).diff(currentDate, 'days');
    // } else if (QEndMonth >= currentMonth) {
    //   QEnd = '-';
    // } else {
    //   QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
    //   QEnd = QEnd > 92 ? 92 : QEnd <= 92 && QEnd > 0 ? QEnd : '-';
    // }
    // yearEnd = yearEnd > 0 ? yearEnd : '-';

    // let productionEnv = process.env.NODE_ENV === 'production' ? true : false; //Temp switch for testing
    const { life, illness, medical, saving, ilas } = this.state;
    let male = _.get(getUsersGender, 'male', 0);
    let female = _.get(getUsersGender, 'female', 0);
    let nullGender = _.get(getUsersGender, 'nullGender', 0);
    let birthdayStatistics =
      GetConnectedUsersBirthday !== undefined && GetConnectedUsersBirthday.statistics !== undefined
        ? GetConnectedUsersBirthday.statistics
        : undefined;

    let preMalePercentage = ((male * 100) / (male + female + nullGender)) //calculate the male percentage
      .toFixed(0);
    let preFemalePercentage = ((female * 100) / (male + female + nullGender)) //calculate the female percentage
      .toFixed(0);

    let malePercentage = preMalePercentage === 'NaN' ? 0 : preMalePercentage;
    let femalePercentage = preFemalePercentage === 'NaN' ? 0 : preFemalePercentage;

    let birthdayChartData = [],
      birthdayChartStepSize;
    if (birthdayStatistics !== undefined) {
      birthdayChartData = Object.values(birthdayStatistics).filter((value, index, arr) => index < arr.length - 1);
      let max = Math.max(...birthdayChartData);
      if (max <= 15) {
        birthdayChartStepSize = 1;
      } else if (max > 15 && max <= 75) {
        birthdayChartStepSize = 5;
      } else if (max > 75 && max <= 150) {
        birthdayChartStepSize = 10;
      } else {
        birthdayChartStepSize = 20;
      }
    }

    const averagePremiumSetting = getAveragePremiumSetting(
      _.get(userDetails, 'averagePremiumSetting'),
      averagePremiumData
    );

    return (
      <div className="big-data-user">
        <AveragePremiumSettingDialog />
        <Dialog
          open={this.state.birthdayChartModal}
          onClose={() => this.setState({ birthdayChartModal: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
          keepMounted
        >
          <DialogTitle id="form-dialog-title">{intl.formatMessage({ id: 'bday_chart_title' })}</DialogTitle>
          <DialogContent>
            {birthdayStatistics !== undefined && (
              <div>
                <Bar
                  // width={window.innerWidth}
                  // height={window.innerHeight * 0.45}
                  options={{
                    layout: {
                      padding: {
                        left: 0,
                        right: 0,
                        top: 20,
                        bottom: 0
                      }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                      display: false
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            stepSize: birthdayChartStepSize,
                            beginAtZero: true,
                            min: 0
                          },
                          scaleLabel: {
                            fontSize: 10
                          }
                        }
                      ]
                    },
                    plugins: {
                      datalabels: {
                        display: false
                      }
                    }
                  }}
                  data={{
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    datasets: [
                      {
                        backgroundColor: DEFAULT_COLORS,
                        data: birthdayChartData,
                        maxBarThickness: 15
                      }
                    ]
                  }}
                />

                <div className="birthday-chart-hint">
                  {intl.formatMessage({ id: 'birthday_chart_na_hint' })}: {birthdayStatistics.na}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ birthdayChartModal: false })} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Grid
          container
          spacing={1}
          className={'clientDash advisorCard' + (this.state.moreFunctionsExpanded ? '' : ' clientDashPadding')}
        >
          <ProRequiredCard sectionTitleId="Big Data" style={{ width: '100%', marginTop: 10 }} />

          <Grid item xs={12} md={6}>
            <div className="cardAnalysis adv-dash-card">
              <Grid container alignItems="stretch">
                <Grid item xs={6}>
                  <div className="cardAnalysis client-dashboard-gender">
                    <div className="client-dashboard-gender-chart flex-box">
                      <IconButton
                        aria-label="statistics"
                        className="client-statistics-button"
                        onClick={event => {
                          event.stopPropagation();
                          this.setState({ clientStatusModal: true });
                        }}
                      >
                        <ZoomInIcon style={{ fontSize: 24 }} />
                      </IconButton>
                      <UserDistributionChart counts={{ male: male, female: female, unknown: nullGender }} />
                    </div>
                    <div className="client-dashboard-gender-overview flex-box">
                      <img alt="" src="img/male.svg" />
                      <Typography color="textSecondary">{malePercentage}%</Typography>
                      <img alt="" src="img/female.svg" />
                      <Typography color="textSecondary">{femalePercentage}%</Typography>
                    </div>
                  </div>
                </Grid>
                <BlockingOverlay overlayStyle={{ borderRadius: '6px' }}>
                  <Grid item xs={6}>
                    <Grid container className="grid-btn-container">
                      <Grid item xs={6}>
                        <div className="cardAnalysis">
                          <div
                            id="Tag_analysis"
                            onClick={event => {
                              event.stopPropagation();
                              this.openTagModal();
                            }}
                          >
                            <div>
                              <img alt="" src="img/tag_analysis.svg" />
                            </div>
                            <div>
                              <Typography color="textSecondary">
                                {intl.formatMessage({ id: 'Tag_analysis' })}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="cardAnalysis">
                          <div
                            id="disc"
                            onClick={event => {
                              event.stopPropagation();
                              this.setState({ discChartModal: true });
                            }}
                          >
                            <div>
                              <img alt="" src="img/disc.svg" />
                            </div>
                            <div>
                              <Typography color="textSecondary">D.I.S.C</Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="cardAnalysis">
                          <div
                            id="birthday"
                            onClick={event => {
                              event.stopPropagation();
                              this.setState({ birthdayChartModal: true });
                            }}
                          >
                            <div>
                              <img alt="" src="img/Birthday_analysis.svg" />
                            </div>
                            <div>
                              <Typography color="textSecondary">{intl.formatMessage({ id: 'Birthday' })}</Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="cardAnalysis">
                          <div
                            id="long-time-no-see"
                            onClick={() => {
                              if (
                                !isSubscriptionAllowAction({
                                  type: 'core',
                                  nicknames: ['Pro', 'Ultra']
                                })
                              )
                                return;
                              this.props.history.push({
                                pathname: '/client',
                                select: 'Long time no see'
                              });
                            }}
                          >
                            <div>
                              <img alt="" src="img/last_meeting.svg" />
                            </div>
                            <div>
                              <Typography color="textSecondary">
                                {intl.formatMessage({ id: 'Long time no see' })}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </BlockingOverlay>
              </Grid>

              <Grid container style={{ padding: '6px 3px 9px 3px' }}>
                <Grid item xs={6} id="target-client">
                  <ChosenClient
                    img="img/HP.svg"
                    client={targetClient}
                    onHint={this.targetClientHint}
                    onClick={targetClient && this.checkTargetClient}
                  />
                </Grid>
                <Grid item xs={6} id="random-client">
                  <ChosenClient
                    img="img/random.svg"
                    client={luckyClient}
                    onHint={this.luckyClientHint}
                    onClick={luckyClient && this.checkLuckyClient}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="stretch"
                direction="row"
                justify="center"
                className="cardAnalysis client-dashboard-number-div"
              >
                {/*<BlockingOverlay overlayStyle={{ top: '-10px', height: 'calc(100% + 18px)', fontSize: '60%' }}>*/}
                <Grid item xs={3}>
                  <Link
                    to={{
                      pathname: '/adviser_business',
                      search: '?businessTabIndex=1&toDoListsExpanded=policy-due'
                    }}
                  >
                    <div className="colon">:</div>
                    <div className="client-dashboard-bignumber">{policyDueClientCount}</div>
                    <div className="client-dashboard-subtitle">
                      <div>{intl.formatMessage({ id: 'Renewal' })}</div>
                    </div>
                  </Link>
                  <div
                    style={{
                      position: 'absolute',
                      height: '100%',
                      top: 0,
                      right: '-3px',
                      borderRight: '2px solid white'
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Link
                    to={{
                      pathname: '/adviser_business',
                      search: '?businessTabIndex=1&toDoListsExpanded=remind-me'
                    }}
                  >
                    <div className="colon">:</div>
                    <div className="client-dashboard-bignumber">{remindMeClientCount}</div>
                    <div className="client-dashboard-subtitle">
                      <div>{intl.formatMessage({ id: 'Remind' })}</div>
                    </div>
                  </Link>

                  <div
                    style={{
                      position: 'absolute',
                      height: '100%',
                      top: 0,
                      right: '-1px',
                      borderRight: '2px solid white'
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Link
                    to={{
                      pathname: '/adviser_business',
                      search: '?businessTabIndex=1&toDoListsExpanded=birthday'
                    }}
                  >
                    <div className="colon">:</div>
                    <div className="client-dashboard-bignumber">{birthdayClientCount}</div>
                    <div className="client-dashboard-subtitle">
                      <div>{intl.formatMessage({ id: 'Birthday' })}</div>
                    </div>
                  </Link>
                </Grid>
                {/*</BlockingOverlay>*/}

                <Grid item xs={3}>
                  <Link
                    to={{
                      pathname: '/adviser_business',
                      search: '?businessTabIndex=1&toDoListsExpanded=paid-up-policy'
                    }}
                  >
                    <div className="colon">:</div>
                    <div className="client-dashboard-bignumber">{paidUpPolicyClientCount}</div>
                    <div className="client-dashboard-subtitle">
                      <div>{intl.formatMessage({ id: 'BigD-Paid-up policy' })}</div>
                    </div>
                  </Link>
                  <div
                    style={{
                      position: 'absolute',
                      height: '100%',
                      top: 0,
                      left: '-3px',
                      borderRight: '2px solid white'
                    }}
                  />
                </Grid>
              </Grid>

              <BlockingOverlay>
                <div className="policy-category-tiles-wrapper" style={{ borderRadius: 5, padding: '0 7px' }}>
                  <Swiper
                    spaceBetween={7}
                    navigation
                    pagination={{ clickable: true }}
                    onTouchStart={() => this.props.toggleSwipeable('bigData', false)}
                    onTouchEnd={() => this.props.toggleSwipeable('bigData', true)}
                  >
                    <SwiperSlide>
                      <PolicyCategoryTiles
                        headingIntlId="Client without Coverage"
                        life={life && life.lifeCount}
                        medical={medical && medical.medicalCount}
                        ci={illness && illness.illnessCount}
                        saving={saving && saving.savingCount}
                        ilas={ilas && ilas.ilasCount}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <PolicyCategoryTiles
                        className="potential-biz-tiles"
                        headingIntlId="Client-wo-Coverage-Biz"
                        life={getPotentialBusiness(
                          life && life.lifeCount,
                          averagePremiumSetting.life,
                          averagePremiumSetting.confidentLevel
                        )}
                        medical={getPotentialBusiness(
                          medical && medical.medicalCount,
                          averagePremiumSetting.medical,
                          averagePremiumSetting.confidentLevel
                        )}
                        ci={getPotentialBusiness(
                          illness && illness.illnessCount,
                          averagePremiumSetting.ci,
                          averagePremiumSetting.confidentLevel
                        )}
                        saving={getPotentialBusiness(
                          saving && saving.savingCount,
                          averagePremiumSetting.saving,
                          averagePremiumSetting.confidentLevel
                        )}
                        ilas={getPotentialBusiness(
                          ilas && ilas.ilasCount,
                          averagePremiumSetting.ilas,
                          averagePremiumSetting.confidentLevel
                        )}
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </BlockingOverlay>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TopClientStatistics />
              </Grid>
              <Grid item xs={12}>
                <div className="cardAnalysis" style={{ marginTop: 0 }}>
                  <div className="form-group adv-dash-select select__flex">
                    <ExpansionPanel
                      className="more-functions"
                      onChange={() => this.setState({ moreFunctionsExpanded: !this.state.moreFunctionsExpanded })}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandIconButton />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        {intl.formatMessage({
                          id: 'More functions'
                        })}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div>
                          {/* <Button value="More functions">
                      {intl.formatMessage({
                        id: 'More functions'
                      })}
                    </Button> */}
                          <div>
                            <Button onClick={() => this.handleChange('Clients connection status')}>
                              {intl.formatMessage({
                                id: 'Clients connection status'
                              })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('Long time no see')}>
                              {intl.formatMessage({ id: 'Long time no see' })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('remindMe')}>
                              {intl.formatMessage({ id: 'Remind me' })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('allReminder')}>
                              {intl.formatMessage({ id: 'All Remind me' })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('disc-counting')}>
                              {intl.formatMessage({ id: 'Personality counting' })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('tag-analysis')}>
                              {intl.formatMessage({ id: 'Tag_analysis' })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('2')}>
                              {intl.formatMessage({
                                id: 'Top 5 clients who login in last month'
                              })}
                            </Button>
                          </div>
                          <div>
                            <Button onClick={() => this.handleChange('3')}>
                              {intl.formatMessage({
                                id: 'Top 5 premium paying clients'
                              })}
                            </Button>
                          </div>
                          {/* <option value="4">
                      {intl.formatMessage({
                        id: 'Clients without Policy'
                      })}
                    </option>
                    <option value="5">
                      {intl.formatMessage({
                        id: 'Clients with Life cover less than ....'
                      })}
                    </option>
                    <option value="6">
                      {intl.formatMessage({
                        id: 'Clients with CI cover less than ....'
                      })}
                    </option> */}
                          <div>
                            <Button onClick={() => this.handleChange('1')}>
                              {intl.formatMessage({ id: 'Age Distribution' })}
                            </Button>
                          </div>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <AlertPopUp
          intl={intl}
          open={this.state.modalIsOpen}
          handleClose={this.closeModal}
          title={intl.formatMessage({ id: 'more_function_title' })}
          content={intl.formatMessage({
            id: 'The selected item is a premium function'
          })}
          showOk={true}
        />
        <Dialog
          open={this.state.reminderInactiveModalIsOpen}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'client_reminder' })}</DialogTitle>
          <DialogContent>
            {intl.formatMessage({
              id: 'The icon would be enabled if there is Client Reminder due in 1 month'
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <Dialog
            open={this.state.clientStatusModal}
            onClose={this.closeModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={SlideTransition}
            fullWidth={true}
            className="modalwidth__mobileview"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {intl.formatMessage({ id: 'Clients connection status' })}
            </DialogTitle>
            <DialogContent>
              <div>
                <div className="row ">
                  <div className="col-md-6 col-xs-6 text-right">
                    <div className="adv_client_status_key figureColor font120">
                      {intl.formatMessage({ id: 'Total clients' })} :
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left">
                    <div className="adv_client_status_key client_status_val figureColor font120">
                      <span style={{ width: '100%' }} className="adv_client_count">
                        {' '}
                        {totalClientsCount}
                        {totalCountMinusDisplay}
                      </span>
                    </div>
                  </div>
                </div>
                <Link
                  to={{
                    pathname: '/client',
                    select: 'Inactive'
                  }}
                >
                  <div className="row">
                    <div className="col-md-6 col-xs-6 text-right">
                      <div className="adv_client_status_key">
                        {intl.formatMessage({ id: 'Inactive' })}
                        <img alt="" src="img/doughnut_chart_half.svg" /> :
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6 text-left">
                      <div className="adv_client_status_key client_status_val">
                        <span className="adv_client_count">{inactiveCount}</span>
                        {'  '}
                        <span className="adv_client_per">
                          ({inactiveCountPercentage}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: '/client',
                    select: 'Pending'
                  }}
                >
                  <div className="row">
                    <div className="col-md-6 col-xs-6 text-right">
                      <div className="adv_client_status_key">
                        {intl.formatMessage({ id: 'Pending' })}
                        <img alt="" src="img/doughnut_pending.svg" /> :
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6 text-left">
                      <div className="adv_client_status_key client_status_val">
                        <span className="adv_client_count"> {pendingCount}</span>
                        {'  '}
                        <span className="adv_client_per">
                          ({pendingCountPercentage}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: '/client',
                    select: 'Connected'
                  }}
                >
                  <div className="row">
                    <div className="col-md-6 col-xs-6 text-right">
                      <div className="adv_client_status_key">
                        {intl.formatMessage({ id: 'Connected' })}
                        <img alt="" src="img/doughnut_chart_blue.svg" /> :
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6 text-left">
                      <div className="adv_client_status_key client_status_val">
                        <span className="adv_client_count">{connectedCount}</span>
                        {'  '}
                        <span className="adv_client_per">
                          ({connectedCountPercentage}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: '/client',
                    select: 'Disconnected'
                  }}
                >
                  <div className="row">
                    <div className="col-md-6 col-xs-6 text-right">
                      <div className="adv_client_status_key">
                        {intl.formatMessage({ id: 'Disconnected' })}
                        <img alt="" src="img/doughnut_chart_grey.svg" /> :
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-6 text-left">
                      <div className="adv_client_status_key client_status_val">
                        <span className="adv_client_count">{disconnectedCount}</span>
                        {'  '}
                        <span className="adv_client_per">
                          ({disconnectedCountPercentage}
                          %)
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeModal} color="primary">
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={discChartModal}
            onClose={() => this.setState({ discChartModal: false })}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={SlideTransition}
            fullWidth={true}
            keepMounted
          >
            <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'Personality' })}</DialogTitle>
            <DialogContent>
              <DialogContentText>{intl.formatMessage({ id: 'Personality counting' })}</DialogContentText>
              <Bar
                // width={window.innerWidth}
                // height={window.innerHeight * 0.45}
                options={{
                  layout: {
                    padding: {
                      left: 0,
                      right: 0,
                      top: 20,
                      bottom: 0
                    }
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          stepSize: birthdayChartStepSize,
                          beginAtZero: true,
                          min: 0
                        },
                        scaleLabel: {
                          fontSize: 10
                        }
                      }
                    ]
                  },
                  plugins: {
                    datalabels: {
                      display: false
                    }
                  }
                }}
                data={{
                  labels: [
                    intl.formatMessage({ id: 'personality_D' }),
                    intl.formatMessage({ id: 'personality_I' }),
                    intl.formatMessage({ id: 'personality_S' }),
                    intl.formatMessage({ id: 'personality_C' })
                  ],
                  datasets: [
                    {
                      backgroundColor: ['#EA8462', '#EAC862', '#62EA84', '#62A6EA'],
                      categoryPercentage: 0.5,
                      barPercentage: 1.0,
                      maxBarThickness: 15,
                      data: [
                        _.get(GetConnectedUsersDiscCount, '[0].dCount', 0),
                        _.get(GetConnectedUsersDiscCount, '[0].iCount', 0),
                        _.get(GetConnectedUsersDiscCount, '[0].sCount', 0),
                        _.get(GetConnectedUsersDiscCount, '[0].cCount', 0)
                      ]
                    }
                  ]
                }}
              />

              <div className="birthday-chart-hint">
                {intl.formatMessage({ id: 'disc_chart_na_hint' })}:{' '}
                {_.get(GetConnectedUsersDiscCount, '[0].naCount', 0)}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ discChartModal: false })} color="primary">
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={tagAnalysisModal}
            onClose={() => this.closeTagModal()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={SlideTransition}
            fullWidth={true}
            keepMounted
          >
            <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'Tag_analysis' })}</DialogTitle>
            <div className="reset-wrapper tag-analysis-reset-wrapper">
              <IconButton onClick={this.openTagModal.bind(this)}>
                <RefreshIcon />
              </IconButton>
            </div>
            <DialogContent>
              {intl.formatMessage({ id: 'Click_tag_for_more_info' })}

              <div className="ReactTags__selected" style={{ margin: '10px 0px 0px 0px' }}>
                {usedTags === null || usedTags === undefined
                  ? ''
                  : usedTags.map((value, index) => {
                      return (
                        <span
                          key={index}
                          className="tag-wrapper ReactTags__tag-no-remove"
                          style={{ color: value.color }}
                          onClick={e => this.editTagModalData(value)}
                        >
                          {value.text}
                        </span>
                      );
                    })}
              </div>
              <div className="ReactTags__selected tag-analysis-modal">
                {this.state.tagAnalysisData.selectedTag === undefined ||
                this.state.tagAnalysisData.selectedTag === null ? (
                  intl.formatMessage({ id: 'Tag_usage_overview' }) +
                  (usedTags === null || usedTags === undefined ? ': 0' : ': ' + usedTags.length)
                ) : (
                  <div>
                    <span
                      className="tag-wrapper ReactTags__tag-no-remove"
                      style={{ color: this.state.tagAnalysisData.selectedTag.color }}
                      onClick={e => {
                        localStorage.setItem(
                          'searchTerm',
                          JSON.stringify('#' + this.state.tagAnalysisData.selectedTag.text)
                        );
                        this.props.history.push({
                          pathname: '/client'
                        });
                      }}
                    >
                      {this.state.tagAnalysisData.selectedTag.text}
                    </span>
                    {intl.formatMessage({ id: 'Also_tag_with' })}

                    <div className="reset-wrapper" style={{ float: 'right', margin: '0px 0px 0px 0px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          borderRadius: 4,
                          padding: '0px 6px',
                          minWidth: '30px'
                        }}
                        onClick={e => {
                          localStorage.setItem(
                            'searchTerm',
                            JSON.stringify('#' + this.state.tagAnalysisData.selectedTag.text)
                          );
                          this.props.history.push({
                            pathname: '/client'
                          });
                        }}
                        value={intl.formatMessage({ id: 'Who' })}
                      >
                        {intl.formatMessage({ id: 'Who' })}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <Bar
                // width={window.innerWidth}
                // height={window.innerHeight * 0.45}
                options={{
                  layout: {
                    padding: {
                      left: 0,
                      right: 0,
                      top: 15,
                      bottom: 0
                    }
                  },
                  responsive: true,
                  maintainAspectRatio: true,
                  legend: {
                    display: false
                  },
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          stepSize: birthdayChartStepSize,
                          beginAtZero: true,
                          min: 0
                        },
                        scaleLabel: {
                          fontSize: 10
                        }
                      }
                    ]
                  },
                  plugins: {
                    datalabels: {
                      display: false
                    }
                  }
                }}
                data={{
                  labels: this.state.tagAnalysisData.label,
                  datasets: [
                    {
                      backgroundColor: this.state.tagAnalysisData.backgroundColor,
                      categoryPercentage: 0.5,
                      barPercentage: 1.0,
                      maxBarThickness: 15,
                      data: this.state.tagAnalysisData.data
                    }
                  ]
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.closeTagModal()} color="primary">
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default UserView;
