import React, { useEffect, useState } from 'react';
import { Chip, Grow, hexToRgb, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    color: '#000000ba',
    height: 'fit-content',
    minWidth: 70,
    textAlign: 'center',
    fontSize: '13px',
    width: '100%'
  },
  label: {
    whiteSpace: 'break-spaces',
    padding: '3px 12px'
  }
});

const AdviserInfoTagChip = props => {
  const { color, isIntoView, ...rest } = props;

  const [isIn, setIsIn] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const RGB = hexToRgb(color || theme.palette.primary.main)
    .replace('rgb', 'rgba')
    .replace(')', ', 0.8)');

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setIsIn(true);
  //   }, 500);
  //
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  useEffect(() => {
    setIsIn(false);

    if (isIntoView) {
      const timeoutId = setTimeout(() => {
        setIsIn(true);
      }, 150);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isIntoView]);

  return (
    <Grow in={isIn} timeout={{ appear: 200, enter: 600, exit: 0 }}>
      <Chip
        color="primary"
        style={{
          boxShadow: `rgb(0 0 0 / 12%) 0px 0px 2px, ${RGB} 2px 2px 5px 0px`
        }}
        classes={classes}
        {...rest}
      />
    </Grow>
  );
};

export default AdviserInfoTagChip;
