import { fetch } from '../../utils';
import Backend from '../../backend';

// API to admin team control
export const adminTeam = data => {
  return fetch(Backend.methodUrl('adminTeam'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to admin team control
export const sendTeamInvitation = data => {
  return fetch(Backend.methodUrl('sendTeamInvitation'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to get the team info as Leader
export const getTeamLeaderOf = data => {
  return fetch(Backend.methodUrl('getTeamLeaderOf'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to get the team info as Leader
export const acceptTeamInvitation = data => {
  return fetch(Backend.methodUrl('acceptTeamInvitation'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to the frontend
export const getTeamSetting = data => {
  return fetch(Backend.methodUrl('getTeamSetting'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to the frontend
export const updateTeamSetting = data => {
  return fetch(Backend.methodUrl('updateTeamSetting'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to the frontend
export const getTeamMemberPermission = data => {
  return fetch(Backend.methodUrl('getTeamMemberPermission'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to the frontend
export const verifyExportCode = data => {
  return fetch(Backend.methodUrl('verifyExportCode'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to the frontend
export const generateExportCode = data => {
  return fetch(Backend.methodUrl('generateExportCode'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to delete team member
export const deleteTeamMember = data => {
  return fetch(Backend.methodUrl('deleteTeamMember'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to get team logo and news urls
export const getTeamLogoNews = () => {
  return fetch(Backend.methodUrl('getUserTeamLogoNews'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to updateTagField
export const updateTagField = () => {
  return fetch(Backend.methodUrl('updateTagField'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to checkTagField
export const checkTagField = () => {
  return fetch(Backend.methodUrl('checkTagField'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getTeams = () => {
  return fetch(Backend.methodUrl('teams'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getTeamInvitation = teamInvitationId => {
  return fetch(Backend.methodUrl(`team-invitation/${teamInvitationId}`))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const uploadAttachmentsToFileAttachmentsCategories = (originalCategories, teamId, categoryId, attachments) => {
  const fileAttachmentsCategories = originalCategories.map(category => {
    if (category._id === categoryId) {
      category.attachments = [...attachments, ...category.attachments];
    }
    return category;
  });
  return fetch(Backend.methodUrl(`teams/${teamId}/fileAttachmentsCategories`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fileAttachmentsCategories
    })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const createFileAttachmentsCategory = (categoryName, teamId) => {
  const category = {
    title: categoryName,
    attachments: []
  };

  return fetch(Backend.methodUrl(`teams/${teamId}/fileAttachmentsCategories`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(category)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const editFileAttachmentsCategory = (teamId, categoryId, originalCategories, categoryName) => {
  const fileAttachmentsCategories = originalCategories.map(category => {
    if (category._id === categoryId) {
      category.title = categoryName;
    }
    return category;
  });

  return fetch(Backend.methodUrl(`teams/${teamId}/fileAttachmentsCategories`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fileAttachmentsCategories
    })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteFileAttachmentsCategory = (teamId, categoryId, originalCategories) => {
  const fileAttachmentsCategories = originalCategories.filter(category => {
    return category._id !== categoryId;
  });

  return fetch(Backend.methodUrl(`teams/${teamId}/fileAttachmentsCategories`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fileAttachmentsCategories
    })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteFileFromS3 = object_path => {
  fetch(Backend.methodUrl(`media?object_path=${object_path}`), {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  }).then(res => {
    return res.json();
  });
};

export const deleteFileFromAttachmentsCategory = (teamId, categoryId, fileId, originalCategories) => {
  const fileAttachmentsCategories = originalCategories.map(category => {
    if (category._id === categoryId) {
      category.attachments = category.attachments.filter(file => {
        return file._id !== fileId;
      });
    }
    return category;
  });

  return fetch(Backend.methodUrl(`teams/${teamId}/fileAttachmentsCategories`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fileAttachmentsCategories
    })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const editAttachmentOfCategory = (originalCategories, teamId, categoryId, fileId, file) => {
  const fileAttachmentsCategories = originalCategories.map(category => {
    if (category._id === categoryId) {
      category.attachments = category.attachments.map(_file => {
        if (_file._id === fileId) {
          return {
            ..._file,
            ...file
          };
        }
        return _file;
      });
    }
    return category;
  });
  return fetch(Backend.methodUrl(`teams/${teamId}/fileAttachmentsCategories`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      fileAttachmentsCategories
    })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to get team logo and news urls
export const getTeamStructure = teamId => {
  return fetch(Backend.methodUrl(`teamStructure?teamId=${teamId}`))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
