import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getAffiliate, resetAffiliate } from '../../../store/affiliate/duck';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { CircularProgress, Grid } from '@material-ui/core';
import { ERROR, INIT, LOADING } from '../../../constants/phase';
import { Redirect } from 'react-router';
import { formatUrlWithSearch } from '../../../utils/url';

const WWW_URL = process.env.WWW_URL;
const PFPLUS_URL = process.env.PFPLUS_URL;

const LoadingContainerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const Affiliate = props => {
  const { intl, userDetails, affiliate, getPhase, error, getAffiliate, resetAffiliate } = props;

  const { affiliateId, registeredAdvisers, subscribedAdvisers } = affiliate || {};

  if (getPhase === ERROR) {
    throw new Error(error);
  }

  if (getPhase === true && affiliate && !affiliateId) {
    throw new Error('Affiliate ID is not defined');
  }

  const directPayShareMessage = intl.formatMessage({ id: 'affiliate-msg-direct-pay' });
  const shareMessage = intl.formatMessage({ id: 'affiliate-msg-www' });
  const directPayLink = formatUrlWithSearch(`${PFPLUS_URL}/signup`, {
    planMode: 'afpay',
    couponId: 'AFFIL22PAY',
    aid: affiliateId
  });
  const shareLink = formatUrlWithSearch(`${WWW_URL}/adviser/referral.html`, {
    n: userDetails.name,
    a: affiliateId,
    e: userDetails.email
  });
  const registeredCount = (registeredAdvisers || []).length;
  const subscribedCount = (subscribedAdvisers || []).length;

  useEffect(() => {
    getAffiliate();
  }, [getAffiliate]);

  useEffect(() => {
    return () => {
      resetAffiliate();
    };
  }, [resetAffiliate]);

  return (
    <AppContentContainer className="affiliate">
      {/*Simple loading*/}
      {(getPhase === LOADING || getPhase === INIT) && (
        <div style={LoadingContainerStyle}>
          <CircularProgress color="primary" />
        </div>
      )}

      {getPhase === true &&
        (affiliate ? (
          <div className="refer-adviser">
            <Container className="container" maxWidth="sm">
              <section className="banner-section">
                <img src="img/affiliate/aff_banner.png" alt="affiliate" />
              </section>

              <section>
                <Typography color="primary" style={{ whiteSpace: 'pre-wrap', fontSize: '150%' }}>
                  {intl.formatMessage({ id: 'affiliate-title' })}
                </Typography>
              </section>

              <section>
                <Typography align="left">{intl.formatMessage({ id: 'affiliate-hint-1' })}</Typography>
              </section>

              <section>
                <Typography align="left">{intl.formatMessage({ id: 'affiliate-direct-pay-hint' })}</Typography>
                <ShareButtonGroup
                  title={directPayShareMessage}
                  url={directPayLink}
                  isCTA
                  CopyTextFieldProps={{
                    multiline: true
                  }}
                />
              </section>

              <section>
                <Typography align="left">{intl.formatMessage({ id: 'affiliate-www-hint' })}</Typography>
                <ShareButtonGroup
                  title={shareMessage}
                  url={shareLink}
                  isCTA
                  CopyTextFieldProps={{
                    multiline: true
                  }}
                />
              </section>

              <section className="rewards-section">
                <Card>
                  <List component="div" disablePadding={true}>
                    <ListItem component="div">
                      <ListItemAvatar>
                        <img src="img/refer-adviser/check.svg" alt="check" width={50} />
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        primary={
                          <Grid className="hint" container spacing={1} alignItems="center" justify="center">
                            <Grid item xs={12}>
                              <Typography color="primary">
                                {intl.formatMessage({ id: 'affiliate-progress-title' })}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                  <Typography>
                                    {intl.formatMessage({ id: 'affiliate-progress-no-registered' })}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography style={{ fontSize: '150%' }}>{registeredCount}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container justify="space-between" alignItems="center">
                                <Grid item>
                                  <Typography>
                                    {intl.formatMessage({ id: 'affiliate-progress-no-referral' })}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography color="primary" style={{ fontWeight: 'bold', fontSize: '150%' }}>
                                    {subscribedCount}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  </List>
                </Card>
              </section>
            </Container>
          </div>
        ) : (
          <Redirect to="/home" />
        ))}
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    affiliate: state.affiliate.affiliate,
    getPhase: state.affiliate.getPhase,
    error: state.affiliate.error
  }),
  {
    getAffiliate,
    resetAffiliate
  }
)(Affiliate);

export default injectIntl(container);
