import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import NativeOrWeb from '../../utils/native';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { checkVersion } from '../../store/version/api';
import { SlideTransition } from '../Transition/Transition';
import './VersionIncompatibleDialog.scss';
import { Browser } from '@capacitor/browser';

const DialogStyle = {
  zIndex: 10000
};

const isNative = NativeOrWeb.isNativePlatform();
const platform = NativeOrWeb.getPlatform();

const VersionIncompatibleDialog = props => {
  const { intl } = props;

  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const onClickUpdate = () => {
    if (platform === 'ios') {
      window.open('https://itunes.apple.com/hk/app/portfoplus/id1590830597?mt=8', '_blank');
    } else if (platform === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.portfoplus.PortfoPlus', '_blank');
    }
  };

  const onClickAndroidApk = () => {
    window.open('https://www.portfoplus.com/asset/apk/android_signed_apk.apk', '_blank');
  };

  const goToWeb = () => {
    Browser.open({ url: 'https://app.portfoplus.com/web/' });
  };

  useEffect(() => {
    if (isNative) {
      const check = async () => {
        const isCompatible = await checkVersion();
        setOpen(!isCompatible);
      };
      check()
        .then()
        .catch();
    }
  }, []);

  return isNative ? (
    <Dialog
      className="version-incompatible-dialog"
      open={open}
      fullWidth={true}
      TransitionComponent={SlideTransition}
      style={DialogStyle}
    >
      <DialogContent>
        <span>{intl.formatMessage({ id: 'version-incompatible-dialog-content' })}</span>
        {platform === 'android' &&
          <>
            <span>{' ' + intl.formatMessage({ id: 'download-android-apk-content' })}</span>
            <span
              onClick={onClickAndroidApk}
              style={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}
            >
              {intl.formatMessage({ id: 'android-apk' })}
            </span>
            <span>{intl.formatMessage({ id: '.' })}</span>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={goToWeb}>
          {intl.formatMessage({ id: 'web-version' })}
        </Button>
        <Button color="primary" variant='contained' onClick={onClickUpdate}>
          {intl.formatMessage({ id: 'Update' })}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default injectIntl(VersionIncompatibleDialog);
