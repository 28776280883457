import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, Typography } from '@material-ui/core';
import { createFileAttachmentsCategory } from '../../../../../store/team/api';
import { getTeams } from '../../../../../store/team/duck';
import { useDispatch } from 'react-redux';
import FormSubmitWrapper from '../../../../../components/FormSubmitWrapper/FormSubmitWrapper';

const CreateCategoryDialog = props => {
  const { intl, open, onClose, team } = props;

  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState('');
  const [error, setError] = useState('');

  const createCategory = async () => {
    await createFileAttachmentsCategory(categoryName, team._id);
    dispatch(getTeams());
  };

  const handleCategoryNameChange = e => {
    setError('');
    setCategoryName(e.target.value);
  };

  const handleConfirm = async () => {
    if (categoryName === '') {
      setError(intl.formatMessage({ id: 'team-library-category-dialog-error-input-required' }));
      return;
    }

    await createCategory();
    setCategoryName('');
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{intl.formatMessage({ id: 'team-library-create-category-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'team-library-create-category-dialog-description' })}</Typography>
          </Grid>
          <Grid item>
            <FormSubmitWrapper onSubmit={handleConfirm}>
              <Input
                autoFocus={true}
                value={categoryName}
                onChange={handleCategoryNameChange}
                placeholder={intl.formatMessage({ id: 'team-library-category-dialog-input-placeholder' })}
                fullWidth
                style={{ height: 30 }}
              />
            </FormSubmitWrapper>
          </Grid>
          {error && (
            <Grid item>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(CreateCategoryDialog);
