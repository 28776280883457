import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch, Typography } from '@material-ui/core';
import { updateUserDetails } from '../../../../../../store/user/duck';
import _ from 'lodash';
import { hasCampaignCode } from '../../../../../../utils/user';

const NFCAutoGenerateDialog = (props) => {
  const { intl, userDetails, teamCampaignCodes, open, onClose, updateUserDetails, ...rest } = props;

  const toggleable = hasCampaignCode(userDetails, teamCampaignCodes, 'FEATURE_AUTONFC');
  const [checked, setChecked] = useState(toggleable && !(_.get(userDetails, 'isNfcAutoGenerateDisabled', false)));

  const onChangeChecked = (event) => setChecked(event.target.checked);

  const onSave = () => {
    updateUserDetails({ isNfcAutoGenerateDisabled: !checked });
    onClose();
  };

  useEffect(() => {
    setChecked(toggleable && !(_.get(userDetails, 'isNfcAutoGenerateDisabled', false)));
  }, [userDetails]);

  return (
    <Dialog open={open} fullWidth={true} onClose={onClose} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'nfc-auto-generate-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12}>
            <Typography>{intl.formatMessage({ id: 'nfc-auto-generate-dialog-content' })}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item>
                <Switch checked={checked} onChange={onChangeChecked} color="primary" disabled={!toggleable} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onSave}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  (state) => ({
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes,
  }),
  {
    updateUserDetails,
  },
)(NFCAutoGenerateDialog);

export default injectIntl(container);
