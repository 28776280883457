import { fetch } from '../../utils';
import Backend from '../../backend';

//API TO ADD MESSAGE
export const addMessage = data => {
  return fetch(Backend.methodUrl('addMessage'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO FECTH THE MESSAGES
export const fetchMessage = key => {
  return fetch(Backend.methodUrl('fetchMessage') + `/?uniqueid1=${key.uniqueid1}&uniqueid2=${key.uniqueid2}`, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO SEND THE FEEDBACK
export const feedback = data => {
  return fetch(Backend.methodUrl('feedback'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
