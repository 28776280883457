export const scrollIt = (element, count, duration, callback) => {
  if (element) {
    const reachedBottom = isReachedBottom(element);
    const reachedTop = element.scrollTop === 0;

    const proceedCallback = () => {
      if (callback) {
        callback({ reachedBottom, reachedTop });
      }
    };

    if (count > 0) {
      if (!reachedBottom) {
        element.scrollTop = element.scrollTop + 5;
        setTimeout(() => {
          scrollIt(element, Math.max(count - 5, 0), duration, callback);
        }, duration);
      } else {
        proceedCallback();
      }
    } else if (count < 0) {
      if (!reachedTop) {
        element.scrollTop = element.scrollTop - 5;
        setTimeout(() => {
          scrollIt(element, Math.min(count + 5, 0), duration, callback);
        }, duration);
      } else {
        proceedCallback();
      }
    } else {
      proceedCallback();
    }
  }
};

export const isReachedBottom = element => {
  if (element) {
    return element.scrollHeight - element.scrollTop <= element.clientHeight;
  }
  return false;
};
