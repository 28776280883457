import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { PhoneNumberInputWrapper } from '../../NewSignUp/contents/PhoneNumberInputWrapper';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';
import { updateFactSheet } from '../../../store/client/duck';
import _ from 'lodash';
import { usePrevious } from '../../../utils/hooks';
import { LOADING } from '../../../constants/phase';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import './AddToEWalletDialog.scss';

const FormControlLabelStyle = {
  height: 29,
  marginRight: 0
};

const AddToEWalletDialog = props => {
  const {
    intl,
    open,
    onClose,
    onSuccess,
    viewingClient,
    updateFactSheetPhase,
    updateFactSheetMessage,
    updateFactSheet
  } = props;

  const [internalPhoneNumber, setInternalPhoneNumber] = useState(_.get(viewingClient, 'factsheetId.phoneNumber'));
  const [shouldIncludePersonalPolicies, setShouldIncludePersonalPolicies] = useState(true);
  const [shouldIncludeCompanyPolicies, setShouldIncludeCompanyPolicies] = useState(true);
  const [shouldIncludeGeneralPolicies, setShouldIncludeGeneralPolicies] = useState(true);
  const [shouldIncludePolicyNumber, setShouldIncludePolicyNumber] = useState(true);
  const [shouldIncludeAdviserInfo, setShouldIncludeAdviserInfo] = useState(true);
  const [shouldUseReferralQRCode, setShouldUseReferralQRCode] = useState(true);
  const [language, setLanguage] = useState('auto');
  const [errors, setErrors] = useState({});
  const prevUpdateFactSheetPhase = usePrevious(updateFactSheetPhase);

  const onChangePhoneNumber = event => setInternalPhoneNumber(event.target.value);
  const onChangeShouldIncludePersonalPolicies = event => setShouldIncludePersonalPolicies(event.target.checked);
  const onChangeShouldIncludeCompanyPolicies = event => setShouldIncludeCompanyPolicies(event.target.checked);
  const onChangeShouldIncludeGeneralPolicies = event => setShouldIncludeGeneralPolicies(event.target.checked);
  const onChangeShouldIncludePolicyNumber = event => setShouldIncludePolicyNumber(event.target.checked);
  const onChangeShouldIncludeAdviserInfo = event => setShouldIncludeAdviserInfo(event.target.checked);
  const onChangeShouldUseReferralQRCode = event => setShouldUseReferralQRCode(event.target.checked);
  const onChangeLanguage = event => {
    setLanguage(event.target.value);
  };

  const onSubmit = () => {
    const trimmedPhoneNumber = (internalPhoneNumber || '').trim();

    if (!trimmedPhoneNumber) {
      setErrors({ internalPhoneNumber: intl.formatMessage({ id: 'required' }) });
      return;
    }

    const factsheetId = _.get(viewingClient, 'factsheetId._id');

    updateFactSheet({
      key: 'phoneNumber',
      value: trimmedPhoneNumber,
      id: factsheetId,
      clientId: viewingClient._id
    });
  };

  useEffect(() => {
    if (prevUpdateFactSheetPhase === LOADING && updateFactSheetPhase !== LOADING) {
      if (updateFactSheetPhase === true) {
        onSuccess({
          shouldIncludePersonalPolicies,
          shouldIncludeCompanyPolicies,
          shouldIncludeGeneralPolicies,
          shouldIncludePolicyNumber,
          shouldIncludeAdviserInfo,
          shouldUseReferralQRCode,
          language
        });
      } else {
        setErrors({ internalPhoneNumber: intl.formatMessage({ id: 'Something went wrong' }) });
      }
    }
  }, [
    intl,
    language,
    onSuccess,
    prevUpdateFactSheetPhase,
    shouldIncludeAdviserInfo,
    shouldIncludeCompanyPolicies,
    shouldIncludeGeneralPolicies,
    shouldIncludePersonalPolicies,
    shouldIncludePolicyNumber,
    shouldUseReferralQRCode,
    updateFactSheetPhase
  ]);

  const header = intl.formatMessage({ id: 'add-to-e-wallet-dialog-title' });

  const content = (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <AladdinHint
          title={intl.formatMessage({ id: 'aladdin-demo-video' })}
          helpCenter="c52"
          style={{ marginTop: 0 }}
        />
        <Typography style={{ whiteSpace: 'pre-wrap' }}>
          {intl.formatMessage({ id: 'add-to-e-wallet-dialog-content' })}
        </Typography>
      </Grid>
      <Grid item>
        <StyledTextField
          className="phone-text-field"
          name="phoneNumber"
          label={`${intl.formatMessage({ id: 'add-to-e-wallet-tel' })}`}
          value={internalPhoneNumber}
          onChange={onChangePhoneNumber}
          InputProps={{
            inputComponent: PhoneNumberInputWrapper,
            inputProps: {
              intl: intl
            }
          }}
          InputLabelProps={{ shrink: false, className: 'non-shrink' }}
          error={!!errors.internalPhoneNumber}
          helperText={errors.internalPhoneNumber && errors.internalPhoneNumber}
        />
      </Grid>
      <Grid item>
        <Typography>{intl.formatMessage({ id: 'add-to-e-wallet-content-options' })}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldIncludePersonalPolicies}
                  onChange={onChangeShouldIncludePersonalPolicies}
                />
              }
              label={intl.formatMessage({ id: 'Personal Policy' })}
              style={FormControlLabelStyle}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldIncludeCompanyPolicies}
                  onChange={onChangeShouldIncludeCompanyPolicies}
                />
              }
              label={intl.formatMessage({ id: 'Workplace Policy' })}
              style={FormControlLabelStyle}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldIncludeGeneralPolicies}
                  onChange={onChangeShouldIncludeGeneralPolicies}
                />
              }
              label={intl.formatMessage({ id: 'General Policy' })}
              style={FormControlLabelStyle}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldIncludePolicyNumber}
                  onChange={onChangeShouldIncludePolicyNumber}
                />
              }
              label={intl.formatMessage({ id: 'Policy No.' })}
              style={FormControlLabelStyle}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldIncludeAdviserInfo}
                  onChange={onChangeShouldIncludeAdviserInfo}
                />
              }
              label={intl.formatMessage({ id: 'Adviser Info' })}
              style={FormControlLabelStyle}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldUseReferralQRCode}
                  onChange={onChangeShouldUseReferralQRCode}
                />
              }
              label={intl.formatMessage({ id: 'Refer QRcode' })}
              style={FormControlLabelStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup value={language} onChange={onChangeLanguage}>
              <Grid container spacing={1}>
                {[
                  {
                    value: 'auto',
                    label: intl.formatMessage({ id: 'Auto' })
                  },
                  {
                    value: 'zh-Hant',
                    label: '繁中'
                  },
                  {
                    value: 'en',
                    label: 'ENG'
                  },
                  {
                    value: 'zh-Hans',
                    label: '简中'
                  }
                ].map(({ value, label }) => (
                  <Grid item xs={6} key={value}>
                    <FormControlLabel
                      control={<Radio color="primary" style={{ padding: 4 }} />}
                      value={value}
                      label={label}
                      style={{ marginBottom: 0 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const actions = [
    <Button color="primary" onClick={onSubmit}>
      {intl.formatMessage({ id: 'add-to-e-wallet-cta' })}
    </Button>
  ];

  return viewingClient ? (
    <BottomSheetOrDialog
      className="add-to-e-wallet-dialog"
      open={open}
      onClose={onClose}
      header={header}
      content={content}
      actions={actions}
      BottomSheetProps={{
        // snapPoints: ({ minHeight, maxHeight }) => maxHeight * 0.95,
        // defaultSnap: ({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints),
        expandOnContentDrag: false,
        disableAutoDismiss: true
      }}
    />
  ) : null;
};

const container = connect(
  state => ({
    viewingClient: state.client.viewingClient,
    updateFactSheetPhase: state.client.updateFactSheetPhase,
    updateFactsheetMessage: state.client.updateFactsheetMessage
  }),
  {
    updateFactSheet
  }
)(AddToEWalletDialog);

export default injectIntl(container);
