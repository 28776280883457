import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { switchLanguage } from '../../utils/locale';
import { selectedLocale } from '../../store/locale/action';
import { createUser } from '../../store/user/duck';
import { LOADING } from '../../constants/phase';
import { formatEmail, isEmailValid } from '../../utils/email';
import BackConfirmDialog from '../../components/BackConfirmDialog/BackConfirmDialog';
import ConfirmEmailDialog from '../../components/ConfirmEmailDialog/ConfirmEmailDialog';
import _ from 'lodash';
import IconToast from '../../components/NewToast';
import { toast } from 'react-toastify';
import './ClientSignUp.scss';
import TagManager from 'react-gtm-module';
import { SignInHomePageGrid } from '../ChooseUserType/component';
import { getSearchObject } from '../../utils/router';
import { tracking } from '../../utils/tracking';
import YellowHint from '../../components/YellowHint/YellowHint';
import LoginBannerContainer from '../../components/LoginBannerContainer/LoginBannerContainer';
import LoginStyles from '../Login/LoginStyles';
import { withStyles } from '@material-ui/styles';
import { validatePassword, validatePasswordConfirm } from '../../utils/validators';
import PasswordRulesInfoTooltip from '../../components/PasswordRulesInfoTooltip/PasswordRulesInfoTooltip';

class ClientSignUp extends React.Component {
  constructor(props) {
    super(props);

    const sessionStorageDetails = sessionStorage.getItem('tempRegisterDetails');
    if (sessionStorageDetails) {
      this.state = JSON.parse(sessionStorageDetails);
      sessionStorage.removeItem('tempRegisterDetails');
    } else {
      const searchObject = getSearchObject(props.history);
      const searchObjectEmail = searchObject['loginEmail'];
      const searchObjectConnectionRequestId = searchObject['cr'];
      const isSearchObjectEmailValid = isEmailValid(searchObjectEmail);
      this.state = {
        language: props.userDetails && props.userDetails.language ? props.userDetails.language : 'zh-Hant-HK',
        email: (isSearchObjectEmailValid && searchObject['loginEmail']) || '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        tacChecked: true,
        dmChecked: true,
        errors: {},
        disableBackConfirm: false,
        showConfirmDialog: false,
        checkedConfirm: false,
        registeredDialog: false,
        fixedEmail: isSearchObjectEmailValid && !!searchObject['loginEmail'],
        connectionRequestId: searchObjectConnectionRequestId || undefined,
        connectionRequestTacChecked: false
      };
      switchLanguage(props.selectedLocale, this.state.language);
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('tempRegisterDetails');
  }

  componentDidUpdate(prevProps) {
    const { createMessage, createMessageExtras, createPhase, intl, history } = this.props;
    if (prevProps.createPhase !== false && createPhase === false) {
      const alreadyRegistered =
        createMessage === 'An account with this email address already exists' ||
        createMessage === 'This email should be used for adviser registration';
      this.setState({ showConfirmDialog: false, checkedConfirm: false, registeredDialog: alreadyRegistered });
      if (!alreadyRegistered) {
        toast.info(IconToast('error', intl.formatMessage({ id: createMessage }, createMessageExtras)), {
          className: 'new-toast'
        });
      }
    }
    if (prevProps.createPhase === LOADING && createPhase === true) {
      // Tracking
      tracking('Client sign up');
      TagManager.dataLayer({
        dataLayer: {
          event: 'Policyholders sign up'
        }
      });
      this.setState({ disableBackConfirm: true }, () => history.push('/welcome'));
    }
  }

  // handle inputs
  handleChange = event => {
    const { selectedLocale } = this.props;
    const { errors } = this.state;

    if (event && event.target && event.target.name === 'language') {
      sessionStorage.setItem(
        'tempRegisterDetails',
        JSON.stringify({
          ...this.state,
          language: event.target.value
        })
      );
      switchLanguage(selectedLocale, event.target.value);
      // selectedLocale(event.target.value);
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        errors: { ..._.omit(errors, [event.target.name]) }
      });
    }
  };

  // handle checkboxes
  handleCheck = event => {
    const { errors } = this.state;
    this.setState({
      [event.target.name]: event.target.checked,
      errors: event.target.checked ? { ..._.omit(errors, [event.target.name]) } : errors
    });
  };

  checkErrors = () => {
    const { intl } = this.props;
    const {
      email,
      password,
      confirmPassword,
      tacChecked,
      connectionRequestId,
      connectionRequestTacChecked
    } = this.state;

    // field validation
    let errors = {};

    if (email === '' || email.trim() === '') {
      errors.email = intl.formatMessage({ id: 'Email is required' });
    } else if (!isEmailValid(email)) {
      errors.email = intl.formatMessage({ id: 'Invalid email' });
    }

    if (password === '') {
      errors.password = intl.formatMessage({ id: 'password-required' });
    }
    if (confirmPassword === '') {
      errors.confirmPassword = intl.formatMessage({ id: 'Confirm Password is required' });
    }

    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult) {
      errors.password = intl.formatMessage({ id: passwordValidationResult });
    }

    const passwordConfirmValidationResult = validatePasswordConfirm(confirmPassword, password);
    if (passwordConfirmValidationResult) {
      errors.confirmPassword = intl.formatMessage({ id: passwordConfirmValidationResult });
    }

    if (!tacChecked) {
      errors.tacChecked = intl.formatMessage({ id: 'Please read and accept beforehand' });
    }

    if (connectionRequestId) {
      if (!connectionRequestTacChecked) {
        errors.connectionRequestTacChecked = intl.formatMessage({ id: 'Please read and accept beforehand' });
        const checkboxElem = document.querySelector('.connection-request-tac-checkbox');
        if (checkboxElem) {
          checkboxElem.scrollIntoView();
        }
      }
    }

    this.setState({ errors: errors });

    // if there is any error, do not send request
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  // creating user
  handleCreate = () => {
    const { history, createUser } = this.props;
    const { email, password, language, dmChecked, connectionRequestId } = this.state;

    let createDetails = {};
    let timezone = new Date().getTimezoneOffset();
    createDetails.password = password;
    createDetails.email = formatEmail(email);
    createDetails.userType = 'User';
    createDetails.language = language;
    createDetails.timeZone = -timezone;
    createDetails.optOutOfEmail = !dmChecked;
    if (connectionRequestId) {
      createDetails.connectionRequestId = connectionRequestId;
    }

    // append referrer email address & name
    const searchObject = getSearchObject(history);
    if (searchObject['Aemail']) {
      createDetails.referrerEmail = searchObject['Aemail'];
      createDetails.referralType = 'medical-card';
      if (searchObject['Aname']) {
        createDetails.referrerName = searchObject['Aname'];
      }
    }

    if (searchObject['campaignCode']) {
      createDetails.campaignCodes = searchObject['campaignCode']
        ? [{ text: (searchObject['campaignCode'] || '').trim().toUpperCase() }]
        : null;
    }

    createUser(createDetails);
  };

  onLogin = () => {
    this.setState({ disableBackConfirm: true }, () =>
      this.props.history.push({
        pathname: '/login',
        search: '?utm_source=In-app&utm_medium=signup-page'
      })
    );
  };

  closeRegisteredDialog = () => this.setState({ registeredDialog: false });

  redirectToLoginWithEmail = () => {
    const { history } = this.props;
    const { email } = this.state;
    this.setState(
      {
        disableBackConfirm: true,
        registeredDialog: false
      },
      () => history.push({ pathname: '/login', search: `?email=${email}` })
    );
  };

  render() {
    const { intl, loading, classes } = this.props;
    const {
      language,
      email,
      password,
      confirmPassword,
      tacChecked,
      dmChecked,
      errors,
      disableBackConfirm,
      showConfirmDialog,
      registeredDialog,
      fixedEmail,
      connectionRequestId,
      connectionRequestTacChecked
    } = this.state;

    const contents = (
      <div className="section register-details max-width-wrapper">
        <Grid container justify="center" alignItems="center" spacing={1}>
          <Grid item>
            <div className="logo-wrapper">
              <img src="img/new_user.png" alt="user" />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h4" component="h4" align="center">
              {intl.formatMessage({ id: 'client-sign-up' })}
            </Typography>
          </Grid>
        </Grid>
        {!disableBackConfirm && <BackConfirmDialog content={intl.formatMessage({ id: 'exit-reg' })} />}
        {showConfirmDialog && (
          <ConfirmEmailDialog
            checked={this.state.checkedConfirm}
            confirmEmail={() => this.setState({ checkedConfirm: !this.state.checkedConfirm })}
            handleCreate={this.handleCreate}
            content={intl.formatMessage({ id: 'user-sign-up-email-confirm' }, { email })}
            closeModal={() => this.setState({ showConfirmDialog: false })}
            confirmButtonId="client-sign-up-confirm-email"
          />
        )}

        <div style={{ padding: '24px 0' }}>
          <Grid className="language button-options" container justify="flex-start">
            {[
              { code: 'en', text: 'ENG' },
              { code: 'zh-Hant-HK', text: '繁中' },
              { code: 'zh', text: '简中' }
            ].map(option => (
              <Button
                key={option.code}
                variant="contained"
                color="primary"
                className={`${language === option.code ? 'selected' : ''}`}
                onClick={() => this.handleChange({ target: { name: 'language', value: option.code } })}
              >
                {option.text}
              </Button>
            ))}
          </Grid>

          <YellowHint>
            <Typography color="textSecondary">{intl.formatMessage({ id: 'user-signup-msg' })}</Typography>
          </YellowHint>

          <form>
            <TextField
              className="input"
              name="email"
              variant="outlined"
              autoComplete="email"
              label={intl.formatMessage({ id: 'Login Email(Unchangeable)' })}
              error={!!errors.email}
              helperText={errors.email ? errors.email : undefined}
              value={email}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  root: classes.inputBase,
                  input: classes.input
                }
              }}
              disabled={fixedEmail}
            />
            <PasswordRulesInfoTooltip password={password}>
              <TextField
                className="input"
                name="password"
                type="password"
                variant="outlined"
                autoComplete="new-password"
                label={intl.formatMessage({ id: 'Password' })}
                error={!!errors.password}
                helperText={errors.password ? errors.password : undefined}
                value={password}
                onChange={this.handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
              />
            </PasswordRulesInfoTooltip>

            <PasswordRulesInfoTooltip password={confirmPassword}>
              <TextField
                className="input"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                variant="outlined"
                label={intl.formatMessage({ id: 'Confirm Password' })}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword ? errors.confirmPassword : undefined}
                value={confirmPassword}
                onChange={this.handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.input
                  }
                }}
              />
            </PasswordRulesInfoTooltip>
          </form>

          <Button
            id="client-sign-up"
            className="action"
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={loading}
            onClick={() => {
              if (this.checkErrors()) {
                this.setState({ showConfirmDialog: true });
              }
            }}
          >
            {intl.formatMessage({ id: 'create-ac' })}
          </Button>

          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                color="secondary"
                name="tacChecked"
                value={tacChecked}
                checked={tacChecked}
                onChange={this.handleCheck}
              />
            }
            label={
              <Fragment>
                <Typography variant="overline" color="textSecondary">
                  {`${intl.formatMessage({ id: 'agree-with-pfp' })} `}
                  <a href="https://www.portfoplus.com/terms.html" target="_blank" rel="noopener noreferrer">
                    <u>{intl.formatMessage({ id: 'Terms of Service' })}</u>
                  </a>
                  {` & `}
                  <a href="https://www.portfoplus.com/privacy.html" target="_blank" rel="noopener noreferrer">
                    <u>{intl.formatMessage({ id: 'Privacy Policy' })}</u>
                  </a>
                </Typography>
                {errors.tacChecked ? <Typography color="error">{errors.tacChecked}</Typography> : null}
              </Fragment>
            }
          />

          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                color="secondary"
                name="dmChecked"
                checked={dmChecked}
                value={dmChecked}
                onChange={this.handleCheck}
              />
            }
            label={
              <Fragment>
                <Typography variant="overline" color="textSecondary">
                  {intl.formatMessage({ id: 'marketing-terms-client' })}{' '}
                </Typography>
                {errors.dmChecked ? <Typography color="error">{errors.dmChecked}</Typography> : null}
              </Fragment>
            }
          />

          {connectionRequestId && (
            <FormControlLabel
              className="checkbox connection-request-tac-checkbox"
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  color="secondary"
                  name="connectionRequestTacChecked"
                  value={connectionRequestTacChecked}
                  checked={connectionRequestTacChecked}
                  onChange={this.handleCheck}
                />
              }
              label={
                <Fragment>
                  <Typography variant="overline" color="textSecondary">
                    {intl.formatMessage({ id: 'understand_message_for_user' })}
                  </Typography>
                  {errors.connectionRequestTacChecked ? (
                    <Typography color="error">{errors.connectionRequestTacChecked}</Typography>
                  ) : null}
                </Fragment>
              }
            />
          )}
        </div>

        <SignInHomePageGrid intl={intl} onLogin={this.onLogin} />
      </div>
    );
    return (
      <>
        <LoginBannerContainer className="new-sign-up gradient-bg nsu" loginBanner={true} ready={true}>
          <Grid item xs={12} className="client-sign-up-wrapper">
            <div>{contents}</div>
          </Grid>
        </LoginBannerContainer>
        <Dialog open={!!registeredDialog}>
          <DialogTitle>{intl.formatMessage({ id: 'alreadyregistered' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'login-wif-this-email' })}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.closeRegisteredDialog}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button color="primary" onClick={this.redirectToLoginWithEmail}>
              {intl.formatMessage({ id: 'confirm-proceed' })}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    loading: state.user.createPhase === LOADING,
    createPhase: state.user.createPhase,
    createMessage: state.user.createMessage,
    createMessageExtras: state.user.createMessageExtras,
    updatePhase: state.user.updatePhase,
    userSubscription: state.userSubscription.userSubscription
  }),
  // Map actions to props
  {
    createUser,
    selectedLocale
  },
  // mergeProps
  null,
  // options
  {}
)(ClientSignUp);

export default injectIntl(withStyles(LoginStyles)(container));
