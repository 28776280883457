import { useMediaQuery } from '@material-ui/core';
import { SHOW_SIDE_BAR_WIDTH } from '../../views/App/component';
import { useEffect, useRef, useCallback } from 'react';
import { shouldHideNavigation } from '../../utils/url';
import _ from 'lodash';
import { connect } from 'react-redux';
import { updateShowPadding } from '../../store/app';
import { withRouter } from 'react-router';

const AppLayoutUpdater = props => {
  const { history, userDetails, updateShowPadding } = props;

  const historyListenerRef = useRef(null);

  const shouldShownSideBar = useMediaQuery(`(min-width:${SHOW_SIDE_BAR_WIDTH}px)`);

  const update = useCallback(
    pathname => {
      updateShowPadding(!shouldHideNavigation(pathname) && shouldShownSideBar && !_.isEmpty(userDetails));
    },
    [shouldShownSideBar, updateShowPadding, userDetails]
  );

  useEffect(() => {
    historyListenerRef.current = history.listen((location, action) => {
      update(location.pathname);
    });

    return function cleanup() {
      if (historyListenerRef && historyListenerRef.current) {
        historyListenerRef.current();
      }
    };
  }, [history, update]);

  useEffect(() => {
    const pathname = _.get(history, 'location.pathname');
    update(pathname);
  }, [history, update]);

  return null;
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    showPadding: state.app.showPadding
  }),
  {
    updateShowPadding
  }
)(AppLayoutUpdater);

export default withRouter(container);
