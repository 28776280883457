import React from 'react';
import { injectIntl } from 'react-intl';
import ReactEcharts from 'echarts-for-react';
import { PYRAMID_COLORS } from '../../constants';
import { getWebImageUrl } from '../../../../../utils/image';

const Pyramid = props => {
  const { intl } = props;

  return (
    <ReactEcharts
      option={{
        animationDuration: 2000,
        animationDurationUpdate: 2000,
        graphic: {
          elements: [
            {
              type: 'image',
              left: '112.5px',
              top: '15px',
              z: 10,
              style: {
                image: getWebImageUrl('img/financial-pyramid/dice_w.png'),
                width: 25,
                height: 25
              }
            },
            {
              type: 'image',
              left: '112.5px',
              top: '85px',
              z: 10,
              style: {
                image: getWebImageUrl('img/financial-pyramid/investment_w.png'),
                width: 25,
                height: 25
              }
            },
            {
              type: 'image',
              left: '112.5px',
              top: '155px',
              z: 10,
              style: {
                image: getWebImageUrl('img/financial-pyramid/saving_w.png'),
                width: 25,
                height: 25
              }
            },
            {
              type: 'image',
              left: '112.5px',
              top: '245px',
              z: 10,
              style: {
                image: getWebImageUrl('img/financial-pyramid/protection_w.png'),
                width: 25,
                height: 25
              }
            }
          ]
        },
        series: [
          {
            type: 'funnel',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            sort: 'ascending',
            gap: 4,
            label: {
              show: true,
              position: 'inside',
              fontSize: 20,
              fontFamily: "'Roboto', 'Noto Sans TC', sans-serif"
            },
            data: [
              {
                value: 20,
                name: intl.formatMessage({ id: 'Risk' }),
                itemStyle: {
                  color: PYRAMID_COLORS[0],
                  height: '23%'
                },
                label: {
                  padding: [40, 0, 0, 0]
                }
              },
              {
                value: 40,
                name: intl.formatMessage({ id: 'Investment' }),
                itemStyle: {
                  color: PYRAMID_COLORS[1],
                  height: '23%'
                },
                label: {
                  padding: [35, 0, 0, 0]
                }
              },
              {
                value: 60,
                name: intl.formatMessage({ id: 'Saving' }),
                itemStyle: {
                  color: PYRAMID_COLORS[2],
                  height: '23%'
                },
                label: {
                  padding: [35, 0, 0, 0]
                }
              },
              {
                value: 60,
                itemStyle: {
                  height: '4%',
                  color: '#f0f9f9'
                }
              },
              {
                value: 60,
                name: intl.formatMessage({ id: 'Protection' }),
                itemStyle: {
                  color: PYRAMID_COLORS[3],
                  height: '22%'
                },
                label: {
                  padding: [35, 0, 0, 0]
                }
              }
            ]
          }
        ]
      }}
    />
  );
};

export default injectIntl(Pyramid);
