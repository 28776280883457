import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './WelcomeDialog.scss';
import withWidth from '@material-ui/core/withWidth';

class WelcomeDialog extends React.PureComponent {
  render() {
    const { intl, open, onGo, width, type } = this.props;
    const fullScreen = !!['xs'].find(size => size === width);

    return (
      <Dialog
        open={!!open}
        className="welcome-dialog"
        TransitionComponent={SlideTransition}
        fullWidth={true}
        fullScreen={fullScreen}
      >
        <DialogContent style={{ padding: '0 0 8px 0' }}>
          <div className="wrapper">
            <div className="welcome-dialog-header">
              <img
                src={`img/welcome/${type === 'adviser' ? 'welcome-adviser.jpg' : 'welcome-client.jpg'}`}
                alt=""
                style={!fullScreen ? { maxHeight: '51vh' } : undefined}
              />
            </div>

            <div className="content">
              <Typography align="center" variant="h3">
                {intl.formatMessage({ id: `welcome-${type}-heading` })}
              </Typography>
              <Typography align="center">{intl.formatMessage({ id: `welcome-${type}-content` })}</Typography>
              <Typography align="center" color="primary">
                {intl.formatMessage({ id: `welcome-${type}-highlight` })}
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="button-wrapper">
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox checked={gotIt} onChange={onGotIt} value="checked" color="primary" />}*/}
          {/*  label={intl.formatMessage({ id: 'dun-show-again' })}*/}
          {/*/>*/}
          <Button onClick={onGo} color="primary" variant="contained" fullWidth>
            {intl.formatMessage({ id: 'here-we-go' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(withWidth()(WelcomeDialog));

export default injectIntl(container);
