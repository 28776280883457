import React from 'react';
import { Button } from '@material-ui/core';

const Style = {
  height: 23,
  borderWidth: 1,
  borderRadius: 12,
  borderColor: '#ccc',
  fontSize: '1.35rem',
  fontWeight: 400,
  padding: '0 6px'
};

const SmallRoundedButton = props => {
  const { selected, style, children, ...rest } = props;

  return (
    <Button
      color="primary"
      variant={selected ? 'contained' : 'outlined'}
      style={{
        ...Style,
        color: selected ? '#fff' : '#ccc',
        ...style
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SmallRoundedButton;
