import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { chromeIOS, iOS, safari } from '../../utils/device';
import { validatePdfPassword, validatePasswordConfirm } from '../../utils/validators';
import StyledTextField from '../StyledTextField/StyledTextField';

const iOSButNotSafari = chromeIOS() || (iOS() && !safari());

const ExportHintDialog = props => {
  const { intl, extraContent, shouldUsePassword, onClose, onConfirm, ...rest } = props;

  const [isPrivateMode, setIsPrivateMode] = useState(false);
  const [isPasswordEnabled, setIsPasswordEnabled] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState({});

  const onChangeIsPrivateMode = event => {
    setIsPrivateMode(event.target.checked);
  };
  const onChangeIsPasswordEnabled = event => {
    setIsPasswordEnabled(event.target.checked);
    if (!event.target.checked) {
      setPassword('');
      setPasswordConfirm('');
      setErrors({});
    }
  };
  const onChangePassword = event => {
    setPassword(event.target.value);
    setErrors({});
  };
  const onChangePasswordConfirm = event => {
    setPasswordConfirm(event.target.value);
    setErrors({});
  };
  const onExited = () => {
    setIsPasswordEnabled(false);
    setPassword('');
    setPasswordConfirm('');
    setErrors({});
  };
  const onGenerate = () => {
    const errs = {};

    if (isPasswordEnabled) {
      const passwordValidationResult = validatePdfPassword(password);
      if (passwordValidationResult) {
        errs.password = intl.formatMessage({ id: passwordValidationResult });
      }

      const passwordConfirmValidationResult = validatePasswordConfirm(passwordConfirm, password);
      if (passwordConfirmValidationResult) {
        errs.confirmPassword = intl.formatMessage({ id: passwordConfirmValidationResult });
      }
    }

    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      onConfirm({ password, isPrivateMode });
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} onExited={onExited} TransitionComponent={SlideTransition} fullWidth={true} {...rest}>
      <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Export Portfolio' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'generate-image' })}</Typography>
            {extraContent && extraContent}
            <Typography>{intl.formatMessage({ id: 'Handle-with-care' })}</Typography>
            {!!iOSButNotSafari && (
              <Fragment>
                <br />
                <br />
                <Typography>{intl.formatMessage({ id: 'export-ios-not-safari-hint' })}</Typography>
              </Fragment>
            )}
          </Grid>
          {shouldUsePassword && (
            <Grid item>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPrivateMode}
                        onChange={onChangeIsPrivateMode}
                        color="primary"
                        style={{ padding: 4 }}
                      />
                    }
                    label={intl.formatMessage({ id: 'private-mode' })}
                    style={{ margin: 0 }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPasswordEnabled}
                        onChange={onChangeIsPasswordEnabled}
                        color="primary"
                        style={{ padding: 4 }}
                      />
                    }
                    label={intl.formatMessage({ id: 'password-protection' })}
                    style={{ margin: 0 }}
                  />
                </Grid>
                {isPasswordEnabled && (
                  <Fragment>
                    <Grid item>
                      <StyledTextField
                        label={intl.formatMessage({ id: 'Password' })}
                        type="password"
                        autoComplete="new-password"
                        disabled={!isPasswordEnabled}
                        value={password}
                        onChange={onChangePassword}
                        error={!!errors.password}
                        helperText={errors.password ? errors.password : undefined}
                      />
                    </Grid>
                    <Grid item>
                      <StyledTextField
                        label={intl.formatMessage({ id: 'Confirm Password' })}
                        type="password"
                        autoComplete="new-password"
                        disabled={!isPasswordEnabled}
                        value={passwordConfirm}
                        onChange={onChangePasswordConfirm}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword ? errors.confirmPassword : undefined}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={onGenerate} color="primary" autoFocus>
          {intl.formatMessage({ id: 'Generate' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ExportHintDialog);
