import React from 'react';

// import { EditorState } from 'draft-js';
// import { BlockPicker } from 'react-color';
// import { Popover, Zoom } from '@material-ui/core';
// import { SELECTABLE_COLORS } from '../../constants';
import './index.scss';

export default (props) => {
    const { i, theme, iconStyle, onClick } = props;

    const icon = typeof i === 'string' ?
        <img src={i} style={{ width: '20px', height: '20px', ...iconStyle }} /> : i;
    return (
        <div
            className={theme.buttonWrapper}
        >
            <button
                type="button"
                className={theme.button}
                onClick={onClick}>
                {icon}
            </button>
        </div>
    );
}
