import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import './MoreInfoDialog.scss';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../store/control/duck';
import { getContentLink, getLocalContent } from '../StandardContentComponents/utils';
import StandardContentTrigger from '../StandardContentComponents/StandardContentDialog/StandardContentTrigger';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

class MoreInfoDialog extends React.Component {
  close = () => this.props.mergeDialog('moreInfo', { closed: true });
  closed = () => this.props.toggleDialog('moreInfo', false);

  render() {
    const { intl, moreInfoDialog } = this.props;

    if (!moreInfoDialog) {
      return null;
    }

    const { titleIntlId, contentIntlId, tutorial, closed } = moreInfoDialog;

    const tutorialContentId = _.get(tutorial, 'contentId'),
      tutorialItemId = _.get(tutorial, 'itemId');

    return (
      <Dialog
        className="more-info-dialog"
        open={!!moreInfoDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        {titleIntlId && (
          <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: titleIntlId })}</DialogTitle>
        )}
        {contentIntlId && (
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: contentIntlId }) }} />
          </DialogContent>
        )}
        <DialogActions>
          {tutorialContentId && tutorialItemId && (
            <StandardContentTrigger
              contentLink={getContentLink(tutorialContentId)}
              localContent={process.env.NODE_ENV === 'development' && getLocalContent(tutorialContentId)}
              itemId={tutorialItemId}
              buttonIntlId="tutorial"
            />
          )}
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    moreInfoDialog: state.control.moreInfoDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog
  },
  // mergeProps
  null,
  // options
  {}
)(MoreInfoDialog);

export default injectIntl(container);
