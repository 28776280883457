const backendUrl = process.env.PFPLUS_BACKEND_URL;
const backendBaseUrl = process.env.PFPLUS_BACKEND_BASE_URL;
const pdfUrl = process.env.PFPLUS_PDF_SERVICE_URL;

const Backend = {
  baseUrl: backendBaseUrl,
  methodUrl: method => {
    return backendUrl + (method.charAt(0) !== '/' ? '/' : '') + method;
  },
  api: method => {
    return backendBaseUrl + '/api/v1/' + method;
  },
  pdfApi: method => {
    return pdfUrl + method;
  }
};

export default Backend;
