import React from 'react';
import { Button, Card, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import NumberFormatTextField from '../../../../../components/NumberFormatTextField/NumberFormatTextField';
import { addThousandSeparators, countDecimals, roundNumber } from '../../../../../utils/formatNumber';
import EditIcon from '@material-ui/icons/Edit';
import './InputPanel.scss';

const LoanEditButtonStyle = {
  padding: '0px 5px',
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1
};

const LoadToggleButtonStyle = {
  minHeight: 'unset',
  minWidth: 'unset',
  padding: 2,
  lineHeight: 1
};

const LoanAmountTypoStyle = {
  whiteSpace: 'pre'
};

const InputPanel = ({
  intl,
  principal,
  base,
  handleChange,
  loanPercentage,
  firstYearBonus,
  openLoanCalculatorDialog,
  openBonusCalculatorDialog,
  template,
  disableLoan,
  onToggleDisableLoan,
  isCurrencyEnabled,
  currency
}) => {
  const _handleChange = isCurrencyEnabled ? undefined : handleChange;
  const formattedLoanPercentage = roundNumber(loanPercentage, 1);
  const loan = (principal ?? 0) - (base ?? 0) - (firstYearBonus ?? 0);
  const onClickToggleDisableLoanButton = () => onToggleDisableLoan();

  return (
    <Card className="pfa-input-panel pfa-card pf-card-container">
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6} md={3} className="input-control">
            <NumberFormatTextField
              disabled={isCurrencyEnabled}
              label={intl.formatMessage({ id: 'pfa-principal' })}
              name="principal"
              value={principal}
              onChange={_handleChange}
              inputProps={{
                allowNegative: false,
                prefix: '$',
                decimalScale: 0
              }}
            />
          </Grid>
          <Grid item xs={6} md={3} className="input-control">
            {!template && (
              <IconButton disabled={isCurrencyEnabled} onClick={openLoanCalculatorDialog} style={LoanEditButtonStyle}>
                <EditIcon style={{ fontSize: 16 }} />
              </IconButton>
            )}
            <NumberFormatTextField
              disabled={isCurrencyEnabled}
              label={
                <Grid container alignItems="center" style={{ marginTop: -2 }}>
                  <Grid item>
                    <Button
                      disabled={isCurrencyEnabled}
                      variant="outlined"
                      color={disableLoan ? 'inherit' : 'primary'}
                      style={LoadToggleButtonStyle}
                      onClick={onClickToggleDisableLoanButton}
                    >
                      {intl.formatMessage({ id: 'pfa-loan-percentage' })}
                    </Button>
                  </Grid>
                  <Grid item style={LoanAmountTypoStyle}>
                    <Typography>{` ${intl.formatMessage({ id: 'pfa-loan-percentage-amount' })}`}</Typography>
                  </Grid>
                </Grid>
              }
              name="loanPercentage"
              value={formattedLoanPercentage}
              inputProps={{
                allowNegative: false,
                suffix: `%`,
                decimalScale: 1,
                isAllowed: values => {
                  const { formattedValue, value } = values;
                  return principal && (formattedValue === '' || (value >= 0 && value <= 100));
                },
                style: disableLoan
                  ? {
                      visibility: 'hidden'
                    }
                  : undefined
              }}
              onChange={_handleChange}
              endAdornment={
                !disableLoan ? <div>{loan ? `($${addThousandSeparators(loan.toFixed(0))})` : ''}</div> : undefined
              }
            />
          </Grid>
          <Grid item xs={6} md={3} className="input-control">
            {!template && (
              <IconButton disabled={isCurrencyEnabled} onClick={openBonusCalculatorDialog} style={LoanEditButtonStyle}>
                <EditIcon style={{ fontSize: 16, opacity: 0.1 }} />
              </IconButton>
            )}
            <NumberFormatTextField
              disabled={isCurrencyEnabled}
              label={intl.formatMessage({ id: 'pfa-bonus' })}
              name="firstYearBonus"
              value={firstYearBonus}
              onChange={_handleChange}
              inputProps={{
                allowNegative: false,
                prefix: '$',
                decimalScale: 0,
                isAllowed: values => {
                  const { formattedValue, value } = values;
                  return (
                    principal &&
                    (formattedValue === '' ||
                      (value >= 0 && value <= principal * (1 - (disableLoan ? 0 : loanPercentage ?? 0) / 100)))
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3} className="input-control">
            <NumberFormatTextField
              disabled={isCurrencyEnabled}
              label={intl.formatMessage({ id: 'pfa-base' })}
              name="base"
              value={base}
              onChange={_handleChange}
              inputProps={{
                allowNegative: false,
                prefix: '$',
                decimalScale: 0,
                isAllowed: values => {
                  const { formattedValue, value } = values;
                  return (
                    principal && (formattedValue === '' || (value >= 0 && value <= principal - (firstYearBonus ?? 0)))
                  );
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InputPanel;
