import React from 'react';
import { injectIntl } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './PasswordRulesInfo.scss';

const PasswordRulesInfo = props => {
  const { intl, validationResults = [] } = props;

  return (
    <div className="PasswordRulesInfoContainer">
      {validationResults.map(({ isValid, key }) => {
        return (
          <div key={key} className="PasswordRule">
            <CheckCircleIcon color={isValid ? 'primary' : 'inherit'} />
            <span className="PasswordRuleName">{intl.formatMessage({ id: key })}</span>
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(PasswordRulesInfo);
