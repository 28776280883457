import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dialog, Grid, Typography } from "@material-ui/core";
import { SlideTransition } from "../Transition/Transition";
import { getVCardString } from "../../utils/vcard";
import QRCodeComponent from "../QRCodeComponent";
import './ECardDialog.scss';
import { useTheme } from "@material-ui/core/styles";
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import { formatPhoneNumber } from "../../utils/user";

const FullWidthStyle = {
	width: '100%'
};

const FirstGridItemStyle = {
	...FullWidthStyle,
	padding: 24
}

const SecondGridItemStyle = {
	...FullWidthStyle,
	padding: '12px 16px'
};

const ThirdGridItemStyle = {
	...FullWidthStyle,
	padding: '0 24px 16px',
};

const NameTypoStyle = {
	fontSize: '2rem',
	lineHeight: 1.5
};

const IconStyle = {
	verticalAlign: 'middle'
};

const HintTypoStyle = {
	padding: 8,
	borderRadius: 8,
	fontSize: '1.5rem'
};

const ECardDialog = props => {
	const { intl, userDetails, className, ...rest } = props;

	const theme = useTheme();

	const { name, phoneNumber, workEmail } = userDetails || {};

	const vCardString = getVCardString(userDetails);

	return (
		<Fragment>
			<Dialog
				className={`e-card-dialog${className ? ` ${className}` : ''}`}
				TransitionComponent={SlideTransition}
				fullWidth={true}
				maxWidth="xs"
				{...rest}
			>
				<Grid container direction="column" alignItems="center">
					<Grid item style={{ ...FirstGridItemStyle, background: theme.palette.primary.main }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography color="textPrimary" style={NameTypoStyle}>
									{name}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography color="textPrimary" component="div">
									<Grid container alignItems="center" spacing={1}>
										<Grid item>
											<PhoneRoundedIcon style={IconStyle}/>
										</Grid>
										<Grid item>
											{formatPhoneNumber(phoneNumber)}
										</Grid>
									</Grid>
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography color="textPrimary" component="div">
									<Grid container alignItems="center" spacing={1}>
										<Grid item>
											<MailRoundedIcon style={IconStyle}/>
										</Grid>
										<Grid item>
											{workEmail}
										</Grid>
									</Grid>
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={SecondGridItemStyle}>
						{vCardString && <QRCodeComponent content={getVCardString(userDetails)} logo={true}/>}
					</Grid>
					<Grid item style={ThirdGridItemStyle}>
						<Typography color="textPrimary" align="center" style={{ ...HintTypoStyle, background: theme.palette.primary.main }}>
							{intl.formatMessage({ id: 'save-my-contact' })}
						</Typography>
					</Grid>
				</Grid>
			</Dialog>
		</Fragment>

	)
};

const container = connect(
	state => ({
		userDetails: state.user.userDetails
	}),
	{}
)(ECardDialog)

export default injectIntl(container);