import { fetch } from '../../utils';
import Backend from '../../backend';
import { tracking } from '../../utils/tracking';

export const getAssistant = () => {
  return fetch(Backend.methodUrl('assistant'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateAssistant = assistant => {
  return fetch(Backend.methodUrl('assistant'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(assistant)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      // Tracking
      tracking('Advisor Assistant: update assistant data');
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
