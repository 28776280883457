import React, { useEffect, useMemo, useState } from 'react';
import HotTopicItem from './HotTopicItem';
import { injectIntl } from 'react-intl';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer.js';
import { usePrevious } from '../../../../utils/hooks';
import { useSelector } from 'react-redux';
import TagsRegion from './TagsRegion/TagsRegion';
import { LANGUAGE } from '../../../../constants/language';

const RowStyle = { flex: 1, display: 'flex', alignItems: 'flex-start' };
const TitleStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 15px'
};
const WrapperStyle = { paddingTop: 8 };
const ItemStyle = (isLargeScreen, rowItemLength) => ({
  width: isLargeScreen ? '20%' : rowItemLength ? `${100 / rowItemLength}%` : '33%',
  display: 'flex',
  justifyContent: 'center'
});

const HotTopicSeeMoreCategory = ({ location, history, intl }) => {
  const [contents, setContents] = useState(null);
  const [className, setClassName] = useState(undefined);
  const [rowItemLength, setRowItemLength] = useState(undefined);
  const [statistics, setStatistics] = useState(undefined);
  const [tag, setTag] = useState(undefined);
  const [taggedContents, setTaggedContents] = useState(undefined);

  const userDetails = useSelector(state => state.user.userDetails);
  const subscriptions = useSelector(state => state.userSubscription.subscriptions);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 850);
  const [isIpadWidth, setIsIpadWidth] = useState(window.innerWidth > 767);
  const previousIsLargeScreen = usePrevious(isLargeScreen);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 850);
      setIsIpadWidth(window.innerWidth > 767);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (location.state) {
      if (location.state.contents) {
        setContents(location.state.contents);
      }
      if (location.state.className) {
        setClassName(location.state.className);
      }
      if (location.state.rowItemLength) {
        setRowItemLength(location.state.rowItemLength);
      }
      if (location.state.statistics) {
        setStatistics(location.state.statistics);
      }
      if (location.state.tag) {
        setTag(location.state.tag);
      }
      if (location.state.taggedContents) {
        setTaggedContents(location.state.taggedContents);
      }
    }
  }, [location]);

  const contentArray = useMemo(() => {
    if (!contents) {
      return [];
    }
    const result = [];
    const itemsPerRow = isLargeScreen ? 5 : rowItemLength || 3;
    for (let i = 0; i < contents.items.length; i += itemsPerRow) {
      result.push(contents.items.slice(i, i + itemsPerRow));
    }
    return result;
  }, [contents, isLargeScreen, rowItemLength]);

  const onClickTag = ({ tag, items }) => {
    history.push({
      pathname: location.state.tagViewPath,
      state: {
        taggedContents: taggedContents,
        tag: tag,
        contents: {
          categoryName: tag,
          items
        },
        statistics: statistics,
        HotTopicItemProps: location.state.HotTopicItemProps
      }
    });
  };

  return (
    <AppContentContainer className={className}>
      {taggedContents && (
        <TagsRegion
          taggedContents={taggedContents.map(content => {
            return {
              ...content,
              hidden: content.tag === tag
            };
          })}
          onClickTag={onClickTag}
        />
      )}
      <div className="hot-topic">
        {contents && (
          <div className="row">
            <div className="col-lg-12" style={WrapperStyle}>
              <div style={TitleStyle}>
                <div className="hot-topic-category-title">{contents.categoryName[intl.locale]}</div>
              </div>
              <div>
                {contentArray.map((e, index) => (
                  <div key={`hot-topic-row-${index}`} style={RowStyle}>
                    {e.map(item => (
                      <div key={`hot-topic-item-dialog-${item.id}`} style={ItemStyle(isLargeScreen, rowItemLength)}>
                        <HotTopicItem
                          intl={intl}
                          itemData={item}
                          index={0}
                          history={history}
                          isLargeScreen={isLargeScreen}
                          previousIsLargeScreen={previousIsLargeScreen}
                          userDetails={userDetails}
                          subscriptions={subscriptions}
                          isIpadWidth={isIpadWidth}
                          shouldShowStatistic={!!statistics}
                          statistic={statistics ? statistics[item.id] : undefined}
                          {...location.state.HotTopicItemProps}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </AppContentContainer>
  );
};

export default injectIntl(HotTopicSeeMoreCategory);
