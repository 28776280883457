import React, { Fragment, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
// import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
// import moment from 'moment-timezone';
// import NativeOrWeb from '../../../utils/native';
import { UserSubscription } from '../../../utils/user-subscription';

// const CloseButtonStyle = {
//   position: 'absolute',
//   top: 8,
//   right: 16,
//   color: '#fff',
//   opacity: '50%'
// };
//
// const IFrameDialogContentStyle = {
//   padding: 0
// };
//
// const IFrameStyle = {
//   width: '100%',
//   height: '100%',
//   border: 'none'
// };

const CancelCurrentSubscriptionDialog = props => {
  const { intl, subscription, open, onClose, onConfirm, ...rest } = props;
  const [state, setState] = useState(0);
  // const [surveyDialog, setSurveyDialog] = useState(false);
  // const [thankYouDialog, setThankYouDialog] = useState(false);

  const isTrailing = UserSubscription.isSubscriptionTrialing(subscription);

  // const closeSurveyDialog = () => {
  //   setSurveyDialog(false);
  //   setThankYouDialog(true);
  // };
  //
  // const closeThankYouDialog = () => {
  //   setThankYouDialog(false);
  // };

  const confirm = () => {
    setState(1);
  };

  const thinkAgain = () => {
    onClose();
  };

  const cancel = () => {
    onConfirm();
    setState(2);
  };

  const whatsapp = () => {
    window.open(
      'https://api.whatsapp.com/send?phone=85266370320&text=(APP)%20%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%88%91%E6%83%B3%E6%9F%A5%E8%A9%A2%E7%BA%8C%E8%A8%82%E5%84%AA%E6%83%A0',
      '_blank'
    );
  };

  // const survey = () => {
  //   onClose();
  //   onConfirm();
  //
  //   // http/untrusted certificate iframe would show blank page
  //   if (NativeOrWeb.isNativePlatform()) {
  //     window.open('https://tb6k5rfd4zd.typeform.com/to/dfIjp10k', '_blank');
  //     setThankYouDialog(true);
  //   } else {
  //     setSurveyDialog(true);
  //   }
  // };

  const done = () => {
    onClose();
  };

  useEffect(() => {
    if (open === false) {
      setTimeout(() => {
        setState(0);
      }, 350);
    }
  }, [open]);

  return (
    <Fragment>
      {/*<Dialog open={surveyDialog} fullScreen={true} TransitionComponent={SlideTransition}>*/}
      {/*  <IconButton style={CloseButtonStyle} onClick={closeSurveyDialog}>*/}
      {/*    <ClearRoundedIcon />*/}
      {/*  </IconButton>*/}

      {/*  <DialogContent style={IFrameDialogContentStyle}>*/}
      {/*    <iframe title="Survey" src="https://tb6k5rfd4zd.typeform.com/to/dfIjp10k" style={IFrameStyle} />*/}
      {/*  </DialogContent>*/}
      {/*</Dialog>*/}
      {/*<Dialog open={thankYouDialog} TransitionComponent={SlideTransition}>*/}
      {/*  <DialogTitle>{intl.formatMessage({ id: 'cancel-sub-dialog-title-4' })}</DialogTitle>*/}
      {/*  <DialogContent>{intl.formatMessage({ id: 'cancel-sub-dialog-content-4' })}</DialogContent>*/}
      {/*  <DialogActions>*/}
      {/*    <Button color="primary" onClick={closeThankYouDialog}>*/}
      {/*      {intl.formatMessage({ id: 'got-it' })}*/}
      {/*    </Button>*/}
      {/*  </DialogActions>*/}
      {/*</Dialog>*/}
      <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} {...rest}>
        <DialogTitle>
          {state === 0
            ? intl.formatMessage({ id: 'cancel-sub-dialog-title-1' })
            : state === 1
            ? intl.formatMessage({ id: 'cancel-sub-dialog-title-2' })
            : intl.formatMessage({ id: 'cancel-sub-dialog-title-4' })}
        </DialogTitle>
        <DialogContent
          dangerouslySetInnerHTML={{
            __html:
              state === 0
                ? intl.formatMessage({
                    id: isTrailing ? 'cancel-sub-dialog-content-1-trail' : 'cancel-sub-dialog-content-1'
                  })
                : state === 1
                ? intl.formatMessage({ id: 'cancel-sub-dialog-content-2' })
                : intl.formatMessage({ id: 'cancel-sub-dialog-content-4' })
          }}
        />
        <DialogActions>
          {state === 0 ? (
            <Fragment>
              <Button variant="contained" color="primary" onClick={thinkAgain}>
                {intl.formatMessage({ id: 'think-again' })}
              </Button>
              <Button onClick={confirm}>{intl.formatMessage({ id: 'Confirm' })}</Button>
            </Fragment>
          ) : state === 1 ? (
            <Fragment>
              <Button color="primary" onClick={cancel}>
                {intl.formatMessage({ id: 'cancel-sub' })}
              </Button>
              <Button variant="contained" color="primary" onClick={whatsapp}>
                {intl.formatMessage({ id: 'check-offer' })}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button color="primary" onClick={done}>
                {intl.formatMessage({ id: 'got-it' })}
              </Button>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default injectIntl(CancelCurrentSubscriptionDialog);
