import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { formatPhoneNumber } from '../../../../utils/user';
import QRCodeComponent from '../../../../components/QRCodeComponent';

const FullWidthStyle = {
  width: '100%'
};

const FirstGridItemStyle = {
  ...FullWidthStyle,
  padding: 24
};

const SecondGridItemStyle = {
  ...FullWidthStyle,
  padding: '12px 16px'
};

const IconStyle = {
  verticalAlign: 'middle'
};

const WhatsappQRCode = props => {
  const { whatsapp, url, qrCodeRef, ...rest } = props;

  const theme = useTheme();

  return (
    <Grid container direction="column" alignItems="center" {...rest}>
      <Grid item style={{ ...FirstGridItemStyle, background: theme.palette.primary.main }}>
        <Typography color="textPrimary" component="div">
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <CropFreeIcon style={IconStyle} />
            </Grid>
            {/* <Grid item>{formatPhoneNumber(whatsapp)}</Grid> */}
          </Grid>
        </Typography>
      </Grid>
      {url && (
        <Grid item style={SecondGridItemStyle}>
          <QRCodeComponent
            content={url}
            logo={true}
            style={{ margin: 0 }}
            imgStyle={{ width: '100%' }}
            ref={qrCodeRef}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default WhatsappQRCode;
