import React, { useState, useEffect, useRef, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { getWalletPassLink, getWalletPassLinkDirectUrl } from '../../store/wallet-pass/api';
import LinkExpiredDialog from './LinkExpiredDialog/LinkExpiredDialog';
import { usePrevious } from '../../utils/hooks';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';
import { isIOS, isAndroid } from 'react-device-detect';
import { handleWalletPassFetch } from '../../utils/wallet-pass';
import TermsAndPolicyTypography from '../../components/TermsAndPolicyTypography/TermsAndPolicyTypography';
import { WALLET_PASS_LINK_TYPE } from '../../constants/wallet-pass-link';
import { SlideTransition } from '../../components/Transition/Transition';
import { tryTranslate } from '../../utils/locale';
import LoginTextField from '../Login/LoginTextField/LoginTextField';
import EmergencyEWalletShareAddToWalletDialog from './EmergencyEWalletShareAddToWalletDialog/EmergencyEWalletShareAddToWalletDialog';

const WalletPassLink = props => {
  const { intl, history, match } = props;

  const [walletPassLink, setWalletPassLink] = useState(undefined);
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [linkExpiredDialog, setLinkExpiredDialog] = useState({ open: false });
  const [emergencyEWalletShareDialog, setEmergencyEWalletShareDialog] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [inputError, setInputError] = useState({});
  const previousToken = usePrevious(match.params.token);

  const walletPassLinkType = walletPassLink && walletPassLink.type;

  useEffect(() => {
    const getWalletPass = async () => {
      if (match.params.token && match.params.token !== previousToken) {
        try {
          const json = await getWalletPassLink({ token: match.params.token });
          console.log(json);
          const { walletPassLink, isPasswordRequired, creator, error } = json || {};

          if (error) {
            if (error === 'Link expired') {
              setLinkExpiredDialog({ open: true, creator: creator });
            } else {
              setError(error.toString());
            }
          } else {
            if (walletPassLink) {
              setWalletPassLink(walletPassLink);
              setIsPasswordRequired(!!isPasswordRequired);
            } else {
              setLinkExpiredDialog({ open: true });
            }
          }
        } catch (error) {
          console.log(error);
          setError(error.toString());
        } finally {
          setLoading(false);
        }
      }
    };
    getWalletPass()
      .then()
      .catch(console.log);
  }, [match.params.token, previousToken]);

  const getPass = async type => {
    try {
      setLoading(true);
      const json = await getWalletPassLink({ token: match.params.token, password, type });
      const { walletPassLink, error } = json || {};

      if (error) {
        if (error === 'Wrong password') {
          setInputError({ password: intl.formatMessage({ id: 'record-not-match' }) });
        } else if (error === 'Password required') {
          setInputError({ password: intl.formatMessage({ id: 'record-not-match' }) });
        } else {
          setError(error.toString());
        }
        return;
      }

      if (walletPassLink && walletPassLink.directToken) {
        setError(undefined);

        const queryParameters = {
          type
        };

        if (walletPassLinkType === WALLET_PASS_LINK_TYPE.EMERGENCY_REFERENCE) {
          queryParameters.name = name;
        }

        const directLinkUrl = getWalletPassLinkDirectUrl(walletPassLink.directToken, queryParameters);
        await handleWalletPassFetch(directLinkUrl, type);
      } else {
        setError('No direct link found');
      }
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    if (!name) {
      setInputError({ name: intl.formatMessage({ id: 'This field is required' }) });
      return false;
    }
    if (!password) {
      setInputError({ password: intl.formatMessage({ id: 'This field is required' }) });
      return false;
    }
    return true;
  };

  const getAppleWalletPass = () => {
    if (validate()) {
      setEmergencyEWalletShareDialog(true);
    }
  };
  const getGoogleWalletPass = () => {
    if (validate()) {
      setEmergencyEWalletShareDialog(true);
    }
  };
  const onCloseEmergencyEWalletShareDialog = () => {
    if (isIOS) {
      getPass('apple');
    } else if (isAndroid) {
      getPass('google');
    }
    setEmergencyEWalletShareDialog(false);
  };
  const onChangeName = event => {
    setName(event.target.value);
    delete inputError.name;
    setInputError(inputError);
    setError(undefined);
  };
  const onChangePassword = event => {
    setPassword(event.target.value);
    delete inputError.password;
    setInputError(inputError);
    setError(undefined);
  };
  const onClickMainPage = () =>
    window.open('https://www.portfoplus.com/?utm_source=applogin&utm_medium=button', '_blank');
  const onCloseErrorDialog = () => {
    setError(undefined);
    history.replace('/');
  };

  if (!match.params.token) {
    return <Redirect to="/" />;
  }

  return (
    <Container maxWidth="sm" style={{ padding: '0 24px' }}>
      <LinkExpiredDialog
        open={linkExpiredDialog.open}
        creator={linkExpiredDialog.creator}
        walletPassLink={walletPassLink}
      />

      <EmergencyEWalletShareAddToWalletDialog
        open={emergencyEWalletShareDialog}
        onClose={onCloseEmergencyEWalletShareDialog}
      />

      <Dialog open={!!error} fullWidth={true} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'Something went wrong' })}</DialogTitle>
        <DialogContent>{tryTranslate(intl, error)}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onCloseErrorDialog}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && <LoadingBackdrop open={loading} />}

      {walletPassLink && (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
          wrap="nowrap"
          style={{ height: '100%' }}
        >
          <Grid item>
            <img src="img/wallet.svg" alt="wallet-pass" width={200} />
          </Grid>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'wallet-pass-link-description' })}</Typography>
          </Grid>
          {isIOS || isAndroid ? (
            <Fragment>
              {walletPassLinkType === WALLET_PASS_LINK_TYPE.EMERGENCY_REFERENCE && (
                <Grid item style={{ width: '100%' }}>
                  <LoginTextField
                    label={intl.formatMessage({ id: 'wallet-pass-link-emer-ref-name' })}
                    type="text"
                    value={name}
                    onChange={onChangeName}
                    error={inputError && !!inputError.name}
                    helperText={inputError && !!inputError.name ? inputError.name : undefined}
                    autoComplete="off"
                  />
                </Grid>
              )}
              {isPasswordRequired && (
                <Grid item style={{ width: '100%' }}>
                  <LoginTextField
                    label={intl.formatMessage({ id: 'wallet-pass-link-password' })}
                    type="password"
                    value={password}
                    onChange={onChangePassword}
                    error={inputError && !!inputError.password}
                    helperText={inputError && !!inputError.password ? inputError.password : undefined}
                    autoComplete="off"
                  />
                </Grid>
              )}
              {isIOS && (
                <Grid item>
                  <IconButton disableRipple={true} disableFocusRipple={true} onClick={getAppleWalletPass}>
                    <img src="img/wallet-pass/add-to-apple-wallet.svg" alt="add-to-apple-wallet" width="150" />
                  </IconButton>
                </Grid>
              )}
              {isAndroid && (
                <Grid item>
                  <IconButton disableRipple={true} disableFocusRipple={true} onClick={getGoogleWalletPass}>
                    <img src="img/wallet-pass/add-to-google-wallet.svg" alt="add-to-google-wallet" width="200" />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography align="center">{intl.formatMessage({ id: 'wallet-pass-link-tnc' })}</Typography>
                <TermsAndPolicyTypography hideLoginMessage={true} />
              </Grid>
            </Fragment>
          ) : (
            <Typography color="error">{intl.formatMessage({ id: 'wallet-pass-link-smartphone-hint' })}</Typography>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default injectIntl(WalletPassLink);
