import { connect } from 'react-redux';
import {
  shareDocuments,
  getShareDocuments,
  getShareMeDocuments,
  getShareToData,
  getShareFromData,
  updateShareToList,
  deleteShareList,
  deleteShareFromList,
  updateShareDetails,
  handleShareDashboardChartData,
  initPhase,
  noticeAdviserToRegister,
  initAdviserStatusPhase,
  handlePersonalShareDashboardChartData,
  handleGeneralShareDashboardChartData,
  handleCompanyShareDashboardChartData
} from '../../store/share/duck';
import {
  handleDashboardSubCategoryData,
  handleDashboardPolicyData,
  editPolicy,
  deletePolicy,
  policyInfo,
  insurerInfo
} from '../../store/policy/duck';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { currencyExchangeRate, handleSignOut, getUser, updateUserDetails } from '../../store/user/duck';
import { cleanQRCode, createQRCode } from '../../store/qrCode/duck';
import ShareView from './component';
import { selectedLocale } from '../../store/locale/action';
import { injectIntl } from 'react-intl';

const Share = connect(
  // Map state to props
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userDetails: state.user.userDetails,
    exchangeRate: state.user.exchangeRate,
    getDocPhase: state.share.getDocPhase,
    sharePhase: state.share.sharePhase,
    togglePhase: state.share.togglePhase,
    deletePhase: state.share.deletePhase,
    deleteFromPhase: state.share.deleteFromPhase,
    resMessage: state.share.resMessage,
    shareToData: state.share.shareToData,
    getShareToPhase: state.share.getShareToPhase,
    shareFromData: state.share.shareFromData,
    getShareFromPhase: state.share.getShareFromPhase,
    overwrite: state.share.overwrite,
    sharedUser: state.share.sharedUser,
    categoryPhase: state.policy.categoryPhase,
    dashboardCategoryData: state.policy.dashboardCategoryData,
    dashboardSubCategoryData: state.policy.dashboardSubCategoryData,
    dashboardPolicyData: state.policy.dashboardPolicyData,
    /*view shared details states*/
    deletePolicyPhase: state.policy.deletePolicyPhase,
    forexCurrency1: state.share.forexCurrency1,
    categories1: state.share.categories1,
    portfolio1: state.share.portfolio1,
    shareChartData: state.share.shareChartData,
    shareType: state.share.shareType,
    adviserInfo: state.share.adviserInfo,
    chartPhase: state.share.chartPhase,
    newReferralCount: state.referral.newReferralCount,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    registerKey: state.share.registerKey,
    adviserNoticeStatus: state.share.adviserNoticeStatus,
    personalShareChartData: state.share.personalShareChartData,
    generalShareChartData: state.share.generalShareChartData,
    companyShareChartData: state.share.companyShareChartData,

    // QR Code
    qrCodeFetchPhase: state.qrCode.fetchPhase,
    qrCodeResMessage: state.qrCode.resMessage,
    qrCodeError: state.qrCode.error,
    qrCode: state.qrCode.qrCode
  }),
  // Map actions to props
  {
    shareDocuments,
    getShareDocuments,
    getShareToData,
    getShareFromData,
    updateShareToList,
    deleteShareList,
    deleteShareFromList,
    insurerInfo,
    handleDashboardSubCategoryData,
    handleDashboardPolicyData,
    policyInfo,
    /*view shared details states*/
    editPolicy,
    deletePolicy,
    currencyExchangeRate,
    handleSignOut,
    updateShareDetails,
    getUser,
    handleShareDashboardChartData,
    initPhase,
    selectedLocale,
    getShareMeDocuments,
    noticeAdviserToRegister,
    initAdviserStatusPhase,
    createQRCode,
    cleanQRCode,
    handlePersonalShareDashboardChartData,
    handleGeneralShareDashboardChartData,
    handleCompanyShareDashboardChartData,
    renderHeaderButton,
    updateUserDetails
  }
)(ShareView);

export default injectIntl(Share);
