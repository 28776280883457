import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { SlideTransition } from '../../../../../components/Transition/Transition';

const clientBaseWordings = ['High', 'Good', 'Fair', 'No Data'];
const clientBaseColors = ['rgb(94, 224, 146)', 'rgb(255, 175, 25)', 'rgb(255, 106, 116)', '#cccccc'];
class smartIdeaModal extends React.Component {
  render() {
    const { open, onClose, intl } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          TransitionComponent={SlideTransition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'Client_Potential_Index' })}</DialogTitle>
          <DialogContent>
            <div className="client-base-modal-content">
              <p>
                {intl.formatMessage({
                  id: 'C.P.I_indicates'
                })}
              </p>
            </div>
            <div className="client-base-modal-content">
              <p>
                {intl.formatMessage({
                  id: 'The_top_indicator'
                })}{' '}
              </p>
            </div>
            <div className="client-base-modal-content">
              <p>
                {intl.formatMessage({
                  id: 'Indicator_attach'
                })}
              </p>
            </div>
            <div className="client-base-modal-colors">
              {clientBaseWordings.map(function(value, index) {
                return (
                  <div
                    key={index}
                    style={{
                      borderLeft: '20px solid ' + clientBaseColors[index]
                    }}
                  >
                    {intl.formatMessage({ id: value })}
                  </div>
                );
              })}
            </div>
            <div>
              <p>
                {intl.formatMessage({
                  id: 'At_least'
                })}
                <br />
                {intl.formatMessage({
                  id: 'C.P.I_is_for_reference'
                })}
                <br />
                {intl.formatMessage({
                  id: 'A.I._engine'
                })}
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default smartIdeaModal;
