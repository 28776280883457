import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
import {
  getCIToReachData,
  getLifeToReachData,
  getMedicalToReachData,
  getSavingToReachData
} from '../../utils/analysisCalculator';
import { GET_USER } from '../user/duck';
import _ from 'lodash';
import { getTotalSavingCoverage } from '../../utils/analysis';
import { InitialStateInterface as AnalysisInitialStateInterface } from '../analysis/duck';
import { FETCH_POLICY, RESET_CATEGORIES_PORTFOLIO } from '../policy/duck';
import { getUserCurrencyFromStore } from '../../utils/store';
import { getAgeFromDob } from '../../utils/date';

/***********************************
 * Action Types
 ***********/

export const SUBMIT_CLIENT = 'portfoplus/client/SUBMIT_CLIENT';
export const SUBMIT_CLIENT_SUCCESS = 'portfoplus/client/SUBMIT_CLIENT_SUCCESS';
export const SUBMIT_CLIENT_ERROR = 'portfoplus/client/SUBMIT_CLIENT_ERROR';

export const GET_CLIENT = 'portfoplus/client/GET_CLIENT';
export const GET_CLIENT_SUCCESS = 'portfoplus/client/GET_CLIENT_SUCCESS';
export const GET_CLIENT_ERROR = 'portfoplus/client/GET_CLIENT_ERROR';

export const GET_CLIENT_DETAILS = 'portfoplus/client/GET_CLIENT_DETAILS';
export const GET_CLIENT_DETAILS_SUCCESS = 'portfoplus/client/GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_ERROR = 'portfoplus/client/GET_CLIENT_DETAILS_ERROR';

export const UPDATE_CLIENT_LANGUAGE = 'portfoplus/adviserDashoard/UPDATE_CLIENT_LANGUAGE ';
export const UPDATE_CLIENT_LANGUAGE_SUCCESS = 'portfoplus/adviserDashoard/UPDATE_CLIENT_LANGUAGE_SUCCESS ';
export const UPDATE_CLIENT_LANGUAGE_ERROR = 'portfoplus/adviserDashoard/UPDATE_CLIENT_LANGUAGE_ERROR ';

export const CHECK_CLIENT = 'portfoplus/client/CHECK_CLIENT';
export const CHECK_CLIENT_SUCCESS = 'portfoplus/client/CHECK_CLIENT_SUCCESS';
export const CHECK_CLIENT_ERROR = 'portfoplus/client/CHECK_CLIENT_ERROR';
export const DELETE_CLIENT = 'portfoplus/client/DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'portfoplus/client/DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_ERROR = 'portfoplus/client/DELETE_CLIENT_ERROR';

export const GET_CONSENT = 'portfoplus/client/GET_CONSENT';
export const GET_CONSENT_SUCCESS = 'portfoplus/client/GET_CONSENT_SUCCESS';
export const GET_CONSENT_ERROR = 'portfoplus/client/GET_CONSENT_ERROR';

export const SUBMIT_CONSENT = 'portfoplus/client/SUBMIT_CONSENT';
export const SUBMIT_CONSENT_SUCCESS = 'portfoplus/client/SUBMIT_CONSENT_SUCCESS';
export const SUBMIT_CONSENT_ERROR = 'portfoplus/client/SUBMIT_CONSENT_ERROR';

export const UPDATE_FACTSHEET = 'portfoplus/client/UPDATE_FACTSHEET';
export const UPDATE_FACTSHEET_SUCCESS = 'portfoplus/client/UPDATE_FACTSHEET_SUCCESS';
export const UPDATE_FACTSHEET_ERROR = 'portfoplus/client/UPDATE_FACTSHEET_ERROR';

export const INIT_PHASE = 'portfoplus/client/INIT_PHASE';
export const INIT_CREATE_PHASE = 'portfoplus/client/INIT_CREATE_PHASE';
export const INIT_SMART_IDEA_DATA = 'portfoplus/client/INIT_SMART_IDEA_DATA';

export const INIT_CHANGE_EMAIL_PHASE = 'portfoplus/client/INIT_CHANGE_EMAIL_PHASE';

export const CHANGE_INACTIVE_USER_EMAIL = 'portfoplus/client/CHANGE_INACTIVE_USER_EMAIL';
export const CHANGE_INACTIVE_USER_EMAIL_SUCCESS = 'portfoplus/client/CHANGE_INACTIVE_USER_EMAIL_SUCCESS';
export const CHANGE_INACTIVE_USER_EMAIL_ERROR = 'portfoplus/client/CHANGE_INACTIVE_USER_EMAIL_ERROR';

export const ENABLE_TRACKING = 'portfoplus/client/ENABLE_TRACKING';
export const ENABLE_TRACKING_SUCCESS = 'portfoplus/client/ENABLE_TRACKING_SUCCESS';
export const ENABLE_TRACKING_ERROR = 'portfoplus/client/ENABLE_TRACKING_ERROR';
export const INIT_TAG_PHASE = 'portfoplus/client/INIT_TAG_PHASE';

export const GET_CLIENT_COVERAGE = 'portfoplus/client/GET_CLIENT_COVERAGE';
export const GET_CLIENT_COVERAGE_SUCCESS = 'portfoplus/client/GET_CLIENT_COVERAGE_SUCCESS';
export const GET_CLIENT_COVERAGE_ERROR = 'portfoplus/client/GET_CLIENT_COVERAGE_ERROR';

export const CLEAR_CLIENT_DETAILS = 'portfoplus/client/CLEAR_CLIENT_DETAILS';
export const INIT_TRACKING_PHASE = 'portfoplus/client/INIT_TRACKING_PHASE';

export const UPDATE_CLIENT_DATA = 'portfoplus/client/UPDATE_CLIENT_DATA';
export const UPDATE_VIEWING_CLIENT = 'portfoplus/client/UPDATE_VIEWING_CLIENT';

export const CREATE_DEMO_CLIENT = 'portfoplus/client/CREATE_DEMO_CLIENT';
export const CREATE_DEMO_CLIENT_SUCCESS = 'portfoplus/client/CREATE_DEMO_CLIENT_SUCCESS';
export const CREATE_DEMO_CLIENT_ERROR = 'portfoplus/client/CREATE_DEMO_CLIENT_ERROR';

export const UPDATE_DEMO_CLIENT = 'portfoplus/client/UPDATE_DEMO_CLIENT';
export const UPDATE_DEMO_CLIENT_SUCCESS = 'portfoplus/client/UPDATE_DEMO_CLIENT_SUCCESS';
export const UPDATE_DEMO_CLIENT_ERROR = 'portfoplus/client/UPDATE_DEMO_CLIENT_ERROR';

export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID';
export const GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS';
export const GET_CLIENT_BY_ID_ERROR = 'GET_CLIENT_BY_ID_ERROR';

export const UPDATE_CLIENT_REFERRER_NAMES = 'UPDATE_CLIENT_REFERRER_NAMES';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  createClientPhase: INIT,
  fetchClientPhase: INIT,
  fetchDetailsPhase: INIT,
  checkClientPhase: INIT,
  deleteClientPhase: INIT,
  getConsentPhase: INIT,
  updateFactSheetPhase: INIT,
  updateFactsheetMessage: null,
  clientData: [],
  clientDetails: {},
  clientPersonalDetails: {},
  clientMessage: null,
  clientMessageExtras: undefined,
  clientFactsheetData: null,
  error: null,
  resMessage: null,
  isSubmitting: false,
  updateEmailPhase: INIT,
  message: '',
  createClientMessageExtras: undefined,
  updateEmailMessageExtras: undefined,
  enableTrackingPhase: INIT,
  updateLanguagePhase: INIT,
  tagData: [],
  clientMatch: INIT,
  tagPhase: false,
  noCoverageData: {},
  coveragePhase: INIT,
  clientFactsheetPhase: INIT,
  viewingClient: undefined,
  lazyChange: false, // flag for indicating spinner should not be shown
  createDemoClientPhase: INIT,
  newDemoClient: null,
  updateDemoClientPhase: INIT,
  getClientByIdPhase: INIT,
  referrerNames: [],
  isAddingNewClient: false
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SUBMIT_CLIENT: {
      return state
        .set('isAddingNewClient', !action.payload?.data?.clientId)
        .set('fetchDetailsPhase', INIT)
        .set('checkClientPhase', INIT)
        .set('createClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case SUBMIT_CLIENT_SUCCESS: {
      const { payload } = action;

      const responseMSG = payload.message;
      if (responseMSG === "Client added successfully") {
        const tracking = require('../../utils/tracking');
        tracking.tracking('Client Added', {
          "Is Recruitment": payload.data.factsheetId?.isRecruitment ?? false,
          "Existing Client": payload.data.factsheetId?.existingClient ?? false,
          "Consent": payload.data.consent,
          "Client ID": payload.data._id,
          "Source": payload.data.factsheetId?.source,
        })

        if (payload.data.factsheetId?.isRecruitment) {
          // console.log('Tracking Client Recruitment: ', payload.data);
          const trackAddedPotentialRecruitment = require('../../utils/tracking/index').trackAddedPotentialRecruitment;
          trackAddedPotentialRecruitment({
            "Facesheet ID": payload.data.factsheetId?._id,
            "Source": payload.data.factsheetId?.source,
            "Create Date": payload.data.factsheetId?.createDate,
            "Latest Meeting Date": payload.data.factsheetId?.latestMeetingDate,
          })
        }
      }

      return state
        .set('createClientPhase', payload.success)
        .set('error', null)
        .set('message', payload.message)
        .set('createClientMessageExtras', payload.extras);
    }

    case SUBMIT_CLIENT_ERROR: {
      const { error } = action.payload;
      return state
        .set('error', error)
        .set('createClientPhase', ERROR)
        .set('createClientMessageExtras', undefined);
    }

    case GET_CLIENT: {
      const { lazyChange } = action;
      return state
        .set('fetchClientPhase', LOADING)
        .set('createClientPhase', INIT)
        .set('enableTrackingPhase', INIT)
        .set('fetchDetailsPhase', INIT)
        .set('updateLanguagePhase', INIT)
        .set('getConsentPhase', INIT)
        .set('checkClientPhase', INIT)
        .set('deleteClientPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
        .set('lazyChange', lazyChange);
    }

    case GET_CLIENT_SUCCESS: {
      let { payload } = action;
      //If new adviser signup and have no any client
      let clientExist = payload.data && payload.data.length > 0 ? true : false;
      // format clients' short fall data
      if (clientExist) {
        // payload.data = payload.data.map(data => {
        //   // for active connected user or inactive user
        //   if (data.connect === true || data.inactiveUser === true) {
        //     if (data.factsheetId !== null && data.factsheetId !== undefined) {
        //       // Check whether the input parameters are valid or not
        //       // For life calculation, dob & monthlyExpense are compulsory
        //       if (
        //         data.factsheetId.age !== null &&
        //         data.factsheetId.age !== undefined &&
        //         data.factsheetId.monthlyExpense !== null &&
        //         data.factsheetId.monthlyExpense !== undefined
        //       ) {
        //         // Set lifeCoverage = 0 if lifeCoverage is undefined (no policy)
        //         let lifeCoverage = _.get(data, 'lifeCoverage', 0);
        //
        //         // Set debt = 0 if debt in factsheet is invalid
        //         let debt =
        //           data.factsheetId.debt === null ||
        //           data.factsheetId.debt === undefined ||
        //           data.factsheetId.debt === '' ||
        //           !/^\d+$/.test(data.factsheetId.debt)
        //             ? 0
        //             : data.factsheetId.debt;
        //
        //         const age = getAgeFromDob(data.factsheetId.dob, data.factsheetId.age);
        //         let lifeToReachData = getLifeToReachData(lifeCoverage, data.factsheetId.monthlyExpense, debt, age);
        //
        //         data.factsheetId.lifeToReachGood = lifeToReachData.reachGood;
        //         data.factsheetId.lifeToReachExcellent = lifeToReachData.reachExcellent;
        //         data.factsheetId.lifeScore = lifeToReachData.score;
        //       }
        //
        //       // For CI calculation, monthlyIncome are compulsory
        //       if (data.factsheetId.monthlyIncome !== null && data.factsheetId.monthlyIncome !== undefined) {
        //         let ciCoverage = _.get(data, 'ciCoverage', 0);
        //         let ciToReachData = getCIToReachData(
        //           ciCoverage,
        //           data.factsheetId.monthlyIncome,
        //           AnalysisInitialStateInterface.ciClaimCoverExpenses,
        //           currency
        //         );
        //
        //         data.factsheetId.ciToReachGood = ciToReachData.reachGood;
        //         data.factsheetId.ciToReachExcellent = ciToReachData.reachExcellent;
        //         data.factsheetId.ciScore = ciToReachData.score;
        //       }
        //
        //       // For Saving calculation, monthlyIncome is compulsory
        //       if (data.factsheetId.monthlyIncome || data.factsheetId.monthlyIncome === 0) {
        //         const savingTotalCoverage = getTotalSavingCoverage(
        //           _.get(data, 'totalPremiumSaving', 0) + _.get(data, 'totalWithILAS', 0),
        //           0
        //         );
        //         const savingToReachData = getSavingToReachData(savingTotalCoverage, data.factsheetId.monthlyIncome);
        //         data.factsheetId.savingToReachMid = savingToReachData.reachMid;
        //         data.factsheetId.savingToReactHigh = savingToReachData.reachHigh;
        //         data.factsheetId.savingScore = savingToReachData.score;
        //       }
        //
        //       // Medical calculation
        //       if (data.medicalPolicyCounts) {
        //         const medicalToReachData = getMedicalToReachData(data.medicalPolicyCounts);
        //         data.factsheetId.medicalScore = medicalToReachData.score;
        //       }
        //     }
        //   }
        //   return data;
        // });

        let viewingClient = undefined;
        const clientId = JSON.parse(window.localStorage.getItem('clientId'));
        if (clientId) {
          viewingClient = (payload.data || []).find(client => client._id === clientId);
        }

        return state
          .set('clientData', payload.data)
          .set('fetchClientPhase', SUCCESS)
          .set('error', null)
          .set('fetchDetailsPhase', INIT)
          .set('lazyChange', false)
          .set('viewingClient', clientId ? viewingClient : state.viewingClient);
      } else {
        return state
          .set('clientData', [])
          .set('fetchClientPhase', SUCCESS)
          .set('error', null)
          .set('fetchDetailsPhase', INIT)
          .set('lazyChange', false)
          .set('viewingClient', undefined);
      }
    }

    case GET_CLIENT_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchClientPhase', ERROR);
    }

    case GET_CLIENT_DETAILS: {
      return state
        .set('clientDetails', {})
        .set('clientPersonalDetails', {})
        .set('fetchDetailsPhase', INIT)
        .set('error', null)
        .set('isSubmitting', true)
        .set('clientFactsheetPhase', LOADING)
        .set('checkClientPhase', INIT);
    }

    case GET_CLIENT_DETAILS_SUCCESS: {
      const { payload } = action;
      return state
        .set('clientDetails', payload.ClientFactsheetData)
        .set('clientPersonalDetails', payload.ClientProfileData)
        .set('fetchDetailsPhase', payload.success)
        .set('clientMessage', payload.message)
        .set('clientMessageExtras', payload.extras)
        .set('clientFactsheetPhase', payload.success)
        .set('error', null);
    }

    case GET_CLIENT_DETAILS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchDetailsPhase', ERROR);
    }

    case CHECK_CLIENT: {
      return state
        .set('checkClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CHECK_CLIENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('checkClientPhase', payload.success)
        .set('clientMessage', payload.message)
        .set('clientMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case CHECK_CLIENT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('checkClientPhase', ERROR);
    }

    case DELETE_CLIENT: {
      return state
        .set('deleteClientPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }
    case DELETE_CLIENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('deleteClientPhase', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case DELETE_CLIENT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('deleteClientPhase', ERROR);
    }

    case GET_CONSENT: {
      return state
        .set('getConsentPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }
    case GET_CONSENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('getConsentPhase', payload.success)
        .set('clientMessage', payload.message)
        .set('clientMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false);
    }
    case GET_CONSENT_ERROR: {
      return state.set('getConsentPhase', ERROR);
    }
    case SUBMIT_CONSENT: {
      return state.set('error', null).set('isSubmitting', true);
    }
    case SUBMIT_CONSENT_SUCCESS: {
      const { payload } = action;
      return state
        .set('error', null)
        .set('isSubmitting', false)
        .set('clientMatch', payload.matched);
    }
    case SUBMIT_CONSENT_ERROR: {
      return state.set('error', true).set('isSubmitting', false);
    }

    case UPDATE_FACTSHEET: {
      return state
        .set('updateFactSheetPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_FACTSHEET_SUCCESS: {
      const { payload } = action;
      return state
        .set('updateFactSheetPhase', payload.success)
        .set('updateFactsheetMessage', payload.message)
        .set('clientDetails', payload.ClientFactsheetData)
        .set('error', null);
    }

    case UPDATE_FACTSHEET_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('updateFactSheetPhase', ERROR);
    }

    case CHANGE_INACTIVE_USER_EMAIL: {
      return state
        .set('updateEmailPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CHANGE_INACTIVE_USER_EMAIL_SUCCESS: {
      const { payload } = action;

      // update clientDetails's email
      let currentClientDetails = state.get('clientDetails');
      if (currentClientDetails) {
        currentClientDetails = {
          ...currentClientDetails,
          email: payload.email
        };
      }

      return state
        .set('updateEmailPhase', payload.success)
        .set('message', payload.message)
        .set('updateEmailMessageExtras', payload.extras)
        .set('clientDetails', currentClientDetails)
        .set('error', null);
    }

    case CHANGE_INACTIVE_USER_EMAIL_ERROR: {
      const { error } = action.payload;
      return state
        .set('error', error)
        .set('updateEmailPhase', ERROR)
        .set('updateEmailMessageExtras', undefined);
    }

    case ENABLE_TRACKING: {
      return state
        .set('enableTrackingPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ENABLE_TRACKING_SUCCESS: {
      const { payload } = action;
      return state
        .set('enableTrackingPhase', payload.success)
        .set('message', payload.message)
        .set('error', null);
    }

    case ENABLE_TRACKING_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('enableTrackingPhase', ERROR);
    }
    case UPDATE_CLIENT_LANGUAGE: {
      return state
        .set('error', null)
        .set('updateLanguagePhase', LOADING)
        .set('isSubmitting', true);
    }

    case UPDATE_CLIENT_LANGUAGE_SUCCESS: {
      const { payload } = action;
      return state
        .set('error', null)
        .set('updateLanguagePhase', payload.success)
        .set('isSubmitting', false);
    }

    case UPDATE_CLIENT_LANGUAGE_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('updateLanguagePhase', ERROR);
    }

    case INIT_PHASE: {
      return state
        .set('clientDetails', {})
        .set('clientPersonalDetails', {})
        .set('fetchDetailsPhase', INIT);
    }

    case INIT_CHANGE_EMAIL_PHASE: {
      return state.set('updateEmailPhase', INIT).set('message', '');
    }
    case INIT_TAG_PHASE: {
      // console.log("===========")
      return state.set('tagPhase', false);
    }

    case INIT_TRACKING_PHASE: {
      return state.set('enableTrackingPhase', INIT);
    }

    case GET_CLIENT_COVERAGE: {
      return state
        .set('error', null)
        .set('isSubmitting', true)
        .set('noCoverageData', {})
        .set('coveragePhase', INIT);
    }
    case GET_CLIENT_COVERAGE_SUCCESS: {
      const { payload } = action;
      return state
        .set('noCoverageData', payload.data.policyData)
        .set('coveragePhase', true)
        .set('error', null)
        .set('isSubmitting', false);
    }
    case GET_CLIENT_COVERAGE_ERROR: {
      return state.set('error', ERROR);
    }
    case INIT_SMART_IDEA_DATA: {
      return state.set('noCoverageData', {}).set('coveragePhase', INIT);
    }
    case INIT_CREATE_PHASE: {
      return state.set('createClientPhase', INIT);
    }
    case CLEAR_CLIENT_DETAILS: {
      return state
        .set('clientDetails', {})
        .set('clientPersonalDetails', {})
        .set('clientFactsheetData', null);
    }
    case UPDATE_CLIENT_DATA: {
      return state.set(
        'clientData',
        state.get('clientData').map(client => {
          if (
            client.factsheetId &&
            client.factsheetId._id === _.get(action, 'payload.data.factsheetId._id', undefined)
          ) {
            client = { ...client, factsheetId: _.merge(client.factsheetId, action.payload.data.factsheetId) };
          }
          if (client.trackingId && client.trackingId._id === _.get(action, 'payload.data.trackingId._id', undefined)) {
            client = { ...client, trackingId: _.merge(client.trackingId, action.payload.data.trackingId) };
          }
          return client;
        })
      );
    }

    case UPDATE_VIEWING_CLIENT: {
      const { payload } = action;
      return state.set('viewingClient', payload.data.client);
    }

    case CREATE_DEMO_CLIENT: {
      return state.set('error', null).set('createDemoClientPhase', LOADING);
    }

    case CREATE_DEMO_CLIENT_SUCCESS: {
      if (action.payload.data) {
        localStorage.setItem('clientId', JSON.stringify(action.payload.data));
      }

      return state
        .set('error', null)
        .set('createDemoClientPhase', SUCCESS)
        .set('newDemoClient', action.payload.data);
    }

    case CREATE_DEMO_CLIENT_ERROR: {
      return state.set('error', action.payload.message).set('createDemoClientPhase', ERROR);
    }

    case UPDATE_DEMO_CLIENT: {
      return state.set('error', null).set('updateDemoClientPhase', LOADING);
    }

    case UPDATE_DEMO_CLIENT_SUCCESS: {
      return state.set('error', null).set('updateDemoClientPhase', SUCCESS);
    }

    case UPDATE_DEMO_CLIENT_ERROR: {
      return state.set('error', action.payload.message).set('updateDemoClientPhase', ERROR);
    }

    case GET_CLIENT_BY_ID: {
      return state.set('error', null).set('getClientByIdPhase', LOADING);
    }

    case GET_CLIENT_BY_ID_SUCCESS: {
      // create a new array so that client page can be re-rendered
      let clientData = [...state.get('clientData')];
      const viewingClient = state.get('viewingClient');
      const updatedClientData = action.payload.client;

      const clientDataIndex = (clientData || []).findIndex(client => client._id === updatedClientData._id);
      if (clientDataIndex > -1) {
        // reuse the old data (scoreLevel) because the endpoint don't compute it
        const { scoreLevel } = clientData[clientDataIndex];
        updatedClientData.scoreLevel = scoreLevel;

        clientData[clientDataIndex] = updatedClientData;
      } else {
        clientData = [updatedClientData].concat(clientData);
      }

      if (viewingClient && viewingClient._id === updatedClientData._id) {
        const { scoreLevel } = viewingClient;
        updatedClientData.scoreLevel = scoreLevel;
        state = state.set('viewingClient', updatedClientData);
      }

      return state
        .set('error', null)
        .set('getClientByIdPhase', SUCCESS)
        .set('clientData', clientData);
    }

    case GET_CLIENT_BY_ID_ERROR: {
      return state.set('error', action.payload.message).set('getClientByIdPhase', ERROR);
    }

    case UPDATE_CLIENT_REFERRER_NAMES: {
      const clientData = state.get('clientData');
      const referrerNames = _.uniq(
        (clientData || []).map(client => _.get(client, 'factsheetId.referrerName', '')).filter(Boolean)
      );
      return state.set('referrerNames', referrerNames);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const clearClientDetails = () => ({
  type: CLEAR_CLIENT_DETAILS
});

export const submitClient = data => {
  return {
    type: SUBMIT_CLIENT,
    payload: { data }
  };
};

export const getClient = () => ({
  type: GET_CLIENT
});

export const getClientDetails = data => ({
  type: GET_CLIENT_DETAILS,
  payload: data
});

export const deleteClient = data => {
  return {
    type: DELETE_CLIENT,
    payload: { data }
  };
};

export const checkClient = data => {
  return {
    type: CHECK_CLIENT,
    payload: { data }
  };
};

export const getConsent = data => {
  return {
    type: GET_CONSENT,
    payload: { data }
  };
};

export const submitConsent = data => {
  return {
    type: SUBMIT_CONSENT,
    payload: { data }
  };
};

export const updateFactSheet = data => {
  return {
    type: UPDATE_FACTSHEET,
    payload: { data }
  };
};

export const initPhase = () => ({
  type: INIT_PHASE
});

export const initCreatePhase = () => ({
  type: INIT_CREATE_PHASE
});

export const initChangeEmailPhase = () => ({
  type: INIT_CHANGE_EMAIL_PHASE
});

export const initSmartIdeaData = () => ({
  type: INIT_SMART_IDEA_DATA
});

export const createDemoClient = payload => ({
  type: CREATE_DEMO_CLIENT,
  payload
});

export const changeInactiveUserEmail = data => async (dispatch, getState) => {
  dispatch({ type: CHANGE_INACTIVE_USER_EMAIL, payload: { data } });

  try {
    const payload = await api.changeInactiveUserEmail(data);
    dispatch({
      type: CHANGE_INACTIVE_USER_EMAIL_SUCCESS,
      payload: { ...payload, ...data }
    });
    dispatch({
      type: GET_CLIENT_BY_ID,
      payload: { clientId: data.clientId }
    });
    return payload;
  } catch (error) {
    dispatch({
      type: CHANGE_INACTIVE_USER_EMAIL_ERROR,
      payload: { error }
    });
    return false;
  }
};

export const enableTracking = data => async (dispatch, getState) => {
  dispatch({ type: ENABLE_TRACKING, payload: { data } });

  try {
    const payload = await api.enableTracking(data);
    if (payload.returnData) {
      dispatch({ type: ENABLE_TRACKING_SUCCESS, payload });
    } else {
      dispatch({ type: ENABLE_TRACKING_SUCCESS, payload });
      dispatch({ type: GET_CLIENT_BY_ID, payload: { clientId: data.clientId } });
    }
    return payload;
  } catch (error) {
    dispatch({
      type: ENABLE_TRACKING_ERROR,
      payload: { error }
    });
    return false;
  }
};

export const updateClientLanguage = data => ({
  type: UPDATE_CLIENT_LANGUAGE,
  payload: { data }
});

export const initTagPhase = () => ({
  type: INIT_TAG_PHASE
});

export const initTrackingPhase = () => ({
  type: INIT_TRACKING_PHASE
});

export const getClientCoverage = data => {
  return {
    type: GET_CLIENT_COVERAGE,
    payload: { data }
  };
};

export const updateClientData = data => (dispatch, _getState) => {
  dispatch({ type: UPDATE_CLIENT_DATA, payload: { data } });
};

export const updateViewingClient = client => async (dispatch, getState) => {
  if (!client) {
    dispatch({ type: RESET_CATEGORIES_PORTFOLIO });
  }
  dispatch({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client } } });
};

export const updateDemoClient = clientId => async (dispatch, _getState) => {
  dispatch({ type: UPDATE_DEMO_CLIENT });

  try {
    const response = await api.changeDemoClient(clientId);
    const client = _.get(response, 'client');
    dispatch({ type: GET_CLIENT_BY_ID, payload: { clientId: clientId } });
    dispatch({ type: UPDATE_DEMO_CLIENT_SUCCESS, payload: { client: client } });
    return client;
  } catch (error) {
    dispatch({ type: UPDATE_DEMO_CLIENT_ERROR, payload: { error } });
  }
};

export const getClientById = clientId => ({
  type: GET_CLIENT_BY_ID,
  payload: { clientId }
});

/***********************************
 * Epics
 ***********/

const submitClientEpic = action$ =>
  action$.ofType(SUBMIT_CLIENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.submitClient(action.payload.data))
      .flatMap(payload => {
        const actions = [
          {
            type: SUBMIT_CLIENT_SUCCESS,
            payload
          }
        ];

        // for create
        if (payload.data && payload.data._id) {
          actions.push({ type: GET_CLIENT_BY_ID, payload: { clientId: payload.data._id } });
        } else if (action.payload.data && action.payload.data.clientId) {
          // for edit (I have no idea why the api responses are different)
          actions.push({ type: GET_CLIENT_BY_ID, payload: { clientId: action.payload.data.clientId } });
        }

        if (!payload.returnData) {
          actions.push({ type: GET_USER });
        }

        // if client data is included, update viewing client
        if (payload.data) {
          actions.push({ type: UPDATE_VIEWING_CLIENT, payload: { data: { client: payload.data } } });
        }

        return actions;
      })
      .catch(error => {
        Rx.Observable.of({
          type: SUBMIT_CLIENT_ERROR,
          payload: { error: error.toString() }
        });
      });
  });

const getClientEpic = (action$, store$) =>
  action$.ofType(GET_CLIENT).mergeMap(() => {
    const state = store$.getState();
    return Rx.Observable.fromPromise(api.getClient())
      .map(payload => {
        return {
          type: GET_CLIENT_SUCCESS,
          figure: state.figure,
          payload
        };
      })
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_ERROR,
          payload: { error }
        })
      );
  });

const getClientDetailsEpic = action$ =>
  action$.ofType(GET_CLIENT_DETAILS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientDetails(action.payload))
      .map(payload => ({
        type: GET_CLIENT_DETAILS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_DETAILS_ERROR,
          payload: { error }
        })
      );
  });

const deleteClientEpic = action$ =>
  action$.ofType(DELETE_CLIENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteClient(action.payload.data))
      .flatMap(payload => {
        localStorage.removeItem('clientId');
        sessionStorage.removeItem('client');
        return [
          {
            type: DELETE_CLIENT_SUCCESS,
            payload
          },
          {
            type: GET_CLIENT
          },
          {
            type: UPDATE_VIEWING_CLIENT,
            payload: {
              data: {
                client: undefined
              }
            }
          }
        ];
      })
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_CLIENT_ERROR,
          payload: { error }
        })
      );
  });

const checkClientEpic = action$ =>
  action$.ofType(CHECK_CLIENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.checkClient(action.payload.data))
      .map(payload => ({
        type: CHECK_CLIENT_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: CHECK_CLIENT_ERROR,
          payload: { error }
        })
      );
  });

const getConsentEpic = action$ =>
  action$.ofType(GET_CONSENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getConsent(action.payload.data))
      .flatMap(payload => [
        {
          type: GET_CONSENT_SUCCESS,
          payload
        },
        {
          type: GET_CLIENT_BY_ID,
          payload: { clientId: action.payload.data.clientId }
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: GET_CONSENT_ERROR,
          payload: { error }
        })
      );
  });

const submitConsentEpic = action$ =>
  action$.ofType(SUBMIT_CONSENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.submitConsent(action.payload.data))
      .flatMap(payload => [
        {
          type: SUBMIT_CONSENT_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: SUBMIT_CONSENT_ERROR,
          payload: { error }
        })
      );
  });

const updateFactSheetEpic = action$ =>
  action$.ofType(UPDATE_FACTSHEET).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateFactSheet(action.payload.data))
      .flatMap(payload => {
        if (payload.returnData) {
          return [
            {
              type: UPDATE_FACTSHEET_SUCCESS,
              payload
            }
          ];
        } else {
          return [
            {
              type: UPDATE_FACTSHEET_SUCCESS,
              payload
            },
            {
              type: GET_CLIENT_BY_ID,
              payload: { clientId: action.payload.data.clientId }
            },
            {
              type: GET_USER
            }
          ];
        }
      })
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_FACTSHEET_ERROR,
          payload: { error }
        })
      );
  });

const updateClientLanguageEpic = action$ =>
  action$.ofType(UPDATE_CLIENT_LANGUAGE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateClientLanguage(action.payload.data))
      .flatMap(payload => [
        {
          type: UPDATE_CLIENT_LANGUAGE_SUCCESS,
          payload
        },
        {
          type: GET_CLIENT_BY_ID,
          payload: { clientId: action.payload.data.clientId }
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_CLIENT_LANGUAGE_ERROR,
          payload: { error }
        })
      );
  });

const getClientCoverageEpic = action$ =>
  action$.ofType(GET_CLIENT_COVERAGE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientCoverage(action.payload.data))
      .flatMap(payload => [
        {
          type: GET_CLIENT_COVERAGE_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_COVERAGE_ERROR,
          payload: { error }
        })
      );
  });

const createDefaultClientEpic = action$ =>
  action$.ofType(CREATE_DEMO_CLIENT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.createDemoClient(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
            {
              type: GET_CLIENT_BY_ID,
              payload: { clientId: payload.data }
            },
            {
              type: CREATE_DEMO_CLIENT_SUCCESS,
              payload
            },
            {
              type: FETCH_POLICY,
              payload: {
                clientId: payload.data
              }
            }
          ]
          : [
            {
              type: CREATE_DEMO_CLIENT_ERROR,
              payload
            }
          ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: CREATE_DEMO_CLIENT_ERROR,
          payload: { error }
        })
      );
  });

const getClientByIdEpic = action$ =>
  action$.ofType(GET_CLIENT_BY_ID).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getClientById(action.payload.clientId))
      .flatMap(payload =>
        payload.success
          ? [
            {
              type: GET_CLIENT_BY_ID_SUCCESS,
              payload
            }
          ]
          : [
            {
              type: GET_CLIENT_BY_ID_ERROR,
              payload
            }
          ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: GET_CLIENT_BY_ID_ERROR,
          payload: { error }
        })
      );
  });

const updateClientsReferrerNamesEpic = (action$, state$) =>
  action$.ofType(GET_CLIENT_SUCCESS, UPDATE_CLIENT_DATA, GET_CLIENT_BY_ID_SUCCESS).mergeMap(action => {
    return Rx.Observable.fromPromise(
      new Promise(resolve =>
        resolve({
          type: UPDATE_CLIENT_REFERRER_NAMES
        })
      )
    );
  });

export const clientEpic = combineEpics(
  submitClientEpic,
  getClientEpic,
  checkClientEpic,
  deleteClientEpic,
  getClientDetailsEpic,
  getConsentEpic,
  submitConsentEpic,
  updateFactSheetEpic,
  updateClientLanguageEpic,
  getClientCoverageEpic,
  createDefaultClientEpic,
  getClientByIdEpic,
  updateClientsReferrerNamesEpic
);
