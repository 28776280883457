import React from 'react';
import { Grid } from '@material-ui/core';
import FunctionCard from './FunctionCard';

const ContainerStyle = {
  maxWidth: 635,
  width: '100%',
  margin: 'auto'
};

const FunctionCardsContainer = props => {
  const { prepend, items, append } = props;

  return (
    <Grid container spacing={2} style={ContainerStyle}>
      {prepend && (
        <Grid item xs={12}>
          {prepend}
        </Grid>
      )}
      {items.map((item, index) =>
        item ? (
          <Grid item xs={12} key={index}>
            <FunctionCard key={index} {...item} />
          </Grid>
        ) : null
      )}
      {append && (
        <Grid item xs={12}>
          {append}
        </Grid>
      )}
    </Grid>
  );
};

export default FunctionCardsContainer;
