export const SIGN_UP_PLAN_MODE = { ALL: 'a', PRO_YEAR: 'py', LITE_QUARTER: 'lq' };

export const TEAM_YEAR_SUBSCRIPTION_PLAN = {
  interval: 'year',
  intervalCount: 1,
  order: 100,
  team: true,
  displayName: {
    en: 'Team',
    zh: '团队',
    zhHantHK: '團隊'
  },
  description: {
    en:
      '✔ ALL Pro Function\n✔ Team Activities Tracking\n✔ Team Business Tracking\n✔ Customized Team Logo\n✔ Customized Agent Function\n✔ Cross Team Competition (To-be launched)\n',
    zh:
      '✔ 所有 Pro 功能\n✔ 团队活动量分析\n✔ 团队生意大数据\n✔ 自定团队Logo\n✔ 度身订造顾问功能\n✔ 团队PK赛 (即将推出)',
    zhHantHK:
      '✔ 所有 Pro 功能\n✔ 團隊活動量分析\n✔ 團隊生意大數據\n✔ 自定團隊Logo\n✔ 度身訂造顧問功能\n✔ 團隊PK賽 (即將推出)'
  }
};

export const TEAM_QUARTER_SUBSCRIPTION_PLAN = {
  interval: 'month',
  intervalCount: 3,
  order: 100,
  team: true,
  displayName: {
    en: 'Team',
    zh: '团队',
    zhHantHK: '團隊'
  },
  description: {
    en:
      '✔ ALL Pro Function\n✔ Team Activities Tracking\n✔ Team Business Tracking\n✔ Customized Team Logo\n✔ Customized Agent Function\n✔ Cross Team Competition (To-be launched)\n',
    zh:
      '✔ 所有 Pro 功能\n✔ 团队活动量分析\n✔ 团队生意大数据\n✔ 自定团队Logo\n✔ 度身订造顾问功能\n✔ 团队PK赛 (即将推出)',
    zhHantHK:
      '✔ 所有 Pro 功能\n✔ 團隊活動量分析\n✔ 團隊生意大數據\n✔ 自定團隊Logo\n✔ 度身訂造顧問功能\n✔ 團隊PK賽 (即將推出)'
  }
};
