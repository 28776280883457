import React from 'react';
import { Card, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import GuidanceButton from './GuidanceButton/GuidanceButton';

const CardStyle = {
  padding: 12
};

const ButtonsGridStyle = {
  flexGrow: 1
};

const ButtonHeightStyle = {
  padding: '6px 6px'
};

const TypoStyle = {
  whiteSpace: 'nowrap',
  color: '#8069ff'
};

const GuidanceGroup = props => {
  const { intl, items } = props;
  const isWide = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Card style={CardStyle}>
      <Grid container alignItems="center" spacing={2} wrap="nowrap">
        <Grid item>
          <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <img src="img/stepbystep.svg" alt="guidance" width={isWide ? 48 : 38} />
            </Grid>
            <Grid item>
              <Typography color="primary" style={TypoStyle}>
                {intl.formatMessage({ id: 'quick-demo' })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={ButtonsGridStyle}>
          <Grid container justify="center" spacing={2}>
            {items.map((item, index) => (
              <Grid key={index} item xs={12} style={ButtonHeightStyle}>
                <GuidanceButton color="#8069ff" {...item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default injectIntl(GuidanceGroup);
