import React from 'react';
import { toggleDialog } from '../../../store/control/duck';
import { connect } from 'react-redux';
import QuickNotesRawDialog from './QuickNotesRawDialog';

const QuickNotesDialog = props => {
  const { quickNotesDialog, toggleDialog } = props;
  const { onSubmit, remark, closed } = quickNotesDialog || {};

  const close = () => {
    toggleDialog('quickNotes', { ...quickNotesDialog, closed: true });
  };

  const onExited = () => {
    toggleDialog('quickNotes', false);
  };

  return (
    <QuickNotesRawDialog
      remark={remark}
      open={!!quickNotesDialog && !closed}
      onClose={close}
      onExited={onExited}
      onSubmit={onSubmit}
    />
  );
};

const container = connect(
  // Map state to props
  state => ({
    quickNotesDialog: state.control.quickNotesDialog
  }),
  {
    toggleDialog
  }
)(QuickNotesDialog);

export default container;
