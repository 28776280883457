import React from 'react';

const BackgroundStyle = {
  position: 'absolute',
  width: 'calc(100% + 16px)',
  height: 54,
  zIndex: 0,
  opacity: 1,
  bottom: -8,
  left: -7,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '0 0 3px 55px'
};

const AdviserInfoPageCardFooter = props => {
  const { style, ...rest } = props;

  return <div style={{ ...BackgroundStyle, ...style }} {...rest} />;
};

export default AdviserInfoPageCardFooter;
