import { Doughnut } from 'react-chartjs-2';
import React from 'react';
import { injectIntl } from 'react-intl';
import { customLegendCallback, PercentageTooltipLabeler, RoundedEdgePlugin } from '../../../../../../utils/chart-js';
import './PolicySavingCountChart.scss';
import _ from 'lodash';

class PolicySavingCountChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.chartRef = React.createRef();
    this.legendRef = React.createRef();
  }

  render() {
    const { intl, counts } = this.props;
    const { countwithGuaranttee, countwithILAS, countNoIdea } = counts;
    const total = countwithGuaranttee + countwithILAS + countNoIdea;

    const isNull = countwithGuaranttee === 0 && countwithILAS === 0 && countNoIdea === 0 ? true : false;

    // const nullData ={
    //   labels: [intl.formatMessage({ id: 'No Policy' })],
    //   datasets: [
    //     {
    //       label: 'Graph1',
    //       data: ['0.1'],
    //       backgroundColor: ['#E9e9e9'],
    //       hoverBackgroundColor: ['#E9e9e9']
    //     },
    //     {
    //       label: 'Graph2',
    //       data: ['0.1'],
    //       backgroundColor: ['#E9e9e9'],
    //       hoverBackgroundColor: ['#E9e9e9']
    //     }
    //   ]
    // };

    const data = {
      labels: [
        intl.formatMessage({ id: 'Guarantee' }),
        intl.formatMessage({ id: 'ILAS' }),
        intl.formatMessage({ id: 'No Idea' })
      ],

      datasets: [
        {
          data: [countwithGuaranttee, countwithILAS, countNoIdea],
          labels: [
            intl.formatMessage({ id: 'Guarantee' }),
            intl.formatMessage({ id: 'ILAS' }),
            intl.formatMessage({ id: 'No Idea' })
          ],
          backgroundColor: ['#8c1aff', '#BF80FF', '#E9e9e9'],
          hoverBackgroundColor: ['#8c1aff', '#BF80FF', '#E9e9e9']
        }
      ],
      texts: [
        {
          subtexts: [
            {
              text: intl.formatMessage({ id: 'Saving Policy Count' }),
              color: '#757575'
            }
          ],
          fontSizeFactor: 1,
          fontWeight: '400'
        },
        {
          subtexts: [
            {
              text: (total ? total : 0).toString(),
              color: '#3966f8'
            }
          ],
          fontSizeFactor: 2,
          fontWeight: '400'
        }
      ]
    };

    const options = {
      cutoutPercentage: 78,
      legendCallback: customLegendCallback(),
      animation: {
        duration: 3000
      },
      cutoutPercentage: 80,
      aspectRatio: 1,
      plugins: {
        datalabels: {
          display: false
        },
        roundedEdge: true
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: PercentageTooltipLabeler
        }
      }
    };

    const legendDiv = _.get(this.legendRef, 'current');
    const chartInstance = _.get(this.chartRef, 'current.chartInstance');
    if (legendDiv && chartInstance) {
      legendDiv.innerHTML = chartInstance.generateLegend();
    }
    return (
      <div className="Outer" style={{ marginTop: '1px' }}>
        <Doughnut data={data} options={options} plugins={RoundedEdgePlugin} ref={this.chartRef} />

        <div ref={this.legendRef} />
      </div>
    );
  }
}

export default injectIntl(PolicySavingCountChart);
