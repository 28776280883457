export const DEFAULT_PLAN_DATA = {
  feature: '',
  policyName: '',
  planType: '',
  policyNumber: '',
  insurer: '',
  type: '',
  companyName: '',
  guaranteeValue: '',
  vhisPlan: '',
  coverageType: '',
  roomClass: '',
  isAnnuity: '',
  accountTypes: [],
  addOns: []
};

export const DEFAULT_PAYMENT_DATA = {
  currency: '',
  sumInsured: '',
  roomClass: '',
  lifeCoverage: 0,
  premium: 0,
  frequency: '',
  levelPremium: '',
  taxDeductible: '',
  paymentMethod: '',
  policyStartDate: null,
  lumpSumAmount: '',
  policyDueDate: '',
  adviserReminderActive: false,
  reminderDate: '',
  remindMeDay: 21,
  reminderActive: false,
  naPremium: false
};

export const DEFAULT_DETAIL_DATA = {
  policyOwner: '',
  policyOwnerSelected: '',
  lifeInsured: '',
  lifeInsuredSelected: '',
  lifeInsuredAge: '',
  paymentTerms: '',
  protectionTerms: '',
  beneficial: '',
  beneficialSelected: '',
  paymentTermsOther: '',
  protectionTermsOther: '',
  vehicleBrand: '',
  vehicleModel: '',
  vehicleYear: '',
  ncb: '',
  homeContentsValue: '',
  homeSize: '',
  coverageDetail: '',
  coverageDay: '',
  tripDelayCover: '',
  lossItemCover: '',
  mobilePhoneCover: '',
  rentalCarCover: '',
  emergencyTel: '',
  permanentDisablement: '',
  hospitalCashDetail: '',
  physiotherapy: '',
  medicalExpenses: '',
  chineseDoctor: '',
  chineseBonesetter: '',
  employersLiability: '',
  outPatientDetail: '',
  dentalDetail: '',
  repatriation: '',
  accident: '',
  maidReplacement: '',
  policyStatus: '',
  premiumHolidayDate: null,
  totalWithdrawn: '',
  marketValue: '',
  surrenderValue: '',
  propertyValue: '',
  downPayment: '',
  loanRatio: '',
  outStandingLoan: '',
  interestRate: '',
  interestTerms: '',
  penaltyPeriod: '',
  smm: 'No',
  hospitalCash: 'No',
  outPatient: 'No',
  dental: 'No',
  deductible: '',
  claimLimit: '',
  paidUpDate: null,
  selectedPolicyStatus: '',
  multiClaim: false,
  earlyStage: false,
  marketValueUpdateDate: null
};

export const DEFAULT_APPENDIX_DATA = {
  advisorName: '',
  advisorPhoneNo: '',
  advisorPhoneNo2: '',
  advisorEmail: '',
  remark: '',
  uploadDocuments: [],
  orphanCase: '',
  isDraft: false,
  productLink: ''
};

export const POLICY_NAMES = {
  SunLife: [
    'Rainbow MPF Scheme',
    '彩虹強積金計劃',
    'MPF Master Trust',
    '強積金集成信託計劃',
    'MPF Basic Scheme',
    '強積金基本計劃',
    'MPF Comprehensive Scheme',
    '強積金綜合計劃',
    'Rainbow ORSO Scheme',
    '彩虹公積金計劃'
  ],

  Manulife: [
    'GlobalSelect (MPF) Scheme',
    '環球精選(強積金) 計劃',
    'RetireChoice (MPF) Scheme',
    '退休精選(強積金) 計劃'
  ],

  AIA: ['Prime Value Choice', '強積金優選計劃', 'AIA ORSO', '職業退休計劃'],

  Prudential: ['Easy choice MPF', '中銀保誠我的強積金', 'My choice MPF ', '中銀保誠簡易強積金', 'ORSO', '公積金計劃'],

  'BOC Life': ['Easy choice MPF', '中銀保誠我的強積金', 'My choice MPF ', '中銀保誠簡易強積金', 'ORSO', '公積金計劃'],

  HSBC: ['SuperTrust Plus', '滙豐強積金智選計劃', 'HSBC ORSO', '滙豐綜合公積金']
};
