import React, { useEffect, useState, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { AVATARS } from '../../../../../constants/my-details';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { usePrevious } from '../../../../../utils/hooks';
import MyDetailsProfilePicture from '../MyDetailsProfilePicture/MyDetailsProfilePicture';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
import UploadAvatarDialog from '../UploadAvatarDialog/UploadAvatarDialog';

const GridItemStyle = {
  cursor: 'pointer'
};

const AvatarStyle = {
  transition: 'border 100ms ease-out',
  padding: 0
};

const UploadButtonStyle = {
  padding: 8
};

const UploadIconStyle = {
  fontSize: 48
};

const UploadHintTypoStyle = {
  color: 'lightgrey'
};

const LineThroughTypoStyle = {
  flexGrow: 1,
  borderBottom: '1px solid lightgrey',
  marginBottom: 14
};

const AvatarPickerDialog = props => {
  const { intl, open, onClose, initial, avatar, onChange, borderColor } = props;

  const [uploadDialog, setUploadDialog] = useState(false);
  const [internalAvatarSrc, setInternalAvatarSrc] = useState(avatar || null);
  const prevInternalAvatar = usePrevious(internalAvatarSrc);

  const submit = () => {
    onChange(internalAvatarSrc || null);
    onClose();
  };

  const createSelectAvatarHandler = src => () => setInternalAvatarSrc(src);
  const openUploadDialog = () => setUploadDialog(true);
  const closeUploadDialog = () => setUploadDialog(false);
  const useUploadedImage = url => {
    if (avatar === url) {
      if (avatar) {
        if (avatar.indexOf('?') === avatar.length - 1) {
          onChange(url);
        } else {
          onChange(`${url}?`);
        }
      } else {
        onChange(url);
      }
    } else {
      onChange(url);
    }
    setUploadDialog(false);
    onClose();
  };

  useEffect(() => {
    if (avatar !== internalAvatarSrc && internalAvatarSrc === prevInternalAvatar) {
      setInternalAvatarSrc(avatar);
    }
  }, [avatar, internalAvatarSrc, prevInternalAvatar]);

  return (
    <Fragment>
      <UploadAvatarDialog open={uploadDialog} onClose={closeUploadDialog} onSuccess={useUploadedImage} />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        PaperProps={{
          style: {
            maxWidth: 64 * Math.ceil(AVATARS.length / 2) - 8 + 48
          }
        }}
        TransitionComponent={SlideTransition}
      >
        <DialogTitle>{intl.formatMessage({ id: 'avatar-picker-dialog-title' })}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justify="flex-start" spacing={1}>
                {AVATARS.map((src, index) => (
                  <Grid key={index} item onClick={createSelectAvatarHandler(src)} style={GridItemStyle}>
                    <MyDetailsProfilePicture
                      src={src}
                      initial={initial}
                      width={48}
                      borderColor={src === internalAvatarSrc ? borderColor : 'transparent'}
                      style={AvatarStyle}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item style={LineThroughTypoStyle} />
                <Grid item>
                  <Typography style={UploadHintTypoStyle}>{intl.formatMessage({ id: 'or-upload' })}</Typography>
                </Grid>
                <Grid item style={LineThroughTypoStyle} />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <IconButton color="primary" onClick={openUploadDialog} style={UploadButtonStyle}>
                <CloudUploadRoundedIcon style={UploadIconStyle} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" onClick={submit}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default injectIntl(AvatarPickerDialog);
