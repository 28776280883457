import { ERROR, INIT, LOADING } from '../../constants/phase';
import produce from 'immer';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const GET_AFFILIATE_IDLE = 'GET_AFFILIATE_IDLE';
export const GET_AFFILIATE_PENDING = 'GET_AFFILIATE_PENDING';
export const GET_AFFILIATE_SUCCESS = 'GET_AFFILIATE_SUCCESS';
export const GET_AFFILIATE_ERROR = 'GET_AFFILIATE_ERROR';

/***********************************
 * Initial State
 ***********/
const initialState = {
  affiliate: undefined,
  getPhase: INIT,
  error: undefined
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_AFFILIATE_IDLE:
        draft = initialState;
        break;
      case GET_AFFILIATE_PENDING:
        draft.getPhase = LOADING;
        break;
      case GET_AFFILIATE_SUCCESS:
        const { success, affiliate } = action;
        draft.affiliate = affiliate;
        draft.getPhase = success;
        draft.error = undefined;
        break;
      case GET_AFFILIATE_ERROR:
        const { error } = action;
        draft.affiliate = undefined;
        draft.getPhase = ERROR;
        draft.error = error;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getAffiliate = () => async (dispatch, getState) => {
  dispatch({ type: GET_AFFILIATE_PENDING });

  try {
    const response = await api.getAffiliate();
    dispatch({ type: GET_AFFILIATE_SUCCESS, success: response.success, affiliate: response.affiliate });
  } catch (error) {
    dispatch({ type: GET_AFFILIATE_ERROR, error });
  }
};

export const resetAffiliate = () => ({ type: GET_AFFILIATE_IDLE });
