import React from 'react';
import { PureComponent } from 'react';
import Modal from 'react-modal';
import { SUCCESS } from '../../constants/phase';
import { QR_CODE_TYPES } from '../../constants/qrCode';
import QRShareToUser from './QRShareToUser/container';
import { getDefaultRoute } from '../../utils/router';

class QRCodeView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: '',
      type: undefined
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { match, isAuthenticated, checkQRCode } = this.props;
    //console.log('mount');
    const qrCodeId = match.params.qrCodeId;

    if (qrCodeId !== undefined && qrCodeId !== '') {
      // Check whether the user if logged in or not
      if (isAuthenticated) {
        if (checkQRCode !== undefined && typeof checkQRCode === 'function') {
          checkQRCode(qrCodeId);
        }
      } else {
        this.setState({ modalOpen: true, modalType: 'login' });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    //console.log('WillReceiveProps');
    //const qrCodeId = this.props.match.params.qrCodeId;
    //console.log(nextProps);

    // Dealing with checkQRCode api response
    if (nextProps.qrCodeFetchPhase === SUCCESS) {
      //console.log('fetch success');
      if (nextProps.qrCodeError === null) {
        //console.log('backend no error');

        const { qrCode } = nextProps;
        if (qrCode !== undefined) {
          if (qrCode.valid === true) {
            //console.log('qr code valid');
            if (qrCode.type === QR_CODE_TYPES.SHARE_TO_USER) {
              // this.props.history.push({
              // 	pathname: '/qr_share_to_user',
              // 	qrCodeId: qrCodeId
              // });
              this.setState({ type: QR_CODE_TYPES.SHARE_TO_USER });
            } else {
              console.log('unexpected QR code type');
              this.setState({ modalOpen: true, modalType: 'invalid' });
            }
          } else {
            console.log('qr code invalid');
            this.setState({ modalOpen: true, modalType: 'invalid' });
          }
        } else {
          console.log('no qr code found');
          this.setState({ modalOpen: true, modalType: 'invalid' });
        }
      } else {
        console.log(nextProps.qrCodeError);
        this.setState({ modalOpen: true, modalType: 'invalid' });
      }
    }
  }

  componentWillUnmount() {
    const { cleanQRCode } = this.props;
    if (cleanQRCode !== undefined && typeof cleanQRCode === 'function') {
      cleanQRCode();
    }
  }

  handleCloseModal() {
    const { modalType } = this.state;
    // console.log(modalType)
    if (modalType === 'login') {
      this.props.history.push({
        pathname: '/login',
        nextUrl: this.props.location.pathname
      });
    } else {
      this.redirectToHome();
    }
  }

  redirectToHome() {
    const { isAuthenticated, userDetails } = this.props;

    if (isAuthenticated && userDetails !== undefined) {
      this.props.history.push({
        pathname: getDefaultRoute(userDetails)
      });
    } else {
      this.props.history.push({
        pathname: '/'
      });
    }
  }

  render() {
    const { intl } = this.props;
    const { modalOpen, modalType, type } = this.state;

    return (
      <div>
        {type === QR_CODE_TYPES.SHARE_TO_USER && <QRShareToUser />}

        <Modal className="modalView" isOpen={modalOpen} onRequestClose={this.handleCloseModal}>
          <div className="modal-text">
            {modalType === 'login'
              ? intl.formatMessage({ id: 'login-or-signup' })
              : intl.formatMessage({
                  id: 'The QR code is invalid / The action is invalid'
                })}
          </div>
          <div className="modalBtn">
            <input type="button" onClick={this.handleCloseModal} className="action-button" value="OK" />
          </div>
        </Modal>
      </div>
    );
  }
}

export default QRCodeView;
