import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';

class ReferralConfirmModal extends React.Component {
  render() {
    const { intl, open, onClose, email, checked, confirmEmail, submitForm, err } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Thank you' })}</DialogTitle>
        <DialogContent>
          <div className="modal-text">
            <p>
              {intl.formatMessage({
                id: this.props.fromReferClient ? 'confirm-info-correct' : 'Please DOUBLE check the email is correct.'
              })}
            </p>
            <p className="referral-email">{email}</p>
            <FormControlLabel
              control={<Checkbox color="primary" checked={checked} onClick={confirmEmail} />}
              className="referral-check"
              label={intl.formatMessage({ id: this.props.fromReferClient ? 'ReferCorrectInfo' : 'ReferCorrect' })}
            />
            <i className="bar" />
            {err ? <p className="error_field">{intl.formatMessage({ id: err })}</p> : ''}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
          <Button onClick={submitForm} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReferralConfirmModal;
