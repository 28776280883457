import { isEmailValid } from './email';
import { isEmptyString } from './string';
import { checkEmail } from '../store/user/api';
import { toast } from 'react-toastify';
import IconToast from '../components/NewToast';

export const validateShareEmail = (email, selfEmail) => {
  if (!email) {
    return 'Enter Email.';
  } else if (!isEmailValid(email)) {
    return 'Invalid email';
  } else if (selfEmail && email === selfEmail) {
    return 'Cannot share policy to yourself';
  }
  return undefined;
};

export const validateShareAccessCode = accessCode => {
  if (isEmptyString(accessCode)) {
    return 'Enter Access Code';
  }
  if (accessCode.length < 8) {
    return 'access-code-8-char';
  }
  if (accessCode.match(/^(?=.*[a-zA-Z])/g) === null) {
    return 'access-code-one-letter';
  }
  if (accessCode.match(/^(?=.*[0-9])/g) === null) {
    return 'access-code-one-number';
  }

  return undefined;
};

export const getErrorMessageIntl = error => {
  if (error === 'required') {
    return 'Required input field';
  } else {
    return error;
  }
};

export const validateName = name => {
  if (isEmptyString(name)) {
    return 'Name is required';
  }
  return undefined;
};

export const validateEmail = email => {
  if (isEmptyString(email)) {
    return 'Email is required';
  } else if (!isEmailValid(email)) {
    return 'Invalid email';
  }
  return undefined;
};

export const validateEmailBackend = async email => {
  const response = await checkEmail(email);
  if (response.result === true) {
    return undefined;
  } else {
    if (response.message === 'existing-user') {
      return 'existing-user';
    } else {
      return 'invalid-email-address';
    }
  }
};

export const passwordValidations = new Map([
  // These validators return true if the rule is valid
  ['password-required', password => !isEmptyString(password)],
  ['password-8-char', password => password?.length >= 8],
  ['password-one-letter', password => password?.match(/^(?=.*[a-zA-Z])/g) !== null],
  ['password-one-number', password => password?.match(/^(?=.*[0-9])/g) !== null]
]);

export const validatePassword = password => {
  if (isEmptyString(password)) {
    return 'password-required';
  }
  if (password.length < 8) {
    return 'password-8-char';
  }
  if (password.match(/^(?=.*[a-zA-Z])/g) === null) {
    return 'password-one-letter';
  }
  if (password.match(/^(?=.*[0-9])/g) === null) {
    return 'password-one-number';
  }
  return undefined;
};

export const validatePasswordConfirm = (passwordConfirm, password) => {
  if (isEmptyString(passwordConfirm)) {
    return 'Confirm Password is required';
  }
  if (passwordConfirm !== password) {
    return 'Password do not match';
  }
  return undefined;
};

export const validatePhoneNumber = phoneNumber => {
  if (isEmptyString(phoneNumber)) {
    return 'input_phone_number';
  }
  return undefined;
};

export const validateTacChecked = tacChecked => {
  if (tacChecked === false) {
    return 'Please read and accept beforehand';
  }
  return undefined;
};

export const validatePaConnectedEmail = paConnectedEmail => {
  if (isEmptyString(paConnectedEmail)) {
    return 'Email is required';
  } else if (!isEmailValid(paConnectedEmail)) {
    return 'Invalid email';
  }
  return undefined;
};

export const validatePdfPassword = password => {
  if (isEmptyString(password)) {
    return 'password-required';
  }

  return undefined;
};
