import React, { PureComponent, Fragment } from 'react';
import ShareToView from './partials/shareTo';
import ShareFromView from './partials/shareFrom';
import ShareOptionView from './partials/shareOptions';
import ShareLinkView from './partials/shareLinksView';
import ShareLink from './partials/shareLinks';
import ViewShareDetails from './partials/viewShareDetails';
import ConnectWithAdviser from './partials/ConnectWithAdviser';
import MyTabs from '../../components/MyTabs/MyTabs';
import MySwipeableViews from '../../components/MySwipeableViews/MySwipeableViews';

class ShareView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      user: '',
      calling: false,
      tab: ''
    };
    this.handleSwipe = this.handleSwipe.bind(this);
  }
  componentDidMount() {
    this.props.renderHeaderButton(true);
    const currentLocation = this.props.location.pathname;
    let tabIndex = currentLocation === '/share' ? 0 : currentLocation === '/share_from' ? 1 : '';
    if (tabIndex !== '') {
      this.setState({ tab: tabIndex });
    }
  }

  UNSAFE_componentWillMount() {
    this.props.currencyExchangeRate();
    //this.props.getUser();
    if (this.props.match.params.Id) {
      var id = this.props.location.pathname.replace('/share/', '');
      this.setState({ id: id });
    }
    setTimeout(() => {
      var user = this.props.userDetails;
      if (!this.isEmpty(user)) {
        this.setState({ user: user });
        this.props.getShareFromData();
        this.props.getUser();
        this.setState({ calling: true });
      }
    }, 200);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var user = nextProps.userDetails;
    if (!this.isEmpty(user) && nextProps.shareFromData && nextProps.shareFromData.length === 0 && !this.state.calling) {
      this.props.getShareFromData();
      this.setState({ calling: true });
      //this.props.getUser();
    }
    if (nextProps.deleteFromPhase === true) {
      this.props.getShareFromData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.getShareToPhase === true && prevProps.getShareToPhase !== true) {
      if (this && this.mySwipeableViews) {
        this.mySwipeableViews.container.current.updateHeight();
      }
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleSwipe(direction) {
    const { location, history } = this.props;

    if (direction === 'left') {
      if (location.pathname === '/share_from') {
        history.push('share', null);
      }
    } else if (direction === 'right') {
      if (location.pathname === '/share') {
        history.push('share_from', null);
      }
    }
  }

  render() {
    const currentLocation = this.props.location.pathname;
    const { userDetails, intl } = this.props;
    const { id, tab } = this.state;
    return (
      <div>
        <div id="wrapper" style={{ overflow: 'hidden' }}>
          {userDetails.userType === 'User'
            ? (currentLocation === '/share' || currentLocation === '/share_from') && (
                <Fragment>
                  <MyTabs
                    tabsClassName="shareTabs"
                    value={tab}
                    onChange={(event, value) => this.setState({ tab: value })}
                    tabLabels={[intl.formatMessage({ id: 'shareTo' }), intl.formatMessage({ id: 'shareFrom' })]}
                  />
                  <MySwipeableViews
                    ref={node => {
                      if (node) this.mySwipeableViews = node;
                    }}
                    index={tab}
                    onChangeIndex={index => {
                      this.setState({
                        tab: index
                      });
                    }}
                  >
                    <div className="adviser-dashboard-slide-team">
                      <ShareToView {...this.props} />
                    </div>
                    <div className="adviser-dashboard-slide-team">
                      <ShareFromView {...this.props} />
                    </div>
                  </MySwipeableViews>
                </Fragment>
              )
            : currentLocation === '/share_from' && <ShareFromView {...this.props} />}

          {currentLocation === '/share_options' && <ShareOptionView {...this.props} />}
          {currentLocation === '/connect_with_adviser' && <ConnectWithAdviser {...this.props} />}
          {currentLocation === '/share_link_view' && <ShareLinkView {...this.props} />}
          {currentLocation === '/view_share_details' && <ViewShareDetails {...this.props} />}
          {id !== '' && currentLocation !== '/share_link_view' && <ShareLink {...this.props} />}
        </div>
      </div>
    );
  }
}

export default ShareView;
