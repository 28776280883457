import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input
} from '@material-ui/core';
import { getClientConnection, isInactiveEmail } from '../utils';
import React from 'react';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import { isEmailValid } from '../../../utils/email';
import { changeInactiveUserEmail } from '../../../store/client/duck';
import _ from 'lodash';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';

const INITIAL_STATE = { error: undefined, loading: false };

class ConsentDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateEmailPhase, clientDetails, consentDialog, toggleDialog, mergeDialog } = nextProps;

    if (consentDialog) {
      if (updateEmailPhase === true && this.props.updateEmailPhase !== true) {
        const newClient = _.merge(
          { ...consentDialog.client },
          { email: clientDetails.email, 'factsheetId.email': clientDetails.email }
        );
        toggleDialog('consent', { ...consentDialog, client: newClient, editingEmail: clientDetails.email });
        mergeDialog('connection', { client: newClient });
      }
    }
  }

  getConsent = async () => {
    const { intl, consentDialog, toggleDialog, changeInactiveUserEmail } = this.props;

    const client = consentDialog.client;
    const editingEmail = consentDialog.editingEmail;

    if (!client) return null;

    let error;
    if (!editingEmail) {
      error = 'Email is required';
    } else if (!isEmailValid(editingEmail)) {
      error = 'Invalid email';
    }

    this.setState({ error: error });
    if (!error) {
      if (isInactiveEmail(client) && editingEmail) {
        this.setState({ loading: true });
        const result = await changeInactiveUserEmail({ clientId: client._id, email: editingEmail });
        if (!result.success) {
          toast.info(IconToast('error', intl.formatMessage({ id: result.message })), { className: 'new-toast' });
          this.setState({ loading: false });
          return;
        }
      }

      toggleDialog('consentConfirm', consentDialog);
    }
  };

  close = () => this.props.toggleDialog('consent', { ...this.props.consentDialog, closed: true });
  closed = () => {
    this.props.toggleDialog('consent', false);
    this.setState(INITIAL_STATE);
  };

  changeEmail = event => {
    this.props.mergeDialog('consent', { editingEmail: event.target.value });
  };

  editEmail = () => {
    this.props.toggleDialog('changeEmail', { ...this.props.consentDialog });
  };

  render() {
    const { intl, consentDialog } = this.props;
    const { error, loading } = this.state;

    const { client, closed, editingEmail } = consentDialog;

    if (!client) return null;

    const { connectionRequestId } = client || {};

    // const inactiveEmail = isInactiveEmail(client);
    const connection = getClientConnection(client);
    const canChangeEmail = !connection && !client.consent && !connectionRequestId;
    const inactiveEmail = isInactiveEmail(client);

    return (
      <Dialog
        open={!!consentDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'get-consent' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'Email_sent' })}</DialogContentText>
          <div>
            <FormControl id="msform" className="share-to-modal-form-control">
              <Input
                className="share-to-modal-input"
                id="clientEmail"
                type="text"
                name="clientEmail"
                value={editingEmail}
                required={true}
                disabled={true}
                style={{ color: 'black' }}
                placeholder={intl.formatMessage({ id: 'Email-address-hints' })}
                onChange={this.changeEmail}
              />
              {error && <span className="error_field">{intl.formatMessage({ id: error })}</span>}

              {canChangeEmail && (
                <div>
                  <Button
                    className="action-change-button"
                    style={{ top: '-15px' }}
                    variant="contained"
                    color="primary"
                    onClick={this.editEmail}
                  >
                    {intl.formatMessage({ id: !inactiveEmail ? 'Change' : 'Add' })}
                  </Button>
                </div>
              )}
            </FormControl>
          </div>
          <div className="email-confirm-hint">
            {intl.formatMessage({ id: '(Please double check the Email address)' })}
          </div>
          <div>{intl.formatMessage({ id: 'get_consent' })}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.getConsent} color="primary" disabled={loading} autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    consentDialog: state.control.consentDialog,
    updateEmailPhase: state.client.updateEmailPhase,
    clientDetails: state.client.clientDetails
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    changeInactiveUserEmail
  },
  // mergeProps
  null,
  // options
  {}
)(ConsentDialog);

export default injectIntl(container);
