import _ from 'lodash';
import { MDRT_CALCULATOR_DEFAULT_STATES } from '../../constants/mdrt-calculator';

/***********************************
 * Action Types
 ***********/
export const TOGGLE_DIALOG = 'TOGGLE_DIALOG';
export const MERGE_DIALOG = 'MERGE_DIALOG';
export const TOGGLE_SWIPEABLE = 'TOGGLE_SWIPEABLE';
export const TOGGLE_CONTROL = 'TOGGLE_CONTROL';
export const MERGE_CONTROL = 'MERGE_CONTROL';

/***********************************
 * Initial State
 ***********/
let initialState = {
  // dialogs
  chooseLanguageDialog: false,
  connectionDialog: false,
  addPortfolioHintDialog: false,
  consentDialog: false,
  consentConfirmDialog: false,
  connectDialog: false,
  connectConfirmDialog: false,
  normalDialog: false,
  shareConfirmDialog: false,
  trackOnDialog: false,
  trackOffDialog: false,
  meetingDialog: false,
  tagsDialog: false,
  contactDialog: false,
  smartIdeaDialog: false,
  CPIHintDialog: false,
  DISCDialog: false,
  greetingDialog: false,
  changeEmailDialog: false,
  isDemoClientWarningDialog: false,
  deleteClientDialogDialog: false,
  quickNotesDialog: false,
  confirmDeleteConnectionDialog: false,

  standardContentDialog: false,
  moreInfoDialog: false,

  assistantHintDialog: false,
  assistantCustomizeDialog: false,
  assistantCategoryCustomize: false,

  discardWarningDialog: false,
  averagePremiumSettingDialog: false,

  financialChartsDialog: false,
  recruitmentDialog: false,
  myQRDialog: false,
  referralQRCodeDialog: false,

  // swipeable views
  bigDataSwipeable: true,
  analysisConfirmSaveDialog: false,

  // analysis
  analysisExportOptionsHintDialog: false,

  freeTrialLazyPackExcludedDialog: false,

  easyAddPolicy: JSON.parse(sessionStorage.getItem('easyAddPolicy')) || false,

  addToHomeSnackbar: false,

  myWhiteBoardDialog: false,
  iframeDialog: false,

  permissionDeniedDialog: false,
  nativeUpdatedDialog: false,
  expenseCalculatorDialog: false,

  appIconChangingDialog: false,

  mdrtActivityCalculator: MDRT_CALCULATOR_DEFAULT_STATES,

  thirdPartyRedirectDialog: false,
  otaUpdateDialog: false,
  chatGPTDialog: false,
  chatGPTDisclaimerDialog: false,
  chatGPTInsufficientDataDialog: false,
  ChatGPTMatchLazyPacks: false,
  chatGPTRewriteMessageDrawer: false,
  chatGPTIceBreakingDrawer: false,
  chatGPTIGPostDrawer: false,
  chatGPTPersonalMessageDrawer: false,
  chatGPTFunDrawer: false,
  chatGPTFeedbackDialog: false,
  hotTopicItemDialog: false
};

/***********************************
 * Reducer
 ***********/
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_DIALOG:
      return { ...state, [`${action.dialog}Dialog`]: action.content };
    case MERGE_DIALOG:
      return { ...state, [`${action.dialog}Dialog`]: { ..._.merge(state[`${action.dialog}Dialog`], action.content) } };
    case TOGGLE_SWIPEABLE:
      return { ...state, [`${action.swipeable}Swipeable`]: action.content };
    case TOGGLE_CONTROL:
      if (action.key === 'easyAddPolicy') {
        sessionStorage.setItem('easyAddPolicy', action.content);
      }
      return { ...state, [action.key]: action.content };
    case MERGE_CONTROL:
      return { ...state, [action.key]: { ..._.merge(state[action.key], action.content) } };
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const toggleDialog = (dialog, content) => ({ type: TOGGLE_DIALOG, dialog: dialog, content: content });
export const mergeDialog = (dialog, content) => ({ type: MERGE_DIALOG, dialog: dialog, content: content });
export const toggleSwipeable = (swipeable, content) => ({
  type: TOGGLE_SWIPEABLE,
  swipeable: swipeable,
  content: content
});
export const toggleControl = (key, content) => ({ type: TOGGLE_CONTROL, key: key, content: content });
export const mergeControl = (key, content) => ({ type: MERGE_CONTROL, key: key, content: content });
