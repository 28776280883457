import { connect } from 'react-redux';
import {
  submitClient,
  getClient,
  checkClient,
  deleteClient,
  getClientDetails,
  getConsent,
  submitConsent,
  initPhase,
  updateFactSheet,
  changeInactiveUserEmail,
  enableTracking,
  updateClientLanguage,
  initChangeEmailPhase,
  initCreatePhase,
  initTagPhase,
  initSmartIdeaData
} from '../../../store/client/duck';
import {
  fetchFigureAdviser,
  updateFigureAdviser,
  updateAdviserBusiness,
  fetchAdviserBusiness
} from '../../../store/adviserDashboard/duck';
import { handleSignOut, approveUser, initPhaseEmail, getUser } from '../../../store/user/duck';
import ClientView from './component';
import { selectedLocale } from '../../../store/locale/action';
import { injectIntl } from 'react-intl';
import { initPremiumViewPhase, movePolicy } from '../../../store/policy/duck';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';
import { runGuideline, pauseGuideline, resumeGuideline, endGuideline } from '../../../store/guideline/duck';
import { toggleDialog } from '../../../store/control/duck';
import { clearAllMsgOpt } from '../../../store/notificationCenter/duck';
import { updateAdviserCustomFilters } from '../../../store/adviserCustomFilters/duck';
import { addAdviserTodo } from '../../../store/todoList/duck';

import _ from 'lodash';

const Client = connect(
  // Map state to props
  state => ({
    tagData: state.client.tagData,
    userDetails: state.user.userDetails,
    createClientPhase: state.client.createClientPhase,
    clientData: state.client.clientData,
    clientMessage: state.client.clientMessage,
    createClientMessageExtras: state.client.createClientMessageExtras,
    updateEmailMessageExtras: state.client.updateEmailMessageExtras,
    updateFactSheetPhase: state.client.updateFactSheetPhase,
    updateFactsheetMessage: state.client.updateFactsheetMessage,
    checkClientPhase: state.client.checkClientPhase,
    deleteClientPhase: state.client.deleteClientPhase,
    clientDetails: state.client.clientDetails,
    clientPersonalDetails: state.client.clientPersonalDetails,
    fetchDetailsPhase: state.client.fetchDetailsPhase,
    getConsentPhase: state.client.getConsentPhase,
    fetchClientPhase: state.client.fetchClientPhase,
    GetUsersPolicyDueData: state.adviserDashboard.GetUsersPolicyDueData,
    GetUsersWithoutPolicyData: state.adviserDashboard.GetUsersWithoutPolicyData,
    newReferralCount: state.referral.newReferralCount,
    message: state.client.message,
    updateEmailPhase: state.client.updateEmailPhase,
    GetConnectedUsersBirthday: state.adviserDashboard.GetConnectedUsersBirthday,
    enableTrackingPhase: state.client.enableTrackingPhase,
    approvePhase: state.user.approvePhase,
    figure: state.user.figure,
    updateLanguagePhase: state.client.updateLanguagePhase,
    PastBirthdayOfUsers: state.adviserDashboard.PastBirthdayOfUsers,
    policyDueData: state.adviserDashboard.policyDueData,
    clientMatch: state.client.clientMatch,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    tagPhase: state.client.tagPhase,
    coveragePhase: state.client.coveragePhase,
    clientFactsheetPhase: state.client.clientFactsheetPhase,
    guidelineIsStarted: state.guideline.start,
    guidelineIsRunning: state.guideline.run,
    guidelineSteps: state.guideline.steps,
    guidelineStepIndex: state.guideline.stepIndex,
    subscriptions: state.userSubscription.subscriptions,
    hasNotifications: state.notificationCenter.hasNotifications,
    newNotificationNumber: state.notificationCenter.newNotificationNumber,
    clearAllThenFetchClient: state.notificationCenter.clearAllThenFetchClient,

    policiesData: state.adviserDashboard.policiesData,
    getFigureAdviserPhase: state.adviserDashboard.getFigureAdviserPhase,
    figureAdviserData: state.adviserDashboard.figureAdviserData,
    updateFigureAdviserPhase: state.adviserDashboard.updateFigureAdviserPhase,
    clientTagUsage: state.adviserDashboard.clientTagUsage,
    getUsersGender: state.adviserDashboard.getUsersGender,
    getClientStatusData: state.adviserDashboard.getClientStatusData,
    GetConnectedUsersDiscCount: state.adviserDashboard.GetConnectedUsersDiscCount,
    getActiveReminder: state.adviserDashboard.getActiveReminder,
    paidUpPolicyClients: _.get(state, 'adviserDashboard.paidUpPolicyClients', []),
    clientsToSendNum: state.notificationCenter.clientsToSendNum,
    lazyChange: state.client.lazyChange,
    adviserCustomFilters: _.get(state, 'user.adviserCustomFilters', null),
    viewingClient: state.client.viewingClient,
    advisorCustomFiltersState: state.adviserCustomFilters,
    showPadding: state.app.get('showPadding'),
    guidelineStepsIdentifier: state.guideline.stepsIdentifier,

    // Move policy
    movePolicyStatus: state.policy.movePolicyStatus,
    referrerNames: state.client.referrerNames,
    isAddingNewClient: state.client.isAddingNewClient
  }),
  // Map actions to props
  {
    submitClient,
    checkClient,
    getClient,
    deleteClient,
    getClientDetails,
    getConsent,
    submitConsent,
    initPhase,
    handleSignOut,
    updateFactSheet,
    changeInactiveUserEmail,
    enableTracking,
    approveUser,
    initPhaseEmail,
    getUser,
    updateClientLanguage,
    selectedLocale,
    initPremiumViewPhase,
    fetchFigureAdviser,
    updateFigureAdviser,
    updateAdviserBusiness,
    fetchAdviserBusiness,
    initChangeEmailPhase,
    initCreatePhase,
    initTagPhase,
    initSmartIdeaData,
    runGuideline,
    pauseGuideline,
    resumeGuideline,
    endGuideline,
    toggleDialog,
    clearAllMsgOpt,
    renderHeaderButton,
    updateAdviserCustomFilters,
    addAdviserTodo,
    movePolicy
  }
)(ClientView);

export default injectIntl(Client);
