import React from 'react';
import _ from 'lodash';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatMessage } from '../../../utils/locale';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import {
  getPolicyCoverageForPolicyPicker,
  getPolicyMarketValue,
  getPolicyMonthlyPremiumForPolicyPicker,
  getSubCategoryColor,
  isPaidUpPolicy,
  policySorter
} from '../../../utils/policy';
import { PolicyReviewShavedText } from '../../../views/PolicyReview/PolicyReview';
import { injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel, useMediaQuery } from '@material-ui/core';
import { isPolicyPicked } from '../PolicyPicker';
import { formatNumber } from '../../../utils/formatNumber';

const PolicyPickerTable = props => {
  const { intl, policies, picked, currency, createTogglePickedHandler } = props;
  const shouldShowMore = useMediaQuery(theme => theme.breakpoints.up('md'));

  return policies ? (
    <TableContainer className="policy-category-table info">
      <Table size="small" style={{ width: '100%' }}>
        <TableHead>
          <TableRow className="main-row">
            <TableCell align="center" style={{ width: 100 }}>
              <div />
            </TableCell>

            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'pf-plan-name' })}</Typography>
              </div>
            </TableCell>

            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'total-coverage' })}</Typography>
              </div>
            </TableCell>

            {shouldShowMore && (
              <TableCell align="center" style={{ width: 100 }}>
                <div>
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'Market Value' })}</Typography>
                </div>
              </TableCell>
            )}

            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Monthly Premium' })}</Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.sort(policySorter).map((policy, policyIndex) => {
            const subCategory = policy.subCategory;
            const subCategoryTitle = _.get(subCategory, 'title');
            const color = getSubCategoryColor(subCategory);
            // const isOtherInsurer = isPolicyInsurer(policy, 'other');
            // const policyInsurer = _.get(policy, 'insurer');
            const onTogglePicked = createTogglePickedHandler(policy);

            const coverage = getPolicyCoverageForPolicyPicker(policy, currency);
            const monthlyPremium = getPolicyMonthlyPremiumForPolicyPicker(policy, currency);
            const marketValue = getPolicyMarketValue(policy, currency);
            const isPaidUp = isPaidUpPolicy(policy);

            return (
              <TableRow key={policy._id}>
                <TableCell
                  align="center"
                  style={{
                    borderRight: `3px solid ${color}`,
                    verticalAlign: 'top',
                    paddingTop: 6
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" onChange={onTogglePicked} checked={isPolicyPicked(policy, picked)} />
                    }
                    label={formatMessage(intl, subCategoryTitle)}
                    style={{ color: color, margin: 0 }}
                  />
                </TableCell>
                <TableCell align="center" className="collapse">
                  <div>
                    <PolicyReviewShavedText>
                      {policy.policyName
                        ? policy.policyName
                        : policy.companyName ? `${intl.formatMessage({ id: 'Company' })}: ${policy.companyName}` : ""
                      }
                    </PolicyReviewShavedText>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    <PolicyReviewShavedText>{formatNumber(coverage, intl)}</PolicyReviewShavedText>
                  </div>
                </TableCell>
                {shouldShowMore && (
                  <TableCell align="center" className="collapse">
                    <div>
                      <PolicyReviewShavedText>{formatNumber(marketValue, intl)}</PolicyReviewShavedText>
                    </div>
                  </TableCell>
                )}
                <TableCell align="center" className="collapse">
                  <div style={isPaidUp ? { textDecoration: 'line-through' } : undefined}>
                    <PolicyReviewShavedText>{formatNumber(monthlyPremium, intl)}</PolicyReviewShavedText>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyPickerTable);
