import React, { Fragment } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import './Pricing.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import PricingCard from './PricingCard/PricingCard';
import { getDisplayInterval } from '../../../utils/user-subscription';
import { TEAM_QUARTER_SUBSCRIPTION_PLAN, TEAM_YEAR_SUBSCRIPTION_PLAN } from '../../../constants/subscription-plan';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getSubscriptionPlans } from '../../../store/subscriptionPlan/duck';
import { getUserSubscription } from '../../../store/userSubscription/duck';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import { ga4TrackSupport } from '../../../utils/tracking';
// const getSpaceBetween = swiperWidth => 30 * (swiperWidth / 375);
// const getSlidesPerView = swiperWidth => swiperWidth / (230 + 30);

class Pricing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroupIndex: 0,
      selectedPlanId: undefined,
      swipeIndex: 2,
      swiperWidth: this.getWrapperWidth(),
      loaded: false
    };
    this.unmount = false;
    this.wrapper = React.createRef();
  }

  // need to consider -32 margin
  getWrapperWidth = () => _.get(this.wrapper, 'current.offsetWidth', 360) - 32;

  updateSwiper() {
    let targetElem = document.querySelector('.pricing-wrapper');
    if (targetElem) {
      this.setState({ swiperWidth: this.getWrapperWidth() }, () => {
        if (this.swiper && this.swiper.params) {
          // this.swiper.params.slidesPerView = getSlidesPerView(this.state.swiperWidth);
          // this.swiper.params.spaceBetween = getSpaceBetween(this.state.swiperWidth);
          // this.swiper.update();
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let targetElem = document.querySelector('.pricing-wrapper');
    if (targetElem) {
      if (window.innerWidth !== this.state.swiperWidth) {
        this.updateSwiper();
      }
    }

    if (this.props.planMode !== prevProps.planMode || this.props.couponId !== prevProps.couponId) {
      this.loadPlans();
    }
  }


  async componentDidMount() {
    window.addEventListener('resize', this.updateSwiper.bind(this));

    if (this && !this.unmount) {
      this.loadPlans();
    }
  }

  componentWillUnmount() {
    this.unmount = true;
    window.removeEventListener('resize', this.updateSwiper.bind(this));
  }

  selectPlan(plan, event) {
    const { onSelectPlan } = this.props;
    const { selectedPlanId } = this.state;

    event.stopPropagation();

    this.setState({ selectedPlanId: selectedPlanId === plan._id ? undefined : plan._id });

    if (onSelectPlan) onSelectPlan(selectedPlanId === plan._id ? undefined : plan);
  }

  async loadPlans() {
    const { planMode, couponId, getSubscriptionPlans } = this.props;
    try {
      await getSubscriptionPlans({
        upgrade: false,
        couponId: couponId,
        planMode: planMode
      });
    } catch {

    } finally {
      const obj = { loaded: true };

      const width = this.getWrapperWidth();
      if (width) {
        obj.swiperWidth = width;
      }

      this.setState(obj);

      if (this && this.props.onLoad) {
        this.props.onLoad();
      }
    }
  }

  render() {
    const {
      intl,
      buttonText,
      subscriptionPlans,
      extraAction,
      withoutTeam,
      children
      // openExternalLinkDialog
    } = this.props;
    const { selectedGroupIndex, swiperWidth, loaded } = this.state;

    if (!subscriptionPlans) return null;

    let plans = [...subscriptionPlans];

    // append team plan
    if (!withoutTeam) {
      if (plans.find(plan => plan.interval === 'year')) plans.push(TEAM_YEAR_SUBSCRIPTION_PLAN);
      if (plans.find(plan => plan.interval === 'month' && plan.intervalCount === 3))
        plans.push(TEAM_QUARTER_SUBSCRIPTION_PLAN);
    }

    const groupedPlans =
      plans && plans.length > 0
        ? plans.reduce((accumulator, plan) => {
          let existingGroupIndex = accumulator.findIndex(
            group =>
              (group.interval === plan.interval && group.intervalCount === plan.intervalCount) ||
              (group.isFreeTrial && plan.isFreeTrial) ||
              (group.isTrial && plan.isTrial)
          );
          if (existingGroupIndex > -1) {
            accumulator[existingGroupIndex].plans.push(plan);
          } else {
            if (plan.isFreeTrial) {
              accumulator.push({ isFreeTrial: true, plans: [plan] });
            } else if (plan.isTrial) {
              accumulator.push({ isTrial: true, plans: [plan] });
            } else {
              accumulator.push({ interval: plan.interval, intervalCount: plan.intervalCount, plans: [plan] });
            }
          }
          return accumulator;
        }, [])
        : undefined;

    const cards = groupedPlans
      ? groupedPlans[selectedGroupIndex].plans.map((plan, index) => {
        return (
          <SwiperSlide key={index} style={{ width: 230 }}>
            {/*<div>*/}
            <PricingCard
              intl={intl}
              plan={plan}
              customAmount={
                !plan.team ? (
                  undefined
                ) : (
                  <div className="team-amount">{intl.formatMessage({ id: 'sub-customized' })}</div>
                )
              }
              buttonText={!plan.team ? buttonText : intl.formatMessage({ id: 'contact-us' })}
              onClick={
                !plan.team
                  ? this.selectPlan.bind(this, plan)
                  : () => {
                    ga4TrackSupport({ 'msg_channel': 'whatsapp', 'channel_targert': '85266370320', 'support_type': 'team_subscription' })
                    window.open(
                      `https://api.whatsapp.com/send?phone=85266370320&text=I want to know more about team subscription`,
                      '_blank'
                    )
                  }

              }
            />
            {/*</div>*/}
          </SwiperSlide>
        );
      })
      : [];

    const useSwiper = !(swiperWidth / cards.length >= 230 + 30);

    return (
      <div className="pricing-wrapper" ref={this.wrapper}>
        {!loaded ? (
          <div className="max-width-wrapper text-center">
            <CircularProgress />
          </div>
        ) : groupedPlans && groupedPlans.length > 0 ? (
          <Fragment>
            <Grid container justify="center">
              <Grid item>
                <img src="img/subscription/paymentBanner.png" alt="mdrt-cot-action" width="300" />
              </Grid>
            </Grid>
            {/*<Typography variant="h4" component="h4" align="center" paragraph={true}>*/}
            {/*  {intl.formatMessage({ id: 'choose-plan' })}*/}
            {/*</Typography>*/}

            {/*<Typography color="textSecondary" align="center">*/}
            {/*  {intl.formatMessage({ id: 'choose-plan-hint' })}*/}
            {/*</Typography>*/}

            <Grid
              className="button-options max-width-wrapper"
              container
              spacing={3}
              alignContent="center"
              justify="center"
              style={{ margin: 'auto', width: '100%' }}
            >
              {groupedPlans.map((group, index, array) => {
                return (
                  <Grid item key={index} style={{ textAlign: 'center' }}>
                    <Button
                      className={`${selectedGroupIndex === index ? 'selected' : ''}`}
                      variant="contained"
                      color="primary"
                      onClick={() => this.setState({ selectedGroupIndex: index }, () => this.updateSwiper())}
                    >
                      {getDisplayInterval(intl, group)}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>

            <br />

            {groupedPlans.map((group, index) => {
              if (index === selectedGroupIndex && group.plans.length > 0) {
                if (useSwiper) {
                  return (
                    <Swiper
                      key={index}
                      ref={node => {
                        if (node) this.swiper = node.swiper;
                      }}
                      updateOnWindowResize={true}
                      slidesPerView="auto"
                      spaceBetween={30}
                      pagination={{
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                      }}
                      navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                      }}
                      centeredSlides={true}
                      // centeredSlidesBounds={true}
                      loop={false}
                      grabCursor={true}
                      effect="coverflow"
                      coverflowEffect={{
                        rotate: 10,
                        stretch: 0,
                        depth: 50,
                        modifier: 1,
                        slideShadows: false
                      }}
                    >
                      {cards}
                    </Swiper>
                  );
                } else {
                  return (
                    <div className="no-swiper-wrapper" key={index}>
                      {cards}
                    </div>
                  );
                }
              } else {
                return null;
              }
            })}

            <div className="actions max-width-wrapper">
              {!!extraAction && extraAction}
              {/*<Button*/}
              {/*  className="type-a"*/}
              {/*  color="primary"*/}
              {/*  onClick={() => window.open('https://www.portfoplus.com/adviser#subscribe', '_blank')}*/}
              {/*>*/}
              {/*  <u>{intl.formatMessage({ id: 'check-more' })}</u>*/}
              {/*</Button>*/}
            </div>
          </Fragment>
        ) : !loaded ? (
          ''
        ) : (
          <div className="max-width-wrapper text-center">
            <div>No available subscription plan is found!</div>
            <div>Please whatsapp us at +852 66370320</div>
          </div>
        )}

        <span
          className="fab"
          onClick={() => {
            ga4TrackSupport({ 'msg_channel': 'whatsapp', 'channel_targert': '85266370320', 'support_type': 'subscription_plan' })
            window.open(
              'https://api.whatsapp.com/send?phone=85266370320&text=(APP-PC)%20%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%88%91%E6%83%B3%E6%9F%A5%E8%A9%A2',
              '_blank'
            )
          }
          }
        >
          <img src="img/whatsapp.png" alt="whatsapp" />
        </span>

        {children}
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    subscriptionPlans: state.subscriptionPlan.subscriptionPlans
  }),
  // Map actions to props
  {
    getSubscriptionPlans,
    getUserSubscription
  },
  // mergeProps
  null,
  // options
  {}
)(Pricing);

export default injectIntl(container);
