import React, { Fragment, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { DEFAULT_MAX_FILE_NUMBER, DEFAULT_MAX_FILE_SIZE } from '../constants';
import FileItem from '../FileItem/FileItem';
import BottomSheetOrDialog from '../../BottomSheetOrDialog/BottomSheetOrDialog';

const FileUploadDialog = props => {
  const {
    intl,
    open,
    onClose,
    files,
    onFilesSubmitted,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    maxFileNumber = DEFAULT_MAX_FILE_NUMBER,
    isLinkUploadEnabled = false,
    isDescriptionInputEnabled = false
  } = props;

  // If description input is enabled, the max file number must be 1 due to UI
  const finalMaxFileNumber = isDescriptionInputEnabled ? 1 : maxFileNumber;

  const [fileDescription, setFileDescription] = useState('');
  const [fileLink, setFileLink] = useState('');
  const [fileLinkDescription, setFileLinkDescription] = useState('');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [error, setError] = useState(null);

  const { getRootProps, getInputProps, rejectedFiles } = useDropzone({
    maxSize: maxFileSize,
    onDropAccepted: acceptedFiles => {
      const newFiles = [...files, ...filesToUpload, ...acceptedFiles];
      if (newFiles.length > finalMaxFileNumber) {
        setError(intl.formatMessage(
          { id: 'files-upload-button-max-file-number-error' },
          { fileNumber: finalMaxFileNumber}
          ));
      } else {
        setFilesToUpload([...filesToUpload, ...acceptedFiles]);
      }
    }
  });

  const removeFileFromFileToUpload = fileIndex => {
    setFilesToUpload([...filesToUpload.slice(0, fileIndex), ...filesToUpload.slice(fileIndex + 1)]);
  };

  const handleFileDescriptionChange = event => {
    setFileDescription(event.target.value);
  };

  const handleFileLinkChange = event => {
    setFileLink(event.target.value);
  };

  const handleFileLinkDescriptionChange = event => {
    setFileLinkDescription(event.target.value);
  };

  const handleSubmit = async () => {
    const files = [...filesToUpload];

    if (isDescriptionInputEnabled && fileDescription && files.length > 0) {
      files[0] = new File([files[0]], fileDescription, { type: files[0].type });
    }

    if (fileLink) {
      const validateUrl = url => {
        const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
        return urlRegex.test(url);
      };
      if (!validateUrl(fileLink)) {
        setError(intl.formatMessage({ id: 'files-upload-button-file-link-error' }));
        return;
      }
      files.push({
        name: fileLinkDescription,
        size: 0,
        url: fileLink
      });
    }

    if (onFilesSubmitted) {
      onFilesSubmitted(files);
    }
    setFilesToUpload([]);
    onClose();
  };

  useEffect(() => {
    setError(null);
  }, [open, filesToUpload]);

  useEffect(() => {
    if (rejectedFiles.length > 0) {
      setError(intl.formatMessage({ id: 'files-upload-button-max-file-size-error' }, { fileSize: maxFileSize / 1024 / 1024 }));
    }
  }, [rejectedFiles, intl]);

  return (
    <BottomSheetOrDialog
      className="files-upload-dialog"
      open={open}
      onClose={onClose}
      header={intl.formatMessage({ id: 'upload-files-dialog-title' })}
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <section>
              <div {...getRootProps()}>
                <Typography paragraph={true}>
                  {intl.formatMessage(
                    { id: 'upload-files-dialog-hint-1' },
                    {
                      fileNumber: finalMaxFileNumber,
                      fileSize: Math.round((maxFileSize / 1024 / 1024) * 100) / 100
                    }
                  )}
                </Typography>
                <input {...getInputProps()} />
                <div className="wrapper">
                  <CloudUploadIcon />
                  <Typography color="textSecondary">
                    {intl.formatMessage({ id: 'upload-files-dialog-hint-2' })}
                  </Typography>
                  <Typography color="primary" style={{ textDecoration: 'underline' }}>
                    {intl.formatMessage({ id: 'clicking-here' })}
                  </Typography>
                </div>
              </div>
              <aside>
                {filesToUpload.map((file, index) => (
                  <FileItem key={index} file={file} onRemoved={() => removeFileFromFileToUpload(index)} />
                ))}

                {!!error && <Typography color="error">{error}</Typography>}
              </aside>
            </section>
          </Grid>
          <Grid item>
            <TextField
              className="upload-link-input"
              variant="outlined"
              value={fileDescription}
              onChange={handleFileDescriptionChange}
              placeholder={intl.formatMessage({ id: 'upload-files-link-description-placeholder' })}
            />
          </Grid>
          {isLinkUploadEnabled && (
            <Fragment>
              <Grid item>
                <Typography align="center">- - - - - - - OR - - - - - - -</Typography>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography>{intl.formatMessage({ id: 'upload-files-link-title' })}</Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      className="upload-link-input"
                      variant="outlined"
                      value={fileLink}
                      onChange={handleFileLinkChange}
                      placeholder="https://"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className="upload-link-input"
                      variant="outlined"
                      value={fileLinkDescription}
                      onChange={handleFileLinkDescriptionChange}
                      placeholder={intl.formatMessage({ id: 'upload-files-link-description-placeholder' })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Grid>
      }
      actions={[
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!fileLink && (!filesToUpload || filesToUpload.length === 0)}
        >
          {intl.formatMessage({ id: 'Submit' })}
        </Button>
      ]}
      BottomSheetProps={{
        expandOnContentDrag: false,
        disableAutoDismiss: true
      }}
    />
  );
};

export default injectIntl(FileUploadDialog);
