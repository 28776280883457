import React, { Fragment, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, IconButton, Portal, Slide, Typography, useMediaQuery } from '@material-ui/core';
import PolicyReviewHeading from '../../PolicyReview/PolicyReviewHeading/PolicyReviewHeading';
import PyramidSection from './PyramidSection/PyramidSection';
import InputTableGrid from './InputTableGrid/InputTableGrid';
import { PYRAMID_COLORS } from './constants';
import { formatPyramidData, getHandwritingImage } from './utils';
import InfoSection from './InfoSection/InfoSection';
import PoweredBy from '../../PolicyReview/PoweredBy/PoweredBy';
import { getWebImageUrl } from '../../../utils/image';
import RemarkSection from './RemarkSection/RemarkSection';
import { tracking } from '../../../utils/tracking';
import MyWhiteboardContainer from '../../../components/MyWhiteboard/MyWhiteboardContainer/MyWhiteboardContainer';
import ScrollBottomButton from '../../../components/ScrollBottomButton/ScrollBottomButton';
import ScrollTopButton from '../../../components/ScrollTopButton/ScrollTopButton';
import AdviserInfo from '../../Export/AdviserInfo/AdviserInfo';
import _ from 'lodash';
import './FinancialPyramid.scss';
import ExpenseCalculator from '../../../components/ExpenseCalculatorDialog/ExpenseCalculator/ExpenseCalculator';
import ColoredLeftBorderCard from './ColoredLeftBorderCard/ColoredLeftBorderCard';
import ExpenseCalculatorCard from './ExpenseCalculatorCard/ExpenseCalculatorCard';
import { getPolicyCoverageForPolicyPicker, getPolicyMonthlyPremiumForPolicyPicker } from '../../../utils/policy';
import produce from 'immer';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import { getUserLogo } from '../../../utils/user';
import { ADVISER_LOGO_FUNCTIONS } from '../../../constants/user';

const ContentWrapperStyle = {
  overflow: 'auto hidden',
};

const ContainerStyle = {
  padding: 0,
  width: '100%',
  margin: 'auto',
};

const FinancialPyramid = (props) => {
  const {
    intl,
    adviser,
    currency,
    clientName,
    clientId,
    factsheetId,
    monthlyIncome,
    monthlyExpense,
    emergencyFund,
    financialPyramidEntry,
    template,
    handwritingMode,
    onChange,
    showIncome,
    showRecord,
    onChangeShowIncome,
    onChangeShowRecord,
    showIncomeAnimation,
    showRecordAnimation,
    onShowIncomeAnimationDone,
    onShowRecordAnimationDone,
    infoSectionRef,
    expenseUpdateDetailsRef,
    showExpenseCalculator,
    onToggleShowExpenseCalculator,
    showExpenseCalculatorAnimation,
    onShowExpenseCalculatorAnimationDone,
    expenses,
    onSubmitExpenses,
    toggleDialog,
    openPolicyPicker,
    isPrivateMode,
  } = props;

  const columnAdviserInfo = useMediaQuery('(max-width:420px)');

  const formattedCurrency = currency || 'HKD';
  const adviserTeamLogoUrl = _.get(adviser, 'teamLogoUrl');
  const userLogo = getUserLogo(adviser, adviserTeamLogoUrl, ADVISER_LOGO_FUNCTIONS.PDF);
  const showTeamLogo = !!userLogo;

  const { risk, investment, saving, protection } = financialPyramidEntry || {};

  const pyramidData = formatPyramidData(risk, investment, saving, protection, monthlyIncome);

  const onChangeRiskData = !template
    ? (data) => {
        tracking('Click on Risk Setting - Financial pyramid');
        onChange({ risk: [...data] });
      }
    : undefined;
  const onChangeInvestmentData = !template
    ? (data) => {
        tracking('Click on Investment setting - Financial pyramid');
        onChange({ investment: [...data] });
      }
    : undefined;
  const onChangeSavingData = !template
    ? (data) => {
        tracking('Click on Saving setting - Financial pyramid');
        onChange({ saving: [...data] });
      }
    : undefined;
  const onChangeProtectionData = !template
    ? (data) => {
        tracking('Click on Insurance setting - Financial pyramid');
        onChange({ protection: [...data] });
      }
    : undefined;

  let minWidth;

  if (!handwritingMode) {
    if (showIncome && showRecord) {
      minWidth = 1115; //suit for ipad pro 11 (1112px)
    } else if (showIncome && !showRecord) {
      minWidth = 920;
    } else if (!showIncome && showRecord) {
      minWidth = 1000;
    } else {
      minWidth = 949;
    }
  }

  return (
    <div className="financial-pyramid" style={ContentWrapperStyle}>
      <Grid
        container
        spacing={2}
        style={{
          ...ContainerStyle,
          minWidth: minWidth,
        }}
      >
        {template && !isPrivateMode && (
          <Grid
            className="header"
            container
            justify="flex-end"
            alignItems="center"
            style={
              template
                ? {
                    backgroundImage: `url("${getWebImageUrl('img/policy-review/header_v2-right-3.png')}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 145px',
                    backgroundPosition: 'bottom 0 right 0',
                    paddingBottom: 24,
                    position: 'relative',
                    minHeight: 145,
                  }
                : undefined
            }
          >
            <div
              style={
                template
                  ? {
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      top: 0,
                      backgroundImage: `url("${getWebImageUrl('img/policy-review/header_v2-left.png')}")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto 145px',
                      backgroundPosition: `bottom 0 left 0`,
                    }
                  : undefined
              }
            />

            {template && (
              <Grid item style={{ flexGrow: 1 }}>
                <div
                  style={{
                    width: 'calc(100% - 575px)',
                    minWidth: showTeamLogo ? 260 : 195,
                    float: 'right',
                    textAlign: 'center',
                  }}
                >
                  {showTeamLogo ? (
                    <img
                      src={userLogo}
                      alt="logo"
                      style={{
                        maxHeight: 150,
                        maxWidth: 260,
                        width: 'auto',
                        objectFit: 'contain',
                      }}
                    />
                  ) : (
                    <img src={getWebImageUrl('img/policy-review/PFPLogoSummary.png')} alt="portfoplus" width={195} />
                  )}
                </div>
              </Grid>
            )}

            <Grid item>
              <AdviserInfo
                show={true}
                showQRCode={true}
                adviser={adviser}
                referById={clientId}
                direction={columnAdviserInfo ? 'column' : 'row'}
                QRActionButton="create-one-for-free"
                qrCodeWidth={150}
                referLinkParams={{
                  source: 'pdf',
                  extraSearch: {
                    defaultMessage: intl.formatMessage({ id: 'fin-py-default-msg' }, { clientName: clientName }),
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <PolicyReviewHeading
            className="heading"
            spacing={1}
            name={clientName}
            // title={
            //   <Typography color="primary" style={{ fontSize: 28, fontWeight: 'bold' }}>
            //     {intl.formatMessage({ id: 'fin-pyramid' })}
            //   </Typography>
            // }
          />
        </Grid>
        <Grid item xs={12}>
          {handwritingMode ? (
            <Fragment>
              <MyWhiteboardContainer
                clientName={clientName}
                className="financial-pyramid-whiteboard-container"
                shouldDrawFrame={true}
              />
              <ScrollTopButton
                scrollElement={{ current: document.querySelector('html') }}
                style={{ top: 15, right: 25 }}
              />
              <ScrollBottomButton
                scrollElement={{ current: document.querySelector('html') }}
                style={{ bottom: 15, right: 25 }}
              />
            </Fragment>
          ) : (
            // <Grid container justify="center">
            //   <Grid item>
            //     <img
            //       src={getHandwritingImage(intl.locale)}
            //       alt="financial-pyramid-frame"
            //       style={{ width: '100%', maxWidth: 720 }}
            //     />
            //   </Grid>
            // </Grid>
            <Grid container wrar="nowrap">
              <Slide
                direction="right"
                in={showIncomeAnimation === undefined ? showIncome : showIncomeAnimation}
                unmountOnExit
                onEnter={onShowIncomeAnimationDone}
                onExited={onShowIncomeAnimationDone}
                timeout={1000}
              >
                <Grid item xs={2 + (!showRecord ? 1 : 0)}>
                  <InfoSection
                    ref={infoSectionRef}
                    expenseUpdateDetailsRef={expenseUpdateDetailsRef}
                    clientId={clientId}
                    factsheetId={factsheetId}
                    currency={formattedCurrency}
                    monthlyIncome={monthlyIncome}
                    monthlyExpense={monthlyExpense}
                    emergencyFund={emergencyFund}
                    pyramidData={pyramidData}
                    onChange={onChange}
                    template={template}
                    showExpenseCalculator={showExpenseCalculator}
                    onToggleShowExpenseCalculator={onToggleShowExpenseCalculator}
                    expenses={expenses}
                  />
                </Grid>
              </Slide>

              <Grid item xs={!showIncome && !showRecord ? 12 : 6 + (!showIncome ? 1 : 0) + (!showRecord ? 3 : 0)}>
                <PyramidSection
                  currency={formattedCurrency}
                  monthlyIncome={monthlyIncome}
                  pyramidData={pyramidData}
                  template={template}
                  showIncome={showIncome}
                  showRecord={showRecord}
                  onChangeShowIncome={onChangeShowIncome}
                  onChangeShowRecord={onChangeShowRecord}
                />
              </Grid>

              <Slide
                direction="left"
                in={showRecordAnimation === undefined ? showRecord : showRecordAnimation}
                unmountOnExit
                onEnter={onShowRecordAnimationDone}
                onExited={onShowRecordAnimationDone}
                timeout={1000}
              >
                <Grid item xs={4 + (!showIncome ? 1 : 0)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputTableGrid
                        imgSrc={getWebImageUrl('img/financial-pyramid/dice_c.png')}
                        type="risk"
                        color={PYRAMID_COLORS[0]}
                        currency={formattedCurrency}
                        data={risk}
                        onChange={onChangeRiskData}
                        openPolicyPicker={openPolicyPicker}
                        disabled={!clientId || !factsheetId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputTableGrid
                        imgSrc={getWebImageUrl('img/financial-pyramid/investment_c.png')}
                        type="investment"
                        color={PYRAMID_COLORS[1]}
                        currency={formattedCurrency}
                        data={investment}
                        onChange={onChangeInvestmentData}
                        openPolicyPicker={openPolicyPicker}
                        disabled={!clientId || !factsheetId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputTableGrid
                        imgSrc={getWebImageUrl('img/financial-pyramid/saving_c.png')}
                        type="saving"
                        color={PYRAMID_COLORS[2]}
                        currency={formattedCurrency}
                        data={saving}
                        onChange={onChangeSavingData}
                        openPolicyPicker={openPolicyPicker}
                        disabled={!clientId || !factsheetId}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 38 }}>
                      <InputTableGrid
                        imgSrc={getWebImageUrl('img/financial-pyramid/protection_c.png')}
                        type="protection"
                        color={PYRAMID_COLORS[3]}
                        currency={formattedCurrency}
                        data={protection}
                        onChange={onChangeProtectionData}
                        cardBackground="#f0f9f9"
                        openPolicyPicker={openPolicyPicker}
                        disabled={!clientId || !factsheetId}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Slide>
            </Grid>
          )}
        </Grid>
        {!template && (
          <Grid item style={{ minWidth: showExpenseCalculator ? 1500 : 'unset', width: '100%' }}>
            <Grid container spacing={1}>
              <Grid item>
                <IconButton
                  color="primary"
                  onClick={onToggleShowExpenseCalculator}
                  style={{
                    transition: 'transform 0.8s',
                    transform: `rotate(${showExpenseCalculator ? 180 : 0}deg)`,
                    padding: 0,
                    boxShadow:
                      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <ArrowDropDownRoundedIcon
                    style={{
                      background: '#3966f8',
                      color: '#fff',
                      borderRadius: '50%',
                      fontSize: 32,
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography color="primary" style={{ fontSize: '150%', color: 'var(--new-theme)' }}>
                  {intl.formatMessage({ id: 'expense-dialog-title' })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Slide
          direction="up"
          in={showExpenseCalculatorAnimation === undefined ? showExpenseCalculator : showExpenseCalculatorAnimation}
          unmountOnExit
          onEnter={onShowExpenseCalculatorAnimationDone}
          onExited={onShowExpenseCalculatorAnimationDone}
          timeout={1000}
        >
          <Grid item xs={showExpenseCalculator ? 12 : false} style={{ minWidth: 1500 }}>
            <ExpenseCalculatorCard
              expenses={expenses}
              onSubmitExpenses={onSubmitExpenses}
              toggleDialog={toggleDialog}
              template={template}
              disabled={!clientId || !factsheetId}
            />
          </Grid>
        </Slide>
        <Grid item xs={12}>
          <Grid container justify={handwritingMode ? 'flex-end' : 'space-between'}>
            {!handwritingMode && (
              <Grid item>
                <RemarkSection />
              </Grid>
            )}
            <Grid item>
              <PoweredBy />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(FinancialPyramid);
