import { fetch } from '../../utils';
import Backend from '../../backend';

export const getAffiliate = async () => {
  return fetch(Backend.methodUrl('affiliates'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
