import ShareToClientMessageDialog from '../ShareToClientMessageDialog/ShareToClientMessageDialog';
import React, { Fragment } from 'react';
import { Typography, Grid, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { translateMessage } from '../../../../../utils/locale';
import moment from 'moment-timezone';
import { RELATED_SITES } from '../COVIDHintDialog/COVIDHintDialog';

const newVersion = !!process.env.COVID_URL;

class COVIDShareToClientDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fourteenDays: true,
      homeQuarantine: true
    };
  }

  render() {
    const { intl, dialog, onClose, toolMode } = this.props;
    const { fourteenDays, homeQuarantine } = this.state;

    if (!dialog) return null;

    let homeLocation = _.get(dialog, 'client.factsheetId.homeLocation');
    let workLocation = _.get(dialog, 'client.factsheetId.workLocation');

    if (homeLocation && homeLocation.latitude) {
      homeLocation = `Lat: ${homeLocation.latitude}, Long: ${homeLocation.longitude}`;
    }

    if (workLocation && workLocation.latitude) {
      workLocation = `Lat: ${workLocation.latitude}, Long: ${workLocation.longitude}`;
    }

    return (
      <ShareToClientMessageDialog
        open={!!dialog}
        onClose={onClose}
        title={intl.formatMessage({ id: 'covid-send-dialog-title' })}
        content={
          <Fragment>
            {!toolMode ? (
              <Fragment>
                <Typography>{intl.formatMessage({ id: 'covid-send-dialog-content' })}</Typography>
                <Typography>
                  {intl.formatMessage({ id: 'Home' })}:<b>{homeLocation}</b>
                </Typography>
                <Typography>
                  {intl.formatMessage({ id: 'Work' })}:<b>{workLocation}</b>
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography>
                  <b>{workLocation}</b>
                </Typography>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'covid-qc-check-result' })}</Typography>
              </Fragment>
            )}
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fourteenDays}
                      onChange={() => this.setState({ fourteenDays: !fourteenDays })}
                      name="fourteenDays"
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({ id: 'covid-14days' })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={homeQuarantine}
                      onChange={() => this.setState({ homeQuarantine: !homeQuarantine })}
                      name="homeQuarantine"
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({ id: 'covid-quarantine' })}
                />
              </Grid>
            </Grid>
          </Fragment>
        }
        getMessage={language => {
          const message = translateMessage('covid-send-message', language, {
            name: _.get(dialog, 'client.factsheetId.name', '')
          });

          let caseBuildingList = fourteenDays ? _.get(dialog, 'client.caseBuildingList', []) : [];
          let homeConfineeBuildingList = homeQuarantine ? _.get(dialog, 'client.homeConfineeBuildingList', []) : [];

          caseBuildingList = caseBuildingList
            .map(
              buildingEntry =>
                `\n${translateMessage('covid-send-entry-a', language, {
                  buildingName:
                    language === 'en'
                      ? `${buildingEntry.districtEng} ${
                          buildingEntry.buildingNameEng ? buildingEntry.buildingNameEng : buildingEntry.buildingNameCht
                        }`
                      : `${buildingEntry.districtCht} ${
                          buildingEntry.buildingNameCht ? buildingEntry.buildingNameCht : buildingEntry.buildingNameEng
                        }`
                })}${(buildingEntry.lastDateOfResidenceOfCases
                  ? translateMessage('covid-send-entry-b-1-1', language, {
                      lastDateOfResidenceOfCases: newVersion
                        ? moment(buildingEntry.lastDateOfResidenceOfCases).format('DD/MM/YYYY')
                        : buildingEntry.lastDateOfResidenceOfCases
                    })
                  : '') +
                  translateMessage('covid-send-entry-b-1-2', language, {
                    relatedProbableOrConfirmedCases: buildingEntry.relatedProbableOrConfirmedCases
                  })}`
            )
            .join('');

          homeConfineeBuildingList = homeConfineeBuildingList
            .map(
              buildingEntry =>
                `\n${translateMessage('covid-send-entry-a', language, {
                  buildingName:
                    language === 'en'
                      ? `${buildingEntry.districtEng} ${
                          buildingEntry.buildingNameEng ? buildingEntry.buildingNameEng : buildingEntry.buildingNameCht
                        }`
                      : `${buildingEntry.districtCht} ${
                          buildingEntry.buildingNameCht ? buildingEntry.buildingNameCht : buildingEntry.buildingNameEng
                        }`
                })}${translateMessage('covid-send-entry-b-2', language, {
                  endDateOfHomeQuarantineOrder: newVersion
                    ? moment(buildingEntry.endDateOfHomeQuarantineOrder).format('DD/MM/YYYY')
                    : buildingEntry.endDateOfHomeQuarantineOrder
                })}`
            )
            .join('');

          return (
            message +
            (caseBuildingList.length > 0
              ? '\n\n' + translateMessage('covid-14days-list', language) + caseBuildingList
              : '') +
            (homeConfineeBuildingList.length > 0
              ? (fourteenDays ? '\n' : '\n\n') +
                translateMessage('covid-quarantine-list', language) +
                homeConfineeBuildingList
              : '') +
            '\n' +
            translateMessage('covid-ask-me', language)
          );
        }}
        phone={_.get(dialog, 'client.factsheetId.phoneNumber')}
        email={
          _.get(dialog, 'client.email', '').indexOf(_.get(dialog, 'client._id')) > -1
            ? ''
            : _.get(dialog, 'client.email', '')
        }
        formatWhatsappSubject={language => {
          return translateMessage('covid-send-message', language, {
            name: _.get(dialog, 'client.factsheetId.name', '')
          }).replace(/\n/g, ' ');
        }}
        extraButton={
          <Button
            color="primary"
            onClick={() => window.open(RELATED_SITES[3].url, '_blank')}
            style={{ position: 'absolute', left: '16px' }}
          >
            {intl.formatMessage({ id: 'hk-dashboard' }).replace('👉🏻', '')}
          </Button>
        }
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(COVIDShareToClientDialog);

export default injectIntl(container);
