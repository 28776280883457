import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import MicIcon from '@material-ui/icons/Mic';
import { IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { handleError } from '../../utils/openai';
import { getMeetingNoteSpeechExtractionPrompt } from '../../utils/aiPrompt';
import { openaiGenerateWithTextPolling } from '../../store/openai/api';
import RecordDialog from '../RecordDialog/RecordDialog';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import { speechToText } from '../../store/speech/api';
import { LANGUAGE } from '../../constants/language';
import { injectIntl } from 'react-intl';
import NativeOrWeb from '../../utils/native';

const RecordMeetingRemarkButton = forwardRef((props, ref) => {
  const { intl, ButtonProps, IconProps, onResult } = props;

  const [initializing, setInitializing] = useState(false);
  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog: openDialog
  }));

  const openDialog = () => {
    setRecordingDialogOpen(true);
  };

  const startRecording = async () => {
    try {
      setRecording(true);
      setInitializing(true);
      await NativeOrWeb.startRecordingVoice();
    } catch (error) {
      console.log(error);
      toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
      setRecordingDialogOpen(false);
    } finally {
      setInitializing(false);
    }
  };

  const cancelRecording = () => {
    setRecordingDialogOpen(false);
    setRecording(false);
    if (recording) {
      NativeOrWeb.stopRecordingVoice()
        .then()
        .catch(console.log);
    }
  };

  const stopRecording = async () => {
    try {
      setRecordingDialogOpen(false);
      setLoading(true);

      const recordingData = await NativeOrWeb.stopRecordingVoice();
      const { mimeType, msDuration, recordDataBase64 } = recordingData?.value || {};
      const speechToTextResult = await speechToText({ mimeType, msDuration, recordDataBase64 });
      // console.log({ speechToTextResult });

      const { text: speechText, language: detectedLanguage } = speechToTextResult?.result || {};

      let speechLanguage;
      switch (detectedLanguage) {
        case 'en-US':
          speechLanguage = LANGUAGE.EN;
          break;
        case 'zh-HK':
          speechLanguage = LANGUAGE.ZH_HANT_HK;
          break;
        case 'zh-CN':
          speechLanguage = LANGUAGE.ZH;
          break;
        default:
          speechLanguage = LANGUAGE.EN;
          break;
      }

      const inputMessage = getMeetingNoteSpeechExtractionPrompt({
        speechText: speechText,
        language: speechLanguage
      });
      const addedChatMessages = [
        {
          content:
            'You are an experienced insurance agent in Hong Kong. Convert the meeting Record with your insurance client delimited by triple quotation to a meeting notes. Output should strictly follow the format specified. ',
          role: 'system'
        },
        { content: inputMessage, role: 'user' }
      ];

      const response = await openaiGenerateWithTextPolling(
        {
          model: 'gpt-4o',
          messages: addedChatMessages,
          temperature: 0
        },
        {
          pollTimeout: 7000
        }
      );

      const { result, error } = response || {};

      if (error) {
        throw new Error(error);
      }

      onResult(result);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setRecording(false);
    }
  };

  return (
    <Fragment>
      <RecordDialog
        open={recordingDialogOpen}
        initializing={initializing}
        recording={recording}
        onClose={cancelRecording}
        onStartRecording={startRecording}
        onDoneRecording={stopRecording}
      />
      <LoadingBackdrop open={loading} />
      <IconButton {...ButtonProps} onClick={openDialog}>
        <MicIcon {...IconProps} />
      </IconButton>
    </Fragment>
  );
});

export default injectIntl(RecordMeetingRemarkButton, { withRef: true });
