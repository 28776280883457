import { connect } from 'react-redux';
import { addMessage, fetchMessages, selectClient } from '../../../store/message/duck';
import { getClient } from '../../../store/client/duck';
import { getUser, handleSignOut } from '../../../store/user/duck';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';
import MessageView from './component';
import { updateAdviserBusiness, fetchAdviserBusiness } from '../../../store/adviserDashboard/duck';
import { injectIntl } from 'react-intl';

const Message = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    clientData: state.client.clientData,
    selectedClient: state.message.selectedClient,
    fetchMessagesPhase: state.message.fetchMessagesPhase,
    fetchMessageData: state.message.fetchMessageData,
    newReferralCount: state.referral.newReferralCount,
    adviserBusiness: state.adviserDashboard.adviserBusiness
  }),
  // Map actions to props
  {
    getClient,
    selectClient,
    addMessage,
    fetchMessages,
    getUser,
    handleSignOut,
    fetchAdviserBusiness,
    updateAdviserBusiness,
    renderHeaderButton
  }
)(MessageView);

export default injectIntl(Message);
