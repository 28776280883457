import React, { Fragment, useState, useEffect, useCallback, memo, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getUserMissions, toggleUserMissionPopover, updateViewingUserMissionId } from '../../store/userMission/duck';
import { INIT } from '../../constants/phase';
import { IconButton, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { getMissionProgress } from '../../utils/user-mission';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import SentimentDissatisfiedRoundedIcon from '@material-ui/icons/SentimentDissatisfiedRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import withRouter from 'react-router/withRouter';
import './UserMissions.scss';
import VideoDialog from '../VideoPlayer/VideoDialog';
import { tracking } from '../../utils/tracking';
import { TopNavigationPortal } from '../topNavigation';
import TempSelect from '../TempSelect/TempSelect';
import { shouldShowReferAdviserPage } from '../../utils/adviser-referral';

const TotalRewardEntryChipStyle = { background: '#fff', color: '#ffa62b', border: '1px solid #ffa62b' };
const TotalRewardEntry = props => {
  const { intl, type, onGoSubscription, ...rest } = props;

  switch (type) {
    case 'extend-current-free-trial':
      return (
        <Grid className="total-reward-entry" container alignItems="center" justify="space-between">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <div>{intl.formatMessage({ id: 'um-reward-extend-free-trial-title' })}</div>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={onGoSubscription}
                  style={TotalRewardEntryChipStyle}
                >
                  {intl.formatMessage({ id: 'um-reward-subscription-action' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MRewardChip intl={intl} type={type} {...rest} />
          </Grid>
        </Grid>
      );
    case 'extend-current-subscription':
      return (
        <Grid className="total-reward-entry" container alignItems="center" spacing={1}>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <div>{intl.formatMessage({ id: 'um-reward-extend-subscription-title' })}</div>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  onClick={onGoSubscription}
                  style={TotalRewardEntryChipStyle}
                >
                  {intl.formatMessage({ id: 'um-reward-subscription-action' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MRewardChip intl={intl} type={type} {...rest} />
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};
TotalRewardEntry.whyDidYouRender = true;
const MTotalRewardEntry = memo(TotalRewardEntry);

const RewardChip = props => {
  const { intl, type, ...rest } = props;
  switch (type) {
    case 'extend-current-free-trial':
      return (
        <Chip
          className="reward"
          size="small"
          label={intl.formatMessage({ id: 'um-reward-extend-free-trial' }, { type, ...rest })}
          {...rest}
        />
      );
    case 'extend-current-subscription':
      return (
        <Chip
          className="reward"
          size="small"
          label={intl.formatMessage({ id: 'um-reward-extend-free-trial' }, { type, ...rest })}
          {...rest}
        />
      );
    default:
      return null;
  }
};
RewardChip.whyDidYouRender = true;
const MRewardChip = memo(RewardChip);

const LinearProgressWithLabel = props => {
  return (
    <Box className="linear-label-progress" display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

const MissionEntry = props => {
  const { intl, history, viewingUserMission, mission, index, missions, showVideo, onClose } = props;

  const isMissionCompleted = _.get(viewingUserMission, `completedMissions.${mission.id}`, false);
  const progress = getMissionProgress(
    mission,
    viewingUserMission.completedMissions,
    _.get(viewingUserMission, 'missionListId.variables'),
    viewingUserMission.variables
  );
  const extras = _.get(mission, 'extras', []);

  return (
    <ListItem ContainerComponent="div" ContainerProps={{ className: 'mission' }} divider={index < missions.length - 1}>
      <ListItemText
        disableTypography={true}
        primary={
          <Grid
            container
            alignItems="center"
            style={{
              fontSize: '125%',
              lineHeight: 1.3,
              color: isMissionCompleted ? '#03CEA4' : undefined
            }}
          >
            <Grid item>
              <Typography>{_.get(mission, `title.${intl.locale}`, '')}</Typography>
            </Grid>
            {isMissionCompleted && (
              <Grid item style={{ lineHeight: 1, height: 22 }}>
                <CheckCircleRoundedIcon />
              </Grid>
            )}
            {extras.length > 0 && (
              <Grid item style={{ height: 22, marginLeft: 6 }}>
                <div style={{ position: 'relative', height: '100%', width: 26 * extras.length }}>
                  {_.get(mission, 'extras', []).map((extra, index) => {
                    const needPosAdjustment = extra.type === 'in-app-video' || extra.type === 'external-link' ? -12 : 0;
                    return (
                      <div
                        style={{
                          position: 'absolute',
                          left: 26 * index + needPosAdjustment,
                          top: needPosAdjustment,
                          width: '100%',
                          height: '100%'
                        }}
                        key={index}
                      >
                        {{
                          'in-app-video': (
                            <IconButton
                              onClick={event => {
                                // Tracking
                                tracking('User missions: Video play button (in app video) clicked');
                                showVideo(event, mission, extra.url);
                              }}
                            >
                              <img src="img/free-trial/play-button.svg" alt="video" width={22} height={22} />
                            </IconButton>
                          ),
                          'external-link': (
                            <IconButton
                              onClick={event => {
                                // Tracking
                                tracking('User missions: Video play button (external link) clicked');
                                window.open(extra.url, '_blank');
                              }}
                            >
                              <img src="img/free-trial/play-button.svg" alt="video" width={22} height={22} />
                            </IconButton>
                          ),
                          'refer-adviser-page': (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={event => {
                                // Tracking
                                tracking('User missions: Refer adviser button clicked');
                                if (onClose) onClose();
                                history.push('/refer_adviser');
                              }}
                              style={{
                                fontSize: '14px',
                                width: '100%',
                                height: '100%',
                                minWidth: 'unset',
                                lineHeight: 1,
                                padding: 0
                              }}
                            >
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          )
                        }[extra.type] || null}
                      </div>
                    );
                  })}
                </div>
              </Grid>
            )}
          </Grid>
        }
        secondary={
          <Fragment>
            <Typography color="textSecondary" style={{ fontSize: '70%' }}>
              {_.get(mission, `description.${intl.locale}`, '')}
            </Typography>
            <LinearProgressWithLabel value={progress} />
          </Fragment>
        }
      />
      <ListItemSecondaryAction>
        <Grid container direction="column" alignItems="center" justify="center">
          {_.get(mission, 'rewards', []).map((reward, index) => {
            return (
              <Grid key={index} item>
                <MRewardChip intl={intl} type={reward.type} quantity={reward.quantity} />
              </Grid>
            );
          })}
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const UserMissions = props => {
  const {
    intl,
    history,
    open,
    page,
    viewingUserMissionId,
    userMissions,
    userSubscription,
    getUserMissionsPhase,
    getUserMissions,
    buttonProps,
    toggleUserMissionPopover,
    updateViewingUserMissionId,
    teamShortName
  } = props;

  const videoDialogRef = useRef(null);
  const buttonRef = useRef(null);
  const shouldShowReferAdviserButton = shouldShowReferAdviserPage(userSubscription, teamShortName);
  const openPopover = event => {
    // Tracking
    tracking('User missions gift icon button clicked');
    getUserMissions();
    toggleUserMissionPopover(true);
  };
  const closePopover = () => toggleUserMissionPopover(false);
  const resetPage = () => toggleUserMissionPopover(open, viewingUserMissionId, 0);

  const selectViewingMission = event => {
    updateViewingUserMissionId(
      _.get(
        (userMissions || []).find(userMission => _.get(userMission, 'missionListId.id') === event.target.value),
        'missionListId.id',
        ''
      )
    );
  };

  const switchPage = () => {
    if (page === 0) {
      // Tracking
      tracking('User missions: View Gained Rewards button clicked');
    }
    toggleUserMissionPopover(true, viewingUserMissionId, page === 0 ? 1 : 0);
  };

  const goSubscription = useCallback(() => {
    history.push('/subscription');
    toggleUserMissionPopover(false);
  }, [history, toggleUserMissionPopover]);

  const showVideo = (event, mission, url) => {
    tracking('Click on Video Button in User Mission Popover', {
      missionId: _.get(mission, 'id')
    });
    const videoDialogInstance = _.get(videoDialogRef, 'current._wrappedInstance');
    if (videoDialogInstance) {
      videoDialogInstance.open(event, url);
    }
  };

  useEffect(() => {
    if (open) {
      if (userMissions && userMissions.length > 0 && !viewingUserMissionId) {
        updateViewingUserMissionId(_.get(userMissions, '[0].missionListId.id'));
      }
    }
  }, [open, userMissions, viewingUserMissionId, updateViewingUserMissionId]);

  useEffect(() => {
    if (getUserMissionsPhase === INIT) {
      getUserMissions();
    }
  }, [getUserMissionsPhase, getUserMissions]);

  const viewingUserMission = (userMissions || []).find(
    userMission => _.get(userMission, 'missionListId.id') === viewingUserMissionId
  );
  const { incompleteList, completedList } = _.get(viewingUserMission, 'missionListId.missions', []).reduce(
    (accumulator, mission) => {
      const isMissionCompleted = _.get(viewingUserMission, `completedMissions.${mission.id}`, false);
      accumulator[isMissionCompleted ? 'completedList' : 'incompleteList'].push(mission);
      return accumulator;
    },
    { incompleteList: [], completedList: [] }
  );

  const scrollableMaxHeight = window.innerHeight - 210;

  const SelectIcon = props => {
    return <Chip className="more" size="small" label={intl.formatMessage({ id: 'More' })} />;
  };

  return (
    userMissions &&
    userMissions.length > 0 &&
    shouldShowReferAdviserButton && (
      <Fragment>
        <VideoDialog ref={videoDialogRef} />
        <TopNavigationPortal>
          <IconButton ref={buttonRef} className="user-missions-button" onClick={openPopover} {...buttonProps}>
            <img src="img/user-mission/reward-header.svg" alt="user-mission" width={20} height={20} />
          </IconButton>
        </TopNavigationPortal>
        <Popover
          className="user-missions-popover"
          open={open}
          anchorEl={_.get(buttonRef, 'current')}
          onClose={closePopover}
          onExited={resetPage}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          PaperProps={{
            style: {
              minWidth: 'min(50%, 500px)'
            }
          }}
        >
          {/*<div className="title">*/}
          {/*	<Typography className="title-typo" color="primary">*/}
          {/*		{intl.formatMessage({ id: 'user-missions-title' })}*/}
          {/*	</Typography>*/}
          {/*</div>*/}

          <Grid container direction="column" wrap="nowrap">
            <Grid item>
              <FormControl className="select">
                <TempSelect
                  className={userMissions.length <= 1 ? 'hide-svg' : undefined}
                  IconComponent={SelectIcon}
                  value={_.get(viewingUserMission, 'missionListId.id', '')}
                  onChange={selectViewingMission}
                >
                  {(userMissions || []).map(userMission => {
                    const missionListId = _.get(userMission, 'missionListId.id', '');
                    return (
                      <MenuItem key={missionListId} value={missionListId}>
                        <ListItemText
                          primary={_.get(userMission, `missionListId.title.${intl.locale}`, '')}
                          secondary={_.get(userMission, `missionListId.description.${intl.locale}`, '')}
                          secondaryTypographyProps={{
                            style: {
                              fontSize: '85%'
                            }
                          }}
                        />
                        {/*<Typography>{_.get(userMission, `missionListId.title.${intl.locale}`, '')}</Typography>*/}
                      </MenuItem>
                    );
                  })}
                </TempSelect>
              </FormControl>
            </Grid>

            {viewingUserMission ? (
              <Fragment>
                <Grid item style={{ flexGrow: 1 }}>
                  {page === 0 && (
                    <div className="incomplete-mission-list" style={{ maxHeight: scrollableMaxHeight }}>
                      <List className="mission-list" component="div" dense={true} disablePadding={true}>
                        {incompleteList
                          .filter(
                            mission =>
                              !mission.parents.find(
                                parentId => !_.get(viewingUserMission, `completedMissions.${parentId}`, false)
                              )
                          )
                          .map((mission, index, missions) => (
                            <MissionEntry
                              key={mission.id}
                              intl={intl}
                              history={history}
                              viewingUserMission={viewingUserMission}
                              mission={mission}
                              index={index}
                              missions={missions}
                              showVideo={showVideo}
                              onClose={closePopover}
                            />
                          ))}
                      </List>
                    </div>
                  )}

                  {page === 1 && (
                    <Grid
                      className="gained-rewards"
                      container
                      alignItems="flex-start"
                      justify="flex-start"
                      direction="column"
                      wrap="nowrap"
                      style={{ maxHeight: scrollableMaxHeight }}
                    >
                      <Grid item>
                        <Typography className="title-typo" gutterBottom={true}>
                          {intl.formatMessage({ id: 'um-rewards-title' })}
                        </Typography>
                      </Grid>
                      {viewingUserMission.gainedRewards && viewingUserMission.gainedRewards.length > 0 ? (
                        <Grid item style={{ width: '100%' }}>
                          {viewingUserMission.gainedRewards
                            .reduce((accumulator, reward) => {
                              const foundIndex = accumulator.findIndex(
                                previousRewards => previousRewards.type === reward.type
                              );
                              if (foundIndex > -1) {
                                if (reward.quantity) {
                                  accumulator[foundIndex].quantity =
                                    (accumulator[foundIndex].quantity || 0) + reward.quantity;
                                }
                              } else {
                                accumulator.push({ ...reward });
                              }
                              return accumulator;
                            }, [])
                            .map((groupedReward, index) => {
                              return (
                                <MTotalRewardEntry
                                  key={index}
                                  intl={intl}
                                  type={groupedReward.type}
                                  quantity={groupedReward.quantity}
                                  onGoSubscription={goSubscription}
                                />
                              );
                            })}
                        </Grid>
                      ) : (
                        <Grid item style={{ width: '100%', textAlign: 'center' }}>
                          <SentimentDissatisfiedRoundedIcon
                            color="error"
                            style={{
                              width: 36,
                              height: 36
                            }}
                          />
                          <Typography color="textSecondary">{intl.formatMessage({ id: 'um-no-reward' })}</Typography>
                        </Grid>
                      )}
                      <Grid item className="completed-mission-list">
                        <List className="mission-list" component="div" dense={true} disablePadding={true}>
                          {completedList.map((mission, index, missions) => (
                            <MissionEntry
                              key={mission.id}
                              intl={intl}
                              history={history}
                              viewingUserMission={viewingUserMission}
                              mission={mission}
                              index={index}
                              missions={missions}
                              showVideo={showVideo}
                              onClose={closePopover}
                            />
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item>
                  <Button
                    className="switch-view"
                    color="primary"
                    endIcon={page === 0 && <ArrowForwardRoundedIcon />}
                    startIcon={page === 1 && <ArrowBackRoundedIcon />}
                    fullWidth={true}
                    onClick={switchPage}
                  >
                    <Chip
                      label={intl.formatMessage({ id: page === 0 ? 'um-view-rewards' : 'um-view-missions' })}
                      color="primary"
                      style={{ fontSize: 'inherit' }}
                    />
                  </Button>
                </Grid>
              </Fragment>
            ) : (
              <Grid item>
                <Typography color="textSecondary" align="center">
                  {intl.formatMessage({ id: 'um-no-selected-list' })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Popover>
      </Fragment>
    )
  );
};

UserMissions.whyDidYouRender = true;

const container = connect(
  state => ({
    open: state.userMission.popoverOpen,
    page: state.userMission.popoverPage,
    viewingUserMissionId: state.userMission.viewingUserMissionId,
    userMissions: state.userMission.userMissions ? [...state.userMission.userMissions] : undefined,
    userSubscription: state.userSubscription.userSubscription,
    getUserMissionsPhase: state.userMission.getUserMissionsPhase
  }),
  {
    getUserMissions,
    toggleUserMissionPopover,
    updateViewingUserMissionId
  }
)(memo(UserMissions));

export default injectIntl(withRouter(container));
