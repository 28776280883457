import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import palette from '../../../App/palette.scss';
import { sanitize } from 'dompurify';

const LinkStyle = {
  textDecoration: 'underline',
  fontWeight: 500,
  color: palette['primaryMain']
};

const MyMedicalCardsSupportDialog = props => {
  const { intl, open, contentPrepend, onClose, ...rest } = props;

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'support' })}</DialogTitle>
      <DialogContent>
        {contentPrepend && contentPrepend}
        <Typography
          paragraph={true}
          dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-support-dialog-content' })) }}
        />
        <Typography>
          MixCare/{intl.formatMessage({ id: 'med-dental' })}:{' '}
          <a href="tel:+85298567349" style={LinkStyle}>
            9856 7349
          </a>
        </Typography>
        <Typography gutterBottom={true}>Mon - Fri: 10:00-18:00</Typography>
        <Typography>
          {intl.formatMessage({ id: 'doctor-network-inquiry' })}:{' '}
          <a href="tel:+85221582600" style={LinkStyle}>
            2158 2600
          </a>
        </Typography>
        <Typography gutterBottom={true}>Mon - Sun: 09:00-23:59</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(MyMedicalCardsSupportDialog);
