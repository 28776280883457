import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: '10%',
    width: '100vw',
    alignItems: 'center'
  },
  main: {
    maxWidth: 600,
    width: '100%'
  },
  fontSize: {
    fontSize: 15
  },
  helperText: {
    fontSize: 13,
    marginLeft: 0
  },
  title: {
    fontSize: 17,
    fontWeight: 400,
    marginBottom: 25
  }
};

class NickName extends React.PureComponent {
  onChange = e => {
    this.props.setName(e.target.value);
  };

  render() {
    const { intl, error, name } = this.props;
    return (
      <div style={styles.container} key={1}>
        <div style={styles.main}>
          <Typography classes={{ root: this.props.classes.title }}>
            {intl.formatMessage({ id: `name-question` })}
          </Typography>
          <TextField
            onChange={this.onChange}
            name="name"
            error={!!error}
            helperText={error ? error : undefined}
            multiline={false}
            placeholder={this.props.intl.formatMessage({ id: 'Name' })}
            variant="outlined"
            FormHelperTextProps={{
              classes: {
                root: this.props.classes.helperText
              }
            }}
            InputProps={{
              classes: {
                input: this.props.classes.fontSize
              },
              style: { lineHeight: '16px' }
            }}
            value={name}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NickName);
