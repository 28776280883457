import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, makeStyles } from '@material-ui/core';
import NativeOrWeb from '../../../utils/native';
import { toggleDialog } from '../../../store/control/duck';

const useStyle = makeStyles({
  root: {
    padding: '4px 8px',
    lineHeight: 1
  }
});

const OTAUpdateButton = props => {
  const { intl, toggleDialog, ...rest } = props;

  const classes = useStyle();
  const [shouldShow, setShouldShow] = useState(false);

  const openConfirmDialog = () => {
    toggleDialog('otaUpdate', {});
  };

  useEffect(() => {
    (async () => {
      const result = await NativeOrWeb.checkForOTAUpdate();
      console.log({ result });
      setShouldShow(result);
    })()
      .then()
      .catch();
  }, []);

  return shouldShow ? (
    <Button variant="outlined" color="primary" onClick={openConfirmDialog} classes={classes} {...rest}>
      {intl.formatMessage({ id: 'ota-update-cta' })}
    </Button>
  ) : null;
};

const container = connect(state => ({}), {
  toggleDialog
})(OTAUpdateButton);

export default injectIntl(container);
