import { connect } from 'react-redux';
import WelcomeView from './component';
import { getUser, updateUserDetails, handleSignOut, setShownDashHelp, updateFirstGuidelineState } from '../../store/user/duck';

import { injectIntl } from 'react-intl';

const Welcome = connect(
  // Map state to props
  state => ({
    getUserPhase: state.user.getUserPhase,
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {
    getUser,
    updateUserDetails,
    handleSignOut,
    setShownDashHelp,
    updateFirstGuidelineState
  }
)(WelcomeView);

export default injectIntl(Welcome);
