import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';
import PasswordRulesInfo from '../PasswordRulesInfo/PasswordRulesInfo';
import { passwordValidations } from '../../utils/validators';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    padding: '4px',
    background: theme.palette.common.white,
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    border: '1px solid #dadde9',
    boxShadow: '1px 5px 5px 0 rgba(109, 109, 109, 0.27)'
  }
}))(Tooltip);

const passwordValidationPairs = [...passwordValidations.entries()].filter(
  ([key, validator]) => key !== 'password-required'
);

const PasswordRulesInfoTooltip = ({ children, ...props }) => {
  const { open, password, ...rest } = props;

  const validationResults = passwordValidationPairs.map(([key, validator]) => {
    return {
      isValid: validator(password),
      key: key
    };
  });

  const isPasswordValid = validationResults.every(({ isValid }) => isValid);

  const MS_TO_SHOW_TOOLTIP = 30 * 60 * 1000;
  return (
    <StyledTooltip
      open={open && !isPasswordValid}
      title={<PasswordRulesInfo validationResults={validationResults} />}
      {...rest}
      enterTouchDelay={0}
      leaveTouchDelay={MS_TO_SHOW_TOOLTIP}
      disableHoverListener
      arrow
      onFocus={evemt => {
        console.log('onFocus', evemt);
      }}
    >
      {children}
    </StyledTooltip>
  );
};

export default PasswordRulesInfoTooltip;
