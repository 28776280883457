import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Card, CardContent, Grid, Portal, TextField, Typography } from '@material-ui/core';
import SimplifiedViewToggle from '../SimplifiedViewToggle/SimplifiedViewToggle';
import _ from 'lodash';
import { getErrorMessageIntl } from '../../../../utils/validators';
import './SimplifiedAddMPF.scss';
import AccountTypesInput from '../../Inputs/AccountTypesInput/AccountTypesInput';
import moment from 'moment';
import CurrencyInput from '../../Inputs/CurrencyInput/CurrencyInput';
import PremiumInput from '../../Inputs/PremiumInput/PremiumInput';
import NumberFormatTextField from '../../Inputs/NumberFormatInput/NumberFormatTextField';
import MarketValueUpdateDateInput from '../../Inputs/MarketValueUpdateDateInput/MarketValueUpdateDateInput';
import { DEFAULT_APPENDIX_DATA, DEFAULT_DETAIL_DATA, DEFAULT_PAYMENT_DATA, DEFAULT_PLAN_DATA } from '../../constants';
import SubmitActionWrapper from '../SubmitActionWrapper/SubmitActionWrapper';
import {
  formatAppendixData,
  formatDetailData,
  formatPaymentData,
  formatPlanData,
  shouldUsePolicyNameInput
} from '../../utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router';
import { validatePaymentData, validatePlanData } from '../../validators';
import PolicyNameInput from '../../Inputs/PolicyNameInput/PolicyNameInput';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { getInsurerTitleEn, isInsurerOther } from '../../../../utils/insurer';

const ContainerStyle = {
  padding: '0 16px'
};

const CardStyle = {
  boxShadow: 'var(--my-shadow)'
};

const SimplifiedAddMPF = props => {
  const { intl, history, userDetails, handleSubmit } = props;

  const [isAddRider, setIsAddRider] = useState(false);
  const [categoryType, setCategoryType] = useState({});
  const [subCategoryType, setSubCategoryType] = useState({});
  const [insurerFix, setInsurerFix] = useState('');
  const [insurer, setInsurer] = useState('');
  const [planData, setPlanData] = useState(DEFAULT_PLAN_DATA);
  const [paymentData, setPaymentData] = useState({ ...DEFAULT_PAYMENT_DATA, currency: 'HKD' });
  const [detailData, setDetailData] = useState(DEFAULT_DETAIL_DATA);
  const [appendixData, setAppendixData] = useState(DEFAULT_APPENDIX_DATA);
  const [error, setError] = useState({});

  const submitActionWrapperRef = useRef(null);

  const categoryTitle = _.get(categoryType, 'title');
  const subCategoryTitle = _.get(subCategoryType, 'title');

  useEffect(() => {
    const lsInsurer = JSON.parse(localStorage.getItem('insurer'));
    const addRider = JSON.parse(localStorage.getItem('addRider'));
    const categoryType = JSON.parse(localStorage.getItem('category'));
    const subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    const lsPlanData = JSON.parse(localStorage.getItem('plan'));
    const lsPaymentData = JSON.parse(localStorage.getItem('payment'));
    const lsDetailData = JSON.parse(localStorage.getItem('detail'));
    const lsAppendixData = JSON.parse(localStorage.getItem('apendix'));

    setIsAddRider(addRider || false);
    setCategoryType(categoryType);
    setSubCategoryType(subCategoryType);

    let value = '';
    let previousPolicy = '';

    if (lsInsurer && !isInsurerOther(lsInsurer)) {
      value = getInsurerTitleEn(lsInsurer);
    }

    setInsurerFix(lsInsurer ? getInsurerTitleEn(lsInsurer) : '');
    setInsurer(value);

    if (addRider === true) {
      previousPolicy = JSON.parse(localStorage.getItem('previousPolicyData'));
    }

    if (lsPlanData) {
      if (lsPlanData.insurerId === '5a1be0f9dfaa60227838e426') {
        setInsurerFix('Other');
      }
      //saving the policy data in there respective fields
      if (!lsPlanData.guaranteeValue) {
        lsPlanData.guaranteeValue = '';
      }

      if (lsPlanData.vhisPlan !== 'Yes' && lsPlanData.coverageType === 'Flexi') {
        lsPlanData.coverageType = '';
      }

      if (!lsPlanData.roomClass) {
        lsPlanData.roomClass = '';
      }

      if (!lsPlanData.isAnnuity) {
        lsPlanData.isAnnuity = '';
      }

      if (!lsPlanData.accountTypes) {
        lsPlanData.accountTypes = [];
      }

      setPlanData(lsPlanData);
    }

    if (lsPaymentData) {
      if (lsPaymentData.policyStartDate) {
        lsPaymentData.policyStartDate = moment.parseZone(lsPaymentData.policyStartDate);
      }

      lsPaymentData.lifeCoverage = lsPaymentData.lifeCoverage ? parseInt(lsPaymentData.lifeCoverage) : 0;
      lsPaymentData.premium = lsPaymentData.premium ? lsPaymentData.premium : 0;
      lsPaymentData.naPremium = lsPaymentData.naPremium || false;
      lsPaymentData.sumInsured = lsPaymentData.sumInsured ? lsPaymentData.sumInsured : 0;
      lsPaymentData.reminderActive =
        lsPaymentData.frequency && lsPaymentData.frequency !== '1' ? lsPaymentData.reminderActive : false;
      lsPaymentData.policyDueDate = lsPaymentData.frequency !== '1' ? lsPaymentData.policyDueDate : null;
      lsPaymentData.reminderDate = lsPaymentData.frequency !== '1' ? lsPaymentData.reminderDate : null;
      lsPaymentData.adviserReminderActive =
        userDetails.userType === 'Adviser' &&
        lsPaymentData.frequency &&
        lsPaymentData.frequency !== '1' &&
        lsPaymentData.adviserReminderActive
          ? lsPaymentData.adviserReminderActive
          : false;

      setPaymentData(lsPaymentData);
    }

    if (lsDetailData) {
      if (lsDetailData.premiumHolidayDate) {
        lsDetailData.premiumHolidayDate = moment.parseZone(lsDetailData.premiumHolidayDate);
      }
      if (lsDetailData.marketValueUpdateDate) {
        lsDetailData.marketValueUpdateDate = moment.parseZone(lsDetailData.marketValueUpdateDate);
      }
      if (lsDetailData.paidUpDate) {
        lsDetailData.paidUpDate = moment.parseZone(lsDetailData.paidUpDate);
      }

      lsDetailData.policyOwnerSelected = lsDetailData.policyOwnerSelected || '';
      lsDetailData.lifeInsuredSelected = lsDetailData.lifeInsuredSelected || '';
      lsDetailData.paymentTerms = lsDetailData.paymentTerms || '';
      lsDetailData.paymentTermsOther = lsDetailData.paymentTermsOther || '';
      lsDetailData.protectionTerms = lsDetailData.protectionTerms || '';
      lsDetailData.protectionTermsOther = lsDetailData.protectionTermsOther || '';
      lsDetailData.beneficialSelected = lsDetailData.beneficialSelected || '';

      setDetailData(lsDetailData);
    }

    if (lsAppendixData) {
      setAppendixData(lsAppendixData);
    }

    if (previousPolicy) {
      if (!lsPlanData) {
        const tempPlanData = {
          ...DEFAULT_PLAN_DATA,
          type: _.get(previousPolicy, 'type', ''),
          planType: 'Rider',
          guaranteeValue: _.get(previousPolicy, 'guaranteeValue', ''),
          policyNumber: _.get(previousPolicy, 'policyNumber', ''),
          coverageType: _.get(previousPolicy, 'coverageType', ''),
          roomClass: _.get(previousPolicy, 'roomClass', ''),
          Rider: 'Yes'
        };

        if (lsInsurer.id === '5a1be0f9dfaa60227838e426' && previousPolicy.insurerId === '5a1be0f9dfaa60227838e426') {
          setInsurerFix('Other');
          setInsurer(_.get(previousPolicy, 'insurer', ''));
        }

        //saving the policy data in there respective fields
        setPlanData(tempPlanData);
      }

      if (!lsPaymentData) {
        const tempPaymentData = {
          ...DEFAULT_PAYMENT_DATA,
          currency: _.get(previousPolicy, 'currency', ''),
          frequency: _.get(previousPolicy, 'frequency', ''),
          paymentMethod: _.get(previousPolicy, 'paymentMethod', ''),
          lumpSumAmount: _.get(previousPolicy, 'lumpSumAmount', '')
        };

        if (previousPolicy.policyStartDate) {
          tempPaymentData.policyStartDate = moment.parseZone(previousPolicy.policyStartDate);
        }

        if (
          previousPolicy.policyDueDate &&
          previousPolicy.reminderActive === true &&
          previousPolicy.frequency &&
          previousPolicy.frequency !== '1'
        ) {
          tempPaymentData.policyDueDate = _.get(previousPolicy, 'policyDueDate', null);
        }
        if (
          previousPolicy.reminderDate &&
          previousPolicy.reminderActive === true &&
          previousPolicy.frequency &&
          previousPolicy.frequency !== '1'
        ) {
          tempPaymentData.reminderDate = _.get(previousPolicy, 'reminderDate', null);
        }
        if (previousPolicy.frequency && previousPolicy.frequency !== '1') {
          tempPaymentData.reminderActive = _.get(previousPolicy, 'reminderActive', false);
        }
        if (userDetails.userType === 'Adviser' && previousPolicy.adviserReminderActive === true) {
          if (
            previousPolicy.reminderDate &&
            previousPolicy.adviserReminderActive === true &&
            previousPolicy.frequency &&
            previousPolicy.frequency !== '1'
          ) {
            tempPaymentData.reminderDate = _.get(previousPolicy, 'reminderDate', null);
          }
          if (
            previousPolicy.policyDueDate &&
            previousPolicy.adviserReminderActive === true &&
            previousPolicy.frequency &&
            previousPolicy.frequency !== '1'
          ) {
            tempPaymentData.policyDueDate = _.get(previousPolicy, 'policyDueDate', null);
          }
          if (previousPolicy.frequency && previousPolicy.frequency !== '1') {
            tempPaymentData.adviserReminderActive = _.get(previousPolicy, 'adviserReminderActive', false);
          }
        }

        setPaymentData(tempPaymentData);
      }

      if (!lsDetailData) {
        const tempDetailData = {
          ...DEFAULT_DETAIL_DATA,
          policyOwner: previousPolicy.policyOwner,
          policyOwnerSelected: previousPolicy.policyOwnerSelected ?? '',
          lifeInsured: previousPolicy.lifeInsured,
          lifeInsuredSelected: previousPolicy.lifeInsuredSelected ? previousPolicy.lifeInsuredSelected : '',
          lifeInsuredAge: _.get(previousPolicy, 'lifeInsuredAge', ''),
          paymentTermsOther: _.get(previousPolicy, 'paymentTermsOther', ''),
          protectionTermsOther: _.get(previousPolicy, 'protectionTermsOther', ''),
          beneficial: _.get(previousPolicy, 'beneficial', ''),
          beneficialSelected: _.get(previousPolicy, 'beneficialSelected', ''),
          vehicleYear: _.get(previousPolicy, 'vehicleYear', ''),
          vehicleModel: _.get(previousPolicy, 'vehicleModel', ''),
          vehicleBrand: _.get(previousPolicy, 'vehicleBrand', ''),
          ncb: _.get(previousPolicy, 'ncb', ''),
          homeSize: _.get(previousPolicy, 'homeSize', ''),
          homeContentsValue: _.get(previousPolicy, 'homeContentsValue'),
          coverageDetail: _.get(previousPolicy, 'coverageDetail', ''),
          coverageDay: _.get(previousPolicy, 'coverageDay', ''),
          tripDelayCover: _.get(previousPolicy, 'tripDelayCover', ''),
          lossItemCover: _.get(previousPolicy, 'lossItemCover', ''),
          mobilePhoneCover: _.get(previousPolicy, 'mobilePhoneCover', ''),
          rentalCarCover: _.get(previousPolicy, 'rentalCarCover', ''),
          permanentDisablement: _.get(previousPolicy, 'permanentDisablement', ''),
          medicalExpenses: _.get(previousPolicy, 'medicalExpenses', ''),
          physiotherapy: _.get(previousPolicy, 'physiotherapy', ''),
          chineseDoctor: _.get(previousPolicy, 'chineseDoctor', ''),
          chineseBonesetter: _.get(previousPolicy, 'chineseBonesetter', ''),
          hospitalCashDetail: _.get(previousPolicy, 'hospitalCashDetail', ''),
          employersLiability: _.get(previousPolicy, 'employersLiability', ''),
          outPatientDetail: _.get(previousPolicy, 'outPatientDetail', ''),
          dentalDetail: _.get(previousPolicy, 'dentalDetail', ''),
          repatriation: _.get(previousPolicy, 'repatriation', ''),
          accident: _.get(previousPolicy, 'accident', ''),
          maidReplacement: _.get(previousPolicy, 'maidReplacement', ''),
          policyStatus: _.get(previousPolicy, 'policyStatus', ''),
          totalWithdrawn: _.get(previousPolicy, 'totalWithdrawn', ''),
          // marketValue: _.get(previousPolicy, 'marketValue', ''),
          propertyValue: _.get(previousPolicy, 'propertyValue', ''),
          downPayment: _.get(previousPolicy, 'downPayment', ''),
          loanRatio: _.get(previousPolicy, 'loanRatio', ''),
          outStandingLoan: _.get(previousPolicy, 'outStandingLoan', ''),
          interestRate: _.get(previousPolicy, 'interestRate', ''),
          interestTerms: _.get(previousPolicy, 'interestTerms', ''),
          penaltyPeriod: _.get(previousPolicy, 'penaltyPeriod', ''),
          smm: _.get(previousPolicy, 'smm', ''),
          hospitalCash: _.get(previousPolicy, 'hospitalCash', ''),
          outPatient: _.get(previousPolicy, 'outPatient', ''),
          dental: _.get(previousPolicy, 'dental', ''),
          deductible: _.get(previousPolicy, 'deductible', ''),
          selectedPolicyStatus: previousPolicy.selectedPolicyStatus ? previousPolicy.selectedPolicyStatus : '',
          multiClaim: _.get(previousPolicy, 'multiClaim', false),
          earlyStage: _.get(previousPolicy, 'earlyStage', false)
        };

        if (previousPolicy.premiumHolidayDate) {
          tempDetailData.premiumHolidayDate = moment.parseZone(previousPolicy.premiumHolidayDate);
        }
        if (previousPolicy.paidUpDate) {
          tempDetailData.paidUpDate = moment.parseZone(previousPolicy.paidUpDate);
        }
        // if (previousPolicy.marketValueUpdateDate) {
        //   tempDetailData.marketValueUpdateDate = moment.parseZone(previousPolicy.marketValueUpdateDate);
        // }

        setDetailData(tempDetailData);
      }

      if (!lsAppendixData) {
        const adviserPhoneNo = _.get(previousPolicy, 'advisorPhoneNo');
        const adviserPhoneNo2 = _.get(previousPolicy, 'advisorPhoneNo2');

        const tempAppendixData = {
          ...DEFAULT_APPENDIX_DATA,
          advisorName: _.get(previousPolicy, 'advisorName', ''),
          advisorPhoneNo: adviserPhoneNo ? adviserPhoneNo.toString() : undefined,
          advisorPhoneNo2: adviserPhoneNo2 ? adviserPhoneNo2.toString() : undefined,
          advisorEmail: _.get(previousPolicy, 'advisorEmail', ''),
          remark: _.get(previousPolicy, 'remark', ''),
          uploadDocuments: _.get(previousPolicy, 'uploadDocuments', []),
          orphanCase: _.get(previousPolicy, 'orphanCase', ''),
          isDraft: _.get(previousPolicy, 'isDraft', false),
          productLink: _.get(previousPolicy, 'productLink', '')
        };

        setAppendixData(tempAppendixData);
      }
    }
  }, [userDetails]);

  const { policyName, policyNumber, accountTypes } = planData || {};
  const { currency, premium, frequency, naPremium } = paymentData || {};
  const { marketValue, marketValueUpdateDate } = detailData || {};

  const createPlanDataOnChangeHandler = key => event => {
    delete error[key];
    setError(error);
    setPlanData({ ...planData, [key]: event.target.value });
  };

  const createPaymentDataOnChangeHandler = key => event => {
    delete error[key];
    setError(error);
    setPaymentData({ ...paymentData, [key]: event.target.value });
  };

  const createDetailDataOnChangeHandler = key => event => {
    delete error[key];
    setError(error);
    setDetailData({ ...detailData, [key]: event.target.value });
  };

  const onChangeNAPremium = event => {
    const stateObj = { naPremium: event.target.checked };
    if (event.target.checked) {
      stateObj.premium = 0;
      stateObj.frequency = '12';
    }
    setPaymentData({ ...paymentData, ...stateObj });

    delete error['premium'];
    delete error['frequency'];
    setError(error);
  };

  const onChangeMarketValueUpdateDate = date => setDetailData({ ...detailData, marketValueUpdateDate: date });

  const save = () => {
    formatPlanData({ ...planData, insurer: insurer });
    formatPaymentData(paymentData, userDetails);
    formatDetailData(detailData);
    formatAppendixData(appendixData);

    setPlanData(DEFAULT_PLAN_DATA);
    setPaymentData({ ...DEFAULT_PAYMENT_DATA, currency: 'HKD' });
    setDetailData(DEFAULT_DETAIL_DATA);
    setAppendixData(DEFAULT_APPENDIX_DATA);
  };

  const showActionsDialog = () => {
    const planDataError = validatePlanData({ ...planData, insurer: insurer }, categoryType, subCategoryType);
    const paymentDateError = validatePaymentData(paymentData, categoryType, subCategoryType);
    const totalError = { ...planDataError, ...paymentDateError };
    setError(totalError);

    if (Object.keys(totalError).length === 0) {
      if (submitActionWrapperRef && submitActionWrapperRef.current && submitActionWrapperRef.current.open) {
        submitActionWrapperRef.current.open();
      }
    }
  };

  const back = () => history.replace(!isAddRider ? '/insurer' : '/insurance_type', history.location.state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="simplified-add-mpf" style={ContainerStyle}>
      <TopNavigationPortal>
        <span onClick={back} className="back_arrow">
          <ArrowBackIcon />
        </span>
      </TopNavigationPortal>

      <SubmitActionWrapper ref={submitActionWrapperRef} handleSubmit={handleSubmit} beforeSubmit={save} />

      <Grid container justify="flex-end" spacing={1}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <SimplifiedViewToggle beforeSwitch={save} />
        </Grid>
        <Grid item xs={12}>
          <Card style={CardStyle}>
            <CardContent>
              <form noValidate={true}>
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="primary" align="center">
                      {intl.formatMessage({ id: 'Plan' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={intl.formatMessage({ id: 'pf-trustee' })}
                      name="insurer"
                      value={
                        insurer === 'Other'
                          ? ''
                          : insurerFix !== 'Other' && insurer
                          ? intl.formatMessage({ id: insurer })
                          : insurer
                      }
                      onChange={createPlanDataOnChangeHandler('insurer')}
                      required
                      disabled={insurerFix !== 'Other'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {subCategoryTitle === 'Provident Fund' && shouldUsePolicyNameInput(insurer) ? (
                      <PolicyNameInput
                        insurer={insurer}
                        subCategoryId={subCategoryType.value}
                        label={intl.formatMessage({ id: 'pf-plan-name' })}
                        value={policyName}
                        onChange={createPlanDataOnChangeHandler('policyName')}
                        error={error.policyName}
                      />
                    ) : (
                      <TextField
                        label={intl.formatMessage({ id: 'pf-plan-name' })}
                        name="policyName"
                        value={policyName || ''}
                        onChange={createPlanDataOnChangeHandler('policyName')}
                        required
                        error={!!error.policyName}
                        helperText={
                          error.policyName && intl.formatMessage({ id: getErrorMessageIntl(error.policyName) })
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={intl.formatMessage({ id: 'pf-member-number' })}
                      name="policyNumber"
                      value={policyNumber || ''}
                      onChange={createPlanDataOnChangeHandler('policyNumber')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AccountTypesInput value={accountTypes} onChange={createPlanDataOnChangeHandler('accountTypes')} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="primary" align="center">
                      {intl.formatMessage({ id: 'payment-and-details' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CurrencyInput
                      value={currency}
                      onChange={createPaymentDataOnChangeHandler('currency')}
                      error={error.currency}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PremiumInput
                      label={intl.formatMessage({ id: 'Payment' })}
                      value={premium}
                      onChange={createPaymentDataOnChangeHandler('premium')}
                      frequency={frequency}
                      onChangeFrequency={createPaymentDataOnChangeHandler('frequency')}
                      naPremium={naPremium}
                      onChangeNAPremium={onChangeNAPremium}
                      error={!!error.premium || !!error.frequency}
                      helperText={
                        (error.premium || error.frequency) &&
                        `${error.premium ? `${intl.formatMessage({ id: getErrorMessageIntl(error.premium) })} ` : ''}${
                          error.frequency ? `${intl.formatMessage({ id: getErrorMessageIntl(error.frequency) })}` : ''
                        }`
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <NumberFormatTextField
                          label={`${intl.formatMessage({ id: 'Market Value' })}:`}
                          name="marketValue"
                          value={marketValue || ''}
                          onChange={createDetailDataOnChangeHandler('marketValue')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MarketValueUpdateDateInput
                          value={marketValueUpdateDate ? moment.parseZone(marketValueUpdateDate) : null}
                          onChange={onChangeMarketValueUpdateDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end" spacing={1}>
                      <Grid item>
                        <Button
                          className="submitButton"
                          color="primary"
                          variant="contained"
                          onClick={showActionsDialog}
                        >
                          {intl.formatMessage({ id: 'Submit' })}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(SimplifiedAddMPF);

export default injectIntl(withRouter(container));
