import React, { PureComponent } from 'react';
import '../ClientDashboardDrawer.scss';
// import _ from 'lodash';
import ReactEcharts from 'echarts-for-react';
import { formatNumber } from '../../../utils/formatNumber';
import { getUserCurrency } from '../../../utils/user';
import {
  getPolicyValue,
  isInsuredNotMePolicy,
  getSubCategoryColorByPolicy,
  getSubCategoryNameById
} from '../../../utils/policy';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { SlideTransition } from '../../Transition/Transition';
import MoreInfoIconButton from '../../MoreInfoIconButton';
import { Dialog, DialogActions } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { getWebImageUrl } from '../../../utils/image';
import Badge from '@material-ui/core/Badge';
import { TERMS_OPTIONS } from '../../../constants/policy';

const getPolicyEndDate = (policy, age) => {
  // exclude data if:
  // 1. policyStartDate or protectionTerms are not filled
  // 2. age has not been filled, cannot calculate "until xxx"
  if (
    policy.policyStartDate === null ||
    policy.protectionTerms === null ||
    ([
      TERMS_OPTIONS.TILL_AGE_55,
      TERMS_OPTIONS.TILL_AGE_60,
      TERMS_OPTIONS.TILL_AGE_65,
      TERMS_OPTIONS.TILL_AGE_70,
      TERMS_OPTIONS.TILL_AGE_75,
      TERMS_OPTIONS.TILL_AGE_80,
      TERMS_OPTIONS.TILL_AGE_85,
      TERMS_OPTIONS.TILL_AGE_100,
      TERMS_OPTIONS.TILL_AGE_120,
      TERMS_OPTIONS.WHOLE_LIFE
    ].includes(policy.protectionTerms) &&
      age === null)
  ) {
    return 0;
  }

  let currentYear = moment().year();
  let startYear = moment(policy.policyStartDate).year();
  let policyStartedYear = currentYear - startYear;
  if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_55) {
    return moment(policy.policyStartDate)
      .add(55 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_60) {
    return moment(policy.policyStartDate)
      .add(60 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_65) {
    return moment(policy.policyStartDate)
      .add(65 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_70) {
    return moment(policy.policyStartDate)
      .add(70 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_75) {
    return moment(policy.policyStartDate)
      .add(75 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_80) {
    return moment(policy.policyStartDate)
      .add(80 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_85) {
    return moment(policy.policyStartDate)
      .add(85 - age + policyStartedYear, 'years')
      .year();
  } else if (
    policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_100 ||
    policy.protectionTerms === TERMS_OPTIONS.WHOLE_LIFE
  ) {
    return moment(policy.policyStartDate)
      .add(100 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.TILL_AGE_120) {
    return moment(policy.policyStartDate)
      .add(120 - age + policyStartedYear, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_5) {
    return moment(policy.policyStartDate)
      .add(5, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_8) {
    return moment(policy.policyStartDate)
      .add(8, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_10) {
    return moment(policy.policyStartDate)
      .add(10, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_12) {
    return moment(policy.policyStartDate)
      .add(12, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_15) {
    return moment(policy.policyStartDate)
      .add(15, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_18) {
    return moment(policy.policyStartDate)
      .add(18, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_20) {
    return moment(policy.policyStartDate)
      .add(20, 'years')
      .year();
  } else if (policy.protectionTerms === TERMS_OPTIONS.YEARS_25) {
    return moment(policy.policyStartDate)
      .add(25, 'years')
      .year();
  } else {
    let tmpNum = policy.protectionTerms ? policy.protectionTerms.replace(/\D/g, '') : '';
    // console.log(policy.protectionTerms);
    // console.log(tmpNum)
    if (isNaN(tmpNum) || tmpNum === '') {
      // console.log('is NAN');
      return 0;
    } else {
      // console.log(policy.protectionTerms);
      // console.log(tmpNum)
      // console.log('not NAN');
      if (tmpNum > 1950) {
        if (tmpNum < 2300) {
          return parseInt(tmpNum);
        } else {
          return 0;
        }
      } else if (tmpNum <= 30) {
        return moment(policy.policyStartDate)
          .add(tmpNum, 'years')
          .year();
      } else if (tmpNum > 30 && tmpNum <= 130) {
        // if age has not been filled, cannot calculate "until xxx" type,exclude it
        if (age === null) {
          return 0;
        }
        return moment(policy.policyStartDate)
          .add(tmpNum - age + policyStartedYear, 'years')
          .year();
      } else {
        return 0;
      }
    }
  }
};

const getXAxisData = (userAge, min, max) => {
  let xAxisData = [];
  let currentYear = moment().year();
  let startYear = moment(min.policyStartDate).year();
  let endYear = getPolicyEndDate(max, userAge);
  // console.log(startYear)
  // console.log(endYear)
  if (endYear - startYear + 1 < 100 && userAge + (endYear - currentYear) < 100) {
    endYear += 10;
  }
  // console.log(startYear)
  // console.log(endYear)
  for (var i = startYear; i <= endYear; i++) {
    let inputValue = userAge !== null ? userAge - (currentYear - i) : i;
    xAxisData.push(inputValue);
  }
  return xAxisData;
};

const colorCodeList = [
  '#8c1aff',
  '#0fc659',
  '#FF6A74',
  '#3966f8',
  '#e5d839',
  '#f7228c',
  '#009999',
  '#3eda7d',
  '#FF6A74',
  '#3966f8',
  '#ffa600',
  '#6d78cc',
  '#F2C1BB',
  '#99E9FF',
  '#B0D5FC',
  '#FFADAD',
  '#FFFBAD',
  '#D0D4DC',
  '#BBDDC6',
  '#D8CCE1',
  '#8B575C',
  '#F0BCC7'
];

class LifeCoverageChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSummaryGraph: true,
      modalIsOpen: false
    };
  }

  getPolicyName = (policy, seriesData) => {
    const { intl } = this.props;
    let policyName = '';
    if (policy.subCategoryId === '59b0ed2cdae1831be084f804') {
      policyName += intl.formatMessage({ id: 'Life' }) + ' ';
    } else if (policy.subCategoryId === '59b0ed6fdae1831be084f805') {
      policyName += intl.formatMessage({ id: 'Critical Illness' }) + ' ';
      policyName = policyName.replace('Critical Illness', 'C.I.');
    } else if (policy.subCategoryId === '59b0ed8fdae1831be084f806') {
      policyName += intl.formatMessage({ id: 'Medical' }) + ' ';
    } else if (policy.subCategoryId === '59b0ed98dae1831be084f807') {
      policyName += intl.formatMessage({ id: 'Other' }) + ' ';
    } else {
    }
    policyName += intl.formatMessage({ id: policy.insurer });
    // if there are the policy with the same type same insurer
    let recordCount = seriesData.filter(data => {
      return data.name.includes(policyName);
    }).length;

    if (recordCount > 0) {
      policyName += ' ' + recordCount / 2;
    }
    return policyName;
  };

  getSeriesData = (policy, min, max, userAge, selectedCurrency, seriesData, policyName) => {
    let currentYear = moment().year();
    let startYear = moment(min.policyStartDate).year();
    let endYear = getPolicyEndDate(max, userAge);
    let policyStartYear = moment(policy.policyStartDate).year();
    // let policyEndYear = moment(getPolicyEndDate(policy, userAge), 'DD/MM/YYYY').year();
    let policyEndYear = getPolicyEndDate(policy, userAge);

    const lifeCoverage = Math.round(getPolicyValue(policy, 'lifeCoverage', selectedCurrency));
    let data = [];
    let data_past = [];
    let data_future = [];

    // let policyName = this.getPolicyName(policy);
    const stackType = 'stack';
    const colorCode = colorCodeList[(seriesData.length / 2) % colorCodeList.length];

    for (var i = startYear; i <= endYear; i++) {
      data.push(policyStartYear <= i && i <= policyEndYear ? lifeCoverage : 0);
      data_past.push(policyStartYear <= i && i <= policyEndYear && i < currentYear ? lifeCoverage : 0);
      data_future.push(policyStartYear <= i && i <= policyEndYear && i >= currentYear ? lifeCoverage : 0);
    }

    return [
      {
        name: policyName,
        type: 'bar',
        color: this.state.isSummaryGraph === true ? '#3eeef8' : colorCode,
        borderColor: this.state.isSummaryGraph === true ? '#3eeef800' : colorCode + '00',
        stack: stackType,
        barCategoryGap: '0%',
        data: data_past,
        itemStyle: {
          opacity: 0.5
        }
      },
      {
        name: policyName,
        type: 'bar',
        color: this.state.isSummaryGraph === true ? '#3eeef8' : colorCode,
        borderColor: this.state.isSummaryGraph === true ? '#3eeef8' : colorCode,
        stack: stackType,
        barCategoryGap: '-30%',
        data: data_future
      }
    ];
    // return {
    //   name: policyName,
    //   type: 'bar',
    //   color: this.state.isSummaryGraph === true ? '#3eeef8' : colorCode,
    //   borderColor: this.state.isSummaryGraph === true ? '#3eeef8' : colorCode,
    //   stack: stackType,
    //   barCategoryGap: '-25%',
    //   data: data,
    //   dimension: getXAxisData_2(userAge, min, max)
    // };
  };

  // onclick = {
  //   click: this.clickEchartsPie.bind(this)
  // };
  // clickEchartsPie(e) {
  //   this.setState({ isSummaryGraph: !this.state.isSummaryGraph });
  //   console.log(this.state.isSummaryGraph);
  // }

  handleChange = event => {
    this.setState({ isSummaryGraph: !this.state.isSummaryGraph });
  };

  render() {
    const { intl, finalPortfolio, userDetails, isSelfPortfolio, factsheetData, age } = this.props;
    const { isSummaryGraph } = this.state;

    let seriesData = [],
      xAxisData = [],
      legendData = [];

    let min = '',
      max = '';

    // get the age of profolio owner or himself
    const userAge = age
      ? age
      : userDetails.userType !== 'Adviser'
      ? userDetails && userDetails.age
        ? userDetails.age
        : null
      : factsheetData && factsheetData.age
      ? factsheetData.age
      : null;

    const selectedCurrency = getUserCurrency(userDetails);

    // filter the valid profolio data which is
    // 1. not a not me policy
    // 2. with valid policy start date and protection terms
    let formattedPortfolio = finalPortfolio.filter(policy => {
      return (
        '59b0eaee14ea7f1aafb6d0b5' === policy.categoryId &&
        !isInsuredNotMePolicy(policy) &&
        policy.policyStartDate !== null &&
        getPolicyEndDate(policy, userAge) > 0
      );
    });

    //Get the non-counting data to be shown in prompt up msg
    let nonCountData = finalPortfolio.filter(policy => {
      return (
        '59b0eaee14ea7f1aafb6d0b5' === policy.categoryId &&
        !isInsuredNotMePolicy(policy) &&
        (policy.policyStartDate === null || getPolicyEndDate(policy, userAge) === 0)
      );
    });

    nonCountData = nonCountData.map(policy => {
      return {
        name: policy.policyName,
        color: getSubCategoryColorByPolicy(policy),
        subCategoryName: getSubCategoryNameById(policy.subCategoryId)
      };
    });

    // get max and min policy start date and end date as start and end of x-axis of the graph
    if (formattedPortfolio.length > 0) {
      min = formattedPortfolio.reduce(function(a, b) {
        return a.policyStartDate < b.policyStartDate ? a : b;
      });
      max = formattedPortfolio.reduce(function(a, b) {
        return getPolicyEndDate(a, userAge) > getPolicyEndDate(b, userAge) ? a : b;
      });
    }

    // sort the profolio by policy start date (ascending order) and protection terms (descending order)
    formattedPortfolio = formattedPortfolio.sort((a, b) => {
      let start_a = moment(a.policyStartDate).year();
      let start_b = moment(b.policyStartDate).year();
      return start_a > start_b
        ? 1
        : start_a === start_b
        ? getPolicyEndDate(a, userAge) > getPolicyEndDate(b, userAge)
          ? -1
          : 1
        : -1;
    });

    formattedPortfolio.forEach(policy => {
      if (getPolicyEndDate(policy, userAge) !== 0) {
        let policyName = this.getPolicyName(policy, seriesData);

        //valid data
        // seriesData.push(this.getSeriesData(policy, min, max, userAge, selectedCurrency, seriesData));
        let tmp = this.getSeriesData(policy, min, max, userAge, selectedCurrency, seriesData, policyName);
        seriesData.push(tmp[0]);
        seriesData.push(tmp[1]);
        legendData.push(policyName);
      }
    });

    xAxisData = getXAxisData(userAge, min, max);
    // console.log("series")
    // console.log(seriesData)
    // console.log("X")
    // console.log(xAxisData)
    // console.log("LEGEND")
    // console.log(legendData)

    var option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: params => {
          var colorSpan = color =>
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';
          let totalCoverage = 0;
          let itemListData = '';
          params.forEach(item => {
            if (item.data > 0) {
              var eachInfo =
                colorSpan(item.color) +
                ' ' +
                item.seriesName +
                ': ' +
                intl.formatMessage({ id: selectedCurrency }) +
                ' ' +
                formatNumber(item.data, intl) +
                '</br>';
              totalCoverage += item.data;
              itemListData += eachInfo;
            }
          });
          let rez = (userAge !== null ? 'Age ' : 'Year ') + params[0].axisValue + '</br>';
          rez +=
            intl.formatMessage({ id: 'Total Life Coverage' }) +
            ': ' +
            intl.formatMessage({ id: selectedCurrency }) +
            ' ' +
            formatNumber(totalCoverage, intl) +
            '</br>';
          rez += isSummaryGraph === true ? '' : itemListData;
          return rez;
        }
      },
      grid: {
        left: legendData.length > 0 ? '10%' : '15%',
        bottom: legendData.length > 6 ? '28%' : '22%',
        top: '3%',
        containLabel: true
      },
      legend: {
        data: this.state.isSummaryGraph === true ? [] : legendData,
        bottom: 0,
        padding: [0, 0, 0, 0],
        icon: 'circle'
      },
      yAxis: {
        type: 'value',
        name: intl.formatMessage({ id: 'coverage-amount' }),
        nameGap: seriesData.length > 0 ? 42 : 22,
        nameLocation: 'center',
        axisLabel: {
          formatter: function(a) {
            return formatNumber(a, intl);
          }
        }
      },
      xAxis: {
        type: 'category',
        name: userAge !== null ? intl.formatMessage({ id: 'Age' }) : intl.formatMessage({ id: 'year' }),
        nameGap: 25,
        nameLocation: 'center',
        data: xAxisData
      },

      series: seriesData
    };
    return (
      <div>
        <div className="cardAnalysis">
          <h4 className="text-center coverage-graph-title">{intl.formatMessage({ id: 'Life Coverage Summary' })}</h4>

          <div className="dashboard-drawer-icon">
            <MoreInfoIconButton handleClick={() => this.setState({ modalIsOpen: true })} />
            {nonCountData.length > 0 && <Badge variant="dot" className="moreInfoDot" color="error" badgeContent="" />}
          </div>

          {seriesData.length === 0 && (
            <div className="Outer">
              <div className="exclamation-chart-wrapper">
                <p>{intl.formatMessage({ id: 'Input-start-date' })}</p>
                <img src={getWebImageUrl('img/exclamation.png')} alt="exclamation" className="exclamation-chart" />
              </div>
            </div>
          )}
          <div className="Outer">
            <ReactEcharts
              option={option}
              notMerge={true}
              className={'react_for_echarts'}
              ref={node => {
                this.echartspie = node;
              }}
              // onEvents={this.onclick}
            />
            <FormControlLabel
              control={
                <Checkbox checked={!isSummaryGraph} onChange={this.handleChange} name="checkedB" color="primary" />
              }
              label={intl.formatMessage({ id: 'show-details' })}
            />
          </div>
        </div>

        <Dialog
          open={this.state.modalIsOpen}
          onClose={() => this.setState({ modalIsOpen: false })}
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="scroll-dialog-title" className="figureColor">
            {intl.formatMessage({ id: 'Life Coverage Summary' })}
          </DialogTitle>
          <DialogContent>
            {intl.formatMessage({ id: 'dashboard-drawer-explain-content' })}
            <br />
            <br />
            {nonCountData.length > 0 && <div>{intl.formatMessage({ id: 'non-count-data-description' })}</div>}
            {nonCountData &&
              nonCountData.map((data, index) => {
                return (
                  <div key={index}>
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: '5px',
                        borderRadius: '10px',
                        width: '9px',
                        height: '9px',
                        backgroundColor: `${data.color}`
                      }}
                    ></span>
                    {intl.formatMessage({ id: data.subCategoryName })}
                    {': '}
                    {data.name}
                  </div>
                );
              })}
          </DialogContent>

          <DialogActions style={{ justifyContent: 'flex-end' }}>
            <Button color="primary" onClick={() => this.setState({ modalIsOpen: false })}>
              {intl.formatMessage({ id: 'Close' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default LifeCoverageChart;
