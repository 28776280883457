import React, { Fragment, useState, useEffect, useRef } from 'react';
import WelcomeDialog from '../WelcomeDialog/WelcomeDialog';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ShortSubscriptions } from '../../utils/user-subscription';
import { pixelTracking, tracking } from '../../utils/tracking';
import { updateUserDetails } from '../../store/user/duck';
import { adviserAllModalDone } from '../../store/notificationCenter/duck';
import DateClientDialog from '../DateClientDialog/DateClientDialog';
import TargetModalPopup from '../../views/Advisor/Dashboard/partials/business/DashboardTargetModal/dashboardTargetModal';
import { injectIntl } from 'react-intl';
import SubscriptionExpiryReminderDialog from '../SubscriptionComponents/SubscriptionExpiryReminderDialog/SubscriptionExpiryReminderDialog';
import ShowThankYouModal from '../ShowThankYouModal/ShowThankYouModal';
import TeamNewsDialog from '../TeamNewsDialog/TeamNewsDialog';
import { usePrevious } from '../../utils/hooks';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import { changeAppIcon } from '../../utils/native/icon';
import NativeOrWeb from '../../utils/native';
import AppIconChangingDialog from './AppIconChangingDialog/AppIconChangingDialog';

const AdviserLoginLogic = props => {
  const {
    intl,
    history,
    location,
    userDetails,
    subscriptions,
    adviserBusiness,
    updateUserDetails,
    adviserAllModalDone,
    onCloseAllDialogs
  } = props;

  const [welcomeDialog, setWelcomeDialog] = useState(undefined);
  const [targetDialog, setTargetDialog] = useState(undefined);
  const [dateClientDialog, setDateClientDialog] = useState(undefined);
  const [teamNewsDialog, setTeamNewsDialog] = useState(undefined);

  const closeWelcomeDialog = () => {
    setWelcomeDialog(false);

    updateUserDetails({ welcomeMessage: false });

    // FB Pixel tracking adviser starting free trial (welcome dialog will only be shown for first time)
    if (userDetails.emailVerified && new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial()) {
      // pixelTracking('StartTrial');
    }
    adviserAllModalDone();
    setDateClientDialog(true);
  };

  const closeTargetDialog = () => {
    setTargetDialog(false);
    adviserAllModalDone();
    // setDateClientDialog(true);
  };

  const closeDateClientDialog = () => {
    // Tracking
    tracking('Q4 Prospecting Series Modal: Continue to operate as usual clicked');
    setDateClientDialog(false);
  };

  const closeTeamNewsDialog = () => setTeamNewsDialog(false);

  useEffect(() => {
    if (history.action === 'PUSH') {
      const locationState = _.get(history, 'location.state');
      if (locationState) {
        const { welcomeMessage, remindTarget, teamNewsUrls } = locationState;
        setWelcomeDialog(!!welcomeMessage);
        setDateClientDialog(false);
        setTargetDialog(!!remindTarget);
        setTeamNewsDialog(
          teamNewsUrls && Array.isArray(teamNewsUrls) && teamNewsUrls.length > 0 ? teamNewsUrls : false
        );

        if (welcomeMessage || remindTarget || teamNewsUrls) {
          setTimeout(() => {
            if (history) {
              history.replace({
                ...history.location,
                state: {
                  ..._.omit(history.location.state, ['welcomeMessage', 'remindTarget', 'teamNewsUrls'])
                }
              });
            }
          }, 500);
        }
      }
    }
  }, [history]);

  useEffect(() => {
    if (!welcomeDialog && !targetDialog) {
      adviserAllModalDone();
    }
  }, [welcomeDialog, targetDialog, adviserAllModalDone]);

  useEffect(() => {
    if (welcomeDialog === false && targetDialog === false && dateClientDialog === false && teamNewsDialog === false) {
      if (onCloseAllDialogs) {
        onCloseAllDialogs();
      }
    }
  }, [dateClientDialog, onCloseAllDialogs, targetDialog, teamNewsDialog, welcomeDialog]);

  return (
    <Fragment>
      <AppIconChangingDialog />
      <WelcomeDialog open={welcomeDialog} onGo={closeWelcomeDialog} type="adviser" />
      <DateClientDialog open={dateClientDialog} onClose={closeDateClientDialog} />
      <TargetModalPopup
        open={targetDialog}
        onClose={closeTargetDialog}
        intl={intl}
        adviserBusiness={adviserBusiness}
        userDetails={userDetails}
      />
      <TeamNewsDialog open={!!teamNewsDialog} onClose={closeTeamNewsDialog} teamNewsUrls={teamNewsDialog} />
      {/*<TeamNewsDialog open={true} onClose={closeTeamNewsDialog} teamNewsUrls={['https://static.portfoplus.com/img/teams/cyOD-Group/OD-migration.jpg', 'https://static.portfoplus.com/img/teams/demo/temp.png']} />*/}
      <SubscriptionExpiryReminderDialog history={history} />
      <ShowThankYouModal
        unreadThankYous={location.state && location.state.unreadThankYous ? location.state.unreadThankYous : []}
      />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    adviserBusiness: state.adviserDashboard.adviserBusiness
  }),
  {
    updateUserDetails,
    adviserAllModalDone
  }
)(AdviserLoginLogic);

export default injectIntl(withRouter(container));
