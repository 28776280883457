/***********************************
 * Action Types
 ***********/
export const UPDATE_COVID_DATA = 'covid/UPDATE_COVID_DATA';

/***********************************
 * Initial State
 ***********/
let initialState = {
  data: undefined
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_COVID_DATA:
      return { data: action.payload };
    default:
      return state;
  }
}
