import { connect } from 'react-redux';
import { getPolicyList, updateReminder } from '../../store/reminder/duck';
import ReminderView from './component';
import { handleSignOut } from '../../store/user/duck';
import { getReminderBadgeCount } from '../../store/reminder/duck';
import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';

const Reminder = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    reminderMessage: state.reminder.reminderMessage,
    addReminderPhase: state.reminder.addReminderPhase,
    getReminderPhase: state.reminder.getReminderPhase,
    getPolicyPhase: state.reminder.getPolicyPhase,
    policyData: state.reminder.policyData,
    newReferralCount: state.referral.newReferralCount,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    subscriptionInitialFetched: state.userSubscription.initialFetched
  }),
  // Map actions to props
  {
    getPolicyList,
    updateReminder,
    handleSignOut,
    renderHeaderButton,
    getReminderBadgeCount
  }
)(ReminderView);

export default injectIntl(Reminder);
