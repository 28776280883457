import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import { Button, Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CreateIcon from '@material-ui/icons/Create';
import { formatOnlyNumber, formatOnlyUnit } from '../../../../../../utils/formatNumber';

class QuarterTargetCard extends PureComponent {
  render() {
    const { intl, openModal, quarterTarget, weeklyAppt } = this.props;

    return (
      <Card className="progress-card" id="quarter-card">
        <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
          <Grid item className="progress-card-title">
            {intl.formatMessage({ id: 'Quarter Target' })}
          </Grid>
          <Grid item className="progress-card-title">
            {intl.formatMessage({ id: 'Weekly Appt' })}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              className="edit-button edit-info-button"
              onClick={openModal}
              style={{ float: 'right' }}
            >
              <CreateIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={0}
          style={{ padding: '10px 0px 8px 0px' }}
        >
          <Grid item xs={6} style={{ color: '#3966f8' }}>
            <span className="progress-card-number">{formatOnlyNumber(quarterTarget, intl)} </span>
            <span className="progress-card-unit">{quarterTarget ? formatOnlyUnit(quarterTarget, intl) : ''}</span>
          </Grid>

          <Grid item xs={4} className="progress-card-number" style={{ color: '#00000061' }}>
            {weeklyAppt}
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default injectIntl(QuarterTargetCard);
