import Rx from 'rxjs/Rx';
import { combineEpics } from 'redux-observable';
import * as api from './api';
import { UPDATE_ADVISER_CUSTOM_FILTERS } from '../user/duck';

export const UPDATE_ADVISER_FILTERS = 'portfoplus/advisor/UPDATE_ADVISER_FILTERS';
export const UPDATE_ADVISER_FILTERS_SUCCESS = 'portfoplus/advisor/UPDATE_ADVISER_FILTERS_SUCCESS';
export const UPDATE_ADVISER_FILTERS_ERROR = 'portfoplus/advisor/UPDATE_ADVISER_FILTERS_ERROR';

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = { error: null, submitting: false }, action = {}) {
  switch (action.type) {
    case UPDATE_ADVISER_FILTERS: {
      return { ...state, submitting: true, error: null };
    }
    case UPDATE_ADVISER_FILTERS_SUCCESS: {
      if (!action.payload.success) {
        return { ...state, error: action.payload.message, submitting: false };
      }
      return { ...state, submitting: false, error: false };
    }

    case UPDATE_ADVISER_FILTERS_ERROR: {
      const { payload } = action;
      return { ...state, error: payload.error, submitting: false };
    }

    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const updateAdviserCustomFilters = data => {
  return { type: UPDATE_ADVISER_FILTERS, payload: data };
};

/***********************************
 * Epics
 ***********/
const updateAdviserCustomFiltersEpic = action$ =>
  action$.ofType(UPDATE_ADVISER_FILTERS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateAdviserCustomFilters(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_ADVISER_CUSTOM_FILTERS,
                payload
              },
              {
                type: UPDATE_ADVISER_FILTERS_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: UPDATE_ADVISER_FILTERS_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_ADVISER_FILTERS_ERROR,
          payload: { error }
        })
      );
  });

export const adviserCustomFiltersEpics = combineEpics(updateAdviserCustomFiltersEpic);
