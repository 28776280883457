import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { toggleDialog } from '../../../store/control/duck';
import { SlideTransition } from '../../Transition/Transition';
import { withRouter } from 'react-router';
import { setShouldShowGPTDisclaimer } from '../../../utils/openai';

const ChatGPTDisclaimerDialog = props => {
  const { intl, history, chatGPTDisclaimerDialog, toggleDialog } = props;

  const { closed } = chatGPTDisclaimerDialog || {};

  const isOpen = !!chatGPTDisclaimerDialog && !closed;

  const onClose = () => {
    toggleDialog('chatGPTDisclaimer', { ...chatGPTDisclaimerDialog, closed: true });
  };

  const onExited = () => {
    toggleDialog('chatGPTDisclaimer', false);
  };

  const onSubmit = () => {
    setShouldShowGPTDisclaimer();
    onClose();
    history.push('/gpt-options');
  };

  return (
    <Dialog
      className="chat-gpt-disclaimer-dialog"
      open={isOpen}
      fullWidth={true}
      onClose={onClose}
      onExited={onExited}
      TransitionComponent={SlideTransition}
    >
      <DialogTitle>{intl.formatMessage({ id: 'chat-gpt-disclaimer-dialog-title' })}</DialogTitle>
      <DialogContent
        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'chat-gpt-disclaimer-dialog-content' }) }}
        style={{ whiteSpace: 'pre-wrap' }}
      />
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onSubmit}>
          {intl.formatMessage({ id: 'agree' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    chatGPTDisclaimerDialog: state.control.chatGPTDisclaimerDialog
  }),
  {
    toggleDialog
  }
)(ChatGPTDisclaimerDialog);

export default injectIntl(withRouter(container));
