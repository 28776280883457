import React from 'react';
import { injectIntl } from 'react-intl';
import { getSearchObject } from '../../../utils/router';
import { Redirect } from 'react-router';

const WHITEBOARD_URL = process.env.WHITEBOARD_URL;
const ACCESS_TOKEN = process.env.WHITEBOARD_ACCESS_TOKEN;

const WhiteboardRemote = props => {
  const { history } = props;

  const searchObj = getSearchObject(history);
  const { whiteboardid, username } = searchObj;

  const src = `${WHITEBOARD_URL}?accesstoken=${ACCESS_TOKEN}&whiteboardid=${whiteboardid}&username=${username}`;

  return searchObj && username ? (
    <div>
      <iframe
        title="Remote Whiteboard"
        src={src}
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          border: 'none'
        }}
      />
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default injectIntl(WhiteboardRemote);
