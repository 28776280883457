import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';

const VerifyDialog = props => {
  const { intl, name, email, open, onLogout, onCancel, onSubmit, ...rest } = props;

  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onChange = event => setPassword(event.target.value);
  const formattedOnSubmit = event => {
    event.preventDefault();
    if (onSubmit) {
      setLoading(true);
      onSubmit(password);
    }
  };

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} maxWidth="xs" fullWidth={true} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'cap-login-verify-dialog-title' }, { name: name || email })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <form onSubmit={formattedOnSubmit}>
            <TextField
              type="password"
              label={intl.formatMessage({ id: 'Password' })}
              value={password}
              disabled={loading}
              onChange={onChange}
              inputProps={{
                style: {
                  boxSizing: 'content-box',
                  padding: '6px 0 7px'
                }
              }}
            />
          </form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <Button color="primary" onClick={onLogout}>
              {intl.formatMessage({ id: 'Logout' })}
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={onCancel}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button color="primary" onClick={formattedOnSubmit}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(VerifyDialog);
