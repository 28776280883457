import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import './PresentModeSwitch.scss';

const PresentModeSwitch = props => {
  const { label, checked, onChange } = props;

  return (
    <FormControlLabel
      className="present-mode-switch"
      control={<Switch color="primary" checked={checked} onChange={onChange} />}
      label={label}
      labelPlacement="start"
      style={{ margin: 0 }}
    />
  );
};

export default PresentModeSwitch;
