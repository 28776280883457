import { ERROR, INIT, LOADING } from '../../constants/phase';
import produce from 'immer';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const RESET_CHEQUE_WIZARD = 'RESET_CHEQUE_WIZARD';
export const GET_CHEQUE_WIZARD_PENDING = 'GET_CHEQUE_WIZARD_PENDING';
export const GET_CHEQUE_WIZARD_SUCCESS = 'GET_CHEQUE_WIZARD_SUCCESS';
export const GET_CHEQUE_WIZARD_ERROR = 'GET_CHEQUE_WIZARD_ERROR';
export const UPDATE_CHEQUE_WIZARD_PENDING = 'UPDATE_CHEQUE_WIZARD_PENDING';
export const UPDATE_CHEQUE_WIZARD_SUCCESS = 'UPDATE_CHEQUE_WIZARD_SUCCESS';
export const UPDATE_CHEQUE_WIZARD_ERROR = 'UPDATE_CHEQUE_WIZARD_ERROR';

/***********************************
 * Initial State
 ***********/
const initialState = {
  chequeWizard: undefined,
  getPhase: INIT,
  updatePhase: INIT,
  error: undefined
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    const { success, chequeWizard, error } = action;

    switch (action.type) {
      case RESET_CHEQUE_WIZARD:
        draft = initialState;
        break;
      case GET_CHEQUE_WIZARD_PENDING:
        draft.getPhase = LOADING;
        break;
      case GET_CHEQUE_WIZARD_SUCCESS:
        draft.chequeWizard = chequeWizard;
        draft.getPhase = success;
        draft.error = undefined;
        break;
      case GET_CHEQUE_WIZARD_ERROR:
        draft.chequeWizard = undefined;
        draft.getPhase = ERROR;
        draft.error = error;
        break;
      case UPDATE_CHEQUE_WIZARD_PENDING:
        draft.updatePhase = LOADING;
        break;
      case UPDATE_CHEQUE_WIZARD_SUCCESS:
        draft.chequeWizard = chequeWizard;
        draft.updatePhase = success;
        draft.error = undefined;
        break;
      case UPDATE_CHEQUE_WIZARD_ERROR:
        draft.chequeWizard = undefined;
        draft.updatePhase = ERROR;
        draft.error = error;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getChequeWizard = () => async (dispatch, getState) => {
  dispatch({ type: GET_CHEQUE_WIZARD_PENDING });

  try {
    const response = await api.getChequeWizard();
    dispatch({ type: GET_CHEQUE_WIZARD_SUCCESS, success: response.success, chequeWizard: response.chequeWizard });
  } catch (error) {
    dispatch({ type: GET_CHEQUE_WIZARD_ERROR, error });
  }
};

export const updateChequeWizard = data => async (dispatch, getState) => {
  dispatch({ type: UPDATE_CHEQUE_WIZARD_PENDING });

  try {
    const response = await api.updateChequeWizard(data);
    dispatch({ type: UPDATE_CHEQUE_WIZARD_SUCCESS, success: response.success, chequeWizard: response.chequeWizard });
  } catch (error) {
    dispatch({ type: UPDATE_CHEQUE_WIZARD_ERROR, error });
  }
};

export const resetChequeWizard = () => ({ type: RESET_CHEQUE_WIZARD });
