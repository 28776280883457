import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ShareIcon from '@material-ui/icons/Share';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NativeOrWeb from '../../utils/native';
import { isAndroid, isIOS, isIPad13, isMobile } from 'react-device-detect';
import AladdinHint from '../AladdinHint/AladdinHint';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';

const ButtonStyle = {
  borderRadius: 4,
  background: 'white'
};
const HintStyle = { marginTop: '10px' };

const ShareButtons = props => {
  const {
    intl,
    whatsappNumber,
    title,
    url,
    text,
    isCTA,
    locked,
    isScript,
    onWhatsapp,
    onShare,
    imageUrl,
    imageCompression
  } = props;

  const sharable = NativeOrWeb.isSharable();

  const whatsapp = () => {
    if (locked) {
      locked();
      return;
    }

    if (onWhatsapp) {
      onWhatsapp();
    }

    const whatsappMessage = `${title}\n${url}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}${
      whatsappNumber ? `&phone=${whatsappNumber.replace('+', '')}` : ''
    }`;
    window.open(whatsappUrl, '_blank');
  };

  const share = () => {
    if (locked) {
      locked();
      return;
    }
    if (sharable) {
      if (onShare) {
        onShare();
      }

      NativeOrWeb.copyToClipboard(text)
        .then(() => toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' }))
        .catch();

      NativeOrWeb.share(
        isScript
          ? // Only share image for iOS
            isIOS && imageUrl
            ? { imageUrl, imageCompression }
            : { title, text, imageUrl, imageCompression }
          : { title: title, url: url }
      )
        .then()
        .catch();
    }
  };

  // show share button when sharable (both web and native)
  const showShare = sharable;

  // show whatsapp button as fallback when it is not sharable or somehow cannot share to Whatsapp (eg. iPad)
  const showWhatsapp = !isMobile || isIPad13 || !imageUrl || !sharable;

  // show paste hint (only image is shared and text is copied) when sharable and image is available
  const showPasteHint = sharable && imageUrl;

  return (
    <>
      <Grid container alignItems="center" justify="center" spacing={1}>
        {showWhatsapp && (
          <Grid item xs={showShare ? 6 : 12}>
            <Button
              variant={isCTA ? 'contained' : 'outlined'}
              color="primary"
              fullWidth={true}
              startIcon={locked ? <LockOutlinedIcon color="inherit" /> : <WhatsAppIcon color="inherit" />}
              style={{
                ...ButtonStyle,
                ...(locked ? { color: isCTA ? '#FFFFFF' : '#4F1271' } : {}),
                ...(isCTA ? { backgroundColor: locked ? '#4F1271' : '#3966f8' } : {})
              }}
              onClick={whatsapp}
            >
              {intl.formatMessage({ id: 'send' })}
            </Button>
          </Grid>
        )}
        {showShare && (
          <Grid item xs={showWhatsapp ? 6 : 12}>
            <Button
              variant={isCTA ? 'contained' : 'outlined'}
              color="primary"
              fullWidth={true}
              startIcon={locked ? <LockOutlinedIcon color="inherit" /> : <ShareIcon color="inherit" />}
              style={{
                ...ButtonStyle,
                ...(locked ? { color: isCTA ? '#FFFFFF' : '#4F1271' } : {}),
                ...(isCTA ? { backgroundColor: locked ? '#4F1271' : '#3966f8' } : {})
              }}
              onClick={share}
              disabled={!sharable}
            >
              {intl.formatMessage({ id: 'share-with-client' })}
            </Button>
          </Grid>
        )}
      </Grid>
      {showPasteHint && (
        <Grid style={HintStyle}>
          <AladdinHint
            title={intl.formatMessage({ id: 'share-paste-hint' })}
            helpCenter="c25"
            titleTypoProps={{ color: 'textSecondary', style: { fontSize: '100%', fontWeight: 'unset' } }}
          />
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ShareButtons);
