import React, { PureComponent } from 'react';
import moment from 'moment';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import MySwipeable from '../../../components/MySwipeable';
import countryList from '../../../store/locale/country-list';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { isUserConnected } from '../../../utils/user';
import SendVerificationEmailDialog from '../../../components/SendVerificationEmailDialog/SendVerificationEmailDialog';
import { getAgeFromDob, getDerivedAge } from '../../../utils/date';

class PersonalData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phoneNumber: '',
      phoneNumber2: '',
      wechatId: '',
      whatsapp: '',
      lineId: '',
      dob: '',
      age: '',
      gender: '',
      occupation: '',
      email: '',
      workEmail: '',
      file: [],
      image: [],
      err: {},
      modalIsOpen: false,
      userType: '',
      keyArray: [],
      Male: 'No',
      Female: 'No',
      openSendVerifyModal: false
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleGenderStatus = this.handleGenderStatus.bind(this);
  }

  componentDidMount() {
    var user = this.props.userDetails;
    this.setState({
      email: user.email ? user.email : '',
      userType: user.userType ? user.userType : ''
    });
    var localStorageData = JSON.parse(localStorage.getItem('personalData'));
    let personalData = localStorageData ? localStorageData : user;
    //saving the users data in there respective fields
    if (personalData) {
      this.setState({
        name: personalData.name ? personalData.name : '',
        workEmail: personalData.workEmail ? personalData.workEmail : '',
        phoneNumber: personalData.phoneNumber ? personalData.phoneNumber : '',
        phoneNumber2: personalData.phoneNumber2 ? personalData.phoneNumber2 : '',
        whatsapp: personalData.whatsapp ? personalData.whatsapp : '',
        wechatId: personalData.wechatId ? personalData.wechatId : '',
        lineId: personalData.lineId ? personalData.lineId : '',
        occupation: personalData.occupation ? personalData.occupation : '',

        /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
        dob:
          personalData.dob !== null && personalData.dob !== undefined && personalData.dob !== ''
            ? moment.parseZone(personalData.dob)
            : null,
        age: getAgeFromDob(personalData.dob, personalData.age) || '',
        gender: personalData.gender ? personalData.gender : ''
      });
      if (personalData.gender && personalData.gender === 'Male') {
        this.setState({ Male: 'Yes' });
      }
      if (personalData.gender && personalData.gender === 'Female') {
        this.setState({ Female: 'Yes' });
      }
    }
  }

  handleChange(e) {
    if (e.target.name === 'name') {
      delete this.state.err.name;
    }
    if (e.target.name === 'workEmail') {
      delete this.state.err.workEmail;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  handleNumberKeyDown(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
      e.preventDefault();
    }
  }

  //Handle the  date of birth
  handleDateChange(date) {
    this.setState({ dob: date });
    let years = date ? getDerivedAge(date) : '';
    this.setState({ age: years });
  }

  handleSwipe(direction) {
    if (direction === 'right') {
      this.submitForm();
    }
  }

  //Submit the data next page
  submitForm() {
    const err = {};
    if (this.state.name === '') {
      err.name = 'Name is required';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      var formData = {};
      formData.name = this.state.name;
      formData.occupation = this.state.occupation;
      formData.phoneNumber = this.state.phoneNumber ? this.state.phoneNumber : '';
      if (this.state.phoneNumber2 !== '') {
        formData.phoneNumber2 = this.state.phoneNumber2;
      }
      formData.whatsapp = this.state.whatsapp ? this.state.whatsapp : '';
      formData.age = parseInt(this.state.age) ? parseInt(this.state.age) : '';
      formData.wechatId = this.state.wechatId;
      formData.lineId = this.state.lineId;
      var date = this.state.dob ? moment(this.state.dob).format('YYYY-MM-DD') : '';
      let dob = date ? moment.utc(date).toISOString() : '';
      formData.dob = dob;
      formData.gender = this.state.gender;
      localStorage.setItem('personalData', JSON.stringify(formData));
      this.props.handleNextPage(1);
    }
  }

  openModal(index) {
    this.setState({ removeId: index, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  delete() {
    const index = this.state.removeId;
    const uploadImage = this.state.image;
    if (index > -1) {
      uploadImage.splice(index, 1);
    }
    this.setState({ modalIsOpen: false });
  }

  //Handle the status of gender
  handleGenderStatus(key) {
    const state = this.state.keyArray;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({
          Male: 'Yes',
          Female: 'No',
          gender: 'Male'
        });
      }
      if (key === 1) {
        this.setState({
          Male: 'No',
          Female: 'Yes',
          gender: 'Female'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Male !== 'No') {
          this.setState({ Male: 'No', gender: '' });
        } else {
          this.setState({
            Male: 'Yes',
            Female: 'No',
            gender: 'Male'
          });
        }
      }
      if (key === 1) {
        if (this.state.Female !== 'No') {
          this.setState({ Female: 'No', gender: '' });
        } else {
          this.setState({
            Male: 'No',
            Female: 'Yes',
            gender: 'Female'
          });
        }
      }
    }
  }

  render() {
    const { intl, history } = this.props;
    let user = this.props.userDetails;

    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <MySwipeable handleSwipe={this.handleSwipe}>
                  <div className="col-lg-12">
                    <form id="msform" className="formPager">
                      <ul id="progressbar">
                        <li className="active" />
                        <li />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'My Data' })}</h2>

                        <div className="row">
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Name' })}*
                              </label>
                              <i className="bar" />
                              {this.state.err.name ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.name
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-xs-6">
                            <div className="select-buttons-inline-form-control">
                              {/* <div className="control-orphay select-buttons-inline-label">
                          {intl.formatMessage({ id: 'Gender' })}
                        </div>*/}
                              <div className="select-buttons-inline-div">
                                <Button
                                  variant={this.state.Male === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.Male === 'Yes'
                                      ? 'plan-type-select-button selected'
                                      : 'plan-type-select-button'
                                  }
                                  key={0}
                                  onClick={() => this.handleGenderStatus(0)}
                                >
                                  {intl.formatMessage({ id: 'Male' })}
                                </Button>

                                <Button
                                  variant={this.state.Female === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.Female === 'Yes'
                                      ? 'plan-type-select-button selected'
                                      : 'plan-type-select-button'
                                  }
                                  key={1}
                                  onClick={() => this.handleGenderStatus(1)}
                                >
                                  {intl.formatMessage({ id: 'Female' })}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <div className="form-group">
                              <input
                                type="text"
                                value={this.state.email}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Login Email' })}*
                              </label>
                              <i className="bar" style={{ marginBottom: 2 }} />
                              {user.userType === 'User' && !isUserConnected(user) && !user.emailVerified ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: 'user-email-not-verified'
                                  })}
                                  <Button
                                    variant="contained"
                                    style={{
                                      background: '#3966f8',
                                      color: 'white',
                                      margin: '3px 5px 5px 5px',
                                      height: 25
                                    }}
                                    onClick={() => this.setState({ openSendVerifyModal: true })}
                                  >
                                    {intl.formatMessage({
                                      id: 'Verify Email'
                                    })}
                                  </Button>
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <div className="form-group phone_module clearfix">
                              <label className=" phone-module-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Tel' })}
                              </label>
                              <Phone
                                international={true}
                                country="HK"
                                // inputComponent={SmartInput}
                                placeholder={intl.formatMessage({
                                  id: 'Enter phone number'
                                })}
                                value={this.state.phoneNumber}
                                onChange={value => this.setState({ phoneNumber: value })}
                                indicateInvalid
                                //labels={countryList.labels}
                                countries={countryList.labels}
                                flagsPath={'img/flags/'}
                              />
                              <i className="bar" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <div className="form-group phone_module clearfix">
                              <label className=" phone-module-label" htmlFor="input">
                                {intl.formatMessage({ id: 'WhatsApp' })}
                              </label>
                              <Phone
                                international={true}
                                country="HK"
                                // inputComponent={SmartInput}
                                placeholder={intl.formatMessage({
                                  id: 'Enter whatsapp number'
                                })}
                                value={this.state.whatsapp}
                                onChange={value => this.setState({ whatsapp: value })}
                                indicateInvalid
                                //labels={countryList.labels}
                                countries={countryList.labels}
                                flagsPath={'img/flags/'}
                              />
                              <i className="bar" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="wechatId"
                                value={this.state.wechatId}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'WeChat ID' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>

                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="lineId"
                                value={this.state.lineId}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Line ID' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        </div>

                        {user.userType === 'User' && (
                          <div className="form-group">
                            <input
                              type="text"
                              name="occupation"
                              value={this.state.occupation}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Occupation' })}
                            </label>
                            <i className="bar" />
                          </div>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.submitForm}
                          endIcon={<ArrowForwardIosIcon />}
                          className="prevNextButton"
                        >
                          {intl.formatMessage({ id: 'Next' })}
                        </Button>
                      </fieldset>
                    </form>
                    <SendVerificationEmailDialog
                      history={history}
                      onClose={() => this.setState({ openSendVerifyModal: false })}
                      open={this.state.openSendVerifyModal}
                    />

                    <Dialog
                      open={this.state.modalIsOpen}
                      onClose={this.closeModal}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      TransitionComponent={SlideTransition}
                      fullWidth={true}
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {intl.formatMessage({ id: 'Remove Picture' })}
                      </DialogTitle>
                      <DialogContent>
                        {intl.formatMessage({
                          id: 'Are you sure? You want to remove this picture.'
                        })}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                          {intl.formatMessage({ id: 'Cancel' })}
                        </Button>
                        <Button onClick={this.delete.bind(this)} color="primary" autoFocus>
                          {intl.formatMessage({ id: 'OK' })}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </MySwipeable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonalData;
