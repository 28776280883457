import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { updateUserDetails } from '../../../../store/user/duck';
import { updateAnalysisInputs, updateCISelectedPolicies } from '../../../../store/analysis/duck';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';
import { getCITopUpMax, getCITopUpOptions, getScoreColor } from '../../../../utils/analysis';
import PolicyPickerInfo from '../PolicyPickerInfo/PolicyPickerInfo';
import _ from 'lodash';
import PolicyPickerButton from '../PolicyPickerButton/PolicyPickerButton';
import { isInsuredNotMePolicy, isPolicyCategory, isPolicySubCategory } from '../../../../utils/policy';
import CustomInputSelect from '../CustomInputSelect/CustomInputSelect';
import { CI_CLAIM_COVER_EXPENSES_OPTIONS, INCOME_OPTIONS } from '../../../../constants/analysis';
import { Button, Grid } from '@material-ui/core';
import AnalysisInputPanel from '../AnalysisInputPanel';
import { getUserCurrency } from '../../../../utils/user';
import Typography from '@material-ui/core/Typography';
import FigureUnitTypo from '../../../../components/FigureUnitTypo/FigureUnitTypo';
import InputPanelSlider from '../InputPanelSlider/InputPanelSlider';
import { getCIToReachData } from '../../../../utils/analysisCalculator';

const IncomeDivStyle = {
  borderLeft: '3px solid #3EDA7D',
  paddingLeft: '6px',
  marginLeft: '7px'
};

const PICKER_FILTER = policy =>
  (isPolicyCategory(policy, 'personal') ||
    (isPolicyCategory(policy, 'general') && isPolicySubCategory(policy, 'saving')) ||
    (isPolicyCategory(policy, 'general') && isPolicySubCategory(policy, 'provident')) ||
    (isPolicyCategory(policy, 'company'))
  ) &&
  !isInsuredNotMePolicy(policy);

const CIInputPanel = forwardRef((props, ref) => {
  const {
    intl,
    userDetails,
    ciData,
    monthlyIncome,
    ciTopUp,
    ciClaimCoverExpenses,
    ciTotalCoverage,
    ciSelectedPolicies,
    updateUserDetails,
    updateCISelectedPolicies,
    updateAnalysisInputs,
    policies
  } = props;

  const stickyInputRef = useRef(null);

  const clientId = JSON.parse(localStorage.getItem('clientId'));

  const currency = getUserCurrency(userDetails);
  const ciTopUpOptions = getCITopUpOptions(monthlyIncome, ciClaimCoverExpenses);

  const currencyIntl = intl.formatMessage({ id: currency });

  const slideTopUp = (event, value) => updateAnalysisInputs({ ciTopUp: value });
  const submitPolicies = policies => {
    updateCISelectedPolicies(policies);
    if (clientId) {
      let selectedPolicies = JSON.parse(sessionStorage.getItem("selectedPolicies") || "{}");
      if (selectedPolicies[clientId]) {
        selectedPolicies[clientId]["cISelectedPolicies"] = policies.map(policy => policy._id);
      } else {
        selectedPolicies[clientId] = { "cISelectedPolicies": policies.map(policy => policy._id) };
      }
      sessionStorage.setItem("selectedPolicies", JSON.stringify(selectedPolicies));
    }
  }
  const changeMonthlyIncome = event => {
    if (event.target.value !== monthlyIncome) {
      const updates = { monthlyIncome: event.target.value };

      if (!clientId) {
        updateUserDetails(updates);
      }

      updateAnalysisInputs(updates);
    }
  };
  const changeClaimCoverExpenses = event => {
    if (event.target.value !== ciClaimCoverExpenses) {
      updateAnalysisInputs({ ciClaimCoverExpenses: event.target.value });
    }
  };
  const changeTopUp = event => {
    if (event.target.value !== ciTopUp) {
      updateAnalysisInputs({ ciTopUp: event.target.value });
    }
  };
  const open = () => {
    if (stickyInputRef && stickyInputRef.current) {
      stickyInputRef.current.openInput();
    }
  };
  const close = () => {
    if (stickyInputRef && stickyInputRef.current) {
      stickyInputRef.current.closeInput();
    }
  };

  useImperativeHandle(ref, () => ({
    open: open,
    close: close
  }));

  useEffect(() => {
    let selectedPolicies = JSON.parse(sessionStorage.getItem("selectedPolicies") || "{}");
    if (selectedPolicies[clientId] && selectedPolicies[clientId]["cISelectedPolicies"]) {
      let newPolicies = policies.filter(policy => selectedPolicies[clientId]["cISelectedPolicies"].includes(policy._id));
      updateCISelectedPolicies(newPolicies);
    }
  }, [policies]);

  return (
    <AnalysisInputPanel
      className="ci-input-panel analysis-input-panel client-dash-analysis client-analysis-top-bar"
      ref={stickyInputRef}
      inputTitle={
        <Grid container direction="column">
          <Grid item>
            <div style={{ fontSize: 18 }}>
              {`${intl.formatMessage({ id: 'Personal C.I.' })}: `}
              <span className="figureColor">{`${currencyIntl} ${decimalToString(ciTotalCoverage)}`}</span>
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'analysis-existing' })}</Typography>
              </Grid>
              <Grid item>
                <FigureUnitTypo {...formatNumber(ciTotalCoverage - ciTopUp, intl, true)} sameFontSize={true} />
              </Grid>
              <Grid item>
                <Typography>+</Typography>
              </Grid>
              <Grid item>
                <FigureUnitTypo {...formatNumber(ciTopUp, intl, true)} sameFontSize={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      slider={
        <InputPanelSlider
          min={0}
          max={getCITopUpMax(monthlyIncome, ciClaimCoverExpenses)}
          steps={100}
          value={ciTopUp}
          onChangeCommitted={slideTopUp}
          stopPropagation={true}
          getTrackColor={internalValue => {
            const { score } = getCIToReachData(
              internalValue + (ciTotalCoverage - ciTopUp),
              monthlyIncome,
              ciClaimCoverExpenses,
              currency
            );
            return getScoreColor(score);
          }}
          valueLabelFormat={decimalToString}
        />
      }
      detailComponents={
        <div className="analysis-input-groups">
          <PolicyPickerInfo
            figures={[
              {
                value: {
                  value: _.get(ciData, 'count', 0),
                  unit: intl.formatMessage({ id: 'analy-picker-count-unit' })
                },
                label: intl.formatMessage({ id: 'analy-picker-count-label' })
              },
              {
                value: formatNumber(_.get(ciData, 'totalCICoverage', 0), intl, true),
                label: intl.formatMessage({ id: 'total-coverage' })
              },
              {
                value: formatNumber(_.get(ciData, 'totalMonthlyPremium', 0), intl, true),
                label: intl.formatMessage({ id: 'Monthly Premium' })
              }
            ]}
            button={
              <PolicyPickerButton
                selectedPolicies={ciSelectedPolicies}
                filter={PICKER_FILTER}
                onSubmit={submitPolicies}
              />
            }
          />

          <div style={IncomeDivStyle}>
            <CustomInputSelect
              className="analysis-input"
              label={intl.formatMessage({ id: 'Monthly income' })}
              onChange={changeMonthlyIncome}
              value={monthlyIncome}
              startAdornmentLabel={currencyIntl}
              selectOptions={INCOME_OPTIONS}
              isDollarValue={true}
              allowCustomValue={true}
              min={0}
              max={INCOME_OPTIONS[INCOME_OPTIONS.length - 1]}
            />

            <CustomInputSelect
              className="analysis-input"
              label={intl.formatMessage({ id: 'claim-cover-expenses' })}
              onChange={changeClaimCoverExpenses}
              value={ciClaimCoverExpenses}
              endAdornmentLabel={intl.formatMessage({ id: 'Yrs' })}
              selectOptions={CI_CLAIM_COVER_EXPENSES_OPTIONS}
              allowCustomValue={true}
              decimalPlaces={1}
              min={0}
              max={19}
            />
          </div>
          <div>
            <CustomInputSelect
              className="analysis-input analysis-input-top-up"
              label={intl.formatMessage({ id: 'Top up protection amount' })}
              onChange={changeTopUp}
              value={ciTopUp}
              startAdornmentLabel={currencyIntl}
              selectOptions={ciTopUpOptions}
              isDollarValue={true}
              allowCustomValue={true}
              min={0}
              max={ciTopUpOptions[ciTopUpOptions.length - 1]}
            />

            <div className="analysis-input analysis-input-go">
              <Button variant="contained" onClick={close}>
                {intl.formatMessage({ id: 'Go' })}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
});

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    ciData: state.analysis.ciData,
    ciSelectedPolicies: state.analysis.ciSelectedPolicies,
    monthlyIncome: state.analysis.monthlyIncome,
    ciTopUp: state.analysis.ciTopUp,
    ciClaimCoverExpenses: state.analysis.ciClaimCoverExpenses,
    ciTotalCoverage: state.analysis.ciTotalCoverage,
    policies: state.policyPicker.policies
  }),
  {
    updateUserDetails,
    updateAnalysisInputs,
    updateCISelectedPolicies
  },
  null,
  {
    forwardRef: true
  }
)(CIInputPanel);

export default injectIntl(container, { withRef: true });
