import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import IconToast from '../../components/NewToast';
import RegisterDetails from './contents/RegisterDetails';
import PaymentSuccess from './contents/PaymentSuccess';
import EmailRegisteredDialog from './contents/EmailRegisteredDialog/EmailRegisteredDialog';
import ReferSignUpBanner from './contents/ReferSignUpBanner/ReferSignUpBanner';
import ConfirmAmountDialog from '../../components/SubscriptionComponents/ConfirmAmountDialog/ConfirmAmountDialog';
import LoginBannerContainer from '../../components/LoginBannerContainer/LoginBannerContainer';
import FreeTrialExpiredDialog from '../../components/SubscriptionComponents/FreeTrialExpiredDialog/FreeTrialExpiredDialog';
import Pricing from '../../components/SubscriptionComponents/Pricing/Pricing';
import { TotalAmountView } from '../../components/SubscriptionComponents/TotalAmountView/TotalAmountView';
import PaymentChannels from '../../components/SubscriptionComponents/PaymentChannels/PaymentChannels';

import { INIT, LOADING, SUCCESS } from '../../constants/phase';
import { createUser, handleSignOut, resetUserStore, updateUserDetails } from '../../store/user/duck';
import { createFreeSubscriptionApi } from '../../store/stripe/api';
import {
  createSubscription,
  getExclusiveCoupons,
  getTeamInvitationExtension,
  getUserSubscription,
  removeUnpaidSubscription,
  resetUserSubscription,
  updateKeepShowingNewSignUp
} from '../../store/userSubscription/duck';
import { getSubscriptionPlanExpiryDate, ShortSubscriptions } from '../../utils/user-subscription';
import { validateEmail, validateName, validatePassword, validatePasswordConfirm, validatePhoneNumber } from '../../utils/validators';
import { ga4Tracking, ga4TrackPayment } from '../../utils/tracking';
import LoginStyles from '../Login/LoginStyles';
import { selectedLocale } from '../../store/locale/action';
import { switchLanguage } from '../../utils/locale';
import { formatCampaignCode, hasReferralCode } from '../../utils/user';
import { formatEmail, isEmailValid } from '../../utils/email';
import { getSearchObject } from '../../utils/router';

import './NewSignUp.scss';
import './NewSignUpComponents.scss';

const getFormattedStep = (step, withRegister) => (withRegister ? step : step - 1);

const NewSignUp = (props) => {
  const {
    intl, isAuthenticated, userDetails, createPhase, createMessage, createMessageExtras,
    updatePhase, subscriptions, exclusiveCouponsPhase, exclusiveCoupons, withRegister,
    pageLayout, logoutButton, handleSignOut, onPaymentCompleted, updateKeepShowingNewSignUp,
    selectedLocale, createUser, updateUserDetails, getUserSubscription, resetUserSubscription,
    resetUserStore, getExclusiveCoupons, getTeamInvitationExtension, getTeamInvitationExtensionPhase,
    stripePromise, classes, history, location
  } = props;

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProps = usePrevious(props);

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmedAmount, setConfirmedAmount] = useState(false);
  const [confirmAmountDialog, setConfirmAmountDialog] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [workEmail, setWorkEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [wechatId, setWechatId] = useState('');
  const [lineId, setLineId] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [couponId, setCouponId] = useState('');
  const [coupon, setCoupon] = useState(undefined);
  const [couponValue, setCouponValue] = useState(0);
  const [remark, setRemark] = useState('');
  const [paymentChannel, setPaymentChannel] = useState('stripe');
  const [errors, setErrors] = useState({});
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [registeredDialog, setRegisteredDialog] = useState(false);
  const [campaignCode, setCampaignCode] = useState('');
  const [campaignCodeDisabled, setCampaignCodeDisabled] = useState(false);
  const [planMode, setPlanMode] = useState(undefined);
  const [aid, setAid] = useState(undefined);
  const [ti, setTi] = useState(undefined);
  const [tacChecked, setTacChecked] = useState(true);
  const [dmChecked, setDmChecked] = useState(true);
  const [adviserReferral, setAdviserReferral] = useState(false);
  const [language, setLanguage] = useState(userDetails?.language || location.state?.language || 'zh-Hant-HK');

  const processSearchParams = () => {
    const searchObject = getSearchObject(history);
    const updates = {};

    if (searchObject.campaignCode) {
      const campaignCodes = userDetails?.campaignCodes || [];
      const isEmailCampaignCode = isEmailValid(searchObject.campaignCode);
      if (isEmailCampaignCode) {
        if (!hasReferralCode(userDetails, searchObject.campaignCode)) {
          const oldReferralCode = campaignCodes.find(code => code.isReferralCode);
          if (oldReferralCode) {
            const newCampaignCodes = campaignCodes.filter(code => !code.isReferralCode);
            newCampaignCodes.push({ text: formatCampaignCode(searchObject.campaignCode), isReferralCode: true });
            newCampaignCodes.push({ text: oldReferralCode.text });
            updates.campaignCodes = newCampaignCodes;
          } else {
            updates.campaignCodes = [...campaignCodes, { text: searchObject.campaignCode, isReferralCode: true }];
          }
        }
      }
    }

    if (searchObject.ti) {
      if (!userDetails?.teamInvitationId || userDetails.teamInvitationId !== searchObject.ti) {
        updates.teamInvitationId = searchObject.ti;
        updates.teamInvitation = true;
      }
    }

    if (Object.keys(updates).length > 0) {
      updateUserDetails(updates);
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === 'language') {
      switchLanguage(selectedLocale, value);
      setLanguage(value);
      history.push({ pathname: history.location.pathname, state: { language: value } });
    } else if (name === 'campaignCode') {
      setCampaignCode(value ? value.toUpperCase() : value);
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));

      switch (name) {
        case 'name':
          setName(value);
          break;
        case 'email':
          setEmail(value);
          break;
        case 'phoneNumber':
          setPhoneNumber(value);
          break;
        case 'password':
          setPassword(value);
          break;
        case 'confirmPassword':
          setConfirmPassword(value);
          break;
        case 'workEmail':
          setWorkEmail(value);
          break;
        default:
          break;
      }
    }
  };

  const handleCheck = event => {
    const { name, checked } = event.target;
    setErrors(prevErrors => ({ ...prevErrors, [name]: checked ? '' : prevErrors[name] }));

    switch (name) {
      case 'whatsapp':
        setWhatsapp(checked);
        break;
      case 'wechatId':
        setWechatId(checked);
        break;
      case 'lineId':
        setLineId(checked);
        break;
      case 'tacChecked':
        setTacChecked(checked);
      case 'dmChecked':
        setDmChecked(checked);
      default:
        break;
    }
  };

  const handleEmailUsed = () => {
    ga4Tracking('email_check', {
      email: email, user_type: 'Adviser', plan_mode: planMode,
      coupon_code: campaignCode, team: userDetails.teamInvitationId,
      email_check_status: 'rejected', err: 'existing_user', err_msg: 'An account with this email address already exists'
    });
    setRegisteredDialog(true);
  };

  const handleEmailAccepted = () => {
    ga4Tracking('email_check', {
      email: email, user_type: 'Adviser', plan_mode: planMode,
      coupon_code: campaignCode, team: userDetails.teamInvitationId,
      email_check_status: 'accepted'
    });
  };

  const trackPayment = (channel, status, details) => {
    const props = {
      ...details,
      channel: channel,
      status: status,
      amount: selectedPlan.amount,
      campaignCode: campaignCode,
      planMode: planMode,
      couponCode: couponId,
      plan_nickname: selectedPlan.nickname,
      trial_mode: selectedPlan.isFreeTrial ? 'free_trial' : selectedPlan.isTrial ? 'trial' : null,
    }
    ga4TrackPayment(props);
    // console.log('track ga4TrackPayment: ', props);
  }

  const validatePageOne = () => {
    const tempErrors = {};
    const validateEmailResult = validateEmail(email);
    if (validateEmailResult) {
      tempErrors.email = intl.formatMessage({ id: validateEmailResult });
    }
    if (workEmail && !isEmailValid(workEmail)) {
      tempErrors.workEmail = intl.formatMessage({ id: 'Invalid email' });
    }
    return tempErrors;
  };

  const handleValidate = () => {
    const tempErrors = validatePageOne();
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleCreate = () => {
    const tempErrors = validatePageOne();

    const validateNameResult = validateName(name);
    if (validateNameResult) {
      tempErrors.name = intl.formatMessage({ id: validateNameResult });
    }

    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult) {
      tempErrors.password = intl.formatMessage({ id: passwordValidationResult });
    }

    const passwordConfirmValidationResult = validatePasswordConfirm(confirmPassword, password);
    if (passwordConfirmValidationResult) {
      tempErrors.confirmPassword = intl.formatMessage({ id: passwordConfirmValidationResult });
    }

    const phoneNumberValidationResult = validatePhoneNumber(phoneNumber);
    if (phoneNumberValidationResult) {
      tempErrors.phoneNumber = intl.formatMessage({ id: phoneNumberValidationResult });
    }

    if (tacChecked === false) {
      tempErrors.tacChecked = intl.formatMessage({ id: 'Please read and accept beforehand' });
    }

    setErrors(tempErrors);

    if (Object.keys(tempErrors).length > 0) {
      return;
    }

    const createDetails = {
      password, name: name.trim(), email: formatEmail(email), phoneNumber, userType: 'Adviser',
      language, timeZone: -new Date().getTimezoneOffset(),
      campaignCodes: campaignCode ? [{ text: campaignCode.trim().toUpperCase() }] : null,
      optOutOfEmail: false, planMode, workEmail: formatEmail(workEmail), aid, ti
    };

    createUser(createDetails);
  };

  const onWhatsapp = () => {
    const text = selectedPlan ?
      `I am ${userDetails.name}, ${userDetails.email}, just subscribed to ${selectedPlan.nickname}/${selectedPlan.intervalCount}-${selectedPlan.interval}${campaignCode ? `, ${campaignCode}` : ''}. Would like to claim Business Bible/生意天書.` :
      `I am ${userDetails.name}, ${userDetails.email}, just subscribed to the service ${campaignCode ? `, ${campaignCode}` : ''}. Would like to claim Business Bible/生意天書.`;

    window.open(`https://api.whatsapp.com/send?phone=85266370320&text=${encodeURIComponent(text)}`, '_blank');
  };

  const onLogin = () => {
    const locationNextUrl = location.state?.nextUrl;
    const currentUrl = location.pathname;
    let nextUrl = locationNextUrl;

    if (!nextUrl) {
      if (currentUrl.includes('share-a-in')) {
        nextUrl = currentUrl;
      } else if (currentUrl.includes('signup') && location.search) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('ti');
        if (searchParams.size > 0) {
          nextUrl = `/home?${searchParams.toString()}`;
        }
      }
    }

    if (!userDetails?.emailVerified || nextUrl) {
      handleSignOut(nextUrl);
    } else {
      history.push({ pathname: '/login' });
    }

    updateKeepShowingNewSignUp(false);
  };

  const handleSelectPlan = async (plan) => {
    getExclusiveCoupons(plan._id);
    setSelectedPlan(plan);
    setActiveStep(getFormattedStep(2, withRegister));

    // console.log('handleSelectPlan: ', plan);
    const p = {
      trial_mode: plan.isFreeTrial ? 'free_trial' : plan.isTrial ? 'trial' : null,
      campaign_code: campaignCode || null,
      coupon_code: couponId || null,
      plan_mode: planMode || null,
      stripe_plan_id: plan.stripePlanId,
      stripe_product_id: plan.stripeProductId,
      plan_id: plan._id,
      plan_nickname: plan.nickname,
      email: userDetails.email,
      user_type: userDetails.userType,
      phone: userDetails.phone,
      username: userDetails.name
    }
    // console.log('track plan_confirm: ', p);
    ga4Tracking('plan_confirm', p)
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes('/signup')) {
      if (isAuthenticated) handleSignOut();
      resetUserStore();
      resetUserSubscription();
    } else {
      getExclusiveCoupons();
    }

    if (pageLayout) updateKeepShowingNewSignUp(true);

    const searchObject = getSearchObject(history);
    const pickedSearchObject = ['couponId', 'campaignCode', 'planMode', 'email', 'aid', 'ti'].reduce((obj, key) => {
      if (searchObject[key]) obj[key] = searchObject[key];
      return obj;
    }, {});

    if (pickedSearchObject.couponId) {
      setCouponId(pickedSearchObject.couponId);
    }
    if (pickedSearchObject.campaignCode) {
      setCampaignCode(pickedSearchObject.campaignCode);
      setCampaignCodeDisabled(true);
      if (pickedSearchObject.campaignCode.includes('@')) {
        setAdviserReferral(true);
      }
    }
    if (pickedSearchObject.email) {
      setEmail(pickedSearchObject.email);
      setEmailDisabled(true);
    }
    if (pickedSearchObject.aid) {
      pickedSearchObject.campaignCode = pickedSearchObject.aid;
      setCampaignCodeDisabled(true);
    }
    setPlanMode(pickedSearchObject.planMode);
    setAid(pickedSearchObject.aid);
    setTi(pickedSearchObject.ti);
  }, [history]);

  useEffect(() => {
    if (!props.userSubscription) getUserSubscription();
    processSearchParams();
  }, []);

  useEffect(() => {
    async function createFreeSubscription() {
      setLoading(true);
      let result = await createFreeSubscriptionApi(null, true);
      setLoading(false);
      if (result.success) {
        setPaymentCompleted(true);
      } else {
        setActiveStep(1);
      }
    }

    if (prevProps?.createPhase !== false && createPhase === false) {
      if (createMessage === 'An account with this email address already exists') {
        handleEmailUsed();
      } else {
        setTimeout(() =>
          toast.info(IconToast('error', intl.formatMessage({ id: createMessage }, createMessageExtras)), { className: 'new-toast' }),
          500);
      }
    }

    if (prevProps?.createPhase !== true && createPhase === true) {
      const validCoreSubscription = new ShortSubscriptions(subscriptions).containValidCoreSubscription() ||
        new ShortSubscriptions(userDetails?.subscriptions).containValidCoreSubscription();
      const coreSubscriptionTrialing = new ShortSubscriptions(subscriptions).isCoreSubscriptionTrialing() ||
        new ShortSubscriptions(userDetails?.subscriptions).isCoreSubscriptionTrialing();

      if (validCoreSubscription) {
        if (coreSubscriptionTrialing && planMode) {
          setActiveStep(1);
        } else {
          setPaymentCompleted(true);
        }
      } else if (planMode === 'ft') {
        createFreeSubscription();
      } else {
        setActiveStep(1);
      }
    }

    if (withRegister && prevProps?.updatePhase !== true && updatePhase === true) {
      setActiveStep(1);
      setPaymentCompleted(process.env.NODE_ENV === 'development' && process.env.WITHOUT_SUBSCRIPTION === 'true');
    }

    if (prevProps?.exclusiveCouponsPhase === LOADING && exclusiveCouponsPhase === SUCCESS && exclusiveCoupons.length > 0 && !couponId) {
      setCouponId(exclusiveCoupons[0].coupon.couponId);
    }

    if (userDetails?.teamInvitationId) {
      if (
        prevProps?.userDetails?.teamInvitationId !== userDetails?.teamInvitationId ||
        getTeamInvitationExtensionPhase === INIT
      ) {
        getTeamInvitationExtension(userDetails.teamInvitationId);
      }
    }
  }, [
    createPhase, createMessage, createMessageExtras, updatePhase, subscriptions,
    exclusiveCouponsPhase, exclusiveCoupons, withRegister, getTeamInvitationExtensionPhase,
    userDetails, couponId, planMode, intl
  ]);

  var { expiryDate } = getSubscriptionPlanExpiryDate(props.userSubscription, selectedPlan);

  const contents = (
    <>
      <FreeTrialExpiredDialog />
      <EmailRegisteredDialog
        open={registeredDialog}
        email={email}
        onClose={() => setRegisteredDialog(false)}
      />
      {!paymentCompleted && (
        <>
          {withRegister && activeStep === 0 && (
            <div className="max-width-wrapper">
              {adviserReferral && <ReferSignUpBanner />}
              <RegisterDetails
                intl={intl}
                onLogin={onLogin}
                yellowHint={intl.formatMessage({
                  id: planMode ? 'adviser-signup-msg-direct-pay' : 'adviser-signup-msg',
                })}
                name={name}
                email={email}
                emailDisabled={emailDisabled}
                phoneNumber={phoneNumber}
                password={password}
                confirmPassword={confirmPassword}
                workEmail={workEmail}
                whatsapp={whatsapp}
                wechatId={wechatId}
                lineId={lineId}
                userType='Adviser'
                language={language}
                tacChecked={tacChecked}
                dmChecked={dmChecked}
                campaignCode={campaignCode}
                campaignCodeDisabled={campaignCodeDisabled}
                handleValidate={handleValidate}
                handleChange={handleChange}
                handleCheck={handleCheck}
                handleCreate={handleCreate}
                handleEmailUsed={handleEmailUsed}
                handleEmailAccepted={handleEmailAccepted}
                loading={createPhase === LOADING || loading}
                classes={classes}
                errors={errors}
              />
            </div>
          )}
          <div>
            {activeStep === getFormattedStep(1, withRegister) && (
              <>
                <Pricing
                  buttonText={intl.formatMessage({ id: 'subscribe' })}
                  withoutTeam={!!planMode}
                  planMode={planMode}
                  couponId={couponId}
                  onSelectPlan={handleSelectPlan}
                >
                  {logoutButton && (
                    <div className="actions max-width-wrapper">
                      <Button
                        className="type-a"
                        color="primary"
                        onClick={handleSignOut}
                      >
                        {intl.formatMessage({ id: 'Logout' })}
                      </Button>
                    </div>
                  )}
                </Pricing>
              </>
            )}
          </div>
          <div className="max-width-wrapper">
            {activeStep === getFormattedStep(2, withRegister) && (
              <>
                {!confirmedAmount && !selectedPlan.isFreeTrial && !selectedPlan.isTrial && (
                  <TotalAmountView
                    intl={intl}
                    userSubscription={props.userSubscription}
                    subscriptionPlan={selectedPlan}
                    couponId={couponId}
                    remark={remark}
                    onConfirm={(couponId, coupon, couponValue, remark) => {
                      setCouponId(couponId);
                      setCoupon(coupon);
                      setCouponValue(couponValue);
                      setRemark(remark);
                      setConfirmedAmount(true);
                    }}
                  />
                )}
                {(confirmedAmount || selectedPlan.isFreeTrial || selectedPlan.isTrial) && (
                  <PaymentChannels
                    userDetails={userDetails}
                    stripePromise={stripePromise}
                    subscriptionPlan={selectedPlan}
                    coupon={coupon}
                    couponValue={couponValue}
                    paymentChannel={paymentChannel}
                    onChange={newPaymentChannel => setPaymentChannel(newPaymentChannel)}
                    completePay={() => {
                      // Payment completed
                      setConfirmAmountDialog(true);
                      // console.log('payment completed - completePay: ', selectedPlan);
                      trackPayment(paymentChannel, 'completed');


                    }}
                    onErr={(err) => {
                      // Payment error
                      // console.log('payment error - err : ', err);
                      trackPayment(paymentChannel, 'rejected', { err: err?.type, errMessage: err?.message });
                    }}
                  />
                )}
                <ConfirmAmountDialog
                  open={confirmAmountDialog}
                  subscriptionPlan={selectedPlan}
                  expiryDate={expiryDate}
                  onConfirm={() => {
                    setConfirmAmountDialog(false);
                    setPaymentCompleted(true);
                    updateKeepShowingNewSignUp(false);
                    if (onPaymentCompleted) onPaymentCompleted();
                  }}
                />
              </>
            )}
          </div>
        </>
      )}
      {paymentCompleted && (
        <div className="max-width-wrapper">
          <PaymentSuccess
            intl={intl}
            location={location}
            onWhatsapp={onWhatsapp}
            onLogin={onLogin}
            emailVerified={userDetails?.emailVerified}
          />
        </div>
      )}
    </>
  );

  return pageLayout ? (
    <LoginBannerContainer className="new-sign-up gradient-bg nsu" loginBanner={!paymentCompleted} ready={true}>
      <Grid item xs={12}>
        <div>{contents}</div>
      </Grid>
    </LoginBannerContainer>
  ) : (
    <div className="new-sign-up nsu">{contents}</div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userDetails: state.user.userDetails,
  createPhase: state.user.createPhase,
  createMessage: state.user.createMessage,
  createMessageExtras: state.user.createMessageExtras,
  updatePhase: state.user.updatePhase,
  subscriptions: state.userSubscription.subscriptions,
  userSubscription: state.userSubscription.userSubscription,
  exclusiveCouponsPhase: state.userSubscription.exclusiveCouponsPhase,
  exclusiveCoupons: state.userSubscription.exclusiveCoupons,
  getTeamInvitationExtensionPhase: state.userSubscription.getTeamInvitationExtensionPhase,
});

const mapDispatchToProps = {
  createUser, updateUserDetails, createSubscription, getUserSubscription,
  removeUnpaidSubscription, handleSignOut, selectedLocale, resetUserSubscription,
  resetUserStore, updateKeepShowingNewSignUp, getExclusiveCoupons, getTeamInvitationExtension
};

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(withStyles(LoginStyles)(withRouter(NewSignUp)))
);
