import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../store/control/duck';
import { handleSignOut } from '../../store/user/duck';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import './EnterpriseOnlyDialog.scss';
import _ from 'lodash';
import NativeOrWeb from '../../utils/native';
import { withRouter } from 'react-router';

const EnterpriseOnlyDialog = props => {
  const { intl, location, enterpriseOnlyDialog, userDetails, toggleDialog, handleSignOut, ...rest } = props;
  const { closed } = enterpriseOnlyDialog || {};

  const shouldBlock = NativeOrWeb.shouldEnterpriseDialogBlock(userDetails);
  const pathname = _.get(location, 'pathname');

  const close = () => toggleDialog('enterpriseOnly', { ...enterpriseOnlyDialog, closed: true });
  const onExited = () => toggleDialog('enterpriseOnly', false);
  const logout = () => handleSignOut();

  return (
    <Dialog
      className="enterprise-only-dialog"
      open={!!enterpriseOnlyDialog && !closed && pathname !== '/login'}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
      {...rest}
    >
      <DialogTitle>
        <Typography color="primary" style={{ width: 'fit-content' }} onClick={close}>
          {intl.formatMessage({ id: 'enterprise-only-dialog-title' })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'enterprise-only-dialog-content' }) }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={logout}>
          {intl.formatMessage({ id: 'Logout' })}
        </Button>
        <Button color="primary" onClick={close} disabled={shouldBlock}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    enterpriseOnlyDialog: state.control.enterpriseOnlyDialog,
    userDetails: state.user.userDetails
  }),
  {
    toggleDialog,
    handleSignOut
  }
)(EnterpriseOnlyDialog);

export default injectIntl(withRouter(container));
