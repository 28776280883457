import React from 'react';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel, Checkbox } from '@material-ui/core';
import withRouter from 'react-router/withRouter';

class ConfirmEmailDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      err: false
    };
  }

  render() {
    const { intl, content, confirmEmail, checked, confirmButtonId } = this.props;
    const { err } = this.state;

    return (
      <Dialog open={true}>
        <DialogTitle>{intl.formatMessage({ id: 'Confirm' })}</DialogTitle>
        {content && (
          <DialogContent>
            <div>{content}</div>
            <FormControlLabel
              control={<Checkbox color="primary" checked={checked} onClick={confirmEmail} />}
              className="referral-check"
              label={intl.formatMessage({ id: 'ReferCorrect' })}
            />
            <i className="bar" />
            {err ? <p className="error_field">{intl.formatMessage({ id: '*Please check the info checkbox' })}</p> : ''}
          </DialogContent>
        )}
        <DialogActions>
          <Button color="primary" onClick={this.props.closeModal}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button
            id={confirmButtonId}
            color="primary"
            onClick={() => {
              if (!checked) {
                this.setState({ err: true });
              } else {
                this.props.handleCreate();
                this.setState({ err: false });
              }
            }}
          >
            {intl.formatMessage({ id: 'Confirm' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(withRouter(ConfirmEmailDialog));

export default injectIntl(container);
