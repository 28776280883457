import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import NotFound from '../NotFound/NotFound';
import CustomTopNavigation from '../../components/CustomTopNavigation/CustomTopNavigation';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { injectIntl } from 'react-intl';

const IFrame = props => {
  const { intl, history, showPadding } = props;

  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(true);

  const back = () => history.goBack();

  useEffect(() => {
    if (history.location && history.location.state) {
      setUrl(history.location.state.url);
      setTitle(history.location.state.title);
    }

    setLoading(false);
  }, [history]);

  return !loading ? (
    url && title ? (
      <div>
        <CustomTopNavigation title={title}>
          <span onClick={back} className="back_arrow">
            <ArrowBackIcon />
          </span>
        </CustomTopNavigation>
        <iframe
          title={title}
          src={url}
          style={{
            width: showPadding ? 'calc(100% - 60px)' : '100%',
            height: '100%',
            position: 'fixed',
            border: 'none',
            paddingTop: 45
          }}
        />
      </div>
    ) : (
      <NotFound onClick={() => history.goBack()} />
    )
  ) : null;
};

export default injectIntl(withRouter(IFrame));
