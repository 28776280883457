import React, { useState, useEffect } from 'react';
import { getLocalContent } from '../../../../components/StandardContentComponents/utils';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { updateAccessHotTopicMissionVariableApi } from '../../../../store/userMission/api';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer.js';
import { tracking } from '../../../../utils/tracking';
import HotTopicContentSlides from './HotTopicContentSlides';
import { getLazyPackStatistics } from '../../../../store/event/api';

const HotTopicView = props => {
  const { intl, hotTopic, renderHeaderButton } = props;

  const [statistics, setStatistics] = useState({});
  const [contents, setContents] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setContents(
        process.env.NODE_ENV === 'development' ? { data: (await getLocalContent('hot-topic')).default } : hotTopic
      );
    };

    fetchData()
      .then()
      .catch();
  }, [hotTopic]);

  useEffect(() => {
    renderHeaderButton(false);
  }, [renderHeaderButton]);

  useEffect(() => {
    tracking('Adviser enter hot topic');
    updateAccessHotTopicMissionVariableApi();
  }, []);

  useEffect(() => {
    const getStatistics = async () => {
      const data = await getLazyPackStatistics();
      setStatistics(data);
    };
    getStatistics()
      .then()
      .catch(console.log);
  }, []);

  return (
    <AppContentContainer>
      <div>
        <div style={{ padding: '8px 0' }}>
          <AladdinHint
            containerProps={{ justify: 'center' }}
            title={intl.formatMessage({ id: 'aladdin-hot-topic-title' })}
            helpCenter="c92"
          />
          <HotTopicContentSlides
            {...props}
            hotTopic={contents}
            eventType="hot topic"
            statistics={statistics}
            categoryViewPath="/hot_topic_category"
            tagViewPath="/hot_topic_tag"
            enableLanguages={true}
            HotTopicItemProps={{
              enableLanguages: true
            }}
          />
        </div>
      </div>
    </AppContentContainer>
  );
};

export default HotTopicView;
