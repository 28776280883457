export const getOSMLocation = async (lat, lon, locale) => {
  let formattedLocale;
  switch (locale) {
    case 'zh':
      formattedLocale = 'zh-Hans';
      break;
    case 'zh-Hant-HK':
      formattedLocale = 'zh-Hant';
      break;
    default:
      formattedLocale = locale;
  }
  const jsonResult = await (
    await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json&accept-language=${formattedLocale}`
    )
  ).json();
  const { building, amenity, borough, road, suburb } = jsonResult.address;

  const firstPart = building || amenity || '';
  const secondPart = suburb || borough || road || '';
  const isEng = locale === 'en';

  let locationStr = `${isEng ? firstPart : secondPart} ${isEng ? secondPart : firstPart}`.trim();
  locationStr = locationStr ? locationStr : jsonResult['display_name'];
  return locationStr;
};