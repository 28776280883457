import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { injectIntl } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import VideoPlayer from './VideoPlayer';
import { usePrevious } from '../../utils/hooks';
import { ZoomTransition } from '../Transition/Transition';

const VideoDialogContentStyle = {
  padding: 0
};

const VideoDialog = React.forwardRef((props, ref) => {
  const { intl, title, url, prependActions, appendActions, ...rest } = props;

  const videoPlayerRef = useRef(null);
  const previousUrl = usePrevious(url);

  const [internalOpen, setInternalOpen] = useState(false);
  const [finalUrl, setFinalUrl] = useState(url);
  const [triggerTarget, setTriggerTarget] = useState(undefined);

  const openDialog = () => setInternalOpen(true);
  const closeDialog = () => setInternalOpen(false);

  useEffect(() => {
    if (previousUrl !== url) {
      setFinalUrl(url);
    }
  }, [url, previousUrl]);

  useImperativeHandle(ref, () => ({
    open: (event, url) => {
      setTriggerTarget(event.target);
      setFinalUrl(url);
      openDialog();
    },
    videoPlayerRef: videoPlayerRef
  }));

  return (
    <Dialog
      open={internalOpen}
      fullWidth={true}
      TransitionComponent={ZoomTransition}
      TransitionProps={{
        triggerTarget
      }}
      // onClose={closeDialog}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent style={VideoDialogContentStyle}>
        <VideoPlayer ref={videoPlayerRef} url={finalUrl} {...rest} />
      </DialogContent>
      <DialogActions>
        {prependActions && prependActions}
        <Button color="primary" onClick={closeDialog}>
          {intl.formatMessage({ id: 'Close' })}
        </Button>
        {appendActions && appendActions}
      </DialogActions>
    </Dialog>
  );
});

export default injectIntl(VideoDialog, { withRef: true });
