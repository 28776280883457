import { injectIntl as baseInjectIntl } from 'react-intl';
import hoistNonReactStatic from 'hoist-non-react-statics';
import EN_TRANSLATION from '../store/locale/messages/en';
import ZH_TRANSLATION from '../store/locale/messages/zh';
import ZH_HANT_HK_TRANSLATION from '../store/locale/messages/zh-Hant-HK';

export const getBrowserDefaultLanguage = () => {
  const locale = navigator.language;

  if (locale.indexOf('zh-TW') > -1 || locale.indexOf('zh-HK') > -1) {
    return 'zh-Hant-HK';
  } else if (locale.indexOf('zh') > -1) {
    return 'zh';
  } else {
    return 'en';
  }
};

export const injectIntlStatic = (component, options) => {
  const WrapperComponent = baseInjectIntl(component, options);

  hoistNonReactStatic(WrapperComponent, component);

  return WrapperComponent;
};

export const getLocaleMessages = language => {
  switch (language) {
    case 'zh-Hant-HK':
      return ZH_HANT_HK_TRANSLATION;
    case 'zh':
      return ZH_TRANSLATION;
    default:
      return EN_TRANSLATION;
  }
};

export const switchLanguage = (selectedLocale, language) => {
  selectedLocale(language);
};

export const formatMessage = (intl, id, values) => (id ? intl.formatMessage({ id: id }, values) : '');

export const translateMessage = (id, language, extras) => {
  // define new component state 'language' instead of using user's language setting
  // define overall message, to {} to indicate dynamic values
  if (id !== '') {
    let message;
    // format message according to language state and using the mappings
    // clientName & insurerName are those dynamic values
    if (language === 'en') {
      message = EN_TRANSLATION.messages[id];
      // message1 = intl.formatMessage(testMessages.reminderMessageEn, {
      //   messages: EN_TRANSLATION.messages[val]
      // });
    } else if (language === 'zh') {
      message = ZH_TRANSLATION.messages[id];
      // message1 = intl.formatMessage(testMessages.reminderMessageZh, {
      //   messages: ZH_TRANSLATION.messages[val]
      // });
    } else if (language === 'zh-Hant-HK') {
      message = ZH_HANT_HK_TRANSLATION.messages[id];
      // message1 = intl.formatMessage(testMessages.reminderMessageZhHK, {
      //   messages: ZH_HANT_HK_TRANSLATION.messages[val]
      // });
    }

    if (extras) {
      Object.entries(extras).forEach(entry => {
        message = message.replace(`{${entry[0]}}`, entry[1]);
      });
    }

    return message;
  }
};

export const tryTranslate = (intl, id, params) => {
  try {
    return intl.formatMessage({ id: id }, params);
  } catch (e) {
    return id;
  }
};
