import * as api from './api';
import _ from 'lodash';
import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { isClientDisconnected } from '../../components/ClientEntry/utils';
import {
  DELETE_CLIENT_SUCCESS,
  GET_CLIENT_SUCCESS,
  SUBMIT_CLIENT_SUCCESS,
  UPDATE_FACTSHEET_SUCCESS
} from '../client/duck';
import { ADD_POLICY_SUCCESS, DELETE_POLICY_SUCCESS, EDIT_POLICY_SUCCESS } from '../policy/duck';
import { GET_ADVISER_DASHBOARD_DATA_SUCCESS, GET_DUE_POLICY_SUCCESS } from '../adviserDashboard/duck';
import Rx from 'rxjs';
import {
  birthdaySort,
  isClientRemindMe,
  paidUpPolicySort,
  remindMeSort,
  trackingSort
} from '../../utils/adviser-client';
// import { toast } from 'react-toastify';
import { TRACKING_MAX_DAYS } from '../../constants/tracking';
// import IconToast from '../../components/NewToast';

/***********************************
 * Action Types
 ***********/
export const UPDATE_ASSISTANT = 'UPDATE_ASSISTANT';
export const UPDATE_ASSISTANT_GETTING = 'UPDATE_ASSISTANT_GETTING';
export const UPDATE_ASSISTANT_UPDATING = 'UPDATE_ASSISTANT_UPDATING';
export const UPDATE_ASSISTANT_DATA_STATUS = 'UPDATE_ASSISTANT_DATA_STATUS';
export const ERROR_ASSISTANT = 'ERROR_ASSISTANT';

/***********************************
 * Initial State
 ***********/
let initialState = {
  assistant: undefined,
  formattedAssistant: undefined,
  getting: false,
  updating: false,
  error: false,
  needUpdate: {
    assistant: false,
    policyDue: false,
    adviserDashboard: false,
    paidUpPolicy: false
  },

  // shortcut data for big data figures
  policyDueClientCount: 0,
  remindMeClientCount: 0,
  birthdayClientCount: 0,
  paidUpPolicyClientCount: 0
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_ASSISTANT:
      const assistantToDoCategory = _.get(action.formattedAssistant, 'categories', []).find(
        category => category.name === 'to-do'
      );

      const countMap = {
        'policy-due': 'policyDueClientCount',
        'remind-me': 'remindMeClientCount',
        birthday: 'birthdayClientCount',
        'paid-up-policy': 'paidUpPolicyClientCount'
      };
      const counts = {};
      if (assistantToDoCategory) {
        const lists = _.get(assistantToDoCategory, 'lists', []);
        const getUnDoneCount = list => _.get(list, 'data', []).filter(entry => !entry.isDone).length;
        lists.forEach(list => {
          counts[countMap[list.name]] = getUnDoneCount(list);
        });
      }

      return {
        ...state,
        assistant: action.assistant,
        formattedAssistant: { ...action.formattedAssistant },
        needUpdate: false,
        error: false,
        getting: false,
        updating: false,
        ...counts
      };
    // case UPDATE_ASSISTANT_LIST:
    // 	return { ...state, assistant: action.data };
    case UPDATE_ASSISTANT_GETTING:
      return { ...state, getting: action.getting };
    case UPDATE_ASSISTANT_UPDATING:
      return { ...state, updating: action.updating };
    case UPDATE_ASSISTANT_DATA_STATUS:
      return { ...state, needUpdate: { ...state.needUpdate, ...action.needUpdate } };
    case ERROR_ASSISTANT:
      const errorMessage = action.error ? action.error.message || action.error.toString() : 'Unknown error occurred';
      // if (action.error.response && action.error.response.status !== 403) {
      //   toast.error(errorMessage);
      // }
      return { ...state, error: errorMessage, getting: false, updating: false };
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
const formatAssistant = async (assistant, getState) => {
  let state = getState();

  const checkFetched = async (conditionCheck, retryCount) => {
    state = getState();
    if (retryCount > 0) {
      if (!conditionCheck(state)) {
        retryCount--;
        return await new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              resolve(await checkFetched(conditionCheck, retryCount));
            } catch (e) {
              reject(e);
            }
          }, 3000);
        });
      } else {
        return true;
      }
    } else {
      throw new Error('Data check failed');
    }
  };

  await checkFetched(state => state.client.fetchClientPhase === 'success', 3);

  let clientList = _.get(state, 'client.clientData', []);

  let dataCheckList = new Set();
  assistant.categories = assistant.categories.map(category => {
    category.lists = category.lists.map(list => {
      switch (list.type) {
        case 'policy-due':
          list.dataPath = 'adviserDashboard.GetUsersPolicyDueData';
          list.mostDoneDays = 10;
          dataCheckList.add(state => state.adviserDashboard.policyDuePhase === true);
          break;
        case 'remind-me':
          list.dataPath = 'adviserDashboard.getActiveReminder';
          dataCheckList.add(state => state.adviserDashboard.getAdviserDashboardPhase === true);
          list.mostDoneDays = 10;
          break;
        case 'birthday':
          list.dataPath = 'adviserDashboard.GetConnectedUsersBirthday.birthdayUsers';
          dataCheckList.add(state => state.adviserDashboard.getAdviserDashboardPhase === true);
          list.mostDoneDays = 10;
          break;
        case 'paid-up-policy':
          list.dataPath = 'adviserDashboard.paidUpPolicyClients';
          dataCheckList.add(state => state.adviserDashboard.paidUpPolicyPhase === true);
          list.mostDoneDays = 10;
          break;
        case 'tracking':
          list.dataPath = 'client.clientData';
          dataCheckList.add(state => state.client.fetchClientPhase === 'success');
          // explicitly impose max limit to last tracking stage
          if (list.name === 'period-4') {
            _.set(list, 'extras.maxDay', TRACKING_MAX_DAYS);
          }
          break;
        default:
          break;
      }
      return list;
    });
    return category;
  });

  dataCheckList = [...dataCheckList];
  await Promise.all(dataCheckList.map(async toCheck => checkFetched(toCheck, 3)));

  const userEmail = _.get(state, 'user.userDetails.email');

  assistant.categories = assistant.categories.map(category => {
    category.lists = category.lists.map(list => {
      const dataPath = list.dataPath;
      const mostDoneDays = list.mostDoneDays;

      let data = [..._.get(state, dataPath)];

      if (!data) {
        return list;
      } else {
        // format data
        switch (list.type) {
          case 'policy-due':
            data = data.reduce((accumulator, entry) => {
              const foundEntry = accumulator.find(accEntry => accEntry._id === entry._id);
              if (foundEntry) {
                foundEntry.policyDueDates.push(entry.policyDueDate);
              } else {
                accumulator.push({ _id: entry._id, policyDueDates: [entry.policyDueDate] });
              }
              return accumulator;
            }, []);
            break;
          default:
            break;
        }
      }

      let formattedData = [];

      data.forEach(dataEntry => {
        const client = clientList.find(clientEntry => clientEntry._id === dataEntry._id);
        // filtering
        switch (list.type) {
          case 'remind-me':
            if (isClientRemindMe(client, userEmail)) {
              break;
            } else {
              return;
            }
          case 'tracking':
            if (!(!isClientDisconnected(client) && client.trackingId && client.trackingId.isActive)) {
              return;
            }

            const condition = _.get(list, 'extras.condition');
            if (condition) {
              switch (condition) {
                case 'period':
                  const minDay = _.get(list, 'extras.minDay'),
                    maxDay = _.get(list, 'extras.maxDay');

                  if (minDay || maxDay) {
                    const startDate = _.get(client, 'trackingId.startDate');
                    if (startDate) {
                      const dayCount = Math.floor(moment().diff(moment(startDate, 'YYYY-MM-DD'), 'days'));
                      if (!((!minDay || minDay <= dayCount) && (!maxDay || maxDay >= dayCount))) {
                        return;
                      }
                    }
                  }
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }

        const doneEntry = list.doneEntries.find(entry => entry._id === dataEntry._id);
        if (doneEntry) {
          if (mostDoneDays) {
            let a = doneEntry.doneAt ? moment.parseZone(doneEntry.doneAt).format('YYYY-MM-DD') : null;
            let b = moment();
            let doneDays = moment(b, 'YYYY-MM-DD').diff(a, 'days');
            if (doneDays <= mostDoneDays) {
              formattedData.push({ client: _.merge(dataEntry, client), isDone: true }); // count only the user who have done date less than or equal 10 days
            }
          } else {
            formattedData.push({ client: _.merge(dataEntry, client), isDone: true });
          }
        } else {
          formattedData.push({ client: _.merge(dataEntry, client), isDone: false });
        }
      });

      // Perform sorting
      switch (list.type) {
        case 'remind-me':
          formattedData = remindMeSort(formattedData, 'client');
          break;
        case 'birthday':
          formattedData = birthdaySort(formattedData, 'client');
          break;
        case 'paid-up-policy':
          formattedData = paidUpPolicySort(formattedData, 'client');
          break;
        case 'tracking':
          formattedData = trackingSort(formattedData, 'client');
          break;
        default:
          break;
      }

      return { ...list, data: formattedData };
    });
    return category;
  });
  return assistant;
};

export const getAssistant = () => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_ASSISTANT_GETTING, getting: true });
    let assistant = await api.getAssistant();
    const formattedAssistant = await formatAssistant(assistant, getState);
    dispatch({ type: UPDATE_ASSISTANT, assistant: assistant, formattedAssistant: formattedAssistant });
    return assistant;
  } catch (e) {
    dispatch({ type: ERROR_ASSISTANT, error: e });
    return undefined;
  }
};

export const refreshAssistant = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ASSISTANT_UPDATING, updating: true });
  let assistant = getState().assistant.assistant;
  if (assistant) {
    assistant = _.cloneDeep(assistant);
    const formattedAssistant = await formatAssistant(assistant, getState);
    dispatch({ type: UPDATE_ASSISTANT, assistant: assistant, formattedAssistant: formattedAssistant });
  }
  dispatch({ type: UPDATE_ASSISTANT_UPDATING, updating: false });
  return assistant;
};

export const updateDataSourceStatus = needUpdate => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ASSISTANT_DATA_STATUS, needUpdate: needUpdate });
};

export const updateAssistantListDoneEntries = (categoryName, listName, entry, isDelete) => async (
  dispatch,
  getState
) => {
  dispatch({ type: UPDATE_ASSISTANT_UPDATING, updating: true });
  let assistant = { ...getState().assistant.assistant };

  const categoryIndex = assistant.categories.findIndex(category => category.name === categoryName);
  const listIndex = assistant.categories[categoryIndex].lists.findIndex(list => list.name === listName);
  const doneEntriesIndex = assistant.categories[categoryIndex].lists[listIndex].doneEntries.findIndex(
    doneEntry => doneEntry._id === entry._id
  );

  if (isDelete) {
    if (doneEntriesIndex !== -1) {
      _.pullAt(assistant.categories[categoryIndex].lists[listIndex].doneEntries, [doneEntriesIndex]);
    }
  } else {
    if (doneEntriesIndex !== -1) {
      assistant.categories[categoryIndex].lists[listIndex].doneEntries[doneEntriesIndex] = entry;
    } else {
      assistant.categories[categoryIndex].lists[listIndex].doneEntries.push(entry);
    }
  }

  try {
    let updatedAssistant = await api.updateAssistant(assistant);
    const formattedAssistant = await formatAssistant(updatedAssistant, getState);

    dispatch({ type: UPDATE_ASSISTANT, assistant: updatedAssistant, formattedAssistant: formattedAssistant });
    dispatch({ type: UPDATE_ASSISTANT_UPDATING, updating: false });

    return updatedAssistant;
  } catch (e) {
    dispatch({ type: ERROR_ASSISTANT, error: e });
    return getState().assistant.assistant;
  }
};

export const updateAssistantCategories = categories => async (dispatch, getState) => {
  dispatch({ type: UPDATE_ASSISTANT_UPDATING, updating: true });
  let assistant = { ...getState().assistant.assistant };

  categories.forEach(newCategory => {
    const oldCategoryIndex = assistant.categories.findIndex(category => category.name === newCategory.name);
    if (oldCategoryIndex) {
      assistant.categories[oldCategoryIndex] = newCategory;
    } else {
      assistant.categories.push(newCategory);
    }
  });

  try {
    let updatedAssistant = await api.updateAssistant(assistant);
    const formattedAssistant = await formatAssistant(updatedAssistant, getState);

    dispatch({ type: UPDATE_ASSISTANT, assistant: updatedAssistant, formattedAssistant: formattedAssistant });
    dispatch({ type: UPDATE_ASSISTANT_UPDATING, updating: false });

    return updatedAssistant;
  } catch (e) {
    dispatch({ type: ERROR_ASSISTANT, error: e });
    return getState().assistant.assistant;
  }
};

/***********************************
 * Epics
 ***********/
const assistantSourceUpdateEpic = action$ =>
  action$.ofType(GET_CLIENT_SUCCESS, GET_DUE_POLICY_SUCCESS, GET_ADVISER_DASHBOARD_DATA_SUCCESS).mergeMap(action => {
    return Rx.Observable.of({ type: UPDATE_ASSISTANT_DATA_STATUS, needUpdate: { assistant: true } });
  });

const policySourceUpdateEpic = action$ =>
  action$.ofType(ADD_POLICY_SUCCESS, EDIT_POLICY_SUCCESS, DELETE_POLICY_SUCCESS).mergeMap(action => {
    return Rx.Observable.of({
      type: UPDATE_ASSISTANT_DATA_STATUS,
      needUpdate: { policyDue: true, paidUpPolicy: true }
    });
  });

const adviserDashboardSourceUpdateEpic = action$ =>
  action$.ofType(SUBMIT_CLIENT_SUCCESS, UPDATE_FACTSHEET_SUCCESS, DELETE_CLIENT_SUCCESS).mergeMap(action => {
    return Rx.Observable.of({ type: UPDATE_ASSISTANT_DATA_STATUS, needUpdate: { adviserDashboard: true } });
  });

export const assistantEpic = combineEpics(
  assistantSourceUpdateEpic,
  policySourceUpdateEpic,
  adviserDashboardSourceUpdateEpic
);
