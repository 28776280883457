export const DEFAULT_PREMIUM_FINANCING_PROFILE = {
  principal: 1000000,
  base: 190000,
  loanPercentage: 80,
  bankLoanInterest: 2,
  policyInterest: 4.1,
  yearsToShow: [0, 8, 9, 10],
  cashValueAt: [1000000, 1300000, 1380000, 1500000],
  firstYearBonus: 10000,
  termLoanPercentage: null,
  termAddOn: null,
  overrideLoanInterest: false,
  day0SurrenderValue: 850000,
  bankLoanPercentage: 95,
  shouldPayPrincipal: 0
};
