import React, { PureComponent, Fragment } from 'react';
import ExistingPolicyView from './partials/existingPolicy1/component';
import AddPortfolioView from './partials/addPortfolio2/component';
import InsuranceTypeView from './partials/insuranceType/component';
import InsurerView from './partials/insurer/component';
import PlanView from './partials/Plan/component';
import PaymentView from './partials/Payment/component';
import DetailView from './partials/Detail/component';
import ApendixView from './partials/Apendix/component';
import { getCurrencyExchangeRate } from '../../utils/currencyExchange';
import _ from 'lodash';
import Template from '../../components/Template/Template';
import { jsonParse } from '../../utils/json';
import SimplifiedAddMPF from './partials/SimplifiedAddMPF/SimplifiedAddMPF';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer.js';
import { formatEmail } from '../../utils/email';

const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];
const subCategoryDeatils = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' },
  { id: '5b9a5bfee624cdb543a476d1', title: 'Maid' },
  { id: '5b9a5bfee624cdb543a476d2', title: 'Accident' },
  { id: '5baf2af899d85c2d38c8f00f', title: 'Saving' },
  { id: '5c18ebd120ca05b7e2b32565', title: 'Provident Fund' },
  { id: '5c41b85a83230016450b2214', title: 'Mortgage' }
];

const SELECT_PAGE_PATHS = ['/add_portfolio', '/insurance_type', '/insurer'];
const PAGE_PATHS = ['/plan', '/payment', '/detail', '/apendix'];

class AddPolicyView extends PureComponent {
  constructor(props) {
    super(props);
    this.page = 0;
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    sessionStorage.removeItem('editPolicyDetails');
    sessionStorage.removeItem('editPolicy');
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillMount() {
    //this.props.currencyExchangeRate();
    //getCurrencyExchangeRate()
    this.props.getPortfolioJson();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    getCurrencyExchangeRate(nextProps.exchangeRate);
  }

  handleResize = () => this.setState({ width: window.innerWidth });

  // Send the policy detail to backend api
  sendData() {
    const { userDetails } = this.props;
    var category = '';
    var subCategory = '';
    var user = this.props.userDetails;
    var planData = JSON.parse(localStorage.getItem('plan'));
    var paymentData = JSON.parse(localStorage.getItem('payment'));
    var detailData = JSON.parse(localStorage.getItem('detail'));
    var apendixData = JSON.parse(localStorage.getItem('apendix'));
    var insurer = JSON.parse(localStorage.getItem('insurer'));
    var formData = {};
    var clientId = JSON.parse(localStorage.getItem('clientId'));
    var categoryType = '';
    var subCategoryType = '';
    if (clientId) {
      formData.userId = clientId;
    } else {
      formData.userId = user._id;
    }
    const editPolicyDetails = jsonParse(sessionStorage.getItem('editPolicyDetails'));
    if (editPolicyDetails) {
      formData.categoryId = editPolicyDetails.categoryId;
      formData.subCategoryId = editPolicyDetails.subCategoryId;
      formData.insurerId = editPolicyDetails.insurerId;
      formData.id = editPolicyDetails._id;
      subCategoryDeatils.forEach(data => {
        let subCategoryId = editPolicyDetails.subCategoryId;
        if (data.id === subCategoryId) {
          subCategoryType = data.title;
        }
      });
      categoryDetails.forEach(data => {
        let categoryId = editPolicyDetails.categoryId;
        if (data.id === categoryId) {
          categoryType = data.title;
        }
      });
    } else {
      category = JSON.parse(localStorage.getItem('category'));
      subCategory = JSON.parse(localStorage.getItem('subCategory'));
      formData.categoryId = _.get(category, 'value', '');
      formData.subCategoryId = _.get(subCategory, 'value', '');
      formData.insurerId = _.get(insurer, '_id', '');
      categoryType = _.get(category, 'title', '');
      subCategoryType = _.get(subCategory, 'title', '');
    }
    formData.insurer = _.get(planData, 'insurer', '');
    //if (categoryType !== 'Company') {
    formData.policyNumber = planData.policyNumber;
    //}

    if (planData.insurerId !== undefined && planData.insurerId !== '') {
      formData.insurerId = planData.insurerId;
    }
    if (categoryType === 'Personal') {
      formData.feature = planData.feature;
    }
    if (categoryType === 'Personal' || subCategoryType === 'Saving' || subCategoryType === 'Accident') {
      formData.planType = planData.planType;
    }
    if (categoryType === 'Personal' || categoryType === 'General') {
      formData.policyName = planData.policyName;
    }
    if (subCategoryType === 'Saving') {
      formData.guaranteeValue = planData.guaranteeValue;
      formData.isAnnuity = planData.isAnnuity;
    }
    if (categoryType === 'Company') {
      formData.companyName = planData.companyName;
    }
    if (subCategoryType === 'Other') {
      formData.type = planData.type;
    }

    if (categoryType === 'Personal' && subCategoryType === 'Medical') {
      formData.vhisPlan = planData.vhisPlan;
    }

    if (subCategoryType === 'Provident Fund') {
      formData.accountTypes = planData.accountTypes;
    }

    if (categoryType === 'Personal') {
      formData.addOns = planData.addOns;
    }

    formData.currency = paymentData.currency;

    formData.lifeCoverage = paymentData.lifeCoverage;
    //formData.protectionAmountInHKD = paymentData.protectionAmountInHKD;

    if (subCategoryType === 'Critical Illness') {
      formData.sumInsured = _.get(paymentData, 'sumInsured', '');
      //formData.sumInsuredInHKD = paymentData.sumInsuredInHKD;
    }
    formData.premium = paymentData.premium;
    formData.naPremium = paymentData.naPremium;
    if (subCategoryType === 'Medical') {
      //only for medical subcategory
      formData.roomClass = planData.roomClass;
      formData.coverageType = planData.coverageType;
    }
    formData.frequency = paymentData.frequency; // for all subcategory
    // formData.annualPremiumInHKD = paymentData.annualPremiumInHKD;
    if (categoryType === 'Personal') {
      formData.levelPremium = paymentData.levelPremium;
    }
    if (subCategoryType === 'Provident Fund' || subCategoryType === 'Medical' || subCategoryType === 'Saving') {
      formData.taxDeductible = paymentData.taxDeductible;
    }
    if (categoryType !== 'Company' && subCategoryType !== 'Provident Fund' && subCategoryType !== 'Mortgage') {
      formData.paymentMethod = paymentData.paymentMethod;
    }
    formData.policyStartDate = paymentData.policyStartDate;
    if (
      categoryType === 'General' &&
      (subCategoryType === 'Saving' || subCategoryType === 'Provident Fund' || subCategoryType === 'Other')
    ) {
      formData.lumpSumAmount = paymentData.lumpSumAmount;
    }
    if (categoryType === 'Personal' && (subCategoryType === 'Life' || subCategoryType === 'Other')) {
      formData.lumpSumAmount = paymentData.lumpSumAmount;
    }

    if (categoryType === 'Personal') {
      formData.policyOwner = detailData.policyOwner;
      formData.policyOwnerSelected = detailData.policyOwnerSelected;
    }

    if (
      (subCategoryType === 'Saving' ||
        subCategoryType === 'Provident Fund' ||
        subCategoryType === 'Home' ||
        subCategoryType === 'Motor' ||
        subCategoryType === 'Accident') &&
      categoryType === 'General'
    ) {
      formData.policyOwner = detailData.policyOwner;
      formData.policyOwnerSelected = detailData.policyOwnerSelected;
    }

    if (categoryType === 'Personal' || subCategoryType === 'Saving' || subCategoryType === 'Accident') {
      formData.lifeInsured = detailData.lifeInsured;
      formData.lifeInsuredSelected = detailData.lifeInsuredSelected;
      formData.lifeInsuredAge = detailData.lifeInsuredAge;
      if (detailData.paymentTermsOther) {
        formData.paymentTerms = detailData.paymentTermsOther;
      } else {
        formData.paymentTerms = detailData.paymentTerms;
      }
      if (detailData.protectionTermsOther) {
        formData.protectionTerms = detailData.protectionTermsOther;
      } else {
        formData.protectionTerms = detailData.protectionTerms;
      }
    }

    if (subCategoryType === 'Mortgage') {
      if (detailData.paymentTermsOther) {
        formData.paymentTerms = detailData.paymentTermsOther;
      } else {
        formData.paymentTerms = detailData.paymentTerms;
      }
    }

    if (categoryType !== 'General' || subCategoryType === 'Saving' || subCategoryType === 'Accident') {
      formData.beneficial = detailData.beneficial;
      formData.beneficialSelected = detailData.beneficialSelected;
    }
    if (subCategoryType === 'Motor') {
      formData.vehicleBrand = detailData.vehicleBrand;
      formData.vehicleModel = detailData.vehicleModel;
      formData.vehicleYear = detailData.vehicleYear;
      formData.ncb = detailData.ncb;
    }
    if (subCategoryType === 'Home') {
      formData.homeSize = detailData.homeSize;
      formData.homeContentsValue = detailData.homeContentsValue;
    }
    if (categoryType === 'General' && subCategoryType === 'Other') {
      formData.coverageDetail = detailData.coverageDetail;
    }
    if (subCategoryType === 'Travel') {
      formData.coverageDay = detailData.coverageDay;
      formData.tripDelayCover = detailData.tripDelayCover;
      formData.lossItemCover = detailData.lossItemCover;
      formData.mobilePhoneCover = detailData.mobilePhoneCover;
      formData.rentalCarCover = detailData.rentalCarCover;
      formData.emergencyTel = detailData.emergencyTel;
    }
    if (subCategoryType === 'Accident') {
      formData.permanentDisablement = detailData.permanentDisablement;
      formData.physiotherapy = detailData.physiotherapy;
      formData.chineseDoctor = detailData.chineseDoctor;
      formData.chineseBonesetter = detailData.chineseBonesetter;
      formData.hospitalCashDetail = detailData.hospitalCashDetail;
    }
    if (subCategoryType === 'Accident' || subCategoryType === 'Maid') {
      formData.medicalExpenses = detailData.medicalExpenses;
    }
    if (subCategoryType === 'Maid') {
      formData.employersLiability = detailData.employersLiability;
      formData.outPatientDetail = detailData.outPatientDetail;
      formData.dentalDetail = detailData.dentalDetail;
      formData.repatriation = detailData.repatriation;
      formData.accident = detailData.accident;
      formData.maidReplacement = detailData.maidReplacement;
    }
    if ((categoryType === 'Personal' || categoryType === 'General') && subCategoryType !== 'Provident Fund') {
      formData.premiumHolidayDate = detailData.premiumHolidayDate;
    }
    if (categoryType === 'Personal' || categoryType === 'General' || subCategoryType === 'Other') {
      formData.policyStatus = detailData.policyStatus;
      formData.selectedPolicyStatus = detailData.selectedPolicyStatus;
    }
    if (
      subCategoryType === 'Saving' ||
      subCategoryType === 'Provident Fund' ||
      (categoryType === 'General' && subCategoryType === 'Other')
    ) {
      formData.totalWithdrawn = detailData.totalWithdrawn;
    }

    if (
      categoryType === 'Personal' ||
      subCategoryType === 'Saving' ||
      subCategoryType === 'Provident Fund' ||
      (categoryType === 'General' && subCategoryType === 'Other')
    ) {
      formData.marketValue = detailData.marketValue;
      formData.surrenderValue = detailData.surrenderValue;
      formData.marketValueUpdateDate = detailData.marketValueUpdateDate;
    }

    //field of mortage
    if (subCategoryType === 'Mortgage') {
      formData.propertyValue = detailData.propertyValue;
      formData.downPayment = detailData.downPayment;
      formData.loanRatio = detailData.loanRatio;
      formData.outStandingLoan = detailData.outStandingLoan;
      formData.interestRate = detailData.interestRate;
      formData.interestTerms = detailData.interestTerms;
      formData.penaltyPeriod = detailData.penaltyPeriod;
    }
    formData.paidUpDate = detailData.paidUpDate;
    formData.advisorName = apendixData.advisorName;
    formData.advisorPhoneNo = apendixData.advisorPhoneNo;
    formData.advisorPhoneNo2 = apendixData.advisorPhoneNo2;
    formData.advisorEmail = formatEmail(apendixData.advisorEmail);
    formData.productLink = apendixData.productLink;
    formData.remark = apendixData.remark;
    formData.uploadDocuments = apendixData.uploadDocuments;
    if (
      (categoryType === 'Personal' || categoryType === 'General') &&
      subCategoryType !== 'Mortgage' &&
      subCategoryType !== 'Provident Fund'
    ) {
      formData.policyDueDate = paymentData.policyDueDate;
      formData.reminderDate = paymentData.reminderDate;
      if (userDetails.userType === 'Adviser') {
        formData.adviserReminderActive = paymentData.adviserReminderActive;
      }
      formData.remindMeDay = paymentData.remindMeDay;
      formData.reminderActive = paymentData.reminderActive;
    }
    if (subCategoryType === 'Medical') {
      formData.smm = detailData.smm;
      formData.hospitalCash = detailData.hospitalCash;
      formData.outPatient = detailData.outPatient;
      formData.dental = detailData.dental;
      formData.deductible = detailData.deductible;
      formData.claimLimit = detailData.claimLimit;
    }
    if (subCategoryType === 'Critical Illness') {
      formData.multiClaim = detailData.multiClaim;
      formData.earlyStage = detailData.earlyStage;
    }
    if (categoryType === 'Personal' || (categoryType === 'General' && subCategoryType === 'Saving')) {
      formData.orphanCase = apendixData.orphanCase;
    }
    formData.isDraft = apendixData.isDraft;
    formData.attachments = apendixData.attachments;

    let addRider = JSON.parse(localStorage.getItem('addRider'));
    if (addRider === true) {
      localStorage.setItem('previousPolicyData', JSON.stringify(formData));
    }

    this.props.addPolicy(formData, clientId ? 'Adviser' : 'User');
    sessionStorage.removeItem('editPolicyDetails');
    sessionStorage.removeItem('editPolicy');
  }

  //function to set the page number
  nextPage(page) {
    const { history, location } = this.props;
    sessionStorage.setItem('editPolicyDetails', JSON.stringify(jsonParse(sessionStorage.getItem('editPolicyDetails'))));
    sessionStorage.setItem('editPolicy', JSON.stringify(jsonParse(sessionStorage.getItem('editPolicy'))));
    history.replace({ pathname: PAGE_PATHS[page + 1], state: location.state });
  }

  //function to set tha page number of previous page
  previousPage(page) {
    const { history, location } = this.props;
    sessionStorage.setItem('editPolicyDetails', JSON.stringify(jsonParse(sessionStorage.getItem('editPolicyDetails'))));
    sessionStorage.setItem('editPolicy', JSON.stringify(jsonParse(sessionStorage.getItem('editPolicy'))));
    history.replace({ pathname: PAGE_PATHS[page - 1], state: location.state });
  }

  selectNextPage = page => {
    const { history, location } = this.props;
    history.replace({ pathname: SELECT_PAGE_PATHS[page + 1], state: location.state });
  };

  selectPreviousPage = page => {
    const { history, location } = this.props;
    // omitting category and subCategory to avoid redirecting to the same page
    history.replace({
      pathname: SELECT_PAGE_PATHS[page - 1],
      state: _.omit(location.state, ['category', 'subCategory'])
    });
  };

  render() {
    const { easyAddPolicy } = this.props;

    var categoryId = '';
    var subCategoryId = '';
    var categoryType = {};
    var subCategoryType = {};
    const editPolicyDetails = jsonParse(sessionStorage.getItem('editPolicyDetails'));
    if (editPolicyDetails) {
      categoryId = editPolicyDetails.categoryId;
      subCategoryId = editPolicyDetails.subCategoryId;
      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }
    const addRider = JSON.parse(localStorage.getItem('addRider'));
    const editPolicy = jsonParse(sessionStorage.getItem('editPolicy'));
    return (
      <AppContentContainer>
        <div style={this.state.width > 800 ? { display: 'flex', height: '100vh' } : {}}>
          <div id="wrapper" style={{ flex: 1 }}>
            {this.props.location.pathname === '/existing_policy' && <ExistingPolicyView {...this.props} />}
            {this.props.location.pathname === '/add_portfolio' && (
              <AddPortfolioView {...this.props} handleNextPage={() => this.selectNextPage(0)} />
            )}
            {this.props.location.pathname === '/insurance_type' && (
              <InsuranceTypeView
                {...this.props}
                handleNextPage={() => this.selectNextPage(1)}
                handlePreviousPage={() => this.selectPreviousPage(1)}
              />
            )}
            {this.props.location.pathname === '/insurer' && (
              <InsurerView {...this.props} handlePreviousPage={() => this.selectPreviousPage(2)} />
            )}

            {(!editPolicy || addRider) && easyAddPolicy && _.get(subCategoryType, 'title') === 'Provident Fund' ? (
              <Fragment>
                {['/plan', '/payment', '/detail', '/apendix'].includes(this.props.location.pathname) && (
                  <SimplifiedAddMPF handleSubmit={this.sendData.bind(this)} />
                )}
              </Fragment>
            ) : (
              <Fragment>
                {this.props.location.pathname === '/plan' && (
                  <PlanView {...this.props} handleNextPage={() => this.nextPage(0)} />
                )}
                {this.props.location.pathname === '/payment' && (
                  <PaymentView
                    {...this.props}
                    handleNextPage={() => this.nextPage(1)}
                    handlePreviousPage={() => this.previousPage(1)}
                  />
                )}
                {this.props.location.pathname === '/detail' && (
                  <DetailView
                    {...this.props}
                    handleNextPage={() => this.nextPage(2)}
                    handlePreviousPage={() => this.previousPage(2)}
                  />
                )}
                {this.props.location.pathname === '/apendix' && (
                  <ApendixView
                    {...this.props}
                    handleSubmit={this.sendData.bind(this)}
                    handlePreviousPage={() => this.previousPage(3)}
                  />
                )}
              </Fragment>
            )}
          </div>
          {this.props.userDetails.userType === 'Adviser' &&
            this.state.width > 800 &&
            (this.props.location.pathname === '/apendix' ||
              this.props.location.pathname === '/detail' ||
              this.props.location.pathname === '/plan' ||
              this.props.location.pathname === '/payment') && (
              <div style={{ background: 'white', paddingTop: 50 }}>
                <Template overlay={true} />
              </div>
            )}
        </div>
      </AppContentContainer>
    );
  }
}

export default AddPolicyView;
