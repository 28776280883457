import React from 'react';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import NumberFormatTextField from '../../../../../components/NumberFormatTextField/NumberFormatTextField';
import { addThousandSeparators, countDecimals, roundNumber } from '../../../../../utils/formatNumber';
import { DEFAULT_PREMIUM_FINANCING_PROFILE } from '../../../../../constants/premium-financing';

const BonusCalculatorDialog = props => {
  const {
    intl,
    open,
    principal,
    loanPercentage,
    shouldPayPrincipal,
    base,
    disableLoan,
    onChange,
    onClose,
    onSubmit,
    ...rest
  } = props;

  let formattedShouldPayPrincipal =
    shouldPayPrincipal !== undefined ? shouldPayPrincipal : DEFAULT_PREMIUM_FINANCING_PROFILE.shouldPayPrincipal;

  const principalNetLoan = ((principal ?? 0) * (100 - (loanPercentage ?? 0))) / 100;

  formattedShouldPayPrincipal = formattedShouldPayPrincipal > principalNetLoan ? 0 : formattedShouldPayPrincipal;

  const firstYearBonus = roundNumber(principalNetLoan - (formattedShouldPayPrincipal ?? 0), 0);

  const onChangeShouldPayPrincipal = event => {
    // use null as empty value
    onChange({ target: { name: 'shouldPayPrincipal', value: event.target.value ?? null } });
  };

  const formattedOnSubmit = () => {
    onSubmit(firstYearBonus);
    onClose();
  };

  return (
    <Dialog maxWidth="xs" open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'pfa-bonus-cal-dialog-title' })}</DialogTitle>
      <DialogContent>
        {intl.formatMessage({ id: 'pfa-bonus-cal-dialog-content' })}
        <Grid container spacing={1} className="pfa-input-panel">
          <Grid item xs={12} className="input-control">
            <Grid container>
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'pfa-should-pay-principal' })}
              </Grid>
              <Grid item xs={6}>
                <NumberFormatTextField
                  name="shouldPayPrincipal"
                  value={formattedShouldPayPrincipal}
                  onChange={onChangeShouldPayPrincipal}
                  inputProps={{
                    allowNegative: false,
                    prefix: '$',
                    decimalScale: 0,
                    isAllowed: values => {
                      const { formattedValue, value } = values;
                      return formattedValue === '' || (value >= 0 && value <= (principalNetLoan ?? 0));
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'pfa-bonus' })}
              </Grid>
              <Grid item xs={6} style={{ color: 'grey', fontSize: '110%' }}>
                ${addThousandSeparators(firstYearBonus)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" disabled={firstYearBonus < 0} onClick={formattedOnSubmit}>
          {intl.formatMessage({ id: 'paste-use' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(BonusCalculatorDialog);
