import { fetch } from '../../utils';
import Backend from '../../backend';

export const getFunctions = async () =>
  fetch(Backend.methodUrl('functions'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
