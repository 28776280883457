import produce from 'immer';

/***********************************
 * Action Types
 ***********/
export const UPDATE_ROUTER = 'UPDATE_ROUTER';

/***********************************
 * Initial State
 ***********/
const initialLocation = {
  pathname: window.location.pathname,
  search: window.location.search,
  hash: window.location.hash
};

const initialState = {
  currentLocation: initialLocation,
  currentAction: 'INIT',
  previousLocation: undefined,
  previousAction: undefined,
  callStacks: [
    {
      location: initialLocation,
      action: 'INIT'
    }
  ],
  navigationStacks: [
    {
      location: initialLocation,
      action: 'INIT'
    }
  ]
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_ROUTER:
        const { location, action: historyAction } = action.data;
        const { currentLocation, currentAction, callStacks } = state;

        draft.callStacks = [
          {
            location: location,
            action: historyAction
          }
        ].concat(callStacks);

        draft.currentLocation = location;
        draft.currentAction = historyAction;

        if (historyAction === 'REPLACE') {
          draft.navigationStacks[0] = {
            location: location,
            action: historyAction
          };
        } else if (historyAction === 'POP') {
          draft.previousLocation = draft.navigationStacks.length <= 1 ? undefined : draft.navigationStacks[1].location;
          draft.previousAction = draft.navigationStacks.length <= 1 ? undefined : draft.navigationStacks[1].action;

          draft.navigationStacks = draft.navigationStacks.slice(1);
        } else {
          draft.navigationStacks = [
            {
              location: location,
              action: historyAction
            }
          ].concat(draft.navigationStacks);

          draft.previousLocation = currentLocation;
          draft.previousAction = currentAction;
        }

        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const updateRouter = ({ location, action }) => ({
  type: UPDATE_ROUTER,
  data: { location, action }
});
