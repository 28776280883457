import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import { BAR_COLOR } from './PVPChartsView';

class PVPHorizontalBar extends React.Component {
  render() {
    const { intl, options, exampleValue } = this.props;
    let { data } = this.props;

    if (data.labels.length === 0) {
      data.labels.push(intl.formatMessage({ id: 'Example' }));
      data.datasets[0].data.push(exampleValue);
      data.datasets[0].backgroundColor = BAR_COLOR.EXAMPLE;
      data.datasets[0].borderColor = BAR_COLOR.EXAMPLE;
    }

    return <HorizontalBar data={data} options={options} />;
  }
}

export default injectIntl(PVPHorizontalBar);
