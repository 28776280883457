import { fetch } from '../../utils';
import Backend from '../../backend';

//API TO SHARE THE DOCUMENT
export const shareDocuments = data => {
  return fetch(Backend.methodUrl('shareDocuments'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE  SHARE THE DOCUMENT WHEN USER GO TO THROUGH THE LINK
export const getShareDocuments = data => {
  return fetch(Backend.methodUrl('getShareDocuments'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE SHARE DOCUMENT ON DASHBOARD
export const getShareMeDocuments = data => {
  return fetch(Backend.methodUrl('getShareMeDocuments'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE SHARE TO LIST
export const getShareToData = () => {
  return fetch(Backend.methodUrl('getShareToData'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET SHARE FROM LIST
export const getShareFromData = () => {
  return fetch(Backend.methodUrl('getShareFromData'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO ENABLE DISABLE THE POLICY OF SHARE TO LIST
export const updateShareToList = data => {
  return fetch(Backend.methodUrl('updateShareToList'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO DELETE THE SHARE LIST OF USER CONNECTION OF ADVISER
export const deleteShareList = id => {
  return fetch(Backend.methodUrl('deleteShareList') + '/' + id, {
    method: 'DELETE'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { error };
    });
};

//API TO DELETE THE SHARE FROM LIST
export const deleteShareFromList = id => {
  return fetch(Backend.methodUrl('deleteShareFromList') + '/' + id, {
    method: 'DELETE'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { error };
    });
};

//API TO UPDATE THE SHAREDETAIL  IN CASE OF OVERWRITE THE SHARE RECORD WHILE SHARING
export const updateShareDetails = data => {
  return fetch(Backend.methodUrl('updateShareDetails'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO NOTIFY THE ADVISER AS A ADVISER IF USER SHARED AS A ADVISER BY EMAIL AND THAT EMAIL IS NOT REGISTERED AS ADVISER
export const noticeAdviserToRegister = data => {
  return fetch(Backend.methodUrl('noticeAdviserToRegister'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const shareWithAdviser = () => {
  return fetch(Backend.methodUrl('shareWithAdviser'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getShareById = id => {
  return fetch(Backend.methodUrl(`share/${id}`), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const acceptShareById = id => {
  return fetch(Backend.methodUrl(`share/accept/${id}`), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
