import React from 'react';
import { injectIntl } from 'react-intl';

const QuickClosing = props => {
  const { intl } = props;
  return (
    <div className="quick-closing">
      <iframe
        title={intl.formatMessage({ id: 'TeamUp' })}
        src="https://w3.portfoplus.com/partner/one-dynasty-%e7%8d%a8%e5%ae%b6%e7%b0%bd%e5%96%aetips"
        frameBorder={0}
        width={window.innerWidth}
        height={window.innerHeight - 95}
        style={{ marginTop: 45 }}
      />
    </div>
  );
};

export default injectIntl(QuickClosing);
