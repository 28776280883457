import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import MyWhiteboard from '../MyWhiteboard';

const ExitWhiteboardDialog = props => {
  const { intl, open, onClose, ...rest } = props;

  const formattedOnConfirm = () => {
    MyWhiteboard.shortcutFunctions.saveWhiteboardAsJson();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'whiteboard-save-json-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'whiteboard-save-json-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'No-need' })}
        </Button>
        <Button color="primary" onClick={formattedOnConfirm}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ExitWhiteboardDialog);
