import React from 'react';
import { Badge, IconButton, Portal } from '@material-ui/core';
import NoteEditorModal from '../../views/Advisor/Dashboard/partials/business/NoteEditorModal/NoteEditorModal';
import './FooterNav.scss';
import { isNavAside } from '../../utils/device';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ClientNavBarDrawer from '../ClientNavBarDrawer/ClientNavBarDrawer';
import _ from 'lodash';
import MyResizeObserver from '../MyResizeObserver/MyResizeObserver';
import { tracking } from '../../utils/tracking';

export default class FooterNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      noteEditorDialog: false,
      openClientNavDrawer: false
    };
    this.navRef = React.createRef();
  }

  componentDidMount(prevProps, prevState, snapshot) {
    this.handleScroll();
  }

  clickBottomNav(url, state) {
    const { location, toggleDialog } = this.props;
    let pathsToCheck = [
      '/insurance_type',
      '/insurer',
      '/plan',
      '/payment',
      '/detail',
      '/apendix',
      '/client_personal_form'
    ];

    if (pathsToCheck.indexOf(location.pathname) > -1) {
      toggleDialog('discardWarning', { onConfirm: () => this.goBottomNav(url) });
    } else {
      this.goBottomNav(url, state);
    }
  }

  goBottomNav(url, state) {
    const { history } = this.props;
    if (state) {
      history.push({ pathname: url, state });
    } else {
      history.push({ pathname: url });
    }
  }

  clearClientsStorage() {
    localStorage.removeItem('clientId');
    localStorage.removeItem('arrowRotateClient');
    localStorage.removeItem('scrollCoordinate');
  }

  openNotePad = () => {
    // Tracking
    tracking('Access Tools: My Notes');
    this.setState({ noteEditorDialog: true });
  };
  closeNotePad = () => this.setState({ noteEditorDialog: false });

  handleScroll = () => {
    const element = _.get(this.navRef, 'current');
    if (element) {
      const navHomeButtonElem = document.querySelector('.nav-home-btn');
      if (navHomeButtonElem) {
        navHomeButtonElem.style.marginTop = window.innerWidth >= 960 ? -35 - element.scrollTop + 'px' : -5;
      }
    }
  };

  render() {
    const { intl, adviserSubscriptionNickname, location, history } = this.props;
    const isSideNav = isNavAside();
    const positionClass = isSideNav ? 'footer-nav side-nav' : 'footer-nav';
    const user = this.props.userDetails;
    let userType = user && user.userType;
    const isProAdviser = adviserSubscriptionNickname === 'Pro' || adviserSubscriptionNickname === 'Ultra';
    let clickAdviser = false;
    if (userType === 'User' && user.advisorDetails && user.advisorDetails.length > 0) {
      // eslint-disable-next-line
      user.advisorDetails.map((val, index) => {
        if (val.connection === true) {
          clickAdviser = true;
        }
      });
    }
    return (
      <>
        <MyResizeObserver targetsSelector=".footer-nav" onResize={this.handleScroll} />
        {user && user.userType === 'User' && (
          <div ref={this.navRef} className={positionClass} onScroll={this.handleScroll}>
            <ul>
              <li className={location.pathname === '/c-dashboard' ? 'active' : ''}>
                <div
                  onClick={() => {
                    tracking('User Function Accessed: Open Portfolio from navigation bar');
                    this.clickBottomNav('/c-dashboard');
                  }}
                >
                  <img alt="" src="img/doughnut_client_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/doughnut_client_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'Portfolio' })}</span>
                </div>
              </li>
              <li
                className={
                  location.pathname === '/c-analysis' || location.pathname === '/need-analysis' ? 'active' : ''
                }
              >
                <div
                  onClick={() => {
                    tracking('User Function Accessed: Open Analysis from side navigation');
                    this.clickBottomNav('/c-analysis');
                  }}
                >
                  <img alt="" src="img/combo_chart_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/combo_chart_grey.svg" className="non-active-img img-responsive" />
                  <Badge
                    variant="dot"
                    className="navigation-referral-new-badge footer-badge hint footer-badge"
                    color="error"
                    badgeContent=""
                  >
                    {intl.formatMessage({ id: 'Analysis' })}
                  </Badge>
                </div>
              </li>
              <li>
                <div className="nav-home-div">
                  <Portal container={document.body} disablePortal={!isSideNav}>
                    <img
                      alt="home"
                      src="img/user_mainbutton.png"
                      className="nav-home-btn link"
                      style={{
                        ...{ background: 'transparent' },
                        ...(isSideNav && { top: 'calc(215px + var(--top-padding))', left: -4, zIndex: 1043 })
                      }}
                      onClick={() => this.setState({ openClientNavDrawer: true })}
                    />
                  </Portal>
                </div>
              </li>
              {/*<li>*/}
              {/*  <div style={{height: '50px', width: '60px', backgroundColor: 'white'}}>*/}
              {/*    <IconButton */}
              {/*      style={{ background: 'transparent', transform: isSideNav ? 'translate(-12px,-20px)' : 'translate(-12px,-32px)' }}*/}
              {/*      onClick={() => this.setState({openClientNavDrawer: true})}>*/}
              {/*      <AddCircleRoundedIcon*/}
              {/*        style={{width: '60px', height: '60px'}}*/}
              {/*        color="primary" />*/}
              {/*    </IconButton>*/}
              {/*  </div>*/}
              {/*</li>*/}
              <li className={location.pathname === '/client-tools' ? 'active' : ''}>
                <div
                  onClick={() => {
                    tracking('User Function Accessed: Open Tools from navigation bar');
                    this.clickBottomNav('/client-tools');
                  }}
                >
                  <img alt="" src="img/tools.svg" className="active-img img-responsive" />
                  <img alt="" src="img/tools_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'client-tools' })}</span>
                </div>
              </li>
              <li className={location.pathname === '/my-profile' ? 'active' : ''}>
                <div
                  onClick={() => {
                    this.clickBottomNav('/my-profile');
                  }}
                >
                  {/*<img alt="" src="img/myProfile_blue.svg" className="active-img img-responsive" />*/}
                  <img alt="" src="img/myProfile_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/myProfile_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'my-profile' })}</span>
                </div>
              </li>
              {isSideNav && (
                <li>
                  <div style={{ width: '100%', borderBottom: 'solid 1px rgba(0, 0, 0, 0.20)' }}></div>
                </li>
              )}
              {isSideNav && (
                <li className={this.props.history.location.pathname === '/emergency_info' ? 'active' : ''}>
                  <div
                    onClick={() => {
                      tracking('User Function Accessed: Open Emergency Info from navigation bar');
                      this.clickBottomNav('/emergency_info');
                    }}
                  >
                    <img alt="" src="img/emergency_color.png" className="new-icon img-responsive" />
                    <span className={intl.formatMessage({ id: 'infoEmergency' }) === 'Emergency' ? 'small-font' : ''}>
                      {intl.formatMessage({ id: 'infoEmergency' })}
                    </span>
                  </div>
                </li>
              )}
              {isSideNav && (
                <li className={this.props.history.location.pathname === '/existing_policy' ? 'active' : ''}>
                  <div
                    onClick={() => {
                      tracking('User Function Accessed: Open Add Policy from side navigation');
                      this.clickBottomNav('/existing_policy');
                    }}
                  >
                    <img alt="" src="img/useraddpolicy.png" className="new-icon img-responsive" />
                    <span className={intl.formatMessage({ id: 'Add Policy' }) === 'Add Policy' ? 'small-font' : ''}>
                      {intl.formatMessage({ id: 'Add Policy' })}
                    </span>
                  </div>
                </li>
              )}
              {isSideNav && this.props.history.location.pathname === '/dashboard' && (
                <li className={this.props.guidelineIsStarted ? 'active' : ''}>
                  <div
                    onClick={() => {
                      tracking('User Function Accessed: Run Dashboard guideline from navigation bar');
                      this.props.runGuideline('userDashboard');
                    }}
                  >
                    <img alt="" src="img/helpCenter.png" className="new-icon img-responsive" />
                    <span>{intl.formatMessage({ id: 'Guide' })}</span>
                  </div>
                </li>
              )}
            </ul>
            <ClientNavBarDrawer
              history={history}
              intl={intl}
              open={this.state.openClientNavDrawer}
              openClientNavDrawer={() => this.setState({ openClientNavDrawer: true })}
              closeClientNavDrawer={() => this.setState({ openClientNavDrawer: false })}
            />
          </div>
        )}
        {user && user.userType === 'Adviser' && (
          <div ref={this.navRef} className={positionClass} onScroll={this.handleScroll}>
            <ul>
              {isSideNav && (
                <li>
                  <div onClick={() => this.clickBottomNav('/home')}>
                    <img
                      alt="home"
                      src={location.pathname === '/home' ? 'img/homeBlue.svg' : 'img/homeGrey.svg'}
                      style={{ height: 33 }}
                    />
                    <span>{intl.formatMessage({ id: 'mainhomepage' })}</span>
                  </div>
                </li>
              )}
              <li className={location.pathname === '/advisorDashboard' ? 'active' : ''}>
                <div
                  onClick={() => {
                    tracking('Policy Portfolio Accessed');
                    this.clickBottomNav('/advisorDashboard');
                  }}
                >
                  <img alt="" src="img/control_panel_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/control_panel_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'Big Data' })}</span>
                </div>
              </li>
              <li className={location.pathname === '/adviser_business' ? 'active' : ''}>
                <div
                  onClick={() => {
                    tracking('Adviser Business Accessed');
                    this.clickBottomNav('/adviser_business');
                  }}
                >
                  <img alt="" src="img/bizmain_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/bizmain_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'Business' })}</span>
                  {/*newReferralCount > 0 ? (
                    <Badge
                      className="navigation-referral-new-badge footer-badge"
                      color="primary"
                      badgeContent={newReferralCount}
                    >
                      {intl.formatMessage({ id: 'Get Users' })}
                    </Badge>
                  ) : (
                    <span>{intl.formatMessage({ id: 'Get Users' })}</span>
                  )*/}
                  <span />
                </div>
              </li>

              {!isSideNav && (
                <li className={location.pathname === '/home' ? 'active' : ''}>
                  <div className="nav-home-div" onClick={() => this.clickBottomNav('/home')}>
                    <Portal container={document.body} disablePortal={!isSideNav}>
                      <img
                        alt="home"
                        src={`img/home_button_${location.pathname === '/home' ? 'blue' : 'grey'}.png`}
                        className="nav-home-btn"
                        style={{
                          ...(isSideNav && { top: 'calc(225px + var(--top-padding))', left: -4, zIndex: 1043 })
                        }}
                      />
                    </Portal>
                  </div>
                </li>
              )}

              <li
                className={
                  location.pathname === '/client' ||
                  location.pathname === '/client_factsheet' ||
                  location.pathname === '/client_personal_form' ||
                  location.pathname === '/emergency_reference'
                    ? 'active'
                    : ''
                }
              >
                <div
                  onClick={() => {
                    tracking('Client Page Accessed');
                    this.clearClientsStorage.bind(this);
                    this.clickBottomNav('/client');
                  }}
                >
                  <img alt="" src="img/management_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/management_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'Client' })}</span>
                </div>
              </li>

              <li className={location.pathname === '/my-profile' ? 'active' : ''}>
                <div
                  onClick={() => {
                    tracking('My Profile Accessed');
                    this.clickBottomNav('/my-profile');
                  }}
                >
                  <img alt="" src="img/myProfile_blue.svg" className="active-img img-responsive" />
                  <img alt="" src="img/myProfile_grey.svg" className="non-active-img img-responsive" />
                  <span>{intl.formatMessage({ id: 'my-profile' })}</span>
                </div>
              </li>

              {/* <li
                className={
                  location.pathname === '/adviser_tools' ||
                  location.pathname === '/insurer_info' ||
                  location.pathname === '/insurer_detail' ||
                  location.pathname === '/help_center' ||
                  location.pathname === '/cheque_out' ||
                  location.pathname === '/hot_topic'
                    ? 'active'
                    : ''
                }
              >
                <div
                  onClick={() => {
                    this.clickBottomNav('/adviser_tools');
                  }}
                >
                  <img alt="" src="img/tools.png" className="active-img img-responsive" />
                  <img alt="" src="img/tools_grey.png" className="non-active-img img-responsive" />
                  <Badge
                    variant="dot"
                    className="navigation-referral-new-badge footer-badge hint footer-badge"
                    color="error"
                    badgeContent=""
                  >
                    {intl.formatMessage({ id: 'Tools' })}
                  </Badge>
                </div>
              </li> */}

              {/* <li
                                        className={location.pathname === '/referral' ? 'active' : ''}
                                      >
                                        <a
                                            onClick={() => {
                                                this.clickBottomNav('/referral');
                                            }}
                                        >
                                            <img
                                                src="img/adviser_profile_blue.png"
                                                className="active-img img-responsive"
                                            />
                                            <img
                                                src="img/adviser_profile_grey.png"
                                                className="non-active-img img-responsive"
                                            />
                                            {newReferralCount > 0 ? (
                                                <Badge
                                                    className="navigation-referral-new-badge footer-badge"
                                                    color="primary"
                                                    badgeContent={newReferralCount}
                                                >
                                                    {intl.formatMessage({ id: 'Profile' })}
                                                </Badge>
                                            ) : (
                                                    <span>{intl.formatMessage({ id: 'Profile' })}</span>
                                                )}
                                        </a>
                                    </li> */}

              {/*<li*/}
              {/*  className={*/}
              {/*    location.pathname === '/referral' ||*/}
              {/*    location.pathname === '/personalData' ||*/}
              {/*    location.pathname === '/get_users' ||*/}
              {/*    location.pathname === '/referral_records'*/}
              {/*      ? 'active'*/}
              {/*      : ''*/}
              {/*  }*/}
              {/*>*/}
              {/*  <div*/}
              {/*    onClick={() => {*/}
              {/*      this.clickBottomNav('/referral');*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <img alt="" src="img/addUser.png" className="active-img img-responsive" />*/}
              {/*    <img alt="" src="img/add_user_group_grey.png" className="non-active-img img-responsive" />*/}
              {/*    /!* <span>{intl.formatMessage({ id: 'Get Users' })}</span> *!/*/}
              {/*    {newReferralCount > 0 ? (*/}
              {/*      <Badge*/}
              {/*        variant="dot"*/}
              {/*        className="navigation-referral-new-badge footer-badge hint footer-badge"*/}
              {/*        color="error"*/}
              {/*        badgeContent={newReferralCount}*/}
              {/*      >*/}
              {/*        {intl.formatMessage({ id: 'Referral' })}*/}
              {/*      </Badge>*/}
              {/*    ) : (*/}
              {/*      <span>{intl.formatMessage({ id: 'Referral' })}</span>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</li>*/}
              {isSideNav && (
                <li>
                  <div style={{ width: '100%', borderBottom: 'solid 1px rgba(0, 0, 0, 0.20)' }}></div>
                </li>
              )}
              {isSideNav && (
                <li className={this.props.todoOpen ? 'active' : ''}>
                  <div
                    onClick={() => {
                      this.props.todoOpen ? this.props.closeTodo() : this.props.openTodo();
                    }}
                  >
                    <img alt="" src="img/todo_colour.png" className="new-icon img-responsive" />
                    <span>ToDo</span>
                  </div>
                </li>
              )}
              {isSideNav && (
                <li className={this.state.noteEditorDialog ? 'active' : ''}>
                  <div
                    onClick={() => {
                      this.openNotePad();
                    }}
                  >
                    <img alt="" src="img/mynotes.png" className="new-icon img-responsive" />
                    <span>{intl.formatMessage({ id: 'Notes' })}</span>
                  </div>
                </li>
              )}
              {isSideNav && (
                <li className={this.props.templateOpen ? 'active' : ''}>
                  <div
                    onClick={() => {
                      this.props.templateOpen ? this.props.closeTemplate() : this.props.openTemplate();
                    }}
                  >
                    <img alt="" src="img/copybook.png" className="new-icon img-responsive" />
                    <span>{intl.formatMessage({ id: 'template' })}</span>
                  </div>
                </li>
              )}
              {isSideNav &&
                ((user.teamLeaderOf && user.teamLeaderOf.length > 0) ||
                  (user.companyLeaderOf && user.companyLeaderOf.length > 0)) && (
                  <li className={location.pathname === '/advisor_team' ? 'active' : ''}>
                    <div
                      onClick={() => {
                        this.clickBottomNav('/advisor_team');
                      }}
                    >
                      <img alt="" src="img/team.png" className="new-icon img-responsive" />
                      <span>{intl.formatMessage({ id: 'Team' })}</span>
                    </div>
                  </li>
                )}
              {isSideNav && (
                <li className={location.pathname === '/new_help_center' ? 'active' : ''}>
                  <div
                    onClick={() => {
                      this.clickBottomNav('/new_help_center');
                    }}
                  >
                    <img alt="" src="img/helpCenter.png" className="new-icon img-responsive" />
                    <span>{intl.formatMessage({ id: 'Help' })}</span>
                  </div>
                </li>
              )}
            </ul>
            <NoteEditorModal open={this.state.noteEditorDialog} onClose={this.closeNotePad} />
          </div>
        )}
      </>
    );
  }
}
