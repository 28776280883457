import React from 'react';
import { tracking } from '../../../../../utils/tracking';

class ContactButton extends React.Component {
  render() {
    const { onClick, className, noContactIconClass, msgOpt, msgOptButton, customizeClassName } = this.props;
    return (
      <span className={className} style={{ marginRight: '0px' }}>
        {msgOptButton ? (
          <img
            alt="contact"
            className={`link ${!noContactIconClass ? 'imageClientState' : ''} ${customizeClassName}`}
            //src={active ? 'img/chat_bubble_blue2.png' : 'img/chat_bubble_grey2.png'}
            src={msgOpt ? 'img/msgOptIn.png' : 'img/msgOptOut.png'}
            onClick={() => {
              tracking('Click on Contact - Client');
              onClick();
            }}
          />
        ) : (
          <img
            alt="contact"
            className={`link ${!noContactIconClass ? 'imageClientState' : ''} ${customizeClassName}`}
            //src={active ? 'img/chat_bubble_blue2.png' : 'img/chat_bubble_grey2.png'}
            src="img/chat_bubble_blue2.png"
            onClick={() => onClick()}
          />
        )}
      </span>
    );
  }
}

export default ContactButton;
