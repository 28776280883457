import React, { useState } from 'react';
import MyEditor from '../../components/MyEditor/MyEditor';
import { formatEditorStateValue } from '../../utils/editor';
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';
import { SlideTransition } from '../../components/Transition/Transition';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { setShowedTeamBlast } from '../../store/notificationCenter/duck';

const CloseButtonStyle = { position: 'absolute', top: 0, right: 0, width: '48px', zIndex: 1 };
const ContentStyle = { paddingLeft: '8px', paddingRight: '8px' };

const TeamBlastPopUp = ({ markAsRead, teamBlastPop, previewOpen, onClose, previewMode }) => {
  const [open, setOpen] = useState(!previewMode);
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const closeDialog = () => setOpen(false);

  const markAndClose = () => {
    if (previewMode) {
      onClose();
    } else {
      closeDialog();
      markAsRead(teamBlastPop);
      dispatch(setShowedTeamBlast(teamBlastPop._id));
    }
  };

  return (
    <Dialog open={open || previewOpen} fullWidth={fullWidth} onClose={markAndClose} TransitionComponent={SlideTransition}>
      <DialogContent style={ContentStyle} className="dialog-content-centered">
        <IconButton onClick={markAndClose} style={CloseButtonStyle}>
          <CloseIcon />
        </IconButton>
        <MyEditor readOnly={true} editorState={formatEditorStateValue(teamBlastPop && teamBlastPop.detail ? teamBlastPop.detail : '')} />
      </DialogContent>
    </Dialog>
  );
};

export default TeamBlastPopUp;
