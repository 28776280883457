import React from 'react';
import { injectIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import LanguageRadioGroup from '../LanguageRadioGroup/LanguageRadioGroup';
import ShareButtonGroup from '../ShareButtonGroup/ShareButtonGroup';

const ChooseLanguageMessages = props => {
  const { intl, titleIntlId, language, translatedMessage, onChange, LanguageRadioGroupProps } = props;

  return (
    <div className="choose-lang-messages">
      <Typography>{intl.formatMessage({ id: titleIntlId })}</Typography>
      <LanguageRadioGroup value={language} onChange={onChange} />
      <ShareButtonGroup
        title={translatedMessage}
        url=""
        {...LanguageRadioGroupProps}
      />
    </div>
  );
};

export default injectIntl(ChooseLanguageMessages);
