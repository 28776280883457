import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import { toggleDialog } from '../../../store/control/duck';
import { updateClientLanguage } from '../../../store/client/duck';

const INITIAL_STATE = { language: undefined };

class ChooseClientLanguageDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  close = () => this.props.toggleDialog('chooseLanguage', { ...this.props.chooseLanguageDialog, closed: true });
  closed = () => {
    this.props.toggleDialog('chooseLanguage', false);
    this.setState(INITIAL_STATE);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { chooseLanguageDialog, updateLanguagePhase, toggleDialog } = nextProps;
    if (updateLanguagePhase === true && this.props.updateLanguagePhase !== true) {
      toggleDialog('connection', chooseLanguageDialog);
      this.props.toggleDialog('chooseLanguage', false);
    }
  }

  submit = () => {
    const { chooseLanguageDialog, onSubmit } = this.props;
    const { language } = this.state;
    if (typeof chooseLanguageDialog === 'object' && chooseLanguageDialog.client) {
      this.props.updateClientLanguage({
        userType: chooseLanguageDialog.client.inactiveUser ? 'inactiveUser' : 'user',
        clientId: chooseLanguageDialog.client._id,
        language: language
      });
    }
    if (onSubmit) onSubmit(language);
  };

  render() {
    const { intl, chooseLanguageDialog } = this.props;
    const { language } = this.state;

    const { closed } = chooseLanguageDialog;

    return (
      <Dialog
        open={!!chooseLanguageDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'client-language' })}</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Button
            variant={language === 'en' ? 'contained' : 'outlined'}
            className={`option-select-button plan-type-select-button ${language === 'en' ? 'selected' : ''}`}
            onClick={() => this.setState({ language: 'en' })}
          >
            {intl.formatMessage({ id: 'English' })}
          </Button>
          <Button
            variant={language === 'zh-Hant-HK' ? 'contained' : 'outlined'}
            className={`option-select-button plan-type-select-button ${language === 'zh-Hant-HK' ? 'selected' : ''}`}
            onClick={() => this.setState({ language: 'zh-Hant-HK' })}
          >
            {intl.formatMessage({ id: 'Chinese HK' })}
          </Button>
          <Button
            variant={language === 'zh' ? 'contained' : 'outlined'}
            className={`option-select-button plan-type-select-button ${language === 'zh' ? 'selected' : ''}`}
            onClick={() => this.setState({ language: 'zh' })}
          >
            {intl.formatMessage({ id: 'Chinese CN' })}
          </Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.submit} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    chooseLanguageDialog: state.control.chooseLanguageDialog,
    updateLanguagePhase: state.client.updateLanguagePhase
  }),
  // Map actions to props
  {
    toggleDialog,
    updateClientLanguage
  },
  // mergeProps
  null,
  // options
  {}
)(ChooseClientLanguageDialog);

export default injectIntl(container);
