import { fetch } from '../../utils';
import Backend from '../../backend';

export const getEmergencyExport = async clientId => {
  return fetch(Backend.methodUrl(`emergency-exports/${clientId}`))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const updateEmergencyExport = async (clientId, emergencyExport) => {
  return fetch(Backend.methodUrl(`emergency-exports/${clientId}`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(emergencyExport)
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
