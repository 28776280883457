
import React, { PureComponent, Fragment } from 'react';
import RequestDeletionView from './component/request_view';
import RequestSuccessView from './component/success_view';
import _ from 'lodash';
import { toast } from 'react-toastify';
import IconToast from '../../components/NewToast';
import { Container } from '@material-ui/core';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';
import AccountDeletionAlertView from './component/alert_view';

export default class AccountDeletionComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: '',
            verifiedEmail: (props.userDetails.email).toLowerCase(),
            survey: [],
            err: {},
            completed: localStorage.getItem('accountDeletionRequested') ? true : false,
            loading: false,
            alertOn: false,
            alertPassed: false
        };
        this.submitForm = this.submitForm.bind(this);
        this.backToHome = this.backToHome.bind(this);
        this.alert = this.alert.bind(this);
        this.onNext = this.onNext.bind(this);
        this.vaildation = this.vaildation.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    componentDidMount() {
        //to display the page language as per the browser language
        var user = this.props.userDetails;
        if (_.isEmpty(user)) {
            var userLang = navigator.language || navigator.userLanguage;
            const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
            this.props.selectedLocale(language);
        }
    }

    alert(alertOn) {
        this.setState({ alertOn: alertOn })
    }
    onCancel() {
        // console.log('proceed to next step::: on Cancel')
        this.setState({ alertOn: false, loading: false, alertPassed: false, err: {} })
    }


    onNext() {
        this.setState({ alertPassed: true, alertOn: false, loading: true })
        this.submitForm(this.state.inputEmail, this.state.survey)
    }

    //sending the new password to update in users record
    vaildation(email, survey) {
        const { verifiedEmail } = this.state;
        const _err = {};
        const _email = email.toLowerCase();
        // console.log('this.props.userDetails.email: ', verifiedEmail)
        // console.log('this.input.email: ', _email)
        if (_email === verifiedEmail) {
        }
        else if (!_email) {
            _err.email = 'Email is required';
        }
        else if (_email !== verifiedEmail) {
            _err.email = 'account-deletion-email-comfirm-error';
        } else {
            _err.email = 'account-deletion-unknown-error';
        }

        if (Object.keys(_err).length) {
            this.setState({ err: _err, loading: false, alertOn: false, alertPassed: false });
        } else {
            this.setState({
                err: {},
                inputEmail: _email,
                survey: survey,
                loading: true,
                alertOn: true,
                alertPassed: false
            });
        }
    }

    submitForm(email, survey) {
        // console.log('proceed to next step::: Account Deletion')

        const { intl, requestAccountDeletion } = this.props;
        requestAccountDeletion(email, survey).then((response) => {
            this.setState({ completed: true, loading: false });
        }).catch((error) => {
            toast.error(<IconToast message={intl.formatMessage({ id: 'account-deletion-request-failed' })} />);
            this.setState({ loading: false });
        });
    }

    backToHome() {
        const { history } = this.props;
        history.push({ pathname: '/home' });
    }



    render() {
        return <AppContentContainer style={{ height: 'auto' }
        } >
            <Container maxWidth="sm">
                <Fragment>
                    <AccountDeletionAlertView
                        open={this.state.alertOn}
                        onClose={this.onCancel}
                        onConfirm={this.onNext}
                        title={this.props.intl.formatMessage({ id: 'account-deletion-alert-title' })}
                        message={this.props.intl.formatMessage({ id: 'account-deletion-alert-message' })}
                        confirmText={this.props.intl.formatMessage({ id: 'account-deletion-alert-cta-confirm' })}
                        closeText={this.props.intl.formatMessage({ id: 'account-deletion-alert-cta-close' })}
                    />
                </Fragment>
                <Fragment>
                    <div className='account-deletion wrapper' >
                        {
                            this.state.completed ?
                                <RequestSuccessView
                                    intl={this.props.intl}
                                    backToHome={this.backToHome}
                                /> :
                                <RequestDeletionView
                                    intl={this.props.intl}
                                    onSubmitForm={this.vaildation}
                                    err={this.state.err}
                                    loading={this.state.loading}
                                />
                        }
                    </div>
                </Fragment>
            </ Container>
        </AppContentContainer >
    }
}
