import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { decimalToString } from '../../../../utils/formatNumber';
import { getUserCurrency } from '../../../../utils/user';

const SavingSummary = props => {
  const { intl, userDetails, savingTotalCoverage } = props;

  const currency = getUserCurrency(userDetails);

  return (
    <div className="text-center cardAnalysis clearfix analysis-expansion-element greyL6 font16">
      <h4>{intl.formatMessage({ id: 'Total Monthly Saving' })}</h4>
      <div>
        <h3 className="figureColor">
          {intl.formatMessage({ id: currency })} {decimalToString(savingTotalCoverage)}{' '}
        </h3>
      </div>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    savingTotalCoverage: state.analysis.savingTotalCoverage
  }),
  {}
)(SavingSummary);

export default injectIntl(container);
