import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import Button from '@material-ui/core/Button';
import { injectIntl } from 'react-intl';
import { getContentJson } from '../utils';
import { getHotTopicContentsAllItems, getHotTopicItemSuggestions } from '../../../utils/hot-topic';
import { usePrevious } from '../../../utils/hooks';

const StandardContentTrigger = props => {
  const {
    intl,
    buttonIntlId,
    children,
    className,
    extraOnClick,
    contentLink,
    localContent,
    itemId,
    toggleDialog,
    ...rest
  } = props;

  const [item, setItem] = useState(undefined);
  const buttonRef = useRef(null);
  const prevContentLink = usePrevious(contentLink);

  const trigger = event => {
    toggleDialog('hotTopicItem', {
      item: item,
      DialogProps: {
        maxWidth: 'md',
        TransitionProps: {
          triggerTarget: buttonRef.current
        }
      }
    });

    if (extraOnClick) {
      extraOnClick(event);
    }
  };

  useEffect(() => {
    if (contentLink && prevContentLink !== contentLink) {
      // read contents from local if it is in development
      getContentJson({ contentLink, localContent }).then(data => {
        let targetItem;
        if (data) {
          data.some(category => {
            const target = category.items.find(item => item.id === itemId);
            if (target) {
              targetItem = target;
              return true;
            }
            return false;
          });

          if (targetItem) {
            const allContentItems = getHotTopicContentsAllItems(data);
            targetItem = {
              ...targetItem,
              suggestions: getHotTopicItemSuggestions(targetItem, allContentItems)
            };
          }
        }

        setItem(targetItem);
      });
    }
  }, [contentLink, itemId, localContent, prevContentLink]);

  return (
    <Button
      className={`standard-content-trigger ${className ? className : ''}`}
      variant="contained"
      color="primary"
      onClick={trigger}
      ref={buttonRef}
      {...rest}
    >
      {buttonIntlId ? intl.formatMessage({ id: buttonIntlId }) : children}
    </Button>
  );
};

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(StandardContentTrigger);

export default injectIntl(container);
