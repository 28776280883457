import { connect } from 'react-redux';
import { addMessage, fetchMessages } from '../../../store/message/duck';
import { handleSignOut } from '../../../store/user/duck';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';

import ToolsView from './component';

import { injectIntl } from 'react-intl';

const Tools = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    fetchMessagesPhase: state.message.fetchMessagesPhase,
    fetchMessageData: state.message.fetchMessageData,
    newReferralCount: state.referral.newReferralCount,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    getFigureAdviserPhase: state.adviserDashboard.getFigureAdviserPhase
  }),
  // Map actions to props
  {
    addMessage,
    fetchMessages,
    handleSignOut,
    renderHeaderButton
  }
)(ToolsView);

export default injectIntl(Tools);
