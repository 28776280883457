import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';

const useStyle = makeStyles({
  button: {
    minWidth: 'unset',
    height: 20,
    padding: '2px 6px',
    marginRight: 8
  },
  icon: {
    fontSize: '1.6rem'
  }
});

const ClientEntryMailButton = props => {
  const classes = useStyle();

  return (
    <Button
      variant="contained"
      color="primary"
      classes={{
        root: classes.button
      }}
      {...props}
    >
      <MailOutlineRoundedIcon classes={{ root: classes.icon }} />
    </Button>
  );
};

export default ClientEntryMailButton;
