import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import React from 'react';
import { Link } from 'react-router-dom';
import COVIDShareReferralDialog from './COVIDShareReferralDialog/COVIDShareReferralDialog';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';

class COVID extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shareReferralDialog: false
    };
  }
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  homeMonitor = () => this.setState({ shareReferralDialog: true });

  render() {
    const { intl } = this.props;
    const { shareReferralDialog } = this.state;

    return (
      <div>
        <COVIDShareReferralDialog
          dialog={shareReferralDialog}
          onClose={() => this.setState({ shareReferralDialog: false })}
        />
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid policyInsuranceType">
              <div className="row">
                <div className="col-lg-12">
                  <ul className="card-list">
                    <li>
                      <Link to="/covid-tools">
                        <div className="portfolioCard">
                          <img src="img/LocationSearch.png" alt="location-seaach" />
                          <h4>{intl.formatMessage({ id: 'covid-quick-check' })}</h4>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to={{ pathname: '/client', select: 'covid' }}>
                        <div className="portfolioCard">
                          <img src="img/search-virus.png" alt="scan-clients" />
                          <h4>{intl.formatMessage({ id: 'covid-clients-scan' })}</h4>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <div className="portfolioCard" onClick={this.homeMonitor}>
                        <img src="img/HomeAlarm.png" alt="home-monitor" />
                        <h4>{intl.formatMessage({ id: 'covid-home-monitor' })}</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {
    renderHeaderButton
  },
  // mergeProps
  null,
  // options
  {}
)(COVID);

export default injectIntl(container);
