export const getCalculationDetails = (
  {
    principal,
    loanPercentage,
    bankLoanInterest,
    policyInterest,
    term,
    overrideLoanInterest,
    termLoanPercentage,
    customizeYearlyAddOn,
    termAddOn
  },
  override
) => {
  let policyReturn = (principal * (policyInterest * term)) / 100;
  let bankLoan = principal * (loanPercentage / 100);
  let bankInterestExpense;
  if (overrideLoanInterest && override) {
    bankInterestExpense = 0;
    termLoanPercentage.forEach(e => {
      bankInterestExpense += (bankLoan * e) / 100;
    });
  } else {
    let onePeriodInterestExpense = (bankLoan * bankLoanInterest) / 100;
    bankInterestExpense = onePeriodInterestExpense * term;
  }
  if (customizeYearlyAddOn && override) {
    termAddOn.forEach(e => {
      if (e) {
        bankInterestExpense += e;
      }
    });
  }
  let initialOwnExpense = principal - bankLoan;
  let profit = policyReturn - bankInterestExpense;
  let profitRate =
    profit !== undefined && initialOwnExpense !== undefined ? (profit / initialOwnExpense) * 100 : undefined;
  let IRR = Math.pow(1 + profitRate / 100, 1 / term) - 1;

  return { bankLoanInterest, bankLoan, policyReturn, bankInterestExpense, initialOwnExpense, profit, profitRate, IRR };
};

export const getProfitByYear = ({
  principal,
  base,
  bankLoanInterest,
  yearsToShow,
  cashValueAt,
  termLoanPercentage,
  termAddOn,
  firstYearBonus,
  overrideLoanInterest
}) => {
  const baseValid = (Math.floor(base) ?? 0) <= (Math.floor(principal) ?? 0);
  const bonus = firstYearBonus ?? 0;
  const bankLoan = (principal ?? 0) - (base ?? 0) - bonus;
  const yearlyExpense = [0];
  const yearlyLoanPercentage =
    overrideLoanInterest && termLoanPercentage
      ? termLoanPercentage
      : Array.from(Array(Math.max(...yearsToShow) || 0).keys()).map(() => bankLoanInterest ?? 0);
  yearlyLoanPercentage.forEach((loanPercentage, i) => {
    const addOn = overrideLoanInterest && termAddOn ? termAddOn[i] || 0 : 0;
    const loanExpense = bankLoan * ((loanPercentage ?? 0) / 100);
    const totalExpense = addOn + loanExpense + (yearlyExpense[i] ?? 0);
    yearlyExpense.push(totalExpense);
  });
  const expenseWithYearsToShow = [];
  const returnWithYearsToShow = [];
  const profitRateWithYearsToShow = [];
  const totalAmount = [];
  const negativeReturn = [];
  const returnsForTable = [];
  const expenseForTable = [];
  const cashValueMinusLoan = [];
  const yearlyIRR = [];
  if (principal !== null && principal !== undefined && principal !== '' && baseValid) {
    yearsToShow.forEach((e, i) => {
      if (i !== 0 && (!e || !cashValueAt[i])) {
        return;
      }
      if (i === 0) {
        profitRateWithYearsToShow.push(0);
        totalAmount.push(principal ?? 0);
        negativeReturn.push(0);
        returnsForTable.push(0);
        expenseForTable.push(0);
        cashValueMinusLoan.push(0);
        yearlyIRR.push(0);
      } else {
        const yearExpense = yearlyExpense[e];
        const cashReturn = cashValueAt[i] - principal - yearExpense + bonus;
        cashValueMinusLoan.push(cashValueAt[i] - bankLoan);
        if (cashReturn < 0) {
          negativeReturn.push(cashValueAt[i]);
        } else {
          negativeReturn.push(0);
        }
        expenseForTable.push(yearExpense >= 0 ? yearExpense : 0);
        returnsForTable.push(cashReturn);
        profitRateWithYearsToShow.push(base ? (cashReturn / base) * 100 : 0);
        yearlyIRR.push((Math.pow(1 + profitRateWithYearsToShow[i] / 100, 1 / e) - 1) * 100);
        totalAmount.push(cashValueAt[i]);
      }
    });
  }
  returnsForTable.forEach((e, i) => {
    expenseWithYearsToShow.push(expenseForTable[i] > 0 && !negativeReturn[i] ? expenseForTable[i] : 0);
    returnWithYearsToShow.push(returnsForTable[i] > 0 ? returnsForTable[i] : 0);
  });

  return {
    expenseWithYearsToShow,
    returnWithYearsToShow,
    profitRateWithYearsToShow,
    totalAmount,
    negativeReturn,
    expenseForTable,
    returnsForTable,
    cashValueMinusLoan,
    yearlyIRR
  };
};

export const getProfitEvenBankLoanInterest = ({ loanPercentage, policyInterest, term }) => {
  // when policyReturn === bankInterestExpense => profit = 0 = even
  return (((policyInterest * term) / 100 / (loanPercentage / 100)) * 100) / term;
};

export const getTargetPolicyInterest = ({ principal, targetPolicyValue, term }) => {
  return ((targetPolicyValue / principal - 1) / term) * 100;
};
