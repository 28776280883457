import React from 'react';
import { Redirect } from 'react-router';

const WebRedirection = props => {
  const { location } = props;
  const { pathname, search, hash, state } = location;

  return <Redirect to={{ pathname: (pathname || '').replace('/web', ''), search, hash, state }} />;
};

export default WebRedirection;
