import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlProvider } from 'react-intl';
import ReferralCard from '../../../views/Advisor/Referral/partials/ReferralCard/ReferralCard';
import ShareButtonGroup from '../../ShareButtonGroup/ShareButtonGroup';
import _ from 'lodash';
import { getReferralLink } from '../../../utils/referral';
import { tracking } from '../../../utils/tracking';
import { Grid, IconButton } from '@material-ui/core';
import { getLocaleMessages } from '../../../utils/locale';
import LocaleMessageExtractor from '../../LocaleMessageExtractor/LocaleMessageExtractor';
import LanguageRadioGroup from '../../LanguageRadioGroup/LanguageRadioGroup';
import { isAndroid, isIOS } from 'react-device-detect';
import { getWalletPass } from '../../../store/wallet-pass/api';
import { hasCampaignCode } from '../../../utils/user';
import LoadingBackdrop from '../../LoadingBackdrop/LoadingBackdrop';
import ProfilePlusAddToEWalletDialog from './ProfilePlusAddToEWalletDialog/ProfilePlusAddToEWalletDialog';
import { isAdviserType } from '../../../utils/user';

const ReferralQRCodeContent = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    intl,
    userDetails,
    teamCampaignCodes,
    teamLogoUrl,
    connectedAdvisers,
    customCTAMessage,
    wallet,
    referLinkSearch,
    defaultMessageIntlId,
    defaultMessageExtras,
    ...rest
  } = props;

  const [locale, setLocale] = useState(intl.locale);
  const [message, setMessage] = useState(intl.formatMessage({ id: defaultMessageIntlId }, defaultMessageExtras));
  const [addEWalletDialogOpen, setAddEWalletDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const walletPassTypeRef = useRef(null);

  const isAdviser = isAdviserType(userDetails);
  const targetUserDetails = isAdviser ? userDetails : _.get(connectedAdvisers, '[0]');

  const name = _.get(targetUserDetails, 'name', '');
  const avatar = _.get(targetUserDetails, 'avatar');
  const themeColor = _.get(targetUserDetails, 'themeColor');

  // const [safariGenDialog, setSafariGenDialog] = useState(false);

  // const isUser = isUserType(userDetails);

  const shouldShowWalletPassButtons = hasCampaignCode(userDetails, teamCampaignCodes, 'FEATURE_EWALLET');

  const locationState = location.state;
  const clientData = locationState && locationState.client;

  const getLink = (isQRCode) => {
    const finalReferLinkSearch = {
      ...referLinkSearch,
      ...(locationState && locationState.referLinkSearch),
    };
    if (userDetails && userDetails.userType === 'User' && connectedAdvisers) {
      return getReferralLink({
        targetAdviser: _.get(connectedAdvisers, '[0]'),
        client: userDetails,
        isQRCode: isQRCode,
        userDetails: userDetails,
        extraSearch: finalReferLinkSearch,
      });
    } else {
      return getReferralLink({
        targetAdviser: userDetails,
        client: clientData || undefined,
        isQRCode: isQRCode,
        userDetails: userDetails,
        extraSearch: finalReferLinkSearch,
      });
    }
  };

  const qrCodeLink = getLink(true);
  const normalLink = getLink(false);

  const onCopy = () => {
    if (isAdviser) {
      tracking('Click on Leave Me Your Contact - Copy');
    } else {
      tracking('User Click on Share Adviser Contact - Copy');
    }
  };

  const onWhatsapp = () => {
    if (isAdviser) {
      tracking('Click on Leave Me Your Contact - Whatsapp');
    } else {
      tracking('User Click on Share Adviser Contact - Whatsapp');
    }
  };

  const onShare = () => {
    if (isAdviser) {
      tracking('Click on Leave Me Your Contact - Native Share');
    } else {
      tracking('User Click on Share Adviser Contact - Native Share');
    }
  };

  const onChangeLocale = (event) => setLocale(event.target.value);

  const addToAppleWallet = async () => {
    walletPassTypeRef.current = 'apple';
    setAddEWalletDialogOpen(true);
  };

  const addToGoogleWallet = async () => {
    walletPassTypeRef.current = 'google';
    setAddEWalletDialogOpen(true);
  };

  const onCloseAddToEWalletDialog = () => {
    setAddEWalletDialogOpen(false);
  };

  const onAddToEWalletDialogSubmit = async ({ language }) => {
    setAddEWalletDialogOpen(false);
    setLoading(true);
    await getWalletPass(walletPassTypeRef.current, { language });
    setLoading(false);
  };

  return (
    <div className={'referral-card-container ' + (teamLogoUrl ? 'custom-logo' : '')} {...rest}>
      <div className="wrapper">
        <LoadingBackdrop open={loading} />
        <ProfilePlusAddToEWalletDialog
          open={addEWalletDialogOpen}
          onClose={onCloseAddToEWalletDialog}
          onSubmit={onAddToEWalletDialogSubmit}
        />

        <ReferralCard
          details={targetUserDetails}
          link={qrCodeLink}
          customLogo={teamLogoUrl}
          customCTAMessage={customCTAMessage}
        />

        {wallet && shouldShowWalletPassButtons && !clientData && (
          <Grid container direction="column" justify="center" alignItems="center" style={{ paddingTop: 12 }}>
            {isIOS && (
              <Grid item>
                <IconButton disableRipple={true} disableFocusRipple={true} onClick={addToAppleWallet}>
                  <img src="img/wallet-pass/add-to-apple-wallet.svg" alt="add-to-apple-wallet" width="165" />
                </IconButton>
              </Grid>
            )}

            {isAndroid && (
              <Grid item>
                <IconButton disableRipple={true} disableFocusRipple={true} onClick={addToGoogleWallet}>
                  <img src="img/wallet-pass/add-to-google-wallet.svg" alt="add-to-google-wallet" width="200" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}

        <div className="share-wrapper">
          <div className="division-wrapper">
            <div className="division">
              {intl.formatMessage({
                id: 'referral-division-hint',
              })}
            </div>
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%', maxWidth: 450 }}>
              <LanguageRadioGroup value={locale} onChange={onChangeLocale} />

              <IntlProvider
                key={locale}
                locale={locale}
                defaultLocale={locale}
                messages={getLocaleMessages(locale).messages}
              >
                <LocaleMessageExtractor
                  locale={locale}
                  id={defaultMessageIntlId}
                  extras={defaultMessageExtras}
                  onChange={setMessage}
                />
              </IntlProvider>

              <ShareButtonGroup
                title={message}
                url={normalLink}
                isCTA
                phone={_.get(location, 'state.data.factsheetId.whatsapp')}
                onCopy={onCopy}
                onWhatsapp={onWhatsapp}
                onShare={onShare}
                CopyTextFieldProps={{
                  multiline: true,
                  rows: 2,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const container = connect(
  (state) => ({
    userDetails: state.user.userDetails,
    connectedAdvisers: state.user.connectedAdvisers,
    teamCampaignCodes: state.functions.campaignCodes,
    teamLogoUrl: state.team.teamLogoUrl,
  }),
  {},
)(ReferralQRCodeContent);

export default injectIntl(container);
