import React, { PureComponent } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { withTheme } from '@material-ui/core/styles';
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import DeleteIconButton from '../../../../components/DeleteIconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Grid, Button, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import TodayIcon from '@material-ui/icons/Today';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import TagsControl from '../../../../components/ClientEntry/controls/TagsControl/TagsControl';
import TagsDialog from '../../../../components/ClientEntry/TagsDialog/TagsDialog';
import TodoList from '../../../../components/TodoList/TodoList';
import Template from '../../../../components/Template/Template';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import './personalDetailsForm.scss';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer.js';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { tracking } from '../../../../utils/tracking';
import RecruitmentHelpModal from '../../../../components/RecruitmentHelpModal/RecruitmentHelpModal';
import { formatEmail } from '../../../../utils/email';
import RemindDateControl from '../../../../components/ClientEntry/controls/RemindDateControl/RemindDateControl';
import ReminderActiveControl from '../../../../components/ClientEntry/controls/ReminderActiveControl/ReminderActiveControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { convertToDateOnly } from '../../../../utils/date';
import LocationControl from '../../../../components/ClientEntry/controls/LocationControl/LocationControl';
import { sanitize } from 'dompurify';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import StyledAutoComplete from '../../../../components/StyledAutoComplete/StyledAutoComplete';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RemarkControl from '../../../../components/ClientEntry/controls/RemarkControl/RemarkControl';
import DISCDialogComponent from '../../../../components/ClientEntry/DISCDialog/DISCDialogComponent/DISCDialogComponent';
import { getReferralRemarkString, getReferralSourceString } from '../../../../utils/referral';
import EditFamilyDialog from '../../../../components/EditFamilyDialog/EditFamilyDialog.js';

const recruitmentTitleStyle = { flex: 1 };
const recruitmentCheckboxStyle = { paddingTop: '9px' };

class PersonalDetailsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nickname: '',
      email: '',
      existingClient: '',
      isRecruitment: false,
      source: '',
      network: '',
      latestMeetingDate: null,
      nextMeetingDate: null,
      remark: '',
      lastMeetingLocation: '',
      referralRecordId: undefined,
      err: {},
      resMessage: '',
      modalIsOpen: false,
      editClientData: false,
      editFactsheet: false,
      submitOnlyClient: false,
      warningModalIsOpen: false,
      consent: false,
      normalModal: false,
      typeArray: [],
      Warm: 'No',
      Refer: 'No',
      Cold: 'No',
      menu: true,
      importance: '',
      askToDisableTracking: false,
      families: [],
      tags: [],
      fsReminderActive: false,
      fsReminderDate: moment(),
      fsReminderDateChange: false,
      startDate: moment(),
      startDateChange: false,
      opportunity: '',
      trackOnModal: false,
      turnOnrackModal: false,
      expeactedBusinessAmount: '',
      personality: '',
      clientPersonalityPopup: false,
      oldPersonality: '',
      referrerName: '',
      msgOpt: false,
      RecruitmentHelpModalOpen: false,
      width: window.innerWidth,
      todoOpen: false,
      templateOpen: false,
      editFamilyDialogOpen: false
    };
    this.submitUserData = this.submitUserData.bind(this);
    this.switchFactsheetpage = this.switchFactsheetpage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeNormalModal = this.closeNormalModal.bind(this);
    this.handleSourceButton = this.handleSourceButton.bind(this);
    this.switchFactsheetpage = this.switchFactsheetpage.bind(this);
    this.handleReminderChange = this.handleReminderChange.bind(this);
    this.handleTrackingTurnOn = this.handleTrackingTurnOn.bind(this);
    this.confirmEnableTracking = this.confirmEnableTracking.bind(this);
    this.closeTurnOnTrackModal = this.closeTurnOnTrackModal.bind(this);
    this.cancelTrackOn = this.cancelTrackOn.bind(this);
    this.handlePersonality = this.handlePersonality.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.handleIsRecruitmentChange = this.handleIsRecruitmentChange.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize, false);
    this.props.renderHeaderButton(false);
  }

  handleResize = () => this.setState({ width: window.innerWidth });

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  setClientDetailsFromProps(checkLocalStorage, props) {
    let { intl, userDetails, location } = this.props;
    let obj = {};

    let dataNew = props.clientDetails;
    if (dataNew && !this.isEmpty(dataNew)) {
      obj.editClientData = true;
    }

    let clientPersonalData = JSON.parse(localStorage.getItem('clientPersonalData'));

    if (checkLocalStorage && clientPersonalData !== null) {
      obj = _.merge(obj, {
        name: clientPersonalData.name,
        nickname: clientPersonalData.nickname,
        email: clientPersonalData.email,
        existingClient: clientPersonalData.existingClient,
        isRecruitment: clientPersonalData.isRecruitment,
        source: clientPersonalData.source,
        referrerName: _.get(clientPersonalData, 'referrerName', ''),
        fsReminderActive: _.get(clientPersonalData, 'fsReminderActive', false),
        fsReminderDate:
          _.get(clientPersonalData, 'fsReminderDate') !== null &&
            _.get(clientPersonalData, 'fsReminderDate') !== undefined &&
            _.get(clientPersonalData, 'fsReminderDate') !== ''
            ? moment.parseZone(_.get(clientPersonalData, 'fsReminderDate'))
            : null,
        importance: clientPersonalData.importance ? clientPersonalData.importance : '',
        families: clientPersonalData.families ? clientPersonalData.families : [],
        tags: clientPersonalData.tags ? clientPersonalData.tags : [],
        savedTags: clientPersonalData.savedTags
          ? clientPersonalData.savedTags
          : clientPersonalData.tags
            ? clientPersonalData.tags
            : [],
        network: clientPersonalData.network,
        // referralName:_.get(clientPersonalData,'referralName' ,''),
        latestMeetingDate:
          clientPersonalData.latestMeetingDate !== null &&
            clientPersonalData.latestMeetingDate !== undefined &&
            clientPersonalData.latestMeetingDate !== ''
            ? moment.parseZone(clientPersonalData.latestMeetingDate)
            : null,
        nextMeetingDate: clientPersonalData.nextMeetingDate
          ? moment.parseZone(clientPersonalData.nextMeetingDate)
          : null,
        msgOpt: clientPersonalData.msgOpt,
        remark: clientPersonalData.remark,
        referralRecordId: clientPersonalData.referralRecordId,
        lastMeetingLocation: clientPersonalData.lastMeetingLocation,
        personality: _.get(clientPersonalData, 'personality', ''),
        oldPersonality: _.get(clientPersonalData, 'personality', ''),
      });

      if (clientPersonalData.source === 'Warm') {
        obj.Warm = 'Yes';
      }
      if (clientPersonalData.source === 'Refer') {
        obj.Refer = 'Yes';
      }
      if (clientPersonalData.source === 'Cold') {
        obj.Cold = 'Yes';
      }
    } else if (dataNew) {
      let clientId = JSON.parse(localStorage.getItem('clientId'));

      obj = _.merge(obj, {
        name: dataNew.name ? dataNew.name : '',
        nickname: dataNew.nickname || '',
        email: dataNew.email ? dataNew.email : '',
        existingClient: dataNew.existingClient ? dataNew.existingClient : '',
        isRecruitment: _.get(dataNew, 'isRecruitment', false),
        source: dataNew.source ? dataNew.source : '',
        referrerName: _.get(dataNew, 'referrerName', ''),
        importance: dataNew.importance ? dataNew.importance : '',
        fsReminderActive: _.get(dataNew, 'fsReminderActive', false),
        fsReminderDate:
          _.get(dataNew, 'fsReminderDate') !== undefined &&
            _.get(dataNew, 'fsReminderDate') !== null &&
            _.get(dataNew, 'fsReminderDate') !== ''
            ? moment.parseZone(_.get(dataNew, 'fsReminderDate'))
            : null,
        network: dataNew.network ? dataNew.network : '',
        // referralName:_.get(clientPersonalData,'referralName' ,''),
        latestMeetingDate: dataNew.latestMeetingDate
          ? moment.parseZone(dataNew.latestMeetingDate)
          : clientId
            ? null
            : moment.parseZone(),
        nextMeetingDate: dataNew.nextMeetingDate ? moment.parseZone(dataNew.nextMeetingDate) : null,
        remark: dataNew.remark ? dataNew.remark : '',
        referralRecordId: dataNew.referralRecordId || undefined,
        lastMeetingLocation: dataNew.lastMeetingLocation || '',
        families: dataNew.families ? dataNew.families : [],
        tags: dataNew.tags ? dataNew.tags : [],
        savedTags: dataNew.savedTags ? dataNew.savedTags : dataNew.tags ? dataNew.tags : [],
        personality: _.get(dataNew, 'personality', ''),
        oldPersonality: _.get(dataNew, 'personality', ''),
        msgOpt: _.get(dataNew, 'msgOpt', false),
      });

      if (dataNew.source === 'Warm') {
        obj.Warm = 'Yes';
      }
      if (dataNew.source === 'Refer') {
        obj.Refer = 'Yes';
      }
      if (dataNew.source === 'Cold') {
        obj.Cold = 'Yes';
      }
    }

    let referralRecord = location.state && location.state.referralRecord ? location.state.referralRecord : undefined;
    if (referralRecord) {
      this.setState({
        name: referralRecord.name,
        email: referralRecord.email,
        network: getReferralSourceString(intl, referralRecord),
        // latestMeetingDate: moment.parseZone()
        latestMeetingDate: referralRecord.createdate,
        remark: getReferralRemarkString(intl, referralRecord),
        referralRecordId: referralRecord._id,
      });
    }

    let val = props.clientPersonalDetails;
    const isInactive = val.inactiveUser !== undefined && val.inactiveUser === true ? true : false;
    if (isInactive && val.trackingId && val.trackingId.isActive) {
      obj.askToDisableTracking = true;
    } else {
      if (val.advisorDetails && val.advisorDetails.length > 0) {
        val.advisorDetails.map((value) => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.askToDisableTracking = true;
          }
          return true;
        });
      }
    }

    this.setState(obj);
  }

  UNSAFE_componentWillMount() {
    let { intl, location } = this.props;

    let referralRecord = location.state && location.state.referralRecord ? location.state.referralRecord : undefined;
    if (referralRecord) {
      let referByName =
        referralRecord.referUserFactsheetId && referralRecord.referUserFactsheetId.name
          ? referralRecord.referUserFactsheetId.name
          : '';

      // if(refe)

      this.setState({
        name: referralRecord.name,
        email: referralRecord.email,
        network: getReferralSourceString(intl, referralRecord),
        remark: getReferralRemarkString(intl, referralRecord),
        referralRecordId: referralRecord._id,
        referrerName: referByName,
        source: 'Refer',
        Refer: 'Yes',
        // latestMeetingDate:moment(_.get(referralRecord,'createdate','')),
        latestMeetingDate: referralRecord.createdate,
        fsReminderDate: null,
      });
    } else {
      this.setClientDetailsFromProps(true, this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, checkClientPhase } = this.props;

    if (this.props.fetchDetailsPhase !== true && nextProps.fetchDetailsPhase === true) {
      this.setClientDetailsFromProps(false, nextProps);
    }

    // if (this.props.createClientPhase !== true && nextProps.createClientPhase === true) {
    //   this.done();
    // }

    if (checkClientPhase !== false && nextProps.checkClientPhase === false) {
      this.setState({
        warningModalIsOpen: false,
        resMessage: intl.formatMessage({ id: nextProps.clientMessage }),
        normalModal: true,
        submitOnlyClient: false,
      });
    }
    if (this.props.checkClientPhase !== true && nextProps.checkClientPhase === true) {
      if (this.state.submitOnlyClient) {
        //if(clientId === null || clientId === undefined){
        // this.setState({ turnOnrackModal: true });
        //}
        //this.props.submitClient(this.state.formData);
        var old_data = this.state.savedTags !== null && this.state.savedTags !== undefined ? this.state.savedTags : [];
        var new_data = this.state.tags !== null && this.state.tags !== undefined ? this.state.tags : [];
        this.props.submitClient({
          formData: {
            ...this.state.formData,
            enableTracking: 'false',
          },
          usedTags: {
            old_data: old_data,
            new_data: new_data,
          },
        });
      } else {
        this.props.handleNextPage(1);
      }
    }
    if (this.props.deleteClientPhase !== true && nextProps.deleteClientPhase === true) {
      this.done();
    }
    if (nextProps.enableTrackingPhase === true) {
      let data = {};
      // if (nextProps.fetchDetailsPhase === true) {
      //   toast.success(intl.formatMessage({ id: nextProps.message }));
      // }
      const { clientPersonalDetails, userDetails } = this.props;
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map((value) => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            data.factsheetId = _.get(value, 'factsheetId', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        data.factsheetId = _.get(clientPersonalDetails, 'factsheetId', '');
      }
      data.userId = _.get(clientPersonalDetails, '_id', '');
      this.props.getClientDetails(data);
      this.setState({ askToDisableTracking: false });
      this.setClientDetailsFromProps(false, nextProps);
    }
  }

  handleReminderChange() {
    const { fsReminderActive, fsReminderDate } = this.state;
    this.setState({
      fsReminderActive: !fsReminderActive,
      fsReminderDate: !fsReminderActive ? fsReminderDate : null,
      err: {},
    });
  }

  handleChange(e) {
    const err = {};
    this.setState({ [e.target.name]: e.target.value, err });
  }

  handleDateChange(name, date) {
    if (name === 'fsReminderDate') {
      // var d = new Date();
      // var tz = d.getTimezoneOffset(); to get the utc offset of date time of local country  and offset can be negative and positive
      // let minuteDiff = -tz; /*change in positive if tz is negative and change in negative if offset if positive*/
      delete this.state.err.fsReminderDate;
      var fsReminderDate =
        date !== undefined && date !== null && date !== ''
          ? date
          : /*comment the code of addding offset*/
          // .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          // .add(
          //   minuteDiff,
          //   'minutes'
          // ) /*adding the offset to save the currec date in db bcoz server convert in utc*/
          date;
      this.setState({
        fsReminderDate: fsReminderDate,
        fsReminderDateChange: true,
      });
    }
    if (name === 'latestMeetingDate' || name === 'nextMeetingDate') {
      this.setState({ [name]: date });
      // let latestMeetingDate = moment(date).format('DD-MM-YYYY');
    }
  }

  submitUserData() {
    var user = this.props.userDetails;
    var clientId = JSON.parse(localStorage.getItem('clientId'));

    const err = {};
    if (this.state.name === '') {
      err.name = 'Name is required';
    }
    if (this.state.source === '') {
      err.source = 'Please pick one.';
    }
    // if (this.state.email === '') {
    //   err.email = 'Email is required';
    // } else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    // ) {
    //   err.email = 'Invalid email';
    // }
    if (this.state.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(this.state.email))) {
        err.email = 'Invalid email';
      }
    }
    if (
      this.state.fsReminderActive &&
      (this.state.fsReminderDate === null ||
        this.state.fsReminderDate === undefined ||
        this.state.fsReminderDate === '')
    ) {
      err.fsReminderDate = 'Please set date';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      var formData = {};
      if (
        this.state.latestMeetingDate !== '' &&
        this.state.latestMeetingDate !== undefined &&
        this.state.latestMeetingDate !== null
      ) {
        var abc = moment(this.state.latestMeetingDate).format('YYYY-MM-DD');
        let latestMeetingDate = moment.utc(abc).format('YYYY-MM-DDT00:00:00.00Z');
        formData.latestMeetingDate = latestMeetingDate;
      } else {
        // let defaultDate = moment().format('YYYY-MM-DD');
        // formData.latestMeetingDate = moment
        // 	.utc(defaultDate)
        // 	.format('YYYY-MM-DDT00:00:00.00Z');
        formData.latestMeetingDate = null;
      }
      if (this.state.nextMeetingDate) {
        formData.nextMeetingDate = convertToDateOnly(this.state.nextMeetingDate);
      } else {
        formData.nextMeetingDate = null;
      }
      formData.name = this.state.name;
      formData.nickname = this.state.nickname;
      formData.email = formatEmail(this.state.email);
      formData.existingClient = this.state.existingClient;
      formData.source = this.state.source;
      formData.importance = this.state.importance;
      formData.network = this.state.network;
      // if(this.state.source === 'Refer'){
      formData.referrerName = _.get(this, 'state.referrerName', '');
      // }
      formData.remark = this.state.remark;
      formData.lastMeetingLocation = this.state.lastMeetingLocation;
      // pass referral record id if auto remove is enabled
      // formData.referralRecordId = this.state.referralRecordId;
      formData.advisorId = user._id;
      formData.connectedAdviserEmail = user.email;
      formData.consent = this.state.consent;
      formData.families = this.state.families;
      formData.tags = this.state.tags;
      formData.personality = this.state.personality;
      formData.msgOpt = this.state.msgOpt;
      formData.isRecruitment = this.state.isRecruitment;
      if (this.state.fsReminderActive) {
        formData.fsReminderActive = this.state.fsReminderActive;
        /* To set the local date*/
        var date = moment(this.state.fsReminderDate).format('YYYY-MM-DD');
        var fs_Reminder_Date = moment.utc(date).format('YYYY-MM-DDT00:00:00.00Z');
        formData.fsReminderDate = fs_Reminder_Date;
      } else if (this.state.fsReminderActive === false) {
        formData.fsReminderActive = null;
        formData.fsReminderDate = null;
      }
      this.setState({ formData, submitOnlyClient: true });
      if (!this.state.editClientData) {
        let data = {};
        data.email = formatEmail(this.state.email);
        data.advisorId = user._id;
        this.props.checkClient(data);
      } else {
        formData.clientId = clientId;
        var old_data = this.state.saveTagsd !== null && this.state.savedTags !== undefined ? this.state.savedTags : [];
        var new_data = this.state.tags !== null && this.state.tags !== undefined ? this.state.tags : [];
        formData.usedTags = {
          old_data: old_data,
          new_data: new_data,
        };
        this.props.submitClient(formData);

        // Tracking
        // Log add client event
        tracking('Client Edited');

        // redirect if edit from assistant
        // this.done();
      }
    }
  }

  saveToLocalStorage() {
    var formData = {};
    if (
      this.state.latestMeetingDate !== '' &&
      this.state.latestMeetingDate !== undefined &&
      this.state.latestMeetingDate !== null
    ) {
      var abc = moment(this.state.latestMeetingDate).format('YYYY-MM-DD');
      let latestMeetingDate = moment.utc(abc).format('YYYY-MM-DDT00:00:00.00Z');
      formData.latestMeetingDate = latestMeetingDate;
    } else {
      formData.latestMeetingDate = null;
    }
    if (this.state.nextMeetingDate) {
      formData.nextMeetingDate = convertToDateOnly(this.state.nextMeetingDate);
    } else {
      formData.nextMeetingDate = null;
    }
    formData.name = this.state.name;
    formData.nickname = this.state.nickname;
    formData.email = formatEmail(this.state.email);
    formData.existingClient = this.state.existingClient;
    formData.source = this.state.source;
    formData.importance = this.state.importance;
    formData.network = this.state.network;
    formData.referrerName = _.get(this, 'state.referrerName', '');
    formData.remark = this.state.remark;
    formData.lastMeetingLocation = this.state.lastMeetingLocation;
    formData.referralRecordId = this.state.referralRecordId;
    formData.consent = this.state.consent;
    formData.families = this.state.families ? this.state.families : [];
    formData.tags = this.state.tags ? this.state.tags : [];
    formData.savedTags = this.state.savedTags ? this.state.savedTags : formData.tags;
    formData.personality = this.state.personality;
    formData.usedTags = {
      old_data: this.state.savedTags !== null && this.state.savedTags !== undefined ? this.state.savedTags : [],
      new_data: this.state.tags !== null && this.state.tags !== undefined ? this.state.tags : [],
    };

    formData.msgOpt = this.state.msgOpt;
    formData.isRecruitment = this.state.isRecruitment;

    if (
      this.state.fsReminderDate !== '' &&
      this.state.fsReminderDate !== undefined &&
      this.state.fsReminderDate !== null
    ) {
      formData.fsReminderDate = this.state.fsReminderDate;
    } else {
      formData.fsReminderDate = null;
    }
    formData.fsReminderActive = this.state.fsReminderActive;
    localStorage.setItem('clientPersonalData', JSON.stringify(formData));
  }

  switchFactsheetpage() {
    var user = this.props.userDetails;
    const err = {};
    if (this.state.name === '') {
      err.name = 'Name is required';
    }
    if (this.state.source === '') {
      err.source = 'Please pick one.';
    }
    // if (this.state.email === '') {
    //   err.email = 'Email is required';
    // } else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    // ) {
    //   err.email = 'Invalid email';
    // }
    if (this.state.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(this.state.email))) {
        err.email = 'Invalid email';
      }
    }
    if (
      this.state.fsReminderActive &&
      (this.state.fsReminderDate === null ||
        this.state.fsReminderDate === undefined ||
        this.state.fsReminderDate === '')
    ) {
      err.fsReminderDate = 'Please set date';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      this.saveToLocalStorage();
      if (!this.state.editClientData && !this.state.editFactsheet) {
        // let data = {};
        // data.email = this.state.email.toLowerCase();
        // data.advisorId = user._id;
        // this.props.checkClient(data);
        this.props.handleNextPage(1);
      } else {
        this.props.handleNextPage(1);
      }
    }
  }

  deleteClient() {
    // if there is tracking, delete it too
    if (this.state.askToDisableTracking) {
      const { clientPersonalDetails, enableTracking, userDetails } = this.props;
      let obj = {};
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map((value) => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.trackingId = _.get(value, 'trackingId._id', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        obj.trackingId = _.get(clientPersonalDetails, 'trackingId._id', '');
      }

      obj.clientId = _.get(clientPersonalDetails, '_id', '');
      obj.trackingType = 'delete';
      enableTracking(obj);
    }

    let clientId = JSON.parse(localStorage.getItem('clientId'));
    let factsheet = this.props.clientDetails;
    let data = {};
    data.clientId = clientId;
    data.factsheetId = factsheet._id;
    this.props.deleteClient(data);
    this.setState({ modalIsOpen: false, menu: true });
  }

  openWarningModal() {
    const err = {};
    if (this.state.name === '') {
      err.name = 'Name is required';
    }
    if (this.state.source === '') {
      err.source = 'Please pick one.';
    }
    // if (this.state.email === '') {
    //   err.email = 'Email is required';
    // } else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    // ) {
    //   err.email = 'Invalid email';
    // }
    if (this.state.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(this.state.email))) {
        err.email = 'Invalid email';
      }
    }
    if (
      this.state.fsReminderActive &&
      (this.state.fsReminderDate === null ||
        this.state.fsReminderDate === undefined ||
        this.state.fsReminderDate === '')
    ) {
      err.fsReminderDate = 'Please set date';
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      this.setState({ warningModalIsOpen: true });
    }
  }

  closeNormalModal() {
    const { initChangeEmailPhase } = this.props;
    this.setState({ normalModal: false });
    initChangeEmailPhase();
  }

  openModal() {
    this.setState({ modalIsOpen: true, menu: false });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      warningModalIsOpen: false,
      menu: true,
      trackOnModal: false,
      opportunity: '',
      startDate: moment(),
      expeactedBusinessAmount: '',
    });
  }

  handleExistingClientButton = (value) => {
    const { existingClient } = this.state;
    delete this.state.err.existingClient;
    this.setState({ existingClient: existingClient === value ? '' : value });
  };

  handleSourceButton(key) {
    const state = this.state.typeArray;
    delete this.state.err.source;
    if (state.indexOf(key) === -1) {
      this.setState({ typeArray: [...this.state.typeArray, key] });
      if (key === 0) {
        this.setState({
          Warm: 'Yes',
          //Existing: 'No',
          Refer: 'No',
          Cold: 'No',
          source: 'Warm',
        });
      }
      // if (key === 1) {
      //   this.setState({
      //     Warm: 'No',
      //     Existing: 'Yes',
      //     Refer: 'No',
      //     Cold: 'No',
      //     source: 'Existing'
      //   });
      // }
      if (key === 1) {
        this.setState({
          Warm: 'No',
          //Existing: 'No',
          Refer: 'Yes',
          Cold: 'No',
          source: 'Refer',
        });
      }
      if (key === 2) {
        this.setState({
          Warm: 'No',
          //Existing: 'No',
          Refer: 'No',
          Cold: 'Yes',
          source: 'Cold',
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Warm !== 'No') {
          this.setState({ Warm: 'No', source: '' });
        } else {
          this.setState({
            Warm: 'Yes',
            // Existing: 'No',
            Refer: 'No',
            Cold: 'No',
            source: 'Warm',
          });
        }
      }
      // if (key === 1) {
      //   if (this.state.Existing !== 'No') {
      //     this.setState({ Existing: 'No', source: '' });
      //   } else {
      //     this.setState({
      //       Warm: 'No',
      //       Existing: 'Yes',
      //       Refer: 'No',
      //       Cold: 'No',
      //       source: 'Existing'
      //     });
      //   }
      // }
      if (key === 1) {
        if (this.state.Refer !== 'No') {
          this.setState({ Refer: 'No', source: '' });
        } else {
          this.setState({
            Warm: 'No',
            // Existing: 'No',
            Refer: 'Yes',
            Cold: 'No',
            source: 'Refer',
          });
        }
      }
      if (key === 2) {
        if (this.state.Cold !== 'No') {
          this.setState({ Cold: 'No', source: '' });
        } else {
          this.setState({
            Warm: 'No',
            //Existing: 'No',
            Refer: 'No',
            Cold: 'Yes',
            source: 'Cold',
          });
        }
      }
    }
  }

  //To save the opportunity when on traking while creating client
  changeOpportunityOnTracking(val) {
    this.setState({ opportunity: val });
  }

  //To trun on tracking modal
  handleTrackingTurnOn() {
    this.setState({ trackOnModal: true, turnOnrackModal: false });
  }

  //Handle the  date of tracking start date
  handleTrackingStartDate(date) {
    /*remove the old logic to add 12 hours*/
    // var startDate = moment(date)
    //   .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    //   .add(12, 'hours');
    //delete this.state.err.bizStartDate;
    this.setState({
      startDate: date,
      startDateChange: true,
    });
  }

  //to enable the tracking
  confirmEnableTracking() {
    let { startDate, opportunity, expeactedBusinessAmount } = this.state;

    //if (startDate) {
    let startDate_format = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    let startDate1 = startDate_format ? moment.utc(startDate_format).format('YYYY-MM-DDT00:00:00.00Z') : '';
    let trackingObj = {};
    // obj.clientId = receiverType._id;
    // obj.factsheetId = receiverType.factsheetId._id;
    //obj.advisorId = userDetails._id;

    trackingObj.met = 0;
    const err = {};
    if (expeactedBusinessAmount === '') {
      err.expeactedBusinessAmount = 'Please fill in';
    }
    this.setState({ err });
    //Added new filed on modal and also insert them on create new tracking
    if (expeactedBusinessAmount) {
      trackingObj.businessVol = expeactedBusinessAmount;
    }
    if (opportunity) {
      trackingObj.opportunity = opportunity;
      if (opportunity === 'H') {
        let date_high = moment().format('YYYY-MM-DD');
        trackingObj.dateHigh = moment.utc(date_high).format('YYYY-MM-DDT00:00:00.00Z');
        trackingObj.dayCount = moment().diff(moment(startDate1, 'YYYY-MM-DD'), 'days');
      } else {
        trackingObj.dayCount = 0;
      }
    } else {
      trackingObj.dayCount = 0;
    }

    //obj.startDate = moment.utc(startDate);
    trackingObj.startDate = startDate1;
    trackingObj.isActive = true;
    trackingObj.connectionStatus = 'Inactive';
    if (!Object.keys(err).length) {
      this.setState({ trackingObj, trackOnModal: false });
      const stateFormData = this.state.formData ? this.state.formData : {};
      const obj = {};
      obj.formData = { ...stateFormData, enableTracking: 'true' };
      obj.trackingData = trackingObj;
      var old_data = this.state.savedTags !== null && this.state.savedTags !== undefined ? this.state.savedTags : [];
      var new_data = this.state.tags !== null && this.state.tags !== undefined ? this.state.tags : [];
      obj.usedTags = {
        old_data: old_data,
        new_data: new_data,
      };
      this.props.submitClient(obj);

      // Tracking
      tracking('Enable Business Tracking After Client Added');

      // redirect
      // this.done();
    }
  }

  //To close and add create the client
  closeTurnOnTrackModal() {
    let formData = {};
    let formData1 = {};
    formData.enableTracking = 'false';
    this.setState({ formData });
    formData1.formData = this.state.formData;
    var old_data = this.state.savedTags !== null && this.state.savedTags !== undefined ? this.state.savedTags : [];
    var new_data = this.state.tags !== null && this.state.tags !== undefined ? this.state.tags : [];
    formData1.usedTags = {
      old_data: old_data,
      new_data: new_data,
    };
    this.props.submitClient(formData1);
    this.setState({ turnOnrackModal: false });

    // redirect
    // this.done();
  }

  //Cancel track on create client after cancel
  cancelTrackOn() {
    let formData1 = {};
    let formData = {};
    formData.enableTracking = 'false';
    this.setState({ formData });
    formData1.formData = this.state.formData;
    var old_data = this.state.savedTags !== null && this.state.savedTags !== undefined ? this.state.savedTags : [];
    var new_data = this.state.tags !== null && this.state.tags !== undefined ? this.state.tags : [];
    formData1.usedTags = {
      old_data: old_data,
      new_data: new_data,
    };
    this.props.submitClient(formData1);
    this.setState({ trackOnModal: false });

    // redirect
    // this.done();
  }

  handlePersonality(personality) {
    this.setState({ personality: personality });
  }

  handleIsRecruitmentChange() {
    this.setState({
      isRecruitment: !this.state.isRecruitment,
    });
  }

  done = () => {
    const { history, location } = this.props;
    const replaceRoute = _.get(location, 'state.replaceRoute');
    if (replaceRoute) {
      history.replace(replaceRoute);
    } else {
      history.goBack();
    }
  };

  onGoBack = () => {
    const { history, toggleDialog } = this.props;
    toggleDialog('discardWarning', {
      onConfirm: () => {
        sessionStorage.removeItem('referralRecordId');
        history.goBack();
      }
    });
  };

  openTodo = () => this.setState({ todoOpen: true });
  closeTodo = () => this.setState({ todoOpen: false });
  openTemplate = () => this.setState({ templateOpen: true, todoOpen: false });
  closeTemplate = () => this.setState({ templateOpen: false });

  addToTodo = () => {
    const { addAdviserTodo } = this.props;
    const { fsReminderDate, fsReminderActive, name } = this.state;
    this.setState({ todoOpen: true }, () => {
      setTimeout(() => {
        if (fsReminderDate && fsReminderActive && name) {
          addAdviserTodo({
            todo: {
              todo: name,
              important: false,
              dueDate: fsReminderDate,
              completed: false,
            },
          });
        }
      }, 200);
    });
  };

  render() {
    const { history, userDetails, clientPersonalDetails, intl, enableTrackingPhase, referrerNames } = this.props;
    const {
      fsReminderDate,
      fsReminderActive,
      latestMeetingDate,
      nextMeetingDate,
      startDateChange,
      startDate,
      personality,
      isRecruitment,
      RecruitmentHelpModalOpen,
      width,
    } = this.state;
    //const tz = maxDate.getTimezoneOffset();
    /*To show the correct date in date picker because date picker taking the previous day in negative utc therfore we add the  offset to selected date or database date*/
    // const fs1 =
    //   fsReminderDate !== null && fsReminderDate !== undefined
    //     ? moment(fsReminderDate)
    //         .utcOffset(tz)
    //         .format('DD-MMM-YYYY')
    //     : null;
    const isInactive = clientPersonalDetails.inactiveUser !== undefined && clientPersonalDetails.inactiveUser === true;
    let DeleteMessage = !isInactive
      ? // ? 'Deleting the user would remove all linkage with the users and data can’t be <br/> recovered. Are You Sure?'
      // : 'Deleting inactive user would remove all the user data inputted. ';
      intl.formatMessage({
        id: 'delete_all_data',
      })
      : intl.formatMessage({ id: 'delete_active_user_message' });

    //let productionEnv = process.env.NODE_ENV === 'production' ? true : false;

    const date = new Date();
    const date1 = moment(date).format('YYYY-MM-DD');

    let latestMeetingDate1 =
      latestMeetingDate !== null && latestMeetingDate !== undefined && latestMeetingDate !== ''
        ? // ? moment.parseZone(latestMeetingDate)
        moment(latestMeetingDate).tz(moment.tz.guess())
        : null;

    const nextMeetingDateTz = nextMeetingDate ? moment(nextMeetingDate).tz(moment.tz.guess()) : null;
    /*To get the local date of country*/
    // let currentDate = new Date();
    // let month =
    //   '' + (currentDate.getMonth() + 1); /*to get the month of current date*/
    // let day = currentDate.getDate()
    // // let day =
    // //   '' + (currentDate.getDate() + 7); /*to get the day of current date*/
    // let year = currentDate.getFullYear(); /*to get the year of current date*/
    // if (month.length < 2)
    //   month =
    //     '0' + month; /*to add 0 if before number if month lies between 1 to 9*/
    // if (day.length < 2)
    //   day = '0' + day; /*to add 0 if before number if day lies between 1 to 9*/
    // let maxLatestDate = [year, month, day].join(
    //   '-'
    // ); /*join the year , month and day to make today date in YYYY-MM-DD*/
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
      <AppContentContainer>
        <TopNavigationPortal>
          <span onClick={this.onGoBack} className="back_arrow">
            <ArrowBackIcon />
          </span>
          {(this.state.editFactsheet || this.state.editClientData) && (
            <DeleteIconButton className="edit__pen" handleClick={this.openModal.bind(this)} />
          )}
        </TopNavigationPortal>

        <Dialog
          open={this.state.normalModal}
          onClose={this.closeNormalModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: sanitize(this.state.resMessage) }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeNormalModal} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.warningModalIsOpen}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Get Consent' })}</DialogTitle>
          <DialogContent>
            {intl.formatMessage({
              id: 'to_get_consent',
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={this.switchFactsheetpage} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.modalIsOpen}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: sanitize(DeleteMessage) }} />

            {this.state.askToDisableTracking && (
              <div dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'delete_the_tracking' })) }} />
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={this.closeModal} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={this.deleteClient.bind(this)} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.trackOnModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
          className="modalwidth__mobileview"
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'BusinessTracking' })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: 'bizTrackingDesc' })}</DialogContentText>
            <div className="modal-text">
              <div className="row">
                <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                  {intl.formatMessage({ id: 'Tracking start on' })}
                </div>
                <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                  <DatePicker
                    className="case-datepicker close-case-date case-open-datepicker"
                    maxDate={new Date()}
                    name="startDate"
                    format="DD-MMM-YYYY"
                    value={startDateChange ? startDate : moment(startDate)}
                    onChange={(date) => this.handleTrackingStartDate(date)}
                    autoOk={true}
                    invalidDateMessage={null}
                    minDateMessage={null}
                    maxDateMessage={null}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                  {intl.formatMessage({ id: 'Opportunity' })}
                </div>
                <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                  <div className="clientList">
                    <Button
                      className="portfolio-default-button"
                      style={{
                        background: this.state.opportunity === 'L' ? 'rgb(255, 106, 116)' : '#cccccc',
                        color: this.state.opportunity === 'L' ? '#ffffff' : '#000',
                      }}
                      variant="contained"
                      onClick={this.changeOpportunityOnTracking.bind(this, this.state.opportunity === 'L' ? '' : 'L')}
                    >
                      {intl.formatMessage({
                        id: 'oppL',
                      })}
                    </Button>
                    <Button
                      className="portfolio-default-button"
                      style={{
                        background: this.state.opportunity === 'M' ? 'rgb(255, 175, 25)' : '#cccccc',
                        color: this.state.opportunity === 'M' ? '#ffffff' : '#000',
                      }}
                      variant="contained"
                      onClick={this.changeOpportunityOnTracking.bind(this, this.state.opportunity === 'M' ? '' : 'M')}
                    >
                      {intl.formatMessage({
                        id: 'oppM',
                      })}
                    </Button>
                    <Button
                      className="portfolio-default-button"
                      style={{
                        background: this.state.opportunity === 'H' ? 'rgb(94, 224, 146)' : '#cccccc',
                        color: this.state.opportunity === 'H' ? '#ffffff' : '#000',
                      }}
                      variant="contained"
                      onClick={this.changeOpportunityOnTracking.bind(this, this.state.opportunity === 'H' ? '' : 'H')}
                    >
                      {intl.formatMessage({
                        id: 'oppH',
                      })}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                  {intl.formatMessage({ id: 'Expected Business' })}
                </div>
                <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                  <NumberFormat
                    className="form-control"
                    style={{
                      width: 111,
                      borderBottom: '1px solid gray',
                      height: 25,
                      color: '#3966f8',
                    }}
                    name="businessVol"
                    value={this.state.expeactedBusinessAmount}
                    thousandSeparator={true}
                    onValueChange={(values) => {
                      delete this.state.err.expeactedBusinessAmount;
                      const { value } = values;
                      this.setState({ expeactedBusinessAmount: value });
                    }}
                    type="tel"
                    //id="numberFormat"
                    // decimalSeparator=","
                    // thousandSeparator="."
                    inputMode="decimal"
                  //pattern="[0-9]*"
                  />
                  <i className="bar bar-edit" />
                  {this.state.err.expeactedBusinessAmount ? (
                    <span className="error_field" style={{ fontSize: '13px' }}>
                      {intl.formatMessage({
                        id: this.state.err.expeactedBusinessAmount,
                      })}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelTrackOn} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button
              onClick={this.confirmEnableTracking}
              color="primary"
              disabled={enableTrackingPhase === 'loading' ? true : false}
              autoFocus
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.turnOnrackModal}
          onClose={this.closeModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Track On' })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: 'addClientOnTracking' })}</DialogContentText>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(intl.formatMessage({ id: 'TurnOnTracking' })),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeTurnOnTrackModal} color="primary">
              {intl.formatMessage({ id: 'No-need' })}
            </Button>
            <Button onClick={this.handleTrackingTurnOn} color="primary" autoFocus>
              {intl.formatMessage({ id: 'Turn-it-on' })}
            </Button>
          </DialogActions>
        </Dialog>

        <DISCDialogComponent
          open={this.state.clientPersonalityPopup}
          onClose={() => {
            this.setState({ clientPersonalityPopup: false });
          }}
          contentText="client"
          personality={this.state.personality}
          onSubmit={this.handlePersonality}
        />

        <EditFamilyDialog
          intl={intl}
          open={this.state.editFamilyDialogOpen}
          closeDialog={() => this.setState({ editFamilyDialogOpen: false })}
          selectedFamilies={this.state.families}
          setSelectedFamilies={families => this.setState({ families: families })}
          manageOnly={false}
        />

        <div className="personal-details-form">
          <TagsDialog />
          <div>
            {(this.props.createClientPhase === 'loading' || this.props.updateEmailPhase === 'loading') && (
              <LoadingBackdrop open={true} />
            )}
          </div>
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="msform" noValidate={true}>
                      <AladdinHint title={intl.formatMessage({ id: 'aladdin-add-client-title' })} helpCenter="c02" />

                      <ul id="progressbar">
                        <li className="active" />
                        <li />
                        <li />
                        <li />
                      </ul>
                      <fieldset>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="fast-made-pdf-btn"
                            onClick={() => history.push('/add_demo_client')}
                          >
                            <FlashOnIcon />
                            {intl.formatMessage({ id: 'fast-made-pdf' })}
                          </Button>
                        </div>

                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Client Data' })}</h2>
                        <div className="row">
                          <div className="col-xs-6 form-group">
                            <input
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input" style={{ paddingLeft: 15 }}>
                              {intl.formatMessage({ id: 'Name' })}*
                            </label>
                            <i className="bar" />
                            {this.state.err.name ? (
                              <span className="error_field">{intl.formatMessage({ id: this.state.err.name })}</span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col-xs-6 form-group">
                            <input
                              type="text"
                              name="nickname"
                              value={this.state.nickname}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input" style={{ paddingLeft: 15 }}>
                              {intl.formatMessage({ id: 'nickname' })}
                            </label>
                            <i className="bar" />
                          </div>
                        </div>
                        <div className="select-buttons-div personal_form">
                          <div className="control-orphay source-button">
                            {intl.formatMessage({ id: 'client-type' })}*
                          </div>
                          <div>
                            <Button
                              variant={this.state.Warm === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Warm === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive'
                                  : 'plan-type-select-button source-button-responsive'
                              }
                              key={0}
                              onClick={() => this.handleSourceButton(0)}
                            >
                              {intl.formatMessage({ id: 'Warm' })}
                            </Button>

                            {/*<Button
                            variant={
                              this.state.Existing == 'Yes'
                                ? 'contained'
                                : 'outlined'
                            }
                            className={
                              this.state.Existing == 'Yes'
                                ? 'plan-type-select-button selected'
                                : 'plan-type-select-button'
                            }
                            key={1}
                            onClick={() => this.handleSourceButton(1)}
                          >
                            Existing
                          </Button>*/}
                            <Button
                              variant={this.state.Refer === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Refer === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive'
                                  : 'plan-type-select-button source-button-responsive'
                              }
                              key={2}
                              onClick={() => this.handleSourceButton(1)}
                            >
                              {intl.formatMessage({ id: 'Refer' })}
                            </Button>

                            <Button
                              variant={this.state.Cold === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Cold === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive'
                                  : 'plan-type-select-button source-button-responsive'
                              }
                              key={3}
                              onClick={() => this.handleSourceButton(2)}
                            >
                              {intl.formatMessage({ id: 'Cold' })}
                            </Button>
                          </div>
                          <i className="bar" />
                          {this.state.err.source ? (
                            <span className="error_field">{intl.formatMessage({ id: this.state.err.source })}</span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="select-buttons-div personal_form">
                          <div className="control-orphay source-button">
                            {intl.formatMessage({ id: 'existing-client' })}
                          </div>
                          <div>
                            <img
                              src="img/existingClient.svg"
                              alt="existing-client"
                              width="20"
                              style={{ margin: '0 6px' }}
                            />
                            <Button
                              variant={this.state.existingClient === 'Yes' ? 'contained' : 'outlined'}
                              className={`plan-type-select-button source-button-responsive${this.state.existingClient === 'Yes' ? ` selected` : ''
                                }`}
                              onClick={() => this.handleExistingClientButton('Yes')}
                            >
                              {intl.formatMessage({ id: 'Yes' })}
                            </Button>
                            <img
                              src="img/nonExistingClient.svg"
                              alt="non-existing-client"
                              width="20"
                              style={{ margin: '0 6px' }}
                            />
                            <Button
                              variant={this.state.existingClient === 'No' ? 'contained' : 'outlined'}
                              className={`plan-type-select-button source-button-responsive${this.state.existingClient === 'No' ? ` selected` : ''
                                }`}
                              onClick={() => this.handleExistingClientButton('No')}
                            >
                              {intl.formatMessage({ id: 'No' })}
                            </Button>
                          </div>
                        </div>
                        {/* {location && location.state &&  location.state.referralRecord &&  this.state.source  === 'Refer' &&(
                      <div className="form-group">
                        <input
                          type="text"
                          name="referrerName"
                          value={this.state.referrerName}
                          onChange={this.handleChange.bind(this)}
                          required="required"
                        />
                        <label className="control-label" htmlFor="input">
                          {intl.formatMessage({ id: 'Referrer' })}
                        </label>
                        <i className="bar" />
                      </div>
                    )} */}
                        <div className="select-buttons-div personal_form">
                          <div className="control-orphay source-button">{intl.formatMessage({ id: 'Referrer' })}</div>
                          <div>
                            <StyledAutoComplete
                              freeSolo={true}
                              value={this.state.referrerName || ''}
                              inputValue={this.state.referrerName || ''}
                              onInputChange={(event, value) => event && this.setState({ referrerName: value || '' })}
                              onChange={(event, value) => event && this.setState({ referrerName: value || '' })}
                              options={referrerNames}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} />}
                              style={{ width: 196, display: 'inline-flex' }}
                            />
                          </div>
                        </div>

                        <div className="select-buttons-div personal_form">
                          <div className="control-orphay source-button">{intl.formatMessage({ id: 'Importance' })}</div>
                          <div>
                            <Button
                              variant={this.state.importance === 'Normal' ? 'contained' : 'outlined'}
                              className={
                                this.state.importance === 'Normal'
                                  ? 'plan-type-select-button selected source-button-responsive btn-margin'
                                  : 'plan-type-select-button source-button-responsive btn-margin'
                              }
                              onClick={() => {
                                delete this.state.err.importance;
                                this.setState({
                                  importance: this.state.importance === 'Normal' ? '' : 'Normal',
                                });
                              }}
                            >
                              {intl.formatMessage({ id: 'Normal' })}
                            </Button>
                            <Button
                              variant={this.state.importance === 'VIP' ? 'contained' : 'outlined'}
                              className={
                                this.state.importance === 'VIP'
                                  ? 'plan-type-select-button selected source-button-responsive btn-margin'
                                  : 'plan-type-select-button source-button-responsive btn-margin'
                              }
                              onClick={() => {
                                delete this.state.err.importance;
                                this.setState({
                                  importance: this.state.importance === 'VIP' ? '' : 'VIP',
                                });
                              }}
                            >
                              {intl.formatMessage({ id: 'VIP' })}
                            </Button>

                            <Button
                              variant={this.state.importance === 'V.VIP' ? 'contained' : 'outlined'}
                              className={
                                this.state.importance === 'V.VIP'
                                  ? 'plan-type-select-button selected source-button-responsive btn-margin'
                                  : 'plan-type-select-button source-button-responsive btn-margin'
                              }
                              key={3}
                              onClick={() => {
                                delete this.state.err.importance;
                                this.setState({
                                  importance: this.state.importance === 'V.VIP' ? '' : 'V.VIP',
                                });
                              }}
                            >
                              {intl.formatMessage({ id: 'V.VIP' })}
                            </Button>
                          </div>
                          <i className="bar" />
                          {this.state.err.importance ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.importance,
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className="form-group">
                          <div style={{ marginLeft: 50 }}>
                            <div onClick={() => this.setState({ editFamilyDialogOpen: true })}>
                              {
                                this.state.families.map((family, index) => (
                                  <span
                                    className='familyTag'
                                    key={index}
                                  >
                                    {family}
                                  </span>
                                ))
                              }
                              {!this.state.families || this.state.families.length === 0 &&
                                <div className="ReactTags__selected">
                                  <span
                                    className="tag-wrapper ReactTags__tag-no-remove"
                                    style={{ color: this.props.theme.palette.primary.main }}
                                  >
                                    + 🏠&nbsp;
                                  </span>
                                </div>
                              }
                            </div>
                            <label className="control-label" htmlFor="input" style={{ top: '0' }}>
                              {intl.formatMessage({ id: 'family' })}
                            </label>
                          </div>
                          <i className="bar" />
                        </div>

                        <div className="form-group">
                          <div>
                            {/* If there is tag, display it */}
                            {this.state.tags !== undefined && this.state.tags !== null && (
                              <TagsControl
                                tags={this.state.tags}
                                style={{ margin: '0 0 0 50px' }}
                                onChange={(tags) => this.setState({ tags: tags })}
                                addTagButton={true}
                              />
                            )}
                          </div>
                          <label className="control-label" htmlFor="input" style={{ top: '0' }}>
                            {intl.formatMessage({ id: 'Tag' })}
                          </label>
                          <i className="bar" />
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-xs-6 form-group">
                            <input
                              type="text"
                              name="network"
                              value={this.state.network}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input" style={{ paddingLeft: 15 }}>
                              {intl.formatMessage({ id: 'client-source' })}
                            </label>
                            <i className="bar" />
                          </div>
                          <div className="col-md-6 col-xs-6 msg-opt">
                            <div className="title" style={recruitmentTitleStyle}>
                              <span>{intl.formatMessage({ id: 'recruit-lead' })}</span>
                              <HelpOutlineIcon
                                color="primary"
                                className="client--personality-info"
                                style={{ paddingBottom: '5px', cursor: 'pointer' }}
                                onClick={() => this.setState({ RecruitmentHelpModalOpen: true })}
                              />
                              <RecruitmentHelpModal
                                open={RecruitmentHelpModalOpen}
                                onClose={() => this.setState({ RecruitmentHelpModalOpen: false })}
                                intl={intl}
                              />
                            </div>
                            <Checkbox
                              checked={isRecruitment}
                              color="primary"
                              className="check-box"
                              style={recruitmentCheckboxStyle}
                              onChange={this.handleIsRecruitmentChange}
                            />
                          </div>
                        </div>
                        {/* Reminder At two field on ReminderActive and ReminderDate */}

                        <div className="select-buttons-div">
                          <div className="control-orphay source-button">
                            {intl.formatMessage({ id: 'Personality' })}
                          </div>
                          <HelpOutlineIcon
                            color="primary"
                            className="client--personality-info"
                            onClick={() => {
                              this.setState({ clientPersonalityPopup: true });
                            }}
                          />
                          <div>
                            <Button
                              className="portfolio-default-button button-line-height disc-default-button"
                              style={{
                                background: personality === 'D' ? '#EA8462' : '#cccccc',
                                color: personality === 'D' ? '#ffffff' : '#000',
                              }}
                              variant="contained"
                              onClick={() => {
                                this.setState({ personality: personality !== 'D' ? 'D' : '' });
                              }}
                            >
                              {intl.formatMessage({
                                id: 'personality_D',
                              })}
                            </Button>
                            <Button
                              className="portfolio-default-button button-line-height disc-default-button"
                              style={{
                                background: personality === 'I' ? '#EAC862' : '#cccccc',
                                color: personality === 'I' ? '#ffffff' : '#000',
                              }}
                              variant="contained"
                              onClick={() => {
                                this.setState({ personality: personality !== 'I' ? 'I' : '' });
                              }}
                            >
                              {intl.formatMessage({
                                id: 'personality_I',
                              })}
                            </Button>
                            <Button
                              className="portfolio-default-button button-line-height disc-default-button"
                              style={{
                                background: personality === 'S' ? '#62EA84' : '#cccccc',
                                color: personality === 'S' ? '#ffffff' : '#000',
                              }}
                              variant="contained"
                              onClick={() => {
                                this.setState({ personality: personality !== 'S' ? 'S' : '' });
                              }}
                            >
                              {intl.formatMessage({
                                id: 'personality_S',
                              })}
                            </Button>
                            <Button
                              className="portfolio-default-button button-line-height disc-default-button"
                              style={{
                                background: personality === 'C' ? '#62A6EA' : '#cccccc',
                                color: personality === 'C' ? '#ffffff' : '#000',
                              }}
                              variant="contained"
                              onClick={() => {
                                this.setState({ personality: personality !== 'C' ? 'C' : '' });
                              }}
                            >
                              {intl.formatMessage({
                                id: 'personality_C',
                              })}
                            </Button>
                          </div>
                        </div>

                        <ExpansionPanel style={{ marginBottom: 20 }}>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '0px 12px' }}>
                            {intl.formatMessage({ id: 'meeting-notes-expansion-panel-title' })}
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <DatePicker
                                  name="latestMeetingDate"
                                  format="DD-MMM-YYYY"
                                  clearable={true}
                                  maxDate={moment().add(7, 'days')}
                                  value={latestMeetingDate1}
                                  //openTo="year"
                                  onChange={(date) => this.handleDateChange('latestMeetingDate', date)}
                                  autoOk={true}
                                  invalidDateMessage={null}
                                  minDateMessage={null}
                                  maxDateMessage={null}
                                  label={intl.formatMessage({ id: 'Latest Meeting' })}
                                  InputProps={{
                                    style: {
                                      height: 36,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton style={{ padding: 0 }}>
                                          <TodayIcon color="action" style={{ fontSize: 20 }} />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <DatePicker
                                  name="nextMeetingDateTz"
                                  format="DD-MMM-YYYY"
                                  clearable={true}
                                  minDate={moment()}
                                  value={nextMeetingDateTz}
                                  //openTo="year"
                                  onChange={(date) => this.handleDateChange('nextMeetingDate', date)}
                                  autoOk={true}
                                  invalidDateMessage={null}
                                  minDateMessage={null}
                                  maxDateMessage={null}
                                  label={intl.formatMessage({ id: 'next-meeting-date' })}
                                  InputProps={{
                                    style: {
                                      height: 36,
                                    },
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton style={{ padding: 0 }}>
                                          <TodayIcon color="action" style={{ fontSize: 20 }} />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container>
                                  <LocationControl
                                    name="lastMeetingLocation"
                                    label={intl.formatMessage({ id: 'location' })}
                                    value={this.state.lastMeetingLocation}
                                    onChange={this.handleChange.bind(this)}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <RemarkControl
                                  remark={this.state.remark}
                                  onChange={(event) => this.setState({ remark: event.target.value })}
                                  noLabelColorClass={true}
                                  noShrinkClass={true}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item xs={6} style={{ position: 'relative', textAlign: 'left' }}>
                                    <Grid container alignItems="center" style={{ height: '100%' }}>
                                      <Grid item>
                                        <Typography>{intl.formatMessage({ id: 'Remind me' })}</Typography>
                                        <Button
                                          className="reminder-date-add-todo-btn"
                                          style={{
                                            position: 'absolute',
                                            marginLeft: 85,
                                            top: 8,
                                            padding: '0 16px',
                                            borderColor: fsReminderDate && fsReminderActive ? '#3966f8' : 'grey',
                                            color: fsReminderDate && fsReminderActive ? '#3966f8' : 'grey',
                                          }}
                                          variant="outlined"
                                          disabled={!fsReminderDate || !fsReminderActive}
                                          onClick={this.addToTodo}
                                        >
                                          {intl.formatMessage({ id: 'Add to Todo' })}
                                        </Button>
                                        <IconButton
                                          className="reminder-date-add-todo-icon-btn"
                                          disabled={!fsReminderDate || !fsReminderActive}
                                          style={{
                                            position: 'absolute',
                                            marginLeft: 70,
                                            top: -3,
                                            color: fsReminderDate && fsReminderActive ? '#3966f8' : 'grey',
                                          }}
                                          onClick={this.addToTodo}
                                        >
                                          <DoneOutlineRoundedIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Grid item style={{ position: 'relative' }}>
                                      <RemindDateControl
                                        className="date-picker"
                                        fsReminderDate={fsReminderDate}
                                        disabled={!fsReminderActive}
                                        minDate={new Date(date1)}
                                        onChange={(date) => this.handleDateChange('fsReminderDate', date)}
                                        InputProps={{
                                          endAdornment: fsReminderActive && (
                                            <InputAdornment position="end">
                                              <IconButton
                                                style={{ padding: '0 5px 0 0', margin: '0px 16px 0px -12px' }}
                                              >
                                                <TodayIcon color="action" style={{ fontSize: 20 }} />
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                          style: {
                                            height: 36,
                                          },
                                        }}
                                      />
                                      <ReminderActiveControl
                                        className="reminder-checkbox"
                                        fsReminderActive={fsReminderActive}
                                        onChange={this.handleReminderChange}
                                        ContainerProps={{
                                          style: {
                                            background: 'transparent',
                                            top: -3,
                                            right: 0,
                                          },
                                        }}
                                      />
                                    </Grid>
                                    {this.state.err.fsReminderDate && (
                                      <Grid item>
                                        <span className="error_field" style={{ fontSize: '13px' }}>
                                          {intl.formatMessage({ id: this.state.err.fsReminderDate })}
                                        </span>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <Grid container justify={"space-between"}>
                          <Grid item style={{ paddingRight: '10px' }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitUserData}
                              className="submitButton"
                            >
                              {intl.formatMessage({ id: 'Submit' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className="prevNextButton"
                              onClick={
                                clientPersonalDetails && !clientPersonalDetails.consent
                                  ? this.openWarningModal.bind(this)
                                  : this.switchFactsheetpage
                              }
                              endIcon={<ArrowForwardIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Factsheet' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <SwipeableDrawer
              anchor="right"
              PaperProps={{ elevation: 10, style: { zIndex: 1300 } }}
              variant={width > 800 ? 'persistent' : 'temporary'}
              onClose={this.closeTodo}
              onOpen={this.openTodo}
              open={this.state.todoOpen}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              disableSwipeToOpen={true}
              className="client-page-filter-SwipeableDrawer"
            >
              <TodoList close={this.closeTodo} openTemplate={this.openTemplate} />
            </SwipeableDrawer>
          </div>
          <div className="">
            <SwipeableDrawer
              anchor="right"
              PaperProps={{ elevation: 10, style: { zIndex: 1300 } }}
              variant={width > 800 ? 'persistent' : 'temporary'}
              onClose={this.closeTemplate}
              onOpen={this.openTemplate}
              open={this.state.templateOpen}
              disableBackdropTransition={!iOS}
              disableDiscovery={iOS}
              disableSwipeToOpen={true}
              className="client-page-filter-SwipeableDrawer"
            >
              <Template close={this.closeTemplate} />
            </SwipeableDrawer>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

export default withTheme(PersonalDetailsForm);
