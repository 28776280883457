import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { useTheme } from '@material-ui/core/styles';
import { HotLabel } from '../../HotLabel/HotLabel';

const HelpCenterButton = props => {
  const { intl, style, isWideLayout, ...rest } = props;
  const theme = useTheme();

  return (
    <Button
      className="help-center-btn"
      style={{
        zIndex: 1,
        borderRadius: 8,
        boxShadow: '0 5px 10px rgb(126 145 221 / 28%), 0 15px 40px rgb(110 129 202 / 38%)',
        padding: 0,
        background: '#fff',
        ...style
      }}
      {...rest}
    >
      <Grid container alignItems="stretch">
        <Grid
          item
          style={{
            padding: 3,
            background: 'var(--new-theme)',
            borderRadius: '8px 0 0 8px'
          }}
        ></Grid>
        <Grid item style={{ flexGrow: 1, padding: isWideLayout ? 10 : 8 }}>
          <Grid container alignItems="center" style={{ height: '100%', width: '100%' }}>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item style={{ height: 25 }}>
                      <img
                        src="img/adviser-home-page/closedeal.svg"
                        alt="signing-classroom"
                        width="25"
                        height="25"
                        style={{ verticalAlign: 'unset' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: '120%', fontWeight: '600' }}>
                    {intl.formatMessage({ id: 'signing-classroom' })}
                  </Typography>
                </Grid>
                <Grid item>
                  <HotLabel
                    text={intl.formatMessage({ id: 'hot' })}
                    style={{ position: 'relative', borderRadius: 4, padding: '0 4px', top: 0, left: 0 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <KeyboardArrowRightRoundedIcon
                style={{
                  verticalAlign: 'middle',
                  borderRadius: '50%',
                  fontSize: 20,
                  background: 'var(--new-theme)',
                  fill: theme.palette.primary.contrastText
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};

export default injectIntl(HelpCenterButton);
