import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const ImageStyle = {
  width: 20,
  height: 20
};

const TypoContainerStyle = {
  width: 'fit-content',
  maxWidth: '100%'
};

const TypoStyle = {
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  color: '#9e9e9e'
};

const AdviserInfoContactEntry = props => {
  const { imgSrc, imgAlt, text, textStyle, onClick } = props;
  return (
    <Grid container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item xs={2}>
        <img
          src={imgSrc}
          alt={imgAlt}
          style={{ ...ImageStyle, cursor: onClick ? 'pointer' : 'none' }}
          onClick={onClick}
        />
      </Grid>
      <Grid item xs={10}>
        <div style={{ ...TypoContainerStyle, cursor: onClick ? 'pointer' : 'none' }} onClick={onClick}>
          <Typography style={{ ...TypoStyle, ...textStyle }}>{text}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default AdviserInfoContactEntry;
