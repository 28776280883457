import React from 'react';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { SlideTransition } from './Transition/Transition';
import IconToast from './NewToast';
import NativeOrWeb from "../utils/native";

export default function ShareModal(props) {
  const { intl, open, onClose, shareURL } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Share' })}</DialogTitle>
        <DialogContent>
          <p>
            {intl.formatMessage({
              id: 'Click to copy the link below for sharing:'
            })}
          </p>
          <div>{`${intl.formatMessage({ id: 'share-copy-msg' })}:`}</div>
          <div>{`${shareURL}`}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
            <Button
              color="primary"
              onClick={() => {
                NativeOrWeb.copyToClipboard(`${intl.formatMessage({ id: 'share-copy-msg' })}: ${shareURL}`).then(() => {
                  toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });
                  onClose();
                })
              }}
            >
              {intl.formatMessage({ id: 'Copy Link' })}
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
