import React, { Fragment, useMemo, useState } from 'react';
import ContentSlide from '../ContentSlide/ContentSlide';
import NativeOrWeb from '../../utils/native';
import mime from 'mime-types';
import moment from 'moment-timezone';
import formatBytes from './utils/formatBytes';
import './FileSlide.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditFilenameDialog from './EditFilenameDialog/EditFilenameDialog';
import EditIcon from '@material-ui/icons/Edit';
import store from '../../store';

export default function FileSlide({ file, onFileDeleted, onFilenameChange, isTeamHead }) {
  const [editFilenameDialogOpen, setEditFilenameDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const intl = getGlobalIntl();
  const { filename, url, size, createdAt } = file;

  const isFileLink = useMemo(() => {
    return !url.startsWith(process.env.PFPLUS_BACKEND_URL);
  }, [url]);

  const iconUrl = useMemo(() => {
    if (isFileLink) {
      return 'img/icons/link.svg';
    }

    const mimeType = mime.lookup(url);

    if (mimeType === 'application/pdf') {
      return 'img/icons/pdf.svg';
    }

    if (/^image\/.+$/.test(mimeType)) {
      return 'img/icons/image.svg';
    }

    if (
      mimeType === 'application/msword' ||
      mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return 'img/icons/doc.svg';
    }

    if (
      mimeType === 'application/vnd.ms-powerpoint' ||
      mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      return 'img/icons/ppt.svg';
    }

    if (
      mimeType === 'application/vnd.ms-excel' ||
      mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return 'img/icons/xls.svg';
    }

    return 'img/icons/file.svg';
  }, [isFileLink, url]);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleFileItemClicked = () => {
    if (!isFileLink) {
      let mimeType = mime.lookup(url);
      NativeOrWeb.downloadFile(url, filename, mimeType);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleClickEdit = event => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    handleOpenEditFilenameDialog();
  };

  const handleClickDelete = event => {
    event.preventDefault();
    event.stopPropagation();
    handleClose();
    if (onFileDeleted) {
      onFileDeleted(file);
    }
  };

  const handleOpenEditFilenameDialog = () => {
    setEditFilenameDialogOpen(true);
  };

  const handleCloseEditFilenameDialog = () => {
    setEditFilenameDialogOpen(false);
  };

  const handleEditFilenameDialogSubmit = filename => {
    if (onFilenameChange) {
      onFilenameChange(file, filename);
    }
  };


  return (
    <Fragment>
      <EditFilenameDialog
        file={file}
        key={file._id}
        open={editFilenameDialogOpen}
        onClose={handleCloseEditFilenameDialog}
        onSubmit={handleEditFilenameDialogSubmit}
      />

      <ContentSlide className="fileSlide">
        <div className="iconContainer">
          <img
            src={iconUrl}
            className="icon"
            alt={iconUrl}
            onClick={handleFileItemClicked}
            style={{ display: 'unset' }}
            role="button"
          />

          <IconButton
            onClick={handleClick}
            style={{
              height: 20,
              position: 'absolute',
              right: 0,
              top: 0,
              color: '#8C8B81',
              padding: 0
            }}
          >
            <ExpandMoreIcon />
          </IconButton>

          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClickEdit}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <EditIcon style={{ verticalAlign: 'middle' }} />
                </Grid>
                <Grid item>
                  <Typography>{intl.formatMessage({ id: 'Edit' })}</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            {!!isTeamHead && <MenuItem onClick={handleClickDelete}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <DeleteIcon style={{ verticalAlign: 'middle' }} />
                </Grid>
                <Grid item>
                  <Typography>{intl.formatMessage({ id: 'Delete' })}</Typography>
                </Grid>
              </Grid>
            </MenuItem>}
            <MenuItem style={{ borderTop: '1px solid currentColor', pointerEvents: 'none' }}>
              <Grid container direction="column">
                <Grid item>
                  <Typography gutterBottom={true}>{intl.formatMessage({ id: 'file-slide-created-at' })}</Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom={true}>{moment(createdAt).format('DD-MMM-YYYY')}</Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom={true}>@ {moment(createdAt).format('HH:mm:ss')}</Typography>
                </Grid>
                {!isFileLink &&
                  <Grid item>
                    <Typography>{intl.formatMessage({ id: 'file-slide-size' }, { size: formatBytes(size) })}</Typography>
                  </Grid>
                }
              </Grid>
            </MenuItem>
          </Menu>
        </div>
        <Typography align="center" className="itemTitle" onClick={handleFileItemClicked} role="button">
          {filename}
        </Typography>
      </ContentSlide>
    </Fragment>
  );
}
