import { Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';

export const getFormattedName = async (filename, pathPrefix, directory) => {
  const currentPlatform = Capacitor.getPlatform();
  const isIOS = currentPlatform === 'ios';

  let formattedName = filename || '';
  let nameOk = isIOS;
  let currentTry = 0;

  const lastIndexOfDot = formattedName.lastIndexOf('.');
  const extension = lastIndexOfDot > -1 ? formattedName.substr(lastIndexOfDot + 1) : '';
  const formattedNameOnly = lastIndexOfDot > -1 ? formattedName.substr(0, lastIndexOfDot) : formattedName;

  while (!nameOk) {
    try {
      await Filesystem.readFile({
        path: `${pathPrefix || ''}${formattedName}`,
        directory: directory
      });

      currentTry++;
      formattedName = `${formattedNameOnly}(${currentTry}).${extension}`;
    } catch (error) {
      // error would be caught if there is a file with the same name
      nameOk = true;
    }
  }

  return formattedName;
};
