import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import { tracking } from '../../../../utils/tracking';

const DialogActionsStyle = {
  justifyContent: 'space-between'
};

const BeforeEditDialog = props => {
  const { history, location, match, staticContext, intl, open, onClose, ...rest } = props;

  const profilePlus = () => {
    tracking('Profile+ Social Platform Modal: Profile+');
    history.push('/profile-plus');
    onClose();
  };

  const edit = () => {
    tracking('Profile+ Social Platform Modal: Edit');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} {...rest}>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ margin: '-20px -24px -8px', width: 'calc(100% + 24px + 24px)' }}
        >
          <Grid item>
            <img src="img/Prpbanner.png" alt="deal-with-client" style={{ width: '100%' }} />
          </Grid>
          <Grid item>
            <Typography style={{ fontWeight: 400, fontSize: '120%', padding: 20, whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'prp-into' })}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={DialogActionsStyle}>
        <Button color="primary" onClick={profilePlus}>
          {intl.formatMessage({ id: 'View' })}
        </Button>
        <Button variant="outlined" color="primary" onClick={edit}>
          {intl.formatMessage({ id: 'go-edit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(withRouter(BeforeEditDialog));
