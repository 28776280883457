import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import copy from 'copy-to-clipboard';

export const copyToClipboard = async message => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    copy(message);
  } else {
    await Clipboard.write({ string: message });
  }
};
