import React, { forwardRef, useRef } from 'react';
import './MyMedicalCard.scss';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

const MyMedicalCardStyle = {
  position: 'relative',
  width: '100%'
};

const CardImageContainerStyle = {
  width: '100%'
};

const CardDummyImageContainerStyle = {};

const CardImageStyle = {
  width: '100%'
};

const CardInfoStyle = {
  position: 'absolute',
  top: '62%',
  zIndex: 1
};

const CardExpiryDateStyle = {
  position: 'absolute',
  bottom: '7%',
  zIndex: 1
};

const MyMedicalCard = forwardRef((props, ref) => {
  const { intl, medicalCard } = props;
  const { serviceStartDate } = medicalCard || {};

  const cardImageContainerRef = useRef(null);
  const containerWidth = _.get(cardImageContainerRef, 'current.offsetWidth', 195) - 2;
  const infoScaleFactor = containerWidth / 310;
  const expiryDateScaleFactor = (containerWidth / 310) * 0.65;
  const serviceStartDateMoment = moment(serviceStartDate);
  const isStarted = !serviceStartDate ? true : serviceStartDateMoment.isBefore(moment());

  return (
    <div className="my-medical-card" style={MyMedicalCardStyle}>
      {!!medicalCard ? (
        <div className="card-image-container" ref={cardImageContainerRef}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            className="card-info"
            style={{
              ...CardInfoStyle,
              paddingLeft: `${(containerWidth * 0.08) / infoScaleFactor}px`,
              transformOrigin: 'left top',
              transform: `perspective(1px) translateZ(0) scale(${infoScaleFactor})`,
              backfaceVisibility: 'hidden',
              WebkitFontSmoothing: 'subpixel-antialiased',
              width: `calc(100% / ${infoScaleFactor})`
            }}
          >
            <Grid item>
              <Typography>{`${medicalCard.lastName} ${medicalCard.firstName}`}</Typography>
            </Grid>
            <Grid item>
              <Typography>{medicalCard.cardNo}</Typography>
            </Grid>
            {!isStarted && (
              <Grid item>
                <Typography style={{ color: '#f8a1a4', fontSize: '80%' }}>
                  {intl.formatMessage(
                    { id: 'med-card-start-from' },
                    { date: serviceStartDateMoment.format('DD MMM YYYY') }
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            direction="column"
            alignItems="flex-end"
            className="card-expiry-date"
            style={{
              ...CardExpiryDateStyle,
              paddingRight: `${(containerWidth * 0.035) / expiryDateScaleFactor}px`,
              transformOrigin: 'right bottom',
              transform: `perspective(1px) translateZ(0) scale(${expiryDateScaleFactor})`,
              backfaceVisibility: 'hidden',
              WebkitFontSmoothing: 'subpixel-antialiased'
            }}
          >
            <Grid item>
              <Typography>{moment(medicalCard.serviceEndDate).format('DD MMM YYYY')}</Typography>
            </Grid>
          </Grid>
          <img
            src="img/medical-card/MixCare_Card_background.png"
            alt="my-medical-card"
            style={{
              ...CardImageStyle,
              transform: `perspective(1px) translateZ(0)`,
              backfaceVisibility: 'hidden',
              WebkitFontSmoothing: 'subpixel-antialiased'
            }}
          />
        </div>
      ) : (
        <div className="card-image-container" style={{ ...CardImageContainerStyle, ...CardDummyImageContainerStyle }}>
          <img src="img/medical-card/dummy_card.png" alt="my-medical-card" style={CardImageStyle} />
        </div>
      )}
    </div>
  );
});

export default injectIntl(MyMedicalCard);
