import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../../store/control/duck';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { SlideTransition } from '../../../../components/Transition/Transition';
import Typography from '@material-ui/core/Typography';

const ExportOptionsHintDialog = props => {
  const { intl, analysisExportOptionsHintDialog, toggleDialog } = props;

  const { closed } = analysisExportOptionsHintDialog || {};

  const close = () => toggleDialog('analysisExportOptionsHint', { ...analysisExportOptionsHintDialog, closed: true });
  const onClosed = () => toggleDialog('analysisExportOptionsHint', false);

  return (
    <Dialog
      open={!!analysisExportOptionsHintDialog && !closed}
      onClose={close}
      onExited={onClosed}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'export-options-hint-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Typography>{intl.formatMessage({ id: 'export-options-hint-dialog-content' })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    analysisExportOptionsHintDialog: state.control.analysisExportOptionsHintDialog
  }),
  {
    toggleDialog
  }
)(ExportOptionsHintDialog);

export default injectIntl(container);
