import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import IconToast from '../../../components/NewToast';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer.js';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

class DefaultWhatUpMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultMessage: '',
      err: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.setDefaultState = this.setDefaultState.bind(this);
  }

  handleChange(event) {
    const { err } = this.state;
    err.defaultMessage = '';
    this.setState({ [event.target.name]: event.target.value });
  }

  submitDefaultMessage(event) {
    const { intl, updateAdviserBusiness } = this.props;
    const { defaultMessage } = this.state;

    event.preventDefault();
    const err = {};
    if (defaultMessage === '') {
      err.defaultMessage = intl.formatMessage({ id: 'Enter Your Message' });
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      const data = {};
      data.defaultMessage = this.state.defaultMessage;
      updateAdviserBusiness(data);
    }
  }

  UNSAFE_componentWillMount() {
    const { adviserBusiness, fetchAdviserBusiness } = this.props;
    fetchAdviserBusiness();
    this.setDefaultState(adviserBusiness);
  }

  setDefaultState(adviser) {
    const { intl } = this.props;
    let defaultMessage = _.get(adviser, 'defaultMessage');
    if (defaultMessage !== undefined && defaultMessage !== null && defaultMessage !== '') {
      this.setState({ defaultMessage: defaultMessage });
    } else {
      let whatsAppMessage = intl.formatMessage({
        id: 'Hi, we connected in PortfoPlus, feel free ask me anything.'
      });
      this.setState({ defaultMessage: whatsAppMessage });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Checks wether the text was updated successfully, and shows a toast
    const { intl } = this.props;
    if (nextProps.updateMessagePhase === true && nextProps.updateMessagePhase !== this.props.updateMessagePhase) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Message added successfully' })), {
        className: 'new-toast'
      });
    }
    let adviserBusiness = _.get(nextProps, 'adviserBusiness');
    this.setDefaultState(adviserBusiness);
  }

  render() {
    const { intl } = this.props;
    const { defaultMessage, err } = this.state;
    return (
      <AppContentContainer>
        <div>
          {this.props.updateMessagePhase === 'loading' && <LoadingBackdrop open={true} />}
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid policyPaddingDefaultWhatupMessage">
                <ul className="referral-card">
                  <li>
                    <div className="text-center">
                      <h4>
                        {intl.formatMessage({
                          id: 'Default_page_heading'
                        })}
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="portfolioCard">
                      <div className="form-group referral-textfield">
                        <textarea
                          placeholder={intl.formatMessage({
                            id: 'Enter Your Message'
                          })}
                          required="required"
                          onChange={this.handleChange}
                          value={defaultMessage}
                          name="defaultMessage"
                        />
                        <i className="bar" />
                        {err.defaultMessage ? <span className="error_field">{err.defaultMessage}</span> : ''}
                      </div>
                    </div>
                  </li>
                  <li>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.submitDefaultMessage.bind(this)}
                      className="referral-button"
                    >
                      {intl.formatMessage({ id: 'Submit' })}
                    </Button>
                    {/*
                    <input
                      type="button"
                      onClick={this.submitDefaultMessage.bind(this)}
                      className="referral-button"
                      value={intl.formatMessage({ id: 'Submit' })}
                    /> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

export default DefaultWhatUpMessage;
