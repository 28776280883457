import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import CustomInputSelect from '../../../../../Analysis/partials/CustomInputSelect/CustomInputSelect';

const CurrencyInputDialog = props => {
  const { intl, currency, open, onClose } = props;

  const [internalCurrency, setInternalCurrency] = useState(currency);

  useEffect(() => {
    setInternalCurrency(currency);
  }, [currency]);

  const onChange = event => {
    setInternalCurrency(event.target.value);
  };

  const cancel = () => {
    onClose();
  };

  const submit = () => {
    onClose(internalCurrency);
  };

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'pf-currency-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'pf-currency-dialog-content' })}</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" justify="center" spacing={1}>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'pf-currency-dialog-currency-label' })}</Typography>
              </Grid>
              <Grid item style={{ minWidth: 64 }}>
                <CustomInputSelect
                  value={internalCurrency}
                  onChange={onChange}
                  selectOptions={[
                    {
                      value: 1 / 7.8,
                      label: '0.128 (1/7.8)'
                    },
                    1,
                    7.8
                  ]}
                  allowCustomValue={true}
                  min={0}
                  decimalPlaces={3}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={cancel}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={submit}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(CurrencyInputDialog);
