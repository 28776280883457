import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';

const WhatsappReminderDialog = props => {
  const { intl, open, onSkip, onFillIn, ...rest } = props;

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'whatsapp-reminder-dialog-title' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'whatsapp-reminder-dialog-content' })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSkip} color="primary">
          {intl.formatMessage({ id: 'continue' })}
        </Button>
        <Button className="button-title-font" onClick={onFillIn} variant="contained" color="primary" autoFocus>
          {intl.formatMessage({ id: 'fill-in-now' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(WhatsappReminderDialog);
