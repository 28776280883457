import React from 'react';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const MedicalCardFreeTrialHintDialog = props => {
  const { intl, open, onClose, onSubmit, ...rest } = props;

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'med-card-free-trial-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Typography color="textSecondary">
          {intl.formatMessage({ id: 'med-card-free-trial-dialog-content' })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onSubmit}>
          {intl.formatMessage({ id: 'med-card-free-trial-dialog-cta' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(MedicalCardFreeTrialHintDialog);
