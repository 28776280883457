import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../store/control/duck';
import { Dialog, DialogContent } from '@material-ui/core';
import FunctionCardsContainer from '../FunctionCard/FunctionCardsContainer';
import { SlideTransition } from '../Transition/Transition';
import ECardDialog from '../ECardDialog/ECardDialog';
import { tracking } from '../../utils/tracking';
import { HotLabel } from '../../views/AdviserHomePage/HotLabel/HotLabel';
import ReferralQRCodeDialog from '../ReferralQRCodeDialog/ReferralQRCodeDialog';
import CornerTriangle from '../CornerTriangle/CornerTriangle';

const DialogContentStyle = {
  padding: 8
};

const MyQRDialog = props => {
  const { history, intl, userDetails, myQRDialog, toggleDialog } = props;

  const [eCardDialog, setECardDialog] = useState(false);

  const { closed } = myQRDialog || {};

  const close = () => toggleDialog('myQR', { ...myQRDialog, closed: true });
  const onClosed = () => toggleDialog('myQR', false);
  const closeECardDialog = () => setECardDialog(false);

  return (
    <Fragment>
      <Dialog
        open={!!myQRDialog && !closed}
        onClose={close}
        onExited={onClosed}
        fullWidth={true}
        TransitionComponent={SlideTransition}
      >
        <DialogContent style={DialogContentStyle}>
          <FunctionCardsContainer
            items={[
              {
                color: '#5fc3d2',
                imgSrc: 'img/Ecard.png',
                imgAlt: 'e-card',
                text: intl.formatMessage({ id: 'e-card' }),
                subtitle: intl.formatMessage({ id: 'e-card-desc' }),
                onClick: () => {
                  setECardDialog(true);
                }
              },
              {
                color: '#3999f8',
                imgSrc: 'img/profile-plus_icon.png',
                imgAlt: 'qr-code',
                text: intl.formatMessage({ id: 'share-qr' }),
                subtitle: intl.formatMessage({ id: 'ca-qr-desc' }),
                onClick: () => {
                  tracking('open refer QRCode');
                  const dialogObj = {
                    shouldShowWallet: true,
                    customCTAMessage: intl.formatMessage({ id: 'referral-card-message' }),
                    defaultMessageIntlId: 'referral-link-message',
                    defaultMessageExtras: { name: userDetails?.name }
                  };
                  toggleDialog('referralQRCode', dialogObj);
                },
                blocking: true,
                extras: (
                  <HotLabel
                    text={intl.formatMessage({ id: 'hot' })}
                    style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                  />
                )
              },
              {
                color: '#2260a5',
                imgSrc: 'img/add_user_group_blue.svg',
                imgAlt: 'referral-qr-code',
                text: intl.formatMessage({ id: 'ca-referral-qr' }),
                subtitle: intl.formatMessage({ id: 'ca-referral-qr-desc' }),
                onClick: () => {
                  tracking('open refer QRCode');
                  const dialogObj = {
                    referLinkSearch: {
                      defaultMessage: intl.formatMessage(
                        { id: 'ca-referral-qr-default-msg' },
                        { adviserName: userDetails?.name }
                      )
                    },
                    customCTAMessage: intl.formatMessage({ id: 'referral-card-message-2' }),
                    defaultMessageIntlId: 'referral-link-message-2',
                    defaultMessageExtras: { name: userDetails?.name }
                  };

                  toggleDialog('referralQRCode', dialogObj);
                },
                blocking: true,
                extras: (
                  <Fragment>
                    <CornerTriangle
                      text="COT"
                      backgroundColor="#ffc000"
                      containerStyle={{ left: 6, borderRadius: 0 }}
                    />
                    <HotLabel
                      text={intl.formatMessage({ id: 'hot' })}
                      style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                    />
                  </Fragment>
                )
              }
            ]}
          />
        </DialogContent>
      </Dialog>
      <ECardDialog open={eCardDialog} onClose={closeECardDialog} />
      <ReferralQRCodeDialog history={history} />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    myQRDialog: state.control.myQRDialog
  }),
  { toggleDialog }
)(MyQRDialog);

export default injectIntl(container);
