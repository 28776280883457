import 'moment';
import _ from 'lodash';
import { INFLATION_RATE } from '../constants/analysis';
import { currencyExchange } from './currencyExchange';

const calculateLifeTopUpReach = (benchmark, lifeCoverage, monthlyExpense, debt, target) => {
  return (
    Math.max(
      0,
      Math.ceil
        (
          ((
            (1 - Math.pow(INFLATION_RATE, target * benchmark * 12)) / (1 - INFLATION_RATE)) * monthlyExpense +
            debt -
            lifeCoverage) /
          10000
        )
    ) * 10000
  );
};

export const getLifeToReachData = (lifeCoverage, monthlyExpense, debt, age) => {
  let obj = {
    reachGood: 0,
    reachExcellent: 0
  };

  let totalLifeNetDebt = lifeCoverage - debt;

  lifeCoverage = parseFloat(lifeCoverage);
  monthlyExpense = parseFloat(monthlyExpense);
  debt = parseFloat(debt);
  age = parseInt(age);

  let yearSupport =
    Math.log(1 - (Math.max(0, totalLifeNetDebt) / monthlyExpense) * (1 - INFLATION_RATE)) /
    Math.log(INFLATION_RATE) /
    12;

  obj.yearSupport = yearSupport.toFixed(1);

  let benchmark = 70 - age;

  // Update 30 APR
  // 
  if (benchmark > 0) {
    obj.score = Math.min(100, Math.max(0, Math.floor((yearSupport / benchmark) * 100)));
  } else if (age >= 70) {
    obj.score = Math.min(100, Math.max(0, Math.floor((yearSupport / (70 / age)) * 100)));
  }

  // console.log('benchmark:', benchmark, ' yearSupport:', yearSupport, ' age:', age, ' lifeCoverage:', lifeCoverage, ' monthlyExpense: ', monthlyExpense, ' debt:', debt, ' final score:', obj.score);

  if (obj.score < 62.5) {
    obj.reachGood = calculateLifeTopUpReach(benchmark, lifeCoverage, monthlyExpense, debt, 0.625);
  }

  if (obj.score < 87.5) {
    obj.reachExcellent = calculateLifeTopUpReach(benchmark, lifeCoverage, monthlyExpense, debt, 0.875);
  }

  return obj;
};

const calculateCITopUpReach = (benchmark, ciCoverage, target) => {
  return Math.max(0, Math.ceil((target * benchmark - ciCoverage) / 10000) * 10000);
};

export const getCIToReachData = (ciCoverage, monthlyIncome, ciClaimCoverExpenses, currency) => {
  let obj = {
    reachGood: 0,
    reachExcellent: 0
  };

  ciCoverage = parseFloat(ciCoverage);
  monthlyIncome = parseFloat(monthlyIncome);
  ciClaimCoverExpenses = parseFloat(ciClaimCoverExpenses);

  let benchmarkValue = (
    currencyExchange(600000, currency, 'HKD') +
    (monthlyIncome * (1 - Math.pow(INFLATION_RATE, ciClaimCoverExpenses * 12))) / (1 - INFLATION_RATE)
  ).toFixed(0);

  let benchmarkValueExtra = parseFloat(benchmarkValue);
  let newBenchmark = benchmarkValue * Math.pow(INFLATION_RATE, 120);
  obj.score = Math.min(100, Math.max(0, Math.floor((ciCoverage / newBenchmark) * 100)));

  if (obj.score < 62.5) {
    obj.reachGood = calculateCITopUpReach(newBenchmark, ciCoverage, 0.625);
  }

  if (obj.score < 87.5) {
    obj.reachExcellent = calculateCITopUpReach(newBenchmark, ciCoverage, 0.875);
  }

  obj.benchmarkValueExtra = benchmarkValueExtra;

  return obj;
};

export const getSavingToReachData = (savingTotalCoverage, monthlyIncome) => {
  return {
    score:
      monthlyIncome !== 0 ? Math.min(100, Math.max(0, Math.floor((savingTotalCoverage / monthlyIncome) * 100))) : 100,
    reachMid: 0.375 * monthlyIncome - savingTotalCoverage,
    reachHigh: 0.625 * monthlyIncome - savingTotalCoverage
  };
};

export const getMedicalToReachData = medicalPolicyCounts => {
  let highendCount = 0,
    othersCount = 0;
  let score = 0;
  if (Array.isArray(medicalPolicyCounts)) {
    medicalPolicyCounts.forEach(countObject => {
      if (countObject._id === 'Highend') {
        highendCount = countObject.count;
      } else {
        othersCount += countObject.count;
      }
    });
  } else {
    highendCount = _.get(medicalPolicyCounts, 'highend', 0);
    othersCount = _.get(medicalPolicyCounts, 'others', 0);
  }

  if (highendCount > 0) {
    score = 100;
  } else if (othersCount > 0) {
    score = 50;
  }

  return { score: score };
};

export const getPeerScore = (lifeBetterThanKey, ciBetterThanKey) => {
  const lifeBetterThanScore = parseInt(lifeBetterThanKey),
    ciBetterThanScore = parseInt(ciBetterThanKey);
  return (
    ((isNaN(lifeBetterThanScore) ? 0 : lifeBetterThanScore) + (isNaN(ciBetterThanScore) ? 0 : ciBetterThanScore)) / 2
  );
};

// module.exports.getLifeToReachData = getLifeToReachData;
// module.exports.getCIToReachData = getCIToReachData;
