import React, { Fragment, useState, useRef, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import AnalysisOverall from './partials/AnalysiOverall/AnalysisOverall';
import MyPolicyView from './partials/myPolicy';
import MarketView from './partials/market';
import SavingView from './partials/saving';
import MySwipeableViews from '../../components/MySwipeableViews/MySwipeableViews';
import MyTabs from '../../components/MyTabs/MyTabs';
import './analysis.scss';
import { updateAnalysisInputs, resetAnalysis } from '../../store/analysis/duck';
import Fab from '@material-ui/core/Fab';
import _ from 'lodash';
import ConnectWithAdviser from '../../components/connectWithAdviser';
import { Grid, Portal } from '@material-ui/core';
import { getScoreColor, getScoreEmoji, putClientDataToStore } from '../../utils/analysis';
import ExportOptionsHintDialog from './partials/ExportOptionsHintDialog/ExportOptionsHintDialog';
import { connect } from 'react-redux';
import { getUser } from '../../store/user/duck';
import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import PolicyPickerDefaultPicked from './partials/PolicyPickerDefaultPicked/PolicyPickerDefaultPicked';
import LifeInputPanel from './partials/LifeInputPanel/LifeInputPanel';
import CIInputPanel from './partials/CIInputPanel/CIInputPanel';
import SavingInputPanel from './partials/SavingInputPanel/SavingInputPanel';
import { usePrevious } from '../../utils/hooks';
import { isUserType } from '../../utils/user';
import FinancialChartsButton from './partials/FinancialChartsButton/FinancialChartsButton';
import { mobile } from '../../utils/device';
import {
  FINANCIAL_CHARTS_CANCER_CHANCE_ITEM,
  FINANCIAL_CHARTS_MAIN_DEATH_CAUSE_ITEM,
  FINANCIAL_CHARTS_SAVE_EARLY_ITEM
} from '../../constants/financial-charts';
import { trackDirect } from '../../store/analytics/api';
// import AppContentContainer from '../../components/AppContentContainer/AppContentContainer.js';

const WrapperStyle = {
  height: '100vh',
  paddingBottom: '50px',
  paddingTop: '100px',
  background: '#F5F5F5'
};

const NeedAnalysisView = memo(
  props => {
    const {
      intl,
      safariGenerate,
      history,
      location,
      userDetails,
      monthlyExpense,
      monthlyIncome,
      age,
      lifeScore,
      ciScore,
      savingScore,
      debtPlusMortgageLoan,
      updateAnalysisInputs,
      resetAnalysis,
      getUser,
      renderHeaderButton
    } = props;

    const [currentSlide, setCurrentSlide] = useState(0);
    const [swipeable, setSwipeable] = useState(true);
    const [connectDialog, setConnectDialog] = useState(false);
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
    const [clickAdviser, setClickAdviser] = useState(false);

    const mySwipeableViewsRef = useRef(null);
    const lifeInputPanelRef = useRef(null);
    const ciInputPanelRef = useRef(null);
    const savingInputPanelRef = useRef(null);
    const triggerPointRef = useRef(720);
    const tempTabRef = useRef(undefined);

    const prevLocation = usePrevious(location);


    const client = _.get(history, 'location.state.client');
    const { _id: clientId } = client || {};
    const triggerPoint = triggerPointRef.current;

    const [elementId, setElementId] = useState(`${require('../../utils/uuid4').uuid4()}`);


    useEffect(() => {
      const trackViewership = require('../../utils/tracking/index').trackViewership;
      trackViewership(elementId, {
        'Page Path': window.location.pathname,
        'Page URL': window.location.href,
        'Page Domain': window.location.hostname,
        'Interval': 5000
      });

      const timer = setTimeout(() => {
        trackDirect("viewNeedAnalysis");
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    const updateDimensions = useCallback(() => {
      const newWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
      if (newWidth <= triggerPoint && width > triggerPoint) {
        setCurrentSlide(currentSlide + 1);
      } else if (newWidth > triggerPoint && width <= triggerPoint) {
        setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
      }
      setWidth(newWidth);
    }, [currentSlide, triggerPoint, width]);

    useEffect(() => {
      updateDimensions();
    }, [updateDimensions]);

    useEffect(() => {
      window.addEventListener('resize', updateDimensions);

      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, [updateDimensions]);

    useEffect(() => {
      return () => {
        resetAnalysis();
      };
    }, [resetAnalysis]);

    useEffect(() => {
      putClientDataToStore(history, updateAnalysisInputs);
    }, [history, updateAnalysisInputs]);

    useEffect(() => {
      if (clientId && location.state && !location.state.previousPath) {
        renderHeaderButton(false);
      } else {
        renderHeaderButton(true);
      }
    }, [clientId, location, renderHeaderButton]);

    useEffect(() => {
      //To set the default current slide index when adviser access the client analysis
      const clientIncData = _.get(location, 'state.clientFactsheetDetail.clientIncData');
      if (clientIncData) {
        const index = _.get(clientIncData, 'currentSlide', 0);
        if (window.innerWidth > triggerPoint) {
          setCurrentSlide(index > 0 ? index - 1 : index);
        } else {
          setCurrentSlide(index);
        }
      }
    }, [location, triggerPoint]);

    useEffect(() => {
      getUser();
    }, [getUser]);

    useEffect(() => {
      if (location !== prevLocation) {
        renderHeaderButton(!location.state || !location.state.previousPath);
      }
    }, [location, prevLocation, renderHeaderButton]);

    useEffect(() => {
      if (userDetails.userType === 'User' && userDetails.advisorDetails && userDetails.advisorDetails.length > 0) {
        setClickAdviser(!!userDetails.advisorDetails.find(val => val.connection === true));
      }
    }, [userDetails]);

    const toggleSwipeableView = value => {
      setSwipeable(value);
    };

    const changeTab = (event, value) => {
      tempTabRef.current = undefined;
      setCurrentSlide(value);
    };

    const onChangeIndex = index => {
      tempTabRef.current = index;
    };

    const onTransitionEnd = () => {
      if (tempTabRef.current !== undefined) {
        setCurrentSlide(tempTabRef.current);
      }
    };

    const openConnectDialog = () => setConnectDialog(true);
    const closeConnectDialog = () => setConnectDialog(false);

    if (safariGenerate === undefined) {
      const isThisSlide = thisSlide => {
        const thisSlideIndex = width > triggerPoint ? thisSlide - 1 : thisSlide;
        return tempTabRef.current === thisSlideIndex || currentSlide === thisSlideIndex;
      };

      return (
        <div id={elementId}>
          <PolicyPickerDefaultPicked />
          <ExportOptionsHintDialog />
          <ConnectWithAdviser
            open={connectDialog}
            onClose={closeConnectDialog}
            intl={intl}
            clickAdviser={clickAdviser}
          />

          <div>
            <Portal container={document.body}>
              <div className="nav-item">
                {width > triggerPoint ? (
                  <Grid container>
                    <Grid item xs={6}>
                      <MyTabs
                        tabsClassName="portfolioTabs"
                        value={0}
                        tabLabels={[intl.formatMessage({ id: 'Overall' })]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MyTabs
                        tabsClassName="portfolioTabs"
                        value={currentSlide}
                        onChange={changeTab}
                        tabLabels={[
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {intl.formatMessage({ id: 'Life' })}
                            <span style={{ color: getScoreColor(lifeScore) }}>{getScoreEmoji(lifeScore)}</span>
                          </span>,
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {intl.formatMessage({ id: 'C.I.' })}
                            <span style={{ color: getScoreColor(ciScore) }}>{getScoreEmoji(ciScore)}</span>
                          </span>,
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {intl.formatMessage({ id: 'Saving' })}
                            <span style={{ color: getScoreColor(savingScore) }}>{getScoreEmoji(savingScore)}</span>
                          </span>
                        ]}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <MyTabs
                    tabsClassName="portfolioTabs"
                    value={currentSlide}
                    onChange={changeTab}
                    tabLabels={[
                      intl.formatMessage({ id: 'Overall' }),
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {intl.formatMessage({ id: 'Life' })}
                        <span style={{ color: getScoreColor(lifeScore) }}>{getScoreEmoji(lifeScore)}</span>
                      </span>,
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {intl.formatMessage({ id: 'C.I.' })}
                        <span style={{ color: getScoreColor(ciScore) }}>{getScoreEmoji(ciScore)}</span>
                      </span>,
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {intl.formatMessage({ id: 'Saving' })}
                        <span style={{ color: getScoreColor(savingScore) }}>{getScoreEmoji(savingScore)}</span>
                      </span>
                    ]}
                  />
                )}
              </div>

              {isThisSlide(1) && <LifeInputPanel client={client} ref={lifeInputPanelRef} />}

              {isThisSlide(2) && <CIInputPanel ref={ciInputPanelRef} />}

              {isThisSlide(3) && <SavingInputPanel ref={savingInputPanelRef} />}

              {(isThisSlide(1) || isThisSlide(2) || isThisSlide(3) || (isThisSlide(0) && mobile())) &&
                userDetails &&
                userDetails.userType === 'Adviser' && (
                  <FinancialChartsButton
                    isWide={width > triggerPoint}
                    defaultItem={
                      isThisSlide(1)
                        ? { ...FINANCIAL_CHARTS_MAIN_DEATH_CAUSE_ITEM }
                        : isThisSlide(2)
                          ? { ...FINANCIAL_CHARTS_CANCER_CHANCE_ITEM }
                          : isThisSlide(3)
                            ? { ...FINANCIAL_CHARTS_SAVE_EARLY_ITEM }
                            : undefined
                    }
                  />
                )}
            </Portal>
            <div id="wrapper" style={WrapperStyle}>
              <div id="page-content-wrapper" className="dashboard-wrapper">
                <div className="container-fluid">
                  <div className="row">
                    {width > triggerPoint ? (
                      <Grid container>
                        <Grid item xs={6}>
                          <div>
                            <AnalysisOverall
                              history={history}
                              location={location}
                              isSafariGenerate={false}
                              toggleSwipeableView={toggleSwipeableView}
                              mySwipeableViews={mySwipeableViewsRef}
                              client={client}
                              isWide={true}
                              currentSlide={currentSlide}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <MySwipeableViews
                            className="analysis-swipeable-view"
                            disabled={!swipeable}
                            ref={mySwipeableViewsRef}
                            index={currentSlide}
                            onChangeIndex={onChangeIndex}
                            onTransitionEnd={onTransitionEnd}
                          >
                            <MyPolicyView
                              history={history}
                              location={location}
                              isSafariGenerate={false}
                              inputPanelRef={lifeInputPanelRef}
                              mySwipeableViews={mySwipeableViewsRef}
                              client={client}
                              isWide={true}
                            />
                            <MarketView
                              history={history}
                              location={location}
                              isSafariGenerate={false}
                              inputPanelRef={ciInputPanelRef}
                              mySwipeableViews={mySwipeableViewsRef}
                              client={client}
                              isWide={true}
                            />
                            <SavingView
                              history={history}
                              location={location}
                              isSafariGenerate={false}
                              inputPanelRef={savingInputPanelRef}
                              mySwipeableViews={mySwipeableViewsRef}
                              toggleSwipeableView={toggleSwipeableView}
                              client={client}
                              isWide={true}
                            />
                          </MySwipeableViews>
                        </Grid>
                      </Grid>
                    ) : (
                      <Fragment>
                        <MySwipeableViews
                          className={isThisSlide(0) ? '' : 'analysis-swipeable-view'}
                          disabled={!swipeable}
                          ref={mySwipeableViewsRef}
                          index={currentSlide}
                          onChangeIndex={onChangeIndex}
                          onTransitionEnd={onTransitionEnd}
                        >
                          <AnalysisOverall
                            history={history}
                            location={location}
                            isSafariGenerate={false}
                            toggleSwipeableView={toggleSwipeableView}
                            mySwipeableViews={mySwipeableViewsRef}
                            client={client}
                          />
                          <MyPolicyView
                            history={history}
                            location={location}
                            isSafariGenerate={false}
                            inputPanelRef={lifeInputPanelRef}
                            mySwipeableViews={mySwipeableViewsRef}
                            client={client}
                          />
                          <MarketView
                            history={history}
                            location={location}
                            isSafariGenerate={false}
                            inputPanelRef={ciInputPanelRef}
                            mySwipeableViews={mySwipeableViewsRef}
                            client={client}
                          />
                          <SavingView
                            history={history}
                            location={location}
                            isSafariGenerate={false}
                            inputPanelRef={savingInputPanelRef}
                            mySwipeableViews={mySwipeableViewsRef}
                            toggleSwipeableView={toggleSwipeableView}
                            client={client}
                          />
                        </MySwipeableViews>
                      </Fragment>
                    )}

                    {!clientId && isUserType(userDetails) && (
                      <Fab color="primary" aria-label="Request for Advice" className="ask-for-advice-fab">
                        {clickAdviser && (
                          <Link
                            to={{
                              pathname: '/message',
                              state: {
                                analysisPage: true,
                                prevLocation: location.pathname
                              }
                            }}
                          >
                            {intl.formatMessage({ id: 'Ask' })}
                          </Link>
                        )}
                        {!clickAdviser && <span onClick={openConnectDialog}>{intl.formatMessage({ id: 'Ask' })}</span>}
                      </Fab>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="wrapper" className="safari-gen">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                {safariGenerate === 'overall' && (
                  <AnalysisOverall history={history} isSafariGenerate={true} client={client} />
                )}
                {safariGenerate === 'life' && (
                  <MyPolicyView history={history} isSafariGenerate={true} client={client} />
                )}
                {safariGenerate === 'ci' && <MarketView history={history} isSafariGenerate={true} client={client} />}
                {safariGenerate === 'saving' && (
                  <SavingView history={history} isSafariGenerate={true} client={client} />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

// NeedAnalysisView.whyDidYouRender = true;

const NeedAnalysis = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,

    monthlyExpense: state.analysis.monthlyExpense,
    debtPlusMortgageLoan: state.analysis.debtPlusMortgageLoan,
    age: state.analysis.age,
    monthlyIncome: state.analysis.monthlyIncome,
    lifeScore: state.analysis.lifeToReachData.score,
    ciScore: state.analysis.ciToReachData.score,
    savingScore: state.analysis.savingToReachData.score
  }),
  // Map actions to props
  {
    getUser,
    renderHeaderButton,
    updateAnalysisInputs,
    resetAnalysis
  }
)(NeedAnalysisView);

export default injectIntl(NeedAnalysis);
