import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';
import {
  getDebtOptions,
  getExtraMoneyOptions,
  getLifeTopUpMax,
  getLifeTopUpOptions,
  getScoreColor,
  lifePolicyPickerDefaultFilter
} from '../../../../utils/analysis';
import PolicyPickerInfo from '../PolicyPickerInfo/PolicyPickerInfo';
import _ from 'lodash';
import PolicyPickerButton from '../PolicyPickerButton/PolicyPickerButton';
import { isInsuredNotMePolicy, isPolicyCategory, isPolicySubCategory } from '../../../../utils/policy';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import CustomInputSelect from '../CustomInputSelect/CustomInputSelect';
import {
  DEBT_MAX,
  EXPENSE_OPTIONS,
  INPUT_GROUP_TYPE,
  SCORE_LEVEL_COLORS,
  SUPPORT_YEAR_OPTIONS
} from '../../../../constants/analysis';
import AgeInput from '../AgeInput/AgeInput';
import AnalysisInputPanel from '../AnalysisInputPanel';
import { getUserCurrency } from '../../../../utils/user';
import { updateAnalysisInputs, updateLifeSelectedPolicies } from '../../../../store/analysis/duck';
import { updateUserDetails } from '../../../../store/user/duck';
import ExpenseCalculatorTriggerButton from '../../../../components/ExpenseCalculatorDialog/ExpenseCalculatorTriggerButton/ExpenseCalculatorTriggerButton';
import Typography from '@material-ui/core/Typography';
import FigureUnitTypo from '../../../../components/FigureUnitTypo/FigureUnitTypo';
import { withStyles } from '@material-ui/styles';
import InputPanelSlider from '../InputPanelSlider/InputPanelSlider';
import { getLifeToReachData } from '../../../../utils/analysisCalculator';

const DurationDivStyle = {
  borderLeft: '3px solid #3EDA7D',
  paddingLeft: 6,
  marginLeft: 7
};

const IncomeDivStyle = {
  borderLeft: '3px solid #FFA600',
  paddingLeft: 6,
  marginLeft: 7
};

const PICKER_FILTER = policy =>
  (isPolicyCategory(policy, 'personal') ||
    (isPolicyCategory(policy, 'general') && isPolicySubCategory(policy, 'saving')) ||
    (isPolicyCategory(policy, 'general') && isPolicySubCategory(policy, 'provident')) ||
    (isPolicyCategory(policy, 'company'))
  ) &&
  !isInsuredNotMePolicy(policy);

const LifeInputPanel = forwardRef((props, ref) => {
  const {
    intl,
    userDetails,
    client,
    mortgageData,
    lifeData,
    monthlyExpense,
    age,
    debt,
    lifeTopUp,
    lifeExtraMoney,
    lifeFamilySupportNeeded,
    lifeTotalCoverage,
    totalOutStandingLoan,
    debtPlusMortgageLoan,
    lifeIncludeMortgageAmount,
    lifeSelectedPolicies,
    updateUserDetails,
    updateLifeSelectedPolicies,
    updateAnalysisInputs,
    policies
  } = props;

  const { _id: clientId, factsheetId } = client || {};

  const [inputGroupOption, setInputGroupOption] = useState(INPUT_GROUP_TYPE.TARGET_DURATION);
  const stickyInputRef = useRef(null);

  const currency = getUserCurrency(userDetails);
  const debtOptions = getDebtOptions(monthlyExpense);
  const extraMoneyOptions = getExtraMoneyOptions(monthlyExpense);
  const lifeTopUpOptions = getLifeTopUpOptions(monthlyExpense, debtPlusMortgageLoan);
  const showMortgage = _.defaultTo(_.get(mortgageData, 'count'), false);

  const currencyIntl = intl.formatMessage({ id: currency });

  const slideTopUp = (event, value) => updateAnalysisInputs({ lifeTopUp: value });
  const submitPolicies = policies => {
    updateLifeSelectedPolicies(policies);
    if (clientId) {
      let selectedPolicies = JSON.parse(sessionStorage.getItem("selectedPolicies") || "{}");
      if (selectedPolicies[clientId]) {
        selectedPolicies[clientId]["lifeSelectedPolicies"] = policies.map(policy => policy._id);
      } else {
        selectedPolicies[clientId] = { "lifeSelectedPolicies": policies.map(policy => policy._id) };
      }
      sessionStorage.setItem("selectedPolicies", JSON.stringify(selectedPolicies));
    }
  }
  const changeInputGroupOption = event => setInputGroupOption(event.target.value);
  const changeMonthlyExpense = event => {
    if (event.target.value !== monthlyExpense) {
      const updates = { monthlyExpense: event.target.value };
      if (!clientId) {
        updateUserDetails(updates);
      }

      updateAnalysisInputs(updates);
    }
  };
  const submitExpenses = total => changeMonthlyExpense({ target: { value: total } });
  const changeAge = event => {
    const value = isNaN(event.target.value) ? 35 : event.target.value ? parseInt(event.target.value) : 0;
    if (value !== age) {
      updateAnalysisInputs({ age: age });
    }
  };

  const changeDebt = event => {
    if (event.target.value !== debt) {
      const updates = { debt: event.target.value };

      if (!clientId) {
        updateUserDetails(updates);
      }

      updates.lifeIncludeMortgageAmount = false;

      updateAnalysisInputs(updates);
    }
  };
  const toggleAddMortgageAmount = () => updateAnalysisInputs({ lifeIncludeMortgageAmount: true });
  const changeFamilySupportNeeded = event => {
    if (event.target.value !== lifeFamilySupportNeeded) {
      updateAnalysisInputs({ lifeFamilySupportNeeded: event.target.value });
    }
  };
  const changeExtraMoney = event => {
    if (event.target.value !== lifeExtraMoney) {
      updateAnalysisInputs({ lifeExtraMoney: event.target.value });
    }
  };
  const changeTopUp = event => {
    if (event.target.value !== lifeTopUp) {
      updateAnalysisInputs({ lifeTopUp: event.target.value });
    }
  };
  const open = () => {
    if (stickyInputRef && stickyInputRef.current) {
      stickyInputRef.current.openInput();
    }
  };
  const close = () => {
    if (stickyInputRef && stickyInputRef.current) {
      stickyInputRef.current.closeInput();
    }
  };

  useImperativeHandle(ref, () => ({
    open: open,
    close: close,
    setInputGroupOption: setInputGroupOption
  }));

  useEffect(() => {
    let selectedPolicies = JSON.parse(sessionStorage.getItem("selectedPolicies") || "{}");
    if (selectedPolicies[clientId] && selectedPolicies[clientId]["lifeSelectedPolicies"]) {
      let newPolicies = policies.filter(policy => selectedPolicies[clientId]["lifeSelectedPolicies"].includes(policy._id));
      updateLifeSelectedPolicies(newPolicies);
    }
  }, [policies]);

  return (
    <AnalysisInputPanel
      className="life-input-panel analysis-input-panel client-dash-analysis client-analysis-top-bar"
      ref={stickyInputRef}
      inputTitle={
        <Grid container direction="column">
          <Grid item>
            <div style={{ fontSize: 18 }}>
              {`${intl.formatMessage({ id: 'Personal Life' })}: `}
              <span className="figureColor">{`${currencyIntl} ${decimalToString(lifeTotalCoverage)}`}</span>
            </div>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography>{intl.formatMessage({ id: 'analysis-existing' })}</Typography>
              </Grid>
              <Grid item>
                <FigureUnitTypo {...formatNumber(lifeTotalCoverage - lifeTopUp, intl, true)} sameFontSize={true} />
              </Grid>
              <Grid item>
                <Typography>+</Typography>
              </Grid>
              <Grid item>
                <FigureUnitTypo {...formatNumber(lifeTopUp, intl, true)} sameFontSize={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      slider={
        <InputPanelSlider
          min={0}
          max={getLifeTopUpMax(monthlyExpense, debtPlusMortgageLoan)}
          steps={100}
          value={lifeTopUp}
          onChangeCommitted={slideTopUp}
          stopPropagation={true}
          getTrackColor={internalValue => {
            const { score } = getLifeToReachData(
              internalValue + (lifeTotalCoverage - lifeTopUp),
              monthlyExpense,
              debtPlusMortgageLoan,
              age
            );
            return getScoreColor(score);
          }}
          valueLabelFormat={decimalToString}
        />
      }
      detailComponents={
        <div className="analysis-input-groups">
          <PolicyPickerInfo
            figures={[
              {
                value: {
                  value: _.get(lifeData, 'count', 0),
                  unit: intl.formatMessage({ id: 'analy-picker-count-unit' })
                },
                label: intl.formatMessage({ id: 'analy-picker-count-label' })
              },
              {
                value: formatNumber(_.get(lifeData, 'totalLifeCoverage', 0), intl, true),
                label: intl.formatMessage({ id: 'total-coverage' })
              },
              {
                value: formatNumber(_.get(lifeData, 'totalMonthlyPremium', 0), intl, true),
                label: intl.formatMessage({ id: 'Monthly Premium' })
              }
            ]}
            button={
              <PolicyPickerButton
                selectedPolicies={lifeSelectedPolicies}
                filter={PICKER_FILTER}
                onSubmit={submitPolicies}
              />
            }
          />

          <FormControl className="input-group-option-select form_mr">
            <RadioGroup value={inputGroupOption} onChange={changeInputGroupOption}>
              <FormControlLabel
                className={`target-duration${inputGroupOption === INPUT_GROUP_TYPE.TARGET_DURATION ? ' checked' : ''}`}
                value={INPUT_GROUP_TYPE.TARGET_DURATION}
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'Duration' })}
              />
              <FormControlLabel
                className={`target-income${inputGroupOption === INPUT_GROUP_TYPE.TARGET_INCOME ? ' checked' : ''}`}
                value={INPUT_GROUP_TYPE.TARGET_INCOME}
                control={<Radio color="primary" />}
                label={intl.formatMessage({ id: 'IncomeAnalysis' })}
              />
            </RadioGroup>
          </FormControl>

          {inputGroupOption === INPUT_GROUP_TYPE.TARGET_DURATION && (
            <div style={DurationDivStyle}>
              <CustomInputSelect
                className="analysis-input"
                label={
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>{intl.formatMessage({ id: 'Mthly family expense' })}</Grid>
                    {clientId && (
                      <Grid item>
                        <ExpenseCalculatorTriggerButton
                          clientId={clientId}
                          factsheetId={factsheetId && factsheetId._id}
                          onSubmit={submitExpenses}
                        />
                      </Grid>
                    )}
                  </Grid>
                }
                onChange={changeMonthlyExpense}
                value={monthlyExpense === undefined ? 0 : monthlyExpense}
                startAdornmentLabel={currencyIntl}
                selectOptions={EXPENSE_OPTIONS}
                isDollarValue={true}
                allowCustomValue={true}
                min={0}
                max={EXPENSE_OPTIONS[EXPENSE_OPTIONS.length - 1]}
                InputLabelProps={{
                  style: {
                    width: 'calc(100% - 6px)'
                  }
                }}
              />

              <AgeInput intl={intl} value={age} onValueChange={changeAge} />

              <CustomInputSelect
                className="analysis-input"
                label={intl.formatMessage({ id: 'Debt amount' })}
                onChange={changeDebt}
                value={debtPlusMortgageLoan}
                startAdornmentLabel={currencyIntl}
                selectOptions={debtOptions}
                isDollarValue={true}
                allowCustomValue={true}
                min={0}
                max={DEBT_MAX}
              />

              <span className="debtamount_right">
                <div className="debt__mortgage">
                  <div>{intl.formatMessage({ id: 'Mortgage Loan' })}</div>
                  <div>
                    {`${currencyIntl} ${totalOutStandingLoan ? decimalToString(totalOutStandingLoan) : showMortgage ? 0 : '-'
                      }`}
                  </div>
                </div>
                <div className="payment-info-detail">
                  <Button
                    variant="contained"
                    className={showMortgage ? 'enabled--add_mortgage' : 'disabled--add_mortgage'}
                    onClick={toggleAddMortgageAmount}
                    disabled={!showMortgage}
                  >
                    {intl.formatMessage({ id: 'Add' })}
                  </Button>
                </div>
              </span>
            </div>
          )}

          {inputGroupOption === INPUT_GROUP_TYPE.TARGET_INCOME && (
            <div style={IncomeDivStyle}>
              <CustomInputSelect
                className="analysis-input"
                label={intl.formatMessage({ id: 'Family support needed' })}
                onChange={changeFamilySupportNeeded}
                value={lifeFamilySupportNeeded}
                endAdornmentLabel={intl.formatMessage({ id: 'Yrs' })}
                selectOptions={SUPPORT_YEAR_OPTIONS}
                allowCustomValue={true}
                min={1}
                nonZeroInteger
                max={SUPPORT_YEAR_OPTIONS[SUPPORT_YEAR_OPTIONS.length - 1]}
              />

              <CustomInputSelect
                className="analysis-input"
                label={
                  intl.locale === 'en'
                    ? `${intl.formatMessage({ id: '$ needed after' })} ${lifeFamilySupportNeeded} ${intl.formatMessage({
                      id: 'yrs'
                    })}`
                    : `${lifeFamilySupportNeeded} ${intl.formatMessage({ id: 'yrs' })}${intl.formatMessage({
                      id: '$ needed after'
                    })}`
                }
                onChange={changeExtraMoney}
                value={lifeExtraMoney}
                startAdornmentLabel={currencyIntl}
                selectOptions={extraMoneyOptions}
                isDollarValue={true}
                allowCustomValue={true}
                min={0}
                max={extraMoneyOptions[extraMoneyOptions.length - 1]}
              />
            </div>
          )}
          <div>
            <CustomInputSelect
              className="analysis-input analysis-input-top-up"
              label={intl.formatMessage({ id: 'Top up protection amount' })}
              onChange={changeTopUp}
              value={lifeTopUp}
              startAdornmentLabel={currencyIntl}
              selectOptions={lifeTopUpOptions}
              isDollarValue={true}
              allowCustomValue={true}
              min={0}
              max={lifeTopUpOptions[lifeTopUpOptions.length - 1]}
            />

            <div className="analysis-input analysis-input-go">
              <Button variant="contained" onClick={close}>
                {intl.formatMessage({ id: 'Go' })}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
});

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    mortgageData: state.analysis.mortgageData,
    lifeData: state.analysis.lifeData,
    lifeSelectedPolicies: state.analysis.lifeSelectedPolicies,
    monthlyExpense: state.analysis.monthlyExpense,
    debt: state.analysis.debt,
    age: state.analysis.age,
    lifeIncludeMortgageAmount: state.analysis.lifeIncludeMortgageAmount,
    lifeTopUp: state.analysis.lifeTopUp,
    lifeExtraMoney: state.analysis.lifeExtraMoney,
    lifeFamilySupportNeeded: state.analysis.lifeFamilySupportNeeded,
    lifeTotalCoverage: state.analysis.lifeTotalCoverage,
    totalOutStandingLoan: state.analysis.totalOutStandingLoan,
    debtPlusMortgageLoan: state.analysis.debtPlusMortgageLoan,
    policies: state.policyPicker.policies
  }),
  {
    updateUserDetails,
    updateAnalysisInputs,
    updateLifeSelectedPolicies
  },
  null,
  {
    forwardRef: true
  }
)(LifeInputPanel);

export default injectIntl(container, { withRef: true });
