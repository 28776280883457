function generateRandomCode(length) {
  let text = '';
  const possibleCharacters = 'abcdefghijkmnpqrstuvwxyz';
  const possibleNumbers = '123456789';

  for (let i = 0; i < length; i++) {
    if (Math.random() > 0.5) {
      text += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    } else {
      text += possibleNumbers.charAt(Math.floor(Math.random() * possibleNumbers.length));
    }
  }

  return text;
}

module.exports.generateRandomCode = generateRandomCode;
