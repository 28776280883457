import React, { Fragment, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';
import { Box, Dialog, DialogContent, IconButton, useMediaQuery } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import './TeamNewsDialog.scss';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const NewDialogPaperStyle = {
  background: 'transparent',
  boxShadow: 'none'
};

const CloseButtonStyle = {
  position: 'absolute',
  color: '#808080',
  opacity: 0.5,
  cursor: 'pointer',
  zIndex: 1,
  background: '#ffffff50',
  padding: 0
};

const ZoomInButtonStyle = {
  position: 'absolute',
  bottom: 0,
  color: '#808080',
  background: '#ffffff50',
  padding: 0
};

const SlideButtonStyle = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)'
};

const TeamNewsDialog = props => {
  const { intl, userDetails, open, onClose, teamNewsUrls, ...rest } = props;

  const swiperRef = useRef(null);
  const [dimensions, setDimensions] = useState({});
  const isWide = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const onSwiper = swiper => (swiperRef.current = swiper);

  const createOnClickHandler = url => () => {
    if (url) {
      const filename = url.split(/(\\|\/)/g).pop();
      if (filename) {
        const fullVersionUrl = url.replace(filename, `full-${filename}`);
        window.open(fullVersionUrl, '_blank');
      }
    }
  };
  const isMoreThanOne = (teamNewsUrls || []).length > 1;

  const onLoadImage = event => {
    const target = event.target;
    if (target) {
      const { src, naturalWidth: srcWidth, naturalHeight: srcHeight } = target;
      const srcAspectRatio = srcWidth / srcHeight;

      const container = target.parentElement;
      const { offsetWidth: containerWidth, offsetHeight: containerHeight } = container;
      const containerAspectRatio = containerWidth / containerHeight;

      let actualWidth, actualHeight;
      if (srcAspectRatio > containerAspectRatio) {
        actualWidth = containerWidth;
        actualHeight = actualWidth / srcAspectRatio;
      } else {
        actualHeight = containerHeight;
        actualWidth = actualHeight * srcAspectRatio;
      }

      const newDimensions = {
        ...dimensions,
        [src]: {
          srcWidth,
          srcHeight,
          srcAspectRatio,
          containerWidth,
          containerHeight,
          containerAspectRatio,
          actualWidth,
          actualHeight
        }
      };
      setDimensions(newDimensions);
    }
  };

  const onSlideBack = event => {
    event.stopPropagation();
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const onSlideForward = event => {
    event.stopPropagation();
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <Dialog
      className="team-news-dialog new"
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      maxWidth="md"
      PaperProps={{
        style: NewDialogPaperStyle
      }}
      {...rest}
    >
      <DialogContent className="content">
        <Swiper
          centeredSlides={true}
          autoHeight={true}
          loop={!!isMoreThanOne}
          // width={Math.min(window.innerWidth - 64, 960)}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }}
          // navigation={{
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev'
          // }}
          autoplay={
            isMoreThanOne
              ? {
                  delay: 8000
                }
              : false
          }
          onSwiper={onSwiper}
        >
          {(teamNewsUrls || []).map((url, index) => {
            const dimension = dimensions[url];
            const { actualWidth } = dimension || {};
            const offsetX = dimension ? `calc(50% - ${actualWidth}px / 2 + ${isWide ? 8 : 4}px)` : undefined;

            return (
              <SwiperSlide key={`${index}-${url}`}>
                <Box maxWidth="80vw" maxHeight="calc(90vh - var(--top-padding))" margin="auto" position="relative">
                  <IconButton onClick={createOnClickHandler(url)} style={{ ...ZoomInButtonStyle, right: offsetX }}>
                    <ZoomInRoundedIcon />
                  </IconButton>
                  <IconButton
                    onClick={onClose}
                    style={{
                      ...CloseButtonStyle,
                      top: isWide ? 8 : 4,
                      right: offsetX
                    }}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={onSlideBack} style={{ ...SlideButtonStyle, left: offsetX }}>
                    <ArrowBackIosRoundedIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={onSlideForward} style={{ ...SlideButtonStyle, right: offsetX }}>
                    <ArrowForwardIosRoundedIcon />
                  </IconButton>

                  <img alt="team-news" src={url} onLoad={onLoadImage} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>

        {isMoreThanOne && (
          <Fragment>
            <div className="swiper-pagination" />
            {/*<div className="swiper-button-next" />*/}
            {/*<div className="swiper-button-prev" />*/}
          </Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(TeamNewsDialog);

export default injectIntl(container);
