import React from 'react';
import './CornerTriangle.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ShortSubscriptions } from '../../utils/user-subscription';
import CornerTriangle from './CornerTriangle';

export const formatExportCornerTriangle = doc => {
  let cornerTriElem = doc.querySelector('.corner-tri div');
  if (cornerTriElem) {
    const top = cornerTriElem.style.top.replace('px', '');
    cornerTriElem.style.top = `${parseFloat(top) - 5}px`;
  }
};

const ProCornerTriangle = props => {
  const { subscriptions, adviserSubscriptionNickname, explicitShow, ...rest } = props;

  return (explicitShow === undefined &&
    (new ShortSubscriptions(subscriptions).containTypedSubscription({ type: 'core', nicknames: ['Pro', 'Ultra'] }) ||
      adviserSubscriptionNickname === 'Pro' ||
      adviserSubscriptionNickname === 'Ultra')) ||
    (explicitShow !== undefined && explicitShow) ? (
    <CornerTriangle text="PRO" backgroundColor="#ffcc00" {...rest} />
  ) : null;
};

const container = connect(
  // Map state to props
  state => ({
    subscriptions: state.userSubscription.subscriptions,
    adviserSubscriptionNickname: state.userSubscription.adviserSubscriptionNickname
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(ProCornerTriangle);

export default injectIntl(container);
