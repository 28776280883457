import React from 'react';
import { PureComponent } from 'react';
import { LOADING } from '../../../constants/phase';
import { Card, CardContent, CardActions } from '@material-ui/core';
import './qr-share-to-user.css';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { getDefaultRoute } from '../../../utils/router';
import { TopNavigationPortal } from '../../../components/topNavigation';
import { sanitize } from 'dompurify';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

class QRShareToUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalMessage: ''
    };
  }

  UNSAFE_componentWillMount() {}

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { intl } = nextProps;

    if (nextProps.qrCodeUseResult === false) {
      this.setState({
        modalOpen: true,
        modalMessage: nextProps.qrCodeResMessage
      });
    } else if (nextProps.qrCodeUseResult === true) {
      this.setState({
        modalOpen: true,
        modalMessage: intl.formatMessage({ id: 'Successfully established!' })
      });
    }
  }

  redirectToHome() {
    const { userDetails, isAuthenticated } = this.props;

    if (isAuthenticated && userDetails !== undefined) {
      this.props.history.push({
        pathname: getDefaultRoute(userDetails)
      });
    } else {
      this.props.history.push({
        pathname: '/'
      });
    }
  }

  handleClick(choice) {
    if (choice === true) {
      const { qrCode, useQRCode } = this.props;
      if (useQRCode !== undefined && typeof useQRCode === 'function') {
        useQRCode(qrCode.qrCodeId);
      }
    } else {
      this.redirectToHome();
    }
  }

  handleCloseModal() {
    this.redirectToHome();
  }

  render() {
    //console.log(this.props.qrCode);
    const { intl, qrCode, qrCodeFetchPhase } = this.props;
    const { modalOpen, modalMessage } = this.state;

    return (
      <div>
        {qrCodeFetchPhase === LOADING && <LoadingBackdrop open={true} />}
        <TopNavigationPortal>
          <li className="title">{intl.formatMessage({ id: 'Connect with User' })}</li>
        </TopNavigationPortal>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid emergency-view-padding">
              <Card className="qr-share-confirmation-card">
                {this.props.qrCode !== undefined ? (
                  <div>
                    <CardContent>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitize(
                            intl.formatMessage(
                              { id: 'qr_code_confirm_accept' },
                              {
                                userType: intl.formatMessage({
                                  id: qrCode.creator.userType
                                })
                              }
                            )
                          )
                        }}
                      />
                      <br />
                      <div className="qr-share-info-line">
                        <div className="qr-share-info-label">{intl.formatMessage({ id: 'Name' })}: </div>
                        <div className="qr-share-info-value">
                          <b>
                            {qrCode.content.senderName !== undefined && qrCode.content.senderName !== ''
                              ? qrCode.content.senderName
                              : qrCode.creator.name}
                          </b>
                        </div>
                      </div>
                      <br />
                      <div className="qr-share-info-line">
                        <div className="qr-share-info-label">{intl.formatMessage({ id: 'Email' })}: </div>
                        <div className="qr-share-info-value">
                          <b>{qrCode.creator.email}</b>
                        </div>
                      </div>
                    </CardContent>

                    <CardActions>
                      <Button
                        className="qr-share-button"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClick(false)}
                      >
                        {intl.formatMessage({ id: 'No' })}
                      </Button>
                      <Button
                        className="qr-share-button"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleClick(true)}
                      >
                        {intl.formatMessage({ id: 'Yes' })}
                      </Button>
                    </CardActions>
                  </div>
                ) : (
                  <div>
                    <div>{intl.formatMessage({ id: 'qr_code_undefined' })}</div>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
        <Dialog
          open={modalOpen}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'QR Code' })}</DialogTitle>
          <DialogContent>{modalMessage}</DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.handleCloseModal();
              }}
              color="primary"
              autoFocus
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default QRShareToUser;
