import { injectIntl } from 'react-intl';
import React, { PureComponent, Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, FormControl, Radio, RadioGroup, Button, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import { addThousandSeparators } from '../../../../../../utils/formatNumber';
import { withTheme } from '@material-ui/core/styles';

class CurrentProgressModal extends PureComponent {
  render() {
    const {
      intl,
      open,
      onClose,
      radioGrpOnChange,
      okBtnOnClick,
      closeBtnOnClick,
      currentProgressOption,
      numberFormatOnChange,
      adjustedBusinessVol,
      currentProgress,
      totalAdjustedTrackingBiz
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        fullWidth={true}
        keepMounted
      >
        <DialogTitle id="alert-dialog-title"> {intl.formatMessage({ id: 'Followup Biz' })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'Followup Biz Description' })}</DialogContentText>
          <div className="caseclose-form caseclose-form-business">
            <div className="form-group">
              <div className="row">
                <div className="col-md-2 col-xs-2" />
                <div style={{ width: '100%' }} className="col-md-10 col-xs-10 text-right adv_biz_modal_key">
                  <FormControl className="d-flex-box">
                    <RadioGroup
                      className="language-setting-option"
                      aria-label="inputGroupOption"
                      name="currentProgressOption"
                      value={currentProgressOption}
                      onChange={radioGrpOnChange}
                    >
                      <FormControlLabel
                        className="text-left"
                        value={'showExpectedFrom'}
                        control={<Radio color="primary" />}
                        label={intl.formatMessage({
                          id: 'Adjusted Tracking Biz'
                        })}
                      />

                      <div
                        style={{
                          width: '90%',
                          color: this.props.theme.palette.primary.main,
                          textAlign: 'center',
                          fontSize: '1.2em'
                        }}
                      >
                        {addThousandSeparators(adjustedBusinessVol.toFixed(0))}
                      </div>

                      <FormControlLabel
                        className="text-left"
                        value={'showManualInput'}
                        control={<Radio color="primary" />}
                        label={
                          <Fragment>
                            <Button
                              className="follow-up-oppo-btn"
                              style={{ color: '#3966f8', border: '1.1px solid #3966f8' }}
                              variant="outlined"
                            >
                              {intl.formatMessage({ id: 'Manual-short' })}
                            </Button>
                            <span style={{ marginLeft: '22px' }}>{intl.formatMessage({ id: 'Input Manually' })}</span>
                          </Fragment>
                        }
                      />
                    </RadioGroup>
                    <NumberFormat
                      className="form-control"
                      style={{
                        width: '90%',
                        color: this.props.theme.palette.primary.main,
                        textAlign: 'center',
                        height: '20px',
                        marginTop: '7px',
                        fontSize: '1.2em'
                      }}
                      thousandSeparator={true}
                      onValueChange={numberFormatOnChange}
                      value={currentProgress}
                      inputMode="decimal"
                      pattern="[0-9]*"
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          <br />

          <DialogContentText>{intl.formatMessage({ id: 'Reference' })}:</DialogContentText>
          <DialogContentText>{intl.formatMessage({ id: 'biz-adjustment-reference' })}</DialogContentText>
          <DialogContentText>
            <Grid container justify="center" alignItems="center" style={{ lineHeight: 2.2 }}>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#5ee092', border: '1.1px solid #5ee092' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'oppH' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 75%
              </Grid>
              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#ffaf19', border: '1.1px solid #ffaf19' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'oppM' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 50%
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
          </DialogContentText>

          <DialogContentText>
            <Grid container justify="center" alignItems="center" style={{ lineHeight: 2.2 }}>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#ff6a74', border: '1.1px solid #ff6a74' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'oppL' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 25%
              </Grid>

              <Grid item xs={2} md={2}></Grid>

              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#cccccc', border: '1.1px solid #cccccc' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'NA' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 10%
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
          </DialogContentText>

          <DialogContentText>
            {intl.formatMessage({ id: 'biz-adjustment-reference-figure' })}{' '}
            <span style={{ color: this.props.theme.palette.primary.main, fontSize: '1.2em' }}>
              {addThousandSeparators(totalAdjustedTrackingBiz.toFixed(0))}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBtnOnClick} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
          <Button onClick={okBtnOnClick} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(withTheme(CurrentProgressModal));
