import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import './PortfolioViewSwitch.scss';
import { injectIntl } from 'react-intl';

const getButtons = (intl, premiumFrequency) => [
  {
    infoType: 'Protection',
    premiumFrequency: undefined,
    icon: <FavoriteIcon />,
    text: intl.formatMessage({ id: 'portf-view-c' })
  },
  {
    infoType: 'Premium',
    premiumFrequency: premiumFrequency ? premiumFrequency : 'Monthly',
    icon: <MonetizationOnIcon />,
    text: intl.formatMessage({ id: premiumFrequency === 'Yearly' ? 'portf-view-y' : 'portf-view-m' })
  },
  {
    infoType: 'MarketValue',
    premiumFrequency: undefined,
    icon: <MonetizationOnIcon />,
    text: intl.formatMessage({ id: 'portf-view-v' })
  }
];

const PortfolioViewSwitch = props => {
  const { intl, infoType, premiumFrequency, onChange, ...rest } = props;
  const buttons = getButtons(intl, premiumFrequency);

  const createOnChangeHandler = (buttonInfoType, buttonPremiumFrequency) => event => {
    if (infoType === 'Premium' && buttonInfoType === 'Premium') {
      onChange(buttonInfoType, buttonPremiumFrequency === 'Monthly' ? 'Yearly' : 'Monthly');
    } else {
      onChange(buttonInfoType, buttonPremiumFrequency);
    }
  };

  return (
    <div className="portfolio-view-switch" {...rest}>
      <Grid container direction="column" alignItems="stretch" wrap="nowrap" style={{ height: '100%' }}>
        {buttons.map((button, index) => {
          const isSelected =
            (!button.infoType || button.infoType === infoType) &&
            (!button.premiumFrequency || button.premiumFrequency === premiumFrequency);
          return (
            <Grid item xs={4} key={index}>
              <Button
                variant="outlined"
                onClick={createOnChangeHandler(button.infoType, button.premiumFrequency)}
                style={
                  isSelected
                    ? {
                        color: '#3966f8',
                        borderLeft: 'none',
                        borderRight: '3px solid #3966f8'
                      }
                    : {
                        color: '#00000088'
                      }
                }
              >
                <Grid container direction="column">
                  <Grid item>{button.icon}</Grid>
                  <Grid item className="text">
                    {button.text}
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default injectIntl(PortfolioViewSwitch);
