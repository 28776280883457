import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Grid, Typography, Button, Container } from '@material-ui/core';
import { copyToClipboard } from '../../../../utils/native/copy';
import _ from 'lodash';
import { Redirect } from 'react-router';

const FabContainerStyle = {
  position: 'fixed',
  bottom: 10,
  right: 10,
  zIndex: 5
};

const ContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%'
};

const GridContainerStyle = {};

const ImageGridStyle = {
  textAlign: 'center',
  marginBottom: 50
};

const ImageStyle = {
  maxWidth: 450,
  padding: 8,
  width: '100%'
};

const TypoGridStyle = {
  padding: '0 16px'
};

const TitleTypoStyle = {
  fontSize: '200%'
};

const ContentTypoStyle = {
  fontSize: '150%',
  whiteSpace: 'pre-wrap',
  color: '#333'
};

const ButtonStyle = {
  textDecoration: 'underline'
};

const NativeFallbackCopy = props => {
  const { intl, location } = props;

  const url = _.get(location, 'state.url');

  const fab = () =>
    window.open(
      'https://api.whatsapp.com/send?phone=85266370320&text=(APP-PC)%20%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%88%91%E6%83%B3%E6%9F%A5%E8%A9%A2',
      '_blank'
    );

  const copy = async () => {
    await copyToClipboard(url);
  };

  return url ? (
    <div>
      <span style={FabContainerStyle} onClick={fab}>
        <img src="img/whatsapp.png" alt="whatsapp" width="50" />
      </span>
      <Container maxWidth="md" style={ContainerStyle}>
        <Grid container spacing={4} style={GridContainerStyle}>
          <Grid item xs={12} style={ImageGridStyle}>
            <img src="img/logo_kyc.png" alt="portfoplus" style={ImageStyle} />
          </Grid>
          <Grid item xs={12} style={TypoGridStyle}>
            <Typography align="center" color="primary" gutterBottom={true} style={TitleTypoStyle}>
              {intl.formatMessage({ id: 'require-web-title' })}
            </Typography>
            <Typography align="center" style={ContentTypoStyle}>
              {intl.formatMessage({ id: 'require-web-content' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <Button color="primary" style={ButtonStyle} onClick={copy}>
                  {intl.formatMessage({ id: 'Copy' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default injectIntl(withRouter(NativeFallbackCopy));
