import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getAssistant, refreshAssistant, updateDataSourceStatus } from '../../../../store/assistant/duck';
import { injectIntl } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssistantCategory from './AssistantCategory/AssistantCategory';
import AssistantHintDialog from './AssistantCategory/AssistantHintDialog';
import { Button, Container, Typography, Grid } from '@material-ui/core';
import { getClient } from '../../../../store/client/duck';
import { getDuePolicy, getPaidUpPolicies } from '../../../../store/adviserDashboard/duck';
import { getAdviserDashboard } from '../../../../store/adviserDashboard/duck';
import AssistantCustomizeDialog from './AssistantCustomizeDialog/AssistantCustomizeDialog';
import BlockingOverlay from '../../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import SwipeableViews from 'react-swipeable-views';
import { getNotificationCenterList } from '../../../../store/notificationCenter/duck';
import AssistantCategoryCustomizeDialog from './AssistantCategoryCustomizeDialog/AssistantCategoryCustomizeDialog';
import { checkAssistantDataExist, retryAssistant, updateAssistantData } from '../../../../utils/assistant';
import './Assistant.scss';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';

const ASSISTANT_WIDTH_TRIGGER_POINT = 1024;
const WIDTHTIGGERPOINT = 1340;
const CATEGORY_NAME_INTL = {
  'to-do': 'ast-cat-to-do',
  'tracking-stage-1': 'ast-cat-tracking-stage-1',
  'tracking-stage-2': 'ast-cat-tracking-stage-2',
  'tracking-stage-3': 'ast-cat-tracking-stage-3',
  'tracking-stage-4': 'ast-cat-tracking-stage-4'
};

class Assistant extends React.PureComponent {
  constructor(props) {
    super(props);

    const stateObj = {
      width: window.innerWidth
    };

    this.unomunted = false;
    this.tempIndex = undefined;

    // check & load assistant data if necessary
    checkAssistantDataExist();

    if (!props.notificationCenterData) {
      props.getNotificationCenterList();
    }

    this.state = stateObj;
  }

  componentWillUnmount() {
    this.unomunted = true;
    window.removeEventListener('resize', this.handleResize, false);
  }

  componentDidMount() {
    // check & update assistant data if there is any change in other data
    updateAssistantData()
      .then()
      .catch();
    window.addEventListener('resize', this.handleResize, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // check & update assistant data if there is any change in other data
    updateAssistantData()
      .then()
      .catch();
  }

  handleRetry = async () => {
    await retryAssistant();
  };

  handleResize = () => {
    const width = window.innerWidth;
    this.setState({ width: width });
  };

  render() {
    const {
      intl,
      assistant,
      assistantGetting,
      assistantUpdating,
      assistantError,
      assistantCategoryIndex,
      onChangeTab
    } = this.props;
    const { width } = this.state;

    let assistantsplitStage = assistant && assistant.categories.slice(1, 5);
    return (
      <div className="assistant">
        {(assistantGetting || assistantUpdating) && (
          <LoadingBackdrop open={true} style={{ top: 0, left: '100%', width: '100%' }} />
        )}
        {assistantError ? (
          <Container style={{ textAlign: 'center' }}>
            <Typography>{assistantError}</Typography>
            <Button color="primary" onClick={this.handleRetry}>
              Retry
            </Button>
          </Container>
        ) : assistant ? (
          <Fragment>
            <BlockingOverlay>
              <div
                className="standard-content-tabs"
                style={{
                  height: width >= 960 ? 'calc(100vh - 90px)' : 'calc(100vh - 140px)',
                  overflow: width < WIDTHTIGGERPOINT ? 'hidden' : 'unset'
                }}
              >
                {width < ASSISTANT_WIDTH_TRIGGER_POINT ? (
                  <React.Fragment>
                    <Tabs
                      className="tabs client-stage-tabs"
                      value={assistantCategoryIndex}
                      onChange={(event, index) => {
                        onChangeTab(index);
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                    >
                      {assistant.categories.map((category, index) => (
                        <Tab
                          key={index}
                          label={`${intl.formatMessage({ id: CATEGORY_NAME_INTL[category.name] })} ${
                            index > 0 ? `(${category.lists[0].data.filter(entry => !entry.isDone).length})` : ''
                          }`}
                        />
                      ))}
                    </Tabs>
                    <SwipeableViews
                      enableMouseEvents={true}
                      index={assistantCategoryIndex}
                      onChangeIndex={index => {
                        this.tempIndex = index;
                      }}
                      onTransitionEnd={() => {
                        if (this.tempIndex !== undefined) {
                          onChangeTab(this.tempIndex);
                          this.tempIndex = undefined;
                        }
                      }}
                      className="swipe__view__inner"
                    >
                      {assistant.categories.map((category, index) => (
                        <div className="adviser-dashboard-slide" key={index} style={{ paddingBottom: '130px' }}>
                          <AssistantCategory category={category} />
                        </div>
                      ))}
                    </SwipeableViews>
                  </React.Fragment>
                ) : (
                  <Grid container>
                    <Grid xs={4} item id="assistant-tab-0">
                      <Tabs
                        className="tabs client-stage-tabs left-tab"
                        value={0}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label={intl.formatMessage({ id: 'Assistant' })} />
                      </Tabs>
                    </Grid>
                    <Grid xs={8} item id="assistant-tab-1">
                      <Tabs
                        className="tabs client-right-stage-tabs right-tab"
                        value={assistantCategoryIndex}
                        onChange={(event, index) => {
                          onChangeTab(index);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        {assistantsplitStage.map((category, index) => (
                          <Tab
                            key={index}
                            label={`${intl.formatMessage({ id: CATEGORY_NAME_INTL[category.name] })} (${
                              category.lists[0].data.filter(entry => !entry.isDone).length
                            })`}
                          />
                        ))}
                      </Tabs>
                    </Grid>
                    <Grid xs={4} item id="assistant-category-0">
                      <div className="adviser-dashboard-slide" style={{ paddingBottom: '100px' }}>
                        <AssistantCategory category={assistant.categories[0]} />
                      </div>
                    </Grid>
                    <Grid xs={8} item id="assistant-category-1">
                      <SwipeableViews
                        enableMouseEvents={true}
                        index={assistantCategoryIndex}
                        onChangeIndex={index => {
                          this.tempIndex = index;
                        }}
                        onTransitionEnd={() => {
                          if (this.tempIndex !== undefined) {
                            onChangeTab(this.tempIndex);
                            this.tempIndex = undefined;
                          }
                        }}
                        className={
                          width > ASSISTANT_WIDTH_TRIGGER_POINT && width < WIDTHTIGGERPOINT && 'swipe__view__inner'
                        }
                      >
                        <div className="adviser-dashboard-slide" style={{ paddingBottom: '85px' }}>
                          <Grid container>
                            <AssistantCategory category={assistantsplitStage[0]} />
                          </Grid>
                        </div>
                        <div className="adviser-dashboard-slide" style={{ paddingBottom: '85px' }}>
                          <Grid container>
                            <AssistantCategory category={assistantsplitStage[1]} />
                          </Grid>
                        </div>
                        <div className="adviser-dashboard-slide" style={{ paddingBottom: '85px' }}>
                          <Grid container>
                            <AssistantCategory category={assistantsplitStage[2]} />
                          </Grid>
                        </div>
                        <div className="adviser-dashboard-slide" style={{ paddingBottom: '85px' }}>
                          <Grid container>
                            <AssistantCategory category={assistantsplitStage[3]} />
                          </Grid>
                        </div>
                      </SwipeableViews>
                    </Grid>
                  </Grid>
                )}
              </div>
            </BlockingOverlay>
            <AssistantHintDialog />
            <AssistantCustomizeDialog />
            <AssistantCategoryCustomizeDialog />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    assistant: state.assistant.formattedAssistant,
    assistantGetting: state.assistant.getting,
    assistantUpdating: state.assistant.updating,
    assistantError: state.assistant.error,
    assistantNeedUpdate: state.assistant.needUpdate,
    fetchClientPhase: state.client.fetchClientPhase,
    GetUsersPolicyDueData: state.adviserDashboard.GetUsersPolicyDueData,
    GetConnectedUsersBirthday: state.adviserDashboard.GetConnectedUsersBirthday,
    notificationCenterData: state.notificationCenter.notificationCenterData,
    paidUpPolicyClients: state.adviserDashboard.paidUpPolicyClients
  }),
  // Map actions to props
  {
    getAssistant,
    refreshAssistant,
    getClient,
    getAdviserDashboard,
    getDuePolicy,
    updateDataSourceStatus,
    getPaidUpPolicies,
    getNotificationCenterList
  },
  // mergeProps
  null,
  // options
  {}
)(Assistant);

export default injectIntl(container);
