import React, { useState, memo, useEffect, useCallback, Fragment } from 'react';
import './AnalysisOverall.scss';
import { DEBT_MAX, EXPENSE_OPTIONS, INCOME_OPTIONS } from '../../../../constants/analysis';
import CustomInputSelect from '../CustomInputSelect/CustomInputSelect';
import AgeInput from '../AgeInput/AgeInput';
import { connect } from 'react-redux';
import { updateAnalysisInputs, updateAnalysisOverallExport } from '../../../../store/analysis/duck';
import { injectIntl } from 'react-intl';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';
import MySlider from '../../../../components/MySlider/MySlider';
import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Button,
  Grid
} from '@material-ui/core';
// import AnalysisExport from '../AnalysisExport/AnalysisExport';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { formatAnalysisDoc } from '../AnalysisExport/formatter';
import { updateUserDetails } from '../../../../store/user/duck';
import _ from 'lodash';
import { getCITopUpMax, getDebtOptions, getLifeTopUpMax, getSavingTopUpMax } from '../../../../utils/analysis';
import SyncInputButton from '../SyncInputButton/SyncInputButton';
import OverallRadar from '../OverallRadar/OverallRadar';
import { getMedicalToReachData, getPeerScore } from '../../../../utils/analysisCalculator';
import { toggleDialog } from '../../../../store/control/duck';
import RemoveIcon from '@material-ui/icons/Remove';
import CreateIcon from '@material-ui/icons/Create';
import { getUserCurrency } from '../../../../utils/user';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import { toast } from 'react-toastify';
import { submitClient } from '../../../../store/client/duck';
import { LOADING } from '../../../../constants/phase';
import IconToast from '../../../../components/NewToast';
import { usePrevious } from '../../../../utils/hooks';
import ExpenseCalculatorTriggerButton from '../../../../components/ExpenseCalculatorDialog/ExpenseCalculatorTriggerButton/ExpenseCalculatorTriggerButton';
import Typography from '@material-ui/core/Typography';
import LifeSummary from '../LifeSummary/LifeSummary';
import LifePolicyStatistics from '../LifePolicyStatistics/LifePolicyStatistics';
import CIPolicyStatistics from '../CIPolicyStatistics/CIPolicyStatistics';
import CISummary from '../CISummary/CISummary';
import SavingSummary from '../SavingSummary/SavingSummary';
import SavingAnnuitySlides from '../../../../components/SavingAnnuitySlides/SavingAnnuitySlides';
import SavingPolicyStatistics from '../SavingPolicyStatistics/SavingPolicyStatistics';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';

const medicalTextFormatter = (intl, medicalScore) => {
  if (medicalScore === 0) {
    return intl.formatMessage({ id: 'No Policy' });
  } else if (medicalScore === 50) {
    return intl.formatMessage({ id: 'Regular' });
  } else {
    return intl.formatMessage({ id: 'Highend' });
  }
};

const AnalysisOverall = memo(
  props => {
    const {
      intl,
      userDetails,
      updatePhase,
      createClientPhase,
      medicalData,
      history,
      client,
      isWide,
      currentSlide,
      isSafariGenerate,
      monthlyExpense,
      age,
      monthlyIncome,
      lifeTopUp,
      lifeTotalCoverage,
      ciTopUp,
      ciTotalCoverage,
      ciClaimCoverExpenses,
      savingTopUp,
      savingTotalCoverage,
      lifeScore,
      ciScore,
      savingScore,
      lifeBetterThanKey,
      ciBetterThanKey,
      exportInputs,
      analysisConfirmSaveDialog,
      debtPlusMortgageLoan,
      mySwipeableViews,
      updateUserDetails,
      submitClient,
      updateAnalysisInputs,
      updateAnalysisOverallExport,
      toggleDialog,
      toggleSwipeableView
    } = props;

    const [inputGroupExpanded, setInputGroupExpanded] = useState(false);
    const [customMedicalScore, setCustomMedicalScore] = useState(undefined); // use custom medical score if it is set
    const prevUpdatePhase = usePrevious(updatePhase);
    const prevCreateClientPhase = usePrevious(createClientPhase);

    const { _id: clientId, factsheetId } = client || {};
    const userType = _.get(userDetails, 'userType');
    const currency = getUserCurrency(userDetails);

    const betterThanScore = getPeerScore(lifeBetterThanKey, ciBetterThanKey);
    const medicalScore = getMedicalToReachData(_.get(medicalData, 'counts')).score;
    const formattedMedicalScore = customMedicalScore !== undefined ? customMedicalScore : medicalScore;

    const debtOptions = getDebtOptions(monthlyExpense);

    const toggleInputPanel = () => setInputGroupExpanded(!inputGroupExpanded);
    const handleBeforeSlideChange = () => toggleSwipeableView(false);
    const handleLifeTopUp = (event, value) => {
      updateAnalysisInputs({ lifeTopUp: value });
      toggleSwipeableView(true);
    };
    const handleCITopUp = (event, value) => {
      updateAnalysisInputs({ ciTopUp: value });
      toggleSwipeableView(true);
    };
    const handleSavingTopUp = (event, value) => {
      updateAnalysisInputs({ savingTopUp: value });
      toggleSwipeableView(true);
    };
    const handleMedicalScore = (event, value) => {
      setCustomMedicalScore(value);
      toggleSwipeableView(true);
    };

    const handleChange = async event => {
      try {
        const stateName = event.target.name;
        const value = event.target.value;

        if (stateName === 'monthlyExpense') {
          // if (clientId) {
          //   updateUserDetails({ monthlyExpense: value });
          // }
          updateAnalysisInputs({ monthlyExpense: value });
        } else if (stateName === 'age') {
          updateAnalysisInputs({ age: isNaN(value) ? 35 : parseInt(value ? value : 0) });
        } else if (stateName === 'debt') {
          // if (clientId) {
          //   updateUserDetails({ debt: value });
          // }
          updateAnalysisInputs({ debt: value });
        } else if (stateName === 'monthlyIncome') {
          // if (clientId) {
          //   updateUserDetails({ monthlyIncome: value });
          // }
          updateAnalysisInputs({ monthlyIncome: value });
        }
      } catch (e) {
        console.log(e);
        return e;
      }
    };

    const submitExpenses = total => handleChange({ target: { name: 'monthlyExpense', value: total } });

    const createSwipeableViewsHandler = timeout => event => {
      if (event && event.persist) {
        event.persist();
      }
      setTimeout(() => {
        if (mySwipeableViews && mySwipeableViews.current) {
          mySwipeableViews.current.adjustSlideHeights();
        }
      }, timeout);
    };
    const onChangeInputExpansionPanel = createSwipeableViewsHandler(350);
    const onChangePVPExpansionPanel = createSwipeableViewsHandler(475);
    const onChangeExportInputExpansionPanel = createSwipeableViewsHandler(350);
    const onDidUpdateExport = createSwipeableViewsHandler(300);

    const openConfirmSaveDialog = () => toggleDialog('analysisConfirmSave', {});
    const closeConfirmSaveDialog = () => toggleDialog('analysisConfirmSave', false);

    const save = () => {
      try {
        const updates = {
          monthlyExpense: monthlyExpense,
          debt: debtPlusMortgageLoan,
          monthlyIncome: monthlyIncome
          // age: age
        };

        if (!_.get(client, 'factsheetId.dob')) {
          updates.age = age;
        }

        // const dobFinder = (dob, originalAge) => {
        //   if (dob) {
        //     const dobAge = getAgeFromDob(dob, originalAge);
        //     if (dobAge) {
        //       return moment(dob)
        //         .subtract(age - dobAge, 'y')
        //         .toISOString();
        //     }
        //   } else {
        //     return moment(moment.utc().format('YYYY-MM-DDT00:00:00.00Z'))
        //       .subtract(age, 'y')
        //       .toISOString();
        //   }
        // };

        // if client, update factsheet, else update profile
        const userType = _.get(userDetails, 'userType');
        if (userType === 'Adviser') {
          if (client) {
            updates.clientId = client._id;
            updates._id = client.factsheetId._id;
            updates.advisorId = userDetails._id;
            updates.connectedAdviserEmail = userDetails.email;
            updates.email = client.email;
            // updates.dob = dobFinder(_.get(client, 'factsheetId.dob'), _.get(client, 'factsheetId.age'));
            submitClient(updates);
          } else {
            toast.warn('Client not found!');
          }
        } else {
          // updates.dob = dobFinder(_.get(userDetails, 'dob'), _.get(userDetails, 'age'));
          updateUserDetails(updates);
        }
      } catch (e) {
        toast.warn('Operation failed! Please find us for support');
        console.log(e);
      }

      closeConfirmSaveDialog();
    };

    // const notifyUpdated = useCallback(
    // 	() =>
    // 		toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), {
    // 			className: 'new-toast'
    // 		}),
    // 	[intl]
    // );
    //
    // useEffect(() => {
    // 	if (prevUpdatePhase === LOADING && updatePhase === true) {
    // 		notifyUpdated();
    // 	}
    // }, [updatePhase, prevUpdatePhase, notifyUpdated]);
    //
    // useEffect(() => {
    // 	if (prevCreateClientPhase === LOADING && createClientPhase === true) {
    // 		notifyUpdated();
    // 	}
    // }, [createClientPhase, prevCreateClientPhase, notifyUpdated]);

    return (
      <div className="analysis-slide analysis-overall">
        {!isSafariGenerate && (
          <ConfirmModal
            open={analysisConfirmSaveDialog}
            title={`confirm-sync-input-title-${userDetails.userType === 'Adviser' ? 'a' : 'u'}`}
            content={`confirm-sync-input-content-${userDetails.userType === 'Adviser' ? 'a' : 'u'}`}
            buttons={[
              {
                text: 'Cancel',
                onClick: closeConfirmSaveDialog
              },
              {
                text: 'Save',
                onClick: save
              }
            ]}
          />
        )}

        {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportRadar === true)) && (
          <div className="cardAnalysis analysis-radar">
            <h4 className="text-center greyL6">{intl.formatMessage({ id: 'Protection Summary' })}</h4>

            <div className="Outer overall-radar">
              <OverallRadar
                ci={ciScore}
                saving={savingScore}
                medical={formattedMedicalScore}
                peer={betterThanScore}
                life={lifeScore}
                maintainAspectRatio={true}
                padding={10}
                width={115}
                height={85}
              />
            </div>
          </div>
        )}

        {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportInputPanel === true)) && (
          <ExpansionPanel
            expanded={inputGroupExpanded}
            className="analysis-expansion analysis-bottom export-expansion analysis-input-panel"
            TransitionProps={{
              className: 'export-expansion-collapse',
              timeout: 300
            }}
            onChange={onChangeInputExpansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={
                inputGroupExpanded ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="analysis-input-group-toggle analysis-input-group-toggle-apply"
                  >
                    <RemoveIcon />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className="analysis-input-group-toggle analysis-input-group-toggle-change"
                  >
                    <CreateIcon />
                  </Button>
                )
              }
              IconButtonProps={{ onClick: toggleInputPanel }}
              className="analysis-expansion-summary analysis-input-group-summary"
            >
              {intl.formatMessage({ id: 'my-information' })}
            </ExpansionPanelSummary>

            <ExpansionPanelDetails className="analysis-expansion-detail analysis-expansion-user-detail">
              <div className="input-wrapper">
                <div className="cardAnalysis overall-input-shortcuts">
                  <CustomInputSelect
                    className="analysis-input"
                    label={
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item>{intl.formatMessage({ id: 'Mthly family expense' })}</Grid>
                        {clientId && (
                          <Grid item>
                            <ExpenseCalculatorTriggerButton
                              clientId={clientId}
                              factsheetId={factsheetId && factsheetId._id}
                              onSubmit={submitExpenses}
                            />
                          </Grid>
                        )}
                      </Grid>
                    }
                    name="monthlyExpense"
                    onChange={handleChange}
                    value={monthlyExpense}
                    startAdornmentLabel={intl.formatMessage({ id: currency })}
                    selectOptions={EXPENSE_OPTIONS}
                    isDollarValue={true}
                    allowCustomValue={true}
                    min={0}
                    max={EXPENSE_OPTIONS[EXPENSE_OPTIONS.length - 1]}
                    InputLabelProps={{
                      style: {
                        width: 'calc(100% - 6px)'
                      }
                    }}
                  />

                  <AgeInput intl={intl} value={age} onValueChange={handleChange} />

                  {((userType === 'Adviser' && clientId) || userType === 'User') && <SyncInputButton />}

                  <CustomInputSelect
                    className="analysis-input"
                    label={intl.formatMessage({ id: 'Debt amount' })}
                    name="debt"
                    onChange={handleChange}
                    value={debtPlusMortgageLoan}
                    startAdornmentLabel={intl.formatMessage({ id: currency })}
                    selectOptions={debtOptions}
                    isDollarValue={true}
                    allowCustomValue={true}
                    min={0}
                    max={DEBT_MAX}
                  />

                  <CustomInputSelect
                    className="analysis-input"
                    label={intl.formatMessage({ id: 'Monthly income' })}
                    name="monthlyIncome"
                    onChange={handleChange}
                    value={monthlyIncome}
                    startAdornmentLabel={intl.formatMessage({ id: currency })}
                    selectOptions={INCOME_OPTIONS}
                    isDollarValue={true}
                    allowCustomValue={true}
                    min={0}
                    max={INCOME_OPTIONS[INCOME_OPTIONS.length - 1]}
                  />
                </div>

                <div className="cardAnalysis overall-input-shortcuts">
                  <div className="coverage">
                    <div>
                      <FormLabel>{`${intl.formatMessage({ id: 'Personal Life' })}: `}</FormLabel>
                      <span className="figureColor">
                        {`${intl.formatMessage({ id: currency })} ${formatNumber(lifeTotalCoverage, intl)}`}
                      </span>
                    </div>
                    <div>
                      <MySlider
                        min={0}
                        max={getLifeTopUpMax(monthlyExpense, debtPlusMortgageLoan)}
                        steps={100}
                        value={lifeTopUp}
                        onChange={handleBeforeSlideChange}
                        onChangeCommitted={handleLifeTopUp}
                        stopPropagation={true}
                        valueLabelFormat={decimalToString}
                      />
                    </div>
                  </div>
                </div>

                <div className="cardAnalysis overall-input-shortcuts">
                  <div className="coverage">
                    <FormLabel>{`${intl.formatMessage({ id: 'Personal C.I.' })}: `}</FormLabel>
                    <span className="figureColor">
                      {`${intl.formatMessage({ id: currency })} ${formatNumber(ciTotalCoverage, intl)}`}
                    </span>
                  </div>
                  <div>
                    <MySlider
                      min={0}
                      max={getCITopUpMax(monthlyIncome, ciClaimCoverExpenses)}
                      steps={100}
                      value={ciTopUp}
                      onChange={handleBeforeSlideChange}
                      onChangeCommitted={handleCITopUp}
                      stopPropagation={true}
                      valueLabelFormat={decimalToString}
                    />
                  </div>
                </div>

                <div className="cardAnalysis overall-input-shortcuts">
                  <div className="coverage">
                    <FormLabel>{`${intl.formatMessage({ id: 'Monthly Saving' })}: `}</FormLabel>
                    <span className="figureColor">
                      {`${intl.formatMessage({ id: currency })} ${formatNumber(savingTotalCoverage, intl)}`}
                    </span>
                  </div>
                  <div>
                    <MySlider
                      min={0}
                      max={getSavingTopUpMax(monthlyIncome)}
                      step={100}
                      value={savingTopUp}
                      onChange={handleBeforeSlideChange}
                      onChangeCommitted={handleSavingTopUp}
                      stopPropagation={true}
                      valueLabelFormat={decimalToString}
                    />
                  </div>
                </div>

                <div className="cardAnalysis overall-input-shortcuts">
                  <div className="coverage">
                    <FormLabel>{`${intl.formatMessage({ id: 'Medical' })}: `}</FormLabel>
                    <span className="figureColor">{medicalTextFormatter(intl, formattedMedicalScore)}</span>
                  </div>
                  <div>
                    <MySlider
                      min={0}
                      max={100}
                      step={50}
                      value={medicalScore}
                      onChange={handleBeforeSlideChange}
                      onChangeCommitted={handleMedicalScore}
                      stopPropagation={true}
                      valueLabelFormat={value => medicalTextFormatter(intl, value)}
                    />
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {isWide && currentSlide === 0 && (
          <Fragment>
            <LifeSummary />
            <LifePolicyStatistics onChange={onChangePVPExpansionPanel} />
            <AladdinHint
              title={intl.formatMessage({ id: 'aladdin-life-analysis-title' })}
              helpCenter="c33"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Fragment>
        )}

        {isWide && currentSlide === 1 && (
          <Fragment>
            <CISummary />
            <CIPolicyStatistics onChange={onChangePVPExpansionPanel} />
            <AladdinHint
              title={intl.formatMessage({ id: 'aladdin-ci-analysis-title' })}
              helpCenter="c34"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Fragment>
        )}

        {isWide && currentSlide === 2 && (
          <Fragment>
            <SavingSummary />
            <SavingAnnuitySlides />
            <SavingPolicyStatistics />
            <AladdinHint
              title={intl.formatMessage({ id: 'aladdin-saving-analysis-title' })}
              helpCenter="c35"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </Fragment>
        )}

        {/*{!isSafariGenerate && userType === 'Adviser' && (*/}
        {/*  <ExpansionPanel*/}
        {/*    defaultExpanded={false}*/}
        {/*    className="analysis-expansion exclude-in-image analysis-bottom export-expansion"*/}
        {/*    TransitionProps={{*/}
        {/*      className: 'export-expansion-collapse',*/}
        {/*      timeout: 300*/}
        {/*    }}*/}
        {/*    onChange={onChangeExportInputExpansionPanel}*/}
        {/*  >*/}
        {/*    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">*/}
        {/*      {intl.formatMessage({ id: 'analysis-export-heading' })}&nbsp;<span className="pro-text">PRO</span>*/}
        {/*    </ExpansionPanelSummary>*/}

        {/*    <ExpansionPanelDetails className="analysis-expansion-detail">*/}
        {/*      <AnalysisExport*/}
        {/*        onDidUpdate={onDidUpdateExport}*/}
        {/*        shouldRender={() => {*/}
        {/*          if (!Object.entries(exportInputs).find(entry => entry[1] === true)) {*/}
        {/*            toggleDialog('analysisExportOptionsHint', {});*/}
        {/*            return false;*/}
        {/*          }*/}
        {/*          return true;*/}
        {/*        }}*/}
        {/*        intl={intl}*/}
        {/*        history={history}*/}
        {/*        safariGenPath="/analysis/overall/safari-gen"*/}
        {/*        targetSelector=".analysis-overall"*/}
        {/*        inputs={*/}
        {/*          <FormControl component="fieldset" className="export-select-form">*/}
        {/*            <FormLabel component="legend">{intl.formatMessage({ id: 'Select fields to export' })}</FormLabel>*/}
        {/*            <FormGroup className="export-select-group">*/}
        {/*              {[*/}
        {/*                { state: 'exportRadar', labelId: 'radar-chart' },*/}
        {/*                { state: 'exportInputPanel', labelId: 'input-panel' }*/}
        {/*              ].map((obj, index) => {*/}
        {/*                return (*/}
        {/*                  <FormControl key={index}>*/}
        {/*                    <FormControlLabel*/}
        {/*                      control={*/}
        {/*                        <Checkbox*/}
        {/*                          color="primary"*/}
        {/*                          checked={exportInputs[obj.state] || false}*/}
        {/*                          onChange={(event, checked) =>*/}
        {/*                            updateAnalysisOverallExport({*/}
        {/*                              [obj.state]: checked*/}
        {/*                            })*/}
        {/*                          }*/}
        {/*                          value={obj.state}*/}
        {/*                        />*/}
        {/*                      }*/}
        {/*                      label={intl.formatMessage({ id: obj.labelId })}*/}
        {/*                    />*/}
        {/*                  </FormControl>*/}
        {/*                );*/}
        {/*              })}*/}
        {/*            </FormGroup>*/}
        {/*          </FormControl>*/}
        {/*        }*/}
        {/*        options={() => {*/}
        {/*          const selectionPairs = [*/}
        {/*            { state: 'exportRadar', selector: '.analysis-radar' },*/}
        {/*            {*/}
        {/*              state: 'exportInputPanel',*/}
        {/*              selector: '.analysis-input-panel'*/}
        {/*            }*/}
        {/*          ];*/}

        {/*          return {*/}
        {/*            height:*/}
        {/*              (exportInputs.exportRadar === true*/}
        {/*                ? document.querySelector('.analysis-overall .analysis-radar').offsetHeight + 7*/}
        {/*                : 0) + (exportInputs.exportInputPanel === true ? 485 : 0),*/}
        {/*            backgroundColor: '#f5f5f5',*/}
        {/*            onclone: async doc => {*/}
        {/*              try {*/}
        {/*                formatAnalysisDoc(doc, '.analysis-overall', '.analysis-overall .analysis-input-panel');*/}

        {/*                selectionPairs.forEach(selectionPair => {*/}
        {/*                  if (exportInputs[selectionPair.state] === false) {*/}
        {/*                    doc.querySelector(`.analysis-overall ${selectionPair.selector}`).remove();*/}
        {/*                  }*/}
        {/*                });*/}
        {/*              } catch (e) {*/}
        {/*                return e;*/}
        {/*              }*/}
        {/*            }*/}
        {/*          };*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </ExpansionPanelDetails>*/}
        {/*  </ExpansionPanel>*/}
        {/*)}*/}

        <div className="height-block-for-fab exclude-in-image" />
      </div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    updatePhase: state.user.updatePhase,
    createClientPhase: state.client.createClientPhase,
    medicalData: state.analysis.medicalData,

    monthlyExpense: state.analysis.monthlyExpense,
    debt: state.analysis.debt,
    includeMortgageAmount: state.analysis.includeMortgageAmount,
    age: state.analysis.age,
    monthlyIncome: state.analysis.monthlyIncome,

    lifeTopUp: state.analysis.lifeTopUp,
    lifeTotalCoverage: state.analysis.lifeTotalCoverage,

    ciTopUp: state.analysis.ciTopUp,
    ciTotalCoverage: state.analysis.ciTotalCoverage,
    ciClaimCoverExpenses: state.analysis.ciClaimCoverExpenses,

    savingTopUp: state.analysis.savingTopUp,
    savingTotalCoverage: state.analysis.savingTotalCoverage,

    lifeScore: state.analysis.lifeToReachData.score,
    ciScore: state.analysis.ciToReachData.score,
    savingScore: state.analysis.savingToReachData.score,
    lifeBetterThanKey: state.analysis.lifeBetterThanKey,
    ciBetterThanKey: state.analysis.ciBetterThanKey,
    debtPlusMortgageLoan: state.analysis.debtPlusMortgageLoan,

    exportInputs: { ...state.analysis.overallExport },

    analysisConfirmSaveDialog: state.control.analysisConfirmSaveDialog
  }),
  // Map actions to props
  {
    updateAnalysisInputs,
    updateAnalysisOverallExport,
    updateUserDetails,
    toggleDialog,
    submitClient
  },
  // mergeProps
  null,
  // options
  {}
)(AnalysisOverall);

export default injectIntl(container);
