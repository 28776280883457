import { connect } from 'react-redux';
import { addMessage, fetchMessages, selectClient } from '../../store/message/duck';
import { getUser, getConnectedAdviser, handleSignOut, updateUserDetails } from '../../store/user/duck';
import { toggleDialog } from '../../store/control/duck';
import MessageView from './component';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';

import { injectIntl } from 'react-intl';

const Message = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    connectedAdvisers: state.user.connectedAdvisers,
    fetchMessagesPhase: state.message.fetchMessagesPhase,
    fetchMessageData: state.message.fetchMessageData,
    selectedClient: state.message.selectedClient,
    reminderBadgeCount: state.reminder.reminderBadgeCount
  }),
  // Map actions to props
  {
    addMessage,
    fetchMessages,
    getUser,
    getConnectedAdviser,
    selectClient,
    handleSignOut,
    renderHeaderButton,
    updateUserDetails,
    toggleDialog
  }
)(MessageView);

export default injectIntl(Message);
