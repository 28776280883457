import moment from 'moment';
import { IconButton, InputAdornment } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { DatePicker } from '@material-ui/pickers';
import React from 'react';
import { injectIntl } from 'react-intl';

const MarketValueUpdateDateInput = props => {
  const { intl, InputAdornmentProps, ...rest } = props;

  return (
    <DatePicker
      name="marketValueUpdateDate"
      format="DD-MMM-YYYY"
      clearable={true}
      openTo="date"
      maxDate={new Date()}
      autoOk={true}
      label={`${intl.formatMessage({ id: 'market-value-update-date' })}:`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" {...InputAdornmentProps}>
            <IconButton>
              <TodayIcon color="action" style={{ fontSize: 20 }} />
            </IconButton>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  );
};

export default injectIntl(MarketValueUpdateDateInput);
