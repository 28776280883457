import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleControl, toggleDialog } from '../../../store/control/duck';
import ContentsDialog, { getLocalePikto } from '../ContentsDialog';
import Pikto from '../../Pikto/Pikto';
import _ from 'lodash';
import MDRTActivityCalculator from './MDRTActivityCalculator/MDRTActivityCalculator';
import { MDRT_CALCULATOR_DEFAULT_STATES } from '../../../constants/mdrt-calculator';

const CONTENTS = [
  {
    id: 'HKMkt-adv',
    title: '香港市場優勢 (示範)',
    type: 'pikto',
    section: 'recruit-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid="57748719-career-market-advantage" />
  },
  {
    id: 'support-industry',
    title: '政策支持理財行業 (示範)',
    type: 'pikto',
    section: 'recruit-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid="57751074-career-government-help" />
  },
  {
    id: 'MDRT-into',
    title: 'MDRT介紹 (示範)',
    type: 'pikto',
    section: 'recruit-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid="57749055-career-mdrt-intro" />
  },
  {
    id: 'Adviser-6-advantage',
    title: '理財行業的優勢 (示範)',
    type: 'pikto',
    section: 'recruit-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid="58779297-adviser-6-advantage" />
  },
  {
    id: 'MDRT-quarlities',
    title: '專業顧問的特質 (示範)',
    type: 'pikto',
    section: 'recruit-charts-sub-1',
    color: '#5fc3d2',
    render: ({ intl }) => <Pikto uid="58981584-mdrt-quarlities-recruitment" />
  }
];

const DEFAULT_ITEM = CONTENTS.find(item => item.id === 'HKMkt-adv');

const RecruitmentContentsDialog = props => {
  const { intl, history, recruitmentDialog, recruitmentContents, toggleDialog } = props;

  const { closed } = recruitmentDialog || {};

  const close = () => {
    toggleDialog('recruitment', { ...recruitmentDialog, closed: true });
  };

  const onClosed = () => {
    toggleDialog('recruitment', false);
    toggleControl('mdrtActivityCalculator', MDRT_CALCULATOR_DEFAULT_STATES);
  };

  return (
    <ContentsDialog
      history={history}
      className="recruitment-dialog"
      open={!!recruitmentDialog && !closed}
      onClose={close}
      onClosed={onClosed}
      title={intl.formatMessage({ id: 'recruitment-dialog-title' })}
      type="recruitment"
      sections={[
        ...(recruitmentContents || []).map(recruitmentContent => {
          const nameLocale = _.get(recruitmentContent.name, intl.locale, '');
          return {
            title: nameLocale,
            img: <img src={recruitmentContent.iconImg} alt={nameLocale} width="42" />,
            items: (recruitmentContent.items || []).map(item => {
              const piktoChartIdLocale = _.get(item.piktoChartIds, intl.locale);
              if (piktoChartIdLocale) {
                const titleLocale = _.get(item.title, intl.locale);
                return {
                  id: piktoChartIdLocale,
                  title: titleLocale,
                  type: 'pikto',
                  color: item.color,
                  render: () => <Pikto uid={piktoChartIdLocale} />
                };
              } else {
                return null;
              }
            }),
            special: true
          };
        }),
        {
          title: intl.formatMessage({ id: 'recruitment-dialog-tools-title' }),
          items: [
            {
              id: 'mdrt-calculator',
              title: ({ intl }) => intl.formatMessage({ id: 'recruitment-dialog-tools-mdrt-cal' }),
              type: 'custom',
              section: 'recruit-tools',
              color: '#3966f8',
              fullScreenDialog: true,
              render: ({ intl }) => <MDRTActivityCalculator />
            }
          ]
        },
        {
          title: intl.formatMessage({ id: 'recruit-charts-sub-1' }),
          items: CONTENTS
        }
      ]}
      defaultItem={DEFAULT_ITEM}
    />
  );
};

const container = connect(
  state => ({
    recruitmentDialog: state.control.recruitmentDialog,
    recruitmentContents: state.functions.recruitmentContents
  }),
  {
    toggleDialog,
    toggleControl
  }
)(RecruitmentContentsDialog);

export default injectIntl(container);
