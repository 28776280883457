import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimePicker } from '@material-ui/pickers';
import { Select, MenuItem, useTheme, Input } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import IconToast from '../../../../../components/NewToast';
import NativeOrWeb from '../../../../../utils/native';
import TempSelect from '../../../../../components/TempSelect/TempSelect';
import { sanitize } from 'dompurify';

export default function CreateRoomModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    intl,
    open,
    isBeta,
    onClose,
    handleChange,
    handleDateChange,
    createRoom,
    joinRoom,
    name,
    privacy,
    nbf,
    exp,
    max_participants,
    autojoin,
    enable_knocking,
    enable_screenshare,
    enable_chat,
    start_video_off,
    start_audio_off,
    owner_only_broadcast,
    enable_recording,
    eject_at_room_exp,
    eject_after_elapsed,
    createdRoomURL,
    roomID
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
        fullWidth={true}
      >
        <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Create Meeting ID' })}</DialogTitle>
        <DialogContent className="dialog-content-centered">
          <div className="caseclose-form">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  intl.formatMessage({
                    id: 'Create-Room-Msg'
                  })
                )
              }}
            ></div>
            {isBeta && (
              <div className="form-body">
                <div className="form-group">
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Name' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <Input id="name" name="name" onChange={handleChange} value={name}>
                      {name}
                    </Input>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Privacy' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect id="privacy" name="privacy" value={privacy} onChange={handleChange}>
                      <MenuItem value={'private'}>private</MenuItem>
                      <MenuItem value={'public'}>public</MenuItem>
                      <MenuItem value={'org'}>org</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Not before' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing datepicker-group label___color--date">
                    <DateTimePicker
                      clearable
                      id="nbf"
                      name="nbf"
                      value={nbf}
                      onChange={date => handleDateChange('nbf', date)}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Expire time' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing datepicker-group label___color--date">
                    <DateTimePicker
                      clearable
                      id="exp"
                      name="exp"
                      value={exp}
                      onChange={date => handleDateChange('exp', date)}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Max Participants' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <NumberFormat
                      id="max_participants"
                      className="form-control  number-input"
                      style={{ width: '90%', color: '#3966f8' }}
                      thousandSeparator={true}
                      // onValueChange={handleQuarterTarget()}
                      onValueChange={values => {
                        const { value } = values;
                        let e = {
                          target: {
                            name: 'max_participants',
                            value: value
                          }
                        };
                        handleChange(e);
                      }}
                      value={max_participants}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Auto join' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect id="autojoin" name="autojoin" value={autojoin} onChange={handleChange}>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Enable Knocking' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="enable_knocking"
                      name="enable_knocking"
                      value={enable_knocking}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Enable Screenshare' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="enable_screenshare"
                      name="enable_screenshare"
                      value={enable_screenshare}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Enable Chat' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect id="enable_chat" name="enable_chat" value={enable_chat} onChange={handleChange}>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Start Video Off' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="start_video_off"
                      name="start_video_off"
                      value={start_video_off}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Start Audio Off' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="start_audio_off"
                      name="start_audio_off"
                      value={start_audio_off}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Owner Only Broadcast' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="owner_only_broadcast"
                      name="owner_only_broadcast"
                      value={owner_only_broadcast}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Enable Recording' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="enable_recording"
                      name="enable_recording"
                      value={enable_recording}
                      onChange={handleChange}
                    >
                      <MenuItem value={false}>False</MenuItem>
                      <MenuItem value={'cloud'}>Cloud</MenuItem>
                      <MenuItem value={'local'}>Local</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Eject At Room Expire' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing">
                    <TempSelect
                      id="eject_at_room_exp"
                      name="eject_at_room_exp"
                      value={eject_at_room_exp}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TempSelect>
                  </div>
                </div>
                <div className="form-group" style={{ marginTop: '-26px' }}>
                  <div className="col-md-6 col-xs-6 text-right adv_biz_modal_key case-minWidth">
                    <label className="control-label adv_biz_modal_key quarterlabel">
                      {intl.formatMessage({ id: 'Eject After Elapsed' })} :
                    </label>
                  </div>
                  <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key input__spacing datepicker-group label___color--date">
                    <DateTimePicker
                      clearable
                      id="eject_after_elapsed"
                      name="eject_after_elapsed"
                      value={eject_after_elapsed}
                      onChange={date => handleDateChange('eject_after_elapsed', date)}
                    />
                  </div>
                </div>
              </div>
            )}
            {roomID && createdRoomURL && (
              <div className="create-room">
                <div>
                  <label>
                    {intl.formatMessage({ id: 'Meeting ID' })} : {roomID}
                  </label>
                </div>
                <div>
                  <label>{intl.formatMessage({ id: 'Share Meeting Link' })}</label>
                  <Button
                    variant="contained"
                    color="primary"
                    className="edit-button copy-url-button"
                    onClick={() => {
                      NativeOrWeb.copyToClipboard(
                        `${intl.formatMessage({ id: 'Join Meeting' })}: ${createdRoomURL}`
                      ).then(() => {
                        toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
                          className: 'new-toast'
                        });
                      });
                    }}
                  >
                    {intl.formatMessage({ id: 'Copy' })}
                  </Button>
                </div>
                <div className="create-room">
                  <Button
                    onClick={joinRoom}
                    variant="contained"
                    color="primary"
                    className="edit-button copy-url-button"
                  >
                    {intl.formatMessage({ id: 'Join Meeting Now' })}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
          <Button onClick={createRoom} color="primary">
            {intl.formatMessage({ id: 'Create-Room' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
