import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

class ProRequiredBlur extends React.PureComponent {
  render() {
    const { children, proDialog, style, ...rest } = this.props;
    return (
      <div {...rest} style={_.merge(style, proDialog ? { filter: 'blur(1.5px)' } : undefined)}>
        {children}
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    proDialog: state.userSubscription.proDialog
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(ProRequiredBlur);

export default container;
