import * as api from './api';
import produce from 'immer';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';

/***********************************
 * Action Types
 ***********/
export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS';
export const GET_CONFIGURATION_ERROR = 'GET_CONFIGURATION_ERROR';
export const REST_CONFIGURATION = 'REST_CONFIGURATION';

/***********************************
 * Initial State
 ***********/
const initialState = {
  configuration: {
    pChartCdn: 'https://create.piktochart.com/'
  },
  getConfigurationPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_CONFIGURATION:
        draft.getConfigurationPhase = LOADING;
        break;
      case GET_CONFIGURATION_SUCCESS:
        draft.getConfigurationPhase = SUCCESS;
        draft.configuration = action.payload;
        break;
      case GET_CONFIGURATION_ERROR:
        draft.getConfigurationPhase = ERROR;
        break;
      case REST_CONFIGURATION:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getConfiguration = () => async (dispatch, getState) => {
  dispatch({ type: GET_CONFIGURATION });
  try {
    const configuration = await api.getConfiguration();
    dispatch({ type: GET_CONFIGURATION_SUCCESS, payload: configuration });
  } catch (error) {
    dispatch({ type: GET_CONFIGURATION_ERROR, error });
  }
};
