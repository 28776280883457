import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, Typography } from '@material-ui/core';
// import NumberFormat from 'react-number-format';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import { addThousandSeparators } from '../../../../../../utils/formatNumber';
import { withTheme } from '@material-ui/core/styles';

class FollowUpDescriptionModal extends PureComponent {
  render() {
    const { intl, open, onClose, okBtnOnClick, totalAdjustedTrackingBiz, isTeam } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        fullWidth={true}
        keepMounted
      >
        <DialogTitle id="alert-dialog-title">
          {' '}
          {isTeam
            ? intl.formatMessage({ id: 'Team-Followup-Biz-Modal-Title' })
            : intl.formatMessage({ id: 'Team-Member-Followup-Biz-Modal-Title' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isTeam
              ? intl.formatMessage({ id: 'Team-Followup-Biz-Modal-Desc' })
              : intl.formatMessage({ id: 'Team-Member-Followup-Biz-Modal-Desc' })}
          </DialogContentText>
          <DialogContentText>
            <Button
              className="follow-up-oppo-btn"
              style={{ color: '#3966f8', border: '1.1px solid #3966f8' }}
              variant="outlined"
            >
              {intl.formatMessage({ id: 'Manual-short' })}
            </Button>
            {isTeam
              ? intl.formatMessage({ id: 'Team-Followup-Biz-Modal-Content' })
              : intl.formatMessage({ id: 'Team-Member-Followup-Biz-Modal-Content' })}
          </DialogContentText>

          <br />

          <DialogContentText>{intl.formatMessage({ id: 'Reference' })}:</DialogContentText>
          <DialogContentText>{intl.formatMessage({ id: 'biz-adjustment-reference' })}</DialogContentText>
          <div>
            <Grid container justify="center" alignItems="center" style={{ lineHeight: 2.2 }}>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#5ee092', border: '1.1px solid #5ee092' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'oppH' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 75%
              </Grid>
              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#ffaf19', border: '1.1px solid #ffaf19' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'oppM' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 50%
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
          </div>

          <div>
            <Grid container justify="center" alignItems="center" style={{ lineHeight: 2.2 }}>
              <Grid item xs={1} md={3}></Grid>
              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#ff6a74', border: '1.1px solid #ff6a74' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'oppL' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 25%
              </Grid>

              <Grid item xs={2} md={2}></Grid>

              <Grid item xs={2} md={1}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#cccccc', border: '1.1px solid #cccccc' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'NA' })}
                </Button>
              </Grid>
              <Grid item xs={2} md={1}>
                x 10%
              </Grid>
              <Grid item xs={1} md={3}></Grid>
            </Grid>
          </div>

          <DialogContentText>
            {intl.formatMessage({ id: 'biz-adjustment-reference-figure' })}{' '}
            <span style={{ color: this.props.theme.palette.primary.main, fontSize: '1.2em' }}>
              {addThousandSeparators(totalAdjustedTrackingBiz.toFixed(0))}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={okBtnOnClick} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(withTheme(FollowUpDescriptionModal));
