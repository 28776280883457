import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { formatNumber } from '../../../utils/formatNumber';
import './PolicyCategoryTableProtection.scss';
import { getPolicyValue, getRoomClass, isInsuredNotMePolicy, isPolicySubCategory } from '../../../utils/policy';
import Grid from '@material-ui/core/Grid';
import {
  InsuredNotMeImage,
  PolicyReviewInapplicableField,
  PolicyReviewLackCoverIcon,
  PolicyReviewShavedText
} from '../PolicyReview';
import Typography from '@material-ui/core/Typography';

export const POLICY_REVIEW_PROTECTION_TABLE_DEFAULT_WIDTH = 615;
var TOTAL_COLUMN = 6;

const PolicyCategoryTableProtection = props => {
  const { intl, currency, category, width, extraRows, selectAccident } = props;

  const subCategories = _.get(category, 'subCategories', []);
  const policies = _.flatMap(subCategories, subCategory => subCategory.policies);

  let totalLifeCoverage = 0,
    totalCICoverage = 0;

  const currencyText = intl.formatMessage({ id: currency });

  return category ? (
    <TableContainer className={`policy-category-table protection${policies.length === 0 ? ' no-policy' : ''}`}>
      <Table size="small" style={{ width: width || POLICY_REVIEW_PROTECTION_TABLE_DEFAULT_WIDTH }}>
        <TableHead>
          <TableRow className="sub-header">
            <TableCell align="center" colSpan={TOTAL_COLUMN}>
              <div>{intl.formatMessage({ id: 'pr-table-protection' })}</div>
            </TableCell>
          </TableRow>
          <TableRow className="main-row">
            <TableCell align="center" style={{ width: 100, borderBottomColor: 'transparent' }}>
              <Grid container>
                <Grid item>
                  <div>
                    <Typography color="textSecondary">{intl.formatMessage({ id: 'Life' })}</Typography>
                  </div>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="center" style={{ width: 200, borderBottomColor: 'transparent' }} colSpan={2}>
              <Grid container>
                <Grid item>
                  <div>
                    <Typography color="textSecondary">{intl.formatMessage({ id: 'Critical Illness' })}</Typography>
                  </div>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="center" style={{ width: 200, borderBottomColor: 'transparent' }} colSpan={2}>
              <Grid container>
                <Grid item>
                  <div>
                    <Typography color="textSecondary">{intl.formatMessage({ id: 'Medical' })}</Typography>
                  </div>
                </Grid>
              </Grid>
            </TableCell>

            {selectAccident &&
              <TableCell align="center" style={{ width: 100, borderBottomColor: 'transparent' }}>
                <Grid container>
                  <Grid item>
                    <div>
                      <Typography color="textSecondary">{intl.formatMessage({ id: 'Accident' })}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </TableCell>
            }
            </TableRow>
          <TableRow className="sub-row">
            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{`(${currencyText})`}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{`${intl.formatMessage({
                  id: 'coverage-amount'
                })} (${currencyText})`}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" style={{ width: 100 }}>
              <Grid container>
                <Grid item>
                  <div>
                    <Typography color="textSecondary">{intl.formatMessage({ id: 'multi-claim' })}</Typography>
                  </div>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Room Class' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" style={{ width: 100 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Type' })}</Typography>
              </div>
            </TableCell>
            {selectAccident &&
              <TableCell align="center" style={{ width: 100 }}>
                <div>
                  <Typography color="textSecondary">{`(${currencyText})`}</Typography>
                </div>
              </TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.map((policy, policyIndex) => {
            const isLifePolicy = isPolicySubCategory(policy, 'life');
            const isCIPolicy = isPolicySubCategory(policy, 'ci');
            const isMedicalPolicy = isPolicySubCategory(policy, 'medical');
            const isAccidentPolicy = isPolicySubCategory(policy, 'accident');
            const lifeCoverage = getPolicyValue(policy, 'lifeCoverage', currency);
            const ciCoverage = getPolicyValue(policy, 'sumInsured', currency);
            const multiClaim = _.get(policy, 'multiClaim');
            const roomClass = getRoomClass(policy);
            const medicalCoverageType = isMedicalPolicy ? _.get(policy, 'coverageType') : undefined;
            const isInsuredNotMe = isInsuredNotMePolicy(policy);

            // accidental policies use 'lifeCoverage' to represent accidental death benefit
            if (!isAccidentPolicy && !isInsuredNotMe) {
              totalLifeCoverage += getPolicyValue(policy, 'lifeCoverage', currency);
            }

            if (isCIPolicy && !isInsuredNotMe) {
              totalCICoverage += ciCoverage;
            }

            const lifeCoverageText = lifeCoverage ? formatNumber(lifeCoverage, intl) : '';

            return (
              <TableRow
                key={policy._id || policyIndex}
                className={policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
                style={policyIndex === policies.length - 1 ? { borderBottom: '3px solid #fbcfd0' } : undefined}
              >
                <TableCell align="center">
                  <Grid container alignItems="center">
                    {!isAccidentPolicy ? (
                      <Fragment>
                        <Grid item>
                          {lifeCoverageText ? (
                            <PolicyReviewShavedText>{lifeCoverageText}</PolicyReviewShavedText>
                          ) : isLifePolicy ? (
                            <PolicyReviewLackCoverIcon />
                          ) : null}
                        </Grid>
                        {isInsuredNotMe && <InsuredNotMeImage />}
                      </Fragment>
                    ) : (
                      <PolicyReviewInapplicableField />
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid container alignItems="center">
                    {isCIPolicy ? (
                      <Fragment>
                        {ciCoverage ? (
                          <PolicyReviewShavedText>{formatNumber(ciCoverage, intl)}</PolicyReviewShavedText>
                        ) : (
                          <PolicyReviewLackCoverIcon />
                        )}
                        {isInsuredNotMe && <InsuredNotMeImage />}
                      </Fragment>
                    ) : (
                      <PolicyReviewInapplicableField />
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <div>
                    {isCIPolicy ? (
                      multiClaim ? (
                        <Typography>{intl.formatMessage({ id: 'pr-yes' })}</Typography>
                      ) : (
                        <PolicyReviewLackCoverIcon />
                      )
                    ) : (
                      <PolicyReviewInapplicableField />
                    )}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    {isMedicalPolicy ? (
                      roomClass ? (
                        intl.formatMessage({ id: roomClass })
                      ) : (
                        <PolicyReviewLackCoverIcon />
                      )
                    ) : (
                      <PolicyReviewInapplicableField />
                    )}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    {isMedicalPolicy ? (
                      medicalCoverageType ? (
                        intl.formatMessage({ id: medicalCoverageType })
                      ) : (
                        <PolicyReviewLackCoverIcon />
                      )
                    ) : (
                      <PolicyReviewInapplicableField />
                    )}
                  </div>
                </TableCell>
                {selectAccident &&
                <TableCell align="center">
                  <Grid container>
                    {isAccidentPolicy ? (
                      <Fragment>
                        <Grid item>
                          {lifeCoverageText ? (
                            <PolicyReviewShavedText>{lifeCoverageText}</PolicyReviewShavedText>
                          ) : (
                            <PolicyReviewLackCoverIcon />
                          )}
                        </Grid>
                        {isInsuredNotMe && <InsuredNotMeImage />}
                      </Fragment>
                    ) : (
                      <PolicyReviewInapplicableField />
                    )}
                  </Grid>
                </TableCell>
              }
              </TableRow>
            );
          })}
          {extraRows && extraRows(TOTAL_COLUMN, totalLifeCoverage, totalCICoverage)}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyCategoryTableProtection);
