import { Grid } from '@material-ui/core';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { LOGIN_BANNER_IMG } from '../../../constants/images';

const styles = theme => ({
  imageLogo: {
    maxWidth: '550px',
    width: '100%',
    marginTop: theme.spacing(3)
  },
  mobileimagewrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  paper: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const LoginBannerImage = props => {
  const { classes, ready, src, onLoad } = props;

  return (
    <Grid item xs={12} sm={12} md={false} className={classes.mobileimagewrapper}>
      <div className={classes.paper}>
        <img
          className={classes.imageLogo}
          src={src || LOGIN_BANNER_IMG}
          alt=""
          style={!ready ? { visibility: 'hidden' } : undefined}
          onLoad={onLoad}
        />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(LoginBannerImage);
