import React from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import {
  getFrequencyIntlId,
  getPremiumPeriodValue,
  isPaidUpPolicy,
  isPolicySubCategory,
  isAnnuityPolicy,
  isTaxDeductiblePolicy,
  isPolicyCategory
} from '../../../utils/policy';
import { formatMessage } from '../../../utils/locale';
import { formatNumber } from '../../../utils/formatNumber';
import moment from 'moment-timezone';
import { PolicyReviewNotTaxDeductibleIcon, PolicyReviewShavedText } from '../PolicyReview';
import Typography from '@material-ui/core/Typography';
import './PolicyCategoryTablePremium.scss';
import { getWebImageUrl } from '../../../utils/image';

export const POLICY_REVIEW_PREMIUM_TABLE_DEFAULT_WIDTH = 425;
var TOTAL_COLUMN = 5;

const PolicyCategoryTablePremium = props => {
  const { intl, currency, category, width, extraRows, selectLumpSum, selectCurrencyPerYear } = props;

  const subCategories = _.get(category, 'subCategories', []);
  const policies = _.flatMap(subCategories, subCategory => subCategory.policies);
  let totalYearlyPremium = 0;

  return category ? (
    <TableContainer className={`policy-category-table premium${policies.length === 0 ? ' no-policy' : ''}`}>
      <Table size="small" style={{ width: width || POLICY_REVIEW_PREMIUM_TABLE_DEFAULT_WIDTH }}>
        <TableHead>
          <TableRow className="sub-header">
            <TableCell align="center" colSpan={TOTAL_COLUMN}>
              <div>{intl.formatMessage({ id: 'pr-table-premium' })}</div>
            </TableCell>
          </TableRow>
          <TableRow className="main-row">
            <TableCell align="center" rowSpan={2} style={{ width: 60 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'tax-deductible' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" rowSpan={2} style={{ width: 60 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Currency' })}</Typography>
              </div>
            </TableCell>
            {selectLumpSum &&
              <TableCell align="center" rowSpan={2} style={{ width: 90 }}>
                <div>
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'LumpSum amount' })}</Typography>
                </div>
              </TableCell>
            }
            <TableCell align="center" rowSpan={2} style={{ width: 125 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Amount-mode' })}</Typography>
              </div>
            </TableCell>
            {selectCurrencyPerYear &&
              <TableCell align="center" rowSpan={2} style={{ width: 90 }}>
                <div>
                  <Typography color="textSecondary">{`${intl.formatMessage({ id: currency })} / ${intl.formatMessage({
                    id: 'year'
                  })}`}</Typography>
                </div>
              </TableCell>
            }
          </TableRow>
          <TableRow className="sub-row" />
        </TableHead>
        <TableBody>
          {policies.map((policy, policyIndex) => {
            const isDummy = !!policy.dummy;
            const isDummyExplicitNonTaxDeductible = !!policy.explicitNonTaxDeductible;
            const isTaxDeductibleRelated =
              isPolicySubCategory(policy, 'provident') ||
              isAnnuityPolicy(policy) ||
              (isPolicyCategory(policy, 'personal') && isPolicySubCategory(policy, 'medical'));
            const isTaxDeductible = isTaxDeductiblePolicy(policy);
            const policyCurrency = _.get(policy, 'currency');
            const premium = _.get(policy, 'premium');
            const levelPremium = _.get(policy, 'levelPremium');
            const lumpSumAmount = _.get(policy, 'lumpSumAmount');
            const frequencyIntlId = getFrequencyIntlId(policy);
            const yearlyPremium = getPremiumPeriodValue(policy, 'Yearly', currency);

            const isPaidUp = isPaidUpPolicy(policy);
            if (!isPaidUp) {
              totalYearlyPremium += yearlyPremium;
            }

            return (
              <TableRow
                key={policy._id}
                className={policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
                style={policyIndex === policies.length - 1 ? { borderBottom: '3px solid #c8ebfa' } : undefined}
              >
                <TableCell align="center">
                  <div>
                    {!isDummy ? (
                      isTaxDeductible ? (
                        <Typography>{intl.formatMessage({ id: 'pr-yes' })}</Typography>
                      ) : (
                        isTaxDeductibleRelated && <PolicyReviewNotTaxDeductibleIcon />
                      )
                    ) : (
                      isDummyExplicitNonTaxDeductible && <PolicyReviewNotTaxDeductibleIcon />
                    )}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>{formatMessage(intl, policyCurrency)}</div>
                </TableCell>
                {selectLumpSum &&
                  <TableCell align="center">
                    <div>{lumpSumAmount ? formatNumber(lumpSumAmount, intl) : ''}</div>
                  </TableCell>
                }
                <TableCell align="center">
                  <div style={isPaidUp ? { textDecoration: 'line-through' } : undefined}>
                    <PolicyReviewShavedText>
                      {premium
                        ? `${formatNumber(premium, intl)} / ${formatMessage(intl, frequencyIntlId).replace(
                            /Per|每/gi,
                            ''
                          )}`
                        : ''}
                    </PolicyReviewShavedText>
                    {levelPremium === 'No' && (
                      <img alt="" className="not_level_icon_small" src={getWebImageUrl('img/not-level.png')} />
                    )}
                  </div>
                </TableCell>
                {selectCurrencyPerYear &&
                  <TableCell align="center">
                    <div style={isPaidUp ? { textDecoration: 'line-through' } : undefined}>
                      <PolicyReviewShavedText>
                        {yearlyPremium ? formatNumber(yearlyPremium, intl) : ''}
                      </PolicyReviewShavedText>
                    </div>
                  </TableCell>
                }
              </TableRow>
            );
          })}
          {extraRows && extraRows(TOTAL_COLUMN, totalYearlyPremium)}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyCategoryTablePremium);
