import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import PreferenceHelpDialog from '../../../../components/PreferenceHelpDialog/PreferenceHelpDialog';
import '../styles.scss';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { tracking } from '../../../../utils/tracking';

class ExistingPolicyView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showHelpPopUp: false
    };
  }

  componentDidMount() {
    this.props.renderHeaderButton(true);
  }

  requestAdviser() {
    let { userDetails } = this.props;
    let flag = _.filter(userDetails && userDetails.advisorDetails, function(o) {
      return o.connection && o.connectionVerified;
    });
    if (flag.length > 0) {
      this.props.history.push({
        state: {
          analysisPage: true,
          prevLocation: this.props.location.pathname
        },
        pathname: '/message'
      });
    } else {
      this.props.history.push('/share_options?connectWithAdviserDialog=true');
    }
  }

  addPolicyWizard = () => {
    tracking('Add Existing Policy: Add Policy Wizard');
    this.setState({ showHelpPopUp: true });
  };

  render() {
    const { intl } = this.props;
    let productionEnv = process.env.NODE_ENV === 'production' ? true : false;
    return (
      <div>
        <div id="wrapper" style={{ backgroundColor: '#ffffff', marginTop: 20, overflow: 'hidden' }}>
          <div className="row">
            <div className="col-lg-12">
              <div onClick={this.addPolicyWizard}>
              <AladdinHint 
                  title={intl.formatMessage({ id: 'add-policy-help' })}
               />
              </div>
              <ul className="card-list">
                <li onClick={this.requestAdviser.bind(this)}>
                  <div className="portfolioCard1">
                    <img src="img/add_requestAdviser.png" alt="" />
                    <h4>{intl.formatMessage({ id: 'Invite Adviser' })}</h4>
                  </div>
                </li>
                <li>
                  <Link to="/add_portfolio">
                    <div className="portfolioCard1">
                      <img src="img/add_manual.png" alt="" />
                      <h4>{intl.formatMessage({ id: 'Manual Input' })}</h4>
                    </div>
                  </Link>
                </li>
                {!productionEnv && (
                  <li>
                    <div className="portfolioCard1">
                      <img src="img/takePicture.png" alt="" />
                      <input type="file" id="menuFile" style={{ display: 'none' }} />
                      <h4>{intl.formatMessage({ id: 'Take Picture Of Policy' })}</h4>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <PreferenceHelpDialog
          open={this.state.showHelpPopUp}
          history={this.props.history}
          fromPortfolio={true}
          intl={intl}
          onClose={() => this.setState({ showHelpPopUp: false })}
        />
      </div>
    );
  }
}

export default ExistingPolicyView;
