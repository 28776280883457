import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, IconButton, Typography } from '@material-ui/core';
import Pyramid from './Pyramid/Pyramid';
import { PYRAMID_COLORS } from '../constants';
import ColoredCurrency from '../ColoredCurrency/ColoredCurrency';
import ColoredRatio from '../ColoredRatio/ColoredRatio';
import GaugeChart from './GaugeChart/GaugeChart';
import * as echarts from 'echarts';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

const HeadingTypoStyle = {
  fontSize: '180%',
  color: 'var(--new-theme)'
};

const FullHeightStyle = {
  height: '100%'
};

const LeftRegionItemStyle = {
  // flexBasis: '24%',
  height: 68,
  textAlign: 'center'
};

const ArrowIconStyle = {
  background: '#3966f8',
  color: '#fff',
  borderRadius: '50%',
  fontSize: 32
};

const AssetItem = props => {
  const { warn, percentage, currency, value, color, fontSize } = props;

  return (
    <Grid container direction="column" justify="flex-start" spacing={0} wrap="nowrap">
      <Grid item style={{ minWidth: 130 }}>
        <ColoredRatio warn={warn} value={percentage} color={color} />
      </Grid>
      <Grid item style={{ flexGrow: 1, minWidth: 130 }}>
        <ColoredCurrency currency={currency} value={value} fontSize={fontSize} />
      </Grid>
    </Grid>
  );
};

const Column = props => {
  const { items } = props;
  return (
    <Grid container direction="column" justify="center" wrap="nowrap" style={FullHeightStyle}>
      {(items || []).map((item, index) => (
        <Grid key={index} item style={LeftRegionItemStyle}>
          <Grid
            container
            alignItems="flex-end"
            justify="center"
            style={{ ...FullHeightStyle, minWidth: 130, padding: 8 }}
          >
            <Grid item>{item}</Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const PyramidSection = props => {
  const {
    intl,
    currency,
    pyramidData,
    monthlyIncome,
    template,
    showIncome,
    showRecord,
    onChangeShowIncome,
    onChangeShowRecord
  } = props;

  const isFirstWarning = pyramidData.risk.asset > pyramidData.investment.asset;
  const isSecondWarning = pyramidData.investment.asset > pyramidData.saving.asset;

  return (
    <Grid container direction="column" justify="center">
      <Grid item style={{ marginTop: 50, marginBottom: 20, whiteSpace: 'nowrap' }}>
        <Grid container>
          <Grid item style={{ width: 'calc((100% - 250px)/2)' }}>
            <Grid container direction="column" alignItems="center" justify="center" spacing={1}>
              {!template && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={onChangeShowIncome}
                    style={{
                      transition: 'transform 0.8s',
                      transform: `rotate(${!showIncome ? -90 : 90}deg)`,
                      padding: 0,
                      boxShadow:
                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                    }}
                  >
                    <ArrowDropDownRoundedIcon style={ArrowIconStyle} />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography color="primary" align="center" style={HeadingTypoStyle}>
                  {intl.formatMessage({ id: 'asset' })}
                </Typography>
                <Typography color="textSecondary" align="center">
                  ({intl.formatMessage({ id: currency })})
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: 250 }} />
          <Grid item style={{ width: 'calc((100% - 250px)/2)' }}>
            <Grid container direction="column" alignItems="center" justify="center" spacing={1}>
              {!template && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={onChangeShowRecord}
                    style={{
                      transition: 'transform 0.8s',
                      transform: `rotate(${!showRecord ? 90 : -90}deg)`,
                      padding: 0,
                      boxShadow:
                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                    }}
                  >
                    <ArrowDropDownRoundedIcon style={ArrowIconStyle} />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography color="primary" align="center" style={HeadingTypoStyle}>
                  {intl.formatMessage({ id: 'monthly-payment' })}
                </Typography>
                <Typography color="textSecondary" align="center">
                  ({intl.formatMessage({ id: currency })})
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ height: 300 }}>
        <Grid container alignItems="flex-start" style={FullHeightStyle}>
          <Grid
            item
            style={{ ...FullHeightStyle, width: 'calc((100% - 250px)/2)', cursor: 'pointer' }}
            onClick={onChangeShowIncome}
          >
            <Column
              items={[
                <AssetItem
                  warn={isFirstWarning}
                  currency={currency}
                  value={pyramidData.risk.asset}
                  percentage={pyramidData.risk.assetPercentage}
                  color={PYRAMID_COLORS[0]}
                />,
                <AssetItem
                  warn={isFirstWarning || isSecondWarning}
                  currency={currency}
                  value={pyramidData.investment.asset}
                  percentage={pyramidData.investment.assetPercentage}
                  color={PYRAMID_COLORS[1]}
                />,
                <AssetItem
                  warn={isSecondWarning}
                  currency={currency}
                  value={pyramidData.saving.asset}
                  percentage={pyramidData.saving.assetPercentage}
                  color={PYRAMID_COLORS[2]}
                />,
                <Grid container direction="column" justify="center" style={{ minWidth: 130, marginTop: 19 }}>
                  <Grid item>
                    <Typography color="textSecondary" align="left">
                      {`${intl.formatMessage({ id: 'Coverage Amount' })}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ColoredCurrency
                      currency={currency}
                      value={pyramidData.protection.asset}
                      color={PYRAMID_COLORS[3]}
                      fontSize={'150%'}
                    />
                  </Grid>
                </Grid>
              ]}
            />
          </Grid>
          <Grid item style={{ ...FullHeightStyle, width: 250 }}>
            <Pyramid />
          </Grid>
          <Grid
            item
            style={{ ...FullHeightStyle, width: 'calc((100% - 250px)/2)', cursor: 'pointer' }}
            onClick={onChangeShowRecord}
          >
            <Column
              items={[
                // <AssetItem
                // 	currency={currency}
                // 	value={pyramidData.risk.monthlyPayment}
                // 	percentage={pyramidData.risk.monthlyPaymentPercentage}
                // 	color={PYRAMID_COLORS[0]}
                // 	fontSize={'110%'}
                // />,
                // <AssetItem
                // 	currency={currency}
                // 	value={pyramidData.investment.monthlyPayment}
                // 	percentage={pyramidData.investment.monthlyPaymentPercentage}
                // 	color={PYRAMID_COLORS[1]}
                // 	fontSize={'110%'}
                // />,
                // <AssetItem
                // 	currency={currency}
                // 	value={pyramidData.saving.monthlyPayment}
                // 	percentage={pyramidData.saving.monthlyPaymentPercentage}
                // 	color={PYRAMID_COLORS[2]}
                // 	fontSize={'110%'}
                // />,
                // <AssetItem
                // 	currency={currency}
                // 	value={pyramidData.protection.monthlyPayment}
                // 	percentage={pyramidData.protection.monthlyPaymentPercentage}
                // 	color={PYRAMID_COLORS[3]}
                // 	fontSize={'110%'}
                // />,

                <ColoredCurrency
                  currency={currency}
                  value={pyramidData.risk.monthlyPayment}
                  color={PYRAMID_COLORS[0]}
                  fontSize={'150%'}
                />,
                <ColoredCurrency
                  currency={currency}
                  value={pyramidData.investment.monthlyPayment}
                  color={PYRAMID_COLORS[1]}
                  fontSize={'150%'}
                />,
                <ColoredCurrency
                  currency={currency}
                  value={pyramidData.saving.monthlyPayment}
                  color={PYRAMID_COLORS[2]}
                  fontSize={'150%'}
                />,
                <Grid container direction="column" justify="center" style={{ width: 'auto', marginTop: 19 }}>
                  <Grid item>
                    <Typography color="textSecondary" align="left">
                      {`${intl.formatMessage({ id: 'Premium' })}:`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ColoredCurrency
                      currency={currency}
                      value={pyramidData.protection.monthlyPayment}
                      color={PYRAMID_COLORS[3]}
                      fontSize="150%"
                    />
                  </Grid>
                </Grid>
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {showRecord && (
        <Grid item style={{ marginTop: 85, marginBottom: !template ? -36 : 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <GaugeChart
                className="saving-income-percent-chart"
                width={template ? 180 : '50%'}
                label={intl.formatMessage({ id: 'fin-py-saving-income-per' })}
                value={pyramidData.saving.monthlyPayment}
                max={(monthlyIncome || 0) * 0.6}
                percentage={monthlyIncome ? (pyramidData.saving.monthlyPayment / monthlyIncome) * 100 : 0}
                maxPercentage={60}
                insufficientPercentage={20}
                colors={[
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: '#ff0000'
                      },
                      {
                        offset: 0.3,
                        color: '#ffa600'
                      },
                      {
                        offset: 0.7,
                        color: '#3eda7d'
                      }
                    ])
                  ]
                ]}
                axisLabelFormatter={v => {
                  switch (v) {
                    case 0:
                      return 0;
                    case 30:
                      return 20;
                    case 70:
                      return 40;
                    case 100:
                      return 60;
                    default:
                      return '';
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <GaugeChart
                className="premium-income-percent-chart"
                width={template ? 180 : '50%'}
                label={intl.formatMessage({ id: 'fin-py-premium-income-per' })}
                value={pyramidData.protection.monthlyPayment}
                max={(monthlyIncome || 0) * 0.3}
                percentage={monthlyIncome ? (pyramidData.protection.monthlyPayment / monthlyIncome) * 100 : 0}
                maxPercentage={30}
                insufficientPercentage={10}
                colors={[
                  [
                    1,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: '#ff0000'
                      },
                      {
                        offset: 0.16667,
                        color: '#ffa600'
                      },
                      {
                        offset: 0.5,
                        color: '#3eda7d'
                      }
                    ])
                  ]
                ]}
                axisLabelFormatter={v => {
                  switch (v) {
                    case 0:
                      return 0;
                    case 30:
                      return 10;
                    case 70:
                      return 20;
                    case 100:
                      return 30;
                    default:
                      return '';
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {/*<Grid item>*/}
      {/*    <Grid container alignItems="center" spacing={2}>*/}
      {/*        <Grid item xs={3}>*/}
      {/*            <Typography color="textSecondary" align="center">*/}
      {/*                {intl.formatMessage({ id: 'fin-py-premium-income-per' })}*/}
      {/*            </Typography>*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={6}>*/}
      {/*            <div style={{ height: 30 }}>*/}
      {/*                <MonthlyPaymentProgressBar*/}
      {/*                    monthlyPayment={pyramidData.protection.monthlyPayment}*/}
      {/*                    monthlyIncome={monthlyIncome}*/}
      {/*                />*/}
      {/*            </div>*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={3}>*/}
      {/*            <Typography color="primary" align="center">*/}
      {/*                {formatPremiumIncomePercentageDisplay(pyramidData.protection.premiumIncomePercentage)}*/}
      {/*            </Typography>*/}
      {/*        </Grid>*/}
      {/*    </Grid>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default injectIntl(PyramidSection);
