import React, { useState, useCallback, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { updateAdviserCustomFilters } from '../../../../../store/adviserCustomFilters/duck';
import { toast } from 'react-toastify';
import IconToast from '../../../../../components/NewToast';
import { defaultFilters } from '../../../../../constants/adviserCustomFilters';
import _ from 'lodash';
import LoadingBackdrop from '../../../../../components/LoadingBackdrop/LoadingBackdrop';

const CustomFilterModal = ({ open, onClose, intl }) => {
  const state = useSelector(s => s.adviserCustomFilters);
  const [localSubmit, setLocalSubmit] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.submitting === false && state.error && localSubmit) {
      setLocalSubmit(false);
      toast.info(IconToast('error', intl.formatMessage({ id: 'todo-err-msg' })), {
        className: 'new-toast'
      });
    } else if (state.submitting === false && state.error === false && localSubmit) {
      setLocalSubmit(false);
      onClose();
      toast.info(IconToast('info', intl.formatMessage({ id: 'Updated successfully' })), {
        className: 'new-toast'
      });
    }
  }, [state, open, localSubmit]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={SlideTransition}
    >
      {state.submitting && <LoadingBackdrop open={true} />}
      <DialogTitle>{intl.formatMessage({ id: 'Confirm' })}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>
          {intl.formatMessage({ id: 'custom-filter-delete' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          autoFocus
          onClick={() => {
            onClose();
          }}
          color="primary"
          autoFocus
          disabled={localSubmit}
        >
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button
          onClick={() => {
            setLocalSubmit(true);
            dispatch(updateAdviserCustomFilters({ customFilters: defaultFilters }));
          }}
          color="primary"
          autoFocus
          disabled={localSubmit}
        >
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomFilterModal;
