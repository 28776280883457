import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const DirectMarketingCheckbox = props => {
  const { intl, checked, error, onChange } = props;

  return (
    <FormControlLabel
      className="checkbox"
      control={
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
          color="primary"
          name="dmChecked"
          checked={checked}
          onChange={onChange}
        />
      }
      label={
        <Fragment>
          <Typography variant="overline" color="textSecondary">{`${intl.formatMessage({
            id: 'register-dm-check'
          })} `}</Typography>
          {error ? <Typography color="error">{error}</Typography> : null}
        </Fragment>
      }
    />
  );
};

export default injectIntl(DirectMarketingCheckbox);
