import React, { Fragment, useState, useEffect } from 'react';
import { Container, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid } from '@material-ui/core';
import './PremiumFinancingAnalysis.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import AccessCodeGate from '../../../../components/AccessCodeGate/AccessCodeGate';
import { selectedLocale } from '../../../../store/locale/action';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';
import AppContentContainer, {
  APP_CONTENT_CONTAINER_MAX_WIDTH
} from '../../../../components/AppContentContainer/AppContentContainer';
import PremiumFinancing from './PremiumFinancing';
import { getPDF } from '../../../../store/pdf/api';
import NativeOrWeb from '../../../../utils/native';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import PDFExportCard from './PDFExportCard/PDFExportCard';
import PreviewWrapper from '../../../../pdf-templates/PreviewWrapper';
import FinancialChartsButton from '../../../Analysis/partials/FinancialChartsButton/FinancialChartsButton';
import { FINANCIAL_CHARTS_HIBOR_ITEM } from '../../../../constants/financial-charts';
import {
  getPFinCalculatorProfiles,
  savePFinCalculatorProfiles,
  updatePFinCalculatorInputs,
  updatePFinCalculatorProfiles
} from '../../../../store/pFinCalculator/duck';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import { LOADING, ERROR, SUCCESS } from '../../../../constants/phase';
import IconToast from '../../../../components/NewToast';
import ConfirmSaveDialog from '../../../Analysis/FinancialPyramid/ConfirmSaveDialog/ConfirmSaveDialog';
import LeavePageConfirm from '../../../../components/LeavePageConfirm/LeavePageConfirm';
import ExportHintDialog from '../../../../components/ExportHintDialog/ExportHintDialog';
import SideLoanInterestInput from './SideLoanInterestInput/SideLoanInterestInput';
import LoanCalculatorDialog from './LoanCalculatorDialog/LoanCalculatorDialog';
import {
  getDefaultPremiumFinancingProfile,
  getPremiumFinancingProfileDisplay
} from '../../../../utils/premium-financing';
import produce from 'immer';
import { usePrevious } from '../../../../utils/hooks';
import BonusCalculatorDialog from './BonusCalculatorDialog/BonusCalculatorDialog';
import { countDecimals, roundNumber } from '../../../../utils/formatNumber';
import EditDisplayNamesDialog from '../../../../components/EditDisplayNamesDialog/EditDisplayNamesDialog';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';

const roundNumberToOneDec = num => Math.round(num * 10) / 10;
const getOverrideLoanInterestSliderValue = (profiles, index, defaultProfile) =>
  _.get(profiles, `[${index}].bankLoanInterest`, defaultProfile.bankLoanInterest);
const getDynamicLoanInterestExpanded = (profiles, index, defaultProfile) =>
  Array.from(
    Array(
      _.get(profiles, `[${index}].yearsToShow`, defaultProfile.yearsToShow)[
        _.get(profiles, `[${index}].yearsToShow`, defaultProfile.yearsToShow).length - 1
      ]
    ).keys()
  ).map(() => false);
const getNewTermLoanPercentage = ({ termLoanPercentage, yearsToShow, bankLoanInterest }) => {
  if (termLoanPercentage && Array.isArray(termLoanPercentage)) {
    const defaultBankLoanInterest = termLoanPercentage[termLoanPercentage.length - 1];
    return Array.from(Array(yearsToShow[yearsToShow.length - 1]).keys()).map((v, index) => {
      return index < termLoanPercentage.length
        ? termLoanPercentage[index] ?? defaultBankLoanInterest
        : defaultBankLoanInterest;
    });
  } else {
    return Array.from(Array(yearsToShow[yearsToShow.length - 1]).keys()).map(() => bankLoanInterest);
  }
};
const getNewTermAddOn = ({ termAddOn, yearsToShow }) => {
  if (termAddOn) {
    return Array.from(Array(yearsToShow[yearsToShow.length - 1]).keys()).map((v, index) => {
      return index < termAddOn.length ? termAddOn[index] ?? 0 : 0;
    });
  } else {
    return Array.from(Array(yearsToShow[yearsToShow.length - 1]).keys()).map(() => 0);
  }
};

const PremiumFinancingAnalysis = props => {
  const {
    intl,
    safariGenerate,
    trial,
    userDetails,
    loading,
    pFinProfiles,
    getProfilesLoadState,
    updateProfilesLoadState,
    updatePFinCalculatorProfiles,
    savePFinCalculatorProfiles,
    updatePFinCalculatorInputs,
    selectedLocale,
    renderHeaderButton,
    getPFinCalculatorProfiles
  } = props;

  // constructor(props) {
  //   super(props);
  //   let state = {
  //     language: _.get(props.userDetails, 'language', 'en'),
  //     profileIndex: 0,
  //     confirmSaveDialog: false,
  //     updated: false,
  //     loaded: false,
  //     graphMode: 'pfa-return-combo-title',
  //     hintDialog: false,
  //     rowOpen: [false, false, false, false],
  //     fixedLoanInterestExpanded: false,
  //     loanCalculatorDialog: false
  //   };
  //   state = { ...state, ...props.pFinProfiles[0] };
  //   state.overrideLoanInterestSliderValue = state.bankLoanInterest;
  //   state.dynamicLoanInterestExpanded = Array.from(Array(state.yearsToShow[state.yearsToShow.length - 1]).keys()).map(
  //       () => false
  //   );
  //
  //   this.state = _.merge(state, props.location.state);
  // }
  const defaultProfile = getDefaultPremiumFinancingProfile();
  const defaultProfileIndex = 0;

  const [internalProfiles, setInternalProfiles] = useState(_.cloneDeep(props.pFinProfiles));
  const [language, setLanguage] = useState(_.get(userDetails, 'language', 'en'));
  const [profileIndex, setProfileIndex] = useState(defaultProfileIndex);
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [graphMode, setGraphMode] = useState('pfa-return-combo-title');
  const [hintDialog, setHintDialog] = useState(false);
  const [rowOpen, setRowOpen] = useState([false, false, false, false]);
  const [fixedLoanInterestExpanded, setFixedLoanInterestExpanded] = useState(false);
  const [loanCalculatorDialog, setLoanCalculatorDialog] = useState(false);
  const [bonusCalculatorDialog, setBonusCalculatorDialog] = useState(false);
  const [overrideLoanInterestSliderValue, setOverrideLoanInterestSliderValue] = useState(
    getOverrideLoanInterestSliderValue(pFinProfiles, defaultProfileIndex, defaultProfile)
  );
  const [dynamicLoanInterestExpanded, setDynamicLoanInterestExpanded] = useState(
    getDynamicLoanInterestExpanded(pFinProfiles, defaultProfileIndex, defaultProfile)
  );
  const [disableLoan, setDisableLoan] = useState(false);
  const [isEditDisplayNamesDialogOpen, setIsEditDisplayNamesDialogOpen] = useState(false);

  const prevGetProfilesLoadState = usePrevious(getProfilesLoadState);
  const prevUpdateProfilesLoadState = usePrevious(updateProfilesLoadState);

  const isGuest = userDetails._id === undefined;
  const currentProfile = _.get(internalProfiles, `[${profileIndex}]`, defaultProfile);
  const {
    principal,
    base,
    loanPercentage,
    bankLoanInterest,
    policyInterest,
    yearsToShow,
    cashValueAt,
    firstYearBonus,
    termLoanPercentage,
    termAddOn,
    overrideLoanInterest,
    day0SurrenderValue,
    bankLoanPercentage,
    shouldPayPrincipal,
    isCurrencyEnabled,
    currency
  } = currentProfile || {};

  const handleChange = event => {
    const newInternalProfiles = produce(internalProfiles, draft => {
      const { name, value } = event.target;

      // avoid overwriting a more precise loanPercentage value
      if (name === 'loanPercentage') {
        if (countDecimals(loanPercentage) > 1 && roundNumber(loanPercentage, 1) === value) {
          return;
        }
      }

      if (name !== 'disableLoan') {
        draft[profileIndex][name] = value;
      }

      if (!isCurrencyEnabled) {
        const finalLoanPercentage = disableLoan ? 0 : loanPercentage;

        if (name === 'yearsToShow') {
          const newMax = Math.max(...value);
          if (newMax !== Math.max(...(yearsToShow ?? []))) {
            if (overrideLoanInterest) {
              draft[profileIndex].termLoanPercentage = getNewTermLoanPercentage({
                termLoanPercentage,
                yearsToShow: value,
                bankLoanInterest
              });
              draft[profileIndex].termAddOn = getNewTermAddOn({ termAddOn, yearsToShow: value });
            }
          }
        } else if (name === 'principal') {
          const principal = value || 0;
          if (!principal) {
            draft[profileIndex].loanPercentage = 0;
            draft[profileIndex].base = 0;
            draft[profileIndex].firstYearBonus = 0;
          } else {
            if (finalLoanPercentage || finalLoanPercentage === 0) {
              draft[profileIndex].base = principal * (1 - finalLoanPercentage / 100);
              draft[profileIndex].firstYearBonus = 0;
            } else {
              draft[profileIndex].base = principal;
              draft[profileIndex].firstYearBonus = 0;
            }
          }
        } else if (name === 'base') {
          const newBase = value || 0;
          const percentage =
            (principal &&
              (newBase || firstYearBonus || newBase === 0 || firstYearBonus === 0) &&
              (principal - (firstYearBonus ?? 0) - (newBase ?? 0)) / principal) ??
            0;

          if ((percentage || percentage === 0) && base !== newBase) {
            draft[profileIndex].loanPercentage = percentage * 100;
            setDisableLoan(false);
          }
        } else if (name === 'firstYearBonus') {
          const newFirstYearBonus = value || 0;
          if ((base || base === 0) && principal && firstYearBonus !== newFirstYearBonus) {
            draft[profileIndex].base = principal * (1 - (finalLoanPercentage ?? 0) / 100) - (newFirstYearBonus ?? 0);
          }
        } else if (name === 'loanPercentage') {
          const newLoanPercentage = value || 0;
          if (newLoanPercentage !== loanPercentage) {
            const base =
              ((newLoanPercentage || newLoanPercentage === 0) &&
                principal &&
                principal * (1 - newLoanPercentage / 100)) ??
              0;
            if (base || base === 0) {
              draft[profileIndex].base = Math.round(base);
              draft[profileIndex].firstYearBonus = 0;
            }
            setDisableLoan(false);
          }
        } else if (name === 'bankLoanInterest') {
          if (new Set(termLoanPercentage).size === 1) {
            draft[profileIndex].termLoanPercentage = Array.from(Array(yearsToShow[yearsToShow.length - 1]).keys()).map(
              () => value
            );
            draft[profileIndex].overrideLoanInterestSliderValue = value;
          }
          setOverrideLoanInterestSliderValue(value);
        } else if (name === 'overrideLoanInterestSliderValue') {
          draft[profileIndex].termLoanPercentage = termLoanPercentage.map(() => value);
          draft[profileIndex].bankLoanInterest = value;
          setOverrideLoanInterestSliderValue(value);
        } else if (name === 'disableLoan') {
          if (value === true) {
            const base = (principal ?? 0) - (firstYearBonus ?? 0);
            if (base || base === 0) {
              draft[profileIndex].base = Math.round(base);
            }
          } else {
            const base = (principal ?? 0) * (1 - loanPercentage / 100) - (firstYearBonus ?? 0);
            if (base >= 0) {
              draft[profileIndex].base = Math.round(base);
            } else {
              draft[profileIndex].base = (principal ?? 0) * (1 - loanPercentage / 100);
              draft[profileIndex].firstYearBonus = 0;
            }
          }
        } else if (name === 'currency') {
          draft[profileIndex].currency = value;
        }
      }
    });
    setUpdated(true);
    setInternalProfiles(newInternalProfiles);
    // updatePFinCalculatorProfiles(profileIndex, newInternalProfiles[profileIndex]);
  };

  const handleChangeLanguage = event => setLanguage(event.target.value);

  const handleCheckedChange = event => {
    const { name, checked } = event.target;

    const newInternalProfiles = produce(internalProfiles, draft => {
      if (name === 'overrideLoanInterest') {
        draft[profileIndex].overrideLoanInterest = !checked;

        if (!checked) {
          draft[profileIndex].termLoanPercentage = getNewTermLoanPercentage({
            termLoanPercentage,
            yearsToShow,
            bankLoanInterest
          });
          draft[profileIndex].termAddOn = getNewTermAddOn({ termAddOn, yearsToShow });
        }
      } else if (name === 'isCurrencyEnabled') {
        draft[profileIndex].isCurrencyEnabled = checked;
      }
    });

    setInternalProfiles(newInternalProfiles);
    setUpdated(true);

    // updatePFinCalculatorProfiles(profileIndex, newInternalProfiles[profileIndex]);
  };

  const handleChangeProfileNames = displayNames => {
    // save new profiles
    const newSaveProfiles = produce(pFinProfiles, draft => {
      for (const [index, displayName] of (displayNames || []).entries()) {
        if (index <= draft.length - 1) {
          draft[index].displayName = displayName;
        }
      }
    });

    if (savePFinCalculatorProfiles) {
      savePFinCalculatorProfiles({ profiles: newSaveProfiles });
    }

    // update internal profiles
    const newInternalProfiles = produce(internalProfiles, draft => {
      for (const [index, displayName] of (displayNames || []).entries()) {
        if (index <= draft.length - 1) {
          draft[index].displayName = displayName;
        }
      }
    });

    setInternalProfiles(newInternalProfiles);
  };

  const handleToggleDisableLoan = flag => {
    const value = flag !== undefined ? flag : !disableLoan;
    setDisableLoan(value);
    if (value) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'pfa-without-loan-toast' })), { className: 'new-toast' });
    }
    handleChange({ target: { name: 'disableLoan', value: value } });
  };

  const handleProfileChange = event => {
    const index = event.target.value;
    if (!index && index !== 0) {
      return;
    }

    if (disableLoan) {
      handleToggleDisableLoan(false);
    }

    setProfileIndex(index);

    // if (this.props.pFinProfiles) {
    // 	setObj = { ...this.props.pFinProfiles[index], ...setObj };
    // }
  };

  const saveProfiles = () => {
    setUpdated(true);
    savePFinCalculatorProfiles({ profiles: internalProfiles });
  };

  useEffect(() => {
    if (_.isEmpty(userDetails)) {
      selectedLocale('zh-Hant-HK');
    } else {
      selectedLocale(_.get(userDetails, 'language', ''));
    }
  }, [userDetails, selectedLocale]);

  useEffect(() => {
    renderHeaderButton(!!safariGenerate);
  }, [safariGenerate, renderHeaderButton]);

  useEffect(() => {
    getPFinCalculatorProfiles();
  }, [getPFinCalculatorProfiles]);

  useEffect(() => {
    if (prevGetProfilesLoadState !== getProfilesLoadState) {
      if (getProfilesLoadState === SUCCESS) {
        const newProfiles = _.cloneDeep(pFinProfiles);
        setInternalProfiles(newProfiles);
        setLoaded(true);
        setOverrideLoanInterestSliderValue(
          getOverrideLoanInterestSliderValue(newProfiles, profileIndex, defaultProfile)
        );
        setDynamicLoanInterestExpanded(getDynamicLoanInterestExpanded(newProfiles, profileIndex, defaultProfile));
      }
    }
  }, [getProfilesLoadState, prevGetProfilesLoadState, pFinProfiles, profileIndex, defaultProfile]);

  useEffect(() => {
    if (prevGetProfilesLoadState !== getProfilesLoadState) {
      if (getProfilesLoadState === ERROR) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'pfa-fetch-err-msg' })), {
          className: 'new-toast'
        });
      }
    }
  }, [getProfilesLoadState, prevGetProfilesLoadState, intl]);

  useEffect(() => {
    if (updateProfilesLoadState === ERROR && prevUpdateProfilesLoadState === LOADING) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'todo-err-msg' })), { className: 'new-toast' });
    }
  }, [updateProfilesLoadState, prevUpdateProfilesLoadState, intl]);

  useEffect(() => {
    if (updateProfilesLoadState === SUCCESS && prevUpdateProfilesLoadState === LOADING) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'file-saved-title' })), {
        className: 'new-toast'
      });
      setUpdated(false);
    }
  }, [updateProfilesLoadState, prevUpdateProfilesLoadState, intl]);

  const generate = () => {
    setHintDialog(true);
  };

  const onConfirmGenerate = async ({ password, isPrivateMode }) => {
    if (!loading) {
      try {
        updatePFinCalculatorInputs('loading', true);

        const options = {
          ...currentProfile,
          pFinProfiles,
          language,
          profileIndex,
          confirmSaveDialog,
          updated,
          loaded,
          graphMode,
          rowOpen,
          fixedLoanInterestExpanded,
          overrideLoanInterestSliderValue,
          dynamicLoanInterestExpanded,
          disableLoan,
          adviser: userDetails,
          outputFormat: 'pdf',
          password,
          isPrivateMode
        };

        // console.log(JSON.stringify(options));

        const pdfBlob = await getPDF('premium-financing', options);

        await NativeOrWeb.downloadFile(
          pdfBlob,
          `Premium_Financing_${moment().format('DDMMMYYYY')}.pdf`,
          'application/pdf'
        );
      } catch (error) {
        toast.error(error.message);
      } finally {
        updatePFinCalculatorInputs('loading', false);
      }
    }
  };

  const openConfirmDialog = () => setConfirmSaveDialog(true);
  const closeConfirmDialog = () => setConfirmSaveDialog(false);

  const onChangeGraphMode = mode => setGraphMode(mode);
  const closeHintDialog = () => setHintDialog(false);

  const onToggleOpen = index => {
    const newRowOpen = _.set([...rowOpen], `[${index}]`, !_.get(rowOpen, `[${index}]`));
    setRowOpen(newRowOpen);
  };
  const onToggleFixedLoanInterestExpanded = () => {
    setFixedLoanInterestExpanded(!fixedLoanInterestExpanded);
  };
  const onToggleDynamicLoanInterestExpanded = index => {
    const newDynamicLoanInterestExpanded = _.set(
      [...dynamicLoanInterestExpanded],
      `[${index}]`,
      !_.get(dynamicLoanInterestExpanded, `[${index}]`)
    );
    setDynamicLoanInterestExpanded(newDynamicLoanInterestExpanded);
  };

  const openLoanCalculatorDialog = () => setLoanCalculatorDialog(true);
  const closeLoanCalculatorDialog = () => setLoanCalculatorDialog(false);
  const onSubmitLoanCalculator = loan => {
    if (principal) {
      const calculatedLoanPercentage = ((loan || 0) / principal) * 100;
      if (calculatedLoanPercentage >= 0 && calculatedLoanPercentage <= 100) {
        handleChange({ target: { name: 'loanPercentage', value: calculatedLoanPercentage } });
      }
    }
  };

  const openBonusCalculatorDialog = () => setBonusCalculatorDialog(true);
  const closeBonusCalculatorDialog = () => setBonusCalculatorDialog(false);
  const onSubmitBonusCalculator = bonus => {
    handleChange({ target: { name: 'firstYearBonus', value: bonus } });
  };
  const openEditDisplayNamesDialog = () => setIsEditDisplayNamesDialogOpen(true);
  const closeEditDisplayNamesDialog = () => setIsEditDisplayNamesDialogOpen(false);
  const onConfirmDisplayNames = displayNames => {
    handleChangeProfileNames(displayNames);
    setIsEditDisplayNamesDialogOpen(false);
  };

  var defaultItem = { ...FINANCIAL_CHARTS_HIBOR_ITEM };
  let contents = (
    <div className="pfa-container">
      <FinancialChartsButton defaultItem={defaultItem} />

      <ExportHintDialog
        open={hintDialog}
        onClose={closeHintDialog}
        onConfirm={onConfirmGenerate}
        shouldUsePassword={true}
      />

      <LoanCalculatorDialog
        open={loanCalculatorDialog}
        day0SurrenderValue={day0SurrenderValue}
        bankLoanPercentage={bankLoanPercentage}
        onChange={handleChange}
        onClose={closeLoanCalculatorDialog}
        onSubmit={onSubmitLoanCalculator}
      />

      <BonusCalculatorDialog
        open={bonusCalculatorDialog}
        principal={principal}
        loanPercentage={disableLoan ? 0 : loanPercentage}
        shouldPayPrincipal={shouldPayPrincipal}
        base={base}
        onChange={handleChange}
        onClose={closeBonusCalculatorDialog}
        onSubmit={onSubmitBonusCalculator}
      />

      <EditDisplayNamesDialog
        open={isEditDisplayNamesDialogOpen}
        onClose={closeEditDisplayNamesDialog}
        displayNames={internalProfiles.map((profile, index) => getPremiumFinancingProfileDisplay(intl, profile, index))}
        onConfirm={onConfirmDisplayNames}
      />

      <AladdinHint title={intl.formatMessage({ id: 'aladdin-demo-video' })} helpCenter="t09" />

      <PreviewWrapper locale={language}>
        <PremiumFinancing
          {...currentProfile}
          profileIndex={profileIndex}
          graphMode={graphMode}
          rowOpen={rowOpen}
          fixedLoanInterestExpanded={fixedLoanInterestExpanded}
          overrideLoanInterestSliderValue={overrideLoanInterestSliderValue}
          dynamicLoanInterestExpanded={dynamicLoanInterestExpanded}
          adviser={userDetails}
          pFinProfiles={internalProfiles}
          disableLoan={disableLoan}
          handleChange={handleChange}
          handleCheckedChange={handleCheckedChange}
          handleProfileChange={handleProfileChange}
          saveProfiles={openConfirmDialog}
          onChangeGraphMode={onChangeGraphMode}
          onToggleOpen={onToggleOpen}
          onToggleFixedLoanInterestExpanded={onToggleFixedLoanInterestExpanded}
          onToggleDynamicLoanInterestExpanded={onToggleDynamicLoanInterestExpanded}
          onToggleDisableLoan={handleToggleDisableLoan}
          openLoanCalculatorDialog={openLoanCalculatorDialog}
          openBonusCalculatorDialog={openBonusCalculatorDialog}
          openEditDisplayNamesDialog={openEditDisplayNamesDialog}
          extraSideInputs={
            <PDFExportCard
              loading={loading}
              language={language}
              onChangeLanguage={handleChangeLanguage}
              onGenerate={generate}
            />
          }
        />
      </PreviewWrapper>
      <Grid container>
        <Grid item xs={12} md={9}>
          {/*{!safariGenerate && !isGuest && (*/}
          {/*  <ExpansionPanel*/}
          {/*    defaultExpanded={false}*/}
          {/*    className="analysis-expansion export-expansion export-wrapper exclude-in-image"*/}
          {/*  >*/}
          {/*    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">*/}
          {/*      {intl.formatMessage({ id: 'analysis-export-heading' })}*/}
          {/*    </ExpansionPanelSummary>*/}

          {/*    <ExpansionPanelDetails className="analysis-expansion-detail">*/}
          {/*      <AnalysisExport*/}
          {/*        intl={intl}*/}
          {/*        history={history}*/}
          {/*        safariGenPath="/premium_financing/safari-gen"*/}
          {/*        targetSelector=".pfa-container"*/}
          {/*        options={targetElem => {*/}
          {/*          // console.log(targetElem, targetElem.offsetWidth)*/}
          {/*          return {*/}
          {/*            // width: targetElem.offsetWidth,*/}
          {/*            onclone: doc => {*/}
          {/*              let container = doc.querySelector('.pfa-container');*/}
          {/*              if (container) {*/}
          {/*                container.style.padding = '10px 8px';*/}
          {/*                container.style.width = `${Math.round(targetElem.offsetWidth)}px`;*/}
          {/*                container.style.fontSize = '85%';*/}

          {/*                // container.querySelectorAll(`input`).forEach(node => node.style.color = 'black');*/}

          {/*                const inputs = container.querySelectorAll('.pfa-input-panel input:not([type="hidden"])');*/}
          {/*                inputs.forEach(node => {*/}
          {/*                  node.style.color = '#665EFF';*/}
          {/*                });*/}

          {/*                const svgs = container.querySelectorAll('svg');*/}
          {/*                svgs.forEach(svg => svg.remove());*/}
          {/*              }*/}
          {/*            }*/}
          {/*          };*/}
          {/*        }}*/}
          {/*        locationState={this.state}*/}
          {/*      />*/}
          {/*    </ExpansionPanelDetails>*/}
          {/*  </ExpansionPanel>*/}
          {/*)}*/}

          <div className="disclaimer">{intl.formatMessage({ id: 'pfa-disclaimer' })}</div>
        </Grid>
      </Grid>
      <ConfirmSaveDialog
        open={confirmSaveDialog}
        onClose={closeConfirmDialog}
        onConfirm={saveProfiles}
        isPFinCalculator
      />
      <LeavePageConfirm shouldShow={updated} save={saveProfiles} />
    </div>
  );

  const wrappedContent = (
    <Fragment>
      {trial && isGuest ? (
        <AccessCodeGate
          intl={intl}
          allowed={!isGuest}
          accessCodes={['pfincalcon', 'pfincalone', 'pfincalapo', 'pfincalteam', 'pfincaldynasty']}
        >
          <Container style={{ maxWidth: APP_CONTENT_CONTAINER_MAX_WIDTH, padding: 0 }}>{contents}</Container>

          <div className="top-hint">
            <a rel="noopener noreferrer" href="https://www.portfoplus.com/adviser/" target="_blank">
              <div>PortfoPlus - 專業顧問必備 你的簽單神器</div>
              <div>{'了解更多>'}</div>
              <div>此連結有效到 15-Jan-2019.</div>
            </a>
          </div>
        </AccessCodeGate>
      ) : (
        <>
          <LoadingBackdrop open={getProfilesLoadState === LOADING || updateProfilesLoadState === LOADING} />
          {(getProfilesLoadState === SUCCESS || getProfilesLoadState === ERROR) && contents}
        </>
      )}
    </Fragment>
  );

  return (
    <div className={`pfa ${safariGenerate ? 'safari-gen' : ''} ${isGuest ? 'guest' : ''}`}>
      {safariGenerate || isGuest ? (
        <div style={{ padding: '50px 0' }}>{wrappedContent}</div>
      ) : (
        <AppContentContainer>{wrappedContent}</AppContentContainer>
      )}
    </div>
  );
};

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    pFinProfiles: state.pFinCalculator.profiles,
    getProfilesLoadState: state.pFinCalculator.getProfilesLoadState,
    updateProfilesLoadState: state.pFinCalculator.updateProfilesLoadState,
    loading: state.pFinCalculator.loading
  }),
  // Map actions to props
  {
    selectedLocale,
    renderHeaderButton,
    getPFinCalculatorProfiles,
    savePFinCalculatorProfiles,
    updatePFinCalculatorProfiles,
    updatePFinCalculatorInputs
  },
  // mergeProps
  null,
  // options
  {}
)(PremiumFinancingAnalysis);

export default injectIntl(container);
