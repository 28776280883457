import React, { Fragment, useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Card, CardContent, CardHeader, Typography, Grid } from '@material-ui/core';
import './SubscriptionPeriodCard.scss';
import moment from 'moment';

const LintContainerStyle = { width: '100%' };
const LineLeaderGridStyle = { flexGrow: 1, position: 'relative', margin: '0 2px' };
const LineLeaderStyle = { position: 'absolute', overflow: 'hidden', width: '100%' };
const LineDots = '.'.repeat(1000);
const Line = props => {
  const { title, value } = props;
  return (
    <Grid container style={LintContainerStyle}>
      <Grid item>{title}</Grid>
      <Grid item style={LineLeaderGridStyle}>
        <div style={LineLeaderStyle}>{LineDots}</div>
      </Grid>
      <Grid item>{value}</Grid>
    </Grid>
  );
};

const CardHeaderStyle = { borderBottom: '1px solid #BFBFBF' };
const CardHeaderTitleStyle = { fontWeight: 500 };
const SubscriptionPeriodCard = props => {
  const { intl, teamInvitationExtension, subscriptionPlan, startDate, expiryDate, freeTrialRemainingSeconds, days } = props;
  const [internalStartDate, setInternalStartDate] = useState(startDate); // moment
  const [internalExpiryDate, setInternalExpiryDate] = useState(expiryDate); // moment
  const isSet = useRef(false);

  useEffect(() => {
    if (startDate && expiryDate) {
      if (!isSet.current) {
        isSet.current = true;
        setInternalStartDate(startDate);
        // need to add teamInvitationExtension month to expiryDate for team invitation
          if (!subscriptionPlan.isTrial) {
              setInternalExpiryDate(moment(expiryDate).add(teamInvitationExtension || 0, 'month'));
          } else {
              setInternalExpiryDate(moment(expiryDate));
          }
      }
    }
  }, [startDate, expiryDate, teamInvitationExtension, subscriptionPlan.isTrial]);

  if (!internalStartDate || !internalExpiryDate) {
    return null;
  }

  return (
    <Fragment>
      <Card className="subscription-period-card">
        <CardHeader
          style={CardHeaderStyle}
          title={
            <Typography style={CardHeaderTitleStyle}>{intl.formatMessage({ id: 'sub-period-card-title' })}</Typography>
          }
        />
        <CardContent>
          <Line title={intl.formatMessage({ id: 'sub-start-date' })} value={internalStartDate.format('DD MMM YYYY')} />
          <Line title={intl.formatMessage({ id: 'expiry-date' })} value={internalExpiryDate.format('DD MMM YYYY')} />
          {/*<Line*/}
          {/*  title={`${intl.formatMessage({ id: 'sub-period' })}${*/}
          {/*      freeTrialRemainingSeconds > 0 ? ` (${intl.formatMessage({ id: 'trial-included' })})` : ''*/}
          {/*  }`}*/}
          {/*  value={`${days} ${intl.formatMessage({ id: 'day' }, { count: days })}`}*/}
          {/*/>*/}
        </CardContent>
      </Card>
    </Fragment>
  );
};

const container = connect(
  state => ({
    teamInvitationExtension: state.userSubscription.teamInvitationExtension
  }),
  {}
)(SubscriptionPeriodCard);

export default injectIntl(container);
