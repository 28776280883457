import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import AdviserInfoCardHeader from '../AdviserInfoCardHeader/AdviserInfoCardHeader';
import { THEME_COLOR_IMAGES, THEME_COLOR_PALETTES } from '../../../constants/my-details';
import React from 'react';
import AdviserInfoPageCardFooter from '../AdviserInfoPageCardFooter/AdviserInfoPageCardFooter';
import _ from 'lodash';

const CardStyle = {
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '90vh',
  maxHeight: 600
};
const CardHeaderStyle = {
  padding: '10px 26px 8px'
};
const CardContentStyle = {
  flexGrow: 1,
  padding: '10px 26px 16px',
  marginBottom: 20,
  overflowY: 'auto'
};

const AdviserInfoCardAbstract = props => {
  const {
    className,
    children,
    title,
    customHeader,
    customFooterStyle,
    contentStyle,
    adviserInfo,
    contentHeight,
    isIntoView,
    ...rest
  } = props;

  const themeColor = _.get(adviserInfo, 'themeColor') || 'blue';
  const themeStyle = _.get(adviserInfo, 'themeStyle') || 'classic';

  return (
    <div className={`adviser-info-page-card card-container${className ? ` ${className}` : ''}`}>
      <Card style={CardStyle}>
        <CardHeader
          className="adviser-info-card-header"
          title={
            customHeader ? (
              customHeader
            ) : (
              <AdviserInfoCardHeader
                titleColor={THEME_COLOR_PALETTES[themeColor][5]}
                style={{
                  backgroundImage: `url("${THEME_COLOR_IMAGES[themeStyle][themeColor || 'blue']['otherTop']}")`
                }}
                label={title}
                height="80%"
              />
            )
          }
          style={CardHeaderStyle}
        />

        <CardContent style={{ ...CardContentStyle, height: contentHeight, ...contentStyle }}>{children}</CardContent>

        <CardActions className="adviser-info-card-actions">
          <div className="aip-card-footer-container">
            <AdviserInfoPageCardFooter
              style={{
                backgroundImage: `url("${THEME_COLOR_IMAGES[themeStyle][themeColor || 'blue']['otherBottom']}")`,
                ...customFooterStyle
              }}
            />
          </div>
        </CardActions>
      </Card>
    </div>
  );
};

export default AdviserInfoCardAbstract;
