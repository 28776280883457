import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import store from '../../store';
import { toggleDialog } from '../../store/control/duck';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { tryTranslate } from '../locale';

export const checkLocalNotificationsPermission = async (onNotDenied, onDenied) => {
  try {
    const permissionsStatus = await LocalNotifications.checkPermissions();

    if (permissionsStatus.display === 'denied') {
      if (onDenied) {
        const intl = getGlobalIntl();
        await onDenied({ message: tryTranslate(intl, 'permission-denied') });
      }
    } else {
      if (permissionsStatus.display === 'prompt-with-rationale' || permissionsStatus.display === 'prompt') {
        store.dispatch(
          toggleDialog('permission', {
            intlId: 'display-notifications-required-msg',
            onClose: onNotDenied
          })
        );
      } else {
        await onNotDenied();
      }
    }
  } catch (error) {
    if (onDenied) {
      await onDenied(error);
    }
  }
};

export const scheduleNotification = ({ notifications }) => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return null;
  } else {
    return new Promise(async (resolve, reject) => {
      await checkLocalNotificationsPermission(
        async () => {
          await LocalNotifications.schedule({
            notifications: notifications.map(notification => ({
              id: new Date().getTime(),
              smallIcon: 'ic_stat_logo',
              iconColor: '#3966f8',
              ...notification
            }))
          });
        },
        error => reject(error)
      );
    });
  }
};
