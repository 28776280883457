import { PureComponent } from 'react';
import React from 'react';
import { Button, Card } from '@material-ui/core';
import './LoginDemo.css';
import _ from 'lodash';
import { getThankYouApi } from '../../store/thankYou/api';
import { toast } from 'react-toastify';
import IconToast from '../../components/NewToast';
import { getDefaultRoute } from '../../utils/router';
import NativeOrWeb from "../../utils/native";

export default class LoginDemo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { checked: true };

    // process login directly if user type is already defined in url
    if (props && props.match && props.match.params && props.match.params.userType && !NativeOrWeb.isNativePlatform()) {
      switch (props.match.params.userType) {
        case 'user':
          this.props.loginUser({ userType: 'user' });
          break;
        case 'adviser':
          this.props.loginUser({ userType: 'adviser' });
          break;
        default:
          this.setState({ checked: true });
          break;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { loginPhase } = this.props;

    if (nextProps.loginPhase === true && loginPhase !== true) {
      this.continueLogin(nextProps);
    }
  }

  async continueLogin(nextProps) {
    const { userDetails, history, currencyExchangeRate, loginFetch } = nextProps;

    if (nextProps.exchangeRate && _.isEmpty(nextProps.exchangeRate)) {
      currencyExchangeRate();
    }

    const showAdviserBlast = userDetails.userType === 'User';
    const showTeamBlast = userDetails.userType === 'Adviser';
    loginFetch(userDetails.userType, { state: { showAdviserBlast: showAdviserBlast, showTeamBlast } });

    // sending the welcome message , remind target & reminderTarget(to show the popup of reminder) on dashboard so that they display only once when user is login
    // logic to display the welcome message , reminder target and reminder popup
    var remindTarget = userDetails.userType === 'Adviser' ? userDetails.remindTarget : false;
    var birthdayPopUp = userDetails.userType === 'Adviser' ? true : false;
    let routePath = getDefaultRoute(userDetails);
    let path = userDetails.hideWelcome ? routePath : '/welcome';
    var welcomeMessage = userDetails && userDetails.welcomeMessage ? userDetails.welcomeMessage : false;

    // Retrieve unread thank you for adviser & pass them
    let unreadThankYous = [];
    if (userDetails.userType === 'Adviser') {
      // with filter
      let apiResult = await getThankYouApi({
        filter: { adviserRead: false }
      });

      if (apiResult.success === true) {
        unreadThankYous = apiResult.data;
      } else {
        toast.info(IconToast('error', apiResult.message), { className: 'new-toast' });
      }
    }

    history.push({
      pathname: path,
      state: {
        remindTarget: remindTarget,
        welcomeMessage: welcomeMessage,
        teamNewsUrls: userDetails.teamDetails !== undefined ? userDetails.teamDetails.teamNewsUrls : undefined,
        birthdayPopUp: birthdayPopUp,
        unreadThankYous: unreadThankYous,
        showAdviserBlast: showAdviserBlast,
        showTeamBlast,
        loginRedirect: true
      }
    });
  }

  login(userType) {
    if (!NativeOrWeb.isNativePlatform()) {
      this.props.loginUser({ userType: userType });
    }
  }

  render() {
    const { intl } = this.props;
    const { checked } = this.state;

    return checked ? (
      <div className="login-demo-wrapper">
        <Card className="login-demo-card">
          <div>{intl.formatMessage({ id: 'login_demo_header' })}</div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.login('user');
              }}
            >
              {intl.formatMessage({ id: 'User' })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.login('adviser');
              }}
            >
              {intl.formatMessage({ id: 'Adviser' })}
            </Button>
          </div>
        </Card>
      </div>
    ) : null;
  }
}
