import React from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
class DeleteIconButton extends React.Component {
  render() {
    const { className, ariaLabel, handleClick } = this.props;

    return (
      <IconButton
        aria-label={ariaLabel ? ariaLabel : 'Delete'}
        color="primary"
        className={className}
        onClick={handleClick}
      >
        <DeleteIcon />
      </IconButton>
    );
  }
}

export default DeleteIconButton;
