import React, { Fragment, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import PolicyCategoryTableInfo, {
  POLICY_REVIEW_INFO_TABLE_DEFAULT_WIDTH,
  POLICY_REVIEW_INFO_TABLE_SHORTENED_DEFAULT_WIDTH
} from '../PolicyCategoryTableInfo/PolicyCategoryTableInfo';
import PolicyCategoryTableBasic, {
  POLICY_REVIEW_BASIC_TABLE_DEFAULT_WIDTH
} from '../PolicyCategoryTableBasic/PolicyCategoryTableBasic';
import { isCategory } from '../../../utils/policy';
import PolicyCategoryTableSavingProtection from '../PolicyCategoryTableSavingProtection/PolicyCategoryTableSavingProtection';
import PolicyCategoryTableProtection, {
  POLICY_REVIEW_PROTECTION_TABLE_DEFAULT_WIDTH
} from '../PolicyCategoryTableProtection/PolicyCategoryTableProtection';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableBody, Typography } from '@material-ui/core';
import { formatNumber } from '../../../utils/formatNumber';
import PolicyCategoryTablePremium, {
  POLICY_REVIEW_PREMIUM_TABLE_DEFAULT_WIDTH
} from '../PolicyCategoryTablePremium/PolicyCategoryTablePremium';
import Card from '@material-ui/core/Card';
import _ from 'lodash';
import './PolicyCategoryTable.scss';
import PolicyCategoryTableEdit from '../PolicyCategoryTableEdit/PolicyCategoryTableEdit';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import moment from 'moment-timezone';
import { PolicyReviewLackCoverIcon } from '../PolicyReview';

const MINIMUM_TWO_ROWS_TABLES_WIDTH = 1288;

const PolicyDummyTable = props => {
  const { category, lastRowNoBorder } = props;
  const subCategories = _.get(category, 'subCategories', []);
  const policies = _.flatMap(subCategories, subCategory => subCategory.policies);
  return (
    <TableContainer className="policy-category-table dummy">
      <Table size="small" style={{ width: 3 }}>
        <TableHead>
          <TableRow className="sub-header">
            <TableCell align="center" style={{ borderBottom: 'transparent' }}>
              <div />
            </TableCell>
          </TableRow>
          <TableRow className="main-row">
            <TableCell align="center" rowSpan={2}>
              <div />
            </TableCell>
          </TableRow>
          <TableRow className="sub-row" />
        </TableHead>
        <TableBody>
          {policies.map((policy, policyIndex) => {
            return (
              <TableRow
                key={policy._id}
                className={lastRowNoBorder && policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
              >
                <TableCell align="center">
                  <div />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PolicyCategoryTableWrapper = props => {
  const {
    selectTwoRows,
    selectBasic,
    selectProtection,
    selectPremium,
    selectSaving, 
    selectLifeInsured, 
    selectBeneficiary, 
    selectAccident,
    selectTotalWithdrawals, 
    selectLumpSum, 
    selectCurrencyPerYear,
    partialScroll,
    fixedRegion,
    scrollableRegion,
    actionRegion
  } = props;

  const ref = useRef(null);

  const shouldResponsive = () => {
    if (ref && ref.current) {
      try {
        const cardElem = ref.current;
        const cardWidth = cardElem.offsetWidth;
        const tables = cardElem.querySelectorAll('.scrollable-region table');
        let tablesWidth = 0;
        tables.forEach(table => {
          tablesWidth += _.get(table, 'offsetWidth', 0);
        });

        // 280 = info table width
        return cardWidth - 280 - tablesWidth < 0;
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  const [collapsed, setCollapsed] = useState(false);
  const [responsiveColumns, setResponsiveColumns] = useState(true);

  useEffect(() => {
    setResponsiveColumns(shouldResponsive());
  }, [selectBasic, 
    selectPremium, 
    selectProtection, 
    selectSaving, 
    selectLifeInsured, 
    selectBeneficiary, 
    selectAccident,
    selectTotalWithdrawals, 
    selectLumpSum, 
    selectCurrencyPerYear,
    selectTwoRows]);

  useEffect(() => {
    if (selectTwoRows) {
      setCollapsed(false);
    }
    setResponsiveColumns(shouldResponsive());
  }, [selectTwoRows]);

  useEffect(() => {
    setResponsiveColumns(shouldResponsive());

    const handleResize = () => setResponsiveColumns(shouldResponsive());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onScroll = event => {
    const target = event.target;
    if (target) {
      if (collapsed) {
        if (target.scrollLeft <= 0) {
          setCollapsed(false);
        }
      } else {
        if (target.scrollLeft > 0) {
          setCollapsed(true);
        }
      }
    }
  };

  return (
    <Card ref={ref} className={`policy-review-card ${partialScroll ? 'partial-scroll' : ''}`}>
      <Grid container wrap="nowrap">
        <Grid item className="without-edit">
          <Grid container wrap="nowrap">
            <Grid item>{fixedRegion(partialScroll && collapsed)}</Grid>
            <Grid item className="scrollable-region" onScroll={responsiveColumns ? onScroll : undefined}>
              {scrollableRegion}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ backgroundColor: '#3eeef818' }}>
          {actionRegion}
        </Grid>
      </Grid>
    </Card>
  );
};

const PolicyCategoryTable = props => {
  const {
    intl,
    currency,
    category,
    selectBasic,
    selectProtection,
    selectPremium,
    selectSaving, 
    selectLifeInsured, 
    selectBeneficiary, 
    selectAccident,
    selectTotalWithdrawals, 
    selectLumpSum, 
    selectCurrencyPerYear,
    selectTwoRows,
    selectTotalCoveragePremium,
    selectPageSplit,
    editable,
    partialScroll,
    template,
    index,
    ...rest
  } = props;

  var infoTableDefaultWidth, infoTableShortenedDefaultWidth, minimumTwoRowsTablesWidth, basicTableDefaultWidth, protectionTableDefaultWidth, premiumTableDefaultWidth;
  
  function calculateWidth(){
    infoTableDefaultWidth = POLICY_REVIEW_INFO_TABLE_DEFAULT_WIDTH;
    infoTableShortenedDefaultWidth = POLICY_REVIEW_INFO_TABLE_SHORTENED_DEFAULT_WIDTH;
    minimumTwoRowsTablesWidth = MINIMUM_TWO_ROWS_TABLES_WIDTH;
    basicTableDefaultWidth = POLICY_REVIEW_BASIC_TABLE_DEFAULT_WIDTH;
    protectionTableDefaultWidth = POLICY_REVIEW_PROTECTION_TABLE_DEFAULT_WIDTH;
    premiumTableDefaultWidth = POLICY_REVIEW_PREMIUM_TABLE_DEFAULT_WIDTH;

    if (!selectSaving) basicTableDefaultWidth -= 50;
    if (!selectLifeInsured) basicTableDefaultWidth -= 80;
    if (!selectBeneficiary) basicTableDefaultWidth -= 80;
    if (!selectAccident && !selectTotalWithdrawals) protectionTableDefaultWidth -= 160;
    else if (!selectTotalWithdrawals) protectionTableDefaultWidth -= 100;
    if (!selectLumpSum) premiumTableDefaultWidth -= 90;
    if (!selectCurrencyPerYear) premiumTableDefaultWidth -= 90;
  }
  calculateWidth();

  useEffect(() => {
    calculateWidth();
  }, [selectBasic, 
    selectPremium, 
    selectProtection, 
    selectSaving, 
    selectLifeInsured, 
    selectBeneficiary, 
    selectAccident,
    selectTotalWithdrawals, 
    selectLumpSum, 
    selectCurrencyPerYear,
    selectTwoRows]);

  const [orderBy, setOrderBy] = useState(undefined);
  const [order, setOrder] = useState('asc');

  const setSort = key => {
    setOrderBy(key);
    setOrder(key === orderBy ? (order === 'asc' ? 'desc' : 'asc') : 'asc');
  };

  const createSortHandler = key => () => setSort(key);

  const categoryId = _.get(category, '_id');
  const lastRowNoBorder = false; //isCategory(categoryId, 'company');

  const infoTable = (collapsed, hideCategory, shortened, width) => (
    <PolicyCategoryTableInfo
      category={category}
      orderBy={orderBy}
      order={order}
      createSortHandler={createSortHandler}
      collapsed={collapsed}
      lastRowNoBorder={lastRowNoBorder}
      hideCategory={hideCategory}
      shortened={shortened}
      width={width}
    />
  );

  const protectionTable = protectionProps =>
    isCategory(categoryId, 'general') ? (
      <PolicyCategoryTableSavingProtection
        selectTotalWithdrawals={selectTotalWithdrawals}
        currency={currency}
        category={category}
        lastRowNoBorder={lastRowNoBorder}
        extraRows={
          selectTotalCoveragePremium && isCategory(categoryId, ['general'])
            ? (columns, totalMarketValue) =>
                totalMarketValue ? (
                  <TableRow className="total-row protection">
                    <TableCell align="center" colSpan={columns}>
                      <div>
                        <Typography>
                          {`${intl.formatMessage({ id: 'pr-table-market-value-sum' })}: ${intl.formatMessage({
                            id: currency
                          })} ${formatNumber(totalMarketValue, intl)}`}
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  undefined
                )
            : undefined
        }
        {...protectionProps}
      />
    ) : (
      <PolicyCategoryTableProtection
        selectAccident={selectAccident}
        currency={currency}
        category={category}
        lastRowNoBorder={lastRowNoBorder}
        extraRows={
          selectTotalCoveragePremium && isCategory(categoryId, 'personal')
            ? (columns, totalLifeCoverage, totalCICoverage) => (
                <TableRow className="total-row protection">
                  <TableCell align="center" colSpan={Math.ceil(columns / 2)}>
                    <Grid container alignItems="center" justify="center" spacing={1}>
                      <Grid item>
                        <Typography>
                          {`${intl.formatMessage({ id: 'pr-table-life-protection-sum' })}: ${intl.formatMessage({
                            id: currency
                          })} ${formatNumber(totalLifeCoverage, intl)}`}
                        </Typography>
                      </Grid>
                      {totalLifeCoverage === 0 && (
                        <Grid item>
                          <PolicyReviewLackCoverIcon />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell align="center" colSpan={Math.floor(columns / 2)}>
                    <Grid container alignItems="center" justify="center" spacing={1}>
                      <Grid item>
                        <Typography>
                          {`${intl.formatMessage({ id: 'pr-table-ci-protection-sum' })}: ${intl.formatMessage({
                            id: currency
                          })} ${formatNumber(totalCICoverage, intl)}`}
                        </Typography>
                      </Grid>
                      {totalCICoverage === 0 && (
                        <Grid item>
                          <PolicyReviewLackCoverIcon />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            : undefined
        }
        {...protectionProps}
      />
    );

  var infoTableWidth = infoTableDefaultWidth;
  var basicTableWidth = basicTableDefaultWidth;
  var protectionTableWidth = protectionTableDefaultWidth;
  var premiumTableWidth = premiumTableDefaultWidth;
  if (selectTwoRows) {
    // second table is longer than the first table if we don't set width explicitly
    if ((selectProtection && selectPremium) || !selectBasic) {
      let totalWidth =
      infoTableShortenedDefaultWidth +
        (selectProtection ? protectionTableDefaultWidth + 6 : 0) +
        (selectPremium ? premiumTableDefaultWidth + 6 : 0);

      // minimum scale requirement
      if (totalWidth < minimumTwoRowsTablesWidth) {
        totalWidth = minimumTwoRowsTablesWidth;

        if (selectProtection) {
          protectionTableWidth =
            ((totalWidth - infoTableShortenedDefaultWidth - 6 - (selectPremium ? 6 : 0)) *
            protectionTableDefaultWidth) /
            (protectionTableDefaultWidth +
              (selectPremium ? premiumTableDefaultWidth : 0));
        }

        if (selectPremium) {
          premiumTableWidth =
            ((totalWidth - infoTableShortenedDefaultWidth - 6 - (selectProtection ? 6 : 0)) *
            premiumTableDefaultWidth) /
            (premiumTableDefaultWidth +
              (selectProtection ? protectionTableDefaultWidth : 0));
        }
      }

      if (!selectBasic) {
        infoTableWidth = totalWidth;
      } else {
        basicTableWidth = totalWidth - infoTableDefaultWidth;
      }
    } else {
      if (selectProtection || selectPremium) {
        let totalWidth =
        infoTableDefaultWidth + (selectBasic ? basicTableDefaultWidth : 0);

        // minimum scale requirement
        if (totalWidth < minimumTwoRowsTablesWidth) {
          totalWidth = minimumTwoRowsTablesWidth;

          if (selectBasic) {
            basicTableWidth = totalWidth - infoTableDefaultWidth;
          }
        }

        if (selectProtection) {
          protectionTableWidth = totalWidth - infoTableShortenedDefaultWidth - 6;
        } else {
          premiumTableWidth = totalWidth - infoTableShortenedDefaultWidth - 6;
        }
      }
    }
  }

  // const protectionTableExplicitWidth =
  // 	selectProtection && selectTwoRows
  // 		? Math.max(
  // 			(selectBasic ? basicTableDefaultWidth : 0) +
  // 			(selectPremium ? premiumTableDefaultWidth + 6 : 0),
  // 			535
  // 		)
  // 		: undefined;
  // const basicPremiumTableExplicitWidth =
  // 	selectProtection && selectTwoRows && (!selectBasic || !selectPremium) ? protectionTableExplicitWidth : undefined;

  const firstTable = (
    <PolicyCategoryTableWrapper
      partialScroll={partialScroll}
      selectTwoRows={selectTwoRows}
      selectBasic={selectBasic}
      selectProtection={selectProtection}
      selectPremium={selectPremium}
      selectSaving={selectSaving}
      selectLifeInsured={selectLifeInsured}
      selectBeneficiary={selectBeneficiary}
      selectAccident={selectAccident}
      selectTotalWithdrawals={selectTotalWithdrawals}
      selectLumpSum={selectLumpSum}
      selectCurrencyPerYear={selectCurrencyPerYear}
      fixedRegion={collapsed => infoTable(collapsed, false, false, infoTableWidth)}
      scrollableRegion={
        <Grid container key={categoryId} wrap="nowrap">
          {selectBasic && (
            <Grid item>
              <PolicyCategoryTableBasic 
                category={category} 
                width={basicTableWidth} 
                lastRowNoBorder={lastRowNoBorder}
                selectSaving={selectSaving}
                selectLifeInsured={selectLifeInsured}
                selectBeneficiary={selectBeneficiary}
              />
            </Grid>
          )}
          {selectProtection && !selectTwoRows && (
            <Fragment>
              <Grid item>
                <PolicyDummyTable category={category} lastRowNoBorder={lastRowNoBorder} />
              </Grid>
              <Grid item>{protectionTable()}</Grid>
            </Fragment>
          )}
          {selectPremium && !selectTwoRows && (
            <Fragment>
              <Grid item>
                <PolicyDummyTable category={category} lastRowNoBorder={lastRowNoBorder} />
              </Grid>
              <Grid item>
                <PolicyCategoryTablePremium
                  selectLumpSum={selectLumpSum}
                  selectCurrencyPerYear={selectCurrencyPerYear}
                  currency={currency}
                  category={category}
                  width={premiumTableWidth}
                  lastRowNoBorder={lastRowNoBorder}
                  extraRows={
                    selectTotalCoveragePremium && isCategory(categoryId, ['personal', 'general'])
                      ? (columns, totalYearlyPremium) => (
                          <TableRow className="total-row premium">
                            <TableCell align="center" colSpan={columns}>
                              <div>
                                <Typography>
                                  {intl.formatMessage(
                                    { id: 'pr-table-premium-sum' },
                                    {
                                      amountText: `${intl.formatMessage({ id: currency })} ${formatNumber(
                                        totalYearlyPremium,
                                        intl
                                      )}`,
                                      monthlyAmount: formatNumber(Math.round(totalYearlyPremium / 12), intl)
                                    }
                                  )}
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      : undefined
                  }
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      }
      actionRegion={
        editable && (
          <Grid item>
            <PolicyCategoryTableEdit category={category} lastRowNoBorder={lastRowNoBorder} {...rest} />
          </Grid>
        )
      }
    />
  );

  const secondTable =
    (selectProtection || selectPremium) && selectTwoRows ? (
      <PolicyCategoryTableWrapper
        partialScroll={partialScroll}
        selectTwoRows={selectTwoRows}
        fixedRegion={collapsed => infoTable(collapsed, true, true, infoTableWidth)}
        scrollableRegion={
          <Grid container key={categoryId} wrap="nowrap">
            {selectProtection && (
              <Fragment>
                <Grid item>
                  <PolicyDummyTable category={category} lastRowNoBorder={lastRowNoBorder} />
                </Grid>
                <Grid item>{protectionTable({ width: protectionTableWidth })}</Grid>
              </Fragment>
            )}
            {selectPremium && (
              <Fragment>
                <Grid item>
                  <PolicyDummyTable category={category} lastRowNoBorder={lastRowNoBorder} />
                </Grid>
                <Grid item>
                  <PolicyCategoryTablePremium
                    selectLumpSum={selectLumpSum}
                    selectCurrencyPerYear={selectCurrencyPerYear}
                    currency={currency}
                    category={category}
                    width={premiumTableWidth}
                    lastRowNoBorder={lastRowNoBorder}
                    extraRows={
                      selectTotalCoveragePremium && isCategory(categoryId, ['personal', 'general'])
                        ? (columns, totalYearlyPremium) => (
                            <TableRow className="total-row premium">
                              <TableCell align="center" colSpan={columns}>
                                <div>
                                  <Typography>
                                    {intl.formatMessage(
                                      { id: 'pr-table-premium-sum' },
                                      {
                                        amountText: `${intl.formatMessage({ id: currency })} ${formatNumber(
                                          totalYearlyPremium,
                                          intl
                                        )}`,
                                        monthlyAmount: formatNumber(Math.round(totalYearlyPremium / 12), intl)
                                      }
                                    )}
                                  </Typography>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        : undefined
                    }
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        }
        actionRegion={
          editable && (
            <Grid item>
              <PolicyCategoryTableEdit category={category} lastRowNoBorder={lastRowNoBorder} {...rest} />
            </Grid>
          )
        }
      />
    ) : null;

  return (
    <Grid className={`policy-category-table-wrapper ${secondTable ? 'two-rows' : ''}`} container direction="column">
      {/*{!template && index === 0 && (*/}
      {/*  <Typography style={{ position: 'absolute', top: -48, right: 0 }}>{moment().format('DD-MMM-YYYY')}</Typography>*/}
      {/*)}*/}
      <Grid item className="first-table">
        {firstTable}
      </Grid>
      {secondTable && (
        // a hack avoiding the tables collapsing together under page-split mode in the generated DPF
        <Grid item className="second-table" style={{ marginTop: template && selectPageSplit ? 32 : 16 }}>
          {secondTable}
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(PolicyCategoryTable);
