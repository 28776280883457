import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import user, { userEpic } from './user/duck';

import headerButtonControl from './headerButtonControl/duck';
import policy, { policyEpic } from './policy/duck';
import analysis, { analysisEpic } from './analysis/duck';
import adviserDashboard, { adviserDashboardEpic } from './adviserDashboard/duck';
import importStore from './import/duck';
import file, { fileEpic } from './file/duck';
import reminder, { reminderEpic } from './reminder/duck';
import share, { shareEpic } from './share/duck';
import client, { clientEpic } from './client/duck';
import summary, { summaryEpic } from './summary/duck';
import message, { messageEpic } from './message/duck';
import referral, { referralEpic } from './referral/duck';
import qrCode, { qrCodeEpic } from './qrCode/duck';
import team, { teamEpic } from './team/duck';
import userSubscription, { userSubscriptionEpic } from './userSubscription/duck';
import subscriptionPlan from './subscriptionPlan/duck';
import guideline, { guidelineEpic } from './guideline/duck';
import notificationCenter, { notificationCenterEpic } from './notificationCenter/duck';
import control from './control/duck';
import covid from './covid/duck';
import assistant, { assistantEpic } from './assistant/duck';
import todoList, { todoListEpic } from './todoList/duck';
import template, { templateEpic } from './template/duck';
import figure from './figure/duck';
import policyReview from './policyReview/duck';
import adviserCustomFilters, { adviserCustomFiltersEpics } from './adviserCustomFilters/duck';
import userMission from './userMission/duck';
import medicalCard from './medicalCard/duck';
import medicalCardCouponCode from './medicalCardCouponCode/duck';
import financialPyramid from './financialPyramid/duck';
import policyPicker, { policyPickerEpic } from './policyPicker/duck';
import auth from './auth/duck';
import adviserProfilePlus from './adviserProfilePlus/duck';
import functions from './functions/duck';
import pFinCalculator from './pFinCalculator/duck';
import adviserExport from './adviserExport/duck';
import emergencyExport from './emergencyExport/duck';
import affiliate from './affiliate/duck';
import chequeWizard from './chequeWizard/duck';
import configuration from './configuration/duck';
import userData from './userData/duck';
import router from './router/duck';
import insurer from './insurer/duck';

// import socket from './socket/duck';
import app from './app';

import { localeReducer } from './locale/locale-reducer';

import { createMiddleware, createMetaReducer } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';

const gtm = GoogleTagManager({});

const eventsMap = {};

const gtmMiddleware = createMiddleware(eventsMap, gtm);
const gtmMetaReducer = createMetaReducer(eventsMap, gtm);

// Bundling Epics
const rootEpic = combineEpics(
  userEpic,
  policyEpic,
  analysisEpic,
  adviserDashboardEpic,
  fileEpic,
  reminderEpic,
  shareEpic,
  clientEpic,
  summaryEpic,
  messageEpic,
  referralEpic,
  qrCodeEpic,
  teamEpic,
  guidelineEpic,
  userSubscriptionEpic,
  notificationCenterEpic,
  assistantEpic,
  todoListEpic,
  templateEpic,
  adviserCustomFiltersEpics,
  policyPickerEpic
);

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware(rootEpic);

// Define Middleware
const middleware = [thunk, promise(), epicMiddleware, gtmMiddleware];

// Define Reducers
const reducer = combineReducers({
  app,
  user,
  policy,
  analysis,
  adviserDashboard,
  file,
  reminder,
  share,
  client,
  summary,
  importStore,
  message,
  referral,
  qrCode,
  // socket,
  team,
  userSubscription,
  subscriptionPlan,
  guideline,
  notificationCenter,
  covid,
  locale: localeReducer,
  gtm: gtmMetaReducer,
  control,
  assistant,
  headerButtonControl,
  todoList,
  figure,
  template,
  policyReview,
  adviserCustomFilters,
  userMission,
  medicalCard,
  medicalCardCouponCode,
  financialPyramid,
  policyPicker,
  auth,
  adviserProfilePlus,
  functions,
  pFinCalculator,
  adviserExport,
  emergencyExport,
  affiliate,
  chequeWizard,
  configuration,
  userData,
  router,
  insurer
});

// Create Store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (state, action) => {
  if (action.type === 'portfoplus/user/LOGOUT_USER_SUCCESS') {
    state = undefined;
  } else if (action.type === 'portfoplus/user/LOGOUT_USER_SUCCESS_KEEP') {
    state = { auth: state.auth, locale: state.locale };
  }
  return reducer(state, action);
};

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)));

export default store;
