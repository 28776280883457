import React from 'react';
import { connect } from 'react-redux';
import { toggleProDialog } from '../../../store/userSubscription/duck';
import { injectIntl } from 'react-intl';
import { Card, CardContent, Typography } from '@material-ui/core';
import './ProRequiredCard.scss';
import { ShortSubscriptions } from '../../../utils/user-subscription';
import withRouter from 'react-router/withRouter';

class ProRequiredCard extends React.PureComponent {
  render() {
    const { intl, history, subscriptions, sectionTitleId, style } = this.props;

    const show = !new ShortSubscriptions(subscriptions).containTypedSubscription({
      type: 'core',
      nicknames: ['Pro', 'Ultra']
    });

    return show ? (
      <Card className="pro-required-card" style={style}>
        <CardContent>
          <Typography>
            {intl.formatMessage(
              { id: 'pro-required-card-message' },
              { sectionTitle: intl.formatMessage({ id: sectionTitleId }) }
            )}
            <span
              className="link"
              onClick={() =>
                history.push({
                  pathname: '/subscription',
                  search: '?utm_source=In-app&utm_medium=upgrade_banner'
                })
              }
            >
              {intl.formatMessage({ id: 'upgrade' })}
            </span>
            {intl.locale === 'en' && ' '}
            {intl.formatMessage({ id: 'or-wording' })}
            <span
              className="link"
              onClick={() =>
                window.open(
                  'https://www.portfoplus.com/adviser/?utm_source=In-app&utm_medium=upgrade_banner#subscribe',
                  '_blank'
                )
              }
            >
              {intl.formatMessage({ id: 'learn-more' })}
            </span>
          </Typography>
        </CardContent>
      </Card>
    ) : null;
  }
}

const container = connect(
  // Map state to props
  state => ({
    subscriptions: state.userSubscription.subscriptions
  }),
  // Map actions to props
  {
    toggleProDialog
  },
  // mergeProps
  null,
  // options
  {}
)(withRouter(ProRequiredCard));

export default injectIntl(container);
