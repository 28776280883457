import * as api from './api';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';

/***********************************
 * Action Types
 ***********/
export const UPDATE_MEDICAL_CARD_COUPON_CODES = 'UPDATE_MEDICAL_CARD_COUPON_CODES';
export const UPDATE_GET_MEDICAL_CARD_COUPON_CODES_PHASE = 'UPDATE_GET_MEDICAL_CARD_COUPON_CODES_PHASE';

/***********************************
 * Initial State
 ***********/
const initialState = {
  medicalCardCouponCodes: undefined,
  triedFreeTrial: undefined,
  getMedicalCardCouponCodesPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_MEDICAL_CARD_COUPON_CODES:
      const { medicalCardCouponCodes, triedFreeTrial } = action.payload;
      return {
        ...state,
        medicalCardCouponCodes: medicalCardCouponCodes,
        triedFreeTrial: triedFreeTrial,
        getMedicalCardCouponCodesPhase: SUCCESS
      };
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const getMedicalCardCouponCodes = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_GET_MEDICAL_CARD_COUPON_CODES_PHASE, payload: { phase: LOADING } });
  try {
    const json = await api.getMedicalCardCouponCodesApi();
    dispatch({
      type: UPDATE_MEDICAL_CARD_COUPON_CODES,
      payload: { medicalCardCouponCodes: json.medicalCardCouponCodes, triedFreeTrial: json.triedFreeTrial }
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_GET_MEDICAL_CARD_COUPON_CODES_PHASE, payload: { phase: ERROR } });
  }
};
