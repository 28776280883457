import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Badge,
  Button,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Switch,
  Zoom
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment-timezone';
import ContactButton from '../../views/Advisor/Client/partials/ContactButton/ContactButton';
import withRouter from 'react-router/withRouter';
import './ClientEntry.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getClientAnalysisScores, getClientWhatsappUrl, openWhatsappUrl } from '../../utils/client';
import { mergeDialog, toggleDialog } from '../../store/control/duck';
import {
  formatLastMeetingDuration,
  getClientConnection,
  getClientConnectionStatus,
  getConstellation,
  getCPIScoreColor,
  getImportanceImage,
  getLastMeetingColorBar,
  getLastMeetingDate,
  getLastMeetingMonthsDays,
  geToFactsheetDetails,
  goToAddPortfolio,
  goToAnalysis,
  goToExportOption,
  goToFactsheet,
  goToPortfolio,
  goToReminder,
  isClientDisconnected,
  toggleBirthdayGreetingDialog,
  toggleMeetingNoteDialog
  // isInactiveEmail,
  // setClientIdLocalStorage
} from './utils';
import _ from 'lodash';
import { enableTracking, getClientDetails, updateFactSheet, updateViewingClient } from '../../store/client/duck';
import OpportunityControl from './controls/OpportunityControl/OpportunityControl';
import Grid from '@material-ui/core/Grid';
import { isSubscriptionAllow } from '../../utils/user-subscription';
import { isSubscriptionAllowAction } from '../../utils/user-subscription-store';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import SettingsIcon from '@material-ui/icons/Settings';
import OverallRadar from '../../views/Analysis/partials/OverallRadar/OverallRadar';
import ColoredProgress from './ClientShortFall/ColoredProgress/ColoredProgress';
import TagsControl from './controls/TagsControl/TagsControl';
import LastMeetingHint from './LastMeetingHint/LastMeetingHint';
import ClientExpansionPortfolio from './ClientExpansionPortfolio/ClientExpansionPortfolio';
import { getCIBetterThanKey, getLifeBetterThanKey } from '../../utils/analysis';
import { getPeerScore } from '../../utils/analysisCalculator';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import { tracking } from '../../utils/tracking';
import { formatOnlyNumber, formatOnlyUnit } from '../../utils/formatNumber';
import { isMobile } from 'react-device-detect';
import { getAvatar } from '../../utils/my-details';
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import { shouldEnableChatGPT, shouldEnableChatGPTTest } from '../../utils/user';
import { goToGPTOptions } from '../../utils/openai';
import EditFamilyDialog from '../EditFamilyDialog/EditFamilyDialog.js';
import { fontSize } from '@material-ui/system';

// colors
export const CPI_WORDINGS = ['High', 'Good', 'Fair', 'No Data'];
export const BORDER_COLORS = ['#5EE092', '#FFAF19', '#FF6A74', '#cccccc'];
export const TRACKING_COLORS = { L: BORDER_COLORS[2], M: BORDER_COLORS[1], H: BORDER_COLORS[0], NA: BORDER_COLORS[3] };
export const DISC_COLORS = { D: '#EA8462', I: '#EAC862', S: '#62EA84', C: '#62A6EA' };

// images
const SMART_IDEA_IMAGE = 'img/smart_idea.png';
const SMART_IDEA_LITE_IMAGE = 'img/smart_idea_lite.png';
const PORTFOLIO_IMAGES = {
  PENDING: 'img/doughnut_pending.svg',
  INACTIVE: 'img/doughnut_chart_half.svg',
  CONNECTED: 'img/doughnut_chart_blue.svg',
  DISCONNECTED: 'img/doughnut_chart_grey.svg'
};
const DISC_UNSET_IMAGE = 'img/discnil.png';
// const EMAIL_IMAGE = 'img/email.png';
// const EMAIL_INACTIVE_IMAGE = 'img/email_grey.png';
// const PHONE_NUMBER_IMAGE = 'img/export_phone.png';
// const PHONE_NUMBER_NA_IMAGE = 'img/export_phone_grey.png';
// const WHATSAPP_IMAGE = 'img/export_whatsapp.png';
// const WHATSAPP_NA_IMAGE = 'img/export_whatsapp_grey.png';
const LAST_MEETING_IMAGE = 'img/last_meeting_count.svg';
const BIRTHDAY_IMAGE = 'img/birthdayClient.svg';
const CONNECTION_STATUS_IMAGES = {
  CONSENT: 'img/consentTrue.svg',
  NO_CONSENT: 'img/consentFalse.svg'
};
const GET_CONNECTION_IMAGE = {
  INACTIVE: 'img/inactiveLink.svg',
  PENDING: 'img/LinkPending.svg',
  CONNECTED: 'img/LinkConnected.svg',
  DISCONNECTED: 'img/LinkDisconnect.svg'
};
// const POLICY_ADD_IMAGE = { ACTIVE: 'img/add_policy_yellow.png', INACTIVE: 'img/add_policy_grey1.png' };
const ANALYSIS_IMAGE = { ACTIVE: 'img/pAnalysis_blue.svg', INACTIVE: 'img/pAnalysis_grey.svg' };
const POLICY_ADD_IMAGE = { ACTIVE: 'img/add-icon.svg', INACTIVE: 'img/add-icon-gray.svg' };
const REMINDER_IMAGE = { ACTIVE: 'img/alarm_clock_blue.png', INACTIVE: 'img/alarm_clock_grey.png' };
const EXPORT_IMAGE = { ACTIVE: 'img/export_blue.svg', INACTIVE: 'img/export_grey.svg' };
const REFERRAL_IMAGE = { ACTIVE: 'img/add_user_group_blue.png', INACTIVE: 'img/add_user_group_grey.png' };
const FACTSHEET_IMAGE = 'img/factsheet.png';
const REMIND_ME_IMAGE = 'img/remindme.png';
// const NOTE_IMAGE = 'img/notes.png';
const TRACKING_IMAGE = {
  ACTIVE: 'img/bizTrackingActive_bluePause_greyCamera.png',
  INACTIVE: 'img/bizTracking_grey.png',
  ON: 'img/bizTrackingOn_yellowPlay_blueCamera.png'
};
const EXISTING_CLIENT_IMAGE = {
  YES: 'img/existingClient.svg',
  NO: 'img/nonExistingClient.svg',
  UNDEFINED: 'img/halfExistingClient.png'
};

class ClientEntry extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMet: _.get(props, 'client.trackingId.met'),
      selectedOpportunity: _.get(props, 'client.trackingId.opportunity'),
      savedFamilies: _.get(this.props, 'client.factsheetId.families', []),
      families: _.get(this.props, 'client.factsheetId.families', []),
      savedTags: _.get(this.props, 'client.factsheetId.tags'),
      anchorEl: null,
      open: false,
      editFamilyDialogOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    const prevOpportunity = _.get(prevProps, 'client.trackingId.opportunity');
    const prevMet = _.get(prevProps, 'client.trackingId.met');
    const opportunity = _.get(this.props, 'client.trackingId.opportunity');
    const met = _.get(this.props, 'client.trackingId.met');
    const { selectedMet, selectedOpportunity } = this.state;

    const obj = {};
    if (opportunity !== prevOpportunity && opportunity !== selectedOpportunity) {
      obj.selectedOpportunity = opportunity;
    }

    if (met !== prevMet && met !== selectedMet) {
      obj.selectedMet = met;
    }

    obj.savedTags = _.get(this.props, 'client.factsheetId.tags');

    if (Object.keys(obj).length > 0) {
      this.setState(obj);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.client?.factsheetId?.families) {
      this.setState({
        families: nextProps.client.factsheetId.families,
        savedFamilies: nextProps.client.factsheetId.families
      })
    }
  }

  contact(client) {
    this.props.toggleDialog('contact', { client: client });
  }

  smartIdea(client) {
    isSubscriptionAllowAction(
      { type: 'core', nicknames: ['Pro', 'Ultra'] },
      {
        customMessage: this.props.intl.formatMessage({ id: 'upgrade-msg-smart-idea' }),
        cb: () => this.props.mergeDialog('smartIdea', { closed: true })
      }
    );
    this.props.toggleDialog('smartIdea', { client: client });
  }

  portfolio(client) {
    if (this.props.beforeRoute) this.props.beforeRoute();
    goToPortfolio(client, this.props.history);
  }

  analysis(client, target) {
    if (this.props.beforeRoute) this.props.beforeRoute();
    goToAnalysis(client, this.props.history, target);
  }

  reminder(client) {
    if (this.props.beforeRoute) this.props.beforeRoute();
    goToReminder(client, this.props.history, this.props.userDetails);
  }

  export(client) {
    if (this.props.beforeRoute) this.props.beforeRoute();
    goToExportOption(client, this.props.history);
  }

  getUsers(client) {
    if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) return;
    // if (this.props.beforeRoute) this.props.beforeRoute();
    const { intl, history, location, userDetails, toggleDialog } = this.props;
    history.replace({
      ...location,
      state: {
        ...location.state,
        client: client
      }
    });

    const dialogObj = {
      referLinkSearch: {
        defaultMessage: intl.formatMessage(
          { id: 'client-referral-qr-default-msg' },
          { adviserName: userDetails?.name, clientName: client?.factsheetId?.name }
        )
      },
      customCTAMessage: intl.formatMessage({ id: 'referral-card-message-2' }),
      defaultMessageIntlId: 'referral-link-message-2',
      defaultMessageExtras: { name: userDetails?.name }
    };

    toggleDialog('referralQRCode', dialogObj);
  }

  factsheet(client) {
    if (this.props.beforeRoute) this.props.beforeRoute();
    goToFactsheet(client, this.props.history);
  }

  factsheetDetails(client) {
    const { history, beforeRoute, getClientDetails } = this.props;
    if (beforeRoute) beforeRoute();
    geToFactsheetDetails(client, history, getClientDetails);
  }

  birthdayGreet(client) {
    const { intl, toggleDialog } = this.props;
    toggleBirthdayGreetingDialog(toggleDialog, intl, client);
  }

  lastMeetingHint() {
    const { intl, toggleDialog, client } = this.props;
    const lastMeetingDuration = getLastMeetingMonthsDays(getLastMeetingDate(client));
    toggleDialog('normal', {
      title: intl.formatMessage({ id: 'Latest Meeting' }),
      message: <LastMeetingHint intl={intl} lastMeetingDuration={lastMeetingDuration} />
    });
  }

  DISC(client) {
    const { toggleDialog, updateFactSheet } = this.props;
    toggleDialog('DISC', {
      personality: _.get(client, 'factsheetId.personality', undefined),
      onSubmit: personality => {
        updateFactSheet({
          id: _.get(client, 'factsheetId._id', undefined),
          key: 'personality',
          value: personality,
          clientId: client._id
        });
      }
    });
  }

  addPortfolio(client) {
    const { history, userDetails, toggleDialog } = this.props;

    if (client.consent) {
      if (this.props.beforeRoute) this.props.beforeRoute();
      goToAddPortfolio(client, history, userDetails);
    } else {
      toggleDialog('addPortfolioHint', { client: client });
    }
  }

  whatsapp(client, whatsapp) {
    const { intl, adviserBusiness } = this.props;
    openWhatsappUrl(getClientWhatsappUrl(intl, client, get(adviserBusiness, 'defaultMessage', undefined), whatsapp));
  }

  connection(client) {
    const { toggleDialog, onConnection } = this.props;

    if (onConnection) {
      onConnection();
    }

    if (client.inactiveUser && !client.language) {
      toggleDialog('chooseLanguage', { client: client });
    } else {
      toggleDialog('connection', { client: client });
    }
  }

  toggleTracking(client) {
    const { toggleDialog } = this.props;
    const connectionStatus = getClientConnectionStatus(client);

    if (connectionStatus === 'Disconnected') {
      return;
    }

    if (!client.trackingId || !client.trackingId.isActive) {
      toggleDialog('trackOn', { client: client, startDate: moment(), expectedBusinessAmount: '' });
    } else {
      toggleDialog('trackOff', {
        client: client,
        businessVol: _.get(client, 'trackingId.businessVol', null),
        met: _.get(client, 'trackingId.met', 0)
      });
    }
  }

  meetingNote() {
    const { client, toggleDialog } = this.props;
    toggleMeetingNoteDialog(toggleDialog, client);
  }

  meetingSummary() {
    const { client, toggleDialog } = this.props;
    toggleDialog('meeting', {
      mode: 'summary',
      client: client,
      met: _.get(client, 'trackingId.met', 0),
      opportunity: _.get(client, 'trackingId.opportunity', ''),
      businessVol: _.get(client, 'trackingId.businessVol', ''),
      trackingStartDate: _.get(client, 'trackingId.startDate'),
      lastMeetingLocation: _.get(client, 'factsheetId.lastMeetingLocation', ''),
      fsReminderDate: _.get(client, 'factsheetId.fsReminderDate', null),
      fsReminderActive: _.get(client, 'factsheetId.fsReminderActive', false),
      remark: _.get(client, 'factsheetId.remark', ''),
      tags: _.get(client, 'factsheetId.tags', []),
      isRecruitment: _.get(client, 'factsheetId.isRecruitment', false),
      nextMeetingDate: _.get(client, 'factsheetId.nextMeetingDate')
    });
  }

  chatGPT = () => {
    tracking('Click on ChatGPT - Client Page');

    const { client } = this.props;
    goToGPTOptions(client);
  };

  chatGPTTest = () => {
    const { toggleDialog, client } = this.props;
    toggleDialog('chatGPT', { client: client });
  };

  selectMet(client, event) {
    const { enableTracking } = this.props;

    const met = parseInt(event.target.value);

    this.setState({ selectedMet: met });

    const obj = {
      met: met,
      trackingId: client.trackingId._id,
      clientId: client._id,
      dayCount: moment().diff(moment(client.trackingId.startDate, 'YYYY-MM-DD'), 'days')
    };

    enableTracking(obj);
  }

  selectOpportunity(client, opportunity) {
    const { enableTracking } = this.props;

    this.setState({ selectedOpportunity: opportunity });

    const obj = {
      opportunity: opportunity,
      trackingId: client.trackingId._id,
      clientId: client._id,
      dayCount: moment().diff(moment(client.trackingId.startDate, 'YYYY-MM-DD'), 'days')
    };

    if (opportunity !== undefined) {
      if (opportunity === 'H') {
        obj.dateHigh = moment.utc(moment().format('YYYY-MM-DD')).format('YYYY-MM-DDT00:00:00.00Z');
      }
    }

    enableTracking(obj, client._id);
  }

  handleMsgOptChange(client) {
    let msgOpt = _.get(client.factsheetId, 'msgOpt', false);
    let formData = {};
    formData.key = 'msgOpt';
    formData.value = !msgOpt;
    formData.id = _.get(client.factsheetId, '_id');
    formData.clientId = _.get(client, '_id', '');
    formData.isInactive = _.get(client, 'inactiveUser', false);
    this.props.updateFactSheet(formData);
  }

  handleMovePolicy(event, client) {
    this.props.movePolicyOptions.onMovePolicy(client);
  }

  updateTags = tags => {
    const { client, updateFactSheet } = this.props;
    const { savedTags } = this.state;
    let obj = {
      id: _.get(client, 'factsheetId._id', undefined),
      key: 'tags',
      value: tags,
      clientId: client._id
    };

    //tag: only set the object if old_data!=new_data
    var old_data = savedTags !== null && savedTags !== undefined ? savedTags : [];
    if (!_.isEqual(old_data, tags)) {
      obj.usedTags = {
        old_data: old_data,
        new_data: tags
      };
      updateFactSheet(obj);
      this.setState({
        savedTags: tags
      });
    }
  };

  updateFamilies = () => {
    const { client, updateFactSheet } = this.props;
    let obj = {
      id: _.get(client, 'factsheetId._id', undefined),
      key: 'families',
      value: this.state.families,
      clientId: client._id
    };

    updateFactSheet(obj);
  };

  analysisOverall = () => {
    const { client } = this.props;
    this.analysis(client, 'overall');
  };

  deleteClient = () => {
    const { client, toggleDialog } = this.props;
    toggleDialog('deleteClient', { client: client });
  };

  handleDotClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleDotClose = () => {
    this.setState({ anchorEl: null });
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const {
      intl,
      userDetails,
      theme,
      subscriptions,
      client,
      summaryPrepend,
      nameAppend,
      hideDetails,
      expanded,
      selectedClient,
      mergeDialog,
      onChange,
      applySearchFilter,
      clientState,
      showDetails,
      figure,
      updateViewingClient,
      customActions,
      teamCampaignCodes
    } = this.props;
    const { selectedMet, selectedOpportunity, anchorEl, open } = this.state;

    if (!client) return null;
    const factsheet = get(client, 'factsheetId', {});
    const trackingclient = get(client, 'trackingId', {});
    const connection = getClientConnection(client);
    const isDisconnected = isClientDisconnected(client);
    const name = get(factsheet, 'name', '');
    const nickname = get(factsheet, 'nickname', '');

    const opportunity = selectedOpportunity !== undefined ? selectedOpportunity : trackingclient.opportunity;
    const met = selectedMet !== undefined ? selectedMet : trackingclient.met;

    const scoreLevelColor = getCPIScoreColor(client);
    const trackingColor = trackingclient.isActive ? get(TRACKING_COLORS, opportunity, TRACKING_COLORS.NA) : '#fff';
    const importanceImage = getImportanceImage(factsheet.importance);
    const portfolioImage = client.inactiveUser
      ? client.connectionRequestId
        ? PORTFOLIO_IMAGES.PENDING
        : PORTFOLIO_IMAGES.INACTIVE
      : connection
        ? client.passwordChangeKey
          ? PORTFOLIO_IMAGES.PENDING
          : PORTFOLIO_IMAGES.CONNECTED
        : PORTFOLIO_IMAGES.DISCONNECTED;
    // const inactiveEmail = isInactiveEmail(client);

    // const phoneNumber = factsheet.phoneNumber
    //   ? factsheet.phoneNumber
    //   : !isDisconnected
    //   ? get(client, 'phoneNumber')
    //   : undefined;
    // const whatsapp = factsheet.whatsapp ? factsheet.whatsapp : !isDisconnected ? get(client, 'whatsapp') : undefined;
    const lastMeetingDate = getLastMeetingDate(client);
    const lastMeetingDuration = getLastMeetingMonthsDays(lastMeetingDate);
    const lastMeetingColorBar = getLastMeetingColorBar(lastMeetingDuration);

    const dob = factsheet.dob ? factsheet.dob : !isDisconnected ? get(client, 'dob') : undefined;
    const dobMoment = dob ? moment(dob) : undefined;
    const connectionStatus = getClientConnectionStatus(client);
    const connectionStatusImage = client.consent
      ? CONNECTION_STATUS_IMAGES.CONSENT
      : isDisconnected
        ? null
        : CONNECTION_STATUS_IMAGES.NO_CONSENT;
    const constellation = getConstellation(dobMoment);

    const useActiveImage = connection || client.inactiveUser || client.passwordChangeKey;
    const policyAddImage = useActiveImage ? POLICY_ADD_IMAGE.ACTIVE : POLICY_ADD_IMAGE.INACTIVE;
    const analysisImage = useActiveImage ? ANALYSIS_IMAGE.ACTIVE : ANALYSIS_IMAGE.INACTIVE;
    const reminderImage = useActiveImage ? REMINDER_IMAGE.ACTIVE : REMINDER_IMAGE.INACTIVE;
    const exportImage = useActiveImage ? EXPORT_IMAGE.ACTIVE : EXPORT_IMAGE.INACTIVE;
    const existingClientImage = !!factsheet.existingClient
      ? factsheet.existingClient === 'Yes'
        ? EXISTING_CLIENT_IMAGE.YES
        : EXISTING_CLIENT_IMAGE.NO
      : undefined;

    const isProUltra = isSubscriptionAllow(subscriptions, { type: 'core', nicknames: ['Pro', 'Ultra'] });
    const formattedExpanded = !hideDetails ? (expanded !== undefined ? expanded : undefined) : false;
    const msgOpt = factsheet.msgOpt ? factsheet.msgOpt : false;

    const tags = _.get(client, 'factsheetId.tags', []);

    const { lifeScore, ciScore, savingScore, medicalScore, peerScore } = getClientAnalysisScores(
      client,
      _.get(userDetails, 'currency', 'HKD'),
      figure
    );

    const showChatGPT = shouldEnableChatGPT({ user: userDetails, teamCampaignCodes: teamCampaignCodes });
    const showChatGPTTest = shouldEnableChatGPTTest({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

    return (
      <>
        <EditFamilyDialog
          intl={intl}
          open={this.state.editFamilyDialogOpen}
          closeDialog={() => this.setState({ editFamilyDialogOpen: false })}
          selectedFamilies={this.state.families}
          savedFamilies={this.state.savedFamilies}
          setSelectedFamilies={families => this.setState({ families: families })}
          saveFactSheet={this.updateFamilies}
          refreshData={this.props.refreshData}
          manageOnly={false}
        />

        <ExpansionPanel
          className={`client-entry client-list-item ${formattedExpanded ? 'expanded' : ''}`}
          style={{
            borderLeftColor: scoreLevelColor,
            borderRightColor: trackingColor,
            opacity: expanded ? 1 : selectedClient ? 0.5 : 1
          }}
          expanded={formattedExpanded}
          onChange={(event, expanded) => {
            updateViewingClient(expanded ? client : undefined);
            mergeDialog('trackDiscardWarning', { triggered: true });
            if (onChange) {
              onChange(event, expanded);
            }
          }}
        >
          <ExpansionPanelSummary
            aria-label="Expand client entry"
            className="summary"
            style={{ borderLeftColor: scoreLevelColor, borderRightColor: trackingColor }}
            expandIcon={!hideDetails ? <ExpandMoreIcon color="primary" /> : undefined}
          >
            {summaryPrepend && summaryPrepend}
            {clientState === 'ClientToSend' && (
              <Checkbox
                style={{ paddingLeft: 0 }}
                checked={msgOpt}
                onClick={event => {
                  event.stopPropagation();
                }}
                onChange={this.handleMsgOptChange.bind(this, client)}
              />
            )}

            {this.props.action === 'movePolicy' &&
              this.props.client._id !== this.props.movePolicyOptions.clientIdToMoveFrom &&
              (this.props.client.inactiveUser || this.props.isClientConnected) && (
                <Checkbox
                  style={{ paddingLeft: 0 }}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                  onChange={event => this.handleMovePolicy(event, client)}
                />
              )}
            <Grid container className="client__name" alignItems="center">
              <Grid item style={{ whiteSpace: 'pre-wrap' }}>{`${name}${name && nickname && ', '}`}</Grid>
              {nickname && <Grid item>{nickname}</Grid>}
              {nameAppend && <Grid item>{nameAppend}</Grid>}
              {
                factsheet.families?.length === 1 ?
                  <span
                    className='familyLabel'
                    onClick={event => { event.stopPropagation(); applySearchFilter(factsheet.families, false) }}
                  >
                    {factsheet.families[0]}
                  </span>
                  :
                  factsheet.families?.length > 1 ?
                    <span
                      className='familyLabel'
                      onClick={event => { event.stopPropagation(); applySearchFilter(factsheet.families, false) }}
                    >
                      {intl.formatMessage({ id: 'families-count' }, { count: factsheet.families.length })}
                    </span>
                    :
                    null
              }
            </Grid>

            <FormControl>
              <div
                className="all__icons--wrapper"
                id="my-helper-text"
                style={{ opacity: expanded ? 1 : selectedClient ? 0.5 : 1 }}
              >
                <div className="leftpull">
                  <Grid container justify={showDetails ? 'flex-start' : 'flex-end'} wrap="nowrap">
                    <Grid item style={!importanceImage ? { visibility: 'hidden' } : undefined}>
                      {importanceImage ? (
                        <img alt="vip" src={importanceImage} />
                      ) : (
                        <div style={{ width: 20, height: 20, margin: '0 6px' }} />
                      )}
                    </Grid>
                    <Grid item style={!existingClientImage ? { visibility: 'hidden' } : undefined}>
                      {existingClientImage ? (
                        <img alt="existing-client" src={existingClientImage} />
                      ) : (
                        <div style={{ width: 20, height: 20, margin: '0 6px' }} />
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className="rightpull" onClick={event => event.stopPropagation()}>
                  {customActions || (
                    <div>
                      <div>
                        <ContactButton
                          active={connection || client.passwordChangeKey || client.inactiveUser}
                          onClick={this.contact.bind(this, client)}
                          noContactIconClass={true}
                          msgOptButton={true}
                          msgOpt={msgOpt}
                        />
                      </div>
                      {showDetails && (
                        <div onClick={!isDisconnected ? this.portfolio.bind(this, client) : undefined}>
                          <img alt="portfolio" src={portfolioImage} />
                        </div>
                      )}
                      <div onClick={this.smartIdea.bind(this, client)}>
                        <img alt="smart-idea" src={isProUltra ? SMART_IDEA_IMAGE : SMART_IDEA_LITE_IMAGE} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </FormControl>
          </ExpansionPanelSummary>

          {!hideDetails && (
            <ExpansionPanelDetails className="details collapsedetails" style={{ padding: 12 }}>
              <div className="textSecondary fullwidth__class" style={{ display: 'flex', flexDirection: 'column' }}>
                {!showDetails && (
                  <ClientExpansionPortfolio
                    isDisconnected={isDisconnected}
                    portfolioImage={portfolioImage}
                    policyAddImage={policyAddImage}
                    exportImage={exportImage}
                    analysisImage={analysisImage}
                    client={client}
                    portfolioOnClick={!isDisconnected ? this.portfolio.bind(this, client) : undefined}
                    addPolicyOnClick={this.addPortfolio.bind(this, client)}
                    exportOnClick={!isDisconnected ? this.export.bind(this, client) : undefined}
                    analysisOnClick={!isDisconnected ? this.analysis.bind(this, client) : undefined}
                    style={{
                      padding: 4,
                      marginBottom: 10,
                      marginRight: -4
                    }}
                  />
                )}

                <Grid container direction="row" alignItems="stretch" spacing={2}>
                  <Grid item style={{ height: 'auto' }} xs={showDetails ? 7 : 6}>
                    <Grid
                      container
                      className="collapsedown__left client-border-second"
                      direction="row"
                      justify={showDetails ? 'flex-start' : 'space-between'}
                      spacing={2}
                      style={{ width: '100%', height: '100%', margin: 0 }}
                    >
                      <Grid
                        item
                        xs={showDetails ? 6 : 12}
                        style={{ position: 'relative', width: '100%' }}
                        onClick={!isDisconnected ? this.analysisOverall : undefined}
                      >
                        {(expanded === undefined || expanded === true) && (
                          <OverallRadar
                            ci={ciScore}
                            saving={savingScore}
                            medical={medicalScore}
                            peer={peerScore}
                            life={lifeScore}
                            shortLabel={true}
                            labelSize={10}
                            padding={0}
                            maintainAspectRatio={false}
                            // width="100%"
                            height={112}
                          />
                        )}
                      </Grid>

                      {showDetails && (
                        <Grid item xs={6}>
                          <div style={{ display: "flex", marginBottom: 5 }}>
                            <span style={{ fontSize: 18, marginRight: 2 }}>🏷️</span>
                            <TagsControl tags={tags} style={{ margin: 0 }} onChange={this.updateTags} addTagButton={true} />
                          </div>
                          <span>
                            <span style={{ fontSize: 16, marginRight: 3 }}>🏠</span>
                            {
                              factsheet.families?.length > 0 ?
                                factsheet.families.map((family, index) => (
                                  <span
                                    className='familyTag'
                                    key={index}
                                    onClick={() => this.setState({ editFamilyDialogOpen: true })}
                                  >
                                    {family}
                                  </span>
                                ))
                                :
                                <span
                                  className='familyTag'
                                  onClick={() => this.setState({ editFamilyDialogOpen: true })}
                                >
                                  + {intl.formatMessage({ id: "family" })}
                                </span>
                            }
                          </span>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justify={showDetails ? 'flex-start' : 'space-between'}
                          alignItems="center"
                          spacing={0}
                          className={`even-margin ${dob ? 'link' : ''}`}
                          onClick={dob ? this.birthdayGreet.bind(this, client) : undefined}
                        // style={{ paddingTop: 0 }}
                        >
                          <Grid item xs={showDetails ? 6 : undefined}>
                            <Grid container direction="row" justify="flex-start" alignItems="center">
                              <Grid item>
                                <img alt="" src={BIRTHDAY_IMAGE} style={{ height: '20px' }} />
                              </Grid>
                              <Grid
                                item
                                style={{
                                  verticalAlign: 'middle',
                                  color: '#00000080'
                                }}
                              >
                                <span>&nbsp;{dobMoment ? dobMoment.format('DD-MMM') : '-'}</span>
                                {dobMoment && <span>&nbsp;({moment().diff(dobMoment, 'years')})</span>}
                              </Grid>
                            </Grid>
                          </Grid>
                          {constellation && (
                            <Grid item xs={showDetails ? 6 : undefined}>
                              &nbsp;
                              <img alt="constellation" src={constellation.image} height={20} />
                              {showDetails && (
                                <span
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#00000080',
                                    lineHeight: 'normal',
                                    marginLeft: '3px'
                                  }}
                                >
                                  &nbsp;
                                  {intl.formatMessage({ id: constellation.descIntl })}
                                </span>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justify={showDetails ? 'flex-start' : 'space-between'} spacing={0}>
                          <Grid
                            item
                            xs={showDetails ? 6 : undefined}
                            className="link last-meeting"
                            onClick={this.lastMeetingHint.bind(this)}
                            style={{
                              verticalAlign: 'middle',
                              color: '#00000080'
                            }}
                          >
                            <div style={{ display: 'inline-flex', height: 'inherit' }}>
                              <img alt="last-meeting" src={LAST_MEETING_IMAGE} style={{ height: 20 }} />
                              &nbsp;
                              <ColoredProgress
                                color={lastMeetingColorBar.color}
                                percentage={lastMeetingColorBar.width}
                                maxWidth="80px"
                                style={{ height: 15, width: 50, margin: '2.8px 0' }}
                              />
                              {showDetails && <span>&nbsp;{formatLastMeetingDuration(intl, lastMeetingDuration)}</span>}
                            </div>
                          </Grid>

                          <Grid item xs={showDetails ? 6 : undefined}>
                            <div className="link" onClick={this.DISC.bind(this, client)}>
                              {factsheet.personality ? (
                                <Button
                                  className="portfolio-default-button client-disc-btn button-line-height"
                                  variant="contained"
                                  style={{
                                    backgroundColor: get(DISC_COLORS, factsheet.personality, ''),
                                    margin: '0'
                                  }}
                                >
                                  {intl.formatMessage({ id: `personality_${factsheet.personality}` })}
                                </Button>
                              ) : (
                                <img alt="disc" style={{ marginRight: '4px' }} src={DISC_UNSET_IMAGE} height={20} />
                              )}
                              {showDetails && (
                                <span
                                  style={{
                                    verticalAlign: 'middle',
                                    color: '#00000080',
                                    lineHeight: 'normal',
                                    marginLeft: '3px'
                                  }}
                                >
                                  &nbsp;
                                  {intl.formatMessage({
                                    id: `DISC-short-${factsheet.personality ? factsheet.personality : 'no'}`
                                  })}
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/*<div className="even-margin">*/}
                      {/*  <Button*/}
                      {/*    onClick={this.analysis.bind(this, client, 'qdap')}*/}
                      {/*    variant="contained"*/}
                      {/*    color="primary"*/}
                      {/*    className="dark-green action-button small"*/}
                      {/*    disabled={isDisconnected}*/}
                      {/*  >*/}
                      {/*    {intl.formatMessage({ id: 'QDAP' })}*/}
                      {/*  </Button>*/}

                      {/*  <Button*/}
                      {/*    onClick={this.analysis.bind(this, client, 'vhis')}*/}
                      {/*    variant="contained"*/}
                      {/*    color="primary"*/}
                      {/*    style={{ marginLeft: '6px' }}*/}
                      {/*    className="light-green action-button small"*/}
                      {/*    disabled={isDisconnected}*/}
                      {/*  >*/}
                      {/*    {intl.formatMessage({ id: 'VHIS' })}*/}
                      {/*  </Button>*/}
                      {/*</div>*/}
                    </Grid>
                  </Grid>

                  <Grid item xs={showDetails ? 5 : 6} style={{ height: 'auto', padding: '8px 0' }}>
                    <Grid container spacing={2} direction="column" style={{ width: '100%', height: '100%', margin: 0 }}>
                      {showDetails && (
                        <Grid item style={{ padding: 4, wordBreak: 'break-word' }}>
                          <ClientExpansionPortfolio
                            isDisconnected={isDisconnected}
                            portfolioImage={portfolioImage}
                            policyAddImage={policyAddImage}
                            exportImage={exportImage}
                            analysisImage={analysisImage}
                            client={client}
                            portfolioOnClick={!isDisconnected ? this.portfolio.bind(this, client) : undefined}
                            addPolicyOnClick={this.addPortfolio.bind(this, client)}
                            exportOnClick={!isDisconnected ? this.export.bind(this, client) : undefined}
                            analysisOnClick={!isDisconnected ? this.analysis.bind(this, client) : undefined}
                            showDetails={showDetails}
                          />
                        </Grid>
                      )}

                      <Grid item style={{ flexGrow: 1, padding: '0 4px', display: 'flex' }}>
                        <div
                          className="client-border-third"
                          style={{ height: 'auto', width: '100%', padding: 4, display: 'flex' }}
                        >
                          <Grid
                            container
                            item
                            direction="row"
                            justify={showDetails ? 'flex-start' : 'space-between'}
                            alignItems="center"
                            spacing={0}
                            style={{ height: '100%', paddingTop: '10px' }}
                          >
                            {/* 客戶資料 */}
                            <Grid
                              item
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              xs={showDetails ? 3 : 6}
                              className={!isDisconnected ? 'link' : 'clientNameHidden'}
                              onClick={e => {
                                this.factsheet(client, e);
                                tracking('Click on Factsheet - Client');
                              }}
                            // onClick={this.factsheet.bind(this, client)}
                            >
                              <Grid item className="client-expand-img-wrapper">
                                <img alt="factsheet" src={FACTSHEET_IMAGE} className="client-expand-img" />
                              </Grid>

                              <Grid item xs>
                                <Button className="action-button adviser-action">
                                  {intl.formatMessage({ id: 'Factsheet' })}
                                </Button>
                              </Grid>
                            </Grid>

                            {/* 保單PD */}
                            <Grid
                              item
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              xs={showDetails ? 3 : 6}
                              className={!isDisconnected ? 'link' : 'clientNameHidden'}
                              onClick={e => {
                                // this.smartIdea.bind(this,client)
                                this.smartIdea(client, e);
                                tracking('Click on Smart Ideas - Client');
                              }}
                            >
                              <Grid item className="client-expand-img-wrapper">
                                {/* <Badge
                                className="button-text-badge"
                                variant="dot"
                                color="error"
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              > */}
                                <img
                                  alt="smart-idea"
                                  src={isProUltra ? SMART_IDEA_IMAGE : SMART_IDEA_LITE_IMAGE}
                                  className="client-expand-img"
                                />
                                {/* </Badge> */}
                              </Grid>

                              <Grid item xs>
                                <Button className="action-button adviser-action">
                                  {intl.formatMessage({ id: 'client-summary' })}
                                </Button>
                              </Grid>
                            </Grid>

                            {/* 會面筆記 */}
                            <Grid
                              item
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              xs={showDetails ? 3 : 6}
                              className={!isDisconnected ? 'link' : 'clientNameHidden'}
                              onClick={e => {
                                // this.meetingNote.bind(this)
                                this.meetingNote(e);
                                tracking('Click on Meeting Notes - Client');
                              }}
                            >
                              <Grid item className="client-expand-img-wrapper">
                                <Badge
                                  className="button-text-badge"
                                  variant="dot"
                                  color="error"
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                  }}
                                >
                                  <img alt="" src={REMIND_ME_IMAGE} className="client-expand-img" />
                                </Badge>
                              </Grid>

                              <Grid item xs>
                                <Button className="action-button adviser-action">
                                  {intl.formatMessage({ id: 'meetingNotes' })}
                                </Button>
                              </Grid>
                            </Grid>

                            {/* 進度跟進 */}
                            <Grid
                              item
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              xs={showDetails ? 3 : 6}
                              className={!isDisconnected ? 'link' : 'clientNameHidden'}
                              onClick={e => {
                                this.toggleTracking(client, e);
                                tracking('Click on Follow Up - Client');
                              }}
                            >
                              <Grid item className="client-expand-img-wrapper">
                                <img
                                  alt="tracking"
                                  src={
                                    !isDisconnected
                                      ? trackingclient && trackingclient.isActive
                                        ? TRACKING_IMAGE.ON
                                        : TRACKING_IMAGE.ACTIVE
                                      : TRACKING_IMAGE.INACTIVE
                                  }
                                  className="client-expand-img"
                                />
                              </Grid>

                              <Grid item xs>
                                <Button className="action-button adviser-action">
                                  {!isDisconnected && trackingclient && trackingclient.isActive
                                    ? intl.formatMessage({ id: 'Track Biz On' })
                                    : intl.formatMessage({ id: 'Track Biz' })}
                                </Button>
                              </Grid>
                            </Grid>

                            {/* 跟進中 */}
                            <Grid
                              item
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              xs={showDetails ? 3 : 6}
                              // style={{ minHeight: '58.3px' }}
                              className={!isDisconnected ? 'link' : 'clientNameHidden'}
                              onClick={e => {
                                tracking('Click on Connection - Client');
                                !isDisconnected ? this.connection(client, e) : undefined;
                              }}
                            // onClick={!isDisconnected ? this.connection.bind(this, client) : undefined}
                            >
                              <Grid item>
                                <span>
                                  <Badge
                                    invisible={connectionStatus !== 'Inactive'}
                                    variant="dot"
                                    color="error"
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right'
                                    }}
                                  >
                                    {connectionStatus === 'Connected' || connectionStatus === 'Disconnected' ? (
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        className="action-button"
                                        disabled={isDisconnected}
                                      >
                                        {intl.formatMessage({ id: connectionStatus })}
                                      </Button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className={`action-button connect-${_.lowerCase(connectionStatus)}`}
                                        disabled={isDisconnected}
                                      >
                                        {intl.formatMessage({ id: connectionStatus })}
                                      </Button>
                                    )}
                                  </Badge>
                                </span>
                              </Grid>
                            </Grid>

                            {/* For filling */}
                            {showDetails && (
                              <Fragment>
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  justify="center"
                                  alignItems="center"
                                  xs={3}
                                // style={{ minHeight: '58.3px' }}
                                >
                                  <Grid item className="client-expand-img-wrapper">
                                    <ContactButton
                                      active={connection || client.passwordChangeKey || client.inactiveUser}
                                      onClick={this.contact.bind(this, client)}
                                      noContactIconClass={true}
                                      msgOptButton={true}
                                      msgOpt={msgOpt}
                                      customizeClassName="client-expand-img-contact-btn"
                                    />
                                  </Grid>
                                </Grid>
                                {!showChatGPT && (
                                  <Grid
                                    item
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    xs={3}
                                  // style={{ minHeight: '58.3px' }}
                                  >
                                    {/* <Grid item></Grid> */}
                                  </Grid>
                                )}
                              </Fragment>
                            )}

                            {/* ChatGPT */}
                            {showChatGPT && showDetails && (
                              <Grid item xs={3}>
                                <Grid container justify="center">
                                  <Grid item>
                                    <IconButton color="primary" size="small" onClick={this.chatGPT}>
                                      <img src="img/gptmain.svg" alt="chatgpt" width={22} height={22} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}

                            {/* 跟進中 */}
                            <Grid
                              item
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              xs={showDetails ? 3 : 6}
                            // style={{ minHeight: '58.3px' }}
                            >
                              <Grid item className="client-page-expansion-dot">
                                <Button
                                  buttonRef={node => {
                                    this.anchorEl = node;
                                  }}
                                  aria-owns={open ? 'menu-list-grow' : undefined}
                                  aria-haspopup="true"
                                  onClick={this.handleToggle}
                                >
                                  <MoreHorizIcon color="primary" className="client-page-expansion-dot-btn" />
                                </Button>

                                <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                                  {({ TransitionProps, placement }) => (
                                    <Zoom
                                      {...TransitionProps}
                                      id="menu-list-grow"
                                      style={{ transformOrigin: 'center top' }}
                                    >
                                      <Paper>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                          <MenuList>
                                            {!showDetails && (
                                              <MenuItem
                                                onClick={this.contact.bind(this, client)}
                                                className="client-expand-img-wrapper"
                                              >
                                                <ContactButton
                                                  active={connection || client.passwordChangeKey || client.inactiveUser}
                                                  onClick={this.contact.bind(this, client)}
                                                  noContactIconClass={true}
                                                  msgOptButton={true}
                                                  msgOpt={msgOpt}
                                                  customizeClassName="client-expand-img-contact-btn"
                                                  className="client-expand-img-contact-span"
                                                />
                                                {intl.formatMessage({ id: 'Contact' })}
                                              </MenuItem>
                                            )}

                                            <MenuItem
                                              onClick={!isDisconnected ? this.reminder.bind(this, client) : undefined}
                                              className="client-expand-img-wrapper"
                                            >
                                              <img alt="" src={reminderImage} className="client-expand-img  mb-0 mr-5" />
                                              {intl.formatMessage({ id: 'premium-reminder-short' })}{' '}
                                              {!isProUltra && <span className="pro-text"> PRO</span>}
                                            </MenuItem>

                                            <MenuItem
                                              onClick={!isDisconnected ? this.getUsers.bind(this, client) : undefined}
                                              className="client-expand-img-wrapper"
                                            >
                                              <img
                                                alt="referral"
                                                src={!isDisconnected ? REFERRAL_IMAGE.ACTIVE : REFERRAL_IMAGE.INACTIVE}
                                                className="client-expand-img  mb-0 mr-5"
                                              />
                                              {intl.formatMessage({ id: 'Ask Referral' })}{' '}
                                              {!isProUltra && <span className="pro-text"> PRO</span>}
                                            </MenuItem>

                                            {showChatGPT && (
                                              <MenuItem onClick={this.chatGPT} className="client-expand-img-wrapper">
                                                <img
                                                  alt="chatgpt"
                                                  src="img/gptmain.svg"
                                                  className="client-expand-img  mb-0 mr-5"
                                                />
                                                {intl.formatMessage({ id: 'chat-gpt' })}
                                              </MenuItem>
                                            )}

                                            {showChatGPTTest && (
                                              <MenuItem onClick={this.chatGPTTest} className="client-expand-img-wrapper">
                                                <img
                                                  alt="chatgpt"
                                                  src="img/gptmain.svg"
                                                  className="client-expand-img  mb-0 mr-5"
                                                />
                                                {intl.formatMessage({ id: 'chat-gpt' })} Test
                                              </MenuItem>
                                            )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Zoom>
                                  )}
                                </Popper>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
          )}

          {trackingclient && trackingclient.isActive && (
            <ExpansionPanelActions style={{ display: 'block', padding: 0 }}>
              <div
                className={'client-tracking-item editlisting'}
                style={{
                  borderRightColor: trackingColor,
                  backgroundColor: trackingclient.opportunity ? `${trackingColor}28` : '#3966f828'
                }}
              >
                <Grid className="clientDetail" container>
                  <Grid item xs={showDetails ? 7 : 6}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Grid item style={{ marginLeft: '6px' }} xs={showDetails ? 6 : undefined}>
                        <Grid container direction="row" justify="flex-start" alignItems="center">
                          <Grid item>
                            <span>{intl.formatMessage({ id: showDetails ? 'Opportunity' : 'Opp' })}</span>
                          </Grid>
                          <Grid item>
                            <OpportunityControl
                              opportunity={opportunity}
                              onChange={this.selectOpportunity.bind(this, client)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {showDetails && (
                        <Grid item>
                          <span style={{ marginLeft: '-8px' }}>{intl.formatMessage({ id: 'Expected-biz' })}</span>
                          <span style={{ color: '#3966f8', marginLeft: '5px' }}>
                            {formatOnlyNumber(trackingclient.businessVol, intl)}
                            {formatOnlyUnit(trackingclient.businessVol, intl)}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={showDetails ? 5 : 6} style={{ height: '100%' }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Grid item style={{ marginLeft: showDetails ? '17px' : '6px' }}>
                        <span>{intl.formatMessage({ id: 'Day' })}</span>
                      </Grid>
                      <Grid item>
                        <span style={{ color: '#3966f8', marginLeft: '5px' }}>
                          {trackingclient.startDate
                            ? moment(moment(), 'YYYY-MM-DD').diff(
                              moment.parseZone(trackingclient.startDate).format('YYYY-MM-DD'),
                              'days'
                            )
                            : 0}
                        </span>
                      </Grid>
                      <Grid item style={{ marginLeft: showDetails ? '30px' : '15px' }}>
                        <span> {intl.formatMessage({ id: 'Met' })}</span>
                      </Grid>
                      <Grid item>
                        <select
                          className="track-met-select link"
                          name="met"
                          value={met}
                          onChange={this.selectMet.bind(this, client)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                      </Grid>

                      <Grid
                        item
                        style={{
                          flexGrow: 1,
                          textAlign: 'right',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <SettingsIcon className="link" color="primary" onClick={this.meetingSummary.bind(this)} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelActions>
          )}
        </ExpansionPanel>
      </>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    selectedClientId: state.assistant.selectedClient ? state.assistant.selectedClient._id : undefined,
    figure: state.figure,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    enableTracking,
    updateFactSheet,
    getClientDetails,
    updateViewingClient
  },
  // mergeProps
  null,
  // options
  {}
)(withRouter(ClientEntry));

export default injectIntl(withTheme(container));
