import React from 'react';
import { injectIntl } from 'react-intl';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { ExpansionPanelDetails, Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { formatNumber, roundNumber } from '../../../../../utils/formatNumber';
import PolicyEntry from '../PolicyEntry/PolicyEntry';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import './PortfolioSubCategory.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  getMedicalPortfolioBestRoom,
  getPolicyMarketValue,
  getPolicyValue,
  getPremiumPeriodValue,
  getSubCategoryIcon,
  isCategory,
  isInsuredNotMePolicy,
  isPaidUpPolicy,
  isPolicyCategory,
  isPolicyMarketValueRelated,
  isPolicySubCategory,
  isSubCategory,
  isSubCategoryMarketValueRelated
} from '../../../../../utils/policy';
import { isWidthExceed, isNavAside } from '../../../../../utils/device';

const LackStyle = {
  marginLeft: 8,
  border: '1px solid #ff0000',
  color: '#ff0000',
  padding: '0 2px',
  borderRadius: 5
};

const LackTypoStyle = {
  fontSize: 10
};

const getCategoryPolicyValue = (policy, currency, infoType, premiumFrequency) => {
  let value;
  switch (infoType) {
    case 'Protection':
      value = getPolicyValue(policy, isPolicySubCategory(policy, 'ci') ? 'sumInsured' : 'lifeCoverage', currency);
      break;
    case 'Premium':
      value = getPremiumPeriodValue(policy, premiumFrequency, currency);
      break;
    case 'MarketValue':
      const marketValue = getPolicyMarketValue(policy, currency);
      value = marketValue === undefined ? 'N/A' : marketValue;
      break;
    default:
      value = 0;
  }

  return value;
};

const getSubCategoryValue = (subCategory, infoType, premiumFrequency) => {
  switch (infoType) {
    case 'Protection':
      return _.get(subCategory, isSubCategory(subCategory._id, 'ci') ? 'ciCoverage' : 'lifeCoverage', 0);
    case 'Premium':
      return _.get(subCategory, premiumFrequency === 'Monthly' ? 'monthlyPremium' : 'yearlyPremium', 0);
    case 'MarketValue':
      return _.get(subCategory, 'marketValue', 0);
    default:
      return 0;
  }
};

const formatSummaryContent = (intl, currency, infoType, premiumFrequency, subCategory, category) => {
  if (subCategory.policies.length > 0) {
    if (isSubCategory(subCategory._id, 'medical') && infoType === 'Protection') {
      return `${intl.formatMessage({ id: getMedicalPortfolioBestRoom(subCategory.policies) })}`;
    } else {
      const categoryValue = getSubCategoryValue(subCategory, infoType, premiumFrequency);

      if (infoType === 'MarketValue' && !isSubCategoryMarketValueRelated(category._id, subCategory._id)) {
        return 'N/A';
      } else {
        return `${intl.formatMessage({ id: currency })} ${categoryValue ? formatNumber(categoryValue, intl) : 0}`;
      }
    }
  } else {
    return '';
  }
};

const isHavePolicy = policies => {
  return policies.length > 0;
};
const PortfolioSubCategory = props => {
  const {
    intl,
    category,
    currency,
    color,
    subCategory,
    infoType,
    premiumFrequency,
    onClickPolicy,
    expandCategory,
    addPolicy,
    ...rest
  } = props;

  const categoryValue = getSubCategoryValue(subCategory, infoType, premiumFrequency);
  const policies = _.get(subCategory, 'policies', []);
  const havePolicy = isHavePolicy(policies);

  const onClick = (event, expanded) => (havePolicy ? expandCategory(event, expanded, subCategory) : addPolicy());
  const icon = getSubCategoryIcon(subCategory, havePolicy);
  const shouldShowLack =
    !havePolicy &&
    !isCategory(category._id, 'company') &&
    isSubCategory(subCategory._id, ['life', 'ci', 'medical', 'saving', 'accident']);

  return subCategory ? (
    <ExpansionPanel
      className="portfolio-subcategory"
      onClick={onClick}
      {...rest}
      style={{ borderLeft: `4px solid ${havePolicy ? color : '#D3DCF2'}` }}
    >
      <ExpansionPanelSummary
        className="summary"
        expandIcon={havePolicy ? <ExpandMoreIcon color="primary" /> : <AddIcon color="primary" />}
        style={{ borderLeft: `3px solid ${havePolicy ? color : '#D3DCF2'}` }}
      >
        <Grid container>
          <Grid item xs={havePolicy ? 6 : 12}>
            <Grid container alignItems="center">
              <Grid item style={{ marginRight: 8 }}>
                <div className="icon" style={{ color: color }}>
                  {icon && icon}
                </div>
              </Grid>
              <Grid item>
                <span style={{ opacity: `${!havePolicy ? '0.6' : '1'}` }}>
                  {intl.formatMessage({ id: subCategory.title })}
                </span>
              </Grid>
              {shouldShowLack && (
                <Grid item style={LackStyle}>
                  <Typography color="inherit" style={LackTypoStyle}>
                    {intl.formatMessage({ id: 'pr-lacks' })}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {havePolicy && (
            <Grid item xs={6}>
              <span>{formatSummaryContent(intl, currency, infoType, premiumFrequency, subCategory, category)}</span>
            </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="details">
        <List component="div">
          {havePolicy ? (
            policies.map(policy => (
              <ListItem key={policy._id} component="div" button={!!onClickPolicy}>
                <PolicyEntry
                  policy={policy}
                  premiumFrequency={premiumFrequency}
                  currency={currency}
                  infoType={infoType}
                  onClick={onClickPolicy}
                  formatValue={policy => getCategoryPolicyValue(policy, currency, infoType, premiumFrequency)}
                  percentage={(policy, policyAmount) => {
                    if (policyAmount === 'N/A') {
                      return policyAmount;
                    }
                    let percentage = 0;
                    if (
                      (infoType === 'Protection' && !isInsuredNotMePolicy(policy)) ||
                      (infoType === 'Premium' && !isPaidUpPolicy(policy)) ||
                      (infoType === 'MarketValue' && isPolicyMarketValueRelated(policy))
                    ) {
                      percentage = categoryValue > 0 ? (policyAmount / categoryValue) * 100 : 0;
                    }
                    return `${roundNumber(percentage, 1)}%`;
                  }}
                  showPolicyName={isWidthExceed(768)}
                  showPolicyNumber={isWidthExceed(1180) || (!isNavAside() && isWidthExceed(768))}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <div className="text-center" style={{ width: '100%' }}>
                {intl.formatMessage({ id: 'No Policy' })}
              </div>
            </ListItem>
          )}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ) : null;
};

export default injectIntl(PortfolioSubCategory);
