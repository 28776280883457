/* eslint no-magic-numbers: 0 */
import MobileDetect from 'mobile-detect';
// import ScreenFull from 'screenfull';

// if (ScreenFull.enabled) {
//   ScreenFull.request();
// }

const detectWebView = () => {
  const isUIwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);

  if (isUIwebview) {
    return true;
  } else {
    return false;
  }
};

const mobileDetector = new MobileDetect(window.navigator.userAgent);

export default {
  md: mobileDetector,
  isMobile: !!mobileDetector.mobile(),
  isWebView: detectWebView(),
  LocalStorageKeys: {
    Authorization: 'Authorization',
    Credentials: 'credentials'
  }
  // DefaultLocation: {
  //   lat: 34.05,
  //   lng: -118.24
  // }
};

export function timeToDate(time) {
  // time: String returns Date()
  if (time && typeof time === 'string') {
    const aryElements = time.split(':');
    if (aryElements.length > 0) {
      const hr = parseInt(aryElements[0]);
      const min = parseInt(aryElements[1]);
      const date = new Date();
      date.setHours(hr);
      date.setMinutes(min);
      return date;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function dateToTime(date) {
  // time: String returns Date()
  if (!isDate(date)) {
    return null;
  }

  const hr = date.getHours();
  const min = date.getMinutes();

  return `${hr < 10 ? 0 : ''}${hr}:${min < 10 ? 0 : ''}${min}`;
}

export function isDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (isNaN(d.getTime())) {
      // d.valueOf() could also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
