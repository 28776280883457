import React, { useState, useEffect } from 'react';
import { RadioGroup, Radio, FormControl, FormControlLabel, Button, Grid, Typography } from '@material-ui/core';
import Modal from 'react-modal';
import numbo from '../../../../utils/numbo';
import AccessCodeGate from '../../../../components/AccessCodeGate/AccessCodeGate'; // add support of Simplified Chinese
import CreateIcon from '@material-ui/icons/Create';
import './chequeOut.css';
import { insurerRegionFilter } from '../../../../utils/region';
import EN_TRANSLATION from './../../../../store/locale/messages/en.json';
import ZH_TRANSLATION from './../../../../store/locale/messages/zh.json';
import ZH_HANT_HK_TRANSLATION from './../../../../store/locale/messages/zh-Hant-HK.json';
import ShareButtonGroup from '../../../../components/ShareButtonGroup/ShareButtonGroup';
import NumberFormatTextField from '../../../../components/NumberFormatTextField/NumberFormatTextField';
import { LOADING } from '../../../../constants/phase';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import { sanitize } from 'dompurify';
import _ from 'lodash';
import { LANGUAGE } from '../../../../constants/language';
import { isInsurerOther, isInsurerWhatIf } from '../../../../utils/insurer';
import { usePrevious } from '../../../../utils/hooks';

numbo.zhTW = require('../../../../utils/numbo/numbo.zhTW'); // add support of Traditional Chinese
numbo.zhCN = require('../../../../utils/numbo/numbo.zhCN');

const NUMBO_LANGUAGE = {
  [LANGUAGE.EN]: 'en',
  [LANGUAGE.ZH]: 'zhCN',
  [LANGUAGE.ZH_HANT_HK]: 'zhTW'
};

const ChequeOutView = props => {
  const {
    intl,
    location,
    userDetails,
    getPhase,
    chequeWizard,
    updatePhase,
    insurers,
    renderHeaderButton,
    getChequeWizard,
    updateChequeWizard,
    resetChequeWizard
  } = props;

  const [cashValue, setCashValue] = useState('');
  const [cashValueOut, setCashValueOut] = useState('');
  const [chequeLanguage, setChequeLanguage] = useState(LANGUAGE.EN);
  const [insurerModalOpen, setInsurerModalOpen] = useState(false);
  const [selectedChequeInsurerId, setSelectedChequeInsurerId] = useState('59b0f0a97af33f1d6074cbfd');
  const [fontSize, setFontSize] = useState(25);
  const [chequeLanguageEdit, setChequeLanguageEdit] = useState(false);
  const [otherPayeeName, setOtherPayeeName] = useState('');
  const [accessCode, setAccessCode] = useState('');

  const prevGetPhase = usePrevious(getPhase);

  useEffect(() => {
    return () => {
      resetChequeWizard();
    };
  }, [resetChequeWizard]);

  useEffect(() => {
    renderHeaderButton(false);
  }, [renderHeaderButton]);

  useEffect(() => {
    if (userDetails && userDetails.language && !chequeLanguageEdit) {
      setChequeLanguage(userDetails.language);
    }
  }, [chequeLanguageEdit, userDetails]);

  // set selected insurer according location state
  useEffect(() => {
    const { insurer } = location.state || {};
    if (insurer) {
      setSelectedChequeInsurerId(insurer._id);
    } else {
      getChequeWizard();
    }
  }, [location, getChequeWizard]);

  useEffect(() => {
    const { insurerId, insurerName } = chequeWizard || {};
    if (getPhase === true && prevGetPhase === LOADING) {
      // not other insurer
      if (insurerId !== '5a1be0f9dfaa60227838e426') {
        const insurer = (insurers || []).find(insurer => insurer._id === insurerId);
        if (insurer) {
          setSelectedChequeInsurerId(insurer._id);
        }
      } else {
        setSelectedChequeInsurerId(insurerId);
        setOtherPayeeName(insurerName);
      }
    }
  }, [chequeWizard, getPhase, insurers, prevGetPhase]);

  useEffect(() => {
    const cashValueStr = (cashValue || '').toString();
    let cashValueOut;

    const numboLanguage = NUMBO_LANGUAGE[chequeLanguage];

    if (chequeLanguage === LANGUAGE.EN) {
      if (cashValueStr && cashValueStr.indexOf('.') === -1) {
        cashValueOut = numbo.convert(cashValueStr, 'check').split('and No Cent Only')[0] + 'Only';
      } else {
        cashValueOut = cashValueStr ? numbo.convert(cashValueStr, 'check') : '';
      }
    } else {
      cashValueOut = cashValueStr ? numbo.convert(cashValueStr, numboLanguage, 'check') : '';
    }
    setCashValueOut(cashValueOut);
  }, [chequeLanguage, cashValue]);

  const handleChangeCashValue = event => {
    setCashValue(event.target.value);
  };

  const handleChequeLanguageChange = event => {
    setChequeLanguage(event.target.value);
    setChequeLanguageEdit(true);
  };

  const handleInsurerModalOpen = () => {
    setInsurerModalOpen(true);
  };

  const handleInsurerModalClose = () => {
    setInsurerModalOpen(false);
  };

  const handleInsurerEdit = insurer => {
    setSelectedChequeInsurerId(insurer._id);
    handleInsurerModalClose();
    updateChequeWizard({
      insurerId: insurer._id,
      insurerName: insurer._id === '5a1be0f9dfaa60227838e426' ? otherPayeeName : ''
    });
  };

  const formatMessage = () => {
    let translation;
    switch (chequeLanguage) {
      case LANGUAGE.ZH_HANT_HK:
        translation = ZH_HANT_HK_TRANSLATION;
        break;
      case LANGUAGE.ZH:
        translation = ZH_TRANSLATION;
        break;
      default:
        translation = EN_TRANSLATION;
    }
    // currency formatter
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    let payee = translation.messages['Payee'];
    let amount = translation.messages['Amount'];

    const selectedInsurer = insurers.find(insurer => insurer._id === selectedChequeInsurerId);

    // hard code payee name for 'convoy'
    let payeeName =
      selectedChequeInsurerId === 'convoy'
        ? 'Kerberos (Nominee) Limited - Client Account'
        : _.get(selectedInsurer, `payeeName.${chequeLanguage}`, '');
    let insurer =
      selectedChequeInsurerId !== '5a1be0f9dfaa60227838e426'
        ? payeeName
          ? payeeName.replace(/<br>/g, '\n')
          : payeeName
        : otherPayeeName;
    let formattedCashValue = formatter.format(cashValue || 0);
    return `${'*' + payee + ':*'} \n${insurer}\n\n${'*' + amount + ':*'} ${formattedCashValue} \n${cashValueOut || ''}`;
  };

  const handleIncreaseFontSize = () => {
    setFontSize(fontSize <= 1 ? 1 : parseInt(fontSize === '0' || !fontSize ? '1' : fontSize) - 1 * 2);
  };

  const handleDecreaseFontSize = () => {
    setFontSize(fontSize >= 37 ? 37 : parseInt(!fontSize ? '0' : fontSize) + (!fontSize ? 0 : 1) * 2);
  };

  const handleChangeOtherPayeeName = event => {
    setOtherPayeeName(event.target.value);
  };

  const updateOtherPayeeName = () => {
    updateChequeWizard({
      insurerId: selectedChequeInsurerId,
      insurerName: selectedChequeInsurerId === '5a1be0f9dfaa60227838e426' ? otherPayeeName : ''
    });
  };

  // get insurer details & filter by region
  const filteredInsurers = (insurers || []).filter(insurerRegionFilter);

  const selectedInsurer = filteredInsurers.find(insurer => insurer._id === selectedChequeInsurerId);

  const getExchangeRate = _.get(selectedInsurer, `exchangeRatePage.${chequeLanguage}`);

  // hard code insurer 'convoy'
  if (accessCode === 'oneteam' || accessCode === 'dynasty' || accessCode === 'apollo') {
    filteredInsurers.push({
      _id: 'convoy',
      title: {
        en: 'CAM',
        zh: 'CAM',
        'zh-Hant-HK': 'CAM'
      },
      payeeName: {
        en: 'Kerberos (Nominee) Limited - Client Account',
        zh: 'Kerberos (Nominee) Limited - Client Account',
        'zh-Hant-HK': 'Kerberos (Nominee) Limited - Client Account'
      },
      image: 'img/insurer_hkconvoy.png'
    });
  }

  if (filteredInsurers.length > 0) {
    var indexOther = filteredInsurers.findIndex(isInsurerOther);
    var indexWhatIf = filteredInsurers.findIndex(isInsurerWhatIf);

    if (indexOther !== -1) {
      var objOther = filteredInsurers[indexOther];
    }

    if (indexWhatIf !== -1) {
    }

    if (indexWhatIf !== -1) {
      filteredInsurers.splice(indexWhatIf, 1);
    }

    if (indexOther !== -1) {
      filteredInsurers.splice(indexOther, 1);
    }

    if (objOther !== undefined) {
      filteredInsurers.push(objOther);
    }

    //filteredInsurers.push(obj1);
  }

  let isGuest = userDetails._id === undefined;

  return (
    <div>
      {(getPhase === LOADING || updatePhase === LOADING) && <LoadingBackdrop />}
      <div id="wrapper">
        <div id="page-content-wrapper">
          <div className="cheque-out">
            <div className="row">
              <div className="nav-top-margin">
                <div className="analysis-expansion">
                  <AccessCodeGate
                    intl={intl}
                    allowed={!isGuest}
                    accessCodes={['oneteam', 'dynasty', 'pfplus', 'apollo']}
                  >
                    <div className="analysis-expansion-element" style={{ maxWidth: 600 }}>
                      <div className="caseclose-form">
                        <form noValidate>
                          <div className="cheque__form--inner">
                            <FormControl>
                              <Grid container justify="space-between" alignItems="center" spacing={1}>
                                <Grid item>
                                  <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                      <Typography>{intl.formatMessage({ id: 'Payee' })}</Typography>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className="edit-button"
                                        onClick={handleInsurerModalOpen}
                                      >
                                        <CreateIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container>
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <Button
                                          color="primary"
                                          disabled={fontSize <= 1}
                                          onClick={handleIncreaseFontSize}
                                          style={{
                                            minWidth: 36,
                                            fontSize: 22
                                          }}
                                        >
                                          -
                                        </Button>
                                      </Grid>
                                      <Grid item style={{ height: 26 }}>
                                        <span>
                                          <img alt="" src="img/fontSize.png" />
                                        </span>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          color="primary"
                                          disabled={fontSize >= 37}
                                          onClick={handleDecreaseFontSize}
                                          style={{
                                            minWidth: 36,
                                            fontSize: 22
                                          }}
                                        >
                                          +
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <div className="form-group">
                                <div className="insure-listing-textarea amount__height">
                                  {selectedChequeInsurerId !== '5a1be0f9dfaa60227838e426' && (
                                    <p
                                      style={{ fontSize: fontSize }}
                                      className="payee__name"
                                      dangerouslySetInnerHTML={{
                                        // hard code payee name of 'convoy'
                                        __html: sanitize(
                                          selectedChequeInsurerId === 'convoy'
                                            ? 'Kerberos (Nominee) Limited - Client Account'
                                            : _.get(selectedInsurer, `payeeName.${chequeLanguage}`)
                                        )
                                      }}
                                    />
                                  )}
                                  {selectedChequeInsurerId === '5a1be0f9dfaa60227838e426' && (
                                    <textarea
                                      type="text"
                                      className="payee__name"
                                      style={{ fontSize: fontSize }}
                                      name={'otherPayeeName'}
                                      value={otherPayeeName}
                                      onChange={handleChangeOtherPayeeName}
                                      required="required"
                                      rows="2"
                                      placeholder={intl.formatMessage({
                                        id: 'Type payee name here'
                                      })}
                                      onBlur={updateOtherPayeeName}
                                    />
                                  )}
                                </div>
                              </div>
                            </FormControl>

                            {cashValue && (
                              <FormControl>
                                <div
                                  className="cash-out--value"
                                  style={{
                                    color: '#3966f8',
                                    fontSize: fontSize
                                  }}
                                >
                                  {cashValue === '' ? '' : cashValueOut}
                                </div>
                              </FormControl>
                            )}

                            <FormControl style={{ marginBottom: 16 }}>
                              <Grid container justify="center" alignItems="flex-end" spacing={2}>
                                <Grid item>
                                  <Typography>{intl.formatMessage({ id: 'Amount' })}:</Typography>
                                </Grid>
                                <Grid item style={{ width: '60%' }}>
                                  <NumberFormatTextField
                                    onChange={handleChangeCashValue}
                                    value={cashValue}
                                    inputProps={{
                                      inputMode: 'decimal',
                                      pattern: '[0-9]*',
                                      style: {
                                        color: '#3966f8',
                                        lineHeight: 1.5,
                                        height: 33,
                                        fontSize: '25px',
                                        fontWeight: 500,
                                        textAlign: 'center'
                                      }
                                    }}
                                  />
                                </Grid>
                                {getExchangeRate && (
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => window.open(getExchangeRate, '_blank')}
                                      style={{
                                        minWidth: 'unset',
                                        padding: 2,
                                        lineHeight: 1
                                      }}
                                    >
                                      {intl.formatMessage({
                                        id: 'forexExchange'
                                      })}
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </FormControl>
                          </div>
                          <FormControl className="language__options">
                            <RadioGroup
                              row
                              value={chequeLanguage}
                              onChange={handleChequeLanguageChange}
                              style={{ margin: 'auto' }}
                            >
                              <FormControlLabel value={LANGUAGE.EN} control={<Radio color="primary" />} label="ENG" />
                              <FormControlLabel
                                value={LANGUAGE.ZH_HANT_HK}
                                control={<Radio color="primary" />}
                                label="繁中"
                              />
                              <FormControlLabel value={LANGUAGE.ZH} control={<Radio color="primary" />} label="简中" />
                            </RadioGroup>
                          </FormControl>
                          <ShareButtonGroup
                            isCTA
                            title={formatMessage()}
                            whatsapp={true}
                            line={true}
                            email={true}
                            wechat={true}
                            copy={true}
                            url=""
                            showCopy={true}
                            CopyTextFieldProps={{
                              multiline: true
                            }}
                          />
                          <Typography color="textSecondary" style={{ fontSize: '80%' }}>
                            {intl.formatMessage({
                              id: '(For reference only)'
                            })}
                          </Typography>
                        </form>
                      </div>
                    </div>
                  </AccessCodeGate>
                </div>
                <Modal
                  isOpen={insurerModalOpen}
                  onRequestClose={handleInsurerModalClose}
                  className="modalView insurelist-thumb"
                >
                  <div className="col-lg-12">
                    <ul className="card-list-insurance insure">
                      {filteredInsurers && filteredInsurers.length > 0
                        ? filteredInsurers.map((val, index) => {
                          return (
                            <li
                              className={selectedChequeInsurerId === val._id ? 'ins-info-selected' : ''}
                              key={index}
                              onClick={() => handleInsurerEdit(val)}
                            >
                              <div className="bg-white">
                                <img className="image1" src={val.image} alt="" />
                                <h4>{val._id === 'convoy' ? 'CAM' : val.title[intl.locale]}</h4>
                              </div>
                            </li>
                          );
                        })
                        : ''}
                    </ul>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChequeOutView;
