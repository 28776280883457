import React from 'react';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import LanguageButtons from '../../../components/LanguageButtons/LanguageButtons';
import LoginTextField from '../../Login/LoginTextField/LoginTextField';
import { SignInHomePageGrid } from '../../ChooseUserType/component';
import { validateEmail, validateName } from '../../../utils/validators';

const BasicDetails = props => {
  const { intl, language, name, email, errors, onChange, onNext } = props;

  return (
    <div className="section register-details">
      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <div className="logo-wrapper">
            <img src="img/new_adviser.png" alt="adviser" />
          </div>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h4" align="center">
            {intl.formatMessage({ id: 'adviser-sign-up' })}
          </Typography>
        </Grid>
      </Grid>

      <form onSubmit={onNext}>
        <div style={{ padding: '24px 0' }}>
          <LanguageButtons className="language button-options" value={language} onChange={onChange} />

          <LoginTextField
            name="name"
            label={intl.formatMessage({ id: 'Name' })}
            error={!!errors.name}
            helperText={errors.name ? errors.name : undefined}
            value={name}
            onChange={onChange}
            required={true}
            autoComplete="name"
          />

          <LoginTextField
            name="email"
            label={intl.formatMessage({ id: 'Login Email(Unchangeable)' })}
            error={!!errors.email}
            helperText={errors.email ? errors.email : undefined}
            value={email}
            onChange={onChange}
            required={true}
            autoComplete="email"
          />

          <Button
            className="action"
            variant="contained"
            color="primary"
            fullWidth={true}
            type="submit"
            onClick={onNext}
          >
            {intl.formatMessage({ id: 'Next' })}
          </Button>
        </div>
      </form>

      <SignInHomePageGrid />
    </div>
  );
};

export default injectIntl(BasicDetails);
