export const ADVISER_HOME_PAGE_BANNERS = [
  {
    en: 'img/adviser-home-page/TOT_demo_en.png',
    zh: 'img/adviser-home-page/TOT_demo_chs.png',
    'zh-Hant-HK': 'img/adviser-home-page/TOT_demo_cht.png'
  },
  {
    en: 'img/adviser-home-page/lazypack_en.png',
    zh: 'img/adviser-home-page/lazypack_zh.png',
    'zh-Hant-HK': 'img/adviser-home-page/lazypack.png'
  },
  {
    en: 'img/adviser-home-page/classrm_en.png',
    zh: 'img/adviser-home-page/classrm_zh.png',
    'zh-Hant-HK': 'img/adviser-home-page/classrm.png'
  },
  {
    en: '/img/adviser-home-page/holdcoverBanner_en.png',
    zh: '/img/adviser-home-page/holdcoverBanner_sc.png',
    'zh-Hant-HK': '/img/adviser-home-page/holdcoverBanner_tc.png'
  },

  {
    en: '/img/event/pfp-olympic/banner.png',
    zh: '/img/event/pfp-olympic/banner.png',
    'zh-Hant-HK': '/img/event/pfp-olympic/banner.png'
  }
  //removed Whatsapp Group homepage banner
  // {
  //   en: 'img/adviser-home-page/Homepage-WhatsappGroup_en.png',
  //   zh: 'img/adviser-home-page/Homepage-WhatsappGroup_chs.png',
  //   'zh-Hant-HK': 'img/adviser-home-page/Homepage-WhatsappGroup_cht.png'
  // }
];
