import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { usePrevious } from '../../utils/hooks';
import './Pikto.scss';
import { CircularProgress } from '@material-ui/core';

const PiktoWrapperStyle = {
  height: 300,
  position: 'relative'
};

const PiktoLoadingOverlayStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  textAlign: 'center'
};

const PiktoLoadingImageStyle = {
  marginTop: 100
};

// const PiktoLoadingTextStyle = {
//   margin: 0,
//   padding: 0,
//   fontWeight: 600,
//   fontSize: 16
// };

const Pikto = props => {
  const { uid, configuration, getConfigurationPhase } = props;

  const { pChartCdn } = configuration || {};

  const ref = useRef(null);
  const prevUid = usePrevious(uid);

  useEffect(() => {
    let js;
    const refElem = _.get(ref, 'current');

    setTimeout(() => {
      if (refElem) {
        let id = 'pikto-embed-js';

        const existingScriptElem = document.getElementById(id);
        if (existingScriptElem) {
          existingScriptElem.remove();
        }

        js = document.createElement('script');
        js.id = id;
        js.async = true;
        // js.src = `https://create.piktochart.com/assets/embedding/embed.js?t=${new Date().getTime()}`;
        // js.src = `http://localhost:3007/assets/embedding/embed.js?t=${new Date().getTime()}`;
        js.src = `${pChartCdn}/assets/embedding/embed.js?t=${new Date().getTime()}`;
        js.crossOrigin = 'true';
        refElem.appendChild(js);
      }
    }, 500);

    return () => {
      if (refElem && js) {
        refElem.removeChild(js);
      }

      // remove existing scripts from head to force reload for next rendering
      const scriptElems = document.querySelectorAll('head script');
      for (const scriptElem of scriptElems) {
        const src = scriptElem.src || '';
        if (src.indexOf(pChartCdn) > -1) {
          scriptElem.remove();
        }
      }
    };
  }, [ref, uid, prevUid, pChartCdn]);

  useEffect(() => {
    const viewportMetaElem = document.querySelector('meta[name=viewport]');
    if (viewportMetaElem) {
      const originalContent = viewportMetaElem.getAttribute('content');
      viewportMetaElem.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=5.0'
      );
      return () => {
        viewportMetaElem.setAttribute('content', originalContent);
      };
    }
  }, []);

  return (
    <div className="pikto" ref={ref}>
      <div className="piktowrapper-embed" style={PiktoWrapperStyle} data-uid={uid}>
        <div className="pikto-canvas-wrap">
          <div className="pikto-canvas">
            <div className="embed-loading-overlay" style={PiktoLoadingOverlayStyle}>
              <CircularProgress color="primary" style={PiktoLoadingImageStyle} />
              {/* <p style={PiktoLoadingTextStyle}>如久未完，請Reload</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const container = connect(state => ({
  configuration: state.configuration.configuration,
  getConfigurationPhase: state.configuration.getConfigurationPhase
}))(Pikto);

export default container;
