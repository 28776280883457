import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';

const AndroidAppIconHintDialog = props => {
  const { intl, open, onClose, onConfirm, ...rest } = props;

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogContent>{intl.formatMessage({ id: 'cap-icon-applied-an' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(AndroidAppIconHintDialog);
