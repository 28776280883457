import React, { Fragment, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './HotTopicItem.scss';
import _ from 'lodash';
import moment from 'moment-timezone';
import { HotLabel } from '../../../AdviserHomePage/HotLabel/HotLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StatisticCount from './StatisticCount/StatisticCount';
import { getHotTopicItemImageUrl } from '../../../../utils/hot-topic';
import { toggleDialog } from '../../../../store/control/duck';
import { connect } from 'react-redux';

const HotTopicItem = forwardRef((props, ref) => {
  const { intl, itemData, DialogProps, onToggleItem, shouldShowStatistic, statistic, enableLanguages, toggleDialog } = props;

  const buttonRef = useRef(null);

  const openDialog = () => {
    toggleDialog('hotTopicItem', {
      item: itemData,
      onToggleItem,
      shouldShowStatistic,
      statistic,
      enableLanguages,
      DialogProps: {
        ...DialogProps,
        TransitionProps: {
          triggerTarget: buttonRef.current
        }
      }
    });

    if (onToggleItem) {
      onToggleItem(itemData, true);
    }
  };

  const thumbnailImageUrl = getHotTopicItemImageUrl(itemData);
  const isRecentlyUpdated = itemData.updatedAt && moment(itemData.updatedAt).isAfter(moment().subtract(14, 'days'));
  const isRecentlyCreated = itemData.createdAt && moment(itemData.createdAt).isAfter(moment().subtract(14, 'days'));
  const isHot = itemData.isHot === true;
  const badge = isRecentlyUpdated
    ? intl.formatMessage({ id: 'hottopic-updated' })
    : isRecentlyCreated
    ? intl.formatMessage({ id: 'hottopic-new' })
    : isHot
    ? intl.formatMessage({ id: 'hot' })
    : undefined;

  useImperativeHandle(ref, () => ({
    openDialog
  }));

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        {badge && <HotLabel text={badge} style={{ zIndex: 1, top: 0, left: 'unset', right: 5, borderRadius: 4 }} />}
        <Button className="hot-topic-item-button" color="primary" ref={buttonRef} onClick={openDialog}>
          <div>
            <div className="hot-topic-img">
              <img src={thumbnailImageUrl} alt="thumbnail" />
            </div>
            {shouldShowStatistic && (
              <Grid container alignItems="center" spacing={1} style={{ paddingTop: 8 }}>
                <Grid item xs={6}>
                  <StatisticCount
                    IconComponent={VisibilityIcon}
                    count={_.get(statistic, 'counts["View Lazy Pack"]', 0)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <StatisticCount
                    IconComponent={FavoriteIcon}
                    count={_.get(statistic, 'counts["Like Lazy Pack"]', 0)}
                  />
                </Grid>
              </Grid>
            )}
            <div className="hot-topic-item-title">{itemData.title[intl.locale]}</div>
          </div>
        </Button>
      </div>
    </Fragment>
  );
});

const container = connect(
  state => ({
    hotTopicItemDialog: state.control.hotTopicItemDialog
  }),
  {
    toggleDialog
  },
  null,
  {
    forwardRef: true
  }
)(HotTopicItem);

export default container;
