import React from 'react';
import { injectIntl } from 'react-intl';
import ProBadgePure from '../../../components/SubscriptionComponents/ProBadge/ProBadgePure';
import { Avatar, Chip, IconButton, Typography } from '@material-ui/core';
import { getWebImageUrl } from '../../../utils/image';
import QRCodeComponent from '../../../components/QRCodeComponent';
import Grid from '@material-ui/core/Grid';
import './AdviserInfo.scss';
import { formatPhoneNumber, getUserInitial } from '../../../utils/user';
import { getReferralLink } from '../../../utils/referral';
import { getAvatar } from '../../../utils/my-details';
import MyDetailsProfilePicture from '../../Advisor/MyDetails/MyDetailsProfile/MyDetailsProfilePicture/MyDetailsProfilePicture';
import { formatOpenUrl, getPdfTelUrl } from '../../../utils/url';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import ProfileButton from './ProfileButton/ProfileButton';
import CreateOneForFreeButton from './CreateOneForFreeButton/CreateOneForFreeButton';

const ImageButtonStyle = {
  padding: 1,
  width: 22,
  height: 22,
};

const ImageStyle = {
  width: 21,
  height: 21,
};

const AdviserInfo = (props) => {
  const {
    intl,
    show,
    showQRCode,
    adviser,
    direction,
    hideTitle,
    hideName,
    hideAvatar,
    qrCodeWidth,
    QRActionButton,
    referById,
    referLinkParams,
    hideFacebookLinkedinInstagramButtons,
    MyDetailsProfilePictureProps,
    hideProfileButton,
  } = props;

  const phone = adviser && (adviser.phoneNumber || adviser.phone);
  const phone2 = adviser && (adviser.phoneNumber2 || adviser.phone2);
  const whatsapp = adviser && adviser.whatsapp;
  const referLink = getReferralLink({
    targetAdviser: adviser,
    client: { _id: referById },
    isQRCode: false,
    ...referLinkParams,
  });
  const referQRLink = getReferralLink({
    targetAdviser: adviser,
    client: { _id: referById },
    isQRCode: true,
    ...referLinkParams,
  });
  const facebook = adviser && adviser.facebookLink;
  const linkedin = adviser && adviser.realLinkedinLink;
  const instagram = adviser && adviser.instagram;
  const facebookUrl = facebook ? formatOpenUrl(facebook) : undefined;
  const linkedInUrl = linkedin ? formatOpenUrl(linkedin) : undefined;
  const instagramUrl = instagram ? `https://www.instagram.com/${instagram}` : undefined;

  const onClickWechat = () => {
    toast.info(IconToast('info', intl.formatMessage({ id: 'wechat-msg-copied' })), { className: 'new-toast' });
  };

  return (
    <div className="adviser-info">
      <Grid
        container
        className={!show ? 'invisible' : ''}
        justify="space-between"
        alignItems="center"
        direction={direction || 'row'}
      >
        <Grid
          item
          className={!showQRCode ? 'adviser-info-left' : 'adviser-info-left-qr'}
          style={{
            width: !showQRCode ? (hideAvatar ? '100%' : 'calc(100% - 172px)') : `calc(100% - ${qrCodeWidth}px)`,
          }}
        >
          <div className="adviser-info-contact-wrapper">
            {!hideTitle && (
              <div className="adviser-info-header" style={{ marginBottom: '2px' }}>
                <span>{intl.formatMessage({ id: 'Adviser' })}</span>
                {!hideProfileButton && (
                  <ProfileButton
                    adviser={adviser}
                    style={{ verticalAlign: 'text-top', marginLeft: 4 }}
                    href={referLink}
                  />
                )}
                <ProBadgePure />
              </div>
            )}

            {!hideName && (
              <div className="adviser-info-name" style={{ marginBottom: '4px' }}>
                {adviser && adviser.name && (
                  <Typography component="span" style={{ fontSize: 20 }}>
                    {adviser.name}
                  </Typography>
                )}
              </div>
            )}

            <Grid container direction="column" spacing={0} alignItems="flex-start">
              {(phone || phone2) && (
                <Grid item className="adviser-info-contact " style={{ marginBottom: '4px' }}>
                  <Chip
                    avatar={<Avatar alt="phone" src={getWebImageUrl('img/phone.svg')} />}
                    label={
                      <span>
                        {phone && (
                          <a
                            className="link"
                            href={phone ? getPdfTelUrl(phone) : undefined}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {formatPhoneNumber(phone)}
                          </a>
                        )}
                        {phone && phone2 && <span style={{ whiteSpace: 'pre-wrap' }}>{' / '}</span>}
                        {phone2 && (
                          <a
                            className="link"
                            href={phone2 ? getPdfTelUrl(phone2) : undefined}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {formatPhoneNumber(phone2)}
                          </a>
                        )}
                      </span>
                    }
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              )}

              {whatsapp && (
                <Grid item className="adviser-info-contact adviser-info-whatsapp" style={{ marginBottom: '4px' }}>
                  <Chip
                    className="link"
                    avatar={<Avatar alt="whatsapp" src={getWebImageUrl('img/whatsapp.svg')} />}
                    label={
                      <a
                        className="link"
                        href={whatsapp ? `https://api.whatsapp.com/send?phone=${whatsapp.replace('+', '')}` : undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatPhoneNumber(whatsapp)}
                      </a>
                    }
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              )}

              {adviser &&
                (adviser.wechatId ||
                  ((facebook || linkedin || instagram) && !hideFacebookLinkedinInstagramButtons)) && (
                  <Grid item style={{ marginBottom: '4px' }}>
                    <Grid container justify="space-between" alignItems="center">
                      {adviser.wechatId && (
                        <Grid item className="adviser-info-contact adviser-info-wechat" onClick={onClickWechat}>
                          <Chip
                            avatar={<Avatar alt="wechat" src={getWebImageUrl('img/wechat.svg')} />}
                            label={adviser.wechatId}
                            color="primary"
                            variant="outlined"
                            style={{ cursor: 'pointer' }}
                          />
                        </Grid>
                      )}

                      {(facebook || linkedin || instagram) && !hideFacebookLinkedinInstagramButtons && (
                        <Grid item style={{ flexGrow: 1 }}>
                          <Grid container justify="flex-start" spacing={1}>
                            {facebook && (
                              <Grid item className="adviser-info-contact">
                                {!linkedin && !instagram ? (
                                  <Chip
                                    className="link"
                                    avatar={<Avatar alt="facebook" src={getWebImageUrl('img/facebook.svg')} />}
                                    label={
                                      <a
                                        lassName="link"
                                        href={facebookUrl ? facebookUrl : undefined}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Facebook
                                      </a>
                                    }
                                    color="primary"
                                    variant="outlined"
                                  />
                                ) : (
                                  <a
                                    className="link"
                                    href={facebookUrl ? facebookUrl : undefined}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <IconButton style={ImageButtonStyle}>
                                      <img src={getWebImageUrl('img/facebook.svg')} alt="facebook" style={ImageStyle} />
                                    </IconButton>
                                  </a>
                                )}
                              </Grid>
                            )}
                            {linkedin && (
                              <Grid item className="adviser-info-contact">
                                {!facebook && !instagram ? (
                                  <Chip
                                    className="link"
                                    avatar={<Avatar alt="linkedin" src={getWebImageUrl('img/linkedin.svg')} />}
                                    label={
                                      <a
                                        className="link"
                                        href={linkedInUrl ? linkedInUrl : undefined}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Linkedin
                                      </a>
                                    }
                                    color="primary"
                                    variant="outlined"
                                  />
                                ) : (
                                  <a
                                    className="link"
                                    href={linkedInUrl ? linkedInUrl : undefined}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <IconButton style={ImageButtonStyle}>
                                      <img src={getWebImageUrl('img/linkedin.svg')} alt="linkedin" style={ImageStyle} />
                                    </IconButton>
                                  </a>
                                )}
                              </Grid>
                            )}
                            {instagram && (
                              <Grid item className="adviser-info-contact">
                                {!facebook && !linkedin ? (
                                  <Chip
                                    className="link"
                                    avatar={<Avatar alt="instagram" src={getWebImageUrl('img/instagram.svg')} />}
                                    label={
                                      <a
                                        className="link"
                                        href={instagramUrl ? instagramUrl : undefined}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {`IG: ${instagram}`}
                                      </a>
                                    }
                                    color="primary"
                                    variant="outlined"
                                  />
                                ) : (
                                  <a
                                    className="link"
                                    href={instagramUrl ? instagramUrl : undefined}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <IconButton style={ImageButtonStyle}>
                                      <img
                                        src={getWebImageUrl('img/instagram.svg')}
                                        alt="instagram"
                                        style={ImageStyle}
                                      />
                                    </IconButton>
                                  </a>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

              {adviser && adviser.workEmail && (
                <Grid item className="adviser-info-contact adviser-info-email">
                  <Chip
                    avatar={<Avatar alt="work-email" src={getWebImageUrl('img/email.svg')} />}
                    label={
                      <a
                        className="link"
                        href={adviser.workEmail ? `mailto:${adviser.workEmail}` : undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {adviser.workEmail}
                      </a>
                    }
                    color="primary"
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>

        {showQRCode ? (
          <Grid item className="adviser-info-qr-right" style={{ width: qrCodeWidth, padding: 5 }}>
            <Grid container direction="column" alignItems="center" justify="center" wrap="nowrap">
              <Grid item>
                <a href={referLink} target="_blank" rel="noopener noreferrer">
                  <QRCodeComponent
                    content={referQRLink}
                    isStyle={'client-export'}
                    logo={true}
                    imgStyle={{
                      maxHeight: qrCodeWidth - 10,
                    }}
                  />
                </a>
              </Grid>
              {QRActionButton && (
                <Grid item style={{ width: '100%' }}>
                  {QRActionButton === 'create-one-for-free' ? (
                    <CreateOneForFreeButton href={referLink} />
                  ) : (
                    QRActionButton
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          !hideAvatar && (
            <Grid item className="adviser-info-right">
              <MyDetailsProfilePicture
                initial={getUserInitial(adviser)}
                src={getAvatar(adviser)}
                width={150}
                style={{ border: 0, borderRadius: '50%', width: 162, height: 162 }}
                {...MyDetailsProfilePictureProps}
              />
            </Grid>

            // <Grid item className="adviser-info-right">
            //   <div className="advisor-right-flex">
            //     <div className={`advisor-right-avatar-wrapper ${adviser.gender === 'Female' ? 'female' : ''}`}>
            //       <Avatar>
            //         <span>{adviser.name ? adviser.name.charAt(0) : ''}</span>
            //       </Avatar>
            //     </div>
            //   </div>
            // </Grid>
          )
        )}
      </Grid>
    </div>
  );
};

export default injectIntl(AdviserInfo);
