import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import './VideoPlayer.scss';

export default class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  getPlayer = () => this.player;
  handleReady = player => {
    this.player = player; // Store a player that is ready for methods
    this.setState({ loaded: true });
  };

  render() {
    const { className, style, containerProps, ...rest } = this.props;
    const { loaded } = this.state;

    return (
      <div className="video-player" {...containerProps}>
        {!loaded && <Skeleton variant="rect" width="100%" height="100%" />}

        <ReactPlayer
          style={_.merge(
            {
              visibility: loaded ? 'visible' : 'hidden',
              position: loaded ? 'absolute' : 'fixed'
            },
            style
          )}
          onReady={this.handleReady}
          className="react-player"
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: {
                controls: 1,
                showinfo: 1,
                enablejsapi: 1,
                origin: 'https://www.youtube.com'
                // origin: window.location.href,
                // widget_referrer: window.location.href,
                // host: `${window.location.protocol}//www.youtube.com`
              }
            }
          }}
          {...rest}
        />
      </div>
    );
  }
}
