export const jsonParse = (str, defaultValue) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return defaultValue || undefined;
  }
};


export const jsonArrayFormatValidator = (string) => {
  try {
    return JSON.parse(string);
  } catch (error) {
    if (string.match(/,$/)) {
      console.log("string end with ,")
      let jsonFormat = string.slice(0, -1)
      if (jsonFormat.match(/("|})$/)) {
        console.log("string end with \" or }")
        jsonFormat += ']'
      } else {
        jsonFormat += '"]'
      }
      return JSON.parse(jsonFormat)
    } else if (string.match(/,]$/)) {
      console.log("string end with ,]")
      let jsonFormat = string.slice(0, -2)
      if (jsonFormat.match(/("|})$/)) {
        jsonFormat += ']'
      } else {
        jsonFormat += '"]'
      }
      return JSON.parse(jsonFormat)
    }
    else if (string.match(/}$/)) {
      const jsonFormat = string + ']'
      return JSON.parse(jsonFormat)
    } else if (string.match(/"$/)) {
      console.log("string end with \"")
      const jsonFormat = string + ']'
      return JSON.parse(jsonFormat)
    } else {
      console.log("all else case: " + string)
      const jsonFormat = string + '"]'
      return JSON.parse(jsonFormat)
    }
  }
}



// console.log('Print start')
// console.log(jsonArrayFormatValidator('["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",]'))
// console.log(jsonArrayFormatValidator('["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",'))
// console.log(jsonArrayFormatValidator('["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"'))
// console.log(jsonArrayFormatValidator('["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y",'))