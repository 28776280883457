import React, { useState } from 'react';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import './TeamLibraryCategory.scss';
import { deleteFileFromAttachmentsCategory } from '../../../store/team/api';
import { getTeams } from '../../../store/team/duck';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import FileSlide from '../../../components/FileSlide/FileSlide';
import { getGlobalIntl } from '../../../reduxConnectedIntlProvider';

const TeamLibraryCategory = ({ location, history }) => {
  const intl = getGlobalIntl();
  const dispatch = useDispatch();
  const teams = useSelector(state => state.team.teams);
  const [isFileRemoveDialogOpen, setIsFileRemoveDialogOpen] = useState(false);
  const [fileToRemove, setFileToRemove] = useState(null);

  if (!teams || !location.state.teamId || !location.state.categoryId) {
    history.push('/team_library');
    return <AppContentContainer />;
  }

  const currentTeam = teams.find(team => team._id === location.state.teamId);
  const categories = currentTeam.fileAttachmentsCategories;
  const category = categories.find(category => category._id === location.state.categoryId);

  // REVIEW: Duplicate with TeamLibrary, possible refactoring

  const removeFile = async () => {
    await deleteFileFromAttachmentsCategory(currentTeam._id, category._id, fileToRemove._id, categories);
    dispatch(getTeams());
    setIsFileRemoveDialogOpen(false);
  };

  const handleRemoveFile = async file => {
    setFileToRemove(file);
    setIsFileRemoveDialogOpen(true);
  };

  const handleAllCategaries = () => {
    history.push({
      pathname: '/team_library'
    });
  };

  const NoFiles = () => <div>{intl.formatMessage({ id: 'team-library-category-no-files' })}</div>;

  return (
    <AppContentContainer>
      <div className="container">
        <div className="containerHeader">
          {/* <Button className="contentSlidesSeeMore" onClick={handleAllCategaries}>
            ❮ {intl.formatMessage({ id: 'team-library-all-categories' })}
          </Button> */}

          <div className="title">
            <Typography variant="h6">{category.title}</Typography>
          </div>
        </div>
        
        <div className="filesContainer">
          {category.attachments.length > 0 ? (
            category.attachments.map((attachment, index) => (
              <FileSlide key={index} file={attachment} onFileDeleted={handleRemoveFile} />
            ))
          ) : (
            <NoFiles />
          )}
        </div>
      </div>
      <Dialog open={isFileRemoveDialogOpen} onBackdropClick={() => setIsFileRemoveDialogOpen(false)}>
        <DialogTitle>{intl.formatMessage({ id: 'remove-file-dialog-title' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'remove-file-dialog-content' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setIsFileRemoveDialogOpen(false)}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" onClick={removeFile}>
            {intl.formatMessage({ id: 'Confirm' })}
          </Button>
        </DialogActions>
      </Dialog>
    </AppContentContainer>
  );
};

export default injectIntl(withRouter(TeamLibraryCategory));
