import React from 'react';
import { TextField, Zoom } from '@material-ui/core';

// temp component to override transition component to avoid issue due to safari CSS bug
// https://github.com/mui/material-ui/pull/32202
const TempTextField = props => {
  const { select, SelectProps, ...rest } = props;

  if (select) {
    const { MenuProps, ...other } = SelectProps || {};

    return (
      <TextField
        select={select}
        SelectProps={{
          MenuProps: {
            TransitionComponent: Zoom,
            ...MenuProps
          },
          ...other
        }}
        {...rest}
      />
    );
  } else {
    return <TextField {...props} />;
  }
};

export default TempTextField;
