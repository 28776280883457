import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';

const EmergencyEWalletShareAddToWalletDialog = props => {
  const { intl, open, onClose } = props;

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'emergency-e-wallet-share-add-to-wallet' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'emergency-e-wallet-add-to-wallet-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(EmergencyEWalletShareAddToWalletDialog);
