import { connect } from 'react-redux';
import { forgotPassword, resetPassword } from '../../store/user/duck';
import ForgotPassword from './component';
import { injectIntl } from 'react-intl';
import { selectedLocale } from '../../store/locale/action';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';

const ResetPassword = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    forgotPasswordPhase: state.user.forgotPasswordPhase,
    resetPasswordPhase: state.user.resetPasswordPhase,
    message: state.user.message,
    forgotPasswordMessageExtras: state.user.forgotPasswordMessageExtras
  }),
  // Map actions to props
  {
    forgotPassword,
    resetPassword,
    selectedLocale,
    renderHeaderButton
  }
)(ForgotPassword);

export default injectIntl(ResetPassword);
