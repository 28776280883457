import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import BusinessTargetCalculator from '../../../../views/Advisor/Dashboard/partials/business/setBusinessTargetModal/BusinessTargetCalculator/BusinessTargetCalculator';
import { connect } from 'react-redux';
import { mergeControl } from '../../../../store/control/duck';

const DescriptionTypoStyle = {
  maxWidth: 600,
  margin: '0 auto'
};

const ImageStyle = {
  width: '100%'
};

const MDRTActivityCalculator = props => {
  const { intl, mdrtActivityCalculator, mergeControl, ...rest } = props;

  const { defaultQuarterTarget, quarterTarget, avgCaseSize, closingRate, isMDRT, isCOT, isTOT } =
    mdrtActivityCalculator || {};

  const onChangeQuarterTarget = (value, isExplicitInput) => {
    if (value !== quarterTarget) {
      mergeControl('mdrtActivityCalculator', { quarterTarget: value || null });
    }

    if (isExplicitInput) {
      if (value !== defaultQuarterTarget) {
        mergeControl('mdrtActivityCalculator', { defaultQuarterTarget: value || null });
      }
    }
  };

  const onChangeAvgCaseSize = value => {
    if (value !== avgCaseSize) {
      mergeControl('mdrtActivityCalculator', { avgCaseSize: value || null });
    }
  };

  const onChangeClosingRate = value => {
    if (value !== closingRate) {
      mergeControl('mdrtActivityCalculator', { closingRate: value || null });
    }
  };

  const onChangeIsMDRT = value => {
    if (value !== isMDRT) {
      mergeControl('mdrtActivityCalculator', { isMDRT: value });
    }
  };

  const onChangeIsCOT = value => {
    if (value !== isCOT) {
      mergeControl('mdrtActivityCalculator', { isCOT: value });
    }
  };

  const onChangeIsTOT = value => {
    if (value !== isTOT) {
      mergeControl('mdrtActivityCalculator', { isTOT: value });
    }
  };

  return (
    <Grid container direction="column" spacing={2} {...rest}>
      <Grid item>
        <Typography color="textSecondary" style={DescriptionTypoStyle}>
          {intl.formatMessage({ id: 'mdrt-activity-cal-desc' })}
        </Typography>
      </Grid>
      <Grid item>
        <BusinessTargetCalculator
          defaultQuarterTarget={defaultQuarterTarget}
          quarterTarget={quarterTarget}
          avgCaseSize={avgCaseSize}
          closingRate={closingRate}
          isMDRT={isMDRT}
          isCOT={isCOT}
          isTOT={isTOT}
          onChangeQuarterTarget={onChangeQuarterTarget}
          onChangeAvgCaseSize={onChangeAvgCaseSize}
          onChangeClosingRate={onChangeClosingRate}
          onChangeIsMDRT={onChangeIsMDRT}
          onChangeIsCOT={onChangeIsCOT}
          onChangeIsTOT={onChangeIsTOT}
          style={{ paddingTop: 16 }}
        />
      </Grid>
      <Grid item>
        <img src="img/recruitment.png" alt="recruitment" style={ImageStyle} />
      </Grid>
    </Grid>
  );
};

const container = connect(
  state => ({
    mdrtActivityCalculator: state.control.mdrtActivityCalculator
  }),
  {
    mergeControl
  }
)(MDRTActivityCalculator);

export default injectIntl(container);
