import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './ConfirmModal.css';
import { SlideTransition } from '../Transition/Transition';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
class ConfirmModal extends React.Component {
  handleClick(index) {
    const { buttons } = this.props;

    if (index <= buttons.length - 1) {
      if (buttons[index].onClick !== undefined && typeof buttons[index].onClick === 'function') {
        buttons[index].onClick();
      }
    }
  }

  render() {
    const { intl, open, title, buttons, content, updatePhase, updateAdviserBusiness, ...rest } = this.props;

    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        {...rest}
      >
        <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: title })}</DialogTitle>
        <DialogContent>{content ? intl.formatMessage({ id: content }) : ''}</DialogContent>
        <DialogActions>
          {buttons !== undefined &&
            Array.isArray(buttons) &&
            buttons.map((button, index) => {
              return (
                <Button
                  key={index}
                  color="primary"
                  variant={button.variant}
                  onClick={() => {
                    this.handleClick(index);
                  }}
                >
                  {intl.formatMessage({ id: button.text })}
                </Button>
              );
            })}
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {
    forwardRef: true
  }
)(ConfirmModal);

export default injectIntl(container, { forwardRef: true });
