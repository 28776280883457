import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isUserConnected } from '../../utils/user';
import ReferralQRCodeContent from '../../components/ReferralQRCodeDialog/ReferralQRCodeContent/ReferralQRCodeContent';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';
import _ from 'lodash';
import { getConnectedAdviser } from '../../store/user/duck';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';
import { isMobile } from 'react-device-detect';

const ReferMyAdviser = props => {
  const {
    intl,
    history,
    location,
    userDetails,
    connectedAdvisers,
    getAdviserPhase,
    getConnectedAdviser,
    renderHeaderButton
  } = props;

  useEffect(() => {
    renderHeaderButton(false);
  }, [renderHeaderButton]);

  useEffect(() => {
    if (getAdviserPhase !== true && isUserConnected(userDetails)) {
      getConnectedAdviser();
    }
  }, [getAdviserPhase, userDetails, getConnectedAdviser]);

  const showSpinner = isUserConnected(userDetails) && getAdviserPhase !== true;
  const adviserName = _.get(connectedAdvisers, '[0].name');

  return (
    <AppContentContainer>
      {showSpinner && <LoadingBackdrop open={true} />}
      {!showSpinner && (
        <ReferralQRCodeContent
          history={history}
          location={location}
          customCTAMessage={intl.formatMessage({ id: 'referral-card-client-message' })}
          referLinkSearch={{
            defaultMessage: intl.formatMessage(
              { id: 'client-referral-qr-default-msg' },
              { adviserName: adviserName, clientName: userDetails?.name }
            )
          }}
          defaultMessageIntlId="referral-link-message-2"
          defaultMessageExtras={{ name: adviserName }}
        />
      )}
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    connectedAdvisers: state.user.connectedAdvisers,
    getAdviserPhase: state.user.getAdviserPhase
  }),
  {
    getConnectedAdviser,
    renderHeaderButton
  }
)(ReferMyAdviser);

export default injectIntl(container);
