import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Typography, Button, Container, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './COVIDQuickCheck.scss';
import COVIDShareToClientDialog from '../../../Client/partials/COVIDShareToClientDialog/COVIDShareToClientDialog';
import { findVirusRelatedClients } from '../../../../../utils/virus';
import { toast } from 'react-toastify';
import IconToast from '../../../../../components/NewToast';
import _ from 'lodash';
import { getOSMLocation } from '../../../../../utils/location';
import AppContentContainer from '../../../../../components/AppContentContainer/AppContentContainer.js';
import NativeOrWeb from '../../../../../utils/native';
import LoadingBackdrop from '../../../../../components/LoadingBackdrop/LoadingBackdrop';

class COVIDQuickCheck extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      covidShareDialog: undefined,
      loading: false
    };
  }

  callWorker = async address => {
    this.setState({ loading: true });

    let clientList = [
      {
        factsheetId: {
          workLocation: address
        }
      }
    ];

    const { intl } = this.props;
    const virusRelatedClients = await findVirusRelatedClients(clientList);

    let dialogResult;
    if (virusRelatedClients && virusRelatedClients.length && virusRelatedClients.length > 0) {
      // converting latitude & longitude to address
      const workLocation = _.get(virusRelatedClients[0], 'factsheetId.workLocation');
      if (workLocation && typeof workLocation !== 'string') {
        _.set(
          virusRelatedClients[0],
          'factsheetId.workLocation',
          await getOSMLocation(workLocation.latitude, workLocation.longitude, intl.locale)
        );
      }
      dialogResult = { client: virusRelatedClients[0] };
    } else {
      toast.info(IconToast('info', intl.formatMessage({ id: 'covid-qc-empty' })), { className: 'new-toast' });
    }

    this.setState({ loading: false, covidShareDialog: dialogResult });
  };

  search = async () => {
    const { address } = this.state;
    if (address === '') {
      return;
    }
    await this.callWorker(address);
  };

  searchByGeolocation = async () => {
    if (NativeOrWeb.isGeolocationable()) {
      try {
        const position = await NativeOrWeb.getCurrentPosition();
        await this.callWorker({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      } catch (error) {
        console.log(error);
        toast.info(IconToast('warn', error.message), { className: 'new-toast' });
      }
    } else {
      const { intl } = this.props;
      toast.info(IconToast('warn', intl.formatMessage({ id: 'covid-no-geolocation' })), { className: 'new-toast' });
    }
  };

  render() {
    const { intl } = this.props;
    const { address, covidShareDialog, loading } = this.state;

    return (
      <AppContentContainer>
        <div>
          {loading && <LoadingBackdrop open={true} />}
          <COVIDShareToClientDialog
            dialog={covidShareDialog}
            onClose={() => this.setState({ covidShareDialog: false })}
            toolMode={true}
          />
          <div id="wrapper">
            <div id="page-content-wrapper" className="covid-qc">
              <div>
                <Container className="my-container flex-box">
                  <div>
                    <Typography variant="h2" color="primary" paragraph={true} style={{ marginTop: 16 }}>
                      {intl.formatMessage({ id: 'covid-qc-title' })}
                    </Typography>
                    <Typography color="textSecondary" paragraph={true}>
                      {intl.formatMessage({ id: 'covid-qc-hint' })}
                    </Typography>
                    <InputBase
                      className="address-input"
                      placeholder={intl.formatMessage({ id: 'covid-qc-address-label' })}
                      value={address}
                      onChange={event => this.setState({ address: event.target.value })}
                      size="medium"
                    />
                    <Button
                      onClick={this.search}
                      fullWidth
                      startIcon={<SearchIcon />}
                      color="primary"
                      variant="contained"
                      disabled={loading}
                    >
                      {intl.formatMessage({ id: 'covid-qc-search' })}
                    </Button>

                    <Typography color="textSecondary" style={{ margin: '10px 0' }}>
                      -{intl.formatMessage({ id: 'or-wording' })}-
                    </Typography>

                    <Button
                      onClick={this.searchByGeolocation}
                      fullWidth
                      startIcon={<RoomOutlinedIcon />}
                      color="primary"
                      variant="contained"
                      disabled={loading}
                    >
                      {intl.formatMessage({ id: 'covid-read-geolocation' })}
                    </Button>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(COVIDQuickCheck);

export default injectIntl(container);
