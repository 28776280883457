import React, { Fragment, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Backdrop, Button, Dialog, Grid, IconButton, makeStyles, Portal, Typography } from '@material-ui/core';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getNewReferralCount } from '../../../../store/referral/duck';
import { toggleDialog } from '../../../../store/control/duck';
import Badge from '@material-ui/core/Badge';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import LikeCount from '../../../../components/LikeCount/LikeCount';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { tracking } from '../../../../utils/tracking';
import { getAvatar } from '../../../../utils/my-details';
import ContactMeDialog from '../../../AdviserInfoPage/AdviserInfoBottomButtons/ContactMeDialog/ContactMeDialog';

const PFPLUS_URL = process.env.PFPLUS_URL;

const WideContainerStyle = {
  position: 'absolute',
  top: 150,
  left: 24,
  width: 'fit-content',
  zIndex: 2,
};

const SpeedDialStyle = {
  position: 'fixed',
  bottom: 'calc(55px + var(--bottom-padding))',
  right: 5,
};

const FabStyle = {
  width: 55,
  height: 55,
  background: 'var(--my-gradient)',
};

const BackDropStyle = {
  zIndex: 1044,
};

const BadgeStyle = {
  position: 'fixed',
  bottom: 106,
  right: 15,
  zIndex: 1051,
};

const ShareButtonStyle = {
  borderRadius: 8,
  background: 'var(--my-gradient)',
  padding: '10px 16px',
};

const QRDialogPaperStyle = {
  background: 'transparent',
  boxShadow: 'none',
  width: 'calc(100% - 32px)',
  margin: 16,
};

const QRDialogContentStyle = {
  padding: '12px 0 24px',
};

const CloseButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
};

const useLikeSpeedDialActionStyle = makeStyles((theme) => ({
  staticTooltipLabel: {
    background: theme.palette.primary.main,
  },
  fab: {
    background: theme.palette.primary.main,
  },
}));

const AdviserInfoPageManageActions = (props) => {
  const {
    intl,
    adviserProfilePlus,
    history,
    newReferralCount,
    getNewReferralCount,
    toggleDialog,
    teamLogoUrl,
    onShare,
    isWideMode,
  } = props;
  const [open, setOpen] = useState(false);
  const [replyFormDialog, setReplyFormDialog] = useState(false);
  const animRef = useRef(null);
  const likeSpeedDialActionClasses = useLikeSpeedDialActionStyle();
  // const [qrOpen, setQROpen] = useState(false);

  const { likes, adviserInfo } = adviserProfilePlus || {};

  const link = `${PFPLUS_URL}/adviser/${_.get(adviserInfo, '_id', '')}`;
  const { whatsapp, phoneNumber } = adviserInfo || {};

  const replyForm = () => {
    tracking('Click on Reply Form - Profile+');
    setReplyFormDialog(true);
  };
  const edit = () => {
    tracking('Click on Edit - Profile+');
    history.push('/my-details');
  };
  // const myQR = () => {
  //   setQROpen(true);
  // };
  const referral = () => {
    tracking('Click on New Clients - Profile+');
    history.push('/referral_records');
  };

  const openSpeedDial = () => setOpen(true);
  const closeSpeedDial = () => setOpen(false);
  const closeReplyFormDialog = () => setReplyFormDialog(false);
  // const closeQRDialog = () => setQROpen(false);

  useEffect(() => {
    setOpen(false);
  }, [isWideMode]);

  useEffect(() => {
    getNewReferralCount();
  }, [getNewReferralCount]);

  useEffect(() => {
    const run = async () => {
      if (isWideMode) {
        if (animRef && animRef.current) {
          const anim = await animRef.current.animationDidLoad();
          setTimeout(() => {
            if (anim) {
              anim.play();
            }
          }, 350);
        } else {
          setTimeout(() => {
            run();
          }, 500);
        }
      }
    };
    run().then().catch();
  }, [isWideMode]);

  const actions = [
    {
      icon: <ShareRoundedIcon color="secondary" />,
      label: (
        <Typography color="secondary" style={{ fontWeight: 'bold' }}>
          {intl.formatMessage({ id: 'share-to-client' })}
        </Typography>
      ),
      action: onShare,
      wideOverride: (
        <Button
          variant="contained"
          color="primary"
          onClick={onShare}
          startIcon={<ShareRoundedIcon />}
          style={ShareButtonStyle}
        >
          {intl.formatMessage({ id: 'share-to-client' })}
        </Button>
      ),
    },
    {
      icon: <ListAltRoundedIcon color="primary" />,
      label: <Typography>{intl.formatMessage({ id: 'reply-form' })}</Typography>,
      action: replyForm,
    },
    {
      icon: <EditRoundedIcon color="primary" />,
      label: <Typography>{intl.formatMessage({ id: 'go-edit' })}</Typography>,
      action: edit,
    },
    {
      icon: <GroupRoundedIcon color="primary" />,
      label: (
        <Grid container alignItems="center" spacing={1} wrap="nowrap">
          <Grid item>
            <Typography className="title" align="left">
              {intl.formatMessage({ id: 'New-Clients' })}
            </Typography>
          </Grid>
          {!!newReferralCount && (
            <Grid item>
              <Badge className="referral-count" badgeContent={newReferralCount} color="error" />
            </Grid>
          )}
        </Grid>
      ),
      action: referral,
    },
    {
      icon: <ThumbUpRoundedIcon style={{ color: '#fff' }} />,
      label: (
        <Typography color="textPrimary" style={{ fontWeight: 'bold' }}>
          {intl.formatMessage({ id: 'like-count' }, { count: likes })}
        </Typography>
      ),
      speedDialActionProps: {
        classes: likeSpeedDialActionClasses,
      },
      wideOverride: (
        <LikeCount
          style={{
            padding: '0 0 0 4px',
            fontSize: 28,
          }}
          likes={likes || 0}
          LottieProps={{
            ref: animRef,
            style: {
              width: 72,
              height: 72,
              top: -34,
              left: -22,
            },
          }}
        />
      ),
    },
    // {
    //   icon: <CropFreeRoundedIcon color="primary" />,
    //   label: intl.formatMessage({ id: 'my-qr' }),
    //   action: myQR
    // },
  ];

  return (
    <Fragment>
      <ContactMeDialog open={replyFormDialog} onClose={closeReplyFormDialog} onSuccess={closeReplyFormDialog} />
      {isWideMode ? (
        <Grid container direction="column" alignItems="flex-start" spacing={3} style={WideContainerStyle}>
          {actions.reverse().map((item, index) =>
            !!item ? (
              <Grid item key={index}>
                {item.wideOverride ? (
                  item.wideOverride
                ) : (
                  <Button startIcon={item.icon} onClick={item.action}>
                    {item.label}
                  </Button>
                )}
              </Grid>
            ) : null,
          )}
        </Grid>
      ) : (
        <Portal container={document.body}>
          <Backdrop open={open} style={BackDropStyle} />
          {!!newReferralCount && (
            <Badge badgeContent={<Typography>{newReferralCount}</Typography>} color="error" style={BadgeStyle} />
          )}
          <SpeedDial
            open={open}
            ariaLabel="Adviser Info Page Actions"
            icon={<SpeedDialIcon icon={<MoreVertRoundedIcon />} openIcon={<CloseRoundedIcon />} />}
            onOpen={openSpeedDial}
            onClose={closeSpeedDial}
            style={SpeedDialStyle}
            FabProps={{
              style: FabStyle,
            }}
          >
            {actions.map((item, index) =>
              !!item ? (
                <SpeedDialAction
                  key={index}
                  icon={item.icon}
                  tooltipTitle={item.label}
                  tooltipOpen={true}
                  onClick={item.action}
                  {...item.speedDialActionProps}
                />
              ) : null,
            )}
          </SpeedDial>
        </Portal>
      )}
    </Fragment>
  );
};

const container = connect(
  (state) => ({
    teamLogoUrl: state.team.teamLogoUrl,
    adviserProfilePlus: state.adviserProfilePlus.adviserProfilePlus,
    newReferralCount: state.referral.newReferralCount,
  }),
  {
    getNewReferralCount,
    toggleDialog,
  },
)(AdviserInfoPageManageActions);

export default injectIntl(withRouter(container));
