import React from 'react';
import TemplateEditor from '../TemplateEditor/TemplateEditor';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, IconButton } from '@material-ui/core';
// import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import './TemplateRow.scss';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';
import { tracking } from '../../../utils/tracking';
import NativeOrWeb from "../../../utils/native";

const styles = {
  dense: {
    paddingTop: 0,
    paddingBottom: 0
  },
  ListItemDisabled: {
    backgroundColor: '#80808022',
    '&:hover': {
      '& $child3': {
        visibility: 'visible!important'
      }
    }
  },
  ListItem: {
    '&:hover': {
      backgroundColor: '#80808022',
      '& $child3': {
        visibility: 'visible!important'
      }
    }
  },
  child3: {
    visibility: 'hidden'
  }
};

class TemplateRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      localSubmit: false,
      focus: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submit && !this.props.submit && this.state.localSubmit) {
      this.setState({ localSubmit: false, edit: false, focus: false });
    }
  }

  handleCheckboxClick = () => {
    this.setState({ localSubmit: true });
    this.props.updateAdviserTemplate({
      template: { ...this.props.template, important: !this.props.template.important }
    });
  };

  toggleEditor = () => {
    this.setState({ edit: !this.state.edit, focus: !this.state.focus });
  };
  onDelete = () => {
    this.setState({ localSubmit: true });
    this.props.deleteAdviserTemplate({ templateId: this.props.template._id.toString() });
  };
  onSave = () => this.setState({ localSubmit: true });

  onCopy = () => {
    // Tracking
    tracking('Template Copied');

      NativeOrWeb.copyToClipboard(this.props.template.template).then(() => {
          if (this.props.closeTemplate) {
              this.props.closeTemplate();
          }
          toast.info(IconToast('info', this.props.intl.formatMessage({ id: 'Copied!' })), {
              className: 'new-toast'
          });
      })
  };

  render() {
    const value = this.props.template;
    return (
      <div>
        <Collapse in={!this.state.edit}>
          <ListItem
            key={value._id.toString()}
            dense
            divider
            classes={{
              container: value.completed ? this.props.classes.ListItemDisabled : this.props.classes.ListItem,
              dense: this.props.classes.dense
            }}
          >
            <ListItemIcon>
                <IconButton style={{ marginLeft: '-17px' }} aria-label="copy" disabled={this.state.localSubmit} onClick={this.onCopy}>
                  <FileCopyOutlinedIcon className="copy-btn" />
                </IconButton>
            </ListItemIcon>
            <ListItemText
              className="todo-and-date"
              //escape the ID here since _id can start with a numeric number
              primary={
                <span
                  style={{
                    maxHeight: '45px',
                    maxWidth: '210px',
                    display: 'inline-block',
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    paddingTop: '5px'
                  }}
                >
                  {value.template}
                </span>
              }
            />
            <ListItemSecondaryAction classes={{ root: this.props.classes.child3 }}>
              <IconButton edge="end" aria-label="edit" disabled={this.state.localSubmit} onClick={this.toggleEditor}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Collapse>
        <Collapse in={this.state.edit}>
          <TemplateEditor
            template={value}
            submit={this.state.localSubmit}
            onSave={this.onSave}
            onDelete={this.onDelete}
            closeEditor={this.toggleEditor}
            updateAdviserTemplate={this.props.updateAdviserTemplate}
            deleteAdviserTemplate={this.props.deleteAdviserTemplate}
            focus={this.state.focus}
          />
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(TemplateRow);
