import React, { PureComponent } from 'react';
import { tracking } from '../../../utils/tracking';
import { injectIntl } from 'react-intl';
import ConnectWithAdviserDialog from './ConnectWithAdviserDialog/ConnectWithAdviserDialog';

class ConnectWithAdviser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDevelopment: process.env.NODE_ENV === 'development',
      connectWithAdviserDialog: false
    };
  }
  componentDidMount() {
    this.props.renderHeaderButton(true);
  }

  handleTracking = trackMsg => {
    // tracking
    tracking(trackMsg);
  };

  toggleConnectWithAdviserDialog = open => this.setState({ connectWithAdviserDialog: open });
  openConnectWithAdviserDialog = () => this.toggleConnectWithAdviserDialog(true);

  render() {
    const { intl } = this.props;
    const { connectWithAdviserDialog } = this.state;

    return (
      <div>
        <ConnectWithAdviserDialog open={connectWithAdviserDialog} toggleDialog={this.toggleConnectWithAdviserDialog} />
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid addPolicyPadding policyPadding">
              <ul className="card-list card-list-share">
                <li>
                  <div className="portfolioCard" onClick={this.openConnectWithAdviserDialog}>
                    <img src="img/user.png" alt="" />
                    <h4>{intl.formatMessage({ id: 'Known Adviser' })}</h4>
                  </div>
                </li>
                {/*remove the absolute overlay and relative position when implementing advisor matching*/}
                <li style={{ position: 'relative' }}>
                  <div className="portfolioCard">
                    <img src="img/share_linkAdviser.png" alt="" />
                    <h4>{intl.formatMessage({ id: 'Coming Soon' })}</h4>
                  </div>
                  <div
                    className="portfolioCard"
                    style={{
                      position: 'absolute',
                      backgroundColor: '#00000093',
                      top: 0,
                      height: '100%',
                      marginTop: 'unset'
                    }}
                  ></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ConnectWithAdviser);
