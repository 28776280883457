import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { getWhatsappUrl } from '../../../utils/url';

const LinkExpiredDialog = props => {
  const { intl, open, creator, walletPassLink, onClose } = props;

  const confirm = () => {
    if (creator) {
      const { whatsapp } = creator || {};
      const whatsappUrl = getWhatsappUrl({
        phone: whatsapp,
        text: intl.formatMessage({ id: 'wallet-pass-link-expired-dialog-contact-adviser-msg' })
      });
      window.open(whatsappUrl, '_blank');
    } else {
      window.location.href = '/';
    }
  };

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'wallet-pass-link-expired-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'wallet-pass-link-expired-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={confirm}>
          {intl.formatMessage({ id: creator ? 'wallet-pass-link-expired-dialog-contact-adviser' : 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(LinkExpiredDialog);
