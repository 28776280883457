import React, { Fragment, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import moment from 'moment-timezone';
import MyResizeObserver from '../../../components/MyResizeObserver/MyResizeObserver';
import CreateIcon from '@material-ui/icons/Create';

const PolicyReviewHeading = props => {
  const { className, name, title, remarkOnClick, remarkDisabled, template, autoAdjustLayout, ...rest } = props;

  const [isRowLayout, setIsRowLayout] = useState(true);
  const containerRef = useRef(null);
  const dateStr = moment().format('DD-MMM-YYYY');

  const onResize = () => {
    if (autoAdjustLayout) {
      const containerElem = containerRef.current;
      if (containerElem) {
        const titleElem = containerElem.querySelector('.policy-review-heading-title');
        if (titleElem) {
          const nameElem = containerElem.querySelector('.policy-review-heading-name');
          const dateElem = containerElem.querySelector('.policy-review-heading-date');

          const { offsetWidth: containerWidth, offsetHeight: containerHeight } = containerElem;
          const { offsetWidth: titleWidth, offsetHeight: titleHeight } = titleElem;
          const { offsetWidth: nameWidth } = nameElem || {};
          const { offsetWidth: dateWidth } = dateElem || {};

          if (isRowLayout) {
            if (titleWidth >= containerWidth || titleHeight !== containerHeight) {
              setIsRowLayout(false);
            }
          } else {
            const halfWidth = (containerWidth - titleWidth) / 2;
            if (titleWidth + (nameWidth || halfWidth) + (dateWidth || halfWidth) <= containerWidth) {
              setIsRowLayout(true);
            }
          }
        }
      }
    }
  };

  return (
    <Fragment>
      {autoAdjustLayout && <MyResizeObserver targetsSelector=".policy-review-heading-title" onResize={onResize} />}
      <Grid
        className={`policy-review-heading${className ? ` ${className}` : ''}`}
        {...rest}
        container
        direction={isRowLayout ? 'row' : 'column'}
        justify={isRowLayout ? 'space-between' : 'center'}
        alignItems="center"
        ref={containerRef}
      >
        <Grid item style={!isRowLayout ? { width: '100%' } : undefined}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className="policy-review-heading-name" style={{ fontSize: 20 }}>
                {name}
              </Typography>
            </Grid>
            {!isRowLayout && (
              <Grid item>
                <Typography className="policy-review-heading-date">{dateStr}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid className="policy-review-heading-title" item style={{ textAlign: 'center', padding: 16, display: "inline-flex" }}>
          {title}
          {!template && !!title &&
            <Button
              color="primary"
              className="edit-info-button"
              onClick={remarkOnClick}
              disabled={remarkDisabled}
            >
              <CreateIcon />
            </Button>
          }
        </Grid>
        {isRowLayout && (
          <Grid item>
            <Typography className="policy-review-heading-date">{dateStr}</Typography>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default PolicyReviewHeading;
