import React from 'react';
import { Box } from '@material-ui/core';

const COLORS = [
  {
    bg: '#DDE3E7',
    label: '#435362'
  },
  {
    bg: '#D7E4F8',
    label: '#234DA0'
  },
  {
    bg: '#F5DDDA',
    label: '#942619'
  },
  {
    bg: '#CEEAD3',
    label: '#285D47'
  },
  {
    bg: '#F9F6D3',
    label: '#1F2B35'
  },
  {
    bg: '#F7DECA',
    label: '#843A1F'
  },
  {
    bg: '#DAEFB7',
    label: '#3A5C1E'
  },
  {
    bg: '#CDE7EC',
    label: '#2C5B5D'
  },
  {
    bg: '#C7EAE7',
    label: '#275869'
  },
  {
    bg: '#E5F0F3',
    label: '#245486'
  },
  {
    bg: '#DEE0F8',
    label: '#3C44AA'
  },
  {
    bg: '#E8DCFA',
    label: '#6636A6'
  },
  {
    bg: '#F2DBEC',
    label: '#8A2471'
  }
];

// https://design.gs.com/components/tag
const GSTag = props => {
  const { label, color: colorIndex = 0, onClick } = props;

  const color = COLORS[colorIndex % COLORS.length];

  return (
    <div
      style={{
        padding: '4px 12px',
        borderRadius: 16,
        backgroundColor: color.bg,
        color: color.label,
        cursor: onClick ? 'pointer' : undefined
      }}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default GSTag;
