import React, { Fragment } from 'react';
import { Typography, Button } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import './PaymentSuccess.scss';

const PaymentSuccess = props => {
  const { intl, location, onWhatsapp, onLogin, emailVerified } = props;

  return (
    <div className="payment-success">
      <div className="wrapper">
        <div className="img-wrapper">
          <CheckCircleRoundedIcon color="primary" />
        </div>

        <Typography variant="h3">{intl.formatMessage({ id: 'Thank you' })}</Typography>
        {!emailVerified ?
          <Fragment>
            <Typography variant="h3">{intl.formatMessage({ id: 'payment-success-last-step' })}</Typography>
            <Typography variant="h5">{intl.formatMessage({ id: 'payment-success-hint' })}</Typography>
          </Fragment>
          :
          <Fragment>
            <Typography variant="h5">{intl.formatMessage({ id: 'payment-success-hint-verified' })}</Typography>
          </Fragment>
        }

        {onWhatsapp && (
          <Button className="action" variant="outlined" color="primary" fullWidth onClick={onWhatsapp}>
            {intl.formatMessage({ id: 'payment-success-support' })}
          </Button>
        )}

        <Button className="action" variant="contained" color="primary" fullWidth onClick={onLogin}>
          {intl.formatMessage({
            id:
              location.pathname.toLowerCase() === '/signup'
                ? !emailVerified
                  ? 'get-started'
                  : 'payment-success-login-again'
                : 'Start Now'
          })}
        </Button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
