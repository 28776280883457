import { fetch } from '../../utils';
import Backend from '../../backend';

export const getPFinCalculatorProfiles = async () => {
  return fetch(Backend.methodUrl("pFin-calculator"))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const updatePFinCalculatorProfiles = async (body) => {
  return fetch(Backend.methodUrl("pFin-calculator"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
