import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, MenuItem, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import MoreInfoTrigger from '../../../../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import TopClientTable from './TopClientTable/TopClientTable';
import SwipeableViews from 'react-swipeable-views';
import { withRouter } from 'react-router';
import { getUserCurrency } from '../../../../../../utils/user';
import BlockingOverlay from '../../../../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';

const TableHeaderGridStyle = {
  padding: '0 8px'
};

const MoreInfoButtonStyle = {
  position: 'relative',
  padding: 4
};

const TabsGridStyle = {
  padding: '0 8px'
};

const TabStyle = {
  fontSize: '1rem',
  minWidth: 'unset'
};

const TopClientStatistics = props => {
  const { intl, history, userDetails, topClientStatistics, clients } = props;

  const [view, setView] = useState(0);
  const [tab, setTab] = useState(0);
  const tempIndexRef = useRef(undefined);

  const {
    income,
    premiumSavingILASMPF,
    premiumSaving,
    premiumILAS,
    premiumMPF,
    marketValueSavingILASMPF,
    marketValueSaving,
    marketValueILAS,
    marketValueMPF
  } = topClientStatistics || {};

  const selectView = event => setView(event.target.value);
  const onChangeTab = (event, value) => {
    tempIndexRef.current = undefined;
    setTab(value);
  };
  const onChangeSwipeable = index => (tempIndexRef.current = index);
  const onSwipeableTransitionEnd = () => {
    if (tempIndexRef.current) {
      setTab(tempIndexRef.current);
    }
  };
  const onClickItem = item => {
    const selectedClient = (clients || []).find(client => client._id === item.userId);
    history.push({
      pathname: '/client',
      select: 'lucky',
      selectedClient: selectedClient,
      smartIdea: 'smart-idea-lucky-hint'
    });
  };

  const currency = getUserCurrency(userDetails);
  const firstTableValueIntl = `${intl.formatMessage({
    id: view === 0 ? 'monthly-premium' : 'Mkt-value'
  })} (${intl.formatMessage({ id: currency })})`;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} style={TableHeaderGridStyle}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <img src="img/adviser-dashboard/premium.svg" alt="top-asset-clients" width="20" />
                  </Grid>
                  <Grid item>
                    <Typography>
                      {intl.formatMessage({ id: view === 0 ? 'top-premium-clients' : 'top-asset-clients' })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <MoreInfoTrigger contentIntlId="top-10-client-moreInfo" style={MoreInfoButtonStyle} />
                  </Grid>
                  <Grid item>
                    <TextField
                      select
                      color="success"
                      focused
                      value={view}
                      onChange={selectView}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value={0}>{intl.formatMessage({ id: 'monthly-premium' })}</MenuItem>
                      <MenuItem value={1}>{intl.formatMessage({ id: 'Mkt-value' })}</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} style={TabsGridStyle}>
                <Tabs
                  value={tab}
                  onChange={onChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    value={0}
                    label={<Typography>{intl.formatMessage({ id: 'Item-Total' })}</Typography>}
                    style={TabStyle}
                  />
                  <Tab
                    value={1}
                    label={<Typography>{intl.formatMessage({ id: 'Saving' })}</Typography>}
                    style={TabStyle}
                  />
                  <Tab
                    value={2}
                    label={<Typography>{intl.formatMessage({ id: 'ILAS' })}</Typography>}
                    style={TabStyle}
                  />
                  <Tab
                    value={3}
                    label={<Typography>{intl.formatMessage({ id: 'Provident Fund' })}</Typography>}
                    style={TabStyle}
                  />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <SwipeableViews
                  index={tab}
                  onChangeIndex={onChangeSwipeable}
                  onTransitionEnd={onSwipeableTransitionEnd}
                >
                  <BlockingOverlay>
                    <div className="cardAnalysis">
                      {view === 0 ? (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={premiumSavingILASMPF}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      ) : (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={marketValueSavingILASMPF}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      )}
                    </div>
                  </BlockingOverlay>
                  <BlockingOverlay>
                    <div className="cardAnalysis">
                      {view === 0 ? (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={premiumSaving}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      ) : (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={marketValueSaving}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      )}
                    </div>
                  </BlockingOverlay>
                  <BlockingOverlay>
                    <div className="cardAnalysis">
                      {view === 0 ? (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={premiumILAS}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      ) : (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={marketValueILAS}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      )}
                    </div>
                  </BlockingOverlay>
                  <BlockingOverlay>
                    <div className="cardAnalysis">
                      {view === 0 ? (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={premiumMPF}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      ) : (
                        <TopClientTable
                          valueLabel={firstTableValueIntl}
                          items={marketValueMPF}
                          progressColor={'#ffaf19'}
                          progressBackgroundColor={'#f7eae1'}
                          onClickItem={onClickItem}
                        />
                      )}
                    </div>
                  </BlockingOverlay>
                </SwipeableViews>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} style={TableHeaderGridStyle}>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <img src="img/adviser-dashboard/income.svg" alt="top-income-clients" width="20" />
                  </Grid>
                  <Grid item>
                    <Typography>{intl.formatMessage({ id: 'top-income-clients' })}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BlockingOverlay>
              <div className="cardAnalysis">
                <TopClientTable
                  valueLabel={intl.formatMessage({ id: 'Monthly income' })}
                  items={income}
                  progressColor={'#5ee092'}
                  progressBackgroundColor={'#e2eef7'}
                  onClickItem={onClickItem}
                />
              </div>
            </BlockingOverlay>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    topClientStatistics: state.adviserDashboard.topClientStatistics,
    clients: state.client.clientData
  }),
  {}
)(TopClientStatistics);

export default injectIntl(withRouter(container));
