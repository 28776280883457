import produce from 'immer';
import * as api from './api';
import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
/***********************************
 * Action Types
 ***********/
export const UPDATE_EMERGENCY_EXPORT_INPUTS = 'UPDATE_EMERGENCY_EXPORT_INPUTS';
export const UPDATE_EMERGENCY_EXPORT = 'UPDATE_EMERGENCY_EXPORT';
export const RESET_EMERGENCY_EXPORT = 'RESET_EMERGENCY_EXPORT';

/***********************************
 * Initial State
 ***********/
const initialState = {
  loading: false,
  getPhase: INIT,
  updatePhase: INIT,
  emergencyExport: {}
};

/***********************************
 * Reducer
 ***********/
export default function reducer(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_EMERGENCY_EXPORT_INPUTS:
        draft[action.key] = action.value;
        break;
      case UPDATE_EMERGENCY_EXPORT:
        draft.emergencyExport = action.value;
        break;
      case RESET_EMERGENCY_EXPORT:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const updateEmergencyExportInputs = (key, value) => ({
  type: UPDATE_EMERGENCY_EXPORT_INPUTS,
  key,
  value
});

export const getEmergencyExport = clientId => async (dispatch, getState) => {
  dispatch({ type: UPDATE_EMERGENCY_EXPORT_INPUTS, key: 'getPhase', value: LOADING });
  try {
    const result = clientId ? await api.getEmergencyExport(clientId) : {};
    dispatch({ type: UPDATE_EMERGENCY_EXPORT, value: result.emergencyExport });
    dispatch({ type: UPDATE_EMERGENCY_EXPORT_INPUTS, key: 'getPhase', value: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_EMERGENCY_EXPORT_INPUTS, key: 'getPhase', value: ERROR });
  }
};

export const updateEmergencyExport = (clientId, emergencyExport) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_EMERGENCY_EXPORT_INPUTS, key: 'updatePhase', value: LOADING });
  try {
    const result = clientId ? await api.updateEmergencyExport(clientId, emergencyExport) : {};
    dispatch({ type: UPDATE_EMERGENCY_EXPORT, value: result.emergencyExport });
    dispatch({ type: UPDATE_EMERGENCY_EXPORT_INPUTS, key: 'updatePhase', value: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_EMERGENCY_EXPORT_INPUTS, key: 'updatePhase', value: ERROR });
  }
};
