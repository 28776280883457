import React from 'react';
import { Typography } from '@material-ui/core';

class Welcome extends React.PureComponent {
  render() {
    const { intl } = this.props;
    return (
      <div key={0} style={{ paddingLeft: '10vw', paddingRight: '10vw', textAlign: 'center' }}>
        <div style={{ width: '80vw', textAlign: 'left', maxWidth: 600, margin: 'auto', marginTop: 10 }}>
          <Typography style={{ fontWeight: 'bold', fontSize: '23px' }} variant="h4">
            {intl.formatMessage({ id: `welcome-user-heading` })}
          </Typography>
        </div>
        <div style={{ width: '80vw' }}>
          <img src={`animated-svg/hello-animate.svg`} alt="" className="welcome-gif" />
        </div>
        <div style={{ width: '80vw', textAlign: 'center', marginTop: 10, fontSize: '18px' }}>
          <Typography style={{ fontSize: '18px' }}>{intl.formatMessage({ id: 'welcome-user-content' })}</Typography>
          <Typography style={{ fontSize: '18px' }}>{intl.formatMessage({ id: 'welcome-user-content2' })}</Typography>
          <Typography style={{ fontSize: '26px' }} color="primary">
            {intl.formatMessage({ id: `welcome-user-highlight` })}
          </Typography>
        </div>
      </div>
    );
  }
}

export default Welcome;
