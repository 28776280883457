import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import '../TodoList.scss';
import './TodoMenu.scss';

const styles = {
  menuItem: {
    borderBottom: 'solid 1px #000'
  }
};

class TodoMenu extends React.PureComponent {
  render() {
    return (
      <Menu
        open={this.props.dropDownAnchor !== null}
        anchorEl={this.props.dropDownAnchor}
        keepMounted
        onClose={this.props.onDropDownClose}
      >
        <MenuItem classes={{ root: this.props.classes.menuItem }} onClick={this.props.openDeleteModal}>
          <DeleteIcon className="delete-icon" />
          <div className="delete-all">{this.props.intl.formatMessage({ id: 'delete-all-completed-todo' })}</div>
        </MenuItem>
        <div className="full-width list-container sort-by">{this.props.intl.formatMessage({ id: 'sort-by' })}</div>
        <MenuItem
          className="arrow"
          onClick={() =>
            this.props.sort === 'creationDate'
              ? this.props.onDropDownClose()
              : this.props.handleChangeSort({ sort: 'creationDate' })
          }
        >
          <ArrowForwardIcon className={this.props.sort === 'creationDate' ? '' : 'inactive'} />
          {this.props.intl.formatMessage({ id: 'creation-date-sort' })}
        </MenuItem>
        <MenuItem
          className="arrow"
          onClick={() =>
            this.props.sort === 'creationDateNewToOld'
              ? this.props.onDropDownClose()
              : this.props.handleChangeSort({ sort: 'creationDateNewToOld' })
          }
        >
          <ArrowForwardIcon className={this.props.sort === 'creationDateNewToOld' ? '' : 'inactive'} />
          {this.props.intl.formatMessage({ id: 'creation-date-sort-new-to-old' })}
        </MenuItem>
        <MenuItem
          className="arrow"
          onClick={() =>
            this.props.sort === 'dueDate'
              ? this.props.onDropDownClose()
              : this.props.handleChangeSort({ sort: 'dueDate' })
          }
        >
          <ArrowForwardIcon className={this.props.sort === 'dueDate' ? '' : 'inactive'} />
          {this.props.intl.formatMessage({ id: 'due-date-sort' })}
        </MenuItem>
      </Menu>
    );
  }
}

export default injectIntl(withStyles(styles)(TodoMenu));
