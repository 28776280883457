import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { tracking } from '../../../../utils/tracking';

const ConfirmSaveDialog = props => {
  const { intl, pyramidIndex, open, onClose, onConfirm, isPFinCalculator, ...rest } = props;

  const confirm = () => {
    isPFinCalculator ? null : tracking('Click on Information saving - Financial pyramid');
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'fin-py-save-dialog-title' })}</DialogTitle>
      <DialogContent>
        {intl.formatMessage({ id: `${isPFinCalculator ? "pfa" : "fin-py"}-save-dialog-content` })}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={confirm}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ConfirmSaveDialog);
