import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import IconToast from '../../../components/NewToast';
import { validatePassword, validatePasswordConfirm } from '../../../utils/validators';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import PasswordRulesInfoTooltip from '../../../components/PasswordRulesInfoTooltip/PasswordRulesInfoTooltip';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

export default class ResetPasswordView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confPassword: '',
      newPassword: '',
      id: '',
      err: {},
      loginStatus: false
    };
    this.submitForm = this.submitForm.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.Id) {
      var id = this.props.location.pathname.replace('/reset_password/', '');
      this.setState({ id: id });
    }
  }

  componentDidMount() {
    //to display the page language as per the browser language
    var user = this.props.userDetails;
    if (_.isEmpty(user)) {
      var userLang = navigator.language || navigator.userLanguage;
      const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      this.props.selectedLocale(language);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.resetPasswordPhase === true) {
      this.setState({
        newPassword: '',
        confPassword: ''
      });
      this.setState({ loginStatus: true });
      toast.info(IconToast('info', intl.formatMessage({ id: nextProps.message })), { className: 'new-toast' });
    }
    if (nextProps.resetPasswordPhase === false) {
      toast.info(IconToast('info', intl.formatMessage({ id: nextProps.message })), { className: 'new-toast' });
    }
  }

  handleChange(e) {
    if (this.state.err.newPassword !== '') {
      delete this.state.err.newPassword;
    }
    if (this.state.err.confPassword !== '') {
      delete this.state.err.confPassword;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  //sending the new password to update in users record
  submitForm() {
    const err = {};
    if (this.state.newPassword === '' || this.state.confPassword === '') {
      err.confPassword = 'Enter complete details';
    }

    const passwordValidationResult = validatePassword(this.state.newPassword);
    if (passwordValidationResult) {
      err.newPassword = passwordValidationResult;
    }
    const passwordConfirmValidationResult = validatePasswordConfirm(this.state.confPassword, this.state.newPassword);
    if (passwordConfirmValidationResult) {
      err.confPassword = passwordConfirmValidationResult;
    }

    this.setState({ err });
    if (!Object.keys(err).length) {
      var formData = {};
      formData.token = this.state.id;
      formData.password = this.state.newPassword;
      this.props.resetPassword(formData);
    }
  }
  backToLogin() {
    const { history } = this.props;
    history.push({
      pathname: '/login'
    });
  }

  render() {
    const { intl } = this.props;
    const { loginStatus } = this.state;
    return (
      <AppContentContainer>
        {this.props.resetPasswordPhase === 'loading' && <LoadingBackdrop open={true} />}
        <div id="loginWrapper">
          <div className="page-containt">
            <img alt="" style={{ marginTop: '50px' }} className="logo" src="img/logo_kyc.png" />
          </div>
          <div className="sub-function-heading">
            <h4>{intl.formatMessage({ id: 'Reset Password' })}</h4>
          </div>

          <div className="myForm referral-form">
            <form>
              <div className="form-group">
                <PasswordRulesInfoTooltip password={this.state.newPassword}>
                  <input
                    type="password"
                    autoComplete="new-password"
                    name="newPassword"
                    value={this.state.newPassword}
                    onChange={this.handleChange.bind(this)}
                    required="required"
                  />
                </PasswordRulesInfoTooltip>
                <label className="control-label" htmlFor="input">
                  {intl.formatMessage({ id: 'New Password*' })}
                </label>
                <i className="bar" />
                {this.state.err.newPassword ? (
                  <span className="error_field">{intl.formatMessage({ id: this.state.err.newPassword })}</span>
                ) : (
                  ''
                )}
              </div>
              <div className="form-group">
                <PasswordRulesInfoTooltip password={this.state.confPassword}>
                  <input
                    type="password"
                    autoComplete="new-password"
                    name="confPassword"
                    value={this.state.confPassword}
                    onChange={this.handleChange.bind(this)}
                    required="required"
                  />
                </PasswordRulesInfoTooltip>
                <label className="control-label" htmlFor="input">
                  {intl.formatMessage({ id: 'Confirm Password' })}*
                </label>
                <i className="bar" />
                {this.state.err.confPassword ? (
                  <span className="error_field">{intl.formatMessage({ id: this.state.err.confPassword })}</span>
                ) : (
                  ''
                )}
              </div>
            </form>
            <div className="upDownGap" style={{ marginTop: '50px' }}>
              <button
                //className="btn-login"
                className={!loginStatus ? 'btn-login' : 'btn-login-disabled'}
                onClick={this.submitForm}
                disabled={loginStatus}
              >
                {intl.formatMessage({ id: 'Submit' })}
              </button>
            </div>
            {loginStatus && (
              <div className="upDownGap" style={{ marginTop: '25px' }}>
                <button className="btn-back-login" onClick={this.backToLogin}>
                  {intl.formatMessage({ id: 'Back to Login' })}
                </button>
              </div>
            )}
          </div>
        </div>
      </AppContentContainer>
    );
  }
}
