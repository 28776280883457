import React from 'react';
import { MenuItem, Menu } from '@material-ui/core';

const DEFAULT_FONT_SIZES = [11, 12, 14, 16, 18, 24, 30, 36];

class FontSizeButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const { theme, onChange, editorState, fontSizeStyles } = this.props;
    const { open } = this.state;

    let currentFontSize = 14;
    if (fontSizeStyles) {
      const foundSize = fontSizeStyles.current(editorState);
      if (foundSize) {
        currentFontSize = foundSize.toString().replace('px', '');
      }
    }

    // if (!DEFAULT_FONT_SIZES.find(fontSize => fontSize.toString() === currentFontSize.toString())) {
    //     currentFontSize =
    // }

    return (
      <div
        className={theme.buttonWrapper}
        onMouseDown={event => event.preventDefault()}
        ref={node => {
          if (node) this.parent = node;
        }}
      >
        <button
          type="button"
          className={`${theme.button} text`}
          onClick={event => {
            event.stopPropagation();
            this.setState({ open: true });
          }}
        >
          <div>{currentFontSize}</div>
        </button>

        <Menu
          disableAutoFocus={true}
          disableEnforceFocus={true}
          disableRestoreFocus={true}
          disableAutoFocusItem={true}
          anchorEl={this.parent}
          autoFocus={false}
          open={open}
        >
          {DEFAULT_FONT_SIZES.map((fontSize, index) => (
            <MenuItem
              key={index}
              value={fontSize}
              onClick={event => {
                event.stopPropagation();
                this.setState({ open: false });
                const newEditorState = fontSizeStyles.toggle(editorState, `${fontSize}px`);
                onChange(newEditorState);
              }}
            >
              {fontSize}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default FontSizeButton;
