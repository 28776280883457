import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import {
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button
} from '@material-ui/core';
import moment from 'moment';
import { openaiGenerateWithTextStreaming } from '../../../store/openai/api';
import { bedrockGenerateWithText } from '../../../store/bedrock/api';
import { decimalToString } from '../../../utils/formatNumber';
import BottomSheetOrDialog from '../../BottomSheetOrDialog/BottomSheetOrDialog';
import NativeOrWeb from '../../../utils/native';
import { isClientDisconnected } from '../utils';
import { decreaseGPTQuota, handleError, validateGPTQuota } from '../../../utils/openai';
import { vertexGenerateWithTextStreaming, vertexCountTokens } from '../../../store/vertex/api';
import './ChatGPTDialog.scss';

const factsheetFields = {
  name: {
    label: 'Name'
  },
  source: {
    label: 'Cold / Warm / Refer'
  },
  referrerName: {
    label: 'Referrer Name'
  },
  importance: {
    label: 'Normal / VIP / V.VIP'
  },
  tags: {
    label: 'Tags',
    format: value => (value || []).map(tag => tag.text).join(' ')
  },
  network: {
    label: 'Group'
  },
  latestMeetingDate: {
    label: 'Latest Meeting',
    format: value => (value ? moment(value).format('DD-MMM-YYYY') : '')
  },
  todayDate: {
    label: 'Today Date',
    format: () => moment().format('DD-MMM-YYYY')
  },
  isRecruitment: {
    label: 'Recruitment target'
  },
  personality: {
    label: 'DISC Personality'
  },
  dob: {
    label: 'Date of Birth',
    format: value => (value ? moment(value).format('DD-MMM-YYYY') : '')
  },
  age: {
    label: 'Age'
  },
  gender: {
    label: 'Gender'
  },
  smoker: {
    label: 'Is Smoker'
  },
  nationality: {
    label: 'Nationality'
  },
  birthPlace: {
    label: 'Birth Place'
  },
  occupation: {
    label: 'Occupation'
  },
  marriageStatus: {
    label: 'Marriage Status'
  },
  child: {
    label: 'No. of Child'
  },
  monthlyIncome: {
    label: 'Monthly Income in HKD',
    format: value => (value || value === 0 ? decimalToString(value) : '')
  },
  monthlyExpense: {
    label: 'Monthly Expense in HKD',
    format: value => (value || value === 0 ? decimalToString(value) : '')
  },
  liquidAsset: {
    label: 'Liquid Asset'
  },
  interest: {
    label: 'Interest'
  },
  remark: {
    label: 'Remark'
  }
};

const ChatGPTDialog = props => {
  const { intl, chatGPTDialog, userDetails, toggleDialog } = props;

  const contentDivRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [model, setModel] = useState('gpt-4o');
  const [temperature, setTemperature] = useState(0.8);
  const [topP, setTopP] = useState(1.0);
  const [maxGenLen, setMaxGenLen] = useState(512);
  const [maxTokenCount, setMaxTokenCount] = useState(4096);
  const [maxOutputTokens, setMaxOutputTokens] = useState(8192);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0.0);
  const [presencePenalty, setPresencePenalty] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [geminiFileUri, setGeminiFileUri] = useState('');
  const [geminiFileType, setGeminiFileType] = useState('');
  const [geminiTokensCountResult, setGeminiTokensCountResult] = useState('');

  const { client, closed } = chatGPTDialog || {};

  const isDisconnected = isClientDisconnected(client);

  const {
    factsheetId,
    lifeCoverage,
    ciCoverage,
    medicalPolicyCounts,
    totalPremiumSaving,
    totalWithILAS,
    savingPolicyCount,
    ilasPolicyCount
  } = client || {};

  const factsheetText = Object.entries(factsheetFields)
    .map(entry => {
      const fieldKey = entry[0];
      const field = entry[1];
      const fieldValue = factsheetId?.[fieldKey] ?? '';
      const formattedValue = field.format ? field.format(fieldValue) : fieldValue;
      return formattedValue ? `${field.label}: ${formattedValue}` : '';
    })
    .filter(str => !!str)
    .join('\n');

  let policyText = '';

  if (!isDisconnected) {
    policyText += `Life coverage: HKD ${decimalToString(lifeCoverage)}\n`;
    policyText += `Critical coverage: HKD ${decimalToString(ciCoverage)}\n`;
    policyText += `${
      medicalPolicyCounts?.highend || medicalPolicyCounts?.others ? 'Have' : 'Do not have'
    } Medical coverage\n`;
    policyText += `${
      savingPolicyCount ? 'Have' : 'Do not have'
    } saving insurance (paying monthly premium HKD ${decimalToString(totalPremiumSaving)})\n`;
    policyText += `${
      ilasPolicyCount ? 'Have' : 'Do not have'
    } investment insurance (paying monthly premium HKD ${decimalToString(totalWithILAS)})`;
  }

  const onChangeInputMessage = event => {
    setGeminiTokensCountResult('');
    setInputMessage(event.target.value);
  };

  const onChangeModel = event => {
    setModel(event.target.value);
  };

  const onChangeTemperature = event => {
    setTemperature(event.target.value);
  };

  const onChangeTopP = event => {
    setTopP(event.target.value);
  };

  const onChangeMaxGenLen = event => {
    setMaxGenLen(event.target.value);
  };

  const onChangeMaxTokenCount = event => {
    setMaxTokenCount(event.target.value);
  };

  const onChangeMaxOutputTokens = event => {
    setMaxOutputTokens(event.target.value);
  };

  const onChangeFrequencyPenalty = event => {
    setFrequencyPenalty(event.target.value);
  };

  const onChangePresencePenalty = event => {
    setPresencePenalty(event.target.value);
  };

  const onChangeGeminiFileType = event => {
    setGeminiFileType(event.target.value);
  };

  const onChangeGeminiFileUri = event => {
    setGeminiFileUri(event.target.value);
  };

  const clearInputMessage = () => {
    setGeminiTokensCountResult('');
    setChatMessages([]);
  };

  const geminiFileTypes = [
    'application/pdf',
    'audio/mpeg',
    'audio/mp3',
    'audio/wav',
    'image/png',
    'image/jpeg',
    'text/plain',
    'video/mov',
    'video/mpeg',
    'video/mp4',
    'video/mpg',
    'video/avi',
    'video/wmv',
    'video/mpegps',
    'video/flv'
  ];

  function send() {
    setGeminiTokensCountResult('');
    if (model.includes('gpt')) {
      azureSend(model);
    } else if (model.includes('gemini')) {
      geminiSend(model);
    } else {
      generalSend(model);
    }
  }

  const azureSend = async model => {
    try {
      validateGPTQuota();

      setLoading(true);
      const addedChatMessages = [...chatMessages, { content: inputMessage, role: 'user' }];
      await openaiGenerateWithTextStreaming(
        model,
        addedChatMessages,
        parseFloat(temperature),
        parseFloat(topP),
        parseFloat(frequencyPenalty),
        parseFloat(presencePenalty),
        setChatMessages
      );

      decreaseGPTQuota();
      setInputMessage('');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const geminiSend = async model => {
    try {
      validateGPTQuota();

      setLoading(true);
      if (geminiFileType && geminiFileUri) {
        var addedChatMessages = [
          ...chatMessages,
          { content: inputMessage, mimeType: geminiFileType, fileUri: geminiFileUri, role: 'user' }
        ];
      } else {
        var addedChatMessages = [...chatMessages, { content: inputMessage, role: 'user' }];
      }

      // Convert into Gemini format
      let contents = [];
      for (let message of addedChatMessages) {
        let textPart = { text: message.content };
        if (message.mimeType && message.fileUri) {
          let filePart = { fileData: { fileUri: message.fileUri.trim(), mimeType: message.mimeType } };
          contents.push({ role: message.role, parts: [textPart, filePart] });
        } else {
          contents.push({ role: message.role, parts: [textPart] });
        }
      }
      await vertexGenerateWithTextStreaming(
        model,
        addedChatMessages,
        contents,
        parseFloat(temperature),
        parseFloat(topP),
        parseInt(maxOutputTokens),
        setChatMessages
      );

      decreaseGPTQuota();
      setInputMessage('');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const geminiCountTokens = async () => {
    try {
      setLoading(true);
      let textPart = { text: inputMessage };
      if (geminiFileUri && geminiFileType) {
        let filePart = { fileData: { fileUri: geminiFileUri.trim(), mimeType: geminiFileType } };
        var contents = [{ role: 'user', parts: [textPart, filePart] }];
      } else {
        var contents = [{ role: 'user', parts: [textPart] }];
      }
      let result = await vertexCountTokens(model, contents);
      if (result.success) {
        setGeminiTokensCountResult(
          `Total Tokens: ${result.totalTokens}, Total Billable Characters: ${result.totalBillableCharacters} `
        );
      } else {
        setGeminiTokensCountResult('Something went wrong when counting tokens');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const generalSend = async model => {
    try {
      validateGPTQuota();

      setLoading(true);
      const addedChatMessages = [...chatMessages, { content: inputMessage, role: 'user' }];
      let prompt = '';
      for (let message of addedChatMessages) {
        let role;
        if (message.role === 'user') {
          role = 'User';
        } else if (message.role === 'assistant') {
          role = 'Bot';
        } else {
          role = 'System';
        }
        prompt += `${role}: ${message.content} `;
      }
      var response = await bedrockGenerateWithText(
        model,
        prompt,
        parseFloat(temperature),
        parseFloat(topP),
        parseInt(maxGenLen),
        parseInt(maxTokenCount)
      );

      decreaseGPTQuota();
      setChatMessages([...addedChatMessages, { content: response, role: 'assistant' }]);
      setInputMessage('');
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    toggleDialog('chatGPT', { ...chatGPTDialog, closed: true });
  };

  const onExited = () => {
    toggleDialog('chatGPT', false);
  };

  useEffect(() => {
    if (!!chatGPTDialog && !chatGPTDialog.closed) {
      NativeOrWeb.copyToClipboard(`${factsheetText}${policyText ? `\n${policyText}` : ''}`);
    }
  }, [chatGPTDialog, factsheetText, policyText]);

  useEffect(() => {
    if (!chatGPTDialog) {
      setInputMessage('');
      setChatMessages([]);
      setLoading(false);
    }
  }, [chatGPTDialog]);

  useEffect(() => {
    const contentDiv = contentDivRef.current;
    if (contentDiv) {
      contentDiv.scrollTop = contentDiv.scrollHeight;
    }
  }, [chatMessages]);

  const header = intl.formatMessage({ id: 'chat-gpt-dialog-title' });

  const content = (
    <Grid container direction="column" spacing={1} style={{ height: '100%', flexFlow: 'column' }}>
      <Grid item style={{ width: '100%' }}>
        <div
          style={{
            height: '100%',
            position: 'relative',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 4,
            padding: '8px 0'
          }}
        >
          <label
            style={{
              transform: 'translate(14px, -6px) scale(0.75)',
              transformOrigin: 'top left',
              zIndex: 1,
              top: 0,
              left: 0,
              position: 'absolute',
              color: 'rgba(0, 0, 0, 0.54)',
              background: '#fff',
              padding: '0 6px'
            }}
          >
            {intl.formatMessage({ id: 'chat-gpt-dialog-respond' })}
          </label>

          <div
            style={{
              height: '90%',
              padding: '10.5px 14px',
              zIndex: 2,
              whiteSpace: 'pre-wrap',
              overflow: 'auto'
            }}
            ref={contentDivRef}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography style={{ fontSize: '12px' }}>{factsheetText}</Typography>
              </Grid>
              {policyText && (
                <Grid item>
                  <Typography style={{ fontSize: '12px' }}>{policyText}</Typography>
                </Grid>
              )}
              {chatMessages && chatMessages.length > 0 && (
                <Grid item>
                  <Grid container>
                    {chatMessages.map((message, index) => (
                      <Grid item key={index} style={{ width: '100%' }}>
                        <Typography
                          align={message.role === 'assistant' ? 'left' : 'right'}
                          style={{
                            color: message.role === 'assistant' ? '#3966f8' : '#A0A0A0'
                          }}
                        >
                          {message.role === 'assistant' ? 'AI:\n' : 'Question:\n'}
                          {(message.content || '').trim()}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Grid container direction="column" alignItems="flex-end" spacing={1}>
          <Grid item style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: 'chat-gpt-dialog-input' })}
              value={inputMessage}
              onChange={onChangeInputMessage}
              multiline={true}
              rows={6}
              disabled={loading}
            />
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={clearInputMessage}
              disabled={chatMessages.length === 0 || loading}
            >
              {loading ? <CircularProgress size={24} color="primary" /> : 'Clear Conversation'}
            </Button>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              {model.includes('gemini') && (
                <>
                  <span>{geminiTokensCountResult}</span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={geminiCountTokens}
                    disabled={!inputMessage || loading}
                    style={{ marginLeft: 5, marginRight: 5 }}
                  >
                    {loading ? <CircularProgress size={24} color="primary" /> : 'Count Tokens'}
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={send}
                disabled={!inputMessage || loading}
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                {loading ? <CircularProgress size={24} color="primary" /> : 'Send'}
              </Button>
            </div>
          </Grid>

          <Grid container alignItems="center">
            <RadioGroup row name="select-model-buttons" value={model} onChange={onChangeModel}>
              <FormControlLabel value="gpt-35" control={<Radio />} label="GPT 3.5" />
              <FormControlLabel value="gpt-4" control={<Radio />} label="GPT 4" />
              <FormControlLabel value="gpt-4o" control={<Radio />} label="GPT 4o" />
              <FormControlLabel value="mistral" control={<Radio />} label="Mistral" />
              <FormControlLabel value="llama" control={<Radio />} label="Llama" />
              <FormControlLabel value="titan_express" control={<Radio />} label="Titan Express" />
              <FormControlLabel value="gemini-1.0" control={<Radio />} label="Gemini 1.0" />
              <FormControlLabel value="gemini-1.5" control={<Radio />} label="Gemini 1.5" />
            </RadioGroup>
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                label="Temperature"
                value={temperature}
                onChange={onChangeTemperature}
                type="number"
              />
            </Grid>
            {(model.includes('GPT') ||
              model.includes('llama') ||
              model.includes('titan') ||
              model.includes('gemini')) && (
              <Grid item xs={2}>
                <TextField variant="outlined" label="Top P" value={topP} onChange={onChangeTopP} type="number" />
              </Grid>
            )}
            {model.includes('GPT') && (
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Frequency Penalty"
                  value={frequencyPenalty}
                  onChange={onChangeFrequencyPenalty}
                  type="number"
                  style={{ display: !model.includes('GPT') && 'none' }}
                />
              </Grid>
            )}
            {model.includes('GPT') && (
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Presence Penalty"
                  value={presencePenalty}
                  onChange={onChangePresencePenalty}
                  type="number"
                />
              </Grid>
            )}
            {model.includes('llama') && (
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Max Generated Length"
                  value={maxGenLen}
                  onChange={onChangeMaxGenLen}
                  type="number"
                />
              </Grid>
            )}
            {model.includes('titan') && (
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Max Token Count"
                  value={maxTokenCount}
                  onChange={onChangeMaxTokenCount}
                  type="number"
                />
              </Grid>
            )}
            {model.includes('gemini') && (
              <Grid item xs={2}>
                <TextField
                  variant="outlined"
                  label="Max Output Tokens"
                  value={maxOutputTokens}
                  onChange={onChangeMaxOutputTokens}
                  type="number"
                />
              </Grid>
            )}
            {model.includes('gemini') && (
              <Grid item xs={2}>
                <FormControl variant="outlined">
                  <InputLabel id="file-type-label">File Type</InputLabel>
                  <Select
                    variant="outlined"
                    value={geminiFileType}
                    onChange={onChangeGeminiFileType}
                    disabled={loading}
                  >
                    <MenuItem value="">{'<Empty>'}</MenuItem>
                    {geminiFileTypes.map(fileType => (
                      <MenuItem value={fileType} key={fileType}>
                        {fileType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {model.includes('gemini') && (
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  label="File URI"
                  value={geminiFileUri}
                  onChange={onChangeGeminiFileUri}
                  disabled={loading}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <BottomSheetOrDialog
      className="chat-gpt-dialog"
      open={!!chatGPTDialog && !closed}
      onClose={close}
      onExited={onExited}
      header={header}
      content={content}
      BottomSheetProps={{
        snapPoints: ({ minHeight, maxHeight }) => maxHeight * 0.95,
        defaultSnap: ({ lastSnap, snapPoints }) => lastSnap ?? Math.max(...snapPoints),
        expandOnContentDrag: false,
        disableAutoDismiss: true,
        BoxProps: {
          height: 'calc(95vh - 54px - var(--top-padding))'
        }
      }}
      DialogProps={{
        maxWidth: 'md',
        fullWidth: true
      }}
      DialogParams={{
        leftCloseButton: true
      }}
    />
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    chatGPTDialog: state.control.chatGPTDialog
  }),
  {
    toggleDialog
  }
)(ChatGPTDialog);

export default injectIntl(container);
