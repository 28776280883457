import React, { useState, useEffect, useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker } from '@material-ui/pickers';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Switch, Grid, Checkbox } from '@material-ui/core';
import moment from 'moment';
import MessageEditorModal from '../AdviserBlast/partials/MessageEditorModal/MessageEditorModal';
import EventIcon from '@material-ui/icons/Event';
import TeamBlastPopUp from '../../AdviserHomePage/TeamBlastPopUp';
import { SlideTransition } from '../../../components/Transition/Transition';
import MoreInfoTrigger from '../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalIntl } from '../../../reduxConnectedIntlProvider';
import {
  closeSendSuccessDialog,
  getTeamBlastNotification,
  updateTeamBlastNotification
} from '../../../store/notificationCenter/duck';
import { getTeamLeaderOf } from '../../../store/team/api';
import FunctionCard from '../../../components/FunctionCard/FunctionCard';
import AdviserHomePageSubtitle from '../../AdviserHomePage/AdviserHomePageSubtitle/AdviserHomePageSubtitle';
import NotFound from '../../NotFound/NotFound';
import _ from 'lodash';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

const FullWidthStyle = {
  width: '100%'
};

const ItemStyle = {
  ...FullWidthStyle,
  marginBottom: 5
};

const MarginLeftStyle = { marginLeft: '5%' };

const TeamWrapperStyle = { ...MarginLeftStyle, width: '95%' };

const MarginRightStyle = { marginRight: '7px' };

const TeamBlast = () => {
  const intl = getGlobalIntl();
  const dispatch = useDispatch();
  const adviserBlastProp = useSelector(state => state.notificationCenter.teamBlast);
  const getAdviserBlastPhase = useSelector(state => state.notificationCenter.getTeamBlastPhase);
  const updateAdviserBlastPhase = useSelector(state => state.notificationCenter.updateTeamBlastPhase);
  const showSendSuccessDialog = useSelector(state => state.notificationCenter.showSendSuccessDialog);
  const disableTodoPhase = useSelector(state => state.notificationCenter.disableTodoPhase);
  const userDetails = useSelector(state => state.user.userDetails);
  const shouldShowTeamBlast = useMemo(() => userDetails && _.get(userDetails, 'teamLeaderOf.length', 0) > 0, [
    userDetails
  ]);
  const [teams, setTeams] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [states, setStates] = useState('disable');
  const [sendToAll, setSendToAll] = useState(false);
  const [oneOff, setOneOff] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [MessageEditorModalOpen, setMessageEditorModalOpen] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [adviserBlast, setAdviserBlast] = useState(null);
  const [clientSendNumModalOpen, setClientSendNumModalOpen] = useState(false);
  const [detailIsNull, setDetailIsNull] = useState(false);
  const [validateOpen, setValidateOpen] = useState(false);
  const [validateValue, setValidateValue] = useState(false);
  const [disableToDo, setDisableToDo] = useState('');

  useEffect(() => {
    if (shouldShowTeamBlast) {
      dispatch(getTeamBlastNotification());
    }
  }, [dispatch, shouldShowTeamBlast]);
  useEffect(() => {
    const getTeams = async () => {
      const userTeams = await getTeamLeaderOf();
      if (userTeams && userTeams.data) {
        setTeams(userTeams.data);
      }
    };
    if (shouldShowTeamBlast) {
      getTeams();
    }
  }, [shouldShowTeamBlast]);
  useEffect(() => {
    if (adviserBlastProp) {
      setStartDate(adviserBlastProp.blastingTime);
      setEndDate(adviserBlastProp.expireTime);
      setStates(adviserBlastProp.states);
      setSendToAll(adviserBlastProp.sendToAll);
      setOneOff(adviserBlastProp.oneOff);
      setAdviserBlast(adviserBlastProp);
    }
    if (
      getAdviserBlastPhase !== 'loading' &&
      updateAdviserBlastPhase !== 'loading' &&
      disableTodoPhase === true &&
      states !== 'enable'
    ) {
      if (disableToDo === 'PromoMessage') {
        setMessageEditorModalOpen(true);
      } else if (disableToDo === 'startDate') {
        setStartDateOpen(true);
      } else if (disableToDo === 'endDate') {
        setEndDateOpen(true);
      } else if (disableToDo === 'sendToAll') {
        dispatch(updateTeamBlastNotification({ key: 'sendToAll', value: !sendToAll }));
      } else if (disableToDo === 'enableBlast') {
        setConfirmSend(true);
      } else if (disableToDo === 'oneOff') {
        dispatch(updateTeamBlastNotification({ key: 'oneOff', value: !oneOff }));
      } else if (disableToDo?.startsWith('teamId')) {
        const id = disableToDo.split('.')[1];
        let newIds;
        if (adviserBlastProp?.teamIds.includes(id)) {
          newIds = adviserBlastProp?.teamIds.filter(i => i != id);
        } else {
          newIds = [...adviserBlastProp?.teamIds, id];
        }
        dispatch(updateTeamBlastNotification({ key: 'teamIds', value: newIds }));
      }
      setDisableToDo('');
    }
  }, [adviserBlastProp, getAdviserBlastPhase, updateAdviserBlastPhase, disableTodoPhase, disableToDo, states]);

  const closeConfirmSend = () => {
    setConfirmSend(false);
  };

  const handleNotificationEnable = () => {
    setConfirmSend(false);
    let formData = {
      key: 'states',
      value: 'enable'
    };
    dispatch(updateTeamBlastNotification(formData));
  };

  const handleNotificationChange = (key, value) => {
    let formData = {};
    formData.key = key;
    formData.value = value;

    dispatch(updateTeamBlastNotification(formData));
  };

  const messageEnable = () => {
    if (states === 'enable') {
      handleNotificationChange('states', 'disable');
      return;
    }
    if (adviserBlast && adviserBlast.teamIds.length === 0 && !adviserBlast.sendToAll) {
      setClientSendNumModalOpen(true);
    } else if (adviserBlast && adviserBlast.detail === '') {
      setDetailIsNull(true);
    } else if (moment(endDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
      setValidateOpen(true);
      setValidateValue('validate-endDate');
    } else if (moment(startDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      setValidateOpen(true);
      setValidateValue('validate-startDate');
    } else {
      setConfirmSend(true);
    }
  };

  const handleDisEnableInDialog = () => {
    dispatch(updateTeamBlastNotification({ key: 'states', value: 'disable' }));
    setIsEnable(false);
  };

  const item = {
    color: '#5fc3d2',
    imgSrc: 'img/promotion.png',
    imgAlt: 'recruitment-target',
    text: intl.formatMessage({ id: 'team-blast-message-content' }),
    subtitle: intl.formatMessage({ id: 'team-blast-message-content-subtitle' }),
    onClick: () => {
      if (states === 'enable') {
        setIsEnable(true);
        setDisableToDo('PromoMessage');
        return;
      }
      setMessageEditorModalOpen(true);
    }
  };

  if (!shouldShowTeamBlast) {
    return <NotFound />;
  }

  return (
    <div>
      {(getAdviserBlastPhase === 'loading' || updateAdviserBlastPhase === 'loading') && <LoadingBackdrop open={true} />}
      <div id="wrapper" style={{ height: '100vh' }}>
        <div id="page-content-wrapper" className="promotion-content-wrapper ">
          <div className="container-fluid">
            <div className="row">
              <div className="plus-btn-message message-guideline-icon">
                <MoreInfoTrigger titleIntlId="team-blast-moreInfo-title" contentIntlId="team-blast-moreInfo-content" />
              </div>
              <div className="promotion-wrapper col-sm-6 col-sm-12 col-sm-offset-3">
                <ul>
                  <li>
                    <Grid item xs={12}>
                      <FunctionCard {...item} />
                    </Grid>
                  </li>
                  <li>
                    <Grid item style={ItemStyle}>
                      <AdviserHomePageSubtitle title={intl.formatMessage({ id: 'Settings' })} />
                    </Grid>
                  </li>
                  <li className="flex-li" style={MarginRightStyle}>
                    {intl.formatMessage({ id: 'start-date' })}
                    <span className="date-span">
                      {moment(startDate).format('DD-MMM-YYYY')}
                      <EventIcon
                        className="icon"
                        onClick={() => {
                          if (states === 'enable') {
                            setIsEnable(true);
                            setDisableToDo('startDate');
                            return;
                          }
                          setStartDateOpen(true);
                        }}
                      />
                    </span>

                    <DatePicker
                      className="datePicker"
                      name="startDate"
                      minDate={moment()}
                      value={startDate}
                      open={startDateOpen}
                      onClose={() => setStartDateOpen(false)}
                      onChange={data => {
                        handleNotificationChange('blastingTime', data.format('YYYY-MM-DDT00:00:00.00Z'));
                      }}
                    />
                  </li>
                  <li className="flex-li" style={MarginRightStyle}>
                    {intl.formatMessage({ id: 'end-date' })}
                    <span className="date-span">
                      {moment(endDate).format('DD-MMM-YYYY')}
                      <EventIcon
                        className="icon"
                        onClick={() => {
                          if (states === 'enable') {
                            setIsEnable(true);
                            setDisableToDo('endDate');
                            return;
                          }
                          setEndDateOpen(true);
                        }}
                      />
                    </span>

                    <DatePicker
                      className="datePicker"
                      name="endDate"
                      minDate={moment(startDate).add(1, 'days')}
                      maxDate={moment(startDate).add(3, 'months')}
                      value={endDate}
                      open={endDateOpen}
                      onClose={() => setEndDateOpen(false)}
                      onChange={data => {
                        handleNotificationChange('expireTime', data.format('YYYY-MM-DDT00:00:00.00Z'));
                      }}
                    />
                  </li>
                  <li>
                    {intl.formatMessage({ id: 'show-frequency' })}
                    <div className="flex-li" style={MarginLeftStyle}>
                      <span>{intl.formatMessage({ id: 'one-off' })}</span>
                      <Checkbox
                        value="sendToAll"
                        color="primary"
                        checked={oneOff}
                        onClick={event => {
                          event.stopPropagation();
                        }}
                        onChange={() => {
                          if (states === 'enable') {
                            setIsEnable(true);
                            setDisableToDo('oneOff');
                            return;
                          }
                          handleNotificationChange('oneOff', !oneOff);
                        }}
                      />
                    </div>
                  </li>
                  {teams?.length > 1 && (
                    <li>
                      {intl.formatMessage({ id: 'teams-to-send' })}
                      <div style={{ marginLeft: '5%' }}>
                        <div className="flex-li">
                          <span>{intl.formatMessage({ id: 'send-to-all-teams' })}</span>
                          <Checkbox
                            style={{ paddingLeft: 0 }}
                            color="primary"
                            checked={sendToAll}
                            onClick={event => {
                              event.stopPropagation();
                            }}
                            onChange={() => {
                              if (states === 'enable') {
                                setIsEnable(true);
                                setDisableToDo('sendToAll');
                                return;
                              }
                              handleNotificationChange('sendToAll', !sendToAll);
                            }}
                          />
                        </div>
                        <div>
                          {teams?.map(e => (
                            <div key={e._id} className="flex-li" style={TeamWrapperStyle}>
                              <div className="flex-li" style={FullWidthStyle}>
                                <span>{e.teamName}</span>
                                <Checkbox
                                  style={{ paddingLeft: 0 }}
                                  color="primary"
                                  checked={(adviserBlast?.teamIds.includes(e._id) ?? false) || sendToAll}
                                  onClick={event => {
                                    event.stopPropagation();
                                  }}
                                  onChange={() => {
                                    if (states === 'enable') {
                                      setIsEnable(true);
                                      setDisableToDo(`teamId.${e._id}`);
                                      return;
                                    }
                                    let newIds;
                                    if (adviserBlast?.teamIds.includes(e._id)) {
                                      newIds = adviserBlast?.teamIds.filter(id => id != e._id);
                                    } else {
                                      newIds = [...adviserBlast?.teamIds, e._id];
                                    }
                                    handleNotificationChange('teamIds', newIds);
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  )}
                </ul>

                <div className="promotion-footer">
                  <ul>
                    <li className="flex-li promotion-status">
                      <span>{intl.formatMessage({ id: 'status' })}</span>
                      <span className={states === 'disable' ? 'red' : 'green'}>
                        {states === 'disable'
                          ? intl.formatMessage({ id: 'disabled' })
                          : intl.formatMessage({ id: 'enabled' })}
                      </span>
                    </li>
                  </ul>

                  <div className="promotion-action" style={{ backgroundColor: 'white' }}>
                    <Button
                      className="send-Button"
                      color="primary"
                      variant="outlined"
                      onClick={event => {
                        event.stopPropagation();
                        setPreviewModalOpen(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'preview' })}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="send-Button"
                      onClick={messageEnable}
                    >
                      {intl.formatMessage({ id: states === 'enable' ? 'disable' : 'enable' })}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={confirmSend} onClose={closeConfirmSend} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'confirm-send-title' })}</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 10 }}>{intl.formatMessage({ id: 'confirm-send-team-content' })}</div>
          <div>
            {intl.formatMessage({ id: 'confirm-send-startDate' }, { data: moment(startDate).format('DD-MMM-YYYY') })}
          </div>
          <div>
            {intl.formatMessage({ id: 'confirm-send-endDate' }, { data: moment(endDate).format('DD-MMM-YYYY') })}
          </div>
          <div style={{ marginBottom: 10 }}>
            {intl.formatMessage({ id: 'confirm-send-days' }, { data: moment(endDate).diff(startDate, 'days') })}
          </div>
          <div>
            {intl.formatMessage(
              { id: 'confirm-one-off' },
              { data: intl.formatMessage({ id: oneOff ? 'Yes' : 'blast-everytime' }) }
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmSend} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" autoFocus onClick={handleNotificationEnable}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={getAdviserBlastPhase === true && updateAdviserBlastPhase === true && showSendSuccessDialog === true}
        onClose={() => dispatch(closeSendSuccessDialog())}
        TransitionComponent={SlideTransition}
      >
        <DialogTitle>{intl.formatMessage({ id: 'send-success-title' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'team-send-success-content' })}</DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={() => dispatch(closeSendSuccessDialog())}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isEnable}
        onClose={() => {
          setIsEnable(false);
        }}
        TransitionComponent={SlideTransition}
      >
        <DialogTitle>{intl.formatMessage({ id: 'warn' })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: 'warn-content' })} <br />
          {intl.formatMessage({ id: 'warn-content-ask' })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsEnable(false);
            }}
            color="primary"
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" autoFocus onClick={handleDisEnableInDialog}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={clientSendNumModalOpen}
        onClose={() => setClientSendNumModalOpen(false)}
        TransitionComponent={SlideTransition}
      >
        <DialogTitle>{intl.formatMessage({ id: 'warn' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'teams-cannot-be-0' })}</DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={() => setClientSendNumModalOpen(false)}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={detailIsNull} onClose={() => setDetailIsNull(false)} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'warn' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'team-detail-cannot-be-null' })}</DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={() => setDetailIsNull(false)}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={validateOpen} onClose={() => setValidateOpen(false)} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'validate-title' })}</DialogTitle>
        <DialogContent>
          {validateValue && (
            <div>
              {intl.formatMessage({ id: validateValue })}
              {validateValue === 'validate-startDate' && <div>{intl.formatMessage({ id: 'validate-ask' })}</div>}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {validateValue === 'validate-startDate' && (
            <Button onClick={() => setValidateOpen(false)} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
          )}
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              if (validateValue === 'validate-startDate') {
                setValidateOpen(false);
                setDisableToDo('enableBlast');
                handleNotificationChange('blastingTime', moment().format('YYYY-MM-DDT00:00:00.00Z'));
              } else {
                setValidateOpen(false);
              }
            }}
          >
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
      <TeamBlastPopUp
        teamBlastPop={adviserBlast}
        onClose={() => setPreviewModalOpen(false)}
        previewOpen={previewModalOpen}
        previewMode
      />
      <MessageEditorModal
        intl={intl}
        open={MessageEditorModalOpen}
        adviserBlast={adviserBlast}
        onClose={() => setMessageEditorModalOpen(false)}
        isTeam
      />
    </div>
  );
};

export default TeamBlast;
