import React, { PureComponent } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Dialog, DialogTitle } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import './NotificationCenterModal.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AdviserBlastPopUp from '../AdviserBlastPopUp/AdviserBlastPopUp';
import moment from 'moment';
// import { isUserConnected } from '../../utils/user';

import {
  getNotificationCenterList,
  markAllNotificationAsRead,
  markNotificationAsRead,
  createNotification
} from '../../store/notificationCenter/duck';
import { toggleUserMissionPopover } from '../../store/userMission/duck';
import { getNotificationImg } from '../../utils/notification-center';
import { sanitize } from 'dompurify';

class NotificationCenterModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      adviserBlastPop: {},
      PromotionModalOpen: false,
      lang: ''
    };
  }

  UNSAFE_componentWillMount() {
    let language = this.props.userDetails?.language;
    if (!language){
      let browserLanguage = navigator.language || navigator.userLanguage;
      language = browserLanguage === 'zh-HK' ? 'zh-Hant-HK' : browserLanguage === 'zh-CN' ? 'zh' : 'en';
    }
    // Temporary fix for non-standard language code
    if (language === 'zh-Hant-HK'){
      language = 'zh_Hant_HK';
    }
    this.setState({
      lang: language
    });
  }

  handleBadgeShow(receipient, id) {
    return receipient.id === id && receipient.state === 'new' ? false : true;
  }

  handleNotificationClickLinkTo(notification) {
    const {
      handleLinkToClientState,
      markNotificationAsRead,
      history,
      location,
      onClose,
      userDetails,
      toggleUserMissionPopover
    } = this.props;
    const { lang } = this.state;
    let flag = this.handleBadgeShow(notification.receipients.ids, userDetails._id);
    let regex = new RegExp('/', 'g');
    let urlType = [];
    let hash = [];

    if (!flag) {
      markNotificationAsRead(notification._id);
    }
    if (notification) {
      if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
        urlType = notification.url[lang].match(regex);
      }
      if (notification.type.name === 'system') {
        if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
          if (urlType.length > 1) {
            notification.urlOpenInNewWindow
              ? window.open(notification.url[lang])
              : (window.location.href = notification.url[lang]);
          } else {
            hash = notification.url[lang].split('#');
            if (hash.length > 1) {
              history.push({
                pathname: hash[0],
                hash: hash[1]
              });
            } else {
              history.push({
                pathname: notification.url[lang]
              });
            }
          }
        }
      } else if (notification.type.name === 'connection') {
        if (
          notification.type.tag === 'CONNECTION_WITH_CLIENT' ||
          notification.type.tag === 'THANK_YOU' ||
          notification.type.tag === 'MORE_DETAIL_CLICK'
        ) {
          if (location.pathname === '/client') {
            handleLinkToClientState('Connected', notification.sender.id);
          } else {
            console.log(notification);
            history.push({
              pathname: '/client',
              select: 'Connected',
              state: {
                connectedId: notification.sender.id
              }
            });
          }
        }
      } else if (notification.type.name === 'reminder') {
        if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
          if (urlType.length > 1) {
            notification.urlOpenInNewWindow
              ? window.open(notification.url[lang])
              : (window.location.href = notification.url[lang]);
          } else {
            hash = notification.url[lang].split('#');
            if (hash.length > 1) {
              history.push({
                pathname: hash[0],
                hash: hash[1]
              });
            } else {
              history.push({
                pathname: notification.url[lang]
              });
            }
          }
        } else {
          if (notification.type.tag === 'BD') {
            if (location.pathname === '/client') {
              handleLinkToClientState('TodayAndTomorrowBirthday');
            }
            history.push({
              pathname: '/client',
              select: 'TodayAndTomorrowBirthday',
              state: { BD: notification.blastingTime }
            });
          } else if (notification.type.tag === 'PolicyReminder') {
            if (userDetails.userType === 'User') {
              history.push({
                pathname: '/reminder'
              });
              onClose();
              return;
            }
          } else if (notification.type.tag === 'SUB_EXPIRED') {
            history.push({
              pathname: '/subscription'
            });
          } else if (notification.type.tag === 'RM') {
            if (location.pathname === '/client') {
              handleLinkToClientState('allReminder', notification.type.extraInfo);
            }
            history.push({
              pathname: '/client',
              select: 'allReminder',
              state: {
                remindMeId: notification.type.extraInfo
              }
            });
          } else {
            // history.push({ pathname: '/hot_topic' });
          }
        }
      } else if (notification.type.name === 'promotion') {
        if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
          if (urlType.length > 1) {
            notification.urlOpenInNewWindow
              ? window.open(notification.url[lang])
              : (window.location.href = notification.url[lang]);
          } else {
            hash = notification.url[lang].split('#');
            if (hash.length > 1) {
              history.push({
                pathname: hash[0],
                hash: hash[1]
              });
            } else {
              history.push({
                pathname: notification.url[lang]
              });
            }
          }
        }
      } else if (notification.type.name === 'agentPromotion') {
        this.setState({
          adviserBlastPop: notification,
          PromotionModalOpen: true
        });
      } else if (notification.type.name === 'user-mission') {
        // extraInfo = mission list id
        // toggleUserMissionPopover(true, notification.type.extraInfo, 1);
        history.push({ pathname: '/refer_adviser', state: { progressDialog: true } });
      } else if (notification.type.name === 'referral-coupon') {
        history.push({ pathname: '/subscription' });
      }
      onClose();
    }
  }

  handleNotificationTitleFormat(notification) {
    const { intl } = this.props;
    const { lang } = this.state;
    if (notification) {
      if (notification.title[lang] === 'Successfully-connected-with-client-title') {
        return intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name });
      } else if (notification.title[lang] === 'Successfully-connected-with-agent-title') {
        return intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name });
      } else if (notification.title[lang] === 'Thank-You-title') {
        return intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name });
      } else if (notification.title[lang] === 'Client-birthday-title') {
        return intl.formatMessage({ id: notification.title[lang] });
      } else if (notification.title[lang] === 'policy-due-reminder-title') {
        return intl.formatMessage({ id: notification.title[lang] });
      } else if (notification.title[lang] === 'Subscription-expired-title') {
        return intl.formatMessage(
          { id: notification.title[lang] },
          { data: moment(notification.type.extraInfo).format('DD-MMM-YYYY') }
        );
      } else if (notification.title[lang] === 'ReminderMe-title') {
        return intl.formatMessage({ id: notification.title[lang] });
      } else if (notification.title[lang] === 'adviser-blast-title') {
        return intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name });
      } else if (notification.title[lang] === 'more-detail-title') {
        return intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name });
      } else if (notification.title[lang] === 'adviser-disconnected-title') {
        return intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name });
      } else {
        return notification.title[lang];
      }
    }
  }

  handleNotificationMessageFormat(notification) {
    const { intl } = this.props;
    const { lang } = this.state;
    if (notification) {
      if (notification.type.tag === 'CONNECTION_WITH_AGENT') {
        return { __html: '' };
      }
      if (notification.message[lang] === 'notification-message') {
        return { __html: sanitize(intl.formatMessage({ id: notification.message[lang] })) };
      } else if (notification.message[lang] === 'Thank-You-message') {
        return {
          __html: sanitize(intl.formatMessage({ id: notification.message[lang] }, { data: notification.sender.name }))
        };
      } else if (notification.message[lang] === 'Client-birthday-message') {
        return {
          __html: sanitize(intl.formatMessage({ id: notification.message[lang] }, { data: notification.sender.name }))
        };
      } else if (notification.message[lang] === 'ReminderMe-message') {
        return {
          __html: sanitize(intl.formatMessage({ id: notification.message[lang] }, { data: notification.sender.name }))
        };
      } else if (notification.message[lang] === 'adviser-blast-message') {
        return { __html: sanitize(intl.formatMessage({ id: notification.message[lang] })) };
      } else if (notification.message[lang] === 'more-detail-message') {
        return { __html: sanitize(intl.formatMessage({ id: notification.message[lang] })) };
      } else if (notification.message[lang] === 'Subscription-expired-message') {
        return { __html: sanitize(intl.formatMessage({ id: notification.message[lang] })) };
      } else if (notification.message[lang] === 'adviser-disconnected-message') {
        return { __html: sanitize(intl.formatMessage({ id: notification.message[lang] })) };
      } else {
        return { __html: sanitize(notification.message[lang]) };
      }
    }
  }

  clickMarkAll() {
    if (!this.props.hasNotifications) {
      return;
    }
    this.props.onClose();
    this.props.markAllNotificationAsRead();
  }

  markAndClose(data) {
    this.setState({ PromotionModalOpen: false });
    if (data.receipients.ids.state === 'new') {
      this.props.markNotificationAsRead(data._id);
    }
  }

  clientMessages() {
    const { intl, connectedAdvisers, userDetails } = this.props;
    let whatsApp = connectedAdvisers ? connectedAdvisers[0].whatsapp : '';
    let phoneNoText = whatsApp ? 'phone=' + whatsApp.replace('+', '') : '';
    let url =
      'https://api.whatsapp.com/send?' + phoneNoText + `&text=${intl.formatMessage({ id: 'wts-promotion-message' })}`;
    var win = window.open(encodeURI(url), '_blank');
    if (win) win.focus();
    let factsheetId = '';
    for (let item of userDetails.advisorDetails) {
      if (item.connection && item.connectionVerified && item.email === connectedAdvisers[0].email) {
        factsheetId = item.factsheetId;
      }
    }

    let data = {
      factsheetId: factsheetId,
      notification: {
        sender: {
          id: userDetails._id,
          type: 'User',
          name: userDetails.name
        },
        receipients: {
          type: 'Adviser',
          ids: [
            {
              id: connectedAdvisers && connectedAdvisers[0]._id
            }
          ]
        },
        type: {
          name: 'connection',
          tag: 'MORE_DETAIL_CLICK',
          extraInfo: 'CLIENT_CLICK_MORE_DETAIL'
        },
        title: {
          en: 'more-detail-title',
          zh: 'more-detail-title',
          zh_Hant_HK: 'more-detail-title'
        },
        message: {
          en: 'more-detail-message',
          zh: 'more-detail-message',
          zh_Hant_HK: 'more-detail-message'
        }
      }
    };
    this.props.createNotification(data);
  }

  render() {
    const { open, onClose, notificationCenterData, userDetails, intl } = this.props;
    const { PromotionModalOpen, adviserBlastPop } = this.state;

    let data = notificationCenterData;
    let userId = userDetails && userDetails._id;
    return (
      <div>
        <Dialog
          open={open}
          className="NotificationCenterModal"
          onClose={() => {
            onClose();
          }}
        >
          <DialogTitle className="ModalTitle">{intl.formatMessage({ id: 'notification-center' })}</DialogTitle>
          <Divider />
          <List className="list">
            {data &&
              data.map((item, index) => {
                return (
                  <div key={index}>
                    <ListItem className="item" onClick={this.handleNotificationClickLinkTo.bind(this, item)}>
                      <ListItemAvatar className="IconImg">
                        <Badge
                          className="NotificationBadge"
                          color="error"
                          variant="dot"
                          invisible={this.handleBadgeShow(item.receipients.ids, userId)}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          {getNotificationImg(item.type.name)}
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        primary={this.handleNotificationTitleFormat(item)}
                        className="ItemTitle"
                        secondary={
                          <span className="message message-wrapper">
                            <span
                              className="message message-content"
                              dangerouslySetInnerHTML={this.handleNotificationMessageFormat(item)}
                            ></span>
                            <span className="message message-sendTime">{moment(item.blastingTime).fromNow()}</span>
                          </span>
                        }
                      ></ListItemText>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
          </List>
          <DialogTitle className="bottomAction" onClick={this.clickMarkAll.bind(this)}>
            {intl.formatMessage({ id: 'mark-all-as-read' })}
          </DialogTitle>
        </Dialog>
        <AdviserBlastPopUp
          open={PromotionModalOpen}
          onClose={() => {
            this.setState({ PromotionModalOpen: false });
          }}
          intl={intl}
          markAndClose={this.markAndClose.bind(this, adviserBlastPop)}
          adviserBlastPop={adviserBlastPop}
          clientMessages={this.clientMessages.bind(this)}
        />
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    notificationCenterData: state.notificationCenter.notificationCenterData,
    hasNotifications: state.notificationCenter.hasNotifications,
    connectedAdvisers: state.user.connectedAdvisers
  }),
  // Map actions to props
  {
    markAllNotificationAsRead,
    markNotificationAsRead,
    getNotificationCenterList,
    createNotification,
    toggleUserMissionPopover
  },
  // mergeProps
  null,
  // options
  {}
)(NotificationCenterModal);

export default injectIntl(container);
