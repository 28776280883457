import React from 'react';
import { Slide, Zoom } from '@material-ui/core';

export const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ZoomTransition = React.forwardRef((props, ref) => {
  const { triggerTarget, ...rest } = props;
  let transformOrigin;
  if (triggerTarget) {
    const rect = triggerTarget.getBoundingClientRect();
    transformOrigin = `${Math.round(rect.x + rect.width / 2)}px ${Math.round(rect.y + rect.height / 2)}px`;
  }
  return <Zoom ref={ref} {...rest} style={{ transformOrigin: transformOrigin }} />;
});
