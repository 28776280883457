import React, { useEffect, useState, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SlideTransition } from '../../../components/Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { updateTeamSetting } from '../../../store/team/api';

const TeamSettingDialog = props => {
  const { intl, teamMemberPermissionPhase, teamMemberPermissionData, open, onClose, ...rest } = props;

  const [internalPermissionData, setInternalPermissionData] = useState([]);
  const [confirmShareDialog, setConfirmShareDialog] = useState(undefined);

  const createLogoChangeHandler = teamId => event => {
    const teams = [...internalPermissionData];

    // check if no team logo is enabled
    let teamLogoEnabledTeam;
    const targetTeam = teams.find(team => team._id === teamId);
    if (targetTeam) {
      if (targetTeam.members.permission.isUseTeamLogo === true) {
        if (teams.length === 1) {
          teamLogoEnabledTeam = targetTeam;
        } else {
          teamLogoEnabledTeam = teams.find(team => team._id !== teamId);
        }
      } else {
        teamLogoEnabledTeam = targetTeam;
      }
    }

    teams.forEach((team, index) => {
      const setValue = team._id === teamLogoEnabledTeam._id;
      team.members.permission.isUseTeamLogo = setValue;
      teams[index] = team;

      // update db
      updateTeamSetting({
        teamId: team._id,
        memberId: team.members.userId,
        setValue,
        updateGroup: 'permission',
        updatePara: 'isUseTeamLogo'
      });
    });

    setInternalPermissionData([...teams]);
  };

  const createConfirmShareHandler = (teamId, memberId, teamIndex) => event => {
    setConfirmShareDialog({ teamId, memberId, teamIndex, setValue: event.target.checked });
  };

  const confirmShare = () => {
    //if enable tea leader access data, pop up confirm modal first,
    // otherwise, directly amended by API
    const teams = [...internalPermissionData];
    const { teamId, memberId, teamIndex, setValue } = confirmShareDialog;
    teams[teamIndex].members.permission.isShareData = setValue;
    updateTeamSetting({ teamId, memberId, setValue, updateGroup: 'permission', updatePara: 'isShareData' });
    setInternalPermissionData([...teams]);
    setConfirmShareDialog(undefined);
  };

  const closeConfirmShareDialog = () => setConfirmShareDialog(undefined);

  useEffect(() => {
    setInternalPermissionData(teamMemberPermissionData);
  }, [teamMemberPermissionData]);

  return (
    <Fragment>
      {/*Main Dialog*/}
      <Dialog TransitionComponent={SlideTransition} fullWidth={true} open={open} onClose={onClose} {...rest}>
        <DialogTitle>{intl.formatMessage({ id: 'teamSetting' })}</DialogTitle>
        <DialogContent>
          {(teamMemberPermissionData || []).length > 0 ? (
            (teamMemberPermissionData || []).map((eachTeam, index) => {
              return (
                <div className="caseclose-form caseclose-form-business" key={index}>
                  {eachTeam.members !== null && eachTeam.members.extraFeatures.isEnableSetting === true && (
                    <div className="form-group">
                      <div className="eachTeam-item">
                        <div
                          className="row"
                          style={{
                            marginLeft: '15px',
                            marginRight: '15px'
                          }}
                        >
                          <div className=" figureColor">{eachTeam.teamName}</div>
                          <div className="col-md-8 col-xs-8 adv_biz_modal_key">
                            {intl.formatMessage({ id: 'useTeamLogo' })}
                          </div>
                          <div className="col-md-4 col-xs-4 adv_biz_modal_key">
                            <Switch
                              className="team-setting-switch"
                              checked={eachTeam.members.permission.isUseTeamLogo}
                              onChange={createLogoChangeHandler(eachTeam._id)}
                              value="isUseTeamLogo"
                              color="primary"
                            />
                          </div>
                          <div className="col-md-8 col-xs-8 adv_biz_modal_key">
                            {intl.formatMessage({
                              id: 'allowTeamLeaderAccess'
                            })}
                          </div>
                          <div className="col-md-4 col-xs-4 adv_biz_modal_key">
                            <Switch
                              className="team-setting-switch"
                              checked={eachTeam.members.permission.isShareData}
                              onChange={createConfirmShareHandler(eachTeam._id, eachTeam.members.userId, index)}
                              value="isShareData"
                              color="primary"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div>You are not in any team now</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      {/*Confirm Share Dialog*/}
      <Dialog
        open={!!confirmShareDialog}
        onClose={closeConfirmShareDialog}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'Warning' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'WarningMessage' })}</DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmShareDialog} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={confirmShare} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const container = connect(
  state => ({
    teamMemberPermissionPhase: state.team.teamMemberPermissionPhase,
    teamMemberPermissionData: state.team.teamMemberPermissionData
  }),
  {}
)(TeamSettingDialog);

export default injectIntl(container);
