import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { InputAdornment, IconButton } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';

class RemindDateControl extends React.PureComponent {
  render() {
    const { fsReminderDate, onChange, className, InputProps, ...rest } = this.props;

    return (
      <DatePicker
        className={`${className ? className : ''}`}
        name="fsReminderDate"
        format="DD-MMM-YYYY"
        clearable={true}
        openTo={true}
        autoOk={true}
        invalidDateMessage={null}
        minDateMessage={null}
        maxDateMessage={null}
        value={
          /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
          !fsReminderDate ? null : moment.parseZone(fsReminderDate)
        }
        onChange={date => onChange(date)}
        InputProps={{
          endAdornment: !rest.disabled && (
            <InputAdornment position="end">
              <IconButton style={{ padding: '0 5px 0 0', margin: '0px 30px 0px -12px' }}>
                <TodayIcon color="action" style={{ fontSize: 20 }} />
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps
        }}
        {...rest}
      />
    );
  }
}

export default RemindDateControl;
