import React, { PureComponent } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import '../styles.scss';
import _ from 'lodash';
import GridButtonLayout from '../../../../components/GridButtonLayout/GridButtonLayout';
import { TopNavigationPortal } from '../../../../components/topNavigation';

class InsuranceTypeView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    const locationSubCategory = _.get(props, 'location.state.subCategory');
    if (locationSubCategory) {
      _.unset(props, 'location.state.subCategory');
      this.handleData(locationSubCategory);
    }
  }

  handleData(data) {
    const { handleSubCategoryData, handleNextPage } = this.props;
    handleSubCategoryData(data);
    var formData = {};
    formData.value = data._id;
    formData.title = data.title;
    //Set the subcategory in local storage
    localStorage.setItem('subCategory', JSON.stringify(formData));

    //Remove the data from localstorage
    let addRider = JSON.parse(localStorage.getItem('addRider'));
    if (!addRider) {
      localStorage.removeItem('plan');
      localStorage.removeItem('payment');
      localStorage.removeItem('detail');
      localStorage.removeItem('apendix');
    }
    handleNextPage();
  }

  render() {
    const { categoryData, intl, handlePreviousPage } = this.props;
    const insuranceType = categoryData.subCategories;
    return (
      <div>
        <TopNavigationPortal>
          <span onClick={handlePreviousPage} className="back_arrow">
            <ArrowBackIcon />
          </span>
        </TopNavigationPortal>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid policyInsuranceTypeAdvisorAddPolicy">
              <div className="row">
                <div className="col-lg-12">
                  <GridButtonLayout
                    items={(insuranceType || []).map(subCategory => ({
                      image: <img src={subCategory.image} alt={subCategory.title} width="60" />,
                      text:
                        subCategory.title === 'Saving'
                          ? intl.formatMessage({ id: 'Saving' }) + ' / ' + intl.formatMessage({ id: 'ILAS' })
                          : intl.formatMessage({ id: subCategory.title }),
                      onClick: this.handleData.bind(this, subCategory)
                    }))}
                    style={{ paddingBottom: 15 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InsuranceTypeView;
