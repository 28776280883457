import React from 'react';
import MyJoyride from '../../MyJoyride';
import { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import _ from 'lodash';
import { isStepLastClicked } from '../../../store/guideline/duck';
import { enableDomInteraction, enableScroll } from '../../../utils/dom';
import { sanitize } from 'dompurify';

class Guideline extends React.Component {
  handleGuideCallback = tour => {
    const { steps } = this.props;
    const { action, status, type } = tour;

    const step = _.get(steps, `[${tour.index}]`);
    if (step && step.callback) {
      step.callback(tour);
    }

    if (action === ACTIONS.CLOSE) {
      this.props.endGuideline();
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.props.endGuideline();
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.props.updateStepIndex(action === ACTIONS.PREV ? -1 : 1);
    }

    if (isStepLastClicked(tour) || action === ACTIONS.STOP) {
      enableScroll();
      enableDomInteraction();
    }
  };

  render() {
    const { run, stepIndex, intl, steps, showProgress, tooltipComponent } = this.props;

    let finalSteps = JSON.parse(JSON.stringify(steps));
    finalSteps.forEach(step => {
      let message = intl.formatMessage({ id: step.content });
      step.content = (
        <React.Fragment>
          <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: sanitize(message) }} />
        </React.Fragment>
      );
    });

    return (
      <MyJoyride
        steps={finalSteps}
        run={run}
        callback={this.handleGuideCallback}
        stepIndex={stepIndex}
        spotlightClicks={false}
        showProgress={showProgress}
        tooltipComponent={tooltipComponent}
      />
    );
  }
}

export default Guideline;
