import { fetch } from '../../utils';
import Backend from '../../backend';

export const getMedicalCardCouponCodesApi = () => {
  return fetch(Backend.methodUrl('medical-card-coupon-codes'))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
