import { fetch } from '../../utils';
import Backend from '../../backend';
import NativeOrWeb from '../../utils/native';

// API TO CHECK VERSION FOR NATIVE APPS
export const checkVersion = async () => {
  try {
    const appInfo = await NativeOrWeb.getAppInfo();
    const platform = NativeOrWeb.getPlatform();
    if (appInfo && platform) {
      const res = await fetch(Backend.methodUrl('version'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          version: appInfo.version,
          platform: platform
        })
      });
      const payload = await res.json();
      return payload;
    } else {
      return true;
    }
  } catch (error) {
    return true;
  }
};
