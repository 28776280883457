import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import _, { assign, toString } from 'lodash';
import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
import NativeOrWeb from '../../utils/native';
/***********************************
 * Action Types
 ***********/

export const GET_TEAM_MEMBER_PERMISSION = 'portfoplus/team/GET_TEAM_MEMBER_PERMISSION';
export const GET_TEAM_MEMBER_PERMISSION_SUCCESS = 'portfoplus/team/GET_TEAM_MEMBER_PERMISSION_SUCCESS';
export const GET_TEAM_MEMBER_PERMISSION_ERROR = 'portfoplus/team/GET_TEAM_MEMBER_PERMISSION_ERROR';

export const UPDATE_TEAM_MEMBER_PERMISSION = 'portfoplus/team/UPDATE_TEAM_MEMBER_PERMISSION';
export const UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS = 'portfoplus/team/UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS';
export const UPDATE_TEAM_MEMBER_PERMISSION_ERROR = 'portfoplus/team/UPDATE_TEAM_MEMBER_PERMISSION_ERROR';

export const GET_TEAM_MEMBER_PERMISSION_STATE = 'portfoplus/team/GET_TEAM_MEMBER_PERMISSION_STATE';

export const UPDATE_TEAM_BASIC = 'portfoplus/team/UPDATE_TEAM_BASIC';

export const UPDATE_TEAMS = 'portfoplus/team/UPDATE_TEAMS';
export const UPDATE_GET_TEAMS_PHASE = 'portfoplus/team/UPDATE_GET_TEAMS_PHASE';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  teamMemberPermissionPhase: INIT,
  teamMemberPermissionData: null,
  error: null,
  isSubmitting: false,

  teamShortName: undefined,
  teamLogoUrl: undefined,
  teamNewsUrls: undefined,

  teams: undefined,
  getTeamsPhase: INIT
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_TEAM_MEMBER_PERMISSION: {
      return state
        .set('teamMemberPermissionPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_TEAM_MEMBER_PERMISSION_SUCCESS: {
      const { payload } = action;
      return state
        .set('teamMemberPermissionData', payload.data)
        .set('teamMemberPermissionPhase', payload.success)
        .set('error', null);
    }

    case GET_TEAM_MEMBER_PERMISSION_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('teamMemberPermissionPhase', ERROR);
    }

    case UPDATE_TEAM_MEMBER_PERMISSION: {
      return state
        .set('teamMemberPermissionPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS: {
      const { payload } = action;
      return state
        .set('teamMemberPermissionData', payload.data)
        .set('teamMemberPermissionPhase', payload.success)
        .set('error', null);
    }

    case UPDATE_TEAM_MEMBER_PERMISSION_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('teamMemberPermissionPhase', ERROR);
    }

    case GET_TEAM_MEMBER_PERMISSION_STATE: {
      return state;
    }

    case UPDATE_TEAM_BASIC: {
      const { payload } = action;

      if (payload.teamShortName) {
        NativeOrWeb.saveStorage('teamShortName', payload.teamShortName)
          .then()
          .catch();
      } else {
        NativeOrWeb.removeStorage('teamShortName')
          .then()
          .catch();
      }

      return state
        .set('teamShortName', payload.teamShortName)
        .set('teamLogoUrl', payload.teamLogoUrl)
        .set('teamNewsUrls', payload.teamNewsUrls);
    }

    case UPDATE_TEAMS: {
      const { payload } = action;
      let updatedState = state.set('teams', payload.teams);
      if (payload.phase) {
        updatedState = updatedState.set('getTeamsPhase', payload.phase);
      }
      return updatedState;
    }

    case UPDATE_GET_TEAMS_PHASE: {
      const { payload } = action;
      return state.set('getTeamsPhase', payload.phase);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getTeamMemberPermission = data => ({
  type: GET_TEAM_MEMBER_PERMISSION,
  payload: data
});

export const updateTeamMemberPermission = data => ({
  type: UPDATE_TEAM_MEMBER_PERMISSION,
  payload: data
});

export const getTeamMemberPermission_state = () => ({
  type: GET_TEAM_MEMBER_PERMISSION_STATE
});

export const getUserTeamLogoNews = () => async dispatch => {
  try {
    let teamLogoNews = await api.getTeamLogoNews();
    dispatch({
      type: UPDATE_TEAM_BASIC,
      payload: teamLogoNews
    });
  } catch (e) {
    return e;
  }
};

export const getTeams = () => async dispatch => {
  dispatch({ type: UPDATE_GET_TEAMS_PHASE, payload: { phase: LOADING } });
  try {
    const teams = await api.getTeams();
    dispatch({ type: UPDATE_TEAMS, payload: { teams, phase: SUCCESS } });
  } catch (e) {
    dispatch({ type: UPDATE_GET_TEAMS_PHASE, payload: { phase: ERROR } });
  }
};
/***********************************
 * Epics
 ***********/

const getTeamMemberPermissionEpic = action$ =>
  action$.ofType(GET_TEAM_MEMBER_PERMISSION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getTeamMemberPermission(action.payload))
      .flatMap(payload => [
        {
          type: GET_TEAM_MEMBER_PERMISSION_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: GET_TEAM_MEMBER_PERMISSION_ERROR,
          payload: { error }
        })
      );
  });

const updateTeamMemberPermissionEpic = action$ =>
  action$.ofType(UPDATE_TEAM_MEMBER_PERMISSION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateTeamSetting(action.payload))
      .flatMap(payload => [
        {
          type: UPDATE_TEAM_MEMBER_PERMISSION_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_TEAM_MEMBER_PERMISSION_ERROR,
          payload: { error }
        })
      );
  });

export const teamEpic = combineEpics(getTeamMemberPermissionEpic, updateTeamMemberPermissionEpic);
