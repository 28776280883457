import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { selectedLocale } from '../../store/locale/action';
import AccountDeletionComponent from './component';
import { requestAccountDeletion } from '../../store/user/api';

const AccountDeletion = connect(
    // Map state to props
    state => ({
        userDetails: state.user.userDetails,
        message: state.user.message,
        requestAccountDeletion: requestAccountDeletion
    }),
    // Map actions to props
    {
        selectedLocale
    }
)(AccountDeletionComponent);

export default injectIntl(AccountDeletion);
