import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { sanitize } from 'dompurify';

const ContainerStyle = {
  marginTop: 125
};

const TypoStyle = {
  fontSize: '130%'
};

const ImageStyle = {
  width: '100%',
  boxShadow: 'var(--my-shadow)'
};

const ComingSoon = props => {
  const { intl } = props;
  return (
    <Container maxWidth="sm" style={ContainerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            color="textSecondary"
            align="center"
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'coming-soon-title' })) }}
            style={TypoStyle}
          />
        </Grid>
        <Grid item xs={12}>
          <img src="img/staytuned.png" alt="stay-tuned" style={ImageStyle} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default injectIntl(ComingSoon);
