import React from 'react';
import Grid from '@material-ui/core/Grid';
import BlockingOverlay from '../SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import ImageIconButton from '../ImageIconButton/ImageIconButton';
import _ from 'lodash';

const GridButtonLayout = props => {
  const { items, ...rest } = props;

  const narrowLayout = window.innerWidth <= 600;
  const buttonGridXs = narrowLayout ? 6 : undefined;
  const buttonGridWidth = narrowLayout ? undefined : '190px';

  return (
    <Grid container spacing={3} {...rest}>
      {items.map((item, index) => {
        if (!item) {
          return null;
        }

        const { BlockingOverlayProps, ...itemRest } = item;
        const overlayProps = _.merge({ parentStyle: { borderRadius: 10 } }, BlockingOverlayProps);
        return (
          <Grid key={index} item xs={buttonGridXs} style={{ width: buttonGridWidth }}>
            {item.overlay ? (
              <BlockingOverlay {...overlayProps}>
                <ImageIconButton {...itemRest} />
              </BlockingOverlay>
            ) : (
              <ImageIconButton {...itemRest} />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default GridButtonLayout;
