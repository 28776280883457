import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import '../styles.scss';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CardContent,
  ExpansionPanelActions
} from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import './referralRecords.scss';
import Card from '@material-ui/core/Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import { HOBBIES_TAG_STYLE } from '../../../../constants/my-details';
import { getLikedTagIntl } from '../../../../utils/my-details';
import { tryTranslate } from '../../../../utils/locale';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { getUserTimezone } from '../../../../utils/user';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import { usePrevious } from '../../../../utils/hooks';
import ReferralExistDialog from './ReferralExistDialog/ReferralExistDialog';
import { getReferralSourceString } from '../../../../utils/referral';

const LinkStyle = { textDecoration: 'underline', color: 'inherit' };

const ReferralListItem = props => (
  <ListItem divider={props.divider || true}>
    <Grid container spacing={1} alignItems={props.alignItems || 'flex-start'}>
      <Grid item xs={3} sm={2} md={1}>
        {props.title}
      </Grid>
      <Grid item xs={9} sm={10} md={11} style={{ textAlign: 'center' }}>
        {props.content}
      </Grid>
    </Grid>
  </ListItem>
);

const ReferralActionableValue = props => (
  <Grid container wrap="nowrap" alignItems="center" justify="center">
    <Grid item style={{ width: 'calc(100% - 80px)' }}>
      {props.value}
    </Grid>
    {props.action}
  </Grid>
);

const ReferralRecordView = props => {
  const {
    intl,
    history,
    userDetails,
    referralRecords,
    clientData,
    deletePhase,
    clearClientDetails,
    deleteRecord,
    updateReferralById,
    getReferralRecords,
    getClient,
    renderHeaderButton
  } = props;

  const [selectedId, setSelectedId] = useState(undefined);
  const [deleteId, setDeleteId] = useState(undefined);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [referralExistDialog, setReferralExistDialog] = useState(false);
  const referralRef = useRef(null);
  const prevDeletePhase = usePrevious(deletePhase);

  const addClient = referral => {
    const isInClientList = referral.email && (clientData || []).find(client => client.email === referral.email);

    if (isInClientList) {
      // do not pass email to client page if the email is already in the client list
      const newReferral = { ...referral, email: '' };
      referralRef.current = newReferral;
      setReferralExistDialog(true);
    } else {
      referralRef.current = referral;
      proceedAddClient();
    }
  };

  const closeReferralExistDialog = () => setReferralExistDialog(false);
  const proceedAddClient = () => {
    localStorage.removeItem('clientId');
    localStorage.removeItem('arrowRotateClient');
    sessionStorage.setItem('referralRecordId', referralRef.current._id);
    clearClientDetails();
    history.push({
      state: { referralRecord: referralRef.current },
      pathname: '/client_personal_form'
    });
  };

  const openDeleteDialog = referralId => {
    setDeleteId(referralId);
    setDeleteDialog(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const deleteReferral = () => {
    deleteRecord(deleteId);
    setDeleteDialog(false);
  };

  // const clientMessages = (referral) => {
  // 	var contactNo = _.get(referral, 'whatsapp', '');
  // 	let phoneNoText = contactNo ? 'phone=' + contactNo.replace('+', '') : '';
  // 	let url = 'https://api.whatsapp.com/send?' + phoneNoText + '&text=Hi, we are connected in PortfoPlus';
  // 	var win = window.open(url, '_blank');
  // 	if (win) win.focus();
  // 	// localStorage.setItem('clientId', JSON.stringify(referral._id));
  // 	// this.props.history.push({
  // 	//   pathname: '/adviser_message'
  // 	// });
  // }

  const selectReferral = (event, expanded, referral) => {
    setSelectedId(selectedId === referral._id ? undefined : referral._id);
    // update isNewClient status if the referral is new
    if (referral.isNewClient) {
      updateReferralById(referral._id, { isNewClient: false });
    }
  };

  const lazyPackIntl = intl.formatMessage({ id: 'lazy-pack' });

  useEffect(() => {
    getReferralRecords();
  }, [getReferralRecords]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  useEffect(() => {
    renderHeaderButton(false);
  }, [renderHeaderButton]);

  useEffect(() => {
    if (prevDeletePhase !== true && deletePhase === true) {
      getReferralRecords();
    }
  }, [prevDeletePhase, deletePhase, getReferralRecords]);

  return (
    <div>
      <ReferralExistDialog open={referralExistDialog} onClose={closeReferralExistDialog} onOK={proceedAddClient} />

      <Dialog
        open={deleteDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete Record' })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({
            id: 'Are you sure you want to delete this?'
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={deleteReferral} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>

      <div id="wrapper-referrel" className="referral__page referral-records-page">
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="reminder-box">
                  <Grid container justify="center">
                    <Grid item>
                      <AladdinHint
                        containerProps={{ justify: 'center' }}
                        title={intl.formatMessage({ id: 'aladdin-referral-records-title' })}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Card className="referral-header" style={{ boxShadow: 'none' }}>
                        <CardContent style={{ padding: 16 }}>
                          <Grid container alignItems="center">
                            <Grid item xs={8} sm={7}>
                              {intl.formatMessage({ id: 'New client' })}
                            </Grid>
                            <Grid item xs={4} sm={5}>
                              {intl.formatMessage({ id: 'source' })}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    {referralRecords &&
                      referralRecords.map((referral, index) => {
                        const sourceStr = getReferralSourceString(intl, referral);

                        let referByName = '';
                        let _id = '';
                        let luckyArray = [];
                        if (referral.referBy && !referral.referUserFactsheetId) {
                          if (clientData && clientData.length > 0) {
                            // eslint-disable-next-line
                            clientData.map(val1 => {
                              if (referral.referBy === val1.email) {
                                referByName =
                                  val1.factsheetId && val1.factsheetId.name ? val1.factsheetId.name : val1.name;
                                _id = val1._id;
                                luckyArray.push(val1);
                              }
                            });
                          }
                        }
                        if (referral.referUserId && referral.referUserFactsheetId) {
                          referByName =
                            referral.referUserFactsheetId && referral.referUserFactsheetId.name
                              ? referral.referUserFactsheetId.name
                              : '';
                          _id = referral.referUserId;
                          //luckyArray.push(referral.referUserId);
                          if (clientData && clientData.length > 0) {
                            // eslint-disable-next-line
                            clientData.map(val1 => {
                              if (referral.referUserId === val1._id) {
                                referByName =
                                  val1.factsheetId && val1.factsheetId.name ? val1.factsheetId.name : val1.name;
                                _id = val1._id;
                                luckyArray.push(val1);
                              }
                            });
                          }
                        }
                        let showEmail = true;
                        let referralId = _id.toString();
                        if (referral.referBy && !referral.referUserFactsheetId) {
                          let inactiveEmail = referral.referBy ? referral.referBy : '';
                          if (inactiveEmail.indexOf(referralId) !== -1) {
                            showEmail = false;
                          }
                        }
                        if (referral.referUserId && referral.referUserFactsheetId) {
                          let inactiveEmail = referral.referUserFactsheetId.email
                            ? referral.referUserFactsheetId.email
                            : '';
                          if (inactiveEmail.indexOf(referralId) !== -1) {
                            showEmail = false;
                          }
                        }
                        let isObj = false;
                        if (referral.referUserId && referral.referUserFactsheetId) {
                          isObj = true;
                        }

                        const whoText =
                          referral.source === 'lazy-pack'
                            ? lazyPackIntl
                            : showEmail && referral.referUserFactsheetId
                            ? referral.referUserFactsheetId.email
                            : referByName;

                        const showWho = () => {
                          history.push({
                            pathname: '/client',
                            select: 'particular',
                            preSelectedClientIds: luckyArray.map(client => client._id)
                          });
                        };

                        return (
                          <Grid item xs={12} key={index}>
                            <ExpansionPanel
                              id={referral._id}
                              className="referral-record"
                              expanded={selectedId === referral._id}
                              onChange={(event, expanded) => selectReferral(event, expanded, referral)}
                            >
                              <ExpansionPanelSummary
                                className="summary"
                                style={{ padding: '0 48px 0 16px' }}
                                expandIcon={<ExpandMoreIcon color="primary" />}
                              >
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item xs={8} sm={7}>
                                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                      <Grid item>
                                        {!referral.name && referral.isAutoGenerated
                                          ? intl.formatMessage({ id: 'referral-records-auto-gen' })
                                          : referral.name}
                                      </Grid>
                                      {!!referral.isNewClient && (
                                        <Grid item>
                                          <Badge
                                            className="Badge todo-badge"
                                            badgeContent={intl.formatMessage({ id: 'New' })}
                                            color="error"
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={4} sm={5}>
                                    <div>{sourceStr}</div>
                                    {/*<div>{referral.source === 'lazy-pack' ? lazyPackIntl : referByName || 'N.A.'}</div>*/}
                                  </Grid>
                                </Grid>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className="details" style={{ flexDirection: 'column' }}>
                                <List style={{ width: '100%' }}>
                                  <ReferralListItem
                                    title={`${intl.formatMessage({ id: 'Tel' })}:`}
                                    content={
                                      referral.phoneNumber && (
                                        <a href={'tel:' + referral.phoneNumber} target="_top" style={LinkStyle}>
                                          {referral.phoneNumber}
                                        </a>
                                      )
                                    }
                                  />

                                  <ReferralListItem
                                    title={`${intl.formatMessage({ id: 'Email' })}:`}
                                    content={
                                      referral.email && (
                                        <a href={'mailto:' + referral.email} target="_top" style={LinkStyle}>
                                          {referral.email}
                                        </a>
                                      )
                                    }
                                  />

                                  {referral.answerTags &&
                                    Object.keys(referral.answerTags).map((question, index) => (
                                      <ReferralListItem
                                        alignItems="center"
                                        title={`${intl.formatMessage(
                                          { id: 'question-number' },
                                          { number: index + 1 }
                                        )}:`}
                                        content={
                                          <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                            spacing={1}
                                          >
                                            <Grid item>
                                              {question}
                                              <SelectButtonGroup
                                                justify="center"
                                                multi={true}
                                                choices={referral.answerTags[question].map(answer => ({
                                                  value: answer,
                                                  label: answer,
                                                  style: HOBBIES_TAG_STYLE,
                                                  disableRipple: true,
                                                  disableFocusRipple: true
                                                }))}
                                                value={[]}
                                              />
                                            </Grid>
                                          </Grid>
                                        }
                                      />
                                    ))}

                                  <ReferralListItem
                                    alignItems="center"
                                    title={`${intl.formatMessage({ id: 'referral-request' })}:`}
                                    content={
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        spacing={1}
                                      >
                                        <Grid item>
                                          <SelectButtonGroup
                                            justify="center"
                                            multi={true}
                                            choices={(referral.likedTags || []).map(likedTag => ({
                                              value: likedTag,
                                              label: tryTranslate(intl, getLikedTagIntl(likedTag)),
                                              style: HOBBIES_TAG_STYLE,
                                              disableRipple: true,
                                              disableFocusRipple: true
                                            }))}
                                            value={[]}
                                          />
                                        </Grid>

                                        {/*{(referral.likedTags || []).map(likedTag => (*/}
                                        {/*  <Grid item key={likedTag}>*/}
                                        {/*    <AdviserInfoTagChip*/}
                                        {/*      isIntoView={true}*/}
                                        {/*      color={THEME_COLOR_PALETTES['blue'][1]}*/}
                                        {/*      label={tryTranslate(intl, getLikedTagIntl(likedTag))}*/}
                                        {/*    />*/}
                                        {/*  </Grid>*/}
                                        {/*))}*/}
                                      </Grid>
                                    }
                                  />

                                  <ReferralListItem
                                    alignItems="center"
                                    title={`${intl.formatMessage({ id: 'leave-comment' })}:`}
                                    content={
                                      <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        spacing={1}
                                      >
                                        {referral.helpItems && (
                                          <Grid item>
                                            <SelectButtonGroup
                                              justify="center"
                                              multi={true}
                                              choices={(referral.helpItems || []).map(helpItem => ({
                                                value: helpItem,
                                                label: tryTranslate(intl, getLikedTagIntl(helpItem)),
                                                style: HOBBIES_TAG_STYLE,
                                                disableRipple: true,
                                                disableFocusRipple: true
                                              }))}
                                              value={[]}
                                            />

                                            {/*{(referral.helpItems || []).map(helpItem => (*/}
                                            {/*  <Grid item key={helpItem}>*/}
                                            {/*    <AdviserInfoTagChip*/}
                                            {/*      isIntoView={true}*/}
                                            {/*      color={THEME_COLOR_PALETTES['blue'][1]}*/}
                                            {/*      label={tryTranslate(intl, getHelpItemIntl(helpItem))}*/}
                                            {/*    />*/}
                                            {/*  </Grid>*/}
                                            {/*))}*/}
                                          </Grid>
                                        )}
                                        {referral.remark && <Grid item>{referral.remark || ''}</Grid>}
                                      </Grid>
                                    }
                                  />

                                  {/*<ReferralListItem*/}
                                  {/*  title={`${intl.formatMessage({ id: 'WhatsApp' })}:`}*/}
                                  {/*  content={*/}
                                  {/*    <ReferralActionableValue*/}
                                  {/*      value={referral.whatsapp}*/}
                                  {/*      action={*/}
                                  {/*        referral.whatsapp && (*/}
                                  {/*          <Grid item className="action">*/}
                                  {/*            <button*/}
                                  {/*              className="list-link-btn"*/}
                                  {/*              onClick={this.clientMessages.bind(this, referral)}*/}
                                  {/*            >*/}
                                  {/*              {intl.formatMessage({ id: 'Go' })}*/}
                                  {/*            </button>*/}
                                  {/*          </Grid>*/}
                                  {/*        )*/}
                                  {/*      }*/}
                                  {/*    />*/}
                                  {/*  }*/}
                                  {/*/>*/}

                                  {/*<ReferralListItem*/}
                                  {/*  title={`${intl.formatMessage({ id: 'WeChat' })}:`}*/}
                                  {/*  content={referral.wechatId}*/}
                                  {/*/>*/}

                                  {/*<ReferralListItem*/}
                                  {/*  title={`${intl.formatMessage({ id: 'Line ID' })}:`}*/}
                                  {/*  content={referral.lineId}*/}
                                  {/*/>*/}

                                  <ReferralListItem
                                    title={`${intl.formatMessage({ id: 'Refer By' })}:`}
                                    content={
                                      <ReferralActionableValue
                                        value={
                                          referral.referBy ||
                                          (referral.referUserId && referral.referUserFactsheetId) ? (
                                            <span style={LinkStyle} onClick={showWho}>
                                              {whoText}
                                            </span>
                                          ) : (
                                            whoText
                                          )
                                        }
                                      />
                                    }
                                  />

                                  <ReferralListItem
                                    title={`${intl.formatMessage({ id: 'Date' })}:`}
                                    content={moment(referral.createdate)
                                      .utcOffset(getUserTimezone(userDetails))
                                      .format('ddd DD-MMM-YYYY HH:mm')}
                                  />

                                  <ReferralListItem
                                    title={`${intl.formatMessage({ id: 'source' })}:`}
                                    content={sourceStr}
                                    divider={false}
                                  />
                                </List>
                              </ExpansionPanelDetails>
                              <ExpansionPanelActions>
                                <Grid container justify="space-between">
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => openDeleteDialog(referral._id)}
                                    >
                                      {intl.formatMessage({ id: 'Delete' })}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => addClient(referral)}>
                                      {intl.formatMessage({ id: 'referral-add' })}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </ExpansionPanelActions>
                            </ExpansionPanel>
                          </Grid>
                        );
                      })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralRecordView;
