import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import NativeOrWeb from '../../../utils/native';
import { toggleDialog } from '../../../store/control/duck';

const DialogStyle = {
  zIndex: 13000
};

const AppIconChangingDialog = props => {
  const { intl, appIconChangingDialog, style, toggleDialog, ...rest } = props;

  const { appIconId, closed } = appIconChangingDialog || {};

  const platform = NativeOrWeb.getPlatform();

  const close = () => toggleDialog('appIconChanging', { ...appIconChangingDialog, closed: true });
  const onExited = () => toggleDialog('appIconChanging', false);

  const onClick = async () => {
    NativeOrWeb.changeAppIcon(appIconId)
      .then()
      .catch();
    close();
  };

  useEffect(() => {
    if (appIconChangingDialog) {
      document.body.classList.add('blur-4px');
    } else {
      document.body.classList.remove('blur-4px');
    }

    return () => {
      document.body.classList.remove('blur-4px');
    };
  }, [appIconChangingDialog]);

  return (
    <Dialog
      className="app-icon-changing-dialog blur-except"
      open={!!appIconChangingDialog && !closed}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      style={{ ...DialogStyle, ...style }}
      {...rest}
    >
      <DialogTitle>{intl.formatMessage({ id: 'cap-app-icon-changing-dialog-title' })}</DialogTitle>
      <DialogContent>
        {intl.formatMessage({ id: platform === 'ios' ? 'cap-icon-auto-applied-ios' : 'cap-icon-auto-applied-an' })}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClick}>
          {intl.formatMessage({ id: platform === 'ios' ? 'OK' : 'restart' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    appIconChangingDialog: state.control.appIconChangingDialog
  }),
  {
    toggleDialog
  }
)(AppIconChangingDialog);

export default injectIntl(container);
