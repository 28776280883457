import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { FormGroup, FormControlLabel, Checkbox, Grid, Button, Typography, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import { updateUserDetails } from '../../../store/user/duck';
import './PolicyReviewEditLayout.scss';

const PolicyReviewEditLayout = props => {
  var { intl, open, closeDialog, updatePolicyReviewInputs, currency } = props;
  const dispatch = useDispatch();
  const state = useSelector(s => s.user);

  const [checkedValues, setCheckedValues] = useState(
    state.userDetails.policyReviewLayout
      ? state.userDetails.policyReviewLayout
      : {
          basicInfo: true,
          coverage: true,
          premium: true,
          // Filter Columns - Basic Info
          saving: true,
          lifeInsured: true,
          beneficiary: true,
          // Coverage
          accident: true,
          totalWithdrawals: true,
          // Premium
          lumpsum: true,
          currencyPerYear: true,
          // Display Options
          total: true,
          showLacks: true,
          personalPolicy: true,
          miscPolicy: true,
          workplacePolicy: true,
          showIn2Rows: true,
          printFriendly: false,
          teamLogo: true
        }
  );

  if (state.userDetails.policyReviewLayout) {
    displayLatestLayout();
  }

  const handleMasterChange = category => {
    return event => {
      const newCheckedState = event.target.checked;
      const updatedValues = { ...checkedValues, [category]: newCheckedState };

      // Update related fields when master checkbox changes
      if (category === 'basicInfo') {
        updatedValues.saving = newCheckedState;
        updatedValues.lifeInsured = newCheckedState;
        updatedValues.beneficiary = newCheckedState;
      } else if (category === 'coverage') {
        updatedValues.accident = newCheckedState;
        updatedValues.totalWithdrawals = newCheckedState;
      } else if (category === 'premium') {
        updatedValues.lumpsum = newCheckedState;
        updatedValues.currencyPerYear = newCheckedState;
      }

      setCheckedValues(updatedValues);
    };
  };

  const handleChange = event => {
    const name = event.target.name;
    const updatedValues = { ...checkedValues, [name]: event.target.checked };

    if (!updatedValues.showIn2Rows) {
      updatedValues.printFriendly = false;
    }

    // Update master checkboxes based on individual checkboxes
    if (name in { saving: true, lifeInsured: true, beneficiary: true }) {
      updatedValues.basicInfo =
        updatedValues.basicInfo || updatedValues.saving || updatedValues.lifeInsured || updatedValues.beneficiary;
    } else if (name in { accident: true, totalWithdrawals: true }) {
      updatedValues.coverage = updatedValues.coverage || updatedValues.accident || updatedValues.totalWithdrawals;
    } else if (name in { lumpsum: true, currencyPerYear: true }) {
      updatedValues.premium = updatedValues.premium || updatedValues.lumpsum || updatedValues.currencyPerYear;
    }

    setCheckedValues(updatedValues);
  };

  const renderSection = (title, fields, masterCheckboxName) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox checked={checkedValues[masterCheckboxName]} onChange={handleMasterChange(masterCheckboxName)} />
        }
        label={title}
        style={{ marginTop: 4, marginBottom: 4 }}
      />
      <div style={{ marginLeft: 20 }}>
        {fields.map(field => (
          <div key={field.name}>
            <FormControlLabel
              control={<Checkbox checked={checkedValues[field.name]} onChange={handleChange} name={field.name} />}
              label={field.label}
              style={{ marginTop: 0, marginBottom: 0 }}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const handleSave = () => {
    dispatch(updateUserDetails({ policyReviewLayout: checkedValues }));
    displayLatestLayout();
    closeDialog();
  };

  function displayLatestLayout() {
    updatePolicyReviewInputs('selectBasic', checkedValues.basicInfo);
    updatePolicyReviewInputs('selectProtection', checkedValues.coverage);
    updatePolicyReviewInputs('selectPremium', checkedValues.premium);

    updatePolicyReviewInputs('selectSaving', checkedValues.saving);
    updatePolicyReviewInputs('selectLifeInsured', checkedValues.lifeInsured);
    updatePolicyReviewInputs('selectBeneficiary', checkedValues.beneficiary);
    updatePolicyReviewInputs('selectAccident', checkedValues.accident);
    updatePolicyReviewInputs('selectTotalWithdrawals', checkedValues.totalWithdrawals);
    updatePolicyReviewInputs('selectLumpSum', checkedValues.lumpsum);
    updatePolicyReviewInputs('selectCurrencyPerYear', checkedValues.currencyPerYear);

    updatePolicyReviewInputs('selectPersonal', checkedValues.personalPolicy);
    updatePolicyReviewInputs('selectCompany', checkedValues.workplacePolicy);
    updatePolicyReviewInputs('selectGeneral', checkedValues.miscPolicy);

    updatePolicyReviewInputs('selectTotalCoveragePremium', checkedValues.total);
    updatePolicyReviewInputs('selectShowLacks', checkedValues.showLacks);
    updatePolicyReviewInputs('selectTwoRows', checkedValues.showIn2Rows);
    updatePolicyReviewInputs('selectPageSplit', checkedValues.printFriendly);
    updatePolicyReviewInputs('selectTeamLogo', checkedValues.teamLogo);
  }

  return (
    <BottomSheetOrDialog
      className="policy-review-edit-layout-dialog"
      open={open}
      onClose={closeDialog}
      header={intl.formatMessage({ id: 'edit-layout' })}
      content={
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormGroup>
              {renderSection(
                intl.formatMessage({ id: 'pr-table-basic-info' }),
                [
                  { name: 'saving', label: intl.formatMessage({ id: 'Saving' }) },
                  { name: 'lifeInsured', label: intl.formatMessage({ id: 'Life Insured' }) },
                  { name: 'beneficiary', label: intl.formatMessage({ id: 'Beneficiary' }) }
                ],
                'basicInfo'
              )}
              {renderSection(
                intl.formatMessage({ id: 'pr-table-protection' }),
                [
                  { name: 'accident', label: intl.formatMessage({ id: 'Accident' }) },
                  { name: 'totalWithdrawals', label: intl.formatMessage({ id: 'Total withdrawals' }) }
                ],
                'coverage'
              )}
              {renderSection(
                intl.formatMessage({ id: 'pr-table-premium' }),
                [
                  { name: 'lumpsum', label: intl.formatMessage({ id: 'LumpSum amount' }) },
                  {
                    name: 'currencyPerYear',
                    label: `${intl.formatMessage({ id: currency })} / ${intl.formatMessage({ id: 'year' })}`
                  }
                ],
                'premium'
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkedValues.total} onChange={handleChange} name="total" />}
                label={intl.formatMessage({ id: 'show-total' })}
              />
              <FormControlLabel
                control={<Checkbox checked={checkedValues.showLacks} onChange={handleChange} name="showLacks" />}
                label={intl.formatMessage({ id: 'show-empty-rows' })}
              />
              <br />
              <Typography style={{ fontWeight: 'bold' }}> {intl.formatMessage({ id: 'policy-type' })} </Typography>
              <FormControlLabel
                control={
                  <Checkbox checked={checkedValues.personalPolicy} onChange={handleChange} name="personalPolicy" />
                }
                label={intl.formatMessage({ id: 'Personal Policy' })}
              />
              <FormControlLabel
                control={
                  <Checkbox checked={checkedValues.workplacePolicy} onChange={handleChange} name="workplacePolicy" />
                }
                label={intl.formatMessage({ id: 'Workplace Policy' })}
              />
              <FormControlLabel
                control={<Checkbox checked={checkedValues.miscPolicy} onChange={handleChange} name="miscPolicy" />}
                label={intl.formatMessage({ id: 'General Policy' })}
              />
              <br />
              <Typography style={{ fontWeight: 'bold' }}>
                {' '}
                {intl.formatMessage({ id: 'Select fields to export' })}{' '}
              </Typography>
              <FormControlLabel
                control={<Checkbox checked={checkedValues.showIn2Rows} onChange={handleChange} name="showIn2Rows" />}
                label={intl.formatMessage({ id: 'pr-two-rows' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedValues.printFriendly}
                    onChange={handleChange}
                    name="printFriendly"
                    disabled={!checkedValues.showIn2Rows}
                  />
                }
                label={intl.formatMessage({ id: 'pr-page-split' })}
              />
              <FormControlLabel
                control={<Checkbox checked={checkedValues.teamLogo} onChange={handleChange} name="teamLogo" />}
                label={intl.formatMessage({ id: 'use_export_logo' })}
              />
            </FormGroup>
          </Grid>
        </Grid>
      }
      actions={[
        <Button onClick={handleSave} color="primary">
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      ]}
      BottomSheetProps={{
        expandOnContentDrag: false,
        disableAutoDismiss: true
      }}
      DialogProps={{
        maxWidth: 'sm',
        fullWidth: true
      }}
    />
  );
};

export default injectIntl(PolicyReviewEditLayout);
