import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  getFinancialPyramid,
  resetFinancialPyramid,
  saveFinancialPyramid,
  updateFinancialPyramid,
  updateFinancialPyramidInputs
} from '../../../store/financialPyramid/duck';
import EditIcon from '@material-ui/icons/Edit';
import PresentModeSwitch from '../../PolicyReview/PresentModeSwitch/PresentModeSwitch';
import _ from 'lodash';
import FinancialPyramid from './FinancialPyramid';
import { isClientDisconnected, setClientIdLocalStorage } from '../../../components/ClientEntry/utils';
import PolicyReviewChooseClient from '../../PolicyReview/PolicyReviewChooseClient/PolicyReviewChooseClient';
import { submitClient, updateViewingClient } from '../../../store/client/duck';
import MeetingDialog from '../../../components/ClientEntry/MeetingDialog/MeetingDialog';
import { getPDF } from '../../../store/pdf/api';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { LOADING, SUCCESS } from '../../../constants/phase';
import TagsDialog from '../../../components/ClientEntry/TagsDialog/TagsDialog';
import FinancialConceptButton from './FinancialConceptButton/FinancialConceptButton';
import ModeSwitch from './ModeSwitch/ModeSwitch';
import ConfirmSaveDialog from './ConfirmSaveDialog/ConfirmSaveDialog';
import IconToast from '../../../components/NewToast';
import LeavePageConfirm from '../../../components/LeavePageConfirm/LeavePageConfirm';
import ConfirmSwitchClientDialog from './ConfirmSwitchClientDialog/ConfirmSwitchClientDialog';
import ExportHintDialog from '../../../components/ExportHintDialog/ExportHintDialog';
import { tracking } from '../../../utils/tracking';
import './FinancialPyramidContainer.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { usePrevious } from '../../../utils/hooks';
import { hasCampaignCode, isAdviserType } from '../../../utils/user';
import AnalysisButton from './AnalysisButton/AnalysisButton';
import PreviewWrapper from '../../../pdf-templates/PreviewWrapper';
import LanguageRadioGroup from '../../../components/LanguageRadioGroup/LanguageRadioGroup';
import ExitWhiteboardDialog from '../../../components/MyWhiteboard/ExitWhiteboardDialog/ExitWhiteboardDialog';
import MyWhiteboard from '../../../components/MyWhiteboard/MyWhiteboard';
import WhiteboardButton from './WhiteboardButton/WhiteboardButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { isSubscriptionAllowAction } from '../../../utils/user-subscription-store';
import NativeOrWeb from '../../../utils/native';
import ExpenseCalculatorButton from './ExpenseCalculatorButton/ExpenseCalculatorButton';
import SelectButtonGroup from '../../../components/SelectButtonGroup/SelectButtonGroup';
import { getDefaultFinancialPyramidCopy } from './utils';
import WhiteboardRemoteHintDialog from './WhiteboardRemoteHintDialog/WhiteboardRemoteHintDialog';
import RemoteButton from './RemoteButton/RemoteButton';
import EditDisplayNamesDialog from '../../../components/EditDisplayNamesDialog/EditDisplayNamesDialog';
import produce from 'immer';
import { toggleDialog } from '../../../store/control/duck';
import PolicyPicker from '../../../components/PolicyPicker/PolicyPicker';
import animateScrollTo from 'animated-scroll-to';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import { trackDirect } from '../../../store/analytics/api';

const FullWidthStyle = {
  width: '100%'
};

const FinancialPyramidContainer = props => {
  const {
    intl,
    history,
    userDetails,
    viewingClient,
    handwritingMode,
    presentMode,
    financialPyramid,
    language,
    pyramidIndex,
    loading,
    fetchClientPhase,
    createClientPhase,
    getPhase,
    updatePhase,
    teamCampaignCodes,
    updateViewingClient,
    updateFinancialPyramidInputs,
    updateFinancialPyramid,
    getFinancialPyramid,
    saveFinancialPyramid,
    submitClient,
    resetFinancialPyramid,
    toggleDialog
  } = props;

  const infoSectionRef = useRef(null);
  const expenseUpdateEditFlagRef = useRef(null);
  const expenseUpdateDetailsRef = useRef(null);

  const [internalFinancialPyramid, setInternalFinancialPyramid] = useState(financialPyramid);
  const [updated, setUpdated] = useState(false);
  const [editEntriesDisplayNameDialog, setEditEntriesDisplayNameDialog] = useState(false);
  const [confirmSaveDialog, setConfirmSaveDialog] = useState(false);
  const [confirmChangeClientDialog, setConfirmChangeClientDialog] = useState(false);
  const [hintDialog, setHintDialog] = useState(false);
  const [exitWhiteboardDialog, setExitWhiteboardDialog] = useState(false);
  const [monthlyIncome, setMonthlyIncome] = useState(_.get(viewingClient, 'factsheetId.monthlyIncome', 0)); //sets default value on monthly income
  const [monthlyExpense, setMonthlyExpense] = useState(_.get(viewingClient, 'factsheetId.monthlyExpense', 0)); //sets default value on monthly expense
  const [expenses, setExpenses] = useState(_.get(viewingClient, 'factsheetId.expenses', {})); //sets default value on expenses
  const [showIncome, setShowIncome] = useState(true);
  const [showRecord, setShowRecord] = useState(true);
  const [showIncomeAnimation, setShowIncomeAnimation] = useState(true);
  const [showRecordAnimation, setShowRecordAnimation] = useState(true);
  const [whiteboardRemoteHintDialog, setWhiteboardRemoteHintDialog] = useState(false);
  const [showExpenseCalculator, setShowExpenseCalculator] = useState(true);
  const [showExpenseCalculatorAnimation, setShowExpenseCalculatorAnimation] = useState(true);
  const [policyPicker, setPolicyPicker] = useState(false);

  const prevGetPhase = usePrevious(getPhase);
  const prevUpdatePhase = usePrevious(updatePhase);
  const prevCreateClientPhase = usePrevious(createClientPhase);

  const financialPyramidEntry = _.get(
    internalFinancialPyramid,
    `entries[${pyramidIndex}]`,
    getDefaultFinancialPyramidCopy(language)
  );
  const { emergencyFund } = financialPyramidEntry || {};

  const isSM = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isMD = useMediaQuery(theme => theme.breakpoints.up('md'));

  const clientName = isAdviserType(userDetails) ? _.get(viewingClient, 'factsheetId.name') : _.get(userDetails, 'name');
  const currency = _.get(userDetails, 'currency', 'HKD');
  const clientId = _.get(viewingClient, '_id');
  const factsheetId = _.get(viewingClient, 'factsheetId._id');

  const shouldShownRemoteButton = isSM && hasCampaignCode(userDetails, teamCampaignCodes, 'REMOTEWHITEBOARD');

  const switchHandwritingMode = () => {
    if (handwritingMode === true) {
      setExitWhiteboardDialog(true);
    } else {
      tracking('Click on Whiteboard- Financial pyramid');
      updateFinancialPyramidInputs('handwritingMode', true);
      updateFinancialPyramidInputs('presentMode', true);
    }
  };
  const switchPresentMode = event => updateFinancialPyramidInputs('presentMode', event.target.checked);
  const openEditEntriesDisplayNameDialog = () => setEditEntriesDisplayNameDialog(true);
  const closeEditEntriesDisplayNameDialog = () => setEditEntriesDisplayNameDialog(false);
  const openConfirmSaveDialog = () => setConfirmSaveDialog(true);
  const closeConfirmSaveDialog = () => setConfirmSaveDialog(false);
  const closeChangeClientDialog = () => setConfirmChangeClientDialog(false);
  const closeHintDialog = () => setHintDialog(false);
  const closeExitWhiteboardDialog = () => {
    updateFinancialPyramidInputs('handwritingMode', false);
    updateFinancialPyramidInputs('presentMode', false);
    setExitWhiteboardDialog(false);
  };
  const closeWhiteboardRemoteHintDialog = () => {
    setWhiteboardRemoteHintDialog(false);
  };

  const onChange = data => {
    if ('monthlyIncome' in data) {
      setMonthlyIncome(data.monthlyIncome);
    }

    if ('monthlyExpense' in data) {
      setMonthlyExpense(data.monthlyExpense);
    }

    if ('expenses' in data) {
      setExpenses(data.expenses);
    }

    for (let key of ['risk', 'investment', 'saving', 'protection', 'emergencyFund']) {
      if (key in data) {
        const newState = produce(internalFinancialPyramid, draft => {
          draft.entries[pyramidIndex][key] = data[key];
        });
        setInternalFinancialPyramid(newState);
      }
    }
    setUpdated(true);
  };

  const selectClient = client => {
    const isDisconnected = isClientDisconnected(client);
    if (!isDisconnected) {
      setUpdated(false);
      updateViewingClient(client);
      setClientIdLocalStorage(_.get(client, '_id'));
    }
  };
  const onSelectClient = client => {
    if (updated) {
      setConfirmChangeClientDialog(client);
    } else {
      updateFinancialPyramidInputs('pyramidIndex', 0);
      selectClient(client);
    }
  };
  const confirmSelectClient = () => {
    expenseUpdateEditFlagRef.current = null;
    expenseUpdateDetailsRef.current = null;
    updateFinancialPyramidInputs('pyramidIndex', 0);
    selectClient(confirmChangeClientDialog);
    setConfirmChangeClientDialog(false);
  };

  const save = () => {
    const clientId = _.get(viewingClient, '_id');
    if (clientId) {
      saveFinancialPyramid(clientId, internalFinancialPyramid);

      if (userDetails) {
        const { factsheetId, email } = viewingClient || {};
        submitClient({
          monthlyIncome: monthlyIncome,
          monthlyExpense: monthlyExpense,
          expenses: expenses,

          advisorId: userDetails._id,
          connectedAdviserEmail: userDetails.email,

          clientId: clientId,
          _id: factsheetId._id,
          email: email
        });
      }
    }
    setUpdated(false);
  };

  const saveDisplayNames = displayNames => {
    const clientId = _.get(viewingClient, '_id');
    if (clientId) {
      // update state
      if (internalFinancialPyramid && internalFinancialPyramid.entries) {
        const newFinancialPyramid = produce(internalFinancialPyramid, draft => {
          for (const [index, displayName] of (displayNames || []).entries()) {
            if (index <= draft.entries.length - 1) {
              draft.entries[index].displayName = displayName;
            } else {
              draft.entries.push({
                ...getDefaultFinancialPyramidCopy(intl.locale),
                displayName: displayName
              });
            }
          }
        });

        setInternalFinancialPyramid(newFinancialPyramid);
      }

      // save
      if (financialPyramid && financialPyramid.entries) {
        const newFinancialPyramid = produce(financialPyramid, draft => {
          for (const [index, displayName] of (displayNames || []).entries()) {
            if (index <= draft.entries.length - 1) {
              draft.entries[index].displayName = displayName;
            } else {
              draft.entries.push({
                ...getDefaultFinancialPyramidCopy(intl.locale),
                displayName: displayName
              });
            }
          }
        });

        if (saveFinancialPyramid) {
          saveFinancialPyramid(clientId, newFinancialPyramid);
        }
      }
    }
    closeEditEntriesDisplayNameDialog();
  };

  const generate = () => {
    if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) {
      return;
    }

    setHintDialog(true);
  };

  const onConfirmGenerate = async ({ password, isPrivateMode }) => {
    if (!loading) {
      tracking('Click on PDF export - Financial pyramid');
      updateFinancialPyramidInputs('loading', true);

      try {
        const options = {
          clientName,
          currency,
          monthlyIncome,
          monthlyExpense,
          emergencyFund,
          financialPyramidEntry,
          handwritingMode,
          language,
          showIncome,
          showRecord,
          showExpenseCalculator,
          expenses,
          adviser: userDetails,
          outputFormat: 'pdf',
          password,
          isPrivateMode
        };

        const pdfBlob = await getPDF('financial-pyramid', options);
        trackDirect("exportFinancialPyramid");
        await NativeOrWeb.downloadFile(
          pdfBlob,
          `Financial_Pyramid_${(clientName || 'Client').split(' ')[0]}_v${pyramidIndex + 1}_${moment().format(
            'DDMMMYYYY'
          )}.pdf`,
          'application/pdf'
        );
      } catch (error) {
        toast.error(error.message);
      } finally {
        updateFinancialPyramidInputs('loading', false);
      }
    }
  };

  const onChangeLanguage = event => {
    const getNewInputDataAfterLanguageChange = (defaultData, currentData, nextData) => {
      return currentData.map((item, index) => {
        const defaultItem = _.get(defaultData, `[${index}]`);

        if (defaultItem) {
          if (item.name === defaultItem.name) {
            const nextName = _.get(nextData, `[${index}].name`, item.name);
            return { ...item, name: nextName };
          }
        }

        return item;
      });
    };

    for (let target of ['risk', 'investment', 'saving', 'protection']) {
      onChange({
        [target]: getNewInputDataAfterLanguageChange(
          getDefaultFinancialPyramidCopy(language)[target],
          financialPyramidEntry[target],
          getDefaultFinancialPyramidCopy(event.target.value)[target]
        )
      });
    }

    updateFinancialPyramidInputs('language', event.target.value);
  };
  const onChangeShowIncome = () => {
    setShowIncomeAnimation(!showIncome);
  };
  const onChangeShowRecord = () => {
    setShowRecordAnimation(!showRecord);
  };
  const onShowIncomeAnimationDone = () => {
    setShowIncome(showIncomeAnimation);
    if (expenseUpdateEditFlagRef.current) {
      expenseUpdateEditFlagRef.current = null;
      if (infoSectionRef && infoSectionRef.current) {
        const wrappedComponent = infoSectionRef.current.getWrappedInstance();
        wrappedComponent.toggleInfoEdit(true);
      }
    }
  };
  const onShowRecordAnimationDone = () => {
    setShowRecord(showRecordAnimation);
  };
  const onToggleShowExpenseCalculator = () => {
    setShowExpenseCalculatorAnimation(!showExpenseCalculator);
  };
  const onShowExpenseCalculatorAnimationDone = () => {
    setShowExpenseCalculator(showExpenseCalculatorAnimation);
    if (showExpenseCalculatorAnimation) {
      animateScrollTo(document.body.scrollHeight, { minDuration: 1000 }).then();
    }
  };
  const onSubmitExpenses = (total, expenses) => {
    setMonthlyExpense(total);
    setExpenses(expenses);
    setUpdated(true);
    expenseUpdateDetailsRef.current = { total: total };

    if (showIncome) {
      // if (infoSectionRef && infoSectionRef.current) {
      //   const wrappedComponent = infoSectionRef.current.getWrappedInstance();
      //   wrappedComponent.toggleInfoEdit(true);
      // }
    } else {
      expenseUpdateEditFlagRef.current = true;
      setShowIncomeAnimation(true);
    }
  };

  const goToRemote = () => {
    setWhiteboardRemoteHintDialog(true);
  };

  const openPolicyPicker = details => {
    setPolicyPicker(details);
  };

  const onSubmitPolicyPicker = policies => {
    const { onSubmit } = policyPicker;
    if (onSubmit) {
      onSubmit(policies);
    }
  };

  const onClosePolicyPicker = () => {
    setPolicyPicker(false);
  };

  useEffect(() => {
    tracking('Financial Pyramid Accessed');
    trackDirect("viewFinancialPyramid");
  }, []);

  useEffect(() => {
    if (getPhase === SUCCESS && getPhase !== prevGetPhase) {
      setInternalFinancialPyramid(financialPyramid);
    }
  }, [getPhase, prevGetPhase, financialPyramid]);

  useEffect(() => {
    updateFinancialPyramidInputs('language', intl.locale);
  }, [intl.locale, updateFinancialPyramidInputs]);

  useEffect(() => {
    setMonthlyIncome(_.get(viewingClient, 'factsheetId.monthlyIncome', 0));
    let finalMonthlyExpense = _.get(viewingClient, 'factsheetId.monthlyExpense', 0);
    if (expenseUpdateDetailsRef.current) {
      finalMonthlyExpense = expenseUpdateDetailsRef.current.total;
      expenseUpdateDetailsRef.current = null;
    }
    setMonthlyExpense(finalMonthlyExpense);
    setExpenses(_.get(viewingClient, 'factsheetId.expenses', {}));
  }, [viewingClient]);

  useEffect(() => {
    setClientIdLocalStorage(viewingClient?._id);
  }, [viewingClient, history]);

  useEffect(() => {
    const viewingClientId = _.get(viewingClient, '_id');
    if (financialPyramid.clientId !== viewingClientId) {
      const isDisconnected = isClientDisconnected(viewingClient);
      if (!isDisconnected) {
        getFinancialPyramid(viewingClientId);
      }
    }
  }, [financialPyramid.clientId, getFinancialPyramid, viewingClient]);

  useEffect(() => {
    if (createClientPhase === true && updatePhase === SUCCESS) {
      if (prevCreateClientPhase !== true || prevUpdatePhase !== SUCCESS) {
        toast.info(IconToast('success', intl.formatMessage({ id: 'saved-successfully' })), { className: 'new-toast' });
      }
    }
  }, [createClientPhase, updatePhase, prevUpdatePhase, prevCreateClientPhase, intl]);

  useEffect(() => {
    return () => {
      resetFinancialPyramid();
    };
  }, [resetFinancialPyramid]);

  useEffect(() => {
    return () => {
      expenseUpdateEditFlagRef.current = null;
      expenseUpdateDetailsRef.current = null;
    };
  }, []);

  const versionChoices = [0, 1].map(index => {
    const defaultLabel = intl.formatMessage({ id: 'fin-py-ver' }, { index: index + 1 });
    const getDisplayName = entry => {
      return entry.displayName ? entry.displayName : defaultLabel;
    };
    return {
      label: internalFinancialPyramid.entries[index]
        ? getDisplayName(internalFinancialPyramid.entries[index])
        : defaultLabel,
      value: index,
      disabled: !clientId
    };
  });

  const VersionButtons = () => (
    <SelectButtonGroup
      buttonClassName="version-button"
      value={pyramidIndex}
      choices={versionChoices}
      onChange={event => {
        updateFinancialPyramidInputs(
          'pyramidIndex',
          event.target.value === undefined ? (pyramidIndex === 0 ? 1 : 0) : event.target.value
        );
      }}
    />
  );

  return (
    <Container
      className="financial-pyramid-container"
      maxWidth="xl"
      style={{ padding: !presentMode ? '50px 16px calc(62px + var(--bottom-padding))' : 0 }}
    >
      <LeavePageConfirm shouldShow={updated} save={save} />

      <ConfirmSwitchClientDialog
        open={confirmChangeClientDialog !== false}
        onClose={closeChangeClientDialog}
        onConfirm={confirmSelectClient}
      />

      <ExportHintDialog
        open={hintDialog}
        onClose={closeHintDialog}
        onConfirm={onConfirmGenerate}
        shouldUsePassword={true}
      />

      <ExitWhiteboardDialog open={exitWhiteboardDialog} onClose={closeExitWhiteboardDialog} />

      <WhiteboardRemoteHintDialog open={whiteboardRemoteHintDialog} onClose={closeWhiteboardRemoteHintDialog} />

      <PolicyPicker
        userId={!isClientDisconnected(viewingClient) ? _.get(viewingClient, '_id') : undefined}
        open={!!policyPicker}
        onClose={onClosePolicyPicker}
        onSubmit={onSubmitPolicyPicker}
        shouldResetPickedAfterSubmit={true}
      />

      {(fetchClientPhase === LOADING ||
        createClientPhase === LOADING ||
        getPhase === LOADING ||
        updatePhase === LOADING) && <LoadingBackdrop open={true} />}

      {!presentMode && (
        <Fragment>
          <MeetingDialog />
          <TagsDialog />
        </Fragment>
      )}

      <ConfirmSaveDialog
        pyramidIndex={pyramidIndex}
        open={confirmSaveDialog}
        onClose={closeConfirmSaveDialog}
        onConfirm={save}
      />

      <EditDisplayNamesDialog
        open={editEntriesDisplayNameDialog}
        onClose={closeEditEntriesDisplayNameDialog}
        displayNames={versionChoices.map(choice => choice.label)}
        onConfirm={saveDisplayNames}
      />

      <Grid container direction="column" spacing={2}>
        {!presentMode && (
          <Grid item>
            <AladdinHint title={intl.formatMessage({ id: 'aladdin-demo-video' })} helpCenter="c39" />
          </Grid>
        )}

        {!isSM && (
          <Grid item style={{ ...FullWidthStyle, textAlign: 'right' }}>
            <Fragment>
              {handwritingMode ? (
                <IconButton onClick={switchHandwritingMode}>
                  <ClearRoundedIcon />
                </IconButton>
              ) : !presentMode ? (
                <PresentModeSwitch
                  checked={presentMode}
                  onChange={switchPresentMode}
                  label={intl.formatMessage({ id: 'present-mode' })}
                />
              ) : null}
            </Fragment>
          </Grid>
        )}

        <Grid item style={FullWidthStyle}>
          {!viewingClient && <Typography color="error">{intl.formatMessage({ id: 'select-client-hint' })}</Typography>}
          <Grid container alignItems="center" justify={handwritingMode ? 'flex-end' : 'space-between'}>
            {!presentMode ? (
              <Grid item style={{ flexGrow: 1 }}>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item style={{ width: '100%', whiteSpace: 'pre' }}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <PolicyReviewChooseClient
                              selectedClient={viewingClient}
                              setSelectedClient={onSelectClient}
                            />
                          </Grid>
                          {!handwritingMode && (
                            <Grid item>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <VersionButtons />
                                </Grid>
                                <Grid item>
                                  <IconButton disabled={!clientId} onClick={openEditEntriesDisplayNameDialog}>
                                    <EditIcon style={{ fontSize: 18 }} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item>
                            <Grid
                              container
                              alignItems="center"
                              wrap="nowrap"
                              style={{
                                border: '1px solid #3966f8',
                                borderRadius: 8
                              }}
                            >
                              <Grid item>
                                <FinancialConceptButton style={{ padding: '3px 8px' }} />
                              </Grid>
                              <Grid item>
                                <ExpenseCalculatorButton
                                  clientId={clientId}
                                  expenses={expenses}
                                  onSubmit={onSubmitExpenses}
                                />
                              </Grid>
                              <Grid item>
                                <AnalysisButton client={viewingClient} style={{ padding: '3px 8px' }} />
                              </Grid>
                              <Grid item>
                                <WhiteboardButton onClick={switchHandwritingMode} style={{ padding: '3px 8px' }} />
                              </Grid>
                              {shouldShownRemoteButton && (
                                <Grid item>
                                  <RemoteButton onClick={goToRemote} style={{ padding: '3px 8px' }} />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center" spacing={1} wrap="nowrap" style={{ minHeight: 66 }}>
                              <Grid item>
                                <Button
                                  color={updated ? undefined : 'primary'}
                                  variant="contained"
                                  onClick={openConfirmSaveDialog}
                                  style={{
                                    padding: '2px 8px',
                                    background: updated ? 'red' : undefined,
                                    color: updated ? 'white' : undefined
                                  }}
                                  disabled={!viewingClient}
                                >
                                  {intl.formatMessage({ id: 'save-data' })}
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={generate}
                                  style={{ padding: '2px 8px' }}
                                  disabled={!viewingClient || loading}
                                >
                                  {loading ? (
                                    <CircularProgress size={24.5} />
                                  ) : (
                                    `${intl.formatMessage({ id: 'Export_action' })} PDF`
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {isSM && (
                        <Grid item>
                          {handwritingMode ? (
                            <IconButton onClick={switchHandwritingMode}>
                              <ClearRoundedIcon />
                            </IconButton>
                          ) : (
                            <PresentModeSwitch
                              checked={presentMode}
                              onChange={switchPresentMode}
                              label={intl.formatMessage({ id: 'present-mode' })}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : !handwritingMode ? (
              <Fragment>
                <Grid item style={{ paddingLeft: 8 }}>
                  <VersionButtons />
                </Grid>
                <Grid item>
                  <PresentModeSwitch
                    checked={presentMode}
                    onChange={switchPresentMode}
                    label={intl.formatMessage({ id: 'present-mode' })}
                  />
                </Grid>
              </Fragment>
            ) : (
              <Grid item>
                <IconButton onClick={switchHandwritingMode}>
                  <ClearRoundedIcon />
                </IconButton>
              </Grid>
            )}
            {!presentMode && (
              <Grid item>
                <Grid container direction="column" alignItems="flex-end">
                  <Grid item>
                    <LanguageRadioGroup value={language} onChange={onChangeLanguage} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item style={{ ...FullWidthStyle, border: !presentMode ? '3px solid #cbafe6' : undefined }}>
          <PreviewWrapper locale={language}>
            <FinancialPyramid
              adviser={userDetails}
              clientName={clientName}
              clientId={clientId}
              factsheetId={factsheetId}
              currency={currency}
              monthlyIncome={monthlyIncome}
              monthlyExpense={monthlyExpense}
              emergencyFund={emergencyFund}
              financialPyramidEntry={financialPyramidEntry}
              handwritingMode={handwritingMode}
              onChange={onChange}
              showIncome={showIncome}
              showRecord={showRecord}
              onChangeShowIncome={onChangeShowIncome}
              onChangeShowRecord={onChangeShowRecord}
              showIncomeAnimation={showIncomeAnimation}
              showRecordAnimation={showRecordAnimation}
              onShowIncomeAnimationDone={onShowIncomeAnimationDone}
              onShowRecordAnimationDone={onShowRecordAnimationDone}
              infoSectionRef={infoSectionRef}
              expenseUpdateDetailsRef={expenseUpdateDetailsRef}
              showExpenseCalculator={showExpenseCalculator}
              onToggleShowExpenseCalculator={onToggleShowExpenseCalculator}
              showExpenseCalculatorAnimation={showExpenseCalculatorAnimation}
              onShowExpenseCalculatorAnimationDone={onShowExpenseCalculatorAnimationDone}
              expenses={expenses}
              onSubmitExpenses={onSubmitExpenses}
              toggleDialog={toggleDialog}
              openPolicyPicker={openPolicyPicker}
            />
          </PreviewWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    viewingClient: state.client.viewingClient,
    fetchClientPhase: state.client.fetchClientPhase,
    createClientPhase: state.client.createClientPhase,
    categories: state.policy.categories,
    portfolio: state.policy.portfolio,
    fetchPhase: state.policy.fetchPhase,
    teamCampaignCodes: state.functions.campaignCodes,
    ...state.financialPyramid
  }),
  {
    updateViewingClient,
    updateFinancialPyramidInputs,
    updateFinancialPyramid,
    getFinancialPyramid,
    saveFinancialPyramid,
    submitClient,
    resetFinancialPyramid,
    toggleDialog
  }
)(FinancialPyramidContainer);

export default injectIntl(container);
