import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getAdviserProfilePlus } from '../../../store/adviserProfilePlus/duck';
import _ from 'lodash';
import AdviserInfoPage from '../../AdviserInfoPage/AdviserInfoPage';
import AdviserInfoPageManageActions from './AdviserInfoPageManageActions/AdviserInfoPageManageActions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Portal, useMediaQuery } from '@material-ui/core';
import { isNavAside } from '../../../utils/device';
import BottomButtons from './BottomButtons/BottomButtons';
import { tracking } from '../../../utils/tracking';
import { toggleDialog } from '../../../store/control/duck';

const AdviserInfoPageManage = props => {
  const {
    intl,
    history,
    location,
    showPadding,
    userDetails,
    adviserInfo,
    getAdviserProfilePlusPhase,
    getAdviserProfilePlus,
    toggleDialog
  } = props;

  const isWideMode = useMediaQuery('(min-width:1251px)');

  const userId = _.get(userDetails, '_id');

  const back = () => history.goBack();

  const share = () => {
    tracking('Click on Share to Clients- Profile+');
    const dialogObj = {
      shouldShowWallet: true,
      customCTAMessage: intl.formatMessage({ id: 'referral-card-message' }),
      defaultMessageIntlId: 'referral-link-message',
      defaultMessageExtras: { name: userDetails?.name }
    };

    toggleDialog('referralQRCode', dialogObj);
  };

  useEffect(() => {
    getAdviserProfilePlus(userId, location);
  }, [getAdviserProfilePlus, userId, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bottomButtons = <BottomButtons onShare={share} />;

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        paddingTop: 0,
        paddingBottom: 0
      }}
    >
      <AdviserInfoPageManageActions onShare={share} isWideMode={isWideMode} />
      <AdviserInfoPage
        withNav={true}
        isAdviser={true}
        wideExtras={!isWideMode ? [bottomButtons] : []}
        narrowExtras={[bottomButtons]}
      />
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    adviserInfo: _.get(state.adviserProfilePlus, 'adviserProfilePlus.adviserInfo'),
    getAdviserProfilePlusPhase: state.adviserProfilePlus.getAdviserProfilePlusPhase,
    showPadding: state.app.get('showPadding')
  }),
  {
    getAdviserProfilePlus,
    toggleDialog
  }
)(AdviserInfoPageManage);

export default injectIntl(container);
