import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { codePush, InstallMode } from 'capacitor-codepush';
import { SyncStatus } from 'capacitor-codepush/dist/esm/syncStatus';
import store from '../../store';
import { toggleDialog } from '../../store/control/duck';

const STORAGE_NATIVE_UPDATED_KEY = 'nativeUpdated';

// console.log({ codePush, InstallMode });

const isNativeUpdated = async () => {
  try {
    const storageResult = await Preferences.get({ key: STORAGE_NATIVE_UPDATED_KEY });
    return !!JSON.parse(storageResult.value);
  } catch (error) {
    return false;
  }
};

export const resetNativeUpdated = async () => {
  try {
    await Preferences.remove({ key: STORAGE_NATIVE_UPDATED_KEY });
    return true;
  } catch (error) {
    return false;
  }
};

export const runShouldShowUpdatedDialog = async () => {
  const isUpdated = await isNativeUpdated();
  console.log('isUpdated', isUpdated);

  if (isUpdated) {
    resetNativeUpdated()
      .then()
      .catch();
    store.dispatch(toggleDialog('nativeUpdated', {}));
  }
};

export const checkForOTAUpdate = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        codePush.checkForUpdate(
          remotePackage => resolve(remotePackage),
          error => reject(error)
        );
      });
      return !!result;
    } catch (error) {
      console.log(error);
      return false;
    }
  } else {
    return false;
  }
};

export const updateOTA = async forceUpdate => {
  try {
    const consoleLogStatus = async status => {
      switch (status) {
        case SyncStatus.UP_TO_DATE:
          console.log('UP_TO_DATE');
          runShouldShowUpdatedDialog()
            .then()
            .catch(console.log);
          break;
        case SyncStatus.UPDATE_INSTALLED:
          console.log('UPDATE_INSTALLED');
          try {
            await Preferences.set({ key: STORAGE_NATIVE_UPDATED_KEY, value: JSON.stringify(true) });
            await codePush.notifyApplicationReady();
          } catch (error) {
            console.error(error);
          }
          break;
        case SyncStatus.UPDATE_IGNORED:
          console.log('UPDATE_IGNORED');
          break;
        case SyncStatus.ERROR:
          console.log('ERROR');
          break;
        case SyncStatus.IN_PROGRESS:
          console.log('IN_PROGRESS');
          break;
        case SyncStatus.CHECKING_FOR_UPDATE:
          console.log('CHECKING_FOR_UPDATE');
          break;
        case SyncStatus.AWAITING_USER_ACTION:
          console.log('AWAITING_USER_ACTION');
          break;
        case SyncStatus.DOWNLOADING_PACKAGE:
          console.log('DOWNLOADING_PACKAGE');
          break;
        case SyncStatus.INSTALLING_UPDATE:
          console.log('INSTALLING_UPDATE');
          break;
        default:
          console.log('UNDEFINED');
      }
    };

    const status = await codePush.sync({
      installMode: forceUpdate ? InstallMode.IMMEDIATE : InstallMode.ON_NEXT_RESTART,
      onSyncStatusChanged: status => {
        consoleLogStatus(status);
      },
      onSyncError: error => {
        console.error(error);
      }
    });
    consoleLogStatus(status)
      .then()
      .catch();

    return status;
  } catch (error) {
    console.error(error);
    return SyncStatus.ERROR;
  }
};

if (process.env.DISABLE_NATIVE_UPDATE !== 'true') {
  if (Capacitor.isNativePlatform()) {
    codePush
      .notifyApplicationReady()
      .then(() => {
        updateOTA()
          .then()
          .catch(console.log);
      })
      .catch(console.log);
  }
}
