import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  Button,
  FormControlLabel,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import NumberFormatTextField from '../../../../../components/NumberFormatTextField/NumberFormatTextField';
import MySlider from '../../../../../components/MySlider/MySlider';
import PolicyInterestCalculatorModal from '../PolicyInterestCalculatorModal/PolicyInterestCalculatorModal';
import { getTargetPolicyInterest } from '../calculator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomInputSelect from '../../../../Analysis/partials/CustomInputSelect/CustomInputSelect';

const YEAR_OPTIONS = Array.from(Array(20).keys()).map(e => e + 1);

export default class InputPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { calculatorOpen: false };
  }

  handleYearsToShow = (event, index) => {
    const newYears = [...this.props.yearsToShow];
    newYears[index] = event.target.value || null;
    this.props.handleChange({
      target: {
        name: 'yearsToShow',
        value: newYears
      }
    });
  };

  handleInputCashValueAt = (event, index) => {
    const { cashValueAt } = this.props;
    const newCashValue = [...cashValueAt];
    newCashValue[index] = event.target.value;
    this.props.handleChange({
      target: {
        name: 'cashValueAt',
        value: newCashValue
      }
    });
  };

  render() {
    const { intl, principal, term, handleChange, yearsToShow, cashValueAt, isCurrencyEnabled } = this.props;
    const { calculatorOpen, loanRateExpanded, termAddOnExpanded } = this.state;
    return (
      <Card className="pfa-card pf-card-container pf-side-input-panel">
        <CardContent style={{ padding: 24 }}>
          <Grid container spacing={1}>
            {yearsToShow.map((y, i) => {
              if (i === 0) {
                return null;
              }

              const handleChangeCashValue = isCurrencyEnabled
                ? undefined
                : event => this.handleInputCashValueAt(event, i);

              return (
                <Grid container xs={12} key={i} justify="space-between" alignItems="center">
                  <Grid item xs={5} className="input-control">
                    <CustomInputSelect
                      disabled={isCurrencyEnabled}
                      label={intl.formatMessage({ id: 'pfa-term' })}
                      onChange={e => this.handleYearsToShow(e, i)}
                      value={yearsToShow[i] || ''}
                      selectOptions={YEAR_OPTIONS}
                      endAdornmentLabel={intl.formatMessage({ id: 'Yrs' })}
                      isDollarValue={true}
                      allowCustomValue={true}
                      min={1}
                      max={99}
                    />
                  </Grid>
                  <Grid xs={7} className="input-control cash-value-at">
                    <NumberFormatTextField
                      disabled={isCurrencyEnabled}
                      label={intl.formatMessage({ id: 'pfa-cash-value' })}
                      name="cashValueAt"
                      value={cashValueAt[i]}
                      onChange={handleChangeCashValue}
                      inputProps={{
                        allowNegative: false,
                        prefix: '$',
                        decimalScale: 0,
                        isAllowed: () => true
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>

        <PolicyInterestCalculatorModal
          intl={intl}
          open={calculatorOpen}
          principal={principal}
          term={term}
          onClose={() => {
            this.setState({ calculatorOpen: false });
          }}
          onSubmitTargetPolicyValue={targetPolicyValue => {
            handleChange({
              target: {
                name: 'policyInterest',
                value: getTargetPolicyInterest({ principal, targetPolicyValue, term })
              }
            });
            this.setState({ calculatorOpen: false });
          }}
        />
      </Card>
    );
  }
}
