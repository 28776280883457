// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './swiper-custom.scss';

export const initSwiperJS = () => {
  // install Swiper components
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
};
