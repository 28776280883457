import React from 'react';
import { IntlProvider } from 'react-intl';
import { getLocaleMessages } from '../utils/locale';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';

addLocaleData([...en, ...zh]);

export default class PreviewWrapper extends React.PureComponent {
  render() {
    const { children, locale } = this.props;

    return (
      <div className="pdf-template">
        <IntlProvider key={locale} locale={locale} defaultLocale={locale} messages={getLocaleMessages(locale).messages}>
          {children}
        </IntlProvider>
      </div>
    );
  }
}
