import React from 'react';
import { Fab, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router';
import { goToGPTOptions } from '../../../utils/openai';
import { tracking } from '../../../utils/tracking';

const ButtonStyle = {
  position: 'fixed',
  bottom: 72,
  zIndex: 10
};

const ImageStyle = {
  width: '100%'
};

const ChatGPTFab = props => {
  const { history, style, ...rest } = props;

  const isWide = useMediaQuery('@media screen and (min-width: 1302px)');

  const onClick = () => {
    tracking('Click on ChatGPT - Main Page');
    goToGPTOptions();
  };

  return (
    <Fab
      className="fab"
      size="medium"
      variant="round"
      onClick={onClick}
      style={{
        ...ButtonStyle,
        right: isWide ? 'calc((100% - 1180px - 50px) / 2 - 72px)' : 20,
        ...style
      }}
    >
      <img alt="chatgpt" src="img/gptmain.svg" style={ImageStyle} />
    </Fab>
  );
};

export default withRouter(ChatGPTFab);
