import { fetch } from '../../utils';
import qs from 'qs';
import Backend from '../../backend';

// API to create thank-you to adviser
export const createThankYouApi = () => {
  return fetch(Backend.methodUrl('thank-you'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to get thank-you
export const getThankYouApi = queryObj => {
  return fetch(Backend.methodUrl('thank-you') + `?${qs.stringify(queryObj)}`, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API to read thank-yous
export const readThankYousApi = () => {
  return fetch(Backend.methodUrl('thank-you/read'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
