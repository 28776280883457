import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, DialogActions, Button, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import './AdviserHelpDialog.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const AdviserHelpDialog = props => {
  const { className, open, intl, items, disableNext, doNotShowClose, customBack } = props;
  const [index, setIndex] = useState(0);
  const onClose = props.onClose
    ? () => {
      setIndex(0);
      props.onClose();
    }
    : undefined;
  const goBack = () => setIndex(0);

  const nextPage = () => setIndex(index + 1);

  return (
    <Dialog
      className={className}
      open={!!open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        style: { margin: 16, width: 'calc(100% - 32px)' }
      }}
    >
      {items[index].title && <DialogTitle>{intl.formatMessage({ id: items[index].title })}
        <IconButton style={{ position: 'absolute', right: 8, top: 8 }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>}
      <DialogContent>{items[index].component(setIndex)}</DialogContent>
      <DialogActions className="preference-actions">
        {items[index].customActions ? (
          items[index].customActions(setIndex)
        ) : (
          <Fragment>
            {/* {index === 0 && onClose && !doNotShowClose && (
              <>
                <div>
                  <Button onClick={onClose} color="primary">
                    {props.intl.formatMessage({ id: 'Close' })}
                  </Button>
                </div>
              </>
            )} */}
            {index > 0 && index < items.length - 1 && (
              <div className="actions-div" style={{ justifyContent: 'space-between' }}>
                <div>
                  <Button onClick={goBack} color="primary">
                    {props.intl.formatMessage({ id: 'joyride_back' })}
                  </Button>
                </div>
                <div>
                  {!!items[index].rightButton && items[index].rightButton()}
                  {!disableNext && (
                    <Button onClick={nextPage} color="primary">
                      {props.intl.formatMessage({ id: 'next-step' })}
                    </Button>
                  )}
                </div>
              </div>
            )}
            {index === items.length - 1 && (
              <>
                <div className="actions-div">
                  {items[index].customBack ? (
                    items[index].customBack
                  ) : (
                    <Button onClick={goBack} color="primary">
                      {props.intl.formatMessage({ id: 'joyride_back' })}
                    </Button>
                  )}
                </div>

                {/* {onClose && (
                  <div>
                    <Button onClick={onClose} color="primary">
                      {props.intl.formatMessage({ id: 'Close' })}
                    </Button>
                  </div>
                )} */}
              </>
            )}
          </Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AdviserHelpDialog;
