import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import './ImageWithTitleCardButton.scss';

const styles = {
  container: {
    paddingBottom: 50
  },
  main: {
    flex: 1,
    justifyContent: 'center'
  },
  imageDiv: {
    margin: 8
  },
  actions: { position: 'absolute', bottom: 0, width: '50%', paddingRight: 0, paddingLeft: 0 }
};

const ImageWithTitleCardButton = props => {
  const { index, title, description, imgSrc, onClick } = props;
  return (
    <Card className="image-title-card-btn" onClick={onClick}>
      <CardContent style={{ width: '100%', padding: '8px 8px 8px 0' }}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            <div style={styles.imageDiv}>
              <img src={imgSrc} alt="" />
            </div>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <div className="content">
              <Grid container alignItems="center">
                {index !== undefined && (
                  <Grid item>
                    <Avatar className="item-index">{index}</Avatar>
                  </Grid>
                )}
                <Grid item>
                  <Typography color="primary" style={{ fontSize: '17px' }}>
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Typography color="textSecondary" style={{ fontSize: '15px' }}>
                {description}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ImageWithTitleCardButton;
