import React from 'react';
import { RichUtils } from 'draft-js';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import { ALIGN_BLOCK_TYPES } from '../../constants';

class AlignButton extends React.PureComponent {
  render() {
    const { theme, onChange, editorState, type } = this.props;

    let icon;
    switch (type) {
      case ALIGN_BLOCK_TYPES.ALIGN_LEFT:
        icon = <FormatAlignLeftIcon />;
        break;
      case ALIGN_BLOCK_TYPES.ALIGN_RIGHT:
        icon = <FormatAlignRightIcon />;
        break;
      default:
        icon = <FormatAlignCenterIcon />;
        break;
    }

    const currentBlockType = RichUtils.getCurrentBlockType(editorState);

    return (
      <div className={theme.buttonWrapper} onMouseDown={event => event.preventDefault()}>
        <button
          type="button"
          className={`${theme.button} ${currentBlockType === type ? 'active' : ''}`}
          onClick={event => {
            event.stopPropagation();
            // let contentState = editorState.getCurrentContent();
            // const selection = editorState.getSelection();
            // contentState = Modifier.setBlockType(contentState, selection, currentBlockType === type ? undefined : type);
            onChange(RichUtils.toggleBlockType(editorState, type));
          }}
        >
          {icon}
        </button>
      </div>
    );
  }
}

export default AlignButton;
