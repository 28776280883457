import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import withRouter from 'react-router/withRouter';
import { getClientConnection, goToAddPortfolio } from '../utils';
import { SlideTransition } from '../../Transition/Transition';
import _ from 'lodash';

class AddPortfolioHintDialog extends React.PureComponent {
  close = () => {
    const onClose = _.get(this.props, 'addPortfolioHintDialog.onClose');
    if (onClose) {
      onClose();
    }
    this.props.toggleDialog('addPortfolioHint', { ...this.props.addPortfolioHintDialog, closed: true });
  };

  closed = () => this.props.toggleDialog('addPortfolioHint', false);

  ok = () => {
    const { history, userDetails, addPortfolioHintDialog, toggleDialog } = this.props;
    const { client, isReplace, extraLocationState } = addPortfolioHintDialog;
    toggleDialog('addPortfolioHint', { ...addPortfolioHintDialog, closed: true });
    goToAddPortfolio(client, history, userDetails, isReplace, extraLocationState);
  };

  render() {
    const { intl, addPortfolioHintDialog } = this.props;

    if (!addPortfolioHintDialog) return null;

    const { client, closed } = addPortfolioHintDialog;

    return (
      <Dialog
        open={
          !!addPortfolioHintDialog &&
          !(!client.inactiveUser && !client.passwordChangeKey && !getClientConnection(client)) &&
          !closed
        }
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'Add Policy' })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({
            id: "no-consent-warning"
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.ok} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    addPortfolioHintDialog: state.control.addPortfolioHintDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(withRouter(AddPortfolioHintDialog));

export default injectIntl(container);
