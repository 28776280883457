import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import _ from 'lodash';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const GET_FIGURE_ADVISER = 'portfoplus/adviserDashoard/GET_FIGURE_ADVISER ';
export const GET_FIGURE_ADVISER_SUCCESS = 'portfoplus/adviserDashoard/GET_FIGURE_ADVISER_SUCCESS ';
export const GET_FIGURE_ADVISER_ERROR = 'portfoplus/adviserDashoard/GET_FIGURE_ADVISER_ERROR ';

export const UPDATE_FIGURE_ADVISER = 'portfoplus/adviserDashoard/UPDATE_FIGURE_ADVISER ';
export const UPDATE_FIGURE_ADVISER_SUCCESS = 'portfoplus/adviserDashoard/UPDATE_FIGURE_ADVISER_SUCCESS ';
export const UPDATE_FIGURE_ADVISER_ERROR = 'portfoplus/adviserDashoard/UPDATE_FIGURE_ADVISER_ERROR ';

export const GET_ADVISER_DASHBOARD_DATA = 'portfoplus/adviserDashoard/GET_ADVISER_DASHBOARD_DATA ';
export const GET_ADVISER_DASHBOARD_DATA_SUCCESS = 'portfoplus/adviserDashoard/GET_ADVISER_DASHBOARD_DATA_SUCCESS';
export const GET_ADVISER_DASHBOARD_DATA_ERROR = 'portfoplus/adviserDashoard/GET_ADVISER_DASHBOARD_DATA_ERROR';

export const ADVISER_BUSINESS_ANALYSIS = 'portfoplus/adviserDashoard/ADVISER_BUSINESS_ANALYSIS ';
export const ADVISER_BUSINESS_ANALYSIS_SUCCESS = 'portfoplus/adviserDashoard/ADVISER_BUSINESS_ANALYSIS_SUCCESS';
export const ADVISER_BUSINESS_ANALYSIS_ERROR = 'portfoplus/adviserDashoard/ADVISER_BUSINESS_ANALYSIS_ERROR';

export const CONNECTED_USER_POLICIES = 'portfoplus/adviserDashoard/CONNECTED_USER_POLICIES';
export const CONNECTED_USER_POLICIES_SUCCESS = 'portfoplus/adviserDashoard/CONNECTED_USER_POLICIES_SUCCESS';
export const CONNECTED_USER_POLICIES_ERROR = 'portfoplus/adviserDashoard/CONNECTED_USER_POLICIES_ERROR';

export const POLICY_COUNT = 'portfoplus/adviserDashoard/POLICY_COUNT ';
export const POLICY_COUNT_SUCCESS = 'portfoplus/adviserDashoard/POLICY_COUNT_SUCCESS';
export const POLICY_COUNT_ERROR = 'portfoplus/adviserDashoard/POLICY_COUNT_ERROR';

export const GET_DUE_POLICY = 'portfoplus/adviserDashoard/GET_DUE_POLICY ';
export const GET_DUE_POLICY_SUCCESS = 'portfoplus/adviserDashoard/GET_DUE_POLICY_SUCCESS';
export const GET_DUE_POLICY_ERROR = 'portfoplus/adviserDashoard/GET_DUE_POLICY_ERROR';

export const UPDATE_ADVISER_BUSINESS = 'portfoplus/adviserDashoard/UPDATE_ADVISER_BUSINESS ';
export const UPDATE_ADVISER_BUSINESS_SUCCESS = 'portfoplus/adviserDashoard/UPDATE_ADVISER_BUSINESS_SUCCESS ';
export const UPDATE_ADVISER_BUSINESS_ERROR = 'portfoplus/adviserDashoard/UPDATE_ADVISER_BUSINESS_ERROR ';

export const GET_ADVISER_BUSINESS = 'portfoplus/adviserDashoard/GET_ADVISER_BUSINESS ';
export const GET_ADVISER_BUSINESS_SUCCESS = 'portfoplus/adviserDashoard/GET_ADVISER_BUSINESS_SUCCESS ';
export const GET_ADVISER_BUSINESS_ERROR = 'portfoplus/adviserDashoard/GET_ADVISER_BUSINESS_ERROR ';

export const GET_ADVISER_TEAM_DETAIL = 'portfoplus/adviserDashoard/GET_ADVISER_TEAM_DETAIL ';
export const GET_ADVISER_TEAM_DETAIL_SUCCESS = 'portfoplus/adviserDashoard/GET_ADVISER_TEAM_DETAIL_SUCCESS ';
export const GET_ADVISER_TEAM_DETAIL_ERROR = 'portfoplus/adviserDashoard/GET_ADVISER_TEAM_DETAIL_ERROR ';

export const UPDATE_ADVISER_TEAMS_DETAIL = 'portfoplus/adviserDashoard/UPDATE_ADVISER_TEAMS_DETAIL ';
export const UPDATE_ADVISER_TEAMS_DETAIL_SUCCESS = 'portfoplus/adviserDashoard/UPDATE_ADVISER_TEAMS_DETAIL_SUCCESS ';
export const UPDATE_ADVISER_TEAMS_DETAIL_ERROR = 'portfoplus/adviserDashoard/UPDATE_ADVISER_TEAMS_DETAIL_ERROR ';

export const UPDATE_PAID_UP_POLICT_CLIENTS = 'portfoplus/adviserDashboard/UPDATE_PAID_UP_POLICT_CLIENTS';

export const INIT_PHASE = 'portfoplus/adviserDashoard/INIT_PHASE';
export const SET_PHASE = 'portfoplus/adviserDashboard/SET_PHASE';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  //GetConnectedUsers: [],
  getUsersGender: {},
  getActiveReminder: [],
  getClientStatusData: {},
  GetConnectedUsersBirthday: undefined,
  PastBirthdayOfUsers: [],
  GetUsersPolicyDueData: undefined,
  getClientSavingILASPolicyData: [],
  getAdviserDashboardPhase: INIT,
  getFigureAdviserPhase: INIT,
  updateFigureAdviserPhase: INIT,
  updateQuarterTargetPhase: INIT,
  noPolicyPhase: INIT,
  policyDuePhase: INIT,
  paidUpPolicyPhase: INIT,
  adjustedBusinessVol: 0,
  activeTrackRecord: 0,
  totalBusinessVol: 0,
  opportunityCount: {},
  opportunityBizVol: {},
  figureAdviserData: {},
  adviserBusinessAnalysisPhase: INIT,
  getAdviserDashboardPhaseMessage: null,
  businessSummaryData: [],
  averageCoverageData: [],
  averagePremiumData: {},
  policiesData: [],
  policyCountData: {},
  error: null,
  isSubmitting: false,
  adviserBusiness: 0,
  policyDueData: [],
  paidUpPolicyClients: undefined,
  getAdviserTeamDetail: [],
  teamTrackingData: [],
  teamsTargetAndBiz: [],
  teamDetailPhase: INIT,
  updateTeamTargetPhase: INIT,
  updateMessagePhase: INIT,
  GetConnectedUsersDiscCount: {},
  clientTagUsage: [],
  topClientStatistics: {}
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_ADVISER_DASHBOARD_DATA: {
      return state
        .set('getAdviserDashboardPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_ADVISER_DASHBOARD_DATA_SUCCESS: {
      const { payload } = action;

      return (
        state
          .set('GetConnectedUsersBirthday', payload.GetConnectedUsersBirthday)
          .set('PastBirthdayOfUsers', payload.PastBirthdayOfUsers)
          // .set('GetConnectedUsers', payload.GetConnectedUsers)
          .set('getUsersGender', payload.getUsersGender)
          .set('getActiveReminder', _.get(payload, 'getActiveReminder', []))
          .set('getClientStatusData', payload.getClientStatusData)
          .set('averageCoverageData', payload.averageCoverageData)
          .set('averagePremiumData', payload.averagePremiumData.averagePremiumData)
          .set('getClientSavingILASPolicyData', payload.getClientSavingILASPolicyData)
          .set('getAdviserDashboardPhase', payload.success)
          .set('getAdviserDashboardPhaseMessage', payload.message)
          .set('GetConnectedUsersDiscCount', payload.GetConnectedUsersDiscCount)
          .set('clientTagUsage', payload.clientTagUsage)
          .set('topClientStatistics', payload.topClientStatistics)
          .set('error', null)
      );
    }

    case GET_ADVISER_DASHBOARD_DATA_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getAdviserDashboardPhase', ERROR);
    }

    case ADVISER_BUSINESS_ANALYSIS: {
      return state
        .set('adviserBusinessAnalysisPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case ADVISER_BUSINESS_ANALYSIS_SUCCESS: {
      const { payload } = action;
      return state
        .set('activeTrackRecord', payload.count)
        .set('adjustedBusinessVol', payload.adjustOpenBiz)
        .set('totalBusinessVol', payload.sumOfBussinessVolume)
        .set('opportunityCount', {
          highOpportunityCount: payload.highOpportunityCount,
          lowOpportunityCount: payload.lowOpportunityCount,
          mediumOpportunityCount: payload.mediumOpportunityCount,
          noneOpportunityCount: payload.noneOpportunityCount
        })
        .set('opportunityBizVol', {
          highOpportunityBizVol: payload.highOpportunityBizVol,
          mediumOpportunityBizVol: payload.mediumOpportunityBizVol,
          lowOpportunityBizVol: payload.lowOpportunityBizVol,
          noneOpportunityBizVol: payload.noneOpportunityBizVol
        })

        .set('adviserBusinessAnalysisPhase', payload.success)
        .set('error', null);
    }

    case ADVISER_BUSINESS_ANALYSIS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('adviserBusinessAnalysisPhase', ERROR);
    }

    case CONNECTED_USER_POLICIES: {
      return state
        .set('noPolicyPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case CONNECTED_USER_POLICIES_SUCCESS: {
      const { payload } = action;
      return state
        .set('noPolicyPhase', payload.success)
        .set('policiesData', payload.data)
        .set('error', null);
    }

    case CONNECTED_USER_POLICIES_ERROR: {
      const { error } = action.payload;
      return state.set('error', error);
    }

    case POLICY_COUNT: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case POLICY_COUNT_SUCCESS: {
      const { payload } = action;
      return state.set('policyCountData', payload.data).set('error', null);
    }

    case POLICY_COUNT_ERROR: {
      const { error } = action.payload;
      return state.set('error', error);
    }

    case GET_DUE_POLICY: {
      return state
        .set('error', null)
        .set('isSubmitting', true)
        .set('policyDuePhase', LOADING);
    }

    case GET_DUE_POLICY_SUCCESS: {
      const { payload } = action;
      return state
        .set('GetUsersPolicyDueData', payload.data)
        .set('policyDueData', payload.policyDueData)
        .set('policyDuePhase', true)
        .set('error', null);
    }

    case GET_DUE_POLICY_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('policyDuePhase', ERROR);
    }

    case GET_FIGURE_ADVISER: {
      return state
        .set('error', null)
        .set('updateFigureAdviserPhase', INIT)
        .set('getFigureAdviserPhase', LOADING);
    }

    case GET_FIGURE_ADVISER_SUCCESS: {
      const { payload } = action;
      return state
        .set('getFigureAdviserPhase', payload.success)
        .set('figureAdviserData', payload.data)
        .set('error', null);
    }

    case GET_FIGURE_ADVISER_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getFigureAdviserPhase', ERROR);
    }

    case UPDATE_FIGURE_ADVISER: {
      return state
        .set('updateFigureAdviserPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_FIGURE_ADVISER_SUCCESS: {
      const { payload } = action;
      return state
        .set('updateFigureAdviserPhase', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case UPDATE_FIGURE_ADVISER_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('updateFigureAdviserPhase', ERROR);
    }

    case UPDATE_ADVISER_BUSINESS: {
      return state
        .set('updateQuarterTargetPhase', LOADING)
        .set('updateMessagePhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_ADVISER_BUSINESS_SUCCESS: {
      const { payload } = action;
      return state
        .set('adviserBusiness', payload.data)
        .set('updateMessagePhase', payload.success)
        .set('updateQuarterTargetPhase', payload.success)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case UPDATE_ADVISER_BUSINESS_ERROR: {
      const { error } = action.payload;
      return state
        .set('error', error)
        .set('updateQuarterTargetPhase', ERROR)
        .set('updateMessagePhase', ERROR);
    }

    case GET_ADVISER_BUSINESS: {
      return state
        .set('error', null)
        .set('updateQuarterTargetPhase', INIT)
        .set('getFigureAdviserPhase', LOADING);
    }

    case GET_ADVISER_BUSINESS_SUCCESS: {
      const { payload } = action;
      return state
        .set('updateQuarterTargetPhase', payload.success)
        .set('getFigureAdviserPhase', payload.success)
        .set('adviserBusiness', payload.data)
        .set('error', null);
    }

    case GET_ADVISER_BUSINESS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('updateQuarterTargetPhase', ERROR);
    }

    case GET_ADVISER_TEAM_DETAIL: {
      return state
        .set('error', null)
        .set('teamDetailPhase', INIT)
        .set('getFigureAdviserPhase', LOADING);
    }

    case GET_ADVISER_TEAM_DETAIL_SUCCESS: {
      const { payload } = action;
      return state
        .set('teamDetailPhase', payload.success)
        .set('getAdviserTeamDetail', payload.data)
        .set('teamTrackingData', payload.teamTrackingData)
        .set('teamsTargetAndBiz', payload.teamsTargetAndBiz)
        .set('error', null);
    }

    case GET_ADVISER_TEAM_DETAIL_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('teamDetailPhase', ERROR);
    }

    case UPDATE_ADVISER_TEAMS_DETAIL: {
      return state
        .set('updateTeamTargetPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_ADVISER_TEAMS_DETAIL_SUCCESS: {
      const { payload } = action;
      return (
        state
          // .set('adviserBusiness', payload.data)
          .set('updateTeamTargetPhase', payload.success)
          .set('error', null)
          .set('isSubmitting', false)
      );
    }

    case UPDATE_ADVISER_TEAMS_DETAIL_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('updateTeamTargetPhase', ERROR);
    }

    case INIT_PHASE: {
      return state.set('updateTeamTargetPhase', INIT);
    }

    case SET_PHASE: {
      const { phaseName, phaseValue } = action;
      return state.set(`${phaseName}Phase`, phaseValue);
    }

    case UPDATE_PAID_UP_POLICT_CLIENTS: {
      const { payload } = action;
      return state.set('paidUpPolicyClients', payload.data).set('paidUpPolicyPhase', true);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getAdviserDashboard = () => ({
  type: GET_ADVISER_DASHBOARD_DATA
});
export const adviserBusinessAnalysis = () => ({
  type: ADVISER_BUSINESS_ANALYSIS
});

export const connectedUserPolicies = () => ({
  type: CONNECTED_USER_POLICIES
});

export const policyCount = () => ({
  type: POLICY_COUNT
});

export const getDuePolicy = () => ({
  type: GET_DUE_POLICY
});

export const getPaidUpPolicies = () => async (dispatch, getState) => {
  dispatch({ type: SET_PHASE, phaseName: 'paidUpPolicy', phaseValue: LOADING });
  try {
    const result = await api.getPaidUpPolicy();
    if (result.paidUpPolicies) {
      const formatted = result.paidUpPolicies.reduce((accumulator, policy) => {
        const clientIndex = accumulator.findIndex(entry => entry._id === policy.userId);
        if (clientIndex <= -1) {
          accumulator.push({ _id: policy.userId, paidUpPolicies: [policy] });
        } else {
          accumulator[clientIndex].paidUpPolicies.push(policy);
        }
        return accumulator;
      }, []);
      dispatch({ type: UPDATE_PAID_UP_POLICT_CLIENTS, payload: { data: formatted } });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SET_PHASE, phaseName: 'paidUpPolicy', phaseValue: ERROR });
  }
};

export const fetchFigureAdviser = () => ({
  type: GET_FIGURE_ADVISER
});

export const updateFigureAdviser = data => ({
  type: UPDATE_FIGURE_ADVISER,
  payload: { data }
});

export const updateAdviserBusiness = data => ({
  type: UPDATE_ADVISER_BUSINESS,
  payload: { data }
});

export const fetchAdviserBusiness = () => ({
  type: GET_ADVISER_BUSINESS
});

export const fetchAdviserTeamDetail = () => ({
  type: GET_ADVISER_TEAM_DETAIL
});

export const updateTeamsOfAdviser = data => ({
  type: UPDATE_ADVISER_TEAMS_DETAIL,
  payload: { data }
});

export const initPhase = () => ({
  type: INIT_PHASE
});

/***********************************
 * Epics
 ***********/

const getAdviserDashboardEpic = action$ =>
  action$.ofType(GET_ADVISER_DASHBOARD_DATA).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getAdviserDashboard())
      .map(payload => ({
        type: GET_ADVISER_DASHBOARD_DATA_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_ADVISER_DASHBOARD_DATA_ERROR,
          payload: { error }
        })
      );
  });

const adviserBusinessAnalysisEpic = action$ =>
  action$.ofType(ADVISER_BUSINESS_ANALYSIS).mergeMap(() => {
    return Rx.Observable.fromPromise(api.adviserBusinessAnalysis())
      .map(payload => ({
        type: ADVISER_BUSINESS_ANALYSIS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: ADVISER_BUSINESS_ANALYSIS_ERROR,
          payload: { error }
        })
      );
  });

const connectedUserPoliciesEpic = action$ =>
  action$.ofType(CONNECTED_USER_POLICIES).mergeMap(() => {
    return Rx.Observable.fromPromise(api.connectedUserPolicies())
      .map(payload => ({
        type: CONNECTED_USER_POLICIES_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: CONNECTED_USER_POLICIES_ERROR,
          payload: { error }
        })
      );
  });

const policyCountEpic = action$ =>
  action$.ofType(POLICY_COUNT).mergeMap(() => {
    return Rx.Observable.fromPromise(api.policyCount())
      .map(payload => ({
        type: POLICY_COUNT_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: POLICY_COUNT_ERROR,
          payload: { error }
        })
      );
  });

const getDuePolicyEpic = action$ =>
  action$.ofType(GET_DUE_POLICY).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getDuePolicy())
      .map(payload => ({
        type: GET_DUE_POLICY_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_DUE_POLICY_ERROR,
          payload: { error }
        })
      );
  });

const getFigureAdviserEpic = action$ =>
  action$.ofType(GET_FIGURE_ADVISER).mergeMap(() => {
    return Rx.Observable.fromPromise(api.fetchFigureAdviser())
      .map(payload => ({
        type: GET_FIGURE_ADVISER_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_FIGURE_ADVISER_ERROR,
          payload: { error }
        })
      );
  });
const updateFigureAdviserEpic = action$ =>
  action$.ofType(UPDATE_FIGURE_ADVISER).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateFigureAdviser(action.payload.data))
      .flatMap(payload => [
        {
          type: UPDATE_FIGURE_ADVISER_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_FIGURE_ADVISER_ERROR,
          payload: { error }
        })
      );
  });

const updateAdviserBusinessEpic = action$ =>
  action$.ofType(UPDATE_ADVISER_BUSINESS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateAdviserBusiness(action.payload.data))
      .flatMap(payload => [
        {
          type: UPDATE_ADVISER_BUSINESS_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_ADVISER_BUSINESS_ERROR,
          payload: { error }
        })
      );
  });

const fetchAdviserBusinessEpic = action$ =>
  action$.ofType(GET_ADVISER_BUSINESS).mergeMap(() => {
    return Rx.Observable.fromPromise(api.fetchAdviserBusiness())
      .map(payload => ({
        type: GET_ADVISER_BUSINESS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_ADVISER_BUSINESS_ERROR,
          payload: { error }
        })
      );
  });

const fetchAdviserTeamDetailEpic = action$ =>
  action$.ofType(GET_ADVISER_TEAM_DETAIL).mergeMap(() => {
    return Rx.Observable.fromPromise(api.fetchAdviserTeamDetail())
      .map(payload => ({
        type: GET_ADVISER_TEAM_DETAIL_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_ADVISER_TEAM_DETAIL_ERROR,
          payload: { error }
        })
      );
  });

const updateTeamsOfAdviserEpic = action$ =>
  action$.ofType(UPDATE_ADVISER_TEAMS_DETAIL).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateTeamsOfAdviser(action.payload.data))
      .flatMap(payload => [
        {
          type: UPDATE_ADVISER_TEAMS_DETAIL_SUCCESS,
          payload
        },
        {
          type: GET_ADVISER_TEAM_DETAIL
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_ADVISER_TEAMS_DETAIL_ERROR,
          payload: { error }
        })
      );
  });

export const adviserDashboardEpic = combineEpics(
  getAdviserDashboardEpic,
  adviserBusinessAnalysisEpic,
  connectedUserPoliciesEpic,
  policyCountEpic,
  getDuePolicyEpic,
  getFigureAdviserEpic,
  updateFigureAdviserEpic,
  updateAdviserBusinessEpic,
  fetchAdviserBusinessEpic,
  fetchAdviserTeamDetailEpic,
  updateTeamsOfAdviserEpic
);
