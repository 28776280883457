import React from 'react';
import { connect } from 'react-redux';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import { tracking } from '../../../utils/tracking';
import { toggleDialog } from '../../../store/control/duck';
import _ from 'lodash';
import { showShowTeamLibrary } from '../../../utils/user';

const Recruitment = props => {
  const { userDetails, teamCampaignCodes, wordpressPages, toggleDialog } = props;

  const shouldShowTeam =
    _.get(userDetails, 'teamLeaderOf.length', 0) > 0 || _.get(userDetails, 'companyLeaderOf.length', 0) > 0;
  const showTeamLibrary = showShowTeamLibrary({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

  const getItems = ({ intl, history }) => [
    {
      color: '#a4a4a4',
      imgSrc: 'img/new_user.png',
      imgAlt: 'team-recruitment',
      text: intl.formatMessage({ id: 'team-form' }),
      subtitle: intl.formatMessage({ id: 'team-recruitment-desc' }),
      onClick: () => {
        tracking('Click on Team & Recruit - Recruitment');
        history.push({ pathname: '/recruitment-form' });
      }
    },
    ...(wordpressPages || []).map(page => {
      const nameLocale = _.get(page.name, intl.locale, '');
      return {
        color: '#5fc3d2',
        imgSrc: page.iconImg,
        imgAlt: nameLocale,
        text: nameLocale,
        subtitle: intl.formatMessage({ id: 'team-page-desc' }),
        onClick: () => {
          const nameEn = _.get(page.name, 'en', '');
          tracking(`Click on ${nameEn} - Recruitment`);
          history.push({ pathname: '/frame', state: { url: page.link, title: nameLocale } });
        }
      };
    }),
    shouldShowTeam && {
      color: '#5fc3d2',
      imgSrc: 'img/promotion.png',
      imgAlt: 'team Blast',
      text: intl.formatMessage({ id: 'team-blast' }),
      subtitle: intl.formatMessage({ id: 'team-blast-subtitle' }),
      onClick: () => {
        tracking('Click on Team Blast - Recruitment');
        history.push({ pathname: '/team_blast' });
      }
    },
    {
      color: '#3999f8',
      imgSrc: 'img/recruitConcept.png',
      imgAlt: 'recruitment-tools',
      text: intl.formatMessage({ id: 'recruitment-tools' }),
      subtitle: intl.formatMessage({ id: 'recruitment-tools-desc' }),
      onClick: () => {
        tracking('Click on Recruitment Tools - Recruitment');
        toggleDialog('recruitment', {});
      }
    },
    showTeamLibrary && {
      color: '#3999f8',
      imgSrc: 'img/recruitConcept.png',
      imgAlt: 'recruitment-tools',
      text: intl.formatMessage({ id: 'team-library' }),
      subtitle: intl.formatMessage({ id: 'team-library-desc' }),
      onClick: () => {
        tracking('Click on Recruitment Tools - Team Library');
        history.push({ pathname: '/team_library' });
      }
    },
    {
      color: '#2260a5',
      imgSrc: 'img/new_user.png',
      imgAlt: 'recruitment-target',
      text: intl.formatMessage({ id: 'recruitment-target-title' }),
      subtitle: intl.formatMessage({ id: 'recruitment-target-subtitle' }),
      onClick: () => {
        window.localStorage.setItem('clientState', JSON.stringify('recruitment'));
        history.push({ pathname: '/client' });
      }
    },
    shouldShowTeam && {
      color: '#453bd1',
      imgSrc: 'img/team.png',
      imgAlt: 'team-performance',
      text: intl.formatMessage({ id: 'team-performance' }),
      subtitle: intl.formatMessage({ id: 'team-performance-desc' }),
      onClick: () => {
        tracking('Click on Team Performance - Recruitment');
        history.push('/advisor_team');
      }
    }
  ];

  return <FunctionCardsView className="recruitment" getItems={getItems} />;
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    teamCampaignCodes: state.functions.campaignCodes,
    wordpressPages: state.functions.wordpressPages
  }),
  {
    toggleDialog
  }
)(Recruitment);

export default container;
