import { TextField } from '@material-ui/core';
import NumberFormatInputWrapper from './NumberFormatInputWrapper';
import React from 'react';

const NumberFormatTextField = props => {
  const { inputProps, InputProps, InputLabelProps, value, ...rest } = props;

  const { shrink, ...InputLabelRest } = InputLabelProps || {};

  return (
    <TextField
      value={value}
      InputProps={{
        inputComponent: NumberFormatInputWrapper,
        inputProps: inputProps,
        ...InputProps
      }}
      InputLabelProps={{
        shrink: shrink !== undefined ? shrink : value || value === 0 ? true : undefined,
        ...InputLabelRest
      }}
      {...rest}
    />
  );
};

export default NumberFormatTextField;
