import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input
} from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import { getClientConnection, isInactiveEmail } from '../utils';
import { isEmailValid } from '../../../utils/email';
import { changeInactiveUserEmail } from '../../../store/client/duck';
import _ from 'lodash';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';
import { usePrevious } from '../../../utils/hooks';

const ConnectDialog = props => {
  const {
    intl,
    connectDialog,
    clientDetails,
    updateEmailPhase,
    clientMessage,
    mergeDialog,
    toggleDialog,
    changeInactiveUserEmail
  } = props;

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const prevUpdateEmailPhase = usePrevious(updateEmailPhase);

  const { client, editingEmail, closed } = connectDialog || {};
  const { consent } = client || {};
  const connection = getClientConnection(client);
  const canChangeEmail = !connection && !consent;
  const inactiveEmail = isInactiveEmail(client);

  useEffect(() => {
    if (connectDialog) {
      if (updateEmailPhase === true && prevUpdateEmailPhase !== true) {
        const newClient = _.merge(
          { ...connectDialog.client },
          { email: clientDetails.email, 'factsheetId.email': clientDetails.email }
        );
        toggleDialog('connect', { ...connectDialog, client: newClient, editingEmail: clientDetails.email });
        mergeDialog('connection', { client: newClient });
      }
    }
  }, [
    intl,
    connectDialog,
    clientDetails,
    prevUpdateEmailPhase,
    updateEmailPhase,
    clientMessage,
    toggleDialog,
    mergeDialog
  ]);

  const getConnected = async () => {
    const errs = {};
    if (!editingEmail) {
      errs.email = 'Email is required';
    } else if (!isEmailValid(editingEmail)) {
      errs.email = 'Invalid email';
    }

    setErrors(errs);

    if (Object.keys(errs).length === 0) {
      if (isInactiveEmail(client) && editingEmail) {
        setLoading(true);
        const result = await changeInactiveUserEmail({ clientId: client._id, email: editingEmail });
        if (!result.success) {
          toast.info(IconToast('error', intl.formatMessage({ id: result.message })), { className: 'new-toast' });
          setLoading(false);
          return;
        }
      }

      toggleDialog('connectConfirm', connectDialog);
    }
  };

  const close = () => toggleDialog('connect', { ...connectDialog, closed: true });

  const onExited = () => {
    toggleDialog('connect', false);
    setErrors({});
    setLoading(false);
  };

  const changeEmail = event => {
    mergeDialog('connect', { editingEmail: event.target.value });
  };

  const editEmail = () => {
    toggleDialog('changeEmail', { ...connectDialog });
  };

  return !client ? null : (
    <Dialog
      open={!!connectDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'Connect with client' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'Email_sent' })}</DialogContentText>
        <div>
          <FormControl id="msform" className="share-to-modal-form-control">
            <Input
              className="share-to-modal-input"
              id="clientEmail"
              type="text"
              name="clientEmail"
              value={editingEmail}
              required={true}
              disabled={true}
              style={{ color: 'black' }}
              placeholder={intl.formatMessage({ id: 'Email-address-hints' })}
              onChange={changeEmail}
            />

            {errors && errors.email && <span className="error_field">{intl.formatMessage({ id: errors.email })}</span>}

            {canChangeEmail && (
              <div>
                <Button
                  className="action-change-button"
                  style={{ top: '-15px' }}
                  variant="contained"
                  color="primary"
                  onClick={editEmail}
                >
                  {intl.formatMessage({ id: !inactiveEmail ? 'Change' : 'Add' })}
                </Button>
              </div>
            )}
          </FormControl>
        </div>
        <div className="email-confirm-hint">
          {intl.formatMessage({
            id: '(Please double check the Email address)'
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={getConnected} disabled={loading} color="primary" autoFocus>
          {intl.formatMessage({ id: 'Submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  // Map state to props
  state => ({
    connectDialog: state.control.connectDialog,
    clientMessage: state.client.clientMessage,
    userDetails: state.user.userDetails,
    updateEmailPhase: state.client.updateEmailPhase,
    clientDetails: state.client.clientDetails
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    changeInactiveUserEmail
  },
  // mergeProps
  null,
  // options
  {}
)(ConnectDialog);

export default injectIntl(container);
