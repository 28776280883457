import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import Modal from 'react-modal';
import FeedBackModal from '../../../components/FeedbackModal/FeedBackModal';
import _ from 'lodash';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer.js';
import Chat from "../../Message/partials/component";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '50vh',
    overflowY: 'scroll',
    maxWidth: '90%'
  }
};

class MessageView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      feedbackModal: false,
      chatModal: false
    };
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    this.props.renderHeaderButton(true);
  }
  UNSAFE_componentWillMount() {
    this.props.fetchAdviserBusiness();
    // this.props.getClient();
  }

  openModal(val) {
    if (val === 'feedbackModal') {
      this.setState({ feedbackModal: true });
    } else {
      this.setState({ chatModal: true });
    }
  }

  closeModal(val) {
    if (val === 'feedbackModal') {
      this.setState({ feedbackModal: false });
    } else {
      this.setState({ chatModal: false });
    }
  }

  selectClient(val) {
    this.props.selectClient(val);
    this.props.history.push({
      state: val,
      pathname: '/adviser_chat'
    });
  }

  render() {
    const { location, clientData, intl, adviserBusiness } = this.props;
    const currentLocation = location.pathname;
    var user = this.props.userDetails;
    let productionEnv = process.env.NODE_ENV === 'production' ? true : false;
    return (
      <AppContentContainer>
        <div>
          {currentLocation === '/adviser_chat' ? (
            <Chat {...this.props} />
          ) : (
            <div className="row">
              <div className="col-lg-12 messagePadding">
                <ul className="card-list">
                  {!productionEnv && (
                    <li>
                      <Link to="/adviser_blast">
                        <div className="portfolioCard1">
                          <img src="img/promotion.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'adviser-blast' })}</h4>
                        </div>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/default_whatup_message">
                      <div className="portfolioCard1">
                        <img src="img/defaultmessage.png" alt="" />
                        <h4>{intl.formatMessage({ id: 'Default Message' })}</h4>
                      </div>
                    </Link>
                  </li>
                  <li onClick={this.openModal.bind(this, 'feedbackModal')}>
                    <div className="portfolioCard1">
                      <img src="img/feedback.png" alt="" />
                      <h4>{intl.formatMessage({ id: 'Feedback' })}</h4>
                    </div>
                  </li>
                  {!productionEnv && (
                    <li>
                      <Link to="/discussion_adviser">
                        <div className="portfolioCard1">
                          <img src="img/add_chat.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'Discussion' })}</h4>
                        </div>
                      </Link>
                    </li>
                  )}
                  {!productionEnv && (
                    <li style={{ marginBottom: 80 }}>
                      <Link to="/adviser_newsFeed">
                        <div className="portfolioCard1">
                          <img src="img/recommendation.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'News Feed' })}</h4>
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
                <Modal
                  isOpen={this.state.chatModal}
                  onRequestClose={this.closeModal.bind(this, 'chatModal')}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="chat-modal">
                    <p>
                      {intl.formatMessage({
                        id: 'Select the client you want to chat with'
                      })}
                    </p>
                    <ul>
                      {clientData && clientData.length > 0
                        ? clientData.map((val, index) => {
                            if (val.advisorDetails && val.advisorDetails.length > 0) {
                              // eslint-disable-next-line
                              val.advisorDetails.map(value => {
                                if (value.email === user.email && !value.connection) {
                                  // eslint-disable-next-line
                                  return;
                                }
                              });
                            }
                            var defaultMessage = _.get(adviserBusiness, 'defaultMessage', undefined);
                            let whatupAppMessage =
                              defaultMessage !== undefined && defaultMessage !== null && defaultMessage !== ''
                                ? defaultMessage
                                : intl.formatMessage({
                                    id: 'Hi, we connected in PortfoPlus, I would like to message you.'
                                  });
                            var contactNo =
                              val.factsheetId && val.factsheetId.whatsapp ? val.factsheetId.whatsapp : val.whatsapp;
                            let phoneNoText = contactNo ? 'phone=' + contactNo.replace('+', '') : '';
                            let url = 'https://api.whatsapp.com/send?' + phoneNoText + '&text=' + whatupAppMessage;
                            return (
                              <a rel="noopener noreferrer" key={index} href={url} target="_blank">
                                <li>
                                  <div className="avatar-chat">
                                    <div id="profileImageMsg">{val.name && val.name.length > 0 ? val.name[0] : ''}</div>
                                  </div>
                                  <span id="firstNameAdv">{val.name}</span>
                                </li>
                              </a>
                            );
                          })
                        : ''}
                    </ul>
                  </div>
                </Modal>

                <FeedBackModal
                  isOpen={this.state.feedbackModal}
                  onRequestClose={() => this.setState({ feedbackModal: false })}
                />
              </div>
            </div>
          )}
        </div>
      </AppContentContainer>
    );
  }
}

export default MessageView;
