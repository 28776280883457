import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import DeleteIconButton from '../../../../components/DeleteIconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { SlideTransition } from '../../../../components/Transition/Transition';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer.js';
import { tracking } from '../../../../utils/tracking';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { sanitize } from 'dompurify';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import { LOADING } from '../../../../constants/phase';

class FactsheetDetailsFormThree extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      MPF: '',
      insurance: '',
      investment: '',
      budget: '',
      interest: '',
      factsheetRemark: '',
      err: {},
      modalIsOpen: false,
      editClientData: false,
      editFactsheet: false,
      askToDisableTracking: false,
      menu: true,
      startDate: moment(),
      startDateChange: false,
      opportunity: '',
      trackOnModal: false,
      turnOnrackModal: false,
      expeactedBusinessAmount: ''
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleTrackingTurnOn = this.handleTrackingTurnOn.bind(this);
    this.confirmEnableTracking = this.confirmEnableTracking.bind(this);
    this.closeTurnOnTrackModal = this.closeTurnOnTrackModal.bind(this);
    this.cancelTrackOn = this.cancelTrackOn.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { createClientPhase } = this.props;
  //   if (createClientPhase === true && prevProps.createClientPhase === LOADING) {
  //     this.done();
  //   }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deleteClientPhase !== true && nextProps.deleteClientPhase === true) {
      this.done();
    }
  }

  UNSAFE_componentWillMount() {
    let { userDetails } = this.props;
    var clientFactsheetDataThree = JSON.parse(localStorage.getItem('clientFactsheetDataThree'));
    var clientData = this.props.clientDetails;
    let clientPersonalDetails = this.props.clientPersonalDetails;
    if (clientData && !this.isEmpty(clientData)) {
      this.setState({ editClientData: true });
    }

    if (clientFactsheetDataThree != null) {
      this.setState({
        MPF: clientFactsheetDataThree.MPF ? clientFactsheetDataThree.MPF : '',
        insurance: clientFactsheetDataThree.insurance ? clientFactsheetDataThree.insurance : '',
        investment: clientFactsheetDataThree.investment ? clientFactsheetDataThree.investment : '',
        budget: clientFactsheetDataThree.budget ? clientFactsheetDataThree.budget : '',
        interest: clientFactsheetDataThree.interest ? clientFactsheetDataThree.interest : '',
        factsheetRemark: clientFactsheetDataThree.factsheetRemark ? clientFactsheetDataThree.factsheetRemark : ''
      });
    } else if (clientData) {
      this.setState({
        MPF: clientData.MPF ? clientData.MPF : '',
        insurance: clientData.insurance ? clientData.insurance : '',
        investment: clientData.investment ? clientData.investment : '',
        budget: clientData.budget ? clientData.budget : '',
        interest: clientData.interest ? clientData.interest : '',
        factsheetRemark: clientData.factsheetRemark ? clientData.factsheetRemark : ''
      });
    }
    let val = clientPersonalDetails;
    const isInactive = val.inactiveUser !== undefined && val.inactiveUser === true ? true : false;
    if (isInactive && val.trackingId && val.trackingId.isActive) {
      this.setState({ askToDisableTracking: true });
    } else {
      if (val.advisorDetails && val.advisorDetails.length > 0) {
        val.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            this.setState({ askToDisableTracking: true });
          }
          return true;
        });
      }
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  submitForm() {
    const { handleSubmit } = this.props;
    var formData = {};
    var clientId = JSON.parse(localStorage.getItem('clientId'));
    formData.MPF = this.state.MPF;
    formData.insurance = this.state.insurance;
    formData.investment = this.state.investment;
    formData.budget = this.state.budget;
    formData.interest = this.state.interest;
    formData.factsheetRemark = this.state.factsheetRemark;
    if (clientId === null || clientId === undefined || clientId === '') {
      this.setState({ formData });
      localStorage.setItem('clientFactsheetDataThree', JSON.stringify(formData));
      localStorage.setItem('enableTracking', false);
      handleSubmit();
    } else {
      localStorage.setItem('clientFactsheetDataThree', JSON.stringify(formData));
      handleSubmit();
    }
  }

  handlePrevious() {
    var formData = {};
    formData.MPF = this.state.MPF;
    formData.insurance = this.state.insurance;
    formData.investment = this.state.investment;
    formData.budget = this.state.budget;
    formData.interest = this.state.interest;
    formData.factsheetRemark = this.state.factsheetRemark;
    localStorage.setItem('clientFactsheetDataThree', JSON.stringify(formData));
    this.props.handlePreviousPage(4);
  }

  openModal() {
    this.setState({ modalIsOpen: true, menu: false });
  }

  deleteClient() {
    if (this.state.askToDisableTracking) {
      const { clientPersonalDetails, enableTracking, userDetails } = this.props;
      let obj = {};
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.trackingId = _.get(value, 'trackingId._id', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        obj.trackingId = _.get(clientPersonalDetails, 'trackingId._id', '');
      }

      obj.clientId = _.get(clientPersonalDetails, '_id', '');
      obj.trackingType = 'delete';
      enableTracking(obj);
    }
    var clientId = JSON.parse(localStorage.getItem('clientId'));
    let factsheet = this.props.clientDetails;
    let data = {};
    data.clientId = clientId;
    data.factsheetId = factsheet._id;
    this.props.deleteClient(data);
    this.setState({ modalIsOpen: false, menu: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      menu: true,
      trackOnModal: false,
      expeactedBusinessAmount: '',
      startDate: moment(),
      opportunity: ''
    });
    localStorage.removeItem('trackingObj');
    localStorage.removeItem('enableTracking');
  }

  //To save the opportunity when on traking while creating client
  changeOpportunityOnTracking(val) {
    this.setState({ opportunity: val });
  }

  //To trun on tracking modal
  handleTrackingTurnOn() {
    this.setState({ trackOnModal: true, turnOnrackModal: false });
  }

  //Handle the  date of tracking start date
  handleTrackingStartDate(date) {
    /*remove the old logic to add 12 hours*/
    // var startDate = moment(date)
    //   .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    //   .add(12, 'hours');
    //delete this.state.err.bizStartDate;
    this.setState({
      startDate: date,
      startDateChange: true
    });
  }

  //to enable the tracking
  confirmEnableTracking() {
    let { handleSubmit } = this.props;
    let { startDate, opportunity, expeactedBusinessAmount } = this.state;
    //if (startDate) {
    let startDate_format = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    let startDate1 = startDate_format ? moment.utc(startDate_format).format('YYYY-MM-DDT00:00:00.00Z') : '';
    let trackingObj = {};
    // obj.clientId = receiverType._id;
    // obj.factsheetId = receiverType.factsheetId._id;
    //obj.advisorId = userDetails._id;

    trackingObj.met = 0;
    const err = {};
    if (expeactedBusinessAmount === '') {
      err.expeactedBusinessAmount = 'Please fill in';
    }
    this.setState({ err });
    //Added new filed on modal and also insert them on create new tracking
    if (expeactedBusinessAmount) {
      trackingObj.businessVol = expeactedBusinessAmount;
    }
    if (opportunity) {
      trackingObj.opportunity = opportunity;
      if (opportunity === 'H') {
        let date_high = moment().format('YYYY-MM-DD');
        trackingObj.dateHigh = moment.utc(date_high).format('YYYY-MM-DDT00:00:00.00Z');
        trackingObj.dayCount = moment().diff(moment(startDate1, 'YYYY-MM-DD'), 'days');
      } else {
        trackingObj.dayCount = 0;
      }
    } else {
      trackingObj.dayCount = 0;
    }

    //obj.startDate = moment.utc(startDate);
    trackingObj.startDate = startDate1;
    trackingObj.isActive = true;
    trackingObj.connectionStatus = 'Inactive';
    if (!Object.keys(err).length) {
      this.setState({ trackingObj, trackOnModal: false });
      var enableTracking = true;
      // formData.formData = this.state.formData
      // formData.trackingData = trackingObj
      localStorage.setItem('enableTracking', enableTracking);
      localStorage.setItem('trackingObj', JSON.stringify(trackingObj));
      handleSubmit();

      // Tracking
      tracking('Enable Business Tracking After Client Added');
    }
  }

  //To close and add create the client
  closeTurnOnTrackModal() {
    const { handleSubmit } = this.props;
    var enableTracking = false;
    localStorage.setItem('enableTracking', enableTracking);
    this.setState({ turnOnrackModal: false });
    handleSubmit();
  }

  //Cancel track on create client after cancel
  cancelTrackOn() {
    const { handleSubmit } = this.props;
    var enableTracking = false;
    localStorage.setItem('enableTracking', enableTracking);
    this.setState({ turnOnrackModal: false });
    handleSubmit();
  }

  done = () => {
    const { history, location } = this.props;
    const replaceRoute = _.get(location, 'state.replaceRoute');
    if (replaceRoute) {
      history.replace(replaceRoute);
    } else {
      history.goBack();
    }
  };

  onGoBack = () => {
    const { history, toggleDialog } = this.props;
    toggleDialog('discardWarning', {
      onConfirm: () => {
        sessionStorage.removeItem('referralRecordId');
        history.goBack();
      }
    });
  };

  render() {
    const { intl, clientPersonalDetails, enableTrackingPhase } = this.props;
    const { startDateChange, startDate } = this.state;
    const isInactive =
      clientPersonalDetails.inactiveUser !== undefined && clientPersonalDetails.inactiveUser === true ? true : false;
    let DeleteMessage = !isInactive
      ? // ? 'Deleting the user would remove all linkage with the users and data can’t be <br/> recovered. Are You Sure?'
        // : 'Deleting inactive user would remove all the user data inputted. ';
        intl.formatMessage({
          id: 'delete_all_data'
        })
      : intl.formatMessage({ id: 'delete_active_user_message' });
    return (
      <AppContentContainer>
        <div>
          <div>{this.props.createClientPhase === 'loading' && <LoadingBackdrop open={true} />}</div>
          <TopNavigationPortal>
            <span onClick={this.onGoBack} className="back_arrow">
              <ArrowBackIcon />
            </span>
            {(this.state.editFactsheet || this.state.editClientData) && (
              <DeleteIconButton className="edit__pen" handleClick={this.openModal.bind(this)} />
            )}
          </TopNavigationPortal>
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="msform" noValidate={true}>
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                        <li className="active" />
                        <li className="active" />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Factsheet' })}</h2>
                        <div className="form-group">
                          <input
                            type="text"
                            name="MPF"
                            value={this.state.MPF}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'MPF' })}
                          </label>
                          <i className="bar" />
                        </div>
                        <div className="form-group">
                          <textarea
                            type="text"
                            name="insurance"
                            value={this.state.insurance}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                            style={{ marginTop: '8px' }}
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Insurance' })}
                          </label>
                          <i className="bar" />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            name="investment"
                            value={this.state.investment}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Investment' })}
                          </label>
                          <i className="bar" />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            name="budget"
                            value={this.state.budget}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Budget' })}
                          </label>
                          <i className="bar" />
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            name="interest"
                            value={this.state.interest}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Interest' })}
                          </label>
                          <i className="bar" />
                        </div>

                        <div className="form-group">
                          <textarea
                            type="text"
                            name="factsheetRemark"
                            value={this.state.factsheetRemark}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                            style={{ marginTop: '8px' }}
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Remark' })}
                          </label>
                          <i className="bar" />
                        </div>

                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              className="prevNextButton"
                              startIcon={<ArrowBackIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              className="submitButton"
                            >
                              {intl.formatMessage({ id: 'Submit' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                    <Dialog
                      open={this.state.modalIsOpen}
                      onClose={this.closeModal}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      TransitionComponent={SlideTransition}
                      fullWidth={true}
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {intl.formatMessage({ id: 'Delete User' })}
                      </DialogTitle>
                      <DialogContent>
                        <div dangerouslySetInnerHTML={{ __html: sanitize(DeleteMessage) }} />

                        {this.state.askToDisableTracking && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitize(intl.formatMessage({ id: 'delete_the_tracking' }))
                            }}
                          />
                        )}
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                          {intl.formatMessage({ id: 'Cancel' })}
                        </Button>
                        <Button onClick={this.deleteClient.bind(this)} color="primary" autoFocus>
                          {intl.formatMessage({ id: 'OK' })}
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={this.state.trackOnModal}
                      onClose={this.closeModal}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      TransitionComponent={SlideTransition}
                      fullWidth={true}
                      className="modalwidth__mobileview"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {intl.formatMessage({ id: 'BusinessTracking' })}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>{intl.formatMessage({ id: 'bizTrackingDesc' })}</DialogContentText>
                        <div>
                          <div className="row">
                            <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                              {intl.formatMessage({ id: 'Tracking start on' })}
                            </div>
                            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                              <DatePicker
                                className="case-datepicker close-case-date case-open-datepicker"
                                maxDate={new Date()}
                                name="startDate"
                                format="DD-MMM-YYYY"
                                value={startDateChange ? startDate : moment(startDate)}
                                onChange={date => this.handleTrackingStartDate(date)}
                                autoOk={true}
                                invalidDateMessage={null}
                                minDateMessage={null}
                                maxDateMessage={null}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                              {intl.formatMessage({ id: 'Opportunity' })}
                            </div>
                            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                              <div className="clientList">
                                <Button
                                  className="portfolio-default-button"
                                  style={{
                                    background: this.state.opportunity === 'L' ? 'rgb(255, 106, 116)' : '#cccccc',
                                    color: this.state.opportunity === 'L' ? '#ffffff' : '#000'
                                  }}
                                  variant="contained"
                                  onClick={this.changeOpportunityOnTracking.bind(
                                    this,
                                    this.state.opportunity === 'L' ? '' : 'L'
                                  )}
                                >
                                  {intl.formatMessage({
                                    id: 'oppL'
                                  })}
                                </Button>
                                <Button
                                  className="portfolio-default-button"
                                  style={{
                                    background: this.state.opportunity === 'M' ? 'rgb(255, 175, 25)' : '#cccccc',
                                    color: this.state.opportunity === 'M' ? '#ffffff' : '#000'
                                  }}
                                  variant="contained"
                                  onClick={this.changeOpportunityOnTracking.bind(
                                    this,
                                    this.state.opportunity === 'M' ? '' : 'M'
                                  )}
                                >
                                  {intl.formatMessage({
                                    id: 'oppM'
                                  })}
                                </Button>
                                <Button
                                  className="portfolio-default-button"
                                  style={{
                                    background: this.state.opportunity === 'H' ? 'rgb(94, 224, 146)' : '#cccccc',
                                    color: this.state.opportunity === 'H' ? '#ffffff' : '#000'
                                  }}
                                  variant="contained"
                                  onClick={this.changeOpportunityOnTracking.bind(
                                    this,
                                    this.state.opportunity === 'H' ? '' : 'H'
                                  )}
                                >
                                  {intl.formatMessage({
                                    id: 'oppH'
                                  })}
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-xs-6 text-right fontWeight--none">
                              {intl.formatMessage({ id: 'Expected Business' })}
                            </div>
                            <div className="col-md-6 col-xs-6 text-left adv_biz_modal_key">
                              <NumberFormat
                                className="form-control"
                                style={{
                                  width: 111,
                                  borderBottom: '1px solid gray',
                                  height: 25,
                                  color: '#3966f8'
                                }}
                                name="businessVol"
                                value={this.state.expeactedBusinessAmount}
                                thousandSeparator={true}
                                onValueChange={values => {
                                  delete this.state.err.expeactedBusinessAmount;
                                  const { value } = values;
                                  this.setState({ expeactedBusinessAmount: value });
                                }}
                                type="tel"
                                //id="numberFormat"
                                // decimalSeparator=","
                                // thousandSeparator="."
                                inputMode="decimal"
                                //pattern="[0-9]*"
                              />
                              <i className="bar bar-edit" />
                              {this.state.err.expeactedBusinessAmount ? (
                                <span className="error_field" style={{ fontSize: '13px' }}>
                                  {intl.formatMessage({
                                    id: this.state.err.expeactedBusinessAmount
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={this.cancelTrackOn} color="primary">
                          {intl.formatMessage({ id: 'Cancel' })}
                        </Button>
                        <Button
                          onClick={this.confirmEnableTracking}
                          color="primary"
                          disabled={enableTrackingPhase === 'loading' ? true : false}
                          autoFocus
                        >
                          {intl.formatMessage({ id: 'OK' })}
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={this.state.turnOnrackModal}
                      onClose={this.closeTurnOnTrackModal}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      TransitionComponent={SlideTransition}
                      fullWidth={true}
                    >
                      <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Track On' })}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>{intl.formatMessage({ id: 'addClientOnTracking' })}</DialogContentText>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(intl.formatMessage({ id: 'TurnOnTracking' }))
                          }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeTurnOnTrackModal} color="primary">
                          {intl.formatMessage({ id: 'No-need' })}
                        </Button>
                        <Button onClick={this.handleTrackingTurnOn} color="primary" autoFocus>
                          {intl.formatMessage({ id: 'Turn-it-on' })}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

export default FactsheetDetailsFormThree;
