import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Lottie from '../Lottie/Lottie';
import _ from 'lodash';

const TypoStyle = {
  fontWeight: 'bold',
  fontSize: '1.5em'
};

const LottieContainerStyle = {
  position: 'relative',
  width: 24,
  height: 24
};

const LottieStyle = {
  width: 54,
  height: 54,
  position: 'absolute',
  top: -22,
  left: -16
};

const LikeCount = props => {
  const { likes, TypoProps, LottieProps, style, ...rest } = props;

  const typoStyle = _.get(TypoProps, 'style');
  const lottieStyle = _.get(LottieProps, 'style');
  const formattedLottieProps = _.omit(LottieProps, ['style']);

  return (
    <Grid container alignItems="center" justify="center" spacing={1} style={style} {...rest}>
      <Grid item>
        <Typography color="primary" style={{ ...TypoStyle, ...typoStyle }}>
          {likes || 0}
        </Typography>
      </Grid>
      <Grid item>
        <div style={LottieContainerStyle}>
          <Lottie
            style={{ ...LottieStyle, ...lottieStyle }}
            options={{
              animationData: 'animations/likeanimatin.json',
              loop: false,
              autoplay: false
            }}
            {...formattedLottieProps}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default LikeCount;
