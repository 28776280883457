import React, { useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { InputAdornment, MenuItem, Zoom } from '@material-ui/core';
import { decimalToString, parseFormattedNumber } from '../../../../utils/formatNumber';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import { usePrevious } from '../../../../utils/hooks';
import NumberFormat from 'react-number-format';
import TempTextField from '../../../../components/TempTextField/TempTextField';
import clsx from 'clsx';

const NumberFormatWrapper = props => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      decimalSeparator="."
      thousandSeparator=","
      inputMode="decimal"
      pattern="[0-9],*"
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value !== '' ? parseFloat(values.value) : undefined
          }
        });
      }}
    />
  );
};

const CustomInputSelect = props => {
  const {
    intl,
    label,
    name,
    onChange,
    value,
    startAdornmentLabel,
    endAdornmentLabel,
    selectOptions,
    className,
    inline,
    isDollarValue,
    decimalPlaces,
    allowCustomValue,
    useClass,
    max,
    min,
    decimalScale,
    nonZeroInteger,
    ...rest
  } = props;

  const [internalValue, setInternalValue] = useState(value);
  const [isCustom, setIsCustom] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputWidth, setInputWidth] = useState(undefined);
  const prevIsCustom = usePrevious(isCustom);
  const inputRef = useRef(null);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const formattedOnChange = event => {
    if (isCustom) {
      // let trimmed = _.replace(event.target.value, /,/g, '');
      //
      // if (max || min) {
      //   const numberValue = parseFloat(trimmed);
      //   if (max && numberValue > max) {
      //     trimmed = max.toString();
      //   }
      //
      //   if (min && numberValue < min) {
      //     trimmed = min.toString();
      //   }
      // }
      //
      // const finalValue = formatDisplayValue(!trimmed ? 0 : trimmed);
      setInternalValue(event.target.value);
    } else {
      if (allowCustomValue) {
        if (event.target.value === 'custom') {
          if (inputRef && inputRef.current) {
            const width = _.get(inputRef.current, 'node.parentElement.offsetWidth', undefined);
            setInputWidth(width ? width + 16 : width);
          }
          setIsCustom(true);
          return;
        }
      }

      if (onChange) {
        onChange(event);
      }
    }
  };

  const onBlur = event => {
    const finalValue = isCustom ? parseFormattedNumber(event.target.value) : event.target.value;

    if (nonZeroInteger && !event.target.value) {
      setInternalValue(value);
      return;
    }
    setInternalValue(event.target.value || 0);
    if (onChange) {
      onChange({ target: { value: finalValue || 0, name: name } });
    }
  };

  const turnOffIsCustom = () => setIsCustom(false);

  useEffect(() => {
    if (isCustom === true) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      if (prevIsCustom) {
        setOpen(true);
      }
    }
  }, [isCustom, prevIsCustom]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <TempTextField
      className={clsx('custom-input-select', className, inline && 'inline')}
      variant="standard"
      select={!isCustom}
      label={label}
      name={name}
      onChange={formattedOnChange}
      value={isCustom ? internalValue : value}
      inputRef={inputRef}
      InputProps={{
        inputComponent: NumberFormatWrapper,
        inputProps: {
          name: name,
          getInputRef: inputRef,
          allowNegative: false,
          decimalScale: nonZeroInteger ? 0 : decimalPlaces ?? 1,
          isAllowed: values => {
            const { formattedValue, value } = values;
            return formattedValue === '' || ((!max || value <= max) && (!min || value >= min));
          },
          onBlur: onBlur,
          style: {
            width: isCustom && inline ? inputWidth : undefined
          }
        },
        startAdornment:
          startAdornmentLabel !== undefined ? (
            <InputAdornment position="start">{startAdornmentLabel}</InputAdornment>
          ) : (
            undefined
          ),
        endAdornment:
          endAdornmentLabel || isCustom ? (
            <InputAdornment position="end" style={{ position: 'absolute', right: isCustom ? 0 : 24 }}>
              {endAdornmentLabel && endAdornmentLabel}
              {isCustom && <ArrowDropDownIcon style={{ width: '1em', height: '1em' }} onClick={turnOffIsCustom} />}
            </InputAdornment>
          ) : (
            undefined
          )
      }}
      inputProps={
        isCustom
          ? {
              style: {
                padding: 0,
                width: inline ? inputWidth : undefined
              }
              // onBlur: onBlur
              // onKeyDown: onKeyDownNumberOnly(true)
            }
          : undefined
      }
      SelectProps={{
        renderValue: value => decimalToString(value, decimalPlaces || 0),
        className: 'custom-input-select',
        SelectDisplayProps: { className: 'custom-input-select-inner', style: { width: '100%' } },
        MenuProps: { className: 'sticky-clickaway-exception', TransitionComponent: Zoom },
        open: open,
        onOpen: onOpen,
        onClose: onClose
      }}
      {...rest}
    >
      {allowCustomValue && <MenuItem value="custom">{intl.formatMessage({ id: 'custom' })}</MenuItem>}
      {selectOptions.map(option => {
        let value, label;
        if (option && option.hasOwnProperty('label') && option.hasOwnProperty('value')) {
          value = option.value;
          label = option.label || decimalToString(option.value, decimalPlaces || 0);
        } else {
          value = option;
          label = decimalToString(option, decimalPlaces || 0);
        }
        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </TempTextField>
  );
};

export default injectIntl(CustomInputSelect);
