import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { connect } from 'react-redux';
import ConfirmAddPolicyDialog from '../ConfirmAddPolicyDialog/ConfirmAddPolicyDialog';
import _ from 'lodash';
import { goToAddPortfolio, goToExportOption, goToPortfolio } from '../../../../components/ClientEntry/utils';
import { withRouterInnerRef } from '../../../../utils/router';
import { usePrevious } from '../../../../utils/hooks';
import { SUCCESS } from '../../../../constants/phase';

const SubmitActionWrapper = forwardRef((props, ref) => {
  const {
    history,
    location,
    match,
    staticContext,
    userDetails,
    policyPhase,
    viewingClient,
    router,
    handleSubmit,
    beforeSubmit,
    ...rest
  } = props;
  const [submitDialog, setSubmitDialog] = useState(false);
  const nextAction = useRef('submit');
  const prevPolicyPhase = usePrevious(policyPhase);

  const isAdviser = _.get(userDetails, 'userType') === 'Adviser';

  const open = () => setSubmitDialog(true);
  const close = () => setSubmitDialog(false);

  const submit = middleFunction => {
    close();
    if (beforeSubmit) {
      beforeSubmit();
    }
    localStorage.removeItem('addRider');
    if (middleFunction) {
      middleFunction();
    }
    handleSubmit();
  };

  const submitForm = () => {
    submit(() => {
      nextAction.current = 'submit';
      localStorage.removeItem('previousPolicyData');
    });
  };

  const addRider = () => {
    submit(() => {
      nextAction.current = 'rider';
    });
  };

  const addAnother = () =>
    submit(() => {
      nextAction.current = 'another';
    });

  const goExport = () =>
    submit(() => {
      nextAction.current = 'export';
    });

  useEffect(() => {
    if (prevPolicyPhase !== SUCCESS && prevPolicyPhase !== undefined && policyPhase === SUCCESS) {
      const { previousLocation } = router || {};
      const { pathname } = previousLocation || {};

      localStorage.removeItem('category');
      localStorage.removeItem('plan');
      localStorage.removeItem('payment');
      localStorage.removeItem('detail');
      localStorage.removeItem('apendix');
      localStorage.removeItem('editPlan');
      localStorage.removeItem('editPayment');
      localStorage.removeItem('editDetail');
      localStorage.removeItem('editApendix');
      localStorage.removeItem('factsheetDOB');
      sessionStorage.removeItem('editPolicyDetails');
      sessionStorage.removeItem('editPolicy');

      // delayed removal due to SimplifiedAddMPF mounting issue (can be improved)
      setTimeout(() => {
        localStorage.removeItem('subCategory');
      }, 500);

      if (nextAction.current === 'another') {
        goToAddPortfolio(viewingClient, history, userDetails, true, location.state);
      } else if (nextAction.current === 'export') {
        goToExportOption(viewingClient, history, true, location.state);
      } else if (nextAction.current === 'rider') {
        localStorage.setItem('addRider', JSON.stringify(true));
        history.replace({ pathname: '/add_portfolio', state: location.state });
      } else {
        if (location.state && location.state.previousPath) {
          localStorage.removeItem('insurer');
          localStorage.removeItem('addRider');
          localStorage.removeItem('previousPolicyData');
          if (pathname === location.state.previousPath) {
            history.goBack();
          } else {
            history.replace(location.state.previousPath);
          }
        } else {
          const replaceRoute = _.get(location, 'state.replaceRoute');
          if (replaceRoute) {
            if (pathname === '/c-dashboard') {
              history.goBack();
            } else {
              history.replace('/c-dashboard');
            }
          } else {
            if (pathname === '/dashboard') {
              history.goBack();
            } else {
              goToPortfolio(viewingClient, history, true, location.state);
            }
          }
        }
      }
    }
  }, [prevPolicyPhase, policyPhase, history, location, viewingClient, userDetails, router]);

  useImperativeHandle(ref, () => ({
    open: open
  }));

  return (
    <ConfirmAddPolicyDialog
      open={submitDialog}
      onClose={close}
      onSubmit={submitForm}
      onRider={addRider}
      onAnother={addAnother}
      onExport={isAdviser && goExport}
      {...rest}
    />
  );
});

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    policyPhase: state.policy.policyPhase,
    viewingClient: state.client.viewingClient,
    router: state.router
  }),
  {},
  null,
  {
    forwardRef: true
  }
)(SubmitActionWrapper);

export default withRouterInnerRef(container);
