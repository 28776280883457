import React, { useState } from 'react';
import '../ClientDashboardDrawer.scss';
import ReactEcharts from 'echarts-for-react';
import { SlideTransition } from '../../Transition/Transition';
import MoreInfoIconButton from '../../MoreInfoIconButton';
import { Dialog, DialogActions } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { formatNumber } from '../../../utils/formatNumber';
import { getUserCurrency } from '../../../utils/user';
import {
  getPolicyValue,
  getPolicyStartDate,
  getPolicyDueDate,
  getSubCategoryNameById,
  getSubCategoryColorByPolicy
} from '../../../utils/policy';
import moment from 'moment';
import _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import { getWebImageUrl } from '../../../utils/image';
import Badge from '@material-ui/core/Badge';

const colorCodeList = [
  '#C285FF',
  '#7FE1A6',
  '#FF9AA1',
  '#95C9FB',
  '#FFEB3B',
  '#FB89C2',
  '#cd8cd8',
  '#98EBBA',
  '#77e0ff',
  '#FFD077',
  '#FFA589',
  '#FB89C2', //self pick
  '#FFC6AD',
  '#CFDED1',
  '#B1FBD9',
  '#AFD6FD',
  '#FFFBAD',
  '#DFB9F3',
  '#F1BBD4',
  '#CDCFDF',
  '#85E2FF',
  '#F0BCC7'
];

const MonthlyPaidChart = props => {
  const { intl, finalPortfolio, userDetails, monthlyIncome, isSelfPortfolio } = props;

  const [isSummaryGraph, setIsSummaryGraph] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleChange = event => {
    setIsSummaryGraph(!isSummaryGraph);
  };

  const addBarBorderRadius = option => {
    for (var dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
      moreValue = false;
      // Stack order is the same as series declaration order.
      for (var i = option.series.length - 1; i >= 0; i--) {
        var data = option.series[i].data;
        if (dataIndex < data.length) {
          moreValue = true;
          var val = data[dataIndex];
          if (!isNaN(val) && val != null && val > 0) {
            data[dataIndex] = {
              value: val,
              itemStyle: {
                emphasis: {
                  barBorderRadius: [50, 50]
                },
                normal: {
                  barBorderRadius: [50, 50, 0, 0]
                }
              }
            };
            break;
          }
        }
      }
    }

    return option;
  };

  const selectedCurrency = getUserCurrency(userDetails);
  const currentMonth = moment().month();
  const stackType = 'stack';
  const shouldShowIncomeLine = isSelfPortfolio || _.get(userDetails, 'userType') !== 'User';

  let seriesData = [];
  let monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let nonCountData = [];
  monthLabels = monthLabels.slice(currentMonth).concat(monthLabels.slice(0, currentMonth));

  // we need to check 13 months
  monthLabels = [...monthLabels, monthLabels[0]];

  // console.log(finalPortfolio);
  // console.log('current Month: ' + currentMonth);
  finalPortfolio.forEach(policy => {
    // console.log('---------------');

    const policyStartDate = getPolicyStartDate(policy);
    const policyStartMonth = moment(policyStartDate).month();

    const policyDueDate = getPolicyDueDate(policy);
    const policyDueMonth = moment(policyDueDate).month();

    const checkMonth =
      policyDueDate !== undefined && policyDueDate !== null
        ? policyDueMonth
        : policyStartDate !== undefined && policyStartDate !== null
        ? policyStartMonth
        : null;

    const frequency = _.get(policy, 'frequency');
    const payment = Math.round(getPolicyValue(policy, 'premium', selectedCurrency));
    const paidUpDate = _.get(policy, 'paidUpDate');
    const premiumHolidayDate = _.get(policy, 'premiumHolidayDate');
    let monthlyPayment = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    // console.log(policy.policyName);
    // console.log('Freq: ' + frequency + '(' + policy.currency + ')');
    // console.log('Paid UP: ' + paidUpDate);
    // console.log('Due Day: ' + policyDueDate);
    // console.log('Start:: ' + policyStartDate);
    // console.log('Amount: ' + payment);
    // console.log('Months start counting: ' + checkMonth);
    // console.log('subCategoryId: ' + policy.subCategoryId);

    if ((checkMonth !== undefined && checkMonth !== null) || frequency === '1') {
      //calculate the related months by frequency and paid month
      let paymentMonths = [];
      for (let i = 0; i < 13; i++) {
        if (
          (moment()
            .add(i, 'M')
            .month() -
            checkMonth) %
            parseInt(frequency) ===
          0
        ) {
          paymentMonths.push(i);
        }
        // paymentMonths.push((checkMonth + i * frequency) % 12);
      }

      // console.log('paymentMonths: ');
      // console.log(paymentMonths);

      monthlyPayment = monthlyPayment.map((value, i) => {
        // There are 3 conditions to involve payment:
        // 1. This month is involved in payment months variable
        // 2. If Paid Up Date is set, and calculating month should not be after it
        // 3 If Due Date is set, and calculating month should be before it
        // Remarks: only year and month would be compared

        let estimatedMonth = moment().add(i, 'M');
        // if(paymentMonths.includes(i)){
        //   console.log("Current i: "+ i)
        //   console.log("paidUpDate Year: " + moment(paidUpDate).year())
        //   console.log("paidUpDate month: " + moment(paidUpDate).month())
        //   console.log("estimated Year: " + estimatedMonth.year())
        //   console.log("estimated month: " + estimatedMonth.month())

        //   console.log(moment(paidUpDate).year()>estimatedMonth.year())
        //   console.log(moment(paidUpDate).year()===estimatedMonth.year() && moment(paidUpDate).month()>estimatedMonth.month())
        // }

        // should return 0 if policy status is paid-up but paid-up date is not specified
        // should follow the same logic of isPaidUpPolicy()
        if (!paidUpDate && _.get(policy, 'selectedPolicyStatus') === 'Paid-up') {
          return 0;
        }

        return paymentMonths.includes(i) &&
          (paidUpDate === null ||
            paidUpDate === undefined ||
            moment(paidUpDate).year() > estimatedMonth.year() ||
            (moment(paidUpDate).year() === estimatedMonth.year() &&
              moment(paidUpDate).month() >= estimatedMonth.month())) &&
          (policyDueDate === null ||
            policyDueDate === undefined ||
            moment(policyDueDate).year() < estimatedMonth.year() ||
            (moment(policyDueDate).year() === estimatedMonth.year() &&
              moment(policyDueDate).month() <= estimatedMonth.month())) &&
          (premiumHolidayDate === null ||
            premiumHolidayDate === undefined ||
            moment(premiumHolidayDate).year() > estimatedMonth.year() ||
            (moment(premiumHolidayDate).year() === estimatedMonth.year() &&
              moment(premiumHolidayDate).month() >= estimatedMonth.month()))
          ? payment
          : 0;
      });

      // monthlyPayment = monthlyPayment.slice(currentMonth).concat(monthlyPayment.slice(0, currentMonth));

      // Summary: use default color code
      // Details: pick from hard coded colorCodeList
      let colorCode = isSummaryGraph === true ? '#3966f8' : colorCodeList[seriesData.length % colorCodeList.length];
      seriesData.push({
        _id: policy._id,
        name: policy.policyName || policy.type || policy.policyNumber || policy.companyName || policy.insurer || '',
        type: 'bar',
        color: colorCode,
        subCategoryId: policy.subCategoryId,
        borderColor: colorCode,
        stack: stackType,
        barCategoryGap: '-30%',
        data: monthlyPayment,
        showBackground: true,
        barWidth: 8,
        roundCap: true
        // itemStyle: {
        //   emphasis: {
        //     barBorderRadius: [50, 50, 0, 0]
        //   },
        //   normal: {
        //     barBorderRadius: [50, 50, 0, 0]
        //   }
        // },
      });
    } else {
      nonCountData.push({
        name: policy.policyName,
        color: getSubCategoryColorByPolicy(policy),
        subCategoryName: getSubCategoryNameById(policy.subCategoryId)
      });
    }
  });

  // sort by data
  seriesData = seriesData.sort((a, b) => {
    return a.data < b.data ? -1 : a.data > b.data ? 1 : 0;
  });

  const isHaveValidData =
    seriesData.filter(policy => policy.data.filter(eachData => eachData > 0).length > 0).length > 0;

  let max = monthlyIncome || undefined;
  let monthlyPremiums = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  // Add mark line data
  if (seriesData && seriesData.length > 0) {
    monthlyPremiums = seriesData.reduce((accumulator, current, index) => {
      current.data.forEach((monthlyPremium, month) => {
        accumulator[month] += monthlyPremium;
      });

      return accumulator;
    }, monthlyPremiums);

    monthlyPremiums.forEach(monthlyPremium => {
      if (monthlyPremium > max) {
        max = monthlyPremium;
      }
    });

    const averagePremium =
      monthlyPremiums.slice(1, 13).reduce((accumulator, current) => accumulator + current, 0) / 12 || 0;

    const markLineData = [
      {
        name: intl.formatMessage({ id: 'Avg-premium' }),
        yAxis: averagePremium,
        lineStyle: {
          color: '#22B14C',
          type: 'dashed',
          width: 3
        },
        label: {
          padding: [0, 0, 0, -35]
        }
      }
    ];

    if (shouldShowIncomeLine) {
      if (monthlyIncome) {
        markLineData.push({
          name: intl.formatMessage({ id: 'Income' }),
          yAxis: monthlyIncome,
          lineStyle: {
            color: '#8A2BE2',
            type: 'dashed',
            width: 3
          },
          label: {
            padding: [0, 0, 0, -15]
          }
        });
      }
    }

    seriesData[seriesData.length - 1].markLine = {
      symbol: 'none',
      symbolSize: [0, 0],
      data: markLineData,
      label: {
        position: 'end',
        formatter: `{b}`,
        textStyle: {
          baseline: 'bottom',
          fontSize: 10
        }
      }
    };
  }

  // console.log('seriesData:');
  // console.log(seriesData);
  // console.log('nonCountData:');
  // console.log(nonCountData);
  // console.log('isHaveValidData:');
  // console.log(isHaveValidData);

  const colorSpan = color =>
    '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
    color +
    '"></span>';

  const columnTooltips = monthlyPremiums.reduce((accumulator, monthlyPayment, index) => {
    const name = monthLabels[index];

    let resultHTML = name + '</br>';
    resultHTML += `${intl.formatMessage({ id: 'monthly-payment-total' })}: ${intl.formatMessage({
      id: selectedCurrency
    })} ${formatNumber(monthlyPayment, intl)}</br>`;

    if (!isSummaryGraph) {
      let itemListData = '';
      seriesData.forEach(item => {
        const seriesThatMonthValue = _.get(item, `data[${index}]`, 0);
        if (seriesThatMonthValue > 0) {
          const subCategoryName = getSubCategoryNameById(seriesData.subCategoryId);
          const subCategoryNameText = `${
            subCategoryName.trim() ? `${intl.formatMessage({ id: subCategoryName })} ` : ''
          }`;
          itemListData += `${colorSpan(item.color)} ${subCategoryNameText}${item.name}: ${intl.formatMessage({
            id: selectedCurrency
          })} ${formatNumber(seriesThatMonthValue, intl)}</br>`;
        }
      });

      resultHTML += itemListData;
    }

    accumulator[index] = resultHTML;
    return accumulator;
  }, {});

  var chartOptions = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      formatter: params => {
        if (Array.isArray(params) || params.componentType === 'series') {
          params = Array.isArray(params) ? params.filter(data => data.axisType === 'xAxis.category') : [params];
          const index = _.get(params, '[0].dataIndex');
          if (index !== undefined) {
            return columnTooltips[index];
          }
          // var colorSpan = color =>
          // 	'<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
          // 	color +
          // 	'"></span>';
          // let totalPayment = 0;
          // let itemListData = '';
          // params.forEach(item => {
          // 	let val = item.data.value ? item.data.value : item.data;
          // 	if (val > 0) {
          // 		const tmpPolicy = seriesData[item.seriesIndex];
          // 		let subCategoryName = getSubCategoryNameById(tmpPolicy.subCategoryId)
          // 			? intl.formatMessage({ id: getSubCategoryNameById(tmpPolicy.subCategoryId) })
          // 			: ' ';
          // 		var eachInfo =
          // 			colorSpan(item.color) +
          // 			' ' +
          // 			subCategoryName +
          // 			' ' +
          // 			item.seriesName +
          // 			': ' +
          // 			intl.formatMessage({ id: selectedCurrency }) +
          // 			' ' +
          // 			formatNumber(val, intl) +
          // 			'</br>';
          //
          // 		totalPayment += val;
          // 		itemListData += eachInfo;
          // 	}
          // });
          // let rez = (_.get(params, '[0].axisValue') || _.get(params, 'params[0].name', '')) + '</br>';
          // rez +=
          // 	intl.formatMessage({ id: 'monthly-payment-total' }) +
          // 	': ' +
          // 	intl.formatMessage({ id: selectedCurrency }) +
          // 	' ' +
          // 	formatNumber(totalPayment, intl) +
          // 	'</br>';
          // if (!isSummaryGraph) {
          // 	rez += itemListData;
          // }
          // return rez;
        } else if (params.componentType === 'markLine') {
          return `${params.name}: ${intl.formatMessage({ id: selectedCurrency })} ${formatNumber(params.value, intl)}`;
        }
      }
    },
    grid: {
      left: '10%',
      bottom: '0%',
      top: '5%',
      containLabel: true
    },
    yAxis: {
      type: 'value',
      name: intl.formatMessage({ id: 'premium-amount' }),
      nameGap: isHaveValidData ? 42 : 22,
      max: max ? max * 1.1 : max,
      nameLocation: 'center',
      axisLabel: {
        formatter: function(a, i) {
          return formatNumber(a, intl);
        }
      }
    },
    xAxis: {
      type: 'category',
      nameLocation: 'center',
      data: monthLabels,
      axisLabel: {
        interval: 0,
        rotate: 45
      }
    },
    series: seriesData,
    axisPointer: {
      show: true,
      type: 'line',
      label: {
        show: false
      },
      lineStyle: {
        width: 0.5
      }
    }
  };

  chartOptions = addBarBorderRadius(chartOptions);

  return (
    <div>
      <div className="cardAnalysis">
        <h4 className="text-center coverage-graph-title">{intl.formatMessage({ id: 'payment-schedule-title' })}</h4>

        <div className="dashboard-drawer-icon">
          <MoreInfoIconButton handleClick={() => setModalIsOpen(true)} />
          {nonCountData.length > 0 && <Badge variant="dot" className="moreInfoDot" color="error" badgeContent="" />}
        </div>

        {!isHaveValidData && (
          <div className="Outer">
            <div className="exclamation-chart-wrapper">
              <p>{intl.formatMessage({ id: 'Input-start-date-2' })}</p>
              <img src={getWebImageUrl('img/exclamation.png')} alt="exclamation" className="exclamation-chart" />
            </div>
          </div>
        )}

        <div className="Outer">
          <ReactEcharts option={chartOptions} notMerge={true} className={'react_for_echarts'} height={200} />
          <FormControlLabel
            control={<Checkbox checked={!isSummaryGraph} onChange={handleChange} name="checkedB" color="primary" />}
            label={intl.formatMessage({ id: 'show-details' })}
          />
        </div>
      </div>

      <Dialog
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title" className="figureColor">
          {intl.formatMessage({ id: 'payment-schedule-title' })}
        </DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: 'payment-schedule-desc' })}
          <br />
          <br />
          {nonCountData.length > 0 && intl.formatMessage({ id: 'non-count-data-description' })}
          {nonCountData &&
            nonCountData.map((data, index) => {
              return (
                <div key={index}>
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '5px',
                      borderRadius: '10px',
                      width: '9px',
                      height: '9px',
                      backgroundColor: `${data.color}`
                    }}
                  ></span>
                  {intl.formatMessage({ id: data.subCategoryName })}
                  {': '}
                  {data.name}
                </div>
              );
            })}
        </DialogContent>

        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button color="primary" onClick={() => setModalIsOpen(false)}>
            {intl.formatMessage({ id: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MonthlyPaidChart;
