import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import { useForceUpdate } from '../../../../utils/hooks';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { getFunPrompt } from '../../../../utils/aiPrompt';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ContentBox from '../ContentBox/ContentBox';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import { openaiGenerateWithTextStreaming } from '../../../../store/openai/api';

const ChatGPTFunDrawer = props => {
  const { intl, chatGPTFunDrawer, toggleControl } = props;

  const [userText, setUserText] = useState('');
  const [userTextError, setUserTextError] = useState('');
  const [text, setText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const forceUpdate = useForceUpdate();

  const { client, closed } = chatGPTFunDrawer || {};

  const isOpen = !!chatGPTFunDrawer && !closed;

  const onClose = () => {
    toggleControl('chatGPTFunDrawer', { ...chatGPTFunDrawer, closed: true });
  };

  const onExited = () => {
    toggleControl('chatGPTFunDrawer', false);
    setUserText('');
    setUserTextError('');
    setText('');
    setChatMessages([]);
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: text,
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const send = async ({ inputMessage, previousMessages }) => {
    if ((userText || '').length > 200) {
      setUserTextError(intl.formatMessage({ id: 'chat-gpt-fun-drawer-max-200-char' }));
      return;
    }

    try {
      validateGPTQuota();
      setLoading(true);

      const addedChatMessages = [...(previousMessages || []), { content: inputMessage, role: 'user' }];
      await openaiGenerateWithTextStreaming('gpt-4o', addedChatMessages, 1, 1, 0, 0, setChatMessages, setText);

      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const sendUserText = () => {
    const inputMessage = getFunPrompt({ text: userText });
    send({ inputMessage });
  };

  const onChangeInputMessage = event => {
    const targetValue = event.target.value;
    if (targetValue.length <= 200) {
      setUserText(event.target.value);
      setUserTextError('');
    }
  };

  const clear = () => {
    setText('');
    setChatMessages([]);
  };

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'chat-gpt-fun-drawer-content' })}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth={true}
              multiline={true}
              rows={5}
              value={userText}
              onChange={onChangeInputMessage}
              placeholder={intl.formatMessage({ id: 'chat-gpt-fun-drawer-input-placeholder' })}
              error={!!userTextError}
              helperText={userTextError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" color="primary" onClick={sendUserText} disabled={!userText || loading}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <ContentBox
                  getHeight={({ innerHeight }) => `calc(${innerHeight}px - 416px - var(--top-padding))`}
                  text={text}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Grid container justify="space-between">
                      <Grid item>{text && <Typography>{intl.formatMessage({ id: 'Follow Up' })}</Typography>}</Grid>
                      <Grid item>
                        <QuotaTypography />
                      </Grid>
                    </Grid>
                  </Grid>
                  {text && (
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FollowUpButton onClick={clear}>
                            {intl.formatMessage({ id: 'chat-gpt-follow-up-clear' })}
                          </FollowUpButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    chatGPTFunDrawer: state.control.chatGPTFunDrawer
  }),
  {
    toggleControl
  }
)(ChatGPTFunDrawer);

export default injectIntl(container);
