import moment from 'moment';

export const isMedicalCardRenewable = medicalCard => {
  const { serviceEndDate } = medicalCard || {};

  if (!serviceEndDate) {
    return false;
  }

  try {
    const oneMonthLaterMoment = moment().add(1, 'month');
    const serviceEndDateMoment = moment(serviceEndDate);

    return serviceEndDateMoment.isSameOrBefore(oneMonthLaterMoment);
  } catch (error) {
    console.log(error);
    return false;
  }
};
