import React, { Fragment, useState } from 'react';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import ActionButton from '../ActionButton/ActionButton';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { getMatchLazyPacksPrompt } from '../../../../utils/aiPrompt';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import ContentBox from '../ContentBox/ContentBox';
import { vertexGenerateWithText } from '../../../../store/vertex/api';
import QuotaTypography from '../QuotaTypography/QuotaTypography';
import IconToast from '../../../NewToast';

const ChatGPTMatchLazyPacks = props => {
  const { intl, history, chatGPTMatchLazyPacks, toggleControl } = props;

  const [options, setOptions] = useState(history.location.state?.options ? history.location.state.options : []);
  const [loading, setLoading] = useState(false);
  const [lifeStage, setLifeStage] = useState(history.location.state?.lifeStage ? history.location.state.lifeStage : '');
  const [chatPurpose, setChatPurpose] = useState(
    history.location.state?.chatPurpose ? history.location.state.chatPurpose : ''
  );
  const [language, setLanguage] = useState(
    history.location.state?.language ? history.location.state.language : 'Traditional Chinese'
  );
  const theme = useTheme();

  const { client, closed } = chatGPTMatchLazyPacks || {};

  const isOpen = !!chatGPTMatchLazyPacks && !closed;

  const onChangeLifeStage = event => {
    setLifeStage(event.target.value);
  };

  const onChangeChatPurpose = event => {
    setChatPurpose(event.target.value);
  };

  const onChangeLanguage = event => {
    setLanguage(event.target.value);
  };

  const onClose = () => {
    // console.log('chatGPTMatchLazyPacks onClose callled');
    toggleControl('chatGPTMatchLazyPacks', { ...chatGPTMatchLazyPacks, closed: true });
    // history.push({ pathname: history.location.pathname, state: {} });
  };

  const onExited = () => {
    // console.log('chatGPTMatchLazyPacks onExit callled');
    toggleControl('chatGPTMatchLazyPacks', false);
    // history.push({ pathname: history.location.pathname, state: {} });
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: options.toString(),
      onSubmit: () => {
        resetGPTQuota();
      }
    });
  };

  const send = async () => {
    try {
      if (!chatPurpose) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'select-chat-purpose' })), { className: 'new-toast' });
        return;
      }

      validateGPTQuota();
      setLoading(true);

      let prompt = getMatchLazyPacksPrompt({
        client,
        lifeStage,
        chatPurpose,
        adviserLanguage: intl.locale,
        clientLanguage: language
      });
      let fileUri = 'gs://hot-topic/hot-topic-library-pdf.pdf';
      let mimeType = 'application/pdf';
      let textPart = { text: prompt };
      let filePart = { fileData: { fileUri: fileUri, mimeType: mimeType } };
      let promptWithFile = [{ role: 'user', parts: [textPart, filePart] }];

      let result = await vertexGenerateWithText('gemini-1.5', promptWithFile, 0.8, 1, 2048);
      if (result.success) {
        let data = result.response?.candidates[0]?.content?.parts[0]?.text.replace('```json', '').replace('```', '');
        data = JSON.parse(data);
        setOptions(data);
      } else {
        setOptions([]);
      }
      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const goToLazyPack = (id, message) => {
    history.push({ pathname: history.location.pathname, state: { options, lifeStage, chatPurpose, language } });
    history.push({ pathname: '/hot_topic', hash: `#${id}`, state: { share_message: message, language: language } });
  };

  let text = options
    .map(
      (option, index) =>
        `${intl.formatMessage({ id: 'option-number' }, { number: index + 1 })}\n` +
        `${intl.formatMessage({ id: 'lazy-pack-title' })}: ${option.title}\n` +
        `${intl.formatMessage({ id: 'reason' })}: ${option.reason}\n\n` +
        `${intl.formatMessage({ id: 'personalized-message' })}: ${option.personalized_message}\n`
    )
    .join('\n\n');

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          <Grid item>
            {intl.formatMessage({ id: 'match-lazy-pack-description' })}
          </Grid>
          <Grid item>
            {intl.formatMessage({ id: 'clients-life-stage' })}
            <FormControl>
              <RadioGroup
                row
                aria-label="clients-life-stage"
                name="clients-life-stage"
                value={lifeStage}
                onChange={onChangeLifeStage}
              >
                <FormControlLabel
                  value="Career Start"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'career-start' })}
                />
                <FormControlLabel
                  value="Career & Family Growth"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'career-and-family-growth' })}
                />
                <FormControlLabel
                  value="Family with Children"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'family-with-children' })}
                />
                <FormControlLabel
                  value="Pre-Retirement"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'pre-retirement' })}
                />
                <FormControlLabel
                  value="Retired"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'retired' })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            {intl.formatMessage({ id: 'chat-purpose' })} <span style={{color: "red"}}>*</span>
            <FormControl>
              <RadioGroup
                row
                aria-label="chat-purpose"
                name="chat-purpose"
                value={chatPurpose}
                onChange={onChangeChatPurpose}
              >
                <FormControlLabel
                  value="Initial interaction"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'initial-interaction' })}
                />
                <FormControlLabel
                  value="Product Selling"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'product-selling' })}
                />
                <FormControlLabel
                  value="Follow Up Service"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: 'follow-up-service' })}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <Grid container justify="center">
              <Grid item>
                <RadioGroup row aria-label="language" name="language" value={language} onChange={onChangeLanguage}>
                  <FormControlLabel
                    value="Traditional Chinese"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: 'traditional-chinese' })}
                  />
                  <FormControlLabel
                    value="English"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: 'standard-english' })}
                  />
                  <FormControlLabel
                    value="Simplified Chinese"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage({ id: 'simplified-chinese' })}
                  />
                </RadioGroup>
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <ActionButton onClick={send} style={{ padding: 5, width: "100%" }}>
                  {intl.formatMessage({ id: 'chat-gpt-options-lazy-packs' })}
                </ActionButton>
              </Grid>
              <Grid item style={{ marginTop: 10 }}>
                {options?.length > 0 && <span style={{ fontSize: 16 }}>{intl.formatMessage({ id: 'choice' })}</span>}
                {options.map((option, index) => (
                  <FollowUpButton
                    key={option.id}
                    onClick={() => goToLazyPack(option.id, option.personalized_message)}
                    color="primary"
                    style={{ color: theme.palette.primary.main, marginLeft: 10 }}
                  >
                    {intl.formatMessage({ id: 'option-number' }, { number: index + 1 })}
                  </FollowUpButton>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ContentBox
              getHeight={({ innerHeight }) => `calc(${innerHeight}px - 330px - var(--top-padding))`}
              text={text}
              disableCopy={true}
            />
          </Grid>
          <Grid item>
            <Grid container justify="space-between">
              <Grid item></Grid>
              <Grid item>
                <QuotaTypography />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    chatGPTMatchLazyPacks: state.control.chatGPTMatchLazyPacks
  }),
  {
    toggleControl
  }
)(ChatGPTMatchLazyPacks);

export default injectIntl(withRouter(container));
