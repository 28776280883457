import React, { PureComponent } from 'react';
import SignupView from './partials/signup';
import VerifyUserView from './partials/verifyUserByAdmin';
import VerifyAdviser from './partials/verifyAdviser';

class SignupForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      verifyUserId: '',
      verifyAdviserId: ''
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.params.Id) {
      var verifyUserId = this.props.location.pathname.replace('/verify_user/', '');
      this.setState({ verifyUserId: verifyUserId });
    }
    if (this.props.match.params.adviserId) {
      this.setState({ verifyAdviserId: this.props.match.params.adviserId });
    }
  }

  render() {
    const currentLocation = this.props.location.pathname;
    return (
      <div>
        <div id="wrapper">
          {currentLocation.toLowerCase() === '/signup' && <SignupView {...this.props} />}
          {this.state.verifyUserId !== '' && <VerifyUserView {...this.props} />}
          {this.state.verifyAdviserId !== '' && <VerifyAdviser {...this.props} />}
        </div>
      </div>
    );
  }
}

export default SignupForm;
