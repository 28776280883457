import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select, Grid, Button, InputLabel, CircularProgress } from '@material-ui/core';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 80
  }
}));

const TeamStructure = props => {
  const {
    intl,
    open,
    onClose,
    teamStructure,
    setTeamStructureTeamId,
    setVersion,
    loading,
    selectedTeams,
    setSelectedTeams,
    changeOwnTeam,
    teamTrackingData,
    ownTeamID
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const selectTeam = (level, teamId) => {
    let newSelectedTeams = [{ 2: teamStructure }];
    let parentTeam = teamStructure;
    for (let i = 3; i <= level; i++) {
      newSelectedTeams.push(selectedTeams[i - 2]);
      parentTeam = Object.values(selectedTeams[i - 2])[0];
    }
    if (level >= 2) {
      let newSelectedTeam = parentTeam.subteams.find(team => team._id === teamId);
      newSelectedTeam.parentTeamId = parentTeam._id;
      newSelectedTeams.push({ [level + 1]: newSelectedTeam });
    }
    setSelectedTeams(newSelectedTeams);
  };

  const reset = () => {
    setSelectedTeams([{ 2: teamStructure }]);
  };

  const resetAndClose = () => {
    setSelectedTeams([{ 2: teamStructure }]);
    setVersion(Date());
    setTeamStructureTeamId(teamStructure?._id);
  };

  const confirm = () => {
    setVersion(Date());
    setTeamStructureTeamId(Object.values(selectedTeams[selectedTeams.length - 1])[0]._id);
  };

  useEffect(() => {
    reset();
  }, [teamStructure]);

  return (
    <BottomSheetOrDialog
      className="policy-review-edit-layout-dialog"
      open={open}
      onClose={onClose}
      header={intl.formatMessage({ id: 'select-team' })}
      content={
        <Grid container direction="column">
          {teamTrackingData?.length <= 1 && (
            <span style={{ fontSize: '110%', marginBottom: 10 }}>
              {intl.formatMessage({ id: 'my-own-team' })}:&nbsp;
              <span style={{ color: theme.palette.primary.main }}>{teamStructure?.teamName}</span>
              <Button
                style={{
                  padding: '0 6px',
                  fontSize: 11,
                  marginLeft: 10,
                  minWidth: 'auto'
                }}
                variant="outlined"
                color="primary"
                onClick={resetAndClose}
              >
                {intl.formatMessage({ id: 'View' })}
              </Button>
            </span>
          )}

          {teamTrackingData?.length > 1 && (
            <Grid item key="level-1">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="level-1-label">Level 1</InputLabel>
                <Select
                  labelId="level-1-label'"
                  id="level-1"
                  value={ownTeamID}
                  label="Level 1"
                  onChange={changeOwnTeam}
                  style={{ background: selectedTeams?.length <= 1 ? '#3966f830' : '' }}
                >
                  {teamTrackingData.map(team => (
                    <MenuItem key={team.teamId} value={team.teamId}>
                      {team.teamName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {selectedTeams.map(
            team =>
              Object.values(team)[0]?.subteams?.length > 0 && (
                <Grid item key={`level-${Object.keys(team)[0]}`}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id={`level-${Object.keys(team)[0]}-label`}>Level {Object.keys(team)[0]}</InputLabel>
                    <Select
                      labelId={`level-${Object.keys(team)[0]}-label`}
                      id={`level-${Object.keys(team)[0]}`}
                      value={
                        selectedTeams[parseInt(Object.keys(team)[0]) - 1]
                          ? Object.values(selectedTeams[parseInt(Object.keys(team)[0]) - 1])[0]._id
                          : ''
                      }
                      label={`Level ${Object.keys(team)[0]}`}
                      onChange={event => selectTeam(parseInt(Object.keys(team)[0]), event.target.value)}
                      style={{
                        background:
                          selectedTeams &&
                          Object.values(team)[0]._id ===
                            Object.values(selectedTeams[selectedTeams.length - 1])[0].parentTeamId
                            ? '#3966f830'
                            : ''
                      }}
                    >
                      {Object.values(team)[0].subteams.map(team => (
                        <MenuItem key={team._id} value={team._id}>
                          {team.teamName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )
          )}
        </Grid>
      }
      actions={[
        <Button
          color="primary"
          onClick={confirm}
          disabled={teamTrackingData?.length === 1 && selectedTeams.length === 1}
        >
          {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'view-team' })}
        </Button>
      ]}
      actionsPrepend={[
        <Button color="primary" onClick={reset} disabled={selectedTeams.length <= 1}>
          {intl.formatMessage({ id: 'Reset' })}
        </Button>
      ]}
      BottomSheetProps={{
        expandOnContentDrag: false,
        disableAutoDismiss: true
      }}
      DialogProps={{
        maxWidth: 'sm',
        fullWidth: true
      }}
    />
  );
};

export default TeamStructure;
