import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import { toggleDialog } from '../../../store/control/duck';

const FreeTrialLazyPackExcludedDialog = props => {
  const { intl, history, freeTrialLazyPackExcludedDialog, toggleDialog } = props;

  if (!freeTrialLazyPackExcludedDialog) {
    return null;
  }

  const { closed } = freeTrialLazyPackExcludedDialog;

  const onClose = () => toggleDialog('freeTrialLazyPackExcluded', { ...freeTrialLazyPackExcludedDialog, closed: true });
  const onClosed = () => toggleDialog('freeTrialLazyPackExcluded', false);
  const cta1 = () => {
    onClose();
    history.push('/purchase-lazy-pack');
  };
  const cta2 = () => {
    onClose();
    history.push('/subscription');
  };

  return (
    <Dialog open={!closed} TransitionComponent={SlideTransition} fullWidth={true} onClose={onClose} onExited={onClosed}>
      <DialogTitle>{intl.formatMessage({ id: 'trial-lazy-pack-excluded-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'trial-lazy-pack-excluded-dialog-content' })}</DialogContent>
      <DialogActions>
        {/* <Button color="primary" onClick={cta1}> 
          {intl.formatMessage({ id: 'trial-lazy-pack-excluded-dialog-cta1' })}
        </Button> */}
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={cta2}>
          {intl.formatMessage({ id: 'trial-lazy-pack-excluded-dialog-cta2' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    freeTrialLazyPackExcludedDialog: state.control.freeTrialLazyPackExcludedDialog
  }),
  {
    toggleDialog
  }
)(FreeTrialLazyPackExcludedDialog);

export default injectIntl(container);
