import React, { Fragment } from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core';
import MyDetailsTeam from '../MyDetailsTeam/MyDetailsTeam';
import MyDetailsCustomQuestions from '../MyDetailsCustomQuestions/MyDetailsCustomQuestions';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const MyDetailsExtra = props => {
  const { intl, userDetails, details, onChangeDetails, updateError, error, onResize, allowProfilePlusTeamPage } = props;

  return (
    <Fragment>
      <ExpansionPanel TransitionProps={{ onEntered: onResize, onExited: onResize }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="team-content" id="team-header">
          <QuestionAnswerIcon color='primary' />
          <Typography style={{marginLeft: 5}}>{intl.formatMessage({ id: 'my-questions' })}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <MyDetailsCustomQuestions
            userDetails={userDetails}
            details={details}
            onChangeDetails={onChangeDetails}
            updateError={updateError}
            error={error}
            onResize={onResize}
            allowProfilePlusTeamPage={allowProfilePlusTeamPage}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <br />

      <ExpansionPanel TransitionProps={{ onEntered: onResize, onExited: onResize }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="team-content" id="team-header">
          <GroupAddIcon color='primary' />
          <Typography style={{marginLeft: 5}}>{intl.formatMessage({ id: 'Team' })}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <MyDetailsTeam
            userDetails={userDetails}
            details={details}
            onChangeDetails={onChangeDetails}
            updateError={updateError}
            error={error}
            onResize={onResize}
            allowProfilePlusTeamPage={allowProfilePlusTeamPage}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
};

export default MyDetailsExtra;
