import React from 'react';
import { EditorState } from 'draft-js';
import UndoIcon from '@material-ui/icons/Undo';

class UndoButton extends React.PureComponent {
  render() {
    const { theme, onChange, editorState } = this.props;

    return (
      <div className={theme.buttonWrapper} onMouseDown={event => event.preventDefault()}>
        <button
          type="button"
          className={theme.button}
          disabled={editorState.getUndoStack().size === 0}
          onClick={event => {
            event.stopPropagation();
            onChange(EditorState.undo(editorState));
          }}
        >
          <UndoIcon />
        </button>
      </div>
    );
  }
}

export default UndoButton;
