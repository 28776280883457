const LoginStyles = theme => ({
  paper: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  desktopLoginwrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    marginBottom: theme.spacing(4)
  },
  avatar: {
    backgroundColor: '#3966f8'
  },
  logindetails: {},
  inputBase: {
    fontSize: 'inherit'
  },
  input: {
    height: '15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '550px',
    zIndex: 1
  },
  submit: {
    margin: theme.spacing(2, 0),
    fontSize: '20px',
    background: 'var(--my-gradient)'
  },
  signupButton: {
    fontSize: '20px',
    padding: '3px 6px',
    minWidth: '233px',
    background: 'var(--my-gradient)'
  }
});

export default LoginStyles;
