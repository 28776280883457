import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { generateTagColor, getAvailableTag, getNewUsedTag, getUsedTagColor, isExceedLimit } from '../../../utils/tag';
import Tag from '../../Tag';
import React from 'react';
import { SlideTransition } from '../../Transition/Transition';
import _ from 'lodash';
import { ShortSubscriptions } from '../../../utils/user-subscription';

const INITIAL_STATE = { tagMsg: '' };

class TagsDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  close = () => {
    const { tagsDialog, toggleDialog } = this.props;
    const { onClose, tags } = tagsDialog;
    onClose(tags);
    toggleDialog('tags', { ...tagsDialog, closed: true });
  };

  closed = () => {
    this.props.toggleDialog('tags', false);
    this.setState(INITIAL_STATE);
  };

  handleDelete(i) {
    const { tagsDialog, toggleDialog } = this.props;
    const { tags } = tagsDialog;
    toggleDialog('tags', { ...tagsDialog, tags: tags.filter(tag => tag.text !== i) });
  }

  addTagIntoEdit(tag) {
    const { userDetails, subscriptions, intl, tagsDialog, toggleDialog, isTeamMember } = this.props;
    let { tags, savedTags } = tagsDialog;
    let validCoreSubscription = new ShortSubscriptions(subscriptions).containValidCoreSubscription();
    let availableTag = getAvailableTag(validCoreSubscription, isTeamMember);
    let editTags = !tags ? [] : tags;
    savedTags = !savedTags ? [] : savedTags;
    let usedTag = userDetails.usedTags ? userDetails.usedTags : [];
    let newUsedTag = getNewUsedTag(usedTag, savedTags, editTags);
    let isLimit = isExceedLimit(editTags, newUsedTag, availableTag, tag);

    if (editTags.find(value => value.text === tag.text)) {
      this.setState({ tagMsg: intl.formatMessage({ id: 'Tag_exist' }) });
      return;
    }
    if (!isLimit) {
      this.setState({ tagMsg: intl.formatMessage({ id: 'Tag_limit_n' }, { n: availableTag }) });
      return;
    }

    //if the tag exists on the other factsheets, get the color code
    let preRecord = usedTag.filter(value => value.text === tag.text);
    let updateTag = {
      color: preRecord.length > 0 ? preRecord[0].color : generateTagColor(),
      text: tag.text
    };

    this.setState({ tagMsg: '' });
    toggleDialog('tags', { ...tagsDialog, tags: editTags.concat(updateTag) });
  }

  handleInputBlur(tag) {
    if (tag.replace(/^\s+|\s+$/g, '').length > 0) {
      let tagFormat = {
        id: tag,
        text: tag
      };
      this.addTagIntoEdit(tagFormat);
    }
  }

  render() {
    const { intl, tagsDialog, userDetails, isTeamMember } = this.props;
    const { tagMsg } = this.state;

    if (!tagsDialog) return null;

    const { tags, closed } = tagsDialog;
    const usedTags = _.get(userDetails, 'usedTags', []);
    const importTag = _.get(tagsDialog, 'tags', []).map(value => {
      return {
        id: value.text,
        text: value.text
      };
    });

    return (
      <Dialog
        open={!!tagsDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'EditTag' })}</DialogTitle>
        <div className="tag-input client-page-edit-tag">
          {/* If there is tag, display it */}
          {tags && (
            <div className="tagsClass react-tags-wrapper">
              <div className="ReactTags__selected">
                {tags.map(value => {
                  return (
                    <span
                      key={value.text}
                      className="tag-wrapper ReactTags__tag"
                      draggable="true"
                      style={{ color: getUsedTagColor(usedTags, value) }}
                    >
                      {value.text}
                      <span className="ReactTags__remove" onClick={e => this.handleDelete(value.text)}>
                        ×
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
          <div className="modal-text tag-msg">{tagMsg}</div>
          <Tag
            intl={intl}
            labelField={'name'}
            tag={importTag}
            placeholder={intl.formatMessage({
              id: 'Add new Tag'
            })}
            displayInput="displayInput"
            handleAddition={tag => this.addTagIntoEdit(tag)}
            handleInputBlur={tag => this.handleInputBlur(tag)}
          />
          <div className="modal-text">{intl.formatMessage({ id: 'UsedTag' })}</div>

          <div className="suggest-area">
            <div className="tagsClass react-tags-wrapper">
              <div className="ReactTags__selected">
                {usedTags &&
                  usedTags.map(value => {
                    let usedTag = {
                      text: value.text,
                      color: value.color
                    };
                    return (
                      <span className="link" key={value.text || ''} onClick={() => this.addTagIntoEdit(usedTag)}>
                        <span className="tag-wrapper ReactTags__tag-no-remove" style={{ color: value.color }}>
                          {value.text}
                        </span>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <DialogActions>
          <Button onClick={this.close} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    tagsDialog: state.control.tagsDialog,
    isTeamMember: (state.team.teams || []).length > 0
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(TagsDialog);

export default injectIntl(container);
