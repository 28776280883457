import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SlideTransition } from '../../../../../components/Transition/Transition';

export default function JoinRoomModal(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { intl, open, onClose, handleChange, joinRoom } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="responsive-dialog-title">{intl.formatMessage({ id: 'Join Meeting' })}</DialogTitle>
        <DialogContent className="dialog-content-centered">
          <div className="caseclose-form">
            <div className="form-group">
              <div className="col-md-6 col-xs-6 text-right">
                <label className="control-label">{intl.formatMessage({ id: 'Meeting ID' })} :</label>
              </div>
              <div className="col-md-6 col-xs-6 text-left">
                <TextField id="roomID" name="roomID" onChange={handleChange} />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Close' })}
          </Button>
          <Button onClick={joinRoom} color="primary">
            {intl.formatMessage({ id: 'Join Meeting' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
