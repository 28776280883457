import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, ButtonGroup } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import _ from 'lodash';

const ButtonGroupStyle = { boxShadow: 'none', width: '100%' };
const CouponButtonStyle = { backgroundColor: '#ffd485', color: '#6b5f49', width: 'calc(100% - 56px)', border: 0 };
const CancelButtonStyle = { backgroundColor: '#f6b53d', color: '#fff', width: 56 };

const getFormattedCouponName = coupon => {
  const couponName = _.get(coupon, 'name');
  const referralCouponIndex = couponName.indexOf('Referral Coupon');
  if (referralCouponIndex > -1) {
    return couponName.substr(referralCouponIndex);
  } else {
    return couponName;
  }
};

const AppliedCoupon = props => {
  const { intl, coupon, onCancel, ...rest } = props;

  return (
    <ButtonGroup variant="contained" color="primary" style={ButtonGroupStyle} {...rest}>
      <Button disableElevation={true} disabled={true} style={CouponButtonStyle}>
        {intl.formatMessage(
          { id: 'coupon-applied' },
          {
            couponName: getFormattedCouponName(coupon),
            couponOff: coupon.percentageOff ? 'percentage' : 'amount',
            couponAmount:
              (intl.locale === 'en' ? coupon.percentageOff : (100 - coupon.percentageOff) / 10) ||
              coupon.amountOff / 100
          }
        )}
      </Button>
      <Button disableElevation={true} style={CancelButtonStyle} onClick={onCancel}>
        <ClearIcon />
      </Button>
    </ButtonGroup>
  );
};

export default injectIntl(AppliedCoupon);
