import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import { StripeElementWrapper } from '../../StripeElement/StripeElementWrapper';
import './PaymentChannels.scss';
import PaymentInfo from '../PaymentInfo/PaymentInfo';
import { TotalAmountCard } from '../TotalAmountView/TotalAmountCard/TotalAmountCard';
import SubscriptionPeriodCard from './SubscriptionPeriodCard/SubscriptionPeriodCard';
import { connect } from 'react-redux';
import { getSubscriptionPlanExpiryDate } from '../../../utils/user-subscription';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import YellowHint from '../../YellowHint/YellowHint';
import SubscriptionExtendedBanner from '../SubscriptionExtendedBanner/SubscriptionExtendedBanner';
import { setupPaymentMethodApi, submitPaymentApi, createSubscriptionApi } from '../../../store/stripe/api';

const PaymentChannels = props => {
  const {
    intl,
    history,
    userDetails,
    stripePromise,
    userSubscription,
    style,
    paymentChannel,
    subscriptionPlan,
    coupon,
    couponValue,
    onChange,
    completePay,
    onErr
  } = props;

  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);

  let formattedAmount = subscriptionPlan.isTrial
    ? subscriptionPlan.trialAmount
    : subscriptionPlan
      ? subscriptionPlan.amount
      : 0;
  let finalAmount = coupon && couponValue ? formattedAmount - couponValue : formattedAmount;
  let { startDate, expiryDate, freeTrialRemainingSeconds, days } = getSubscriptionPlanExpiryDate(userSubscription, subscriptionPlan);
  let shouldOthersDisabled = subscriptionPlan && subscriptionPlan.interval !== 'year';

  async function setupPaymentMethod(metadata) {
    setLoading(true);
    let result = await setupPaymentMethodApi(metadata);
    setClientSecret(result.clientSecret);
    setLoading(false);
  }

  async function submitPayment(amount, description, shortDescription, metadata, authorization) {
    setLoading(true);
    let result = await submitPaymentApi(amount, description, shortDescription, metadata, authorization);
    setClientSecret(result.clientSecret);
    setLoading(false);
  }

  async function createSubscription(priceId, cancelAtPeriodEnd) {
    setLoading(true);
    let result = await createSubscriptionApi(priceId, cancelAtPeriodEnd, coupon?.couponId);
    setClientSecret(result.clientSecret);
    setLoading(false);
  }

  useEffect(() => {
    if (subscriptionPlan && subscriptionPlan.stripePlanId) {
      if (subscriptionPlan.isTrial) {
        // Paid subscription (with trial)
        if (subscriptionPlan.trialInterval === "day") {
          var trialPeriodDays = subscriptionPlan.trialIntervalCount;
        } else if (subscriptionPlan.trialInterval === "week") {
          var trialPeriodDays = subscriptionPlan.trialIntervalCount * 7;
        } else if (subscriptionPlan.trialInterval === "month") {
          var trialPeriodDays = subscriptionPlan.trialIntervalCount * 31;
        } else if (subscriptionPlan.trialInterval === "year") {
          var trialPeriodDays = subscriptionPlan.trialIntervalCount * 365;
        }
        let metadata = {
          type: "create_subscription",
          planId: subscriptionPlan._id,
          priceId: subscriptionPlan.stripePlanId,
          cancelAtPeriodEnd: false,
          trialPeriodDays: trialPeriodDays
        };
        if (subscriptionPlan.trialAmount <= 0) {
          // Paid subscription with free trial
          setupPaymentMethod(metadata);
        } else {
          // Paid subscription with paid trial
          submitPayment(finalAmount, 'PORTFO+ TRIAL', 'TRIAL', metadata, false);
        }
      } else if (subscriptionPlan.isAuthorization) {
        // Paid subscription (without trial) (with authorization)
        let metadata = {
          type: "create_subscription",
          planId: subscriptionPlan._id,
          priceId: subscriptionPlan.stripePlanId,
          couponId: coupon ? coupon.couponId : undefined,
          cancelAtPeriodEnd: false,
          trialPeriodDays: 0
        };
        // Paid subscription with paid trial
        submitPayment(finalAmount, 'PORTFO+ SUBSCRIPTION', 'SUBSCRIPTION', metadata, true);
      } else if (subscriptionPlan.isFreeTrial) {
        // Free subscription
        createSubscription(subscriptionPlan.stripePlanId, true);
      } else {
        // Paid subscription (manually payment) or paid subscription (without trial)
        createSubscription(subscriptionPlan.stripePlanId, false);
      }
    }
  }, [finalAmount, subscriptionPlan]);

  return (
    <div className="payment-channels">
      <SubscriptionExtendedBanner freeTrialRemainingSeconds={freeTrialRemainingSeconds} />

      <Typography variant="h4" component="h4" align="center" paragraph={true}>
        {intl.formatMessage({ id: 'Payment' })}
      </Typography>

      <TotalAmountCard intl={intl} amount={finalAmount} />

      {subscriptionPlan && subscriptionPlan.isTrial && subscriptionPlan.amount > 0 && (
        <YellowHint>
          <Typography color="textSecondary">{intl.formatMessage({ id: 'pTrialInfo' })}</Typography>
        </YellowHint>
      )}

      <SubscriptionPeriodCard
        subscriptionPlan={subscriptionPlan}
        startDate={startDate}
        expiryDate={expiryDate}
        freeTrialRemainingSeconds={freeTrialRemainingSeconds}
        days={days}
      />

      {subscriptionPlan.isFreeTrial && (
        <Button className="action" variant="contained" color="primary" fullWidth={true} onClick={completePay}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      )}

      {!subscriptionPlan.isFreeTrial && (
        <div className="payment-method-wrapper" style={style}>
          {!subscriptionPlan.isTrial && (
            <Grid className="button-options fx" container spacing={3} alignContent="center">
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Button
                  className={`${paymentChannel === 'stripe' ? 'selected' : ''}`}
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={() => onChange('stripe')}
                >
                  {intl.formatMessage({ id: 'credit-card' })}
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <Button
                  className={`${paymentChannel === 'others' ? 'selected' : ''}`}
                  disabled={shouldOthersDisabled}
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  onClick={() => onChange('others')}
                >
                  {intl.formatMessage({ id: 'others' })}
                </Button>
              </Grid>
            </Grid>
          )}

          {paymentChannel === 'stripe' && loading &&
            <div style={{ textAlign: 'center', margin: 10 }}>
              <CircularProgress />
            </div>
          }

          {paymentChannel === 'stripe' && clientSecret && !loading && (
            <div>
              <div className="stripe-hint" style={{ background: '#ebf5ff', marginTop: 10, marginBottom: 10 }}>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <div className="icon">
                      <AutorenewIcon />
                    </div>
                  </Grid>
                  <Grid item xs={10} sn={11}>
                    <div className="text">
                      {intl.formatMessage({ id: !subscriptionPlan.isTrial ? 'billing-agreement' : 'billing-agreement-trial' })}
                    </div>
                  </Grid>
                </Grid>
              </div>

              <StripeElementWrapper
                intl={intl}
                stripePromise={stripePromise}
                clientSecret={clientSecret}
                userDetails={userDetails}
                buttonText={(subscriptionPlan.isTrial && subscriptionPlan.trialAmount <= 0) || subscriptionPlan.amount <= 0 ?
                  intl.formatMessage({ id: "start-trial" }) :
                  intl.formatMessage({ id: "pay" })
                }
                isSetup={(subscriptionPlan.isTrial && subscriptionPlan.trialAmount <= 0) || subscriptionPlan.amount <= 0}
                isRecurring={true}
                paymentDescription={intl.formatMessage({ id: "portfoplus-subscription" })}
                billingAgreement={intl.formatMessage({ id: subscriptionPlan.isTrial ? 'billing-agreement-trial' : 'billing-agreement' })}
                recurringAmount={coupon && couponValue ? subscriptionPlan.amount - couponValue : subscriptionPlan.amount}
                recurringLabel={intl.formatMessage({ id: "subscription" })}
                recurringStartDate={subscriptionPlan.isTrial ? new Date(expiryDate) : new Date(startDate)}
                recurringIntervalUnit={subscriptionPlan.interval === "week" ? "day" : subscriptionPlan.interval}
                recurringIntervalCount={subscriptionPlan.interval === "week" ? subscriptionPlan.intervalCount * 7 : subscriptionPlan.intervalCount}
                trialAmount={subscriptionPlan.isTrial ? subscriptionPlan.trialAmount : 0}
                trialLabel={intl.formatMessage({ id: "trial" })}
                trialStartDate={subscriptionPlan.isTrial ? new Date(startDate) : null}
                trialEndDate={subscriptionPlan.isTrial ? new Date(expiryDate) : null}
                trialIntervalUnit={subscriptionPlan.isTrial ? subscriptionPlan.trialInterval === "week" ? "day" : subscriptionPlan.trialInterval : null}
                trialIntervalCount={subscriptionPlan.isTrial ? subscriptionPlan.trialInterval === "week" ? subscriptionPlan.trialIntervalCount * 7 : subscriptionPlan.trialIntervalCount : null}
                completePay={completePay}
                onErr={onErr}
              />
            </div>
          )}

          {paymentChannel === 'others' && (
            <div>
              <PaymentInfo
                intl={intl}
                subscriptionPlan={subscriptionPlan}
                coupon={coupon}
                couponValue={couponValue}
                customButton={
                  <Button
                    className="action"
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={() => history.push('/login')}
                  >
                    {intl.formatMessage({ id: 'Login' })}
                  </Button>
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const container = connect(
  state => ({
    userSubscription: state.userSubscription.userSubscription
  }),
  {}
)(PaymentChannels);

export default injectIntl(withRouter(container));
