import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { toggleDialog } from '../../../store/control/duck';
import { getClientConnectionStatus, getClientTracking } from '../utils';
import _ from 'lodash';
import { deleteClient, enableTracking } from '../../../store/client/duck';
import { sanitize } from 'dompurify';

const DeleteClientDialog = props => {
  const { intl, userDetails, deleteClientDialog, toggleDialog, enableTracking, deleteClient } = props;

  const { client, closed } = deleteClientDialog || {};

  const close = () => toggleDialog('deleteClient', { ...deleteClientDialog, closed: true });
  const onClosed = () => toggleDialog('deleteClient', false);

  if (!client) {
    return null;
  }

  const isInactive = getClientConnectionStatus(client) === 'Inactive';
  const tracking = getClientTracking(client, userDetails);
  const isTrackingActive = _.get(tracking, 'isActive', false);

  const confirm = () => {
    const clientId = _.get(client, '_id', '');

    // if there is tracking, delete it too
    if (isTrackingActive) {
      enableTracking({
        trackingId: _.get(tracking, '_id', ''),
        clientId: clientId,
        trackingType: 'delete'
      });
    }

    deleteClient({
      clientId: clientId,
      factsheetId: _.get(client, 'factsheetId._id', '')
    });

    close();
  };

  return (
    <Dialog
      className="delete-client-dialog"
      open={!!deleteClientDialog && !closed}
      onClose={close}
      onExited={onClosed}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(intl.formatMessage({ id: isInactive ? 'delete_active_user_message' : 'delete_all_data' }))
          }}
        />

        {isTrackingActive && (
          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'delete_the_tracking' }) }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={confirm} color="primary" autoFocus>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    deleteClientDialog: state.control.deleteClientDialog
  }),
  {
    toggleDialog,
    enableTracking,
    deleteClient
  }
)(DeleteClientDialog);

export default injectIntl(container);
