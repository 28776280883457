import { decimalToString } from './formatNumber';
import _ from 'lodash';

export const PercentageTooltipLabeler = (tooltipItem, data) => {
  if (tooltipItem.datasetIndex) {
    const labels = data.datasets[tooltipItem.datasetIndex].labels;
    var dataset = data.datasets[tooltipItem.datasetIndex];
    var total = dataset.data.reduce(function(previousValue, currentValue) {
      return previousValue + currentValue;
    });
    var currentValue = dataset.data[tooltipItem.index];
    var percentage = currentValue !== 0.1 && total !== 0.1 && total > 0 ? ((currentValue / total) * 100).toFixed(2) : 0;

    return labels[tooltipItem.index] + ': ' + decimalToString(currentValue) + '(' + percentage + '%)';
  } else {
    const labels = data.datasets[tooltipItem.datasetIndex].labels;

    dataset = data.datasets[tooltipItem.datasetIndex];
    total = dataset.data.reduce(function(previousValue, currentValue) {
      return previousValue + currentValue;
    });
    currentValue = dataset.data[tooltipItem.index];
    percentage = currentValue !== 0.1 && total !== 0.1 && total > 0 ? ((currentValue / total) * 100).toFixed(2) : 0;
    return labels[tooltipItem.index] + ': ' + decimalToString(currentValue) + '(' + percentage + '%)';
  }
};

export const RoundedEdgePlugin = [
  {
    afterUpdate: function(chart) {
      if (chart.config.options.plugins.roundedEdge !== undefined && chart.config.data && chart.config.data.datasets) {
        let a = Math.max(chart.config.data.datasets.length - 1.5, 0);
        const updateMeta = chartElement => {
          if (chartElement && chartElement._model) {
            chartElement.round = {
              x: (chart.chartArea.left + chart.chartArea.right) / 2,
              y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
              radius: chart.innerRadius + chart.radiusLength / 2 + a * chart.radiusLength,
              thickness: Math.abs(chart.radiusLength / 2 - 1),
              backgroundColor: chartElement._model.backgroundColor
            };
          }
        };

        for (let i in chart.config.data.datasets) {
          chart.getDatasetMeta(i).data.forEach(updateMeta);
          a--;
        }
      }
    },
    afterDatasetsDraw: function(chart) {
      if (chart.config.options.plugins.roundedEdge && chart.config.data && chart.config.data.datasets) {
        let ctx = chart.chart.ctx;

        const drawEdge = (chartElement, angle) => {
          ctx.save();
          ctx.translate(chartElement.round.x, chartElement.round.y);
          ctx.fillStyle = chartElement.round.backgroundColor;
          ctx.beginPath();
          ctx.arc(
            chartElement.round.radius * Math.sin(angle),
            chartElement.round.radius * Math.cos(angle),
            chartElement.round.thickness,
            0,
            2 * Math.PI
          );
          ctx.closePath();
          ctx.fill();
          ctx.restore();
        };

        for (let i in chart.config.data.datasets) {
          chart.getDatasetMeta(i).data.forEach((chartElement, index) => {
            if (chartElement) {
              if (index === 0) {
                drawEdge(chartElement, Math.PI / 2 - chartElement._view.startAngle);
              }

              drawEdge(chartElement, Math.PI / 2 - chartElement._view.endAngle);
            }
          });
        }
      }
    }
  }
];

export const customLegendCallback = className => chart => {
  const dataset = _.get(chart, 'data.datasets[0]', []);
  const labels = _.get(chart, 'data.labels', []);
  let html = `<div class="custom-chart-legend ${className || ''}">`;
  for (let i = 0; i < dataset.data.length; i++) {
    if (labels[i]) {
      html += `<div class="item">
                  <div class="icon" style="background-color: ${dataset.backgroundColor[i]};"></div>
                  <div class="text">${labels[i]}</div>
              </div>`;
    }
  }
  html += '</div>';
  return html;
};
