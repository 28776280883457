import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import {
  FormControl,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  IconButton
  // Grid
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import '../../../Client/styles.scss';
import ClientList from './ClientList';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { addSearch } from '../../../../../utils/router';
import withRouter from 'react-router/withRouter';
import { updateAssistantListDoneEntries } from '../../../../../store/assistant/duck';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../../../store/control/duck';
import _ from 'lodash';
import {
  getClientEmail,
  getPolicyDueGreetingMessage,
  toggleBirthdayGreetingDialog,
  toggleMeetingNoteDialog,
  togglePaidUpPoliciesGreetingDialog
} from '../../../../../components/ClientEntry/utils';
// import moment from 'moment';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import { isSubscriptionAllowFromStore } from '../../../../../utils/user-subscription-store';
import './AssistantCategory.scss';
import Button from '@material-ui/core/Button';
import { TRACKING_MAX_DAYS } from '../../../../../constants/tracking';
import Grid from '@material-ui/core/Grid';
import { tracking } from '../../../../../utils/tracking';

const LIST_IMAGES = {
  'policy-due': 'img/Renewal.png',
  'remind-me': 'img/reminder.png',
  birthday: 'img/Birthday-cake.png',
  'paid-up-policy': 'img/Payup.png'
};
const LIST_NAME_INTL = {
  'policy-due': 'Renewal',
  'remind-me': 'Remind me',
  birthday: 'Birthday',
  'paid-up-policy': 'Paid-up policy'
};
const getCategoryUndoneIntl = (intl, category) => {
  if (category.name === 'to-do') {
    return intl.formatMessage({ id: 'ast-cat-to-do' });
  } else if (category.name.indexOf('tracking-stage-') > -1) {
    const maxDay = _.get(category, 'lists[0].extras.maxDay');
    return intl.formatMessage(
      { id: maxDay ? 'ast-tracking-undone' : 'ast-tracking-undone-min-only' },
      category.lists[0].extras
    );
  } else {
    return '';
  }
};
export const CATEGORY_DESCRIPTION_INTL = {
  'to-do': 'ast-cat-des-to-do',
  'tracking-stage-1': 'ast-cat-des-tracking-stage-1',
  'tracking-stage-2': 'ast-cat-des-tracking-stage-2',
  'tracking-stage-3': 'ast-cat-des-tracking-stage-3',
  'tracking-stage-4': 'ast-cat-des-tracking-stage-4'
};
const ListExpansion = ({ summaryPrepend, title, expanded, onChange, entries, props, noExpansion, titlePrepend }) => {
  let newProps = { ...props };
  newProps.expanded = expanded;
  return !noExpansion ? (
    <ExpansionPanel className="nobord margin-5 boxshadowwrapper__done" expanded={expanded} onChange={onChange}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        style={{ paddingLeft: '15px' }}
      >
        {summaryPrepend && summaryPrepend}
        <FormControl>
          <div className="all__icons--wrapper" id="my-helper-text">
            <Grid container className="centerpull" alignItems="center" justify="center">
              {titlePrepend && (
                <Grid item style={{ height: 24 }}>
                  {titlePrepend}
                </Grid>
              )}

              <Grid item>
                <span className="title__text">{title}</span>: {entries.length}
              </Grid>
            </Grid>
          </div>
        </FormControl>
      </ExpansionPanelSummary>
      <ClientList entries={entries} {...newProps} />
    </ExpansionPanel>
  ) : (
    <ClientList entries={entries} {...props} />
  );
};

class AssistantCategory extends React.Component {
  constructor(props) {
    super(props);

    const stateObj = {
      openMoreInfoModal: false,
      toDoListsExpanded: [],
      doneListsExpanded: []
    };

    const { location } = props;

    // do not pre-expand the category & list when the user only has lite subscription
    if (isSubscriptionAllowFromStore({ type: 'core', nicknames: ['Pro', 'Ultra'] })) {
      if (location && location.search) {
        const searchParams = new URLSearchParams(location.search);
        const toDoListsExpandedSearch = searchParams.get('toDoListsExpanded');
        const doneListsExpandedSearch = searchParams.get('doneListsExpanded');
        if (toDoListsExpandedSearch) {
          stateObj.toDoListsExpanded = toDoListsExpandedSearch.split(',');
        }
        if (doneListsExpandedSearch) {
          stateObj.toDoListsExpanded = doneListsExpandedSearch.split(',');
        }
      }
    }

    this.state = stateObj;
  }

  updateDone = (client, categoryName, listName, isDone) => {
    const { updateAssistantListDoneEntries } = this.props;
    // Tracking
    tracking(
      `Assistant Accessed: ${isDone ? 'checked' : 'unchecked'} client on category ${categoryName} on list ${listName}`
    );
    updateAssistantListDoneEntries(categoryName, listName, { _id: client._id, doneAt: new Date() }, isDone);
  };

  toggleList = (listName, done, expanded) => {
    const targetList = !done ? 'toDoListsExpanded' : 'doneListsExpanded';
    const set = new Set(this.state[targetList]);
    if (expanded) {
      set.add(listName);
      // Tracking
      tracking(`Assistant Accessed: Expand ${listName}`);
    } else {
      set.delete(listName);
    }
    const array = [...set];
    this.setState({ [targetList]: array }, () => {
      const { history } = this.props;
      addSearch(history, { [targetList]: array });
    });
  };

  clientAction(client, listName) {
    const { intl, policyDueData, toggleDialog } = this.props;
    if (listName === 'birthday') {
      toggleBirthdayGreetingDialog(toggleDialog, intl, client);
    } else if (listName === 'policy-due') {
      toggleDialog('greeting', {
        title: intl.formatMessage({ id: 'Client Reminder' }),
        phone: _.get(client, 'factsheetId.phoneNumber'),
        whatsapp: _.get(client, 'factsheetId.whatsapp'),
        email: getClientEmail(client),
        getMessage: language => getPolicyDueGreetingMessage(client, policyDueData, language)
      });
    } else if (listName === 'remind-me') {
      toggleMeetingNoteDialog(toggleDialog, client);
    } else if (listName === 'paid-up-policy') {
      togglePaidUpPoliciesGreetingDialog(toggleDialog, intl, client);
    }
  }

  render() {
    const { intl, history, category, toggleDialog } = this.props;
    const { toDoListsExpanded, doneListsExpanded } = this.state;

    if (!category) return null;

    const lists = category.lists;

    return (
      <div className="assistant-category-wrapper">
        <div>
          <div className="box-shadow-wrapper">
            <h4 className="main_heading">
              <div>
                <span>{getCategoryUndoneIntl(intl, category)} </span>
                <HelpOutlineIcon
                  className="link"
                  onClick={() => {
                    // Tracking
                    tracking(`Advisor Assistant: Edit Hint for ${getCategoryUndoneIntl(intl, category)}`);
                    toggleDialog('assistantHint', { categoryName: category.name });
                  }}
                  style={{ verticalAlign: 'sub' }}
                />
              </div>
              {category.name.indexOf('tracking-stage') > -1 && (
                <IconButton
                  onClick={() => toggleDialog('assistantCategoryCustomize', { category: category })}
                  style={{ position: 'absolute', right: '-12px' }}
                >
                  <CreateRoundedIcon />
                </IconButton>
              )}
              {category.name.indexOf('tracking-stage-4') > -1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ position: 'absolute', right: '36px', padding: 0, fontSize: '80%' }}
                  onClick={() => history.push({ pathname: '/client', select: 'longPendingCases' })}
                >
                  >{TRACKING_MAX_DAYS}
                  {intl.formatMessage({ id: 'Days' })}
                </Button>
              )}
            </h4>
            <h3 className="main_subheading nodatafoundhead">
              {category.description
                ? category.description
                : intl.formatMessage({ id: CATEGORY_DESCRIPTION_INTL[category.name] })}
            </h3>

            {lists
              .filter(list => !!list.data)
              .map(list => {
                const titleIntl = _.get(LIST_NAME_INTL, list.name, undefined);
                return (
                  <ListExpansion
                    key={list.name}
                    entries={list.data.filter(entry => !entry.isDone)}
                    props={{
                      handleDoneChange: client => this.updateDone(client, category.name, list.name, false),
                      listName: list.name,
                      isDone: false,
                      category: category.name,
                      actionImage: list.type === 'remind-me' ? 'img/Notepad-alarm.png' : undefined,
                      onAction:
                        ['birthday', 'policy-due', 'remind-me', 'paid-up-policy'].findIndex(
                          actionListName => actionListName === list.name
                        ) > -1
                          ? client => this.clientAction(client, list.name)
                          : undefined
                    }}
                    noExpansion={lists.length <= 1}
                    expanded={!!toDoListsExpanded.find(listName => listName === list.name)}
                    onChange={(event, expanded) => this.toggleList(list.name, false, expanded)}
                    summaryPrepend={
                      list.name === 'birthday' && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={event => {
                            event.stopPropagation();
                            history.push({
                              pathname: '/client',
                              select: 'dateBackBirthdayCount'
                            });
                          }}
                          style={{
                            position: 'absolute',
                            left: 8,
                            top: '50%',
                            transform: 'translate(0, -50%)',
                            padding: '3px 4px',
                            minWidth: 40,
                            lineHeight: 1,
                            zIndex: 1
                          }}
                        >
                          {intl.formatMessage({ id: 'BigD-Date-Back' })}
                        </Button>
                      )
                    }
                    titlePrepend={<img alt="" className="todoImages" src={_.get(LIST_IMAGES, list.name, '')} />}
                    title={titleIntl ? intl.formatMessage({ id: titleIntl }) : ''}
                  />
                );
              })}
          </div>
          <div className="box-shadow-wrapper">
            <h2 className="main_heading--done">
              <Grid container alignItems="center">
                <Grid item>{intl.formatMessage({ id: 'Done' })}</Grid>
                <Grid item style={{ height: 24, marginLeft: 8 }}>
                  <CheckBoxIcon color="primary" />
                </Grid>
              </Grid>
            </h2>
            {lists.map(list => (
              <ListExpansion
                key={list.name}
                expanded={!!doneListsExpanded.find(listName => listName === list.name)}
                onChange={(event, expanded) => this.toggleList(list.name, true, expanded)}
                title={intl.formatMessage({ id: lists.length <= 1 ? 'Count' : LIST_NAME_INTL[list.name] })}
                entries={list.data.filter(entry => entry.isDone)}
                props={{
                  handleDoneChange: client => this.updateDone(client, category.name, list.name, true),
                  listName: list.name,
                  isDone: true,
                  category: category.name
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    policyDueData: state.adviserDashboard.policyDueData
  }),
  // Map actions to props
  {
    updateAssistantListDoneEntries,
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(withRouter(AssistantCategory));

export default injectIntl(container);
