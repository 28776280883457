import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { deleteAllTemplate } from '../../../store/template/duck';
import { SlideTransition } from '../../Transition/Transition';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

class TemplateDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      localSubmit: false
    };
  }

  onConfirm = () => {
    this.setState({ localSubmit: true });
    this.props.deleteAllTemplate({});
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showDeleteModal && !this.props.showDeleteModal) {
      this.setState({ localSubmit: false });
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.showDeleteModal}
        onClose={this.props.onModalClose}
        keepMounted
        TransitionComponent={SlideTransition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{this.props.intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
        <DialogContent>{this.props.intl.formatMessage({ id: 'delete-all-template-question' })}</DialogContent>
        <DialogActions>
          <Button disabled={this.state.localSubmit} onClick={this.props.onModalClose} color="primary">
            {this.props.intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button disabled={this.state.localSubmit} onClick={this.onConfirm} color="primary">
            {this.props.intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  { deleteAllTemplate },
  // mergeProps
  null,
  // options
  {}
)(TemplateDialog);

export default injectIntl(container);
