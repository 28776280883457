import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import './ImageIconButton.scss';

const ImageIconButton = props => {
  const { image, text, extra, ...rest } = props;
  return (
    <Button className="image-icon-button" color="primary" fullWidth={true} {...rest}>
      <Grid container direction="column" spacing={1}>
        <Grid item>{image}</Grid>
        <Grid item>{typeof text === 'string' ? <Typography color="textPrimary">{text}</Typography> : text}</Grid>
      </Grid>
      {extra && extra}
    </Button>
  );
};

export default ImageIconButton;
