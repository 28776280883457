import React, { useState } from 'react';
import { Checkbox, Typography, FormControlLabel, TextField, FormControl, FormGroup, Button } from '@material-ui/core';



export default function RequestDeletionView(props) {
    const { intl, onSubmitForm, err } = props;
    const [email, setEmail] = useState('');
    const [survey, setSurvey] = useState([]);


    const onSetSurvey = (e) => {
        const exist = survey.find((i) => i === e.target.value)
        // console.log('survey: ', e.target.value, ' is exist ? ', exist)
        if (exist) {
            setSurvey(survey.filter((i) => i !== e.target.value))
        }
        else {
            setSurvey([...survey, e.target.value])
        }
    }

    return (
        <div>
            <h4>{intl.formatMessage({ id: 'account-deletion-page-title' })}</h4>
            <div>
                <Typography>{intl.formatMessage({ id: 'account-deletion-page-tnc' })}</Typography>
            </div>
            <br />
            <FormGroup>
                <Typography variant="h6" gutterBottom> {intl.formatMessage({ id: 'account-deletion-survey-title' })} </Typography>
                <div>
                    {intl.formatMessage({ id: 'account-deletion-survey-options' }).split(',').map((item, index) => {
                        return <div key={'account-deletion-survey-checkbox-' + index}>
                            <FormControlLabel
                                checked={survey.find((i) => i === item) != undefined}
                                value={item}
                                onChange={onSetSurvey}
                                control={<Checkbox />} label={item} />
                        </div>
                    })}
                </div>
            </FormGroup>
            <br />

            <div className="myForm referral-form">
                <FormControl>
                    <TextField
                        onChange={(e) => { setEmail(e.target.value) }} id="standard-basic"
                        label={intl.formatMessage({ id: "account-deletion-email-comfirm" })} />
                    <i className="bar" />
                    {err?.email ? (
                        <span className="error_field">{intl.formatMessage({ id: err?.email })}</span>
                    ) : ('')}
                </FormControl>

                <div style={{ marginTop: '50px', width: '100%' }}>
                    <Button
                        variant="contained"
                        disabled={props.loading}
                        onClick={(e) => {
                            // console.log('request view email: ', email)
                            onSubmitForm(email, survey)

                        }}
                        style={{ width: '100%', backgroundColor: 'red', color: 'white' }}
                    >
                        {intl.formatMessage({ id: 'account-deletion-alert-cta-submit' })}
                    </Button>
                </div>
            </div>
        </div>)
}