import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Card, CardContent, FormControlLabel, Grid, Switch, Typography, IconButton, Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';
import CurrencyInputDialog from './CurrencyInputDialog/CurrencyInputDialog';
import { decimalToString } from '../../../../../utils/formatNumber';

const CurrencyInputPanel = props => {
  const { intl, enabled, onToggle, currency, onChange, template } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const onCloseDialog = currency => {
    if (currency !== undefined) {
      onChange(currency);
    }
    setDialogOpen(false);
  };

  return (
    <Fragment>
      <CurrencyInputDialog currency={currency} open={dialogOpen} onClose={onCloseDialog} />

      <Card className="pfa-card pf-card-container pf-currency-input-panel">
        <CardContent style={{ paddingTop: 8, background: enabled ? '#ffe6e6' : '#fffbf5' }}>
          <Grid container direction="column">
            <Grid item>
              <Grid container alignItems="center" justify="space-between" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch checked={!!enabled} onChange={onToggle} name="isCurrencyEnabled" color="primary" />
                    }
                    label={
                      enabled ? (
                        <Grid container alignItems="center" style={{ lineHeight: 1 }}>
                          <Grid item>
                            <LockIcon color="error" />
                          </Grid>
                          <Grid item>
                            <Typography color="textSecondary" style={{ fontSize: 14 }}>
                              {intl.formatMessage(
                                { id: 'pf-currency-enabled-heading' },
                                { currency: decimalToString(currency, 3) }
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container alignItems="center" spacing={1} style={{ lineHeight: 1 }}>
                          <Grid item>
                            <div
                              style={{
                                border: '1px solid #ffc000',
                                color: '#ffc000',
                                padding: 1,
                                borderRadius: 3,
                                fontSize: 12
                              }}
                            >
                              COT
                            </div>
                          </Grid>
                          <Grid item>
                            <Typography color="textSecondary" style={{ fontSize: 14 }}>
                              {intl.formatMessage({ id: 'pf-currency-disabled-heading' })}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    }
                    style={{ marginBottom: 0 }}
                  />
                </Grid>
                {!template && (
                  <Grid item>
                    <IconButton size="small" onClick={openDialog}>
                      <EditIcon style={{ fontSize: 16 }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default injectIntl(CurrencyInputPanel);
