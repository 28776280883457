import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import store from '../../store';
import { toggleDialog } from '../../store/control/duck';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { tryTranslate } from '../locale';

export const isGeolocationable = () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return navigator && !!navigator.geolocation;
  } else {
    return true;
  }
};

export const checkGeolocationPermission = async (onNotDenied, onDenied) => {
  try {
    const permissionsStatus = await Geolocation.checkPermissions();

    if (permissionsStatus.location === 'denied') {
      store.dispatch(
        toggleDialog('permissionDenied', {
          intlId: 'geolocation-denied-msg',
          onClose: () => {
            OpenNativeSettings.open('application_details')
              .then()
              .catch();
          }
        })
      );
      if (onDenied) {
        const intl = getGlobalIntl();
        await onDenied({ message: tryTranslate(intl, 'permission-denied') });
      }
    } else {
      if (permissionsStatus.location === 'prompt-with-rationale' || permissionsStatus.location === 'prompt') {
        store.dispatch(
          toggleDialog('permission', {
            intlId: 'geolocation-required-msg',
            onClose: onNotDenied
          })
        );
      } else {
        await onNotDenied();
      }
    }
  } catch (error) {
    if (onDenied) {
      await onDenied(error);
    }
  }
};

export const getCurrentPosition = () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    if (!navigator.geolocation) {
      const intl = getGlobalIntl();
      return Promise.reject({ error: { message: tryTranslate(intl, 'geolocation-browser-unsupported') } });
    } else {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve(position);
          },
          error => {
            const intl = getGlobalIntl();
            reject(error || { message: tryTranslate(intl, 'geolocation-unable') });
          }
        );
      });
    }
  } else {
    return new Promise(async (resolve, reject) => {
      await checkGeolocationPermission(
        async () => {
          try {
            const position = await Geolocation.getCurrentPosition();
            resolve(position);
          } catch (error) {
            const intl = getGlobalIntl();
            reject({ message: tryTranslate(intl, 'geolocation-unable') });
          }
        },
        error => reject(error)
      );
    });
  }
};
