import React from 'react';
import { connect } from 'react-redux';
import MyEditorModal from '../../../../../../components/MyEditorModal/MyEditorModal';
import { updateUserDetails } from '../../../../../../store/user/duck';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import './TargetEditorModal.css';
import { getEditorDefaultMessage } from '../../../../../../utils/editor';
import _ from 'lodash';
import { android } from '../../../../../../utils/device';
import { toast } from 'react-toastify';
import IconToast from '../../../../../../components/NewToast';

const isAndroid = android();

class TargetEditorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { remindTarget: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ remindTarget: _.get(nextProps, 'remindTarget', false) });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, updatePhase } = this.props;
    if (updatePhase === true && prevProps.updatePhase !== updatePhase) {
      toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), { className: 'new-toast' });
    }
  }

  render() {
    const { intl, remindMe, open, loadingPhase, updatePhase, onClose, updateUserDetails } = this.props;
    const { remindTarget } = this.state;

    let formattedRemindMe = remindMe === undefined ? getEditorDefaultMessage(intl.locale, 'remind-me') : remindMe;

    return (
      <MyEditorModal
        className="target-editor-modal"
        intl={intl}
        originalState={remindMe}
        contentState={formattedRemindMe}
        open={open}
        onClose={onClose}
        loadingPhase={loadingPhase}
        updatePhase={updatePhase}
        headerText={intl.formatMessage({ id: 'Target' })}
        onUpdate={data => {
          updateUserDetails({
            remindTarget: remindTarget,
            remindMe: data
          });
        }}
        imageUsedIn={'adviser/remindMe'}
        extraHTML={
          <Grid container justify="flex-end" alignItems="flex-start" wrap="nowrap">
            {isAndroid && (
              <Grid item>
                <Typography color="textSecondary" style={{ fontSize: '75%' }}>
                  {intl.formatMessage({ id: 'target-editor-android-hint' })}
                </Typography>
              </Grid>
            )}
            <Grid item style={{ minWidth: 134, marginLeft: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="wel-check-box"
                    checked={!!remindTarget}
                    onChange={() => {
                      this.setState({ remindTarget: !remindTarget });
                    }}
                    color="primary"
                  />
                }
                label={intl.formatMessage({ id: 'Remind me when login' })}
                className="forFont"
                style={{ margin: 0 }}
              />
            </Grid>
          </Grid>
        }
        textAlign={true}
        image={true}
        list={true}
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    updatePhase: state.user.updatePhase
  }),
  // Map actions to props
  {
    updateUserDetails
  }
)(TargetEditorModal);

export default container;
