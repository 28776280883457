import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

function mapStateToProps(state) {
  const { lang, messages } = state.locale;
  return { locale: lang, key: lang, messages };
}

let globalIntl;

class MyIntlProvider extends IntlProvider {
  constructor(props) {
    super(props);
    globalIntl = this;
  }
}

export const getGlobalIntl = () => (globalIntl ? globalIntl.getChildContext().intl : undefined);
export default connect(mapStateToProps)(MyIntlProvider);
