import { getCurrentCoreSubscription } from './user-subscription';
import moment from 'moment';

export const shouldShowReferAdviserPage = (userSubscription, teamShortName) => {
  const currentCoreSubscription = getCurrentCoreSubscription(userSubscription);
  if (currentCoreSubscription) {
    const { startedAt } = currentCoreSubscription || {};

    if (startedAt) {
      const is3DaysAfterSubscriptionStart = moment(currentCoreSubscription.startedAt)
        .add(3, 'days')
        .isBefore(moment());

      if (teamShortName) {
        // console.log('teamShortName not null, return false')
        return false;
      } else if
        (!is3DaysAfterSubscriptionStart) {
        // console.log('within 3 days after subscription start')
        return false;
      } else {

        // console.log('is 3 days after subscription start')
        return true;
      }
    }
  }

  return false;
};
