import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Avatar, Button, Card, Chip, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { getWebImageUrl } from '../../../utils/image';
import { formatPhoneNumber } from '../../../utils/user';
import _ from 'lodash';
import './UserAccountDetailsCard.scss';
import { tracking } from '../../../utils/tracking';

const CardStyle = {
  padding: 16,
  boxShadow: 'var(--my-shadow)'
};

const GridItemStyle = {
  width: '100%'
};

const TitleStyle = {
  fontSize: '125%',
  fontWeight: 'bold'
};

const ManageButtonStyle = {
  padding: 0
};

const IconTextTypoStyle = {
  color: '#000',
  fontSize: 11,
  fontWeight: 'unset'
};

const IconText = props => {
  const { src, alt, text } = props;

  return (
    <Grid container justify="flex-start" alignItems="center" wrap="nowrap" spacing={1}>
      <Grid item>
        <img src={src} alt={alt} width={22} height={22} />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Typography style={IconTextTypoStyle}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

const UserAccountDetailsCard = props => {
  const { intl, history, userDetails } = props;

  const phone = _.get(userDetails, 'phoneNumber');
  const phone2 = _.get(userDetails, 'phoneNumber2');
  const whatsapp = _.get(userDetails, 'whatsapp');
  const wechat = _.get(userDetails, 'wechat');
  const phoneText = `${formatPhoneNumber(phone)}${phone && phone2 ? ' / ' : ''}${
    phone2 ? formatPhoneNumber(phone2) : ''
  }`;

  const manage = () => {
    tracking('My Profile: UserAccountDetailsCard Manage');
    history.push('/personalData');
  };

  return (
    <Card className="user-account-details-card" style={CardStyle}>
      <Grid container direction="column" alignItems="flex-start">
        <Grid item style={GridItemStyle}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography style={TitleStyle}>{intl.formatMessage({ id: 'Profile' })}</Typography>
            </Grid>
            <Grid item>
              <Button color="primary" variant="outlined" style={ManageButtonStyle} onClick={manage}>
                {intl.formatMessage({ id: 'manage-profile' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={GridItemStyle}>
          <IconText
            src={getWebImageUrl('img/phone.svg')}
            alt="phone"
            text={phoneText || intl.formatMessage({ id: 'not-entered' })}
          />
        </Grid>
        <Grid item style={GridItemStyle}>
          <IconText
            src={getWebImageUrl('img/whatsapp.svg')}
            alt="whatsapp"
            text={formatPhoneNumber(whatsapp) || intl.formatMessage({ id: 'not-entered' })}
          />
        </Grid>
        <Grid item style={GridItemStyle}>
          <IconText
            src={getWebImageUrl('img/wechat.svg')}
            alt="wechat"
            text={formatPhoneNumber(wechat) || intl.formatMessage({ id: 'not-entered' })}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(UserAccountDetailsCard);

export default injectIntl(withRouter(container));
