import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../store/control/duck';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import { tryTranslate } from '../../utils/locale';

const PermissionDeniedDialog = props => {
  const { intl, permissionDeniedDialog, toggleDialog } = props;

  const { intlId, onClose, closed } = permissionDeniedDialog || {};

  const close = () => {
    toggleDialog('permissionDenied', { ...permissionDeniedDialog, closed: true });
    if (onClose) {
      onClose();
    }
  };
  const onExited = () => toggleDialog('permissionDenied', false);

  return (
    <Dialog
      open={!!permissionDeniedDialog && !closed}
      TransitionProps={{
        onExited: onExited
      }}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>Permission Denied</DialogTitle>
      <DialogContent>{tryTranslate(intl, intlId)}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    permissionDeniedDialog: state.control.permissionDeniedDialog
  }),
  {
    toggleDialog
  }
)(PermissionDeniedDialog);

export default injectIntl(container);
