import React, { PureComponent } from 'react';
import MyTabs from '../../components/MyTabs/MyTabs';
import MoreInfoIconButton from '../../components/MoreInfoIconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AdviserBlastPopUp from '../../components/AdviserBlastPopUp/AdviserBlastPopUp';
import { isAdviserType, isUserConnected, isUserType } from '../../utils/user';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Dialog, DialogActions, Grid, Portal } from '@material-ui/core';
import { IconButton, Button } from '@material-ui/core';
import { SlideTransition } from '../../components/Transition/Transition';
import PreferenceHelpDialog from '../../components/PreferenceHelpDialog/PreferenceHelpDialog';
import SwipeableViews from 'react-swipeable-views';
import PortfolioCategory from './partials/PortfolioCategory/PortfolioCategory';
import { categorySorter, checkPolicy, isCategory } from '../../utils/policy';
import MyResizeObserver from '../../components/MyResizeObserver/MyResizeObserver';
import { LOADING } from '../../constants/phase';
import _ from 'lodash';
import ClientDashboardDrawer from '../../components/ClientDashboardDrawer/ClientDashboardDrawer';
import './Dashboard.scss';
import UserFirstGuidelineTooltip from '../../components/guidelines/UserFirstGuidelineTooltip/UserFirstGuidelineTooltip';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { tracking } from '../../utils/tracking';
import { TopNavigationPortal } from '../../components/topNavigation';
import AppContentContainer, {
  APP_CONTENT_CONTAINER_MAX_WIDTH
} from '../../components/AppContentContainer/AppContentContainer';
import { SHOW_SIDE_BAR_WIDTH } from '../App/component';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';

class DashboardComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      modalIsOpen: false,
      showAdviserBlast: false,
      connectedAdviser: false,
      showHelpModal: false,
      showGraphModal: false,
      width: window.innerWidth
    };
    //this.handleSwipe = this.handleSwipe.bind(this);
    this.runGuideline = this.runGuideline.bind(this);
  }

  UNSAFE_componentWillMount = async () => {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
    try {
      const {
        location,
        userDetails,
        loginFetched,
        userDashboardFetch,
        updateLoginFetched,
        updateVisitedDashboard,
        currentPortfolioId
      } = this.props;

      let currentPage = location.state && location.state.currentPage ? location.state.currentPage : 0;
      this.setState({ tab: currentPage });
      localStorage.removeItem('personalData');
      localStorage.removeItem('myDetails');

      if (!loginFetched || !isUserType(userDetails) || location.state.fetch) {
        userDashboardFetch(location, currentPortfolioId);
      } else {
        updateLoginFetched(false);
      }

      updateVisitedDashboard(true);

      let showAdviserBlast =
        location.state && location.state.showAdviserBlast ? location.state.showAdviserBlast : false;
      this.setState({ showAdviserBlast: showAdviserBlast });
      localStorage.removeItem('addRider');
      localStorage.removeItem('previousPolicyData');
    } catch (e) {
      return e;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userDetails } = nextProps;
    if (nextProps.connectedAdvisers) {
      let advisers = nextProps.connectedAdvisers;
      if (advisers.length > 0) {
        let userType = userDetails && userDetails.userType ? userDetails.userType : '';
        advisers.forEach(adviser => {
          if (userType === 'User' && userDetails.advisorDetails && userDetails.advisorDetails.length > 0) {
            userDetails.advisorDetails.forEach(val => {
              if (val.connection === true && val.email === adviser.email) {
                this.setState({ connectedAdviser: true });
              }
            });
          }
        });
      }
    }
  }

  //Handle the chart data to tab on personal , company and general
  handleTab(index) {
    this.setState({ tab: index });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { clientAllModalDone } = this.props;
    if (!nextState.modalIsOpen) {
      clientAllModalDone();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      const clientId = JSON.parse(window.localStorage.getItem('clientId'));
      if (clientId && this.props.location.state) this.props.renderHeaderButton(false);
      else this.props.renderHeaderButton(true);
    }
    if (!prevProps.guidelineIsStarted && this.props.guidelineIsStarted && this.state.tab !== 0) {
      this.setState({ tab: 0 });
    }
    if (
      !prevProps.firstGuidelineRan &&
      this.props.firstGuidelineRan &&
      !this.props.shownDashHelp &&
      _.get(this.props.userDetails, 'userType', '') === 'User' &&
      !isUserConnected(this.props.userDetails) &&
      !this.props.userDetails.hideHelpPopUp
    ) {
      this.setState(
        {
          showHelpModal: true
        },
        () => this.props.setShownDashHelp(true)
      );
    }
  }

  handleResize = () => {
    const width = window.innerWidth;
    this.setState({ width });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize, false);
    let clientId = JSON.parse(window.localStorage.getItem('clientId'));
    const {
      intl,
      userFirstGuideline,
      runGuideline,
      firstGuidelineRan,
      shownDashHelp,
      history,
      location,
      userDetails,
      toggleControl
    } = this.props;
    const locationState = _.get(location, 'state');
    const { loginRedirect } = locationState || {};

    if (clientId && this.props.location.state) this.props.renderHeaderButton(false);
    else this.props.renderHeaderButton(true);
    if (!_.isEmpty(userDetails) && userDetails.userType === 'User') {
      if (userDetails.firstLoginGuidelineDone !== undefined || !isUserConnected(userDetails)) {
        if (userFirstGuideline && !firstGuidelineRan) {
          runGuideline('userFirstLogin1', {
            parameters: {
              intl
            },
            showProgress: true,
            tooltipComponent: UserFirstGuidelineTooltip
          });
        } else if (!shownDashHelp && !userDetails.hideHelpPopUp && !isUserConnected(userDetails)) {
          this.setState(
            {
              showHelpModal: true
            },
            () => this.props.setShownDashHelp(true)
          );
        } else {
          if (loginRedirect) {
            toggleControl('addToHomeSnackbar', true);
          }
        }
      } else {
        if (loginRedirect) {
          toggleControl('addToHomeSnackbar', true);
        }
      }
    }

    if (loginRedirect) {
      setTimeout(() => {
        history.replace(_.omit(location, ['state.loginRedirect']));
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
  }

  runGuideline() {
    // Tracking
    tracking('User Function Accessed: Run Dashboard guideline from top navigation');
    this.setState(
      {
        tab: 0
      },
      () => {
        setTimeout(() => {
          this.props.runGuideline('userDashboard');
        }, 400);
      }
    );
  }

  markAndClose(data) {
    this.setState({ showAdviserBlast: false });
    if (data.receipients.ids.state === 'new') {
      this.props.markNotificationAsRead(data._id);
    }
  }

  clientMessages() {
    const { intl, connectedAdvisers, userDetails } = this.props;
    let whatsApp = connectedAdvisers ? connectedAdvisers[0].whatsapp : '';
    let phoneNoText = whatsApp ? 'phone=' + whatsApp.replace('+', '') : '';
    let url =
      'https://api.whatsapp.com/send?' + phoneNoText + `&text=${intl.formatMessage({ id: 'wts-promotion-message' })}`;
    var win = window.open(encodeURI(url), '_blank');
    if (win) win.focus();
    let factsheetId = '';
    for (let item of userDetails.advisorDetails) {
      if (item.connection && item.connectionVerified && item.email === connectedAdvisers[0].email) {
        factsheetId = item.factsheetId;
      }
    }
    let data = {
      factsheetId: factsheetId,
      notification: {
        sender: {
          id: userDetails._id,
          type: 'User',
          name: userDetails.name
        },
        receipients: {
          type: 'Adviser',
          ids: [
            {
              id: connectedAdvisers && connectedAdvisers[0]._id
            }
          ]
        },
        type: {
          name: 'connection',
          tag: 'MORE_DETAIL_CLICK',
          extraInfo: 'CLIENT_CLICK_MORE_DETAIL'
        },
        title: {
          en: 'more-detail-title',
          zh: 'more-detail-title',
          zh_Hant_HK: 'more-detail-title'
        },
        message: {
          en: 'more-detail-message',
          zh: 'more-detail-message',
          zh_Hant_HK: 'more-detail-message'
        }
      }
    };
    this.props.createNotification(data);
  }

  //Call API to get the policy of family share
  selectPortfolio = targetShareId => {
    const { userDetails, initSharePhase, fetchMePolicy, getShareMeDocuments, updateSelectedPortfolio } = this.props;

    // Call api to get the data of ME
    if (targetShareId === undefined || targetShareId === userDetails._id || targetShareId === 'Me') {
      initSharePhase();
      fetchMePolicy();
    } else {
      // Call api to get the data family share
      getShareMeDocuments({ id: targetShareId });
    }

    updateSelectedPortfolio(targetShareId);
  };

  handleSlidesResize = () => {
    if (this.mySwipeableViews) {
      this.mySwipeableViews.updateHeight();
    }
  };

  clickPolicy = policy => {
    const { history, handleDashboardPolicyData } = this.props;
    checkPolicy(policy, { history, handleDashboardPolicyData });
  };

  closePreferenceHelpDialog = () => {
    this.setState({ showHelpModal: false });
    this.props.toggleControl('addToHomeSnackbar', true);
  };

  render() {
    const {
      intl,
      categories,
      categories1,
      history,
      location,
      adviserBlastPop,
      hasAdviserBlast,
      markNotificationAsRead,
      portfolio,
      portfolio1,
      userDetails,
      fetchPhase,
      getDocPhase,
      currentPortfolioId,
      updateUserDetails
    } = this.props;
    const { tab, modalIsOpen, showAdviserBlast, connectedAdviser } = this.state;

    const clientId = JSON.parse(window.localStorage.getItem('clientId'));
    const isAdviser = isAdviserType(userDetails);

    if (isAdviser && !clientId) {
      history.goBack();
      return null;
    }

    const isSelfPortfolio =
      clientId ||
      currentPortfolioId === undefined ||
      currentPortfolioId === userDetails._id ||
      currentPortfolioId === 'Me';
    const userType = userDetails && userDetails.userType ? userDetails.userType : '';

    // portfolio = own portfolio, portfolio1 = share portfolio
    const finalPortfolio = (isSelfPortfolio || getDocPhase === LOADING ? portfolio : portfolio1) || [];

    // categories = own categories, categories1 = share categories
    const finalCategories = ((isSelfPortfolio || getDocPhase === LOADING ? categories : categories1) || []).sort(
      categorySorter
    );

    return (
      <AppContentContainer className="dashboard">
        <MyResizeObserver
          targetsSelector=".user-dashboard-slide"
          onResize={this.handleSlidesResize}
          trigger={finalCategories}
        />
        {/*{!clientId && <PortfolioFab />}*/}
        {finalCategories.length === 0 && (fetchPhase === LOADING || getDocPhase === LOADING) && (
          <LoadingBackdrop open={true} />
        )}
        {!modalIsOpen && (
          <AdviserBlastPopUp
            open={hasAdviserBlast && showAdviserBlast && connectedAdviser}
            onClose={() => {
              this.setState({ showAdviserBlast: false });
            }}
            markAndClose={this.markAndClose.bind(this, adviserBlastPop)}
            clientMessages={this.clientMessages.bind(this)}
            intl={intl}
            history={history}
            adviserBlastPop={adviserBlastPop}
            markNotificationAsRead={markNotificationAsRead}
          />
        )}

        <TopNavigationPortal>
          <div className="plus-btn-client client-dashboard-guideline-icon">
            <MoreInfoIconButton notClientStyle={true} handleClick={this.runGuideline} />
          </div>
          <div
            className="plus-btn-openGraph client-dashboard-guideline-icon"
            style={{
              display: this.state.width > 960 ? 'none' : 'block',
              right: userType === 'User' ? (this.state.width > 600 ? '80px' : '60px') : '50px'
            }}
          >
            <IconButton
              edge="start"
              aria-label="copy"
              onClick={() => {
                // Tracking
                tracking('Client Dashboard: Portfolio graphic button clicked');
                this.setState({ showGraphModal: true });
              }}
            >
              <AssessmentOutlinedIcon className="top-copy-btn" style={{ fill: '#ffffff' }} />
            </IconButton>
          </div>
        </TopNavigationPortal>

        <Portal container={document.body}>
          <div
            className="nav-item"
            style={{
              maxWidth: APP_CONTENT_CONTAINER_MAX_WIDTH,
              marginLeft:
                this.state.width > SHOW_SIDE_BAR_WIDTH
                  ? this.state.width > APP_CONTENT_CONTAINER_MAX_WIDTH + 60
                    ? `calc(((100% - 60px - ${APP_CONTENT_CONTAINER_MAX_WIDTH}px) / 2) + 60px)`
                    : 60
                  : '0'
            }}
          >
            <Grid container>
              <Grid item style={{ width: this.state.width > 960 ? 'calc(100% - 400px)' : '100%' }}>
                <MyTabs
                  tabsClassName="portfolioTabs"
                  value={tab}
                  onChange={(event, value) => {
                    this.tempTab = undefined;
                    this.setState({ tab: value });
                  }}
                  tabLabels={
                    finalCategories && finalCategories.length > 0
                      ? finalCategories.map(val => intl.formatMessage({ id: val.title }))
                      : []
                  }
                />
              </Grid>
              {this.state.width > 960 && (
                <Grid item style={{ width: 400 }}>
                  <div className="text-center figureColor dashboard-drawer-title">
                    {intl.formatMessage({ id: 'graph' })}
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </Portal>

        <Grid container style={{ paddingTop: 55 }}>
          <Grid
            item
            style={{
              width: this.state.width > 960 ? 'calc(100% - 400px)' : '100%',
              height: this.state.width > 960 ? `calc(100vh - 103px)` : undefined,
              overflowY: this.state.width > 960 ? 'auto' : undefined
            }}
          >
            <SwipeableViews
              className="nav-top-margin"
              ref={node => {
                if (node) this.mySwipeableViews = node;
              }}
              animateHeight={true}
              index={tab}
              onChangeIndex={index => (this.tempTab = index)}
              onTransitionEnd={() => {
                if (this.tempTab !== undefined) {
                  this.setState({ tab: this.tempTab });
                }
              }}
            >
              {finalCategories.map(category => {
                const isGeneralCategory = isCategory(category._id, 'general');
                return (
                  <div
                    className="user-dashboard-slide"
                    style={{ minHeight: window.innerHeight - 150 }}
                    key={category._id}
                  >
                    <PortfolioCategory
                      portfolio={finalPortfolio}
                      category={category}
                      currentPortfolioId={currentPortfolioId}
                      selectPortfolio={this.selectPortfolio}
                      portfolioSelector={true}
                      viewSelector={true}
                      onClickPolicy={this.clickPolicy}
                      infoType={isGeneralCategory ? 'Premium' : undefined}
                      premiumFrequency={isGeneralCategory ? 'Monthly' : undefined}
                    />
                  </div>
                );
              })}
            </SwipeableViews>
          </Grid>
          {this.state.width > 960 && (
            <Grid item style={{ width: 400 }}>
              <ClientDashboardDrawer
                finalPortfolio={finalPortfolio}
                finalCategories={finalCategories}
                isSelfPortfolio={isSelfPortfolio}
                width={this.state.width}
                {...this.props}
                style={{ marginTop: 0, position: 'relative', height: `calc(100vh - 103px)` }}
              />
            </Grid>
          )}
        </Grid>

        {this.state.width <= 960 && (
          <Dialog
            open={this.state.showGraphModal}
            onClose={() => this.setState({ showGraphModal: false })}
            TransitionComponent={SlideTransition}
            fullScreen={true}
            fullWidth={true}
          >
            <DialogTitle id="scroll-dialog-title" className="figureColor dashboard-drawer-title">
              {intl.formatMessage({ id: 'graph' })}
            </DialogTitle>
            <DialogContent
              id="scroll-dialog-description"
              style={{ padding: '0 0 0 0' }}
              // dividers={true}
            >
              <ClientDashboardDrawer
                finalPortfolio={finalPortfolio}
                finalCategories={finalCategories}
                isSelfPortfolio={isSelfPortfolio}
                width={this.state.width}
                {...this.props}
              />
            </DialogContent>

            <DialogActions style={{ justifyContent: 'flex-end' }}>
              <Button color="primary" onClick={() => this.setState({ showGraphModal: false })}>
                {intl.formatMessage({ id: 'Close' })}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <PreferenceHelpDialog
          open={this.state.showHelpModal}
          history={history}
          fromPortfolio={false}
          intl={intl}
          onClose={this.closePreferenceHelpDialog}
          updateUserDetails={updateUserDetails}
        />
      </AppContentContainer>
    );
  }
}

export default DashboardComponent;
