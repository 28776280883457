import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, IconButton, TextField, Typography, InputAdornment  } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import { PhoneNumberInputWrapper } from '../../../NewSignUp/contents/PhoneNumberInputWrapper';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import MyDetailsProfilePicture from './MyDetailsProfilePicture/MyDetailsProfilePicture';
import _ from 'lodash';
import { THEME_COLOR_PALETTES } from '../../../../constants/my-details';
import { getErrorMessageIntl } from '../../../../utils/validators';
import { getAvatar } from '../../../../utils/my-details';
import AvatarPickerDialog from './AvatarPickerDialog/AvatarPickerDialog';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { formatTwoRowsString, getUserInitial } from '../../../../utils/user';
import DISCSelect from '../../../../components/DISCSelect/DISCSelect';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { getAgeFromDob } from '../../../../utils/date';
import DISCDialogComponent from '../../../../components/ClientEntry/DISCDialog/DISCDialogComponent/DISCDialogComponent';

const ProfileImageContainerStyle = {
  padding: 8,
  border: '8px solid #3966f870'
};

const ProfileImageStyle = {
  width: 100
};

const ProfileImageUploadTypoStyle = {
  textDecoration: 'underline'
};

const MyDetailsProfile = props => {
  const { intl, details, error, onChangeDetails } = props;

  const [avatarDialog, setAvatarDialog] = useState(false);
  const [personalityDialog, setPersonalityDialog] = useState(false);

  const {
    name,
    gender,
    email,
    workEmail,
    phoneNumber,
    phoneNumber2,
    whatsapp,
    wechatId,
    lineId,
    dob,
    age,
    personality
  } = details || {};

  const themeColor = _.get(details, 'themeColor') || 'blue';
  const initial = getUserInitial(details);
  const avatar = getAvatar(details);
  const formattedAge = getAgeFromDob(dob, age) || '';

  const createSimpleOnChangeHandler = (key, emptyValue, formatter = val => val) => event =>
    onChangeDetails({ [key]: formatter(event.target.value || emptyValue) });
  const onChangeAvatar = src => onChangeDetails({ avatar: src });
  const onChangeName = createSimpleOnChangeHandler('name', '', formatTwoRowsString);
  const onChangeGender = createSimpleOnChangeHandler('gender', null);
  const onChangeWorkEmail = createSimpleOnChangeHandler('workEmail', '');
  const onChangePhoneNumber = createSimpleOnChangeHandler('phoneNumber', '');
  const onChangePhoneNumber2 = createSimpleOnChangeHandler('phoneNumber2', '');
  const onChangeWhatsapp = createSimpleOnChangeHandler('whatsapp', '');
  const onChangeWechatId = createSimpleOnChangeHandler('wechatId', '');
  const onChangeLineId = createSimpleOnChangeHandler('lineId', '');
  const onChangeDob = date => {
    const updates = { dob: date };
    if (date) {
      updates.age = moment().diff(moment(moment(date).format('DD-MM-YYYY'), 'DD-MM-YYYY'), 'years');
    }
    onChangeDetails(updates);
  };
  const onChangeAge = createSimpleOnChangeHandler('age', '');
  const onChangePersonality = personality => onChangeDetails({ personality: personality || '' });

  const openAvatarDialog = () => setAvatarDialog(true);
  const closeAvatarDialog = () => setAvatarDialog(false);

  const openPersonalityDialog = () => setPersonalityDialog(true);
  const closePersonalityDialog = () => setPersonalityDialog(false);

  return (
    <Fragment>
      <AvatarPickerDialog
        open={avatarDialog}
        onClose={closeAvatarDialog}
        initial={initial}
        avatar={avatar}
        onChange={onChangeAvatar}
        borderColor={THEME_COLOR_PALETTES[themeColor][1]}
      />

      <DISCDialogComponent
        open={personalityDialog}
        onClose={closePersonalityDialog}
        contentText="adviser"
        personality={personality}
        onSubmit={onChangePersonality}
      />

      <Grid container direction="column" spacing={3}>
        <Grid item>
          <AladdinHint title={intl.formatMessage({ id: 'mda-profile-title' })} />
        </Grid>
        <Grid item>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Grid item onClick={openAvatarDialog}>
                  <MyDetailsProfilePicture
                    initial={initial}
                    src={avatar}
                    width={150}
                    borderColor={THEME_COLOR_PALETTES[themeColor][1]}
                  />
                </Grid>
                <Grid item>
                  <Button fullWidth={true} onClick={openAvatarDialog}>
                    <Typography color="textSecondary" style={ProfileImageUploadTypoStyle}>
                      {intl.formatMessage({ id: 'mda-avatar-change' })}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid container direction="column" alignItems="flex-start" spacing={2}>
                <Grid item style={{ width: '100%' }}>
                  <TextField
                    label={`${intl.formatMessage({ id: 'Name' })}*`}
                    value={name}
                    onChange={onChangeName}
                    error={!!error.name}
                    helperText={error.name && intl.formatMessage({ id: getErrorMessageIntl(error.name) })}
                    multiline={true}
                    rows={2}
                  />
                </Grid>
                <Grid item>
                  <SelectButtonGroup
                    choices={[
                      {
                        label: intl.formatMessage({ id: 'Male' }),
                        value: 'Male',
                        style: {
                          minWidth: 64
                        }
                      },
                      {
                        label: intl.formatMessage({ id: 'Female' }),
                        value: 'Female',
                        style: {
                          minWidth: 64
                        }
                      }
                    ]}
                    value={gender}
                    onChange={onChangeGender}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField label={`${intl.formatMessage({ id: 'Login Email' })}*`} value={email} />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'Work Email' })}
            value={workEmail}
            onChange={onChangeWorkEmail}
            error={!!error.workEmail}
            helperText={error.workEmail && intl.formatMessage({ id: getErrorMessageIntl(error.workEmail) })}
          />
        </Grid>
        <Grid item>
          <TextField
            className="phone-text-field"
            name="phoneNumber"
            label={`${intl.formatMessage({ id: 'Tel' })}*`}
            value={phoneNumber}
            onChange={onChangePhoneNumber}
            InputProps={{
              inputComponent: PhoneNumberInputWrapper,
              inputProps: {
                intl: intl
              }
            }}
            InputLabelProps={{ shrink: false, className: 'non-shrink' }}
            error={!!error.phoneNumber}
            helperText={error.phoneNumber && intl.formatMessage({ id: getErrorMessageIntl(error.phoneNumber) })}
          />
        </Grid>
        <Grid item>
          <TextField
            className="phone-text-field"
            name="phoneNumber2"
            label={intl.formatMessage({ id: 'Tel 2' })}
            value={phoneNumber2}
            onChange={onChangePhoneNumber2}
            InputProps={{
              inputComponent: PhoneNumberInputWrapper,
              inputProps: {
                intl: intl
              }
            }}
            InputLabelProps={{ shrink: false, className: 'non-shrink' }}
          />
        </Grid>
        <Grid item>
          <TextField
            className="phone-text-field"
            name="whatsapp"
            label={intl.formatMessage({ id: 'WhatsApp' })}
            value={whatsapp}
            onChange={onChangeWhatsapp}
            InputProps={{
              inputComponent: PhoneNumberInputWrapper,
              inputProps: {
                intl: intl
              }
            }}
            InputLabelProps={{ shrink: false, className: 'non-shrink' }}
          />
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <TextField label={intl.formatMessage({ id: 'Wechat ID' })} value={wechatId} onChange={onChangeWechatId} />
            </Grid>
            <Grid item xs={6}>
              <TextField label={intl.formatMessage({ id: 'Line ID' })} value={lineId} onChange={onChangeLineId} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                name="dob"
                clearable={true}
                format="DD-MMM-YYYY"
                maxDate={new Date()}
                value={!dob ? null : moment.parseZone(dob)}
                openTo="year"
                onChange={onChangeDob}
                autoOk={true}
                initialFocusedDate={moment().subtract(30, "years")}
                label={intl.formatMessage({ id: 'Date of Birth' })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          padding: '0 5px 0 0',
                          marginLeft: '-12px'
                        }}
                      >
                        <TodayIcon color="action" style={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={intl.formatMessage({ id: 'Age' })}
                value={formattedAge}
                disabled={!!dob}
                onChange={onChangeAge}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justify="space-between" spacing={1}>
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>{intl.formatMessage({ id: 'Personality' })}</Typography>
                </Grid>
                <Grid item>
                  <IconButton color="primary" size="small" onClick={openPersonalityDialog}>
                    <HelpOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DISCSelect personality={personality} onChange={onChangePersonality} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default injectIntl(MyDetailsProfile);
