import React, { useEffect } from 'react';
import '../styles.scss';
import { insurerRegionFilter } from '../../../utils/region';
import { staticMpfDetail } from '../../../utils/staticMpfDetail';
import { getTranslatedInsurerInfo, isInsurerOther, isInsurerWhatIf } from '../../../utils/insurer';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';

const InsurerInfoView = props => {
  const { intl, history, location, userDetails, insurers, insurerInfo, renderHeaderButton } = props;

  useEffect(() => {
    insurerInfo();
  }, [insurerInfo]);

  useEffect(() => {
    const showHeader = !(location.state && location.state.previousPath);
    renderHeaderButton(showHeader);
  }, [location, renderHeaderButton]);

  const onClickInsurer = insurer => {
    if (userDetails && userDetails.userType === 'Adviser') {
      history.push({ pathname: '/insurer_detail', state: { insurInfo: insurer, previousPath: true } });
    } else {
      history.push({ pathname: '/insurer_detail', state: { insurInfo: insurer } });
    }
  };

  const setInsurer = array => {
    const mfa = staticMpfDetail;
    return [array[0], mfa[0], ...array.slice(1, array.length - 2), array[array.length - 1]];
  };

  let filteredInsurers = (insurers || []).filter(insurerRegionFilter);

  if (filteredInsurers.length > 0) {
    var indexOther = filteredInsurers.findIndex(isInsurerOther);
    var indexWhatIf = filteredInsurers.findIndex(isInsurerWhatIf);

    if (indexOther !== -1) {
      var objOther = filteredInsurers[indexOther];
    }

    if (indexWhatIf !== -1) {
      var objWhatIf = filteredInsurers[indexWhatIf];
    }

    if (indexWhatIf !== -1) {
      filteredInsurers.splice(indexWhatIf, 1);
    }

    if (indexOther !== -1) {
      filteredInsurers.splice(indexOther, 1);
    }

    if (objOther !== undefined) {
      filteredInsurers.push(objOther);
    }

    if (objWhatIf !== undefined) {
      filteredInsurers.unshift(objWhatIf);
    }
  }
  const finalArray = setInsurer(filteredInsurers);

  return (
    <div style={{ padding: 16 }}>
      <GridButtonLayout
        items={(finalArray || []).map(insurer => {
          const insurerTranslatedData = insurer._id ? getTranslatedInsurerInfo(insurer, intl.locale) : insurer;
          return {
            image: (
              <img src={!isInsurerWhatIf(insurer) ? insurerTranslatedData.image : 'img/emergency_detail.png'} alt="" />
            ),
            text: !isInsurerWhatIf(insurer) ? insurerTranslatedData.title : intl.formatMessage({ id: 'infoEmergency' }),
            onClick: () => onClickInsurer(insurer)
          };
        })}
      />
    </div>
  );
};

export default InsurerInfoView;
