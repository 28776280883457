import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isUserConnected } from '../../utils/user';
import LoadingBackdrop from '../../components/LoadingBackdrop/LoadingBackdrop';
import ConnectionRequestFailDialog from './ConnectionRequestFailDialog/ConnectionRequestFailDialog';
import ConnectionRequestAcceptDialog from './ConnectionRequestAcceptDialog/ConnectionRequestAcceptDialog';
import { Redirect } from 'react-router';

const ConnectionRequestAccept = props => {
  const { location, userDetails } = props;

  const [connectionRequestFailDialogOpen, setConnectionRequestFailDialogOpen] = useState(false);
  const [connectionRequestAcceptDialogOpen, setConnectionRequestAcceptDialogOpen] = useState(false);

  const { connectionRequest } = location.state || {};

  useEffect(() => {
    const { connectionRequest } = location.state || {};
    if (userDetails && connectionRequest) {
      if (!connectionRequestFailDialogOpen && !connectionRequestAcceptDialogOpen) {
        const isConnected = isUserConnected(userDetails);
        if (isConnected) {
          setConnectionRequestFailDialogOpen(true);
        } else {
          setConnectionRequestAcceptDialogOpen(true);
        }
      }
    }
  }, [connectionRequestAcceptDialogOpen, connectionRequestFailDialogOpen, location, userDetails]);

  if (!connectionRequest) {
    return <Redirect to="/" />;
  }

  if (userDetails && userDetails.email !== connectionRequest.email) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <LoadingBackdrop open={!userDetails} />
      <ConnectionRequestFailDialog open={connectionRequestFailDialogOpen} />
      <ConnectionRequestAcceptDialog open={connectionRequestAcceptDialogOpen} connectionRequest={connectionRequest} />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(ConnectionRequestAccept);

export default injectIntl(container);
