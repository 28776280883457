import { fetch } from '../../utils';
import Backend from '../../backend';
import moment from 'moment-timezone';
import { getFormattedReferralLinkSearchObject } from '../../utils/referral';
import { getSearchObject } from '../../utils/router';

// API for getting adviser's profile plus
export const getAdviserProfilePlus = (userId, location) => {
  let searchObj = getFormattedReferralLinkSearchObject(getSearchObject({ location }));
  
  let query = "";
  let nfcReferral = sessionStorage.getItem('nfc_referral');
  if ((!nfcReferral || nfcReferral === "null") && (searchObj.source === 'card' || searchObj.medium === 'nfc')) {
    let timezone = moment.tz.guess();
    let language = navigator.language;
    query = `?medium=nfc&role=adviser&language=${language}&timezone=${timezone}`;
  }

  return fetch(`${Backend.methodUrl('adviser-profile-plus')}/${userId}${query}`)
    .then(res => {
      return res.json();
    })
    .then(payload => {
      if (payload["referral"]){
        sessionStorage.setItem('nfc_referral', payload["referral"]);
      }
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// API for incrementing likes of adviser's profile plus
export const increaseAdviserProfilePlusLike = userId => {
  return fetch(`${Backend.methodUrl('adviser-profile-plus/like')}/${userId}`)
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
