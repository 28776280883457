import React from 'react';
import _ from 'lodash';

export default class ColoredProgress extends React.PureComponent {
  render() {
    const { color, percentage, maxWidth, style, ...rest } = this.props;

    return (
      <div
        style={_.merge(
          {
            position: 'relative',
            border: '1px solid lightgrey',
            borderRadius: '6px',
            minWidth: '25px',
            height: '100%',
            maxWidth: maxWidth
          },
          style
        )}
        {...rest}
      >
        <div
          style={{
            position: 'absolute',
            left: '1px',
            top: '1px',
            height: 'calc(100% - 2px)',
            width: `calc((100% - 2px) * ${percentage} / 100)`,
            backgroundColor: color,
            borderRadius: '4px'
          }}
        />
      </div>
    );
  }
}
