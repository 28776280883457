import React from 'react';
import { ShortSubscriptions } from '../../../utils/user-subscription';
import './ProBadge.scss';

export default class ProBadgePure extends React.PureComponent {
  render() {
    const { subscriptions, adviserSubscriptionNickname, explicitShow } = this.props;

    return (explicitShow === undefined &&
      (new ShortSubscriptions(subscriptions).containTypedSubscription({ type: 'core', nicknames: ['Pro', 'Ultra'] }) ||
        adviserSubscriptionNickname === 'Pro' ||
        adviserSubscriptionNickname === 'Ultra')) ||
      (explicitShow !== undefined && explicitShow) ? (
      <span className="pro-badge">PRO</span>
    ) : null;
  }
}
