import React from 'react';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import ComingSoon from '../../../components/ComingSoon/ComingSoon';

const ClientMatching = () => {
  return (
    <AppContentContainer>
      <ComingSoon />
    </AppContentContainer>
  );
};

export default ClientMatching;
