import React from 'react';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../../../../store/control/duck';
import { updateAssistantCategories } from '../../../../../store/assistant/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { CATEGORY_DESCRIPTION_INTL } from '../AssistantCategory/AssistantCategory';

class AssistantCategoryCustomizeDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: undefined,
      changed: false
    };
  }

  close = () => this.props.mergeDialog('assistantCategoryCustomize', { closed: true });
  closed = () => {
    this.setState({ category: undefined, changed: false });
    this.props.toggleDialog('assistantCategoryCustomize', false);
  };

  changeDescription = event => {
    const { category } = this.state;
    const copy = _.cloneDeep(category);
    _.set(copy, 'description', event.target.value);
    this.setState({ category: copy, changed: true });
  };

  clear = () => this.changeDescription({ target: { value: '' } });

  submit = async () => {
    const { updateAssistantCategories } = this.props;
    const { category, changed } = this.state;

    if (changed) {
      await updateAssistantCategories([category]);
    }
    this.close();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { assistantCategoryCustomizeDialog } = this.props;
    const { category } = this.state;
    if (assistantCategoryCustomizeDialog && !assistantCategoryCustomizeDialog.closed) {
      const prevCategory = _.get(prevProps, 'assistantCategoryCustomizeDialog.category');
      const thisCategory = assistantCategoryCustomizeDialog.category;
      if ((!prevCategory && thisCategory) || (!category && thisCategory)) {
        this.setState({ category: thisCategory });
      }
    }
  }

  render() {
    const { category } = this.state;
    const { intl, assistantCategoryCustomizeDialog } = this.props;

    if (!assistantCategoryCustomizeDialog || !category) {
      return null;
    }

    const { closed } = assistantCategoryCustomizeDialog;
    const categoryDescription = _.get(category, 'description');

    return (
      <Dialog
        className="assistant-customize-dialog"
        open={!!assistantCategoryCustomizeDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle>{intl.formatMessage({ id: 'ast-cat-cus-dialog-title' })}</DialogTitle>
        <DialogContent>
          <Typography color="textSecondary" gutterBottom={true}>
            {intl.formatMessage({ id: 'ast-cat-cus-dialog-hint' })}
          </Typography>
          <form>
            <TextField
              value={categoryDescription}
              multiline
              rows="3"
              variant="outlined"
              onChange={this.changeDescription}
              placeholder={intl.formatMessage({ id: CATEGORY_DESCRIPTION_INTL[category.name] })}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.clear} color="primary" autoFocus style={{ position: 'absolute', left: 0 }}>
            {intl.formatMessage({ id: 'Clear' })}
          </Button>
          <Button onClick={this.close} color="primary" autoFocus>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.submit} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    assistantCategoryCustomizeDialog: state.control.assistantCategoryCustomizeDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    updateAssistantCategories
  },
  // mergeProps
  null,
  // options
  {}
)(AssistantCategoryCustomizeDialog);

export default injectIntl(container);
