import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { THEME_COLOR_PALETTES } from '../../../constants/my-details';
import AdviserInfoTagChip from '../AdviserInfoTagChip/AdviserInfoTagChip';
import { getPersonalityTagIntl } from '../../../utils/my-details';
import { formatEditorStateValue, getEditorDefaultMessage } from '../../../utils/editor';
import MyEditor from '../../../components/MyEditor/MyEditor';
import { tryTranslate } from '../../../utils/locale';
import AdviserInfoCardAbstract from '../AdviserInfoCardAbstract/AdviserInfoCardAbstract';

const FullWidthStyle = {
  width: '100%'
};

const AdviserInfoPageProfile = props => {
  const { intl, adviserInfo, isIntoView } = props;

  const { personalityTags, adviserMessage } = adviserInfo || {};
  const themeColor = _.get(adviserInfo, 'themeColor') || 'blue';
  const formattedPersonalityTags = personalityTags || [];
  const formattedAdviserMessage = formatEditorStateValue(
    adviserMessage || getEditorDefaultMessage(intl.locale, 'adviser-message')
  );

  return (
    <AdviserInfoCardAbstract
      className="adviser-info-page-profile"
      title={intl.formatMessage({ id: 'adv-info-about-me-title' })}
      adviserInfo={adviserInfo}
      contentHeight="calc(100% - 110px)"
      isIntoView={isIntoView}
    >
      <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
        <Grid item className="content-container" style={{ ...FullWidthStyle, flexGrow: 1 }}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            wrap="nowrap"
            spacing={3}
            style={{ height: '100%' }}
          >
            <Grid item style={FullWidthStyle}>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography display="inline" className="emoji">
                    🤠
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'personality-tags' })}
                  </Typography>
                </Grid>
                <Grid item>
                  {formattedPersonalityTags.length > 0 ? (
                    <Grid container alignItems="center" spacing={1}>
                      {formattedPersonalityTags.map(tag => (
                        <Grid item key={tag}>
                          <AdviserInfoTagChip
                            color={THEME_COLOR_PALETTES[themeColor][1]}
                            label={tryTranslate(intl, getPersonalityTagIntl(tag))}
                            isIntoView={isIntoView}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography color="textSecondary">-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ ...FullWidthStyle, flexGrow: 1, overflow: 'hidden' }}>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                wrap="nowrap"
                spacing={1}
                style={{ height: '100%' }}
              >
                <Grid item style={FullWidthStyle}>
                  <Typography display="inline" className="emoji">
                    💬
                  </Typography>
                  <Typography display="inline" className="title">
                    {intl.formatMessage({ id: 'my-intro' })}
                  </Typography>
                </Grid>
                <Grid item style={{ ...FullWidthStyle, height: '100%', overflowY: 'auto' }}>
                  <MyEditor editorState={formattedAdviserMessage} readOnly={true} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdviserInfoCardAbstract>
  );
};

export default injectIntl(AdviserInfoPageProfile);
