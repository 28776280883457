import { connect } from 'react-redux';
import { addMessage, fetchMessages } from '../../../../store/message/duck';
import { handleSignOut } from '../../../../store/user/duck';
import HotTopicView from './component';

import { injectIntl } from 'react-intl';
import { insurerInfo } from '../../../../store/policy/duck';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';
const HotTopic = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    fetchMessagesPhase: state.message.fetchMessagesPhase,
    fetchMessageData: state.message.fetchMessageData,
    newReferralCount: state.referral.newReferralCount,
    hotTopic: state.functions.hotTopic
  }),
  // Map actions to props
  {
    addMessage,
    fetchMessages,
    handleSignOut,
    insurerInfo,
    renderHeaderButton
  }
)(HotTopicView);

export default injectIntl(HotTopic);
