import React, { useRef, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import './PortfolioChart.scss';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { customLegendCallback } from '../../../../../utils/chart-js';
import { getWebImageUrl } from '../../../../../utils/image';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { decimalToString } from '../../../../../utils/formatNumber';

const PortfolioChart = props => {
  const chartGridRef = useRef();
  const chartRef = useRef();
  const legendRef = useRef();

  useEffect(() => {
    const legendDiv = _.get(legendRef, 'current');
    const chartInstance = _.get(chartRef, 'current.chartInstance');
    if (legendDiv && chartInstance) {
      legendDiv.innerHTML = chartInstance.generateLegend();
    }

    const { updateHeight } = props;
    if (updateHeight) {
      const chartGridDiv = _.get(chartGridRef, 'current');
      if (chartGridDiv && legendDiv) {
        updateHeight(chartGridDiv.offsetHeight + legendDiv.offsetHeight + 26);
      }
    }

    // const chartGridDiv = _.get(chartGridRef, 'current');
    // if (chartGridDiv) {
    //   if (chartGridDiv) {
    //     setHeight(chartGridDiv.offsetHeight);
    //   }
    // }
  });

  const {
    intl,
    children,
    currency,
    chartData,
    style,
    legendWrapperProps,
    title,
    chartHeight,
    updateHeight,
    isProfolioChartShown,
    addPolicy,
    ...rest
  } = props;

  const chartConfig = {
    cutoutPercentage: 78,
    legend: {
      display: false
    },
    legendCallback: customLegendCallback('portfolio-chart-legend'),
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      }
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          var label = data.labels[tooltipItem.index] || '';
          var currentValue = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

          if (label !== intl.formatMessage({ id: 'No Policy' })) {
            if (label) {
              label += ': ';
            }
            label += intl.formatMessage({ id: currency }) + ' ';
            label += currentValue === '0.1' ? '0' : decimalToString(currentValue, 0);
          }

          return label;
        }
      }
    }
  };

  return (
    <div className="portfolio-chart" style={style} {...rest}>
      <div>
        <div className="Outer">
          {chartData && isProfolioChartShown && (
            <Grid container direction="column" alignItems="center" style={{ height: '100%' }}>
              <Grid item>
                {title && (
                  <Typography
                    color="textSecondary"
                    align="center"
                    style={{ fontSize: '125%', fontWeight: '600', marginBottom: '5px' }}
                  >
                    {title}
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ width: '100%' }} ref={chartGridRef}>
                <div style={{ position: 'relative', padding: '0 10%' }}>
                  <div style={{ height: chartHeight, width: '100%', margin: '0 auto' }}>
                    <Doughnut data={chartData} options={chartConfig} ref={chartRef} />
                  </div>
                </div>
              </Grid>
              <Grid item>
                <div {...legendWrapperProps} ref={legendRef} />
              </Grid>
            </Grid>
          )}

          {!isProfolioChartShown && (
            <Grid container direction="column" alignItems="center" style={{ height: '100%' }} onClick={addPolicy}>
              <Grid item>
                <Typography
                  color="textSecondary"
                  align="center"
                  style={{ fontSize: '125%', fontWeight: '600', marginBottom: '5px' }}
                >
                  {intl.formatMessage({ id: 'have-no-policy' })}
                </Typography>
              </Grid>
              <Grid item style={{ width: '100%' }} ref={chartGridRef}>
                <div style={{ position: 'relative', padding: '0 10%' }}>
                  <div style={{ height: chartHeight, width: '100%', margin: '0 auto' }}>
                    <img
                      src={getWebImageUrl('img/exclamation.png')}
                      alt="exclamation"
                      style={{ maxWidth: '80%', maxHeight: '80%', paddingTop: '5%' }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item container direction="row" alignItems="center" justify="center">
                <Grid item>
                  <AddIcon color="primary" onClick={() => addPolicy()} />
                </Grid>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    align="center"
                    style={{ fontSize: '125%', fontWeight: '600', marginBottom: '5px' }}
                  >
                    {intl.formatMessage({ id: 'add-policies' })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default injectIntl(PortfolioChart);
