import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { Dialog, IconButton } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ContactMeCard from './ContactMeCard/ContactMeCard';
import { getSearchObject } from '../../../../utils/router';
import { submitReferral } from '../../../../store/referral/duck';
import { usePrevious } from '../../../../utils/hooks';
import moment from 'moment-timezone';
import { getFormattedReferralLinkSearchObject } from '../../../../utils/referral';
import _ from 'lodash';

const CloseButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1
};

const ContactMeDialog = props => {
  const {
    intl,
    history,
    location,
    match,
    staticContext,
    open,
    onClose,
    onSuccess,
    animToggle,
    adviserProfilePlus,
    referral,
    submitReferral,
    ...rest
  } = props;

  const [referById, setReferById] = useState(undefined);
  const [source, setSource] = useState(undefined);
  const [medium, setMedium] = useState(undefined);
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    const searchObj = getFormattedReferralLinkSearchObject(getSearchObject({ location }));

    if (searchObj.referById) {
      setReferById(searchObj.referById);
    }

    // special handling for nfc card
    if (searchObj.source === 'card') {
      setSource(undefined);
      setMedium('nfc');
      setRole('adviser');
    } else {
      if (searchObj.source) {
        setSource(searchObj.source);
      }

      if (searchObj.medium) {
        setMedium(searchObj.medium);
      }

      if (searchObj.role) {
        setRole(searchObj.role);
      }
    }
  }, [location]);

  return (
    <ContactMeCard
      animToggle={animToggle}
      onSuccess={onSuccess}
      referById={referById}
      source={source}
      medium={medium}
      role={role}
      open={open}
      onClose={onClose}
    />
  );
};

const container = connect(
  state => ({
    adviserProfilePlus: state.adviserProfilePlus.adviserProfilePlus,
    referral: state.referral.referral
  }),
  {
    submitReferral
  }
)(ContactMeDialog);

export default injectIntl(withRouter(container));
