import React, { PureComponent } from 'react';
import '../../components/ClientDashboardDrawer/ClientDashboardDrawer.scss';
import LifeCoverageChart from '../../components/ClientDashboardDrawer/DrawerComponent/LifeCoverageChart';
import MonthlyPremiumChart from '../../components/ClientDashboardDrawer/DrawerComponent/MonthlyPremiumChart';
import MonthlyPaidChart from '../../components/ClientDashboardDrawer/DrawerComponent/MonthlyPaidChart';
import { Dialog, DialogTitle, DialogActions, DialogContent, Portal, Typography } from '@material-ui/core';
import { SlideTransition } from '../../components/Transition/Transition';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { categorySorter } from '../../utils/policy';
import { injectIntl } from 'react-intl';
import { LOADING } from '../../constants/phase';
import { userDashboardFetch } from '../../store/user/duck';
import { isUserType } from '../../utils/user';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { fetchMePolicy } from '../../store/policy/duck';
import { initSharePhase } from '../../store/share/duck';
import AnalysisInputPanel from './partials/AnalysisInputPanel';
import { INCOME_OPTIONS } from '../../constants/analysis';
import CustomInputSelect from './partials/CustomInputSelect/CustomInputSelect';
import { updateAnalysisInputs } from '../../store/analysis/duck';
import { updateUserDetails, getUser } from '../../store/user/duck';
import _ from 'lodash';
import AgeInput from './partials/AgeInput/AgeInput';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { toggleDialog } from '../../store/control/duck';
import SyncInputButton from './partials/SyncInputButton/SyncInputButton';
import { toast } from 'react-toastify';
import { submitClient } from '../../store/client/duck';
import { putClientDataToStore } from '../../utils/analysis';
import IconToast from '../../components/NewToast';

class PremiumProtection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      width: window.innerWidth
    };
  }

  handleResize = () => {
    const width = window.innerWidth;
    this.setState({ width });
  };

  componentDidMount() {
    const { history, location, renderHeaderButton, updateAnalysisInputs, getUser } = this.props;

    window.addEventListener('resize', this.handleResize, false);

    renderHeaderButton(!location.state || !location.state.previousPath);

    putClientDataToStore(history, updateAnalysisInputs);

    getUser();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, updatePhase, createClientPhase } = this.props;
    if (
      (prevProps.updatePhase === LOADING && updatePhase === true) ||
      (prevProps.createClientPhase === LOADING && createClientPhase === true)
    ) {
      toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), { className: 'new-toast' });
    }
  }

  UNSAFE_componentWillMount = async () => {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
    try {
      const { location, loginFetched, userDashboardFetch, currentPortfolioId, userDetails } = this.props;

      if (!loginFetched || !isUserType(userDetails)) {
        userDashboardFetch(location, currentPortfolioId);
      }
    } catch (e) {
      return e;
    }
  };

  handleChangeMonthlyIncome = event => {
    const { updateAnalysisInputs } = this.props;
    const obj = { monthlyIncome: event.target.value };
    updateAnalysisInputs(obj);
  };

  handleChangeAge = event => {
    const { updateAnalysisInputs } = this.props;
    const x = event.target.value;
    updateAnalysisInputs({ age: isNaN(x) ? 35 : parseInt(x ? x : 0) });
  };

  handleAgeFocus = event => {
    this.setState({ inputFocusing: true });
  };

  closeConfirmSaveDialog = () => this.props.toggleDialog('analysisConfirmSave', false);

  save = () => {
    const { age, monthlyIncome, userDetails, submitClient, updateUserDetails, viewingClient } = this.props;
    try {
      const updates = {
        monthlyIncome: monthlyIncome
        // age: age
      };

      if (!_.get(viewingClient, 'factsheetId.dob')) {
        updates.age = age;
      }

      // if client, update factsheet, else update profile
      const userType = _.get(userDetails, 'userType');
      if (userType === 'Adviser') {
        if (viewingClient) {
          updates.clientId = viewingClient._id;
          updates._id = viewingClient.factsheetId._id;
          updates.advisorId = userDetails._id;
          updates.connectedAdviserEmail = userDetails.email;
          updates.email = viewingClient.email;
          // updates.dob = dobFinder(_.get(client, 'factsheetId.dob'), _.get(client, 'factsheetId.age'));
          submitClient(updates);
        } else {
          toast.warn('Client not found!');
        }
      } else {
        // updates.dob = dobFinder(_.get(userDetails, 'dob'), _.get(userDetails, 'age'));
        updateUserDetails(updates);
      }
    } catch (e) {
      toast.warn('Operation failed! Please find us for support');
      console.log(e);
    }

    this.closeConfirmSaveDialog();
  };

  render() {
    const { width } = this.state;
    const {
      intl,
      portfolio,
      portfolio1,
      userDetails,
      getDocPhase,
      currentPortfolioId,
      categories,
      categories1,
      monthlyIncome,
      age,
      analysisConfirmSaveDialog
    } = this.props;

    const clientId = JSON.parse(window.localStorage.getItem('clientId'));
    const selectedCurrency = _.get(userDetails, 'currency', 'HKD');
    const isSelfPortfolio =
      clientId ||
      currentPortfolioId === undefined ||
      currentPortfolioId === userDetails._id ||
      currentPortfolioId === 'Me';

    // portfolio = own portfolio, portfolio1 = share portfolio
    const finalPortfolio = (isSelfPortfolio || getDocPhase === LOADING ? portfolio : portfolio1) || [];
    const finalCategories = ((isSelfPortfolio || getDocPhase === LOADING ? categories : categories1) || []).sort(
      categorySorter
    );
    const userType = _.get(userDetails, 'userType');
    return (
      <div>
        <ConfirmModal
          open={analysisConfirmSaveDialog}
          title={`confirm-sync-input-title-${userDetails.userType === 'Adviser' ? 'a' : 'u'}`}
          content={`confirm-sync-input-content-${userDetails.userType === 'Adviser' ? 'a' : 'u'}`}
          buttons={[
            {
              text: 'Cancel',
              onClick: () => this.closeConfirmSaveDialog()
            },
            {
              text: 'Save',
              onClick: () => this.save()
            }
          ]}
        />

        {/* <div className="plus-btn-client client-dashboard-guideline-icon">
            <MoreInfoIconButton notClientStyle={true}  handleClick={() => this.setState({ modalIsOpen: true })} />
        </div> */}
        <div style={{ marginTop: '50px', paddingBottom: '100px' }}>
          {/* <MoreInfoIconButton className="pt-10 progress-card-more-info-btn" */}

          <Portal container={document.body}>
            <AnalysisInputPanel
              className="analysis-input-panel client-dash-analysis premium-protection-input-panel"
              ref={instance => {
                this.stickyInput = instance;
              }}
              inputTitle={<Typography>{intl.formatMessage({ id: 'my-information' })}</Typography>}
              detailComponents={
                <div className="analysis-input-groups">
                  <div>
                    {((userType === 'Adviser' && clientId) || userType === 'User') && <SyncInputButton />}

                    <CustomInputSelect
                      className="analysis-input"
                      label={intl.formatMessage({ id: 'Monthly income' })}
                      name="monthlyIncome"
                      onChange={this.handleChangeMonthlyIncome}
                      value={monthlyIncome}
                      startAdornmentLabel={intl.formatMessage({ id: selectedCurrency })}
                      selectOptions={INCOME_OPTIONS}
                      isDollarValue={true}
                      allowCustomValue={true}
                      min={0}
                      max={INCOME_OPTIONS[INCOME_OPTIONS.length - 1]}
                    />

                    <AgeInput
                      intl={intl}
                      value={age}
                      onValueChange={this.handleChangeAge}
                      onFocus={this.handleAgeFocus}
                    />

                    <div className="analysis-input analysis-input-go" style={{ float: 'right ' }}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.stickyInput.closeInput();
                        }}
                      >
                        {intl.formatMessage({ id: 'Go' })}
                      </Button>
                    </div>
                  </div>
                </div>
              }
            />
          </Portal>

          <div className="dashboard-drawer-graph-container premium-protection-container">
            <LifeCoverageChart
              finalPortfolio={finalPortfolio}
              finalCategories={finalCategories}
              isSelfPortfolio={isSelfPortfolio}
              width={width}
              width={width}
              {...this.props}
            />
            <MonthlyPaidChart
              finalPortfolio={finalPortfolio}
              finalCategories={finalCategories}
              isSelfPortfolio={isSelfPortfolio}
              width={width}
              {...this.props}
            />
            <MonthlyPremiumChart
              finalPortfolio={finalPortfolio}
              finalCategories={finalCategories}
              isSelfPortfolio={isSelfPortfolio}
              width={width}
              {...this.props}
            />
          </div>
        </div>

        <Dialog
          open={this.state.modalIsOpen}
          onClose={() => this.setState({ modalIsOpen: false })}
          TransitionComponent={SlideTransition}
          // fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle id="scroll-dialog-title" className="figureColor">
            {intl.formatMessage({ id: 'dashboard-drawer-explain-title' })}
          </DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'dashboard-drawer-explain-content' })}</DialogContent>

          <DialogActions style={{ justifyContent: 'flex-end' }}>
            <Button color="primary" onClick={() => this.setState({ modalIsOpen: false })}>
              {intl.formatMessage({ id: 'Close' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default injectIntl(
  connect(
    state => ({
      userDetails: state.user.userDetails,
      updatePhase: state.user.updatePhase,
      categories: state.policy.categories,
      portfolio: state.policy.portfolio,
      categories1: state.share.categories1,
      portfolio1: state.share.portfolio1,
      currentPortfolioId: state.policy.currentPortfolioId,
      getDocPhase: state.share.getDocPhase,
      loginFetched: state.user.loginFetched,
      factsheetData: state.policy.factsheetData,
      viewingClient: state.client.viewingClient,
      createClientPhase: state.client.createClientPhase,

      monthlyIncome: state.analysis.monthlyIncome,
      age: state.analysis.age,

      analysisConfirmSaveDialog: state.control.analysisConfirmSaveDialog
    }),
    {
      userDashboardFetch,
      renderHeaderButton,
      initSharePhase,
      fetchMePolicy,
      updateUserDetails,
      updateAnalysisInputs,
      toggleDialog,
      submitClient,
      getUser
    }
  )(PremiumProtection)
);
