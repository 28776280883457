import React, { PureComponent } from 'react';
import ReferralRecordView from './partials/referralRecords';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer.js';

class ReferralView extends PureComponent {
  render() {
    const currentLocation = this.props.location.pathname;
    return (
      <AppContentContainer>
        <div>
          <div id="wrapper">{currentLocation === '/referral_records' && <ReferralRecordView {...this.props} />}</div>
        </div>
      </AppContentContainer>
    );
  }
}

export default ReferralView;
