import React from 'react';
import ReactEcharts from 'echarts-for-react/lib/index';
import { injectIntl } from 'react-intl';
import { POLICY_TYPE } from '../../../../constants/policy';
import { SCORE_LEVEL_COLORS } from '../../../../constants/analysis';

const chartIntervals = [
  {
    scoreHigherThan: 0,
    scoreLessThan: 25,
    color: SCORE_LEVEL_COLORS[0],
    text: 'Bad',
    shortText: 'Bad',
    textPosition: 10,
    textStyle: {
      color: '#757575',
      fontWeight: 'bold',
      padding: [0, 0, 0, 20]
    },
    centerImage: 'bad.png',
    supportImage: '03.png'
  },
  {
    scoreHigherThan: 25,
    scoreLessThan: 50,
    color: SCORE_LEVEL_COLORS[1],
    text: 'Poor',
    shortText: 'Poor',
    textPosition: 40,
    textStyle: {
      color: '#757575',
      fontWeight: 'bold',
      padding: [0, 0, 25, 0]
    },
    centerImage: 'poor.png',
    supportImage: '05.png'
  },
  {
    scoreHigherThan: 50,
    scoreLessThan: 75,
    color: SCORE_LEVEL_COLORS[2],
    text: 'Good',
    shortText: 'Good',
    textPosition: 60,
    textStyle: {
      color: '#757575',
      fontWeight: 'bold',
      padding: [0, 0, 25, 0]
    },
    centerImage: 'good.png',
    supportImage: '02.png'
  },
  {
    scoreHigherThan: 75,
    scoreLessThan: 100.1, // to bound 100
    color: SCORE_LEVEL_COLORS[3],
    text: 'Nice',
    shortText: 'Nice',
    textPosition: 90,
    textStyle: {
      color: '#757575',
      fontWeight: 'bold',
      padding: [0, 20, 0, 0]
    },
    centerImage: 'excellent.png',
    supportImage: '04.png'
  }
];

const findInterval = score => {
  if (!score) {
    return chartIntervals[0];
  }

  let interval = chartIntervals.find(interval => interval.scoreLessThan > score);

  if (interval === undefined) {
    interval = chartIntervals[chartIntervals.length - 1];
  }

  return interval;
};

const getCenterImage = score => {
  return 'img/' + findInterval(score).centerImage;
};

export const getSupportImage = score => {
  return 'img/' + findInterval(score).supportImage;
};

export const getShortTextWithColor = score => {
  let interval = findInterval(score);
  return {
    text: interval.shortText,
    color: interval.color,
    scoreLessThan: interval.scoreLessThan
  };
};

class CoverageChartView extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = this.props;

    let axisColors = chartIntervals.map(interval => {
      return [(interval.scoreLessThan - 0.01) / 100, interval.color];
    });

    let axisStyles = {};
    chartIntervals.forEach(interval => {
      axisStyles[interval.text] = interval.textStyle;
    });

    let axisLabels = chartIntervals.map(interval => {
      return {
        value: interval.textPosition,
        label: '{' + interval.text + '|' + intl.formatMessage({ id: interval.text }) + '}'
      };
    });

    this.csChartOption = (policyType, score) => {
      return {
        title: {
          show: false
        },
        animationDuration: 500,
        animationDurationUpdate: 500,
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        },
        tooltip: {
          show: false,
          formatter: '{b}: {c}'
        },
        legend: {
          show: false
        },
        toolbox: {},
        series: [
          {
            name:
              policyType === POLICY_TYPE.LIFE
                ? intl.formatMessage({ id: 'Life Protection Score' })
                : intl.formatMessage({ id: 'C.I. Protection Score' }),
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            radius: '100%',
            data: [
              {
                value: isNaN(parseInt(score)) ? 0 : parseInt(score),
                name: intl.formatMessage({ id: 'Your score' })
              }
            ],
            axisLine: {
              // 坐标轴线
              show: true, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle控制线条样式
                color: axisColors,
                width: 43
              }
            },
            axisTick: {
              // 坐标轴小标记
              show: false // 属性show控制显示与否，默认不显示
            },
            axisLabel: {
              // 坐标轴文本标签，详见axis.axisLabel
              show: true,
              formatter: function(v) {
                let labelObj = axisLabels.find(labelObj => labelObj.value === v);
                return labelObj === undefined ? '' : labelObj.label;
              },
              color: '#9E9E9E',
              fontWeight: 'normal',
              fontSize: 11,
              shadowBlur: 0,
              rich: axisStyles
            },
            splitLine: {
              // 分隔线
              show: false // 默认显示，属性show控制显示与否
            },
            pointer: {
              length: '40%',
              width: 7
            },
            itemStyle: {
              color: '#3966f8',
              shadowBlur: 0
            },
            title: {
              show: false,
              offsetCenter: [0, 0], // x, y，单位px
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: '#333',
                fontSize: 13
              }
            },
            detail: {
              show: true, //disable showing xx/100 score in UI
              borderWidth: 0,
              borderColor: '#ccc',
              offsetCenter: [0, 0], // x, y，单位px
              // formatter: '',
              textStyle: {
                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                color: 'transparent',
                fontSize: 30
              },
              backgroundColor: {
                image: getCenterImage(score)
              },
              height: '31%',
              width: '25%',
              rich: {}
            },
            tooltips: {
              formatter: '{a} <br/>{b} : {c}%'
            }
          }
        ]
      };
    };
  }

  render() {
    const { intl, policyType, score } = this.props;

    return (
      <div>
        <h4 className="text-center greyL6">
          {policyType === POLICY_TYPE.LIFE
            ? intl.formatMessage({ id: 'Life Protection Score' })
            : intl.formatMessage({ id: 'C.I. Protection Score' })}
        </h4>

        <div id="canvas-holder" className="Outer" style={{ height: '160px' }}>
          <ReactEcharts option={this.csChartOption(policyType, score)} />
        </div>
        <br />
      </div>
    );
  }
}

// module.exports.getSupportImage = getSupportImage;
// module.exports.getShortTextWithColor = getShortTextWithColor;

export default injectIntl(CoverageChartView);
