import React from 'react';
import { Checkbox } from '@material-ui/core';
import { isSubscriptionAllowAction } from '../../../../utils/user-subscription-store';

class ReminderActiveControl extends React.PureComponent {
  render() {
    const { fsReminderActive, onChange, className, ContainerProps, ...rest } = this.props;

    return (
      <span className={`checkbox-right checkbox_back-color ${className ? className : ''}`} {...ContainerProps}>
        <Checkbox
          name="fsReminderActive"
          value="fsReminderActive"
          checked={fsReminderActive}
          onChange={event => {
            if (!isSubscriptionAllowAction({ type: 'core', nicknames: ['Pro', 'Ultra'] })) return;
            onChange(event.target.checked);
          }}
          color="primary"
          {...rest}
        />
      </span>
    );
  }
}

export default ReminderActiveControl;
