import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { authenticateUser } from '../../store/auth/duck';
import { usePrevious } from '../../utils/hooks';
import { CircularProgress } from '@material-ui/core';

const ContainerStyle = {
  position: 'relative',
  height: '100vh',
  overflow: 'hidden'
};

const ProgressContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const Auth = props => {
  const { initialAuthDone, authenticateUser } = props;

  const previousInitialAuthDone = usePrevious(initialAuthDone);

  useEffect(() => {
    if (previousInitialAuthDone !== false && initialAuthDone === false) {
      authenticateUser();
    }
  }, [initialAuthDone, previousInitialAuthDone, authenticateUser]);

  return (
    <div style={ContainerStyle}>
      <div style={ProgressContainerStyle}>
        <CircularProgress />
      </div>
    </div>
  );
};

const container = connect(
  state => ({
    initialAuthDone: state.auth.initialAuthDone,
    isAuthenticated: state.auth.isAuthenticated,
    authenticatedUser: state.auth.authenticatedUser,
    authenticationError: state.auth.authenticationError
  }),
  {
    authenticateUser
  }
)(Auth);

export default container;
