import { fetch } from '../../utils';
import Backend from '../../backend';

export const getChequeWizard = async () => {
  return fetch(Backend.methodUrl('cheque-wizards'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateChequeWizard = async data => {
  return fetch(Backend.methodUrl('cheque-wizards'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
