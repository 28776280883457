import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../../Transition/Transition';
import { resendConnectionRequest } from '../../../store/connectionRequest/api';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';

const ResendDialog = props => {
  const { intl, resendDialog, toggleDialog } = props;

  const [loading, setLoading] = useState(false);

  const { closed, client } = resendDialog || {};
  const { connectionRequestId } = client || {};

  const close = () => !loading && toggleDialog('resend', { ...resendDialog, closed: true });
  const onExited = () => toggleDialog('resend', false);
  const confirm = async () => {
    if (connectionRequestId) {
      try {
        setLoading(true);
        const response = await resendConnectionRequest(connectionRequestId._id);
        if (response.success) {
          toggleDialog('connect', false);
          toggleDialog('resend', false);
          toggleDialog('shareConfirm', { client: client, mode: 'connect' });
        } else {
          toast.info(IconToast('error', response.message), { className: 'new-toast' });
        }
      } catch (error) {
        toast.info(IconToast('error', error.message), { className: 'new-toast' });
      } finally {
        setLoading(false);
      }
    } else {
      toggleDialog('connect', { ...resendDialog });
    }
  };

  return !resendDialog ? null : (
    <Dialog
      open={!!resendDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'resend-connect' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'resendClient_email' })}</DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" disabled={loading}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={confirm} color="primary" disabled={loading} autoFocus>
          {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  // Map state to props
  state => ({
    resendDialog: state.control.resendDialog
  }),
  // Map actions to props
  {
    toggleDialog
  }
)(ResendDialog);

export default injectIntl(container);
