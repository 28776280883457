export const MEDIA_UPLOAD_TYPE = {
  PORTFOLIO: 'portfolio',
  ADVISER: 'adviser',
  AVATAR: 'avatar',
  ADVISER_VISION_BOARD: 'adviser_vision_board',
  ADVISER_LOGO: 'adviser_logo',
  TEAM_BLAST: 'team_blast',
  ADVISER_BLAST: 'adviser_blast',
  TEAM: 'team',
};
