import { fetch } from '../../utils';
import Backend from '../../backend';

export const createRoom = data => {
  return fetch(Backend.methodUrl('createConfRoom'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
