import React, { Fragment } from 'react';

const BallStyle = {
  background: '#c3e0fd',
  borderRadius: '50%',
  position: 'absolute'
};

const BALLS = [
  {
    left: 15,
    bottom: 35,
    size: 65
  },
  {
    left: 90,
    top: 30,
    size: 20
  },
  {
    right: 10,
    top: 10,
    size: 40
  },
  {
    right: 42,
    top: 32,
    size: 30
  },
  {
    right: 80,
    top: 70,
    size: 15
  }
];

const AdviserSwiperDecoration = props => {
  return (
    <Fragment>
      {BALLS.map((ball, index) => {
        const { size, ...rest } = ball;
        return (
          <div
            key={index}
            style={{
              ...BallStyle,
              ...rest,
              width: size,
              height: size
            }}
          />
        );
      })}
    </Fragment>
  );
};

export default AdviserSwiperDecoration;
