import Rx from 'rxjs/Rx';
import { combineEpics } from 'redux-observable';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';
import * as api from './api';
import { Record } from 'immutable';

/***********************************
 * Action Types
 ***********/
export const CREATE_QR_CODE = 'portfoplus/qrCode/CREATE_QR_CODE';
export const CREATE_QR_CODE_SUCCESS = 'portfoplus/qrCode/CREATE_QR_CODE_SUCCESS';
export const CREATE_QR_CODE_ERROR = 'portfoplus/qrCode/CREATE_QR_CODE_ERROR';

export const CHECK_QR_CODE = 'portfoplus/qrCode/CHECK_QR_CODE';
export const CHECK_QR_CODE_SUCCESS = 'portfoplus/qrCode/CHECK_QR_CODE_SUCCESS';
export const CHECK_QR_CODE_ERROR = 'portfoplus/qrCode/CHECK_QR_CODE_ERROR';

export const USE_QR_CODE = 'portfoplus/qrCode/USE_QR_CODE';
export const USE_QR_CODE_SUCCESS = 'portfoplus/qrCode/USE_QR_CODE_SUCCESS';
export const USE_QR_CODE_ERROR = 'portfoplus/qrCode/USE_QR_CODE_ERROR';

export const CLEAN_QR_CODE = 'portfoplus/qrCode/CLEAN_QR_CODE';

/***********************************
 * Initial State
 ***********/
const InitialStateInterface = {
  fetchPhase: INIT,
  resMessage: null,
  error: null,
  qrCode: undefined,
  useResult: undefined
};

class InitialState extends Record(InitialStateInterface) {}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  const { payload } = action;

  switch (action.type) {
    /***********************************
     * Create QR Code
     ***********/
    case CREATE_QR_CODE: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('qrCode', undefined);
    }

    case CREATE_QR_CODE_SUCCESS: {
      return state
        .set('resMessage', payload.message)
        .set('error', payload.success ? null : 'Rejected request')
        .set('fetchPhase', SUCCESS)
        .set('qrCode', payload.qrCode);
    }

    case CREATE_QR_CODE_ERROR: {
      return state
        .set('error', payload.error)
        .set('fetchPhase', ERROR)
        .set('qrCode', undefined);
    }

    /***********************************
     * Check QR Code
     ***********/
    case CHECK_QR_CODE: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('qrCode', undefined);
    }

    case CHECK_QR_CODE_SUCCESS: {
      return state
        .set('resMessage', payload.message)
        .set('error', payload.error)
        .set('fetchPhase', SUCCESS)
        .set('qrCode', payload.qrCode);
    }

    case CHECK_QR_CODE_ERROR: {
      return state
        .set('error', payload.error)
        .set('fetchPhase', ERROR)
        .set('qrCode', undefined);
    }

    /***********************************
     * Use QR Code
     ***********/
    case USE_QR_CODE: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('useResult', undefined);
    }

    case USE_QR_CODE_SUCCESS: {
      return state
        .set('resMessage', payload.message)
        .set('error', payload.error)
        .set('fetchPhase', SUCCESS)
        .set('useResult', payload.success);
    }

    case USE_QR_CODE_ERROR: {
      return state
        .set('error', payload.error)
        .set('fetchPhase', ERROR)
        .set('useResult', undefined);
    }

    /***********************************
     * Clean QR Code
     ***********/
    case CLEAN_QR_CODE: {
      return state
        .set('fetchPhase', INIT)
        .set('resMessage', null)
        .set('error', null)
        .set('qrCode', undefined)
        .set('useResult', undefined);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const createQRCode = (type, content) => ({
  type: CREATE_QR_CODE,
  payload: {
    type: type,
    content: content
  }
});

export const checkQRCode = id => ({
  type: CHECK_QR_CODE,
  payload: {
    id: id
  }
});

export const useQRCode = (id, extras) => ({
  type: USE_QR_CODE,
  payload: {
    id: id,
    extras: extras
  }
});

export const cleanQRCode = () => ({
  type: CLEAN_QR_CODE
});

/***********************************
 * Epics
 ***********/

const createQRCodeEpic = action$ =>
  action$.ofType(CREATE_QR_CODE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.createQRCode(action.payload))
      .map(payload => ({
        type: CREATE_QR_CODE_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: CREATE_QR_CODE_ERROR,
          payload: { error }
        })
      );
  });

const checkQRCodeEpic = action$ =>
  action$.ofType(CHECK_QR_CODE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.checkQRCode(action.payload))
      .map(payload => ({
        type: CHECK_QR_CODE_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: CHECK_QR_CODE_ERROR,
          payload: { error }
        })
      );
  });

const useQRCodeEpic = action$ =>
  action$.ofType(USE_QR_CODE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.useQRCode(action.payload))
      .map(payload => ({
        type: USE_QR_CODE_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: USE_QR_CODE_ERROR,
          payload: { error }
        })
      );
  });

export const qrCodeEpic = combineEpics(createQRCodeEpic, checkQRCodeEpic, useQRCodeEpic);
