import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const RemarkSection = props => {
  const { intl } = props;

  return (
    <Grid container alignItems="center" justify="flex-start" spacing={2}>
      <Grid item>
        <Grid container alignItems="center" justify="flex-start" spacing={1}>
          <Grid item>
            <WarningRoundedIcon color="error" style={{ verticalAlign: 'middle' }} />
          </Grid>
          <Grid item>
            <Typography color="textSecondary">{intl.formatMessage({ id: 'fin-py-remark-1' })}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justify="flex-start" spacing={1}>
          <Grid item>
            <WarningRoundedIcon style={{ color: '#ffa600', verticalAlign: 'middle' }} />
          </Grid>
          <Grid item>
            <Typography color="textSecondary">{intl.formatMessage({ id: 'fin-py-remark-2' })}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default injectIntl(RemarkSection);
