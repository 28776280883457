import fx from 'money';

export const getCurrencyExchangeRate = data => {
  fx.rates = data;
  return fx.rates;
};

export const currencyExchange = (amount, userCurrency = 'HKD', policyCurrency = 'USD') => {
  try {
    if (userCurrency === policyCurrency) {
      return amount;
    } else {
      let rate = fx(amount)
        .from(policyCurrency)
        .to(userCurrency);
      return rate;
    }
  } catch (e) {
    return amount;
  }
};

export const annualFrequency = (terms, frequency) => {
  var premiumTime =
    terms === '' || terms === 'Yearly'
      ? 12 / parseFloat(frequency) //to get yearly data
      : 1 / parseFloat(frequency); //to get monthly data
  return premiumTime;
};
