import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { updateUserDetails } from '../../store/user/duck';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import './SayThankYouModal.css';
import { createThankYouApi } from '../../store/thankYou/api';
import Lottie from '../Lottie/Lottie';

let showModalTimeout = undefined;
let TIMEOUT_DURATION = 10000;
let showCloseButtonTimeout = undefined;
let CLOSE_BUTTON_TIMEOUT_DURATION = 8000;

class SayThankYouModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      heartClicked: false,
      requesting: false,
      showCloseButton: false
    };
  }

  componentDidMount() {
    const { userDetails } = this.props;
    if (userDetails.userType === 'User' && userDetails.showedThankYouModal !== true) {
      const triggerCloseButtonTimeout = () =>
        (showCloseButtonTimeout = setTimeout(() => {
          if (this) {
            this.setState({ showCloseButton: true });
          }
        }, CLOSE_BUTTON_TIMEOUT_DURATION));

      if (showModalTimeout === undefined) {
        showModalTimeout = setTimeout(() => {
          if (this) {
            this.setState({ isOpen: true });
            triggerCloseButtonTimeout();
          }
        }, TIMEOUT_DURATION);
      } else {
        this.setState({ isOpen: true });
        triggerCloseButtonTimeout();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(showModalTimeout);
    showModalTimeout = undefined;
    clearTimeout(showCloseButtonTimeout);
    showCloseButtonTimeout = undefined;
  }

  closeModal() {
    this.props.updateUserDetails({ showedThankYouModal: true });
    this.setState({ isOpen: false });
  }

  clickHeart = async () => {
    const { requesting } = this.state;
    if (!requesting) {
      const { heartClicked } = this.state;

      if (!heartClicked) {
        this.setState({ requesting: true });
        const apiResult = await createThankYouApi();

        if (apiResult.success === true) {
          this.setState({ heartClicked: true, requesting: false });
          setTimeout(() => {
            if (this !== undefined && this !== null) {
              this.closeModal();
            }
          }, 1500);
        } else {
          this.setState({ requesting: false });
        }
      }
    }
  };

  render() {
    const { intl } = this.props;
    const { isOpen, heartClicked, requesting, showCloseButton } = this.state;

    return (
      <div>
        <Dialog className="say-thank-you-modal" open={isOpen}>
          <DialogTitle className="dialog-title">{intl.formatMessage({ id: 'say-thank-you-title' })}</DialogTitle>
          <DialogContent className="dialog-content">
            <DialogContentText>{intl.formatMessage({ id: 'say-thank-you-content' })}</DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <div>
              <div className="button-wrapper heart-wrapper">
                <Lottie
                  style={{
                    width: '150px',
                    height: '150px'
                  }}
                  options={{
                    animationData: 'animations/heart.json',
                    loop: false,
                    autoplay: false
                  }}
                  isPlaying={heartClicked}
                  onClick={this.clickHeart}
                />
              </div>
              <div className="button-wrapper">
                <Button
                  className="yes"
                  variant="contained"
                  color="primary"
                  onClick={() => this.clickHeart()}
                  disabled={requesting}
                >
                  <div className="text">{intl.formatMessage({ id: 'ok-thanks' })}</div>
                </Button>
              </div>
              {!heartClicked && showCloseButton && (
                <div className="button-wrapper" onClick={() => this.closeModal()}>
                  <Button className="no">{intl.formatMessage({ id: 'no-thanks' })}</Button>
                </div>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {
    updateUserDetails
  },
  // mergeProps
  null,
  // options
  {}
)(SayThankYouModal);

export default injectIntl(container);
