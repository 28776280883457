import React from 'react';
import { Dialog, FormControlLabel, Checkbox, DialogActions, Button, DialogTitle } from '@material-ui/core';
import Slider from 'react-slick';
import PreferenceQuestion from '../../views/WelcomePage/partials/Preferences/components/PreferenceQuestion';
import PreferenceHelp from '../../views/WelcomePage/partials/Preferences/components/PreferenceHelp';
import { withStyles } from '@material-ui/core/styles';
import { tracking } from '../../utils/tracking';

const themeStyles = {
  title: {
    paddingTop: 0,
    '& h2': {
      color: 'black',
      fontWeight: 400,
      fontSize: 15
    }
  },
  Typography: {
    // marginLeft: 10,
    textAlign: 'center'
  },
  topTitle: {
    paddingBottom: 0
  },
  portfolioTitle: {}
};

class PreferenceHelpDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      preferenceIndex: 0,
      hideHelpChecked: false
    };
  }

  onClose = () => {
    if (this.state.hideHelpChecked) {
      this.props.updateUserDetails({ hideHelpPopUp: this.state.hideHelpChecked });
    }
    this.setState({ index: 0, preferenceIndex: 0 });
    this.props.onClose();
  };

  toggleChecked = () => this.setState({ hideHelpChecked: !this.state.hideHelpChecked });

  render() {
    const { open, intl, classes, fromPortfolio, history } = this.props;
    const { index, preferenceIndex, hideHelpChecked } = this.state;
    let settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false
    };
    return (
      <Dialog open={open} onClose={this.onClose}>
        {index === 0 && (
          <div>
            {!fromPortfolio && (
              <DialogTitle classes={{ root: classes.topTitle }}>{intl.formatMessage({ id: 'help-title' })}</DialogTitle>
            )}
            <DialogTitle classes={{ root: fromPortfolio ? classes.portfolioTitle : classes.title }}>
              {intl.formatMessage({ id: 'preference-question' })}
            </DialogTitle>
          </div>
        )}
        {index === 1 && (
          <div>
            {preferenceIndex === 1 && <DialogTitle>{intl.formatMessage({ id: 'Add Policy' })}</DialogTitle>}
            {preferenceIndex !== 1 && <DialogTitle>{intl.formatMessage({ id: 'Connect Adviser' })}</DialogTitle>}
          </div>
        )}
        <Slider {...settings} ref={slider => (this.slider = slider)}>
          <PreferenceQuestion
            intl={intl}
            setPreference={preferenceIndex => {
              const event =
                preferenceIndex === 0
                  ? 'User Preference: Connect with adviser to manage'
                  : 'User Preference: Self-manage portfolio';
              // Tracking
              tracking(event);
              this.setState({ preferenceIndex, index: 1 }, () => setTimeout(this.slider.slickNext, 100));
            }}
            classes={classes}
          />
          <PreferenceHelp intl={intl} preferenceIndex={this.state.preferenceIndex} />
        </Slider>
        <DialogActions className="preference-actions">
          {index === 0 && (
            <div className={'actions-div' + (fromPortfolio ? ' flex-right' : '')}>
              {!fromPortfolio && (
                <div className="dun-show-again">
                  <FormControlLabel
                    color="primary"
                    className="wel-check-box dun-show-again-box"
                    control={<Checkbox color="primary" checked={hideHelpChecked} onClick={this.toggleChecked} />}
                    label={intl.formatMessage({ id: 'dun-show-again' })}
                  />
                </div>
              )}
              <Button onClick={this.onClose} color="primary">
                {intl.formatMessage({ id: 'Close' })}
              </Button>
            </div>
          )}
          {index === 1 && (
            <div className="actions-div">
              <div className="back-btn">
                <Button
                  className="action-btn"
                  onClick={() => this.setState({ index: 0 }, () => setTimeout(this.slider.slickPrev, 100))}
                  color="primary"
                >
                  {intl.formatMessage({ id: 'Back' })}
                </Button>
              </div>
              {preferenceIndex === 1 && (
                <Button
                  className="action-btn"
                  onClick={() => {
                    // Tracking
                    tracking('User preference: Open Add Policy page');
                    this.onClose();
                    history.push('/add_portfolio');
                  }}
                  color="primary"
                >
                  {intl.formatMessage({ id: 'add-policy' })}
                </Button>
              )}
              {preferenceIndex !== 1 && (
                <div className="main-actions">
                  <a
                    href="https://w3.portfoplus.com/help-center/function/#howtouse_connect"
                    target="_blank"
                    rel="noopener noreferrer"
                    // Tracking
                    onClick={() => tracking('User preference: Open Connect tutorial')}
                  >
                    <Button color="primary">{intl.formatMessage({ id: 'tutorial' })}</Button>
                  </a>
                  <Button
                    className={'action-btn' + (fromPortfolio ? ' with-portfolio-btn' : '')}
                    onClick={() => {
                      // Tracking
                      tracking('User preference: Open Share page to connect with Adviser');
                      this.onClose();
                      history.push('/share_options?connectWithAdviserDialog=true');
                    }}
                    color="primary"
                  >
                    {intl.formatMessage({ id: 'Connect' })}
                  </Button>
                </div>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(themeStyles)(PreferenceHelpDialog);
