import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import './AgeInput.css';
import { onKeyDownNumberOnly } from '../../../../utils/input';

class AgeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internalValue: props.value !== undefined ? props.value : 35
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { value } = nextProps;
    this.setState({ internalValue: value });
  }

  render() {
    const { intl, onFocus, onValueChange, styleAdjust, value, ...rest } = this.props;
    const { internalValue } = this.state;

    return (
      <TextField
        className={`analysis-input ${styleAdjust && styleAdjust}`}
        name="age"
        label={intl.formatMessage({ id: 'Age' })}
        value={internalValue}
        onChange={event => {
          let x = event.target.value;

          if (x < 0) x = 0;

          if (x > 120) x = 120;

          this.setState({ internalValue: x });
        }}
        type="number"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: 0,
          max: 70,
          pattern: '[0-9]*',
          onFocus: event => (onFocus ? onFocus(event) : null),
          onBlur: event => {
            onValueChange(event);
          },
          onKeyDown: onKeyDownNumberOnly(false)
        }}
        {...rest}
      />
    );
  }
}

export default AgeInput;
