import Phone from 'react-phone-number-input';
import countryList from '../../../store/locale/country-list';
import React from 'react';
import './PhoneNumberInputWrapper.scss';

export const PhoneNumberInputWrapper = props => {
  const { className, inputRef, onChange, intl, ...rest } = props;
  return (
    <Phone
      className={`phone-number-input-wrapper${className ? ` ${className}` : ''}`}
      {...rest}
      international={true}
      // inputComponent={SmartInput}
      country="HK"
      onChange={value => onChange({ target: { name: props.name, value: value } })}
      countries={countryList.labels}
      flagsPath={'img/flags/'}
      indicateInvalid
    />
  );
};
