export const DEFAULT_CATEGORIES = [
  {
    image: 'img/personalInsurance.png',
    subCategories: [
      {
        image: 'img/life.png',
        _id: '59b0ed2cdae1831be084f804',
        title: 'Life',
        value: '0',
        __v: 0
      },
      {
        image: 'img/illness.png',
        _id: '59b0ed6fdae1831be084f805',
        title: 'Critical Illness',
        value: '0',
        __v: 0
      },
      {
        image: 'img/medical.png',
        _id: '59b0ed8fdae1831be084f806',
        title: 'Medical',
        value: '0',
        __v: 0
      },
      {
        image: 'img/others.png',
        _id: '59b0ed98dae1831be084f807',
        title: 'Other',
        value: '0',
        __v: 0
      }
    ],
    _id: '59b0eaee14ea7f1aafb6d0b5',
    title: 'Personal',
    __v: 0
  },
  {
    image: 'img/companyInsurance.png',
    subCategories: [
      {
        image: 'img/life.png',
        _id: '59b0ed2cdae1831be084f804',
        title: 'Life',
        value: '0',
        __v: 0
      },
      {
        image: 'img/illness.png',
        _id: '59b0ed6fdae1831be084f805',
        title: 'Critical Illness',
        value: '0',
        __v: 0
      },
      {
        image: 'img/medical.png',
        _id: '59b0ed8fdae1831be084f806',
        title: 'Medical',
        value: '0',
        __v: 0
      },
      {
        image: 'img/others.png',
        _id: '59b0ed98dae1831be084f807',
        title: 'Other',
        value: '0',
        __v: 0
      }
    ],
    _id: '59b0eb2314ea7f1aafb6d0b6',
    title: 'Company',
    __v: 0
  },
  {
    image: 'img/generalInsurance.png',
    subCategories: [
      {
        image: 'img/general_saving.png',
        _id: '5baf2af899d85c2d38c8f00f',
        title: 'Saving',
        value: '0',
        __v: 0
      },
      {
        image: 'img/general_provident.png',
        _id: '5c18ebd120ca05b7e2b32565',
        title: 'Provident Fund',
        value: '0',
        __v: 0
      },
      {
        image: 'img/general_accident.png',
        _id: '5b9a5bfee624cdb543a476d2',
        title: 'Accident',
        value: '0',
        __v: 0
      },
      {
        image: 'img/home.png',
        _id: '59b0eddfdae1831be084f809',
        title: 'Home',
        value: '0',
        __v: 0
      },
      {
        image: 'img/maid.png',
        _id: '5b9a5bfee624cdb543a476d1',
        title: 'Maid',
        value: '0',
        __v: 0
      },
      {
        image: 'img/mortgage.png',
        _id: '5c41b85a83230016450b2214',
        title: 'Mortgage',
        value: '0',
        __v: 0
      },
      {
        image: 'img/motor.png',
        _id: '59b0edd8dae1831be084f808',
        title: 'Motor',
        value: '0',
        __v: 0
      },
      {
        image: 'img/travel.png',
        _id: '59b0ede7dae1831be084f80a',
        title: 'Travel',
        value: '0',
        __v: 0
      },
      {
        image: 'img/others.png',
        _id: '59b0ed98dae1831be084f807',
        title: 'Other',
        value: '0',
        __v: 0
      }
    ],
    _id: '59b0eb3814ea7f1aafb6d0b7',
    title: 'General',
    __v: 0
  }
];
