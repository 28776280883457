import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';

const CheckboxGridStyle = {
  textAlign: 'center'
};

const AdviserExportWarningDialog = props => {
  const { intl, open, onClose, onConfirm, ...rest } = props;

  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});

  const onChangeChecked = event => {
    setErrors({});
    setChecked(event.target.checked);
  };

  const formattedOnConfirm = () => {
    if (checked) {
      onConfirm();
      onClose();
    } else {
      setErrors({ checked: intl.formatMessage({ id: 'adviser-export-warning-dialog-checkbox-error' }) });
    }
  };

  const onExited = () => {
    setChecked(false);
    setErrors({});
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExited={onExited}
      fullWidth={true}
      TransitionComponent={SlideTransition}
      {...rest}
    >
      <DialogTitle>{intl.formatMessage({ id: 'adviser-export-warning-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" justify="center" spacing={1}>
          <Grid item>
            <Typography color="textSecondary">
              {intl.formatMessage({ id: 'adviser-export-warning-dialog-content' })}
            </Typography>
          </Grid>
          <Grid item style={CheckboxGridStyle}>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={onChangeChecked} />}
              label={
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>{intl.formatMessage({ id: 'adviser-export-warning-dialog-checkbox-label' })}</Grid>
                  {errors.checked && (
                    <Grid item>
                      <FormHelperText error={true}>{errors.checked}</FormHelperText>
                    </Grid>
                  )}
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disableElevation={true} onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={formattedOnConfirm}>{intl.formatMessage({ id: 'Confirm' })}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(AdviserExportWarningDialog);
