import React from 'react';
import { Button, Fade } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { isUserConnected } from '../../../../utils/user';
import { injectIntl } from 'react-intl';
import Slider from 'react-slick';
import Welcome from './components/Welcome';
import NickName from './components/Name';
import './Preferences.scss';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    paddingTop: '7%',
    background: 'white'
  },
  main: {
    flex: 1,
    justifyContent: 'center'
  },
  bottomBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  tabs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '5%',
    marginBottom: '5%'
  }
};

class Preference extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      fadeIn: false,
      name: props.userDetails.name ? props.userDetails.name : '',
      preference: 0,
      error: null
    };
  }

  onConfirm = () => {
    if (this.state.index === 1) {
      this.onDone();
    } else {
      this.slider.slickNext();
    }
  };
  setName = val => this.setState({ name: val, error: null });
  setPreference = val => this.setState({ preference: val });

  componentDidMount() {
    setTimeout(() => this.setState({ fadeIn: true }), 300);
  }

  onDone = () => {
    const { intl, setShownDashHelp, updateFirstGuidelineState, userDetails } = this.props;
    if (this.state.name === '' || this.state.name.trim() === '') {
      this.setState({ error: intl.formatMessage({ id: 'Name is required' }) });
    } else if (!this.state.error) {
      if (!isUserConnected(userDetails)) {
        setShownDashHelp(false);
      }
      updateFirstGuidelineState(false);
      this.props.onClose({ name: this.state.name });
    }
  };

  render() {
    const { intl } = this.props;
    let settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: isMobile,
      afterChange: index => this.setState({ index })
    };
    return (
      <Fade in={this.state.fadeIn} timeout={700}>
        <div style={styles.container}>
          <div style={styles.main} className="preference">
            <Slider {...settings} ref={slider => (this.slider = slider)}>
              <Welcome {...this.props} next={this.onConfirm} skip={this.props.onClose} />
              <NickName {...this.props} setName={this.setName} error={this.state.error} name={this.state.name} />
            </Slider>
            <div className="prefBtn">
              <Button style={{ background: '#3966f8', width: '100%' }} onClick={this.onConfirm}>
                {intl.formatMessage({ id: this.state.index === 0 ? `joyride_next` : 'here-we-go' })}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default injectIntl(Preference);
