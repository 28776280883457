import { getWebImageUrl } from '../../../utils/image';
import { DEFAULT_FINANCIAL_PYRAMID } from './constants';
import _ from 'lodash';

export const formatPyramidData = (riskData, investmentData, savingData, protectionData, monthlyIncome) => {
  const reduceData = data =>
    (data || []).reduce(
      (accumulator, current) => {
        if (current) {
          accumulator.asset += current.value || 0;
          accumulator.monthlyPayment += current.monthlyPayment || 0;
        }
        return accumulator;
      },
      { asset: 0, monthlyPayment: 0 }
    );

  const formattedPercentage = (value, total) => (total ? (value / total) * 100 : undefined);

  const formattedRiskData = reduceData(riskData);
  const formattedInvestmentData = reduceData(investmentData);
  const formattedSavingData = reduceData(savingData);
  const formattedProtectionData = reduceData(protectionData);

  const totalAsset = formattedRiskData.asset + formattedInvestmentData.asset + formattedSavingData.asset;

  const premiumIncomePercentage = formattedPercentage(formattedProtectionData.monthlyPayment, monthlyIncome);

  return {
    totalAsset: totalAsset,
    risk: {
      asset: formattedRiskData.asset,
      monthlyPayment: formattedRiskData.monthlyPayment,
      assetPercentage: formattedPercentage(formattedRiskData.asset, totalAsset),
      monthlyPaymentPercentage: formattedPercentage(formattedRiskData.monthlyPayment, monthlyIncome)
    },
    investment: {
      asset: formattedInvestmentData.asset,
      monthlyPayment: formattedInvestmentData.monthlyPayment,
      assetPercentage: formattedPercentage(formattedInvestmentData.asset, totalAsset),
      monthlyPaymentPercentage: formattedPercentage(formattedInvestmentData.monthlyPayment, monthlyIncome)
    },
    saving: {
      asset: formattedSavingData.asset,
      monthlyPayment: formattedSavingData.monthlyPayment,
      assetPercentage: formattedPercentage(formattedSavingData.asset, totalAsset),
      monthlyPaymentPercentage: formattedPercentage(formattedSavingData.monthlyPayment, monthlyIncome)
    },
    protection: {
      asset: formattedProtectionData.asset,
      monthlyPayment: formattedProtectionData.monthlyPayment,
      premiumIncomePercentage: premiumIncomePercentage,
      monthlyPaymentPercentage: formattedPercentage(formattedProtectionData.monthlyPayment, monthlyIncome)
    }
  };
};

export const formatPremiumIncomePercentageDisplay = percentage =>
  percentage ? `${percentage ? percentage.toFixed(2) : 0}%` : '-';

export const getHandwritingImage = locale => {
  let src;
  switch (locale) {
    case 'zh-Hant-HK':
      src = 'img/financial-pyramid/triangle_frame.png';
      break;
    case 'zh':
      src = 'img/financial-pyramid/triangle_frame_simplified.png';
      break;
    default:
      src = 'img/financial-pyramid/triangle_frame_eng.png';
  }

  return getWebImageUrl(src);
};

export const getDefaultFinancialPyramidCopy = language => _.cloneDeep(DEFAULT_FINANCIAL_PYRAMID.get(language));
