import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment } from 'react';
import './StandardContentItem.scss';
import { toast } from 'react-toastify';
import GetAppIcon from '@material-ui/icons/GetApp';
import { stripHTML } from '../../../../utils/helpers';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';
import IconToast from '../../../NewToast';
import { tracking } from '../../../../utils/tracking';
import { toggleDialog } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { ShortSubscriptions } from '../../../../utils/user-subscription';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { interpolateString } from '../../../../utils/string';
import ShareButtonGroup from '../../../ShareButtonGroup/ShareButtonGroup';
import ZoomIn from '@material-ui/icons/ZoomIn';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import moment from 'moment-timezone';
import { getHotTopicItemShareUrl } from "../../../../utils/hot-topic";

const PFPLUS_URL = process.env.PFPLUS_URL;

const StandardContentItem = props => {
  const {
    intl,
    history,
    userDetails,
    subscriptions,
    item,
    expanded,
    onChange,
    onExited,
    onEntered,
    withoutExpansion,
    alwaysBreak,
    eventType,
    toggleDialog,
    dialogLayout
  } = props;

  const isItemFreeTrialExcluded =
    item.excludeFreeTrial && new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();

  const externalPiktoLinkWithoutSearch = getHotTopicItemShareUrl(item, intl.locale);
  const externalPiktoLink = getHotTopicItemShareUrl(item, intl.locale, { aId: _.get(userDetails, '_id', '') });

  const globalVariables = { externalPiktoLinkWithoutSearch, externalPiktoLink };

  const download = () => {
    if (isItemFreeTrialExcluded) {
      toggleDialog('freeTrialLazyPackExcluded', {});
    } else {
      window.open(item.pdfLink);

      if (eventType) {
        let eventName;
        switch (eventType) {
          case 'hot topic':
            // due to historic problems, event name is not in sync with the actual thing
            eventName = 'Adviser download in help center';
            break;
          case 'help center':
            // due to historic problems, event name is not in sync with the actual thing
            eventName = 'Adviser download in help center (real)';
            break;
          default:
            eventName = `Adviser download in ${eventType}`;
        }
        tracking(eventName, { id: item.id });
      }
    }
  };

  const pikto = () => {
    if (isItemFreeTrialExcluded) {
      toggleDialog('freeTrialLazyPackExcluded', {});
    } else {
      if (eventType === 'hot topic') {
        tracking('Click on Hot Topic - Preview');
      }

      window.open(externalPiktoLink, '_blank');
    }
  };

  const whatsapp = t => {
    if (isItemFreeTrialExcluded) {
      toggleDialog('freeTrialLazyPackExcluded', {});
    } else {
      if (eventType === 'hot topic') {
        tracking('Click on Hot Topic - Whatsapp Share');
      }
      window.open(
        `https://api.whatsapp.com/send?&text=${stripHTML(t)}%0a${encodeURIComponent(externalPiktoLink)}`,
        '_blank'
      );
    }
  };

  const graphicLink = async () => {
    toggleDialog('iframe', { title: intl.formatMessage({ id: 'click-graphic-link' }), url: item.graphicLink });
  };

  const afterCopy = () => {
    if (isItemFreeTrialExcluded) {
      toggleDialog('freeTrialLazyPackExcluded', {});
    } else {
      if (eventType === 'hot topic') {
        tracking('Click on Hot Topic - Copy');
      }
      toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
        className: 'new-toast'
      });
    }
  };

  const shop = () => history.push('/purchase-lazy-pack');

  const title = <div>{item.title[intl.locale]}</div>;

  const actions = (
    <div className="buttons-wrapper">
      {item.graphicLink && (
        <Grid container spacing={1}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={graphicLink} className="basic-button">
              {intl.formatMessage({ id: 'click-graphic-link' })}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        {item.pdfLink && item.pdfLink.trim() !== '' && (
          <Fragment>
            <Grid item>
              <Button variant="contained" color="primary" onClick={download}>
                {/* {intl.formatMessage({ id: 'download-pdf' })} */}
                <GetAppIcon />
              </Button>
            </Grid>
            {isItemFreeTrialExcluded && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={shop}>
                  <LocalMallRoundedIcon />
                </Button>
              </Grid>
            )}
          </Fragment>
        )}
      </Grid>
    </div>
  );

  const video = item.videoId && item.videoId.trim() !== '' && (expanded || withoutExpansion) && (
    <VideoPlayer url={item.videoId} />
  );

  const content = (
    <Grid container>
      {!dialogLayout && (
        <Grid item xs={12} sm={alwaysBreak ? 12 : 6}>
          {video}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={!dialogLayout ? (alwaysBreak ? 12 : item.videoId && item.videoId.trim() !== '' ? 6 : 12) : 12}
      >
        <Grid container direction="column">
          <Grid item>
            <div className="texts-wrapper">
              {item.texts[intl.locale].map((text, index) => (
                <div key={index} className="text-wrapper">
                  <div style={{ margin: '10px 0' }}>
                    <div className="text-title" dangerouslySetInnerHTML={{ __html: text.subtitle || '' }} />
                    <div
                      className="text-description"
                      dangerouslySetInnerHTML={{ __html: interpolateString(text.description, globalVariables) || '' }}
                    />
                    {item.piktoId && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={isItemFreeTrialExcluded ? { color: '#FFFFFF', backgroundColor: '#4F1271' } : {}}
                          startIcon={isItemFreeTrialExcluded ? <LockOutlinedIcon /> : <ZoomIn />}
                          onClick={pikto}
                        >
                          {intl.formatMessage({ id: 'preview' })}
                        </Button>
                      </Grid>
                    )}
                  </div>
                  {item.suggestions && item.suggestions.length > 0 && (
                    <Grid container direction="column" style={{ margin: '20px 0 10px 0' }}>
                      <Grid item>
                        <Typography>{intl.formatMessage({ id: 'hot-topic-suggestions' })}</Typography>
                      </Grid>
                      {item.suggestions.map(suggestion => {
                        const onClick = event => {
                          event.preventDefault();
                          history.push({ pathname: '/new_help_center', hash: suggestion.id });
                        };
                        return (
                          <Grid item key={suggestion.id}>
                            <a
                              href={`${window.location.pathname}#${suggestion.id}`}
                              style={{ color: '#3966F8', textDecoration: 'underline' }}
                              onClick={onClick}
                            >
                              <Typography>{suggestion.title[intl.locale]}</Typography>
                            </a>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                  {(item.updatedAt || item.createdAt) && (
                    <Typography color="textSecondary" gutterBottom={true}>
                      {`${intl.formatMessage({ id: 'Last Update' })}: ${moment(item.updatedAt || item.createdAt).format(
                        'D-MMM-YYYY'
                      )}`}
                    </Typography>
                  )}
                  {text['copy-text'] &&
                    text['copy-text'].length > 0 &&
                    text['copy-text'].map((t, copyTextIndex) => {
                      t = interpolateString(t, globalVariables);

                      return (
                        <div className="text-copy" key={copyTextIndex}>
                          <ShareButtonGroup
                            title={t.replace(/<br\s*[\/]?>/gi, '\n').replace(/<\/?[^>]+(>|$)/g, '')}
                            url={externalPiktoLink}
                            isCTA
                            locked={
                              isItemFreeTrialExcluded ? () => toggleDialog('freeTrialLazyPackExcluded', {}) : null
                            }
                            onShare={
                              eventType === 'hot topic'
                                ? () => tracking('Click on Hot Topic - Native share')
                                : undefined
                            }
                            onWhatsapp={
                              eventType === 'hot topic'
                                ? () => tracking('Click on Hot Topic - Whatsapp Share')
                                : undefined
                            }
                            CopyTextFieldProps={{
                              multiline: true
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          </Grid>
          <Grid item>{actions}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return !withoutExpansion ? (
    <ExpansionPanel
      id={item.id}
      className="standard-content-item"
      expanded={expanded}
      onChange={onChange}
      TransitionProps={{ onExited: onExited, onEntered: onEntered }}
    >
      <ExpansionPanelSummary className="item-title" expandIcon={<ExpandMoreIcon />}>
        {title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="item-details">{content}</ExpansionPanelDetails>
    </ExpansionPanel>
  ) : (
    <div className="standard-content-item no-expansion">
      {dialogLayout && <div className="item-video">{video}</div>}
      <div className="item-title">{title}</div>
      <div className="item-details">{content}</div>
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions
  }),
  {
    toggleDialog
  }
)(StandardContentItem);

export default injectIntl(withRouter(container));
