import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    CircularProgress,
    TextField,
    IconButton,
    InputAdornment,
    MenuItem
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { withRouter } from 'react-router';
import BottomSheetOrDialog from '../../../components/BottomSheetOrDialog/BottomSheetOrDialog';
import { StripeElementWrapper } from '../../../components/StripeElement/StripeElementWrapper';
import { getChangeablePlansApi, changePlanApi, getCouponApi, cancelPendingSubscriptionChangeApi } from '../../../store/userSubscription/api';
import { translationKeyMap } from '../../../utils/user-subscription';

const ChangeSubscriptionPlan = props => {
    const {
        history,
        intl,
        userDetails,
        stripePromise,
        open,
        onClose,
        currentSubscription,
        currentSubscriptionPlan,
        trialing,
        defaultPaymentMethod,
        fetchPaymentMethod,
        currentCredits,
        getCustomerCredits,
        defaultCoupon
    } = props;

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [option, setOption] = useState("");
    const [coupon, setCoupon] = useState(null);
    const [couponId, setCouponId] = useState("");
    const [couponValue, setCouponValue] = useState(0);
    const [invalidCoupon, setInvalidCoupon] = useState(false);
    const [changeablePlans, setChangeablePlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [extension, setExtension] = useState(0);
    const [credits, setCredits] = useState(0);
    const [clientSecret, setClientSecret] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const theme = useTheme();

    useEffect(() => {
        async function getChangeablePlans() {
            let result = await getChangeablePlansApi(currentSubscription._id);
            if (result.success) {
                setExtension(result.extension);
                setCredits(result.credits);
                setChangeablePlans(result.changeablePlans);
                if (result.changeablePlans.length === 1) {
                    let plan = result.changeablePlans[0];
                    if (defaultCoupon) {
                        applyCoupon(defaultCoupon, plan._id);
                    }
                    setSelectedPlan(plan);
                    if (plan.options.length === 1) {
                        setOption(plan.options[0]);
                    } else {
                        setOption("");
                    }
                } else if (result.changeablePlans.length > 1 && defaultCoupon) {
                    applyCoupon(defaultCoupon);
                }
            }
        }

        if (open) {
            setStep(1);
            setOption("");
            setCoupon(null);
            setCouponId(defaultCoupon);
            setCouponValue(0);
            setInvalidCoupon(false);
            setChangeablePlans([]);
            setSelectedPlan(null);
            setExtension(0);
            setCredits(0);
            setErrorMessage("");
            if (clientSecret) {
                cancelPendingSubscriptionChangeApi();
            }
            setClientSecret("");
            getChangeablePlans();
        }
    }, [open]);

    async function changePlan(paymentMethodId) {
        setErrorMessage("");
        setLoading(true);

        let couponResult = await getCouponApi(couponId, selectedPlan._id);
        if (couponResult?.coupon) {
            setInvalidCoupon(false);
            setCoupon(couponResult.coupon);
            setCouponValue(couponResult.couponValue);
        }

        let finalCouponValue = couponResult.couponValue || 0;
        if (option === 'endWithCredits') {
            var authentication = currentCredits + credits >= selectedPlan.amount - finalCouponValue;
        } else {
            var authentication = currentCredits + finalCouponValue >= selectedPlan.amount;
        }

        let result = await changePlanApi(currentSubscription._id, selectedPlan._id, couponId, option, paymentMethodId, authentication);
        setLoading(false);
        if (result.success) {
            if (result.finalized) {
                proceedToSuccess();
            } else {
                setClientSecret(result.clientSecret);
                setStep(2);
            }
            if (result.message) {
                setErrorMessage(result.message);
            }
        } else {
            setErrorMessage(result.message);
        }
    }

    async function applyCoupon(couponId, planId) {
        if (couponId) {
            let result = await getCouponApi(couponId, planId);
            if (result?.coupon) {
                setInvalidCoupon(false);
                setCoupon(result.coupon);
                setCouponValue(result.couponValue || 0);
            } else {
                setInvalidCoupon(true);
                setCoupon("");
                setCouponValue(0);
            }
        } else {
            setInvalidCoupon(false);
            setCoupon("");
            setCouponValue(0);
        }
    }

    function selectPlan(event) {
        setErrorMessage("");
        if (clientSecret) {
            cancelPendingSubscriptionChangeApi();
            setClientSecret("");
        }

        let newPlan = changeablePlans.find(changeablePlan => changeablePlan._id === event.target.value);
        setSelectedPlan(newPlan);
        if (newPlan.options.length === 1) {
            setOption(newPlan.options[0]);
        } else {
            setOption("");
        }
        if (couponId) {
            applyCoupon(couponId, newPlan._id);
        }
    };

    function proceedToEnterCreditCard() {
        if (clientSecret) {
            setErrorMessage("");
            setStep(2);
        } else {
            changePlan(null);
        }
    }

    function completePayment() {
        getCustomerCredits();
        fetchPaymentMethod();
        proceedToSuccess();
    }

    function proceedToSuccess() {
        setErrorMessage("");
        setStep(3);
    }

    function refreshPage() {
        if (!loading) {
            setLoading(true);
            if (clientSecret) {
                var delay = 8000;
            } else {
                var delay = 1000;
            }
            const timer = setTimeout(async () => {
                history.go(0);
            }, delay);
            return () => clearTimeout(timer);
        }
    }

    function onChangeCouponId(event) {
        setCouponId(event.target.value);
        setInvalidCoupon(false);
        setErrorMessage("");
        if (clientSecret) {
            cancelPendingSubscriptionChangeApi();
            setClientSecret("");
        }
    }

    function selectOption(event) {
        setOption(event.target.value);
        if (clientSecret) {
            cancelPendingSubscriptionChangeApi();
            setClientSecret("");
        }
    }

    function calculateDisplayPrice(plan, coupon) {
        let price = 0;
        if (!coupon) {
            price = plan.amount / 100;
        } else if (coupon.percentageOff) {
            price = Math.round(plan.amount * (1 - coupon.percentageOff / 100)) / 100;
        } else if (coupon.amountOff) {
            price = (plan.amount - coupon.amountOff) / 100
        }
        if (price < 0) {
            price = 0;
        }
        return price;
    }

    function getCardIconSrc(brand) {
        switch (brand.toLowerCase()) {
            case 'visa':
                return 'img/payments/visa.svg';
            case 'mastercard':
                return 'img/payments/mastercard.svg';
            case 'amex':
                return 'img/payments/american_express.svg';
            case 'jcb':
                return 'img/payments/jcb.svg';
            case 'unionpay':
                return 'img/payments/unionpay.svg';
            default:
                return 'img/payments/generic.svg';
        }
    };


    if (selectedPlan) {
        let newEndDate = moment().add(selectedPlan.intervalCount, selectedPlan.interval).add(extension, "days");
        if (newEndDate.diff(moment(), "days") >= 730) {
            var disableExtension = true;
        } else {
            var disableExtension = false;
        }

        if (option === "endWithCredits") {
            var amountDueToday = (selectedPlan.amount - couponValue - credits - currentCredits) / 100;
        } else if (option === "scheduleChange") {
            var amountDueToday = 0;
        } else {
            var amountDueToday = (selectedPlan.amount - couponValue - currentCredits) / 100;
        }
        if (amountDueToday < 0) {
            amountDueToday = 0;
        }

        if (coupon && (coupon.duration === "forever" || (coupon.duration === "repeating" && coupon.InMonths !== 1))) {
            var amountDueNextBillingPeriod = (selectedPlan.amount - couponValue) / 100;
        } else {
            var amountDueNextBillingPeriod = selectedPlan.amount / 100
        }

        if (option === "endWithExtension") {
            var nextBillingDate = moment().add(extension, "days").add(selectedPlan.intervalCount, selectedPlan.interval);
        } else {
            var nextBillingDate = moment().add(selectedPlan.intervalCount, selectedPlan.interval);
        }

        if (intl.locale === "zh-Hant-HK" || intl.locale === "zh") {
            var formattedNextBillingDate = nextBillingDate.format('YYYY年M月D日');
            var currentSubscriptionExpiredAt = moment(currentSubscription.expiredAt).format('YYYY年M月D日');
        } else {
            var formattedNextBillingDate = nextBillingDate.format('DD MMM YYYY');
            var currentSubscriptionExpiredAt = moment(currentSubscription.expiredAt).format('DD MMM YYYY');
        }
    }

    return (
        <BottomSheetOrDialog
            className="policy-review-edit-layout-dialog"
            open={open}
            onClose={step === 3 ? refreshPage : onClose}
            header={
                trialing ?
                    currentSubscriptionPlan.interval === "year" ?
                        intl.formatMessage({ id: 'start-annual-plan-early' }) :
                        intl.formatMessage({ id: 'start-monthly-plan-early' }) :
                    intl.formatMessage({ id: 'change-subscription-plan' })
            }
            content={
                <>
                    {step === 1 &&
                        <>
                            {!trialing &&
                                <>
                                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'current-plan' })} </Typography>
                                    <Typography style={{ fontSize: 15 }}>
                                        {intl.locale !== "en" && (currentSubscriptionPlan.intervalCount === 1 ?
                                            intl.formatMessage({ id: `per-${currentSubscriptionPlan.interval}` }) :
                                            intl.formatMessage({ id: `for-number-${currentSubscriptionPlan.interval}` }, { number: currentSubscriptionPlan.intervalCount })
                                        )}
                                        {currentSubscription.currentCouponValue ? <span>&nbsp;<s>${currentSubscriptionPlan.amount / 100}</s></span> : null}
                                        &nbsp;${currentSubscription.currentCouponValue ?
                                            (currentSubscriptionPlan.amount - currentSubscription.currentCouponValue) / 100 :
                                            currentSubscriptionPlan.amount / 100
                                        }
                                        {intl.locale === "en" && (currentSubscriptionPlan.intervalCount === 1 ?
                                            intl.formatMessage({ id: `per-${currentSubscriptionPlan.interval}` }) :
                                            intl.formatMessage({ id: `for-number-${currentSubscriptionPlan.interval}` }, { number: currentSubscriptionPlan.intervalCount })
                                        )}
                                    </Typography>

                                    <br />
                                </>
                            }

                            <Grid container direction="row">
                                <Grid item xs={8}>
                                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'select-new-plan' })} </Typography>
                                </Grid>

                                {selectedPlan &&
                                    <Grid item xs={4}>
                                        <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'coupon' })} </Typography>
                                    </Grid>
                                }
                            </Grid>

                            <Grid container direction="row">
                                <Grid item xs={7}>
                                    {changeablePlans?.length > 0 ?
                                        <TextField
                                            select
                                            size='small'
                                            key={selectedPlan?._id || 'none'}
                                            id="changeablePlans"
                                            variant='outlined'
                                            value={selectedPlan?._id}
                                            onChange={selectPlan}
                                            autoWidth
                                        >
                                            {changeablePlans.map(changeablePlan =>
                                                <MenuItem key={changeablePlan._id} value={changeablePlan._id}>
                                                    <Typography style={{ fontSize: 15 }}>
                                                        {intl.locale !== "en" && (changeablePlan.intervalCount === 1 ?
                                                            intl.formatMessage({ id: `per-${changeablePlan.interval}` }) :
                                                            intl.formatMessage({ id: `for-number-${changeablePlan.interval}` }, { number: changeablePlan.intervalCount })
                                                        )}
                                                        {coupon ? <span>&nbsp;<s>${changeablePlan.amount / 100}</s></span> : null}
                                                        &nbsp;${calculateDisplayPrice(changeablePlan, coupon)}
                                                        {intl.locale === "en" && (changeablePlan.intervalCount === 1 ?
                                                            intl.formatMessage({ id: `per-${changeablePlan.interval}` }) :
                                                            intl.formatMessage({ id: `for-number-${changeablePlan.interval}` }, { number: changeablePlan.intervalCount })
                                                        )}
                                                    </Typography>
                                                </MenuItem>
                                            )}
                                        </TextField>
                                        :
                                        <Typography style={{ fontSize: 15 }}>{intl.formatMessage({ id: `no-available-plan` })}</Typography>
                                    }
                                </Grid>

                                <Grid item xs={1}></Grid>

                                {selectedPlan &&
                                    <Grid item xs={4}>
                                        <TextField
                                            variant='outlined'
                                            size='small'
                                            error={invalidCoupon}
                                            helperText={
                                                invalidCoupon ?
                                                    intl.formatMessage({ id: 'invalid-coupon' }) :
                                                    couponValue ?
                                                        intl.formatMessage({ id: 'coupon-has-been-applied' }, { amount: couponValue / 100 }) :
                                                        ""
                                            }
                                            value={couponId}
                                            onChange={onChangeCouponId}
                                            onBlur={() => applyCoupon(couponId, selectedPlan._id)}
                                            FormHelperTextProps={{
                                                style: { fontSize: 12, color: invalidCoupon ? theme.palette.error.main : 'green' }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ padding: 0 }} onClick={() => applyCoupon(couponId, selectedPlan._id)}>
                                                            <CheckCircleOutlineIcon style={{ fontSize: 20, color: invalidCoupon ? theme.palette.error.main : undefined }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                }
                            </Grid>

                            <br />

                            {selectedPlan &&
                                <>
                                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'options' })} </Typography>

                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="option" name="option" value={option} onChange={selectOption}>
                                            {selectedPlan.options.includes("endWithExtension") && !disableExtension && <FormControlLabel value="endWithExtension" control={<Radio />} label={intl.formatMessage({ id: 'end-plan-and-get-extension' }, { number: extension })} />}
                                            {selectedPlan.options.includes("endWithCredits") && <FormControlLabel value="endWithCredits" control={<Radio />} label={intl.formatMessage({ id: 'end-plan-and-get-credits' }, { amount: credits / 100 })} />}
                                            {selectedPlan.options.includes("scheduleChange") && <FormControlLabel value="scheduleChange" control={<Radio />} label={intl.formatMessage({ id: 'change-after-current-plan-expires' }, { date: currentSubscriptionExpiredAt })} />}
                                        </RadioGroup>
                                    </FormControl>

                                    <br />

                                    {option &&
                                        <>
                                            <Typography style={{ fontSize: 16, fontWeight: "bold" }}> {intl.formatMessage({ id: 'summary' })} </Typography>
                                            {currentCredits > 0 && <Typography>{intl.formatMessage({ id: 'you-have-credits-available' }, { amount: currentCredits / 100 })}</Typography>}
                                            <Typography>
                                                {currentCredits > 0 ? intl.formatMessage({ id: 'after-applying-current-credits' }) : ""}
                                                {option === "scheduleChange" || (option === "endWithCredits" && amountDueToday <= 0) ?
                                                    intl.formatMessage({ id: 'you-dont-need-to-pay-today' }) :
                                                    intl.formatMessage({ id: 'you-need-to-pay-amount-today' }, { amount: amountDueToday })
                                                }
                                            </Typography>
                                            <Typography>
                                                {intl.formatMessage(
                                                    {
                                                        id: option === "endWithCredits" && (currentCredits + credits > selectedPlan.amount - couponValue) ?
                                                            'plan-renew-automatically-for-amount-on-date-with-credits' :
                                                            'plan-renew-automatically-for-amount-on-date'
                                                    },
                                                    {
                                                        amount: amountDueNextBillingPeriod,
                                                        credits: (currentCredits + credits - (selectedPlan.amount - couponValue)) / 100,
                                                        date: formattedNextBillingDate
                                                    }
                                                )}
                                            </Typography>

                                            {defaultPaymentMethod?.card ?
                                                <>
                                                    {amountDueToday !== 0 &&
                                                        <Button
                                                            variant="text"
                                                            disabled={loading}
                                                            onClick={proceedToEnterCreditCard}
                                                            style={{ color: "grey", float: "right" }}
                                                        >
                                                            {loading ? "" : intl.formatMessage({ id: 'pay-with-other-credit-card' })}
                                                        </Button>
                                                    }
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth={true}
                                                        disabled={loading}
                                                        onClick={() => changePlan(defaultPaymentMethod.id)}
                                                        style={{ marginTop: amountDueToday === 0 ? 5 : 0 }}
                                                    >
                                                        {loading ?
                                                            <CircularProgress size={24} /> :
                                                            <FormattedMessage
                                                                id={amountDueToday === 0 ? "Confirm" : "pay-with-card"}
                                                                values={{
                                                                    amount: amountDueToday,
                                                                    card:
                                                                        <span>
                                                                            <img src={getCardIconSrc(defaultPaymentMethod.card.brand)} alt={`${defaultPaymentMethod.card.brand} icon`} height="24" style={{ marginLeft: 3, marginRight: 3 }} />
                                                                            •⁠•⁠•⁠⁠• {defaultPaymentMethod.card.last4}
                                                                        </span>
                                                                }}
                                                            />

                                                        }
                                                    </Button>
                                                </>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth={true}
                                                    disabled={loading}
                                                    onClick={proceedToEnterCreditCard}
                                                    style={{ marginTop: 5 }}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'proceed-to-payment' })}
                                                </Button>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </>
                    }

                    {step === 2 &&
                        <>
                            <Button
                                onClick={() => setStep(1)}
                                variant="text"
                                style={{ color: "grey", marginTop: 0 }}
                            >
                                <ArrowBackIosIcon fontSize='small' /> {intl.formatMessage({ id: 'back-to-previous' })}
                            </Button>

                            <StripeElementWrapper
                                intl={intl}
                                stripePromise={stripePromise}
                                clientSecret={clientSecret}
                                userDetails={userDetails}
                                buttonText={option === "scheduleChange" ? intl.formatMessage({ id: "Update" }) : intl.formatMessage({ id: "pay" })}
                                isSetup={option === "scheduleChange"}
                                isRecurring={true}
                                paymentDescription={intl.formatMessage({ id: "portfoplus-subscription" })}
                                billingAgreement={intl.formatMessage({ id: 'billing-agreement' })}
                                recurringAmount={amountDueNextBillingPeriod * 100}
                                recurringLabel={
                                    option === "scheduleChange" ?
                                        intl.formatMessage({ id: "new-subscription" }) :
                                        intl.formatMessage({ id: "change-plan-apple-pay-second-interval" }, { interval: intl.formatMessage({ id: selectedPlan.interval }) })
                                }
                                recurringStartDate={nextBillingDate.toDate()}
                                recurringIntervalUnit={selectedPlan.interval === "week" ? "day" : selectedPlan.interval}
                                recurringIntervalCount={selectedPlan.interval === "week" ? selectedPlan.intervalCount * 7 : selectedPlan.intervalCount}
                                trialAmount={amountDueToday * 100}
                                trialLabel={
                                    option === "endWithExtension" ?
                                        intl.formatMessage({ id: "change-plan-apple-pay-extension" }, { interval: intl.formatMessage({ id: selectedPlan.interval }), number: extension }) :
                                        option === "endWithCredits" ?
                                            intl.formatMessage({ id: "change-plan-apple-pay-credits" }, { interval: intl.formatMessage({ id: selectedPlan.interval }), amount: credits / 100 }) :
                                            intl.formatMessage({ id: "current-subscription" })
                                }
                                trialStartDate={new Date()}
                                trialEndDate={nextBillingDate.toDate()}
                                trialIntervalUnit={selectedPlan.interval === "week" ? "day" : selectedPlan.interval}
                                trialIntervalCount={selectedPlan.interval === "week" ? selectedPlan.intervalCount * 7 : selectedPlan.intervalCount}
                                completePay={completePayment}
                            />
                        </>
                    }

                    {step === 3 &&
                        <>
                            <Typography>
                                {intl.formatMessage({ id: 'change-plan-success' })}
                                {intl.formatMessage(
                                    {
                                        id: option === "endWithCredits" && (currentCredits + credits > selectedPlan.amount - couponValue) ?
                                            'plan-renew-automatically-for-amount-on-date-with-credits' :
                                            'plan-renew-automatically-for-amount-on-date'
                                    },
                                    {
                                        amount: amountDueNextBillingPeriod,
                                        credits: (currentCredits + credits - (selectedPlan.amount - couponValue)) / 100,
                                        date: formattedNextBillingDate
                                    }
                                )}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={true}
                                disabled={loading}
                                onClick={refreshPage}
                                style={{ marginTop: 6 }}
                            >
                                {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'start-using' })}
                            </Button>
                        </>
                    }

                    {errorMessage && <Typography style={{ color: theme.palette.error.main, marginTop: 5 }}>{errorMessage}</Typography>}
                </>
            }
            actions={[]}
            BottomSheetProps={{
                expandOnContentDrag: false,
                disableAutoDismiss: true
            }}
            DialogProps={{
                maxWidth: 'xs',
                fullWidth: true
            }}
            DialogActionsProps={{
                style: { padding: 4 }
            }}
            DialogParams={{ dialogTitleCloseButton: true }}
        />
    );
};

export default withRouter(ChangeSubscriptionPlan);