import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
import * as api from './api';
import _ from 'lodash';
import { getDefaultFinancialPyramidCopy } from '../../views/Analysis/FinancialPyramid/utils';
import produce from 'immer';

/***********************************
 * Action Types
 ***********/
export const UPDATE_FINANCIAL_PYRAMID_INPUTS = 'UPDATE_FINANCIAL_PYRAMID_INPUTS';
export const UPDATE_FINANCIAL_PYRAMID = 'UPDATE_FINANCIAL_PYRAMID';
export const RESET_FINANCIAL_PYRAMID = 'RESET_FINANCIAL_PYRAMID';

/***********************************
 * Initial State
 ***********/
const initialState = {
  handwritingMode: false,
  presentMode: false,
  getPhase: INIT,
  updatePhase: INIT,

  financialPyramid: {
    entries: [getDefaultFinancialPyramidCopy('en'), getDefaultFinancialPyramidCopy('en')]
  },
  language: 'en',
  pyramidIndex: 0,
  loading: false
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_FINANCIAL_PYRAMID_INPUTS:
        draft[action.key] = action.value;
        break;
      case UPDATE_FINANCIAL_PYRAMID:
        if (state.entries.length >= state.pyramidIndex + 1) {
          draft.entries[state.pyramidIndex][action.key] = action.value;
        } else {
          draft.entries.push(_.assign(getDefaultFinancialPyramidCopy(state.language), { [action.key]: action.value }));
        }
        break;
      case RESET_FINANCIAL_PYRAMID:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getFinancialPyramid = clientId => async (dispatch, getState) => {
  dispatch({ type: UPDATE_FINANCIAL_PYRAMID_INPUTS, key: 'getPhase', value: LOADING });
  try {
    const result = clientId ? await api.getFinancialPyramidApi(clientId) : {};
    const lang = getState().financialPyramid.language;
    dispatch({
      type: UPDATE_FINANCIAL_PYRAMID_INPUTS,
      key: 'financialPyramid',
      value: result.financialPyramid || {
        entries: [getDefaultFinancialPyramidCopy(lang), getDefaultFinancialPyramidCopy(lang)]
      }
    });
    dispatch({ type: UPDATE_FINANCIAL_PYRAMID_INPUTS, key: 'getPhase', value: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_FINANCIAL_PYRAMID_INPUTS, key: 'getPhase', value: ERROR });
  }
};

export const saveFinancialPyramid = (clientId, financialPyramid) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_FINANCIAL_PYRAMID_INPUTS, key: 'updatePhase', value: LOADING });
  try {
    const result = await api.updateFinancialPyramidApi(clientId, financialPyramid);
    const lang = getState().financialPyramid.language;
    dispatch({
      type: UPDATE_FINANCIAL_PYRAMID_INPUTS,
      key: 'financialPyramid',
      value: result.financialPyramid || {
        entries: [getDefaultFinancialPyramidCopy(lang), getDefaultFinancialPyramidCopy(lang)]
      }
    });
    dispatch({ type: UPDATE_FINANCIAL_PYRAMID_INPUTS, key: 'updatePhase', value: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_FINANCIAL_PYRAMID_INPUTS, key: 'updatePhase', value: ERROR });
  }
};

export const updateFinancialPyramidInputs = (key, value) => ({
  type: UPDATE_FINANCIAL_PYRAMID_INPUTS,
  key,
  value
});

export const updateFinancialPyramid = (key, value) => ({
  type: UPDATE_FINANCIAL_PYRAMID,
  key,
  value
});

export const resetFinancialPyramid = () => ({ type: RESET_FINANCIAL_PYRAMID });
