import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../store/control/duck';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import { tryTranslate } from '../../utils/locale';

const PermissionDialog = props => {
  const { intl, permissionDialog, toggleDialog } = props;

  const { intlId, onClose, closed } = permissionDialog || {};

  const close = () => {
    toggleDialog('permission', { ...permissionDialog, closed: true });
    if (onClose) {
      onClose();
    }
  };
  const onExited = () => toggleDialog('permission', false);

  return (
    <Dialog
      open={!!permissionDialog && !closed}
      TransitionProps={{
        onExited: onExited
      }}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'permission-dialog-title' })}</DialogTitle>
      <DialogContent>{tryTranslate(intl, intlId)}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    permissionDialog: state.control.permissionDialog
  }),
  {
    toggleDialog
  }
)(PermissionDialog);

export default injectIntl(container);
