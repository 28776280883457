import React from 'react';
import { Button, DialogContentText, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import ImageWithTitleCardButton from './ImageWithTitleCardButton/ImageWithTitleCardButton';

const styles = {
  container: {
    paddingBottom: 50
  },
  main: {
    flex: 1,
    justifyContent: 'center'
  },
  imageDiv: {
    marginTop: 5
  },
  actions: { position: 'absolute', bottom: 0, width: '50%', paddingRight: 0, paddingLeft: 0 }
};

const gif = ['questions-animate.svg', 'call-center-animate.svg', 'business-deal-animate.svg'];

const IntroPage = ({ setIndex, intl }) => {
  return (
    <div>
      <DialogContentText style={{ fontSize: 16 }}>
        {intl.formatMessage({ id: 'adviser-help-intro-title' })}
      </DialogContentText>
      {[1, 2, 3].map((e, index) => (
        <ImageWithTitleCardButton
          key={index}
          index={e}
          title={intl.formatMessage({ id: `adviser-help-intro-question-${e}` })}
          description={intl.formatMessage({ id: `adviser-help-intro-question-subtext-${e}` })}
          imgSrc={'animated-svg/' + gif[index]}
          onClick={() => setIndex(e)}
        />
      ))}
    </div>
  );
};

export default IntroPage;
