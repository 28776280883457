export const CPIGuideline = ({ intl }) => [
  {
    target: '.custom-filter-switch .hightolow',
    content: 'jr-cpi-1',
    placement: 'auto',
    disableBeacon: true,
    isFixed: true,
    disableScrolling: true,
    title: intl.formatMessage({ id: 'jr-cpi-1-title' }),
    tutorialItemId: 'c43'
  }
];
