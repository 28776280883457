import { NO_NAVIGATION_URLS } from '../constants/paths';

export const getSearchParam = (search, name) => {
  const params = new URLSearchParams(search);
  return params.get(name);
};

export const getUrlWithProtocol = url => {
  const prefix = 'http';
  if (url && url.substr(0, prefix.length) !== prefix) {
    return `https://${url}`;
  }
  return url;
};

export const shouldHideNavigation = pathname => {
  return NO_NAVIGATION_URLS.find(url => (pathname || '').indexOf(url) > -1);
};

export const formatOpenUrl = url => {
  return !url || url.indexOf('http://') > -1 || url.indexOf('https://') > -1 ? url : `https://${url}`;
};

export const formatCapacitorUrl = url => {
  return (url || '').replace(new RegExp('^capacitor:', 'g'), 'https:');
};

export const formatUrlWithSearch = (url, searchObject) => {
  const formattedSearchObject = Object.entries(searchObject).reduce((accumulator, [key, value]) => {
    // accepts 0 and ''
    if (value !== undefined && value !== null) {
      accumulator[key] = value;
    }

    return accumulator;
  }, {});
  const urlSearchParams = new URLSearchParams(formattedSearchObject);
  return `${url}?${urlSearchParams.toString()}`;
};

export const getYouTubeVideoIdFromUrl = url => {
  // Our regex pattern to look for a youTube ID
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  //Match the url with the regex
  const match = url.match(regExp);
  //Return the result
  return match && match[2].length === 11 ? match[2] : undefined;
};

export const getPdfTelUrl = tel => {
  if (tel) {
    return `https://static.portfoplus.com/func.html?tel=${(tel || '').replace(/\s/g, '')}`;
  }

  return undefined;
};

export const getWhatsappUrl = ({ phone, text }) => {
  const searchParams = new URLSearchParams({ phone, text });
  return `https://wa.me?${searchParams.toString()}`;
};
