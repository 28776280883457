import React, { Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import Compressor from 'compressorjs';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import './AddImageButton.scss';
import ImageIcon from '@material-ui/icons/Image';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { uploadAttachments } from '../../../../store/media/api';
import { MEDIA_UPLOAD_TYPE } from '../../../../constants/media';

const NEW_IMAGE_UPLOAD_TYPE = {
  'adviser/remindMe': MEDIA_UPLOAD_TYPE.ADVISER_VISION_BOARD,
  'adviser/teamBlast': MEDIA_UPLOAD_TYPE.TEAM_BLAST,
  'adviser/promotion': MEDIA_UPLOAD_TYPE.ADVISER_BLAST
};

class AddImageButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      files: []
    };
  }

  handleUploadImage() {
    const { intl, userDetails, imageUsedIn } = this.props;
    const { files } = this.state;

    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    const filename = file?.name || `${new Date.getTime()}.jpeg`;
    const userId = userDetails?._id;

    return new Promise((resolve, reject) => {
      new Compressor(file, {
        convertSize: 0.2 * 1024 * 1024, // max size = 200 KB,
        quality: 0.8,
        maxWidth: 500,
        maxHeight: 700,
        mimeType: 'image/jpeg',
        success: async compressedFile => {
          if (NEW_IMAGE_UPLOAD_TYPE[imageUsedIn]) {
            try {
              const { editorState, onChange } = this.props;

              const attachmentsData = await uploadAttachments({
                resourceId: userId,
                attachments: [
                  {
                    filename: filename,
                    url: URL.createObjectURL(compressedFile)
                  }
                ],
                type: NEW_IMAGE_UPLOAD_TYPE[imageUsedIn]
              });

              const url = attachmentsData[0].url;

              onChange(this.props.modifier(editorState, url));
              this.setState({ open: false });
              resolve(url);
            } catch (error) {
              alert(error);
              console.error(error);
              resolve(error);
            }
          } else {
            alert('Invalid image used in');
            // const data = new FormData();
            // data.append('image', compressedFile);
            // data.append('usedIn', imageUsedIn);
            //
            // const { editorState, onChange } = this.props;
            //
            // fetch(Backend.methodUrl('uploadImage'), {
            //   method: 'POST',
            //   body: data,
            //   credentials: 'include'
            // })
            //   .then(res => {
            //     res.json().then(data => {
            //       if (data.error === undefined) {
            //         if (data.success === false && data.message === 'Failed to authenticate token.') {
            //           reject('Failed to authenticate token.');
            //           alert('Session is expired, please login again.');
            //           window.location.replace('/');
            //         } else {
            //           let url = Backend.methodUrl('getImage') + '/' + data._id;
            //           onChange(this.props.modifier(editorState, url));
            //           this.setState({ open: false });
            //           resolve({ data: { link: url } });
            //         }
            //       } else {
            //         alert(intl.formatMessage({ id: data.error }));
            //         reject(data.error);
            //       }
            //     });
            //   })
            //   .catch(fetchError => {
            //     reject(fetchError);
            //   });
          }
        },
        error(compressError) {
          alert('An error occurred in compressing the image');
          reject(compressError.message);
        }
      });
    });
  }

  render() {
    const { intl, theme } = this.props;
    const { open, files } = this.state;

    return (
      <Fragment>
        <div className={theme.buttonWrapper} onMouseDown={event => event.preventDefault()}>
          <button className={theme.button} onClick={() => this.setState({ open: true, files: [] })} type="button">
            <ImageIcon />
          </button>
        </div>
        <Dialog className="add-image-dialog" open={open} onBackdropClick={() => this.setState({ open: false })}>
          <DialogTitle>{intl.formatMessage({ id: 'upload-photo-title' })}</DialogTitle>
          <DialogContent>
            <Dropzone
              onDropAccepted={files =>
                this.setState({
                  files: files.map(file =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file)
                    })
                  )
                })
              }
              accept={['image/*']}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <Typography paragraph={true}>{intl.formatMessage({ id: 'upload-photo-hint-1' })}</Typography>
                    <input {...getInputProps()} />
                    <div className="wrapper">
                      <CloudUploadIcon />
                      <Typography color="textSecondary">{intl.formatMessage({ id: 'upload-photo-hint-2' })}</Typography>
                      <Typography color="primary" style={{ textDecoration: 'underline' }}>
                        {intl.formatMessage({ id: 'clicking-here' })}
                      </Typography>
                    </div>
                  </div>
                  <aside>
                    {files.map(file => (
                      <div key={file.name}>
                        <div>
                          <img src={file.preview} alt="preview" />
                        </div>
                      </div>
                    ))}
                  </aside>
                </section>
              )}
            </Dropzone>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ open: false })}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button color="primary" onClick={() => this.handleUploadImage()} disabled={!files || files.length === 0}>
              {intl.formatMessage({ id: 'Submit' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(AddImageButton);

export default injectIntl(container);
