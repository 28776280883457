import Immutable from 'immutable';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import * as policyAPI from '../policy/api';
import _ from 'lodash';

/**
 * Private: Initial State
 */

const initialState = Immutable.fromJS({
  window: {
    width: 0,
    height: 0
  },
  visitedDashboard: false,
  showPadding: false,

  categories: [],
  getCategoriesPhase: INIT
});

/**
 * Public: Action Types
 */

export const actionTypes = {
  WINDOW_RESIZE: 'FB/app/WINDOW_RESIZE',
  UPDATE_VISITED_DASHBOARD: 'portfoplus/app/UPDATE_VISITED_DASHBOARD',
  UPDATE_SHOW_PADDING: 'UPDATE_SHOW_PADDING',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_GET_CATEGORIES_PHASE: 'UPDATE_GET_CATEGORIES_PHASE'
};

/**
 * Public: Action Creators
 */

export const windowResized = (width, height) => {
  return {
    type: actionTypes.WINDOW_RESIZE,
    payload: { width, height }
  };
};

export const updateVisitedDashboard = visited => {
  return {
    type: actionTypes.UPDATE_VISITED_DASHBOARD,
    payload: {
      visited: visited
    }
  };
};

export const updateShowPadding = flag => {
  return {
    type: actionTypes.UPDATE_SHOW_PADDING,
    payload: {
      flag: flag
    }
  };
};

export const getCategories = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.UPDATE_GET_CATEGORIES_PHASE, phase: LOADING });
  try {
    const result = await policyAPI.getPortfolioJson();
    dispatch({ type: actionTypes.UPDATE_CATEGORIES, categories: _.get(result, 'data', []) });
  } catch (error) {
    dispatch({ type: actionTypes.UPDATE_GET_CATEGORIES_PHASE, phase: ERROR });
  }
};

/**
 * Public: Reducer
 */

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.WINDOW_RESIZE: {
      const { width, height } = action.payload;
      return state.mergeIn(['window'], { width, height });
    }

    case actionTypes.UPDATE_VISITED_DASHBOARD: {
      const { visited } = action.payload;
      return state.set('visitedDashboard', visited);
    }

    case actionTypes.UPDATE_SHOW_PADDING: {
      const { flag } = action.payload;
      return state.set('showPadding', flag);
    }

    case actionTypes.UPDATE_CATEGORIES: {
      return state.set('categories', action.categories).set('getCategoriesPhase', SUCCESS);
    }

    case actionTypes.UPDATE_GET_CATEGORIES_PHASE: {
      return state.set('getCategoriesPhase', action.phase);
    }

    default: {
      return state;
    }
  }
}
