import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import EmergencyInfo from './EmergencyInfo/EmergencyInfo';
import { TopNavigationPortal } from '../../../components/topNavigation';
import { sanitize } from 'dompurify';
import { getInsurerTitleEn, getTranslatedInsurerInfo } from '../../../utils/insurer';

const InsurerDetailView = props => {
  const { intl, history, location, userDetails, insurers, emergency, renderHeaderButton } = props;

  const [emergencySwitch, setEmergencySwitch] = useState(false);

  let data = location.state;

  const holtline =
    '<br /><br />Emergency hotline<br /> Assistance to HK Residents Hotline (852) 1868<br /> Chinese citizens Global Emergency hotline (86) 1012308<br /><br />Major cities emergency hotline<br />Hong Kong, Singapore, Macau, 999<br />Malaysia, UK<br /><br />China, Taiwan, Japan 110<br /><br />Thailand, Philippines, Canada, USA 911<br /><br />Germany, France, Italy, Switzerland 112<br />Spain, Australia, New Zealand, Korea';

  if (data) {
    if (data.analysisInfo) {
      data = data.analysisInfo;
      // routePath = 'insured_details';
    } else if (data.insurInfo) {
      data = data.insurInfo;
      // routePath = 'insurer_info';
    } else if (data.reminderInfo) {
      data = data.reminderInfo;
      // routePath = 'reminder';
    } else if (data.viewShareInfo) {
      data = data.viewShareInfo;
      // routePath = 'view_share_details';
    } else if (data.shareLinkView) {
      data = data.shareLinkView;
      // routePath = 'share_link_view';
    }
  } else if (emergency) {
    data = {};
    data.title = {
      en: 'What if'
    };
  }

  let showEmergency = _.get(location, 'state.showEmergency', false);
  const insurer = (insurers || []).find(insurer => insurer._id === data._id) || {};

  const translatedInsurer = getTranslatedInsurerInfo(insurer, intl.locale);
  const engTitle = getInsurerTitleEn(data);
  const isMPF = data.title === 'MPF';

  const switchToEmergency = () => {
    setEmergencySwitch(true);
  };

  const switchToInsurer = () => {
    setEmergencySwitch(false);
  };

  const onClickChequeWizardGo = () => {
    history.push({ pathname: '/cheque_out', state: { insurer: data } });
  };

  useEffect(() => {
    renderHeaderButton(location.pathname === '/emergency_info');
  }, [location, renderHeaderButton]);

  return !data && !emergency ? (
    <Redirect to="/login" />
  ) : (
    <div>
      <div>
        {!emergency && emergencySwitch && (
          <TopNavigationPortal>
            <span className="back_arrow" onClick={switchToInsurer}>
              <ArrowBackIcon />
            </span>
          </TopNavigationPortal>
        )}
        {engTitle !== 'What if' && !isMPF && !emergencySwitch && !showEmergency && (
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid lab-page">
                <div className="insure-listing insure-listing--scrolls">
                  <div className="text-center">
                    <img className="image1" src={translatedInsurer.image} alt="" />
                  </div>
                  <ul>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Website' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.website && (
                          <a rel="noopener noreferrer" href={translatedInsurer.website} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Hotline' })}</span>
                      {engTitle !== 'What if' ? (
                        <span className="insure-listing-right">
                          {translatedInsurer.hotline && translatedInsurer.hotline}
                          {translatedInsurer.hotline && (
                            <a rel="noopener noreferrer" href={'tel://' + translatedInsurer.hotline} target="_top">
                              <Button variant="contained" type="submit" color="primary">
                                {intl.formatMessage({ id: 'Call' })}
                              </Button>
                            </a>
                          )}
                        </span>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: sanitize(holtline)
                          }}
                        />
                      )}
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Email' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.email && translatedInsurer.email}
                        {translatedInsurer.insurer && translatedInsurer.email && engTitle !== 'Manulife' ? (
                          <a href={'mailto:' + translatedInsurer.email} target="_top">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Mail' })}
                            </Button>
                          </a>
                        ) : (
                          ''
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Web Login' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.weblogin && (
                          <a rel="noopener noreferrer" href={translatedInsurer.weblogin} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Payment Method' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.payment && (
                          <a rel="noopener noreferrer" href={translatedInsurer.payment} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Claim Info' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.claimInfo && (
                          <a rel="noopener noreferrer" href={translatedInsurer.claimInfo} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Claim Report' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.claimReport && (
                          <a rel="noopener noreferrer" href={translatedInsurer.claimReport} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'Forms' })}</span>
                      <span className="insure-listing-right">
                        {translatedInsurer.forms && (
                          <a rel="noopener noreferrer" href={translatedInsurer.forms} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left">{intl.formatMessage({ id: 'infoEmergency' })}</span>
                      <span className="insure-listing-right" onClick={switchToEmergency}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          style={{
                            background: '#FE6A74',
                            border: '1px solid #FE6A74'
                          }}
                        >
                          {intl.formatMessage({ id: 'Go' })}
                        </Button>
                      </span>
                    </li>
                    <li>
                      <span className="insure-listing-left title__width">
                        {intl.formatMessage({ id: 'Exchange Rates' })}
                      </span>
                      <span className="insure-listing-right">
                        {translatedInsurer.exchangeRatePage && (
                          <a rel="noopener noreferrer" href={translatedInsurer.exchangeRatePage} target="_blank">
                            <Button variant="contained" type="submit" color="primary">
                              {intl.formatMessage({ id: 'Go' })}
                            </Button>
                          </a>
                        )}
                      </span>
                    </li>
                    <li>
                      <label> {intl.formatMessage({ id: 'Cheque Payee' })} </label>
                      <div className="insure-listing-textarea amount__height" style={{ paddingRight: 0 }}>
                        {/*insurer && translatedInsurer.payeeName*/}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translatedInsurer.payeeName
                          }}
                        />
                        <span className="insure-listing-right">
                          <Button variant="contained" color="primary" onClick={onClickChequeWizardGo}>
                            {intl.formatMessage({ id: 'Go' })}
                          </Button>
                        </span>
                      </div>
                    </li>
                    <li>
                      <label> {intl.formatMessage({ id: 'Address' })} </label>
                      <div className="insure-listing-textarea amount__height">
                        {/*insurer && translatedInsurer.address*/}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translatedInsurer.address
                          }}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {(engTitle === 'What if' || emergencySwitch) && (
          <div id="wrapper">
            <div id="page-content-wrapper" className="emergency-wrapper">
              <div className="container-fluid lab-page" style={{ background: '#fff' }}>
                <EmergencyInfo />
              </div>
            </div>
          </div>
        )}
        {isMPF && (
          <div id="wrapper">
            <div id="page-content-wrapper" className="emergency-wrapper">
              <div className="container-fluid lab-page" style={{ background: '#fff' }}>
                <div className="emergency-listing">
                  <div className="text-center">
                    <img className="image1" src="img/pro_fund.png" alt="" />
                  </div>
                  <h4>{intl.formatMessage({ id: 'HK MPF Regulator' })}</h4>
                  <ul>
                    <li>
                      <span className="travelinsurance_hotline-left">
                        {intl.formatMessage({ id: 'Hong Kong MPFA' })}
                        <br />
                      </span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2918 0102'} target="_top">
                          {'+852 2918 0102'}
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="travelinsurance_hotline">
                  <h4>
                    {intl.formatMessage({
                      id: 'HK-MPF-MEM-hotline'
                    })}
                  </h4>
                  <ul>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFAIA' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2200 6288'} target="_top">
                          {'+852 2200 6288, 19'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFBCom' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2239 5559'} target="_top">
                          {'+852 2239 5559, 1410'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFBCT' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2298 9333'} target="_top">
                          {'+852 2298 9333, 1129'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFAMTD' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel://  +852 2172 0909'} target="_top">
                          {'  +852 2172 0909'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFInvesco' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2842 7878'} target="_top">
                          {'+852 2842 7878, 10#'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFAllianz' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2298 9000'} target="_top">
                          {'+852 2298 9000, 12'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFBEA' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2211 1777'} target="_top">
                          {'+852 2211 1777, 11'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFBOCIPRU' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2929 3030'} target="_top">
                          {'+852 2929 3030, 1130'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFBOCIPRUMC' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2929 3366'} target="_top">
                          {'+852 2929 3366, 12'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFCLife' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 3999 5555'} target="_top">
                          {'+852 3999 5555, 10'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFFide' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2629 2629'} target="_top">
                          {'+852 2629 2629, 1202'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFHT' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2500 1600'} target="_top">
                          {'+852 2500 1600, 10'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFHSBC' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 3128 0128'} target="_top">
                          {'+852 3128 0128, 1#'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFHSB' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2213 2213'} target="_top">
                          {'+852 2213 2213, 1#'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFSunlife' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 3183 1888'} target="_top">
                          {'+852 3183 1888, 130'}
                        </a>
                      </span>
                    </li>
                    {/* <li>
                      <span className="travelinsurance_hotline-left">
                        {intl.formatMessage({ id: 'MPFSunlife_Sch' })}
                      </span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2971 0200'} target="_top">
                          {'+852 2971 0200, 130'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">
                        {intl.formatMessage({ id: 'MPFSunlife_FWD' })}
                      </span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 3183 1900'} target="_top">
                          {'+852 3183 1900, 10'}
                        </a>
                      </span>
                    </li> */}
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFManu' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2298 9000'} target="_top">
                          {'+852 2298 9000'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFYF' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2533 5522'} target="_top">
                          {'+852 2533 5522'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFPrincipal' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 2827 1233'} target="_top">
                          {'+852 2827 1233'}
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="travelinsurance_hotline-left">{intl.formatMessage({ id: 'MPFSHKP' })}</span>
                      <span className="travelinsurance_hotline-right">
                        <a rel="noopener noreferrer" href={'tel:// +852 3183 3183'} target="_top">
                          {'+852 3183 3183, 130'}
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsurerDetailView;
