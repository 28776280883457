import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const GET_POLICY_LIST = 'portfoplus/reminder/GET_POLICY_LIST';
export const GET_POLICY_LIST_SUCCESS = 'portfoplus/reminder/GET_POLICY_LIST_SUCCESS';
export const GET_POLICY_LIST_ERROR = 'portfoplus/reminder/GET_POLICY_LIST_ERROR';

export const UPDATE_REMINDER = 'portfoplus/reminder/UPDATE_REMINDER';
export const UPDATE_REMINDER_SUCCESS = 'portfoplus/reminder/UPDATE_REMINDER_SUCCESS';
export const UPDATE_REMINDER_ERROR = 'portfoplus/reminder/UPDATE_REMINDER_ERROR';

export const GET_REMINDER_BADGE_COUNT = 'portfoplus/reminder/GET_REMINDER_BADGE_COUNT';
export const GET_REMINDER_BADGE_COUNT_SUCCESS = 'portfoplus/reminder/GET_REMINDER_BADGE_COUNT_SUCCESS';
export const GET_REMINDER_BADGE_COUNT_ERROR = 'portfoplus/reminder/GET_REMINDER_BADGE_COUNT_ERROR';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated

  getPolicyPhase: INIT,
  addReminderPhase: INIT,
  reminderData: [],
  policyData: [],
  reminderMessage: null,
  error: null,
  isSubmitting: false,
  reminderBadgeCount: 0,
  reminderBadgeCountPhase: INIT
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_POLICY_LIST: {
      return state
        .set('addReminderPhase', INIT)
        .set('getPolicyPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_POLICY_LIST_SUCCESS: {
      const { payload } = action;
      return state
        .set('policyData', payload.data)
        .set('getPolicyPhase', payload.success)
        .set('error', null);
    }

    case GET_POLICY_LIST_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getPolicyPhase', ERROR);
    }

    case UPDATE_REMINDER: {
      return state
        .set('addReminderPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case UPDATE_REMINDER_SUCCESS: {
      const { payload } = action;
      return state
        .set('addReminderPhase', payload.success)
        .set('reminderMessage', payload.message)
        .set('error', null);
    }

    case UPDATE_REMINDER_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('addReminderPhase', ERROR);
    }

    case GET_REMINDER_BADGE_COUNT: {
      return state
        .set('error', null)
        .set('reminderBadgeCountPhase', LOADING)
        .set('isSubmitting', true);
    }

    case GET_REMINDER_BADGE_COUNT_SUCCESS: {
      const { payload } = action;
      return state
        .set('error', null)
        .set('reminderBadgeCount', payload.data)
        .set('reminderBadgeCountPhase', SUCCESS)
        .set('isSubmitting', false);
    }

    case GET_REMINDER_BADGE_COUNT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('reminderBadgeCountPhase', ERROR);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/
export const getPolicyList = data => ({
  type: GET_POLICY_LIST,
  payload: data
});

export const updateReminder = data => {
  return {
    type: UPDATE_REMINDER,
    payload: { data }
  };
};

export const getReminderBadgeCount = () => ({
  type: GET_REMINDER_BADGE_COUNT
});
/***********************************
 * Epics
 ***********/

const getPolicyListEpic = action$ =>
  action$.ofType(GET_POLICY_LIST).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getPolicyList(action.payload))
      .map(payload => ({
        type: GET_POLICY_LIST_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_POLICY_LIST_ERROR,
          payload: { error }
        })
      );
  });

const updateReminderEpic = action$ =>
  action$.ofType(UPDATE_REMINDER).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateReminder(action.payload.data))
      .map(payload => ({
        type: UPDATE_REMINDER_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_REMINDER_ERROR,
          payload: { error }
        })
      );
  });

const getReminderBadgeCountEpic = action$ =>
  action$.ofType(GET_REMINDER_BADGE_COUNT).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getReminderBadgeCount())
      .map(payload => ({
        type: GET_REMINDER_BADGE_COUNT_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_REMINDER_BADGE_COUNT_ERROR,
          payload: { error }
        })
      );
  });

export const reminderEpic = combineEpics(getPolicyListEpic, updateReminderEpic, getReminderBadgeCountEpic);
