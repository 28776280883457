export const TEMP_ENABLE_EXPORT_ALL = false;
//2019 figure : export const INFLATION_RATE = 1.002630675; //ie 3.163281, was 1.002598604;(28,Feb,19),1.00246627 (25,Sep,18)  inflation / 100/12 -1
export const INFLATION_RATE = 1.002610549; //<-average mthly com CPI,  cpi 1990 to 2021: 3.17803125 -> (CPI/100+1)^(1/12)
export const SCORE_LEVEL_COLORS = ['#ff0000', '#ffa600', '#3eda7d', '#3999f8'];

export const INPUT_GROUP_TYPE = {
  TARGET_DURATION: '0',
  TARGET_INCOME: '1'
};

export const DEBT_MAX = 99999999;

export const SUPPORT_YEAR_OPTIONS = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40];

export const EXPENSE_OPTIONS = [
  5000,
  10000,
  15000,
  20000,
  25000,
  30000,
  35000,
  40000,
  45000,
  50000,
  55000,
  60000,
  65000,
  70000,
  75000,
  80000,
  85000,
  90000,
  95000,
  100000,
  110000,
  130000,
  150000,
  180000,
  200000
];

export const DEBT_OPTIONS = [
  0,
  100000,
  200000,
  300000,
  400000,
  600000,
  800000,
  1000000,
  1500000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  5500000,
  6000000,
  6500000,
  7000000,
  8000000,
  9000000,
  10000000
];

export const INCOME_OPTIONS = [
  5000,
  10000,
  15000,
  20000,
  25000,
  30000,
  35000,
  40000,
  45000,
  50000,
  55000,
  60000,
  65000,
  70000,
  75000,
  80000,
  90000,
  100000,
  110000,
  130000,
  150000,
  180000,
  200000,
  230000,
  280000
];

export const LIFE_EXTRA_MONEY_OPTIONS = [
  0,
  100000,
  200000,
  300000,
  400000,
  600000,
  800000,
  1000000,
  1500000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  5500000,
  6000000,
  6500000,
  7000000,
  8000000,
  9000000,
  10000000
];

export const LIFE_TOP_UP_OPTIONS = [
  0,
  100000,
  200000,
  300000,
  400000,
  600000,
  800000,
  1000000,
  1500000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  5500000,
  6000000
];

export const CI_TOP_UP_OPTIONS = [
  0,
  100000,
  200000,
  300000,
  400000,
  600000,
  800000,
  1000000,
  1500000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
  5500000,
  6000000
];

export const CI_CLAIM_COVER_EXPENSES_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const SAVING_TOP_UP_OPTIONS = [
  0,
  500,
  1000,
  1500,
  2000,
  2500,
  3000,
  4000,
  5000,
  6000,
  8000,
  10000,
  12000,
  15000,
  18000,
  20000,
  25000,
  30000,
  35000,
  40000,
  50000,
  60000
];

export const RETURN_RATE_OPTIONS = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 7, 8, 9, 10, 11, 13, 15];
export const INFLATION_RATE_OPTIONS = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7];
export const SAVING_YEAR_OPTIONS = [1, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

export const EMOJI_IMAGES = {
  BAD: {
    src: 'img/bad.png',
    alt: 'bad'
  },
  POOR: {
    src: 'img/poor.png',
    alt: 'poor'
  },
  GOOD: {
    src: 'img/good.png',
    alt: 'good'
  },
  EXCELLENT: {
    src: 'img/excellent.png',
    alt: 'excellent'
  }
};
