import NativeOrWeb from './native';
import { fetch } from './index';
import { blobToDataURL } from './download';

export const handleWalletPassFetch = async (url, type) => {
  if (type === 'apple') {
    if (NativeOrWeb.isNativePlatform()) {
      const res = await fetch(url);
      const blob = await res.blob();
      const base64Data = await blobToDataURL(blob);
      const formattedBase64Data = base64Data.replace(/^data:[a-z/.]+;base64,/, '');
      await NativeOrWeb.addToWallet(formattedBase64Data);
    } else {
      setTimeout(() => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_top';
        a.click();
        a.remove();
      }, 250);
    }
  } else {
    const res = await fetch(url);
    const json = await res.json();
    if (json.url) {
      window.open(json.url, '_blank');
      return;
    }
    throw new Error('Google Wallet Pass creation failed');
  }
};
