import { Capacitor } from '@capacitor/core';
import { Badge } from '@capawesome/capacitor-badge';

export const setBadge = async count => {
  if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
    const formattedCount = typeof count === 'string' ? parseInt(count) : count;
    if (!formattedCount) {
      return await Badge.clear();
    } else {
      return await Badge.set({ count: formattedCount });
    }
  }
};
