import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import { getRegion } from '../../../../utils/region';

const CurrencyInput = props => {
  const { intl, value, onChange, error } = props;

  const choices = ['USD', 'HKD', 'CNY'].map(choice => ({
    value: choice,
    label: intl.formatMessage({ id: choice })
  }));

  if (getRegion() === 'tw') {
    choices.push({
      value: 'TWD',
      label: intl.formatMessage({ id: 'TWD' })
    });
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid container alignItems="flex-start" spacing={2} wrap="nowrap">
          <Grid item>
            <Typography color={error ? 'error' : 'textSecondary'} style={{ whiteSpace: 'nowrap' }}>
              {`${intl.formatMessage({ id: 'Currency' })}*`}
            </Typography>
          </Grid>
          <Grid item style={{ flexGrow: 1, paddingLeft: 4, paddingRight: 4 }}>
            <SelectButtonGroup
              value={value}
              choices={choices}
              onChange={onChange}
              justify="center"
              spacing={0}
              buttonClassName="plan-type-select-button"
            />
          </Grid>
        </Grid>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography color="error" className="error">
            {intl.formatMessage({ id: error })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(CurrencyInput);
