import {
  CI_TOP_UP_OPTIONS,
  DEBT_OPTIONS,
  EMOJI_IMAGES,
  LIFE_EXTRA_MONEY_OPTIONS,
  LIFE_TOP_UP_OPTIONS,
  SAVING_TOP_UP_OPTIONS,
  SCORE_LEVEL_COLORS
} from '../constants/analysis';
import _ from 'lodash';
import { isNullOrUndefined } from './helpers';
import { loadAnalysisSessionStorage } from '../store/analysis/duck';
import { currencyExchange } from './currencyExchange';
import { isInsuredNotMePolicy, isPolicyCategory, isPolicySubCategory } from './policy';
import { decimalToString } from './formatNumber';
import { getUserCurrency } from './user';

export const lifePolicyPickerDefaultFilter = policy =>
  isPolicyCategory(policy, 'personal') && isPolicySubCategory(policy, 'life') && !isInsuredNotMePolicy(policy);
export const ciPolicyPickerDefaultFilter = policy =>
  isPolicyCategory(policy, 'personal') && isPolicySubCategory(policy, 'ci') && !isInsuredNotMePolicy(policy);
export const savingPolicyPickerDefaultFilter = policy =>
  isPolicySubCategory(policy, 'saving') && !isInsuredNotMePolicy(policy);

export const putClientDataToStore = (history, updateAnalysisInputs) => {
  const clientId = JSON.parse(localStorage.getItem('clientId'));
  let defaultStoreObject = {};
  // set analysis inputs based on local storage
  if (clientId) {
    // if the viewer is adviser
    // let monthlyIncome = JSON.parse(localStorage.getItem('monthlyIncome'));
    const clientDetails =
      _.get(history, 'location.state.clientFactsheetDetail.clientIncData') ||
      _.get(history, 'location.state.client.factsheetId');

    if (!clientDetails) {
      history.push({ pathname: '/' });
    } else {
      if (clientDetails) {
        let paths = ['monthlyExpense', 'debt', 'age', 'monthlyIncome'];
        paths.forEach(path => {
          if (!isNullOrUndefined(clientDetails[path]) && clientDetails[path].toString().trim() !== '') {
            defaultStoreObject[path] = parseFloat(clientDetails[path].toString());
          }
        });
      }
    }
  } else {
    defaultStoreObject = _.merge(defaultStoreObject, loadAnalysisSessionStorage());
  }

  updateAnalysisInputs(defaultStoreObject);
};

export const getDynamicOptions = (min, max, steps, prefix, defaultOptions) => {
  // // y = a^x - 1
  // const a = Math.pow(2, Math.log2(max + 1) / (steps - 1));
  // console.log(min, max, steps, a, Array(steps).fill(0).map((value, index) => Math.round(Math.pow(a, index) - 1)));
  // return Array(steps).fill(0).map((value, index) => Math.round(Math.pow(a, index) - 1));

  if (prefix) {
    steps = steps - 1;
  }

  const correctToNearestFunc = (value, correctTo) => Math.ceil(value / correctTo) * correctTo;
  const correctToOwnNearestFunc = value => {
    const decimal = Math.round(value).toString().length;
    const targetNearest = Math.max(100, Math.pow(10, Math.max(0, decimal - 2)));
    // return value;
    return correctToNearestFunc(value, targetNearest);
  };

  let results = [min];
  steps = steps - 1;

  const ignoreSteps = Math.floor(steps * 0.4);
  steps = steps + ignoreSteps;

  // y = x^a + min with y = mx + min
  const a = Math.log2(max - min) / Math.log2(steps - 1);
  // const interceptStep = Math.floor(steps * 0.3);
  if (isNaN(a)) {
    return defaultOptions;
  }

  for (let i = ignoreSteps + 1; i < steps; i++) {
    results.push(correctToOwnNearestFunc(Math.round(Math.pow(i, a) + min)));
  }

  // const m = correctToOwnNearestFunc((results[0] - min) / interceptStep);
  results = [prefix].concat(
    results
    // Array(interceptStep - 1)
    //   .fill(0)
    //   .map((value, index) => Math.round(m * index + min))
    //   .concat(results)
  );

  return _.uniq(results);

  // // y = mx
  // const correctToNearestFunc = (value, correctTo) => Math.ceil(value / correctTo) * correctTo;
  // let stepValue = (max - min) / (steps - 1);
  // const decimal = Math.round(stepValue).toString().length;
  // const correctToNearest = Math.max(1000, Math.pow(10, Math.max(0, decimal - 2)));
  // stepValue = correctToNearestFunc(stepValue, correctToNearest);
  // return Array(steps)
  //   .fill(0)
  //   .map((value, index) => correctToNearestFunc(min + stepValue * index, correctToNearest));
};

export const getDebtOptions = monthlyExpense => getDynamicOptions(100000, monthlyExpense * 350, 23, 0, DEBT_OPTIONS);
export const getExtraMoneyOptions = monthlyExpense =>
  getDynamicOptions(200000, monthlyExpense * 350, 23, 0, LIFE_EXTRA_MONEY_OPTIONS);
export const getLifeTopUpMax = (monthlyExpense, debt) =>
  Math.max(monthlyExpense * 700 + 2 * debt, LIFE_TOP_UP_OPTIONS[LIFE_TOP_UP_OPTIONS.length - 1]);
export const getLifeTopUpOptions = (monthlyExpense, debt) =>
  getDynamicOptions(200000, getLifeTopUpMax(monthlyExpense, debt), 23, 0, LIFE_TOP_UP_OPTIONS);

export const getCITopUpMax = (monthlyIncome, ciClaimCoverExpenses) =>
  Math.max(monthlyIncome * (100 + ciClaimCoverExpenses * 12), CI_TOP_UP_OPTIONS[CI_TOP_UP_OPTIONS.length - 1]);
export const getCITopUpOptions = (monthlyIncome, ciClaimCoverExpenses) =>
  getDynamicOptions(200000, getCITopUpMax(monthlyIncome, ciClaimCoverExpenses), 23, 0, CI_TOP_UP_OPTIONS);

export const getSavingTopUpMax = monthlyIncome =>
  Math.max(monthlyIncome * 3, SAVING_TOP_UP_OPTIONS[SAVING_TOP_UP_OPTIONS.length - 1]);
export const getSavingTopUpOptions = monthlyIncome =>
  getDynamicOptions(1000, getSavingTopUpMax(monthlyIncome), 23, 0, SAVING_TOP_UP_OPTIONS);

export const getScoreColor = score => {
  if (score >= 75) {
    return SCORE_LEVEL_COLORS[3];
  } else if (score >= 50) {
    return SCORE_LEVEL_COLORS[2];
  } else if (score >= 25) {
    return SCORE_LEVEL_COLORS[1];
  } else {
    return SCORE_LEVEL_COLORS[0];
  }
};

export const getScoreEmoji = score => {
  if (score >= 75) {
    return '';
  } else if (score >= 50) {
    return '';
  } else if (score >= 25) {
    return ' ⚠ ';
  } else {
    return ' ⚠ ';
  }
};

export const getTotalLifeCoverage = (totalLifeCoverage, lifeTopUp) => {
  return lifeTopUp + totalLifeCoverage;
};

export const getDebtPlusMortgageLoan = (debt, totalOutStandingLoan, lifeIncludeMortgageAmount) => {
  return parseFloat(isNaN(debt) ? 0 : debt) + (lifeIncludeMortgageAmount ? totalOutStandingLoan : 0);
};

export const getLifeBetterThanKey = (protectionAmountFigure, lifeTotalCoverage) => {
  const protectionAmountOf25 = _.get(protectionAmountFigure, 'protectionAmountOf25', 0),
    protectionAmountOf50 = _.get(protectionAmountFigure, 'protectionAmountOf50', 0),
    protectionAmountOf75 = _.get(protectionAmountFigure, 'protectionAmountOf75', 0);

  if (lifeTotalCoverage >= protectionAmountOf25 && lifeTotalCoverage < protectionAmountOf50) {
    return '25';
  } else if (lifeTotalCoverage >= protectionAmountOf50 && lifeTotalCoverage < protectionAmountOf75) {
    return '50';
  } else if (lifeTotalCoverage >= protectionAmountOf75 && protectionAmountOf75 !== 0) {
    return '75';
  } else if (protectionAmountOf25 && lifeTotalCoverage !== 0 && lifeTotalCoverage > 0) {
    return 'lessThan25';
  }

  return '';
};

export const getTotalCICoverage = (ciTotalCoverage, ciTopUp) => {
  return parseInt(ciTopUp) + ciTotalCoverage;
};

export const getCIBetterThanKey = (protectionAmountFigure, ciTotalCoverage) => {
  const ciProtectionAmountOf25 = _.get(protectionAmountFigure, 'ciProtectionAmountOf25', 0),
    ciProtectionAmountOf50 = _.get(protectionAmountFigure, 'ciProtectionAmountOf50', 0),
    ciProtectionAmountOf75 = _.get(protectionAmountFigure, 'ciProtectionAmountOf75', 0);

  if (ciTotalCoverage >= ciProtectionAmountOf25 && ciTotalCoverage < ciProtectionAmountOf50) {
    return '25';
  } else if (ciTotalCoverage >= ciProtectionAmountOf50 && ciTotalCoverage < ciProtectionAmountOf75) {
    return '50';
  } else if (ciTotalCoverage >= ciProtectionAmountOf75 && ciProtectionAmountOf75 !== 0) {
    return '75';
  } else if (ciTotalCoverage < ciProtectionAmountOf25 && ciTotalCoverage !== 0) {
    return 'lessThan25';
  }

  return '';
};

export const getTotalSavingCoverage = (totalPremium, savingTopUp) => {
  return (totalPremium || 0) + (savingTopUp || 0);
};

export const getSavingRetirementIncomeLevel = (retirementValue, monthlyIncome, currency) => {
  const monthlyIncomeInHKD = currencyExchange(monthlyIncome, 'HKD', currency);
  if (monthlyIncomeInHKD >= 30000) {
    if (retirementValue < monthlyIncome * 0.5) {
      return EMOJI_IMAGES.BAD;
    } else if (monthlyIncome * 0.5 <= retirementValue && retirementValue < monthlyIncome * 0.8) {
      return EMOJI_IMAGES.POOR;
    } else if (monthlyIncome * 0.8 <= retirementValue && retirementValue < monthlyIncome * 1.5) {
      return EMOJI_IMAGES.GOOD;
    } else {
      return EMOJI_IMAGES.EXCELLENT;
    }
  } else {
    const retirementValueInHKD = currencyExchange(retirementValue, 'HKD', currency);
    if (retirementValueInHKD < 15000) {
      return EMOJI_IMAGES.BAD;
    } else if (15000 <= retirementValueInHKD && retirementValueInHKD < 24000) {
      return EMOJI_IMAGES.POOR;
    } else if (24000 <= retirementValueInHKD && retirementValueInHKD < 45000) {
      return EMOJI_IMAGES.GOOD;
    } else {
      return EMOJI_IMAGES.EXCELLENT;
    }
  }
};

export const futureValueCalculator = (rate, nper, pmt, pv, type) => {
  var pow = Math.pow(1 + rate, nper),
    fv;
  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nper);
  }
  return fv;
};

/**
 * Copy of Excel's PMT function.
 * Credit: http://stackoverflow.com/questions/2094967/excel-pmt-function-in-js
 *
 * rate_per_period       The interest rate for the loan.
 *  number_of_payments    The total number of payments for the loan in months.
 *  present_value         The present value, or the total amount that a series of future payments is worth now;
 *                              Also known as the principal.
 * future_value          The future value, or a cash balance you want to attain after the last payment is made.
 *                              If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.
 * type                  Optional, defaults to 0. The number 0 (zero) or 1 and indicates when payments are due.
 *                              0 = At the end of period
 *                              1 = At the beginning of the period
 *  {number}
 */
export const retirementValueCalculator = (rate_per_period, number_of_payments, present_value, future_value, type) => {
  future_value = typeof future_value !== 'undefined' ? future_value : 0;
  type = typeof type !== 'undefined' ? type : 0;
  if (rate_per_period !== 0.0) {
    // Interest rate exists
    var q = Math.pow(1 + rate_per_period, number_of_payments);
    return -(rate_per_period * (future_value + q * present_value)) / ((-1 + q) * (1 + rate_per_period * type));
  } else if (number_of_payments !== 0.0) {
    // No interest rate, but number of payments exists
    return -(future_value + present_value) / number_of_payments;
  }
  return 0;
};

export const getCalculatedSavingData = (
  userDetails,
  monthlyIncome,
  currentAsset,
  returnRate,
  inflationRate,
  savingTerms,
  savingTotalCoverage,
  showAccumulationValueAt,
  retireAfter,
  retirementReturnRate,
  retirementInflationRate,
  retirementTerms
) => {
  const currency = getUserCurrency(userDetails);

  const returnRateMonthly = returnRate / 1200;
  const inflationRateMonthly = inflationRate / 1200;
  const futureValueFirst = futureValueCalculator(
    returnRateMonthly - inflationRateMonthly,
    savingTerms * 12,
    -savingTotalCoverage,
    -currentAsset,
    1
  );

  const principalValue =
    showAccumulationValueAt > savingTerms
      ? currentAsset + savingTotalCoverage * 12 * savingTerms
      : currentAsset + savingTotalCoverage * 12 * showAccumulationValueAt;

  const futureValueSecond =
    showAccumulationValueAt > savingTerms
      ? futureValueCalculator(
          returnRateMonthly - inflationRateMonthly,
          (showAccumulationValueAt - savingTerms) * 12,
          0,
          -futureValueFirst,
          1
        )
      : futureValueCalculator(
          returnRateMonthly - inflationRateMonthly,
          showAccumulationValueAt * 12,
          -savingTotalCoverage,
          -currentAsset,
          1
        );

  const retirementValueAtRetireAfter =
    retireAfter > savingTerms
      ? futureValueCalculator(
          returnRateMonthly - inflationRateMonthly,
          (retireAfter - savingTerms) * 12,
          0,
          -futureValueFirst,
          1
        )
      : futureValueCalculator(
          returnRateMonthly - inflationRateMonthly,
          retireAfter * 12,
          -savingTotalCoverage,
          -currentAsset,
          1
        );

  const retirementReturnRateMonthly = retirementReturnRate / 1200;
  const retirementInflationRateMonthly = retirementInflationRate / 1200;

  const retirementValue = retirementValueCalculator(
    retirementReturnRateMonthly - retirementInflationRateMonthly,
    retirementTerms * 12,
    -retirementValueAtRetireAfter,
    0,
    1
  );
  const retirementIncomeLevel = getSavingRetirementIncomeLevel(retirementValue, monthlyIncome, currency);

  const retirementDisplayValue = isNaN(parseInt(retirementValue)) ? 0 : decimalToString(retirementValue, 0);

  return {
    returnRateMonthly,
    inflationRateMonthly,
    principalValue,
    futureValueFirst,
    futureValueSecond,
    retirementValueAtRetireAfter,
    retirementReturnRateMonthly,
    retirementInflationRateMonthly,
    retirementIncomeLevel,
    retirementDisplayValue
  };
};
