import { fetch } from '../../utils';
import Backend from '../../backend';

export async function uploadAttachments({ resourceId, attachments, data, type }) {
  try {
    const files = await Promise.all(
      attachments.map(async attachment => await fetch(attachment.url).then(r => r.blob()))
    );
    const uploadConfigPromises = files.map((file, index) => {
      return getMediaUploadConfig(type, resourceId, file.type, file.size, attachments[index].filename.split('.')[0]);
    });

    const mediaConfigs = await Promise.all(uploadConfigPromises);
    const uploadPromises = mediaConfigs.map((mediaConfig, index) => {
      return uploadMedia(mediaConfig, files[index]);
    });
    await Promise.all(uploadPromises);
    const attachmentsData = mediaConfigs.map((mediaConfig, index) => {
      let url;
      // can retrieve file directly from S3 if the acl is public-read
      if (mediaConfig.policy.conditions.length >= 8 && mediaConfig.policy.conditions[7]['acl'] === 'public-read') {
        url = `${mediaConfig.url}/${mediaConfig.policy.conditions[1]['key']}`;
      } else {
        // otherwise, get a signed url from the backend
        url = Backend.methodUrl(
          'media/download-url?' +
            new URLSearchParams({
              object_path: mediaConfig.policy.conditions[1]['key']
            })
        );
      }
      return {
        filename: attachments[index].filename,
          size: files[index].size,
        url: url
      };
    });

    return attachmentsData;
  } catch (err) {
    throw err;
  }
}

export const getMediaUploadConfig = (type, resourceId, contentType, contentLength, filename) => {
  return fetch(
    Backend.methodUrl(
      'media/upload-config?' +
        new URLSearchParams({
          type,
          resource_id: resourceId,
          content_type: contentType,
          content_length: contentLength,
          filename
        })
    )
  )
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};

export const uploadMedia = (mediaConfig, file) => {
  const formData = new FormData();
  formData.append('policy', Buffer.from(JSON.stringify(mediaConfig.policy)).toString('base64'));
  formData.append('key', mediaConfig.policy.conditions[1]['key']);
  formData.append('Content-Type', mediaConfig.policy.conditions[2]['Content-Type']);
  formData.append('x-amz-algorithm', mediaConfig.policy.conditions[4]['x-amz-algorithm']);
  formData.append('x-amz-credential', mediaConfig.policy.conditions[5]['x-amz-credential']);
  formData.append('x-amz-date', mediaConfig.policy.conditions[6]['x-amz-date']);
  if (mediaConfig.policy.conditions.length >= 8 && mediaConfig.policy.conditions[7]['acl']) {
    formData.append('acl', mediaConfig.policy.conditions[7]['acl']);
  }
  formData.append('x-amz-signature', mediaConfig.signature);
  formData.append('file', file);

  return fetch(
    mediaConfig.url,
    {
      method: 'POST',
      body: formData
    },
    false
  );
};
