import React from 'react';
import IntegerControl from '../../../IntegerControl/IntegerControl';

class MetControl extends React.PureComponent {
  render() {
    const { className, met, onChange, ...rest } = this.props;

    return <IntegerControl className={className} value={met} onChange={onChange} min={0} max={12} {...rest} />;
  }
}

export default MetControl;
