import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { getFormattedName } from './file-system';
import { blobToDataURL } from '../download';
import Compressor from 'compressorjs';

export const isSharable = () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return navigator && !!navigator.share;
  } else {
    return true;
  }
};

export const share = async ({ title, text, url, imageUrl, imageCompression }) => {
  const currentPlatform = Capacitor.getPlatform();
  const shareData = { title, text, url };

  const compress = async blob => {
    return new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality: 1,
        resize: 'contain',
        ...imageCompression,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        }
      });
    });
  };

  // if (currentPlatform === 'web') {
  if (navigator && !!navigator.share) {
    if (imageUrl) {
      const res = await fetch(imageUrl, { cache: 'no-cache' });
      const blob = await res.blob();
      const compressedBlob = await compress(blob);
      const f = new File([compressedBlob], 'thumbnail.png', { type: compressedBlob.type });
      shareData.files = [f];
    }
    return navigator.share(shareData);
  } else {
    if (currentPlatform === 'ios' || currentPlatform === 'android') {
      if (imageUrl) {
        const res = await fetch(imageUrl, { cache: 'no-cache' });
        const blob = await res.blob();
        const compressedBlob = await compress(blob);
        const dataUrl = await blobToDataURL(compressedBlob);

        const formattedName = await getFormattedName(`${new Date().getTime()}.png`, '', Directory.Cache);

        const writeResult = await Filesystem.writeFile({
          path: formattedName,
          data: dataUrl,
          directory: Directory.Cache
        });

        shareData.url = writeResult.uri;

        if (currentPlatform === 'ios') {
          delete shareData.title;
          delete shareData.text;
        }
      }

      return Share.share(shareData)
        .then()
        .catch(console.log);
    }
  }

  // } else {
  //   if (imageUrl) {
  //     const res = await fetch(imageUrl, { cache: 'no-cache' });
  //     const blob = await res.blob();
  //     const dataUrl = await blobToDataURL(blob);
  //
  //     const fileExtension = imageUrl.split('.').pop();
  //     const formattedName = await getFormattedName(`${new Date().getTime()}.${fileExtension}`, '', Directory.Cache);
  //
  //     const writeResult = await Filesystem.writeFile({
  //       path: formattedName,
  //       data: dataUrl,
  //       directory: Directory.Cache
  //     });
  //
  //     shareData.url = writeResult.uri;
  //   }
  //
  //   return Share.share(shareData);
  // }
};
