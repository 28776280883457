import React from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import { toggleDialog } from '../../store/control/duck';
import { injectIntl } from 'react-intl';

const ThirdPartyRedirectDialog = props => {
  const { intl, thirdPartyRedirectDialog, toggleDialog } = props;

  const { title, content, url, closed } = thirdPartyRedirectDialog || {};

  const close = () => {
    toggleDialog('thirdPartyRedirect', { ...thirdPartyRedirectDialog, closed: true });
  };

  const confirm = () => {
    window.open(url, '_blank');
    close();
  };

  const onExited = () => {
    toggleDialog('thirdPartyRedirect', false);
  };

  return (
    <Dialog
      open={!!thirdPartyRedirectDialog && !closed}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={confirm}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    thirdPartyRedirectDialog: state.control.thirdPartyRedirectDialog
  }),
  {
    toggleDialog
  }
)(ThirdPartyRedirectDialog);

export default injectIntl(container);
