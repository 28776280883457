import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { history } from '../views/App/history';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const useHistoryChange = (fn, dependencies) => {
  const historyListenerRef = useRef(null);
  const _fn = useCallback(fn, dependencies);

  if (!historyListenerRef.current) {
    historyListenerRef.current = history.listen((path, action) => {
      _fn(path, action);
    });
  }

  return historyListenerRef.current;
};
