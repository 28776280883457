import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getUserCurrency } from '../../../../utils/user';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';

const CISummary = props => {
  const { intl, userDetails, ciToReachData, ciTotalCoverage } = props;

  const currency = getUserCurrency(userDetails);
  const { reachGood, reachExcellent } = ciToReachData || {};

  return (
    <div className="text-center cardAnalysis analysis-expansion-element greyL6 font16 analysis-total">
      <h4>{intl.formatMessage({ id: 'Total C.I. protection' })}</h4>
      <div>
        <h3 className="figureColor">
          {intl.formatMessage({ id: currency })} {decimalToString(ciTotalCoverage)}{' '}
        </h3>
      </div>
      {(reachGood > 0 || reachExcellent > 0) && (
        <div>
          {reachGood > 0 && (
            <div>
              <span>
                {intl.formatMessage({
                  id: 'Top up'
                })}{' '}
              </span>

              <span className="figureColor">
                {intl.formatMessage({
                  id: currency
                })}{' '}
                {formatNumber(reachGood, intl)}{' '}
              </span>

              <span>
                {intl.formatMessage({
                  id: 'to be'
                })}{' '}
              </span>

              <span className="good-color">
                {intl.formatMessage({
                  id: 'Good'
                })}
              </span>
            </div>
          )}

          {reachExcellent > 0 && (
            <div>
              <span>
                {intl.formatMessage({
                  id: 'Top up'
                })}{' '}
              </span>

              <span className="figureColor">
                {intl.formatMessage({
                  id: currency
                })}{' '}
                {formatNumber(reachExcellent, intl)}{' '}
              </span>

              <span>
                {intl.formatMessage({
                  id: 'to be'
                })}{' '}
              </span>

              <span className="excellent-color">
                {intl.formatMessage({
                  id: 'Nice'
                })}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    ciToReachData: state.analysis.ciToReachData,
    ciTotalCoverage: state.analysis.ciTotalCoverage
  }),
  {}
)(CISummary);

export default injectIntl(container);
