import Rx from 'rxjs/Rx';
import { combineEpics } from 'redux-observable';
import * as api from './api';
import { UPDATE_TODO } from '../user/duck';

export const UPDATE_ADVISER_TODO = 'portfoplus/advisor/UPDATE_ADVISOR_TODO';
export const UPDATE_ADVISER_TODO_SUCCESS = 'portfoplus/advisor/UPDATE_ADVISOR_TODO_SUCCESS';
export const UPDATE_ADVISER_TODO_ERROR = 'portfoplus/advisor/UPDATE_ADVISOR_TODO_ERROR';

export const ADD_ADVISER_TODO = 'portfoplus/advisor/ADD_ADVISOR_TODO';
export const ADD_ADVISER_TODO_SUCCESS = 'portfoplus/advisor/ADD_ADVISOR_TODO_SUCCESS';
export const ADD_ADVISER_TODO_ERROR = 'portfoplus/advisor/ADD_ADVISOR_TODO_ERROR';

export const DELETE_ADVISER_TODO = 'portfoplus/advisor/DELETE_ADVISOR_TODO';
export const DELETE_ADVISER_TODO_SUCCESS = 'portfoplus/advisor/DELETE_ADVISOR_TODO_SUCCESS';
export const DELETE_ADVISER_TODO_ERROR = 'portfoplus/advisor/DELETE_ADVISOR_TODO_ERROR';

export const DELETE_ALL_COMPLETED_TODO = 'portfoplus/advisor/DELETE_ALL_COMPLETED_TODO';
export const DELETE_ALL_COMPLETED_TODO_SUCCESS = 'portfoplus/advisor/DELETE_ALL_COMPLETED_TODO_SUCCESS';
export const DELETE_ALL_COMPLETED_TODO_ERROR = 'portfoplus/advisor/DELETE_ALL_COMPLETED_TODO_ERROR';

export const UPDATE_SORT = 'portfoplus/adviser/UPDATE_SORT';

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = { error: null, submitting: false, sort: 'creationDate' }, action = {}) {
  switch (action.type) {
    case DELETE_ADVISER_TODO:
    case DELETE_ALL_COMPLETED_TODO:
    case ADD_ADVISER_TODO:
    case UPDATE_ADVISER_TODO: {
      return { ...state, isSubmitting: true, error: null };
    }

    case ADD_ADVISER_TODO_SUCCESS:
    case DELETE_ALL_COMPLETED_TODO_SUCCESS:
    case DELETE_ADVISER_TODO_SUCCESS:
    case UPDATE_ADVISER_TODO_SUCCESS: {
      if (!action.payload.success) {
        return { ...state, error: action.payload.message, isSubmitting: false };
      }
      return { ...state, isSubmitting: false };
    }

    case ADD_ADVISER_TODO_ERROR:
    case DELETE_ALL_COMPLETED_TODO_ERROR:
    case DELETE_ADVISER_TODO_ERROR:
    case UPDATE_ADVISER_TODO_ERROR: {
      const { payload } = action;
      return { ...state, error: payload.error, isSubmitting: false };
    }
    case UPDATE_SORT: {
      const { payload } = action;
      return { ...state, sort: payload.sort };
    }
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const updateAdviserTodo = data => {
  return { type: UPDATE_ADVISER_TODO, payload: data };
};

export const addAdviserTodo = data => {
  return { type: ADD_ADVISER_TODO, payload: data };
};

export const deleteAdviserTodo = data => {
  return { type: DELETE_ADVISER_TODO, payload: data };
};

export const deleteAllCompletedTodo = () => {
  return { type: DELETE_ALL_COMPLETED_TODO };
};

export const updateSort = data => {
  return { type: UPDATE_SORT, payload: data };
};

/***********************************
 * Epics
 ***********/
const addAdviserTodoEpic = action$ =>
  action$.ofType(ADD_ADVISER_TODO).mergeMap(action => {
    return Rx.Observable.fromPromise(api.addAdviserTodo(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TODO,
                payload
              },
              {
                type: ADD_ADVISER_TODO_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: ADD_ADVISER_TODO_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: ADD_ADVISER_TODO_ERROR,
          payload: { error }
        })
      );
  });

const updateAdviserTodoEpic = action$ =>
  action$.ofType(UPDATE_ADVISER_TODO).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateAdviserTodo(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TODO,
                payload
              },
              {
                type: UPDATE_ADVISER_TODO_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: UPDATE_ADVISER_TODO_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_ADVISER_TODO_ERROR,
          payload: { error }
        })
      );
  });

const deleteAdviserTodoEpic = action$ =>
  action$.ofType(DELETE_ADVISER_TODO).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteAdviserTodo(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TODO,
                payload
              },
              {
                type: DELETE_ADVISER_TODO_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: DELETE_ADVISER_TODO_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_ADVISER_TODO_ERROR,
          payload: { error }
        })
      );
  });

const deleteAllCompletedTodoEpic = action$ =>
  action$.ofType(DELETE_ALL_COMPLETED_TODO).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteAllCompletedTodo())
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TODO,
                payload
              },
              {
                type: DELETE_ALL_COMPLETED_TODO_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: DELETE_ALL_COMPLETED_TODO_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_ALL_COMPLETED_TODO_ERROR,
          payload: { error }
        })
      );
  });

export const todoListEpic = combineEpics(
  addAdviserTodoEpic,
  updateAdviserTodoEpic,
  deleteAdviserTodoEpic,
  deleteAllCompletedTodoEpic
);
