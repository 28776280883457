import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Input,
  IconButton
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { updateAdviserCustomFilters } from '../../../../../store/adviserCustomFilters/duck';
import { toast } from 'react-toastify';
import IconToast from '../../../../../components/NewToast';
import _ from 'lodash';
import LoadingBackdrop from '../../../../../components/LoadingBackdrop/LoadingBackdrop';

const CustomFilterModal = ({
  open,
  onClose,
  filters,
  intl,
  usedTags,
  customFilterIndex,
  searchTerm,
  clientState,
  filterObject
}) => {
  const state = useSelector(s => s.adviserCustomFilters);
  const [name, setName] = useState(filters[customFilterIndex].name);
  const [openNameEditor, setOpenNameEditor] = useState(false);
  const [localSubmit, setLocalSubmit] = useState(false);
  const dispatch = useDispatch();
  const save = useCallback(() => {
    const isNotDirty =
      _.isEqual(filters[customFilterIndex].filterObject, filterObject) &&
      (filters[customFilterIndex].name === name ||
        (filters[customFilterIndex].name === null &&
          name === intl.formatMessage({ id: `custom-filter-${customFilterIndex + 1}` }))) &&
      filters[customFilterIndex].clientState === clientState &&
      filters[customFilterIndex].searchTerm === searchTerm;
    if (isNotDirty) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Updated successfully' })), {
        className: 'new-toast'
      });
      onClose();
      return;
    }
    const searchState = [...filters];
    const newFilter = {};
    newFilter.name = name;
    newFilter.clientState = clientState;
    const newFilterObject = { ...filterObject };
    if (newFilterObject['tags'] && newFilterObject['tags'].length > 0) {
      newFilterObject['tags'] = newFilterObject['tags'].filter(e => usedTags.includes(e));
      if (newFilterObject['tags'].length === 0) {
        delete newFilterObject.tags;
      }
    }
    newFilter.filterObject = newFilterObject;
    newFilter.searchTerm = searchTerm;
    newFilter.initial = false;
    searchState[customFilterIndex] = newFilter;
    setLocalSubmit(true);
    dispatch(updateAdviserCustomFilters({ customFilters: searchState }));
  }, [name, clientState, filterObject, searchTerm, customFilterIndex, filters, intl]);
  useEffect(() => {
    if (state.submitting === false && state.error && localSubmit) {
      setLocalSubmit(false);
      toast.info(IconToast('error', intl.formatMessage({ id: 'todo-err-msg' })), {
        className: 'new-toast'
      });
    } else if (state.submitting === false && state.error === false && localSubmit) {
      setLocalSubmit(false);
      onClose();
      toast.info(IconToast('info', intl.formatMessage({ id: 'Updated successfully' })), {
        className: 'new-toast'
      });
    }
  }, [state, open, localSubmit]);
  useEffect(() => {
    setName(filters[customFilterIndex].name);
  }, [customFilterIndex, filters]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setName(filters[customFilterIndex].name);
        setOpenNameEditor(false);
        onClose();
      }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={SlideTransition}
    >
      {state.submitting && <LoadingBackdrop open={true} />}
      <DialogTitle>{intl.formatMessage({ id: 'custom-filter-modal-title' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'custom-filter-edit' })}</DialogContentText>
        {!openNameEditor && (
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            <Button
              variant="outlined"
              className="cpi-btn"
              color="primary"
              style={{ minWidth: 50, padding: '0 10px', border: '1.1px solid' }}
              onClick={() => setOpenNameEditor(true)}
            >
              {name === null ? intl.formatMessage({ id: `custom-filter-${customFilterIndex + 1}` }) : name}
            </Button>
          </div>
        )}
        {openNameEditor && (
          <div style={{ textAlign: 'center' }}>
            <Input
              onChange={e => setName(e.target.value)}
              value={name === null ? intl.formatMessage({ id: `custom-filter-${customFilterIndex + 1}` }) : name}
              style={{ fontSize: 15, width: 138 }}
              inputProps={{ style: { textAlign: 'center', height: '1.5em' } }}
              type="text"
            />
            <Button
              style={{ minWidth: 39, padding: 0, margin: '0px 0px 12px 6px' }}
              color="primary"
              variant="contained"
              onClick={() => setOpenNameEditor(false)}
            >
              {intl.formatMessage({ id: 'Set' })}
            </Button>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          autoFocus
          onClick={() => {
            if (openNameEditor) {
              setName(filters[customFilterIndex].name);
              setOpenNameEditor(false);
            } else {
              onClose();
            }
          }}
          color="primary"
          autoFocus
          disabled={localSubmit}
        >
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={save} color="primary" autoFocus disabled={localSubmit || openNameEditor}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomFilterModal;
