import React, { useState } from 'react';
import {
  categorySorter,
  getPolicyStartDate,
  isAnnuityPolicy,
  isCategory,
  isILASPolicy,
  isPolicySubCategory,
  isSubCategory,
} from '../../utils/policy';
import Grid from '@material-ui/core/Grid';
import { Typography, useMediaQuery } from '@material-ui/core';
import PolicyCategoryTable from './PolicyCategoryTable/PolicyCategoryTable';
import './PolicyReview.scss';
import moment from 'moment-timezone';
import _ from 'lodash';
import Skeleton from '@material-ui/lab/Skeleton';
import { injectIntl } from 'react-intl';
import { getWebImageUrl } from '../../utils/image';
import ShavedText from '../../components/ShavedText/ShavedText';
import { android, iOS, mobile } from '../../utils/device';
import AdviserInfo from '../Export/AdviserInfo/AdviserInfo';
import PoweredBy from './PoweredBy/PoweredBy';
import PolicyReviewHeading from './PolicyReviewHeading/PolicyReviewHeading';
import MyResizeObserver from '../../components/MyResizeObserver/MyResizeObserver';
import { sanitize } from 'dompurify';
import { getUserLogo } from '../../utils/user';
import { ADVISER_LOGO_FUNCTIONS } from '../../constants/user';

export const PolicyReviewShavedText = (props) => <ShavedText maxHeight={28}>{props.children}</ShavedText>;
export const PolicyReviewLackCoverIcon = (props) => (
  <img src={getWebImageUrl('img/policy-review/PolicyReviewLackCover.svg')} alt="lack-cover" width="20" height="20" />
);
export const PolicyReviewNotTaxDeductibleIcon = (props) => (
  <img src={getWebImageUrl('img/policy-review/NotTaxDeductible.png')} alt="not-tax-deductible" width="20" height="20" />
);
export const PolicyReviewInapplicableField = (props) => '–';

export const InsuredNotMeImage = (props) => (
  <Grid item style={{ width: 20, height: 20 }}>
    <img alt="insured-not-me" src={getWebImageUrl('img/notme.png')} style={{ width: 'inherit', height: 'inherit' }} />
  </Grid>
);

export const getMaxWidth = ({ 
  selectBasic, selectProtection, selectPremium,  selectTwoRows, template,
  selectSaving, selectLifeInsured, selectBeneficiary, selectAccident,
  selectTotalWithdrawals, selectLumpSum, selectCurrencyPerYear
}) => {
  const templateOffset = !!template ? -40 : 0;

  let width;

  if (selectTwoRows) {
    if (selectBasic && !selectProtection && !selectPremium) {
      width = 1120;
    } else {
      width = 1412;
    }
  } else {
    width = 570;

    if (selectBasic) {
      width += 550;
    }

    if (selectProtection) {
      width += 621;
    }

    if (selectPremium) {
      width += 431;
    }
  }

  return Math.max(width + templateOffset, 950);
};

const PolicyReview = (props) => {
  const {
    intl,
    clientName,
    currency,
    categories,
    portfolio,
    selectShowLacks,
    selectBasic,
    selectProtection,
    selectPremium,
    selectSaving, 
    selectLifeInsured, 
    selectBeneficiary, 
    selectAccident,
    selectTotalWithdrawals, 
    selectLumpSum, 
    selectCurrencyPerYear,
    selectPersonal,
    selectCompany,
    selectGeneral,
    selectTwoRows,
    selectTotalCoveragePremium,
    selectTeamLogo,
    title,
    remarkOnClick,
    remarkDisabled,
    adviser,
    viewingClientId,
    adviserTeamLogoUrl,
    explicitLoading,
    template,
    isPrivateMode,
    ...rest
  } = props;

  const [disclaimerWidth, setDisclaimerWidth] = useState(0);
  const columnAdviserInfo = useMediaQuery('(max-width:420px)');
  const useCustomScrollBar = !iOS() && !android();

  // console.log(JSON.stringify({
  // 	clientName,
  // 	currency,
  // 	categories,
  // 	portfolio,
  // 	selectBasic,
  // 	selectProtection,
  // 	selectPremium,
  // 	selectPersonal,
  // 	selectCompany,
  // 	selectGeneral,
  // 	selectTwoRows,
  // 	selectTotalCoveragePremium,
  // }));

  // filter policies
  const finalPortfolio = [...(portfolio || [])];

  // categories = own categories, categories1 = share categories
  // filter & sort categories
  let finalCategories = [...(categories || [])];

  // move accidental sub-category from misc. category to personal category explicitly
  let personalCategoryIndex = finalCategories.findIndex((category) => isCategory(category._id, 'personal'));
  const miscCategoryIndex = finalCategories.findIndex((category) => isCategory(category._id, 'general'));
  if (personalCategoryIndex > -1 && miscCategoryIndex > -1) {
    const accidentSubCategoryIndex = finalCategories[miscCategoryIndex].subCategories.findIndex((subCategory) =>
      isSubCategory(subCategory._id, 'accident'),
    );
    if (accidentSubCategoryIndex > -1) {
      finalCategories[personalCategoryIndex].subCategories.push(
        finalCategories[miscCategoryIndex].subCategories[accidentSubCategoryIndex],
      );
      finalCategories[miscCategoryIndex].subCategories.splice(accidentSubCategoryIndex, 1);
    }

    // sort personal category
    finalCategories[personalCategoryIndex].subCategories.sort((a, b) => {
      // console.log(a, b, isSubCategory(a, 'others'), isSubCategory(b, 'others'));
      if (isSubCategory(a._id, 'others')) {
        return 1;
      } else if (isSubCategory(b._id, 'others')) {
        return -1;
      }
      return 0;
    });
  }

  finalCategories = finalCategories
    .filter((category) => {
      const categoryId = _.get(category, '_id');
      return (
        (isCategory(categoryId, 'personal') && selectPersonal) ||
        (isCategory(categoryId, 'general') && selectGeneral) ||
        (isCategory(categoryId, 'company') && selectCompany)
      );
    })
    .sort()
    .map((category) => ({
      ...category,
      subCategories: (category.subCategories || []).map((subCategory) => ({
        ...subCategory,
        policies: [],
      })),
    }));

  // update index of personal category after filtering
  personalCategoryIndex = finalCategories.findIndex((category) => isCategory(category._id, 'personal'));

  finalPortfolio
    .sort((a, b) => {
      const aPolicyStartDate = getPolicyStartDate(a),
        bPolicyStartDate = getPolicyStartDate(b);
      if (!aPolicyStartDate && bPolicyStartDate) {
        return -1;
      } else if (aPolicyStartDate && !bPolicyStartDate) {
        return 1;
      } else {
        try {
          const isAFirst = moment(aPolicyStartDate).isSameOrBefore(moment(bPolicyStartDate));
          if (isAFirst) {
            return -1;
          } else {
            return 1;
          }
        } catch (e) {
          return -1;
        }
      }
    })
    .forEach((policy) => {
      const targetCategoryIndex =
        isPolicySubCategory(policy, 'accident') && personalCategoryIndex > -1
          ? personalCategoryIndex
          : finalCategories.findIndex((category) => category._id && category._id === policy.categoryId);
      if (targetCategoryIndex > -1) {
        if (finalCategories[targetCategoryIndex].subCategories) {
          const targetSubCategoryIndex = finalCategories[targetCategoryIndex].subCategories.findIndex(
            (subCategory) => subCategory._id && subCategory._id === policy.subCategoryId,
          );
          if (targetSubCategoryIndex > -1) {
            finalCategories[targetCategoryIndex].subCategories[targetSubCategoryIndex].policies.push(policy);
          }
        }
      }
    });

  // add dummy policy to empty subcategories for personal category
  if (selectShowLacks) {
    finalCategories.forEach((category) => {
      if (isCategory(category._id, ['personal', 'general'])) {
        category.subCategories.forEach((subCategory) => {
          if (isSubCategory(subCategory._id, ['life', 'ci', 'medical', 'accident', 'provident'])) {
            if (subCategory.policies.length === 0) {
              subCategory.policies.push({
                _id: subCategory._id,
                subCategoryId: subCategory._id,
                selectedPolicyStatus: 'pr-lacks',
                lifeCoverage: 0,
                sumInsured: 0,
                premium: 0,
                frequency: 12,
                dummy: true,
                explicitNonTaxDeductible: isSubCategory(subCategory._id, ['medical', 'provident']),
              });
            }
          } else if (isSubCategory(subCategory._id, 'saving')) {
            let havePolicyNoILASAnnuity = false,
              havePolicyILASOnly = false,
              havePolicyAnnuityOnly = false;

            subCategory.policies.forEach((policy) => {
              const isILAS = isILASPolicy(policy);
              const isAnnuity = isAnnuityPolicy(policy);
              if (!isILAS && !isAnnuity) {
                havePolicyNoILASAnnuity = true;
              } else if (isILAS && !isAnnuity) {
                havePolicyILASOnly = true;
              } else if (!isILAS && isAnnuity) {
                havePolicyAnnuityOnly = true;
              }
            });

            if (!havePolicyNoILASAnnuity) {
              subCategory.policies.push({
                _id: `${subCategory._id}_1`,
                subCategoryId: subCategory._id,
                policyName: intl.formatMessage({ id: 'Saving' }),
                selectedPolicyStatus: 'pr-lacks',
                lifeCoverage: 0,
                sumInsured: 0,
                premium: 0,
                frequency: 12,
                guaranteeValue: 'Yes',
                isAnnuity: 'No',
                dummy: true,
              });
            }

            if (!havePolicyILASOnly) {
              subCategory.policies.push({
                _id: `${subCategory._id}_2`,
                subCategoryId: subCategory._id,
                policyName: intl.formatMessage({ id: 'ILAS' }),
                selectedPolicyStatus: 'pr-lacks',
                lifeCoverage: 0,
                sumInsured: 0,
                premium: 0,
                frequency: 12,
                guaranteeValue: 'Yes',
                isAnnuity: 'Yes',
                dummy: true,
              });
            }

            if (!havePolicyAnnuityOnly) {
              subCategory.policies.push({
                _id: `${subCategory._id}_0`,
                subCategoryId: subCategory._id,
                policyName: intl.formatMessage({ id: 'Annuity' }),
                selectedPolicyStatus: 'pr-lacks',
                lifeCoverage: 0,
                sumInsured: 0,
                premium: 0,
                frequency: 12,
                guaranteeValue: 'No',
                isAnnuity: 'No',
                dummy: true,
                explicitNonTaxDeductible: true,
              });
            }
          }
        });
      }
    });
  }

  const updateDisclaimerHeight = (entries) => {
    setDisclaimerWidth(_.get(entries, '[0].target.offsetWidth', 0) / 2);
  };

  const showLogo =
    (selectTwoRows && selectBasic && selectPremium) ||
    (!selectTwoRows && (selectProtection || (selectBasic && selectPremium)));
  const userLogo = getUserLogo(adviser, adviserTeamLogoUrl, ADVISER_LOGO_FUNCTIONS.PDF);
  const showTeamLogo = selectTeamLogo && userLogo;
  const showAdviserInfo = adviser && !mobile();
  const maxWidth = getMaxWidth({ 
    selectBasic, 
    selectProtection, 
    selectPremium, 
    selectSaving, 
    selectLifeInsured, 
    selectBeneficiary, 
    selectAccident,
    selectTotalWithdrawals, 
    selectLumpSum, 
    selectCurrencyPerYear,
    selectTwoRows, 
    template 
  });

  return (
    <div className={`policy-review ${useCustomScrollBar ? 'custom-scroll' : ''}`} style={{ maxWidth: maxWidth }}>
      <MyResizeObserver targetsSelector=".tables" onResize={updateDisclaimerHeight} />

      {(template || showAdviserInfo) && !isPrivateMode && (
        <Grid
          className="header"
          container
          justify="flex-end"
          alignItems="center"
          style={
            template
              ? {
                  backgroundImage: `url("${getWebImageUrl('img/policy-review/header_v2-right-3.png')}")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto 160px',
                  backgroundPosition: 'bottom 0 right 0',
                  paddingBottom: 24,
                  position: 'relative',
                  minHeight: 160,
                }
              : undefined
          }
        >
          <div
            style={
              template
                ? {
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    backgroundImage: `url("${getWebImageUrl('img/policy-review/header_v2-left.png')}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 160px',
                    backgroundPosition: `bottom 0 left 0`,
                  }
                : undefined
            }
          />

          {template && showLogo && (
            <Grid item style={{ flexGrow: 1 }}>
              <div
                style={{
                  width: 'calc(100% - 575px)',
                  minWidth: showTeamLogo ? 260 : 195,
                  float: 'right',
                  textAlign: 'center',
                }}
              >
                {showTeamLogo ? (
                  <img
                    src={userLogo}
                    alt="team"
                    style={{ maxHeight: 150, maxWidth: 260, width: 'auto', objectFit: 'contain' }}
                  />
                ) : (
                  <img src={getWebImageUrl('img/policy-review/PFPLogoSummaryNull.png')} alt="portfoplus" width={195} /> //temp fix , was "img/policy-review/PFPLogoSummary.png"
                )}
              </div>
            </Grid>
          )}

          {template && showAdviserInfo && (
            <Grid item>
              <AdviserInfo
                show={true}
                showQRCode={true}
                adviser={adviser}
                referById={viewingClientId}
                direction={columnAdviserInfo ? 'column' : 'row'}
                QRActionButton="create-one-for-free"
                qrCodeWidth={150}
                referLinkParams={{
                  source: 'pdf',
                  extraSearch: {
                    defaultMessage: intl.formatMessage({ id: 'policy-review-default-msg' }, { clientName: clientName }),
                  },
                }}
              />
            </Grid>
          )}
        </Grid>
      )}

      <PolicyReviewHeading
        className="heading"
        spacing={columnAdviserInfo ? 1 : undefined}
        name={clientName}
        title={<div dangerouslySetInnerHTML={{ __html: sanitize(decodeURIComponent(title)) }} />}
        template={template}
        autoAdjustLayout={true}
        remarkOnClick={remarkOnClick}
        remarkDisabled={remarkDisabled}
      />

      {categories.length > 0 && !explicitLoading ? (
        finalCategories.length > 0 ? (
          <Grid className="tables" container direction="column">
            {finalCategories.map((category, index) => (
              <Grid item key={category._id || index}>
                <PolicyCategoryTable
                  category={category}
                  currency={currency}
                  selectBasic={selectBasic}
                  selectProtection={selectProtection}
                  selectPremium={selectPremium}
                  selectSaving={selectSaving}
                  selectLifeInsured={selectLifeInsured}
                  selectBeneficiary={selectBeneficiary}
                  selectAccident={selectAccident}
                  selectTotalWithdrawals={selectTotalWithdrawals}
                  selectLumpSum={selectLumpSum}
                  selectCurrencyPerYear={selectCurrencyPerYear}
                  selectTwoRows={selectTwoRows}
                  selectTotalCoveragePremium={selectTotalCoveragePremium}
                  template={template}
                  index={index}
                  {...rest}
                />
              </Grid>
            ))}
          </Grid>
        ) : null
      ) : (
        <Grid className="tables" container direction="column">
          <Grid item style={{ width: '100%' }}>
            <Skeleton variant="rect" width="100%" height={200} animation="wave" />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Skeleton variant="rect" width="100%" height={200} animation="wave" />
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Skeleton variant="rect" width="100%" height={200} animation="wave" />
          </Grid>
        </Grid>
      )}

      <div className="remark">
        <Grid
          container
          justify="space-between"
          wrap="nowrap"
          alignItems={window.innerWidth > 500 ? 'center' : 'flex-start'}
        >
          <Grid item style={{ minWidth: 130 }}>
            <Grid container spacing={2}>
              <Grid item>
                <Grid container alignItems="center" className="remark-icon-text">
                  <Grid item>
                    <PolicyReviewLackCoverIcon />
                  </Grid>
                  <Grid item>
                    <Typography>{` : ${intl.formatMessage({ id: 'lack-coverage' })}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" className="remark-icon-text">
                  <Grid item>
                    <PolicyReviewNotTaxDeductibleIcon />
                  </Grid>
                  <Grid item>
                    <Typography>{` : ${intl.formatMessage({ id: 'pr-not-tax-deductible-hint' })}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" className="remark-icon-text">
                  <Grid item>
                    <img alt="insured-not-me" src={getWebImageUrl('img/notme.png')} />
                  </Grid>
                  <Grid item>
                    <Typography>{`: ${intl.formatMessage({ id: 'life-insured-hint' })}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {!template && (
                <Grid item>
                  <Grid container alignItems="center" className="remark-icon-text">
                    <Grid item>
                      <img alt="draft" src={getWebImageUrl('img/draft.png')} />
                    </Grid>
                    <Grid item>
                      <Typography>{`: ${intl.formatMessage({ id: 'draft-hint' })}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item>
                <Grid container alignItems="center" className="remark-icon-text">
                  <Grid item>
                    <img
                      alt="no-premium-level"
                      className="not_level_icon_small"
                      src={getWebImageUrl('img/not-level.png')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{`: ${intl.formatMessage({ id: 'no-premium-level-hint' })}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" className="remark-icon-text">
                  <Grid item>
                    <img alt="rider" className="not_level_icon_small" src={getWebImageUrl('img/rider_small.png')} />
                  </Grid>
                  <Grid item>
                    <Typography>{`: ${intl.formatMessage({ id: 'pdf-rider-hint' })}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ flexGrow: 1 }}>
            <PoweredBy />
          </Grid>
        </Grid>
      </div>

      <div className="remark" style={{ width: disclaimerWidth }}>
        <Typography className="disclaimer">{intl.formatMessage({ id: 'pr-disclaimer' })}</Typography>
      </div>
    </div>
  );
};

export default injectIntl(PolicyReview);
