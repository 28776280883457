import { fetch } from '../../utils';
import Backend from '../../backend';

export const getBusinessSummary = advisorId => {
  let url = '';

  if (advisorId.input_year) {
    const id = advisorId.user;
    url =
      Backend.methodUrl('getBusinessSummary') +
      `/${id}?input_month=${advisorId.input_month}&input_year=${advisorId.input_year}&input_target=${advisorId.input_target}`;
  } else {
    url = Backend.methodUrl('getBusinessSummary') + '/' + advisorId;
  }
  return fetch(url, { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
