import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import { getUserFirstGuidelineTotalSteps } from '../../../store/guideline/userFirstGuideline';
import DoNotShowAgainTooltip from '../DoNotShowAgainTooltip/DoNotShowAgainTooltip';
import { updateUserDetails } from '../../../store/user/duck';

const UserFirstGuidelineTooltip = props => {
  const {
    intl,
    userDetails,
    updateUserDetails,
    stepsIdentifier,

    continuous,
    index,
    isLastStep,
    step,
    size,
    ...rest
  } = props;
  const { locale, showProgress } = step;
  const { close, last, next } = locale;
  const output = {
    primary: close
  };

  let formattedStep = step,
    formattedSize = size;

  if (continuous) {
    output.primary = isLastStep ? last : next;

    if (showProgress) {
      // need to customize page size and current page according to redux state as the whole guideline is composed of 3 guidelines.
      const guidelineLengths = getUserFirstGuidelineTotalSteps({ intl });

      let currentIndex = index;
      if (stepsIdentifier === 'userFirstLogin2') {
        currentIndex += guidelineLengths.userFirstLogin1;
      } else if (stepsIdentifier === 'userFirstLogin3') {
        currentIndex += guidelineLengths.userFirstLogin1 + guidelineLengths.userFirstLogin2;
      }
      formattedStep = currentIndex + 1;
      formattedSize = guidelineLengths.total;
    }
  }

  return (
    <DoNotShowAgainTooltip
      continuous={continuous}
      index={index}
      isLastStep={isLastStep}
      step={step}
      formattedStep={formattedStep}
      formattedSize={formattedSize}
      doNotShowAgain={_.get(userDetails, 'firstLoginGuidelineDone', false)}
      onChangeDoNotShowAgain={event => updateUserDetails({ firstLoginGuidelineDone: event.target.checked })}
      {...rest}
    />
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    stepsIdentifier: state.guideline.stepsIdentifier
  }),
  {
    updateUserDetails
  }
)(UserFirstGuidelineTooltip);

export default injectIntl(container);
