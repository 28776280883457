import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';

import Card from '@material-ui/core/Card';

class EmojiCard extends PureComponent {
  render() {
    const { intl, headingStatus } = this.props;

    return (
      <Card className="progress-card quarter-progress-description" id="quarter-end-card">
        {headingStatus === 'onTrack' && (
          <div>
            <div>
              {intl.formatMessage({
                id: 'You are on track'
              })}
            </div>
            <img
              alt=""
              src="img/good.png"
              className="support-duration-img-on-quater-progress-card quarter-progress-emoji"
            />
            <div>
              {intl.formatMessage({
                id: 'Keep it up!'
              })}
            </div>
          </div>
        )}
        {headingStatus === 'offTrack' && (
          <div>
            <div>
              {intl.formatMessage({
                id: 'You are off track'
              })}
            </div>
            <img
              alt=""
              src="img/bad.png"
              className="support-duration-img-on-quater-progress-card quarter-progress-emoji"
            />
            <div>
              {intl.formatMessage({
                id: 'Start the engine!'
              })}
            </div>
          </div>
        )}
        {headingStatus === 'great' && (
          <div>
            <div>
              {intl.formatMessage({
                id: 'Great!'
              })}
            </div>
            <div>
              <img
                alt=""
                src="img/excellent.png"
                className="support-duration-img-on-quater-progress-card quarter-progress-emoji"
              />
            </div>
            <div>
              {intl.formatMessage({
                id: 'hit target!'
              })}
            </div>
          </div>
        )}
        {headingStatus === 'zeroProgress' && (
          <div>
            <div>
              {intl.formatMessage({
                id: 'Set to get started'
              })}
            </div>
            <div>
              <img
                alt=""
                src="img/poor.png"
                className="support-duration-img-on-quater-progress-card quarter-progress-emoji"
              />
            </div>
            <div>
              {intl.formatMessage({
                id: 'Start the engine!'
              })}
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default injectIntl(EmojiCard);
