import { connect } from 'react-redux';
import { getUser } from '../../store/user/duck';
import { injectIntl } from 'react-intl';
import QRCodeView from './component';
import { checkQRCode, cleanQRCode } from '../../store/qrCode/duck';

const QRCode = connect(
  // Map state to props
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userDetails: state.user.userDetails,

    // QR Code
    qrCodeFetchPhase: state.qrCode.fetchPhase,
    qrCodeResMessage: state.qrCode.resMessage,
    qrCodeError: state.qrCode.error,
    qrCode: state.qrCode.qrCode
  }),
  // Map actions to props
  {
    getUser,
    checkQRCode,
    cleanQRCode
  }
)(QRCodeView);

export default injectIntl(QRCode);
