import React from 'react';
import _ from 'lodash';

const AdviserInfoPageCardBackground3 = props => {
  const { style, ...rest } = props;

  return (
    <div
      style={_.merge(
        {
          position: 'absolute',
          width: 'calc(100% - 7px)',
          height: '100%',
          zIndex: 0,
          opacity: 1,
          top: 4,
          left: 4,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          borderRadius: '3px 90px 0 0'
        },
        style
      )}
      {...rest}
    />
  );
};

export default AdviserInfoPageCardBackground3;
