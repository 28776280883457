import React from 'react';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import { EditorState, Modifier } from 'draft-js';
import { Popover, Zoom } from '@material-ui/core';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import './EmojiButton.scss';

class EmojiButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const { theme, onChange, editorState } = this.props;
    const { open } = this.state;

    return (
      <div
        className={theme.buttonWrapper}
        ref={node => {
          if (node) this.parent = node;
        }}
      >
        <button
          type="button"
          className={theme.button}
          onClick={event => {
            event.stopPropagation();
            this.selectionState = editorState.getSelection();
            this.emoji = undefined;
            this.setState({ open: true });
          }}
        >
          <InsertEmoticonIcon />
        </button>
        <Popover
          TransitionComponent={Zoom}
          className="emoji-picker"
          ref={node => {
            if (node) this.popover = node;
          }}
          open={open}
          onClose={() => this.setState({ open: false })}
          anchorEl={this.parent}
          onExited={() => {
            this.props.editor.focus();
            let newEditorState = EditorState.forceSelection(editorState, this.selectionState);
            const contentState = newEditorState.getCurrentContent();
            const selectionState = newEditorState.getSelection();
            if (this.emoji) {
              const newContent = Modifier.replaceText(contentState, selectionState, this.emoji);
              newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
            }
            onChange(newEditorState);
          }}
        >
          <Picker
            native={true}
            onSelect={emoji => {
              this.emoji = emoji.native;
              this.setState({ open: false });
            }}
            showPreview={false}
            showSkinTones={false}
            title=""
            darkMode={false}
          />
        </Popover>
      </div>
    );
  }
}

export default EmojiButton;
