export const DEFAULT_COLORS = [
  '#d31145', // AIA
  '#3b5598', // AXA
  '#f73b6a', // BOC Life
  '#1aa7ff', // Bupa
  '#33ffb8', // China Life
  '#01C1D6', // CHUBB
  '#0059b3', // Friends
  '#f68a3c', // FTLife
  '#e74e4b', // Generali
  '#ffa733', // FWD
  '#00e6a4', // Manulife
  '#0b7ada', // MassMutual -> YFLife
  '#549bde', // MetLife
  '#fc7b36', // PingAn
  '#f68892', // Prudential
  '#fdbe10', // SunLife
  '#c4936e', // Transmerica
  '#0088ff', // Zurich
  '#3999f8', // Other
  '#76c76b', // Cigna
  '#ff4133', // HSBC
  '#fdc468', // Standard Life
  '#cee6fd', // what if
  '#eb9396', // Taheo
  '#d31145', // AIA TW
  '#c27b44', // BankTaiwan Life
  '#2bb066', // Cathy Life
  '#29dbee', // CHUBB TW
  '#37487e', // Nanshan Life
  '#f83824', // Shin Kong Life
  '#329696', // Taiwan Life
  '#309c39', // reserve (Hangseng)
  '#3999F8', // reserve
  '#c019cc' // reserve
];
