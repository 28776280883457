import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import './TotalAmountCard.scss';
import { getPriceAmountDisplay } from '../../../../utils/user-subscription';

export class TotalAmountCard extends React.Component {
  render() {
    const { intl, amount } = this.props;
    return (
      <div className="total-amount-card-wrapper">
        <Card className="total-amount-card">
          <CardHeader className="card-header" title={<div>{intl.formatMessage({ id: 'total-amount' })}</div>} />
          <CardContent className="card-content">
            {amount > 0 ? (
              <Fragment>
                <sup>$</sup>
                <span>{getPriceAmountDisplay(amount)}</span>
              </Fragment>
            ) : (
              <span>{intl.formatMessage({ id: 'free' })}</span>
            )}
          </CardContent>
        </Card>
        <Typography align="right">{intl.formatMessage({ id: 'sub-amount-corrected' })}</Typography>
      </div>
    );
  }
}
