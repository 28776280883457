import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { toast } from 'react-toastify';
import './COVIDHintDialog.scss';
import IconToast from '../../../../../components/NewToast';
import NativeOrWeb from '../../../../../utils/native';
import { sanitize } from 'dompurify';

export const RELATED_SITES = [
  {
    id: 'hk-page',
    url: 'https://www.coronavirus.gov.hk/'
  },
  {
    id: 'hk-chp',
    url: 'https://www.chp.gov.hk/'
  },
  {
    id: 'wto-page',
    url: 'https://www.who.int/emergencies/diseases/novel-coronavirus-2019'
  },
  {
    id: 'hk-dashboard',
    url: 'https://chp-dashboard.geodata.gov.hk/'
  },
  {
    id: 'cn-dashboard',
    url: 'http://2019ncov.chinacdc.cn/2019-nCoV/'
  },
  {
    id: 'wto-dashboard',
    url: 'https://who.sprinklr.com/'
  },
  {
    id: 'news-dashboard',
    url: 'https://portal.who.int/eios-coronavirus-newsmap/'
  }
];

class COVIDHintDialog extends React.PureComponent {
  render() {
    const { intl, open, onClose, closable, className, ...rest } = this.props;

    const relatedSites = RELATED_SITES.map(site => {
      const translation = intl.formatMessage({ id: site.id });
      return {
        ...site,
        translation: translation,
        copyMessage: `${translation} ${site.url}`
      };
    });

    return (
      <Dialog open={open} className={`covid-hint-dialog ${className ? className : ''}`} {...rest}>
        <DialogTitle>{intl.formatMessage({ id: 'covid-hint-title' })}</DialogTitle>
        <DialogContent className="content" style={{ whiteSpace: 'pre-wrap' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(
                intl.formatMessage(
                  { id: 'covid-hint-content' },
                  { image: `<img src='img/messagetoc3.png' alt="message"/>` }
                )
              )
            }}
          />
          {relatedSites.map((site, index) => {
            return (
              <Grid key={index} container alignItems="center">
                <Grid item xs={8}>
                  <a className="virus-instruction link" href={site.url} target="_blank" rel="noopener noreferrer">
                    {site.translation}
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() =>
                      NativeOrWeb.copyToClipboard(site.copyMessage).then(() => {
                        toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
                          className: 'new-toast'
                        });
                      })
                    }
                    color="primary"
                  >
                    {intl.formatMessage({ id: 'Copy' })}
                  </Button>
                </Grid>
              </Grid>
            );
          })}
          <Typography className="disclaimer">{intl.formatMessage({ id: 'covid-hint-disclaimer' })}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              NativeOrWeb.copyToClipboard(relatedSites.map(site => site.copyMessage).join('\n')).then(() => {
                toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });
              })
            }
            color="primary"
          >
            {intl.formatMessage({ id: 'Copy-all' })}
          </Button>
          {closable ? (
            <Button onClick={onClose} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          ) : (
            <CircularProgress />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(COVIDHintDialog);

export default injectIntl(container);
