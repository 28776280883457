import React from 'react';
import Sticky from 'react-sticky-el';

const StickyHeader = props => {
  const { children, ...rest } = props;
  return (
    <Sticky mode="top" topOffset={-100} stickyStyle={{ top: 'calc(45px + var(--top-padding))', zIndex: 3, background: '#fff' }} {...rest}>
      {children}
    </Sticky>
  );
};

export default StickyHeader;
