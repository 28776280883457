import React from 'react';
import NumberFormat from 'react-number-format';

class BusinessVolControl extends React.PureComponent {
  render() {
    const { className, businessVol, onChange, ...rest } = this.props;
    return (
      <NumberFormat
        className={`form-control ${className ? className : ''}`}
        name="businessVol"
        thousandSeparator={true}
        type="tel"
        inputMode="decimal"
        pattern="[0-9]*"
        value={businessVol}
        onValueChange={values => onChange(values.value)}
        {...rest}
      />
    );
  }
}

export default BusinessVolControl;
