import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ShareButtonGroup from '../../../../components/ShareButtonGroup/ShareButtonGroup';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { withRouter } from 'react-router';

const PFPLUS_URL = process.env.PFPLUS_URL;

const DialogContentStyle = {
  whiteSpace: 'pre-wrap'
};

const ButtonStyle = {
  marginBottom: 8
};

const WhiteboardRemoteHintDialog = props => {
  const { intl, history, location, match, userDetails, open, onClose, ...rest } = props;

  const params = { whiteboardid: `${userDetails._id}-${new Date().getTime()}` };
  const adviserParams = { ...params, username: userDetails.name };
  const clientParams = { ...params, username: 'Client' };

  const adviserUrl = `${PFPLUS_URL}/whiteboard-remote?${new URLSearchParams(adviserParams).toString()}`;
  const clientUrl = `${PFPLUS_URL}/whiteboard-remote?${new URLSearchParams(clientParams).toString()}`;

  const goToRemote = () => {
    window.open(adviserUrl, '_blank');
    onClose();
  };

  return (
    <Dialog
      className="whiteboard-remote-hint-dialog"
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      {...rest}
    >
      <DialogTitle>{intl.formatMessage({ id: 'whiteboard-remote-hint-dialog-title' })}</DialogTitle>
      <DialogContent style={DialogContentStyle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography paragraph={true}>
              {intl.formatMessage({ id: 'whiteboard-remote-hint-dialog-content' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ShareButtonGroup
              title={intl
                .formatMessage({ id: 'whiteboard-remote-share-msg' })
                .replace(/<br\s*[\/]?>/gi, '\n')
                .replace(/<\/?[^>]+(>|$)/g, '')}
              url={clientUrl}
              isCTA
              CopyTextFieldProps={{
                multiline: true
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="contained" fullWidth={true} onClick={goToRemote} style={ButtonStyle}>
              {intl.formatMessage({ id: 'whiteboard-remote-hint-dialog-start' })}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {}
)(WhiteboardRemoteHintDialog);

export default injectIntl(withRouter(container));
