import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import './CustomFilterSwitch.scss';
import { injectIntl } from 'react-intl';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

const recruitmentTabInactiveStyle = { backgroundColor: '#3966f888', color: '#ffffff' };
const recruitmentTabActiveStyle = {
  color: '#3966f8',
  borderTop: 'none',
  borderBottom: '4px solid #3966f8'
};

const CustomFilterSwitch = props => {
  const { intl, customFilters, applyFilter, activeTab, allFilter, reminderFilter, recruitmentFilter } = props;

  return (
    <div className="custom-filter-switch">
      <Grid container direction="row" alignItems="stretch" wrap="nowrap" style={{ height: '100%' }}>
        <Grid item xs={4} key={0}>
          <Button
            variant="outlined"
            onClick={allFilter}
            style={
              activeTab[0]
                ? {
                    color: '#3966f8',
                    borderTop: 'none',
                    // borderLeft: '2px solid lightgrey',
                    // borderRight: '2px solid lightgrey',
                    borderBottom: '4px solid #3966f8'
                  }
                : {
                    color: '#00000088'
                  }
            }
          >
            <Grid container direction="column">
              <Grid item className="text">
                {intl.formatMessage({ id: 'All' })}
              </Grid>
            </Grid>
          </Button>
        </Grid>
        {customFilters.map((e, index) => {
          const isSelected = activeTab[index + 1];
          return (
            <Grid item xs={4} key={index + 1} className={index === 2 ? 'last-filter' : ''}>
              <Button
                className={e.clientState}
                variant="outlined"
                onClick={() => applyFilter(index)}
                style={
                  isSelected
                    ? {
                        color: '#3966f8',
                        borderTop: 'none',
                        // borderLeft: '2px solid lightgrey',
                        // borderRight: '2px solid lightgrey',
                        borderBottom: '4px solid #3966f8'
                      }
                    : {
                        color: '#00000088'
                      }
                }
              >
                <Grid container direction="column">
                  <Grid item className="text">
                    {!e.name ? intl.formatMessage({ id: `custom-filter-${index + 1}` }) : e.name}
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          );
        })}
        <Grid item xs={4} key={4}>
          <Button
            variant="outlined"
            onClick={reminderFilter}
            style={
              activeTab[4]
                ? {
                    color: '#3966f8',
                    borderTop: 'none',
                    borderBottom: '3px solid #3966f8'
                  }
                : {
                    color: '#00000088'
                  }
            }
          >
            <Grid container direction="column">
              <Grid item className="text">
                <NotificationsNoneIcon color="primary" />
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={4} key={5}>
          <Button
            variant="outlined"
            onClick={recruitmentFilter}
            style={activeTab[5] ? recruitmentTabActiveStyle : recruitmentTabInactiveStyle}
          >
            <Grid container direction="column">
              <Grid item className="text">
                {intl.formatMessage({ id: 'recruit' })}
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(CustomFilterSwitch);
