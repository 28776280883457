import React from 'react';
import { Typography, DialogContent } from '@material-ui/core';
import '../Preferences.scss';

const styles = {
  answer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10
  },
  container: {
    paddingBottom: 50
  },
  main: {
    flex: 1,
    justifyContent: 'center'
  },
  imageDiv: {
    flex: 2,
    border: 'solid 1px #ddd',
    borderRadius: 20,
    boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.05)',
    marginTop: 5
  },
  actions: { position: 'absolute', bottom: 0, width: '50%', paddingRight: 0, paddingLeft: 0 }
};

const preferences = ['match-known-adviser', 'self-manage-portfolio'];
const gif = ['find-my-adviser-animate.svg', 'self-manage-animate.svg'];

class PreferenceQuestion extends React.PureComponent {
  onChange = e => {
    this.props.setNickName(e.target.value);
  };

  render() {
    const { intl, classes } = this.props;
    return (
      <div key={2}>
        <div>
          <DialogContent>
            {preferences.map((e, index) => (
              <div key={e} style={styles.answer} onClick={() => this.props.setPreference(index)}>
                <div style={styles.imageDiv} className="preference-question-container">
                  <img className="preference-img" src={'animated-svg/' + gif[index]} alt="" />
                  <Typography style={{ margin: '-15px 0 10px 0px' }} classes={{ root: classes.Typography }}>
                    {intl.formatMessage({ id: e })}
                  </Typography>
                </div>
              </div>
            ))}
          </DialogContent>
        </div>
      </div>
    );
  }
}

export default PreferenceQuestion;
