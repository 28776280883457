import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, Typography } from '@material-ui/core';
import FormSubmitWrapper from '../../FormSubmitWrapper/FormSubmitWrapper';

const EditFilenameDialog = props => {
  const { intl, open, file, onClose, onSubmit } = props;

  const [filename, setFilename] = useState(file?.filename || '');
  const [error, setError] = useState('');

  const onChangeFilename = event => {
    setFilename(event.target.value);
  };

  const _onSubmit = () => {
    if (filename === '') {
      setError(intl.formatMessage({ id: 'edit-filename-dialog-error-input-required' }));
      return;
    }
    onSubmit(filename);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>{intl.formatMessage({ id: 'edit-filename-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid item>
          <Typography>{intl.formatMessage({ id: 'edit-filename-dialog-content' })}</Typography>
        </Grid>
        <Grid item>
          <FormSubmitWrapper onSubmit={_onSubmit}>
            <Input autoFocus={true} value={filename} onChange={onChangeFilename} fullWidth style={{ height: 30 }} />
          </FormSubmitWrapper>
        </Grid>
        {error && (
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={_onSubmit}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(EditFilenameDialog);
