import { connect } from 'react-redux';
import ConfCallView from './component';

import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';

const ConfCall = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {
    renderHeaderButton
  }
)(ConfCallView);

export default injectIntl(ConfCall);
