import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, useMediaQuery } from '@material-ui/core';
import InputPanel from './InputPanel/InputPanel';
import SideLoanInterestInput from './SideLoanInterestInput/SideLoanInterestInput';
import GraphCard from './GraphCard/GraphCard';
import SideInput from './SideInput/SideInput';
import { getProfitByYear } from './calculator';
import { useTheme } from '@material-ui/styles';
import PFinTable from './PFinTable/PFinTable';
import CalculatorProfiles from './CalculatorProfiles/CalculatorProfiles';
import { getWebImageUrl } from '../../../../utils/image';
import AdviserInfo from '../../../Export/AdviserInfo/AdviserInfo';
import _ from 'lodash';
import PoweredBy from '../../../PolicyReview/PoweredBy/PoweredBy';
import { getPremiumFinancingProfileDisplay } from '../../../../utils/premium-financing';
import { getUserLogo } from '../../../../utils/user';
import { ADVISER_LOGO_FUNCTIONS } from '../../../../constants/user';
import CurrencyInputPanel from './CurrencyInputPanel/CurrencyInputPanel';

const PremiumFinancing = (props) => {
  const {
    intl,
    adviser,
    pFinProfiles,
    principal: originalPrincipal,
    base: originalBase,
    loanPercentage,
    bankLoanInterest,
    policyInterest,
    term,
    yearsToShow,
    cashValueAt: originalCashValueAt,
    firstYearBonus: originalFirstYearBonus,
    termLoanPercentage,
    termAddOn: originalTermAddOn,
    overrideLoanInterest,
    graphMode,
    rowOpen,
    fixedLoanInterestExpanded,
    dynamicLoanInterestExpanded,
    isCurrencyEnabled = false,
    currency = 7.8,
    displayName,
    extraSideInputs,
    template,
    disableLoan,
    handleChange,
    handleCheckedChange,
    handleProfileChange,
    profileIndex,
    saveProfiles,
    onChangeGraphMode,
    onToggleOpen,
    onToggleFixedLoanInterestExpanded,
    onToggleDynamicLoanInterestExpanded,
    onToggleDisableLoan,
    overrideLoanInterestSliderValue,
    openLoanCalculatorDialog,
    openBonusCalculatorDialog,
    openEditDisplayNamesDialog,
    isPrivateMode,
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerScreen = useMediaQuery('(max-width: 499px)');
  const spacing = isSmallScreen ? 2 : 2;

  const adviserTeamLogoUrl = _.get(adviser, 'teamLogoUrl');
  const userLogo = getUserLogo(adviser, adviserTeamLogoUrl, ADVISER_LOGO_FUNCTIONS.PDF);
  const showTeamLogo = !!userLogo;

  // adjust inputs according to currency settings
  const factor = isCurrencyEnabled ? currency : 1;
  const principal = originalPrincipal * factor;
  const base = originalBase * factor;
  const cashValueAt = (originalCashValueAt || []).map((e) => e * factor);
  const firstYearBonus = originalFirstYearBonus * factor;
  const termAddOn = (originalTermAddOn || []).map((e) => e * factor);

  const yearlyData = getProfitByYear({
    principal,
    base,
    bankLoanInterest,
    yearsToShow,
    cashValueAt,
    termLoanPercentage,
    termAddOn,
    firstYearBonus,
    overrideLoanInterest,
    disableLoan,
  });
  const years = yearsToShow.filter((e, i) => i === 0 || (e && cashValueAt[i]));
  const loan = (principal ?? 0) - (base ?? 0) - (firstYearBonus ?? 0);

  const inputPanel = (
    <InputPanel
      intl={intl}
      principal={principal}
      base={base}
      loanPercentage={loanPercentage}
      firstYearBonus={firstYearBonus}
      handleChange={handleChange}
      openLoanCalculatorDialog={openLoanCalculatorDialog}
      openBonusCalculatorDialog={openBonusCalculatorDialog}
      template={template}
      disableLoan={disableLoan}
      onToggleDisableLoan={onToggleDisableLoan}
      isCurrencyEnabled={isCurrencyEnabled}
      currency={currency}
    />
  );

  const graphCard = (
    <GraphCard
      intl={intl}
      yearsToShow={yearsToShow}
      principal={principal}
      base={base}
      data={yearlyData}
      firstYearBonus={firstYearBonus}
      isSmallScreen={isSmallScreen}
      isSmallerScreen={isSmallerScreen}
      graphMode={graphMode}
      onChangeGraphMode={onChangeGraphMode}
      template={template}
      openEditDisplayNamesDialog={openEditDisplayNamesDialog}
      displayName={getPremiumFinancingProfileDisplay(intl, { displayName: displayName }, profileIndex)}
    />
  );

  const pFinTable = (
    <PFinTable
      principal={principal}
      base={base}
      bankLoanInterest={bankLoanInterest}
      firstYearBonus={firstYearBonus}
      yearlyData={yearlyData}
      years={years}
      overrideLoanInterest={overrideLoanInterest}
      intl={intl}
      rowOpen={rowOpen}
      onToggleOpen={onToggleOpen}
      profileIndex={profileIndex}
    />
  );

  const currencyInputPanel = (
    <CurrencyInputPanel
      enabled={isCurrencyEnabled}
      currency={currency}
      onToggle={handleCheckedChange}
      onChange={(currency) => handleChange({ target: { name: 'currency', value: currency } })}
      template={template}
    />
  );

  const sideLoanInterestInput = (
    <SideLoanInterestInput
      intl={intl}
      bankLoanInterest={bankLoanInterest}
      term={term}
      overrideLoanInterest={overrideLoanInterest}
      termLoanPercentage={termLoanPercentage}
      termAddOn={termAddOn}
      yearsToShow={yearsToShow}
      handleChange={handleChange}
      handleCheckedChange={handleCheckedChange}
      loan={loan}
      fixedLoanInterestExpanded={fixedLoanInterestExpanded}
      onToggleFixedLoanInterestExpanded={onToggleFixedLoanInterestExpanded}
      dynamicLoanInterestExpanded={dynamicLoanInterestExpanded}
      onToggleDynamicLoanInterestExpanded={onToggleDynamicLoanInterestExpanded}
      overrideLoanInterestSliderValue={overrideLoanInterestSliderValue}
      template={template}
      isCurrencyEnabled={isCurrencyEnabled}
    />
  );

  const sideInput = (
    <SideInput
      intl={intl}
      principal={principal}
      term={term}
      yearsToShow={yearsToShow}
      cashValueAt={cashValueAt}
      handleChange={handleChange}
      isCurrencyEnabled={isCurrencyEnabled}
      currency={currency}
    />
  );

  const calculatorProfiles = (
    <CalculatorProfiles
      intl={intl}
      pFinProfiles={pFinProfiles}
      index={profileIndex}
      onIndexChange={handleProfileChange}
      saveProfiles={saveProfiles}
      isSmallScreen={isSmallScreen}
      extraSideInputs={extraSideInputs}
      openEditDisplayNamesDialog={openEditDisplayNamesDialog}
    />
  );

  if (!isSmallScreen) {
    return (
      <Fragment>
        {template && !isPrivateMode && (
          <Grid
            className="header"
            container
            justify="flex-end"
            alignItems="center"
            style={
              template
                ? {
                    backgroundImage: `url("${getWebImageUrl('img/policy-review/header_v2-right-3.png')}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'auto 145px',
                    backgroundPosition: 'bottom 0 right 0',
                    paddingBottom: 24,
                    marginBottom: 16,
                    position: 'relative',
                    minHeight: 145,
                  }
                : undefined
            }
          >
            <div
              style={
                template
                  ? {
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                      top: 0,
                      backgroundImage: `url("${getWebImageUrl('img/policy-review/header_v2-left.png')}")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto 145px',
                      backgroundPosition: `bottom 0 left 0`,
                    }
                  : undefined
              }
            />

            {template && (
              <Grid item style={{ flexGrow: 1 }}>
                <div
                  style={{
                    width: 'calc(100% - 510px)',
                    minWidth: showTeamLogo ? 260 : 195,
                    float: 'right',
                    textAlign: 'center',
                  }}
                >
                  {showTeamLogo ? (
                    <img
                      src={userLogo}
                      alt="logo"
                      style={{ maxHeight: 150, maxWidth: 260, width: 'auto', objectFit: 'contain' }}
                    />
                  ) : (
                    <img src={getWebImageUrl('img/policy-review/PFPLogoSummary.png')} alt="portfoplus" width={195} />
                  )}
                </div>
              </Grid>
            )}

            <Grid item>
              <AdviserInfo
                show={true}
                showQRCode={true}
                adviser={adviser}
                direction="row"
                QRActionButton="create-one-for-free"
                qrCodeWidth={150}
                referLinkParams={{
                  source: 'pdf',
                  extraSearch: {
                    defaultMessage: intl.formatMessage({ id: 'pfin-default-msg' }),
                  },
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={spacing}>
          <Grid item xs={9}>
            <Grid container spacing={spacing}>
              <Grid item xs={12}>
                {inputPanel}
              </Grid>
              <Grid item xs={12}>
                {graphCard}
              </Grid>
              <Grid item xs={12}>
                {pFinTable}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={spacing}>
              {(!template || isCurrencyEnabled) && (
                <Grid item xs={12}>
                  {currencyInputPanel}
                </Grid>
              )}
              <Grid item xs={12}>
                {sideLoanInterestInput}
              </Grid>
              <Grid item xs={12}>
                {sideInput}
              </Grid>
              {!template && (
                <Grid item xs={12}>
                  {calculatorProfiles}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {template && (
          <Grid container justify="space-between" spacing={spacing}>
            <Grid item>
              <div className="disclaimer">{intl.formatMessage({ id: 'pfa-disclaimer' })}</div>
            </Grid>
            <Grid item>
              <PoweredBy />
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          {inputPanel}
        </Grid>
        {(!template || isCurrencyEnabled) && (
          <Grid item xs={12}>
            {currencyInputPanel}
          </Grid>
        )}
        <Grid item xs={12}>
          {sideLoanInterestInput}
        </Grid>
        <Grid item xs={12}>
          {sideInput}
        </Grid>
        <Grid item xs={12}>
          {graphCard}
        </Grid>
        <Grid item xs={12}>
          {pFinTable}
        </Grid>
        <Grid item xs={12}>
          {calculatorProfiles}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default injectIntl(PremiumFinancing);
