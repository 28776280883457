import React, { useState, useCallback, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SlideTransition } from '../../../components/Transition/Transition';
import { updateUserDetails } from '../../../store/user/duck';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import _ from 'lodash';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

const AddCampaignCode = ({ open, onClose, intl }) => {
  const state = useSelector(s => s.user);
  const [text, setText] = useState('');
  const [localSubmit, setLocalSubmit] = useState(false);
  const dispatch = useDispatch();

  const save = useCallback(() => {
    setLocalSubmit(true);
    let campaignCodes = state.userDetails.campaignCodes ? state.userDetails.campaignCodes : [];
    dispatch(updateUserDetails({ campaignCodes: [...campaignCodes, { text }] }));
  }, [text]);

  const close = useCallback(() => {
    setText('');
    onClose();
  }, []);

  useEffect(() => {
    if (state.isSubmitting === false && state.error && localSubmit) {
      setLocalSubmit(false);
      toast.info(IconToast('error', intl.formatMessage({ id: 'todo-err-msg' })), {
        className: 'new-toast'
      });
    } else if (state.isSubmitting === false && state.error === null && localSubmit) {
      setLocalSubmit(false);
      close();
      toast.info(IconToast('info', intl.formatMessage({ id: 'Updated successfully' })), {
        className: 'new-toast'
      });
    }
  }, [state, open, localSubmit]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      TransitionComponent={SlideTransition}
    >
      {state.isSubmitting && localSubmit && <LoadingBackdrop open={true} />}
      <DialogTitle>{intl.formatMessage({ id: 'campaign' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'enter-campaign-code' })}</DialogContentText>
        <div style={{ textAlign: 'center' }}>
          <Input
            onChange={e => setText(e.target.value ? e.target.value.toUpperCase() : e.target.value)}
            value={text}
            style={{ fontSize: 15, width: 138 }}
            inputProps={{ style: { textAlign: 'center', height: '1.5em' } }}
            type="text"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" autoFocus onClick={close} disabled={localSubmit}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={save} color="primary" autoFocus disabled={localSubmit || !text}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCampaignCode;
