import React from 'react';

const HotLabelStyle = {
  background: '#ff0000',
  color: '#fff',
  padding: '1px 5px',
  position: 'absolute',
  top: 15,
  left: 0,
  fontSize: 14,
  borderRadius: '0 4px 4px 0'
};

export const HotLabel = props => (
  <div className="hot" style={{ ...HotLabelStyle, ...props.style }}>
    {props.text}
  </div>
);
