import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Container, useMediaQuery } from '@material-ui/core';
import AdviserInfoPageBackgroundRight from './AdviserInfoPageBackground/AdviserInfoPageBackgroundRight/AdviserInfoPageBackgroundRight';
import AdviserInfoPageBackground from './AdviserInfoPageBackground/AdviserInfoPageBackground';
import MyResizeObserver from '../../components/MyResizeObserver/MyResizeObserver';
import { connect } from 'react-redux';
import { THEME_COLOR_BACKGROUNDS, THEME_COLOR_PALETTES } from '../../constants/my-details';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router';
import { CustomTopNavigationWithPortal } from '../../components/CustomTopNavigation/CustomTopNavigation';

const ContainerStyle = {
  position: 'relative',
  overflowX: 'hidden'
};

const BackgroundStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 0,
  opacity: 0.9,
  height: '100%'
};

const WideContainerStyle = {
  ...ContainerStyle,
  padding: 32
};

const NarrowContainerStyle = {
  ...ContainerStyle
};

const NarrowInnerContainerStyle = {
  paddingLeft: 0,
  paddingRight: 0
};

const AdviserInfoContainer = props => {
  const { history, isAuthenticated, themeColor, wideContent, narrowContent, withNav, style } = props;

  const useWideLayout = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [backgroundHeight, setBackgroundHeight] = useState(undefined);

  const onLayout = useCallback(() => {
    // if (useWideLayout) {
    const headerElem = document.querySelector('.adviser-info-page .header');
    const contentElem = document.querySelector('.adviser-info-page .content');
    const contentParentElem = contentElem && contentElem.parentElement;
    if (headerElem || contentParentElem) {
      const height =
        (headerElem ? headerElem.offsetHeight : 0) + (contentParentElem ? contentParentElem.offsetHeight : 0) + 32;
      // console.log(height);
      setBackgroundHeight(height);
    }
    // }
  }, []);

  const back = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    onLayout();
  }, [onLayout, withNav]);

  const minHeight = `min(100vh, 700px)`;

  return (
    <Fragment>
      <MyResizeObserver targetsSelector="body" onResize={onLayout} />

      {withNav && (
        <CustomTopNavigationWithPortal style={{ backgroundColor: 'unset', boxShadow: 'unset' }}>
          <span className="back_arrow" onClick={back}>
            <ArrowBackIcon style={{ color: THEME_COLOR_PALETTES[themeColor || 'blue'][5] }} />
          </span>
        </CustomTopNavigationWithPortal>
      )}

      {useWideLayout ? (
        <div
          className="adviser-info-page wide"
          style={{
            minHeight,
            ...WideContainerStyle,
            backgroundImage: THEME_COLOR_BACKGROUNDS[themeColor || 'blue'],
            ...style
          }}
        >
          {/*<AdviserInfoPageBackground*/}
          {/*  themeColor={themeColor}*/}
          {/*  style={{ ...BackgroundStyle, minHeight: backgroundHeight }}*/}
          {/*/>*/}
          {wideContent && wideContent}
        </div>
      ) : (
        <div
          className="adviser-info-page"
          style={{
            minHeight,
            padding: `0 0 ${isAuthenticated ? 0 : 0} 0`,
            ...NarrowContainerStyle,
            backgroundImage: THEME_COLOR_BACKGROUNDS[themeColor || 'blue'],
            ...style
          }}
        >
          <Container maxWidth="xs" style={NarrowInnerContainerStyle}>
            {/*<AdviserInfoPageBackground*/}
            {/*  themeColor={themeColor}*/}
            {/*  style={{ ...BackgroundStyle, minHeight: backgroundHeight }}*/}
            {/*/>*/}
            {narrowContent && narrowContent}
          </Container>
        </div>
      )}
    </Fragment>
  );
};

const container = connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated
  }),
  {}
)(AdviserInfoContainer);

export default withRouter(container);
