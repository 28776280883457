import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControl, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import TempSelect from '../../../../../components/TempSelect/TempSelect';
import { CURRENCIES } from '../../../../../constants/currency';
import MenuItem from '@material-ui/core/MenuItem';
import { usePrevious } from '../../../../../utils/hooks';

const SelectCurrencyDialog = props => {
  const { intl, open = false, currency = 'HKD', exclude, onSubmit = currency => {}, onClose = () => {} } = props;

  const prevCurrency = usePrevious(currency);
  const [_currency, setCurrency] = useState(currency);
  const [currencies, setCurrencies] = useState(CURRENCIES);

  const onChange = useCallback(event => {
    setCurrency(event.target.value);
  }, []);

  const _onClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const _onSubmit = useCallback(() => {
    onSubmit(_currency);
    onClose();
  }, [onSubmit, _currency, onClose]);

  useEffect(() => {
    if (currency !== prevCurrency) {
      if (_currency !== currency) {
        setCurrency(currency);
      }
    }
  }, [_currency, currency, prevCurrency]);

  useEffect(() => {
    const excludeMap = (exclude || []).reduce((acc, currencyValue) => {
      if (currency !== currencyValue) {
        acc.set(currencyValue, true);
      }
      return acc;
    }, new Map());
    setCurrencies(CURRENCIES.filter(currency => !excludeMap.has(currency.value)));
  }, [exclude, currency]);

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'select-currency-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <FormControl>
              <TempSelect value={_currency} onChange={onChange}>
                {currencies.map(currency => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {intl.formatMessage({ id: currency.value })}
                  </MenuItem>
                ))}
              </TempSelect>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={_onClose}>
          {intl.formatMessage({ id: 'Close' })}
        </Button>
        <Button color="primary" onClick={_onSubmit}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(SelectCurrencyDialog);
