import store from '../store';
import { history } from '../views/App/history';
import { toast } from 'react-toastify';
import IconToast from '../components/NewToast';
import { getGlobalIntl } from '../reduxConnectedIntlProvider';
import { setClientIdLocalStorage } from '../components/ClientEntry/utils';
import { INITIAL_GPT_QUOTA } from '../constants/gpt';
import { toggleDialog } from '../store/control/duck';
import { updateViewingClient } from '../store/client/duck';

export const getGPTQuota = () => {
  try {
    const gptQuotaString = localStorage.getItem('gptQuota');
    const gptQuota = parseInt(gptQuotaString);
    if (isNaN(gptQuota)) {
      return INITIAL_GPT_QUOTA;
    } else if (process.env.NODE_ENV === 'development') {
      return 1;
    }
    return gptQuota;
  } catch (error) {
    return INITIAL_GPT_QUOTA;
  }
};
export const validateGPTQuota = () => {
  if (getGPTQuota() <= 0) {
    throw new Error('Quota exceeded');
  }
};
export const resetGPTQuota = () => localStorage.setItem('gptQuota', INITIAL_GPT_QUOTA.toString());
export const decreaseGPTQuota = () => {
  const quota = getGPTQuota();
  localStorage.setItem('gptQuota', (quota ? quota - 1 : 0).toString());
};

export const shouldShowGPTDisclaimer = () => !localStorage.getItem('showedGPTDisclaimer');
export const setShouldShowGPTDisclaimer = () => localStorage.setItem('showedGPTDisclaimer', 'true');
export const resetGPTDisclaimer = () => localStorage.removeItem('showedGPTDisclaimer');

export const goToGPTOptions = client => {
  if (shouldShowGPTDisclaimer()) {
    store.dispatch(toggleDialog('chatGPTDisclaimer', {}));
  } else {
    setClientIdLocalStorage(client?._id);
    store.dispatch(updateViewingClient(client));
    history.push('/gpt-options');
  }
};

export const handleError = error => {
  console.log(error);
  const intl = getGlobalIntl();
  if (error.message.includes('Token count exceeds')) {
    toast.info(IconToast('error', error.message), { className: 'new-toast' });
  } else if (error.message === 'Quota exceeded') {
    toast.info(IconToast('error', intl.formatMessage({ id: 'chat-gpt-quota-exceeded' })), { className: 'new-toast' });
  } else {
    toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
  }
};