import { fetch } from '../../utils';
import qs from 'qs';

export const getSubscriptionPlansApi = queryObj => {
  if (process.env.NODE_ENV === 'development' && process.env.WITHOUT_SUBSCRIPTION === 'true') {
    return {
      data: [
        {
          _id: '5e3269934878ed4364c09445',
          intervalCount: 1,
          order: 0,
          color: '#3966f8',
          type: 'core',
          nickname: 'Pro',
          amount: 338800,
          currency: 'hkd',
          interval: 'year',
          stripeProductId: 'prod_G5itirI78WYmSg',
          stripePlanId: 'price_1Oo1N1HNO6xAq419iMnpvisv',
          active: true,
          displayName: {
            en: 'Pro',
            zh: 'Pro',
            zhHantHK: 'Pro'
          },
          description: {
            en: 'Policy Consolidation\nCoverage Analysis\nClient Management\nBusiness Tracking\nAnalysis Export',
            zhHantHK: '政策整合\n覆蓋率分析\n客戶管理\n業務跟踪\n分析導出',
            zh: '政策整合\n覆盖率分析\n客户管理\n业务跟踪\n分析导出'
          },
          updatedAt: '2020-02-05T04:39:01.557Z',
          createdAt: '2020-01-30T05:28:51.342Z',
          __v: 0,
          hotPick: true
        }
      ],
      total: 1
    };
  } else {
    return fetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/subscription-plans?${qs.stringify(queryObj)}`, {
      method: 'GET'
    })
      .then(res => {
        return res.json();
      })
      .then(payload => {
        return payload;
      })
      .catch(error => {
        throw error;
      });
  }
};
