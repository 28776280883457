import React from 'react';
import { Table, TableFooter, TableRow, TablePagination, IconButton, NativeSelect } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import './MyTablePagination.scss';

class TablePaginationActions extends React.Component {
  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleSelectPage = event => {
    this.props.onChangePage(event, parseInt(event.target.value));
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  render() {
    const { count, page, rowsPerPage } = this.props;

    let maxPage = Math.ceil(count / rowsPerPage);

    return (
      <div className="pagination-actions">
        <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
          <KeyboardArrowLeft />
        </IconButton>
        <NativeSelect className="my-pagination-select" value={page} onChange={this.handleSelectPage}>
          {[...Array(maxPage)].map((x, index) => (
            <option value={index} key={index}>
              {index + 1}
            </option>
          ))}
        </NativeSelect>
        <IconButton onClick={this.handleNextButtonClick} disabled={page >= maxPage - 1} aria-label="Next Page">
          <KeyboardArrowRight />
        </IconButton>
      </div>
    );
  }
}

class MyTablePagination extends React.PureComponent {
  render() {
    return (
      <Table className="my-table-pagination">
        <TableFooter>
          <TableRow>
            <TablePagination {...this.props} ActionsComponent={TablePaginationActions} />
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
}

export default MyTablePagination;
