// const MIXCARE_HOST = 'https://pfp.mixcarehealth.com';
const MIXCARE_HOST = 'https://pfp.mixcarehealth.com';
const TERMS_LINK = 'https://mixcarehealth.com/terms-and-conditions/';
const MIXCARE_DOCTOR_LIST_URL = 'https://pfp.mixcarehealth.com/doctors';

export const getMixCareTermsLink = () => TERMS_LINK;

export const getMixCareDoctorListUrl = () => MIXCARE_DOCTOR_LIST_URL;

export const getMixCareRegistrationLink = ({ coupon }) => {
  const params = {
    action: 'register',
    distributor: 'pfp'
  };

  if (coupon) {
    params['coupon'] = coupon;
  }

  const searchStr = new URLSearchParams(params).toString();

  return `${MIXCARE_HOST}/landing?${searchStr}`;
};

export const getMixCareBulkPurchaseLink = ({ name, email, quota }) => {
  const params = {
    action: 'bulk-purchase',
    distributor: 'pfp'
  };

  if (name) {
    params['agent_name'] = name;
  }

  if (email) {
    params['agent_email'] = email;
  }

  if (quota) {
    params['quota'] = quota;
  }

  const searchStr = new URLSearchParams(params).toString();

  return `${MIXCARE_HOST}/landing?${searchStr}`;
};

export const getMixCareRenewLink = ({ cardNo }) => {
  const params = {
    action: 'renew',
    distributor: 'pfp',
    card_number: cardNo
  };

  const searchStr = new URLSearchParams(params).toString();

  return `${MIXCARE_HOST}/landing?${searchStr}`;
};
