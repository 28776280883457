import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { toggleDialog } from '../../../../../store/control/duck';
import { sanitize } from 'dompurify';

const CATEGORY_HINT_TITLE_INTL = {
  'to-do': 'ast-cat-hint-title-to-do',
  'tracking-stage-1': 'ast-cat-hint-title-tracking-stage-1',
  'tracking-stage-2': 'ast-cat-hint-title-tracking-stage-2',
  'tracking-stage-3': 'ast-cat-hint-title-tracking-stage-3',
  'tracking-stage-4': 'ast-cat-hint-title-tracking-stage-4'
};
const CATEGORY_HINT_DESCRIPTION_INTL = {
  'to-do': 'ast-cat-hint-desc-to-do',
  'tracking-stage-1': 'ast-cat-hint-desc-tracking-stage-1',
  'tracking-stage-2': 'ast-cat-hint-desc-tracking-stage-2',
  'tracking-stage-3': 'ast-cat-hint-desc-tracking-stage-3',
  'tracking-stage-4': 'ast-cat-hint-desc-tracking-stage-4'
};

class AssistantHintDialog extends React.PureComponent {
  close = () => this.props.toggleDialog('assistantHint', false);

  render() {
    const { intl, assistantHintDialog } = this.props;

    if (!assistantHintDialog) return null;

    const { categoryName } = assistantHintDialog;

    return (
      <Dialog open={!!assistantHintDialog} onClose={this.close} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: CATEGORY_HINT_TITLE_INTL[categoryName] })}</DialogTitle>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.formatMessage({ id: CATEGORY_HINT_DESCRIPTION_INTL[categoryName] }))
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    assistantHintDialog: state.control.assistantHintDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(AssistantHintDialog);

export default injectIntl(container);
