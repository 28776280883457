import * as api from './api';
import { REQUIRE_EXPLICIT_LOGIN } from '../user/duck';
import { saveProFlagToLocalStorage, UserSubscription } from '../../utils/user-subscription';
import { toast } from 'react-toastify';
import moment from 'moment';
import Rx from 'rxjs';
import { combineEpics } from 'redux-observable';
import { formatAPIResponseMessage } from '../../utils/api-response-message';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { CREATENOTIFICATION } from '../notificationCenter/duck';
import IconToast from '../../components/NewToast';
import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import { ga4TrackPayment, tracking } from '../../utils/tracking';
import { produce } from 'immer';
import * as teamApi from '../team/api';

/***********************************
 * Action Types
 ***********/
export const UPDATE_SUBSCRIPTIONS = 'portfoplus/userSubscription/UPDATE_SUBSCRIPTIONS';
export const UPDATE_USER_SUBSCRIPTION = 'portfoplus/userSubscription/UPDATE_USER_SUBSCRIPTION';
export const UPDATE_PRO_REQUIRED_DIALOG = 'portfoplus/userSubscription/UPDATE_PRO_REQUIRED_DIALOG';
export const UPDATE_ADVISER_SUBSCRIPTION_NICKNAME = 'portfoplus/userSubscription/UPDATE_ADVISER_SUBSCRIPTION_NICKNAME';
export const UPDATE_EXPIRY_DIALOG = 'portfoplus/userSubscription/UPDATE_EXPIRY_DIALOG';
export const SUBSCRIPTION_INITIAL_FETCHED = 'portfoplus/userSubscription/INITIAL_FETCHED';
export const RESET_USER_SUBSCRIPTION = 'portfoplus/userSubscription/RESET_USER_SUBSCRIPTION';
export const UPDATE_CREATE_ERROR = 'portfoplus/userSubscription/UPDATE_CREATE_ERROR';
export const UPDATE_KEEP_SHOWING_NEW_SIGN_UP = 'portfoplus/userSubscription/UPDATE_KEEP_SHOWING_NEW_SIGN_UP';
export const UPDATE_EXCLUSIVE_COUPONS = 'portfoplus/userSubscription/UPDATE_EXCLUSIVE_COUPONS';
export const UPDATE_EXCLUSIVE_COUPONS_PHASE = 'portfoplus/userSubscription/UPDATE_EXCLUSIVE_COUPONS_PHASE';

export const GET_TEAM_INVITATION_EXTENSION = 'portfoplus/userSubscription/GET_TEAM_INVITATION_EXTENSION';
export const GET_TEAM_INVITATION_EXTENSION_SUCCESS =
  'portfoplus/userSubscription/GET_TEAM_INVITATION_EXTENSION_SUCCESS';
export const GET_TEAM_INVITATION_EXTENSION_ERROR = 'portfoplus/userSubscription/GET_TEAM_INVITATION_EXTENSION_ERROR';

/***********************************
 * Initial State
 ***********/
const initialState = {
  // shorter version used in checking permissions
  subscriptions: [],

  // full details used in setting
  userSubscription: undefined,

  // toggle of pro-required modal
  proDialog: false,

  // connected adviser subscription nickname
  adviserSubscriptionNickname: undefined,

  // control open of expiry dialog
  expiryDialogOpen: false,

  // initial fetch flag
  initialFetched: false,

  // createSubscription error
  createError: undefined,

  // flag to avoid NewSignUp is replaced
  keepShowingNewSignUp: false,

  exclusiveCoupons: [],

  exclusiveCouponsPhase: INIT,

  teamInvitationExtension: undefined,
  getTeamInvitationExtensionPhase: INIT,
  getTeamInvitationExtensionError: undefined
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_SUBSCRIPTIONS: {
        saveProFlagToLocalStorage(action.payload);

        draft.subscriptions = action.payload;
        if (action.payload?.length > 0 && state.keepShowingNewSignUp !== 'WILL_RESET') {
          draft.keepShowingNewSignUp = false;
        }
        break;
      }

      case UPDATE_USER_SUBSCRIPTION: {
        const shortSubscriptions = action.payload ? new UserSubscription(action.payload).getSubscriptionsArray() : [];
        saveProFlagToLocalStorage(shortSubscriptions);

        draft.userSubscription = action.payload;
        draft.subscriptions = shortSubscriptions;

        if (shortSubscriptions?.length > 0 && draft.keepShowingNewSignUp !== 'WILL_RESET') {
          draft.keepShowingNewSignUp = false;
        }
        break;
      }

      case UPDATE_PRO_REQUIRED_DIALOG: {
        draft.proDialog = action.payload;
        break;
      }

      case UPDATE_ADVISER_SUBSCRIPTION_NICKNAME: {
        draft.adviserSubscriptionNickname = action.payload;
        break;
      }

      case UPDATE_EXPIRY_DIALOG: {
        draft.expiryDialogOpen = action.payload;
        break;
      }

      case SUBSCRIPTION_INITIAL_FETCHED: {
        draft.initialFetched = true;
        break;
      }

      case RESET_USER_SUBSCRIPTION: {
        draft = { ...initialState };
        break;
      }

      case UPDATE_CREATE_ERROR: {
        draft.createError = action.payload;
        ga4TrackPayment({
          channel: 'stripe',
          status: 'rejected',
          err: 'invalid_payment',
          errMessage: action.payload
        });

        break;
      }

      case UPDATE_KEEP_SHOWING_NEW_SIGN_UP: {
        draft.keepShowingNewSignUp = action.payload;
        break;
      }

      case UPDATE_EXCLUSIVE_COUPONS: {
        const { coupons, phase } = action.payload;
        draft.exclusiveCoupons = coupons;
        draft.exclusiveCouponsPhase = phase;
        break;
      }

      case UPDATE_EXCLUSIVE_COUPONS_PHASE: {
        draft.exclusiveCouponsPhase = action.payload;
        break;
      }

      case GET_TEAM_INVITATION_EXTENSION: {
        draft.getTeamInvitationExtensionPhase = LOADING;
        draft.getTeamInvitationExtensionError = undefined;
        break;
      }

      case GET_TEAM_INVITATION_EXTENSION_SUCCESS: {
        draft.getTeamInvitationExtensionPhase = SUCCESS;
        draft.teamInvitationExtension = action.payload.teamInvitationExtension;
        break;
      }

      case GET_TEAM_INVITATION_EXTENSION_ERROR: {
        draft.teamInvitationExtension = null;
        draft.getTeamInvitationExtensionPhase = ERROR;
        draft.getTeamInvitationExtensionError = action.payload.error;
        break;
      }

      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const getUserSubscription = () => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.getUserSubscriptionApi();
  } catch (e) {
    userSubscription = undefined;
  }

  dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });

  return userSubscription;
};

export const createSubscription = (createDTO, noUpdate) => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.createSubscriptionApi(createDTO);
    if (!userSubscription.error) {
      if (!noUpdate) {
        dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });
      } else {
        dispatch({ type: REQUIRE_EXPLICIT_LOGIN });
      }

      // if (!noReAuth) {
      //   dispatch({ type: AUTHENTICATE_USER });
      // }
    } else {
      dispatch({
        type: UPDATE_CREATE_ERROR,
        payload: formatAPIResponseMessage(getGlobalIntl(), userSubscription.message || userSubscription.error.message)
      });
      userSubscription = undefined;
    }
  } catch (e) {
    userSubscription = undefined;
    toast.info(IconToast('error', e.toString()), { className: 'new-toast' });
  }

  return userSubscription;
};

export const paySubscription = (subscriptionId, stripeTokenId) => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.paySubscription(subscriptionId, stripeTokenId);
    if (!userSubscription.error) {
      dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });
    } else {
      throw userSubscription.error;
    }
  } catch (e) {
    userSubscription = undefined;
    toast.info(IconToast('error', e.toString()), { className: 'new-toast' });
  }

  return userSubscription;
};

export const cancelSubscription = subscriptionId => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.cancelSubscriptionApi(subscriptionId);
    if (!userSubscription.error) {
      tracking('Cancelled Subscription');
      dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });
    } else {
      throw userSubscription.error;
    }
  } catch (e) {
    userSubscription = undefined;
    toast.info(IconToast('error', e.toString()), { className: 'new-toast' });
  }

  return userSubscription;
};

export const removeUnpaidSubscription = subscriptionId => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.removeUnpaidSubscriptionApi(subscriptionId);
    if (!userSubscription.error) {
      dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });
    } else {
      throw userSubscription.error;
    }
  } catch (e) {
    userSubscription = undefined;
    toast.info(IconToast('error', e.toString()), { className: 'new-toast' });
  }

  return userSubscription;
};

export const endSubscriptionNow = subscriptionId => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.endSubscriptionNowApi(subscriptionId);
    if (!userSubscription.error) {
      tracking('Ended Subscription Now');
      dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });
    } else {
      throw userSubscription.error;
    }
  } catch (e) {
    userSubscription = undefined;
    toast.info(IconToast('error', e.toString()), { className: 'new-toast' });
  }

  return userSubscription;
};

export const toggleProDialog = details => ({
  type: UPDATE_PRO_REQUIRED_DIALOG,
  payload: details
});

export const getAdviserSubscriptionNickname = advisorId => async (dispatch, getState) => {
  let result = await api.getAdviserSubscriptionNickname(advisorId);
  if (!result.error) {
    dispatch({ type: UPDATE_ADVISER_SUBSCRIPTION_NICKNAME, payload: result.subscriptionNickname });
  } else {
    throw result.error;
  }
  return result;
};

export const checkUserSubscriptionExpiry = () => async (dispatch, getState) => {
  let userSubscription;
  try {
    userSubscription = await api.getUserSubscriptionApi();
  } catch (e) {
    userSubscription = undefined;
  }
  dispatch({ type: UPDATE_USER_SUBSCRIPTION, payload: userSubscription });

  // // perform checking
  let open = false;
  let Sub = await api.isUserSubscriptionExpired();
  open = Sub.open;

  if (open) {
    let userDetails = getState().user.userDetails;
    let userId = userDetails._id ? userDetails._id : '';
    let userSubExpiredAt = Sub.currentSub[0].subscriptions.expiredAt;
    let data = {
      notification: {
        sender: {
          type: 'system'
        },
        receipients: {
          type: 'Adviser',
          ids: [
            {
              id: userId,
              state: 'new'
            }
          ]
        },
        title: {
          en: 'Subscription-expired-title',
          zh: 'Subscription-expired-title',
          zh_Hant_HK: 'Subscription-expired-title'
        },
        message: {
          en: 'Subscription-expired-message',
          zh: 'Subscription-expired-message',
          zh_Hant_HK: 'Subscription-expired-message'
        },
        type: {
          name: 'reminder',
          tag: 'SUB_EXPIRED',
          extraInfo: moment(userSubExpiredAt)
            .subtract(1, 'days')
            .format()
        }
      }
    };
    dispatch({ type: CREATENOTIFICATION, payload: data });
  }

  dispatch({ type: UPDATE_EXPIRY_DIALOG, payload: open });
  return { userSubscription, open: open };
};

export const toggleExpiryDialog = open => ({
  type: UPDATE_EXPIRY_DIALOG,
  payload: open
});

export const resetUserSubscription = () => ({
  type: RESET_USER_SUBSCRIPTION
});

export const updateCreateError = payload => ({
  type: UPDATE_CREATE_ERROR,
  payload
});

export const updateKeepShowingNewSignUp = show => ({
  type: UPDATE_KEEP_SHOWING_NEW_SIGN_UP,
  payload: show
});

export const getExclusiveCoupons = subscriptionPlanId => async (dispatch, _getState) => {
  let coupons = [],
    phase;
  dispatch({ type: UPDATE_EXCLUSIVE_COUPONS_PHASE, payload: LOADING });
  try {
    coupons = await api.getExclusiveCouponsApi(subscriptionPlanId);
    phase = SUCCESS;
  } catch (error) {
    coupons = [];
    phase = ERROR;
  }

  dispatch({ type: UPDATE_EXCLUSIVE_COUPONS, payload: { coupons, phase } });
  return coupons;
};

export const getTeamInvitationExtension = teamInvitationId => ({
  type: GET_TEAM_INVITATION_EXTENSION,
  payload: { teamInvitationId }
});

/***********************************
 * Epics
 ***********/
const updateSubscriptionEpic = action$ =>
  action$.ofType(UPDATE_USER_SUBSCRIPTION, UPDATE_SUBSCRIPTIONS).mergeMap(() => {
    return Rx.Observable.fromPromise(
      new Promise(resolve => {
        // add small delay to allow react to re-render view
        setTimeout(() => {
          resolve();
        }, 1000);
      })
    ).map(() => ({ type: SUBSCRIPTION_INITIAL_FETCHED }));
  });

const getTeamInvitationExtensionEpic = action$ =>
  action$.ofType(GET_TEAM_INVITATION_EXTENSION).mergeMap(action => {
    return Rx.Observable.fromPromise(teamApi.getTeamInvitation(action.payload.teamInvitationId))
      .map(result => {
        const { success, team } = result;
        console.log(result);
        if (!success || !team) {
          throw new Error('Team invitation not found');
        }

        return {
          type: GET_TEAM_INVITATION_EXTENSION_SUCCESS,
          payload: {
            teamInvitationExtension: team.teamInvitation.subscriptionMonthExtension
          }
        };
      })
      .catch(error =>
        Rx.Observable.of({
          type: GET_TEAM_INVITATION_EXTENSION_ERROR,
          payload: { error }
        })
      );
  });

export const userSubscriptionEpic = combineEpics(updateSubscriptionEpic, getTeamInvitationExtensionEpic);
