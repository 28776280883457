import React from 'react';
import { getSearchObject } from '../../utils/router';
import NotFound from '../NotFound/NotFound';
import { crypt } from '../../utils/string';
import moment from 'moment-timezone';

const MedicalCardQRApply = props => {
  const { history } = props;

  const searchObj = getSearchObject(history) || {};
  const { hash } = searchObj;
  if (hash) {
    try {
      const { applyLink, expiredAt } = JSON.parse(crypt(decodeURIComponent(hash)));

      if (applyLink) {
        if (moment(expiredAt).isAfter(moment())) {
          window.location.href = applyLink;
          return null;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return <NotFound onClick={() => history.replace('/')} />;
};

export default MedicalCardQRApply;
