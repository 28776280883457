import React, { useState, useRef, useEffect, useCallback } from 'react';
import Sticky from 'react-sticky-el';
import { connect } from 'react-redux';
import './StickyFooter.scss';

const StickyFooter = props => {
  const { showPadding, children, bottom, ...rest } = props;
  const [width, setWidth] = useState(0);
  const containerRef = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const [ixFixed, setIsFixed] = useState(true);

  const onFixedToggle = flag => setIsFixed(flag);

  return (
    // <Sticky
    //   mode="bottom"
    //   topOffset={-window.innerHeight}
    //   stickyStyle={ixFixed ? { zIndex: 3, background: '#fff' } : undefined}
    //   onFixedToggle={onFixedToggle}
    //   stickyClassName={`sticky sticky-footer${ixFixed ? ' fixed' : ''}${showPadding ? ' padding' : ''}`}
    //   {...rest}
    // >
    //   {children}
    // </Sticky>
    <div
      ref={containerRef}
      style={
        width
          ? { position: 'fixed', width: width, bottom: `calc(${showPadding ? 0 : 49}px + var(--bottom-padding))` }
          : undefined
      }
    >
      {children}
    </div>
  );
};

const container = connect(
  state => ({
    showPadding: state.app.get('showPadding')
  }),
  {}
)(StickyFooter);

export default container;
