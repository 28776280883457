import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { tracking } from '../../utils/tracking';

export const StripeElement = props => {
  const {
    intl,
    userDetails,
    buttonText,
    isSetup,
    isRecurring,
    paymentDescription,
    billingAgreement,
    recurringAmount,
    recurringLabel,
    recurringStartDate,
    recurringIntervalUnit,
    recurringIntervalCount,
    trialAmount,
    trialLabel,
    trialStartDate,
    trialEndDate,
    trialIntervalUnit,
    trialIntervalCount,
    completePay,
    onErr
  } = props;

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();


  const pay = async () => {
    if (!stripe || !elements) {
      return;
    }

    setErrorMessage(null);
    setLoading(true);
    tracking('Adviser make payment', {
      'proposed amount': 10,
      'actual amount': 10
    });

    if (isSetup) {
      var result = await stripe.confirmSetup({ elements, redirect: 'if_required' })
    } else {
      var result = await stripe.confirmPayment({ elements, redirect: 'if_required' });
    }

    setLoading(false);
    if (result.error) {
      // console.log(result.error);
      if (result.error.type !== 'validation_error') {
        setErrorMessage(result.error.message);
        onErr?.(result.error)
      }
    } else {
      completePay();
    }
  };

  let applePay = {
    recurringPaymentRequest: {
      paymentDescription: paymentDescription,
      managementURL: `${process.env.PFPLUS_URL}/subscription`,
      regularBilling: {
        amount: recurringAmount,
        label: recurringLabel,
        recurringPaymentStartDate: recurringStartDate,
        recurringPaymentIntervalUnit: recurringIntervalUnit,
        recurringPaymentIntervalCount: recurringIntervalCount
      },
      trialBilling: trialStartDate ? {
        amount: trialAmount,
        label: trialLabel,
        recurringPaymentStartDate: trialStartDate,
        recurringPaymentEndDate: trialEndDate,
        recurringPaymentIntervalUnit: trialIntervalUnit,
        recurringPaymentIntervalCount: trialIntervalCount
      } : undefined,
      billingAgreement: billingAgreement
    }
  };

  // To use Link from Stripe
  let defaultValues = userDetails && userDetails.name && userDetails.email ? {
    billingDetails: {
      name: userDetails.name,
      email: userDetails.email
    }
  } : undefined;

  return (
    <div>
      <PaymentElement
        options={{
          applePay: isRecurring ? applePay : undefined,
          business: { name: 'PortfoPlus' },
          defaultValues: undefined,
          paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
        }}
      />

      {errorMessage && (
        <>
          <br />
          <Typography color="error">{errorMessage}</Typography>
        </>
      )}

      <Button
        className="action"
        variant="contained"
        color="primary"
        fullWidth={true}
        disabled={loading}
        onClick={pay}
        style={{ marginTop: 12 }}
      >
        {loading ? <CircularProgress size={24} /> : buttonText}
      </Button>
    </div>
  );
};
