import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import MyTabs from '../../../components/MyTabs/MyTabs';
import { getCurrencyExchangeRate } from '../../../utils/currencyExchange';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Portal } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { categorySorter, isCategory } from '../../../utils/policy';
import PortfolioCategory from '../../Dashboard/partials/PortfolioCategory/PortfolioCategory';
import SwipeableViews from 'react-swipeable-views';
import MyResizeObserver from '../../../components/MyResizeObserver/MyResizeObserver';
import { NavMenuButton } from '../../../components/topNavigation';
import { CustomTopNavigationWithPortal } from '../../../components/CustomTopNavigation/CustomTopNavigation';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';

class ShareLinkView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      open: false,
      openModal: false,
      sendersAdviserId: ''
    };
    //this.handleSwipe = this.handleSwipe.bind(this);
  }

  handleToggle = () => this.setState({ open: !this.state.open });
  handleClose = () => this.setState({ open: false });

  //To get the language of user browser5
  componentDidMount() {
    var user = this.props.userDetails;
    if (this.isEmpty(user)) {
      var userLang = navigator.language || navigator.userLanguage;
      const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      this.props.selectedLocale(language);
    }
  }

  UNSAFE_componentWillMount = async () => {
    try {
      let lastPage = this.props.location.state;
      if (lastPage && lastPage.page === 'share_link_page') {
        this.setState({
          openModal: true,
          sendersAdviserId: lastPage.sendersAdviserId
        });
      }
    } catch (e) {
      return e;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    getCurrencyExchangeRate(nextProps.exchangeRate);
  }

  //Handle the chart data to tab on personal , company and general
  handleTab(index) {
    this.setState({ tab: index });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  closeModal() {
    this.setState({ openModal: false });
  }

  handleSlidesResize = () => {
    if (this.mySwipeableViews) {
      this.mySwipeableViews.updateHeight();
    }
  };

  render() {
    const { intl, location, portfolio1 } = this.props;
    const { tab } = this.state;
    var shareType =
      _.get(this.props, 'shareType').length > 0 &&
      _.get(this.props, 'shareType') !== undefined &&
      _.get(this.props, 'shareType') !== null
        ? _.get(this.props, 'shareType[0].receiverType', '')
        : _.get(location.state, 'sharePolicyData.shareType[0].receiverType');
    var senderId =
      _.get(this.props, 'shareType').length > 0 &&
      _.get(this.props, 'shareType') !== undefined &&
      _.get(this.props, 'shareType') !== null
        ? _.get(this.props, 'shareType[0].senderId', '')
        : _.get(location.state, 'sharePolicyData.shareType[0].senderId');
    var user = this.props.userDetails;
    let shareDoc =
      _.get(this.props, 'categories1') !== null &&
      _.get(this.props, 'categories1') !== undefined &&
      _.get(this.props, 'categories1').length > 0
        ? _.get(this.props, 'categories1')
        : _.get(location.state, 'sharePolicyData.categories1');

    let clientReferLink = '';
    if (this.state.sendersAdviserId !== '') {
      clientReferLink = '/referral_response/usr/' + this.state.sendersAdviserId + '$' + senderId._id;
    }

    const finalCategories = [...(shareDoc || [])].sort(categorySorter);
    const finalPortfolio = portfolio1 || [];

    const isEmptyUser = this.isEmpty(user);

    return (
      <AppContentContainer>
        <MyResizeObserver targetsSelector=".user-dashboard-slide" onResize={this.handleSlidesResize} />
        {!isEmptyUser ? null : (
          <div>
            <div>
              <Drawer open={this.state.open} onClose={() => this.setState({ open: false })}>
                <MenuItem className="menuSide">
                  <div className="profile-sidebar share-link-page">
                    <div className="profile-userpic1">
                      <img
                        src="img/logo_2s.png"
                        className="img-responsive"
                        alt=""
                        style={{ borderRadius: '0', paddingTop: '0px' }}
                      />
                    </div>
                  </div>
                </MenuItem>
                <MenuItem className="menuSide">
                  <Link to="/login" className="sideMenuAnchor">
                    <div className="sideMenuItem">
                      <img alt="" src="img/login.png" className="img-responsive" />
                      <span>{intl.formatMessage({ id: 'Login' })}</span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem className="menuSide" onClick={this.handleClose}>
                  <Link to="/signup" className="sideMenuAnchor">
                    <div className="sideMenuItem">
                      <img alt="" src="img/signup.png" className="img-responsive" />
                      <span> {intl.formatMessage({ id: 'Signup' })} </span>
                    </div>
                  </Link>
                </MenuItem>
                <MenuItem className="menuSide" onClick={this.handleClose}>
                  <a href="https://www.portfoplus.com" className="sideMenuAnchor">
                    <div className="sideMenuItem">
                      <img alt="" src="img/discussion1.png" className="img-responsive" />
                      <span> {intl.formatMessage({ id: 'More' })} </span>
                    </div>
                  </a>
                </MenuItem>
                {shareType === 'Family' && this.state.sendersAdviserId !== '' && (
                  <MenuItem className="menuSide" onClick={this.handleClose}>
                    <Link to={clientReferLink} className="sideMenuAnchor">
                      <div className="sideMenuItem">
                        <img alt="" src="img/share1.png" className="img-responsive" />
                        <span>{intl.formatMessage({ id: 'HelpBuild' })}</span>
                      </div>
                    </Link>
                  </MenuItem>
                )}
                <div style={{ padding: '20px' }}>
                  <p>
                    {intl.formatMessage({
                      id:
                        'PortfoPlus is a FREE a service to manage ALL your insurance policies and get quick info during emergency.'
                    })}
                  </p>
                  {shareType === 'Family' && this.state.sendersAdviserId !== '' && (
                    <p>
                      {intl.formatMessage({ id: 'Click' })}
                      {intl.formatMessage({ id: 'Help me build' })}
                      {intl.formatMessage({
                        id: "to request the sender's adviser to"
                      })}
                      {intl.formatMessage({
                        id: 'create an account for you for free.'
                      })}
                    </p>
                  )}
                </div>
              </Drawer>
            </div>
            <CustomTopNavigationWithPortal title={intl.formatMessage({ id: 'Portfolio' })}>
              <NavMenuButton id="menu-toggle" onClick={this.handleToggle} />
            </CustomTopNavigationWithPortal>
          </div>
        )}
        <div style={{ margin: '0 -10px 10px' }}>
          <MyTabs
            value={tab}
            onChange={(event, value) => {
              this.tempTab = undefined;
              this.setState({ tab: value });
            }}
            tabLabels={
              finalCategories && finalCategories.length > 0
                ? finalCategories.map(val => intl.formatMessage({ id: val.title }))
                : []
            }
          />
        </div>
        <div>
          <SwipeableViews
            // className="nav-top-margin"
            ref={node => {
              if (node) this.mySwipeableViews = node;
            }}
            animateHeight={true}
            index={tab}
            onChangeIndex={index => (this.tempTab = index)}
            onTransitionEnd={() => {
              if (this.tempTab !== undefined) {
                this.setState({ tab: this.tempTab });
              }
            }}
          >
            {finalCategories.map(category => {
              const isGeneralCategory = isCategory(category._id, 'general');
              return (
                <div
                  className="user-dashboard-slide"
                  style={{ minHeight: window.innerHeight - 150 }}
                  key={category._id}
                >
                  <PortfolioCategory
                    portfolio={finalPortfolio}
                    category={category}
                    portfolioSelector={false}
                    viewSelector={false}
                    infoType={isGeneralCategory ? 'Premium' : undefined}
                    premiumFrequency={isGeneralCategory ? 'Monthly' : undefined}
                  />
                </div>
              );
            })}
          </SwipeableViews>
        </div>
        <Dialog
          open={this.state.openModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Share Portfolio' })}</DialogTitle>
          <DialogContent>
            {intl.formatMessage({
              id:
                'Here is the preview of portfolio shared to you. Please sign up (using the same email address) or login to see the full shared information.'
            })}
            <br />
            <br />
            {intl.formatMessage({
              id: 'PortfoPlus - Know Your Coverage. Be Well Protected.'
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeModal.bind(this)} color="primary">
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </AppContentContainer>
    );
  }
}

export default ShareLinkView;
