import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getUser, handleSignOut } from '../../../store/user/duck';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import { acceptConnectionRequest } from '../../../store/connectionRequest/api';
import { withRouter } from 'react-router';
import { fetchMePolicy } from '../../../store/policy/duck';
import { usePrevious } from '../../../utils/hooks';
import { LOADING, SUCCESS } from '../../../constants/phase';

const ConnectionRequestAcceptDialog = props => {
  const {
    history,
    match,
    location,
    staticContext,
    intl,
    open,
    connectionRequest,
    fetchPhase,
    handleSignOut,
    fetchMePolicy,
    getUser,
    ...rest
  } = props;

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const prevFetchPhase = usePrevious(fetchPhase);

  const onChangeChecked = event => {
    setChecked(event.target.checked);
    setError(false);
  };

  const logout = () => handleSignOut();

  const submit = async () => {
    if (checked) {
      try {
        setLoading(true);
        const response = await acceptConnectionRequest(connectionRequest._id);
        if (response.success) {
          getUser();
          setDone(true);
          toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), {
            className: 'new-toast'
          });
          setTimeout(() => {
            history.replace('/c-dashboard', { fetch: true });
          }, 1000);
        } else {
          toast.info(IconToast('error', response.message), { className: 'new-toast' });
        }
      } catch (error) {
        console.log(error);
        toast.info(IconToast('error', error.message), { className: 'new-toast' });
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'connection-request-accept-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item>
            <Typography>{intl.formatMessage({ id: 'connection-request-accept-dialog-content' })}</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              label={intl.formatMessage({ id: 'understand_message_for_user' })}
              control={<Checkbox checked={checked} onChange={onChangeChecked} color="primary" />}
            />
            {error && (
              <Typography color="error">{intl.formatMessage({ id: 'Please read and accept beforehand' })}</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={loading || done} onClick={logout}>
          {intl.formatMessage({ id: 'Logout' })}
        </Button>
        <Button color="primary" disabled={loading || done} onClick={submit}>
          {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    fetchPhase: state.policy.fetchPhase
  }),
  {
    handleSignOut,
    fetchMePolicy,
    getUser
  }
)(ConnectionRequestAcceptDialog);

export default injectIntl(withRouter(container));
