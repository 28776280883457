import React, { forwardRef } from 'react';
import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 'inherit',
    height: 'unset',
    lineHeight: 1.9
  },
  helperText: {
    fontSize: 'inherit'
  },
  labelFormControl: {
    '&:not(.MuiInputLabel-shrink)': {
      transform: 'translate(0, 24px) scale(1)'
    }
  },
  labelShrink: {
    transform: 'translate(0, 1.5px) scale(1)'
  }
}));

const StyledTextField = forwardRef((props, ref) => {
  const { InputProps, InputLabelProps, FormHelperTextProps, noShrinkClass, ...rest } = props;
  const classes = useStyles(props);

  const { classes: inputClasses = {}, ...InputPropsRest } = InputProps || {};
  const { classes: labelClasses = {}, ...InputLabelPropsRest } = InputLabelProps || {};
  const { classes: helperTextClasses = {}, ...FormHelperTextPropsRest } = FormHelperTextProps || {};

  return (
    <TextField
      InputProps={{
        classes: {
          input: classes.input,
          ...inputClasses
        },
        ...InputPropsRest
      }}
      InputLabelProps={{
        classes: {
          formControl: !noShrinkClass ? classes.labelFormControl : undefined,
          shrink: !noShrinkClass ? classes.shrink : undefined,
          ...labelClasses
        },
        ...InputLabelPropsRest
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
          ...helperTextClasses
        },
        ...FormHelperTextPropsRest
      }}
      {...rest}
      ref={ref}
    />
  );
});

export default StyledTextField;
