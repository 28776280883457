import { disableDomInteraction, enableDomInteraction } from '../../utils/dom';
import store from '../index';
import { closeHeaderMenu, openHeaderMenu } from '../headerButtonControl/duck';
import { runGuideline, isStepLastClicked } from './duck';
import { updateFirstGuidelineState } from '../user/duck';
import { isNavAside } from '../../utils/device';
import { ACTIONS } from 'react-joyride';
import UserFirstGuidelineTooltip from '../../components/guidelines/UserFirstGuidelineTooltip/UserFirstGuidelineTooltip';

export const getUserFirstGuidelineTotalSteps = ({ intl }) => {
  // const isSideNav = isNavAside();

  const result = {
    userFirstLogin1: userFirstLogin1({ intl }).length || 0,
    userFirstLogin2: 0,
    userFirstLogin3: 0
    // userFirstLogin2: isSideNav ? 0 : userFirstLogin2({ intl }).length || 0,
    // userFirstLogin3: isSideNav ? 0 : userFirstLogin3.length || 0
  };

  return {
    ...result,
    total: result.userFirstLogin1 // + result.userFirstLogin2 + result.userFirstLogin3
  };
};

const callback = tour => {
  if (tour.action === ACTIONS.CLOSE) {
    store.dispatch(updateFirstGuidelineState(true));
  }
};

const menuStep = {
  target: '.nav-menu-button',
  content: 'jr-ufl-1',
  placement: 'auto',
  isFixed: true,
  disableScrolling: true,
  disableBeacon: true,
  callback: callback
};

const notificationStep = {
  target: '.bellButton',
  content: 'jr-ufl-2',
  placement: 'auto',
  disableBeacon: true,
  isFixed: true,
  disableScrolling: true,
  callback: callback
};

const questionStep = {
  target: '.client-dashboard-guideline-icon',
  content: 'jr-ufl-3',
  placement: 'auto',
  disableBeacon: true,
  isFixed: true,
  disableScrolling: true,
  callback: tour => {
    if (isStepLastClicked(tour) || tour.action === ACTIONS.CLOSE) {
      // store.dispatch(updateUserDetails({ firstLoginGuidelineDone: true }));
      store.dispatch(updateFirstGuidelineState(true));
    }
  }
};

export const userFirstLogin1 = ({ intl }) => {
  let steps = [
    {
      target: '.footer-nav > ul > li:nth-child(5)',
      content: 'jr-ufl-0',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      callback: callback
    },
    // {
    //   target: '.footer-nav',
    //   content: 'jr-ufl-4',
    //   placement: 'auto',
    //   disableBeacon: true,
    //   isFixed: true,
    //   disableScrolling: true
    // },
    {
      target: '.footer-nav > ul > li:first-child',
      content: 'jr-ufl-5',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      callback: callback
    },
    {
      target: '.footer-nav > ul > li:nth-child(2)',
      content: 'jr-ufl-6',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      callback: callback
    },
    // {
    //   target: '.footer-nav > ul > li:nth-child(3)',
    //   content: 'jr-ufl-7',
    //   placement: 'auto',
    //   disableBeacon: true,
    //   isFixed: true,
    //   disableScrolling: true
    // },
    {
      target: '.footer-nav > ul > li:nth-child(4)',
      content: 'jr-ufl-8',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      callback: callback
    },
    {
      target: '.footer-nav > ul > li:nth-child(3)',
      content: 'jr-ufl-1',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      callback: callback
    },
    questionStep
  ];

  // const isSideNav = isNavAside();
  //
  // if (isSideNav) {
  //   steps = steps.concat([
  //     {
  //       target: '.footer-nav > ul > li:nth-child(8)',
  //       content: 'jr-ufl-11',
  //       placement: 'auto',
  //       disableBeacon: true,
  //       isFixed: true,
  //       disableScrolling: true,
  //       callback: callback
  //     },
  //     menuStep,
  //     notificationStep,
  //     questionStep
  //   ]);
  // } else {
  //   steps = steps.concat([
  //     {
  //       ...menuStep,
  //       callback: tour => {
  //         if (isStepLastClicked(tour)) {
  //           // disable dom interaction
  //           disableDomInteraction();
  //
  //           // open side menu
  //           store.dispatch(openHeaderMenu());
  //
  //           // wait for transition & run guideline
  //           setTimeout(() => {
  //             store.dispatch(
  //               runGuideline('userFirstLogin2', {
  //                 parameters: { intl },
  //                 showProgress: true,
  //                 tooltipComponent: UserFirstGuidelineTooltip
  //               })
  //             );
  //             enableDomInteraction();
  //           }, 350);
  //         } else if (tour.action === ACTIONS.CLOSE) {
  //           store.dispatch(updateFirstGuidelineState(true));
  //         }
  //       },
  //       locale: {
  //         last: intl.formatMessage({ id: 'joyride_next' })
  //       }
  //     }
  //   ]);
  // }

  return steps;
};

export const userFirstLogin2 = ({ intl }) => [
  // {
  //   target: 'div[role="presentation"] > div > div > .side-functions',
  //   content: 'jr-ufl-10',
  //   placement: window.innerHeight < 667 || window.innerWidth < 670 ? 'right' : 'auto', // fix wired tooltip position
  //   disableBeacon: true,
  //   isFixed: true,
  //   disableScrolling: true,
  //   spotlightPadding: 0,
  //   callback: tour => {
  //     if (tour.lifecycle === LIFECYCLE.TOOLTIP) {
  //     	setTimeout(() => {
  // 		const floaterElem = document.querySelector('.__floater');
  // 		const rect = floaterElem.getBoundingClientRect();
  // 		console.log(rect);
  // 		const fix = (window.innerHeight - (rect.y + 200)) * 1.5;
  // 		console.log(`${fix}px`);
  // 		// floaterElem.click();
  // 		if (fix < 0) {
  // 			floaterElem.style.marginTop = `${fix}px`;
  // 		}
  // 	}, 100);
  //     }
  //   }
  // },
  {
    target: 'div[role="presentation"] > div > div > .side-functions > li:nth-child(2)',
    content: 'jr-ufl-11',
    placement: 'auto',
    disableBeacon: true,
    isFixed: true,
    disableScrolling: true,
    callback: tour => {
      if (isStepLastClicked(tour)) {
        // close drawer
        store.dispatch(closeHeaderMenu());

        // disable dom interaction
        disableDomInteraction();

        // wait for transition, run guideline & resume dom interaction
        setTimeout(() => {
          store.dispatch(
            runGuideline('userFirstLogin3', { showProgress: true, tooltipComponent: UserFirstGuidelineTooltip })
          );
          enableDomInteraction();
        }, 250);
      } else if (tour.action === ACTIONS.CLOSE) {
        store.dispatch(updateFirstGuidelineState(true));
      }
    },
    locale: {
      last: intl.formatMessage({ id: 'joyride_next' })
    }
  }
  // {
  //   target: 'div[role="presentation"] > div > div > .side-functions > li:nth-child(2)',
  //   content: 'jr-ufl-12',
  //   placement: 'auto',
  //   disableBeacon: true,
  //   isFixed: true,
  //   disableScrolling: true,
  //   callback: tour => {
  //     if (isStepLastFinished(tour)) {
  //       // close drawer
  //       store.dispatch(closeHeaderMenu());
  //
  //       // disable dom interaction
  //       disableDomInteraction();
  //
  //       // wait for transition, run guideline & resume dom interaction
  //       setTimeout(() => {
  //         store.dispatch(runGuideline('userFirstLogin3'));
  //         enableDomInteraction();
  //       }, 250);
  //     }
  //   },
  //   locale: {
  //     last: intl.formatMessage({ id: 'joyride_next' })
  //   }
  // }
];

export const userFirstLogin3 = [notificationStep, questionStep];
