import { fetch } from '../../utils';
import qs from 'qs';

import Backend from '../../backend';
import { tracking } from '../../utils/tracking';
import { getInsurerTitleEn } from '../../utils/insurer';
import { MEDIA_UPLOAD_TYPE } from '../../constants/media';
import { uploadAttachments } from '../media/api';

//API TO ADD THE POLICY FOR BOTH USER AND ADVISER (ADD FOR HIS CLIENT)
export const addPolicy = data => {
  const { attachments, ...policyData } = data;
  return fetch(Backend.methodUrl('addPortfolio'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(policyData)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      // For backward compatibility, when older server may return payload.data without populate those fields.
      if (
        payload.data &&
        payload.data.categoryId &&
        payload.data.categoryId.title &&
        payload.data.subCategoryId &&
        payload.data.subCategoryId.title &&
        payload.data.insurerId &&
        payload.data.insurerId.title
      ) {
        if (!data.id) {
          // Tracking
          tracking('Policy Added', {
            Category: payload.data.categoryId.title,
            'Sub Category': payload.data.subCategoryId.title,
            Insurer: getInsurerTitleEn(payload.data.insurerId)
          });
        } else {
          // Tracking
          tracking('Policy Edited', {
            Category: payload.data.categoryId.title,
            'Sub Category': payload.data.subCategoryId.title,
            Insurer: getInsurerTitleEn(payload.data.insurerId)
          });
        }
      } else {
        if (!data.id) {
          // Tracking
          tracking('Policy Added');
        } else {
          // Tracking
          tracking('Policy Edited');
        }
      }
      return payload;
    })
    .then(async payload => {
      if (!(attachments && attachments.length)) return payload;
      const policyId = data.id ? data.id : payload.data.id;
      const attachmentsData = await uploadAttachments({
        resourceId: policyId,
        data: payload,
        attachments: attachments,
        type: MEDIA_UPLOAD_TYPE.PORTFOLIO
      });
      await editPolicy({
        id: policyId,
        attachments: attachmentsData
      });
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO EDIT THE POLICY
export const editPolicy = data => {
  var id = data.id;
  delete data.id;
  return fetch(Backend.methodUrl('editPortfoliofield') + '/' + id, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET TEH POILCY DETAIL
export const policyInfo = id => {
  return fetch(Backend.methodUrl('policyInfo') + '/' + id, { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO DELETE THE POLICY
export const deletePolicy = id => {
  return fetch(Backend.methodUrl('deletePolicy') + '/' + id, {
    method: 'DELETE'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { error };
    });
};

//API TO MOVE THE POLICY
export const movePolicy = data => {
  return fetch(Backend.methodUrl('movePortfolio'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const uploadDocument = data => {
  return fetch(Backend.methodUrl('uploadDocument'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE POLICY OF CLIENT
export const fetchPolicy = data => {
  return fetch(Backend.methodUrl('getPortfolio') + `?userId=${data.clientId}`, {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API GET THE POLICY OF LOGIN USER
export const fetchMePolicy = () => {
  return fetch(Backend.methodUrl('getMePortfolio'), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getPortfolioJson = () => {
  return fetch(Backend.methodUrl('getPortfolioJson'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

//API TO GET THE DETAIL OF INSURER
export const insurerInfo = () => {
  return fetch(Backend.methodUrl('insurerInfo'), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

const POLICIES_POPULATES = [
  {
    path: 'category',
    select: 'title'
  },
  {
    path: 'subCategory',
    select: 'title'
  }
];

// New API to get client policies
export const getClientPolicies = clientId => {
  return fetch(Backend.methodUrl(`client/${clientId}/policies?${qs.stringify({ populates: POLICIES_POPULATES })}`), {
    method: 'GET'
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

// New API to get own policies
export const getOwnPolicies = () => {
  return fetch(Backend.methodUrl(`policies?${qs.stringify({ populates: POLICIES_POPULATES })}`), { method: 'GET' })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
