import React from 'react';
import { IconButton } from '@material-ui/core';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import Badge from '@material-ui/core/Badge';
import './TodoIconButton.scss';
class TodoIconButton extends React.Component {
  isOverDue = todo => {
    let date = todo.dueDate;
    if (!date) {
      return false;
    }
    //normalize dates and disregard hours
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return date <= now;
  };

  render() {
    const { handleClick, activeTodo } = this.props;
    const numberOfOverDueTodo = activeTodo.filter(this.isOverDue).length;
    return (
      <IconButton color="primary" onClick={handleClick}>
        <Badge
          color="error"
          className="Badge todo-badge"
          badgeContent={numberOfOverDueTodo}
          invisible={numberOfOverDueTodo > 0 ? false : true}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <DoneOutlineRoundedIcon />
        </Badge>
      </IconButton>
    );
  }
}

export default TodoIconButton;
