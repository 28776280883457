import { useRef, useEffect, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import _ from 'lodash';

const MyResizeObserver = props => {
  const { targetsSelector, onResize } = props;

  const unmountedRef = useRef(false);
  const observersRef = useRef({});

  const setObservers = useCallback(() => {
    const targets = document.querySelectorAll(targetsSelector);
    if (targets && targets.length > 0) {
      targets.forEach((target, index) => {
        const entry = {
          observer: new ResizeObserver((entries, observer) => {
            if (!unmountedRef.current) {
              const observers = observersRef.current;
              const observerEntry = _.get(observers, `${index}`);
              if (observerEntry) {
                let currentTime = new Date();
                if (observerEntry.observedTime) {
                  if (currentTime - observerEntry.observedTime < 1000) {
                    if (observerEntry.observedActionTimeout) {
                      clearTimeout(observerEntry.observedActionTimeout);
                    }
                  } else {
                    currentTime = undefined;
                  }
                }
                _.set(observers, `${index}.observedTime`, currentTime);
                _.set(
                  observers,
                  `${index}.observedActionTimeout`,
                  setTimeout(() => {
                    if (!unmountedRef.current) {
                      if (onResize) {
                        onResize(entries, observer);
                      }
                    }
                  }, 80)
                );
              }
            }
          }),
          observedTime: undefined,
          observedActionTimeout: undefined
        };
        entry.observer.observe(target);
        observersRef.current[index] = entry;
      });
    } else {
      // retry until observer is set or component is unmounted
      setTimeout(() => {
        if (!unmountedRef.current) {
          setObservers();
        }
      }, 500);
    }
  }, [targetsSelector, onResize]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (targetsSelector) {
      setObservers();
    }
  }, [setObservers, targetsSelector]);

  return null;
};

export default MyResizeObserver;
