import _ from 'lodash';

// interpolate string
// "${varA} >= 5 && ${varB} <= 10", { varA: 3, varB: 200 } ==> "3 >= 5 && 200 <= 10"
// ref: https://gist.github.com/smeijer/6580740a0ff468960a5257108af1384e
const interpolateString = (str, variables) => {
  return str.replace(/\${([^}]+)}/g, (m, p) => {
    const splitReduce = p.split('.').reduce((a, f) => (a ? a[f] : undefined), variables);
    return splitReduce !== null && splitReduce !== undefined ? splitReduce : m;
  });
};

const evaluateConditionString = (completeCondition, initialVariables, variables) => {
  let i = 0;
  let trailingWhiteSpace = completeCondition[completeCondition.length - 1] === ' ';
  const main = () => {
    const result = [];
    let startIndex = i;

    const evaluateExpression = () => {
      if (i - 1 > startIndex) {
        const expression = completeCondition.slice(startIndex, i - 1);
        const targetVariable = _.get(expression.match(/\${([^}]+)}/g), '[0]', '')
          .replace('${', '')
          .replace('}', '');
        const interpolation = interpolateString(expression, variables);
        const splits = interpolation.split(' ').map(part => part.trim());
        if (targetVariable && splits.length === 3) {
          const target = parseFloat(splits[2]);
          const start = initialVariables[targetVariable] || 0;
          const current = variables[targetVariable] || 0;
          const progress = (current - start) / (target - start);
          result.push(Math.max(Math.min(progress, 1), 0) * 100);
        } else {
          try {
            // eslint-disable-next-line no-eval
            if (eval(interpolation)) {
              result.push(100);
            } else {
              result.push(0);
            }
          } catch (error) {
            console.log(error);
            result.push(0);
          }
        }
      }
    };

    while (i < completeCondition.length) {
      switch (completeCondition[i++]) {
        case ' ':
          const twoCharAfter = completeCondition.substring(i, i + 2);
          if (twoCharAfter === '&&' || twoCharAfter === '||') {
            evaluateExpression();
            startIndex = i + 2;
            result.push(twoCharAfter);
          }
          continue;
        case '(':
          result.push(main());
          startIndex = i;
          continue;
        case ')':
          evaluateExpression();
          return result;
        default:
          break;
      }
    }

    if (!trailingWhiteSpace) {
      i = i + 1;
      evaluateExpression();
    }

    return result;
  };

  const progresses = main();

  const getPartProgress = part => {
    if (part.length === 1) {
      return { score: part[0], andCount: 1 };
    } else {
      let currentScore = 0,
        currentAndCount = 0,
        previousSign;
      for (let i = 0; i < part.length; i++) {
        const value = part[i];

        if (typeof value === 'number' || Array.isArray(value)) {
          const { score, andCount } =
            typeof value === 'number' ? { score: value, andCount: 1 } : getPartProgress(value);
          // console.log(currentScore, currentAndCount, { score, andCount });
          if (!previousSign || previousSign === '&&') {
            currentScore = (currentScore * currentAndCount + score * andCount) / (currentAndCount + andCount);
            currentAndCount += andCount;
          } else {
            if (previousSign === '||') {
              currentScore = Math.max(currentScore, score);
            } else {
              // unsupported operator
              currentScore = 0;
            }
            currentAndCount = 0;
          }
        } else {
          previousSign = value;
        }
      }
      return { score: currentScore, andCount: currentAndCount };
    }
  };

  const { score } = getPartProgress(progresses);

  // console.log(completeCondition, initialVariables, variables, progresses, score);

  return score;
};

export const getMissionProgress = (mission, completedMissions, initialVariables, variables) => {
  if (mission) {
    const missionId = _.get(mission, '_id');
    if (completedMissions[missionId]) {
      return 100;
    } else {
      let completeCondition = mission.completeCondition || '';
      return evaluateConditionString(completeCondition, initialVariables, variables);
    }
  }
  return 0;
};

export const containMissionList = (userMissions, missionListId) => {
  return !!(userMissions || []).find(
    userMission =>
      _.get(userMission, 'missionListId.id') === missionListId ||
      _.get(userMission, 'missionListId._id') === missionListId
  );
};
