import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { checkIsBiometricSet, updateIsBiometricSet } from '../../../store/auth/duck';
import { CircularProgress, makeStyles, Switch } from '@material-ui/core';
import NativeOrWeb from '../../../utils/native';
import _ from 'lodash';
import AskPasswordDialog from './AskPasswordDialog/AskPasswordDialog';

const ProgressStyle = {
  verticalAlign: 'middle'
};

const useStyles = makeStyles({
  root: {
    marginRight: -8
  }
});

const BiometricAuthSwitch = props => {
  const { userDetails, isBiometricSet, updateIsBiometricSet, checkIsBiometricSet } = props;
  const [passwordDialog, setPasswordDialog] = useState(false);

  const [isThisUserSet, setIsThisUserSet] = useState(false);
  const [loading, setLoading] = useState(true);
  const classes = useStyles(props);

  const onChange = async event => {
    if (event.target.checked) {
      setPasswordDialog(true);
    } else {
      const isDisabled = await NativeOrWeb.disableBiometricAuth();
      if (isDisabled) {
        updateIsBiometricSet(false);
      }
    }
  };

  const closePasswordDialog = () => setPasswordDialog(false);

  const proceed = async (username, password) => {
    await NativeOrWeb.setBiometricCredentials(username, password);
    const isEnabled = await NativeOrWeb.enableBiometricAuth();
    if (isEnabled) {
      updateIsBiometricSet(true);
      setIsThisUserSet(true);
    }
  };

  useEffect(() => {
    NativeOrWeb.getBiometricCredentials()
      .then(credentials => {
        let flag = false;
        if (isBiometricSet) {
          const thisUserEmail = _.get(userDetails, 'email');
          const savedEmail = _.get(credentials, 'username');
          if (thisUserEmail && savedEmail) {
            if (thisUserEmail.toLowerCase() === savedEmail.toLowerCase()) {
              flag = true;
            }
          }
        } else {
          flag = true;
        }

        setIsThisUserSet(flag);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isBiometricSet, userDetails]);

  useEffect(() => {
    checkIsBiometricSet();
  }, [checkIsBiometricSet]);

  return loading ? (
    <CircularProgress color="primary" style={ProgressStyle} />
  ) : (
    <Fragment>
      <AskPasswordDialog open={passwordDialog} onClose={closePasswordDialog} onSuccess={proceed} />
      <Switch color="primary" checked={isThisUserSet && isBiometricSet} onChange={onChange} classes={classes} />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    isBiometricSet: state.auth.isBiometricSet
  }),
  {
    updateIsBiometricSet,
    checkIsBiometricSet
  }
)(BiometricAuthSwitch);

export default injectIntl(container);
