import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './AddIconButton.scss';

export class AddIconButton extends React.PureComponent {
  render() {
    return (
      <IconButton {...this.props} className={'add-icon-button ' + (this.props.className ? this.props.className : '')}>
        <AddIcon />
      </IconButton>
    );
  }
}

export class ExpandIconButton extends React.PureComponent {
  render() {
    return <ExpandMoreIcon color="primary" />;
  }
}
