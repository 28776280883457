import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import { goToExport, goToExportEmergencyEWallet } from '../../components/ClientEntry/utils';
import './ExportOptions.scss';
import { tracking } from '../../utils/tracking';
import FunctionCardsContainer from '../../components/FunctionCard/FunctionCardsContainer';
import { hasCampaignCode } from '../../utils/user';
import AddToEWalletDialog from './AddToEWalletDialog/AddToEWalletDialog';
import { HotLabel } from '../AdviserHomePage/HotLabel/HotLabel';
import CornerTriangle from '../../components/CornerTriangle/CornerTriangle';
import { toggleControl } from '../../store/control/duck';

const ExportOptions = props => {
  const { intl, history, userDetails, teamCampaignCodes, toggleControl } = props;

  const [addToEWalletDialogOpen, setAddToEWalletDialogOpen] = useState(false);

  // const shouldShowWalletPassButtons = hasCampaignCode(userDetails, teamCampaignCodes, 'FEATURE_EMINFO');  //limit by campaigncode
  const shouldShowWalletPassButtons = true; //avalible to all

  const exportReview = () => {
    // Tracking
    tracking('Policy Review Accessed');
    toggleControl('chatGPTPersonalMessageDrawer', false);
    history.push('/policy_review');
  };
  const exportEmergency = () => {
    // Tracking
    tracking('Export Emergency Accessed');
    toggleControl('chatGPTPersonalMessageDrawer', false);
    goToExport(JSON.parse(sessionStorage.getItem('client')), history);
  };
  const exportEmergencyEWallet = () => {
    setAddToEWalletDialogOpen(true);
  };

  const closeAddToEWalletDialog = () => setAddToEWalletDialogOpen(false);
  const onAddToEWalletSuccess = options => {
    tracking('Export Emergency E-Wallet Accessed');
    goToExportEmergencyEWallet(JSON.parse(sessionStorage.getItem('client')), history, options);
  };

  const clientId = JSON.parse(window.localStorage.getItem('clientId'));

  if (!clientId) {
    history.push('/client');
  }

  return (
    <Fragment>
      <AddToEWalletDialog
        open={addToEWalletDialogOpen}
        onClose={closeAddToEWalletDialog}
        onSuccess={onAddToEWalletSuccess}
      />

      <div className="export-options">
        <Container className="addPolicyPadding">
          <FunctionCardsContainer
            items={[
              {
                text: intl.formatMessage({ id: 'export-sum-desc' }),
                subtitle: intl.formatMessage({ id: 'export-sum-info' }),
                imgSrc: 'img/export-options/exportreview.png',
                imgAlt: 'export-review',
                color: '#5fc3d2',
                onClick: exportReview
              },
              {
                text: intl.formatMessage({ id: 'Emergency Reference' }),
                subtitle: intl.formatMessage({ id: 'export-emer-info' }),
                imgSrc: 'img/export-options/exportemergency.png',
                imgAlt: 'export-emergency',
                color: '#3999f8',
                onClick: exportEmergency
              },
              shouldShowWalletPassButtons && {
                text: intl.formatMessage({ id: 'export-emer-e-wallet-desc' }),
                subtitle: intl.formatMessage({ id: 'export-emer-e-wallet-info' }),
                imgSrc: 'img/export-options/emergencyEwallet.png',
                imgAlt: 'export-emergency-e-wallet',
                color: '#2260a5',
                onClick: exportEmergencyEWallet,
                extras: (
                  <Fragment>
                    <CornerTriangle
                      text="COT"
                      backgroundColor="#ffc000"
                      containerStyle={{ left: 6, borderRadius: 0 }}
                    />
                    <HotLabel
                      text={intl.formatMessage({ id: 'hot' })}
                      style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                    />
                  </Fragment>
                )
              }
            ]}
          />
        </Container>
      </div>
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    connectedAdvisers: state.user.connectedAdvisers,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {
    toggleControl
  }
)(ExportOptions);

export default injectIntl(container);
