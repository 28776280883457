import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

const TypoStyle = {
  fontSize: '13px',
  color: '#666'
};

const TermsAndPolicyTypography = props => {
  const { intl, hideLoginMessage } = props;

  return (
    <Typography align="center" style={TypoStyle}>
      {!hideLoginMessage && (
        <Fragment>
          {intl.formatMessage({
            id: 'By logging in you AGREED to PortfoPlus'
          })}
          <br />
        </Fragment>
      )}
      <a rel="noopener noreferrer" className="login-link" href="https://www.portfoplus.com/terms.html" target="_blank">
        {intl.formatMessage({ id: 'Terms of Service' })}
      </a>{' '}
      {intl.formatMessage({ id: 'and' })}{' '}
      <a
        rel="noopener noreferrer"
        className="login-link"
        href="https://www.portfoplus.com/privacy.html"
        target="_blank"
      >
        {intl.formatMessage({ id: 'Privacy Policy' })}
      </a>
    </Typography>
  );
};

export default injectIntl(TermsAndPolicyTypography);
