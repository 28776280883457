import React, { Fragment, useState } from 'react';
import NativeOrWeb from '../../../utils/native';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import mime from 'mime-types';

const FileItem = ({ file, onRemoved }) => {
  const [isFilePreviewOpen, setIsFilePreviewOpen] = useState(false);

  const handleFileItemClicked = () => {
    let mimeType = mime.lookup(file.url);
    NativeOrWeb.downloadFile(file.url, file.filename, mimeType);
  };

  return (
    <Fragment>
      <div className="file-item-container">
        <AttachmentIcon className="attachment-icon" color="primary" />
        <span className="file-item-title-container">
          {!!file.url ? (
            <Button className="file-button" onClick={() => handleFileItemClicked()} color="inherit">
              <span className="file-item-title">{file.name}</span>
            </Button>
          ) : (
            <Button className="file-button" color="inherit">
              <span className="file-item-title">{file.name}</span>
            </Button>
          )}
        </span>
        <IconButton className="file-item-close-button" onClick={() => onRemoved()}>
          <CloseIcon />
        </IconButton>
      </div>
      {!!file.url && (
        <Modal className="file-preview-modal" open={isFilePreviewOpen} onClose={() => setIsFilePreviewOpen(false)}>
          <div className="file-preview-container">
            <IconButton className="file-preview-close-button" onClick={() => setIsFilePreviewOpen(false)}>
              <CloseIcon />
            </IconButton>
            <iframe className="file-preview-iframe" title={file.name} src={file.url} allowFullScreen />
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default FileItem;
