import React, { PureComponent } from 'react';
import '../styles.scss';
import _ from 'lodash';
import FunctionCardsContainer from '../../../../components/FunctionCard/FunctionCardsContainer';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { isAdviserType } from '../../../../utils/user';

class AddPortfolioView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    const locationCategory = _.get(props, 'location.state.category');
    if (locationCategory) {
      _.unset(props, 'location.state.category');
      this.handleData(locationCategory);
    }
  }

  handleData(data) {
    const { handleCategoryData, handleNextPage } = this.props;
    handleCategoryData(data);
    var formData = {};
    formData.value = data._id;
    formData.title = data.title;
    localStorage.setItem('category', JSON.stringify(formData));
    handleNextPage();
  }

  render() {
    const { AddPortfolioData, intl, userDetails } = this.props;
    // var clientId = JSON.parse(window.localStorage.getItem('clientId'));
    // let routingPath = clientId ? '/client' : '/existing_policy';
    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid" style={{ paddingTop: 15 }}>
              <div className="row">
                <div className="col-lg-12">
                  {isAdviserType(userDetails) && (
                    <AladdinHint title={intl.formatMessage({ id: 'aladdin-add-policy-title' })} helpCenter="c03" />
                  )}

                  <FunctionCardsContainer
                    items={
                      AddPortfolioData && AddPortfolioData.length > 0
                        ? AddPortfolioData.map((val, index) => {
                            let title =
                              val.title === 'Company'
                                ? 'Workplace Policy'
                                : val.title === 'Personal'
                                ? 'Personal Protection'
                                : val.title === 'General'
                                ? 'Personal Misc.'
                                : '';
                            let subtitle =
                              val.title === 'Personal'
                                ? intl.formatMessage({
                                    id: 'personal-policy-desc'
                                  })
                                : val.title === 'Company'
                                ? intl.formatMessage({
                                    id: 'work-policy-desc'
                                  })
                                : val.title === 'General'
                                ? intl.formatMessage({
                                    id: 'misc-policy-desc'
                                  })
                                : '';
                            let color =
                              val.title === 'Company'
                                ? 'rgb(34, 96, 165)'
                                : val.title === 'Personal'
                                ? 'rgb(95, 195, 210)'
                                : val.title === 'General'
                                ? 'rgb(57, 102, 248)'
                                : '#5fc3d2';
                            return {
                              text: intl.formatMessage({ id: title }),
                              subtitle: subtitle,
                              imgSrc: val.image,
                              imgAlt: '',
                              color,
                              onClick: this.handleData.bind(this, val)
                            };
                          })
                        : []
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPortfolioView;
