import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../store/control/duck';
import Pikto from '../../Pikto/Pikto';
import { ShortSubscriptions } from '../../../utils/user-subscription';
import { hasCampaignCode, hasReferralCode } from '../../../utils/user';
import _ from 'lodash';
import {
  FINANCIAL_CHARTS_NOVA_CONTENTS,
  FINANCIAL_CHARTS_SECTION_ONE_CONTENTS,
  FINANCIAL_CHARTS_SECTION_TWO_CONTENTS,
  FINANCIAL_CHARTS_DEFAULT_ITEM
} from '../../../constants/financial-charts';
import './FinancialChartsDialog.scss';
import ContentsDialog from '../ContentsDialog';
import { Button, Grid } from '@material-ui/core';

const MoreButtonGridStyle = {
  textAlign: 'right'
};

const FinancialChartsDialog = props => {
  const {
    history,
    intl,
    userDetails,
    subscriptions,
    financialChartsDialog,
    financialConcepts,
    teamCampaignCodes,
    toggleDialog
  } = props;

  const isFreeTrial = new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();
  const shouldShowNOVASection = !isFreeTrial && hasCampaignCode(userDetails, teamCampaignCodes, 'NOVAFINCON');
  // const shouldShowBG = hasCampaignCode(userDetails, 'JAN22BG');
  // const shouldShowAL = hasCampaignCode(userDetails, 'JAN22AL');
  // const shouldShowCS = hasCampaignCode(userDetails, 'JAN22CS');
  //const shouldShowDemo = hasCampaignCode(userDetails, teamCampaignCodes, 'DEMO');

  const { closed, defaultItem } = financialChartsDialog || {};

  const close = () => {
    toggleDialog('financialCharts', { ...financialChartsDialog, closed: true });
  };

  const onClosed = () => {
    toggleDialog('financialCharts', false);
  };

  const more = () => {
    history.push('/hot_topic');
    close();
  };

  return (
    <ContentsDialog
      history={history}
      className="financial-charts-dialog"
      open={!!financialChartsDialog && !closed}
      onClose={close}
      onClosed={onClosed}
      title={intl.formatMessage({ id: 'fin-charts' })}
      type="financial-charts"
      sections={[
        shouldShowNOVASection && {
          title: intl.formatMessage({ id: 'special-area' }),
          img: <img src="img/adviser-home-page/nova-slife.png" alt="nova" width="42" />,
          items: FINANCIAL_CHARTS_NOVA_CONTENTS,
          special: true
        },
        // shouldShowBG && {
        //   title: intl.formatMessage({ id: 'special-area' }),
        //   img: <img src="img/adviser-home-page/synergySmall.png" alt="synergy" width="42" />,
        //   items: NOVA_CONTENTS,
        //   special: true
        // },
        // shouldShowAL && {
        //   title: intl.formatMessage({ id: 'special-area' }),
        //   img: <img src="img/adviser-home-page/eandw.png" alt="eh" width="42" />,
        //   items: NOVA_CONTENTS,
        //   special: true
        // },
        // shouldShowCS && {
        //   title: intl.formatMessage({ id: 'special-area' }),
        //   img: <img src="img/adviser-home-page/universeSmall.png" alt="universe" width="42" />,
        //   items: NOVA_CONTENTS,
        //   special: true
        // },
        // shouldShowDemo && {
        //   title: intl.formatMessage({ id: 'special-area' }),
        //   img: <img src="img/adviser-home-page/demoSmall.png" alt="demo" width="42" />,
        //   items: NOVA_CONTENTS,
        //   special: true
        // },
        ...(financialConcepts || []).map(financialConcept => {
          const nameLocale = _.get(financialConcept.name, intl.locale, '');
          return {
            title: nameLocale,
            img: <img src={financialConcept.iconImg} alt={nameLocale} width="42" />,
            items: (financialConcept.items || []).map(item => {
              const piktoChartIdLocale = _.get(item.piktoChartIds, intl.locale);
              if (piktoChartIdLocale) {
                const titleLocale = _.get(item.title, intl.locale);
                return {
                  id: piktoChartIdLocale,
                  title: titleLocale,
                  type: 'pikto',
                  color: item.color,
                  render: () => <Pikto uid={piktoChartIdLocale} />
                };
              } else {
                return null;
              }
            }),
            special: true
          };
        }),
        {
          title: intl.formatMessage({ id: 'fin-charts-sub-1' }),
          items: FINANCIAL_CHARTS_SECTION_ONE_CONTENTS
        },
        {
          title: intl.formatMessage({ id: 'fin-charts-sub-2' }),
          items: FINANCIAL_CHARTS_SECTION_TWO_CONTENTS
        }
      ]}
      defaultItem={defaultItem || FINANCIAL_CHARTS_DEFAULT_ITEM}
      extras={
        <Grid item style={MoreButtonGridStyle}>
          <Button color="primary" variant="outlined" onClick={more}>
            {intl.formatMessage({ id: 'More' })}
          </Button>
        </Grid>
      }
    />
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    subscriptions: state.userSubscription.subscriptions,
    financialChartsDialog: state.control.financialChartsDialog,
    financialConcepts: state.functions.financialConcepts,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {
    toggleDialog
  }
)(FinancialChartsDialog);

export default injectIntl(container);
