import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';

const ImportUnexpectedErrorDialog = props => {
  const { intl, open, refId, onClose } = props;

  return (
    <Dialog open={open} TransitionComponent={SlideTransition} fullWidth={true}>
      <DialogTitle>{intl.formatMessage({ id: 'Something went wrong' })}</DialogTitle>
      <DialogContent>
        <Typography>{intl.formatMessage({ id: 'error-msg' })}</Typography>
        <Typography>Ref ID: {refId}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ImportUnexpectedErrorDialog);
