import React from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import './PolicyPickerInfo.scss';
import FigureUnitTypo from '../../../../components/FigureUnitTypo/FigureUnitTypo';
// import { useTheme } from "@material-ui/core/styles";
// import { formatNumber } from "../../../../utils/formatNumber";

const Style = {
  padding: 4
};

const ContainerStyle = {
  padding: 4,
  border: '1px solid var(--primary-main)',
  borderRadius: 8,
  backgroundColor: '#3966f808'
};

const FullWidthStyle = {
  width: '100%'
};

const BottomGridContainerStyle = {};

const LabelValueGridStyle = {
  flexGrow: 1
};

const ButtonGridStyle = {
  paddingBottom: 4
};

const PolicyPickerInfo = props => {
  const { intl, figures, button } = props;
  // const theme = useTheme();

  return (
    <div className="policy-picker-info" style={Style}>
      <Grid container direction="column" spacing={1} style={ContainerStyle}>
        <Grid item style={FullWidthStyle}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography>{intl.formatMessage({ id: 'analy-picker-title' })}</Typography>
            </Grid>
            <Grid item>{button}</Grid>
          </Grid>
        </Grid>
        <Grid item style={FullWidthStyle}>
          <Grid container alignItems="flex-end" style={BottomGridContainerStyle}>
            {(figures || []).map((figure, index) => {
              return (
                <Grid style={LabelValueGridStyle} key={index}>
                  {figure ? (
                    <Grid container direction="column" alignItems="flex-start">
                      <Grid item>
                        <Typography color="textSecondary">{figure.label}</Typography>
                      </Grid>
                      <Grid item>
                        <FigureUnitTypo value={figure.value.value} unit={figure.value.unit} fontSizeRem={2} />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(PolicyPickerInfo);
