import VCard from 'vcard-creator';
import { getReferralLink } from './referral';

export const getVCardString = (userDetails) => {
  const { name, workEmail, phoneNumber, phoneNumber2, company } = userDetails || {};

  const vCard = new VCard();

  if (name) {
    vCard.addName('', name);
  }

  if (workEmail) {
    vCard.addEmail(workEmail);
  }

  if (phoneNumber) {
    vCard.addPhoneNumber(phoneNumber);
  }

  if (phoneNumber2) {
    vCard.addPhoneNumber(phoneNumber2);
  }

  if (company) {
    vCard.addCompany(company);
  }

  const url = getReferralLink({ targetAdviser: userDetails, userDetails: userDetails });
  if (url) {
    vCard.addURL(url);
  }

  return vCard.toString();
};

export const getVCardStringWithPhoto = async (userDetails, callback) => {
  const { name, workEmail, phoneNumber, phoneNumber2, company, avatar } = userDetails || {};

  const vCard = new VCard();

  if (name) {
    vCard.addName('', name);
  }

  if (workEmail) {
    vCard.addEmail(workEmail);
  }

  if (phoneNumber) {
    vCard.addPhoneNumber(phoneNumber);
  }

  if (phoneNumber2) {
    vCard.addPhoneNumber(phoneNumber2);
  }

  if (company) {
    vCard.addCompany(company);
  }

  const url = getReferralLink({ targetAdviser: userDetails, userDetails: userDetails });
  if (url) {
    vCard.addURL(url);
  }

  if (avatar) {
    const reader = new FileReader();
    const res = await fetch(`${avatar}?t=${new Date().getTime()}`);
    const blob = await res.blob();
    const f = new File([blob], 'avatar', { type: blob.type });
    reader.readAsDataURL(f);
    reader.onloadend = () => {
      vCard.addPhoto(reader.result.substring(reader.result.indexOf(',') + 1), blob.type.split('/')[1].toUpperCase());
      callback(vCard.toString());
    };
  } else {
    callback(vCard.toString());
  }
};
