import React, { useState, useEffect, Fragment } from 'react';
import TargetEditorModal from './../../Dashboard/partials/business/TargetEditorModal/TargetEditorModal';
import BusinessTargetEditorModal from './../../Dashboard/partials/business/setBusinessTargetModal/BusinessTargetModal.js';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { getSearchObject } from '../../../../utils/router';
import FunctionCardsView from '../../../../components/FunctionCard/FunctionCardsView';

const SetTargetView = props => {
  const { location, intl, userDetails, fetchAdviserBusiness, renderHeaderButton } = props;

  const [targetEditorDialog, setTargetEditorDialog] = useState(false);
  const [quarterTargetDialog, setQuarterTargetDialog] = useState(false);

  const openTargetEditorDialog = () => setTargetEditorDialog(true);
  const closeTargetEditorDialog = () => setTargetEditorDialog(false);
  const openQuarterTargetDialog = () => setQuarterTargetDialog(true);
  const closeQuarterTargetDialog = () => setQuarterTargetDialog(false);

  useEffect(() => {
    fetchAdviserBusiness();
  }, [fetchAdviserBusiness]);

  useEffect(() => {
    renderHeaderButton(false);
  }, [renderHeaderButton]);

  useEffect(() => {
    const searchObject = getSearchObject({ location });
    const { biz } = searchObject || {};
    if (!!biz) {
      setQuarterTargetDialog(true);
    }
  }, [location]);

  const getItems = ({ intl, history }) => [
    {
      color: '#5fc3d2',
      imgSrc: 'img/visionboard.png',
      imgAlt: 'vision-board',
      text: intl.formatMessage({ id: 'Vision Board' }),
      subtitle: intl.formatMessage({ id: 'vision_sub' }),
      onClick: openTargetEditorDialog
    },
    {
      color: '#3999f8',
      imgSrc: 'img/qtarget.png',
      imgAlt: 'business-target',
      text: intl.formatMessage({ id: 'Business Target' }),
      subtitle: intl.formatMessage({ id: 'bizTarget_sub' }),
      onClick: openQuarterTargetDialog
    }
  ];

  return (
    <Fragment>
      <TargetEditorModal
        intl={intl}
        remindMe={userDetails.remindMe}
        remindTarget={userDetails.remindTarget}
        open={targetEditorDialog}
        onClose={closeTargetEditorDialog}
        loadingPhase={userDetails !== undefined}
      />
      <BusinessTargetEditorModal
        {...props}
        intl={intl}
        open={quarterTargetDialog}
        onClose={closeQuarterTargetDialog}
        updateSwipe={true}
      />

      <FunctionCardsView
        className="door-opener"
        getItems={getItems}
        prepend={
          <AladdinHint
            containerProps={{ justify: 'center' }}
            title={intl.formatMessage({ id: 'aladdin-set-target-title' })}
            helpCenter="c98"
          />
        }
      />
    </Fragment>
  );
};

export default SetTargetView;
