import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';
import { Dialog, DialogContent, Grid, useMediaQuery } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReferralQRCodeContent from './ReferralQRCodeContent/ReferralQRCodeContent';
import { toggleDialog } from '../../store/control/duck';
import CustomTopNavigation from '../CustomTopNavigation/CustomTopNavigation';
import { isAdviserType } from '../../utils/user';
import { isMobile } from 'react-device-detect';

const QRDialogPaperStyle = {
  // background: 'transparent',
  // boxShadow: 'none',
};

const QRDialogContentStyle = {
  padding: 0
};

const ReferralQRCodeDialog = props => {
  const { history, intl, userDetails, referralQRCodeDialog, toggleDialog } = props;
  const isWide = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isAdviser = isAdviserType(userDetails);

  const {
    closed,
    shouldShowWallet,
    referLinkSearch,
    customCTAMessage,
    defaultMessageIntlId,
    defaultMessageExtras
  } = referralQRCodeDialog || {};

  const close = () => toggleDialog('referralQRCode', { ...referralQRCodeDialog, closed: true });
  const onClosed = () => toggleDialog('referralQRCode', false);

  return (
    <Dialog
      className="referral-qr-code-dialog"
      open={!!referralQRCodeDialog && !closed}
      onClose={close}
      onExited={onClosed}
      fullWidth={true}
      fullScreen={!isWide}
      maxWidth="xs"
      TransitionComponent={SlideTransition}
      PaperProps={{
        style: {
          ...QRDialogPaperStyle,
          width: isWide ? 'calc(100% - 32px)' : undefined,
          margin: isWide ? 16 : undefined
        }
      }}
    >
      <DialogContent style={QRDialogContentStyle}>
        <Grid container>
          {!isWide && (
            <Grid item xs={12}>
              <CustomTopNavigation title={intl.formatMessage({ id: 'Referral' })}>
                <span onClick={close} className="back_arrow">
                  <ArrowBackIcon />
                </span>
              </CustomTopNavigation>
            </Grid>
          )}
          <Grid item xs={12} style={!isWide ? { marginTop: 50 } : undefined}>
            <ReferralQRCodeContent
              history={history}
              location={history.location}
              style={isWide ? { paddingTop: 0 } : undefined}
              wallet={isAdviser && shouldShowWallet}
              customCTAMessage={customCTAMessage}
              referLinkSearch={referLinkSearch}
              defaultMessageIntlId={defaultMessageIntlId || 'referral-card-message'}
              defaultMessageExtras={defaultMessageExtras || { name: userDetails?.name }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    referralQRCodeDialog: state.control.referralQRCodeDialog
  }),
  { toggleDialog }
)(ReferralQRCodeDialog);

export default injectIntl(container);
