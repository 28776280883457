import Rx from 'rxjs/Rx';
import { combineEpics } from 'redux-observable';
import * as api from './api';
import { UPDATE_TEMPLATE } from '../user/duck';

export const UPDATE_ADVISER_TEMPLATE = 'portfoplus/advisor/UPDATE_ADVISOR_TEMPLATE';
export const UPDATE_ADVISER_TEMPLATE_SUCCESS = 'portfoplus/advisor/UPDATE_ADVISOR_TEMPLATE_SUCCESS';
export const UPDATE_ADVISER_TEMPLATE_ERROR = 'portfoplus/advisor/UPDATE_ADVISOR_TEMPLATE_ERROR';

export const ADD_ADVISER_TEMPLATE = 'portfoplus/advisor/ADD_ADVISOR_TEMPLATE';
export const ADD_ADVISER_TEMPLATE_SUCCESS = 'portfoplus/advisor/ADD_ADVISOR_TEMPLATE_SUCCESS';
export const ADD_ADVISER_TEMPLATE_ERROR = 'portfoplus/advisor/ADD_ADVISOR_TEMPLATE_ERROR';

export const DELETE_ADVISER_TEMPLATE = 'portfoplus/advisor/DELETE_ADVISOR_TEMPLATE';
export const DELETE_ADVISER_TEMPLATE_SUCCESS = 'portfoplus/advisor/DELETE_ADVISOR_TEMPLATE_SUCCESS';
export const DELETE_ADVISER_TEMPLATE_ERROR = 'portfoplus/advisor/DELETE_ADVISOR_TTEMPLATE_ERROR';

export const DELETE_ALL_COMPLETED_TEMPLATE = 'portfoplus/advisor/DELETE_ALL_COMPLETED_TEMPLATE';
export const DELETE_ALL_COMPLETED_TEMPLATE_SUCCESS = 'portfoplus/advisor/DELETE_ALL_COMPLETED_TEMPLATE_SUCCESS';
export const DELETE_ALL_COMPLETED_TEMPLATE_ERROR = 'portfoplus/advisor/DELETE_ALL_COMPLETED_TEMPLATE_ERROR';

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = { error: null, submitting: false, template: [] }, action = {}) {
  switch (action.type) {
    case DELETE_ADVISER_TEMPLATE:
    case DELETE_ALL_COMPLETED_TEMPLATE:
    case ADD_ADVISER_TEMPLATE:
    case UPDATE_ADVISER_TEMPLATE: {
      return { ...state, isSubmitting: true, error: null };
    }

    case ADD_ADVISER_TEMPLATE_SUCCESS:
    case DELETE_ALL_COMPLETED_TEMPLATE_SUCCESS:
    case DELETE_ADVISER_TEMPLATE_SUCCESS:
    case UPDATE_ADVISER_TEMPLATE_SUCCESS: {
      if (!action.payload.success) {
        return { ...state, error: action.payload.message, isSubmitting: false };
      }
      return { ...state, isSubmitting: false };
    }

    case ADD_ADVISER_TEMPLATE_ERROR:
    case DELETE_ALL_COMPLETED_TEMPLATE_ERROR:
    case DELETE_ADVISER_TEMPLATE_ERROR:
    case UPDATE_ADVISER_TEMPLATE_ERROR: {
      const { payload } = action;
      return { ...state, error: payload.error, isSubmitting: false };
    }
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const updateAdviserTemplate = data => {
  return { type: UPDATE_ADVISER_TEMPLATE, payload: data };
};

export const addAdviserTemplate = data => {
  return { type: ADD_ADVISER_TEMPLATE, payload: data };
};

export const deleteAdviserTemplate = data => {
  return { type: DELETE_ADVISER_TEMPLATE, payload: data };
};

export const deleteAllTemplate = () => {
  return { type: DELETE_ALL_COMPLETED_TEMPLATE };
};

/***********************************
 * Epics
 ***********/
const addAdviserTemplateEpic = action$ =>
  action$.ofType(ADD_ADVISER_TEMPLATE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.addAdviserTemplate(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TEMPLATE,
                payload
              },
              {
                type: ADD_ADVISER_TEMPLATE_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: ADD_ADVISER_TEMPLATE_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: ADD_ADVISER_TEMPLATE_ERROR,
          payload: { error }
        })
      );
  });

const updateAdviserTemplateEpic = action$ =>
  action$.ofType(UPDATE_ADVISER_TEMPLATE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateAdviserTemplate(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TEMPLATE,
                payload
              },
              {
                type: UPDATE_ADVISER_TEMPLATE_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: UPDATE_ADVISER_TEMPLATE_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_ADVISER_TEMPLATE_ERROR,
          payload: { error }
        })
      );
  });

const deleteAdviserTemplateEpic = action$ =>
  action$.ofType(DELETE_ADVISER_TEMPLATE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteAdviserTemplate(action.payload))
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TEMPLATE,
                payload
              },
              {
                type: DELETE_ADVISER_TEMPLATE_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: DELETE_ADVISER_TEMPLATE_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_ADVISER_TEMPLATE_ERROR,
          payload: { error }
        })
      );
  });

const deleteAllTemplateEpic = action$ =>
  action$.ofType(DELETE_ALL_COMPLETED_TEMPLATE).mergeMap(action => {
    return Rx.Observable.fromPromise(api.deleteAllTemplate())
      .flatMap(payload =>
        payload.success
          ? [
              {
                type: UPDATE_TEMPLATE,
                payload
              },
              {
                type: DELETE_ALL_COMPLETED_TEMPLATE_SUCCESS,
                payload
              }
            ]
          : [
              {
                type: DELETE_ALL_COMPLETED_TEMPLATE_SUCCESS,
                payload
              }
            ]
      )
      .catch(error =>
        Rx.Observable.of({
          type: DELETE_ALL_COMPLETED_TEMPLATE_ERROR,
          payload: { error }
        })
      );
  });

export const templateEpic = combineEpics(
  addAdviserTemplateEpic,
  updateAdviserTemplateEpic,
  deleteAdviserTemplateEpic,
  deleteAllTemplateEpic
);
