import React from 'react';
import lottie from 'lottie-web';

export default class Lottie extends React.PureComponent {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.anim = undefined;
    this.animLoading = undefined;
  }

  componentDidMount() {
    const { initialFrame, options, onComplete } = this.props;

    this.animLoading = new Promise(async (resolve, reject) => {
      // if it is an url string
      if (typeof options.animationData === 'string') {
        try {
          options.animationData = await (await fetch(options.animationData)).json();
        } catch (error) {
          console.log(error);
          reject();
          return;
        }
      }

      this.anim = lottie.loadAnimation({
        container: this.container.current,
        ...options
      });

      if (onComplete) {
        this.anim.addEventListener('complete', onComplete);
      }

      if (initialFrame) {
        this.anim.goToAndStop(initialFrame, true);
      }

      resolve(this.anim);
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.anim) {
      if (this.animLoading) {
        try {
          await this.animLoading;
        } catch (error) {}
      } else {
        return;
      }
    }

    if (prevProps.isPlaying && !this.props.isPlaying) {
      if (this.props.assistantExpand) {
        this.anim.stop();
      } else {
        this.anim.pause();
      }
    }
    if (!prevProps.isPlaying && this.props.isPlaying) {
      this.anim.play();
    }
  }

  animationDidLoad = async () => {
    if (this.animLoading) {
      return await this.animLoading;
    }
    return this.anim;
  };

  onClick = () => (this.props.onClick ? this.props.onClick() : null);

  render() {
    const { className, initialFrame, options, onComplete, isPlaying, assistantExpand, ...rest } = this.props;
    return (
      <div onClick={this.onClick} className={`lottie ${className ? className : ''}`} ref={this.container} {...rest} />
    );
  }
}
