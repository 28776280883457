export const validatePlanData = (data, categoryType, subCategoryType) => {
  const {
    companyName,
    feature,
    planType,
    policyName,
    insurer,
    type,
    guaranteeValue,
    isAnnuity,
    vhisPlan,
    coverageType,
    roomClass
  } = data || {};

  const err = {};

  if (categoryType.title === 'Company') {
    if (!companyName) {
      err.companyName = 'Enter Workplace Name.';
    }
  }

  if (categoryType.title !== 'General' && categoryType.title !== 'Company') {
    if (!feature) {
      err.feature = 'Please pick one.';
    }
  }

  if (categoryType.title === 'Personal' || subCategoryType.title === 'Saving' || subCategoryType.title === 'Accident') {
    if (!planType) {
      err.planType = 'Please pick one.';
    }
  }

  if (categoryType.title === 'Personal' || categoryType.title === 'General') {
    if (!policyName) {
      err.policyName = 'Enter Policy Name.';
    }
  }
  if (!insurer) {
    err.insurer = 'Enter Insurer';
  }

  if (subCategoryType.title === 'Other') {
    if (!type) {
      err.type = 'Enter Type.';
    }
  }
  if (categoryType.title === 'General' && subCategoryType.title === 'Saving') {
    if (!guaranteeValue) {
      err.guaranteeValue = 'Please pick one.';
    }
    if ((guaranteeValue === 'Yes' || guaranteeValue === 'No Idea') && !isAnnuity) {
      err.isAnnuity = 'Please pick one.';
    }
  }
  if (categoryType.title === 'Personal' && subCategoryType.title === 'Medical') {
    if (!vhisPlan) {
      err.vhisPlan = 'Please choose VHIS plan';
    }
  }

  if (categoryType.title !== 'General') {
    if (subCategoryType.title === 'Medical') {
      if (!coverageType) {
        err.coverageType = 'Please choose coverage type.';
      }
      if (!roomClass) {
        err.roomClass = 'Please choose room class.';
      }
    }
  }

  return err;
};

export const validatePaymentData = (data, categoryType, subCategoryType) => {
  const { currency, lifeCoverage, premium, naPremium, sumInsured, paymentMethod, levelPremium, frequency } = data || {};
  const err = {};

  if (!currency) {
    err.currency = 'Please choose currency';
  }
  if (!lifeCoverage && lifeCoverage !== 0) {
    if (categoryType.title !== 'General') {
      err.lifeCoverage = 'Enter Life Coverage.';
    } else {
      err.lifeCoverage = 'Enter Coverage Amount.';
    }
  }
  if (!premium && premium !== 0 && !naPremium) {
    err.premium = 'Enter Premium.';
  }
  if (subCategoryType.title === 'Critical Illness') {
    if (!sumInsured) {
      err.sumInsured = 'Enter C.I. Coverage Amount.';
    }
  }
  if (categoryType.title !== 'Company' && categoryType.title !== 'General') {
    if (!levelPremium) {
      err.levelPremium = 'Please pick one.';
    }
  }
  if (!frequency && !naPremium) {
    err.frequency = 'Please pick one.';
  }

  return err;
};
