import { fetch } from '../../utils';
import _ from 'lodash';

const thisFetch = (url, options) =>
  fetch(
    url,
    _.merge(
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      },
      options
    )
  )
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return error;
    });

export const getUserSubscriptionApi = () => {
  if (process.env.NODE_ENV === 'development' && process.env.WITHOUT_SUBSCRIPTION === 'true') {
    return {
      _id: '5e45000f50c40a56e828ff33',
      subscriptions: [
        {
          _id: '5e45000f50c40a56e828ff34',
          subscriptionPlan: {
            intervalCount: 1,
            order: 0,
            color: '#3966f8',
            _id: '5e3269934878ed4364c09445',
            type: 'core',
            nickname: 'Pro',
            amount: 338800,
            currency: 'hkd',
            interval: 'year',
            stripeProductId: 'prod_G5itirI78WYmSg',
            stripePlanId: 'price_1Oo1N1HNO6xAq419iMnpvisv',
            active: true,
            displayName: {
              en: 'Pro',
              zh: 'Pro',
              zhHantHK: 'Pro'
            }
          },
          paymentType: 'onetime',
          paymentChannel: 'others',
          paid: true,
          active: true,
          autoExtend: false,
          payments: [],
          createType: 'create',
          startedAt: '2019-02-27T16:00:00.000Z',
          expiredAt: '2029-02-28T16:00:00.000Z',
          createdAt: '2020-02-13T07:51:43.169Z',
          updatedAt: '2020-02-13T07:51:43.169Z'
        }
      ],
      createdAt: '2020-02-13T07:51:43.164Z',
      updatedAt: '2020-02-13T07:51:43.164Z'
    };
  } else {
    return thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions`);
  }
};

export const createSubscriptionApi = createDTO => {
  return thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions`, {
    method: 'POST',
    body: JSON.stringify(createDTO)
  });
};

export const paySubscription = (subscriptionId, stripeTokenId) => {
  return thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/${subscriptionId}/pay`, {
    method: 'POST',
    body: JSON.stringify({ tokenId: stripeTokenId })
  });
};

export const cancelSubscriptionApi = subscriptionId =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/${subscriptionId}/cancel`, {
    method: 'PATCH'
  });

export const removeUnpaidSubscriptionApi = subscriptionId =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/${subscriptionId}`, {
    method: 'DELETE'
  });

export const endSubscriptionNowApi = subscriptionId =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/${subscriptionId}/endNow`, {
    method: 'PATCH'
  });

export const getCouponApi = (couponId, subscriptionPlanId) =>
  thisFetch(
    `${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/coupons/${encodeURIComponent(couponId)}?${subscriptionPlanId ? `subscriptionPlanId=${subscriptionPlanId}` : ''
    }`
  );
export const getAdviserSubscriptionNickname = advisorId =>
  thisFetch(
    `${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/check-adviser${advisorId ? `/${advisorId}` : ''}`
  );

export const isUserSubscriptionExpired = () => {
  return thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/isUserSubscriptionExpired`);
};

export const getExclusiveCouponsApi = subscriptionPlanId =>
  thisFetch(
    `${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/coupons?${subscriptionPlanId ? `subscriptionPlanId=${subscriptionPlanId}` : ''
    }`
  );

export const getChangeablePlansApi = (currentSubscriptionId) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/${currentSubscriptionId}/getChangeablePlans`, {
    method: 'GET'
  });

export const changePlanApi = (currentSubscriptionId, newSubscriptionPlanId, couponId, option, paymentMethodId, authentication) =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/${currentSubscriptionId}/changePlan`, {
    method: 'PUT',
    body: JSON.stringify({ newSubscriptionPlanId, couponId, option, paymentMethodId, authentication })
  });

export const cancelPendingSubscriptionChangeApi = () =>
  thisFetch(`${process.env.PFPLUS_SUBSCRIPTION_SERVICE_URL}/user-subscriptions/cancelPendingSubscriptionChange`, {
    method: 'GET'
  });
