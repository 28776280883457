import _ from 'lodash';
import moment from 'moment';

const getEntryPath = path => (path ? `${path}.` : '');

export const remindMeSort = (entries, clientPath) => {
  return _.sortBy(entries, entry => _.get(entry, `${getEntryPath(clientPath)}factsheetId.fsReminderDate`)).reverse();
};

export const birthdaySort = (entries, clientPath) => {
  const nowMoment = moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
  return _.sortBy(entries, entry => {
    const dobMoment = moment.parseZone(_.get(entry, `${getEntryPath(clientPath)}factsheetId.dob`));
    const thisYearDobMoment = dobMoment.clone().year(nowMoment.year());
    let nextDobMoment;

    if (thisYearDobMoment.isBefore(nowMoment)) {
      nextDobMoment = thisYearDobMoment.clone().add(1, 'year');
    } else {
      nextDobMoment = thisYearDobMoment.clone();
    }
    return nextDobMoment.format('YYYY/MM/DD');
  });
};

export const paidUpPolicySort = (entries, clientPath) => {
  return _.sortBy(entries, entry =>
    _.min(_.get(entry, `${getEntryPath(clientPath)}paidUpPolicies`, []).map(policy => policy.paidUpDate))
  );
};

export const trackingSort = (entries, clientPath) => {
  return _.sortBy(entries, entry => _.get(entry, `${getEntryPath(clientPath)}trackingId.createdDate`));
};

export const isClientRemindMe = (client, adviserEmail) => {
  const isActiveRemindMe = ({ fsReminderActive, fsReminderDate }) => {
    if (fsReminderActive && fsReminderDate) {
      const fsReminderDateMoment = moment(fsReminderDate, 'YYYY-MM-DD');
      const nowMoment = moment();
      const difference = fsReminderDateMoment.diff(nowMoment, 'month', true);
      if ((difference >= 0 && difference <= 1) || fsReminderDateMoment.isSame(nowMoment, 'day')) {
        return true;
      }
    }
    return false;
  };

  //condition of filter data for upcoming month
  if (client) {
    if (client.advisorDetails && client.advisorDetails.length > 0) {
      const adviserDetail = client.advisorDetails.find(
        adviserDetail => !adviserEmail || adviserDetail.email === adviserEmail
      );
      if (adviserDetail && adviserDetail.connection) {
        if (isActiveRemindMe(client.factsheetId ? client.factsheetId : adviserDetail.factsheetId)) {
          return true;
        }
      }
    } else {
      if (client.inactiveUser && client.factsheetId) {
        if (isActiveRemindMe(client.factsheetId)) {
          return true;
        }
      }
    }
  }

  return false;
};
