import React, { useEffect, useState } from 'react';
import BottomSheetOrDialog from '../../BottomSheetOrDialog/BottomSheetOrDialog';
import { StripeElementWrapper } from '../../StripeElement/StripeElementWrapper';
import { setupPaymentMethodApi } from '../../../store/stripe/api';

const UpdatePaymentMethod = props => {
    const { intl, userDetails, stripePromise, open, onClose, subscription, subscriptionPlan } = props;

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        async function getClientSecret() {
            let result = await setupPaymentMethodApi({ type: "change_payment_method", subscriptionId: subscription.stripeSubscriptionId });
            setClientSecret(result.clientSecret);
        }

        if (open) {
            getClientSecret();
        } else {
            setClientSecret("");
        }
    }, [open]);

    return (
        <BottomSheetOrDialog
            className="policy-review-edit-layout-dialog"
            open={open}
            onClose={onClose}
            header={intl.formatMessage({ id: 'update-payment-method' })}
            content={
                <StripeElementWrapper
                    intl={intl}
                    stripePromise={stripePromise}
                    clientSecret={clientSecret}
                    userDetails={userDetails}
                    buttonText={intl.formatMessage({ id: "Update" })}
                    isSetup={true}
                    isRecurring={true}
                    paymentDescription={intl.formatMessage({ id: "portfoplus-subscription" })}
                    billingAgreement={intl.formatMessage({ id: 'billing-agreement' })}
                    recurringAmount={subscriptionPlan.amount - subscription.currentCouponValue}
                    recurringLabel={intl.formatMessage({ id: "subscription" })}
                    recurringStartDate={subscription.expiredAt}
                    recurringIntervalUnit={subscriptionPlan.interval === "week" ? "day" : subscriptionPlan.interval}
                    recurringIntervalCount={subscriptionPlan.interval === "week" ? subscriptionPlan.intervalCount * 7 : subscriptionPlan.intervalCount}
                    completePay={onClose}
                />
            }
            actions={[]}
            BottomSheetProps={{
                expandOnContentDrag: false,
                disableAutoDismiss: true
            }}
            DialogProps={{
                maxWidth: 'xs',
                fullWidth: true
            }}
            DialogParams={{ dialogTitleCloseButton: true }}
        />
    );
};

export default UpdatePaymentMethod;
