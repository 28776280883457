import React from 'react';
import { Container, Grid } from '@material-ui/core';
import FunctionCardsContainer from './FunctionCardsContainer';
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";

const FunctionCardsView = props => {
  const { intl, history, className, getItems, prepend, append } = props;

  const items = getItems({ intl, history });

  return (
    <Container className={`nav-pad${className ? ` ${className}` : ''}`}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <FunctionCardsContainer items={items} prepend={prepend} append={append} />
        </Grid>
      </Grid>
    </Container>
  );
};


export default injectIntl(withRouter(FunctionCardsView));
