import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Doughnut } from 'react-chartjs-2';
import _ from 'lodash';
import PVPHorizontalBar from '../PVPChartsView/PVPHorizontalBar';
import React from 'react';
import { getUserCurrency } from '../../../../utils/user';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';

const SavingPolicyStatistics = props => {
  const { intl, userDetails, savingData, PVPChartDataSaving, mySwipeableViews } = props;

  const currency = getUserCurrency(userDetails);

  const onChangeExpansionPanel = event => {
    event.persist();
    setTimeout(() => {
      if (mySwipeableViews?.current) {
        mySwipeableViews.current.adjustSlideHeights();
      }
    }, 413);
  };

  const {
    totalWithGuarantee: premiumGuarantee = 0,
    totalWithILAS: premiumILAS = 0,
    totalWithGuaranteeNoIdea: premiumNoIdea = 0,
    guaranteeCount = 0,
    noIdeaCount = 0,
    ilasCount = 0
  } = savingData || {};

  // DOUGHNUT CHART FOR SAVING START
  let savingDoughnutChart;
  if (_.get(savingData, 'count', 0) === 0) {
    savingDoughnutChart = {
      labels: [intl.formatMessage({ id: 'No Policy' })],
      datasets: [
        {
          data: ['0.1'],
          backgroundColor: ['#E9e9e9'],
          hoverBackgroundColor: ['#E9e9e9']
        }
      ]
    };
  } else {
    savingDoughnutChart = {
      labels: [
        intl.formatMessage({ id: 'Guarantee' }),
        intl.formatMessage({ id: 'ILAS' }),
        intl.formatMessage({ id: 'No Idea' })
      ],
      datasets: [
        {
          data: [
            guaranteeCount === 0 ? 0 : premiumGuarantee > 0 ? premiumGuarantee : 0.1,
            ilasCount === 0 ? 0 : premiumILAS > 0 ? premiumILAS : 0.1,
            noIdeaCount === 0 ? 0 : premiumNoIdea > 0 ? premiumNoIdea : 0.1
          ],
          backgroundColor: ['#8c1aff', '#BF80FF', '#E9e9e9'],
          hoverBackgroundColor: ['#8c1aff', '#BF80FF', '#E9e9e9']
        }
      ],
      borderWidth: [1]
    };
  }

  let savingDoughnutChartConfig = {
    cutoutPercentage: 80,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#34A0DC',
        fontSize: 10,
        boxWidth: 10,
        usePointStyle: true
      }
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false
      }
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.labels[tooltipItem.index] || '';
          if (label !== 'No Policy') {
            let dataset = data.datasets[tooltipItem.datasetIndex];
            if (label) {
              label += ': ';
            }
            label += intl.formatMessage({ id: currency }) + ' ';
            label += decimalToString(dataset.data[tooltipItem.index]);
            return label;
          }
          return label;
        }
      }
    }
  };

  let monthlyPremiumBarChartConfig = {
    title: {
      display: true,
      text: intl.formatMessage({ id: 'Monthly Premium' })
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontSize: 12,
        boxWidth: 11
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            min: 0,
            autoSkip: false,
            callback: function(label) {
              return formatNumber(label, intl);
            }
          },
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage({ id: 'Premium (HKD/Month)' })
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontSize: '10'
          },
          offset: true
        }
      ]
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ';
          }
          label += intl.formatMessage({ id: currency }) + ' ';
          label += decimalToString(tooltipItem.xLabel);

          return label;
        }
      }
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };

  // bar chart formation calculation
  let pvpBarChartHeight = 30 * Math.max(PVPChartDataSaving.length, 1) + 80;
  let monthlyPremiumBarChartData = { labels: [], datasets: [] };
  let premiumDataSet = [];
  let barColor = [];
  for (let i = 0; i < PVPChartDataSaving.length; i++) {
    monthlyPremiumBarChartData.labels.push(intl.formatMessage({ id: PVPChartDataSaving[i].insurerTitle[intl.locale] }));
    premiumDataSet.push(PVPChartDataSaving[i].totalPremiumAmount);
    if (PVPChartDataSaving[i].guaranteeValue === 'Yes') {
      barColor.push('#8c1aff');
    } else if (PVPChartDataSaving[i].guaranteeValue === 'No') {
      barColor.push('#BF80FF');
    } else if (PVPChartDataSaving[i].guaranteeValue === 'No Idea') {
      barColor.push('#E9e9e9');
    }
  }

  monthlyPremiumBarChartData.datasets.push({
    backgroundColor: barColor,
    borderColor: barColor,
    borderWidth: 1,
    categoryPercentage: 0.75,
    barPercentage: 1,
    data: premiumDataSet,
    maxBarThickness: 15,
    label: intl.formatMessage({ id: 'Premium' })
  });

  return (
    <ExpansionPanel
      defaultExpanded={false}
      className="analysis-expansion analysis-expansion-overview analysis-bottom exclude-in-image"
      style={{ backgroundColor: '#3966f808' }}
      onChange={onChangeExpansionPanel}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">
        {intl.formatMessage({ id: 'Policy-Statistics' })}
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className="analysis-expansion-detail">
        {/* REMOVE showing chart in analysis which both A and C can read
							<div className="cardAnalysis analysis-expansion-element text-center font16 greyL6">
								<SaveEarlyChart />
							</div> */}

        <div className="cardAnalysis analysis-expansion-element">
          <h4 className="text-center figureColor">
            {intl.formatMessage({
              id: 'Saving Premium Analysis'
            })}
          </h4>
          <div className="Outer" style={{ marginTop: '1px' }}>
            <Doughnut data={savingDoughnutChart} options={savingDoughnutChartConfig} />
            <div className="chart-title saving_chart">
              {intl.formatMessage({
                id: 'Policy Count'
              })}
            </div>
            <div className="policy-number saving_chart_count">{_.get(savingData, 'count', 0)}</div>
          </div>
          <div id="canvas-holder" className="Outer" style={{ height: pvpBarChartHeight, padding: 0 }}>
            <PVPHorizontalBar
              data={monthlyPremiumBarChartData}
              options={monthlyPremiumBarChartConfig}
              exampleValue={10000}
            />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    savingData: state.analysis.savingData,
    PVPChartDataSaving: state.analysis.PVPChartData.saving
  }),
  {}
)(SavingPolicyStatistics);

export default injectIntl(container);
