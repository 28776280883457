Object.defineProperty(exports, '__esModule', {
  value: true
});

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _draftJs = require('draft-js');

var _createDecorator = require('./createDecorator');

var _createDecorator2 = _interopRequireDefault(_createDecorator);

var _AlignmentTool = require('./AlignmentTool');

var _AlignmentTool2 = _interopRequireDefault(_AlignmentTool);

var _createStore = require('./utils/createStore');

var _createStore2 = _interopRequireDefault(_createStore);

var _buttonStyles = {
  buttonWrapper: 'draftJsEmojiPlugin__buttonWrapper__1Dmqh',
  button: 'draftJsEmojiPlugin__button__qi1gf',
  active: 'draftJsEmojiPlugin__active__3qcpF'
};

var _buttonStyles2 = _interopRequireDefault(_buttonStyles);

var _alignmentToolStyles = {
  alignmentTool: 'draftJsEmojiPlugin__alignmentTool__2mkQr'
};

var _alignmentToolStyles2 = _interopRequireDefault(_alignmentToolStyles);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var createSetAlignment = function createSetAlignment(contentBlock, _ref) {
  var getEditorState = _ref.getEditorState,
    setEditorState = _ref.setEditorState;
  return function(data) {
    var entityKey = contentBlock.getEntityAt(0);
    if (entityKey) {
      var editorState = getEditorState();
      var contentState = editorState.getCurrentContent();
      contentState.mergeEntityData(entityKey, _extends({}, data));
      setEditorState(_draftJs.EditorState.forceSelection(editorState, editorState.getSelection()));
    }
  };
};

exports.default = function() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var store = (0, _createStore2.default)({
    isVisible: false
  });

  var defaultAlignmentToolTheme = {
    buttonStyles: _buttonStyles2.default,
    alignmentToolStyles: _alignmentToolStyles2.default
  };

  var _config$theme = config.theme,
    theme = _config$theme === undefined ? defaultAlignmentToolTheme : _config$theme;

  var DecoratedAlignmentTool = function DecoratedAlignmentTool(props) {
    return _react2.default.createElement(_AlignmentTool2.default, _extends({}, props, { store: store, theme: theme }));
  };

  return {
    initialize: function initialize(_ref2) {
      var getReadOnly = _ref2.getReadOnly,
        getEditorState = _ref2.getEditorState,
        setEditorState = _ref2.setEditorState;

      store.updateItem('getReadOnly', getReadOnly);
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
    },
    decorator: (0, _createDecorator2.default)({ config: config, store: store }),
    blockRendererFn: function blockRendererFn(contentBlock, _ref3) {
      var getEditorState = _ref3.getEditorState,
        setEditorState = _ref3.setEditorState;

      var entityKey = contentBlock.getEntityAt(0);
      var contentState = getEditorState().getCurrentContent();
      var alignmentData = entityKey ? contentState.getEntity(entityKey).data : {};
      return {
        props: {
          alignment: alignmentData.alignment || 'default',
          setAlignment: createSetAlignment(contentBlock, {
            getEditorState: getEditorState,
            setEditorState: setEditorState
          })
        }
      };
    },
    AlignmentTool: DecoratedAlignmentTool
  };
};
