import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import { PhoneNumberInputWrapper } from '../../NewSignUp/contents/PhoneNumberInputWrapper';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import WhatsappQRCodeDialog from './WhatsappQRCodeDialog/WhatsappQRCodeDialog';
import _ from 'lodash';
import { updateUserDetails } from '../../../store/user/duck';
import { usePrevious } from '../../../utils/hooks';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import WhatsappQRCode from './WhatsappQRCode/WhatsappQRCode';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { LOADING } from '../../../constants/phase';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CropFreeIcon from '@material-ui/icons/CropFree';

const IconStyle = {
  color: '#3966f8'
};

const QRCodeCardStyle = {
  position: 'relative'
};

const RefreshQRCodeButtonStyle = {
  position: 'absolute',
  top: 14,
  right: 8,
  color: '#fff'
};

const GeneratedPanelSummaryStyle = {
  cursor: 'auto'
};

const PanelRightActionButtonStyle = {
  marginLeft: 'auto'
};

const ShareButtonGroupStyle = {
  width: '100%'
};

const getWhatsappGeneratedLink = (phone, text) => {
  const searchStr = new URLSearchParams({
    text: text
  }).toString();

  return `https://wa.me/${(phone || '').replace('+', '')}?${searchStr}`;
};

const WhatsappWizard = props => {
  const { intl, userDetails, updatePhase, updateUserDetails } = props;

  const defaultWhatsapp = _.get(userDetails, 'whatsapp', '');
  const defaultMessage = _.get(userDetails, 'whatsappMessage', '');
  const defaultCustomUrl = _.get(userDetails, 'customUrl', '');

  const shouldUseWideLayout = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const [mode, setMode] = useState(undefined);
  const [whatsapp, setWhatsapp] = useState(defaultWhatsapp);
  const [message, setMessage] = useState(defaultMessage);
  const [customUrl, setCustomUrl] = useState(defaultCustomUrl);
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(getWhatsappGeneratedLink(defaultWhatsapp, defaultMessage));
  const prevUpdatePhase = usePrevious(updatePhase);
  const whatsappQRCodeRef = useRef(null);
  const updateQRCodeTimeoutRef = useRef(null);

  const delayedUpdateQRCode = () => {
    if (updateQRCodeTimeoutRef.current) {
      clearTimeout(updateQRCodeTimeoutRef.current);
    }
    updateQRCodeTimeoutRef.current = setTimeout(refreshQRCode, 500);
  };

  const onChangePhoneNumber = event => {
    setWhatsapp(event.target.value);
    setGeneratedLink(getWhatsappGeneratedLink(event.target.value, message));
    delayedUpdateQRCode();
  };

  const onChangeMessage = event => {
    setMessage(event.target.value);
    setGeneratedLink(getWhatsappGeneratedLink(whatsapp, event.target.value));
    delayedUpdateQRCode();
  };

  const onChangeCustomUrl = event => {
    setCustomUrl(event.target.value);
    setGeneratedLink(event.target.value);
    delayedUpdateQRCode();
  };

  const saveWhatsappMessage = () => {
    updateUserDetails({ whatsappMessage: message });
  };

  const saveCustomUrl = () => {
    updateUserDetails({ customUrl: customUrl });
  };

  const send = () => {
    window.open(generatedLink, '_blank');
  };

  const openQrCodeDialog = () => {
    setQrCodeDialog(true);
  };

  const closeQrCodeDialog = () => {
    setQrCodeDialog(false);
  };

  const refreshQRCode = () => {
    if (whatsappQRCodeRef && whatsappQRCodeRef.current) {
      whatsappQRCodeRef.current.renderQR();
    }
  };

  const onChangeWhatsappPanel = () => {
    if (mode === 'whatsapp') {
      setMode(undefined);
    } else {
      setMode('whatsapp');
      setGeneratedLink(getWhatsappGeneratedLink(whatsapp, message));
    }
  };

  const onChangeLinkPanel = () => {
    if (mode === 'link') {
      setMode(undefined);
    } else {
      setMode('link');
      setGeneratedLink(customUrl);
    }
  };

  useEffect(() => {
    if (updatePhase === true && prevUpdatePhase === LOADING) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'file-saved-title' })), {
        className: 'new-toast'
      });
    }
  }, [updatePhase, prevUpdatePhase, intl]);

  return (
    <AppContentContainer>
      <WhatsappQRCodeDialog open={qrCodeDialog} onClose={closeQrCodeDialog} whatsapp={whatsapp} url={generatedLink} />

      <Grid container direction="column" spacing={2} style={{ padding: 16 }}>
        <Grid item>
          <AladdinHint title={intl.formatMessage({ id: 'aladdin-whatsapp-wizard-title' })} helpCenter="c99" />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={shouldUseWideLayout ? 6 : 12}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <ExpansionPanel expanded={mode === 'whatsapp'} onChange={onChangeWhatsappPanel}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <WhatsAppIcon style={IconStyle} />
                        </Grid>
                        <Grid item>
                          <Typography>{intl.formatMessage({ id: 'whatsapp-wizard-generator-title' })}</Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <TextField
                            className="phone-text-field"
                            name="phoneNumber"
                            label={intl.formatMessage({ id: 'WhatsApp' })}
                            value={whatsapp}
                            onChange={onChangePhoneNumber}
                            InputProps={{
                              inputComponent: PhoneNumberInputWrapper,
                              inputProps: {
                                intl: intl
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                            required={true}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            name="message"
                            label={intl.formatMessage({ id: 'message' })}
                            multiline={true}
                            rows={5}
                            value={message}
                            onChange={onChangeMessage}
                            onBlur={refreshQRCode}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container justify="space-between" spacing={1}>
                            <Grid item>
                              <Button variant="outlined" color="primary" onClick={saveWhatsappMessage}>
                                {intl.formatMessage({ id: 'Save' })}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={send}
                                style={PanelRightActionButtonStyle}
                              >
                                {intl.formatMessage({ id: 'send' })}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>

                <Grid item>
                  <ExpansionPanel expanded={mode === 'link'} onChange={onChangeLinkPanel}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <img src="img/adviser-home-page/shortcut_qr.svg" alt="qr-code" width={24} />
                        </Grid>
                        <Grid item>
                          <Typography>{intl.formatMessage({ id: 'whatsapp-wizard-link-qr-title' })}</Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            name="customUrl"
                            label={intl.formatMessage({ id: 'whatsapp-wizard-custom-url' })}
                            multiline={true}
                            rows={5}
                            value={customUrl}
                            onChange={onChangeCustomUrl}
                            onBlur={refreshQRCode}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container justify="space-between" spacing={1}>
                            <Grid item>
                              <Button variant="outlined" color="primary" onClick={saveCustomUrl}>
                                {intl.formatMessage({ id: 'Save' })}
                              </Button>
                            </Grid>
                            {!shouldUseWideLayout && customUrl && (
                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={openQrCodeDialog}
                                  style={PanelRightActionButtonStyle}
                                >
                                  {intl.formatMessage({ id: 'Generate' })}
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>

                <Grid item></Grid>

                <Grid item>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h5">
                        {intl.formatMessage({ id: 'whatsapp-wizard-generated-link-title' })}
                      </Typography>
                    </Grid>

                    {!shouldUseWideLayout && ((mode === 'whatsapp' && whatsapp) || (mode === 'link' && customUrl)) && (
                      <Grid item>
                        <Button color="primary" variant="outlined" onClick={openQrCodeDialog}>
                          {intl.formatMessage({ id: 'QR Code' })}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <ShareButtonGroup
                  title={generatedLink}
                  isCTA
                  style={ShareButtonGroupStyle}
                  CopyTextFieldProps={{
                    multiline: true
                  }}
                />
              </Grid>
            </Grid>
            {shouldUseWideLayout && (
              <Grid item xs={6}>
                <Card style={QRCodeCardStyle}>
                  <IconButton style={RefreshQRCodeButtonStyle} onClick={refreshQRCode}>
                    <RefreshRoundedIcon />
                  </IconButton>
                  <WhatsappQRCode whatsapp={whatsapp} url={generatedLink} qrCodeRef={whatsappQRCodeRef} />
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    updatePhase: state.user.updatePhase
  }),
  {
    updateUserDetails
  }
)(WhatsappWizard);

export default injectIntl(container);
