import React from 'react';
import { injectIntl } from 'react-intl';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const MyMuiPickersUtilsProvider = props => {
  const { intl, children, ...rest } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale} moment={moment} {...rest}>
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default injectIntl(MyMuiPickersUtilsProvider);
