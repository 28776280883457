import { connect } from 'react-redux';
import {
  getAdviserBlastNotification,
  updateAdviserBlastNotification,
  closeSendSuccessDialog
} from '../../../store/notificationCenter/duck';
import AdviserBlastView from './component';
import { injectIntl } from 'react-intl';

const AdviserBlast = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    adviserBlast: state.notificationCenter.adviserBlast,
    getAdviserBlastPhase: state.notificationCenter.getAdviserBlastPhase,
    clientsToSendNum: state.notificationCenter.clientsToSendNum,
    updateAdviserBlastPhase: state.notificationCenter.updateAdviserBlastPhase,
    showSendSuccessDialog: state.notificationCenter.showSendSuccessDialog,
    disableTodoPhase: state.notificationCenter.disableTodoPhase
  }),
  // Map actions to props
  {
    getAdviserBlastNotification,
    updateAdviserBlastNotification,
    closeSendSuccessDialog
  }
)(AdviserBlastView);

export default injectIntl(AdviserBlast);
