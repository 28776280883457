import React from 'react';
import { injectIntl } from 'react-intl';
import './Event.scss';
import Container from '@material-ui/core/Container';
import FunctionCardsContainer from '../../../components/FunctionCard/FunctionCardsContainer';
import { tracking } from '../../../utils/tracking';
import { hasCampaignCode } from '../../../utils/user';
import store from '../../../store';

const Events = props => {
    tracking('Events Page Accessed');
    const user = store.getState().user.userDetails;
    const teamCampaignCodes = store.getState().functions.campaignCodes

    const { intl, history } = props;

    // console.log('Events props: ', props, ' userde: ', user, ' teamCampaignCodes: ', teamCampaignCodes);

    const redirectHandler = item => () =>
        history.push({
            pathname: `/events/${item.id}`,
            state: { back: true, item: item },
        });

    const data = [
        {
            id: 'pfp-olympics-lazy_pack',
            title: intl.formatMessage({ id: 'pfp-olympics-1-title' }),
            // subtitle: intl.formatMessage({ id: 'pfp-olympics-1-subtitle' }),
            imgSrc: '/img/event/pfp-olympic/lazy_pack_banner.png',
            imgAlt: 'Lazy Pack',
            bannerSrc: '/img/event/pfp-olympic/lazy_pack_detail_banner.png',
            color: '#faea38',
            terms: intl.formatMessage({ id: 'pfp-olympics-1-terms' }),
            content: intl.formatMessage({ id: 'pfp-olympics-1-content' }),
            cta: intl.formatMessage({ id: 'pfp-olympics-1-cta' }),
            link: '/hot_topic',
            target: '_self',
            exclude: {
                campaignCodes: ['ENTCBB']
            },
            styles: { img: { width: 'auto' } }
        },
        {
            id: 'pfp-olympics-client',
            title: intl.formatMessage({ id: 'pfp-olympics-2-title' }),
            // subtitle: intl.formatMessage({ id: 'pfp-olympics-2-subtitle' }),
            imgSrc: '/img/event/pfp-olympic/client_banner.png',
            imgAlt: 'pfp-olympics-client',
            bannerSrc: '/img/event/pfp-olympic/client_detail_banner.png',
            color: '#47C2BF',
            link: '/client_personal_form',
            terms: intl.formatMessage({ id: 'pfp-olympics-2-terms' }),
            content: intl.formatMessage({ id: 'pfp-olympics-2-content' }),
            cta: intl.formatMessage({ id: 'pfp-olympics-2-cta' }),
            target: '_self',
            exclude: {
                campaignCodes: ['ENTCBB']
            },
            styles: { img: { width: 'auto' } }
        },
        {
            id: 'pfp-olympics-sosp',
            title: intl.formatMessage({ id: 'pfp-olympics-3-title' }),
            // subtitle: intl.formatMessage({ id: 'pfp-olympics-3-subtitle' }),
            imgSrc: '/img/event/pfp-olympic/sosp_banner.png',
            imgAlt: 'pfp-olympics-sosp',
            bannerSrc: '/img/event/pfp-olympic/sosp_detail_banner.png',
            color: '#0F4C81',
            link: '/add_demo_client',
            terms: intl.formatMessage({ id: 'pfp-olympics-3-terms' }),
            content: intl.formatMessage({ id: 'pfp-olympics-3-content' }),
            cta: intl.formatMessage({ id: 'pfp-olympics-3-cta' }),
            target: '_self',
            exclude: {
                campaignCodes: ['ENTCBB']
            },
            styles: { img: { width: 'auto' } }

        },
    ];

    // remove items that should be excluded
    const filteredData = data.filter(item => {
        if (item.exclude) {
            if (item.exclude.campaignCodes) {
                const checks = item.exclude.campaignCodes.map(code => {
                    return hasCampaignCode(user, teamCampaignCodes, code);
                }).filter(check => check !== undefined);
                return checks.length === 0;
            }
        }
        return true;
    });

    return (
        <div style={{ background: '#fff' }}>
            <Container className="partner-list">
                {
                    filteredData.length === 0 ?
                        <h1>No events available</h1>
                        : <FunctionCardsContainer
                            items={filteredData.map(item =>
                                ({ ...item, onClick: redirectHandler(item) }))} />}
            </Container>
        </div>
    );
};

export default injectIntl(Events);
