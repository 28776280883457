import { injectIntl } from 'react-intl';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import {
  HOBBIES_CHOICES,
  THEME_COLOR_CHOICES,
  THEME_COLOR_IMAGES,
  THEME_COLOR_PALETTES,
  THEME_STYLE_CHOICES
} from '../../../../constants/my-details';
import { getHobbyIntl } from '../../../../utils/my-details';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import _ from 'lodash';
import { sanitize } from 'dompurify';

const HOSTNAME = process.env.PFPLUS_URL;

const FullWidthStyle = {
  width: '100%'
};

const DisclaimerTypoStyle = {
  fontSize: '80%'
};

const ProfilePlusButtonStyle = {
  minWidth: 'unset',
  padding: '4px 8px',
  lineHeight: 1.25,
  marginBottom: 8
};

const MyDetailsSocial = props => {
  const { intl, userDetails, details, error, onChangeDetails, updateError, onResize } = props;

  const {
    hobbies,
    customizedHobby,
    university,
    secondarySchool,
    familiarPlaces,
    otherHashtags,
    instagram,
    facebookLink,
    realLinkedinLink,
    linkedinLink,
    themeColor,
    themeStyle
  } = details || {};

  const createSimpleOnChangeHandler = (key, emptyValue) => event =>
    onChangeDetails({ [key]: event.target.value || emptyValue });
  const onChangeHobbies = createSimpleOnChangeHandler('hobbies', []);
  const onChangeCustomizedHobby = createSimpleOnChangeHandler('customizedHobby', '');
  const onChangeUniversity = createSimpleOnChangeHandler('university', '');
  const onChangeSecondarySchool = createSimpleOnChangeHandler('secondarySchool', '');
  const onChangeFamiliarPlaces = createSimpleOnChangeHandler('familiarPlaces', '');
  const onChangeOtherHashtags = createSimpleOnChangeHandler('otherHashtags', '');
  const onChangeInstagram = createSimpleOnChangeHandler('instagram', '');
  const onChangeFacebookLink = createSimpleOnChangeHandler('facebookLink', '');
  const onChangeRealLinkedinLink = createSimpleOnChangeHandler('realLinkedinLink', '');
  const onChangeLinkedinLink = createSimpleOnChangeHandler('linkedinLink', '');
  const onChangeThemeColor = createSimpleOnChangeHandler('themeColor', 'blue');
  const onChangeThemeStyle = createSimpleOnChangeHandler('themeStyle', 'classic');
  const onHobbiesMax = () => updateError({ hobbies: true });
  const profilePlus = () => onChangeDetails({ linkedinLink: `${HOSTNAME}/adviser/${_.get(userDetails, '_id')}` });

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <AladdinHint title={intl.formatMessage({ id: 'mda-social-title' })} />
      </Grid>
      <Grid item>
        <ExpansionPanel className="mc-expansion-panel" expanded={true}>
          <ExpansionPanelSummary>
            <Typography className="field-label" color={!!error.hobbies ? 'error' : undefined}>{`${intl.formatMessage({
              id: 'hobbies'
            })}*`}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container direction="column" alignItems="flex-start" spacing={2}>
              <Grid item>
                <Typography
                  color="textSecondary"
                  dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'mda-mc-6-hint' })) }}
                />
              </Grid>
              <Grid item>
                <SelectButtonGroup
                  multi={true}
                  max={6}
                  choices={HOBBIES_CHOICES.map(hobby => ({
                    value: hobby,
                    label: intl.formatMessage({ id: getHobbyIntl(hobby) })
                  }))}
                  value={hobbies}
                  onChange={onChangeHobbies}
                  // onMax={onHobbiesMax}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={intl.formatMessage({ id: 'customizedHobby' })}
                  value={customizedHobby || ''}
                  onChange={onChangeCustomizedHobby}
                  placeholder=""
                  inputProps={{ maxLength: 20 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>

      <Grid item>
        <Typography color="primary" align="center">
          {intl.formatMessage({ id: 'mda-my-footprint' })}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'familiar-places' })}
          value={familiarPlaces || ''}
          onChange={onChangeFamiliarPlaces}
          placeholder="#沙田友 #Sydney #Vancouver"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'university' })}
          value={university || ''}
          onChange={onChangeUniversity}
          placeholder="#澳大 #MIT"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'sec-pri-school' })}
          value={secondarySchool || ''}
          onChange={onChangeSecondarySchool}
          placeholder="#保富中學 #保富小學"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'other-hashtags' })}
          value={otherHashtags || ''}
          onChange={onChangeOtherHashtags}
          placeholder="#NZ工作假期 #加拿大Exchange"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item>
        <Typography color="primary" align="center">
          {intl.formatMessage({ id: 'mda-social-platforms' })}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'instagram' })}
          value={instagram}
          onChange={onChangeInstagram}
          InputLabelProps={{ shrink: true }}
          placeholder="portfoplus"
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'Facebook Link' })}
          value={facebookLink}
          onChange={onChangeFacebookLink}
          InputLabelProps={{ shrink: true }}
          placeholder="https://www.facebook.com/portfoplus"
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'linkedin' })}
          value={realLinkedinLink}
          onChange={onChangeRealLinkedinLink}
          InputLabelProps={{ shrink: true }}
          placeholder="https://www.linkedin.com/company/portfoplus"
        />
      </Grid>
      <Grid item>
        <TextField
          label={intl.formatMessage({ id: 'Adviser-Link' })}
          value={linkedinLink}
          onChange={onChangeLinkedinLink}
          InputLabelProps={{ shrink: true }}
          placeholder="https://www.myweb.com"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button color="primary" variant="contained" style={ProfilePlusButtonStyle} onClick={profilePlus}>
                  PF+
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Grid>

      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item style={FullWidthStyle}>
            <Typography className="field-label">{intl.formatMessage({ id: 'mda-theme-color' })}</Typography>
          </Grid>
          <Grid item>
            <SelectButtonGroup
              choices={THEME_COLOR_CHOICES.map(tag => ({
                value: tag,
                label: intl.formatMessage({ id: `tc-${tag}` }),
                selectedColor: THEME_COLOR_PALETTES[tag][0]
              }))}
              value={themeColor || THEME_COLOR_CHOICES[0]}
              onChange={onChangeThemeColor}
            />
          </Grid>
          {/*   wait style design 2 to confirm then release the code to let user pick styles

          <Grid item style={FullWidthStyle}>
            <Typography className="field-label" alignItems="flex-start">
              {intl.formatMessage({ id: 'mda-theme-style' })}
            </Typography>
          </Grid>
          <Grid item>
            <SelectButtonGroup
              choices={THEME_STYLE_CHOICES.map(tag => ({
                value: tag,
                label: intl.formatMessage({ id: `ts-${tag}` }),
                selectedColor: THEME_COLOR_PALETTES[themeColor || 'blue'][0]
              }))}
              value={themeStyle || THEME_STYLE_CHOICES[0]}
              onChange={onChangeThemeStyle}
            />
          </Grid> */}
          <Grid item>
            <img
              src={THEME_COLOR_IMAGES[themeStyle || 'classic'][themeColor || 'blue']['preview']}
              alt="preview"
              style={{ height: 45 }}
            />
          </Grid>
          {/*<Grid item style={{ borderBottom: `1px solid ${THEME_COLOR_PALETTES[themeColor || 'blue'][0]}80` }}>*/}
          {/*  <Grid container alignItems="flex-end" justify="center" spacing={1}>*/}
          {/*    <Grid item>*/}
          {/*      <img src={THEME_COLOR_IMAGES[themeColor || 'blue']['logo']} alt="portfoplus" style={{ height: 45 }} />*/}
          {/*    </Grid>*/}
          {/*    <Grid item>*/}
          {/*      <Grid container spacing={1}>*/}
          {/*        {THEME_COLOR_PALETTES[themeColor || 'blue'].map((color, index) => (*/}
          {/*          <Grid item key={`${index}-${color}`}>*/}
          {/*            <div style={{ background: color, width: 35, height: 35 }} />*/}
          {/*          </Grid>*/}
          {/*        ))}*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
      <Grid item>
        <Typography style={DisclaimerTypoStyle}>{intl.formatMessage({ id: 'profile-disclaim' })}</Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(MyDetailsSocial);
