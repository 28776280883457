import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { goToAnalysis } from '../../../../components/ClientEntry/utils';

const ButtonStyle = {
  padding: 3,
  lineHeight: 1,
  minWidth: 71,
  textTransform: 'unset'
};

const IconStyle = {
  width: 24,
  margin: '4px 0'
};

const AnalysisButton = props => {
  const { intl, client, history, style } = props;

  const onClick = () => {
    if (!client) {
      localStorage.removeItem('clientId');
      history.push({
        pathname: '/need-analysis',
        state: {
          previousPath: history.location.pathname,
          clientFactsheetDetail: { clientIncData: { currentSlide: 3 } }
        }
      });
    } else {
      goToAnalysis(client, history, 'Saving');
    }
  };

  return (
    <Button color="primary" onClick={onClick} style={{ ...ButtonStyle, ...style }}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img src="img/saletools.png" alt="analysis" style={IconStyle} />
        </Grid>
        <Grid item>
          <Typography align="center" color="textSecondary">
            {intl.formatMessage({ id: 'Analysis' })}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default injectIntl(withRouter(AnalysisButton));
