import React from 'react';
import { injectIntl } from 'react-intl';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';

const ReferralExistDialog = props => {
  const { intl, open, onClose, onOK, ...rest } = props;

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition} {...rest}>
      <DialogContent>{intl.formatMessage({ id: 'referral-exist' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onOK}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ReferralExistDialog);
