import { injectIntl } from 'react-intl';
import React, { PureComponent, Fragment } from 'react';
import { Button, Grid } from '@material-ui/core';
import AnimatedLinearProgressBar from '../../../../../../components/AnimatedLinearProgressBar';
import { formatNumber, formatOnlyNumber, formatOnlyUnit } from '../../../../../../utils/formatNumber';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Card from '@material-ui/core/Card';

class FollowUpCard extends PureComponent {
  render() {
    const {
      intl,
      targetBiz,
      opportunityBizVolObj,
      // openModal,
      page,
      opportunityCount,
      numberOfRecord,
      leftBtnComponent,
      rightBtnComponent
    } = this.props;
    const highOpportunityBizVol = opportunityBizVolObj.highOpportunityBizVol;
    const mediumOpportunityBizVol = opportunityBizVolObj.mediumOpportunityBizVol;
    const lowOpportunityBizVol = opportunityBizVolObj.lowOpportunityBizVol;
    const noneOpportunityBizVol = opportunityBizVolObj.noneOpportunityBizVol;
    const manualBizVol = opportunityBizVolObj.manualBizVol;
    const isManual = opportunityBizVolObj.isManual;

    const totalOpportunityBizVol =
      highOpportunityBizVol +
      mediumOpportunityBizVol +
      lowOpportunityBizVol +
      noneOpportunityBizVol +
      (manualBizVol ? manualBizVol : 0);

    const highOpportunityCount = opportunityCount.highOpportunityCount;
    const mediumOpportunityCount = opportunityCount.mediumOpportunityCount;
    const lowOpportunityCount = opportunityCount.lowOpportunityCount;
    const noneOpportunityCount = opportunityCount.noneOpportunityCount;
    const totalOpportunityCount =
      highOpportunityCount + mediumOpportunityCount + lowOpportunityCount + noneOpportunityCount;

    return (
      <Card className={page !== 'teamMember' ? 'progress-card' : 'progress-card-upper-shadow'}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
          <Grid item xs={5} className="progress-card-title">
            <FiberManualRecordIcon className="card-title-dot" style={{ color: '#d2a6ff' }} />
            {intl.formatMessage({ id: 'followingUp-biz' })}
          </Grid>
          <Grid item xs={2}>
            {leftBtnComponent}
          </Grid>
          <Grid item xs={5} className="progress-card-title">
            {intl.formatMessage({ id: 'follow-up-case' })}
          </Grid>
        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={0}>
          <Grid item xs={isManual === true && page === 'adviser_business' ? 5 : 7} style={{ color: '#d2a6ff' }}>
            <span>
              <span className="progress-card-number">
                {parseInt(targetBiz) > 0 ? formatOnlyNumber(targetBiz, intl) : '-'}{' '}
              </span>
              <span className="progress-card-unit">{targetBiz ? formatOnlyUnit(targetBiz, intl) : ''}</span>
            </span>
          </Grid>
          {isManual === true && page === 'adviser_business' && (
            <Grid item xs={2} style={{ color: '#00000061', marginLeft: '-7px', marginRight: '7px' }}>
              <Button
                className="follow-up-oppo-btn-small"
                style={{ color: '#3966f8', border: '1.1px solid #3966f8' }}
                variant="outlined"
              >
                {intl.formatMessage({ id: 'Manual-short' })}
              </Button>
            </Grid>
          )}

          <Grid item xs={3} className="progress-card-number" style={{ color: '#00000061' }}>
            {numberOfRecord ? numberOfRecord.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : numberOfRecord}{' '}
          </Grid>
          <Grid item xs={2}>
            {rightBtnComponent}
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          style={{ lineHeight: 2.3, textAlign: 'center', marginBottom: '-3px' }}
        >
          <Grid item xs={2} className="follow-up-biz-vol">
            <span className="follow-up-biz-vol-num ">{formatNumber(highOpportunityBizVol, intl)}</span>
          </Grid>
          <Grid item xs={3} style={{ transform: 'rotate(180deg)' }}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-green-color"
              barValue={
                opportunityBizVolObj && totalOpportunityBizVol > 0
                  ? parseInt((highOpportunityBizVol / totalOpportunityBizVol) * 100)
                  : 0
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className="follow-up-oppo-btn"
              style={{ color: '#5ee092', border: '1.1px solid #5ee092' }}
              variant="outlined"
            >
              {intl.formatMessage({ id: 'oppH' })}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-green-color"
              barValue={opportunityCount ? parseInt((highOpportunityCount / totalOpportunityCount) * 100) : 0}
            />
          </Grid>
          <Grid item xs={2} className="follow-up-case">
            <span className="follow-up-case-num">{highOpportunityCount}</span>
          </Grid>

          <Grid item xs={2} className="follow-up-biz-vol">
            <span className="follow-up-biz-vol-num">{formatNumber(mediumOpportunityBizVol, intl)}</span>
          </Grid>
          <Grid item xs={3} style={{ transform: 'rotate(180deg)' }}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-yellow-color"
              barValue={
                opportunityBizVolObj && totalOpportunityBizVol > 0
                  ? parseInt((mediumOpportunityBizVol / totalOpportunityBizVol) * 100)
                  : 0
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className="follow-up-oppo-btn"
              style={{ color: '#ffaf19', border: '1.1px solid #ffaf19' }}
              variant="outlined"
            >
              {intl.formatMessage({ id: 'oppM' })}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-yellow-color"
              barValue={opportunityCount ? parseInt((mediumOpportunityCount / totalOpportunityCount) * 100) : 0}
            />
          </Grid>
          <Grid item xs={2} className="follow-up-case">
            <span className="follow-up-case-num">{mediumOpportunityCount}</span>
          </Grid>

          <Grid item xs={2} className="follow-up-biz-vol">
            <span className="follow-up-biz-vol-num">{formatNumber(lowOpportunityBizVol, intl)}</span>
          </Grid>
          <Grid item xs={3} style={{ transform: 'rotate(180deg)' }}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-red-color"
              barValue={
                opportunityBizVolObj && totalOpportunityBizVol > 0
                  ? parseInt((lowOpportunityBizVol / totalOpportunityBizVol) * 100)
                  : 0
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className="follow-up-oppo-btn"
              style={{ color: '#ff6a74', border: '1.1px solid #ff6a74' }}
              variant="outlined"
            >
              {intl.formatMessage({ id: 'oppL' })}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-red-color"
              barValue={opportunityCount ? parseInt((lowOpportunityCount / totalOpportunityCount) * 100) : 0}
            />
          </Grid>
          <Grid item xs={2} className="follow-up-case">
            <span className="follow-up-case-num">{lowOpportunityCount}</span>
          </Grid>

          <Grid item xs={2} className="follow-up-biz-vol">
            <span className="follow-up-biz-vol-num">{formatNumber(noneOpportunityBizVol, intl)}</span>
          </Grid>
          <Grid item xs={3} style={{ transform: 'rotate(180deg)' }}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-grey-color"
              barValue={
                opportunityBizVolObj && totalOpportunityBizVol > 0
                  ? parseInt((noneOpportunityBizVol / totalOpportunityBizVol) * 100)
                  : 0
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className="follow-up-oppo-btn"
              style={{ color: '#cccccc', border: '1.1px solid #cccccc' }}
              variant="outlined"
            >
              {intl.formatMessage({ id: 'NA' })}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <AnimatedLinearProgressBar
              className="customize-progress-bar bar-grey-color"
              barValue={opportunityCount ? parseInt((noneOpportunityCount / totalOpportunityCount) * 100) : 0}
            />
          </Grid>
          <Grid item xs={2} className="follow-up-case">
            <span className="follow-up-case-num">{noneOpportunityCount}</span>
          </Grid>

          {manualBizVol > 0 && (
            <Fragment>
              <Grid item xs={2} className="follow-up-biz-vol">
                <span className="follow-up-biz-vol-num">{formatNumber(manualBizVol, intl)}</span>
              </Grid>
              <Grid item xs={3} style={{ transform: 'rotate(180deg)' }}>
                <AnimatedLinearProgressBar
                  className="customize-progress-bar bar-blue-color"
                  barValue={
                    manualBizVol && totalOpportunityBizVol > 0
                      ? parseInt((manualBizVol / totalOpportunityBizVol) * 100)
                      : 0
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  className="follow-up-oppo-btn"
                  style={{ color: '#3966f8', border: '1.1px solid #3966f8' }}
                  variant="outlined"
                >
                  {intl.formatMessage({ id: 'Manual-short' })}
                </Button>
              </Grid>
              <Grid item xs={5}></Grid>
            </Fragment>
          )}
        </Grid>
      </Card>
    );
  }
}

export default injectIntl(FollowUpCard);
