import React from 'react';
import { injectIntl } from 'react-intl';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import { getIntegerOrFixed } from '../../../../../utils/formatNumber';

const ContainerStyle = {
  position: 'relative',
  height: 130
};

const IconStyle = {
  position: 'absolute',
  bottom: -16,
  left: '50%',
  transform: 'translate(-50%, 0)'
};

const ChartContainerStyle = {
  position: 'absolute',
  height: 150,
  width: '100%',
  maxWidth: 175,
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const getOptions = (intl, value) => ({
  legend: {
    show: false
  },
  grid: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  series: [
    {
      min: 0,
      max: 12,
      splitNumber: 12,
      type: 'gauge',
      axisLine: {
        lineStyle: {
          color: [
            [
              0.16667,
              new echarts.graphic.LinearGradient(
                0,
                1,
                0,
                0,
                [
                  {
                    offset: 1,
                    color: '#ffa600' // 50% 处的颜色
                  },
                  {
                    offset: 0,
                    color: '#ff0000' // 40% 处的颜色
                  }
                ],
                false
              )
            ], // 100% 处的颜色
            [
              0.45,
              new echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 1,
                    color: '#3eda7d' // 70% 处的颜色
                  },
                  {
                    offset: 0,
                    color: '#ffa600' // 50% 处的颜色
                  }
                ],
                false
              )
            ],
            [
              1,
              new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 1,
                    color: '#ffa600'
                  },
                  {
                    offset: 0.6,
                    color: '#3eda7d'
                  }
                ],
                false
              )
            ]
          ],
          width: 8
        }
      },
      pointer: {
        length: '60%',
        width: 2
      },
      itemStyle: {
        color: '#3966f8',
        shadowBlur: 0
      },
      axisTick: {
        show: false
      },
      splitLine: {
        distance: 10,
        length: 10,
        lineStyle: {
          color: '#fff',
          width: 2
        }
      },
      axisLabel: {
        show: true,
        color: '#aeaeae',
        distance: 0,
        fontSize: 10
      },
      title: {
        show: false
      },
      detail: {
        valueAnimation: true,
        formatter: `{figure|${value}}\n${intl.formatMessage({ id: 'Mth' })}`,
        color: '#aeaeae',
        fontSize: 11,
        rich: {
          figure: {
            color: '#3966f8',
            fontSize: 18,
            fontWeight: 500,
            verticalAlign: 'bottom',
            padding: [0, 0, 0, 0]
          }
        }
      },
      data: [
        {
          value: value
        }
      ]
    }
  ]
});

const MonthlyExpensePurchaseMonthsChart = props => {
  const { intl, value, template, ...rest } = props;

  return (
    <div
      className="monthly-expense-purchase-months-chart"
      style={{ ...ContainerStyle, width: template ? 225 : '100%', margin: 'auto' }}
    >
      {(value > 12 || value < 2) && (
        <div style={IconStyle}>
          <ReportProblemRoundedIcon color="error" />
        </div>
      )}
      <div style={ChartContainerStyle}>
        <ReactEcharts option={getOptions(intl, getIntegerOrFixed(value, 1))} {...rest} />
      </div>
    </div>
  );
};

export default injectIntl(MonthlyExpensePurchaseMonthsChart);
