import { getSearchParam } from './url';

export const getRegionSuffixInUrlSearch = search => {
  let region = getSearchParam(search, 'region');

  if (region) {
    region = region.trim();
    if (region === 'tw') {
      return region;
    }
  }

  return 'hk';
};

export const saveRegion = regionText => {
  localStorage.setItem('region', regionText);
};

export const getRegion = () => {
  let regionInLocalStorage = localStorage.getItem('region');
  return regionInLocalStorage ? regionInLocalStorage : 'hk';
};

export const cleanRegion = () => {
  localStorage.removeItem('region');
};

export const insurerRegionFilter = insurer => insurer.region === getRegion() || insurer.region === 'any';
