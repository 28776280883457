import _ from 'lodash';
import React from 'react';
import { injectIntl } from 'react-intl';
import withRouter from 'react-router/withRouter';
import PortfolioSubCategory from './PortfolioSubCategory/PortfolioSubCategory';
import Grid from '@material-ui/core/Grid';
import PortfolioChart from './PortfolioChart/PortfolioChart';
import PortfolioSelector from './PortfolioSelector/PortfolioSelector';
import PortfolioViewSwitch from './PortfolioViewSwitch/PortfolioViewSwitch';
import {
  getPolicyMarketValue,
  getPolicyValue,
  getPremiumPeriodValue,
  getSubCategoryColor,
  isCategory,
  isInsuredNotMePolicy,
  isPaidUpPolicy,
  isPolicySubCategory,
  isRiderPolicy,
  isSubCategory
} from '../../../../utils/policy';
import { getUserCurrency, isAdviserType } from '../../../../utils/user';
import { connect } from 'react-redux';
import { handleDashboardPolicyData } from '../../../../store/policy/duck';
import { formatNumber } from '../../../../utils/formatNumber';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import './PortfolioCategory.scss';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import { getLocalStorageClientId } from '../../../../utils/client';
import PortfolioActions from './PortfolioActions/PortfolioActions';
import { getInsurerColor, getInsurerTitleEn } from '../../../../utils/insurer';

const PortfolioActionsStyle = { width: 58, height: '100%', borderRadius: 'inherit', padding: '2px 6px' };

const getChartTitle = (infoType, premiumFrequency, selectedSubCategory, intl) => {
  let title;
  if (selectedSubCategory) {
    title = intl.formatMessage({ id: selectedSubCategory.title });
    if (intl.locale === 'en') {
      title += ' ';
    }
  } else {
    title = intl.formatMessage({ id: 'total' });
  }

  switch (infoType) {
    case 'Protection':
      if (!selectedSubCategory) {
        title = intl.formatMessage({ id: 'Total Life' });
      } else {
        if (isSubCategory(selectedSubCategory._id, ['medical', 'saving'])) {
          title = intl.formatMessage({ id: 'life-in-sub-cat' }, { title: title });
        } else {
          title = intl.formatMessage({ id: 'sub-cat-cover' }, { title: title });
        }
      }
      break;
    case 'Premium':
      if (premiumFrequency === 'Monthly') {
        title += intl.formatMessage({ id: 'monthly-pay' });
      } else {
        title += intl.formatMessage({ id: 'yearly-pay' });
      }
      break;
    case 'MarketValue':
      title += intl.formatMessage({ id: 'Market Value' });
      break;
    default:
      break;
  }

  return title;
};

class PortfolioCategory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      infoType: props.infoType || 'Protection',
      selectedSubCategory: undefined,
      premiumFrequency: props.premiumFrequency || undefined,
      chartRegionHeight: undefined
    };
  }

  //Handle the data while click on tab to show the chart monthly yearly
  expandCategory = (event, expanded, category) => {
    const { selectedSubCategory } = this.state;
    const stateObj = {
      selectedSubCategory: _.get(selectedSubCategory, '_id') === _.get(category, '_id') ? undefined : category
    };
    this.setState(stateObj);
  };

  addPolicy = () => {
    this.props.history.push({
      pathname: this.props.userDetails.userType === 'Adviser' ? 'add_portfolio' : 'existing_policy'
    });
  };

  isProfolioChartShown = allPolicyCount => {
    const { selectedSubCategory } = this.state;
    return selectedSubCategory !== undefined || (allPolicyCount !== 0 && selectedSubCategory === undefined);
  };

  selectView = (nextInfoType, nextPremiumFrequency) => {
    const stateObj = {
      infoType: nextInfoType,
      premiumFrequency: nextInfoType === 'Premium' ? nextPremiumFrequency : undefined
    };

    this.setState(stateObj);
  };

  updateChartRegionHeight = height => this.setState({ chartRegionHeight: height + 10 });

  render() {
    const {
      intl,
      userDetails,
      shareFromData,
      portfolio,
      category,
      currentPortfolioId,
      selectPortfolio,
      portfolioSelector,
      viewSelector,
      onClickPolicy
    } = this.props;
    const { infoType, premiumFrequency, selectedSubCategory, chartRegionHeight } = this.state;

    const clientId = getLocalStorageClientId();
    const isAdviser = isAdviserType(userDetails);

    const selectedCurrency = getUserCurrency(userDetails);

    // filter by category id
    const formattedPortfolio = portfolio.filter(policy => policy.categoryId === _.get(category, '_id'));

    // const policyCount = formattedPortfolio.length;
    const basicPolicyCount = (selectedSubCategory
      ? _.get(selectedSubCategory, 'policies', [])
      : formattedPortfolio
    ).filter(policy => !isRiderPolicy(policy)).length;
    let totalLifeCoverage = 0,
      // totalCiCoverage = 0,
      totalMonthlyPremium = 0,
      totalYearlyPremium = 0,
      totalMarketValue = 0;

    const allPolicyCount = (selectedSubCategory ? _.get(selectedSubCategory, 'policies', []) : formattedPortfolio)
      .length;

    // format subcategories
    const formattedSubCategories = formattedPortfolio.reduce(
      (accumulator, policy) => {
        const targetIndex = accumulator.findIndex(subCategory => subCategory._id === policy.subCategoryId);

        if (targetIndex >= 0) {
          const newPolicy = { ...policy };

          const monthlyPremium = getPremiumPeriodValue(newPolicy, 'Monthly', selectedCurrency),
            yearlyPremium = monthlyPremium * 12;

          newPolicy.formattedMonthlyPremium = monthlyPremium;
          newPolicy.formattedYearlyPremium = yearlyPremium;
          const isPaidUp = isPaidUpPolicy(newPolicy);
          // Do not count paid up policies' premium
          if (!isPaidUp) {
            accumulator[targetIndex].monthlyPremium += monthlyPremium;
            accumulator[targetIndex].yearlyPremium += yearlyPremium;
            totalMonthlyPremium += monthlyPremium;
            totalYearlyPremium += yearlyPremium;
          }

          // Do not include coverage values of insured not me policies
          if (!isInsuredNotMePolicy(newPolicy)) {
            const lifeCoverage = getPolicyValue(newPolicy, 'lifeCoverage', selectedCurrency),
              ciCoverage = getPolicyValue(newPolicy, 'sumInsured', selectedCurrency);

            // exclude lifeCoverage values of accident policies from total life coverage
            const isAccidentPolicy = isPolicySubCategory(newPolicy, 'accident');
            if (!isAccidentPolicy) {
              totalLifeCoverage += lifeCoverage;
            }
            accumulator[targetIndex].lifeCoverage += lifeCoverage;
            accumulator[targetIndex].ciCoverage += ciCoverage;
            // totalCiCoverage += ciCoverage;
            newPolicy.formattedLifeCoverage = lifeCoverage;
            newPolicy.formattedCICoverage = ciCoverage;
          }

          const marketValue = getPolicyMarketValue(newPolicy, selectedCurrency);
          accumulator[targetIndex].marketValue += marketValue || 0;
          newPolicy.formattedMarketValue = marketValue;
          totalMarketValue += marketValue || 0;

          accumulator[targetIndex].policies.push(newPolicy);
        }
        return accumulator;
      },
      _.get(category, 'subCategories', []).map(subCategory => ({
        ...subCategory,
        policies: [],
        monthlyPremium: 0,
        yearlyPremium: 0,
        lifeCoverage: 0,
        ciCoverage: 0,
        marketValue: 0
      }))
    );

    // format chart data
    let chartData = {};
    if (selectedSubCategory) {
      const chartPolicies = _.get(
        formattedSubCategories.find(subCategory => subCategory._id === selectedSubCategory._id),
        'policies',
        []
      ).filter(policy => !isInsuredNotMePolicy(policy));

      chartData = chartPolicies.reduce(
        (accumulator, policy) => {
          // chart value selection
          let value;

          switch (infoType) {
            case 'Protection':
              value = isPolicySubCategory(policy, 'ci')
                ? _.get(policy, 'formattedCICoverage')
                : _.get(policy, 'formattedLifeCoverage');
              break;
            case 'Premium':
              value =
                premiumFrequency === 'Monthly'
                  ? _.get(policy, 'formattedMonthlyPremium')
                  : _.get(policy, 'formattedYearlyPremium');
              break;
            case 'MarketValue':
              value = _.get(policy, 'formattedMarketValue');
              break;
            default:
              value = 0;
          }

          if (value || value === 0) {
            const insurer = _.get(policy, 'insurerId', {});
            const title = getInsurerTitleEn(insurer);
            const color = value > 0 ? getInsurerColor(insurer) : '#e9e9e9';
            accumulator.labels.push(title ? intl.formatMessage({ id: title }) : title);
            accumulator.datasets[0].data.push(value);
            accumulator.datasets[0].backgroundColor.push(color);
            accumulator.datasets[0].hoverBackgroundColor.push(color);
          }
          return accumulator;
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              hoverBackgroundColor: []
            }
          ]
        }
      );
    } else {
      chartData = formattedSubCategories.reduce(
        (accumulator, subCategory) => {
          // sub category value selection
          let subCategoryValue;

          switch (infoType) {
            case 'Protection':
              subCategoryValue = _.get(subCategory, 'lifeCoverage', 0);
              break;
            case 'Premium':
              subCategoryValue =
                premiumFrequency === 'Monthly'
                  ? _.get(subCategory, 'monthlyPremium')
                  : _.get(subCategory, 'yearlyPremium');
              break;
            case 'MarketValue':
              subCategoryValue = _.get(subCategory, 'marketValue');
              break;
            default:
              subCategoryValue = 0;
          }

          if (subCategoryValue || subCategoryValue === 0) {
            accumulator.datasets[0].data.push(subCategoryValue);
            accumulator.labels.push(intl.formatMessage({ id: subCategory.title.replace('Critical Illness', 'C.I.') })); // replace long CI title
            const color = getSubCategoryColor(subCategory);
            accumulator.datasets[0].backgroundColor.push(color);
            accumulator.datasets[0].hoverBackgroundColor.push(color);
          }
          return accumulator;
        },
        {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              hoverBackgroundColor: []
            }
          ]
        }
      );
    }

    // avoid empty chart
    if (chartData.labels.length === 0) {
      chartData = {
        labels: [intl.formatMessage({ id: 'No Policy' })],
        datasets: [
          {
            data: [0.1],
            backgroundColor: ['#e9e9e9'],
            hoverBackgroundColor: ['#e9e9e9']
          }
        ]
      };
    } else if (_.sum(chartData.datasets[0].data) === 0) {
      chartData.datasets[0].data.push(0.1);
      chartData.datasets[0].backgroundColor.push('#e9e9e9');
      chartData.datasets[0].hoverBackgroundColor.push('#e9e9e9');
    }

    // Adding text to center of doughnut chart
    chartData.texts = [
      {
        subtexts: [
          {
            text: intl.formatMessage({ id: 'basic-plan' }),
            color: 'rgba(0, 0, 0, 0.54)'
          }
        ],
        fontSizeFactor: 1.1,
        fontWeight: 'normal',
        textWrap: intl.locale === 'en' ? 'word' : 'character'
      },
      {
        subtexts: [
          {
            text: 'x',
            color: 'rgb(204, 204, 204)'
          },
          {
            text: `${basicPolicyCount}`,
            color: '#3966f8'
          }
        ],
        fontSizeFactor: 1.5,
        fontWeight: 'bold',
        topMargin: 10,
        textWrap: 'character'
      }
    ];

    const userId = _.get(userDetails, '_id');
    const selectedSubCategoryId = _.get(selectedSubCategory, '_id');
    const totalLifeCoverageDisplay = formatNumber(totalLifeCoverage, intl, true);
    const secondBoxValueDisplay = formatNumber(
      infoType === 'MarketValue'
        ? totalMarketValue
        : premiumFrequency === 'Yearly'
        ? totalYearlyPremium
        : totalMonthlyPremium,
      intl,
      true
    );
    const chartTitle = getChartTitle(infoType, premiumFrequency, selectedSubCategory, intl);

    // heights for selector & chart region
    const chartHeight = Math.min((window.innerWidth - 133) * 0.8, Math.max(122, window.innerHeight - 545), 225);

    return (
      <div className="portfolio-category">
        <div className="list cardView dasboard-cardView">
          <Grid container style={{ marginBottom: 10 }}>
            {portfolioSelector && (
              <Grid item>
                <Card className="portfolio-selector-wrapper">
                  {clientId && isAdviser ? (
                    <PortfolioActions style={PortfolioActionsStyle} />
                  ) : (
                    <PortfolioSelector
                      shareFromData={shareFromData}
                      defaultId={userId}
                      selectedId={currentPortfolioId}
                      onSelect={selectPortfolio}
                      maxHeight={chartRegionHeight}
                    />
                  )}
                </Card>
              </Grid>
            )}

            <Grid
              item
              style={{
                width: portfolioSelector ? 'calc(100% - 68px)' : '100%',
                marginLeft: portfolioSelector ? 10 : undefined
              }}
            >
              <Card className="portfolio-chart-wrapper" style={{ height: '100%' }}>
                <PortfolioChart
                  title={chartTitle}
                  currency={selectedCurrency}
                  chartData={chartData}
                  style={{
                    backgroundColor: currentPortfolioId && currentPortfolioId !== userId ? '#e2ffff' : '',
                    height: '100%',
                    paddingRight: viewSelector && portfolioSelector ? 35 : undefined
                  }}
                  legendWrapperProps={{
                    style: {
                      display: 'block',
                      padding: viewSelector && !portfolioSelector ? '0 35px' : undefined
                    }
                  }}
                  chartHeight={chartHeight}
                  updateHeight={this.updateChartRegionHeight}
                  isProfolioChartShown={this.isProfolioChartShown(allPolicyCount)}
                  addPolicy={this.addPolicy}
                >
                  {viewSelector && (
                    <PortfolioViewSwitch
                      infoType={infoType}
                      premiumFrequency={premiumFrequency}
                      onChange={this.selectView}
                      style={{ position: 'absolute', top: 0, right: 0 }}
                    />
                  )}
                </PortfolioChart>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={1} className="info-boxes">
            <Grid item xs={6} className="info-box">
              <div className="dash-Life-CI dashboard-life-policies">
                <div>
                  <div className="title">
                    <Grid container justify="center" alignItems="center">
                      <Grid item>
                        <FavoriteIcon />
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary">{intl.formatMessage({ id: 'Total Life' })}</Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="content">
                    <span className="value">{totalLifeCoverageDisplay.value}</span>
                    {totalLifeCoverageDisplay.unit && <span>{totalLifeCoverageDisplay.unit}</span>}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={6} className="info-box">
              <div className="dash-Life-CI dashboard-life-policies">
                <div>
                  <div className="title">
                    <Grid container justify="center" alignItems="center">
                      <Grid item>
                        <MonetizationOnIcon />
                      </Grid>
                      <Grid item>
                        <Typography color="textSecondary">
                          {intl.formatMessage({
                            id:
                              infoType === 'MarketValue'
                                ? isCategory(category._id, 'general')
                                  ? 'sub-cat-market-value'
                                  : 'sub-cat-cash-value'
                                : premiumFrequency === 'Yearly'
                                ? 'yearly-pay'
                                : 'monthly-pay'
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="content">
                    <span className="value">{secondBoxValueDisplay.value}</span>
                    {secondBoxValueDisplay.unit && <span>{secondBoxValueDisplay.unit}</span>}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div>
            {formattedSubCategories.map(subCategory => {
              return (
                <PortfolioSubCategory
                  expanded={subCategory._id === selectedSubCategoryId}
                  category={category}
                  key={subCategory._id}
                  currency={selectedCurrency}
                  color={getSubCategoryColor(subCategory)}
                  subCategory={subCategory}
                  infoType={infoType}
                  premiumFrequency={premiumFrequency}
                  onClickPolicy={onClickPolicy}
                  expandCategory={this.expandCategory}
                  addPolicy={this.addPolicy}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    shareFromData: state.share.shareFromData
  }),
  {
    handleDashboardPolicyData
  }
)(PortfolioCategory);

export default injectIntl(withRouter(container));
