import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import { getPolicies, resetPolicyPicker } from '../../store/policyPicker/duck';
import { usePrevious } from '../../utils/hooks';
import { INIT, LOADING } from '../../constants/phase';
import PolicyPickerTable from './PolicyPickerTable/PolicyPickerTable';
import '../../views/PolicyReview/PolicyReview.scss';
import '../../views/PolicyReview/PolicyCategoryTableInfo/PolicyCategoryTableInfo.scss';
import _ from 'lodash';
import { getUserCurrency } from '../../utils/user';

const TitleTypoStyle = {
  fontSize: 'inherit'
};

const HintTypoStyle = {
  fontSize: '1.38rem'
};

const DialogContentStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  position: 'relative'
};

const ProgressDivStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

export const isPolicyPicked = (policy, picked) => picked === true || !!picked[_.get(policy, '_id')];

const PolicyPicker = props => {
  const {
    intl,
    userDetails,
    userId,
    open,
    policies,
    policiesOwner,
    selectedPolicies,
    filter,
    onClose,
    onSubmit,
    getPoliciesPhase,
    getPolicies,
    resetPolicyPicker,
    shouldResetPickedAfterSubmit
  } = props;

  const [picked, setPicked] = useState({});
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const previousOpen = usePrevious(open);

  const currency = getUserCurrency(userDetails);
  const filteredPolicies = (policies || []).filter(filter || (() => true));

  const createTogglePickedHandler = policy => event => {
    let newPicked;
    if (picked === true) {
      newPicked = (policies || []).reduce((accumulator, current) => {
        accumulator[current._id] = true;
        return accumulator;
      }, {});
    } else {
      newPicked = { ...picked };
    }

    if (event.target.checked !== undefined) {
      if (event.target.checked) {
        newPicked[policy._id] = true;
      } else {
        delete newPicked[policy._id];
      }
    } else {
      if (!newPicked[policy._id]) {
        newPicked[policy._id] = true;
      } else {
        delete newPicked[policy._id];
      }
    }

    setPicked(newPicked);
  };

  const submit = () => {
    const pickedPolicies = filteredPolicies.filter(policy => isPolicyPicked(policy, picked));
    onSubmit(pickedPolicies);
    onClose();

    if (shouldResetPickedAfterSubmit) {
      setPicked({});
    }
  };

  const setSelectedPoliciesPicked = useCallback(() => {
    setPicked(
      (selectedPolicies || []).reduce((accumulator, policy) => {
        accumulator[_.get(policy, '_id')] = true;
        return accumulator;
      }, {})
    );
  }, [selectedPolicies]);

  const close = () => {
    setSelectedPoliciesPicked();
    onClose();
  };

  useEffect(() => {
    setSelectedPoliciesPicked();
  }, [selectedPolicies, setSelectedPoliciesPicked]);

  useEffect(() => {
    if (userId) {
      if (userId !== policiesOwner) {
        getPolicies(userId);
      }
    } else {
      resetPolicyPicker();
    }
  }, [userId, policiesOwner, getPolicies, resetPolicyPicker]);

  useEffect(() => {
    if (open && !previousOpen && getPoliciesPhase === INIT) {
      getPolicies(userId);
    }
  }, [previousOpen, open, userId, getPolicies, getPoliciesPhase]);

  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={SlideTransition}
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography gutterBottom={true} style={TitleTypoStyle}>
          {intl.formatMessage({ id: 'policy-picker-title' })}
        </Typography>
        <Typography color="textSecondary" style={HintTypoStyle}>
          {intl.formatMessage({ id: 'policy-picker-hint' })}
        </Typography>
      </DialogTitle>
      <DialogContent style={DialogContentStyle}>
        {getPoliciesPhase === LOADING ? (
          <div style={ProgressDivStyle}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div className="policy-review">
            <PolicyPickerTable
              policies={filteredPolicies}
              picked={picked}
              currency={currency}
              createTogglePickedHandler={createTogglePickedHandler}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'Close' })}
        </Button>
        <Button color="primary" onClick={submit}>
          {intl.formatMessage({ id: 'Submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    ...state.policyPicker
  }),
  {
    getPolicies,
    resetPolicyPicker
  }
)(PolicyPicker);

export default injectIntl(container);
