import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

class FreeToolsView extends PureComponent {
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }
  render() {
    const { intl } = this.props;
    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid policyInsuranceType">
              <div className="row">
                <div className="col-lg-12">
                  <ul className="card-list-insurance card-list-category insure">
                    <li>
                      <Link to="/dice">
                        <div className="checkout_img">
                          <img src="img/diceTools.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'Lucky Game' })}</h4>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/targetsCalculator">
                        <div className="checkout_img">
                          <img src="img/pfinancing.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'Target Calculator' })}</h4>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FreeToolsView;
