// interpolate string
// "${varA} >= 5 && ${varB} <= 10", { varA: 3, varB: 200 } ==> "3 >= 5 && 200 <= 10"
// ref: https://gist.github.com/smeijer/6580740a0ff468960a5257108af1384e
export const interpolateString = (str, variables) => {
  return (str || '').replace(/\${([^}]+)}/g, (m, p) => {
    const splitReduce = p.split('.').reduce((a, f) => (a ? a[f] : undefined), variables);
    return splitReduce !== null && splitReduce !== undefined ? splitReduce : m;
  });
};

export const crypt = (str, key) => {
  let me = str + '';

  key = Number(String(Number(key))) === key ? Number(key) : 13;

  me = me
    .split('')
    .map(c => c.charCodeAt(0))
    .map(i => i ^ key)
    .map(i => String.fromCharCode(i))
    .join('');

  return me;
};

export const isEmptyString = str => {
  return (str || '').trim() === '';
};
