import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import ReactEcharts from 'echarts-for-react';

class TimeAvailableChart extends PureComponent {
  render() {
    const { intl, r1, QEnd, chartData, ...rest } = this.props;

    //time available chart
    const timeAvailableChartOption = {
      title: {
        show: false
      },
      animationDuration: 1200,
      animationDurationUpdate: 1200,
      grid: {
        show: false,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      // tooltip: {
      //   formatter: '{b}: {c}'
      // },
      legend: {
        show: false
      },
      toolbox: {},
      series: [
        {
          name: intl.formatMessage({ id: 'Life Protection Score' }),
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          radius: '100%',
          innerSize: '8%',
          innerRadius: 0.9,
          data: [
            {
              value: QEnd === '-' || QEnd === 0 ? 100 : chartData >= 100 ? 100 - chartData : chartData, //QEnd && QEnd !== '-' ? 100 - QEnd : 100, //isNaN(parseInt(mm)) ? 0 : parseInt(mm),

              name: intl.formatMessage({ id: 'Your score' })
            }
          ],
          axisLine: {
            // 坐标轴线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [
                [0.329, '#3eda7d'],
                [0.669, '#ffa600'],
                [1, '#FF0000']
              ],
              width: 10
            }
          },
          axisTick: {
            // 坐标轴小标记
            show: false // 属性show控制显示与否，默认不显示
          },
          axisLabel: {
            // 坐标轴文本标签，详见axis.axisLabel
            show: true,
            formatter: function(v) {
              switch (v + '') {
                case '10':
                  return r1 < 70 && r1 !== 0 && QEnd !== 0
                    ? '{badStyle|}'
                    : '{badStyle|' + intl.formatMessage({ id: '3 Mth' }) + '}'; //not show the 3 mnth if range less than
                case '50':
                  return r1 < 70 && r1 !== 0 && QEnd !== 0
                    ? '{fairStyle|}'
                    : '{fairStyle|' + intl.formatMessage({ id: '2 Mth' }) + '}'; //not show the 2 mnth if range less than
                case '90':
                  return r1 < 70 && r1 !== 0 && QEnd !== 0
                    ? '{goodStyle|}'
                    : '{goodStyle|' + intl.formatMessage({ id: '1 Mth' }) + '}'; //not show the 1 mnth if range less than
                default:
                  return '';
              }
            },
            color: '#000',
            fontWeight: 'bold',
            fontSize: 11,
            shadowBlur: 0,
            rich: {
              badStyle: {
                color: '#00000061',
                //fontWeight: 'bold',
                padding: [0, 0, 0, -20]
              },
              fairStyle: {
                color: '#00000061',
                //fontWeight: 'bold',
                padding: [0, 0, -20, 0]
              },
              goodStyle: {
                color: '#00000061',
                //fontWeight: 'bold',
                padding: [0, -20, 0, 0]
              }
            }
          },
          splitLine: {
            // 分隔线
            show: false // 默认显示，属性show控制显示与否
          },
          pointer: {
            length: '45%',
            width: 3
          },
          itemStyle: {
            color: '#3966f8',
            shadowBlur: 0
          },
          title: {
            show: false,
            offsetCenter: [0, 0], // x, y，单位px
            textStyle: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              color: '#333',
              fontSize: 13
            }
          },
          detail: {
            show: false,
            backgroundColor: 'rgba(0,0,0,0)',
            borderWidth: 0,
            borderColor: '#ccc',
            width: 0,
            height: 0,
            offsetCenter: [0, 0], // x, y，单位px
            // formatter: '{value}/100',
            textStyle: {
              // 其余属性默认使用全局文本样式，详见TEXTSTYLE
              color: 'auto',
              fontSize: 18
            }
          },
          tooltips: {
            formatter: '{a} <br/>{b} : {c}%'
          }
        }
      ]
    };

    return <ReactEcharts option={timeAvailableChartOption} {...rest} />;
  }
}

export default injectIntl(TimeAvailableChart);
