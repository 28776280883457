import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import _ from 'lodash';
import NotFound from '../NotFound/NotFound';
import { getRegion } from '../../utils/region';
import { getProFlagFromLocalStorage } from '../../utils/user-subscription';
import { getSearchObject } from '../../utils/router';

const AnotherPrivateRoute = props => {
  const { userType, component, render, ...rest } = props;

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userDetails = useSelector(state => state.user.userDetails);

  const timeout = !!window.localStorage.getItem('timeout');

  return (
    <Route
      {...rest}
      exact
      render={props => {
        if (isAuthenticated) {
          if (userType && _.get(userDetails, 'userType') !== userType) {
            return <div>{React.createElement(NotFound, props)}</div>;
          } else {
            return render(props);
          }
        } else {
          let email;
          if (props.location.pathname.indexOf('/subscription') > -1) {
            const currentSearchObject = getSearchObject({ location: props.location });
            if (currentSearchObject && currentSearchObject.email) {
              email = currentSearchObject.email;
            }
          }

          const searchObject = {
            region: getRegion(),
            pro: getProFlagFromLocalStorage()
          };

          if (email) {
            searchObject.email = email;
          }

          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?${new URLSearchParams(searchObject).toString()}`,
                state: { from: props.location, timeout }
              }}
            />
          );
        }
      }}
    />
  );
};

export default AnotherPrivateRoute;
