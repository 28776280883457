import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { formatNumber } from '../../../../utils/formatNumber';
import * as Exchange from '../../../../utils/currencyExchange';
import BlockingOverlay from '../../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import ProRequiredCard from '../../../../components/SubscriptionComponents/ProRequiredCard/ProRequiredCard';
import PolicyCountChart from './policy/PolicyCountChart/PolicyCountChart';
import PolicySavingCountChart from './policy/PolicySavingCountChart/PolicySavingCountChart';

class PolicyView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      lifeCount: 0,
      criticalCount: 0,
      medicalCount: 0,
      totalCount: 0,
      paymentOptions: 'Average Coverage',
      avgLifePolicyCoverage: 0,
      avgCICoverage: 0,
      avgLifeCoverage: 0,
      avgLifePolicyCoverageAllUser: 0,
      avgCICoverageAllUser: 0,
      avgLifeCoverageAllUser: 0,
      totalCiBetterThan: 0,
      totalLifeBetterThan: 0,
      totalLifePolicyBetterThan: 0,

      avgLifePolicyPremium: 0,
      avgCIPolicyPremium: 0,
      avgMedicalPolicyPremium: 0,
      avgSavingPolicyPremium: 0,
      avgILASPolicyPremium: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.chartRef = React.createRef();
    this.legendRef = React.createRef();
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChange(e) {
    let val = e.target.value;
    if (val === '1') {
      this.setState({ [e.target.name]: val });
    } else {
      this.setState({ modalIsOpen: true });
    }
  }

  updateData(props) {
    const { averageCoverageData, averagePremiumData, userDetails } = props;
    let selectedCurrency = _.get(userDetails, 'currency', 'HKD');
    if (averageCoverageData && averageCoverageData.connectedUserData !== undefined) {
      let connect = averageCoverageData.connectedUserData[0];
      let allUser = averageCoverageData.allUserData;
      var avgLifePolicyCoverage =
        connect !== undefined &&
        _.get(connect, 'avgLifePolicyCoverage') !== null &&
        _.get(connect, 'avgLifePolicyCoverage') !== undefined
          ? Exchange.currencyExchange(parseFloat(_.get(connect, 'avgLifePolicyCoverage')), selectedCurrency, 'HKD')
          : 0;
      var avgCICoverage =
        connect !== undefined &&
        _.get(connect, 'avgCICoverage') !== null &&
        _.get(connect, 'avgCICoverage') !== undefined
          ? Exchange.currencyExchange(parseFloat(_.get(connect, 'avgCICoverage', 0)), selectedCurrency, 'HKD')
          : 0;
      var avgLifeCoverage =
        connect !== undefined &&
        _.get(connect, 'avgLifeCoverage') !== null &&
        _.get(connect, 'avgLifeCoverage') !== undefined
          ? Exchange.currencyExchange(parseFloat(_.get(connect, 'avgLifeCoverage', 0)), selectedCurrency, 'HKD')
          : 0;

      var avgLifePolicyCoverageAllUser =
        allUser !== undefined && _.get(allUser, 'avgLifePolicyCoverage') !== null
          ? Exchange.currencyExchange(parseFloat(_.get(allUser, 'avgLifePolicyCoverage', 0)), selectedCurrency, 'HKD')
          : 0;
      var avgCICoverageAllUser =
        allUser !== undefined && _.get(allUser, 'avgCICoverage') !== null
          ? Exchange.currencyExchange(
              parseFloat(_.get(allUser, 'avgCICoverage', 0).toFixed(2)),
              selectedCurrency,
              'HKD'
            )
          : 0;
      var avgLifeCoverageAllUser =
        allUser !== undefined && _.get(allUser, 'avgLifeCoverage') !== null
          ? Exchange.currencyExchange(parseFloat(_.get(allUser, 'avgLifeCoverage', 0)), selectedCurrency, 'HKD')
          : 0;
      var totalCiBetterThan =
        connect !== undefined &&
        _.get(connect, 'totalCIbetterthan') !== null &&
        _.get(connect, 'totalCIbetterthan') !== undefined
          ? _.get(connect, 'totalCIbetterthan')
          : 0;
      var totalLifeBetterThan =
        connect !== undefined &&
        _.get(connect, 'totalLifePolicybetterthan') !== null &&
        _.get(connect, 'totalLifePolicybetterthan') !== undefined
          ? _.get(connect, 'totalLifePolicybetterthan')
          : 0;
      var totalLifePolicyBetterThan =
        connect !== undefined &&
        _.get(connect, 'totalLifebetterthan') !== null &&
        _.get(connect, 'totalLifebetterthan') !== undefined
          ? _.get(connect, 'totalLifebetterthan')
          : 0;
    }

    //data to display average premium of connected users in HKD monthly
    if (averagePremiumData) {
      var avgLifePolicyPremium =
        averagePremiumData !== undefined && _.get(averagePremiumData, 'avgLifePolicyPremium') !== null
          ? _.get(averagePremiumData, 'avgLifePolicyPremium', 0).toFixed(2)
          : 0;
      var avgCIPolicyPremium =
        averagePremiumData !== undefined && _.get(averagePremiumData, 'avgCIPolicyPremium') !== null
          ? _.get(averagePremiumData, 'avgCIPolicyPremium', 0).toFixed(2)
          : 0;
      var avgMedicalPolicyPremium =
        averagePremiumData !== undefined && _.get(averagePremiumData, 'avgMedicalPolicyPremium') !== null
          ? _.get(averagePremiumData, 'avgMedicalPolicyPremium', 0).toFixed(2)
          : 0;
      var avgSavingPolicyPremium =
        averagePremiumData !== undefined && _.get(averagePremiumData, 'avgSavingPolicyPremium') !== null
          ? _.get(averagePremiumData, 'avgSavingPolicyPremium', 0).toFixed(2)
          : 0;

      var avgILASPolicyPremium =
        averagePremiumData !== undefined && _.get(averagePremiumData, 'avgILASPolicyPremium') !== null
          ? _.get(averagePremiumData, 'avgILASPolicyPremium', 0).toFixed(2)
          : 0;

      this.setState({
        avgLifePolicyPremium: avgLifePolicyPremium,
        avgCIPolicyPremium: avgCIPolicyPremium,
        avgMedicalPolicyPremium: avgMedicalPolicyPremium,
        avgSavingPolicyPremium: avgSavingPolicyPremium,
        avgILASPolicyPremium: avgILASPolicyPremium
      });
    }

    this.setState({
      avgLifePolicyCoverage: avgLifePolicyCoverage,
      avgCICoverage: avgCICoverage,
      avgLifeCoverage: avgLifeCoverage,
      avgLifePolicyCoverageAllUser: avgLifePolicyCoverageAllUser,
      avgCICoverageAllUser: avgCICoverageAllUser,
      avgLifeCoverageAllUser: avgLifeCoverageAllUser,
      totalCiBetterThan: totalCiBetterThan,
      totalLifeBetterThan: totalLifeBetterThan,
      totalLifePolicyBetterThan: totalLifePolicyBetterThan
    });
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-next-line no-restricted-globals
    scrollTo(0, 0);
    this.props.policyCount();
    this.updateData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.updateData(nextProps);
  }

  componentDidUpdate() {
    const legendDiv = _.get(this.legendRef, 'current');
    const chartInstance = _.get(this.chartRef, 'current.chartInstance');
    if (legendDiv && chartInstance) {
      legendDiv.innerHTML = chartInstance.generateLegend();
    }
  }

  render() {
    const { getClientSavingILASPolicyData, userDetails } = this.props;
    let selectedCurrency = _.get(userDetails, 'currency', 'HKD');
    let ciBetterthan = this.state.totalCiBetterThan * 100;
    if (Number.isInteger(ciBetterthan) === true) {
      //eslint-disable-next-line
      ciBetterthan = ciBetterthan;
    } else {
      ciBetterthan = ciBetterthan && parseFloat(ciBetterthan).toFixed(1);
    }

    let ciBetterClass = '';

    //To change the color of Peer comparison chart Better than percentage of ci
    if (ciBetterthan < 33) {
      ciBetterClass = 'client-shortfall-value-bad';
    } else if (ciBetterthan >= 33 && ciBetterthan <= 66) {
      ciBetterClass = 'client-shortfall-value-fair';
    } else if (ciBetterthan > 66) {
      ciBetterClass = 'client-shortfall-value-good';
    }

    let TotalLifeBetterThan = this.state.totalLifeBetterThan * 100;
    if (Number.isInteger(TotalLifeBetterThan) === true) {
      //eslint-disable-next-line
      TotalLifeBetterThan = TotalLifeBetterThan;
    } else {
      TotalLifeBetterThan = TotalLifeBetterThan && parseFloat(TotalLifeBetterThan).toFixed(1);
    }
    let LifeBetterClass = '';
    //To change the color of Peer comparison chart Better than percentage of totallife
    if (TotalLifeBetterThan < 33) {
      LifeBetterClass = 'client-shortfall-value-bad';
    } else if (TotalLifeBetterThan >= 33 && TotalLifeBetterThan <= 66) {
      LifeBetterClass = 'client-shortfall-value-fair';
    } else if (TotalLifeBetterThan > 66) {
      LifeBetterClass = 'client-shortfall-value-good';
    }

    let TotalLifePolicyBetterThan = this.state.totalLifePolicyBetterThan * 100;
    let TotalLifeBetterClass = '';

    //To change the color of Peer comparison chart Better than percentage of life policy only
    if (TotalLifePolicyBetterThan < 33) {
      TotalLifeBetterClass = 'client-shortfall-value-bad';
    } else if (TotalLifePolicyBetterThan >= 33 && TotalLifePolicyBetterThan <= 66) {
      TotalLifeBetterClass = 'client-shortfall-value-fair';
    } else if (TotalLifePolicyBetterThan > 66) {
      TotalLifeBetterClass = 'client-shortfall-value-good';
    }
    if (Number.isInteger(TotalLifePolicyBetterThan) === true) {
      //eslint-disable-next-line
      TotalLifePolicyBetterThan = TotalLifePolicyBetterThan;
    } else {
      TotalLifePolicyBetterThan = TotalLifePolicyBetterThan && parseFloat(TotalLifePolicyBetterThan).toFixed(1);
    }

    let { policyCountData, intl } = this.props;
    let totalCount = _.get(policyCountData, 'totalPolicy', 0);
    let lifeCount = _.get(policyCountData, 'policyData.lifeCount', 0);
    let medicalCount = _.get(policyCountData, 'policyData.medicalCount', 0);
    let criticalCount = _.get(policyCountData, 'policyData.illnessCount', 0);
    let savingCount = _.get(policyCountData, 'policyData.savingCount', 0);
    let savingILASCount = _.get(policyCountData, 'policyData.savingILASCount', 0);

    let countwithGuaranttee = _.get(getClientSavingILASPolicyData[0], 'countwithGuaranttee', 0);
    let countwithILAS = _.get(getClientSavingILASPolicyData[0], 'countwithILAS', 0);
    let countNoIdea = _.get(getClientSavingILASPolicyData[0], 'countNoIdea', 0);

    var averageChartData = {
      labels: [
        intl.formatMessage({ id: 'Life' }),
        intl.formatMessage({ id: 'Critical Illness' }),
        intl.formatMessage({ id: 'Overall Life' })
      ],
      datasets: [
        {
          backgroundColor: '#44d9e6',
          borderColor: '#44d9e6',
          borderWidth: 0,
          data: [
            Math.floor(this.state.avgLifePolicyCoverage / 10000) * 10000,
            Math.floor(this.state.avgCICoverage / 10000) * 10000,
            Math.floor(this.state.avgLifeCoverage / 10000) * 10000
          ],
          label: intl.formatMessage({ id: 'Clients' }),
          barThickness: 15
        },
        {
          backgroundColor: '#3966f8',
          borderColor: '#3966f8',
          borderWidth: 0,
          data: [
            Math.floor(this.state.avgLifePolicyCoverageAllUser / 10000) * 10000,
            Math.floor(this.state.avgCICoverageAllUser / 10000) * 10000,
            Math.floor(this.state.avgLifeCoverageAllUser / 10000) * 10000
          ],
          label: intl.formatMessage({ id: 'PFPlus' }),
          barThickness: 15
        }
      ]
    };

    let averageChartOptions = {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: true,
        labels: {
          fontSize: 10,
          usePointStyle: true,
          boxWidth: 11
        }
      },
      title: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(label) {
                return formatNumber(label, intl);
              }
            }
          }
        ]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += intl.formatMessage({ id: selectedCurrency }) + ' ';
            label += tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            return label;
          }
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    };

    let averageMonthlyChartOptions = {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      responsive: true,
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          fontSize: 10,
          usePointStyle: true,
          boxWidth: 11
        }
      },
      title: {
        display: false
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function(label) {
                return formatNumber(label, intl);
              }
            }
          }
        ]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += intl.formatMessage({ id: selectedCurrency }) + ' ';
            label += tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            return label;
          }
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    };

    var averagePremiumChartData = {
      labels: [
        intl.formatMessage({ id: 'Life' }),
        intl.formatMessage({ id: 'C.I.' }),
        intl.formatMessage({ id: 'Med' }),
        intl.formatMessage({ id: 'Saving' }),
        intl.formatMessage({ id: 'ILAS' })
      ],
      datasets: [
        {
          backgroundColor: ['#3eda7d', '#FF6A74', '#3966f8', '#8c1aff', '#BF80FF'],
          //backgroundColor: '#44d9e6',
          borderColor: ['#3eda7d', '#FF6A74', '#3966f8', '#8c1aff', '#BF80FF'],
          borderWidth: 0,
          maxBarThickness: 15,
          data: [
            Math.floor(this.state.avgLifePolicyPremium / 100) * 100,
            Math.floor(this.state.avgCIPolicyPremium / 100) * 100,
            Math.floor(this.state.avgMedicalPolicyPremium / 100) * 100,
            Math.floor(this.state.avgSavingPolicyPremium / 100) * 100,
            Math.floor(this.state.avgILASPolicyPremium / 100) * 100
          ],
          label: intl.formatMessage({ id: 'Premium' })
        }
      ]
    };

    let HKDWord = intl.formatMessage({ id: selectedCurrency });
    let renderShortfallValue = value => {
      value = value === undefined || value === 0 ? '-' : formatNumber(value, intl);
      return HKDWord + ' ' + value;
    };

    // var ctx = 'canvas';
    /*    var chart = new Chart('line-chart', {
		  type: 'bar',
		  data: barChartData,
		  options: {
			responsive: true,
			legend: {
			  position: 'top',
			  display: false
			},
			title: {
			  display: true
			},
			scales: {
			  yAxes: [
				{
				  ticks: {
					beginAtZero: true
				  }
				}
			  ],
			  xAxes: [
				{
				  barThickness: 25
				}
			  ]
			},
			plugins: {
			  datalabels: {
				display: false
			  }
			}
		  }
    });*/
    //To round the edge of outer chart
    let pluginVar = [
      {
        afterUpdate: function(chart) {
          let offset = chart.config.data.datasets.length - 1;
          for (let key in chart.config.data.datasets) {
            for (let i = 0; i < chart.getDatasetMeta(key).data.length; i++) {
              let arc = chart.getDatasetMeta(key).data[i];
              arc.round = {
                x: (chart.chartArea.left + chart.chartArea.right) / 2,
                y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                radius: chart.innerRadius + chart.radiusLength / 2 + offset * chart.radiusLength,
                thickness: Math.abs(chart.radiusLength / 2 - 1),
                backgroundColor: arc._model.backgroundColor
              };
            }
            offset--;
          }
        }
      },
      {
        afterDatasetDraw: function(chart) {
          let ctx = chart.chart.ctx;
          for (let key in chart.config.data.datasets) {
            for (let i = 0; i < chart.getDatasetMeta(key).data.length; i++) {
              let arc = chart.getDatasetMeta(key).data[i];
              // let startAngle = Math.PI / 2 - arc._view.startAngle;
              let endAngle = Math.PI / 2 - arc._view.endAngle;

              ctx.save();
              ctx.translate(arc.round.x, arc.round.y);
              ctx.fillStyle = arc.round.backgroundColor;
              ctx.beginPath();
              ctx.arc(
                arc.round.radius * Math.sin(endAngle),
                arc.round.radius * Math.cos(endAngle),
                arc.round.thickness,
                0,
                2 * Math.PI
              );
              ctx.closePath();
              ctx.fill();
              ctx.restore();
            }
          }
        }
      }
    ];

    // console.log("DoughnutForSavingCount")
    // console.log(DoughnutForSavingCount)
    // console.log("DoughnutForSavingCountConfig")
    // console.log(DoughnutForSavingCountConfig)
    return (
      <div>
        <div className="clientDash advisorCard">
          <ProRequiredCard sectionTitleId="Big Data" style={{ width: '100%', marginTop: 10 }} />

          <BlockingOverlay>
            <div className="cardAnalysis adv-dash-card">
              <h4 className="text-center adv-dash-chart-title">{intl.formatMessage({ id: 'Number of Policies' })}</h4>
              <div className="Outer" style={{ marginTop: '1px' }}>
                <PolicyCountChart
                  counts={{
                    lifeCount: lifeCount,
                    medicalCount: medicalCount,
                    criticalCount: criticalCount,
                    savingCount: savingCount,
                    savingILASCount: savingILASCount
                  }}
                />
              </div>
            </div>

            <div className="cardAnalysis adv-dash-card">
              <h4 className="text-center adv-dash-chart-title">
                {intl.formatMessage({ id: 'Average Monthly Premium' })}
              </h4>
              <div className="Outer" style={{ marginTop: '1px' }}>
                <Bar data={averagePremiumChartData} options={averageMonthlyChartOptions} height={200} />
              </div>
            </div>
            {/* Edit graph here */}
            {/* {!productionEnv && (
                      <li className="tile chart">
                        <div className="Outer" style={{ marginTop: '1px' }}>
                          <PolicyProgressBar percentage={'30'} text={`30%`} />
                        </div>
                      </li>
                    )} */}
            <div className="cardAnalysis adv-dash-card">
              <h4 className="text-center adv-dash-chart-title">{intl.formatMessage({ id: 'Average Coverage' })}</h4>
              <div className="Outer" style={{ marginTop: '1px' }}>
                <Bar data={averageChartData} options={averageChartOptions} height={200} />
              </div>
            </div>
            <div className="cardAnalysis adv-dash-card">
              <h4 className="text-center adv-dash-chart-title">{intl.formatMessage({ id: 'Peers Comparison' })}</h4>
              <div className="peers__table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{intl.formatMessage({ id: 'My clients' })}</th>
                      <th>{intl.formatMessage({ id: 'AvgValue' })}</th>
                      <th>{intl.formatMessage({ id: 'Better than' })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{intl.formatMessage({ id: 'Life' })}</td>
                      <td>{renderShortfallValue(this.state.avgLifePolicyCoverage)}</td>
                      <td className={TotalLifeBetterClass}>{TotalLifePolicyBetterThan}%</td>
                    </tr>
                    <tr>
                      <td>{intl.formatMessage({ id: 'Critical Illness' })}</td>
                      <td>{renderShortfallValue(this.state.avgCICoverage)}</td>
                      <td className={ciBetterClass}>{ciBetterthan}%</td>
                    </tr>
                    <tr>
                      <td>{intl.formatMessage({ id: 'Overall Life' })}</td>
                      <td>{renderShortfallValue(this.state.avgLifeCoverage)}</td>
                      <td className={LifeBetterClass}>{TotalLifeBetterThan}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="cardAnalysis adv-dash-card">
              <h4 className="text-center adv-dash-chart-title">{intl.formatMessage({ id: 'Saving Policies' })}</h4>
              <div className="Outer" style={{ marginTop: '1px' }}>
                <PolicySavingCountChart
                  counts={{
                    countwithGuaranttee: countwithGuaranttee,
                    countwithILAS: countwithILAS,
                    countNoIdea: countNoIdea
                  }}
                />
              </div>
            </div>
            {/* <div className="cardAnalysis adv-dash-card">
              <div className="form-group adv-dash-select select__flex">
                <select
                  name="paymentOptions"
                  onChange={this.handleChange}
                  onClick={this.openModal}
                  value={this.state.paymentOptions}
                >
                  <option value="1">{intl.formatMessage({ id: 'Average Coverage' })}</option>
                  <option value="2">
                    {intl.formatMessage({
                      id: 'Premium Vs Protection'
                    })}
                  </option>
                  <option value="3">
                    {intl.formatMessage({
                      id: 'Average coverage by age'
                    })}
                  </option>
                  <option value="4">{intl.formatMessage({ id: 'Average Premium' })}</option>
                  <option value="5">
                    {intl.formatMessage({
                      id: 'Average no. of policies'
                    })}
                  </option>
                  <option value="6">{intl.formatMessage({ id: 'Top 5 Insurers' })}</option>
                  <option value="7">
                    {intl.formatMessage({
                      id: 'Saving vs Non Saving vs No idea'
                    })}
                  </option>
                </select>
                <ExpandIconButton />
              </div>
            </div> */}
          </BlockingOverlay>
        </div>
        {/* <AlertPopUp
          intl={intl}
          open={this.state.modalIsOpen}
          handleClose={this.closeModal}
          title={intl.formatMessage({ id: 'more_function_title' })}
          content={intl.formatMessage({
            id: 'The selected item is a premium function'
          })}
          showOk={true}
        /> */}
      </div>
    );
  }
}

export default PolicyView;
