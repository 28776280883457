import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, SUCCESS, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';

/***********************************
 * Action Types
 ***********/

export const GET_UPLOAD_DOCUMENTS = 'portfoplus/file/GET_UPLOAD_DOCUMENTS';
export const GET_UPLOAD_DOCUMENTS_SUCCESS = 'portfoplus/file/GET_UPLOAD_DOCUMENTS_SUCCESS';
export const GET_UPLOAD_DOCUMENTS_ERROR = 'portfoplus/file/GET_UPLOAD_DOCUMENTS_ERROR';

export const DOCUMENT_POLICY = 'portfoplus/file/DOCUMENT_POLICY';
export const HANDLE_DOCUMENTS = 'portfoplus/file/HANDLE_DOCUMENTS';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated

  fetchPhase: INIT,
  sharePhase: INIT,
  getDocPhase: INIT,
  fileData: [],
  resMessage: null,
  documentsPolicy: [],
  documents: [],
  error: null,
  isSubmitting: false
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_UPLOAD_DOCUMENTS: {
      return state
        .set('fetchPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_UPLOAD_DOCUMENTS_SUCCESS: {
      const { payload } = action;
      return state
        .set('fileData', payload.data)
        .set('fetchPhase', SUCCESS)
        .set('error', null);
    }

    case GET_UPLOAD_DOCUMENTS_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('fetchPhase', ERROR);
    }

    case DOCUMENT_POLICY: {
      const { payload } = action;
      return state.set('documentsPolicy', payload);
    }

    case HANDLE_DOCUMENTS: {
      const { payload } = action;
      return state.set('documents', payload);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getUploadDocuments = data => ({
  type: GET_UPLOAD_DOCUMENTS,
  payload: data
});

export const handleDocumentsPolicy = data => ({
  type: DOCUMENT_POLICY,
  payload: data
});

export const handleDocuments = data => ({
  type: HANDLE_DOCUMENTS,
  payload: data
});

/***********************************
 * Epics
 ***********/

const getUploadDocumentsEpic = action$ =>
  action$.ofType(GET_UPLOAD_DOCUMENTS).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getUploadDocuments(action.payload))
      .map(payload => ({
        type: GET_UPLOAD_DOCUMENTS_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_UPLOAD_DOCUMENTS_ERROR,
          payload: { error }
        })
      );
  });

export const fileEpic = combineEpics(getUploadDocumentsEpic);
