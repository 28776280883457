import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import FlipMove from 'react-flip-move';
import { Button, IconButton, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import TemplateRow from './TemplateRow/TemplateRow';
import TemplateEditor from './TemplateEditor/TemplateEditor';
import TemplateMenu from './TemplateMenu/TemplateMenu';
import TemplateDialog from './TemplateDialog/TemplateDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import StarIcon from '@material-ui/icons/Star';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';
import {
  updateAdviserTemplate,
  addAdviserTemplate,
  deleteAdviserTemplate,
  deleteAllTemplate
} from '../../store/template/duck';
import './Template.scss';
import IconToast from '../NewToast';
import { tracking } from '../../utils/tracking';

const styles = {
  root: {
    width: '100%',
    maxWidth: 300,
    wordWrap: 'break-word'
  },
  disabled: {
    fill: '#808080!important'
  },
  addBtn: {
    flex: 1,
    height: '50px'
  }
};

class Template extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.templates = React.createRef();
    //filter arrays
    const importantTemplate = props.template.filter(e => e.important).reverse();
    const template = props.template.filter(e => !e.important).reverse();

    this.state = {
      edit: false,
      importantTemplate,
      template,
      dropDownAnchor: null,
      localSubmit: false,
      initialExpand: true,
      expandCompleted: true,
      showDeleteModal: false,
      focus: false,
      noteEditorDialog: false,
      helpDialog: false
    };
  }

  componentDidMount() {
    this.templates.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submit && !this.props.submit) {
      if (this.props.error) {
        toast.info(IconToast('error', this.props.intl.formatMessage({ id: 'todo-err-msg' })), {
          className: 'new-toast'
        });
        this.setState({ localSubmit: false });
      }
      const importantTemplate = this.props.template.filter(e => e.important).reverse();
      const template = this.props.template.filter(e => !e.important).reverse();
      this.setState({
        localSubmit: false,
        importantTemplate,
        template,
        showDeleteModal: false,
        edit: this.state.localSubmit ? false : this.state.edit,
        focus: this.state.localSubmit ? false : this.state.focus
      });
    }
  }

  toggleTemplateForm = () => {
    this.setState({ edit: !this.state.edit, focus: !this.state.focus });
  };

  //delete modal
  onModalClose = () => this.setState({ showDeleteModal: false });

  //editor callback
  onEditorSave = () => this.setState({ localSubmit: true });

  //Drop down menu methods
  openDropDown = e => this.setState({ dropDownAnchor: e.currentTarget });
  onDropDownClose = () => this.setState({ dropDownAnchor: null });

  openDeleteModal = () => this.setState({ showDeleteModal: true, dropDownAnchor: null });

  getRow = value => {
    return (
      <TemplateRow
        key={value._id}
        template={value}
        submit={this.props.submit}
        updateAdviserTemplate={this.props.updateAdviserTemplate}
        deleteAdviserTemplate={this.props.deleteAdviserTemplate}
        intl={this.props.intl}
        closeTemplate={this.props.overlay ? null : this.props.close}
      />
    );
  };

  toggleExpand = () => {
    if (this.state.initialExpand) {
      this.setState({ expandCompleted: !this.state.expandCompleted, initialExpand: false });
    } else {
      this.setState({ expandCompleted: !this.state.expandCompleted });
    }
  };

  openNotePad = () => {
    // Tracking
    tracking('Access Tools: My Notes');
    this.setState({ noteEditorDialog: true });
  };
  closeNotePad = () => this.setState({ noteEditorDialog: false });

  openHelpDialog = () => this.setState({ helpDialog: true });
  closeHelpDialog = () => this.setState({ helpDialog: false });

  render() {
    return (
      <div className="template">
        <TemplateDialog
          showDeleteModal={this.state.showDeleteModal}
          onModalClose={this.onModalClose}
          deleteAllTemplate={this.props.deleteAllTemplate}
        />
        <Dialog
          open={this.state.helpDialog}
          onClose={this.closeHelpDialog}
          keepMounted
          TransitionComponent={SlideTransition}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{this.props.intl.formatMessage({ id: 'template-help-title' })}</DialogTitle>
          <DialogContent>{this.props.intl.formatMessage({ id: 'template-help-content' })}</DialogContent>
          <DialogActions>
            <Button onClick={this.closeHelpDialog} color="primary">
              {this.props.intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="top-bar">
          <IconButton
            edge="start"
            onClick={this.props.close}
            disabled={this.props.overlay}
            style={this.props.overlay ? { visibility: 'hidden' } : {}}
          >
            <CloseIcon />
          </IconButton>
          <div className="title">{this.props.intl.formatMessage({ id: 'template' })}</div>
          <IconButton onClick={this.openDropDown}>
            <MoreVertIcon />
          </IconButton>
          <TemplateMenu
            onDropDownClose={this.onDropDownClose}
            dropDownAnchor={this.state.dropDownAnchor}
            sort={this.props.sort}
            handleChangeSort={this.props.updateSort}
            openDeleteModal={this.openDeleteModal}
          />
        </div>
        <Collapse in={!this.state.edit}>
          <div className="add-row">
            <Button classes={{ root: this.props.classes.addBtn }} onClick={this.toggleTemplateForm}>
              <div className="full-width">
                <AddCircleRoundedIcon className="add-btn" />
                {this.props.intl.formatMessage({ id: 'add-template' })}
              </div>
            </Button>
            <div>
              <IconButton onClick={this.openHelpDialog}>
                <HelpOutlineIcon className="template-help" />
              </IconButton>
            </div>
          </div>
        </Collapse>
        <Collapse in={this.state.edit}>
          <TemplateEditor
            onSave={this.onEditorSave}
            template={{ template: '', important: false }}
            submit={this.state.localSubmit}
            closeEditor={this.toggleTemplateForm}
            focus={this.state.focus}
            addAdviserTemplate={this.props.addAdviserTemplate}
          />
        </Collapse>
        <div className="list-container" ref={this.templates}>
          <FlipMove className={this.props.classes.root}>
            {this.state.importantTemplate.map(this.getRow)}
            <div className="other-template" />
            {this.state.template.map(this.getRow)}
          </FlipMove>
        </div>
      </div>
    );
  }
}

const container = withStyles(styles)(
  connect(
    // Map state to props
    state => ({
      template: state.user.adviserTemplate,
      submit: state.template.isSubmitting,
      error: state.template.error
    }),
    // Map actions to props
    {
      updateAdviserTemplate,
      addAdviserTemplate,
      deleteAdviserTemplate,
      deleteAllTemplate
    },
    // mergeProps
    null,
    // options
    {}
  )(Template)
);

export default injectIntl(container);
