import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import './ShowThankYouModal.css';
import { readThankYousApi } from '../../store/thankYou/api';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ContactButton from '../../views/Advisor/Client/partials/ContactButton/ContactButton';
import ClientContactModal from '../../views/Advisor/Client/partials/ClientContactModal/ClientContactModal';
import Lottie from '../Lottie/Lottie';

let counter = 0;

class ShowThankYouModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      checkedCounter: -1,
      contactData: {},
      contactModalIsOpen: false
    };

    this.checkCounterTimeout = undefined;
    this.startCheckCountTimeout = undefined;
  }

  UNSAFE_componentWillMount() {
    counter = 0;
  }

  componentDidMount() {
    const { unreadThankYous } = this.props;
    if (unreadThankYous.length > 0) {
      this.setState({ isOpen: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (counter !== undefined) {
      counter++;
      let cloneCounter = counter;

      if (this.state.isOpen === true) {
        this.checkCounterTimeout = setTimeout(() => {
          if (this !== undefined && this !== null && counter === cloneCounter) {
            counter = undefined;
            this.startCheckedCount();
          }
        }, 1500);
      }
    }
  }

  componentWillUnmount() {
    if (this.checkCounterTimeout !== undefined) {
      clearTimeout(this.checkCounterTimeout);
    }
    if (this.startCheckCountTimeout !== undefined) {
      clearTimeout(this.startCheckCountTimeout);
    }
  }

  startCheckedCount() {
    if (this !== undefined && this !== null) {
      const { unreadThankYous } = this.props;
      const { checkedCounter } = this.state;
      if (checkedCounter <= unreadThankYous.length - 1) {
        this.setState({ checkedCounter: checkedCounter + 1 });
        this.startCheckCountTimeout = setTimeout(() => {
          if (this !== undefined && this !== null) {
            this.startCheckedCount();
          }
        }, 300);
      }
    }
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  showContactModal(thankYou) {
    const { userDetails } = this.props;

    this.setState({
      contactData: {
        _id: thankYou.userId,
        advisorDetails: [
          {
            email: userDetails.email,
            connection: true,
            factsheetId: thankYou.factsheet
          }
        ]
      },
      contactModalIsOpen: true
    });
  }

  render() {
    const { intl, userDetails, unreadThankYous, adviserBusinessDefaultMessage } = this.props;
    const { isOpen, checkedCounter, contactModalIsOpen, contactData } = this.state;

    return (
      <div>
        <Dialog
          className="show-thank-you-modal"
          open={isOpen && unreadThankYous && unreadThankYous.length > 0}
          onExit={() => readThankYousApi()}
        >
          <DialogTitle className="dialog-title">{intl.formatMessage({ id: 'show-thank-you-title' })}</DialogTitle>
          <DialogContent className="dialog-content">
            <List className="thank-you-list" dense={false}>
              {unreadThankYous.map((thankYou, index) => (
                <ListItem key={index}>
                  <ListItemText primary={thankYou.factsheet.name} secondary={thankYou.factsheet.email} />
                  <ListItemSecondaryAction>
                    <ContactButton
                      className="contact-button"
                      active={true}
                      onClick={() => this.showContactModal(thankYou)}
                      msgOptButton={false}
                    />
                    <Lottie
                      style={{
                        width: '100px',
                        height: '100px'
                      }}
                      options={{
                        animationData: 'animations/heart.json',
                        loop: false,
                        autoplay: false
                      }}
                      isPlaying={checkedCounter >= index}
                    />
                    {/*<AnimatedHeart checked={checkedCounter >= index} />*/}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <div className="content-footer">{intl.formatMessage({ id: 'show-thank-you-footer' })}</div>
          </DialogContent>
          {checkedCounter > unreadThankYous.length - 1 && (
            <DialogActions className="dialog-actions">
              <Button onClick={() => this.closeModal()} color="primary" autoFocus>
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          )}
        </Dialog>

        <ClientContactModal
          open={contactModalIsOpen}
          onClose={() => this.setState({ contactModalIsOpen: false })}
          intl={intl}
          userDetails={userDetails}
          clientDetails={contactData}
          defaultMessage={adviserBusinessDefaultMessage}
        />
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    adviserBusinessDefaultMessage: state.adviserDashboard.adviserBusiness
      ? state.adviserDashboard.adviserBusiness.defaultMessage
      : undefined
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(ShowThankYouModal);

export default injectIntl(container);
