import React from 'react';
import { Drawer, Menu, MenuItem, Popover, IconButton, Portal, Zoom } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import FeedBackModal from './FeedbackModal/FeedBackModal';
import ProBadge from './SubscriptionComponents/ProBadge/ProBadge';
import ConnectWithAdviser from './connectWithAdviser';
import _ from 'lodash';
import NotificationBellButton from './NotificationBellButton/NotificationBellButton';
import NotificationCenterModal from './NotificationCenterModal/NotificationCenterModal';
import { getNotificationCenterList, markNotificationAsRead } from '../store/notificationCenter/duck';
import { openHeaderMenu, closeHeaderMenu, renderHeaderButton } from '../store/headerButtonControl/duck';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withRouter } from 'react-router';
import topNavTitle from './topNavTitle';
import topNavTitlePro from './topNavTitlePro';
import TodoIconButton from './TodoList/TodoIconButton/TodoIconButton';
import TodoList from './TodoList/TodoList';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import './topNavigation.scss';
import { toggleDialog } from '../store/control/duck';
import { runGuideline } from '../store/guideline/duck';
import { updateUserDetails } from '../store/user/duck';
import IconToast from './NewToast';
import NavDrawer from './NavDrawer/NavDrawer';
import FooterNav from './FooterNav/FooterNav';
import Template from './Template/Template';
import ClientDashboardDrawer from './ClientDashboardDrawer/ClientDashboardDrawer';
import UserMissions from './UserMissions/UserMissions';
import { getNotificationImg } from '../utils/notification-center';
import { selectedLocale } from '../store/locale/action';
import { tracking } from '../utils/tracking';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { MENU_BUTTON_PATHS } from '../constants/paths';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { tryTranslate } from '../utils/locale';
import CustomTopNavigation from './CustomTopNavigation/CustomTopNavigation';
import { sanitize } from 'dompurify';
import NFCAutoGenerateButton from '../views/Advisor/Referral/partials/NFCAutoGenerateButton/NFCAutoGenerateButton';
import { shouldEnableNFCAutoGen } from '../utils/user';

const topNavTitleEntries = Object.entries(topNavTitle);

const UserMissionsButtonProps = { style: { position: 'absolute', left: 50, bottom: 0, zIndex: 1200, color: '#fff' } };

const NavMenuButtonStyle = {
  position: 'absolute',
  width: 'auto',
  height: '100%',
  top: '50%',
  left: 0,
  transform: 'translate(0, -50%)',
  color: '#fff',
  padding: 8,
};
const NavMenuButtonIconStyle = { fontSize: '32px' };

export const NavMenuButton = (props) => {
  const { className, style, ...rest } = props;

  return (
    <IconButton
      className={`nav-menu-button${className ? ` ${className}` : ''}`}
      style={{ ...NavMenuButtonStyle, ...style }}
      {...rest}
    >
      <MenuRoundedIcon style={NavMenuButtonIconStyle} />
    </IconButton>
  );
};

class TopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      open: false,
      openPopup: false,
      activeTab: 'Portfolio',
      feedBackModalOpen: false,
      connectModalOpen: false,
      showModal: false,
      todoOpen: false,
      templateOpen: false,
    };
    this.notificationPushed = false;
    this.notificationCenterPushed = [];
    //this.submitFormOfFeedBack = this.submitFormOfFeedBack.bind(this);
    if (!localStorage.getItem('notificationCenterPushed')) {
      localStorage.setItem('notificationCenterPushed', JSON.stringify(this.notificationCenterPushed));
    }
  }

  componentDidMount() {
    const { userDetails, getReminderBadgeCount, getNotifications, getNotificationCenterList, location } = this.props;

    window.addEventListener('resize', this.handleResize, false);

    let userType = userDetails && userDetails.userType;

    if (userType === 'User' && getReminderBadgeCount !== undefined && typeof getReminderBadgeCount === 'function') {
      getReminderBadgeCount();
    }

    if (getNotifications !== undefined && typeof getNotifications === 'function') {
      getNotifications();
    }
    if (
      getNotificationCenterList !== undefined &&
      typeof getNotificationCenterList === 'function' &&
      (location.pathname === '/advisorDashboard' || location.pathname === '/dashboard')
    ) {
      getNotificationCenterList();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
  }

  handleResize = () => {
    const width = window.innerWidth;
    this.setState({ width });
  };

  parseQuery = (string) =>
    _.chain(string)
      .replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
      .split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
      .map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
      .value();

  UNSAFE_componentWillReceiveProps(props) {
    const { notifications, showToast, adviserAllModalDone, clientAllModalDone, userDetails } = props;

    if (userDetails && userDetails.userType === 'Adviser') {
      let shouldCloseTodoAndTemplate = false;
      const pathNotSame = props.location.pathname !== this.props.location.pathname;
      const queryNotSame = props.location.search !== this.props.location.search;
      const isBusinessPage = !pathNotSame && queryNotSame && props.location.pathname === '/adviser_business';
      if (isBusinessPage) {
        const newQueryParam = this.parseQuery(props.location.search);
        const oldQueryParam = this.parseQuery(this.props.location.search);
        const ignoreChange =
          newQueryParam.businessTabIndex === oldQueryParam.businessTabIndex &&
          newQueryParam.assistantCategoryIndex === oldQueryParam.assistantCategoryIndex;
        if (!ignoreChange && (this.state.todoOpen || this.state.templateOpen)) {
          shouldCloseTodoAndTemplate = true;
        }
      } else if (pathNotSame && (this.state.todoOpen || this.state.templateOpen)) {
        shouldCloseTodoAndTemplate = true;
      }
      if (shouldCloseTodoAndTemplate) {
        this.closeTodoAndTemplate();
      }
    }

    if (notifications && notifications.length > 0 && !this.notificationPushed) {
      this.notificationPushed = true;
      notifications.forEach(function (value) {
        if (value.type === 'info') {
          toast.info(IconToast('info', value.message), { autoClose: false, className: 'new-toast' });
        } else if (value.type === 'success') {
          toast.info(IconToast('success', value.message), { autoClose: false, className: 'new-toast' });
        } else if (value.type === 'warning') {
          toast.info(IconToast('warn', value.message), { autoClose: false, className: 'new-toast' });
        } else if (value.type === 'error') {
          toast.info(IconToast('error', value.message), { autoClose: false, className: 'new-toast' });
        } else {
          toast.info(IconToast('info', value.message), { autoClose: false, className: 'new-toast' });
        }
      });
    }
    if (showToast && showToast.length > 0) {
      if (
        (adviserAllModalDone && userDetails.userType === 'Adviser') ||
        (clientAllModalDone && userDetails.userType === 'User')
      ) {
        this.handleShowToast(showToast);
      }
    }

    let currentStepIndex = props.guidelineStepIndex;
    let steps = this.props.guidelineSteps;

    if (
      this.props.guidelineStepIndex !== currentStepIndex &&
      props.guidelineIsStarted &&
      steps[currentStepIndex] &&
      steps[currentStepIndex].requireAction
    ) {
      props.closeHeaderMenu();
    }
  }

  handleClickToastLinkTo = (notification, lang) => {
    let { markNotificationAsRead, history } = this.props;
    let regex = new RegExp('/', 'g');
    let urlType = [];
    let hash = [];
    if (notification) {
      markNotificationAsRead(notification._id);
      if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
        urlType = notification.url[lang].match(regex);
      }
      if (notification.type.name === 'system') {
        if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
          if (urlType.length > 1) {
            notification.urlOpenInNewWindow
              ? window.open(notification.url[lang])
              : (window.location.href = notification.url[lang]);
          } else {
            hash = notification.url[lang].split('#');
            if (hash.length > 1) {
              history.push({
                pathname: hash[0],
                hash: hash[1],
              });
            } else {
              history.push({
                pathname: notification.url[lang],
              });
            }
          }
        } else {
          // this.props.history.push({ pathname: '/new_help_center' });
        }
      } else if (notification.type.name === 'reminder') {
        if (notification.type.tag === 'BD') {
          this.props.history.push({
            pathname: '/client',
            select: 'TodayAndTomorrowBirthday',
            state: { BD: notification.blastingTime },
          });
        }
        if (notification.type.tag === 'RM') {
          this.props.history.push({
            pathname: '/client',
            select: 'allReminder',
            state: {
              remindMeId: notification.type.extraInfo,
            },
          });
        }
        if (notification.type.tag === 'PolicyReminder') {
          this.props.history.push({
            pathname: '/reminder',
          });
        } else if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
          if (urlType.length > 1) {
            notification.urlOpenInNewWindow
              ? window.open(notification.url[lang])
              : (window.location.href = notification.url[lang]);
          } else {
            hash = notification.url[lang].split('#');
            if (hash.length > 1) {
              history.push({
                pathname: hash[0],
                hash: hash[1],
              });
            } else {
              history.push({
                pathname: notification.url[lang],
              });
            }
          }
        } else {
          // this.props.history.push({ pathname: '/hot_topic' });
        }
      } else if (notification.type.name === 'promotion') {
        if (notification.url && notification.url[lang] !== null && notification.url[lang] !== undefined) {
          if (urlType.length > 1) {
            notification.urlOpenInNewWindow
              ? window.open(notification.url[lang])
              : (window.location.href = notification.url[lang]);
          } else {
            hash = notification.url[lang].split('#');
            if (hash.length > 1) {
              history.push({
                pathname: hash[0],
                hash: hash[1],
              });
            } else {
              history.push({
                pathname: notification.url[lang],
              });
            }
          }
        }
      } else if (notification.type.name === 'referral-coupon') {
        history.push({ pathname: '/subscription' });
      }
    }
  };

  handleShowToast = (showToast) => {
    let lang =
      this.props.userDetails.language === 'en' ? 'en' : this.props.userDetails.language === 'zh' ? 'zh' : 'zh_Hant_HK';
    let newToastIds = showToast.map((item, index) => {
      return item._id;
    });
    if (showToast && showToast.length > 5) {
      showToast.splice(5);
    }
    let notificationCenterPushed = JSON.parse(localStorage.getItem('notificationCenterPushed'));
    let toastToShow = _.difference(newToastIds, notificationCenterPushed);
    let toastToKeep = _.intersection(newToastIds, notificationCenterPushed);

    const Item = (notification, closeToast) => (
      <div>
        <ListItem
          style={{
            padding: 0,
            alignItems: 'flex-start',
          }}
          onClick={() => {
            this.handleClickToastLinkTo(notification, lang);
          }}
        >
          <ListItemAvatar
            style={{
              marginTop: '1.1rem',
              marginLeft: '1rem',
            }}
          >
            {getNotificationImg(notification.type.name, { style: { width: 40 } })}
          </ListItemAvatar>
          <ListItemText
            className="Toast-title"
            style={{
              wordBreak: 'break-word',
            }}
            primary={
              notification.type.tag === 'CONNECTION_WITH_AGENT'
                ? this.props.intl.formatMessage({ id: notification.title[lang] }, { data: notification.sender.name })
                : notification.type.tag === 'BD' ||
                    notification.type.tag === 'RM' ||
                    notification.type.tag === 'PolicyReminder'
                  ? this.props.intl.formatMessage({ id: notification.title[lang] })
                  : notification.title[lang]
            }
            secondary={
              <span
                style={{
                  color: 'gray',
                  fontSize: '1.4rem',
                }}
                dangerouslySetInnerHTML={
                  notification.type.tag === 'CONNECTION_WITH_AGENT'
                    ? { __html: '' }
                    : {
                        __html: sanitize(
                          notification.type.tag === 'BD' || notification.type.tag === 'RM'
                            ? this.props.intl.formatMessage(
                                { id: notification.message[lang] },
                                { data: notification.sender.name },
                              )
                            : notification.message[lang],
                        ),
                      }
                }
              />
            }
          />
        </ListItem>
        <button onClick={closeToast} style={{ display: 'none' }} />
      </div>
    );
    showToast.forEach((notification) => {
      if (toastToShow.indexOf(notification._id) > -1) {
        toast.info(Item(notification), {
          position: 'top-left',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: 'notification-toast',
        });
      }
    });
    localStorage.setItem('notificationCenterPushed', JSON.stringify(toastToShow.concat(toastToKeep)));
  };

  handleToggle = () => {
    if (this.props.headerButtonControl) {
      this.closeDrawer();
      this.props.closeHeaderMenu();
    } else {
      this.openDrawer();
      this.props.openHeaderMenu();
    }
  };

  handleRequestClose = () => {
    this.setState({
      openPopup: false,
    });
  };

  handleTouchTap = (event) => {
    event.preventDefault();
    this.setState({
      openPopup: true,
    });
  };

  closeFeedBackModal = () => {
    this.setState({ feedBackModalOpen: false });
  };

  openDrawer = () => {
    this.setState({ open: true });
    // document.body.style.overflow = 'hidden';
  };

  closeDrawer = () => {
    this.setState({ open: false });
    // document.body.style.overflow = 'unset';
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  openTodo = () => this.setState({ todoOpen: true, templateOpen: false });
  closeTodo = () => this.setState({ todoOpen: false });

  openTemplate = () => this.setState({ templateOpen: true, todoOpen: false });
  closeTemplate = () => this.setState({ templateOpen: false });
  closeTodoAndTemplate = () => this.setState({ todoOpen: false, templateOpen: false });

  openUserDrawer = () => {
    // Tracking
    tracking('User Drawer Opened');
    this.props.openHeaderMenu();
  };
  emergency = () => {
    const { history } = this.props;
    tracking('Click on SOS button on top left');
    history.push({ pathname: '/insurer_detail', state: { title: { en: 'What if' } } });
  };

  render() {
    const {
      intl,
      history,
      // newReferralCount,
      // reminderBadgeCount,
      location,
      adviserSubscriptionNickname,
      hasNotifications,
      newNotificationNumber,
      headerButtonControl,
      openHeaderMenu,
      closeHeaderMenu,
      teamCampaignCodes,
    } = this.props;
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const { showModal } = this.state;

    let user = this.props.userDetails;
    let userType = user && user.userType;
    // let productionEnv = process.env.NODE_ENV === 'production' ? true : false;
    let clickAdviser = false;
    if (userType === 'User' && user.advisorDetails && user.advisorDetails.length > 0) {
      user.advisorDetails.forEach((val) => {
        if (val.connection === true) {
          clickAdviser = true;
        }
      });
    }

    // let intials = user && user.name ? user.name.charAt(0) : '';
    const isProAdviser = adviserSubscriptionNickname === 'Pro' || adviserSubscriptionNickname === 'Ultra';

    const shouldShowMenuButton = MENU_BUTTON_PATHS.find((path) => new RegExp(path).test(location.pathname));
    const title =
      _.get(
        topNavTitleEntries.find((entry) => location.pathname === entry[0]),
        '[1]',
      ) ||
      _.get(
        topNavTitleEntries.find((entry) => location.pathname.indexOf(entry[0]) === 0),
        '[1]',
      );

    const back = () => history.goBack();

    return (
      <div id="wrapper">
        {/*Since the drawer has colored background, we can't rely on global var(--top-padding) to add padding for iOS devices*/}
        <Drawer open={this.props.headerButtonControl} onClose={closeHeaderMenu} PaperProps={{ style: { padding: 0 } }}>
          <NavDrawer {...this.props} width={this.state.width} />
        </Drawer>
        <FooterNav
          {...this.props}
          width={this.state.width}
          openTodo={this.openTodo}
          closeTodo={this.closeTodo}
          todoOpen={this.state.todoOpen}
          closeTemplate={this.closeTemplate}
          openTemplate={this.openTemplate}
          templateOpen={this.state.templateOpen}
        />
        <NotificationCenterModal
          open={showModal}
          onClose={() => this.closeModal()}
          history={this.props.history}
          location={this.props.location}
        />

        {(!user || !userType) && title && (
          <div>
            <div>
              <CustomTopNavigation className="top-navigation" title={title && tryTranslate(intl, title)}>
                <span className="back_arrow" onClick={back}>
                  <ArrowBackIcon />
                </span>
              </CustomTopNavigation>
            </div>
          </div>
        )}

        {user && userType === 'User' && (
          <div>
            <div>
              <CustomTopNavigation className="top-navigation" title={title && tryTranslate(intl, title)}>
                {shouldShowMenuButton ? (
                  <NavMenuButton
                    id="menu-toggle"
                    onClick={() => (headerButtonControl ? closeHeaderMenu() : this.openUserDrawer())}
                  />
                ) : (
                  <span className="back_arrow" onClick={back}>
                    <ArrowBackIcon />
                  </span>
                )}

                {history.location.pathname !== '/insurer_detail' && (
                  <IconButton
                    className="plus-btn-client"
                    size="small"
                    onClick={this.emergency}
                    style={{
                      width: 36,
                      left: 50,
                      right: 'unset',
                      bottom: 4,
                      position: 'absolute',
                      zIndex: 1100,
                    }}
                  >
                    <img src="img/sos.png" alt="emergency" width={30} />
                  </IconButton>
                )}

                <IconButton
                  className="notification-bell"
                  style={
                    location.pathname === '/c-dashboard'
                      ? {
                          position: 'absolute',
                          right: this.state.width > 600 ? 41 : 31,
                          zIndex: 1100,
                          bottom: 0,
                        }
                      : location.pathname === '/c-analysis' ||
                          location.pathname === '/reminder' ||
                          location.pathname === '/message' ||
                          location.pathname === '/insurer_info' ||
                          location.pathname === '/my-profile' ||
                          location.pathname === '/client-tools'
                        ? { position: 'absolute', right: 10, zIndex: 1100, bottom: 0 }
                        : {
                            display: 'none',
                          }
                  }
                  onClick={() => {
                    this.setState({ showModal: true });
                  }}
                >
                  <NotificationBellButton
                    hasNotifications={hasNotifications}
                    newNotificationNumber={newNotificationNumber}
                  />
                </IconButton>

                <Popover
                  TransitionComponent={Zoom}
                  style={{ right: '0', marginLeft: '80%', marginTop: '4%' }}
                  open={this.state.openPopup}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                  onRequestClose={this.handleRequestClose}
                >
                  <Menu>
                    <MenuItem primaryText="Premium" />
                    <MenuItem primaryText="Protection" />
                    <MenuItem primaryText="My Self" />
                    <MenuItem primaryText="Wife" />
                  </Menu>
                </Popover>
              </CustomTopNavigation>
            </div>

            <FeedBackModal isOpen={this.state.feedBackModalOpen} onRequestClose={() => this.closeFeedBackModal()} />
          </div>
        )}

        {user && userType === 'Adviser' && (
          <div>
            <div>
              <div>
                <UserMissions buttonProps={UserMissionsButtonProps} teamShortName={this.props.teamShortName} />

                <CustomTopNavigation
                  className="top-navigation"
                  title={
                    topNavTitlePro[location.pathname]
                      ? intl.formatMessage({ id: topNavTitlePro[location.pathname] })
                      : title && tryTranslate(intl, title)
                  }
                  shouldShowProBadge={!!topNavTitlePro[location.pathname]}
                >
                  {shouldShowMenuButton ? (
                    <NavMenuButton
                      id="menu-toggle"
                      onClick={() => (headerButtonControl ? closeHeaderMenu() : openHeaderMenu())}
                    />
                  ) : (
                    <span className="back_arrow" onClick={back}>
                      <ArrowBackIcon />
                    </span>
                  )}

                  <div
                    className={
                      'top-nav-icons' +
                      (location.pathname === '/advisorDashboard' ||
                      location.pathname === '/adviser_business' ||
                      location.pathname === '/home' ||
                      location.pathname === '/my-profile'
                        ? ' dashboard-icons'
                        : location.pathname === '/adviser_tools' ||
                            location.pathname === '/door-opener' ||
                            location.pathname === '/medical-card-referral' ||
                            location.pathname === '/client'
                          ? ''
                          : ' hide')
                    }
                  >
                    {location.pathname !== '/client' && (
                      <IconButton
                        className={'notification-bell'}
                        onClick={() => {
                          this.setState({ showModal: true });
                        }}
                      >
                        <NotificationBellButton
                          hasNotifications={hasNotifications}
                          newNotificationNumber={newNotificationNumber}
                        />
                      </IconButton>
                    )}
                    <div
                      className={
                        'todo-btn' +
                        (location.pathname === '/advisorDashboard' ||
                        location.pathname === '/adviser_business' ||
                        location.pathname === '/home' ||
                        location.pathname === '/my-profile'
                          ? ' extra-margin'
                          : ' normal-margin')
                      }
                    >
                      <TodoIconButton
                        activeTodo={this.props.activeTodo}
                        handleClick={() => {
                          // Tracking
                          tracking('Todo List Opened');
                          this.openTodo();
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      'top-nav-icons' +
                      (this.state.width <= 600 &&
                      (this.props.location.pathname === '/apendix' ||
                        this.props.location.pathname === '/detail' ||
                        this.props.location.pathname === '/plan' ||
                        this.props.location.pathname === '/payment')
                        ? ''
                        : ' hide')
                    }
                  >
                    <IconButton edge="start" aria-label="copy" onClick={this.openTemplate}>
                      <FileCopyOutlinedIcon className="top-copy-btn" />
                    </IconButton>
                  </div>

                  {location.pathname === '/referral_records' &&
                    shouldEnableNFCAutoGen({ user: user, teamCampaignCodes }) && (
                      <NFCAutoGenerateButton style={{ color: '#fff', position: 'absolute', right: 0 }} />
                    )}
                </CustomTopNavigation>
              </div>
            </div>

            <div className="">
              <SwipeableDrawer
                anchor="right"
                PaperProps={{ elevation: 10, style: { zIndex: 1300 } }}
                variant={this.state.width > 800 ? 'persistent' : 'temporary'}
                onClose={this.closeTodo}
                onOpen={this.openTodo}
                open={this.state.todoOpen}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                disableSwipeToOpen={true}
                className="client-page-filter-SwipeableDrawer"
              >
                <TodoList close={this.closeTodo} openTemplate={this.openTemplate} />
              </SwipeableDrawer>
            </div>
            <div className="">
              <SwipeableDrawer
                anchor="right"
                PaperProps={{ elevation: 10, style: { zIndex: 1300 } }}
                variant={this.state.width > 800 ? 'persistent' : 'temporary'}
                onClose={this.closeTemplate}
                onOpen={this.openTemplate}
                open={this.state.templateOpen}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                disableSwipeToOpen={true}
                className="client-page-filter-SwipeableDrawer"
              >
                <Template close={this.closeTemplate} />
              </SwipeableDrawer>
            </div>
          </div>
        )}

        <ConnectWithAdviser
          open={this.state.connectModalOpen}
          onClose={() => {
            this.setState({ connectModalOpen: false });
          }}
          intl={intl}
          clickAdviser={clickAdviser}
        />
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  (state) => ({
    userDetails: state.user.userDetails,
    teamShortName: state.team.teamShortName,
    teamLogoUrl: state.team.teamLogoUrl,
    adviserSubscriptionNickname: state.userSubscription.adviserSubscriptionNickname,
    hasNotifications: state.notificationCenter.hasNotifications,
    newNotificationNumber: state.notificationCenter.newNotificationNumber,
    notificationCenterData: state.notificationCenter.notificationCenterData,
    showToast: state.notificationCenter.showToast,
    headerButtonControl: state.headerButtonControl.headerButtonControl,
    showMenuButton: state.headerButtonControl.showMenuButton,
    getNotification: state.notificationCenter.getNotification,
    adviserAllModalDone: state.notificationCenter.adviserAllModalDone,
    clientAllModalDone: state.notificationCenter.clientAllModalDone,
    activeTodo: _.isEmpty(state.user.adviserTodo)
      ? []
      : state.user.adviserTodo.activeAdviserTodo.concat(state.user.adviserTodo.importantAdviserTodo),
    guidelineIsStarted: state.guideline.start,
    guidelineIsRunning: state.guideline.run,
    guidelineSteps: state.guideline.steps,
    guidelineStepIndex: state.guideline.stepIndex,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    teamCampaignCodes: state.functions.campaignCodes,
  }),
  // Map actions to props
  {
    getNotificationCenterList,
    markNotificationAsRead,
    openHeaderMenu,
    closeHeaderMenu,
    renderHeaderButton,
    toggleDialog,
    runGuideline,
    updateUserDetails,
    selectedLocale,
  },
  // mergeProps
  null,
  // options
  {},
)(TopNavigation);

export const TopNavigationPortal = (props) => (
  <Portal container={() => document.querySelector('.top-navigation > div')} {...props} />
);

export default withRouter(injectIntl(container));
