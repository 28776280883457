import React from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import {
  getPolicyBeneficialDisplay,
  getPolicyLifeInsuredDisplay,
  isPolicySavingFeatured,
  isPolicySubCategory,
  isRiderPolicy
} from '../../../utils/policy';
import moment from 'moment-timezone';
import './PolicyCategoryTableBasic.scss';
import { PolicyReviewShavedText } from '../PolicyReview';
import Typography from '@material-ui/core/Typography';

export const POLICY_REVIEW_BASIC_TABLE_DEFAULT_WIDTH = 550;
var TOTAL_COLUMNS = 7;

const PolicyCategoryTableBasic = props => {
  const { intl, category, width, lastRowNoBorder, selectSaving, selectLifeInsured, selectBeneficiary } = props;

  const subCategories = _.get(category, 'subCategories', []);

  return category ? (
    <TableContainer className="policy-category-table basic">
      <Table size="small" style={{ width: width || POLICY_REVIEW_BASIC_TABLE_DEFAULT_WIDTH }}>
        <TableHead>
          <TableRow className="sub-header">
            <TableCell align="center" colSpan={TOTAL_COLUMNS} style={{ borderBottom: '3px solid #ffbe92' }}>
              <div>{intl.formatMessage({ id: 'pr-table-basic-info' })}</div>
            </TableCell>
          </TableRow>
          <TableRow className="main-row">
            {selectSaving &&
              <TableCell align="center" rowSpan={2} style={{ width: 50 }}>
                <div>
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'Saving' })}</Typography>
                </div>
              </TableCell>
            }
            <TableCell align="center" rowSpan={2} style={{ width: 80 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'sub-eff-date-title' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" rowSpan={2} style={{ width: 90 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'pr-table-cov-terms' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" rowSpan={2} style={{ width: 60 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Payment Terms' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" rowSpan={2} style={{ width: 80 }}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'pf-policy-status' })}</Typography>
              </div>
            </TableCell>
            {selectLifeInsured &&
              <TableCell align="center" rowSpan={2} style={{ width: 80 }}>
                <div>
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'Life Insured' })}</Typography>
                </div>
              </TableCell>
            }
            {selectBeneficiary &&
              <TableCell align="center" rowSpan={2} style={{ width: 80 }}>
                <div>
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'Beneficiary' })}</Typography>
                </div>
              </TableCell>
            }
          </TableRow>
          <TableRow className="sub-row" />
        </TableHead>
        <TableBody>
          {subCategories.map(subCategory => {
            const policies = _.get(subCategory, 'policies', []);

            return policies.map((policy, policyIndex) => {
              const isDummy = !!policy.dummy;
              const isSavingFeatured =
                (!isDummy && isPolicySubCategory(policy, 'saving')) || isPolicySavingFeatured(policy);
              const startDate = _.get(policy, 'policyStartDate');
              const protectionTerms = _.get(policy, 'protectionTerms');
              const paymentTerms = _.get(policy, 'paymentTerms');
              const selectedPolicyStatus = _.get(policy, 'selectedPolicyStatus');
              const lifeInsuredDisplay = getPolicyLifeInsuredDisplay(policy, intl);
              const beneficialDisplay = getPolicyBeneficialDisplay(policy, intl);

              return (
                <TableRow
                  key={policy._id}
                  className={lastRowNoBorder && policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
                >
                  {selectSaving &&
                    <TableCell align="center">
                      <div>{isSavingFeatured && <Typography>{intl.formatMessage({ id: 'pr-yes' })}</Typography>}</div>
                    </TableCell>
                  }
                  <TableCell align="center">
                    <div>{startDate && moment(startDate).format('DD-MMM-YYYY')}</div>
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <PolicyReviewShavedText>
                        {protectionTerms &&
                          protectionTerms.replace(/Years|Till age|Whole Life|Other/gi, protectionTerms =>
                            intl.formatMessage({ id: protectionTerms })
                          )}
                      </PolicyReviewShavedText>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <PolicyReviewShavedText>
                        {paymentTerms &&
                          paymentTerms.replace(/Years|Till age|Whole Life|Other/gi, paymentTerms =>
                            intl.formatMessage({ id: paymentTerms })
                          )}
                      </PolicyReviewShavedText>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      {selectedPolicyStatus &&
                        (selectedPolicyStatus === 'pr-lacks' ? (
                          <div>
                            <div className="lacks">{intl.formatMessage({ id: selectedPolicyStatus })}</div>
                          </div>
                        ) : (
                          <PolicyReviewShavedText>
                            {intl.formatMessage({ id: (selectedPolicyStatus || '').toLowerCase() })}
                          </PolicyReviewShavedText>
                        ))}
                    </div>
                  </TableCell>
                  {selectLifeInsured &&
                    <TableCell align="center">
                      <div>
                        <PolicyReviewShavedText>{lifeInsuredDisplay}</PolicyReviewShavedText>
                      </div>
                    </TableCell>
                  }
                  {selectBeneficiary &&
                    <TableCell align="center">
                      <div>
                        <PolicyReviewShavedText>{beneficialDisplay}</PolicyReviewShavedText>
                      </div>
                    </TableCell>
                  }
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyCategoryTableBasic);
