import React from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import './MyEditorModal.css';
import MyEditorModalResponsive from './MyEditorModalResponsive';
import { LOADING } from '../../constants/phase';
import { formatEditorStateValue } from '../../utils/editor';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';

const getContentState = state => {
  if (state) {
    return formatEditorStateValue(state);
  } else {
    return EditorState.createEmpty();
  }
};

export default class MyEditorModal extends React.Component {
  constructor(props) {
    super(props);
    const editorState = getContentState(props.contentState);
    this.state = {
      originalState: props.originalState ? getContentState(props.originalState) : editorState,
      editorState: editorState,
      confirmModalOpen: false,
      changed: false,
      messageConfirmModalOpen: false
    };
    this.handleEditorStateChange = this.handleEditorStateChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.updateEditorState(this.props.contentState, this.props.updatePhase);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.updateEditorState(nextProps.contentState, nextProps.updatePhase);
  }

  updateEditorState(content, updatePhase) {
    let obj = {};

    if (updatePhase === true) {
      obj.confirmModalOpen = false;
    }

    if (content !== undefined && content !== null) {
      obj.editorState = getContentState(content);
    }

    this.setState(obj);
  }

  close() {
    const { originalState } = this.state;

    this.setState(
      {
        editorState: originalState,
        confirmModalOpen: false
      },
      () => {
        this.save();
      }
    );
  }

  save = () => {
    const { editorState } = this.state;
    if (this.props.imageUsedIn === 'adviser/promotion' || this.props.imageUsedIn === 'adviser/teamBlast') {
      this.setState({
        messageConfirmModalOpen: true
      });
      return;
    }
    this.update(editorState);
  };

  update(editorState) {
    let editorStateObj = convertToRaw(editorState.getCurrentContent());
    //console.log(editorStateObj);

    // filtering blocks with image
    let imageBlocks = editorStateObj.blocks.filter(block => {
      return block.type === 'atomic' && block.entityRanges !== undefined && block.entityRanges.length > 0;
    });

    const { imageUsedIn, intl } = this.props;
    //console.log(imageBlocks);

    if (imageUsedIn === 'adviser/remindMe' && imageBlocks.length > 1) {
      alert(intl.formatMessage({ id: 'upload_image_at_most' }));
      return;
    }

    if (
      (imageUsedIn === 'adviser/promotion' || this.props.imageUsedIn === 'adviser/teamBlast') &&
      imageBlocks.length > 1
    ) {
      alert(intl.formatMessage({ id: 'upload_image_at_most' }));
      return;
    }

    // Fix problem of not removing old image link
    if (editorStateObj.blocks !== undefined) {
      editorStateObj.blocks = editorStateObj.blocks.filter(block => {
        return !(block.type === 'unstyled' && block.entityRanges !== undefined && block.entityRanges.length > 0);
      });
    }

    const { onUpdate } = this.props;
    if (onUpdate !== undefined && typeof onUpdate === 'function') {
      onUpdate(JSON.stringify(editorStateObj));
    }
    this.handleClose();
    this.handleConfirmClose();
  }

  handleEditorStateChange(editorState) {
    this.setState({ editorState: editorState, changed: true });
  }

  handleClose() {
    this.setState({ confirmModalOpen: false }, () => {
      const { onClose } = this.props;
      if (onClose !== undefined && typeof onClose === 'function') {
        onClose();
      }
    });
  }

  handleConfirmClose() {
    this.setState({
      messageConfirmModalOpen: false
    });
  }

  render() {
    const { intl, open, loadingPhase, updatePhase, headerText, extraHTML, onClose, ...rest } = this.props;
    const { editorState, messageConfirmModalOpen } = this.state;

    return (
      <div>
        <MyEditorModalResponsive
          open={open}
          onClose={onClose}
          editorState={editorState}
          onChange={this.handleEditorStateChange}
          {...rest}
          updatePhase={updatePhase}
          loadingPhase={loadingPhase}
          headerText={headerText}
          intl={intl}
          LOADING={LOADING}
          extraHTML={extraHTML}
          save={this.save}
          className={`target-editor-modal${open ? ' open' : ''}`}
        />
        {(this.props.imageUsedIn === 'adviser/promotion' || this.props.imageUsedIn === 'adviser/teamBlast') && (
          <Dialog
            open={messageConfirmModalOpen}
            onClose={() => {
              this.handleConfirmClose();
            }}
            TransitionComponent={SlideTransition}
          >
            <DialogTitle>{intl.formatMessage({ id: 'Confirm' })}</DialogTitle>
            <DialogContent>{intl.formatMessage({ id: 'message-confirm-content' })}</DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleConfirmClose();
                }}
                color="primary"
              >
                {intl.formatMessage({ id: 'Cancel' })}
              </Button>
              <Button
                color="primary"
                autoFocus
                onClick={() => {
                  this.update(editorState);
                }}
              >
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}
