import React from 'react';

const RecruitmentGoogleForm = props => {
  const { showPadding } = props;
  return (
    <div>
      <iframe
        title="Recruitment"
        src="https://docs.google.com/forms/d/e/1FAIpQLScMvp0v-7mBYpIg6-NQw7bOqLtjsShc8Z5Uke7DF4mpBDlP4A/alreadyresponded?embedded=true"
        style={{
          width: showPadding ? 'calc(100% - 60px)' : '100%',
          height: '100%',
          position: 'fixed',
          border: 'none',
          paddingTop: 45,
          paddingBottom: 20
        }}
      />
    </div>
  );
};

export default RecruitmentGoogleForm;
