import React, { PureComponent } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { insurerRegionFilter } from '../../../../utils/region';
import '../styles.scss';
import GridButtonLayout from '../../../../components/GridButtonLayout/GridButtonLayout';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { isInsurerOther, isInsurerWhatIf } from '../../../../utils/insurer';

class InsurerView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleData = async data => {
    const { history, location } = this.props;
    localStorage.setItem('insurer', JSON.stringify(data));
    history.replace({
      pathname: '/plan',
      state: location.state
    });
  };

  UNSAFE_componentWillMount() {
    //Remove the keys from localstorage
    let addRider = JSON.parse(localStorage.getItem('addRider'));
    if (!addRider) {
      localStorage.removeItem('plan');
      localStorage.removeItem('payment');
      localStorage.removeItem('detail');
      localStorage.removeItem('apendix');
    }
  }

  render() {
    const { subCategoryData, intl, handlePreviousPage } = this.props;
    const insurer = subCategoryData.insurer;
    // if (insurer) {
    //   var index = insurer.findIndex(p => p.title == 'Other');
    //   let obj = insurer[index];
    //   insurer.splice(index, 1);
    //   insurer.push(obj);
    // }

    let filteredInsurers = (insurer || []).filter(insurerRegionFilter);

    //To arrange the order of insurer
    if (filteredInsurers && filteredInsurers.length > 0) {
      let indexOther = filteredInsurers.findIndex(isInsurerOther);
      if (indexOther !== -1) {
        let obj = filteredInsurers[indexOther];
        filteredInsurers.splice(indexOther, 1);
        filteredInsurers.push(obj);
      }

      let indexWhatIf = filteredInsurers.findIndex(isInsurerWhatIf);
      if (indexWhatIf !== -1) {
        let obj = filteredInsurers[indexWhatIf];
        filteredInsurers.splice(indexWhatIf, 1);
        filteredInsurers.push(obj);
      }
    }

    return (
      <div>
        <TopNavigationPortal>
          <span onClick={handlePreviousPage} className="back_arrow">
            <ArrowBackIcon />
          </span>
        </TopNavigationPortal>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <GridButtonLayout
                    items={(filteredInsurers || []).map(insurer => ({
                      image: <img src={insurer.image} alt={insurer.title[intl.locale]} />,
                      text: insurer.title[intl.locale],
                      onClick: this.handleData.bind(this, insurer)
                    }))}
                    style={{ padding: '15px 0' }}
                  />

                  {/*<ul className="card-list-insurance insure insurePadding">*/}
                  {/*  {filteredInsurers && filteredInsurers.length > 0*/}
                  {/*    ? filteredInsurers.map((val, index) => {*/}
                  {/*        return (*/}
                  {/*          <li key={index} onClick={this.handleData.bind(this, val)}>*/}
                  {/*            <div className="bg-white">*/}
                  {/*              <img className="image1" src={val.image} alt="" />*/}
                  {/*              <h4>{intl.formatMessage({ id: val.title })}</h4>*/}
                  {/*            </div>*/}
                  {/*          </li>*/}
                  {/*        );*/}
                  {/*      })*/}
                  {/*    : ''}*/}
                  {/*</ul>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InsurerView;
