import React, { PureComponent } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker } from '@material-ui/pickers';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Switch, Grid } from '@material-ui/core';
import './style.scss';
import moment from 'moment';
import MessageEditorModal from './partials/MessageEditorModal/MessageEditorModal';
import EventIcon from '@material-ui/icons/Event';
import AdviserBlastPopUp from '../../../components/AdviserBlastPopUp/AdviserBlastPopUp';
import { SlideTransition } from '../../../components/Transition/Transition';
import MoreInfoTrigger from '../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import FunctionCard from '../../../components/FunctionCard/FunctionCard';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

class AdviserBlast extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      states: 'disable',
      sendToAll: false,
      clientsToSendNum: 0,
      confirmSend: false,
      MessageEditorModalOpen: false,
      contentState: '',
      isEnable: false,
      startDateOpen: false,
      endDateOpen: false,
      previewModalOpen: false,
      adviserBlast: undefined,
      clearAllModalOpen: false,
      clientSendNumModalOpen: false,
      detailIsNull: false,
      validateOpen: false,
      validateValue: false,
      disableToDo: ''
    };

    this.closeConfirmSend = this.closeConfirmSend.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getAdviserBlastNotification();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { disableToDo } = this.state;
    if (nextProps.adviserBlast) {
      this.setState({
        startDate: nextProps.adviserBlast.blastingTime,
        endDate: nextProps.adviserBlast.expireTime,
        states: nextProps.adviserBlast.states,
        sendToAll: nextProps.adviserBlast.sendToAll,
        contentState: nextProps.adviserBlast.detail,
        clientsToSendNum: nextProps.clientsToSendNum,
        adviserBlast: nextProps.adviserBlast
      });
    }
    if (
      nextProps.getAdviserBlastPhase !== 'loading' &&
      nextProps.updateAdviserBlastPhase !== 'loading' &&
      nextProps.disableTodoPhase === true
    ) {
      if (disableToDo === 'PromoMessage') {
        this.setState({
          MessageEditorModalOpen: true
        });
      } else if (disableToDo === 'startDate') {
        this.setState({
          startDateOpen: true
        });
      } else if (disableToDo === 'endDate') {
        this.setState({
          endDateOpen: true
        });
      } else if (disableToDo === 'sendToAll') {
        this.props.updateAdviserBlastNotification({ key: 'sendToAll', value: !this.state.sendToAll });
      } else if (disableToDo === 'ClientToSend') {
        this.props.history.push({
          pathname: '/client',
          select: 'ClientToSend',
          state: {
            path: 'adviser_blast'
          }
        });
      } else if (disableToDo === 'enableBlast') {
        this.setState({
          confirmSend: true
        });
      }
      this.setState({
        disableToDo: ''
      });
    }
  }

  handleSendChange() {
    this.setState({
      sendToAll: !this.state.sendToAll
    });
  }
  closeConfirmSend() {
    this.setState({
      confirmSend: false
    });
  }

  handleNotificationEnable() {
    this.setState({ confirmSend: false });
    let formData = {
      key: 'states',
      value: 'enable'
    };
    this.props.updateAdviserBlastNotification(formData);
  }

  handleNotificationChange(key, value) {
    let formData = {};
    formData.key = key;
    formData.value = value;

    this.props.updateAdviserBlastNotification(formData);
  }

  messageEnable() {
    const { clientsToSendNum, adviserBlast, startDate, endDate } = this.state;
    if (clientsToSendNum === 0) {
      this.setState({ clientSendNumModalOpen: true });
    } else if (adviserBlast && adviserBlast.detail === '') {
      this.setState({ detailIsNull: true });
    } else if (moment(endDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {
      this.setState({
        validateOpen: true,
        validateValue: 'validate-endDate'
      });
    } else if (moment(startDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      this.setState({
        validateOpen: true,
        validateValue: 'validate-startDate'
      });
    } else {
      this.setState({ confirmSend: true });
    }
  }

  handleDisEnableInDialog() {
    this.props.updateAdviserBlastNotification({ key: 'states', value: 'disable' });
    this.setState({
      isEnable: false
    });
  }

  render() {
    const {
      intl,
      history,
      getAdviserBlastPhase,
      updateAdviserBlastPhase,
      showSendSuccessDialog,
      closeSendSuccessDialog
    } = this.props;
    const {
      states,
      sendToAll,
      clientsToSendNum,
      confirmSend,
      MessageEditorModalOpen,
      startDate,
      endDate,
      contentState,
      isEnable,
      startDateOpen,
      endDateOpen,
      previewModalOpen,
      adviserBlast,
      clientSendNumModalOpen,
      detailIsNull,
      validateValue,
      validateOpen
    } = this.state;

    const item = {
      color: '#5fc3d2',
      imgSrc: 'img/promotion.png',
      imgAlt: 'adviser-blast',
      text: intl.formatMessage({ id: 'adviser-blast-message-content' }),
      subtitle: intl.formatMessage({ id: 'adviser-blast-message-content-subtitle' }),
      onClick: () => {
        if (states === 'enable') {
          this.setState({
            isEnable: true,
            disableToDo: 'PromoMessage'
          });
          return;
        }
        this.setState({ MessageEditorModalOpen: true });
      }
    };
    return (
      <div>
        {(getAdviserBlastPhase === 'loading' || updateAdviserBlastPhase === 'loading') && (
          <LoadingBackdrop open={true} />
        )}
        <div id="wrapper" style={{ height: '100vh' }}>
          <div id="page-content-wrapper" className="promotion-content-wrapper ">
            <div className="container-fluid">
              <div className="row">
                <div className="plus-btn-message message-guideline-icon">
                  <MoreInfoTrigger
                    titleIntlId="adviser-blast-moreInfo-title"
                    contentIntlId="adviser-blast-moreInfo-content"
                  />
                </div>
                <div className="promotion-wrapper col-sm-6 col-sm-12 col-sm-offset-3">
                  <ul>
                    <li>
                      <Grid item xs={12}>
                        <FunctionCard {...item} />
                      </Grid>
                    </li>
                    <li className="flex-li">
                      {intl.formatMessage({ id: 'start-date' })}
                      <span className="date-span">
                        {moment(startDate).format('DD-MMM-YYYY')}
                        <EventIcon
                          className="icon"
                          onClick={() => {
                            if (states === 'enable') {
                              this.setState({
                                isEnable: true,
                                disableToDo: 'startDate'
                              });
                              return;
                            }
                            this.setState({ startDateOpen: true });
                          }}
                        />
                      </span>

                      <DatePicker
                        className="datePicker"
                        name="startDate"
                        minDate={moment()}
                        value={startDate}
                        open={startDateOpen}
                        onClose={() => this.setState({ startDateOpen: false })}
                        onChange={data => {
                          this.handleNotificationChange('blastingTime', data.format('YYYY-MM-DDT00:00:00.00Z'));
                        }}
                      />
                    </li>
                    <li className="flex-li">
                      {intl.formatMessage({ id: 'end-date' })}
                      <span className="date-span">
                        {moment(endDate).format('DD-MMM-YYYY')}
                        <EventIcon
                          className="icon"
                          onClick={() => {
                            if (states === 'enable') {
                              this.setState({
                                isEnable: true,
                                disableToDo: 'endDate'
                              });
                              return;
                            }
                            this.setState({ endDateOpen: true });
                          }}
                        />
                      </span>

                      <DatePicker
                        className="datePicker"
                        name="endDate"
                        minDate={moment(startDate).add(1, 'days')}
                        maxDate={moment(startDate).add(3, 'months')}
                        value={endDate}
                        open={endDateOpen}
                        onClose={() => this.setState({ endDateOpen: false })}
                        onChange={data => {
                          this.handleNotificationChange('expireTime', data.format('YYYY-MM-DDT00:00:00.00Z'));
                        }}
                      />
                    </li>
                    <li>
                      {intl.formatMessage({ id: 'clients-to-send' })}
                      <div className="send-swiper">
                        <div className="flex-li">
                          <span>{intl.formatMessage({ id: 'send-to-all' })}</span>
                          <Switch
                            value="sendToAll"
                            color="primary"
                            checked={sendToAll}
                            onChange={() => {
                              if (states === 'enable') {
                                this.setState({
                                  isEnable: true,
                                  disableToDo: 'sendToAll'
                                });
                                return;
                              }
                              this.handleNotificationChange('sendToAll', !sendToAll);
                            }}
                          />
                        </div>
                        <div
                          className="flex-li"
                          style={sendToAll ? { color: 'darkgray' } : undefined}
                          onClick={() => {
                            if (sendToAll) {
                              return;
                            }
                            if (states === 'enable') {
                              this.setState({
                                isEnable: true,
                                disableToDo: 'ClientToSend'
                              });
                              return;
                            }
                            history.push({
                              pathname: '/client',
                              select: 'ClientToSend',
                              state: {
                                path: 'adviser_blast'
                              }
                            });
                          }}
                        >
                          <span>{intl.formatMessage({ id: 'select-clients' })}</span>
                          <img alt="" src="img/forwardArrow.png" />
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div className="promotion-footer">
                    <ul>
                      <li className="flex-li">
                        <span>{intl.formatMessage({ id: 'num-of-clients-receiving' })}</span>
                        <span style={{ color: '#3966f8', width: 25, textAlign: 'center' }}>{clientsToSendNum}</span>
                      </li>
                      <li className="flex-li promotion-status">
                        <span>{intl.formatMessage({ id: 'status' })}</span>
                        <span className={states === 'disable' ? 'red' : 'green'}>
                          {states === 'disable'
                            ? intl.formatMessage({ id: 'disabled' })
                            : intl.formatMessage({ id: 'enabled' })}
                        </span>
                      </li>
                    </ul>
                    <div className="promotion-action" style={{ backgroundColor: 'white' }}>
                      <Button
                        className="send-Button"
                        color="primary"
                        variant="outlined"
                        onClick={event => {
                          event.stopPropagation();
                          this.setState({ previewModalOpen: true });
                        }}
                      >
                        {intl.formatMessage({ id: 'preview' })}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="send-Button"
                        onClick={this.messageEnable.bind(this)}
                      >
                        {intl.formatMessage({ id: states === 'enable' ? 'disable' : 'enable' })}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog open={confirmSend} onClose={this.closeConfirmSend} TransitionComponent={SlideTransition}>
          <DialogTitle>{intl.formatMessage({ id: 'confirm-send-title' })}</DialogTitle>
          <DialogContent>
            <div>{intl.formatMessage({ id: 'confirm-send-clientNum' }, { data: clientsToSendNum })}</div>
            <div>
              {intl.formatMessage({ id: 'confirm-send-startDate' }, { data: moment(startDate).format('DD-MMM-YYYY') })}
            </div>
            <div>
              {intl.formatMessage({ id: 'confirm-send-endDate' }, { data: moment(endDate).format('DD-MMM-YYYY') })}
            </div>
            <div style={{ marginBottom: 10 }}>
              {intl.formatMessage({ id: 'confirm-send-days' }, { data: moment(endDate).diff(startDate, 'days') })}
            </div>
            {intl.formatMessage({ id: 'confirm-send-content' })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeConfirmSend} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                this.handleNotificationEnable();
              }}
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={getAdviserBlastPhase === true && updateAdviserBlastPhase === true && showSendSuccessDialog === true}
          onClose={() => closeSendSuccessDialog()}
          TransitionComponent={SlideTransition}
        >
          <DialogTitle>{intl.formatMessage({ id: 'send-success-title' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'send-success-content' })}</DialogContent>
          <DialogActions>
            <Button color="primary" autoFocus onClick={() => closeSendSuccessDialog()}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isEnable}
          onClose={() => {
            this.setState({ isEnable: false });
          }}
          TransitionComponent={SlideTransition}
        >
          <DialogTitle>{intl.formatMessage({ id: 'warn' })}</DialogTitle>
          <DialogContent>
            {intl.formatMessage({ id: 'warn-content' })} <br />
            {intl.formatMessage({ id: 'warn-content-ask' })}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ isEnable: false });
              }}
              color="primary"
            >
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button color="primary" autoFocus onClick={this.handleDisEnableInDialog.bind(this)}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={clientSendNumModalOpen}
          onClose={() => {
            this.setState({ clientSendNumModalOpen: false });
          }}
          TransitionComponent={SlideTransition}
        >
          <DialogTitle>{intl.formatMessage({ id: 'warn' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'cannot-be-0' })}</DialogContent>
          <DialogActions>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                this.setState({ clientSendNumModalOpen: false });
              }}
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={detailIsNull}
          onClose={() => {
            this.setState({ detailIsNull: false });
          }}
          TransitionComponent={SlideTransition}
        >
          <DialogTitle>{intl.formatMessage({ id: 'warn' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'detail-cannot-be-null' })}</DialogContent>
          <DialogActions>
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                this.setState({ detailIsNull: false });
              }}
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={validateOpen}
          onClose={() => {
            this.setState({ validateOpen: false });
          }}
          TransitionComponent={SlideTransition}
        >
          <DialogTitle>{intl.formatMessage({ id: 'validate-title' })}</DialogTitle>
          <DialogContent>
            {validateValue && (
              <div>
                {intl.formatMessage({ id: validateValue })}
                {validateValue === 'validate-startDate' && <div>{intl.formatMessage({ id: 'validate-ask' })}</div>}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {validateValue === 'validate-startDate' && (
              <Button
                onClick={() => {
                  this.setState({ validateOpen: false });
                }}
                color="primary"
              >
                {intl.formatMessage({ id: 'Cancel' })}
              </Button>
            )}
            <Button
              color="primary"
              autoFocus
              onClick={() => {
                if (validateValue === 'validate-startDate') {
                  this.setState({ validateOpen: false, disableToDo: 'enableBlast' }, () => {
                    this.handleNotificationChange('blastingTime', moment().format('YYYY-MM-DDT00:00:00.00Z'));
                  });
                } else {
                  this.setState({ validateOpen: false });
                }
              }}
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <AdviserBlastPopUp
          intl={intl}
          open={previewModalOpen}
          onClose={() => {
            this.setState({ previewModalOpen: false });
          }}
          adviserBlastPop={adviserBlast}
          clientMessages={() => {
            return;
          }}
          markAndClose={() => {
            this.setState({ previewModalOpen: false });
          }}
        />
        <MessageEditorModal
          intl={intl}
          open={MessageEditorModalOpen}
          contentState={contentState}
          onClose={() => {
            this.setState({ MessageEditorModalOpen: false });
          }}
        />
      </div>
    );
  }
}

export default AdviserBlast;
