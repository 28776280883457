import React from 'react';
// import {COUNTRIES} from './countries';
import { WithContext as ReactTags } from 'react-tag-input';
import Button from '@material-ui/core/Button';

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Tag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: this.props.tags,
      displayInput: this.props.displayInput,
      handleDelete: this.props.handleDelete,
      handleAddition: this.props.handleAddition,
      handleInputBlur: this.props.handleInputBlur,
      suggestions: [{ id: 'id', text: 'text' }]
    };
  }

  render() {
    const { tags, suggestions, handleAddition, handleDelete, displayInput, handleInputBlur } = this.state;
    const { intl } = this.props;
    return (
      <div>
        <ReactTags
          classNames={{
            tags: 'tagsClass',
            tagInput: displayInput,
            tag: 'tagClass'
          }}
          tags={tags}
          suggestions={suggestions}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          placeholder={intl.formatMessage({ id: 'Add new Tag' })}
          delimiters={delimiters}
          handleInputBlur={handleInputBlur}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ float: 'right', marginTop: '-35px', fontSize: '1.2rem' }}
          onClick={() => {
            if (tags && tags !== undefined && tags !== null) {
              this.props.handleAddition(tags);
            }
          }}
        >
          {intl.formatMessage({ id: 'Add' })}
        </Button>
      </div>
    );
  }
}

export default Tag;
