import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';

export default class AspectRatioSkeleton extends React.PureComponent {
  render() {
    const { aspectPercentage, SkeletonProps } = this.props;

    let skeletonPropsStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0
      },
      skeletonPropsRest = {};

    if (SkeletonProps) {
      skeletonPropsStyle = _.merge(skeletonPropsStyle, SkeletonProps.style);
      skeletonPropsRest = _.omit(SkeletonProps, ['style']);
    }

    return (
      <div
        style={{
          height: 0,
          overflow: 'hidden',
          paddingTop: `${aspectPercentage}%`,
          position: 'relative'
        }}
      >
        <Skeleton variant="rect" style={skeletonPropsStyle} {...skeletonPropsRest} />
      </div>
    );
  }
}
