import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  InputLabel,
  Button,
  FormControlLabel,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Switch
} from '@material-ui/core';
import NumberFormatTextField from '../../../../../components/NumberFormatTextField/NumberFormatTextField';
import MySlider from '../../../../../components/MySlider/MySlider';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { addThousandSeparators } from '../../../../../utils/formatNumber';
import _ from 'lodash';

const CustomizeInterestRow = ({
  termLoanPercentage,
  termAddOn,
  loan,
  i,
  intl,
  handleInputBankTermInterest,
  handleInputBankTermInterestOnBlur,
  expanded,
  onToggleExpanded,
  overrideLoanInterestSliderValue,
  isCurrencyEnabled
}) => {
  return (
    <>
      <TableRow>
        <TableCell>{i + 1}</TableCell>
        <TableCell className="loan-percentage">
          {
            <NumberFormatTextField
              disabled={isCurrencyEnabled}
              name="termLoanPercentage"
              value={termLoanPercentage[i] === 0 ? '0.' : termLoanPercentage[i]}
              onChange={isCurrencyEnabled ? undefined : event => handleInputBankTermInterest(event, i)}
              onBlur={isCurrencyEnabled ? undefined : () => handleInputBankTermInterestOnBlur(i)}
              inputProps={{
                allowNegative: false,
                suffix: '%',
                decimalScale: 3,
                isAllowed: values => {
                  const { formattedValue, value } = values;
                  return formattedValue === '' || value === '0' || value === '0.' || (value >= 0.001 && value <= 10);
                }
              }}
              endAdornment={
                <IconButton aria-label="expand row" size="small" onClick={onToggleExpanded}>
                  {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              }
            />
          }
        </TableCell>
        <TableCell className="term-add-on">
          {
            <NumberFormatTextField
              disabled={isCurrencyEnabled}
              name="termAddOn"
              value={termAddOn[i]}
              onChange={isCurrencyEnabled ? undefined : event => handleInputBankTermInterest(event, i)}
              inputProps={{
                allowNegative: true,
                prefix: '$',
                decimalScale: 0,
                isAllowed: values => {
                  const { formattedValue, value } = values;
                  return formattedValue === '' || value || value === 0;
                }
              }}
            />
          }
        </TableCell>
      </TableRow>
      <TableRow className="collapse-rows">
        <TableCell />
        <TableCell colSpan={2}>
          <Collapse in={expanded}>
            {intl.formatMessage(
              { id: 'pfa-table-mthly-interest' },
              { interest: addThousandSeparators(((loan * (termLoanPercentage[i] ?? 0)) / (12 * 100)).toFixed(0)) }
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default class SideLoanInterestInput extends React.PureComponent {
  handleSlideBankLoanInterest = (event, value) =>
    this.props.handleChange({
      target: {
        name: 'bankLoanInterest',
        value: value
      }
    });

  handleSlideBankTermLoanInterest = (event, value, index) => {
    const newPercentages = [...this.props.termLoanPercentage];
    newPercentages[index] = value;
    this.props.handleChange({
      target: {
        name: 'termLoanPercentage',
        value: newPercentages
      }
    });
  };

  handleSlideOverrideLoanInterestSliderValue = (event, value) =>
    this.props.handleChange({
      target: {
        name: 'overrideLoanInterestSliderValue',
        value: value
      }
    });

  handleInputBankTermInterest = (event, index) => {
    const { termLoanPercentage, termAddOn } = this.props;
    const newPercentages = [...(event.target.name === 'termLoanPercentage' ? termLoanPercentage : termAddOn)];
    newPercentages[index] = event.target.value;
    this.props.handleChange({
      target: {
        name: event.target.name,
        value: newPercentages
      }
    });
  };

  handleInputBankTermInterestOnBlur = index => {
    const e = { target: { value: this.props.termLoanPercentage[index], name: 'termLoanPercentage' } };
    if (e.target.value < 0.001 || !e.target.value) {
      e.target.value = 0.001;
    }
    this.handleInputBankTermInterest(e, index);
  };

  handleLoanInterestOnBlur = () => {
    const e = { target: { value: this.props.bankLoanInterest, name: 'bankLoanInterest' } };
    if (e.target.value < 0.001 || !e.target.value) {
      e.target.value = 0.001;
    }
    this.props.handleChange(e);
  };

  render() {
    const {
      intl,
      bankLoanInterest,
      handleChange,
      overrideLoanInterest,
      termLoanPercentage,
      termAddOn,
      handleCheckedChange,
      loan,
      fixedLoanInterestExpanded,
      onToggleFixedLoanInterestExpanded,
      dynamicLoanInterestExpanded,
      onToggleDynamicLoanInterestExpanded,
      overrideLoanInterestSliderValue,
      template,
      isCurrencyEnabled
    } = this.props;

    return (
      <Card className="pfa-card pf-card-container pf-loan-interest-input-panel">
        <CardContent>
          <Grid container spacing={1}>
            {!template && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isCurrencyEnabled}
                      checked={!overrideLoanInterest}
                      onChange={handleCheckedChange}
                      name="overrideLoanInterest"
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({ id: 'custom-loan-interest' })}
                />
              </Grid>
            )}
            <Grid item xs={12} className="bank-loan-interest slider-control">
              <Grid container style={{ marginBottom: '13px' }}>
                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                  <InputLabel>{intl.formatMessage({ id: 'pfa-bank-loan-interest' })}</InputLabel>
                </Grid>
                {!overrideLoanInterest && (
                  <Grid item xs={6}>
                    <NumberFormatTextField
                      disabled={isCurrencyEnabled}
                      name="bankLoanInterest"
                      value={bankLoanInterest === 0 ? '0.' : bankLoanInterest}
                      onChange={isCurrencyEnabled ? undefined : handleChange}
                      onBlur={this.handleLoanInterestOnBlur}
                      inputProps={{
                        allowNegative: false,
                        suffix: '%',
                        decimalScale: 3,
                        isAllowed: values => {
                          const { formattedValue, value } = values;
                          return (
                            formattedValue === '' || value === '0' || value === '0.' || (value >= 0.001 && value <= 10)
                          );
                        }
                      }}
                      endAdornment={
                        <IconButton aria-label="expand row" size="small" onClick={onToggleFixedLoanInterestExpanded}>
                          {fixedLoanInterestExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      }
                    />
                  </Grid>
                )}
              </Grid>

              {!overrideLoanInterest && (
                <Collapse in={fixedLoanInterestExpanded} className="mthly-interest">
                  {intl.formatMessage(
                    { id: 'pfa-table-mthly-interest' },
                    { interest: addThousandSeparators(((loan * (bankLoanInterest ?? 0)) / (12 * 100)).toFixed(0)) }
                  )}
                </Collapse>
              )}

              <div className="labelled-slider">
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <MySlider
                      disabled={isCurrencyEnabled}
                      min={0.125}
                      max={10}
                      step={0.125}
                      value={(overrideLoanInterest ? overrideLoanInterestSliderValue : bankLoanInterest) ?? 0}
                      onChangeCommitted={
                        overrideLoanInterest
                          ? this.handleSlideOverrideLoanInterestSliderValue
                          : this.handleSlideBankLoanInterest
                      }
                    />
                  </Grid>
                </Grid>
                {overrideLoanInterest && (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{intl.formatMessage({ id: 'Year' })}</TableCell>
                          <TableCell>{intl.formatMessage({ id: 'pfa-bank-loan-interest' })}</TableCell>
                          <TableCell>{intl.formatMessage({ id: 'pfa-fixed-cost' })}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {termLoanPercentage.map((e, i) => {
                          return (
                            <CustomizeInterestRow
                              key={`bank-loan-${i}`}
                              termLoanPercentage={termLoanPercentage}
                              termAddOn={termAddOn}
                              i={i}
                              loan={loan}
                              intl={intl}
                              handleInputBankTermInterest={this.handleInputBankTermInterest}
                              expanded={_.get(dynamicLoanInterestExpanded, `[${i}]`, false)}
                              onToggleExpanded={() => onToggleDynamicLoanInterestExpanded(i)}
                              handleInputBankTermInterestOnBlur={this.handleInputBankTermInterestOnBlur}
                              isCurrencyEnabled={isCurrencyEnabled}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
