import React from 'react';
import { injectIntl } from 'react-intl';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Button } from '@material-ui/core';

const ButtonStyle = {
  minWidth: 'unset',
  padding: '2px 6px'
};

const EditIconStyle = {
  fontSize: '1.3rem'
};

const SaveButtonStyle = {
  ...ButtonStyle,
  lineHeight: 1.2,
  fontSize: '11px'
};

const ToggleEditButton = props => {
  const { intl, isEdit, onChange, style, disabled, ...rest } = props;

  return !isEdit ? (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      style={{ ...ButtonStyle, background: !disabled ? 'var(--new-theme)' : undefined, ...style }}
      onClick={onChange}
      {...rest}
    >
      <EditRoundedIcon style={EditIconStyle} />
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      style={{ ...SaveButtonStyle, background: !disabled ? 'red' : undefined, ...style }}
      onClick={onChange}
      {...rest}
    >
      {intl.formatMessage({ id: 'apply' })}
    </Button>
  );
};

export default injectIntl(ToggleEditButton);
