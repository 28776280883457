import React from 'react';
import store from '../../store';
import { hasCampaignCode } from '../../utils/user';
import { Grid } from '@material-ui/core';

const RUNMATHDOGHK = props => {
    const shouldShowRMD = hasCampaignCode(
        store.getState().user.userDetails, store.getState().functions.campaignCodes, 'PARTNER_RUNDOG'
    );

    if (!shouldShowRMD) { window.location.href = '/'; }

    const { showPadding } = props;
    return (
        <Grid
            container
            spacing={0}
            // direction="column"
            alignItems="center"
            justify="center"
        >
            <Grid item
                style={{
                    height: "100vh",
                    width: showPadding ? 'calc(100% - 60px)' : '100%',
                    maxWidth: "600px",
                    paddingTop: 45,

                }}
            >
                <iframe
                    title="RUNMATHDOGHK"
                    src={
                        process.env.NODE_ENV !== 'production' ?
                            "https://w3.portfoplus.com/partner/runmathdoghk-dev/" : "https://w3.portfoplus.com/partner/runmathdoghk/"
                    }
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default RUNMATHDOGHK;
