import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';
import { MultipleChoicesSubTitleStyle } from '../MyDetails';
import {
  MAIN_LANGUAGES_CHOICES,
  PERSONALITY_LIFE_ATTITUDE_CHOICES,
  PERSONALITY_TREAT_PEOPLE_CHOICES,
  PERSONALITY_TYPES_CHOICES,
  PERSONALITY_WORK_ATTITUDE_CHOICES
} from '../../../../constants/my-details';
import { getMainLanguageIntl, getPersonalityTagIntl } from '../../../../utils/my-details';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import { updateUserDetails } from '../../../../store/user/duck';
import { useDispatch } from 'react-redux';
import TextEditorInput from '../TextEditorInput/TextEditorInput';
import { sanitize } from 'dompurify';

const MarginBottomStyle = {
  marginBottom: 16
};

const MyDetailsAboutMe = props => {
  const { intl, details, error, onChangeDetails, updateError, onResize } = props;
  const dispatch = useDispatch();

  const { mainLanguages, personalityTags, adviserMessage } = details || {};

  const createSimpleOnChangeHandler = (key, emptyValue) => event =>
    onChangeDetails({ [key]: event.target.value || emptyValue });
  const onChangeMainLanguages = createSimpleOnChangeHandler('mainLanguages', []);
  const onChangePersonalityTags = createSimpleOnChangeHandler('personalityTags', []);
  const onChangeAdviserMessage = editorState => {
    onChangeDetails({ adviserMessage: editorState });
  };

  const setDefaultAdviserMessage = () => {
    dispatch(updateUserDetails({ unsetKey: 'adviserMessage' }));
  };

  return (
    <Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <AladdinHint title={intl.formatMessage({ id: 'mda-about-me-title' })} />
        </Grid>
        <Grid item>
          <ExpansionPanel className="mc-expansion-panel" expanded={true}>
            <ExpansionPanelSummary>
              <Typography
                className="field-label"
                color={!!error.mainLanguages ? 'error' : undefined}
              >{`${intl.formatMessage({
                id: 'main-lang'
              })}*`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography color="textSecondary">{intl.formatMessage({ id: 'mda-mc-hint' })}</Typography>
                </Grid>
                <Grid item>
                  <SelectButtonGroup
                    multi={true}
                    choices={MAIN_LANGUAGES_CHOICES.map(language => ({
                      value: language,
                      label: intl.formatMessage({ id: getMainLanguageIntl(language) })
                    }))}
                    value={mainLanguages}
                    onChange={onChangeMainLanguages}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Grid item style={MarginBottomStyle}>
          <ExpansionPanel className="mc-expansion-panel" expanded={true}>
            <ExpansionPanelSummary>
              <Typography
                className="field-label"
                color={!!error.personalityTags ? 'error' : undefined}
              >{`${intl.formatMessage({
                id: 'personality-tags'
              })}*`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container direction="column" alignItems="flex-start" spacing={1}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'mda-mc-6-hint' })) }}
                  />
                </Grid>
                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-pt-type' })}</Typography>
                </Grid>
                <Grid item style={MarginBottomStyle}>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    choices={PERSONALITY_TYPES_CHOICES.map(tag => ({
                      value: tag,
                      label: intl.formatMessage({ id: getPersonalityTagIntl(tag) })
                    }))}
                    value={personalityTags}
                    onChange={onChangePersonalityTags}
                    // onMax={onPersonalityTagsMax}
                  />
                </Grid>
                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-pt-work-attitude' })}</Typography>
                </Grid>
                <Grid item style={MarginBottomStyle}>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    choices={PERSONALITY_WORK_ATTITUDE_CHOICES.map(tag => ({
                      value: tag,
                      label: intl.formatMessage({ id: getPersonalityTagIntl(tag) })
                    }))}
                    value={personalityTags}
                    onChange={onChangePersonalityTags}
                    // onMax={onPersonalityTagsMax}
                  />
                </Grid>
                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-pt-treat-people' })}</Typography>
                </Grid>
                <Grid item style={MarginBottomStyle}>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    choices={PERSONALITY_TREAT_PEOPLE_CHOICES.map(tag => ({
                      value: tag,
                      label: intl.formatMessage({ id: getPersonalityTagIntl(tag) })
                    }))}
                    value={personalityTags}
                    onChange={onChangePersonalityTags}
                    // onMax={onPersonalityTagsMax}
                  />
                </Grid>
                <Grid item style={MultipleChoicesSubTitleStyle}>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-pt-life-attitude' })}</Typography>
                </Grid>
                <Grid item>
                  <SelectButtonGroup
                    multi={true}
                    max={6}
                    choices={PERSONALITY_LIFE_ATTITUDE_CHOICES.map(tag => ({
                      value: tag,
                      label: intl.formatMessage({ id: getPersonalityTagIntl(tag) })
                    }))}
                    value={personalityTags}
                    onChange={onChangePersonalityTags}
                    // onMax={onPersonalityTagsMax}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        {adviserMessage && (
          <Grid item>
            <TextEditorInput
              title={intl.formatMessage({ id: 'my-intro' })}
              hint={intl.formatMessage({ id: 'mda-my-intro-hint' })}
              value={adviserMessage}
              onChange={onChangeAdviserMessage}
              onSetDefault={setDefaultAdviserMessage}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default injectIntl(MyDetailsAboutMe);
