import { VoiceRecorder } from 'capacitor-voice-recorder';

export const startRecordingVoice = async () => {
  const currentStatusResult = await VoiceRecorder.getCurrentStatus();
  if (currentStatusResult.status === 'RECORDING' || currentStatusResult.status === 'PAUSED') {
    await VoiceRecorder.stopRecording();
  }

  const result = await VoiceRecorder.canDeviceVoiceRecord();
  // console.log({ result });

  if (!result.value) {
    throw new Error('Device does not support voice recording');
  }

  const permissionResult = await VoiceRecorder.hasAudioRecordingPermission();

  if (!permissionResult.value) {
    const requestResult = await VoiceRecorder.requestAudioRecordingPermission();
    // console.log({ requestResult });
    if (!requestResult.value) {
      throw new Error('User did not allow voice recording');
    }
  }

  return VoiceRecorder.startRecording();
};

export const stopRecordingVoice = async () => {
  return VoiceRecorder.stopRecording();
};
