import React, { Fragment, useState } from 'react';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { IconButton } from '@material-ui/core';
import CreateCategoryDialog from '../CreateCategoryDialog/CreateCategoryDialog';

const CreateCategoryButton = props => {
  const { team, ...rest } = props;
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] = useState(false);

  const onClick = () => {
    setIsCreateCategoryDialogOpen(true);
  };

  const onCloseCreateCategoryDialog = () => {
    setIsCreateCategoryDialogOpen(false);
  };

  return (
    <Fragment>
      <CreateCategoryDialog team={team} open={isCreateCategoryDialogOpen} onClose={onCloseCreateCategoryDialog} />
      <IconButton onClick={onClick} {...rest}>
        <PlaylistAddIcon />
      </IconButton>
    </Fragment>
  );
};

export default CreateCategoryButton;
