const ADMIN_EMAIL = process.env.ADMIN_EMAIL;

export const isEmailValid = email => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(email));
export const formatEmail = email => {
  if (email) {
    email = email
      .toString()
      .trim()
      .toLowerCase();
  }
  return email;
};
export const getAdminEmail = () => ADMIN_EMAIL;
