import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Grid, IconButton, Menu, MenuItem, Typography, useMediaQuery, Zoom } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { goToAddClient } from '../../../components/ClientEntry/utils';
import { withRouter } from 'react-router';
import { tracking } from '../../../utils/tracking';
import './ShortcutsSection.scss';
import { toggleDialog } from '../../../store/control/duck';

const ShortCutButtonStyle = {
  background: '#fff',
  minWidth: 'unset',
  padding: 6
};

const ShortCutButton = props => {
  const { imgSrc, imgAlt, label, onClick } = props;

  return (
    <Grid container alignItems="center" justify="flex-start" spacing={1} wrap="nowrap">
      <Grid item>
        <Button variant="contained" color="primary" onClick={onClick} style={ShortCutButtonStyle}>
          <img src={imgSrc} alt={imgAlt} height={32} />
        </Button>
      </Grid>
      <Grid item>
        <Typography color="textSecondary">{label}</Typography>
      </Grid>
    </Grid>
  );
};

const ShortcutsSection = props => {
  const { intl, history, toggleDialog } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isWide = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const addClient = () => goToAddClient(history, { replaceRoute: '/client' });

  const getUsers = () => {
    tracking('Click on QR Code - Homepage');
    toggleDialog('myQR', {});
  };

  const policyReview = () => {
    tracking('Click on Policy Review - Homepage');
    history.push('/policy_review');
  };

  const adviserInfoPage = () => {
    tracking('Click on advisers page - Homepage');
    history.push('/profile-plus');
  };

  const usefulCharts = () => {
    tracking('Click on Financial Concept - Main page');
    toggleDialog('financialCharts', {});
  };

  const premiumFinancing = () => {
    tracking('Click on Premium Financing - Main page');
    history.push('/premium_financing');
  };

  const chequeWizard = () => {
    tracking('Click on Cheque Wizard - Main page');
    history.push({ pathname: '/cheque_out' });
  };

  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const shortcuts = [
    {
      imgSrc: 'img/adviser-home-page/shortcut_add.svg',
      imgAlt: 'add',
      label: intl.formatMessage({ id: 'add-client' }),
      onClick: addClient
    },
    {
      imgSrc: 'img/adviser-home-page/shortcut_qr.svg',
      imgAlt: 'qr-code',
      label: intl.formatMessage({ id: 'my-qr' }),
      onClick: getUsers
    },
    {
      imgSrc: 'img/adviser-home-page/shortcut_review.svg',
      imgAlt: 'review-pdf',
      label: intl.formatMessage({ id: 'export-sum-desc' }),
      onClick: policyReview
    },
    // {
    //   imgSrc: 'img/adviser-home-page/shortcut_biz.png',
    //   imgAlt: 'my-business-page',
    //   label: intl.formatMessage({ id: 'adviser-info-page' }),
    //   onClick: adviserInfoPage
    // }
    // {
    //   imgSrc: 'img/finConcept.png',
    //   imgAlt: 'useful-charts',
    //   label: intl.formatMessage({ id: 'fin-charts' }),
    //   onClick: usefulCharts
    // },
    // {
    //   imgSrc: 'img/pfinancing.png',
    //   imgAlt: 'premium-financing',
    //   label: intl.formatMessage({ id: 'pfa' }),
    //   onClick: premiumFinancing
    // },
    {
      imgSrc: 'img/adviser-home-page/shortcut_cheque.svg',
      imgAlt: 'cheque-wizard',
      label: intl.formatMessage({ id: 'Cheque Wizard' }),
      onClick: chequeWizard
    }
  ];

  return (
    <Fragment>
      {isWide ? (
        <Grid container justify="center" alignItems="center" spacing={1}>
          {shortcuts.map((shortcut, index) => (
            <Grid item xs={6} key={index}>
              <ShortCutButton {...shortcut} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Fragment>
          <IconButton onClick={openMenu} style={{ width: '100%' }}>
            <MoreVertIcon color="primary" className="client-page-expansion-dot-btn" />
          </IconButton>
          <Menu
            TransitionComponent={Zoom}
            MenuListProps={{
              disablePadding: true
            }}
            anchorEl={anchorEl}
            keepMounted={true}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            {shortcuts.map((shortcut, index) => (
              <MenuItem onClick={shortcut.onClick} key={index}>
                <Grid container alignItems="center" wrap="nowrap" spacing={1}>
                  <Grid item>
                    <img src={shortcut.imgSrc} alt={shortcut.imgAlt} height={32} />
                  </Grid>
                  <Grid item>
                    <Typography>{shortcut.label}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
          </Menu>
        </Fragment>
      )}
    </Fragment>
  );
};

const container = connect(state => ({}), {
  toggleDialog
})(ShortcutsSection);

export default injectIntl(withRouter(container));
