import React from 'react';
import './CornerTriangle.scss';

const CornerTriangle = props => {
  const { text, sizeFactor, orientation, backgroundColor, containerStyle, triangleStyle, textStyle } = props;
  const formattedSizeFactor = sizeFactor ? sizeFactor : 1;

  let _containerStyle, _triangleStyle, _textStyle;
  switch (orientation) {
    case 'bottomRight':
      _containerStyle = {
        bottom: 0,
        right: 0
      };
      _triangleStyle = {
        borderBottom: `${50 * formattedSizeFactor}px solid ${backgroundColor}`,
        borderLeft: `${50 * formattedSizeFactor}px solid transparent`
      };
      _textStyle = {
        width: `${Math.sqrt(Math.pow(50 * formattedSizeFactor, 2) + Math.pow(50 * formattedSizeFactor, 2))}px`,
        fontSize: `${14 * formattedSizeFactor}px`,
        left: `-${Math.sqrt(Math.pow(50 * formattedSizeFactor, 2) + Math.pow(50 * formattedSizeFactor, 2))}px`,
        transformOrigin: 'top right',
        transform: 'rotate(-50deg)'
      };
      break;
    default:
      _containerStyle = {
        top: 0,
        left: 0
      };
      _triangleStyle = {
        borderTop: `${50 * formattedSizeFactor}px solid ${backgroundColor}`,
        borderRight: `${50 * formattedSizeFactor}px solid transparent`
      };
      _textStyle = {
        width: `${Math.sqrt(Math.pow(50 * formattedSizeFactor, 2) + Math.pow(50 * formattedSizeFactor, 2))}px`,
        fontSize: `${14 * formattedSizeFactor}px`,
        top: `-${14 * formattedSizeFactor}px`,
        transformOrigin: 'bottom left',
        transform: 'rotate(-45deg)'
      };
  }

  return (
    <div className="corner-tri-wrapper" style={{ ..._containerStyle, ...containerStyle }}>
      <div className="corner-tri" style={{ ..._triangleStyle, triangleStyle }}>
        <div style={{ ..._textStyle, ...textStyle }}>{text}</div>
      </div>
    </div>
  );
};

export default CornerTriangle;
