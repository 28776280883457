import React from 'react';
import './ChooseUserType.scss';
import { injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import { LargeATypography, styles } from '../Login/component';
import LoginBannerContainer from '../../components/LoginBannerContainer/LoginBannerContainer';
import LoginBannerImage from '../../components/LoginBannerContainer/LoginBannerImage/LoginBannerImage';
import { withRouter } from 'react-router';

export const SignInHomePageGrid = injectIntl(
  withRouter(props => {
    const { intl, history, onLogin } = props;

    const login = onLogin || (() => history.push('/login'));

    return (
      <Grid container>
        <Grid item xs={6}>
          <LargeATypography onClick={login}>{intl.formatMessage({ id: 'sign-in-now' })}</LargeATypography>
        </Grid>

        <Grid item xs={6}>
          <LargeATypography
            onClick={() =>
              window.open('https://www.portfoplus.com/?utm_source=appregister&utm_medium=button', '_blank')
            }
          >
            {intl.formatMessage({ id: 'home-page' })}
          </LargeATypography>
        </Grid>
      </Grid>
    );
  })
);

class ChooseUserType extends React.PureComponent {
  render() {
    const { intl } = this.props;
    const contents = (
      <div className="max-width-wrapper choose-type-wrapper">
        <div className="type-title">
          <Typography style={{ marginTop: '5px', fontSize: '22px' }} align="center" className="page-title">
            {intl.formatMessage({ id: 'user-type-question' })}
          </Typography>
        </div>
        <div className="type-options button-options">
          <div>
            <Button
              variant="contained"
              style={{ boxShadow: '0 0 black' }}
              color="primary"
              onClick={() => this.props.history.push('/signup')}
            >
              <div className="user-type-btn-content">
                <img src="img/new_adviser.png" alt="adviser" />
                <div style={{ marginTop: '3px', fontSize: '20px' }}>{intl.formatMessage({ id: 'Adviser' })}</div>
              </div>
            </Button>

            <Typography color="textSecondary" align="center" style={{ fontSize: 'inherit', fontWeight: 500 }}>
              {intl.formatMessage({ id: 'user-type-adviser-hint' })}
            </Typography>
          </div>

          <div>
            <Button
              variant="contained"
              style={{ boxShadow: '0 0 black' }}
              color="primary"
              onClick={() => this.props.history.push('/clientSignUp')}
            >
              <div className="user-type-btn-content">
                <img src="img/new_user.png" alt="user" />
                <div style={{ marginTop: '3px', fontSize: '20px' }}>{intl.formatMessage({ id: 'user-role' })}</div>
              </div>
            </Button>
            <Typography align="center" color="textSecondary" style={{ fontSize: 'inherit', fontWeight: 500 }}>
              {intl.formatMessage({ id: 'registration-reminder-client' })}
            </Typography>
          </div>
        </div>
        <div className="choose-type-login-wrapper">
          <SignInHomePageGrid
            intl={intl}
            onLogin={() =>
              this.props.history.push({ pathname: '/login', search: '?utm_source=In-app&utm_medium=signup-page' })
            }
          />
        </div>
      </div>
    );
    return (
      <LoginBannerContainer className="new-sign-up gradient-bg nsu" loginBanner={true} ready={true}>
        <Grid item xs={12}>
          <div>{contents}</div>
        </Grid>
      </LoginBannerContainer>
    );
  }
}

export default injectIntl(ChooseUserType);
