import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getUsedTagColor } from '../../../../../utils/tag';
import Checkbox from '@material-ui/core/Checkbox';
import { addThousandSeparators, decimalToString } from '../../../../../utils/formatNumber';
import NumberFormat from 'react-number-format';
import { Icon, IconButton, TextField, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MenuItem from '@material-ui/core/MenuItem';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import _ from 'lodash';
import CakeOutlinedIcon from '@material-ui/icons/CakeOutlined';
import ProRequiredBlur from '../../../../../components/SubscriptionComponents/ProRequiredDialog/ProRequiredBlur';
import MySlider from '../../../../../components/MySlider/MySlider';
import withStyles from '@material-ui/core/styles/withStyles';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import IconToast from '../../../../../components/NewToast';
import MemoryOutlinedIcon from '@material-ui/icons/MemoryOutlined';
import './searchFilter.scss';
import TempSelect from '../../../../../components/TempSelect/TempSelect';
import Badge from '@material-ui/core/Badge';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import StyledTextField from '../../../../../components/StyledTextField/StyledTextField';
import { Autocomplete } from '@material-ui/lab';
import StyledAutoComplete from '../../../../../components/StyledAutoComplete/StyledAutoComplete';
import EditFamilyDialog from '../../../../../components/EditFamilyDialog/EditFamilyDialog.js';


const TransparentValueLabelMySlider = withStyles({ valueLabel: { '& > span': { background: 'transparent' } } })(
  MySlider
);

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: [],
      importance: [],
      personality: [],
      monthlyIncome: 0,
      monthlySaving: 0,
      lifeCoverage: 0,
      ciCoverage: 0,
      ageMin: 0,
      ageMax: 100,
      bdayMonth: 0,
      families: [],
      tags: [],
      source: [],
      existingClient: [],
      referrerName: '',
      localSubmit: false,
      editFamilyDialogOpen: false
    };
    this.editStateArray = this.editStateArray.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { setState } = this.props;
    if (setState !== undefined) {
      this.setState(setState);
    }
  }
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.setState, this.props.setState)) {
      this.setState({
        gender: [],
        importance: [],
        personality: [],
        monthlyIncome: 0,
        monthlySaving: 0,
        lifeCoverage: 0,
        ciCoverage: 0,
        ageMin: 0,
        ageMax: 100,
        bdayMonth: 0,
        tags: [],
        source: [],
        existingClient: [],
        referrerName: '',
        families: [],
        ...this.props.setState
      });
    }
    if (
      prevProps.advisorCustomFiltersState.submitting &&
      !this.props.advisorCustomFiltersState.submitting &&
      this.state.localSubmit
    ) {
      if (!this.props.advisorCustomFiltersState.error) {
        toast.info(IconToast('info', this.props.intl.formatMessage({ id: 'Updated successfully' })), {
          className: 'new-toast'
        });
      } else {
        toast.info(IconToast('error', this.props.intl.formatMessage({ id: 'todo-err-msg' })), {
          className: 'new-toast'
        });
      }
      this.setState({ localSubmit: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.isEmpty(nextProps.setState)) {
      this.setState({
        gender: [],
        importance: [],
        personality: [],
        monthlyIncome: 0,
        monthlySaving: 0,
        lifeCoverage: 0,
        ciCoverage: 0,
        ageMin: 0,
        ageMax: 100,
        bdayMonth: 0,
        tags: [],
        source: [],
        existingClient: [],
        referrerName: ''
      });
    }
  }

  resetState = () => () => {
    this.setState(
      {
        gender: [],
        importance: [],
        personality: [],
        monthlyIncome: 0,
        monthlySaving: 0,
        ageMin: 0,
        ageMax: 100,
        bdayMonth: 0,
        lifeCoverage: 0,
        ciCoverage: 0,
        families: [],
        tags: [],
        source: [],
        existingClient: [],
        referrerName: ''
      },
      () => {
        const { aiSuggestClient } = this.props;
        aiSuggestClient(true);
        this.sendFilterCriteria();
      }
    );
  };

  //passing filter object to client page
  sendFilterCriteria = () => {
    let filter = { ...this.state };
    delete filter.localSubmit;
    this.props.getFilterObject(filter);
  };

  openFilterGuideline = () => {
    this.props.powerSearchModal();
  };

  editStateArray = (stateName, value) => event => {
    let editState = this.state[stateName];

    if (editState.includes(value)) {
      editState = editState.filter(item => item !== value);
    } else {
      editState.push(value);
    }
    this.setState({ [stateName]: editState }, () => {
      this.sendFilterCriteria();
    });
  };

  handleSliderChange = stateName => (event, newValue) => {
    this.setState({ [stateName]: newValue }, () => {
      this.sendFilterCriteria();
    });
  };

  handleAgeChange = (event, newValue) => {
    this.setState(
      {
        ageMin: newValue[0],
        ageMax: newValue[1]
      },
      () => {
        this.sendFilterCriteria();
      }
    );
  };

  handleInputChange = (stateName, value) => {
    this.setState(
      {
        [stateName]: value
      },
      () => {
        this.sendFilterCriteria();
      }
    );
  };

  handleSelectChange = stateName => event => {
    this.setState(
      {
        [stateName]: event.target.value === '' ? '' : Number(event.target.value)
      },
      () => {
        this.sendFilterCriteria();
      }
    );
  };

  handleReferrerNameChange = (event, value) => {
    if (event) {
      this.setState({ referrerName: value || '' }, () => {
        this.sendFilterCriteria();
      });
    }
  };

  setToCurrentMonth = () => () => {
    const { bdayMonth } = this.state;

    let newDate = new Date();
    let currentMonth = newDate.getMonth() + 1;
    this.setState(
      {
        bdayMonth: currentMonth !== bdayMonth ? currentMonth : 0
      },
      () => {
        this.sendFilterCriteria();
      }
    );
  };

  aiSuggest = () => {
    const { aiSuggestClient } = this.props;
    aiSuggestClient();
  };

  referralRecords = () => {
    const { history } = this.props;
    history.push({ pathname: '/referral_records' });
  };

  render() {
    let {
      intl,
      usedTags,
      filters,
      applyCustomFilter,
      openFilterModal,
      openResetFilterModal,
      newReferralCount,
      referrerNames
    } = this.props;
    const {
      gender,
      importance,
      personality,
      monthlyIncome,
      monthlySaving,
      bdayMonth,
      families,
      tags,
      ageMin,
      ageMax,
      source,
      existingClient,
      lifeCoverage,
      ciCoverage,
      referrerName
    } = this.state;

    const ageSliderTheme = createMuiTheme({
      overrides: {
        padding: '20px 0px 12px 0px',
        MuiSlider: {
          thumb: {
            color: '#3966f8'
          },
          track: {
            color: '#3966f8'
          },
          rail: {
            color: '#3966f8'
          },
          valueLabel: {
            fontSize: '1.4rem',
            top: -17,
            '& *': {
              background: 'transparent',
              color: '#000'
            }
          }
        }
      }
    });

    let newDate = new Date();
    let currentMonth = newDate.getMonth() + 1;

    return (
      <div
        className={'client-page-filter' + (this.props.drawer ? ' filter-drawer' : '')}
        style={{ maxWidth: this.props.drawer ? '245px' : undefined, height: '100%' }}
      >
        <EditFamilyDialog
          intl={intl}
          open={this.state.editFamilyDialogOpen}
          closeDialog={() => this.setState({ editFamilyDialogOpen: false })}
          refreshData={this.props.refreshData}
          manageOnly={true}
        />

        <ProRequiredBlur>
          <form noValidate={true} style={{ paddingTop: 18 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <MemoryOutlinedIcon color="primary" style={{ verticalAlign: 'middle', marginLeft: -2 }} />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.aiSuggest}
                      style={{ borderRadius: 16, lineHeight: 1 }}
                    >
                      {intl.formatMessage({ id: 'ai-suggest' })}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item onClick={this.referralRecords}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <GroupAddIcon color="primary" style={{ verticalAlign: 'middle', marginLeft: -2 }} />
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Button variant="outlined" color="primary" style={{ borderRadius: 16, lineHeight: 1 }}>
                        {intl.formatMessage({ id: 'New-Clients' })}
                      </Button>
                      {!!newReferralCount && (
                        <Grid item>
                          <Badge className="referral-count" badgeContent={newReferralCount} color="error" />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <hr style={{ margin: '15px 0px' }} />

            <Grid container spacing={1} direction="row" alignItems="center" justify="flex-start">
              <Grid item xs style={{ fontSize: 16, fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'filter_option' })}
              </Grid>
              <Grid item>
                <div>
                  <Button
                    variant="outlined"
                    color="default"
                    className="basic-button"
                    style={{ border: '1px solid' }}
                    onClick={this.resetState()}
                  >
                    {intl.formatMessage({ id: 'preset-reset' })}
                  </Button>
                  <IconButton size="small" color={'default'} onClick={this.openFilterGuideline}>
                    <HelpOutlineIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3} direction="row" alignItems="center">
              <Grid item xs={12}>
                <IconButton style={{ padding: '12px 3px 12px 0px', marginLeft: '-2px' }}>
                  <CakeOutlinedIcon style={{ color: bdayMonth === 0 ? '' : '3966f8' }} />
                </IconButton>
                <FormControl style={{ maxWidth: '85px', margin: '16px 15px 0px 5px' }}>
                  <TempSelect value={bdayMonth} onChange={this.handleSelectChange('bdayMonth')}>
                    <MenuItem value={0}>{intl.formatMessage({ id: 'All' })}</MenuItem>
                    <MenuItem value={1}>{intl.formatMessage({ id: 'Jan-mth' })}</MenuItem>
                    <MenuItem value={2}>{intl.formatMessage({ id: 'Feb-mth' })}</MenuItem>
                    <MenuItem value={3}>{intl.formatMessage({ id: 'Mar-mth' })}</MenuItem>
                    <MenuItem value={4}>{intl.formatMessage({ id: 'Apr-mth' })}</MenuItem>
                    <MenuItem value={5}>{intl.formatMessage({ id: 'May-mth' })}</MenuItem>
                    <MenuItem value={6}>{intl.formatMessage({ id: 'Jun-mth' })}</MenuItem>
                    <MenuItem value={7}>{intl.formatMessage({ id: 'Jul-mth' })}</MenuItem>
                    <MenuItem value={8}>{intl.formatMessage({ id: 'Aug-mth' })}</MenuItem>
                    <MenuItem value={9}>{intl.formatMessage({ id: 'Sep-mth' })}</MenuItem>
                    <MenuItem value={10}>{intl.formatMessage({ id: 'Oct-mth' })}</MenuItem>
                    <MenuItem value={11}>{intl.formatMessage({ id: 'Nov-mth' })}</MenuItem>
                    <MenuItem value={12}>{intl.formatMessage({ id: 'Dec-mth' })}</MenuItem>
                  </TempSelect>
                </FormControl>
                <Button
                  className="basic-button"
                  // style={{border: '1px solid'}}
                  variant={currentMonth === this.state.bdayMonth ? 'contained' : 'outlined'}
                  color={currentMonth === this.state.bdayMonth ? 'primary' : 'default'}
                  onClick={this.setToCurrentMonth()}
                >
                  {intl.formatMessage({ id: 'This-mth' })}
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              justify={this.props.drawer ? 'flex-start' : 'space-between'}
            >
              <Grid item xs={12}>
                <Typography color="textSecondary" style={{ color: 'rgb(97, 97, 97)', fontSize: '14px' }}>
                  {intl.formatMessage({ id: 'existing-client' })}
                </Typography>
                <img src="img/existingClient.svg" alt="existing-client" width="20" style={{ margin: '0 6px' }} />
                <Button
                  style={this.props.drawer ? { minWidth: '60px' } : { minWidth: '60px', flex: 1 }}
                  variant={existingClient.includes('Yes') ? 'contained' : 'outlined'}
                  className={`plan-type-select-button source-button-responsive${existingClient.includes('Yes') ? ` selected` : ''
                    }`}
                  onClick={this.editStateArray('existingClient', 'Yes')}
                >
                  {intl.formatMessage({ id: 'Yes' })}
                </Button>
                <img src="img/nonExistingClient.svg" alt="non-existing-client" width="20" style={{ margin: '0 6px' }} />
                <Button
                  style={this.props.drawer ? { minWidth: '60px' } : { minWidth: '60px', flex: 1 }}
                  variant={existingClient.includes('No') ? 'contained' : 'outlined'}
                  className={`plan-type-select-button source-button-responsive${existingClient.includes('No') ? ` selected` : ''
                    }`}
                  onClick={this.editStateArray('existingClient', 'No')}
                >
                  {intl.formatMessage({ id: 'No' })}
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={3} direction="row" alignItems="center" justify="flex-start">
              <Grid item xs={12}>
                <img alt="" className="imageClientState" src={'img/vvip.png'} />
                <Checkbox
                  checked={importance.includes('V.VIP') ? true : false}
                  onClick={this.editStateArray('importance', 'V.VIP')}
                  value="checkedB"
                  color={importance.includes('V.VIP') ? 'primary' : 'default'}
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                  style={{ padding: '3px' }}
                />
                <img alt="" className="imageClientState" src={'img/vip.png'} />
                <Checkbox
                  checked={importance.includes('VIP') ? true : false}
                  onClick={this.editStateArray('importance', 'VIP')}
                  color={importance.includes('VIP') ? 'primary' : 'default'}
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                  style={{ padding: '3px' }}
                />
                {intl.formatMessage({ id: 'Normal' })}
                <Checkbox
                  checked={importance.includes('Normal') ? true : false}
                  onClick={this.editStateArray('importance', 'Normal')}
                  color={importance.includes('Normal') ? 'primary' : 'default'}
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                  style={{ padding: '3px' }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              justify={this.props.drawer ? 'flex-start' : 'space-between'}
            >
              <Grid item xs={12}>
                <Button
                  style={this.props.drawer ? { minWidth: '60px' } : { minWidth: '60px', flex: 1 }}
                  variant={source.includes('Warm') ? 'contained' : 'outlined'}
                  className={
                    source.includes('Warm')
                      ? 'plan-type-select-button selected source-button-responsive'
                      : 'plan-type-select-button source-button-responsive'
                  }
                  onClick={this.editStateArray('source', 'Warm')}
                >
                  {intl.formatMessage({ id: 'Warm' })}
                </Button>

                <Button
                  style={this.props.drawer ? { minWidth: '60px' } : { minWidth: '60px', flex: 1 }}
                  variant={source.includes('Refer') ? 'contained' : 'outlined'}
                  className={
                    source.includes('Refer')
                      ? 'plan-type-select-button selected source-button-responsive'
                      : 'plan-type-select-button source-button-responsive'
                  }
                  onClick={this.editStateArray('source', 'Refer')}
                >
                  {intl.formatMessage({ id: 'Refer' })}
                </Button>

                <Button
                  style={this.props.drawer ? { minWidth: '60px' } : { minWidth: '60px', flex: 1 }}
                  variant={source.includes('Cold') ? 'contained' : 'outlined'}
                  className={
                    source.includes('Cold')
                      ? 'plan-type-select-button selected source-button-responsive'
                      : 'plan-type-select-button source-button-responsive'
                  }
                  onClick={this.editStateArray('source', 'Cold')}
                >
                  {intl.formatMessage({ id: 'Cold' })}
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item style={{ width: '100%' }}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Typography>{intl.formatMessage({ id: 'Referrer' })}</Typography>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <StyledAutoComplete
                      freeSolo={true}
                      value={referrerName || ''}
                      inputValue={referrerName || ''}
                      onInputChange={this.handleReferrerNameChange}
                      onChange={this.handleReferrerNameChange}
                      options={referrerNames}
                      getOptionLabel={option => option}
                      renderInput={params => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                {intl.formatMessage({ id: 'family' })}&nbsp;
                {this.props.enableEditFamily &&
                  <EditIcon
                    onClick={() => this.setState({ editFamilyDialogOpen: true })}
                    style={{ fontSize: 14, verticalAlign: "middle" }}
                  />
                }
              </Grid>
              <div
                style={{ marginBottom: '18px' }}
              >
                <div>
                  {this.props.families?.map(family => {
                    return (
                      <span
                        className="familyTag"
                        key={family}
                        onClick={this.editStateArray('families', family)}
                        style={{ opacity: families.includes(family) ? '1' : '0.3' }}
                      >
                        {family}
                      </span>
                    );
                  })}
                </div>
              </div>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                {intl.formatMessage({ id: 'Tag' })}
              </Grid>
              <div
                className="tagsClass react-tags-wrapper"
                // style={{ width: this.props.drawer ? '200px' : '250px', marginBottom: '18px' }}
                style={{ marginBottom: '18px' }}
              >
                <div className="ReactTags__selected">
                  {usedTags.map(value => {
                    return (
                      <span key={value.text} onClick={this.editStateArray('tags', value.text)}>
                        <span
                          key={value.text}
                          className="tag-wrapper ReactTags__tag-no-remove"
                          draggable="true"
                          style={{
                            color: getUsedTagColor(usedTags, value),
                            opacity: tags.includes(value.text) ? '1' : '0.3'
                          }}
                        >
                          {value.text}
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>
            </Grid>

            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
              <Grid item xs={4}>
                <div
                  onClick={this.editStateArray('gender', 'Male')}
                  style={{ display: 'inline-block', opacity: gender.includes('Male') ? '1' : '0.35' }}
                >
                  <img alt="" className="search-filter-gender-icon" src="img/male.svg" />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div
                  onClick={this.editStateArray('gender', 'Female')}
                  style={{ display: 'inline-block', opacity: gender.includes('Female') ? '1' : '0.35' }}
                >
                  <img alt="" className="search-filter-gender-icon" src="img/female.svg" />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div
                  onClick={this.editStateArray('gender', undefined)}
                  style={{ display: 'inline-block', opacity: gender.includes(undefined) ? '1' : '0.35' }}
                >
                  <img alt="" className="search-filter-gender-icon" src="img/unisex.svg" />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} style={{ marginBottom: '-13px' }}>
                {intl.formatMessage({ id: 'Personality' })}
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Button
                    variant="contained"
                    className="portfolio-default-button button-line-height"
                    style={{
                      background: personality.includes('D') ? '#EA8462' : '#e0e0e0',
                      color: personality.includes('D') ? '#ffffff' : '#000'
                    }}
                    onClick={this.editStateArray('personality', 'D')}
                  >
                    {intl.formatMessage({ id: 'personality_D' })}
                  </Button>
                  <Button
                    variant="contained"
                    className="portfolio-default-button button-line-height"
                    style={{
                      background: personality.includes('I') ? '#EAC862' : '#e0e0e0',
                      color: personality.includes('I') ? '#ffffff' : '#000'
                    }}
                    onClick={this.editStateArray('personality', 'I')}
                  >
                    {intl.formatMessage({ id: 'personality_I' })}
                  </Button>
                  <Button
                    variant="contained"
                    className="portfolio-default-button button-line-height"
                    style={{
                      background: personality.includes('S') ? '#62EA84' : '#e0e0e0',
                      color: personality.includes('S') ? '#ffffff' : '#000'
                    }}
                    onClick={this.editStateArray('personality', 'S')}
                  >
                    {intl.formatMessage({ id: 'personality_S' })}
                  </Button>
                  <Button
                    variant="contained"
                    className="portfolio-default-button button-line-height"
                    style={{
                      background: personality.includes('C') ? '#62A6EA' : '#e0e0e0',
                      color: personality.includes('C') ? '#ffffff' : '#000E'
                    }}
                    onClick={this.editStateArray('personality', 'C')}
                  >
                    {intl.formatMessage({ id: 'personality_C' })}
                  </Button>

                  <Button
                    className="portfolio-default-button button-line-height"
                    style={{
                      opacity: personality.includes('') ? '1' : '0.35'
                    }}
                    onClick={this.editStateArray('personality', '')}
                  >
                    <img src="img/discnil.png" alt="" style={{ height: '27px' }} />
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '30px' }}
              alignItems="center"
              className={`age-slider-container ${ageMin > 0 ? 'age-min-active ' : ''}${ageMax < 100 ? 'age-max-active' : ''
                }`}
            >
              <Grid item xs={3}>
                {intl.formatMessage({ id: 'Age' })}
              </Grid>
              <Grid item xs={9}>
                <ThemeProvider theme={ageSliderTheme}>
                  <TransparentValueLabelMySlider
                    value={[ageMin, ageMax]}
                    onChangeCommitted={this.handleAgeChange}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    timedUpdateDelay={500}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
            <hr />
            <Grid
              container
              spacing={3}
              alignItems="center"
              className={`slider-container${monthlySaving ? ' active' : ''}`}
            >
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'MonthlySaving_short' })}
              </Grid>
              <Grid item xs={6}>
                <NumberFormat
                  style={{
                    width: '100%',
                    color: '#3966f8',
                    textAlign: 'center',
                    height: '20px',
                    maxWidth: '115px',
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: 'black'
                  }}
                  prefix="< "
                  thousandSeparator={true}
                  onValueChange={values => {
                    this.handleInputChange('monthlySaving', values.floatValue);
                  }}
                  value={decimalToString(monthlySaving, intl)}
                  inputMode="decimal"
                  pattern="[0-9]*"
                />
              </Grid>

              <Grid item xs={12}>
                <MySlider
                  style={{
                    padding: '0px 0px 11px 2px'
                  }}
                  min={0}
                  step={500}
                  max={30000}
                  value={monthlySaving || 0}
                  onChangeCommitted={this.handleSliderChange('monthlySaving')}
                  aria-labelledby="input-slider"
                  valueLabelFormat={value => addThousandSeparators(value)}
                  timedUpdateDelay={500}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              alignItems="center"
              className={`slider-container${lifeCoverage ? ' active' : ''}`}
            >
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'Life_insured' })}
              </Grid>

              <Grid item xs={6}>
                <NumberFormat
                  style={{
                    width: '100%',
                    color: '#3966f8',
                    textAlign: 'center',
                    height: '20px',
                    maxWidth: '115px',
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: 'black'
                  }}
                  prefix="< "
                  thousandSeparator={true}
                  onValueChange={values => {
                    this.handleInputChange('lifeCoverage', values.floatValue);
                  }}
                  value={decimalToString(lifeCoverage, intl)}
                  inputMode="decimal"
                  pattern="[0-9]*"
                />
              </Grid>

              <Grid item xs={12}>
                <MySlider
                  style={{
                    padding: '0px 0px 11px 2px'
                  }}
                  min={0}
                  step={100000}
                  max={3000000}
                  value={lifeCoverage || 0}
                  onChangeCommitted={this.handleSliderChange('lifeCoverage')}
                  aria-labelledby="input-slider"
                  valueLabelFormat={value => addThousandSeparators(value)}
                  timedUpdateDelay={500}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              alignItems="center"
              className={`slider-container${ciCoverage ? ' active' : ''}`}
            >
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'CI_insured' })}
              </Grid>

              <Grid item xs={6}>
                <NumberFormat
                  style={{
                    width: '100%',
                    color: '#3966f8',
                    textAlign: 'center',
                    height: '20px',
                    maxWidth: '115px',
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: 'black'
                  }}
                  prefix="< "
                  thousandSeparator={true}
                  onValueChange={values => {
                    this.handleInputChange('ciCoverage', values.floatValue);
                  }}
                  value={decimalToString(ciCoverage, intl)}
                  inputMode="decimal"
                  pattern="[0-9]*"
                />
              </Grid>

              <Grid item xs={12}>
                <MySlider
                  style={{
                    padding: '0px 0px 11px 2px'
                  }}
                  min={0}
                  step={100000}
                  max={2000000}
                  value={ciCoverage || 0}
                  onChangeCommitted={this.handleSliderChange('ciCoverage')}
                  aria-labelledby="input-slider"
                  valueLabelFormat={value => addThousandSeparators(value)}
                  timedUpdateDelay={500}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              alignItems="center"
              className={`slider-container${monthlyIncome ? ' active' : ''}`}
            >
              <Grid item xs={6}>
                {intl.formatMessage({ id: 'MonthlyIncome_short' })}
              </Grid>

              <Grid item xs={6}>
                <NumberFormat
                  style={{
                    width: '100%',
                    color: '#3966f8',
                    textAlign: 'center',
                    height: '20px',
                    maxWidth: '115px',
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: 'black'
                  }}
                  prefix="> "
                  thousandSeparator={true}
                  onValueChange={values => {
                    this.handleInputChange('monthlyIncome', values.floatValue);
                  }}
                  value={decimalToString(monthlyIncome, intl)}
                  inputMode="decimal"
                  pattern="[0-9]*"
                  noValidate="novalidate"
                />
              </Grid>

              <Grid item xs={12}>
                <MySlider
                  style={{
                    padding: '0px 0px 11px 2px'
                  }}
                  min={0}
                  step={1000}
                  max={60000}
                  value={monthlyIncome || 0}
                  onChangeCommitted={this.handleSliderChange('monthlyIncome')}
                  aria-labelledby="input-slider"
                  valueLabelFormat={value => addThousandSeparators(value)}
                  timedUpdateDelay={500}
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container justify="flex-start">
              <Grid item xs={12} container justify="space-between" style={{ marginBottom: '20px' }}>
                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                  {intl.formatMessage({ id: 'custom-filter-title' })}
                </Typography>
                <Button
                  variant="outlined"
                  color="default"
                  className="basic-button"
                  style={{ marginRight: 0, border: '1px solid' }}
                  onClick={openResetFilterModal}
                >
                  {intl.formatMessage({ id: 'preset-reset' })}
                </Button>
              </Grid>
              {filters.map((e, i) => (
                <Grid item xs={12} key={i}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Button
                        className="basic-button"
                        color="primary"
                        variant="outlined"
                        key={i}
                        onClick={() => applyCustomFilter(i)}
                        style={{ minWidth: 50, padding: '0 10px', border: '1px solid' }}
                      >
                        <div
                          style={{
                            textOverflow: 'ellipsis',
                            minWidth: 50,
                            maxWidth: 70,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                        >
                          {!e.name ? intl.formatMessage({ id: `custom-filter-${i + 1}` }) : e.name}
                        </div>
                      </Button>
                    </Grid>
                    <Grid item>
                      <IconButton
                        style={{ padding: 9 }}
                        onClick={() => {
                          openFilterModal(i);
                        }}
                      >
                        <EditIcon style={{ width: '0.8em', height: '0.8em' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </form>
        </ProRequiredBlur>
      </div>
    );
  }
}

export default SearchFilter;
