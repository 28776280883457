import React from 'react';

const NFCCard = props => {
  const { showPadding } = props;
  return (
    <div>
      <iframe
        title="NFCCard"
        src="https://w3.portfoplus.com/partner/nfccard/"
        style={{
          width: showPadding ? 'calc(100% - 60px)' : '100%',
          height: '100%',
          position: 'fixed',
          border: 'none',
          paddingTop: 45
        }}
      />
    </div>
  );
};

export default NFCCard;
