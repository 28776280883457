import { connect } from 'react-redux';
import { changePassword, updateUserDetails, getUser, handleSignOut } from '../../store/user/duck';
import {
  getTeamMemberPermission,
  updateTeamMemberPermission,
  getTeamMemberPermission_state
} from '../../store/team/duck';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import SettingsView from './component';
import { injectIntl } from 'react-intl';
import { selectedLocale } from '../../store/locale/action';
import { getUserSubscription } from '../../store/userSubscription/duck';

const Settings = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    changePasswordPhase: state.user.changePasswordPhase,
    message: state.user.message,
    updatePhase: state.user.updatePhase,
    newReferralCount: state.referral.newReferralCount,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    teamMemberPermissionPhase: state.team.teamMemberPermissionPhase,
    teamMemberPermissionData: state.team.teamMemberPermissionData,
    userSubscription: state.userSubscription.userSubscription,
    isBiometricAvailable: state.auth.isBiometricAvailable
  }),
  // Map actions to props
  {
    changePassword,
    updateUserDetails,
    getUser,
    handleSignOut,
    selectedLocale,
    getTeamMemberPermission,
    updateTeamMemberPermission,
    getTeamMemberPermission_state,
    getUserSubscription,
    renderHeaderButton
  }
)(SettingsView);

export default injectIntl(Settings);
