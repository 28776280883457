import { Capacitor } from '@capacitor/core';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { Preferences } from '@capacitor/preferences';
import { Dialog } from '@capacitor/dialog';
import { getGlobalIntl } from '../../reduxConnectedIntlProvider';
import { tryTranslate } from '../locale';

// console.log({ NativeBiometric });

const PUBLIC_URL = process.env.PUBLIC_URL || '';
const server = PUBLIC_URL.replace('https://', '')
  .replace('http://', '')
  .split(':')[0];

const verifyIdentity = () => {
  const intl = getGlobalIntl();

  return NativeBiometric.verifyIdentity({
    title: 'PortfoPlus Authentication',
    reason: intl.formatMessage({ id: 'biometric-verify-title' }),
    subtitle: intl.formatMessage({ id: 'biometric-verify-subtitle' })
  });
};

export const enableBiometricAuth = async () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform !== 'web') {
    try {
      let isVerified;
      try {
        const verifyResult = await verifyIdentity();
        // console.log(verifyResult);
        isVerified = true;
      } catch (error) {
        isVerified = false;
      }

      if (isVerified) {
        const storageResult = await Preferences.set({ key: 'biometricSet', value: JSON.stringify(true) });
        // console.log('storageResult', storageResult);
        return true;
      }
    } catch (error) {
      // console.log('storageResult', error);
      return false;
    }
  }

  return false;
};

export const disableBiometricAuth = async () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform !== 'web') {
    try {
      const storageResult = await Preferences.remove({ key: 'biometricSet' });
      // console.log('storageResult', storageResult);
      return true;
    } catch (error) {
      // console.log('storageResult', error);
      return false;
    }
  }

  return false;
};

export const isBiometricAvailable = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      const isAvailableResult = await NativeBiometric.isAvailable();
      return isAvailableResult.isAvailable;
    } catch (error) {
      // console.log('isBiometricAvailable error', error);
    }
  }

  return false;
};

export const isBiometricSet = async () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return false;
  } else {
    const isAvailableResult = await NativeBiometric.isAvailable();
    const isAvailable = isAvailableResult.isAvailable;
    // console.log('isAvailableResult', isAvailableResult);

    if (isAvailable) {
      let storageResult, credentials;

      try {
        storageResult = await Preferences.get({ key: 'biometricSet' });
      } catch (error) {
        // console.log('storage error', error);
        storageResult = {};
      }
      // console.log('storageResult', storageResult);

      try {
        credentials = await NativeBiometric.getCredentials({ server: server });
        // console.log(credentials);
      } catch (error) {
        // console.log('isBiometricSet error', error);
        credentials = {};
      }

      return !!JSON.parse(storageResult.value) && !!credentials.username && !!credentials.password;
    }

    return false;
  }
};

export const askBiometricAuth = async (username, password) => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return false;
  } else {
    let result;

    const isAvailableResult = await NativeBiometric.isAvailable();
    const isAvailable = isAvailableResult.isAvailable;

    // console.log('isAvailableResult', isAvailableResult.toString());

    if (isAvailable) {
      let askedStorageResult;
      try {
        askedStorageResult = await Preferences.get({ key: 'askedBiometricAuth' });
        // console.log(askedStorageResult);
      } catch (error) {
        // console.log('storage error', error);
        askedStorageResult = {};
      }
      const asked = !!JSON.parse(askedStorageResult.value);

      if (!asked) {
        let confirmResult;
        try {
          const intl = getGlobalIntl();

          confirmResult = await Dialog.confirm({
            title: tryTranslate(intl, 'ask-biometric-title'),
            message: tryTranslate(intl, 'ask-biometric-content')
          });
          // console.log('confirmResult', confirmResult.toString());

          try {
            const setStorageResult = await Preferences.set({
              key: 'askedBiometricAuth',
              value: JSON.stringify(true)
            });
            // console.log('setStorageResult', setStorageResult.toString());
          } catch (error) {
            // console.log('storage error', error);
          }
        } catch (error) {
          // console.log('dialog error', error);
          confirmResult = { value: false };
        }

        if (!!confirmResult.value) {
          try {
            await setBiometricCredentials(username, password);
          } catch (error) {
            // console.log('setBiometricCredentials error', error);
            result = false;
          }
          const enabled = await enableBiometricAuth();
          result = enabled;
        }
      }
    }

    // console.log('askBiometricAuth result', result);
    return result;
  }
};

export const setBiometricCredentials = async (username, password) => {
  const deleteResult = await NativeBiometric.deleteCredentials({ server: server });
  // console.log('deleteResult', deleteResult);

  return await NativeBiometric.setCredentials({
    username: username,
    password: password,
    server: server
  });
};

export const getBiometricCredentials = async () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return undefined;
  } else {
    try {
      const credentials = await NativeBiometric.getCredentials({ server: server });
      return credentials;
    } catch (error) {
      return undefined;
    }
  }
};

export const getBiometricCredentialsWithVerification = async () => {
  const currentPlatform = Capacitor.getPlatform();
  if (currentPlatform === 'web') {
    return undefined;
  } else {
    try {
      // Check if biometrics are available and which type is supported
      const isAvailableResult = await NativeBiometric.isAvailable();
      const isAvailable = isAvailableResult.isAvailable;

      if (isAvailable) {
        // Get user's credentials
        const credentials = await NativeBiometric.getCredentials({ server: server });
        // console.log(credentials);

        const verifyResult = await verifyIdentity();
        // console.log(verifyResult);

        return credentials;
      }

      return undefined;
    } catch (error) {
      // console.log(error);
      return undefined;
    }
  }
};
