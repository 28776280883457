import React from 'react';
import { injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import LoginTextField from '../../Login/LoginTextField/LoginTextField';
import { PhoneNumberInputWrapper } from '../../NewSignUp/contents/PhoneNumberInputWrapper';
import TermsCheckbox from '../../../components/TermsCheckbox/TermsCheckbox';

const ExtraDetails = props => {
  const {
    intl,
    paConnectedEmail,
    phoneNumber,
    password,
    confirmPassword,
    tacChecked,
    errors,
    loading,
    onChange,
    onSubmit
  } = props;

  return (
    <div className="section register-details">
      <Grid container justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <div className="logo-wrapper">
            <img src="img/new_adviser.png" alt="adviser" />
          </div>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h4" align="center">
            {intl.formatMessage({ id: 'adviser-sign-up' })}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ padding: '24px 0' }}>
        <LoginTextField
          name="paConnectedEmail"
          label={intl.formatMessage({ id: 'pa-connected-email' })}
          error={!!errors.paConnectedEmail}
          helperText={errors.paConnectedEmail ? errors.paConnectedEmail : undefined}
          value={paConnectedEmail}
          onChange={onChange}
          required={true}
        />

        <LoginTextField
          name="phoneNumber"
          label={intl.formatMessage({ id: 'Phone Number' })}
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber ? errors.phoneNumber : undefined}
          value={phoneNumber}
          onChange={onChange}
          InputProps={{
            inputComponent: PhoneNumberInputWrapper,
            inputProps: {
              intl: intl
            }
          }}
          InputLabelProps={{ shrink: true }}
          required={true}
        />

        <LoginTextField
          name="password"
          type="password"
          autoComplete="new-password"
          label={intl.formatMessage({ id: 'Password' })}
          error={!!errors.password}
          helperText={errors.password ? errors.password : undefined}
          value={password}
          onChange={onChange}
          required={true}
        />

        <LoginTextField
          name="confirmPassword"
          type="password"
          autoComplete="new-password"
          label={intl.formatMessage({ id: 'Confirm Password' })}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword ? errors.confirmPassword : undefined}
          value={confirmPassword}
          onChange={onChange}
          required={true}
        />

        <Button
          id="adviser-sign-up"
          className="action"
          variant="contained"
          color="primary"
          fullWidth={true}
          disabled={loading}
          onClick={onSubmit}
        >
          {intl.formatMessage({ id: 'create-ac' })}
        </Button>

        <TermsCheckbox checked={tacChecked} onChange={onChange} error={errors && errors.tacChecked} />
      </div>
    </div>
  );
};

export default injectIntl(ExtraDetails);
