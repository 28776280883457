import { disableDomInteraction, enableDomInteraction } from '../../utils/dom';
import store from '../index';
import { isStepLastClicked, runGuideline } from './duck';
import { closeHeaderMenu, openHeaderMenu } from '../headerButtonControl/duck';
import AdviserFirstGuidelineTooltip from '../../components/guidelines/AdviserFirstGuidelineTooltip/AdviserFirstGuidelineTooltip';
import palette from '../../views/App/palette.scss';
import { ACTIONS, STATUS } from 'react-joyride';
import { history } from '../../views/App/history';

export const adviserWelcome = ({ intl, onFinish }) => {
  return [
    {
      target: '.dashboard-guideline-icon',
      content: 'jr-aw-1',
      isFixed: true,
      disableScrolling: true,
      placement: 'auto',
      disableBeacon: true,
      spotlightClicks: true,
      callback: tour => {
        if (isStepLastClicked(tour)) {
          // disable dom interaction
          disableDomInteraction();

          // open side menu
          store.dispatch(openHeaderMenu());

          // wait for transition & run guideline
          setTimeout(() => {
            store.dispatch(
              runGuideline('adviserWelcome2', {
                parameters: { intl },
                showProgress: true,
                tooltipComponent: AdviserFirstGuidelineTooltip
              })
            );
            enableDomInteraction();
          }, 350);
        }
      },
      locale: {
        last: intl.formatMessage({ id: 'joyride_next' })
      }
    }
  ];
};

export const adviserWelcome2 = ({ intl, onFinish }) => {
  return [
    {
      target: '.nav-drawer .grouped-for-adviser-welcome',
      content: 'jr-aw-2',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      showProgress: false,
      spotlightClicks: false,
      callback: tour => {
        if (isStepLastClicked(tour)) {
          // disable dom interaction
          disableDomInteraction();

          // close drawer
          store.dispatch(closeHeaderMenu());

          // wait for transition & run guideline
          setTimeout(() => {
            store.dispatch(
              runGuideline('adviserWelcome3', {
                parameters: { intl },
                showProgress: true,
                tooltipComponent: AdviserFirstGuidelineTooltip
              })
            );

            enableDomInteraction();
          }, 350);
        }
      },
      locale: {
        last: intl.formatMessage({ id: 'joyride_next' })
      }
    }
  ];
};

export const adviserWelcome3 = ({ intl }) => {
  return [
    {
      target: '.biz-tools .help-center-btn',
      content: 'jr-aw-3',
      placement: 'auto',
      disableBeacon: true,
      isFixed: true,
      disableScrolling: true,
      showProgress: false,
      spotlightClicks: false,
      callback: tour => {
        if (tour.action === ACTIONS.NEXT && tour.status === STATUS.FINISHED) {
          history.push('/new_help_center');
        }
      },
      locale: {
        last: intl.formatMessage({ id: 'go-to' })
      },
      styles: {
        tooltipContent: {
          whiteSpace: 'pre-wrap'
        },
        buttonNext: {
          color: palette.primaryMain,
          backgroundColor: 'transparent'
        }
      }
    }
  ];
};
