import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PolicyPicker from "../../../../components/PolicyPicker/PolicyPicker";
import _ from "lodash";
import { Button } from "@material-ui/core";
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const ButtonStyle = {
	minWidth: 'unset',
	minHeight: 'unset',
	padding: '2px 8px',
};

const PolicyPickerButton = props => {
	const { viewingClient, selectedPolicies, filter, onSubmit, ...rest } = props;

	const [open, setOpen] = useState(false);
	const openPicker = () => setOpen(true);
	const closePicker = () => setOpen(false);

	return (
		<Fragment>
			<Button
				className="policy-picker-button"
				variant="contained"
				color="primary"
				onClick={openPicker}
				style={ButtonStyle}
			>
				<EditRoundedIcon />
			</Button>

			<PolicyPicker
				userId={_.get(viewingClient, '_id')}
				selectedPolicies={selectedPolicies}
				open={open}
				onClose={closePicker}
				filter={filter}
				onSubmit={onSubmit}
				{...rest}
			/>
		</Fragment>
	)
};

const container = connect(
	state => ({
		viewingClient: state.client.viewingClient
	}),
	{

	}
)(PolicyPickerButton);

export default container;