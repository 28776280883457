import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../store/control/duck';
import { SlideTransition } from '../../Transition/Transition';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  Typography
} from '@material-ui/core';
import { updateDemoClient } from '../../../store/client/duck';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';

const IsDemoClientWarningDialog = props => {
  const { intl, isDemoClientWarningDialog, toggleDialog, updateDemoClient } = props;

  const { client, onConfirm, loading, closed } = isDemoClientWarningDialog || {};

  const close = () => toggleDialog('isDemoClientWarning', { ...isDemoClientWarningDialog, closed: true });
  const onClosed = () => toggleDialog('isDemoClientWarning', false);
  const confirm = async () => {
    toggleDialog('isDemoClientWarning', { ...isDemoClientWarningDialog, loading: true });

    const updatedClient = await updateDemoClient(client._id);

    const updateObj = { ...isDemoClientWarningDialog, loading: false };
    if (updatedClient && updatedClient._id && !updatedClient.isDemo) {
      updateObj.closed = true;
      if (onConfirm) {
        onConfirm();
      }
    } else {
      toast.info(IconToast('error', intl.formatMessage({ id: 'todo-err-msg' })), { className: 'new-toast' });
    }
    toggleDialog('isDemoClientWarning', updateObj);
  };

  return (
    <Dialog
      open={!!isDemoClientWarningDialog && !closed}
      onClose={!loading ? close : undefined}
      onExited={onClosed}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'demo-con-warn-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Typography>{intl.formatMessage({ id: 'demo-con-warn-dialog-content' })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close} disabled={loading}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={confirm} disabled={loading}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    isDemoClientWarningDialog: state.control.isDemoClientWarningDialog
  }),
  {
    toggleDialog,
    updateDemoClient
  }
)(IsDemoClientWarningDialog);

export default injectIntl(container);
