import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

const MyWhiteboardInfoDialog = props => {
  const { open, onClose, ...rest } = props;

  return (
    <Dialog open={open} fullWidth={true} onClose={onClose} {...rest}>
      <DialogContent>
        <img src="img/financial-pyramid/whiteboard_Info.png" alt="info" width="100%" />
      </DialogContent>
    </Dialog>
  );
};

export default MyWhiteboardInfoDialog;
