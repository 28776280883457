import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { toggleDialog } from '../../store/control/duck';
import LoadingBackdrop from '../LoadingBackdrop/LoadingBackdrop';
import NativeOrWeb from '../../utils/native';
import { SyncStatus } from 'capacitor-codepush/dist/esm/syncStatus';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';

const OTAUpdateDialog = props => {
  const { intl, otaUpdateDialog, toggleDialog } = props;
  const [loading, setLoading] = useState(false);

  const { closed } = otaUpdateDialog || {};

  const update = async forceUpdate => {
    close();
    const status = await NativeOrWeb.updateOTA(forceUpdate);
    if (status === SyncStatus.ERROR) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'error-msg' })), { className: 'new-toast' });
    }
  };

  const updateNow = async () => {
    setLoading(true);
    await update(true);
    setLoading(false);
  };

  const close = () => {
    toggleDialog('otaUpdate', { ...otaUpdateDialog, closed: true });
  };

  const onExited = () => {
    toggleDialog('otaUpdate', false);
  };

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <Dialog open={!!otaUpdateDialog && !closed} onExited={onExited} TransitionComponent={SlideTransition}>
        <DialogTitle>{intl.formatMessage({ id: 'ota-update-confirm-dialog-title' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'ota-update-confirm-dialog-content' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={updateNow}>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const container = connect(
  state => ({
    otaUpdateDialog: state.control.otaUpdateDialog
  }),
  {
    toggleDialog
  }
)(OTAUpdateDialog);

export default injectIntl(container);
