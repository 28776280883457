import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import { Button, Container, Grid, Typography, CircularProgress } from '@material-ui/core';
import AdviserExportWarningDialog from './AdviserExportWarningDialog/AdviserExportWarningDialog';
import { adviserExport } from '../../../store/client/api';
import NativeOrWeb from '../../../utils/native';
import { toast } from 'react-toastify';
import IconToast from '../../../components/NewToast';
import moment from 'moment-timezone';
import { validatePassword, validatePasswordConfirm } from '../../../utils/validators';
import PasswordRulesInfoTooltip from '../../../components/PasswordRulesInfoTooltip/PasswordRulesInfoTooltip';
import { updateAdviserExportInputs } from '../../../store/adviserExport/duck';
import StyledTextField from '../../../components/StyledTextField/StyledTextField';

const GridContainerStyle = {
  padding: '16px 0',
  minHeight: 'calc(100vh - 100px - var(--top-padding) - var(--bottom-padding))'
};

const AdviserExport = props => {
  const { intl, loading, updateAdviserExportInputs } = props;

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [warningDialog, setWarningDialog] = useState(false);
  const [errors, setErrors] = useState({});

  const onChangePassword = event => {
    setErrors({});
    setPassword(event.target.value);
  };
  const onChangePasswordConfirm = event => {
    setErrors({});
    setPasswordConfirm(event.target.value);
  };
  const exportData = () => {
    const errs = {};
    const passwordValidationResult = validatePassword(password);
    if (passwordValidationResult) {
      errs.password = intl.formatMessage({ id: passwordValidationResult });
    }

    const passwordConfirmValidationResult = validatePasswordConfirm(passwordConfirm, password);
    if (passwordConfirmValidationResult) {
      errs.passwordConfirm = intl.formatMessage({ id: passwordConfirmValidationResult });
    }

    setErrors(errs);

    // if there is any error, do not send request
    if (Object.keys(errs).length === 0) {
      setWarningDialog(true);
    }
  };
  const onCloseWarningDialog = () => setWarningDialog(false);
  const onConfirmWarningDialog = async () => {
    if (!loading) {
      try {
        updateAdviserExportInputs('loading', true);

        const zipBlob = await adviserExport({ password });

        await NativeOrWeb.downloadFile(zipBlob, `Export_${moment().format('DDMMMYYYY')}.zip`, 'application/zip');
      } catch (error) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'Something went wrong' })), { className: 'new-toast' });
      } finally {
        updateAdviserExportInputs('loading', false);
      }
    }
  };

  useEffect(() => {
    return () => {
      setPassword('');
      setPasswordConfirm('');
      setErrors({});
      setWarningDialog(false);
    };
  }, []);

  return (
    <AppContentContainer>
      <AdviserExportWarningDialog
        open={warningDialog}
        onClose={onCloseWarningDialog}
        onConfirm={onConfirmWarningDialog}
      />

      <Container maxWidth="sm">
        <Grid container direction="column" justify="space-between" style={GridContainerStyle}>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'adviser-export-hint-1' })}</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'adviser-export-hint-2' })}</Typography>
              </Grid>
              <Grid item>
                <PasswordRulesInfoTooltip password={password}>
                  <StyledTextField
                    name="password"
                    type="password"
                    label={intl.formatMessage({ id: 'Password' })}
                    value={password}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password : undefined}
                    onChange={onChangePassword}
                  />
                </PasswordRulesInfoTooltip>
              </Grid>
              <Grid item>
                <PasswordRulesInfoTooltip password={passwordConfirm}>
                  <StyledTextField
                    name="passwordConfirm"
                    type="password"
                    label={intl.formatMessage({ id: 'Confirm Password' })}
                    value={passwordConfirm}
                    error={!!errors.passwordConfirm}
                    helperText={errors.passwordConfirm ? errors.passwordConfirm : undefined}
                    onChange={onChangePasswordConfirm}
                  />
                </PasswordRulesInfoTooltip>
              </Grid>
              <Grid item>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'adviser-export-hint-3' })}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'adviser-export-disclaimer' })}</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  fullWidth={true}
                  disabled={loading}
                  onClick={exportData}
                >
                  {loading ? <CircularProgress size={24.5} /> : intl.formatMessage({ id: 'adviser-export-action' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    loading: state.adviserExport.loading
  }),
  {
    updateAdviserExportInputs
  }
)(AdviserExport);

export default injectIntl(container);
