import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { insurerRegionFilter } from '../../../../utils/region';
import '../styles.scss';
import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { jsonParse } from '../../../../utils/json';
import SimplifiedViewToggle from '../SimplifiedViewToggle/SimplifiedViewToggle';
import AccountTypesInput from '../../Inputs/AccountTypesInput/AccountTypesInput';
import { formatPlanData, shouldUsePolicyNameInput } from '../../utils';
import { validatePlanData } from '../../validators';
import { DEFAULT_PLAN_DATA } from '../../constants';
import PolicyNameInput from '../../Inputs/PolicyNameInput/PolicyNameInput';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import AddOnsInput from '../../Inputs/AddOnsInput/AddOnsInput';
import { getInsurerTitleEn, isInsurerOther, isInsurerWhatIf } from '../../../../utils/insurer';
import { tryTranslate } from '../../../../utils/locale';

const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];
const subCategoryDeatils = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' },
  { id: '5b9a5bfee624cdb543a476d1', title: 'Maid' },
  { id: '5b9a5bfee624cdb543a476d2', title: 'Accident' },
  { id: '5baf2af899d85c2d38c8f00f', title: 'Saving' },
  { id: '5c18ebd120ca05b7e2b32565', title: 'Provident Fund' },
  { id: '5c41b85a83230016450b2214', title: 'Mortgage' }
];

class PlanView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_PLAN_DATA,

      insurerfix: '',
      err: {},
      Saving: 'No',
      NonSaving: 'No',
      NoIdea: 'No',
      NoIdea1: 'No',
      NoIdea2: 'No',
      keyArray: [],
      guaranteeArray: [],
      Basic: 'No',
      Rider: 'No',
      typeArray: [],
      modalIsOpen2: false,
      selectedInsurerData: {},
      vhisYes: 'No',
      vhisNo: 'No',
      vhisNoIdea: 'No',
      vhisArray: [],
      annuityArray: [],
      annuityYes: 'No',
      annuityNo: 'No',
      annuityNoIdea2: 'No'
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleButtonStatus = this.handleButtonStatus.bind(this);
    this.handleButtonTypeStatus = this.handleButtonTypeStatus.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
    this.submitInsurer = this.submitInsurer.bind(this);
    this.handleVhisStatus = this.handleVhisStatus.bind(this);

    this.editPolicyDetails = jsonParse(sessionStorage.getItem('editPolicyDetails'));
    this.editPolicy = jsonParse(sessionStorage.getItem('editPolicy'));
  }

  UNSAFE_componentWillMount() {
    var insurer = JSON.parse(localStorage.getItem('insurer'));
    var editPlan = JSON.parse(localStorage.getItem('editPlan'));
    var addRider = JSON.parse(localStorage.getItem('addRider'));
    var value = '';
    var planData = '';
    var categoryId = '';
    var subCategoryId = '';
    var subCategoryType = {};
    var previousPolicy = '';
    var categoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }
    if (this.editPolicyDetails) {
      planData = this.editPolicyDetails;
      if (!isInsurerOther(planData.insurer)) {
        value = planData.insurer;
      }
      this.setState({ insurerfix: getInsurerTitleEn(planData.insurerId), insurer: value });
    } else {
      if (insurer && !isInsurerOther(insurer)) {
        value = getInsurerTitleEn(insurer);
      }
      planData = JSON.parse(localStorage.getItem('plan'));
      this.setState({
        insurerfix: insurer ? getInsurerTitleEn(insurer) : '',
        insurer: value
      });
    }
    if (editPlan === 'true') {
      planData = JSON.parse(localStorage.getItem('plan'));
    }
    if (addRider === true) {
      previousPolicy = JSON.parse(localStorage.getItem('previousPolicyData'));
    }
    if (planData !== null) {
      if (planData.insurerId === '5a1be0f9dfaa60227838e426') {
        this.setState({ insurerfix: 'Other' });
      }
      //saving the policy data in there respective fields
      this.setState({
        insurer: planData.insurer,
        feature: planData.feature,
        type: planData.type,
        policyName: planData.policyName,
        companyName: planData.companyName,
        planType: planData.planType,
        guaranteeValue: planData.guaranteeValue ? planData.guaranteeValue : '',
        policyNumber: planData.policyNumber,
        insurerId: planData.insurerId,
        vhisPlan: planData.vhisPlan,
        coverageType: planData.vhisPlan !== 'Yes' && planData.coverageType === 'Flexi' ? '' : planData.coverageType,
        roomClass: planData.roomClass ? planData.roomClass : '',
        isAnnuity: _.get(planData, 'isAnnuity', ''),
        accountTypes: planData.accountTypes || [],
        addOns: planData.addOns || []
      });
      if (planData.feature === 'Saving') {
        this.setState({ Saving: 'Yes' });
      }
      if (planData.feature === 'Non Saving') {
        this.setState({ NonSaving: 'Yes' });
      }
      if (planData.feature === 'No Idea') {
        this.setState({ NoIdea: 'Yes' });
      }
      if (planData.planType === 'Basic') {
        this.setState({ Basic: 'Yes' });
      }
      if (planData.planType === 'Rider') {
        this.setState({ Rider: 'Yes' });
      }
      if (planData.planType === 'No Idea') {
        this.setState({ NoIdea1: 'Yes' });
      }
      if (planData.guaranteeValue === 'Yes') {
        this.setState({ Yes: 'Yes' });
      }
      if (planData.guaranteeValue === 'No') {
        this.setState({ No: 'Yes' });
      }
      if (planData.guaranteeValue === 'No Idea') {
        this.setState({ NoIdea2: 'Yes' });
      }
      if (planData.vhisPlan === 'Yes') {
        this.setState({ vhisYes: 'Yes' });
      }
      if (planData.vhisPlan === 'No') {
        this.setState({ vhisNo: 'Yes' });
      }
      if (planData.vhisPlan === 'No Idea') {
        this.setState({ vhisNoIdea: 'Yes' });
      }
      if (planData.isAnnuity === 'Yes') {
        this.setState({ annuityYes: 'Yes' });
      }
      if (planData.isAnnuity === 'No') {
        this.setState({ annuityNo: 'Yes' });
      }
      if (planData.isAnnuity === 'No Idea') {
        this.setState({ annuityNoIdea2: 'Yes' });
      }
    }
    if (previousPolicy != null && planData === null && previousPolicy !== '' && previousPolicy !== undefined) {
      if (insurer.id === '5a1be0f9dfaa60227838e426' && previousPolicy.insurerId === '5a1be0f9dfaa60227838e426') {
        this.setState({
          insurerfix: 'Other',
          insurer: _.get(previousPolicy, 'insurer', '')
        });
      }
      //saving the policy data in there respective fields

      //this.setState({ companyName: _.get(previousPolicy,'companyName','') });
      this.setState({
        type: _.get(previousPolicy, 'type', ''),
        planType: 'Rider',
        guaranteeValue: _.get(previousPolicy, 'guaranteeValue', ''),
        policyNumber: _.get(previousPolicy, 'policyNumber', ''),
        coverageType: _.get(previousPolicy, 'coverageType', ''),
        roomClass: _.get(previousPolicy, 'roomClass', ''),
        Rider: 'Yes'
      });
      if (_.get(previousPolicy, 'guaranteeValue') === 'Yes') {
        this.setState({ Yes: 'Yes' });
      }
      if (_.get(previousPolicy, 'guaranteeValue') === 'No') {
        this.setState({ No: 'Yes' });
      }
      if (_.get(previousPolicy, 'guaranteeValue') === 'No Idea') {
        this.setState({ NoIdea2: 'Yes' });
      }
    }
    /*if (planData === null && subCategoryType.title === 'Other') {
			  this.setState({ type: 'Accident' });
			}
			if (planData === null && categoryType.title === 'General') {
			  this.setState({ type: 'Domestic Helper' });
			}*/
  }

  handleChangePolicyName = event => {
    this.handleChange({ target: { name: 'policyName', value: event.target.value } });
  };

  handleChange(e) {
    if (e.target.name === 'policyName') {
      delete this.state.err.policyName;
    }
    if (e.target.name === 'type') {
      delete this.state.err.type;
    }
    if (e.target.name === 'companyName') {
      delete this.state.err.companyName;
    }
    if (e.target.name === 'coverageType') {
      delete this.state.err.coverageType;
    }
    if (e.target.name === 'roomClass') {
      delete this.state.err.roomClass;
    }
    if (e.target.name === 'insurer') {
      delete this.state.err.insurer;
    }

    if (e.target.name === 'coverageType' || e.target.name === 'roomClass') {
      if (this.state[e.target.name] === e.target.value) {
        e.target.value = '';
      }
    }

    this.setState({ [e.target.name]: e.target.value });
  }

  //Submit the form of Plan Type
  submitForm() {
    var categoryId = '';
    var categoryType = {};
    var subCategoryId = '';
    var subCategoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;

      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }

    const err = validatePlanData(this.state, categoryType, subCategoryType);
    this.setState({ err });
    if (!Object.keys(err).length) {
      formatPlanData(this.state);
      this.props.handleNextPage();
    }
  }

  //Handle the status of guarantee value
  handleGuaranteeValue(key) {
    delete this.state.err.guaranteeValue;
    const state = this.state.guaranteeArray;
    if (state.indexOf(key) === -1) {
      this.setState({ guaranteeArray: [...this.state.guaranteeArray, key] });
      if (key === 0) {
        this.setState({
          Yes: 'Yes',
          No: 'No',
          NoIdea2: 'No',
          guaranteeValue: 'Yes'
        });
      }
      if (key === 1) {
        this.setState({
          Yes: 'No',
          No: 'Yes',
          NoIdea2: 'No',
          guaranteeValue: 'No',
          annuityArray: [],
          annuityYes: 'No',
          annuityNo: 'No',
          annuityNoIdea2: 'No',
          isAnnuity: ''
        });
      }
      if (key === 2) {
        this.setState({
          Yes: 'No',
          No: 'No',
          NoIdea2: 'Yes',
          guaranteeValue: 'No Idea'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Yes !== 'No') {
          this.setState({ Yes: 'No', guaranteeValue: '' });
        } else {
          this.setState({
            Yes: 'Yes',
            No: 'No',
            NoIdea2: 'No',
            guaranteeValue: 'Yes'
          });
        }
      }
      if (key === 1) {
        if (this.state.No !== 'No') {
          this.setState({ No: 'No', guaranteeValue: '' });
        } else {
          this.setState({
            Yes: 'No',
            No: 'Yes',
            NoIdea2: 'No',
            guaranteeValue: 'No',
            annuityArray: [],
            annuityYes: 'No',
            annuityNo: 'No',
            annuityNoIdea2: 'No',
            isAnnuity: ''
          });
        }
      }
      if (key === 2) {
        if (this.state.NoIdea2 !== 'No') {
          this.setState({ NoIdea2: 'No', guaranteeValue: '' });
        } else {
          this.setState({
            Yes: 'No',
            No: 'No',
            NoIdea2: 'Yes',
            guaranteeValue: 'No Idea'
          });
        }
      }
    }
  }

  //Handle the status of Feature
  handleButtonStatus(key) {
    const state = this.state.keyArray;
    delete this.state.err.feature;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({
          Saving: 'Yes',
          NonSaving: 'No',
          NoIdea: 'No',
          feature: 'Saving'
        });
      }
      if (key === 1) {
        this.setState({
          Saving: 'No',
          NonSaving: 'Yes',
          NoIdea: 'No',
          feature: 'Non Saving'
        });
      }
      if (key === 2) {
        this.setState({
          Saving: 'No',
          NonSaving: 'No',
          NoIdea: 'Yes',
          feature: 'No Idea'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Saving !== 'No') {
          this.setState({ Saving: 'No', feature: '' });
        } else {
          this.setState({
            Saving: 'Yes',
            NonSaving: 'No',
            NoIdea: 'No',
            feature: 'Saving'
          });
        }
      }
      if (key === 1) {
        if (this.state.NonSaving !== 'No') {
          this.setState({ NonSaving: 'No', feature: '' });
        } else {
          this.setState({
            Saving: 'No',
            NonSaving: 'Yes',
            NoIdea: 'No',
            feature: 'Non Saving'
          });
        }
      }
      if (key === 2) {
        if (this.state.NoIdea !== 'No') {
          this.setState({ NoIdea: 'No', feature: '' });
        } else {
          this.setState({
            Saving: 'No',
            NonSaving: 'No',
            NoIdea: 'Yes',
            feature: 'No Idea'
          });
        }
      }
    }
  }

  //Handle the status of plan type
  handleButtonTypeStatus(key) {
    const state = this.state.typeArray;
    delete this.state.err.planType;
    if (state.indexOf(key) === -1) {
      this.setState({ typeArray: [...this.state.typeArray, key] });
      if (key === 0) {
        this.setState({
          Basic: 'Yes',
          Rider: 'No',
          NoIdea1: 'No',
          planType: 'Basic'
        });
      }
      if (key === 1) {
        this.setState({
          Basic: 'No',
          Rider: 'Yes',
          NoIdea1: 'No',
          planType: 'Rider'
        });
      }
      if (key === 2) {
        this.setState({
          Basic: 'No',
          Rider: 'No',
          NoIdea1: 'Yes',
          planType: 'No Idea'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Basic !== 'No') {
          this.setState({ Basic: 'No', planType: '' });
        } else {
          this.setState({
            Basic: 'Yes',
            Rider: 'No',
            NoIdea1: 'No',
            planType: 'Basic'
          });
        }
      }
      if (key === 1) {
        if (this.state.Rider !== 'No') {
          this.setState({ Rider: 'No', planType: '' });
        } else {
          this.setState({
            Basic: 'No',
            Rider: 'Yes',
            NoIdea1: 'No',
            planType: 'Rider'
          });
        }
      }
      if (key === 2) {
        if (this.state.NoIdea1 !== 'No') {
          this.setState({ NoIdea1: 'No', planType: '' });
        } else {
          this.setState({
            Basic: 'No',
            Rider: 'No',
            NoIdea1: 'Yes',
            planType: 'No Idea'
          });
        }
      }
    }
  }

  //Handle the status of VHIS Plan
  handleVhisStatus(key) {
    delete this.state.err.vhisPlan;
    const state = this.state.vhisArray;
    if (state.indexOf(key) === -1) {
      this.setState({ vhisArray: [...this.state.vhisArray, key] });
      if (key === 0) {
        this.setState({
          vhisYes: 'Yes',
          vhisNo: 'No',
          vhisNoIdea: 'No',
          vhisPlan: 'Yes'
        });
      }
      if (key === 1) {
        this.setState({
          vhisYes: 'No',
          vhisNo: 'Yes',
          vhisNoIdea: 'No',
          vhisPlan: 'No'
        });
      }
      if (key === 2) {
        this.setState({
          vhisYes: 'No',
          vhisNo: 'No',
          vhisNoIdea: 'Yes',
          vhisPlan: 'No Idea'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.vhisYes !== 'No') {
          this.setState({ vhisYes: 'No', vhisPlan: '' });
        } else {
          this.setState({
            vhisYes: 'Yes',
            vhisNo: 'No',
            vhisNoIdea: 'No',
            vhisPlan: 'Yes'
          });
        }
      }
      if (key === 1) {
        if (this.state.vhisNo !== 'No') {
          this.setState({ vhisNo: 'vhisNo', vhisPlan: '' });
        } else {
          this.setState({
            vhisYes: 'No',
            vhisNo: 'Yes',
            vhisNoIdea: 'No',
            vhisPlan: 'No'
          });
        }
      }
      if (key === 2) {
        if (this.state.vhisNoIdea !== 'No') {
          this.setState({ vhisNoIdea: 'No', vhisPlan: '' });
        } else {
          this.setState({
            vhisYes: 'No',
            vhisNo: 'No',
            vhisNoIdea: 'Yes',
            vhisPlan: 'No Idea'
          });
        }
      }
    }
    if (key !== 0 && this.state.coverageType === 'Flexi') {
      this.setState({ coverageType: '' });
    }
  }

  closeModal2() {
    this.setState({
      modalIsOpen2: false,
      selectedInsurerData: {}
    });
  }

  //Open the ,odal of insurer
  openInsurerModal() {
    this.props.insurerInfo();
    this.setState({
      modalIsOpen2: true
    });
  }

  //Submit the insurer
  submitInsurer(val, index) {
    delete this.state.err.insurer;
    this.setState({
      insurerfix: getInsurerTitleEn(val),
      insurer: !isInsurerOther(val) ? getInsurerTitleEn(val) : '',
      insurerId: val._id,
      selectedInsurerIndex: index,
      modalIsOpen2: false
    });
    let value = {};
    value.title = _.get(val, 'title', '');
    value.id = _.get(val, '_id', '');
    localStorage.setItem('insurer', JSON.stringify(value));
  }

  //Handle the editing of insurer
  // handleInsurerEdit(val, index) {
  //   this.setState({
  //     selectedInsurerData: val,
  //     selectedInsurerIndex: index
  //   });
  // }

  //Handle the status of annuity value
  handleAnnuityValue(key) {
    delete this.state.err.isAnnuity;
    const state = this.state.annuityArray;
    if (state.indexOf(key) === -1) {
      this.setState({ annuityArray: [...this.state.annuityArray, key] });
      if (key === 0) {
        this.setState({
          annuityYes: 'Yes',
          annuityNo: 'No',
          annuityNoIdea2: 'No',
          isAnnuity: 'Yes'
        });
      }
      if (key === 1) {
        this.setState({
          annuityYes: 'No',
          annuityNo: 'Yes',
          annuityNoIdea2: 'No',
          isAnnuity: 'No'
        });
      }
      if (key === 2) {
        this.setState({
          annuityYes: 'No',
          annuityNo: 'No',
          annuityNoIdea2: 'Yes',
          isAnnuity: 'No Idea'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.annuityYes !== 'No') {
          this.setState({ annuityYes: 'No', isAnnuity: '' });
        } else {
          this.setState({
            annuityYes: 'Yes',
            annuityNo: 'No',
            annuityNoIdea2: 'No',
            isAnnuity: 'Yes'
          });
        }
      }
      if (key === 1) {
        if (this.state.annuityNo !== 'No') {
          this.setState({ annuityNo: 'No', isAnnuity: '' });
        } else {
          this.setState({
            annuityYes: 'No',
            annuityNo: 'Yes',
            annuityNoIdea2: 'No',
            isAnnuity: 'No'
          });
        }
      }
      if (key === 2) {
        if (this.state.annuityNoIdea2 !== 'No') {
          this.setState({ annuityNoIdea2: 'No', isAnnuity: '' });
        } else {
          this.setState({
            annuityYes: 'No',
            annuityNo: 'No',
            annuityNoIdea2: 'Yes',
            isAnnuity: 'No Idea'
          });
        }
      }
    }
  }

  render() {
    const { insurers } = this.props;
    let filteredInsurers = (insurers || []).filter(insurerRegionFilter);
    if (filteredInsurers.length > 0) {
      let indexOther = filteredInsurers.findIndex(isInsurerOther);
      if (indexOther !== -1) {
        let obj = filteredInsurers[indexOther];
        filteredInsurers.splice(indexOther, 1);
        filteredInsurers.push(obj);
      }

      let indexWhatIf = filteredInsurers.findIndex(isInsurerWhatIf);
      if (indexWhatIf !== -1) {
        let obj = filteredInsurers[indexWhatIf];
        filteredInsurers.splice(indexWhatIf, 1);
        filteredInsurers.push(obj);
      }
    }

    var categoryId = '';
    var subCategoryId = '';
    var categoryType = {};
    var subCategoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;
      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }

    const { intl, history } = this.props;
    let addRider = JSON.parse(localStorage.getItem('addRider'));

    return (
      <div>
        {this.editPolicy !== true && (
          <TopNavigationPortal>
            <span
              onClick={() =>
                history.replace(addRider !== true ? '/insurer' : '/insurance_type', history.location.state)
              }
              className="back_arrow"
            >
              <ArrowBackIcon />
            </span>
          </TopNavigationPortal>
        )}
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  {categoryType && categoryType.title === 'Personal' ? (
                    <form id="msform" noValidate={true}>
                      <ul id="progressbar">
                        <li className="active" />
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Plan' })}</h2>
                        <div className="form-group">
                          <input
                            type="text"
                            name={this.state.insurerfix === 'Other' ? 'insurer' : ''}
                            value={
                              this.state.insurerfix !== 'Other'
                                ? tryTranslate(intl, this.state.insurer)
                                : this.state.insurer
                            }
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Insurer' })}*
                          </label>
                          <i className="bar" />
                          {this.state.err.insurer ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.insurer
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                          {this.editPolicyDetails !== undefined ? (
                            <Button
                              className="action-change-button"
                              variant="contained"
                              color="primary"
                              onClick={this.openInsurerModal.bind(this)}
                            >
                              {intl.formatMessage({ id: 'Change' })}
                            </Button>
                          ) : (
                            ''
                          )}
                        </div>

                        {subCategoryType.title === 'Other' ? (
                          <div className="form-group">
                            <input
                              type="text"
                              name="type"
                              value={this.state.type}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Type' })}*
                            </label>
                            <i className="bar" />
                            {this.state.err.type ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.type
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="select-buttons-div">
                          <div className="control-tab">{intl.formatMessage({ id: 'Feature' })}*</div>
                          <div>
                            <Button
                              variant={this.state.Saving === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Saving === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={0}
                              onClick={() => this.handleButtonStatus(0)}
                            >
                              {intl.formatMessage({ id: 'Saving' })}
                            </Button>

                            <Button
                              variant={this.state.NonSaving === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.NonSaving === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={1}
                              onClick={() => this.handleButtonStatus(1)}
                            >
                              {intl.formatMessage({ id: 'Non Saving' })}
                            </Button>

                            <Button
                              variant={this.state.NoIdea === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.NoIdea === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={2}
                              onClick={() => this.handleButtonStatus(2)}
                            >
                              {intl.formatMessage({ id: 'No Idea' })}
                            </Button>
                          </div>
                          <i className="bar" />
                          {this.state.err.feature ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.feature
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="form-group">
                          <PolicyNameInput
                            insurer={this.state.insurer}
                            subCategoryId={subCategoryType.value}
                            label={intl.formatMessage({ id: 'pf-plan-name' })}
                            value={this.state.policyName}
                            onChange={this.handleChangePolicyName}
                            error={this.state.err.policyName}
                          />
                        </div>
                        <div className="select-buttons-div">
                          <div className="control-tab source-button">{intl.formatMessage({ id: 'Plan Type' })}*</div>
                          <div>
                            {this.state.Rider === 'Yes' && (
                              <img alt="" className="not_me_icon" src="img/rider_small.png" />
                            )}
                            <Button
                              variant={this.state.Basic === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Basic === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive-rider'
                                  : 'plan-type-select-button source-button-responsive-rider'
                              }
                              key={0}
                              onClick={() => this.handleButtonTypeStatus(0)}
                            >
                              {intl.formatMessage({ id: 'Basic' })}
                            </Button>

                            <Button
                              variant={this.state.Rider === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Rider === 'Yes'
                                  ? 'plan-type-select-button  selected source-button-responsive-rider'
                                  : 'plan-type-select-button source-button-responsive-rider'
                              }
                              key={1}
                              onClick={() => this.handleButtonTypeStatus(1)}
                            >
                              {intl.formatMessage({ id: 'Rider' })}
                            </Button>

                            <Button
                              variant={this.state.NoIdea1 === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.NoIdea1 === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive-rider'
                                  : 'plan-type-select-button source-button-responsive-rider'
                              }
                              key={2}
                              onClick={() => this.handleButtonTypeStatus(2)}
                            >
                              {intl.formatMessage({ id: 'No Idea' })}
                            </Button>
                          </div>
                          <i className="bar" />
                          {this.state.err.planType ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.planType
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>

                        {categoryType.title === 'Personal' && (
                          <div className="form-group">
                            <AddOnsInput
                              value={this.state.addOns}
                              onChange={event => this.setState({ addOns: event.target.value })}
                            />
                          </div>
                        )}

                        <div className="form-group">
                          <input
                            type="text"
                            name="policyNumber"
                            value={this.state.policyNumber}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {subCategoryType.title === 'Provident Fund'
                              ? intl.formatMessage({ id: 'pf-member-number' })
                              : intl.formatMessage({ id: 'Policy Number' })}
                          </label>
                          <i className="bar" />
                        </div>
                        {subCategoryType.title === 'Medical' && (
                          <div>
                            <div className="select-buttons-div">
                              <div className="control-orphay source-button">
                                {intl.formatMessage({ id: 'VHIS Plan' })}*
                              </div>
                              <div>
                                <Button
                                  variant={this.state.vhisYes === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.vhisYes === 'Yes'
                                      ? 'plan-type-select-button selected source-button-responsive'
                                      : 'plan-type-select-button source-button-responsive'
                                  }
                                  key={0}
                                  onClick={() => this.handleVhisStatus(0)}
                                >
                                  {intl.formatMessage({ id: 'Yes' })}
                                </Button>

                                <Button
                                  variant={this.state.vhisNo === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.vhisNo === 'Yes'
                                      ? 'plan-type-select-button selected source-button-responsive'
                                      : 'plan-type-select-button source-button-responsive'
                                  }
                                  key={1}
                                  onClick={() => this.handleVhisStatus(1)}
                                >
                                  {intl.formatMessage({ id: 'No' })}
                                </Button>

                                <Button
                                  variant={this.state.vhisNoIdea === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.vhisNoIdea === 'Yes'
                                      ? 'plan-type-select-button selected'
                                      : 'plan-type-select-button'
                                  }
                                  key={2}
                                  onClick={() => this.handleVhisStatus(2)}
                                >
                                  {intl.formatMessage({ id: 'No Idea' })}
                                </Button>
                              </div>
                              <i className="bar" />
                              {this.state.err.vhisPlan ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.vhisPlan
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="select-buttons-div">
                              <div
                                className={`control-orphay source-button ${
                                  this.state.vhisPlan === 'Yes' ? 'source-button-edit' : ''
                                }`}
                              >
                                {intl.formatMessage({ id: 'Coverage Type' })}*
                              </div>
                              <div>
                                <Button
                                  variant={this.state.coverageType === 'Regular' ? 'contained' : 'outlined'}
                                  className={
                                    'plan-type-select-button source-button-responsive' +
                                    (this.state.coverageType === 'Regular' ? ' selected source-button-responsive' : '')
                                  }
                                  key={0}
                                  onClick={() =>
                                    this.handleChange({
                                      target: {
                                        name: 'coverageType',
                                        value: 'Regular'
                                      }
                                    })
                                  }
                                >
                                  {intl.formatMessage({ id: 'Regular' })}
                                </Button>
                                {this.state.vhisPlan === 'Yes' && (
                                  <Button
                                    variant={this.state.coverageType === 'Flexi' ? 'contained' : 'outlined'}
                                    className={
                                      'plan-type-select-button source-button-responsive' +
                                      (this.state.coverageType === 'Flexi' ? ' selected source-button-responsive' : '')
                                    }
                                    key={1}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'coverageType',
                                          value: 'Flexi'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Flexi' })}
                                  </Button>
                                )}
                                <Button
                                  variant={this.state.coverageType === 'Highend' ? 'contained' : 'outlined'}
                                  className={
                                    'plan-type-select-button source-button-responsive' +
                                    (this.state.coverageType === 'Highend' ? ' selected source-button-responsive' : '')
                                  }
                                  key={2}
                                  onClick={() =>
                                    this.handleChange({
                                      target: {
                                        name: 'coverageType',
                                        value: 'Highend'
                                      }
                                    })
                                  }
                                >
                                  {intl.formatMessage({ id: 'Highend' })}
                                </Button>
                              </div>
                              <i className="bar" />
                              {this.state.err.coverageType ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.coverageType
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="select-buttons-div">
                              <div className="control-orphay">{intl.formatMessage({ id: 'Room Class' })}*</div>
                              <div>
                                <div className="plan-type-select-buttons-row">
                                  <Button
                                    variant={this.state.roomClass === 'Semi Ward' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Semi Ward' ? ' selected' : '')
                                    }
                                    key={0}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Semi Ward'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Semi Ward' })}
                                  </Button>

                                  <Button
                                    variant={this.state.roomClass === 'Ward' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Ward' ? ' selected' : '')
                                    }
                                    key={1}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Ward'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Ward' })}
                                  </Button>
                                </div>

                                <div className="plan-type-select-buttons-row">
                                  <Button
                                    variant={this.state.roomClass === 'Semi Private' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Semi Private' ? ' selected' : '')
                                    }
                                    key={2}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Semi Private'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Semi Private' })}
                                  </Button>

                                  <Button
                                    variant={this.state.roomClass === 'Private' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Private' ? ' selected' : '')
                                    }
                                    key={3}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Private'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Private' })}
                                  </Button>
                                </div>
                              </div>
                              <i className="bar" />
                              {this.state.err.roomClass ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.roomClass
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )}
                        <Grid container justify={'flex-end'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className="prevNextButton"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}

                  {categoryType && categoryType.title === 'Company' ? (
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" />
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Plan' })}</h2>
                        <div className="form-group">
                          <input
                            type="text"
                            name={this.state.insurerfix === 'Other' ? 'insurer' : ''}
                            value={
                              this.state.insurerfix !== 'Other'
                                ? tryTranslate(intl, this.state.insurer)
                                : this.state.insurer
                            }
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {subCategoryType.title === 'Provident Fund'
                              ? intl.formatMessage({ id: 'pf-trustee' })
                              : intl.formatMessage({ id: 'Insurer' })}
                            *
                          </label>
                          <i className="bar" />
                          {this.state.err.insurer ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.insurer
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                          {this.editPolicyDetails !== undefined ? (
                            <input
                              type="button"
                              value={intl.formatMessage({ id: 'Change' })}
                              className="action-button action-change-button"
                              onClick={this.openInsurerModal.bind(this)}
                            />
                          ) : (
                            ''
                          )}
                        </div>

                        {subCategoryType.title === 'Other' ? (
                          <div className="form-group">
                            <input
                              type="text"
                              name="type"
                              value={this.state.type}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Type' })}*
                            </label>
                            <i className="bar" />
                            {this.state.err.type ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.type
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="form-group">
                          <input
                            type="text"
                            name="companyName"
                            value={this.state.companyName}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Workplace Name' })}*
                          </label>
                          <i className="bar" />
                          {this.state.err.companyName ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.companyName
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        {subCategoryType.title === 'Medical' && (
                          <div className="select-buttons-div">
                            <div>
                              <div className="control-orphay source-button">
                                {intl.formatMessage({ id: 'Coverage Type' })}*
                              </div>
                              <div>
                                <Button
                                  variant={this.state.coverageType === 'Regular' ? 'contained' : 'outlined'}
                                  className={
                                    'plan-type-select-button source-button-responsive' +
                                    (this.state.coverageType === 'Regular' ? ' selected source-button-responsive' : '')
                                  }
                                  key={0}
                                  onClick={() =>
                                    this.handleChange({
                                      target: {
                                        name: 'coverageType',
                                        value: 'Regular'
                                      }
                                    })
                                  }
                                >
                                  {intl.formatMessage({ id: 'Regular' })}
                                </Button>

                                <Button
                                  variant={this.state.coverageType === 'Highend' ? 'contained' : 'outlined'}
                                  className={
                                    'plan-type-select-button source-button-responsive' +
                                    (this.state.coverageType === 'Highend' ? ' selected source-button-responsive' : '')
                                  }
                                  key={1}
                                  onClick={() =>
                                    this.handleChange({
                                      target: {
                                        name: 'coverageType',
                                        value: 'Highend'
                                      }
                                    })
                                  }
                                >
                                  {intl.formatMessage({ id: 'Highend' })}
                                </Button>
                              </div>
                              <i className="bar" />
                              {this.state.err.coverageType ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.coverageType
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>

                            <div className="select-buttons-div">
                              <div className="control-orphay">{intl.formatMessage({ id: 'Room Class' })}*</div>
                              <div>
                                <div className="plan-type-select-buttons-row">
                                  <Button
                                    variant={this.state.roomClass === 'Semi Ward' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Semi Ward' ? ' selected' : '')
                                    }
                                    key={0}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Semi Ward'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Semi Ward' })}
                                  </Button>

                                  <Button
                                    variant={this.state.roomClass === 'Ward' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Ward' ? ' selected' : '')
                                    }
                                    key={1}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Ward'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Ward' })}
                                  </Button>
                                </div>

                                <div className="plan-type-select-buttons-row">
                                  <Button
                                    variant={this.state.roomClass === 'Semi Private' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Semi Private' ? ' selected' : '')
                                    }
                                    key={2}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Semi Private'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Semi Private' })}
                                  </Button>

                                  <Button
                                    variant={this.state.roomClass === 'Private' ? 'contained' : 'outlined'}
                                    className={
                                      'half-width-button plan-type-select-button' +
                                      (this.state.roomClass === 'Private' ? ' selected' : '')
                                    }
                                    key={3}
                                    onClick={() =>
                                      this.handleChange({
                                        target: {
                                          name: 'roomClass',
                                          value: 'Private'
                                        }
                                      })
                                    }
                                  >
                                    {intl.formatMessage({ id: 'Private' })}
                                  </Button>
                                </div>
                              </div>
                              <i className="bar" />
                              {this.state.err.roomClass ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.roomClass
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )}
                        <div className="form-group">
                          <input
                            type="text"
                            name="policyNumber"
                            value={this.state.policyNumber || ''}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {subCategoryType.title === 'Provident Fund'
                              ? intl.formatMessage({ id: 'pf-member-number' })
                              : intl.formatMessage({ id: 'Policy Number' })}
                            :
                          </label>
                          <i className="bar" />
                        </div>
                        <Grid container justify={'flex-end'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}

                  {categoryType && categoryType.title === 'General' ? (
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" />
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      {(!this.editPolicy || addRider) && subCategoryType.title === 'Provident Fund' && (
                        <div style={{ width: '98%', textAlign: 'right' }}>
                          <SimplifiedViewToggle beforeSwitch={() => formatPlanData(this.state)} />
                        </div>
                      )}
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Plan' })}</h2>
                        <div className="form-group">
                          <input
                            type="text"
                            name={this.state.insurerfix === 'Other' ? 'insurer' : ''}
                            value={
                              this.state.insurerfix !== 'Other'
                                ? tryTranslate(intl, this.state.insurer)
                                : this.state.insurer
                            }
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {subCategoryType.title === 'Mortgage'
                              ? intl.formatMessage({ id: 'MortageCompany' })
                              : subCategoryType.title === 'Provident Fund'
                              ? intl.formatMessage({ id: 'pf-trustee' })
                              : intl.formatMessage({ id: 'Insurer' })}
                            *
                          </label>
                          <i className="bar" />
                          {this.state.err.insurer ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.insurer
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                          {this.editPolicyDetails !== undefined ? (
                            <input
                              type="button"
                              value={intl.formatMessage({ id: 'Change' })}
                              className="action-button action-change-button"
                              onClick={this.openInsurerModal.bind(this)}
                            />
                          ) : (
                            ''
                          )}
                        </div>

                        {subCategoryType.title === 'Other' ? (
                          <div className="form-group">
                            <input
                              type="text"
                              name="type"
                              value={this.state.type}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Type' })}*
                            </label>
                            <i className="bar" />
                            {this.state.err.type ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.type
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="form-group">
                          <PolicyNameInput
                            insurer={this.state.insurer}
                            subCategoryId={subCategoryType.value}
                            label={intl.formatMessage({ id: 'pf-plan-name' })}
                            value={this.state.policyName}
                            onChange={this.handleChangePolicyName}
                            error={this.state.err.policyName}
                          />
                          {/*{subCategoryType.title === 'Provident Fund' &&*/}
                          {/*shouldUsePolicyNameInput(this.state.insurer) ? (*/}
                          {/*  <PolicyNameInput*/}
                          {/*    insurer={this.state.insurer}*/}
                          {/*    label={intl.formatMessage({ id: 'pf-plan-name' })}*/}
                          {/*    value={this.state.policyName}*/}
                          {/*    onChange={this.handleChangePolicyName}*/}
                          {/*    error={this.state.err.policyName}*/}
                          {/*  />*/}
                          {/*) : (*/}
                          {/*  <Fragment>*/}
                          {/*    <input*/}
                          {/*      type="text"*/}
                          {/*      name="policyName"*/}
                          {/*      value={this.state.policyName}*/}
                          {/*      onChange={this.handleChange.bind(this)}*/}
                          {/*      required="required"*/}
                          {/*    />*/}
                          {/*    <label className="control-label" htmlFor="input">*/}
                          {/*      {subCategoryType.title === 'Provident Fund'*/}
                          {/*        ? intl.formatMessage({ id: 'pf-plan-name' })*/}
                          {/*        : intl.formatMessage({ id: 'Policy Name' })}*/}
                          {/*      **/}
                          {/*    </label>*/}
                          {/*    <i className="bar" />*/}
                          {/*    {this.state.err.policyName ? (*/}
                          {/*      <span className="error_field">*/}
                          {/*        {intl.formatMessage({*/}
                          {/*          id: this.state.err.policyName*/}
                          {/*        })}*/}
                          {/*      </span>*/}
                          {/*    ) : (*/}
                          {/*      ''*/}
                          {/*    )}*/}
                          {/*  </Fragment>*/}
                          {/*)}*/}
                        </div>

                        {(subCategoryType.title === 'Saving' || subCategoryType.title === 'Accident') && (
                          <div className="select-buttons-div">
                            <div className="control-tab source-button">{intl.formatMessage({ id: 'Plan Type' })}*</div>
                            <div>
                              {this.state.Rider === 'Yes' && (
                                <img alt="" className="not_me_icon" src="img/rider_small.png" />
                              )}
                              <Button
                                variant={this.state.Basic === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.Basic === 'Yes'
                                    ? 'plan-type-select-button selected source-button-responsive-rider'
                                    : 'plan-type-select-button source-button-responsive-rider'
                                }
                                key={0}
                                onClick={() => this.handleButtonTypeStatus(0)}
                              >
                                {intl.formatMessage({ id: 'Basic' })}
                              </Button>

                              <Button
                                variant={this.state.Rider === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.Rider === 'Yes'
                                    ? 'plan-type-select-button  selected source-button-responsive-rider'
                                    : 'plan-type-select-button source-button-responsive-rider'
                                }
                                key={1}
                                onClick={() => this.handleButtonTypeStatus(1)}
                              >
                                {intl.formatMessage({ id: 'Rider' })}
                              </Button>

                              <Button
                                variant={this.state.NoIdea1 === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.NoIdea1 === 'Yes'
                                    ? 'plan-type-select-button selected source-button-responsive-rider'
                                    : 'plan-type-select-button source-button-responsive-rider'
                                }
                                key={2}
                                onClick={() => this.handleButtonTypeStatus(2)}
                              >
                                {intl.formatMessage({ id: 'No Idea' })}
                              </Button>
                            </div>
                            <i className="bar" />
                            {this.state.err.planType ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.planType
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}

                        {subCategoryType.title === 'Saving' && (
                          <div>
                            <div className="select-buttons-div">
                              <div className="control-orphay">
                                {intl.formatMessage({
                                  id: 'Got Guarantee Value'
                                })}
                                *
                              </div>
                              <div style={{ textAlign: 'left' }}>
                                <Button
                                  variant={this.state.Yes === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.Yes === 'Yes'
                                      ? 'plan-type-select-button selected'
                                      : 'plan-type-select-button'
                                  }
                                  key={0}
                                  onClick={() => this.handleGuaranteeValue(0)}
                                >
                                  {intl.formatMessage({ id: 'Yes' })}
                                </Button>

                                <Button
                                  variant={this.state.No === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.No === 'Yes'
                                      ? 'plan-type-select-button selected'
                                      : 'plan-type-select-button'
                                  }
                                  key={1}
                                  onClick={() => this.handleGuaranteeValue(1)}
                                >
                                  {intl.formatMessage({ id: 'No (ILAS)' })}
                                </Button>

                                <Button
                                  variant={this.state.NoIdea2 === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.NoIdea2 === 'Yes'
                                      ? 'plan-type-select-button selected'
                                      : 'plan-type-select-button'
                                  }
                                  key={2}
                                  onClick={() => this.handleGuaranteeValue(2)}
                                >
                                  {intl.formatMessage({ id: 'No Idea' })}
                                </Button>
                              </div>
                              <i className="bar" />
                              {this.state.err.guaranteeValue ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.guaranteeValue
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            {this.state.No === 'Yes' && (
                              <div
                                className="control-orphay"
                                style={{
                                  paddingBottom: '20px',
                                  color: '#3966f8',
                                  fontSize: '16px'
                                }}
                              >
                                {intl.formatMessage({
                                  id: 'It is an ILAS plan'
                                })}{' '}
                                <img
                                  alt=""
                                  style={{ width: '100%', maxWidth: '18px' }}
                                  className="rider__1"
                                  src="img/ilas_small.png"
                                />
                              </div>
                            )}
                            {this.state.guaranteeValue !== 'No' && (
                              <div className="select-buttons-div">
                                <div className="control-orphay source-button-default">
                                  {intl.formatMessage({
                                    id: 'annuity-plan'
                                  })}
                                  *
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                  <Button
                                    variant={this.state.annuityYes === 'Yes' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.annuityYes === 'Yes'
                                        ? 'plan-type-select-button selected'
                                        : 'plan-type-select-button'
                                    }
                                    key={0}
                                    onClick={() => this.handleAnnuityValue(0)}
                                  >
                                    {intl.formatMessage({ id: 'Yes' })}
                                  </Button>

                                  <Button
                                    variant={this.state.annuityNo === 'Yes' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.annuityNo === 'Yes'
                                        ? 'plan-type-select-button selected'
                                        : 'plan-type-select-button'
                                    }
                                    key={1}
                                    onClick={() => this.handleAnnuityValue(1)}
                                  >
                                    {intl.formatMessage({ id: 'No' })}
                                  </Button>

                                  <Button
                                    variant={this.state.annuityNoIdea2 === 'Yes' ? 'contained' : 'outlined'}
                                    className={
                                      this.state.annuityNoIdea2 === 'Yes'
                                        ? 'plan-type-select-button selected'
                                        : 'plan-type-select-button'
                                    }
                                    key={2}
                                    onClick={() => this.handleAnnuityValue(2)}
                                  >
                                    {intl.formatMessage({ id: 'No Idea' })}
                                  </Button>
                                </div>
                                <i className="bar" />
                                {this.state.err.isAnnuity ? (
                                  <span className="error_field">
                                    {intl.formatMessage({
                                      id: this.state.err.isAnnuity
                                    })}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        <div className="form-group">
                          <input
                            type="text"
                            name="policyNumber"
                            value={this.state.policyNumber}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {subCategoryType.title === 'Provident Fund'
                              ? intl.formatMessage({ id: 'pf-member-number' })
                              : intl.formatMessage({ id: 'Policy Number' })}
                            :
                          </label>
                          <i className="bar" />
                        </div>

                        {subCategoryType.title === 'Provident Fund' && (
                          <div className="form-group">
                            <AccountTypesInput
                              value={this.state.accountTypes}
                              onChange={event => this.setState({ accountTypes: event.target.value })}
                            />
                          </div>
                        )}

                        <Grid container justify={'flex-end'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={this.state.modalIsOpen2}
                    onClose={this.closeModal2}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {intl.formatMessage({ id: 'Select-insurer' })}
                    </DialogTitle>
                    <DialogContent>
                      {/* <div className="col-lg-12"> */}
                      <ul className="card-list-insurance insure">
                        {filteredInsurers && filteredInsurers.length > 0
                          ? filteredInsurers.map((val, index) => {
                              return (
                                <li
                                  className={this.state.selectedInsurerIndex === index ? 'ins-info-selected' : ''}
                                  key={index}
                                  onClick={this.submitInsurer.bind(this, val, index)}
                                >
                                  <div className="bg-white">
                                    <img className="image1" src={val.image} alt="" />
                                    <h4>{val.title[intl.locale]}</h4>
                                  </div>
                                </li>
                              );
                            })
                          : ''}
                      </ul>
                      {/* </div> */}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeModal2} color="primary">
                        {intl.formatMessage({ id: 'Close' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanView;
