import { connect } from 'react-redux';
import TargetsCalculatorView from './component';
import { selectedLocale } from '../../store/locale/action';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { injectIntl } from 'react-intl';

const TargetsCalculator = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to dispatch and props
  { selectedLocale, renderHeaderButton }
)(TargetsCalculatorView);

export default injectIntl(TargetsCalculator);
