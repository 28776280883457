import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Link, CircularProgress
} from '@material-ui/core';
import HistoryKeywords from './components/LIST';
import React, { Fragment } from "react";
import { history } from '../../App/history';
import { injectIntl } from 'react-intl';

const host = process.env.HEADLESS_CMS_URL

const lts = host.concat("/api/weekly-keywords?sort=createdAt:desc&pagination[limit]=1&populate[keyword][populate]=*&populate[preview]=*")
const clist = host.concat("/api/weekly-keywords?pagination[limit]=10&sort=createdAt:desc&populate[keyword]=*&populate[preview]=*")
const details = host.concat("/api/weekly-keywords/{id}?populate[keyword][populate]=*&populate[preview]=*")


function getImageUrl(/** @type {Object} */ image, /** @type {Object} */ key,) {
    if (image.formats && image.formats[key]) {
        return image.formats[key] ? image.formats[key].url : null;
    } else if (image?.data?.attributes?.formats) {
        return image.data.attributes.formats[key] ? image.data.attributes.formats[key].url : null;
    }
    return null
}

function WeeklyKeywords(props) {
    console.log("WeeklyKeywords: ", props)
    // console.log("WeeklyKeywords PATHNAME: ", props.location.pathname)
    const [article, setArticle] = React.useState(null);
    const [list, setList] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { intl } = props;

    React.useEffect(
        () => {
            // console.log(" --- WeeklyKeywords USE EFFECT ---")
            const id = props?.match?.params?.id
            if (id) {
                setLoading(true)
                // console.log("calling ID: ", id)
                Promise.all([
                    fetch(id === "lts" ? lts : details.replace("{id}", id)),
                    fetch(clist)
                ]).then(async ([res1, res2]) => {
                    return Promise.all([res1.json(), res2.json()])
                }).then(([data1, data2]) => {
                    // console.log(" --- DATA GOT FROM CMS ---")
                    // console.log(" DATA1: ", data1)
                    // console.log(" DATA2: ", data2)
                    if (id === "lts" && data1.data.length > 0) {
                        setArticle(data1.data[0])
                    } else if (id !== "lts" && data1.data) {
                        setArticle(data1.data)
                    }
                    setList(data2.data)
                    // console.log(" --- SETTING ARTICLE AND LIST ---")
                }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    // console.log(" --- FINALLY ---, setloading to false")
                    // console.log(" ARTICLE: ", article)
                    // console.log(" LIST: ", list)
                    setLoading(false)
                })
            }
        }, [props.location.pathname])

    // console.log(" --- RENDER ---")
    // console.log(" ARTICLE: ", article)
    // console.log(" LIST: ", list)

    if (article && article.attributes && !loading) {
        // console.log(" article && article.attributes && !loading ")
        // Destructure the data for easier access
        const { heading, subhead, call_to_action, action, closing, vol, keyword, preview } = article.attributes;
        const previewImage = preview ? getImageUrl(article.attributes.preview, 'medium') : null;

        return (
            <Fragment key={props.location.pathname} >
                <Container maxWidth="sm" style={{ padding: '46px 0px 80px 0px' }}>
                    <Card>
                        {preview && ( // Conditionally render the image
                            <CardMedia
                                component="img"
                                image={previewImage}
                                alt={preview.alternativeText}
                            />
                        )}

                        <CardContent>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {heading}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {subhead}
                            </Typography>

                            <Typography variant="h6" component="h3" gutterBottom mt={2}>
                                {vol} Keywords:
                            </Typography>
                            <Grid style={{ padding: '12px 0px 12px 0px' }} container spacing={2}> {/* Use Grid for image layout */}
                                {keyword.map((kw) => {
                                    const keywordImage = kw.image ? getImageUrl(kw.image, 'thumbnail') : null;
                                    return (
                                        <Grid item xs={12} sm={6} md={4} key={kw.id}> {/* Adjust grid breakpoints as needed */}
                                            <Card>
                                                {keywordImage && ( // Conditionally render if kw.imageUrl exists 
                                                    <CardMedia
                                                        component="img"
                                                        height="150" // Adjust image height as needed
                                                        image={keywordImage}
                                                        alt={kw.keyword}
                                                    />
                                                )}
                                                <CardContent>
                                                    <Typography variant="body1" component="p">
                                                        {kw.keyword}
                                                    </Typography>
                                                    <Typography variant="body1" component="p">
                                                        {kw.detail}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )

                                })}
                            </Grid>


                            <Link href={action} target="_blank" rel="noopener">
                                <Typography variant="body2" color="textSecondary" component="p" mt={2}>
                                    {call_to_action}:
                                </Typography>
                            </Link>

                            <Typography variant="body2" color="textSecondary" component="p" mt={2}>
                                {closing}
                            </Typography>
                        </CardContent>

                        <CardContent>
                            <Typography variant="h5" color="textSecondary" component="p" mt={2}>
                                {intl.formatMessage({ id: 'weekly-keywords-history-label' })}
                            </Typography>
                            <HistoryKeywords list={list} getImage={getImageUrl} onClick={(kw) => history.push(`/weekly_keywords/${kw.id}`)} />
                        </CardContent>
                    </Card>

                </Container>

            </Fragment >
        );
    }
    else return (
        <Fragment>
            <Container>
                <CircularProgress color="primary" />
            </Container>
        </Fragment>)

}
export default injectIntl(WeeklyKeywords);