import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';

const ConfirmDialog = props => {
  const { intl, open, onClose, onConfirm, ...rest } = props;

  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(undefined);

  const toggleConfirmed = event => {
    setError(undefined);
    setConfirmed(event.target.checked);
  };

  const confirm = () => {
    if (confirmed) {
      onConfirm();
      close();
    } else {
      setError('*Please check the info checkbox');
    }
  };

  const close = () => {
    onClose();
    setError(undefined);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs" fullWidth={true} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'Confirm' })}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            <Typography color="textSecondary">{intl.formatMessage({ id: 'adv-disclaim' })}</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Checkbox color="primary" checked={confirmed} onClick={toggleConfirmed} />}
              label={intl.formatMessage({ id: 'agree' })}
              style={{ margin: 0 }}
            />
          </Grid>
          {error && (
            <Grid item>
              <Typography color="error">{intl.formatMessage({ id: error })}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'Close' })}
        </Button>
        <Button color="primary" onClick={confirm}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ConfirmDialog);
