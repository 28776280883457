import { Capacitor } from '@capacitor/core';
import { getStorage, removeStorage, saveStorage } from './preferences';
import { DynamicAppIcon as AppIcon } from '@capacitor/dynamic-app-icon';
import store from '../../store';
import _ from 'lodash';
import { toggleDialog } from '../../store/control/duck';

export const RESET_ICON_STORAGE = 'resetIcon';

export const getAppIcon = () => {
  const state = store.getState();
  const appIcon = _.get(state, 'functions.appIcon.id');
  return appIcon;
};

export const isIconSupported = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      const result = await AppIcon.isSupported();
      return result.value;
    } catch (error) {
      console.error(error);
      return false;
    }
  } else {
    return false;
  }
};

export const isIconChangeable = async () => {
  const isChanged = await isIconChanged();

  if (isChanged) {
    return true;
  }

  const isSupported = await isIconSupported();
  return isSupported && !!getAppIcon();
};

export const isIconChanged = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      const result = await AppIcon.getName();
      return !!result.value;
    } catch (error) {
      console.error(error);
      return false;
    }
  } else {
    return false;
  }
};

export const getIconName = async () => {
  if (Capacitor.isNativePlatform()) {
    try {
      const result = await AppIcon.getName();
      return result.value;
    } catch (error) {
      console.error(error);
      return null;
    }
  } else {
    return null;
  }
};

export const autoChangeAppIcon = async appIconId => {
  const platform = Capacitor.getPlatform();
  if (platform === 'ios' || platform === 'android') {
    const iconName = await getIconName();
    if (iconName !== appIconId) {
      if (appIconId) {
        const resetIconStorage = await getStorage(RESET_ICON_STORAGE);
        if (!resetIconStorage) {
          store.dispatch(toggleDialog('appIconChanging', { appIconId }));
        }
      } else {
        // if (platform === 'ios') {
        //   await resetIcon(true);
        // }
      }
    }
  }
};

export const changeAppIcon = async appIconId => {
  const iconName = appIconId || getAppIcon();
  if (iconName) {
    await changeIcon(iconName);
    await removeStorage(RESET_ICON_STORAGE);
  }
};

export const changeIcon = async iconName => {
  if (Capacitor.isNativePlatform()) {
    if (await isIconSupported()) {
      await AppIcon.change({ name: iconName, suppressNotification: true });
    }
  }
};

export const resetIcon = async isSystemSet => {
  if (Capacitor.isNativePlatform()) {
    if (await isIconSupported()) {
      if (!isSystemSet) {
        await saveStorage(RESET_ICON_STORAGE, 'true');
      }
      await AppIcon.reset({ suppressNotification: true });
    }
  }
};
