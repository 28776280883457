Object.defineProperty(exports, '__esModule', {
  value: true
});

var _createBlockStyleButton = require('../../utils/createBlockStyleButton');

var _createBlockStyleButton2 = _interopRequireDefault(_createBlockStyleButton);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

exports.default = (0, _createBlockStyleButton2.default)({
  blockType: 'header-three',
  children: 'H3'
});
