import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { acceptShareById, getShareById } from '../../../store/share/api';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import _ from 'lodash';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import './ShareWIthAdviserIn.scss';
import { getClient } from '../../../store/client/duck';
import { Redirect } from 'react-router';
import { setClientIdLocalStorage } from '../../../components/ClientEntry/utils';
import AppContentContainer from '../../../components/AppContentContainer/AppContentContainer';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

const ShareWIthAdviserIn = props => {
  const { intl, history } = props;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const shareId = history.location.pathname.replace('/share-a-in/', '');

  const userDetails = useSelector(state => state.user.userDetails);
  const userType = _.get(userDetails, 'userType');
  const intlFormatMessage = intl.formatMessage;
  const historyPush = history.push;
  const dispatch = useDispatch();

  const [share, setShare] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [acceptError, setAcceptError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const onAccepted = useCallback(
    finalShare => {
      setAccepted(true);
      setAcceptError(false);

      // fetch client list beforehand
      dispatch(getClient());

      // redirect to the client list after 3 seconds
      setTimeout(() => {
        const senderId = _.get(finalShare, 'senderId._id');
        setClientIdLocalStorage(senderId);
        historyPush({
          pathname: '/client',
          select: 'particular',
          preSelectedClientIds: [senderId]
        });
      }, 3000);
    },
    [dispatch, historyPush]
  );

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoading(true);
      if (isAuthenticated && userType === 'Adviser') {
        const result = await getShareById(shareId);
        if (isMounted) {
          if (!result.error) {
            const share = _.get(result, 'share');
            setShare(share);
            if (share) {
              // retrieval succeeds (own share entry) but it is already verified
              if (result.share.linkVerified) {
                if (!result.share.email) {
                  // cannot find share entry, probably it is already accepted by other adviser
                  setError(intlFormatMessage({ id: 'share-verified' }));
                } else {
                  setError(false);
                  onAccepted(share);
                }
              } else {
                // check whether it is expired or not
                const expiryDate = _.get(share, 'expiryDate');
                if (!expiryDate || new Date(expiryDate).getTime() >= new Date().getTime()) {
                  setError(false);
                } else {
                  setError(intlFormatMessage({ id: 'share-expired' }));
                }
              }
            }
          } else {
            setShare(undefined);
            setError(result.error.toString());
          }
        }
      }
      setLoading(false);
    };
    fetchData().then();

    return () => {
      isMounted = false;
    };
  }, [intlFormatMessage, isAuthenticated, onAccepted, shareId, userType]);

  const accept = async () => {
    setLoading(true);
    const result = await acceptShareById(shareId);
    if (!result.error) {
      onAccepted(share);
    } else {
      const errorMsg = result.error.toString();
      if (errorMsg === 'The client is already connected') {
        setAcceptError(intl.formatMessage({ id: 'share-client-connected' }));
      } else {
        setAcceptError(errorMsg);
      }
    }
    setLoading(false);
  };

  return isAuthenticated ? (
    <Fragment>
      {loading && <LoadingBackdrop open={true} />}

      <AppContentContainer>
        <div className="share-wif-adv-in">
          {userDetails.userType === 'Adviser' ? (
            <Fragment>
              {!share ? (
                !loading && <Typography>{intl.formatMessage({ id: 'share-not-found' })}</Typography>
              ) : (
                <Fragment>
                  <Typography color="textSecondary" paragraph={true}>
                    {intl.formatMessage(
                      { id: 'adv-share-connect-msg' },
                      { name: share.senderId.name, email: share.senderId.email }
                    )}
                  </Typography>
                  {share.existingInactiveUser && (
                    <Typography color="textSecondary" paragraph={true}>
                      {intl.formatMessage({ id: 'adv-share-merge-client-msg' })}
                    </Typography>
                  )}
                  {accepted ? (
                    <Fragment>
                      <CheckCircleRoundedIcon color="primary" style={{ fontSize: 90 }} />
                      <Typography color="textSecondary">{intl.formatMessage({ id: 'redirect-soon' })}</Typography>
                    </Fragment>
                  ) : error ? (
                    <Typography color="error">{error}</Typography>
                  ) : (
                    <Button variant="contained" color="primary" onClick={accept} disabled={loading}>
                      {intl.formatMessage({ id: 'Confirm' })}
                    </Button>
                  )}
                </Fragment>
              )}

              {acceptError && <Typography color="error">{intl.formatMessage({ id: acceptError })}</Typography>}
            </Fragment>
          ) : (
            <Typography color="error">You are not Adviser</Typography>
          )}
        </div>
      </AppContentContainer>
    </Fragment>
  ) : (
    <Redirect to={{ pathname: '/login', nextUrl: history.location.pathname }} />
  );
};

export default injectIntl(withRouter(ShareWIthAdviserIn));
