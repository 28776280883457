import { connect } from 'react-redux';
import {
  fetchAdviserTeamDetail,
  updateTeamsOfAdviser,
  initPhase,
  fetchAdviserBusiness,
  updateAdviserBusiness
} from '../../../store/adviserDashboard/duck';
import { renderHeaderButton } from '../../../store/headerButtonControl/duck';
import { handleSignOut } from '../../../store/user/duck';
import { selectedLocale } from '../../../store/locale/action';
import AdvisorTeamDashboardComponent from './component';

import { injectIntl } from 'react-intl';

const AdvisorTeamDashboardContainer = connect(
  // Map state to props
  state => ({
    getAdviserTeamDetail: state.adviserDashboard.getAdviserTeamDetail,
    userDetails: state.user.userDetails,
    teamTrackingData: state.adviserDashboard.teamTrackingData,
    teamDetailPhase: state.adviserDashboard.teamDetailPhase,
    teamsTargetAndBiz: state.adviserDashboard.teamsTargetAndBiz,
    updateTeamTargetPhase: state.adviserDashboard.updateTeamTargetPhase,
    adviserBusiness: state.adviserDashboard.adviserBusiness
  }),
  // Map actions to props
  {
    selectedLocale,
    fetchAdviserTeamDetail,
    updateTeamsOfAdviser,
    initPhase,
    handleSignOut,
    fetchAdviserBusiness,
    updateAdviserBusiness,
    renderHeaderButton
  }
)(AdvisorTeamDashboardComponent);

export default injectIntl(AdvisorTeamDashboardContainer);
