import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import ShareToClientMessageDialog from '../../../views/Advisor/Client/partials/ShareToClientMessageDialog/ShareToClientMessageDialog';

class GreetingDialog extends React.PureComponent {
  close = () => this.props.toggleDialog('greeting', { ...this.props.greetingDialog, closed: true });
  closed = () => this.props.toggleDialog('greeting', false);

  render() {
    const { greetingDialog } = this.props;

    if (!greetingDialog) return null;

    const { closed } = greetingDialog;

    return (
      <ShareToClientMessageDialog
        open={!!greetingDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        {...greetingDialog}
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    greetingDialog: state.control.greetingDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(GreetingDialog);

export default injectIntl(container);
