import React from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './ConfirmAddPolicyDialog.scss';

const ConfirmAddPolicyDialog = props => {
  const { intl, onClose, onSubmit, onRider, onAnother, onExport, title, ...rest } = props;

  return (
    <Dialog className="confirm-add-policy-dialog" TransitionComponent={SlideTransition} fullWidth={true} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: title ? title : 'confirm-add-policy-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Typography color="textSecondary" gutterBottom={true}>
          {intl.formatMessage({ id: 'choose-next-action' })}
        </Typography>
        <Grid className="next-actions" container justify="center" alignItems="center" spacing={2}>
          {onRider && (
            <Grid item xs={12} sm={3}>
              <Button variant="outlined" color="primary" onClick={onRider}>
                {intl.formatMessage({ id: 'Add Rider' })}
              </Button>
            </Grid>
          )}
          {onAnother && (
            <Grid item xs={12} sm={3}>
              <Button variant="outlined" color="primary" onClick={onAnother}>
                {intl.formatMessage({ id: 'add-another-policy' })}
              </Button>
            </Grid>
          )}
          {/* {onExport && (
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<img alt="portfolio" src="img/export_blue.svg" height="20" />}
                onClick={onExport}
              >
                {intl.formatMessage({ id: 'After_add_Export_action' })}
              </Button>
            </Grid>
          )} */}
        </Grid>
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button onClick={onClose} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
        )}
        <Button onClick={onSubmit} color="primary">
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ConfirmAddPolicyDialog);
