import React from 'react';
import { injectIntl } from 'react-intl';
import { TextField, IconButton, Button, Checkbox } from '@material-ui/core';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import TodayIcon from '@material-ui/icons/Today';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { isMobile } from 'react-device-detect';
import './TodoEditor.scss';

const styles = {
  inputBase: {
    fontSize: 'inherit'
  },
  input: {
    height: '15px',
    fontSize: 15
  }
};

class TodoEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      todo: props.todo.todo,
      dueDate: props.todo.dueDate,
      important: props.todo.important,
      showDatePicker: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focus && this.props.focus) {
      this.ref.current.focus();
    } else if (!this.props.submit && prevProps.submit) {
      //reset fields for adding new todo
      this.setState({
        todo: this.props.todo._id ? this.props.todo.todo : '',
        dueDate: this.props.todo._id ? this.props.todo.dueDate : null,
        important: this.props.todo._id ? this.props.todo.important : false
      });
    }
  }

  handleClose = () => {
    this.props.closeEditor();
    this.setState({
      todo: this.props.todo._id ? this.props.todo.todo : '',
      dueDate: this.props.todo._id ? this.props.todo.dueDate : null,
      important: this.props.todo._id ? this.props.todo.important : false
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  onSubmit = () => {
    if (
      this.state.todo === this.props.todo.todo &&
      this.state.dueDate === this.props.todo.dueDate &&
      this.state.important === this.props.todo.important &&
      this.props.todo._id
    ) {
      this.props.closeEditor();
      return;
    }
    let newTodo = {
      ...this.props.todo,
      todo: this.state.todo,
      dueDate: this.state.dueDate,
      important: this.state.important
    };
    this.props.onSave();
    if (!this.props.todo._id) {
      this.props.addAdviserTodo({ todo: newTodo });
    } else {
      this.props.updateAdviserTodo({ todo: newTodo });
    }
  };

  setImportant = () => this.setState({ important: !this.state.important });

  onDelete = () => this.props.deleteAdviserTodo({ todoId: this.props.todo._id.toString() });

  render() {
    return (
      <div className="editor-container">
        <TextField
          onChange={e => this.setState({ todo: e.target.value })}
          multiline={true}
          inputRef={this.ref}
          label={
            this.props.todo._id
              ? this.props.intl.formatMessage({ id: 'update-todo' })
              : this.props.intl.formatMessage({ id: 'add-todo' })
          }
          variant="outlined"
          disabled={this.props.submit}
          onKeyPress={this.handleKeyPress}
          InputProps={{
            classes: {
              root: this.props.classes.inputBase,
              input: this.props.classes.input
            }
          }}
          value={this.state.todo}
        />
        <div className="main-fields">
          <div className="date-field" onClick={() => this.setState({ showDatePicker: true })}>
            <TodayIcon />
            <div className="date">
              {this.state.dueDate ? moment(new Date(this.state.dueDate)).format('DD-MMM-YYYY') : ''}
            </div>
          </div>
          <div className="star">
            <IconButton onClick={this.setImportant}>
              <Checkbox
                disabled={this.props.submit}
                tabIndex={-1}
                disableRipple
                checked={this.state.important}
                checkedIcon={<StarIcon className="checked" />}
                icon={<StarBorderIcon className="unchecked" />}
              />
            </IconButton>
          </div>
        </div>
        <div className={'save-and-cancel' + (this.props.todo._id ? '' : ' no-delete')}>
          {this.props.todo._id && (
            <IconButton edge="end" aria-label="delete" disabled={this.props.submit} onClick={this.props.onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
          <div>
            <Button disabled={this.props.submit} onClick={this.handleClose}>
              {this.props.intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button disabled={this.props.submit} onClick={this.onSubmit}>
              {this.props.intl.formatMessage({ id: this.props.todo._id ? 'Save' : 'Submit' })}
            </Button>
          </div>
        </div>
        <div className="date-picker">
          <DatePicker
            open={this.state.showDatePicker}
            className="form-group datepicker-group"
            name="Due Date"
            format="DD-MMM-YYYY"
            clearable={true}
            disabled={this.props.submit}
            minDate={Date.now()}
            value={this.state.dueDate}
            onClose={() => this.setState({ showDatePicker: false })}
            onChange={date => this.setState({ dueDate: date ? date.format('DD-MMM-YYYY') : date })}
            autoOk={false}
            invalidDateMessage={null}
            minDateMessage={null}
            maxDateMessage={null}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(TodoEditor));
