import Backend from '../backend';
import { fetch } from './index';

const PULL_JOB_INTERVAL = 5000;

export const handleJobResponse = async (fetchPromise, { pollTimeout }) => {
  const response = await fetchPromise;

  const json = await response.json();

  // If the response is not 202 & no job, then it is not a job response, return it as is
  if (response.status !== 202 && !json.job) {
    return json;
  }

  const { pollEndpoint } = json;

  if (!pollEndpoint) {
    throw new Error('No poll endpoint');
  }

  const pollUrl = Backend.methodUrl(pollEndpoint);

  const continueFetchImportClientJobUntilDone = async () => {
    await new Promise(resolve => setTimeout(resolve, pollTimeout || PULL_JOB_INTERVAL));

    const job = await fetch(pollUrl)
      .then(res => {
        return res.json();
      })
      .then(payload => {
        return payload;
      })
      .catch(error => {
        throw error;
      });

    if (job) {
      const { tasks, completed, failed } = job;
      // If all tasks are completed or failed, then the job is done
      if (tasks.length === completed.length + failed.length) {
        return job;
      }
    }

    return await continueFetchImportClientJobUntilDone();
  };

  try {
    const job = await continueFetchImportClientJobUntilDone();

    // delete job immediately after it is done
    fetch(pollUrl, { method: 'DELETE' })
      .then()
      .catch(console.log);

    return job;
  } catch (error) {
    throw error;
  }
};
