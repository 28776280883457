import { connect } from 'react-redux';

import { selectedLocale } from '../../../../store/locale/action';
import AcceptInvitationComponent from './component';

import { injectIntl } from 'react-intl';

const AcceptTeamInvitation = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {
    selectedLocale
  }
)(AcceptInvitationComponent);

export default injectIntl(AcceptTeamInvitation);
