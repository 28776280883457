import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { deleteRecord } from '../../../../../store/referral/api';

const NewClientAddPolicyDialog = props => {
  const { intl, onNo, onYes, ...rest } = props;
  const [removeFromNewLeads, setRemoveFromNewLeads] = useState(true);

  function nextStep(addPolicy) {
    let referralRecordId = sessionStorage.getItem('referralRecordId');
    if (removeFromNewLeads && referralRecordId) {
      deleteRecord(referralRecordId);
    }
    sessionStorage.removeItem('referralRecordId');

    if (addPolicy) {
      onYes();
    } else {
      onNo();
    }
  }

  const onChangeRemoveFromNewLeads = event => {
    setRemoveFromNewLeads(event.target.checked);
  };

  return (
    <Dialog TransitionComponent={SlideTransition} fullWidth={true} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'client-added' })}</DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>
          {intl.formatMessage({ id: 'client-added-dialog-content' })}
        </Typography>
        {sessionStorage.getItem('referralRecordId') && (
          <div>
            <FormControlLabel
              control={<Checkbox checked={removeFromNewLeads} onChange={onChangeRemoveFromNewLeads} />}
              label={<Typography style={{fontSize: 12}}>{intl.formatMessage({ id: 'remove-from-new-leads' })}</Typography>}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => nextStep(false)}>
          {intl.formatMessage({ id: 'show-client' })}
        </Button>
        <Button color="primary" onClick={() => nextStep(true)}>
          {intl.formatMessage({ id: 'add-policy' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(NewClientAddPolicyDialog);
