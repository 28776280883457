import { Map } from 'immutable';
export const PYRAMID_COLORS = ['#66339a', '#0079e0', '#1EA3D7', '#00bbaf'];
export const DEFAULT_FINANCIAL_PYRAMID = Map({
  en: {
    risk: [{ name: 'Bitcoin', value: 0, monthlyPayment: 0 }, {}, {}, {}, {}],
    investment: [
      { name: 'Stock', value: 0, monthlyPayment: 0 },
      { name: 'Fund', value: 0, monthlyPayment: 0 },
      { name: 'MPF', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    saving: [
      { name: 'Cash', value: 0, monthlyPayment: 0 },
      { name: 'Foreign Currency', value: 0, monthlyPayment: 0 },
      { name: 'Savings Plan', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    protection: [
      { name: 'Life', value: 0, monthlyPayment: 0 },
      { name: 'C.I.', value: 0, monthlyPayment: 0 },
      { name: 'Medical', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    emergencyFund: 0
  },
  zh: {
    risk: [{ name: '比特币', value: 0, monthlyPayment: 0 }, {}, {}, {}, {}],
    investment: [
      { name: '股票', value: 0, monthlyPayment: 0 },
      { name: '基金', value: 0, monthlyPayment: 0 },
      { name: '强积金', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    saving: [
      { name: '现金', value: 0, monthlyPayment: 0 },
      { name: '外币', value: 0, monthlyPayment: 0 },
      { name: '储蓄计划', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    protection: [
      { name: '人寿', value: 0, monthlyPayment: 0 },
      { name: '危疾', value: 0, monthlyPayment: 0 },
      { name: '医疗', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    emergencyFund: 0
  },
  'zh-Hant-HK': {
    risk: [{ name: '比特幣', value: 0, monthlyPayment: 0 }, {}, {}, {}, {}],
    investment: [
      { name: '股票', value: 0, monthlyPayment: 0 },
      { name: '基金', value: 0, monthlyPayment: 0 },
      { name: '強積金', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    saving: [
      { name: '現金', value: 0, monthlyPayment: 0 },
      { name: '外幣', value: 0, monthlyPayment: 0 },
      { name: '儲蓄計劃', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    protection: [
      { name: '人壽', value: 0, monthlyPayment: 0 },
      { name: '危疾', value: 0, monthlyPayment: 0 },
      { name: '醫療', value: 0, monthlyPayment: 0 },
      {},
      {}
    ],
    emergencyFund: 0
  }
});
