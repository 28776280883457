import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { scrollIt } from '../../utils/scroll';

const Style = {
  position: 'fixed',
  top: 10,
  right: 10,
  opacity: 0.5,
  minWidth: 'unset',
  borderRadius: '50%',
  width: 36,
  height: 36,
  zIndex: 1
};

const getScrollElementCurrent = scrollElement => {
  if (scrollElement && scrollElement.current) {
    if (typeof scrollElement.current === 'function') {
      return scrollElement.current();
    } else {
      return scrollElement.current;
    }
  }
  return null;
};

const ScrollTopButton = props => {
  const { className, style, scrollElement, ...rest } = props;

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = React.useState(0);

  const onClick = async () => {
    if (!scrolling) {
      const current = getScrollElementCurrent(scrollElement);
      if (current) {
        setScrolling(true);
        await new Promise(resolve => {
          scrollIt(current, -300, 10, reached => resolve(true));
        });
        setScrolling(false);
      }
    }
  };

  useEffect(() => {
    const listener = () => {
      const current = getScrollElementCurrent(scrollElement);
      if (current) {
        setScrollTop(current.scrollTop);
      }
    };

    const current = getScrollElementCurrent(scrollElement);
    if (current) {
      current.addEventListener('scroll', listener);
    }
    window.addEventListener('scroll', listener);

    return () => {
      const current = getScrollElementCurrent(scrollElement);
      if (current) {
        current.removeEventListener('scroll', listener);
      }
      window.removeEventListener('scroll', listener);
    };
  }, [scrollElement]);

  return scrollTop > 0 ? (
    <Button
      className={className}
      color="primary"
      variant="contained"
      onClick={onClick}
      style={{
        ...Style,
        ...style
      }}
      {...rest}
    >
      <KeyboardArrowUpIcon />
    </Button>
  ) : null;
};

export default ScrollTopButton;
