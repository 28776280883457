import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import { getPolicyValue, isAnnuityPolicy, isILASPolicy, isInsuredNotMePolicy } from '../../../utils/policy';
import { formatNumber } from '../../../utils/formatNumber';
import Typography from '@material-ui/core/Typography';
import { InsuredNotMeImage, PolicyReviewLackCoverIcon } from '../PolicyReview';
import Grid from '@material-ui/core/Grid';
import './PolicyCategoryTableSavingProtection.scss';

const TOTAL_COLUMN = 5;

const PolicyCategoryTableSavingProtection = props => {
  const { intl, currency, category, width, extraRows, selectTotalWithdrawals } = props;

  const subCategories = _.get(category, 'subCategories', []);
  const policies = _.flatMap(subCategories, subCategory => subCategory.policies);
  const currencyText = intl.formatMessage({ id: currency });

  let totalMarketValue = 0;

  return category ? (
    <TableContainer className={`policy-category-table saving-protection${policies.length === 0 ? ' no-policy' : ''}`}>
      <Table size="small" style={{ width: width || 615 }}>
        <TableHead>
          <TableRow className="sub-header">
            <TableCell align="center" colSpan={TOTAL_COLUMN}>
              <div>{intl.formatMessage({ id: 'pr-table-protection' })}</div>
            </TableCell>
          </TableRow>
          <TableRow className="main-row">
            <TableCell align="center" style={{ width: 125 }} rowSpan={2}>
              <div>
                <Typography color="textSecondary">{`${intl.formatMessage({
                  id: 'coverage-amount'
                })} (${currencyText})`}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" style={{ width: 70 }} rowSpan={2}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'ILAS' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" style={{ width: 70 }} rowSpan={2}>
              <div>
                <Typography color="textSecondary">{intl.formatMessage({ id: 'Annuity' })}</Typography>
              </div>
            </TableCell>
            <TableCell align="center" style={{ width: 125 }} rowSpan={2}>
              <div>
                <Typography color="textSecondary">{`${intl.formatMessage({
                  id: 'Market Value'
                })} (${currencyText})`}</Typography>
              </div>
            </TableCell>
            {selectTotalWithdrawals &&
              <TableCell align="center" style={{ width: 160 }} rowSpan={2}>
                <div>
                  <Typography color="textSecondary">{`${intl.formatMessage({
                    id: 'Total withdrawals'
                  })} (${currencyText})`}</Typography>
                </div>
              </TableCell>
            }
          </TableRow>
          <TableRow className="sub-row" />
        </TableHead>
        <TableBody>
          {policies.map((policy, policyIndex) => {
            const isDummy = !!policy.dummy;
            const compensation = getPolicyValue(policy, 'lifeCoverage', currency);
            const isILAS = isILASPolicy(policy);
            const isAnnuity = isAnnuityPolicy(policy);
            const marketValue = getPolicyValue(policy, 'marketValue', currency);
            const totalWidthDrawn = getPolicyValue(policy, 'totalWithdrawn', currency);
            const dummyProtectionLackEntry = isDummy && !isILAS && !isAnnuity;
            const isInsuredNotMe = isInsuredNotMePolicy(policy);

            totalMarketValue += marketValue;

            return (
              <TableRow
                key={policy._id}
                className={policyIndex === policies.length - 1 ? 'no-border-row' : undefined}
                style={policyIndex === policies.length - 1 ? { borderBottom: '3px solid #fbcfd0' } : undefined}
              >
                <TableCell align="center">
                  <Grid container alignItems="center">
                    <Fragment>
                      <Grid item>
                        {dummyProtectionLackEntry ? (
                          <PolicyReviewLackCoverIcon />
                        ) : compensation ? (
                          formatNumber(compensation, intl)
                        ) : (
                          ''
                        )}
                      </Grid>
                      {isInsuredNotMe && <InsuredNotMeImage />}
                    </Fragment>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <div>
                    {dummyProtectionLackEntry ? null : isILAS ? (
                      !isDummy ? (
                        <Typography>{intl.formatMessage({ id: 'pr-yes' })}</Typography>
                      ) : null
                    ) : isDummy ? (
                      <PolicyReviewLackCoverIcon />
                    ) : null}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>
                    {dummyProtectionLackEntry ? null : isAnnuity ? (
                      !isDummy ? (
                        <Typography>{intl.formatMessage({ id: 'pr-yes' })}</Typography>
                      ) : null
                    ) : isDummy ? (
                      <PolicyReviewLackCoverIcon />
                    ) : null}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div>{marketValue ? formatNumber(marketValue, intl) : ''}</div>
                </TableCell>
                {selectTotalWithdrawals && 
                  <TableCell align="center">
                    <div>{totalWidthDrawn ? formatNumber(totalWidthDrawn, intl) : ''}</div>
                  </TableCell>
                }
              </TableRow>
            );
          })}
          {extraRows && extraRows(TOTAL_COLUMN, totalMarketValue)}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default injectIntl(PolicyCategoryTableSavingProtection);
