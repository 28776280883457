import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  Input,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import AutoGenInput from '../../../components/AutoGenInput';
import { toast } from 'react-toastify';
import { LOADING } from '../../../constants/phase';
import { QR_CODE_TYPES } from '../../../constants/qrCode';
import QRCodeComponent from '../../../components/QRCodeComponent';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import { SlideTransition } from '../../../components/Transition/Transition';
import MoreInfoTrigger from '../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import IconToast from '../../../components/NewToast';
import { validateShareAccessCode, validateShareEmail } from '../../../utils/validators';
import ConnectWithAdviserDialog from './ConnectWithAdviserDialog/ConnectWithAdviserDialog';
import { getSearchParam } from '../../../utils/url';
import { tracking } from '../../../utils/tracking';
import NativeOrWeb from '../../../utils/native';
import { TopNavigationPortal } from '../../../components/topNavigation';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

const PFPLUS_URL = process.env.PFPLUS_URL;
const DEBUG = false;

class ShareOptionView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      confirmationModalIsOpen: false,
      senderName: '',
      email: '',
      contact: '',
      receiverType: '',
      id: '',
      resMessage: '',
      err: {},
      errMsg: false,
      confirmModal: false,
      shareModal: false,
      overwrite: false,
      registerModal: false,
      shareType: 'qrCode',
      showLimit: 0,
      connectWithAdviserDialog:
        getSearchParam(_.get(props, 'history.location.search', ''), 'connectWithAdviserDialog') || false
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeconfirmationModal = this.closeconfirmationModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.closeShareModal = this.closeShareModal.bind(this);
    this.updateShareDetail = this.updateShareDetail.bind(this);
    this.NoticeOfRegister = this.NoticeOfRegister.bind(this);
    this.closeRegisterModal = this.closeRegisterModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      const openAdviserDialog =
        getSearchParam(_.get(this.props, 'location.search', ''), 'connectWithAdviserDialog') || false;
      if (openAdviserDialog) {
        this.openConnectWithAdviserDialog();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.sharePhase === true && nextProps.adviserNoticeStatus === false) {
      this.setState({
        confirmModal: true,
        resMessage: nextProps.resMessage,
        email: '',
        senderName: '',
        modalIsOpen: false,
        toUserOptions: false
      });
      this.props.getShareToData();
    }

    if (nextProps.sharePhase === false && nextProps.registerKey === false) {
      //if(nextProps.resMessage.indexOf('@'))
      if (nextProps.resMessage.indexOf('@') === -1) {
        this.setState({
          errMsg: true,
          resMessage: intl.formatMessage({ id: nextProps.resMessage }),
          shareModal: true,
          overwrite: nextProps.overwrite
        });
      } else if (nextProps.resMessage.indexOf('@') !== -1) {
        let splitedMessage = nextProps.resMessage.split('with');
        this.setState({
          errMsg: true,
          resMessage: intl.formatMessage({ id: splitedMessage[0] }) + splitedMessage[1],
          shareModal: true,
          overwrite: nextProps.overwrite
        });
      }
    }
    if (nextProps.sharePhase === false && nextProps.registerKey === true) {
      this.setState({
        //errMsg: true,
        // resMessage: nextProps.resMessage,
        registerModal: nextProps.registerKey,
        shareModal: false,
        modalIsOpen: false
      });
    }

    if (nextProps.adviserNoticeStatus === true) {
      this.setState({
        resMessage: '',
        registerModal: false,
        shareModal: false,
        modalIsOpen: false,
        email: '',
        contact: ''
      });
      this.props.initAdviserStatusPhase();
    }
    // if (nextProps.location.state && nextProps.location.state.reset) {
    // 	this.setState({ toUserOptions: false });
    // }
    // if (nextProps.location.state) {
    // 	let sharingTitle = intl.formatMessage({
    // 		id: 'connect-wif-adv-title'
    // 	});
    // 	let consentMessage = intl.formatMessage({
    // 		id: 'connect-wif-adv-hint'
    // 	});
    // 	this.setState({
    // 		modalIsOpen: true,
    // 		receiverType: 'Advisor',
    // 		shareType: 'email',
    // 		consentMessage,
    // 		sharingTitle
    // 	});
    // }
  }

  handleChange(e) {
    let err = this.state.err;
    if (e.target.name === 'senderName') {
      err.senderName = '';
      this.setState({ err: err });
    }
    if (e.target.name === 'email') {
      err.email = '';
      this.setState({ err: err });
    }
    if (e.target.name === 'contact') {
      err.contact = '';
      this.setState({ err: err });
    }
    this.setState({ [e.target.name]: e.target.value, errMsg: false });
  }

  displayToUserOptions() {
    this.setState({ toUserOptions: true });
  }

  hideToUserOptions() {
    this.setState({ toUserOptions: false });
  }

  ShareConfirmationModal() {
    this.setState({ shareModal: true });
  }

  //To overwrite the  share detail
  updateShareDetail() {
    const { sharedUser } = this.props;
    var formData = {};
    formData.senderName = this.state.senderName;
    formData.email = this.state.email.toLowerCase();
    formData.mobileNumber = this.state.contact;
    formData.receiverType = this.state.receiverType;
    formData._id = sharedUser._id;
    this.props.updateShareDetails(formData);
    this.setState({ shareModal: false });
  }

  //Notice the advoser email if the email is not registered as a adviser
  NoticeOfRegister() {
    const { noticeAdviserToRegister } = this.props;
    var formData = {};
    formData.email = this.state.email.toLowerCase();
    formData.mobileNumber = this.state.contact;
    formData.receiverType = this.state.receiverType;
    noticeAdviserToRegister(formData);
    this.setState({ modalIsOpen: false, shareModal: false });
  }

  //Open the modal according to share type
  openModal(val) {
    const { intl, userDetails } = this.props;
    if (userDetails.userType === 'User' && val === 'Advisor') {
      // history.push({ pathname: '/connect_with_adviser', state: '/share_options' });
      this.setState({ connectWithAdviserDialog: true });
      return;
    }
    let consentMessage = '';
    //this.setState({resMessage:''})
    let sharingTitle = '';
    let shareType = 'qrCode';
    // if (val === 'Advisor') {
    //   this.setState({ connectWithAdviserDialog: true });
    //   return;
    //   // sharingTitle = intl.formatMessage({ id: 'connect-wif-adv-title' });
    //   // consentMessage = intl.formatMessage({ id: 'connect-wif-adv-hint' });
    //   // shareType = 'email';
    // }
    if (val === 'Family') {
      sharingTitle = intl.formatMessage({ id: 'Enter details for sharing:' });
      consentMessage = intl.formatMessage({
        id: 'Receiver can view and edit your portfolio. It can be disabled anytime'
      });
    }
    if (val === 'User') {
      sharingTitle = intl.formatMessage({ id: 'Enter details for sharing:' });
      consentMessage = intl.formatMessage({
        id: 'Receiver can preview your portfolio without viewing details. It can be disabled anytime'
      });
    }
    if (val === 'Emergency') {
      sharingTitle = intl.formatMessage({ id: 'Enter details for sharing:' });
      consentMessage = intl.formatMessage({
        id:
          'Receiver can view limited information of your portfolio for emergency reference purpose. The share can be disabled anytime'
      });
    }
    this.setState({
      modalIsOpen: true,
      receiverType: val,
      shareType,
      consentMessage,
      sharingTitle
    });
  }

  //Confirmation modal after share the records
  openconfirmationModal() {
    tracking('Share Option: Email Submit', { receiverType: this.state.receiverType });

    let user = this.props.userDetails;
    const err = {};
    if (this.state.receiverType === 'Family' && this.state.senderName === '') {
      err.senderName = 'Enter Your Name.';
    }

    const emailResult = validateShareEmail(this.state.email, user.email);
    if (emailResult) {
      err.email = emailResult;
    }
    const accessCodeResult = validateShareAccessCode(this.state.contact);
    if (accessCodeResult) {
      err.contact = accessCodeResult;
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      this.setState({ confirmationModalIsOpen: true, showLimit: 0 });
    }
  }

  closeModal() {
    const { cleanQRCode } = this.props;
    if (cleanQRCode !== undefined && typeof cleanQRCode === 'function') {
      cleanQRCode();
    }

    if (
      this.props.location.state &&
      this.props.location.state.clickAdviser &&
      this.props.location.state.clickAdviser === true
    ) {
      this.props.history.push({ pathname: this.props.location.state.pathname });
    } else {
      this.setState({
        modalIsOpen: false,
        errMsg: false,
        email: '',
        contact: '',
        senderName: '',
        err: {},
        showLimit: this.state.showLimit + 1
      });
    }
  }

  closeconfirmationModal() {
    this.setState({ confirmationModalIsOpen: false });
  }

  closeConfirmModal() {
    this.setState({ confirmModal: false, contact: '' });
  }

  closeShareModal() {
    this.props.initPhase();
    this.setState({ shareModal: false, modalIsOpen: false, overwrite: false });
  }

  closeRegisterModal() {
    this.props.initPhase();
    this.setState({
      registerModal: false,
      shareModal: false,
      modalIsOpen: false
    });
  }

  //To view the emergency export
  onClickExport() {
    this.props.history.push({
      pathname: '/export'
    });
  }

  //Submit the access of shared user
  submitForm() {
    const { userDetails } = this.props;
    var user = userDetails;
    var formData = {};
    formData.senderId = user._id;
    formData.senderName = this.state.senderName;
    formData.email = this.state.email.toLowerCase();
    formData.mobileNumber = this.state.contact;
    formData.receiverType = this.state.receiverType;
    if (this.state.receiverType === 'Family' && userDetails.advisorDetails.length > 0) {
      userDetails.advisorDetails.map(value => {
        if (value.connection && value.connectionVerified) {
          formData.sendersAdviserEmail = value.email;
        }
        return null;
      });
    }
    this.props.shareDocuments(formData);
    this.setState({ confirmationModalIsOpen: false });
  }

  handleClickGenerate = () => {
    const { receiverType, senderName } = this.state;

    tracking('Share Option: QR Code Generate', { receiverType: receiverType });

    const err = {};
    if (receiverType === 'Family' && senderName === '') {
      err.senderName = 'Enter Your Name.';
      this.setState({ err: err });
    } else {
      const { createQRCode } = this.props;

      if (createQRCode !== undefined && typeof createQRCode === 'function') {
        createQRCode(QR_CODE_TYPES.SHARE_TO_USER, {
          senderName: senderName,
          receiverType: receiverType
        });
      }
    }
  };

  toggleConnectWithAdviserDialog = open => this.setState({ connectWithAdviserDialog: open });
  openConnectWithAdviserDialog = () => this.toggleConnectWithAdviserDialog(true);

  render() {
    const { intl, qrCode, userDetails } = this.props;
    const { connectWithAdviserDialog } = this.state;
    return (
      <div>
        <ConnectWithAdviserDialog
          open={!!connectWithAdviserDialog}
          toggleDialog={this.toggleConnectWithAdviserDialog}
        />
        {this.props.sharePhase === 'loading' && <LoadingBackdrop open={true} />}
        {this.state.toUserOptions && (
          <TopNavigationPortal>
            <span className="back_arrow" onClick={this.hideToUserOptions.bind(this)}>
              <ArrowBackIcon />
            </span>
          </TopNavigationPortal>
        )}
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className=" addPolicyPadding policyPadding">
              <ul className="card-list card-list-share">
                {this.state.toUserOptions ? (
                  <div>
                    <li className="popup-listing">
                      <div className="portfolioCard" onClick={this.openModal.bind(this, 'Family')}>
                        <img src="img/family.png" alt="" />
                        <h4>{intl.formatMessage({ id: 'Family' })}</h4>
                      </div>
                      <MoreInfoTrigger titleIntlId="Family" contentIntlId="moreInfo_FamilyMoreInfoMessage" />
                    </li>
                    {/*<li className="popup-listing">*/}
                    {/*  <div className="portfolioCard" onClick={this.openModal.bind(this, 'User')}>*/}
                    {/*    <img src="img/person.png" alt="" />*/}
                    {/*    <h4>{intl.formatMessage({ id: 'Reference' })}</h4>*/}
                    {/*  </div>*/}
                    {/*  <MoreInfoTrigger titleIntlId="User" contentIntlId="moreInfo_ReferenceMoreInfoMessage" />*/}
                    {/*</li>*/}
                    {/*<li className="popup-listing">*/}
                    {/*  <div className="portfolioCard" onClick={this.openModal.bind(this, 'Emergency')}>*/}
                    {/*    <img src="img/emergency.png" alt="" />*/}
                    {/*    <h4>{intl.formatMessage({ id: 'Emergency' })}</h4>*/}
                    {/*  </div>*/}
                    {/*  <MoreInfoTrigger titleIntlId="Emergency" contentIntlId="moreInfo_EmergencyMoreInfoMessage" />*/}
                    {/*</li>*/}
                  </div>
                ) : (
                  <div>
                    <li onClick={this.displayToUserOptions.bind(this)}>
                      <div className="portfolioCard">
                        <img src="img/user.png" alt="" />
                        <h4>{intl.formatMessage({ id: 'To User' })}</h4>
                      </div>
                    </li>
                    <li onClick={this.openModal.bind(this, 'Advisor')}>
                      <div className="portfolioCard">
                        <img src="img/share_linkAdviser.png" alt="" />
                        <h4>{intl.formatMessage({ id: 'Connect Adviser' })}</h4>
                      </div>
                    </li>
                    <Link to="share">
                      <li>
                        <div className="portfolioCard">
                          <img src="img/record.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'Record' })}</h4>
                        </div>
                      </li>
                    </Link>
                    {/*Hide the export policy option from user or client login
                    not to show user*/}
                    {_.get(userDetails, 'userType') !== 'User' && (
                      <li className="popup-listing" onClick={this.onClickExport.bind(this)}>
                        <div className="portfolioCard">
                          <img src="img/export_user.png" alt="" />
                          <h4>{intl.formatMessage({ id: 'Export Portfolio' })}</h4>
                        </div>
                        <MoreInfoTrigger
                          titleIntlId="More Info"
                          contentIntlId="moreInfo_EmergencyExportMoreInfoMessage"
                        />
                      </li>
                    )}
                  </div>
                )}
                <div>
                  <Dialog
                    open={this.state.modalIsOpen}
                    onClose={this.closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{this.state.sharingTitle}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>{this.state.consentMessage}</DialogContentText>
                      {['Family', 'User', 'Emergency'].indexOf(this.state.receiverType) >= 0 && (
                        <FormControl className="share-type-control">
                          <RadioGroup
                            aria-label="shareType"
                            name="shareType"
                            value={this.state.shareType}
                            onChange={event => {
                              this.handleChange(event);
                            }}
                          >
                            <FormControlLabel
                              value={'qrCode'}
                              control={<Radio color="primary" />}
                              label={intl.formatMessage({ id: 'QR Code' })}
                            />
                            {/*<FormControlLabel*/}
                            {/*  value={'email'}*/}
                            {/*  control={<Radio color="primary" />}*/}
                            {/*  label={intl.formatMessage({ id: 'Email' })}*/}
                            {/*/>*/}
                          </RadioGroup>
                        </FormControl>
                      )}

                      {/* {this.state.shareType === 'email' && <div className="modal-text">{this.state.sharingTitle}</div>} */}

                      {this.state.receiverType === 'Family' && (
                        <FormControl className="share-to-modal-form-control">
                          <InputLabel htmlFor="senderName">
                            {intl.formatMessage({
                              id: 'Your Name to be Shown* (e.g. Wife)'
                            })}
                          </InputLabel>
                          <Input
                            className="share-to-modal-input"
                            id="senderName"
                            type="text"
                            name="senderName"
                            value={this.state.senderName}
                            onChange={this.handleChange.bind(this)}
                            required={true}
                          />

                          {this.state.err.senderName ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.senderName
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </FormControl>
                      )}

                      {this.state.shareType === 'email' ? (
                        <div>
                          <FormControl className="share-to-modal-form-control">
                            <InputLabel htmlFor="email">{intl.formatMessage({ id: "Receiver's Email:*" })}</InputLabel>
                            <Input
                              className="share-to-modal-input"
                              id="email"
                              type="text"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange.bind(this)}
                              required={true}
                            />

                            {this.state.err.email ? (
                              <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
                            ) : (
                              ''
                            )}
                          </FormControl>

                          <FormControl className="share-to-modal-form-control">
                            <AutoGenInput
                              label={intl.formatMessage({ id: 'Create an access code*' })}
                              className="share-to-modal-input"
                              name="contact"
                              value={this.state.contact}
                              onChange={this.handleChange.bind(this)}
                              required={true}
                              autoGenLabel={intl.formatMessage({ id: 'Generate a random password' })}
                            />

                            {this.state.err.contact ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.contact
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </FormControl>
                        </div>
                      ) : (
                        <div>
                          {qrCode !== undefined && qrCode.qrCodeId !== undefined && (
                            <div className="qr-code-container">
                              <QRCodeComponent content={PFPLUS_URL + '/qr_code/' + qrCode.qrCodeId} />
                              <div className="qr-hints">
                                {intl.formatMessage(
                                  { id: 'qr_code_valid_msg' },
                                  { validDuration: qrCode.validDuration }
                                )}
                              </div>
                              <div className="qr-hints">{intl.formatMessage({ id: 'qr_code_once_msg' })}</div>
                              {DEBUG === true && (
                                <div className="qr-hints">DEBUGGING: {PFPLUS_URL + '/qr_code/' + qrCode.qrCodeId}</div>
                              )}
                            </div>
                          )}
                          <div className="qr-hints text-left">
                            {intl.formatMessage({
                              id: 'qr_code_share_overwrite_msg'
                            })}
                          </div>
                        </div>
                      )}

                      {/*res message is insert in intl only use the intl here no need to define again. We don't use because of dynamic email */}
                      {this.state.errMsg && !this.state.overwrite && this.state.showLimit === 0 ? (
                        <span className="error_field">
                          {/* {intl.formatMessage({id:this.state.resMessage})} */}
                          {this.state.resMessage}
                          <br />
                          <br />
                        </span>
                      ) : (
                        ''
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeModal} color="primary">
                        {intl.formatMessage({ id: 'Close' })}
                      </Button>
                      {this.state.shareType === 'email' ? (
                        this.props.sharePhase === LOADING ? (
                          <Button color="primary">{intl.formatMessage({ id: 'Submitting' }) + '...'}</Button>
                        ) : (
                          <Button onClick={this.openconfirmationModal.bind(this)} color="primary">
                            {intl.formatMessage({ id: 'Submit' })}
                          </Button>
                        )
                      ) : this.props.qrCodeFetchPhase === LOADING ? (
                        <Button color="primary">{intl.formatMessage({ id: 'Submitting' }) + '...'}</Button>
                      ) : (
                        <Button onClick={this.handleClickGenerate} color="primary">
                          {intl.formatMessage({ id: 'Generate' })}
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={this.state.confirmationModalIsOpen}
                    onClose={this.closeconfirmationModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {intl.formatMessage({ id: 'Share Portfolio' })}
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        {intl.formatMessage({
                          id: 'Confirm to share your policy to'
                        })}{' '}
                        <b>{this.state.email}</b> ?
                        <div>
                          {intl.formatMessage({
                            id: '(Please double check the Email address)'
                          })}
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeconfirmationModal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.submitForm} color="primary" autoFocus>
                        {intl.formatMessage({ id: 'Yes' })}
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={this.state.confirmModal}
                    onClose={this.closeConfirmModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {intl.formatMessage({ id: 'Share Portfolio' })}
                    </DialogTitle>
                    <DialogContent>
                      {this.state.resMessage === 'Email is sent.'
                        ? intl.formatMessage({ id: 'EmailSent' })
                        : this.state.resMessage
                        ? this.state.resMessage
                        : this.state.resMessage}
                      {this.state.resMessage === 'Email is sent.' &&
                        intl.formatMessage({
                          id: 'Please tell receiver the access code:'
                        })}
                      {this.state.resMessage === 'Email is sent.' && <b> {this.state.contact} </b>}
                      {this.state.resMessage === 'Email is sent.' &&
                        intl.formatMessage({
                          id: 'separately for verifying and access the share content.'
                        })}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeConfirmModal} color="primary">
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                      {this.state.resMessage === 'Email is sent.' && (
                        <Button
                          onClick={() => {
                            NativeOrWeb.copyToClipboard(this.state.contact).then(() => {
                              toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
                                className: 'new-toast'
                              });
                            });
                          }}
                          color="primary"
                          autoFocus
                        >
                          {intl.formatMessage({ id: 'Copy Code' })}
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                  {this.state.overwrite && (
                    <Dialog
                      open={this.state.shareModal}
                      onClose={this.closeShareModal}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">{intl.formatMessage({ id: 'Share Portfolio' })}</DialogTitle>
                      <DialogContent>{this.state.resMessage}</DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeShareModal} color="primary">
                          {intl.formatMessage({ id: 'Cancel' })}
                        </Button>
                        <Button onClick={this.updateShareDetail} color="primary" autoFocus>
                          {intl.formatMessage({ id: 'Overwrite' })}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  <Dialog
                    open={this.state.registerModal}
                    onClose={this.closeRegisterModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {intl.formatMessage({ id: 'Share Portfolio' })}
                    </DialogTitle>
                    <DialogContent>
                      {this.state.registerModal ? intl.formatMessage({ id: 'Adviser_Register_message' }) : ''}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeRegisterModal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.NoticeOfRegister} color="primary" autoFocus>
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShareOptionView;
