import React, { Fragment } from 'react';
import { Card, CardActions, CardContent, CardHeader, Button } from '@material-ui/core';
import './PricingCard.scss';
import { getFormattedInterval, getPriceAmountDisplay, translationKeyMap } from '../../../../utils/user-subscription';
import _ from 'lodash';

export default class PricingCard extends React.PureComponent {
  render() {
    const {
      intl,
      header,
      plan,
      onClick,
      content,
      buttonText,
      hideAmount,
      customAmount,
      extras,
      ...rest
    } = this.props;

    const formattedPlan = { ...plan };

    if (formattedPlan.isTrial) {
      formattedPlan.interval = formattedPlan.trialInterval;
      formattedPlan.intervalCount = formattedPlan.trialIntervalCount;
    }

    const { interval, intervalCount } = getFormattedInterval({
      interval: formattedPlan.interval,
      intervalCount: formattedPlan.intervalCount
    });

    const planColor = _.get(formattedPlan, 'color', '#3966f8');
    const cardBorder = `1px solid ${planColor}`;

    const discountedAmount = formattedPlan.amount;
    const withCouponAmount = formattedPlan.couponValue
      ? discountedAmount - formattedPlan.couponValue
      : discountedAmount;

    return (
      <Card className={`subscription-plan-card ${buttonText ? 'actionable' : ''}`} {...rest}>
        {extras && extras}
        {formattedPlan.hotPick && (
          <div className="hot-pick">
            {intl.formatMessage(
              { id: 'hot-pick' },
              {
                nickName: formattedPlan.displayName
                  ? formattedPlan.displayName[translationKeyMap[intl.locale]]
                  : formattedPlan.nickname
              }
            )}
          </div>
        )}

        <CardHeader
          className="card-header"
          style={{ backgroundColor: planColor }}
          title={
            <div
              className="card-header-inner"
              style={{
                borderLeft: cardBorder,
                borderRight: cardBorder
              }}
            >
              {header ? (
                header
              ) : (
                <Fragment>
                  <div
                    className="plan-title"
                    style={{
                      visibility: formattedPlan.hotPick ? 'hidden' : undefined,
                      fontSize: hideAmount ? '200%' : undefined
                    }}
                  >
                    {formattedPlan.displayName
                      ? formattedPlan.displayName[translationKeyMap[intl.locale]]
                      : formattedPlan.nickname}
                  </div>
                  {!customAmount ? (
                    <Fragment>
                      {!hideAmount && (
                        <Fragment>
                          {!formattedPlan.isTrial && withCouponAmount !== discountedAmount && (
                            <div className="sub-price">${getPriceAmountDisplay(discountedAmount)}</div>
                          )}
                          <div className="price">
                            $
                            {getPriceAmountDisplay(
                              !formattedPlan.isTrial ? withCouponAmount : formattedPlan.trialAmount
                            )}
                          </div>
                        </Fragment>
                      )}
                      <div className="interval">
                        {formattedPlan.isFreeTrial
                          ? intl.formatMessage({ id: `free-trial-${interval}-interval` }, { count: intervalCount })
                          : formattedPlan.isTrial
                          ? intl.formatMessage(
                              { id: `trial-${interval}-interval` },
                              { count: intervalCount > 1 ? intervalCount : '' }
                            )
                          : formattedPlan.interval === 'year'
                          ? intl.formatMessage({ id: 'year-interval' })
                          : formattedPlan.interval === 'month' && formattedPlan.intervalCount === 3
                          ? intl.formatMessage({ id: 'quarter-interval' })
                          : formattedPlan.interval === 'day'
                          ? intl.formatMessage({ id: 'day-w-count' }, { count: intervalCount })
                          : intl.formatMessage(
                              { id: 'sub-per' },
                              {
                                intervalText: `${intervalCount > 1 ? `${intervalCount}-` : ''}${intl.formatMessage({
                                  id: interval
                                })}`
                              }
                            )}
                      </div>
                    </Fragment>
                  ) : (
                    customAmount
                  )}
                </Fragment>
              )}
            </div>
          }
        />
        <CardContent
          className="card-content"
          style={{
            borderLeft: cardBorder,
            borderRight: cardBorder,
            borderBottom: !buttonText ? cardBorder : undefined
          }}
        >
          {content ? (
            content
          ) : formattedPlan.description ? (
            <div>
              <div>
                {formattedPlan.description[translationKeyMap[intl.locale]].split('\n').map((line, i) => (
                  <div key={i}>{line}</div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </CardContent>

        {buttonText && (
          <CardActions
            className="card-actions"
            style={{
              borderLeft: cardBorder,
              borderRight: cardBorder,
              borderBottom: cardBorder
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              onClick={onClick}
              style={{ backgroundColor: formattedPlan.color ? formattedPlan.color : '#3966f8' }}
            >
              {buttonText}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  }
}
