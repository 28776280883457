import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../store/control/duck';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';

const DialogStyle = {
  zIndex: 10000
};

const NativeNetworkOfflineDialog = props => {
  const { intl, nativeNetworkOfflineDialog, toggleDialog } = props;

  const { closed } = nativeNetworkOfflineDialog || {};

  const onExited = () => toggleDialog('nativeNetworkOffline', false);
  const close = () => toggleDialog('nativeNetworkOffline', { ...nativeNetworkOfflineDialog, closed: true });

  return (
    <Dialog
      open={!!nativeNetworkOfflineDialog && !closed}
      TransitionProps={{
        onExited: onExited
      }}
      TransitionComponent={SlideTransition}
      fullWidth={true}
      style={DialogStyle}
    >
      <DialogTitle>{intl.formatMessage({ id: 'native-network-offline-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'native-network-offline-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    nativeNetworkOfflineDialog: state.control.nativeNetworkOfflineDialog
  }),
  {
    toggleDialog
  }
)(NativeNetworkOfflineDialog);

export default injectIntl(container);
