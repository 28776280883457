import React, { useState, useEffect, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Card, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import './AdviserAccountDetailsCard.scss';
import AdviserInfo from '../../Export/AdviserInfo/AdviserInfo';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { getTeamMemberPermission, getTeams } from '../../../store/team/duck';
import EditIcon from '@material-ui/icons/Edit';
import { INIT, LOADING, SUCCESS } from '../../../constants/phase';
import TeamSettingDialog from '../TeamSettingDialog/TeamSettingDialog';
import { toggleDialog } from '../../../store/control/duck';
import { getUserLogo, hasCampaignCode } from '../../../utils/user';
import PhotoCameraRoundedIcon from '@material-ui/icons/PhotoCameraRounded';
import UploadMyLogoButton from './UploadMyLogoButton/UploadMyLogoButton';
import { PURCHASE_LOGO_URL } from '../../../constants/url';
import { ADVISER_LOGO_FUNCTIONS } from '../../../constants/user';

const CardStyle = {
  padding: 16,
  boxShadow: 'var(--my-shadow)'
};

const TitleItemStyle = {
  width: '100%'
};

const TitleStyle = {
  fontSize: '125%',
  fontWeight: 'bold'
};

const ManageButtonStyle = {
  padding: 0
};

const FullWidthStyle = {
  width: '100%'
};

const AdviserInfoContainerStyle = {
  ...FullWidthStyle
};

const InlineImageStyle = {
  height: 'inherit',
  verticalAlign: 'unset'
};

const TeamNameLabelTypoContainerStyle = {
  marginRight: 3
};

const TeamNameTypoContainerStyle = {
  marginRight: 6
};

const EditIconButtonStyle = {
  padding: 3
};

const LogoTextContainerStyle = {
  marginRight: 6
};

const LogoPurchaseAStyle = {
  textDecoration: 'underline'
};

const LogoContainerStyle = {
  height: 88
};

const ZoomQRButtonContainerStyle = {
  width: '100%',
  position: 'relative'
};

const ZoomQRButtonStyle = {
  position: 'absolute',
  top: 0,
  right: 3,
  padding: 0,
  fontSize: 11,
  minWidth: 45
};

const AdviserAccountDetailsCard = props => {
  const {
    intl,
    history,
    userDetails,
    teamMemberPermissionPhase,
    teamMemberPermissionData,
    teams,
    teamCampaignCodes,
    getTeamsPhase,
    getTeamMemberPermission,
    getTeams,
    toggleDialog
  } = props;

  const [teamSettingDialog, setTeamSettingDialog] = useState(false);

  const company = _.get(userDetails, 'company', '');
  const teamLogoTeam =
    getTeamsPhase === SUCCESS
      ? teams.find(team => !!team.members.find(member => !!_.get(member, 'permission.isUseTeamLogo')))
      : undefined;
  const teamName = _.get(teamLogoTeam, 'teamName', '');
  const teamLogoUrl = _.get(teamLogoTeam, 'teamLogoUrl', '');
  const userLogo = getUserLogo(userDetails, teamLogoUrl, ADVISER_LOGO_FUNCTIONS.ACCOUNT);

  const notEnteredIntl = intl.formatMessage({ id: 'not-entered' });
  const { phoneNumber, phoneNumber2, whatsapp, wechatId, lineId, workEmail } = userDetails || {};
  const formattedUserDetails = {
    ...userDetails,
    phoneNumber: !phoneNumber && !phoneNumber2 ? notEnteredIntl : phoneNumber,
    whatsapp: whatsapp || notEnteredIntl,
    wechatId: wechatId || notEnteredIntl,
    lineId: lineId || notEnteredIntl,
    workEmail: workEmail || notEnteredIntl
  };
  const canEditMyLogo = hasCampaignCode(userDetails, teamCampaignCodes, 'FEATURE_SELFLOGO');

  const manage = () => history.push('/my-details');
  const zoomQR = () => toggleDialog('myQR', {});
  const editTeamSettings = () => setTeamSettingDialog(true);
  const closeTeamSettingDialog = () => setTeamSettingDialog(false);

  useEffect(() => {
    if (teamMemberPermissionPhase === INIT) {
      getTeamMemberPermission();
    }
  }, [teamMemberPermissionPhase, getTeamMemberPermission]);

  useEffect(() => {
    if (getTeamsPhase === INIT) {
      getTeams();
    }
  }, [getTeamsPhase, getTeams]);

  return (
    <Fragment>
      <Card className="adviser-account-details-card" style={CardStyle}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item style={TitleItemStyle}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography style={TitleStyle}>{intl.formatMessage({ id: 'Profile' })}</Typography>
              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" style={ManageButtonStyle} onClick={manage}>
                  {intl.formatMessage({ id: 'manage-profile' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={AdviserInfoContainerStyle}>
            <AdviserInfo
              show={true}
              showQRCode={false}
              adviser={formattedUserDetails}
              hideTitle={true}
              hideName={true}
              hideAvatar={true}
              // QRActionButton={
              //   <div style={ZoomQRButtonContainerStyle}>
              //     <Button color="primary" variant="outlined" onClick={zoomQR} style={ZoomQRButtonStyle}>
              //       {intl.formatMessage({ id: 'my-qr' })}
              //     </Button>
              //   </div>
              // }
            />
          </Grid>
          <Grid item style={FullWidthStyle}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography>{`${intl.formatMessage({ id: 'Company' })}: ${company}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item style={TeamNameLabelTypoContainerStyle}>
                        <Typography>{`${intl.formatMessage({ id: 'Team' })}:`}</Typography>
                      </Grid>
                      <Grid item style={TeamNameTypoContainerStyle}>
                        {getTeamsPhase === LOADING ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Typography>{teamName}</Typography>
                        )}
                      </Grid>
                      {(teamMemberPermissionData || []).length > 0 && (
                        <Grid item>
                          <IconButton color="primary" onClick={editTeamSettings} style={EditIconButtonStyle}>
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item style={{ width: '100%' }}>
                    <Grid container justify="space-between" alignItems="flex-start" spacing={1}>
                      <Grid item style={LogoTextContainerStyle}>
                        <Typography>{`${intl.formatMessage({ id: 'team-logo' })}:`}</Typography>
                      </Grid>
                      {canEditMyLogo && (
                        <Grid item>
                          <UploadMyLogoButton />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    {getTeamsPhase === LOADING ? (
                      <Grid item style={LogoContainerStyle}>
                        <CircularProgress size={20} />
                      </Grid>
                    ) : userLogo ? (
                      <Grid item style={LogoContainerStyle}>
                        <img alt="rank" src={userLogo} style={InlineImageStyle} />
                      </Grid>
                    ) : (
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography>{intl.formatMessage({ id: 'no-logo' })}</Typography>
                          </Grid>
                          <Grid item>
                            <a
                              href={PURCHASE_LOGO_URL}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={LogoPurchaseAStyle}
                            >
                              <Typography>{intl.formatMessage({ id: 'add-purchase' })}</Typography>
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <TeamSettingDialog open={teamSettingDialog} onClose={closeTeamSettingDialog} />
    </Fragment>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    teamMemberPermissionPhase: state.team.teamMemberPermissionPhase,
    teamMemberPermissionData: state.team.teamMemberPermissionData,
    teams: state.team.teams,
    getTeamsPhase: state.team.getTeamsPhase,
    teamCampaignCodes: state.functions.campaignCodes
  }),
  {
    getTeamMemberPermission,
    getTeams,
    toggleDialog
  }
)(AdviserAccountDetailsCard);

export default injectIntl(withRouter(container));
