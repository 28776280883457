import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
import NativeOrWeb from '../../utils/native';

/***********************************
 * Action Types
 ***********/

export const GET_NOTIFICATIONCENTER_LIST = 'portfoplus/notificationCenter/GET_NOTIFICATIONCENTER_LIST';
export const GET_NOTIFICATIONCENTER_LIST_SUCCESS = 'portfoplus/notificationCenter/GET_NOTIFICATIONCENTER_LIST_SUCCESS';
export const GET_NOTIFICATIONCENTER_LIST_ERROR = 'portfoplus/notificationCenter/GET_NOTIFICATIONCENTER_LIST_ERROR';

export const HANDLE_HASNOTIFICATION = 'portfoplus/notificationCenter/HANDLE_HASNOTIFICATION';

export const MARKALLNOTIFICATIONASREAD = 'portfoplus/notificationCenter/MARKALLNOTIFICATIONASREAD';
export const MARKALLNOTIFICATIONASREAD_SUCCESS = 'portfoplus/notificationCenter/MARKALLNOTIFICATIONASREAD_SUCCESS';
export const MARKALLNOTIFICATIONASREAD_ERROR = 'portfoplus/notificationCenter/MARKALLNOTIFICATIONASREAD_ERROR';

export const MARKNOTIFICATIONASREAD = 'portfoplus/notificationCenter/MARKNOTIFICATIONASREAD';
export const MARKNOTIFICATIONASREAD_SUCCESS = 'portfoplus/notificationCenter/MARKNOTIFICATIONASREAD_SUCCESS';
export const MARKNOTIFICATIONASREAD_ERROR = 'portfoplus/notificationCenter/MARKNOTIFICATIONASREAD_ERROR';

export const CREATENOTIFICATION = 'portfoplus/notificationCenter/CREATENOTIFICATION';
export const CREATENOTIFICATION_SUCCESS = 'portfoplus/notificationCenter/CREATENOTIFICATION_SUCCESS';
export const CREATENOTIFICATION_ERROR = 'portfoplus/notificationCenter/CREATENOTIFICATION_ERROR';

export const GET_ADVISERBLAST_NOTIFICATION = 'portfoplus/notificationCenter/GET_ADVISERBLAST_NOTIFICATION';
export const GET_ADVISERBLAST_NOTIFICATION_SUCCESS =
  'portfoplus/notificationCenter/GET_ADVISERBLAST_NOTIFICATION_SUCCESS';
export const GET_ADVISERBLAST_NOTIFICATION_ERROR = 'portfoplus/notificationCenter/GET_ADVISERBLAST_NOTIFICATION_ERROR';

export const UPDATE_ADVISERBLAST_NOTIFICATION = 'portfoplus/notificationCenter/UPDATE_ADVISERBLAST_NOTIFICATION';
export const UPDATE_ADVISERBLAST_NOTIFICATION_SUCCESS =
  'portfoplus/notificationCenter/UPDATE_ADVISERBLAST_NOTIFICATION_SUCCESS';
export const UPDATE_ADVISERBLAST_NOTIFICATION_ERROR =
  'portfoplus/notificationCenter/UPDATE_ADVISERBLAST_NOTIFICATION_ERROR';

export const GET_TEAMBLAST_NOTIFICATION = 'portfoplus/notificationCenter/GET_TEAMBLAST_NOTIFICATION';
export const GET_TEAMBLAST_NOTIFICATION_SUCCESS = 'portfoplus/notificationCenter/GET_TEAMBLAST_NOTIFICATION_SUCCESS';
export const GET_TEAMBLAST_NOTIFICATION_ERROR = 'portfoplus/notificationCenter/GET_TEAMBLAST_NOTIFICATION_ERROR';

export const UPDATE_TEAMBLAST_NOTIFICATION = 'portfoplus/notificationCenter/UPDATE_TEAMBLAST_NOTIFICATION';
export const UPDATE_TEAMBLAST_NOTIFICATION_SUCCESS =
  'portfoplus/notificationCenter/UPDATE_TEAMBLAST_NOTIFICATION_SUCCESS';
export const UPDATE_TEAMBLAST_NOTIFICATION_ERROR = 'portfoplus/notificationCenter/UPDATE_TEAMBLAST_NOTIFICATION_ERROR';

export const SET_SHOWED_TEAMBLAST = 'portfoplus/notificationCenter/SET_SHOWED_TEAMBLAST';

export const CLOSE_SEND_SUCCESS_DIALOG = 'portfoplus/notificationCenter/CLOSE_SEND_SUCCESS_DIALOG';

export const CLEAR_ALL_MSGOPT = 'portfoplus/notificationCenter/CLEAR_ALL_MSGOPT';
export const CLEAR_ALL_MSGOPT_SUCCESS = 'portfoplus/notificationCenter/CLEAR_ALL_MSGOPT_SUCCESS';
export const CLEAR_ALL_MSGOPT_ERROR = 'portfoplus/notificationCenter/CLEAR_ALL_MSGOPT_ERROR';

export const ADVISER_ALL_MODAL_DONE = 'portfoplus/notificationCenter/ADVISER_ALL_MODAL_DONE';
export const CLIENT_ALL_MODAL_DONE = 'portfoplus/notificationCenter/CLIENT_ALL_MODAL_DONE';
/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated

  getNotificationCenterPhase: INIT,
  getAdviserBlastPhase: INIT,
  updateAdviserBlastPhase: INIT,
  getTeamBlastPhase: INIT,
  updateTeamBlastPhase: INIT,
  clearAllMsgOptPhase: INIT,
  notificationCenterData: undefined,
  hasNotifications: false,
  newNotificationNumber: 0,
  showToast: [],
  createNotificationPhase: INIT,
  adviserBlast: undefined,
  teamBlast: undefined,
  clientsToSendNum: 0,
  showSendSuccessDialog: false,
  hasAdviserBlast: false,
  adviserBlastPop: undefined,
  hasTeamBlast: false,
  teamBlastPop: undefined,
  clearAllThenFetchClient: false,
  disableTodoPhase: INIT,
  adviserAllModalDone: false,
  clientAllModalDone: false
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_NOTIFICATIONCENTER_LIST: {
      return state.set('getNotificationCenterPhase', LOADING);
    }

    case GET_NOTIFICATIONCENTER_LIST_SUCCESS: {
      const { payload } = action;

      NativeOrWeb.setBadge(payload.newNotificationNumber)
        .then(console.log)
        .catch(console.log);

      return state
        .set('notificationCenterData', payload.data)
        .set('getNotificationCenterPhase', payload.success)
        .set('hasNotifications', payload.hasNotification)
        .set('newNotificationNumber', payload.newNotificationNumber)
        .set('showToast', payload.showToast)
        .set('adviserBlastPop', payload.adviserBlastPop)
        .set('hasAdviserBlast', payload.hasAdviserBlast)
        .set('teamBlastPop', payload.teamBlastPop)
        .set('hasTeamBlast', payload.hasTeamBlast);
    }

    case GET_ADVISERBLAST_NOTIFICATION: {
      return state.set('getAdviserBlastPhase', LOADING);
    }

    case UPDATE_ADVISERBLAST_NOTIFICATION: {
      const { payload } = action;
      let success = payload.value === 'enable' ? true : false;
      return state
        .set('updateAdviserBlastPhase', LOADING)
        .set('showSendSuccessDialog', success)
        .set('disableTodoPhase', LOADING);
    }

    case GET_TEAMBLAST_NOTIFICATION: {
      return state.set('getTeamBlastPhase', LOADING);
    }

    case UPDATE_TEAMBLAST_NOTIFICATION: {
      const { payload } = action;
      let success = payload.value === 'enable' ? true : false;
      return state
        .set('updateTeamBlastPhase', LOADING)
        .set('showSendSuccessDialog', success)
        .set('disableTodoPhase', LOADING);
    }

    case UPDATE_ADVISERBLAST_NOTIFICATION_SUCCESS: {
      const { payload } = action;
      return state.set('updateAdviserBlastPhase', payload.success);
    }

    case GET_ADVISERBLAST_NOTIFICATION_SUCCESS: {
      const { payload } = action;
      return state
        .set('getAdviserBlastPhase', payload.success)
        .set('disableTodoPhase', payload.success)
        .set('adviserBlast', payload.data)
        .set('clientsToSendNum', payload.clientsToSendNum);
    }

    case UPDATE_TEAMBLAST_NOTIFICATION_SUCCESS: {
      const { payload } = action;
      return state.set('updateTeamBlastPhase', payload.success);
    }

    case GET_TEAMBLAST_NOTIFICATION_SUCCESS: {
      const { payload } = action;
      return state
        .set('getTeamBlastPhase', payload.success)
        .set('disableTodoPhase', payload.success)
        .set('teamBlast', payload.data);
    }

    case GET_NOTIFICATIONCENTER_LIST_ERROR: {
      const { error } = action;
      return state.set('getNotificationCenterPhase', error);
    }

    case CREATENOTIFICATION_SUCCESS: {
      const { payload } = action;
      return state.set('createNotificationPhase', payload.success);
    }

    case CLOSE_SEND_SUCCESS_DIALOG: {
      return state.set('showSendSuccessDialog', false);
    }

    case CLEAR_ALL_MSGOPT: {
      return state.set('clearAllMsgOptPhase', INIT);
    }

    case CLEAR_ALL_MSGOPT_SUCCESS: {
      return state.set('clearAllMsgOptPhase', true).set('clearAllThenFetchClient', true);
    }

    case ADVISER_ALL_MODAL_DONE: {
      return state.set('adviserAllModalDone', true);
    }

    case CLIENT_ALL_MODAL_DONE: {
      return state.set('clientAllModalDone', true);
    }

    case SET_SHOWED_TEAMBLAST: {
      const newTeamBlastPop = [...state.teamBlastPop];
      const index = newTeamBlastPop.findIndex(e => e._id === action.payload);
      newTeamBlastPop.splice(index, 1);
      return state.set('teamBlastPop', newTeamBlastPop);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getNotificationCenterList = () => ({
  type: GET_NOTIFICATIONCENTER_LIST
});

export const getAdviserBlastNotification = () => ({
  type: GET_ADVISERBLAST_NOTIFICATION
});

export const updateAdviserBlastNotification = data => ({
  type: UPDATE_ADVISERBLAST_NOTIFICATION,
  payload: data
});

export const getTeamBlastNotification = () => ({
  type: GET_TEAMBLAST_NOTIFICATION
});

export const updateTeamBlastNotification = data => ({
  type: UPDATE_TEAMBLAST_NOTIFICATION,
  payload: data
});
export const markAllNotificationAsRead = () => ({
  type: MARKALLNOTIFICATIONASREAD
});

export const markNotificationAsRead = id => ({
  type: MARKNOTIFICATIONASREAD,
  payload: id
});

export const createNotification = data => ({
  type: CREATENOTIFICATION,
  payload: data
});

export const closeSendSuccessDialog = () => ({
  type: CLOSE_SEND_SUCCESS_DIALOG
});

export const clearAllMsgOpt = () => ({
  type: CLEAR_ALL_MSGOPT
});

export const adviserAllModalDone = () => ({
  type: ADVISER_ALL_MODAL_DONE
});

export const clientAllModalDone = () => ({
  type: CLIENT_ALL_MODAL_DONE
});

export const setShowedTeamBlast = data => ({
  type: SET_SHOWED_TEAMBLAST,
  payload: data
});

/***********************************
 * Epics
 ***********/

const getNotificationCenterListEpic = action$ =>
  action$.ofType(GET_NOTIFICATIONCENTER_LIST).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getNotificationList())
      .map(payload => ({
        type: GET_NOTIFICATIONCENTER_LIST_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_NOTIFICATIONCENTER_LIST_ERROR,
          payload: { error }
        })
      );
  });

const markAllNotificationAsReadEpic = action$ =>
  action$.ofType(MARKALLNOTIFICATIONASREAD).mergeMap(action => {
    return Rx.Observable.fromPromise(api.markAllNotificationAsRead())
      .flatMap(payload => [
        {
          type: MARKALLNOTIFICATIONASREAD_SUCCESS,
          payload
        },
        {
          type: GET_NOTIFICATIONCENTER_LIST
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: MARKALLNOTIFICATIONASREAD_ERROR,
          payload: { error }
        })
      );
  });

const markNotificationAsReadEpic = action$ =>
  action$.ofType(MARKNOTIFICATIONASREAD).mergeMap(action => {
    return Rx.Observable.fromPromise(api.markNotificationAsRead(action.payload))
      .flatMap(payload => [
        {
          type: MARKNOTIFICATIONASREAD_SUCCESS,
          payload
        },
        {
          type: GET_NOTIFICATIONCENTER_LIST
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: MARKNOTIFICATIONASREAD_ERROR,
          payload: { error }
        })
      );
  });

const createNotificationEpic = action$ =>
  action$.ofType(CREATENOTIFICATION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.createNotification(action.payload))
      .flatMap(payload => [
        {
          type: CREATENOTIFICATION_SUCCESS,
          payload
        },
        {
          type: GET_NOTIFICATIONCENTER_LIST
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: CREATENOTIFICATION_ERROR,
          payload: { error }
        })
      );
  });

const getAdviserBlastNotificationEpic = action$ =>
  action$.ofType(GET_ADVISERBLAST_NOTIFICATION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getAdviserBlastNotification())
      .map(payload => ({
        type: GET_ADVISERBLAST_NOTIFICATION_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_ADVISERBLAST_NOTIFICATION_ERROR,
          payload: { error }
        })
      );
  });

const updateAdviserBlastNotificationEpic = action$ =>
  action$.ofType(UPDATE_ADVISERBLAST_NOTIFICATION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateAdviserBlastNotification(action.payload))
      .flatMap(payload => [
        {
          type: UPDATE_ADVISERBLAST_NOTIFICATION_SUCCESS,
          payload
        },
        {
          type: GET_ADVISERBLAST_NOTIFICATION
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_ADVISERBLAST_NOTIFICATION_ERROR,
          payload: { error }
        })
      );
  });

const getTeamBlastNotificationEpic = action$ =>
  action$.ofType(GET_TEAMBLAST_NOTIFICATION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getTeamBlastNotification())
      .map(payload => ({
        type: GET_TEAMBLAST_NOTIFICATION_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_TEAMBLAST_NOTIFICATION_ERROR,
          payload: { error }
        })
      );
  });

const updateTeamBlastNotificationEpic = action$ =>
  action$.ofType(UPDATE_TEAMBLAST_NOTIFICATION).mergeMap(action => {
    return Rx.Observable.fromPromise(api.updateTeamBlastNotification(action.payload))
      .flatMap(payload => [
        {
          type: UPDATE_TEAMBLAST_NOTIFICATION_SUCCESS,
          payload
        },
        {
          type: GET_TEAMBLAST_NOTIFICATION
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: UPDATE_TEAMBLAST_NOTIFICATION_ERROR,
          payload: { error }
        })
      );
  });

const clearAllMsgOptEpic = action$ =>
  action$.ofType(CLEAR_ALL_MSGOPT).mergeMap(action => {
    return Rx.Observable.fromPromise(api.clearAllMsgOpt())
      .flatMap(payload => [
        {
          type: CLEAR_ALL_MSGOPT_SUCCESS,
          payload
        }
      ])
      .catch(error =>
        Rx.Observable.of({
          type: CLEAR_ALL_MSGOPT_ERROR,
          payload: { error }
        })
      );
  });

export const notificationCenterEpic = combineEpics(
  getNotificationCenterListEpic,
  markAllNotificationAsReadEpic,
  markNotificationAsReadEpic,
  createNotificationEpic,
  getAdviserBlastNotificationEpic,
  updateAdviserBlastNotificationEpic,
  getTeamBlastNotificationEpic,
  updateTeamBlastNotificationEpic,
  clearAllMsgOptEpic
);
