import { fetch } from '../../utils';
import Backend from '../../backend';

export const getPolicyReview = async clientId => {
  return fetch(Backend.methodUrl(`policy-reviews/${clientId}`))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const updatePolicyReview = async (clientId, policyReview) => {
  return fetch(Backend.methodUrl(`policy-reviews/${clientId}`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(policyReview)
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
