import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { Button } from '@material-ui/core';
import { shareWithAdviser } from '../../../../store/share/api';
import Typography from '@material-ui/core/Typography';
import ShareButtonGroup from '../../../../components/ShareButtonGroup/ShareButtonGroup';
import { tryTranslate } from '../../../../utils/locale';
import './ConnectWithAdviserDialog.scss';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import QRCodeComponent from '../../../../components/QRCodeComponent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { sanitize } from 'dompurify';

const APP_URL = process.env.PFPLUS_URL;

const ConnectWithAdviserDialog = props => {
  const [share, setShare] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [showQRCode, setShowQRCode] = useState(false);
  const userDetails = useSelector(state => state.user.userDetails);
  const userName = _.get(userDetails, 'name');
  const userLanguage = _.get(userDetails, 'language');

  const { intl, toggleDialog, onSubmit, ...rest } = props;
  const closeDialog = () => toggleDialog(false);
  const submit = async () => {
    // Tracking
    // tracking('User Function Accessed: Create Adviser invitation link');
    const result = await shareWithAdviser();
    if (!result.error && result.share) {
      setShare(result.share);
      setError(undefined);
    } else {
      setShare(undefined);
      setError(tryTranslate(intl, result.error) || 'Unexpected Error Occurred');
    }
  };

  const shareUrl = share
    ? encodeURI(`${APP_URL}/share-a/${share._id}?lang=${userLanguage}${userName ? `&name=${userName}` : ''}`)
    : '';
  const shareExpiryDate = _.get(share, 'expiryDate');
  const shareExpiryDateMoment = shareExpiryDate ? moment(shareExpiryDate) : moment().add(8, 'days');
  const expiryText =
    share && shareExpiryDateMoment
      ? intl.formatMessage(
          { id: 'connect-wif-adv-hint-3' },
          // The expiryDate saved is 8 days after but we want eliminate the trouble in handling problems of timezone & excessive information,
          // so we display the expiry date one day earlier than the exact time without time information
          { datetime: shareExpiryDateMoment.subtract(1, 'days').format('DD-MMM-YYYY') }
        )
      : '';

  const switchShowQRCode = event => setShowQRCode(event.target.checked);

  return (
    <Fragment>
      <Dialog
        className="connect-wif-adv-dialog"
        TransitionComponent={SlideTransition}
        maxWidth="xs"
        fullWidth={true}
        {...rest}
      >
        <DialogTitle>{intl.formatMessage({ id: 'connect-wif-adv-title' })}</DialogTitle>
        <DialogContent>
          {!share ? (
            <Fragment>
              <div className="img-wrapper" style={{ textAlign: 'center' }}>
                <img src="img/share/account-animate.svg" alt="connect-with-adviser" />
              </div>
              <Typography
                dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'connect-wif-adv-hint-4' })) }}
                style={{ fontSize: '16px', whiteSpace: 'pre-wrap' }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                paragraph={true}
                dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'connect-wif-adv-hint-1' })) }}
              />
              <Typography paragraph={true} style={{ fontSize: '75%' }}>
                {expiryText}
                {intl.formatMessage({ id: 'connect-wif-adv-hint-2' })}
              </Typography>

              {share && (
                <ShareButtonGroup
                  url={shareUrl}
                  title={`${intl.formatMessage({ id: 'adv-share-msg-template' })}\n${expiryText}`}
                  CopyTextFieldProps={{
                    multiline: true,
                    rows: 2
                  }}
                />
              )}

              <div className="qr-code-option">
                <FormControlLabel
                  control={<Switch color="primary" checked={showQRCode} onChange={switchShowQRCode} />}
                  label={intl.formatMessage({ id: 'connect-wif-adviser-qr' })}
                  labelPlacement="start"
                  style={{ margin: 0 }}
                />
                {showQRCode && <QRCodeComponent content={shareUrl} logo={true} />}
              </div>
            </Fragment>
          )}
          {error && <Typography color="error">{error}</Typography>}
        </DialogContent>
        <DialogActions>
          {!share ? (
            <Fragment>
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'Close' })}
              </Button>
              <Button onClick={submit} color="primary" disabled={!!shareUrl}>
                {intl.formatMessage({ id: 'continue-2' })}
              </Button>
            </Fragment>
          ) : (
            <Button onClick={closeDialog} color="primary">
              {intl.formatMessage({ id: 'finish' })}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default injectIntl(ConnectWithAdviserDialog);
