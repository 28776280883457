import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import DEFAULT_ADVISER_MESSAGES from '../constants/editor-messages/adviser-message';
import DEFAULT_EXPORT_REMARK from '../constants/editor-messages/export-remark';
import DEFAULT_REMIND_ME from '../constants/editor-messages/remind-me';
import DEFAULT_POLICY_REVIEW_TITLE from '../constants/editor-messages/policy-review-title';
import DEFAULT_PROFILE_PLUS_TEAM_DESCRIPTION from '../constants/editor-messages/profile-plus-team-description';

export const getEditorDefaultMessage = (locale, type) => {
  let targetMessages;
  switch (type) {
    case 'adviser-message':
      targetMessages = DEFAULT_ADVISER_MESSAGES;
      break;
    case 'export-remark':
      targetMessages = DEFAULT_EXPORT_REMARK;
      break;
    case 'remind-me':
      targetMessages = DEFAULT_REMIND_ME;
      break;
    case 'policy-review-title':
      targetMessages = DEFAULT_POLICY_REVIEW_TITLE;
      break;
    case 'profile-plus-team-description':
      targetMessages = DEFAULT_PROFILE_PLUS_TEAM_DESCRIPTION;
      break;
    default:
      throw new Error(`Default message type ${type} not found`);
  }

  switch (locale) {
    case 'zh-Hant-HK':
      return targetMessages['zh-Hant-HK'];
    case 'zh':
      return targetMessages['zh'];
    default:
      return targetMessages['en'];
  }
};

export const formatEditorStateValue = value => {
  if (typeof value === 'object') {
    // if it is EditorState already
    if (value.getCurrentContent) {
      return value;
    } else if (value.getEntityMap) {
      return EditorState.createWithContent(value);
    } else {
      return EditorState.createWithContent(convertFromRaw(value));
    }
  } else if (typeof value === 'string') {
    try {
      // assume it is json-stringified object
      return EditorState.createWithContent(convertFromRaw(JSON.parse(value)));
    } catch (error) {
      // if json parse error, it should be a plain text
      return EditorState.createWithContent(ContentState.createFromText(value));
    }
  }
};

export const getSaveContentState = editorState => JSON.stringify(convertToRaw(editorState.getCurrentContent()));

export const getImageBlockCount = editorState => {
  let editorStateObj = convertToRaw(editorState.getCurrentContent());
  //console.log(editorStateObj);

  // filtering blocks with image
  let imageBlocks = editorStateObj.blocks.filter(block => {
    return block.type === 'atomic' && block.entityRanges !== undefined && block.entityRanges.length > 0;
  });

  return imageBlocks.length;
};

/*
 return all index / length pairs which include an emoji
 */
const getEmojiIndexes = text => {
  const emojiRegex = require('emoji-regex');
  // Note: because the regular expression has the global flag set, this module
  // exports a function that returns the regex rather than exporting the regular
  // expression itself, to make it impossible to (accidentally) mutate the
  // original regular expression.
  const regex = emojiRegex();
  const result = [];
  let match;
  while ((match = regex.exec(text))) {
    const emoji = match[0];
    const index = text.indexOf(match[0]);
    const { length } = emoji;
    result.push({ index, length });
  }
  return result;
};

/*
 check if inlineStyleRange object's range (offset, length) includes an emoji
 */
const indexMatch = (range, emojiIndex, emojiLength) => {
  const { offset, length } = range;
  const rangeEnd = offset + length;
  const emojiEnd = emojiIndex + emojiLength;
  return offset < emojiEnd && rangeEnd >= emojiIndex;
};

/*
 emojis may treated as a single index inside draft block's inlineStyleRanges
 calculate appropriate offset / length for an inlineStyleRange of a raw draft block;
 */
const manipulateStyleRange = (range, emojiIndex, emojiLength) => {
  const { offset, length } = range;
  const newOffset = Math.min(offset, emojiIndex);
  const emojiEnd = emojiIndex + emojiLength;
  const rangeEnd = offset + length;
  const newLength = Math.max(emojiEnd, rangeEnd) - newOffset;
  return { offset: newOffset, length: newLength };
};

/*
 find inlineStyleRange objects covering emoji indexes inside a raw draft block,
 update offset and length attributes of these inlineStyleRange objects with an appropriate values
 return manipulated raw editor state object
 */
export const manipulateRawBlocks = rawState =>
  rawState.blocks.map(entry => {
    const emojiIndexes = getEmojiIndexes(entry.text);
    let { inlineStyleRanges } = entry;
    emojiIndexes.forEach(({ index, length }) => {
      inlineStyleRanges = inlineStyleRanges.map(inline => {
        const matches = indexMatch(inline, index, length);
        if (matches) {
          const newRangeConfig = manipulateStyleRange(inline, index, length);
          return { ...inline, ...newRangeConfig };
        }
        return inline;
      });
    });
    return { ...entry, inlineStyleRanges };
  });


export const getPlainTextFromEditorState = editorState => editorState.getCurrentContent().getPlainText('\u0001');