import { fetch } from '../../utils';
import Backend from '../../backend';

//TO UPDATE THE CUSTOM FILTERS OF ADVISER
export const updateAdviserCustomFilters = data => {
  return fetch(Backend.methodUrl('adviserCustomFilters'), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error;
    });
};
