import { Button } from '@material-ui/core';
import React from 'react';

const MyToggleButton = props => {
  const { className, value, targetValue, onClick, children, ...rest } = props;
  const isSelected = Array.isArray(value) ? value.findIndex(v => v === targetValue) > -1 : value === targetValue;
  return (
    <Button
      variant={isSelected ? 'contained' : 'outlined'}
      className={`plan-type-select-button${className ? ` ${className}` : ''}${isSelected ? ' selected' : ''}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default MyToggleButton;
