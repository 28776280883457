import React, { PureComponent } from 'react';
import _ from 'lodash';

export default class ConsentResponse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
  }

  //this funtion I am commenting because when this function runs the signup component gets reloaded twice
  // componentDidMount() {
  //   var user = this.props.userDetails;
  //   /*to display the page language as per the browser language*/
  //   //the isEmpty check we kept here so that if any user is login in any side tab so that it will not affect this page
  //   if (_.isEmpty(user)) {
  //     var userLang = navigator.language || navigator.userLanguage;
  //     const language =
  //       userLang === 'zh-HK'
  //         ? 'zh-Hant-HK'
  //         : userLang === 'zh-CN'
  //           ? 'zh'
  //           : 'en';
  //     this.props.selectedLocale(language);
  //   }
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.approvePhase === 'accepted') {
      /*when admin accept the adviser signup request*/
      this.setState({
        message: 'The request is successfully verified'
      });
    }
    if (nextProps.approvePhase === 'deleted') {
      /*when admin reject the adviser signup request*/
      this.setState({
        message: 'The user is successfully kept in pending requests data'
      });
    }
  }

  UNSAFE_componentWillMount() {
    var user = this.props.userDetails;
    //the isEmpty check we kept here so that if any user is login in any side tab so that it will not affect this page
    if (_.isEmpty(user) && this.props.match.params.Id) {
      var id = this.props.location.pathname.replace('/verify_user/', '');
      if (id.substring(0, 6) === 'accept') {
        id = id.substring(7);
        let postData = {};
        postData.id = id;
        postData.status = true;
        this.props.approveUser(postData);
      } else {
        id = id.substring(8);
        let postData = {};
        postData.id = id;
        postData.status = false;
        this.props.approveUser(postData);
      }
    }
  }

  render() {
    return (
      <div>
        <div id="loginWrapper">
          <div className="page-containt">
            <img alt="" style={{ paddingTop: '125px' }} className="logo" src="img/logo_kyc.png" />
            <div className="consent-style">
              <p>{this.state.message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
