import { Grid, Hidden } from '@material-ui/core';
import { LOGIN_BANNER_IMG } from '../../constants/images';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import LoginBannerImage from './LoginBannerImage/LoginBannerImage';

const styles = theme => ({
  root: {
    height: '100%',
    maxWidth: '1920px',
    margin: '0 auto',
    fontSize: '16px'
  },
  desktopimagewrapper: {
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  desktopimage: {
    top: '45%',
    left: '50%',
    display: 'block',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    maxWidth: '70%'
  },
  waveimage: {
    width: '150%',
    position: 'absolute',
    bottom: 0,
    pointerEvents: 'none'
  },
  mobilewaveimage: {
    width: '100%',
    bottom: 0
  }
});

const LoginBannerContainer = props => {
  const {
    className,
    style,
    children,
    classes,
    src,
    loginBanner,
    ready,
    onLoad,
    outsideWave,
    ContainerProps,
    ...rest
  } = props;

  return (
    <div className={className} style={style} {...rest}>
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={false} md={8} className={classes.desktopimagewrapper}>
          <img
            src={src || LOGIN_BANNER_IMG}
            className={classes.desktopimage}
            alt=""
            style={ready === false ? { visibility: 'hidden' } : undefined}
          />
          <div>
            <img src="img/Login-wave.png" className={classes.waveimage} alt="" />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4} elevation={6}>
          <Grid container direction="column" style={{ height: '100%', background: 'white' }} {...ContainerProps}>
            <Grid item style={{ width: '100%', flexGrow: 1 }}>
              <Grid container style={{ height: '100%', alignItems: 'center' }}>
                {loginBanner && (
                  <LoginBannerImage src={src} ready={ready !== undefined ? ready : true} onLoad={onLoad} />
                )}
                {children}
              </Grid>
            </Grid>
            <Grid item style={{ flexBasis: 'fit-content' }}>
              <Hidden mdUp>
                <div className="fade-in">
                  <img src="img/LoginMobileWave.png" className={classes.mobilewaveimage} alt="" />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>

        {outsideWave && (
          <Hidden mdUp>
            <Grid item xs={12}>
              <div className="fade-in">
                <img
                  src="img/LoginMobileWave.png"
                  className={classes.mobilewaveimage}
                  alt=""
                  style={{ position: 'absolute' }}
                />
              </div>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(LoginBannerContainer);
