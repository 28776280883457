import React from 'react';
import * as Exchange from './currencyExchange';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getWebImageUrl } from './image';
import { TERMS_OPTIONS } from '../constants/policy';

const CATEGORY_IDS = {
  personal: '59b0eaee14ea7f1aafb6d0b5',
  company: '59b0eb2314ea7f1aafb6d0b6',
  general: '59b0eb3814ea7f1aafb6d0b7'
};

const SUB_CATEGORY_IDS = {
  life: '59b0ed2cdae1831be084f804',
  ci: '59b0ed6fdae1831be084f805',
  medical: '59b0ed8fdae1831be084f806',
  others: '59b0ed98dae1831be084f807',
  saving: '5baf2af899d85c2d38c8f00f',
  provident: '5c18ebd120ca05b7e2b32565',
  accident: '5b9a5bfee624cdb543a476d2',
  home: '59b0eddfdae1831be084f809',
  maid: '5b9a5bfee624cdb543a476d1',
  mortgage: '5c41b85a83230016450b2214',
  motor: '59b0edd8dae1831be084f808',
  travel: '59b0ede7dae1831be084f80a'
};
const SUB_CATEGORY_ORDER = Object.values(SUB_CATEGORY_IDS);

const SUB_CATEGORY_NAMES = {
  life: 'Life',
  ci: 'C.I.',
  medical: 'Med',
  others: 'others',
  saving: 'Saving',
  provident: 'Provident Fund',
  accident: 'Accident',
  home: 'Home',
  maid: 'Maid',
  mortgage: 'Mortgage',
  motor: 'Motor',
  travel: 'Travel'
};

export const SUB_CATEGORY_COLORS = {
  life: '#3eda7d',
  ci: '#FF6A74',
  criticalIllness: '#FF6A74',
  medical: '#3966f8',
  others: '#ffa600',
  saving: '#8c1aff',
  ilas: '#8c1aff',
  provident: '#6d78cc',
  providentFund: '#6d78cc',
  accident: '#f49e0b',
  home: '#FF6A74',
  maid: '#3966f8',
  mortgage: '#a55a5a',
  motor: '#f7228c',
  travel: '#009999'
};

const SUB_CATEGORY_ICONS = {
  life: <img src={getWebImageUrl('img/sub-categories/life.png')} alt="life" />,
  ci: <img src={getWebImageUrl('img/sub-categories/critical.png')} alt="critical-illness" />,
  medical: <img src={getWebImageUrl('img/sub-categories/medical.png')} alt="medical" />,
  others: <img src={getWebImageUrl('img/sub-categories/other.png')} alt="others" />,
  saving: <img src={getWebImageUrl('img/sub-categories/saving.png')} alt="saving" />,
  provident: <img src={getWebImageUrl('img/sub-categories/Pfund.png')} alt="provident-fund" />,
  accident: <img src={getWebImageUrl('img/sub-categories/accident.png')} alt="accident" />,
  home: <img src={getWebImageUrl('img/sub-categories/home.png')} alt="home" />,
  maid: <img src={getWebImageUrl('img/sub-categories/Maid.png')} alt="maid" />,
  mortgage: <img src={getWebImageUrl('img/sub-categories/mortgage.png')} alt="mortgage" />,
  motor: <img src={getWebImageUrl('img/sub-categories/Motor.png')} alt="motor" />,
  travel: <img src={getWebImageUrl('img/sub-categories/Travel.png')} alt="travel" />
};

const SUB_CATEGORY_ICONS_BW = {
  life: <img src={getWebImageUrl('img/sub-categories/life-bw.png')} alt="life" />,
  ci: <img src={getWebImageUrl('img/sub-categories/critical-bw.png')} alt="critical-illness" />,
  medical: <img src={getWebImageUrl('img/sub-categories/medical-bw.png')} alt="medical" />,
  others: <img src={getWebImageUrl('img/sub-categories/other-bw.png')} alt="others" />,
  saving: <img src={getWebImageUrl('img/sub-categories/saving-bw.png')} alt="saving" />,
  provident: <img src={getWebImageUrl('img/sub-categories/Pfund-bw.png')} alt="provident-fund" />,
  accident: <img src={getWebImageUrl('img/sub-categories/accident-bw.png')} alt="accident" />,
  home: <img src={getWebImageUrl('img/sub-categories/home-bw.png')} alt="home" />,
  maid: <img src={getWebImageUrl('img/sub-categories/Maid-bw.png')} alt="maid" />,
  mortgage: <img src={getWebImageUrl('img/sub-categories/mortgage-bw.png')} alt="mortgage" />,
  motor: <img src={getWebImageUrl('img/sub-categories/Motor-bw.png')} alt="motor" />,
  travel: <img src={getWebImageUrl('img/sub-categories/Travel-bw.png')} alt="travel" />
};

const INSURER_IDS = {
  other: '5a1be0f9dfaa60227838e426'
};
export const getAllSubCategoryColor = () => SUB_CATEGORY_COLORS;
export const isCategory = (categoryId, categoryIndex) =>
  Array.isArray(categoryIndex)
    ? categoryIndex.find(index => categoryId === CATEGORY_IDS[index])
    : categoryId === CATEGORY_IDS[categoryIndex];
export const isPolicyCategory = (policy, categoryIndex) => isCategory(policy.categoryId, categoryIndex);
export const isSubCategory = (subCategoryId, subCategoryIndex) =>
  Array.isArray(subCategoryIndex)
    ? subCategoryIndex.find(index => subCategoryId === SUB_CATEGORY_IDS[index])
    : subCategoryId === SUB_CATEGORY_IDS[subCategoryIndex];
export const isPolicySubCategory = (policy, subCategoryIndex) => isSubCategory(policy.subCategoryId, subCategoryIndex);
export const isSubCategoryMarketValueRelated = (categoryId, subCategoryId) =>
  isCategory(categoryId, 'personal') ||
  isSubCategory(subCategoryId, ['saving', 'provident']) ||
  (isCategory(categoryId, 'general') && isSubCategory(subCategoryId, 'others'));
export const getSubCategoryColor = subCategory =>
  _.get(
    SUB_CATEGORY_COLORS,
    _.findKey(SUB_CATEGORY_IDS, id => id === subCategory._id),
    '#333'
  );

export const getSubCategoryColorByPolicy = poilcy =>
  _.get(
    SUB_CATEGORY_COLORS,
    _.findKey(SUB_CATEGORY_IDS, id => id === poilcy.subCategoryId),
    '#333'
  );

export const getSubCategoryNameById = subCategoryId =>
  _.get(
    SUB_CATEGORY_NAMES,
    _.findKey(SUB_CATEGORY_IDS, id => id === subCategoryId),
    ' '
  );

export const getSubCategoryIcon = (subCategory, havePolicy) =>
  _.get(
    havePolicy ? SUB_CATEGORY_ICONS : SUB_CATEGORY_ICONS_BW,
    _.findKey(SUB_CATEGORY_IDS, id => id === subCategory._id)
  );

export const isInsurer = (insurerId, insurerIndex) =>
  Array.isArray(insurerIndex)
    ? insurerIndex.find(index => insurerId === INSURER_IDS[index])
    : insurerId === INSURER_IDS[insurerIndex];
export const isPolicyInsurer = (policy, insurerIndex) => {
  const insurerId = typeof policy.insurerId === 'string' ? policy.insurerId : _.get(policy, 'insurerId._id');
  return isInsurer(insurerId, insurerIndex);
};
export const isVHISPolicy = policy => _.get(policy, 'vhisPlan') === 'Yes';
export const isPaidUpPolicy = policy => {
  const paidUpDate = _.get(policy, 'paidUpDate');
  if (paidUpDate) {
    try {
      return moment(paidUpDate).isBefore(moment());
    } catch (error) {
      return false;
    }
  } else {
    return _.get(policy, 'selectedPolicyStatus') === 'Paid-up';
  }
};
export const isRiderPolicy = policy => _.get(policy, 'planType') === 'Rider';
export const isInsuredNotMePolicy = policy => _.get(policy, 'lifeInsuredSelected') === 'Not Me';
export const isILASPolicy = policy => _.get(policy, 'guaranteeValue') === 'No';
export const isAnnuityPolicy = policy => _.get(policy, 'isAnnuity') === 'Yes';
export const isDraftPolicy = policy => _.get(policy, 'isDraft', false);
export const isPolicySavingFeatured = policy => _.get(policy, 'feature') === 'Saving';
export const isMultiClaimPolicy = policy => _.get(policy, 'multiClaim') === true;
export const isTaxDeductiblePolicy = policy => _.get(policy, 'taxDeductible') === 'Yes';
export const isPolicyMarketValueRelated = policy =>
  isSubCategoryMarketValueRelated(policy.categoryId, policy.subCategoryId);
export const shouldShowVHISIcon = policy => isPolicySubCategory(policy, 'medical') && isVHISPolicy(policy);
export const shouldShowQDAPIcon = policy =>
  isPolicySubCategory(policy, 'saving') && isAnnuityPolicy(policy) && isTaxDeductiblePolicy(policy);
export const getPolicyLifeInsuredDisplay = (policy, intl) => {
  const { lifeInsured, lifeInsuredSelected } = policy || {};
  if (lifeInsuredSelected === 'Not Me') {
    return lifeInsured || intl.formatMessage({ id: 'Not Me' });
  }
  if (lifeInsuredSelected) {
    return intl.formatMessage({ id: lifeInsuredSelected });
  } else {
    return '';
  }
};
export const getPolicyBeneficialDisplay = (policy, intl) => {
  const { beneficial, beneficialSelected } = policy || {};
  if (beneficialSelected === 'Not Me') {
    return beneficial || intl.formatMessage({ id: 'Not Me' });
  }
  if (beneficialSelected) {
    return intl.formatMessage({ id: beneficialSelected });
  } else {
    return '';
  }
};

export const getPolicyValue = (policy, field, currency) => {
  const value = policy[field] || 0;
  return Exchange.currencyExchange(
    typeof policy[field] === 'string' ? parseFloat(value) : value,
    currency,
    policy.currency
  );
};
export const getPremiumPeriodValue = (policy, period, currency) => {
  const premiumTime = Exchange.annualFrequency(period, policy.frequency || 12);
  const premium =
    Exchange.currencyExchange(parseFloat(policy.premium || 0), currency, policy.currency || 'HKD') * premiumTime;
  return premium;
};
export const getPolicyMarketValue = (policy, currency) => {
  if (isPolicyMarketValueRelated(policy)) {
    return getPolicyValue(policy, 'marketValue', currency);
  } else {
    return undefined;
  }
};

export const getPolicyStartDate = policy => {
  return _.get(policy, 'policyStartDate');
};

export const getPolicyDueDate = policy => {
  return _.get(policy, 'policyDueDate');
};

export const getRoomClass = policy => {
  const isMedical = isPolicySubCategory(policy, 'medical');
  if (isMedical) {
    const roomClass = _.get(policy, 'roomClass');
    if (roomClass === 'Private' || roomClass === 'Private Room') {
      return 'Private';
    }
    if (roomClass === 'Ward' || roomClass === 'Ward Room') {
      return 'Ward';
    }

    return roomClass;
  } else {
    return undefined;
  }
};

export const getMedicalPortfolioBestRoom = policies => {
  // filter off 'Not Me' policies
  policies = (policies || []).filter(policy => !isInsuredNotMePolicy(policy));

  const counts = _.countBy(policies, policy => getRoomClass(policy));
  let result = 'N.A.   ';
  ['Private', 'Semi Private', 'Ward', 'Semi Ward'].some(roomClassType => {
    if (counts[roomClassType]) {
      result = roomClassType;
      return true;
    }
    return false;
  });
  return result;
};

export const categorySorter = (a, b) => {
  if (isCategory(b._id, 'personal') || (isCategory(b._id, 'general') && !isCategory(a._id, 'personal'))) {
    return 1;
  }
  return -1;
};

export const policySorter = (a, b) => {
  return (
    SUB_CATEGORY_ORDER.findIndex(id => id === a.subCategoryId) -
    SUB_CATEGORY_ORDER.findIndex(id => id === b.subCategoryId)
  );
};

export const getFrequencyIntlId = policy => {
  const frequency = _.get(policy, 'frequency');
  switch (frequency) {
    case '1':
      return 'Per Month';
    case '3':
      return 'Per Quarter';
    case '6':
      return 'Per Half Year';
    case '12':
      return 'Per Year';
    default:
      return undefined;
  }
};

export const checkPolicy = (policy, { history, handleDashboardPolicyData }) => {
  handleDashboardPolicyData(policy);
  history.push({
    pathname: '/insured_details',
    state: {
      clientFactsheetDetail: {
        clientIncData: _.get(history.location, 'state.clientFactsheetDetail.clientIncData')
      },
      previousPath: _.get(history.location, 'state.previousPath')
    }
  });
};

export const getPolicyCoverageForPolicyPicker = (policy, currency) =>
  isPolicySubCategory(policy, 'ci')
    ? getPolicyValue(policy, 'sumInsured', currency)
    : getPolicyValue(policy, 'lifeCoverage', currency);
export const getPolicyMonthlyPremiumForPolicyPicker = (policy, currency) =>
  getPremiumPeriodValue(policy, 'Monthly', currency);

export const getTermsIntl = (intl, option) => {
  switch (option) {
    case TERMS_OPTIONS.YEARS_5:
      return `5 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_8:
      return `8 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_10:
      return `10 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_12:
      return `12 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_15:
      return `15 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_18:
      return `18 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_20:
      return `20 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.YEARS_25:
      return `25 ${intl.formatMessage({ id: 'Years' })}`;
    case TERMS_OPTIONS.TILL_AGE_55:
      return `${intl.formatMessage({ id: 'Till age' })} 55`;
    case TERMS_OPTIONS.TILL_AGE_60:
      return `${intl.formatMessage({ id: 'Till age' })} 60`;
    case TERMS_OPTIONS.TILL_AGE_65:
      return `${intl.formatMessage({ id: 'Till age' })} 65`;
    case TERMS_OPTIONS.TILL_AGE_70:
      return `${intl.formatMessage({ id: 'Till age' })} 70`;
    case TERMS_OPTIONS.TILL_AGE_75:
      return `${intl.formatMessage({ id: 'Till age' })} 75`;
    case TERMS_OPTIONS.TILL_AGE_80:
      return `${intl.formatMessage({ id: 'Till age' })} 80`;
    case TERMS_OPTIONS.TILL_AGE_85:
      return `${intl.formatMessage({ id: 'Till age' })} 85`;
    case TERMS_OPTIONS.TILL_AGE_100:
      return `${intl.formatMessage({ id: 'Till age' })} 100`;
    case TERMS_OPTIONS.TILL_AGE_120:
      return `${intl.formatMessage({ id: 'Till age' })} 120`;
    case TERMS_OPTIONS.WHOLE_LIFE:
      return intl.formatMessage({ id: 'Whole Life' });
    case TERMS_OPTIONS.OTHER:
      return intl.formatMessage({ id: 'Other' });
    default:
      return '';
  }
};

export const getAddOnsIntlId = option => `add-ons-${option}`;
