import { fetch } from '../../utils';
import Backend from '../../backend';

export const importClients = async files => {
  const file = files[0];
  const formData = new FormData();
  formData.append('data', file);

  return fetch(Backend.methodUrl('import/clients'), {
    method: 'POST',
    body: formData
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
