import React from 'react';
import { injectIntl } from 'react-intl';
import { Card, Typography } from '@material-ui/core';

const CardStyle = {
  padding: 16,
  boxShadow: 'var(--my-shadow)'
};

const TitleTypeStyle = {
  fontSize: '150%'
};

const ForEnterpriseOnlyCard = props => {
  const { intl } = props;

  return (
    <Card style={CardStyle}>
      <Typography style={TitleTypeStyle}>{intl.formatMessage({ id: 'enterprise-only-card-title' })}</Typography>
      <Typography>{intl.formatMessage({ id: 'enterprise-only-card-content' })}</Typography>
    </Card>
  );
};

export default injectIntl(ForEnterpriseOnlyCard);
