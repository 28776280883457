import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import SettingsIcon from '@material-ui/icons/Settings';
import './PolicyCategoryTiles.scss';
import { formatNumber } from '../../../../../../utils/formatNumber';
import IconButton from '@material-ui/core/IconButton';
import { toggleDialog } from '../../../../../../store/control/duck';
import _ from 'lodash';
import { sanitize } from 'dompurify';
// import { getAveragePremiumSetting } from '../../../../../../utils/potential-business';

const formattedNumberDisplay = (val, intl) => {
  const { value, unit } = formatNumber(val, intl, true);
  return (
    <span>
      <span>{value}</span>
      <span>{unit}</span>
    </span>
  );
};

class PolicyCategoryTiles extends React.PureComponent {
  openAveragePremiumSettingDialog = () => {
    const { averagePremiumSetting, toggleDialog } = this.props;
    toggleDialog('averagePremiumSetting', { ...averagePremiumSetting });
  };

  render() {
    const {
      intl,
      headingIntlId,
      life,
      medical,
      ci,
      saving,
      ilas,
      className,
      averagePremiumSetting,
      toggleDialog,
      ...rest
    } = this.props;
    return (
      <div className={`cardAnalysis client-dashboard-coverage-div ${className ? className : ''}`} {...rest}>
        <IconButton className="setting-btn" color="primary" onClick={this.openAveragePremiumSettingDialog}>
          <SettingsIcon />
        </IconButton>

        <div className="client-dashboard-coverage-div-title">
          <p dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: headingIntlId })) }} />
        </div>
        <div>
          <div className="client-dashboard-coverage-group">
            <Link
              to={{
                pathname: '/client',
                select: 'life'
              }}
            >
              <div className="colon">:</div>
              <img alt="" src="img/lifepolicy.svg" />
              <div>
                <h5>{intl.formatMessage({ id: 'Life' })}</h5>
              </div>
              <div>
                <p>{formattedNumberDisplay(life, intl)}</p>
              </div>
            </Link>
          </div>

          <div className="client-dashboard-coverage-group">
            <Link
              to={{
                pathname: '/client',
                select: 'medical'
              }}
            >
              <div className="colon">:</div>
              <img alt="" src="img/med.svg" />
              <div>
                <h5>{intl.formatMessage({ id: 'Medical' })}</h5>
              </div>

              <div>
                <p>{formattedNumberDisplay(medical, intl)}</p>
              </div>
            </Link>
          </div>

          <div className="client-dashboard-coverage-group ci-tab">
            <Link
              to={{
                pathname: '/client',
                select: 'illness'
              }}
            >
              <div className="colon">:</div>
              <img alt="" src="img/ci.svg" />
              <div>
                <h5>{intl.formatMessage({ id: 'C.I.' })}</h5>
              </div>

              <div>
                <p>{formattedNumberDisplay(ci, intl)}</p>
              </div>
            </Link>
          </div>
          <div className="two-cal">
            <div className="client-dashboard-coverage-group">
              <Link
                to={{
                  pathname: '/client',
                  select: 'saving'
                }}
              >
                <div className="colon">:</div>
                <img alt="" src="img/saving.svg" />
                <div>
                  <h5>{intl.formatMessage({ id: 'Saving' })}</h5>
                </div>

                <div>
                  <p>{formattedNumberDisplay(saving, intl)}</p>
                </div>
              </Link>
            </div>
            <div className="client-dashboard-coverage-group">
              <Link
                to={{
                  pathname: '/client',
                  select: 'ilas'
                }}
              >
                <div className="colon">:</div>
                <img alt="" src="img/ilas.svg" />
                <div>
                  <h5>{intl.formatMessage({ id: 'ILAS' })}</h5>
                </div>

                <div>
                  <p>{formattedNumberDisplay(ilas, intl)}</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    averagePremiumSetting: _.get(state, 'user.userDetails.averagePremiumSetting')
  }),
  // Map actions to props
  {
    toggleDialog
  }
)(PolicyCategoryTiles);

export default injectIntl(container);
