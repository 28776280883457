import React from 'react';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router';
import { Container, Typography } from '@material-ui/core';
import ReferralCard from '../Referral/partials/ReferralCard/ReferralCard';
import moment from 'moment-timezone';
import _ from 'lodash';
import { crypt } from '../../../utils/string';
import { sanitize } from 'dompurify';

const PFPLUS_URL = process.env.PFPLUS_URL;

const MedicalCardQRCode = props => {
  const { intl, location } = props;

  const applyLink = _.get(location, 'state.applyLink');

  if (applyLink) {
    const cryptString = crypt(JSON.stringify({ applyLink: applyLink, expiredAt: moment().add(3, 'minute') }));
    const qrLink = `${PFPLUS_URL}/medical-card-qr-apply?hash=${encodeURIComponent(cryptString)}`;

    return (
      <div className="medical-card-qr-code refer-adviser">
        <Container className="container" maxWidth="sm">
          <section>
            <Typography
              style={{ whiteSpace: 'pre-wrap', fontSize: '115%' }}
              paragraph={true}
              dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: 'med-card-qr-desc' })) }}
            />
          </section>

          <section>
            <ReferralCard
              link={qrLink}
              customCTAMessage={intl.formatMessage({ id: 'med-card-qr-card-title' })}
              hideHeader={true}
            />
          </section>
        </Container>
      </div>
    );
  } else {
    return <Redirect to="/medical-card-purchase" />;
  }
};

export default injectIntl(MedicalCardQRCode);
