import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { decimalToString, formatNumber } from '../../../../../utils/formatNumber';
import { Doughnut } from 'react-chartjs-2';

class QuarterProgressChart extends PureComponent {
  render() {
    const {
      intl,
      headingStatus,
      outerChartValue,
      targetBiz,
      QEnd,
      quarterTarget,
      confirmedBiz,
      quarterBusiness
    } = this.props;

    let outerProgressValue = outerChartValue;
    let innerProgressValue = quarterBusiness;

    //for greate job value current progress minus quarter target
    let greateJobValue = outerChartValue - quarterTarget;

    //Calculate the value of leading or lagging
    let leadingCondition =
      outerProgressValue >= innerProgressValue
        ? outerProgressValue - innerProgressValue
        : innerProgressValue - outerProgressValue;

    const headingStatusText =
      headingStatus === 'onTrack'
        ? 'Leading'
        : headingStatus === 'offTrack'
        ? 'Lagging'
        : headingStatus === 'great'
        ? 'Overachieved'
        : headingStatus === 'zeroProgress'
        ? 'Current Biz'
        : null;

    //quarter progress chart
    let quarterProgressData = {
      datasets: [
        /* Outer progress data starts*/
        {
          label: 'Graph1',
          data:
            quarterTarget === 0 && outerChartValue === 0
              ? [0.1]
              : quarterTarget - outerChartValue > 0
              ? [parseInt(confirmedBiz), parseInt(targetBiz), quarterTarget - outerChartValue]
              : [parseInt(confirmedBiz), parseInt(targetBiz)],
          backgroundColor:
            quarterTarget === 0 && outerChartValue === 0
              ? ['#E9e9e9']
              : quarterTarget - outerChartValue > 0
              ? ['#8c1aff', '#D2A6FF', '#E9e9e9']
              : ['#8c1aff', '#D2A6FF'],
          hoverBackgroundColor:
            quarterTarget === 0 && outerChartValue === 0
              ? ['#E9e9e9']
              : quarterTarget - outerChartValue > 0
              ? ['#8c1aff', '#D2A6FF', '#E9e9e9']
              : ['#8c1aff', '#D2A6FF'],
          weight: 1,
          labels: [
            intl.formatMessage({ id: 'Closed Biz' }),
            intl.formatMessage({ id: 'followingUp-biz' }),
            intl.formatMessage({ id: 'Quarter Target' })
          ]
          //borderWidth: [2]
        },
        /* Outer progress data ends*/

        /* Inner progress data starts*/
        {
          label: 'Graph2',
          data:
            QEnd === '-' && QEnd === 0
              ? [0, 0]
              : quarterTarget - quarterBusiness >= 0
              ? [quarterBusiness, quarterTarget - quarterBusiness]
              : [quarterBusiness, quarterTarget],
          backgroundColor: ['#3966f8', '#ffffff'],
          hoverBackgroundColor: ['#3966f8', '#ffffff'],
          weight: 0.5,
          labels: [intl.formatMessage({ id: 'Expected Biz' }), intl.formatMessage({ id: 'Quarter Target' })]
          // borderWidth: [5]
        }
        /* Inner progress data ends*/
      ],
      texts: [
        {
          subtexts: [
            {
              text:
                headingStatusText !== null && headingStatusText !== undefined
                  ? intl.formatMessage({
                      id: headingStatusText
                    })
                  : '',
              color: '#000'
            }
          ],
          fontSizeFactor: 1,
          fontWeight: '400'
        },
        {
          subtexts: [
            {
              text:
                headingStatus === 'onTrack'
                  ? formatNumber(leadingCondition, intl)
                  : headingStatus === 'offTrack'
                  ? formatNumber(leadingCondition, intl)
                  : headingStatus === 'great'
                  ? formatNumber(greateJobValue, intl)
                  : headingStatus === 'zeroProgress'
                  ? '-'
                  : '',
              color: headingStatus === 'onTrack' || headingStatus === 'great' ? '#5ee092' : '#ff6a74'
            }
          ],
          fontSizeFactor: 2,
          fontWeight: '400'
        }
      ],
      borderWidth: [2]
    };

    const quarterProgressConfig = {
      animation: {
        duration: 3000
      },
      cutoutPercentage: 75,
      legend: {
        display: false,
        position: 'right',
        labels: {
          fontColor: '#34A0DC',
          fontSize: 9,
          borderRadius: 2,
          boxWidth: 10,
          usePointStyle: true,
          generateLabels: function(chart) {
            var data = chart.data;
            if (data.datasets.length) {
              var allLabels = [];
              // eslint-disable-next-line
              data.datasets.map(function(dataSetData) {
                // eslint-disable-next-line
                dataSetData.labels.map(function(result, inner) {
                  if (inner === 0)
                    allLabels.push({
                      label: dataSetData.labels[inner],
                      backgroundColor: dataSetData.backgroundColor[inner],
                      hoverBackgroundColor: dataSetData.hoverBackgroundColor[inner],
                      hidden: false
                    });
                  if (inner === 1 && result === intl.formatMessage({ id: 'followingUp-biz' }))
                    allLabels.push({
                      label: dataSetData.labels[inner],
                      backgroundColor: dataSetData.backgroundColor[inner],
                      hoverBackgroundColor: dataSetData.hoverBackgroundColor[inner],
                      hidden: false
                    });
                });
              });
              // eslint-disable-next-line
              return allLabels.map((label, i) => {
                if (i === 0 || i === 1) {
                  let meta = chart.getDatasetMeta(0);
                  let ds = data.datasets[0];
                  return {
                    text: label.label,
                    fillStyle: label.backgroundColor,
                    strokeStyle: label.hoverBackgroundColor,
                    pointShape: 'circle',
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i
                  };
                }
                if (i === 2) {
                  let meta = chart.getDatasetMeta(1);
                  let ds = data.datasets[1];
                  return {
                    text: label.label,
                    fillStyle: label.backgroundColor,
                    strokeStyle: label.hoverBackgroundColor,
                    pointShape: 'circle',
                    hidden: isNaN(ds.data[1]) || meta.data[1].hidden,
                    index: i
                  };
                }
              });
            }
            return [];
          }
        }
      },
      responsive: false,
      elements: {
        arc: {
          roundedCornersFor: 0
        }
      },
      plugins: {
        datalabels: {
          display: false
        }
      },
      tooltips: {
        enabled: true,
        //*****add for reference********* /
        filter: function(tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].labels;
          if (label === 'Quarter Target') {
            return false;
          } else {
            return true;
          }
        },
        callbacks: {
          label: function(tooltipItem, data) {
            if (tooltipItem.datasetIndex) {
              const labels = data.datasets[tooltipItem.datasetIndex].labels;
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var total = dataset.data.reduce(function(previousValue, currentValue) {
                return previousValue + currentValue;
              });
              var currentValue = dataset.data[tooltipItem.index];
              var percentage =
                currentValue !== 0.1 && total !== 0.1 && total > 0 ? ((currentValue / total) * 100).toFixed(2) : 0;

              return labels[tooltipItem.index] + ': ' + decimalToString(currentValue) + '(' + percentage + '%)';
            } else {
              const labels = data.datasets[tooltipItem.datasetIndex].labels;

              dataset = data.datasets[tooltipItem.datasetIndex];
              total = dataset.data.reduce(function(previousValue, currentValue) {
                return previousValue + currentValue;
              });
              currentValue = dataset.data[tooltipItem.index];
              percentage =
                currentValue !== 0.1 && total !== 0.1 && total > 0 ? ((currentValue / total) * 100).toFixed(2) : 0;
              return labels[tooltipItem.index] + ': ' + decimalToString(currentValue) + '(' + percentage + '%)';
            }
          }
        }
      }
    };

    //To round the edge of outer chart
    const pluginVar = [
      {
        afterUpdate: function(chart) {
          if (
            chart.config.options.elements.arc.roundedCornersFor !== undefined &&
            quarterProgressData &&
            quarterProgressData.datasets
          ) {
            let a = chart.config.data.datasets.length - 1.5;
            for (let i in chart.config.data.datasets) {
              let arc = chart.getDatasetMeta(i).data[chart.config.options.elements.arc.roundedCornersFor];
              arc.round = {
                x: (chart.chartArea.left + chart.chartArea.right) / 2,
                y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                radius: chart.innerRadius + chart.radiusLength / 2 + a * chart.radiusLength,
                thickness: Math.abs(chart.radiusLength / 2 - 1),
                backgroundColor: arc._model.backgroundColor
              };
              if (i === '0') {
                let arc = chart.getDatasetMeta(0).data[1];
                if (arc) {
                  arc.round = {
                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                    radius: chart.innerRadius + chart.radiusLength / 2 + a * chart.radiusLength,
                    thickness: Math.abs(chart.radiusLength / 2 - 1),
                    backgroundColor: arc._model.backgroundColor
                  };
                }
              }
              a--;
            }
          }
        }
      },
      {
        afterDraw: function(chart) {
          if (
            chart.config.options.elements.arc.roundedCornersFor !== undefined &&
            quarterProgressData &&
            quarterProgressData.datasets
          ) {
            let ctx = chart.chart.ctx;

            for (let i in chart.config.data.datasets) {
              if (i === '0') {
                let arc = chart.getDatasetMeta(i).data[chart.config.options.elements.arc.roundedCornersFor];
                let startAngle = Math.PI / 2 - arc._view.startAngle;
                let endAngle = Math.PI / 2 - arc._view.endAngle;

                ctx.save();
                ctx.translate(arc.round.x, arc.round.y);
                ctx.fillStyle = arc.round.backgroundColor;
                ctx.beginPath();
                ctx.arc(
                  arc.round.radius * Math.sin(startAngle),
                  arc.round.radius * Math.cos(startAngle),
                  arc.round.thickness,
                  0,
                  2 * Math.PI
                );
                ctx.arc(
                  arc.round.radius * Math.sin(endAngle),
                  arc.round.radius * Math.cos(endAngle),
                  arc.round.thickness,
                  0,
                  2 * Math.PI
                );
                ctx.closePath();
                ctx.fill();
                ctx.restore();
              }
              if (i === '0') {
                let arc = chart.getDatasetMeta(0).data[1];
                if (arc) {
                  // let startAngle = Math.PI / 2 - arc._view.startAngle;
                  let endAngle = Math.PI / 2 - arc._view.endAngle;
                  //testing
                  ctx.save();
                  ctx.translate(arc.round.x, arc.round.y);
                  ctx.fillStyle = arc.round.backgroundColor;
                  ctx.beginPath();
                  ctx.arc(0, 0, 0, 0, 0);
                  ctx.arc(
                    arc.round.radius * Math.sin(endAngle),
                    arc.round.radius * Math.cos(endAngle),
                    arc.round.thickness,
                    0,
                    2 * Math.PI
                  );
                  ctx.closePath();
                  ctx.fill();
                  ctx.restore();
                }
              }
            }
          }
        }
      }
    ];
    return (
      <div>
        <Doughnut data={quarterProgressData} options={quarterProgressConfig} plugins={pluginVar} />
      </div>
    );
  }
}

export default injectIntl(QuarterProgressChart);
