import { Button, Card, Grid, IconButton, Typography } from '@material-ui/core';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const CardStyle = {
  zIndex: 1,
  borderRadius: 16,
  boxShadow: '0 5px 10px rgb(126 145 221 / 5%), 0 15px 40px rgb(110 129 202 / 20%)',
  padding: 0,
  background: '#fff',
};

const FullHeightStyle = {
  height: '100%'
};

const ContentGridItemStyle = {
  flexGrow: 1
};

const TypoGridItemStyle = {
  flexGrow: 1
};

const TitleTypoStyle = {
  fontSize: '130%',
  fontWeight: 600,
  color: '#616161'
};

const DescriptionTypoStyle = {
  whiteSpace: 'pre-wrap',
  lineHeight: 1.3
};

const IconStyle = {
  verticalAlign: 'middle',
  borderRadius: '50%',
  fontSize: 20
};

const BusinessToolCard = props => {
  const { backgroundColor, imgSrc, imgAlt, title, titleExtras, description, small, action, style, ...rest } = props;
  const theme = useTheme();

  return (
    <Button color="primary" fullWidth={true} style={{ ...CardStyle, ...style }} onClick={action} {...rest}>
      <Grid container alignItems="stretch">
        <Grid
          item
          style={{
            padding: small ? '12px 16px' : '16px 22px',
            background: backgroundColor,
            borderRadius: '16px 0 0 16px'
          }}
        >
          <Grid container alignItems="center" style={FullHeightStyle}>
            <Grid item>
              <img src={imgSrc} alt={imgAlt} width={small ? 55 : 65} height={small ? 55 : 65} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ ...ContentGridItemStyle, padding: small ? '8px 16px' : 25 }}>
          <Grid container alignItems="center" style={FullHeightStyle}>
            <Grid item style={TypoGridItemStyle}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography align="left" style={TitleTypoStyle}>
                    {title}
                  </Typography>
                </Grid>
                {titleExtras && <Grid item>{titleExtras}</Grid>}
              </Grid>
              <Typography color="textSecondary" align="left" style={DescriptionTypoStyle}>
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <KeyboardArrowRightRoundedIcon
                style={{
                  ...IconStyle,
                  background: 'var(--new-theme)',
                  fill: theme.palette.primary.contrastText
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};

export default BusinessToolCard;
