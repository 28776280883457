import React, { Fragment, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Card, CardContent, Grid, IconButton } from '@material-ui/core';
import SelectButtonGroup from '../../../../../components/SelectButtonGroup/SelectButtonGroup';
import EditDisplayNamesDialog from '../../../../../components/EditDisplayNamesDialog/EditDisplayNamesDialog';
import './CalculatorProfiles.scss';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getPremiumFinancingProfileDisplay } from '../../../../../utils/premium-financing';

SwiperCore.use([Pagination]);

const VersionButtons = ({ index, intl, versionChoices, onChange }) => {
  return (
    <SelectButtonGroup
      value={index}
      choices={versionChoices}
      buttonClassName="profile-buttons"
      spacing={2}
      onChange={onChange}
      justify="center"
    />
  );
};

const CalculatorProfiles = props => {
  const {
    pFinProfiles,
    index,
    onIndexChange,
    openEditDisplayNamesDialog,
    saveProfiles,
    intl,
    isSmallScreen,
    extraSideInputs
  } = props;

  const getVersionChoices = indexes =>
    indexes.map(index => {
      return {
        label: getPremiumFinancingProfileDisplay(intl, pFinProfiles[index], index),
        value: index,
        xs: 6
      };
    });

  const firstVersionChoices = getVersionChoices([0, 1, 2, 3]);
  const secondVersionChoices = getVersionChoices([4, 5, 6, 7]);

  return (
    <Fragment>
      <Card className="pfa-card pf-card-container calculator-profiles">
        <CardContent>
          <Grid container>
            <Grid container xs={12} justify="flex-end">
              <IconButton className="editIconButton" onClick={openEditDisplayNamesDialog}>
                <EditIcon style={{ fontSize: 18 }} />
              </IconButton>
            </Grid>
            <Grid xs={12}>
              <div>
                <Swiper
                  spaceBetween={15}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  autoHeight={true}
                  initialSlide={0}
                  slidesPerView={1}
                  navigation={false}
                  pagination={{
                    clickable: true
                  }}
                >
                  <SwiperSlide>
                    <VersionButtons
                      intl={intl}
                      index={index}
                      versionChoices={firstVersionChoices}
                      onChange={onIndexChange}
                      isSmallScreen={isSmallScreen}
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <VersionButtons
                      intl={intl}
                      index={index}
                      versionChoices={secondVersionChoices}
                      onChange={onIndexChange}
                      isSmallScreen={isSmallScreen}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </Grid>
            <Grid xs={12} className="save-button">
              <Button onClick={saveProfiles} color="primary" variant="contained">
                {intl.formatMessage({ id: 'save-data' })}
              </Button>
            </Grid>
          </Grid>
          {extraSideInputs && extraSideInputs}
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default CalculatorProfiles;
