import React, { PureComponent } from 'react';
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import './EmergencyExportView.css';
import { toast } from 'react-toastify';
import { formatEditorStateValue, getEditorDefaultMessage, getPlainTextFromEditorState, getSaveContentState } from '../../../utils/editor';
import BlockingOverlay from '../../../components/SubscriptionComponents/BlockingOverlay/BlockingOverlay';
import EN_TRANSLATION from '../../../store/locale/messages/en.json';
import ZH_HANT_HK_TRANSLATION from '../../../store/locale/messages/zh-Hant-HK.json';
import ZH_TRANSLATION from '../../../store/locale/messages/zh.json';
import MyEditor from '../../../components/MyEditor/MyEditor';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import ProRequiredCard from '../../../components/SubscriptionComponents/ProRequiredCard/ProRequiredCard';
import { renderToString } from 'react-dom/server';
import { getPDF } from '../../../store/pdf/api';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import Export from '../../Export/Export';
import { isChromeIOS, isWidthExceed } from '../../../utils/device';
import Typography from '@material-ui/core/Typography';
import LanguageRadioGroup from '../../../components/LanguageRadioGroup/LanguageRadioGroup';
import _ from 'lodash';
import PreviewWrapper from '../../../pdf-templates/PreviewWrapper';
import AlertPopUp from '../../../components/AlertPopup';
import moment from 'moment-timezone';
import ExportHintDialog from '../../../components/ExportHintDialog/ExportHintDialog';
import { isAdviserType, shouldEnableChatGPT } from '../../../utils/user';
import ShareButtonGroup from '../../../components/ShareButtonGroup/ShareButtonGroup';
import { tracking } from '../../../utils/tracking';
import NativeOrWeb from '../../../utils/native';
import TempSelect from '../../../components/TempSelect/TempSelect';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import ChatGPTPersonalMessageDrawer from '../../../components/ClientEntry/ChatGPTPresetDrawer/ChatGPTPersonalMessageDrawer/ChatGPTPersonalMessageDrawer';
import PopupDraftEditor from '../../../components/PopupDraftEditor';
import { trackDirect } from '../../../store/analytics/api';

export const POLICY_INFO_TYPE = {
  POLICY_NO: 0,
  POLICY_NAME: 1
};

const GenerateButton = props => {
  const { buttonRows, onClick, loading, children, variant } = props;
  return (
    <Button
      variant={variant ?? "contained"}
      color="primary"
      className="new-export-button"
      style={{ width: buttonRows ? '100%' : undefined }}
      onClick={onClick}
      // disabled={!canRender || loading}
      disabled={loading}
    >
      {children}
    </Button>
  );
};

class EmergencyExportView extends PureComponent {
  constructor(props) {
    super(props);

    const remark = _.get(props, 'emergencyExport.remark');

    this.state = {
      selectName: true,
      selectPolicyInfo: true,
      selectPolicyInfoType: POLICY_INFO_TYPE.POLICY_NAME,
      selectPersonalPolicy: true,
      selectWorkplacePolicy: true,
      selectGeneralPolicy: true,
      selectMacauInfo: false,
      language: _.get(props, 'userDetails.language', 'en'),
      useTeamLogo: true,
      changeHeaderImage: false,
      showReferIcon: true,
      remark: remark
        ? formatEditorStateValue(remark)
        : formatEditorStateValue(getEditorDefaultMessage(props.intl.locale, 'export-remark')),
      informClientModal: false,
      messageLanguage: '',
      messageDefaultLanguage: 'en',
      editMessageLanguage: false,
      messageOption: 'upperMessage',
      defaultRemarkDialog: false,
      criOSDialog: false,
      hintDialog: false,
      popupDraftEditorOpen: false,
      popupDraftEditorRef: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleInformClientModal = this.handleInformClientModal.bind(this);
    this.translateUpperMessage = this.translateUpperMessage.bind(this);
    this.translateLowerMessage = this.translateLowerMessage.bind(this);
    this.clientMessages = this.clientMessages.bind(this);
    this.handleEditorStateChange = this.handleEditorStateChange.bind(this);
    this.onPopupDraftEditorSet = this.onPopupDraftEditorSet.bind(this);
    // this.updateRemark = this.updateRemark.bind(this);

    this.myEditorRef = React.createRef();
    this.myToolbarRef = React.createRef();
    this.popupDraftEditorRef = React.createRef();
  }

  componentDidUpdate(prevProp) {
    const prevRemark = _.get(prevProp, 'emergencyExport.remark');
    const thisRemark = _.get(this.props, 'emergencyExport.remark');
    if (prevRemark !== thisRemark) {
      this.setState({
        remark: thisRemark
          ? formatEditorStateValue(thisRemark)
          : formatEditorStateValue(getEditorDefaultMessage(this.props.intl.locale, 'export-remark'))
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { userDetails } = this.props;

    if (userDetails !== undefined && userDetails.name !== undefined && userDetails.userType !== undefined) {
      let name = userDetails.name;
      if (name.substr(name.length - 4) === '_One' && userDetails.userType === 'Adviser') {
        this.setState({ changeHeaderImage: true });
      } else {
        this.setState({ changeHeaderImage: false });
      }
    }
  }

  getExportOptions = () => {
    const { userDetails, emergencyExport } = this.props;
    const {
      selectName,
      selectPolicyInfo,
      selectPolicyInfoType,
      selectPersonalPolicy,
      selectWorkplacePolicy,
      selectGeneralPolicy,
      selectMacauInfo,
      language,
      useTeamLogo,
      changeHeaderImage,
      showReferIcon,
      remark
    } = this.state;

    const clientId = JSON.parse(window.localStorage.getItem('clientId'));

    return {
      language: language,
      data: emergencyExport,
      selectName: selectName,
      selectPolicyInfo: selectPolicyInfo,
      selectPolicyInfoType: selectPolicyInfoType,
      selectPersonalPolicy: selectPersonalPolicy,
      selectWorkplacePolicy: selectWorkplacePolicy,
      selectGeneralPolicy: selectGeneralPolicy,
      selectMacauInfo: selectMacauInfo,
      useTeamLogo: useTeamLogo,
      changeHeaderImage: changeHeaderImage,
      showReferIcon: showReferIcon,
      remark: encodeURIComponent(
        renderToString(<MyEditor readOnly={true} editorState={remark} textAlignment="center" />)
      ),
      referById: clientId
    };
  };

  generateImage = () => {
    this.outputFormat = 'png';
    this.generate();
  };
  generatePDF = () => {
    this.outputFormat = 'pdf';
    this.generate();
  };

  //Generate the export emergency detail
  generate = () => {
    this.saveRemark();
    const { userDetails } = this.props;
    const isAdviser = isAdviserType(userDetails);
    if (isAdviser) {
      this.openHintDialog();
    }

    // Tracking
    tracking(`Export policy: export ${this.outputFormat}`);

    this.setState({
      // loading: true,
      criOSDialog: isChromeIOS()
    });
  };

  onConfirmGenerate = async ({ password, isPrivateMode }) => {
    const { updateEmergencyExportInputs } = this.props;
    const stateObj = {};
    try {
      updateEmergencyExportInputs('loading', true);
      const options = this.getExportOptions();
      options.outputFormat = this.outputFormat || 'pdf';
      options.password = password;
      options.isPrivateMode = isPrivateMode;
      const pdfBlob = await getPDF('export', options);
      trackDirect("exportEmergencyReference");
      this.setState(stateObj);
      await NativeOrWeb.downloadFile(
        pdfBlob,
        `Summary_${(_.get(options, 'data.clientName') || 'Client').split(' ')[0]}_${moment().format('DDMMMYYYY')}.${options.outputFormat
        }`,
        options.outputFormat === 'pdf' ? 'application/pdf' : 'image/png'
      );
    } catch (error) {
      this.setState(stateObj);
      toast.error(error.message);
    } finally {
      updateEmergencyExportInputs('loading', false);
    }
  };

  //Render or show only the clicked items
  handleChange(event) {
    let obj = {
      // canRender: false
    };

    if (event.target.name === 'remark') {
      obj[event.target.name] = event.target.value;
    } else {
      obj[event.target.value] = event.target.checked;
    }

    this.setState(obj);
  }

  handleChangeSelect(event) {
    let obj = {
      [event.target.name]: event.target.value
    };

    this.setState(obj);
  }

  handleEditorStateChange(editorState) {
    this.setState({ remark: editorState });
    // console.log('handleEditorStateChange:: ', getPlainTextFromEditorState(this.state.remark));
  }

  handleInformClientModal() {
    this.setState({ informClientModal: true });
    // Tracking
    tracking('Export policy: Inform Client Modal Opened');
  }

  handleChangeLanugage(event) {
    const { value } = event.target;
    this.setState({ messageLanguage: value, editMessageLanguage: true });
  }

  selectLanguage = event => this.setState({ language: event.target.value });

  handleSelectMessage(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  translateUpperMessage(language) {
    // define new component state 'language' instead of using user's language setting
    // define overall message, to {} to indicate dynamic values
    let message1;
    // format message according to language state and using the mappings
    // clientName & insurerName are those dynamic values
    if (language === 'en') {
      message1 = EN_TRANSLATION.messages['upper-message-to-inform-client'];
    } else if (language === 'zh') {
      message1 = ZH_TRANSLATION.messages['upper-message-to-inform-client'];
    } else if (language === 'zh-Hant-HK') {
      message1 = ZH_HANT_HK_TRANSLATION.messages['upper-message-to-inform-client'];
    }
    return message1;
  }

  translateLowerMessage(language) {
    // define new component state 'language' instead of using user's language setting
    // define overall message, to {} to indicate dynamic values
    let message1;
    // format message according to language state and using the mappings
    // clientName & insurerName are those dynamic values
    if (language === 'en') {
      message1 = EN_TRANSLATION.messages['lower-message-to-inform-client'];
    } else if (language === 'zh') {
      message1 = ZH_TRANSLATION.messages['lower-message-to-inform-client'];
    } else if (language === 'zh-Hant-HK') {
      message1 = ZH_HANT_HK_TRANSLATION.messages['lower-message-to-inform-client'];
    }
    return message1;
  }

  clientMessages = () => {
    const { userDetails } = this.props;
    const { messageLanguage, editMessageLanguage, messageOption } = this.state;
    let messageDefaultLanguage = editMessageLanguage
      ? messageLanguage
      : userDetails && userDetails.language
        ? userDetails.language
        : 'en';
    let whatsAppMessage =
      messageOption === 'upperMessage'
        ? this.translateUpperMessage(messageDefaultLanguage)
        : this.translateLowerMessage(messageDefaultLanguage);
    return whatsAppMessage;
  };

  // updateRemark = (remark) => {
  //   this.setState({
  //     ...this.state,
  //     remark: remark
  //   })
  // }

  saveRemark = () => {
    const { updateEmergencyExport } = this.props;
    const { remark } = this.state;
    const clientId = JSON.parse(window.localStorage.getItem('clientId'));
    updateEmergencyExport(clientId, { remark: getSaveContentState(remark) });
  };

  changeDefaultRemark = () => {
    const { intl } = this.props;
    this.setState({
      remark: formatEditorStateValue(getEditorDefaultMessage(intl.locale, 'export-remark')),
      defaultRemarkDialog: false
    });
  };

  openDefaultRemarkDialog = () => this.setState({ defaultRemarkDialog: true });
  closeDefaultRemarkDialog = () => this.setState({ defaultRemarkDialog: false });

  closeCrIOSDialog = () => this.setState({ criOSDialog: false });
  openHintDialog = () => this.setState({ hintDialog: this.outputFormat });
  closeHintDialog = () => this.setState({ hintDialog: false });

  chatGPT = event => {
    event.stopPropagation();

    tracking('Click on ChatGPT - Export Emergency');

    const { toggleControl, viewingClient } = this.props;
    toggleControl('chatGPTPersonalMessageDrawer', { client: viewingClient });
  };

  onPopupDraftEditorSet = (v) => {
    // console.log('onPopupDraftEditorSet:: ', v);
    this.setState({
      ...this.state,
      popupDraftEditorOpen: v
    });
  }

  render() {
    const { intl, userDetails, teamCampaignCodes, location, width, loading } = this.props;
    const {
      language,
      selectName,
      selectPolicyInfo,
      selectPolicyInfoType,
      selectPersonalPolicy,
      selectWorkplacePolicy,
      selectGeneralPolicy,
      selectMacauInfo,
      useTeamLogo,
      showReferIcon,
      remark,
      informClientModal,
      editMessageLanguage,
      messageLanguage,
      messageOption,
      defaultRemarkDialog,
      criOSDialog,
      hintDialog
    } = this.state;
    let messageDefaultLanguage = editMessageLanguage
      ? messageLanguage
      : userDetails && userDetails.language
        ? userDetails.language
        : 'en';

    let clientEmail = location.state && location.state.clientDetail ? location.state.clientDetail.clientEmail : '';
    let contactNo = location.state && location.state.clientDetail ? location.state.clientDetail.clientWhatsApp : '';
    // console.log('location.state.clientDetail: ', location.state.clientDetail)

    const previewWidth = 780;
    // const previewMode = isWidthExceed(845);
    const previewParallelMode = isWidthExceed(1280);
    const buttonRows = width === 'xs' || (previewParallelMode && !isWidthExceed(1440));
    const showChatGPT = shouldEnableChatGPT({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

    return (
      <div>
        <PopupDraftEditor
          ref={this.popupDraftEditorRef}
          className="export-remark-input"
          open={this.state.popupDraftEditorOpen}
          currentRemark={remark}
          onChange={editorState => this.handleEditorStateChange(editorState)}
          myEditorRef={this.myEditorRef}
          toolbarRef={this.myToolbarRef}
          client={this.props.viewingClient}
          defaultRemarkGetter={() => { return getEditorDefaultMessage(this.props.intl.locale, 'export-remark') }}
          onSave={
            (remark) => {
              // this.updateRemark(remark)
              this.saveRemark()
              this.onPopupDraftEditorSet(false)
            }
          }
          onClose={(remark) => {
            this.onPopupDraftEditorSet(false)
          }}
          translations={{
            header: intl.formatMessage({ id: 'export-cus-message' })
          }}
          options={{
            ChatGPTPersonalMessage: shouldEnableChatGPT({ user: userDetails, teamCampaignCodes: teamCampaignCodes })
          }}
          controllers={{
            toggleControl: this.props.toggleControl
          }}
        />

        <Dialog open={criOSDialog} style={{ zIndex: 100000 }}>
          <DialogTitle>{intl.formatMessage({ id: 'cr-ios-dialog-title' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'cr-ios-dialog-content' })}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.closeCrIOSDialog}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
        <ChatGPTPersonalMessageDrawer />

        <ExportHintDialog
          open={hintDialog}
          onClose={this.closeHintDialog}
          onConfirm={this.onConfirmGenerate}
          shouldUsePassword={hintDialog === 'pdf'}
        />

        <AlertPopUp
          intl={intl}
          open={defaultRemarkDialog}
          handleClose={this.closeDefaultRemarkDialog}
          handleUpdateUserProfile={this.changeDefaultRemark}
          title={intl.formatMessage({ id: 'Confirm' })}
          description={intl.formatMessage({ id: 'adviser_message_delete' })}
          showUpdateButton={true}
        />

        <Dialog
          open={informClientModal}
          onClose={() => {
            this.setState({ informClientModal: false });
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Message to client' })}</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="client__sms">
                <FormControl>
                  <RadioGroup
                    aria-label="inputGroupOption"
                    name="messageOption"
                    value={messageOption}
                    onChange={this.handleSelectMessage.bind(this)}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      label={intl.formatMessage({ id: 'share-message-option-1' })}
                      className="text-left"
                      value={'upperMessage'}
                      control={<Radio color="primary" />}
                    />
                    <FormControlLabel
                      label={intl.formatMessage({ id: 'share-message-option-2' })}
                      className="text-left"
                      value={'lowerMessage'}
                      control={<Radio color="primary" />}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="analysis-input-groups">
              <FormControl>
                <LanguageRadioGroup
                  value={messageDefaultLanguage.toString()}
                  onChange={this.handleChangeLanugage.bind(this)}
                />
              </FormControl>
            </div>
            <ShareButtonGroup
              title={this.clientMessages()}
              whatsappNumber={contactNo}
              url=""
              isCTA
              CopyTextFieldProps={{
                multiline: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  informClientModal: false,
                  editMessageLanguage: false,
                  messageOption: 'upperMessage'
                });
              }}
              color="primary"
            >
              {intl.formatMessage({ id: 'Close' })}
            </Button>
          </DialogActions>
        </Dialog>

        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid emergency-view-padding">
              <AladdinHint
                title={intl.formatMessage({ id: 'aladdin-demo-video' })}
                helpCenter="c22"
                style={{ marginBottom: 16 }}
              />

              <ProRequiredCard sectionTitleId="Export Portfolio" />

              <Grid container wrap={previewParallelMode ? 'nowrap' : undefined}>
                <Grid
                  item
                  xs={!previewParallelMode ? 12 : undefined}
                  style={previewParallelMode ? { flexGrow: 1 } : undefined}
                >
                  <Paper className="export-input-div" elevation={1}>
                    <div>
                      <BlockingOverlay parentStyle={{ borderRadius: '8px', padding: '0 10px 10px' }}>
                        <FormControl component="fieldset" className="export-select-form">
                          <FormLabel component="legend">
                            {intl.formatMessage({ id: 'Select fields to export' })}
                          </FormLabel>
                          <FormGroup className="export-select-group">
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectName}
                                    onChange={this.handleChange}
                                    value="selectName"
                                  />
                                }
                                label={intl.formatMessage({ id: 'ClientName' })}
                              />
                            </FormControl>

                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectPersonalPolicy}
                                    onChange={this.handleChange}
                                    value="selectPersonalPolicy"
                                  />
                                }
                                label={intl.formatMessage({ id: 'Personal Policy' })}
                              />
                            </FormControl>

                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectWorkplacePolicy}
                                    onChange={this.handleChange}
                                    value="selectWorkplacePolicy"
                                  />
                                }
                                label={intl.formatMessage({ id: 'Workplace Policy' })}
                              />
                            </FormControl>

                            {userDetails.userType === 'Adviser' && (
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={useTeamLogo}
                                      onChange={this.handleChange}
                                      value="useTeamLogo"
                                    />
                                  }
                                  label={intl.formatMessage({
                                    id: 'use_export_logo'
                                  })}
                                />
                              </FormControl>
                            )}

                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectGeneralPolicy}
                                    onChange={this.handleChange}
                                    value="selectGeneralPolicy"
                                  />
                                }
                                label={intl.formatMessage({ id: 'General Policy' })}
                              />
                            </FormControl>
                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={showReferIcon}
                                    onChange={this.handleChange}
                                    value="showReferIcon"
                                  />
                                }
                                label={intl.formatMessage({ id: 'Refer QRcode' })}
                              />
                            </FormControl>
                            {/* Show Icon Control */}

                            <FormControl>
                              <div className="export-policy-info-type-input">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      checked={selectPolicyInfo}
                                      onChange={this.handleChange}
                                      value="selectPolicyInfo"
                                    />
                                  }
                                />
                                <TempSelect
                                  disabled={!selectPolicyInfo}
                                  name="selectPolicyInfoType"
                                  value={selectPolicyInfoType}
                                  onChange={this.handleChangeSelect}
                                >
                                  <MenuItem value={POLICY_INFO_TYPE.POLICY_NO}>
                                    {intl.formatMessage({ id: 'Policy No.' })}
                                  </MenuItem>
                                  <MenuItem value={POLICY_INFO_TYPE.POLICY_NAME}>
                                    {intl.formatMessage({ id: 'Policy Name' })}
                                  </MenuItem>
                                </TempSelect>
                              </div>
                            </FormControl>

                            <FormControl>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={selectMacauInfo}
                                    onChange={this.handleChange}
                                    value="selectMacauInfo"
                                  />
                                }
                                label={intl.formatMessage({ id: 'macau-info' })}
                              />
                            </FormControl>

                            <FormControl className="full-wid">
                              <LanguageRadioGroup value={language} onChange={this.selectLanguage} />
                            </FormControl>
                            <FormControl className="full-wid">
                              <GenerateButton
                                buttonRows={false}
                                onClick={() => this.onPopupDraftEditorSet(true)}
                                loading={loading}>
                                {loading ? (
                                  <CircularProgress size={24.5} />
                                ) : (`${intl.formatMessage({ id: 'edit-export-cus-message' })}`)}
                              </GenerateButton>
                            </FormControl>

                            {/* <ExpansionPanel className="full-wid">
                              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '0 12px' }}>
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item>
                                    <Typography>{intl.formatMessage({ id: 'export-cus-message' })}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <OutlinedBadge color="#ff9900" text="COT" />
                                  </Grid>
                                  {showChatGPT && (
                                    <Grid item>
                                      <IconButton color="primary" onClick={this.chatGPT} style={{ padding: '0 2px' }}>
                                        <img src="img/gptmain.svg" alt="chatgpt" width={22} height={22} />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails style={{ flexDirection: 'column', padding: 0 }}>
                                <Grid container justify="flex-end" style={{ marginBottom: 8 }}>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      className="plan-type-select-button selected"
                                      onClick={this.saveRemark}
                                      disabled={loading}
                                    >
                                      {loading ? <CircularProgress size={24.5} /> : intl.formatMessage({ id: 'Save' })}
                                    </Button>
                                  </Grid>

                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      className="plan-type-select-button selected"
                                      onClick={this.openDefaultRemarkDialog}
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <CircularProgress size={24.5} />
                                      ) : (
                                        intl.formatMessage({ id: 'Default' })
                                      )}
                                    </Button>
                                  </Grid>
                                </Grid>

                                <MyToolbar
                                  editorState={remark}
                                  onChange={editorState => this.handleEditorStateChange(editorState)}
                                  ref={this.myToolbarRef}
                                  editorRef={this.myEditorRef}
                                  undo={true}
                                  textAlign={{ left: true, center: true, right: true }}
                                  list={true}
                                />

                                <MyEditor
                                  wrapperClassName="export-remark-input"
                                  editorState={remark}
                                  textAlignment="center"
                                  onChange={editorState => this.handleEditorStateChange(editorState)}
                                  ref={this.myEditorRef}
                                  toolbarRef={this.myToolbarRef}
                                />
                              </ExpansionPanelDetails>
                            </ExpansionPanel> */}
                          </FormGroup>
                        </FormControl>
                      </BlockingOverlay>

                      <Grid
                        container
                        className="export-button-group"
                        justify="space-between"
                        style={{ padding: '0 10px' }}
                      >
                        <Grid item xs={buttonRows ? 12 : undefined}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="new-export-button"
                            style={{ width: buttonRows ? '100%' : undefined }}
                            onClick={this.handleInformClientModal}
                          >
                            {intl.formatMessage({ id: 'template-for-clients' })}
                          </Button>
                        </Grid>
                        <Grid item xs={buttonRows ? 12 : undefined}>
                          <Grid container spacing={1} wrap="nowrap">
                            <Grid item xs={buttonRows ? 6 : undefined}>
                              <GenerateButton
                                variant="outlined"
                                buttonRows={buttonRows}
                                onClick={this.generateImage}
                                loading={loading}>
                                {loading ? (
                                  <CircularProgress size={24.5} />
                                ) : (
                                  `${intl.formatMessage({ id: 'Export_action' })} ${intl.formatMessage({
                                    id: 'image'
                                  })}`
                                )}
                              </GenerateButton>
                            </Grid>
                            <Grid item xs={buttonRows ? 6 : undefined}>
                              <GenerateButton
                                variant="outlined"
                                buttonRows={buttonRows}
                                onClick={this.generatePDF}
                                loading={loading}>
                                {loading ? (
                                  <CircularProgress size={24.5} />
                                ) : (
                                  `${intl.formatMessage({ id: 'Export_action' })} PDF`
                                )}
                              </GenerateButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item style={{ padding: '0 8px', width: '100%' }}>
                  <div style={{ margin: '0 auto' }}>
                    <Typography color="textSecondary">{intl.formatMessage({ id: 'export-preview' })}</Typography>
                    <div style={{ overflow: 'auto' }}>
                      <div style={{ width: previewWidth + 10 + 16, margin: 'auto' }}>
                        <PreviewWrapper locale={language}>
                          <Export
                            onRemarkEdit={() => this.onPopupDraftEditorSet(true)}
                            options={this.getExportOptions()}
                            style={{ border: '3px solid #8c2fe2' }} />
                        </PreviewWrapper>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withWidth()(EmergencyExportView);
