import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import HotTopicItem from './HotTopicItem';
import { usePrevious } from '../../../../utils/hooks';
import { isMobile, isIPad13 } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { formatHotTopicContents } from '../../../../utils/hot-topic';
import animateScrollTo from 'animated-scroll-to';
import { changeLocationHash } from '../../../../utils/router';
import clsx from 'clsx';
import TagsRegion from './TagsRegion/TagsRegion';
import { LANGUAGE } from '../../../../constants/language';

const WrapperStyle = { padding: isMobile || isIPad13 ? '0px 5px' : '0 30px', textAlign: 'center' };
const CategoryWrapperStyles = isLargeScreen => ({
  display: 'flex',
  flexDirection: 'row',
  padding: isLargeScreen ? '0 15px' : '0'
});
const TitleStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '20px'
};
const MobileStyle = { display: 'flex', flexDirection: 'row', overflow: 'scroll' };
const RowStyle = { marginTop: 24 };
const SeeAllStyle = { color: '#9E9E9E', padding: '3px 6px 0px' };

const HotTopicContentSlides = ({
  intl,
  hotTopic,
  location,
  history,
  userDetails,
  subscriptions,
  className,
  SeeMoreProps,
  HotTopicItemProps,
  statistics,
  categoryViewPath,
  tagViewPath
}) => {
  const [contents, setContents] = useState(null);
  const [taggedContents, setTaggedContents] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 850);
  const [isIpadWidth, setIsIpadWidth] = useState(window.innerWidth > 767);
  const previousHash = usePrevious(location.hash);
  const previousIsLargeScreen = usePrevious(isLargeScreen);
  const initialHashSet = useRef(false);

  const sliderMapRef = useRef({});
  const itemMapRef = useRef({});
  const hotTopicItemMapRef = useRef({});

  const onToggleItem = (item, isOpen) => {
    if (isOpen) {
      changeLocationHash(item.id);
    } else {
      changeLocationHash('');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 767);
      setIsIpadWidth(window.innerWidth > 767);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const getContent = async () => {
      const data = _.get(hotTopic, 'data', []);

      const items = _.flatMap(data, category => category.items);
      const taggedContent = Object.values(
        items.reduce((acc, item) => {
          const tags = item?.tags || [];
          tags.forEach(tag => {
            const tagId = tag?.[intl.locale || LANGUAGE.ZH_HANT_HK];
            if (acc[tagId]) {
              acc[tagId].items.push(item);
            } else {
              acc[tagId] = {
                tag: tag,
                items: [item]
              };
            }
          });
          return acc;
        }, {})
      );

      const featuredItems = items
        .filter(item => item.isFeatured)
        .sort((a, b) => {
          if (b.createdAt && a.createdAt) {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          } else if (!a.createdAt) {
            return 1;
          } else if (!b.createdAt) {
            return -1;
          } else {
            return -1;
          }
        })
        .map(item => ({
          ...item,
          id: `featured-${item.id}`
        }));

      const dataWithFeatured =
        featuredItems.length > 0
          ? [
              {
                _id: 'featured',
                categoryName: {
                  en: '💎Featured💎',
                  'zh-Hant-HK': '💎推介💎',
                  zh: '💎推介💎'
                },
                items: featuredItems
              },
              ...data
            ]
          : data;

      const formattedContents = formatHotTopicContents(dataWithFeatured);

      setContents(formattedContents);
      setTaggedContents(taggedContent);
    };

    if (hotTopic && hotTopic.data && !initialHashSet.current) {
      getContent()
        .then()
        .catch();
    }
  }, [hotTopic, location]);

  useEffect(() => {
    if (location.hash && contents) {
      if (previousHash !== location.hash || !initialHashSet.current) {
        initialHashSet.current = true;

        let targetTabIndex;
        let targetItemIndex;
        let selectedPanelId;

        selectedPanelId = location.hash.replace('#', '');
        targetTabIndex = contents.findIndex(category => {
          const index = category.items.findIndex(item => item.id === selectedPanelId);
          if (index > -1) {
            targetItemIndex = index;
            return true;
          } else {
            return false;
          }
        });

        if (targetTabIndex < 0) {
          targetTabIndex = 0;
        }

        if (targetTabIndex > -1) {
          setTimeout(() => {
            const itemRef = itemMapRef.current[selectedPanelId];
            if (itemRef) {
              animateScrollTo(itemRef, { verticalOffset: -50 }).then(() => {});

              // need to scroll to the left if slider is not used
              if (isMobile || isIPad13) {
                const scrollParent = itemRef.parentElement;
                if (scrollParent) {
                  const boundingRect = itemRef.getBoundingClientRect();
                  scrollParent.scrollTo({
                    left: boundingRect.x,
                    behavior: 'smooth'
                  });
                }
              } else {
                const numSlides = isLargeScreen ? 5 : 3;
                const sliderRef = sliderMapRef.current[targetTabIndex];
                if (sliderRef) {
                  sliderRef.slickGoTo(Math.floor(targetItemIndex / numSlides) * numSlides);
                }
              }
            }

            const hotTopicItemRef = hotTopicItemMapRef.current[selectedPanelId];
            if (hotTopicItemRef && hotTopicItemRef.current) {
              hotTopicItemRef.current.openDialog();
            }

            // const targetTab = document.getElementById(`hot-topic-category-${targetTabIndex}`);
            // if (targetTab) {
            // 	// const topOffset = 0;
            // 	// const elementPosition = targetTab.getBoundingClientRect().top;
            // 	// const offsetPosition = elementPosition - topOffset;
            // 	animateScrollTo(targetTab)
            // 		.then()
            // 		.catch(console.log);
            // }
          }, 1);
        }
      }
    }
  }, [contents, isLargeScreen, location, previousHash]);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: isLargeScreen ? 5 : 3,
      slidesToScroll: isLargeScreen ? 5 : 3,
      swipe: false
    }),
    [isLargeScreen]
  );

  const onClickTag = ({ tag, items }) => {
    history.push({
      pathname: tagViewPath,
      state: {
        tagViewPath: tagViewPath,
        taggedContents: taggedContents,
        tag: tag,
        contents: {
          categoryName: tag,
          items
        },
        ...SeeMoreProps,
        statistics: statistics,
        HotTopicItemProps: HotTopicItemProps
      }
    });
  };

  return (
    <Fragment>
      <TagsRegion taggedContents={taggedContents} onClickTag={onClickTag} />
      <div style={WrapperStyle} className={clsx('hot-topic', className)}>
        {contents &&
          contents.map((category, index) => {
            const sliderRef = React.createRef();
            const isFeaturedCategory = category._id === 'featured';
            const items = category.items?.map((item, i) => {
              hotTopicItemMapRef.current[item.id] = React.createRef();
              return (
                <div
                  className={`hot-topic-item-${item.id}`}
                  key={`hot-topic-item-${item.id}`}
                  ref={ref => {
                    itemMapRef.current[item.id] = ref;
                  }}
                >
                  <HotTopicItem
                    intl={intl}
                    itemData={item}
                    sliderRef={sliderRef}
                    index={i}
                    isLargeScreen={isLargeScreen}
                    previousIsLargeScreen={previousIsLargeScreen}
                    history={history}
                    userDetails={userDetails}
                    subscriptions={subscriptions}
                    isIpadWidth={isIpadWidth}
                    onToggleItem={onToggleItem}
                    shouldShowStatistic={!!statistics}
                    statistic={statistics ? statistics[item.id] : undefined}
                    {...HotTopicItemProps}
                    ref={hotTopicItemMapRef.current[item.id]}
                  />
                </div>
              );
            });
            return (
              <div key={`hot-topic-category-${index}`} style={RowStyle}>
                <div style={CategoryWrapperStyles(isLargeScreen)}>
                  <div style={TitleStyle}>
                    <div
                      id={`hot-topic-category-${index}`}
                      className="hot-topic-category-title"
                      style={isFeaturedCategory ? { color: '#ff0000' } : undefined}
                    >
                      {category.categoryName[intl.locale]} ({items.length})
                    </div>
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: categoryViewPath,
                          state: {
                            contents: category,
                            ...SeeMoreProps,
                            statistics: statistics,
                            HotTopicItemProps: HotTopicItemProps
                          }
                        })
                      }
                      style={SeeAllStyle}
                    >
                      {intl.formatMessage({ id: 'See-All' })} ❯
                    </Button>
                  </div>
                </div>
                {isMobile || isIPad13 ? (
                  <div className="hot-topic-row-mobile" style={MobileStyle}>
                    {items}
                  </div>
                ) : (
                  <Slider {...settings} ref={ref => (sliderMapRef.current[index] = ref)}>
                    {items}
                  </Slider>
                )}
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

export default HotTopicContentSlides;
