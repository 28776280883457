import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState, Fragment } from 'react';
import { SlideTransition } from '../../Transition/Transition';
import ChooseLanguageMessages from '../../ChooseLanguageMessages/ChooseLanguageMessages';
import { getClientEmail, getClientWhatsapp } from '../utils';
import { translateMessage } from '../../../utils/locale';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';

const ShareConfirmDialog = props => {
  const { intl, shareConfirmDialog, toggleDialog } = props;

  const [language, setLanguage] = useState(props.intl.locale);

  const { client, closed, mode } = shareConfirmDialog || {};
  const email = getClientEmail(client);
  const whatsapp = getClientWhatsapp(client);
  const isConnectMode = mode === 'connect';

  const translatedMessage = translateMessage(isConnectMode ? 'sent-connect-msg' : 'sent-consent-msg', language, {
    email: email
  });

  const close = () => toggleDialog('shareConfirm', { ...shareConfirmDialog, closed: true });
  const onExited = () => toggleDialog('shareConfirm', false);
  const selectLanguage = event => setLanguage(event.target.value);
  // const copied = () => toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });

  return !shareConfirmDialog || !client ? null : (
    <Dialog
      open={!!shareConfirmDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Success' })}</DialogTitle>
      <DialogContent>
        {isConnectMode ? (
          <Typography style={{ whiteSpace: 'pre-wrap' }}>
            {intl.formatMessage({ id: 'connect-request-sent-msg' })}
          </Typography>
        ) : (
          <span>{intl.formatMessage({ id: 'Consent-sent' })}</span>
        )}

        <ChooseLanguageMessages
          titleIntlId="share-remind-msg"
          language={language}
          onChange={selectLanguage}
          translatedMessage={translatedMessage}
          LanguageRadioGroupProps={{
            CopyTextFieldProps: {
              whatsappNumber: whatsapp,
              multiline: true
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          {intl.formatMessage({ id: 'Close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  // Map state to props
  state => ({
    shareConfirmDialog: state.control.shareConfirmDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(ShareConfirmDialog);

export default injectIntl(container);
