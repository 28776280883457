import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, MenuItem } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NumberFormatTextField from '../../../../../../components/NumberFormatTextField/NumberFormatTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';
import './AveragePremiumSettingDialog.scss';
import { updateUserDetails } from '../../../../../../store/user/duck';
import { LOADING } from '../../../../../../constants/phase';
import { toast } from 'react-toastify';
import { getAveragePremiumSetting } from '../../../../../../utils/potential-business';
import TextField from '@material-ui/core/TextField';
import IconToast from '../../../../../../components/NewToast';
import LoadingBackdrop from '../../../../../../components/LoadingBackdrop/LoadingBackdrop';

const CONFIDENT_LEVELS = [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];

const CoverageTextField = props => {
  const { label, name, value, auto, onChange } = props;
  return (
    <NumberFormatTextField
      margin="normal"
      label={label}
      name={name}
      inputProps={{
        // prefix: '$',
        allowNegative: false
      }}
      InputLabelProps={{ shrink: value || value === 0 ? true : undefined }}
      value={value}
      onChange={!auto ? onChange : undefined}
      disabled={auto}
      className={`cov-text-field ${auto ? ' auto' : ''}`}
    />
  );
};

class AveragePremiumSettingDialog extends React.PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, updatePhase } = this.props;
    if (prevProps.updatePhase !== true && updatePhase === true) {
      toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), { className: 'new-toast' });
    }
  }

  close = () => this.props.mergeDialog('averagePremiumSetting', { closed: true });
  closed = () => this.props.toggleDialog('averagePremiumSetting', false);
  changeConfidentLevel = event => {
    if (event.target.value > 100) {
      event.target.value = 100;
    }
    this.changeText(event);
  };
  changeText = event =>
    this.props.mergeDialog('averagePremiumSetting', {
      [event.target.name]: event.target.value !== undefined ? event.target.value : null
    });
  switch = event => this.props.mergeDialog('averagePremiumSetting', { [event.target.name]: event.target.checked });
  save = () => {
    const { averagePremiumSettingDialog, updateUserDetails } = this.props;
    let picker = ['auto', 'confidentLevel'];

    if (!averagePremiumSettingDialog.auto) {
      picker = picker.concat(['life', 'medical', 'ci', 'saving', 'ilas']);
    }

    const data = _.pick(averagePremiumSettingDialog, picker);
    updateUserDetails(_.fromPairs(Object.entries(data).map(entry => [`averagePremiumSetting.${entry[0]}`, entry[1]])));
    this.close();
  };

  render() {
    const { intl, averagePremiumSettingDialog, updatePhase, averagePremiumData } = this.props;

    if (!averagePremiumSettingDialog) {
      return null;
    }

    const { closed, ...averagePremiumSetting } = averagePremiumSettingDialog;
    const formattedSetting = getAveragePremiumSetting(averagePremiumSetting, averagePremiumData);
    const { auto, confidentLevel, life, medical, ci, saving, ilas } = formattedSetting;

    return (
      <Fragment>
        {updatePhase === LOADING && <LoadingBackdrop open={true} />}
        <Dialog
          className="avg-prem-setting-dialog"
          open={!closed}
          onClose={this.close}
          onExited={this.closed}
          TransitionComponent={SlideTransition}
          maxWidth="xs"
          fullWidth={true}
        >
          <DialogTitle>{intl.formatMessage({ id: 'avg-prem-setting-dialog-title' })}</DialogTitle>
          <DialogContent>
            <Typography color="textSecondary">{intl.formatMessage({ id: 'avg-prem-setting-dialog-hint' })}</Typography>

            <form>
              <TextField
                className="cov-text-field"
                margin="normal"
                label={intl.formatMessage({ id: 'confident-lv' })}
                select
                name="confidentLevel"
                InputLabelProps={{ shrink: confidentLevel ? true : undefined }}
                value={confidentLevel}
                onChange={this.changeConfidentLevel}
              >
                {CONFIDENT_LEVELS.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}%
                  </MenuItem>
                ))}
              </TextField>

              <br />

              <FormControlLabel
                className="auto-switch"
                control={<Switch name="auto" color="primary" checked={auto} onChange={this.switch} />}
                label={intl.formatMessage({ id: 'Auto' })}
                labelPlacement="start"
              />

              <Fragment>
                <CoverageTextField
                  label={intl.formatMessage({ id: 'Life' })}
                  name="life"
                  value={life}
                  auto={auto}
                  onChange={this.changeText}
                />

                <CoverageTextField
                  label={intl.formatMessage({ id: 'Medical' })}
                  name="medical"
                  value={medical}
                  auto={auto}
                  onChange={this.changeText}
                />

                <CoverageTextField
                  label={intl.formatMessage({ id: 'C.I.' })}
                  name="ci"
                  value={ci}
                  auto={auto}
                  onChange={this.changeText}
                />

                <CoverageTextField
                  label={intl.formatMessage({ id: 'Saving' })}
                  name="saving"
                  value={saving}
                  auto={auto}
                  onChange={this.changeText}
                />

                <CoverageTextField
                  label={intl.formatMessage({ id: 'ILAS' })}
                  name="ilas"
                  value={ilas}
                  auto={auto}
                  onChange={this.changeText}
                />
              </Fragment>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close} color="primary">
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={this.save} color="primary" autoFocus>
              {intl.formatMessage({ id: 'Save' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    averagePremiumSettingDialog: state.control.averagePremiumSettingDialog,
    averagePremiumData: state.adviserDashboard.averagePremiumData,
    updatePhase: state.user.updatePhase
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    updateUserDetails
  }
)(AveragePremiumSettingDialog);

export default injectIntl(container);
