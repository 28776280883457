import React, { PureComponent } from 'react';
import InsuredPolicyView from './partials/component';
import InsurerInfoView from './partials/insurerInfo';
import InsurerDetailView from './partials/insurerDetail';
import { getCurrencyExchangeRate } from '../../utils/currencyExchange';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer.js';

class InsuredDetailsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };
  }

  UNSAFE_componentWillMount() {
    // eslint-disable-next-line no-restricted-globals
    window.scrollTo(0, 0);
    //this.props.currencyExchangeRate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    getCurrencyExchangeRate(nextProps.exchangeRate);
  }

  nextPage(page) {
    this.setState({ page: page + 1 });
  }

  previousPage(page) {
    this.setState({ page: page - 1 });
  }

  render() {
    return (
      <AppContentContainer>
        <div id="wrapper">
          {this.props.location.pathname === '/insured_details' && <InsuredPolicyView {...this.props} />}
          {this.props.location.pathname === '/insurer_info' && <InsurerInfoView {...this.props} />}
          {this.props.location.pathname === '/insurer_detail' && <InsurerDetailView {...this.props} />}
          {this.props.location.pathname === '/emergency_info' && <InsurerDetailView {...this.props} emergency={true} />}
        </div>
      </AppContentContainer>
    );
  }
}

export default InsuredDetailsView;
