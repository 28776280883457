import React from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import './DISCDialogComponent/DISCDialogComponent.scss';
import DISCDialogComponent from './DISCDialogComponent/DISCDialogComponent';

const DISCDialog = props => {
  const { DISCDialog, toggleDialog } = props;

  const { personality, contentTextType, closed, onSubmit, onClose } = DISCDialog || {};
  const isOpen = !!DISCDialog && !closed;

  const _onClose = () => {
    toggleDialog('DISC', { ...DISCDialog, closed: true });
    if (onClose) onClose();
  };

  const onExited = () => toggleDialog('DISC', false);

  return (
    <DISCDialogComponent
      open={isOpen}
      personality={personality}
      contentTextType={contentTextType}
      onClose={_onClose}
      onExited={onExited}
      onSubmit={onSubmit}
    />
  );
};

const container = connect(
  // Map state to props
  state => ({
    DISCDialog: state.control.DISCDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(DISCDialog);

export default container;
