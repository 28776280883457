import React, { useState, useEffect, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import FunctionCardsView from '../../../components/FunctionCard/FunctionCardsView';
import { HotLabel } from '../../AdviserHomePage/HotLabel/HotLabel';
import Badge from '@material-ui/core/Badge';
import { getNewReferralCount } from '../../../store/referral/duck';
import Grid from '@material-ui/core/Grid';
import './ClientAcquisition.scss';
import { Typography } from '@material-ui/core';
import { tracking } from '../../../utils/tracking';
import { toggleDialog } from '../../../store/control/duck';
import GridButtonLayout from '../../../components/GridButtonLayout/GridButtonLayout';
import CornerTriangle from '../../../components/CornerTriangle/CornerTriangle';
import NFCAutoGenerateDialog from '../Referral/partials/NFCAutoGenerateButton/NFCAutoGenerateDialog/NFCAutoGenerateDialog';
import { shouldEnableNFCAutoGen } from '../../../utils/user';

const isDevelopment = process.env.NODE_ENV !== 'production';
const ClientAcquisition = props => {
  const { intl, history, userDetails, teamCampaignCodes, newReferralCount, getNewReferralCount, toggleDialog } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const shouldShowNFC = shouldEnableNFCAutoGen({ user: userDetails, teamCampaignCodes: teamCampaignCodes });

  const onCloseDialog = () => setDialogOpen(false);

  const getItems = ({ intl, history }) => [
    {
      color: '#5fc3d2',
      imgSrc: 'img/showqrcode.png',
      imgAlt: 'qr-code',
      text: intl.formatMessage({ id: 'my-qr' }),
      subtitle: intl.formatMessage({ id: 'my-qr-desc' }),
      onClick: () => {
        tracking('open refer QRCode');
        toggleDialog('myQR', {});
      },
      blocking: true,
    },
    {
      color: '#3999f8',
      imgSrc: 'img/profile-plus_icon.png',
      imgAlt: 'profile+',
      text: <img src="img/profile-plus.png" alt="profile+" height="20" />,
      subtitle: intl.formatMessage({ id: 'ca-aip-desc' }),
      onClick: () => {
        tracking('Click on Profile+ - Lead Generation');
        history.push('/profile-plus');
      },
      extras: (
        <HotLabel
          text={intl.formatMessage({ id: 'hot' })}
          style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
        />
      ),
    },
    {
      color: '#2260a5',
      imgSrc: 'img/NFCicon.png',
      imgAlt: 'nfc-card',
      text: intl.formatMessage({ id: 'nfc-card' }),
      subtitle: intl.formatMessage({ id: 'nfc-card-desc' }),
      onClick: nfcCard,
      extras: (
        <Fragment>
          <CornerTriangle text="COT" backgroundColor="#ffc000" containerStyle={{ left: 6, borderRadius: 0 }} />
          <HotLabel
            text={intl.formatMessage({ id: 'hot' })}
            style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
          />
        </Fragment>
      ),
    },
    {
      color: '#453bd1',
      imgSrc: 'img/newuser.png',
      imgAlt: 'new-clients',
      text: (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography className="title" align="left">
              {intl.formatMessage({ id: 'New-Clients' })}
            </Typography>
          </Grid>
          {!!newReferralCount && (
            <Grid item>
              <Badge className="referral-count" badgeContent={newReferralCount} color="error" />
            </Grid>
          )}
        </Grid>
      ),
      subtitle: intl.formatMessage({ id: 'ca-new-client-desc' }),
      onClick: () => {
        tracking('Click on referral listing - Lead Generation');
        history.push({ pathname: '/referral_records' });
      },
      blocking: true,
    },
    isDevelopment && {
      //hide from production
      color: '#3966f8',
      imgSrc: 'img/person.png',
      imgAlt: 'client-matching',
      text: intl.formatMessage({ id: 'client-matching' }),
      subtitle: intl.formatMessage({ id: 'ca-client-matching-desc' }),
      disabled: true,
      onClick: () => {
        tracking('Click on client matching- Lead Generation ');
        history.push('/client-matching');
      },
    },
  ];

  const nfcCard = () => {
    tracking('Click on NFC Card - Lead Generation');
    history.push('/nfc-card');
  };

  const whatsappWizard = () => {
    tracking('Click on whatsapp wizard - Lead Generation');
    history.push('/whatsapp-wizard');
  };

  useEffect(() => {
    getNewReferralCount();
  }, [getNewReferralCount]);

  return (
    <Fragment>
      <NFCAutoGenerateDialog open={dialogOpen} onClose={onCloseDialog} />
      <FunctionCardsView
        className="client-acquisition"
        getItems={getItems}
        append={
          <GridButtonLayout
            items={[
              shouldShowNFC && {
                image: <img src="img/NFCicon.png" alt="nfc-card-settings" />,
                text: intl.formatMessage({ id: 'nfc-card-settings' }),
                onClick: () => { setDialogOpen(true) },
              },
              {
                image: <img src="img/showqrcode.png" alt="whatsapp-wizard" />,
                text: intl.formatMessage({ id: 'whatsapp-wizard' }),
                onClick: whatsappWizard,
              },
            ]}
          />
        }
      />
    </Fragment>
  );
};

const container = connect(
  (state) => ({
    userDetails: state.user.userDetails,
    newReferralCount: state.referral.newReferralCount,
    teamCampaignCodes: state.functions.campaignCodes,
  }),
  {
    getNewReferralCount,
    toggleDialog,
  },
)(ClientAcquisition);

export default injectIntl(container);
