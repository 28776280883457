import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { InputAdornment, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const NumberFormatWrapper = props => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      decimalSeparator="."
      thousandSeparator=","
      inputMode="decimal"
      pattern="[0-9],*"
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value !== '' ? parseFloat(values.value) : undefined
          }
        });
      }}
    />
  );
};

const CurrentAssetInput = props => {
  const { intl, value, onFocus, onValueChange, startAdornmentLabel, ...rest } = props;

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <TextField
      className="analysis-input"
      name="currentAsset"
      label={intl.formatMessage({ id: 'Current Asset' })}
      value={internalValue}
      onChange={event => {
        setInternalValue(event.target.value);
      }}
      type="text"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: NumberFormatWrapper,
        inputProps: {
          allowNegative: false,
          decimalScale: 1,
          isAllowed: values => {
            const { formattedValue, value } = values;
            return formattedValue === '' || value;
          },
          onBlur: event => {
            if (onValueChange !== undefined) {
              onValueChange({ target: { value: internalValue } });
            }
          }
        },
        startAdornment:
          startAdornmentLabel !== undefined ? (
            <InputAdornment position="start">{startAdornmentLabel}</InputAdornment>
          ) : (
            undefined
          ),
        className: 'analysis-input-text'
      }}
    />
  );
};

export default injectIntl(CurrentAssetInput);
