import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import DeleteIconButton from '../../../../components/DeleteIconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import _ from 'lodash';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import TodayIcon from '@material-ui/icons/Today';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer.js';
import { getAgeFromDob, getDerivedAge } from '../../../../utils/date';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import ExpenseCalculatorTriggerButton from '../../../../components/ExpenseCalculatorDialog/ExpenseCalculatorTriggerButton/ExpenseCalculatorTriggerButton';
import { sanitize } from 'dompurify';

class FactsheetDetailsFormTwo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      marriageStatus: '',
      child: '',
      monthlyIncome: '',
      dob: null,
      age: '',
      gender: '',
      smoker: '',
      monthlyExpense: '',
      liquidAsset: '',
      debt: '',
      Male: 'No',
      Female: 'No',
      Yes: 'No',
      No: 'No',
      keyArray: [],
      typeArray: [],
      expenses: undefined,
      err: {},
      modalIsOpen: false,
      editClientData: false,
      editFactsheet: false,
      askToDisableTracking: false,
      menu: true
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  setClientDetailsFromProps(checkLocalStorage, props) {
    const { userDetails, clientDetails, clientPersonalDetails } = props;

    let clientFactsheetDataTwo = JSON.parse(localStorage.getItem('clientFactsheetDataTwo'));

    let obj = {};

    if (clientDetails && !this.isEmpty(clientDetails)) {
      obj.editClientData = true;
    }

    if (checkLocalStorage && clientFactsheetDataTwo !== null) {
      obj.marriageStatus = clientFactsheetDataTwo.marriageStatus ? clientFactsheetDataTwo.marriageStatus : '';
      obj.child =
        clientFactsheetDataTwo.child !== null && clientFactsheetDataTwo.child !== undefined
          ? clientFactsheetDataTwo.child
          : '';
      obj.monthlyIncome =
        clientFactsheetDataTwo.monthlyIncome !== null && clientFactsheetDataTwo.monthlyIncome !== undefined
          ? clientFactsheetDataTwo.monthlyIncome
          : '';
      obj.monthlyExpense =
        clientFactsheetDataTwo.monthlyExpense !== null && clientFactsheetDataTwo.monthlyExpense !== undefined
          ? clientFactsheetDataTwo.monthlyExpense
          : '';
      obj.expenses =
        clientFactsheetDataTwo.expenses !== null && clientFactsheetDataTwo.expenses !== undefined
          ? clientFactsheetDataTwo.expenses
          : undefined;
      obj.liquidAsset =
        clientFactsheetDataTwo.liquidAsset !== null && clientFactsheetDataTwo.liquidAsset !== undefined
          ? clientFactsheetDataTwo.liquidAsset
          : '';
      obj.debt =
        clientFactsheetDataTwo.debt !== null && clientFactsheetDataTwo.debt !== undefined
          ? clientFactsheetDataTwo.debt
          : '';

      obj.dob =
        clientFactsheetDataTwo.dob !== null &&
        clientFactsheetDataTwo.dob !== '' &&
        clientFactsheetDataTwo.dob !== undefined
          ? moment.parseZone(clientFactsheetDataTwo.dob)
          : null;
      obj.age = getAgeFromDob(clientFactsheetDataTwo.dob, clientFactsheetDataTwo.age) || '';
      obj.gender = clientFactsheetDataTwo.gender ? clientFactsheetDataTwo.gender : '';
      obj.smoker = clientFactsheetDataTwo.smoker ? clientFactsheetDataTwo.smoker : '';
      if (clientFactsheetDataTwo.marriageStatus === 'Single') obj.Single = 'Yes';
      if (clientFactsheetDataTwo.marriageStatus === 'Married') obj.Married = 'Yes';
      if (clientFactsheetDataTwo.marriageStatus === 'None') obj.None = 'Yes';
      if (clientFactsheetDataTwo.gender === 'Male') obj.Male = 'Yes';
      if (clientFactsheetDataTwo.gender === 'Female') obj.Female = 'Yes';
      if (clientFactsheetDataTwo.smoker === 'Yes') obj.Yes = 'Yes';
      if (clientFactsheetDataTwo.smoker === 'No') obj.No = 'Yes';
    } else if (clientDetails) {
      obj.marriageStatus = clientDetails.marriageStatus ? clientDetails.marriageStatus : '';
      obj.child = clientDetails.child !== null && clientDetails.child !== undefined ? clientDetails.child : '';
      obj.monthlyIncome =
        clientDetails.monthlyIncome !== null && clientDetails.monthlyIncome !== undefined
          ? clientDetails.monthlyIncome
          : '';
      obj.monthlyExpense =
        clientDetails.monthlyExpense !== null && clientDetails.monthlyExpense !== undefined
          ? clientDetails.monthlyExpense
          : '';
      obj.expenses =
        clientDetails.expenses !== null && clientDetails.expenses !== undefined ? clientDetails.expenses : undefined;
      obj.liquidAsset =
        clientDetails.liquidAsset !== null && clientDetails.liquidAsset !== undefined ? clientDetails.liquidAsset : '';
      obj.debt = clientDetails.debt !== null && clientDetails.debt !== undefined ? clientDetails.debt : '';
      obj.dob =
        clientDetails.dob !== null && clientDetails.dob !== '' && clientDetails.dob !== undefined
          ? moment.parseZone(clientDetails.dob)
          : null;
      obj.age = getAgeFromDob(clientDetails.dob, clientDetails.age) || '';
      obj.gender = clientDetails.gender ? clientDetails.gender : '';
      obj.smoker = clientDetails.smoker ? clientDetails.smoker : '';
      if (clientDetails.marriageStatus === 'Single') obj.Single = 'Yes';
      if (clientDetails.marriageStatus === 'Married') obj.Married = 'Yes';
      if (clientDetails.gender === 'Male') obj.Male = 'Yes';
      if (clientDetails.gender === 'Female') obj.Female = 'Yes';
      if (clientDetails.smoker === 'Yes') obj.Yes = 'Yes';
      if (clientDetails.smoker === 'No') obj.No = 'Yes';
    }

    let val = clientPersonalDetails;
    const isInactive = val.inactiveUser !== undefined && val.inactiveUser === true ? true : false;
    if (isInactive && val.trackingId && val.trackingId.isActive) {
      obj.askToDisableTracking = true;
    } else {
      if (val.advisorDetails && val.advisorDetails.length > 0) {
        val.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.askToDisableTracking = true;
          }
          return true;
        });
      }
    }

    this.setState(obj);
  }

  UNSAFE_componentWillMount() {
    this.setClientDetailsFromProps(true, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deleteClientPhase !== true && nextProps.deleteClientPhase === true) {
      this.done();
    }
    if (nextProps.enableTrackingPhase === true) {
      let data = {};
      // if (nextProps.fetchDetailsPhase === true) {
      //   toast.success(intl.formatMessage({ id: nextProps.message }));
      // }
      const { clientPersonalDetails, userDetails } = this.props;
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            data.factsheetId = _.get(value, 'factsheetId', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        data.factsheetId = _.get(clientPersonalDetails, 'factsheetId', '');
      }
      data.userId = _.get(clientPersonalDetails, '_id', '');
      this.props.getClientDetails(data);
      this.setState({ askToDisableTracking: false });
      this.setClientDetailsFromProps(false, nextProps);
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleNumberKeyDown(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
      e.preventDefault();
    }
  }

  submitForm() {
    var formData = {};
    if (this.state.dob !== '' && this.state.dob !== undefined && this.state.dob !== null) {
      var abc = moment(this.state.dob).format('YYYY-MM-DD');
      let dob = moment.utc(abc).format('YYYY-MM-DDT00:00:00.00Z');
      formData.dob = dob;
    } else {
      formData.dob = null;
    }
    formData.marriageStatus = this.state.marriageStatus;
    formData.child = this.state.child;
    formData.monthlyIncome = parseInt(this.state.monthlyIncome);
    formData.monthlyExpense = parseInt(this.state.monthlyExpense);
    formData.expenses = this.state.expenses;
    formData.liquidAsset = this.state.liquidAsset;
    formData.debt = this.state.debt;
    formData.age = parseInt(this.state.age) ? parseInt(this.state.age) : '';
    formData.gender = this.state.gender;
    formData.smoker = this.state.smoker;
    localStorage.setItem('clientFactsheetDataTwo', JSON.stringify(formData));
    this.props.handleNextPage(3);
  }

  handlePrevious() {
    var formData = {};
    if (this.state.dob !== '' && this.state.dob !== undefined && this.state.dob !== null) {
      var abc = moment(this.state.dob).format('YYYY-MM-DD');
      let dob = moment.utc(abc).format('YYYY-MM-DDT00:00:00.00Z');
      formData.dob = dob;
    } else {
      formData.dob = null;
    }
    formData.marriageStatus = this.state.marriageStatus;
    formData.child = this.state.child;
    formData.monthlyIncome = this.state.monthlyIncome;
    formData.monthlyExpense = this.state.monthlyExpense;
    formData.expenses = this.state.expenses;
    formData.liquidAsset = this.state.liquidAsset;
    formData.debt = this.state.debt;
    formData.age = parseInt(this.state.age) ? parseInt(this.state.age) : '';
    formData.gender = this.state.gender;
    formData.smoker = this.state.smoker;
    localStorage.setItem('clientFactsheetDataTwo', JSON.stringify(formData));
    this.props.handlePreviousPage(3);
  }

  openModal() {
    this.setState({ modalIsOpen: true, menu: false });
  }

  deleteClient() {
    // if there is tracking, delete it too
    if (this.state.askToDisableTracking) {
      const { clientPersonalDetails, enableTracking, userDetails } = this.props;
      let obj = {};
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.trackingId = _.get(value, 'trackingId._id', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        obj.trackingId = _.get(clientPersonalDetails, 'trackingId._id', '');
      }

      obj.clientId = _.get(clientPersonalDetails, '_id', '');
      obj.trackingType = 'delete';
      enableTracking(obj);
    }
    var clientId = JSON.parse(localStorage.getItem('clientId'));
    let factsheet = this.props.clientDetails;
    let data = {};
    data.clientId = clientId;
    data.factsheetId = factsheet._id;
    this.props.deleteClient(data);
    this.setState({ modalIsOpen: false, menu: false });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      menu: true
    });
  }

  handleMarriageStatus(key) {
    const { marriageStatus } = this.state;
    let formattedKey = key;

    if (marriageStatus === key) formattedKey = '';

    this.setState({
      marriageStatus: formattedKey
    });
  }
  handleDateChange = date => {
    this.setState({ dob: date });
    let years = date ? getDerivedAge(date) : '';
    this.setState({ age: years });
  };
  handleNumberKeyDown = e => {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
      e.preventDefault();
    }
  };

  handleGenderStatus = key => {
    const state = this.state.keyArray;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({
          Male: 'Yes',
          Female: 'No',
          gender: 'Male'
        });
      }
      if (key === 1) {
        this.setState({
          Male: 'No',
          Female: 'Yes',
          gender: 'Female'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Male !== 'No') {
          this.setState({ Male: 'No', gender: '' });
        } else {
          this.setState({
            Male: 'Yes',
            Female: 'No',
            gender: 'Male'
          });
        }
      }
      if (key === 1) {
        if (this.state.Female !== 'No') {
          this.setState({ Female: 'No', gender: '' });
        } else {
          this.setState({
            Male: 'No',
            Female: 'Yes',
            gender: 'Female'
          });
        }
      }
    }
  };

  handleSmokerStatus = key => {
    const state = this.state.typeArray;
    if (state.indexOf(key) === -1) {
      this.setState({ typeArray: [...this.state.typeArray, key] });
      if (key === 0) {
        this.setState({
          No: 'Yes',
          Yes: 'No',
          smoker: 'No'
        });
      }
      if (key === 1) {
        this.setState({
          No: 'No',
          Yes: 'Yes',
          smoker: 'Yes'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.No !== 'No') {
          this.setState({ No: 'No', smoker: '' });
        } else {
          this.setState({
            No: 'Yes',
            Yes: 'No',
            None1: 'No',
            smoker: 'No'
          });
        }
      }
      if (key === 1) {
        if (this.state.Yes !== 'No') {
          this.setState({ Yes: 'No', smoker: '' });
        } else {
          this.setState({
            No: 'No',
            Yes: 'Yes',
            None1: 'No',
            smoker: 'Yes'
          });
        }
      }
    }
  };

  done = () => {
    const { history, location } = this.props;
    const replaceRoute = _.get(location, 'state.replaceRoute');
    if (replaceRoute) {
      history.replace(replaceRoute);
    } else {
      history.goBack();
    }
  };

  onGoBack = () => {
    const { history, toggleDialog } = this.props;
    toggleDialog('discardWarning', {
      onConfirm: () => {
        sessionStorage.removeItem('referralRecordId');
        history.goBack();
      }
    });
  };

  render() {
    const { intl, clientDetails, clientPersonalDetails } = this.props;
    const maxDate = new Date();
    const clientId = JSON.parse(localStorage.getItem('clientId'));
    const isInactive =
      clientPersonalDetails.inactiveUser !== undefined && clientPersonalDetails.inactiveUser === true ? true : false;
    let DeleteMessage = !isInactive
      ? // ? 'Deleting the user would remove all linkage with the users and data can’t be <br/> recovered. Are You Sure?'
        // : 'Deleting inactive user would remove all the user data inputted. ';
        intl.formatMessage({
          id: 'delete_all_data'
        })
      : intl.formatMessage({ id: 'delete_active_user_message' });
    return (
      <AppContentContainer>
        <div>
          <TopNavigationPortal>
            <span onClick={this.onGoBack} className="back_arrow">
              <ArrowBackIcon />
            </span>
            {(this.state.editFactsheet || this.state.editClientData) && (
              <DeleteIconButton className="edit__pen" handleClick={this.openModal.bind(this)} />
            )}
          </TopNavigationPortal>
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="msform" noValidate={true}>
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                        <li className="active" />
                        <li />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Factsheet' })}</h2>
                        <p>{intl.formatMessage({ id: 'Fill-up-the-information-for-A.I' })}</p>

                        <div className="select-buttons-inline-form-control">
                          <div className="control-orphay select-buttons-inline-label violet__label">
                            {intl.formatMessage({ id: 'Gender' })}
                          </div>
                          <div className="select-buttons-inline-div">
                            <Button
                              variant={this.state.Male === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Male === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={0}
                              onClick={() => this.handleGenderStatus(0)}
                            >
                              {intl.formatMessage({ id: 'Male' })}
                            </Button>

                            <Button
                              variant={this.state.Female === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Female === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={1}
                              onClick={() => this.handleGenderStatus(1)}
                            >
                              {intl.formatMessage({ id: 'Female' })}
                            </Button>
                          </div>
                        </div>
                        <div className="select-buttons-inline-form-control">
                          <div className="control-orphay select-buttons-inline-label violet__label">
                            {intl.formatMessage({ id: 'Smoker' })}
                          </div>
                          <div className="select-buttons-inline-div">
                            <Button
                              variant={this.state.No === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.No === 'Yes' ? 'plan-type-select-button selected' : 'plan-type-select-button'
                              }
                              key={0}
                              onClick={() => this.handleSmokerStatus(0)}
                            >
                              {intl.formatMessage({ id: 'No' })}
                            </Button>

                            <Button
                              variant={this.state.Yes === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Yes === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={1}
                              onClick={() => this.handleSmokerStatus(1)}
                            >
                              {intl.formatMessage({ id: 'Yes' })}
                            </Button>
                          </div>
                        </div>

                        <div className="select-buttons-inline-form-control">
                          <div className="control-orphay select-buttons-inline-label status-label-res violet__label">
                            {intl.formatMessage({ id: 'Marriage Status' })}
                          </div>
                          <div className="select-buttons-inline-div select-buttons-inline-res">
                            <Button
                              variant={this.state.marriageStatus === 'Single' ? 'contained' : 'outlined'}
                              className={
                                this.state.marriageStatus === 'Single'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={0}
                              onClick={() => this.handleMarriageStatus('Single')}
                            >
                              {intl.formatMessage({ id: 'Single' })}
                            </Button>

                            <Button
                              variant={this.state.marriageStatus === 'Married' ? 'contained' : 'outlined'}
                              className={
                                this.state.marriageStatus === 'Married'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={1}
                              onClick={() => this.handleMarriageStatus('Married')}
                            >
                              {intl.formatMessage({ id: 'Married' })}
                            </Button>
                          </div>
                        </div>
                        <div className="clearfix" />
                        <div className="select-buttons-inline-form-control ">
                          <div className="control-orphay select-buttons-inline-label status-label-res violet__label">
                            {intl.formatMessage({ id: 'No. of Child' })}
                          </div>
                          <div className="select-buttons-inline-div select-buttons-inline-res">
                            <Button
                              variant={this.state.child !== null ? 'contained' : 'outlined'}
                              className={
                                this.state.child === null || this.state.child === ''
                                  ? 'plan-type-select-button child-sel-btn child-btn-res'
                                  : 'plan-type-select-button selected child-sel-btn child-btn-res'
                              }
                              key={0}
                              onClick={() =>
                                this.setState({
                                  child:
                                    this.state.child === '0' ||
                                    this.state.child === 0 ||
                                    this.state.child === null ||
                                    this.state.child === ''
                                      ? null
                                      : (
                                          parseInt(
                                            this.state.child === '' ||
                                              this.state.child === '0' ||
                                              this.state.child === 0
                                              ? '1'
                                              : this.state.child
                                          ) - 1
                                        ).toString()
                                })
                              }
                            >
                              -
                            </Button>

                            <span style={{ padding: '5px 12px 2px 12px' }}>{this.state.child}</span>
                            <Button
                              variant={this.state.child !== '9' ? 'contained' : 'outlined'}
                              className={
                                this.state.child !== '9'
                                  ? 'plan-type-select-button selected child-sel-btn child-btn-res'
                                  : 'plan-type-select-button child-sel-btn child-btn-res'
                              }
                              key={1}
                              onClick={() =>
                                this.setState({
                                  child:
                                    this.state.child === '9'
                                      ? '9'
                                      : (
                                          parseInt(
                                            this.state.child === '' || this.state.child === null
                                              ? '0'
                                              : this.state.child
                                          ) + (this.state.child === null || this.state.child === '' ? 0 : 1)
                                        ).toString()
                                })
                              }
                            >
                              +
                            </Button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group datepicker-group label___color--date">
                              <DatePicker
                                name="dob"
                                maxDate={maxDate}
                                format="DD-MMM-YYYY"
                                clearable={true}
                                value={
                                  this.state.dob === undefined || this.state.dob === null || this.state.dob === ''
                                    ? null
                                    : moment.parseZone(
                                        this.state.dob
                                      ) /*moment.parseZone is equivalent to parsing the string and using moment utcoffset and to handle the date for -utc as well as it works for +utc*/
                                }
                                openTo="year"
                                onChange={date => this.handleDateChange(date)}
                                autoOk={true}
                                initialFocusedDate={moment().subtract(30, "years")}
                                label={intl.formatMessage({
                                  id: 'Date of Birth'
                                })}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton style={{ padding: '0 5px 0 0' }}>
                                        <TodayIcon color="action" style={{ fontSize: 20 }} />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="number"
                                name={
                                  this.state.dob === undefined || this.state.dob === null || this.state.dob === ''
                                    ? 'age'
                                    : ''
                                }
                                value={this.state.age}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                                min="0"
                                pattern="[0-9]*"
                                onKeyDown={this.handleNumberKeyDown.bind(this)}
                                disabled={!!this.state.dob}
                              />
                              <label className="control-label violet__label" htmlFor="input">
                                {intl.formatMessage({ id: 'Age' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        </div>

                        {/* <div className="form-group">
                        <input
                          type="number"
                          name="child"
                          value={this.state.child}
                          onChange={this.handleChange.bind(this)}
                          min="0"
                          pattern="[0-9]*"
                          onKeyDown={this.handleNumberKeyDown.bind(this)}
                          required="required"
                        />
                        <label className="control-label" htmlFor="input">
                          No. of Child
                        </label>
                        <i className="bar" />
                      </div> */}
                        <div className="row">
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="monthlyIncome"
                                thousandSeparator={true}
                                value={this.state.monthlyIncome}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ monthlyIncome: value });
                                }}
                                min="0"
                                //pattern="[0-9]*"
                                onKeyDown={this.handleNumberKeyDown.bind(this)}
                                required="required"
                              />
                              <label className="control-label label-res violet__label" htmlFor="input">
                                {intl.formatMessage({ id: 'Monthly Income' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="monthlyExpense"
                                thousandSeparator={true}
                                value={this.state.monthlyExpense}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ monthlyExpense: value });
                                }}
                                min="0"
                                // pattern="[0-9]*"
                                onKeyDown={this.handleNumberKeyDown.bind(this)}
                                required="required"
                              />
                              <label className="control-label label-res violet__label" htmlFor="input">
                                {intl.formatMessage({ id: 'Monthly Expense' })}
                              </label>
                              <i className="bar" />
                              <ExpenseCalculatorTriggerButton
                                clientId={clientId}
                                factsheetId={clientDetails && clientDetails._id}
                                expenses={this.state.expenses}
                                onSubmit={(total, expenses) =>
                                  this.setState({ monthlyExpense: total, expenses: expenses })
                                }
                                style={{ position: 'absolute', right: 0, bottom: 8 }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="liquidAsset"
                            value={this.state.liquidAsset}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label violet__label" htmlFor="input">
                            {intl.formatMessage({ id: 'Liquid Asset' })}
                          </label>
                          <i className="bar" />
                        </div>
                        <div className="form-group">
                          <NumberFormat
                            //type="number"
                            //name="debt"
                            thousandSeparator={true}
                            value={parseInt(this.state.debt)}
                            onValueChange={values => {
                              const { value } = values;
                              this.setState({ debt: value });
                            }}
                            min="0"
                            //pattern="[0-9]*"
                            onKeyDown={this.handleNumberKeyDown.bind(this)}
                            required="required"
                          />
                          <label className="control-label violet__label" htmlFor="input">
                            {intl.formatMessage({ id: 'Debt' })}
                          </label>
                          <i className="bar" />
                        </div>
                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className="prevNextButton"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              className="prevNextButton"
                              endIcon={<ArrowForwardIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  </div>
                  <Dialog
                    open={this.state.modalIsOpen}
                    onClose={this.closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
                    <DialogContent>
                      <div dangerouslySetInnerHTML={{ __html: sanitize(DeleteMessage) }} />

                      {this.state.askToDisableTracking && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(intl.formatMessage({ id: 'delete_the_tracking' }))
                          }}
                        />
                      )}
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={this.closeModal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.deleteClient.bind(this)} color="primary" autoFocus>
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

export default FactsheetDetailsFormTwo;
