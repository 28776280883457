import { fetch } from '../../utils';
import Backend from '../../backend';

export const createConnectionRequest = inactiveUserId => {
  return fetch(Backend.methodUrl('connection-requests'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ inactiveUserId: inactiveUserId })
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const resendConnectionRequest = connectionRequestId => {
  return fetch(Backend.methodUrl(`connection-requests/${connectionRequestId}/resend`))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const getConnectionRequest = connectionRequestId => {
  return fetch(Backend.methodUrl(`connection-requests/${connectionRequestId}`))
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const acceptConnectionRequest = connectionRequestId => {
  return fetch(Backend.methodUrl(`connection-requests/${connectionRequestId}`), {
    method: 'PATCH'
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};

export const deleteConnectionRequest = connectionRequestId => {
  return fetch(Backend.methodUrl(`connection-requests/${connectionRequestId}`), {
    method: 'DELETE'
  })
    .then(response => response.json())
    .then(payload => payload)
    .catch(error => {
      throw error;
    });
};
