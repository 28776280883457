import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isReachedBottom, scrollIt } from '../../utils/scroll';

const Style = {
  position: 'fixed',
  bottom: 10,
  right: 10,
  opacity: 0.5,
  minWidth: 'unset',
  borderRadius: '50%',
  width: 36,
  height: 36,
  zIndex: 1
};

const getScrollElementCurrent = scrollElement => {
  if (scrollElement && scrollElement.current) {
    if (typeof scrollElement.current === 'function') {
      return scrollElement.current();
    } else {
      return scrollElement.current;
    }
  }
  return null;
};

const ScrollBottomButton = props => {
  const { className, style, scrollElement, ...rest } = props;
  const [scrolling, setScrolling] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(isReachedBottom(scrollElement.current));

  const onClick = async () => {
    if (!scrolling) {
      const current = getScrollElementCurrent(scrollElement);
      if (current) {
        setScrolling(true);
        const reachedB = await new Promise(resolve => {
          if (!reachedBottom) {
            scrollIt(current, 300, 10, reached => resolve(reached.reachedBottom));
          } else {
            scrollIt(current, -current.scrollHeight, 2, reached => resolve(reached.reachedBottom));
          }
        });
        setReachedBottom(reachedB);
        setScrolling(false);
      }
    }
  };

  useEffect(() => {
    const listener = () => {
      const current = getScrollElementCurrent(scrollElement);
      if (current) {
        const reactedB = isReachedBottom(current);
        if (reactedB !== reachedBottom) {
          setReachedBottom(reactedB);
        }
      }
    };

    const current = getScrollElementCurrent(scrollElement);
    if (current) {
      current.addEventListener('scroll', listener);
    }
    window.addEventListener('scroll', listener);

    return () => {
      const current = getScrollElementCurrent(scrollElement);
      if (current) {
        current.removeEventListener('scroll', listener);
      }
      window.removeEventListener('scroll', listener);
    };
  }, [reachedBottom, scrollElement]);

  return (
    <Button
      className={className}
      color="primary"
      variant="contained"
      onClick={onClick}
      style={{
        ...Style,
        ...style
      }}
      {...rest}
    >
      {!reachedBottom ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
    </Button>
  );
};

export default ScrollBottomButton;
