/* eslint-disable no-unreachable */
import React from 'react';
import ReactDOM from 'react-dom';
import SwipeableViews from 'react-swipeable-views';

export default class MySwipeableViews extends React.Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  // componentWillReceiveProps(nextProps) {
  //     let filteredChildren = [];
  //     React.Children.forEach(nextProps.children, (child) => {
  //         if (child !== undefined && child !== null){
  //             filteredChildren.push(child);
  //         }
  //     });
  //     this.setState({filteredChildren: filteredChildren});
  // }

  componentDidMount() {
    // console.log('MySwipeableViews componentDidMount');
    // this.adjustSlideHeights();
  }

  componentDidUpdate() {
    // console.log('MySwipeableViews componentDidUpdate');
    // this.adjustSlideHeights();
  }

  adjustSlideHeights = () => {
    if (this.container && this.container.current && this.container.current.updateHeight) {
      this.container.current.updateHeight();
    }
    return;

    let currentIndex = this.props.index;
    // this.container.current.updateHeight();
    // return;

    let slideNodes = this.props.children.map((child, index) => {
      return ReactDOM.findDOMNode(this.refs[index]);
    });

    // console.log('slideNodes', slideNodes);

    let partitioned = slideNodes.reduce(
      (accumulator, slideNode, index) => {
        accumulator[currentIndex === index ? 0 : 1].push(slideNode);
        return accumulator;
      },
      [[], []]
    );

    // console.log('partitioned', partitioned);

    let current = partitioned[0][0];
    let others = partitioned[1];

    // console.log('current', current);
    // console.log('current.offsetHeight', current.offsetHeight);

    if (current !== undefined) {
      if (current.style.height !== '' && this.props.savingAnnuityHeight === undefined) {
        current.style.height = 'fit-content';
      }

      let targetHeight = current.offsetHeight;

      // console.log('targetHeight', targetHeight);

      if (others !== null && others !== undefined && others.length > 0) {
        others.forEach(elem => {
          if (this.props.savingAnnuityHeight === undefined) {
            elem.style.height = targetHeight + 'px';
            // console.log(elem, elem.style.height);
          }
        });
      }

      if (this.props.savingAnnuityHeight === undefined) {
        current.style.height = targetHeight + 'px';
        // current.closest('.react-swipeable-view-container').style.height = targetHeight + 'px';
      }
    }
  };

  // handleException() {
  // 	// handle some exceptions
  // 	console.log(event)
  // 	console.log(document.querySelector('.rc-slider').contains(event.target))
  // 	if (document.querySelector('.rc-slider').contains(event.target)) {
  // 		event.stopPropagation();
  // 	}
  // }

  render() {
    const {
      index,
      onChangeIndex,
      children,
      onSwitching,
      onTransitionEnd,
      threshold,
      disabled,
      enableMouseEvents,
      className,
      style,
      slideStyle
    } = this.props;

    return (
      <SwipeableViews
        className={className}
        ref={this.container}
        disabled={disabled}
        index={index}
        onChangeIndex={index => {
          if (onChangeIndex !== undefined && typeof onChangeIndex === 'function') {
            onChangeIndex(index);
          }
        }}
        onSwitching={onSwitching}
        onTransitionEnd={onTransitionEnd}
        enableMouseEvents={enableMouseEvents !== undefined ? enableMouseEvents : true}
        disableLazyLoading={true}
        ignoreNativeScroll={true}
        threshold={threshold}
        animateHeight={true}
        style={style}
        slideStyle={slideStyle}
      >
        {React.Children.map(children, (element, idx) => {
          if (element) {
            return React.cloneElement(element, { ref: idx });
          } else {
            return '';
          }
        })}
      </SwipeableViews>
    );
  }
}
