import React from 'react';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { Dialog } from '@material-ui/core';
import WhatsappQRCode from '../WhatsappQRCode/WhatsappQRCode';

const WhatsappQRCodeDialog = props => {
  const { open, onClose, whatsapp, url, ...rest } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="whatsapp-qrcode-dialog"
      TransitionComponent={SlideTransition}
      fullWidth={true}
      maxWidth="xs"
      {...rest}
    >
      <WhatsappQRCode whatsapp={whatsapp} url={url} />
    </Dialog>
  );
};

export default WhatsappQRCodeDialog;
