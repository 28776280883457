import React from 'react';
import { connect } from 'react-redux';
import { SlideTransition } from '../../../components/Transition/Transition';
import { Dialog, DialogContent } from '@material-ui/core';
import { toggleDialog } from '../../../store/control/duck';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './IFrameDialog.scss';
import CustomTopNavigation from '../../../components/CustomTopNavigation/CustomTopNavigation';

const IFrameDialogContentStyle = {
  padding: 0
};

const IFrameStyle = {
  width: '100%',
  height: '100%',
  border: 'none'
};

const IFrameDialog = props => {
  const { iframeDialog, toggleDialog } = props;

  const { closed, title, url } = iframeDialog || {};

  const close = () => {
    toggleDialog('iframe', { ...iframeDialog, closed: true });
  };
  const onClosed = () => toggleDialog('iframe', false);

  return (
    <Dialog
      className="iframe-dialog"
      open={!!iframeDialog && !closed}
      fullScreen={true}
      TransitionComponent={SlideTransition}
      onExited={onClosed}
    >
      <CustomTopNavigation title={title}>
        <span onClick={close} className="back_arrow">
          <ArrowBackIcon />
        </span>
      </CustomTopNavigation>
      <DialogContent style={IFrameDialogContentStyle}>
        <iframe title={title} src={url} style={IFrameStyle} />
      </DialogContent>
    </Dialog>
  );
};

const container = connect(
  state => ({
    iframeDialog: state.control.iframeDialog
  }),
  {
    toggleDialog
  }
)(IFrameDialog);

export default container;
