import React from 'react';
import { getUsedTagColor } from '../../../../utils/tag';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../../store/control/duck';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

class TagsControl extends React.PureComponent {
  openTagsDialog = () => {
    const { tags, toggleDialog, onChange } = this.props;
    toggleDialog('tags', { tags: tags, savedTags: tags, onClose: tags => onChange(tags) });
  };

  render() {
    const { intl, tags, userDetails, style, toggleDialog, onChange, addTagButton, ...rest } = this.props;

    const usedTags = _.get(userDetails, 'usedTags', []);

    return tags ? (
      <div
        className="tagsClass react-tags-wrapper"
        style={_.merge({ margin: '0 0 0 50px' }, style)}
        onClick={this.openTagsDialog}
        {...rest}
      >
        <div className="ReactTags__selected">
          {tags.length > 0 ? (
            tags.map(value => {
              return (
                <span
                  key={value.text}
                  className="tag-wrapper ReactTags__tag-no-remove"
                  draggable="true"
                  style={{ color: getUsedTagColor(usedTags, value) }}
                >
                  {value.text}
                </span>
              );
            })
          ) : addTagButton ? (
            <span
              className="tag-wrapper ReactTags__tag-no-remove"
              style={{ color: '#3966f8' }}
              onClick={this.openTagsDialog}
            >
              + {intl.formatMessage({ id: 'Tag' })}
            </span>
          ) : (
            <input disabled="isDisabled" type="text" name="label" onClick={this.openTagsDialog} required="required" />
          )}
        </div>
      </div>
    ) : null;
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(TagsControl);

export default injectIntl(container);
