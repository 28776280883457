import { injectIntl } from 'react-intl';
import React, { PureComponent } from 'react';
import './FactSheetField.css';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment';

class FactSheetField extends PureComponent {
  render() {
    const {
      intl,
      isHead,
      leftCSS,
      inputComponent,
      leftTitle,
      errorField,
      inputType,
      editValue,
      editValueOnChange,
      updateFactSheetPhase,
      isEditing,
      unEditOnChange,
      displayValue,
      saveBtnOnClick,
      cancelBtnOnClick,
      editInputExtras
    } = this.props;
    return (
      <li className={isHead ? 'head' : ''}>
        <div className={leftCSS ? leftCSS : 'left-title'}>{leftTitle}</div>
        <div className="left-value">
          {isEditing ? (
            <div className="button-option-wrapper">
              <div style={{ position: 'relative' }}>
                {inputComponent ? (
                  inputComponent
                ) : inputType === 'currency' ? (
                  <NumberFormat
                    thousandSeparator={true}
                    className="left-value-input"
                    value={editValue}
                    onValueChange={editValueOnChange}
                  />
                ) : (
                  <input
                    type="text"
                    name="name"
                    className="left-value-input"
                    value={editValue}
                    onChange={editValueOnChange}
                  />
                )}
                {editInputExtras && editInputExtras}
              </div>
              {errorField !== undefined && <div className="error_field">{errorField} </div>}
              <div className="save-cancel-btn-grp">
                <Button
                  color="primary"
                  variant="contained"
                  className="factsheet-edit-button"
                  onClick={cancelBtnOnClick}
                >
                  {intl.formatMessage({ id: 'Cancel' })}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="factsheet-edit-button"
                  disabled={updateFactSheetPhase === 'loading' ? true : false}
                  onClick={saveBtnOnClick}
                >
                  {intl.formatMessage({ id: 'Save' })}
                </Button>
              </div>
            </div>
          ) : (
            <div className="fs-value" onClick={unEditOnChange}>
              {displayValue ? (
                inputType === 'currency' ? (
                  parseInt(displayValue)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                ) : inputType === 'formatMessage' ? (
                  intl.formatMessage({ id: displayValue })
                ) : inputType === 'date' ? (
                  displayValue !== null && displayValue !== undefined && displayValue !== '' ? (
                    moment.parseZone(displayValue).format('DD-MMM-YYYY')
                  ) : null
                ) : (
                  displayValue
                )
              ) : (
                <span className="left-null-value" onClick={unEditOnChange}>
                  {intl.formatMessage({ id: 'N/A' })}
                </span>
              )}
              <EditIcon className="fs-value-edit-icon" />
            </div>
          )}
        </div>
      </li>
    );
  }
}

export default injectIntl(FactSheetField);
