import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@material-ui/core';

const Style = {
  minWidth: 'unset',
  minHeight: 'unset',
  fontSize: 11,
  lineHeight: 1,
  padding: 4,
  background: '#fff',
  boxShadow: 'var(--my-shadow)'
};

const CreateOneForFreeButton = props => {
  const { intl, href, style } = props;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <Button variant="outlined" color="primary" fullWidth={true} style={{ ...Style, ...style }}>
        {intl.formatMessage({ id: 'create-one-for-free' })}
      </Button>
    </a>
  );
};

export default injectIntl(CreateOneForFreeButton);
