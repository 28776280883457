import { connect } from 'react-redux';
import { handleSignOut, updateUserDetails, getUser } from '../../../store/user/duck';
import { getNewReferralCount } from '../../../store/referral/duck';
import DefaultWhatUpMessageView from './component';
import { injectIntl } from 'react-intl';
import { updateAdviserBusiness, fetchAdviserBusiness } from '../../../store/adviserDashboard/duck';

const DefaultMessage = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    newReferralCount: state.referral.newReferralCount,
    updatePhase: state.user.updatePhase,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    updateMessagePhase: state.adviserDashboard.updateMessagePhase
  }),
  // Map actions to props
  {
    handleSignOut,
    getNewReferralCount,
    updateUserDetails,
    fetchAdviserBusiness,
    updateAdviserBusiness,
    getUser
  }
)(DefaultWhatUpMessageView);

export default injectIntl(DefaultMessage);
