import { fetch } from '../../utils';
import Backend from '../../backend';

export const bedrockGenerateWithText = async (model, prompt, temperature, topP, maxGenLen, maxTokenCount) => {
  try {
    const response = await fetch(Backend.methodUrl('bedrockGenerateWithText'), {
      method: 'POST',
      body: JSON.stringify({ model, prompt, temperature, topP, maxGenLen, maxTokenCount }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseData = await response.json();
    let result = responseData.result;
    if (result === '') {
      return "AI doesn't respond anything.";
    } else {
      let redundantWords = ['Bot: ', '\nBot: '];
      for (let redundantWord of redundantWords) {
        if (result.startsWith(redundantWord)) {
          result = result.replace(redundantWord, '');
        }
      }
      return result;
    }
  } catch (error) {
    return 'Something went wrong.';
  }
};
