import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import CreateIcon from '@material-ui/icons/Create';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';

const AnalysisInputPanel = forwardRef((props, ref) => {
  const { inputTitle, slider, detailComponents, className, ...rest } = props;

  const [inputGroupExpanded, setInputGroupExpanded] = useState(false);

  // disabled due to mobile virtual keyboard
  // useEffect(() => {
  //   const onScroll = (event) => {
  //     closeInput();
  //   };
  //
  //   window.addEventListener('scroll', onScroll);
  //
  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // }, []);

  useImperativeHandle(ref, () => ({
    openInput: openInput,
    closeInput: closeInput
  }));

  const handleInputGroupChange = () => {
    setInputGroupExpanded(!inputGroupExpanded);
  };

  const closeInput = () => {
    setInputGroupExpanded(false);
  };

  const openInput = () => {
    setInputGroupExpanded(true);
  };

  const onClickAway = event => {
    let found = false;
    console.log(event.constructor.name);
    if (event.constructor.name !== 'TouchEvent' && event.constructor.name !== 'MouseEvent') {
      return;
    }

    const path = event.composedPath ? event.composedPath() : event.path;
    if (path) {
      found = path.find(element => {
        if (!element?.className) {
          return false;
        }

        if (!element.className.includes) {
          return false;
        }

        return (
          element.className.includes('sticky-clickaway-exception') || element.className.includes('ReactModalPortal')
        );
      });
    }

    if (!found) {
      closeInput();
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <ExpansionPanel
        className={clsx(['analysis-expansion', 'analysis-input-group'], className)}
        expanded={inputGroupExpanded}
        TransitionProps={{
          className: 'analysis-input-group-detail-collapse'
        }}
        {...rest}
      >
        <ExpansionPanelSummary className="analysis-expansion-summary analysis-input-group-summary">
          <Grid container direction="column">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item style={{ width: 'calc(100% - 56px)' }}>
                  {inputTitle}
                </Grid>
                <Grid item>
                  {inputGroupExpanded ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      className="analysis-input-group-toggle analysis-input-group-toggle-apply"
                      onClick={handleInputGroupChange}
                    >
                      <RemoveIcon />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      className="analysis-input-group-toggle analysis-input-group-toggle-change"
                      onClick={handleInputGroupChange}
                    >
                      <CreateIcon />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {slider && <Grid item>{slider}</Grid>}
          </Grid>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className="analysis-input-group-detail">{detailComponents}</ExpansionPanelDetails>
      </ExpansionPanel>
    </ClickAwayListener>
  );
});

export default AnalysisInputPanel;
