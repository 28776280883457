import React, { Suspense, useEffect, useState } from 'react';
import './MyWhiteboard.scss';
import { tracking } from '../../utils/tracking';
import { IconButton } from '@material-ui/core';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import MyWhiteboardInfoDialog from './MyWhiteboardInfoDialog/MyWhiteboardInfoDialog';
import SaveWhiteboardJSONDialog from './SaveWhiteboardJSONDialog/SaveWhiteboardJSONDialog';
import NativeOrWeb from '../../utils/native';

const ContainerStyle = { position: 'relative', height: '100%' };
const InfoButtonStyle = { position: 'absolute', right: 4, top: 20, padding: 4, zIndex: 1300 };

const Whiteboard = React.lazy(async () => {
  // const PDF = await import('https://raw.githubusercontent.com/mozilla/pdfjs-dist/master/build/pdf.worker.min.js');
  const promise = import('whiteboard/react/dist');
  promise.then(module => {
    MyWhiteboard.whiteboard = module.default.whiteboard;
    MyWhiteboard.shortcutFunctions = module.default.shortcutFunctions;
  });
  return promise;
});
// const PDFWorker = React.lazy(() => import('whiteboard/react/dist/pdf.worker'));

const MyWhiteboard = props => {
  const { onSavePNG, onSaveJSON, onLoad, ...rest } = props;

  const [infoDialog, setInfoDialog] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);

  const formattedOnSavePNG = () => {
    tracking('Whiteboard Saved');
    if (onSavePNG) {
      onSavePNG();
    }
  };
  const formattedOnSaveJSON = () => {
    tracking('Whiteboard Saved');
    setSaveDialog(true);
    if (onSaveJSON) {
      onSaveJSON();
    }
  };

  const formattedOnLoad = () => {
    if (onLoad) {
      onLoad();
    }
    setTimeout(() => {
      MyWhiteboard.shortcutFunctions.setTool_pen();
    }, 100);
  };

  const overrideDownload = (data, filename, type) => {
    return NativeOrWeb.downloadFile(data, filename, type);
  };

  const openInfoDialog = () => setInfoDialog(true);
  const closeInfoDialog = () => setInfoDialog(false);
  const closeSaveDialog = () => setSaveDialog(false);

  useEffect(() => {
    return () => {
      MyWhiteboard.shortcutFunctions.clearWhiteboard();
    };
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div style={ContainerStyle}>
        <MyWhiteboardInfoDialog open={infoDialog} onClose={closeInfoDialog} />
        <SaveWhiteboardJSONDialog open={saveDialog} onClose={closeSaveDialog} />

        <IconButton color="primary" style={InfoButtonStyle} onClick={openInfoDialog}>
          <HelpOutlineRoundedIcon />
        </IconButton>

        {/*<PDFWorker />*/}
        <Whiteboard
          onLoad={formattedOnLoad}
          onSavePNG={formattedOnSavePNG}
          onSaveJSON={formattedOnSaveJSON}
          overrideDownload={overrideDownload}
          {...rest}
        />
      </div>
    </Suspense>
  );
};

export default MyWhiteboard;
