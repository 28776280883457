import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const UPDATE_SUBSCRIPTION_PLANS = 'portfoplus/subscriptionPlan/UPDATE_SUBSCRIPTIONS';

/***********************************
 * Initial State
 ***********/
let initialState = {
  subscriptionPlans: [],
  upgradeSubscriptionPlansFetched: false
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_PLANS:
      const stateObj = { ...state, subscriptionPlans: action.payload };
      if (action.isUpgrade) {
        stateObj.upgradeSubscriptionPlansFetched = true;
      }
      return stateObj;
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const getSubscriptionPlans = queryObj => async (dispatch, getState) => {
  const subscriptionPlansResult = await api.getSubscriptionPlansApi(queryObj);
  dispatch({ type: UPDATE_SUBSCRIPTION_PLANS, payload: subscriptionPlansResult.data, isUpgrade: !!queryObj.upgrade });
  return subscriptionPlansResult.data;
};
