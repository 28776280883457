import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ProBadgePure from './ProBadgePure';

class ProBadge extends React.PureComponent {
  render() {
    return <ProBadgePure {...this.props} />;
  }
}

const container = connect(
  // Map state to props
  state => ({
    subscriptions: state.userSubscription.subscriptions,
    adviserSubscriptionNickname: state.userSubscription.adviserSubscriptionNickname
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(ProBadge);

export default injectIntl(container);
