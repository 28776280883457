import React from 'react';
import { toast } from 'react-toastify';
import { getClientWhatsappUrl, openWhatsappUrl } from '../../../../../utils/client';
import './ClientContactModal.css';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../../../components/Transition/Transition';
import IconToast from '../../../../../components/NewToast';
import NativeOrWeb from '../../../../../utils/native';

class ClientContactModal extends React.Component {
  render() {
    const { intl, userDetails, clientDetails, open, onClose, defaultMessage } = this.props;

    let clientFactsheet,
      emailShow = false;
    if (clientDetails) {
      if (clientDetails.inactiveUser) {
        clientFactsheet = clientDetails.factsheetId;
      } else {
        let advisorDetail = clientDetails.advisorDetails
          ? clientDetails.advisorDetails.find(advisorDetail => advisorDetail.email === userDetails.email)
          : undefined;
        if (advisorDetail !== undefined) {
          clientFactsheet = advisorDetail.factsheetId;
        }
      }

      if (clientFactsheet && clientFactsheet.email) {
        let _id = clientDetails._id.toString();
        emailShow = clientFactsheet.email.indexOf(_id) !== -1;
      }
    }
    return (
      <div>
        <Dialog
          open={open}
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Contact' })}</DialogTitle>
          <DialogContent>
            {/*connection && clientFactsheet ? (*/}
            {clientFactsheet ? (
              <ul className="contact__modal">
                <li>
                  <img alt="" src="img/phone.svg" />
                  <span className="center-col">
                    {clientFactsheet.phoneNumber && clientFactsheet.phoneNumber !== ''
                      ? clientFactsheet.phoneNumber
                      : '-'}
                  </span>
                  <span>
                    {clientFactsheet.phoneNumber && clientFactsheet.phoneNumber !== '' ? (
                      <a href={'tel:' + clientFactsheet.phoneNumber} target="_top">
                        <button>{intl.formatMessage({ id: 'Call' })}</button>
                      </a>
                    ) : (
                      <button className="contact-disabled">{intl.formatMessage({ id: 'Call' })}</button>
                    )}
                  </span>
                </li>
                <li>
                  <img alt="" src="img/whatsapp.svg" height="32" />
                  <span className="center-col">
                    {clientFactsheet.whatsapp && clientFactsheet.whatsapp !== '' ? clientFactsheet.whatsapp : '-'}
                  </span>
                  <span>
                    {clientFactsheet.whatsapp && clientFactsheet.whatsapp !== '' ? (
                      <button
                        onClick={() =>
                          openWhatsappUrl(
                            getClientWhatsappUrl(intl, clientDetails, defaultMessage, clientFactsheet.whatsapp)
                          )
                        }
                      >
                        {intl.formatMessage({ id: 'Message' })}
                      </button>
                    ) : (
                      <button className="contact-disabled">{intl.formatMessage({ id: 'Message' })}</button>
                    )}
                  </span>
                </li>
                <li>
                  <img alt="" src="img/wechat.svg" />
                  <span className="center-col breakword">
                    {clientFactsheet.wechatId && clientFactsheet.wechatId !== '' ? clientFactsheet.wechatId : '-'}
                  </span>
                  <span>
                    {clientFactsheet.wechatId && clientFactsheet.wechatId !== '' ? (
                      <button
                        onClick={() => {
                          NativeOrWeb.copyToClipboard(clientFactsheet.wechatId).then(() => {
                            toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
                              className: 'new-toast'
                            });
                          });
                        }}
                      >
                        {intl.formatMessage({ id: 'Copy' })}
                      </button>
                    ) : (
                      <button className="contact-disabled">{intl.formatMessage({ id: 'Copy' })}</button>
                    )}
                  </span>
                </li>
                <li>
                  <img alt="" src="img/email.svg" />
                  <span className="center-col breakword">
                    {!emailShow && clientFactsheet.email && clientFactsheet.email !== '' ? clientFactsheet.email : '-'}
                  </span>
                  <span>
                    {!emailShow && clientFactsheet.email && clientFactsheet.email !== '' ? (
                      <a href={`mailto:${clientFactsheet.email}`} target="_top">
                        <button>{intl.formatMessage({ id: 'Mail' })}</button>
                      </a>
                    ) : (
                      <button className="contact-disabled">{intl.formatMessage({ id: 'Mail' })}</button>
                    )}
                  </span>
                </li>
              </ul>
            ) : (
              <div className="modal-text">{intl.formatMessage({ id: 'You are not connected to this client.' })}</div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()} color="primary" autoFocus>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ClientContactModal;
