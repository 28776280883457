import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../store/control/duck';
import { SlideTransition } from '../../Transition/Transition';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { toast } from 'react-toastify';
import IconToast from '../../NewToast';
import { deleteConnectionRequest } from '../../../store/connectionRequest/duck';

const ConfirmDeleteConnectionRequestDialog = props => {
  const { intl, confirmDeleteConnectionRequestDialog, toggleDialog, deleteConnectionRequest } = props;
  const { client, closed } = confirmDeleteConnectionRequestDialog || {};
  const { connectionRequestId } = client || {};

  const [loading, setLoading] = useState(false);

  const close = () =>
    !loading &&
    toggleDialog('confirmDeleteConnectionRequest', { ...confirmDeleteConnectionRequestDialog, closed: true });

  const onExited = () => toggleDialog('confirmDeleteConnectionRequest', false);

  const confirm = async () => {
    try {
      setLoading(true);
      const response = await deleteConnectionRequest(connectionRequestId._id);
      if (response.success) {
        toast.info(IconToast('success', intl.formatMessage({ id: 'Updated successfully' })), {
          className: 'new-toast'
        });
        close();
        toggleDialog('connection', false);
      } else {
        toast.info(IconToast('error', intl.formatMessage({ id: response.message })), { className: 'new-toast' });
      }
    } catch (error) {
      toast.info(IconToast('error', intl.formatMessage({ id: error.message })), { className: 'new-toast' });
    } finally {
      setLoading(false);
    }
  };

  return !confirmDeleteConnectionRequestDialog || !client || !connectionRequestId ? null : (
    <Dialog
      className="confirm-delete-connection-request-dialog"
      open={!!confirmDeleteConnectionRequestDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'delete-connection-request-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'delete-connection-request-dialog-content' })}</DialogContent>
      <DialogActions>
        <Button color="primary" disabled={loading} onClick={close}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" disabled={loading} onClick={confirm}>
          {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    confirmDeleteConnectionRequestDialog: state.control.confirmDeleteConnectionRequestDialog
  }),
  {
    toggleDialog,
    deleteConnectionRequest
  }
)(ConfirmDeleteConnectionRequestDialog);

export default injectIntl(container);
