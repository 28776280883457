import React, { PureComponent } from 'react';
import ReactDice from 'react-dice-complete';
import 'react-dice-complete/dist/react-dice-complete.css';
import { Button, Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import AccessCodeGate from '../../components/AccessCodeGate/AccessCodeGate';
import './style.css';
import ShareIconButtonn from '../../components/ShareIconButton';
import ShareModal from '../../components/ShareModal';
import TempSelect from '../../components/TempSelect/TempSelect';

const pfpDomain = process.env.PFPLUS_URL;
const shareURL = new URL('/dice', pfpDomain).href;

class DiceView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      numDice: 3,
      message: null,
      shareModalOpen: false
    };
    this.rollAll = this.rollAll.bind(this);
    this.rollDoneCallback = this.rollDoneCallback.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  rollAll() {
    this.reactDice.rollAll();
  }

  rollDoneCallback(num) {
    this.setState({
      message: num
    });
  }

  handleChange(e) {
    let value = e.target.value;

    this.setState({
      numDice: value
    });
  }

  handleModal() {
    let { shareModalOpen } = this.state;
    this.setState({
      shareModalOpen: !shareModalOpen
    });
  }
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  render() {
    let { intl, userDetails } = this.props;
    let { shareModalOpen } = this.state;
    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid" id="dice-container">
              <div className="row">
                <div className="share-button">
                  <div onClick={this.handleModal}>
                    <ShareIconButtonn style={{ color: 'white' }} />
                  </div>
                </div>
                <div className="nav-top-margin-dice">
                  <AccessCodeGate
                    intl={intl}
                    allowed={userDetails && userDetails._id}
                    accessCodes={['oneteam', 'pfplus', 'lucky', 'Lucky', 'AD']}
                  >
                    <div className="card">
                      <div className="card-wrapper analysis-expansion-element" id="dice-card">
                        <div className="icon-mice">
                          <img alt="" src="img/logo_kyc.png" />
                        </div>
                        <h4 id="dice-title">
                          總點數: <span>{this.state.message}</span>
                        </h4>
                        <div className="card-body">
                          <div className="caseclose-form">
                            <form className="caseclose-form-dice">
                              <FormControl>
                                <InputLabel>骰仔數量: </InputLabel>
                                <TempSelect
                                  style={{ zIndex: '999 !important', overflow: 'visible' }}
                                  id="num-dice"
                                  value={this.state.numDice}
                                  onChange={this.handleChange}
                                >
                                  {Array.from({ length: 10 }, (v, k) => k + 1).map((i, index) => {
                                    return <MenuItem value={i}>{i}</MenuItem>;
                                  })}
                                </TempSelect>
                              </FormControl>
                              <div id="roll-dice">
                                <h5 id="roll-hint">按“LUCKY”或按個別骰仔</h5>
                                <div className="button-wrapper-dice">
                                  <Button className="dice-btn" variant="contained" onClick={this.rollAll}>
                                    LUCKY
                                  </Button>
                                </div>
                              </div>
                              <ReactDice
                                numDice={this.state.numDice}
                                faceColor="#3966f8"
                                dotColor="#FFFFFF"
                                rollDone={this.rollDoneCallback}
                                ref={dice => (this.reactDice = dice)}
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                      <div>
                        <img
                          className="logo"
                          src="https://www.portfoplus.com/adviser/img/AdvBanner-1.png"
                          alt="cheque-banner"
                          onClick={() => window.open('http://bit.ly/PFP_Facebook')}
                        />
                      </div>
                    </div>
                  </AccessCodeGate>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShareModal open={shareModalOpen} onClose={this.handleModal} shareURL={shareURL} {...this.props} />
      </div>
    );
  }
}

export default DiceView;
