import React, { memo, useEffect } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup
} from '@material-ui/core';
import { formatNumber, decimalToString } from '../../../utils/formatNumber';
import EditIconButton from '../../../components/EditIconButton';
import PVPChartsView from './PVPChartsView/PVPChartsView';
import { POLICY_TYPE } from '../../../constants/policy';
import CoverageChartView from './CoverageChartView/CoverageChartView';
// import AnalysisExport from './AnalysisExport/AnalysisExport';
// import { formatAnalysisDoc } from './AnalysisExport/formatter';
import { connect } from 'react-redux';
import { updateAnalysisCIExport } from '../../../store/analysis/duck';
import { injectIntl } from 'react-intl';
import './styles.css';
import MoreInfoTrigger from '../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import { toggleDialog } from '../../../store/control/duck';
import { getUserCurrency, isAdviserType } from '../../../utils/user';
import AladdinHint from '../../../components/AladdinHint/AladdinHint';
import CIInputPanel from './CIInputPanel/CIInputPanel';
import CIPolicyStatistics from './CIPolicyStatistics/CIPolicyStatistics';
import CISummary from './CISummary/CISummary';
// import CancerChanceChart from "./CancerChanceChart/CancerChanceChart";

const MarketView = memo(
  props => {
    const {
      intl,
      history,
      location,
      isWide,
      userDetails,
      figureData,
      isSafariGenerate,
      monthlyIncome,
      ciClaimCoverExpenses,
      ciTotalCoverage,
      ciToReachData,
      ciBetterThanKey,
      exportInputs,
      mySwipeableViews,
      inputPanelRef,
      updateAnalysisCIExport,
      toggleDialog
    } = props;

    // true = incidence, false = mortality
    const clientId = JSON.parse(localStorage.getItem('clientId'));

    const currency = getUserCurrency(userDetails);

    const benchmarkValueExtra = ciToReachData.benchmarkValueExtra;
    const ciScore = ciToReachData.score;
    const reachGood = ciToReachData.reachGood;
    const reachExcellent = ciToReachData.reachExcellent;
    const pfpAverage = _.defaultTo(_.get(figureData, 'average'), 0).toFixed(0);

    const horizontalBarData = {
      labels: [
        intl.formatMessage({ id: 'Me' }),
        intl.formatMessage({ id: 'Benchmark' }),
        intl.formatMessage({ id: 'PFPlus Avg' })
      ],
      datasets: [
        {
          label: '',
          backgroundColor: ['rgba(255, 106, 116,0.7)', 'rgba(255, 166, 0, 0.7)', 'rgba(57, 102, 248,0.7)'],
          /*  borderColor: ['#FFB1C1', '#FFE6AA', '#9AD0F5'], */
          borderWidth: 1,
          hoverBackgroundColor: ['rgba(255, 106, 116,0.5)', 'rgba(255, 166, 0, 0.5)', 'rgba(57, 102, 248,0.5)'],
          /*   hoverBorderColor: 'rgba(255,99,132,1)', */
          data: [ciTotalCoverage, benchmarkValueExtra, pfpAverage],
          maxBarThickness: 15
        }
      ]
    };

    const horizontalBarOptions = {
      legend: { display: false },

      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }
            label += intl.formatMessage({ id: currency }) + ' ';
            label += decimalToString(tooltipItem.xLabel);

            return label;
          }
        }
      },

      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(label, index, labels) {
                return formatNumber(label, intl);
              }
            }
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    };

    const handleClickEdit = () => {
      if (inputPanelRef && inputPanelRef.current) {
        let instance;
        if (inputPanelRef.current.getWrappedInstance) {
          instance = inputPanelRef.current.getWrappedInstance();
        } else {
          instance = inputPanelRef.current;
        }

        if (instance) {
          instance.open();
        }
      }
    };

    const createSwipeableViewsHandler = timeout => event => {
      if (event && event.persist) {
        event.persist();
      }
      setTimeout(() => {
        if (mySwipeableViews && mySwipeableViews.current) {
          mySwipeableViews.current.adjustSlideHeights();
        }
      }, timeout);
    };
    const onChangePVPExpansionPanel = createSwipeableViewsHandler(405);
    const onChangeExportExpansionPanel = createSwipeableViewsHandler(350);
    const onDidUpdateExport = createSwipeableViewsHandler(300);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return (
      <div
        style={{ paddingTop: 0, marginTop: 0 }}
        className={'analysis-slide analysis-ci' + (isSafariGenerate ? ' safari-gen' : '')}
      >
        {isSafariGenerate && exportInputs.exportInputPanel === true && <CIInputPanel />}
        <div className="analysis-expansion">
          {!isWide && isAdviserType(userDetails) && !isSafariGenerate && (
            <AladdinHint
              title={intl.formatMessage({ id: 'aladdin-ci-analysis-title' })}
              helpCenter="c34"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          )}

          {!isWide && !isSafariGenerate && <CIPolicyStatistics onChange={onChangePVPExpansionPanel} />}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportScore === true)) && (
            <div className="cardAnalysis analysis-expansion-element analysis-score">
              <MoreInfoTrigger
                titleIntlId="C.I. Protection Score"
                contentIntlId="moreInfo_ciScore"
                className="analysis-moreinfo"
              />
              <CoverageChartView policyType={POLICY_TYPE.CI} score={ciScore} />
            </div>
          )}

          {!isWide && (!isSafariGenerate || (isSafariGenerate && exportInputs.exportTotal === true)) && <CISummary />}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportBenchmark === true)) && (
            <div
              style={{ borderLeft: '6px solid #3EDA7D' }}
              className="cardAnalysis analysis-expansion-element analysis-benchmark text-center greyL6 font16"
            >
              <MoreInfoTrigger
                titleIntlId="Analysis"
                contentIntlId="moreInfo_ciCalculation"
                className="analysis-moreinfo"
              />

              <EditIconButton handleClick={handleClickEdit} />

              <h4>{intl.formatMessage({ id: 'Analysis' })}</h4>
              <div className="Outer">
                <HorizontalBar data={horizontalBarData} options={horizontalBarOptions} />
              </div>
              <div className="analysis-expansion-element violet-text">
                {benchmarkValueExtra > ciTotalCoverage ? (
                  <div style={{ margin: '15px 0' }}>
                    {intl.formatMessage({
                      id: 'Compare to benchmark, your protection'
                    })}
                    {intl.formatMessage({ id: ' is lacking' })}
                    <span className="figureColor ">
                      {' '}
                      {intl.formatMessage({
                        id: currency
                      })}{' '}
                      {decimalToString(benchmarkValueExtra - ciTotalCoverage)}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="violet-text">
                {ciTotalCoverage > benchmarkValueExtra &&
                  intl.formatMessage({
                    id: 'Great! Your coverage is better than benchmark!'
                  })}
                {ciTotalCoverage === benchmarkValueExtra &&
                  intl.formatMessage({
                    id: 'Great! Your coverage is same as benchmark!'
                  })}
              </div>
            </div>
          )}

          {(!isSafariGenerate || (isSafariGenerate && exportInputs.exportRanking === true)) && (
            <div className="cardAnalysis analysis-expansion-element analysis-ranking text-center greyL6 font16">
              <div style={{ marginBottom: '15px' }}>
                <div>
                  {ciBetterThanKey !== 'lessThan25'
                    ? intl.formatMessage({
                        id: 'Your total C.I. protection is better than'
                      })
                    : intl.formatMessage({
                        id: 'Your total C.I. protection is less than'
                      })}{' '}
                </div>
                <div className="font24 figureColor">
                  {/*{totalCIbetterthan}%{''}*/}
                  {ciBetterThanKey === '25' && '25'}
                  {ciBetterThanKey === '50' && '50'}
                  {ciBetterThanKey === '75' && '75'}
                  {ciBetterThanKey === '' && '0'}
                  {ciBetterThanKey === 'lessThan25' && '25'}
                  {'%'}
                </div>
                <div>{intl.formatMessage({ id: 'of peers' })}</div>
              </div>
            </div>
          )}

          {/*{!isSafariGenerate && _.get(userDetails, 'userType') === 'Adviser' && (*/}
          {/*  <ExpansionPanel*/}
          {/*    defaultExpanded={false}*/}
          {/*    className="analysis-expansion exclude-in-image analysis-bottom export-expansion"*/}
          {/*    TransitionProps={{*/}
          {/*      className: 'export-expansion-collapse',*/}
          {/*      timeout: 300*/}
          {/*    }}*/}
          {/*    onChange={onChangeExportExpansionPanel}*/}
          {/*  >*/}
          {/*    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">*/}
          {/*      {intl.formatMessage({ id: 'analysis-export-heading' })}&nbsp;<span className="pro-text">PRO</span>*/}
          {/*    </ExpansionPanelSummary>*/}
          {/*    <ExpansionPanelDetails className="analysis-expansion-detail">*/}
          {/*      <AnalysisExport*/}
          {/*        onDidUpdate={onDidUpdateExport}*/}
          {/*        shouldRender={() => {*/}
          {/*          if (!Object.entries(exportInputs).find(entry => entry[1] === true)) {*/}
          {/*            toggleDialog('analysisExportOptionsHint', {});*/}
          {/*            return false;*/}
          {/*          }*/}
          {/*          return true;*/}
          {/*        }}*/}
          {/*        intl={intl}*/}
          {/*        history={history}*/}
          {/*        safariGenPath="/analysis/ci/safari-gen"*/}
          {/*        targetSelector=".analysis-ci"*/}
          {/*        inputs={*/}
          {/*          <FormControl component="fieldset" className="export-select-form">*/}
          {/*            <FormLabel component="legend">*/}
          {/*              {intl.formatMessage({*/}
          {/*                id: 'Select fields to export'*/}
          {/*              })}*/}
          {/*            </FormLabel>*/}
          {/*            <FormGroup className="export-select-group">*/}
          {/*              {[*/}
          {/*                {*/}
          {/*                  state: 'exportInputPanel',*/}
          {/*                  labelId: 'input-panel'*/}
          {/*                },*/}
          {/*                { state: 'exportScore', labelId: 'score' },*/}
          {/*                {*/}
          {/*                  state: 'exportTotal',*/}
          {/*                  labelId: 'Total C.I. protection'*/}
          {/*                },*/}
          {/*                { state: 'exportBenchmark', labelId: 'Benchmark' },*/}
          {/*                { state: 'exportRanking', labelId: 'Ranking' }*/}
          {/*              ].map((obj, index) => {*/}
          {/*                return (*/}
          {/*                  <FormControl key={index}>*/}
          {/*                    <FormControlLabel*/}
          {/*                      control={*/}
          {/*                        <Checkbox*/}
          {/*                          color="primary"*/}
          {/*                          checked={exportInputs[obj.state] || false}*/}
          {/*                          onChange={(event, checked) =>*/}
          {/*                            updateAnalysisCIExport({*/}
          {/*                              [obj.state]: checked*/}
          {/*                            })*/}
          {/*                          }*/}
          {/*                          value={obj.state}*/}
          {/*                        />*/}
          {/*                      }*/}
          {/*                      label={intl.formatMessage({*/}
          {/*                        id: obj.labelId*/}
          {/*                      })}*/}
          {/*                    />*/}
          {/*                  </FormControl>*/}
          {/*                );*/}
          {/*              })}*/}
          {/*            </FormGroup>*/}
          {/*          </FormControl>*/}
          {/*        }*/}
          {/*        options={targetElem => {*/}
          {/*          const selectionPairs = [*/}
          {/*            {*/}
          {/*              state: 'exportInputPanel',*/}
          {/*              selector: '.analysis-input-panel'*/}
          {/*            },*/}
          {/*            { state: 'exportScore', selector: '.analysis-score' },*/}
          {/*            { state: 'exportTotal', selector: '.analysis-total' },*/}
          {/*            {*/}
          {/*              state: 'exportBenchmark',*/}
          {/*              selector: '.analysis-benchmark'*/}
          {/*            },*/}
          {/*            {*/}
          {/*              state: 'exportRanking',*/}
          {/*              selector: '.analysis-ranking'*/}
          {/*            }*/}
          {/*          ];*/}

          {/*          return {*/}
          {/*            height:*/}
          {/*              (exportInputs.exportInputPanel === true ? 300 : 0) +*/}
          {/*              (exportInputs.exportScore === true*/}
          {/*                ? document.querySelector('.analysis-ci .analysis-score').offsetHeight + 14*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportTotal === true*/}
          {/*                ? document.querySelector('.analysis-ci .analysis-total').offsetHeight + 14*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportBenchmark === true*/}
          {/*                ? document.querySelector('.analysis-ci .analysis-benchmark').offsetHeight + 14*/}
          {/*                : 0) +*/}
          {/*              (exportInputs.exportRanking === true*/}
          {/*                ? document.querySelector('.analysis-ci .analysis-ranking').offsetHeight + 14*/}
          {/*                : 0),*/}
          {/*            backgroundColor: '#ffffff',*/}
          {/*            onclone: async doc => {*/}
          {/*              try {*/}
          {/*                formatAnalysisDoc(doc, '.analysis-ci', '.ci-input-panel');*/}

          {/*                selectionPairs.forEach(selectionPair => {*/}
          {/*                  if (exportInputs[selectionPair.state] === false) {*/}
          {/*                    doc*/}
          {/*                      .querySelector('.analysis-ci')*/}
          {/*                      .querySelector(selectionPair.selector)*/}
          {/*                      .remove();*/}
          {/*                  }*/}
          {/*                });*/}
          {/*              } catch (e) {*/}
          {/*                return e;*/}
          {/*              }*/}
          {/*            }*/}
          {/*          };*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </ExpansionPanelDetails>*/}
          {/*  </ExpansionPanel>*/}
          {/*)}*/}
        </div>

        <div className="height-block-for-fab exclude-in-image" />
      </div>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

// MarketView.whyDidYouRender = true;

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    figureData: state.analysis.figureData,

    monthlyIncome: state.analysis.monthlyIncome,
    ciClaimCoverExpenses: state.analysis.ciClaimCoverExpenses,

    ciToReachData: state.analysis.ciToReachData,
    ciBetterThanKey: state.analysis.ciBetterThanKey,
    ciTotalCoverage: state.analysis.ciTotalCoverage,

    exportInputs: { ...state.analysis.ciExport }
  }),
  // Map actions to props
  {
    updateAnalysisCIExport,
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(MarketView);

export default injectIntl(container);
