import React, { useEffect } from 'react';
import './ShareButtonGroup.scss';
import ShareButtons from '../ShareButtons/ShareButtons';
import CopyTextField from '../CopyTextField/CopyTextField';

const ShareButtonGroup = props => {
  const {
    title,
    url,
    whatsappNumber,
    phone,
    isCTA,
    locked,
    onCopy,
    onWhatsapp,
    onShare,
    imageUrl,
    imageCompression,
    CopyTextFieldProps,
    showGPT = true,
    style
  } = props;


  const [messageState, setMessageState] = React.useState({ message: title, isCustom: false });
  useEffect(() => {
    if (messageState.isCustom) return;
    else {
      setMessageState({
        message: title,
        isCustom: false
      })
    }
  }, [title])
  const urlFallback = url || '';
  const titleFallback = messageState.message || '';
  const text = `${messageState.message}${urlFallback ? '\n' : ''}${urlFallback}`;

  return (
    <div style={style}>
      <section>
        <CopyTextField
          text={text}
          onCopy={onCopy}
          oriMessage={messageState.message}
          showGPT={showGPT}
          onRewriteMessageValue={(message) => {
            setMessageState({
              message: message,
              isCustom: true
            })
          }}
          {...CopyTextFieldProps} />
      </section>
      <section style={{ marginTop: 10, marginBottom: 10 }}>
        <ShareButtons
          whatsappNumber={whatsappNumber || phone}
          title={titleFallback}
          url={urlFallback}
          text={text}
          isCTA={isCTA}
          locked={locked}
          isScript={true}
          onWhatsapp={onWhatsapp}
          onShare={onShare}
          imageUrl={imageUrl}
          imageCompression={imageCompression} // parameters used in Compressor.js
        />
      </section>
    </div>
  );
};

export default ShareButtonGroup;
