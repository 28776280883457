import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import { decimalToString } from '../../../../../../../utils/formatNumber';
import { TARGET_TYPE } from '../../../../../../../constants/businessTarget';
import { weeklyAptCalculator } from '../../../../../../../utils/weeklyAptCalculator';
import { Grid, Typography } from '@material-ui/core';
import NumberFormatTextField from '../../../../../../AddPolicy/Inputs/NumberFormatInput/NumberFormatTextField';
import palette from '../../../../../../App/palette.scss';

const LeftColumnGridStyle = {
  textAlign: 'right'
};

const RightColumnGridStyle = {
  textAlign: 'left'
};

const WeeklyApptTypoStyle = {
  fontSize: '25px',
  fontWeight: 600
};

const MDRTValueTypeStyle = {
  fontWeight: 500,
  fontSize: '15px'
};

const InputControlStyle = {
  width: 100
};

const InputStyle = {
  color: palette['primaryMain'],
  fontWeight: 500,
  fontSize: '130%'
};

const BusinessTargetCalculator = props => {
  const {
    intl,
    defaultQuarterTarget,
    quarterTarget,
    avgCaseSize,
    closingRate,
    isMDRT,
    isCOT,
    isTOT,

    onChangeQuarterTarget,
    onChangeAvgCaseSize,
    onChangeClosingRate,
    onChangeIsMDRT,
    onChangeIsCOT,
    onChangeIsTOT,

    ...rest
  } = props;

  const _onChangeIsMDRT = () => {
    if (isMDRT === 'No') {
      onChangeIsMDRT('Yes');
      onChangeIsCOT('No');
      onChangeIsTOT('No');
      onChangeQuarterTarget(TARGET_TYPE.MDRTC / 4, false);
    } else if (isMDRT === 'Yes') {
      onChangeIsMDRT('YesP');
      onChangeIsCOT('No');
      onChangeIsTOT('No');
      onChangeQuarterTarget(TARGET_TYPE.MDRTP / 4, false);
    } else if (isMDRT === 'YesP') {
      onChangeIsMDRT('No');
      onChangeIsCOT('No');
      onChangeIsTOT('No');
      onChangeQuarterTarget(defaultQuarterTarget || 0, false);
    }
  };

  const _onChangeIsCOT = () => {
    if (isCOT === 'No') {
      onChangeIsMDRT('No');
      onChangeIsCOT('Yes');
      onChangeIsTOT('No');
      onChangeQuarterTarget((TARGET_TYPE.MDRTC * 3) / 4, false);
    } else if (isCOT === 'Yes') {
      onChangeIsMDRT('No');
      onChangeIsCOT('YesP');
      onChangeIsTOT('No');
      onChangeQuarterTarget((TARGET_TYPE.MDRTP * 3) / 4, false);
    } else if (isCOT === 'YesP') {
      onChangeIsMDRT('No');
      onChangeIsCOT('No');
      onChangeIsTOT('No');
      onChangeQuarterTarget(defaultQuarterTarget || 0, false);
    }
  };

  const _onChangeIsTOT = () => {
    if (isTOT === 'No') {
      onChangeIsMDRT('No');
      onChangeIsCOT('No');
      onChangeIsTOT('Yes');
      onChangeQuarterTarget((TARGET_TYPE.MDRTC * 6) / 4, false);
    } else if (isTOT === 'Yes') {
      onChangeIsMDRT('No');
      onChangeIsCOT('No');
      onChangeIsTOT('YesP');
      onChangeQuarterTarget((TARGET_TYPE.MDRTP * 6) / 4, false);
    } else if (isTOT === 'YesP') {
      onChangeIsMDRT('No');
      onChangeIsCOT('No');
      onChangeIsTOT('No');
      onChangeQuarterTarget(defaultQuarterTarget || 0, false);
    }
  };

  const formattedOnChangeQuarterTarget = event => {
    const value = event.target.value;
    if (value !== quarterTarget) {
      onChangeIsMDRT('No');
      onChangeIsCOT('No');
      onChangeIsTOT('No');
      onChangeQuarterTarget(value, value !== quarterTarget);
    }
  };

  const formattedOnChangeAvgCaseSize = event => {
    const value = event.target.value;
    onChangeAvgCaseSize(value);
  };

  const formattedOnChangeClosingRate = event => {
    const value = event.target.value;
    onChangeClosingRate(Math.min(Math.max(value, 0), 100));
  };

  return (
    <Grid container justify="center" alignItems="center" spacing={2} {...rest}>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={6} style={LeftColumnGridStyle}>
            <Typography>{intl.formatMessage({ id: 'Quarter Target' })} :</Typography>
          </Grid>
          <Grid item xs={6}>
            <NumberFormatTextField
              value={quarterTarget}
              onChange={formattedOnChangeQuarterTarget}
              style={InputControlStyle}
              inputProps={{
                style: InputStyle,
                inputMode: 'decimal',
                pattern: '[0-9]*'
              }}
            />
            {/*<NumberFormat*/}
            {/*	style={{ fontSize: '130%', color: '#3966f8' }}*/}
            {/*	thousandSeparator={true}*/}
            {/*	onValueChange={formattedOnChangeQuarterTarget}*/}
            {/*	value={quarterTarget}*/}
            {/*	inputMode="decimal"*/}
            {/*	pattern="[0-9]*"*/}
            {/*/>*/}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6} style={LeftColumnGridStyle}>
            <Button
              variant={isMDRT === 'Yes' || isMDRT === 'YesP' ? 'contained' : 'outlined'}
              className={
                isMDRT === 'Yes' || isMDRT === 'YesP'
                  ? 'plan-type-select-button selected source-button-responsive'
                  : 'plan-type-select-button source-button-responsive'
              }
              onClick={_onChangeIsMDRT}
            >
              {intl.formatMessage({ id: 'MDRT' })}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography display="inline" color="primary" style={MDRTValueTypeStyle}>
              {isMDRT === 'Yes' || isMDRT === 'No'
                ? intl.formatMessage({ id: 'C_Target' })
                : intl.formatMessage({ id: 'P_Target' })}
            </Typography>
            <Typography display="inline"> </Typography>
            <Typography display="inline" style={MDRTValueTypeStyle}>
              {isMDRT === 'Yes' || isMDRT === 'No'
                ? decimalToString(TARGET_TYPE.MDRTC)
                : decimalToString(TARGET_TYPE.MDRTP)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6} style={LeftColumnGridStyle}>
            <Button
              variant={isCOT === 'Yes' || isCOT === 'YesP' ? 'contained' : 'outlined'}
              className={
                isCOT === 'Yes' || isCOT === 'YesP'
                  ? 'plan-type-select-button selected source-button-responsive'
                  : 'plan-type-select-button source-button-responsive'
              }
              onClick={_onChangeIsCOT}
            >
              {intl.formatMessage({ id: 'COT' })}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography display="inline" color="primary" style={MDRTValueTypeStyle}>
              {isCOT === 'Yes' || isCOT === 'No'
                ? intl.formatMessage({ id: 'C_Target' })
                : intl.formatMessage({ id: 'P_Target' })}
            </Typography>
            <Typography display="inline"> </Typography>
            <Typography display="inline" style={MDRTValueTypeStyle}>
              {isCOT === 'Yes' || isCOT === 'No'
                ? decimalToString(TARGET_TYPE.MDRTC * 3)
                : decimalToString(TARGET_TYPE.MDRTP * 3)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6} style={LeftColumnGridStyle}>
            <Button
              variant={isTOT === 'Yes' || isTOT === 'YesP' ? 'contained' : 'outlined'}
              className={
                isTOT === 'Yes' || isTOT === 'YesP'
                  ? 'plan-type-select-button selected source-button-responsive'
                  : 'plan-type-select-button source-button-responsive'
              }
              onClick={_onChangeIsTOT}
            >
              {intl.formatMessage({ id: 'TOT' })}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography display="inline" color="primary" style={MDRTValueTypeStyle}>
              {isTOT === 'Yes' || isTOT === 'No'
                ? intl.formatMessage({ id: 'C_Target' })
                : intl.formatMessage({ id: 'P_Target' })}
            </Typography>
            <Typography display="inline"> </Typography>
            <Typography display="inline" style={MDRTValueTypeStyle}>
              {isTOT === 'Yes' || isTOT === 'No'
                ? decimalToString(TARGET_TYPE.MDRTC * 6)
                : decimalToString(TARGET_TYPE.MDRTP * 6)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6} style={LeftColumnGridStyle}>
            <Typography>{intl.formatMessage({ id: 'avg_case_size' })} :</Typography>
          </Grid>
          <Grid item xs={6}>
            <NumberFormatTextField
              value={avgCaseSize}
              onChange={formattedOnChangeAvgCaseSize}
              style={InputControlStyle}
              inputProps={{
                style: InputStyle
                // inputMode: "decimal",
                // pattern: "[0-9]*"
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6} style={LeftColumnGridStyle}>
            <Typography>{intl.formatMessage({ id: 'Closing Rate' })} :</Typography>
          </Grid>
          <Grid item xs={6}>
            <NumberFormatTextField
              value={closingRate}
              onChange={formattedOnChangeClosingRate}
              style={{ ...InputControlStyle, width: 60 }}
              inputProps={{
                style: InputStyle,
                inputMode: 'decimal',
                pattern: '[0-9]*',
                suffix: '%'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" style={{ ...WeeklyApptTypoStyle, paddingTop: 16 }}>
          {intl.formatMessage({ id: 'Weekly Appt' })}
        </Typography>
        <Typography color="primary" align="center" style={WeeklyApptTypoStyle}>
          {weeklyAptCalculator(parseFloat(quarterTarget), parseFloat(avgCaseSize), parseFloat(closingRate) / 100)}
        </Typography>
        <Typography align="center" style={WeeklyApptTypoStyle}>
          {intl.formatMessage({ id: 'weekly-appt-to-achieve' })}
        </Typography>
        <Typography color="primary" align="center" style={WeeklyApptTypoStyle}>
          {quarterTarget ? decimalToString(quarterTarget / 3, 0) : 0}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(BusinessTargetCalculator);
