import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { toggleDialog } from '../../store/control/duck';
import { SlideTransition } from '../Transition/Transition';
import MyWhiteboardContainer from './MyWhiteboardContainer/MyWhiteboardContainer';
import ExitWhiteboardDialog from './ExitWhiteboardDialog/ExitWhiteboardDialog';
import ScrollBottomButton from '../ScrollBottomButton/ScrollBottomButton';
import ScrollTopButton from '../ScrollTopButton/ScrollTopButton';

const MyWhiteboardDialog = props => {
  const { intl, myWhiteBoardDialog, toggleDialog } = props;

  const dialogContentRef = useRef(null);
  const [exitWhiteboardDialog, setExitWhiteboardDialog] = useState(false);

  const { closed } = myWhiteBoardDialog || {};

  const close = () => {
    setExitWhiteboardDialog(true);
  };

  const onClosed = () => {
    toggleDialog('myWhiteBoard', false);
  };

  const closeExitWhiteboardDialog = () => {
    setExitWhiteboardDialog(false);
    toggleDialog('myWhiteBoard', { ...myWhiteBoardDialog, closed: true });
  };

  return (
    <Fragment>
      <Dialog
        open={!!myWhiteBoardDialog && !closed}
        TransitionComponent={SlideTransition}
        fullScreen={true}
        onExited={onClosed}
      >
        <DialogContent ref={dialogContentRef} style={{ height: 'calc(100vh - 28px - 53px)' }}>
          {!!myWhiteBoardDialog && !closed && (
            <Fragment>
              <ScrollTopButton scrollElement={dialogContentRef} style={{ top: 60, right: 55 }} />
              <ScrollBottomButton
                scrollElement={dialogContentRef}
                style={{
                  bottom: 60,
                  right: 55
                }}
              />
              <MyWhiteboardContainer />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close}>
            {intl.formatMessage({ id: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>

      <ExitWhiteboardDialog open={exitWhiteboardDialog} onClose={closeExitWhiteboardDialog} />
    </Fragment>
  );
};

const container = connect(
  state => ({
    myWhiteBoardDialog: state.control.myWhiteBoardDialog
  }),
  {
    toggleDialog
  }
)(MyWhiteboardDialog);

export default injectIntl(container);
