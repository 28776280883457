import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleProDialog } from '../../../store/userSubscription/duck';

class ProRequiredDialog extends React.PureComponent {
  close = isUpgrade => {
    const { proDialog, toggleProDialog, history } = this.props;
    toggleProDialog(false);
    if (proDialog.cb) proDialog.cb(isUpgrade, history);
  };

  render() {
    const { intl, history, proDialog } = this.props;
    return proDialog ? (
      <Dialog open={!!proDialog} fullWidth={true} onClose={() => this.close(false)} style={{ zIndex: 1500 }}>
        <DialogTitle>{intl.formatMessage({ id: 'pro-dialog-title' })}</DialogTitle>
        <DialogContent>
          {proDialog.customMessage && <Typography>{proDialog.customMessage}</Typography>}
          <Typography>{intl.formatMessage({ id: 'pro-dialog-msg' })}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => this.close(false)}>{intl.formatMessage({ id: 'Cancel' })}</Button>
          <Button
            color="primary"
            onClick={() => {
              this.close(true);
              history.push('/subscription');
            }}
          >
            {intl.formatMessage({ id: 'upgrade' })}
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

const container = connect(
  // Map state to props
  state => ({
    proDialog: state.userSubscription.proDialog
  }),
  // Map actions to props
  {
    toggleProDialog
  },
  // mergeProps
  null,
  // options
  {}
)(ProRequiredDialog);

export default injectIntl(container);
