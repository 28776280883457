import * as api from './api';
import { GET_CLIENT } from '../client/duck';

export const createConnectionRequest = inactiveUserId => async (dispatch, getState) => {
  try {
    const response = await api.createConnectionRequest(inactiveUserId);
    if (response.success) {
      dispatch({ type: GET_CLIENT });
    }

    return response;
  } catch (error) {
    return { success: false, error: error, message: error.toString() };
  }
};

export const resendConnectionRequest = connectionRequestId => async (dispatch, getState) => {
  try {
    const response = await api.resendConnectionRequest(connectionRequestId);
    if (response.success) {
      dispatch({ type: GET_CLIENT });
    }

    return response;
  } catch (error) {
    return { success: false, error: error, message: error.toString() };
  }
};

export const deleteConnectionRequest = connectionRequestId => async (dispatch, getState) => {
  try {
    const response = await api.deleteConnectionRequest(connectionRequestId);
    if (response.success) {
      dispatch({ type: GET_CLIENT });
    }

    return response;
  } catch (error) {
    return { success: false, error: error, message: error.toString() };
  }
};
