import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';

const ButtonStyle = {
  padding: 3,
  lineHeight: 1,
  minWidth: 71,
  textTransform: 'unset'
};

const IconStyle = {
  width: 24,
  margin: '4px 0'
};

const WhiteboardButton = props => {
  const { intl, style, onClick } = props;

  return (
    <Button color="primary" onClick={onClick} style={{ ...ButtonStyle, ...style }}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <img src="img/financial-pyramid/whiteboardPen.png" alt="whiteboard" style={IconStyle} />
        </Grid>
        <Grid item>
          <Typography align="center" color="textSecondary">
            {intl.formatMessage({ id: 'fin-py-handwriting-mode' })}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default injectIntl(WhiteboardButton);
