import React from 'react';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import { EditorState } from 'draft-js';
import { BlockPicker } from 'react-color';
import { Popover, Zoom } from '@material-ui/core';
import { SELECTABLE_COLORS } from '../../constants';
import './ColorButton.scss';

class ColorButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const { theme, onChange, editorState, colorStyles } = this.props;
    const { open } = this.state;

    const color = colorStyles.current(editorState);

    return (
      <div
        className={theme.buttonWrapper}
        ref={node => {
          if (node) this.parent = node;
        }}
      >
        <button
          type="button"
          className={theme.button}
          onClick={event => {
            event.stopPropagation();
            this.selectionState = editorState.getSelection();
            this.selectedColor = undefined;
            this.setState({ open: true });
          }}
        >
          <ColorLensIcon />
        </button>
        <Popover
          TransitionComponent={Zoom}
          ref={node => {
            if (node) this.popover = node;
          }}
          open={open}
          onClose={() => this.setState({ open: false })}
          anchorEl={this.parent}
          onExited={() => {
            this.props.editor.focus();
            let newEditorState = EditorState.forceSelection(editorState, this.selectionState);
            if (this.selectedColor) {
              newEditorState = colorStyles.toggle(newEditorState, this.selectedColor.hex);
            }
            onChange(newEditorState);
          }}
        >
          <BlockPicker
            width="276px"
            color={color}
            onChange={(selectedColor, event) => {
              event.preventDefault();
              event.stopPropagation();
              this.selectedColor = selectedColor;
              this.setState({ open: false });
            }}
            colors={SELECTABLE_COLORS}
          />
        </Popover>
      </div>
    );
  }
}

export default ColorButton;
