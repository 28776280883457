import { connect } from 'react-redux';
import { checkQRCode, useQRCode } from '../../../store/qrCode/duck';
import { injectIntl } from 'react-intl';
import QRShareToUserView from './component';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const QRShareToUser = connect(
  // Map state to props
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userDetails: state.user.userDetails,

    // QR Code
    qrCodeFetchPhase: state.qrCode.fetchPhase,
    qrCodeResMessage: state.qrCode.resMessage,
    qrCodeError: state.qrCode.error,
    qrCode: state.qrCode.qrCode,
    qrCodeUseResult: state.qrCode.useResult
  }),
  // Map actions to props
  {
    checkQRCode,
    useQRCode
  }
)(QRShareToUserView);

QRShareToUserView.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(injectIntl(QRShareToUser));
