import React from 'react';
import { Typography, Card, Grid, Button } from '@material-ui/core';
import './PaymentInfo.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { getAdminEmail } from '../../../utils/email';
import { ga4TrackSupport } from '../../../utils/tracking';
// import { mobile } from '../../../utils/device';

const PaymentCard = ({ image, info, extra, ...rest }) => (
  <Card className="payment-card" {...rest}>
    <Grid className="basic" container spacing={0}>
      <Grid className="center-wrapper" item xs={6}>
        <img src={image} alt={image} />
      </Grid>

      <Grid className="center-wrapper" item xs={6}>
        {info}
      </Grid>
    </Grid>

    {!!extra && extra}
  </Card>
);

class PaymentInfo extends React.PureComponent {
  render() {
    const { intl, subscriptionPlan, coupon, userDetails, customButton } = this.props;
    const proofMessage = subscriptionPlan
      ? `I want to subscribe to this plan: ${subscriptionPlan.nickname}/${subscriptionPlan.intervalCount}-${subscriptionPlan.interval
      } ${coupon ? `(coupon: ${coupon.couponId})` : ''}${userDetails &&
      userDetails.email &&
      `%0aMy email is ${userDetails.email}`}`
      : '';
    const adminEmail = getAdminEmail();

    return (
      <div className="payment-info">
        <Typography variant="h5" align="center">
          {intl.formatMessage({ id: 'submit-via-whatsapp' })}
        </Typography>
        <PaymentCard
          image="img/payments/fps.png"
          info={
            <div>
              <Typography>{intl.formatMessage({ id: 'fps' })}</Typography>
              <Typography>{adminEmail}</Typography>
            </div>
          }
        />
        <PaymentCard
          image="img/payments/hsbc.png"
          info={
            <div>
              <Typography>{intl.formatMessage({ id: 'banktransfer' })}</Typography>
              <Typography>PortfoPlus Limited</Typography>
              <Typography>023-752348-838</Typography>
            </div>
          }
        />
        {/* <PaymentCard
          image="img/payments/icbc.png"
          info={
            <div>
              <Typography>{intl.formatMessage({ id: 'banktransfer' })}</Typography>
              <Typography>PortfoPlus Limited</Typography>
              <Typography>637-15113761</Typography>
            </div>
          }
        /> */}
        {/*<PaymentCard*/}
        {/*  image="img/payments/payme.png"*/}
        {/*  info={*/}
        {/*    <div>*/}
        {/*      <Typography>{adminEmail}</Typography>*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*  onClick={() => window.open('https://qr.payme.hsbc.com.hk/2/HdcnswPwL8yUv3D7C9mUEC', '_blank')}*/}
        {/*  extra={*/}
        {/*    !mobile() ? (*/}
        {/*      <div className="center-wrapper qr-code-wrapper">*/}
        {/*        <img src="img/payments/paymePFPlus.jpg" alt="payme-qr-code" style={{}} />*/}
        {/*      </div>*/}
        {/*    ) : (*/}
        {/*      undefined*/}
        {/*    )*/}
        {/*  }*/}
        {/*/>*/}


        <Button
          className="action wtsapp-green"
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => {

            ga4TrackSupport({ 'msg_channel': 'whatsapp', 'channel_targert': '85266370320', 'support_type': 'payment_verify' })
            window.open(`https://api.whatsapp.com/send?phone=85266370320&text=${proofMessage}`, '_blank')
          }}
        >
          {intl.formatMessage({ id: 'submit-payment-proof-wtsapp' })}
        </Button>

        <Button
          className="action orange"
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={() => {
            ga4TrackSupport({ 'msg_channel': 'email', 'channel_targert': adminEmail, 'support_type': 'payment_verify' })
            window.open(`mailto:${adminEmail}?Submit Payment Proof&body=${proofMessage}`, '_blank')
          }}
        >
          {intl.formatMessage({ id: 'submit-payment-proof-email' }, { email: adminEmail })}
        </Button>
        {customButton && customButton}
      </div>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {},
  // mergeProps
  null,
  // options
  {}
)(withRouter(PaymentInfo));

export default injectIntl(container);
