import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteFileAttachmentsCategory } from '../../../../../store/team/api';
import { getTeams } from '../../../../../store/team/duck';

const RemoveCategoryDialog = props => {
  const { intl, open, onClose, team, category, categories } = props;

  const dispatch = useDispatch();
  const removeCategory = async () => {
    await deleteFileAttachmentsCategory(team._id, category._id, categories);
    dispatch(getTeams());
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{intl.formatMessage({ id: 'team-library-remove-category-dialog-title' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'team-library-remove-category-dialog-description' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={removeCategory}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(RemoveCategoryDialog);
