import { connect } from 'react-redux';
import { handleSignOut } from '../../../../store/user/duck';
import SetTargetView from './component';
import { injectIntl } from 'react-intl';
import { updateAdviserBusiness, fetchAdviserBusiness } from './../../../../store/adviserDashboard/duck';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';
import { withRouter } from 'react-router';

const setTarget = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    updateQuarterTargetPhase: state.adviserDashboard.updateQuarterTargetPhase,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    updatePhase: state.user.updatePhase
  }),
  // Map actions to props
  {
    handleSignOut,
    updateAdviserBusiness,
    fetchAdviserBusiness,
    renderHeaderButton
  }
)(SetTargetView);

export default injectIntl(withRouter(setTarget));
