import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { IconButton, Card, CardContent, Grid, MenuItem, Popover, Typography, Zoom } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './GraphCard.scss';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { formatNumber } from '../../../../../utils/formatNumber';
import { isMobile } from 'react-device-detect';
import TempSelect from '../../../../../components/TempSelect/TempSelect';
import EditIcon from '@material-ui/icons/Edit';

const DisplayNameTypeStyle = { fontSize: '125%', fontWeight: 500 };
const EditButtonStyle = { padding: 4 };
const EditButtonIconStyle = { fontSize: 16 };

const BarChart = ({ showGraph, years, datasets, intl, inBarLabelFontSize }) => (
  <Bar
    data={{
      labels: showGraph ? years.map(year => intl.formatMessage({ id: 'pfa-year' }, { year })) : [],
      datasets
    }}
    options={{
      showAllTooltips: true,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              callback: label => formatNumber(label, intl)
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => {
            return tooltipItem[0].xLabel;
          },
          label: (tooltipItem, data) => {
            return '$' + formatNumber(tooltipItem.yLabel, intl);
          }
        }
      },
      layout: {
        padding: {
          top: 45
        }
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
          font: { size: inBarLabelFontSize },
          formatter: (value, context) => {
            return context.dataset.data[context.dataIndex] ? context.dataset.label : '';
          }
        }
      },
      cornerRadius: 0
    }}
    plugins={[ChartDataLabels]}
  />
);

const GraphCard = ({
  intl,
  data,
  yearsToShow,
  principal,
  base,
  firstYearBonus,
  isSmallScreen,
  isSmallerScreen,
  graphMode,
  onChangeGraphMode,
  template,
  displayName,
  openEditDisplayNamesDialog
}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const {
    expenseWithYearsToShow,
    returnWithYearsToShow,
    totalAmount,
    negativeReturn,
    profitRateWithYearsToShow,
    cashValueMinusLoan
  } = data;

  const bankLoan = principal - (base ?? 0) - (firstYearBonus ?? 0);
  const years = yearsToShow.filter((e, i) => i === 0 || (e && totalAmount[i]));
  const baseValid = (Math.floor(base) ?? 0) <= (Math.floor(principal) ?? 0);
  const showGraph = principal && baseValid;
  const datasets = [];
  const showSmallerFontSize = (isSmallScreen && isMobile) || isSmallerScreen;
  const inBarLabelFontSize = showSmallerFontSize ? 9 : 12;
  const topLabelFontSize = showSmallerFontSize ? 11 : 16;
  if (showGraph) {
    if (graphMode !== 'pfa-cash-minus-loan-title') {
      datasets.push({
        label: intl.formatMessage({ id: 'pfa-base-graph' }),
        data: years.map((e, i) => (!negativeReturn[i] ? base : 0)),
        fill: false,
        showLine: true,
        spanGaps: true,
        backgroundColor: '#3966f8',
        maxBarThickness: 85,
        borderColor: '#3966f8',
        order: 0
      });
      if (graphMode === 'pfa-return-combo-title') {
        datasets.push({
          label: intl.formatMessage({ id: 'pfa-bank-loan' }),
          data: years.map((e, i) => (!negativeReturn[i] ? bankLoan : 0)),
          fill: false,
          showLine: true,
          spanGaps: true,
          backgroundColor: '#FFA427',
          maxBarThickness: 85,
          borderColor: '#3966f8',
          order: 1
        });
        datasets.push({
          label: intl.formatMessage({ id: 'pfa-expense-graph' }),
          data: expenseWithYearsToShow,
          fill: false,
          showLine: true,
          spanGaps: true,
          backgroundColor: '#FFC000',
          maxBarThickness: 85,
          borderColor: '#3966f8',
          order: 2
        });
        datasets.push({
          label: intl.formatMessage({ id: 'pfa-negative-return' }),
          data: negativeReturn,
          fill: false,
          showLine: true,
          spanGaps: true,
          backgroundColor: '#b8b8b8',
          maxBarThickness: 85,
          borderColor: '#3966f8',
          order: 3
        });
        datasets.push({
          label: intl.formatMessage({ id: 'pfa-bonus-graph' }),
          data: years.map((e, i) => (!i ? firstYearBonus : 0)),
          fill: false,
          showLine: true,
          spanGaps: true,
          backgroundColor: '#0FAF3F',
          maxBarThickness: 85,
          borderColor: '#3966f8',
          order: 4
        });
      }
      datasets.push({
        label: intl.formatMessage({ id: 'pfa-return' }),
        data: returnWithYearsToShow,
        fill: false,
        showLine: true,
        spanGaps: true,
        backgroundColor: '#0FAF3F',
        maxBarThickness: 85,
        borderColor: '#3966f8',
        order: 5,
        datalabels: {
          labels: {
            value: {
              color: '#ffffff',
              font: { size: inBarLabelFontSize },
              formatter: (value, context) => {
                return context.dataset.data[context.dataIndex] ? context.dataset.label : '';
              }
            },
            total: {
              color: '#665EFF',
              align: 'end',
              anchor: 'end',
              clip: false,
              padding: {
                top: 7,
                bottom: -4
              },
              font: { size: topLabelFontSize, weight: 600 },
              formatter: (value, context) => {
                if (negativeReturn[context.dataIndex] && graphMode === 'pfa-return-only-title') {
                  return null;
                }
                return (
                  formatNumber(
                    totalAmount[context.dataIndex] -
                      (graphMode === 'pfa-return-combo-title'
                        ? 0
                        : (principal ?? 0) -
                          (base ?? 0) -
                          (context.dataIndex ? firstYearBonus ?? 0 : 0) +
                          expenseWithYearsToShow[context.dataIndex]),
                    intl
                  ) +
                  (context.dataIndex
                    ? '\n(' +
                      (profitRateWithYearsToShow[context.dataIndex] > 0 ? '+' : '') +
                      (!isNaN(profitRateWithYearsToShow[context.dataIndex])
                        ? profitRateWithYearsToShow[context.dataIndex]?.toFixed(2) ?? '0'
                        : '-') +
                      '%)'
                    : '')
                );
              },
              textAlign: 'center'
            }
          }
        }
      });
    } else {
      datasets.push({
        label: intl.formatMessage({ id: 'pfa-base-graph' }),
        data: years.map((e, i) => (!i ? base : 0)),
        fill: false,
        showLine: true,
        spanGaps: true,
        backgroundColor: '#3966f8',
        maxBarThickness: 85,
        borderColor: '#3966f8',
        order: 0
      });
      datasets.push({
        label: intl.formatMessage({ id: 'pfa-cash-minus-loan' }),
        data: cashValueMinusLoan,
        fill: false,
        showLine: true,
        spanGaps: true,
        backgroundColor: '#3EC7F8',
        maxBarThickness: 85,
        borderColor: '#3966f8',
        order: 3,
        datalabels: {
          labels: {
            value: {
              color: '#ffffff',
              font: { size: inBarLabelFontSize },
              formatter: (value, context) => {
                return context.dataset.data[context.dataIndex] ? context.dataset.label : '';
              }
            },
            total: {
              color: '#665EFF',
              align: 'end',
              anchor: 'end',
              clip: false,
              padding: {
                top: 7,
                bottom: -4
              },
              font: { size: topLabelFontSize, weight: 600 },
              formatter: (value, context) => {
                if (context.dataIndex === 0) {
                  return formatNumber(base ?? 0, intl);
                }
                return (
                  formatNumber(cashValueMinusLoan[context.dataIndex], intl) +
                  '\n(' +
                  (base ? cashValueMinusLoan[context.dataIndex] / base : 0)?.toFixed(2) +
                  'x)'
                );
              },
              textAlign: 'center'
            }
          }
        }
      });
    }
  }

  return (
    <Card className="pfa-graph-card pfa-card pf-graph-card-container">
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ textAlign: 'end' }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography color="textSecondary" style={DisplayNameTypeStyle}>
                      {displayName}
                    </Typography>
                  </Grid>
                  {!template && (
                    <Grid item>
                      <IconButton onClick={openEditDisplayNamesDialog} style={EditButtonStyle}>
                        <EditIcon style={EditButtonIconStyle} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end" alignItems="center">
                  <IconButton aria-label={'More Info'} color={'primary'} onClick={() => setOpen(true)} ref={buttonRef}>
                    <HelpOutlineIcon />
                  </IconButton>
                  <TempSelect
                    style={{ fontSize: '12px' }}
                    variant="outlined"
                    value={graphMode}
                    onChange={e => onChangeGraphMode(e.target.value)}
                  >
                    <MenuItem value="pfa-return-combo-title">
                      {intl.formatMessage({ id: 'pfa-return-combo-title' })}
                    </MenuItem>
                    <MenuItem value="pfa-return-only-title">
                      {intl.formatMessage({ id: 'pfa-return-only-title' })}
                    </MenuItem>
                    <MenuItem value="pfa-cash-minus-loan-title">
                      {intl.formatMessage({ id: 'pfa-cash-minus-loan' })}
                    </MenuItem>
                  </TempSelect>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">{intl.formatMessage({ id: graphMode })}</Typography>
          </Grid>
          <Grid item xs={12}>
            {graphMode === 'pfa-return-combo-title' && (
              <BarChart
                showGraph={showGraph}
                years={years}
                datasets={datasets}
                intl={intl}
                inBarLabelFontSize={inBarLabelFontSize}
              />
            )}
            {graphMode === 'pfa-return-only-title' && (
              <BarChart
                showGraph={showGraph}
                years={years}
                datasets={datasets}
                intl={intl}
                inBarLabelFontSize={inBarLabelFontSize}
              />
            )}
            {graphMode === 'pfa-cash-minus-loan-title' && (
              <BarChart
                showGraph={showGraph}
                years={years}
                datasets={datasets}
                intl={intl}
                inBarLabelFontSize={inBarLabelFontSize}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
      <Popover
        TransitionComponent={Zoom}
        open={open}
        anchorEl={_.get(buttonRef, 'current')}
        onClose={() => setOpen(false)}
        onExited={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            textAlign: 'center',
            padding: '14px',
            fontSize: '14px',
            maxWidth: '300px'
          }
        }}
      >
        <div
          style={{
            whiteSpace: 'pre-wrap',
            textAlign: 'left'
          }}
        >
          {intl.formatMessage({ id: 'pfa-combo-help' })}
        </div>
      </Popover>
    </Card>
  );
};

export default GraphCard;
