import React, { useRef, useEffect } from 'react';
import shave from 'shave';

const ShavedText = props => {
  const { maxHeight, children, ...rest } = props;
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      shave(ref.current, maxHeight);
    }
  });

  return (
    <div ref={ref} {...rest}>
      {children}
    </div>
  );
};

export default ShavedText;
