import React from 'react';
import { injectIntl } from 'react-intl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { SlideTransition } from '../../../../components/Transition/Transition';
import { withRouter } from 'react-router';
import { getSearchObject } from '../../../../utils/router';

const EmailRegisteredDialog = props => {
  const { intl, history, email, open, onClose } = props;

  const signIn = () => {
    const searchObj = getSearchObject(history);
    const { planMode, couponId, campaignCode, ti } = searchObj || {};

    if (planMode || couponId || campaignCode || ti) {
      const urlSearchParams = new URLSearchParams({ ...searchObj, email: email });
      history.push({ pathname: '/subscription', search: `?${urlSearchParams.toString()}` });
    } else {
      history.push({ pathname: '/login', search: `?email=${email}` });
    }
    onClose();
  };

  return (
    <Dialog open={open} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'alreadyregistered' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'login-wif-this-email' })}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={signIn}>
          {intl.formatMessage({ id: 'Sign In' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(withRouter(EmailRegisteredDialog));
