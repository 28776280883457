export const CURRENCIES = [
  { value: 'HKD' },
  { value: 'USD' },
  { value: 'TWD' },
  { value: 'CNY' },
  { value: 'SGD' },
  { value: 'MYR' },
  { value: 'MOP' },
  { value: 'EUR' },
  { value: 'GBP' },
  { value: 'AUD' },
  { value: 'CAD' },
  { value: 'NZD' },
  { value: 'JPY' }
];
