import NativeOrWeb from '../../utils/native';

export const getContentJson = async ({ contentLink, localContent }) => {
  return process.env.NODE_ENV === 'production' || !localContent
    ? (
        await (
          await fetch(`${contentLink}?t=${new Date().toISOString()}`, {
            headers: { Pragma: 'no-cache' },
            cache: 'no-cache'
          })
        ).json()
      ).data
    : (await localContent).data;
};

export const getContentLink = contentId => {
  switch (contentId) {
    case 'help-center':
      return 'https://static.portfoplus.com/help-center/contents.json';
    case 'hot-topic':
      if (NativeOrWeb.isNativePlatform()) {
        return 'https://static.portfoplus.com/hot-topic/contents.json';
      } else {
        return 'https://static.portfoplus.com/hot-topic/contents.json'; //naming change at every deploy to solve cache problem
      }
    default:
      return undefined;
  }
};

export const getLocalContent = contentId => {
  switch (contentId) {
    case 'help-center':
      return import('../../constants/information-contents/help-center/contents');
    case 'hot-topic':
      return import('../../constants/information-contents/hot-topic/contents');
    default:
      return undefined;
  }
};
