import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from './Transition/Transition';

class AlertDialog extends React.Component {
  render() {
    const {
      open,
      handleClose,
      intl,
      handleUpdateUserProfile,
      title,
      showUpdateButton,
      content,
      showOk,
      description
    } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          {content && (
            <DialogContent>
              {intl.formatMessage({
                id: 'The selected item is a premium function'
              })}
            </DialogContent>
          )}
          {description && <DialogContent>{description}</DialogContent>}
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {intl.formatMessage({ id: !showOk ? 'Cancel' : 'OK' })}
            </Button>
            {showUpdateButton && (
              <Button onClick={handleUpdateUserProfile} color="primary" autoFocus>
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
