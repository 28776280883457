import React from 'react';

const FormSubmitWrapper = props => {
  const { onSubmit, children } = props;

  const _onSubmit = event => {
    event.preventDefault();
    onSubmit();
  };

  return <form onSubmit={_onSubmit}>{children}</form>;
};

export default FormSubmitWrapper;
