import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../store/control/duck';
import { SlideTransition } from '../Transition/Transition';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { WebAppVersionContext } from '../../contexts/WebAppVersionContext';
import NativeOrWeb from '../../utils/native';

const NativeUpdatedDialog = props => {
  const { versionString, versionDescription } = useContext(WebAppVersionContext);

  const { intl, nativeUpdatedDialog, toggleDialog } = props;

  const { closed } = nativeUpdatedDialog || {};

  const close = () => {
    NativeOrWeb.resetNativeUpdated()
      .then()
      .catch(console.log);
    toggleDialog('nativeUpdated', { ...nativeUpdatedDialog, closed: true });
  };
  const onExited = () => toggleDialog('nativeUpdated', false);

  return (
    <Dialog
      open={!!nativeUpdatedDialog && !closed}
      TransitionProps={{
        onExited: onExited
      }}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'native-updated-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Typography>{intl.formatMessage({ id: 'native-updated-dialog-content' }, { versionString })}</Typography>
        {versionDescription && <Typography style={{ whiteSpace: 'pre-wrap' }}>{versionDescription}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const container = connect(
  state => ({
    nativeUpdatedDialog: state.control.nativeUpdatedDialog
  }),
  {
    toggleDialog
  }
)(NativeUpdatedDialog);

export default injectIntl(container);
