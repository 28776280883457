import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const TermsCheckbox = props => {
  const { intl, checked, error, onChange } = props;

  return (
    <FormControlLabel
      className="checkbox"
      control={
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
          color="primary"
          name="tacChecked"
          checked={checked}
          onChange={onChange}
        />
      }
      label={
        <Fragment>
          <Typography variant="overline" color="textSecondary">
            {`${intl.formatMessage({ id: 'agree-with-pfp' })} `}
            <a href="https://www.portfoplus.com/terms.html" target="_blank" rel="noopener noreferrer">
              <u>{intl.formatMessage({ id: 'Terms of Service' })}</u>
            </a>
            {` & `}
            <a href="https://www.portfoplus.com/privacy.html" target="_blank" rel="noopener noreferrer">
              <u>{intl.formatMessage({ id: 'Privacy Policy' })}</u>
            </a>
          </Typography>

          {error ? <Typography color="error">{error}</Typography> : null}
        </Fragment>
      }
    />
  );
};

export default injectIntl(TermsCheckbox);
