import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

class AnimatedLinearProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValue: 0,
      kickStart: false,
      savedBarValue: undefined
    };
  }

  changeBarValue = () => {
    const { barValue } = this.props;
    this.timer = setInterval(() => {
      if (parseInt(barValue) > this.state.initialValue && this.state.initialValue <= 100) {
        this.setState({ initialValue: this.state.initialValue + 3 });
      } else {
        clearInterval(this.timer);
      }
    }, 38);
  };

  changeKickStart = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.setState({
      initialValue: 0,
      kickStart: true,
      savedBarValue: this.props.barValue ? this.props.barValue : 0
    });
  };

  render() {
    const { className, barValue } = this.props;
    const { kickStart, savedBarValue } = this.state;

    if (
      (barValue && !kickStart) ||
      (!isNaN(barValue) &&
        barValue !== undefined &&
        parseInt(savedBarValue) !== parseInt(barValue) &&
        savedBarValue !== undefined)
    ) {
      this.changeKickStart();
      this.changeBarValue();
    }

    return (
      <LinearProgress
        className={className}
        variant={
          parseInt(savedBarValue) > this.state.initialValue && this.state.initialValue <= 100 ? 'buffer' : 'determinate'
        }
        valueBuffer={
          parseInt(barValue) > this.state.initialValue && this.state.initialValue <= 100 ? this.state.initialValue : 0
        }
        value={this.state.initialValue}
      />
    );
  }
}

export default AnimatedLinearProgressBar;
