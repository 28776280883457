import DoNotShowAgainTooltip from '../DoNotShowAgainTooltip/DoNotShowAgainTooltip';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { updateUserDetails } from '../../../store/user/duck';

const AdviserFirstGuidelineTooltip = props => {
  const { userDetails, updateUserDetails, ...rest } = props;

  return (
    <DoNotShowAgainTooltip
      doNotShowAgain={_.get(userDetails, 'firstLoginGuidelineDone', false)}
      onChangeDoNotShowAgain={event => updateUserDetails({ firstLoginGuidelineDone: event.target.checked })}
      hideCloseButton={true}
      {...rest}
    />
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails
  }),
  {
    updateUserDetails
  }
)(AdviserFirstGuidelineTooltip);

export default container;
