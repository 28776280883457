import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';

/***********************************
 * Action Types
 ***********/

export const GET_BUSINESS_SUMMARY = 'portfoplus/summary/GET_BUSINESS_SUMMARY';
export const GET_BUSINESS_SUMMARY_SUCCESS = 'portfoplus/summary/GET_BUSINESS_SUMMARY_SUCCESS';
export const GET_BUSINESS_SUMMARY_ERROR = 'portfoplus/summary/GET_BUSINESS_SUMMARY_ERROR';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  businessSummaryPhase: INIT,
  businessSummaryData: [],
  error: null,
  isSubmitting: false
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_BUSINESS_SUMMARY: {
      return state
        .set('businessSummaryPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_BUSINESS_SUMMARY_SUCCESS: {
      const { payload } = action;
      return state
        .set('businessSummaryData', payload.data)
        .set('businessSummaryPhase', payload.success)
        .set('error', null);
    }

    case GET_BUSINESS_SUMMARY_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('businessSummaryPhase', ERROR);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getBusinessSummary = data => ({
  type: GET_BUSINESS_SUMMARY,
  payload: data
});

/***********************************
 * Epics
 ***********/

const getBusinessSummaryEpic = action$ =>
  action$.ofType(GET_BUSINESS_SUMMARY).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getBusinessSummary(action.payload))
      .map(payload => ({
        type: GET_BUSINESS_SUMMARY_SUCCESS,
        payload
      }))
      .catch(error =>
        Rx.Observable.of({
          type: GET_BUSINESS_SUMMARY_ERROR,
          payload: { error }
        })
      );
  });

export const summaryEpic = combineEpics(getBusinessSummaryEpic);
