import React from 'react';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../../../components/Transition/Transition';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, DialogContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const ConfirmSwitchClientDialog = props => {
  const { intl, open, onClose, onConfirm } = props;

  return (
    <Dialog open={open} TransitionComponent={SlideTransition}>
      <DialogTitle>{intl.formatMessage({ id: 'fin-py-change-client-wo-save' })}</DialogTitle>
      <DialogContent>{intl.formatMessage({ id: 'Inputted data will be lost, confirm?' })}</DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ConfirmSwitchClientDialog);
