import { Doughnut } from 'react-chartjs-2';
import React from 'react';
import { injectIntl } from 'react-intl';
import { PercentageTooltipLabeler, RoundedEdgePlugin } from '../../../../../../utils/chart-js';
import './UserDistributionChart.scss';

class UserDistributionChart extends React.PureComponent {
  render() {
    const { intl, counts } = this.props;

    const total = counts.male + counts.female + counts.unknown;

    const data = {
      datasets: [
        {
          data: [counts.male, counts.female, counts.unknown],
          backgroundColor: ['#3966f8', '#FF6A74', '#E0E0E0'],
          hoverBackgroundColor: ['#198af9', '#ff4a56', '#CCCCCC'],
          weight: 1,
          labels: [
            intl.formatMessage({ id: 'Male' }),
            intl.formatMessage({ id: 'Female' }),
            intl.formatMessage({ id: 'Unknown' })
          ]
        }
      ],
      texts: [
        {
          subtexts: [
            {
              text: intl.formatMessage({ id: 'No. of Clients' }),
              color: '#757575'
            }
          ],
          fontSizeFactor: 1,
          fontWeight: '400'
        },
        {
          subtexts: [
            {
              text: (total ? total : 0).toString(),
              color: '#3966f8'
            }
          ],
          fontSizeFactor: 2,
          fontWeight: '400'
        }
      ]
    };

    const options = {
      animation: {
        duration: 3000
      },
      cutoutPercentage: 80,
      aspectRatio: 1,
      plugins: {
        datalabels: {
          display: false
        },
        roundedEdge: true
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: PercentageTooltipLabeler
        }
      }
    };

    return (
      <div className="user-distrib-chart">
        <Doughnut data={data} options={options} plugins={RoundedEdgePlugin} height={null} width={null} />
      </div>
    );
  }
}

export default injectIntl(UserDistributionChart);
