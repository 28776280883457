import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import './MyMedicalCardsCard.scss';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import _ from 'lodash';
import { getMedicalCards } from '../../../store/medicalCard/duck';
import { INIT } from '../../../constants/phase';
import MyMedicalCardsContainer from './MyMedicalCardsContainer/MyMedicalCardsContainer';
import { withRouterInnerRef } from '../../../utils/router';
import { tracking } from '../../../utils/tracking';
import PhoneIcon from '@material-ui/icons/Phone';
import MyMedicalCardsSupportDialog from './MyMedicalCardsSupportDialog/MyMedicalCardsSupportDialog';
import NativeOrWeb from '../../../utils/native';
import { isMedicalCardRenewable } from '../../../utils/medical-card';
import { getMixCareDoctorListUrl, getMixCareRenewLink } from '../../../utils/mixcare';

const PFPLUS_URL = process.env.PFPLUS_URL;

const CardStyle = {
  padding: 16,
  boxShadow: 'var(--my-shadow)',
  position: 'relative'
};

const TitleStyle = {
  fontSize: '125%',
  fontWeight: 'bold'
};

const ContentContainerStyle = {
  width: '100%'
};

const MyMedicalCardsContainerGridStyle = {
  flexGrow: 1
};

const MyMedicalCardsActionsGridStyle = {
  width: 96,
  paddingLeft: 8,
  fontSize: '85%'
};

const RenewButtonStyle = {
  padding: '4px 84x',
  minWidth: 'unset',
  minHeight: 'unset',
  lineHeight: 1
};

const MyMedicalCardsCard = forwardRef((props, ref) => {
  const { intl, history, medicalCards, getMedicalCardsPhase, getMedicalCards } = props;

  const shouldTriggerEnlarged = useRef(false);
  const [enlarged, setEnlarged] = useState(undefined);
  const [selectedCardNo, setSelectedCardNo] = useState(false);
  const [supportDialog, setSupportDialog] = useState(false);

  const currentMedicalCard = _.find(medicalCards, { cardNo: selectedCardNo });
  const isCurrentMedicalCardRenewable = isMedicalCardRenewable(currentMedicalCard);

  const hasMedicalCard = (medicalCards || []).length > 0;

  const myMedicalCardContainerRef = useRef(null);
  const toggleMyMedicalCard = () => {
    if (!enlarged) {
      shouldTriggerEnlarged.current = true;
      support();
    } else {
      setEnlarged(false);
    }
  };

  const onChangeCard = (event, newCardNo) => setSelectedCardNo(newCardNo);
  const checkCard = () => {
    tracking('My Profile: My Medical Card Check');
    toggleMyMedicalCard();
  };
  const clinic = () => {
    tracking('My Profile: My Medical Card Clinic');
    window.open(getMixCareDoctorListUrl(), '_blank');
  };
  const terms = () => {
    tracking('My Profile: My Medical Card Details');
    window.open('https://w3.portfoplus.com/partner/medical-card-details/', '_blank');
  };
  const support = () => {
    setSupportDialog(true);
  };

  const closeSupportDialog = () => {
    setSupportDialog(false);
    if (shouldTriggerEnlarged.current) {
      shouldTriggerEnlarged.current = false;
      setEnlarged(true);
    }
  };

  const renew = () => {
    tracking('My Profile: My Medical Card Renew');
    window.open(getMixCareRenewLink({ cardNo: selectedCardNo }), '_blank');
  };

  useEffect(() => {
    if (getMedicalCardsPhase === INIT) {
      getMedicalCards();
    }
  }, [getMedicalCardsPhase, getMedicalCards]);

  useEffect(() => {
    if (!selectedCardNo && hasMedicalCard) {
      setSelectedCardNo(medicalCards[0].cardNo);
    }
  }, [medicalCards]);

  useImperativeHandle(ref, () => ({
    toggle: toggleMyMedicalCard
  }));

  return (
    <Fragment>
      <MyMedicalCardsSupportDialog open={supportDialog} onClose={closeSupportDialog} />
      <Card className="my-medical-card-card" style={CardStyle}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item style={{ width: '100%' }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography style={TitleStyle}>
                  {intl.formatMessage({ id: hasMedicalCard ? 'my-med-card' : 'my-medical-info' })}
                </Typography>
              </Grid>
              {isCurrentMedicalCardRenewable && (
                <Grid item>
                  <Button color="primary" variant="contained" onClick={renew} style={RenewButtonStyle}>
                    {intl.formatMessage({ id: 'renew' })}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={ContentContainerStyle}>
            <Grid container alignItems="flex-end">
              <Grid item style={MyMedicalCardsContainerGridStyle}>
                <MyMedicalCardsContainer
                  ref={myMedicalCardContainerRef}
                  medicalCards={medicalCards}
                  selectedCardNo={selectedCardNo}
                  onChangeCard={onChangeCard}
                  enlarged={enlarged}
                  onToggleEnlarged={toggleMyMedicalCard}
                />
              </Grid>
              <Grid item style={MyMedicalCardsActionsGridStyle}>
                <Grid container direction="column" alignItems="flex-start">
                  <Grid item>
                    <Button startIcon={<ZoomInIcon />} onClick={checkCard} disabled={!hasMedicalCard}>
                      {intl.formatMessage({ id: 'med-card-check' })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button startIcon={<ZoomInIcon />} onClick={clinic} disabled={!hasMedicalCard}>
                      {intl.formatMessage({ id: 'med-card-clinic-info' })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button startIcon={<ZoomInIcon />} onClick={terms} disabled={!hasMedicalCard}>
                      {intl.formatMessage({ id: 'med-card-tc' })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button startIcon={<PhoneIcon />} onClick={support} disabled={!hasMedicalCard}>
                      {intl.formatMessage({ id: 'support' })}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
});

const container = connect(
  state => ({
    medicalCards: state.medicalCard.medicalCards,
    getMedicalCardsPhase: state.medicalCard.getMedicalCardsPhase
  }),
  {
    getMedicalCards
  },
  null,
  {
    forwardRef: true
  }
)(MyMedicalCardsCard);

export default injectIntl(withRouterInnerRef(container), { withRef: true });
