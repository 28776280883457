export const NO_NAVIGATION_URLS = [
  '/welcome',
  '/login',
  '/verify_user',
  '/verify_Adviser',
  '/signup',
  '/vip',
  '/lp',
  '/profile-plus',
  '/adviser/',
  '/frame',
  '/pa-signup',
  '/whiteboard-remote',
  '/wallet-pass-link/'
];

export const UNAUTHORIZED_REDIRECT_EXCEPTIONAL_PATHS = [
  '/auth',
  '/login',
  '/vip',
  '/userType',
  '/clientSignUp',
  '/signup',
  '/forgot_password',
  '/reset_password/',
  '/verify_user/',
  '/verify_Adviser/',
  '/dem',
  '/dem/',
  '/dice',
  '/targetsCalculator',
  '/share/',
  '/share-a/',
  '/share_link_view',
  '/view_share_details',
  '/insurer_detail',
  '/emergency_info',
  '/consentResponse/',
  '/referral_response/',
  '/qr_code/',
  '/accpetInvitation/',
  '/conf_call/',
  '/cheque_out',
  '/premium_financing_trial',
  '/lp',
  '/medical-card-qr-apply',
  '/adviser/',
  '/pa-signup',
  '/whiteboard-remote'
];

export const MENU_BUTTON_PATHS = [
  '^/home$',
  '^/adviser_business$',
  '^/advisorDashboard$',
  '^/client$',
  '^/my-profile$',
  '^/c-dashboard$',
  '^/c-analysis$',
  '^/client-tools$'
];
