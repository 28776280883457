import { spawn, Thread, Worker } from 'threads';
import store from '../store';
import { UPDATE_COVID_DATA } from '../store/covid/duck';

const CASE_BUILDING_CSV_URL = `${process.env.COVID_URL}/covid-data/case-building`;
const HOME_CONFINEE_BUILDING_CSV_URL = `${process.env.COVID_URL}/covid-data/home-confinee-building`;

const getS3Url = async url => {
  const response = await fetch(url, { credentials: 'include' });
  if (response.status === 200) {
    return await response.text();
  } else {
    return null;
  }
};

export const findVirusRelatedClients = async clientList => {
  const localSetting = localStorage.getItem('threshold');
  let threshold;
  try {
    threshold = localSetting ? JSON.parse(localSetting) : undefined;
  } catch (e) {
    alert('Parse localStorage thresholds error');
  }

  // console.log(store);

  const data = store.getState().covid.data;

  let result;
  let caseBuildingUrl, homeConfineeBuildingUrl;
  if (!data) {
    caseBuildingUrl = await getS3Url(CASE_BUILDING_CSV_URL, { credentials: 'include' });
    homeConfineeBuildingUrl = await getS3Url(HOME_CONFINEE_BUILDING_CSV_URL);
    if (!caseBuildingUrl || !homeConfineeBuildingUrl) {
      alert('Fetching data failed');
      return [];
    }
  }

  if (window.Worker) {
    const virusWorker = await spawn(new Worker('./virus-worker-new'));
    result = await virusWorker.run(data, clientList, threshold, { caseBuildingUrl, homeConfineeBuildingUrl });
    Thread.terminate(virusWorker);
  } else {
    result = await require('./virus-worker-new').run(data, clientList, threshold, {
      caseBuildingUrl,
      homeConfineeBuildingUrl
    });
  }
  store.dispatch({ type: UPDATE_COVID_DATA, payload: result.data });

  return result.clients;
};
