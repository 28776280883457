import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';
import { getCIToReachData, getLifeToReachData, getSavingToReachData } from '../../utils/analysisCalculator';
import _ from 'lodash';
import {
  getCIBetterThanKey,
  getDebtPlusMortgageLoan,
  getLifeBetterThanKey,
  getTotalCICoverage,
  getTotalLifeCoverage,
  getTotalSavingCoverage
} from '../../utils/analysis';
import { getUserCurrencyFromStore } from '../../utils/store';

/***********************************
 * Action Types
 ***********/

export const GET_ANALYSIS_DATA = 'portfoplus/analysis/GET_ANALYSIS_DATA';
export const GET_ANALYSIS_DATA_SUCCESS = 'portfoplus/analysis/GET_ANALYSIS_DATA_SUCCESS';
export const GET_ANALYSIS_DATA_ERROR = 'portfoplus/analysis/GET_ANALYSIS_DATA_ERROR';
export const UPDATE_ANALYSIS_INPUTS = 'UPDATE_ANALYSIS_INPUTS';
export const UPDATE_ANALYSIS_LIFE_EXPORT = 'UPDATE_ANALYSIS_LIFE_EXPORT';
export const UPDATE_ANALYSIS_CI_EXPORT = 'UPDATE_ANALYSIS_CI_EXPORT';
export const UPDATE_ANALYSIS_SAVING_EXPORT = 'UPDATE_ANALYSIS_SAVING_EXPORT';
export const UPDATE_ANALYSIS_OVERALL_EXPORT = 'UPDATE_ANALYSIS_OVERALL_EXPORT';
export const RESET_ANALYSIS_DEFAULT_VALUES = 'RESET_DEFAULT_VALUES';
export const RESET_ANALYSIS = 'RESET_ANALYSIS';
export const UPDATE_SELECTED_POLICIES = 'UPDATE_SELECTED_POLICIES';
export const UPDATE_LIFE_SELECTED_POLICIES = 'UPDATE_LIFE_SELECTED_POLICIES';
export const UPDATE_CI_SELECTED_POLICIES = 'UPDATE_CI_SELECTED_POLICIES';
export const UPDATE_SAVING_SELECTED_POLICIES = 'UPDATE_SAVING_SELECTED_POLICIES';

/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const defaultValues = {
  monthlyExpense: 35000,
  debt: 600000,
  age: 35,
  monthlyIncome: 50000,
  currentAsset: 0,

  showAge: false,

  // life tab specific inputs
  lifeIncludeMortgageAmount: false,
  lifeTopUp: 0,
  lifeExtraMoney: 100000,
  lifeFamilySupportNeeded: 18,
  lifeSelectedPolicies: undefined,

  // ci tab specific inputs
  ciTopUp: 0,
  ciClaimCoverExpenses: 2,
  ciSelectedPolicies: undefined,

  // saving tab specific inputs
  savingTopUp: 0,
  savingCustomTopUp: 0,
  savingTerms: 20,
  savingShowAccumulationValueAt: 20,
  savingReturnRate: 0,
  savingInflationRate: 0,
  savingRetireAfter: 30,
  savingRetirementTerms: 20,
  savingRetirementReturnRate: 0,
  savingRetirementInflationRate: 0,
  savingSelectedPolicies: undefined,

  // common outputs
  lifeToReachData: {},
  lifeBetterThanKey: '',
  ciToReachData: {},
  ciBetterThanKey: '',
  savingToReachData: {},

  // life tab specific outputs
  lifeTotalCoverage: 0,
  totalOutStandingLoan: 0,
  debtPlusMortgageLoan: 0,

  // ci tab specific outputs
  ciTotalCoverage: 0,

  // saving tab specific outputs
  savingTotalCoverage: 0,

  // for overall export
  overallExport: {
    exportRadar: true,
    exportInputPanel: true
  },

  // for life export
  lifeExport: {
    exportInputPanel: true,
    exportScore: true,
    exportTotal: true,
    exportDuration: true,
    exportIncome: true,
    exportRanking: false
  },

  // for ci export
  ciExport: {
    exportInputPanel: true,
    exportScore: true,
    exportTotal: true,
    exportBenchmark: true,
    exportRanking: false
  },

  // for saving export
  savingExport: {
    exportInputPanel: true,
    exportScore: true,
    exportTotal: true,
    exportAccumulation: true,
    exportRetirementUsage: false
  }
};

export const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated
  PVPChartData: {
    life: [],
    ci: [],
    saving: []
  },
  lifeData: {
    totalLifeCoverage: 0,
    totalMonthlyPremium: 0,
    count: 0
  },
  ciData: {
    totalCICoverage: 0,
    totalMonthlyPremium: 0,
    count: 0
  },
  figureData: {},
  medicalData: {
    counts: {}
  },
  savingData: {
    totalPremium: 0,
    totalPremiumSaving: 0,
    totalWithGuarantee: 0,
    totalWithGuaranteeNoIdea: 0,
    totalWithILAS: 0,
    totalMarketValue: 0,
    count: 0,
    guaranteeCount: 0,
    noIdeaCount: 0,
    ilasCount: 0
  },
  mortgageData: {
    totalOutStandingLoan: 0,
    count: 0
  },
  getAnalysisPhase: INIT,
  getAnalysisMessage: null,
  businessSummaryData: [],
  error: null,
  isSubmitting: false,
  ...defaultValues
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/
const SESSION_PATHS = [
  // 'monthlyExpense', 'debt', 'age', 'monthlyIncome', 'currentAsset',
  'lifeIncludeMortgageAmount',
  'lifeTopUp',
  'lifeExtraMoney',
  'lifeFamilySupportNeeded',
  'ciTopUp',
  'ciClaimCoverExpenses',
  'savingTopUp',
  'savingCustomTopUp',
  'savingTerms',
  'savingShowAccumulationValueAt',
  'savingReturnRate',
  'savingInflationRate',
  'savingRetireAfter',
  'savingRetirementTerms',
  'savingRetirementReturnRate',
  'savingRetirementInflationRate'
];

const saveAnalysisSessionStorage = state => {
  SESSION_PATHS.forEach(path => {
    if (state[path] === undefined || Number.isNaN(state[path])) {
      sessionStorage.removeItem(path);
    } else {
      sessionStorage.setItem(path, state[path]);
    }
  });
};

export const loadAnalysisSessionStorage = () => {
  try {
    return SESSION_PATHS.reduce((accumulator, path) => {
      let pathValue = _.defaultTo(JSON.parse(sessionStorage.getItem(path)), undefined);
      if (pathValue !== undefined) {
        accumulator[path] = pathValue;
      }
      return accumulator;
    }, {});
  } catch (error) {
    console.log(error);
    cleanAnalysisSessionStorage();
    return {};
  }
};

export const cleanAnalysisSessionStorage = () => {
  SESSION_PATHS.forEach(path => sessionStorage.removeItem(path));
};

const formatLifeStates = state => {
  const { lifeTopUp, debt, lifeIncludeMortgageAmount, monthlyExpense, age } = state;

  // Retrieve lifeTotalCoverage from props
  const totalLifeCoverage = _.get(state, 'lifeData.totalLifeCoverage', 0);

  // Adjust total life value used for calculation based on checkboxes and top up
  const lifeTotalCoverage = getTotalLifeCoverage(totalLifeCoverage, lifeTopUp);

  // Retrieve outstanding loan from props & find adjusted debt amount
  const totalOutStandingLoan = _.get(state, 'mortgageData.totalOutStandingLoan', 0);
  const debtPlusMortgageLoan = getDebtPlusMortgageLoan(debt, totalOutStandingLoan, lifeIncludeMortgageAmount);

  // Get life to reach data
  const lifeToReachData = getLifeToReachData(lifeTotalCoverage, monthlyExpense, debtPlusMortgageLoan, age);

  // Get life's key of peer score
  const lifeBetterThanKey = getLifeBetterThanKey(state.figureData, lifeTotalCoverage /*- debtPlusMortgageLoan*/);

  // set states
  return state
    .set('lifeTotalCoverage', lifeTotalCoverage)
    .set('totalOutStandingLoan', totalOutStandingLoan)
    .set('debtPlusMortgageLoan', debtPlusMortgageLoan)
    .set('lifeToReachData', lifeToReachData)
    .set('lifeBetterThanKey', lifeBetterThanKey); // set lifeBetterThanKey
};

const formatCIStates = (state, currency) => {
  const { monthlyIncome, ciTopUp, ciClaimCoverExpenses } = state;

  // Retrieve fixed ci coverage
  const ciFixedTotalCoverage = _.get(state, 'ciData.totalCICoverage', 0);

  // Adjust total ci value used for calculation based on checkbox and top up
  const ciTotalCoverage = getTotalCICoverage(ciFixedTotalCoverage, ciTopUp);

  // Get ci to reach data
  const ciToReachData = getCIToReachData(ciTotalCoverage, monthlyIncome, ciClaimCoverExpenses, currency);

  // Get ci's key of peer score
  const ciBetterThanKey = getCIBetterThanKey(state.figureData, ciTotalCoverage);

  // set states
  return state
    .set('ciTotalCoverage', ciTotalCoverage)
    .set('ciToReachData', ciToReachData)
    .set('ciBetterThanKey', ciBetterThanKey); //set ciBetterThanKey
};

const formatSavingStates = state => {
  const { monthlyIncome, savingTopUp, savingCustomTopUp } = state;
  const totalPremium = _.get(state, 'savingData.totalPremium', 0);
  const savingTotalCoverage = getTotalSavingCoverage(totalPremium, savingTopUp + savingCustomTopUp);
  const savingToReachData = getSavingToReachData(savingTotalCoverage, monthlyIncome);

  return state.set('savingTotalCoverage', savingTotalCoverage).set('savingToReachData', savingToReachData);
};

// eslint-disable-next-line complexity, max-statements
export default function(state = new InitialState(), action = {}) {
  switch (action.type) {
    case GET_ANALYSIS_DATA: {
      return state
        .set('getAnalysisPhase', LOADING)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_ANALYSIS_DATA_SUCCESS: {
      const { payload, currency } = action;

      let updatedState = state;

      const newTotalMarketValue = _.get(payload, 'savingData.totalMarketValue');
      if (newTotalMarketValue !== state.get('savingData').totalMarketValue) {
        updatedState = updatedState.set('currentAsset', newTotalMarketValue);
      }

      updatedState = updatedState
        .set('PVPChartData', payload.PVPChartData)
        .set('lifeData', payload.lifeData)
        .set('ciData', payload.ciData)
        .set('figureData', payload.figureData)
        .set('medicalData', payload.medicalData)
        .set('savingData', payload.savingData)
        .set('mortgageData', payload.mortgageData)
        .set('getAnalysisPhase', payload.success)
        .set('getAnalysisMessage', payload.message)
        .set('error', null);

      if (payload.mortgageData && payload.mortgageData.count === 0) {
        updatedState = updatedState.set('lifeIncludeMortgageAmount', false);
      }

      updatedState = formatLifeStates(updatedState);
      updatedState = formatCIStates(updatedState, currency);
      updatedState = formatSavingStates(updatedState);

      return updatedState;
    }

    case GET_ANALYSIS_DATA_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('getAnalysisPhase', ERROR);
    }

    case UPDATE_ANALYSIS_INPUTS: {
      const { payload, currency } = action;
      let updatedState = state.merge(payload);

      updatedState = formatLifeStates(updatedState);
      updatedState = formatCIStates(updatedState, currency);
      updatedState = formatSavingStates(updatedState);

      saveAnalysisSessionStorage(updatedState);
      return updatedState;
    }

    case UPDATE_ANALYSIS_LIFE_EXPORT: {
      return state.set('lifeExport', _.merge(state.get('lifeExport'), action.payload));
    }

    case UPDATE_ANALYSIS_CI_EXPORT: {
      return state.set('ciExport', _.merge(state.get('ciExport'), action.payload));
    }

    case UPDATE_ANALYSIS_SAVING_EXPORT: {
      return state.set('savingExport', _.merge(state.get('savingExport'), action.payload));
    }

    case UPDATE_ANALYSIS_OVERALL_EXPORT: {
      return state.set('overallExport', _.merge(state.get('overallExport'), action.payload));
    }

    case UPDATE_SELECTED_POLICIES: {
      return state
        .set('lifeSelectedPolicies', action.lifeSelectedPolicies)
        .set('ciSelectedPolicies', action.ciSelectedPolicies)
        .set('savingSelectedPolicies', action.savingSelectedPolicies);
    }

    case UPDATE_LIFE_SELECTED_POLICIES: {
      return state.set('lifeSelectedPolicies', action.policies);
    }

    case UPDATE_CI_SELECTED_POLICIES: {
      return state.set('ciSelectedPolicies', action.policies);
    }

    case UPDATE_SAVING_SELECTED_POLICIES: {
      return state.set('savingSelectedPolicies', action.policies);
    }

    case RESET_ANALYSIS_DEFAULT_VALUES: {
      return state.merge(defaultValues);
    }

    case RESET_ANALYSIS: {
      return new InitialState();
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const getAnalysisData = data => ({
  type: GET_ANALYSIS_DATA,
  payload: data
});

export const updateAnalysisInputs = data => (dispatch, getState) => {
  const state = getState();
  const currency = getUserCurrencyFromStore(state);
  dispatch({
    type: UPDATE_ANALYSIS_INPUTS,
    payload: data,
    currency
  });
};

export const updateAnalysisLifeExport = data => ({
  type: UPDATE_ANALYSIS_LIFE_EXPORT,
  payload: data
});

export const updateAnalysisCIExport = data => ({
  type: UPDATE_ANALYSIS_CI_EXPORT,
  payload: data
});

export const updateAnalysisSavingExport = data => ({
  type: UPDATE_ANALYSIS_SAVING_EXPORT,
  payload: data
});

export const updateAnalysisOverallExport = data => ({
  type: UPDATE_ANALYSIS_OVERALL_EXPORT,
  payload: data
});

export const resetAnalysisDefaultValues = () => ({
  type: RESET_ANALYSIS_DEFAULT_VALUES
});

export const resetAnalysis = () => ({ type: RESET_ANALYSIS });

export const updateSelectedPolicies = ({ lifeSelectedPolicies, ciSelectedPolicies, savingSelectedPolicies }) => ({
  type: UPDATE_SELECTED_POLICIES,
  lifeSelectedPolicies,
  ciSelectedPolicies,
  savingSelectedPolicies
});
export const updateLifeSelectedPolicies = policies => ({ type: UPDATE_LIFE_SELECTED_POLICIES, policies: policies });
export const updateCISelectedPolicies = policies => ({ type: UPDATE_CI_SELECTED_POLICIES, policies: policies });
export const updateSavingSelectedPolicies = policies => ({ type: UPDATE_SAVING_SELECTED_POLICIES, policies: policies });

/***********************************
 * Epics
 ***********/

const getAnalysisDataEpic = (action$, store$) =>
  action$.ofType(GET_ANALYSIS_DATA).mergeMap(action => {
    return Rx.Observable.fromPromise(api.getAnalysisData(action.payload))
      .map(payload => {
        const state = store$.getState();
        const currency = getUserCurrencyFromStore(state);

        return {
          type: GET_ANALYSIS_DATA_SUCCESS,
          payload,
          currency
        };
      })
      .catch(error =>
        Rx.Observable.of({
          type: GET_ANALYSIS_DATA_ERROR,
          payload: { error }
        })
      );
  });

const updateSelectedPoliciesEpic = (action$, store$) =>
  action$
    .ofType(
      UPDATE_LIFE_SELECTED_POLICIES,
      UPDATE_CI_SELECTED_POLICIES,
      UPDATE_SAVING_SELECTED_POLICIES,
      UPDATE_SELECTED_POLICIES
    )
    .mergeMap(action => {
      const state = store$.getState();
      const clientStore = state.client;
      const analysisStore = state.analysis;
      const viewingClientId = _.get(clientStore, 'viewingClient._id');

      const payload = { userId: viewingClientId };

      if (analysisStore.lifeSelectedPolicies && Array.isArray(analysisStore.lifeSelectedPolicies)) {
        payload.lp =
          analysisStore.lifeSelectedPolicies.length > 0
            ? analysisStore.lifeSelectedPolicies.map(policy => policy._id)
            : '[]';
      }

      if (analysisStore.ciSelectedPolicies && Array.isArray(analysisStore.ciSelectedPolicies)) {
        payload.cp =
          analysisStore.ciSelectedPolicies.length > 0
            ? analysisStore.ciSelectedPolicies.map(policy => policy._id)
            : '[]';
      }

      if (analysisStore.savingSelectedPolicies && Array.isArray(analysisStore.savingSelectedPolicies)) {
        payload.sp =
          analysisStore.savingSelectedPolicies.length > 0
            ? analysisStore.savingSelectedPolicies.map(policy => policy._id)
            : '[]';
      }

      return Rx.Observable.of({ type: GET_ANALYSIS_DATA, payload });
    });

export const analysisEpic = combineEpics(getAnalysisDataEpic, updateSelectedPoliciesEpic);
