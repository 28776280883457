import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Container, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { handleSignOut } from '../../../../store/user/duck';

const FabContainerStyle = {
  position: 'fixed',
  bottom: 10,
  right: 10,
  zIndex: 5
};

const ContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%'
};

const GridContainerStyle = {};

const ImageGridStyle = {
  textAlign: 'center',
  marginBottom: 50
};

const ImageStyle = {
  maxWidth: 450,
  padding: 8,
  width: '100%'
};

const TypoGridStyle = {
  padding: '0 16px'
};

const TitleTypoStyle = {
  fontSize: '200%'
};

const ContentTypoStyle = {
  fontSize: '150%',
  whiteSpace: 'pre-wrap',
  color: '#333'
};

const ButtonStyle = {
  textDecoration: 'underline',
  marginBottom: 60
};

const NativeFallback = props => {
  const { intl } = props;

  const dispatch = useDispatch();

  const fab = () =>
    window.open(
      'https://api.whatsapp.com/send?phone=85266370320&text=(APP-PC)%20%E4%BD%A0%E5%A5%BD%EF%BC%81%E6%88%91%E6%83%B3%E6%9F%A5%E8%A9%A2',
      '_blank'
    );
  const back = () => {
    dispatch(handleSignOut());
  };

  return (
    <Fragment>
      <span style={FabContainerStyle} onClick={fab}>
        <img src="img/whatsapp.png" alt="whatsapp" width="50" />
      </span>
      <Container maxWidth="sm" style={ContainerStyle}>
        <Grid container spacing={4} style={GridContainerStyle}>
          <Grid item xs={12} style={ImageGridStyle}>
            <img src="img/logo_kyc.png" alt="portfoplus" style={ImageStyle} />
          </Grid>
          <Grid item xs={12} style={TypoGridStyle}>
            <Typography align="center" color="primary" gutterBottom={true} style={TitleTypoStyle}>
              {intl.formatMessage({ id: 'sub-expired-native-title' })}
            </Typography>
            <Typography style={ContentTypoStyle}>{intl.formatMessage({ id: 'sub-expired-native-content' })}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <Button color="primary" style={ButtonStyle} onClick={back}>
                  {intl.formatMessage({ id: 'back-to-home' })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default injectIntl(NativeFallback);
