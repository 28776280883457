import * as api from './api';

/***********************************
 * Action Types
 ***********/
export const UPDATE_FIGURE = 'figure/UPDATE_FIGURE';

/***********************************
 * Initial State
 ***********/
const initialState = {
  average: 0,
  protectionAmountOf25: 0,
  protectionAmountOf50: 0,
  protectionAmountOf75: 0,
  ciProtectionAmountOf25: 0,
  ciProtectionAmountOf50: 0,
  ciProtectionAmountOf75: 0
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FIGURE:
      return action.payload;
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const getFigure = () => async (dispatch, getState) => {
  try {
    const data = await api.getFigure();
    dispatch({ type: UPDATE_FIGURE, payload: data });
    return data;
  } catch (e) {
    dispatch({ type: UPDATE_FIGURE, payload: initialState });
  }
};
