import React, { PureComponent, Fragment } from 'react';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import './styles.scss';
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Grid,
  Typography,
  withStyles,
  Popover,
  MenuItem
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import EN_TRANSLATION from '../../store/locale/messages/en.json';
import ZH_HANT_HK_TRANSLATION from '../../store/locale/messages/zh-Hant-HK.json';
import ZH_TRANSLATION from '../../store/locale/messages/zh.json';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../components/Transition/Transition';
import { isSubscriptionAllowAction } from '../../utils/user-subscription-store';
import ProRequiredBlur from '../../components/SubscriptionComponents/ProRequiredDialog/ProRequiredBlur';
import MoreInfoTrigger from '../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import IconToast from '../../components/NewToast';
import ShareButtonGroup from '../../components/ShareButtonGroup/ShareButtonGroup';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import TempSelect from '../../components/TempSelect/TempSelect';
import ClientEntryMailButton from '../../components/ClientEntry/ClientEntryMailButton/ClientEntryMailButton';
import { getInsurerTitleEn, isInsurerOther } from '../../utils/insurer';

const ReminderActiveToggleButtonStyle = {
  minHeight: 18,
  minWidth: 18,
  fontSize: '11px',
  padding: 0
};

const LineItemStyle = {
  padding: 9,
  borderBottom: '1px solid #eee'
};

const ReminderPayMethodButtonStyle = {
  padding: 0,
  minWidth: 30,
  maxHeight: 25.8,
  minHeight: 25.8
};

const PolicyNameTypoStyle = {
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

const LineItem = props => {
  const { left, right } = props;
  return (
    <Grid item xs={12} style={LineItemStyle}>
      <Grid container justify="space-between">
        {left && <Grid item>{left}</Grid>}
        {right && <Grid item>{right}</Grid>}
      </Grid>
    </Grid>
  );
};

const ThisExpansionPanelSummary = withStyles({
  root: {
    padding: 0
  },
  content: {
    margin: 0
  }
})(ExpansionPanelSummary);

class ReminderView extends PureComponent {
  constructor(props) {
    super(props);

    this.checkSubscription(props);

    this.state = {
      userId: '',
      remindTime: 21,
      openPopup: false,
      policyDueDate: '',
      selectedId: '',
      selectedIndex: -1,
      policyData: [],
      menu: false,
      messagePopUp: false,
      insurerName: '',
      policySubCat: '',
      policyDueDate1: '',
      policyNumber: '',
      val1: '',
      index1: '',
      open: false,
      popUpIndex: '',
      clientStatus: '',
      inactiveUserPremiumReminderModal: false,
      enable: true,
      messageLanguage: '',
      editMessageLanguage: false,
      insurer: '',
      datePickerOpen: false
    };
    this.openAdviserMenu = this.openAdviserMenu.bind(this);
    this.adviserMessagePopUp = this.adviserMessagePopUp.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openInactivePremiumModal = this.openInactivePremiumModal.bind(this);
    this.enableClick = this.enableClick.bind(this);
    this.translateMessage = this.translateMessage.bind(this);
  }

  checkSubscription(props) {
    if (props.subscriptionInitialFetched) {
      if (props.userDetails && props.userDetails.userType === 'Adviser') {
        isSubscriptionAllowAction(
          { type: 'core', nicknames: ['Pro', 'Ultra'] },
          {
            customMessage: props.intl.formatMessage({ id: 'upgrade-msg-reminder' }),
            cb: isUpgrade => {
              if (!isUpgrade) props.history.goBack();
            }
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var clientId = JSON.parse(window.localStorage.getItem('clientId'));

      if ((clientId || this.props.userDetails.userType === 'User') && this.props.location.state)
        this.props.renderHeaderButton(false);
      else this.props.renderHeaderButton(true);
    }
  }

  componentDidMount() {
    var clientId = JSON.parse(window.localStorage.getItem('clientId'));

    if ((clientId || this.props.userDetails.userType === 'User') && this.props.location.state)
      this.props.renderHeaderButton(false);
    else this.props.renderHeaderButton(true);
  }

  UNSAFE_componentWillMount() {
    //when adviser will fetcht any users reminder so we will send the clientid to get reminder list else nothing
    var clientId = JSON.parse(window.localStorage.getItem('clientId'));
    this.setState({ selectedId: clientId });
    var userId = clientId ? clientId : undefined;
    this.setState({ userId: userId });
    this.props.getPolicyList(userId);
    const { location } = this.props;
    let clientData = _.get(location, 'state.clientFactsheetDetail');
    let clientStatus = _.get(clientData, 'clientIncData.clientStatus', '');
    this.setState({ clientStatus: clientStatus });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.getReminderPhase === true) {
      const state = this.state;
      state.openPopup = true;
      this.setState(state);
    }
    if (nextProps.addReminderPhase === true) {
      var data = this.state.userId;
      this.props.getPolicyList(data);
      nextProps.getReminderBadgeCount();
    }
    if (nextProps.subscriptionInitialFetched && !this.props.subscriptionInitialFetched) {
      this.checkSubscription(nextProps);
    }
  }

  //when user enable/disable the reminder
  onToggle(v, index, event) {
    event.stopPropagation();
    let { policyDueDate, remindTime, dateIndex } = this.state;
    const { intl } = this.props;
    var postData = {};
    policyDueDate = policyDueDate !== '' && dateIndex === index ? policyDueDate : v.policyDueDate;
    let remindMeDay =
      v.remindMeDay && v.remindMeDay !== undefined && v.remindMeDay !== null ? v.remindMeDay : remindTime;
    //If reminder is monthly
    if (v.frequency === '1') {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Reminder is monthly' })), { className: 'new-toast' });
      return;
    }
    //if policy due date is not selected
    if (!policyDueDate) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Please Set the Next Payment Date' })), {
        className: 'new-toast'
      });
      return;
    }

    // only need to check payment due date when the reminder is turning on
    if (!v.reminderActive) {
      let currentDate = moment(new Date());
      let date2 = moment(policyDueDate);
      //when payment due date is selected past of current day
      if (date2.isBefore(currentDate)) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'Payment due date need to be in the future' })), {
          className: 'new-toast'
        });
        return;
      }
    }

    //Get the reminder date by policy due date according to the remind me day , here subract the remind me day in policy due date
    var reminderDate = moment
      .parseZone(policyDueDate)
      .subtract(remindMeDay, 'days')
      .format('YYYY-MM-DD');
    postData.remindMeDay = remindMeDay;
    postData.policyDueDate = policyDueDate;
    postData.portfolioId = v._id;
    postData.reminderActive = !v.reminderActive;
    postData.reminderDate = reminderDate;
    this.props.updateReminder(postData);
  }

  //When adviser on the switch
  onAdviserToggle(v, index) {
    let { policyDueDate, dateIndex } = this.state;
    let postData = {};
    const { updateReminder, intl } = this.props;
    policyDueDate = policyDueDate !== '' && dateIndex === index ? policyDueDate : v.policyDueDate;
    if (v.frequency === '1') {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Reminder is monthly' })), { className: 'new-toast' });
      return;
    }
    //if policy due date is not selected
    if (!policyDueDate) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Please Set the Next Payment Date' })), {
        className: 'new-toast'
      });
      return;
    }

    // only need to check payment due date when the reminder is turning on
    if (!v.adviserReminderActive) {
      let currentDate = moment(new Date());
      let date2 = moment(policyDueDate);
      //when payment due date is selected past of current day
      if (date2.isBefore(currentDate)) {
        toast.info(IconToast('error', intl.formatMessage({ id: 'Next Payment Date need to be in the future' })), {
          className: 'new-toast'
        });
        return;
      }
    }

    postData.portfolioId = v._id;
    postData.adviserReminderActive = v.frequency !== '1' ? !v.adviserReminderActive : false;
    postData.updateBy = 'Adviser';
    updateReminder(postData);
  }

  //when select the remind time
  handleChange(v, index, e) {
    const { intl } = this.props;
    let remindTime = e.target.value;
    this.setState({ remindTime: remindTime, remindIndex: index });
    let { policyDueDate, dateIndex } = this.state;
    var postData = {};
    policyDueDate = policyDueDate !== '' && dateIndex === index ? policyDueDate : v.policyDueDate;
    //if policy due date is not selected
    if (!policyDueDate) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Please Set the Next Payment Date' })), {
        className: 'new-toast'
      });
      return;
    }
    let currentDate = moment(new Date());
    let date2 = moment(policyDueDate);
    //Policy Due Date should be in future
    if (date2.isBefore(currentDate)) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Payment due date need to be in the future' })), {
        className: 'new-toast'
      });
      return;
    }
    //Get the reminder date by policy due date according to the remind me day , here subract the remind me day in policy due date
    var reminderDate = moment
      .parseZone(policyDueDate)
      .subtract(remindTime, 'days')
      .format('YYYY-MM-DD');
    postData.remindMeDay = remindTime;
    postData.policyDueDate = policyDueDate;
    postData.portfolioId = v._id;
    postData.reminderActive = v.reminderActive;
    postData.reminderDate = reminderDate;
    this.props.updateReminder(postData);
  }

  //when user change the payment due date
  handleDateChange(date, v, index) {
    let { remindTime } = this.state;
    var policyDueDate = date !== null && date !== undefined && date !== '' ? moment(date).format('YYYY-MM-DD') : null;
    var policyDueDate1 = date !== null ? moment.utc(policyDueDate).format('YYYY-MM-DDT00:00:00.00Z') : null;
    this.setState({
      policyDueDate: policyDueDate1,
      dateIndex: index,
      selectedId: v._id,
      datePickerOpen: false
    });
    var postData = {};
    let remindMeDay =
      v.remindMeDay && v.remindMeDay !== undefined && v.remindMeDay !== null ? v.remindMeDay : remindTime;
    //Get the reminder date by policy due date according to the remind me day , here subract the remind me day in policy due date
    var reminderDate = moment(policyDueDate)
      .subtract(remindMeDay, 'days')
      .format('YYYY-MM-DD');
    postData.remindMeDay = remindMeDay;
    postData.policyDueDate = policyDueDate;
    postData.portfolioId = v._id;
    postData.reminderActive = v.reminderActive;
    postData.reminderDate = reminderDate;
    this.props.updateReminder(postData);
  }

  //funciton to rotate the arrows
  changeState = (val, index) => {
    const { selectedId } = this.state;
    if (val._id === selectedId) {
      this.setState({
        selectedId: '',
        selectedIndex: '',
        insurerName: '',
        policyNumber: '',
        policyDueDate1: '',
        insurer: ''
      });
    } else {
      const insurer = _.get(val, 'insurerId', {});
      const isOtherInsurer = isInsurerOther(insurer);
      const insurerTitleEn = getInsurerTitleEn(insurer);
      this.setState({
        selectedId: val._id,
        selectedIndex: index,
        insurerName: isOtherInsurer ? _.get(val, 'insurer', '') : insurerTitleEn,
        policySubCat: _.get(val, 'subCategoryId.title', ''),
        policyNumber: _.get(val, 'policyNumber', ''),
        policyDueDate1:
          _.get(val, 'policyDueDate') !== null && _.get(val, 'policyDueDate') !== undefined
            ? moment.parseZone(_.get(val, 'policyDueDate')).format('DD-MMM-YYYY')
            : '',
        insurer: insurerTitleEn
      });
    }
  };

  insurerInfo(val) {
    this.props.history.push({
      pathname: '/insurer_detail',
      state: { reminderInfo: val.insurerId }
    });
  }

  openAdviserMenu(val, index) {
    this.setState({ menu: true, val1: val, index1: index });
  }

  adviserMessagePopUp(val) {
    const insurer = val.insurerId;
    const isOtherInsurer = isInsurerOther(insurer);
    const insurerTitleEn = getInsurerTitleEn(insurer);

    this.setState({
      messagePopUp: true,
      insurerName: isOtherInsurer ? _.get(val, 'insurer', '') : insurerTitleEn,
      policySubCat: _.get(val, 'subCategoryId.title', ''),
      policyNumber: _.get(val, 'policyNumber', ''),
      policyDueDate1:
        _.get(val, 'policyDueDate') !== null && _.get(val, 'policyDueDate') !== undefined
          ? _.get(val, 'policyDueDate')
          : '',
      insurer: insurerTitleEn
    });
  }

  clientMessages = () => {
    const { userDetails, location } = this.props;
    const {
      insurerName,
      policySubCat,
      policyNumber,
      policyDueDate1,
      messageLanguage,
      editMessageLanguage,
      insurer
    } = this.state;
    let clientData = _.get(location, 'state.clientFactsheetDetail');
    let clientName = _.get(clientData, 'clientIncData.name', '');

    let messageDefaultLanguage = editMessageLanguage ? messageLanguage : _.get(userDetails, 'language', '');

    let policyDate = policyDueDate1
      ? moment
          .parseZone(policyDueDate1)
          .locale(messageDefaultLanguage)
          .format('DD-MMM-YYYY')
      : '';
    let whatsAppMessage = `${this.translateMessage(
      'Hi',
      messageDefaultLanguage
    )}${' '}${clientName}${','}\n\n${this.translateMessage(
      'Remind you of the following policy due info:',
      messageDefaultLanguage
    )}\n${this.translateMessage('Insurer', messageDefaultLanguage)}${': '}${
      insurerName && insurer !== 'Other' ? this.translateMessage(insurerName, messageDefaultLanguage) : insurerName
    }\n${this.translateMessage('Type', messageDefaultLanguage)}${': '}${
      policySubCat ? this.translateMessage(policySubCat, messageDefaultLanguage) : ' '
    }\n${this.translateMessage('Policy No.', messageDefaultLanguage)}${': '}${policyNumber}\n${this.translateMessage(
      'Due date',
      messageDefaultLanguage
    )}${': '}${policyDate}\n\n${this.translateMessage(
      'Login',
      messageDefaultLanguage
    )}${' '}https://app.portfoplus.com${' '}${this.translateMessage(
      'for more information and payment methods',
      messageDefaultLanguage
    )}`;
    // let defaultMessage = _.get(userDetails, 'defaultMessage', undefined);
    return whatsAppMessage;
  };

  handleToggle = (event, index) => {
    event.stopPropagation();
    this.anchorEl = event.target;
    this.setState(state => ({ open: !state.open, popUpIndex: index }));
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ open: false });
  };

  closeModal() {
    this.setState({
      messagePopUp: false,
      inactiveUserPremiumReminderModal: false,
      editMessageLanguage: false
    });
  }

  openInactivePremiumModal = event => {
    event.stopPropagation();
    this.setState({ inactiveUserPremiumReminderModal: true });
  };

  enableClick() {
    this.setState({ enable: true });
  }

  translateMessage(val, language) {
    // define new component state 'language' instead of using user's language setting
    const langauge = language;
    let messages;
    // // format message according to language state and using the mappings
    // // clientName & insurerName are those dynamic values
    if (val !== '' && val !== undefined && val !== null) {
      if (langauge === 'en') {
        messages = EN_TRANSLATION.messages[val];
        // messages = intl.formatMessage(testMessages.reminderMessageEn, {
        //   messages: EN_TRANSLATION.messages[val]
        // });
      } else if (langauge === 'zh') {
        messages = ZH_TRANSLATION.messages[val];
        // messages = intl.formatMessage(testMessages.reminderMessageZh, {
        //   messages: ZH_TRANSLATION.messages[val]
        // });
      }
      if (langauge === 'zh-Hant-HK') {
        messages = ZH_HANT_HK_TRANSLATION.messages[val];
        // messages = intl.formatMessage(testMessages.reminderMessageEn, {
        //   messages: ZH_HANT_HK_TRANSLATION.messages[val]
        // });
      }
      return messages;
    }
  }

  handleChangeLanugage(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value, editMessageLanguage: true });
  }

  render() {
    const today = new Date();
    var year = today.getFullYear();
    var month = 12;
    var day = 0;
    const maxDate = new Date(year + 3, month, day);
    const { intl, policyData, userDetails, history, location } = this.props;
    const {
      policyDueDate,
      dateIndex,
      remindIndex,
      selectedId,
      selectedIndex,
      policyNumber,
      policyDueDate1,
      policySubCat,
      insurerName,
      open,
      popUpIndex,
      clientStatus,
      messageLanguage,
      editMessageLanguage,
      insurer,
      datePickerOpen
    } = this.state;
    var clientId = JSON.parse(window.localStorage.getItem('clientId'));
    let messageDefaultLanguage = editMessageLanguage ? messageLanguage : _.get(userDetails, 'language', '');

    let clientData = _.get(location, 'state.clientFactsheetDetail');
    let clientName = _.get(clientData, 'clientIncData.name', '');
    let clientEmail = _.get(clientData, 'clientIncData.email', '');
    let emailShow = _.get(clientData, 'clientIncData.emailShow', '');
    let policyDate = policyDueDate1
      ? moment
          .parseZone(policyDueDate1)
          .locale(messageDefaultLanguage)
          .format('DD-MMM-YYYY')
      : '';
    let message = `<span>${this.translateMessage(
      'Hi',
      messageDefaultLanguage
    )}${' '}${clientName}${','}</span><br /><br />
      <span>${this.translateMessage(
        'Remind you of the following policy due info:',
        messageDefaultLanguage
      )}</span><br />
      <span>${this.translateMessage('Insurer', messageDefaultLanguage)}${': '}${
      insurerName && insurer !== 'Other' ? this.translateMessage(insurerName, messageDefaultLanguage) : insurerName
    }</span><br />
      <span>${this.translateMessage('Type', messageDefaultLanguage)}${': '}${
      policySubCat ? this.translateMessage(policySubCat, messageDefaultLanguage) : ' '
    }</span><br />
      <span>${this.translateMessage('Policy No.', messageDefaultLanguage)}${': '}${policyNumber}</span><br />
      <span>${this.translateMessage('Due date', messageDefaultLanguage)}${': '}${policyDate}</span><br /><br />
      <span>${clientStatus !== 'Inactive' ? this.translateMessage('Login', messageDefaultLanguage) : ''}${' '}${
      clientStatus !== 'Inactive'
        ? `<a target="_blank" href ='https://app.portfoplus.com'>https://app.portfoplus.com</a>`
        : ''
    }${' '}${
      clientStatus !== 'Inactive'
        ? this.translateMessage('for more information and payment methods', messageDefaultLanguage)
        : ''
    }</span>`;

    let whatsAppMessage = `${this.translateMessage(
      'Hi',
      messageDefaultLanguage
    )}${' '}${clientName}${','}\n\n${this.translateMessage(
      'Remind you of the following policy due info:',
      messageDefaultLanguage
    )}\n${this.translateMessage('Insurer', messageDefaultLanguage)}${': '}${
      insurerName && insurer !== 'Other' ? this.translateMessage(insurerName, messageDefaultLanguage) : insurerName
    }\n${this.translateMessage('Type', messageDefaultLanguage)}${': '}${
      policySubCat ? this.translateMessage(policySubCat, messageDefaultLanguage) : ' '
    }\n${this.translateMessage('Policy No.', messageDefaultLanguage)}${': '}${policyNumber}\n${this.translateMessage(
      'Due date',
      messageDefaultLanguage
    )}${': '}${policyDate}\n\n${
      clientStatus !== 'Inactive' ? this.translateMessage('Login', messageDefaultLanguage) : ''
    }${' '}${clientStatus !== 'Inactive' ? `https://app.portfoplus.com` : ''}${' '}${
      clientStatus !== 'Inactive'
        ? this.translateMessage('for more information and payment methods', messageDefaultLanguage)
        : ''
    }`;
    return (
      <AppContentContainer>
        <div className="reminder" style={{ padding: 16 }}>
          <Dialog
            open={this.state.messagePopUp}
            onClose={() => this.setState({ messagePopUp: false })}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={SlideTransition}
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Client Reminder' })}</DialogTitle>
            <DialogContent>
              <div className="analysis-input-groups">
                <FormControl className="d-flex-box-lang input-group-option-select font-css client-reminder-color">
                  <RadioGroup
                    aria-label="inputGroupOption"
                    name="messageLanguage"
                    value={messageDefaultLanguage.toString()}
                    onChange={this.handleChangeLanugage.bind(this)}
                  >
                    <FormControlLabel
                      className="text-left language__select"
                      value={'en'}
                      control={<Radio color="primary" />}
                      label="ENG"
                    />
                    <FormControlLabel
                      className="text-left language__select"
                      value={'zh-Hant-HK'}
                      control={<Radio color="primary" />}
                      label="繁中"
                    />
                    <FormControlLabel
                      className="text-left language__select"
                      value={'zh'}
                      control={<Radio color="primary" />}
                      label="简中"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <ShareButtonGroup
                title={this.clientMessages()}
                url=""
                whatsappNumber={_.get(clientData, 'clientIncData.phoneNumber', '')}
                CopyTextFieldProps={{
                  multiline: true
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeModal} color="primary" autoFocus>
                {intl.formatMessage({ id: 'Close' })}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.inactiveUserPremiumReminderModal}
            onClose={this.closeModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            TransitionComponent={SlideTransition}
            fullWidth={true}
          >
            <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Connect with client' })}</DialogTitle>
            <DialogContent>
              {intl.formatMessage({
                id: 'CONNECT with Client to set the Premium Reminder.'
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeModal} color="primary" autoFocus>
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>

          <ProRequiredBlur>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <Grid
                  container
                  alignItems="center"
                  style={{
                    minHeight: 48,
                    boxShadow: 'var(--my-shadow)',
                    borderRadius: 5,
                    padding: '0 5px'
                  }}
                >
                  <Grid item xs={3}>
                    <Typography align="center">{intl.formatMessage({ id: 'Insurer' })}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography align="center">{intl.formatMessage({ id: 'Type' })}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography align="center">{intl.formatMessage({ id: 'Policy Name' })}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography align="center">{intl.formatMessage({ id: 'On/off' })}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <MoreInfoTrigger
                      titleIntlId="Premium Reminder"
                      contentIntlId={`moreInfo_${
                        userDetails.userType === 'Adviser' ? 'adviserReminder' : 'clientReminder'
                      }`}
                      style={{
                        padding: 8,
                        top: '50%',
                        right: 8,
                        transform: 'translate(0, -50%)'
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {policyData &&
                policyData.length > 0 &&
                policyData.map((val, index) => {
                  let flag = val.insurerId._id === '5a1be0f9dfaa60227838e426' ? true : false;
                  if (val.policyStartDate != null) {
                    var policyStartDate = moment.parseZone(val.policyStartDate).format('DD-MMM-YYYY');
                  } else {
                    policyStartDate = intl.formatMessage({
                      id: 'N.A.'
                    });
                  }
                  var premium = val.premium;
                  if (Number.isInteger(premium) === true) {
                    premium = premium.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  } else {
                    premium =
                      premium &&
                      parseFloat(premium)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  let collapseClass = this.state.selectedId === val._id ? 'collapse in' : 'collapse';
                  let policyType = val.subCategoryId.title === 'Critical Illness' ? 'Illness' : val.subCategoryId.title;
                  let dueDate = val.policyDueDate ? moment(val.policyDueDate) : null;

                  let finalDisplayDate = policyDueDate !== '' && dateIndex === index ? policyDueDate : dueDate;

                  return (
                    <Grid item xs={12} key={index}>
                      <ExpansionPanel
                        className="tile"
                        style={{
                          display: 'block',
                          boxShadow: 'var(--my-shadow)',
                          borderRadius: 5,
                          borderLeftWidth: 5,
                          borderLeftStyle: 'solid',
                          borderLeftColor:
                            val.renewalPolicy === true && userDetails.userType === 'Adviser' ? 'red' : 'transparent',
                          borderRightWidth: 5,
                          borderRightStyle: 'solid',
                          borderRightColor:
                            val.renewalPolicy === true && userDetails.userType !== 'Adviser' ? 'red' : 'transparent'
                        }}
                        onChange={() => this.changeState(val, index)}
                        expanded={selectedId === val._id}
                      >
                        <ThisExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          IconButtonProps={{
                            color: 'primary',
                            style: {
                              position: 'absolute',
                              padding: 8,
                              right: 8
                            }
                          }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={3}>
                              <Typography align="center" color="primary">
                                {flag ? (
                                  isInsurerOther(val.insurerId) ? (
                                    val.insurer
                                  ) : (
                                    intl.formatMessage({
                                      id: val.insurer
                                    })
                                  )
                                ) : (
                                  <img alt="insurer" src={val.insurerId.image} width="70" />
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography align="center">
                                {policyType === 'Medical'
                                  ? intl.formatMessage({ id: 'Med' })
                                  : policyType === 'Accident'
                                  ? intl.formatMessage({ id: 'Accid' })
                                  : intl.formatMessage({
                                      id: policyType
                                    })}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography align="center" style={PolicyNameTypoStyle}>
                                {val.policyName ? val.policyName : intl.formatMessage({ id: 'N.A.' })}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Grid container direction="column" justify="center" alignItems="center">
                                {userDetails && userDetails.userType === 'User' && (
                                  <Grid item>
                                    <Switch
                                      color="primary"
                                      key={index}
                                      checked={val.reminderActive}
                                      onChange={this.onToggle.bind(this, val, index)}
                                      style={{
                                        marginLeft: '0',
                                        display: 'inlineBlock'
                                      }}
                                      value="dynamic-class-name"
                                    />
                                  </Grid>
                                )}

                                {userDetails && userDetails.userType === 'Adviser' && (
                                  <Fragment>
                                    <Grid item>
                                      <Button
                                        style={{
                                          ...ReminderActiveToggleButtonStyle,
                                          color: '#ffffff',
                                          background: val.reminderActive === true ? '#3966f8' : undefined
                                        }}
                                        variant="contained"
                                        onClick={event => this.handleToggle(event, index)}
                                      >
                                        {intl.formatMessage({ id: 'C_reminder' })}
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        style={{
                                          ...ReminderActiveToggleButtonStyle,
                                          color: '#ffffff',
                                          background: val.adviserReminderActive === true ? '#3966f8' : undefined
                                        }}
                                        variant="contained"
                                        onClick={event => this.handleToggle(event, index)}
                                      >
                                        {intl.formatMessage({ id: 'A_reminder' })}
                                      </Button>
                                    </Grid>
                                    {popUpIndex === index && (
                                      <Popover
                                        open={open}
                                        anchorEl={this.anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'center'
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'center'
                                        }}
                                        onClick={event => event.stopPropagation()}
                                      >
                                        <Grid container style={{ padding: '6px 6px 6px 18px' }}>
                                          <Grid item xs={12}>
                                            <Grid container alignItems="center" justify="space-between">
                                              <Grid item>
                                                <Typography>{intl.formatMessage({ id: 'Client' })}</Typography>
                                              </Grid>
                                              <Grid item>
                                                <Switch
                                                  color="primary"
                                                  checked={val.reminderActive}
                                                  onChange={
                                                    clientStatus === 'Connected'
                                                      ? this.onToggle.bind(this, val, index)
                                                      : this.openInactivePremiumModal
                                                  }
                                                  style={{ marginLeft: '0' }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Grid container alignItems="center" justify="space-between">
                                              <Grid item>
                                                <Typography>{intl.formatMessage({ id: 'Adviser' })}</Typography>
                                              </Grid>
                                              <Grid item>
                                                <Switch
                                                  color="primary"
                                                  checked={
                                                    val.adviserReminderActive !== undefined &&
                                                    val.adviserReminderActive !== null &&
                                                    val.adviserReminderActive !== '' &&
                                                    val.frequency !== '1'
                                                      ? val.adviserReminderActive
                                                      : false
                                                  }
                                                  onChange={this.onAdviserToggle.bind(this, val, index)}
                                                  style={{ marginLeft: '0' }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Popover>
                                    )}
                                  </Fragment>
                                )}
                              </Grid>
                            </Grid>
                            <Grid item xs={1} />
                          </Grid>
                        </ThisExpansionPanelSummary>
                        <ExpansionPanelActions>
                          <Grid container>
                            {userDetails.userType === 'Adviser' && (
                              <LineItem
                                left={<Typography>{`${intl.formatMessage({ id: 'Reminder Message' })}:`}</Typography>}
                                right={
                                  <ClientEntryMailButton
                                    onClick={() => this.adviserMessagePopUp(val)}
                                    style={{
                                      marginRight: 0
                                    }}
                                  />
                                }
                              />
                            )}

                            <LineItem
                              left={<Typography>{`${intl.formatMessage({ id: 'Policy Name' })}:`}</Typography>}
                              right={<Typography>{val.policyName}</Typography>}
                            />

                            <LineItem
                              left={<Typography>{intl.formatMessage({ id: 'Policy Start Date' })}</Typography>}
                              right={<Typography>{policyStartDate}</Typography>}
                            />

                            <LineItem
                              left={<Typography>{`${intl.formatMessage({ id: 'Next Payment Date' })}:`}</Typography>}
                              right={
                                <Grid container alignItems="center" spacing={1}>
                                  <Grid item className="datepicker-group datepicker-group-no-underline">
                                    <DatePicker
                                      // style = {{pointerEvents: enable !== true ? 'none' : ''}}
                                      open={index === datePickerOpen}
                                      name="policyDueDate"
                                      format="DD-MMM-YYYY"
                                      minDate={moment().add(1, 'days')}
                                      maxDate={maxDate}
                                      minDateMessage=""
                                      clearable={false}
                                      value={
                                        finalDisplayDate === '' ||
                                        finalDisplayDate === null ||
                                        finalDisplayDate === undefined
                                          ? null
                                          : moment.parseZone(finalDisplayDate)
                                      }
                                      initialFocusedDate={
                                        finalDisplayDate === '' ||
                                        finalDisplayDate === null ||
                                        finalDisplayDate === undefined
                                          ? null
                                          : moment.parseZone(finalDisplayDate)
                                      }
                                      onChange={date => this.handleDateChange(date, val, index)}
                                      onClose={() => this.setState({ datePickerOpen: false })}
                                      autoOk={true}
                                      inputProps={{
                                        style: {
                                          color: '#3966f8',
                                          textAlign: 'right',
                                          padding: 0,
                                          width: 90
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className="edit-button"
                                      onClick={() => this.setState({ datePickerOpen: index })}
                                    >
                                      <CreateIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                              }
                            />

                            <LineItem
                              left={<Typography>{`${intl.formatMessage({ id: 'Payment Method' })}:`}</Typography>}
                              right={
                                <Grid container alignItems="center" spacing={1}>
                                  {val.paymentMethod && (
                                    <Grid item>
                                      <Typography>{intl.formatMessage({ id: val.paymentMethod })}</Typography>
                                    </Grid>
                                  )}
                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.insurerInfo.bind(this, val)}
                                      style={ReminderPayMethodButtonStyle}
                                    >
                                      {intl.formatMessage({ id: 'Info' })}
                                    </Button>
                                  </Grid>
                                </Grid>
                              }
                            />

                            <LineItem
                              left={<Typography>{`${intl.formatMessage({ id: 'Payment Frequency' })}:`}</Typography>}
                              right={
                                <Typography>
                                  {val.frequency === '1'
                                    ? intl.formatMessage({
                                        id: 'Per Month'
                                      })
                                    : val.frequency === '3'
                                    ? intl.formatMessage({
                                        id: 'Per Quarter'
                                      })
                                    : val.frequency === '6'
                                    ? intl.formatMessage({
                                        id: 'Per Half Year'
                                      })
                                    : val.frequency === '12'
                                    ? intl.formatMessage({
                                        id: 'Per Year'
                                      })
                                    : ''}
                                </Typography>
                              }
                            />

                            <LineItem
                              left={<Typography>{`${intl.formatMessage({ id: 'Premium' })}:`}</Typography>}
                              right={
                                <Typography>{`${intl.formatMessage({ id: val.currency })} ${premium}`}</Typography>
                              }
                            />

                            <LineItem
                              left={<Typography>{`${intl.formatMessage({ id: 'Remind me' })}:`}</Typography>}
                              right={
                                <div>
                                  <TempSelect
                                    className="remind-me-select"
                                    value={
                                      remindIndex === index
                                        ? this.state.remindTime
                                        : val.remindMeDay !== undefined && val.remindMeDay !== null
                                        ? val.remindMeDay
                                        : 21
                                    }
                                    onChange={this.handleChange.bind(this, val, index)}
                                    IconComponent={() => (
                                      <ChevronRightRoundedIcon color="primary" style={{ marginRight: -8 }} />
                                    )}
                                  >
                                    <MenuItem value={14}>{`2 ${intl.formatMessage({ id: 'Weeks Before' })}`}</MenuItem>
                                    <MenuItem value={21}>{`3 ${intl.formatMessage({ id: 'Weeks Before' })}`}</MenuItem>
                                    <MenuItem value={28}>{`4 ${intl.formatMessage({ id: 'Weeks Before' })}`}</MenuItem>
                                  </TempSelect>
                                </div>
                              }
                            />
                          </Grid>
                        </ExpansionPanelActions>
                      </ExpansionPanel>
                    </Grid>
                  );
                })}
            </Grid>
          </ProRequiredBlur>
        </div>
      </AppContentContainer>
    );
  }
}

export default ReminderView;
