import React from 'react';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import _ from 'lodash';
import { getLocalStorageClientId } from '../../../../../utils/client';
import { injectIntl } from 'react-intl';
import withStyles from '@material-ui/styles/withStyles';
import './PortfolioSelector.scss';
import ShareIcon from '@material-ui/icons/Share';
import { withRouter } from 'react-router';

const styles = theme => ({
  avatar: {
    boxShadow: theme.shadows[3]
  }
});

const PortfolioSelector = props => {
  const {
    intl,
    history,
    classes,
    shareFromData,
    onSelect,
    selectedId,
    defaultId,
    maxHeight,
    staticContext,
    ...rest
  } = props;
  const clientId = getLocalStorageClientId();

  const clickMe = () => onSelect(defaultId);
  const clickOthers = share => onSelect(_.get(share, '_id', ''));
  const share = () => history.push('/share_options');

  return !clientId ? (
    <div className="portfolio-selector" {...rest}>
      <Typography color="textSecondary" align="center">
        {intl.formatMessage({ id: 'Family' })}
      </Typography>
      <div
        className="scrollIcons"
        style={maxHeight ? { maxHeight: maxHeight - 26.67 - (!clientId ? 50 : 0) } : undefined}
      >
        <IconButton onClick={clickMe} style={{ padding: 0 }}>
          <Avatar
            classes={{ root: classes.avatar }}
            style={{
              backgroundColor: selectedId === defaultId || !selectedId ? '#3966f8' : '#cccccc',
              margin: '5px 5px'
            }}
          >
            {intl.formatMessage({ id: 'Me' })}
          </Avatar>
        </IconButton>
        {shareFromData &&
          shareFromData.map(share => {
            if (share.receiverType === 'Family') {
              let personInfo = (share.senderName || '').toString();
              return (
                <div key={_.get(share, '_id', '')}>
                  <IconButton onClick={() => clickOthers(share)} style={{ padding: 0 }}>
                    <Avatar
                      classes={{ root: classes.avatar }}
                      style={{
                        backgroundColor: selectedId === _.get(share, '_id', '') ? '#3966f8' : '#cccccc',
                        margin: '5px 5px'
                      }}
                    >
                      {personInfo
                        .split(' ')
                        .map(part => part.substring(0, 1).toUpperCase())
                        .join('')}
                    </Avatar>
                  </IconButton>
                </div>
              );
            }
            return '';
          })}
      </div>
      {!clientId && (
        <IconButton style={{ padding: 0 }} onClick={share}>
          <Avatar classes={{ root: classes.avatar }} style={{ margin: 5, color: '#3966f8', background: '#fff' }}>
            <ShareIcon />
          </Avatar>
        </IconButton>
      )}
    </div>
  ) : null;
};

export default injectIntl(withRouter(withStyles(styles)(PortfolioSelector)));
