import React from 'react';
import MoreInfoIconButton from '../../MoreInfoIconButton';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import _ from 'lodash';

class MoreInfoTrigger extends React.PureComponent {
  trigger = () => this.props.toggleDialog('moreInfo', _.pick(this.props, ['titleIntlId', 'contentIntlId', 'tutorial']));

  render() {
    return (
      <MoreInfoIconButton
        handleClick={this.trigger}
        {..._.omit(this.props, ['titleIntlId', 'contentIntlId', 'tutorial', 'toggleDialog'])}
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({}),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(MoreInfoTrigger);

export default container;
