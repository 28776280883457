import React, { PureComponent, Fragment } from 'react';
import './ClientDashboardDrawer.scss';
import LifeCoverageChart from './DrawerComponent/LifeCoverageChart';
import MonthlyPremiumChart from './DrawerComponent/MonthlyPremiumChart';
import MonthlyPaidChart from './DrawerComponent/MonthlyPaidChart';
import MoreInfoIconButton from '../MoreInfoIconButton';
import { Dialog, DialogActions } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SlideTransition } from '../Transition/Transition';
import { Button } from '@material-ui/core';
import { putClientDataToStore } from '../../utils/analysis';
import { updateAnalysisInputs } from '../../store/analysis/duck';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class ClientDashboardDrawer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  componentDidMount() {
    const { history, updateAnalysisInputs } = this.props;
    putClientDataToStore(history, updateAnalysisInputs);
  }

  render() {
    const { width, intl, style } = this.props;
    return (
      <Fragment>
        <div className={width > 960 ? 'dashboard-drawer' : ''} style={style}>
          <div className="dashboard-drawer-icon">
            <MoreInfoIconButton handleClick={() => this.setState({ modalIsOpen: true })} />
          </div>

          <div className="dashboard-drawer-graph-container">
            <LifeCoverageChart {...this.props} />
            <MonthlyPaidChart {...this.props} />
            <MonthlyPremiumChart {...this.props} />
          </div>
        </div>

        <Dialog
          open={this.state.modalIsOpen}
          onClose={() => this.setState({ modalIsOpen: false })}
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="scroll-dialog-title" className="figureColor">
            {intl.formatMessage({ id: 'dashboard-drawer-explain-title' })}
          </DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'dashboard-drawer-explain-content' })}</DialogContent>

          <DialogActions style={{ justifyContent: 'flex-end' }}>
            <Button color="primary" onClick={() => this.setState({ modalIsOpen: false })}>
              {intl.formatMessage({ id: 'Close' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const container = connect(
  state => ({
    monthlyIncome: state.analysis.monthlyIncome,
    age: state.analysis.age
  }),
  {
    updateAnalysisInputs
  }
)(ClientDashboardDrawer);

export default withRouter(container);
