import React from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import NativeOrWeb from '../../../../utils/native';
import { toast } from 'react-toastify';
import IconToast from '../../../NewToast';
import { injectIntl } from 'react-intl';
import { useWindowSize } from '../../../../utils/hooks';

const ContentBox = props => {
  const { intl, text, getHeight, disableCopy } = props;

  const [innerWidth, innerHeight] = useWindowSize();

  const copy = () => {
    NativeOrWeb.copyToClipboard(text)
      .then(() => {
        toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), { className: 'new-toast' });
      })
      .catch(console.log);
  };

  const height = getHeight({ innerWidth, innerHeight });

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        whiteSpace: 'pre-wrap',
        position: 'relative',
        height: height
      }}
    >
      {!disableCopy && (
        <IconButton
          edge="end"
          color="primary"
          onClick={copy}
          disabled={!text}
          size="small"
          style={{ position: 'absolute', right: 12, top: '50%', transform: 'translate(0, -50%)', zIndex: 1 }}
        >
          <FileCopyOutlinedIcon style={{ fontSize: '20' }} />
        </IconButton>
      )}

      {!text && (
        <Typography style={{ position: 'absolute', top: 12, left: 12, color: 'rgba(0, 0, 0, 0.3654)' }}>
          {disableCopy
            ? intl.formatMessage({ id: 'chat-gpt-content-placeholder-2' })
            : intl.formatMessage({ id: 'chat-gpt-content-placeholder' })}
        </Typography>
      )}

      {/* https://github.com/stipsan/react-spring-bottom-sheet/issues/68#issuecomment-757073061 */}
      <Grid container data-body-scroll-lock-ignore="true" style={{ height: '100%', overflow: 'auto' }}>
        <Grid item style={{ width: 'calc(100% - 24px)' }}>
          <div
            style={{
              padding: 12,
              zIndex: 2,
              height: '100%',
              minHeight: 150
            }}
          >
            {text}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default injectIntl(ContentBox);
