import React from 'react';
import { toast } from 'react-toastify';
import { getClientWhatsappUrl, openWhatsappUrl } from '../../../utils/client';
import './ClientContactDialog.css';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Checkbox, Grid } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { updateFactSheet } from '../../../store/client/duck';
import _ from 'lodash';
import PromotionHelpModal from '../../../components/PromotionHelpModal';
import IconToast from '../../../components/NewToast';
import NativeOrWeb from '../../../utils/native';
import AdviserInfoContactEntry from '../../../views/AdviserInfoPage/AdviserInfoContactEntry/AdviserInfoContactEntry';
import { formatPhoneNumber } from '../../../utils/user';
import ContactDialog from '../../ContactDialog/ContactDialog';

const FullWidthStyle = {
  width: '100%'
};

class ClientContactDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PromotionHelpModalOpen: false,
      msgOpt: false,
      initMsgOpt: false
    };
  }
  close = () => {
    const { contactDialog } = this.props;
    const { client } = contactDialog;
    if (this.state.msgOpt !== this.state.initMsgOpt) {
      const clientFactsheet = this.getClientFactsheet();
      this.props.updateFactSheet({
        id: clientFactsheet._id,
        clientId: client._id,
        isInactive: _.get(client, 'inactiveUser', false),
        key: 'msgOpt',
        value: this.state.msgOpt
      });
    }
    this.props.toggleDialog('contact', { ...this.props.contactDialog, closed: true });
  };
  closed = () => this.props.toggleDialog('connect', false);

  getClientFactsheet = () => {
    const { userDetails, contactDialog } = this.props;
    const { client } = contactDialog;
    let clientFactsheet;
    if (client) {
      if (client.inactiveUser) {
        clientFactsheet = client.factsheetId;
      } else {
        let advisorDetail = client.advisorDetails
          ? client.advisorDetails.find(advisorDetail => advisorDetail.email === userDetails.email)
          : undefined;
        if (advisorDetail !== undefined) {
          clientFactsheet = advisorDetail.factsheetId;
        }
      }
    }
    return clientFactsheet;
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      msgOpt: _.get(nextProps.contactDialog, 'client.factsheetId.msgOpt', false),
      initMsgOpt: _.get(nextProps.contactDialog, 'client.factsheetId.msgOpt', false)
    });
  }
  render() {
    const { intl, userDetails, contactDialog, adviserBusiness } = this.props;

    const defaultMessage = _.get(adviserBusiness, 'defaultMessage', undefined);

    const { client, closed } = contactDialog || {};

    let clientFactsheet = this.getClientFactsheet(),
      isInactiveEmail = false;

    if (client) {
      if (clientFactsheet && clientFactsheet.email) {
        let _id = client._id.toString();
        isInactiveEmail = clientFactsheet.email.indexOf(_id) !== -1;
      }
    }

    return (
      <ContactDialog
        open={!closed && !!contactDialog}
        onClose={this.close}
        onExited={this.closed}
        phoneNumber={clientFactsheet && clientFactsheet.phoneNumber}
        whatsapp={clientFactsheet && clientFactsheet.whatsapp}
        wechatId={clientFactsheet && clientFactsheet.wechatId}
        lineId={clientFactsheet && clientFactsheet.lineId}
        email={clientFactsheet && !isInactiveEmail ? clientFactsheet.email : undefined}
        customWhatsappMessage={
          clientFactsheet && getClientWhatsappUrl(intl, client, defaultMessage, clientFactsheet.whatsapp)
        }
        extras={
          <Grid item style={FullWidthStyle}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2}>
                {this.state.msgOpt ? (
                  <img alt="msgOpt" style={{ width: 20, height: 20 }} src="img/msgOptIn.png" />
                ) : (
                  <img alt="msgOpt" style={{ width: 20, height: 20 }} src="img/msgOptOut.png" />
                )}
              </Grid>
              <Grid item>
                <span className="center-col" style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {intl.formatMessage({ id: 'message-opt' })}
                  <HelpOutlineIcon
                    color="primary"
                    className="client--personality-info"
                    style={{ paddingBottom: '5px', cursor: 'pointer' }}
                    onClick={() => this.setState({ PromotionHelpModalOpen: true })}
                  />
                  <PromotionHelpModal
                    open={this.state.PromotionHelpModalOpen}
                    onClose={() => this.setState({ PromotionHelpModalOpen: false })}
                    intl={intl}
                  />
                </span>
              </Grid>
              <Grid item>
                <Checkbox
                  checked={this.state.msgOpt}
                  onChange={() => this.setState({ msgOpt: !this.state.msgOpt })}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        }
        customContent={
          !clientFactsheet ? (
            <div className="modal-text">{intl.formatMessage({ id: 'You are not connected to this client.' })}</div>
          ) : (
            undefined
          )
        }
      />
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    adviserBusiness: state.adviserDashboard.adviserBusiness,
    contactDialog: state.control.contactDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    updateFactSheet
  },
  // mergeProps
  null,
  // options
  {}
)(ClientContactDialog);

export default injectIntl(container);
