import React from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

export const NumberFormatWrapper = props => {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      thousandSeparator={true}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value !== '' ? parseFloat(values.value) : undefined
          }
        });
      }}
    />
  );
};

export default class NumberFormatTextField extends React.PureComponent {
  render() {
    const { label, name, value, onChange, inputProps, InputLabelProps, endAdornment, ...rest } = this.props;

    return (
      <TextField
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        InputLabelProps={_.merge({ classes: { root: 'form-label', focused: 'focused' } }, InputLabelProps)}
        InputProps={{
          inputComponent: NumberFormatWrapper,
          inputProps: {
            ...inputProps,
            'data-lpignore': 'true',
            'data-form-type': 'other'
          },
          endAdornment
        }}
        {...rest}
      />
    );
  }
}
