import Rx from 'rxjs/Rx';
import { Record } from 'immutable';
import { combineEpics } from 'redux-observable';
import { assign } from 'lodash';
import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
import Config from '../../config';
import * as api from './api';

/***********************************
 * Action Types
 ***********/

export const SUBMIT_ADVISER_MESSAGE = 'portfoplus/referral/SUBMIT_ADVISER_MESSAGE';
export const SUBMIT_ADVISER_MESSAGE_SUCCESS = 'portfoplus/referral/SUBMIT_ADVISER_MESSAGE_SUCCESS';
export const SUBMIT_ADVISER_MESSAGE_ERROR = 'portfoplus/referral/SUBMIT_ADVISER_MESSAGE_ERROR';

export const GET_ADVISOR_MESSAGE = 'portfoplus/referral/GET_ADVISOR_MESSAGE';
export const GET_ADVISOR_MESSAGE_SUCCESS = 'portfoplus/referral/GET_ADVISOR_MESSAGE_SUCCESS';
export const GET_ADVISOR_MESSAGE_ERROR = 'portfoplus/referral/GET_ADVISOR_MESSAGE_ERROR';

export const SUBMIT_REFERRAL = 'portfoplus/referral/SUBMIT_REFERRAL';
export const SUBMIT_REFERRAL_SUCCESS = 'portfoplus/referral/SUBMIT_REFERRAL_SUCCESS';
export const SUBMIT_REFERRAL_ERROR = 'portfoplus/referral/SUBMIT_REFERRAL_ERROR';

export const GET_REFERRAL_RECORDS = 'portfoplus/referral/GET_REFERRAL_RECORDS';
export const GET_REFERRAL_RECORDS_SUCCESS = 'portfoplus/referral/GET_REFERRAL_RECORDS_SUCCESS';
export const GET_REFERRAL_RECORDS_ERROR = 'portfoplus/referral/GET_REFERRAL_RECORDS_ERROR';

export const GET_NEW_REFERRAL_COUNT = 'portfoplus/referral/GET_NEW_REFERRAL_COUNT';
export const GET_NEW_REFERRAL_COUNT_SUCCESS = 'portfoplus/referral/GET_NEW_REFERRAL_COUNT_SUCCESS';
export const GET_NEW_REFERRAL_COUNT_ERROR = 'portfoplus/referral/GET_NEW_REFERRAL_COUNT_ERROR';

export const REFRESH_NEW_REFERRALS = 'portfoplus/referral/REFRESH_NEW_REFERRALS';
export const REFRESH_NEW_REFERRALS_SUCCESS = 'portfoplus/referral/REFRESH_NEW_REFERRALS_SUCCESS';
export const REFRESH_NEW_REFERRALS_ERROR = 'portfoplus/referral/REFRESH_NEW_REFERRALS_ERROR';

export const DELETE_RECORD = 'portfoplus/referral/DELETE_RECORD';
export const DELETE_RECORD_SUCCESS = 'portfoplus/referral/DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_ERROR = 'portfoplus/referral/DELETE_RECORD_ERROR';

export const UPDATE_REFERRAL_BY_ID = 'portfoplus/referral/UPDATE_REFERRAL_BY_ID';
export const UPDATE_REFERRAL_BY_ID_SUCCESS = 'portfoplus/referral/UPDATE_REFERRAL_BY_ID_SUCCESS';
export const UPDATE_REFERRAL_BY_ID_ERROR = 'portfoplus/referral/UPDATE_REFERRAL_BY_ID_ERROR';

export const INIT_PHASE = 'portfoplus/referral/INIT_PHASE';

/***********************************
 * Initial State
 ***********/
let sessionStorageReferral;

try {
  sessionStorageReferral = JSON.parse(sessionStorage.getItem('referral'));
} catch (error) {
  console.log(error);
}

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  token: null, // We need this here to tell InitialState that there is a token key,
  //                 but it will be reset below to what is in localStorage, unless a value
  //                 is passed in when the object is instanciated

  submitAdviserMessagePhase: INIT,
  advisorMessagePhase: INIT,
  submitReferralPhase: INIT,
  submitReferralMessageExtras: undefined,
  deletePhase: INIT,
  advisorMessage: {},
  referralRecords: [],
  newReferralCount: 0,
  message: null,
  error: null,
  isSubmitting: false,
  teamLogoUrl: {},
  referral: sessionStorageReferral || undefined,
  updateReferralPhase: INIT,
  updateReferralPhaseMessageExtras: undefined,
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    super(desiredValues);
  }
}

/***********************************
 * Reducer
 ***********/

// eslint-disable-next-line complexity, max-statements
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case SUBMIT_ADVISER_MESSAGE: {
      return state.set('submitAdviserMessagePhase', LOADING).set('error', null).set('isSubmitting', true);
    }

    case SUBMIT_ADVISER_MESSAGE_SUCCESS: {
      const { payload } = action;
      return state
        .set('submitAdviserMessagePhase', payload.success)
        .set('message', payload.message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SUBMIT_ADVISER_MESSAGE_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error).set('submitAdviserMessagePhase', ERROR);
    }

    case GET_ADVISOR_MESSAGE: {
      return state
        .set('advisorMessagePhase', LOADING)
        .set('submitAdviserMessagePhase', INIT)
        .set('error', null)
        .set('isSubmitting', true);
    }

    case GET_ADVISOR_MESSAGE_SUCCESS: {
      const { payload } = action;
      return state
        .set('advisorMessage', payload.data)
        .set('advisorMessagePhase', payload.success)
        .set('teamLogoUrl', payload.teamLogoUrl)
        .set('error', null);
    }

    case GET_ADVISOR_MESSAGE_ERROR: {
      const { error } = action.payload;
      return state.set('error', error).set('advisorMessagePhase', ERROR);
    }

    case SUBMIT_REFERRAL: {
      return state.set('submitReferralPhase', LOADING).set('error', null).set('isSubmitting', true);
    }

    case SUBMIT_REFERRAL_SUCCESS: {
      const { payload } = action;

      sessionStorage.setItem('referral', JSON.stringify(payload.referral));

      return state
        .set('referral', payload.referral)
        .set('submitReferralPhase', payload.success)
        .set('message', payload.message)
        .set('submitReferralMessageExtras', payload.extras)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case SUBMIT_REFERRAL_ERROR: {
      const { payload } = action;

      sessionStorage.removeItem('referral');

      return state
        .set('error', payload.error)
        .set('submitReferralPhase', ERROR)
        .set('submitReferralMessageExtras', undefined);
    }

    case GET_REFERRAL_RECORDS: {
      return state.set('error', null).set('deletePhase', INIT).set('isSubmitting', true);
    }

    case GET_REFERRAL_RECORDS_SUCCESS: {
      const { payload } = action;
      return state.set('error', null).set('referralRecords', payload.data).set('isSubmitting', false);
    }

    case GET_REFERRAL_RECORDS_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error);
    }

    case GET_NEW_REFERRAL_COUNT: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case GET_NEW_REFERRAL_COUNT_SUCCESS: {
      const { payload } = action;
      return state.set('error', null).set('newReferralCount', payload.data).set('isSubmitting', false);
    }

    case GET_NEW_REFERRAL_COUNT_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error);
    }

    case REFRESH_NEW_REFERRALS: {
      return state.set('error', null).set('isSubmitting', true);
    }

    case REFRESH_NEW_REFERRALS_SUCCESS: {
      return state.set('error', null).set('newReferralCount', 0).set('isSubmitting', false);
    }

    case REFRESH_NEW_REFERRALS_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error);
    }

    case DELETE_RECORD: {
      return state.set('error', null).set('deletePhase', LOADING).set('isSubmitting', true);
    }

    case DELETE_RECORD_SUCCESS: {
      const { payload } = action;
      return state
        .set('error', null)
        .set('deletePhase', payload.success)
        .set('message', payload.message)
        .set('isSubmitting', false);
    }

    case DELETE_RECORD_ERROR: {
      const { payload } = action;
      return state.set('error', payload.error);
    }

    case UPDATE_REFERRAL_BY_ID: {
      return state.set('error', null).set('updateReferralPhase', LOADING).set('isSubmitting', true);
    }

    case UPDATE_REFERRAL_BY_ID_SUCCESS: {
      const { referralId, referral, success, message, extras } = action.payload;

      const updatedReferralRecords = [...state.get('referralRecords')];
      const index = updatedReferralRecords.findIndex((referral) => referral._id === referralId);
      if (index !== -1) {
        updatedReferralRecords.splice(index, 1, referral);
      }
      const newReferralCount = updatedReferralRecords.filter((referral) => referral.isNewClient).length;

      return state
        .set('referralRecords', updatedReferralRecords)
        .set('newReferralCount', newReferralCount)
        .set('referral', referral)
        .set('updateReferralPhase', success)
        .set('updateReferralPhaseMessageExtras', extras)
        .set('message', message)
        .set('error', null)
        .set('isSubmitting', false);
    }

    case UPDATE_REFERRAL_BY_ID_ERROR: {
      const { payload } = action;

      sessionStorage.removeItem('referral');

      return state.set('error', payload.error);
    }

    case INIT_PHASE: {
      return state.set('submitReferralPhase', INIT);
    }

    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const submitAdviserMessage = (data) => ({
  type: SUBMIT_ADVISER_MESSAGE,
  payload: { data },
});

export const getAdvisorMessage = (data) => ({
  type: GET_ADVISOR_MESSAGE,
  payload: data,
});

export const getReferralRecords = () => ({
  type: GET_REFERRAL_RECORDS,
});

export const getNewReferralCount = () => ({
  type: GET_NEW_REFERRAL_COUNT,
});

export const refreshNewReferrals = () => ({
  type: REFRESH_NEW_REFERRALS,
});

export const submitReferral = (data) => ({
  type: SUBMIT_REFERRAL,
  payload: { data },
});

export const updateReferralById = (referralId, update) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_REFERRAL_BY_ID });
    const apiResult = await api.updateReferralById(referralId, update);
    dispatch({
      type: UPDATE_REFERRAL_BY_ID_SUCCESS,
      payload: { referralId, ...apiResult },
    });
    return true;
  } catch (error) {
    dispatch({ type: UPDATE_REFERRAL_BY_ID_ERROR, payload: { error: error } });
    return false;
  }
};

export const deleteRecord = (data) => ({
  type: DELETE_RECORD,
  payload: data,
});

export const initPhase = () => ({
  type: INIT_PHASE,
});

/***********************************
 * Epics
 ***********/

const submitAdviserMessageEpic = (action$) =>
  action$.ofType(SUBMIT_ADVISER_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.submitAdviserMessage(action.payload.data))
      .flatMap((payload) => [
        {
          type: SUBMIT_ADVISER_MESSAGE_SUCCESS,
          payload,
        },
      ])
      .catch((error) =>
        Rx.Observable.of({
          type: SUBMIT_ADVISER_MESSAGE_ERROR,
          payload: { error },
        }),
      );
  });

const getAdvisorMessageEpic = (action$) =>
  action$.ofType(GET_ADVISOR_MESSAGE).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.getAdvisorMessage(action.payload))
      .map((payload) => ({
        type: GET_ADVISOR_MESSAGE_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: GET_ADVISOR_MESSAGE_ERROR,
          payload: { error },
        }),
      );
  });

const submitReferralEpic = (action$) =>
  action$.ofType(SUBMIT_REFERRAL).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.submitReferral(action.payload.data))
      .flatMap((payload) => [
        {
          type: SUBMIT_REFERRAL_SUCCESS,
          payload,
        },
      ])
      .catch((error) =>
        Rx.Observable.of({
          type: SUBMIT_REFERRAL_ERROR,
          payload: { error },
        }),
      );
  });

const getReferralRecordsEpic = (action$) =>
  action$.ofType(GET_REFERRAL_RECORDS).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getReferralRecords())
      .map((payload) => ({
        type: GET_REFERRAL_RECORDS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: GET_REFERRAL_RECORDS_ERROR,
          payload: { error },
        }),
      );
  });

const getNewReferralCountEpic = (action$) =>
  action$.ofType(GET_NEW_REFERRAL_COUNT).mergeMap(() => {
    return Rx.Observable.fromPromise(api.getNewReferralCount())
      .map((payload) => ({
        type: GET_NEW_REFERRAL_COUNT_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: GET_NEW_REFERRAL_COUNT_ERROR,
          payload: { error },
        }),
      );
  });

const refreshNewReferralsEpic = (action$) =>
  action$.ofType(REFRESH_NEW_REFERRALS).mergeMap(() => {
    return Rx.Observable.fromPromise(api.refreshNewReferrals())
      .map((payload) => ({
        type: REFRESH_NEW_REFERRALS_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: REFRESH_NEW_REFERRALS_ERROR,
          payload: { error },
        }),
      );
  });

const deleteRecordEpic = (action$) =>
  action$.ofType(DELETE_RECORD).mergeMap((action) => {
    return Rx.Observable.fromPromise(api.deleteRecord(action.payload))
      .map((payload) => ({
        type: DELETE_RECORD_SUCCESS,
        payload,
      }))
      .catch((error) =>
        Rx.Observable.of({
          type: DELETE_RECORD_ERROR,
          payload: { error },
        }),
      );
  });

export const referralEpic = combineEpics(
  submitAdviserMessageEpic,
  getAdvisorMessageEpic,
  submitReferralEpic,
  getReferralRecordsEpic,
  getNewReferralCountEpic,
  refreshNewReferralsEpic,
  deleteRecordEpic,
);
