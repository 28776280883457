import { ERROR, INIT, LOADING, SUCCESS } from '../../constants/phase';
import * as api from './api';
import _ from 'lodash';
import NativeOrWeb from '../../utils/native';

/***********************************
 * Action Types
 ***********/
export const UPDATE_FUNCTIONS = 'UPDATE_FUNCTIONS';
export const UPDATE_GET_FUNCTIONS_PHASE = 'UPDATE_GET_FUNCTIONS_PHASE';

/***********************************
 * Initial State
 ***********/
const initialState = {
  wordpressPages: [],
  financialConcepts: [],
  recruitmentContents: [],
  appIcon: {},
  hotTopic: {},
  campaignCodes: [],
  getFunctionsPhase: INIT
};

/***********************************
 * Reducer
 ***********/
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FUNCTIONS:
      const {
        wordpressPages,
        financialConcepts,
        recruitmentContents,
        appIcon,
        hotTopic,
        campaignCodes
      } = action.payload;

      NativeOrWeb.autoChangeAppIcon(_.get(appIcon, 'id'))
        .then()
        .catch();
      return {
        ...state,
        wordpressPages: _.flatten((wordpressPages || []).map(page => page.items)),
        financialConcepts: financialConcepts,
        recruitmentContents: recruitmentContents,
        appIcon: appIcon,
        hotTopic: hotTopic,
        campaignCodes: campaignCodes,
        getFunctionsPhase: SUCCESS
      };
    case UPDATE_GET_FUNCTIONS_PHASE:
      const { phase } = action.payload;
      return {
        ...state,
        getFunctionsPhase: phase
      };
    default:
      return state;
  }
}

/***********************************
 * Action Creators
 ***********/
export const getFunctions = () => async (dispatch, getState) => {
  dispatch({ type: UPDATE_GET_FUNCTIONS_PHASE, payload: { phase: LOADING } });
  try {
    const json = await api.getFunctions();
    dispatch({ type: UPDATE_FUNCTIONS, payload: json });
  } catch (error) {
    console.error(error);
    dispatch({ type: UPDATE_GET_FUNCTIONS_PHASE, payload: { phase: ERROR } });
  }
};
