import { connect } from 'react-redux';
import { addMessage, fetchMessages, selectClient } from '../../../../store/message/duck';
import { getUser, getConnectedAdviser, handleSignOut } from '../../../../store/user/duck';
import { submitReferral, initPhase, getAdvisorMessage } from '../../../../store/referral/duck';
import AdviserProfileView from './component';

import { injectIntl } from 'react-intl';
import { getAdviserSubscriptionNickname } from '../../../../store/userSubscription/duck';

const AdviserProfile = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    advisorMessage: state.referral.advisorMessage,
    connectedAdvisers: state.user.connectedAdvisers,
    fetchMessagesPhase: state.message.fetchMessagesPhase,
    fetchMessageData: state.message.fetchMessageData,
    selectedClient: state.message.selectedClient,
    reminderBadgeCount: state.reminder.reminderBadgeCount,
    submitReferralPhase: state.referral.submitReferralPhase,
    adviserSubscriptionNickname: state.userSubscription.adviserSubscriptionNickname,
    advisorMessagePhase: state.referral.advisorMessagePhase
  }),
  // Map actions to props
  {
    addMessage,
    fetchMessages,
    getUser,
    getConnectedAdviser,
    selectClient,
    handleSignOut,
    submitReferral,
    initPhase,
    getAdviserSubscriptionNickname,
    getAdvisorMessage
  }
)(AdviserProfileView);

export default injectIntl(AdviserProfile);
