import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { getTextWidth } from '../../../../utils/getTextWidth';
import { formatNumber } from '../../../../utils/formatNumber';
import _ from 'lodash';

class PVPScatterChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scatterGridTop: 0
    };

    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    const { pvpScatterChartData } = this.props;

    if (this.chart) {
      let cWidth = this.chart.echartsElement.offsetWidth;
      let sWidth = cWidth - 10,
        maxWidthCount = cWidth - 10,
        rowCount = 1;

      pvpScatterChartData.forEach(function(value, index) {
        //let textWidth = getTextWidth(value.name, '10px Poppins');
        let textWidth = getTextWidth(value.name, '10px');
        if (maxWidthCount - (textWidth + 25 + 10) > 0) {
          maxWidthCount -= textWidth + 25 + 10;
        } else {
          maxWidthCount = sWidth;
          rowCount += 1;
        }
      });

      this.setState({ scatterGridTop: rowCount * 40 + 20 });
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { pvpScatterChartData, pvpColor, intl, currency } = this.props;
    const { scatterGridTop } = this.state;

    const premiumIntl = intl.formatMessage({ id: 'Premium' }),
      protectionIntl = intl.formatMessage({ id: 'Protection' }),
      currencyIntl = intl.formatMessage({ id: currency });

    let formattedData = [],
      formattedColors = [],
      formattedLegendData = [];
    let groups = _.groupBy(
      _.zipWith(pvpScatterChartData, pvpColor, (data, color) => {
        return { ...data, color: color };
      }),
      'name'
    );

    for (let [, values] of Object.entries(groups)) {
      if (values.length > 1) {
        values.forEach((value, index) => {
          value.name = `${value.name} ${index + 1}`;
          formattedData.push(value);
          formattedLegendData.push(value.name);
          formattedColors.push(value.color);
        });
      } else {
        formattedData.push(values[0]);
        formattedLegendData.push(values[0].name);
        formattedColors.push(values[0].color);
      }
    }

    let pvpScatterChartOption = {
      tooltip: {
        showDelay: 0,
        formatter: function(params) {
          return (
            '<span style="font-weight: 400;">' +
            params.seriesName +
            '</span>' +
            `<br/>${protectionIntl}: ${currencyIntl} ` +
            params.value[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            `<br/>${premiumIntl}: ${currencyIntl} ` +
            params.value[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          );
        },
        confine: true,
        extraCssText:
          'text-align: left; font-size: 10px; font-family: inherit; font-weight: 200; line-height: 15px; z-index: 999; background-color: rgba(0, 0, 0, 0.8);'
      },
      grid: {
        left: '30px',
        right: '20px',
        bottom: scatterGridTop + 'px',
        top: '20px',
        containLabel: true
      },
      legend: {
        data: formattedLegendData,
        left: 'center',
        bottom: 0,
        itemGap: 10,
        itemWidth: 15,
        itemHeight: 15
      },
      xAxis: [
        {
          name: `${protectionIntl} (${currencyIntl})`,
          nameLocation: 'middle',
          nameTextStyle: {
            color: '#666',
            fontSize: '10'
          },
          nameGap: 20,
          type: 'value',
          scale: true,
          axisLabel: {
            formatter: function(value, index) {
              return formatNumber(value, intl);
            }
          },
          splitLine: {
            show: false
          },
          min: 0
        }
      ],
      yAxis: [
        {
          name: `${premiumIntl} (${currencyIntl}/${intl.formatMessage({ id: 'year' })})`,
          nameLocation: 'middle',
          nameGap: 50,
          type: 'value',
          scale: true,
          axisLabel: {
            formatter: function(value, index) {
              return formatNumber(value, intl);
            }
          },
          splitLine: {
            show: false
          },
          min: 0
        }
      ],
      series: formattedData,
      textStyle: {
        color: '#666',
        fontSize: '10'
      },
      color: formattedColors
    };

    return (
      <ReactEcharts
        ref={instance => {
          this.chart = instance;
        }}
        option={pvpScatterChartOption}
        style={{
          height: scatterGridTop + 250 + 'px'
        }}
      />
    );
  }
}

export default PVPScatterChart;
