import { connect } from 'react-redux';
import { toggleDialog } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import React, { Fragment } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

const ButtonStyle = {
  padding: 3,
  lineHeight: 1,
  minWidth: 71,
  textTransform: 'unset'
};

const IconStyle = {
  width: 24,
  margin: '4px 0'
};

const ExpenseCalculatorButton = props => {
  const { intl, style, clientId, factsheetId, expenses, onSubmit, toggleDialog } = props;

  const openDialog = () => {
    toggleDialog('expenseCalculator', {
      clientId: clientId,
      factsheetId: factsheetId,
      expenses: expenses,
      onSubmit: onSubmit,
    });
  };

  return (
    <Button color="primary" onClick={openDialog} disabled={!clientId} style={{ ...ButtonStyle, ...style }}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          {
            clientId ? 
            <img src="img/financial-pyramid/expense_details.png" alt="expense-calculator" style={IconStyle} /> :
            <img src="img/financial-pyramid/expense_details_grey.png" alt="expense-calculator-disabled" style={IconStyle} />
          }
          
        </Grid>
        <Grid item>
          <Typography align="center" color="textSecondary">
            {intl.formatMessage({ id: 'fin-py-expense-cal' })}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

const container = connect(state => ({}), {
  toggleDialog
})(ExpenseCalculatorButton);

export default injectIntl(container);
