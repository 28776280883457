import React, { PureComponent } from 'react';
import DailyIframe from '@daily-co/daily-js';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { createRoom } from '../../../../store/confCall/api';
import CreateRoomModal from './partials/createRoomModal';
import JoinRoomModal from './partials/joinRoomModal';
import ConfirmRoomModal from './partials/confirmModal';
import './style.css';
import IconToast from '../../../../components/NewToast';
import { tracking } from '../../../../utils/tracking';

const domain = process.env.DAILY_CO_DOMAIN;
const pfpDomain = process.env.PFPLUS_URL;
const roomURLDomian = new URL('/conf_call', pfpDomain).href;
const cookies = new Cookies();

let currentTime = new Date();

const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
const hasCHINESE = str => REGEX_CHINESE.test(str);
const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const roomParameter = {
  name: '',
  privacy: 'public',
  nbf: null,
  exp: currentTime.setDate(currentTime.getDate() + 1),
  max_participants: 3,
  autojoin: true,
  enable_knocking: true,
  enable_screenshare: true,
  enable_chat: true,
  start_video_off: false,
  start_audio_off: false,
  owner_only_broadcast: false,
  enable_recording: 'local',
  eject_at_room_exp: true,
  eject_after_elapsed: null
};

class ConfCallView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      roomID: props.match.params.Id,
      createdRoomURL: null,
      createRoomModalOpen: false,
      joinRoomModalOpen: false,
      confirmRoomModalOpen: false,
      ...roomParameter
    };

    this.handleCreateRoomModal = this.handleCreateRoomModal.bind(this);
    this.handleJoinRoomModal = this.handleJoinRoomModal.bind(this);
    this.handleConfirmRoomModal = this.handleConfirmRoomModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.joinRoom = this.joinRoom.bind(this);
    this.createRoom = this.createRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
  }

  componentDidMount() {
    let roomID = this.state.roomID;

    if (roomID !== '' && roomID !== undefined && roomID !== null) {
      this.setState({
        confirmRoomModalOpen: true
      });
    }
    this.props.renderHeaderButton(false);
  }

  componentWillUnmount() {
    this.leaveRoom();
  }

  handleCreateRoomModal() {
    let open = this.state.createRoomModalOpen;
    this.setState({
      createRoomModalOpen: !open
    });
  }

  handleJoinRoomModal() {
    let open = this.state.joinRoomModalOpen;
    this.setState({
      joinRoomModalOpen: !open
    });
  }

  handleConfirmRoomModal() {
    let open = this.state.confirmRoomModalOpen;
    this.setState({
      confirmRoomModalOpen: !open
    });
  }

  handleDateChange(key, date) {
    let formatted_date = date !== null ? new Date(date) : null;
    this.setState({
      [key]: formatted_date
    });
  }

  handleChange(e) {
    let key = e.target.name;
    let value = e.target.value;

    this.setState({
      [key]: value
    });
  }

  createRoom() {
    const { intl, userDetails } = this.props;
    let {
      name,
      privacy,
      nbf,
      exp,
      max_participants,
      autojoin,
      enable_knocking,
      enable_screenshare,
      enable_chat,
      start_video_off,
      start_audio_off,
      owner_only_broadcast,
      enable_recording,
      eject_at_room_exp,
      eject_after_elapsed
    } = this.state;

    let body = { privacy };
    let properties = {
      autojoin,
      enable_knocking,
      enable_screenshare,
      enable_chat,
      start_video_off,
      start_audio_off,
      owner_only_broadcast
    };

    if (!name || name === '') {
      let roomName;
      let date = new Date();
      let month = monthName[date.getMonth()];
      let day = date.getDate();
      let username = userDetails.name || '';
      let email = userDetails.workEmail || '';
      let phone = userDetails.phoneNumber || '';
      username = username.split(/\s/g)[0];
      email = email.split('@')[0];
      phone = phone.replace('+852', '');

      if (!hasCHINESE(username)) {
        roomName = username + phone;
      } else if (email !== '') {
        roomName = email + phone;
      } else {
        roomName = phone;
      }

      roomName = roomName + month + day;
      roomName = roomName.replace(/\W+/g, '');

      body['name'] = roomName.toLowerCase();
    } else {
      body['name'] = name;
    }

    if (max_participants !== '' && max_participants !== undefined && max_participants !== null) {
      properties['max_participants'] = parseInt(max_participants);
    }

    if (eject_at_room_exp !== '' && eject_at_room_exp !== undefined && eject_at_room_exp !== null) {
      properties['eject_at_room_exp'] = eject_at_room_exp;
    }

    if (
      enable_recording !== '' &&
      enable_recording !== undefined &&
      enable_recording !== null &&
      enable_recording !== false
    ) {
      properties['enable_recording'] = enable_recording;
    }

    if (nbf !== '' && nbf !== undefined && nbf !== null) {
      nbf = new Date(nbf);
      properties['nbf'] = Math.floor(nbf / 1000);
    }

    if (exp !== '' && exp !== undefined && exp !== null) {
      exp = new Date(exp);
      properties['exp'] = Math.floor(exp / 1000);
    }

    if (eject_after_elapsed !== '' && eject_after_elapsed !== undefined && eject_after_elapsed !== null) {
      eject_after_elapsed = new Date(eject_after_elapsed);
      properties['eject_after_elapsed'] = Math.floor(eject_after_elapsed / 1000);
    }

    body['properties'] = properties;

    this.setState({
      createdRoomURL: `${roomURLDomian}/${body['name']}`,
      roomID: body['name']
    });

    createRoom(body)
      .then(res => {
        //console.log(res);
        if (res.success) {
          this.setState({
            createdRoomURL: `${roomURLDomian}/${res.body.name}`,
            roomID: res.body.name,
            ...roomParameter
          });
          toast.info(IconToast('info', intl.formatMessage({ id: 'Meeting ID created' })), { className: 'new-toast' });

          // tracking
          tracking('Adviser create conf room');
        } else {
          let info = res.body.info;
          if (typeof info === 'string') {
            toast.info(IconToast('error', info), { className: 'new-toast' });
          } else {
            console.log(res.body);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  joinRoom() {
    const { userDetails } = this.props;
    const { roomID } = this.state;
    if (roomID === null || roomID === undefined || roomID === '') {
      toast.info(IconToast('error', 'Meeting ID is empty'), { className: 'new-toast' });
      return;
    }
    let url = new URL(roomID.trim(), domain).href;
    let conf_call_token = cookies.get('conf_call_token');
    url = conf_call_token ? `${url}?t=${conf_call_token}` : url;

    // open conf room in new window
    if (userDetails && userDetails._id && userDetails.userType === 'Adviser' && !isMobile) {
      let newWindowURL = new URL('/client', pfpDomain).href;
      window.open(newWindowURL, '_blank', 'menubar=no,left=800,width=500,height=780');

      // tracking
      tracking('Adviser join conf room');
    }

    let properties = {
      showLeaveButton: true,
      showFullscreenButton: true,
      iframeStyle: {
        top: 0,
        left: 0,
        position: 'fixed',
        width: '100%',
        height: '100%',
        'z-index': 1044
      }
    };
    let callFrame = DailyIframe.createFrame(properties);
    callFrame.join({ url: url });

    // close modal
    this.setState({
      joinRoomModalOpen: false,
      createRoomModalOpen: false,
      confirmRoomModalOpen: false,
      callFrame
    });

    callFrame
      .on('left-meeting', evt => {
        this.leaveRoom();
      })
      .on('error', evt => {
        toast.info(IconToast('error', evt.errorMsg), { className: 'new-toast' });
        this.leaveRoom();
      });
  }

  leaveRoom() {
    let callFrame = this.state.callFrame;
    if (callFrame) {
      callFrame.destroy();
      this.setState({
        callFrame: null
      });
    }
  }

  render() {
    const { intl, userDetails, location } = this.props;

    let isBeta = location && location.pathname && location.pathname.includes('/conf_call_beta');

    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid policyCategory">
              <div className="row policyTitle">
                <div className="col-lg-12">
                  <ul className="card-list">
                    {userDetails && userDetails._id && userDetails.userType === 'Adviser' && (
                      <li onClick={this.handleCreateRoomModal}>
                        <div className="portfolioCard1">
                          <img src="img/RemoteAdd.png" alt="" />
                          <h5>{intl.formatMessage({ id: 'Create Meeting ID' })}</h5>
                        </div>
                      </li>
                    )}
                    <li onClick={this.handleJoinRoomModal}>
                      <div className="portfolioCard1">
                        <img src="img/JoinMeeting.png" alt="" />
                        <h5>{intl.formatMessage({ id: 'Join Meeting' })}</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {userDetails && userDetails._id && userDetails.userType === 'Adviser' && (
          <CreateRoomModal
            open={this.state.createRoomModalOpen}
            onClose={this.handleCreateRoomModal}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            createRoom={this.createRoom}
            joinRoom={this.joinRoom}
            isBeta={isBeta}
            {...this.props}
            {...this.state}
          />
        )}
        <JoinRoomModal
          open={this.state.joinRoomModalOpen}
          onClose={this.handleJoinRoomModal}
          handleChange={this.handleChange}
          joinRoom={this.joinRoom}
          roomID={this.state.roomID}
          {...this.props}
        />
        <ConfirmRoomModal
          open={this.state.confirmRoomModalOpen}
          onClose={this.handleConfirmRoomModal}
          joinRoom={this.joinRoom}
          roomID={this.state.roomID}
          {...this.props}
        />
      </div>
    );
  }
}

export default ConfCallView;
