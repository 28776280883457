import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { toggleDialog } from '../../../store/control/duck';
import { Button } from '@material-ui/core';

const ButtonStyle = {
  padding: '2px 4px',
  minWidth: 'unset',
  minHeight: 'unset',
  lineHeight: 1
};

const ExpenseCalculatorTriggerButton = props => {
  const { intl, toggleDialog, clientId, factsheetId, expenses, onSubmit, style } = props;

  const onClick = () => {
    toggleDialog('expenseCalculator', {
      clientId: clientId,
      factsheetId: factsheetId,
      expenses: expenses,
      onSubmit: onSubmit
    });
  };

  return (
    <Button variant="outlined" color="primary" onClick={onClick} style={{ ...ButtonStyle, ...style }}>
      {intl.formatMessage({ id: 'expense-dialog-trigger-button' })}
    </Button>
  );
};

const container = connect(state => ({}), {
  toggleDialog
})(ExpenseCalculatorTriggerButton);

export default injectIntl(container);
