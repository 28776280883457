import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { decimalToString } from '../../../../utils/formatNumber';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { NumberFormatWrapper } from '../../../../components/NumberFormatTextField/NumberFormatTextField';
import ColoredLeftBorderCard from '../ColoredLeftBorderCard/ColoredLeftBorderCard';
import ToggleEditButton from '../ToggleEditButton/ToggleEditButton';
import produce from 'immer';
import { usePrevious } from '../../../../utils/hooks';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {
  getPolicyCoverageForPolicyPicker,
  getPolicyMarketValue,
  getPolicyMonthlyPremiumForPolicyPicker,
  isPaidUpPolicy
} from '../../../../utils/policy';

const HeaderTypoStyle = {
  opacity: 0.75
};

const EditButtonStyle = {
  position: 'absolute',
  top: 9,
  right: 5,
  zIndex: 1
};

const ValueTypoStyle = {
  textOverflow: 'ellipsis',
  overflowX: 'hidden'
};

const PolicyPickerEditButtonStyle = {
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  padding: 4,
  zIndex: 1
};

const InputTableGrid = props => {
  const { intl, imgSrc, type, data, color, currency, onChange, cardBackground, openPolicyPicker, disabled } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [internalData, setInternalData] = useState(_.cloneDeep(data));
  const prevData = usePrevious(data);

  const toggleIsEdit = () => {
    if (isEdit) {
      onChange(internalData);
    }
    setIsEdit(!isEdit);
  };

  const createFieldOnChangeHandler = (index, field) => event => {
    const formattedValue = event.target.value;
    setInternalData(
      produce(internalData, draft => {
        draft[index][field] = formattedValue;
      })
    );
  };

  useEffect(() => {
    if (data !== prevData) {
      setInternalData(_.cloneDeep(data));
    }
  }, [data, prevData]);

  return (
    <Grid container alignItems="flex-start" spacing={2} wrap="nowrap">
      <Grid item style={{ marginRight: -10 }}>
        <img src={imgSrc} alt={type} width={30} height={30} />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <ColoredLeftBorderCard color={color} background={cardBackground}>
          {onChange && (
            <ToggleEditButton isEdit={isEdit} onChange={toggleIsEdit} style={EditButtonStyle} disabled={disabled} />
          )}

          <Grid container direction="column" spacing={1}>
            <Grid item style={{ borderBottom: '1px solid', borderBottomColor: color, whiteSpace: 'nowrap' }}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Typography color="textSecondary" style={HeaderTypoStyle}>
                    {intl.formatMessage({ id: 'fin-py-item' })}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="textSecondary" style={HeaderTypoStyle}>
                    {type === 'protection'
                      ? intl.formatMessage(
                          { id: 'fin-py-input-coverage' },
                          { currency: intl.formatMessage({ id: currency }) }
                        )
                      : intl.formatMessage(
                          { id: 'fin-py-net-value' },
                          { currency: intl.formatMessage({ id: currency }) }
                        )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="textSecondary" style={HeaderTypoStyle}>
                    {intl.formatMessage(
                      { id: 'fin-py-monthly-payment' },
                      { currency: intl.formatMessage({ id: currency }) }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {(internalData || []).map((item, index) => {
              const { name, value, monthlyPayment } = item || {};

              if (!isEdit && !name && !value && !monthlyPayment) {
                return null;
              }

              const choosePolicies = () => {
                openPolicyPicker({
                  onSubmit: policies => {
                    const { coverage, monthlyPremium } = (policies || []).reduce(
                      (accumulator, policy) => {
                        const coverage =
                          (type === 'protection'
                            ? getPolicyCoverageForPolicyPicker(policy, currency)
                            : getPolicyMarketValue(policy, currency)) || 0;
                        const monthlyPremium =
                          (!isPaidUpPolicy(policy) ? getPolicyMonthlyPremiumForPolicyPicker(policy, currency) : 0) || 0;
                        accumulator.coverage += coverage;
                        accumulator.monthlyPremium += monthlyPremium;
                        return accumulator;
                      },
                      { coverage: 0, monthlyPremium: 0 }
                    );

                    setInternalData(
                      produce(internalData, draft => {
                        if ((policies || []).length === 1) {
                          draft[index].name = policies[0].policyName;
                        }

                        draft[index].value = Math.round(coverage);
                        draft[index].monthlyPayment = Math.round(monthlyPremium);
                      })
                    );
                  }
                });
              };

              return (
                <Grid item key={index} style={{ position: 'relative' }}>
                  {isEdit && (
                    <IconButton style={PolicyPickerEditButtonStyle} onClick={choosePolicies}>
                      <EditRoundedIcon fontSize="small" />
                    </IconButton>
                  )}

                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      {!isEdit ? (
                        <Typography color="textSecondary" style={ValueTypoStyle}>
                          {name}
                        </Typography>
                      ) : (
                        <TextField
                          inputProps={{ style: { marginBottom: 1 } }}
                          value={name || ''}
                          onChange={createFieldOnChangeHandler(index, 'name')}
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {!isEdit ? (
                        <Typography color="textSecondary" style={ValueTypoStyle}>
                          {value ? decimalToString(value) : '-'}
                        </Typography>
                      ) : (
                        <TextField
                          value={value}
                          onChange={createFieldOnChangeHandler(index, 'value')}
                          InputProps={{
                            inputComponent: NumberFormatWrapper
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {!isEdit ? (
                        <Typography color="textSecondary" style={ValueTypoStyle}>
                          {monthlyPayment ? decimalToString(monthlyPayment) : '-'}
                        </Typography>
                      ) : (
                        <TextField
                          value={monthlyPayment}
                          onChange={createFieldOnChangeHandler(index, 'monthlyPayment')}
                          InputProps={{
                            inputComponent: NumberFormatWrapper
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </ColoredLeftBorderCard>
      </Grid>
    </Grid>
  );
};

export default injectIntl(InputTableGrid);
