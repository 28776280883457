import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import IconToast from '../../../components/NewToast';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { formatEmail } from '../../../utils/email';
import LoadingBackdrop from '../../../components/LoadingBackdrop/LoadingBackdrop';

export default class ForgotPasswordView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      err: {},
      disabled: false,
      open: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForgotPasswordForm = this.submitForgotPasswordForm.bind(this);
  }

  handleChange(event) {
    if (event.target.name === 'email') {
      delete this.state.err.email;
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  componentDidMount() {
    /*to display the page language as per the browser language*/
    var user = this.props.userDetails;
    if (_.isEmpty(user)) {
      var userLang = navigator.language || navigator.userLanguage;
      const language = userLang === 'zh-HK' ? 'zh-Hant-HK' : userLang === 'zh-CN' ? 'zh' : 'en';
      this.props.selectedLocale(language);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl } = this.props;
    if (nextProps.forgotPasswordPhase === true) {
      this.setState({
        email: '',
        open: true,
        disabled: true
      });
      //   toast.info(
      //     intl.formatMessage({
      //       id: 'Reset link has been sent to your email address.'
      //     })
      //   );
    } else if (nextProps.forgotPasswordPhase === false) {
      toast.info(
        IconToast('info', intl.formatMessage({ id: nextProps.message }, nextProps.forgotPasswordMessageExtras)),
        { className: 'new-toast' }
      );
      this.setState({ disabled: false });
    }
    // if (nextProps.forgotPasswordPhase === 'loading') {
    //   toast.info(intl.formatMessage({ id: 'Sending request...' }));
    // }
  }

  //sending customer's email to backend in case customer forget password he will get a reset link through email to set a new password.
  submitForgotPasswordForm(event) {
    event.preventDefault();
    const { disabled } = this.state;
    if (!disabled) {
      const err = {};
      const formattedEmail = formatEmail(this.state.email);
      if (formattedEmail === '') {
        err.email = 'Email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formattedEmail)) {
        err.email = 'Invalid email';
      }
      this.setState({ err });
      if (!Object.keys(err).length) {
        const data = {};
        data.email = formattedEmail;
        this.props.forgotPassword(data);
      }
    }
  }

  render() {
    const { intl } = this.props;
    const { disabled, open } = this.state;
    return (
      <div>
        {this.props.forgotPasswordPhase === 'loading' && <LoadingBackdrop open={true} />}
        <div id="loginWrapper">
          <div className="col-sm-6 col-sm-12 col-sm-offset-3">
            <form className="myForm" style={{ paddingTop: '80px' }} onSubmit={this.submitForgotPasswordForm}>
              <div className="form-group upDownGap">
                <input
                  type="text"
                  required="required"
                  onChange={this.handleChange}
                  disabled={disabled}
                  value={this.state.email}
                  name="email"
                />
                <label className="control-label" htmlFor="input">
                  {intl.formatMessage({ id: 'Login Email' })}
                </label>
                <i className="bar" />
                {this.state.err.email ? (
                  <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
                ) : (
                  ''
                )}
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth={true}
                onClick={this.submitForgotPasswordForm}
                disabled={disabled}
              >
                {intl.formatMessage({ id: 'Get Password' })}
              </Button>
            </form>
          </div>
          <Dialog
            open={open}
            onClose={() => {
              this.setState({
                open: false
              });
            }}
          >
            <DialogContent>
              {intl.formatMessage({ id: 'Reset link has been sent to your email address.' })}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  this.setState({
                    open: false
                  });
                }}
              >
                {intl.formatMessage({ id: 'OK' })}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
