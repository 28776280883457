import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { SlideTransition } from '../../../components/Transition/Transition';

const ECardWelcomeDialog = props => {
  const { intl, open, onSayNo, onSayYes, ...rest } = props;

  return (
    <Dialog open={open} fullWidth={true} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'e-card-welcome-dialog-title' })}</DialogTitle>
      <DialogContent>
        <Typography style={{ whiteSpace: 'pre-wrap' }}>
          {intl.formatMessage({ id: 'e-card-welcome-dialog-content' })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSayNo}>
          {intl.formatMessage({ id: 'i-dont-have' })}
        </Button>
        <Button variant="contained" color="primary" disableElevation={true} onClick={onSayYes}>
          {intl.formatMessage({ id: 'i-have' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ECardWelcomeDialog);
