import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel
} from '@material-ui/core';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import { getClientName } from '../utils';
import _ from 'lodash';
import { tracking } from '../../../utils/tracking';
import { sanitize } from 'dompurify';
import { createConnectionRequest } from '../../../store/connectionRequest/duck';

const INITIAL_STATE = { confirmed: false, error: undefined, extras: undefined, loading: false };

const ConnectConfirmDialog = props => {
  const { intl, connectConfirmDialog, createConnectionRequest, toggleDialog } = props;

  const [confirmed, setConfirmed] = useState(INITIAL_STATE.confirmed);
  const [error, setError] = useState(INITIAL_STATE.error);
  const [extras, setExtras] = useState(INITIAL_STATE.extras);
  const [loading, setLoading] = useState(INITIAL_STATE.loading);

  const { client, closed, editingEmail } = connectConfirmDialog || {};
  const clientName = getClientName(client);
  // const clientEmail = getClientEmail(client);
  // const passCode = connectConfirmDialog.passCode;
  // const resendStatus = connectConfirmDialog.resendStatus;

  // useEffect(() => {
  //   if (sharePhase !== prevSharePhase) {
  //     if (connectConfirmDialog) {
  //       if (sharePhase === true) {
  //         close();
  //         toggleDialog('connect', false);
  //         toggleDialog('resend', false);
  //         toggleDialog('shareConfirm', { client: _.get(connectConfirmDialog, 'client'), mode: 'connect' });
  //       } else if (sharePhase === false) {
  //         setError(clientMessage);
  //         setExtras(clientMessageExtras);
  //       }
  //     }
  //   }
  // }, [sharePhase, prevSharePhase, connectConfirmDialog]);

  const submitConnect = async () => {
    if (confirmed) {
      // const formData = {};
      // formData.clientEmail = clientEmail.toLowerCase();
      // formData.clientName = clientName;
      // formData.clientPhone =
      //     client.factsheetId && client.factsheetId.phoneNumber ? client.factsheetId.phoneNumber : client.phoneNumber;
      // formData.passcode = passCode;
      // formData.resendkey = resendStatus;
      //
      // if (editingEmail !== clientEmail) {
      //   let formData = {};
      //   formData.email = editingEmail;
      //   formData.clientId = client._id;
      //   changeInactiveUserEmail(formData);
      // }
      //
      // shareClientCredentials(formData);
      try {
        // Tracking
        tracking('Connect With Client');

        setLoading(true);
        const response = await createConnectionRequest(client._id);
        if (response.success) {
          close();
          toggleDialog('connect', false);
          toggleDialog('resend', false);
          toggleDialog('connection', false);
          toggleDialog('shareConfirm', { client: client, mode: 'connect' });
        } else {
          if (response.message === 'Client already connected') {
            toggleDialog('normal', {
              title: intl.formatMessage({ id: 'user-connected-dialog-title' }),
              message: intl.formatMessage({ id: 'user-connected-dialog-content' })
            });
          } else {
            setError(response.message);
          }
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setError('Please confirm');
    }
  };

  const onChecked = event => {
    setConfirmed(event.target.checked);
    setError(INITIAL_STATE.error);
    setExtras(INITIAL_STATE.extras);
  };

  const close = () => {
    if (!loading) {
      toggleDialog('connectConfirm', { ...connectConfirmDialog, closed: true });
    }
  };

  const onExited = () => {
    toggleDialog('connectConfirm', false);
    setConfirmed(INITIAL_STATE.confirmed);
    setError(INITIAL_STATE.error);
    setExtras(INITIAL_STATE.extras);
    setLoading(INITIAL_STATE.loading);
  };

  return client ? (
    <Dialog
      open={!!connectConfirmDialog && !closed}
      onClose={close}
      onExited={onExited}
      TransitionComponent={SlideTransition}
      fullWidth={true}
    >
      <DialogTitle>{intl.formatMessage({ id: 'Confirm' })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{intl.formatMessage({ id: 'DOUBLE CHECK the email address' })}</DialogContentText>
        <div className="double-check-div">
          <div>
            <b>{editingEmail}</b>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                name="confirmed"
                value="confirmed"
                checked={confirmed}
                disabled={loading}
                onChange={onChecked}
                color="primary"
              />
            }
            label={intl.formatMessage({ id: 'Correct' })}
            className="double-check-label"
          />
        </div>
        {error && (
          <div
            className="error_field"
            dangerouslySetInnerHTML={{ __html: sanitize(intl.formatMessage({ id: error }, extras)) }}
          />
        )}
        {intl.formatMessage({ id: 'Confirm to get connected with' })} <b>{clientName}</b> ?<br />
        {intl.formatMessage({
          id: 'receive-email-information'
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" disabled={loading}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={submitConnect} color="primary" disabled={loading} autoFocus>
          {loading ? <CircularProgress size={24} /> : intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

const container = connect(
  // Map state to props
  state => ({
    connectConfirmDialog: state.control.connectConfirmDialog
  }),
  // Map actions to props
  {
    toggleDialog,
    createConnectionRequest
  },
  // mergeProps
  null,
  // options
  {}
)(ConnectConfirmDialog);

export default injectIntl(container);
