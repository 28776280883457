import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import moment from 'moment';
import EditIconButton from '../../../../../../components/EditIconButton';
// import { weeklyAptCalculator } from '../../../../../../utils/weeklyAptCalculator';
import { decimalToString } from '../../../../../../utils/formatNumber';
import Button from '@material-ui/core/Button';
import './DashboardTargetModal.scss';
// import { get } from 'lodash';
import MyEditor from '../../../../../../components/MyEditor/MyEditor';
import { SlideTransition } from '../../../../../../components/Transition/Transition';
import { formatEditorStateValue, getEditorDefaultMessage } from '../../../../../../utils/editor';
import _ from 'lodash';

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { intl, open, onClose, adviserBusiness, userDetails } = props;
  let currentYear = moment().format('YYYY');
  let currentMonth = parseInt(moment().format('MM'));
  let Q =
    currentMonth <= 3
      ? currentYear + '-03-31'
      : currentMonth <= 6
      ? currentYear + '-06-30'
      : currentMonth <= 9
      ? currentYear + '-09-30'
      : currentMonth <= 12
      ? currentYear + '-12-31'
      : '';
  let currentDate = moment().startOf('day');
  let endDate =
    adviserBusiness && adviserBusiness.quarterEndDate
      ? moment.parseZone(adviserBusiness.quarterEndDate).format('YYYY-MM-DD')
      : Q;
  /* Find Range of start month to current date */
  let QMonthStartDate1 =
    currentMonth <= 3
      ? currentYear + '-01-01'
      : currentMonth <= 6
      ? currentYear + '-04-01'
      : currentMonth <= 9
      ? currentYear + '-07-01'
      : currentMonth <= 12
      ? currentYear + '-10-01'
      : '';
  //r1++
  let QEndDate = moment(endDate).toDate();
  let QEndMonth = parseInt(moment(endDate).format('MM'));
  let yearEnd = moment(moment(currentYear + '-12-31').toDate()).diff(currentDate, 'days');

  //calculate the range  according to the quarter
  let r1 = 0;
  let QEndYear = parseInt(moment(endDate).format('YYYY'));
  let currrentEndYear = parseInt(moment().format('YYYY'));
  if (moment(endDate).isAfter(currentDate) && QEndYear === currrentEndYear) {
    r1 = moment(endDate).diff(QMonthStartDate1, 'days');
  } else if (QEndMonth >= currentMonth && QEndYear === currrentEndYear) {
    r1 = 0;
  } else if (
    QEndYear === currrentEndYear &&
    QEndMonth <= currentMonth &&
    QEndMonth <= parseInt(moment(Q).format('MM')) &&
    currentMonth <= parseInt(moment(Q).format('MM')) &&
    QEndMonth >= parseInt(moment(QMonthStartDate1).format('MM'))
  ) {
    r1 = 0;
  } else {
    r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days');
  }

  let QEnd = 0;
  if ((moment(QEndDate).isAfter(currentDate) || moment(QEndDate).isSame(currentDate)) && QEndYear === currrentEndYear) {
    QEnd = moment(QEndDate).diff(currentDate, 'days');
  } else if (QEndMonth >= currentMonth && QEndYear === currrentEndYear) {
    QEnd = '-';
  } else if (
    QEndYear === currrentEndYear &&
    QEndMonth <= currentMonth &&
    QEndMonth <= parseInt(moment(Q).format('MM')) &&
    currentMonth <= parseInt(moment(Q).format('MM')) &&
    QEndMonth >= parseInt(moment(QMonthStartDate1).format('MM'))
  ) {
    QEnd = '-';
  } else {
    QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
    QEnd = QEnd > 92 ? 92 : QEnd <= 92 && QEnd >= 0 ? QEnd : '-';
  }
  yearEnd = yearEnd > 0 ? yearEnd : '-';
  let quarterPercentage = QEnd !== '-' && r1 !== 0 ? (QEnd / r1) * 100 : 0;
  let bizTarget =
    adviserBusiness &&
    adviserBusiness.quarterTarget !== undefined &&
    adviserBusiness.quarterTarget !== null &&
    adviserBusiness.quarterTarget !== '' &&
    adviserBusiness.quarterTarget > 0
      ? adviserBusiness.quarterTarget
      : '-';

  //Calculate teh big days acc. to big date
  // let bigDate = get(adviserBusiness, 'bigDate', null);
  // var StartBigdate = moment().format('YYYY-MM-DD');
  // let bigDateEnd1 = moment.utc(StartBigdate).format('YYYY-MM-DDT00:00:00.00Z');

  // let bigDateDays = bigDate ? moment(bigDate).diff(moment(bigDateEnd1), 'days') : '-';
  // let adviserQuarterTarget = parseFloat(get(adviserBusiness, 'quarterTarget', 0));
  // let avgCaseSize = parseFloat(get(adviserBusiness, 'avgCaseSize', 0));
  // let closingRate = parseFloat(get(adviserBusiness, 'closingRate', 0));

  const remindMe = _.get(userDetails, 'remindMe');
  const formattedRemindMe = formatEditorStateValue(
    remindMe === undefined ? getEditorDefaultMessage(intl.locale, 'remind-me') : remindMe
  );

  return (
    <div>
      <Dialog
        open={!!open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        keepMounted
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        // fullWidth={true}
        fullScreen={fullScreen}
        className="dashboard-target-modal"
      >
        <DialogContent className="dialog-content-centered" style={{ paddingLeft: 8, paddingRight: 8 }}>
          <div>
            <Link to="/set_target">
              <EditIconButton changeClass={true} />
            </Link>
            <MyEditor readOnly={true} editorState={formattedRemindMe} />
          </div>
        </DialogContent>
        <DialogActions className="action">
          <div>
            <div className="business-analysis-month-margin">
              <div>
                <div>
                  <div
                    className="col-md-6 col-xs-6 text-right adv_biz_modal_key margin__bottom"
                    style={{ textAlign: 'right' }}
                  >
                    {intl.formatMessage({ id: 'Biz Target' })} :
                  </div>
                  <div
                    className="col-md-6 col-xs-6 text-right adv_biz_modal_key"
                    style={{
                      textAlign: 'right',
                      marginBottom: '5px'
                    }}
                  >
                    <div
                      className={yearEnd > 0 ? 'blue-color  text-left' : 'text-left'}
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'left'
                      }}
                    >
                      {bizTarget > 0 && bizTarget !== '-' ? decimalToString(bizTarget) : bizTarget}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div
                    className="col-md-6 col-xs-6 text-right adv_biz_modal_key"
                    style={{
                      textAlign: 'right',
                      marginBottom: '5px'
                    }}
                  >
                    {intl.formatMessage({ id: 'Quarter End' })} :
                  </div>
                  <div
                    className="col-md-6 col-xs-6 text-right adv_biz_modal_key"
                    style={{
                      textAlign: 'right',
                      marginBottom: '5px'
                    }}
                  >
                    <div
                      className={
                        QEnd > (2 * r1) / 3
                          ? 'green-color'
                          : QEnd > r1 / 3 && QEnd <= (2 * r1) / 3
                          ? 'yellow-color'
                          : QEnd >= 0 && QEnd <= r1 / 3
                          ? 'red-color'
                          : ''
                      }
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'left'
                      }}
                    >
                      {QEnd > 0 &&
                        QEnd +
                          ' ' +
                          intl.formatMessage({ id: 'days' }) +
                          ' ' +
                          '(' +
                          decimalToString(quarterPercentage) +
                          '%)'}
                      {QEnd === 0 &&
                        // eslint-disable-next-line
                        intl.formatMessage({ id: 'Today' }) + ' ' + '(' + decimalToString(quarterPercentage) + '%)'}
                      {QEnd === '-' && '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <Button variant="contained" color="primary" onClick={onClose} className="gradient-action-button">
                {intl.formatMessage({ id: 'Make it happen' })}
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
