import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { toggleDialog } from '../../../store/control/duck';
import { SlideTransition } from '../../Transition/Transition';

class TrackDiscardWarningDialog extends React.Component {
  close = () =>
    this.props.toggleDialog('trackDiscardWarning', { ...this.props.trackDiscardWarningDialog, closed: true });
  closed = () => this.props.toggleDialog('trackDiscardWarning', false);

  discard = () => {
    const { trackDiscardWarningDialog } = this.props;
    const { onDiscard } = trackDiscardWarningDialog;
    this.close();
    if (onDiscard) onDiscard();
  };

  save = () => {
    const { trackDiscardWarningDialog } = this.props;
    const { onSave } = trackDiscardWarningDialog;
    this.close();
    if (onSave) onSave();
  };

  render() {
    const { intl, trackDiscardWarningDialog } = this.props;

    if (!trackDiscardWarningDialog) return null;

    const { enabled, triggered, closed } = trackDiscardWarningDialog;

    return (
      <Dialog
        open={!!(!!trackDiscardWarningDialog && enabled && triggered && !closed)}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Discard Changes' })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({
            id: 'Switching_And_Discard_changes'
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.discard} color="primary">
            {intl.formatMessage({ id: 'Discard' })}
          </Button>
          <Button onClick={this.save} color="primary" autoFocus>
            {intl.formatMessage({ id: 'OK' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    trackDiscardWarningDialog: state.control.trackDiscardWarningDialog
  }),
  // Map actions to props
  {
    toggleDialog
  },
  // mergeProps
  null,
  // options
  {}
)(TrackDiscardWarningDialog);

export default injectIntl(container);
