import React, { Fragment, useState } from 'react';
import ChatGPTPresetDrawer from '../ChatGPTPresetDrawer';
import { connect } from 'react-redux';
import { toggleControl } from '../../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import FollowUpButton from '../FollowUpButton/FollowUpButton';
import ActionButton from '../ActionButton/ActionButton';
import { decreaseGPTQuota, handleError, resetGPTQuota, validateGPTQuota } from '../../../../utils/openai';
import { getBriefPrompt, getMorePrompt, getPersonalMessagePrompt } from '../../../../utils/aiPrompt';
import LoadingBackdrop from '../../../LoadingBackdrop/LoadingBackdrop';
import ContentBox from '../ContentBox/ContentBox';
import { openaiGenerateWithTextStreaming } from '../../../../store/openai/api';
import { useForceUpdate } from '../../../../utils/hooks';
import QuotaTypography from '../QuotaTypography/QuotaTypography';

const ChatGPTPersonalMessageDrawer = props => {
  const { intl, chatGPTPersonalMessageDrawer, toggleControl } = props;

  const [text, setText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const forceUpdate = useForceUpdate();

  const { client, closed } = chatGPTPersonalMessageDrawer || {};
  const { factsheetId } = client || {};
  const { name } = factsheetId || {};

  const isOpen = !!chatGPTPersonalMessageDrawer && !closed;

  const onClose = () => {
    toggleControl('chatGPTPersonalMessageDrawer', { ...chatGPTPersonalMessageDrawer, closed: true });
  };

  const onExited = () => {
    toggleControl('chatGPTPersonalMessageDrawer', false);
    setText('');
    setChatMessages([]);
  };

  const onFeedback = () => {
    toggleControl('chatGPTFeedbackDialog', {
      client: client,
      extraContent: text,
      onSubmit: () => {
        resetGPTQuota();
        forceUpdate();
      }
    });
  };

  const send = async ({ previousMessages, inputMessage }) => {
    try {
      validateGPTQuota();
      setLoading(true);

      const addedChatMessages = [...(previousMessages || []), { content: inputMessage, role: 'user' }];
      await openaiGenerateWithTextStreaming('gpt-4o', addedChatMessages, 1, 1, 0, 0, setChatMessages, setText);

      decreaseGPTQuota();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const life = () => {
    const inputMessage = getPersonalMessagePrompt({ clientName: name, insuranceType: 'Life' });
    send({ inputMessage });
  };

  const ci = () => {
    const inputMessage = getPersonalMessagePrompt({ clientName: name, insuranceType: 'Critical illness' });
    send({ inputMessage });
  };

  const medical = () => {
    const inputMessage = getPersonalMessagePrompt({ clientName: name, insuranceType: 'Medical' });
    send({ inputMessage });
  };

  const saving = () => {
    const inputMessage = getPersonalMessagePrompt({ clientName: name, insuranceType: 'Saving' });
    send({ inputMessage });
  };

  const clear = () => {
    setText('');
    setChatMessages([]);
  };

  const brief = () => {
    const inputMessage = getBriefPrompt();
    send({ inputMessage, previousMessages: chatMessages });
  };

  const more = () => {
    const inputMessage = getMorePrompt();
    send({ inputMessage, previousMessages: chatMessages });
  };

  return (
    <Fragment>
      <LoadingBackdrop open={loading} />
      <ChatGPTPresetDrawer open={isOpen} onClose={onClose} onExited={onExited} onFeedback={onFeedback}>
        <Grid container direction="column" spacing={2} style={{ height: '100%' }}>
          <Grid item>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {intl.formatMessage({ id: 'chat-gpt-personal-msg-drawer-content' })}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <ActionButton onClick={life}>{intl.formatMessage({ id: 'Life' })}</ActionButton>
              </Grid>
              <Grid item>
                <ActionButton onClick={ci}>{intl.formatMessage({ id: 'C.I.' })}</ActionButton>
              </Grid>
              <Grid item>
                <ActionButton onClick={medical}>{intl.formatMessage({ id: 'Medical' })}</ActionButton>
              </Grid>
              <Grid item>
                <ActionButton onClick={saving}>{intl.formatMessage({ id: 'Saving' })}</ActionButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ContentBox
              getHeight={({ innerHeight }) => `calc(${innerHeight}px - 300px - var(--top-padding))`}
              text={text}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item>{text && <Typography>{intl.formatMessage({ id: 'Follow Up' })}</Typography>}</Grid>
                  <Grid item>
                    <QuotaTypography />
                  </Grid>
                </Grid>
              </Grid>
              {text && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FollowUpButton onClick={clear}>
                        {intl.formatMessage({ id: 'chat-gpt-follow-up-clear' })}
                      </FollowUpButton>
                    </Grid>
                    <Grid item>
                      <FollowUpButton onClick={brief}>
                        {intl.formatMessage({ id: 'chat-gpt-follow-up-brief' })}
                      </FollowUpButton>
                    </Grid>
                    <Grid item>
                      <FollowUpButton onClick={more}>
                        {intl.formatMessage({ id: 'chat-gpt-follow-up-more' })}
                      </FollowUpButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ChatGPTPresetDrawer>
    </Fragment>
  );
};

const container = connect(
  state => ({
    chatGPTPersonalMessageDrawer: state.control.chatGPTPersonalMessageDrawer
  }),
  {
    toggleControl
  }
)(ChatGPTPersonalMessageDrawer);

export default injectIntl(container);
