import React from 'react';
import { connect } from 'react-redux';
import { mergeDialog, toggleDialog } from '../../../store/control/duck';
import { injectIntl } from 'react-intl';
import { SlideTransition } from '../../Transition/Transition';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel
} from '@material-ui/core';
import { changeInactiveUserEmail } from '../../../store/client/duck';
import { toast } from 'react-toastify';
import { getClientEmail } from '../utils';
import IconToast from '../../NewToast';
import { formatEmail } from '../../../utils/email';

const INITIAL_STATE = { error: undefined, loading: false };

class ChangeEmailDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      intl,
      changeEmailDialog,
      updateEmailPhase,
      mergeDialog,
      clientMessage,
      updateEmailMessageExtras
    } = nextProps;
    if (changeEmailDialog) {
      if (updateEmailPhase === true && this.props.updateEmailPhase !== true) {
        mergeDialog('changeEmail', { closed: true });
        this.setState({ loading: false });
      } else if (updateEmailPhase === false && this.props.updateEmailPhase !== false) {
        toast.info(IconToast('error', intl.formatMessage({ id: clientMessage }, updateEmailMessageExtras)), {
          className: 'new-toast'
        });
        this.setState({ loading: false });
      }
    }
  }
  close = () => this.props.mergeDialog('changeEmail', { closed: true });
  closed = () => {
    this.props.toggleDialog('changeEmail', false);
    this.setState(INITIAL_STATE);
  };
  changeEmail = event => this.props.mergeDialog('changeEmail', { editingEmail: event.target.value });
  submit = () => {
    const { changeInactiveUserEmail, changeEmailDialog } = this.props;
    const { client, editingEmail } = changeEmailDialog;

    let error = undefined;
    if (editingEmail !== '') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(editingEmail))) {
        error = 'Invalid email';
      }
    }

    this.setState({ error, loading: !error });

    if (!error) {
      let formData = {};
      formData.email = formatEmail(editingEmail);
      formData.clientId = client._id;
      const clientEmail = getClientEmail(client);
      if (formData.email === clientEmail) {
        this.close();
      }
      if (formData.email !== clientEmail) {
        changeInactiveUserEmail(formData);
      }
    }
  };

  render() {
    const { intl, changeEmailDialog } = this.props;
    const { error, loading } = this.state;

    if (!changeEmailDialog) return null;

    const { editingEmail, closed } = changeEmailDialog;

    return (
      <Dialog
        open={!!changeEmailDialog && !closed}
        onClose={this.close}
        onExited={this.closed}
        TransitionComponent={SlideTransition}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Change Email' })}</DialogTitle>
        <DialogContent>
          <FormControl className="share-to-modal-form-control">
            <InputLabel htmlFor="email">{intl.formatMessage({ id: 'Email' })}:</InputLabel>
            <Input
              className="share-to-modal-input"
              id="email"
              type="text"
              name="email"
              value={editingEmail}
              onChange={this.changeEmail}
              placeholder={intl.formatMessage({ id: 'Email-address-hints' })}
              required={true}
            />

            {error && <span className="error_field">{intl.formatMessage({ id: error })}</span>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} color="primary" disabled={loading}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button onClick={this.submit} color="primary" autoFocus disabled={loading}>
            {intl.formatMessage({ id: 'Submit' })}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    changeEmailDialog: state.control.changeEmailDialog,
    updateEmailPhase: state.client.updateEmailPhase,
    clientMessage: state.client.message,
    updateEmailMessageExtras: state.client.updateEmailMessageExtras
  }),
  // Map actions to props
  {
    toggleDialog,
    mergeDialog,
    changeInactiveUserEmail
  },
  // mergeProps
  null,
  // options
  {}
)(ChangeEmailDialog);

export default injectIntl(container);
