import React, { useRef, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getAdviserProfilePlus } from '../../store/adviserProfilePlus/duck';
import { INIT, LOADING } from '../../constants/phase';
import _, { set } from 'lodash';
import { CircularProgress, Container, Dialog, DialogContent, DialogTitle, Grid, useMediaQuery } from '@material-ui/core';
import AdviserInfoPageProfile from './AdviserInfoPageProfile/AdviserInfoPageProfile';
import SwiperCore, { Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import AdviserInfoPageBusiness from './AdviserInfoPageBusiness/AdviserInfoPageBusiness';
import AdviserInfoPageSocial from './AdviserInfoPageSocial/AdviserInfoPageSocial';
import './AdviserInfoPage.scss';
import NotFound from '../NotFound/NotFound';
import AdviserInfoPageContact from './AdviserInfoPageContact/AdviserInfoPageContact';
import AdviserInfoBottomButtons from './AdviserInfoBottomButtons/AdviserInfoBottomButtons';
import { withRouter } from 'react-router';
import { THEME_COLOR_PALETTES } from '../../constants/my-details';
import AdviserInfoContainer from './AdviserInfoContainer';
import AdviserInfoLocaleSelector from './AdviserInfoLocaleSelector/AdviserInfoLocaleSelector';
import { getLocaleMessages } from '../../utils/locale';
import AdviserInfoPageCover from './AdviserInfoPageCover/AdviserInfoPageCover';
import AdviserInfoPageTeam from './AdviserInfoPageTeam/AdviserInfoPageTeam';
import AdviserShortCuts from './AdviserShortCuts/AdviserShortCuts';
import { ZoomTransition } from '../../components/Transition/Transition';
import AdviserInfoBottomFAB from './AdviserInfoBottomButtons/AdviserInfoBottomFAB';


SwiperCore.use([Pagination, Navigation, EffectCoverflow, Autoplay]);

const LoadingContainerStyle = {
  width: '100%',
  position: 'relative',
};

const LoadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const ContentContainerStyle = {};

// const HeaderImageGridStyle = {
// 	width: '100%',
// 	textAlign: 'left',
// 	zIndex: 1
// };
//
// const HeaderImageStyle = {
// 	height: 50
// };

const ContentGridStyle = {
  width: '100%',
  zIndex: 1,
};

const ProfileGridStyle = {
  width: '100%',
};

const FindMeGridStyle = {
  width: '100%',
};

const AdviserInfoPage = (props) => {
  const {
    history,
    userDetails,
    adviserInfo,
    getAdviserProfilePlusPhase,
    withNav,
    isAdviser,
    style,
    wideExtras,
    narrowExtras,
    triggerFormOpen,
    defaultMessage,
  } = props;
  const swiperRef = useRef(null);
  const useWideLayout = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [slideIndex, setSlideIndex] = useState(0);
  const [locale, setLocale] = useState('zh-Hant-HK');

  const { shouldShowProfilePlusTeamPage } = adviserInfo || {};
  const themeColor = _.get(adviserInfo, 'themeColor') || 'blue';
  const onSwiper = (swiper) => (swiperRef.current = swiper);

  const onSlideChange = (swiper) => {
    setSlideIndex(swiper.realIndex);
  };

  const onChangeLocale = (locale) => setLocale(locale);
  const [shortCutsOpen, setShortCutsOpen] = useState(false);

  const isNFC = new URLSearchParams(window.location.search).get('s') === 'card'
  const path = window.location.pathname
  const user = userDetails._id
  const _isAdviser = isAdviser ?? userDetails.userType === 'Adviser'

  const adviserShortcutRef = useRef(null)

  useEffect(() => {
    // console.log(userDetails)
    if (shortCutChecker()) {
      setShortCutsOpen(true)
    }
  }, []);

  const shortCutChecker = () => {
    // console.log('shortCutChecker: ', _isAdviser, isNFC, path.includes(user))
    if (_isAdviser && isNFC && path.includes(user)) {
      console.log('shortCutChecker true')
      return true
    } else {
      console.log('shortCutChecker false')
      return false
    }
  }

  const onSetShortCutsOpen = (open) => {
    if (_isAdviser && open) {
      // console.log('userDetails, id::: ', user, ' path: ', path)
      if (path.includes(user) && isNFC) {
        // console.log('set shortcut init open')
        setShortCutsOpen(true)
      } else {
        console.log('onSetShortCuts closed')
        setShortCutsOpen(false)
      }
    } else {
      console.log('onSetShortCuts closed')
      setShortCutsOpen(false)
      // console.log('set shortcut closed')
    }
  }

  const onProfilePicClick = () => {
    if (window.location.pathname !== '/profile-plus' && isNFC) {
      if (shortCutChecker()) {
        // history.push(`/adviser/${userDetails._id}/profile`)
        // goto login, and callback to the profile page
        setShortCutsOpen(true)
      } else {
        if (!user) {
          const redirection = `${window.location.pathname}${window.location.search}`
          history.push({ pathname: '/login', nextUrl: redirection })
        }
        // if (Capacitor.getPlatform() === 'web') {
        //   const system = getMobileOperatingSystem()
        //   if (system === 'iOS') {
        //     window.open('https://apps.apple.com/hk/app/portfoplus/id1590830597?l=tc', '_blank')
        //   } else if (system === 'Android') {
        //     window.open('https://play.google.com/store/apps/details?id=com.portfoplus.PortfoPlus&pli=1', '_blank')
        //   }
        // }
      }
    }
  }
  // console.log('onProfilePicClick::: ', onProfilePicClick)

  return [INIT, LOADING].includes(getAdviserProfilePlusPhase) ? (
    <div style={_.merge(LoadingContainerStyle, { height: window.innerHeight })}>
      <div style={LoadingStyle}>
        <CircularProgress />
      </div>
    </div>
  ) : !!adviserInfo ? (
    <IntlProvider key={locale} locale={locale} defaultLocale={locale} messages={getLocaleMessages(locale).messages}>

      <AdviserInfoContainer
        themeColor={themeColor}
        withNav={withNav}
        style={style}
        wideContent={
          <Fragment>
            <AdviserShortCuts
              adviserShortcutRef={adviserShortcutRef}
              history={history}
              shortCutsOpen={shortCutsOpen}
              onSetShortCutsOpen={onSetShortCutsOpen}
            />
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={3}
              wrap="nowrap"
              style={{
                ...ContentContainerStyle,
                height: '100%',
                paddingTop: 32,
                paddingBottom: !useWideLayout ? 80 : 0,
              }}
            >

              {/*<Grid item className="header" style={HeaderImageGridStyle}>*/}
              {/*	<img src={THEME_COLOR_IMAGES[themeColor || 'blue']} alt="portfoplus" style={HeaderImageStyle}/>*/}
              {/*</Grid>*/}
              <Grid item className="content" style={ContentGridStyle}>
                <Container maxWidth="md">
                  <Grid container alignItems="center" justify="center" wrap="nowrap" spacing={3}>
                    <Grid item style={{ width: 320 }}>
                      <Grid container direction="column" alignItems="center" spacing={2}>
                        <Grid item style={ProfileGridStyle}>
                          <AdviserInfoPageCover
                            onProfilePicClick={onProfilePicClick}
                            isWide={true}
                            userDetails={userDetails}
                            adviserInfo={adviserInfo}
                            isIntoView={true}
                          />
                        </Grid>

                        {!isAdviser && (
                          <Grid item style={FindMeGridStyle}>
                            <AdviserInfoBottomButtons triggerOpen={triggerFormOpen} defaultMessage={defaultMessage} />
                          </Grid>
                        )}

                        {(wideExtras || []).map((extra, index) => (
                          <Grid item style={FindMeGridStyle} key={index}>
                            {extra}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item style={{ width: 'calc(100% - 320px)', marginTop: 10, position: 'relative' }}>
                      <AdviserInfoLocaleSelector
                        locale={locale}
                        onChange={onChangeLocale}
                        textColor={THEME_COLOR_PALETTES[themeColor || 'blue'][0]}
                        style={{
                          position: 'absolute',
                          top: !useWideLayout ? 10 : 0,
                          right: 0,
                          transform: 'translateZ(10px)',
                        }}
                      />

                      <Grid
                        container
                        direction="column"
                        spacing={4}
                        className={_.get(adviserInfo, 'themeColor', 'blue')}
                      >
                        <Grid ref={adviserShortcutRef} item style={{ width: '100%', zIndex: 1044 }} className="pagination-container">
                          <div className="swiper-pagination" />
                        </Grid>
                        <Grid item style={{ width: '100%', position: 'relative' }}>
                          <Swiper
                            initialSlide={0}
                            spaceBetween={16}
                            slidesPerView={1.5}
                            loop={true}
                            loopedSlides={0}
                            centeredSlides={true}
                            centeredSlidesBounds={true}
                            autoHeight={true}
                            touchStartPreventDefault={false}
                            effect="coverflow"
                            autoplay={{
                              delay: 12000,
                            }}
                            coverflowEffect={{
                              rotate: 0,
                              depth: 350,
                              modifier: 2,
                              stretch: 75,
                              slideShadows: false,
                            }}
                            pagination={{
                              el: '.swiper-pagination',
                              type: 'bullets',
                              clickable: true,
                            }}
                            navigation={{
                              nextEl: '.swiper-button-next',
                              prevEl: '.swiper-button-prev',
                            }}
                            onSwiper={onSwiper}
                            onSlideChange={onSlideChange}
                          // height={Math.round(cardHeight + 8)}
                          >
                            <SwiperSlide>
                              <AdviserInfoPageProfile
                                isWide={true}
                                adviserInfo={adviserInfo}
                                isIntoView={slideIndex === 0}
                              />
                            </SwiperSlide>
                            <SwiperSlide>
                              <AdviserInfoPageSocial
                                isWide={true}
                                adviserInfo={adviserInfo}
                                isIntoView={slideIndex === 1}
                              />
                            </SwiperSlide>
                            <SwiperSlide>
                              <AdviserInfoPageBusiness
                                isWide={true}
                                adviserInfo={adviserInfo}
                                isIntoView={slideIndex === 2}
                              />
                            </SwiperSlide>
                            <SwiperSlide>
                              <AdviserInfoPageContact
                                isWide={true}
                                adviserInfo={adviserInfo}
                                isIntoView={slideIndex === 3}
                              />
                            </SwiperSlide>
                            {shouldShowProfilePlusTeamPage && (
                              <SwiperSlide>
                                <AdviserInfoPageTeam
                                  isWide={true}
                                  adviserInfo={adviserInfo}
                                  isIntoView={slideIndex === 4}
                                />
                              </SwiperSlide>
                            )}
                          </Swiper>
                          <div className="swiper-button-next" />
                          <div className="swiper-button-prev" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Fragment>
        }
        narrowContent={
          <Fragment>
            <AdviserShortCuts
              adviserShortcutRef={adviserShortcutRef}
              history={history}
              shortCutsOpen={shortCutsOpen}
              onSetShortCutsOpen={onSetShortCutsOpen} />

            <AdviserInfoLocaleSelector
              locale={locale}
              onChange={onChangeLocale}
              textColor={THEME_COLOR_PALETTES[themeColor || 'blue'][0]}
              style={{
                position: 'absolute',
                top: !useWideLayout ? 5 : 0,
                right: 0,
                transform: 'translateZ(10px)',
              }}
            />

            <div
              style={
                window.innerHeight > 690
                  ? {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '100%',
                    transform: 'translate(-50%, -50%)',
                    padding: '0 0 0 0',
                  }
                  : {
                    width: '100%',
                    padding: 0,
                  }
              }
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={1}
                justify="center"
                style={{ position: 'relative', minHeight: window.innerHeight - 80 }}
                className={`content ${_.get(adviserInfo, 'themeColor', 'blue')}`}
              >
                <Grid
                  item
                  className="pagination-container"
                  style={{ width: 'calc(100% - 150px)', marginTop: 10, zIndex: 1044 }}
                >
                  <div className="swiper-pagination" />
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <div style={{
                      margin: 'auto',

                      marginTop: '100px',
                      width: '5px', height: "5px",
                      display: 'hidden!important',
                      // backgroundColor: 'red'
                    }}
                      ref={adviserShortcutRef}

                    ></div>
                  </div>
                </Grid>
                <Grid item style={{ width: window.innerWidth > 700 ? 700 : '100%' }}>
                  <Swiper
                    initialSlide={0}
                    spaceBetween={8}
                    slidesPerView={window.innerWidth > 700 ? 1.5 : 1.15}
                    loop={true}
                    loopedSlides={0}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    autoHeight={true}
                    touchStartPreventDefault={false}
                    effect="coverflow"
                    autoplay={{
                      delay: 12000,
                    }}
                    coverflowEffect={{
                      rotate: 0,
                      depth: 350,
                      modifier: 2,
                      stretch: 75,
                      slideShadows: false,
                    }}
                    pagination={{
                      el: '.swiper-pagination',
                      type: 'bullets',
                      clickable: true,
                    }}
                    navigation={{
                      nextEl: '.swiper-button-next',
                      prevEl: '.swiper-button-prev',
                    }}
                    onSwiper={onSwiper}
                    onSlideChange={onSlideChange}
                  // height={Math.round(cardHeight + 8)}
                  >
                    <SwiperSlide>
                      <AdviserInfoPageCover
                        onProfilePicClick={onProfilePicClick}
                        adviserShortcutRef={adviserShortcutRef}
                        isWide={false}
                        userDetails={userDetails}
                        adviserInfo={adviserInfo}
                        isIntoView={slideIndex === 0}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AdviserInfoPageProfile isWide={false} adviserInfo={adviserInfo} isIntoView={slideIndex === 1} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AdviserInfoPageSocial isWide={false} adviserInfo={adviserInfo} isIntoView={slideIndex === 2} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AdviserInfoPageBusiness isWide={false} adviserInfo={adviserInfo} isIntoView={slideIndex === 3} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <AdviserInfoPageContact isWide={false} adviserInfo={adviserInfo} isIntoView={slideIndex === 4} />
                    </SwiperSlide>
                    {shouldShowProfilePlusTeamPage && (
                      <SwiperSlide>
                        <AdviserInfoPageTeam isWide={false} adviserInfo={adviserInfo} isIntoView={slideIndex === 5} />
                      </SwiperSlide>
                    )}
                  </Swiper>
                  <div className="swiper-button-next" />
                  <div className="swiper-button-prev" />
                </Grid>

                {!isAdviser && (
                  <Grid item style={{ ...FindMeGridStyle }}>
                    <AdviserInfoBottomFAB
                      triggerOpen={triggerFormOpen}
                      defaultMessage={defaultMessage}
                      fab={true}
                    />
                  </Grid>
                )}

                {(narrowExtras || []).map((extra, index) => (
                  <Grid item style={{ width: '88%' }} key={index}>
                    {extra}
                  </Grid>
                ))}
              </Grid>
            </div>
          </Fragment>
        }
      />
    </IntlProvider>
  ) : (
    <NotFound onClick={() => history.replace('/')} />
  );
};

const container = connect(
  (state) => ({
    userDetails: state.user.userDetails,
    adviserInfo: _.get(state.adviserProfilePlus, 'adviserProfilePlus.adviserInfo'),
    getAdviserProfilePlusPhase: state.adviserProfilePlus.getAdviserProfilePlusPhase,
  }),
  {
    getAdviserProfilePlus,
  },
)(AdviserInfoPage);

export default withRouter(container);
