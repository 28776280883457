import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';
import TextEditorInput from '../TextEditorInput/TextEditorInput';
import { updateUserDetails } from '../../../../store/user/duck';
import EnableTeamPageDialog from './EnableTeamPageDialog/EnableTeamPageDialog';

const FullHeightStyle = {
  height: '100%'
};

const FullWidthStyle = {
  width: '100%'
};

const CheckBoxStyle = {
  padding: 4
};

const MyDetailsTeam = props => {
  const { intl, details, error, onChangeDetails, updateError, updateUserDetails, allowProfilePlusTeamPage } = props;

  const [enableTeamPageDialog, setEnableTeamPageDialog] = React.useState(false);

  const {
    shouldShowProfilePlusTeamPage,
    shouldShowProfilePlusTeamLogo,
    teamDescription,
    teamPage,
    teamInstagram,
    teamFacebook,
    teamLinkedin,
    teamWechat
  } = details || {};

  const createSimpleOnChangeHandler = (key, emptyValue) => event =>
    onChangeDetails({ [key]: event.target.value || emptyValue });
  const createSimpleOnChangeCheckboxHandler = key => event => onChangeDetails({ [key]: event.target.checked || false });

  const onChangeShouldShowProfilePlusTeamPage = event => {
    if (allowProfilePlusTeamPage) {
      onChangeDetails({ shouldShowProfilePlusTeamPage: event.target.checked || false });
    } else {
      setEnableTeamPageDialog(true);
    }
  };
  const onChangeShouldShowProfilePlusTeamLogo = createSimpleOnChangeCheckboxHandler('shouldShowProfilePlusTeamLogo');
  const onChangeTeamDescription = editorState => onChangeDetails({ teamDescription: editorState });
  const setDefaultTeamDescription = () => updateUserDetails({ unsetKey: 'teamDescription' });
  const onChangeTeamPage = createSimpleOnChangeHandler('teamPage', '');
  const onChangeTeamInstagram = createSimpleOnChangeHandler('teamInstagram', '');
  const onChangeTeamFacebook = createSimpleOnChangeHandler('teamFacebook', '');
  const onChangeTeamLinkedin = createSimpleOnChangeHandler('teamLinkedin', '');
  const onChangeTeamWechat = createSimpleOnChangeHandler('teamWechat', '');

  const closeEnableTeamPageDialog = () => setEnableTeamPageDialog(false);

  return (
    <Fragment>
      <EnableTeamPageDialog open={enableTeamPageDialog} onClose={closeEnableTeamPageDialog} />

      <Grid container direction="column" spacing={3}>
        <Grid item>
          <AladdinHint title={intl.formatMessage({ id: 'mda-team-title' })} />
        </Grid>
        <Grid item>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" style={FullHeightStyle}>
                <Grid item>
                  <Typography color="primary">{intl.formatMessage({ id: 'mda-team-show-team-page' })}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Checkbox
                checked={allowProfilePlusTeamPage ? shouldShowProfilePlusTeamPage || false : false}
                onChange={onChangeShouldShowProfilePlusTeamPage}
                style={CheckBoxStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center" style={FullHeightStyle}>
                <Grid item>
                  <Typography>{intl.formatMessage({ id: 'mda-team-show-team-logo' })}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Checkbox
                id='enableTeamPage'
                checked={shouldShowProfilePlusTeamLogo || false}
                onChange={onChangeShouldShowProfilePlusTeamLogo}
                style={CheckBoxStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        {teamDescription && (
          <Grid item>
            <TextEditorInput
              title={intl.formatMessage({ id: 'mda-team-description' })}
              value={teamDescription || ''}
              onChange={onChangeTeamDescription}
              onSetDefault={setDefaultTeamDescription}
            />
          </Grid>
        )}
        <Grid item>
          <Typography color="primary" align="center">
            {intl.formatMessage({ id: 'mda-team-contacts' })}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'mda-team-page' })}
            value={teamPage || ''}
            onChange={onChangeTeamPage}
            InputLabelProps={{ shrink: true }}
            placeholder="https://www.myweb.com"
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'instagram' })}
            value={teamInstagram || ''}
            onChange={onChangeTeamInstagram}
            InputLabelProps={{ shrink: true }}
            placeholder="portfoplus"
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'Facebook Link' })}
            value={teamFacebook || ''}
            onChange={onChangeTeamFacebook}
            InputLabelProps={{ shrink: true }}
            placeholder="https://www.facebook.com/portfoplus"
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'linkedin' })}
            value={teamLinkedin || ''}
            onChange={onChangeTeamLinkedin}
            InputLabelProps={{ shrink: true }}
            placeholder="https://www.linkedin.com/company/portfoplus"
          />
        </Grid>
        <Grid item>
          <TextField
            label={intl.formatMessage({ id: 'Wechat ID' })}
            value={teamWechat || ''}
            onChange={onChangeTeamWechat}
            InputLabelProps={{ shrink: true }}
            placeholder="portfoplus"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

const container = connect(state => ({}), {
  updateUserDetails
})(MyDetailsTeam);

export default injectIntl(container);
