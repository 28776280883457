import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { approveUser, handleSignOut } from '../../store/user/duck';
import { isUserConnected } from '../../utils/user';
import _ from 'lodash';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';

class SendVerificationEmailDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { resent: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, approvePhase, onClose } = this.props;
    if (approvePhase === 'accepted' && this.props.open && prevProps.open) {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Verfication_Email_Sent' })), { className: 'new-toast' });
      this.setState({ resent: false });
      onClose();
    }
  }

  render() {
    const { intl, userDetails, approveUser, open, onClose } = this.props;
    const { resent } = this.state;

    return userDetails &&
      userDetails.userType === 'User' &&
      !userDetails.emailVerified &&
      !isUserConnected(userDetails) ? (
      <Dialog className="email-verify-modal" open={open} onClose={onClose}>
        <DialogContent className="content">
          <div
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                { id: 'confirm-send-verification-email' },
                { email: _.get(userDetails, 'email', '') }
              )
            }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={resent}
            onClick={() => {
              this.setState({ resent: true });
              approveUser({
                email: _.get(userDetails, 'email', ''),
                id: _.get(userDetails, '_id', ''),
                resendVerification: 'resendVerification'
              });
            }}
          >
            {intl.formatMessage({ id: 'Send verification Email' })}
          </Button>
          <Button
            variant="contained"
            style={{ background: '#fff' }}
            fullWidth={true}
            disabled={resent}
            onClick={onClose}
            elevation={0}
          >
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>

          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: '(check junk box if not receiving)' }) }} />
        </DialogContent>
      </Dialog>
    ) : null;
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    approvePhase: state.user.approvePhase
  }),
  // Map actions to props
  {
    approveUser,
    handleSignOut
  },
  // mergeProps
  null,
  // options
  {}
)(SendVerificationEmailDialog);

export default injectIntl(container);
