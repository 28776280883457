export const formatAPIResponseMessage = (intl, message) => {
  if (message.indexOf('[intl] ') > -1) {
    const content = message.replace('[intl] ', '');
    let text, extra;
    // when extra is included
    if (content.indexOf('*|*') > -1) {
      let splits = content.split('*|*');
      text = splits[0];
      extra = JSON.parse(splits[1]);
    } else {
      text = content;
    }

    return intl.formatMessage({ id: text }, extra);
  } else {
    return message;
  }
};
