import React, { PureComponent } from 'react';
import _ from 'lodash';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import countryList from '../../../../store/locale/country-list';
import DeleteIconButton from '../../../../components/DeleteIconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Input
} from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import AppContentContainer from '../../../../components/AppContentContainer/AppContentContainer.js';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { formatEmail } from '../../../../utils/email';
import { sanitize } from 'dompurify';

class FactsheetDetailsFormOne extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phoneNumber: '',
      wechatId: '',
      whatsapp: '',
      lineId: '',
      nationality: '',
      birthPlace: '',
      workLocation: '',
      homeLocation: '',
      occupation: '',
      modalIsOpen: false,
      editClientData: false,
      disabelEmail: false,
      editFactsheet: false,
      askToDisableTracking: false,
      menu: true,
      err: {}
    };
    this.submitForm = this.submitForm.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
  }
  componentDidMount() {
    this.props.renderHeaderButton(false);
  }

  setClientDetailsFromProps(checkLocalStorage, props) {
    const { userDetails, clientDetails, clientPersonalDetails } = props;

    let clientPersonalData = JSON.parse(localStorage.getItem('clientPersonalData'));
    let clientFactsheetDataOne = JSON.parse(localStorage.getItem('clientFactsheetDataOne'));

    let obj = {};

    if (clientDetails && !this.isEmpty(clientDetails)) {
      obj.editClientData = true;
      obj.disabelEmail = true;
    }

    if (clientPersonalData) {
      obj.email = clientPersonalData.email;
    }

    if (checkLocalStorage && clientFactsheetDataOne !== null) {
      if (clientFactsheetDataOne.email) {
        obj.email = clientFactsheetDataOne.email;
      }

      obj.phoneNumber = clientFactsheetDataOne.phoneNumber;
      obj.whatsapp = clientFactsheetDataOne.whatsapp;
      obj.wechatId = clientFactsheetDataOne.wechatId;
      obj.lineId = clientFactsheetDataOne.lineId;
      obj.nationality = clientFactsheetDataOne.nationality;
      obj.birthPlace = _.get(clientFactsheetDataOne, 'birthPlace', '');
      obj.occupation = clientFactsheetDataOne.occupation ? clientFactsheetDataOne.occupation : '';
      obj.workLocation = clientFactsheetDataOne.workLocation ? clientFactsheetDataOne.workLocation : '';
      obj.homeLocation = clientFactsheetDataOne.homeLocation ? clientFactsheetDataOne.homeLocation : '';
    } else if (clientDetails) {
      obj.phoneNumber = clientDetails.phoneNumber ? clientDetails.phoneNumber : '';
      obj.whatsapp = clientDetails.whatsapp ? clientDetails.whatsapp : '';
      obj.wechatId = clientDetails.wechatId ? clientDetails.wechatId : '';
      obj.lineId = clientDetails.lineId ? clientDetails.lineId : '';
      obj.nationality = clientDetails.nationality ? clientDetails.nationality : '';
      obj.birthPlace = _.get(clientDetails, 'birthPlace', '') ? _.get(clientDetails, 'birthPlace', '') : '';
      obj.occupation = clientDetails.occupation ? clientDetails.occupation : '';
      obj.workLocation = clientDetails.workLocation ? clientDetails.workLocation : '';
      obj.homeLocation = clientDetails.homeLocation ? clientDetails.homeLocation : '';
    }

    let val = clientPersonalDetails;
    const isInactive = val.inactiveUser !== undefined && val.inactiveUser === true ? true : false;
    if (isInactive && val.trackingId && val.trackingId.isActive) {
      obj.askToDisableTracking = true;
    } else {
      if (val.advisorDetails && val.advisorDetails.length > 0) {
        val.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.askToDisableTracking = true;
          }
          return true;
        });
      }
    }
    this.setState(obj);
  }

  UNSAFE_componentWillMount() {
    let { location } = this.props;

    let referralRecord = location.state && location.state.referralRecord ? location.state.referralRecord : undefined;

    if (referralRecord) {
      this.setState({
        email: referralRecord.email || '',
        phoneNumber: referralRecord.phoneNumber ? referralRecord.phoneNumber : '',
        whatsapp: referralRecord.whatsapp ? referralRecord.whatsapp : '',
        wechatId: referralRecord.wechatId ? referralRecord.wechatId : '',
        lineId: referralRecord.lineId ? referralRecord.lineId : ''
      });
    } else {
      this.setClientDetailsFromProps(true, this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, checkClientPhase } = this.props;

    if (this.props.deleteClientPhase !== true && nextProps.deleteClientPhase === true) {
      this.done();
    }
    if (nextProps.enableTrackingPhase === true) {
      let data = {};
      // if (nextProps.fetchDetailsPhase === true) {
      //   toast.success(intl.formatMessage({ id: nextProps.message }));
      // }
      const { clientPersonalDetails, userDetails } = this.props;
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            data.factsheetId = _.get(value, 'factsheetId', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        data.factsheetId = _.get(clientPersonalDetails, 'factsheetId', '');
      }
      data.userId = _.get(clientPersonalDetails, '_id', '');
      this.props.getClientDetails(data);
      this.setState({ askToDisableTracking: false });
      this.setClientDetailsFromProps(false, nextProps);
    }
    if (nextProps.updateEmailPhase === false) {
      this.setState({
        warningModalIsOpen: false,
        resMessage: intl.formatMessage({ id: nextProps.message }, nextProps.updateEmailMessageExtras),
        normalModal: true,
        submitOnlyClient: false,
        changeInactiveEmail: false,
        email: _.get(nextProps, 'clientPersonalDetails.email')
      });
    } else if (nextProps.updateEmailPhase === true) {
      this.setState({ changeInactiveEmail: false });
    }

    if (checkClientPhase !== false && nextProps.checkClientPhase === false) {
      this.setState({
        resMessage: intl.formatMessage({ id: nextProps.clientMessage }),
        normalModal: true
      });
    } else if (this.props.checkClientPhase !== true && nextProps.checkClientPhase === true) {
      this.processNext();
    }
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleNumberKeyDown(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8)) {
      e.preventDefault();
    }
  }

  setLocalStorage = () => {
    var formData = {};
    formData.email = formatEmail(this.state.email || '');
    formData.phoneNumber = this.state.phoneNumber ? this.state.phoneNumber : '';
    formData.whatsapp = this.state.whatsapp ? this.state.whatsapp : '';
    formData.wechatId = this.state.wechatId;
    formData.lineId = this.state.lineId;
    formData.nationality = this.state.nationality;
    formData.birthPlace = this.state.birthPlace;
    formData.workLocation = this.state.workLocation;
    formData.homeLocation = this.state.homeLocation;
    formData.occupation = this.state.occupation;

    localStorage.setItem('clientFactsheetDataOne', JSON.stringify(formData));
  };

  closeNormalModal = () => {
    const { initChangeEmailPhase } = this.props;
    this.setState({ normalModal: false });
    initChangeEmailPhase();
  };

  validateData = () => {
    const { email } = this.state;

    const err = {};

    if (email !== '') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(email))) {
        err.email = 'Invalid email';
      }
    }

    this.setState({ err });

    return !Object.keys(err).length;
  };

  processCheckClient = () => {
    const { userDetails, checkClient } = this.props;
    const { email } = this.state;
    checkClient({ email: formatEmail(email || ''), advisorId: userDetails._id });
  };

  processNext = () => {
    this.setLocalStorage();
    this.props.handleNextPage(2);
  };

  processPrevious = () => {
    this.setLocalStorage();
    this.props.handlePreviousPage(2);
  };

  submitForm() {
    if (this.validateData()) {
      if (!this.state.editClientData) {
        this.processCheckClient();
      } else {
        this.processNext();
      }
    }
  }

  handlePrevious() {
    if (this.validateData()) {
      this.processPrevious();
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true, menu: false });
  }

  deleteClient() {
    // if there is tracking, delete it too
    if (this.state.askToDisableTracking) {
      const { clientPersonalDetails, enableTracking, userDetails } = this.props;
      let obj = {};
      if (
        clientPersonalDetails &&
        clientPersonalDetails.advisorDetails &&
        clientPersonalDetails.advisorDetails.length > 0
      ) {
        clientPersonalDetails.advisorDetails.map(value => {
          if (value.email === userDetails.email && value.trackingId && value.trackingId.isActive) {
            obj.trackingId = _.get(value, 'trackingId._id', '');
          }
          return true;
        });
      } else if (clientPersonalDetails.inactiveUser) {
        obj.trackingId = _.get(clientPersonalDetails, 'trackingId._id', '');
      }

      obj.clientId = _.get(clientPersonalDetails, '_id', '');
      obj.trackingType = 'delete';
      enableTracking(obj);
    }
    var clientId = JSON.parse(localStorage.getItem('clientId'));
    let factsheet = this.props.clientDetails;
    let data = {};
    data.clientId = clientId;
    data.factsheetId = factsheet._id;
    this.props.deleteClient(data);
    this.setState({ modalIsOpen: false, menu: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false,
      menu: true
    });
  }

  copyTelToWhatsapp() {
    const { phoneNumber } = this.state;
    if (phoneNumber && phoneNumber !== '') {
      this.setState({ whatsapp: phoneNumber });
    }
  }

  done = () => {
    const { history, location } = this.props;
    const replaceRoute = _.get(location, 'state.replaceRoute');
    if (replaceRoute) {
      history.replace(replaceRoute);
    } else {
      history.goBack();
    }
  };

  onGoBack = () => {
    const { history, toggleDialog } = this.props;
    toggleDialog('discardWarning', {
      onConfirm: () => {
        sessionStorage.removeItem('referralRecordId');
        history.goBack();
      }
    });
  };

  changeInactiveEmailmodal = () => {
    this.setState({ changeInactiveEmail: true });
  };

  closeInactiveEmailmodal = () => {
    this.setState({
      changeInactiveEmail: false,
      err: '',
      email: _.get(this.props, 'clientPersonalDetails.email')
    });
  };

  //To change the email of inactive user
  handleChangeInactiveEmail = () => {
    const { changeInactiveUserEmail, clientPersonalDetails } = this.props;
    const err = {};
    // if (this.state.email === '') {
    //   err.email = 'Email is required';
    // } else if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    // ) {
    //   err.email = 'Invalid email';
    // }
    if (this.state.email !== '') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formatEmail(this.state.email))) {
        err.email = 'Invalid email';
      }
    }
    if (this.state.email && clientPersonalDetails && clientPersonalDetails.email) {
      let _id = clientPersonalDetails._id.toString();
      let inactiveEmail = this.state.email.toLowerCase();
      if (inactiveEmail.indexOf(_id) !== -1) {
      } else {
      }
    }

    this.setState({ err });
    if (!Object.keys(err).length) {
      let formData = {};
      formData.email = formatEmail(this.state.email);
      formData.clientId = _.get(this.props, 'clientPersonalDetails._id');
      if (formData.email === clientPersonalDetails.email) {
        this.setState({ changeInactiveEmail: false });
      }
      if (formData.email !== clientPersonalDetails.email) {
        changeInactiveUserEmail(formData);
      }
    }
  };

  render() {
    const { intl, clientPersonalDetails } = this.props;
    const { phoneNumber } = this.state;
    const isInactive =
      clientPersonalDetails.inactiveUser !== undefined && clientPersonalDetails.inactiveUser === true ? true : false;
    let DeleteMessage = !isInactive
      ? // ? 'Deleting the user would remove all linkage with the users and data can’t be <br/> recovered. Are You Sure?'
        // : 'Deleting inactive user would remove all the user data inputted. ';
        intl.formatMessage({
          id: 'delete_all_data'
        })
      : intl.formatMessage({ id: 'delete_active_user_message' });

    let emailShow = false;
    if (this.state.email && clientPersonalDetails && clientPersonalDetails.email) {
      let _id = clientPersonalDetails._id.toString();
      let inactiveEmail = this.state.email;
      if (inactiveEmail.indexOf(_id) !== -1) {
        emailShow = true;
      } else {
        emailShow = false;
      }
    }

    const editInactiveEmail =
      clientPersonalDetails &&
      !clientPersonalDetails.consent &&
      clientPersonalDetails.inactiveUser !== undefined &&
      clientPersonalDetails.inactiveUser === true;

    return (
      <AppContentContainer>
        <div>
          <TopNavigationPortal>
            <span onClick={this.onGoBack} className="back_arrow">
              <ArrowBackIcon />
            </span>
            {(this.state.editFactsheet || this.state.editClientData) && (
              <DeleteIconButton className="edit__pen" handleClick={this.openModal.bind(this)} />
            )}
          </TopNavigationPortal>
          <div id="wrapper">
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="msform" noValidate={true}>
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                        <li />
                        <li />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Factsheet' })}</h2>

                        <div className="form-group">
                          <input
                            type="text"
                            name={!this.state.disabelEmail ? 'email' : ''}
                            value={!emailShow ? this.state.email : ''}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                            className={!this.state.disabelEmail ? 'add-client-no-email-input' : ''}
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Email' })}
                          </label>
                          {/* {!this.state.disabelEmail && (
												  <Button
													variant="contained"
													color="primary"
													className="add-client-no-email-button"
													onClick={this.handleClickNoEmailModal}
												  >
													{intl.formatMessage({ id: 'No Email' })}
												  </Button>
												)}*/}
                          {editInactiveEmail && (
                            <Button
                              variant={'contained'}
                              color="primary"
                              className="action-change-button"
                              onClick={this.changeInactiveEmailmodal}
                            >
                              {!emailShow ? intl.formatMessage({ id: 'Change' }) : intl.formatMessage({ id: 'Add' })}
                            </Button>
                          )}
                          <i className="bar" />
                          {this.state.err.email ? (
                            <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className="form-group phone_module clearfix">
                          <label className=" phone-module-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Tel' })}
                          </label>
                          <Phone
                            international={true}
                            // inputComponent={SmartInput}
                            country="HK"
                            placeholder={intl.formatMessage({
                              id: 'Enter phone number'
                            })}
                            value={this.state.phoneNumber}
                            onChange={value => this.setState({ phoneNumber: value })}
                            //labels={countryList.labels}
                            countries={countryList.labels}
                            flagsPath={'img/flags/'}
                            indicateInvalid
                          />

                          <i className="bar" />
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <div className="form-group phone_module clearfix">
                              <label className=" phone-module-label" htmlFor="input">
                                {intl.formatMessage({ id: 'WhatsApp' })}
                              </label>
                              <Phone
                                international={true}
                                // inputComponent={SmartInput}
                                country="HK"
                                placeholder={intl.formatMessage({
                                  id: 'Enter whatsapp number'
                                })}
                                value={this.state.whatsapp}
                                onChange={value => this.setState({ whatsapp: value })}
                                //labels={countryList.labels}
                                countries={countryList.labels}
                                flagsPath={'img/flags/'}
                                indicateInvalid
                              />
                              <i className="bar" />
                              {phoneNumber && phoneNumber !== '' && (
                                <Button
                                  variant={'contained'}
                                  color="primary"
                                  className="action-change-button"
                                  onClick={this.copyTelToWhatsapp.bind(this)}
                                >
                                  {intl.formatMessage({ id: 'Same' })}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="wechatId"
                                value={this.state.wechatId}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'WeChat ID' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>

                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="lineId"
                                value={this.state.lineId}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Line ID' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="nationality"
                                value={this.state.nationality}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Nationality' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="birthPlace"
                                value={this.state.birthPlace}
                                onChange={this.handleChange.bind(this)}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'Place of Birth' })}
                              </label>
                              <i className="bar" />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <input
                            type="text"
                            name="occupation"
                            value={this.state.occupation || ''}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Occupation' })}
                          </label>
                          <i className="bar" />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="workLocation"
                            value={this.state.workLocation}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Work Location' })}
                          </label>
                          <i className="bar" />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="homeLocation"
                            value={this.state.homeLocation}
                            onChange={this.handleChange.bind(this)}
                            required="required"
                          />
                          <label className="control-label" htmlFor="input">
                            {intl.formatMessage({ id: 'Home Location' })}
                          </label>
                          <i className="bar" />
                        </div>
                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              className="prevNextButton"
                              startIcon={<ArrowBackIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              className="prevNextButton"
                              endIcon={<ArrowForwardIosIcon />}
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  </div>
                  <Dialog
                    open={this.state.modalIsOpen}
                    onClose={this.closeModal}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
                    <DialogContent>
                      <div dangerouslySetInnerHTML={{ __html: sanitize(DeleteMessage) }} />
                      {this.state.askToDisableTracking && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(intl.formatMessage({ id: 'delete_the_tracking' }))
                          }}
                        />
                      )}
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={this.closeModal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.deleteClient.bind(this)} color="primary" autoFocus>
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={!!this.state.changeInactiveEmail}
                    onClose={this.closeInactiveEmailmodal.bind(this)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogTitle id="alert-dialog-slide-title">
                      {intl.formatMessage({ id: 'Change Email' })}
                    </DialogTitle>
                    <DialogContent>
                      <FormControl className="share-to-modal-form-control">
                        <InputLabel htmlFor="email">{intl.formatMessage({ id: 'Email' })}:</InputLabel>
                        <Input
                          className="share-to-modal-input"
                          id="email"
                          type="text"
                          name="email"
                          value={!emailShow ? this.state.email : ''}
                          onChange={this.handleChange.bind(this)}
                          placeholder={intl.formatMessage({
                            id: 'Email-address-hints'
                          })}
                          required={true}
                        />

                        {this.state.err.email ? (
                          <span className="error_field">{intl.formatMessage({ id: this.state.err.email })}</span>
                        ) : (
                          ''
                        )}
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeInactiveEmailmodal} color="primary">
                        {intl.formatMessage({ id: 'Cancel' })}
                      </Button>
                      <Button onClick={this.handleChangeInactiveEmail} color="primary" autoFocus>
                        {intl.formatMessage({ id: 'Submit' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={!!this.state.normalModal}
                    onClose={this.closeNormalModal}
                    TransitionComponent={SlideTransition}
                    fullWidth={true}
                  >
                    <DialogContent>
                      <div dangerouslySetInnerHTML={{ __html: sanitize(this.state.resMessage) }} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.closeNormalModal} color="primary">
                        {intl.formatMessage({ id: 'OK' })}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContentContainer>
    );
  }
}

export default FactsheetDetailsFormOne;
