import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { toggleDialog } from '../../../store/control/duck';
import { enableTracking, getClientDetails, updateFactSheet } from '../../../store/client/duck';
import { injectIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControlLabel
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { SlideTransition } from '../../Transition/Transition';
import { geToFactsheetDetails } from '../utils';
import withRouter from 'react-router/withRouter';
import _ from 'lodash';
import { updateAdviserBusiness } from '../../../store/adviserDashboard/duck';
import MetControl from '../controls/MetControl/MetControl';
import BusinessVolControl from '../controls/BusinessVolControl/BusinessVolControl';
import { tracking } from '../../../utils/tracking';
import { LOADING } from '../../../constants/phase';

const newBizWrapperStyle = { marginBottom: '20px' };
const newBizButtonsStyle = { display: 'flex', justifyContent: 'center' };

const INITIAL_STATE = {
  closeDate: moment(),
  newBiz: '',
  checkUpdateProgress: true,
  allowCloseTracking: false,
  businessVolError: undefined,
  newBizError: undefined,
  metError: undefined,
  loading: false,

  deleteDialog: false,
  warningDialog: false
};

class TrackOffDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.trackingObj = undefined;
  }

  componentDidUpdate(prevProps, prevState) {
    const { toggleDialog } = this.props;
    if (this.props.enableTrackingPhase === true && prevProps.enableTrackingPhase === LOADING) {
      this.setState(INITIAL_STATE);
      toggleDialog('trackOff', false);
    }
  }

  close = () => {
    this.props.toggleDialog('trackOff', { ...this.props.trackOffDialog, closed: true });
  };
  closed = () => {
    this.props.toggleDialog('trackOff', false);
    this.setState(INITIAL_STATE);
  };

  editFactsheet = () => {
    const { history, location, trackOffDialog, getClientDetails } = this.props;
    const { newBiz, closeDate } = this.state;
    const { client, businessVol, met } = trackOffDialog;

    geToFactsheetDetails(client, history, getClientDetails, {
      businessVol: businessVol,
      val: client,
      met: met,
      newBiz: newBiz,
      closeDate: closeDate.toString(),
      previousPath: location.pathname
    });
  };

  submit = () => {
    const {
      userDetails,
      trackOffDialog,
      adviserBusiness,
      updateFactSheet,
      enableTracking,
      updateAdviserBusiness
    } = this.props;
    const { newBiz, closeDate, checkUpdateProgress } = this.state;
    const { client, met, businessVol } = trackOffDialog;

    const stateObj = {};
    if (!newBiz) {
      stateObj.newBizError = 'Required input field';
    }
    if (newBiz !== 'No' && !businessVol) {
      stateObj.businessVolError = 'Required input field';
    }

    if (newBiz === 'Yes' && !met) {
      stateObj.metError = 'Required input field';
    }

    if (!Object.keys(stateObj).length) {
      let excludeUpdateProgress = true;

      const closeDateMoment = closeDate ? moment(closeDate).format('YYYY-MM-DD') : null;
      const closeDateMomentUTCFormat = closeDateMoment
        ? moment.utc(closeDateMoment).format('YYYY-MM-DDT00:00:00.00Z')
        : null;

      let obj = {
        clientId: client._id,
        factsheetId: client.factsheetId._id,
        advisorId: userDetails._id,
        trackingId: client.trackingId._id,
        newBiz: newBiz,
        met: met,
        businessVol: newBiz === 'No' ? 0 : businessVol,
        dayHighToClose: client.trackingId.dateHigh
          ? moment(closeDate, 'YYYY-MM-DD').diff(moment(client.trackingId.dateHigh, 'YYYY-MM-DD'), 'days')
          : undefined,
        dayCount: moment(closeDate, 'YYYY-MM-DD').diff(moment(client.trackingId.startDate, 'YYYY-MM-DD'), 'days'),
        isActive: false,
        closeDate: closeDateMomentUTCFormat
      };

      let currentDate = moment().format('YYYY-MM-DD');
      const timeDifference = moment().diff(moment(client.trackingId.createdDate), 'minutes');
      if (moment(client.trackingId.startDate, 'YYYY-MM-DD').isSame(currentDate) === true && timeDifference <= 10) {
        //if (timeDifference <= 10) {
        excludeUpdateProgress = false;
        obj.trackingType = 'delete';
        this.trackingObj = obj;
        this.setState({ warningDialog: true });
        //}
      } else {
        stateObj.loading = true;
        if (
          client.factsheetId.latestMeetingDate === null ||
          client.factsheetId.latestMeetingDate === undefined ||
          client.factsheetId.latestMeetingDate === ''
        ) {
          updateFactSheet({
            id: client.factsheetId._id,
            key: 'latestMeetingDate',
            value: closeDateMomentUTCFormat
          });
        }
        obj.trackingType = 'disable';
        enableTracking(obj);

        // Tracking
        tracking('Disable Business Tracking');
      }
      //Show Trackon list when enable tracking on
      // this.setState({ clientState: 'TrackOn' })
      //To add the current business in expected business if update progress is checked and generate new business is yes
      if (checkUpdateProgress && newBiz === 'Yes' && excludeUpdateProgress) {
        let confirmedBiz;
        if (adviserBusiness && adviserBusiness.confirmedBiz) {
          confirmedBiz = _.get(adviserBusiness, 'confirmedBiz', 0) + parseInt(businessVol);
        } else {
          confirmedBiz = parseInt(businessVol);
        }
        updateAdviserBusiness({ confirmedBiz });
      }
    }

    this.setState(stateObj);
  };

  delete = () => this.setState({ deleteDialog: true });

  deleteTracking = () => {
    const { trackOffDialog, enableTracking, toggleDialog } = this.props;
    const { client } = trackOffDialog;

    enableTracking({ clientId: client._id, trackingId: client.trackingId._id, trackingType: 'delete' });
    this.setState({ deleteDialog: false, loading: true });
    toggleDialog('trackOff', false);
  };

  closeDeleteDialog = () => this.setState({ deleteDialog: false });

  disableTracking = () => {
    this.props.enableTracking(this.trackingObj);
    this.trackingObj = undefined;
    this.setState({ warningDialog: false });
  };

  closeWarningDialog = () => this.setState({ warningDialog: false });

  render() {
    const { intl, trackOffDialog, toggleDialog } = this.props;
    const {
      closeDate,
      newBiz,
      checkUpdateProgress,
      businessVolError,
      newBizError,
      metError,
      deleteDialog,
      warningDialog,
      loading
    } = this.state;

    if (!trackOffDialog) return null;

    const { client, businessVol, met, closed } = trackOffDialog;

    return (
      <Fragment>
        {/*Tracking Off Dialog*/}
        <Dialog
          open={!!trackOffDialog && !closed}
          onClose={this.close}
          onExited={this.closed}
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle>{intl.formatMessage({ id: 'Case Closing' })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{intl.formatMessage({ id: 'CloseTrackingDesc' })}</DialogContentText>
            <div className="modal-text tracking-off">
              <div>
                <div className="case-close-met" style={{ marginBottom: '20px' }}>
                  <div className="col-md-5 col-xs-5 text-right caseclosing_padding">
                    {intl.formatMessage({ id: 'Total No. of Meeting' })}
                  </div>
                  <MetControl
                    className="col-md-7 col-xs-7"
                    style={{ paddingLeft: '11px' }}
                    met={met}
                    onChange={met => {
                      toggleDialog('trackOff', { ...trackOffDialog, met: met });
                      if (met > 0) {
                        this.setState({ metError: undefined });
                      }
                    }}
                  />
                </div>
                {metError && (
                  <div className="text-right error_field" style={{ width: '75%' }}>
                    {intl.formatMessage({ id: metError })}
                  </div>
                )}
              </div>
              <div className="caseclose-datepicker-form">
                <div className="form-group datepicker-group">
                  <div className="col-md-5 col-xs-5 text-right caseclosing_padding">
                    <label className="control-label" htmlFor="select">
                      {intl.formatMessage({ id: 'Close Date' })}
                    </label>
                  </div>
                  <div className="col-md-7 col-xs-7">
                    <DatePicker
                      className="caseclose-datepicker close-case-date"
                      maxDate={new Date()}
                      minDate={
                        client && client.trackingId && client.trackingId.startDate
                          ? moment.parseZone(client.trackingId.startDate)
                          : undefined
                      }
                      name="closeDate"
                      format="DD-MMM-YYYY"
                      value={closeDate}
                      onChange={date => this.setState({ closeDate: date })}
                      autoOk={true}
                      invalidDateMessage={null}
                      minDateMessage={null}
                      maxDateMessage={null}
                    />
                  </div>
                </div>
              </div>
              <div className="case-close-met" style={newBizWrapperStyle}>
                <div className="col-md-5 col-xs-5 text-right caseclosing_padding">
                  {intl.formatMessage({ id: 'gen-new-biz' })}
                </div>
                <div className="col-md-7 col-xs-7" style={newBizButtonsStyle}>
                  <Button
                    variant={newBiz === 'Yes' ? 'contained' : 'outlined'}
                    className={`option-select-button plan-type-select-button ${newBiz === 'Yes' ? 'selected' : ''}`}
                    onClick={() => this.setState({ newBiz: 'Yes', newBizError: undefined })}
                  >
                    {intl.formatMessage({ id: 'YES' })}
                  </Button>
                  <Button
                    variant={newBiz === 'No' ? 'contained' : 'outlined'}
                    className={`option-select-button plan-type-select-button ${newBiz === 'No' ? 'selected' : ''}`}
                    onClick={() => {
                      this.setState({
                        newBiz: 'No',
                        newBizError: undefined,
                        businessVolError: undefined,
                        metError: undefined,
                        checkUpdateProgress: true
                      });
                      toggleDialog('trackOff', {
                        ...trackOffDialog,
                        businessVol: _.get(client, 'trackingId.businessVol', null)
                      });
                    }}
                  >
                    {intl.formatMessage({ id: 'NO' })}
                  </Button>
                </div>
              </div>
              {newBizError && (
                <div className="text-right error_field" style={{ width: '75%' }}>
                  {intl.formatMessage({ id: newBizError })}
                </div>
              )}
              {newBiz === 'Yes' && (
                <div className="caseclose-form">
                  <div className="form-group">
                    <div className="col-md-5 col-xs-5 text-right caseclosing_padding">
                      <label className="control-label">{intl.formatMessage({ id: 'Closed Business' })}</label>
                    </div>
                    <div className="col-md-7 col-xs-7 close-case-date adv_biz_modal_key"
                      style={{
                        padding: '0px 15px 0px 15px'
                      }}>
                      <BusinessVolControl
                        style={{
                          borderBottom: '1px solid gray',
                          height: '25px',
                          color: '#3966f8'
                        }}
                        value={businessVol}
                        onChange={value => {
                          this.setState({ businessVolError: undefined });
                          toggleDialog('trackOff', { ...trackOffDialog, businessVol: value });
                        }}
                      />
                    </div>
                  </div>
                  {businessVolError && (
                    <div className="text-right error_field" style={{ width: '75%' }}>
                      {intl.formatMessage({ id: businessVolError })}
                    </div>
                  )}
                </div>
              )}
              {newBiz === 'Yes' && (
                <div className="caseclose-form">
                  <div className="form-group">
                    <div className="col-md-5 col-xs-5 text-right caseclosing_padding">
                      <label className="control-label">{intl.formatMessage({ id: 'AddToBiz' })}</label>
                    </div>
                    <div className="col-md-7 col-xs-7 close-case-date">
                      <div className="double-check-div">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkUpdateProgress"
                              value="checkUpdateProgress"
                              checked={checkUpdateProgress}
                              onChange={() => this.setState({ checkUpdateProgress: !checkUpdateProgress })}
                              color="primary"
                            />
                          }
                          className="double-check-label"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions className="noflex">
            <Button onClick={this.delete} className="adrider__btn" color="primary" disabled={loading}>
              {intl.formatMessage({ id: 'Delete' })}
            </Button>
            <Button className="cancel__right" onClick={this.submit} color="primary" autoFocus disabled={loading}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
            <Button className="cancel__right" onClick={this.close} color="primary" disabled={loading}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
          </DialogActions>
        </Dialog>

        {/*Tracking Delete Dialog*/}
        <Dialog
          open={deleteDialog}
          onClose={this.closeDeleteDialog}
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle>{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'caseCloseSureDel' })}</DialogContent>
          <DialogActions>
            <Button onClick={this.closeDeleteDialog} color="primary" disabled={loading}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={this.deleteTracking.bind(this)} color="primary" autoFocus disabled={loading}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        {/*Tracking Off Warning Dialog*/}
        <Dialog
          open={warningDialog}
          onClose={this.closeWarningDialog}
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle>{intl.formatMessage({ id: 'Delete User' })}</DialogTitle>
          <DialogContent>{intl.formatMessage({ id: 'Tracking_Short_Duration' })}</DialogContent>
          <DialogActions>
            <Button onClick={this.closeWarningDialog} color="primary" disabled={loading}>
              {intl.formatMessage({ id: 'Cancel' })}
            </Button>
            <Button onClick={this.disableTracking} color="primary" autoFocus disabled={loading}>
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const container = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    trackOffDialog: state.control.trackOffDialog,
    enableTrackingPhase: state.client.enableTrackingPhase,
    enableTrackingMessage: state.client.message,
    adviserBusiness: state.adviserDashboard.adviserBusiness
  }),
  // Map actions to props
  {
    toggleDialog,
    enableTracking,
    getClientDetails,
    updateFactSheet,
    updateAdviserBusiness
  },
  // mergeProps
  null,
  // options
  {}
)(withRouter(TrackOffDialog));

export default injectIntl(container);
