import React, { PureComponent } from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { getRegion } from '../../../../utils/region';
import Switch from '@material-ui/core/Switch';
import { toast } from 'react-toastify';
import FillInStartDateModal from '../FillInStartDateModal/FillInStartDateModal';
import '../styles.scss';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import MoreInfoTrigger from '../../../../components/MoreInfoDialog/MoreInfoTrigger/MoreInfoTrigger';
import { getClientConnection } from '../../../../components/ClientEntry/utils';
import IconToast from '../../../../components/NewToast';
import TodayIcon from '@material-ui/icons/Today';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { jsonParse } from '../../../../utils/json';
import { formatPaymentData } from '../../utils';
import { validatePaymentData } from '../../validators';
import { DEFAULT_PAYMENT_DATA } from '../../constants';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import SelectCurrencyDialog from './SelectCurrencyDialog/SelectCurrencyDialog';

const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];
const subCategoryDeatils = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' },
  { id: '5b9a5bfee624cdb543a476d1', title: 'Maid' },
  { id: '5b9a5bfee624cdb543a476d2', title: 'Accident' },
  { id: '5baf2af899d85c2d38c8f00f', title: 'Saving' },
  { id: '5c18ebd120ca05b7e2b32565', title: 'Provident Fund' },
  { id: '5c41b85a83230016450b2214', title: 'Mortgage' }
];

class PaymentView extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...DEFAULT_PAYMENT_DATA,

      err: {},
      Yes: 'No',
      No: 'No',
      NoIdea: 'No',
      keyArray: [],
      Manual: 'No',
      Bank: 'No',
      CreditCard: 'No',
      payArray: [],
      inactiveUserPremiumReminderModal: false,
      fillInStartDateModalOpen: false
    };
    this.submitForm = this.submitForm.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handlePremiumStatus = this.handlePremiumStatus.bind(this);
    this.handlePaymentStatus = this.handlePaymentStatus.bind(this);

    this.editPolicyDetails = jsonParse(sessionStorage.getItem('editPolicyDetails'));
    this.editPolicy = jsonParse(sessionStorage.getItem('editPolicy'));
  }

  UNSAFE_componentWillMount() {
    const { userDetails } = this.props;
    var paymentData = null;
    var policyStartDate = null;
    var previousPolicy = '';
    var editPayment = JSON.parse(localStorage.getItem('editPayment'));
    var addRider = JSON.parse(localStorage.getItem('addRider'));

    const subCategoryType = JSON.parse(localStorage.getItem('subCategory')) || {};
    if (subCategoryType.title === 'Provident Fund') {
      this.setState({ currency: 'HKD' });
    }

    if (this.editPolicyDetails) {
      paymentData = this.editPolicyDetails;
    } else {
      paymentData = JSON.parse(localStorage.getItem('payment'));
    }
    if (editPayment === 'true') {
      paymentData = JSON.parse(localStorage.getItem('payment'));
    }
    if (addRider === true) {
      previousPolicy = JSON.parse(localStorage.getItem('previousPolicyData'));
    }
    //saving the policy data in there respective fields
    if (paymentData != null) {
      if (paymentData.policyStartDate != null) {
        policyStartDate = moment.parseZone(paymentData.policyStartDate);
      }
      this.setState({
        currency: paymentData.currency,
        lifeCoverage: paymentData.lifeCoverage ? parseInt(paymentData.lifeCoverage) : 0,
        premium: paymentData.premium ? paymentData.premium : 0,
        naPremium: paymentData.naPremium || false,
        sumInsured: paymentData.sumInsured ? paymentData.sumInsured : 0,
        frequency: paymentData.frequency,
        levelPremium: paymentData.levelPremium,
        taxDeductible: paymentData.taxDeductible,
        paymentMethod: paymentData.paymentMethod,
        policyStartDate: policyStartDate,
        lumpSumAmount: paymentData.lumpSumAmount,
        remindMeDay: paymentData.remindMeDay,
        reminderActive:
          paymentData && paymentData.frequency && paymentData.frequency !== '1' ? paymentData.reminderActive : false,
        policyDueDate: paymentData.frequency !== '1' ? paymentData.policyDueDate : null,
        reminderDate: paymentData.frequency !== '1' ? paymentData.reminderDate : null,
        adviserReminderActive:
          userDetails.userType === 'Adviser' &&
            paymentData &&
            paymentData.frequency &&
            paymentData.frequency !== '1' &&
            paymentData.adviserReminderActive !== undefined &&
            paymentData.adviserReminderActive !== null &&
            paymentData.adviserReminderActive !== ''
            ? paymentData.adviserReminderActive
            : false
      });
      if (paymentData.levelPremium === 'Yes') {
        this.setState({ Yes: 'Yes' });
      }
      if (paymentData.levelPremium === 'No') {
        this.setState({ No: 'Yes' });
      }
      if (paymentData.levelPremium === 'No Idea') {
        this.setState({ NoIdea: 'Yes' });
      }
      if (paymentData.paymentMethod === 'Bank') {
        this.setState({ Bank: 'Yes' });
      }
      if (paymentData.paymentMethod === 'Credit Card') {
        this.setState({ CreditCard: 'Yes' });
      }
      if (paymentData.paymentMethod === 'Manual') {
        this.setState({ Manual: 'Yes' });
      }
    }
    if (previousPolicy != null && paymentData === null && previousPolicy !== '' && previousPolicy !== undefined) {
      if (previousPolicy.policyStartDate != null) {
        policyStartDate = moment.parseZone(previousPolicy.policyStartDate);
      }
      this.setState({
        currency: _.get(previousPolicy, 'currency', ''),
        frequency: _.get(previousPolicy, 'frequency', ''),
        paymentMethod: _.get(previousPolicy, 'paymentMethod', ''),
        policyStartDate: policyStartDate,
        lumpSumAmount: _.get(previousPolicy, 'lumpSumAmount', '')
      });
      if (_.get(previousPolicy, 'paymentMethod') === 'Bank') {
        this.setState({ Bank: 'Yes' });
      }
      if (_.get(previousPolicy, 'paymentMethod') === 'Credit Card') {
        this.setState({ CreditCard: 'Yes' });
      }
      if (_.get(previousPolicy, 'paymentMethod') === 'Manual') {
        this.setState({ Manual: 'Yes' });
      }
      if (
        previousPolicy.policyDueDate &&
        previousPolicy.policyDueDate !== '' &&
        previousPolicy.reminderActive === true &&
        previousPolicy.frequency &&
        previousPolicy.frequency !== '1'
      ) {
        this.setState({ policyDueDate: _.get(previousPolicy, 'policyDueDate', null) });
      }
      if (
        previousPolicy.reminderDate &&
        previousPolicy.reminderDate !== '' &&
        previousPolicy.reminderActive === true &&
        previousPolicy.frequency &&
        previousPolicy.frequency !== '1'
      ) {
        this.setState({ reminderDate: _.get(previousPolicy, 'reminderDate', null) });
      }
      if (previousPolicy.frequency && previousPolicy.frequency !== '1') {
        this.setState({ reminderActive: _.get(previousPolicy, 'reminderActive', false) });
      }
      if (userDetails.userType === 'Adviser' && previousPolicy.adviserReminderActive === true) {
        if (
          previousPolicy.reminderDate &&
          previousPolicy.reminderDate !== '' &&
          previousPolicy.adviserReminderActive === true &&
          previousPolicy.frequency &&
          previousPolicy.frequency !== '1'
        ) {
          this.setState({ reminderDate: _.get(previousPolicy, 'reminderDate', null) });
        }
        if (
          previousPolicy.policyDueDate &&
          previousPolicy.policyDueDate !== '' &&
          previousPolicy.adviserReminderActive === true &&
          previousPolicy.frequency &&
          previousPolicy.frequency !== '1'
        ) {
          this.setState({ policyDueDate: _.get(previousPolicy, 'policyDueDate', null) });
        }
        if (previousPolicy.frequency && previousPolicy.frequency !== '1') {
          this.setState({ adviserReminderActive: _.get(previousPolicy, 'adviserReminderActive', false) });
        }
      }
    }
  }

  handleChange(e) {
    const err = {};
    if (e.target.name === 'currency') {
      delete this.state.err.currency;
    }

    if (e.target.name === 'sumInsured') {
      delete this.state.err.sumInsured;
    }

    if (e.target.name === 'lifeCoverage') {
      if (isNaN(e.target.value)) {
        err.lifeCoverage = 'Life Coverage field must be a number.';
        this.setState({ err });
        return;
      }
    }
    if (e.target.name === 'sumInsured') {
      if (isNaN(e.target.value)) {
        err.sumInsured = 'C.I. Coverage Amount field must be a number.';
        this.setState({ err });
        return;
      }
    }
    if (e.target.name === 'premium') {
      if (isNaN(e.target.value)) {
        err.premium = 'Premium field must be a number.';
        this.setState({ err });
        return;
      }
    }
    if (e.target.name === 'currency') {
      if (this.state[e.target.name] === e.target.value) {
        e.target.value = '';
      }
    }

    if (e.target.name === 'lumpSumAmount') {
      if (isNaN(e.target.value)) {
        err.lumpSumAmount = 'Lumpsum amount field must be a number.';
        this.setState({ err });
        return;
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  //Hanlde the date of policy
  handleDateChange(date) {
    const { frequency } = this.state;
    this.setState({
      policyStartDate: date,
      ...this.getFormattedReminderState(date, frequency)
    });
  }

  handleNextPaymentDateChange(date) {
    let policyDueDate = date !== null && date !== undefined && date !== '' ? moment(date).format('YYYY-MM-DD') : null;

    //Get the reminder date by policy due date according to the remind me day , here subract the remind me day in policy due date
    if (policyDueDate !== null) {
      let reminderDate = moment(policyDueDate)
        .subtract(21, 'days')
        .format('YYYY-MM-DD');
      let remindMeDay = 21;
      this.setState({ remindMeDay: remindMeDay, policyDueDate: policyDueDate, reminderDate: reminderDate });
    } else {
      this.setState({
        policyDueDate: policyDueDate,
        reminderDate: '',
        reminderActive: false,
        adviserReminderActive: false
      });
    }
  }

  autoCalculateNextPaymentDate = event => {
    event.stopPropagation();
    const { policyStartDate, frequency } = this.state;
    const state = this.getFormattedReminderState(policyStartDate, frequency, true);
    this.setState({ policyDueDate: state.policyDueDate });
  };

  isClientReminderToggleable() {
    const { userDetails, viewingClient } = this.props;
    if (userDetails.userType === 'Adviser') {
      if (!viewingClient || !getClientConnection(viewingClient)) {
        return false;
      }
    }
    return true;
  }

  //when user enable/disable the reminder
  onToggle() {
    let { policyDueDate, reminderActive, frequency } = this.state;
    const { intl } = this.props;
    //if policy due date is not selected
    let status = true;

    if (!this.isClientReminderToggleable()) {
      this.setState({ inactiveUserPremiumReminderModal: true });
      return;
    }
    //if policy due date is not selected
    if (frequency === '1') {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Reminder is monthly' })), { className: 'new-toast' });
      return;
    }

    if (!policyDueDate && frequency !== '1') {
      this.setState({ status: false });
      toast.info(IconToast('error', intl.formatMessage({ id: 'Please Set the Next Payment Date' })), {
        className: 'new-toast'
      });
      return;
    }
    let currentDate = moment(new Date());
    let date2 = moment(policyDueDate);
    //when payment due date is selected past of current day
    if (date2.isBefore(currentDate)) {
      this.setState({ status: false });
      toast.info(IconToast('error', intl.formatMessage({ id: 'Next Payment Date need to be in the future' })), {
        className: 'new-toast'
      });
      return;
    }
    //Get the reminder date by policy due date according to the remind me day , here subract the remind me day in policy due date
    if (status) {
      let reminderDate = moment
        .parseZone(policyDueDate)
        .subtract(21, 'days')
        .format('YYYY-MM-DD');
      let remindMeDay = 21;
      this.setState({
        reminderActive: !reminderActive,
        reminderDate: reminderDate,
        remindMeDay: remindMeDay,
        policyDueDate: policyDueDate
      });
    }
  }

  //When adviser on the switch
  onAdviserToggle() {
    let { policyDueDate, frequency } = this.state;
    const { intl } = this.props;

    //if policy due date is not selected
    if (frequency === '1') {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Reminder is monthly' })), { className: 'new-toast' });
      return;
    }
    if (!policyDueDate && frequency !== '1') {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Please Set the Next Payment Date' })), {
        className: 'new-toast'
      });
      return;
    }
    let currentDate = moment(new Date());
    let date2 = moment(policyDueDate);

    //when payment due date is selected past of current day
    if (date2.isBefore(currentDate)) {
      toast.info(IconToast('error', intl.formatMessage({ id: 'Next Payment Date need to be in the future' })), {
        className: 'new-toast'
      });
      return;
    }

    const { adviserReminderActive } = this.state;
    this.setState({ adviserReminderActive: !adviserReminderActive });
  }

  //Submit the data of payment
  submitForm() {
    let categoryId = '';
    let subCategoryId = '';
    let categoryType = {};
    let subCategoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;
      categoryDetails.map(function (data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function (data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }

    const err = validatePaymentData(this.state, categoryType, subCategoryType);
    this.setState({ err });

    if (!Object.keys(err).length) {
      if (!this.state.policyStartDate && subCategoryType.title !== 'Provident Fund') {
        this.setState({ fillInStartDateModalOpen: true });
      } else {
        this.continueNext();
      }
    }
  }

  continueNext() {
    const { userDetails } = this.props;
    formatPaymentData(this.state, userDetails);
    //Send to the next page
    this.props.handleNextPage();
  }

  handlePrevious() {
    const { userDetails } = this.props;
    formatPaymentData(this.state, userDetails);
    this.props.handlePreviousPage();
  }

  //Handle the status of level Premium
  handlePremiumStatus(key) {
    delete this.state.err.levelPremium;
    const state = this.state.keyArray;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({
          Yes: 'Yes',
          No: 'No',
          NoIdea: 'No',
          levelPremium: 'Yes'
        });
      }
      if (key === 1) {
        this.setState({
          Yes: 'No',
          No: 'Yes',
          NoIdea: 'No',
          levelPremium: 'No'
        });
      }
      if (key === 2) {
        this.setState({
          Yes: 'No',
          No: 'No',
          NoIdea: 'Yes',
          levelPremium: 'No Idea'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Yes !== 'No') {
          this.setState({ Yes: 'No', levelPremium: '' });
        } else {
          this.setState({
            Yes: 'Yes',
            No: 'No',
            NoIdea: 'No',
            levelPremium: 'Yes'
          });
        }
      }
      if (key === 1) {
        if (this.state.No !== 'No') {
          this.setState({ No: 'No', levelPremium: '' });
        } else {
          this.setState({
            Yes: 'No',
            No: 'Yes',
            NoIdea: 'No',
            levelPremium: 'No'
          });
        }
      }
      if (key === 2) {
        if (this.state.NoIdea !== 'No') {
          this.setState({ NoIdea: 'No', levelPremium: '' });
        } else {
          this.setState({
            Yes: 'No',
            No: 'No',
            NoIdea: 'Yes',
            levelPremium: 'No Idea'
          });
        }
      }
    }
  }

  //Handle the status of payment method
  handlePaymentStatus(key) {
    const state = this.state.payArray;
    delete this.state.err.paymentMethod;
    if (state.indexOf(key) === -1) {
      this.setState({ payArray: [...this.state.payArray, key] });
      if (key === 0) {
        this.setState({
          Bank: 'Yes',
          CreditCard: 'No',
          Manual: 'No',
          paymentMethod: 'Bank'
        });
      }
      if (key === 1) {
        this.setState({
          Bank: 'No',
          CreditCard: 'Yes',
          Manual: 'No',
          paymentMethod: 'Credit Card'
        });
      }
      if (key === 2) {
        this.setState({
          Bank: 'No',
          CreditCard: 'No',
          Manual: 'Yes',
          paymentMethod: 'Manual'
        });
      }
    } else {
      if (key === 0) {
        if (this.state.Bank !== 'No') {
          this.setState({ Bank: 'No', paymentMethod: '' });
        } else {
          this.setState({
            Bank: 'Yes',
            CreditCard: 'No',
            Manual: 'No',
            paymentMethod: 'Bank'
          });
        }
      }
      if (key === 1) {
        if (this.state.CreditCard !== 'No') {
          this.setState({ CreditCard: 'No', paymentMethod: '' });
        } else {
          this.setState({
            Bank: 'No',
            CreditCard: 'Yes',
            Manual: 'No',
            paymentMethod: 'Credit Card'
          });
        }
      }
      if (key === 2) {
        if (this.state.Manual !== 'No') {
          this.setState({ Manual: 'No', paymentMethod: '' });
        } else {
          this.setState({
            Bank: 'No',
            CreditCard: 'No',
            Manual: 'Yes',
            paymentMethod: 'Manual'
          });
        }
      }
    }
  }

  handleFrequencyChange(value) {
    const { policyStartDate, frequency, err } = this.state;

    this.setState({
      frequency: frequency === value ? '' : value,
      err: _.omit(err, ['frequency']),
      ...this.getFormattedReminderState(policyStartDate, value)
    });
  }

  getFormattedReminderState(policyStartDate, frequency, byPassEditRestriction) {
    const { userDetails } = this.props;

    // only auto fill in reminder state when it is creating a new policy
    if (byPassEditRestriction || !this.editPolicyDetails) {
      let policyDueDateMoment = moment(policyStartDate).add(frequency, 'months'),
        reminderDateMoment = policyDueDateMoment.clone().subtract(21, 'days'),
        nowMoment = moment();

      if (frequency !== '') {
        if (policyStartDate && frequency !== '1') {
          while (policyDueDateMoment.isBefore(nowMoment)) {
            policyDueDateMoment.add(frequency, 'months');
          }

          // Find next reminder date that is after today
          while (reminderDateMoment.isBefore(nowMoment)) {
            reminderDateMoment.add(frequency, 'months');
          }

          return {
            policyDueDate: policyDueDateMoment.format('YYYY-MM-DD'),
            reminderDate: reminderDateMoment.format('YYYY-MM-DD'),
            reminderActive: this.isClientReminderToggleable(),
            adviserReminderActive: userDetails && userDetails.userType === 'Adviser' ? true : undefined
          };
        } else {
          return {
            policyDueDate: '',
            reminderDate: '',
            reminderActive: false,
            adviserReminderActive: userDetails && userDetails.userType === 'Adviser' ? false : undefined
          };
        }
      }
    }

    return {};
  }

  copyCiToLife() {
    const { sumInsured } = this.state;
    this.setState({ lifeCoverage: sumInsured });
  }

  checkNAPremium = event => {
    const stateObj = { naPremium: event.target.checked };
    if (event.target.checked) {
      stateObj.premium = 0;
      stateObj.frequency = '12';
    }
    this.setState(stateObj);
  };

  selectTaxDeductibleYes = () => this.setState({ taxDeductible: this.state.taxDeductible === 'Yes' ? '' : 'Yes' });
  selectTaxDeductibleNo = () => this.setState({ taxDeductible: this.state.taxDeductible === 'No' ? '' : 'No' });
  selectTaxDeductibleNoIdea = () =>
    this.setState({ taxDeductible: this.state.taxDeductible === 'No Idea' ? '' : 'No Idea' });

  render() {
    const { intl, userDetails } = this.props;
    const { reminderActive, frequency } = this.state;
    let categoryId = '';
    let subCategoryId = '';
    let categoryType = {};
    let subCategoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;
      categoryDetails.map(function (data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function (data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }

    let middleComponent =
      categoryType &&
        subCategoryType &&
        (categoryType.title === 'Personal' || categoryType.title === 'General') &&
        subCategoryType.title !== 'Provident Fund' &&
        subCategoryType.title !== 'Mortgage' ? (
        <div>
          <div className="row">
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label
                  className={this.state.frequency !== '1' ? 'control-label' : 'control-label-disable'}
                  htmlFor="input"
                >
                  {intl.formatMessage({ id: 'Policy Start Date' })}
                </label>
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label
                  className={this.state.frequency !== '1' ? 'control-label' : 'control-label-disable'}
                  htmlFor="input"
                >
                  {intl.formatMessage({ id: 'Next Payment Date' })}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xs-6">
              <div className="form-group datepicker-group date--center">
                <DatePicker
                  name="policyStartDate"
                  maxDate={moment().add(3, 'months')}
                  format="DD-MMM-YYYY"
                  clearable={true}
                  value={
                    this.state.policyStartDate !== null &&
                      this.state.policyStartDate !== undefined &&
                      this.state.policyStartDate !== ''
                      ? moment.parseZone(this.state.policyStartDate)
                      : null
                  }
                  openTo="year"
                  onChange={date => this.handleDateChange(date)}
                  autoOk={true}
                  invalidDateMessage={null}
                  minDateMessage={null}
                  maxDateMessage={null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton style={{ padding: '0 5px 0 0' }}>
                          <TodayIcon color="action" style={{ fontSize: 20 }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="form-group datepicker-group date--center">
                <DatePicker
                  name="policyDueDate"
                  minDate={moment().add(1, 'days')}
                  maxDate={moment().add(3, 'years')}
                  format="DD-MMM-YYYY"
                  clearable={true}
                  minDateMessage=""
                  value={
                    this.state.policyDueDate === null ||
                      this.state.policyDueDate === undefined ||
                      this.state.policyDueDate === ''
                      ? null
                      : moment.parseZone(this.state.policyDueDate)
                  }
                  onChange={date => this.handleNextPaymentDateChange(date)}
                  autoOk={true}
                  disabled={this.state.frequency === '1'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={this.state.frequency === '1' || !this.state.policyStartDate}
                          onClick={this.autoCalculateNextPaymentDate}
                          style={{ minWidth: 36, padding: 0, height: 25 }}
                        >
                          {intl.formatMessage({ id: 'Auto' })}
                        </Button>
                        {/*{this.state.frequency === '1' && (*/}
                        {/*  <IconButton style={{ padding: '0 5px 0 0', marginLeft: '-12px' }}>*/}
                        {/*    <TodayIcon color="disabled" style={{ fontSize: 20 }} />*/}
                        {/*  </IconButton>*/}
                        {/*)}*/}
                        {/*{this.state.frequency !== '1' && (*/}
                        {/*  <IconButton style={{ padding: '0 5px 0 0', marginLeft: '-12px' }}>*/}
                        {/*    <TodayIcon color="action" style={{ fontSize: 20 }} />*/}
                        {/*  </IconButton>*/}
                        {/*)}*/}
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-md-6 col-xs-6">
              <div className="form-group">
                <label
                  className={this.state.frequency !== '1' ? 'control-label' : 'control-label-disable'}
                  htmlFor="input"
                >
                  {intl.formatMessage({ id: 'Policy Start Date' })}
                </label>
              </div>
            </div>
            <div className="col-md-6 col-xs-6">{/*empty, remainderComponent condition not hit*/}</div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xs-6">
              <div className="form-group datepicker-group date--center">
                <DatePicker
                  name="policyStartDate"
                  maxDate={moment().add(3, 'months')}
                  format="DD-MMM-YYYY"
                  clearable={true}
                  value={
                    this.state.policyStartDate !== null &&
                      this.state.policyStartDate !== undefined &&
                      this.state.policyStartDate !== ''
                      ? moment.parseZone(this.state.policyStartDate)
                      : null
                  }
                  openTo="year"
                  onChange={date => this.handleDateChange(date)}
                  autoOk={true}
                  invalidDateMessage={null}
                  minDateMessage={null}
                  maxDateMessage={null}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton style={{ padding: '0 5px 0 0' }}>
                          <TodayIcon color="action" style={{ fontSize: 20 }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-6">{/*empty, remainderComponent condition not hit*/}</div>
          </div>
        </div>
      );

    let remainderComponent =
      categoryType &&
        subCategoryType &&
        (categoryType.title === 'Personal' || categoryType.title === 'General') &&
        subCategoryType.title !== 'Provident Fund' &&
        subCategoryType.title !== 'Mortgage' ? (
        <div className="row">
          <div className="col-md-6 col-xs-7">
            <div className="form-group">
              <label
                className={frequency !== '1' ? 'control-label' : 'control-label-disable'}
                htmlFor="input"
                style={{ letterSpacing: '0px' }}
              >
                {intl.formatMessage({
                  id: 'Premium Reminder'
                })}
              </label>
            </div>
            <MoreInfoTrigger
              titleIntlId="Premium Reminder"
              contentIntlId={`moreInfo_${userDetails.userType === 'Adviser' ? 'adviserReminder' : 'clientReminder'}`}
              className="retirement-saving-more-info top-align"
            />
          </div>
          <div className="col-md-6 col-md-offset-6 col-xs-5 padding_left_zero">
            <div>
              <div
                className="plan__insurer-toggle"
                style={userDetails.userType === 'User' ? { justifyContent: 'flex-end' } : undefined}
              >
                {userDetails.userType === 'Adviser' && (
                  <span
                    style={{
                      color: frequency === '1' ? '#ccc' : ''
                    }}
                  >
                    {intl.formatMessage({ id: 'Client' })}
                  </span>
                )}
                <Switch
                  color="primary"
                  checked={reminderActive}
                  onChange={this.onToggle.bind(this)}
                  className={reminderActive ? 'material-ui-toggle' : ''}
                  style={{
                    marginLeft: '0',
                    display: 'inlineBlock'
                  }}
                />
              </div>
              {userDetails.userType === 'Adviser' && (
                <div>
                  <div className="plan__insurer-toggle">
                    <span
                      style={{
                        color: frequency === '1' ? '#ccc' : ''
                      }}
                    >
                      {intl.formatMessage({ id: 'Adviser' })}
                    </span>
                    <Switch
                      color="primary"
                      checked={this.state.adviserReminderActive}
                      onChange={this.onAdviserToggle.bind(this)}
                      className={this.state.adviserReminderActive ? 'material-ui-toggle' : ''}
                      style={{
                        marginLeft: '0',
                        display: 'inlineBlock'
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null;

    const taxDeductibleField = subCategoryType &&
      (subCategoryType.title === 'Provident Fund' ||
        subCategoryType.title === 'Medical' ||
        subCategoryType.title === 'Saving') && (
        <div className="select-buttons-div">
          <div className="control-orphay source-button source-button-edit">
            {intl.formatMessage({ id: 'tax-deductible' })}
          </div>
          <div>
            <Button
              variant={this.state.taxDeductible === 'Yes' ? 'contained' : 'outlined'}
              className={`plan-type-select-button source-button-responsive${this.state.taxDeductible === 'Yes' ? ' selected' : ''
                }`}
              onClick={this.selectTaxDeductibleYes}
            >
              {intl.formatMessage({ id: 'Yes' })}
            </Button>

            <Button
              variant={this.state.taxDeductible === 'No' ? 'contained' : 'outlined'}
              className={`plan-type-select-button source-button-responsive${this.state.taxDeductible === 'No' ? ' selected' : ''
                }`}
              onClick={this.selectTaxDeductibleNo}
            >
              {intl.formatMessage({ id: 'No' })}
            </Button>

            <Button
              variant={this.state.taxDeductible === 'No Idea' ? 'contained' : 'outlined'}
              className={`plan-type-select-button source-button-responsive${this.state.taxDeductible === 'No Idea' ? ' selected' : ''
                }`}
              onClick={this.selectTaxDeductibleNoIdea}
            >
              {intl.formatMessage({ id: 'No Idea' })}
            </Button>
          </div>
          <i className="bar" />
        </div>
      );

    const currencyField = (
      <div className="select-buttons-div form-group">
        <div className={'control-orphay source-button'} style={{ color: '#333' }}>
          {intl.formatMessage({ id: 'Currency' })}*
        </div>
        <div style={{ paddingBottom: 5 }}>
          <Button
            variant={this.state.currency === 'USD' ? 'contained' : 'outlined'}
            className={
              'plan-type-select-button  source-button-responsive' +
              (this.state.currency === 'USD' ? ' selected  source-button-responsive' : '')
            }
            key={0}
            onClick={() =>
              this.handleChange({
                target: {
                  name: 'currency',
                  value: 'USD'
                }
              })
            }
          >
            {intl.formatMessage({ id: 'USD' })}
          </Button>

          <Button
            variant={this.state.currency === 'HKD' ? 'contained' : 'outlined'}
            className={
              'plan-type-select-button  source-button-responsive' +
              (this.state.currency === 'HKD' ? ' selected  source-button-responsive' : '')
            }
            key={1}
            onClick={() =>
              this.handleChange({
                target: {
                  name: 'currency',
                  value: 'HKD'
                }
              })
            }
          >
            {intl.formatMessage({ id: 'HKD' })}
          </Button>

          {getRegion() === 'tw' ? (
            <Button
              variant={this.state.currency === 'TWD' ? 'contained' : 'outlined'}
              className={
                'plan-type-select-button source-button-responsive' +
                (this.state.currency === 'TWD' ? ' selected source-button-responsive' : '')
              }
              key={3}
              onClick={() =>
                this.handleChange({
                  target: { name: 'currency', value: 'TWD' }
                })
              }
            >
              {intl.formatMessage({ id: 'TWD' })}
            </Button>
          ) : (
            <Button
              variant={this.state.currency === 'CNY' ? 'contained' : 'outlined'}
              className={
                'plan-type-select-button  source-button-responsive' +
                (this.state.currency === 'CNY' ? ' selected  source-button-responsive' : '')
              }
              key={2}
              onClick={() =>
                this.handleChange({
                  target: {
                    name: 'currency',
                    value: 'CNY'
                  }
                })
              }
            >
              {intl.formatMessage({ id: 'CNY' })}
            </Button>
          )}

          <SelectCurrencyDialog
            open={this.state.selectCurrencyDialogOpen}
            currency={this.state.currency}
            exclude={['USD', 'HKD', 'CNY', 'TWD']}
            onClose={() => this.setState({ selectCurrencyDialogOpen: false })}
            onSubmit={currency => this.handleChange({ target: { name: 'currency', value: currency } })}
          />

          <Button
            variant={this.state.currency && !['USD', 'HKD', 'CNY', 'TWD'].includes(this.state.currency) ? 'contained' : 'outlined'}
            className={
              'plan-type-select-button  source-button-responsive' +
              (this.state.currency && !['USD', 'HKD', 'CNY', 'TWD'].includes(this.state.currency) ? ' selected  source-button-responsive' : '')
            }
            onClick={() => this.setState({ selectCurrencyDialogOpen: true })}
          >
            {this.state.currency && !['USD', 'HKD', 'CNY', 'TWD'].includes(this.state.currency)
              ? intl.formatMessage({ id: this.state.currency })
              : intl.formatMessage({ id: 'Other' })}
          </Button>
        </div>
        {this.state.err.currency ? (
          <span className="error_field">
            {intl.formatMessage({
              id: this.state.err.currency
            })}
          </span>
        ) : (
          ''
        )}
      </div>
    );

    return (
      <div>
        <TopNavigationPortal>
          <span className="back_arrow" onClick={this.handlePrevious}>
            <ArrowBackIcon />
          </span>
        </TopNavigationPortal>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  {categoryType && categoryType.title === 'Personal' ? (
                    <form id="msform" noValidate={true}>
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                        <li />
                        <li />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Payment' })}</h2>

                        {currencyField}

                        {subCategoryType && subCategoryType.title === 'Critical Illness' ? (
                          <div className="row">
                            <div className="col-md-12 col-xs-12">
                              <div className="form-group">
                                <NumberFormat
                                  //type="number"
                                  //name="sumInsured"
                                  thousandSeparator={true}
                                  value={this.state.sumInsured}
                                  onValueChange={values => {
                                    const { value } = values;
                                    delete this.state.err.sumInsured;
                                    this.setState({ sumInsured: value });
                                  }}
                                  required="required"
                                  inputMode="decimal"
                                  pattern="[0-9]*"
                                />
                                <label className="control-label" htmlFor="input" style={{ top: '-12px' }}>
                                  {intl.formatMessage({
                                    id: 'C.I. Coverage Amount'
                                  })}
                                  *
                                </label>
                                <i className="bar" />
                                {this.state.err.sumInsured ? (
                                  <span className="error_field">
                                    {intl.formatMessage({
                                      id: this.state.err.sumInsured
                                    })}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {subCategoryType && subCategoryType.title === 'Medical' && <div />}
                        <div className="form-group">
                          <NumberFormat
                            //type="number"
                            //name="lifeCoverage"
                            value={this.state.lifeCoverage}
                            thousandSeparator={true}
                            onValueChange={values => {
                              const { value } = values;
                              delete this.state.err.lifeCoverage;
                              this.setState({ lifeCoverage: value });
                            }}
                            required="required"
                            inputMode="decimal"
                            pattern="[0-9]*"
                          />
                          <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                            {intl.formatMessage({ id: 'Life Coverage Amount' })}*
                          </label>
                          <i className="bar" />
                          {this.state.err.lifeCoverage ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.lifeCoverage
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                          {subCategoryType && subCategoryType.title === 'Critical Illness' && this.state.sumInsured && (
                            <Button
                              className="action-change-button"
                              variant="contained"
                              color="primary"
                              onClick={this.copyCiToLife.bind(this)}
                            >
                              {intl.formatMessage({ id: 'Same' })}
                            </Button>
                          )}
                        </div>

                        <div className="form-group">
                          <NumberFormat
                            //type="number"
                            //name="premium"
                            value={this.state.premium}
                            thousandSeparator={true}
                            onValueChange={values => {
                              const { value } = values;
                              delete this.state.err.premium;
                              this.setState({ premium: value });
                            }}
                            required="required"
                            inputMode="decimal"
                            pattern="[0-9]*"
                            disabled={this.state.naPremium}
                          />
                          <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                            {intl.formatMessage({ id: 'Premium' })}*
                          </label>
                          <i className="bar" />
                          {this.state.err.premium ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.premium
                              })}
                            </span>
                          ) : (
                            ''
                          )}

                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={this.state.naPremium}
                                  onChange={this.checkNAPremium}
                                />
                              }
                              label={intl.formatMessage({ id: 'premium-na' })}
                            />
                          </div>

                          <div className="premium__btns">
                            <Button
                              variant={this.state.frequency === '1' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '1' ? ' selected' : '')
                              }
                              //key={0}
                              onClick={this.handleFrequencyChange.bind(this, '1')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Month' })}
                            </Button>

                            <Button
                              variant={this.state.frequency === '3' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '3' ? ' selected' : '')
                              }
                              //key={1}
                              onClick={this.handleFrequencyChange.bind(this, '3')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Quarter' })}
                            </Button>

                            <Button
                              variant={this.state.frequency === '6' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '6' ? ' selected' : '')
                              }
                              //key={2}
                              onClick={this.handleFrequencyChange.bind(this, '6')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Half Year' })}
                            </Button>
                            <Button
                              variant={this.state.frequency === '12' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '12' ? ' selected' : '')
                              }
                              //key={2}
                              onClick={this.handleFrequencyChange.bind(this, '12')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Year' })}
                            </Button>
                            {this.state.err.frequency ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.frequency
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        {subCategoryType && (subCategoryType.title === 'Life' || subCategoryType.title === 'Other') && (
                          <div className="form-group">
                            <NumberFormat
                              //type="number"
                              //name="lumpSumAmount"
                              thousandSeparator={true}
                              value={this.state.lumpSumAmount}
                              onValueChange={values => {
                                const { value } = values;
                                this.setState({ lumpSumAmount: value });
                              }}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input">
                              {intl.formatMessage({ id: 'LumpSum amount' })}
                            </label>
                            <i className="bar" />
                          </div>
                        )}

                        <div className="select-buttons-div">
                          <div className="control-orphay source-button source-button-edit">
                            {`${intl.formatMessage({ id: 'Level Premium' })}*`}
                          </div>
                          <div>
                            {this.state.No === 'Yes' && (
                              <img alt="" className="not_level_icon" src="img/not-level.png" />
                            )}
                            <Button
                              variant={this.state.Yes === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Yes === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive'
                                  : 'plan-type-select-button source-button-responsive'
                              }
                              key={0}
                              onClick={() => this.handlePremiumStatus(0)}
                            >
                              {intl.formatMessage({ id: 'Yes' })}
                            </Button>

                            <Button
                              variant={this.state.No === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.No === 'Yes'
                                  ? 'plan-type-select-button selected source-button-responsive'
                                  : 'plan-type-select-button source-button-responsive'
                              }
                              key={1}
                              onClick={() => this.handlePremiumStatus(1)}
                            >
                              {intl.formatMessage({ id: 'No' })}
                            </Button>

                            <Button
                              variant={this.state.NoIdea === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.NoIdea === 'Yes'
                                  ? 'plan-type-select-button selected'
                                  : 'plan-type-select-button'
                              }
                              key={2}
                              onClick={() => this.handlePremiumStatus(2)}
                            >
                              {intl.formatMessage({ id: 'No Idea' })}
                            </Button>
                          </div>
                          <i className="bar" />
                          {this.state.err.levelPremium ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.levelPremium
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>

                        {taxDeductibleField}

                        <div className="select-buttons-div">
                          <div className="control-orphay">{intl.formatMessage({ id: 'Payment Method' })}</div>
                          <div>
                            <Button
                              variant={this.state.Bank === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Bank === 'Yes'
                                  ? 'payment-type-select-button selected'
                                  : 'payment-type-select-button'
                              }
                              key={0}
                              onClick={() => this.handlePaymentStatus(0)}
                            >
                              {intl.formatMessage({ id: 'AutoPay by Bank' })}
                            </Button>

                            <Button
                              variant={this.state.CreditCard === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.CreditCard === 'Yes'
                                  ? 'payment-type-select-button selected'
                                  : 'payment-type-select-button'
                              }
                              key={1}
                              onClick={() => this.handlePaymentStatus(1)}
                            >
                              {intl.formatMessage({
                                id: 'AutoPay by Credit Card'
                              })}
                            </Button>

                            <Button
                              variant={this.state.Manual === 'Yes' ? 'contained' : 'outlined'}
                              className={
                                this.state.Manual === 'Yes'
                                  ? 'payment-type-select-button selected'
                                  : 'payment-type-select-button'
                              }
                              key={2}
                              onClick={() => this.handlePaymentStatus(2)}
                            >
                              {intl.formatMessage({ id: 'Manual' })}
                            </Button>
                          </div>
                          <i className="bar" />
                          {this.state.err.paymentMethod ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.paymentMethod
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="row"></div>

                        {middleComponent}
                        {remainderComponent}

                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}
                  {categoryType && categoryType.title === 'Company' ? (
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                        <li />
                        <li />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center">{intl.formatMessage({ id: 'Payment' })}</h2>
                        {currencyField}
                        {subCategoryType && subCategoryType.title === 'Critical Illness' ? (
                          <div className="form-group">
                            <NumberFormat
                              //type="number"
                              //name="sumInsured"
                              thousandSeparator={true}
                              value={this.state.sumInsured}
                              onValueChange={values => {
                                const { value } = values;
                                this.setState({ sumInsured: value });
                              }}
                              required="required"
                              inputMode="decimal"
                              pattern="[0-9]*"
                            />
                            <label className="control-label" htmlFor="input" style={{ top: '-12px' }}>
                              {intl.formatMessage({
                                id: 'C.I. Coverage Amount'
                              })}
                              *
                            </label>
                            <i className="bar" />
                            {this.state.err.sumInsured ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.sumInsured
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="form-group">
                          <NumberFormat
                            //type="number"
                            //name="lifeCoverage"
                            value={this.state.lifeCoverage}
                            thousandSeparator={true}
                            onValueChange={values => {
                              const { value } = values;
                              delete this.state.err.lifeCoverage;
                              this.setState({ lifeCoverage: value });
                            }}
                            required="required"
                            inputMode="decimal"
                            pattern="[0-9]*"
                          />
                          <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                            {intl.formatMessage({ id: 'Life Coverage Amount' })}*
                          </label>
                          <i className="bar" />
                          {this.state.err.lifeCoverage ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.lifeCoverage
                              })}
                            </span>
                          ) : (
                            ''
                          )}
                          {subCategoryType && subCategoryType.title === 'Critical Illness' && this.state.sumInsured && (
                            <input
                              type="button"
                              value={intl.formatMessage({
                                id: 'Same'
                              })}
                              className="action-button action-change-button"
                              onClick={this.copyCiToLife.bind(this)}
                            />
                          )}
                        </div>

                        <div className="form-group">
                          <NumberFormat
                            //type="number"
                            //name="premium"
                            value={this.state.premium}
                            thousandSeparator={true}
                            onValueChange={values => {
                              const { value } = values;
                              delete this.state.err.premium;
                              this.setState({ premium: value });
                            }}
                            required="required"
                            inputMode="decimal"
                            pattern="[0-9]*"
                            disabled={this.state.naPremium}
                          />
                          <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                            {intl.formatMessage({ id: 'Premium' })}*
                          </label>
                          <i className="bar" />
                          {this.state.err.premium ? (
                            <span className="error_field">
                              {intl.formatMessage({
                                id: this.state.err.premium
                              })}
                            </span>
                          ) : (
                            ''
                          )}

                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={this.state.naPremium}
                                  onChange={this.checkNAPremium}
                                />
                              }
                              label={intl.formatMessage({ id: 'premium-na' })}
                            />
                          </div>

                          <div className="premium__btns">
                            <Button
                              variant={this.state.frequency === '1' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '1' ? ' selected' : '')
                              }
                              //key={0}
                              onClick={this.handleFrequencyChange.bind(this, '1')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Month' })}
                            </Button>

                            <Button
                              variant={this.state.frequency === '3' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '3' ? ' selected' : '')
                              }
                              //key={1}
                              onClick={this.handleFrequencyChange.bind(this, '3')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Quarter' })}
                            </Button>

                            <Button
                              variant={this.state.frequency === '6' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '6' ? ' selected' : '')
                              }
                              //key={2}
                              onClick={this.handleFrequencyChange.bind(this, '6')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Half Year' })}
                            </Button>
                            <Button
                              variant={this.state.frequency === '12' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '12' ? ' selected' : '')
                              }
                              //key={2}
                              onClick={this.handleFrequencyChange.bind(this, '12')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Year' })}
                            </Button>
                            {this.state.err.frequency ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.frequency
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        {/* <input
                          type="button"
                          onClick={this.handlePrevious}
                          className="action-button"
                          value={intl.formatMessage({ id: 'Back' })}
                        />
                        <input
                          type="button"
                          onClick={this.submitForm}
                          className="action-button"
                          value={intl.formatMessage({ id: 'Next' })}
                        /> */}

                        {middleComponent}

                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}
                  {categoryType && categoryType.title === 'General' ? (
                    <form id="msform">
                      <ul id="progressbar">
                        <li className="active" />
                        <li className="active" />
                        <li />
                        <li />
                      </ul>
                      <fieldset>
                        <h2 className="title-text text-center"> {intl.formatMessage({ id: 'Payment' })}</h2>
                        {currencyField}
                        {subCategoryType && subCategoryType.title === 'Critical Illness' ? (
                          <div className="form-group">
                            <input
                              type="text"
                              name="sumInsured"
                              value={this.state.sumInsured}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input" style={{ top: '-12px' }}>
                              {intl.formatMessage({ id: 'Sum Insured' })}*
                            </label>
                            <i className="bar" />
                            {this.state.err.sumInsured ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.sumInsured
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        {subCategoryType &&
                          subCategoryType.title !== 'Accident' &&
                          subCategoryType.title !== 'Provident Fund' &&
                          subCategoryType.title !== 'Mortgage' && (
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="lifeCoverage"
                                value={this.state.lifeCoverage}
                                thousandSeparator={true}
                                onValueChange={values => {
                                  const { value } = values;
                                  delete this.state.err.lifeCoverage;
                                  this.setState({ lifeCoverage: value });
                                }}
                                required="required"
                                inputMode="decimal"
                                pattern="[0-9]*"
                              />
                              <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                                {subCategoryType && subCategoryType.title === 'Saving'
                                  ? intl.formatMessage({
                                    id: 'Life Coverage Amount'
                                  })
                                  : intl.formatMessage({
                                    id: 'Coverage Amount'
                                  })}
                                *
                              </label>
                              <i className="bar" />
                              {this.state.err.lifeCoverage ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.lifeCoverage
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                              {subCategoryType &&
                                subCategoryType.title === 'Critical Illness' &&
                                this.state.sumInsured && (
                                  <input
                                    type="button"
                                    value={intl.formatMessage({
                                      id: 'Same'
                                    })}
                                    className="action-button action-change-button"
                                    onClick={this.copyCiToLife.bind(this)}
                                  />
                                )}
                            </div>
                          )}
                        {subCategoryType && subCategoryType.title === 'Accident' && (
                          <div className="form-group">
                            <NumberFormat
                              //type="number"
                              //name="lifeCoverage"
                              value={this.state.lifeCoverage}
                              thousandSeparator={true}
                              onValueChange={values => {
                                delete this.state.err.lifeCoverage;
                                const { value } = values;
                                this.setState({ lifeCoverage: value });
                              }}
                              required="required"
                              inputMode="decimal"
                              pattern="[0-9]*"
                            />
                            <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                              {intl.formatMessage({ id: 'Accidental Death' })}*
                            </label>
                            <i className="bar" />
                            {this.state.err.lifeCoverage ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.lifeCoverage
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}

                        <div className="form-group">
                          <NumberFormat
                            //type="number"
                            //name="premium"
                            value={this.state.premium}
                            thousandSeparator={true}
                            onValueChange={values => {
                              const { value } = values;
                              delete this.state.err.premium;
                              this.setState({ premium: value });
                            }}
                            required="required"
                            inputMode="decimal"
                            pattern="[0-9]*"
                            disabled={this.state.naPremium}
                          />
                          <label className="control-label" style={{ top: '-12px' }} htmlFor="input">
                            {subCategoryType &&
                              subCategoryType.title !== 'Provident Fund' &&
                              subCategoryType.title !== 'Mortgage'
                              ? intl.formatMessage({ id: 'Premium' })
                              : intl.formatMessage({ id: 'Payment' })}
                            *
                          </label>
                          <i className="bar" />
                          {this.state.err.premium ? <span className="error_field">{this.state.err.premium}</span> : ''}

                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={this.state.naPremium}
                                  onChange={this.checkNAPremium}
                                />
                              }
                              label={intl.formatMessage({ id: 'premium-na' })}
                            />
                          </div>

                          <div className="premium__btns">
                            <Button
                              variant={this.state.frequency === '1' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '1' ? ' selected' : '')
                              }
                              //key={0}
                              onClick={this.handleFrequencyChange.bind(this, '1')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Month' })}
                            </Button>

                            <Button
                              variant={this.state.frequency === '3' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '3' ? ' selected' : '')
                              }
                              //key={1}
                              onClick={this.handleFrequencyChange.bind(this, '3')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Quarter' })}
                            </Button>

                            <Button
                              variant={this.state.frequency === '6' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '6' ? ' selected' : '')
                              }
                              //key={2}
                              onClick={this.handleFrequencyChange.bind(this, '6')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Half Year' })}
                            </Button>
                            <Button
                              variant={this.state.frequency === '12' ? 'contained' : 'outlined'}
                              className={
                                'half-width-button plan-type-select-button' +
                                (this.state.frequency === '12' ? ' selected' : '')
                              }
                              //key={2}
                              onClick={this.handleFrequencyChange.bind(this, '12')}
                              disabled={this.state.naPremium}
                            >
                              {intl.formatMessage({ id: 'Per Year' })}
                            </Button>
                            {this.state.err.frequency ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.frequency
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {taxDeductibleField}
                        {subCategoryType &&
                          (subCategoryType.title === 'Saving' ||
                            subCategoryType.title === 'Provident Fund' ||
                            subCategoryType.title === 'Other') && (
                            <div className="form-group">
                              <NumberFormat
                                //type="number"
                                //name="lumpSumAmount"
                                thousandSeparator={true}
                                value={this.state.lumpSumAmount}
                                onValueChange={values => {
                                  const { value } = values;
                                  this.setState({ lumpSumAmount: value });
                                }}
                                required="required"
                              />
                              <label className="control-label" htmlFor="input">
                                {intl.formatMessage({ id: 'LumpSum amount' })}:
                              </label>
                              <i className="bar" />
                            </div>
                          )}
                        {subCategoryType &&
                          subCategoryType.title !== 'Provident Fund' &&
                          subCategoryType.title !== 'Mortgage' && (
                            <div className="select-buttons-div">
                              <div className="control-orphay">{intl.formatMessage({ id: 'Payment Method' })}:</div>
                              <div>
                                <Button
                                  variant={this.state.Bank === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.Bank === 'Yes'
                                      ? 'payment-type-select-button selected'
                                      : 'payment-type-select-button'
                                  }
                                  key={0}
                                  onClick={() => this.handlePaymentStatus(0)}
                                >
                                  {intl.formatMessage({
                                    id: 'AutoPay by Bank'
                                  })}
                                </Button>

                                <Button
                                  variant={this.state.CreditCard === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.CreditCard === 'Yes'
                                      ? 'payment-type-select-button selected'
                                      : 'payment-type-select-button'
                                  }
                                  key={1}
                                  onClick={() => this.handlePaymentStatus(1)}
                                >
                                  {intl.formatMessage({
                                    id: 'AutoPay by Credit Card'
                                  })}
                                </Button>

                                <Button
                                  variant={this.state.Manual === 'Yes' ? 'contained' : 'outlined'}
                                  className={
                                    this.state.Manual === 'Yes'
                                      ? 'payment-type-select-button selected'
                                      : 'payment-type-select-button'
                                  }
                                  key={2}
                                  onClick={() => this.handlePaymentStatus(2)}
                                >
                                  {intl.formatMessage({ id: 'Manual' })}
                                </Button>
                              </div>
                              <i className="bar" />
                              {this.state.err.paymentMethod ? (
                                <span className="error_field">
                                  {intl.formatMessage({
                                    id: this.state.err.paymentMethod
                                  })}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          )}

                        {middleComponent}
                        {remainderComponent}

                        <Grid container justify={'space-between'}>
                          <Grid item>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={this.handlePrevious}
                              startIcon={<ArrowBackIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Back' })}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.submitForm}
                              endIcon={<ArrowForwardIosIcon />}
                              className="prevNextButton"
                            >
                              {intl.formatMessage({ id: 'Next' })}
                            </Button>
                          </Grid>
                        </Grid>
                      </fieldset>
                    </form>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.inactiveUserPremiumReminderModal}
          onClose={() => this.setState({ inactiveUserPremiumReminderModal: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          TransitionComponent={SlideTransition}
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-slide-title">{intl.formatMessage({ id: 'Connect with client' })}</DialogTitle>
          <DialogContent>
            {intl.formatMessage({
              id: 'CONNECT with Client to set the Premium Reminder.'
            })}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ inactiveUserPremiumReminderModal: false })}
              color="primary"
              autoFocus
            >
              {intl.formatMessage({ id: 'OK' })}
            </Button>
          </DialogActions>
        </Dialog>

        <FillInStartDateModal
          intl={intl}
          open={this.state.fillInStartDateModalOpen}
          onClose={this.continueNext.bind(this)}
          onOK={() => this.setState({ fillInStartDateModalOpen: false })}
          onContinue={this.continueNext.bind(this)}
        />
      </div>
    );
  }
}

export default PaymentView;
