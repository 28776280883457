import React, { Fragment, useState, useRef } from 'react';
import { uploadAttachments } from '../../../../../store/media/api';
import { MEDIA_UPLOAD_TYPE } from '../../../../../constants/media';
import {
  deleteFileFromAttachmentsCategory,
  deleteFileFromS3,
  editAttachmentOfCategory,
  uploadAttachmentsToFileAttachmentsCategories
} from '../../../../../store/team/api';
import { getTeams } from '../../../../../store/team/duck';
import { toast } from 'react-toastify';
import IconToast from '../../../../../components/NewToast';
import ContentSlides from '../../../../../components/ContentSlides/ContentSlides';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import FileSlide from '../../../../../components/FileSlide/FileSlide';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import MenuButton from '../../../../../components/MenuButton/MenuButton';
import EditIcon from '@material-ui/icons/Edit';
import EditCategoryDialog from '../EditCategoryDialog/EditCategoryDialog';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FileUploadDialog from '../../../../../components/FilesUploadButton/FileUploadDialog/FileUploadDialog';

const FileSlides = props => {
  const { intl, history, team, categories, category, isTeamHead } = props;

  const dispatch = useDispatch();
  const [isEditCategoryDialogOpen, setIsEditCategoryDialogOpen] = useState(false);
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
  const [isFileRemoveDialogOpen, setIsFileRemoveDialogOpen] = useState(false);
  const [fileToRemove, setFileToRemove] = useState(null);

  const handleFilesSubmitted = async files => {
    try {
      const filesToAttachments = files => {
        return files.map(file => {
          return {
            filename: file.name,
            size: file.size,
            url: URL.createObjectURL(file)
          };
        });
      };

      const linkFilesToAttachments = files => {
        return files.map(file => {
          return {
            filename: file.name,
            size: file.size,
            url: file.url
          };
        });
      };
      const filesToUpload = filesToAttachments(files.filter(file => !file.url));
      const linkFiles = linkFilesToAttachments(files.filter(file => !!file.url));
      const attachments = await uploadAttachments({
        resourceId: team?._id,
        attachments: filesToUpload,
        type: MEDIA_UPLOAD_TYPE.TEAM
      });
      await uploadAttachmentsToFileAttachmentsCategories(categories, team?._id, category?._id, [
        ...attachments,
        ...linkFiles
      ]);
      dispatch(getTeams());
    } catch (error) {
      toast.info(
        IconToast(
          'error',
          error.message === 'File attachments size limit exceeded'
            ? intl.formatMessage({ id: 'upload-files-size-limit-error' })
            : intl.formatMessage({ id: 'upload-files-error' })
        ),
        {
          className: 'new-toast'
        }
      );
    }
  };

  const handleSeeAll = category => {
    history.push({
      pathname: '/team_library_category',
      state: {
        teamId: team?._id,
        categoryId: category._id
      }
    });
  };

  const removeFile = async () => {
    await deleteFileFromAttachmentsCategory(team._id, category._id, fileToRemove._id, categories);
    dispatch(getTeams());
    const object_path = (new URL(fileToRemove.url)).searchParams.get('object_path');
    if (object_path) {
      deleteFileFromS3(object_path);
    }
    setIsFileRemoveDialogOpen(false);
  };

  const handleRemoveFile = async file => {
    setFileToRemove(file);
    setIsFileRemoveDialogOpen(true);
  };

  const handleEditFilename = async (file, filename) => {
    await editAttachmentOfCategory(categories, team._id, category._id, file._id, { filename, updatedAt: new Date() });
    dispatch(getTeams());
  };

  const openEditCategoryDialog = () => {
    setIsEditCategoryDialogOpen(true);
  };

  const closeEditCategoryDialog = () => {
    setIsEditCategoryDialogOpen(false);
  };

  const openFileUploadDialog = () => {
    setIsFileUploadDialogOpen(true);
  };

  const closeFileUploadDialog = () => {
    setIsFileUploadDialogOpen(false);
  };

  const seeAllButtonProps =
    category.attachments.length > 0
      ? {
        label: `${intl.formatMessage({ id: 'team-library-category-see-all' })} ❯`,
        onClick: () => {
          handleSeeAll(category);
        }
      }
      : null;

  return (
    <Fragment>
      <FileUploadDialog
        open={isFileUploadDialogOpen}
        onClose={closeFileUploadDialog}
        files={[]}
        onFilesSubmitted={handleFilesSubmitted}
        isLinkUploadEnabled={true}
        isDescriptionInputEnabled={true}
      />

      <ContentSlides
        title={category.title}
        titleChildren={
          <div className="titleButtonsContainer">
            <MenuButton
              style={{
                height: 20,
                color: '#8C8B81',
                padding: 0
              }}
              menuItems={[
                {
                  title: intl.formatMessage({ id: 'Edit' }),
                  icon: EditIcon,
                  handler: openEditCategoryDialog
                },
                {
                  title: intl.formatMessage({ id: 'team-library-category-add-items' }),
                  icon: NoteAddIcon,
                  handler: openFileUploadDialog
                }
              ]}
            />
          </div>
        }
        seeAllButton={seeAllButtonProps}
      >
        {category.attachments.length > 0 ? (
          category.attachments.map((attachment, index) => (
            <FileSlide
              key={index}
              file={attachment}
              onFileDeleted={handleRemoveFile}
              onFilenameChange={handleEditFilename}
              isTeamHead={isTeamHead}
            />
          ))
        ) : (
          <div>{intl.formatMessage({ id: 'team-library-category-no-files' })}</div>
        )}
      </ContentSlides>

      <EditCategoryDialog
        open={isEditCategoryDialogOpen}
        onClose={closeEditCategoryDialog}
        team={team}
        category={category}
        categories={categories}
        isTeamHead={isTeamHead}
      />

      <Dialog fullWidth={true} open={isFileRemoveDialogOpen} onBackdropClick={() => setIsFileRemoveDialogOpen(false)}>
        <DialogTitle>{intl.formatMessage({ id: 'remove-file-dialog-title' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'remove-file-dialog-content' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setIsFileRemoveDialogOpen(false)}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" onClick={removeFile}>
            {intl.formatMessage({ id: 'Confirm' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default injectIntl(withRouter(FileSlides));
