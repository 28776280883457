import _ from 'lodash';

export const productionEnv = process.env.NODE_ENV.trim() === 'production';

export const isNullOrUndefined = value => value === undefined || value === null;
export const isFalseExceptZero = value => !value && value !== 0;
export const formatFalseNumber = (value, defaultValue) => (isFalseExceptZero(value) ? defaultValue : value);

export const difference = (object, base) => {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
};

export const stripHTML = html => {
  let tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
