import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import GridButton from '../../components/GridButtonLayout/GridButton/GridButton';
import { tracking } from '../../utils/tracking';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer.js';

const ClientTools = props => {
  const { intl, history, renderHeaderButton } = props;

  useEffect(() => {
    renderHeaderButton(true);
  }, [renderHeaderButton]);

  const info = () => {
    tracking('Access Client Tools: Insurer Info');
    history.push({ pathname: '/insurer_info', state: { previousPath: '/client-tools' } });
  };

  const insurance101 = () => {
    tracking('Access Client Tools: Insurance 101');
    window.open('https://w3.portfoplus.com', '_blank');
  };

  // const covid19 = () => {
  //   tracking('Access Client Tools: COVID 19 Radar');
  //   history.push({ pathname: '/covid-tools' });
  // };

  return (
    <AppContentContainer>
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid policyInsuranceTypeClientTools">
              <div className="row">
                <div className="col-lg-12">
                  <Grid container spacing={3} style={{ paddingBottom: 15 }}>
                    <GridButton
                      image={<img src="img/informbig.png" alt="insurer-info" />}
                      text={intl.formatMessage({ id: 'Info' })}
                      onClick={info}
                    />

                    <GridButton
                      image={<img src="img/insurance-101.png" alt="insurance-101" />}
                      text={intl.formatMessage({ id: 'insurance-101' })}
                      onClick={insurance101}
                    />

                    {/*<GridButton*/}
                    {/*  image={<img src="img/StopVirus.png" alt="covid-19" />}*/}
                    {/*  text={intl.formatMessage({ id: 'covid-quick-check' })}*/}
                    {/*  onClick={covid19}*/}
                    {/*/>*/}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContentContainer>
  );
};

const container = connect(state => ({}), {
  renderHeaderButton
})(ClientTools);

export default injectIntl(container);
