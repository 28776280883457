import React, { PureComponent } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { decimalToString, formatNumber } from '../../../../utils/formatNumber';
import { Button, Grid } from '@material-ui/core';
import '../style.scss';
import QuarterProgressChart from '../../../Advisor/Business/partials/ProgressComponent/QuarterProgressChart.js';
import ClosedBizCard from '../../../Advisor/Business/partials/ProgressComponent/Cards/ClosedBizCard.js';
import FollowUpCard from '../../../Advisor/Business/partials/ProgressComponent/Cards/FollowUpCard.js';
import Card from '@material-ui/core/Card';
import ContactDialog from '../../../../components/ContactDialog/ContactDialog';
import MoreInfoIconButton from '../../../../components/MoreInfoIconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

class TeamsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      arrowRotateClient: false,
      selectedId: '',
      memberDetailPopup: false,
      phoneNumber: '',
      whatsapp: '',
      email: '',
      sortingState: 'hightolow',
      wechatId: '',
      lineId: ''
    };
    this.unmounted = false;
    this.selectedClientCheck = false;
    this.memberDetailPopup = this.memberDetailPopup.bind(this);
  }

  UNSAFE_componentWillMount() {}

  changeState(val, index) {
    let arrowRotateClient = this.state.selectedIndex === index ? !this.state.arrowRotateClient : true;
    this.selectedClientCheck = false;

    this.setState(
      {
        selectedId: arrowRotateClient ? val._id : '',
        arrowRotateClient: arrowRotateClient,
        selectedIndex: index
      },
      () => {
        this.setLocalStorage(this.state.selectedId, true);
      }
    );
  }

  memberDetailPopup(val) {
    this.setState({
      phoneNumber: _.get(val, 'phoneNumber', ''),
      whatsapp: _.get(val, 'whatsapp', ''),
      email: _.get(val, 'TeamAdviserEmail', ''),
      memberDetailPopup: true,
      wechatId: _.get(val, 'wechatId', ''),
      lineId: _.get(val, 'lineId', '')
    });
  }

  clientMessages() {
    const { whatsapp } = this.state;
    if (!whatsapp || whatsapp === '') {
      return;
    }
    let phoneNoText = whatsapp ? 'phone=' + whatsapp.replace('+', '') : '';
    let url = '';
    if (phoneNoText === '') {
      url = `https://api.whatsapp.com`;
    } else {
      url = `https://api.whatsapp.com/send?${phoneNoText}`;
    }
    var win = window.open(url, '_blank');
    if (win) win.focus();
  }

  setLocalStorage(id) {
    if (id !== undefined) {
      localStorage.setItem('arrowRotateClient', JSON.stringify(this.state.arrowRotateClient));
    }
  }

  //To handle the scroll functionality
  showCoords(event) {
    let clientArrow = window.localStorage.getItem('arrowRotateClient');
    let deviceFinalLength = window.screen.height / 2;
    let eventClick = event.clientY;
    if (clientArrow !== null && clientArrow !== undefined && clientArrow === 'false' && window.pageYOffset <= 250) {
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick - 135;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    } else if (
      clientArrow !== null &&
      clientArrow !== undefined &&
      clientArrow === 'true' &&
      window.pageYOffset <= 250
    ) {
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick - 135;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    }
    if (clientArrow !== null && clientArrow !== undefined && clientArrow === 'false' && window.pageYOffset >= 250) {
      let scroll = window.pageYOffset;
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick + scroll - 120;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    } else if (
      clientArrow !== null &&
      clientArrow !== undefined &&
      clientArrow === 'true' &&
      window.pageYOffset >= 250
    ) {
      let scroll = window.pageYOffset;
      if (deviceFinalLength < eventClick) {
        this.setState({ changeStateCord: true });
        let scrollCord = eventClick + scroll - 120;
        localStorage.setItem('changeStateCord', JSON.stringify(true));
        localStorage.setItem('scrollCoordinate', JSON.stringify(scrollCord));
      } else {
        this.setState({ changeStateCord: false });
        localStorage.setItem('changeStateCord', JSON.stringify(false));
        localStorage.setItem('scrollCoordinate', JSON.stringify(window.pageYOffset));
      }
    }
  }

  componentDidMount() {
    if (!this.unmounted) {
      this.selectedClientCollapseCheck();
    }
  }

  componentDidUpdate(prevProps) {
    this.selectedClientCollapseCheck();
    // check web tour running status
    if (!this.props.guidelineIsStarted && prevProps.guidelineIsStarted) {
      // list all client after the end of web tour
      let obj = {
        clientState: '',
        selectedId: '',
        arrowRotateClient: false
      };

      this.setState(obj, () => {
        this.setLocalStorage(this.state.selectedId, true);
      });
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  selectedClientCollapseCheck() {
    if (!this.selectedClientCheck) {
      const { selectedId, arrowRotateClient } = this.state;
      // find open client and trigger collapse class explicitly
      let otherElements = document.querySelectorAll('.panel-collapse.collapse');

      if (otherElements.length > 0) {
        this.selectedClientCheck = true;
        otherElements.forEach(element => {
          if (element.id === selectedId && arrowRotateClient) {
            element.classList.add('in');
            element.style.height = 'unset';
          } else {
            element.classList.remove('in');
            element.style.height = '0px';
          }
        });
      }
    }
  }

  selectTeam(level, subteam) {
    let tempSelectedTeams = [...this.props.selectedTeams, { [level]: subteam }];
    this.props.setSelectedTeams(tempSelectedTeams);
    this.props.setVersion(Date());
    this.props.setTeamStructureTeamId(subteam._id);
  }

  selectUpperTeam() {
    let tempSelectedTeams = [...this.props.selectedTeams];
    tempSelectedTeams.pop();
    this.props.setSelectedTeams(tempSelectedTeams);
    this.props.setVersion(Date());
    this.props.setTeamStructureTeamId(Object.values(tempSelectedTeams[tempSelectedTeams.length - 1])[0]._id);
  }

  render() {
    const {
      intl,
      activeTeamData,
      adviserDetails,
      myselfData,
      globalQuarterEndDate,
      openFollowupDiscriptionDialog,
      selectedTeams
    } = this.props;
    const {
      selectedIndex,
      arrowRotateClient,
      memberDetailPopup,
      phoneNumber,
      whatsapp,
      email,
      wechatId,
      lineId
    } = this.state;
    let filteredMembers = [];
    if (adviserDetails && adviserDetails.length > 0) {
      adviserDetails.map(val => {
        //Quarter end and year End
        //Logic to calculate the QEND and YEAREND keys
        let currentYear = moment().format('YYYY');
        let currentMonth = parseInt(moment().format('MM'));
        let currentDate = moment().startOf('day');
        let Q =
          currentMonth <= 3
            ? currentYear + '-03-31'
            : currentMonth <= 6
            ? currentYear + '-06-30'
            : currentMonth <= 9
            ? currentYear + '-09-30'
            : currentMonth <= 12
            ? currentYear + '-12-31'
            : '';
        let QMonthStartDate1 =
          currentMonth <= 3
            ? currentYear + '-01-01'
            : currentMonth <= 6
            ? currentYear + '-04-01'
            : currentMonth <= 9
            ? currentYear + '-07-01'
            : currentMonth <= 12
            ? currentYear + '-10-01'
            : '';

        let endDate = globalQuarterEndDate ? moment.parseZone(globalQuarterEndDate).format('YYYY-MM-DD') : Q;
        let QEndDate = moment(endDate).toDate();

        let pastDays = moment().diff(QMonthStartDate1, 'days') + 1;

        let QEndMonth = parseInt(moment(endDate).format('MM'));

        /*End to calculate the big date count*/

        let QEnd = 0;

        //calculate the range according to the quarter
        let r1 = 0;
        let QEndYear = parseInt(moment(endDate).format('YYYY'));
        let currrentEndYear = parseInt(moment().format('YYYY'));

        if (
          moment(endDate).isAfter(currentDate) ||
          (moment(endDate).isSame(currentDate) && QEndYear === currrentEndYear)
        ) {
          r1 = moment(endDate).diff(QMonthStartDate1, 'days') + 1;
        } else if (QEndMonth >= currentMonth && QEndYear === currrentEndYear) {
          // r1 = 0;
          r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
        } else if (
          QEndYear === currrentEndYear &&
          QEndMonth <= currentMonth &&
          QEndMonth <= parseInt(moment(Q).format('MM')) &&
          currentMonth <= parseInt(moment(Q).format('MM')) &&
          QEndMonth >= parseInt(moment(QMonthStartDate1).format('MM'))
        ) {
          // r1 = 0;
          r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
        } else {
          r1 = moment(moment(Q).toDate()).diff(moment(QMonthStartDate1).startOf('day'), 'days') + 1;
        }

        //define Qend(date from )
        if (
          (moment(QEndDate).isAfter(currentDate) || moment(QEndDate).isSame(currentDate)) &&
          QEndYear === currrentEndYear
        ) {
          QEnd = moment(QEndDate).diff(currentDate, 'days');
        } else if (QEndMonth >= currentMonth && QEndYear === currrentEndYear) {
          // QEnd = '-';
          QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
        } else if (
          QEndYear === currrentEndYear &&
          QEndMonth <= currentMonth &&
          QEndMonth <= parseInt(moment(Q).format('MM')) &&
          currentMonth <= parseInt(moment(Q).format('MM')) &&
          QEndMonth >= parseInt(moment(QMonthStartDate1).format('MM'))
        ) {
          // QEnd = '-';
          QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
        } else {
          QEnd = moment(moment(Q).toDate()).diff(moment().startOf('day'), 'days');
          QEnd = QEnd > 92 ? 92 : QEnd <= 92 && QEnd >= 0 ? QEnd : '-';
        }

        let quarterTarget = _.get(val, 'quarterTarget', 0);
        let expectedBiz = r1 > 0 ? (pastDays / r1) * quarterTarget : 0;
        let currentBiz = 0;
        if (val.currentProgressOption === 'showExpectedFrom' || val.currentProgressOption === '') {
          currentBiz = (val.adjustOpenBiz || 0) + (val.closedBiz || 0);
        }

        if (val.currentProgressOption === 'showManualInput') {
          currentBiz = (val.currentProgress || 0) + (val.closedBiz || 0);
        }

        val.currentBiz = currentBiz;
        val.QEnd = r1 - pastDays;
        val.expectedBiz = expectedBiz;

        filteredMembers.push(val);
        return true;
      });
    }

    let lagging = [];
    let noTarget = [];
    let leading = [];
    for (let filteredMember of filteredMembers) {
      if (!filteredMember.expectedBiz || filteredMember.expectedBiz === 0) {
        noTarget.push(filteredMember);
      } else if (filteredMember.currentBiz < filteredMember.expectedBiz) {
        lagging.push(filteredMember);
      } else {
        leading.push(filteredMember);
      }
    }

    let sortByName = function(a, b) {
      return a.TeamAdviserName.localeCompare(b.TeamAdviserName, ['en', 'zh-Hant', 'zh']);
    };
    lagging.sort((a, b) => sortByName(a, b));
    noTarget.sort((a, b) => sortByName(a, b));
    leading.sort((a, b) => sortByName(a, b));

    let myOrderedArray = [...noTarget, ...lagging, ...leading];

    //filter the duplicated record with same email address
    if (activeTeamData && activeTeamData.teamName === 'Total') {
      myOrderedArray = myOrderedArray.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj['TeamAdviserEmail']).indexOf(obj['TeamAdviserEmail']) === pos;
      });
    } else {
      myOrderedArray = myOrderedArray.filter(value => {
        return activeTeamData.teamName === value.teamName;
      });

      myOrderedArray.sort((a, b) => b.role?.length - a.role?.length);

      if (selectedTeams && selectedTeams.length >= 1) {
        let level = parseInt(Object.keys(selectedTeams[selectedTeams.length - 1])[0]);
        let currentTeam = Object.values(selectedTeams[selectedTeams.length - 1])[0];
        let subteams = currentTeam?.subteams;
        if (subteams) {
          myOrderedArray.map(member => {
            for (let subteam of subteams) {
              for (let tempMember of subteam.members) {
                if (member._id === tempMember.id && tempMember.role === 'teamLeader') {
                  subteam.parentTeamId = currentTeam._id;
                  member.subteam = subteam;
                  member.subteamLevel = level + 1;
                }
              }
            }
          });
        }
      }
    }

    return (
      <div>
        {selectedTeams?.length > 1 && (
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 3, padding: '0 6px 0 0', fontSize: 12 }}
            onClick={() => this.selectUpperTeam()}
          >
            <KeyboardArrowLeftIcon /> {intl.formatMessage({ id: 'upper-team' })}
          </Button>
        )}

        <div className="advisor_team-list cardView-reminder teams-tab-wrapper">
          <div className="tile Teamlist">
            <div className="insured-heading insured-heading-first" style={{ minWidth: '30%' }}>
              {intl.formatMessage({ id: 'Name' })}
            </div>
            <div className="insured-heading insured-heading-second">{intl.formatMessage({ id: 'Tracking' })}</div>
            <div className="insured-heading insured-heading-third">{intl.formatMessage({ id: 'Closed Biz' })}</div>
          </div>
          <div className="body">
            {myOrderedArray && myOrderedArray.length > 0
              ? myOrderedArray.map((val, index) => {
                  // let collapseClass = selectedId === val._id ? 'collapse in' : 'collapse';

                  let highOpportunityCount = _.get(val, 'highOpportunityCount', 0);
                  let mediumOpportunityCount = _.get(val, 'mediumOpportunityCount', 0);
                  let lowOpportunityCount = _.get(val, 'lowOpportunityCount', 0);
                  let noneOpportunityCount = _.get(val, 'noneOpportunityCount', 0);

                  let quarterTargetOfTeam = _.get(val, 'quarterTarget', 0);

                  let closedBizOfMemberPercentage = val.closedBiz > 0 ? (val.closedBiz / val.quarterTarget) * 100 : 0;
                  if (!val.expectedBiz || val.expectedBiz === 0) {
                    var liClassName = '#cccccc';
                  } else if (val.currentBiz >= val.expectedBiz) {
                    var liClassName = '#5ee092';
                  } else {
                    var liClassName = '#ff6a74';
                  }

                  let percentageOfAdjustedBiz =
                    quarterTargetOfTeam > 0 ? (val.currentBiz / quarterTargetOfTeam) * 100 : 0;

                  let headingStatus = '';
                  let outerProgressValue = val.currentBiz;
                  let innerProgressValue = val.expectedBiz;

                  if (
                    outerProgressValue !== 0 &&
                    outerProgressValue >= innerProgressValue &&
                    percentageOfAdjustedBiz < 100
                  ) {
                    headingStatus = 'onTrack';
                  } else if (outerProgressValue < innerProgressValue && percentageOfAdjustedBiz < 100) {
                    headingStatus = 'offTrack';
                  } else if (percentageOfAdjustedBiz >= 100) {
                    headingStatus = 'great';
                  } else {
                    headingStatus = 'zeroProgress';
                  }

                  let opportunityCountObj = {
                    highOpportunityCount: highOpportunityCount,
                    mediumOpportunityCount: mediumOpportunityCount,
                    lowOpportunityCount: lowOpportunityCount,
                    noneOpportunityCount: noneOpportunityCount
                  };
                  let totalOpportunityCount =
                    highOpportunityCount + mediumOpportunityCount + lowOpportunityCount + noneOpportunityCount;

                  let sumOfManualInput =
                    val.currentProgressOption === 'showManualInput' ? _.get(val, 'currentProgress', 0) : 0;

                  let highOpportunityBizVol = _.get(val, 'highAdjustedBiz', 0);
                  let mediumOpportunityBizVol = _.get(val, 'medAdjustedBiz', 0);
                  let lowOpportunityBizVol = _.get(val, 'lowAdjustedBiz', 0);
                  let noneOpportunityBizVol = _.get(val, 'noneAdjustedBiz', 0);
                  let opportunityBizVolObj = {
                    highOpportunityBizVol: highOpportunityBizVol,
                    mediumOpportunityBizVol: mediumOpportunityBizVol,
                    lowOpportunityBizVol: lowOpportunityBizVol,
                    noneOpportunityBizVol: noneOpportunityBizVol,
                    manualBizVol: sumOfManualInput
                  };
                  let adjustedOpportunityBizVol =
                    highOpportunityBizVol * 0.75 +
                    mediumOpportunityBizVol * 0.5 +
                    lowOpportunityBizVol * 0.25 +
                    noneOpportunityBizVol * 0.1;

                  return (
                    <div
                      className="reminder-list"
                      key={index}
                      style={{
                        borderRadius: '6px',
                        borderLeft: liClassName === '' ? '4px solid transparent' : '4px solid ' + liClassName
                      }}
                    >
                      <div
                        className={'tile Teamlist'}
                        style={{
                          borderRadius: '6px',
                          borderTopLeftRadius: '0px',
                          borderBottomLeftRadius: '0px',
                          borderLeft: '4px solid ' + liClassName
                        }}
                        onClick={this.showCoords.bind(this)}
                      >
                        <div
                          className="insured-text insured-heading-edit insured-heading-third insured-text-policy for-center"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          href={'#' + val._id}
                          aria-expanded="false"
                          aria-controls={val._id}
                          onClick={this.changeState.bind(this, val, index)}
                        >
                          {val.TeamAdviserName}
                          {val.subteam ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                fontWeight: 400,
                                fontSize: 11,
                                padding: '0 3px',
                                minWidth: 'auto',
                                marginLeft: 5
                              }}
                              onClick={() => this.selectTeam(val.subteamLevel, val.subteam)}
                            >
                              {intl.formatMessage({ id: 'Team' })}
                            </Button>
                          ) : (
                            val.role === 'teamLeader' && (
                              <Button
                                color="primary"
                                style={{
                                  border: '1.1px solid',
                                  borderRadius: 10,
                                  padding: '0 6px',
                                  fontSize: 11,
                                  marginLeft: 5,
                                  minWidth: 'auto'
                                }}
                              >
                                {intl.formatMessage({ id: 'leader' })}
                              </Button>
                            )
                          )}
                        </div>

                        <div className="insured-text insured-heading-edit insured-heading-third insured-text-policy">
                          <span className="portfolio-add-sign mamberchat">
                            <img
                              alt=""
                              className="imageClientState"
                              src={'img/chat_bubble_blue2.png'}
                              onClick={this.memberDetailPopup.bind(this, val)}
                            />
                          </span>
                          {val.count}
                          {val.currentProgressOption === 'showManualInput' && (
                            <Button
                              className="follow-up-oppo-btn-small"
                              style={{
                                color: '#3966f8',
                                border: '1.1px solid #3966f8',
                                marginLeft: '10px'
                              }}
                              variant="outlined"
                              onClick={() => openFollowupDiscriptionDialog(false, adjustedOpportunityBizVol)}
                            >
                              {intl.formatMessage({ id: 'Manual-short' })}
                            </Button>
                          )}
                        </div>
                        <div className="advisor_team_policy" style={{ color: '#8c1aff' }}>
                          <span>{val.closedBiz >= 0 ? formatNumber(val.closedBiz, intl) : 0}</span>
                          <span>
                            (
                            {val.expectedBiz && val.expectedBiz !== 0
                              ? decimalToString(closedBizOfMemberPercentage) + '%'
                              : '-'}
                            )
                          </span>
                        </div>

                        <img
                          alt=""
                          className={
                            selectedIndex === index && arrowRotateClient
                              ? 'dash-arrow arrow-rotate arrow-animation'
                              : 'dash-arrow arrow-animation'
                          }
                          src="img/downArrow.svg"
                          role="button"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          href={'#' + val._id}
                          aria-expanded="false"
                          aria-controls={val._id}
                          onClick={this.changeState.bind(this, val, index)}
                          style={{ width: 24, height: 24 }}
                        />
                      </div>
                      <div className={'panel-collapse collapse'} id={val._id}>
                        <Grid container spacing={0} style={{ lineHeight: '1.35' }}>
                          <Grid container spacing={0} style={{ margin: '0px', background: '#fff' }}>
                            <Grid container item direction="row" justify="center" alignItems="center" xs={6}>
                              <div>
                                <div style={{ textAlign: 'center' }}>
                                  {intl.formatMessage({ id: 'Target' })}:{'  '}
                                  <span className="progress-card-number-smaller">
                                    {val.quarterTarget > 0 ? formatNumber(val.quarterTarget, intl) : 0}
                                  </span>
                                </div>
                                <div className="team-member-graph">
                                  <QuarterProgressChart
                                    headingStatus={headingStatus}
                                    outerChartValue={val.currentBiz}
                                    targetBiz={
                                      val.currentProgressOption === 'showManualInput'
                                        ? _.get(val, 'currentProgress', 0)
                                        : _.get(val, 'adjustOpenBiz', 0)
                                    }
                                    QEnd={val.QEnd}
                                    quarterTarget={val.quarterTarget}
                                    confirmedBiz={val.closedBiz}
                                    quarterBusiness={val.expectedBiz}
                                  />
                                </div>
                              </div>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" item xs={6}>
                              <Grid item xs={12}>
                                <Card className="progress-card-upper-shadow">
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                    spacing={0}
                                  >
                                    <Grid item xs={12} className="team-mamber-teamName">
                                      {val.teamName}
                                    </Grid>
                                    <Grid item xs={12} className="progress-card-title">
                                      {intl.formatMessage({ id: 'No. of Clients' })}:{' '}
                                      <span className="progress-card-number-smaller">{val.TotalClientOfTeam}</span>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Grid>

                              <Grid item xs={12}>
                                <ClosedBizCard
                                  page="teamMember"
                                  confirmedBiz={val.closedBiz}
                                  expectedBiz={val.expectedBiz}
                                  quarterTarget={val.quarterTarget}
                                />
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <FollowUpCard
                                page="teamMember"
                                targetBiz={
                                  val.currentProgressOption === 'showManualInput'
                                    ? val.currentProgress > 0
                                      ? val.currentProgress
                                      : 0
                                    : val.adjustOpenBiz > 0
                                    ? val.adjustOpenBiz
                                    : 0
                                }
                                opportunityBizVolObj={opportunityBizVolObj}
                                opportunityCount={opportunityCountObj}
                                numberOfRecord={totalOpportunityCount}
                                isManual={val.currentProgressOption === 'showManualInput'}
                                manualInput={sumOfManualInput}
                                leftBtnComponent={
                                  <MoreInfoIconButton
                                    className="pt-10 progress-card-more-info-btn"
                                    handleClick={() => openFollowupDiscriptionDialog(false, adjustedOpportunityBizVol)}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>

          <ContactDialog
            open={memberDetailPopup}
            onClose={() => this.setState({ memberDetailPopup: false })}
            phoneNumber={phoneNumber}
            whatsapp={whatsapp}
            wechatId={wechatId}
            lineId={lineId}
            email={email}
          />
        </div>
      </div>
    );
  }
}

export default TeamsView;
