import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import SelectButtonGroup from '../../../../components/SelectButtonGroup/SelectButtonGroup';

const AccountTypesInput = props => {
  const { intl, value, onChange } = props;

  return (
    <Grid container justify="space-between" alignItems="flex-start" spacing={2} wrap="nowrap">
      <Grid item>
        <Typography color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
          {intl.formatMessage({ id: 'account-types' })}
        </Typography>
      </Grid>
      <Grid item>
        <SelectButtonGroup
          value={value}
          choices={['personal', 'voluntary', 'contribution', 'provident-fund'].map(choice => ({
            value: choice,
            label: intl.formatMessage({ id: `at-${choice}` })
          }))}
          onChange={onChange}
          justify="flex-end"
          buttonClassName="plan-type-select-button"
          multi={true}
        />
      </Grid>
    </Grid>
  );
};

export default injectIntl(AccountTypesInput);
