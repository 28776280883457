export const clientFilterGuideline = ({ intl }) => [
  {
    target: '.filter-toggle',
    content: 'jr-cf-1',
    placement: 'left',
    disableBeacon: true,
    isFixed: true,
    disableScrolling: true,
    title: intl.formatMessage({ id: 'jr-cf-1-title' }),
    tutorialItemId: 'c44',
    styles: {
      tooltip: {
        width: 240
      }
    }
  }
];
