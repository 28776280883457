import React, { PureComponent } from 'react';
import Phone from 'react-phone-number-input';
import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import countryList from '../../../../store/locale/country-list';
import _ from 'lodash';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import '../styles.scss';
import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { SlideTransition } from '../../../../components/Transition/Transition';
import Checkbox from '@material-ui/core/Checkbox';
import { jsonParse } from '../../../../utils/json';
import ConfirmAddPolicyDialog from '../ConfirmAddPolicyDialog/ConfirmAddPolicyDialog';
import { goToAddPortfolio, goToExportOption, goToPortfolio } from '../../../../components/ClientEntry/utils';
import { getUrlWithProtocol } from '../../../../utils/url';
import { formatAppendixData } from '../../utils';
import { DEFAULT_APPENDIX_DATA } from '../../constants';
import { isAdviserType } from '../../../../utils/user';
import { TopNavigationPortal } from '../../../../components/topNavigation';
import { isEmailValid } from '../../../../utils/email';
import FilesUploadButton from '../../../../components/FilesUploadButton/FilesUploadButton';
import { ShortSubscriptions } from '../../../../utils/user-subscription';
import { hasCampaignCode } from '../../../../utils/user';
import LoadingBackdrop from '../../../../components/LoadingBackdrop/LoadingBackdrop';
import { LOADING, SUCCESS } from '../../../../constants/phase';

const categoryDetails = [
  { id: '59b0eaee14ea7f1aafb6d0b5', title: 'Personal' },
  { id: '59b0eb2314ea7f1aafb6d0b6', title: 'Company' },
  { id: '59b0eb3814ea7f1aafb6d0b7', title: 'General' }
];

const subCategoryDeatils = [
  { id: '59b0ed2cdae1831be084f804', title: 'Life' },
  { id: '59b0ed6fdae1831be084f805', title: 'Critical Illness' },
  { id: '59b0ed8fdae1831be084f806', title: 'Medical' },
  { id: '59b0ed98dae1831be084f807', title: 'Other' },
  { id: '59b0edd8dae1831be084f808', title: 'Motor' },
  { id: '59b0eddfdae1831be084f809', title: 'Home' },
  { id: '59b0ede7dae1831be084f80a', title: 'Travel' },
  { id: '5b9a5bfee624cdb543a476d1', title: 'Maid' },
  { id: '5b9a5bfee624cdb543a476d2', title: 'Accident' },
  { id: '5baf2af899d85c2d38c8f00f', title: 'Saving' },
  { id: '5c18ebd120ca05b7e2b32565', title: 'Provident Fund' },
  { id: '5c41b85a83230016450b2214', title: 'Mortgage' }
];

// const OneChoiceButton = props => {
//   const { ownValue, value, onChange, children, ...rest } = props;
//   const isChosen = value === ownValue;
//   const onClick = onChange(isChosen ? null : ownValue);
//   return (
//     <Button
//       variant={isChosen ? 'contained' : 'outlined'}
//       className={`plan-type-select-button source-button-responsive ${isChosen ? 'selected' : ''}`}
//       onClick={onClick}
//       {...rest}
//     >
//       {children}
//     </Button>
//   );
// };

class ApendixView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_APPENDIX_DATA,
      imagePreviewUrl: [],
      image: [],
      progress: '',
      modalIsOpen: false,
      modalIsOpen1: false,
      removeId: '',
      err: {},
      errMessage: '',
      //deductible: '',
      subCategory: '',
      subCategoryType: '',
      categoryType: '',
      // smm: 'No',
      // hospitalCash: 'No',
      // outPatient: 'No',
      // dental: 'No',
      Yes: 'No',
      No: 'No',
      NoIdea: 'No',
      keyIndex: [],
      keyArray: [],
      status: false,
      files: []
    };
    this.submitForm = this.submitForm.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleButtonStatus = this.handleButtonStatus.bind(this);
    this.handleOrphayStatus = this.handleOrphayStatus.bind(this);
    this.closeModal1 = this.closeModal1.bind(this);
    this.addRider = this.addRider.bind(this);

    this.editPolicyDetails = jsonParse(sessionStorage.getItem('editPolicyDetails'));
    this.editPolicy = jsonParse(sessionStorage.getItem('editPolicy'));
    this.nextAction = 'submit';
  }

  UNSAFE_componentWillMount() {
    var apendixData = '';
    var editApendix = JSON.parse(localStorage.getItem('editApendix'));
    var addRider = JSON.parse(localStorage.getItem('addRider'));
    var previousPolicy = '';
    //saving the policy data in there respective fields
    if (this.editPolicyDetails) {
      apendixData = this.editPolicyDetails;
    } else {
      apendixData = JSON.parse(localStorage.getItem('apendix'));
    }
    if (editApendix === 'true') {
      apendixData = JSON.parse(localStorage.getItem('apendix'));
    }
    if (addRider === true) {
      previousPolicy = JSON.parse(localStorage.getItem('previousPolicyData'));
    }
    if (apendixData != null) {
      this.setState({
        advisorName: apendixData.advisorName,
        advisorPhoneNo: apendixData.advisorPhoneNo ? apendixData.advisorPhoneNo.toString() : apendixData.advisorPhoneNo,
        advisorPhoneNo2: apendixData.advisorPhoneNo2
          ? apendixData.advisorPhoneNo2.toString()
          : apendixData.advisorPhoneNo2,
        advisorEmail: apendixData.advisorEmail,
        productLink: apendixData.productLink,
        remark: apendixData.remark,
        imagePreviewUrl: apendixData.uploadDocuments,
        orphanCase: apendixData.orphanCase ? apendixData.orphanCase : '',
        isDraft: apendixData.isDraft || false,
        files: apendixData.attachments.map(file => {
          return {
            name: file.filename,
            url: file.url
          };
        })
      });

      if (apendixData.orphanCase === 'Yes') {
        this.setState({ Yes: 'Yes' });
      }
      if (apendixData.orphanCase === 'No') {
        this.setState({ No: 'Yes' });
      }
      if (apendixData.orphanCase === 'No Idea') {
        this.setState({ NoIdea: 'Yes' });
      }
    }
    if (previousPolicy != null && apendixData === null && previousPolicy !== '' && previousPolicy !== undefined) {
      this.setState({
        advisorName: _.get(previousPolicy, 'advisorName', ''),
        advisorPhoneNo:
          _.get(previousPolicy, 'advisorPhoneNo') !== null && _.get(previousPolicy, 'advisorPhoneNo') !== undefined
            ? _.get(previousPolicy, 'advisorPhoneNo', '').toString()
            : '',
        advisorPhoneNo2:
          _.get(previousPolicy, 'advisorPhoneNo2') !== null && _.get(previousPolicy, 'advisorPhoneNo2') !== undefined
            ? _.get(previousPolicy, 'advisorPhoneNo2', '').toString()
            : '',
        advisorEmail: _.get(previousPolicy, 'advisorEmail', ''),
        imagePreviewUrl: _.get(previousPolicy, 'uploadDocuments', []),
        orphanCase: _.get(previousPolicy, 'orphanCase', ''),
        isDraft: _.get(previousPolicy, 'isDraft', false),
        attachments: _.get(previousPolicy, 'attachments', [])
      });
      if (_.get(previousPolicy, 'orphanCase') === 'Yes') {
        this.setState({ Yes: 'Yes' });
      }
      if (_.get(previousPolicy, 'orphanCase') === 'No') {
        this.setState({ No: 'Yes' });
      }
      if (_.get(previousPolicy, 'orphanCase') === 'No Idea') {
        this.setState({ NoIdea: 'Yes' });
      }
    }
    if (this.editPolicyDetails) {
      var categoryType = '';
      var subCategoryType = '';
      var categoryId = this.editPolicyDetails.categoryId;
      var subCategoryId = this.editPolicyDetails.subCategoryId;
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType = data.title;
        }
        return true;
      });
      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType = data.title;
        }
        return true;
      });
      this.setState({
        subCategoryType: subCategoryType,
        categoryType: categoryType
      });
    } else {
      this.setState({
        subCategoryType: _.get(JSON.parse(localStorage.getItem('subCategory')), 'title', ''),
        categoryType: _.get(JSON.parse(localStorage.getItem('category')), 'title', '')
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let addRider = JSON.parse(localStorage.getItem('addRider'));
    const { history, location, viewingClient, userDetails, router } = this.props;
    if (
      nextProps.policyPhase === 'success' &&
      this.props.policyPhase !== SUCCESS &&
      this.props.policyPhase !== undefined
    ) {
      localStorage.removeItem('category');
      localStorage.removeItem('subCategory');
      localStorage.removeItem('plan');
      localStorage.removeItem('payment');
      localStorage.removeItem('detail');
      localStorage.removeItem('apendix');
      localStorage.removeItem('editPlan');
      localStorage.removeItem('editPayment');
      localStorage.removeItem('editDetail');
      localStorage.removeItem('editApendix');
      localStorage.removeItem('factsheetDOB');
      sessionStorage.removeItem('editPolicyDetails');
      sessionStorage.removeItem('editPolicy');
      if (this.nextAction === 'another') {
        goToAddPortfolio(viewingClient, history, userDetails, true, location.state);
      } else if (this.nextAction === 'export') {
        goToExportOption(viewingClient, history, true, location.state);
      } else if (this.nextAction === 'rider') {
        history.replace({
          pathname: '/add_portfolio',
          state: location.state
        });
      } else {
        const { previousLocation } = router || {};
        const { pathname } = previousLocation || {};

        if (location.state && location.state.previousPath) {
          localStorage.removeItem('insurer');
          localStorage.removeItem('addRider');
          localStorage.removeItem('previousPolicyData');
          if (pathname === location.state.previousPath) {
            history.goBack();
          } else {
            history.replace(location.state.previousPath);
          }
        } else {
          const replaceRoute = _.get(location, 'state.replaceRoute');
          if (replaceRoute) {
            history.replace(replaceRoute);
          } else {
            if (isAdviserType(userDetails)) {
              if (pathname === '/dashboard') {
                history.goBack();
              } else {
                goToPortfolio(viewingClient, history, true, location.state);
              }
            } else {
              if (pathname === '/c-dashboard') {
                history.goBack();
              } else {
                history.replace('/c-dashboard');
              }
            }
          }
        }
      }
    }
  }

  handleChange(e) {
    if (e.target.name === 'advisorEmail') {
      delete this.state.err.advisorEmail;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  setLocalStorage = () => {
    formatAppendixData(this.state);
  };

  submit = middleFunction => {
    this.setState({ modalIsOpen1: false });
    this.setLocalStorage();
    localStorage.removeItem('addRider');
    if (middleFunction) {
      middleFunction();
    }
    this.props.handleSubmit();
  };

  //Submit the appendix data
  submitForm() {
    this.submit(() => {
      this.nextAction = 'submit';
      localStorage.removeItem('previousPolicyData');
    });
  }

  addRider() {
    this.submit(() => {
      this.nextAction = 'rider';
      localStorage.setItem('addRider', JSON.stringify(true));
    });
  }

  addAnother = () =>
    this.submit(() => {
      this.nextAction = 'another';
    });

  goExport = () =>
    this.submit(() => {
      this.nextAction = 'export';
    });

  openModal(index) {
    this.setState({ removeId: index, modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal1() {
    const err = {};
    var categoryId = '';
    var categoryType = {};
    var subCategoryId = '';
    var subCategoryType = {};
    if (this.editPolicyDetails) {
      categoryId = this.editPolicyDetails.categoryId;
      subCategoryId = this.editPolicyDetails.subCategoryId;

      categoryDetails.map(function(data) {
        if (data.id === categoryId) {
          categoryType.title = data.title;
        }
        return true;
      });
      subCategoryDeatils.map(function(data) {
        if (data.id === subCategoryId) {
          subCategoryType.title = data.title;
        }
        return true;
      });
    } else {
      categoryType = JSON.parse(localStorage.getItem('category'));
      subCategoryType = JSON.parse(localStorage.getItem('subCategory'));
    }
    if (this.state.advisorEmail !== '' && !isEmailValid(this.state.advisorEmail)) {
      err.advisorEmail = 'Invalid email';
    }
    if (categoryType && subCategoryType && (categoryType.title === 'Personal' || subCategoryType.title === 'Saving')) {
      if (this.state.orphanCase === '' || this.state.orphanCase === null) {
        err.orphanCase = 'Please pick one.';
      }
    }
    this.setState({ err });
    if (!Object.keys(err).length) {
      this.setState({ modalIsOpen1: true });
    }
  }

  closeModal1() {
    this.setState({ modalIsOpen1: false });
  }

  delete() {
    const index = this.state.removeId;
    const displayImage = this.state.imagePreviewUrl;
    const uploadImage = this.state.image;
    if (index > -1) {
      displayImage.splice(index, 1);
      uploadImage.splice(index, 1);
    }
    this.setState({ modalIsOpen: false });
  }

  handlePrevious() {
    formatAppendixData(this.state);
    this.props.handlePreviousPage();
  }

  //Handle the status in case of medical for hospital cash , smm , outpatient and denal
  handleButtonStatus(key) {
    const state = this.state.keyArray;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({ smm: 'Yes' });
      }
      if (key === 1) {
        this.setState({ hospitalCash: 'Yes' });
      }
      if (key === 2) {
        this.setState({ outPatient: 'Yes' });
      }
      if (key === 3) {
        this.setState({ dental: 'Yes' });
      }
    } else {
      this.setState({
        keyArray: this.state.keyArray.filter(key1 => {
          return key1 !== key;
        })
      });
      if (key === 0) {
        this.setState({ smm: 'No' });
      }
      if (key === 1) {
        this.setState({ hospitalCash: 'No' });
      }
      if (key === 2) {
        this.setState({ outPatient: 'No' });
      }
      if (key === 3) {
        this.setState({ dental: 'No' });
      }
    }
  }

  //Handle teh status of orphan case.
  handleOrphayStatus(key) {
    const state = this.state.keyArray;
    delete this.state.err.orphanCase;
    if (state.indexOf(key) === -1) {
      this.setState({ keyArray: [...this.state.keyArray, key] });
      if (key === 0) {
        this.setState({
          Yes: 'Yes',
          No: 'No',
          NoIdea: 'No',
          orphanCase: 'Yes'
        });
      }
      if (key === 1) {
        this.setState({ Yes: 'No', No: 'Yes', NoIdea: 'No', orphanCase: 'No' });
      }
      if (key === 2) {
        this.setState({
          Yes: 'No',
          No: 'No',
          NoIdea: 'Yes',
          orphanCase: 'No Idea'
        });
      }
    } else {
      this.setState({
        keyArray: this.state.keyArray.filter(key1 => {
          return key1 !== key;
        })
      });
      if (key === 0) {
        if (this.state.Yes !== 'No') {
          this.setState({ Yes: 'No', orphanCase: '' });
        } else {
          this.setState({
            Yes: 'Yes',
            No: 'No',
            NoIdea: 'No',
            orphanCase: 'Yes'
          });
        }
      }
      if (key === 1) {
        if (this.state.No !== 'No') {
          this.setState({ No: 'No', orphanCase: '' });
        } else {
          this.setState({
            Yes: 'No',
            No: 'Yes',
            NoIdea: 'No',
            orphanCase: 'No'
          });
        }
      }
      if (key === 2) {
        if (this.state.NoIdea !== 'No') {
          this.setState({ NoIdea: 'No', orphanCase: '' });
        } else {
          this.setState({
            Yes: 'No',
            No: 'No',
            NoIdea: 'Yes',
            orphanCase: 'No Idea'
          });
        }
      }
    }
  }

  //Will save the name, work email and tel of adviser automatically
  handleClickAuto() {
    const { userDetails } = this.props;
    this.setState({
      advisorName: userDetails.name ? userDetails.name : '',
      advisorEmail: userDetails.workEmail ? userDetails.workEmail : '',
      advisorPhoneNo: userDetails.phoneNumber ? userDetails.phoneNumber : '',
      advisorPhoneNo2: userDetails.phoneNumber2 ? userDetails.phoneNumber2 : ''
    });
  }

  changeIsDraft = (event, checked) => this.setState({ isDraft: checked });

  render() {
    const { intl, subscriptions, userDetails, teamCampaignCodes } = this.props;
    const isFreeTrial = new ShortSubscriptions(subscriptions).isCoreSubscriptionFreeTrial();
    const isShowFileAttachments = !isFreeTrial && hasCampaignCode(userDetails, teamCampaignCodes, 'POLICYFILE');

    //let editPolicy = _.get(location,'state.editPolicy',false)
    const isAdviser = _.get(userDetails, 'userType') === 'Adviser';
    let showAutoButton = isAdviser;
    let { imagePreviewUrl, subCategoryType, categoryType, isDraft } = this.state;

    if (imagePreviewUrl) {
    } else {
    }
    return (
      <div>
        {this.props.policyPhase === 'loading' && <LoadingBackdrop open={true} />}
        <TopNavigationPortal>
          <span className="back_arrow" onClick={this.handlePrevious}>
            <ArrowBackIcon />
          </span>
        </TopNavigationPortal>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <form id="msform" noValidate={true}>
                    <ul id="progressbar">
                      <li className="active"></li>
                      <li className="active"></li>
                      <li className="active"></li>
                      <li className="active"></li>
                    </ul>
                    <fieldset>
                      <h2 className="title-text text-center">{intl.formatMessage({ id: 'Appendix' })}</h2>
                      <div className="form-group">
                        <input
                          type="text"
                          name="advisorName"
                          value={this.state.advisorName}
                          onChange={this.handleChange.bind(this)}
                          required="required"
                          className={showAutoButton ? 'add-client-no-email-input' : ''}
                        />
                        <label className="control-label" htmlFor="input">
                          {intl.formatMessage({ id: 'Adviser Name' })}:
                        </label>
                        {showAutoButton && (
                          <Button
                            variant="contained"
                            color="primary"
                            className=" action-change-button"
                            onClick={this.handleClickAuto.bind(this)}
                          >
                            {intl.formatMessage({ id: 'Auto' })}
                          </Button>
                        )}
                        <i className="bar" />
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group phone_module clearfix">
                            <label className=" phone-module-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Adviser Tel' })}:
                            </label>
                            <Phone
                              international={true}
                              // inputComponent={SmartInput}
                              country="HK"
                              placeholder={intl.formatMessage({
                                id: 'Enter phone number'
                              })}
                              value={
                                this.state.advisorPhoneNo
                                  ? this.state.advisorPhoneNo.toString()
                                  : this.state.advisorPhoneNo
                              }
                              onChange={value => this.setState({ advisorPhoneNo: value ? value.toString() : value })}
                              //labels={countryList.labels}
                              countries={countryList.labels}
                              flagsPath={'img/flags/'}
                              indicateInvalid
                            />
                            <i className="bar" />
                          </div>
                        </div>
                        {/* <label className="control-label" htmlFor="input">
                          Adviser Tel
                        </label>*/}
                        {/*<i className="bar" />
                        {this.state.err.advisorPhoneNo ? (
                          <span className="error_field">
                            {this.state.err.advisorPhoneNo}
                          </span>
                        ) : (
                          ''
                        )}*/}
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group phone_module clearfix">
                            <label className=" phone-module-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Adviser Tel 2' })}:
                            </label>
                            <Phone
                              international={true}
                              // inputComponent={SmartInput}
                              country="CN"
                              placeholder={intl.formatMessage({
                                id: 'Enter second phone number'
                              })}
                              value={
                                this.state.advisorPhoneNo2
                                  ? this.state.advisorPhoneNo2.toString()
                                  : this.state.advisorPhoneNo2
                              }
                              onChange={value => this.setState({ advisorPhoneNo2: value ? value.toString() : value })}
                              //labels={countryList.labels}
                              countries={countryList.labels}
                              flagsPath={'img/flags/'}
                              indicateInvalid
                            />
                            <i className="bar" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="advisorEmail"
                          value={this.state.advisorEmail}
                          onChange={this.handleChange.bind(this)}
                          required="required"
                        />
                        <label className="control-label" htmlFor="input">
                          {intl.formatMessage({ id: 'Adviser Email' })}:
                        </label>
                        <i className="bar" />
                        {this.state.err.advisorEmail ? (
                          <span className="error_field">
                            {intl.formatMessage({
                              id: this.state.err.advisorEmail
                            })}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          name="productLink"
                          value={this.state.productLink}
                          onChange={this.handleChange.bind(this)}
                          required="required"
                          placeholder="https://"
                        />
                        <label className="control-label login__page--label" htmlFor="input">
                          {intl.formatMessage({ id: 'product-link' })}:
                        </label>
                        <i className="bar" />
                        <Button
                          className="action-change-button"
                          color="primary"
                          variant="contained"
                          disabled={!this.state.productLink}
                          onClick={() => window.open(getUrlWithProtocol(this.state.productLink), '_blank')}
                        >
                          {intl.formatMessage({ id: 'Go' })}
                        </Button>
                      </div>

                      {/*subCategoryType === 'Medical' && (
                        <div className="select-buttons-div">
                          <div style={{ marginBottom: '15px' }}>
                            <Button
                              variant={
                                this.state.smm == 'Yes'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              className={
                                this.state.smm == 'Yes'
                                  ? 'plan-type-select-button medical-type-select-button selected'
                                  : 'plan-type-select-button medical-type-select-button'
                              }
                              key={0}
                              onClick={() => this.handleButtonStatus(0)}
                            >
                              {intl.formatMessage({ id: 'SMM' })}
                            </Button>

                            <Button
                              variant={
                                this.state.hospitalCash == 'Yes'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              className={
                                this.state.hospitalCash == 'Yes'
                                  ? 'plan-type-select-button medical-type-select-button selected'
                                  : 'plan-type-select-button medical-type-select-button'
                              }
                              key={1}
                              onClick={() => this.handleButtonStatus(1)}
                            >
                              {intl.formatMessage({ id: 'Hospital Cash' })}
                            </Button>

                            <Button
                              variant={
                                this.state.outPatient == 'Yes'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              className={
                                this.state.outPatient == 'Yes'
                                  ? 'plan-type-select-button medical-type-select-button selected'
                                  : 'plan-type-select-button medical-type-select-button'
                              }
                              key={2}
                              onClick={() => this.handleButtonStatus(2)}
                            >
                              {intl.formatMessage({ id: 'Outpatient' })}
                            </Button>

                            <Button
                              variant={
                                this.state.dental == 'Yes'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              className={
                                this.state.dental == 'Yes'
                                  ? 'plan-type-select-button medical-type-select-button selected'
                                  : 'plan-type-select-button medical-type-select-button'
                              }
                              key={3}
                              onClick={() => this.handleButtonStatus(3)}
                            >
                              {intl.formatMessage({ id: 'Dental' })}
                            </Button>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              name="deductible"
                              value={this.state.deductible}
                              onChange={this.handleChange.bind(this)}
                              required="required"
                            />
                            <label className="control-label" htmlFor="input">
                              {intl.formatMessage({ id: 'Deductible' })}:
                            </label>
                            <i className="bar" />
                          </div>
                        </div>
                      )*/}
                      <div className="form-group">
                        <textarea
                          type="text"
                          name="remark"
                          value={this.state.remark}
                          onChange={this.handleChange.bind(this)}
                          required="required"
                          style={{ marginTop: '8px' }}
                        />
                        <label className="control-label" htmlFor="input">
                          {intl.formatMessage({ id: 'Remark' })}:
                        </label>
                        <i className="bar" />
                      </div>

                      {categoryType &&
                        subCategoryType &&
                        (categoryType === 'Personal' || subCategoryType === 'Saving') && (
                          <div className="select-buttons-div">
                            <div className="control-orphay source-button  source-button-edit">
                              {intl.formatMessage({ id: 'Orphan Case' })}*
                            </div>
                            <div>
                              <Button
                                variant={this.state.Yes === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.Yes === 'Yes'
                                    ? 'plan-type-select-button selected source-button-responsive'
                                    : 'plan-type-select-button source-button-responsive'
                                }
                                key={0}
                                onClick={() => this.handleOrphayStatus(0)}
                              >
                                {intl.formatMessage({ id: 'Yes' })}
                              </Button>

                              <Button
                                variant={this.state.No === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.No === 'Yes'
                                    ? 'plan-type-select-button selected source-button-responsive'
                                    : 'plan-type-select-button source-button-responsive'
                                }
                                key={1}
                                onClick={() => this.handleOrphayStatus(1)}
                              >
                                {intl.formatMessage({ id: 'No' })}
                              </Button>

                              <Button
                                variant={this.state.NoIdea === 'Yes' ? 'contained' : 'outlined'}
                                className={
                                  this.state.NoIdea === 'Yes'
                                    ? 'plan-type-select-button selected source-button-responsive'
                                    : 'plan-type-select-button source-button-responsive'
                                }
                                key={2}
                                onClick={() => this.handleOrphayStatus(2)}
                              >
                                {intl.formatMessage({ id: 'No Idea' })}
                              </Button>
                            </div>
                            <i className="bar" />
                            {this.state.err.orphanCase ? (
                              <span className="error_field">
                                {intl.formatMessage({
                                  id: this.state.err.orphanCase
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        )}

                      <Grid container className="select-buttons-div" alignItems="center">
                        <Grid item>{intl.formatMessage({ id: 'draft' })}</Grid>
                        {isDraft && (
                          <Grid item>
                            <img alt="draft" src="img/draft.png" width={16} />
                          </Grid>
                        )}
                        <Grid item style={{ flexGrow: 1 }}>
                          <Checkbox checked={isDraft} onChange={this.changeIsDraft} />
                        </Grid>
                      </Grid>

                      {isShowFileAttachments && (
                        <>
                          <Grid container alignItems="center">
                            <Grid item>{intl.formatMessage({ id: 'upload-files-form-title' })}</Grid>
                          </Grid>
                          <Grid>
                            <FilesUploadButton
                              files={this.state.files}
                              onFilesSubmitted={files =>
                                this.setState({
                                  files: [...this.state.files, ...files]
                                })
                              }
                              onFileRemoved={fileIndex =>
                                this.setState({
                                  files: [
                                    ...this.state.files.slice(0, fileIndex),
                                    ...this.state.files.slice(fileIndex + 1)
                                  ]
                                })
                              }
                            />
                          </Grid>
                        </>
                      )}

                      <Grid container justify={'space-between'}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handlePrevious}
                            startIcon={<ArrowBackIosIcon />}
                            className="prevNextButton"
                          >
                            {intl.formatMessage({ id: 'Back' })}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            className="submitButton"
                            onClick={this.openModal1.bind(this)}
                          >
                            {intl.formatMessage({ id: 'Submit' })}
                          </Button>
                        </Grid>
                      </Grid>
                    </fieldset>
                  </form>

                  <ConfirmAddPolicyDialog
                    open={this.state.modalIsOpen1}
                    onClose={this.closeModal1}
                    onSubmit={this.submitForm}
                    onRider={this.addRider}
                    onAnother={this.addAnother}
                    onExport={isAdviser && this.goExport}
                  />

                  <div>
                    <Dialog
                      open={this.state.modalIsOpen}
                      onClose={this.closeModal}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                      TransitionComponent={SlideTransition}
                      fullWidth={true}
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {intl.formatMessage({ id: 'Remove Picture' })}
                      </DialogTitle>
                      <DialogContent>
                        {intl.formatMessage({
                          id: 'Are you sure? You want to remove this picture.'
                        })}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.closeModal} color="primary">
                          {intl.formatMessage({ id: 'Cancel' })}
                        </Button>
                        <Button onClick={this.delete.bind(this)} color="primary" autoFocus>
                          {intl.formatMessage({ id: 'OK' })}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApendixView;
