import React from 'react';
import { TotalAmountCard } from './TotalAmountCard/TotalAmountCard';
import './TotalAmountView.scss';
import '../../../views/NewSignUp/NewSignUpComponents.scss';
import { Button, ButtonGroup, TextField, Typography } from '@material-ui/core';
import { getCouponApi } from '../../../store/userSubscription/api';
import InputAdornment from '@material-ui/core/InputAdornment';
import { formatAPIResponseMessage } from '../../../utils/api-response-message';
import AppliedCoupon from './AppliedCoupon/AppliedCoupon';
import SubscriptionExtendedBanner from '../SubscriptionExtendedBanner/SubscriptionExtendedBanner';
import { getSubscriptionPlanExpiryDate } from '../../../utils/user-subscription';

export class TotalAmountView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      couponId: props.couponId || '',
      coupon: undefined,
      couponValue: undefined,
      loading: false,
      remark: props.remark || '',
      errors: {}
      // couponAppliedAfterChange: true
    };
  }

  componentDidMount() {
    const { couponId } = this.props;
    if (couponId) {
      this.handleApplyCoupon(false);
    }
  }

  handleChange(event) {
    const { errors } = this.state;
    let value = event.target.value || '';

    if (event.target.name === 'couponId') {
      value = value.toUpperCase();
    }

    this.setState({
      [event.target.name]: value,
      // couponAppliedAfterChange: false,
      errors: { ...errors, [event.target.name]: undefined }
    });
  }

  async handleApplyCoupon(confirm) {
    this.setState({ loading: true });

    const { intl, subscriptionPlan } = this.props;
    const { couponId, errors, remark } = this.state;

    let result = await getCouponApi(couponId, subscriptionPlan._id);

    if (result.error) {
      this.setState({
        errors: {
          ...errors,
          couponId: formatAPIResponseMessage(intl, result.error.message)
        },
        coupon: undefined,
        couponValue: undefined,
        loading: false
        // couponAppliedAfterChange: true
      });
    } else {
      this.setState(
        {
          coupon: result.coupon,
          couponValue: result.couponValue,
          loading: false,
          errors: {
            ...errors,
            couponId: undefined
          }
          // couponAppliedAfterChange: true
        },
        () => {
          if (confirm && this) {
            this.props.onConfirm(couponId, result.coupon, result.couponValue, remark);
          }
        }
      );
    }
  }

  render() {
    const { intl, userSubscription, subscriptionPlan } = this.props; // onConfirm
    const { couponId, coupon, couponValue, errors, loading, remark } = this.state; // couponAppliedAfterChange

    const formattedAmount = subscriptionPlan.isTrial
      ? subscriptionPlan.trialAmount
      : subscriptionPlan
      ? subscriptionPlan.amount
      : 0;

    const { freeTrialRemainingSeconds } = getSubscriptionPlanExpiryDate(userSubscription, subscriptionPlan);

    return (
      <div className="total-amount-view">
        <SubscriptionExtendedBanner freeTrialRemainingSeconds={freeTrialRemainingSeconds} />

        <Typography variant="h4" component="h4" align="center" paragraph={true}>
          {intl.formatMessage({ id: 'use-coupon' })}
        </Typography>

        <TotalAmountCard intl={intl} amount={coupon && couponValue ? formattedAmount - couponValue : formattedAmount} />

        {!coupon ? (
          <TextField
            className="input"
            variant="outlined"
            name="couponId"
            label={intl.formatMessage({ id: 'coupon-code' })}
            error={!!errors.couponId}
            helperText={errors.couponId ? errors.couponId : undefined}
            value={couponId}
            onChange={this.handleChange.bind(this)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    className="action"
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disabled={loading}
                    onClick={this.handleApplyCoupon.bind(this, false)}
                  >
                    {intl.formatMessage({ id: 'coupon-apply' })}
                  </Button>
                </InputAdornment>
              )
            }}
          />
        ) : (
          <AppliedCoupon className="input" coupon={coupon} onCancel={() => this.setState({ coupon: undefined })} />
        )}

        <TextField
          className="input"
          variant="outlined"
          name="remark"
          label={intl.formatMessage({ id: 'Remark' })}
          value={remark}
          onChange={this.handleChange.bind(this)}
          multiline={true}
          rows={6}
        />

        <Button
          className="action"
          variant="contained"
          color="primary"
          fullWidth={true}
          disabled={loading}
          onClick={() => {
            // if (couponAppliedAfterChange) {
            //   onConfirm(couponId, coupon, couponValue);
            // } else {
            this.handleApplyCoupon(true);
            // }
          }}
        >
          {intl.formatMessage({ id: 'Confirm' })}
        </Button>
      </div>
    );
  }
}
