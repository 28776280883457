import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { IconButton, InputAdornment } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import React from 'react';

const NextMeetingDateControl = props => {
  const { nextMeetingDate, onChange, onClose, className } = props;

  return (
    <DatePicker
      className={`${className ? className : ''}`}
      onClose={onClose}
      name="nextMeetingDate"
      format="DD-MMM-YYYY"
      clearable={true}
      disablePast={true}
      value={nextMeetingDate || null}
      onChange={onChange}
      autoOk={true}
      invalidDateMessage={null}
      minDateMessage={null}
      maxDateMessage={null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton style={{ padding: '0 5px 0 0' }}>
              <TodayIcon color="action" style={{ fontSize: 20 }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default NextMeetingDateControl;
