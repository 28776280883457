import { INITIAL_GPT_QUOTA } from '../../../../constants/gpt';
import { Typography } from '@material-ui/core';
import React from 'react';
import { getGPTQuota } from '../../../../utils/openai';
import { injectIntl } from 'react-intl';

const QuotaTypography = props => {
  const { intl, style, ...rest } = props;
  const currentQuota = getGPTQuota();

  return (
    <Typography
      color="textSecondary"
      style={{
        whiteSpace: 'pre-wrap',
        ...style
      }}
      {...rest}
    >
      {intl.formatMessage({ id: 'quota' })}: {currentQuota} / {INITIAL_GPT_QUOTA}
    </Typography>
  );
};

export default injectIntl(QuotaTypography);
