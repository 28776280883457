import { fetch } from '../../utils';
import Backend from '../../backend';
import NativeOrWeb from '../../utils/native';
import { versionString } from '../../contexts/version';
import { getBrowserDefaultLanguage } from '../../utils/locale';
import { getFingerprint } from '../../utils/fingerprint';

export const trackEvent = async (category, name, data) => {
  let timezone;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {}

  const fingerprint = await getFingerprint();

  return fetch(Backend.methodUrl(`events/${category}`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name: name,
      capacitorPlatform: NativeOrWeb.getPlatform(),
      appVersion: versionString,
      locale: getBrowserDefaultLanguage(),
      timezone: timezone,
      locationHref: window.location.href,
      fingerprintId: fingerprint.visitorId,
      data: data
    })
  })
    .then()
    .catch(console.log);
};

export const getLazyPackStatistics = async () => {
  return fetch(Backend.methodUrl('statistics/lp'))
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
