import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import { updateUserDetails } from '../../../../store/user/duck';
import AladdinHint from '../../../../components/AladdinHint/AladdinHint';

const MyDetailsCustomQuestions = props => {
  const { intl, details, onChangeDetails } = props;

  let { customQuestions, shouldHideProfilePlusCustomQuestions } = details || {};

  if (shouldHideProfilePlusCustomQuestions === undefined) {
    onChangeDetails({ shouldHideProfilePlusCustomQuestions: false });
  }

  if (!customQuestions || customQuestions.length != 2) {
    customQuestions = [
      { question: '', answers: '' },
      { question: '', answers: '' }
    ];
  }

  const onChangeQuestion = (event, index) => {
    let newDetails = [...customQuestions];
    newDetails[index].question = event.target.value;
    onChangeDetails({ customQuestions: newDetails });
  };

  const onChangeAnswers = (event, index) => {
    let newDetails = [...customQuestions];
    newDetails[index].answers = event.target.value;
    onChangeDetails({ customQuestions: newDetails });
  };

  const onChangeShouldHideProfilePlusCustomQuestions = event => {
    onChangeDetails({ shouldHideProfilePlusCustomQuestions: !event.target.checked });
  }

  return (
    <Fragment>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <AladdinHint title={intl.formatMessage({ id: 'custom-questions-hint' })} />
        </Grid>

        <Grid item>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography color="primary">{intl.formatMessage({ id: 'show-my-questions' })}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Checkbox
                id='disableCustomQuestions'
                checked={!shouldHideProfilePlusCustomQuestions}
                onChange={onChangeShouldHideProfilePlusCustomQuestions}
                style={{ padding: 4 }}
              />
            </Grid>
          </Grid>
        </Grid>

        {customQuestions.map((customQuestion, index) => (
          <Grid item key={index}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <TextField
                  label={intl.formatMessage({ id: 'question-number' }, { number: index + 1 })}
                  value={customQuestion.question}
                  onChange={event => onChangeQuestion(event, index)}
                  placeholder={intl.formatMessage({ id: 'custom-question-question-placeholder' })}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item>
                <TextField
                  label={intl.formatMessage({ id: 'answers-number' }, { number: index + 1 })}
                  value={customQuestion.answers}
                  onChange={event => onChangeAnswers(event, index)}
                  placeholder={intl.formatMessage({ id: 'custom-question-answer-placeholder' })}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

const container = connect(state => ({}), {
  updateUserDetails
})(MyDetailsCustomQuestions);

export default injectIntl(container);
