import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import './EditDisplayNamesDialog.scss';

const DialogContentStyle = {
  maxWidth: '450px',
  margin: '0 auto'
};

const EditDisplayNamesDialog = props => {
  const { intl, displayNames, open, onConfirm, onClose, ...rest } = props;

  const [names, setNames] = useState(displayNames);

  useEffect(() => {
    setNames(displayNames);
  }, [displayNames]);

  const handleConfirm = () => {
    onConfirm(names);
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} {...rest}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'edit-display-names-dialog-title'
        })}
      </DialogTitle>
      <DialogContent style={DialogContentStyle}>
        <Grid container spacing={2}>
          {names.map((displayName, index) => (
            <Grid key={index} item xs={6} className="display-name-input-container">
              <TextField
                className="display-name-text-field"
                value={displayName}
                placeholder={displayName}
                onChange={e => {
                  const newNames = [...names];
                  newNames[index] = e.target.value;
                  setNames(newNames);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          {intl.formatMessage({ id: 'Save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(EditDisplayNamesDialog);
