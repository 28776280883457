import React, { Fragment } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './MenuButton.scss';

const MenuButton = ({ menuItems, className, style }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton aria-label="MoreVert" color="primary" className={className} onClick={handleClick} style={style}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              handleClose();
              item.handler();
            }}
          >
            {item.icon && <item.icon className="menuIcon" />}
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

export default MenuButton;
