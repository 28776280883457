import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Input from '@material-ui/core/Input';
import React from 'react';
import './LocationControl.scss';
import { getOSMLocation } from '../../../../utils/location';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import NativeOrWeb from '../../../../utils/native';
import { TextField } from '@material-ui/core';

class LocationControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      clickedCount: 0,
      loading: false
    };

    this.input = React.createRef();
  }

  componentDidMount() {
    // manually updating the height of the textarea to show full text
    if (this.input && this.input.current) {
      const textarea = this.input.current.querySelector('textarea');
      if (textarea) {
        textarea.style.minHeight = `${textarea.scrollHeight}px`;
      }
    }
  }

  getLocationString = async () => {
    try {
      const { clickedCount } = this.state;
      if (clickedCount < 2) {
        this.setState({ loading: true, clickedCount: clickedCount + 1 });

        const location = await NativeOrWeb.getCurrentPosition();
        const lat = _.get(location, 'coords.latitude');
        const lon = _.get(location, 'coords.longitude');

        try {
          const { intl, onChange, name } = this.props;
          const locationStr = await getOSMLocation(lat, lon, intl.locale);

          if (locationStr) {
            if (onChange) {
              onChange({ target: { value: locationStr, name: name } });
            }
          } else {
            toast.error('Unable to get location result');
          }
        } catch (fetchError) {
          console.log(fetchError);
          toast.error('Unable to get location result');
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { intl, className, onChange, ...rest } = this.props;
    const { clickedCount, loading } = this.state;
    return (
      <TextField
        ref={this.input}
        // inputComponent={TextareaAutosize}
        className={`location-control ${className ? className : ''}`}
        type="text"
        multiline={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={this.getLocationString} disabled={loading || clickedCount > 1}>
                <LocationOnIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        // endAdornment={
        //   <InputAdornment position="end">
        //     <IconButton onClick={this.getLocationString} disabled={loading || clickedCount > 1}>
        //       <LocationOnIcon />
        //     </IconButton>
        //   </InputAdornment>
        // }
        onChange={onChange}
        {...rest}
      />
    );
  }
}

export default injectIntl(LocationControl);
