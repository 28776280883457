import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { sanitize } from 'dompurify';

const IconToast = (toastType, toastMessage, onClick) => (
  <div onClick={onClick}>
    <ListItem
      style={{
        padding: 0,
        alignItems: 'flex-start'
      }}
    >
      <ListItemAvatar
        style={{
          marginLeft: '1rem',
          marginTop: '0.8rem'
        }}
      >
        {(toastType === 'error' && <img src="img/quit.png" alt="reminder" style={{ width: 40 }} />) ||
          (toastType === 'info' && <img src="img/info.png" alt="system" style={{ width: 40 }} />) ||
          (toastType === 'success' && <img src="img/check.png" alt="promotion" style={{ width: 40 }} />) ||
          (toastType === 'warn' && <img src="img/alert.png" alt="promotion" style={{ width: 40 }} />) ||
          (toastType === 'pwa' && <img src="img/login-addtohomescreen.png" alt="install" style={{ width: 40 }} />)}
      </ListItemAvatar>
      <ListItemText
        className="Toast-title"
        style={{
          wordBreak: 'break-word',
          minHeight: '48px'
        }}
        primary={<div dangerouslySetInnerHTML={{ __html: sanitize(toastMessage) }} />}
      />
    </ListItem>
    {/* <button onClick={closeToast} style={{ display: 'none' }}></button> */}
  </div>
);

export default IconToast;
