import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { SlideTransition } from '../Transition/Transition';
import AdviserInfoContactEntry from '../../views/AdviserInfoPage/AdviserInfoContactEntry/AdviserInfoContactEntry';
import { formatPhoneNumber } from '../../utils/user';
import { toast } from 'react-toastify';
import IconToast from '../NewToast';
import NativeOrWeb from '../../utils/native';

const FullWidthStyle = {
  width: '100%'
};

const ContactDialog = props => {
  const {
    intl,
    open,
    onClose,
    phoneNumber,
    whatsapp,
    wechatId,
    lineId,
    email,
    extras,
    customContent,
    customWhatsappMessage,
    ...rest
  } = props;

  const goPhoneNumber = phoneNumber
    ? () => {
        window.open(`tel:${phoneNumber}`, '_blank');
      }
    : undefined;

  const goWhatsapp = whatsapp
    ? () => {
        window.open(
          customWhatsappMessage || `https://api.whatsapp.com/send?phone=${whatsapp.replace('+', '')}`,
          '_blank'
        );
      }
    : undefined;

  const onClickWechat = wechatId
    ? () => {
        NativeOrWeb.copyToClipboard(wechatId).then(() => {
          toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
            className: 'new-toast'
          });
        });
      }
    : undefined;

  const onClickLine = lineId
    ? () => {
        NativeOrWeb.copyToClipboard(lineId).then(() => {
          toast.info(IconToast('info', intl.formatMessage({ id: 'Copied!' })), {
            className: 'new-toast'
          });
        });
      }
    : undefined;

  const goEmail = email
    ? () => {
        window.open(`mailto:${email}`, '_blank');
      }
    : undefined;

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} fullWidth={true} {...rest}>
      <DialogTitle>{intl.formatMessage({ id: 'Contact' })}</DialogTitle>
      <DialogContent>
        {customContent ? (
          customContent
        ) : (
          <div style={{ maxWidth: 300, margin: 'auto' }}>
            <Grid container direction="column" alignItems="flex-start" spacing={2}>
              <Grid item style={FullWidthStyle}>
                <AdviserInfoContactEntry
                  imgSrc="img/phone.svg"
                  alt="phone"
                  text={formatPhoneNumber(phoneNumber) || '-'}
                  textStyle={phoneNumber ? { textDecoration: 'underline' } : undefined}
                  onClick={goPhoneNumber}
                />
              </Grid>
              <Grid item style={FullWidthStyle}>
                <AdviserInfoContactEntry
                  imgSrc="img/whatsapp.svg"
                  alt="whatsapp"
                  text={formatPhoneNumber(whatsapp) || '-'}
                  textStyle={whatsapp ? { textDecoration: 'underline' } : undefined}
                  onClick={goWhatsapp}
                />
              </Grid>
              <Grid item style={FullWidthStyle}>
                <AdviserInfoContactEntry
                  imgSrc="img/wechat.svg"
                  alt="wechat"
                  text={wechatId || '-'}
                  textStyle={wechatId ? { textDecoration: 'underline' } : undefined}
                  onClick={onClickWechat}
                />
              </Grid>
              <Grid item style={FullWidthStyle}>
                <AdviserInfoContactEntry
                  imgSrc="img/line.svg"
                  alt="line"
                  text={lineId || '-'}
                  textStyle={lineId ? { textDecoration: 'underline' } : undefined}
                  onClick={onClickLine}
                />
              </Grid>
              <Grid item style={FullWidthStyle}>
                <AdviserInfoContactEntry
                  imgSrc="img/email.svg"
                  alt="email"
                  text={email || '-'}
                  textStyle={email ? { textDecoration: 'underline' } : undefined}
                  onClick={goEmail}
                />
              </Grid>
              {extras && extras}
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          {intl.formatMessage({ id: 'OK' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default injectIntl(ContactDialog);
