import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Fab, Grid } from '@material-ui/core';
import './MyProfile.scss';
import './MyProfileAdviser.scss';
import NameCard from './NameCard/NameCard';
import AccountDetailsCard from './AdviserAccountDetailsCard/AdviserAccountDetailsCard';
import SubscriptionCard from './SubscriptionCard/SubscriptionCard';
import { renderHeaderButton } from '../../store/headerButtonControl/duck';
import GridButtonLayout from '../../components/GridButtonLayout/GridButtonLayout';
import { tracking } from '../../utils/tracking';
import { containMissionList } from '../../utils/user-mission';
import { toggleUserMissionPopover } from '../../store/userMission/duck';
import FeedBackModal from '../../components/FeedbackModal/FeedBackModal';
import Badge from '@material-ui/core/Badge';
import TopNavHelpButton from '../../components/TopNavHelpButton/TopNavHelpButton';
import AppContentContainer from '../../components/AppContentContainer/AppContentContainer';
import { TopNavigationPortal } from '../../components/topNavigation';
import { HotLabel } from '../../views/AdviserHomePage/HotLabel/HotLabel';
import ForEnterpriseOnlyCard from './ForEnterpriseOnlyCard/ForEnterpriseOnlyCard';
import NativeOrWeb from '../../utils/native';

const FabStyle = {
  width: 39.2,
  height: 39.2,
  position: 'fixed',
  right: 5,
  boxShadow: 'none',
  background: 'none'
};

const FabImageStyle = {
  width: '100%'
};

const MyProfileAdviser = props => {
  const { intl, history, stripePromise, userMissions, renderHeaderButton, toggleUserMissionPopover } = props;

  const [feedbackDialog, setFeedbackDialog] = useState(false);

  const shouldShowExtendFreeTrial = containMissionList(userMissions, 'free-trial');
  const shouldShowAdviserReferral = containMissionList(userMissions, 'adviser-referral');

  const closeFeedbackDialog = () => setFeedbackDialog(false);

  // const setTarget = () => {
  // tracking('Click on Set Target - My Profile');
  // history.push('/set_target');
  // };
  const extendFreeTrial = shouldShowExtendFreeTrial
    ? () => {
      // tracking('Click on Free Trial Extension - My Profile');
      toggleUserMissionPopover(true, 'free-trial');
    }
    : undefined;
  const feedback = () => {
    // tracking('Click on Feedback - My Profile');
    setFeedbackDialog(true);
  };
  const referAdviser = () => {
    // tracking('Click on Refer Adviser - My Profile');
    history.push('/refer_adviser');
  };
  const message = () => {
    // tracking('Adviser Drawer Accessed: My Profile');
    history.push('/adviser_message');
  };
  const team = () => {
    // tracking('Adviser Drawer Accessed: My Profile');
    history.push('/advisor_team');
  };
  const help = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=85266370320&text=${encodeURIComponent('(HC) 我要幫忙')}`,
      '_blank'
    );
  };

  const settings = () => {
    history.push('/settings');
  };

  const importClient = () => {
    tracking('Click on Import Clients - My Profile');
    history.push('/import');
  };

  useEffect(() => {
    renderHeaderButton(true);
  }, [renderHeaderButton]);

  return (
    <AppContentContainer>
      <div className="my-profile my-profile-adviser">
        <TopNavigationPortal>
          <div className="plus-btn-client dashboard-guideline-icon">
            <TopNavHelpButton buttonProps={{ notClientStyle: true }} />
          </div>
        </TopNavigationPortal>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <NameCard />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="stretch" spacing={2} className="same-height-container">
              <Grid item xs={12} sm={6}>
                <AccountDetailsCard />
              </Grid>
              <Grid item xs={12} sm={6}>
                {NativeOrWeb.isIOSPlatform() ? <ForEnterpriseOnlyCard /> : <SubscriptionCard stripePromise={stripePromise} />}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <GridButtonLayout
              items={[
                // shouldShowExtendFreeTrial && {
                //   image: <img src="img/help/extendtrial.png" alt="extend-free-trial" />,
                //   text: intl.formatMessage({ id: 'extend-free-trial' }),
                //   onClick: extendFreeTrial
                // },
                {
                  image: <img src="img/importUser.png" alt="import-clients" />,
                  text: intl.formatMessage({ id: 'import-clients' }),
                  onClick: importClient,
                  extra: (
                    <HotLabel
                      text={intl.formatMessage({ id: 'hot' })}
                      style={{ zIndex: 1, left: 'unset', right: 0, borderRadius: '4px 0 0 4px' }}
                    />
                  )
                },
                {
                  image: <img src="img/settings1.png" alt="settings" />,
                  text: intl.formatMessage({ id: 'Settings' }),
                  onClick: settings
                },
                // shouldShowAdviserReferral && {
                //   image: (
                //     <Badge variant="dot" color="error" badgeContent="">
                //       <img src="img/adviser-home-page/referadviser.png" alt="refer-adviser" />
                //     </Badge>
                //   ),
                //   text: intl.formatMessage({ id: 'refer-adviser' }),
                //   onClick: referAdviser
                // },
                {
                  image: <img src="img/advdiscussion1.png" alt="message" />,
                  text: intl.formatMessage({ id: 'adviser_message' }),
                  onClick: message
                }

                // {
                //   image: <img src="img/adviser-home-page/AddReferral.png" alt="feedback" />,
                //   text: intl.formatMessage({ id: 'Feedback' }),
                //   onClick: feedback
                // }
              ]}
            />
          </Grid>
        </Grid>

        <FeedBackModal isOpen={feedbackDialog} onRequestClose={closeFeedbackDialog} />

        <Fab className="fab" color="primary" style={FabStyle} onClick={help}>
          <img src="img/whatsapp.png" alt="whatsapp" style={FabImageStyle} />
        </Fab>
      </div>
    </AppContentContainer>
  );
};

const container = connect(
  state => ({
    userMissions: state.userMission.userMissions
  }),
  {
    renderHeaderButton,
    toggleUserMissionPopover
  }
)(MyProfileAdviser);

export default injectIntl(container);
