import { connect } from 'react-redux';
import { createUser, approveUser, verifyEmail, getUser } from '../../store/user/duck';
import SignupForm from './component';
import { selectedLocale } from '../../store/locale/action';

import { injectIntl } from 'react-intl';

const Signup = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails,
    user: state.user.user,
    createPhase: state.user.createPhase,
    createMessage: state.user.createMessage,
    approvePhase: state.user.approvePhase,
    verifyEmailPhase: state.user.verifyEmailPhase
  }),
  // Map actions to props
  {
    createUser,
    approveUser,
    verifyEmail,
    selectedLocale,
    getUser
  }
)(SignupForm);

export default injectIntl(Signup);
