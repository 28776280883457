import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { approveUser, handleSignOut } from '../../store/user/duck';
import _ from 'lodash';
import { toast } from 'react-toastify';
import './EmailVerificationModal.scss';
import IconToast from '../NewToast';
import { Typography } from '@material-ui/core';

class EmailVerificationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { resent: false };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { intl, approvePhase } = this.props;
    if (approvePhase === 'accepted' && prevProps.approvePhase !== 'accepted') {
      toast.info(IconToast('info', intl.formatMessage({ id: 'Verfication_Email_Resend' })), { className: 'new-toast' });
    }
  }

  loginAgain = () => {
    const { handleSignOut } = this.props;
    handleSignOut();
  };

  render() {
    const { intl, open, history, email, approveUser } = this.props;
    const { resent } = this.state;

    // show different messages in register page after successful registration
    const showRegisterMessage = _.get(history, 'location.pathname', '').toLowerCase() === '/signup';

    return <Dialog className="email-verify-modal" open={open}>
        <DialogContent className="content">
          <Typography gutterBottom={true}>
            {intl.formatMessage({ id: showRegisterMessage ? 'sign-up-email-verify-message' : 'Email_Verify_Message' })}
          </Typography>

          <Typography>{intl.formatMessage({ id: 'still-not-found' })}</Typography>

          <Button
            variant="outlined"
            color="primary"
            fullWidth={true}
            disabled={resent}
            onClick={() => {
              this.setState({ resent: true });
              approveUser({
                email: email,
                resendVerification: 'resendVerification'
              });
            }}
          >
            {intl.formatMessage({ id: 'Resend verification Email' })}
          </Button>

          <Button variant="contained" color="primary" fullWidth={true} onClick={this.loginAgain}>
            {intl.formatMessage({ id: 'login-again' })}
          </Button>
        </DialogContent>
      </Dialog>
  }
}

const container = connect(
  // Map state to props
  state => ({
    approvePhase: state.user.approvePhase,
    keepShowingNewSignUp: state.userSubscription.keepShowingNewSignUp
  }),
  // Map actions to props
  {
    approveUser,
    handleSignOut
  },
  // mergeProps
  null,
  // options
  {}
)(EmailVerificationModal);

export default injectIntl(container);
