import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  option: {
    fontSize: '14px',
    minHeight: 32
  },
  input: {
    padding: '0 !important'
  }
});
const StyledAutoComplete = props => {
  const { classes: propsClasses = {}, ...rest } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        ...classes,
        ...propsClasses
      }}
      {...rest}
    />
  );
};

export default StyledAutoComplete;
