import React, { Fragment, useState } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { DEFAULT_MAX_FILE_SIZE, DEFAULT_MAX_FILE_NUMBER } from './constants';
import './FilesUploadButton.scss';
import FileItem from './FileItem/FileItem';
import FileUploadDialog from './FileUploadDialog/FileUploadDialog';

const FilesUploadButton = props => {
  const {
    intl,
    customButtonRenderer,
    files,
    isFilesShown = true,
    isLoading = false,
    onFilesSubmitted,
    onFileRemoved,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    maxFileNumber = DEFAULT_MAX_FILE_NUMBER,
    isLinkUploadEnabled = false
  } = props;

  const [isFilesUploadDialogOpen, setIsFilesUploadDialogOpen] = useState(false);
  const [isFileRemoveDialogOpen, setIsFileRemoveDialogOpen] = useState(false);
  const [fileIndexToRemove, setFileIndexToRemove] = useState(null);

  const handleRemoveFile = fileIndex => {
    setIsFileRemoveDialogOpen(true);
    setFileIndexToRemove(fileIndex);
  };

  const confirmRemoveFile = () => {
    const fileIndex = fileIndexToRemove;
    if (onFileRemoved) {
      onFileRemoved(fileIndex);
    }
    setIsFileRemoveDialogOpen(false);
  };

  const handleFilesUploadButtonClick = () => {
    setIsFilesUploadDialogOpen(true);
  };

  return (
    <Fragment>
      <Grid>
        {files.length > 0 && isFilesShown && (
          <div className="file-items-container">
            {files.map((file, index) => (
              <FileItem key={index} file={file} onRemoved={() => handleRemoveFile(index)} />
            ))}
          </div>
        )}
        {!isLoading && files.length !== maxFileNumber && customButtonRenderer ? (
          customButtonRenderer(handleFilesUploadButtonClick)
        ) : (
          <div className="upload-button-container">
            <Button
              className="upload-button"
              variant="contained"
              color="primary"
              onClick={handleFilesUploadButtonClick}
            >
              {intl.formatMessage({ id: 'upload-files-button-title' })}
            </Button>
          </div>
        )}
        {isLoading && (
          <div className="loading-container">
            <CircularProgress color="primary" size={25} />
          </div>
        )}
      </Grid>

      <Dialog
        className="remove-file-dialog"
        open={isFileRemoveDialogOpen}
        onBackdropClick={() => setIsFileRemoveDialogOpen(false)}
      >
        <DialogTitle>{intl.formatMessage({ id: 'remove-file-dialog-title' })}</DialogTitle>
        <DialogContent>{intl.formatMessage({ id: 'remove-file-dialog-content' })}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setIsFileRemoveDialogOpen(false)}>
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="primary" onClick={() => confirmRemoveFile()}>
            {intl.formatMessage({ id: 'Confirm' })}
          </Button>
        </DialogActions>
      </Dialog>

      <FileUploadDialog
        open={isFilesUploadDialogOpen}
        onClose={() => setIsFilesUploadDialogOpen(false)}
        files={files}
        onFilesSubmitted={onFilesSubmitted}
        maxFileSize={maxFileSize}
        maxFileNumber={maxFileNumber}
        isLinkUploadEnabled={isLinkUploadEnabled}
      />
    </Fragment>
  );
};

export default injectIntl(FilesUploadButton);
