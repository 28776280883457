import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getUserCurrency } from '../../../../utils/user';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PVPChartsView from '../PVPChartsView/PVPChartsView';
import { POLICY_TYPE } from '../../../../constants/policy';

const CIPolicyStatistics = props => {
  const { intl, onChange, userDetails, PVPChartDataCI } = props;

  const currency = getUserCurrency(userDetails);

  return (
    <ExpansionPanel
      defaultExpanded={false}
      className="analysis-expansion analysis-expansion-overview analysis-bottom exclude-in-image"
      style={{ backgroundColor: '#3966f808' }}
      onChange={onChange}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="analysis-expansion-summary1">
        {intl.formatMessage({ id: 'Policy-Statistics' })}
      </ExpansionPanelSummary>

      <ExpansionPanelDetails className="analysis-expansion-detail">
        {/*REMOVE showing chart in analysis which both A and C can read
                 <div className="cardAnalysis analysis-expansion-element">
                  <CancerChanceChart />
                </div> */}
        <div className="cardAnalysis analysis-expansion-element">
          <PVPChartsView policyType={POLICY_TYPE.CI} data={PVPChartDataCI} currency={currency} />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const container = connect(
  state => ({
    userDetails: state.user.userDetails,
    PVPChartDataCI: state.analysis.PVPChartData.ci
  }),
  {}
)(CIPolicyStatistics);

export default injectIntl(container);
