import { connect } from 'react-redux';
import FreeToolsView from './component';
import { injectIntl } from 'react-intl';
import { renderHeaderButton } from '../../../../store/headerButtonControl/duck';

const FreeTools = connect(
  // Map state to props
  state => ({
    userDetails: state.user.userDetails
  }),
  // Map actions to props
  {
    renderHeaderButton
  }
)(FreeToolsView);

export default injectIntl(FreeTools);
