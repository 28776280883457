import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import LanguageRadioGroup from '../../../../../components/LanguageRadioGroup/LanguageRadioGroup';
import './PDFExportCard.scss';

const PDFExportCard = props => {
  const { intl, loading, language, onChangeLanguage, onGenerate } = props;
  return (
    <Grid className="pdf-export-card" container justify="center">
      <Grid item>
        <LanguageRadioGroup name="language" value={language} onChange={onChangeLanguage} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button color="primary" variant="contained" disabled={loading} onClick={onGenerate}>
              {loading ? <CircularProgress size={24.5} /> : `${intl.formatMessage({ id: 'Export_action' })} PDF`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default injectIntl(PDFExportCard);
