import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { getIntegerOrFixed } from '../../../../utils/formatNumber';

const ColoredRatio = props => {
  const { intl, warn, color, value } = props;

  return (
    <Grid container alignItems="center" spacing={1}>
      {warn && (
        <Grid item>
          <WarningRoundedIcon style={{ color: '#ffa600' }} />
        </Grid>
      )}
      {/* <Grid item  style={{ flexGrow: 1 }}>
        <Typography color="textSecondary">{intl.formatMessage({ id: 'ratio' })}</Typography>
      </Grid> */}
      <Grid item style={{ flexGrow: 1 }}>
        <Typography style={{ color: color, fontWeight: 'bold', fontSize: '160%' }}>
          {value ? `${getIntegerOrFixed(value, 1)}%` : value === 0 ? '0%' : '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(ColoredRatio);
