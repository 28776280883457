import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Portal } from "@material-ui/core";

class Chat extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      textMessage: '',
      senderId: '',
      receiverId: ''
    };
  }

  handleChange(e) {
    this.setState({ textMessage: e.target.value });
  }

  UNSAFE_componentWillMount() {
    var h = window.innerHeight;
    window.scrollBy(100, h);
    let selectedClient = this.props.selectedClient;
    var user = this.props.userDetails;
    if (!this.isEmpty(selectedClient)) {
      this.setState({ senderId: user._id, receiverId: selectedClient._id });
      const mergeData1 = [user._id, selectedClient._id];
      const mergeData2 = [selectedClient._id, user._id];
      const uniqueid1 = mergeData1.join('|');
      const uniqueid2 = mergeData2.join('|');
      const data = {};
      data.uniqueid1 = uniqueid1;
      data.uniqueid2 = uniqueid2;
      // socket.on('RECEIVE_MESSAGE', function(data1) {
      //   fetchMessages(data);
      // });
      this.props.fetchMessages(data);
    }
  }

  addMessage() {
    const { addMessage } = this.props;
    let mergeData = [this.state.senderId, this.state.receiverId];
    let uniqueKey = mergeData.join('|');
    const date = moment().format();
    const msgData = {
      key: uniqueKey,
      senderId: this.state.senderId,
      receiverId: this.state.receiverId,
      message: this.state.textMessage,
      createdDate: date
    };
    addMessage(msgData);
    //socket.emit('SEND_MESSAGE', function() {});
    this.setState({ textMessage: '' });
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  render() {
    var h = window.innerHeight;
    window.scrollBy(100, h);
    const { fetchMessageData, selectedClient } = this.props;
    var user = this.props.userDetails;
    let receiver = user && selectedClient;
    return (
      <div>
        <div id="wrapper">
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="chat-window">
                    <ol className="chat">
                      {fetchMessageData &&
                        fetchMessageData.length > 0 &&
                        fetchMessageData.map((val, index) => {
                          var date = val.createdDate;
                          date = new Date(date);
                          var time = date.getTime();
                          time = date.getHours() + ':' + date.getMinutes();
                          const mergeData1 = [user._id, selectedClient._id];
                          const uniqueid1 = mergeData1.join('|');

                          let positionClass = '';
                          if (uniqueid1 === val.key) {
                            positionClass = 'self';
                          } else {
                            positionClass = 'other';
                          }
                          return (
                            <div key={index}>
                              <li className={positionClass}>
                                <div className="avatar">
                                  <img alt="" src={''} draggable="false" />
                                </div>
                                <div className="msg">
                                  <p>{val.message}</p>
                                  <span>{time}</span>
                                </div>
                              </li>
                            </div>
                          );
                        })}
                    </ol>
                    <div className="send-icon">
                      <input
                        className="textarea"
                        type="text"
                        value={this.state.textMessage}
                        onChange={this.handleChange.bind(this)}
                        placeholder="Type Message..."
                      />
                      <img
                        alt=""
                        className="send-img"
                        type="button"
                        onClick={this.addMessage.bind(this)}
                        src="img/sendIcon.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
