import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { CircularProgress } from '@material-ui/core';
import { StripeElement } from './StripeElement';

export const StripeElementWrapper = props => {
    const {
        intl,
        stripePromise,
        clientSecret
    } = props;

    return (
        clientSecret ?
            <Elements
                stripe={stripePromise}
                options={{
                    clientSecret: clientSecret,
                    loader: "always",
                    locale: intl.locale === 'zh-Hant-HK' ? 'zh-HK' : intl.locale
                }}
            >
                <StripeElement {...props} />
            </Elements>
            :
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                <CircularProgress size={30} />
            </div>
    );
};