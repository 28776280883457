import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@material-ui/core';


export default function HistoryKeywords(props) {
    // console.log(" HistoryKeywords props: ", props)
    const { list, getImage, onClick } = props;
    return (
        <List>
            {list.map(kw => {
                const attributes = kw.attributes;
                const previewImg = getImage(attributes.preview, 'thumbnail');
                // console.log(" HistoryKeywords attributes: ", attributes)
                return (
                    <ListItem key={kw.id}>
                        <ListItemAvatar>
                            <Avatar alt={attributes.heading} src={previewImg} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                attributes.keyword.map(k => {
                                    return '#' + k.keyword + ' '
                                })
                            }
                            onClick={() => onClick(kw)}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="h5"
                                        color="text.primary"
                                    >
                                        {attributes.vol}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                )
            })}
        </List>
    );
}

