import produce from 'immer';
import { formatEditorStateValue, getEditorDefaultMessage } from '../../utils/editor';
import * as api from './api';
import { INIT, LOADING, ERROR, SUCCESS } from '../../constants/phase';
import {
  RESET_EMERGENCY_EXPORT,
  UPDATE_EMERGENCY_EXPORT,
  UPDATE_EMERGENCY_EXPORT_INPUTS
} from '../emergencyExport/duck';

/***********************************
 * Action Types
 ***********/
export const UPDATE_POLICY_REVIEW_INPUTS = 'UPDATE_POLICY_REVIEW_INPUTS';
export const UPDATE_POLICY_REVIEW = 'UPDATE_POLICY_REVIEW';
export const RESET_POLICY_REVIEW = 'RESET_POLICY_REVIEW';

/***********************************
 * Initial State
 ***********/
const initialState = {
  selectShowLacks: true,
  selectBasic: true,
  selectProtection: true,
  selectPremium: true,
  selectSaving: true,
  selectLifeInsured: true,
  selectBeneficiary: true,
  selectAccident: true,
  selectTotalWithdrawals: true,
  selectLumpSum: true,
  selectCurrencyPerYear: true,
  selectPersonal: true,
  selectCompany: true,
  selectGeneral: true,
  selectTwoRows: true,
  selectTotalCoveragePremium: true,
  selectPageSplit: false,
  selectLanguage: 'en',
  selectTeamLogo: true,
  remark: formatEditorStateValue(getEditorDefaultMessage('en', 'policy-review-title')),

  presentMode: false,
  loading: false,

  getPhase: INIT,
  updatePhase: INIT,
  policyReview: {}
};

/***********************************
 * Reducer
 ***********/
export default function(state = initialState, action = {}) {
  return produce(state, draft => {
    switch (action.type) {
      case UPDATE_POLICY_REVIEW_INPUTS:
        draft[action.key] = action.value;
        break;
      case UPDATE_POLICY_REVIEW:
        draft.policyReview = action.value;
        break;
      case RESET_POLICY_REVIEW:
        draft = initialState;
        break;
      default:
        break;
    }
  });
}

/***********************************
 * Action Creators
 ***********/
export const updatePolicyReviewInputs = (key, value) => ({
  type: UPDATE_POLICY_REVIEW_INPUTS,
  key,
  value
});

export const getPolicyReview = clientId => async (dispatch, getState) => {
  dispatch({ type: UPDATE_POLICY_REVIEW_INPUTS, key: 'getPhase', value: LOADING });
  try {
    const result = clientId ? await api.getPolicyReview(clientId) : {};
    dispatch({ type: UPDATE_POLICY_REVIEW, value: result.policyReview });
    dispatch({ type: UPDATE_POLICY_REVIEW_INPUTS, key: 'getPhase', value: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_POLICY_REVIEW_INPUTS, key: 'getPhase', value: ERROR });
  }
};

export const updatePolicyReview = (clientId, policyReview) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_POLICY_REVIEW_INPUTS, key: 'updatePhase', value: LOADING });
  try {
    const result = await api.updatePolicyReview(clientId, policyReview);
    dispatch({ type: UPDATE_POLICY_REVIEW, value: result.policyReview });
    dispatch({ type: UPDATE_POLICY_REVIEW_INPUTS, key: 'updatePhase', value: SUCCESS });
  } catch (error) {
    dispatch({ type: UPDATE_POLICY_REVIEW_INPUTS, key: 'updatePhase', value: ERROR });
  }
};

export const resetPolicyReview = () => ({
  type: RESET_POLICY_REVIEW
});
