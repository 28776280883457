export const MACAU_INSURERS = {
  // AIA
  '59b0f0a97af33f1d6074cbfd': {
    email: 'aiamacau@aia.com',
    hotline: '+853 8988 1822'
  },
  // AXA
  '59b0f1037af33f1d6074cbff': {
    email: 'ma.enquiry@axa.com.mo',
    hotline: '+853 8799 2812'
  },
  // China Life
  '59b0f1377af33f1d6074cc01': {
    email: 'pos_mo@chinalife.com.hk',
    hotline: '+853 2878 7288'
  },
  // YF Life
  '59b0f2587af33f1d6074cc0b': {
    email: '',
    hotline: '+853 2832 2622'
  },
  // Manulife
  '59b0f21d7af33f1d6074cc0a': {
    email: '',
    hotline: '+853 8398 0383'
  },
  // FWD
  '59b0f1ef7af33f1d6074cc09': {
    email: 'cs.mo@fwd.com',
    hotline: '+853 8988 6060'
  },
  // Tahoe
  '5d8225ae427ca38a99af68b6': {
    email: 'cs@tahoelife.com.mo',
    hotline: '+853 2855 8777'
  },
  // HSBC Life
  '5b5333e61e4baddb4483c570': {
    email: '',
    hotline: '+853 2821 6133'
  }
};
