import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { getLocaleMessages } from '../../utils/locale';

const IntlProvideWrapper = props => {
  const [language, setLanguage] = useState(props.defaultLanguage || 'en');

  return (
    <IntlProvider
      key={language}
      locale={language}
      defaultLocale={language}
      messages={getLocaleMessages(language).messages}
    >
      {props.children({ language, setLanguage })}
    </IntlProvider>
  );
};

export default IntlProvideWrapper;
