
import { tracking } from "../../tracking";




/**
 * Tracks viewership engagement for a specific element on the page.
 * @param {string} elementId - The ID of the element to track viewership for.
 * @param {Object} eventProps - Additional properties to include in the tracking event.
 * @param {string} eventProps Page Path - The path of the page where the element is located.
 * @param {number} eventProps Interval - Time to track viewership, default 60000ms.
 * @param {boolean} eventProps Is Loop - Whether to continuously track viewership at the specified interval.
 * @returns {Promise<void>} - A promise that resolves once the viewership tracking is complete.
 */

export const trackViewership = async (elementId = "", eventProps = {}) => {
    console.log('viewship: ', elementId, eventProps)
    if (eventProps["Page Path"] && elementId) {
        eventProps["Element ID"] = elementId;

        const interval = eventProps["Interval"] ?? 60000;
        if (!eventProps["Interval"]) {
            eventProps["Interval"] = interval;
        }

        if (eventProps["Is Loop"] && interval) {
            eventProps["Interval Count"] = 0;
            var shouldDoTracking = true;
            delete eventProps["Is Loop"];

            while (shouldDoTracking) {
                await new Promise(r => setTimeout(r, interval));
                const element = document.getElementById(elementId);
                if (element) {
                    eventProps["Interval Count"] += 1;
                    // console.log('loop viewship tracked: ', eventProps)
                    tracking("Viewership Engagement", eventProps)
                } else {
                    shouldDoTracking = false
                    // console.log('loop viewship track cancelled')
                };
            }
        } else {
            setTimeout(() => {
                const element = document.getElementById(elementId);

                if (element) {
                    // console.log('viewship tracked: ', eventProps)
                    tracking("Viewership", eventProps)
                } else {
                    // console.log('viewship track cancelled')
                }
            }, interval);

        }
    } else {
        // console.log('viewship track cancelled, missing required props')
    }


}
